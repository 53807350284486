import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as SC from "./styles";

import { setCurrentFloor } from "../../../store/blueprints/store/actions";
import { isEqual } from "lodash";

export default function FloorSelector({ floors }) {
  const dispatch = useDispatch();

  const floorsRef = React.useRef();

  const sortedFloors =
    floors && floors.sort((a, b) => (a.floor > b.floor ? 1 : -1));

  const currentBuildingId = useSelector(
    (state) => state.blueprints.currentBuilding?.id
  );

  const currentFloor = useSelector((state) => state.blueprints.currentFloor);

  React.useEffect(() => {
    if (!isEqual(floors, floorsRef.current)) {
      floorsRef.current = floors;
    }
  }, [floors]);

  React.useEffect(() => {
    if (floors.length) {
      dispatch(setCurrentFloor({ floor: sortedFloors[0] }));
    } else {
      dispatch(setCurrentFloor({ floor: null }));
    }
  }, [currentBuildingId, floorsRef.current]);

  const handleChange = (floor) => {
    dispatch(setCurrentFloor({ floor }));
  };

  return (
    <>
      {sortedFloors?.length ? (
        <SC.SelectWrapper>
          <SC.InputFieldTitle>Våning</SC.InputFieldTitle>

          <Select
            id={"floorSelector"}
            onChange={handleChange}
            defaultValue={null}
            value={currentFloor}
            options={sortedFloors}
            getOptionValue={(option) => option.floor}
            getOptionLabel={(option) => option.floor}
          />
        </SC.SelectWrapper>
      ) : null}
    </>
  );
}
