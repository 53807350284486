import styled, { css } from "styled-components";
import { animated } from "@react-spring/web";

import closeCircleIcon from "../../assets/svg/close-circle.svg";

export const BackDrop = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0 70px 0;
  z-index: 1001;
`;

export const FormModalWrapper = styled.div`
  min-width: 800px;
  max-width: 1000px;
  min-height: 100%;
  width: 60%;
  padding: 10px 20px 20px 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  overflow-y: auto;
`;

export const FormModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  font-weight: 600;
  color: ${(p) => p.theme.colors.black};
  font-size: 1.4rem;
`;

export const CloseCircleButton = styled.div`
  cursor: pointer;
  height: 32px;
  width: 32px;
  position: absolute;
  top: calc(50% - 16px);
  right: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeCircleIcon});
`;

export const StandardBackDrop = styled(animated.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(p) => p.theme.zIndex.modalBackdrop};

  font-family: "Inter", sans-serif;

  ${(p) =>
    p.modalInModal &&
    css`
      height: 100%;
      width: 100%;
    `}
`;

export const ConfirmationBackdrop = styled(StandardBackDrop)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(p) => p.theme.zIndex.modalBackdrop + 1};

  ${(p) =>
    p.modalInModal &&
    css`
      height: 100%;
      width: 100%;
    `}
`;

export const Modal = styled(animated.div)`
  height: 70vh;
  min-height: 600px;
  cursor: default;
  width: 90%;
  max-width: 1000px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  z-index: ${(p) => p.theme.zIndex.modal};
  ${(p) =>
    p.high &&
    css`
      height: 85vh;
    `}

  ${(p) =>
    p.large &&
    css`
      height: 85vh;
      max-width: 90vw;
    `}

  ${(p) =>
    p.small &&
    css`
      height: 30vh;
      max-width: 500px;
      min-height: 400px;
      max-height: 30vh;
    `}


    ${(p) =>
    p.modalInModal &&
    css`
      width: 90%;
      height: 90%;
      margin: 5% 0;
    `}


    @media(${(p) => p.theme.mediaQueries.mobile}) {
    width: 98%;
    height: 98%;
  }
`;

export const ConfirmationModal = styled(Modal)`
  min-height: 200px;
  max-height: 400px;
  min-width: 400px;
  max-width: 500px;

  ${(p) =>
    p.modalInModal &&
    css`
      width: 90%;
      height: 90%;
      margin: 5% 0;
    `}
`;

export const ConfirmationTitle = styled.div`
  display: flex;
  padding: 24px;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.primary};
`;

export const ModalActionBar = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid ${(p) => p.theme.colors.fadedBorder};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ModalContent = styled.div`
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  padding: 20px 20px 240px 20px;
  overflow-y: auto;
  ${(p) =>
    p.noBottomPadding &&
    css`
      padding-bottom: 20px;
    `}

  ${(p) => {
    return (
      p.withTitle &&
      css`
        max-height: calc(100% - 120px);
        min-height: calc(100% - 120px);
      `
    );
  }}
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.fadedBorder};
  color: ${(p) => p.theme.colors.blueDark};
  font-weight: 600;

  ${(p) =>
    p.withHeaderAction &&
    css`
      justify-content: space-between;
      padding: 0 20px;
      padding-right: 70px;
    `}

  ${(p) =>
    p.hideHeaderBorder &&
    css`
      border-bottom: none;
    `}
`;

export const SideModal = styled(animated.div)`
  height: 100vh;
  min-height: 100vh;
  cursor: default;
  width: 60%;
  max-width: 1000px;
  min-width: 400px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  box-shadow: ${(p) => p.theme.boxShadows.standard};

  z-index: ${(p) => p.theme.zIndex.modal};
`;

export const SkeletonModal = styled(animated.div)``;
