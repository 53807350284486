import * as React from "react";
import { useHistory, useParams } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import { createManyUrl } from "../../../../store/marketApartmentAds";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import {
  constants as apartmentAdConstants,
  updateDefaultSettings as apartmentUpdateDefaultSettings,
} from "../../../../store/marketApartmentAds";
import {
  constants as indpAdConstants,
  updateDefaultSettings as indpUpdateDefaultSettings,
} from "../../../../store/marketIndpAds";
import {
  constants as parkingAdConstants,
  updateDefaultSettings as parkingUpdateDefaultSettings,
} from "../../../../store/marketParkingAds";
import useQuery from "../../../utils/useQuery";
import { AD_CREATE_TYPES } from "../../../../views/Market/ManyHandling/ManyAdRootObjectSelect";

const getConstants = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentAdConstants;
  if (type === AD_CREATE_TYPES.INDP) return indpAdConstants;
  if (type === AD_CREATE_TYPES.PARKING) return parkingAdConstants;
};

const getUpdateDefaultSettings = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentUpdateDefaultSettings;
  if (type === AD_CREATE_TYPES.INDP) return indpUpdateDefaultSettings;
  if (type === AD_CREATE_TYPES.PARKING) return parkingUpdateDefaultSettings;
};

export default function ManyAdsFlowForm() {
  const { type } = useParams();
  const constants = getConstants(type);
  const updateDefaultSettings = getUpdateDefaultSettings(type);
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { push } = useHistory();
  const query = useQuery();
  const buildingId = query.get("building");

  const onSubmit = () => {
    push(`${createManyUrl({ type })}?building=${buildingId}`);
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          updateDefaultSettings,
          type,
          chapterDefs: chapterDefs(),
          chapters,
          descriptions,
          onSubmit,
          title: "Grundinställningar för annonser",
        }}
      />
    </>
  );
}
