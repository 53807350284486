import * as React from "react";
import * as SC from "./styles";

export default ({ clicked, disabled, title, extraStyle, newDesign }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };
  return (
    <SC.LinkButton
      {...{ newDesign }}
      style={{ ...extraStyle }}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
    </SC.LinkButton>
  );
};
