import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useParkingContractForm,
  constants,
  create,
  destroyPostForm,
  createCostsUrl,
} from "../../../../store/parkingContracts";
import useQuery from "../../../utils/useQuery";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import StepDisplay from "../../../StepDisplay/StepDisplay";
import { useFormInstanceField } from "../../../../store/base";

export default function ParkingContractCreateForm({ method = "POST" }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useParkingContractForm(method));
  const { contractType } = useParams();
  const { replace } = useHistory();

  const query = useQuery();
  const isManualSign = query.get("manual_sign") === "true";

  // id of the lease contract used for the proposal, might be null
  const proposalSourceId = useFormInstanceField({
    storeName,
    fieldKey: "_proposalSourceId",
  });

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(
      createCostsUrl({
        id: returned.id,
        cloneFrom: proposalSourceId || undefined,
      })
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(false));
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  // TODO - Ask before save draft
  const onSaveDraft = () => {
    onSubmit(true);
  };

  const onSubmit = (draft = false) => {
    setLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) =>
          preProcess({ data, draft, contractType, isManualSign }),
      })
    );
  };

  const renderSteps = () => {
    const steps = ["1. Avtalstyp", "2. Avtal", "3. Debitering & Avisering"];

    if (contractType !== "existing") {
      steps.push("4. Dokument för signering");
    }

    return <StepDisplay steps={steps} activeIndex={1} />;
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      {renderSteps()}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs({
            contractType,
          }),
          chapters,
          contractType,
          descriptions,
          method,
          onSubmit,
          onSaveDraft,
          displayDocumentFieldKey: "editabledoc.docData_",
          title:
            method === "POST"
              ? "Lägg till parkeringsavtal"
              : "Uppdatera parkeringsavtal",
        }}
      />
    </>
  );
}

const preProcess = ({ data, draft, contractType, isManualSign }) => {
  const copy = cloneDeep(data);

  copy.draft = draft ? true : false;
  copy.pre_existing = contractType === "existing";
  copy.uses_e_signing = !isManualSign;

  if (!copy.bill_with_lease) {
    copy.bill_with_lease = false;
  }

  if (copy.renew_indefinetely === "no_renewal") {
    copy.renew_indefinetely = false;
    copy.renew_interval = null;
  }

  // always create an editable doc
  if (!copy.editabledoc?.title) {
    set(copy, "editabledoc.title", copy.id_number || "Dokument");
  }

  // can't send null to backend
  if (copy.renew_indefinetely === null) {
    delete copy.renew_indefinetely;
  }

  return copy;
};
