import * as React from "react";
import FlowFormDescription from "./FlowFormDescription";
import FlowFormNavigator from "./FlowFormNavigator";
import FlowFormTitle from "./FlowFormTitle";
import * as SC from "./styles";

// ...rest is whatever else gets passed down, which can be specific flags and functions depending on what form is in use
export default ({
  chapter,
  chapterIndex,
  chapterCount,
  CurrentDescription,
  CurrentChapter,
  hasError,
  withNavigator,
  onNavigatePrev,
  onNavigateNext,
  method,
  onSubmit,
  onSaveDraft,
  ...rest
}) => {
  const ref = React.useRef();

  return (
    <SC.FlowFormChapterContainer ref={ref} id={chapter.key} key={chapter.key}>
      <SC.FlowFormChapterTitleWrapper>
        <FlowFormNavigator
          {...{
            chapterCount,
            chapterIndex,
            onNavigateNext,
            onNavigatePrev,
            isVisibile: withNavigator,
          }}
        />
        <FlowFormTitle
          {...{
            chapter,
            chapterCount,
            chapterIndex,
            isMain: withNavigator,
            hasError,
          }}
        />

        {CurrentDescription && (
          <FlowFormDescription isMain={withNavigator}>
            <CurrentDescription {...{ method, ...rest }} />
          </FlowFormDescription>
        )}
      </SC.FlowFormChapterTitleWrapper>

      <SC.FlowFormChapter {...{ hasError }}>
        {CurrentChapter && (
          <CurrentChapter {...{ method, onSubmit, onSaveDraft, ...rest }} />
        )}
      </SC.FlowFormChapter>
    </SC.FlowFormChapterContainer>
  );
};
