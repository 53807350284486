import * as React from "react";

import {
  TextInput,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/keypermission";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
     <TextInput
        title="Namn"
        storeName={storeName}
        method={method}
        fieldKey="title"
      />
    </>
  );
};
