const defs = [
  {
    title: "Objektstyp",
    key: "TYPE",
    visited: true,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Objektsfiltrering",
    key: "QUERY",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Exkluderingsregler",
    key: "EXCLUDE",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },

  {
    title: "Gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
