import * as React from "react";

// style, design
import {
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import {
  AsyncSelect,
  Checkbox,
  TextInput,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/fortnoxConfig";
import { useFilteredCompanies } from "../../../../../store/companies";
import { buildQueryString } from "../../../../../store/base";

export default ({ method }) => {

  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Fortnox Konfiguratin</FormAreaTitle>
      <AsyncSelect
      storeName={storeName}
      method={method}
      fieldKey={"company"}
      fetchMethod={() => useFilteredCompanies(buildQueryString({has_no_fortnox_credential:false}))}
      title="Företag"
      />
      <Checkbox
      storeName={storeName}
      method={method}
      fieldKey={"auto_fetch_cost_centers"}
      title={"Auto hämta kostnadscenter"}
      />
      <Checkbox
      storeName={storeName}
      method={method}
      fieldKey={"auto_fetch_projects"}
      title={"Auto hämta projekt"}
      />
      <Checkbox
      storeName={storeName}
      method={method}
      fieldKey={"auto_fetch_products"}
      title={"Auto hämta produkter"}
      />
    </>
  );
};
