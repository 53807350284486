import * as React from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default React.memo(
  ({
    storeName,
    method,
    fieldKey,
    fetchMethod,
    fetchQueryString,
    instructionsKey,
    changeCallback,

    //special, changecallback is used differently here from elsewhere so
    // this needs to be passed to use changecallback in combination with updating form instance
    updateFormInstanceAndChangeCallback = false,
    noTitle,
    noMargin,
    disabled,
    menuPlacement,
    placeholder,
    title,
    description,
    extraStyles,
    setIdOnly,
    children,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    let formattedError;
    // fallback for error beeing array
    if (Array.isArray(error)) {
      formattedError = error[0];
    } else {
      formattedError = error;
    }

    const [choices, isLoading] = fetchMethod(`${fetchQueryString || ""}`);

    const onChange = (data) => {
      let formattedValue = instructions._allowNull ? null : undefined;
      if (data) {
        formattedValue = data;
        if (setIdOnly) { formattedValue = formattedValue?.id; }
      }
      if (changeCallback && !updateFormInstanceAndChangeCallback) {
        changeCallback(formattedValue);
      } else {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: formattedValue },
          })
        );

        if (changeCallback) {
          changeCallback(formattedValue);
        }
      }
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const selected = choices?.find((c) => c.id === (setIdOnly ? value : value?.id)) || null;

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {!noTitle && (
          <SC.InputFieldTitle>
            {title || instructions._label}
            {instructions._required ? "*" : ""}
          </SC.InputFieldTitle>
        )}
        {(instructions._helpText || description) && (
          <SC.InputFieldDescription>
            {description || instructions._helpText}
          </SC.InputFieldDescription>
        )}
        {choices ? (
          <SC.SelectWrapper>
            <Select
              {...{ menuPlacement }}
              isDisabled={disabled}
              id={instructions._internalId}
              placeholder={placeholder || "Välj..."}
              onChange={onChange}
              defaultValue={null}
              value={selected}
              options={choices}
              getOptionValue={(option) => option}
              getOptionLabel={(option) => option.str_representation}
              isClearable={!instructions._required}
            />
          </SC.SelectWrapper>
        ) : (
          <SC.SelectLoader>Laddar alternativ...</SC.SelectLoader>
        )}
        {!!formattedError && (
          <SC.ErrorMessage>{formattedError}</SC.ErrorMessage>
        )}

        {children && children}
      </SC.InputSpacing>
    );
  }
);
