import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  update,
  destroyPatchForm,
  destroyPostForm,
  useBlueprintForm,
} from "../../../../store/blueprints";
import { updateActiveFormInstance } from "../../../../store/base";

export default ({ method, id, isOpen, onCheckout }) => {
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);

  const formLoaded = Boolean(useBlueprintForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoadingSubmit(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoadingSubmit(true);
    if (method === "POST") {
      dispatch(create({ successCallback: onSuccess }));
    } else if (method === "PATCH") {
      dispatch(update({ id, successCallback: onSuccess }));
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Skapa planritning" : "Uppdatera planritning"}
      onDone={onDone}
      loading={!formLoaded || loadingSubmit}
      isOpen={isOpen}
    />
  );
};
