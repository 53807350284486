import * as React from "react";

// style, design
import { TextInput, NumberInput, Checkbox } from "../Base/Fields";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  return (
    <>
      <TextInput
        title="Namn"
        storeName={storeName}
        fieldKey={`${parentPath}.name`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.name`}
      />

      <TextInput
        title="Adress"
        storeName={storeName}
        fieldKey={`${parentPath}.address`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.address`}
      />

      <TextInput
        title="Land"
        storeName={storeName}
        fieldKey={`${parentPath}.country`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.country`}
      />

      <TextInput
        title="Personnummer/orgnr"
        storeName={storeName}
        fieldKey={`${parentPath}.ssn_number`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.ssn_number`}
      />

      <NumberInput
        title="Ägarandel i %"
        storeName={storeName}
        fieldKey={`${parentPath}.holding_shares_percent`}
        method={method}
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.holding_shares_percent`}
      />

      <NumberInput
        title="Röstandel i %"
        storeName={storeName}
        fieldKey={`${parentPath}.holding_votes_percent`}
        method={method}
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.holding_votes_percent`}
        title={"Antal rösträtter (i procent)"}
      />

      <Checkbox
        title="Är ej juridisk person"
        storeName={storeName}
        fieldKey={`${parentPath}.is_non_legal_entity`}
        method={method}
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.is_non_legal_entity`}
      />

      <Checkbox
        title="Har amerikanskt medborgarskap"
        storeName={storeName}
        fieldKey={`${parentPath}.has_us_citizenship`}
        method={method}
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.has_us_citizenship`}
      />

      <Checkbox
        title="Är ej direkt ägare"
        storeName={storeName}
        fieldKey={`${parentPath}.non_legal_direct_owner`}
        method={method}
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.non_legal_direct_owner`}
      />
    </>
  );
};
