import * as React from "react";

export default () => {
  return (
    <div>
      Ange huruvida de besiktade komponenterna klarade besiktningen och lämna
      eventuella kommentarer
    </div>
  );
};
