import * as React from "react";
import moment from "moment";
import { useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import BrfContractModal from "../../../components/Forms/BrfContract/ChapterForm/ModalForm";

import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import ContractTable from "../../../components/Tables/BrfContracts/FullTable";

import { useFilteredBrfContracts, constants } from "../../../store/brfContract";

import { buildQueryString } from "../../../store/base";
import { useBrfPremises } from "../../../store/brfPremises";
import { useBrfOwner, useFilteredBrfOwners } from "../../../store/brfOwner";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import { useIsImpersonation } from "src/store/app/hooks";
import DeleteModal from "src/components/Forms/Delete/DeleteModal";

export default function BrfOwnerSwaps() {
  const [addBrfContractOpen, setBrfContractOpen] = React.useState(false);
  const [editBrfContractOpen, setEditBrfContractOpen] = React.useState(false);

  const [adminEditOpen, setAdminEditOpen] = React.useState(false);
  const [deleteTransaction, setDeleteTransaction] = React.useState(null);

  const isAdmin = useIsImpersonation();

  const { brfPremisesId } = useParams();

  const [brfPremises] = useBrfPremises(brfPremisesId);

  const [currentOwner] = useBrfOwner(brfPremises?.owner?.id);
  const biOwnerQ = buildQueryString({
    id__in: brfPremises?.bi_owners?.map((b) => b.id) || [],
  });
  const [currentBiOwners] = useFilteredBrfOwners(biOwnerQ);

  const brfContractQuery = {
    brf_premis: brfPremisesId,
  };

  const [brfContracts] = useFilteredBrfContracts(
    buildQueryString(brfContractQuery)
  );

  const latestTransaction = brfContracts?.reduce((acc, cur) => {
    const curTransactionDate = moment(cur.transaction_date);
    const accTransactionDate = acc ? moment(acc.transaction_date) : null;

    if (!accTransactionDate || curTransactionDate.isAfter(accTransactionDate))
      return cur;

    return acc;
  }, null);

  const today = moment().format("YYYY-MM-DD");
  const premisesRep = brfPremises?.str_representation;
  const initialTransactionData = {
    sellers: currentOwner
      ? [currentOwner, ...(currentBiOwners || [])]
      : undefined,
    brf_premis: {
      id: brfPremisesId,
    },
    id_number: `${premisesRep} - ${today}`,
  };

  const onAddTransaction = () => {
    setBrfContractOpen(true);
  };

  const editLatestTransaction = () => {
    setEditBrfContractOpen(latestTransaction?.id);
  };

  const editAdminAnyRow = (original) => {
    setAdminEditOpen(original);
  };

  return (
    <>
      {deleteTransaction && (
        <DeleteModal
          isOpen={true}
          closeFunction={() => setDeleteTransaction(false)}
          instance={deleteTransaction}
          constants={constants}
        />
      )}

      {addBrfContractOpen && (
        <BrfContractModal
          method="POST"
          instance={initialTransactionData}
          onCheckout={() => setBrfContractOpen(false)}
        />
      )}

      {editBrfContractOpen && (
        <BrfContractModal
          method="PATCH"
          instance={latestTransaction}
          id={editBrfContractOpen}
          onCheckout={() => setEditBrfContractOpen(false)}
        />
      )}

      {adminEditOpen && (
        <BrfContractModal
          method="PATCH"
          instance={adminEditOpen}
          id={adminEditOpen?.id}
          onCheckout={() => setAdminEditOpen(false)}
        />
      )}
      <DetailInnerWrapper>
        {brfContracts?.length > 0 && !deleteTransaction ? (
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Ägarbyten</OverviewTitle>
                <OverviewSubtitle>
                  <TextButton
                    title="Redigera det senaste ägarbytet"
                    iconType="edit"
                    iconPlacement="right"
                    clicked={editLatestTransaction}
                  />
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>

              <PrimaryBtn onClick={onAddTransaction}>
                Registrera ägarbyte
              </PrimaryBtn>
            </OverviewTitleWrapper>

            <ContractTable
              persistantQuery={brfContractQuery}
              isBare
              ignoreLocalStorage
              isAdmin={isAdmin}
              initialSortBy={[{ id: "transaction_date", desc: true }]}
              onEdit={editAdminAnyRow}
              onDelete={(original) => setDeleteTransaction(original)}
            />
          </DetailPageBox>
        ) : (
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Ägare saknas</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>

              <PrimaryBtn onClick={onAddTransaction}>
                Lägg till ägare
              </PrimaryBtn>
            </OverviewTitleWrapper>
          </DetailPageBox>
        )}
      </DetailInnerWrapper>
    </>
  );
}
