import * as React from "react";

import { Cancellation, Confirm } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "CANCELLATION":
      return <Cancellation method={method} />;

    default:
      return <Confirm />;
  }
};
