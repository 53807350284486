import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/IMDMeasureType";

export default function MeasureTypeTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const columns = React.useMemo(() => {
    const data = columnDefs();
    return data;
  }, [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "measuretype_full_table"}
      title={"Mätartyper"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
