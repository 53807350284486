import * as React from "react";
import { useAsyncDebounce } from 'react-table'

export default function SimpleBooleanFilter({column:{filterValue, setFilter}, flatRows}) {
  
    const count = flatRows?.length || 0;
    const [value, setValue] = React.useState(filterValue)
    const onChange = useAsyncDebounce(value => {
        
        if (value === "true") {
            setFilter(true);
        } else if (value === "false") {
            setFilter(false);
        } else {
            setFilter(undefined);
        }
        
    }, 200)

    return (
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value || undefined);
          onChange(e.target.value || undefined);
        }}
        placeholder={`Sök bland ${count} rader`}
      />
    )
  }