import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as SC from "./styles";
// style, design

import { didLogout, login } from "../../store/app/actions";
import { resetPassword } from "../../store/users";
import useQuery from "../../components/utils/useQuery";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import Logo from "../../components/Layout/Sidebar/Logo";
import NonConnectedTextInput from "../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { PrimaryButton, TextButton } from "../../components/Forms/Base/Buttons";
import {
  OverviewSubtitle,
  OverviewTitle,
} from "../../components/Details/OverviewInfo/styles";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";

import { resetAllStates } from "../../store/utils";
import { getToastForLoginPage, removeToastForLoginPage } from "../../utils";

const STEPS = {
  LOGIN: "LOGIN",
  RESET_PASSWORD: "RESET_PASSWORD",
  DID_RESET: "DID_RESET",
  MFA: "MFA",
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = React.useState(STEPS.LOGIN);
  const [mfaCode, setMfaCode] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [resetEmail, setResetEmail] = React.useState("");

  const query = useQuery();

  const showLoggedOutBanner = query.get("loggedout");
  const next = query.get("next");

  const errorStatus = useSelector((state) => state.app.errorStatus);
  const isAuthenticated = useSelector((state) => !!state.app.authorization);
  const authenticating = useSelector((state) => state.app.authenticating);
  const isLoggingOut = useSelector((state) => state.app.isLoggingOut);

  React.useEffect(() => {
    let toastData = getToastForLoginPage();

    if (toastData) {
      dispatch(addToast(toastData));
      removeToastForLoginPage();
    }

    if (!isLoggingOut) return;
    //This might be uneccessary
    dispatch(didLogout());

    //not this.
    dispatch(resetAllStates());
  }, []);

  const openHomePage = () => {
    window.open("https://pigello.se", "_blank");
  };

  const openManagementPortal = () => {
    window.open("https://management.pigello.se");
  };
  const openTenantPortal = () => {
    window.open("https://portal.pigello.se");
  };

  const onError = React.useCallback(() => {
    if (errorStatus) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Felaktiga uppgifter",
          description:
            "Du har skrivit in ett felaktigt användarnamn eller lösenord.",
        })
      );
    }
  }, [errorStatus]);

  React.useEffect(() => onError(), [onError]);

  const handleResetPassword = () => {
    dispatch(resetPassword({ username, email: resetEmail }));
    setStep(STEPS.DID_RESET);
  };

  const mfaCallback = () => {
    if (mfaCode) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Felaktig 2-faktorskod",
          description: "Försök igen",
        })
      );
    } else {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "2-faktorsautentisering krävs",
        })
      );
    }
    setStep(STEPS.MFA);
  };

  const handleLogin = () => {
    dispatch(login(username, password, next, mfaCallback));
  };
  const handleMFALogin = () => {
    dispatch(login(username, password, next, mfaCallback, mfaCode));
  };

  React.useEffect(() => {
    if (showLoggedOutBanner) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Du loggades ut",
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push(next && !next.includes("invitation") ? next : "/");
    }
  }, [history, isAuthenticated]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (step === STEPS.RESET_PASSWORD) {
        handleResetPassword();
      } else if (step === STEPS.MFA) {
        handleMFALogin();
      } else {
        handleLogin();
      }
    }
  };

  return (
    <>
      <SC.LoginPageContainer>
        {authenticating && <OverlaySpinner />}
        <SC.LoginContentWrapper>
          <SC.LoginInnerContent>
            <Logo noBorder overrideClick={openHomePage} />
          </SC.LoginInnerContent>
          {step === STEPS.LOGIN && (
            <SC.LoginInnerContent>
              <NonConnectedTextInput
                extraStyles={{ minWidth: "60%", marginBottom: 12 }}
                value={username}
                onChange={(val) => setUsername(val)}
                label="Användarnamn"
                onKeyPress={handleKeyPress}
              />
              <NonConnectedTextInput
                type="password"
                extraStyles={{ minWidth: "60%", marginBottom: 12 }}
                value={password}
                onChange={(val) => setPassword(val)}
                label="Lösenord"
                onKeyPress={handleKeyPress}
              />

              <PrimaryButton
                extraStyle={{ minWidth: "60%" }}
                title="Logga in"
                clicked={handleLogin}
              />
              <TextButton
                extraStyle={{ marginTop: 12 }}
                title="Glömt lösenord?"
                clicked={() => setStep(STEPS.RESET_PASSWORD)}
              />
            </SC.LoginInnerContent>
          )}

          {step === STEPS.RESET_PASSWORD && (
            <SC.LoginInnerContent>
              <NonConnectedTextInput
                extraStyles={{ minWidth: "60%", marginBottom: 12 }}
                value={resetEmail}
                onChange={(val) => setResetEmail(val)}
                label="Din E-postadress hos Pigello"
                onKeyPress={handleKeyPress}
              />
              <NonConnectedTextInput
                extraStyles={{ minWidth: "60%", marginBottom: 12 }}
                value={username}
                onChange={(val) => setUsername(val)}
                label="Ditt användarnamn hos Pigello"
                onKeyPress={handleKeyPress}
              />

              <PrimaryButton
                extraStyle={{ minWidth: "60%" }}
                title="Återställ lösenord"
                clicked={handleResetPassword}
              />
              <TextButton
                extraStyle={{ marginTop: 12 }}
                title="Tillbaka till inloggning"
                clicked={() => setStep(STEPS.LOGIN)}
              />
            </SC.LoginInnerContent>
          )}

          {step === STEPS.DID_RESET && (
            <SC.LoginInnerContent>
              <OverviewTitle small>Kolla din E-post</OverviewTitle>

              <OverviewSubtitle
                style={{ textAlign: "center", maxWidth: "60%" }}
              >
                Om vi hittade en användare som matchar de uppgifterna du angav
                så har vi skickat en återställningslänk.
              </OverviewSubtitle>

              <PrimaryButton
                extraStyle={{ marginTop: 12 }}
                title="Tillbaka till inloggning"
                clicked={() => setStep(STEPS.LOGIN)}
              />
            </SC.LoginInnerContent>
          )}

          {step === STEPS.MFA && (
            <SC.LoginInnerContent>
              <OverviewTitle small>2-faktorsautentisering</OverviewTitle>

              <OverviewSubtitle
                style={{ textAlign: "center", maxWidth: "60%" }}
              >
                Skriv in koden från din 2-faktorsautentiseringsapp
              </OverviewSubtitle>

              <NonConnectedTextInput
                extraStyles={{
                  minWidth: "60%",
                  marginBottom: 12,
                  marginTop: 24,
                }}
                value={mfaCode}
                onChange={(val) => setMfaCode(val)}
                label="Kod för 2-faktorsautentisering"
                onKeyPress={handleKeyPress}
              />

              <PrimaryButton
                extraStyle={{ marginTop: 12 }}
                title="Logga in"
                clicked={() => handleMFALogin()}
              />
            </SC.LoginInnerContent>
          )}

          <SC.LoginInnerContent>
            <div>
              <TextButton
                title='Hyresgäst eller BRF-medlem? Gå till "Mina sidor"'
                iconType="arrow"
                iconPlacement="right"
                clicked={openTenantPortal}
              />
            </div>

            <div style={{ marginTop: 12 }}>
              <TextButton
                title='Förvaltare? Gå till "Pigello förvaltningsportal"'
                iconType="arrow"
                iconPlacement="right"
                clicked={openManagementPortal}
              />
            </div>
          </SC.LoginInnerContent>
        </SC.LoginContentWrapper>
        <SC.BackgroundImage>
          <SC.BackgroundImageFilter />
        </SC.BackgroundImage>
      </SC.LoginPageContainer>
    </>
  );
};
