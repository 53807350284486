import * as React from "react";

import { Confirm, Setting, Statuses } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "SETTING":
      return <Setting {...{ method }} />;
    case "STATUSES":
      return <Statuses {...{ method }} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
