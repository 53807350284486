import styled, { css } from "styled-components";

export const InfoTable = styled.div`
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: 24px 0;

  ${(p) =>
    p.flexed &&
    css`
      flex: 1;

      &:first-child {
        margin-right: 24px;
      }
    `}
`;

export const InfoTableTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #eceff1;
  padding: 12px;

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

export const InfoTableTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoTableTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #12263f;
  margin-bottom: 6px;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

export const InfoTableSubtitle = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
`;

export const InfoTableContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
`;

export const InfoTableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #fff;

  &:nth-child(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ${(p) =>
    p.invalid &&
    css`
      -webkit-box-shadow: inset 0px 0px 5px 0px rgba(207, 0, 28, 1);
      -moz-box-shadow: inset 0px 0px 5px 0px rgba(207, 0, 28, 1);
      box-shadow: inset 0px 0px 5px 0px rgba(207, 0, 28, 1);
    `}
`;

export const InfoTableRowTitle = styled.div`
  font-weight: 500;
  color: #12263f;
  flex: 1;
`;

export const InfoTableRowValue = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
