import * as React from "react";

import { Select, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/tenantPortalSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Aptus SSO integration</OverviewTitle>
          <OverviewSubtitle>
            Fyll i uppgifterna nedan för att låsa upp SSO inlogg för hyresgästerna genom portalen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        method={method}
        title="Url"
        fieldKey="apptus_sso_host"
        description="Inkludera inga query-parametrar, inkludera inte ? som suffix i url:en"
      />

      <Select
        method={method}
        storeName={storeName}
        fieldKey="apptus_sso_customer_name_field"
        title="Vilket fält som ska användas för att generera SSO länkar"
      />

    <TextInput
        storeName={storeName}
        method={method}
        title="Hash nyckel"
        fieldKey="apptus_sso_hash_key"
        description="Hash nyckeln som används för att generera SSO länkar. Detta värdet kommer inte vara synligt för någon senare"
      />
    </>
  );
};
