import * as React from "react";
import { constants } from "../../../../../store/inspectionErrandSettings";
import { Select, TextInput } from "../../../Base/Fields";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ComponentTypesTable from "src/components/Tables/ErrandComponentTypes/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Typ av besiktning</OverviewTitle>
          <OverviewSubtitle>
            Ange ett namn för besiktningstypen och vilken typ av ärende
            inställningen gäller.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey={"title"}
        title="Typ av besiktning"
        description={`Beskriv vilken typ av besiktning som ingår i denna inställning.`}
        alwaysShowDescription
      />

      <Select
        menuPlacement="top"
        storeName={storeName}
        method={method}
        fieldKey={"category"}
        title="Kategori"
        description={`Koppla besiktningstypen till en specifik kategori, lämnas den tom gäller kategorin "Övrig". Det innebär att besiktningstypen kan gälla oavsett specifierad plats/objekt.`}
      />

      <div className="grid grid-cols-1 gap-6 mb-6">
        <TableSelectField
          TableComponent={ComponentTypesTable}
          fieldKey="exclude_component_types"
          title="Exkludera komponenter av denna typ..."
          description="Besiktningar av denna typ kommer att exkludera komponenter som är av dessa typer. Detta är användbart för att specificera olika typer av besiktningar som ska innehålla olika typer av komponenter."
          method={method}
          storeName={storeName}
          placeholder="Välj komponenttyper att exkludera..."
          isMany
        />
      </div>
    </>
  );
};
