export default ({}) => [
  {
    title: "Utskick",
    key: "SENDOUT",
    visited: true,
    hasError: false,
    fieldKeys: ["title", "message"],
  },
  {
    title: "Inställningar",
    key: "SETTINGS",
    visited: false,
    hasError: false,
    fieldKeys: ["scheduled_for", "pinned"],
  },
  {
    title: "Mottagare",
    key: "RECIPIENTS",
    visited: false,
    hasError: false,
    fieldKeys: ["recipients", "buildings", "realestates"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
