import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state

import { constants, detailUrl } from "../../../store/keypermission";
import { useAllPermissionCheck } from "../../../store/base";

export default function KeyPermissionsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const canParakey = useAllPermissionCheck([
    "allow_parakey",
    "view_can_parakey",
  ]);

  const columns = React.useMemo(
    () => columnDefs(canParakey),
    [persistantQuery, canParakey]
  );

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "keypermission_setting_full_table"}
      title={"Nycklar"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
