import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../components/Displays";
import { TextButton } from "../../components/Forms/Base/Buttons";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import { ALL_VIEW_PERM_MAP, ALL_CUSTOMER_PERM_MAP } from "./allViewPermMap";

export default function PermissionDeniedPage() {
  const query = useQuery();

  const perms = query.get("perms")?.split(",");

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(
      perms?.map((p) => ALL_VIEW_PERM_MAP[p] || p).join(", ")
    );
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <InfoBox boxTheme="warning" title="Åtkomst nekad" />
        <OverviewTitleWrapper style={{ marginTop: 24 }}>
          <OverviewTitle> Behörighet saknas</OverviewTitle>
        </OverviewTitleWrapper>

        <div>
          Din användare saknar behörighet att se informationen på denna sida.
          Kontakta systemadmin för att låsa upp behörighet om du ska ha tillgång
          till informationen.
          <br />
          <br />
          <hr />
          Behörigheter som saknas är:
        </div>

        <ul
          style={{
            marginTop: 24,
            listStyleType: "square",
            listStylePosition: "outside",
            paddingLeft: 24,
          }}
        >
          {perms?.map((p) => (
            <li key={p}>{ALL_VIEW_PERM_MAP[p] || p}</li>
          ))}
        </ul>

        <TextButton
          extraStyle={{ marginTop: 24 }}
          title="Kopiera behörigheter till urklipp"
          iconType="copy"
          iconPlacement="right"
          clicked={copyToClipBoard}
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
