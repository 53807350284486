import * as React from "react";
import { SvgProxy } from "react-svgmt";
import { PanZoom } from "react-easy-panzoom";
import axios from "axios";

import generateProxies from "./proxies";
import useResizeObserver from "../../hooks/useResizeObserver";
import {
  BlueprintWrapper,
  ControllerBox,
  ControllerWrapper,
  StyledSVGLoader,
} from "./BlueprintDisplay/styles";

import zoomInIcon from "../../assets/svg/zoom-in.svg";
import zoomOutIcon from "../../assets/svg/zoom-out.svg";
import rotateRightIcon from "../../assets/svg/rotate-right.svg";
import rotateLeftIcon from "../../assets/svg/rotate-left.svg";
import { InnerBox } from "../sharedStyles";

import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import { BLUEPRINT_PREMISE_OPTIONS } from "./BlueprintDisplay/Controller";

const viewBoxRegex = new RegExp('(?:viewbox=")([^"]+)(?=#*)');

export default ({ blueprint, premises, rooms, premisesType }) => {
  const dispatch = useDispatch();
  const panZoomRef = React.useRef();
  const containerRef = React.useRef();
  const [rotation, setRotation] = React.useState(0);
  const [pageDimensions, setPageDimensions] = React.useState(false);
  const [SVGLoaded, setSVGLoaded] = React.useState(false);
  const [SVG, setSVG] = React.useState(null);
  const [SVGViewBox, setSVGViewBox] = React.useState(null);

  const getSvgXml = async () => {
    try {
      const svg = await axios.get(blueprint.svg_file?.get, {
        headers: {
          "cross-origin": "Anonnymous",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const svgXML = svg.data;
      const viewBox = svgXML.match(viewBoxRegex)[1];

      setSVGViewBox(viewBox);
      setSVG(svgXML);
      setSVGLoaded(true);
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel",
          description: "Planritningen kunde inte hämtas",
        })
      );
    }
  };

  const getHeight = () => {
    if (containerRef?.current) {
      // 90 is blueprint menu height
      const height =
        window.innerHeight / 1.5 - containerRef?.current.offsetTop - 90;

      return height;
    } else {
      return window.innerHeight / 1.5 - 150;
    }
  };

  const getDimensions = () => {
    if (containerRef?.current) {
      const { height, width } = containerRef.current.getBoundingClientRect();

      setPageDimensions({ height, width });
    }
  };

  const resizeCallback = (event) => {
    if (!event.length) return;
    const { contentRect } = event[0];

    if (!contentRect) return;
    const { width, height } = contentRect;
    setPageDimensions({ width, height });
  };

  React.useEffect(() => {
    getSvgXml();

    return () => {
      setSVGLoaded(false);
      setSVG(null);
    };
  }, [blueprint.svg_file]);

  React.useEffect(() => {
    getDimensions();

    return () => {
      setPageDimensions(false);
    };
  }, []);

  const handleZoomIn = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn(5);
    }
  };

  const handleZoomOut = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn(-5);
    }
  };

  const rotateRight = () => {
    setRotation(rotation + 90);
  };

  const rotateLeft = () => {
    setRotation(rotation - 90);
  };

  useResizeObserver({ callback: resizeCallback, element: containerRef });

  const proxies = generateProxies.init({
    currentRooms: rooms,
    currentBuildingPremises: [premises],
    handleBlueprintState: "PASSIVE",
    selectedPremises: premises,
    premisesType,
    selectedRooms: rooms
      ?.filter((r) => {
        if (premisesType === BLUEPRINT_PREMISE_OPTIONS.APARTMENT) {
          return r.apartment?.id == premises?.id;
        } else if (
          premisesType === BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES
        ) {
          return r.industrial_premises?.id == premises?.id;
        } else {
          return r.common_area?.id == premises?.id;
        }
      })
      ?.map((r) => r.id),
    BLUEPRINT_STATES: {
      PASSIVE: "PASSIVE",
      CREATING: "CREATING",
      EDITING: "EDITING",
    },
  });

  const Proxy = (data) => {
    return (
      <SvgProxy
        key={data.selector}
        selector={data.selector}
        style={data.style}
      />
    );
  };

  return (
    <>
      <InnerBox>
        <BlueprintWrapper height={getHeight()} ref={containerRef}>
          <ControllerWrapper>
            <ControllerBox onClick={() => handleZoomIn()} src={zoomInIcon} />
            <ControllerBox onClick={() => handleZoomOut()} src={zoomOutIcon} />
            <ControllerBox
              onClick={() => rotateRight()}
              src={rotateRightIcon}
            />
            <ControllerBox onClick={() => rotateLeft()} src={rotateLeftIcon} />
          </ControllerWrapper>
          {pageDimensions && SVGLoaded ? (
            <PanZoom
              className="panzoom"
              style={{ height: "100%", zIndex: 0, width: "100%" }}
              ref={panZoomRef}
              autoCenter={true}
              autoCenterZoomLevel={1}
              boundaryRatioVertical={0.5}
              boundaryRatioHorizontal={0.5}
              enableBoundingBox
              minZoom={0.1}
              maxZoom={3}
              disableScrollZoom={true}
            >
              <StyledSVGLoader
                svgXML={SVG}
                viewBox={SVGViewBox}
                height={pageDimensions.height}
                width={pageDimensions.width}
                preserveAspectRatio="xMinYMin meet"
                rotation={rotation}
              >
                {proxies && proxies.map(Proxy)}
              </StyledSVGLoader>
            </PanZoom>
          ) : null}
        </BlueprintWrapper>
      </InnerBox>
    </>
  );
};
