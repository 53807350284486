import * as React from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../store/base";
import {
  useRoundingErrandSetting,
  constants,
  update,
} from "../../../store/roundingErrandSettings";
import { useUser, detailUrl as userDetailUrl } from "../../../store/users";
import { useFilteredNotes } from "../../../store/notes";

import NextErrandInfo from "../../../components/Details/RoundingErrandSetting/NextErrandInfo";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";

import { LinkedObject } from "../../../components/Displays";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import RoundingAreasTable from "src/components/Tables/RoundingAreas/FullTable";
import { getRoundingIntervalStr } from "src/views/RoundingErrands/settingdetail/Overview";

export default function GovErrandSettingOverview() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { settingId } = useParams();

  const [roundingErrandSetting, roundingErrandSettingLoading] =
    useRoundingErrandSetting(settingId);

  const [autoGenOn, setAutoGenOn] = React.useState(false);
  const [pausedLoading, setPausedLoading] = React.useState(false);

  const notesQ = buildQueryString({
    id__in: roundingErrandSetting?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const [trustee] = useUser(roundingErrandSetting?.responsible_trustee?.id);

  const preProcess = () => {
    const clone = cloneDeep(roundingErrandSetting);
    if (clone.paused) {
      clone.paused = false;
    } else {
      clone.paused = true;
    }
    return clone;
  };

  const onSuccess = (data, returnedData) => {
    setPausedLoading(false);
    setAutoGenOn(returnedData?.paused);
  };

  const handleTogglePaused = () => {
    setPausedLoading(true);
    dispatch(
      update({
        id: roundingErrandSetting?.id,
        preProcess: preProcess,
        successCallback: onSuccess,
        errorCallback: () => setPausedLoading(false),
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({ storeName, data: roundingErrandSetting })
    );
    if (roundingErrandSetting) {
      setAutoGenOn(roundingErrandSetting?.paused);
    }
  }, [roundingErrandSetting]);

  return (
    <>
      {pausedLoading && <OverlaySpinner />}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Ronderingsschema</OverviewTitle>
          </OverviewTitleWrapper>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, marginRight: 12 }}>
              <DetailInfo
                infoObj={getInfoObj(roundingErrandSetting, trustee)}
                extraChapterStyles={{ marginRight: 0 }}
              />
              <InnerBox>
                <Notes
                  notes={notes}
                  contentType={"errands.roundingerrandsetting"}
                  objectId={roundingErrandSetting?.id}
                  title="Anteckningar"
                />
              </InnerBox>
            </div>
            <div style={{ flex: 1, flexDirection: "column" }}>
              <NextErrandInfo
                setting={roundingErrandSetting}
                autoGenOn={autoGenOn}
                onToggle={handleTogglePaused}
              />
            </div>
          </div>
        </DetailPageBox>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>
              Områden som ingår i myndighetskrav
            </OverviewTitle>
          </OverviewTitleWrapper>

          <RoundingAreasTable
            persistantQuery={{
              id__in: roundingErrandSetting?.areas?.map((a) => a.id) || [],
            }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const getResponsibleTrustee = (trustee) => {
  if (trustee) {
    return <LinkedObject obj={trustee} urlMethod={userDetailUrl} />;
  }
  return <div>Ingen ansvarig hittades</div>;
};

function getInfoObj(roundingErrandSetting, trustee) {
  let data = [
    {
      title: "Typ av krav",
      value: roundingErrandSetting?.kind_display,
    },
    {
      title: "Titel",
      value: roundingErrandSetting?.str_representation,
    },
    {
      title: "Typ av interval",
      value: roundingErrandSetting?.month_interval
        ? "Månadsintervall"
        : "Veckointervall",
    },
    {
      title: "Intervall",
      value: getRoundingIntervalStr(roundingErrandSetting),
    },
    {
      title: "Förväntat slutförande inom",
      value: `${
        roundingErrandSetting?.expected_duration_months
          ? `${roundingErrandSetting?.expected_duration_months} mån`
          : `${roundingErrandSetting?.expected_duration_days} dagar`
      }`,
    },
    {
      title: "Startdatum",
      value: roundingErrandSetting?.start_date,
    },
    {
      title: "Generas",
      value: roundingErrandSetting?.generate_from_previously_finished
        ? "Utifrån tidigare ärende"
        : "Strikt utifrån intervall",
    },
    {
      title: "Ansvarig förvaltare",
      value: getResponsibleTrustee(trustee),
    },
    {
      title: "Prioriterad roll",
      value: roundingErrandSetting?.performers?.[0]?.str_representation || "-",
    },
  ];

  const infoObj = {
    Information: data,
  };

  return infoObj;
}
