import {
  ExclamationTriangleIcon,
  FolderPlusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { cloneDeep, get } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

import Modal from "../Modals/Modal";
import { formatError } from "./utils";

export default function TableSelectFieldWithCreate({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  placeholder,
  title,
  description,
  forceError,
  forceRequired,
  forceInstructions,
  changeCallback,
  disabled,
  persistantQuery,
  TableComponent,
  keepOpenOnSelect = false,
  children,
  createTitle,
  createDisplayKey,
  createRenderFunction,
  isMany,
  displayKey = "str_representation", // key on selected object to display if isMany
}) {
  const dispatch = useDispatch();
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const [createNestledOpen, setCreateNestledOpen] = React.useState(false);
  const [createNestledManyIndex, setCreateManyNestledIndex] =
    React.useState(null);

  const renderChildrenWithParentPath = () => {
    const parentPath = `${fieldKey}${
      isMany ? `[${createNestledManyIndex}]` : ""
    }`;

    return children(parentPath, instructionsKey || fieldKey);
  };

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || forceInstructions;
  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey });

  const onChange = (selected) => {
    if (isMany) {
      let valueClone = cloneDeep(value);

      if (!valueClone) {
        valueClone = [selected];
      } else if (valueClone.find((s) => s.id === selected.id)) {
        valueClone = valueClone.filter((s) => s.id !== selected.id);
      } else {
        valueClone.push(selected);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [fieldKey]: valueClone,
          },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [fieldKey]: selected,
          },
        })
      );
    }

    if (!keepOpenOnSelect && !isMany) {
      setPickerOpen(false);
    }
    if (changeCallback) {
      changeCallback(selected);
    }
  };

  const onRemoveIdx = (idx) => {
    let valueClone = cloneDeep(value);

    valueClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: valueClone,
        },
      })
    );
  };

  const checkRowHighlighted = (original) => {
    if (isMany) {
      return value?.some((v) => v.id === original.id);
    } else {
      let match = value?.id === original.id;

      return match;
    }
  };

  const onClear = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: isMany ? [] : instructions?._allowNull ? null : undefined,
        },
      })
    );

    changeCallback &&
      changeCallback(isMany ? [] : instructions?._allowNull ? null : undefined);
  };

  const onInitiateCreate = () => {
    if (isMany) {
      setCreateManyNestledIndex(value?.length || 0);
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [fieldKey]: null,
          },
        })
      );
    }

    setCreateNestledOpen(true);
  };

  const renderTitle = () => {
    if (isMany) {
      if (value?.length === 1) {
        return (
          <div className="flex items-center max-w-[90%] whitespace-nowrap text-ellipsis">
            <div
              onClick={
                !value[0]?.id
                  ? (e) => {
                      e.stopPropagation();
                      setCreateManyNestledIndex(0);
                      setCreateNestledOpen(true);
                    }
                  : undefined
              }
              className={classNames("mr-1", {
                "cursor-pointer text-primaryblue underline": !value?.[0]?.id,
              })}
            >
              {value[0]?.id
                ? get(value[0], displayKey)
                : createRenderFunction
                ? createRenderFunction(value[0])
                : get(value[0], createDisplayKey || displayKey)}{" "}
            </div>
          </div>
        );
      } else {
        return <div>{placeholder || title}</div>;
      }
    } else {
      return (
        <div className="max-w-[90%] whitespace-nowrap text-ellipsis">
          {value
            ? !value?.id
              ? createRenderFunction
                ? createRenderFunction(value)
                : get(value, createDisplayKey) || "Nytt objekt"
              : value?.str_representation
            : placeholder || title}
        </div>
      );
    }
  };

  if (!instructions || instructions?._readOnly) return null;

  return (
    <>
      <div>
        <div>
          <div className="font-medium text-gray-900 text-sm flex items-center">
            {(error || forceError) && (
              <ExclamationTriangleIcon
                width={16}
                className="text-red-600 mr-1"
              />
            )}{" "}
            {title}
            {instructions._required || forceRequired ? "*" : ""}
          </div>
          {description && (
            <div className="text-xs font-normal text-gray-500 bg-transparent mb-1 ">
              {description}
            </div>
          )}
        </div>

        {(error || forceError) && (
          <div className="text-xs font-normal text-red-600 mb-1">
            {formatError(forceError || error)}
          </div>
        )}
        <div
          className={classNames(
            "inline-flex rounded w-full max-w-full items-center border border-solid border-slate-300 text-gray-900  ",
            isMany && value?.length > 1 && "rounded-bl-none"
          )}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              if (value && !isMany && !value?.id) {
                // only open edit on click if not is many
                setCreateNestledOpen(true);
              } else {
                setPickerOpen(true);
              }
            }}
            disabled={disabled}
            className={`inline-flex max-w-[calc(100%-100px)] flex-[2_2_0%] whitespace-nowrap ${
              disabled
                ? "bg-slate-200 opacity-80 cursor-not-allowed"
                : "bg-white"
            } items-center  text-gray-900 rounded-l focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center `}
          >
            {renderTitle()}
            {(isMany && value?.length) || (!isMany && value) ? (
              <XMarkIcon
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
                width={16}
                className="ml-auto"
              />
            ) : (
              <FolderPlusIcon width={16} className="ml-auto" />
            )}
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              onInitiateCreate();
            }}
            className={classNames(
              "inline-flex w-[100px]  flex-shrink-0 whitespace-nowrap items-center border-l border-t-0 border-r-0 border-b-0 border-slate-300  rounded-r focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-primaryblue/80  focus:outline-none text-sm p-2.5 text-center",
              disabled
                ? "bg-slate-400 opacity-80 cursor-not-allowed text-gray-900"
                : "bg-slate-700 text-white"
            )}
          >
            Skapa ny
            <PlusIcon width={16} className="ml-1" />
          </button>
        </div>
        {isMany && value?.length > 1 && (
          <div className="flex flex-col w-[calc(100%-100px)] border border-t-0 border-solid border-slate-300 rounded-b overflow-hidden">
            {value?.map((v, idx) => (
              <div className="border-b cursor-default border-slate-200 p-2 flex justify-between bg-slate-50 hover:bg-blue-100 last-of-type:border-b-0">
                <div
                  onClick={
                    !v?.id
                      ? (e) => {
                          e.stopPropagation();
                          setCreateManyNestledIndex(idx);
                          setCreateNestledOpen(true);
                        }
                      : undefined
                  }
                  className={classNames("text-sm", {
                    "cursor-pointer text-primaryblue underline": !v.id,
                  })}
                >
                  {v?.id
                    ? get(v, displayKey)
                    : createRenderFunction
                    ? createRenderFunction(v)
                    : get(v, createDisplayKey || displayKey)}
                </div>
                <XMarkIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveIdx(idx);
                  }}
                  width={16}
                  className="ml-auto cursor-pointer"
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {createNestledOpen && (
        <Modal
          closeFunction={() => setCreateNestledOpen(false)}
          title={createTitle || "Skapa ny"}
        >
          {renderChildrenWithParentPath()}
        </Modal>
      )}

      {pickerOpen && (
        <Modal closeFunction={() => setPickerOpen(false)} title={placeholder}>
          <TableComponent
            ignoreLocalStorage
            {...{
              persistantQuery,
              checkRowHighlighted,
              onRowClicked: (original) => onChange(original),
            }}
          />
        </Modal>
      )}
    </>
  );
}
