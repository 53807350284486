import * as React from "react";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ErrandRoleUsersTable from "src/components/Tables/RoleUsers/FullTable";

import { constants } from "../../../../../store/inspectionErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import DurationField from "../../../Base/Fields/DurationField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Delegering av ärende</OverviewTitle>
          <OverviewSubtitle>
            Delegera vem som ska utföra ärendet.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="performer"
          title="Utförare (hård delegering)"
          placeholder="Välj utförare..."
          description="Hård delegering innebär att utföraren sätts på ärendet utan att förfrågas först."
          TableComponent={ErrandRoleUsersTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="requested_performer"
          title="Förfrågad utförare (mjuk delegering)"
          placeholder="Välj utförare..."
          description="Mjuk delegering innebär att utföraren förfrågad om att utföra ärendent och kan välja att acceptera eller neka via Pigellos web-portal eller App."
          TableComponent={ErrandRoleUsersTable}
        />
      </div>

      <DurationField
        storeName={storeName}
        method={method}
        title="Påminn utförare så här lång tid innan planerad start"
        fieldKey="remind_executor_duration"
        menuPlacement="top"
      />
    </>
  );
};
