import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import LeaseContractsTable from "../../../components/Tables/LeaseContracts/FullTableNewVersion";

import { PERSISTANT_CATEGORY_KEY } from "./LeaseContracts";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";

export default function LeaseContractDrafts() {
  const { filteredRealEstates } = useActiveFilters();

  const draftPersistantQuery = {
    realestate__in: filteredRealEstates,
    draft: true,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Utkast av hyresavtal</OverviewTitle>
          </OverviewTitleWrapper>

          <LeaseContractsTable persistantQuery={draftPersistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
