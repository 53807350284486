import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingParking";
import {
  useFormInstanceField,
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../../../store/base";

import {
  constants as invoicingSettingConstants,
  useInvoicingSettingPagination,
  performFilter,
  constructStrRep,
  useInvoicingSetting,
} from "../../../../../store/invoicingSettings";
import listDefs from "../../../../Tables/InvoicingSettings/listDefs";
import InvoicingSettingSummary from "../../../../Summaries/InvoicingSettingSummary";
import { RadioGroup, TableSelect } from "../../../Base/Fields";

import {
  constants as companyConstants,
  useCompanyPagination,
  performFilter as companyPerformFilter,
  constructStrRep as companyConstructStrRep,
} from "../../../../../store/companies";
import companyListDefs from "../../../../Tables/Companies/listDefs";

import {
  constants as imdSettingConstants,
  useInvoicingIMDPagination,
  performFilter as imdPerformFilter,
  constructStrRep as imdConstructStrRep,
} from "../../../../../store/invoicingIMDSettings";
import imdListDefs from "../../../../Tables/InvoicingIMDSettings/listDefs";

import { cloneDeep } from "lodash";
import InvoicingSettingDefault from "../../../../Summaries/InvoicingSettingDefault";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import { InnerBox } from "../../../../sharedStyles";
import { useParams } from "react-router-dom";
import { useParkingContract } from "../../../../../store/parkingContracts";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";
import IMDSettingsTable from "src/components/Tables/InvoicingIMDSettings/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();

  const storeName = constants.STORE_NAME;

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const imdSetting = useFormInstanceField({
    storeName,
    fieldKey: "imd_setting",
  });

  const [existingSetting] = useInvoicingSetting(defaultSetting?.id);

  const { parkingContractId } = useParams();

  const [parkingContract] = useParkingContract(parkingContractId);

  return (
    <>
      <FormAreaTitle>Aviseringsinställning</FormAreaTitle>

      {!!parkingContract?.main_contract && (
        <RadioGroup
          title="Avisera för detta avtal tillsammans med huvudavtalet"
          {...{ storeName, fieldKey: "bill_with_lease", method }}
          defaultValue={true}
          options={[
            { label: "Samavisera med huvudavtalet", value: true },
            { label: "Avisera detta avtal separat", value: false },
          ]}
        />
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"billing_company"}
          placeholder="Välj fakturerande bolag..."
          title="Fakturerande bolag"
          description={
            "Välj vilket bolag detta avtal ska aviseras ifrån. Om inget bolag väljs kommer bolaget som hyresobjektet tillhör att användas"
          }
          TableComponent={CompanyTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"setting"}
          placeholder="Välj aviseringsinställning..."
          title="Aviseringsinställning"
          description={
            "Välj vilken basinställning för avisering som ska användas för avtalet"
          }
          TableComponent={InvoicingSettingTable}
        />
      </div>

      <InnerBox style={{ marginBottom: 24 }}>
        {imdSetting != null || !existingSetting?.imd_setting ? (
          <>
            {existingSetting?.imd_setting != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  instanceClone.imd_setting = null;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                method={method}
                fieldKey={"imd_setting"}
                placeholder="Välj inställning..."
                title="Debiteringsinställning för IMD"
                description={
                  "Välj vilken basinställning för debitering av IMD som ska användas för avtalet"
                }
                TableComponent={IMDSettingsTable}
              />
            </div>
          </>
        ) : (
          <InvoicingSettingDefault
            title="Inställning för IMD-debitering"
            content={[
              {
                title: "Inställning",
                content: existingSetting?.imd_setting?.str_representation,
              },
            ]}
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    imd_setting: {},
                  },
                })
              );
            }}
          />
        )}
      </InnerBox>

      {defaultSetting?.id && (
        <>
          {existingSetting ? (
            <InvoicingSettingSummary setting={existingSetting} />
          ) : null}
        </>
      )}
    </>
  );
};
