import * as React from "react";
import LocalCheckField from "./LocalCheckField";

export default function LocalCheckManyField({
  values,
  onDone,
  onClose,
  choices,
  enabled = true,
  className,
  forceHasUpdated = false,
  onChange,
  getValues,
  callDoneOnClose = false,
  removeUpdateOnlyOnDone = false,
}) {
  // console.log("values", values);
  const [selectedStates, setSelectedStates] = React.useState(values);
  // console.log("selectedStates", selectedStates);

  const selectedStatesRef = React.useRef(selectedStates);

  React.useEffect(() => {
    selectedStatesRef.current = selectedStates;
  }, [selectedStates]);

  const updateStatesForClose = (vals) => {
    // console.log("updateStatesForClose");
    setPrevRenderStates(buildRenderStates(selectedStates));

    setSelectedStates(vals);

    setRenderStates(buildRenderStates(vals));
  };

  const enabledRef = React.useRef(enabled);

  const dropdownRef = React.useRef();
  const doneBtnRef = React.useRef();

  React.useEffect(() => {
    enabledRef.current = enabled;
  }, [enabled]);

  const close = () => {
    if (callDoneOnClose) {
      onClose();
      done();
      return;
    }

    if (!getValues) {
      onClose(selectedStates);
      return;
    }
    updateStatesForClose(getValues());
    onClose(selectedStates);
  };

  const clickListener = React.useCallback(
    (evt) => {
      if (!enabledRef.current) return;

      let clickedOutsideDropdown = true;

      for (let child of dropdownRef.current.children) {
        if (evt.target === child) {
          clickedOutsideDropdown = false;
          break;
        }
      }

      if (clickedOutsideDropdown) {
        if (doneBtnRef.current === evt.target) clickedOutsideDropdown = false;
      }

      if (clickedOutsideDropdown) {
        close();
      }
    },
    [enabled]
  );

  React.useEffect(() => {
    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, []);

  const buildRenderStates = (values = undefined) => {
    let states = values ? values : selectedStates;

    // console.log("states buildRenderStates", states);

    return choices.map((obj) => {
      if (states && states.includes(obj.value)) return true;
      return false;
    });
  };

  const [renderStates, setRenderStates] = React.useState(buildRenderStates());

  //Probably never needs to be set.
  const [prevRenderStates, setPrevRenderStates] = React.useState(
    buildRenderStates()
  );

  const renderStatesRef = React.useRef(renderStates);
  const prevRenderStatesRef = React.useRef(prevRenderStates);

  const rebuildRenderStates = (force = false, values = undefined) => {
    let states = buildRenderStates(values);

    // console.log("new states", states);

    //TODO: Why did this work? (to comment this out)
    // setSelectedStates([]);
    setRenderStates(states);
    if (removeUpdateOnlyOnDone && !force) return;
    setPrevRenderStates(states);
  };

  React.useEffect(() => {
    rebuildRenderStates();
  }, [choices]);

  React.useEffect(() => {
    renderStatesRef.current = renderStates;
    let newSelectedArray = [];

    for (let index in renderStates) {
      if (renderStates[index] === true) {
        newSelectedArray.push(choices[index].value);
      }
    }

    if (
      !selectedStates ||
      newSelectedArray.toString() !== selectedStates.toString()
    ) {
      setSelectedStates(newSelectedArray);
    }
  }, [renderStates]);

  const hasUpdatedRenderState = React.useMemo(() => {
    if (forceHasUpdated) return true;
    let same = true;

    for (let index in renderStates) {
      if (renderStates[index] !== prevRenderStates[index]) {
        same = false;
        break;
      }
    }

    return !same;
  }, [renderStates]);

  const done = () => {
    let selected_items = [];

    for (let index in renderStatesRef.current) {
      if (
        renderStatesRef.current[index] !== prevRenderStatesRef.current[index]
      ) {
        selected_items.push(choices[index]);
      }
    }

    // console.log("calling onDone", selectedStatesRef.current);

    if (removeUpdateOnlyOnDone) {
      rebuildRenderStates(true, getValues());
    }

    onDone(
      selectedStatesRef.current,
      selected_items,
      renderStatesRef.current,
      prevRenderStatesRef.current
    );
  };

  const clickedOption = (val, index) => {
    let newRenderStates = renderStates.slice();
    newRenderStates[index] = val;
    setRenderStates(newRenderStates);
    if (onChange) {
      let selected_items = [];

      for (let index in newRenderStates) {
        if (newRenderStates[index] === true) {
          selected_items.push(choices[index].value);
        }
      }

      onChange(selected_items, newRenderStates);
    }
  };

  const renderOptions = React.useMemo(() => {
    return choices.map((obj, index) => {
      // if (!obj) return undefined;
      return (
        <div
          onClick={() => clickedOption(!renderStates[index], index)}
          className="w-full p-4 border-t border-r border-l whitespace-normal bg-white break-all border-solid border-gray-200 cursor-pointer transition-colors hover:bg-blue-200"
          key={index}
        >
          <LocalCheckField
            className="pointer-events-none"
            value={renderStates[index]}
            onChange={(val) => {}}
            title={obj.name}
          />
        </div>
      );
    });
  }, [renderStates]);

  return (
    <div
      className={`w-full left-0 absolute z-20 top-18 border-b border-gray-200 ${
        !enabled && "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`overflow-y-auto max-h-[400px] overflow-x-hidden ${
          className && className
        }`}
        ref={dropdownRef}
      >
        {renderOptions.length !== 0 ? (
          renderOptions
        ) : (
          <div className="w-full p-4 border-t border-r border-l border-solid border-gray-200">
            <p>Inget att välja</p>
          </div>
        )}
      </div>
      {hasUpdatedRenderState && enabled && (
        <div
          onClick={(evt) => {
            evt.stopPropagation();
            if (callDoneOnClose) {
              close();
              return;
            }
            done();
          }}
          className="absolute bottom-[-3.5rem] left-0 w-full h-14 cursor-pointer bg-[#5165FB] flex items-center justify-center transition-colors hover:bg-blue-600"
          ref={doneBtnRef}
        >
          <p className="text-white">Klar</p>
        </div>
      )}
    </div>
  );
}
