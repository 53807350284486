export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_FORTNOXCONFIG",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_FORTNOXCONFIG",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_FORTNOXCONFIG",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_FORTNOXCONFIG",
  DESTROY_FORM: "DESTROY_FORM_FORTNOXCONFIG",
  SET_FORM_ERROR: "SET_FORM_ERROR_FORTNOXCONFIG",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_FORTNOXCONFIG",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_FORTNOXCONFIG",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_FORTNOXCONFIG",
  RESET_STATE: "RESET_STATE_FORTNOXCONFIG",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_FORTNOXCONFIG",
  REMOVE_OBJECT: "REMOVE_OBJECT_FORTNOXCONFIG",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_FORTNOXCONFIG",
  CLEAR_FETCHED: "CLEAR_FETCHED_FORTNOXCONFIG",

  LIST_URL: "/accounting/fortnox/fortnoxconfig/list/",
  POST_URL: "/accounting/fortnox/fortnoxconfig/",
  PATCH_URL: "/accounting/fortnox/fortnoxconfig/",
  GET_URL: "/accounting/fortnox/fortnoxconfig/",

  STORE_NAME: "fortnoxConfig",
};
