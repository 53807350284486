import * as React from "react";

// style, design
import BaseTable from "../../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./errandConfigListDefs";

// store, state
import { constants } from "../../../../store/parkingSpots";
import { useFilteredRealEstates } from "../../../../store/realEstates";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  onExcludeClicked,
}) => {
  const [realEstates] = useFilteredRealEstates("");

  const columns = React.useMemo(
    () => columnDefs({ onExcludeClicked, realEstates }),
    [persistantQuery, onExcludeClicked, realEstates]
  );

  const filters = {};
  const badges = {};

  return (
    <BaseTable
      tableId={tableId || "parking_errand_full_table"}
      title={"Fordonsplatser"}
      // onRowClickedWithDetail={(obj) =>
      //   detailUrl({
      //     id: obj.id,
      //   })
      // }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
