import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import {
  Checkbox,
  NumberInput,
  RadioGroup,
  Select,
  TextInput,
} from "../../../Base/Fields";
import { constants } from "../../../../../store/marketRequirements";
import { InnerBox } from "../../../../sharedStyles";
import { useFormInstanceField } from "../../../../../store/base";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const selectedRentCalcType = useFormInstanceField({
    storeName,
    fieldKey: "rent_calculation_type",
  });

  const warrantorAllowed = useFormInstanceField({
    storeName,
    fieldKey: "warrantor",
  });

  return (
    <>
      <FormAreaTitle>Inkomst & Arbete</FormAreaTitle>

      <TextInput
        fieldKey="name"
        storeName={storeName}
        method={method}
        title="Titel på kravprofil"
      />

      <Select
        storeName={storeName}
        method={method}
        fieldKey="rent_calculation_type"
        title="Typ av inkomstkrav"
      />

      {selectedRentCalcType != null && (
        <InnerBox style={{ marginBottom: 24 }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>
              {selectedRentCalcType === 0
                ? "Inkomst relativt hyra"
                : "Inkomst efter hyra baserat på normalbelopp"}
            </OverviewTitle>
          </OverviewTitleWrapper>
          {selectedRentCalcType === 0 && (
            <>
              <NumberInput
                storeName={storeName}
                method={method}
                fieldKey="rent_factor"
                title="Inkomstkrav gånger hyran"
                description="Ange i x gånger hyran, t.ex. 2.5"
              />

              <div style={{ fontSize: 12 }}>
                Exempel: 5.000kr/mån x 2 motsvarar krav på 10.0000kr/mån
              </div>
            </>
          )}

          {selectedRentCalcType === 1 && (
            <>
              <NumberInput
                storeName={storeName}
                method={method}
                fieldKey="living_standard_factor"
                title="Inkomst efter hyra baserat på normalbelopp"
                description="Ange i x gånger normalbelopp, t.ex. 2.5"
              />

              <div style={{ fontSize: 12 }}>
                Normalbelopp är vad kronofogden anser är det minsta man behöver
                för att leva i Sverige <br /> <br /> Ensamstående{" "}
                <strong>5016 kr</strong>
                <br />
                Makar/Sambos <strong>8287 kr</strong>
                <br /> Barn 7 år eller äldre <strong>3064 kr</strong>
                <br /> Barn 6 år eller yngre <strong>2662 kr</strong>
              </div>
            </>
          )}
        </InnerBox>
      )}

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Borgensman</OverviewTitle>
        </OverviewTitleWrapper>

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="warrantor"
          storeName={storeName}
          method={method}
          title="Sökande med borgensman tillåts"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        {warrantorAllowed && (
          <NumberInput
            storeName={storeName}
            method={method}
            fieldKey="warrantor_rent_factor"
            title="Inkomstkrav gånger hyran för borgensman"
            description="Ange i x gånger hyran, t.ex. 2.5. Exempel: 2x inkomstkrav på en hyra på 10.000kr motsvarar 20.000kr/månad"
          />
        )}
      </InnerBox>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Godkänd typ av anställning</OverviewTitle>
        </OverviewTitleWrapper>

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="employment_permanent"
          storeName={storeName}
          method={method}
          title="Fast anställning"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={true}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="employment_temporary"
          storeName={storeName}
          method={method}
          title="Visstidsanställning"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={true}
        />
      </InnerBox>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Typ av inkomst</OverviewTitle>
        </OverviewTitleWrapper>

        <DoubleFieldWrapper>
          <RadioGroup
            extraStyles={{ flex: 1 }}
            fieldKey="income_fixed"
            storeName={storeName}
            method={method}
            title="Inkomst från fast anställning räknas"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={true}
          />

          <RadioGroup
            extraStyles={{ flex: 1 }}
            fieldKey="income_variable"
            storeName={storeName}
            method={method}
            title="Inkomst från visstidsanställning räknas"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={true}
          />
        </DoubleFieldWrapper>

        <DoubleFieldWrapper>
          <RadioGroup
            extraStyles={{ flex: 1 }}
            fieldKey="income_student"
            storeName={storeName}
            method={method}
            title="Sökande med studentinkomst tillåts"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
          <RadioGroup
            extraStyles={{ flex: 1 }}
            fieldKey="income_housing_support"
            storeName={storeName}
            method={method}
            title="Inkomst från bostadsbidrag räknas"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
        </DoubleFieldWrapper>

        <DoubleFieldWrapper>
          <RadioGroup
            extraStyles={{ flex: 1, marginRight: 8, marginLeft: 8 }}
            fieldKey="income_housing_supplement"
            storeName={storeName}
            method={method}
            title="Inkomst från bostadstillägg räknas"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />

          <RadioGroup
            extraStyles={{ flex: 1 }}
            fieldKey="income_living_expense_supplement"
            storeName={storeName}
            method={method}
            title="Inkomst från försörjningsstöd räknas"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
        </DoubleFieldWrapper>
      </InnerBox>
    </>
  );
};
