import * as React from "react";
import * as SharedStyles from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import BuildingTable from "../../../components/Tables/Buildings/FullTable";

export default function Buildings() {
  const { filteredRealEstates } = useActiveFilters();
  const persistantQuery = {
    realestate__in: filteredRealEstates,
  };

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Översikt</OverviewTitle>
        </OverviewTitleWrapper>

        <BuildingTable {...{ persistantQuery }} />
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
