import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import CompanyModal from "../../../components/Forms/Company/ChapterForm/ModalForm";
import { useParams } from "react-router";
import { useCompany, constants } from "../../../store/companies";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  setActiveFormInstance,
  usePermissionCheck,
} from "../../../store/base";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { useFilteredNotes } from "../../../store/notes";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useRealEstatePaginationCount } from "../../../store/realEstates";
import { LinkedObject } from "../../../components/Displays";
import { detailUrl as vacancyDetailUrl } from "../../../store/vacancyBookingSettings";
import { detailUrl as invoicingDetailUrl } from "../../../store/invoicingSettings";
import { detailUrl } from "../../../store/users";

function CompanyOverview() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);
  const [company] = useCompany(companyId);
  const canEdit = usePermissionCheck("change_can_company");

  const notesQ = buildQueryString({
    id__in: company?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const [realEstateCount] = useRealEstatePaginationCount({
    filters: { company: companyId },
  });

  const onEdit = () => {
    setEditOpen(true);
  };

  return (
    <>
      {editOpen && (
        <CompanyModal
          method="PATCH"
          onCheckout={() => setEditOpen(false)}
          id={companyId}
          instance={company}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "70%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              {canEdit && <PrimaryButton title="Redigera" clicked={onEdit} />}
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getInfoObj(company, realEstateCount)} />
          </DetailPageBox>

          <DetailPageBox style={{ width: "30%", alignSelf: "flex-start" }}>
            <Notes
              notes={notes}
              contentType="standard.company"
              objectId={companyId}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj(company, realEstateCount) {
  const infoObj = {
    Bolagsinfo: [
      {
        title: "Namn",
        value: company?.str_representation,
      },
      {
        title: "Orgnr",
        value: company?.orgnr || "-",
      },
      {
        title: "Bolagsadress",
        value: company?.address?.base?.split(",")?.[0] || "-",
      },
      {
        title: "Ansvarig signeringspart",
        value: <LinkedObject obj={company?.owner} urlMethod={detailUrl} />,
      },
    ],
    Övrigt: [
      {
        title: "Antal fastigheter",
        value: realEstateCount,
      },
      {
        title: "Aviseringsinställning",
        value: (
          <LinkedObject
            obj={company?.invoicing_config}
            urlMethod={invoicingDetailUrl}
          />
        ),
      },
      {
        title: "Vakansbokningsinställning",
        value: (
          <LinkedObject
            obj={company?.vacancy_booking_config}
            urlMethod={vacancyDetailUrl}
          />
        ),
      },
    ],
  };

  return infoObj;
}

export default CompanyOverview;
