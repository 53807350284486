export const constructStrRep = (obj) => {
  return obj.id || "Annons";
};

export const detailUrl = ({ id }) => {
  return `/market/industrial-premises-ads/detail/${id}`;
};

export const overviewUrl = () => {
  return `/market/ads`;
};

export const createManyMenuUrl = ({ type }) =>
  `/market/ads/create-many/${type}`;

export const createManyDefaultSettingUrl = ({ type }) =>
  `/market/ads/create-many/${type}/default-settings`;

export const createManyUrl = ({ type }) =>
  `/market/ads/create-many/${type}/handle`;
