import * as React from "react";

export default () => {
  return (
    <div>
      Ladda upp dokument tillhörande utförandet.
      <br />
      <br />
    </div>
  );
};
