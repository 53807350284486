import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import { DetailInnerWrapper, DetailPageBox } from "../../../sharedStyles";
import { PrimaryButton, TextButton } from "../../Base/Buttons";
import useQuery from "../../../utils/useQuery";

export default function MassConfigSummary() {
  const q = useQuery();
  const rooms = q.get("rooms");
  const components = q.get("components");
  const { push } = useHistory();

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Skapandet lyckades</OverviewTitle>
              <OverviewSubtitle style={{ marginRight: 24 }}>
                Rummen och komponenterna sattes upp i de valda objekten
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            <PrimaryButton
              title="Avsluta flöde"
              clicked={() => push("/errand-settings/mass-config")}
            />
          </OverviewTitleWrapper>

          <div>
            Antal rum som skapades: <strong>{rooms}</strong>
          </div>
          <div>
            Antal komponenter som skapades: <strong>{components}</strong>
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
