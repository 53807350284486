import moment from "moment";
import * as React from "react";

import svLocale from "@fullcalendar/core/locales/sv";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import { useDispatch, useSelector } from "react-redux";

import { buildQueryString } from "../../store/base";

import { axiosInstance } from "../../store/base/store/axios";

import {
  constants as calendarConstants,
  useFilteredCalendars,
} from "../../store/calendar";
import {
  constants as eventConstants,
  useFilteredCalendarEvents,
} from "../../store/calendarEvents";
import { useFilteredOutlookCalendarEvents } from "../../store/outlookCalendarEvents";

import CalendarForm from "../../components/Forms/Calendar/ChapterForm/ModalForm";
import CalendarEventForm from "../../components/Forms/CalendarEvent/ChapterForm/ModalForm";
import { PrimaryButton, TextButton } from "../Forms/Base/Buttons";
import DeleteModal from "../Forms/Delete/DeleteModal";
import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  Pill,
  PillsWrapper,
} from "../sharedStyles";

import CalendarInfo from "./CalendarInfo";
import EventInfo from "./EventInfo";

import StandardModal from "../Modals/StandardModal";

import { cloneDeep } from "lodash";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import DetailPageHeaderMenu from "../../views/Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { lightOrDark } from "../DigitalDoc/utils";
import OutlookButton from "../Forms/Base/Buttons/OutlookButton";
import * as SC from "./styles";

export default () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.app.userId);

  const calQ = buildQueryString({
    owner: userId || null,
  });

  const [allCalendars, allCalendarsLoading] = useFilteredCalendars(calQ);

  const [selectedCalendars, setSelectedCalendars] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment().startOf("month"));
  const [endDate, setEndDate] = React.useState(moment().endOf("month"));

  // undefined - no, null -> adding, other editing
  const [editingCalendar, setEditingCalendar] = React.useState(undefined);
  const [editingEvent, setEditingEvent] = React.useState(undefined);

  const [deletingCalendar, setDeletingCalendar] = React.useState(undefined);
  const [deletingEvent, setDeletingEvent] = React.useState(undefined);

  const [viewingEvent, setViewingEvent] = React.useState(undefined);
  const [viewingCalendar, setViewingCalendar] = React.useState(undefined);

  const [outlookActivationStatus, setOutlookActivationStatus] =
    React.useState(undefined);

  const checkOutlookStatus = async () => {
    const url = "events/outlook/has_credential/";
    try {
      const result = await axiosInstance.get(url);
      setOutlookActivationStatus(result.data.result);
    } catch (err) {
      setOutlookActivationStatus(null);
    }
  };

  React.useEffect(() => {
    if (!selectedCalendars?.length && allCalendars?.length) {
      setSelectedCalendars(allCalendars);
    }
  }, [allCalendarsLoading]);

  React.useEffect(() => {
    if (outlookActivationStatus !== undefined) {
      return;
    }
    checkOutlookStatus();
  }, [outlookActivationStatus]);

  // load the events from the event store - based on the selected calendars and their querystring
  let outlookCalendars = [];
  let normalCalendars = [];

  selectedCalendars.forEach((c) => {
    if (c.is_outlook_calendar) {
      outlookCalendars.push(c);
    } else {
      normalCalendars.push(c);
    }
  });

  const eventQueryString = normalCalendars?.length
    ? buildQueryString({
        start__gte: startDate.format(),
        end__lte: endDate.format(),
        mine: true,
      })
    : null;

  const outlookEventQueryString = outlookCalendars?.length
    ? buildQueryString({
        start_date: startDate.format(),
        end_date: endDate.format(),
        calendar_ids: outlookCalendars.map((c) => c.id),
      })
    : null;

  const [events, eventsLoading] = useFilteredCalendarEvents(eventQueryString);
  const [outlookEvents, outlookEventsLoading] =
    useFilteredOutlookCalendarEvents(outlookEventQueryString);

  const filteredEvents = events?.filter((e) => {
    // filter out events owned by me but not in a selected calendar

    if (e.owner?.id != userId) return true;

    return selectedCalendars?.map((c) => c.id)?.includes(e?.calendar?.id);
  });

  const addCalendarSelection = (calendar, forceRefresh) => {
    if (!calendar) {
      return;
    }

    if (selectedCalendars.find((c) => c.id === calendar.id)) {
      if (forceRefresh) {
        setSelectedCalendars(
          (allCalendars || []).filter((c) =>
            selectedCalendars?.map((s) => s.id)?.includes(c.id)
          )
        );
      }
      return;
    }
    setSelectedCalendars([...selectedCalendars, calendar]);
  };

  const getCalendarColor = (calendarId) => {
    return selectedCalendars.find((c) => c.id === calendarId)?.color_code;
  };

  const checkAllDay = (event) => {
    if (!event.end || !event.start) {
      return false;
    }

    const start = moment(event.start);
    const end = moment(event.end);

    return (
      start.hour() == 0 &&
      start.minute() == 0 &&
      ((end.hour() == 23 && end.minute() >= 59) ||
        (end.hour() == 0 && end.minute() == 0))
    );
  };

  const formatEvents = (events, isOutlookEvent) => {
    return events.map((e) => {
      return {
        id: e.id,
        title: e.title,
        start: e.start,
        end: e.end,
        allDay: checkAllDay(e),
        editable: !isOutlookEvent,
        backgroundColor: getCalendarColor(e.calendar?.id),
      };
    });
  };

  const addEvent = () => {
    setEditingEvent(null);
  };

  const addCalendar = () => {
    setEditingCalendar(null);
  };

  const signupOutlook = async () => {
    const url = "events/outlook/initiate_authentication/";
    try {
      const result = await axiosInstance.post(url);
      const activationUrl = result.data.url;
      window.open(activationUrl, "_blank").focus();
    } catch (err) {
      dispatch(
        addToast({
          title: "Något gick fel",
          description: "Kunde ej initiate outlook kopplingen",
          type: TOAST_TYPES.ERROR,
        })
      );
    }
  };

  const toggleCalendarSelected = (data) => {
    let selectedClone = cloneDeep(selectedCalendars);

    if (selectedClone?.find((c) => c.id === data.id)) {
      selectedClone = selectedClone.filter((s) => s.id !== data.id);
    } else {
      selectedClone.push(data);
    }

    setSelectedCalendars(selectedClone);
  };

  const isOutlookEvent = (event) => {
    return outlookCalendars.find((c) => c.id === event.calendar.id);
  };

  const handleEventClicked = (event) => {
    const id = event?.event?.id;
    if (!id) {
      return;
    }

    const matchedEvent = (events ?? [])
      .concat(outlookEvents ?? [])
      .find((e) => `${e.id}` === id);
    if (!matchedEvent) {
      return;
    }

    setViewingEvent(matchedEvent);
  };

  const handleCalendarDatesSet = (data) => {
    setStartDate(moment(data.start));
    setEndDate(moment(data.end));
  };

  const handleDateClicked = (data) => {
    setEditingEvent({
      start: moment(data.date).format(),
      end: moment(data.date).add(1, "hours").format(),
    });
  };
  const handleRangeSelected = (data) => {
    setEditingEvent({
      start: moment(data.start).format(),
      end: moment(data.end).format(),
    });
  };

  const handleEventDragged = (data) => {
    const id = data?.event?.id;
    if (!id) {
      return;
    }

    const matchedEvent = (events ?? [])
      .concat(outlookEvents ?? [])
      .find((e) => `${e.id}` === id);
    if (!matchedEvent) {
      return;
    }

    setEditingEvent({
      ...matchedEvent,
      start: moment(data.event.start).format(),
      end: moment(data.event.end).format(),
    });
  };
  const handleEventDropped = (data) => {
    handleEventDragged(data);
  };

  const cleanedEvents = formatEvents(filteredEvents ?? []).concat(
    formatEvents(outlookEvents ?? [], true)
  );

  const renderActions = () => (
    <>
      {outlookActivationStatus === false ? (
        <OutlookButton
          extraStyle={{ marginLeft: 12 }}
          title={"Aktivera Outlook-koppling"}
          onClick={signupOutlook}
        />
      ) : (
        <Pill
          style={{
            backgroundColor: "#24995e",
            fontWeight: 500,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            cursor: "default",
          }}
        >
          Outlook aktiverat
          <SC.OutlookActiveIcon />
        </Pill>
      )}
    </>
  );

  return (
    <>
      <StandardModal
        isOpen={viewingEvent !== undefined}
        canAccept={false}
        actionBarCancelTitle={"Stäng"}
        closeFunction={() => setViewingEvent(undefined)}
        withActionBar={true}
        title={viewingEvent?.title}
        newDesign={true}
        renderCustomActionBar={
          viewingEvent && !isOutlookEvent(viewingEvent)
            ? () => {
                return (
                  <>
                    <TextButton
                      title={"Radera händelse"}
                      red
                      clicked={() => {
                        setDeletingEvent(viewingEvent);
                        setViewingEvent(undefined);
                      }}
                    />
                    <PrimaryButton
                      title={"Uppdatera händelse"}
                      extraStyle={{ marginLeft: "auto" }}
                      clicked={() => {
                        setEditingEvent(viewingEvent);
                        setViewingEvent(undefined);
                      }}
                    />
                    <TextButton
                      extraStyle={{ marginLeft: 12 }}
                      title={"Stäng"}
                      clicked={() => {
                        setViewingEvent(undefined);
                      }}
                    />
                  </>
                );
              }
            : undefined
        }
      >
        {!!viewingEvent && <EventInfo instance={viewingEvent} />}
      </StandardModal>

      <StandardModal
        isOpen={viewingCalendar !== undefined}
        canAccept={false}
        actionBarCancelTitle={"Stäng"}
        closeFunction={() => setViewingCalendar(undefined)}
        withActionBar={true}
        newDesign={true}
        renderCustomActionBar={() => {
          return (
            <>
              <TextButton
                title={"Radera kalender"}
                red
                extraStyle={{ marginRight: "auto" }}
                clicked={() => {
                  setDeletingCalendar(viewingCalendar);
                  setViewingCalendar(undefined);
                }}
              />
              <PrimaryButton
                title={"Uppdatera kalender"}
                clicked={() => {
                  setEditingCalendar(viewingCalendar);
                  setViewingCalendar(undefined);
                }}
              />
              <TextButton
                extraStyle={{ marginLeft: 12 }}
                title={"Stäng"}
                clicked={() => {
                  setViewingCalendar(undefined);
                }}
              />
            </>
          );
        }}
      >
        {!!viewingCalendar && <CalendarInfo instance={viewingCalendar} />}
      </StandardModal>

      <DeleteModal
        isOpen={deletingCalendar || deletingEvent}
        instance={deletingCalendar ?? deletingEvent}
        constants={deletingCalendar ? calendarConstants : eventConstants}
        closeFunction={() => {
          setDeletingCalendar(undefined);
          setDeletingEvent(undefined);
        }}
      />

      <CalendarForm
        method={editingCalendar === null ? "POST" : "PATCH"}
        id={editingCalendar?.id}
        isOpen={editingCalendar !== undefined}
        onCheckout={(calendar) => {
          const forceRefresh = editingCalendar?.id !== undefined;

          setEditingCalendar(undefined);
          addCalendarSelection(calendar, forceRefresh);
        }}
        instance={editingCalendar}
      />

      <CalendarEventForm
        method={editingEvent?.id === undefined ? "POST" : "PATCH"}
        id={editingEvent?.id}
        isOpen={editingEvent !== undefined}
        onCheckout={() => setEditingEvent(undefined)}
        instance={editingEvent}
        calendars={selectedCalendars}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Kalender`}
          breadCrumbs={[
            { url: "/", label: "Min arbetsdag" },
            { label: "Kalender" },
          ]}
          {...{ renderActions }}
        />

        <DetailInnerWrapper>
          <DetailPageBox>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  minWidth: 300,
                  maxWidth: "70%",
                }}
              >
                <PillsWrapper>
                  {allCalendars?.map((c) => (
                    <Pill
                      onClick={() => toggleCalendarSelected(c)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 400,
                        fontSize: 14,
                        backgroundColor: c.color_code || "#2ec177",
                        color:
                          lightOrDark(c.color_code || "#2ec177") === "light"
                            ? "#444A55"
                            : "#fff",
                        opacity: selectedCalendars
                          ?.map((sc) => sc.id)
                          ?.includes(c.id)
                          ? 1
                          : 0.4,
                      }}
                      key={c.id}
                    >
                      {c.is_outlook_calendar && (
                        <SC.OutlookIcon
                          light={
                            lightOrDark(c.color_code || "#2ec177") === "light"
                          }
                        />
                      )}
                      {c.title}
                      <div
                        style={{
                          height: 20,
                          width: 1,
                          margin: "0 8px",
                          backgroundColor:
                            lightOrDark(c.color_code || "#2ec177") === "light"
                              ? "#444A55"
                              : "#fff",
                        }}
                      ></div>
                      <SC.EditCalendarIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setViewingCalendar(c);
                        }}
                        light={
                          lightOrDark(c.color_code || "#2ec177") === "light"
                        }
                      />
                    </Pill>
                  ))}
                  <TextButton
                    extraStyle={{ marginBottom: 8 }}
                    title="Lägg till kalender"
                    iconType="add"
                    iconPlacement="right"
                    clicked={addCalendar}
                  />
                </PillsWrapper>
              </div>

              <div
                style={{
                  marginLeft: "auto",
                  height: 40,
                  width: 2,
                  borderRadius: 3,
                  backgroundColor: "#979FAE",
                }}
              ></div>
              <div>
                <PrimaryButton
                  extraStyle={{ marginLeft: 16 }}
                  title={"Lägg till händelse"}
                  clicked={addEvent}
                  disabled={!selectedCalendars?.length}
                />
              </div>
            </div>

            <hr />

            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={cleanedEvents}
              firstDay={1}
              showNonCurrentDates={false}
              datesSet={handleCalendarDatesSet}
              buttonText={{
                today: "Idag",
                month: "Månad",
                week: "Vecka",
                day: "Dag",
                list: "Lista",
              }}
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                hour: "2-digit",
                meridiem: false,
                hour12: false,
              }}
              eventClick={handleEventClicked}
              selectable={true}
              select={handleRangeSelected}
              dateClick={handleDateClicked}
              eventStartEditable={true}
              eventResizableFromStart={true}
              eventDurationEditable={true}
              droppable={true}
              editable={true}
              eventDrop={handleEventDropped}
              eventResize={handleEventDragged}
              locale={svLocale}
            />
          </DetailPageBox>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
};
