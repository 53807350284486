import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import {
  buildQueryString,
  useAllPermissionCheck,
  useFrequentPermissions,
} from "../../store/base";
import {
  useBrfCompany,
  overviewUrl,
  constants,
} from "../../store/brfCompanies";
import { useInvoicingErrorPaginationCount } from "../../store/invoicingErrors/hooks/retrieve";
import { setActiveFilteredRealEstates } from "../../store/overview/store/actions";
import { useFilteredRealEstates } from "../../store/realEstates";
import MovedComponent from "../ConfigCenter/MovedComponent";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import BrfCompanyActivateFortnox from "./detail/ActivateFortnox";
import BrfCompanyAwaitActivateFortnox from "./detail/AwaitActivationFortnox";
import BrfCompanyBoard from "./detail/Board";
import BrfCompanyBookkeepingSettings from "./detail/BookkeepingSettings";
import BrfCompanyInvoicingErrors from "./detail/BrfCompanyErrors";
import BrfCompanyDocuments from "./detail/Documents";
import BrfCompanyInvoicing from "./detail/Invoicing";
import BrfCompanyOverview from "./detail/Overview";
import BrfCompanyQuotas from "./detail/Quotas";
import BrfLoans from "./detail/Loans";


export default function BrfCompanyDetail() {
  const dispatch = useDispatch();
  const { brfCompanyId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const [brfCompany] = useBrfCompany(brfCompanyId);
  usePermissionRedirect(["allow_brf", "view_can_brf"]);

  const canDelete = useAllPermissionCheck([
    "delete_can_company",
    "delete_can_brf",
  ]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [invoiceSettingPromptOpen, setInvoicingSettingPromtOpen] =
    React.useState(false);
  const { hasBillectaViewPermission, hasBillectaFullPermission } =
    useFrequentPermissions();

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      company: brfCompany?.company?.id,
    },
  });

  const [errorsConnectedToCreditorCount] = useInvoicingErrorPaginationCount({
    filters: {
      billecta_creditor_id: brfCompany?.company?.billecta_id,
    },
  });

  const realEstateQ = buildQueryString({
    company: brfCompany?.company?.id,
  });
  const [realEstates] = useFilteredRealEstates(realEstateQ);

  const { push } = useHistory();
  const query = useQuery();
  const showInvoicingSettingPrompt = query.get("showInvoicingSettingPrompt");

  React.useEffect(() => {
    if (hasBillectaFullPermission && showInvoicingSettingPrompt == "true") {
      setInvoicingSettingPromtOpen(true);
    }
  }, [showInvoicingSettingPrompt, hasBillectaFullPermission]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Förening",
      component: BrfCompanyOverview,
    },
    {
      path: "/quotas",
      label: "Andelstal",
      component: BrfCompanyQuotas,
    },
    {
      path: "/board",
      label: "Styrelse",
      component: BrfCompanyBoard,
    },
    {
      path: "/documents",
      label: "Dokument",
      component: BrfCompanyDocuments,
    },
  ];

  if (hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      hasError: errorCount,
      component: BrfCompanyInvoicing,
    });
  }

  if (brfCompany?.company?.billecta_id && hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing-integrations",
      label: "Integrationer",
      component: MovedComponent,
    });
    SUB_PAGES.push({
      path: "/accounting",
      label: "Bokföring",
      component: BrfCompanyBookkeepingSettings,
    });

    SUB_PAGES.push({
      path: "/activate-fortnox",
      hiddenLabel: true,
      component: BrfCompanyActivateFortnox,
    });

    SUB_PAGES.push({
      path: "/activate-fortnox-complete",
      hiddenLabel: true,
      component: BrfCompanyAwaitActivateFortnox,
    });
  }

  if (
    brfCompany?.company?.billecta_id &&
    hasBillectaViewPermission &&
    errorsConnectedToCreditorCount > 0
  ) {
    SUB_PAGES.push({
      path: "/creditor-related-errors",
      label: "Fel under bolag",
      hasError: errorsConnectedToCreditorCount > 0,
      component: BrfCompanyInvoicingErrors,
    });
  }

  SUB_PAGES.push({
      path: "/loans",
      label: "Avgifter & Lån",
      component: BrfLoans,
    });

  const subPageLinks = SUB_PAGES?.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderLogo = () => {
    if (brfCompany?.company?.image?.get) {
      return (
        <div
          style={{
            height: 50,
            width: 70,
            marginRight: 8,
            boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${brfCompany?.company.image.get})`,
          }}
        ></div>
      );
    }

    return null;
  };

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={brfCompany?.company?.invoicing_details?.id ? 1 : 6}>
          {brfCompany?.company?.invoicing_details?.id
            ? "Uppsatt för avisering"
            : "Ej uppsatt för avisering"}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => {
    return (
      <PrimaryButton
        title="Filtrera systemet på denna BRF"
        clicked={() => {
          dispatch(
            setActiveFilteredRealEstates({
              realEstateIds: realEstates?.map((r) => r?.id),
              brfCompanyIds: [brfCompanyId],
            })
          );
          push("/");
        }}
      />
    );
  };

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  const goToInvoicing = () => {
    push(`/brf-companies/detail/${brfCompanyId}/invoicing?opensettings=true`);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={brfCompany}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <ConfirmationModal
        isOpen={invoiceSettingPromptOpen}
        closeFunction={() => setInvoicingSettingPromtOpen(false)}
        title="Vill du konfigurera aviseringsinställningar för denna BRF direkt?"
        acceptTitle="Konfigurera nu"
        acceptCallback={goToInvoicing}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`${
            brfCompany?.str_representation?.includes("BRF") ? "" : "BRF "
          }${brfCompany?.str_representation || "laddar..."}`}
          renderLogo={renderLogo}
          breadCrumbs={[
            { url: "/brf-companies", label: "BRF" },
            { label: brfCompany?.str_representation || "Laddar..." },
          ]}
          eventContentType="brf.brfcompany"
          eventObjectId={brfCompanyId}
          eventIdQueryStr="brfcompany"
          {...{
            subPages: subPageLinks,
            renderInfoPills,
            handleActions,
            renderActions,
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
