import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ItemTitle } from "../../../components/Details/OverviewInfo/DetailInfo/styles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useImportantMessages } from "../../../store/billectaEvents/hooks/retrieve";
import { creditorDetailUrl } from "../../../store/billectaInvoicing/utils";
import { useFilteredCompanies } from "../../../store/companies";

export default function Notifications() {
  const [messages] = useImportantMessages();
  const { push } = useHistory();

  const [currentCreditor, setCurrentCreditor] = React.useState(null);

  const q = buildQueryString({
    "billecta_id__isnull!": true,
  });
  const [companies] = useFilteredCompanies(q);

  const goToCreditor = (creditorId) => {
    push(creditorDetailUrl({ creditorId }));
  };

  const byCreditor = messages?.reduce((acc, cur) => {
    if (acc[cur.CreditorPublicId]) {
      return {
        ...acc,
        [cur.CreditorPublicId]: acc[cur.CreditorPublicId] + 1,
      };
    } else {
      return { ...acc, [cur.CreditorPublicId]: 1 };
    }
  }, {});

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Viktiga meddelanden gällande avisering
            </OverviewTitle>
            <OverviewSubtitle>
              {currentCreditor
                ? `Visar meddelanden för ${
                    companies?.find((c) => c.billecta_id === currentCreditor)
                      ?.str_representation
                  }. Klicka på ett meddelande för att komma till bolagets aviseringssida.`
                : "Klicka på ett bolag för att visa meddelandena"}
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {!currentCreditor ? (
          <>
            {Object.keys(byCreditor || {}).map((key) => {
              const matchingCompany = companies?.find(
                (c) => c.billecta_id === key
              );
              if (!matchingCompany) return null;

              return (
                <InnerBox
                  key={matchingCompany.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => setCurrentCreditor(key)}
                >
                  <BodyText>{matchingCompany.str_representation}</BodyText>
                  <ItemTitle>{byCreditor[key]} viktiga meddelanden</ItemTitle>
                </InnerBox>
              );
            })}
          </>
        ) : (
          <>
            <TextButton
              title="Tillbaka"
              extraStyle={{ marginBottom: 12 }}
              clicked={() => setCurrentCreditor(null)}
              iconType="arrow-back"
            />

            {messages
              ?.filter((m) => m.CreditorPublicId === currentCreditor)
              ?.map((m) => {
                return (
                  <InnerBox
                    style={{ cursor: "pointer" }}
                    onClick={() => goToCreditor(m.CreditorPublicId)}
                  >
                    <BodyText>
                      {
                        companies?.find(
                          (c) => c.billecta_id === m.CreditorPublicId
                        )?.str_representation
                      }{" "}
                      - {moment(m.Created).format("YYYY-MM-DD")}
                    </BodyText>
                    <ItemTitle>{m.Message}</ItemTitle>
                  </InnerBox>
                );
              })}
          </>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
