import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  setActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import {
  constants,
  destroyPatchForm,
  detailUrl,
  markRoundingComponentsNonFaults,
  update,
  useRoundingErrand,
  useRoundingErrandForm,
} from "../../../../store/roundingErrands";
import { useRoundingErrandSetting } from "../../../../store/roundingErrandSettings";

//components & styles
import { addToast, TOAST_TYPES } from "src/store/toasts";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import chapters from "./Chapters";
import descriptions from "./Descriptions";

export default function ExecuteRoundingErrandFlowForm({
  method = "PATCH",
  isGov,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { errandId } = useParams();
  const { replace } = useHistory();
  const formLoaded = Boolean(useRoundingErrandForm(method, errandId));
  const [roundingErrand] = useRoundingErrand(errandId);

  const [settings] = useRoundingErrandSetting(roundingErrand?.setting?.id);
  const areaIds = settings?.areas?.map((a) => a.id) || [];

  const nonFaultErrandHandling = useFormInstanceField({
    storeName,
    fieldKey: "_handleNonFaults",
  });

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id: errandId,
        successCallback: (_, returned) => {
          if (nonFaultErrandHandling === true) {
            dispatch(
              markRoundingComponentsNonFaults({
                id: errandId,
                markAsOk: nonFaultErrandHandling,
                successCallback: () => {
                  dispatch(
                    addToast({
                      type: TOAST_TYPES.SUCCESS,
                      title: "Ärendet slutfördes",
                    })
                  );

                  setLoading(false);

                  checkout(true);
                  replace(detailUrl({ id: returned.id, isGov }));
                },
                errorCallback: () => {
                  dispatch(
                    addToast({
                      type: TOAST_TYPES.INFO,
                      title: "Ärendet slutfördes",
                      description:
                        "Lyckades ej markera ej avvikande komponenter som OK",
                    })
                  );

                  setLoading(false);

                  checkout(true);
                  replace(detailUrl({ id: returned.id, isGov }));
                },
              })
            );
          } else {
            dispatch(
              markRoundingComponentsNonFaults({
                id: errandId,
                markAsOk: nonFaultErrandHandling,
                successCallback: () => {
                  dispatch(
                    addToast({
                      type: TOAST_TYPES.SUCCESS,
                      title: "Ärendet slutfördes",
                    })
                  );

                  setLoading(false);

                  checkout(true);
                  replace(detailUrl({ id: returned.id, isGov }));
                },
                errorCallback: () => {
                  dispatch(
                    addToast({
                      type: TOAST_TYPES.INFO,
                      title: "Ärendet slutfördes",
                      description:
                        "Lyckades ej markera ej avvikande komponenter som Ej ronderade",
                    })
                  );

                  setLoading(false);

                  checkout(true);
                  replace(detailUrl({ id: returned.id, isGov }));
                },
              })
            );
          }
        },
        errorCallback: onError,
        preProcess: (data) => preProcess({ data }),
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      setActiveFormInstance({
        storeName,
        data: { ...roundingErrand, _handleNonFaults: true },
      })
    );
  }, [roundingErrand]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,
          descriptions,
          areaIds,
          errandId,
          method,
          onSubmit,
          isGov,
          displayDocumentFieldKey: "completion_files[0]._tempData.data",
          title: isGov
            ? "Registrering av myndighetskrav"
            : "Genomgång av rondering",
        }}
      />
    </>
  );
}

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  copy.status = 3;

  return copy;
};
