import * as React from "react";
// style, design
import { DoubleFieldWrapper } from "../../../Base/Chapters/styles";
import { RadioGroup, Select, TextInput } from "../../../Base/Fields";

// store, states
import { constants } from "../../../../../store/invoicingDebtor";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useParams } from "react-router-dom";
import { useTenant } from "../../../../../store/tenants";
import { InfoBox } from "../../../../Displays";
import { useDispatch } from "react-redux";
import Alert, { ALERT_TYPES } from "../../../../Alert/Alert";

export default ({ method, kind, isMember }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { tenantId } = useParams();

  const [tenant] = useTenant(tenantId);
  const disableAutogiroField = tenant && !tenant?.user?.legal_id;

  const useAutoGiro = useFormInstanceField({
    storeName,
    fieldKey: "use_autogiro",
  });
  const autogiroService = useFormInstanceField({
    storeName,
    fieldKey: "autogiro_payment_service",
  });
  const autogiroClearing = useFormInstanceField({
    storeName,
    fieldKey: "autogiro_clearing",
  });
  const autogiroNumber = useFormInstanceField({
    storeName,
    fieldKey: "autogiro_number",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Betalmetoder</OverviewTitle>
          <OverviewSubtitle>
            Ställ in vilka extra betalmetoder som kan användas för motparten
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {kind === "private" ? (
        <Select
          title="Bank för E-faktura"
          description="För att kunna använda E-faktura mot privata hyresgäster krävs det att E-faktura är aktiverat för det fakturerande bolaget. Bolagets bank ger ut ett FUI-nummer som skrivs in under bolagets faktureringsinställningar."
          {...{
            storeName,
            method,
            fieldKey: `e_invoice_bank`,
          }}
        />
      ) : (
        <>
          <Select
            title="Intermediator"
            description="Välj av en de som finns i listan. Om ni är osäker på vilken intermediatör er kund har så behöver ni kontakta er kund."
            {...{
              storeName,
              method,
              fieldKey: `intermediator`,
            }}
          />

          <TextInput
            title="GLN"
            description="Denna uppgift är frivillig men om ni anger detta så minskar det risken för att er kund inte hittas i e-fakturanätverket."
            {...{
              storeName,
              method,
              fieldKey: `gln`,
            }}
          />
        </>
      )}

      {kind === "private" && (
        <p
          style={{
            maxWidth: "600px",
            fontWeight: 500,
            fontSize: ".8rem",
            margin: "20px 0",
            backgroundColor: "#e4f1ff",
            padding: "12px",
            borderRadius: "8px",
          }}
        >
          <strong>OBS:</strong> Om du fyller i eller ändrar uppgifterna för
          autogiro måste du ha ett medgivande från{" "}
          {isMember ? "medlemmen" : "hyresgästen"}. Medgivandet kan ske genom
          ett anmälningsformulär. Har medgivandet skett digitalt på annat sätt
          måste kunden tilldelas viss information för att medgivandet ska vara
          giltigt. Mer information hittar du på{" "}
          <a
            href="https://www.bankgirot.se"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.bankgirot.se
          </a>
          . För medgivande genom ett formulär så kan du använda följande
          blanketter som mallar om du inte har ett eget.
          <ul>
            <li>
              <a
                href="https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/public_media/Other/Autogiroanmalan_konto.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medgivande för kontonummer
              </a>
            </li>
            <li>
              <a
                href="https://www.bankgirot.se/globalassets/dokument/e-formular/Autogiroanmalan_medgivande_till_bankgironummer.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medgivande för bankgironummer
              </a>
            </li>
          </ul>
        </p>
      )}

      {disableAutogiroField && (
        <InfoBox
          title="Personnummer saknas"
          text="För att kunna aktivera autogiro som betalmetod krävs det att personnummer är inställt"
          boxTheme="warning"
        />
      )}
      <RadioGroup
        disabled={disableAutogiroField}
        title="Autogiro"
        options={[
          { label: "Använd autogiro", value: true },
          { label: "Använd ej autogiro", value: false },
        ]}
        defaultValue={false}
        {...{
          storeName,
          method,
          fieldKey: `use_autogiro`,
        }}
      />

      {useAutoGiro && (
        <>
          {(!autogiroNumber || !autogiroClearing || !autogiroService) && (
            <Alert title="Aktivering av autogiro" type={ALERT_TYPES.INFO}>
              För att autogiro ska fungera så krävs något av följande:
              <br />
              <br />
              <ul className="list-disc space-y-2">
                <li className="font-medium">
                  Autogiroleverantör, Autogiro Clearing samt Autogironummer.
                </li>

                <li>eller</li>

                <li className="font-medium">Autogiro betalarnummer</li>
              </ul>
            </Alert>
          )}
          <TextInput
            title="Autogiro betalarnummer"
            extraStyles={{ minWidth: "150px", marginRight: "20px" }}
            {...{
              storeName,
              method,
              fieldKey: `autogiro_payer_number`,
            }}
          />

          <TextInput
            title="Autogiroleverantör"
            description="Namnet på autogiroleverantören, t.ex. SHB"
            {...{
              storeName,
              method,
              fieldKey: `autogiro_payment_service`,
            }}
          />
          <DoubleFieldWrapper>
            <TextInput
              title="Autogiro clearing"
              extraStyles={{ minWidth: "150px", marginRight: "20px" }}
              {...{
                storeName,
                method,
                fieldKey: `autogiro_clearing`,
              }}
            />

            <TextInput
              title="Autogironummer"
              extraStyles={{ minWidth: "290px" }}
              {...{
                storeName,
                method,
                fieldKey: `autogiro_number`,
              }}
            />
          </DoubleFieldWrapper>
        </>
      )}
    </>
  );
};
