import * as React from "react";

import {
  TextInput,
  Select,
  AddressField,
  NumberInput,
  Checkbox,
  TextField,
  TimePicker,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/keypermission";
import {
  buildQueryString, updateActiveFormInstance, useFormError, useFormInstanceField,
} from "../../../../../store/base";

import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";
import { retrieveAccesses } from "../../../../../store/parakey";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "../../../Base/Fields/styles";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const parakeyId = useFormInstanceField({storeName, fieldKey: "parakey_id"})
  const error = useFormError({storeName, fieldKey:"parakey_id"})

  const [parakeyChoices, setParakeyChoices] = React.useState(undefined);

  const loadParakeyChoices = async () => {
    const data = await retrieveAccesses();
    setParakeyChoices(data.map((item) => { return {v:item.id, d:item.name} }))
  }

  React.useEffect(() => {
    if (parakeyChoices === undefined) {
        loadParakeyChoices();
    }
  }, [])

  return (
    <>
      <NonConnectedSelect
        extraStyles={{ marginTop: 24 }}
        choices={parakeyChoices}
        allowNull={true}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Parakey behörighet"
        id="parakey_id"
        value={parakeyId}
        onUpdate={(v) => dispatch(updateActiveFormInstance({storeName, data:{parakey_id:v}}))}
        />
        {!!error && (
          <ErrorMessage>
            {error}
          </ErrorMessage>
        )}
    </>
  );
};
