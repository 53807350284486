import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { update } from "../../../../store/calendarEvents";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { useUser } from "../../../../store/users";
import { redirectMethodFromKind } from "../../../../store/utils";
import { TextButton } from "../../../Forms/Base/Buttons";
import TodoItemForm from "../../../Forms/TodoItem/ChapterForm/ModalForm";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import * as SC from "./styles";

export default function TodoItem({
  event,
  modalInModal = false,
  hideObjectLink = false,
}) {
  const dispatch = useDispatch();
  const [done, setDone] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const { push } = useHistory();

  const hasConnectedObj = event?.object_id && event?.content_type;

  const userId = useSelector((state) => state.app.userId);

  const [owner] = useUser(event?.owner?.id);

  const getTimeStatus = () => {
    const end = moment(event.end);
    const now = moment();

    return end.isBefore(now)
      ? "late"
      : end.isSame(now, "day")
      ? "warning"
      : null;
  };

  const handleSetDone = () => {
    setDone(!event.done);
    const data = { done: !event.done };
    dispatch(
      update({
        forceData: data,
        id: event.id,
        successCallback: () => {
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: `Kunde ej ${
                event.done ? "avmarkera" : "markera"
              } uppgift som klar`,
              description: "Försök igen",
            })
          );
        },
      })
    );
  };

  const renderDescription = () => {
    if (event?.description?.length > 100 && !showAll) {
      return (
        <div>
          {event?.description?.substr(0, 100) + "..."}{" "}
          <TextButton title="Visa mer" clicked={() => setShowAll(true)} />
        </div>
      );
    }

    return event?.description;
  };

  const urlMethod = redirectMethodFromKind(event?.content_type);

  return (
    <>
      <TodoItemForm
        method={"PATCH"}
        id={event?.id}
        isOpen={editOpen}
        onCheckout={() => {
          setEditOpen(false);
        }}
        instance={event}
        modalInModal={modalInModal}
      />

      <SC.TodoItemWrapper>
        {loading && <OverlaySpinner />}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SC.TodoEndDate>
            Klar senast:{" "}
            {event?.end ? (
              <SC.TodoTime status={getTimeStatus()}>
                {moment(event.end).format("YYYY-MM-DD HH:mm")}
              </SC.TodoTime>
            ) : (
              "Ingen tidsgräns angiven"
            )}
          </SC.TodoEndDate>

          <SC.TodoEndDate>
            Skapad av{" "}
            {event?.owner?.id == userId ? "mig" : owner?.str_representation}
          </SC.TodoEndDate>
        </div>
        <SC.TodoItemTitleWrapper>
          <SC.TodoIcon {...{ done }} onClick={loading ? null : handleSetDone} />
          <SC.TodoText onClick={loading ? null : () => setEditOpen(true)}>
            {event?.title}
          </SC.TodoText>
        </SC.TodoItemTitleWrapper>

        <SC.TodoItemDescription>{renderDescription()}</SC.TodoItemDescription>

        {hasConnectedObj && urlMethod && !hideObjectLink && (
          <div style={{ display: "flex" }}>
            <TextButton
              title="Gå till objekt"
              iconType="arrow"
              iconPlacement="right"
              extraStyle={{ marginTop: 6, marginLeft: "auto", marginRight: 0 }}
              clicked={() => push(urlMethod({ id: event.object_id }))}
            />
          </div>
        )}
      </SC.TodoItemWrapper>
    </>
  );
}
