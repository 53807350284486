import * as React from "react";
import {
  useParams,
  useRouteMatch,
  Route,
  Switch,
  Redirect,
} from "react-router";

import * as SharedStyles from "../../components/sharedStyles";
import { useTenantPortalSetting } from "../../store/tenantPortalSettings";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import EditTenantPortalSetting from "./EditTenantPortalSetting";
import TenantPortalInfo from "./TenantPortalInfo";

export default function TenantPortalOverview() {
  const { id } = useParams();
  const { path, url } = useRouteMatch();
  const [portalSetting, portalSettingsLoading] = useTenantPortalSetting(id);

  const breadCrumbs = [
    {
      label: "Mina sidor",
      url: "/configcenter/tenant-portal",
    },
    {
      label: `${portalSetting?.company_name}`,
    },
  ];

  const SUB_PAGES = [
    {
      path: "/settings",
      label: "Inställningar",
      component: EditTenantPortalSetting,
    },
    {
      path: "/tenants-in-portal",
      label: "Hyresgäster under inställning",
      component: TenantPortalInfo,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <>
      <SharedStyles.DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`"Mina sidor"-inställningar för ${
            portalSetting?.company_name || "laddar..."
          } `}
          breadCrumbs={breadCrumbs}
          subPages={subPageLinks}
        />
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </SharedStyles.DetailLayoutWrapper>
    </>
  );
}
