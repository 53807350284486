import * as React from "react";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../store/base";

import { useInvoicingCostCenter } from "../../../store/invoicingCostCenters";
import { useInvoicingProject } from "../../../store/invoicingProjects";

import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Table from "../../Billecta/Table/BasicTable";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../Base/Buttons";

import { InnerBox } from "../../sharedStyles";
import { toMoneyString } from "../../utils/stringUtils";

import { OrderUp, OrderDown } from "../../Details/OverviewInfo/Costs/styles";

import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { InfoBox, LinkedObject } from "../../Displays";
import { cloneDeep } from "lodash";
import { detailUrl as indexDetailUrl } from "../../../store/indexsetting";
import { useFilteredApartments } from "../../../store/apartments";
import { useFilteredIndustrialPremises } from "../../../store/industrialPremises";
import { useFilteredParkingSpots } from "../../../store/parkingSpots";
import ConnectedObjectCosts from "../../Details/OverviewInfo/Costs/ConnectedObjectCosts";

import CheckField from "../Base/Fields/CheckField";
import TextInputField from "../Base/Fields/TextInputField";
import TableSelectFieldWithCreate from "../Base/Fields/TableSelectFieldWithCreate";
import ProductNestedFields from "../InvoicingProduct/NestedFields";
import RentInputField from "../Base/Fields/RentInputField";
import SelectField from "../Base/Fields/SelectField";
import DateSelect from "../Base/Fields/DateSelect";
import ProjectNestedFields from "../InvoicingProject/NestedFields";
import CostCenterNestedFields from "../InvoicingCostCenter/NestedFields";
import IndexSettingNestedFields from "../IndexSetting/NestedFields";
import PrimaryBtn from "../Base/Buttons/PrimaryBtn";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";
import IndexSettingsTable from "src/components/Tables/IndexSetting/FullTable";

export default function ContractCostsBase({
  method,
  skipInvoicing,
  goBackToInvoicingSetup,
  contract,
  hideCostsFromObject = false,
  constants,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [handleCostIndex, setHandleCostIndex] = React.useState(null);
  const [addContractSpecificCostsOpen, setAddContractSpecifiCostsOpen] =
    React.useState(false);

  const apartmentQ = buildQueryString({
    id__in: contract?.apartments?.map((a) => a.id) || [],
  });
  const indpQ = buildQueryString({
    id__in: contract?.industrial_premises_list?.map((a) => a.id) || [],
  });
  const parkingQ = buildQueryString({
    id__in: contract?.parking_spots?.map((a) => a.id) || [],
  });
  const [apartments] = useFilteredApartments(apartmentQ);
  const [indps] = useFilteredIndustrialPremises(indpQ);
  const [parkingSpots] = useFilteredParkingSpots(parkingQ);

  const combinedPremises = [
    ...(apartments?.map((ap) => ({ ...ap, kind: "APARTMENT" })) || []),
    ...(indps?.map((ap) => ({ ...ap, kind: "INDP" })) || []),
    ...(parkingSpots?.map((ap) => ({ ...ap, kind: "PARKING" })) || []),
  ];

  const topRef = React.useRef();

  const totalArea = contract?.total_area || null;

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const costs =
    useFormInstanceField({
      storeName,
      fieldKey: "cost_set",
    }) || [];

  const baseVAT = useFormInstanceField({
    storeName,
    fieldKey: "vat",
  });

  const costErrors = useFormError({
    storeName,
    fieldKey: "cost_set",
  });

  const [defaultProject] = useInvoicingProject(defaultSetting?.project?.id);
  const [defaultCostCenter] = useInvoicingCostCenter(
    defaultSetting?.cost_center?.id
  );

  const goToTop = () => {
    setTimeout(() => {
      topRef.current &&
        topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 150);
  };

  const handleRowDown = (event, row) => {
    event.stopPropagation();

    const instanceClone = cloneDeep(costs);

    const rowOrder = row.order;
    const newOrder = rowOrder + 1;

    const rowThatChangedOrder = instanceClone.find((r) => r.order === rowOrder);
    const rowThatPreviuoslyHadOrder = instanceClone.find(
      (r) => r.order === newOrder
    );

    rowThatChangedOrder.order = newOrder;
    rowThatPreviuoslyHadOrder.order = rowOrder;

    instanceClone.sort((a, b) => {
      return a.order - b.order;
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: instanceClone,
        },
      })
    );
  };

  const handleRowUp = (event, row) => {
    event.stopPropagation();

    const instanceClone = cloneDeep(costs);

    const rowOrder = row.order;
    const newOrder = rowOrder - 1;

    const rowThatChangedOrder = instanceClone.find((r) => r.order === rowOrder);
    const rowThatPreviuoslyHadOrder = instanceClone.find(
      (r) => r.order === newOrder
    );

    rowThatChangedOrder.order = newOrder;
    rowThatPreviuoslyHadOrder.order = rowOrder;

    instanceClone.sort((a, b) => {
      return a.order - b.order;
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: instanceClone,
        },
      })
    );
  };

  const tableCosts = React.useMemo(() => {
    return costs;
  }, [costs]);

  const tableColumns = React.useMemo(() => [
    {
      Header: " ",
      id: "orderOnInvoice",
      Cell: ({ row }) => {
        const rowOrder = row.original.order;
        const allOrders = costs.map((fc) => fc.order);
        const maxUp = Math.max.apply(null, allOrders);
        const maxDown = Math.min.apply(null, allOrders);
        const canDown = rowOrder < maxUp;
        const canUp = rowOrder > maxDown;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {canUp && <OrderUp onClick={(e) => handleRowUp(e, row.original)} />}
            {canDown && (
              <OrderDown onClick={(e) => handleRowDown(e, row.original)} />
            )}
          </div>
        );
      },
    },
    {
      Header: "Debiteras",
      accessor: "do_not_debit",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 8 : 1}>
            {value ? "Debiteras Inte" : "Debiteras"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Typ",
      accessor: "_",
      Cell: ({ row }) => {
        const isDiscount = row.original.value < 0;
        return (
          <StatusLabel state={isDiscount ? 3 : 1}>
            {isDiscount ? "Rabatt" : "Kostnad"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Produkt",
      accessor: "product",
      Cell: ({ value }) => {
        const title = value?.title || "";

        return <div>{title}</div>;
      },
    },
    {
      Header: "SEK/månad",
      accessor: "value",
      Cell: ({ value }) => {
        return <div>{toMoneyString(value || 0)}</div>;
      },
    },
    {
      Header: "Momssats (%)",
      accessor: "vat",
      Cell: ({ value, row }) => {
        const productVat = row.original.product?.vat;
        const val =
          value != null
            ? value
            : productVat != null
            ? `${productVat} (från produkt)`
            : baseVAT != null
            ? `${baseVAT} (från grundmomssats)`
            : "-";
        return <div>{val}</div>;
      },
    },
    {
      Header: "Börjar aviseras för",
      accessor: "start_date",
      Cell: ({ value }) => {
        if (!value) return <div>Med avtalet</div>;
        return <div>{value}</div>;
      },
    },
    {
      Header: "Slutar aviseras för",
      accessor: "end_date",
      Cell: ({ value }) => {
        if (!value) return <div>Med avtalet</div>;

        return <div>{value}</div>;
      },
    },
    {
      Header: "Kostnadsställe",
      accessor: "cost_center",
      Cell: ({ value }) => {
        const title = value?.title
          ? value.title
          : defaultCostCenter
          ? `${defaultCostCenter.str_representation} (från inställning)`
          : "-";

        return <div>{title}</div>;
      },
    },
    {
      Header: "Projekt",
      accessor: "project",
      Cell: ({ value }) => {
        const title = value?.title
          ? value.title
          : defaultProject
          ? `${defaultProject.str_representation} (från inställning)`
          : "-";

        return <div>{title}</div>;
      },
    },
    {
      Header: "Indexuppräkning",
      accessor: "indexation",
      Cell: ({ value }) => {
        return <LinkedObject obj={value} urlMethod={indexDetailUrl} />;
      },
    },
  ]);

  const handleEditCost = (row) => {
    setHandleCostIndex(row.index);
  };

  const handleAddCost = () => {
    const costsCopy = [...costs];
    const newIndex = costsCopy.length;
    costsCopy.push({ order: newIndex + 1 });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: costsCopy,
        },
      })
    );

    setHandleCostIndex(newIndex);
  };

  const handleRemoveCost = (index) => {
    const costsCopy = cloneDeep(costs);
    const removedRowOrder = costsCopy[index].order;
    costsCopy.splice(index, 1);

    // fix orders
    if (costsCopy.length) {
      costsCopy.forEach((c) => {
        if (c.order > removedRowOrder) {
          c.order = c.order - 1;
        }
      });
    }

    setHandleCostIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );

    goToTop();
  };

  const handleEditCostDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostIndex(null);

    goToTop();
  };

  const renderCostErrors = () => {
    return (
      <InfoBox
        title="En eller flera kostnadsrader innehåller fel"
        text="Kontrollera de rödmarkerade raderna. Klicka på raden för att se detaljer kring felet"
        boxTheme="warning"
      />
    );
  };

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  const setProductCategoryAsTitle = (data) => {
    if (!costs[handleCostIndex] || !data) return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`cost_set[${handleCostIndex}].title`]: data.category_display,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Titeln uppdaterades",
        description:
          "Titeln på debiteringsraden sattes till kategorin på den valda produkten",
      })
    );
  };

  if (handleCostIndex != null) {
    return (
      <>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Hantera rad {costs[handleCostIndex]?.title || ""}
          </OverviewTitle>
        </OverviewTitleWrapper>

        <div className="mb-6">
          <CheckField
            title="Debitera ej för denna rad"
            description="Inaktiverar debitering för denna debiteringsrad. Används t.ex. när indexuppräkningen från raden ska debiteras men inte basbeloppet."
            fieldKey={`cost_set[${handleCostIndex}].do_not_debit`}
            instructionsKey="cost_set.do_not_debit"
            {...{ storeName, method }}
          />
        </div>

        <div className="grid grid-cols-1 gap-6 mb-6">
          <TextInputField
            title="Beskrivning"
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].title`}
            instructionsKey="cost_set.title"
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <SelectField
            title="Momssats"
            placeholder="Utifrån grundmoms på avtalet"
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].vat`}
            instructionsKey="cost_set.vat"
          />

          <TableSelectFieldWithCreate
            storeName={storeName}
            placeholder="Välj produkt..."
            title="Produkt"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].product`}
            instructionsKey={"cost_set.product"}
            createDisplayKey="title"
            createTitle="Skapa ny produkt"
            TableComponent={InvoicingProductTable}
          >
            {(parentPath) => (
              <ProductNestedFields
                parentPath={parentPath}
                parentInstructionsPath={`cost_set.product`}
                method={method}
                storeName={storeName}
              />
            )}
          </TableSelectFieldWithCreate>
        </div>

        <div className="grid grid-cols-1 mb-6 gap-6">
          <RentInputField
            {...{ method, storeName }}
            fieldKey={`cost_set[${handleCostIndex}].value`}
            instructionsKey="cost_set.value"
            title="Debiteras (SEK)"
            description="Värde på debiteringen i SEK. Rabatter anges negativt, t.ex. -1000"
            area={totalArea}
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <DateSelect
            title="Startdatum"
            description="Kostnaden ska faktureras från detta datum. Lämnas tomt om kostnaden löper med avtalet."
            storeName={storeName}
            placeholder="Med avtalet"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].start_date`}
            instructionsKey="cost_set.start_date"
          />

          <DateSelect
            title="Slutdatum"
            description="Kostnaden ska faktureras till detta datum. Lämnas tomt om kostnaden löper med avtalet."
            storeName={storeName}
            placeholder="Med avtalet"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].end_date`}
            instructionsKey="cost_set.end_date"
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectFieldWithCreate
            storeName={storeName}
            placeholder="Välj projekt..."
            title="Projekt"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].project`}
            instructionsKey={"cost_set.project"}
            createDisplayKey="title"
            createTitle="Skapa nytt projekt"
            TableComponent={InvoicingProjectsTable}
          >
            {(parentPath) => (
              <ProjectNestedFields
                method={method}
                storeName={storeName}
                parentPath={parentPath}
                parentInstructionsPath={`cost_set.project`}
              />
            )}
          </TableSelectFieldWithCreate>

          <TableSelectFieldWithCreate
            storeName={storeName}
            placeholder="Välj kostnadsställe..."
            title="Kostnadsställe"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].cost_center`}
            instructionsKey={"cost_set.cost_center"}
            createDisplayKey="title"
            createTitle="Skapa nytt kostnadsställe"
            TableComponent={InvoicingCostCenterTable}
          >
            {(parentPath) => (
              <CostCenterNestedFields
                method={method}
                storeName={storeName}
                parentPath={parentPath}
                parentInstructionsPath={`cost_set.cost_center`}
              />
            )}
          </TableSelectFieldWithCreate>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectFieldWithCreate
            storeName={storeName}
            placeholder="Välj inställning..."
            title="Indexuppräkningsinställning"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].indexation`}
            instructionsKey={"cost_set.indexation"}
            createDisplayKey="title"
            createTitle="Skapa ny inställning"
            TableComponent={IndexSettingsTable}
          >
            {(parentPath) => (
              <IndexSettingNestedFields
                method={method}
                storeName={storeName}
                parentPath={parentPath}
                parentInstructionsPath={"cost_set.indexation"}
              />
            )}
          </TableSelectFieldWithCreate>
        </div>

        <div className="flex justify-end space-x-2">
          <PrimaryBtn onClick={handleEditCostDone}>Klar</PrimaryBtn>
          <PrimaryBtn error onClick={() => handleRemoveCost(handleCostIndex)}>
            Ta bort rad
          </PrimaryBtn>
        </div>
      </>
    );
  }

  return (
    <>
      {skipInvoicing && (
        <InfoBox
          boxTheme="info"
          title="Avtalet sätts ej upp för avisering"
          text={`Du har valt att ej sätta upp avtalet för avisering. Tryck på "Sätt upp för avisering" för att gå tillbaka och välja aviseringsinställning.`}
          renderButton={{
            title: "Sätt upp för avisering",
            iconType: "arrow-back",
            clicked: goBackToInvoicingSetup,
          }}
        />
      )}

      {!hideCostsFromObject && (
        <InnerBox style={{ marginBottom: 12 }}>
          <OverviewTitleWrapper style={{ scrollMargin: 50 }} ref={topRef}>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Debiteringsrader från objekt på avtal
              </OverviewTitle>
              <OverviewSubtitle>
                Dessa debiteringsrader kommer från avtalets objekt. Gå till det
                specifika objektet för att redigera en debiteringsrad.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <ConnectedObjectCosts combinedPremises={combinedPremises} />
        </InnerBox>
      )}

      <InnerBox>
        <OverviewTitleWrapper style={{ scrollMargin: 50 }} ref={topRef}>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              Avtalsspecifika debiteringsrader
            </OverviewTitle>
            <OverviewSubtitle>
              Dessa debiteringsrader är specifika för detta avtal och påverkar
              ej kopplade objekts debiteringsrader. Tryck på en rad för att
              redigera eller ta bort den.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        {(costs?.length > 0 || addContractSpecificCostsOpen) && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <SelectField
              title="Grundmomssats (%)"
              description="Momssats som ska ligga till grund för alla rader som EJ har en momssats specificerad. Om en rad har en momssats specificerad gäller den."
              {...{ storeName, method, fieldKey: "vat" }}
            />
          </div>
        )}

        {costErrors?.length && renderCostErrors()}

        {costs?.length > 0 ? (
          <>
            <Table
              onRowClicked={handleEditCost}
              columns={tableColumns}
              data={tableCosts}
              checkRowError={checkRowError}
              hideSearch
            />
            <div className="flex justify-end">
              <PrimaryBtn onClick={handleAddCost}>Lägg till rad</PrimaryBtn>
            </div>
          </>
        ) : (
          <InnerBox
            style={{
              minHeight: 100,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {addContractSpecificCostsOpen ? (
              <>
                Inga debiteringsrader är tillagda än
                <div style={{ marginTop: 24 }}>
                  <PrimaryBtn onClick={handleAddCost}>Lägg till rad</PrimaryBtn>
                </div>
              </>
            ) : (
              <>
                <TextButton
                  title="Lägg till avtalsspecifika debiteringsrader"
                  iconType="add"
                  clicked={() => setAddContractSpecifiCostsOpen(true)}
                />
              </>
            )}
          </InnerBox>
        )}
      </InnerBox>
    </>
  );
}
