import * as React from "react";
import { useFastdocTemplates } from "../../../store/editabledocs";
import Table from "../../Billecta/Table/BasicTable";

export default ({ fastDocTemplateName, choseTemplate }) => {
  const [fastDocTemplates, isLoadingFastDocTemplates] = useFastdocTemplates();

  const data = React.useMemo(() => {
    return fastDocTemplates;
  }, [fastDocTemplates]);

  const cols = React.useMemo(() => {
    return [
      {
        Header: "Namn",
        accessor: "name",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Beskrivning",
        accessor: "description",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
    ];
  }, []);

  return (
    <>
      <Table
        onRowClicked={({ original }) =>
          choseTemplate({
            id: original._id,
            type: fastDocTemplateName,
            object: original,
          })
        }
        columns={cols}
        data={data || []}
      />
    </>
  );
};
