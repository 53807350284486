export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_COMMON_AREAS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_COMMON_AREAS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_COMMON_AREAS",
  DESTROY_FORM: "DESTROY_FORM_COMMON_AREAS",
  SET_FORM_ERROR: "SET_FORM_ERROR_COMMON_AREAS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_COMMON_AREAS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_COMMON_AREAS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_COMMON_AREAS",
  RESET_STATE: "RESET_STATE_COMMON_AREAS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_COMMON_AREAS",
  REMOVE_OBJECT: "REMOVE_OBJECT_COMMON_AREAS",
  CLEAR_FETCHED: "CLEAR_FETCHED_COMMONAREAS",

  LIST_URL: "/standard/mainlevel/premises/commonarea/list/",
  POST_URL: "/standard/mainlevel/premises/commonarea/",
  PATCH_URL: "/standard/mainlevel/premises/commonarea/",
  GET_URL: "/standard/mainlevel/premises/commonarea/",

  STORE_NAME: "commonAreas",
};
