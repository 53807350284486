import * as React from "react";
// style, design

import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state

import { constants, detailUrl } from "../../../store/brfPaymentGroupQuota";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);
  const fetchAllTreshold = 25;

  const localStorageHiddenColumnId = "brf_payment_group_full_table_hidden";

  const filters = {};
  const badges = {};

  return (
    <BaseTable
      tableId={tableId || "brf_payment_group_full_table"}
      title={"Andelstal"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
