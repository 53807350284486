export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title || obj.errand_number;
};
export const detailUrl = ({ id, isGov = false }) => {
  if (isGov) return `/gov-errands/detail/${id}`;
  return `/rounding-errands/detail/${id}`;
};

export const overviewUrl = ({ isGov = false }) => {
  if (isGov) return `/gov-errands`;

  return `/rounding-errands`;
};

export const editUrl = ({ authInspection, id }) => {
  if (authInspection) {
    return `/authority-errands/edit/${id}`;
  }
  return `/rounding-errands/edit/${id}`;
};

export const createUrl = ({ id }) => {
  return `/rounding-errands/create/${id}`;
};

export const createAuthErrandUrl = ({ id }) => {
  return `/authority-errands/create/${id}`;
};

export const allUrl = () => {
  return `/rounding-errands/all`;
};

export const executeUrl = ({ id, isGov }) => {
  if (isGov) return `/gov-errands/execute/${id}`;
  return `/rounding-errands/execute/${id}`;
};

export const getRoundingOverviewStatsProcessName = () => {
  return `ROUNDING_ERRAND_OVERVIEW_STATS`;
};
