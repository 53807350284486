const defs = [
  {
    title: "Motpart",
    key: "PARTIES",
    visited: true,
    hasError: false,
    fieldKeys: ["tenant"],
  },
  {
    title: "Dokument",
    key: "EXISTINGDOC",
    visited: false,
    hasError: false,
    fieldKeys: ["editabledoc.doc"],
  },
  {
    title: "Bilagor",
    key: "ATTACHMENTS",
    visited: false,
    hasError: false,
    fieldKeys: ["attachments"],
  },
  {
    title: "Avtal",
    key: "CONTRACT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "main_contract",
      "id_number",
      "category",
      "status",
      "start_date",
      "end_date",
      "renewed_to",
      "renew_interval",
      "warning_interval",
      "notify_interval",
      "content_type",
      "object_id",
    ],
  },

  {
    title: "Signeringsstatus",
    key: "SIGN_STATUS",
    visited: false,
    hasError: false,
    fieldKeys: ["is_signed", "signed_date"],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default ({ contractType, isManualSign }) => {
  const exclude = [];

  // only include signing if existing contract, new contracts uses SCRIVE_SETTINGS
  if (contractType === "new") {
    exclude.push("EXISTINGDOC");
    exclude.push("ATTACHMENTS"); // uploaded with editable doc

    if (!isManualSign) {
      exclude.push("SIGN_STATUS");
    }
  }

  return defs.filter((d) => !exclude.includes(d.key));
};
