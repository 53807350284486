import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { deletePayment } from "../../../store/billectaInvoicing/store/actions";
import { getPaymentMeansInstance } from "../../../store/billectaPaymentMeans";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import StatusFlag, { STATUS_FLAG_STATES } from "../../Displays/StatusFlag";
import LocalDateSelect from "../../Forms/Base/Fields/LocalDateSelect";
import LocalRadioGroupField from "../../Forms/Base/Fields/LocalRadioGroupField";
import LocalSelectField from "../../Forms/Base/Fields/LocalSelectField";
import LocalTextInputField from "../../Forms/Base/Fields/LocalTextInputField";
import Modal from "../../Forms/Base/Modals/Modal";

import OverlaySpinner from "../../Loaders/OverlaySpinner";

const OPTIONS = {
  NON_ACTION: 1,
  MANUAL: 2,
  PAYMENT_MEANS: 3,
};

const todaysDate = moment().toDate();

export default ({ closeFunction, paymentId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [selectedKind, setSelectedKind] = React.useState(OPTIONS.NON_ACTION);
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [account, setAccount] = React.useState("");
  const { creditorId } = useParams();

  const [paymentMeans, setPaymentMeans] = React.useState(null);

  React.useEffect(() => {
    dispatch(
      getPaymentMeansInstance({
        creditorId,
        successCallback: (data) => {
          setPaymentMeans(data);
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta betalsätt",
              description:
                "Betalningen kan fortfarande tas bort, ange kontot manuellt för att bokföra borttagningen på ett visst konto",
            })
          );
        },
      })
    );
  }, []);

  const successCallback = (bookKeeping) => {
    setLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Betalningen togs bort",
        description: bookKeeping
          ? "Borttagandet bokfördes"
          : "Borttagandet bokförs ej",
      })
    );

    closeFunction();
  };

  const errorCallback = (message) => {
    setLoading(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Betalningen kunde ej tas bort",
        description: message ? message : "",
      })
    );
  };

  const handleRemovePayment = () => {
    setLoading(true);
    if (selectedKind === OPTIONS.NON_ACTION) {
      dispatch(
        deletePayment({
          creditorId,
          paymentId,
          successCallback: () => successCallback(false),
          errorCallback,
        })
      );
    } else {
      dispatch(
        deletePayment({
          creditorId,
          paymentId,
          transactionDate: date,
          bookKeepingAccount: account,
          successCallback: () => successCallback(true),
          errorCallback,
        })
      );
    }
  };

  const removeOptions = [
    {
      title: "Bokför inte borttagningen",
      description:
        "Observera att inbetalningen redan är bokförd och måste bokas bort manuellt med detta alternativ",
      value: OPTIONS.NON_ACTION,
    },
    {
      title: "Bokför på specifikt konto",
      description: "Skriv manuellt in konto som betalningen ska bokas bort på",
      value: OPTIONS.MANUAL,
    },
  ];

  if (!!paymentMeans) {
    removeOptions.push({
      title: "Bokför med betalsätt",
      description: "Välj ett betalsätt som betalningen bokas bort mot",
      value: OPTIONS.PAYMENT_MEANS,
    });
  }

  const canAccept =
    selectedKind === OPTIONS.NON_ACTION ? true : !!date && !!account;

  return (
    <Modal
      {...{ closeFunction }}
      title="Borttagning av betalning"
      onAccept={handleRemovePayment}
      canAccept={canAccept}
      acceptTitle={
        selectedKind === OPTIONS.NON_ACTION
          ? "Ta bort utan att bokföra borttagning"
          : "Bokför och ta bort"
      }
    >
      {loading && <OverlaySpinner />}

      <div className="mb-6">
        Betalningen har bokförts på 'Förskott från kund' i
        bokföringsinställningarna. Hur vill du att bokföringen ska ske vid
        borttagning?
      </div>

      <LocalRadioGroupField
        title="Bokföring av borttagning"
        description="Välj hur borttagningen ska bokföras"
        required
        value={selectedKind}
        onChange={(val) => setSelectedKind(val)}
        options={removeOptions}
      />

      {selectedKind === OPTIONS.MANUAL && (
        <div className="grid grid-cols-2 mb-6 gap-6">
          <LocalTextInputField
            value={account}
            onChange={(val) => setAccount(val)}
            title="Konto"
            id="account"
          />

          <LocalDateSelect
            id="date-date"
            value={date}
            title="Transaktionsdatum"
            onChange={(newDate) => setDate(newDate)}
            maxDate={todaysDate}
          />
        </div>
      )}

      {selectedKind === OPTIONS.PAYMENT_MEANS && (
        <div className="grid grid-cols-2 mb-6 gap-6">
          <LocalSelectField
            placeholder="Välj ett betalsätt"
            title="Välj betalsätt"
            required
            choices={paymentMeans.map((pm) => ({
              d: `${pm.Description} ${pm.BookkeepingAccount} (${pm.PaymentCode})`,
              v: pm.BookkeepingAccount,
            }))}
            onChange={(val) => setAccount(val)}
            value={account}
            id="pm_account"
          />
          <LocalDateSelect
            id="date-date"
            required
            value={date}
            title="Transaktionsdatum"
            onChange={(newDate) => setDate(newDate)}
            maxDate={todaysDate}
          />
        </div>
      )}

      {selectedKind === OPTIONS.NON_ACTION && (
        <StatusFlag state={STATUS_FLAG_STATES.ERROR}>
          <ExclamationTriangleIcon width={24} className="mr-2" /> Betalningen
          har bokförts. Tar du bort betalningen kommer den ligga kvar i
          bokföringen och du behöver manuellt skapa en bokföringspost för att
          boka bort den.
        </StatusFlag>
      )}
    </Modal>
  );
};
