import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./errandConfigListDefs";
import { buildRangeFilter } from "src/components/Lists/Base/utils";

// store, state
import {
  useCommonAreasPagination,
  constants,
} from "../../../store/commonAreas";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  onExcludeClicked,
}) => {
  // const filterInstructions = {
  //   common_area_id: { operator: "icontains" },
  //   "address.str_representation": { operator: "icontains" },
  //   "building.str_representation": { operator: "icontains" },
  //   "realestate.str_representation": { operator: "icontains" },
  //   area: { handler: (filterObject) => buildRangeFilter(filterObject) },
  // };

  const columns = React.useMemo(
    () => columnDefs({ onExcludeClicked }),
    [persistantQuery, onExcludeClicked]
  );

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "commonareas_errand_full_table"}
      title={"Gemensamma utrymmen"}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
