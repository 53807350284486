import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import TableFileM2m from "../../../components/Forms/Base/Fields/TableFileM2m";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  updateActiveFormInstance,
  usePermissionCheck,
} from "../../../store/base";
import {
  useEditabledoc,
  useEditabledocForm,
  constants,
  update,
} from "../../../store/editabledocs";

export default function Attachments() {
  const dispatch = useDispatch();
  const { editableDocId } = useParams();

  const [editableDoc] = useEditabledoc(editableDocId);
  useEditabledocForm("PATCH", editableDocId);

  const canChange = usePermissionCheck("change_can_contract");

  React.useEffect(() => {
    if (editableDoc) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: editableDoc,
        })
      );
    }
  }, [editableDoc]);

  const saveAttachmentsUpdate = (newFiles) => {
    const cleanData = {
      id: editableDocId,
      attachments: newFiles,
    };

    dispatch(
      update({
        id: editableDocId,
        forceData: cleanData,
      })
    );
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Bilagor på mall</OverviewTitle>
        </OverviewTitleWrapper>

        <InnerBox>
          <TableFileM2m
            extraStyles={{ maxWidth: "100%" }}
            storeName={constants.STORE_NAME}
            method={"PATCH"}
            fieldKey={"attachments"}
            title="Bilagor"
            onChangeCallback={saveAttachmentsUpdate}
            disabled={!canChange}
          />
        </InnerBox>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
