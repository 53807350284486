import baseReducer from "../../billecta/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_IMPORTANT_MESSAGES: {
      return {
        ...newState,
        importantMessages: payload.messages,
      };
    }

    case constants.DELETE_IMPORTANT_MESSAGE: {
      const newMessages = newState.importantMessages.filter(
        (m) => m.NotificationPublicId !== payload.deleteId
      );

      return { ...newState, importantMessages: newMessages };
    }

    default:
      return newState;
  }
};
