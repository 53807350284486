import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  constants,
  create,
  useBrfPremisesForm,
  destroyPatchForm,
  destroyPostForm,
  detailUrl,
  update,
} from "../../../../store/brfPremises";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import { AddressField } from "../../Base/Fields";
import SelectField from "../../Base/Fields/SelectField";
import FloorSelector from "../../Base/FieldComponents/FloorSelector";
import TableSelectField from "../../Base/Fields/TableSelectField";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import useActiveFilters from "src/hooks/useActiveFilters";
import ManyMediaField from "../../Base/Fields/ManyMediaField";

export default function BrfPremisesModalForm({
  method,
  id,
  onCheckout,
  instance,
  skipRedirect,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useBrfPremisesForm(method, id));
  const { filteredRealEstates } = useActiveFilters();
  const floors = useFormInstanceField({ storeName, fieldKey: "floors" });

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);

    if (method === "POST" && !skipRedirect) {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={
        method === "POST" ? "Lägg till bostadsrätt" : "Uppdatera bostadsrätt"
      }
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">
        Ange information om objektet
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey="premises_id"
          title={"Lägenhetsnummer"}
        />
        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey="internal_premises_id"
          title="Objektsnummer"
        />

        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            realestate__in: filteredRealEstates,
            has_brf: true,
          }}
          placeholder="Välj byggnad..."
          title="Byggnad"
          method={method}
          fieldKey="building"
          TableComponent={BuildingTable}
        />

        <SelectField
          storeName={storeName}
          method={method}
          fieldKey="category"
          title="Kategori"
          forceChoices={[
            { v: 0, d: "Annan" },
            {
              v: 1,
              d: "1 rum",
            },
            {
              v: 1.5,
              d: "1.5 rum",
            },
            {
              v: 2,
              d: "2 rum",
            },
            {
              v: 2.5,
              d: "2.5 rum",
            },
            {
              v: 3,
              d: "3 rum",
            },
            {
              v: 4,
              d: "4 rum ",
            },
            {
              v: 5,
              d: "5 rum",
            },
            {
              v: 6,
              d: "6 rum",
            },
          ]}
        />
      </div>

      <AddressField
        title="Adress"
        storeName={storeName}
        method={method}
        fieldKey="address"
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          isNumber
          storeName={storeName}
          method={method}
          fieldKey="area"
          title="Area i m&sup2;"
        />
        <TextInputField
          isNumber
          step=".01"
          storeName={storeName}
          method={method}
          fieldKey="fund_quota"
          title="Inre fondavsättning - kvot"
        />
        <TextInputField
          isNumber
          storeName={storeName}
          method={method}
          fieldKey="placement_fee"
          title="Upplåtelseavgift"
        />
        <TextInputField
          isNumber
          storeName={storeName}
          method={method}
          fieldKey="investment_capital"
          title="Insatskapital"
        />
      </div>

      <FloorSelector
        storeName={storeName}
        fieldKey={"floors"}
        floors={floors}
      />

      <ManyMediaField
        storeName={storeName}
        method={method}
        fieldKey="images"
        fileKey="image"
        title="Bilder på objektet"
        allowedFormats={[".jpg", ".jpeg", ".png"]}
      />
    </Modal>
  );
}
