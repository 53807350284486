import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import PlainForm from "../../components/Forms/User/PlainForm";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import { destroyForm, updateActiveFormInstance } from "../../store/base";
import { useUser, constants } from "../../store/users";
import PageTitle from "../Layouts/PageTitle/PageTitle";

export default () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [user] = useUser(userId);

  React.useEffect(() => {
    if (user) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: user,
        })
      );
    }
  }, [user]);

  React.useEffect(() => {
    return () => {
      dispatch(
        destroyForm({
          constants,
          method: "PATCH",
        })
      );
    };
  }, []);

  const breadCrumbs = [
    {
      label: "Min profil",
      url: "/configcenter/profile/user",
    },
    {
      label: "Redigera",
    },
  ];

  if (!user)
    return (
      <DetailPageBox style={{ height: "600px" }}>
        <OverlaySpinner />
      </DetailPageBox>
    );

  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle title="Redigera min användare" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Redigera min användare</OverviewTitle>
            <OverviewSubtitle>
              Tryck på "Uppdatera" för att spara ändringar
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <PlainForm
          method="PATCH"
          untouchedInstance={user}
          nextUrl={"/configcenter/profile/user"}
        />
      </DetailPageBox>
    </BareLayoutWrapper>
  );
};
