import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import NonConnectedRadioGroup from "../../../Base/Old/NonConnected/NonConnectedRadioGroup";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";

export const WATER_OPTIONS = [
  {
    v: 0,
    d: "Betala extra",
  },
  {
    v: 1,
    d: "Kallvatten inkluderat",
  },
  {
    v: 2,
    d: "Värme inkluderad",
  },
];

export const READABLE_WATER_OPTIONS = {
  0: "Betala extra",

  1: "Kallvatten inkluderat",

  2: "Värme inkluderad",
};

export default ({ storeName, updateDefaultSettings }) => {
  const dispatch = useDispatch();

  const defaultSettingsInstance = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const updateAttr = ({ attr, val }) => {
    dispatch(updateDefaultSettings({ attr, val }));
  };

  return (
    <>
      <NonConnectedRadioGroup
        id={`electricity`}
        title="Elektricitet ingår i hyran"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.electricity_included}
        onChange={(val) => {
          updateAttr({ attr: "electricity_included", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`internet`}
        title="Internet ingår i hyran"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.internet_included}
        onChange={(val) => {
          updateAttr({ attr: "internet_included", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`heating`}
        title="Värme ingår i hyran"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.heating_included}
        onChange={(val) => {
          updateAttr({ attr: "heating_included", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`cable_package_included`}
        title="Kabel-TV ingår i hyran"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.cable_package_included}
        onChange={(val) => {
          updateAttr({ attr: "cable_package_included", val });
        }}
      />

      <NonConnectedSelect
        id="water"
        choices={WATER_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Vatten"
        value={defaultSettingsInstance.water}
        onUpdate={(val) => {
          updateAttr({ attr: "water", val });
        }}
      />
    </>
  );
};
