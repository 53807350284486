import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useHistory } from "react-router-dom";
import { useAllPermissionCheck } from "../../../store/base";
import { overviewUrl } from "../../../store/tenantPortalSettings";

export default function IntercomIntegration() {
  const current = INTEGRATIONS.find((i) => i.title === "Intercom");

  const canChangePortalSetting = useAllPermissionCheck([
    "allow_tenantportal",
    "view_can_tenantportal",
    "change_can_tenantportal",
  ]); //CUSTOMERPERMISSION?

  const { push } = useHistory();

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: "Outlook" },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Intercoms hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Intercom-integrationen ställs in per "Mina
                  sidor"-konfiguration och tillhandahåller en chattmodul som
                  tillåter boende och hyresgäster att enkelt kontakta
                  hyresvärden via "Mina sidor".
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Registrera ett konto på Intercom och sätt upp chatten.
                  Instruktioner finns på Intercoms hemsida.
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Ange Intercom integrations-token i inställningen för den "Mina
                  sidor"-konfiguration du vill att chatten ska finnas
                  tillgänglig i.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hantera integration</OverviewTitle>
                  <OverviewSubtitle>
                    Intercom-integrationen aktiveras per "Mina
                    sidor"-inställning
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              {canChangePortalSetting ? (
                <TextButton
                  title={`Gå till konfiguration `}
                  iconType="arrow"
                  iconPlacement="right"
                  clicked={() => push(overviewUrl())}
                />
              ) : (
                <InnerBox>Behörighet till "Mina sidor" saknas.</InnerBox>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
