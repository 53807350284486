import * as React from "react";
import { TextInput } from "../Base/Fields";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  return (
    <>
      <TextInput
        title="Kod för kostnadsställe"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.title`,
          instructionsKey: `${parentInstructionsPath}.title`,
        }}
      />

      <TextInput
        title="Beskrivning"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.description`,
          instructionsKey: `${parentInstructionsPath}.description`,
        }}
      />
    </>
  );
};
