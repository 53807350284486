import * as React from "react";
import { useParams } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state

import { constants, detailUrl } from "../../../store/inspectionErrands";
import { BADGE_TYPES } from "src/components/Badge/Badge";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  const { realEstateId } = useParams();

  const columns = React.useMemo(() => columnDefs, []);
  // const exportExclude = [
  //   "connectedTo",
  //   "setting",
  //   "actual_trustee",
  //   "chosen_performer",
  //   "reported_by",
  //   "costs",
  //   "errandRatingId",
  //   "tenantRatingId",
  //   "componentRatingId",
  // ];

  const badges = {
    Avklarade: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        status__in: [3],
      },
    },
    Påbörjade: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        status__in: [1],
      },
    },
    Pausade: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        status__in: [2],
      },
    },
    "Ej påbörjade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        status__in: [0],
      },
    },
  };
  const filters = {
    Ärendenummer: {
      queryKey: "errand_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Titel: {
      queryKey: "title",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Skapat: {
      queryKey: "created_at",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    "Planerat utförandedatum": {
      queryKey: "planned_start",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    Utförandedatum: {
      queryKey: "execute_end",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  return (
    <BaseTable
      tableId={tableId || "inspection_errands_full_table"}
      title={"Besiktningar"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          realEstateId: realEstateId | obj.realestate,
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
