import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAllPermissionCheck } from "../../../../../store/base";

import { useFilteredMarketingRequirements } from "../../../../../store/marketRequirements";
import NonConnectedDatePicker from "../../../Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedRadioGroup from "../../../Base/Old/NonConnected/NonConnectedRadioGroup";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";

// style, design

export default ({ storeName, updateDefaultSettings }) => {
  const dispatch = useDispatch();

  const [availableRequirements, setAvailablaRequirements] = React.useState([]);

  const [requirementProfiles] = useFilteredMarketingRequirements("");

  React.useEffect(() => {
    if (requirementProfiles?.length && !availableRequirements?.length) {
      setAvailablaRequirements(requirementProfiles);
    }
  }, [requirementProfiles]);

  const defaultSettingsInstance = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const updateAttr = ({ attr, val }) => {
    dispatch(updateDefaultSettings({ attr, val }));
  };

  return (
    <>
      <NonConnectedDatePicker
        value={defaultSettingsInstance.access_date}
        onChange={(val) => {
          updateAttr({ attr: "access_date", val });
        }}
        label="Tillträde"
        clearable={false}
        id={`access_date_`}
      />

      <NonConnectedSelect
        extraStyles={{ minWidth: 200 }}
        label="Kravprofil"
        id={`requirement_profile_`}
        value={defaultSettingsInstance.requirement}
        choices={availableRequirements}
        getOptionLabel={(o) => o.str_representation}
        getOptionValue={(o) => o.id}
        allowNull={false}
        onUpdate={(val) => {
          updateAttr({ attr: "requirement", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`prior_access_`}
        title="Tidigare inflytt möjlig"
        options={[
          {
            label: "Ja, tidigare inflytt möjlig",
            value: true,
          },
          {
            label: "Nej, tidigare inflytt är ej möjlig",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.prior_access}
        onChange={(val) => {
          updateAttr({ attr: "prior_access", val });
        }}
      />
    </>
  );
};
