export const constructStrRep = (obj) => {
  return obj.title || "Avbetalning";
};
export const createUrl = (paymentGroupId, brfPremisesId) => {
  return `/brf-payment-contributions/create/${paymentGroupId}/${brfPremisesId}`;
};

export const updateUrl = (id) => {
  return `/brf-payment-contributions/update/${id}`;
};
