export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INVOICING_VACANCY_RECORDS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INVOICING_VACANCY_RECORDS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INVOICING_VACANCY_RECORDS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INVOICING_VACANCY_RECORDS",
  DESTROY_FORM: "DESTROY_FORM_INVOICING_VACANCY_RECORDS",
  SET_FORM_ERROR: "SET_FORM_ERROR_INVOICING_VACANCY_RECORDS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INVOICING_VACANCY_RECORDS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INVOICING_VACANCY_RECORDS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INVOICING_VACANCY_RECORDS",
  RESET_STATE: "RESET_STATE_INVOICING_VACANCY_RECORDS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INVOICING_VACANCY_RECORDS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_INVOICING_VACANCY_RECORDS",
  CLEAR_FETCHED: "CLEAR_FETCHED_INVOICING_VACANCY_RECORDS",

  LIST_URL: "/accounting/basic/billectavacancyinvoicerecord/list/",

  STORE_NAME: "invoicingVacancyRecords",
};
