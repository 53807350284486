import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: 1, display: "Aktiv" },
    { value: 2, display: "Tillsvidare" },
    { value: 3, display: "Utgående" },
    { value: 4, display: "Kommande" },
    { value: 5, display: "Ej signerat" },
    { value: 6, display: "Uppsagt för utflytt" },
    { value: 7, display: "Uppsagt för förnyelse" },
    { value: 8, display: "Ointressant" },
    { value: 9, display: "Tidigare avtal" },
    { value: 10, display: "Okänt" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};

export const sortInvoicingActive = (rowA, rowB, columnId, desc) => {
  const valueA =
    rowA.values[columnId] === true
      ? "Konfigurerad"
      : rowA.values[columnId] === null
      ? "Ej konfigurerad"
      : rowA.values[columnId] === false
      ? "Pausad"
      : "Okänd";

  const valueB =
    rowB.values[columnId] === true
      ? "Konfigurerad"
      : rowB.values[columnId] === null
      ? "Ej konfigurerad"
      : rowB.values[columnId] === false
      ? "Pausad"
      : "Okänd";

  return valueA.localeCompare(valueB);
};

export const sortEndDate = (rowA, rowB, columnId, desc) => {
  const valueA =
    rowA.values[columnId] !== null
      ? rowA.values[columnId]
      : rowA.values.renewed_to !== null
      ? rowA.values.renewed_to
      : rowA.values.end_date;

  const valueB =
    rowB.values[columnId] !== null
      ? rowB.values[columnId]
      : rowB.values.renewed_to !== null
      ? rowB.values.renewed_to
      : rowB.values.end_date;

  return valueA > valueB;
};
