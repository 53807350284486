import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { NumberInput, Select } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingSettings";
import { useFormInstanceField } from "../../../../../store/base";
import { useDispatch } from "react-redux";
import { InfoBox } from "../../../../Displays";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const intervalMode = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.mode",
  });

  return (
    <>
      <FormAreaTitle>Faktureringsintervall </FormAreaTitle>
      <Select
        title="Typ av faktureringsintervall"
        description={`Typ av intervall för när fakturor ska genereras och skickas. Vid månadsvis fakturering väljs t.ex. "Per månad".`}
        {...{ storeName, method, fieldKey: "interval_setting.mode" }}
      />

      {/* Per month */}
      {intervalMode === 0 && (
        <>
          <NumberInput
            title="Månadsintervall"
            description={`Ange i vilket månadsintervall fakturor ska genereras. Ska en faktura genereras varje månad anges 1, varannan månad 2 osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i varje månad som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      {/* Quarterly */}
      {intervalMode === 1 && (
        <>
          <NumberInput
            title="Kvartalsintervall"
            description={`Ange i vilket kvartalsintervall fakturor ska genereras. Ska en faktura genereras varje kvartal anges "1", varannat kvartal "2" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          <NumberInput
            title="Månad i kvartal som faktura ska genereras"
            description={`Ange vilken månaden i kvartalet som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas 1:a månaden kvartalet så anges "1".`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.on_month",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      {/* yearly */}
      {intervalMode === 2 && (
        <>
          <NumberInput
            title="Årsintervall"
            description={`Ange i vilket årsintervall fakturor ska genereras. Ska en faktura genereras varje år anges "1", varannat år "2" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          <NumberInput
            title="Månad som faktura ska genereras"
            description={`Ange vilken månaden på året som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas i Januari så anges "1", Juli så anges "7" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.on_month",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      <Select
        title="Faktureringsperiod"
        {...{ storeName, method, fieldKey: "interval_setting.invoice_period" }}
      />
    </>
  );
};
