import * as React from "react";

// style, design
import {
  DateCell,
  LinkedObject,
  TimeCell,
  ToolTipCell,
  BooleanLabel,
} from "../../Displays";

import { detailUrl as keypermissionDetailUrl } from "../../../store/keypermission";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import BooleanFilter from "src/components/Lists/Base/CompleteList/Filters/BooleanFilter";
import DateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import { StatusLabel } from "./styles";
import { calculateStatus, STATUS_MAPPING } from "../../../store/key";

export default () => [
  {
    Header: "Status",
    id: "status",
    Cell: ({ value, row }) => {
      const status = calculateStatus(row.original);
      return (
        <StatusLabel status={status}>{STATUS_MAPPING[status]}</StatusLabel>
      );
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Motpart",
    accessor: "user.str_representation",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Innehavande status",
    accessor: "possession_display",
    Cell: ({ value, row }) => {
      const status = row.original.possession == 2 ? 6 : 5;
      return <StatusLabel status={status}>{value}</StatusLabel>;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Namn",
    accessor: "key_name",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Serienummer",
    accessor: "serial_number",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Elektronisk nyckel",
    accessor: "electronic_key",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} onLabel="Ja" offLabel="Nej" />;
    },
    Filter: BooleanFilter,
    filter: "text",
  },
  {
    Header: "Pinkod",
    accessor: "pin_code",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Gäller f.o.m",
    accessor: "start_time",
    Cell: ({ value, row }) => {
      return <TimeCell date={value} />;
    },
    Filter: DateFilter,
    filter: "between",
  },
  {
    Header: "Gäller t.o.m",
    accessor: "end_time",
    Cell: ({ value, row }) => {
      return <TimeCell date={value} />;
    },
    Filter: DateFilter,
    filter: "between",
  },
  {
    Header: "Blockerad auto-tid",
    accessor: "block_automatic_time_conforming",
    Cell: ({ value }) => {
      return <BooleanLabel value={value} onLabel="Ja" offLabel="Nej" />;
    },
    Filter: BooleanFilter,
    filter: "text",
  },
  {
    Header: "Autoskapad",
    accessor: "auto_created",
    Cell: ({ value }) => {
      return <BooleanLabel value={value} onLabel="Ja" offLabel="Nej" />;
    },
    Filter: BooleanFilter,
    filter: "text",
  },
  {
    Header: "Nyckelbehörighet",
    accessor: "permission",
    Cell: ({ value }) => {
      return <LinkedObject obj={value} urlMethod={keypermissionDetailUrl} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
