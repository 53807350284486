import * as React from "react";
import * as SharedStyles from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import RealEstateTable from "../../../components/Tables/RealEstate/FullTable";
import Alert from "src/components/Alert/Alert";

export default function RealEstates() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  return (
    <>
      <SharedStyles.DetailInnerWrapper>
        <SharedStyles.DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>
          </OverviewTitleWrapper>

          <Alert title="Filtrera globalt på fastigheter">
            Genom att markera en eller flera fastigheter i listan och klicka på
            "Filtrera" så filtrerar du systemet globalt på objekt som ligger
            under dessa fastigheter.
          </Alert>

          <RealEstateTable withSelect {...{ persistantQuery }} />
        </SharedStyles.DetailPageBox>
      </SharedStyles.DetailInnerWrapper>
    </>
  );
}
