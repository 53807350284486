import React, { useRef } from "react";
import * as SC from "../../components/Layout/Insights/styles";
import PageTopBar from "../../components/Layout/Insights/PageTopBar";
import WidgetPlacement from "./WidgetPlacement";
import {
  constants as widgetPlacementConstants,
} from "../../store/widgetPlacements";
import InsightsPageForm from "../../components/Forms/Insights/InsightsPage/InsightsPageForm";
import InsightsGoalOverview from "../../components/Forms/Insights/InsightsGoal/InsightsGoalOverview";
import { WidgetPlacementDefaults } from "./WidgetPlacementDefaults";
import { useSelector } from "react-redux";
import OverlaySpinnerLoadingBar from "src/components/Loaders/OverlaySpinnerLoadingBar";
import { buildQueryString, usePermissionCheck } from "src/store/base";
import useActiveFilters from "src/hooks/useActiveFilters";
import { useFilteredCompanies } from "src/store/companies";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { constants } from "src/store/insightsPage";

export default function DefaultInsightsPage() {
  const [insightsPageFormOpen, setInsightsPageFormOpen] = React.useState(false);

  const {push} = useHistory();

  let redirectPage = false
  if (window?.insightsPageId) redirectPage = true
  
  if (redirectPage) {
  push(`insights/page/${window.insightsPageId}`)

  }

  const [goalOverviewOpen, setGoalOverviewOpen] = React.useState(false);
  const [realestateIds, setRealestateIds] = React.useState([]);
  const [actualRealestateIds, setActualRealestateIds] = React.useState([]);

  const formMethod = "POST";
  const gridRef = useRef();

  const hasEditPermission = usePermissionCheck("change_can_insights");
  const hasViewPermission = usePermissionCheck("view_can_insights");
  const canViewConfigCenter = usePermissionCheck("view_can_configcenter");
  const { filterActive, filteredRealEstates, filteredCompanies } =
    useActiveFilters();

  const companyQuery = buildQueryString({
    id__in: filteredCompanies,
  });
  const [companies, companiesLoading] = useFilteredCompanies(companyQuery);

  const [gridHeight, setGridHeight] = React.useState(null);

  const pageWidth = 6
  const pageHeight = 10

  const reduxRealEstateIds = useSelector((state) => state[constants.STORE_NAME].resultData?.localRealEstateIds);

  React.useEffect(() => {

    if (reduxRealEstateIds && realestateIds.toString() !== reduxRealEstateIds.toString())  {
    setRealestateIds(reduxRealEstateIds)
    }
  }, [reduxRealEstateIds, hasViewPermission])

  React.useEffect(() => {
    if (hasViewPermission) return
    if (!filterActive) {
      setRealestateIds([]);
      return;
    }
    let _realestateIds = new Set(filteredRealEstates);
    if (companiesLoading) return;
    companies.map((company) => {
      company.realestates.map((realestateId) => {
        _realestateIds.add(realestateId);
      });
    });
    setRealestateIds(Array.from(_realestateIds));
  }, [companiesLoading, filterActive]);

  const doSetRealestateIds = (liRealestateIds) => {
    setActualRealestateIds(liRealestateIds);
  };
  const debounceRealestateIds = React.useCallback(
    debounce(doSetRealestateIds, 500),
    [realestateIds]);
  React.useEffect(() => {
    debounceRealestateIds(realestateIds)
  }, [realestateIds]);

  const activeFilters = JSON.parse(localStorage.getItem("active_filters"))
  let needRealEstateIds = false
  if (activeFilters && Object.values(activeFilters).some((elem) => elem?.length > 0)) needRealEstateIds = true
  if (hasViewPermission && reduxRealEstateIds && reduxRealEstateIds.length > 0) needRealEstateIds = true

  const handleOpenInsightsForm = (method) => {
    setInsightsPageFormOpen(true);
  };
  const widgetsInProgress = useSelector((state) => {
    return state[widgetPlacementConstants.STORE_NAME].inProgress.length;
  });

  React.useLayoutEffect(() => {
      setGridHeight(gridRef?.current?.offsetWidth / pageWidth * pageHeight * 0.8)
  },  [gridRef?.current?.offsetWidth])
  return (
    <SC.InsightsPageContainer>
      {widgetsInProgress != 0 && (
        <>
          <OverlaySpinnerLoadingBar
            currentCount={Math.max(
              WidgetPlacementDefaults.length - widgetsInProgress,
              0
            )}
            totalCount={WidgetPlacementDefaults.length}
          />
        </>
      )}

      {insightsPageFormOpen && (
        <InsightsPageForm
          method={formMethod}
          open={true}
          onCheckout={() => setInsightsPageFormOpen(false)}
        />
      )}

      <InsightsGoalOverview
        defaultPage={true}
        open={goalOverviewOpen}
        checkout={() => setGoalOverviewOpen(false)}
        insightsPage={{ id: "dashboard-default" }}
      />

      <PageTopBar
        _currentPage={{ id: "dashboard-default", title: "Dashboard" }}
        defaultPage={true}
        openModal={handleOpenInsightsForm}
        setGoalOverviewOpen={setGoalOverviewOpen}
      />
      <div style={{ overflowX: "auto", marginRight: "20px" }}>
        <SC.WidgetPlacementsGrid
          id="widgetPlacementsGrid"
          width={pageWidth}
          height={pageHeight}
          gridHeight={gridHeight}
          ref={gridRef}
        >
          {!redirectPage && (
          <>
          {WidgetPlacementDefaults?.map((place, idx) => (
            <SC.WidgetPlacementContainer
              key={`${idx}__${place.id}`}
              columnStart={place.grid_column}
              columnEnd={place.grid_column_end}
              rowStart={place.grid_row}
              rowEnd={place.grid_row_end}
            >
              <WidgetPlacement
                gridRef={gridRef}
                jsonWidgetPlacement={place}
                hasEditPermission={hasEditPermission}
                realestateIds={actualRealestateIds}
                needRealEstateIds={needRealEstateIds}
                localFilter={hasViewPermission}
                canViewConfigCenter={canViewConfigCenter}
              />
            </SC.WidgetPlacementContainer>
          ))}
</>
          )}
        </SC.WidgetPlacementsGrid>
      </div>
    </SC.InsightsPageContainer>
  );
}
