import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design

// store, state
import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  useBrfCompanyForm,
} from "../../../store/brfCompanies";
import StandardModal from "../../Modals/StandardModal";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import DocumentUploader from "../Base/Fields/DocumentUploader";
import { DatePicker } from "../Base/Fields";

export default ({ method, id, onCheckout, isOpen }) => {
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const formLoaded = Boolean(useBrfCompanyForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  return (
    <StandardModal
      saveFunction={onSubmit}
      isOpen={isOpen}
      closeFunction={onCheckout}
      withActionBar
      title={
        method === "PATCH"
          ? "Uppdatera ekonomisk plan"
          : "Lägg till ekonomisk plan"
      }
    >
      {loading && <OverlaySpinner />}

      <DocumentUploader
        storeName={storeName}
        method={method}
        fieldKey={"financial_plan"}
        title="Ekonomisk plan"
      />

      <DatePicker
        storeName={storeName}
        method={method}
        fieldKey={"date_financial_plan"}
        title="Skapandedatum av ekonomisk plan"
      />
    </StandardModal>
  );
};
