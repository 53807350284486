import * as React from "react";

// style, design

import { Checkbox, TextInput, TextField } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/sendout";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InfoBox } from "../../../../Displays";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Utskick</OverviewTitle>
          <OverviewSubtitle>
            Ange informationen som ska med i utskicket
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {method === "PATCH" && (
        <InfoBox
          title="OBS"
          text='Vid redigering av ett utskick som redan har skickats ut kommer ändringarna enbart att reflekteras inne på "Mina sidor". Utskicket kommer alltså inte att skickas om till mottagarna per E-post eller övriga kommunikationskanaler.'
        />
      )}

      <hr />

      <TextInput
        storeName={storeName}
        fieldKey={"title"}
        title="Titel"
        method={method}
      />
      <TextField
        storeName={storeName}
        fieldKey={"message"}
        title="Meddelande"
        method={method}
        rows={20}
      />
    </>
  );
};
