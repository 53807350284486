import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import FlowForm from "../../components/Forms/ParkingLot/FlowForm/FlowForm";
import { updateActiveFormInstance } from "../../store/base";
import { useParkingLot, constants } from "../../store/parkingLots";

export default () => {
  const dispatch = useDispatch();

  const { parkingLotId } = useParams();
  const [parkingLot] = useParkingLot(parkingLotId);

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: parkingLot,
      })
    );
  }, [parkingLot]);

  return <FlowForm id={parkingLotId} method="PATCH" />;
};
