import * as React from "react";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import { useFilteredParkingSpecifications } from "../../../store/parkingSpecifications";

import { RelationalField } from "../Base/FieldComponents";

import { TextInput } from "../Base/Fields";
import NestedSpecification from "../ParkingSpecification/NestedChildren";

import TableSelectField from "../Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  const { filteredRealEstates } = useActiveFilters();

  const realEstateFilter = buildQueryString({
    realestate_ids: filteredRealEstates,
  });

  return (
    <>
      <TextInput
        title="Namn på parkeringsområdet"
        storeName={storeName}
        fieldKey={`${parentPath}.title`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.title`}
      />

      <RelationalField
        storeName={storeName}
        method={method}
        modalInModal
        fetchMethod={() => useFilteredParkingSpecifications(realEstateFilter)} //
        fieldKey={`${parentPath}.specification`}
        title="Specifikationer och regler"
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.specification`}
      >
        <NestedSpecification
          storeName={storeName}
          method={method}
          parentPath={`${parentPath}.specification`}
          parentInstructionsPath={`${
            parentInstructionsPath || parentPath
          }.specification`}
        />
      </RelationalField>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          TableComponent={RealEstateTable}
          placeholder="Välj fastigheter..."
          title="Fastigheter"
          method={method}
          fieldKey={`${parentPath}.realestates`}
          instructionsKey={`${
            parentInstructionsPath || parentPath
          }.realestates`}
          isMany
        />
      </div>
    </>
  );
};
