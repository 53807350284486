import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Checkbox, NumberInput, RadioGroup } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketRequirements";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Övrigt</FormAreaTitle>

      <NumberInput
        {...{ storeName, method }}
        fieldKey="move_frequency"
        title="Max antal tillåtna flyttar de senaste 3 åren"
        description="Gäller per hushåll"
      />

      <NumberInput
        {...{ storeName, method }}
        fieldKey="extra_residents"
        title="Max antal tillåtna extra hyresgäster utöver antal rum"
        description="Exempel: 1 extra hyresgäst i en 2:a innebär totalt 3 hyresgäster som max"
      />

      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="smoking"
          storeName={storeName}
          method={method}
          title="Tillåt rökning i lägenheten"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="pets"
          storeName={storeName}
          method={method}
          title="Tillåt husdjur i lägenheten"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>
    </>
  );
};
