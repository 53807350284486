import { retrieve, update, upload } from "../base/store/services";

export const retrieveAccessKeys = async (accessId) => {
    const result = await retrieve({url: "keyhandling/parakey/accesskey/list/", data:accessId == null ? undefined : {parakey_access_id:accessId} });
    return result?.data ?? [];
}

export const retrieveParakeyAccess = async (accessId) => {
    const result = await retrieve({url: `keyhandling/parakey/access/${accessId}/` });
    return result?.data ?? {};
}

export const retrieveAccesses = async (doorIds) => {
    const result = await retrieve({url: "keyhandling/parakey/access/list/" , data: doorIds == null ? undefined : {parakey_door_ids:doorIds}});
    return result?.data ?? [];
}

export const retrieveDevices = async (accessId) => {
    const result = await retrieve({url: "keyhandling/parakey/device/list/", data:accessId == null ? undefined : {parakey_access_id:accessId} });
    return result?.data ?? [];
}

export const collectAccess = async () => {
    await update({url: "keyhandling/parakey/keypermission/collect/" });
}

export const collectKeys = async () => {
    await update({url: "keyhandling/parakey/key/collect/" });
}

export const collectDoors = async () => {
    await update({url: "keyhandling/parakey/keydevice/collect/" });
}

export const bulkUploadKeys = async () => {
    await upload({url: "keyhandling/parakey/key/bulk_create/" });
}