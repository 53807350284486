import * as React from "react";
import { useParams, useHistory } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import { createUrl as keyPermissionCreateUrl } from "../../../store/keypermission";
import { createUrl as keyCreateUrl } from "../../../store/key";

import KeyTable from "src/components/Tables/Keys/FullTable";
import KeyPermissionTable from "../../../components/Tables/KeyPermissions/FullTable";
import KeyDeviceTable from "../../../components/Tables/KeyDevices/FullTable";

import { TextButton } from "../../../components/Forms/Base/Buttons";
import StandardModal from "../../../components/Modals/StandardModal";
import {
  useFilteredKeyDevices,
  createUrl as keyDeviceCreateUrl,
} from "../../../store/keydevice";
import { retrieveAccesses } from "../../../store/parakey";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";

export default function CommonAreaKeys() {
  const { push } = useHistory();
  const { commonAreaId } = useParams();

  const [selectPermissionModalOpen, setSelectPermissionModalOpen] =
    React.useState(false);
  const [selectedPermission, setSelectedPermission] = React.useState(undefined);

  const canParakey = useAllPermissionCheck([
    "allow_parakey",
    "view_can_parakey",
  ]);
  const canAddKey = useAllPermissionCheck(["allow_keys", "add_can_keys"]);

  const [parakeyAccess, setParakeyAccesses] = React.useState(undefined);
  const [parakeyKeyDevices, parakeyKeyDevicesLoading] = useFilteredKeyDevices(
    buildQueryString({ common_area: commonAreaId, parakey_id__isnull: false })
  );

  const keyQuery = { common_areas: commonAreaId };

  const keyDeviceQuery = { common_area: commonAreaId };

  const keyPermissionQuery = {
    common_areas: commonAreaId,
  };
  const keyPermissionFilterMethod = (perm) =>
    perm.common_areas.some((a) => `${a.id}` === commonAreaId);

  const continueToKeyCreate = () => {
    setSelectPermissionModalOpen(false);

    if (selectedPermission) {
      push(keyCreateUrl() + `/keypermission/${selectedPermission.id}`);
    } else {
      push(keyCreateUrl());
    }
  };

  const loadParakeyPermission = async () => {
    const doorIds = parakeyKeyDevices.map((i) => i.parakey_id);
    if (!doorIds.length) {
      setParakeyAccesses([]);
    } else {
      const data = await retrieveAccesses(doorIds);
      setParakeyAccesses(data);
    }
  };

  React.useEffect(() => {
    if (
      parakeyKeyDevices != null &&
      parakeyKeyDevices?.length &&
      parakeyAccess == null
    ) {
      loadParakeyPermission();
    }
  }, [parakeyKeyDevices]);

  return (
    <>
      <StandardModal
        isOpen={selectPermissionModalOpen}
        saveFunction={continueToKeyCreate}
        closeFunction={() => setSelectPermissionModalOpen(false)}
        withActionBar
        title="Välj nyckelbehörighet"
        actionBarAcceptTitle={"Gå vidare"}
        actionBarCancelTitle={"Avbryt"}
      >
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={KeyPermissionTable}
            persistantQuery={keyPermissionQuery}
            placeholder="Välj nyckelbehörighet..."
            title="Nyckelbehörighet"
            value={selectedPermission}
            onChange={(obj) => setSelectedPermission(obj)}
          />
        </div>
      </StandardModal>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              minWidth: "58%",
              alignSelf: "flex-start",
              marginRight: "8px",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Nycklar</OverviewTitle>
                {canAddKey && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => setSelectPermissionModalOpen(true)}
                    />
                  </div>
                )}
              </OverviewTitleWrapper>
              <KeyTable persistantQuery={keyQuery} isBare ignoreLocalStorage />
            </DetailPageBox>

            {canParakey && (
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small>Parakey-kopplade Nycklar</OverviewTitle>
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => setSelectPermissionModalOpen(true)}
                    />
                  </div>
                </OverviewTitleWrapper>
                <KeyTable
                  persistantQueryString={{
                    parakey_permission_ids: !parakeyAccess
                      ? []
                      : parakeyAccess.map((item) => item.id),
                  }}
                  isBare
                  ignoreLocalStorage
                />
              </DetailPageBox>
            )}
          </div>
          <div style={{ minWidth: "39%", alignSelf: "flex-start" }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Nyckelbehörigheter</OverviewTitle>
                {canAddKey && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() =>
                        push(
                          keyPermissionCreateUrl() +
                            `/common-area/${commonAreaId}`
                        )
                      }
                    />
                  </div>
                )}
              </OverviewTitleWrapper>
              <KeyPermissionTable
                persistantQuery={keyPermissionQuery}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
            {canParakey && (
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small>
                    Parakey-styrda Behörigheter
                  </OverviewTitle>
                </OverviewTitleWrapper>
                <KeyPermissionTable
                  persistantQuery={{
                    parakey_id__in: !parakeyAccess
                      ? []
                      : parakeyAccess.map((item) => item.id),
                  }}
                  isBare
                  ignoreLocalStorage
                />
              </DetailPageBox>
            )}
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Dörrar</OverviewTitle>
                {canAddKey && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() =>
                        push(
                          keyDeviceCreateUrl() + `/common-area/${commonAreaId}`
                        )
                      }
                    />
                  </div>
                )}
              </OverviewTitleWrapper>
              <KeyDeviceTable
                persistantQuery={keyDeviceQuery}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
