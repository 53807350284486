import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import LeaseList from "src/components/Tables/LeaseContracts/FullTableNewVersion";
// import LeaseList from "src/components/Tables/LeaseContracts/FullTable";
import useActiveFilters from "../../../hooks/useActiveFilters";

export const PERSISTANT_CATEGORY_KEY = "persistant_category_leasecontract";

export default function LeaseContracts() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate__in: filteredRealEstates,
    draft: false,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Hyresavtal</OverviewTitle>
          </OverviewTitleWrapper>
          <LeaseList persistantQuery={persistantQuery} hideTitle />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
