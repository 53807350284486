import * as React from "react";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { detailUrl as productTypeDetailUrl } from "../../../../../store/productTypes";
import ProductTypeNestedChildren from "../../../ProductType/NestedChildren";
import { useErrandComponent } from "../../../../../store/errandComponents";
import { getCurrentProductTypeUsage } from "../../../../TechnicalManagement/utils";
import { DateCell, InfoBox, LinkedObject } from "../../../../Displays";
import { InnerBox } from "../../../../sharedStyles";
import DetailInfo from "../../../../Details/OverviewInfo/DetailInfo/DetailInfo";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import ProductTypesTable from "src/components/Tables/ErrandProductTypes/FullTable";
import DateSelect from "src/components/Forms/Base/Fields/DateSelect";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";

export default function Component({ method, storeName, patchId }) {
  const [current, currentLoading] = useErrandComponent(
    method === "POST" ? null : patchId
  );
  const currentProductTypeUsage = getCurrentProductTypeUsage(current);

  // last index or first ig empty room
  const newIndex = current?.product_types?.length || 0;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för Komponent</OverviewTitle>
          <OverviewSubtitle>
            Specificera komponentmodell och installationsdatum för denna
            komponent
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {method === "PATCH" ? (
        <>
          <InfoBox
            title="Installation av ny komponent"
            text="Uppdatera denna komponent genom att lägga till en ny komponentmodell som ersätter den gamla. Den tidigare komponentmodellen kommer fortfarande att finnas kvar för historikens och statistikens skull."
          />

          <InnerBox>
            <DetailInfo
              infoObj={{
                "Nuvarande komponentmodell": [
                  {
                    title: "Komponentmodell",
                    value: (
                      <LinkedObject
                        obj={currentProductTypeUsage?.product_type}
                        urlMethod={productTypeDetailUrl}
                      />
                    ),
                  },
                  {
                    title: "Installerades",
                    value: (
                      <DateCell date={currentProductTypeUsage?.from_date} />
                    ),
                  },
                  {
                    title: "Serienummer",
                    value: currentProductTypeUsage?.code || "-",
                  },
                ],
              }}
            />

            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectFieldWithCreate
                storeName={storeName}
                TableComponent={ProductTypesTable}
                placeholder="Välj komponentmodell..."
                title={"Ny komponentmodell"}
                method={method}
                fieldKey={`product_types[${newIndex}].product_type`}
                instructionsKey={"product_types.product_type"}
                createDisplayKey={"name"}
              >
                {(parentPath) => (
                  <ProductTypeNestedChildren
                    {...{
                      storeName,
                      method,
                      parentPath,
                      parentInstructionsPath: `product_types.product_type`,
                    }}
                  />
                )}
              </TableSelectFieldWithCreate>
            </div>

            <div className="grid grid-cols-2 gap-6 mb-6">
              <DateSelect
                {...{
                  storeName,
                  title: "Installerades",
                  method,
                  fieldKey: `product_types[${newIndex}].from_date`,
                  instructionsKey: "product_types.from_date",
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-6 mb-6">
              <TextInputField
                {...{
                  storeName,
                  title: "Serienummer",
                  description:
                    "Serienummer används för att identifiera en specifik komponent",
                  method,
                  fieldKey: `product_types[${newIndex}].code`,
                  instructionsKey: "product_types.code",
                }}
              />
            </div>
          </InnerBox>
        </>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectFieldWithCreate
              storeName={storeName}
              TableComponent={ProductTypesTable}
              placeholder="Välj komponentmodell..."
              title={"Komponentmodell"}
              method={method}
              fieldKey={`product_types[0].product_type`}
              instructionsKey={"product_types.product_type"}
              createDisplayKey={"name"}
            >
              {(parentPath) => (
                <ProductTypeNestedChildren
                  {...{
                    storeName,
                    method,
                    parentPath,
                    parentInstructionsPath: `product_types.product_type`,
                  }}
                />
              )}
            </TableSelectFieldWithCreate>
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <DateSelect
              {...{
                storeName,
                title: "Installerades",
                method,
                fieldKey: `product_types[${newIndex}].from_date`,
                instructionsKey: "product_types.from_date",
              }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              {...{
                storeName,
                title: "Serienummer",
                description:
                  "Serienummer används för att identifiera en specifik komponent",
                method,
                fieldKey: `product_types[0].code`,
                instructionsKey: "product_types.code",
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
