import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewContent,
  OverviewItem,
  OverviewItemTitle,
  OverviewItemValue,
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, InfoBox, LinkedObject } from "../../../components/Displays";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import FullPageSpinner from "../../../components/Loaders/FullPageSpinner";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { buildQueryString } from "../../../store/base";
import {
  activateAutogiroForInvoice,
  cancelDebtCollection,
  cancelRemindersForInvoice,
  inactivateAutogiroForInvoice,
  invoiceDetailUrl,
  postponeDebtCollection,
  registerDebtHandlingPaymnet,
  registerInvoicePayment,
} from "../../../store/billectaInvoicing";
import {
  useDebtCollectionAction,
  useInvoice,
  useReminderInvoice,
} from "../../../store/billectaInvoicing/hooks/retrieve";
import { downloadBillectaFile } from "../../../store/billectaInvoicing/store/actions";
import { DELIVERY_METHOD_MAPPER_EXCL_PRICES } from "../../../store/billectaInvoicing/utils";
import { useFilteredDebtorInvoicings } from "../../../store/invoicingDebtor";
import {
  detailUrl as tenantDetailUrl,
  useTenant,
} from "../../../store/tenants";
import { TOAST_TYPES, addToast } from "../../../store/toasts";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as productDetailUrl } from "../../../store/invoicingProducts";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { detailUrl as userDetailUrl } from "../../../store/users";

import Table from "../../../components/Billecta/Table/BasicTable";
import { ItemTitle } from "../../../components/Details/OverviewInfo/DetailInfo/styles";
import ConfirmModal from "../../../components/Forms/Base/Modals/ConfirmModal";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import RegisterInvoicePayment from "../../../components/Forms/Invoice/RegisterPayment";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useFilteredInvoicingRecords } from "../../../store/invoicingRecords";
import { useFilteredVacancyInvoicingRecords } from "../../../store/invoicingVacancyRecords";
import theme from "../../../theme";

export default function InvoiceOverview() {
  const dispatch = useDispatch();
  const { invoiceId, creditorId } = useParams();
  const [invoice, invoiceLoading] = useInvoice({ creditorId, invoiceId });

  const [debtCollectionInfo, debtCollectionInfoLoading] =
    useDebtCollectionAction({
      debtCollectionId: invoice?.DebtCollectionActionPublicId,
    });
  const [debtCollectionNextDate, setDebtCollectionNextDate] =
    React.useState(null);
  const [cancelDebtCollectionActive, setCancelDebtCollectionActive] =
    React.useState(null);

  React.useEffect(() => {
    if (!debtCollectionNextDate && debtCollectionInfo?.State?.NextEventDate) {
      setDebtCollectionNextDate(
        moment(debtCollectionInfo.State.NextEventDate).format("YYYY-MM-DD")
      );
    }
  }, [debtCollectionInfo?.State?.NextEventDate]);

  const isVacancyBookingInvoice =
    invoice?.ActionType === "VerificationInvoiceAction";

  // get debit invoice which credit invoice credits
  const creditingInvoiceId = invoice?.CreditingInvoice?.SourcePublicId;
  const creditingInvoiceNumber = invoice?.CreditingInvoice?.SourceInvoiceId;

  const [loading, setLoading] = React.useState(false);
  const [autogiroLoading, setAutogiroLoading] = React.useState(false);
  const [confirmCancelReminderOpen, setConfirmCancelReminderOpen] =
    React.useState(false);

  const debtorQuery = buildQueryString({
    billecta_object_ids: invoice?.Debtor?.DebtorPublicId || [],
  });

  const [debtors] = useFilteredDebtorInvoicings(debtorQuery);
  const debtor = debtors?.[0];

  const [tenant] = useTenant(debtor?.tenant?.id);

  const hasAttested = !!invoice?.State?.AttestedDate;
  const canAttest = moment(invoice?.InvoiceDate).isSameOrBefore(moment());
  const [periodStart, periodEnd] = calculatePeriodForInvoice(invoice?.Records);
  const isCreditInvoice = invoice?.ActionType === "CreditInvoiceAction";
  const canRegisterPayment = invoice?.CanRegisterPayment;
  const nextEvent = invoice?.State?.NextEvent;
  const nextEventDate = invoice?.State?.NextEventDate;
  const autoGiroActivated = !!invoice?.Autogiro;
  const canActivateAutogiro = !!invoice?.CanCreateAutogiroWithdrawal;
  const canInactivateAutogiro = autoGiroActivated;
  const isPaused = invoice?.State?.IsPaused;

  const invoiceRecordQ = buildQueryString({
    billecta_object_ids: invoiceId || [],
  });
  const [invoiceRecords] = useFilteredInvoicingRecords(invoiceRecordQ);
  const invoiceRecord = invoiceRecords?.[0];
  const [vacancyInvoiceRecords] =
    useFilteredVacancyInvoicingRecords(invoiceRecordQ);
  const vacancyInvoiceRecord = vacancyInvoiceRecords?.[0];

  const [reminderInvoice] = useReminderInvoice({
    reminderInvoiceId: invoice?.ReminderInvoiceActionPublicId,
    invoiceId,
  });

  const handleRegisterPayment = ({
    value,
    currency,
    paidDate,
    overpaymentHandle,
    reference,
    paymentMeansCode,
    invoiceInterest,
    isPaymentCreditation,
    isDebtHandling,
  }) => {
    setLoading(true);
    if (isDebtHandling) {
      dispatch(
        registerDebtHandlingPaymnet({
          invoiceActionId: invoiceId,
          debtCollectionActionId: invoice?.DebtCollectionActionPublicId,
          creditorId,
          paymentData: {
            value,
            currency,
            paidDate,
            reference,
            paymentMeansCode,
            invoiceInterest,
            isPaymentCreditation,
          },
          successCallback: () => {
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Betalningen lades till",
              })
            );
            setLoading(false);
          },
          errorCallback: (message) => {
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Betalningen kunde inte läggas till",
                description: message || "",
              })
            );
            setLoading(false);
          },
        })
      );
    } else {
      dispatch(
        registerInvoicePayment({
          invoiceActionId: invoiceId,
          creditorId,
          paymentData: {
            value,
            currency,
            paidDate,
            overpaymentHandle,
            reference,
            paymentMeansCode,
          },
          successCallback: () => {
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Betalningen lades till",
              })
            );
            setLoading(false);
          },
          errorCallback: (message) => {
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Betalningen kunde inte läggas till",
                description: message || "",
              })
            );
            setLoading(false);
          },
        })
      );
    }
  };

  const downloadReminderInvoice = ({ filePublicId }) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Hämtar påminnelsefaktura...",
      })
    );

    dispatch(downloadBillectaFile({ filePublicId }));
  };

  const cancelReminders = () => {
    dispatch(
      cancelRemindersForInvoice({
        invoiceActionId: invoice?.ActionPublicId,
        creditorId: invoice.CreditorPublicId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Påminnelser avbrutna",
            })
          );
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej avbryta påminnelser",
              description:
                message ||
                "Försök igen, kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const performPostponeDebtCollection = () => {
    setLoading(true);
    dispatch(
      postponeDebtCollection({
        invoiceActionId: invoice.ActionPublicId,
        creditorId: invoice.CreditorPublicId,
        debtCollectionActionId: debtCollectionInfo.ActionPublicId,
        toDate: debtCollectionNextDate,
        successCallback: () => {
          setDebtCollectionNextDate(null);
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Kravhantering framskjuten",
            })
          );
        },
        errorCallback: (message) => {
          setDebtCollectionNextDate(null);
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej skjuta fram kravhanteringshändelsen",
              description:
                message ||
                "Försök igen, kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const performCancelDebtCollection = () => {
    setLoading(true);
    dispatch(
      cancelDebtCollection({
        invoiceActionId: invoice.ActionPublicId,
        creditorId: invoice.CreditorPublicId,
        debtCollectionActionId: debtCollectionInfo.ActionPublicId,
        successCallback: () => {
          setDebtCollectionNextDate(null);
          setCancelDebtCollectionActive(false);
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Kravhantering avbröts",
            })
          );
        },
        errorCallback: (message) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej avbryta kravhanteringen",
              description:
                message ||
                "Försök igen, kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const costRows = React.useMemo(() => {
    return invoice?.Records?.filter((r) => r.RecordType === "Standard") || [];
  }, [invoice]);

  const costColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Typ",
        id: "type",
        Cell: ({ row }) => {
          if (isVacancyBookingInvoice) {
            return <StatusLabel state={0}>Vakansbokning</StatusLabel>;
          }

          return (
            <StatusLabel
              state={row?.original?.TotalExclVAT?.ValueForView > -1 ? 0 : 3}
            >
              {row?.original?.TotalExclVAT?.ValueForView > -1
                ? "Kostnad"
                : "Rabatt"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Beskrivning",
        accessor: "ArticleDescription",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Produkt",
        accessor: "ProductPublicId",
        Cell: ({ value }) => {
          return <LinkedObject obj={value} urlMethod={productDetailUrl} />;
        },
      },
      {
        Header: "Total ink. Moms",
        accessor: "TotalIncVAT.ValueForView",
        Cell: ({ value }) => {
          return <div>{toMoneyString(value, true)}</div>;
        },
      },
      {
        Header: "Momssats",
        accessor: "VAT",
        Cell: ({ value }) => {
          return <div>{value}%</div>;
        },
      },
      {
        Header: "Total Exkl. Moms",
        accessor: "TotalExclVAT.ValueForView",
        Cell: ({ value }) => {
          return <div>{toMoneyString(value, true)}</div>;
        },
      },
    ];

    return cols;
  }, [invoice]);

  const reminderFee = reminderInvoice?.ReminderFee?.ValueForView || 0;
  const remainingReminderFee = reminderInvoice?.CurrentValue?.ValueForView || 0;

  const totalRemainingAmount =
    invoice?.CurrentAmount?.ValueForView + remainingReminderFee;

  return (
    <>
      {confirmCancelReminderOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmCancelReminderOpen(false)}
          onAccept={cancelReminders}
          acceptTitle="Fortsätt"
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Vill du avbryta påminnelser för denna faktura?
          </h3>
        </ConfirmModal>
      )}

      {debtCollectionNextDate != null &&
        debtCollectionInfo?.State?.NextEventDate != null &&
        debtCollectionNextDate !=
          moment(debtCollectionInfo.State.NextEventDate).format(
            "YYYY-MM-DD"
          ) && (
          <ConfirmModal
            onAccept={performPostponeDebtCollection}
            closeFunction={() =>
              setDebtCollectionNextDate(
                debtCollectionInfo?.State?.NextEventDate
              )
            }
          >
            <h3 className="mb-2 text-base font-normal text-slate-700 ">
              {`Vill du flytta förfallodatumet för kravhanteringen till ${debtCollectionNextDate}?`}
            </h3>
          </ConfirmModal>
        )}

      {cancelDebtCollectionActive && (
        <ConfirmModal
          onAccept={performCancelDebtCollection}
          closeFunction={() => setCancelDebtCollectionActive(false)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Vill du avbryta kravhanteringen?
          </h3>
        </ConfirmModal>
      )}

      {loading && <FullPageSpinner />}
      <DetailInnerWrapper>
        {!canAttest && !hasAttested && (
          <InfoBox
            title="Fakturan kan inte attesteras än"
            text={`Attestering kan ske tidigast på fakturadatumet, ${moment(
              invoice?.InvoiceDate
            ).format("YYYY-MM-DD")}`}
          />
        )}
        {invoiceLoading && <FullPageSpinner />}
        {debtCollectionInfo && (
          <DetailPageBoxFlexWrapper>
            <DetailPageBox style={{ flex: 3, alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <div>
                  <OverviewTitle>Kravhantering</OverviewTitle>
                  <div
                    style={{
                      marginTop: "4px",
                      marginBottom: "2px",
                      fontSize: theme.fontSizes.headerXSmall,
                    }}
                  >
                    Nästa händelse
                  </div>
                  {debtCollectionInfo?.State?.NextEventDate && (
                    <NonConnectedDatePicker
                      value={debtCollectionNextDate}
                      extraStyle={{ marginBottom: "0px", marginLeft: "0px" }}
                      clearable={false}
                      onChange={(value) => setDebtCollectionNextDate(value)}
                      disabled={!debtCollectionInfo?.CanPostpone}
                    />
                  )}
                </div>
                <div style={{ alignSelf: "flex-start" }}>
                  {debtCollectionInfo?.CanCancel && (
                    <TextButton
                      red
                      title="Avbryt kravhantering"
                      iconType="close"
                      iconPlacement="right"
                      clicked={() => setCancelDebtCollectionActive(true)}
                    />
                  )}
                </div>
              </OverviewTitleWrapper>
              <DetailInfo
                infoObj={getDebtCollectionInfoObj(
                  debtCollectionInfo,
                  reminderInvoice
                )}
              />
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        )}

        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ flex: 3, alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>
            </OverviewTitleWrapper>

            {nextEvent && !isPaused && (
              <InfoBox
                boxTheme="info"
                title={`Nästa händelse ${
                  nextEventDate
                    ? `(${moment(nextEventDate).format("YYYY-MM-DD")})`
                    : ""
                }`}
                text={nextEvent}
              />
            )}

            {invoice && (
              <DetailInfo
                infoObj={getInfoObj({
                  isVacancyBookingInvoice,
                  invoiceRecord,
                  vacancyInvoiceRecord,
                  invoice,
                  reminderInvoice,
                  tenant,
                  periodStart,
                  periodEnd,
                  isCreditInvoice,
                  downloadReminderInvoice,
                  creditingInvoiceId,
                  creditingInvoiceNumber,
                  creditorId,
                  handleCancelReminders: () =>
                    setConfirmCancelReminderOpen(true),
                })}
              />
            )}

            {invoice && !isVacancyBookingInvoice && (
              <InnerBox style={{ marginRight: 36 }}>
                <OverviewTitleWrapper>
                  <OverviewTitle small>Meddelande på faktura</OverviewTitle>
                </OverviewTitleWrapper>
                {invoice.Message || "Saknar meddelande"}
              </InnerBox>
            )}
          </DetailPageBox>

          {!isVacancyBookingInvoice && (
            <div
              style={{
                flex: 1,
                minWidth: "300px",
                alignSelf: "flex-start",
                flexDirection: "column",
              }}
            >
              {hasAttested &&
                invoice?.State?.Stage !== "Completed" &&
                !invoice?.State?.ClosedDate &&
                (invoice?.ActionType === "InvoiceAction" ||
                  invoice?.ActionType === "PaymentAdviceAction") && (
                  <DetailPageBox>
                    {autogiroLoading && <OverlaySpinner />}
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>Autogiro</OverviewTitle>
                        <OverviewSubtitle>
                          {!autoGiroActivated && !canActivateAutogiro ? (
                            <BodyText>
                              Autogiro kan ej aktiveras på denna faktura då
                              autogiro ej är aktiverat på kunden och/eller det
                              fakturerande bolaget.
                            </BodyText>
                          ) : (
                            <BodyText>
                              Autogirodragningen skickas till Bankgirocentralen
                              18:00 på fakturans förfallodatum. Om fakturan har
                              förfallit skickas den vid första möjliga
                              tillfälle.
                            </BodyText>
                          )}
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>
                    <InnerBox>
                      <ItemTitle>
                        Autogiro är{" "}
                        <StatusLabel state={autoGiroActivated ? 0 : 6}>
                          {autoGiroActivated ? "Aktiverat" : "Inaktivt"}
                        </StatusLabel>
                      </ItemTitle>
                      {canInactivateAutogiro && (
                        <TextButton
                          extraStyle={{ marginTop: 6 }}
                          iconType="close"
                          iconPlacement="right"
                          title="Inaktivera för denna faktura"
                          clicked={() => {
                            setAutogiroLoading(true);
                            dispatch(
                              inactivateAutogiroForInvoice({
                                invoiceActionId: invoice?.ActionPublicId,
                                creditorId: invoice?.CreditorPublicId,
                                successCallback: () => {
                                  setAutogiroLoading(false);
                                  dispatch(
                                    addToast({
                                      type: TOAST_TYPES.SUCCESS,
                                      title: "Autogiro inaktiverades",
                                    })
                                  );
                                },
                                errorCallback: (e) => {
                                  setAutogiroLoading(false);
                                  dispatch(
                                    addToast({
                                      type: TOAST_TYPES.ERROR,
                                      title: "Kunde ej inaktivera autogiro",
                                      description:
                                        e ||
                                        "Kontrollera datan och försök igen",
                                    })
                                  );
                                },
                              })
                            );
                          }}
                        />
                      )}
                      {canActivateAutogiro && !autoGiroActivated && (
                        <TextButton
                          extraStyle={{ marginTop: 6 }}
                          iconType="sync"
                          iconPlacement="right"
                          title="Aktivera för denna faktura"
                          clicked={() => {
                            setAutogiroLoading(true);
                            dispatch(
                              activateAutogiroForInvoice({
                                invoiceActionId: invoice?.ActionPublicId,
                                creditorId: invoice?.CreditorPublicId,
                                successCallback: () => {
                                  setAutogiroLoading(false);
                                  dispatch(
                                    addToast({
                                      type: TOAST_TYPES.SUCCESS,
                                      title: "Autogiro aktiverades",
                                    })
                                  );
                                },
                                errorCallback: (e) => {
                                  setAutogiroLoading(false);
                                  dispatch(
                                    addToast({
                                      type: TOAST_TYPES.ERROR,
                                      title: "Kunde ej aktivera autogiro",
                                      description:
                                        e ||
                                        "Kontrollera datan och försök igen",
                                    })
                                  );
                                },
                              })
                            );
                          }}
                        />
                      )}
                    </InnerBox>
                  </DetailPageBox>
                )}

              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small>Betalinfo</OverviewTitle>
                </OverviewTitleWrapper>

                {invoice && (
                  <OverviewContent>
                    <OverviewItem>
                      <OverviewItemTitle>Fakturerad summa</OverviewItemTitle>
                      <OverviewItemValue>
                        {invoice.InvoicedAmount.ValueForView.toLocaleString()}{" "}
                        SEK
                      </OverviewItemValue>
                    </OverviewItem>

                    {reminderInvoice?.ReminderInvoices?.length > 0 && (
                      <OverviewItem>
                        <OverviewItemTitle>Påminnelseavgift</OverviewItemTitle>

                        <OverviewItemValue>
                          {toMoneyString(reminderFee, true)}
                        </OverviewItemValue>
                      </OverviewItem>
                    )}

                    <OverviewItem>
                      <OverviewItemTitle>Varav moms</OverviewItemTitle>
                      <OverviewItemValue>
                        {invoice.TotalVATAmount.ValueForView.toLocaleString()}{" "}
                        SEK
                      </OverviewItemValue>
                    </OverviewItem>

                    <OverviewItem>
                      <OverviewItemTitle>Betalat</OverviewItemTitle>
                      <OverviewItemValue>
                        {invoice.PaidAmount.ValueForView.toLocaleString()} SEK
                      </OverviewItemValue>
                    </OverviewItem>

                    {invoice.CreditedAmount.ValueForView > 0 && (
                      <OverviewItem>
                        <OverviewItemTitle>Krediterat</OverviewItemTitle>
                        <OverviewItemValue>
                          {invoice.CreditedAmount.ValueForView.toLocaleString()}{" "}
                          SEK
                        </OverviewItemValue>
                      </OverviewItem>
                    )}

                    {invoice.InterestAmount.ValueForView > 0 && (
                      <OverviewItem>
                        <OverviewItemTitle>Räntebelopp</OverviewItemTitle>
                        <OverviewItemValue>
                          {invoice.InterestAmount.ValueForView.toLocaleString()}{" "}
                          SEK
                        </OverviewItemValue>
                      </OverviewItem>
                    )}

                    <OverviewItem>
                      <OverviewItemTitle>Kvarvarande</OverviewItemTitle>
                      <OverviewItemValue>
                        {toMoneyString(totalRemainingAmount, true)}
                      </OverviewItemValue>
                    </OverviewItem>
                  </OverviewContent>
                )}

                {canRegisterPayment && invoice && (
                  <RegisterInvoicePayment
                    isCreditInvoice={isCreditInvoice}
                    registerPayment={handleRegisterPayment}
                    defaultValue={
                      invoice?.DebtCollectionActionPublicId != null
                        ? debtCollectionInfo?.CurrentAmount.ValueForView +
                          (reminderInvoice?.CurrentValue?.ValueForView ?? 0)
                        : invoice.CurrentAmount.ValueForView +
                          (reminderInvoice?.CurrentValue?.ValueForView ?? 0)
                    }
                    creditorId={creditorId}
                    isDebtHandling={
                      invoice?.DebtCollectionActionPublicId != null
                    }
                  />
                )}
              </DetailPageBox>
            </div>
          )}
        </DetailPageBoxFlexWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>Debiteringsrader</OverviewTitle>
          </OverviewTitleWrapper>

          <Table data={costRows} columns={costColumns} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getInvoiceTypeStr({ invoiceRecord }) {
  if (!invoiceRecord?.from_auto) return "Manuell";

  return "Automatisk";
}

const getUrlMethod = (invoiceRecord) => {
  if (invoiceRecord?.lease_invoicing)
    return `/error-redirect/invoicinglease/${invoiceRecord?.lease_invoicing?.id}`;
  if (invoiceRecord?.other_invoicing)
    return `/error-redirect/invoicingother/${invoiceRecord?.other_invoicing?.id}`;
  if (invoiceRecord?.parking_invoicing)
    return `/error-redirect/invoicingparking/${invoiceRecord?.parking_invoicing?.id}`;
  if (invoiceRecord?.brf_invoicing)
    return `/error-redirect/invoicingbrf/${invoiceRecord?.brf_invoicing?.id}`;
};

function getLinkedObject({ invoiceRecord, vacancyInvoiceRecord }) {
  let obj;
  let urlMethod;
  if (invoiceRecord) {
    return (
      <LinkedObject
        obj={
          invoiceRecord.lease_invoicing ||
          invoiceRecord.other_invoicing ||
          invoiceRecord.parking_invoicing ||
          invoiceRecord.brf_invoicing
        }
        urlMethod={() => getUrlMethod(invoiceRecord)}
      />
    );
  }

  if (vacancyInvoiceRecord) {
    if (vacancyInvoiceRecord?.apartment) {
      return (
        <LinkedObject
          obj={vacancyInvoiceRecord.apartment}
          urlMethod={apartmentDetailUrl}
        />
      );
    }
    if (vacancyInvoiceRecord.industrial_premises) {
      return (
        <LinkedObject
          obj={vacancyInvoiceRecord.industrial_premises}
          urlMethod={indpDetailUrl}
        />
      );
    }
    if (vacancyInvoiceRecord.parking_spot) {
      return (
        <LinkedObject
          obj={vacancyInvoiceRecord.parking_spot}
          urlMethod={parkingDetailUrl}
        />
      );
    }
  }

  if (!obj || !urlMethod) return "-";

  return <LinkedObject obj={obj} urlMethod={urlMethod} />;
}

function getDebtCollectionInfoObj(debtCollectionInfo, reminderInvoice) {
  const infoObj = {
    Detaljer: [
      {
        title: "Nästa händelse",
        value: (
          <div
            dangerouslySetInnerHTML={{
              __html:
                debtCollectionInfo.State?.NextEvent?.replace("\\r", "<br/>") ??
                "-",
            }}
          ></div>
        ),
      },
      {
        title: "Fordran avser",
        value: (
          <div
            dangerouslySetInnerHTML={{
              __html: debtCollectionInfo?.ReasonDescription
                ? debtCollectionInfo?.ReasonDescription?.replaceAll(
                    `\\r`,
                    "<br />"
                  )
                : "",
            }}
          ></div>
        ),
      },
    ],
    Skuld: [
      {
        title: "Originellt Fakturabelopp",
        value: toMoneyString(
          debtCollectionInfo.OriginalAmount.ValueForView,
          true
        ),
      },
      {
        title: "Skuld från faktura",
        value: toMoneyString(debtCollectionInfo.DebtAmount.ValueForView, true),
      },
      {
        title: "Avgift",
        value: toMoneyString(
          debtCollectionInfo.FeeAmount.ValueForView +
            (reminderInvoice?.ReminderFee?.ValueForView ?? 0),
          true
        ),
      },
      {
        title: "Ränteavgift",
        value: toMoneyString(
          debtCollectionInfo.InterestAmount.ValueForView,
          true
        ),
      },
      {
        title: "Total skuld",
        value: toMoneyString(
          debtCollectionInfo.DebtAmount.ValueForView +
            debtCollectionInfo.FeeAmount.ValueForView +
            debtCollectionInfo.InterestAmount.ValueForView +
            (reminderInvoice?.ReminderFee?.ValueForView ?? 0),
          true
        ),
      },
      {
        title: <b>Kvar att betala</b>,
        value: (
          <b>
            {toMoneyString(
              debtCollectionInfo.CurrentAmount.ValueForView +
                (reminderInvoice?.CurrentValue?.ValueForView ?? 0),
              true
            )}
          </b>
        ),
      },
    ],
  };

  return infoObj;
}

function getInfoObj({
  isVacancyBookingInvoice,
  invoiceRecord,
  vacancyInvoiceRecord,
  invoice,
  reminderInvoice,
  tenant,
  periodStart,
  periodEnd,
  isCreditInvoice,
  downloadReminderInvoice,
  creditingInvoiceId,
  creditingInvoiceNumber,
  handleCancelReminders,
  creditorId,
}) {
  const infoObj = {};

  if (reminderInvoice && reminderInvoice?.ReminderInvoices?.length) {
    reminderInvoice.ReminderInvoices.forEach((ri, idx) => {
      infoObj[`Påminnelse nummer ${idx + 1}`] = [
        {
          title: "Skickad",
          value: <DateCell date={ri?.InvoiceDate} />,
        },
        {
          title: "Betalas senast",
          value: <DateCell date={ri?.DueDate} />,
        },
        {
          title: "Påminnelseavgift",
          value: toMoneyString(
            reminderInvoice?.ReminderFee?.ValueForView,
            true
          ),
          hidden: idx > 0,
        },
        {
          title: "OCR",
          value: ri?.OCR,
        },
        {
          title: (
            <TextButton
              title="Hämta påminnelsefaktura"
              clicked={() =>
                downloadReminderInvoice({
                  filePublicId: ri.InvoiceFile?.FilePublicId,
                })
              }
              iconType="download"
              iconPlacement="right"
            />
          ),
          hidden: !ri.InvoiceFile?.FilePublicId,
        },
        {
          title: (
            <TextButton
              red
              title="Avbryt påminnelser (makulera avgift)"
              clicked={() => handleCancelReminders()}
              iconType="close"
              iconPlacement="right"
            />
          ),
        },
      ];
    });
  }

  infoObj[
    isVacancyBookingInvoice ? "Underlagsinformation" : "Fakturainformation"
  ] = [
    {
      title: "Krediterar faktura",
      value: (
        <LinkedObject
          obj={{ str_representation: creditingInvoiceNumber }}
          urlMethod={() =>
            invoiceDetailUrl({ invoiceId: creditingInvoiceId, creditorId })
          }
        />
      ),
      hidden: !creditingInvoiceId,
    },
    {
      title: `Avser ${
        invoiceRecord ? "avtal" : vacancyInvoiceRecord ? "objekt" : "-"
      }`,
      value:
        invoiceRecord || vacancyInvoiceRecord
          ? getLinkedObject({ invoiceRecord, vacancyInvoiceRecord })
          : "Manuell",
      hidden: isCreditInvoice || !invoiceRecord || !vacancyInvoiceRecord,
    },
    {
      title: "Typ av faktura",
      value: getInvoiceTypeStr({
        invoiceRecord,
      }),
      hidden: isCreditInvoice || isVacancyBookingInvoice,
    },
    {
      title: "Genererad av",
      hidden: isCreditInvoice,
      value: (invoiceRecord || vacancyInvoiceRecord)?.owner ? (
        <LinkedObject
          obj={(invoiceRecord || vacancyInvoiceRecord).owner}
          urlMethod={userDetailUrl}
        />
      ) : (
        "System"
      ),
    },
    {
      title: "Fakturerad part",
      value: <LinkedObject obj={tenant} urlMethod={tenantDetailUrl} />,
      hidden: isVacancyBookingInvoice,
    },
    {
      title: "Kundens referens",
      value: invoice?.YourReference,
      hidden: isVacancyBookingInvoice,
    },
    {
      title: "Bolagets referens",
      value: invoice?.OurReference,
    },
    {
      title: "OCR",
      value: invoice?.Invoices?.[0]?.OCR || "-",
    },
    {
      title: "Leveransmetod",
      value: DELIVERY_METHOD_MAPPER_EXCL_PRICES[invoice?.DeliveryMethod],
      hidden: isVacancyBookingInvoice,
    },
    {
      title: "Dröjsmålsränta",
      value:
        invoice?.InterestType === "NoInterest"
          ? "0%"
          : `${invoice?.InterestPercentage}%`,
      hidden: isVacancyBookingInvoice,
    },
    {
      title: "Adminavgift",
      value: toMoneyString(invoice?.InvoiceFee?.ValueForView, true),
      hidden: isVacancyBookingInvoice || isCreditInvoice,
    },
    {
      title: "Använder kundsaldo",
      value: invoice?.State?.UseDebtorBalance ? "Ja" : "Nej",
      hidden: isCreditInvoice || isVacancyBookingInvoice,
    },
  ];
  infoObj["Datum"] = [
    {
      title: "Period start",
      value: <DateCell date={periodStart} />,
    },
    {
      title: "Period slut",
      value: <DateCell date={periodEnd} />,
    },
    {
      title: "Fakturadatum",
      value: <DateCell date={invoice?.InvoiceDate} />,
    },
    {
      title: "Förfaller",
      value: <DateCell date={invoice?.DueDate} />,
      hidden: isCreditInvoice || isVacancyBookingInvoice,
    },
    {
      title: "Skapades",
      value: <DateCell date={invoice?.Created} />,
    },
    {
      title: "Attesterad",
      value: invoice?.State?.AttestedDate ? (
        <DateCell date={invoice.State.AttestedDate} />
      ) : (
        "Ej attesterad"
      ),
    },
    {
      title: "Skickad",
      value: invoice?.State?.InvoiceSentDate ? (
        <DateCell date={invoice.State.InvoiceSentDate} />
      ) : (
        "Ej skickad"
      ),
      hidden: isVacancyBookingInvoice,
    },
    {
      title: "Stängdes",
      value: <DateCell date={invoice?.State?.ClosedDate} />,
      hidden: !invoice?.State?.ClosedDate,
    },
    {
      title: "Bestriden",
      value: <DateCell date={invoice?.State?.DisputedDate} />,
      hidden: !invoice?.State?.DisputedDate || isVacancyBookingInvoice,
    },
    {
      title: "Gick till Kravhantering",
      value: <DateCell date={invoice?.State?.SentToDebtCollectionDate} />,
      hidden:
        !invoice?.State?.SentToDebtCollectionDate || isVacancyBookingInvoice,
    },
  ];

  return infoObj;
}

function calculatePeriodForInvoice(records) {
  if (!records) return [undefined, undefined];
  const periodEnds = records.map((r) => r.PeriodEnd).filter((p) => p);
  const periodStarts = records.map((r) => r.PeriodStart).filter((p) => p);

  const periodStart = periodStarts.reduce((acc, cur) => {
    const curPeriod = moment(cur);
    const accPeriod = acc ? moment(acc) : null;

    if (!accPeriod || curPeriod.isBefore(accPeriod))
      return curPeriod.format("YYYY-MM-DD");

    return accPeriod ? accPeriod.format("YYYY-MM-DD") : null;
  }, null);

  const periodEnd = periodEnds.reduce((acc, cur) => {
    const curPeriod = moment(cur);
    const accPeriod = acc ? moment(acc) : null;

    if (!accPeriod || curPeriod.isAfter(accPeriod))
      return curPeriod.format("YYYY-MM-DD");

    return accPeriod ? accPeriod.format("YYYY-MM-DD") : null;
  }, null);

  return [periodStart, periodEnd];
}
