import * as React from "react";
import { useDispatch } from "react-redux";

import {
  destroyPatchForm,
  constants,
  update,
  useLeaseContractForm,
} from "../../../../store/leaseContracts";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";
import TableSelectFieldWithCreate from "../../Base/Fields/TableSelectFieldWithCreate";
import IndustrialPremisesNestledFields from "../../IndustrialPremises/NestedChildren";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import ApartmentNestedFields from "../../Apartment/NestedChildren";

export default function UpdateLeaseContractPremisesModalForm({
  id,
  onCheckout,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useLeaseContractForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (dataClone.apartments?.length < 1) {
      dataClone.apartments = [];
    }

    delete dataClone.editabledoc;

    if (dataClone.industrial_premises_list?.length < 1) {
      dataClone.industrial_premises_list = [];
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        preProcess,
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title="Uppdatera hyresobjekt på avtal"
      onAccept={onSubmit}
      acceptTitle="Spara"
      closeFunction={onDone}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Hyresobjekt på avtal</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey={"industrial_premises_list"}
          method={method}
          title="Lokaler"
          placeholder="Välj lokaler..."
          description="Välj lokaler på avtalet."
          createDisplayKey="premises_id"
          TableComponent={IndustrialPremisesTable}
          isMany
        >
          {(parentPath) => (
            <IndustrialPremisesNestledFields
              storeName={storeName}
              method={method}
              parentPath={parentPath}
              parentInstructionsPath={"industrial_premises_list"}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey={"apartments"}
          method={method}
          title="Lägenheter"
          placeholder="Välj lägenheter..."
          description="Välj hyreslägenheter på avtalet."
          createDisplayKey="apartment_id"
          TableComponent={ApartmentTable}
          isMany
        >
          {(parentPath) => (
            <ApartmentNestedFields
              storeName={storeName}
              method={method}
              parentPath={parentPath}
              parentInstructionsPath={"apartments"}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>
    </Modal>
  );
}
