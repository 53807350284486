import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

import { constants } from "../../../../../store/parkingSpots";

import { AddressField } from "../../../Base/Fields";
import ParkingLotNestedChildren from "../../../ParkingLot/NestedChildren";
import ParkingSpecificationNestedChildren from "../../../ParkingSpecification/NestedChildren";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import InvoicingSettingDefault from "../../../../Summaries/InvoicingSettingDefault";

import {
  useRealEstatePagination,
  constants as realEstateConstants,
  performFilter as realEstatePerformFilter,
} from "../../../../../store/realEstates";
import realEstateListDefs from "../../../../Tables/RealEstate/listDefs";

import { useParkingLot } from "../../../../../store/parkingLots";

import { useDispatch } from "react-redux";
import { TextButton } from "../../../Base/Buttons";
import { cloneDeep } from "lodash";
import TableSelectField from "../../../Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import ParkingLotsTable from "../../../../Tables/Parking/ParkingLots/FullTable";
import ParkingSpecificationsTable from "src/components/Tables/Parking/ParkingSpecifications/FullTable";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });
  const selectedArea = useFormInstanceField({
    storeName,
    fieldKey: "parking_lot",
  });

  const selectedSpecification = useFormInstanceField({
    storeName,
    fieldKey: "specification",
  });

  const [area, areaLoading] = useParkingLot(selectedArea?.id);

  return (
    <>
      <FormAreaTitle>Fordonsplats</FormAreaTitle>
      <FormAreaDescription>
        Inställningar för fordonsplatsen.
      </FormAreaDescription>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey="title"
          title="Plats nr."
        />
      </div>

      <AddressField
        title="Huvudaklig adress"
        storeName={storeName}
        method={method}
        fieldKey="address"
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey="realestate"
          method={method}
          placeholder="Välj fastighet..."
          title="Tillhör fastighet"
          description="Ange vilken fastighet fordonsplatsen tillhör"
          TableComponent={RealEstateTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          TableComponent={CompanyTable}
          placeholder="Välj hyresvärd..."
          title="Hyresvärd"
          method={method}
          fieldKey="landlord"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          TableComponent={CompanyTable}
          placeholder="Välj bolag för vakansbokning..."
          title="Bolag för vakansbokning"
          method={method}
          fieldKey="vacancy_booking_company"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          TableComponent={ParkingLotsTable}
          placeholder="Välj parkeringsområde..."
          title="Parkeringsområde"
          method={method}
          fieldKey="parking_lot"
        >
          {(parentPath) => (
            <ParkingLotNestedChildren
              storeName={storeName}
              method={method}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      {selectedSpecification != null || area?.specification == null ? (
        <>
          {area?.specification != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              sourceTitle="Från valt parkeringsområde"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.specification = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectFieldWithCreate
              storeName={storeName}
              placeholder="Välj specifikation..."
              title="Specifikation och regler"
              method={method}
              fieldKey="specification"
              TableComponent={ParkingSpecificationsTable}
            >
              {(parentPath) => (
                <ParkingSpecificationNestedChildren
                  storeName={storeName}
                  method={method}
                  parentPath={parentPath}
                />
              )}
            </TableSelectFieldWithCreate>
          </div>
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Specifikation & Regler"
          content={[
            {
              title: "Specifikation & regler",
              content:
                area?.specification?.str_representation || "Från valt område",
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  specification: 0,
                },
              })
            );
          }}
        />
      )}
    </>
  );
};
