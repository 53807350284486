import * as React from "react";
import { useParams } from "react-router";

import { DetailInnerWrapper } from "../../../components/sharedStyles";
import DocumentViewer from "../../../components/Details/OverviewInfo/DocumentViewer/DocumentViewer";
import {
  useOtherContract,
  constants,
  update,
  detailUrl,
  useOtherContractForm,
} from "../../../store/otherContracts";

export default function OtherContractDocumentTabs() {
  const { otherContractId } = useParams();
  const [otherContract] = useOtherContract(otherContractId);

  useOtherContractForm("PATCH", otherContractId);
  return (
    <DetailInnerWrapper>
      <DocumentViewer
        contract={otherContract}
        contractConstants={constants}
        contractUpdate={update}
        {...{ detailUrl }}
      />
    </DetailInnerWrapper>
  );
}
