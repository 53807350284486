import * as React from "react";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";

import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import StepDisplay from "../../../StepDisplay/StepDisplay";
import {
  useServiceInvoicingForm,
  constants,
  create,
  destroyPostForm,
} from "../../../../store/invoicingService";

import { updateActiveFormInstance } from "../../../../store/base";

import StandardModal from "../../../Modals/StandardModal";
import { BodyText } from "../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import { useEditabledoc } from "../../../../store/editabledocs";

import {
  useServiceContract,
  detailUrl,
} from "../../../../store/serviceContracts";

export default function ServiceContractCostsForm({ method = "POST" }) {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const [alreadyHasInvoicing, setAlreadyHasInvoicing] = React.useState(false);
  const formLoaded = Boolean(useServiceInvoicingForm(method));

  const { contractId } = useParams();

  const [serviceContract] = useServiceContract(contractId);
  const [editableDoc] = useEditabledoc(serviceContract?.editabledoc?.id);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(false));
    };
  }, []);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    replace(detailUrl({ id: serviceContract?.id }));
  };

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          service_contract: { id: contractId },
        },
      })
    );
  }, []);

  React.useEffect(() => {
    if (!editableDoc) return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _editabledoctemp: editableDoc,
        },
      })
    );
  }, [editableDoc]);

  React.useEffect(() => {
    if (serviceContract?.service_invoicing) {
      setAlreadyHasInvoicing(true);
    }
  }, [serviceContract]);

  React.useEffect(() => {
    return () => {
      destroyPostForm(false);
    };
  }, []);

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data }),
      })
    );
  };

  const renderSteps = () => {
    const steps = ["1. Avtal", "2. Kostnader & Schemaläggning"];

    return <StepDisplay steps={steps} activeIndex={1} />;
  };

  return (
    <>
      <StandardModal
        title={`Konstnader & schemaläggning redan konfigurerat`}
        isOpen={alreadyHasInvoicing}
        canClose={false}
        actionBarAcceptTitle="Gå till detaljsida"
        withActionBar
        saveFunction={() => {
          replace(detailUrl({ id: serviceContract?.id }));
        }}
        canCancel={false}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Konstnader & schemaläggning redan konfigurerat för detta avtal
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          Denna sida är enbart till för skapande av kostnader & schemaläggning.
          Detta avtal har redan en inställning, gå till avtalets detaljsida för
          att redigera kostnader & schemaläggning.
        </BodyText>
      </StandardModal>

      {(loading || !formLoaded) && <FullPageSpinner />}

      <>
        {renderSteps()}

        {serviceContract && (
          <FlowFormBase
            {...{
              storeName,
              chapterDefs: chapterDefs({}),
              chapters,
              descriptions,
              method,
              onSubmit,
              contract: serviceContract,
              displayDocumentFieldKey: "_editabledoctemp.doc.get",
              title: `Kostnader & Schemaläggning
            för ${serviceContract.id_number}`,
            }}
          />
        )}
      </>
    </>
  );
}

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  return copy;
};
