export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INSPECTION_RATING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INSPECTION_RATING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INSPECTION_RATING",
  DESTROY_FORM: "DESTROY_FORM_INSPECTION_RATING",
  SET_FORM_ERROR: "SET_FORM_ERROR_INSPECTION_RATING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INSPECTION_RATING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INSPECTION_RATING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INSPECTION_RATING",
  RESET_STATE: "RESET_STATE_INSPECTION_RATING",
  CLEAR_FETCHED: "CLEAR_FETCHED_INSPECTION_RATING",
  SET_OVERVIEW_STATS: "SET_INSPECTION_RATING_OVERVIEW_STATS",
  POST_ACCEPT_DECLINE: "POST_ACCEPT_DECLINE_INSPECTION_RATING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INSPECTION_RATING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INSPECTION_RATING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INSPECTION_RATING",

  LIST_URL: "/errands/components/componentrating/list/",
  POST_URL: "/errands/components/componentrating/",
  PATCH_URL: "/errands/components/componentrating/",
  GET_URL: "/errands/components/componentrating/",

  STORE_NAME: "inspectionErrandExecutionRating",
};
