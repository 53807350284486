import * as React from "react";
import { useFormInstanceField } from "../../../../../store/base";
import { constants } from "../../../../../store/calendarEvents";
// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import Summary from "../Summary";

export default () => {
  const instance = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "",
  });

  return (
    <>
      <FormAreaTitle>Granska och slutför</FormAreaTitle>
      <FormAreaDescription>
        Kontrollera att uppgifterna stämmer.
      </FormAreaDescription>

      <Summary {...{ instance }} />
    </>
  );
};
