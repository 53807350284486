import * as React from "react";

import { buildQueryString } from "../../store/base";
import { useFilteredUserGroups } from "../../store/userGroups";

import FullTable from "../Tables/Users/FullTable";

export default () => {
  const persistantQuery = {
    "user_type__in!": [3, 5],
  };
  const [groups] = useFilteredUserGroups("");

  return (
    <>
      <FullTable persistantQuery={persistantQuery} groups={groups} />
    </>
  );
};
