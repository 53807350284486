import * as React from "react";
import { useParams, useHistory } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { EmailTag, LinkedObject, PhoneTag } from "../../../components/Displays";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { buildQueryString, setActiveFormInstance } from "../../../store/base";
import { useDebtorInvoicing } from "../../../store/invoicingDebtor";
import DebtorInvoiceForm from "../../../components/Forms/InvoicingDebtor/ChapterForm/ModalForm";
import { useTenant } from "../../../store/tenants";
import { useDispatch } from "react-redux";
import { constants as invoicingDebtorConstants } from "../../../store/invoicingDebtor";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import useQuery from "../../../components/utils/useQuery";
import { detailUrl } from "../../../store/invoicingCustomDimensionEntries";
import {
  clearSearchWithId,
  useInvoicesFromMultipleSearchResults,
} from "../../../store/billectaSearch";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ContractViewInvoicesTable from "../../../components/Billecta/Invoices/ContractViewInvoicesTable";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import DebtorAccountBalance from "./AccountBalance";
import { useFilteredApartments } from "../../../store/apartments";
import { useFilteredIndustrialPremises } from "../../../store/industrialPremises";

export default function TenantInvoicing() {
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const { tenantId } = useParams();
  const { push } = useHistory();

  const query = useQuery();
  const openSettings = query.get("opensettings") == "true";

  const [tenant] = useTenant(tenantId);

  const tenantQ = buildQueryString({
    broad_tenant: tenantId,
  });
  const [tenantApartments] = useFilteredApartments(tenantQ);
  const [tenantIndps] = useFilteredIndustrialPremises(tenantQ);

  const apartmentAddressProposals = tenantApartments
    ?.filter((a) => !!a.address?.id)
    ?.map((a) => a.address);
  const indpAddressProposals = tenantIndps
    ?.filter((a) => !!a.address?.id)
    ?.map((a) => a.address);
  const addressProposals = [
    ...(apartmentAddressProposals || []),
    ...(indpAddressProposals || []),
  ];

  const [debtorInvoiceConfig, debtorInvoiceConfigLoading] = useDebtorInvoicing(
    tenant?.debtor_invoice_config?.id
  );

  const tenantBillectaIds = debtorInvoiceConfig?.billecta_ids?.map((bid) => ({
    creditorId: bid.creditor_id,
    debtorId: bid.object_id,
  }));

  const searchInstances = tenantBillectaIds?.map((tbi) => {
    const params = {
      CreditorPublicId: tbi.creditorId,
      DebtorPublicIds: [tbi.debtorId],
    };

    return params;
  });

  const [invoices, invoicesLoading] = useInvoicesFromMultipleSearchResults({
    searchInstances,
    searchId: tenant?.debtor_invoice_config?.id,
    typeConstants: invoicingDebtorConstants,
    errorCallback: () => {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde inte hämta avier för hyresgäst",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );
    },
  });

  React.useEffect(() => {
    return () => {
      dispatch(
        clearSearchWithId({
          name: `search_${tenant?.debtor_invoice_config?.id}`,
          typeConstants: invoicingDebtorConstants,
        })
      );
    };
  }, []);

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      debtorinvoiceconfig: tenant?.debtor_invoice_config?.id || "",
    },
  });

  const errorQuery = {
    debtorinvoiceconfig: tenant?.debtor_invoice_config?.id,
  };

  const openCreateDebtorInvoiceForm = () => {
    const initialData = {
      tenant: { id: tenantId },
      email_invoice: tenant?.user?.email,
      your_reference: tenant?.user?.corporate_name
        ? tenant.user.corporate_name
        : `${tenant?.user?.first_name || ""} ${tenant?.user?.last_name || ""}`,
    };

    dispatch(
      setActiveFormInstance({
        storeName: invoicingDebtorConstants.STORE_NAME,
        data: initialData,
      })
    );

    setCreateOpen(true);
  };

  React.useEffect(() => {
    if (openSettings) {
      openCreateDebtorInvoiceForm();
    }
  }, [openSettings]);

  const handleInvoiceRowClicked = (row) => {
    const { original } = row;
    push(
      invoiceDetailUrl({
        invoiceId: original.ActionPublicId,
        creditorId: original.CreditorPublicId,
      })
    );
  };

  return (
    <>
      <DebtorInvoiceForm
        method="PATCH"
        id={debtorInvoiceConfig?.id}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        instance={debtorInvoiceConfig}
        kind={!!tenant?.user?.corporate_name ? "company" : "private"}
        isMember={tenant?.is_brf}
      />

      <DebtorInvoiceForm
        method="POST"
        isOpen={createOpen}
        onCheckout={() => setCreateOpen(false)}
        kind={tenant?.user?.corporate_name ? "company" : "private"}
        isMember={tenant?.is_brf}
        addressProposals={addressProposals}
      />

      <DetailInnerWrapper>
        {errorCount > 0 && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle small>Felmeddelanden</OverviewTitle>
            </OverviewTitleWrapper>

            <InvoicingErrorTable
              isBare
              ignoreLocalStorage
              persistantQuery={errorQuery}
            />
          </DetailPageBox>
        )}

        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 3, alignSelf: "flex-start", maxWidth: "70%" }}
          >
            {debtorInvoiceConfigLoading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitle>Aviseringsinställning</OverviewTitle>

              {debtorInvoiceConfig && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            {!debtorInvoiceConfig ? (
              <div
                style={{
                  width: "100%",
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PrimaryButton
                  title="Sätt upp aviseringsinställning"
                  clicked={openCreateDebtorInvoiceForm}
                />
              </div>
            ) : (
              <>
                <DetailInfo
                  infoObj={getInfoObj({ debtor: debtorInvoiceConfig, tenant })}
                />
              </>
            )}
          </DetailPageBox>

          <div
            style={{
              flex: 1,
              minWidth: "25%",
              alignSelf: "flex-start",
              flexDirection: "column",
              display: "flex",
            }}
          >
            {debtorInvoiceConfig?.billecta_ids?.map((billectaObj) => (
              <DebtorAccountBalance {...{ billectaObj, tenant }} />
            ))}
          </div>
        </DetailPageBoxFlexWrapper>

        {tenantBillectaIds?.length > 0 && (
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>Avier till hyresgäst</OverviewTitle>
            </OverviewTitleWrapper>

            {invoicesLoading && <OverlaySpinner />}

            {invoices?.length ? (
              <ContractViewInvoicesTable
                invoices={invoices}
                handleRowClicked={handleInvoiceRowClicked}
              />
            ) : (
              <BodyText>
                {invoicesLoading
                  ? "Laddar avier..."
                  : "Inga avier har genererats än för denna hyresgäst"}
              </BodyText>
            )}
          </DetailPageBox>
        )}
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ debtor, tenant }) {
  const infoObj = {
    Faktureringsinfo: [
      {
        title: "Fakturaadress",
        value: debtor.invoice_address?.base?.split(",")?.[0] || "-",
      },
      {
        title: "Fakturaadress postnr.",
        value: debtor.invoice_address?.postal_code || "-",
      },
      {
        title: "Fakturaadress stad",
        value: debtor.invoice_address?.city || "-",
      },
      {
        title: "Fakturaadress land",
        value: debtor.invoice_address?.country || "-",
      },
      {
        title: "c/o",
        value: debtor.care_of || "-",
      },
      {
        title: "Adress 2",
        value: debtor.invoice_address_extra || "-",
      },
    ],
    "Leverans av faktura": [
      {
        title: "Leveransmetod",
        value: debtor.delivery_method_display || "Ej specificerad",
      },
      {
        title: "Kundens referens",
        value: debtor.your_reference || "-",
      },
      { title: "E-post", value: <EmailTag mail={debtor?.email_invoice} /> },
      {
        title: "E-post CC",
        value: <EmailTag mail={debtor?.email_invoice_cc} />,
      },
      {
        title: "E-post BCC",
        value: <EmailTag mail={debtor?.email_invoice_bcc} />,
      },
      { title: "Telefon", value: <PhoneTag phone={debtor?.invoice_phone} /> },
    ],

    Betalmetoder: [
      {
        title: "Autogiro betalarnummer",
        value: debtor.autogiro_payer_number || "Ej uppsatt",
      },
      {
        title: "Autogiro clearing",
        value: debtor.autogiro_clearing || "Ej uppsatt",
      },
      {
        title: "Autogironummer",
        value: debtor.autogiro_number || "Ej uppsatt",
      },
      {
        title: "Autogiroleverantör",
        value: debtor.autogiro_payment_service || "Ej uppsatt",
      },
    ],

    Bokföring: [
      {
        title: "Anpassad dimension",
        value: (
          <LinkedObject
            obj={debtor?.additional_dimension}
            urlMethod={detailUrl}
          />
        ),
      },
    ],
  };

  const tenantIsCompany = tenant?.corporate_name ? true : false;

  if (tenantIsCompany) {
    infoObj["Faktureringsinfo"].push({
      title: "VAT-nummer",
      value: debtor.vat || "-",
    });

    infoObj["Betalmetoder"].push({
      title: "Intermediator",
      value: debtor.intermediator_display || "Ej uppsatt",
    });

    infoObj["Betalmetoder"].push({
      title: "GLN",
      value: debtor.gln || "Ej uppsatt",
    });
  } else {
    infoObj["Betalmetoder"].push({
      title: "E-faktura bank",
      value: debtor.e_invoice_bank_display || "Ej uppsatt",
    });
  }

  return infoObj;
}
