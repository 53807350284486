import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import moment from "moment";
import {
  setActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import {
  constants,
  destroyPostForm,
  detailUrl,
  update,
  useInspectionErrand,
  useInspectionErrandForm,
} from "../../../../store/inspectionErrands";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapters from "./Chapters";
import descriptions from "./Descriptions";
import chapterDefs from "./chapterDefs";

export default function InpectionErrandExecuteForm({ method = "PATCH" }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { inspectionErrandId } = useParams();

  const { replace } = useHistory();

  const [inspectionErrand] = useInspectionErrand(inspectionErrandId);

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(detailUrl({ id: returned.id }));
  };

  React.useEffect(() => {
    if (!instance?.id && inspectionErrand?.id) {
      const formData = {
        ...inspectionErrand,
        execution_ratings: inspectionErrand.active_ratings || [],
      };

      if (!formData.execute_end) {
        formData.execute_end = moment().toISOString();
      }
      dispatch(
        setActiveFormInstance({
          storeName,
          data: formData,
        })
      );
    }
  }, [inspectionErrand]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(false));
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        successCallback: onSuccess,
        id: inspectionErrandId,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data }),
      })
    );
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,
          descriptions,
          method,
          onSubmit,
          title: "Utför besiktning",
        }}
      />
    </>
  );
}

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  // set to done
  if ([0, 1, 2].includes(copy.status)) {
    copy.status = 3;
  }
  delete copy.execution_ratings;

  return copy;
};
