import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  redirectMethodFromKind,
  fetchSingleMethodFromKind,
} from "../../store/utils";
import { TableLink } from "../Forms/Base/Buttons";

export default ({ contentType, id }) => {
  const { push } = useHistory();

  const urlMethod = redirectMethodFromKind(contentType);
  const objMethod = fetchSingleMethodFromKind(contentType);

  const [obj, isLoading] = objMethod ? objMethod(id) : [undefined, false];

  if (!contentType || !id || !obj) {
    return <span className={"eui-textTruncate"}>-</span>;
  }

  if (!urlMethod) {
    return <span className={"eui-textTruncate"}>{obj.str_representation}</span>;
  }

  return (
    <TableLink
      clicked={() => {
        push(urlMethod({ id }));
      }}
      title={obj.str_representation}
    />
  );
};
