import * as React from "react";

import {
  Confirm,
  Setting,
  Contacts,
  Freshchat,
  Images,
  JourInfo,
  Functionality,
  Intercom,
  Apptus,
  RCO
} from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "SETTING":
      return <Setting method={method} />;
    case "IMAGES":
      return <Images method={method} />;
    case "JOURINFO":
      return <JourInfo method={method} />;
    case "CONTACTS":
      return <Contacts method={method} />;
    case "FRESHCHAT":
      return <Freshchat method={method} />;
    case "INTERCOM":
      return <Intercom method={method} />;
    case "FUNCTIONALITY":
      return <Functionality method={method} />;
    case "APPTUS":
      return <Apptus method={method} />;
    case "RCO":
      return <RCO method={method} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
