import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  buildQueryString,
  clearFetched,
  deleteObject,
  useAnyPermissionCheck,
} from "../../../store/base";
import { useErrandRole, constants } from "../../../store/errandRoles";
import {
  constants as errandRoleUserConstants,
  useFilteredErrandRoleUsers,
  create as createRoleUser,
  update as updateRoleUser,
  createMany as createManyRoleUsers,
} from "../../../store/errandRoleUsers";
import { useFilteredRealEstates } from "../../../store/realEstates";

import { useFilteredUsers } from "../../../store/users";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { PrimaryButton, TextButton } from "../../Forms/Base/Buttons";
import DeleteModal from "../../Forms/Delete/DeleteModal";
import ErrandRoleForm from "../../Forms/ErrandRole/ChapterForm/ModalForm";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { DetailInnerWrapper, DetailPageBox } from "../../sharedStyles";
import * as SC from "./styles";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import { axiosInstance } from "../../../store/base/store/axios";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default function RolesHandleForm({ id }) {
  const dispatch = useDispatch();
  const [currentRole, currentRoleLoading] = useErrandRole(id);
  const [deleteRoleOpen, setDeleteRoleOpen] = React.useState(false);
  const [editRoleOpen, setEditRoleOpen] = React.useState(false);
  const [addSingleUserOpen, setAddSingleUserOpen] = React.useState(false);
  const [addMulipleUserOpen, setAddMultipleUserOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedRealEstates, setSelectedRealEstates] = React.useState([]);
  const [addSingleUserLoading, setAddSingleUserLoading] = React.useState(false);
  const [addMultipleUserLoading, setAddMultipleUserLoading] =
    React.useState(false);
  const [swapLoading, setLoadingSwap] = React.useState(false);
  const { goBack } = useHistory();

  const [realEstates, realEstatesLoading] = useFilteredRealEstates("");
  const [selectedRealEstate, setSelectedRealEstate] = React.useState(null);

  const canChangeRole = useAnyPermissionCheck([
    "change_can_reporterrand",
    "change_can_inspectionerrand",
    "change_can_roundingerrand",
    "change_can_goverrand",
  ]);

  const canDeleteRole = useAnyPermissionCheck([
    "delete_can_reporterrand",
    "delete_can_inspectionerrand",
    "delete_can_roundingerrand",
    "delete_can_goverrand",
  ]);

  const [roleUsers, roleUsersLoading] = useFilteredErrandRoleUsers(
    !selectedRealEstate
      ? null
      : buildQueryString({
          role: id,
          realestates: [selectedRealEstate.id],
        })
  );

  const userQ = buildQueryString({
    id__in: roleUsers?.map((ru) => ru.user.id) || [],
  });

  const [users, usersLoading] = useFilteredUsers(userQ);

  const addSingleUser = () => {
    setAddSingleUserLoading(true);
    const order = roleUsers?.length || 0;

    const newRoleUser = {
      order,
      user: selectedUser,
      participation: {
        role: {
          id,
        },
        realestate: selectedRealEstate,
      },
    };

    dispatch(
      createRoleUser({
        forceData: newRoleUser,
        successCallback: () => {
          setAddSingleUserLoading(false);

          clearFetched(errandRoleUserConstants, true);
          setSelectedUser(null);
          setAddSingleUserOpen(false);
        },
      })
    );
  };

  const addMultipleUser = async () => {
    setAddMultipleUserLoading(true);

    const querystring = buildQueryString({
      role: id,
      realestates: selectedRealEstates?.map((r) => r.id) || [],
    });
    const { data: roleUsersForSelectedRealEstates } = await axiosInstance.get(
      `${errandRoleUserConstants.LIST_URL}?${querystring}`
    );

    const byRealEstate = roleUsersForSelectedRealEstates.reduce((acc, cur) => {
      const realEstateId = cur.participation.realestate.id;

      if (acc[realEstateId]) {
        acc[realEstateId].push(cur);
      } else {
        acc[realEstateId] = [cur];
      }

      return acc;
    }, {});

    const newRoleUsers = [];

    selectedRealEstates.forEach((sr) => {
      const curRealEstateId = sr.id;

      const existsInRealEstate = byRealEstate[curRealEstateId]?.find(
        (u) => u.user.id === selectedUser.id
      );

      if (existsInRealEstate) return;

      const maxOrderForRealEstate = byRealEstate[curRealEstateId]?.reduce(
        (acc, cur) => {
          if (cur.order > acc) return cur.order;

          return acc;
        },
        0
      );

      const newUser = {
        order: (maxOrderForRealEstate || 0) + 1,
        user: selectedUser,
        participation: {
          role: {
            id,
          },
          realestate: sr,
        },
      };

      newRoleUsers.push(newUser);
    });

    if (!newRoleUsers?.length) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Användaren är redan satt som roll i valda fastigheter",
        })
      );

      setAddMultipleUserLoading(false);

      return;
    }

    dispatch(
      createManyRoleUsers({
        roleUsers: newRoleUsers,
        successCallback: () => {
          setAddMultipleUserLoading(false);

          clearFetched(errandRoleUserConstants, true);
          setSelectedUser(null);
          setSelectedRealEstates([]);
          setAddMultipleUserOpen(false);
        },
        errorCallback: () => {
          setAddMultipleUserLoading(false);
        },
      })
    );
  };

  const swapRoleUsers = (newBelow, newAbove) => {
    setLoadingSwap(true);
    dispatch(
      updateRoleUser({
        id: newAbove.id,
        forceData: {
          order: newBelow.order,
        },
        successCallback: () => {
          setLoadingSwap(false);
          clearFetched(errandRoleUserConstants, true);
        },
      })
    );
    dispatch(
      updateRoleUser({
        id: newBelow.id,
        forceData: {
          order: newAbove.order,
        },
        successCallback: () => {
          setLoadingSwap(false);
          clearFetched(errandRoleUserConstants, true);
        },
      })
    );
  };

  const removeRoleUser = (roleUser) => {
    dispatch(
      deleteObject({
        instance: roleUser,
        constants: errandRoleUserConstants,
      })
    );
  };

  const handleRealEstateChanges = (data) => {
    setSelectedRealEstates(data);
  };

  const orderedRoleUsers = React.useMemo(() => {
    const roleUserClone = cloneDeep(roleUsers || []);

    roleUserClone.sort((a, b) => a.order - b.order);

    return roleUserClone;
  }, [roleUsers]);
  return (
    <>
      <StandardModal
        isOpen={addSingleUserOpen}
        closeFunction={() => setAddSingleUserOpen(false)}
        title={`Lägg till användare i roll för ${selectedRealEstate?.str_representation}`}
        withActionBar
        canAccept={!!selectedUser}
        saveFunction={addSingleUser}
      >
        {addSingleUserLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Inställningar för användare i roll</OverviewTitle>
            <OverviewSubtitle>
              Specificera vilken användare som ska ha denna roll för vald
              fastighet
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <hr />

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            value={selectedUser}
            TableComponent={UsersTable}
            persistantQuery={{
              user_type__in: [0, 1, 2, 4, 5],
            }}
            title="Användare"
            placeholder="Välj användare..."
            description="Välj användare för att koppla som nuvarande roll till den valda fastigheten"
            onChange={(val) => setSelectedUser(val)}
          />
        </div>
      </StandardModal>

      <StandardModal
        isOpen={addMulipleUserOpen}
        closeFunction={() => setAddMultipleUserOpen(false)}
        title={`Lägg till användare i roll för flera fastigheter`}
        withActionBar
        canAccept={!!selectedUser && selectedRealEstates?.length}
        saveFunction={addMultipleUser}
      >
        {addMultipleUserLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Inställningar för användare i roll</OverviewTitle>
            <OverviewSubtitle>
              Specificera vilken användare som ska ha denna roll för vald
              fastighet
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <hr />

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            value={selectedUser}
            TableComponent={UsersTable}
            persistantQuery={{
              user_type__in: [0, 1, 2, 4, 5],
            }}
            title="Användare"
            placeholder="Välj användare..."
            description="Välj användare för att koppla som nuvarande roll till de valda fastigheterna"
            onChange={(val) => setSelectedUser(val)}
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            value={selectedRealEstates}
            TableComponent={RealEstateTable}
            persistantQuery={{
              user_type__in: [0, 1, 2, 4, 5],
            }}
            title="Fastigheter"
            placeholder="Välj fastigheter..."
            onChange={handleRealEstateChanges}
            isMany
          />
        </div>
      </StandardModal>

      <DeleteModal
        isOpen={!!deleteRoleOpen}
        closeFunction={() => setDeleteRoleOpen(null)}
        instance={currentRole}
        constants={constants}
      />

      <ErrandRoleForm
        method="PATCH"
        id={id}
        onCheckout={() => setEditRoleOpen(false)}
        isOpen={editRoleOpen}
        instance={currentRole}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => goBack()}
            extraStyle={{ marginBottom: 12 }}
          />
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>
                Hantera roll: {currentRole?.str_representation || "laddar..."}
              </OverviewTitle>
              <OverviewSubtitle>
                Hantera vilka användare som ska agera som denna roll för vilka
                fastigheter.
              </OverviewSubtitle>

              {canChangeRole && (
                <OverviewSubtitle>
                  <TextButton
                    title="Lägg till användare i flera fastigheter"
                    iconType="add"
                    iconPlacement="right"
                    clicked={() => setAddMultipleUserOpen(true)}
                  />
                </OverviewSubtitle>
              )}

              {canDeleteRole && (
                <OverviewSubtitle>
                  <TextButton
                    title="Radera roll"
                    red
                    iconType="close"
                    iconPlacement="right"
                    clicked={() => setDeleteRoleOpen(true)}
                  />
                </OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>

            {canChangeRole && (
              <PrimaryButton
                title="Redigera roll"
                clicked={() => setEditRoleOpen(true)}
              />
            )}
          </OverviewTitleWrapper>

          <SC.Container
            maxElementCount={
              realEstates?.length > orderedRoleUsers?.length
                ? realEstates?.length
                : orderedRoleUsers?.length
            }
          >
            <SC.RealEstateContainer>
              {realEstatesLoading && <OverlaySpinner />}

              <SC.Header>Fastigheter</SC.Header>
              {realEstates?.map((r) => (
                <SC.RealEstateItem
                  selected={selectedRealEstate?.id === r.id}
                  key={r._id}
                  onClick={() => setSelectedRealEstate(r)}
                >
                  <div style={{ flex: 3 }}>{r.str_representation}</div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  ></div>
                </SC.RealEstateItem>
              ))}
            </SC.RealEstateContainer>

            <SC.UsersContainer>
              {(roleUsersLoading || usersLoading || swapLoading) && (
                <OverlaySpinner />
              )}
              <SC.UserHeader>
                <SC.UserInner style={{ flex: 1, fontWeight: 600 }}>
                  Ordning
                </SC.UserInner>
                <SC.UserInner style={{ fontWeight: 600, flex: 4 }}>
                  Användare
                </SC.UserInner>
                <SC.UserInner style={{ flex: 1 }}></SC.UserInner>
              </SC.UserHeader>

              {orderedRoleUsers?.map((r, idx) => (
                <SC.UserItem key={r.id}>
                  <SC.UserInner
                    style={{ flex: 1, display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        marginRight: orderedRoleUsers?.length > 0 ? 6 : 0,
                      }}
                    >
                      {idx + 1}
                    </div>
                    {orderedRoleUsers.length > 0 && (
                      <>
                        {idx !== 0 && (
                          <SC.ArrowUpIcon
                            onClick={() =>
                              swapRoleUsers(r, orderedRoleUsers[idx - 1])
                            }
                          />
                        )}
                        {idx !== orderedRoleUsers.length - 1 && (
                          <SC.ArrowDownIcon
                            onClick={() =>
                              swapRoleUsers(orderedRoleUsers[idx + 1], r)
                            }
                          />
                        )}
                      </>
                    )}
                  </SC.UserInner>

                  <SC.UserInner style={{ flex: 4 }}>
                    {
                      users?.find((u) => u.id === r.user?.id)
                        ?.str_representation
                    }
                  </SC.UserInner>

                  <SC.UserInner style={{ flex: 1 }}>
                    <TextButton
                      title="Ta bort"
                      red
                      iconType="close"
                      clicked={() => removeRoleUser(r)}
                    />
                  </SC.UserInner>
                </SC.UserItem>
              ))}

              {!selectedRealEstate && (
                <SC.UserItem>
                  Välj en fastighet i listan till vänster för att se
                  fastighetens användarlista
                </SC.UserItem>
              )}

              {selectedRealEstate && !roleUsers?.length && canChangeRole && (
                <SC.UserItem>
                  Lägg till användare på rollen för fastigheten genom att klicka
                  på "Lägg till användare" nedanför
                </SC.UserItem>
              )}

              {selectedRealEstate && canChangeRole && (
                <SC.AddRoleButton onClick={() => setAddSingleUserOpen(true)}>
                  Lägg till användare <SC.AddRoleIcon />
                </SC.AddRoleButton>
              )}
            </SC.UsersContainer>
          </SC.Container>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
