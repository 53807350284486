export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ROUNDING_ERRAND",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ROUNDING_ERRAND",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ROUNDING_ERRAND",
  DESTROY_FORM: "DESTROY_FORM_ROUNDING_ERRAND",
  SET_FORM_ERROR: "SET_FORM_ERROR_ROUNDING_ERRAND",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ROUNDING_ERRAND",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ROUNDING_ERRAND",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ROUNDING_ERRAND",
  RESET_STATE: "RESET_STATE_ROUNDING_ERRAND",
  CLEAR_FETCHED: "CLEAR_FETCHED_ROUNDING_ERRAND",
  SET_OVERVIEW_STATS: "SET_ROUNDING_ERRAND_OVERVIEW_STATS",
  POST_ACCEPT_DECLINE: "POST_ACCEPT_DECLINE_ROUNDING_ERRAND",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ROUNDINGERRAND",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_ROUNDINGERRAND",
  REMOVE_OBJECT: "REMOVE_OBJECT_ROUNDINGERRAND",

  LIST_URL: "/errands/main/roundingerrand/list/",
  POST_URL: "/errands/main/roundingerrand/",
  PATCH_URL: "/errands/main/roundingerrand/",
  GET_URL: "/errands/main/roundingerrand/",
  MARK_NON_FAULTS_URL: "/errands/main/roundingerrand/bulk_mark_faults/",

  STORE_NAME: "roundingErrands",
};
