import * as React from "react";
import * as SharedStyles from "../../../components/sharedStyles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { buildQueryString } from "../../../store/base";
import { useFilteredCompanies } from "../../../store/companies";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { constants } from "../../../store/billectaSearch";
import SearchFields from "../../../components/Billecta/Search/SearchFields";
import {
  clearSearch,
  performSearch,
} from "../../../store/billectaSearch/store/actions";
import InvoiceTable from "../../../components/Billecta/Search/InvoiceTable";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";

import { addToast, TOAST_TYPES } from "../../../store/toasts";
import {
  convertPigelloCostCentersToBillecta,
  convertPigelloProductsToBillecta,
  convertPigelloProjectsToBillecta,
} from "../../../store/billectaInvoicing/store/services";

export default () => {
  const dispatch = useDispatch();
  const { creditorId } = useParams();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const [companies] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const searchResult = useSelector((state) => state[storeName].searchResult);

  const [selectedCostCenter, setSelectedCostCenter] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [selectedStatuses, setSelectedStatuses] = React.useState([]);

  const [searchShowing, setSearchShowing] = React.useState(true);
  const [searchLoading, setSearchLoading] = React.useState(false);

  const [invoiceNumber, setInvoiceNumber] = React.useState("");
  const [costPlace, setCostPlace] = React.useState("");

  const [invoiceDateFrom, setInvoiceDateFrom] = React.useState("");
  const [invoiceDateTo, setInvoiceDateTo] = React.useState("");

  const [dueDateFrom, setDueDateFrom] = React.useState("");
  const [dueDateTo, setDueDateTo] = React.useState("");

  const [periodDateFrom, setPeriodDateFrom] = React.useState("");
  const [periodDateTo, setPeriodDateTo] = React.useState("");

  const [createdDateFrom, setCreatedDateFrom] = React.useState("");
  const [createdDateTo, setCreatedDateTo] = React.useState("");

  const [attestedDateFrom, setAttestedDateFrom] = React.useState("");
  const [attestedDateTo, setAttestedDateTo] = React.useState("");

  const [closedDateFrom, setClosedDateFrom] = React.useState("");
  const [closedDateTo, setClosedDateTo] = React.useState("");

  React.useEffect(() => {
    if (searchResult) {
      setSearchShowing(false);
    }
  }, []);

  const search = async () => {
    setSearchLoading(true);

    let mappedProducts;
    let mappedCostCenter;
    let mappedProject;

    if (selectedProducts?.length) {
      mappedProducts = await convertPigelloProductsToBillecta({
        products: selectedProducts,
        companyId: company?.id,
      });
    }

    if (selectedCostCenter) {
      mappedCostCenter = await convertPigelloCostCentersToBillecta({
        costCenters: [selectedCostCenter],
        companyId: company?.id,
      });
    }

    if (selectedProject) {
      mappedProject = await convertPigelloProjectsToBillecta({
        projects: [selectedProject],
        companyId: company?.id,
      });
    }

    if (selectedProducts?.length && !mappedProducts) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej filtrera på produkter",
          description: "Sökningen genomförs ändå utan denna filtrering",
        })
      );
    }

    if (selectedCostCenter && !mappedCostCenter?.length) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej filtrera på kostnadsställe",
          description: "Sökningen genomförs ändå utan denna filtrering",
        })
      );
    }

    if (selectedProject && !mappedProject?.length) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej filtrera på projekt",
          description: "Sökningen genomförs ändå utan denna filtrering",
        })
      );
    }

    const searchParams = {
      SearchValue: invoiceNumber || undefined,
      CreditorPublicId: creditorId || undefined,
      InvoiceDateFrom: invoiceDateFrom || undefined,
      InvoiceDateTo: invoiceDateTo || undefined,
      DueDateFrom: dueDateFrom || undefined,
      DueDateTo: dueDateTo || undefined,
      CreatedDateFrom: createdDateFrom || undefined,
      CreatedDateTo: createdDateTo || undefined,
      AttestedDateFrom: attestedDateFrom || undefined,
      AttestedDateTo: attestedDateTo || undefined,
      ClosedDateFrom: closedDateFrom || undefined,
      ClosedDateTo: closedDateTo || undefined,
      PeriodDateFrom: periodDateFrom || undefined,
      PeriodDateTo: periodDateTo || undefined,
      ProductPublicIds: mappedProducts || undefined,
      Project: mappedProject?.[0] || undefined,
      CostCenter: mappedCostCenter?.[0] || undefined,
      Status: selectedStatuses.length ? selectedStatuses : undefined,
    };

    dispatch(
      performSearch({
        searchParams,
        successCallback: () => {
          setSearchLoading(false);
          setSearchShowing(false);
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Sökningen misslyckades",
            })
          );
          setSearchLoading(false);
          setSearchShowing(true);
        },
      })
    );
  };

  const handleSubmitClicked = () => {
    if (searchShowing) {
      search();
    } else {
      setSearchShowing(true);
      dispatch(clearSearch());
    }
  };

  const handleInvoiceClicked = (row) => {
    const invoiceId = row?.original?.ActionPublicId;

    push(invoiceDetailUrl({ creditorId, invoiceId }));
  };

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        {searchLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Avancerad sök</OverviewTitle>
            <OverviewSubtitle>
              Sök bland alla fakturor för {company?.str_representation}
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <PrimaryButton
            title={
              searchShowing
                ? "Sök bland fakturor och avtalsfakturor"
                : "Ny sökning"
            }
            newDesign
            clicked={handleSubmitClicked}
          />
        </OverviewTitleWrapper>

        {searchShowing && (
          <SearchFields
            {...{
              invoiceNumber,
              setInvoiceNumber,
              invoiceDateFrom,
              setInvoiceDateFrom,
              invoiceDateTo,
              setInvoiceDateTo,
              dueDateFrom,
              setDueDateFrom,
              dueDateTo,
              setDueDateTo,
              periodDateFrom,
              setPeriodDateFrom,
              periodDateTo,
              setPeriodDateTo,
              createdDateFrom,
              setCreatedDateFrom,
              createdDateTo,
              setCreatedDateTo,
              attestedDateFrom,
              setAttestedDateFrom,
              attestedDateTo,
              setAttestedDateTo,
              closedDateFrom,
              setClosedDateFrom,
              closedDateTo,
              setClosedDateTo,
              selectedCostCenter,
              setSelectedCostCenter,
              selectedProject,
              setSelectedProject,
              selectedProducts,
              setSelectedProducts,
              selectedStatuses,
              setSelectedStatuses,
            }}
          />
        )}

        {!searchShowing && (
          <>
            {searchResult?.InvoiceActions?.length ? (
              <SharedStyles.InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small style={{ marginTop: "4px" }}>
                    Fakturor som matchar sökningen
                  </OverviewTitle>
                </OverviewTitleWrapper>

                <InvoiceTable
                  handleRowClicked={handleInvoiceClicked}
                  invoices={searchResult?.InvoiceActions}
                />
              </SharedStyles.InnerBox>
            ) : (
              "Inga resultat"
            )}
          </>
        )}
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
};
