import * as React from "react";
import * as SC from "../Invoices/styles";
import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { useParams } from "react-router-dom";
import PaymentsTableFilters from "../ExternalFilters/PaymentsTableFilters";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import { RemoveButton } from "./styles";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { DateCell } from "../../Displays";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import {
  setShowConnectedPaymentFromDate,
  constants,
} from "../../../store/billectaInvoicing";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const PERSISTANT_FILTER_KEY = `persistant_category_paymentstable`;

export default ({
  payments,
  handleRowClicked,
  handleSelectedPaymentsUpdated,
  handleMatchSelectedPayments,
  handleRemovePayment,
  selectedPayments,
  matchSelectedDisabled,
}) => {
  const dispatch = useDispatch();
  const { creditorId } = useParams();
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [filterCounts, setFilterCounts] = React.useState(null);
  const [hasPersistantFilter, setHasPersistantFilter] = React.useState(
    !!localStorage.getItem(PERSISTANT_FILTER_KEY)
  );

  const connectedPaymentDateFrom = useSelector(
    (state) => state[constants.STORE_NAME].connectedPaymentFromDate
  );

  React.useState(() => {
    let newFilterCounts = {};

    payments.forEach((i) => {
      if (
        newFilterCounts[i.State] &&
        i.UnhandledPaymentType !== "OverPayment"
      ) {
        newFilterCounts[i.State] += 1;
      } else if (i.UnhandledPaymentType !== "OverPayment") {
        newFilterCounts[i.State] = 1;
      }

      if (i.State === "Unhandled" && i.UnhandledPaymentType === "OverPayment") {
        if (newFilterCounts["Overpaid"]) {
          newFilterCounts["Overpaid"] += 1;
        } else if (i.UnhandledPaymentType === "OverPayment") {
          newFilterCounts["Overpaid"] = 1;
        }
      }

      if (i.InvoiceNumber) {
        if (newFilterCounts["Matched"]) {
          newFilterCounts["Matched"] += 1;
        } else {
          newFilterCounts["Matched"] = 1;
        }
      }

      setFilterCounts(newFilterCounts);
    });
  }, [payments]);

  const updateFilter = React.useCallback(
    (filter) => {
      if (selectedFilter === filter) {
        setSelectedFilter(null);
        localStorage.removeItem(PERSISTANT_FILTER_KEY);
      } else {
        setSelectedFilter(filter);
        localStorage.setItem(PERSISTANT_FILTER_KEY, filter);
      }
    },
    [selectedFilter]
  );

  React.useEffect(() => {
    if (hasPersistantFilter) {
      const item = localStorage.getItem(PERSISTANT_FILTER_KEY);

      const filter = item ? item : null;

      updateFilter(filter);
    }
  }, [hasPersistantFilter]);

  const data = React.useMemo(() => {
    let displayedPayments = [];

    // map extra data
    displayedPayments = payments.map((i) => ({
      ...i,
      total: `${(i.Amount.Value / 100)?.toLocaleString("sv")}`,
    }));

    // filter external filters
    if (selectedFilter) {
      displayedPayments = displayedPayments.filter((i) => {
        if (selectedFilter === "Matched" && i.InvoiceNumber) return true;
        return i.State === selectedFilter;
      });
    }

    return displayedPayments;
  }, [payments, selectedFilter]);

  const billectaStateMap = {
    Unhandled: 6,
    Overpayment: 3,
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Ta bort betalning",
        accessor: "_",
        Cell: ({ row }) => {
          return (
            <RemoveButton onClick={() => handleRemovePayment(row?.original)} />
          );
        },
      },
      {
        Header: "Status",
        accessor: "State",
        Cell: (props) => {
          const { row } = props;

          const isOverpayment =
            row.original?.UnhandledPaymentType === "OverPayment";
          const isMatched = !!row.original?.InvoiceNumber;

          return (
            <StatusLabel
              state={
                isOverpayment
                  ? billectaStateMap["Overpayment"]
                  : isMatched
                  ? 4
                  : billectaStateMap[props.value]
              }
            >
              {billectaStateMap[props.value] === 6
                ? isOverpayment
                  ? "Överbetalning "
                  : "Ej matchad"
                : "Matchad"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Belopp (SEK)",
        accessor: "total",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },

      {
        Header: "Inbetalningsdatum",
        accessor: "PaymentDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Matchad mot faktura",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Referens",
        accessor: "Reference",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },

      {
        Header: "Avsändare",
        accessor: "SenderName",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },

      {
        Header: "OCR",
        accessor: "OCR",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Betalsätt",
        accessor: "PaymentMeanCode",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Person/Org:nr avsändare",
        accessor: "SenderOrgNo",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },

      {
        Header: "Bankgiro avsändare",
        accessor: "SenderBankgiroNo",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },

      {
        Header: "Adress avsändare",
        accessor: "SenderAddress",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },

      {
        Header: "Stad avsändare",
        accessor: "SenderCity",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Postnummer avsändare",
        accessor: "SenderZipCode",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
    ],
    []
  );

  const canMatch = selectedPayments?.length > 0;

  const renderDatePicker = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: 8 }}>
        Visa betalningar som är matchade sedan
      </div>

      <NonConnectedDatePicker
        inputExtraStyle={{ maxWidth: 160 }}
        id="billecta_payment_date_from"
        value={connectedPaymentDateFrom}
        onChange={(date) => {
          dispatch(setShowConnectedPaymentFromDate({ date, creditorId }));
        }}
        clearable={false}
        noMargin
        maxDate={moment().toDate()}
      />
    </div>
  );

  return (
    <>
      <BasicTable
        withSelectableRows
        handleSelectedUpdated={handleSelectedPaymentsUpdated}
        data={data}
        columns={columns}
        onRowClicked={handleRowClicked}
        tableId="paymentstable"
        withPersistantSortBy
        withPersistantGlobalFilter
        clearExternalFilters={() => {
          updateFilter(null);
          setHasPersistantFilter(false);
        }}
        hasPersistantExternalFilter={hasPersistantFilter}
        externalFilters={() => (
          <PaymentsTableFilters
            updateFilter={updateFilter}
            selectedFilter={selectedFilter}
            filterCounts={filterCounts}
          />
        )}
        renderBesideSearch={renderDatePicker}
      >
        {!matchSelectedDisabled && (
          <SC.HandleSelectedContainer>
            {/* {actionTabLoading && <OverlaySpinner />} */}
            <div>
              Hantera {selectedPayments?.length}{" "}
              {selectedPayments?.length > 1
                ? "valda betalningar"
                : "vald betalning"}
            </div>
            <PrimaryButton
              disabled={!canMatch}
              extraStyle={{ marginLeft: "auto" }}
              title="Matcha mot faktura"
              clicked={handleMatchSelectedPayments}
            />
          </SC.HandleSelectedContainer>
        )}
      </BasicTable>
    </>
  );
};
