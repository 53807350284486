import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "closed", display: "Avklarad" },
    { value: "preparation", display: "Förberedelse" },
    { value: "timedout", display: "Utgången" },
    { value: "pending", display: "Avvaktande" },
    { value: "canceled", display: "Avbruten" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
