import * as React from "react";

// style, design
import {
  ToolTipCell,
  GenericCell,
  DateCell,
  DurationCell,
  MultiEntryLinks,
} from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import SimpleIntervalFilter from "src/components/Lists/Base/CompleteList/Filters/IntervalFilter";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";

import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";

import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import {
  getContractFromAndTo,
  getCostBetweenMonthDates,
  getCostColumns,
} from "src/components/Lists/utils/costCalculations";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }) => {
        let label = row.original.state_display;
        const state = row.original.state;
        if (
          [6, 7].includes(state) &&
          !row.original.closed_signed &&
          row.original.uses_cancellation_e_signing
        ) {
          label += ` (Ej signerat)`;
        }
        const isDraft = row.original.draft;

        if (isDraft) {
          label = "Utkast";
        }

        return <StatusLabel state={state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    // searchable
    {
      Header: "Avtalsnummer",
      accessor: "id_number",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastigheter",
      accessor: "realestates",
      Cell: ({ value }) => {
        const realEstates = value || [];
        let mapping = [];
        let restLength = 0;

        realEstates.forEach((c) => {
          mapping.push({ obj: c, urlMethod: realEstateDetailUrl });
        });

        if (mapping.length > 2) {
          restLength = mapping.length - 2;
          mapping = mapping.slice(0, 2);
        }

        return <MultiEntryLinks mappings={mapping} restLength={restLength} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    // searchable

    {
      Header: "Koppling",
      accessor: "content_type",
      Cell: ({ row }) => (
        <GenericCell
          contentType={row.original.content_type}
          id={row.original.object_id}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Beställare",
      accessor: "ordering_party.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Utförare",
      accessor: "opponent_party.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Kategori",
      accessor: "category.title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Startdatum",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Slutdatum",
      accessor: (row, _) => (row?.renewed_to ? row.renewed_to : row.end_date),
      id: "end_date",
      Cell: ({ row }) => (
        <>
          {row.original.renewed_to ? (
            <>
              <DateCell date={row.original.renewed_to} />
              <span> (Förlängt)</span>
            </>
          ) : (
            <DateCell date={row.original.end_date} />
          )}
        </>
      ),
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Uppsägningstid",
      accessor: "notify_interval",
      Cell: ({ row }) => (
        <DurationCell
          durationStamp={row.original.notify_interval}
          shadowDate={row.original.notify_time}
        />
      ),
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Förlängningstid",
      accessor: "renew_interval",
      Cell: ({ row }) => {
        const forever = row.original.renew_indefinetely;
        if (forever) {
          return "Tillsvidare";
        }
        return <DurationCell durationStamp={row.original.renew_interval} />;
      },
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Kostnad/år",
      id: "custom_costs",
      alignRight: true,
      Cell: ({ row }) => {
        const original = row.original;

        let { contractFrom, contractTo } = getContractFromAndTo(original);

        if (!original.costs || original.costs.length === 0) return "-";

        let totalMonths = contractTo.diff(contractFrom, "months", true);

        let costs = getCostBetweenMonthDates(
          original.costs,
          contractFrom,
          contractTo
        );

        let totalCost = 0;

        for (let cost of costs) {
          totalCost +=
            Math.round(
              (cost.value * cost.unit_amount * totalMonths + Number.EPSILON) *
                100
            ) / 100;
        }

        return <p>{totalCost}</p>;
      },
    },
  ];

  return cols;
};
