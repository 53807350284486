export default () => [
  {
    title: "Besiktningstyp",
    key: "TYPE",
    visited: true,
    hasError: false,
    fieldKeys: ["setting", "title"],
  },
  {
    title: "Objekt att besikta",
    key: "PREMISES",
    visited: true,
    hasError: false,
    fieldKeys: [
      "apartment",
      "industrial_premises",
      "common_area",
      "brf_premis",
      "parking_spot",
    ],
  },

  {
    title: "Utförare",
    key: "PERFORMERS",
    visited: false,
    hasError: false,
    fieldKeys: ["performer", "can_use_key", "remind_executor_duration"],
  },
  {
    title: "Planering",
    key: "PLANNING",
    visited: false,
    hasError: false,
    fieldKeys: ["expected_duration", "planned_start", "planned_end"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["created_at", "reported_by"],
  },
];
