import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import OtherList from "src/components/Tables/OtherContracts/FullTableNewVersion";
import useActiveFilters from "../../../hooks/useActiveFilters";

export default function OtherContractDrafts() {
  const { filteredRealEstates } = useActiveFilters();

  const draftPersistantQuery = {
    realestate__in: filteredRealEstates,
    draft: true,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Utkast av övriga avtal</OverviewTitle>
          </OverviewTitleWrapper>

          <OtherList persistantQuery={draftPersistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
