import {
  ChevronLeftIcon,
  DocumentArrowUpIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import { PDFDocument } from "pdf-lib";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../store/base";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Alert, { ALERT_TYPES } from "../../Alert/Alert";
import { TextButton } from "../../Forms/Base/Buttons";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import {
  areImageDimsValid,
  loadFile,
  loadImage,
  toBase64,
} from "../../Forms/Base/Fields/ManyMediaField";
import OverlaySpinner from "../../Loaders/OverlaySpinner";

const BYTES_PER_MEGABYTE = 1000000;
const MAX_FILE_SIZE = 100;
const ALLOWED_FORMATS = [".pdf"];

const options = {};
const b64Prefix = "data:application/pdf;base64,";

export default function DigitalDocHandleAttachments({
  onDone,
  storeName,
  method,
  isFastDoc,
}) {
  const {
    minImageWidth,
    maxImageWidth,
    minImageHeight,
    maxImageHeight,
    resizeImage,
    imageQuality,
  } = options;
  const fieldKey = "attachments";
  const fileKey = "pdf";
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [highlighted, setHighlighted] = React.useState(false);
  const formats = ALLOWED_FORMATS;
  const [files, setFileList] = React.useState([]);
  const [errors, setError] = React.useState({});
  const [hasSetListeners, setHasSetListeners] = React.useState(false);

  const dragAreaRef = React.useRef();
  const fileInputRef = React.useRef(null);

  const value = useFormInstanceField({
    storeName,
    fieldKey,
  });
  const instructions = useFormField({
    storeName,
    method,
    fieldKey: "attachments",
  });
  const editableDoc = useFormInstanceField({
    storeName,
    fieldKey: "",
  });
  const b64Data = useFormInstanceField({
    storeName,
    fieldKey: "doc._tempData.data",
  });
  const mainFileUrl = useFormInstanceField({
    storeName,
    fieldKey: "doc.get",
  });

  const error = useFormError({ storeName, fieldKey });

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  let b64 = b64Data;
  if (b64 && !b64?.includes(b64Prefix)) {
    b64 = b64Prefix + b64;
  }

  const onMergeWithFile = async (file) => {
    const attachments = value;
    if (!attachments) {
      return false;
    }

    setLoading(true);

    const mergeDoc = await PDFDocument.create();

    // load file data
    // load main pdf data
    const mainData =
      b64 ??
      (mainFileUrl != null
        ? await fetch(mainFileUrl).then((res) => res.arrayBuffer())
        : null);
    if (!mainData) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Den valda bilagan kunde ej slås ihop med huvuddokumentet",
        })
      );
      setLoading(false);
      return false;
    }
    const biData =
      file?.pdf?.get != null
        ? await fetch(file?.pdf?.get).then((res) => res.arrayBuffer())
        : file?._tempData?.data;
    if (!biData) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Den valda bilagan kunde ej slås ihop med huvuddokumentet",
        })
      );
      setLoading(false);

      return false;
    }

    const mainPdf = await PDFDocument.load(mainData);
    const biPdf = await PDFDocument.load(biData);

    // merge these
    const copiedPagesA = await mergeDoc.copyPages(
      mainPdf,
      mainPdf.getPageIndices()
    );
    copiedPagesA.forEach((page) => mergeDoc.addPage(page));

    const copiedPagesB = await mergeDoc.copyPages(
      biPdf,
      biPdf.getPageIndices()
    );
    copiedPagesB.forEach((page) => mergeDoc.addPage(page));

    const fileId = file.id || file._referenceId;

    const atchCopy = cloneDeep(attachments).filter(
      (v) => v.id !== fileId && v._referenceId !== fileId
    );

    // remove file
    // set main pdf data
    const mergedData = await mergeDoc.saveAsBase64({ dataUri: true });

    let name = typeof editableDoc.doc === "string" ? editableDoc.doc : null;
    if (!name) {
      let fname = editableDoc?.doc?.get?.split("?");
      if (fname != null) {
        fname = fname[0].split("/");
        name = fname.length ? fname[fname.length - 1] : null;
      }

      if (!name) {
        name = "document.pdf";
      }
    }

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Den valda bilagan slogs ihop med huvuddokumentet",
      })
    );

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          doc: { doc: name, _tempData: { data: mergedData } },
          attachments: atchCopy,
        },
      })
    );

    setLoading(false);
  };

  const highlight = () => {
    setHighlighted(true);
  };
  const unhighlight = () => {
    setHighlighted(false);
  };

  const handleDrop = (e) => {
    let dt = e.dataTransfer;
    let files = dt.files;

    onChange(files);
  };

  React.useLayoutEffect(() => {
    if (!dragAreaRef.current || hasSetListeners) return;
    setHasSetListeners(true);

    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dragAreaRef.current.addEventListener(eventName, preventDefaults, false);
    });

    ["dragenter", "dragover"].forEach((eventName) => {
      dragAreaRef.current.addEventListener(eventName, highlight, false);
    });
    ["dragleave", "drop"].forEach((eventName) => {
      dragAreaRef.current.addEventListener(eventName, unhighlight, false);
    });

    dragAreaRef.current.addEventListener("drop", handleDrop, false);
  });

  const removeFile = (idx) => {
    const valueClone = cloneDeep(value);

    valueClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: valueClone,
        },
      })
    );
  };

  const onClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const onChange = async (fileList) => {
    if (!fileList || !fileList.length) return;

    // Scrub previous data from state
    setError(() => ({}));
    setFileList([]);

    // Convert native file list to iterable. Much easier to work with.
    let iterableFileList = Array.from(fileList);

    const anyHasWrongFormat = iterableFileList.some((f) => {
      const nameParts = f.name.split(".");
      return !formats.includes(
        `.${nameParts[nameParts?.length - 1]?.toLowerCase()}`
      );
    });

    if (anyHasWrongFormat) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "En eller flera filer är av ett icke godkänt format",
          description: `De godkända formaten är ${formats?.join(", ")}`,
        })
      );

      return;
    }

    if (MAX_FILE_SIZE) {
      // convert maxSize from megabytes to bytes
      const maxBytes = MAX_FILE_SIZE * BYTES_PER_MEGABYTE;
      const tooBig = iterableFileList.some((file) => file.size > maxBytes);
      setError((prevErrors) => ({
        ...prevErrors,
        hasInvalidFileSize: tooBig,
      }));

      if (tooBig) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "En eller flera filer är för stora",
            description: `Max godkänd storlek på fil är ${MAX_FILE_SIZE}MB`,
          })
        );
        return;
      }
    }

    const dims = {
      minImageWidth,
      maxImageWidth,
      minImageHeight,
      maxImageHeight,
    };
    // Is there at least one dim to care about?
    if (Object.values(dims).some(Boolean)) {
      try {
        const dataUrls = await Promise.all(iterableFileList.map(loadFile));
        const images = await Promise.all(dataUrls.map(loadImage));
        const hasImageWithInvalidDims = images.some(
          (image) => !areImageDimsValid(image, dims)
        );
        // Is there an image in the collection with invalid dims or...
        // ...does user want to change the quality of the images?
        if (
          (hasImageWithInvalidDims && resizeImage) ||
          (!hasImageWithInvalidDims && imageQuality)
        ) {
          const resizedImageBlobs = await Promise.all(
            images.map((image, index) => {
              // Either we resize based on a max width/height provided by the user...
              // Or we use the image itself and just change the quality (without scaling size).
              const maxSize =
                Math.max(maxImageWidth || 0, maxImageHeight || 0) ||
                Math.max(image.width, image.height);
              const imageType = iterableFileList[index].type;
              return resizeImage(image, maxSize, imageType, imageQuality);
            })
          );
          iterableFileList = resizedImageBlobs.map((blob, index) => {
            const fileName = iterableFileList[index].name;
            return new File([blob], fileName, {
              lastModified: Date.now(),
            });
          });
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidImage: false,
          }));
        } else if (hasImageWithInvalidDims) {
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidImage: true,
          }));
        } else {
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidImage: false,
          }));
        }
      } catch (err) {
        setError((prevErrors) => ({
          ...prevErrors,
          hasInvalidImage: true,
        }));
      }
    }

    setFileList(iterableFileList);
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = async () => {
    if (files.length === 0) {
      return;
    } else {
      setLoading(true);

      let newFiles = [];
      if (value?.length) {
        newFiles = [...value];
      }

      for (let i = 0; i < files.length; i++) {
        const restFile = await toBase64(files[i], fileKey);
        newFiles.push(restFile);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: newFiles },
        })
      );

      setFileList([]);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleChange();
  }, [files]);

  if (!fieldKey || !storeName || !instructions || instructions?._readOnly) {
    return null;
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-2">
        <PrimaryBtn secondary onClick={onDone}>
          <ChevronLeftIcon width={16} className="mr-1" /> Tillbaka till menyn
        </PrimaryBtn>

        <Alert title="Hantera bilagor" type={ALERT_TYPES.INFO}>
          Lägg till bilagor på dokumentet. Tryck på sammanför-knappen på dem för
          att sammanföra bilagan med huvuddokumentet.
          {isFastDoc && (
            <>
              <br />
              <br />
              <strong>OBS:</strong> Bilagor för signering i Fastighetsägarna
              Dokument hanteras i deras portal. Bilagor kan ej sammanföras ihop
              med dokument som ligger hos Fastighetsägarna.
            </>
          )}
        </Alert>
      </div>

      <div className="flex flex-col">
        <div className="text-xs mb-2">
          Tillåtna filformat: {formats?.join(", ")}
        </div>

        <div
          ref={dragAreaRef}
          className={classNames(
            "flex flex-col space-y-1 border border-dashed border-gray-300 rounded p-2",
            { "border-primaryblue bg-primaryblue/20": highlighted }
          )}
        >
          {loading && <OverlaySpinner />}
          {(value || []).map((file, idx) => (
            <div
              className={classNames(
                "border border-solid rounded px-2 py-1 text-xs flex overflow-hidden flex-col text-ellipsis font-medium"
              )}
              key={file.id || file._referenceId}
              id={file.id || file._referenceId}
            >
              <div className="flex justify-between items-center">
                <div className="text-ellipsis whitespace-nowrap max-w-[80%] overflow-hidden">
                  {file._tempData?.file_name || file.str_representation}
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center ml-2 "
                  onClick={() => removeFile(idx)}
                >
                  <XCircleIcon width={16} />
                </button>
              </div>

              {!isFastDoc && (
                <div>
                  <button
                    className="text-primaryblue font-medium text-sm inline-flex items-center hover:text-primaryblue-light  focus:ring-sky-200 focus:ring-4 rounded-sm"
                    onClick={() => onMergeWithFile(file)}
                  >
                    Sammanför med dokument{" "}
                    <DocumentArrowUpIcon width={16} className="ml-1" />{" "}
                  </button>
                </div>
              )}
            </div>
          ))}
          <div className="text-xs border border-solid rounded p-4 flex flex-col items-center text-center ">
            Släpp filer här
            <br />
            eller
            <TextButton
              title="Lägg till fil"
              iconType="add"
              clicked={onClick}
              iconPlacement="right"
              extraStyle={{ marginRight: "auto", marginLeft: "auto" }}
            />
          </div>
          <input
            type="file"
            ref={fileInputRef}
            multiple={true}
            accept={formats}
            style={{ display: "none" }}
            onChange={(evt) => {
              const target = evt.target;
              onChange(target.files);
            }}
          />
        </div>
      </div>
    </>
  );
}
