import {
  get,
  options,
  patch,
  getPagination,
  getAllFetchProcessName,
  destroyForm,
  getFiltered,
  addToProcess,
} from "../../base";
import { removeFromProgress } from "../../base/store/actions";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import * as services from "./services";

import { getFormInstanceStateType } from "../../base/utils";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const getCurrentConfigCenter = ({}) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, "configCenter");

    const configCenter = await services.getConfigCenter();

    // set current configcenter
    dispatch({
      type: constants.SET_CURRENT_CONFIGCENTER,
      payload: {
        configCenter,
      },
    });
    dispatch(setConfigCenterFormInstance({ configCenter }));
    removeFromProgress(dispatch, constants, "configCenter");
  };
};

export const setConfigCenterFormInstance = ({ configCenter }) => {
  return async (dispatch) => {
    dispatch({
      type: getFormInstanceStateType(constants.STORE_NAME),
      payload: { result: configCenter, clean: true },
    });
  };
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPatchForm = () => {
  const url = `${constants.PATCH_URL}`;
  return options({ url, constants, method: "PATCH" });
};

export const update = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}`;

  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess,
  });
};

export const getCreatePatchForm = () => {
  const url = `${constants.PATCH_URL}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};
