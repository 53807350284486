import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBookKeepingForm } from "../../../store/billectaBookKeeping/hooks/form";
import { usePaymentMeansForm } from "../../../store/billectaPaymentMeans/hooks/form";
import {
  getBookKeepingInstance,
  constants as bookKeepingConstants,
  getPeriodLockPeriod,
  lockPeriod,
  deleteLockPeriod,
} from "../../../store/billectaBookKeeping";
import {
  getPaymentMeansInstance,
  constants as paymentMeanConstants,
} from "../../../store/billectaPaymentMeans";
import { TextButton } from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";

import { InfoBox } from "../../Displays";
import { updateBookKeepingSettings } from "../../../store/billectaBookKeeping/store/actions";
import { useParams } from "react-router";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useCompany } from "../../../store/companies";
import { BodyText, InnerBox } from "../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import moment from "moment";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import TextInputField from "../../Forms/Base/Fields/TextInputField";
import SelectField from "../../Forms/Base/Fields/SelectField";
import CheckField from "../../Forms/Base/Fields/CheckField";
import LocalRadioGroupField from "../../Forms/Base/Fields/LocalRadioGroupField";
import Modal from "../../Forms/Base/Modals/Modal";
import LocalDateSelect from "../../Forms/Base/Fields/LocalDateSelect";
import ConfirmModal from "../../Forms/Base/Modals/ConfirmModal";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";

const ACCRUAL_TYPES = {
  NO_ACCRUAL: 0,
  RESTING_VAT: 1,
  ENABLED: 2,
};

export default function BookKeepingContainer({ forceCompanyId }) {
  const storeName = bookKeepingConstants.STORE_NAME;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = React.useState(false);

  const [lockPeriodOpen, setLockPeriodOpen] = React.useState(false);
  const [lockPeriodConfirmOpen, setLockPeriodConfirmOpen] =
    React.useState(false);
  const [lockPeriodDate, setLockPeriodDate] = React.useState(
    moment().subtract({ month: 1 }).endOf("month").format("YYYY-MM-DD")
  );
  const [lockPeriodLoading, setLockPeriodLoading] = React.useState(false);
  const [confirmRemoveLockPeriodOpen, setConfirmRemoveLockPeriodOpen] =
    React.useState(false);
  const [accrualType, setAccrualType] = React.useState(null);

  const { companyId } = useParams();
  const [company] = useCompany(forceCompanyId || companyId);
  const creditorId = company?.billecta_id;

  const bookKeepingInstance = useSelector(
    (state) => state.billectaBookKeeping.formInstance
  );
  const paymentMeansInstance = useSelector(
    (state) => state.billectaPaymentMeans.formInstance
  );
  const periodLockPeriod = useSelector(
    (state) => state.billectaBookKeeping[`periodLockPeriod_${creditorId}`]
  );

  const restingVatIsEnabled = useFormInstanceField({
    storeName,
    fieldKey: "RestingVatIsEnabled",
  });

  const accrualIsEnabled = useFormInstanceField({
    storeName,
    fieldKey: "AccrualIsEnabled",
  });

  React.useEffect(() => {
    if (
      accrualType === null &&
      (restingVatIsEnabled != null || accrualIsEnabled != null)
    ) {
      if (restingVatIsEnabled) {
        setAccrualType(ACCRUAL_TYPES.RESTING_VAT);
      } else if (accrualIsEnabled) {
        setAccrualType(ACCRUAL_TYPES.ENABLED);
      } else {
        setAccrualType(ACCRUAL_TYPES.NO_ACCRUAL);
      }
    }
  }, [restingVatIsEnabled, accrualIsEnabled]);

  useBookKeepingForm("PUT");
  usePaymentMeansForm("PUT");

  React.useEffect(() => {
    if (!Object.keys(bookKeepingInstance).length && creditorId) {
      dispatch(
        getBookKeepingInstance({
          creditorId,
        })
      );
    }
  }, [creditorId]);

  React.useEffect(() => {
    if (!Object.keys(paymentMeansInstance).length && creditorId) {
      dispatch(
        getPaymentMeansInstance({
          creditorId,
        })
      );
    }
  }, [creditorId]);

  React.useEffect(() => {
    if (creditorId && !periodLockPeriod) {
      dispatch(getPeriodLockPeriod({ creditorId }));
    }
  }, [periodLockPeriod, creditorId]);

  React.useEffect(() => {
    if (accrualType === null) return;
    if (accrualType === ACCRUAL_TYPES.NO_ACCRUAL) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            AccrualIsEnabled: false,
            RestingVatIsEnabled: false,
          },
        })
      );
    } else if (accrualType === ACCRUAL_TYPES.RESTING_VAT) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            AccrualIsEnabled: false,
            RestingVatIsEnabled: true,
          },
        })
      );
    } else if (accrualType === ACCRUAL_TYPES.ENABLED) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            AccrualIsEnabled: true,
            RestingVatIsEnabled: false,
          },
        })
      );
    }
  }, [accrualType]);

  React.useEffect(() => {
    if (
      Object.keys(paymentMeansInstance).length &&
      Object.keys(bookKeepingInstance).length
    ) {
      setLoading(false);
    }
  }, [paymentMeansInstance, bookKeepingInstance]);

  React.useEffect(() => {
    return () => {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: paymentMeanConstants.STORE_NAME,
          data: {},
        })
      );
    };
  }, []);

  const handleLockPeriod = () => {
    setLockPeriodLoading(true);
    dispatch(
      lockPeriod({
        creditorId,
        date: lockPeriodDate,
        successCallback: () => {
          setLockPeriodLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: `Bokföringen låstes fram till ${lockPeriodDate}`,
            })
          );
        },
        errorCallback: (message) => {
          setLockPeriodLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Perioden kunde inte låsas",
            })
          );
        },
      })
    );
  };

  const handleDeleteLockPeriod = () => {
    setLockPeriodLoading(true);
    dispatch(
      deleteLockPeriod({
        creditorId,
        successCallback: () => {
          setLockPeriodLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: `Bokföringslåsningen togs bort`,
            })
          );
        },
        errorCallback: (message) => {
          setLockPeriodLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Låsningen kunde inte tas bort",
            })
          );
        },
      })
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      updateBookKeepingSettings({
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              title: "Uppdateringen lyckades",
              type: TOAST_TYPES.SUCCESS,
            })
          );
        },
        errorCallback: (message) => {
          setLoading(false);
          dispatch(
            addToast({
              title: "Inställningarna kunde inte uppdateras",
              description: message ? message : "Något gick fel. Försök igen.",
              type: TOAST_TYPES.ERROR,
            })
          );
        },
        creditorId,
      })
    );
  };

  return (
    <>
      {confirmSubmitOpen && (
        <ConfirmModal
          onAccept={handleSubmit}
          closeFunction={() => setConfirmSubmitOpen(false)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Bekräfta uppdatering av bokföringsinställning. <br />
          </h3>

          <p>
            Uppdateringar i bokföringsinställningarna för ett bolag kan få stora
            konsekvenser i vad som skickas över till integrerade
            bokföringssystem. Kontakta gärna teamet på Pigello innan
            uppdateringen görs om du är osäker.
          </p>
        </ConfirmModal>
      )}
      {confirmRemoveLockPeriodOpen && (
        <ConfirmModal
          onAccept={handleDeleteLockPeriod}
          closeFunction={() => setConfirmRemoveLockPeriodOpen(null)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Bekräfta borttagande av låsning. <br />
          </h3>

          <p>
            Detta innebär att den tidigare låsta bokföringsperioden återigen
            blir öppen för händelser som leder till ändringar i bokföringen.
          </p>
        </ConfirmModal>
      )}

      {lockPeriodConfirmOpen && (
        <ConfirmModal
          onAccept={handleLockPeriod}
          closeFunction={() => setLockPeriodConfirmOpen(null)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Bekräfta låsning av period. <br />
          </h3>

          <p>
            Att låsa perioden innebär att inga fler händelser som påverkar
            bokföringen kan ske fram till det låsta datumet. Exempelvis kan inte
            fakturor och kreditfakturor skapas upp med ett fakturadatum som
            ligger innan det låsta datumet. Vänligen bekräfta att detta stämmer.
          </p>
        </ConfirmModal>
      )}

      {lockPeriodOpen && (
        <Modal
          closeFunction={() => setLockPeriodOpen(false)}
          title="Lås bokföring"
          onAccept={() => {
            setLockPeriodConfirmOpen(true);
            setLockPeriodOpen(false);
          }}
        >
          <LocalDateSelect
            value={lockPeriodDate}
            title="Lås bokföring till och med"
            id="lock_period"
            onChange={(date) => setLockPeriodDate(date)}
            maxDate={moment().subtract({ days: 1 }).toDate()}
          />
        </Modal>
      )}

      {loading && <OverlaySpinner />}
      <InfoBox
        boxTheme="info"
        title="Information om bokföringsinställningar"
        text="Uppgifterna ligger som underlag till exporten av
        bokföringsunderlag. Är du osäker vad du ska ange bör du kontakta din
        bokföringskonsult."
      />

      <InnerBox style={{ marginBottom: 24 }}>
        {lockPeriodLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Låsning av bokföringsperioder</OverviewTitle>
            <OverviewSubtitle>
              Genom att låsa bokföringen fram till ett visst datum förhindras
              nya bokföringshändelser fram till det låsta datumet. Detta innebär
              t.ex. att inga fakturor med ett fakturadatum till och med det
              låsta datumet kan attesteras.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {periodLockPeriod ? (
          <BodyText>
            <strong>
              Bokföringen är låst fram till och med{" "}
              {moment(periodLockPeriod).format("YYYY-MM-DD")}
            </strong>
          </BodyText>
        ) : (
          <>
            <BodyText>
              <strong>Bokföringen är ej låst fram till ett visst datum.</strong>{" "}
              Tryck på "Lås bokföring fram till ett visst datum" för att låsa
              bokföringen.
            </BodyText>
          </>
        )}

        <TextButton
          extraStyle={{ marginTop: 24 }}
          title="Lås bokföring fram till ett visst datum"
          iconType="lock"
          iconPlacement="right"
          clicked={() => setLockPeriodOpen(true)}
        />

        {periodLockPeriod && (
          <TextButton
            extraStyle={{ marginTop: 12 }}
            red
            title="Ta bort låsning av bokföring"
            iconType="close"
            iconPlacement="right"
            clicked={() => setConfirmRemoveLockPeriodOpen(true)}
          />
        )}
      </InnerBox>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Generella inställningar</OverviewTitle>
            <OverviewSubtitle>
              Inställingar för vilka standarder exporten av bokföringsunderlag
              ska ta hänsyn till.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            title="Verifikationsserie"
            storeName={bookKeepingConstants.STORE_NAME}
            method={"PUT"}
            fieldKey="VoucherSeries"
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <SelectField
            title="Räkenskapsår"
            storeName={bookKeepingConstants.STORE_NAME}
            method={"PUT"}
            fieldKey="FiscalYear"
          />
          <SelectField
            title="Bokföringsmetod"
            storeName={bookKeepingConstants.STORE_NAME}
            method={"PUT"}
            fieldKey="BookKeepingMethod"
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          {/* <TextInputField
            title="Exkludera kostnadsställe/projekt för konton"
            description="Ange konton som ska exkludera kostnadsställe och projekt i bokföring. Om inget anges kommer kostnadställe och projekt att visas för konton enligt systemets standardinställningar. Separera konton med mellanslag eller kommatecken."
            storeName={bookKeepingConstants.STORE_NAME}
            placeholder={`Skriv in konton, t.ex. "1010,1011,1012".`}
            method={"PUT"}
            fieldKey="ExcludedAccounts"
          /> */}

          <TextInputField
            title="Lås alla föregående perioder per vald dag"
            description="Lås alla föregående perioder per vald dag innevarande månad. Låsning av perioden innebär att bokföringshändelser förhindras fram till det låsta datumet. T.ex. kommer fakturor med ett fakturadatum innan datumet ej kunna attesteras."
            placeholder="Skriv in dag i månad som tidigare perioder ska låsas."
            storeName={bookKeepingConstants.STORE_NAME}
            method={"PUT"}
            fieldKey="LockPreviousPeriodsAtDay"
          />
        </div>

        <div className="mb-6">
          <LocalRadioGroupField
            title="Periodisering"
            description="Ange vilken typ av periodisering som ska användas."
            value={accrualType}
            onChange={(type) => setAccrualType(type)}
            options={[
              {
                title: "Använd ej periodisering",
                value: ACCRUAL_TYPES.NO_ACCRUAL,
              },
              {
                title: "Periodisering med vilande moms",

                value: ACCRUAL_TYPES.RESTING_VAT,
              },
              {
                title: "Periodisering utan vilande moms",

                value: ACCRUAL_TYPES.ENABLED,
              },
            ]}
          />
        </div>

        <div className="mb-6">
          <CheckField
            title="Bokför ej överbetalningar automatiskt"
            description="Överbetalningar bokförs automatiskt som standard. Kryssa denna ruta om detta ska inaktiveras."
            fieldKey="DisableBookkeepingOverPayments"
            {...{ method: "PUT", storeName: bookKeepingConstants.STORE_NAME }}
          />
        </div>
        <div className="mb-6">
          <CheckField
            title="Bokför ej omatchade betalningar automatiskt"
            description="Omatchade betalningar bokförs automatiskt som standard. Kryssa denna ruta om detta ska inaktiveras."
            fieldKey="DisableBookkeepingUnmatchedPayments"
            {...{ method: "PUT", storeName: bookKeepingConstants.STORE_NAME }}
          />
        </div>
        <div className="mb-6">
          <CheckField
            title="Tvinga samma dag för nyttjande av omatchad betalning"
            description="Nyttjande av en omatchad betalning sker alltid på betalningens
              inbetalningsdag."
            fieldKey="UsageOfUnmatchedPaymentIsBookedOnIncomingPaymentDate"
            {...{ method: "PUT", storeName: bookKeepingConstants.STORE_NAME }}
          />
        </div>
      </InnerBox>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Kontoplan</OverviewTitle>
            <OverviewSubtitle>
              Ange vilket konto dessa transaktioner ska bokas mot.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <div className="grid grid-cols-4 gap-6">
          {bookKeepingInstance?.Accounts?.filter((a) =>
            ["TradeDebtsAccount", "TradeDebtsTaxReductionAccount"].includes(
              a.AccountType
            )
          )?.map((account, index) => (
            <TextInputField
              key={index}
              title={
                ACCOUNT_MAPPER[`${account.AccountType}${account.VAT || ""}`]
              }
              storeName={bookKeepingConstants.STORE_NAME}
              method={"PUT"}
              fieldKey={`Accounts[${bookKeepingInstance.Accounts?.findIndex(
                (ba) =>
                  ba.AccountType === account.AccountType &&
                  ba.VAT === account.VAT
              )}].Account`}
              instructionsKey="Accounts.Account"
            />
          ))}
        </div>
        <hr />

        <div className="grid grid-cols-4 gap-6">
          {bookKeepingInstance?.Accounts?.filter((a) =>
            [
              "AccountPayablesAccount",
              "ShortTermDebtsAccount",
              "CustomerAdvancedPaymentsAccount",
              "UnmatchedPaymentsAccount",
              "AccrualAccount",
              "FinancialInstituteDebtAccount",
            ].includes(a.AccountType)
          )?.map((account, index) => (
            <TextInputField
              key={index}
              title={
                ACCOUNT_MAPPER[`${account.AccountType}${account.VAT || ""}`]
              }
              storeName={bookKeepingConstants.STORE_NAME}
              method={"PUT"}
              fieldKey={`Accounts[${bookKeepingInstance.Accounts?.findIndex(
                (ba) =>
                  ba.AccountType === account.AccountType &&
                  ba.VAT === account.VAT
              )}].Account`}
              instructionsKey="Accounts.Account"
            />
          ))}
        </div>
        <hr />
        <div className="grid grid-cols-4 gap-6">
          {bookKeepingInstance?.Accounts?.filter((a) =>
            ["VATAccount", "RestingVATAccount", "IncomingVATAccount"].includes(
              a.AccountType
            )
          )?.map((account, index) => (
            <TextInputField
              key={index}
              title={
                ACCOUNT_MAPPER[`${account.AccountType}${account.VAT || ""}`]
              }
              storeName={bookKeepingConstants.STORE_NAME}
              method={"PUT"}
              fieldKey={`Accounts[${bookKeepingInstance.Accounts?.findIndex(
                (ba) =>
                  ba.AccountType === account.AccountType &&
                  ba.VAT === account.VAT
              )}].Account`}
              instructionsKey="Accounts.Account"
            />
          ))}
        </div>
        <hr />
        <div className="grid grid-cols-4 gap-6">
          {bookKeepingInstance?.Accounts?.filter((a) =>
            ["FreightCostAccount", "AdministrationCostAccount"].includes(
              a.AccountType
            )
          )?.map((account, index) => (
            <TextInputField
              key={index}
              title={
                ACCOUNT_MAPPER[`${account.AccountType}${account.VAT || ""}`]
              }
              storeName={bookKeepingConstants.STORE_NAME}
              method={"PUT"}
              fieldKey={`Accounts[${bookKeepingInstance.Accounts?.findIndex(
                (ba) =>
                  ba.AccountType === account.AccountType &&
                  ba.VAT === account.VAT
              )}].Account`}
              instructionsKey="Accounts.Account"
            />
          ))}
        </div>
        <hr />
        <div className="grid grid-cols-4 gap-6">
          {bookKeepingInstance?.Accounts?.filter((a) =>
            [
              "SalesWithReverseVATAccount",
              "SalesWithRotRutAccount",
              "RoundingAccount",
              "OtherOperatingIncomeAccount",
              "WriteOffAccount",
              "RevenueCorrectionAccount",
            ].includes(a.AccountType)
          )?.map((account, index) => (
            <TextInputField
              key={index}
              title={
                ACCOUNT_MAPPER[`${account.AccountType}${account.VAT || ""}`]
              }
              storeName={bookKeepingConstants.STORE_NAME}
              method={"PUT"}
              fieldKey={`Accounts[${bookKeepingInstance.Accounts?.findIndex(
                (ba) =>
                  ba.AccountType === account.AccountType &&
                  ba.VAT === account.VAT
              )}].Account`}
              instructionsKey="Accounts.Account"
            />
          ))}
        </div>
        <hr />
        <div className="grid grid-cols-4 gap-6">
          {bookKeepingInstance?.Accounts?.filter((a) =>
            [
              "CurrencyDifferenceLossesAccount",
              "CurrencyDifferenceGainsAccount",
              "InterestAccount",
              "FinancialCostsAccount",
              "BankAndTransactionCostAccount",
            ].includes(a.AccountType)
          )?.map((account, index) => (
            <TextInputField
              key={index}
              title={
                ACCOUNT_MAPPER[`${account.AccountType}${account.VAT || ""}`]
              }
              storeName={bookKeepingConstants.STORE_NAME}
              method={"PUT"}
              fieldKey={`Accounts[${bookKeepingInstance.Accounts?.findIndex(
                (ba) =>
                  ba.AccountType === account.AccountType &&
                  ba.VAT === account.VAT
              )}].Account`}
              instructionsKey="Accounts.Account"
            />
          ))}
        </div>
      </InnerBox>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Betalsätt</OverviewTitle>
            <OverviewSubtitle>
              Inställningar för betalsätt och vilka konton de ska bokas på.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          {Object.keys(paymentMeansInstance)?.map((key) => (
            <React.Fragment key={key}>
              <TextInputField
                title={`${paymentMeansInstance[key].PaymentCode} namn`}
                storeName={paymentMeanConstants.STORE_NAME}
                method={"PUT"}
                fieldKey={`${key}.Description`}
                instructionsKey="Description"
              />
              <TextInputField
                title={`${paymentMeansInstance[key].PaymentCode} konto`}
                storeName={paymentMeanConstants.STORE_NAME}
                method={"PUT"}
                fieldKey={`${key}.BookkeepingAccount`}
                instructionsKey="BookkeepingAccount"
              />
            </React.Fragment>
          ))}
        </div>
      </InnerBox>

      <div className="flex justify-end">
        <PrimaryBtn onClick={() => setConfirmSubmitOpen(true)}>
          Spara uppdateringar
        </PrimaryBtn>
      </div>
    </>
  );
}

const ACCOUNT_MAPPER = {
  TradeDebtsAccount: "Kundfordringar",
  TradeDebtsTaxReductionAccount: "Kundfordran (ROT/RUT)",
  AccountPayablesAccount: "Leverantörsskulder",
  ShortTermDebtsAccount: "Kortfristiga skulder",
  AccrualAccount: "Periodiseringskonto",
  FinancialInstituteDebtAccount: "Skulder till finansiella institut",
  CustomerAdvancedPaymentsAccount: "Förskott från kund",
  UnmatchedPaymentsAccount: "Omatchade betalningar",
  VATAccount6: "Moms 6%",
  VATAccount12: "Moms 12%",
  VATAccount25: "Moms 25%",
  IncomingVATAccount: "Ingående moms",
  RestingVATAccount: "Vilande moms",
  FreightCostAccount: "Fraktavgifter 0%",
  FreightCostAccount6: "Fraktavgifter 6%",
  FreightCostAccount12: "Fraktavgifter 12%",
  FreightCostAccount25: "Fraktavgifter 25%",
  AdministrationCostAccount: "Administrationsavgifter 0%",
  AdministrationCostAccount6: "Administrationsavgifter 6%",
  AdministrationCostAccount12: "Administrationsavgifter 12%",
  AdministrationCostAccount25: "Administrationsavgifter 25%",
  SalesWithReverseVATAccount: "Försälj. Omvänd moms",
  SalesWithRotRutAccount: "Försälj. Arbete ROT/RUT",
  RoundingAccount: "Öresavrundning",
  OtherOperatingIncomeAccount: "Övriga rörelseintäkter",
  WriteOffAccount: "Bortskrivningar/Kundförluster",
  RevenueCorrectionAccount: "Intäktskorrigeringar",
  CurrencyDifferenceLossesAccount: "Valutakursförluster",
  CurrencyDifferenceGainsAccount: "Valutakursvinster",
  InterestAccount: "Ränteintäkter",
  FinancialCostsAccount: "Finansiella kostnader",
  BankAndTransactionCostAccount: "Bank/transaktionskostnader",
};
