import * as React from "react";
import { useHistory } from "react-router-dom";
import { TextButton } from "../../Forms/Base/Buttons";
import { detailUrl } from "../../../store/pipes";

import * as SC from "./styles";
import MenuButton from "../../Forms/Base/Buttons/MenuButton";
import { InnerBox } from "../../sharedStyles";
import { HomeQL } from "../Pipe/styles";
export default function PipeList({
  pipes,
  onEdit,
  onDelete,
  canEditPipe,
  canDeletePipe,
  stages,
  leadsPerStageData,
}) {
  const { push } = useHistory();

  return (
    <SC.PipeWrapper>
      {pipes?.map((pipe) => {
        const actions = [];

        const pipeStages = stages?.filter((s) => s.pipe?.id === pipe?.id);

        const [totalConfirmed, totalLatent, totalDenied] = pipeStages?.reduce(
          (acc, cur) => {
            const matchingData = leadsPerStageData?.[cur.id];

            if (!matchingData) return acc;

            return [
              acc[0] + matchingData.confirmed,
              acc[1] + matchingData.latent,
              acc[2] + matchingData.denied,
            ];
          },
          [0, 0, 0]
        );

        if (canEditPipe) {
          actions.push({
            name: "Redigera projekt",
            onClick: () => onEdit(pipe.id),
          });
        }
        if (canDeletePipe) {
          actions.push({
            name: "Radera projekt",
            onClick: () => onDelete(pipe.id),
          });
        }
        return (
          <SC.Pipe>
            <SC.ColorBorder color={pipe.color_code} />
            <SC.PipeHeader>
              <SC.PipeTitle>
                {pipe.name} {pipe.is_homeq_pipe && <HomeQL />}
              </SC.PipeTitle>
              {actions?.length > 0 && (
                <MenuButton leftAligned actions={actions} />
              )}
            </SC.PipeHeader>
            <SC.PipeDescription>
              {pipe.description || "Saknar beskrivning"}
            </SC.PipeDescription>

            {!pipe?.is_homeq_pipe && (
              <InnerBox style={{ marginTop: 8 }}>
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 12,
                    }}
                  >
                    <strong style={{ marginRight: 4 }}>Bekräftade:</strong>{" "}
                    {totalConfirmed}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 12,
                    }}
                  >
                    <strong style={{ marginRight: 4 }}>Väntande:</strong>{" "}
                    {totalLatent}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 12,
                    }}
                  >
                    <strong style={{ marginRight: 4 }}>Nekade:</strong>{" "}
                    {totalDenied}
                  </div>
                </>
              </InnerBox>
            )}
            <SC.PipeFooter>
              <TextButton
                title="Gå till projekt"
                iconType="arrow"
                iconPlacement="right"
                clicked={() => push(detailUrl({ id: pipe.id }))}
              />
            </SC.PipeFooter>
          </SC.Pipe>
        );
      })}
    </SC.PipeWrapper>
  );
}
