import * as React from "react";

import Badge from "../../../Badge/Badge";
import { cloneDeep } from "lodash";
import { retrieve } from "../../../../store/base/store/services";
import { buildQueryString } from "../../../../store/base";
import { useDispatch } from "react-redux";
import { TOAST_TYPES, addToast } from "../../../../store/toasts";

export const useBadgeFilters = ({
  constants,
  badges,
  tableId,
  badgesChecked,
  setBadgesChecked,
  disableBadgeFilterCounts,
  ignoreLocalStorage,
  persistantQuery,
}) => {
  // const [badgeFilters, setBadgeFilters] = React.useState([]);

  const dispatch = useDispatch();

  const [badgeFilterCounts, setBadgeFilterCounts] = React.useState({});

  const [selectedBadges, setSelectedBadges] = React.useState([]);

  const [badgeQuery, setBadgeQuery] = React.useState({});

  const resetBadgeFilter = () => {
    setSelectedBadges([]);
  };

  React.useEffect(() => {
    if (ignoreLocalStorage) return;
    let stringifiedSelectedBadgeFilters = localStorage.getItem(
      `TABLE_SELECTED_BADGES_${tableId}`
    );

    if (
      !stringifiedSelectedBadgeFilters ||
      stringifiedSelectedBadgeFilters.length === 0
    )
      return;

    let parsed;

    try {
      parsed = JSON.parse(stringifiedSelectedBadgeFilters);
    } catch (err) {
      return;
    }

    setSelectedBadges(parsed);
  }, []);

  React.useEffect(() => {
    if (ignoreLocalStorage) return;
    localStorage.setItem(
      `TABLE_SELECTED_BADGES_${tableId}`,
      JSON.stringify(selectedBadges)
    );
  }, [selectedBadges]);

  React.useEffect(() => {
    let newBadgeQuery = {};

    let propsToDelete = [];

    for (let name of selectedBadges) {
      //check if the prop already exist
      //if it does, make in an array and add the value

      //remove the old prop and replace with {prop}__in

      let querySet = badges[name].querySet;

      for (let prop in querySet) {
        if (newBadgeQuery.hasOwnProperty(prop)) {
          if (Array.isArray(querySet[prop])) {
            //not tested

            if (!newBadgeQuery[prop]) newBadgeQuery[prop] = querySet[prop];
            else
              newBadgeQuery[prop] = [...newBadgeQuery[prop], ...querySet[prop]];

            continue;
          }

          if (
            !Array.isArray(newBadgeQuery[prop]) &&
            !propsToDelete.includes(prop)
          ) {
            newBadgeQuery[`${prop}__in`] = [
              newBadgeQuery[prop],
              querySet[prop],
            ];
            propsToDelete.push(prop);
          } else {
            if (propsToDelete.includes(prop)) {
              newBadgeQuery[`${prop}__in`].push(querySet[prop]);
              continue;
            }

            console.warn("reached no reach land. should never happen");

            // newBadgeQuery[prop].push(querySet[prop]);
          }
        } else {
          newBadgeQuery[prop] = querySet[prop];
        }
      }
    }

    for (let prop of propsToDelete) {
      delete newBadgeQuery[prop];
    }

    // console.log("new badge query", newBadgeQuery);

    setBadgeQuery(newBadgeQuery);

    if (!badgesChecked) setBadgesChecked(true);
  }, [selectedBadges]);

  const chooseBadge = (name) => {
    let clone = selectedBadges.slice();

    if (clone.includes(name)) {
      clone.splice(clone.indexOf(name), 1);
    } else {
      clone.push(name);
    }

    setSelectedBadges(clone);
  };

  const badgeFilters = React.useMemo(() => {
    let newBadgeFilters = [];

    for (let badgeName in badges) {
      let count = 0;

      if (badgeFilterCounts.hasOwnProperty(badgeName))
        count = badgeFilterCounts[badgeName];

      newBadgeFilters.push(
        <Badge
          onClick={() => chooseBadge(badgeName)}
          type={badges[badgeName].color}
          selected={selectedBadges.includes(badgeName)}
          className="mt-2"
          key={newBadgeFilters.length}
        >
          {badgeName}
          {!disableBadgeFilterCounts && (
            <span className="ml-1 opacity-70">({count})</span>
          )}
        </Badge>
      );
    }

    return newBadgeFilters;
  }, [badges, badgeFilterCounts, selectedBadges]);

  React.useEffect(() => {
    //Load all badge counts

    if (Object.keys(badgeFilterCounts).length !== 0) return;

    if (disableBadgeFilterCounts) return;

    const doFetch = async () => {
      let newCounts = {};

      let baseUrl = constants.LIST_URL;

      let baseQuery = {
        ...persistantQuery,
        _page: 1,
        _page_size: 0,
      };

      let gotCountError = false;

      for (let badgeName in badges) {
        let badge = badges[badgeName];

        let badgeQuery = cloneDeep(baseQuery);
        badgeQuery = {
          ...badge.querySet,
          ...baseQuery,
        };

        await new Promise((resolve) => {
          retrieve({ url: `${baseUrl}?${buildQueryString(badgeQuery)}` })
            .then((res) => {
              newCounts[badgeName] = res.data.count;
              resolve();
            })
            .catch((err) => {
              //show toast, after all request
              gotCountError = true;
              newCounts[badgeName] = 0;
              resolve();
            });
        });
      }

      if (gotCountError) {
        console.log("badge count error!");

        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde inte hämta filtreringsantal",
            description:
              "En eller flera av filtrerings-knapparna kunde inte hämta antalet",
          })
        );
      }

      setBadgeFilterCounts(newCounts);
    };

    doFetch();
  }, []);

  return {
    badgeQuery,
    badgeFilters,
    resetBadgeFilter,
  };
};
