import * as React from "react";
import { buildQueryString } from "../../../../store/base";
import { useFilteredRealEstates } from "../../../../store/realEstates";
import * as ISC from "../../../Details/InfoTable/styles";

const USER_TYPE_DISPLAY = {
  0: "Admin",
  1: "Standard",
  2: "Worker",
  4: "Gäst",
};

export default ({ instance }) => {
  const realestatesIds = instance?.realestates;
  const realEstateQuery = buildQueryString({ id__in: realestatesIds });
  const [realestates] = useFilteredRealEstates(realEstateQuery);

  const getRealestateNames = () => {
    return realestates.map((r) => r.str_representation).join(", ");
  };
  return (
    <ISC.InfoTable>
      <ISC.InfoTableTitleWrapper>
        <ISC.InfoTableTitle>
          <ISC.InfoTableTitle>Användarinformation</ISC.InfoTableTitle>
        </ISC.InfoTableTitle>
      </ISC.InfoTableTitleWrapper>

      <ISC.InfoTableContent>
        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Företagsnamn:</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.corporate_name || "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Förnamn:</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.first_name || "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Efternamn:</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.last_name || "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>E-post:</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.email || "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Telefon:</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.phone || "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>
            Personnummer/Org-nummer:
          </ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.legal_id || "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>
        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Användartyp:</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.user_type === 0 || instance?.user_type
              ? USER_TYPE_DISPLAY[instance.user_type]
              : "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>
        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Fastigheter:</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.realestates?.length
              ? getRealestateNames()
              : "Inga fastigheter valda"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>
      </ISC.InfoTableContent>
    </ISC.InfoTable>
  );
};
