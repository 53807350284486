import * as React from "react";

export default () => {
  return (
    <div>
      Välj de hyresobjekt som hyresavtalet gäller för.
      <br />
      <br />
      Enbart hyresobjekt från samma fastighet kan väljas.
    </div>
  );
};
