import * as React from "react";
import * as SC from "./styles";

export default ({ clicked, disabled, title, extraStyle, fillWidth }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };

  return (
    <SC.SecondaryButton
      style={{ ...extraStyle }}
      {...{ fillWidth }}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
    </SC.SecondaryButton>
  );
};
