import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";
import { lightOrDark } from "../../DigitalDoc/utils";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
export default () => [
  {
    Header: "Inställning",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Gäller för lägenheter",
    accessor: "for_apartments",
    Cell: ({ value }) => (
      <StatusLabel state={value ? 0 : 6}>{value ? "Ja" : "Nej"}</StatusLabel>
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Gäller för lokaler",
    accessor: "for_industrial_premises",
    Cell: ({ value }) => (
      <StatusLabel state={value ? 0 : 6}>{value ? "Ja" : "Nej"}</StatusLabel>
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Gäller för fordonsplatser",
    accessor: "for_parking_spots",
    Cell: ({ value }) => (
      <StatusLabel state={value ? 0 : 6}>{value ? "Ja" : "Nej"}</StatusLabel>
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Dag i månad för generering",
    accessor: "day_in_month",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Förskjutning av vakansbokning",
    accessor: "month_offset",
    Cell: ({ value }) => <ToolTipCell text={`${value} månad(er)`} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Exluderade statusar",
    accessor: "not_for_statuses",
    Cell: ({ value }) => {
      if (!value?.length) return <div>-</div>;

      return value.map((status) => {
        return (
          <StatusLabel
            style={{
              backgroundColor: status.color_code || "#f1f1f1",
              color:
                lightOrDark(status.color_code || "#f1f1f1") === "light"
                  ? "#000"
                  : "#fff",
            }}
          >
            {status?.str_representation}
          </StatusLabel>
        );
      });
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },

  {
    Header: "Inkluderade statusar",
    accessor: "only_for_statuses",
    Cell: ({ value }) => {
      if (!value?.length) return <div>Gäller för alla objekt</div>;

      return value.map((status) => {
        return (
          <StatusLabel
            style={{
              backgroundColor: status.color_code || "#f1f1f1",
              color:
                lightOrDark(status.color_code || "#f1f1f1") === "light"
                  ? "#000"
                  : "#fff",
            }}
          >
            {status?.str_representation}
          </StatusLabel>
        );
      });
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
];
