import * as React from "react";
import { useFormInstanceField } from "../../../../../store/base";
import { constants } from "../../../../../store/invoicingService";

// style, design

import { FormAreaTitle } from "../../../Base/Chapters/styles";
import Interval, {
  INTERVAL_MAP_SERVICECONTRACT,
} from "../../../Base/Fields/CustomFields/Interval";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const intervalMode = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.mode",
  });

  return (
    <>
      <FormAreaTitle>Schemaläggning på avtal</FormAreaTitle>

      <Interval
        mode={intervalMode}
        storeName={storeName}
        method={method}
        baseFieldKey="interval_setting"
        config={INTERVAL_MAP_SERVICECONTRACT}
      />
    </>
  );
};
