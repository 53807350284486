import * as React from "react";
import * as SC from "./styles";

export default ({ clicked, disabled, title, extraStyle }) => {
  return (
    <SC.CreateNewButton
      style={{ ...extraStyle }}
      disabled={disabled}
      onClick={clicked}
    >
      {title}
    </SC.CreateNewButton>
  );
};
