import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

import { constants } from "../../../store/errandRoleUsers";

export default function ErrandRoleUsersTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "role_users_full_table"}
        title={"Rollanvändare"}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
