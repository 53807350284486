import * as React from "react";

import { constants } from "../../../../../store/leads";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableFileM2m from "../../../Base/Fields/TableFileM2m";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Dokument</OverviewTitle>
          <OverviewSubtitle>Dokument kopplade till leaden</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TableFileM2m
        storeName={storeName}
        method={method}
        fieldKey={"documents"}
        fileKey="file"
        allowedFileFormats={[".pdf", ".jpg", ".png", ".jpeg"]}
        title="Dokument"
      />
    </>
  );
};
