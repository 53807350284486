export const constructStrRep = (obj) => {
  return (
    obj.str_representation ||
    obj?.tenant?.user?.corporate_name ||
    obj?.tenant?.user?.last_name ||
    obj?.tenant?.user?.first_name ||
    obj?.tenant?.user?.legal_id ||
    obj?.tenant?.user?.email ||
    obj?.tenant?.invoice_email ||
    "Bostadsrättsinnehavare"
  );
};
export const detailUrl = ({ id, showInvoicingSettingPrompt }) => {
  return `/members/detail/${id}${
    showInvoicingSettingPrompt ? "?showInvoicingSettingPrompt=true" : ""
  }`;
};
export const overviewUrl = () => {
  return `/members`;
};
