import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/actions";
import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
} from "../../base";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
} from "../store/actions";
import constants from "../store/constants";

export function useStage(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useStages(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredStages(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useFilteredStagesDeepPagination({ querystring, pipeId }) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
    pipeId,
  };

  return useFilteredDeepPaginationObjects(params);
}

export function useStagePagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

function useFilteredDeepPaginationObjects({
  storeName,
  fetchMethod,
  querystring,
  pipeId,
}) {
  const dispatch = useDispatch();
  // undefined, null e.g -> "";
  const formattedQueryString = querystring || "";

  const userLoggedin = useSelector((state) => !!state.app.authorization);

  const hasAttemptedLogin = useSelector(
    (state) => !!state.app.hasAttemptedLogin
  );

  const existing = useSelector(
    (state) => state[storeName].filtered[formattedQueryString]
  );

  const all = useSelector((state) => state[storeName].all);

  const existingPreviousLeads = useSelector(
    (state) => state[storeName][`leadPaginationPreviews_pipe${pipeId}`]
  );
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(formattedQueryString)
  );

  const filteredObjects = useSelector((state) => {
    if (!existing && !existingPreviousLeads?.length) {
      return undefined;
    } else if (!existing && existingPreviousLeads) {
      const result = Object.values(all).filter((obj) => obj.pipe === pipeId);
      result.forEach((stage) => {
        const matchingPreviousLeads = existingPreviousLeads.filter(
          (pLead) => pLead.stage === stage.id
        );

        if (matchingPreviousLeads) {
          matchingPreviousLeads.forEach((pLead) => {
            stage.leads.preview.push(pLead);
          });

          const unique = new Set(stage.leads.preview);

          stage.leads.preview = [...unique];

          stage.leads.preview.sort((a, b) =>
            a.last_stage_change > b.last_stage_change ? -1 : 1
          );
        }
      });

      return result;
    } else {
      const distinct = existing.filter((id, index) => {
        return existing.indexOf(id) === index;
      });

      let result = [];
      distinct.forEach((id) => {
        const obj = state[storeName].all[id];
        if (obj) {
          result.push(obj);
        }
      });

      if (existingPreviousLeads) {
        result.forEach((stage) => {
          const matchingPreviousLeads = existingPreviousLeads.filter(
            (pLead) => pLead.stage === stage.id
          );

          if (matchingPreviousLeads) {
            matchingPreviousLeads.forEach((pLead) => {
              stage.leads.preview.push(pLead);
            });

            const unique = new Set(stage.leads.preview);

            stage.leads.preview = [...unique];

            stage.leads.preview.sort((a, b) =>
              a.last_stage_change > b.last_stage_change ? -1 : 1
            );
          }
        });
      }

      return result;
    }
  });

  if (!userLoggedin) {
    if (hasAttemptedLogin) {
      dispatch(logout());
    }
    return [undefined, isFetching];
  }

  // first check if it exists in store
  if (filteredObjects) {
    if (!existing && !isFetching) {
      dispatch(fetchMethod(formattedQueryString));
    }
    // it exists, apply filter if we need to, otherwice just return
    return [filteredObjects, isFetching];
  }

  // if it doesn't exist check if it is currently beeing fetched
  // if so, we wait for that

  if (isFetching || querystring === null) {
    return [[], isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(fetchMethod(formattedQueryString));

  return [[], isFetching];
}
