import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useParkingSpot, constants } from "../../store/parkingSpots";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import ParkingSpotOverview from "./spotDetails/Overview";
import ParkingSpotContracts from "./spotDetails/Contracts";
import { useAllPermissionCheck, usePermissionCheck } from "../../store/base";
import ParkingSpotIMD from "./spotDetails/IMD";
import ParkingSpotMarket from "./spotDetails/Market";
import { useExternalSyncErrorPaginationCount } from "../../store/externalSyncErrors";
import { SYNC_ERROR_MAP } from "../../components/Tables/ExternalSyncErrors/FullTable";
import ObjectCosts from "../../components/Details/OverviewInfo/Costs/ObjectCosts";
import TFPremisesBase from "../../components/TechnicalManagement/PremisesBase";

export default function MainParkingSpotDetails() {
  const { parkingSpotId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [parkingSpot] = useParkingSpot(parkingSpotId);
  const canDelete = usePermissionCheck("delete_can_parking");
  const canImd = useAllPermissionCheck(["allow_imd", "view_can_imd"]);
  const canMarket = useAllPermissionCheck(["allow_market", "view_can_market"]);
  const canErrands = useAllPermissionCheck([
    "allow_errand_faults",
    "view_can_reporterrand",
  ]);

  const [marketErrorCount] = useExternalSyncErrorPaginationCount({
    filters: {
      api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
      parkingspot: parkingSpotId,
    },
  });

  const hasMarketError = marketErrorCount > 0;

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ParkingSpotOverview,
    },
    {
      path: "/costs",
      label: "Debiteringsrader",
      component: ObjectCosts,
    },
    {
      path: "/contract",
      label: "Avtal",
      component: ParkingSpotContracts,
    },
  ];

  if (canErrands) {
    SUB_PAGES.push({
      path: "/errands",
      label: "Teknisk förvaltning",
      component: TFPremisesBase,
    });
  }

  if (canMarket) {
    SUB_PAGES.push({
      path: "/market",
      label: "Marknad",
      component: ParkingSpotMarket,
      hasError: hasMarketError,
    });
  }
  if (canImd) {
    SUB_PAGES.push({
      path: "/imd",
      label: "IMD",
      component: ParkingSpotIMD,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel contentType="premises" state={parkingSpot?.state}>
          {parkingSpot?.state_display}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={parkingSpot}
        constants={constants}
        deletedCallback={() => replace("/parking/parking-spots")}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Fordonsplats ${
            parkingSpot?.str_representation || "Laddar..."
          }`}
          breadCrumbs={[
            { url: "/parking/parking-spots", label: "Fordonsplatser" },
            { label: parkingSpot?.str_representation || "Laddar..." },
          ]}
          eventContentType="standard.parkingspot"
          eventObjectId={parkingSpotId}
          eventIdQueryStr="parkingspot"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
