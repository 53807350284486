import * as React from "react";

import { Confirm, Lead, Details, Contacts, Documents } from "./Chapters";

export default ({ key, method, hideObjects }) => {
  switch (key) {
    case "LEAD":
      return <Lead method={method} />;
    case "DETAILS":
      return <Details {...{ hideObjects }} method={method} />;
    case "CONTACT_PERSONS":
      return <Contacts method={method} />;
    case "DOCUMENTS":
      return <Documents method={method} />;
    default:
      return <Confirm method={method} />;
  }
};
