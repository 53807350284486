import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import ApartmentsTable from "../../../components/Tables/Apartments/FullTableNewVersion";

import useActiveFilters from "../../../hooks/useActiveFilters";

export const PERSISTANT_CATEGORY_KEY = "persistant_category_apartment";

export default function Apartments() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Översikt</OverviewTitle>
        </OverviewTitleWrapper>

        <ApartmentsTable persistantQuery={persistantQuery} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
