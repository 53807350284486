import * as React from "react";

// style, design
import { BooleanLabel, DateCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import BooleanFilter from "src/components/Lists/Base/CompleteList/Filters/BooleanFilter";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";

export default () => [
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fast uppräkning",
    id: "_is_dynamic",
    Cell: ({ value, row }) => {
      return (
        <BooleanLabel
          value={row.original?.index_quota != null}
          onLabel={"Ja"}
          offLabel={"Nej"}
        />
      );
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Basdatum",
    accessor: "index_base_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Andel som räknas upp",
    accessor: "index_amount",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Använd underliggande produkt",
    accessor: "use_cost_product",
    Cell: ({ value }) => {
      return <BooleanLabel value={value} onLabel={"Ja"} offLabel={"Nej"} />;
    },
    Filter: BooleanFilter,
    filter: "text",
  },
];
