import * as React from "react";
import { useTransition } from "@react-spring/web";

import * as SC from "./styles";
import {
  PrimaryButton,
  LinkButton,
  SecondaryButton,
  TextButton,
} from "../Forms/Base/Buttons";

export default function StandardModal({
  isOpen,
  closeFunction,

  //for when the "close function" is go back but the top close button should close
  forceCloseFunction,
  saveFunction,
  children,
  withActionBar,
  title,
  high = false,
  large = false,
  small = false,
  extraStyle = {},
  noBottomPadding = false,
  actionBarAcceptTitle = null,
  actionBarCancelTitle = null,
  canAccept = true,
  canCancel = true,
  canClose = true,
  headerActionTitle,
  headerAction,
  actionBarAcceptIsWarning = false,
  extraContentStyles = {},
  hideHeaderBorder,
  // custom header component, i.e options button
  renderHeaderComponent,

  // custom action bar
  renderCustomActionBar,
  // special styling rules if modal in modal
  modalInModal,
}) {
  const backdropRef = React.useRef();
  const modalTransition = useTransition(!!isOpen, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateY(100%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  // const handleBackdropClicked = (e) => {
  //   if (backdropRef?.current === e.target) {
  //     closeFunction();
  //   }
  // };

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.StandardBackDrop
          onClick={(e) => e.stopPropagation()}
          ref={backdropRef}
          style={{ opacity: styles.opacity }}
          {...{ modalInModal }}
        >
          <SC.Modal
            style={{ ...styles, ...extraStyle, opacity: 1 }}
            {...{ noBottomPadding, high, large, small, modalInModal }}
          >
            {(title || renderHeaderComponent) && (
              <SC.ModalHeader
                {...{
                  withHeaderAction: !!renderHeaderComponent,
                  hideHeaderBorder,
                }}
              >
                {title}

                {canClose && (
                  <SC.CloseCircleButton
                    onClick={forceCloseFunction || closeFunction}
                  />
                )}

                {renderHeaderComponent ? renderHeaderComponent() : null}
              </SC.ModalHeader>
            )}
            <SC.ModalContent
              style={{ ...extraContentStyles }}
              {...{ withTitle: !!title, noBottomPadding }}
            >
              {children}
            </SC.ModalContent>
            {withActionBar && (
              <SC.ModalActionBar>
                {headerAction && (
                  <SecondaryButton
                    extraStyle={{ marginRight: "auto" }}
                    title={headerActionTitle}
                    clicked={headerAction}
                  />
                )}
                {canCancel && (
                  <TextButton
                    clicked={closeFunction}
                    title={actionBarCancelTitle || "Avbryt"}
                    extraStyle={{ marginRight: "24px" }}
                  />
                )}
                {saveFunction && (
                  <PrimaryButton
                    disabled={!canAccept}
                    clicked={saveFunction}
                    title={actionBarAcceptTitle || "Spara"}
                    extraStyle={
                      actionBarAcceptIsWarning
                        ? { backgroundColor: "rgb(235, 55, 43)" }
                        : ""
                    }
                  />
                )}
              </SC.ModalActionBar>
            )}

            {renderCustomActionBar && (
              <>
                <SC.ModalActionBar style={{ justifyContent: "flex-start" }}>
                  {renderCustomActionBar()}
                </SC.ModalActionBar>
              </>
            )}
          </SC.Modal>
        </SC.StandardBackDrop>
      )
  );
}
