import { cloneDeep, constant } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  clearFetched,
  addToProcess,
} from "../../base";
import { removeFromProgress } from "../../base/store/actions";
import { axiosInstance } from "../../base/store/axios";
import { getSingleFetchProcessName } from "../../base/utils";
import { store } from "../../store";
import { addToast, TOAST_TYPES } from "../../toasts";
import constants from "./constants";
import { createUploadHandler } from "./uploadHandler";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};
export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  (cloned?.images || []).forEach((a) => {
    // will never be sent to backend
    delete a._tempData;
    delete a._referenceId;

    if (a.id) {
      // file has not been edited, so we can just use the id
      delete a.image;
    }
  });
  return cloned;
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
    uploadCallback: uploadCallback,
  });
};

export const clearFetchedData = (clearAll) => {
  return clearFetched(constants, clearAll);
};

export const generateBrokerDoc = ({
  brfPremisesId,
  attrName,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, attrName);

      const URL = `${constants.BROKER_DOC_URL}`;

      const newBrokerDoc = await axiosInstance.post(URL, {
        additional_kwargs: {
          brf_premis_id: parseInt(brfPremisesId),
        },
        format: "pdf",
      });

      const pdf = newBrokerDoc.data.result?.get;

      dispatch({
        type: constants.SET_BROKER_IMAGE,
        payload: {
          attrName,
          brokerDoc: pdf,
        },
      });

      removeFromProgress(dispatch, constants, attrName);

      successCallback && successCallback();
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej skapa mäklarbild",
        })
      );
      errorCallback && errorCallback();
    }
  };
};
