import * as React from "react";
import Select from "react-select";

export default function SimpleChoiceFilter({ choices, column, disablePortal, extraStyles }) {
  const { filterValue, setFilter } = column;

  return (
    <div style={{ minWidth: "250px", ...extraStyles }} >
      <Select
        id={column.id}
        menuPortalTarget={disablePortal ? undefined : document.querySelector("body")}
        isClearable
        placeholder="Välj..."
        onChange={(selected) => {
          setFilter(selected?.value);
        }}
        defaultValue={null}
        value={choices.find((c) => c.value === filterValue)}
        options={choices}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.display}
      />
    </div>
  );
}
