import { cloneDeep } from "lodash";
import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_CONTRACT_LEADS: {
      return {
        ...newState,
        contractLeads: payload.leads,
      };
    }

    case constants.SET_CONTRACT_LEADS_WARNINGS: {
      return {
        ...newState,
        contractLeadWarnings: payload.warnings,
      };
    }

    case constants.SET_CONTRACT_PROPOSALS: {
      return {
        ...newState,
        contractProposals: payload.proposals,
      };
    }

    case constants.SET_CREATED_CONTRACTS: {
      return {
        ...newState,
        createdContracts: payload.createdContracts,
      };
    }

    case constants.SET_COST_PROPOSALS: {
      return {
        ...newState,
        costProposals: payload.proposals,
      };
    }

    case constants.REMOVE_CONTRACT_LEAD: {
      return {
        ...newState,
        contractLeads: newState.contractLeads?.filter(
          (l) => l.id !== payload.id
        ),
      };
    }

    case constants.REMOVE_CONTRACT_PROPOSAL: {
      return {
        ...newState,
        contractProposals: newState.contractProposals?.filter(
          (l) => l.id !== payload.id
        ),
      };
    }

    case constants.UPDATE_CONTRACT_PROPOSAL: {
      return handleUpdateProposal({ newState, payload });
    }

    case constants.REMOVE_COST_PROPOSAL: {
      return {
        ...newState,
        costProposals: newState.costProposals?.filter(
          (l) => l._reduxId != payload.id
        ),
      };
    }

    case constants.UPDATE_COST_PROPOSAL: {
      return handleUpdateCostProposal({ newState, payload });
    }

    case constants.SET_CONTRACT_SIGNING_TYPE: {
      return { ...newState, signingType: payload.type };
    }

    case constants.SET_DOCUMENT_APPROVAL_INDEX: {
      return {
        ...newState,
        docIndex: payload.index,
      };
    }

    case constants.SET_DOCUMENT_DENIED: {
      const newDeniedDocContracts =
        cloneDeep(newState.deniedDocContracts) || [];
      newDeniedDocContracts.push(payload.contract);
      return {
        ...newState,
        deniedDocContracts: newDeniedDocContracts,
        docIndex: newState.docIndex + 1,
      };
    }

    case constants.CLEAR_CONTRACT_CREATION_DATA: {
      return {
        ...newState,
        deniedDocContracts: undefined,
        docIndex: undefined,
        costProposals: undefined,
        contractLeads: undefined,
        contractProposals: undefined,
        contractLeadWarnings: undefined,
        formInstance: {},
      };
    }
    default:
      return newState;
  }
};

const handleUpdateProposal = ({ newState, payload }) => {
  const newProposals = newState.contractProposals.map((p) => {
    if (p._reduxId === payload.id) {
      return payload.newProposal;
    } else {
      return p;
    }
  });

  return { ...newState, contractProposals: newProposals };
};

const handleUpdateCostProposal = ({ newState, payload }) => {
  const newProposals = newState.costProposals.map((p) => {
    if (p._reduxId === payload.id) {
      return payload.newProposal;
    } else {
      return p;
    }
  });

  return { ...newState, costProposals: newProposals };
};
