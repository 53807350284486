import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import ParkingList from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersion";
import useActiveFilters from "../../../hooks/useActiveFilters";

export const PERSISTANT_CATEGORY_KEY = "persistant_category_parkingcontract";

export default function ParkingContracts() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate__in: filteredRealEstates,
    draft: false,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Parkeringsavtal</OverviewTitle>
          </OverviewTitleWrapper>

          <ParkingList persistantQuery={persistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
