import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PopupButton } from "../../components/Forms/Base/Buttons";
import { useAllPermissionCheck, useAnyPermissionCheck } from "../../store/base";
import { useKeyPaginationCount, createUrl as keyCreateUrl } from "../../store/key";
import { createUrl as keyPermissionCreateUrl } from "../../store/keypermission";

import Keys from "./overview/Keys";
import KeyPermissions from "./overview/KeyPermissions";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

export default function MainKeysOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  const { filteredRealEstates } = useActiveFilters();

  usePermissionRedirect(["view_can_keys"]);

  const canAdd = useAllPermissionCheck(["add_can_keys"]);

  let actions = [];

  if (canAdd) {
    actions.push({
      onClick: () => push(keyCreateUrl()),
      name: "Nyckel",
    });

    actions.push({
        onClick: () => push(keyPermissionCreateUrl()),
        name: "Nyckelbehörighet",
      });
  }

  const [activeCount] = useKeyPaginationCount({
    filters: { realestate_ids: filteredRealEstates },
  });

  const SUB_PAGES = [
    {
      path: "/keys",
      label: "Nycklar",
      component: Keys,
    },
    {
      path: "/keypermissions",
      label: "Nyckelbehörigheter",
      component: KeyPermissions,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal nycklar:{" "}
          {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <>
      {actions?.length > 0 && (
        <PopupButton title="Lägg till..." actions={actions} leftAligned />
      )}
    </>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Nycklar`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
