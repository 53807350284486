import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import {
  useMarketingRequirement,
  constants,
  overviewUrl,
} from "../../store/marketRequirements";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import RequirementOverview from "./RequirementDetail/Overview";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import RequirementForm from "../../components/Forms/MarketRequirement/ChapterForm/ModalForm";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { usePermissionCheck } from "../../store/base";
import SbfConnections from "./RequirementDetail/SbfConnections";
export default function RequirementDetail() {
  const { requirementId } = useParams();
  const [requirement] = useMarketingRequirement(requirementId);
  const { url, path } = useRouteMatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const { push } = useHistory();

  const hasEditPerm = usePermissionCheck("change_can_market");
  const hasDeletePerm = usePermissionCheck("delete_can_market");
  const hasSbf = usePermissionCheck("allow_sbf");

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: RequirementOverview,
    },
  ];

  if (hasSbf) {
    SUB_PAGES.push({
      path: "/sbf-connections",
      label: "Bostadsförmedlingen Stockholm kopplingar",
      component: SbfConnections,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (hasEditPerm) {
    handleActions.push({
      name: "Redigera",
      onClick: () => setEditOpen(true),
    });
  }
  if (hasDeletePerm) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  return (
    <>
      <RequirementForm
        method="PATCH"
        id={requirementId}
        instance={requirement}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DeleteModal
        isOpen={deleteOpen}
        instance={requirement}
        constants={constants}
        deletedCallback={() => push(overviewUrl())}
        closeFunction={() => setDeleteOpen(false)}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Kravprofil ${requirement?.name || "laddar..."}`}
          breadCrumbs={[
            {
              url: "/market/requirements",
              label: "Kravprofiler",
            },
            {
              label: requirement?.name || "Laddar...",
            },
          ]}
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
