import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import RequirementForm from "../../../components/Forms/MarketRequirement/ChapterForm/ModalForm";
import RequirementsList from "../../../components/Tables/MarketRequirements/FullTable";
import { usePermissionCheck } from "../../../store/base";

export default function MarketRequirements() {
  const [createOpen, setCreateOpen] = React.useState(false);
  const hasAddPerm = usePermissionCheck("add_can_market");
  return (
    <>
      <RequirementForm
        method="POST"
        isOpen={createOpen}
        onCheckout={() => setCreateOpen(false)}
      />
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Kravprofiler</OverviewTitle>
              <OverviewSubtitle>
                Kravprofiler används för att specificera kraven för att få
                ansöka eller visa intresse på en publicerad annons
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            {hasAddPerm && (
              <PrimaryButton
                title="Lägg till kravprofil"
                clicked={() => setCreateOpen(true)}
              />
            )}
          </OverviewTitleWrapper>

          <RequirementsList />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
