import { useObject, usePagination, useFilteredObjects } from "../../base";
import { performFilter, filterPagination, getSingle } from "../store/actions";
import constants from "../store/constants";

export function useProductType(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useFilteredProductTypes(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useProductTypePagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}
