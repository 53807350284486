import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  useTodoTenantStatistics,
  useTodoUserStatistics,
} from "../../../store/overview";

import UserTable from "../../../components/Tables/Users/FullTable";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const READABLE_TYPES = {
  inative_today: "Inaktiveras idag",
  missing_realestates: "Saknar tillgång till en fastighet",
  unaccapted: "Ej accepterat inbjudan",
  anonymize: "Bör anonymiseras eller tas bort",
  uninvited: "Ej inbjuden till hyresgästportal",
};

export default function UsersTodo() {
  const [openTable, setOpenTable] = React.useState(null);
  const [rawTodoTenantStats, tenantLoading] = useTodoTenantStatistics();
  const [rawTodoUserStats, userLoading] = useTodoUserStatistics();

  const filteredUserStatKeys = Object.keys(rawTodoUserStats?.data || {}).filter(
    (key) => rawTodoUserStats?.data?.[key]?.length > 0
  );
  const filteredTenantStatKeys = Object.keys(
    rawTodoTenantStats?.data || {}
  ).filter((key) => rawTodoTenantStats?.data?.[key]?.length > 0);

  return (
    <DetailInnerWrapper>
      {filteredUserStatKeys?.length > 0 ||
      filteredTenantStatKeys?.length > 0 ? (
        <>
          {filteredUserStatKeys?.length > 0 &&
            filteredUserStatKeys.map((key) => {
              const data = rawTodoUserStats?.data[key];
              const open = openTable === `${key}`;
              const ids = data?.map((d) => d.id);
              const persistantQuery = {
                id__in: ids,
              };

              return (
                <DetailPageBox key={key}>
                  <OverviewTitleWrapper>
                    <OverviewTitle>
                      {READABLE_TYPES[key]} ({data?.length})
                    </OverviewTitle>
                  </OverviewTitleWrapper>
                  <InnerBox style={{ marginBottom: 12 }}>
                    <OverviewTitleWrapper
                      style={{ marginBottom: open ? 24 : 0 }}
                    >
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Användare ({data?.length})
                        </OverviewTitle>
                        <OverviewSubtitle>
                          {!open
                            ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa användare`
                            : `Klicka på en användare för att komma till detaljsidan`}
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>

                      <PrimaryButton
                        title={!open ? "Visa detaljer" : "Stäng"}
                        clicked={
                          !open
                            ? () => setOpenTable(`${key}`)
                            : () => setOpenTable(null)
                        }
                      />
                    </OverviewTitleWrapper>

                    {open && (
                      <UserTable
                        {...{
                          persistantQuery,
                          isBare: true,
                          ignoreLocalStorage: true,
                        }}
                      />
                    )}
                  </InnerBox>
                </DetailPageBox>
              );
            })}

          {filteredTenantStatKeys?.length > 0 &&
            filteredTenantStatKeys.map((key) => {
              const data = rawTodoTenantStats?.data[key];
              const open = openTable === `${key}`;

              const ids = data?.map((d) => d.id);
              const persistantQuery = {
                id__in: ids,
              };

              return (
                <DetailPageBox key={key}>
                  <OverviewTitleWrapper>
                    <OverviewTitle>
                      {READABLE_TYPES[key]} ({data?.length})
                    </OverviewTitle>
                  </OverviewTitleWrapper>
                  <InnerBox style={{ marginBottom: 12 }}>
                    <OverviewTitleWrapper
                      style={{ marginBottom: open ? 24 : 0 }}
                    >
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Hyresgäster ({data?.length})
                        </OverviewTitle>
                        <OverviewSubtitle>
                          {!open
                            ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa hyresgäster`
                            : `Klicka på en hyresgäst för att komma till detaljsidan`}
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>

                      <PrimaryButton
                        title={!open ? "Visa detaljer" : "Stäng"}
                        clicked={
                          !open
                            ? () => setOpenTable(`${key}`)
                            : () => setOpenTable(null)
                        }
                      />
                    </OverviewTitleWrapper>

                    {open && (
                      <TenantTable
                        {...{
                          persistantQuery,
                          isBare: true,
                          ignoreLocalStorage: true,
                        }}
                      />
                    )}
                  </InnerBox>
                </DetailPageBox>
              );
            })}
        </>
      ) : (
        <DetailPageBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OverviewTitle style={{ padding: "48px 0 " }}>
            {tenantLoading || userLoading ? "Laddar..." : "Inget att hantera"}
          </OverviewTitle>
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
