import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject } from "../../Displays";

import SimpleTextFilter from "../../Lists/Base/CompleteList/Filters/TextFilter";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as brfCompanyDetailUrl } from "../../../store/brfCompanies";
import { detailUrl as buildingDetailUrl } from "../../../store/buildings";

import InvoiceFilter from "../LeaseContracts/Filters/InvoiceFilter";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";

export default () => [
  {
    Header: "Internt id",
    accessor: "internal_premises_id",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Objektsnummer",
    accessor: "premises_id",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Aviseringsstatus",
    accessor: "brf_invoicing_active",
    Cell: ({ value }) => (
      <StatusLabel
        state={
          value === true ? 1 : value === null ? 6 : value === false ? 3 : 10
        }
      >
        {value === true
          ? "Konfigurerad"
          : value === null
          ? "Ej konfigurerad"
          : value === false
          ? "Pausad"
          : "Okänd"}
      </StatusLabel>
    ),
    Filter: InvoiceFilter,
    filter: "textExact",
  },
  {
    Header: "Innehavare",
    accessor: "tenant.str_representation",
    Cell: ({ row }) => {
      return row.original.tenant ? (
        <LinkedObject
          obj={{
            id: row.original.tenant.id,
            str_representation: `${row.original.tenant.str_representation}${
              row.original?.bi_tenants?.length > 0
                ? ` + ${row.original?.bi_tenants?.length}`
                : ""
            }`,
          }}
          urlMethod={tenantDetailUrl}
        />
      ) : (
        "-"
      );
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },

  {
    Header: "BRF",
    accessor: "brf_company.str_representation",
    Cell: ({ row }) => {
      return row.original.brf_company ? (
        <LinkedObject
          obj={row.original.brf_company}
          urlMethod={brfCompanyDetailUrl}
        />
      ) : (
        "-"
      );
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },

  {
    Header: "Andel av BRF",
    accessor: "quota",
    Cell: ({ row }) => (
      <ToolTipCell text={row.original.quota ? `${row.original.quota}%` : "0"} />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },

  {
    Header: "Fastighet",
    accessor: "realestate.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Byggnad",
    accessor: "building.str_representation",
    Cell: ({ row }) => {
      return row.original.building ? (
        <LinkedObject
          obj={row.original.building}
          urlMethod={buildingDetailUrl}
        />
      ) : (
        "-"
      );
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
