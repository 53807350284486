import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import {
  useVacancyInvoicingRecordPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/invoicingVacancyRecords";
import { useHistory } from "react-router-dom";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";

export default function VacancyBookingRecordTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  withDetailUrl = false,
  withInvoiceDetailUrl = false,
}) {
  const { push } = useHistory();

  const columns = React.useMemo(() => {
    const data = columnDefs(push);
    return data;
  }, [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "invocing_vacancy_record_full_table"}
      title={"Vakansbokningsloggar"}
      onRowClickedWithDetail={(obj) =>
        withDetailUrl
          ? detailUrl({ id: obj.id })
          : withInvoiceDetailUrl
          ? invoiceDetailUrl({
              invoiceId: obj.billecta_id?.object_id,
              creditorId: obj.billecta_id?.creditor_id,
            })
          : {}
      }
      initialOrderBy={["-updated_at"]}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
