import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Checkbox, Select, TextInput } from "../../../Base/Fields";
import { constants } from "../../../../../store/IMDMeasureType";
import { useFormInstanceField } from "../../../../../store/base";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
import SelectField from "src/components/Forms/Base/Fields/SelectField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const unit = useFormInstanceField({
    storeName,
    fieldKey: "unit",
  });

  return (
    <>
      <FormAreaTitle>Inställningar för mätartyp</FormAreaTitle>

      <TextInput
        title="Namn på mätartypen"
        {...{ storeName, method, fieldKey: "title" }}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          TableComponent={InvoicingProductTable}
          fieldKey="product"
          title="Bokföringsprodukt"
          description="Ange vilken bokföringsprodukt som ska användas för debitering av konsumtion under denna mätartyp."
          storeName={storeName}
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Typ av konsumtion"
          description="Ange vilken typ av konsumtion mätartypen avser."
          {...{ storeName, method, fieldKey: "kind" }}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Produktkategori för avstämning vid driftavräkning"
          description="Ange vilken typ av produkt mätartypen avser. Observera att det är denna kategori som debiteringen kommer driftavräknas mot. Om ingen kategori väljs kommer konsumtionen EJ att driftavräknas vid debitering."
          {...{ storeName, method, fieldKey: "product_category" }}
        />
      </div>

      <DoubleFieldWrapper>
        <Select
          extraStyles={{ marginRight: 12, minWidth: 188 }}
          title="Prefix på enhet"
          description="Ange vilket prefix som ska användas"
          {...{ storeName, method, fieldKey: "prefix" }}
        />

        <Select
          extraStyles={{ minWidth: 400 }}
          title="Enhet för konsumtion"
          description="Ange vilken enhet konsumtionen importeras i Pigello i. T.ex. om elkonsumtionen räknas i kWh (Kilowatt-timmar) så anges enheten till Watt) medan vattenkonsumtion oftast räknas i m³ (Kubikmeter)"
          {...{ storeName, method, fieldKey: "unit" }}
        />
      </DoubleFieldWrapper>

      <Checkbox
        storeName={storeName}
        method={method}
        fieldKey={"billable"}
        title={"Mätvärden av denna typ ska leda till debitering"}
        description={"Fakturering tillåts ske för mätvärden av denna typ"}
      />
    </>
  );
};
