import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";

import {
  useAllPermissionCheck,
  useFrequentPermissions,
} from "../../store/base";
import BrfModal from "../../components/Forms/BrfPremises/ChapterForm/ModalForm";
import BrfPremises from "./overview/BrfPremises";
import {
  useBrfPremisesPaginationCount,
  handleManyInvoicingUrl,
} from "../../store/brfPremises";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import HandleMassInvoincing from "./overview/HandleMassInvoicing";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
export default function MainBrfPremisesOverview() {
  const { path, url } = useRouteMatch();
  const { filteredRealEstates } = useActiveFilters();
  const { push } = useHistory();

  const [createBrfPremisesOpen, setCreateBrfPremisesOpen] =
    React.useState(false);
  usePermissionRedirect(["allow_brf", "view_can_baseobject", "view_can_brf"]);

  const canAdd = useAllPermissionCheck(["add_can_baseobject", "add_can_brf"]);
  const { hasBillectaFullPermission } = useFrequentPermissions();

  const [activeCount] = useBrfPremisesPaginationCount({
    filters: { realestate_ids: filteredRealEstates },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: BrfPremises,
    },
    {
      path: "/handle-invoicing-statuses",
      hiddenLabel: true,
      component: HandleMassInvoincing,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal bostadsrätter: {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {hasBillectaFullPermission && (
        <PrimaryBtn secondary onClick={() => push(handleManyInvoicingUrl())}>
          Masshantera aviseringsstatus
        </PrimaryBtn>
      )}
      {canAdd && (
        <PrimaryBtn
          className="ml-2"
          onClick={() => setCreateBrfPremisesOpen(true)}
        >
          Lägg till bostadsrätt
        </PrimaryBtn>
      )}
    </div>
  );

  return (
    <>
      {createBrfPremisesOpen && (
        <BrfModal
          method="POST"
          onCheckout={() => setCreateBrfPremisesOpen(false)}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Bostadsrätter`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
