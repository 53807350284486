import * as React from "react";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import { constants as basicConstants } from "../../../store/basicDocs";

import ParkingContractsTable from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersion";
import OtherContractsTable from "src/components/Tables/OtherContracts/FullTableNewVersion";
import BasicDocTable from "../../../components/Tables/BasicDocs/FullTable";

import {
  buildQueryString,
  updateActiveFormInstance,
  usePermissionCheck,
} from "../../../store/base";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useDispatch } from "react-redux";

import BasicModal from "../../../components/Forms/BasicDoc/ChapterForm/ModalForm";
import { useParams } from "react-router";
import { useLeaseContract } from "../../../store/leaseContracts";

function LeaseContractConnectedContracts() {
  const { leaseContractId } = useParams();
  const [leaseContract] = useLeaseContract(leaseContractId);
  const dispatch = useDispatch();

  //share same permission
  const canAdd = usePermissionCheck("add_can_contract");

  const [createBasicActive, setCreateBasicActive] = React.useState(false);

  const parkingQuery = !!leaseContract?.id
    ? { main_contract: leaseContract.id }
    : null;

  const otherQuery = !!leaseContract?.id
    ? { main_contract: leaseContract?.id }
    : null;

  const basicQuery = {
    content_type__model: "leasecontract",
    object_id: leaseContract?.id,
  };

  const setBasicInitialData = () => {
    const data = {
      content_type: "leasecontract",
      object_id: leaseContract?.id,
      realestate: { id: leaseContract?.realestate },
    };
    dispatch(
      updateActiveFormInstance({ storeName: basicConstants.STORE_NAME, data })
    );
  };

  const initiateBasicCreation = () => {
    setBasicInitialData();
    setCreateBasicActive(true);
  };

  return (
    <DetailInnerWrapper>
      {createBasicActive && (
        <BasicModal
          method="POST"
          onCheckout={() => setCreateBasicActive(false)}
        />
      )}

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Parkeringsavtal</OverviewTitle>
        </OverviewTitleWrapper>
        <ParkingContractsTable
          persistantQuery={parkingQuery}
          isBare
          ignoreLocalStorage
        />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Övriga avtal</OverviewTitle>
        </OverviewTitleWrapper>
        <OtherContractsTable
          persistantQuery={otherQuery}
          isBare
          ignoreLocalStorage
        />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Dokument</OverviewTitle>
        </OverviewTitleWrapper>
        <BasicDocTable persistantQuery={basicQuery} isBare ignoreLocalStorage />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default LeaseContractConnectedContracts;
