import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function checkActivated() {
  const res = await axiosInstance.get(constants.CHECK_ACTIVATED_URL);

  return res;
}

async function authenticate(authObj) {
  const res = await axiosInstance.post(constants.HANDLE_URL, authObj);

  return res;
}

async function inactivateAuth() {
  const res = await axiosInstance.delete(constants.HANDLE_URL);

  return res;
}

async function updateAuth(authObj) {
  const res = await axiosInstance.put(constants.HANDLE_URL, authObj);

  return res;
}

async function syncHomeQ(postData) {
  const res = await axiosInstance.post(constants.MASS_MATCHING_URL, postData);

  return res;
}

async function collectHomeQ(postData) {
  const res = await axiosInstance.post(constants.MASS_COLLECTION_URL, postData);

  return res;
}

async function getRejectionReasons() {
  const { data } = await axiosInstance.get(constants.REJECTION_REASONS_URL);

  return data;
}

async function getProjects() {
  const { data } = await axiosInstance.get(constants.PROJECTS_URL);

  return data;
}
export {
  authenticate,
  checkActivated,
  syncHomeQ,
  collectHomeQ,
  inactivateAuth,
  updateAuth,
  getRejectionReasons,
  getProjects,
};
