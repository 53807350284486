import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import Pdf from "../../components/Pdf/Pdf";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import PageSlider from "../Layouts/PageSlider/PageSlider";
import PageTitle from "../Layouts/PageTitle/PageTitle";

const TABS = {
  TERMS: "Villkor",
  GDPR: "GDPR",
};

export default () => {
  const [selectedTab, setSelectedTab] = React.useState("TERMS");
  const breadCrumbs = [
    {
      label: "Användarvillkor",
    },
  ];

  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle title="Användarvillkor" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Villkor</OverviewTitle>
          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        {selectedTab === "TERMS" && (
          <Pdf
            url={
              "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/public_media/Other/Terms_of_Service.pdf"
            }
          />
        )}

        {selectedTab === "GDPR" && (
          <Pdf
            url={
              "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/public_media/Other/Privacy_Policy.pdf"
            }
          />
        )}
      </DetailPageBox>
    </BareLayoutWrapper>
  );
};
