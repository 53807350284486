export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title || obj.errand_number;
};
export const detailUrl = ({ id }) => {
  return `/report-errands/detail/${id}`;
};
export const invoicingDetailUrl = ({ id }) => {
  return `${detailUrl({ id })}/invoicing`;
};
export const overviewUrl = () => {
  return `/report-errands`;
};

export const reportErrandComponentUrl = ({ id }) => {
  return `/errand-components/${id}/reporterrands`;
};

export const editUrl = ({ id, realEstateId }) => {
  return `/report-errands/edit/${id}?realEstateId=${realEstateId}`;
};

export const createUrl = () => {
  return `/report-errands/errands/create`;
};

export const allUrl = () => {
  return `/report-errands/all`;
};

export const getReportOverviewStatsProcessName = () => {
  return `REPORT_ERRAND_OVERVIEW_STATS`;
};
