import * as React from "react";

// style, design
import { DateCell, ToolTipCell, LinkedObject } from "../../Displays";

import StateFilter from "../Apartments/Filters/StateFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as realestateDetailUrl } from "../../../store/realEstates";
import { detailUrl as buildingDetailurl } from "../../../store/buildings";

export default () => [
  {
    Header: "Status",
    accessor: "state",
    Cell: ({ value, row }) => {
      return (
        <StatusLabel state={row.original?.state} contentType="premises">
          {row.original?.state_display}
          {row.original?.subletted ? " (andrahand)" : ""}
        </StatusLabel>
      );
    },
    Filter: StateFilter,
    filter: "textExact",
  },
  {
    Header: "Uppsägningsdatum",
    accessor: "closed_on",
    Cell: ({ value, row }) => <DateCell date={value} />,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Utflytt/Förnyelse datum",
    accessor: "termination_date",
    Cell: ({ value, row }) => <DateCell date={value} />,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Signerings datum",
    accessor: "signed_date",
    Cell: ({ value, row }) => <DateCell date={value} />,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Start datum",
    accessor: "start_date",
    Cell: ({ value, row }) => <DateCell date={value} />,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Utflyttande hyresgäst",
    accessor: "old_tenant.str_representation",
    Cell: ({ value, row }) => (
      <LinkedObject
        obj={row.original?.old_tenant}
        urlMethod={tenantDetailUrl}
      />
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Inflyttande hyresgäst",
    accessor: "new_tenant.str_representation",
    Cell: ({ value, row }) => (
      <LinkedObject
        obj={row.original?.new_tenant}
        urlMethod={tenantDetailUrl}
      />
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Area",
    accessor: "area",
    Cell: ({ value, row }) => <ToolTipCell text={value} />,
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Objektsnummer",
    accessor: "premises_id",
    Cell: ({ value, row }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Lägenhetsnummer",
    accessor: "apartment_id",
    Cell: ({ value, row }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Adress",
    accessor: "address.str_representation",
    Cell: ({ value, row }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Byggnad",
    accessor: "building.str_representation",
    Cell: ({ value, row }) => (
      <LinkedObject
        obj={row.original?.building}
        urlMethod={buildingDetailurl}
      />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fastighet",
    accessor: "realestate.str_representation",
    Cell: ({ value, row }) => (
      <LinkedObject
        obj={row.original?.realestate}
        urlMethod={realestateDetailUrl}
      />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
