import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Checkbox, NumberInput, RadioGroup } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketRequirements";
import { useFormInstanceField } from "../../../../../store/base";
import { InnerBox } from "../../../../sharedStyles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Kredit</FormAreaTitle>

      <NumberInput
        {...{ storeName, method }}
        fieldKey="payment_remarks"
        title="Max antal tillåtna betalningsanmärkningar"
        description="Max betalningsanmärkningar för alla sökande samlat (hushåll & borgensman)"
      />

      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="outstanding_debt"
          storeName={storeName}
          method={method}
          title="Tillåt sökande med skuldsaldo"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1, marginLeft: 8, marginRight: 8 }}
          fieldKey="debt_sanitization"
          storeName={storeName}
          method={method}
          title="Tillåt sökande med skuldsanering"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="bankruptcy"
          storeName={storeName}
          method={method}
          title="Tillåt sökande med personlig konkurs"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>
    </>
  );
};
