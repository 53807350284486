export const getAvailableReportsUrl = () => {
  return "/reporting/generators/available_reports/";
};

export const createNewReportUrl = (reportPath) => {
  return `/reporting/generators/run_report/${reportPath}/`;
};

export const reRunReportUrl = (id) => {
  return `/reporting/generators/rerun_report/${id}/`;
};

export const getCreatedReportsUrl = () => {
  return `/reporting/generators/performedreport/list/`;
};

export const updateCreatedReportTemplateUrl = (id) => {
  return `/reporting/generators/performedreport/${id}/`;
};

export const deleteReportUrl = (id) => {
  return `/reporting/generators/performedreport/`;
};

export const getLoadReportTemplateUrl = (id) => {
  return `/reporting/generators/performedreport/${id}/`;
};

export const getAllFetchProcessName = () => {
  return "all";
};
