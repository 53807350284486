import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingDebtSettings";
import { NumberInput, Select, TextField } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Dröjsmålsränta</FormAreaTitle>
      <Select
        title="Typ av dröjsmålsränta"
        {...{
          storeName,
          method,
          fieldKey: "interest_type",
        }}
      />

      <NumberInput
        title="Dröjsmålsränta i procent"
        description="Observera att ränta över det som anses vara rimligt kräver att anledning till högre ränta anges"
        {...{
          storeName,
          method,
          fieldKey: "interest_percentage",
        }}
      />

      <TextField
        title="Anledning till förhöjd dröjsmålsränta"
        description="Specificera anledning till en högre ränta än vad som anses rimligt"
        {...{
          storeName,
          method,
          fieldKey: "reason_higher_interest",
        }}
      />

      <NumberInput
        title="Antal dagar efter förfallodatum som ränta börjar räknas"
        {...{
          storeName,
          method,
          fieldKey: "interest_start_due_date",
        }}
      />
    </>
  );
};
