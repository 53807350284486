import { cloneDeep } from "lodash";
import { axiosInstance } from "../base/store/axios";
import { constants as leaseInvoicingConstants } from "../invoicingLease";
import { constants as leaseConstants } from "../leaseContracts";
import { constants as parkingContractConstants } from "../parkingContracts";
import { constants as parkingInvoicingConstants } from "../invoicingParking";
import { constants as otherInvoicingConstants } from "../invoicingOther";

export const constructStrRep = (obj) => {
  if (obj.str_representation) return obj.str_representation;
  if (obj.id_number) return obj.id_number;
  return "Hyresavtal";
};

export const createOptionsUrl = () => {
  return `/lease-contracts/create/type`;
};

export const createUrl = ({ kind, existing, digitalSign, query }) => {
  return `/lease-contracts/create/${
    existing ? "existing" : "new"
  }/${kind}?manual_sign=${!digitalSign}${query ? `&${query}` : ""}`;
};
export const createCostsUrl = ({ id, skipSigning, cloneFrom }) => {
  return `/lease-contracts/create/costs/${id}${
    skipSigning ? "?skip-sign=true" : ""
  }${
    skipSigning && cloneFrom
      ? `&clone-from=${cloneFrom}`
      : cloneFrom
      ? `?clone-from=${cloneFrom}`
      : ""
  }`;
};

export const detailUrl = ({ id }) => {
  return `/lease-contracts/detail/${id}`;
};
export const overviewUrl = () => {
  return `/lease-contracts`;
};
export const cancelUrl = ({ id, isManualSign }) => {
  return `/lease-contracts/cancel/${id}${
    isManualSign ? "?manual_sign=true" : ""
  }`;
};

export const handleManyInvoicingUrl = () => {
  return "/lease-contracts/handle-invoicing-statuses";
};

export const isCancelSigning = (contract) => {
  if (!contract) {
    return false;
  }
  const possibleInitial = contract?.is_signed === false;
  const possibleClosing =
    [6, 7].includes(contract?.state) &&
    !contract?.closed_signed &&
    !possibleInitial;

  return possibleClosing;
};

const CLONE_COPY_KEYS = [
  "landlord",
  "notify_interval",
  "notify_time",
  "realestate",
  "renew_indefinetely",
  "renew_interval",
  "warning_interval",
  "warning_time",
  "apartments",
  "industrial_premises_list",
  "id_number",
];

const PARKING_CONTRACT_CLONE_KEYS = [
  "notify_interval",
  "notify_time",
  "realestate",
  "renew_indefinetely",
  "renew_interval",
  "warning_interval",
  "warning_time",
  "parking_spots",
  "id_number",
];

const OTHER_CONTRACT_CLONE_KEYS = [
  "notify_interval",
  "notify_time",
  "realestate",
  "renew_indefinetely",
  "renew_interval",
  "warning_interval",
  "warning_time",
  "id_number",
  "category",
];

const CLONE_INVOICING_KEYS = [
  "admin_fee",
  "auto_attest",
  "auto_merge",
  "billing_company",
  "cost_set",
  "delivery_method",
  "external_reference",
  "imd_setting",
  "include_auto_message",
  "interval_setting",
  "setting",
  "vat",
  "message",
];

// fetch the setting and clean ids and fields to set to forminstance
export const cleanLeaseInvoicingClone = async (leaseContractId) => {
  try {
    if (!leaseContractId) return {};
    const { data: leaseContract } = await axiosInstance.get(
      `${leaseConstants.GET_URL}${leaseContractId}/`
    );

    if (!leaseContract) return {};
    const leaseClone = cloneDeep(leaseContract);

    const invoicingSettingId = leaseClone?.lease_invoicing?.id;

    if (!invoicingSettingId) return {};

    const { data: setting } = await axiosInstance.get(
      `${leaseInvoicingConstants.GET_URL}${invoicingSettingId}/`
    );

    const source = {};

    Object.keys(setting).forEach((key) => {
      if (CLONE_INVOICING_KEYS.includes(key)) {
        source[key] = setting[key];
      }
    });

    if (leaseClone?.cost_set?.length) {
      source.cost_set = leaseClone?.cost_set?.map((c) => ({
        ...c,
        id: undefined,
        lease_invoicing: undefined,
      }));
    } else {
      source.cost_set = [];
    }

    return source;
  } catch (e) {
    return {};
  }
};

// fetch the setting and clean ids and fields to set to forminstance
export const cleanParkingInvoicingClone = async (parkingContractId) => {
  try {
    if (!parkingContractId) return {};

    const { data: parkingContract } = await axiosInstance.get(
      `${parkingContractConstants.GET_URL}${parkingContractId}/`
    );

    if (!parkingContract) return {};
    const parkingClone = cloneDeep(parkingContract);

    const invoicingSettingId = parkingContract?.parking_invoicing?.id;

    if (!invoicingSettingId) return {};

    const { data: setting } = await axiosInstance.get(
      `${parkingInvoicingConstants.GET_URL}${invoicingSettingId}/`
    );

    const source = {};

    Object.keys(setting).forEach((key) => {
      if (CLONE_INVOICING_KEYS.includes(key)) {
        source[key] = setting[key];
      }
    });

    if (parkingClone?.cost_set?.length) {
      source.cost_set = parkingClone?.cost_set?.map((c) => ({
        ...c,
        id: undefined,
        parking_invoicing: undefined,
      }));
    } else {
      source.cost_set = [];
    }

    return source;
  } catch (e) {
    return {};
  }
};

export const cleanOtherInvoicingClone = async (otherContract) => {
  const otherClone = cloneDeep(otherContract);

  const invoicingSettingId = otherClone?.other_invoicing?.id;

  if (!invoicingSettingId) return {};

  const { data: setting } = await axiosInstance.get(
    `${otherInvoicingConstants.GET_URL}${invoicingSettingId}/`
  );

  const source = {};

  Object.keys(setting).forEach((key) => {
    if (CLONE_INVOICING_KEYS.includes(key)) {
      source[key] = setting[key];
    }
  });

  if (otherClone?.cost_set?.length) {
    source.cost_set = otherClone?.cost_set?.map((c) => ({
      ...c,
      id: undefined,
      other_invoicing: undefined,
    }));
  } else {
    source.cost_set = [];
  }

  return source;
};

export const cleanOtherContractClone = async (otherContract) => {
  const contractClone = cloneDeep(otherContract);

  const source = {};

  Object.keys(contractClone).forEach((key) => {
    if (OTHER_CONTRACT_CLONE_KEYS.includes(key)) {
      source[key] = contractClone[key];
    }
  });

  return source;
};
