import * as React from "react";
import { useTransition } from "@react-spring/web";
import { LinkButton, PrimaryButton } from "../Forms/Base/Buttons";

import * as SC from "./styles";

export default function ConfirmationModal({
  isOpen,
  title,
  renderContent,
  closeFunction,
  acceptCallback,
  canAccept = true,
  acceptTitle = "Fortsätt",
  cancelTitle = "Avbryt",
  showCancel = true,

  alternateStyles,

  // special styling rules if modal in modal
  modalInModal,
}) {
  const backdropRef = React.useRef();
  const modalTransition = useTransition(!!isOpen, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateY(100%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  const handleAcceptClicked = () => {
    acceptCallback();
    closeFunction();
  };

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.ConfirmationBackdrop
          ref={backdropRef}
          onClick={closeFunction}
          style={{ opacity: styles.opacity, ...alternateStyles }}
          {...{ modalInModal }}
        >
          <SC.ConfirmationModal
            onClick={(e) => e.stopPropagation()}
            style={{ ...styles, opacity: 1 }}
            {...{ modalInModal }}
          >
            <SC.ConfirmationTitle>{title}</SC.ConfirmationTitle>
            {renderContent && (
              <div style={{ padding: "12px" }}>{renderContent()}</div>
            )}

            <SC.ModalActionBar>
              {showCancel && (
                <LinkButton title={cancelTitle} clicked={closeFunction} />
              )}
              <PrimaryButton
                disabled={!canAccept}
                title={acceptTitle}
                clicked={handleAcceptClicked}
              />
            </SC.ModalActionBar>
          </SC.ConfirmationModal>
        </SC.ConfirmationBackdrop>
      )
  );
}
