import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default function TextAreaField({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  title,
  description,
  forceError,
  forceRequired,
  forceInstructions,
  placeholder,
  changeCallback,
}) {
  const dispatch = useDispatch();

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || forceInstructions;

  const required = forceRequired || instructions?._required;

  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey }) || forceError;

  const onChange = (data) => {
    dispatch(
      updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
    );
    if (changeCallback) {
      changeCallback(data);
    }
  };

  if (!instructions || instructions?._readOnly) return null;

  return (
    <div>
      <label
        htmlFor={fieldKey}
        className="block text-sm flex items-center font-medium text-gray-900 "
      >
        {(error || forceError) && (
          <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
        )}
        {title}
        {required ? "*" : ""}
      </label>
      {description && (
        <p
          id={`${fieldKey}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {(error || forceError) && (
        <div className="text-xs mb-1 font-normal text-red-600 ">
          {forceError || error}
        </div>
      )}
      <textarea
        id={fieldKey}
        rows="4"
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        className="block bg-white p-2.5 w-full text-sm text-gray-900 rounded border border-slate-300 focus:ring-blue-500 focus:border-blue-500   "
        placeholder={placeholder || title}
      ></textarea>
    </div>
  );
}
