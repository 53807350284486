import AdDetails from "./AdDetails";
import Confirm from "./Confirm";
import Description from "./Description";
import Publish from "./Publish";

// Map of chapterdef KEY: Component
export default {
  AD_DETAILS: AdDetails,
  PUBLISH: Publish,
  DESCRIPTION: Description,
  CONFIRM: Confirm,
};
