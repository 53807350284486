import * as React from "react";
import { useFormInstanceField } from "../../store/base";
import DigitalDocDraggableField from "./DraggableField";

export default function DigitalDocFieldSet({
  storeName,
  disabled,
  pageBounds,
}) {
  const partyBaseKey = "parties";
  const parties =
    useFormInstanceField({ storeName, fieldKey: partyBaseKey }) || [];

  const buildFields = () => {
    const fields = [];

    parties.forEach((party, partyIdx) => {
      if (!party.fields) return;

      party.fields.forEach((field, fieldIdx) => {
        const kind = field.kind;

        if (!field.placements?.length) return;

        field.placements.forEach((placement, placementIdx) => {
          const partyKey = `${partyBaseKey}[${partyIdx}]`;
          const fieldKey = `${partyKey}.fields[${fieldIdx}]`;
          const placementKey = `${fieldKey}.placements[${placementIdx}]`;

          fields.push(
            <DigitalDocDraggableField
              key={placementKey}
              disabled={disabled}
              storeName={storeName}
              partyKey={partyKey}
              fieldKey={fieldKey}
              kind={kind}
              placementKey={placementKey}
              placement={placement}
              pageBounds={pageBounds}
            />
          );
        });
      });
    });

    return fields;
  };
  const Fields = buildFields();

  if (!Fields?.length) return null;

  return Fields.map((F) => F);
}
