import * as React from "react";

import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => [
    {
        Header: "Bild",
        accessor: "image.get",

        Cell: ({ value }) => (
            <div
            style={{
                height: "60px",
                width: "60px",
                backgroundImage: `url(${value})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
            }}
            ></div>
        )
    },
    {
        Header: "Namn",
        accessor: "title"
    }
]