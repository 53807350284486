import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./AvyTmplListDefs";

// store, state
import { constants } from "../../../store/realEstates";

export default function AvyTmplRealEstateTable({
  persistantQuery,
  isBare,
  tableId,
  onRowClicked,
  checkRowHighlighted,
}) {
  const columns = React.useMemo(() => {
    const data = columnDefs();
    return data;
  }, [persistantQuery]);

  const filters = [];

  return (
    <>
      <BaseTable
        tableId={tableId || "realestate_full_table"}
        title={"Fastigheter"}
        {...{
          isBare,
          ignoreLocalStorage: true,
          onRowClicked,
          columns,
          persistantQuery,
          filters,
          constants,
          checkRowHighlighted,
        }}
      />
    </>
  );
}
