import { PlusIcon } from "@heroicons/react/24/outline";
import { cloneDeep } from "lodash";
import moment from "moment";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../components/Forms/Base/Buttons";
import PrimaryBtn from "../../components/Forms/Base/Buttons/PrimaryBtn";
import IndexTableModalForm from "../../components/Forms/IndexTable/ModalForm/ModalForm";
import IndexTableRowModalForm from "../../components/Forms/IndexTableRow/ModalForm/ModalForm";
import PremisesTable from "../../components/PremisesTable/PremisesTable";
import { DetailInnerWrapper } from "../../components/sharedStyles";
import { buildQueryString } from "../../store/base";
import { useIndexTable } from "../../store/indexTable";
import { useFilteredIndexTableRows } from "../../store/indexTableRow";

export default function IndexationTableDetail() {
  const { id } = useParams();
  const { goBack } = useHistory();
  const [table, tableLoading] = useIndexTable(id);
  const [editOpen, setEditOpen] = React.useState(false);
  const [addRowOpen, setAddRowOpen] = React.useState(false);
  const [editRowOpen, setEditRowOpen] = React.useState(false);

  const [rows, rowsLoading] = useFilteredIndexTableRows(
    buildQueryString({ table: id, order_by: "-for_date" })
  );

  const tableRows = React.useMemo(() => {
    return rows.map((r) => [moment(r.for_date).format("YYYY-MM"), r.value]);
  }, [rows]);

  return (
    <>
      {editOpen && (
        <IndexTableModalForm
          method="PATCH"
          id={id}
          instance={table}
          closeFunction={() => setEditOpen(false)}
        />
      )}

      {addRowOpen && (
        <IndexTableRowModalForm
          method="POST"
          instance={{ table: { id } }}
          closeFunction={() => setAddRowOpen(false)}
        />
      )}

      {editRowOpen && (
        <IndexTableRowModalForm
          method="PATCH"
          id={editRowOpen?.id}
          instance={editRowOpen}
          closeFunction={() => setEditRowOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <TextButton
          title="Tillbaka"
          iconType="arrow-back"
          clicked={() => goBack()}
          extraStyle={{ marginBottom: 24 }}
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>{table?.title || "Laddar..."}</OverviewTitle>
            <OverviewSubtitle style={{ marginRight: 24 }}>
              {table?.assume_previous_value
                ? "Använder senast registrerade värde vid tom period"
                : "Använder EJ senast registrerade värde vid tom period"}
            </OverviewSubtitle>
            <OverviewSubtitle style={{ marginRight: 24 }}>
              Lägg till en ny rad genom att klicka på "Lägg till rad" ovanför
              tabellen
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <PrimaryBtn onClick={() => setEditOpen(true)}>Redigera</PrimaryBtn>
        </OverviewTitleWrapper>

        <OverviewTitleWrapper style={{ marginBottom: 6, marginTop: 12 }}>
          <OverviewTitle small>Indexvärden</OverviewTitle>
          <PrimaryBtn secondary onClick={() => setAddRowOpen(true)}>
            <PlusIcon width={16} className="mr-1" />
            Lägg till rad
          </PrimaryBtn>
        </OverviewTitleWrapper>
        <PremisesTable
          onRowClicked={(row) => setEditRowOpen(row)}
          headers={["Månad", "Värde"]}
          rows={tableRows}
          onClickRowMapping={rows}
        />
      </DetailInnerWrapper>
    </>
  );
}
