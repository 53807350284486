import * as React from "react";

// style, design
import { DoubleFieldWrapper } from "../../../Base/Chapters/styles";
import { constants } from "../../../../../store/marketIndustrialPremisesCommodities";
import { NumberInput, RadioGroup } from "../../../Base/Fields";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Parkering</OverviewTitle>
          <OverviewSubtitle>
            Ange vilka möjligheter till parkering som finns
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />
      <DoubleFieldWrapper>
        <NumberInput
          extraStyles={{ minWidth: 200, marginRight: 12 }}
          fieldKey="available_parking_spots"
          storeName={storeName}
          method={method}
          title="Antal tillgängliga parkeringsplatser"
        />

        <NumberInput
          extraStyles={{ minWidth: 200 }}
          fieldKey="included_parking_spots"
          storeName={storeName}
          method={method}
          title="Antal inkluderade parkeringsplatser"
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <NumberInput
          extraStyles={{ minWidth: 200, marginRight: 12 }}
          fieldKey="available_garage_spots"
          storeName={storeName}
          method={method}
          title="Antal tillgängliga garageplatser"
        />

        <NumberInput
          extraStyles={{ minWidth: 200 }}
          fieldKey="included_garage_spots"
          storeName={storeName}
          method={method}
          title="Antal inkluderade garageplatser"
        />
      </DoubleFieldWrapper>
    </>
  );
};
