import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { useInspectionErrand } from "../../../store/inspectionErrands";
import { buildQueryString } from "../../../store/base";
import { useFilteredErrandComponents } from "../../../store/errandComponents";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useApartment } from "../../../store/apartments";
import { useIndustrialPremises } from "../../../store/industrialPremises";
import { useCommonArea } from "../../../store/commonAreas";
import {
  detailUrl as tenantDetailUrl,
  useFilteredTenants,
} from "../../../store/tenants";
import { detailUrl as userDetailUrl, useUser } from "../../../store/users";
import { useBrfPremises } from "../../../store/brfPremises";
import { PREMISES_TYPES } from "./Overview";
import RoomOverview from "../../../components/TechnicalManagement/Component/RoomOverview";
import * as SC from "./styles";
import { getComponentsByRooms } from "../../../components/TechnicalManagement/utils";
import MediaDisplay from "../../../components/Details/Images/MediaDisplay";
import { LinkedObject } from "../../../components/Displays";

export default function InspectionErrandReport() {
  const { inspectionErrandId } = useParams();
  const [inspectionErrand] = useInspectionErrand(inspectionErrandId);
  const [apartment] = useApartment(inspectionErrand?.apartment?.id);
  const [indp] = useIndustrialPremises(
    inspectionErrand?.industrial_premises?.id
  );
  const [commonArea] = useCommonArea(inspectionErrand?.common_area?.id);
  const [brfPremises] = useBrfPremises(inspectionErrand?.brf_premis?.id);

  const [performerSignatureUser] = useUser(
    inspectionErrand?.performer_signature_user?.id
  );
  const tenantFilter = buildQueryString({
    user: inspectionErrand?.tenant_signature_user?.id || -1,
  });
  const [tenants] = useFilteredTenants(tenantFilter);
  const tenant = tenants?.[0];

  const ratingComponentIds = inspectionErrand?.active_ratings
    ?.map((ar) => ar.component_placement?.id)
    ?.filter((c) => c);

  const kind = apartment
    ? PREMISES_TYPES.APARTMENT
    : indp
    ? PREMISES_TYPES.INDP
    : commonArea
    ? PREMISES_TYPES.COMMON_AREA
    : brfPremises
    ? PREMISES_TYPES.BRF_PREMIS
    : PREMISES_TYPES.PARKING_SPOT;

  const componentQ = buildQueryString({
    id__in: ratingComponentIds || [],
  });
  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components });
  }, [components]);

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Resultat av besiktning</OverviewTitle>
              <OverviewSubtitle>
                Tryck på en komponent för att se kommentar och bilder samt skapa
                felanmälan
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <div style={{ display: "flex", marginBottom: 12 }}>
            <InnerBox style={{ marginBottom: 12, flex: 2 }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Kommentar från utförare</OverviewTitle>
                  <OverviewSubtitle>
                    {inspectionErrand?.performer?.str_representation || ""}
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              <InnerBox>
                {inspectionErrand?.completion_comment ||
                  "Ingen kommentar lämnades"}
              </InnerBox>

              <InnerBox style={{ marginTop: 12 }}>
                <MediaDisplay files={inspectionErrand?.completion_files} />
              </InnerBox>
            </InnerBox>

            <div style={{ flex: 1, marginLeft: 8 }}>
              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Utförares signatur</OverviewTitle>
                    <OverviewSubtitle>
                      Signerat av:{" "}
                      <LinkedObject
                        obj={performerSignatureUser}
                        urlMethod={userDetailUrl}
                      />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <InnerBox>
                  {inspectionErrand?.performer_signature?.get ? (
                    <SC.SignBox
                      url={inspectionErrand?.performer_signature?.get}
                    />
                  ) : (
                    "Ej signerat av utförare"
                  )}
                </InnerBox>
              </InnerBox>

              <InnerBox style={{ marginTop: 8 }}>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Hyresgästs signatur</OverviewTitle>
                    <OverviewSubtitle>
                      Signerat av:{" "}
                      <LinkedObject obj={tenant} urlMethod={tenantDetailUrl} />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <InnerBox>
                  {inspectionErrand?.tenant_signature?.get ? (
                    <SC.SignBox url={inspectionErrand?.tenant_signature?.get} />
                  ) : (
                    "Ej signerat av hyresgäst"
                  )}
                </InnerBox>
              </InnerBox>
            </div>
          </div>

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Komponentbetyg</OverviewTitle>
                <OverviewSubtitle>
                  {inspectionErrand?.active_ratings?.length} komponenter
                  besiktades
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            <RoomOverview
              rooms={componentsByRoom}
              selectOnly
              showRatings
              ratings={inspectionErrand?.active_ratings}
              isNonRoomType={kind === PREMISES_TYPES.PARKING_SPOT}
              contentKey={
                kind === PREMISES_TYPES.PARKING_SPOT ? "parking_spot" : null
              }
              contentId={inspectionErrand?.parking_spot?.id} // only parking spot is non room type for insepction errand
              showArchived
            />
          </InnerBox>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
