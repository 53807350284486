import * as React from "react";
import moment from "moment";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { useInvoice } from "../../../store/billectaInvoicing/hooks/retrieve";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { ItemTitle } from "../../../components/Details/OverviewInfo/DetailInfo/styles";

export default function InvoiceEvents() {
  const { invoiceId, creditorId } = useParams();
  const [invoice, invoiceLoading] = useInvoice({ creditorId, invoiceId });

  const renderEvents = () => {
    return invoice?.Events?.map((e, idx) => (
      <InnerBox key={idx}>
        <ItemTitle>
          {!e.HasOccured
            ? `Kommande (${moment(e.EventDate).format("YYYY-MM-DD HH:mm")})`
            : moment(e.EventDate).format("YYYY-MM-DD HH:mm")}{" "}
        </ItemTitle>
        <ItemTitle>
          {e.Title}
          {e.Content ? ` - ${e.Content}` : ""}
        </ItemTitle>
      </InnerBox>
    ));
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        {invoiceLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitle>Händelser</OverviewTitle>
        </OverviewTitleWrapper>

        {renderEvents()}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
