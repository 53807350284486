import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  SecondaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import BrfPremisesTable from "../../../components/Tables/BrfPremises/SelectTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { massUpdateInvoicingStatus } from "../../../store/invoicingBrf";
import { MASS_INVOICING_UPDATE_ACTIONS } from "../../../store/invoicingLease/store/actions";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { overviewUrl } from "../../../store/brfPremises";
import { useHistory } from "react-router";

const UPDATE_TYPES = {
  ACTIVATE: 0,
  INACTIVATE: 1,
};

export default function HandleMassInvoincingBrfPremises() {
  const { filteredRealEstates } = useActiveFilters();
  const dispatch = useDispatch();
  const [updateType, setUpdateType] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [confirmationFunction, setConfirmationFunction] = React.useState(null);
  const [realEstates] = useFilteredRealEstates("");
  const { push } = useHistory();
  const realEstateIds = filteredRealEstates || realEstates?.map((r) => r.id);

  const activePersistantQueryString = buildQueryString({
    brf_invoicing_active: true,
    realestate_ids: filteredRealEstates,
  });

  const inactivePersistantQueryString = buildQueryString({
    brf_invoicing_active: false,
    realestate_ids: filteredRealEstates,
  });

  const activeFilter = (obj) => {
    if (!filteredRealEstates?.length) return obj.brf_invoicing_active;

    return (
      obj.brf_invoicing_active &&
      filteredRealEstates.includes(obj.realestate?.id)
    );
  };
  const inactiveFilter = (obj) => {
    if (!filteredRealEstates?.length) return obj.brf_invoicing_active === false;

    return (
      obj.brf_invoicing_active === false &&
      filteredRealEstates.includes(obj.realestate?.id)
    );
  };

  const activateInvoicings = (selected) => {
    setLoading(true);

    const ids = selected
      .map((s) => s.original.brf_invoicing?.id)
      ?.filter((id) => !!id);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.ACTIVATE,
        ids,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för bostadsrätterna aktiverades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda bostadsrätterna`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  const handleActivateAllFilteredRealEstates = () => {
    setLoading(true);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.ACTIVATE,
        realEstateIds,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för bostadsrätterna aktiverades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda bostadsrätterna`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  const inActivateInvoicings = (selected) => {
    setLoading(true);

    const ids = selected
      .map((s) => s.original.brf_invoicing?.id)
      ?.filter((id) => !!id);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.PAUSE,
        ids,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för bostadsrätterna pausades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda bostadsrätterna`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  const handlePauseAllFilteredRealEstates = () => {
    setLoading(true);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.PAUSE,
        realEstateIds,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för bostadsrätterna aktiverades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda bostadsrätterna`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  return (
    <DetailInnerWrapper>
      <ConfirmationModal
        isOpen={!!confirmationFunction}
        closeFunction={() => setConfirmationFunction(null)}
        title={confirmationTitle}
        acceptCallback={confirmationFunction}
      />

      {updateType === null && (
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>
              Masshantering av aviseringsstatus för bostadsrätter
            </OverviewTitle>
          </OverviewTitleWrapper>

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Vilken typ av uppdatering ska genomföras?
              </OverviewTitle>
            </OverviewTitleWrapper>

            <BodyText style={{ maxWidth: 800 }}>
              Välj vilken typ av uppdatering som ska utföras. Vid uppdatering av
              status för flera bostadsrätter samtidigt så kommer eventuella fel
              hamna under "Felmeddelanden" under sidan "Hyresavisering" samt
              inne på det specifika bostadsrättens aviseringsflik.{" "}
            </BodyText>

            <TextButton
              extraStyle={{ marginTop: 24 }}
              title="Aktivera avisering för bostadsrätter med pausad avisering"
              iconType="done"
              iconPlacement="right"
              clicked={() => setUpdateType(UPDATE_TYPES.ACTIVATE)}
            />

            <TextButton
              extraStyle={{ marginTop: 24 }}
              title="Pausa avisering för bostadsrätter med aktiv avisering"
              iconType="pause"
              iconPlacement="right"
              clicked={() => setUpdateType(UPDATE_TYPES.PAUSE)}
            />
          </InnerBox>
        </DetailPageBox>
      )}

      {updateType === UPDATE_TYPES.ACTIVATE && (
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            title="Gå tillbaka till val av uppdatering"
            iconType="arrow-back"
            clicked={() => setUpdateType(null)}
            extraStyle={{ marginBottom: 24 }}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper style={{ marginRight: 48 }}>
              <OverviewTitle small>
                Aktivera avisering för bostadsrätter med pausad avisering
              </OverviewTitle>
              <OverviewSubtitle>
                Välj de bostadsrätterna du vill aktivera den pausade aviseringen
                för. Tryck på <strong>"Aktivera avisering"</strong> under listan
                för att spara. Tryck på <strong>"Aktivera alla"</strong> för att
                aktivera avisering för alla bostadsrätter med pausad avisering.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <div style={{ display: "flex", alignItems: "center" }}>
              <SecondaryButton
                title="Aktivera alla"
                clicked={() => {
                  setConfirmationTitle(
                    "Aktivera avisering för alla bostadsrätter under de filtrerade fastigheterna? Om ingen filtrering är aktiv kommer aktiveringen ske för alla fastigheter i systemet."
                  );

                  setConfirmationFunction(
                    () => handleActivateAllFilteredRealEstates
                  );
                }}
              />
            </div>
          </OverviewTitleWrapper>

          <BrfPremisesTable
            persistantFilterMethod={inactiveFilter}
            persistantQueryString={inactivePersistantQueryString}
            hideColumns
            hideTitle
            hideExport
            onSubmit={activateInvoicings}
            submitLabel={"Aktivera pausad avisering"}
            withSelectableRows
          />
        </DetailPageBox>
      )}

      {updateType === UPDATE_TYPES.PAUSE && (
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            title="Gå tillbaka till val av uppdatering"
            iconType="arrow-back"
            clicked={() => setUpdateType(null)}
            extraStyle={{ marginBottom: 24 }}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper style={{ marginRight: 48 }}>
              <OverviewTitle small>
                Pausa avisering för bostadsrätter med aktiv avisering
              </OverviewTitle>
              <OverviewSubtitle>
                Välj de bostadsrätter du vill pausa den aktiva aviseringen för.
                Tryck på <strong>"Pausa avisering"</strong> under listan för att
                spara. Tryck på <strong>"Pausa alla"</strong> för att pausa
                avisering för alla bostadsrätter med aktiv avisering.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <div style={{ display: "flex", alignItems: "center" }}>
              <SecondaryButton
                title="Pausa alla"
                clicked={() => {
                  setConfirmationTitle(
                    "Pausa avisering för alla bostadsrätter under de filtrerade fastigheterna? Om ingen filtrering är aktiv kommer aktiveringen ske för alla fastigheter i systemet."
                  );
                  setConfirmationFunction(
                    () => handlePauseAllFilteredRealEstates
                  );
                }}
              />
            </div>
          </OverviewTitleWrapper>

          <BrfPremisesTable
            persistantFilterMethod={activeFilter}
            persistantQueryString={activePersistantQueryString}
            hideColumns
            hideTitle
            hideExport
            onSubmit={inActivateInvoicings}
            submitLabel={"Pausa aktiv avisering"}
            withSelectableRows
          />
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
