import * as React from "react";

// style, design
import { Select, PositiveNumber, TextInput } from "../Base/Fields";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  return (
    <>
      <TextInput
        title="Namn på specifikation"
        storeName={storeName}
        fieldKey={`${parentPath}.title`}
        method={method}
      />
      <Select
        title="Typ av områden inom specifikationen"
        storeName={storeName}
        fieldKey={`${parentPath}.category`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.category`}
      />
      <Select
        title="Fordonstyper "
        description="Ange vilka typer av fordon som kan parkera på denna typ av specifikation"
        storeName={storeName}
        fieldKey={`${parentPath}.vehicle_support`}
        method={method}
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.vehicle_support`}
      />
      <PositiveNumber
        title="Max höjd på fordon (CM)"
        storeName={storeName}
        fieldKey={`${parentPath}.height_limit`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.height_limit`}
      />
      <PositiveNumber
        title="Viktgräns för fordon (KG)"
        storeName={storeName}
        fieldKey={`${parentPath}.weight_limit`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.weight_limit`}
      />
      <PositiveNumber
        title="Max bredd för fordon (CM)"
        storeName={storeName}
        fieldKey={`${parentPath}.width_limit`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.width_limit`}
      />
      <PositiveNumber
        title="Max längd för fordon (CM)"
        storeName={storeName}
        fieldKey={`${parentPath}.length_limit`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.length_limit`}
      />
    </>
  );
};
