import * as React from "react";

// style, design
import { DoubleFieldWrapper } from "../../../Base/Chapters/styles";
import { DatePicker, RadioGroup, Select } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketIndustrialPremisesCommodities";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Bekvämligheter</OverviewTitle>
          <OverviewSubtitle>
            Ange vilka bekvämligheter som ska synas på annonsen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      <DatePicker
        fieldKey="renovation_date"
        title="Renoverades senast"
        storeName={storeName}
        method={method}
      />
      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="elevator"
          storeName={storeName}
          method={method}
          title="Har hiss"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="pantry"
          storeName={storeName}
          method={method}
          title="Har skafferi"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="patio"
          storeName={storeName}
          method={method}
          title="Har uteplats"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>
      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="handicap_adapted"
          storeName={storeName}
          method={method}
          title="Handikappanpassad"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="accessibility_adapted"
          storeName={storeName}
          method={method}
          title="Tillgänglighetsanpassad"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="security_door"
          storeName={storeName}
          method={method}
          title="Säkerhetsdörr"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>
      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="laundry_room"
          storeName={storeName}
          method={method}
          title="Tvättstuga"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="shower"
          storeName={storeName}
          method={method}
          title="Dusch"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="bed_in_wall"
          storeName={storeName}
          method={method}
          title="Utfällbar säng"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="stove"
          storeName={storeName}
          method={method}
          title="Spis"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="gas_cooker"
          storeName={storeName}
          method={method}
          title="Gasspis"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="tiled_stove"
          storeName={storeName}
          method={method}
          title="Öppen spis"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>

      <Select
        {...{ fieldKey: "kitchen", title: "Typ av kök", storeName, method }}
      />

      <Select
        fieldKey="kitchen_fan"
        title="Köksfläkt"
        {...{ storeName, method }}
      />
      <Select
        fieldKey="dishwasher"
        title="Diskmaskin"
        {...{ storeName, method }}
      />
      <Select fieldKey="balcony" title="Balkong" {...{ storeName, method }} />
      <Select
        fieldKey="washing_machine"
        title="Tvättmaskin"
        {...{ storeName, method }}
      />
      <Select fieldKey="dryer" title="Torktumlare" {...{ storeName, method }} />
      <Select fieldKey="bathtub" title="Badkar" {...{ storeName, method }} />
    </>
  );
};
