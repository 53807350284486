import styled from "styled-components";
import backIcon from "../../assets/svg/back.svg";

export const FormHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;

export const BackButton = styled.div`
  color: ${(p) => p.theme.colors.textPrimary};
  padding-left: 12px;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 18px;
    width: 20px;
    top: calc(50% - 10px);
    left: -10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${backIcon});
  }
`;

export const Spacer = styled.div`
  min-width: 100px;
`;

export const PageTitle = styled.h1`
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const InfoTraderTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const InfoTraderSubtitle = styled.div`
  color: ${(p) => p.theme.colors.textSecondaryDark};
  max-width: 60ch;
`;

export const InputsWrapper = styled.div`
  margin: 24px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FieldDescription = styled.div`
  margin: -4px 0 10px 0;
  font-size: 14px;
  color: ${(p) => p.theme.colors.textSecondaryDark};
`;

export const Content = styled.div`
  padding: 20px;
`;
