import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";

import SensorTable from "../../../components/Tables/Sensors/FullTable";
import MeasureTypeTable from "../../../components/Tables/MeasureType/FullTable";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import { useTodoImdStatistics } from "../../../store/overview";

const READABLE_TYPES = {
  missing_consumptions: "Saknar mätvärden",
  missing_prices: "Saknar pris",
  unconnected_sensors: "Okopplade sensorer",
};

export default function ImdTodo() {
  const [openTable, setOpenTable] = React.useState(null);
  const [rawTodoImdStats, imdLoading] = useTodoImdStatistics();

  const filteredStatKeys = Object.keys(rawTodoImdStats?.data || {}).filter(
    (key) => rawTodoImdStats?.data?.[key]?.length > 0
  );

  return (
    <DetailInnerWrapper>
      {filteredStatKeys?.length > 0 ? (
        <>
          {filteredStatKeys.map((key) => {
            const data = rawTodoImdStats?.data[key];
            const open = openTable === `${key}`;
            const ids = data?.map((d) => d.id);
            const persistantQuery = {
              id__in: ids,
            };

            const title =
              key.includes("sensor") || key.includes("consumptions")
                ? "Sensorer"
                : "Mätartyper";
            const TableComp =
              key.includes("sensor") || key.includes("consumptions")
                ? SensorTable
                : MeasureTypeTable;

            return (
              <DetailPageBox key={key}>
                <OverviewTitleWrapper>
                  <OverviewTitle>
                    {READABLE_TYPES[key]} ({data?.length})
                  </OverviewTitle>
                </OverviewTitleWrapper>
                <InnerBox style={{ marginBottom: 12 }}>
                  <OverviewTitleWrapper style={{ marginBottom: open ? 24 : 0 }}>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        {title} ({data?.length})
                      </OverviewTitle>
                      <OverviewSubtitle>
                        {!open
                          ? `Tryck på "Visa detaljer" för att se en detaljerad lista`
                          : `Klicka på en rad för att komma till detaljsidan`}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>

                    <PrimaryButton
                      title={!open ? "Visa detaljer" : "Stäng"}
                      clicked={
                        !open
                          ? () => setOpenTable(`${key}`)
                          : () => setOpenTable(null)
                      }
                    />
                  </OverviewTitleWrapper>

                  {open && (
                    <TableComp
                      {...{
                        persistantQuery,
                        isBare: true,
                        ignoreLocalStorage: true,
                      }}
                    />
                  )}
                </InnerBox>
              </DetailPageBox>
            );
          })}
        </>
      ) : (
        <DetailPageBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OverviewTitle style={{ padding: "48px 0 " }}>
            {imdLoading ? "Laddar..." : "Inget att hantera"}
          </OverviewTitle>
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
