import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Granska och slutför</OverviewTitle>
          <OverviewSubtitle>
            Kontrollera att uppgifterna stämmer. Tryck på "
            {method === "POST" ? "Lägg till" : "Uppdatera"} besiktningstyp" för
            att slutföra.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
    </>
  );
};
