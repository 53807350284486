import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "../../../../store/base";
import { useFilteredCalendarEvents } from "../../../../store/calendarEvents";
import { TextButton } from "../../../Forms/Base/Buttons";
import TodoItemForm from "../../../Forms/TodoItem/ChapterForm/ModalForm";
import { DetailPageBox } from "../../../sharedStyles";
import { OverviewTitle, OverviewTitleWrapper } from "../../OverviewInfo/styles";
import * as SC from "./styles";
import TodoItem from "./TodoItem";

export default function TodoList() {
  const { push } = useHistory();
  const [addTodoOpen, setAddTodoOpen] = React.useState(false);

  const eventQueryString = buildQueryString({
    calendar__isnull: true,
    done: false,
    mine: true,
  });

  const [events, eventsLoading] = useFilteredCalendarEvents(eventQueryString);

  return (
    <>
      <TodoItemForm
        method={"POST"}
        isOpen={addTodoOpen}
        onCheckout={() => {
          setAddTodoOpen(false);
        }}
      />

      <DetailPageBox style={{ borderRadius: 8, marginTop: 0 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Att göra</OverviewTitle>
          <TextButton
            title="Lägg till"
            iconType="add"
            iconPlacement="right"
            extraStyle={{ marginLeft: "auto", marginRight: 0 }}
            clicked={() => setAddTodoOpen(true)}
          />
        </OverviewTitleWrapper>

        <SC.TodoItemContainer>
          {events?.length === 0 && !eventsLoading && (
            <>
              <div
                style={{
                  padding: 12,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                Listan är tom. Tryck på "Lägg till" för att skapa en ny uppgift.
              </div>
            </>
          )}
          {events?.map((event) => (
            <TodoItem key={event.id} {...{ event }} />
          ))}
        </SC.TodoItemContainer>

        <TextButton
          extraStyle={{ margin: "24px auto 0 auto" }}
          title="Visa avklarade uppgifter"
          iconType="arrow"
          iconPlacement="right"
          clicked={() => push("/todos")}
        />
      </DetailPageBox>
    </>
  );
}
