import * as React from "react";

import { useAnyPermissionCheck } from "../../../store/base";

import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import ErrandRoleTable from "../../../components/Tables/ErrandRoles/FullTable";
import ErrandRoleForm from "../../../components/Forms/ErrandRole/ChapterForm/ModalForm";

export default function ErrandRoles() {
  const [createFormOpen, setCreateFormOpen] = React.useState(false);

  const canAddRole = useAnyPermissionCheck([
    "add_can_reporterrand",
    "add_can_inspectionerrand",
    "add_can_roundingerrand",
    "add_can_goverrand",
  ]);

  return (
    <DetailInnerWrapper>
      <ErrandRoleForm
        isOpen={createFormOpen}
        onCheckout={() => setCreateFormOpen(false)}
        method={"POST"}
      />

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Utföranderoller</OverviewTitle>
            <OverviewSubtitle style={{ marginRight: 24 }}>
              Utföranderoller är till för att kategorisera utförare efter vilken
              roll i beståndet de har. Genom att koppla en utföranderoll till en
              ärendetyp så aktiveras den automatiska delegeringen av ärenden.
            </OverviewSubtitle>

            <OverviewSubtitle style={{ marginRight: 24, fontWeight: 500 }}>
              Klicka på en roll för att hantera vilka användare som ska ha
              rollen för olika fastigheter.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          {canAddRole && (
            <PrimaryButton
              title="Lägg till roll"
              clicked={() => setCreateFormOpen(true)}
            />
          )}
        </OverviewTitleWrapper>

        <ErrandRoleTable hideTitle hideExport />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
