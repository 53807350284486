export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_FORTNOXTRANSACTION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_FORTNOXTRANSACTION",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_FORTNOXTRANSACTION",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_FORTNOXTRANSACTION",
  DESTROY_FORM: "DESTROY_FORM_FORTNOXTRANSACTION",
  SET_FORM_ERROR: "SET_FORM_ERROR_FORTNOXTRANSACTION",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_FORTNOXTRANSACTION",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_FORTNOXTRANSACTION",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_FORTNOXTRANSACTION",
  RESET_STATE: "RESET_STATE_FORTNOXTRANSACTION",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_FORTNOXTRANSACTION",
  REMOVE_OBJECT: "REMOVE_OBJECT_FORTNOXTRANSACTION",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_FORTNOXTRANSACTION",
  CLEAR_FETCHED: "CLEAR_FETCHED_FORTNOXTRANSACTION",

  LIST_URL: "/accounting/fortnox/fortnoxtransaction/list/",
  POST_URL: "/accounting/fortnox/fortnoxtransaction/",
  PATCH_URL: "/accounting/fortnox/fortnoxtransaction/",
  GET_URL: "/accounting/fortnox/fortnoxtransaction/",

  STORE_NAME: "fortnoxTransaction",
};
