import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];
    const b64Prefix = "data:application/pdf;base64,";

    // main document
    const docPath = responseData?.doc?.put;

    let base64file = formInstance?.doc?._tempData?.data;
    if (base64file && !base64file.includes(b64Prefix)) {
      base64file = b64Prefix + base64file;
    }

    if (docPath && base64file) {
      urls.push(docPath);
      files.push(base64file);
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );

    return;
  }
};
