import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import BottomBar from "../../../components/StepDisplay/BottomBar";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import WhitelistPermsForm from "../../../components/Forms/WhitelistPerms/WhitelistPermsForm";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { BareLayoutWrapper, InnerBox } from "../../../components/sharedStyles";
import { useUserGroup } from "../../../store/userGroups";
import { performUpdate } from "../../../store/whitelistPerms";

export default () => {
  const [loading, setLoading] = React.useState(false);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { userId, groupId } = useParams();

  const [group, groupLoading] = useUserGroup(groupId);

  const onSuccess = () => {
    setLoading(false);

    push(
      `/configcenter/groups-and-users/user-setup/invite-user/${groupId}/${userId}` //groupId, userId
    );
  };

  const onError = () => {
    setLoading(false);
  };

  const handleSaveDetailPerms = () => {
    dispatch(
      performUpdate({ successCallback: onSuccess, errorCallback: onError })
    );
  };

  const onSubmit = () => {
    setLoading(true);
    handleSaveDetailPerms();
  };

  return (
    <BareLayoutWrapper fillWidth style={{ paddingBottom: 120 }}>
      <StepDisplay
        steps={[
          "1. Skapa användare",
          "2. Ställ in behörigheter",
          "3. Bjud in användare",
        ]}
        activeIndex={1}
      />

      <InnerBox
        style={{
          maxWidth: 1200,
          padding: 48,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Skapa grupp för behörigheter (Steg 2/2)
            </OverviewTitle>
            <OverviewSubtitle>
              Välj om gruppen {group?.name || "som nyss skapades"} ska ha några
              extra behörigheter utöver de grundläggande.
              <strong>
                {" "}
                Exempel på extra behörigheter är om en användare ska jobba med
                hyresavtal i två fastigheter men ha tillgång till ett specifikt
                hyresavtal i en 3:e fastighet.
              </strong>
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <WhitelistPermsForm group={group} fromForm />
      </InnerBox>

      <BottomBar
        primaryActionTitle="Spara och gå vidare"
        disabled={false}
        primaryAction={onSubmit}
      />
      {loading && <OverlaySpinner />}
    </BareLayoutWrapper>
  );
};
