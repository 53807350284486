import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  useRoundingErrandForm,
  destroyPatchForm,
  destroyPostForm,
  constants,
  detailUrl,
  create,
  update,
} from "../../../../store/roundingErrands";
import { cloneDeep } from "lodash";
import { updateActiveFormInstance } from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TableSelectField from "../../Base/Fields/TableSelectField";
import RoundingErrandSettingTable from "src/components/Tables/RoundingErrandSettings/FullTable";
import TextInputField from "../../Base/Fields/TextInputField";
import { TimePicker } from "../../Base/Fields";
import UsersTable from "src/components/Tables/Users/FullTable";
import TimeSuggestion from "src/components/Details/BaseErrand/TimeSuggestion";
import DurationField from "../../Base/Fields/DurationField";

export default ({ method, id, isGov, instance, onCheckout }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useRoundingErrandForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }

    return () => {
      dispatch(destroyPatchForm());
      dispatch(destroyPostForm());
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    onCheckout(success);
  };

  const onSuccess = (data, returned) => {
    setLoading(false);
    checkout(true);

    if (method === "POST") {
      push(detailUrl({ id: returned.id, isGov: false }));
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={method === "POST" ? "Skapa ärende" : "Uppdatera ärende"}
      closeFunction={onDone}
      onAccept={onSubmit}
      acceptTitle={"Spara"}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}

      {method !== "PATCH" && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey="setting"
            title={isGov ? "Välj myndighetskravstyp" : "Ronderingstyp"}
            placeholder="Välj typ..."
            TableComponent={RoundingErrandSettingTable}
            persistantQuery={
              isGov ? { kind__isnull: false } : { kind__isnull: true }
            }
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          fieldKey={"title"}
          method={method}
          title="Titel på ärende"
        />
      </div>

      {isGov && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            storeName={storeName}
            fieldKey="inspecting_party"
            title="Besiktande part"
            method={method}
          />
        </div>
      )}

      <div className="grid grid-cols-2">
        <TimePicker
          storeName={storeName}
          method={method}
          fieldKey={"created_at"}
          defaultNow={true}
          title="Initierat den"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          method={method}
          fieldKey="reported_by"
          storeName={storeName}
          TableComponent={UsersTable}
          persistantQuery={{ user_type__in: [0, 1, 2] }}
          title="Initierat av"
        />
      </div>

      <hr />

      <TimeSuggestion storeName={storeName} method={method} />
      <br />
      <DurationField
        title="Förväntad arbetstid"
        storeName={storeName}
        fieldKey={"expected_duration"}
        method={method}
        extraStyle={{ maginTop: "12px" }}
      />
      <TimePicker
        title="Planerat slut"
        storeName={storeName}
        fieldKey={"planned_end"}
        method={method}
      />
    </Modal>
  );
};
