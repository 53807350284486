import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import PlainForm from "../../components/Forms/UserGroup/PlainForm";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import { updateActiveFormInstance } from "../../store/base";
import { useUserGroup, constants } from "../../store/userGroups";
import PageTitle from "../Layouts/PageTitle/PageTitle";

export default () => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const [group] = useUserGroup(groupId);

  React.useEffect(() => {
    if (group) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: group,
        })
      );
    }
  }, [group]);

  const breadCrumbs = [
    {
      label: "Användare & behörigheter",
      url: "/configcenter/groups-and-users/group",
    },
    {
      label: group?.name || "Laddar...",
      url: `/configcenter/groups-and-users/group/${group?.id}`,
    },
    {
      label: "Redigera",
    },
  ];

  if (!group)
    return (
      <DetailPageBox style={{ height: "600px" }}>
        <OverlaySpinner />
      </DetailPageBox>
    );

  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle title="Redigera grupptitel" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Redigera grupptitel</OverviewTitle>
            <OverviewSubtitle>
              Tryck på "Uppdatera" för att spara ändringar
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <PlainForm
          method="PATCH"
          untouchedInstance={group}
          nextUrl={`/configcenter/groups-and-users/group/${group?.id}`}
        />
      </DetailPageBox>
    </BareLayoutWrapper>
  );
};
