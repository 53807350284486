import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";

export default React.memo(
  ({
    value,
    onChange,
    required,
    error,
    title,
    description,
    options = [],
    disabled,
    id = "radio",
  }) => {
    return (
      <>
        <div>
          <div className="font-medium text-gray-900 text-sm flex items-center mb-0">
            {error && (
              <ExclamationTriangleIcon
                width={16}
                className="text-red-600 mr-1"
              />
            )}{" "}
            {title}
            {required ? "*" : ""}
          </div>
          {description && (
            <div className="text-xs font-normal text-gray-500 bg-transparent mb-4">
              {description}
            </div>
          )}
        </div>

        {error && (
          <div className="text-xs font-normal text-red-600 mb-4">{error}</div>
        )}

        {options.map((op) => (
          <div className="flex items-center mb-4">
            <div className="flex items-center">
              <input
                onChange={() => onChange(op.value)}
                disabled={disabled || op.disabled}
                id={id + op.value}
                type="radio"
                checked={value === op.value}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 "
              />
            </div>
            <div className="ml-2 text-sm">
              <label for={id + op.value} className="font-medium text-gray-900 ">
                {op.title}
              </label>
              <p
                id="helper-radio-text"
                className="text-xs font-normal text-gray-500 "
              >
                {op.description}
              </p>
            </div>
          </div>
        ))}
      </>
    );
  }
);
