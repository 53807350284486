import * as React from "react";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";

import { constants } from "../../../../../../store/IMDMeasureType";
import MeasureTypeTable from "../../../../../Tables/MeasureType/FullTable";

export default () => {
  const storeName = constants.STORE_NAME;

  return (
    <div className="grid grid-cols-2 gap-6 mb-6">
      <TableSelectField
        storeName={storeName}
        title="Mätartyp som importen gäller för"
        description='Mätartypen bestämmer hur värdena för importen ska avläsas och hanteras. Kopplat till mätartypen finns priser som avgör hur mätarvärdena ska debiteras för. Under "IMD" -> "Mätartyper" skapas och hanteras mätartyper'
        placeholder="Välj mätartyp..."
        fieldKey={"_import_measure_type"}
        forceRender
        method={"POST"}
        TableComponent={MeasureTypeTable}
      />
    </div>
  );
};
