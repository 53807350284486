export const constructStrRep = (obj) => {
  return (
    obj?.user?.corporate_name ||
    obj?.user?.last_name ||
    obj?.user?.first_name ||
    obj?.user?.legal_id ||
    obj?.user?.email ||
    obj?.invoice_email ||
    "Hyresgäst"
  );
};
export const detailUrl = ({ id, isMember, showInvoicingSettingPrompt }) => {
  if (isMember) return `/members/detail/${id}`;
  return `/tenants/detail/${id}${
    showInvoicingSettingPrompt ? "?showInvoicingSettingPrompt=true" : ""
  }`;
};
export const overviewUrl = (conf) => {
  const isMember = conf?.isMember;
  if (isMember) return `/members`;

  return `/tenants`;
};
