import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import { useMeasureType } from "../../../store/IMDMeasureType";
import { useFilteredPriceValues } from "../../../store/IMDPriceValue";
import PriceValueTable from "../../../components/Tables/PriceValues/FullTable";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import PriceForm from "../../../components/Forms/PriceValue/ChapterForm/ModalForm";
import MeasureTypeForm from "../../../components/Forms/IMDMeasureType/ChapterForm/ModalForm";
import { detailUrl as productDetailUrl } from "../../../store/invoicingProducts";
import { BooleanLabel, LinkedObject } from "../../../components/Displays";

export default function IMDDetailOverview() {
  const { id } = useParams();

  const [measureType, loading] = useMeasureType(id);
  const [priceOpen, setPriceOpen] = React.useState(false);
  const [editingPrice, setEditingPrice] = React.useState(undefined);
  const [editingMeasureType, setEditingMeasureType] = React.useState(false);

  //const [priceValues] = useFilteredPriceValues(!!id ? buildQueryString({measure_type:id}) : null);

  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = !id
    ? null
    : {
        realestate_ids: filteredRealEstates,
        measure_type: id,
      };
  const persistantFilterMethod = (obj) => {
    if (!id) {
      return false;
    }

    if (!filteredRealEstates) return obj?.measure_type?.id === parseInt(id);
    return (
      obj?.measure_type?.id === parseInt(id) &&
      filteredRealEstates.includes(obj.realestate.id)
    );
  };

  const infoObj = {
    Detaljer: [
      {
        title: "Namn",
        value: measureType?.title || "-",
      },
      {
        title: "Typ",
        value: measureType?.kind_display,
      },
      {
        title: "Enhet",
        value: measureType?.unit_display,
      },
      {
        title: "Prefix",
        value: measureType?.prefix_display,
      },
      {
        title: "Fakturerbar",
        value: (
          <BooleanLabel
            value={measureType?.billable}
            onLabel={"Ja"}
            offLabel={"Nej"}
          />
        ),
      },
      {
        title: "Bokföringsprodukt",
        value: (
          <LinkedObject
            obj={measureType?.product}
            urlMethod={productDetailUrl}
          />
        ),
      },
      {
        title: "Produkt kategori",
        value: measureType?.product_category_display,
      },
    ],
  };

  return (
    <>
      <MeasureTypeForm
        method={"PATCH"}
        id={measureType?.id}
        instance={measureType}
        isOpen={editingMeasureType}
        onCheckout={() => setEditingMeasureType(false)}
      />
      <PriceForm
        method={!editingPrice ? "POST" : "PATCH"}
        isOpen={priceOpen}
        instance={editingPrice}
        id={editingPrice?.id}
        onCheckout={() => {
          setPriceOpen(false);
          setEditingPrice(undefined);
        }}
        skipRedirect={true}
        preProcess={(data) => {
          return { ...data, measure_type: measureType };
        }}
      />
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ minWidth: "39%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>

                <OverviewSubtitle>
                  {!!measureType && (
                    <TextButton
                      title="Uppdatera"
                      clicked={() => {
                        setEditingPrice(undefined);
                        setPriceOpen(false);
                        setEditingMeasureType(true);
                      }}
                      iconType="edit"
                      iconPlacement="right"
                    />
                  )}
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            <DetailInfo infoObj={infoObj} />
          </DetailPageBox>

          <DetailPageBox style={{ minWidth: "60%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Priser</OverviewTitle>
              {!!measureType && (
                <PrimaryButton
                  title="Lägg till ny"
                  clicked={() => {
                    setEditingPrice(undefined);
                    setPriceOpen(true);
                    setEditingMeasureType(false);
                  }}
                />
              )}
            </OverviewTitleWrapper>
            <PriceValueTable
              persistantQuery={persistantQuery}
              onRowClicked={(original) => {
                setEditingPrice(original);
                setPriceOpen(true);
              }}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
