import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { useTenantPaginationCount } from "../../store/tenants";

import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import Tenants from "./overview/Tenants";
import Sendouts from "./overview/Sendouts";

import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useAllPermissionCheck } from "../../store/base";
import MovedComponent from "../ConfigCenter/MovedComponent";

export default function MainTenantOverview() {
  const { path, url } = useRouteMatch();
  const { filteredRealEstates } = useActiveFilters();

  usePermissionRedirect(["view_can_tenant"]);

  const [activeCount] = useTenantPaginationCount({
    filters: { realestateIds: filteredRealEstates, is_brf: false },
  });

  const hasSendoutPermission = useAllPermissionCheck([
    "view_can_tenant",
    "allow_tenantportal",
    "view_can_tenantportal",
  ]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: Tenants,
    },
    // {
    //   path: "/overview",
    //   label: "Översikt",
    //   component: TenantsNewVersion,
    // },
  ];

  if (hasSendoutPermission) {
    SUB_PAGES.push({
      path: "/sendouts",
      label: "Utskick",
      component: Sendouts,
    });

    SUB_PAGES.push({
      path: "/tenant-portal",
      label: `"Mina sidor"`,
      component: MovedComponent,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal hyresgäster: {activeCount || "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Hyresgäster`}
          {...{ subPages: subPageLinks, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
