import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/CompleteList/Table";
import columnDefs from "./selectListDefs";

// store, state
import { buildRangeFilter } from "src/components/Lists/Base/utils";

import {
  useBrfPremisesPagination,
  performFilter,
  constants,
} from "../../../store/brfPremises";
import { PrimaryButton } from "../../Forms/Base/Buttons";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  onRowClicked,
  checkRowHighlighted,
  forceInitialPageSize,
  hidePagination,
  externalFilters,
  withPersistantSortBy,
  withPersistantGlobalFilter,
  persistantCategories,
  clearCategories,
  onSubmit,
  submitLabel,
}) => {
  const storeName = constants.STORE_NAME;
  const [selected, setSelected] = React.useState([]);

  const handleSelectedUpdated = (sel) => {
    setSelected(sel);
  };

  const filterInstructions = {
    premises_id: { operator: "icontains" },
    area: { handler: (filterObject) => buildRangeFilter(filterObject) },
  };

  const columns = React.useMemo(
    () => columnDefs(),
    [persistantFilterMethod, persistantQueryString]
  );
  const fetchAllTreshold = 25;

  const localStorageHiddenColumnId = "brfpremises_full_table_hidden";

  // not filterable on backend, but we want to enable filtering on it
  const forceFrontendColumns = [];

  return (
    <>
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useBrfPremisesPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        localStorageHiddenColumnId={localStorageHiddenColumnId}
        onRowClicked={() => {}}
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Bostadsrätter"}
        forceFrontendColumns={forceFrontendColumns}
        forceInitialPageSize={forceInitialPageSize}
        hidePagination={hidePagination}
        withSelectableRows
        handleSelectedUpdated={handleSelectedUpdated}
        {...{
          hideSearch,
          hideFilters,
          hideExport,
          hideColumns,
          onRowClicked,
          checkRowHighlighted,
          externalFilters,
          withPersistantSortBy,
          withPersistantGlobalFilter,
          persistantCategories,
          clearCategories,
        }}
      />

      {selected?.length > 0 && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 42 }}
        >
          <PrimaryButton
            extraStyle={{ minWidth: "300px" }}
            clicked={() => onSubmit(selected)}
            title={submitLabel}
          />
        </div>
      )}
    </>
  );
};
