import { axiosInstance } from "../../base/store/axios";

async function getPaymentMeansInstance(creditorId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/payment_mean/${creditorId}/`
  );

  return data;
}

async function updatePaymentMeans({ data }) {
  const { data: response } = await axiosInstance.put(
    `/accounting/gateways/payment_means/`,
    data
  );

  return response;
}

export { getPaymentMeansInstance, updatePaymentMeans };
