export default [
  {
    title: "Projekt",
    key: "PIPE",
    visited: true,
    hasError: false,
    fieldKeys: [
      "name",
      "description",
      "realestate",
      "color_code",
      "is_homeq_pipe",
      "homeq_project_id",
    ],
  },
  {
    title: "Fält för leads i projektet",
    key: "ATTRIBUTES",
    visited: false,
    hasError: false,
    fieldKeys: ["dynamic_attributes"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
