import * as React from "react";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

// style, design

export default () => {
  return [
    {
      Header: "Namn på lista",
      accessor: "name",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Aktiv för självavhjälpande",
      accessor: "hide_for_tenants",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 3 : 0}>
            {value ? "Nej" : "Ja"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Aktiv för utförande",
      accessor: "hide_for_non_tenants",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 3 : 0}>
            {value ? "Nej" : "Ja"}
          </StatusLabel>
        );
      },
    },
  ];
};
