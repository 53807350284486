import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useFrequentPermissions, usePermissionCheck } from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import {
  createOptionsUrl,
  handleManyInvoicingUrl,
} from "../../store/otherContracts";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";

import useActiveFilters from "../../hooks/useActiveFilters";

import { useOtherContractPaginationCount } from "../../store/otherContracts/hooks/retrieve";
import OtherContracts from "./overview/OtherContracts";
import OtherContractDocuments from "./overview/OtherContractDocuments";
import OtherContractCosts from "./overview/OtherContractCosts";
import OtherContractDrafts from "./overview/Drafts";
import HandleMassInvoincingOtherContract from "./overview/HandleMassInvoicing";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function OtherContractOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["view_can_contract"]);

  const canAdd = usePermissionCheck("add_can_contract");
  const { filteredRealEstates } = useActiveFilters();
  const { hasBillectaFullPermission } = useFrequentPermissions();

  const [activeCount] = useOtherContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: false,
  });
  const [draftCount] = useOtherContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: true,
  });

  const SUB_PAGES = [
    // {
    //   path: "/overview",
    //   label: "Avtal",
    //   component: OtherContracts,
    // },
    {
      path: "/overview",
      label: "Avtal",
      component: OtherContractCosts,
    },
    {
      path: "/drafts",
      label: `Utkast${draftCount ? ` (${draftCount})` : ""}`,
      component: OtherContractDrafts,
    },
    {
      path: "/handle-invoicing-statuses",
      hiddenLabel: true,
      component: HandleMassInvoincingOtherContract,
    },
    {
      path: "/overview-docs",
      label: "Digital signering",
      component: OtherContractDocuments,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal övriga avtal: {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {hasBillectaFullPermission && (
        <PrimaryBtn secondary onClick={() => push(handleManyInvoicingUrl())}>
          Masshantera aviseringsstatus
        </PrimaryBtn>
      )}
      {canAdd && (
        <PrimaryBtn className="ml-2" onClick={() => push(createOptionsUrl())}>
          Lägg till avtal
        </PrimaryBtn>
      )}
    </div>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Övriga avtal`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
