import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";

import RealEstateTable from "../../../components/Tables/RealEstate/FullTable";
import ApartmentTable from "../../../components/Tables/Apartments/FullTableNewVersion";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import { useTodoYourBlockStatistics } from "../../../store/overview";

const READABLE_TYPES = {
  realestates: "Fastigheter",
  apartments: "Lägenheter",
  tenants: "Hyresgäster",
};

const TABLE_TYPES = {
  realestates: RealEstateTable,
  apartments: ApartmentTable,
  tenants: TenantTable,
};

const ERROR_STR = {
  apartments:
    "Oftast beror synkproblem för lägenheter på att en lägenhet saknar adress. Åtgärda problemet så försöker systemet automatiskt att synka om förutsatt att YourBlock-synken är aktiv på fastigheten.",
  tenants:
    "Oftast beror synkproblem för hyresgäster på att en hyresgäst saknar E-postadress. Åtgärda problemet så försöker systemet automatiskt att synka om förutsatt att YourBlock-synken är aktiv på fastigheten.",
  realestate: "",
};

export default function IntegrationsTodo() {
  const [openTable, setOpenTable] = React.useState(null);
  const [rawYourBlockStatistics, yourblockLoading] =
    useTodoYourBlockStatistics();

  const filteredStatKeys = Object.keys(
    rawYourBlockStatistics?.data?.unsynced || {}
  ).filter((key) => rawYourBlockStatistics?.data?.unsynced?.[key]?.length > 0);

  return (
    <DetailInnerWrapper>
      {filteredStatKeys?.length > 0 ? (
        <>
          {filteredStatKeys.map((key) => {
            const data = rawYourBlockStatistics?.data?.unsynced?.[key];
            const open = openTable === `${key}`;
            const ids = data?.map((d) => d?.id || d);
            const persistantQuery = {
              id__in: ids,
            };

            const TableComp = TABLE_TYPES[key];

            const errorStr = ERROR_STR[key];

            return (
              <DetailPageBox key={key}>
                <OverviewTitleWrapper>
                  <OverviewTitle>
                    {READABLE_TYPES[key]} ({data?.length})
                  </OverviewTitle>
                </OverviewTitleWrapper>
                <InnerBox style={{ marginBottom: 12 }}>
                  <OverviewTitleWrapper style={{ marginBottom: open ? 24 : 0 }}>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Kunde ej synkas ({data?.length})
                      </OverviewTitle>
                      <OverviewSubtitle>
                        {!open
                          ? `Tryck på "Visa detaljer" för att se en detaljerad lista`
                          : `Klicka på en rad för att komma till detaljsidan`}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>

                    <PrimaryButton
                      title={!open ? "Visa detaljer" : "Stäng"}
                      clicked={
                        !open
                          ? () => setOpenTable(`${key}`)
                          : () => setOpenTable(null)
                      }
                    />
                  </OverviewTitleWrapper>

                  {open && (
                    <>
                      {errorStr && <InnerBox>{errorStr}</InnerBox>}

                      <TableComp
                        {...{
                          persistantQuery,
                          isBare: true,
                          ignoreLocalStorage: true,
                        }}
                      />
                    </>
                  )}
                </InnerBox>
              </DetailPageBox>
            );
          })}
        </>
      ) : (
        <DetailPageBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OverviewTitle style={{ padding: "48px 0 " }}>
            {yourblockLoading ? "Laddar..." : "Inget att hantera"}
          </OverviewTitle>
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
