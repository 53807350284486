import * as React from "react";

import { STRUCTURES } from "../templates";
import { CATEGORIES } from "../utils";

import { EXPORT_TYPES_ICON } from "../utils";

import moment from "moment";

import PrimaryBtn from "../../../components/Forms/Base/Buttons/PrimaryBtn";

import {
  ChevronRightIcon,
  TrashIcon,
  StarIcon as StarIconOutline,
} from "@heroicons/react/24/outline";

import PopOver, {
  POP_OVER_POSITIONS,
} from "../../../components/PopOver/PopOver";

import Badge, { BADGE_TYPES } from "../../../components/Badge/Badge";

export default ({
  isOnSharedPage,
  goToReportTable,
  removeReport,
  openShareSettings,
  downloadAgain,
  query,
}) => {
  const cols = [
    {
      Header: "fav",
      accessor: "is_starred",
      Cell: ({ row }) => {
        const report = row.original;

        return (
          <>
            {report.is_starred && !isOnSharedPage && (
              <StarIconOutline className="mr-2" width={16} />
            )}
          </>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "n",
      accessor: "title",
      Cell: ({ row }) => {
        const report = row.original;

        return (
          <div className={`relative flex flex-col`}>
            <p
              className={` text-sm font-medium text-ellipsis break-keep whitespace-nowrap overflow-hidden`}
            >
              {report.title || report.str_representation}
            </p>
            <PopOver
              position={POP_OVER_POSITIONS.BOTTOM}
              tooltipClassName={`!left-0`}
            >
              <div className=" text-sm flex break-all">
                <p>{report.title || report.str_representation}</p>
              </div>
            </PopOver>
          </div>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "k",
      accessor: "report",
      Cell: ({ row }) => {
        const report = row.original;

        let category = undefined;

        if (!STRUCTURES?.[report.report]) {
          category = CATEGORIES.missing;
        } else {
          category =
            STRUCTURES?.[report.report].category &&
            STRUCTURES?.[report.report].category !== "unset"
              ? CATEGORIES[STRUCTURES[report.report].category]
              : CATEGORIES.missing;
        }

        return category.badge(false, true); // selected false, small true
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "s",
      accessor: "performed_by",
      Cell: ({ row }) => {
        const report = row.original;
        return (
          <>
            {report.performed_at ? (
              <p className="text-gray-400 text-xs max-w-[240px] text-start overflow-hidden whitespace-nowrap break-keep text-ellipsis">
                {moment(report.performed_at).format("YYYY-MM-DD")}
                <br />
                {report.performed_by.str_representation}
              </p>
            ) : (
              <div>
                <Badge small type={BADGE_TYPES.YELLOW}>
                  Pigello
                </Badge>
              </div>
            )}
          </>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "d",
      accessor: "shared_with",
      Cell: ({ row }) => {
        const report = row.original;

        return (
          <>
            {report.base_template !== true && (
              <div
                onClick={(e) => openShareSettings(e, report)}
                className={`cursor-pointer ${report.is_template && "ml-14"}`}
              >
                {report.shared_with.length !== 0 ? (
                  <p
                    className=" text-xs border-b border-solid border-transparent transition-colors hover:border-gray-500"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    Delad ({report.shared_with.length || 0})
                  </p>
                ) : (
                  <p
                    className=" text-xs border-b border-solid border-transparent transition-colors hover:border-gray-500"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    Privat
                  </p>
                )}
              </div>
            )}
          </>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "k",
      id: "main_buttons",
      Cell: ({ row }) => {
        const report = row.original;

        return (
          <div className="flex items-center space-x-2 justify-between pr-2.5">
            {!report.is_template && (
              <div
                onClick={(evt) => {
                  evt.stopPropagation();
                  downloadAgain(report);
                }}
                className="h-[32px] relative flex cursor-pointer items-center border border-gray-200 hover:bg-gray-200 text-black p-2.5 rounded transition-colors child-svg:w-5 child-svg:h-5"
              >
                {EXPORT_TYPES_ICON[report.format]}
                <div className="h-[26px] mr-2 ml-2 border-l border-gray-300 w-[1px]"></div>
                <p className="text-xs whitespace-nowrap">Ladda ner</p>
              </div>
            )}

            <PrimaryBtn
              onClick={(evt) => {
                evt.stopPropagation();
                goToReportTable(report);
              }}
              className={`flex cursor-pointer items-center bg-indigo-500 text-white p-2 rounded transition-colors ${
                report.is_template
                  ? "group-hover:bg-indigo-600"
                  : "hover:bg-indigo-600 relative"
              }`}
            >
              <p className="text-xs">
                {report.is_template ? "Öppna mall" : "Öppna"}
              </p>
              <ChevronRightIcon width={16} className="ml-1" />
            </PrimaryBtn>
            {!report.base_template && (
              <button
                onClick={(evt) => removeReport(evt, report, query)}
                className={`outline-none border border-gray-200 border-solid p-1.5 rounded hover:bg-gray-200 transition-colors hover:text-red-400`}
              >
                <TrashIcon className="text-red-500" width={16} />
              </button>
            )}
          </div>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];

  return cols;
};
