import { cloneDeep } from "lodash";
import baseReducer from "../../base/store/reducer";
import constants from "./constants";

const INITIAL_STATE = {
  all: {},
  allCopy: {},
  inProgress: [],
  paginations: {},
  paginationsCopy: {},
  filtered: {},
  filteredCopy: {},
  backgroundTasks: {},
  hasFetchedAll: false,
  forms: {},
  formInstance: {},
  formErrors: {},
  commodities: [], // array of objs
  defaultSettings: {
    electricity_included: false,
    heating_included: false,
    internet_included: false,
    cable_package_included: false,
    water: null,
    renovation_date: null,
    elevator: false,
    kitchen: null,
    pantry: false,
    patio: false,
    gas_cooker: false,
    stove: false,
    tiled_stove: false,
    bed_in_wall: false,
    accessibility_adapted: false,
    handicap_adapted: false,
    security_door: false,
    laundry_room: true,
    shower: true,
    balcony: null,
    kitchen_fan: null,
    dishwasher: null,
    washing_machine: null,
    dryer: null,
    bathtub: null,
    available_parking_spots: 0,
    included_parking_spots: 0,
    available_garage_spots: 0,
    included_garage_spots: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.ADD_INDP: {
      return handleAddIndp(newState, payload);
    }

    case constants.ADD_MULTIPLE_INDPS: {
      return handleAddMultipleIndps(newState, payload);
    }

    case constants.REMOVE_INDP: {
      return handleRemoveIndp(newState, payload);
    }

    case constants.SET_COMMODITIES: {
      return { ...newState, commodities: payload.newCommodities };
    }

    case constants.SET_DEFAULT_SETTINGS: {
      return { ...newState, defaultSettings: payload.settings };
    }

    default:
      return newState;
  }
};

const handleAddIndp = (state, payload) => {
  const { indp } = payload;
  const stateClone = cloneDeep(state);

  const { commodities } = stateClone;

  commodities.push({
    ...indp,
  });

  return stateClone;
};

const handleAddMultipleIndps = (state, payload) => {
  const { indps } = payload;

  const stateClone = cloneDeep(state);

  const { commodities } = stateClone;

  indps.forEach((a) => {
    commodities.push({
      ...a,
    });
  });

  return stateClone;
};

const handleRemoveIndp = (state, payload) => {
  const { indp } = payload;
  const stateClone = cloneDeep(state);

  const { commodities } = stateClone;

  const newCommodities = commodities.filter((i) => i.id !== indp.id);
  stateClone.commodities = newCommodities;
  return stateClone;
};
