import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";

import {
  updateActiveFormInstance,
  useFormField,
  useFormInstanceField,
} from "../../../../../store/base";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InnerBox } from "../../../../sharedStyles";
import Toggle from "../../../../Toggle/Toggle";

// style, design
import * as SC from "../styles";

export default React.memo(
  ({ fieldKey, instructionsKey, storeName, extraStyles, method }) => {
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const dispatch = useDispatch();
    const value = useFormInstanceField({ storeName, fieldKey });

    const choices = instructions._choices;

    const onChange = (toggled) => {
      let valueCopy = cloneDeep(value);

      if (value.includes(toggled)) {
        valueCopy = valueCopy.filter((v) => v !== toggled);
      } else {
        valueCopy.push(toggled);
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [fieldKey]: valueCopy,
          },
        })
      );
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <InnerBox style={{ maxWidth: 600 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Inkluderade händelser</OverviewTitle>
        </OverviewTitleWrapper>
        <SC.InputSpacing style={{ ...extraStyles }}>
          {choices.map((c) => (
            <React.Fragment key={c.v}>
              <Toggle
                value={(value || []).includes(c.v)}
                title={c.d}
                onToggle={() => onChange(c.v)}
              />
              <hr style={{ margin: "4px 0" }} />
            </React.Fragment>
          ))}
        </SC.InputSpacing>
      </InnerBox>
    );
  }
);
