import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../components/Displays";
import { PrimaryButton, TextButton } from "../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import Pdf from "../../components/Pdf/Pdf";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import { INVOICE_TYPES } from "../../store/invoicingLease";
import {
  previewInvoice as leasePreviewInvoice,
  generateInvoice as leaseGenerateInvoice,
  detailUrl as leaseDetailUrl,
} from "../../store/invoicingLease";

import {
  previewInvoice as parkingPreviewInvoice,
  generateInvoice as parkingGenerateInvoice,
  detailUrl as parkingDetailUrl,
} from "../../store/invoicingParking";

import {
  previewInvoice as otherPreviewInvoice,
  generateInvoice as otherGenerateInvoice,
  detailUrl as otherDetailUrl,
} from "../../store/invoicingOther";

import {
  previewInvoice as brfPreviewInvoice,
  generateInvoice as brfGenerateInvoice,
  detailUrl as brfDetailUrl,
} from "../../store/invoicingBrf";
import { constants as invoicingRecordsConstants } from "../../store/invoicingRecords";

import { addToast, TOAST_TYPES } from "../../store/toasts";
import { clearSearchWithId } from "../../store/billectaSearch";
import { clearFetched } from "../../store/base";
import { unpackErrorStr } from "../../components/Tables/InvoicingErrors/listDefs";

const getPreviewMethodFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return leasePreviewInvoice;
  if (type === INVOICE_TYPES.OTHER) return otherPreviewInvoice;
  if (type === INVOICE_TYPES.PARKING) return parkingPreviewInvoice;
  if (type === INVOICE_TYPES.BRF) return brfPreviewInvoice;
};
const getGenerateMethodFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return leaseGenerateInvoice;
  if (type === INVOICE_TYPES.OTHER) return otherGenerateInvoice;
  if (type === INVOICE_TYPES.PARKING) return parkingGenerateInvoice;
  if (type === INVOICE_TYPES.BRF) return brfGenerateInvoice;
};
const getDetailUrlFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return leaseDetailUrl;
  if (type === INVOICE_TYPES.OTHER) return otherDetailUrl;
  if (type === INVOICE_TYPES.PARKING) return parkingDetailUrl;
  if (type === INVOICE_TYPES.BRF) return brfDetailUrl;
};
const getInvoicingKeyFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return "lease_invoicing";
  if (type === INVOICE_TYPES.OTHER) return "other_invoicing";
  if (type === INVOICE_TYPES.PARKING) return "parking_invoicing";
  if (type === INVOICE_TYPES.BRF) return "brf_invoicing";
};

export default function PreviewGenerateInvoiceBase() {
  const dispatch = useDispatch();
  const { type, id, contractId, basedOn } = useParams();
  const { goBack, replace } = useHistory();
  const query = useQuery();

  const withGenerate = query.get("withGenerate") == "true";
  const hasGenerated = query.get("hasGenerated") == "true";

  const [previewData, setPreviewData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");

  const invoicingKey = getInvoicingKeyFromType(type);
  const previewMethod = getPreviewMethodFromType(type);
  const generateInvoiceMethod = getGenerateMethodFromType(type);
  const detailUrl = getDetailUrlFromType(type);

  const downloadPreview = () => {
    // trigger pdf download
    const a = document.createElement("a");
    a.href = previewData;
    a.download = `invoice_${basedOn}.pdf`;
    a.click();
  };

  const previewInvoice = () => {
    setLoading(true);
    dispatch(
      previewMethod({
        id,
        basedOn,
        successCallback: (data) => {
          setLoading(false);

          setPreviewData(`data:application/pdf;base64, ${data}`);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Förhandsgranskningen hämtades",
            })
          );
        },
        errorCallback: (errorMessage, status) => {
          if (status === 404 || status === 500) {
            setErrorState(status);

            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Förhandsgranskningen kunde ej hämtas",
                description:
                  status === 404
                    ? "Aviseringen är ej aktiv för denna period"
                    : status === 500
                    ? "Något gick fel. Pigello har fått information om detta och arbetar för att lösa problemet. Kontakta oss om problemet kvarstår"
                    : "",
              })
            );
            return;
          }

          const error = errorMessage?.non_field_errors
            ? errorMessage.non_field_errors
            : unpackErrorStr(errorMessage);
          setLoading(false);

          //TODO: Test this. unable to test on current branch
          //should work though
          if (typeof error === "object" && error?.Message) {
            error = error.Message;
          }

          setErrorMessage(error);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Förhandsgranskningen kunde ej hämtas",
              description: `Felmeddelande: ${
                JSON.stringify(error) || "Försök igen"
              }`,
            })
          );
        },
      })
    );
  };

  const generateInvoice = () => {
    setLoading(true);

    dispatch(
      generateInvoiceMethod({
        id,
        basedOn,
        successCallback: (data) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avin genererades",
              description:
                'Den visas under "Genererade avier" längst ner på sidan',
            })
          );

          setLoading(false);
          replace(detailUrl({ id: contractId }));
        },
        errorCallback: (errorMessage) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Avin kunde ej genereras",
              description: `Felmeddelande: ${
                JSON.stringify(errorMessage) || "Försök igen"
              }`,
            })
          );
        },
      })
    );
  };

  React.useEffect(() => {
    previewInvoice();
  }, []);

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        {loading && <OverlaySpinner />}
        <TextButton
          title="Gå tillbaka"
          clicked={goBack}
          extraStyle={{ marginBottom: 12 }}
          iconType="arrow-back"
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Förhandsgranskning av avi</OverviewTitle>
            {withGenerate ? (
              <OverviewSubtitle>
                Klicka på "Generera nu" för att skapa avin
              </OverviewSubtitle>
            ) : (
              <OverviewSubtitle>
                Klicka på "Ladda ner" för att hämta förhandsgranskningen
              </OverviewSubtitle>
            )}
          </OverviewTitleWithSubtitleWrapper>

          {withGenerate ? (
            <PrimaryButton
              disabled={!previewData}
              title="Generera nu"
              clicked={generateInvoice}
            />
          ) : (
            <PrimaryButton
              disabled={!previewData}
              title="Ladda ner"
              clicked={downloadPreview}
            />
          )}
        </OverviewTitleWrapper>

        {hasGenerated && (
          <InfoBox
            boxTheme="warning"
            title="En avi har redan genererats för denna period"
            text="En avi med samma period har redan genererats för detta avtal. Debiteringsraderna har justerats för att ta hänsyn till det tidigare aviserade beloppet."
          />
        )}

        <InnerBox
          style={{
            minHeight: 400,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {previewData ? (
            <Pdf b64={previewData} />
          ) : !errorState && !errorMessage ? (
            "Laddar förhandsgranskning..."
          ) : errorMessage ? (
            `Felmeddelande: ${errorMessage || "Försök igen"}`
          ) : null}

          {errorState === 404 && (
            <div>
              Avi kan ej genereras för denna period, antingen på grund av att
              avtalet inte är aktivt för denna period eller att det inte är
              aktiverat än.
            </div>
          )}

          {errorState === 500 && <div>Något gick fel på servern</div>}
        </InnerBox>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
