import Confirm from "./Confirm";

import General from "./General";
import Fees from "./Fees";
import Debit from "./Debit";

export default {
  GENERAL: General,
  FEES: Fees,
  DEBIT: Debit,
  CONFIRM: Confirm,
};
