import * as React from "react";

import { AddressField } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/industrialPremises";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import useActiveFilters from "../../../../../hooks/useActiveFilters";

import { useFilteredRealEstates } from "../../../../../store/realEstates";
import { useDispatch } from "react-redux";
import FloorSelector from "../../../Base/FieldComponents/FloorSelector";
import { useIsImpersonation } from "../../../../../store/app/hooks";
import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";
import SelectField from "src/components/Forms/Base/Fields/SelectField";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();

  const isAdmin = useIsImpersonation();

  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const floors = useFormInstanceField({ storeName, fieldKey: "floors" });
  const dynamicFloors = useFormInstanceField({
    storeName,
    fieldKey: "dynamic_floors",
  });

  const landlord = useFormInstanceField({
    storeName,
    fieldKey: "landlord",
  });
  const vacancyBookingCompany = useFormInstanceField({
    storeName,
    fieldKey: "vacancy_booking_company",
  });

  const persistantQuery = {
    realestate__in: filteredRealEstates,
  };

  const realEstateQuery = buildQueryString({
    id__in: filteredRealEstates,
  });

  const [realEstates] = useFilteredRealEstates(realEstateQuery);

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({ storeName, data: { floors: dynamicFloors } })
    );
  }, []);

  React.useEffect(() => {
    if (landlord && !vacancyBookingCompany) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            vacancy_booking_company: landlord,
          },
        })
      );
    }
  }, [landlord]);

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Objektsnummer"
          storeName={storeName}
          method={method}
          fieldKey="premises_id"
        />
      </div>

      <AddressField
        title="Adress"
        storeName={storeName}
        method={method}
        fieldKey="address"
      />

      <FloorSelector
        storeName={storeName}
        fieldKey={"floors"}
        floors={floors}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Typ av lokal"
          storeName={storeName}
          method={method}
          fieldKey="category"
          extraStyles={{ minWidth: 290, marginRight: 12 }}
        />
        <TextInputField
          storeName={storeName}
          isNumber
          method={method}
          fieldKey="area"
          title="Area i m&sup2;"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={persistantQuery}
          placeholder="Välj byggnad..."
          title="Byggnad"
          method={method}
          fieldKey="building"
          TableComponent={BuildingTable}
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj hyresvärd..."
          title="Hyresvärd"
          method={method}
          fieldKey="landlord"
          TableComponent={CompanyTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj bolag för vakansbokning..."
          title="Bolag för vakansbokning"
          method={method}
          fieldKey="vacancy_booking_company"
          description="Välj vilket bolag vakanser ska bokas på."
          TableComponent={CompanyTable}
        />
      </div>
      {isAdmin && (
        <>
          <InnerBox style={{ marginBottom: 24 }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Adminfunktioner</OverviewTitle>
            </OverviewTitleWrapper>

            <TableSelectField
              storeName={storeName}
              placeholder="Välj hyresgäst..."
              title="Hyregäst"
              method={method}
              fieldKey="tenant"
              TableComponent={TenantTable}
            />

            <TableSelectField
              storeName={storeName}
              fieldKey={"bi_tenants"}
              method={method}
              title={"Ytterligare hyresgäster"}
              placeholder={"Välj hyresgäster..."}
              isMany
              TableComponent={TenantTable}
            />
          </InnerBox>
        </>
      )}
    </>
  );
};
