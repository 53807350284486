import { debounce } from "lodash";
import * as React from "react";
import { Page } from "react-pdf";

/***
 * takes element and padding
 * element - html element that is checked if in viewport
 * padding - extra space in y considered viewport
 */
export function isInViewport(element, padding = 2000) {
  if (!element?.getBoundingClientRect) return false;
  const rect = element.getBoundingClientRect();

  const elementBottom = rect.bottom;

  const elementHeight = element.offsetHeight + padding;
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const offset = elementHeight;

  const activeWindow = viewportHeight + offset + padding;

  const visible = elementBottom + padding > 0 && elementBottom <= activeWindow;

  return visible;
}

export default function DigitalDocPDFPage({ pageNumber, pageWidth }) {
  const [visible, setVisible] = React.useState(true);
  const [height, setHeight] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const pageRef = React.useRef();

  React.useEffect(() => {
    if (!visible) {
      setLoaded(false);
    }
  }, [visible]);

  const onPageLoaded = (data) => {
    setHeight(data.height);

    setLoaded(true);
  };

  const checkIfVisible = () => {
    const vis = isInViewport(pageRef?.current);

    setVisible(vis);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", debounce(checkIfVisible, 100));

    return () => {
      window.removeEventListener("scroll", debounce(checkIfVisible));
    };
  }, []);

  return (
    <div
      ref={pageRef}
      style={{ minHeight: visible && loaded ? undefined : height }}
      className={`mb-4 shadow`}
    >
      {visible && (
        <Page
          renderAnnotationLayer={false}
          onLoadSuccess={onPageLoaded}
          width={pageWidth}
          pageNumber={pageNumber}
          externalLinkTarget="_blank"
          loading="Laddar sida..."
        />
      )}
    </div>
  );
}
