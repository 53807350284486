import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";

import { useReportErrand } from "../../../store/reportErrands";
import FullTable from "../../../components/Tables/ReportErrands/FullTableNewVersion";
import { buildQueryString } from "../../../store/base";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

function ErrandReport() {
  const { reportErrandId } = useParams();
  const [reportErrand, reportErrandLoading] = useReportErrand(reportErrandId);

  const componentQuery = {
    component_placement: reportErrand?.component_placement?.id || "-1",
    "id!": reportErrandId,
  };

  const reporterQuery = {
    reported_by: reportErrand?.reported_by?.id || "-1",
    "id!": reportErrandId,
  };

  return (
    <DetailInnerWrapper>
      {reportErrandLoading && <OverlaySpinner />}
      <DetailPageBoxFlexWrapper>
        <DetailPageBox style={{ flex: 1, maxWidth: "50%" }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>
              Felanmälningar på samma komponent
            </OverviewTitle>
          </OverviewTitleWrapper>
          {reportErrand && <FullTable persistantQuery={componentQuery} />}
        </DetailPageBox>

        <DetailPageBox style={{ flex: 1, maxWidth: "50%" }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>
              Felanmälningar av samma anmälare
            </OverviewTitle>
          </OverviewTitleWrapper>

          {reportErrand && <FullTable persistantQuery={reporterQuery} />}
        </DetailPageBox>
      </DetailPageBoxFlexWrapper>
    </DetailInnerWrapper>
  );
}

export default ErrandReport;
