import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import BasicTable from "../../Billecta/Table/BasicTable";
import { PrimaryButton, TextButton } from "../../Forms/Base/Buttons";
import PreviewIcon from "../../../assets/svg/material/preview_blue.svg";
import { OverviewTitle, OverviewTitleWrapper } from "../OverviewInfo/styles";
import ModalForm from "../../Forms/PortalSettingTerms/ChapterForm/ModalForm";
import {
  constants,
  update,
  useTenantPortalSetting,
} from "../../../store/tenantPortalSettings";
import {
  updateActiveFormInstance,
  useAllPermissionCheck,
} from "../../../store/base";
import { cloneDeep } from "lodash";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { exportEditableDoc } from "../../DigitalDoc/utils";

export default function TermsOverview({ terms }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [currentSetting] = useTenantPortalSetting(id);

  const [selectedRow, setSelectedRow] = React.useState(false);
  const [editTermOpen, setEditTermOpen] = React.useState(false);
  const [addTermOpen, setAddTermOpen] = React.useState(false);
  const [removeLoading, setRemoveLoading] = React.useState(false);

  const canChangePortalSetting = useAllPermissionCheck([
    "allow_tenantportal",
    "change_can_tenantportal",
  ]); //CUSTOMERPERMISSION?
  const canAddPortalSetting = useAllPermissionCheck([
    "allow_tenantportal",
    "add_can_tenantportal",
  ]); //CUSTOMERPERMISSION?
  const canDeletePortalSetting = useAllPermissionCheck([
    "allow_tenantportal",
    "delete_can_tenantportal",
  ]); //CUSTOMERPERMISSION?

  const createPdf = async (term) => {
    let data = await exportEditableDoc({
      editableDoc: term,
      forShow: true,
      onlyDoc: true,
    });

    const blob = new Blob([data], { type: "application/pdf" });
    const link = window.URL.createObjectURL(blob);
    window.open(link, "", "height=650,width=840");
  };

  const handleEditTerm = (term) => {
    setEditTermOpen(term);
  };

  const onSuccess = () => {
    setRemoveLoading(false);
  };

  const handleRemoveTerm = (term) => {
    setRemoveLoading(true);
    const settingClone = cloneDeep(currentSetting);

    settingClone.terms = settingClone?.terms?.filter((t) => t.id !== term.id);
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: settingClone,
      })
    );
    dispatch(
      update({
        id: settingClone?.id,
        successCallback: onSuccess,
        errorCallback: () => setRemoveLoading(false),
      })
    );
  };

  const data = React.useMemo(() => {
    return terms;
  }, [terms]);

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: "Namn",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Kategori",
        accessor: "category_display",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Dokument",
        accessor: "_doc",
        Cell: (props) => {
          const term = props.row.original;
          return (
            <TextButton
              title="Visa dokument"
              icon={PreviewIcon}
              clicked={() => createPdf(term)}
            />
          );
        },
      },
    ];

    if (canChangePortalSetting) {
      cols.push({
        Header: "Redigera",
        accessor: "id",
        Cell: ({ row }) => {
          return (
            <TextButton
              title="Redigera"
              iconType="edit"
              clicked={() => handleEditTerm(row.original)}
            />
          );
        },
      });
    }
    if (canDeletePortalSetting) {
      cols.push({
        Header: "Ta bort",
        accessor: "_id",
        Cell: ({ row }) => {
          return (
            <TextButton
              title="Ta bort"
              iconType="close"
              clicked={() => handleRemoveTerm(row.original)}
            />
          );
        },
      });
    }
    return cols;
  }, [canChangePortalSetting, canDeletePortalSetting, currentSetting]);

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitle>Översikt regler</OverviewTitle>
        {canAddPortalSetting && (
          <PrimaryButton
            title="Lägg till nytt dokument"
            clicked={() => {
              setAddTermOpen(true);
            }}
          />
        )}
      </OverviewTitleWrapper>
      {removeLoading && <OverlaySpinner />}
      {terms?.length > 0 ? (
        <BasicTable
          data={data}
          columns={columns}
          onRowClicked={() => {}}
          selectedRow={selectedRow}
          hideSearch={true}
          tableId="termsoverviewtabletenantportal"
          withPersistantSortBy
          withPersistantGlobalFilter
        />
      ) : (
        <div>Inga dokument är tillagda på denna inställning</div>
      )}
      <ModalForm
        method={"PATCH"}
        id={editTermOpen?.id}
        isOpen={!!editTermOpen}
        existingData={editTermOpen}
        onCheckout={setEditTermOpen}
      />
      <ModalForm
        method={"POST"}
        isOpen={addTermOpen}
        onCheckout={setAddTermOpen}
      />
    </>
  );
}
