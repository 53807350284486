import { constants } from "..";
import { formatSearchResults } from "../utils";
import * as services from "./services";

export const performSearch = ({ query, errorCallback, successCallback }) => {
  return async (dispatch) => {
    try {
      const searchResults = await services.performSearch(query);

      const formattedSearchResults = formatSearchResults(searchResults);

      dispatch({
        type: constants.SET_RESULTS,
        payload: {
          formattedSearchResults,
        },
      });
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const clearResults = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_RESULTS,
    });
  };
};
