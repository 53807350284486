import * as React from "react";

// store, state
import { constants } from "../../../../../store/industrialPremises";
import ManyMediaField from "../../../Base/Fields/ManyMediaField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <ManyMediaField
        storeName={storeName}
        method={method}
        fieldKey="images"
        fileKey="image"
        title="Bilder på objektet"
        allowedFormats={[".jpg", ".jpeg", ".png"]}
      />
    </>
  );
};
