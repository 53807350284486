import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

export default ({ method }) => {
  return (
    <>
      <FormAreaTitle>Granska och slutför</FormAreaTitle>
      <FormAreaDescription>
        {method === "POST " ? (
          <>
            Kontrollera att uppgifterna stämmer. Tryck på "Skapa inställning"
            för att slutföra.
          </>
        ) : (
          <>
            Kontrollera att uppgifterna stämmer. Genom att trycka på
            "Kontrollera uppdatering" visas en sammanfattning av undantag i
            uppdateringen som kan vara av intresse. Där kan ändringarna
            bekfräftas och genomföras.
          </>
        )}
      </FormAreaDescription>
    </>
  );
};
