import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import useActiveFilters from "../../../hooks/useActiveFilters";
import { useAllPermissionCheck } from "../../../store/base";

import ErrandsTable from "../../../components/Tables/RoundingErrands/FullTable";
import ModalForm from "../../../components/Forms/RoundingErrand/ChapterForm/ModalForm";

import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function Errands() {
  const { filteredRealEstates } = useActiveFilters();

  const [createErrandOpen, setCreateErrandOpen] = React.useState(false);

  const query = {
    realestate_ids: filteredRealEstates,
    is_not_gov: false,
  };
  const canAdd = useAllPermissionCheck([
    "add_can_goverrand",
    "allow_errand_gov",
  ]);

  return (
    <>
      {canAdd && createErrandOpen && (
        <ModalForm
          method="POST"
          isGov
          onCheckout={() => setCreateErrandOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper className="mb-0">
            <OverviewTitle>Ärenden</OverviewTitle>
            {canAdd && (
              <PrimaryBtn onClick={() => setCreateErrandOpen(true)}>
                Skapa manuellt ärende
              </PrimaryBtn>
            )}
          </OverviewTitleWrapper>
          <ErrandsTable isGov persistantQuery={query} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
