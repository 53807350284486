import Cookies from "js-cookie";
import moment from "moment";

const fixEmail = (email, createdAt) => {
  const split = email.split("@");
  const fixed = `${split[0]}+${createdAt}@${split[1]}`;

  return fixed;
};

export const initIntercom = ({ user, intercomData, isDev }) => {
  let APP_ID;
  if (window.location.hostname === "app.pigello.se") {
    APP_ID = "hi0kt15h";
  } else {
    APP_ID = "vpmhskl3";
  }

  Cookies.remove(`intercom-device-id-${APP_ID}`);
  Cookies.remove(`intercom-session-${APP_ID}`);

  const bootObj = {
    app_id: isDev ? "vpmhskl3" : "hi0kt15h",
    name: user?.str_representation,
    phone: user?.phone,
    email: fixEmail(
      user?.email,
      moment(intercomData?.user_created_date).unix()
    ),
    user_id: user?.actual_username,
    created_at: moment(intercomData?.user_created_date).unix(),
    user_hash: intercomData?.user_hash_key,
    company: {
      company_id: intercomData?.customer?.id,
      name: intercomData?.customer?.name,
    },
  };

  window.Intercom("boot", bootObj);
};
