import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import ArticleForm from "../../../components/Forms/ErrandArticle/ChapterForm/ModalForm";
import { useErrandArticle } from "../../../store/errandArticles";
import { detailUrl as productDetailUrl } from "../../../store/invoicingProducts";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { useAnyPermissionCheck } from "../../../store/base";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import theme from "../../../theme";
import { LinkedObject } from "../../../components/Displays";

export default function ArticleDetailOverview() {
  const { id } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);

  const [article] = useErrandArticle(id);

  const canEditArticle = useAnyPermissionCheck([
    "change_can_reporterrand",
    "change_can_inspectionerrand",
    "change_can_roundingerrand",
    "change_can_goverrand",
  ]);

  return (
    <>
      <ArticleForm
        method="PATCH"
        id={id}
        instance={article}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>
              <OverviewSubtitle>
                {canEditArticle && (
                  <TextButton
                    iconType="edit"
                    iconPlacement="right"
                    title="Redigera artikel"
                    clicked={() => setEditOpen(true)}
                  />
                )}
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <DetailInfo infoObj={getDetailInfoObj({ article })} />

          <InnerBox>
            <div
              style={{
                fontSize: theme.fontSizes.headerXSmall,
                fontWeight: theme.fontWeights.headerSmall,
              }}
            >
              Beskrivning
            </div>

            <div>{article?.description || "Beskrivning saknas"}</div>
          </InnerBox>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getDetailInfoObj({ article }) {
  return {
    Artikel: [
      {
        title: "Namn",
        value: article?.title,
      },
      {
        title: "Produkt",
        value: (
          <LinkedObject obj={article?.product} urlMethod={productDetailUrl} />
        ),
      },
      {
        title: "Artikelnr.",
        value: article?.code || "-",
      },
      {
        title: "Enhet",
        value: article?.unit || "-",
      },
      {
        title: "Standard antal",
        value: article?.standard_unit_amount || "-",
      },
      {
        title: "Kostnad/enhet",
        value: toMoneyString(article?.cost_per_unit, true),
      },
      {
        title: "Status",
        value: (
          <StatusLabel state={article?.is_active ? 0 : 3}>
            {article?.is_active ? "Aktiv" : "Inaktiv"}
          </StatusLabel>
        ),
      },
    ],
  };
}
