import * as React from "react";

function lighten(color, percentLightness, percentSaturation) {
  let split = color.split(",");
  let lightness = parseInt(split[2].replace(/\s+/g, "").replace("%", ""));
  lightness = Math.round((lightness * (100 + percentLightness)) / 100);
  lightness = lightness > 100 ? 100 : lightness;
  lightness = lightness < 0 ? 0 : lightness;

  let saturation = parseInt(split[1].replace(/\s+/g, "").replace("%", ""));
  saturation = Math.round((saturation * (100 + percentSaturation)) / 100);
  saturation = saturation > 100 ? 100 : saturation;
  saturation = saturation < 0 ? 0 : saturation;
  let res = split[0] + ", " + saturation + "%, " + lightness + "%)";
  return res;
}

export const WHITE = "hsl(0, 0%, 100%)";
export const PURPLE = "hsl(320, 28%, 42%)";
export const LIGHTPURPLE = "hsl(320, 37%, 58%)";
export const DARKPURPLE = "hsl(320, 28%, 45%)";
export const ORANGE = "hsl(39, 100%, 50%)";
export const TURQUOISE = "hsl(180, 65%, 81%)";
export const BLACK = "hsl(0, 0%, 21%)";
export const PINK = "hsl(350, 93%, 73%)";
export const BROWN = "hsl(66, 9%, 43%)";
export const GRAY = "hsl(75, 4%, 81%)";
export const BEIGELIGHT = "hsl(75, 4%, 81%)";
export const BEIGELIGHTER = "hsl(72, 14%, 93%)";
export const BLUE = "hsl(208, 79%, 35%)";
export const LIGHTERBLUE = "hsl(208, 80%, 75%)";
export const YELLOW = "hsl(49, 79%, 55%)";

export const LIGHTBLUE = "hsl(208, 79%, 55%)";
export const YELLOWLIGHT = "hsl(57, 84%, 65%)";
export const RED = "hsl(5, 70%, 64%)";
export const GREEN = "hsl(125, 49%, 67%)";

const Pattern = (id, stripeColor, backgroundColor) => (
  <pattern
    id={id}
    patternUnits="userSpaceOnUse"
    width="10"
    height="10"
    patternTransform="rotate(45)"
  >
    <rect x="0" y="0" width="10" height="10" fill={backgroundColor}></rect>
    <line x1="0" y="0" x2="0" y2="10" stroke={stripeColor} strokeWidth="10" />
  </pattern>
);

export const Defs = () => (
  <defs>
    {Pattern("striped-dark-purple-light-purple", DARKPURPLE, LIGHTPURPLE)}
    {Pattern("striped-green-gray", GREEN, GRAY)}
    {Pattern("striped-red-black", RED, BLACK)}
    {Pattern("striped-red-gray", RED, GRAY)}
    {Pattern("striped-red-white", RED, WHITE)}
    {Pattern("striped-yellow-gray", YELLOWLIGHT, GRAY)}
    {Pattern("striped-blue-gray", LIGHTBLUE, GRAY)}
    {Pattern("striped-green-white", GREEN, WHITE)}
  </defs>
);

export const stripedDarkPurpleLightPurple =
  "url(#striped-dark-purple-light-purple)";
export const stripedGreenGray = "url(#striped-green-gray)";
export const stripedRedBlack = "url(#striped-red-black)";
export const stripedRedGray = "url(#striped-red-gray)";
export const stripedGreenWhite = "url(#striped-green-white)";
export const stripedRedWhite = "url(#striped-red-white)";
export const stripedYellowGray = "url(#striped-yellow-gray)";
export const stripedBlueGray = "url(#striped-blue-gray)";

export const NOT_SIGNED = PURPLE;
export const FURTHER_CONTRACT = GREEN;
export const UNINTERESTING = GRAY;
export const UNKNOWN = GRAY;
export const UPCOMING = BLUE;
export const OK = GREEN;
export const WARNING = YELLOW;
export const DANGER = RED;
export const CLOSED = stripedRedBlack;
export const CLOSED_RENEW = stripedGreenWhite;

// Also used for statistics
export const ACTIVE = GREEN;
export const ENDING = YELLOW;
export const COMMON = GRAY;
export const VACANT = RED;

// blueprint lightness
const bpl = 32;

// blueprint saturation
const bps = -30;

export const BLUEPRINT_NOT_SIGNED = lighten(NOT_SIGNED, bpl, bps);
export const BLUEPRINT_FURTHER_CONTRACT = lighten(FURTHER_CONTRACT, bpl, bps);
export const BLUEPRINT_UNINTERESTING = lighten(UNINTERESTING, bpl, bps);
export const BLUEPRINT_UNKNOWN = UNKNOWN;
export const BLUEPRINT_UPCOMING = lighten(UPCOMING, bpl, bps);
export const BLUEPRINT_OK = lighten(OK, bpl, bps);
export const BLUEPRINT_WARNING = lighten(WARNING, bpl, bps);
export const BLUEPRINT_DANGER = lighten(DANGER, bpl, bps);
export const BLUEPRINT_CLOSED = CLOSED;
export const BLUEPRINT_CLOSED_RENEW = CLOSED_RENEW;
export const BLUEPRINT_ACTIVE = lighten(ACTIVE, bpl, bps);
export const BLUEPRINT_COMMON = COMMON;

export const BLUEPRINT_UNSELECTED = lighten(GRAY, bpl, bps);

export const RENOVATING_NOT_SIGNED = OK;
export const RENOVATING_FURTHER_CONTRACT = OK;
export const RENOVATING_UNINTERESTING = OK;
export const RENOVATING_UNKNOWN = OK;
export const RENOVATING_UPCOMING = OK;
export const RENOVATING_OK = OK;
export const RENOVATING_WARNING = OK;
export const RENOVATING_DANGER = OK;
export const RENOVATING_CLOSED = OK;
export const RENOVATING_CLOSED_RENEW = OK;
export const RENOVATING_ACTIVE = OK;
export const RENOVATING_COMMON = OK;

export const UNITINDUSTRIAL = BLUE;
export const UNITRENTRESIDENCE = LIGHTPURPLE;
export const UNITRENTHOTEL = TURQUOISE;
export const UNITRENTGARAGE = BLACK;
export const UNITRENTROOM = DARKPURPLE;
export const UNITRENTOFFICE = ORANGE;
export const UNITSPECIAL = PINK;
export const UNITOTHER = GRAY;
export const UNITRENTROOMRESIDENCE = stripedDarkPurpleLightPurple;
