export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INDEX_TABLE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INDEX_TABLE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INDEX_TABLE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INDEX_TABLE",
  DESTROY_FORM: "DESTROY_FORM_INDEX_TABLE",
  SET_FORM_ERROR: "SET_FORM_ERROR_INDEX_TABLE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INDEX_TABLE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INDEX_TABLE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INDEX_TABLE",
  RESET_STATE: "RESET_STATE_INDEX_TABLE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INDEX_TABLE",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDEX_TABLE",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_INDEX_TABLE",
  CLEAR_FETCHED: "CLEAR_FETCHED_INDEX_TABLE",

  LIST_URL: "/standard/index/indextable/list/",
  GET_URL: "/standard/index/indextable/",
  POST_URL: "/standard/index/indextable/",
  PATCH_URL: "/standard/index/indextable/",

  STORE_NAME: "indexTable",
};
