export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title;
};
export const detailUrl = ({ id }) => {
  return `/rounding-areas/detail/${id}`;
};

export const createUrl = ({ realEstateId }) => {
  return `/real-estate/${realEstateId}/rounding-area/create`;
};

export const overviewUrl = () => {
  return ``;
};
