import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./docDefs";

// style, design

// store, state
import { constants, detailUrl } from "../../../store/editabledocs";
import { useDispatch } from "react-redux";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";
import { BADGE_TYPES } from "src/components/Badge/Badge";

export default function OtherContractsDocsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const [removeId, setRemoveId] = React.useState(null);

  const onRemove = (id) => {
    setRemoveId(id);
  };

  const columns = React.useMemo(
    () => columnDefs(onRemove),
    [persistantQuery, removeId]
  );

  const badges = {
    Avklarad: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        status: "closed",
      },
    },
    Förberedelse: {
      color: BADGE_TYPES.CYAN,
      querySet: {
        status: "preparation",
      },
    },
    Avvaktande: {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        status: "pending",
      },
    },
    Utgången: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        status: "timedout",
      },
    },
    Avbruten: {
      color: BADGE_TYPES.RED,
      querySet: {
        status: "canceled",
      },
    },
  };

  const filters = {
    Titel: {
      queryKey: "title",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    "Dagar att signera": {
      queryKey: "days_to_sign",
      type: "number",
      operators: OPERATOR_TEMPLATES.NUMBER,
    },
    Avtal: {
      queryKey: "other_contract__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    "Uppdaterades senast": {
      queryKey: "inhouse_time",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  return (
    <BaseTable
      tableId={tableId || "other_contracts_docs_full_table"}
      title={"Dokument"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
