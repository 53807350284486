import moment from "moment";
import { durationParse } from "../../Displays/utils";

export const unPackArrayErrors = (errors) => {
  if (typeof errors === "string") {
    return errors;
  }

  if (!errors?.length) {
    return "";
  }

  return errors.filter((e) => typeof e === "string").join("\n");
};

export const getProposedEndDate = (contract) => {
  const DATE_FORMAT = "YYYY-MM-DD";

  if (!contract.closed_on) return;

  const proposal = moment(contract.closed_on).startOf("month");

  const { presentation, type } = durationParse(
    contract.notify_interval || "00 00 00 00"
  );

  switch (type) {
    case "år":
      proposal.add("years", presentation);
      break;
    case "månader":
      proposal.add("months", presentation);
      break;
    case "veckor":
      proposal.add("weeks", presentation);
      break;
    case "dagar":
      proposal.add("days", presentation);
      break;
    default:
      break;
  }

  proposal.endOf("month");

  return proposal.format(DATE_FORMAT);
};

export const CANCEL_CONTRACT_SAVED_FIELD_KINDS = [
  "first_name",
  "last_name",
  "personal_number",
  "corporate_number",
  "email",
  "phone",
];
