export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_RENTINCREASE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_RENTINCREASE",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_RENTINCREASE",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_RENTINCREASE",
  DESTROY_FORM: "DESTROY_FORM_RENTINCREASE",
  SET_FORM_ERROR: "SET_FORM_ERROR_RENTINCREASE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_RENTINCREASE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_RENTINCREASE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_RENTINCREASE",
  RESET_STATE: "RESET_STATE_RENTINCREASE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_RENTINCREASE",
  REMOVE_OBJECT: "REMOVE_OBJECT_RENTINCREASE",
  CLEAR_FETCHED: "CLEAR_FETCHED_RENTINCREASE",

  ADD_MULTIPLE_APARTMENTS: "ADD_MULTIPLE_APARTMENTS_RENTINCREASE",
  ADD_APARTMENT: "ADD_APARTMENT_RENTINCREASE",
  REMOVE_APARTMENT: "REMOVE_APARTMENT_RENTINCREASE",
  SET_APARTMENT_INCREASES: "SET_APARTMENT_INCREASES",
  SET_CURRENT_APARTMENT_INCREASES: "SET_CURRENT_APARTMENT_INCREASES",
  ADD_MULTIPLE_INDPS: "ADD_MULTIPLE_INDPS_RENTINCREASE",
  ADD_INDP: "ADD_INDP_RENTINCREASE",
  REMOVE_INDP: "REMOVE_INDP_RENTINCREASE",
  SET_INDP_INCREASES: "SET_INDP_INCREASES",
  SET_CURRENT_INDP_INCREASES: "SET_CURRENT_INDP_INCREASES",

  APARTMENT_RENT_INCREASE_UPDATE_URL:
    "/standard/mainlevel/premises/apartment/list/rent_increase/",
  INDP_RENT_INCREASE_UPDATE_URL:
    "/standard/mainlevel/premises/industrialpremises/list/rent_increase/",
  LIST_URL: "/standard/mainlevel/premises/rentincreases/",
  GET_URL: "/standard/mainlevel/premises/rentincrease/",

  STORE_NAME: "rentIncrease",
};
