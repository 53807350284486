import * as React from "react";
import * as SC from "./styles";

export default ({ clicked, disabled, title, extraStyle, closeIcon }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };
  return (
    <SC.RemoveButton
      style={{ ...extraStyle }}
      disabled={disabled}
      onClick={handleClick}
      closeIcon={closeIcon}
    >
      {title}
    </SC.RemoveButton>
  );
};
