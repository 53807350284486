export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PIPES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PIPES",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PIPES",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PIPES",
  DESTROY_FORM: "DESTROY_FORM_PIPES",
  SET_FORM_ERROR: "SET_FORM_ERROR_PIPES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PIPES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PIPES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PIPES",
  RESET_STATE: "RESET_STATE_PIPES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PIPES",
  REMOVE_OBJECT: "REMOVE_OBJECT_PIPES",
  CLEAR_FETCHED: "CLEAR_FETCHED_PIPES",

  SET_CONTRACT_LEADS: "SET_CONTRACT_LEADS_PIPES",
  SET_CONTRACT_SIGNING_TYPE: "SET_CONTRACT_SIGNING_TYPE_PIPES",
  SET_CONTRACT_PROPOSALS: "SET_CONTRACT_PROPOSALS_PIPES",
  SET_CREATED_CONTRACTS: "SET_CREATED_CONTRACTS_PIPES",
  SET_COST_PROPOSALS: "SET_COST_PROPOSALS_PIPES",
  REMOVE_CONTRACT_LEAD: "REMOVE_CONTRACT_LEAD_PIPES",
  REMOVE_CONTRACT_PROPOSAL: "REMOVE_CONTRACT_PROPOSAL_PIPES",
  UPDATE_CONTRACT_PROPOSAL: "UPDATE_CONTRACT_PROPOSAL_PIPES",
  REMOVE_COST_PROPOSAL: "REMOVE_COST_PROPOSAL_PIPES",
  UPDATE_COST_PROPOSAL: "UPDATE_COST_PROPOSAL_PIPES",
  SET_CONTRACT_LEADS_WARNINGS: "SET_CONTRACT_LEADS_WARNINGS_PIPES",
  SET_DOCUMENT_APPROVAL_INDEX: "SET_DOCUMENT_APPROVAL_INDEX_PIPES",
  SET_DOCUMENT_DENIED: "SET_DOCUMENT_DENIED_PIPES",
  CLEAR_CONTRACT_CREATION_DATA: "CLEAR_CONTRACT_CREATION_DATA_PIPES",

  LIST_URL: "/market/crm/pipe/list/",
  POST_URL: "/market/crm/pipe/",
  PATCH_URL: "/market/crm/pipe/",
  GET_URL: "/market/crm/pipe/",
  CONTRACT_WARNING_URL: "/market/crm/leadmanagement/conflicts/",
  LEAD_CONTRACT_PROPOSAL_URL: "/market/crm/leadmanagement/propose/",
  LEAD_COST_PROPOSAL_URL: "/accounting/contract/proposal/",

  STORE_NAME: "pipes",
};
