import React from "react";

import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import RealEstatePortfolioTable from "src/components/Tables/RealEstatePortfolios/FullTable";
import { cloneDeep, set } from "lodash";
import ScrollableModal from "../../Base/Modals/ScrollableModal";
import LocalTableSelectField from "../../Base/Fields/LocalTableSelectField";
import { useInsightsPageForm } from "src/store/insightsPage/hooks/form";

const inputsReducer = (state, action) => {
  if (action?.type === "all") return action.value;

  if (action?.type === "overrideMultiProps") {
    return {
      ...state,
      ...action.value,
    };
  }

  if (action.key.includes(".")) {
    let newState = cloneDeep(state);

    set(newState, action.key, action.value);

    return newState;
  }

  return {
    ...state,
    [action.key]: action.value,
  };
};

export default function FilterForm({
  formInstance,
  miscInstances,
  onCheckout,
  onDone,
  open,
  setOpen
}) {
  const insightsPage = formInstance
  const [loading, setLoading] = React.useState(false);
  const method = "PATCH"
  const formLoaded = insightsPage?.id === "dashboard-default" ? true : Boolean(useInsightsPageForm(method, insightsPage?.id))

  const [realEstates, setRealEstates] = React.useState([])
  const [companies, setCompanies] = React.useState([])
  const [portfolio, setPortfolio] = React.useState(null)

  const showPortfolio = localStorage.getItem("sidebar__hidden__portfolio") == "true" ? true : false

  const [inputsState, inputsStateDispatch] = React.useReducer(
    inputsReducer,
    insightsPage || {}
  );  
  
  const [hasLoadedInputsState, setHasLoadedInputsState] = React.useState(false);

  React.useEffect(() => {
    setCompanies(miscInstances?.selectedCompanies)
    setPortfolio(miscInstances?.selectedPortfolio)
    setRealEstates(miscInstances?.realestates)

  }, [miscInstances, open])
  React.useEffect(() => {
    if (!insightsPage) return;

    inputsStateDispatch({
      type: "all",
      value: insightsPage,
    });
    setHasLoadedInputsState(true);
  }, [insightsPage]);

  const onSubmit = () => {
    setLoading(true);
    onDone(realEstates, companies, portfolio, setLoading)
    setOpen(false)
  };

  const dynamicClearState = (key) => {
    switch (key) {
      case "realestates":
        return (setCompanies([]), setPortfolio(null))
      case "companies":
        return (setRealEstates([]), setPortfolio(null))
      case "portfolio":
        return (setRealEstates([]), setCompanies([]))
    }
  }
  return (
    <>
      {open && (
      <ScrollableModal
        closeFunction={() => {
          setOpen(false)}}
        onAccept={onSubmit}
        title="Uppdatera filtrering"
        className="!mt-0"
      >
        {(!formLoaded || (loading && open)) && <OverlaySpinner />}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              Uppdatera filtrering
            </OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={RealEstateTable}
            value={realEstates}
            placeholder="Välj fastigheter..."
            isMany
            tooltipPopOverClassName="ml-[50px] !bg-black"
            onClearAll={() => setRealEstates([])}
            onChange={(value) => {
              dynamicClearState("realestates")
              setRealEstates(value)}}
            title="Fastigheter"
          />
        </div>

        <p>Eller</p>
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={CompanyTable}
            value={companies}
            placeholder="Välj bolag..."
            isMany
            tooltipPopOverClassName="ml-[50px] !bg-black"
            onClearAll={() => setCompanies([])}
            onChange={(value) => {
              dynamicClearState("companies")
              setCompanies(value)}
            }
            title="Bolag"
          />
        </div>
        {showPortfolio && (
        <>
        <p>Eller</p>
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={RealEstatePortfolioTable}
            value={portfolio}
            tooltipPopOverClassName="ml-[50px] !bg-black"
            placeholder="Välj portfölj..."
            onClearAll={() => setPortfolio(null)}
            onChange={(value) => {
              dynamicClearState("portfolio")
              setPortfolio(value)}
            }
            title="Portfölj"
          />
        </div>
          </>
        )}
      </ScrollableModal>
      
      )}
    </>
  );
}
