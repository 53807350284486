import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import {
  AddressField,
  NonNestedSelect,
  NumberInput,
  TextInput,
} from "../../../Base/Fields";
import { constants } from "../../../../../store/leads";
import { useFilteredStages } from "../../../../../store/pipeStages";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../../../store/base";
import { useParams } from "react-router-dom";
import { useFilteredPipeAttributes } from "../../../../../store/pipeAttributes";
import { InputFieldTitle } from "../../../Base/Fields/styles";
import DescriptionToolTip from "../../../Base/Layout/DescriptionToolTip";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { pipeId } = useParams();

  const stageQuery = buildQueryString({
    pipe: pipeId,
  });

  const [pipeAttributeChoices] = useFilteredPipeAttributes(stageQuery);

  const pipeAttributeValues = useFormInstanceField({
    storeName,
    fieldKey: "pipeattributevalue_set",
  });
  const pipeAttributeErrors = useFormError({
    storeName,
    fieldKey: "pipeattributevalue_set",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Information om lead</OverviewTitle>
          <OverviewSubtitle>
            Grundläggande information om leaden
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <NonNestedSelect
        title="Steg"
        storeName={storeName}
        method={method}
        fieldKey="stage"
        fetchMethod={useFilteredStages}
        fetchQueryString={stageQuery}
      />

      <TextInput
        title="Bolag"
        storeName={storeName}
        method={method}
        fieldKey={"corporate_name"}
      />

      <DoubleFieldWrapper>
        <TextInput
          title="Förnamn"
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          method={method}
          fieldKey={"first_name"}
        />
        <TextInput
          title="Efternamn"
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          method={method}
          fieldKey={"last_name"}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          title="E-post"
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          method={method}
          fieldKey={"email"}
        />
        <TextInput
          title="Telefon"
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          method={method}
          fieldKey={"phone"}
        />
      </DoubleFieldWrapper>

      <TextInput
        {...{
          storeName,
          method,
          fieldKey: "profession",
          title: "Yrke/bransch",
        }}
      />

      <AddressField
        {...{ storeName, method, fieldKey: "address", title: "Adress" }}
      />

      {pipeAttributeChoices?.map((attribute, index) => {
        const valueIndex = pipeAttributeValues?.findIndex((val) => val.pipe_attribute.id === attribute.id);
        if (valueIndex == null) { return null; }

        const error = pipeAttributeErrors?.[valueIndex];

        // first check if choices field, needs to be rendered and handled differently
        if (attribute.pipeattributechoices_set?.length) {
          const attr = pipeAttributeValues?.[valueIndex];
          const choices = attribute.pipeattributechoices_set;

          if (attr?._selectedId == null) {
            attr._selectedId = choices.find((c) => c.max_float_value === attr.max_float_value && c.float_value === attr.float_value && c.char_value === attr.char_value)?.id;
          }

          return (
            <>
              <NonConnectedSelect
                label={attribute.title}
                helpText={attribute.description}
                value={attr?._selectedId}
                choices={choices}
                error={
                  error?.float_value ||
                  error?.max_float_value ||
                  error?.char_value
                }
                getOptionLabel={(option) => option.str_representation}
                getOptionValue={(option) => option.id}
                onUpdate={(val) => {
                  const selected = choices.find((c) => c.id === val);

                  dispatch(
                    updateActiveFormInstance({
                      storeName,
                      data: {
                        [`pipeattributevalue_set[${valueIndex}]._selectedId`]: val.id,
                        [`pipeattributevalue_set[${valueIndex}].float_value`]:
                          selected?.float_value,
                        [`pipeattributevalue_set[${valueIndex}].max_float_value`]:
                          selected?.max_float_value,
                        [`pipeattributevalue_set[${valueIndex}].char_value`]:
                          selected?.char_value,
                      },
                    })
                  );
                }}
              />
            </>
          );
        }

        switch (attribute.kind) {
          case 0: // value/decimal
            return (
              <NumberInput
                title={attribute?.title}
                description={attribute.description}
                method={method}
                storeName={storeName}
                forceError={error?.float_value}
                fieldKey={`pipeattributevalue_set[${valueIndex}].float_value`}
                instructionsKey={"pipeattributevalue_set.float_value"}
              />
            );

          case 1: // char field
            return (
              <TextInput
                title={attribute?.title}
                description={attribute.description}
                method={method}
                storeName={storeName}
                forceError={error?.char_value}
                fieldKey={`pipeattributevalue_set[${valueIndex}].char_value`}
                instructionsKey={"pipeattributevalue_set.char_value"}
              />
            );

          case 3: // interval field
            return (
              <>
                <InputFieldTitle
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {attribute.title}{" "}
                  {attribute.description && (
                    <DescriptionToolTip description={attribute.description} />
                  )}
                </InputFieldTitle>

                <DoubleFieldWrapper style={{ marginBottom: "24px" }}>
                  <NumberInput
                    noTitle
                    noMargin
                    description={attribute.description}
                    placeholder="Från..."
                    method={method}
                    storeName={storeName}
                    forceError={error?.float_value}
                    fieldKey={`pipeattributevalue_set[${valueIndex}].float_value`}
                    instructionsKey={"pipeattributevalue_set.float_value"}
                  />

                  <span>&nbsp; &mdash; &nbsp;</span>

                  <NumberInput
                    noTitle
                    noMargin
                    description={attribute.description}
                    method={method}
                    placeholder="Till..."
                    storeName={storeName}
                    forceError={error?.max_float_value}
                    fieldKey={`pipeattributevalue_set[${valueIndex}].max_float_value`}
                    instructionsKey={"pipeattributevalue_set.max_float_value"}
                  />
                </DoubleFieldWrapper>
              </>
            );

          default:
            return null;
        }
      })}
    </>
  );
};
