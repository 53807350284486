import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useKeyDeviceForm,
  constants,
  create,
  update,
  destroyPostForm,
  destroyPatchForm,
  useKeyDevice,
} from "../../../../store/keydevice";

import { overviewUrl as keyOverviewUrl } from "../../../../store/key";

import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../../store/base";
import {
  useApartment,
  detailUrl as apartmentDetailUrl,
} from "../../../../store/apartments";
import {
  useIndustrialPremises,
  detailUrl as industrialDetailUrl,
} from "../../../../store/industrialPremises";
import {
  useCommonArea,
  detailUrl as commonAreaDetailUrl,
} from "../../../../store/commonAreas";

export default function KeyDeviceForm({
  method = "POST",
  id,
  apartment,
  industrialPremises,
  commonArea,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useKeyDeviceForm(method, id));
  const { replace } = useHistory();

  const [instance, instanceLoading] = useKeyDevice(id);
  const [hasSetInstance, setHasSetInstance] = React.useState(false);

  const [hasSetDefaultObject, setHasSetDefaultObject] = React.useState(false);
  const [apartmentDefault, apartmentDefaultLoading] = useApartment(apartment);
  const [industrialDefault, industrialDefaultLoading] =
    useIndustrialPremises(industrialPremises);
  const [commonDefault, commonDefaultLoading] = useCommonArea(commonArea);

  React.useEffect(() => {
    if (!hasSetInstance && instance != null) {
      dispatch(
        setActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );

      setHasSetInstance(true);
    }
  }, [hasSetInstance, instance]);

  React.useEffect(() => {
    if (id != null) {
      return;
    }

    const obj =
      apartmentDefault != null
        ? { apartment: apartmentDefault }
        : industrialDefault != null
        ? { industrial_premises: industrialDefault }
        : commonDefault != null
        ? { common_area: commonDefault }
        : null;

    if (!hasSetDefaultObject && obj != null) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: obj,
        })
      );

      setHasSetDefaultObject(true);
    }
  }, [hasSetDefaultObject, apartmentDefault, industrialDefault, commonDefault]);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    if (returned?.apartment?.id) {
      replace(apartmentDetailUrl({ id: returned.apartment.id }) + "/keys");
    } else if (returned?.industrial_premises?.id) {
      replace(
        industrialDetailUrl({ id: returned.industrial_premises.id }) + "/keys"
      );
    } else if (returned?.common_area?.id) {
      replace(commonAreaDetailUrl({ id: returned.common_area.id }) + "/keys");
    } else {
      replace(keyOverviewUrl());
    }
  };

  React.useEffect(() => {
    return () => {
      if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      } else {
        dispatch(destroyPostForm(false));
      }
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    } else {
      dispatch(destroyPostForm(success));
    }
  };

  const onSubmit = () => {
    setLoading(true);

    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: onSuccess,
          errorCallback: onError,
          id,
        })
      );
    } else {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: onError,
        })
      );
    }
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,

          descriptions,
          method,
          onSubmit,

          title: method === "POST" ? "Lägg till dörr" : "Uppdatera dörr",
        }}
      />
    </>
  );
}
