import Confirm from "./Confirm";

import Device from "./Device";

import Integrations from "./Integrations";


export default {
  DEVICE: Device,
  CONFIRM: Confirm,
  INTEGRATION: Integrations
};
