import * as React from "react";
import moment from "moment";
import { useParams, useHistory } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "src/components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "src/components/Details/OverviewInfo/styles";
import LocalDateSelect from "src/components/Forms/Base/Fields/LocalDateSelect";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { buildQueryString } from "src/store/base";
import { axiosInstance } from "src/store/base/store/axios";
import { getCurrentProductTypeUsage } from "src/components/TechnicalManagement/utils";

import {
  getErrandStatus,
  getPerformer,
  getRequestedPerformer,
} from "src/components/utils/errandUtils";
import { LinkedObject, TimeCell, ToolTipCell } from "src/components/Displays";
import { StatusLabel } from "src/components/Tables/Keys/styles";
import { ErrandStatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import Table from "src/components/Billecta/Table/BasicTable";

import { detailUrl as apartmentDetailUrl } from "src/store/apartments";
import { detailUrl as indpDetailUrl } from "src/store/industrialPremises";
import { detailUrl as commonDetailUrl } from "src/store/commonAreas";
import { detailUrl as parkingDetailUrl } from "src/store/parkingSpots";
import { detailUrl as userDetailUrl } from "src/store/users";
import { detailUrl as brfDetailUrl } from "src/store/brfPremises";
import { detailUrl as reportErrandDetailUrl } from "src/store/reportErrands";
import { TOAST_TYPES, addToast } from "src/store/toasts";
import { useDispatch } from "react-redux";

export default function Errands() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(
    moment().subtract({ month: 1 }).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(
    moment().set({ hours: 0, minute: 0 }).format("YYYY-MM-DD")
  );

  const [data, setData] = React.useState([]);

  const diff =
    (moment.duration(moment(fromDate).diff(moment(toDate))) /
      1000 /
      60 /
      60 /
      24) *
    -1;

  const performSearch = async () => {
    dispatch(
      addToast({
        title: "Söker efter ärenden...",
        type: TOAST_TYPES.INFO,
      })
    );
    setLoading(true);
    const errandQ = buildQueryString({
      created_at__gte: fromDate,
      created_at__lte: toDate,
    });

    const { data: errands } = await axiosInstance.get(
      `/errands/main/reporterrand/list/?${errandQ}`
    );

    if (errands?.length === 0) {
      setLoading(false);
      dispatch(
        addToast({
          title: "Ingen data hittades",
          type: TOAST_TYPES.INFO,
        })
      );
      return;
    }

    const componentPlacementIds = errands?.map(
      (e) => e.component_placement?.id
    );

    const componentQ = buildQueryString({
      id__in: componentPlacementIds,
    });

    const { data: componentPlacements } = await axiosInstance.get(
      `/errands/components/componentplacement/list/?${componentQ}`
    );

    if (componentPlacements?.length === 0) {
      setLoading(false);

      dispatch(
        addToast({
          title: "Ingen data hittades",
          type: TOAST_TYPES.INFO,
        })
      );
      return;
    }

    const validComponentPlacements = componentPlacements.filter((c) => {
      const currentProductPlacement = getCurrentProductTypeUsage(c);

      return currentProductPlacement.product_type?.component_type?.id == id;
    });

    const validComponentPlacementIds = validComponentPlacements.map(
      (c) => c.id
    );

    const validErrands = errands.filter((e) =>
      validComponentPlacementIds.includes(e.component_placement.id)
    );

    if (validErrands?.length > 0) {
      dispatch(
        addToast({
          title: "Data hämtades",
          description: `${validErrands?.length} ärenden hittades`,
          type: TOAST_TYPES.SUCCESS,
        })
      );
    } else {
      dispatch(
        addToast({
          title: "Ingen data hittades",
          type: TOAST_TYPES.INFO,
        })
      );
    }

    setLoading(false);

    setData(validErrands);
  };

  const tableData = React.useMemo(() => {
    return data;
  }, [data]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Status",
        // accessor: "status",
        id: "errand_status",
        Cell: ({ row }) => {
          const { status, display } = getErrandStatus({
            status: row.original?.status,
            plannedStart: row.original?.planned_start,
            plannedEnd: row.original?.planned_end,
            actualStart: row.original?.execute_start,
            actualEnd: row.original?.execute_end,
          });
          return (
            <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>
          );
        },
      },

      {
        Header: "Ärendenummer",
        accessor: "errand_id",
        id: "id",
        Cell: ({ value }) => <ToolTipCell text={value} />,
      },
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => <ToolTipCell text={value} />,
      },
      {
        Header: "Objekt",
        accessor: "connected_obj.str_representation",
        Cell: ({ row }) => {
          const obj = row.original.connected_obj;
          const detailUrl =
            obj?.content_type === "standard.apartment"
              ? apartmentDetailUrl
              : obj?.content_type === "standard.industrialpremises"
              ? indpDetailUrl
              : obj?.content_type === "standard.commonarea"
              ? commonDetailUrl
              : obj?.content_type === "standard.parkingspot"
              ? parkingDetailUrl
              : brfDetailUrl;
          return <LinkedObject obj={obj} urlMethod={detailUrl} />;
        },
      },
      {
        Header: "Objektstyp",
        accessor: "connected_obj.content_type",
        Cell: ({ value }) => {
          const str_rep =
            value === "standard.apartment"
              ? "Lägenhet"
              : value === "standard.industrialpremises"
              ? "Lokal"
              : value === "standard.commonarea"
              ? "Gemensamt utrymme"
              : value === "standard.parkingspot"
              ? "Fordonsplays"
              : value === "brf.brfpremis"
              ? "Bostadsrätt"
              : "Okänd";
          return <div>{str_rep}</div>;
        },
      },
      {
        Header: "Ärendetyp",
        accessor: "setting.str_representation",
        id: "errandType",
        Cell: ({ value }) => {
          return <ToolTipCell text={value} />;
        },
      },
      {
        Header: "Fel avhjälpt",
        accessor: "active_rating",
        Cell: ({ row }) => {
          const active_rating = row.original?.active_rating;
          const errandStatus = row.original?.status;

          let displayValue = "Inte utfört";
          let displayStatus = 10;
          if ([3, 4].includes(errandStatus) && active_rating) {
            displayStatus = active_rating?.is_ok ? 0 : 7;
            displayValue = active_rating?.is_ok ? "Ja" : "Nej";
          } else if (errandStatus === 1 || errandStatus === 2) {
            displayStatus = 3;
            displayValue = "Inte fastställt";
          }

          return (
            <StatusLabel state={displayStatus}>{displayValue}</StatusLabel>
          );
        },
      },

      {
        Header: "Skapat",
        accessor: "created_at",
        Cell: ({ value }) => <TimeCell date={value} />,
      },
      {
        Header: "Vald utförare",
        accessor: "performer.str_representation",
        Cell: ({ row }) => {
          const performer = getPerformer(row.original);
          if (performer) {
            return (
              <LinkedObject
                obj={{ ...performer, id: performer.userId }}
                urlMethod={userDetailUrl}
              />
            );
          }
          return <div> Ingen utförare delegerad </div>;
        },
      },
      {
        Header: "Förfrågad utförare",
        accessor: "requested_performer.str_representation",
        Cell: ({ row }) => {
          const performer = getRequestedPerformer(row.original);
          if (performer) {
            return (
              <LinkedObject
                obj={{ ...performer, id: performer.userId }}
                urlMethod={userDetailUrl}
              />
            );
          }
          return <div>Ingen utförare förfrågad</div>;
        },
      },
      {
        Header: "Rapporterat av",
        accessor: "reported_by",
        Cell: ({ value }) => {
          return <LinkedObject obj={value} urlMethod={userDetailUrl} />;
        },
      },
      {
        Header: "Planerat utförandedatum",
        accessor: "planned_start",
        Cell: ({ value }) => <TimeCell date={value} />,
      },
      {
        Header: "Utförandedatum",
        accessor: "execute_end",
        Cell: ({ value }) => <TimeCell date={value} />,
      },
      {
        Header: "Adress",
        accessor: "dynamic_address.base",
        Cell: ({ value }) => <ToolTipCell text={value} />,
      },
    ];
    return cols;
  }, []);

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>
            <OverviewSubtitle>
              Sök på ärenden under denna komponenttyp
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <InnerBox>
          <div className="flex w-full space-x-2 items-center">
            <LocalDateSelect
              alwaysOpen
              title="Från"
              value={fromDate}
              onChange={(val) => setFromDate(val)}
            />
            <LocalDateSelect
              alwaysOpen
              title="Till"
              value={toDate}
              onChange={(val) => setToDate(val)}
            />
          </div>

          {diff > 60 && (
            <div className="text-red-700 my-2">
              OBS: Sökningar med ett spann över 60 dagar kan ta lång tid att
              utföra.
            </div>
          )}

          <div className="mt-2">
            <PrimaryBtn disabled={loading} onClick={performSearch}>
              <MagnifyingGlassIcon width={16} className="mr-2" /> Sök bland
              ärenden
            </PrimaryBtn>
          </div>
        </InnerBox>

        {data?.length > 0 && (
          <Table
            extraStyles={{ marginTop: 0 }}
            columns={tableColumns}
            onRowClicked={(row) =>
              push(reportErrandDetailUrl({ id: row.original.id }))
            }
            data={tableData || []}
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
