import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  useStageForm,
  destroyPatchForm,
  destroyPostForm,
  update,
  useStage,
} from "../../../../store/pipeStages";
import { updateActiveFormInstance } from "../../../../store/base";

export default function StageModalForm({
  instance,
  method,
  id,
  isOpen,
  onCheckout,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [lastSet, setLastSet] = React.useState(null);

  const [current] = useStage(id);

  const formLoaded = Boolean(useStageForm(method, id));

  React.useEffect(() => {
    if (method === "PATCH" && !!current && id !== lastSet) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: current,
        })
      );

      setLastSet(id);
    }
  }, [id, current]);

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (!isOpen) {
      setLastSet(null);
    }
  }, [isOpen]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Lägg till steg" : "Uppdatera steg"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
