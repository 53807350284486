import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingBrf";
import { useDispatch } from "react-redux";
import { TextButton } from "../../../Base/Buttons";
import InvoicingSettingDefault from "../../../../Summaries/InvoicingSettingDefault";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useParams } from "react-router";
import { useDebtorInvoicing } from "../../../../../store/invoicingDebtor";
import { useTenant } from "../../../../../store/tenants";
import { useCompany } from "../../../../../store/companies";
import { useCompanyInvoicing } from "../../../../../store/invoicingCompany";
import { cloneDeep } from "lodash";
import { DatePicker, NumberInput, Select, TextInput, AddressField } from "../../../Base/Fields";
import { getIntervalStr } from "../../../../Displays/InvoicingHelpers";
import { useInvoicingSetting } from "../../../../../store/invoicingSettings";
import { InfoBox } from "../../../../Displays";
import { useBrfPremises } from "../../../../../store/brfPremises";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { brfPremisesId } = useParams();

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const deliveryMethod = useFormInstanceField({
    storeName,
    fieldKey: "delivery_method",
  });

  const selectedBillingCompany = useFormInstanceField({
    storeName,
    fieldKey: "billing_company",
  });

  const intervalMode = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.mode",
  });

  const [billingCompany] = useCompany(selectedBillingCompany?.id);
  const [brfPremises] = useBrfPremises(brfPremisesId);
  const [tenant] = useTenant(brfPremises?.tenant?.id);
  const [debtorInvoicingConfig] = useDebtorInvoicing(
    tenant?.debtor_invoice_config?.id
  );
  const [companyInvoicingConfig] = useCompanyInvoicing(
    billingCompany?.invoicing_details?.id
  );

  const [selectedSetting] = useInvoicingSetting(instance?.setting?.id);

  const renderIntervalSetting = () => {
    return (
      <>
        <Select
          title="Typ av faktureringsintervall"
          description={`Typ av intervall för när fakturor ska genereras och skickas. Vid månadsvis fakturering väljs t.ex. "Per månad".`}
          {...{ storeName, method, fieldKey: "interval_setting.mode" }}
        />

        {/* Per month */}
        {intervalMode === 0 && (
          <>
            <NumberInput
              title="Månadsintervall"
              description={`Ange i vilket månadsintervall fakturor ska genereras. Ska en faktura genereras varje månad anges 1, varannan månad 2 osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.interval",
              }}
            />

            <NumberInput
              title="Dag i månad som faktura ska genereras"
              description={`Ange vilken dag i varje månad som en faktura ska genereras upp och skickas.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.day_in_month",
              }}
            />
          </>
        )}

        {/* Quarterly */}
        {intervalMode === 1 && (
          <>
            <NumberInput
              title="Kvartalsintervall"
              description={`Ange i vilket kvartalsintervall fakturor ska genereras. Ska en faktura genereras varje kvartal anges "1", varannat kvartal "2" osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.interval",
              }}
            />

            <NumberInput
              title="Månad i kvartal som faktura ska genereras"
              description={`Ange vilken månaden i kvartalet som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas 1:a månaden kvartalet så anges "1".`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.on_month",
              }}
            />

            <NumberInput
              title="Dag i månad som faktura ska genereras"
              description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.day_in_month",
              }}
            />
          </>
        )}

        {/* yearly */}
        {intervalMode === 2 && (
          <>
            <NumberInput
              title="Årsintervall"
              description={`Ange i vilket årsintervall fakturor ska genereras. Ska en faktura genereras varje år anges "1", varannat år "2" osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.interval",
              }}
            />

            <NumberInput
              title="Månad som faktura ska genereras"
              description={`Ange vilken månaden på året som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas i Januari så anges "1", Juli så anges "7" osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.on_month",
              }}
            />

            <NumberInput
              title="Dag i månad som faktura ska genereras"
              description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.day_in_month",
              }}
            />
          </>
        )}

        <Select
          title="Faktureringsperiod"
          {...{
            storeName,
            method,
            fieldKey: "interval_setting.invoice_period",
          }}
        />
      </>
    );
  };

  return (
    <>
      <FormAreaTitle>Specifika inställningar för bostadsrätten</FormAreaTitle>

      {deliveryMethod != null ||
      (debtorInvoicingConfig?.delivery_method == null &&
        companyInvoicingConfig?.delivery_method == null) ? (
        <>
          {(debtorInvoicingConfig?.delivery_method != null ||
            companyInvoicingConfig?.delivery_method != null) && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.delivery_method = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <Select
            title="Leveransmetod"
            {...{ storeName, method, fieldKey: "delivery_method" }}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Leveransmetod"
          sourceTitle={
            debtorInvoicingConfig?.delivery_method != null
              ? "Från hyresgästens inställningar"
              : "Från valt bolag"
          }
          content={[
            {
              title: "Leveransmetod",
              content:
                debtorInvoicingConfig?.delivery_method_display ||
                companyInvoicingConfig?.delivery_method_display,
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  delivery_method: "Email",
                },
              })
            );
          }}
        />
      )}

      <DatePicker
        title="Startdatum för avisering"
        description='Om inget startdatum väljs kommer startdatumet på avtalet att användas. Vid "kommande" period bör en aviseringsperiod innan startdatumet på avtalet väljas, vid "innevarande" bör de två datumen matcha varandra och på "föregående" bör startdatumet vara en period efter startdatumet på avtalet.'
        {...{ storeName, method, fieldKey: "start_date" }}
      />

      <hr />

      {intervalMode != null || selectedSetting?.interval_setting == null ? (
        <>
          {selectedSetting?.interval_setting != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.interval_setting = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          {renderIntervalSetting()}
        </>
      ) : (
        <InvoicingSettingDefault
          title="Faktureringsintervall"
          content={[
            {
              title: "Intervall",
              content: getIntervalStr(selectedSetting?.interval_setting),
            },
            {
              title: "Faktureringsperiod",
              content:
                selectedSetting?.interval_setting?.invoice_period_display,
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  "interval_setting.mode": 0,
                },
              })
            );
          }}
        />
      )}
      <hr />
      <TextInput
        storeName={storeName}
        fieldKey={"override_email_address"}
        title="Specifik E-mail för leverans"
        method={method}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"override_care_of"}
        title="Specifik C/O"
        method={method}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"override_attention"}
        title="Specifik Attention"
        method={method}
      />
      <AddressField
        title="Specifik Fakturaaddress"
        storeName={storeName}
        method={method}
        fieldKey={"override_mail_address"}
      />
    </>
  );
};
