import * as React from "react";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import Alert from "src/components/Alert/Alert";
import { PrimaryButton } from "src/components/Forms/Base/Buttons";
import { useHistory } from "react-router-dom";

export default function IndustrialPremisesCancellations() {
  const history = useHistory();

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <Alert title="Flyttad modul">
          <p className="mb-6">
            Utflyttsöversikten har flyttats till rapporten "In och
            Utflyttningslista"
          </p>

          <PrimaryButton
            title="Gå till rapporten"
            clicked={() => history.push("/reports/table-view/evictions_simple")}
          />
        </Alert>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
