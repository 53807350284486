import * as React from "react";
import { useHistory, useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import ProductForm from "../../../components/Forms/InvoicingProduct/ChapterForm/ModalForm";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import {
  useInvoicingProduct,
  constants,
  detailUrl,
} from "../../../store/invoicingProducts";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import StandardModal from "../../../components/Modals/StandardModal";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import CompanyTable from "../../../components/Tables/Companies/FullTable";
import { LinkedObject } from "../../../components/Displays";

function InvoicingProduct() {
  const { productId } = useParams();

  const [editOpen, setEditOpen] = React.useState(false);
  const [product] = useInvoicingProduct(productId);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [cantDeleteInfoOpen, setCantDeleteInfoOpen] = React.useState(false);
  const { replace } = useHistory();
  const companyQuery = buildQueryString({
    id__in: product?.companies?.map((c) => c.id) || [],
  });

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      product: productId,
    },
  });

  const errorQuery = {
    product: productId,
  };

  const detailInfo = {
    "Information om produkt": [
      {
        title: "Titel",
        value: product?.title,
      },
      {
        title: "Enhet",
        value: product?.unit,
      },
      {
        title: "Kategori",
        value: product?.category_display,
      },
      {
        title: "Intäktskonto",
        value: product?.account_no,
      },
      {
        title: "Standard momssats",
        value: product?.vat != null ? `${product.vat}%` : "-",
      },
      {
        title: "Utgående moms",
        value: product?.account_no_vat,
      },
      {
        title: "Konto försäljning EU",
        value: product?.account_no_eu,
      },
      {
        title: "Konto försäljning EU moms",
        value: product?.account_no_eu_vat,
      },
      {
        title: "Konto köp",
        value: product?.account_no_purchase,
      },
      {
        title: "Konto köp moms",
        value: product?.account_no_purchase_vat,
      },
      {
        title: "Konto försäljning icke-EU",
        value: product?.account_no_non_eu,
      },
      {
        title: "Ersätts vid vakansbokning med",
        value: (
          <LinkedObject
            obj={product?.vacancy_booking_product}
            urlMethd={detailUrl}
          />
        ),
      },
    ],
  };

  return (
    <>
      <StandardModal
        withActionBar
        title="Kan ej radera produkt"
        isOpen={cantDeleteInfoOpen}
        actionBarCancelTitle="Stäng"
        closeFunction={() => setCantDeleteInfoOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Denna produkt kan ej raderas då den används
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          Produkter som används kan ej raderas av bokföringsskäl.
        </BodyText>
      </StandardModal>

      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={product}
        constants={constants}
        errorCallback={() => setCantDeleteInfoOpen(true)}
        deletedCallback={() =>
          replace("/rental-notifications/invoicing-objects")
        }
      />

      <ProductForm
        method="PATCH"
        id={product?.id}
        instance={product}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Produkt ${product?.str_representation || "Laddar..."}`}
          breadCrumbs={[
            {
              url: "/rental-notifications/invoicing-objects",
              label: "Produkter & Bokföring",
            },
            { label: `Produkt ${product?.str_representation || "Laddar..."}` },
          ]}
        />

        <DetailInnerWrapper>
          {errorCount > 0 && (
            <DetailPageBox error>
              <OverviewTitleWrapper>
                <OverviewTitle>Felmeddelanden</OverviewTitle>
              </OverviewTitleWrapper>

              <InvoicingErrorTable
                isBare
                ignoreLocalStorage
                persistantQuery={errorQuery}
              />
            </DetailPageBox>
          )}

          <DetailPageBoxFlexWrapper>
            <DetailPageBox style={{ width: "59%", alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>

                <PopupButton
                  leftAligned
                  title="Hantera"
                  actions={[
                    {
                      name: "Redigera",
                      onClick: () => setEditOpen(true),
                    },
                    {
                      name: "Radera",
                      isDelete: true,
                      onClick: () => setDeleteOpen(true),
                    },
                  ]}
                />
              </OverviewTitleWrapper>

              <DetailInfo infoObj={detailInfo} />
            </DetailPageBox>

            <DetailPageBox style={{ width: "40%", alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitle small>Används i bolag</OverviewTitle>
              </OverviewTitleWrapper>

              <CompanyTable
                persistantFilterMethod={(obj) =>
                  product?.companies?.map((c) => c.id)?.includes(obj.id)
                }
                persistantQueryString={companyQuery}
                hideTitle
                hideExport
                hideFilters
                hideSearch
                hideColumns
                hideSelect
              />
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}

export default InvoicingProduct;
