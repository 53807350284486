import * as React from "react";
import * as SC from "./styles";

import Select from "react-select";

export default function Pagination({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) {
  return (
    <SC.PaginationWrapper>
      <span>
        Sida{" "}
        <strong>
          {pageIndex + 1} av {pageOptions.length}
        </strong>{" "}
      </span>
      <SC.ButtonWrapper>
        <SC.PaginationButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </SC.PaginationButton>
        <SC.PaginationButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </SC.PaginationButton>
        <SC.PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </SC.PaginationButton>
        <SC.PaginationButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </SC.PaginationButton>
      </SC.ButtonWrapper>
      <span>
        Gå till sida:{" "}
        <SC.PaginationInput
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
      </span>{" "}
      <SC.SelectWrapper>
        <Select
          style={{ width: "120px" }}
          value={{ label: `Visa ${pageSize}`, value: pageSize }}
          menuPlacement="top"
          onChange={(v) => {
            setPageSize(Number(v.value));
          }}
          options={[10, 20, 30, 40, 50, 100, 500, 1000, 5000].map(
            (pageSize) => ({
              label: `Visa ${pageSize}`,
              value: pageSize,
            })
          )}
        />
      </SC.SelectWrapper>
    </SC.PaginationWrapper>
  );
}
