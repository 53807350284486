import * as React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as SC from "./styles";

import {
  searchSuggestions,
  fetchInfoTraderData,
} from "../../store/infoTrader/actions";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import { PrimaryButton, SecondaryButton } from "../Forms/Base/Buttons";
import NonConnectedCheckbox from "../Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import NonConnectedInputField from "../Forms/Base/Old/NonConnected/NonConnectedInputField";
import { addToast, TOAST_TYPES } from "../../store/toasts";

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export default ({ setRenderForm }) => {
  const dispatch = useDispatch();
  const suggestions = useSelector(
    (state) => state.infoTrader.suggestionSearchResults
  );
  const infoTraderDataLoading = useSelector(
    (state) => state.infoTrader.infoTraderDataLoading
  );

  const [inputValue, setInputValue] = React.useState("");
  const [hasApartments, setHasApartments] = React.useState(false);
  const [renderSuggestions, setRenderSuggestions] = React.useState(false);
  const [infoTraderFetchAllowed, setInfoTraderFetchAllowed] =
    React.useState(false);

  React.useEffect(() => {
    if (inputValue.length > 3) {
      performSuggestionSearch(inputValue);
    }
  }, [inputValue]);

  const performSuggestionSearch = React.useCallback(
    debounce((searchTerm) => dispatch(searchSuggestions(searchTerm)), 300),
    []
  );

  const onInputValueChanged = ({ target: { value } }) => {
    setInputValue(value);
    setRenderSuggestions(true);
    setInfoTraderFetchAllowed(false);
  };
  const onHasApartmentsChanged = (checked) => {
    setHasApartments(checked);
  };

  const onSuggestionClicked = ({ suggestion }) => {
    setInputValue(suggestion.text);
    setRenderSuggestions(false);

    if (suggestion.text.split(" ").length > 1) {
      setInfoTraderFetchAllowed(true);
    }
  };

  const handleFetchInfoTraderData = () => {
    const municipality = inputValue.split(" ")[0];
    const key = inputValue.split(" ").slice(1).join(" ");
    dispatch(
      fetchInfoTraderData({
        municipality,
        key,
        hasApartments,
        successCallback: () => setRenderForm(),
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Datan kunde inte hämtas",
              description: "Försök igen eller ange datan manuellt",
            })
          );
        },
      })
    );
  };

  const mappedSuggestions = suggestions?.map((s) => ({
    id: s,
    text: s,
  }));

  const Description = (
    <SC.FieldDescription>
      Här anger du beteckning, kommun, kvarter och enhet för fastigheten i
      formatet:
      <br />
      <strong>KOMMUN FASTIGHETSBETECKNING KVARTER:ENHET</strong>
      <br /> när du börjar skriva kommer förslag upp automatiskt.
    </SC.FieldDescription>
  );

  return (
    <>
      {infoTraderDataLoading && <OverlaySpinner />}
      <SC.Content>
        <SC.InfoTraderTitle>Grundläggande information</SC.InfoTraderTitle>
        <SC.InfoTraderSubtitle>
          Välj om du vill hämta data automatiskt med hjälp av fastighetens
          beteckning eller fylla i manuellt.
        </SC.InfoTraderSubtitle>

        <SC.InputsWrapper>
          <NonConnectedInputField
            id="real-estate-input"
            title="Fastighetsbeteckning"
            renderDescription={Description}
            onChange={onInputValueChanged}
            value={inputValue}
            renderSuggestions={renderSuggestions && inputValue.length > 3}
            suggestions={mappedSuggestions}
            handleSuggestionClicked={onSuggestionClicked}
          />
          <NonConnectedCheckbox
            id="has-apartments"
            value={hasApartments}
            onChange={onHasApartmentsChanged}
            title="Fastigheten innehåller hyreslägenheter"
          />
        </SC.InputsWrapper>

        <SC.ButtonWrapper>
          <PrimaryButton
            extraStyle={{ marginRight: "12px" }}
            title="Hämta information"
            disabled={!infoTraderFetchAllowed}
            clicked={handleFetchInfoTraderData}
          />
          <SecondaryButton
            title="Ange manuellt"
            clicked={() => {
              setRenderForm();
            }}
            disabled={false}
          />
        </SC.ButtonWrapper>
      </SC.Content>
    </>
  );
};
