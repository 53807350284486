import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  useSendoutForm,
  constants,
  create,
  update,
  destroyPatchForm,
  destroyPostForm,
} from "../../../../store/sendout";
import { constants as userConstants } from "../../../../store/users";
import { clearFetched, updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";

export default ({ method, id, isOpen, onCheckout, instance }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useSendoutForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));

      // clear users, new tenant = new user but cache does not know
      dispatch(clearFetched(userConstants));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout(success);
  };

  const onSuccess = (user, returned) => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!dataClone.realestates) {
      dataClone.realestates = [];
    }
    if (!dataClone.buildings) {
      dataClone.buildings = [];
    }
    if (!dataClone.recipients) {
      dataClone.recipients = [];
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs({})}
      onRenderChapter={(key) => renderChapter({ key, method })}
      submitCallback={onSubmit}
      title={method === "POST" ? "Skapa nytt utskick" : "Uppdatera utskick"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
