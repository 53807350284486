import * as React from "react";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../../Forms/Base/Buttons";

export default ({ handleSelectPremisesType, OPTIONS }) => {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Koppla rum till valda ytor</OverviewTitle>
          <OverviewSubtitle>
            Välj vilken typ av yta du vill koppla rummen till.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="flex flex-col">
        <TextButton
          title="Lägenhet"
          clicked={() => handleSelectPremisesType(OPTIONS.APARTMENT)}
          iconType="arrow"
          iconPlacement="right"
        />
        <TextButton
          extraStyle={{ marginTop: 12 }}
          title="Lokal"
          clicked={() => handleSelectPremisesType(OPTIONS.INDUSTRIAL_PREMISES)}
          iconType="arrow"
          iconPlacement="right"
        />
        <TextButton
          extraStyle={{ marginTop: 12 }}
          title="Gemensamt utrymme"
          clicked={() => handleSelectPremisesType(OPTIONS.COMMON_AREA)}
          iconType="arrow"
          iconPlacement="right"
        />
      </div>
    </>
  );
};
