import * as React from "react";
import { useHistory } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString, usePermissionCheck } from "../../../store/base";

import SensorTable from "../../../components/Tables/Sensors/FullTable";
import { InfoBox } from "../../../components/Displays";
import { handleUrl, useSensorPaginationCount } from "../../../store/IMDSensor";

export default function IMDSensors() {
  const { push } = useHistory();

  const { filteredRealEstates } = useActiveFilters();
  const persistantQuery = {
    realestate__in: filteredRealEstates,
  };

  const [nonConnectedCount] = useSensorPaginationCount({
    filters: {
      apartment__isnull: true,
      industrial_premises__isnull: true,
      parking_spot__isnull: true,
      brf_premis__isnull: true,
      common_area__isnull: true,
    },
  });

  const canChangeImd = usePermissionCheck("change_can_imd");

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <InfoBox
          title="Skapande av sensorer"
          renderText={() => (
            <div>
              <div>
                Vid tillägg av sensorer försöker Pigello automatiskt att matcha
                sensorerna mot de objekt som de tillhör. Om systemet inte lyckas
                koppla ihop sensorerna automatiskt så måste en manuell koppling
                göras, detta görs genom att trycka på "Hantera ej matchade
                sensorer" nedan till höger.
              </div>
              <br />
              <div>
                <strong>OBS - Vid integration mot leverantör av mätdata</strong>
                <br />
                Sensorer för IMD läggs automatiskt till i Pigello vid import av
                mätvärden eller integration mot leverantörer av mätdata. Dessa
                sensorer måste dock matchas manuellt mot rätt objekt första
                gången de skapas upp.
              </div>
            </div>
          )}
        />

        <OverviewTitleWrapper style={{ marginTop: 12 }}>
          <OverviewTitle>IMD Sensorer</OverviewTitle>

          {canChangeImd && nonConnectedCount > 0 && (
            <PrimaryButton
              title={`Hantera ${nonConnectedCount || 0} ej matchade sensorer`}
              clicked={() => push(handleUrl())}
            />
          )}
        </OverviewTitleWrapper>

        <SensorTable {...{ persistantQuery }} allowEdit={true} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
