import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import PipeModalForm from "../../../components/Forms/Pipe/ChapterForm/ModalForm";
import PipeList from "../../../components/Market/PipeList/PipeList";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import usePermissionRedirect from "../../../hooks/usePermissionRedirect";

import { useFilteredPipes, constants } from "../../../store/pipes";
import { useFilteredStages } from "../../../store/pipeStages";
import { useLeadPerStageCount } from "../../../store/leads";

export default function PipeOverview() {
  const [open, setOpen] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState(null);

  const [pipes, pipesLoading] = useFilteredPipes("");
  const [leadsPerStageData, leadsPerStageDataLoading] = useLeadPerStageCount();

  const stagesQ = buildQueryString({
    pipe__in: pipes?.map((p) => p.id),
  });
  const [stages] = useFilteredStages(stagesQ);

  usePermissionRedirect(["view_can_market", "view_can_lead"]);

  const canCreatePipe = useAllPermissionCheck([
    "add_can_market",
    "add_can_lead",
  ]);
  const canEditPipe = useAllPermissionCheck([
    "change_can_market",
    "change_can_lead",
  ]);
  const canDeletePipe = useAllPermissionCheck([
    "delete_can_market",
    "delete_can_lead",
  ]);

  return (
    <>
      {canCreatePipe && (
        <PipeModalForm
          method="POST"
          isOpen={open}
          onCheckout={() => setOpen(false)}
        />
      )}

      {canEditPipe && (
        <PipeModalForm
          method="PATCH"
          id={editId}
          isOpen={!!editId}
          onCheckout={() => setEditId(null)}
        />
      )}

      {canDeletePipe && (
        <DeleteModal
          isOpen={deleteId}
          instance={pipes?.find((p) => p.id === deleteId)}
          constants={constants}
          closeFunction={() => setDeleteId(null)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Projekt</OverviewTitle>
              <OverviewSubtitle>
                Projekt för hantering av inkommna leads
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            {canCreatePipe && (
              <PrimaryButton
                title="Skapa nytt projekt"
                clicked={() => setOpen(true)}
              />
            )}
          </OverviewTitleWrapper>

          <PipeList
            pipes={pipes}
            stages={stages}
            leadsPerStageData={leadsPerStageData}
            onEdit={setEditId}
            onDelete={setDeleteId}
            {...{ canEditPipe, canDeletePipe }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
