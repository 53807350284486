export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_KEYPERMISSION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_KEYPERMISSION",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_KEYPERMISSION",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_KEYPERMISSION",
  DESTROY_FORM: "DESTROY_FORM_KEYPERMISSION",
  SET_FORM_ERROR: "SET_FORM_ERROR_KEYPERMISSION",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_KEYPERMISSION",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_KEYPERMISSION",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_KEYPERMISSION",
  RESET_STATE: "RESET_STATE_KEYPERMISSION",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_KEYPERMISSION",
  REMOVE_OBJECT: "REMOVE_OBJECT_KEYPERMISSION",
  CLEAR_FETCHED: "CLEAR_FETCHED_KEYPERMISSION",

  LIST_URL: "/keyhandling/keypermission/list/",
  POST_URL: "/keyhandling/keypermission/",
  PATCH_URL: "/keyhandling/keypermission/",
  GET_URL: "/keyhandling/keypermission/",

  PARAKEY_CONVERT_URL: "/keyhandling/parakey/keypermission/convert/",

  STORE_NAME: "keypermission",
};
