import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import * as SC from "../../styles";
import Profile from "../../../../../views/Account/Profile";
import EditMyUser from "../../../../../views/Account/EditMyUser";
import ResetMyPassword from "../../../../../views/Account/ResetMyPassword";
import ProfileGroups from "../../../../../views/Account/ProfileGroups";
import Notifications from "../../../../../views/Account/Notifications";
import NotificationSettings from "../../../../../views/Account/NotificationSettings";

export default () => {
  const { path, url } = useRouteMatch();

  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  const SUB_PAGES = [
    {
      path: "/user/edit/:userId",
      component: EditMyUser,
    },
    {
      path: "/user/reset-password",
      component: ResetMyPassword,
    },
    {
      path: "/user",
      component: Profile,
    },
    {
      path: "/groups",
      component: ProfileGroups,
    },
    {
      path: "/notifications/settings",
      component: NotificationSettings,
    },
    {
      path: "/notifications",
      component: Notifications,
    },
  ];

  return (
    <SC.ConfigCenterDetailWrapper>
      <SC.ConfigCenterSideBarDetailWrapper>
        <SC.DetailPageBox>
          <SC.DetailPageItem
            active={getIsActive("/configcenter/profile/user", false)}
            to={"/configcenter/profile/user"}
          >
           Preferenser 
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={getIsActive("/configcenter/profile/groups", false)}
            to={"/configcenter/profile/groups"}
          >
            Behörigheter
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={getIsActive("/configcenter/profile/notifications", false)}
            to={"/configcenter/profile/notifications"}
          >
            Notifikationer
          </SC.DetailPageItem>
        </SC.DetailPageBox>
      </SC.ConfigCenterSideBarDetailWrapper>

      <SC.ConfigCenterDetailElement>
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              exact={sp.exact}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[2].path}`} path={`${path}`} />
        </Switch>
      </SC.ConfigCenterDetailElement>
    </SC.ConfigCenterDetailWrapper>
  );
};
