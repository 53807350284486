import { DocumentPlusIcon, SparklesIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAllPermissionCheck } from "../../../store/base";
import { axiosInstance } from "../../../store/base/store/axios";
import Alert from "../../Alert/Alert";
import { TextButton } from "../../Forms/Base/Buttons";
import DigitalDocTemplateContainer from "./TemplateContainer";
import DigitalDocUploadDocument from "./UploadDocument";

export const STEPS = {
  SELECT_TYPE: "SELECT_TYPE",
  DOCUMENT: "DOCUMENT",
  TEMPLATES: "TEMPLATES",
};

export default function DigitalDocTemplateSelector({
  method,
  storeName,
  contract,
  detailUrl,
  isCancelledDoc,
  usesESigning,
  isTemplateEditor,
}) {
  const { goBack, push } = useHistory();

  const hasFastDocPermission = useAllPermissionCheck([
    "allow_fastdoc",
    "view_can_fastdoc",
  ]);

  const [isFastdocAuthed, setIsFastdocAuthed] = React.useState(null);
  const [step, setStep] = React.useState(STEPS.SELECT_TYPE);

  const checkIsFastdocAuthed = async () => {
    if (!hasFastDocPermission) {
      return false;
    }
    try {
      const result = await axiosInstance.get(
        "external/fastdoc/check_activated/"
      );
      const activated = result?.data?.is_activated ?? null;
      setIsFastdocAuthed(activated);
    } catch (err) {
      setIsFastdocAuthed(false);
    }
  };

  React.useEffect(() => {
    if (isFastdocAuthed == null) {
      checkIsFastdocAuthed();
    }
  }, [hasFastDocPermission]);

  if (step === STEPS.DOCUMENT) {
    return (
      <DigitalDocUploadDocument
        cancel={() => setStep(STEPS.SELECT_TYPE)}
        method={method}
        storeName={storeName}
      />
    );
  }

  if (step === STEPS.TEMPLATES) {
    return (
      <DigitalDocTemplateContainer
        hasFastDoc={isFastdocAuthed}
        cancel={() => setStep(STEPS.SELECT_TYPE)}
        storeName={storeName}
        usesESigning={usesESigning}
      />
    );
  }

  return (
    <div className="max-w-[1000px] w-full mx-auto pt-12 flex flex-col px-12">
      {isTemplateEditor && (
        <TextButton
          title="Avbryt och gå tillbaka"
          clicked={goBack}
          iconType="arrow-back"
        />
      )}

      {!isTemplateEditor && contract && (
        <Alert
          title={`Digitalt dokument för avtal ${contract?.str_representation}`}
          secondaryAction={() => push(detailUrl({ id: contract.id }))}
          secondaryTitle="Sätt upp det digitala dokumentet senare och gå till avtal"
        >
          Detta avtal är markerat för att signeras med{" "}
          <strong>
            {contract?.uses_e_signing
              ? "Scrive"
              : `Manuell signering${
                  hasFastDocPermission ? "/Fastighetsägarna Dokument" : ""
                }`}
          </strong>
          . Välj en mall att utgå ifrån eller ladda upp ett dokument och placera
          ut fält.
        </Alert>
      )}

      <h1 className="font-medium text-2xl mt-6">
        {isTemplateEditor
          ? method === "POST"
            ? "Skapa avtalsmall"
            : "Uppdatera avtalsmall"
          : "Hantera digitalt dokument"}
      </h1>

      <div>
        Välj om dokumentet ska utgå ifrån en mall eller om ett dokument ska
        laddas upp.
      </div>

      <div className="grid grid-cols-2 gap-6 max-w-[600px] margin-auto w-full mt-12">
        <div
          onClick={() => setStep(STEPS.TEMPLATES)}
          className="cursor-pointer transition-all duration-200 ease-in-out rounded text-primaryblue border-2 border-solid border-primaryblue shadow-lg flex flex-col justify-center items-center min-h-[150px] font-semibold hover:bg-primaryblue hover:text-white"
        >
          <SparklesIcon width={52} className="mb-4" />
          Utgå ifrån en mall
        </div>
        <div
          onClick={() => setStep(STEPS.DOCUMENT)}
          className="cursor-pointer transition-all duration-200 ease-in-out rounded text-slate-700 border-2 border-solid border-slate-700 shadow-lg  flex flex-col justify-center items-center min-h-[150px] font-semibold hover:bg-slate-700 hover:text-white"
        >
          <DocumentPlusIcon width={52} className="mb-4" />
          Ladda upp dokument
        </div>
      </div>
    </div>
  );
}
