import {
    get,
    getPagination,
    getAllFetchProcessName,
    getFiltered,
    getSingleFetchProcessName
} from "../../base";
import constants from "./constants";

export const getAll = () => {
    return get({url:constants.LIST_URL, constants, name:getAllFetchProcessName()});
}

export const getSingle = (id) => {
    const url = `${constants.GET_URL}${id}`;
    return get({url, constants, name:getSingleFetchProcessName(id)})
}

export const performFilter = (querystring, callback) => {
    const url = `${constants.LIST_URL}?${querystring}`;
    return getFiltered({url, constants, querystring, callback});
}

export const filterPagination = (querystring) => {
    const url = `${constants.LIST_URL}?${querystring}`;
    return getPagination({url, constants, querystring});
}
