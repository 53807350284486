import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, TimeCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import ErrandIdTextFilter from "src/components/Lists/Base/CompleteList/Filters/ErrandIdTextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as industrialDetailUrl } from "../../../store/industrialPremises";
import { ErrandStatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import {
  getErrandStatus,
  getPerformer,
  getRequestedPerformer,
  getReportErrandCost,
} from "../../utils/errandUtils";
import { detailUrl as commonDetailUrl } from "../../../store/commonAreas";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { detailUrl as brfPremisDetailUrl } from "../../../store/brfPremises";
import { useInspectionErrandSetting } from "../../../store/inspectionErrandSettings";
import { detailUrl as userDetailUrl } from "../../../store/users";

export default [
  {
    Header: "Status",
    // accessor: "status",
    id: "errand_status",
    Cell: ({ row }) => {
      const { status, display } = getErrandStatus({
        status: row.original?.status,
        plannedStart: row.original?.planned_start,
        plannedEnd: row.original?.planned_end,
        actualStart: row.original?.execute_start,
        actualEnd: row.original?.execute_end,
      });
      return <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>;
    },
    Filter: StateFilter,
    filter: "textExact",
  },
  {
    Header: "Ärendenummer",
    accessor: "errand_id",
    id: "id",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: ErrandIdTextFilter,
    filter: "text",
  },
  {
    Header: "Titel",
    accessor: "title",
    Cell: ({ row }) => {
      const value = row.original.str_representation
        ? row.original.str_representation
        : "-";
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Ärendetyp",
    accessor: "setting",
    Cell: ({ value }) => {
      return <ToolTipCell text={value?.str_representation} />;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Objekt",
    id: "connected_to",
    Cell: ({ row }) => {
      if (row.original.apartment) {
        return (
          <LinkedObject
            obj={row.original.apartment}
            urlMethod={apartmentDetailUrl}
          />
        );
      }
      if (row.original.industrial_premises) {
        return (
          <LinkedObject
            obj={row.original.industrial_premises}
            urlMethod={industrialDetailUrl}
          />
        );
      }
      if (row.original.common_area) {
        return (
          <LinkedObject
            obj={row.original.common_area}
            urlMethod={commonDetailUrl}
          />
        );
      }
      if (row.original.parking_spot) {
        return (
          <LinkedObject
            obj={row.original.parking_spot}
            urlMethod={parkingDetailUrl}
          />
        );
      }
      if (row.original.brf_premis) {
        return (
          <LinkedObject
            obj={row.original.brf_premis}
            urlMethod={brfPremisDetailUrl}
          />
        );
      }

      return <div>-</div>;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Objektstyp",
    id: "connected_to_type",
    Cell: ({ row }) => {
      if (row.original.apartment) {
        return <div>Lägenhet</div>;
      }
      if (row.original.industrial_premises) {
        return <div>Lokal</div>;
      }
      if (row.original.common_area) {
        return <div>Gemensamt utrymme</div>;
      }
      if (row.original.parking_spot) {
        return <div>Fordonsplats</div>;
      }
      if (row.original.brf_premis) {
        return <div>Bostadsrätt</div>;
      }

      return <div>-</div>;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Skapat",
    accessor: "created_at",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Vald utförare",
    accessor: "performer.str_representation",
    Cell: ({ row }) => {
      const performer = getPerformer(row.original);
      if (performer) {
        return (
          <LinkedObject
            obj={{ ...performer, id: performer?.userId }}
            urlMethod={userDetailUrl}
          />
        );
      }
      return <div> Ingen utförare delegerad </div>;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Förfrågad utförare",
    accessor: "requested_performer.str_representation",
    Cell: ({ row }) => {
      const performer = getRequestedPerformer(row.original);
      if (performer) {
        return (
          <LinkedObject
            obj={{ ...performer, id: performer?.userId }}
            urlMethod={userDetailUrl}
          />
        );
      }
      return <div>Ingen utförare förfrågad</div>;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Initierat av",
    accessor: "reported_by",
    Cell: ({ value }) => {
      return <LinkedObject obj={value} urlMethod={userDetailUrl} />;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },

  {
    Header: "Planerat utförandedatum",
    accessor: "planned_start",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Utförandedatum",
    accessor: "execute_end",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Adress",
    accessor: "dynamic_address.base",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
];

//Exporting columDefs as funciton to play nicely with detailPerms logic (components/Details/Account/UserGroups/WhiteListTable.js)

export const inspectionErrandColumnDefs = () => {
  return [
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const { status, display } = getErrandStatus({
          status: row.original?.status,
          plannedStart: row.original?.planned_start,
          plannedEnd: row.original?.planned_end,
          actualStart: row.original?.execute_start,
          actualEnd: row.original?.execute_end,
        });
        return <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Ärendenummer",
      accessor: "errand_id",
      id: "id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: ErrandIdTextFilter,
      filter: "text",
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ row }) => {
        const value = row.original.str_representation
          ? row.original.str_representation
          : "-";
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Ärendetyp",
      accessor: "setting",
      Cell: ({ value }) => {
        const [setting] = useInspectionErrandSetting(value?.id);
        return <ToolTipCell text={setting?.str_representation} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Skapat",
      accessor: "created_at",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Vald utförare",
      accessor: "performer.str_representation",
      Cell: ({ row }) => {
        const performer = getPerformer(row.original);
        if (performer) {
          return (
            <LinkedObject
              obj={{ ...performer, id: performer?.userId }}
              urlMethod={userDetailUrl}
            />
          );
        }
        return <div> Ingen utförare delegerad </div>;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Requested performer",
      accessor: "requested_performer.str_representation",
      Cell: ({ row }) => {
        const performer = getRequestedPerformer(row.original);
        if (performer) {
          return (
            <LinkedObject
              obj={{ ...performer, id: performer?.userId }}
              urlMethod={userDetailUrl}
            />
          );
        }
        return <div> Ingen utförare delegerad </div>;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Planerat utförandedatum",
      accessor: "planned_start",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Utförandedatum",
      accessor: "execute_end",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
  ];
};
