import * as React from "react";
import moment from "moment";
import * as SC from "./styles";

export default ({ date, shadowDate }) => {
  if (!date) {
    return <>-</>;
  }

  if (!shadowDate) {
    return (
      <SC.DateCell>{moment(date).format("YYYY-MM-DD, HH:mm")}</SC.DateCell>
    );
  }

  return (
    <>
      <SC.DateCell>{moment(date).format("YYYY-MM-DD, HH:mm")}</SC.DateCell>
      <span> ({moment(shadowDate).format("YYYY-MM-DD, HH:mm")})</span>
    </>
  );
};
