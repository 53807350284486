import * as React from "react";

import { useRouteMatch, Redirect, Route, Switch } from "react-router-dom";

import * as SC from "../../styles";

import MyPages from "../../../../../views/Tenant/overview/MyPages";
import TenantPortalOverview from "../../../../../views/Account/TenantPortalOverview";

export default () => {
  const { path, url } = useRouteMatch();

  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  const SUB_PAGES = [
    {
      path: "/detail/:id",
      component: TenantPortalOverview,
    },
    {
      path: "/overview",
      component: MyPages,
    },
  ];
  return (
    <SC.ConfigCenterDetailWrapper>
      <SC.ConfigCenterSideBarDetailWrapper>
        <SC.DetailPageBox>
          <SC.DetailPageItem
            active={getIsActive("/configcenter/tenant-portal/", false)}
            to={"/configcenter/tenant-portal/overview"}
          >
            Inställningar
          </SC.DetailPageItem>
        </SC.DetailPageBox>
      </SC.ConfigCenterSideBarDetailWrapper>
      <SC.ConfigCenterDetailElement>
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              exact={sp.exact}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[1].path}`} path={`${path}`} />
        </Switch>
      </SC.ConfigCenterDetailElement>
    </SC.ConfigCenterDetailWrapper>
  );
};
