import * as React from "react";
import { useDispatch } from "react-redux";

import {
  constants,
  create,
  useBrfPledgeForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/brfPledges";
import { clearFetchedData } from "../../../../store/brfPremises";
import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TableSelectField from "../../Base/Fields/TableSelectField";
import BrfOwnerTable from "src/components/Tables/BrfOwner/FullTable";
import TextInputField from "../../Base/Fields/TextInputField";
import DateSelect from "../../Base/Fields/DateSelect";
import MediaField from "../../Base/Fields/MediaField";
import { InnerBox } from "src/components/sharedStyles";
import { useFilteredBrfContracts } from "src/store/brfContract";
import { useParams } from "react-router-dom";

export default function BrfPledgeModalForm({
  method,
  id,
  instance,
  onCheckout,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useBrfPledgeForm(method, id));

  const { brfPremisesId } = useParams();

  const brfContractQuery = buildQueryString({
    brf_premis: brfPremisesId,
  });

  const [brfContracts] = useFilteredBrfContracts(brfContractQuery);

  const ownerIds = brfContracts
    ?.map((c) => {
      const ids = [];

      if (c.owner?.id) {
        ids.push(c.owner.id);
      }

      if (c.bi_owners?.length) {
        c.bi_owners.forEach((bo) => {
          ids.push(bo.id);
        });
      }

      return ids;
    })
    .flat();

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
      dispatch(clearFetchedData(true));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={
        method === "POST" ? "Lägg till pansättnign" : "Uppdatera pantsättning"
      }
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">
        Ange detaljer om pantsättningen
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={constants.STORE_NAME}
          fieldKey={"owner"}
          persistantQuery={{
            id__in: ownerIds,
          }}
          method={method}
          title="Ansvarig ägare"
          placeholder={"Välj ansvarig ägare..."}
          TableComponent={BrfOwnerTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Referensnummer hos banken till pantsättningen"
          {...{ method, storeName, fieldKey: "reference_number" }}
        />

        <TextInputField
          isNumber
          step=".01"
          title="Värde på pantsättningen"
          {...{ method, storeName, fieldKey: "value" }}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          title="Gäller från"
          {...{ method, storeName, fieldKey: "from_date" }}
        />

        <DateSelect
          extraStyle={{ marginLeft: 12 }}
          title="Registeringsdatum"
          {...{ method, storeName, fieldKey: "register_date" }}
        />
      </div>

      <InnerBox style={{ marginBottom: 24 }}>
        <div className="mb-6 text-base font-medium">Panthavare</div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            title="Ange bank eller övrig panthavare som bostadsrätten är pantsatt hos"
            {...{ storeName, method, fieldKey: "contact.title" }}
          />

          <TextInputField
            {...{ storeName, method }}
            fieldKey={`contact.name`}
            title="Namn på kontaktperson"
          />

          <TextInputField
            {...{ storeName, method }}
            fieldKey={`contact.phone`}
            title="Telefonnummer till kontaktperson på bank"
          />

          <TextInputField
            {...{ storeName, method }}
            fieldKey={`contact.email`}
            title="E-post till kontaktperson på bank"
          />
        </div>
      </InnerBox>

      <MediaField
        title="Pantnotering"
        description="Ladda upp dokumentet för pantnoteringen om det finns ett sådant"
        storeName={storeName}
        allowedFormats={[".pdf"]}
        fieldKey={"doc"}
        method={method}
      />
    </Modal>
  );
}
