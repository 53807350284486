import * as React from "react";
import { TextInput } from "../Base/Fields";

export default function ComponentTypeNestedChildren({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  return (
    <>
      <TextInput
        title="Namn"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.name`,
          instructionsKey: `${parentInstructionsPath}.name`,
        }}
      />
    </>
  );
}
