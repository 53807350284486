import * as React from "react";

import { Confirm, CustomDimension } from "./Chapters";

export default ({ key, method, customDimension }) => {
  switch (key) {
    case "CUSTOM_DIMENSION":
      return <CustomDimension {...{ method, customDimension }} />;
    default:
      return <Confirm {...{ method, customDimension }} />;
  }
};
