import * as React from "react";
import { useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, LinkedObject } from "../../../components/Displays";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { durationParse } from "../../../components/Displays/utils";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  useEditabledoc,
} from "../../../store/editabledocs";
import { detailUrl as leaseDetailUrl } from "../../../store/leaseContracts";
import { useFilteredNotes } from "../../../store/notes";
import {
  useOtherContract,
  update as updateContract,
  constants as otherConstants,
} from "../../../store/otherContracts";
import UpdateOtherContractModalForm from "../../../components/Forms/OtherContract/EditContractForm/ModalForm";
import UpdateOtherContractTenantModalForm from "../../../components/Forms/OtherContract/EditContractTenantForm/ModalForm";
import { getOverviewPageCostsDetail } from "../../LeaseContract/Detail/Overview";
import { useDispatch } from "react-redux";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useOtherInvoicing } from "../../../store/invoicingOther";
import DigitalSign from "../../../components/Signing/DigitalSign";
import ManualSign from "../../../components/Signing/ManualSign";
import DigitalCancellation from "../../../components/Signing/DigitalCancellation";
import ManualCancellation from "../../../components/Signing/ManualCancellation";
import SmallTenantSummary from "../../../components/SummaryCards/Tenant/Small";
import { getContractFromAndTo } from "../../../components/Lists/utils/costCalculations";

export default function OtherContractOverview() {
  const dispatch = useDispatch();
  const { otherContractId } = useParams();
  const [contract] = useOtherContract(otherContractId);
  const [invoicingObj] = useOtherInvoicing(contract?.other_invoicing?.id);
  const [editableDoc] = useEditabledoc(contract?.editabledoc?.id);
  const [cancelledDoc] = useEditabledoc(contract?.cancelled_doc?.id);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editTenantOpen, setEditTenantOpen] = React.useState(false);
  const [draftLoading, setDraftLoading] = React.useState(false);

  const canEdit = usePermissionCheck("change_can_contract");

  const showSigningDetails = !contract?.is_signed;
  const isDigitalSign = contract?.uses_e_signing;
  const isDraft = contract?.draft;

  const isCancelled = contract?.closed_date || contract?.closed_renew_date;
  const isDigitalCancelSign = contract?.uses_cancellation_e_signing;

  const notesQ = buildQueryString({
    id__in: contract?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const convertDraftToReal = () => {
    setDraftLoading(true);

    dispatch(
      updateContract({
        id: otherContractId,
        forceData: {
          draft: false,
        },
        successCallback: () => {
          setDraftLoading(false);
        },
      })
    );
  };

  return (
    <>
      <UpdateOtherContractModalForm
        instance={contract}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        id={otherContractId}
      />

      {editTenantOpen && (
        <UpdateOtherContractTenantModalForm
          instance={contract}
          onCheckout={() => setEditTenantOpen(false)}
          id={otherContractId}
        />
      )}

      <DetailInnerWrapper>
        {isDraft && (
          <DetailPageBox>
            {draftLoading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Detta avtal är ett utkast</OverviewTitle>

                <OverviewSubtitle>
                  Tryck på "Konvertera från utkast till avtal" om avtalet är
                  redo att användas.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Konvertera från utkast till avtal"
              clicked={convertDraftToReal}
              iconType="done"
              iconPlacement="right"
            />
          </DetailPageBox>
        )}

        {contract && showSigningDetails && !isDraft && (
          <>
            {isDigitalSign ? (
              <DigitalSign
                {...{
                  contract,
                  editableDoc,
                  constants: otherConstants,
                  update: updateContract,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT,
                }}
              />
            ) : (
              <ManualSign
                {...{
                  contract,
                  constants: otherConstants,
                  update: updateContract,
                }}
              />
            )}
          </>
        )}

        {contract && isCancelled && (
          <>
            {isDigitalCancelSign ? (
              <DigitalCancellation
                {...{
                  contract,
                  cancelledDoc,
                  constants: otherConstants,
                  update: updateContract,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT,
                }}
              />
            ) : (
              <ManualCancellation
                {...{
                  contract,
                  cancelledDoc,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT,
                  update: updateContract,
                  constants: otherConstants,
                }}
              />
            )}
          </>
        )}

        <DetailPageBoxFlexWrapper>
          <div style={{ width: "65%", marginRight: 12 }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>
                    Översikt över {contract?.id_number}
                  </OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera avtalsdetaljer"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {contract && (
                <DetailInfo infoObj={getInfoObj({ contract, invoicingObj })} />
              )}
            </DetailPageBox>
          </div>

          <div style={{ width: "35%" }}>
            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Motpart</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera motpart"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditTenantOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <div className="grid grid-cols-1 gap-3">
                <SmallTenantSummary id={contract?.tenant?.id} />
              </div>
            </DetailPageBox>

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <Notes
                notes={notes}
                contentType="standard.othercontract"
                objectId={otherContractId}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ contract, invoicingObj }) {
  const { contractFrom, contractTo } = getContractFromAndTo(contract);

  const infoObj = {
    Avtalsdetaljer: [
      {
        title: "Avtalsstatus",
        value: (
          <StatusLabel state={contract?.state}>
            {contract?.state_display}
          </StatusLabel>
        ),
      },
      { title: "Avtals-ID", value: contract?.id_number },
      { title: "Kategori", value: contract?.category_display },
      {
        title: "Huvudavtal",
        value: (
          <LinkedObject
            obj={contract?.main_contract}
            urlMethod={leaseDetailUrl}
          />
        ),
        hidden: !contract?.main_contract?.id,
      },
      {
        title: "Fastighet",
        value: contract?.realestate?.str_representation,
      },
      { title: "Startdatum", value: <DateCell date={contract?.start_date} /> },
      { title: "Slutdatum", value: <DateCell date={contract?.end_date} /> },
      {
        title: "Förlängt till",
        value: <DateCell date={contract?.renewed_to} />,
        hidden: !contract?.renewed_to,
      },
      {
        title: "Uppsägningstid",
        value: contract?.notify_interval
          ? durationParse(contract?.notify_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Förlängningstid",
        value: contract?.renew_interval
          ? durationParse(contract?.renew_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Varningstid",
        value: contract?.warning_interval
          ? durationParse(contract?.warning_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Signerades",
        value: <DateCell date={contract?.signed_date} />,
      },
    ],

    "Övrig information": [
      {
        title: "Faktureras nästa period",
        value: `${toMoneyString(contract?.next_period_value)} SEK`,
        hidden: !contract?.other_invoicing?.id,
      },
    ],

    [`Intäkter (${contractFrom.format("YYYY-MM")} - ${contractTo.format(
      "YYYY-MM"
    )})`]: contract
      ? getOverviewPageCostsDetail(contract, invoicingObj?.cost_set)
      : [],
  };

  return infoObj;
}
