import { useObject } from "../../base";
import {
  getSummarizedStatistics,
  getCostStatistics,
  getHeavyCostStatistics,
  getTodoBillingStatistics,
  getTodoContractStatistics,
  getTodoBillingInvoiceStatistics,
  getReportErrandStatistics,
  getRoundingErrandStatistics,
  getInspectionErrandStatistics,
  getTodoPremisesStatistics,
  getTodoUserStatistics,
  getTodoTenantStatistics,
  getTodoImdStatistics,
  getTodoErrandStatistics,
  getTodoYourBlockStatistics,
} from "../store/actions";
import constants from "../store/constants";

export function useSummarizedStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.SUMMARIZED_STATISTICS_ID,
    fetchMethod: getSummarizedStatistics,
    processNames: [constants.SUMMARIZED_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useCostStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.COST_STATISTICS_ID,
    fetchMethod: getCostStatistics,
    processNames: [constants.COST_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useHeavyCostStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.HEAVY_COST_STATISTICS_ID,
    fetchMethod: getHeavyCostStatistics,
    processNames: [constants.HEAVY_COST_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoContractStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_CONTRACT_STATISTICS_ID,
    fetchMethod: getTodoContractStatistics,
    processNames: [constants.TODO_CONTRACT_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoPremisesStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_PREMISES_STATISTICS_ID,
    fetchMethod: getTodoPremisesStatistics,
    processNames: [constants.TODO_PREMISES_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoTenantStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_TENANT_STATISTICS_ID,
    fetchMethod: getTodoTenantStatistics,
    processNames: [constants.TODO_TENANT_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoUserStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_USER_STATISTICS_ID,
    fetchMethod: getTodoUserStatistics,
    processNames: [constants.TODO_USER_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoImdStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_IMD_STATISTICS_ID,
    fetchMethod: getTodoImdStatistics,
    processNames: [constants.TODO_IMD_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoYourBlockStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.YOUR_BLOCK_STATISTICS_ID,
    fetchMethod: getTodoYourBlockStatistics,
    processNames: [constants.YOUR_BLOCK_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoBillingInvoiceStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_BILLING_INVOICE_STATISTICS_ID,
    fetchMethod: getTodoBillingInvoiceStatistics,
    processNames: [constants.TODO_BILLING_INVOICE_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoBillingStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_BILLING_STATISTICS_ID,
    fetchMethod: getTodoBillingStatistics,
    processNames: [constants.TODO_BILLING_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useTodoErrandStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.TODO_ERRAND_STATISTICS_ID,
    fetchMethod: getTodoErrandStatistics,
    processNames: [constants.TODO_ERRAND_STATISTICS_PROCESS_NAME],
  };
  return useObject(params);
}

export function useReportErrandStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.REPORT_ERRAND_STATISTICS_ID,
    fetchMethod: getReportErrandStatistics,
    processNames: [constants.REPORT_ERRAND_STATISTICS_ID],
  };
  return useObject(params);
}

export function useInspectionErrandStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.INSPECTION_ERRAND_STATISTICS_ID,
    fetchMethod: getInspectionErrandStatistics,
    processNames: [constants.INSPECTION_ERRAND_STATISTICS_ID],
  };
  return useObject(params);
}

export function useRoundingErrandStatistics() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.ROUNDING_ERRAND_STATISTICS_ID,
    fetchMethod: getRoundingErrandStatistics,
    processNames: [constants.ROUNDING_ERRAND_STATISTICS_ID],
  };
  return useObject(params);
}
