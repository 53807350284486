export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_XLEDGER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_XLEDGER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_XLEDGER",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_XLEDGER",
  DESTROY_FORM: "DESTROY_FORM_XLEDGER",
  SET_FORM_ERROR: "SET_FORM_ERROR_XLEDGER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_XLEDGER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_XLEDGER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_XLEDGER",
  RESET_STATE: "RESET_STATE_XLEDGER",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_XLEDGER",
  REMOVE_OBJECT: "REMOVE_OBJECT_XLEDGER",
  CLEAR_FETCHED: "CLEAR_FETCHED_XLEDGER",

  LIST_URL: "/accounting/bookkeeping/exledger/xledgervoucherconfig/list/",
  POST_URL: "/accounting/bookkeeping/exledger/xledgervoucherconfig/",
  PATCH_URL: "/accounting/bookkeeping/exledger/xledgervoucherconfig/",
  GET_URL: "/accounting/bookkeeping/exledger/xledgervoucherconfig/",

  STORE_NAME: "Xledger",
};
