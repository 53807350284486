import * as React from "react";
import { usePrivateFastdocTemplates } from "../../../../store/editabledocs/hooks/retrieve";
import Table from "../../../Billecta/Table/BasicTable";

export default function PrivateFastDocTemplates({
  privateFastDocTemplateName,
  choseTemplate,
}) {
  const [privateFastDocTemplates, isLoadingPrivateFastDocTemplates] =
    usePrivateFastdocTemplates();

  const data = React.useMemo(() => {
    return privateFastDocTemplates;
  }, [privateFastDocTemplates]);

  const cols = React.useMemo(() => {
    return [
      {
        Header: "Namn",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Kategori",
        accessor: "category_display",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
    ];
  }, []);

  return (
    <>
      <Table
        onRowClicked={({ original }) =>
          choseTemplate({ id: original.id, type: privateFastDocTemplateName })
        }
        columns={cols}
        data={data || []}
      />
    </>
  );
}
