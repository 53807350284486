import * as React from "react";
import { Redirect, useParams } from "react-router";

import { useParkingInvoicing } from "../../../store/invoicingParking";
import { detailUrl } from "../../../store/invoicingParking";

export default function InvoicingParkingRedirect() {
  const { id } = useParams();

  const [parkingInvoicing] = useParkingInvoicing(id);

  const parkingContractId = parkingInvoicing?.parking_contract?.id;

  // wait for sufficient data to redirect
  if (!parkingContractId) return null;

  return <Redirect to={detailUrl({ id: parkingContractId })} />;
}
