export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGCANCELLATION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGCANCELLATION",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGCANCELLATION",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGCANCELLATION",
  DESTROY_FORM: "DESTROY_FORM_PARKINGCANCELLATION",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGCANCELLATION",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGCANCELLATION",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGCANCELLATION",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGCANCELLATION",
  RESET_STATE: "RESET_STATE_PARKINGCANCELLATION",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGCANCELLATION",
  REMOVE_OBJECT: "REMOVE_OBJECT_PARKINGCANCELLATION",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_PARKINGCANCELLATION",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGCANCELLATION",

  LIST_URL: "/standard/parking/cancellations/parkingspot/",

  STORE_NAME: "parkingSpotCancellations",
};
