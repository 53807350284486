import * as React from "react";
import { constants } from "../../../../../store/serviceContracts";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ServicePartnerTable from "src/components/Tables/ServicePartners/FullTable";
import CompanyTable from "src/components/Tables/Companies/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="opponent_party"
          title={"Servicepartner"}
          placeholder="Välj servicepartner..."
          TableComponent={ServicePartnerTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"ordering_party"}
          placeholder="Välj beställande bolag..."
          title="Beställande bolag"
          TableComponent={CompanyTable}
        />
      </div>
    </>
  );
};
