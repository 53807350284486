import * as React from "react";
import FlowFormBase from "../../Base/FlowForm/FlowFormBase";

import chapters from "./Chapters";
import descriptions from "./Descriptions";

const chapterDefs = [
  {
    title: "Basinställning för avisering",
    key: "INVOICING_BASE",
    visited: true,
    hasError: false,
    fieldKeys: ["setting"],
  },
  {
    title: "Gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [""],
  },
];

export default function InvoicingSettingBase({
  storeName,
  method,
  onSubmit,
  onGoToCreateNew,
  onCopyAndCreateNew,
  contract,
  topButtonConfig,
  title,
  isBrf,
}) {
  return (
    <FlowFormBase
      {...{
        storeName,
        chapterDefs,
        chapters,
        descriptions,
        method,
        onSubmit,
        onGoToCreateNew,
        onCopyAndCreateNew,
        contract,
        topButtonConfig,
        title,
        isBrf,
      }}
    />
  );
}
