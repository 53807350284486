export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_REALESTATEPORTFOLIO",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_REALESTATEPORTFOLIO",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_REALESTATEPORTFOLIO",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_REALESTATEPORTFOLIO",
  DESTROY_FORM: "DESTROY_FORM_REALESTATEPORTFOLIO",
  SET_FORM_ERROR: "SET_FORM_ERROR_REALESTATEPORTFOLIO",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_REALESTATEPORTFOLIO",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_REALESTATEPORTFOLIO",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_REALESTATEPORTFOLIO",
  RESET_STATE: "RESET_STATE_REALESTATEPORTFOLIO",
  CLEAR_FETCHED: "CLEAR_FETCHED_REALESTATEPORTFOLIOS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_REALESTATEPORTFOLIO",
  REMOVE_OBJECT: "REMOVE_OBJECT_REALESTATEPORTFOLIO",

  LIST_URL: "/standard/toplevel/realestateportfolio/list/",
  POST_URL: "/standard/toplevel/realestateportfolio/",
  PATCH_URL: "/standard/toplevel/realestateportfolio/",
  GET_URL: "/standard/toplevel/realestateportfolio/",

  STORE_NAME: "realestatePortfolio",
};
