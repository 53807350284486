export default [
  {
    title: "Planritning",
    key: "BLUEPRINT",
    visited: true,
    hasError: false,
    fieldKeys: ["floor", "building", "svg_file"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["realestates", "svg_file_temp_data"],
  },
];
