export default [
  {
    title: "Bolagsinfo",
    key: "INFO",
    visited: true,
    hasError: false,
    fieldKeys: [
      "vat_number",
      "invoice_address",
      "attention",
      "residence",
      "care_of",
      "signatory_contact",
    ],
  },
  {
    title: "Leverans av faktura",
    key: "DELIVERY",
    visited: false,
    hasError: false,
    fieldKeys: [
      "delivery_method",
      "our_reference",
      "invoice_email",
      "invoice_phone",
      "contact_person",
      "claims_contact",
    ],
  },
  {
    title: "Standardinställningar",
    key: "STANDARD",
    visited: false,
    hasError: false,
    fieldKeys: [
      "admin_fee",
      "use_cent_rounding",
      "show_unpaid_next",
      "use_debtor_balance",
    ],
  },
  {
    title: "Bokföring",
    key: "ACCOUNTING",
    visited: false,
    hasError: false,
    fieldKeys: ["additional_dimension", "payment_dimension_override"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["company"],
  },
];
