import * as React from "react";
import * as XLSX from "xlsx";
import moment from "moment";
import * as FileSaver from "file-saver";
import { cloneDeep, get } from "lodash";

import {
  ALWAYS_HIDDEN_EXCEL_COLUMNS,
  EXCEL_ACCESSOR_MAP,
  EXCEL_ENUM_MAP,
  OVERRIDE_COLUMN_WIDTH_WITH_ACCESSOR,
} from "./utils";

import { useSelector } from "react-redux";
import { isDev } from "../../../..";

export const useExcelHandler = ({ hiddenColumns, columns, title }) => {
  let fileName = title || "Lista";

  const userStr = useSelector((state) => state.app.user?.str_representation);

  const buildActualColumns = () => {
    let cols = columns.filter((col) => {
      let accessor = col.accessor || col.id;
      return !hiddenColumns.includes(accessor);
    });
    return cols;
  };

  const buildHeaders = (cols) => {
    let headers = [];

    for (let col of cols) {
      let accessor = col.accessor || col.id;
      if (ALWAYS_HIDDEN_EXCEL_COLUMNS.includes(accessor)) continue;
      headers.push(col.Header);
    }

    return [headers];
  };

  const convertAndDownloadExcel = async ({ data }) => {
    // console.log("convert!", hiddenColumns, data);

    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet([]);

    const actualColumns = buildActualColumns();

    //Building headers

    let headers = buildHeaders(actualColumns);

    let colInfos = [];

    for (let header of headers[0]) {
      let col = actualColumns.find((obj) => obj.Header === header);

      let width = header.length + 1;

      if (
        OVERRIDE_COLUMN_WIDTH_WITH_ACCESSOR.hasOwnProperty(
          col?.accessor || col?.id
        )
      ) {
        width = OVERRIDE_COLUMN_WIDTH_WITH_ACCESSOR[col?.accessor || col?.id];
      }

      colInfos.push({
        wch: width,
      });
    }

    sheet["!cols"] = colInfos;

    XLSX.utils.sheet_add_aoa(sheet, headers);

    //Building content

    // let content = [
    //   [row],
    //   [row],
    //   [row]
    // ]

    let content = [];

    for (let obj of data) {
      let row = [];

      for (let col of actualColumns) {
        let accessor = col.accessor || col.id;
        if (ALWAYS_HIDDEN_EXCEL_COLUMNS.includes(accessor)) continue;

        // console.log(accessor, col.Header, 95);

        if (typeof accessor === "function") {
          if (isDev())
            console.log(
              `Accessor for header: ${col.Header} was a method. Using accessor() return value`
            );
          let tryVal = accessor(obj);
          if (tryVal != null) {
            row.push(tryVal);
            continue;
          }
        }

        if (EXCEL_ACCESSOR_MAP.hasOwnProperty(accessor)) {
          if (typeof EXCEL_ACCESSOR_MAP[accessor] === "function") {
            try {
              let newValue = EXCEL_ACCESSOR_MAP[accessor](
                get(obj, accessor),
                obj
              );

              row.push(newValue);
            } catch (err) {
              console.error(
                "Ran into EXCEL_ACCESSOR_MAP (function) error",
                err
              );
              row.push("-");
            }
            continue;
          }

          accessor = EXCEL_ACCESSOR_MAP[accessor];
        }

        let value = get(obj, accessor);

        if (value === null || (value === undefined && isDev())) {
          if (!obj.hasOwnProperty(accessor)) {
            console.log(
              `Accessor: ${accessor} is un-mapped or nested (with value: null). Please ensure the mapping. Object:`,
              obj
            );
          }
        } else if (isDev()) {
          if (
            typeof value === "object" &&
            !Array.isArray(value) &&
            value !== null
          ) {
            console.log(
              `Accessor: ${accessor} is mapped to an object (unable to write an object to excel). Please ensure the mapping. Object:`,
              obj
            );
          }
        }

        if (Array.isArray(value)) {
          //assuming its an array with objects with str_rep eg.
          //[ { id: {num}, str_representation: "some rep" } ]
          let newValue = "";
          let index = 0;
          for (let valueObj of value) {
            if (index !== 0) newValue += ", ";
            newValue += valueObj.str_representation;
            index++;
          }

          value = newValue;
        }

        if (obj.hasOwnProperty(`${accessor}_display`)) {
          value = obj[`${accessor}_display`];
        } else if (EXCEL_ENUM_MAP.hasOwnProperty(accessor)) {
          value = EXCEL_ENUM_MAP[accessor][value];
        }

        if (value === null || value === undefined) {
          value = "-";
        }

        if (value === false) value = "Nej";
        if (value === true) value = "Ja";

        row.push(value);
      }

      content.push(row);
    }

    //need to add empty data to be able to change their values (for meta)
    for (let i = 0; i < 10; i++) {
      content.push(["", ""]);
    }

    //A2 so no headers are overidden
    XLSX.utils.sheet_add_aoa(sheet, content, { origin: "A2" });

    //Generating file

    let currentMetaPos = content.length - 10 + 5;

    sheet[`A${currentMetaPos}`] = {
      v: "Export från Pigello Fastighetssystem",
      t: "s",
    };

    currentMetaPos += 2;

    sheet[`A${currentMetaPos}`] = {
      v: "Export skapad",
      t: "s",
    };

    sheet[`B${currentMetaPos}`] = {
      v: moment().format("YYYY-MM-DD HH:mm"),
      t: "s",
    };
    currentMetaPos++;

    sheet[`A${currentMetaPos}`] = {
      v: "Exporterad av",
      t: "s",
    };

    sheet[`B${currentMetaPos}`] = {
      v: userStr || "Anonym",
      t: "s",
    };

    XLSX.utils.book_append_sheet(workbook, sheet, "Export");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    workbook.Props = {
      Title: "Pigello Export",
      Author: userStr || "Pigello",
      CreatedDate: moment(),
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  };

  return { convertAndDownloadExcel };
};
