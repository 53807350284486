export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PIPESTAGES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PIPESTAGES",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PIPESTAGES",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PIPESTAGES",
  DESTROY_FORM: "DESTROY_FORM_PIPESTAGES",
  SET_FORM_ERROR: "SET_FORM_ERROR_PIPESTAGES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PIPESTAGES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PIPESTAGES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PIPESTAGES",
  RESET_STATE: "RESET_STATE_PIPESTAGES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PIPESTAGES",
  REMOVE_OBJECT: "REMOVE_OBJECT_PIPESTAGES",
  CLEAR_FETCHED: "CLEAR_FETCHED_PIPESTAGES",
  UP_LEAD_PAGINATION_OFFSET: "UP_LEAD_PAGINATION_OFFSET_PIPESTAGES",
  RESET_PAGINATION_OFFSET: "RESET_PAGINATION_OFFSET_PIPESTAGES",
  SET_FILTERS: "SET_FILTERS_PIPESTAGES",
  SET_SEARCH_TERM: "SET_SEARCH_TERM_PIPESTAGES",

  LIST_URL: "/market/crm/stage/list/",
  POST_URL: "/market/crm/stage/",
  PATCH_URL: "/market/crm/stage/",
  GET_URL: "/market/crm/stage/",

  STORE_NAME: "pipeStages",
};
