import * as React from "react";

// style, design
// import BaseTable from "src/components/Lists/Base/CompleteList/Table";
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";
import { buildRangeFilter } from "../../Lists/Base/utils";

// store, state
import { constants, detailUrl } from "../../../store/brfPremises";
import { useFrequentPermissions } from "../../../store/base";

import { OPERATOR_TEMPLATES } from "../../Lists/Base/FinalTable/utils";

export default function BrfPremisesTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const { hasBillectaViewPermission } = useFrequentPermissions();

  // const exportParser = {
  //   state: (data) => data.state_display,
  //   category: (data) => data.category_display,
  // };

  //TODO: filters & badges

  const filters = {
    Lägenhetsnummer: {
      queryKey: "internal_premises_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Objektsnummer: {
      queryKey: "premises_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Adress: {
      queryKey: "address__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Byggnad: {
      queryKey: "building__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Area: {
      queryKey: "area",
      type: "number",
      operators: OPERATOR_TEMPLATES.NUMBER,
    },
  };

  const badges = {};

  const columns = React.useMemo(
    () => columnDefs(hasBillectaViewPermission),
    [persistantQuery, hasBillectaViewPermission]
  );

  return (
    <BaseTable
      title="Bostadsrätter"
      tableId={tableId || "brf_premises_full_table"}
      onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
