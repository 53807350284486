import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const InsightsSideBarWrapper = styled.div`
  width: 150px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  color: ${(p) => p.theme.colors.primaryText};
  background-color: #fff;
  border-right: ${(p) => p.theme.borders.standard};
`;

export const InsightsSideBarPageDetailsBorder = styled.div`
  margin-top: 25px;
  border-top: ${(p) => p.theme.borders.standard};
`;

export const InsightsSideBarPageDetails = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

export const PulseContainer = styled.div`
   {
    width: 200px;
    height: 100%;
    margin: 0 auto 0;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    background: #fff;
  }
`;

const Pulse = keyframes`
  from {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);

  }
  to {
    -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }

`;
export const IconContainer = styled.div`
  width: 110px;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: ${(p) => p.theme.borders.standard};
`;

export const InsightsSidebarGroup = styled(Link)`
  width: 110px;
  min-height: 63px;
  max-height: 70px;
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: "Inter", sans-serif;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;

  color: ${(p) => p.theme.colors.primaryText};

  &:hover {
    background-color: #e4f1ff;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  //   ${(p) =>
    p.inner &&
    p.last &&
    css`
      margin-bottom: 12px;
    `}

  background-color: ${(p) => (p.isOpen ? " #f1f1f1" : "#fff")};
  padding: ${(p) =>
    p.filterActive ? "4px 36px 4px 36px" : "4px 14px 4px 14px"};
  transition: ${(p) =>
    p.closedMode ? "" : "background-color, color 0.2s ease-in-out"};

  &:hover {
    background-color: #f1f1f1;
  }

  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => p.theme.colors.blueLight};
      color: ${(p) => p.theme.colors.primaryBlue};

      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

  ${(p) =>
    p.icon &&
    css`
      &:before {
        top: calc(50% - 9px);
        left: ${(p) => (p.closedMode ? "9px" : "12px")};
        height: 40px;
        width: 30px;

        background-repeat: no-repeat;
        content: "";
        background-position: center;
        background-size: contain;
        background-image: url(${p.icon});
      }
    `}

    margin: 5px;
`;

export const InsightsPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    background-color: hsla(220, 81%, 98%, 1);
    position: relative;
    width: 100%;
`

export const WidgetPlacementsGrid = styled.div`
  min-width: 1600px;
  display: grid;
  grid-template-columns: repeat(${(p) => p.width}, 1fr);
  grid-template-rows: repeat(${(p) => p.height}, 1fr);
  height: ${(p) => p.gridHeight}px;
`;
export const WidgetPlacementContainer = styled.div`
  grid-column-start: ${(p) => p.columnStart};
  grid-column-end: ${(p) => p.columnEnd};
  grid-row-start: ${(p) => p.rowStart};
  grid-row-end: ${(p) => p.rowEnd};
  margin: 5px;
`;

export const EmptyGridCell = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${(p) => p.icon});
  transition: opacity 0.3s ease-in-out;
  grid-column-start: ${(p) => p.columnStart};
  grid-column-end: ${(p) => p.columnEnd};
  grid-row-start: ${(p) => p.rowStart};
  grid-row-end: ${(p) => p.rowEnd};

  opacity: 0;

  font-size: 14px;
  color: #a3a3a3;
  text-align: center;
  line-height: 128px;

  &:hover {
    opacity: 1;
  }
`;

export const TopBarContainer = styled.div`
  border-bottom: ${(p) => p.theme.borders.standard};
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 5px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
