import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state

import {
  useKeyDevicePagination,
  performFilter,
  constants,
  updateUrl,
} from "../../../store/keydevice";
import { overviewUrl } from "../../../store/key";
import { useAllPermissionCheck } from "../../../store/base";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  const canUpdateKey = useAllPermissionCheck(["allow_keys", "change_can_keys"]);
  const canParakey = useAllPermissionCheck([
    "allow_parakey",
    "view_can_parakey",
  ]);

  const columns = React.useMemo(
    () => columnDefs(canParakey),
    [persistantQuery, canParakey]
  );

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "keydevice_setting_full_table"}
      title={"Dörrar"}
      onRowClickedWithDetail={(obj) =>
        canUpdateKey ? updateUrl({ id: obj.id }) : overviewUrl()
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
