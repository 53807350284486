import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { updateActiveFormInstance } from "../../../../../store/base";

import { constants } from "../../../../../store/reportErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { Select, TimePicker } from "../../../Base/Fields";
import DurationField from "../../../Base/Fields/DurationField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const setEndDate = () => {
    const endDate = moment().format();
    dispatch(
      updateActiveFormInstance({
        storeName: storeName,
        data: {
          execute_end: endDate,
        },
      })
    );
  };

  React.useEffect(() => {
    setEndDate();
  }, []);

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Granska och slutför</OverviewTitle>
          <OverviewSubtitle>
            Ange när ärendet avklarades, klicka sedan på "Slutför avhjälpande"
            för att slutföra ärendet
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <Select
        title="Ärendestatus"
        filterChoices={(c) => [2, 3, 4].includes(c.v)}
        storeName={storeName}
        method={method}
        fieldKey={"status"}
      />

      <TimePicker
        storeName={storeName}
        fieldKey={"execute_end"}
        method={method}
        title="Tid för avklarande"
      />

      <DurationField
        title="Faktiskt arbetstid"
        description="Den totala arbetstiden som lagts ner på ärendet"
        storeName={storeName}
        fieldKey={"execute_duration"}
        method={method}
        extraStyles={{ marginTop: "12px" }}
      />
    </>
  );
};
