import * as React from "react";
import { useParams } from "react-router-dom";

import KeyForm from "../../../components/Forms/Keys/FlowForm/FlowForm";

export default () => {
  const { userId, permissionId } = useParams();

  return <KeyForm method="POST" userId={userId} permissionId={permissionId}/>;
};
