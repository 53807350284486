import Attachemnts from "./Attachments";
import Costs from "./Costs";
import DebtCollection from "./DebtCollection";
import Debtor from "./Debtor";
import Interest from "./Interest";
import Settings from "./Settings";

export default {
  DEBTOR: Debtor,
  SETTINGS: Settings,
  COSTS: Costs,
  INTEREST: Interest,
  DEBT_COLLECTION: DebtCollection,
  ATTACHMENTS: Attachemnts,
};
