export default () => [
  {
    title: "Avhjälpande",
    key: "ERRAND",
    visited: true,
    hasError: false,
    fieldKeys: ["completion_comment", "completion_files"],
  },

  {
    title: "Komponent",
    key: "COMPONENT",
    visited: true,
    hasError: false,
    fieldKeys: ["execution_rating"],
  },
  {
    title: "Kostnader",
    key: "COSTS",
    visited: true,
    hasError: false,
    fieldKeys: ["costs"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["execute_end", "execute_duration", "status"],
  },
];
