import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";
import { constants } from "../../../store/inspectionErrandSettings";
import { useHistory } from "react-router";
import DeleteModal from "../../Forms/Delete/DeleteModal";
import { usePermissionCheck } from "../../../store/base";
import SettingModalForm from "../../Forms/InspectionErrandSetting/ChapterForm/ModalForm";
import { useSelector } from "react-redux";

export default function InspectionErrandSettingsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const { push } = useHistory();
  const user_type = useSelector((state) => state.app.user?.user_type);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [editSettingOpen, setEditSettingOpen] = React.useState(false);
  const [currentInstance, setCurrentInstance] = React.useState(null);

  const canDeleteSetting = usePermissionCheck("delete_can_inspectionerrand");
  const canEditSetting = usePermissionCheck("change_can_inspectionerrand");

  const goToEdit = (row) => {
    if (canEditSetting && user_type !== 2) {
      setEditSettingOpen(row);
    }
  };
  const onDeleteClicked = (setting) => {
    setCurrentInstance(setting);
    setDeleteModalOpen(true);
  };

  const deletedCallback = () => {
    push(`/inspection-errands`);
  };

  const columns = React.useMemo(
    () => columnDefs(onDeleteClicked, canDeleteSetting),
    [canDeleteSetting, canEditSetting]
  );

  const badges = {};
  const filters = {};

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        constants={constants}
        instance={currentInstance}
        deletedCallback={deletedCallback}
      />
      {canEditSetting && (
        <SettingModalForm
          method="PATCH"
          id={editSettingOpen?.id}
          isOpen={editSettingOpen}
          onCheckout={() => setEditSettingOpen(false)}
          instance={editSettingOpen}
        />
      )}

      <BaseTable
        tableId={tableId || "brf_invoicing_full_table"}
        title={"Bostadsrätter"}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked: onRowClicked || goToEdit,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
