import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import NonConnectedNumberInput from "../../../Base/Old/NonConnected/NonConnectedNumberInput";

export default ({ storeName, updateDefaultSettings }) => {
  const dispatch = useDispatch();

  const defaultSettingsInstance = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const updateAttr = ({ attr, val }) => {
    dispatch(updateDefaultSettings({ attr, val }));
  };

  return (
    <>
      <NonConnectedNumberInput
        id={`available_parking_spots`}
        label="Antal tillgängliga fordonsplatser"
        value={defaultSettingsInstance.available_parking_spots}
        onUpdate={(val) => {
          updateAttr({ attr: "available_parking_spots", val });
        }}
      />

      <NonConnectedNumberInput
        id={`included_parking_spots`}
        label="Antal inkluderade fordonsplatser"
        value={defaultSettingsInstance.included_parking_spots}
        onUpdate={(val) => {
          updateAttr({ attr: "included_parking_spots", val });
        }}
      />

      <NonConnectedNumberInput
        id={`available_garage_spots`}
        label="Antal tillgängliga garageplatser"
        value={defaultSettingsInstance.available_garage_spots}
        onUpdate={(val) => {
          updateAttr({ attr: "available_garage_spots", val });
        }}
      />

      <NonConnectedNumberInput
        id={`included_garage_spots`}
        label="Antal inkluderade garageplatser"
        value={defaultSettingsInstance.included_garage_spots}
        onUpdate={(val) => {
          updateAttr({ attr: "included_garage_spots", val });
        }}
      />
    </>
  );
};
