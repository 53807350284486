import * as React from "react";

import { Errand, Component, Costs, Confirm } from "./Chapters";

export default ({ key, method, id }) => {
  switch (key) {
    case "ERRAND":
      return <Errand {...{ method }} />;

    case "COMPONENT":
      return <Component {...{ method, id }} />;

    case "COSTS":
      return <Costs {...{ method }} />;

    default:
      return <Confirm {...{ method }} />;
  }
};
