import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import ApartmentAdsTable from "../../Tables/MarketApartmentAds/FullTable";
import IndpAdsTable from "../../Tables/MarketIndustrialPremisesAds/FullTable";
import ParkingAdsTable from "../../Tables/MarketParkingAds/FullTable";
import { buildQueryString } from "../../../store/base";
import { useApartmentAdsPaginationCount } from "../../../store/marketApartmentAds";
import PageSlider from "../../../views/Layouts/PageSlider/PageSlider";
import { useParkingSpotAdsPaginationCount } from "../../../store/marketParkingAds";
import { useIndustrialPremisesAdsPaginationCount } from "../../../store/marketIndpAds";

export default function ConnectedAds() {
  const { pipeId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState("APARTMENTS");
  const TABS = {
    APARTMENTS: "För lägenheter",
    INDP: "För lokaler",
    PARKING: "För fordonsplatser",
  };

  const [apartmentAdsCount] = useApartmentAdsPaginationCount({
    filters: {
      dump_in_pipe: pipeId,
    },
  });

  const [indpAdsCount] = useIndustrialPremisesAdsPaginationCount({
    filters: {
      dump_in_pipe: pipeId,
    },
  });

  const [parkingAdsCount] = useParkingSpotAdsPaginationCount({
    filters: {
      dump_in_pipe: pipeId,
    },
  });

  const persistantQuery = {
    dump_in_pipe: pipeId,
  };

  TABS.APARTMENTS = `${TABS.APARTMENTS} (${apartmentAdsCount || 0})`;

  TABS.INDP = `${TABS.INDP} (${indpAdsCount || 0})`;

  TABS.PARKING = `${TABS.PARKING} (${parkingAdsCount || 0})`;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Kopplade annonser</OverviewTitle>
          <OverviewSubtitle>
            Leads från dessa annonser hamnar i denna pipe
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>

        <PageSlider
          {...{
            TABS,
            selectedTab,
            onTabSelected: (tab) => setSelectedTab(tab),
          }}
        />
      </OverviewTitleWrapper>

      {selectedTab === "APARTMENTS" && (
        <ApartmentAdsTable {...{ persistantQuery }} />
      )}
      {selectedTab === "INDP" && <IndpAdsTable {...{ persistantQuery }} />}
      {selectedTab === "PARKING" && (
        <ParkingAdsTable {...{ persistantQuery }} />
      )}
    </>
  );
}
