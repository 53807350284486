import { useObjects, usePagination, useFilteredObjects } from "../../base";
import { getAll, performFilter, filterPagination } from "../store/actions"
import constants from "../store/constants"

export function useAddresses(filterMethod) {
    const params = {
        storeName:constants.STORE_NAME,
        filterMethod: filterMethod,
        fetchMethod: getAll
    }
    return useObjects(params);
}

export function useFilteredAddresses(querystring) {
    const params = {
        storeName:constants.STORE_NAME,
        fetchMethod: performFilter,
        querystring: querystring
    }

    return useFilteredObjects(params);
}

export function useAddressPagination(querystring) {
    const params = {
        storeName:constants.STORE_NAME,
        fetchMethod: filterPagination,
        querystring: querystring
    }

    return usePagination(params);
}