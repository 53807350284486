import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { EmailTag, PhoneTag } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  buildQueryString,
  updateActiveFormInstance,
  usePermissionCheck,
} from "../../../store/base";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import { useDispatch } from "react-redux";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useFilteredNotes } from "../../../store/notes";
import { useServicePartner, constants } from "../../../store/servicePartners";
import ServicePartnerModalForm from "../../../components/Forms/ServicePartners/ChapterForm/ModalForm";

const getContactPerson = (servicePartner) => {
  let currentServicePartnerContact;

  if (servicePartner?.user?.first_name) {
    currentServicePartnerContact = servicePartner.user.first_name;
  }
  if (servicePartner?.user?.last_name) {
    if (currentServicePartnerContact) {
      currentServicePartnerContact = `${currentServicePartnerContact} ${servicePartner.user.last_name}`;
    } else {
      currentServicePartnerContact = servicePartner.user.last_name;
    }
  }
  if (!currentServicePartnerContact) {
    currentServicePartnerContact = "-";
  }

  return currentServicePartnerContact;
};

export default function ServicePartnerOverview() {
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const { servicePartnerId } = useParams();

  const canAddUser = usePermissionCheck("add_can_user");
  const canEditServicePartner = usePermissionCheck("change_can_contract");
  const canEditUser = usePermissionCheck("change_can_user");

  const [servicePartner] = useServicePartner(servicePartnerId);

  const notesQ = buildQueryString({
    id__in: servicePartnerId?.user?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const onEdit = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: servicePartnerId,
      })
    );
    setEditOpen(true);
  };

  return (
    <>
      <ServicePartnerModalForm
        method="PATCH"
        id={servicePartnerId}
        instance={servicePartner}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "60%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              {canEditServicePartner && canEditUser && (
                <PrimaryButton title="Redigera" clicked={onEdit} />
              )}
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getInfoObj(servicePartner)} />
          </DetailPageBox>

          <DetailPageBox style={{ width: "40%", alignSelf: "flex-start" }}>
            <Notes
              notes={notes}
              contentType="accounts.user"
              objectId={servicePartner?.user?.id}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj(servicePartner) {
  const infoObj = {
    Servicepartner: [
      { title: "Bolagsnamn", value: servicePartner?.user?.corporate_name },

      { title: "Kontaktperson", value: getContactPerson(servicePartner) },
      { title: "Orgnr.", value: servicePartner?.user?.legal_id || "-" },
      {
        title: "E-post",
        value: <EmailTag mail={servicePartner?.user?.email} />,
      },
      {
        title: "Telefon",
        value: <PhoneTag phone={servicePartner?.user?.phone} />,
      },
      {
        title: servicePartner?.user?.corporate_name ? "Orgnr" : "Personnummer",
        value: servicePartner?.user?.legal_id || "-",
      },
      {
        title: "Tillhör fastigheter",
        value:
          servicePartner?.user?.realestates?.length > 0
            ? servicePartner?.user?.realestates?.map((re) => {
                return (
                  <>
                    <div>{re.str_representation}</div>
                    <br />
                  </>
                );
              })
            : "Ingen specifik",
      },
    ],
  };

  return infoObj;
}
