import * as React from "react";

// style, design
import * as SC from "./styles";

import { ErrorMessage } from "../Fields/styles";
import { EditButton, RemoveButton } from "../Buttons";

// store, state
import { useFormError, useFormField, useFormInstanceField } from "../../../../store/base";

export default React.memo(({
    storeName,
    method,
    fieldKey,
    onRemove,
    onEdit,
    constructStrRep,
    instructionsKey,
    tryNestedUpdate
}) => {
    const value = useFormInstanceField({storeName, fieldKey});
    const error = useFormError({storeName, fieldKey});
    const instructions = useFormField({
        storeName,
        method,
        fieldKey: instructionsKey || fieldKey,
      });
    const nestedUpdate = value && value.id && instructions?._nestedUpdate && tryNestedUpdate;
    const show = (value && !value.id) || nestedUpdate;
    if (!show) {return null;}

    return (
        <SC.Wrapper>
         <SC.Representation>{constructStrRep ? constructStrRep(value) : "Tillagt"}</SC.Representation>
        
        <RemoveButton clicked={onRemove} title="Ta bort" />
        <EditButton
            extraStyle={{ marginLeft: "14px" }}
            clicked={onEdit}
            title="Uppdatera"
        />
        {nestedUpdate ? <><br></br><span style={{color:"orange"}}>Varning: Om du uppdaterar detta uppdateras det för alla objekt som använder det</span></> : null}

        {!!error && <ErrorMessage>Det nya objektet innehåller fel</ErrorMessage>}
        </SC.Wrapper>
    );
})
