import * as React from "react";
import { StatusLabel } from "../Lists/Base/CompleteList/styles";

export default ({ value, onLabel, offLabel }) => {
  if (value) {
    return <StatusLabel state={0}>{onLabel}</StatusLabel>;
  }

  return <StatusLabel state={6}>{offLabel}</StatusLabel>;
};
