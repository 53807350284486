export default () => [
  {
    title: "Utförare",
    key: "DELEGATION",
    visited: true,
    hasError: false,
    fieldKeys: ["performer", "remind_executor_duration", "requested_performer"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
