import * as React from "react";

import { BooleanLabel, TimeCell } from "../../Displays";
import { TextButton } from "../../Forms/Base/Buttons";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default (deleteCallback) => [
  {
    Header: "Titel",
    accessor: "title",
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Tid för utskick",
    accessor: "scheduled_for",
    Cell: ({ value, row }) => <TimeCell date={value} />,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Fastnålad",
    accessor: "pinned",
    Cell: ({ value, row }) => (
      <BooleanLabel value={value} onLabel={"Ja"} offLabel={"Nej"} />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Antal mottagare",
    id: "recipient_count",
    Cell: ({ row }) => row.original.recipients?.length || 0,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Radera",
    id: "delete",
    Cell: ({ row }) => (
      <TextButton
        title="Radera utskick"
        red
        iconType="close"
        iconPlacement="right"
        clicked={() => deleteCallback(row.original)}
      />
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
];
