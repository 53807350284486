import * as React from "react";
import { TextButton } from "../../Forms/Base/Buttons";
import MediaField from "../../Forms/Base/Fields/MediaField";

export default function DigitalDocUploadDocument({
  cancel,
  method,
  storeName,
}) {
  return (
    <div className="max-w-[1000px] w-full mx-auto pt-12 flex flex-col px-12">
      <TextButton title="Gå tillbaka" clicked={cancel} iconType="arrow-back" />

      <h1 className="font-medium text-2xl mt-6">Ladda upp dokument</h1>

      <div className="mt-12">
        <MediaField
          title="Välj dokument"
          storeName={storeName}
          fieldKey="doc"
          method={method}
          description="Ladda upp dokumentet som fälten ska placeras ut på."
          allowedFormats={[".pdf"]}
          fileKey="doc"
        />
      </div>
    </div>
  );
}
