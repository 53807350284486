import * as React from "react";

export default () => {
  return (
    <div>
      Välj basinställning för avisering. När en inställning är vald så visas en
      förhandsgranskning över vad inställningen innebär.
      <br />
      <br />
      Kostnader och datumspecifika inställningar för avisering ställs in i nästa
      steg.
    </div>
  );
};
