import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import Toggle from "../Toggle/Toggle";
import * as SC from "./styles";

// headers -> array of headers
// rows -> array of arrays with data. Each arrays length must match header length
// summarizerRow -> array of items with index corresponding to header index
export default ({
  title,
  headers = [],
  rows = [],
  summarizerRows = [],
  loading,
  renderEmpty,
  extraStyles = {},
  extraContainerStyles = {},
  errorRowIndexes = [], // array of indexes with errors
  handleDetailPermToggled,
  activeActions,
  contentType,
  disabled,
}) => {
  return (
    <div style={{ ...extraContainerStyles }}>
      {title && (
        <OverviewTitleWrapper style={{ marginBottom: 12 }}>
          <OverviewTitle small>{title}</OverviewTitle>
        </OverviewTitleWrapper>
      )}
      <SC.Wrapper style={{ ...extraStyles }}>
        {headers.length > 0 && rows.length > 0 ? (
          <SC.Table>
            <thead>
              <tr role="row">
                {headers.map((h) => (
                  <th colspan={1} role="columnheader">
                    {h}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody role="rowgroup">
              {rows.map((r, idx) => (
                <tr
                  role="row"
                  style={
                    errorRowIndexes.includes(idx)
                      ? { backgroundColor: "#fddddd" }
                      : {}
                  }
                >
                  <td>{r.object_str_rep || r.str_representation}</td>
                  <td>
                    <Toggle
                      title=""
                      value={r.operations?.view}
                      disabled={!activeActions[contentType].view}
                      extraStyle={{ justifyContent: "left" }}
                    />
                  </td>
                  <td>
                    <Toggle
                      title=""
                      value={r.operations?.change}
                      disabled={!activeActions[contentType].change || disabled}
                      onToggle={() => handleDetailPermToggled(r, "CHANGE")}
                      extraStyle={{ justifyContent: "left" }}
                    />
                  </td>
                  <td>
                    <Toggle
                      title=""
                      value={r.operations?.delete}
                      disabled={!activeActions[contentType].delete || disabled}
                      onToggle={() => handleDetailPermToggled(r, "DELETE")}
                      extraStyle={{ justifyContent: "left" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>

            {summarizerRows?.length > 0 && (
              <tfoot>
                <tr role="row">
                  {summarizerRows.map((s) => (
                    <th>{s}</th>
                  ))}
                </tr>
              </tfoot>
            )}
          </SC.Table>
        ) : (
          <SC.TableEmptyWrapper>
            {loading ? (
              <OverlaySpinner />
            ) : (
              <SC.TableEmptyText>
                {renderEmpty ? renderEmpty() : "Det finns ingen data att visa"}
              </SC.TableEmptyText>
            )}
          </SC.TableEmptyWrapper>
        )}
      </SC.Wrapper>
    </div>
  );
};
