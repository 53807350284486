import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import PrimaryBtn from "../Base/Buttons/PrimaryBtn";
import CheckField from "../Base/Fields/CheckField";
import DateSelect from "../Base/Fields/DateSelect";
import LocalRadioGroupField from "../Base/Fields/LocalRadioGroupField";
import SelectField from "../Base/Fields/SelectField";
import TableSelectField from "../Base/Fields/TableSelectField";
import TextInputField from "../Base/Fields/TextInputField";
import {
  decideSettingType,
  INDEX_ALTERNATIVES,
  MONTHS_OPTIONS,
  INDEX_TYPES,
} from "./ModalForm/ModalForm";

import { useDispatch } from "react-redux";
import IndexTablesTable from "src/components/Tables/IndexTable/FullTable";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";

export default function IndexSettingNestedFields({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  const dispatch = useDispatch();
  const [selectedAlternative, setSelectedAlternative] = React.useState(null);

  const instance = useFormInstanceField({
    storeName,
    fieldKey: parentPath,
  });

  const includeCostTitle = instance?.include_cost_title;
  const useCostProduct = instance?.use_cost_product;

  React.useEffect(() => {
    if (instance) {
      const mode = decideSettingType(instance);

      setSelectedAlternative(mode);
    }
  }, []);

  const clearIndexSetting = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [parentPath]: {
            index_amount: 100,
            index_quota: null,
            final_rounding_mode: 2,
            index_around_month: 1,
            include_cost_title: true,
            use_cost_product: true,
          },
        },
      })
    );

    setSelectedAlternative(null);
  };

  return (
    <>
      {!selectedAlternative && (
        <div className="mb-6">
          <LocalRadioGroupField
            value={selectedAlternative}
            onChange={(val) => setSelectedAlternative(val)}
            required
            title="Typ av indexinställning"
            description="Välj hur denna indexinställning ska räkna upp debiteringsrader"
            options={INDEX_ALTERNATIVES}
            id="_type"
          />
        </div>
      )}

      {selectedAlternative === INDEX_TYPES.SET && (
        <>
          <div className="mb-6">
            <PrimaryBtn secondary onClick={clearIndexSetting}>
              <ArrowLeftIcon width={16} className="mr-1" /> Byt typ av index
            </PrimaryBtn>
          </div>

          <div className="mb-6 text-base font-medium">Fast indexuppräkning</div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Namn på inställning"
              fieldKey={`${parentPath}.title`}
              instructionsKey={`${parentInstructionsPath}.title`}
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Andel som räknas upp i %"
              description="Andel av debiteringsraden som räknas upp i %."
              fieldKey={`${parentPath}.index_amount`}
              instructionsKey={`${parentInstructionsPath}.index_amount`}
              {...{ method, storeName }}
            />
            <TextInputField
              title="Fast uppräkning i %"
              description="Uppräkning i %."
              fieldKey={`${parentPath}.index_quota`}
              instructionsKey={`${parentInstructionsPath}.index_quota`}
              {...{ method, storeName }}
            />
          </div>
        </>
      )}

      {(selectedAlternative === INDEX_TYPES.DYNAMIC ||
        selectedAlternative === INDEX_TYPES.CUSTOM_DYNAMIC) && (
        <>
          <div className="mb-6">
            <PrimaryBtn secondary onClick={clearIndexSetting}>
              <ArrowLeftIcon width={16} className="mr-1" /> Byt typ av index
            </PrimaryBtn>
          </div>

          <div className="mb-6 text-base font-medium">
            Dynamisk utifrån{" "}
            {selectedAlternative === INDEX_TYPES.DYNAMIC
              ? "KPI 1980 (Oktober)"
              : "egen indextabell"}
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Namn på inställning"
              fieldKey={`${parentPath}.title`}
              instructionsKey={`${parentInstructionsPath}.title`}
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {selectedAlternative === INDEX_TYPES.CUSTOM_DYNAMIC && (
              <TableSelectField
                description="Tabell som värden för uppräkning ska hämtas ifrån."
                storeName={storeName}
                fieldKey={`${parentPath}.table`}
                instructionsKey={`${parentInstructionsPath}.table`}
                method={method}
                placeholder="Välj indextabell"
                title="Indextabell"
                TableComponent={IndexTablesTable}
              />
            )}
            <TextInputField
              title="Andel som räknas upp i %"
              description="Andel av debiteringsraden som räknas upp i %."
              fieldKey={`${parentPath}.index_amount`}
              instructionsKey={`${parentInstructionsPath}.index_amount`}
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Minimal indexkvot i %"
              description="Om den egentliga årliga uppräkningen är lägre än denna kommer denna att användas istället. Den årliga uppräkningen innebär differensen mellan kvoten för innevarande indexperiod och den tidigare indexperioden."
              fieldKey={`${parentPath}.index_min_val`}
              instructionsKey={`${parentInstructionsPath}.index_min_val`}
              {...{ method, storeName }}
            />
            <TextInputField
              title="Maximal indexkvot i %"
              description="Om den egentliga årliga uppräkningen är högre än denna kommer denna att användas istället. Den årliga uppräkningen innebär differensen mellan kvoten för innevarande indexperiod och den tidigare indexperioden."
              fieldKey={`${parentPath}.index_max_val`}
              instructionsKey={`${parentInstructionsPath}.index_max_val`}
              {...{ method, storeName }}
            />
            <TextInputField
              title="Minsta förändring i enheter"
              description="Antal enheter ett KPI-värde måste ändras med för att en uppräkning ska göras, 0.01 tillåts, 3.0 tillåts. En ändring från 300,0 till 303,0 är 3 enheter. En ändring från 300,0 till 302,6 är alltså mindre än 3 enheter och resulterar då ej i en ny uppräkning."
              fieldKey={`${parentPath}.index_min_points`}
              instructionsKey={`${parentInstructionsPath}.index_min_points`}
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              isNumber
              title="Antal decimaler vid beräkning av KPI"
              placeholder="17 (Lämna tom för standard)"
              description="Ange hur många decimaler som används vid beräkning med KPI-värde. Anges inget används 17 decimaler som standard."
              fieldKey={`${parentPath}.calculate_with_decimals`}
              instructionsKey={`${parentInstructionsPath}.calculate_with_decimals`}
              {...{ method, storeName }}
            />

            <SelectField
              title="Metod för avrundning av KPI"
              description="Ange hur beräknat värde ska avrundas. Anges inget avrundas ej värdet."
              fieldKey={`${parentPath}.final_rounding_mode`}
              instructionsKey={`${parentInstructionsPath}.final_rounding_mode`}
              {...{ method, storeName }}
            />
          </div>

          <div className="mb-6">
            <CheckField
              description="Om detta är aktivt kan indexuppräkningen leda till en nedjustering av debiteringsraden."
              title="Tillåt sänkning"
              fieldKey={`${parentPath}.allow_reduction`}
              instructionsKey={`${parentInstructionsPath}.allow_reduction`}
              {...{ method, storeName }}
            />
          </div>
        </>
      )}

      {selectedAlternative != null && (
        <>
          <hr />
          <div className="mb-6 text-base font-medium">Datum & Perioder</div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <DateSelect
              description="Lämna tom om systemet ska räkna ut basåret. Basår angett på avtalet som debiteringsraden ligger på skriver över detta värde."
              title="Basår"
              showYearPicker
              fieldKey={`${parentPath}.index_base_date`}
              instructionsKey={`${parentInstructionsPath}.index_base_date`}
              {...{ method, storeName }}
            />

            {selectedAlternative === INDEX_TYPES.CUSTOM_DYNAMIC && (
              <SelectField
                description="Basmånad som ska användas för beräkning av indexuppräkning. Anges inget används Oktober enligt standard."
                title="Basmånad"
                fieldKey={`${parentPath}.index_base_month`}
                instructionsKey={`${parentInstructionsPath}.index_base_month`}
                forceChoices={MONTHS_OPTIONS}
                {...{ method, storeName }}
              />
            )}
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <SelectField
              description="Ange vilken månad som ska användas som brytpunkt för det nya indexåret. Anges inget används Januari som standard."
              title="Startmånad för nytt indexår"
              fieldKey={`${parentPath}.index_around_month`}
              instructionsKey={`${parentInstructionsPath}.index_around_month`}
              forceChoices={MONTHS_OPTIONS}
              {...{ method, storeName }}
            />

            <TextInputField
              isNumber
              title="Antal nådemånader"
              description="Ange antal månader från det att index börjar gälla första gången till att det ska debiteras."
              fieldKey={`${parentPath}.initial_zero_value_months`}
              instructionsKey={`${parentInstructionsPath}.initial_zero_value_months`}
              {...{ method, storeName }}
            />
          </div>

          <hr />
          <div className="mb-6 text-base font-medium">
            Avisering & Bokföring
          </div>

          <div className="mb-6">
            <CheckField
              description="Visa vilken debiteringsrad som indexuppräkningen kommer ifrån på avier."
              title="Visa källa på avier"
              fieldKey={`${parentPath}.include_cost_title`}
              instructionsKey={`${parentInstructionsPath}.include_cost_title`}
              {...{ method, storeName }}
            />
          </div>
          {!includeCostTitle && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TextInputField
                description="Ange hur indexuppräkningen ska anges på avier."
                title="Namn på avier"
                fieldKey={`${parentPath}.indexation_value_title`}
                instructionsKey={`${parentInstructionsPath}.indexation_value_title`}
                {...{ method, storeName }}
              />
            </div>
          )}

          <div className="mb-6">
            <CheckField
              description="Om detta alternativ är valt ignoreras den valda produkten på denna indexinställning."
              title="Använd produkt från debiteringsrad"
              fieldKey={`${parentPath}.use_cost_product`}
              instructionsKey={`${parentInstructionsPath}.use_cost_product`}
              {...{ method, storeName }}
            />
          </div>

          {!useCostProduct && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                fieldKey={`${parentPath}.product`}
                instructionsKey={`${parentInstructionsPath}.product`}
                method={method}
                placeholder="Välj produkt"
                title="Produkt för bokföring"
                TableComponent={InvoicingProductTable}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
