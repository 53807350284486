import * as React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Select from "react-select";

// style, design
import * as SC from "./styles";

// store, design
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    extraStyles,
    instructionsKey,
    noTitle,
    noMargin,
    children,
    title,
    description,
    menuPlacement,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    let internalValue = moment.duration(value);

    const onChange = (data) => {
      let formattedData = null;
      if (moment.isDuration(data)) {
        formattedData = data.toISOString();
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
    };

    const onDayChange = ({ value }) => {
      // id is value
      if (!moment.isDuration(internalValue)) {
        internalValue = moment.duration(value, "days");
      } else {
        // prev: 4, new: 6 -> 4 + (6-4) = 6
        internalValue.add(value - internalValue.days(), "days");
      }
      onChange(internalValue);
    };
    const onHourChange = ({ value }) => {
      // id is value
      if (!moment.isDuration(internalValue)) {
        internalValue = moment.duration(value, "hours");
      } else {
        // prev: 4, new: 6 -> 4 + (6-4) = 6
        internalValue.add(value - internalValue.hours(), "hours");
      }
      onChange(internalValue);
    };
    const onMinuteChange = ({ value }) => {
      // id is value
      if (!moment.isDuration(internalValue)) {
        internalValue = moment.duration(value, "minutes");
      } else {
        // prev: 4, new: 6 -> 4 + (6-4) = 6
        internalValue.add(value - internalValue.minutes(), "minutes");
      }
      onChange(internalValue);
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const integerChoices = (length) => {
      return [...Array(length).keys()].map((i) => {
        return { value: i, label: i };
      });
    };

    const dayChoices = integerChoices(29);
    const hourChoices = integerChoices(24);
    const minuteChoices = [0, 15, 30, 45].map((i) => {
      return { value: i, label: i };
    });

    const selectedDay = internalValue.days() || 0;
    const selectedHour = internalValue.hours() || 0;
    const selectedMinute = internalValue.minutes() || 0;

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {!noTitle && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SC.InputFieldTitle>
              {title}
              {instructions._required ? "*" : ""}
            </SC.InputFieldTitle>
            {description && <DescriptionToolTip description={description} />}
          </div>
        )}

        <SC.DurationFieldWrapper>
          <SC.DurationSelectWrapper>
            <div style={{ minWidth: "90px" }}>
              <Select
                menuPlacement={menuPlacement ? menuPlacement : "bottom"}
                id={`${instructions._internalId}-days`}
                placeholder="Dagar..."
                onChange={onDayChange}
                defaultValue={{ value: selectedDay, label: selectedDay }}
                value={{ value: selectedDay, label: selectedDay }}
                options={dayChoices}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                isMulti={false}
                isClearable={false}
                isDisabled={false}
              />
            </div>

            <span style={{ marginLeft: "6px" }}>dagar, </span>
          </SC.DurationSelectWrapper>

          <SC.DurationSelectWrapper>
            <div style={{ minWidth: "90px" }}>
              <Select
                menuPlacement={menuPlacement ? menuPlacement : "bottom"}
                id={`${instructions._internalId}-hours`}
                placeholder="Timmar..."
                onChange={onHourChange}
                defaultValue={{ value: selectedHour, label: selectedHour }}
                value={{ value: selectedHour, label: selectedHour }}
                options={hourChoices}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                isMulti={false}
                isClearable={false}
                isDisabled={false}
              />
            </div>

            <span style={{ marginLeft: "6px" }}>timmar, </span>
          </SC.DurationSelectWrapper>

          <SC.DurationSelectWrapper>
            <div style={{ minWidth: "90px" }}>
              <Select
                menuPlacement={menuPlacement ? menuPlacement : "bottom"}
                id={`${instructions._internalId}-minutes`}
                placeholder="Minuter..."
                onChange={onMinuteChange}
                defaultValue={{ value: selectedMinute, label: selectedMinute }}
                value={{ value: selectedMinute, label: selectedMinute }}
                options={minuteChoices}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                isMulti={false}
                isClearable={false}
                isDisabled={false}
              />
            </div>

            <span style={{ marginLeft: "6px" }}>minuter </span>
          </SC.DurationSelectWrapper>
        </SC.DurationFieldWrapper>

        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        {children}
      </SC.InputSpacing>
    );
  }
);
