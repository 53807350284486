export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CALENDAR_EVENT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CALENDAR_EVENT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CALENDAR_EVENT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CALENDAR_EVENT",
  DESTROY_FORM: "DESTROY_FORM_CALENDAR_EVENT",
  SET_FORM_ERROR: "SET_FORM_ERROR_CALENDAR_EVENT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CALENDAR_EVENT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CALENDAR_EVENT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CALENDAR_EVENT",
  RESET_STATE: "RESET_STATE_CALENDAR_EVENT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CALENDAR_EVENT",
  REMOVE_OBJECT: "REMOVE_OBJECT_CALENDAR_EVENT",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_CALENDAR_EVENT",
  CLEAR_FETCHED: "CLEAR_FETCHED_CALENDAR_EVENT",

  LIST_URL: "/events/todo/list/",
  POST_URL: "/events/todo/",
  PATCH_URL: "/events/todo/",
  GET_URL: "/events/todo/",

  STORE_NAME: "calendarEvents",
};
