import { useDispatch, useSelector } from "react-redux";
import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
} from "../../base";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
  fetchPublicArticles,
} from "../store/actions";
import constants from "../store/constants";
import { getPublicErrandArticleProcessName } from "../utils";

export function useErrandArticle(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useErrandArticles(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredErrandArticles(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useErrandArticlesPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function usePublicArticles(querystring) {
  const formattedQueryString = querystring || "";

  const dispatch = useDispatch();
  const possibleProcessName =
    getPublicErrandArticleProcessName(formattedQueryString);
  const storeName = constants.STORE_NAME;

  const existing = useSelector(
    (state) => state[storeName]?.publicArticles?.[formattedQueryString]
  );
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(possibleProcessName)
  );

  // first check if it exists in store
  if (existing) {
    return [existing, isFetching];
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result

  if (isFetching) {
    return [undefined, isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(fetchPublicArticles(formattedQueryString));

  return [undefined, isFetching];
}
