import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default function TextInputField({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  placeholder,
  title,
  description,
  forceError,
  forceRequired,
  forceInstructions,
  changeCallback,
  isNumber,
  step = "0.1",
  disabled,
}) {
  const dispatch = useDispatch();

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || forceInstructions;

  const required =
    forceRequired || (instructions?._required && !instructions._allowNull);

  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey }) || forceError;

  const onChange = (data) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]:
            data?.length > 0
              ? data
              : instructions?._allowNull
              ? null
              : undefined,
        },
      })
    );
    if (changeCallback) {
      changeCallback(data);
    }
  };

  if (!instructions || instructions?._readOnly) return null;

  return (
    <div>
      {title && (
        <label
          htmlFor={fieldKey}
          className=" text-sm flex items-center font-medium text-gray-900 "
        >
          {(error || forceError) && (
            <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
          )}
          {title}
          {required ? "*" : ""}
        </label>
      )}
      {description && (
        <p
          id={`${fieldKey}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {(error || forceError) && (
        <div className="text-xs mb-1 font-normal text-red-600 ">
          {forceError || error}
        </div>
      )}
      <input
        type={isNumber ? "number" : "text"}
        step={isNumber ? step : undefined}
        value={value?.toString() || ""}
        disabled={disabled}
        onChange={({ target: { value } }) => onChange(value)}
        id={fieldKey}
        className="bg-white border border-slate-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
        placeholder={placeholder || title}
        required={required}
      />
    </div>
  );
}
