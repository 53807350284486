import * as React from "react";

import { constants } from "../../../../../store/reportErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { RadioGroup, TextField } from "../../../Base/Fields";
import MediaField from "../../../Base/Fields/MediaField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const ratingsFieldKey = "execution_rating";

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Information kring komponent</OverviewTitle>
          <OverviewSubtitle>
            Markera om felet är avhjälp och fyll i kommentar samt bild på
            hanterad komponent
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <RadioGroup
        storeName={storeName}
        method={method}
        options={[
          { label: "Avhjälpt", value: true },
          { label: "Inte avhjälpt", value: false },
        ]}
        title="Välj om felet är avhjälpt eller inte"
        fieldKey={`${ratingsFieldKey}.is_ok`}
        instructionsKey={`${ratingsFieldKey}.is_ok`}
      />

      <RadioGroup
        storeName={storeName}
        method={method}
        options={[
          { label: "5 (Mycket bra)", value: 5 },
          { label: "4 (Bra)", value: 4 },
          { label: "3 (Okej)", value: 3 },
          { label: "2 (Ganska dåligt)", value: 2 },
          { label: "1 (Dåligt)", value: 1 },
        ]}
        title="Specificera skicket på komponenten"
        fieldKey={`${ratingsFieldKey}.rating`}
        instructionsKey={`${ratingsFieldKey}.rating`}
      />

      <TextField
        storeName={storeName}
        fieldKey={`${ratingsFieldKey}.note`}
        instructionsKey={`${ratingsFieldKey}.note`}
        method={method}
        title={"Kommentar kring komponent"}
      />

      <MediaField
        storeName={storeName}
        method={method}
        fieldKey={`${ratingsFieldKey}.file`}
        instructionsKey={`${ratingsFieldKey}.file`}
        allowedFormats={[".png", ".jpg", ".jpeg"]}
        title="Bild på hanterad komponent"
        fileKey="file"
      />
    </>
  );
};
