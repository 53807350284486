import * as React from "react";

// store, state
import { constants } from "../../../../../store/sendout";
import {
  constants as userConstants,
  useUserPagination,
  constructStrRep as constructUserStrRep,
} from "../../../../../store/users";

import {
  constants as buildingConstants,
  useBuildingPagination,
  constructStrRep as constructBuildingStrRep,
} from "../../../../../store/buildings";

import {
  constants as realEstateConstants,
  useRealEstatePagination,
  constructStrRep as constructRealEstateStrRep,
} from "../../../../../store/realEstates";

import userColumnDefs from "../../../../Tables/Users/listDefs";
import buildingColumnDefs from "../../../../Tables/Buildings/listDefs";
import realEstateColumnDefs from "../../../../Tables/RealEstate/listDefs";
import { TableSelect } from "../../../Base/Fields";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InfoBox } from "../../../../Displays";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import BuildingTable from "src/components/Tables/Buildings/FullTable";

const CATEGORIES = {
  REALESTATES: 0,
  BUILDINGS: 1,
  USERS: 2,
};

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [category, setCategory] = React.useState(null);

  const selectedBuildings = useFormInstanceField({
    storeName,
    fieldKey: "buildings",
  });
  const selectedRealEstates = useFormInstanceField({
    storeName,
    fieldKey: "realestates",
  });
  const selectedRecipients = useFormInstanceField({
    storeName,
    fieldKey: "recipients",
  });

  React.useEffect(() => {
    if (selectedBuildings?.length) {
      setCategory(CATEGORIES.BUILDINGS);
    }
    if (selectedRealEstates?.length) {
      setCategory(CATEGORIES.REALESTATES);
    }
    if (selectedRecipients?.length) {
      setCategory(CATEGORIES.USERS);
    }
  }, []);

  const clearAndGoBack = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          realestates: [],
          buildings: [],
          recipients: [],
        },
      })
    );

    setCategory(null);
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Mottagare</OverviewTitle>
          <OverviewSubtitle>
            Lägg till mottagare av meddelandet. Observera att de mottagare som
            ej har tillgång till "Mina sidor" enbart kommer att få utskicket per
            E-post eller övriga tillgängliga kommunikationskanaler. Mottagare
            som ej har någon giltig kommunikationsväg inställd kommer ej att få
            utskicket.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {method === "PATCH" && (
        <InfoBox
          title="OBS"
          text='Vid redigering av ett utskick som redan har skickats ut kommer ändringarna enbart att reflekteras inne på "Mina sidor". Utskicket kommer alltså inte att skickas om till mottagarna per E-post eller övriga kommunikationskanaler.'
        />
      )}

      <hr />

      {category == null && (
        <>
          <div style={{ marginBottom: 12 }}>
            <TextButton
              title="Skicka till alla hyresgäster i utvalda fastigheter"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setCategory(CATEGORIES.REALESTATES)}
            />
          </div>
          <div style={{ marginBottom: 12 }}>
            <TextButton
              title="Skicka till alla hyresgäster i utvalda byggnader"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setCategory(CATEGORIES.BUILDINGS)}
            />
          </div>

          <div>
            <TextButton
              title="Skicka till utvalda hyresgäster"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setCategory(CATEGORIES.USERS)}
            />
          </div>
        </>
      )}

      {category != null && (
        <TextButton
          extraStyle={{ marginBottom: 12 }}
          title="Rensa och byt mottagare"
          iconType="arrow-back"
          clicked={clearAndGoBack}
        />
      )}

      {category === CATEGORIES.USERS && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey={"recipients"}
            title={"Mottagare"}
            placeholder={"Välj mottagare..."}
            isMany
            persistantQuery={{
              user_type: 3,
            }}
            TableComponent={UsersTable}
          />
        </div>
      )}

      {category === CATEGORIES.REALESTATES && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey={"realestates"}
            fieldTitle={"Fastigheter som ska ta emot utskicket"}
            title={"Välj fastigheter..."}
            isMany={true}
            TableComponent={RealEstateTable}
          />
        </div>
      )}

      {category === CATEGORIES.BUILDINGS && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey={"buildings"}
            fieldTitle={"Byggnader som ska ta emot utskicket"}
            title={"Välj byggnader..."}
            isMany={true}
            TableComponent={BuildingTable}
          />
        </div>
      )}
    </>
  );
};
