import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, LinkedObject, TimeCell } from "../../../components/Displays";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { useApartmentAd, update } from "../../../store/marketApartmentAds";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as pipeDetailUrl } from "../../../store/pipes";
import { detailUrl as requirementDetailUrl } from "../../../store/marketRequirements";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import Toggle from "../../../components/Toggle/Toggle";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import ApartmentAdViewingForm from "../../../components/Forms/MarketApartmentAd/ViewingForm/ModalForm";
import { useDispatch } from "react-redux";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import SyncErrorTable, {
  SYNC_ERROR_MAP,
} from "../../../components/Tables/ExternalSyncErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import LeadsTable from "../../../components/Tables/Leads/FullTable";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useExternalSyncErrorPaginationCount } from "../../../store/externalSyncErrors";
import { getAdStateAndLabel } from "../../../store/marketApartmentAds/utils";

export default function ApartmentAdDetailOverview() {
  const dispatch = useDispatch();
  const { adId } = useParams();
  const [ad] = useApartmentAd(adId);

  const [editViewingOpen, setEditViewingOpen] = React.useState(false);
  const [loadingPublish, setLoadingPublish] = React.useState(false);

  const togglePublishing = ({ attr, active, isSbf }) => {
    setLoadingPublish(true);

    const forceData = {
      [attr]: active,
    };

    if (isSbf) {
      forceData["publish_pigello"] = false;
      forceData["publish_homeq"] = false;
    }

    dispatch(
      update({
        id: adId,
        forceData,
        successCallback: () => {
          setLoadingPublish(false);
        },
        errorCallback: (_, e) => {
          if (e?.viewing_time) {
            dispatch(
              addToast({
                type: TOAST_TYPES.INFO,
                title: "Felmeddelande",
                description: e.viewing_time,
              })
            );
          }
          setLoadingPublish(false);
        },
      })
    );
  };

  const [syncErrorCount] = useExternalSyncErrorPaginationCount({
    filters: {
      api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
      apartment: ad?.apartment?.id,
    },
  });

  const hasSyncError = syncErrorCount > 0;

  const errorQuery = {
    api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
    apartment: ad?.apartment?.id,
  };

  const leadQuery = {
    apartment: ad?.apartment?.id,
  };

  return (
    <>
      {/* <ApartmentAdViewingForm
        method="PATCH"
        id={adId}
        instance={ad}
        isOpen={editViewingOpen}
        onCheckout={() => setEditViewingOpen(false)}
      /> */}

      <DetailInnerWrapper>
        {hasSyncError && (
          <DetailPageBox error>
            <SyncErrorTable
              hideTitle
              {...{
                persistantQuery: errorQuery,
              }}
            />
          </DetailPageBox>
        )}
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ flex: 7, maxWidth: "69%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getInfoObj({ ad })} />

            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Annonsbeskrivning</OverviewTitle>
              </OverviewTitleWrapper>
              <BodyText>{ad?.description || "Beskrivning saknas"}</BodyText>
            </InnerBox>
          </DetailPageBox>
          <div
            style={{
              flex: 3,
              maxWidth: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailPageBox>
              {loadingPublish && <OverlaySpinner />}
              <OverviewTitleWrapper>
                <OverviewTitle small>Publiceras på</OverviewTitle>
              </OverviewTitleWrapper>

              {/* <Toggle
                extraTitleStyle={{
                  fontSize: 12,
                  fontWeight: 500,
                  margin: "6px 0",
                }}
                title="Pigello Marknadsplats"
                value={ad?.publish_pigello}
                onToggle={() => {
                  togglePublishing({
                    attr: "publish_pigello",
                    active: !ad?.publish_pigello,
                  });
                }}
              /> */}

              <Toggle
                extraTitleStyle={{
                  fontSize: 12,
                  fontWeight: 500,
                  margin: "6px 0",
                }}
                title="HomeQ"
                value={ad?.publish_homeq}
                onToggle={() => {
                  togglePublishing({
                    attr: "publish_homeq",
                    active: !ad?.publish_homeq,
                  });
                }}
              />

              <Toggle
                extraTitleStyle={{
                  fontSize: 12,
                  fontWeight: 500,
                  margin: "6px 0",
                }}
                title="Stockholms Bostadsförmedling"
                value={ad?.publish_sbf}
                onToggle={() => {
                  togglePublishing({
                    attr: "publish_sbf",
                    active: !ad?.publish_sbf,
                    isSbf: true,
                  });
                }}
              />
            </DetailPageBox>

            {/* <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Visning</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Redigera visning"
                      iconType="edit"
                      iconPlacement="right"
                      clicked={() => setEditViewingOpen(true)}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {!ad?.viewing_type ? (
                <InnerBox>Ingen visning planerad</InnerBox>
              ) : (
                <InnerBox>
                  <BodyText>
                    <strong>Typ av visning:</strong>
                    <br />
                    {ad?.viewing_type_display} <br />
                    <br />
                    {ad?.viewing_time && (
                      <>
                        {" "}
                        <strong>Tid för visning:</strong>{" "}
                        <div>
                          <TimeCell date={ad?.viewing_time} />
                        </div>
                        <br />
                      </>
                    )}
                    {ad?.viewing_notes && (
                      <>
                        {" "}
                        <strong>Anteckningar:</strong>
                        <br /> {ad?.viewing_notes}
                      </>
                    )}
                  </BodyText>
                </InnerBox>
              )}
            </DetailPageBox> */}
          </div>
        </DetailPageBoxFlexWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>Leads på detta objekt</OverviewTitle>
          </OverviewTitleWrapper>

          <LeadsTable
            {...{
              persistantQuery: leadQuery,
            }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ ad }) {
  const { stateDisplay, state } = getAdStateAndLabel(ad);
  let infoObj = {
    Annons: [
      {
        title: "Status",
        value: <StatusLabel state={state}>{stateDisplay}</StatusLabel>,
      },
      {
        title: "Objekt",
        value: (
          <LinkedObject obj={ad?.apartment} urlMethod={apartmentDetailUrl} />
        ),
      },
      {
        title: "Kravprofil",
        value: (
          <LinkedObject
            obj={ad?.requirement}
            urlMethod={requirementDetailUrl}
          />
        ),
      },
      {
        title: "Kopplat projekt",
        value: (
          <LinkedObject obj={ad?.dump_in_pipe} urlMethod={pipeDetailUrl} />
        ),
      },
      {
        title: "Publiceringsdatum",
        value: <DateCell date={ad?.date_publish} />,
      },
      {
        title: "Tillträdesdatum",
        value: <DateCell date={ad?.access_date} />,
      },
      {
        title: "Annonshantering",
        value: ad?.pilot_mode_display,
      },
      {
        title: "Prioritera nuvarande hyresgäster",
        value: (
          <StatusLabel state={ad?.prioritize_customers ? 0 : 6}>
            {ad?.prioritize_customers ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Korttidskontrakt",
        value: (
          <StatusLabel
            state={ad?.short_lease_max_date || ad?.short_lease_min_date ? 0 : 6}
          >
            {ad?.short_lease_max_date || ad?.short_lease_min_date
              ? "Ja"
              : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Uthyres som minst till",
        value: <DateCell date={ad?.short_lease_min_date} />,
        hidden: !ad?.short_lease_min_date,
      },
      {
        title: "Uthyres som mest till",
        value: <DateCell date={ad?.short_lease_max_date} />,
        hidden: !ad?.short_lease_max_date,
      },
    ],
  };

  return infoObj;
}
