export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MARKETING_INDP_COMMODITIES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MARKETING_INDP_COMMODITIES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MARKETING_INDP_COMMODITIES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MARKETING_INDP_COMMODITIES",
  DESTROY_FORM: "DESTROY_FORM_MARKETING_INDP_COMMODITIES",
  SET_FORM_ERROR: "SET_FORM_ERROR_MARKETING_INDP_COMMODITIES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MARKETING_INDP_COMMODITIES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MARKETING_INDP_COMMODITIES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MARKETING_INDP_COMMODITIES",
  RESET_STATE: "RESET_STATE_MARKETING_INDP_COMMODITIES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MARKETING_INDP_COMMODITIES",
  REMOVE_OBJECT: "REMOVE_OBJECT_MARKETING_INDP_COMMODITIES",
  CLEAR_FETCHED: "CLEAR_FETCHED_MARKETING_INDP_COMMODITIES",

  ADD_MULTIPLE_INDPS: "ADD_MULTIPLE_INDPS_INDP_COMMODITIES",
  ADD_INDP: "ADD_INDP_INDP_COMMODITIES",
  REMOVE_INDP: "REMOVE_INDP_INDP_COMMODITIES",
  SET_COMMODITIES: "SET_COMMODITIES_INDP_COMMODITIES",
  SET_DEFAULT_SETTINGS: "SET_DEFAULT_SETTINGS_INDP_COMMODITIES",

  LIST_URL: "/market/ads/industrialpremisescommodities/list/",
  POST_URL: "/market/ads/industrialpremisescommodities/",
  PATCH_URL: "/market/ads/industrialpremisescommodities/",
  GET_URL: "/market/ads/industrialpremisescommodities/",

  STORE_NAME: "marketIndustrialPremisesCommodities",
};
