import * as React from "react";

import { constants } from "../../../../../store/invoicingOther";
import { useTenant } from "../../../../../store/tenants";
import { useDebtorInvoicing } from "../../../../../store/invoicingDebtor";
import SpecificInvoicingSettingBase from "../../../Costs/SpecificInvoicingSettingBase";

export default ({ method, contract }) => {
  const [tenant] = useTenant(contract?.tenant?.id);
  const [debtorInvoicingConfig] = useDebtorInvoicing(
    tenant?.debtor_invoice_config?.id
  );

  return (
    <SpecificInvoicingSettingBase
      {...{
        debtorInvoicingConfig,
        constants,
        method,
      }}
    />
  );
};
