import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
export default (buildings, realEstateStats) => [
  {
    Header: "Bild",
    id: "logotype_from_buildings",
    Cell: ({ row }) => {
      const id = row.original.id;

      const building = buildings?.find((b) => b.realestate?.id == id);

      return (
        <div
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "5px",
            backgroundImage: `url(${building?.image?.get})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
      );
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Beteckning",
    accessor: "key",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Bolag",
    accessor: "company.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Typ",
    accessor: "type_code_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Huvudadress",
    accessor: "main_address.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];

//Exporting columDefs as funciton to play nicely with detailPerms logic (components/Details/Account/UserGroups/WhiteListTable.js)

export const realEstateColumnDefs = (_, buildings) => {
  return [
    {
      Header: "Bild",
      accessor: "buildings",
      Cell: ({ value }) => {
        const buildingId = value?.[0];
        let building;

        if (buildingId) {
          building = buildings?.find((b) => b.id == buildingId);
        }

        return (
          <div
            style={{
              height: "60px",
              width: "60px",
              borderRadius: "5px",
              backgroundImage: `url(${building?.image?.get})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Beteckning",
      accessor: "key",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Bolag",
      accessor: "company.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Typ",
      accessor: "type_code_display",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Huvudadress",
      accessor: "main_adress.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];
};
