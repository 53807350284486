import * as React from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

// style, design
import * as SC from "./styles";
import { CreateNewButton } from "../Buttons";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { unPackArrayErrors } from "../utils";

export default React.memo(
  ({
    storeName,
    method,
    fieldKey,
    fetchMethod,
    onCreateClicked,
    instructionsKey,
    noTitle,
    title,
    noHelp,
    noMargin,
    placeholder,
    changeCallback,

    // allows for react select to anchor the select list to the body.
    // Excellent for overflow problems but MUST NOT be used in modals
    bodyPortaled,
    ignoreCreation,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const showingCreated = value && !value.id && !ignoreCreation;
    const [choices, choicesLoading] = fetchMethod();

    const onChange = (data) => {
      let formattedData = instructions._allowNull ? null : undefined;
      if (data !== null) {
        formattedData = data;
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
      changeCallback && changeCallback(formattedData);
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing {...{ noMargin }}>
        {!noTitle && (
          <SC.InputFieldTitle>
            {title || instructions._label}
            {instructions._required ? "*" : ""}
          </SC.InputFieldTitle>
        )}
        {!noHelp && instructions._helpText && (
          <SC.InputFieldDescription>
            {instructions._helpText}
          </SC.InputFieldDescription>
        )}
        {choices ? (
          <SC.SelectWrapper>
            <Select
              id={instructions._internalId}
              placeholder={placeholder || "Välj..."}
              onChange={onChange}
              defaultValue={null}
              value={value}
              options={choices}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.str_representation}
              isClearable={!instructions._required}
              isDisabled={showingCreated}
              {...{
                menuPortalTarget: bodyPortaled
                  ? document.querySelector("body")
                  : null,
              }}
            />
          </SC.SelectWrapper>
        ) : (
          <SC.SelectLoader>Laddar alternativ...</SC.SelectLoader>
        )}
        {!!onCreateClicked && !showingCreated && (
          <CreateNewButton title={"Skapa ny"} clicked={onCreateClicked} />
        )}
        {!!error && (
          <SC.ErrorMessage>{unPackArrayErrors(error)}</SC.ErrorMessage>
        )}
      </SC.InputSpacing>
    );
  }
);
