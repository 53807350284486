import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../../components/Displays";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import StandardModal from "../../../components/Modals/StandardModal";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { useFilteredCompanies } from "../../../store/companies";
import {
  connectSbftoPigelloRequirement,
  getSbfRequirementConnections,
  useSbfRequirementConnections,
  useSbfRequirements,
} from "../../../store/stockholmsBF";
import { buildQueryString } from "../../../store/base";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";

const GUIDE_STEPS = {
  COMPANY: 1,
  SBF_PROFILE: 2,
  DONE: 3,
};

export default function SbfConnections() {
  const dispatch = useDispatch();
  const { requirementId } = useParams();
  const [createConnectionOpen, setCreateConnectionOpen] = React.useState(false);
  const [step, setStep] = React.useState(GUIDE_STEPS.COMPANY);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState(null);
  const [selectedSbfId, setSelectedSbfId] = React.useState(null);
  const [connectLoading, setConnectLoading] = React.useState(false);
  const [requirements] = useSbfRequirements();
  const [connections] = useSbfRequirementConnections();
  const [companies] = useFilteredCompanies("");

  const currentRequirements = requirements?.[selectedCompanyId];

  const filteredConnections = connections?.filter(
    (c) => c.requirement?.id == requirementId
  );

  const companyFilter = (obj) => {
    return Object.keys(requirements || {}).includes(obj.id?.toString());
  };
  const companyQuery = buildQueryString({
    id__in: Object.keys(requirements || {}),
  });

  const connect = () => {
    setConnectLoading(true);

    dispatch(
      connectSbftoPigelloRequirement({
        sbfId: selectedSbfId,
        companyId: selectedCompanyId,
        requirementId,
        successCallback: () => {
          setConnectLoading(false);
          setStep(GUIDE_STEPS.DONE);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Koppling skapad",
              description:
                "Annonser på Bostadsförmedlingen Stockholm är nu redo att skapas via Pigello med denna kravprofil för lägenheter under det valda bolaget.",
            })
          );

          dispatch(getSbfRequirementConnections());
        },
        errorCallback: () => {
          setConnectLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej skapa koppling",
              description: "Kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const continueGuide = () => {
    switch (step) {
      case GUIDE_STEPS.COMPANY:
        setStep(GUIDE_STEPS.SBF_PROFILE);
        break;
      case GUIDE_STEPS.SBF_PROFILE:
        connect();
        break;

      case GUIDE_STEPS.DONE:
        setCreateConnectionOpen(false);
        setStep(GUIDE_STEPS.COMPANY);
        break;
    }
  };

  const guideTitles = () => {
    switch (step) {
      case GUIDE_STEPS.COMPANY:
        return ["Gå vidare", "Avbryt"];
      case GUIDE_STEPS.SBF_PROFILE:
        return ["Skapa koppling", "Avbryt"];

      default:
        return ["", "Klar"];
    }
  };

  const canSave = () => {
    switch (step) {
      case GUIDE_STEPS.COMPANY:
        return !!selectedCompanyId;

      case GUIDE_STEPS.SBF_PROFILE:
        return !!selectedSbfId;

      default:
        return true;
    }
  };

  const selectCompanyStep = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Välj bolag denna koppling gäller för</OverviewTitle>
          <OverviewSubtitle>
            Observera att om kravprofilen ska kopplas för flera bolag krävs det
            att en koppling per bolag skapas. Bolaget måste vara integrerat mot
            Bostadsförmedlingen Stockholm via integrationssidan för att synas i
            listan och kunna kopplas.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTableSelectField
          value={companies?.find((c) => c.id == selectedCompanyId)}
          required
          TableComponent={CompanyTable}
          persistantQuery={{
            id__in: Object.keys(requirements || {}),
          }}
          title="Bolag som kopplingen ska ske för"
          placeholder="Välj bolag..."
          onChange={(val) => setSelectedCompanyId(val.id)}
        />
      </div>
    </>
  );

  const selectSbfProfileStep = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>
            Välj kravprofil hos Bostadsförmedligen Stockholm
          </OverviewTitle>
          <OverviewSubtitle>
            Klicka på en kravprofil för att välja. Välj den som bäst
            överensstämmer med denna kravprofil.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InnerBox>
        {currentRequirements?.length < 1 && (
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Kravprofiler saknas hos Bostadsförmedligen Stockholm
              </OverviewTitle>
              <OverviewSubtitle>
                Kontrollera att profilerna finns inlagda på ert konto hos
                Bostadsförmedligen.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
        )}

        {currentRequirements?.length > 0 &&
          currentRequirements?.map((req) => (
            <InnerBox
              style={
                selectedSbfId === req.sbf_id
                  ? {
                      marginTop: 8,
                      backgroundColor: "rgba(44, 174, 249, 0.28)",
                    }
                  : { marginTop: 8, cursor: "pointer" }
              }
              onClick={() => setSelectedSbfId(req.sbf_id)}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>{req.title}</OverviewTitle>
                  <OverviewSubtitle>{req.description}</OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
            </InnerBox>
          ))}
      </InnerBox>
    </>
  );

  const doneStep = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Koppling klar</OverviewTitle>
          <OverviewSubtitle>
            Denna kravprofil kan nu användas för att annonsera ut lägenheter
            under det kopplade bolaget på Bostadsförmedligen Stockholm
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
    </>
  );

  return (
    <>
      <StandardModal
        isOpen={createConnectionOpen}
        closeFunction={() => {
          setCreateConnectionOpen(false);
          setStep(GUIDE_STEPS.COMPANY);
        }}
        title="Skapa koppling"
        withActionBar
        canAccept={canSave()}
        saveFunction={step !== GUIDE_STEPS.DONE ? continueGuide : null}
        actionBarAcceptTitle={guideTitles()[0]}
        actionBarCancelTitle={guideTitles()[1]}
      >
        {connectLoading && <OverlaySpinner />}
        {step === GUIDE_STEPS.COMPANY && selectCompanyStep}
        {step === GUIDE_STEPS.SBF_PROFILE && selectSbfProfileStep}
        {step === GUIDE_STEPS.DONE && doneStep}
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Kopplingar av kravprofiler</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Lägg till koppling"
              clicked={() => setCreateConnectionOpen(true)}
            />
          </OverviewTitleWrapper>

          <InfoBox
            title="Koppling av kravprofiler"
            text="Integrationen mot Bostadsförmedlingen Stockholm kräver att
              kravprofiler från Pigello och Bostadsförmedlingen kopplas ihop.
              Detta görs genom en koppling mellan ett bolag, en
              Pigello-kravprofil och en Bostadsförmedlingen
              Stockholm-kravprofil."
          />

          {filteredConnections?.length > 0 ? (
            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>
                    Kopplingar till denna kravprofil
                  </OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {filteredConnections.map((c) => {
                const connectedCompany = companies?.find(
                  (co) => co.id === c.company.id
                );
                const connectedSbfDescription = requirements?.[
                  c.company?.id
                ]?.find((r) => r.sbf_id === c.sbf_id);

                return (
                  <InnerBox key={c.id}>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          {c.str_representation}
                        </OverviewTitle>
                        <OverviewSubtitle>
                          Gäller för bolaget{" "}
                          <TextButton
                            title={connectedCompany?.str_representation}
                            clicked={() => {}}
                          />
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>

                    <InnerBox>
                      {connectedSbfDescription?.description ||
                        "Laddar kravprofil..."}
                    </InnerBox>
                  </InnerBox>
                );
              })}
            </InnerBox>
          ) : (
            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Kopplingar saknas</OverviewTitle>
                  <OverviewSubtitle>
                    Tryck på "Lägg till koppling" för att koppla en
                    Bostadsförmedlingen Stockholm kravprofil mot denna
                    kravprofil.
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
            </InnerBox>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
