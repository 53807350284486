import * as React from "react";
import DescriptionToolTip from "../../Layout/DescriptionToolTip";

// style, design
import * as SC from "../../Fields/styles";

// store, design

export default React.memo(
  ({
    extraStyles,
    id = "active-text-field",
    type,
    noTitle,
    noMargin,
    disabled,
    title,
    description,
    placeholder,
    noBorder,
    noResize,
    maxLength,
    required,
    value,
    handleChange,

    // explicitly specified rows
    rows,
  }) => {
    const onChange = (data) => {
      handleChange(data);
    };

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {!noTitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.InputFieldTitle>
              {title}
              {required ? "*" : ""}
            </SC.InputFieldTitle>
            {description && <DescriptionToolTip description={description} />}
          </div>
        )}
        <SC.InputFieldWrapper {...{ noBorder }}>
          <SC.TextField
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            maxLength={maxLength}
            placeholder={placeholder || ""}
            id={id}
            required={required}
            disabled={disabled}
            type={type || "text"}
            {...{ placeholder, noResize, rows }}
          />
        </SC.InputFieldWrapper>
      </SC.InputSpacing>
    );
  }
);
