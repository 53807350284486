import * as React from "react";
import { useParams } from "react-router-dom";

import PaymentGroupForm from "../../../components/Forms/PaymentGroup/FlowForm/FlowForm";

export default () => {
  const { brfCompanyId, id } = useParams();

  return <PaymentGroupForm method="PATCH" id={id} brfCompanyId={brfCompanyId}/>;
};
