import * as React from "react";

// style, design
import { LinkedObject, ToolTipCell } from "../../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import CategoryFilter from "./Filters/CategoryFilter";
import VehicleSupportFilter from "./Filters/VehicleSupportFilter";

import { detailUrl as lotDetailurl } from "../../../../store/parkingLots";
import { TextButton } from "../../../Forms/Base/Buttons";

export default ({ onExcludeClicked, realEstates } = {}) => {
  const cols = [
    {
      Header: "",
      id: "exclude",
      Cell: ({ row }) => {
        return (
          <TextButton
            red
            iconType="close"
            title="Exkludera"
            clicked={() => onExcludeClicked(row)}
          />
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Platsnr",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kategori",
      accessor: (row, _) => row?.specification?.category_display || null,
      id: "category",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: CategoryFilter,
      filter: "textExact",
    },
    {
      Header: "Typ av plats",
      accessor: (row, _) => row?.specification?.vehicle_support_display || null,
      id: "vehicle_support",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: VehicleSupportFilter,
      filter: "textExact",
    },
    {
      Header: "Adress",
      accessor: (row, _) => row?.address?.str_representation || null,
      id: "address",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Område",
      accessor: "parking_lot.str_representation",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.parking_lot} urlMethod={lotDetailurl} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastighet",
      accessor: "realestate",

      Cell: ({ value }) => (
        <ToolTipCell text={value?.str_representation || "-"} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
