import constants from "./constants";
const INITIAL_STATE = {
  instance: {},
  exclusionRules: {},
  data: [],
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_INSTANCE: {
      return {
        ...state,
        instance: payload.instance,
      };
    }
    case constants.SET_DATA: {
      return {
        ...state,
        data: payload.data,
      };
    }
    case constants.SET_EXCLUSION_RULES: {
      return {
        ...state,
        exclusionRules: payload.instance,
      };
    }
    default:
      return state;
  }
};
