import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { NumberInput, TextField, TextInput } from "../../../Base/Fields";
import { constants } from "../../../../../store/pipeStages";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Konfigurera steget</FormAreaTitle>
      <FormAreaDescription>
        Ange namn och beskrivning för steget.
      </FormAreaDescription>

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey="name"
        title="Namn på steget"
      />

      <NumberInput
        storeName={storeName}
        method={method}
        fieldKey="order"
        title="Steg i pipen (1 är längst till vänster)"
        description="Ange vilket steg i pipen detta steg är, där 1 är längst till vänster och högre tal är längre höger"
      />

      <TextField
        storeName={storeName}
        method={method}
        fieldKey="description"
        title="Beskrivning"
        rows="5"
      />
    </>
  );
};
