import * as React from "react";
import { useDispatch } from "react-redux";
import {
  billectaStateMap,
  connectCreditToDebit,
} from "../../../store/billectaInvoicing";
import { getOpenDebitInvoicesForDebtor } from "../../../store/billectaSearch";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Table from "../../Billecta/Table/BasicTable";

import { DateCell } from "../../Displays";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { toMoneyString } from "../../utils/stringUtils";
import Modal from "../Base/Modals/Modal";

export default function ConnectToDebitInvoiceForm({
  creditorId,
  closeFunction,
  companyId,
  debtorId,
}) {
  const dispatch = useDispatch();
  const [selectedInvoiceId, setSelectedInvoiceId] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [invoiceOptions, setInvoiceOptions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const connect = () => {
    setLoading(true);
    dispatch(
      connectCreditToDebit({
        connectToId: selectedInvoiceId,
        creditorId,
        companyId,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Kredit kopplades mot vald debet ",
            })
          );
          closeFunction();
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej koppla kredit till vald debet",
            })
          );

          closeFunction();
        },
      })
    );
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(
      getOpenDebitInvoicesForDebtor({
        creditorId,
        debtorId,
        withNonAttested: true,
        successCallback: (invoices) => {
          setLoading(false);
          setInvoiceOptions(invoices);
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta debetfakturor",
            })
          );

          closeFunction();
        },
      })
    );
  }, []);

  const rows = React.useMemo(() => {
    return invoiceOptions || [];
  }, [invoiceOptions]);

  console.log({ rows });

  const cols = React.useMemo(() => {
    return [
      {
        Header: "Status",
        accessor: "Stage",
        Cell: (props) => {
          if (props.row?.original?.IsDisputed) {
            return (
              <StatusLabel state={6}>
                {billectaStateMap[props.value]} (Bestriden)
              </StatusLabel>
            );
          }

          if (props.row?.original?.ActionType === "CreditInvoiceAction") {
            return (
              <StatusLabel state={"Kreditfaktura"}>Kreditfaktura</StatusLabel>
            );
          }
          return (
            <StatusLabel state={billectaStateMap[props.value]}>
              {billectaStateMap[props.value]}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Fakturanr.",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },

      {
        Header: "Fakturadatum",
        accessor: "InvoiceDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "DueDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Fakturerat belopp",
        accessor: "InvoicedAmount.ValueForView",
        Cell: (props) => {
          return <div>{toMoneyString(props.value, true)}</div>;
        },
      },
      {
        Header: "Kvarstående belopp",
        accessor: "CurrentAmount.ValueForView",
        Cell: (props) => {
          return <div>{toMoneyString(props.value, true)}</div>;
        },
      },
      {
        Header: "OCR",
        accessor: "OCR",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },
    ];
  }, [invoiceOptions]);

  return (
    <Modal
      closeFunction={closeFunction}
      title="Välj faktura att koppla kredit till"
      onAccept={connect}
      canAccept={!!selectedInvoiceId}
      acceptTitle="Koppla kredit mot debet"
      denyTitle="Avbryt"
    >
      {loading && <OverlaySpinner />}

      <Table
        columns={cols}
        hideSearch
        selectedRow={selectedRow}
        data={rows}
        onRowClicked={({ original, id }) => {
          setSelectedRow(id);
          setSelectedInvoiceId(original.ActionPublicId);
        }}
      />
    </Modal>
  );
}
