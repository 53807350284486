import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  deleteObject,
  useAnyPermissionCheck,
  useFormInstanceField,
} from "../../../../store/base";

import Table from "../../../Billecta/Table/BasicTable";
import { TextButton } from "../../../Forms/Base/Buttons";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../sharedStyles";
import { toMoneyString } from "../../../utils/stringUtils";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../styles";
import { detailUrl as brfCompanyDetailUrl } from "../../../../store/brfCompanies";
import { useInvoicingCostCenter } from "../../../../store/invoicingCostCenters";
import { useInvoicingProject } from "../../../../store/invoicingProjects";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { useInvoicingSetting } from "../../../../store/invoicingSettings";

import {
  createCostsUrl as createLeaseCostsUrl,
  useLeaseContract,
} from "../../../../store/leaseContracts";
import {
  constants as leaseInvoicingConstants,
  useLeaseInvoicingForm,
  useLeaseInvoicing,
  update as updateLeaseInvoicing,
  destroyPatchForm as leaseDestroyPatchForm,
} from "../../../../store/invoicingLease";
import {
  useOtherInvoicing,
  constants as otherInvoicingConstants,
  useOtherInvoicingForm,
  update as updateOtherInvoicing,
  destroyPatchForm as otherDestroyPatchForm,
} from "../../../../store/invoicingOther";
import {
  useOtherContract,
  createCostsUrl as createOtherCostsUrl,
  useFilteredOtherContracts,
} from "../../../../store/otherContracts";
import {
  useParkingContract,
  createCostsUrl as createParkingCostsUrl,
  useFilteredParkingContracts,
} from "../../../../store/parkingContracts";
import {
  useParkingInvoicing,
  constants as parkingInvoicingConstants,
  update as updateParkingInvoicing,
  useParkingInvoicingForm,
  destroyPatchForm as parkingDestroyPatchForm,
} from "../../../../store/invoicingParking";
import {
  useServiceContract,
  createCostsUrl as createServiceCostsUrl,
} from "../../../../store/serviceContracts";
import {
  useServiceInvoicing,
  constants as serviceInvoicingConstants,
  update as updateServiceInvoicing,
  useServiceInvoicingForm,
} from "../../../../store/invoicingService";

import {
  useBrfPremises,
  createCostsUrl as createBrfPremisesCostsUrl,
} from "../../../../store/brfPremises";
import {
  useBrfInvoicing,
  constants as brfInvoicingConstants,
  update as updateBrfInvoicing,
  useBrfInvoicingForm,
  destroyPatchForm as brfDestroyPatchForm,
} from "../../../../store/invoicingBrf";
import BilledWithContract from "./BilledWithContract";
import { useFilteredApartments } from "../../../../store/apartments";
import { useFilteredIndustrialPremises } from "../../../../store/industrialPremises";
import { useFilteredParkingSpots } from "../../../../store/parkingSpots";
import ConnectedObjectCosts from "./ConnectedObjectCosts";
import { useRealEstate } from "../../../../store/realEstates";

import CostFilters from "./CostFilters";

import {
  constants as costConstants,
  update as updateCost,
  useFilteredCosts,
} from "../../../../store/costs";
import { PlusIcon } from "@heroicons/react/24/outline";
import PrimaryBtn from "../../../Forms/Base/Buttons/PrimaryBtn";
import CostModalForm from "../../../Forms/Costs/ModalForm/ModalForm";
import ConfirmModal from "../../../Forms/Base/Modals/ConfirmModal";
import SelectField from "../../../Forms/Base/Fields/SelectField";
import Modal from "../../../Forms/Base/Modals/Modal";
import costCols, { COST_ROW_STATES, getCostRowState } from "./costCols";
import { DateCell } from "../../../Displays";
import moment from "moment";
import IndexConnectedCosts from "./IndexConnectedCosts";

const getContractHook = (url) => {
  if (url.includes("lease-contracts")) return useLeaseContract;
  if (url.includes("other-contracts")) return useOtherContract;
  if (url.includes("parking-contracts")) return useParkingContract;
  if (url.includes("service-contracts")) return useServiceContract;
  if (url.includes("brf-premises")) return useBrfPremises;

  return null;
};

const getCreateCostsUrlMethod = (url) => {
  if (url.includes("lease-contracts")) return createLeaseCostsUrl;
  if (url.includes("other-contracts")) return createOtherCostsUrl;
  if (url.includes("parking-contracts")) return createParkingCostsUrl;
  if (url.includes("service-contracts")) return createServiceCostsUrl;
  if (url.includes("brf-premises")) return createBrfPremisesCostsUrl;

  return null;
};

const getInvoicingObjHook = (url) => {
  if (url.includes("lease-contracts")) return useLeaseInvoicing;
  if (url.includes("other-contracts")) return useOtherInvoicing;
  if (url.includes("parking-contracts")) return useParkingInvoicing;
  if (url.includes("service-contracts")) return useServiceInvoicing;
  if (url.includes("brf-premises")) return useBrfInvoicing;

  return null;
};
const getConstants = (url) => {
  if (url.includes("lease-contracts")) return leaseInvoicingConstants;
  if (url.includes("other-contracts")) return otherInvoicingConstants;
  if (url.includes("parking-contracts")) return parkingInvoicingConstants;
  if (url.includes("service-contracts")) return serviceInvoicingConstants;
  if (url.includes("brf-premises")) return brfInvoicingConstants;

  return null;
};

const getUpdateMethod = (url) => {
  if (url.includes("lease-contracts")) return updateLeaseInvoicing;
  if (url.includes("other-contracts")) return updateOtherInvoicing;
  if (url.includes("parking-contracts")) return updateParkingInvoicing;
  if (url.includes("service-contracts")) return updateServiceInvoicing;
  if (url.includes("brf-premises")) return updateBrfInvoicing;

  return null;
};

const getFormHook = (url) => {
  if (url.includes("lease-contracts")) return useLeaseInvoicingForm;
  if (url.includes("other-contracts")) return useOtherInvoicingForm;
  if (url.includes("parking-contracts")) return useParkingInvoicingForm;
  if (url.includes("service-contracts")) return useServiceInvoicingForm;
  if (url.includes("brf-premises")) return useBrfInvoicingForm;

  return null;
};

const getAttrName = (url) => {
  if (url.includes("lease-contracts")) return "lease_invoicing";
  if (url.includes("other-contracts")) return "other_invoicing";
  if (url.includes("parking-contracts")) return "parking_invoicing";
  if (url.includes("service-contracts")) return "service_invoicing";
  if (url.includes("brf-premises")) return "brf_invoicing";

  return null;
};

function Costs() {
  const dispatch = useDispatch();
  const [editBaseVat, setEditBaseVat] = React.useState(false);

  const [selectedFilters, setSelectedFilters] = React.useState([
    COST_ROW_STATES.ACTIVE,
    COST_ROW_STATES.COMING,
  ]);

  const canAddCost = useAnyPermissionCheck([
    "add_can_billing",
    "add_can_contract",
  ]);

  const canEditCost = useAnyPermissionCheck([
    "change_can_billing",
    "change_can_contract",
  ]);

  const { url } = useRouteMatch();
  const { push } = useHistory();
  const {
    leaseContractId,
    otherContractId,
    parkingContractId,
    serviceContractId,
    brfPremisesId,
  } = useParams();

  const contractId =
    leaseContractId ||
    otherContractId ||
    parkingContractId ||
    serviceContractId ||
    brfPremisesId;

  const useContractHook = getContractHook(url);
  const createCostUrlMethod = getCreateCostsUrlMethod(url);
  const useInvoicingObjHook = getInvoicingObjHook(url);
  const invoicingConstants = getConstants(url);
  const attrName = getAttrName(url);
  const updateMethod = getUpdateMethod(url);
  const formHook = getFormHook(url);

  const storeName = invoicingConstants.STORE_NAME;
  const [addCostOpen, setAddCostOpen] = React.useState(false);
  const [editCostOpen, setEditCostOpen] = React.useState(false);
  const [deleteCostOpen, setDeleteCostOpen] = React.useState(false);
  const [updateVatLoading, setUpdateVatLoading] = React.useState(false);
  const [showIndexSourceOpen, setShowIndexSourceOpen] = React.useState(false);

  const [contract, contractLoading] = useContractHook(contractId);
  const [realEstate] = useRealEstate(contract?.realestate?.id);

  const apartmentQ = buildQueryString({
    id__in: contract?.apartments?.map((a) => a.id) || [],
  });
  const [apartments, apartmentsLoading] = useFilteredApartments(apartmentQ);
  const indpQ = buildQueryString({
    id__in: contract?.industrial_premises_list?.map((ip) => ip.id) || [],
  });
  const [industrialPremises, industrialPremisesLoading] =
    useFilteredIndustrialPremises(indpQ);

  const parkingQ = buildQueryString({
    id__in: contract?.parking_spots?.map((p) => p.id) || [],
  });
  const [parkingSpots, parkingSpotsLoading] = useFilteredParkingSpots(parkingQ);

  const combinedPremises = [
    ...(apartments?.map((ap) => ({ ...ap, kind: "APARTMENT" })) || []),
    ...(industrialPremises?.map((ap) => ({ ...ap, kind: "INDP" })) || []),
    ...(parkingSpots?.map((ap) => ({ ...ap, kind: "PARKING" })) || []),
  ];

  const [invoicingObj, invoicingObjLoading] = useInvoicingObjHook(
    contract?.[attrName]?.id
  );

  const [objCosts] = useFilteredCosts(
    buildQueryString({ [attrName]: contract?.[attrName]?.id || "-1" })
  );

  const [invoicingSetting, invoicingSettingLoading] = useInvoicingSetting(
    invoicingObj?.setting?.id
  );
  formHook("PATCH", invoicingObj?.id);

  const [defaultProject] = useInvoicingProject(invoicingSetting?.project?.id);
  const [defaultCostCenter] = useInvoicingCostCenter(
    invoicingSetting?.cost_center?.id
  );

  const billWithMainQuery = buildQueryString({
    main_contract_invoicing: leaseContractId || "",
  });

  const [otherContractsBilledWithMain] =
    useFilteredOtherContracts(billWithMainQuery);
  const [parkingContractsBilledWithMain] =
    useFilteredParkingContracts(billWithMainQuery);

  const combinedBilledWithMain = [
    ...(otherContractsBilledWithMain?.map((o) => ({
      ...o,
      contractKind: "other",
    })) || []),
    ...(parkingContractsBilledWithMain?.map((o) => ({
      ...o,
      contractKind: "parking",
    })) || []),
  ];

  const indexations = contract?.indexations;
  const hasIndexSetting = indexations != null ? indexations.length > 0 : false;

  const taxes = contract?.billable_property_taxes;
  const hasTaxes = Object.keys(taxes || {})?.length > 0;

  const isBRF =
    invoicingConstants.STORE_NAME === brfInvoicingConstants.STORE_NAME;
  const isOther =
    invoicingConstants.STORE_NAME === otherInvoicingConstants.STORE_NAME;
  const isService =
    invoicingConstants.STORE_NAME === serviceInvoicingConstants.STORE_NAME;

  const combinedPremisesCostsWithIndexation = combinedPremises
    ?.map((c) => c.cost_set)
    ?.flat()
    ?.filter((c) => !!c.indexation);

  const allCostsWithIndexation = [
    ...(combinedPremisesCostsWithIndexation || []),
    ...(objCosts?.filter((c) => !!c.indextion) || []),
  ];

  const configMissing =
    !contractLoading && !invoicingObjLoading && !invoicingObj; // no cost and invoicing object setup

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const baseVAT = useFormInstanceField({
    storeName,
    fieldKey: "vat",
  });

  const updateBaseVat = () => {
    setUpdateVatLoading(true);
    dispatch(
      updateMethod({
        id: invoicingObj?.id,
        forceData: {
          vat: baseVAT,
        },
        successCallback: () => {
          setUpdateVatLoading(false);
          setEditBaseVat(false);
        },
        errorCallback: () => {
          setUpdateVatLoading(false);
        },
      })
    );
  };

  const handleRowDown = (event, cost) => {
    event.stopPropagation();

    const prevOrder = cost.order || 0;
    const newOrder = prevOrder + 1;

    const costThatPreviuoslyHadOrder = objCosts.find(
      (r) => r.order === newOrder
    );

    // update both rows
    dispatch(
      updateCost({
        id: cost.id,
        forceData: {
          order: newOrder,
        },
        preventDefaultToast: true,
      })
    );

    if (costThatPreviuoslyHadOrder) {
      dispatch(
        updateCost({
          id: costThatPreviuoslyHadOrder.id,
          forceData: {
            order: prevOrder,
          },
          preventDefaultToast: true,
        })
      );
    }
  };

  const handleRowUp = (event, cost) => {
    event.stopPropagation();

    const prevOrder = cost.order || 0;
    const newOrder = prevOrder - 1;

    const costThatPreviuoslyHadOrder = objCosts?.find(
      (r) => r.order === newOrder
    );

    // update both rows
    dispatch(
      updateCost({
        id: cost.id,
        forceData: {
          order: newOrder,
        },
        preventDefaultToast: true,
      })
    );

    if (costThatPreviuoslyHadOrder) {
      dispatch(
        updateCost({
          id: costThatPreviuoslyHadOrder.id,
          forceData: {
            order: prevOrder,
          },
          preventDefaultToast: true,
        })
      );
    }
  };

  React.useEffect(() => {
    return () => {
      dispatch(brfDestroyPatchForm());
      dispatch(leaseDestroyPatchForm());
      dispatch(otherDestroyPatchForm());
      dispatch(parkingDestroyPatchForm());
    };
  }, []);

  const toggleFilter = (filter) => {
    let filterCopy = [...selectedFilters];
    if (filterCopy.includes(filter)) {
      filterCopy = filterCopy.filter((f) => f != filter);
    } else {
      filterCopy.push(filter);
    }

    setSelectedFilters(filterCopy);
  };

  const tableCosts = React.useMemo(() => {
    const costs = cloneDeep(objCosts || []);

    costs.sort((a, b) => {
      return a.order - b.order;
    });

    if (selectedFilters?.length) {
      return costs.filter((fc) => {
        const state = getCostRowState({ costRow: fc, contract, invoicingObj });

        return selectedFilters.includes(state);
      });
    }

    return costs;
  }, [objCosts, invoicingObj, selectedFilters]);

  const tableColumns = React.useMemo(() => {
    const cols = costCols({
      invoicingObj,
      realEstate,
      defaultCostCenter,
      defaultProject,
      setDeleteCostOpen,
      handleRowDown,
      handleRowUp,
    });

    return cols;
  }, [
    invoicingObj,
    realEstate,
    defaultProject,
    defaultCostCenter,
    setDeleteCostOpen,
  ]);

  const handleRemoveCost = (id) => {
    dispatch(
      deleteObject({
        instance: { id },
        constants: costConstants,
      })
    );
  };

  const maxOrder = tableCosts?.[tableCosts?.length - 1]?.order || 0;

  const renderAddons = () => {
    return (
      <div
        style={{
          width: "30%",
          alignSelf: "flex-start",
        }}
      >
        {hasIndexSetting && (
          <DetailPageBox>
            <div className=" text-sm flex items-center font-medium text-gray-900 ">
              Indexuppräkning
            </div>

            <p className="text-xs mb-1 font-normal text-gray-500 bg-transparent">
              Indexuppräkningen baseras på valda inställningar för index på
              debiteringsraderna. Uppräkningarna presenteras utifrån under
              vilken period de gäller. Tryck på en rad för att visa de
              debiteringsrader som leder till uppräkningen.
            </p>

            <div className="flex flex-col space-y-2 mt-2">
              {indexations
                ?.sort((a, b) => (a.start_date < b.start_date ? 1 : -1))
                .map((period) => {
                  // console.log(period, 603);

                  const today = moment();
                  const isCurrent =
                    moment(period.start_date).isSameOrBefore(today) &&
                    moment(period.end_date).isSameOrAfter(today);
                  return (
                    <button
                      onClick={() => setShowIndexSourceOpen(period)}
                      className={`border border-solid text-start focus:ring-sky-200 focus:ring-4 focus:outline-none   ${
                        isCurrent ? "border-primaryblue" : "border-slate-200"
                      } rounded p-2 ${
                        isCurrent ? "bg-primaryblue/5" : "bg-white"
                      }`}
                    >
                      <div className={`flex flex-col `}>
                        <div className="flex items-center">
                          <DateCell hig date={period.start_date} /> -{" "}
                          <DateCell date={period.end_date} />
                        </div>
                      </div>

                      <div className="flex flex-col mt-2">
                        <div className="text-sm font-medium">
                          {toMoneyString(period.value / 12, true)}/månad.
                        </div>
                        <div className="text-xs font-light">
                          {toMoneyString(period.value, true)} totalt under
                          period.
                        </div>
                      </div>
                    </button>
                  );
                })}
            </div>
          </DetailPageBox>
        )}

        {hasTaxes && (
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Fastighetsskatt</OverviewTitle>
                <OverviewSubtitle>
                  Fastighetsskatten räknas fram utifrån angivna inställningar på
                  avtal och fastighet. Nedan visas den aktuella och historiska
                  debiteringar.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {Object.keys(taxes || {}).length === 0 && (
              <InnerBox>Ingen skatt debiteras på detta avtal.</InnerBox>
            )}

            {Object.keys(taxes || {}).map((year) => (
              <InnerBox style={{ marginBottom: 8 }} key={year}>
                <strong>{year.split("-")?.[0] || year}: </strong>
                {toMoneyString((taxes[year] || 0) / 12, true)}/månad
              </InnerBox>
            ))}
          </DetailPageBox>
        )}

        {isBRF && (
          <DetailPageBox>
            {contractLoading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>
                  Medlemsavgifter för nästa debitering
                </OverviewTitle>
                <OverviewSubtitle>
                  Avgifterna kan variera över tid. Exempelvis på grund utav att
                  nya avgifter träder i kraft, gamla träder ur kraft, eller
                  avgifternas debitering är inställda på att automatiskt stängas
                  av när totalt efterfrågat belopp är inbetalt.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {!contract?.fee_previews?.length && (
              <InnerBox>Inga avgifter debiteras för denna lägenhet</InnerBox>
            )}

            {(contract?.fee_previews ?? []).map((preview) => (
              <InnerBox
                style={{ marginBottom: 8 }}
                key={`fee_preview_id_${preview.id}`}
              >
                <strong>
                  {`${preview.title}${
                    preview.is_main_fee ? " (Huvudavgift)" : ""
                  }`}
                  :{" "}
                </strong>
                {toMoneyString(preview.value, true)}
              </InnerBox>
            ))}

            <TextButton
              title="Gå till avgiftsgrupper för föreningen"
              iconType="arrow"
              iconPlacement="right"
              clicked={() =>
                push(
                  brfCompanyDetailUrl({ id: contract?.brf_company?.id }) +
                    "/loans"
                )
              }
            />
          </DetailPageBox>
        )}
      </div>
    );
  };

  return (
    <>
      {deleteCostOpen && (
        <ConfirmModal
          onAccept={() => handleRemoveCost(deleteCostOpen)}
          closeFunction={() => setDeleteCostOpen(false)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Radera debiteringsrad?
          </h3>
          <p>Denna debiteringsrad kommer att tas bort.</p>
        </ConfirmModal>
      )}

      {showIndexSourceOpen && (
        <IndexConnectedCosts
          costIds={showIndexSourceOpen?.costs}
          closeFunction={() => setShowIndexSourceOpen(false)}
          invoicingObj={invoicingObj}
          contract={contract}
        />
      )}

      {addCostOpen && (
        <CostModalForm
          method="POST"
          instance={{
            [attrName]: { id: invoicingObj?.id },
            order: maxOrder + 1,
          }}
          closeFunction={() => setAddCostOpen(false)}
          areaForRentField={contract?.total_area}
        />
      )}
      {editCostOpen?.id && (
        <CostModalForm
          method="PATCH"
          id={editCostOpen.id}
          instance={editCostOpen}
          closeFunction={() => setEditCostOpen(false)}
          areaForRentField={contract?.total_area}
        />
      )}

      {editBaseVat && (
        <Modal
          title="Uppdatera grundmomssats"
          closeFunction={() => setEditBaseVat(false)}
          onAccept={updateBaseVat}
          canAccept={baseVAT != null}
        >
          {updateVatLoading && <OverlaySpinner />}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <SelectField
              title="Grundmomssats (%)"
              description="Momssats som ska ligga till grund för alla rader som EJ har en momssats specificerad. Om en rad har en momssats specificerad gäller den."
              {...{ storeName, method: "PATCH", fieldKey: "vat" }}
            />
          </div>
        </Modal>
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{
              width: isBRF || hasIndexSetting || hasTaxes ? "70%" : "100%",
              maxWidth: isBRF || hasIndexSetting || hasTaxes ? "70%" : "100%",
            }}
          >
            {configMissing ? (
              <div className="flex flex-col justify-center items-center p-12 border border-solid border-slate-200 rounded">
                <div className="text-sm font-medium mb-2">
                  Kostnadsinställning saknas för{" "}
                  {isBRF ? "denna bostadsrätt." : "detta avtal."}
                </div>
                <PrimaryBtn
                  onClick={() =>
                    push(
                      createCostUrlMethod({
                        id: contract?.id,
                        skipSigning: true,
                      })
                    )
                  }
                >
                  {brfPremisesId
                    ? "Ställ in tilläggskostnader för denna bostadsrätt"
                    : "Ställ in debiteringsrader för detta avtal"}
                </PrimaryBtn>
              </div>
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <div>
                    <CostFilters {...{ selectedFilters, toggleFilter }} />
                  </div>

                  <div className="flex items-center space-x-2">
                    <PrimaryBtn secondary onClick={() => setEditBaseVat(true)}>
                      Grundmomssats: {invoicingObj?.vat || 0}%
                    </PrimaryBtn>

                    {canAddCost && (
                      <PrimaryBtn onClick={() => setAddCostOpen(true)}>
                        <PlusIcon width={16} className="mr-1" /> Lägg till rad
                      </PrimaryBtn>
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-xs font-medium mb-1 mt-4">
                    Debiteringsrader på avtal
                  </div>
                  <Table
                    extraStyles={{ marginTop: 0 }}
                    onRowClicked={({ original }) => {
                      if (!canEditCost) return;
                      setEditCostOpen(original);
                    }}
                    columns={tableColumns}
                    data={tableCosts || []}
                    hideSearch
                  />
                </div>
              </>
            )}

            {(!isBRF || combinedBilledWithMain?.length > 0) && <hr />}

            {!isBRF && !isOther && !isService && (
              <ConnectedObjectCosts
                combinedPremises={combinedPremises}
                realEstate={realEstate}
                contract={contract}
                invoicingObj={invoicingObj}
                selectedFilters={selectedFilters}
              />
            )}

            {!isBRF && combinedBilledWithMain?.length > 0 && <hr />}

            {combinedBilledWithMain.map((billedContract) => (
              <BilledWithContract
                key={billedContract.id_number}
                billedContract={billedContract}
                invoicingObj={invoicingObj}
                selectedFilters={selectedFilters}
                realEstate={realEstate}
              />
            ))}
          </DetailPageBox>

          {(hasIndexSetting || isBRF || hasTaxes) && renderAddons()}
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

export default Costs;
