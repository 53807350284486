import * as React from "react";
import { useFormInstanceField } from "../../../../../store/base";
import validationErrorMap from "../../../../Billecta/Invoices/InvoiceComponents/validationErrorMap";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import {
  NumberInput,
  Select,
  TextField,
  TextInput,
} from "../../../Base/Fields";
import CheckField from "../../../Base/Fields/CheckField";
import DateSelect from "../../../Base/Fields/DateSelect";
import LocalDateSelect from "../../../Base/Fields/LocalDateSelect";
import SelectField from "../../../Base/Fields/SelectField";
import TextAreaField from "../../../Base/Fields/TextAreaField";
import TextInputField from "../../../Base/Fields/TextInputField";

export default function Settings({
  title,
  description,
  method,
  storeName,
  creditorHasAutogiro,
  tenantHasAutogiro,
  setPeriodStart,
  periodStart,
  setPeriodEnd,
  periodEnd,
  tenantHasEInvoiceActivated,
  validationErrors,
  isCredit,
}) {
  const sendReminder = useFormInstanceField({
    storeName,
    fieldKey: "ReminderInvoiceDetails.SendReminderInvoice",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>
          {description && <OverviewSubtitle>{description}</OverviewSubtitle>}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="mb-6">
        <TextInputField
          title="Vår referens"
          fieldKey={`OurReference`}
          {...{ method, storeName }}
        />
      </div>

      <div className="grid gap-6 mb-6 grid-cols-2">
        <LocalDateSelect
          title="Period från"
          value={periodStart}
          onChange={(date) => setPeriodStart(date)}
        />

        <LocalDateSelect
          title="Period till"
          value={periodEnd}
          onChange={(date) => setPeriodEnd(date)}
        />

        <DateSelect
          {...{ method, storeName }}
          title="Fakturadatum"
          fieldKey="InvoiceDate"
        />

        <DateSelect
          {...{ method, storeName }}
          title="Förfallodatum"
          fieldKey="DueDate"
          forceError={
            (validationErrors["DueDate"] && validationErrorMap["DueDate"]) ||
            (validationErrors["DueDate_before_today"] &&
              validationErrorMap["DueDate_before_today"])
          }
        />
      </div>

      {!isCredit && (
        <>
          {" "}
          <div className="mb-6">
            <CheckField
              {...{ method, storeName }}
              title="Skicka automatiska påminnelser"
              description="Välj om systemet automatiskt ska skicka påminnelser på förfallna fakturor"
              fieldKey="ReminderInvoiceDetails.SendReminderInvoice"
            />
          </div>
          {sendReminder && (
            <div className="mb-6">
              <TextInputField
                isNumber
                title="Antal dagar innan påminnelse skickas"
                fieldKey={`ReminderInvoiceDetails.DaysDelayAfterDueDate`}
                {...{ method, storeName }}
              />
            </div>
          )}
          <div className="mb-6">
            <CheckField
              disabled={!creditorHasAutogiro || !tenantHasAutogiro}
              {...{ method, storeName }}
              title="Använd autogiro"
              description="Kräver att autogiro är aktiverat på bolag och mottagare"
              fieldKey="Autogiro.AutogiroWithdrawalEnabled"
            />
          </div>
        </>
      )}

      <div className="mb-6">
        <SelectField
          {...{ method, storeName }}
          fieldKey="DeliveryMethod"
          title="Leveransmetod"
          disabled={tenantHasEInvoiceActivated}
          forceError={
            (validationErrors["DeliveryMethod_Email"] &&
              validationErrorMap["DeliveryMethod_Email"]) ||
            (validationErrors["DeliveryMethod_Mail"] &&
              validationErrors["DeliveryMethod_Mail"]) ||
            (validationErrors["DeliveryMethod_Kivra_Disabled"] &&
              validationErrorMap["DeliveryMethod_Kivra_Disabled"]) ||
            (validationErrors["DeliveryMethod_EInvoice_Disabled"] &&
              validationErrorMap["DeliveryMethod_EInvoice_Disabled"])
          }
        />
      </div>

      <TextAreaField
        title="Meddelande på fakturan"
        {...{ method, storeName }}
        fieldKey={`Message`}
      />
    </>
  );
}
