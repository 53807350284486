import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { LinkedObject } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useComponentType, update } from "../../../store/componentTypes";
import { detailUrl as checklistDetailUrl } from "../../../store/checklists";
import { detailUrl as userDetailUrl } from "../../../store/users";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { buildQueryString } from "../../../store/base";
import ProductTypesTable from "../../../components/Tables/ErrandProductTypes/FullTable";
import ComponentTypeForm from "../../../components/Forms/ComponentType/ChapterForm/ModalForm";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import StandardModal from "../../../components/Modals/StandardModal";
import articleListDefs from "../../../components/Tables/ErrandArticles/listDefs";
import {
  constants as articleConstants,
  useErrandArticlesPagination,
  performFilter as performArticleFilter,
  useFilteredErrandArticles,
} from "../../../store/errandArticles";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import Table from "../../../components/Lists/Base/CompleteList/Table";
import { toMoneyString } from "../../../components/utils/stringUtils";

export default function ComponentTypeDetailOverview() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);
  const [addArticlesLoading, setAddArticlesLoading] = React.useState(false);
  const [removeArticlesLoading, setRemoveArticlesLoading] =
    React.useState(false);
  const [componentType] = useComponentType(id);
  const [pickArticlesOpen, setPickArticlesOpen] = React.useState(false);
  const [selectedArticles, setSelectedArticles] = React.useState([]);

  const persistantQuery = {
    component_type: id,
  };

  const articleQ = buildQueryString({
    id__in: componentType?.default_articles?.map((da) => da.id) || [],
  });
  const [defaultArticles, defaultArticlesLoading] =
    useFilteredErrandArticles(articleQ);

  React.useEffect(() => {
    if (pickArticlesOpen) {
      setSelectedArticles(defaultArticles || []);
    }
  }, [pickArticlesOpen]);

  const addSelectedArticles = () => {
    setAddArticlesLoading(true);

    dispatch(
      update({
        forceData: {
          default_articles: selectedArticles,
        },
        id,
        successCallback: () => {
          setSelectedArticles([]);
          setAddArticlesLoading(false);
          setPickArticlesOpen(false);
        },
        errorCallback: () => {
          setAddArticlesLoading(false);
        },
      })
    );
  };

  const checkRowHighlighted = (row) => {
    const { original } = row;
    const match = selectedArticles?.find((s) => s.id === original.id);

    return match;
  };

  const onSelected = (row) => {
    const { original } = row;

    let selectedClone = cloneDeep(selectedArticles);

    if (!selectedClone) {
      selectedClone = [original];
    } else if (selectedClone.find((s) => s.id === original.id)) {
      selectedClone = selectedClone.filter((s) => s.id !== original.id);
    } else {
      selectedClone.push(original);
    }

    setSelectedArticles(selectedClone);
  };

  const removeArticle = (article) => {
    setRemoveArticlesLoading(true);

    const newArticles = componentType?.default_articles?.filter(
      (a) => a.id !== article.id
    );

    dispatch(
      update({
        forceData: {
          default_articles: newArticles,
        },
        id,
        successCallback: () => {
          setRemoveArticlesLoading(false);
        },
        errorCallback: () => {
          setRemoveArticlesLoading(false);
        },
      })
    );
  };

  return (
    <>
      <ComponentTypeForm
        method="PATCH"
        id={id}
        instance={componentType}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <StandardModal
        isOpen={pickArticlesOpen}
        closeFunction={() => setPickArticlesOpen(false)}
        withActionBar
        title="Lägg till kopplade artiklar"
        saveFunction={addSelectedArticles}
      >
        {addArticlesLoading && <OverlaySpinner />}
        <Table
          storeName={articleConstants.STORE_NAME}
          columns={articleListDefs()}
          paginationMethod={useErrandArticlesPagination}
          fetchAllTreshold={25}
          filterAction={performArticleFilter}
          {...{
            hideExport: true,
            hideColumns: true,
            onRowClicked: onSelected,
            checkRowHighlighted,
          }}
        />
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 1, maxWidth: "50%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>
                <OverviewSubtitle>
                  <TextButton
                    iconType="edit"
                    iconPlacement="right"
                    title="Redigera komponenttyp"
                    clicked={() => setEditOpen(true)}
                  />
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getDetailInfoObj({ componentType })} />
          </DetailPageBox>
          <DetailPageBox
            style={{ flex: 1, maxWidth: "50%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Komponentmodeller av denna komponenttyp
              </OverviewTitle>
            </OverviewTitleWrapper>

            <ProductTypesTable persistantQuery={persistantQuery} />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Kopplade artiklar</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Lägg till kopplad artikel"
              clicked={() => setPickArticlesOpen(true)}
            />
          </OverviewTitleWrapper>

          {removeArticlesLoading && <OverlaySpinner />}

          <PremisesTable
            onRowClicked={() => {}}
            headers={["Namn", "Artikelnr.", "Enhet", "Kostnad per enhet", ""]}
            rows={defaultArticles?.map((a) => {
              return [
                a.title,
                a.code,
                a.unit,
                toMoneyString(a.cost_per_unit, true),
                <TextButton
                  title="Ta bort"
                  red
                  clicked={() => removeArticle(a)}
                />,
              ];
            })}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getDetailInfoObj({ componentType }) {
  return {
    Komponenttyp: [
      {
        title: "Namn",
        value: componentType?.name,
      },
      {
        title: "Checklista för felanmälan",
        value: (
          <LinkedObject
            obj={componentType?.report_errand_checklist}
            urlMethod={checklistDetailUrl}
          />
        ),
      },
      {
        title: "Checklista för besiktning",
        value: (
          <LinkedObject
            obj={componentType?.inspection_errand_checklist}
            urlMethod={checklistDetailUrl}
          />
        ),
      },
      {
        title: "Checklista för rondering",
        value: (
          <LinkedObject
            obj={componentType?.rounding_errand_checklist}
            urlMethod={checklistDetailUrl}
          />
        ),
      },
      {
        title: "Rapport skickas till",
        value: (
          <LinkedObject
            obj={componentType?.send_faults_to_user}
            urlMethod={userDetailUrl}
          />
        ),
      },
    ],
  };
}
