import { axiosInstance } from "./axios";

/**
 * Perform a GET request
 */
export async function retrieve({ url, data, taskToken }) {
  if (taskToken) {
    return await axiosInstance.get(url, {
      cancelToken: taskToken.token,
      params: { data },
    });
  }
  return await axiosInstance.get(url, { params: data });
}

/**
 * Perform an OPTIONS request
 */
export async function instructions({ url }) {
  return await axiosInstance.options(url);
}

/**
 * Perform a POST request
 */
export async function upload({ url, data }) {
  return await axiosInstance.post(url, data);
}

/**
 * Perform a PATCH request
 */
export async function update({ url, data }) {
  return await axiosInstance.patch(url, data);
}

/**
 * Perform a PUT request
 */
export async function putUpdate({ url, data }) {
  return await axiosInstance.put(url, data);
}

/**
 * Get delete data (info on what data will be deleted)
 */
export async function fetchAskDeleteData({ url, id }) {
  return await axiosInstance.get(`${url}${id}/ask_delete/`);
}

export async function deleteData({ url, id }) {
  return await axiosInstance.delete(`${url}${id}/`);
}
