import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useApartment } from "../../../../../store/apartments";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../../../store/base";
import { useBrfPremises } from "../../../../../store/brfPremises";
import { useCommonArea } from "../../../../../store/commonAreas";
import { useFilteredErrandComponents } from "../../../../../store/errandComponents";
import { useFilteredErrandCosts } from "../../../../../store/errandCosts";
import { useIndustrialPremises } from "../../../../../store/industrialPremises";
import { useInspectionErrand } from "../../../../../store/inspectionErrands";
import { useParkingSpot } from "../../../../../store/parkingSpots";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import Table from "../../../../Billecta/Table/BasicTable";
import { ERRAND_LIABLE_MAP } from "../../../../Details/OverviewInfo/Costs/ErrandCosts/CostSummary";
import HandleErrandCost from "../../../../Details/OverviewInfo/Costs/ErrandCosts/HandleErrandCost";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { DateCell } from "../../../../Displays";
import { InnerBox } from "../../../../sharedStyles";
import { getCurrentProductTypeUsage } from "../../../../TechnicalManagement/utils";
import { toMoneyString } from "../../../../utils/stringUtils";
import { PrimaryButton } from "../../../Base/Buttons";

export default ({ storeName }) => {
  const dispatch = useDispatch();
  const { inspectionErrandId } = useParams();

  const [handleCostIndex, setHandleCostIndex] = React.useState(null);
  const [hasSetInitial, setHasSetInitial] = React.useState(false);

  const [inspectionErrand, inspectionErrandLoading] =
    useInspectionErrand(inspectionErrandId);

  const [apartment, apartmentLoading] = useApartment(
    inspectionErrand?.apartment?.id
  );
  const [industrialPremises, industrialPremisesLoading] = useIndustrialPremises(
    inspectionErrand?.industrial_premises?.id
  );
  const [commonArea, commonAreaLoading] = useCommonArea(
    inspectionErrand?.common_area?.id
  );
  const [brfPremises, brfPremisesLoading] = useBrfPremises(
    inspectionErrand?.brf_premis?.id
  );
  const [parkingSpot, parkingSpotLoading] = useParkingSpot(
    inspectionErrand?.parking_spot?.id
  );

  const currentPremises =
    apartment || industrialPremises || commonArea || brfPremises || parkingSpot;

  const key = inspectionErrand?.apartment
    ? "apartments"
    : inspectionErrand?.industrial_premises
    ? "industrial_premises_list"
    : inspectionErrand?.common_area
    ? "common_areas"
    : inspectionErrand?.brf_premis
    ? "brf_premises"
    : "parking_spot";

  const componentQ = buildQueryString({
    [key]: currentPremises?.id || "-1",
  });
  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const componentIds = components?.map((c) => c.id);

  const formCosts = useFormInstanceField({
    storeName,
    fieldKey: "costs",
  });

  const costIds = formCosts?.filter((c) => c.id)?.map((c) => c.id);

  const q = buildQueryString({
    id__in: costIds || [],
  });

  const [currentCosts, currentCostsLoading] = useFilteredErrandCosts(q);

  // only set costs if all have an id, else we have done changes
  React.useEffect(() => {
    if (
      !hasSetInitial &&
      currentCosts?.length > 0 &&
      currentCosts?.length === formCosts?.length &&
      currentCosts?.every((c) => !!c.id)
    ) {
      setHasSetInitial(true);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            costs: currentCosts,
          },
        })
      );
    }
  }, [hasSetInitial, currentCosts]);

  const costErrors = useFormError({
    storeName,
    fieldKey: "costs",
  });

  const tableCosts = React.useMemo(() => {
    if (!formCosts) return [];

    return formCosts;
  }, [formCosts]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Ansvarig",
        accessor: "liable_display",
        Cell: ({ value, row }) => {
          return <div>{value || ERRAND_LIABLE_MAP[row.original?.liable]}</div>;
        },
        TotalFooter: () => {
          return <div style={{ fontWeight: 600 }}>Total:</div>;
        },
      },
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Komponent",
        accessor: "component",
        Cell: ({ value }) => {
          const productTypeUsage = getCurrentProductTypeUsage(value);

          return (
            <div>
              {productTypeUsage?.product_type?.str_representation ||
                "Saknar komponentmodell"}
            </div>
          );
        },
      },
      {
        Header: "Artikel",
        accessor: "article.str_representation",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Antal",
        accessor: "unit_amount",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Pris/enhet",
        accessor: "unit_cost",
        Cell: ({ value }) => {
          return <div>{toMoneyString(value, true)}</div>;
        },
      },
      {
        Header: "Summa",
        id: "sum",
        Cell: ({ row }) => {
          return (
            <div>
              {toMoneyString(
                (row.original.unit_amount || 0) * (row.original.unit_cost || 0),
                true
              )}
            </div>
          );
        },
        TotalFooter: ({ row }) => {
          const totalSum = formCosts?.reduce((acc, cur) => {
            const curTot = cur.unit_cost * cur.unit_amount;

            return acc + curTot;
          }, 0);

          return (
            <div style={{ fontWeight: 600 }}>
              {toMoneyString(totalSum, true)}
            </div>
          );
        },
      },
      {
        Header: "Momssats (%)",
        accessor: "vat",
        Cell: ({ value }) => {
          return <div>{value}%</div>;
        },
      },
      {
        Header: "Tillagd",
        accessor: "created_at",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
    ];

    return cols;
  }, [formCosts]);

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  const handleEditCost = (row) => {
    setHandleCostIndex(row.index);
  };

  const handleAddCost = () => {
    const costsCopy = [...(formCosts || [])];
    const newIndex = costsCopy.length;

    costsCopy.push({ order: newIndex + 1 });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          costs: costsCopy,
        },
      })
    );

    setHandleCostIndex(newIndex);
  };

  const handleRemoveCost = (index) => {
    const costsCopy = cloneDeep(formCosts);

    costsCopy.splice(index, 1);

    setHandleCostIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          costs: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );
  };

  const handleEditCostDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostIndex(null);
  };

  return (
    <>
      <HandleErrandCost
        costs={formCosts}
        componentIds={componentIds}
        handleCostIndex={handleCostIndex}
        closeFunction={() => setHandleCostIndex(null)}
        onUpdate={handleEditCostDone}
        method={"PATCH"}
        storeName={storeName}
        onRemove={() => handleRemoveCost(handleCostIndex)}
      />

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Kostnader</OverviewTitle>
          <OverviewSubtitle>
            Ange kostnader som uppstod vid utförande
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>

        <PrimaryButton title="Lägg till" clicked={handleAddCost} />
      </OverviewTitleWrapper>
      {formCosts?.length > 0 ? (
        <Table
          onRowClicked={handleEditCost}
          columns={tableColumns}
          data={tableCosts}
          checkRowError={checkRowError}
          hideSearch
          withTotalSummaryFooter
        />
      ) : (
        <InnerBox
          style={{
            marginBottom: 12,
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            Inga kostnader är tillagda än
            <div style={{ marginTop: 24 }}>
              <PrimaryButton
                title="Lägg till kostnad"
                clicked={() => handleAddCost()}
              />
            </div>
          </>
        </InnerBox>
      )}
    </>
  );
};
