import * as React from "react";
import { usePermissionCheck } from "../../../store/base";
import Toggle from "../../Toggle/Toggle";
import DetailInfo from "../OverviewInfo/DetailInfo/DetailInfo";

function NextErrandInfo({ setting, autoGenOn, onToggle }) {
  const canToggleAutoGen = usePermissionCheck("change_can_goverrand");

  const infoObjProps = {
    onToggle,
    autoGenOn,
    canToggleAutoGen,
  };
  return (
    <>
      <DetailInfo
        infoObj={getInfoObj(setting, infoObjProps)}
        extraChapterStyles={{ marginRight: 0 }}
      />
    </>
  );
}

function getInfoObj(setting, infoObjProps) {
  let data = [
    {
      title: "Automatisk generering",
      value: (
        <Toggle
          value={!infoObjProps?.autoGenOn}
          onToggle={infoObjProps?.onToggle}
          title=""
          addSpace
          isRedGreen
          disabled={!infoObjProps?.canToggleAutoGen}
        />
      ),
    },
    {
      title: "Nästa generering av ärende",
      value: setting?.next_recurrence || "-",
    },
  ];

  const infoObj = {
    "Generering av ärenden": data,
  };

  return infoObj;
}

export default NextErrandInfo;
