import * as React from "react";

// style, design
import { DoubleFieldWrapper } from "../../../Base/Chapters/styles";
import { DatePicker, NumberInput } from "../../../Base/Fields";
import { constants } from "../../../../../store/leads";

import apartmentListDefs from "../../../../Tables/Apartments/listDefs";
import indpListDefs from "../../../../Tables/IndustrialPremises/listDefs";
import parkingListDefs from "../../../../Tables/Parking/ParkingSpots/listDefs";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useParams } from "react-router-dom";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import LeadsTable from "src/components/Tables/Leads/FullTable";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";

export default ({ method, hideObjects }) => {
  const { pipeId } = useParams();
  const { filteredRealEstates } = useActiveFilters();
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Detaljer</OverviewTitle>
          <OverviewSubtitle> Detaljerad information om leaden</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <DoubleFieldWrapper>
        <NumberInput
          storeName={storeName}
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          method={method}
          fieldKey={"value"}
          title="Budget/värde för lead"
        />

        <DatePicker
          storeName={storeName}
          method={method}
          extraStyle={{ minWidth: "290px" }}
          fieldKey={"end_date"}
          title="Förväntat konverteringsdatum"
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <NumberInput
          storeName={storeName}
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          method={method}
          fieldKey={"yearly_income"}
          title="Årsinkomst/Omsättning"
        />

        <NumberInput
          storeName={storeName}
          method={method}
          extraStyle={{ minWidth: "290px" }}
          fieldKey={"yearly_income_year"}
          title="År för årsinkomst/omsättning"
        />
      </DoubleFieldWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          TableComponent={TenantTable}
          persistantQuery={{
            realestate_ids: filteredRealEstates,
          }}
          placeholder="Välj hyresgäst..."
          title="Kopplad hyresgäst"
          method={method}
          fieldKey="tenant"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          TableComponent={LeadsTable}
          persistantQuery={{
            pipe: filteredRealEstates,
          }}
          placeholder="Välj medsökande..."
          title="Medsökande"
          method={method}
          fieldKey="together_with"
        />
      </div>

      {!hideObjects && (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              listDefs={apartmentListDefs}
              persistantQuery={{
                realestate_ids: filteredRealEstates,
              }}
              placeholder="Välj lägenhet..."
              title="Intresserad av lägenhet..."
              method={method}
              fieldKey="apartment"
              TableComponent={ApartmentTable}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              listDefs={indpListDefs}
              persistantQuery={{
                realestate_ids: filteredRealEstates,
              }}
              placeholder="Välj lokal..."
              title="Intresserad av lokal..."
              method={method}
              fieldKey="industrial_premises"
              TableComponent={IndustrialPremisesTable}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              listDefs={parkingListDefs}
              persistantQuery={{
                realestate_ids: filteredRealEstates,
              }}
              placeholder="Välj fordonsplats..."
              title="Intresserad av fordonsplats..."
              method={method}
              fieldKey="parking_spot"
              TableComponent={ParkingSpotsTable}
            />
          </div>
        </>
      )}

      {/* <TextField
        storeName={storeName}
        method={method}
        fieldKey={"notes"}
        title="Anteckningar"
      /> */}
    </>
  );
};
