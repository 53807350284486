export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ROUNDING_COMPONENT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ROUNDING_COMPONENT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ROUNDING_COMPONENT",
  DESTROY_FORM: "DESTROY_FORM_ROUNDING_COMPONENT",
  SET_FORM_ERROR: "SET_FORM_ERROR_ROUNDING_COMPONENT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ROUNDING_COMPONENT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ROUNDING_COMPONENT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ROUNDING_COMPONENT",
  RESET_STATE: "RESET_STATE_ROUNDING_COMPONENT",
  CLEAR_FETCHED: "CLEAR_FETCHED_ROUNDING_COMPONENT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ROUNDING_COMPONENT",

  LIST_URL: "/errands/components/roundingcomponent/list/",
  POST_URL: "/errands/components/roundingcomponent/",
  PATCH_URL: "/errands/components/roundingcomponent/",
  GET_URL: "/errands/components/roundingcomponent/",

  STORE_NAME: "roundingComponents",
};
