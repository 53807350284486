import styled from "styled-components";
import closeIcon from "../../../assets/svg/close-circle.svg";

export const ListWrapper = styled.div`
  background-color: white;
  border: thin solid rgba(0, 0, 0, 0.2);
  width: 100%;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const ListHeader = styled.div`
  padding: 12px;
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const ComponentName = styled.div`
  flex: 2;
`;
export const ComponentArea = styled.div`
  flex: 1;
`;

export const ListItem = styled.div`
  padding: 12px;
  border-bottom: ${(p) => p.theme.borders.standard};
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};

  &:nth-of-type(even) {
    background-color: ${(p) => p.theme.colors.gray1};
  }
`;

export const ListContent = styled.div`
  width: 100%;
  min-height: 120px;
`;

export const ListBottom = styled.div`
  padding: 12px;
  border-top: thin solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const RemoveButton = styled.div`
  margin-left: 12px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeIcon});
`;

export const HeaderSpacer = styled.div`
  width: 36px; ;
`;
