import * as React from "react";

import { Confirm, Delegation } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "DELEGATION":
      return <Delegation {...{ method }} />;

    default:
      return <Confirm />;
  }
};
