import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useTenant } from "../../../store/tenants";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import ErrandsTable from "../../../components/Tables/ReportErrands/FullTableNewVersion";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ReportErrandForm from "../../../components/Forms/ReportErrand/ChapterForm/ModalForm";

function TenantErrands() {
  const { tenantId } = useParams();
  const [tenant] = useTenant(tenantId);

  const [createErrandOpen, setCreateErrandOpen] = React.useState(false);

  const errandsQuery = {
    reported_by: tenant?.user?.id,
  };

  return (
    <>
      <ReportErrandForm
        method="POST"
        isOpen={createErrandOpen}
        onCheckout={() => setCreateErrandOpen(false)}
        instance={{
          _filterObjectsByTenantId: tenantId,
          reported_by: tenant?.user,
        }}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Felanmälan</OverviewTitle>
              <OverviewSubtitle>
                Översikt över felanmälningar rapporterade av denna hyresgäst
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Skapa ny felanmälan"
              clicked={() => setCreateErrandOpen(true)}
            />
          </OverviewTitleWrapper>
          <ErrandsTable persistantQuery={errandsQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export default TenantErrands;
