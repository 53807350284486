import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  useRoundingErrandSettingForm,
  destroyPatchForm,
  destroyPostForm,
  constants,
  detailUrl,
  create,
  update,
} from "../../../../store/roundingErrandSettings";
import { cloneDeep } from "lodash";
import { updateActiveFormInstance } from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";
import DelegationOrder from "../DelegationOrder/DelegationOrder";
import SelectField from "../../Base/Fields/SelectField";
import DateSelect from "../../Base/Fields/DateSelect";
import LocalRadioGroupField from "../../Base/Fields/LocalRadioGroupField";
import PrimaryBtn from "../../Base/Buttons/PrimaryBtn";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import CheckField from "../../Base/Fields/CheckField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import TextAreaField from "../../Base/Fields/TextAreaField";
import TableSelectFieldWithCreate from "../../Base/Fields/TableSelectFieldWithCreate";
import RoundingAreasTable from "src/components/Tables/RoundingAreas/FullTable";
import ComponentTypesTable from "src/components/Tables/ErrandComponentTypes/FullTable";

const INTERVAL_TYPES = {
  MONTH: "MONTH",
  WEEK: "WEEK",
};

export const INTERVAL_ALTERNATIVES = [
  {
    title: "Månadsintervall",
    description: "Ange ett intervall baserat månader och dag i månad.",
    value: INTERVAL_TYPES.MONTH,
  },
  {
    title: "Veckointervall",
    description: "Ange ett intervall baserast på veckor och veckodagar.",
    value: INTERVAL_TYPES.WEEK,
  },
];

export default function RoundingErrandSettingModalForm({
  method,
  id,
  onCheckout,
  instance,
  isGov,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);
  const [intervalType, setIntervalType] = React.useState(null);

  const formLoaded = Boolean(useRoundingErrandSettingForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );

      if (instance.month_interval) {
        setIntervalType(INTERVAL_TYPES.MONTH);
      } else if (instance.week_interval) {
        setIntervalType(INTERVAL_TYPES.WEEK);
      }
    }

    return () => {
      dispatch(destroyPostForm());
      dispatch(destroyPatchForm());
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    onCheckout(success);
  };

  const onSuccess = (data, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "PATCH") {
      dispatch(updateActiveFormInstance({ storeName, data: returned }));
    }

    if (method === "POST") {
      push(detailUrl({ id: returned.id, isGov }));
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  const clearIntervalSetting = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          month_interval: null,
          week_interval: null,
          day_in_month: null,
          weekday: null,
          expected_duration_months: null,
          expected_duration_weeks: null,
        },
      })
    );

    setIntervalType(null);
  };

  React.useEffect(() => {
    return () => {
      dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    };
  }, []);

  return (
    <Modal
      title={
        method === "POST"
          ? "Skapa ronderingsschema"
          : "Uppdatera ronderingsschema"
      }
      closeFunction={onDone}
      onAccept={onSubmit}
    >
      {(!formLoaded || loading) && <OverlaySpinner />}

      <div className="mb-6 text-base font-medium">Ronderingsschema</div>

      {isGov && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <SelectField
            title="Typ av myndighetskrav"
            method={method}
            storeName={storeName}
            fieldKey={"kind"}
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Typ av rondering"
          method={method}
          storeName={storeName}
          fieldKey={"title"}
        />
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"responsible_trustee"}
          persistantQuery={{ user_type__in: [0, 1, 2] }}
          title="Ansvarig förvaltare"
          TableComponent={UsersTable}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <DelegationOrder
          storeName={storeName}
          method={method}
          modalInModal
          goToRoleButton
        />
      </div>

      <hr />
      <div className="mb-6 text-base font-medium">Ronderingsområden</div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          method={method}
          fieldKey="areas"
          title="Ronderingsområden"
          TableComponent={RoundingAreasTable}
          isMany
          createDisplayKey="title"
        >
          {(parentPath, instructionsPath) => (
            <>
              <div className="grid grid-cols-2 gap-2 mb-2">
                <TextInputField
                  storeName={storeName}
                  method={method}
                  fieldKey={`${parentPath}.title`}
                  instructionsKey={`${instructionsPath}.title`}
                  title="Namn på område"
                />
                <TableSelectField
                  title="Fastigheter i område"
                  storeName={storeName}
                  method={method}
                  fieldKey={`${parentPath}.realestates`}
                  instructionsKey={`${instructionsPath}.realestates`}
                  TableComponent={RealEstateTable}
                  isMany
                />
              </div>

              <div className="grid grid-cols-1 gap-2 mb-2">
                <TextAreaField
                  storeName={storeName}
                  method={method}
                  fieldKey={`${parentPath}.description`}
                  instructionsKey={`${instructionsPath}.description`}
                  title="Områdesbeskrivning"
                />
              </div>

              <div className="grid grid-cols-1 gap-6 mb-6">
                <TableSelectField
                  title="Komponettyper som ingår i område"
                  storeName={storeName}
                  method={method}
                  fieldKey={`${parentPath}.component_types`}
                  instructionsKey={`${instructionsPath}.component_types`}
                  TableComponent={ComponentTypesTable}
                  isMany
                />
              </div>
            </>
          )}
        </TableSelectFieldWithCreate>
      </div>

      <hr />
      <div className="mb-6 text-base font-medium">Intervall</div>

      {intervalType === null && (
        <div className="mb-6">
          <LocalRadioGroupField
            value={intervalType}
            onChange={(val) => setIntervalType(val)}
            required
            title="Typ av intervall"
            description="Välj typ av intervall detta ronderingsschema ska använda för generering av ärenden."
            options={INTERVAL_ALTERNATIVES}
            id="_type"
          />
        </div>
      )}

      {intervalType === INTERVAL_TYPES.MONTH && (
        <>
          <div className="mb-6">
            <PrimaryBtn secondary onClick={clearIntervalSetting}>
              <ArrowLeftIcon width={16} className="mr-1" /> Byt typ av intervall
            </PrimaryBtn>
          </div>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <DateSelect
              storeName={storeName}
              method={method}
              fieldKey="start_date"
              title="Startdatum för generering av ärende"
              description="Påbörja generering av ärende från och med ..."
            />

            <TextInputField
              isNumber
              storeName={storeName}
              method={method}
              fieldKey="month_interval"
              title="Månadsintervall för generering av ärende"
              description="Generera ett ärende var ... månad."
            />

            <TextInputField
              isNumber
              storeName={storeName}
              method={method}
              fieldKey="day_in_month"
              title="Dag i månad för generering"
              description="Generera ett ärende den ... i månaden."
            />

            <TextInputField
              isNumber
              storeName={storeName}
              method={method}
              fieldKey="expected_duration_months"
              title="Ärendet förväntas ta ... månader"
              description="Ange hur långt efter generering som ärendet ska förfalla."
            />
          </div>
        </>
      )}

      {intervalType === INTERVAL_TYPES.WEEK && (
        <>
          <div className="mb-6">
            <PrimaryBtn secondary onClick={clearIntervalSetting}>
              <ArrowLeftIcon width={16} className="mr-1" /> Byt typ av intervall
            </PrimaryBtn>
          </div>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <DateSelect
              storeName={storeName}
              method={method}
              fieldKey="start_date"
              title="Startdatum för generering av ärende"
              description="Påbörja generering av ärende från och med ..."
            />
            <TextInputField
              isNumber
              storeName={storeName}
              method={method}
              fieldKey="week_interval"
              title="Veckointervall för generering av ärende"
              description="Generera ett ärende var ... vecka."
            />

            <SelectField
              isNumber
              storeName={storeName}
              forceChoices={[
                {
                  v: 0,
                  d: "Måndag",
                },
                {
                  v: 1,
                  d: "Tisdag",
                },
                {
                  v: 2,
                  d: "Onsdag",
                },
                {
                  v: 3,
                  d: "Torsdag",
                },
                {
                  v: 4,
                  d: "Fredag",
                },
                {
                  v: 5,
                  d: "Lördag",
                },
                {
                  v: 6,
                  d: "Söndag",
                },
              ]}
              method={method}
              fieldKey="weekday"
              title="Veckodag för generering"
              description="Generera ett ärende på ... i vecka för generering."
            />

            <TextInputField
              isNumber
              storeName={storeName}
              method={method}
              fieldKey="expected_duration_days"
              title="Ärendet förväntas ta ... dagar"
              description="Ange hur långt efter generering som ärendet ska förfalla."
            />
          </div>
        </>
      )}

      <div className="mb-6">
        <CheckField
          storeName={storeName}
          method={method}
          fieldKey="generate_from_previously_finished"
          title="Generera nästa ärende utifrån avklarendestatus på tidigare ärende"
          description="Om detta alternativ är aktivt kommer nästa ärende genereras utifrån att det har gått ett intervall sedan senaste avklarandetiden för ett ärende under denna inställning. Om denna inställning ej är aktiv kommer tidigare ej avklarade ärenden markeras som arkiverade när intervallet genererar nästkommande ärende."
        />
      </div>
      <div className="mb-6">
        <CheckField
          storeName={storeName}
          method={method}
          fieldKey="generate_from_previous"
          title="Utgå ifrån startdatumet på det tidigare ärendet vid generering"
          description="Om detta alternativ är aktivt kommer nästa ärende ta hänsyn till startdatumet + intervall från det senaste ärendet under inställningen vid generering. Detta kan leda till bättre upplevelse av ärendegenerering i de fall manuella ärenden genereras mellan de automatiska genereringarna."
        />
      </div>
    </Modal>
  );
}
