import * as React from "react";
import { DateCell } from "../../Displays";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: "status_display",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Beskrivning",
      accessor: "description",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Projektledare",
      accessor: "project_leader",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kontaktperson",
      accessor: "contact_name",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Startdatum",
      accessor: "start_date",
      Cell: ({ value }) => {
        return <DateCell date={value} />;
      },
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Slutdatum",
      accessor: "end_date",
      Cell: ({ value }) => {
        return <DateCell date={value} />;
      },
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
  ];

  return cols;
};
