import { cloneDeep } from "lodash";
import { MASS_CONFIG_OBJECT_TYPES_KEYS } from "../../components/Forms/MassConfigPremisesComponents/FlowForm/Chapters/Type";
import { store } from "../store";
import constants from "./constants";
import * as services from "./services";

export const clearInstance = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_INSTANCE,
      payload: {
        instance: {},
      },
    });
    dispatch({
      type: constants.SET_EXCLUSION_RULES,
      payload: {
        instance: {},
      },
    });
  };
};

export const updateInstance = ({ key, value }) => {
  return async (dispatch) => {
    let instance = cloneDeep(store.getState()[constants.STORE_NAME].instance);

    instance[key] = value;

    dispatch({
      type: constants.SET_INSTANCE,
      payload: {
        instance,
      },
    });
  };
};

export const updateData = ({ newData }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_DATA,
      payload: {
        data: newData,
      },
    });
  };
};

export const clearData = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_DATA,
      payload: {
        data: [],
      },
    });
  };
};

export const updateExclusionRules = ({ key, value }) => {
  return async (dispatch) => {
    let instance = cloneDeep(
      store.getState()[constants.STORE_NAME].exclusionRules
    );

    instance[key] = value;

    dispatch({
      type: constants.SET_EXCLUSION_RULES,
      payload: {
        instance,
      },
    });
  };
};

export const create = ({
  type,
  query,
  data,
  restrictions,
  errorCallback,
  successCallback,
}) => {
  return async (dispatch) => {
    try {
      const url =
        type === MASS_CONFIG_OBJECT_TYPES_KEYS.APARTMENT
          ? constants.APARTMENT_CREATE_URL
          : type === MASS_CONFIG_OBJECT_TYPES_KEYS.INDP
          ? constants.INDP_CREATE_URL
          : type === MASS_CONFIG_OBJECT_TYPES_KEYS.COMMON
          ? constants.COMMON_AREA_CREATE_URL
          : type === MASS_CONFIG_OBJECT_TYPES_KEYS.BRF
          ? constants.BRF_CREATE_URL
          : type === MASS_CONFIG_OBJECT_TYPES_KEYS.REALESTATE
          ? constants.REALESTATE_CREATE_URL
          : type === MASS_CONFIG_OBJECT_TYPES_KEYS.BUILDING
          ? constants.BUILDING_CREATE_URL
          : constants.PARKING_CREATE_URL;

      const res = await services.createMassComponents({
        url,
        query,
        data,
        restrictions,
      });

      successCallback && successCallback(res.data);
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback(e);
    }
  };
};
