export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CUSTOMDIMENSIONENTRIES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CUSTOMDIMENSIONENTRIES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CUSTOMDIMENSIONENTRIES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CUSTOMDIMENSIONENTRIES",
  DESTROY_FORM: "DESTROY_FORM_CUSTOMDIMENSIONENTRIES",
  SET_FORM_ERROR: "SET_FORM_ERROR_CUSTOMDIMENSIONENTRIES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CUSTOMDIMENSIONENTRIES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CUSTOMDIMENSIONENTRIES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CUSTOMDIMENSIONENTRIES",
  RESET_STATE: "RESET_STATE_CUSTOMDIMENSIONENTRIES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CUSTOMDIMENSIONENTRIES",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_CUSTOMDIMENSIONENTRIES",
  CLEAR_FETCHED: "CLEAR_FETCHED_CUSTOMDIMENSIONENTRIES",

  LIST_URL: "/accounting/costs/dimensionentry/list/",
  POST_URL: "/accounting/costs/dimensionentry/",
  PATCH_URL: "/accounting/costs/dimensionentry/",
  GET_URL: "/accounting/costs/dimensionentry/",

  STORE_NAME: "invoicingCustomDimensionEntries",
};
