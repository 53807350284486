import * as React from "react";
import { useSpring } from "@react-spring/web";

import * as SC from "./styles";

export default ({ extraStyle }) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <SC.CoverOverlay style={extraStyle ? { ...props, ...extraStyle } : props}>
      <SC.SpinningCircleContainer viewBox="0 0 50 50">
        <SC.SpinningCircle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke="#93bfec"
          strokeWidth="3"
        />
      </SC.SpinningCircleContainer>
    </SC.CoverOverlay>
  );
};
