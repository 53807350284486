import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/marketRequirements";
import DeleteModal from "../../Forms/Delete/DeleteModal";

export default function MarketRequirementsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  withRemove = false,
}) {
  const [deleteInstance, setDeleteInstance] = React.useState(null);

  const handleRemove = (row) => {
    const { original } = row;
    setDeleteInstance(original);
  };

  const columns = React.useMemo(() => {
    const data = columnDefs(withRemove, handleRemove);
    return data;
  }, [persistantQuery, withRemove, handleRemove]);

  const badges = {};
  const filters = {};

  return (
    <>
      <DeleteModal
        isOpen={!!deleteInstance}
        instance={deleteInstance}
        constants={constants}
        closeFunction={() => setDeleteInstance(null)}
      />

      <BaseTable
        tableId={tableId || "market_requirements_full_table"}
        title={"Kravprofiler"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
