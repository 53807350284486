import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";

export default function LocalTextInputField({
  id,
  value,
  onChange,
  placeholder,
  title,
  description,
  error,
  required,
  isNumber,
  step = "0.01",
  disabled,
}) {
  return (
    <div>
      <label
        htmlFor={id || title}
        className=" text-sm flex items-center font-medium text-gray-900 "
      >
        {error && (
          <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
        )}
        {title}
        {required ? "*" : ""}
      </label>
      {description && (
        <p
          id={`${id || title}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {error && (
        <div className="text-xs mb-1 font-normal text-red-600 ">{error}</div>
      )}
      <input
        type={isNumber ? "number" : "text"}
        step={isNumber ? step : undefined}
        value={value}
        disabled={disabled}
        onChange={({ target: { value } }) => onChange(value)}
        id={id || title}
        className={`${
          disabled ? "bg-slate-100 opacity-80 cursor-not-allowed" : "bg-white"
        } border border-slate-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5`}
        placeholder={placeholder || title}
        required={required}
      />
    </div>
  );
}
