import styled from "styled-components";
import checkIconBlueLight from "../../../../assets/svg/material/check_circle_blue_light.svg";

export const Banner = styled.div`
  background-color: ${(p) => p.theme.colors.primaryBlue};
  margin-top: 24px;
  padding: 12px 24px;
  border-radius: 24px;
  color: ${(p) => p.theme.colors.blueLight};
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  position: relative;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
`;

export const BannerTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  display: flex;
  align-items: center;
`;

export const BannerSubtitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const NoFilterBanner = styled.div`
  background-color: ${(p) => p.theme.colors.primaryBlue};
  min-width: 300px;

  margin: 12px 12px 12px 0;

  padding: 24px;
  border-radius: 30px;
  color: ${(p) => p.theme.colors.blueLight};
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;

  box-shadow: ${(p) => p.theme.boxShadows.default};
  position: relative;
`;

export const FilterActiveIcon = styled.div`
  height: 22px;
  width: 22px;
  margin-left: 3px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${checkIconBlueLight});
`;
