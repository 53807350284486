import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useScreenSize from "../../hooks/useScreenSize";
import Toast from "./Toast";
import {
  removeToast,
  constants,
  lockToast,
  unlockToast,
} from "../../store/toasts";
import * as SC from "./styles";

export default function ToastList() {
  const dispatch = useDispatch();
  const toastList = useSelector(
    (state) => state[constants.STORE_NAME]?.toastList
  );
  const { isMobile } = useScreenSize();

  const handleLockToast = (id) => {
    dispatch(lockToast(id));
  };

  const handleUnlockToast = (id) => {
    dispatch(unlockToast(id));
  };

  const handleRemoveToast = (id) => {
    dispatch(removeToast(id, true));
  };

  const renderToasts = () => {
    if (!toastList?.length) return null;

    return (
      <SC.ListWrapper>
        {toastList?.map((toast) => {
          return (
            <Toast
              key={toast.id}
              {...{
                toast,
                handleUnlockToast,
                handleLockToast,
                handleRemoveToast,
              }}
            />
          );
        })}
      </SC.ListWrapper>
    );
  };

  return <>{renderToasts()}</>;
}
