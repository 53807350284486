import styled, { css } from "styled-components";
import searchIcon from "../../../assets/svg/material/search_black.svg";
import checkCircleIcon from "../../../assets/svg/material/check_circle_green.svg";
import closeCircleIcon from "../../../assets/svg/material/close-circle_red.svg";
import syncIcon from "../../../assets/svg/material/sync_black.svg";
import HomeQLogo from "../../../assets/img/homeq.png";
import { lightOrDark } from "../../DigitalDoc/utils";

export const CustomFieldPill = styled.div`
  align-self: flex-start;
  padding: 2px 6px;
  font-weight: 600;
  font-size: ${(p) => (p.large ? p.theme.fontSizes.headerSmall : "0.8rem")};
  border-radius: 3px;
  color: ${(p) =>
    lightOrDark(p.fieldColor ?? p.theme.colors.blueLight) == "light"
      ? p.theme.colors.primaryText
      : "#FFFFFF"};
  display: inline-flex;
  background-color: ${(p) => p.fieldColor ?? p.theme.colors.blueLight};
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 6px;
`;

export const PipeWrapper = styled.div`
  width: 100vw;
`;

export const SearchBarContainer = styled.div`
  flex: 2;
  max-width: 400px;
  position: relative;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: 12px;
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${searchIcon});
`;

export const SearchBar = styled.input.attrs(({ placeholder }) => ({
  type: "text",
  placeholder: placeholder || "Sök bland leads",
}))`
  padding: 6px 6px 6px 34px;
  border: none;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-bottom: ${(p) => p.theme.borders.standard};
`;

export const HomeQL = styled.div`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${HomeQLogo});
  height: 12px;
  width: 40px;
  margin: 0 4px;
`;

export const StagesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - ${(p) => p.topDistance}px);
  overflow-x: scroll;
  display: flex;
  padding: 0 12px 12px 12px;
`;

export const Stage = styled.div`
  min-width: 270px;
  max-width: 270px;
  padding-right: 10px;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 10px;
    border-right: thin solid whitesmoke;
  }
`;

export const StageHeader = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 12px 8px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border: thin solid whitesmoke;
  border-top: 5px solid ${(p) => p.color};
  border-radius: 5px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const StageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeadContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  ${(p) =>
    p.isCreateStage &&
    css`
      background-color: rgba(0, 0, 0, 0);
    `}
`;

export const CreateStagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;
  margin: 0 auto;
  align-items: start;
`;

export const CreateStagesProposal = styled.div`
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  overflow: hidden;
`;

export const CreateStagesProposalHeader = styled.div`
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const CreateStagesProposalTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
`;
export const CreateStagesProposalSubtitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const CreateStagesProposalContent = styled.div`
  padding: 12px;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const CreateStagesProposalFooter = styled.div`
  border-top: thin solid rgba(0, 0, 0, 0.12);
  background-color: ${(p) => p.theme.colors.primaryBlue};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};

  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
`;

export const LeadBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 5px;
  margin-bottom: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.default};
`;

export const LeadHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: ${(p) => p.theme.borders.standard};
`;

export const LeadImage = styled.div`
  height: 42px;
  width: 42px;
  margin-right: 6px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  background-color: #fff;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(p) =>
    `https://eu.ui-avatars.com/api/?name=${p.name || ""}&background=${
      p.color
    }&color=${p.textColor}`});
`;

export const LeadName = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const LeadFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const LeadDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LeadDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const LeadStageInfo = styled.div`
  font-size: 9px;
  line-height: 100%;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const LeadConfirmedWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LeadConfirmed = styled.div`
  height: 22px;
  width: 22px;
  margin-right: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(p) =>
    p.isDenied ? closeCircleIcon : checkCircleIcon});
`;

export const ActionContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  background-color: ${(p) => p.theme.colors.backgroundGray};
  border-top: thin solid rgba(0, 0, 0, 0.2);
  z-index: 19;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.backgroundGray};
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
`;
export const StagesTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  display: flex;
  justify-content: center;
`;

export const LeadDetailHeader = styled.div`
  padding: 12px 24px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-items: center;
`;

export const PageLink = styled.div`
  padding: 8px 24px;
  border-top-left-radius: 8px;
  position: relative;
  border-top-right-radius: 8px;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.primaryText};
  border-top: thin solid rgba(0, 0, 0, 0);
  border-left: thin solid rgba(0, 0, 0, 0);
  border-right: thin solid rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${(p) => p.theme.colors.secondaryText};
  }

  margin-bottom: -1px;
  &.active {
    color: ${(p) => p.theme.colors.blue};
    background-color: white;
    font-weight: ${(p) => p.theme.fontWeights.headerSmall};
    border-top: thin solid ${(p) => p.theme.colors.gray1};
    border-left: thin solid ${(p) => p.theme.colors.gray1};
    border-right: thin solid ${(p) => p.theme.colors.gray1};
  }
`;

export const InnerLeadDetailContent = styled.div`
  height: calc(100vh - 128px);
  overflow-y: auto;
  padding-bottom: 200px;
`;

export const HomeQInfoLogo = styled.div`
  background-image: url(${HomeQLogo});
  background-position: center;
  background-size: contain;
  height: 30px;
  width: 50px;
  background-repeat: no-repeat;
`;
