import React from "react";
import UserSummary from "./UserSummary";
import PermissionSummary from "./PermissionSummary";

export default function FullSummary({ user, group }) {
  return (
    <div>
      <UserSummary user={user} />
      <PermissionSummary group={group} userId={user?.id} />
    </div>
  );
}
