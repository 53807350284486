import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";
async function patchContract({ url, data }) {
  const resp = axiosInstance.patch(url, data);

  return resp;
}

async function createMany(postData) {
  const resp = axiosInstance.post(constants.LIST_URL, postData);

  return resp;
}

export { patchContract, createMany };
