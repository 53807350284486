export const detailUrl = ({ id }) => {
  return `/parking-lots/detail/${id}`;
};
export const overviewUrl = () => {
  return `/parking/parking-lots`;
};
export const createUrl = () => {
  return `/parking-lots/create`;
};
export const editUrl = ({ id }) => {
  return `/parking-lots/edit/${id}`;
};

export const constructStrRep = (obj) => {
  return obj?.title || "Parkeringsområde";
};
