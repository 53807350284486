import * as React from "react";

import { constants } from "../../../../../store/premisesStatuses";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { ColorPicker, TextInput } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitle>Statusinställning</OverviewTitle>
      </OverviewTitleWrapper>

      <hr />

      <ColorPicker
        title="Färg"
        {...{ storeName, method, fieldKey: "color_code" }}
      />

      <TextInput
        title="Namn på statusen"
        {...{ storeName, method, fieldKey: "name" }}
      />
    </>
  );
};
