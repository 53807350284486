import * as React from "react";
import { PrimaryButton } from "../../../Base/Buttons";
import { constants } from "../../../../../store/invoicingParking";
import { useFormInstanceField } from "../../../../../store/base";

export default ({ onSubmit }) => {
  const storeName = constants.STORE_NAME;

  const billWithLease = useFormInstanceField({
    storeName,
    fieldKey: "bill_with_lease",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        minHeight: 200,
      }}
    >
      <div>
        <PrimaryButton
          title={
            billWithLease
              ? "Spara och gå vidare till debiteringsrader"
              : "Spara och välj aviseringsinställningar"
          }
          clicked={onSubmit}
        />
      </div>
    </div>
  );
};
