export default [
  {
    title: "Komponentmodell",
    key: "PRODUCT_TYPE",
    visited: true,
    hasError: false,
    fieldKeys: [
      "name",
      "code",
      "component_type",
      "report_errand_checklist",
      "inspection_errand_checklist",
      "url_reference",
      "warranty_months",
      "send_faults_to_user",
    ],
  },
];
