const defs = [
  {
    title: "Besiktning",
    key: "INSPECTION",
    visited: true,
    hasError: false,
    fieldKeys: ["components", "execution_ratings"],
  },
  {
    title: "Kostnader",
    key: "COSTS",
    visited: false,
    hasError: false,
    fieldKeys: ["costs"],
  },
  {
    title: "Övrigt",
    key: "COMPLETION",
    visited: false,
    hasError: false,
    fieldKeys: ["completion_comment", "completion_files", "execute_end"],
  },

  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
