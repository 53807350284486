import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import { useReportErrand } from "../../../store/reportErrands";

import ErrandCostSummary, {
  ERRAND_COST_TYPES,
} from "../../../components/Details/OverviewInfo/Costs/ErrandCosts/CostSummary";

export default function ReportErrandCosts() {
  const { reportErrandId } = useParams();
  const [reportErrand, reportErrandLoading] = useReportErrand(reportErrandId);

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          {reportErrand && (
            <ErrandCostSummary
              kind={ERRAND_COST_TYPES.REPORT_ERRAND}
              id={reportErrandId}
              errand={reportErrand}
              costs={reportErrand?.costs}
            />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
