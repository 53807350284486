import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ProductTypeForm from "../../../components/Forms/ProductType/ChapterForm/ModalForm";
import ProductTypesTable from "../../../components/Tables/ErrandProductTypes/FullTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

export default function ProductTypes() {
  const [addOpen, setAddOpen] = React.useState(false);

  return (
    <>
      <ProductTypeForm
        method="POST"
        onCheckout={() => setAddOpen(false)}
        isOpen={addOpen}
      />
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Komponentmodeller</OverviewTitle>
              <OverviewSubtitle>
                Komponentmodeller är en mer specifik kategori på komponenterna i
                systemet och ligger under Komponenttyper. Dessa bestämmer vilken
                produkt komponenten är, fabrikat, artikelnummer, serienummer
                osv. Alla komponenter i systemet måste ha en komponentmodell.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              extraStyle={{ marginLeft: 12 }}
              title="Lägg till komponentmodell"
              clicked={() => setAddOpen(true)}
            />
          </OverviewTitleWrapper>

          <ProductTypesTable />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
