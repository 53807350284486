import * as React from "react";

import { TextButtonIcon } from "../Forms/Base/Buttons/styles";

import classNames from "classnames";

import { TextButton } from "../Forms/Base/Buttons";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default ({ text, title = "", infoStyle = {}, popupClassName = "" }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const isOpenRef = React.useRef(isOpen);
  const wrapperRef = React.useRef();

  React.useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  const open = (evt) => {
    if (evt) evt.stopPropagation();
    setIsOpen(!isOpenRef.current);
  };

  const close = (evt) => {
    if (evt) evt.stopPropagation();
    setIsOpen(false);
  };

  const closeListener = React.useCallback((evt) => {
    if (!isOpenRef.current) return;

    let currentElem = evt.target;
    let close = true;

    while (currentElem.parentElement) {
      if (currentElem === wrapperRef.current) {
        close = false;
        break;
      }
      currentElem = currentElem.parentElement;
    }

    if (close) setIsOpen(false);
  });

  React.useEffect(() => {
    window.addEventListener("click", closeListener);

    return () => {
      window.removeEventListener("click", closeListener);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      style={{ ...infoStyle }}
      className="relative items-center justify-center pt-[3px] pl-[3px]"
    >
      {title.length !== 0 ? (
        <TextButton
          title={title}
          iconType="info"
          clicked={(evt) => open(evt)}
          extraStyle={{
            marginLeft: "12px",
          }}
        />
      ) : (
        <TextButtonIcon
          style={{ cursor: "pointer" }}
          title=""
          iconType="info"
          onClick={(evt) => open(evt)}
        />
      )}

      <div
        className={classNames(
          "absolute top-[100%] z-20 bg-white min-w-[240px] border border-solid border-primaryblue/30 py-2 rounded inline-block flex-col",
          isOpen ? "flex" : "hidden",
          popupClassName
        )}
      >
        <button
          className="absolute right-2.5 top-2.5 cursor-pointer"
          onClick={close}
        >
          <XMarkIcon width={16} />
        </button>
        <p className="text-sm font-semibold px-2">Info</p>
        <div className="left-0 right-0 h-[1px] w-full bg-primaryblue/30 opacity-50 my-2"></div>
        <p className="!text-sm text-slate-700 !font-normal px-2">{text}</p>
      </div>
    </div>
  );
};
