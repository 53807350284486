export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ELVACO",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ELVACO",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ELVACO",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ELVACO",
  DESTROY_FORM: "DESTROY_FORM_ELVACO",
  SET_FORM_ERROR: "SET_FORM_ERROR_ELVACO",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ELVACO",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ELVACO",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ELVACO",
  RESET_STATE: "RESET_STATE_ELVACO",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_ELVACO",
  REMOVE_OBJECT: "REMOVE_OBJECT_ELVACO",
  CLEAR_FETCHED: "CLEAR_FETCHED_ELVACO",

  LIST_URL: "/imd/elvaco/elvacocredential/list/",
  POST_URL: "/imd/elvaco/elvacocredential/",
  PATCH_URL: "/imd/elvaco/elvacocredential/",
  GET_URL: "/imd/elvaco/elvacocredential/",

  STORE_NAME: "elvacoIntegration",
};
