import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InvoicePreviewForm from "../../components/Forms/Invoice/PreviewForm/PreviewForm";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import useQuery from "../../components/utils/useQuery";
import { setActiveFormInstance } from "../../store/base";
import { constants, getCopyInvoice } from "../../store/billectaInvoicing";
import { addToast, TOAST_TYPES } from "../../store/toasts";

export default function CreateInvoice() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [isReady, setIsReady] = React.useState(false);

  // tenant  instance from copied invoices
  const [tenant, setTenant] = React.useState(null);

  const { goBack } = useHistory();
  const query = useQuery();

  // copy from invoice data
  const copyFromId = query.get("copyFrom");
  const newInvoiceDate = query.get("newInvoiceDate");

  // clear form instance before creation
  // if copy, insert cleaned version into form instance
  React.useEffect(() => {
    if (copyFromId) {
      dispatch(
        getCopyInvoice({
          invoiceActionId: copyFromId,
          newInvoiceDate,
          successCallback: ({ tenant }) => {
            setTenant(tenant);
            setIsReady(true);
          },
          errorCallback: () => {
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Något gick fel vid kopiering",
                description: "Försök igen",
              })
            );

            goBack();
          },
        })
      );
    } else {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );

      setIsReady(true);
    }
  }, []);

  if (!isReady) return <FullPageSpinner />;

  return <InvoicePreviewForm method="POST" presetTenant={tenant} />;
}
