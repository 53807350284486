export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_LEASECONTRACT_WITH_COSTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_LEASECONTRACT_WITH_COSTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_LEASECONTRACT_WITH_COSTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_LEASECONTRACT_WITH_COSTS",
  DESTROY_FORM: "DESTROY_FORM_LEASECONTRACT_WITH_COSTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_LEASECONTRACT_WITH_COSTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_LEASECONTRACT_WITH_COSTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_LEASECONTRACT_WITH_COSTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_LEASECONTRACT_WITH_COSTS",
  RESET_STATE: "RESET_STATE_LEASECONTRACT_WITH_COSTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_LEASECONTRACT_WITH_COSTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_LEASECONTRACT_WITH_COSTS",
  SET_EXPECTED_INVOICE_STAT:
    "SET_EXPECTED_INVOICE_STAT_LEASECONTRACT_WITH_COSTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_LEASECONTRACT_WITH_COSTS",

  LIST_URL: "/standard/mainlevel/contracts/leasecontract/list/fullcosts/",

  STORE_NAME: "leaseContractsWithCosts",
};
