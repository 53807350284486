export const constructStrRep = (obj) => {
  return obj.name || obj.contact_name || obj.contact_email || obj.contact_phone;
};

export const overviewUrl = () => {
  return `/service-contracts/service-partners`;
};

export const detailUrl = ({ id }) => {
  return `/service-partners/${id}`;
};
