import * as React from "react";

import Number from "./NumberInput";

export default ({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  noTitle,
  noMargin,
  rowSize,
  disabled,
  children,
  title,
  description,
  placeholder,
}) => {
  const validation = (data) => {
    return data >= 0;
  };
  return (
    <Number
      {...{ noTitle, noMargin, rowSize, title, description, placeholder }}
      storeName={storeName}
      fieldKey={fieldKey}
      method={method}
      validation={validation}
      instructionsKey={instructionsKey}
      disabled={disabled}
    >
      {children && children}
    </Number>
  );
};
