import * as React from "react";

export default () => {
  return (
    <div>
      Kontrollera att uppgifterna stämmer. Tryck sedan på "Registrera
      besiktning" för att spara och gå vidare.
    </div>
  );
};
