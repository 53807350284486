const defs = [
  {
    title: "Avsändare och Avseende",
    key: "GENERAL",
    visited: true,
    hasError: false,
    fieldKeys: [
      "payment_group",
      "brf_premis",
    ],
  },
  {
    title: "Inbetalning",
    key: "PAYMENT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "loan_capital",
      "loan_interest",
      "loan_firm_interest_fee",
      "loan_non_capital",
      "non_loan",
    ],
  },
  {
    title: "Registrering",
    key: "REGISTRATION",
    visited: true,
    hasError: false,
    fieldKeys: [
      "registration_date",
      "performed_date",
      "period_start",
      "period_end",
    ],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
