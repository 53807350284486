import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";
import constants from "./constants";

const STATE = {
    ...INITIAL_STATE,
    serviceContractStatuses: undefined
};

export default (state=STATE, action) => {
    const { type, payload } = action;

    if (type === constants.INSERT_CONTRACT_STATE_STAT) {
        return {...state, serviceContractStatuses:payload.result}
    }

    return baseReducer(state, action, constants);
}