import { cloneDeep, set } from "lodash";
import moment from "moment";
import constants from "./constants";

const INITIAL_STATE = {
  all: [],
  current: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.SET_CURRENT_OBJECT: {
      return handleSetCurrentObject(state, payload);
    }

    case constants.SET_WHITELIST_OBJECT: {
      return handleSetWhitelistObject(state, payload);
    }

    // case constants.GET_WHITELIST_OBJECT: {
    //   return handleGetWhitelistObject(state, payload);
    // }

    case constants.REMOVE_WHITELIST_OBJECT: {
      return handleRemoveWhitelistObject(state, payload);
    }

    case constants.SET_ROWS: {
      return handleSetUpdatedRows(state, payload);
    }

    default:
      return state;
  }
};

const handleSetCurrentObject = (state, payload) => {
  const { groupId, contentType } = payload;
  const stateClone = cloneDeep(state);

  const { current } = stateClone;

  current.groupId = groupId;
  current[contentType] = [];

  return stateClone;
};

const handleSetWhitelistObject = (state, payload) => {
  const { object, contentType } = payload;
  const stateClone = cloneDeep(state);
  const { current } = stateClone;
  current[contentType].push({
    object_str_rep: object?.str_representation,
    object_id: object?.id,
    operations: {
      view: true,
      change: false,
      delete: false,
    },
  });

  return stateClone;
};

const handleRemoveWhitelistObject = (state, payload) => {
  const { object, contentType } = payload;
  const stateClone = cloneDeep(state);

  const { current } = stateClone;

  const newRows = current?.[contentType].filter(
    (r) => r.object_id !== object.id
  );
  stateClone.current[contentType] = newRows;
  return stateClone;
};

const handleSetUpdatedRows = (state, payload) => {
  const { newRows, contentType } = payload;
  const stateClone = cloneDeep(state);
  const { current } = stateClone;
  set(current, contentType, newRows);
  return stateClone;
};
