import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import classNames from "classnames";

export default function SelectField({
  disabled,
  storeName,
  method,
  fieldKey,
  instructionsKey,
  placeholder,
  title,
  description,
  forceError,
  forceRequired,
  forceChoices,
  filterChoices, // function that returns only the available options
  forceInstructions,
  changeCallback,
  tempUseChoiceFilter = false,
}) {
  const dispatch = useDispatch();

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || forceInstructions;

  const allowNull = instructions?._allowNull;
  const required = forceRequired || instructions?._required;

  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey }) || forceError;

  const choices = forceChoices || instructions?._choices || [];

  const filteredChoices = choices.filter((c) => {
    if (tempUseChoiceFilter) {
      return filterChoices(c);
    }

    const filtered = filterChoices ? filterChoices(choices) : choices;

    return filtered;
  });

  const onChange = (data) => {
    if (data === "_clear") {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: null },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: data ? data : allowNull ? null : undefined },
        })
      );
    }
    if (changeCallback) {
      changeCallback(data);
    }
  };

  if (!instructions || instructions?._readOnly) return null;

  return (
    <div>
      <label
        htmlFor={fieldKey}
        className="text-sm font-medium flex items-center text-gray-900 "
      >
        {(error || forceError) && (
          <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
        )}
        {title}
        {required ? "*" : ""}
      </label>
      {description && (
        <p
          id={`${fieldKey}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {(error || forceError) && (
        <div className="text-xs mb-1 font-normal text-red-600 ">
          {forceError || error}
        </div>
      )}
      <select
        disabled={disabled}
        id={fieldKey}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        style={{
          backgroundSize: "1.5em 1.5em",
        }}
        className={classNames(
          "bg-white appearance-none bg-no-repeat bg-[right_0.5rem_center] bg-chevrondown border border-solid border-slate-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ",
          {
            "!bg-gray-100 !text-gray-400 cursor-not-allowed": disabled,
          }
        )}
      >
        <option selected>{placeholder || "Välj ett alternativ..."}</option>
        {filteredChoices?.map((choice) => (
          <option value={choice.v}>{choice.d}</option>
        ))}
        {allowNull && <option value={"_clear"}>Inget valt</option>}
      </select>
    </div>
  );
}
