import baseReducer from "../../billecta/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_DASHBOARD_BILLING_REPORT: {
      return {
        ...newState,
        dashboardBillingReport: payload.data,
        dashboardBillingReportCreditors: payload.creditorIds,
      };
    }

    case constants.SET_DASHBOARD_DUEDATE_REPORT: {
      return {
        ...newState,
        dashboardDueDateReport: payload.data,
        dashboardDueDateReportCreditors: payload.creditorIds,
      };
    }

    case constants.SET_ADVANCED_INCOMING_PAYMENTS: {
      return { ...newState, advancedPaymentsReport: payload.data };
    }

    case constants.SET_INCOMING_DASHBOARD_PAYMENTS: {
      return {
        ...newState,
        dashboardPaymentsReport: payload.data,
        dashboardPaymentsReportCreditors: payload.creditorIds,
      };
    }

    default:
      return newState;
  }
};
