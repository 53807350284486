import * as React from "react";
import { PrimaryButton } from "../../../Base/Buttons";

export default ({ onSubmit, isManualSign }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200,
      }}
    >
      <div>
        <PrimaryButton
          title={
            isManualSign
              ? "Spara uppsägning"
              : "Spara och gå vidare till digitala dokumentet"
          }
          clicked={onSubmit}
        />
      </div>
    </div>
  );
};
