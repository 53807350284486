import { LEAD_RANKING_TYPES } from "./store/actions";

export const detailUrl = ({ id }) => {
  return `/pipes/${id}`;
};
export const overviewUrl = () => {
  return "/pipes";
};

export const getAdTypeForRanking = (ad) => {
  const adType = ad.apartment
    ? LEAD_RANKING_TYPES.APARTMENT
    : ad.industrial_premises
    ? LEAD_RANKING_TYPES.INDP
    : LEAD_RANKING_TYPES.PARKING;

  return adType;
};
export const getAttrForRanking = (ad) => {
  const attr = ad.apartment
    ? "apartment"
    : ad.industrial_premises
    ? "industrial_premises"
    : "parking_spot";

  return attr;
};
