import * as React from "react";

// style, design
// import BaseTable from "src/components/Lists/Base/CompleteList/TableNewVersion";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../../store/parkingContracts";

import { useFrequentPermissions } from "../../../../store/base";

import { BADGE_TYPES } from "../../../Badge/Badge";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function ParkingContractsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  disableBadgeFilterCounts,
}) {
  const { hasBillectaViewPermission } = useFrequentPermissions();

  const columns = React.useMemo(
    () => columnDefs(hasBillectaViewPermission),
    [persistantQuery, hasBillectaViewPermission]
  );

  const filters = {
    Avtalsnummer: {
      queryKey: "id_number",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    //Waiting for backend to add support. (DEV-721)
    // Hyresgäst: {
    //   queryKey: "tenant_name",
    //   type: "text",
    //   operators: OPERATOR_TEMPLATES.SEARCH,
    // },
    //Waiting for backend to add more detailed support (DEV-720)
    // Aviseringsstatus: {
    //   queryKey: "lease_invoicing_active",
    //   type: "boolean",
    //   operators: OPERATOR_TEMPLATES.BOOLEAN,
    // },
    Fastighet: {
      queryKey: "realestate_key",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Startdatum: {
      queryKey: "start_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    Slutdatum: {
      queryKey: "end_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <>
      <BaseTable
        title={"Parkeringsavtal"}
        tableId={tableId || "parkingcontracts_full_table"}
        onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
          disableBadgeFilterCounts,
        }}
      />
    </>
  );
}
