import React from "react";

import * as SC from "../../Layout/Insights/Widgets/styles";

export default function OverviewDiff({ height, width, valueDict, widgetPlacement }) {
  return (
    <SC.InternalComponentContainer width={width} height={height}>
      <SC.InternalComponentFlexContainer flexDirection="column">
        <SC.WidgetDataText>{valueDict.current_value}</SC.WidgetDataText>
        <SC.WidgetTextSupplement>Denna period</SC.WidgetTextSupplement>
        <SC.WidgetTextSeparator />
        <SC.WidgetDataText>{valueDict.diff_value}%</SC.WidgetDataText>
        <SC.WidgetTextSupplement>
          {valueDict.diff_value > 0
            ? "Ökning"
            : valueDict.diff_value < 0
            ? "Minskning"
            : ""}{" "}
          från föregående {valueDict.date_key}
        </SC.WidgetTextSupplement>
        <SC.WidgetTextSeparator />
        <SC.WidgetDataText>{valueDict.average_value}</SC.WidgetDataText>
        <SC.WidgetTextSupplement>
          Snitt, {valueDict.date_key}
        </SC.WidgetTextSupplement>
      </SC.InternalComponentFlexContainer>
    </SC.InternalComponentContainer>
  );
}
