import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useParkingLotForm,
  constants,
  create,
  update,
  destroyPostForm,
  destroyPatchForm,
  detailUrl,
} from "../../../../store/parkingLots";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";

export default function ParkingLotForm({ method = "POST", id }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useParkingLotForm(method, id));
  const { replace } = useHistory();

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(detailUrl({ id: returned.id }));
  };

  React.useEffect(() => {
    return () => {
      if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      } else {
        dispatch(destroyPostForm(false));
      }
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    } else {
      dispatch(destroyPostForm(success));
    }
  };

  const onSubmit = (draft = false) => {
    setLoading(true);

    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: onSuccess,
          errorCallback: onError,
          id,
          preProcess: (data) => preProcess({ data }),
        })
      );
    } else {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: onError,
          preProcess: (data) => preProcess({ data }),
        })
      );
    }
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,

          descriptions,
          method,
          onSubmit,

          title:
            method === "POST"
              ? "Lägg till parkeringsområde"
              : "Uppdatera parkeringsområde",
        }}
      />
    </>
  );
}

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  return copy;
};
