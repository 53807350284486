import * as React from "react";

// style, design
import { LinkedObject, ToolTipCell } from "../../Displays";

import { detailUrl as brfPremisDetailUrl } from "../../../store/brfPremises";
import { detailUrl as paymentGroupDetailUrl } from "../../../store/paymentGroup";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import { toMoneyString } from "../../utils/stringUtils";

export default () => [
  {
    Header: "Lägenhet",
    accessor: "brf_premis",
    Cell: ({ value, row }) => {
      return <LinkedObject obj={value} urlMethod={brfPremisDetailUrl} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Avgiftsgrupp",
    accessor: "payment_group",
    Cell: ({ value, row }) => {
      return <LinkedObject obj={value} urlMethod={paymentGroupDetailUrl} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Andel",
    accessor: "quota",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={`${value.toFixed(5)} %`} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Total årsavgift",
    accessor: "total_yearly_payment_fee",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true, 2)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Total månadsavgift",
    id: "total_monthly_payment_fee",
    Cell: ({ value, row }) => {
      return (
        <ToolTipCell
          text={toMoneyString(
            row.original.total_yearly_payment_fee / 12,
            true,
            2
          )}
        />
      );
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Total kvarvarande skuld",
    accessor: "current_debt_to_pay",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true, 2)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
];
