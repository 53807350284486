import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./errandConfigListDefs";

// store, state
import { constants } from "../../../store/industrialPremises";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  onExcludeClicked,
}) => {
  const columns = React.useMemo(
    () => columnDefs({ onExcludeClicked }),
    [persistantQuery, onExcludeClicked]
  );

  const filters = {};
  const badges = {};

  return (
    <BaseTable
      tableId={tableId || "indp_errand_full_table"}
      title={"Lokaler"}
      // onRowClickedWithDetail={(obj) =>
      //   detailUrl({
      //     id: obj.id,
      //   })
      // }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
