import React from "react";
import * as SC from "../styles";
import { cloneDeep } from "lodash";
import { constants } from "../../../../../store/userGroups";

import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useDispatch } from "react-redux";
import { getModalInformation } from "./infoItems";
import Toggle from "../../../../Toggle/Toggle";

const BASE_ACTIONS = ["view", "add", "change", "delete"];
const BASE_SUBPERM = [
  "reporterrand",
  "inspectionerrand",
  "roundingerrand",
  "goverrand",
];
const allView = [
  "view_can_reporterrand",
  "view_can_inspectionerrand",
  "view_can_roundingerrand",
  "view_can_goverrand",
];
const allAdd = [
  "add_can_reporterrand",
  "add_can_inspectionerrand",
  "add_can_roundingerrand",
  "add_can_goverrand",
];
const allChange = [
  "change_can_reporterrand",
  "change_can_inspectionerrand",
  "change_can_roundingerrand",
  "change_can_goverrand",
];
const allDelete = [
  "delete_can_reporterrand",
  "delete_can_inspectionerrand",
  "delete_can_roundingerrand",
  "delete_can_goverrand",
];

export default function MultiChapterRow({
  title,
  perms,
  showPermsInfo,
  group,
  disabled,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);

  const pickedPerms = group?.permissions;

  const baseViewActive = allView?.every((i) => pickedPerms?.includes(i));
  const baseAddActive = allAdd?.every((i) => pickedPerms?.includes(i));
  const baseChangeActive = allChange?.every((i) => pickedPerms?.includes(i));
  const baseDeleteActive = allDelete?.every((i) => pickedPerms?.includes(i));

  const handleBaseToggle = (p, isActive) => {
    const pickedPermsClone = cloneDeep(pickedPerms || []);
    if (isActive) {
      let newPerms;
      switch (p) {
        case "view": {
          newPerms = pickedPermsClone.filter(
            (p) =>
              ![...allView, ...allAdd, ...allChange, ...allDelete].includes(p)
          );
          break;
        }
        case "add": {
          newPerms = pickedPermsClone.filter((p) => !allAdd.includes(p));
          break;
        }
        case "change": {
          newPerms = pickedPermsClone.filter((p) => !allChange.includes(p));
          break;
        }
        case "delete": {
          newPerms = pickedPermsClone.filter((p) => !allDelete.includes(p));
          break;
        }

        default:
          break;
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            permissions: newPerms,
          },
        })
      );
    } else {
      let newPerms;
      switch (p) {
        case "view": {
          newPerms = [...pickedPermsClone, ...allView];
          break;
        }
        case "add": {
          newPerms = Array.from(
            new Set([...pickedPermsClone, ...allAdd, ...allView])
          );
          break;
        }
        case "change": {
          newPerms = Array.from(
            new Set([...pickedPermsClone, ...allChange, ...allView])
          );
          break;
        }
        case "delete": {
          newPerms = Array.from(
            new Set([...pickedPermsClone, ...allDelete, ...allView])
          );
          break;
        }

        default:
          break;
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            permissions: newPerms,
          },
        })
      );
    }
  };

  const renderBaseToggles = () => {
    const toggles = BASE_ACTIONS.map((p) => {
      let isActive;
      switch (p) {
        case "view":
          isActive = baseViewActive;
          break;
        case "add":
          isActive = baseAddActive;
          break;
        case "change":
          isActive = baseChangeActive;
          break;
        case "delete":
          isActive = baseDeleteActive;
          break;
      }
      return (
        <Toggle
          disabled={disabled}
          isRedGreen
          key={p}
          extraStyle={{ margin: "4px 0" }}
          value={isActive}
          onToggle={() => {
            handleBaseToggle(p, isActive);
          }}
          title=""
          extraStyle={{ flex: 1, justifyContent: "flex-start" }}
        />
      );
    });

    return toggles;
  };

  const handlePermToggle = (p) => {
    let pickedPermsClone = cloneDeep(pickedPerms || []);

    const [kind, can, base] = p.split("_");
    const isView = kind === "view";
    const viewPerm = `view_${can}_${base}`;

    if (pickedPermsClone.includes(p)) {
      if (isView) {
        pickedPermsClone = pickedPermsClone.filter((i) => !i.includes(base));
      } else {
        pickedPermsClone = pickedPermsClone.filter((i) => i !== p);
      }
    } else {
      pickedPermsClone.push(p);

      if (!isView && !pickedPerms?.includes(viewPerm)) {
        pickedPermsClone.push(viewPerm);
      }
    }
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          permissions: pickedPermsClone,
        },
      })
    );
  };

  const renderSubPermToggles = (p) => {
    const keys = Object.keys(perms);
    const toggles = [];
    keys.map((perm) => {
      if (perm === p) {
        perms[perm].map((pp) => {
          if (toggles?.length === 5) {
            toggles.push(<SC.TableFiller />);
          }
          if (toggles.length > 4) return;
          const isActive = pickedPerms?.includes(pp);
          toggles.push(
            <Toggle
              disabled={disabled}
              isRedGreen
              key={pp}
              extraStyle={{ margin: "4px 0" }}
              value={isActive}
              onToggle={() => {
                handlePermToggle(pp);
              }}
              title=""
              extraStyle={{ flex: 1, justifyContent: "flex-start" }}
            />
          );
        });
      }
    });

    return toggles;
  };

  const subPermTitle = (p) => {
    switch (p) {
      case "reporterrand":
        return "Felanmälningar";
      case "inspectionerrand":
        return "Besiktningar";
      case "roundingerrand":
        return "Ronderingar";
      case "goverrand":
        return "Myndighetskrav";
    }
  };

  const renderSubPermRows = () => {
    const subRows = BASE_SUBPERM.map((p) => {
      return (
        <SC.ChapterContentRow key={p}>
          <SC.HeaderTitle
            icon
            style={{ flex: 2 }}
            onClick={() => showPermsInfo(getModalInformation(p))}
          >
            {subPermTitle(p)}
          </SC.HeaderTitle>
          {renderSubPermToggles(p)}
        </SC.ChapterContentRow>
      );
    });
    return subRows;
  };

  return (
    <>
      <SC.ChapterRow>
        <SC.HeaderTitle style={{ flex: 2, paddingLeft: "20px" }}>
          {title}
        </SC.HeaderTitle>
        {renderBaseToggles()}
        <SC.ChapterExpand open={open} onClick={() => setOpen(!open)} />
      </SC.ChapterRow>
      {open && (
        <SC.ChapterContentWrapper open={open}>
          {renderSubPermRows()}
        </SC.ChapterContentWrapper>
      )}
    </>
  );
}
