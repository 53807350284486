import * as React from "react";
import { useHistory, useParams } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import { publishNewProductionUrl } from "../../../../store/marketApartmentAds";
import {
  constants as apartmentAdConstants,
  updateDefaultSettings as apartmentUpdateDefaultSettings,
} from "../../../../store/marketApartmentAds";
import useQuery from "../../../utils/useQuery";

export default function NewProductionAdManyFlowForm() {
  const updateDefaultSettings = apartmentUpdateDefaultSettings;
  const storeName = apartmentAdConstants.STORE_NAME;
  const { push } = useHistory();
  const { pipeId } = useParams();
  const query = useQuery();
  const realEstateId = query.get("realestate");

  const onSubmit = () => {
    push(`${publishNewProductionUrl({ realEstateId, pipeId })}`);
  };

  return (
    <>
      <FlowFormBase
        {...{
          storeName,
          updateDefaultSettings,
          chapterDefs: chapterDefs(),
          chapters,
          descriptions,
          onSubmit,
          title: "Grundinställningar för publicering av annonser",
        }}
      />
    </>
  );
}
