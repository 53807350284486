import * as React from "react";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../components/Displays";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import RenderGroup from "../../components/Forms/UserGroup/Permissions/RenderGroup";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import StandardModal from "../../components/Modals/StandardModal";
import PremisesTable from "../../components/PremisesTable/PremisesTable";
import * as SharedStyles from "../../components/sharedStyles";
import { useAllPermissionCheck } from "../../store/base";
import { useFilteredUserGroups, update } from "../../store/userGroups";
import PageTitle from "../Layouts/PageTitle/PageTitle";

export default () => {
  const dispatch = useDispatch();

  const canChangeGroup = useAllPermissionCheck([
    "view_can_permissions",
    "change_can_permissions",
  ]);

  const [selectOpen, setSelectOpen] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [userGroups] = useFilteredUserGroups("");

  const breadCrumbs = [
    {
      label: "Förvaltning",
    },
  ];
  const defaultGroup = userGroups?.find((ug) => ug.util_user_default);

  const saveDefaultGroup = () => {
    setLoading(true);

    if (defaultGroup) {
      dispatch(
        update({
          id: defaultGroup?.id,
          forceData: {
            util_user_default: false,
          },
          successCallback: () => {
            dispatch(
              update({
                id: selectedGroup?.id,
                forceData: {
                  util_user_default: true,
                },
                successCallback: () => {
                  setLoading(false);
                  setSelectOpen(false);
                  setSelectedGroup(null);
                  setSelectedIndex(null);
                },
                errorCallback: () => {
                  setLoading(false);
                },
              })
            );
          },
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    } else {
      dispatch(
        update({
          id: selectedGroup?.id,
          forceData: {
            util_user_default: true,
          },
          successCallback: () => {
            setLoading(false);
            setSelectOpen(false);
            setSelectedGroup(null);
            setSelectedIndex(null);
          },
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    }
  };

  return (
    <>
      <StandardModal
        title="Välj standardgrupp"
        isOpen={selectOpen}
        closeFunction={() => {
          setSelectOpen(false);
          setSelectedGroup(null);
          setSelectOpen(null);
        }}
        withActionBar
        saveFunction={saveDefaultGroup}
        actionBarAcceptTitle="Spara"
        canAccept={!!selectedGroup}
      >
        {loading && <OverlaySpinner />}
        <PremisesTable
          headers={["Grupp"]}
          rows={userGroups?.map((ug) => [ug.name])}
          onClickRowMapping={userGroups}
          selectedRowIndex={selectedIndex}
          onRowClicked={(group, idx) => {
            setSelectedGroup(group);
            setSelectedIndex(idx);
          }}
        />
      </StandardModal>

      <SharedStyles.BareLayoutWrapper>
        <SharedStyles.BareLayoutTitleWrapper>
          <PageTitle title="Förvaltning" breadCrumbs={breadCrumbs} />
        </SharedStyles.BareLayoutTitleWrapper>

        <SharedStyles.DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Förvaltning</OverviewTitle>
              <OverviewSubtitle>
                Inställningar för förvaltning av detta konto
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <SharedStyles.InnerBox>
            <InfoBox
              title="OBS"
              text="Denna grupp sätts enbart för nya förvaltaranvändare. Förvaltare som redan skapats för detta konto kommer att ha den grupp som sattes på dem när de skapades. Detta kan redigeras under användaren i fråga."
            />
            <OverviewTitleWrapper>
              <OverviewTitle small>Standardgrupp för förvaltare</OverviewTitle>

              {canChangeGroup && (
                <PrimaryButton
                  title={
                    defaultGroup ? "Byt standardgrupp" : "Välj standardgrupp"
                  }
                  clicked={() => setSelectOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            {defaultGroup ? (
              <SharedStyles.InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small>{defaultGroup?.name}</OverviewTitle>
                </OverviewTitleWrapper>

                <RenderGroup group={defaultGroup} disabled />
              </SharedStyles.InnerBox>
            ) : (
              <SharedStyles.InnerBox>
                Standardgrupp ej inställd
              </SharedStyles.InnerBox>
            )}
          </SharedStyles.InnerBox>
        </SharedStyles.DetailPageBox>
      </SharedStyles.BareLayoutWrapper>
    </>
  );
};
