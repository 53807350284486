import * as React from "react";

import { Component } from "./Chapters";

export default ({ key, method, storeName, patchId }) => {
  switch (key) {
    default:
      return (
        <Component method={method} storeName={storeName} patchId={patchId} />
      );
  }
};
