import * as React from "react";
import * as SC from "./styles";

import NonConnectedInputField from "../Forms/Base/Old/NonConnected/NonConnectedInputField";
import { PrimaryButton } from "../Forms/Base/Buttons";
import OverlaySpinner from "../Loaders/OverlaySpinner";

export default ({
  buttonDisabled,
  inputValue,
  onInputChanged,
  onSetPassword,
  loading,
}) => {
  return (
    <SC.Wrapper>
      {loading && <OverlaySpinner />}
      <SC.Header>Återställ lösenord</SC.Header>

      <NonConnectedInputField
        fullWidth
        description="Ange ditt nya lösenord"
        title="Nytt lösenord"
        value={inputValue}
        onChange={onInputChanged}
        type="password"
      />

      <PrimaryButton
        clicked={onSetPassword}
        title="Skapa nytt lösenord"
        disabled={buttonDisabled}
      />
    </SC.Wrapper>
  );
};
