const defs = [
  {
    title: "Mottagare",
    description:
      "Välj vem som ska stå som mottagare på fakturan. Endast hyresgäster och medlemmar som har aviseringsinställningar uppsatta kan väljas.",
    key: "DEBTOR",
    hasError: false,
    fieldKeys: [""],
  },
  {
    title: "Inställningar",
    description:
      "Ange inställningar för fakturan. Systemet autofyller detta i den mån det går utifrån valt bolag och mottagare.",
    key: "SETTINGS",
    hasError: false,
    fieldKeys: [""],
  },
  {
    title: "Debiteringsrader",
    description:
      'Lägg till och hantera de debiteringsrader som ska finnas på fakturan. Tryck på "Lägg till meddelanderad" på en rad för att lägga till ett meddelande.',
    key: "COSTS",
    hasError: false,
    fieldKeys: [""],
  },
  {
    title: "Dröjsmålsränta",
    description:
      "Inställningar för dröjsmålsränta vid försenad eller utebliven betalning.",
    key: "INTEREST",
    hasError: false,
    fieldKeys: [""],
  },
  {
    title: "Kravhantering",
    description:
      "Automatisera kravhantering för fakturan och ange vad som gäller vid utebliven betalning.",
    key: "DEBT_COLLECTION",
    hasError: false,
    fieldKeys: [""],
  },
  {
    title: "Bilagor",
    description: "Bilagor som ska skickas med fakturan",
    key: "ATTACHMENTS",
    hasError: false,
    fieldKeys: [""],
  },
];

export default ({ method, isCredit }) => {
  if (method === "PUT") {
    return defs.filter((d) => d.key !== "DEBTOR");
  } else if (isCredit) {
    return defs.filter(
      (d) =>
        d.key !== "DEBTOR" &&
        d.key !== "INTEREST" &&
        d.key !== "DEBT_COLLECTION"
    );
  } else {
    return defs;
  }
};
