import * as React from "react";
import { useDispatch } from "react-redux";
import { performMultiCreditorReturnSearch } from "../../../store/billectaSearch";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ContractViewInvoicesTable from "../../../components/Billecta/Invoices/ContractViewInvoicesTable";
import { useHistory } from "react-router-dom";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";

export default function InvoicesSearchTable({ invoices }) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [data, setData] = React.useState([]);

  const handleInvoiceRowClicked = (row) => {
    const { original } = row;
    push(
      invoiceDetailUrl({
        invoiceId: original.ActionPublicId,
        creditorId: original.CreditorPublicId,
      })
    );
  };

  React.useEffect(() => {
    const searchInstances = invoices.reduce((acc, cur) => {
      const creditorSearch = acc.find(
        (a) => a.CreditorPublicId === cur.creditor_id
      );

      if (creditorSearch) {
        creditorSearch.SearchValue = `${creditorSearch.SearchValue},${cur.ocr}`;

        return acc;
      } else {
        return [
          ...acc,
          { CreditorPublicId: cur.creditor_id, SearchValue: cur.ocr },
        ];
      }
    }, []);

    dispatch(
      performMultiCreditorReturnSearch({
        searchInstances,
        successCallback: (resp) => {
          const d = resp
            .reduce((acc, cur) => {
              return [...acc, ...(cur.InvoiceActions || [])];
            }, [])
            .flat();
          setData(d);
        },
        errorCallback: (e) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Fakturorna kunde inte hämtas",
            })
          );
        },
      })
    );
  }, []);

  if (!data?.length) return "Laddar...";

  return (
    <ContractViewInvoicesTable
      invoices={data}
      handleRowClicked={handleInvoiceRowClicked}
    />
  );
}
