export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRFCONTRACTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRFCONTRACTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRFCONTRACTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRFCONTRACTS",
  DESTROY_FORM: "DESTROY_FORM_BRFCONTRACTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRFCONTRACTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRFCONTRACTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRFCONTRACTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRFCONTRACTS",
  RESET_STATE: "RESET_STATE_BRFCONTRACTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRFCONTRACTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_BRFCONTRACTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRFCONTRACTS",

  LIST_URL: "/brf/brfcontract/list/",
  POST_URL: "/brf/brfcontract/",
  PATCH_URL: "/brf/brfcontract/",
  GET_URL: "/brf/brfcontract/",
  PROPOSAL_URL: "/accounting/propose/brf_contracts/",

  STORE_NAME: "brfContracts",
};
