import * as React from "react";
import { constants } from "../../../../../store/otherContracts";

import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import TenantNestedChildren from "src/components/Forms/Tenant/NestedChildren";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey="tenant"
          method={method}
          placeholder="Välj hyresgäst..."
          title="Hyresgäst"
          description="Välj motpart hyresgäst på avtalet. Denna motpart är även mottagare av avier för avtalet om avisering är aktiverat."
          TableComponent={TenantTable}
          createDisplayKey="user.first_name"
        >
          {(parentPath) => (
            <TenantNestedChildren
              storeName={storeName}
              method={method}
              parentInstructionsPath={"tenant"}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>
    </>
  );
};
