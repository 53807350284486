import * as React from "react";

// style, design
import {
  AddressField,
  TextInput,
  Select,
  PositiveNumber,
  Checkbox,
  TableSelect,
} from "../Base/Fields";

import {
  useBuildingPagination,
  constants as buildingConstants,
  performFilter,
  useBuilding,
} from "../../../store/buildings";
import listDefs from "../../Tables/Buildings/listDefs";

// store, state
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { useFilteredRealEstates } from "../../../store/realEstates";
import FloorSelector from "../Base/FieldComponents/FloorSelector";
import { useDispatch } from "react-redux";
import TableSelectField from "../Base/Fields/TableSelectField";
import BuildingTable from "src/components/Tables/Buildings/FullTable";

export default function IndustrialPremisesNestledFields({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  const dispatch = useDispatch();

  const floors = useFormInstanceField({
    storeName,
    fieldKey: `${parentPath}.floors`,
  });
  const dynamicFloors = useFormInstanceField({
    storeName,
    fieldKey: `${parentPath}.dynamic_floors`,
  });

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`${parentPath}.floors`]: dynamicFloors },
      })
    );
  }, []);

  return (
    <>
      <TextInput
        title="Objektsnummer"
        storeName={storeName}
        fieldKey={`${parentPath}.premises_id`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.premises_id`}
      />

      <Select
        title="Typ av lokal"
        storeName={storeName}
        fieldKey={`${parentPath}.category`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.category`}
      />

      <PositiveNumber
        title="Area i m&sup2;"
        storeName={storeName}
        fieldKey={`${parentPath}.area`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.area`}
      />

      <FloorSelector
        storeName={storeName}
        fieldKey={`${parentPath}.floors`}
        floors={floors}
      />

      <Checkbox
        title="Objektet är uthyrt i andrahand"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.subletted`}
        instructionsKey={`${parentInstructionsPath || parentPath}.subletted`}
      />
      <Checkbox
        title="Objektet är under renovering"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.renovating`}
        instructionsKey={`${parentInstructionsPath || parentPath}.renovating`}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj byggnad..."
          title="Byggnad"
          method={method}
          fieldKey={`${parentPath}.building`}
          instructionsKey={`${parentInstructionsPath || parentPath}.building`}
          TableComponent={BuildingTable}
        />
      </div>

      <AddressField
        title="Adress"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.address`}
        instructionsKey={`${parentInstructionsPath || parentPath}.address`}
      />
    </>
  );
}
