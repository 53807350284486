import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];

    // errand completion_files
    const errandImages = formInstance?.completion_files;
    const errandImagesPutUrls = responseData?.completion_files?.map(
      (f) => f?.file?.put
    );

    if (errandImages?.length) {
      errandImagesPutUrls.forEach((url, index) => {
        const image = errandImages?.[index];

        if (image?._tempData?.data && url) {
          urls.push(url);
          files.push(image?._tempData?.data);
        }
      });
    }

    // cost files
    let costFiles = [];
    const reportPutUrls =
      responseData?.costs?.map((c) => c?.evidence?.put) || [];

    if (formInstance.costs?.length) {
      formInstance.costs.forEach((c, index) => {
        const currentPutUrl = reportPutUrls[index];

        if (c.evidenceData_ && !!currentPutUrl) {
          costFiles.push(c.evidenceData_);
          urls.push(reportPutUrls[index]);
        }
      });
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
