import * as React from "react";
import { useFilteredRealEstatePortfolios } from "src/store/realEstatePortfolios";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import { useFilteredBrfCompanies } from "../../../store/brfCompanies";
import { useFilteredBuildings } from "../../../store/buildings";
import { useFilteredCompanies } from "../../../store/companies";
import { useFilteredRealEstates } from "../../../store/realEstates";
import * as SC from "./styles";

export default () => {
  const {
    filteredRealEstates,
    filteredCompanies,
    filteredBrfCompanies,
    filteredPortfolioIds,
    resetFilters,
  } = useActiveFilters();

  const portfolioQ = buildQueryString({
    id__in: filteredPortfolioIds || [],
  });

  const [portfolios] = useFilteredRealEstatePortfolios(portfolioQ);

  const brfCompanyQueryString = buildQueryString({
    id__in: filteredBrfCompanies || [],
  });
  const [brfCompanies] = useFilteredBrfCompanies(brfCompanyQueryString);

  const companyQueryString = buildQueryString({
    id__in: [
      ...(filteredCompanies || []),
      ...(brfCompanies?.map((bc) => bc.company?.id) || []),
    ],
  });
  const [companies] = useFilteredCompanies(companyQueryString);

  const realEstateQueryString = buildQueryString({
    id__in: filteredRealEstates || [],
  });
  const [realEstates] = useFilteredRealEstates(realEstateQueryString);

  const buildingQuery = buildQueryString({
    id__in: realEstates
      ?.filter((r) => r.buildings?.length)
      ?.map((r) => r.buildings?.[0]),
  });

  const [buildings] = useFilteredBuildings(buildingQuery);

  const spacerWidth = portfolios?.length
    ? portfolios.length * 10
    : companies?.length
    ? companies.length * 10
    : realEstates.length * 10;

  const renderImages = () => {
    if (companies?.length) {
      return renderCompanyImages();
    } else if (portfolios?.length) {
      return renderPortfolioImages();
    } else {
      return renderRealEstateImages();
    }
  };

  const renderRealEstateImages = () => {
    return realEstates.map((r, idx) => {
      const buildingId = r.buildings?.[0];
      const building = buildings?.find((b) => b.id === buildingId);

      const img =
        building?.image?.get || `https://eu.ui-avatars.com/api/?name=${r.key}`;

      return <SC.FilterImage key={r.id} leftPosition={10 * idx} src={img} />;
    });
  };

  const renderCompanyImages = () => {
    return companies.map((c, idx) => {
      const img =
        c?.image?.get || `https://eu.ui-avatars.com/api/?name=${c.name}`;
      return <SC.FilterImage key={c.id} leftPosition={10 * idx} src={img} />;
    });
  };

  const renderPortfolioImages = () => {
    return portfolios.map((c, idx) => {
      const img = `https://eu.ui-avatars.com/api/?name=${c.title}`;
      return <SC.FilterImage key={c.id} leftPosition={10 * idx} src={img} />;
    });
  };

  const getFilterString = () => {
    if (companies?.length) {
      return `Filtrerar på ${
        filteredBrfCompanies?.length
          ? `förening${companies?.length > 1 ? "arna" : "en"}`
          : `bolag${companies?.length > 1 ? "en" : "et"}`
      } ${companies[0].name} ${
        companies?.length > 1 ? `+${companies?.length - 1}` : ""
      }`;
    } else if (portfolios?.length) {
      return `Filtrerar på portfölj${portfolios?.length > 1 ? "erna" : "en"} ${
        portfolios?.[0]?.title
      } ${portfolios?.length > 1 ? `+${portfolios?.length - 1}` : ""}`;
    } else if (realEstates?.length) {
      return `Filtrerar på fastighet${
        realEstates?.length > 1 ? "erna" : "en"
      } ${realEstates?.[0]?.key} ${
        realEstates?.length > 1 ? `+${realEstates?.length - 1}` : ""
      }`;
    }
  };

  return (
    <SC.Wrapper>
      <SC.InfoSection>
        <SC.FilterImages spacerWidth={spacerWidth}>
          {renderImages()}
        </SC.FilterImages>
        <SC.Title>{getFilterString()}</SC.Title>
      </SC.InfoSection>
      <SC.ResetButton onClick={resetFilters}>Återställ filter</SC.ResetButton>
    </SC.Wrapper>
  );
};
