export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRF_OWNER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRF_OWNER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRF_OWNER",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRF_OWNER",
  DESTROY_FORM: "DESTROY_FORM_BRF_OWNER",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRF_OWNER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRF_OWNER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRF_OWNER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRF_OWNER",
  RESET_STATE: "RESET_STATE_BRF_OWNER",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRF_OWNERS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRF_OWNER",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_BRF_OWNER",

  LIST_URL: "/brf/brfowner/list/",
  POST_URL: "/brf/brfowner/",
  PATCH_URL: "/brf/brfowner/",
  GET_URL: "/brf/brfowner/",

  STORE_NAME: "brfOwner",
};
