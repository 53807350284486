export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_TENANT_PORTAL_SETTINGS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_TENANT_PORTAL_SETTINGS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_TENANT_PORTAL_SETTINGS",
  DESTROY_FORM: "DESTROY_FORM_TENANT_PORTAL_SETTINGS",
  SET_FORM_ERROR: "SET_FORM_ERROR_TENANT_PORTAL_SETTINGS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_TENANT_PORTAL_SETTINGS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_TENANT_PORTAL_SETTINGS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_TENANT_PORTAL_SETTINGS",
  RESET_STATE: "RESET_STATE_TENANT_PORTAL_SETTINGS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_TENANT_PORTAL_SETTINGS",
  REMOVE_OBJECT: "REMOVE_OBJECT_TENANT_PORTAL_SETTINGS",
  CLEAR_FETCHED: "CLEAR_FETCHED_TENANT_PORTAL_SETTINGS",

  LIST_URL: "/accounts/tenants/tenant_portal_setting/list/",
  POST_URL: "/accounts/tenants/tenant_portal_setting/",
  PATCH_URL: "/accounts/tenants/tenant_portal_setting/",
  GET_URL: "/accounts/tenants/tenant_portal_setting/",

  STORE_NAME: "tenantPortalSettings",
};
