import * as React from "react";

import moment from "moment";

const getStartDate = () => {
  return moment().day(0).format("YYYY-MM-DD");
};

const getEndDate = () => {
  return moment().day(0).add(1, "month").format("YYYY-MM-DD");
};

const getDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const STRUCTURES = {
  invoicing_vatreportgenerator: {
    createPath: "invoicing_vatreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar den moms som blivit aviserad avseende en given period. Ni kan här se vad som är aviserat, tillkommer i förskott, avgår förskott samt moms att redovisa för aktuell period. Varje rad i rapporten visar ett bolag eller faktura, beroende på inställningar. ",
  },
  standard_rentrollgenerator: {
    createPath: "standard_rentrollgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "base",
    infoText:
      "Rapporten visar en lista över samtliga aktiva avtal och dess information. I denna rapport kan man välja att inkludera eller exkludera vakanser. Varje rad i rapporten visar ett specifikt avtal eller en specifik vakans, beroende på inställningar.",
  },
  invoicing_customerpicturegenerator: {
    createPath: "invoicing_customerpicturegenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar alla icke betalda avier vid ett givet datum. Välj att ta exkludera ej förfallna avier om ni använder AVI-metoden som bokföringsmetod. Varje rad i rapporten visar en specifik faktura. ",
  },
  invoicing_costsexpandedreportgenerator: {
    createPath: "invoicing_costsexpandedreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "tenants",
    infoText:
      "Rapporten visar total information kopplad till respektive hyresgäst. Varje rad i rapporten visar en debiteringsrad för en specifik hyresgäst och avtal.",
  },
  invoicing_prepaidreportgenerator: {
    createPath: "invoicing_prepaidreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar avier som blivit betalda i en viss period men där intäkten avser en senare period. Varje rad visar en specifik faktura. ",
  },
  standard_realestatereportgenerator: {
    createPath: "standard_realestatereportgenerator",
    createParameters: {
      exclude_main_data: false,
    },
    category: "base",
    infoText:
      "Rapporten visar en lista över fastigheter med tillhörande fastighetsinformation. Varje rad i rapporten visar information om en specifik fastighet.",
  },
  standard_realestatetaxationsreportgenerator: {
    createPath: "standard_realestatetaxationsreportgenerator",
    createParameters: {
      exclude_main_data: false,
    },
    category: "base",
    infoText:
      "Rapporten visar registreringar av historiska fastighetstaxeringar på respektive fastighet. Varje rad i rapporten visar en specifik fastighetstaxering.",
  },
  standard_rentincreasereportgenerator: {
    createPath: "standard_rentincreasereportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "index",
    infoText:
      "Rapporten visar historiska och framtida hyreshöjningar som är registrerade i systemet. Varje rad i rapporten visar en specifik hyreshöjning.",
  },
  standard_indexreportgenerator: {
    createPath: "standard_indexreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "index",
    infoText:
      "Rapporten visar samtliga rader som indexuppräknas. I rapporten framgår samtliga attribut kring hur uppräkningen görs. Varje rad i rapporten visar en specifik debiteringsrad. ",
  },
  standard_vacancyreportgenerator: {
    createPath: "standard_vacancyreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "object",
    infoText:
      "Rapporten visar samtliga vakanta objekt som har varit eller kommer att bli vakant inom det angivna tidsintervallet. Varje rad i rapporten visar ett vakant objekt.",
  },
  standard_evictionsreportgenerator: {
    createPath: "standard_evictionsreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "object",
    infoText:
      "Rapporten visar samtliga in- och utflyttningar under det angivna tidsintervallet. Varje rad i rapporten visar antingen en inflyttning eller utflyttning.",
  },
  invoicing_invoicereminderreportgenerator: {
    createPath: "invoicing_invoicereminderreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar antalet påminnelser för respektive fakturerande bolag i det givna tidsintervallet. Varje rad i rapporten visar information om respektive fakturerande bolag.",
  },
  invoicing_invoicepreviewreportgenerator: {
    createPath: "invoicing_invoicepreviewreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "notification",
    infoText:
      "Rapport över de avier som prognotiseras att ställas ut under en given period. Varje rad i rapporten visar en avi. ",
  },
  invoicing_transactionsreportgenerator: {
    createPath: "invoicing_transactionsreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar en varje enskild bokföringstransaktion som har skett under ett givet intervall. Används vanligtvis för att stämma av mot bokföringssystemet. Varje rad visar en specifik transaktion.",
  },
  invoicing_paymentsreportgenerator: {
    createPath: "invoicing_paymentsreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar alla inbetalningar under ett givet intervall. I rapporten  särskiljs även inbetalningar, överbetalningar och ohanterade betalningar. Varje rad i rapporten visar en inbetalning. ",
  },
  invoicing_taxationscoveragereportgenerator: {
    createPath: "invoicing_taxationscoveragereportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "base",
    infoText:
      "Rapporten visar en sammanställning över fastighetsskatten kopplat till respektive avtal. Varje rad i rapporten visar ett specifikt avtals koppling till debitering av fastighetsskatt.",
  },
  invoicing_invoicedreportgenerator: {
    createPath: "invoicing_invoicedreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar en sammanställning över fastighetsskatten kopplat till respektive avtal. Varje rad i rapporten visar ett specifikt avtals koppling till debitering av fastighetsskatt.",
  },
  invoicing_debtreportgenerator: {
    createPath: "invoicing_debtreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "notification",
    infoText:
      "Rapporten visar samtliga förfallna fakturor/avier som inte har blivit slutbetalda eller krediterade. Varje rad i rapporten visar information om en specifik faktura/avi.",
  },
  standard_vatkeysreportgenerator: {
    createPath: "standard_vatkeysreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "base",
    infoText:
      "Rapporten visar per ett givet datum den momsade arean på respektive objekt samt momsnyckeln (andelen momsad yta) på respektive fastighet och bolag. Varje rad i rapporten, beroende på inställningar, visar ett objekt, en fastighet eller ett bolag.",
  },
  standard_tenantcontactreportgenerator: {
    createPath: "standard_tenantcontactreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "tenants",
    infoText:
      "Rapporten visar kontaktuppgifter till samtliga aktiva hyresgäster. Varje rad i rapporten visar kontaktuppgifter till en specifik hyresgäst.",
  },
  brf_brfmembersreportgenerator: {
    createPath: "brf_brfmembersreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "brf",
    infoText:
      "Medlemsregistret redogör för vilka nuvarande medlemmar som tillhör bostadsrättsföreningar som rapporten tas ut för. Varje rad visar en nuvarande medlem för en specifik bostadsrätt.",
  },
  brf_brfpremisreportgenerator: {
    createPath: "brf_brfpremisreportgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "brf",
    infoText:
      "Objektsförteckningen redogör för samtliga bostadsrätter och dess debiteringsrader. Varje rad i rapporten visar en unik debiteringsrad för en specifik bostadsrätt.",
  },
  brf_pledgesreportgenerator: {
    createPath: "brf_pledgesreportgenerator",
    createParameters: {
      exclude_main_data: false,
    },
    category: "brf",
    infoText:
      "Rapporten visar samtliga pantsättningar för respektive bostadsrätt. Varje rad i rapporten visar en specifik pantsättning.",
  },
  brf_brftransactionsreportgenerator: {
    createPath: "brf_brftransactionsreportgenerator",
    createParameters: {
      exclude_main_data: false,
      start_date: getStartDate(),
      end_date: getEndDate(),
    },
    category: "brf",
    infoText:
      "Rapporten visar genomförda bostadsrättsöverlåtelser inom den angivna tidsperioden. Varje rad i rapporten visar en specifik överlåtelse.",
  },
  standard_objectsgenerator: {
    //Objekslista
    createPath: "standard_objectsgenerator",
    createParameters: {
      exclude_main_data: false,
      date: getDate(),
    },
    category: "object",
    infoText:
      "Rapporten visar hyresobjekt med tillhörande objektsinformaton. Varje rad i rapporten visar information om ett specifikt hyresobjekt.",
  },
};

export const templates = {
  pigello: [
    {
      title: "Fastigheter - Simpel",
      column_order: [
        "realestate_key",
        "realestate_popular_name",
        "realestate_owner",
        "realestate_owner_since",
        "project",
        "cost_center",
        "main_address",
        "other_addresses",
        "land_area",
        "building_amount",
        "total_rentable_area",
        "type_code",
        "taxed_area",
      ],
      report: "standard_realestatereportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "realestate_simple",
    },
    {
      title: "Fastigheter - Utökad",
      column_order: [
        "realestate_key",
        "realestate_popular_name",
        "realestate_owner",
        "realestate_owner_since",
        "project",
        "cost_center",
        "main_address",
        "other_addresses",
        "land_area",
        "responsible_user",
        "building_amount",
        "tenant_amount",
        "apartment_amount",
        "industrial_premises_amount",
        "brf_premises_amount",
        "parking_spot_amount",
        "total_apartment_area",
        "total_industrial_premises_area",
        "total_rentable_area",
        "rent_level_amount_apartment_industrial",
        "rent_level_amount_apartment",
        "rent_level_amount_industrial_premises",
        "rent_level_amount_parking_spot",
        "type_code",
        "taxed_area",
        "taxation_year",
        "taxation_kind",
        "build_housing_value",
        "ground_housing_value",
        "build_premises_value",
        "ground_premises_value",
        "build_industrial_value",
        "ground_industrial_value",
      ],
      report: "standard_realestatereportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "realestate_complex",
    },
    {
      title: "Momsnycklar",
      column_order: [],
      report: "standard_vatkeysreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "vatkey_simple",
    },
    {
      title: "Rent Roll - Simpel",
      column_order: [
        "company",
        "realestate_key",
        "id_number",
        "tenant_name",
        "tenant_legal_id",
        "bi_tenants_name",
        "bi_tenants_legal_id",
        "contract_kind",
        "main_contract",
        "contract_status",
        "is_vacant",
        "start_date",
        "end_date",
        "renewed_to",
        "notify_interval",
        "renew_interval",
        "warning_interval",
        "closed_on",
        "closed_kind",
        "eviction_date",
        "premises_id",
        "apartment_id",
        "premises_kind",
        "premises_category",
        "area",
        "premises_street",
        "premises_postal_code",
        "premises_city",
        "invoice_period",
        "deposition",
        "yearly_reference_rent",
        "yearly_reference_rent_index",
        "yearly_reference_rent_index_m2",
        "yearly_reference_rent_vat",
        "index_base_month",
        "index_base_year",
        "index_amount",
        "index_around_month",
        "index_min_points",
        "index_allow_reduction",
        "index_quota",
        "index_min_val",
        "index_max_val",
        "index_result_value",
        "taxation_quota",
        "taxation_kind",
        "taxation_rent",
        "has_multiple_index_rows",
        "has_imd",
        "yearly_electricity_rent",
        "yearly_electricity_rent_index",
        "yearly_water_rent",
        "yearly_water_rent_index",
        "yearly_warmwater_rent",
        "yearly_warmwater_rent_index",
        "yearly_cold_rent",
        "yearly_cold_rent_index",
        "yearly_heat_rent",
        "yearly_heat_rent_index",
        "yearly_parking_rent",
        "yearly_parking_rent_index",
        "yearly_gas_rent",
        "yearly_gas_rent_index",
        "yearly_ws_rent",
        "yearly_ws_rent_index",
        "yearly_sign_rent",
        "yearly_sign_rent_index",
        "yearly_maintenance_rent",
        "yearly_maintenance_rent_index",
        "yearly_wifi_rent",
        "yearly_wifi_rent_index",
        "yearly_snow_rent",
        "yearly_snow_rent_index",
        "yearly_waste_rent",
        "yearly_waste_rent_index",
        "yearly_cleaning_rent",
        "yearly_cleaning_rent_index",
        "yearly_air_rent",
        "yearly_air_rent_index",
        "yearly_adjustment_rent",
        "yearly_adjustment_rent_index",
        "yearly_errand_rent",
        "yearly_errand_rent_index",
        "yearly_other_rent",
        "yearly_other_rent_index",
        "yearly_addon_index",
        "yearly_discount_year_1_index",
        "yearly_discount_year_2_index",
        "yearly_discount_year_3_index",
        "yearly_discount_year_plus_3",
        "total_yearly_value",
        "contract_rest_liftime_value",
      ], //which columns to start with. Will load all editable columns
      //with the giveen createPath.
      report: "standard_rentrollgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "rentroll_simple",
    },
    {
      title: "Rent Roll - Utökad",
      column_order: [
        "company",
        "realestate_key",
        "realestate_popular_name",
        "building_name",
        "id_number",
        "tenant_name",
        "tenant_legal_id",
        "invoice_street",
        "invoice_postal_code",
        "invoice_city",
        "care_of",
        "your_reference",
        "delivery_method",
        "email_invoice",
        "e_invoice_bank",
        "intermediator",
        "gln",
        "has_autogiro",
        "autogiro_bank",
        "autogiro_payer_number",
        "autogiro_clearing_number",
        "autogiro_account_number",
        "bi_tenants_name",
        "bi_tenants_legal_id",
        "contract_kind",
        "main_contract",
        "contract_status",
        "is_vacant",
        "invoice_status",
        "start_date",
        "end_date",
        "renewed_to",
        "notify_time",
        "notify_interval",
        "renew_interval",
        "warning_interval",
        "closed_on",
        "closed_kind",
        "eviction_date",
        "closed_by",
        "premises_id",
        "apartment_id",
        "premises_kind",
        "premises_category",
        "area",
        "premises_street",
        "premises_postal_code",
        "premises_city",
        "invoice_period",
        "deposition",
        "yearly_reference_rent",
        "yearly_reference_rent_index",
        "yearly_reference_rent_index_m2",
        "yearly_reference_rent_vat",
        "index_base_month",
        "index_base_year",
        "index_amount",
        "index_around_month",
        "index_min_points",
        "index_allow_reduction",
        "index_quota",
        "index_min_val",
        "index_max_val",
        "index_result_value",
        "taxation_quota",
        "taxation_kind",
        "taxation_rent",
        "has_multiple_index_rows",
        "has_imd",
        "yearly_electricity_rent",
        "yearly_electricity_rent_index",
        "yearly_water_rent",
        "yearly_water_rent_index",
        "yearly_warmwater_rent",
        "yearly_warmwater_rent_index",
        "yearly_cold_rent",
        "yearly_cold_rent_index",
        "yearly_heat_rent",
        "yearly_heat_rent_index",
        "yearly_parking_rent",
        "yearly_parking_rent_index",
        "yearly_gas_rent",
        "yearly_gas_rent_index",
        "yearly_ws_rent",
        "yearly_ws_rent_index",
        "yearly_sign_rent",
        "yearly_sign_rent_index",
        "yearly_maintenance_rent",
        "yearly_maintenance_rent_index",
        "yearly_wifi_rent",
        "yearly_wifi_rent_index",
        "yearly_snow_rent",
        "yearly_snow_rent_index",
        "yearly_waste_rent",
        "yearly_waste_rent_index",
        "yearly_cleaning_rent",
        "yearly_cleaning_rent_index",
        "yearly_air_rent",
        "yearly_air_rent_index",
        "yearly_adjustment_rent",
        "yearly_adjustment_rent_index",
        "yearly_errand_rent",
        "yearly_errand_rent_index",
        "yearly_other_rent",
        "yearly_other_rent_index",
        "yearly_addon_index",
        "yearly_discount_year_1_index",
        "yearly_discount_year_2_index",
        "yearly_discount_year_3_index",
        "yearly_discount_year_plus_3",
        "total_yearly_value",
        "contract_rest_liftime_value",
      ], //which columns to start with. Will load all editable columns
      //with the giveen createPath.
      report: "standard_rentrollgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "rentroll_complex",
    },
    {
      title: "Sammanställning Taxeringsinformation",
      column_order: [],
      report: "invoicing_taxationscoveragereportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "taxationscoverage_simple",
    },
    {
      title: "Taxeringshistorik",
      column_order: [],
      report: "standard_realestatetaxationsreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "realestatetaxation_simple",
    },
    {
      title: "Medlemsregister",
      column_order: [],
      report: "brf_brfmembersreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "brfmembers_simple",
    },
    {
      title: "Objektsförteckning",
      column_order: [],
      report: "brf_brfpremisreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "brfpremis_simple",
    },
    {
      title: "Pantsättningar",
      column_order: [],
      report: "brf_pledgesreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "brf_pledges_simple",
    },
    {
      title: "Överlåtelser",
      column_order: [],
      report: "brf_brftransactionsreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "brf_brftransactions_simple",
    },
    {
      title: "Detaljerad hyresgästlista",
      column_order: [],
      report: "invoicing_costsexpandedreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "costsexpanded_simple",
    },
    {
      title: "Kontaktlista hyresgäster",
      column_order: [],
      report: "standard_tenantcontactreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "tenantcontact_simple",
    },
    {
      title: "In och Utflyttningslista",
      column_order: [],
      report: "standard_evictionsreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "evictions_simple",
    },
    {
      title: "Objektslista",
      column_order: [],
      report: "standard_objectsgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "objects_simple",
    },
    {
      title: "Vakansrapport",
      column_order: [],
      report: "standard_vacancyreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "vacancy_simple",
    },
    {
      title: "Detaljerad indexlista",
      column_order: [],
      report: "standard_indexreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "index_simple",
    },
    {
      title: "Hyreshöjningar",
      column_order: [],
      report: "standard_rentincreasereportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "rentincrease_simple",
    },

    {
      title: "Momsrapport",
      column_order: [], //which columns to start with. Will load all editable columns
      //with the giveen createPath.
      report: "invoicing_vatreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      defaults: {
        filters: {},
        grouping: ["billing_company"],
        aggregations: [
          {
            group_by: ["billing_company"],
            modes: ["sum"],
          },
        ],
      },
      baseTemplateName: "vatreport_simple",
    },

    {
      title: "Kundreskontra med åldersfördelning",
      column_order: [],
      report: "invoicing_customerpicturegenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      defaults: {
        filters: {},
        aggregations: [{ group_by: ["billing_company"], modes: ["size"] }],
      },
      baseTemplateName: "customerpicture_simple",
    },
    {
      title: "Förutbetalade intäkter",
      column_order: [],
      report: "invoicing_prepaidreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "prepaidreport_simple",
    },
    {
      title: "Påminnelserapport",
      column_order: [],
      report: "invoicing_invoicereminderreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "invoicing_invoicereminder_simple",
    },
    {
      title: "Aviseringsprognos",
      column_order: [],
      report: "invoicing_invoicepreviewreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "invoicepreview_simple",
    },
    {
      title: "Bokföringsjournal",
      column_order: [],
      report: "invoicing_transactionsreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "transactions_simple",
    },
    {
      title: "Inbetalningsrapport",
      column_order: [],
      report: "invoicing_paymentsreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "payments_simple",
    },

    {
      title: "Periodiserad Försäljning",
      column_order: [],
      report: "invoicing_invoicedreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "invoiced_simple",
    },
    {
      title: "Kravhantering",
      column_order: [],
      report: "invoicing_debtreportgenerator",
      showOnTabs: ["all_reports", "pigello_templates"],
      baseTemplateName: "debt_simple",
    },
  ],
};
