import * as React from "react";
import DescriptionToolTip from "../Forms/Base/Layout/DescriptionToolTip";
import * as SC from "./styles";

export default ({
  value,
  onToggle,
  title,
  extraStyle = {},
  extraTitleStyle = {},
  disabled,
  addSpace,
  isRedGreen,
  description,
  leftToggled,
}) => {
  return (
    <SC.ToggleWrapper style={{ ...extraStyle }} {...{ leftToggled }}>
      {
        <SC.Title
          style={{
            ...extraTitleStyle,
            marginLeft: leftToggled ? 6 : undefined,
          }}
          addSpace={addSpace}
        >
          {title || ""}{" "}
          {description && <DescriptionToolTip {...{ description }} />}
        </SC.Title>
      }

      <SC.ToggleBox {...{ disabled }} onClick={disabled ? null : onToggle}>
        <SC.ToggleDot isRedGreen={isRedGreen} active={!!value} />
      </SC.ToggleBox>
    </SC.ToggleWrapper>
  );
};
