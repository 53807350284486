import * as React from "react";

import Badge, { BADGE_TYPES } from "src/components/Badge/Badge";
import { LinkedObject } from "src/components/Displays";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import { getCurrentProductTypeUsage } from "src/components/TechnicalManagement/utils";
import { detailUrl } from "src/store/reportErrands";
import { detailUrl as realEstateDetailUrl } from "src/store/realEstates";
import { detailUrl as buildingDetailUrl } from "src/store/buildings";
import { detailUrl as commonDetailUrl } from "src/store/commonAreas";
import { detailUrl as apartmentDetailUrl } from "src/store/apartments";
import { detailUrl as indpDetailUrl } from "src/store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "src/store/parkingSpots";
import { detailUrl as componentDetailurl } from "src/store/errandComponents";
import { detailUrl as roundingDetailUrl } from "src/store/roundingErrands";

export default ({ createReportErrandCallback }) => {
  let list = [
    {
      Header: "Status",
      // accessor: "status",
      accessor: "is_ok",
      Cell: ({ row, value }) => {
        return (
          <Badge
            type={
              value === false
                ? BADGE_TYPES.RED
                : value === null
                ? BADGE_TYPES.GRAY
                : BADGE_TYPES.GREEN
            }
          >
            {value === false
              ? "Avvikelse"
              : value === null
              ? "Ej ronderad "
              : "OK"}
          </Badge>
        );
      },
    },
    {
      Header: "Titel",
      // accessor: "status",
      accessor: "title",
      Cell: ({ value, row }) => {
        return (
          <div className="flex flex-col">
            <div className="text-sm font-semibold">{value}</div>
            {row.original.description && (
              <div className="text-xs font-thin">
                {row.original.description.substr(0, 50)}
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Ärende",
      // accessor: "status",
      id: "rounding_errand",
      Cell: ({ row }) => {
        if (!row.original.rounding_errands?.[0]?.id) return "-";
        return (
          <LinkedObject
            obj={{
              id: row.original.rounding_errands?.[0]?.id,
              str_representation: "Visa ärende",
            }}
            urlMethod={roundingDetailUrl}
          />
        );
      },
    },
    {
      Header: "Komponent",
      accessor: "component_placement",
      Cell: ({ value, row }) => {
        const currentProductUsage = getCurrentProductTypeUsage(value);
        const componentType =
          currentProductUsage?.product_type?.component_type?.str_representation;

        return (
          <LinkedObject
            obj={{
              str_representation: componentType,
              id: value?.id,
            }}
            urlMethod={componentDetailurl}
          />
        );
      },
    },
    {
      Header: "Placerad i",
      id: "realestate",
      Cell: ({ row }) => {
        const placement = row.original.component_placement;

        const realEstate = placement?.realestate;
        const parkingSpot = placement?.parking_spot;
        const apartment = placement?.room?.apartment;
        const indp = placement?.room?.industrial_premises;
        const common = placement?.room?.common_area;
        const building = placement?.building;

        const urlMethod = realEstate
          ? realEstateDetailUrl
          : building
          ? buildingDetailUrl
          : parkingSpot
          ? parkingDetailUrl
          : apartment
          ? apartmentDetailUrl
          : indp
          ? indpDetailUrl
          : common
          ? commonDetailUrl
          : null;

        const obj = realEstate
          ? realEstate
          : building
          ? building
          : parkingSpot
          ? parkingSpot
          : apartment
          ? { id: apartment.id, str_representation: "Rum i lägenhet" }
          : indp
          ? { id: indp.id, str_representation: "Rum i lokal" }
          : common
          ? { id: common.id, str_representation: "Rum i gemensamt utrymme" }
          : null;

        return <LinkedObject obj={obj} urlMethod={urlMethod} />;
      },
    },
    {
      Header: "Felanmälan",
      accessor: "fault_report",
      Cell: ({ row, value }) => {
        if (row.original.is_ok !== false) return <div>Ej avvikande</div>;
        if (value) {
          return <LinkedObject obj={value} urlMethod={detailUrl} />;
        } else if (createReportErrandCallback) {
          return (
            <PrimaryBtn
              secondary
              onClick={() => createReportErrandCallback(row.original)}
            >
              Skapa felanmälan
            </PrimaryBtn>
          );
        } else {
          return "Ej felanmält";
        }
      },
    },
  ];

  return list;
};
