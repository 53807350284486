import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { DateCell } from "../../Displays";

const TYPES = {
  Payments: "Betalning",
  Creditations: "Kreditering",
  Overpayments: "Överbetalning",
  UnmatchedPayments: "Omatchad betalning",
  WriteOffs: "Avskrivning",
  Centroundings: "Öresavrundning",
  ReminderPayments: "Påminnelseavgift",
};

export default ({
  reportRows,
  fromDate,
  toDate,
  handleRowClicked,
  companies,
}) => {
  const data = React.useMemo(() => {
    return reportRows;
  }, [reportRows]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Betaldatum",
        accessor: "PaymentDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
        Footer: () => {
          return <Field style={{ fontWeight: 600 }}>Total sida:</Field>;
        },
        TotalFooter: () => {
          return <Field style={{ fontWeight: 600 }}>Total:</Field>;
        },
      },
      {
        Header: "Fakturerande bolag",
        accessor: "CreditorPublicId",
        Cell: (props) => {
          const match = companies?.find((c) => c.billecta_id === props.value);
          return <Field>{match?.str_representation || "-"}</Field>;
        },
      },
      {
        Header: "Fakturerad part",
        accessor: "DebtorName",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Fakturanummer",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Typ",
        accessor: "ValueType",
        Cell: (props) => {
          return <Field>{TYPES[props.value]}</Field>;
        },
      },
      {
        Header: "Belopp (SEK)",
        accessor: "Amount.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.Amount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.rows.reduce((acc, cur) => {
            const val = cur.original.Amount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Betalsätt",
        accessor: "PaymentMeanCode",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Referens",
        accessor: "OCR",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        exportName={`inbetalningsrapport_fakturabetalningar_${fromDate}-${toDate}`}
        withExport
        onRowClicked={handleRowClicked}
        withSummaryFooter
        withTotalSummaryFooter
        tableId="paymentreportinvoicetable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
