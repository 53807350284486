import * as React from "react";
import { EmailTag, LinkedObject, PhoneTag } from "../../Displays";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import DetailInfo from "../OverviewInfo/DetailInfo/DetailInfo";

function FaultLocation({
  reportErrand,
  realEstate,
  building,
  displayedPremises,
  premisesDetailUrl,
  reportedBy,
  realEstateDetailUrl,
  buildingDetailUrl,
  tenantDetailUrl,
  userDetailUrl,
}) {
  const infoObjProps = {
    realEstate,
    building,
    reportedBy,
    displayedPremises,
    premisesDetailUrl,
    realEstateDetailUrl,
    buildingDetailUrl,
    tenantDetailUrl,
    userDetailUrl,
  };
  return (
    <>
      <DetailInfo infoObj={getInfoObj(reportErrand, infoObjProps)} />
    </>
  );
}

function getInfoObj(reportErrand, infoObjProps) {
  const getReportedBy = () => {
    const user = infoObjProps?.reportedBy;

    if (user?.tenant) {
      const obj = {
        id: user.tenant?.id,
        str_representation: user?.str_representation,
      };
      return (
        <LinkedObject obj={obj} urlMethod={infoObjProps?.tenantDetailUrl} />
      );
    }
    return <LinkedObject obj={user} urlMethod={infoObjProps?.userDetailUrl} />;
  };

  const getContactInfo = () => {
    const user = infoObjProps?.reportedBy;

    return {
      phone: user?.phone || "-",
      email: user?.email || "-",
    };
  };

  const getDisplayAddress = () => {
    return reportErrand?.dynamic_address?.base;
  };

  let data = [
    {
      title: "Fastighet",
      value: (
        <LinkedObject
          obj={infoObjProps?.realEstate}
          urlMethod={infoObjProps?.realEstateDetailUrl}
        />
      ),
    },
    {
      title: "Byggnad",
      value: (
        <LinkedObject
          obj={infoObjProps?.building}
          urlMethod={infoObjProps?.buildingDetailUrl}
        />
      ),
    },
    {
      title: "Objekt",
      value: (
        <LinkedObject
          obj={infoObjProps?.displayedPremises}
          urlMethod={infoObjProps?.premisesDetailUrl}
        />
      ),
    },
    {
      title: "Hyresgäst har förberett för tillträde",
      value: (
        <StatusLabel state={reportErrand?.can_use_key ? 0 : 3}>
          {reportErrand?.can_use_key ? "Ja" : "Nej"}
        </StatusLabel>
      ),
    },
    {
      title: "Adress",
      value: getDisplayAddress(),
    },
    {
      title: "Anmälare",
      value: getReportedBy(),
    },
    {
      title: "Epost",
      value: <EmailTag mail={getContactInfo().email} />,
    },
    {
      title: "Telefon",
      value: <PhoneTag phone={getContactInfo().phone} />,
    },
  ];

  const infoObj = {
    "Plats & kontakt": data,
  };

  return infoObj;
}

export default FaultLocation;
