export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CONFIGCENTER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CONFIGCENTER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CONFIGCENTER",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CONFIGCENTER",
  DESTROY_FORM: "DESTROY_FORM_CONFIGCENTER",
  SET_FORM_ERROR: "SET_FORM_ERROR_CONFIGCENTER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CONFIGCENTER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CONFIGCENTER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CONFIGCENTER",
  RESET_STATE: "RESET_STATE_CONFIGCENTER",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CONFIGCENTER",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_CONFIGCENTER",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_CONFIGCENTER",
  CLEAR_FETCHED: "CLEAR_FETCHED_CONFIGCENTER",
  GET_CURRENT_CONFIGCENTER: "GET_CURRENT_CONFIGCENTER",
  SET_CURRENT_CONFIGCENTER: "SET_CURRENT_CONFIGCENTER",

  LIST_URL: "/general/configcenter/list/",
  PATCH_URL: "/general/configcenter/",
  GET_URL: "/general/configcenter/",

  STORE_NAME: "configcenter",
};
