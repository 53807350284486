import Confirm from "./Confirm";
import Apartment from "./Apartment";
import Images from "./Images";
import Costs from "./Costs";
export default {
  APARTMENT: Apartment,
  IMAGES: Images,
  COSTS: Costs,
  CONFIRM: Confirm,
};
