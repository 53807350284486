import * as React from "react";
import { useSelector } from "react-redux";
import {
  constants as homeQConstants,
  useIsHomeQAuthenticated,
} from "../../../store/homeQ";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../../Forms/Base/Buttons";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { InnerBox } from "../../sharedStyles";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import NonConnectedTextField from "../../Forms/Base/Old/NonConnected/NonConnectedTextField";
import { buildAcceptByObjectObj } from "./ConfirmLeadModal";
import { usePermissionCheck } from "../../../store/base";

const STEPS = {
  BASE: 1,
  HOMEQ_REJECTION_REASON: 2,
  SBF_REJECTION_REASON: 3,
  CONFIRM: 4,
};

export default function RejectLeadModal({
  callback,
  openLeadCallback,
  loading,
  isOpen,
  leads,
  closeFunction,
  isHomeQPipe,
}) {
  const [homeQActivated] = useIsHomeQAuthenticated();
  const showHomeQ = homeQActivated && isHomeQPipe;
  const sbfActivated = usePermissionCheck("allow_sbf");

  const storeName = homeQConstants.STORE_NAME;
  const [selectedHomeQRejectionReason, setSelectedHomeQRejectionReason] =
    React.useState(null);
  const [selectedSbfRejectionReason, setSelectedSbfRejectionReason] =
    React.useState(null);

  const rejectionReasons = useSelector(
    (state) => state[storeName].rejectionReasons
  );

  const [step, setStep] = React.useState(STEPS.BASE);

  const {
    apartments: acceptApartments,
    indps: acceptsIndps,
    parkings: acceptParkings,
  } = buildAcceptByObjectObj(leads);

  const openObjInNewTab = (obj, type) => {
    switch (type) {
      case "apartment": {
        window.open(apartmentDetailUrl({ id: obj.id }), "_blank").focus();
        break;
      }
      case "indp": {
        window.open(indpDetailUrl({ id: obj.id }), "_blank").focus();
        break;
      }
      case "parking": {
        window.open(parkingDetailUrl({ id: obj.id }), "_blank").focus();
        break;
      }
    }
  };

  const checkNext = () => {
    switch (step) {
      case STEPS.CONFIRM: {
        callback({
          homeQRejectionReason: selectedHomeQRejectionReason,
          sbfRejectionReason: selectedSbfRejectionReason,
        });
        break;
      }

      case STEPS.HOMEQ_REJECTION_REASON: {
        if (sbfActivated) {
          setStep(STEPS.SBF_REJECTION_REASON);
        } else {
          setStep(STEPS.CONFIRM);
        }

        break;
      }

      case STEPS.SBF_REJECTION_REASON: {
        setStep(STEPS.CONFIRM);
        break;
      }

      default:
        break;
    }
  };

  return (
    <StandardModal
      title={`Neka ${leads?.length || ""} lead${leads?.length > 1 ? "s" : ""}`}
      isOpen={isOpen}
      closeFunction={() => {
        setStep(STEPS.BASE);
        closeFunction();
      }}
      withActionBar={step !== STEPS.BASE}
      actionBarAcceptTitle={step === STEPS.CONFIRM ? "Neka leads" : "Gå vidare"}
      saveFunction={checkNext}
    >
      {loading && <OverlaySpinner />}
      {step === STEPS.BASE && (
        <>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Nekande av leads</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>
                  Information om nekande av lead
                </OverviewTitle>
                <OverviewSubtitle>
                  Att neka en lead innebär att leaden inte längre är aktuell för
                  objektet som leaden visat intresse för. Vissa integrationer
                  kan kräva mer information om varför, vilket isåfall anges i
                  kommande steg.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            <TextButton
              title="Gå vidare och neka leads"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => {
                if (showHomeQ) {
                  setStep(STEPS.HOMEQ_REJECTION_REASON);
                } else if (sbfActivated) {
                  setStep(STEPS.SBF_REJECTION_REASON);
                } else {
                  setStep(STEPS.CONFIRM);
                }
              }}
            />
          </InnerBox>
        </>
      )}

      {step === STEPS.ORDER && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj i vilken ordning källorna ska prioriteras
              </OverviewTitle>
              <OverviewSubtitle>
                Om en lead har flera källor så kommer den enbart att bekräftas
                på den högst prioriterade källan
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
        </>
      )}

      {step === STEPS.HOMEQ_REJECTION_REASON && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj vilken anledning till nekande sökande via HomeQ ska få
              </OverviewTitle>
              <OverviewSubtitle>
                Om en annons har fler sökande så krävs det att en anledning till
                att de nekas objektet specificeras för övriga sökande
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <NonConnectedSelect
            id="rejection_reason"
            value={selectedHomeQRejectionReason}
            choices={rejectionReasons}
            getOptionLabel={(o) => o.description}
            getOptionValue={(o) => o.id}
            placeholder="Välj en anledning"
            onUpdate={(val) => setSelectedHomeQRejectionReason(val)}
          />
        </>
      )}

      {step === STEPS.SBF_REJECTION_REASON && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj vilken anledning till nekande de sökande via Stockholms
                Bostadsförmedling ska få
              </OverviewTitle>
              <OverviewSubtitle>
                Om en annons har fler sökande så krävs det att en anledning till
                att de nekas objektet specificeras för övriga sökande
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <NonConnectedTextField
            id="sbf_rejection_reason"
            value={selectedSbfRejectionReason}
            placeholder="Välj en anledning"
            handleChange={(val) => setSelectedSbfRejectionReason(val)}
          />
        </>
      )}

      {step === STEPS.CONFIRM && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Bekräfta nekande av leads</OverviewTitle>
              <OverviewSubtitle>
                Vänligen bekräfta att du vill neka valda leads.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>
                  Bland de nekade leadsen finns leads på dessa objekt
                </OverviewTitle>
                <OverviewSubtitle>
                  Kontrollera att detta stämmer. Tryck på "Neka leads" för att
                  bekräfta.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {acceptApartments.map((ap) => {
              return (
                <InnerBox key={ap.id} style={{ marginBottom: 8 }}>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Lägenhet {ap.str_representation}
                      </OverviewTitle>
                      <OverviewSubtitle>
                        <TextButton
                          title="Öppna i ny flik"
                          clicked={() => openObjInNewTab(ap, "apartment")}
                          iconType="launch"
                          iconPlacement="right"
                        />{" "}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <div style={{ fontWeight: 500, fontSize: 14 }}>
                    Nekade leads
                  </div>

                  {ap.leads.map((l) => (
                    <TextButton
                      title={l.str_representation}
                      clicked={
                        openLeadCallback ? () => openLeadCallback(l) : () => {}
                      }
                      key={l.id}
                    />
                  ))}
                </InnerBox>
              );
            })}

            {acceptsIndps.map((ap) => {
              return (
                <InnerBox key={ap.id} style={{ marginBottom: 8 }}>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Lägenhet {ap.str_representation}
                      </OverviewTitle>
                      <OverviewSubtitle>
                        <TextButton
                          title="Öppna i ny flik"
                          clicked={() => openObjInNewTab(ap, "indp")}
                          iconType="launch"
                          iconPlacement="right"
                        />{" "}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <div style={{ fontWeight: 500, fontSize: 14 }}>
                    Nekade leads
                  </div>

                  {ap.leads.map((l) => (
                    <TextButton
                      title={l.str_representation}
                      clicked={
                        openLeadCallback ? () => openLeadCallback(l) : () => {}
                      }
                      key={l.id}
                    />
                  ))}
                </InnerBox>
              );
            })}

            {acceptParkings.map((ap) => {
              return (
                <InnerBox key={ap.id} style={{ marginBottom: 8 }}>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Lägenhet {ap.str_representation}
                      </OverviewTitle>
                      <OverviewSubtitle>
                        <TextButton
                          title="Öppna i ny flik"
                          clicked={() => openObjInNewTab(ap, "parking")}
                          iconType="launch"
                          iconPlacement="right"
                        />{" "}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <div style={{ fontWeight: 500, fontSize: 14 }}>
                    Nekade leads
                  </div>

                  {ap.leads.map((l) => (
                    <TextButton
                      title={l.str_representation}
                      clicked={
                        openLeadCallback ? () => openLeadCallback(l) : () => {}
                      }
                      key={l.id}
                    />
                  ))}
                </InnerBox>
              );
            })}
          </InnerBox>
        </>
      )}
    </StandardModal>
  );
}
