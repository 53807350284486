import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  buildQueryString,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../../store/base";
import { LinkedObject } from "../../../components/Displays";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { useParkingSpot, editUrl } from "../../../store/parkingSpots";
import ParkingLotList from "../../../components/Tables/Parking/ParkingLots/FullTable";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { useParkingLot } from "../../../store/parkingLots";
import { useSelector } from "react-redux";
import Table from "../../../components/Billecta/Table/BasicTable";
import { lightOrDark } from "../../../components/DigitalDoc/utils";
import ParkingSpotStatusModalForm from "../../../components/Forms/ParkingSpot/StatusForm/ModalForm";
import {
  useCompany,
  detailUrl as companyDetailUrl,
} from "../../../store/companies";
import ParkingSpotVacancyBookingModalForm from "../../../components/Forms/ParkingSpot/VacancyBookingForm/ModalForm";
import VacancyBookingRecordTable from "../../../components/Tables/InvoicingVacancyRecord/FullTable";

import SmallTenantSummary from "../../../components/SummaryCards/Tenant/Small";
import SmallCompanySummary from "../../../components/SummaryCards/Company/Small";
import Cookies from "js-cookie";

export default function ParkingSpotOverview() {
  const { parkingSpotId } = useParams();
  const [parkingSpot] = useParkingSpot(parkingSpotId);
  const [parkingLot] = useParkingLot(parkingSpot?.parking_lot?.id);

  const [statusesOpen, setStatusesOpen] = React.useState(false);
  const [vacancyBookingConfigOpen, setVacancyBookingConfigOpen] =
    React.useState(false);
  const { push } = useHistory();
  const userType = useSelector((state) => state.app.user?.user_type);

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const customerId = React.useMemo(() => {
    try {
      return JSON.parse(Cookies.get("intercom_customer") ?? {})?.id;
    } catch (e) {
      return null;
    }
  }, []);

  const canViewSensitiveInfoTemp = useSelector(
    (state) =>
      (state.app.user?.user_type === 1 || state.app.user?.user_type === 0) &&
      (customerId != 128 || state.app.user?.user_type === 0)
  );
  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const canEdit = usePermissionCheck("change_can_parking");

  const [landlord] = useCompany(parkingSpot?.landlord?.id);
  const [vancacyBookingCompany] = useCompany(
    parkingSpot?.vacancy_booking_company?.id
  );

  const lotPersistantQuery = {
    id: parkingSpot?.parking_lot?.id,
  };

  const inInvoicingVacancyRecordsQ = {
    parking_spot: parkingSpotId,
    order_by: "-updated_at",
  };

  const statuses = React.useMemo(() => {
    return parkingSpot?.customer_statuses;
  }, [parkingSpot]);

  const statusColumns = React.useMemo(() => {
    return [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 8px",
                borderRadius: 5,
                backgroundColor: value?.color_code,
                color:
                  lightOrDark(value?.color_code) === "light" ? "#000" : "#fff",
              }}
            >
              {value?.name || "-"}
            </div>
          );
        },
      },

      {
        Header: "Börjar gälla",
        accessor: "start_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;
          return <div>{value}</div>;
        },
      },
      {
        Header: "Slutar gälla",
        accessor: "end_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;

          return <div>{value}</div>;
        },
      },
    ];
  }, [parkingSpot]);

  return (
    <>
      {vacancyBookingConfigOpen && (
        <ParkingSpotVacancyBookingModalForm
          id={parkingSpotId}
          onCheckout={() => setVacancyBookingConfigOpen(false)}
          instance={parkingSpot}
        />
      )}

      <ParkingSpotStatusModalForm
        id={parkingSpotId}
        isOpen={statusesOpen}
        onCheckout={() => setStatusesOpen(false)}
        instance={parkingSpot}
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "65%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              {canEdit && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => push(editUrl({ id: parkingSpotId }))}
                />
              )}
            </OverviewTitleWrapper>

            <DetailInfo
              infoObj={getInfoObj({
                parkingSpot,

                avyTmplActive,
                parkingLot,
                landlord,
                vancacyBookingCompany,
              })}
            />
          </DetailPageBox>

          {canViewSensitiveInfoTemp && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "35%",
                alignSelf: "flex-start",
              }}
            >
              {parkingSpot?.tenant?.id && (
                <DetailPageBox>
                  <OverviewTitleWrapper>
                    <OverviewTitle small>Hyresgäst</OverviewTitle>
                  </OverviewTitleWrapper>

                  <SmallTenantSummary id={parkingSpot?.tenant?.id} />
                </DetailPageBox>
              )}
              {parkingSpot?.landlord?.id && (
                <DetailPageBox>
                  <OverviewTitleWrapper>
                    <OverviewTitle small>Hyresvärd</OverviewTitle>
                  </OverviewTitleWrapper>

                  <SmallCompanySummary id={parkingSpot?.landlord?.id} />
                </DetailPageBox>
              )}
              <DetailPageBox style={{ alignSelf: "flex-start", width: "100%" }}>
                <OverviewTitleWrapper style={{ marginBottom: 4 }}>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Anpassade statusar</OverviewTitle>
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera statusar"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setStatusesOpen(true)}
                      />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                {statuses?.length ? (
                  <Table data={statuses} columns={statusColumns} hideSearch />
                ) : (
                  <InnerBox style={{ marginTop: 8, marginBottom: 12 }}>
                    Inga statusar satta på objektet
                  </InnerBox>
                )}
                <hr style={{ marginTop: 0 }} />
                <TextButton
                  title="Gå till statusöversikt"
                  iconType="arrow"
                  iconPlacement="right"
                  clicked={() => push("/configcenter/premises-statuses")}
                />
              </DetailPageBox>
            </div>
          )}
        </DetailPageBoxFlexWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>Parkeringsområde</OverviewTitle>
          </OverviewTitleWrapper>
          <ParkingLotList
            persistantQuery={lotPersistantQuery}
            isBare
            ignoreLocalStorage
          />
        </DetailPageBox>

        {hasBillectaViewPermission && (
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Vakansbokningar</OverviewTitle>
                <OverviewSubtitle>
                  Logg över genererade vakansbokningar. Tryck på en rad för att
                  komma till underlaget.
                </OverviewSubtitle>
                <OverviewSubtitle>
                  <TextButton
                    title="Redigera inställning för vakansbokningar"
                    clicked={() => setVacancyBookingConfigOpen(true)}
                    iconType="edit"
                    iconPlacement="right"
                  />
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <VacancyBookingRecordTable
              persistantQuery={inInvoicingVacancyRecordsQ}
              isBare
              ignoreLocalStorage
              withInvoiceDetailUrl
            />
          </DetailPageBox>
        )}
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({
  parkingSpot,
  parkingLot,
  avyTmplActive,
  vancacyBookingCompany,
}) {
  const infoObj = {};

  infoObj["Översikt"] = [
    {
      title: "Synkad mot Avy-Tmpl",
      value: (
        <StatusLabel state={parkingSpot?.avytmpl_id ? 0 : 3}>
          {parkingSpot?.avytmpl_id ? "Synkad" : "Ej synkad"}
        </StatusLabel>
      ),
      hidden: !avyTmplActive,
    },
    {
      title: "Status",
      value: (
        <StatusLabel contentType="premises" state={parkingSpot?.state}>
          {parkingSpot?.state_display}
        </StatusLabel>
      ),
    },
    {
      title: "Namn",
      value: parkingSpot?.str_representation,
    },

    {
      title: "Adress",
      value: parkingSpot?.address?.str_representation || "-",
    },
    {
      title: "Typ av plats",

      value: parkingSpot?.specification?.vehicle_support_display,
    },
    {
      title: "Referenshyra (SEK/månad)",

      value: toMoneyString(parkingSpot?.rent, true),
    },

    {
      title: "Vakanser bokas på",
      value: (
        <LinkedObject
          obj={vancacyBookingCompany}
          urlMethod={companyDetailUrl}
        />
      ),
    },
    {
      title: "Fastighet",
      value: (
        <LinkedObject
          obj={parkingSpot?.realestate}
          urlMethod={realEstateDetailUrl}
        />
      ),
    },
  ];

  infoObj["Specifikation & regler"] = [
    {
      title: "Specifikation",
      value:
        parkingSpot?.specification?.str_representation ||
        parkingLot?.specification?.str_representation ||
        "Ingen vald",
    },
    {
      title: "Max höjd (CM)",
      value:
        parkingSpot?.specification?.height_limit ||
        parkingLot?.specification?.height_limit ||
        "-",
    },
    ,
    {
      title: "Max bredd (CM)",
      value:
        parkingSpot?.specification?.width_limit ||
        parkingLot?.specification?.width_limit ||
        "-",
    },
    {
      title: "Max längd (CM)",
      value:
        parkingSpot?.specification?.length_limit ||
        parkingLot?.specification?.length_limit ||
        "-",
    },
    {
      title: "Max vikt (KG)",
      value:
        parkingSpot?.specification?.weight_limit ||
        parkingLot?.specification?.weight_limit ||
        "-",
    },
  ];

  return infoObj;
}
