import * as React from "react";

import { FormAreaTitle } from "../../../Base/Chapters/styles";

import { constants } from "../../../../../store/invoicingSettings";
import { TextButton } from "../../../Base/Buttons";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { Checkbox, NumberInput, TextInput } from "../../../Base/Fields";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { cloneDeep } from "lodash";
import IMDSettingSummary from "../../../../Summaries/IMDSettingSummary";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import IMDSettingsTable from "../../../../Tables/InvoicingIMDSettings/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [createNewActive, setCreateNewActive] = React.useState(false);

  const selectedIMDSetting = useFormInstanceField({
    storeName,
    fieldKey: "imd_setting",
  });

  React.useEffect(() => {
    if (selectedIMDSetting && !selectedIMDSetting.id) {
      setCreateNewActive(true);
    }
  }, []);

  const handleOnCopy = () => {
    const IMDSettingClone = cloneDeep(selectedIMDSetting);

    delete IMDSettingClone.title;
    delete IMDSettingClone.id;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          imd_setting: IMDSettingClone,
        },
      })
    );

    setCreateNewActive(true);
  };

  if (!createNewActive) {
    return (
      <>
        <FormAreaTitle>IMD-debitering</FormAreaTitle>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey="imd_setting"
            title={"Inställning för IMD-debitering"}
            placeholder={"Välj inställning..."}
            description="Inställningen för IMD-debitering bestämmer hur debitering till följd av IMD-data debiteras"
            TableComponent={IMDSettingsTable}
          />
        </div>

        {!selectedIMDSetting && (
          <TextButton
            title="Eller tryck här för att skapa en ny inställning för IMD-debitering"
            iconType="add"
            iconPlacement="right"
            clicked={() => setCreateNewActive(true)}
          />
        )}

        {selectedIMDSetting && (
          <IMDSettingSummary
            setting={selectedIMDSetting}
            onCopy={handleOnCopy}
          />
        )}
      </>
    );
  }

  return (
    <>
      <TextButton
        title="Välj existerande inställning istället"
        extraStyle={{ marginBottom: 36 }}
        iconType="arrow-back"
        red
        clicked={() => {
          setCreateNewActive(false);
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: {
                imd_setting: null,
              },
            })
          );
        }}
      />

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Aviseringsinställning för IMD</OverviewTitle>
          <OverviewSubtitle>
            Grundläggande inställningar för hur IMD debiteras
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        {...{
          method,
          storeName,
          fieldKey: "imd_setting.title",
          title: "Titel på inställning",
        }}
      />

      <Checkbox
        {...{
          method,
          storeName,
          fieldKey: "imd_setting.display_consumtion",
          title: "Visa konsumtion på avier",
          description:
            "Om detta val är aktivt kommer konsumtionen som ligger till grund för debiteringen att visas på avier. Om den är inaktiv klumpas summan ihop på en rad.",
        }}
      />

      <Checkbox
        {...{
          method,
          storeName,
          fieldKey: "imd_setting.include_period_string",
          title: "Visa period som debiteras för på avi",
          description:
            "Om detta val är aktivt kommer konsumtionen som ligger till grund för debiteringen att visas på avier. Om den är inaktiv klumpas summan ihop på en rad.",
        }}
      />

      <NumberInput
        title="Förskjuten debitering, antal perioder"
        description={`Som standard (1 periods förskjutning) debiteras t.ex. januaris konsumtion i februari. För att exempelvis debitera januaris konsumtion i mars, ange 2, för att debitera i april, ange 3 osv.`}
        {...{ storeName, method, fieldKey: "imd_setting.invoice_month_offset" }}
      />
    </>
  );
};
