import * as React from "react";

import { retrieve } from "../../../../store/base/store/services";
import { cloneDeep } from "lodash";

export const useStandardPagination = ({
  queryString,
  constants,
  isReadyForFetch,
}) => {
  const [cache, setCache] = React.useState({});
  const [items, setItems] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentQueryString, setCurrentQueryString] = React.useState("");

  const [didAllUpdateFromRedux, setDidAllUpdateFromRedux] =
    React.useState(false);

  const isLoadingRef = React.useRef(isLoading);

  React.useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  const customEventListener = React.useCallback(
    (evt) => {
      if (isLoadingRef.current) return;

      if (evt.detail?.storeName !== constants?.STORE_NAME) return;

      setCache({});

      if (evt.detail.clearItems) {
        setCurrentQueryString("");
        setDidAllUpdateFromRedux(true);
      }
    },
    [isLoadingRef.current]
  );

  React.useEffect(() => {
    window.addEventListener("TABLE_SOCKET_CHANGES", customEventListener);
    return () => {
      window.removeEventListener("TABLE_SOCKET_CHANGES", customEventListener);
    };
  }, []);

  React.useEffect(() => {
    if (!isReadyForFetch) return;
    if (queryString.length === 0) return;

    if (currentQueryString !== queryString) {
      setCurrentQueryString(queryString);

      const doFetch = async () => {
        let url = `${constants.LIST_URL}?${queryString}`;

        if (cache.hasOwnProperty(url)) {
          setError(false);
          setItems(cache[url].items);
          setCount(cache[url].count);
          return;
        }

        setIsLoading(true);

        await new Promise((resolve) => {
          retrieve({ url })
            .then((res) => {
              //put items in all
              // dispatch(insertIntoAll(constants, res.data.results));

              let clone = cloneDeep(cache);

              clone[url] = {
                count: res.data.count,
                items: res.data.results,
              };

              setCache(clone);
              setError(false);

              setItems(res.data.results);
              setCount(res.data.count);

              resolve();
            })
            .catch((err) => {
              console.log("got error", err);

              setError(true);
              setItems([]);
              setCount(0);

              resolve();
            });
        });

        if (didAllUpdateFromRedux) setDidAllUpdateFromRedux(false);

        setIsLoading(false);
      };

      doFetch();
    }
  }, [queryString, isReadyForFetch, didAllUpdateFromRedux]);

  return {
    items,
    count,
    isLoading,
    error,
  };
};
