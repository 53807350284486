import * as React from "react";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import illustrationCopy from "../../../../assets/illustrations/illustration_copy.svg";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import billWithMainContractChapters from "./BillWithMainContractChapters";
import { detailUrl, useOtherContract } from "../../../../store/otherContracts";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import StepDisplay from "../../../StepDisplay/StepDisplay";
import {
  useOtherInvoicingForm,
  constants,
  create,
  destroyPostForm,
} from "../../../../store/invoicingOther";

import {
  constants as invoicingSettingConstants,
  create as createInvoicingSetting,
  useInvoicingSettingForm,
  useInvoicingSetting,
} from "../../../../store/invoicingSettings";

import {
  buildQueryString,
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
  useFrequentPermissions,
} from "../../../../store/base";
import billWithMainDescriptions from "./BillWithMainContractDescriptions";
import billWithMainChapterDefs from "./billWithMainContractChapterDefs";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";

import { useRealEstate } from "../../../../store/realEstates";
import { useCompany } from "../../../../store/companies";
import StandardModal from "../../../Modals/StandardModal";
import { BodyText } from "../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import {
  handleContractEditableDocUrl,
  EDITABLE_DOC_CONTRACT_TYPES,
  useEditabledoc,
} from "../../../../store/editabledocs";
import CreateInvoicingSettingBase from "../../Costs/CreateInvoicingSettingBase/CreateInvoicingSettingBase";
import moment from "moment";
import InvoicingSettingBase from "../../Costs/InvoiceSettingBase/InvoiceSettingBase";
import useQuery from "../../../utils/useQuery";
import ActionBanner from "../../../Displays/ActionBanner";
import {
  CheckBadgeIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import PrimaryBtn from "../../Base/Buttons/PrimaryBtn";
import CloneSourcePickerOtherInvoicing from "../../Pickers/CloneSourcePickerOtherInvoicing";
import { cleanOtherInvoicingClone } from "../../../../store/leaseContracts/utils";

const STEPS = {
  BILL_WITH_CONTRACT: "BILL_WITH_CONTRACT",
  INVOICING_BASE: "INVOICING_BASE",
  INVOICING_BASE_CREATE: "INVOICING_BASE_CREATE",
  COSTS: "COSTS",
};

export default ({ method = "POST" }) => {
  const [step, setStep] = React.useState(STEPS.INVOICING_BASE);
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const [cloneOpen, setCloneOpen] = React.useState(false);
  const [didClone, setDidClone] = React.useState(false);
  const [alreadyHasInvoicing, setAlreadyHasInvoicing] = React.useState(false);
  const skipInvoicing =
    useFormInstanceField({
      storeName,
      fieldKey: "is_active",
    }) === false;
  const formLoaded = Boolean(useOtherInvoicingForm(method));
  useInvoicingSettingForm(method);
  const { contractId } = useParams();

  const query = useQuery();
  const skipSign = query.get("skip-sign");
  const cloneFrom = query.get("clone-from");

  const { hasBillectaFullPermission } = useFrequentPermissions();
  const userPermissionsLoaded = useSelector(
    (state) => !!state.app.user?.permissions
  );
  const selectedSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const selectedBillingCompany = useFormInstanceField({
    storeName,
    fieldKey: "billing_company",
  });

  const billingStartDate = useFormInstanceField({
    storeName,
    fieldKey: "start_date",
  });

  const billWithMainContract = useFormInstanceField({
    storeName,
    fieldKey: "bill_with_lease",
  });
  const [otherContract] = useOtherContract(contractId);
  const [editableDoc] = useEditabledoc(otherContract?.editabledoc?.id);

  // get company for autofill of invoicing company
  const [realEstate] = useRealEstate(otherContract?.realestate?.id);
  const [company] = useCompany(realEstate?.company?.id);
  const [companyDefaultInvoicingSetting] = useInvoicingSetting(
    company?.invoicing_config?.id
  );

  React.useEffect(() => {
    if (!userPermissionsLoaded || !otherContract) return;

    if (!hasBillectaFullPermission) {
      setStep(STEPS.COSTS);
    }

    if (otherContract.main_contract?.id) {
      setStep(STEPS.BILL_WITH_CONTRACT);
    }
  }, [userPermissionsLoaded, otherContract]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(false));
    };
  }, []);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    if (otherContract?.pre_existing) {
      replace(detailUrl({ id: otherContract?.id }));
    } else if (skipSign) {
      replace(detailUrl({ id: otherContract.id }));
    } else {
      replace(
        handleContractEditableDocUrl({
          contractType: EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT,
          id: otherContract.id,
        })
      );
    }
  };

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  React.useEffect(() => {
    if (cloneFrom) return;
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          other_contract: { id: contractId },
        },
      })
    );
  }, []);

  const getClone = async () => {
    const clone = await cleanOtherInvoicingClone(cloneFrom);

    dispatch(
      setActiveFormInstance({
        storeName,
        data: { ...clone, other_contract: { id: contractId } },
      })
    );

    if (Object.keys(clone)?.length > 1) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: `Inställningar kopierades från ett avtal`,
        })
      );

      setDidClone(true);
      setStep(STEPS.COSTS);
    }
  };

  React.useEffect(() => {
    if (cloneFrom != null) {
      getClone();
    }
  }, [cloneFrom]);

  // auto set billing company
  React.useEffect(() => {
    if (!hasBillectaFullPermission || selectedBillingCompany || !company)
      return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          billing_company: company,
          _original_billing_company: company, // for table select component
        },
      })
    );
  }, [company, hasBillectaFullPermission]);

  React.useEffect(() => {
    if (!editableDoc) return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _editabledoctemp: editableDoc,
        },
      })
    );
  }, [editableDoc]);

  React.useEffect(() => {
    if (
      !hasBillectaFullPermission ||
      selectedSetting ||
      !companyDefaultInvoicingSetting
    )
      return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          setting: companyDefaultInvoicingSetting,
          _original_setting: companyDefaultInvoicingSetting, // for table select component
        },
      })
    );
  }, [companyDefaultInvoicingSetting, hasBillectaFullPermission]);

  // auto set billing start date from contract start date
  React.useEffect(() => {
    if (
      !hasBillectaFullPermission ||
      !otherContract ||
      billingStartDate ||
      !companyDefaultInvoicingSetting
    )
      return;

    if (!companyDefaultInvoicingSetting?.interval_setting) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            start_date: moment(otherContract.start_date)
              .subtract({ months: 1 })
              .format("YYYY-MM-DD"),
          },
        })
      );

      return;
    } else {
      const invoicePeriod =
        companyDefaultInvoicingSetting.interval_setting.invoice_period;
      const invoiceMode = companyDefaultInvoicingSetting.interval_setting.mode;
      const interval = companyDefaultInvoicingSetting.interval_setting.interval;

      const modeOffset =
        invoiceMode === 0
          ? { months: interval }
          : invoiceMode === 1
          ? { months: 3 * interval }
          : invoiceMode === 2
          ? { years: interval }
          : { months: 0 };

      // prev period, set start date one period before
      if (invoicePeriod === -1) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(otherContract.start_date)
                .add(modeOffset)
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      } else if (invoicePeriod === 0) {
        // same period, set start date to contract start date
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(otherContract.start_date)
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      } else if (invoicePeriod === 1) {
        // next period, set start date to one  period after
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(otherContract.start_date)
                .subtract(modeOffset)
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      } else {
        // set to one month before, most common scenario
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(otherContract.start_date)
                .subtract({ months: 1 })
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      }
    }
  }, [
    otherContract,
    hasBillectaFullPermission,
    companyDefaultInvoicingSetting,
  ]);

  React.useEffect(() => {
    if (otherContract?.other_invoicing) {
      setAlreadyHasInvoicing(true);
    }
  }, [otherContract]);

  React.useEffect(() => {
    return () => {
      destroyPostForm(false);
    };
  }, []);

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data }),
      })
    );
  };

  const onSelectedSetting = () => {
    setStep(STEPS.COSTS);
  };

  const onSelectedBillWithMainContract = () => {
    if (billWithMainContract) {
      setStep(STEPS.COSTS);
    } else {
      setStep(STEPS.INVOICING_BASE);
    }
  };

  // go to create new setting with selected one as base
  const handleCopyAndCreateNew = () => {
    const cleanedExistingSetting = cloneDeep(selectedSetting);
    delete cleanedExistingSetting.id;
    delete cleanedExistingSetting.title;

    if (cleanedExistingSetting.interval_setting) {
      delete cleanedExistingSetting.interval_setting.id;
    }

    if (cleanedExistingSetting.debt_setting) {
      cleanedExistingSetting._original_debt_setting =
        cleanedExistingSetting.debt_setting; // for table select
    }

    dispatch(
      setActiveFormInstance({
        storeName: invoicingSettingConstants.STORE_NAME,
        data: cleanedExistingSetting,
      })
    );

    setStep(STEPS.INVOICING_BASE_CREATE);
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Aviseringsinställning kopierades",
        description:
          "Uppdatera inställningarna och spara för att skapa en ny inställning",
      })
    );
  };

  // create new setting and then set to active and proceed to next step
  const onCreateNewSetting = () => {
    setLoading(true);
    dispatch(
      createInvoicingSetting({
        preventDefaultToast: true,
        successCallback: (_, returnData) => {
          setLoading(false);

          // set new setting in store
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: {
                setting: returnData,
              },
            })
          );

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Aviseringsinställning skapades",
              description: "Inställningen valdes för avtalet",
            })
          );

          // go to costs step
          setStep(STEPS.COSTS);
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                "Inställningen kunde inte skapas. Kontrollera datan och försök igen.",
            })
          );
        },
      })
    );
  };

  const goBackToInvoicingSetup = () => {
    if (otherContract?.main_contract) {
      setStep(STEPS.BILL_WITH_CONTRACT);
    } else {
      setStep(STEPS.INVOICING_BASE);
    }

    if (company) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            billing_company: company,
            _original_billing_company: company, // for table select component
          },
        })
      );

      if (companyDefaultInvoicingSetting) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              setting: companyDefaultInvoicingSetting,
              _original_setting: companyDefaultInvoicingSetting, // for table select component
            },
          })
        );
      }
    }
  };

  const renderSteps = () => {
    const steps = [
      "1. Avtalstyp",
      "2. Avtal",
      hasBillectaFullPermission ? "3. Debitering & Avisering" : "3. Kostnader",
    ];

    if (!otherContract.pre_existing) {
      steps.push("4. Dokument för signering");
    }

    return <StepDisplay steps={steps} activeIndex={2} />;
  };

  const renderActionBanner = () => (
    <>
      <CloneSourcePickerOtherInvoicing
        isOpen={cloneOpen}
        forceData={{
          other_contract: { id: contractId },
        }}
        closeFunction={() => setCloneOpen(false)}
        getPersistantQueryString={() =>
          buildQueryString({
            other_invoicing__isnull: false,
            bill_with_lease: false,
          })
        }
        onDone={() => {
          setStep(STEPS.COSTS);
          setCloneOpen(false);
          setDidClone(true);

          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: `Inställningar kopierades från ett avtal`,
            })
          );
        }}
      />

      <ActionBanner
        renderTitle={
          didClone
            ? () => (
                <>
                  Avtalet kopierades
                  <CheckBadgeIcon
                    className="text-green-600 ml-1"
                    width={24}
                  />{" "}
                </>
              )
            : () => "Kopiera från avtal"
        }
        renderDescription={
          didClone
            ? () => (
                <>
                  Attributen från det valda avtalet kopierades till formuläret.
                </>
              )
            : () => (
                <>
                  Kopiera inställningar från ett annat avtal i systemet.
                  Attribut som ofta skiljer sig från avtal till avtal, t.ex.
                  startdatum för avisering tas ej med vid kopiering.
                </>
              )
        }
        illustrationSvg={illustrationCopy}
        renderActions={() => (
          <>
            <PrimaryBtn onClick={() => setCloneOpen(true)}>
              <DocumentDuplicateIcon width={18} className="mr-1" />
              {didClone ? "Välj ett annat avtal" : "Kopiera från avtal"}
            </PrimaryBtn>
          </>
        )}
      />
    </>
  );

  const topButtonConfig = {
    title: `Hoppa över debitering ${
      hasBillectaFullPermission ? " & avisering" : ""
    } och gå direkt till avtal`,
    iconType: "arrow",
    iconPlacement: "right",
    clicked: () => {
      replace(detailUrl({ id: otherContract.id }));
    },
  };

  return (
    <>
      <StandardModal
        title={`Debitering ${
          hasBillectaFullPermission ? "& Avisering" : ""
        } redan konfigurerat`}
        isOpen={alreadyHasInvoicing}
        canClose={false}
        actionBarAcceptTitle="Gå till detaljsida"
        withActionBar
        saveFunction={() => {
          replace(detailUrl({ id: otherContract?.id }));
        }}
        canCancel={false}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Debitering {hasBillectaFullPermission ? "& Avisering" : ""} redan
            konfigurerat för detta avtal
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          Denna sida är enbart till för skapande av debiteringsrader
          {hasBillectaFullPermission ? " och aviseringsinställningar" : ""}.
          Detta avtal har redan en inställning, gå till avtalets detaljsida för
          att redigera debiteringsrader
          {hasBillectaFullPermission ? " och aviseringsinställningar" : ""}.
        </BodyText>
      </StandardModal>

      {(loading || !formLoaded) && <FullPageSpinner />}

      {/*  if contract has main contrat, we can choose to bill with main contract... */}

      {hasBillectaFullPermission &&
        otherContract &&
        otherContract?.main_contract &&
        step === STEPS.BILL_WITH_CONTRACT && (
          <>
            {renderSteps()}
            {renderActionBanner()}
            <FlowFormBase
              {...{
                storeName,
                chapterDefs: billWithMainChapterDefs(),
                chapters: billWithMainContractChapters,
                descriptions: billWithMainDescriptions,
                method,
                onSubmit: onSelectedBillWithMainContract,

                contract: otherContract,
                topButtonConfig,
                title: "Avisera med huvudavtal",
              }}
            />
          </>
        )}

      {/*  if we have billecta permission, we select an invoicing settings or... */}
      {hasBillectaFullPermission &&
        otherContract &&
        step === STEPS.INVOICING_BASE && (
          <>
            {renderSteps()}
            {renderActionBanner()}

            <InvoicingSettingBase
              {...{
                storeName,
                method,
                onSubmit: onSelectedSetting,
                onGoToCreateNew: () => setStep(STEPS.INVOICING_BASE_CREATE),
                onCopyAndCreateNew: handleCopyAndCreateNew,
                contract: otherContract,

                topButtonConfig,
                title: "Välj aviseringsinställning",
              }}
            />
          </>
        )}

      {/* Create a new one to be used. This renders and invoicing settings form */}
      {hasBillectaFullPermission &&
        otherContract &&
        step === STEPS.INVOICING_BASE_CREATE && (
          <>
            {renderSteps()}
            {renderActionBanner()}

            <CreateInvoicingSettingBase
              {...{
                storeName: invoicingSettingConstants.STORE_NAME,
                method,
                onSubmit: onCreateNewSetting,
                onGoBackToSelect: () => {
                  setStep(STEPS.INVOICING_BASE);
                  dispatch(
                    updateActiveFormInstance({
                      storeName,
                      data: {
                        setting: null, // TODO - set company default here
                      },
                    })
                  );
                },
                title: "Skapa aviseringsinställning",
              }}
            />
          </>
        )}

      {/* Then we can set up the costs and contract specific settings  */}
      {otherContract && step === STEPS.COSTS && (
        <>
          {renderSteps()}
          {renderActionBanner()}

          <FlowFormBase
            {...{
              storeName,
              chapterDefs: chapterDefs({
                hasBillectaFullPermission,
                billWithMainContract,
                skipInvoicing,
              }),
              chapters,
              descriptions,
              method,
              onSubmit,
              goBackToInvoicingSetup,
              skipInvoicing,
              topButtonConfig,
              contract: otherContract,
              displayDocumentFieldKey: "_editabledoctemp.doc.get",
              title: `Debitering ${
                hasBillectaFullPermission ? "& avisering " : ""
              }för ${otherContract.id_number}`,
            }}
          />
        </>
      )}
    </>
  );
};

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  if (copy.bill_with_lease == null) {
    copy.bill_with_lease = false;
  }

  return copy;
};
