import * as React from "react";

import { Checkbox, Select, TextField, TextInput } from "../../../Base/Fields";

import { constants } from "../../../../../store/invoicingDebtor";
import {
  updateActiveFormInstance,
  useAllPermissionCheck,
  useFormInstanceField,
} from "../../../../../store/base";
import { useTenant } from "../../../../../store/tenants";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { InnerBox } from "../../../../sharedStyles";
import { TextButton } from "../../../Base/Buttons";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { tenantId } = useParams();
  const [tenant] = useTenant(tenantId);

  const tenantEmail = tenant?.user?.email;

  const canViewAvyTmpl = useAllPermissionCheck([
    "view_can_avytmpl",
    "allow_avytmpl",
  ]);

  const selectedEmail = useFormInstanceField({
    storeName,
    fieldKey: "email_invoice",
  });

  const setEmail = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          email_invoice: tenantEmail,
        },
      })
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Leverans av faktura</OverviewTitle>
          <OverviewSubtitle>
            Ange hur fakturor ska levereras till denna mottagare
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />
      <Select
        title="Leveransmetod"
        {...{
          storeName,
          method,
          fieldKey: `delivery_method`,
        }}
      />
      <Checkbox
        title="(Post) Försök skicka till tidigare/övrig adress innan/efter avtal gäller"
        description="Om hyresgästen ej flyttat in än eller redan har flyttat ut kommer övrig adress användas för leverans av Postavi"
        {...{
          storeName,
          method,
          fieldKey: `attempt_previous_postal_address`,
        }}
      />
      <TextInput
        title="Kundens referens"
        {...{
          storeName,
          method,
          fieldKey: `your_reference`,
        }}
      />
      <TextField
        title="Standardmeddelande på avier"
        {...{
          storeName,
          method,
          fieldKey: `default_invoice_message`,
        }}
      />
      <TextInput
        title="Telefonnummer för fakturaleverans"
        {...{
          storeName,
          method,
          fieldKey: `invoice_phone`,
        }}
      />
      {tenantEmail && !selectedEmail && (
        <InnerBox style={{ marginBottom: 8 }}>
          <OverviewTitleWrapper style={{ marginBottom: 8 }}>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Föreslagen E-post</OverviewTitle>
              <OverviewSubtitle>
                Kicka på E-postadressen för att använda den
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <TextButton
            title={tenantEmail}
            iconType="add"
            clicked={() => setEmail(tenantEmail)}
          />
        </InnerBox>
      )}
      <TextInput
        title="E-postadress för fakturaleverans"
        {...{
          storeName,
          method,
          fieldKey: `email_invoice`,
        }}
      />
      <TextInput
        title="E-postadress för fakturaleverans CC"
        {...{
          storeName,
          method,
          fieldKey: `email_invoice_cc`,
        }}
      />
      <TextInput
        title="E-postadress för fakturaleverans BCC"
        {...{
          storeName,
          method,
          fieldKey: `email_invoice_bcc`,
        }}
      />
      <TextInput
        title="Externt referens-ID"
        extraStyles={{ minWidth: "150px", marginRight: "20px" }}
        {...{
          storeName,
          method,
          fieldKey: `external_id`,
        }}
      />

      {canViewAvyTmpl && (
        <Checkbox
          title="Skicka avier med vald leveransmetod trots att de levererats via Avy-Tmpl"
          description="OBS:Gäller endast AvyTmpl-användare. Om detta alternativ är aktiverat kommer avier, förutom att levereras till Avy-Tmpl-appen, skickas ut med det valda leveranssättet som vanligt."
          {...{
            storeName,
            method,
            fieldKey: `redistribute_avytmpl_invoice`,
          }}
        />
      )}
    </>
  );
};
