import * as React from "react";

// store, state
import { constants } from "../../../../../store/realEstates";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useDispatch } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Bokföringsdimensioner</OverviewTitle>
          <OverviewSubtitle>
            Ställ in standard bokföringsdimensioner för objekt under denna
            fastighet
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"project"}
          placeholder="Välj projekt..."
          title="Projekt"
          TableComponent={InvoicingProjectsTable}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"cost_center"}
          placeholder="Välj kostnadsställe..."
          title="Kostnadsställe"
          TableComponent={InvoicingCostCenterTable}
        />
      </div>
    </>
  );
};
