import * as React from "react";
import * as SharedStyles from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import { useFilteredRealEstates } from "../../../store/realEstates";
import CompanyTable from "../../../components/Tables/Companies/FullTable";
import * as BannerSC from "../../../components/Details/Dashboard/NoFilterBanner/styles";
import Alert from "src/components/Alert/Alert";

export default function Companies() {
  const { filteredRealEstates } = useActiveFilters();

  const realEstateQuery = buildQueryString({
    id__in: filteredRealEstates,
  });
  const [matchingFilteredRealEstates] = useFilteredRealEstates(realEstateQuery);

  const persistantQuery = {
    id__in: filteredRealEstates
      ? matchingFilteredRealEstates?.map((r) => r.company?.id)
      : undefined,
    brfcompany__isnull: true,
  };

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Översikt</OverviewTitle>
        </OverviewTitleWrapper>

        <Alert title="Filtrera globalt på bolag">
          Genom att markera ett eller flera bolag i listan och klicka på
          "Filtrera" så filtrerar du systemet globalt på fastigheter och objekt
          som ligger under dessa bolag.
        </Alert>

        <CompanyTable withSelect {...{ persistantQuery }} />
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
