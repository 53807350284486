import * as React from "react";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "src/components/Details/OverviewInfo/styles";
import ReportErrandForm from "src/components/Forms/ReportErrand/ChapterForm/ModalForm";
import { DetailInnerWrapper, DetailPageBox } from "src/components/sharedStyles";
import RoundingFaultTable from "src/components/Tables/RoundingFaults/FullTable";
import { usePermissionCheck } from "src/store/base";

export default function Faults() {
  const dispatch = useDispatch();
  const [createReportErrandOpen, setCreateReportErrandOpen] =
    React.useState(false);
  const [createReportErrandFault, setCreateReportErrandFault] =
    React.useState(null);
  const canReportErrand = usePermissionCheck("add_can_reporterrand");

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper className="mb-0">
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Avvikelser</OverviewTitle>

            <OverviewSubtitle>
              Avvikelser som har uppstått under ronderingsärenden.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        {createReportErrandOpen && (
          <ReportErrandForm
            preventGoToDetail
            method="POST"
            isOpen={true}
            onCheckout={() => setCreateReportErrandOpen(false)}
            instance={{
              title: `Ronderingsavvikelse ${createReportErrandFault?.title}`,
              report_comments: [
                {
                  content: createReportErrandFault?.description,
                },
              ],
              rounding_fault: {
                ...createReportErrandFault,
                str_representation: createReportErrandFault?.title,
              },
              component_placement: {
                ...createReportErrandFault?.component_placement,
                _dontClear: true,
              },
            }}
          />
        )}

        {!createReportErrandOpen && (
          <RoundingFaultTable
            hideBadges
            persistantQuery={{ has_no_gov_errand: true, is_ok: false }}
            createReportErrandCallback={
              canReportErrand
                ? (obj) => {
                    setCreateReportErrandFault(obj);
                    setCreateReportErrandOpen(true);
                  }
                : null
            }
            onRowClicked={() => {}}
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
