import * as React from "react";
import { BodyText, InnerBox } from "../../sharedStyles";

import * as SC from "./styles";
import { cloneDeep } from "lodash";
import Notes from "../OverviewInfo/Notes/Notes";
import { TextButton } from "../../Forms/Base/Buttons";
import VideoFileIconBlue from "../../../assets/svg/material/video_file_blue.svg";
import { OverviewTitle } from "../OverviewInfo/styles";
import moment from "moment";
import ChecklistSummary from "./ChecklistSummary";
import { buildQueryString } from "../../../store/base";
import { useFilteredChecklistRows } from "../../../store/checklistRows";

function ErrandFault({
  reportErrand,
  reportComments,
  bookingInfo,
  setting,
  calendar,
}) {
  const openImage = (src) => {
    window.open(src, "_blank").focus();
  };

  const notOkChecklistRowIds =
    reportErrand?.active_rating?.tenant_not_ok_check_list_rows?.map(
      (r) => r.id
    ) || [];
  const okChecklistRowIds =
    reportErrand?.active_rating?.tenant_ok_check_list_rows?.map((r) => r.id) ||
    [];
  const checklistRowQ = buildQueryString({
    id__in: [...notOkChecklistRowIds, ...okChecklistRowIds],
  });

  const [checklistRows] = useFilteredChecklistRows(checklistRowQ);

  const renderImages = () => {
    const { report_files } = reportErrand || {};

    if (!report_files?.length)
      return <BodyText>Ärendet saknar bilder</BodyText>;
    return report_files.map((f) => (
      <SC.ImageWrapper>
        <SC.Image onClick={() => openImage(f.file?.get)} src={f.file?.get} />
        <SC.ImageDescription>
          {f.description || "Bilden saknar beskrivning"}
        </SC.ImageDescription>
      </SC.ImageWrapper>
    ));
  };

  const openVideoLink = (videoUrl) => {
    window.open(videoUrl, "_blank").focus();
  };

  const showDateAndTime = () => {
    const date = moment(bookingInfo?.start).format("YYYY-MM-DD");
    const startTime = moment(bookingInfo?.start).format("HH:mm");
    const endTime = moment(bookingInfo?.end).format("HH:mm");

    return {
      date,
      startTime,
      endTime,
    };
  };

  const reportCommentsClone = cloneDeep(reportComments);
  if (!!reportCommentsClone?.length) {
    reportCommentsClone.shift();
  }

  return (
    <>
      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitle small style={{ marginBottom: 12 }}>
          Bilder och felbeskrivning
        </OverviewTitle>
        {reportErrand?.report_files?.length > 0 && (
          <SC.ImageContainer>{renderImages()}</SC.ImageContainer>
        )}
        <InnerBox style={{ marginBottom: 24 }}>
          <div style={{ fontSize: 14 }}>
            <strong>
              Felbeskrivning
              <br />
            </strong>{" "}
            {reportComments?.length
              ? reportComments?.[0]?.content
              : "Ingen felbeskrivning lämnades"}
          </div>
        </InnerBox>

        {checklistRows?.length > 0 && (
          <InnerBox>
            <div style={{ fontSize: 14 }}>
              <strong>Egenkontroll av hyresgäst</strong>
              <br />

              <div style={{ marginTop: 12 }}>
                <ChecklistSummary
                  checklistRows={checklistRows}
                  notOkIds={notOkChecklistRowIds}
                  okIds={okChecklistRowIds}
                />
              </div>
            </div>
          </InnerBox>
        )}
        {reportErrand?.video_url && (
          <TextButton
            title="Information kring ärende i videoformat"
            clicked={() => openVideoLink(reportErrand?.video_url)}
            icon={VideoFileIconBlue}
            extraStyle={{ marginTop: 24 }}
          />
        )}
      </InnerBox>
      {setting?.meeting_calendar?.id && (
        <InnerBox style={{ marginBottom: 24 }}>
          <OverviewTitle small>Mötesbokning</OverviewTitle>
          {!!bookingInfo ? (
            <>
              <InnerBox style={{ marginTop: 8 }}>
                <strong>Bokningsinformation för deltagande:</strong>
                {calendar?.booking_info || "Information saknas"} <br />
                <strong style={{ marginTop: 4 }}>
                  Inför mötesinformation för deltagande:{" "}
                </strong>
                {bookingInfo?.dynamic_remind_content || "Information saknas"}
              </InnerBox>
              {bookingInfo?.start && (
                <InnerBox style={{ marginTop: 8 }}>
                  <strong>Datum och tid</strong>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 8 }}>
                      {showDateAndTime().date}
                    </div>
                    <div>
                      {showDateAndTime().startTime} -{" "}
                      {showDateAndTime().endTime}
                    </div>
                  </div>
                </InnerBox>
              )}
            </>
          ) : (
            <InnerBox style={{ marginTop: 8 }}>
              <strong style={{ marginTop: 4 }}>Bokning saknas</strong>
            </InnerBox>
          )}
        </InnerBox>
      )}
      <InnerBox>
        <Notes
          notes={reportCommentsClone}
          contentType={"errands.reporterrand"}
          objectId={reportErrand?.id}
          attrName={"report_comments"}
          title="Kommentarer"
          fieldTitle="Kommentar"
          green
        />
      </InnerBox>
    </>
  );
}

export default ErrandFault;
