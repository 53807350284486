import { cloneDeep } from "lodash";
import moment from "moment";
import { constants } from "..";
import baseReducer from "../../base/store/reducer";

const INITIAL_STATE = {
  all: {},
  allCopy: {},
  inProgress: [],
  paginations: {},
  paginationsCopy: {},
  filtered: {},
  filteredCopy: {},
  backgroundTasks: {},
  hasFetchedAll: false,
  forms: {},
  formInstance: {},
  formErrors: {},
  increases: [], // array of objs, converted to obj with id as key on create call
  indpIncreases: [], // array of objs, converted to obj with id as key on create call
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.ADD_APARTMENT: {
      return handleAddApartment(newState, payload);
    }

    case constants.ADD_MULTIPLE_APARTMENTS: {
      return handleAddMultipleApartments(newState, payload);
    }

    case constants.REMOVE_APARTMENT: {
      return handleRemoveApartment(newState, payload);
    }

    case constants.SET_CURRENT_APARTMENT_INCREASES: {
      return { ...newState, currentIncreases: payload.increases };
    }

    case constants.SET_APARTMENT_INCREASES: {
      return { ...newState, increases: payload.newIncreases };
    }

    case constants.ADD_INDP: {
      return handleAddIndp(newState, payload);
    }

    case constants.ADD_MULTIPLE_INDPS: {
      return handleAddMultipleIndps(newState, payload);
    }

    case constants.REMOVE_INDP: {
      return handleRemovIndp(newState, payload);
    }

    case constants.SET_CURRENT_INDP_INCREASES: {
      return { ...newState, currentIndpIncreases: payload.increases };
    }

    case constants.SET_INDP_INCREASES: {
      return { ...newState, indpIncreases: payload.newIncreases };
    }
    default:
      return newState;
  }
};

const handleAddApartment = (state, payload) => {
  const { apartment } = payload;
  const stateClone = cloneDeep(state);

  const { increases } = stateClone;

  increases.push({
    ...apartment,
    apply_from: moment().format("YYYY-MM-DD"),
    quota: 0,
    increase_in_sek: 0,
  });

  return stateClone;
};

const handleAddIndp = (state, payload) => {
  const { indp } = payload;
  const stateClone = cloneDeep(state);

  const { indpIncreases } = stateClone;

  indpIncreases.push({
    ...indp,
    apply_from: moment().format("YYYY-MM-DD"),
    quota: 0,
    increase_in_sek: 0,
  });

  return stateClone;
};

const handleAddMultipleApartments = (state, payload) => {
  const { apartments } = payload;

  const stateClone = cloneDeep(state);

  const { increases } = stateClone;

  apartments.forEach((a) => {
    increases.push({
      ...a,
      apply_from: moment().format("YYYY-MM-DD"),
      quota: 0,
      increase_in_sek: 0,
    });
  });

  return stateClone;
};

const handleAddMultipleIndps = (state, payload) => {
  const { indps } = payload;

  const stateClone = cloneDeep(state);

  const { indpIncreases } = stateClone;

  indps.forEach((a) => {
    indpIncreases.push({
      ...a,
      apply_from: moment().format("YYYY-MM-DD"),
      quota: 0,
      increase_in_sek: 0,
    });
  });

  return stateClone;
};

const handleRemoveApartment = (state, payload) => {
  const { apartment } = payload;
  const stateClone = cloneDeep(state);

  const { increases } = stateClone;

  const newIncreases = increases.filter((i) => i.id !== apartment.id);
  stateClone.increases = newIncreases;
  return stateClone;
};

const handleRemovIndp = (state, payload) => {
  const { indp } = payload;
  const stateClone = cloneDeep(state);

  const { indpIncreases } = stateClone;

  const newIncreases = indpIncreases.filter((i) => i.id !== indp.id);
  stateClone.indpIncreases = newIncreases;
  return stateClone;
};
