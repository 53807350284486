export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_VACANCY_BOOKING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_VACANCY_BOOKING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_VACANCY_BOOKING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_VACANCY_BOOKING",
  DESTROY_FORM: "DESTROY_FORM_VACANCY_BOOKING",
  SET_FORM_ERROR: "SET_FORM_ERROR_VACANCY_BOOKING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_VACANCY_BOOKING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_VACANCY_BOOKING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_VACANCY_BOOKING",
  RESET_STATE: "RESET_STATE_VACANCY_BOOKING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_VACANCY_BOOKING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_VACANCY_BOOKING",
  CLEAR_FETCHED: "CLEAR_FETCHED_VACANCY_BOOKING",

  LIST_URL: "/accounting/vacancy/vacancybookingconfig/list/",
  POST_URL: "/accounting/vacancy/vacancybookingconfig/",
  PATCH_URL: "/accounting/vacancy/vacancybookingconfig/",
  GET_URL: "/accounting/vacancy/vacancybookingconfig/",

  STORE_NAME: "vacancyBookingConfig",
};
