import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//store, state
import { buildQueryString, usePermissionCheck } from "../../../store/base";

import { detailUrl as userDetailUrl, useUser } from "../../../store/users";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { useFilteredNotes } from "../../../store/notes";

//components & styles
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, LinkedObject } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  ErrandStatusLabel,
  StatusLabel,
} from "../../../components/Lists/Base/CompleteList/styles";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import {
  getChosenPerformer,
  getErrandStatus,
} from "../../../components/utils/errandUtils";

import {
  acceptInspectionErrand,
  declineInspectionErrand,
  useInspectionErrand,
  update,
  executeUrl,
  overviewUrl,
} from "../../../store/inspectionErrands";
import { useFilteredErrandComponents } from "../../../store/errandComponents";
import InspectionErrandAddressList from "../../../components/Details/InspectionErrand/AddressList";
import InspectionErrandFault from "../../../components/Details/InspectionErrand/Fault";
import { useApartment } from "../../../store/apartments";
import { useIndustrialPremises } from "../../../store/industrialPremises";
import { useCommonArea } from "../../../store/commonAreas";
import { useBrfPremises } from "../../../store/brfPremises";
import { useParkingSpot } from "../../../store/parkingSpots";
import RoomOverview from "../../../components/TechnicalManagement/Component/RoomOverview";
import {
  getComponentsByRooms,
  getCurrentProductTypeUsage,
} from "../../../components/TechnicalManagement/utils";
import PerformerStatusBar from "../../../components/TechnicalManagement/ErrandComponents/PerformerStatusBar";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import InspectionErrandPlanForm from "../../../components/Forms/InspectionErrand/PlanForm/ModalForm";
import useDurationString from "../../../hooks/useDurationString";
import FinishedErrandStatusBar from "../../../components/TechnicalManagement/ErrandComponents/FinishedErrandStatusBar";
import AdminTakeOverErrandStatusBar from "../../../components/TechnicalManagement/ErrandComponents/AdminTakeOverErrandStatusBar";
import { useInspectionErrandSetting } from "src/store/inspectionErrandSettings";

export const PREMISES_TYPES = {
  APARTMENT: "APARTMENT",
  INDP: "INDP",
  COMMON_AREA: "COMMON_AREA",
  BRF_PREMIS: "BRF_PREMIS",
  PARKING_SPOT: "PARKING_SPOT",
};

export const getComponentQueryKeyFromKind = (type) => {
  if (type === PREMISES_TYPES.APARTMENT) return "apartments";
  if (type === PREMISES_TYPES.INDP) return "industrial_premises_list";
  if (type === PREMISES_TYPES.COMMON_AREA) return "common_areas";
  if (type === PREMISES_TYPES.BRF_PREMIS) return "brf_premises";
  if (type === PREMISES_TYPES.PARKING_SPOT) return "parking_spot";
};
export default function InspectionErrandOverview() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { inspectionErrandId } = useParams();
  const canEditErrand = usePermissionCheck("change_can_inspectionerrand");

  const currentUser = useSelector((state) => state.app.user);

  const [inspectionErrand, inspectionErrandLoading] =
    useInspectionErrand(inspectionErrandId);
  const [inspectionErrandSetting] = useInspectionErrandSetting(
    inspectionErrand?.setting?.id
  );
  const excludeComponentTypeIds =
    inspectionErrandSetting?.exclude_component_types?.map((t) => t.id);

  const [loadingAction, setLoadingAction] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [reactivateErrandLoading, setReactivateErrandLoading] =
    React.useState(false);
  const [confirmExecuteOpen, setConfirmExecuteOpen] = React.useState(false);
  const [planErrandOpen, setPlanErrandOpen] = React.useState(false);
  const durationStr = useDurationString(inspectionErrand?.expected_duration);

  const [apartment] = useApartment(inspectionErrand?.apartment?.id);
  const [indp] = useIndustrialPremises(
    inspectionErrand?.industrial_premises?.id
  );
  const [commonArea] = useCommonArea(inspectionErrand?.common_area?.id);
  const [brfPremises] = useBrfPremises(inspectionErrand?.brf_premis?.id);
  const [parkingSpot] = useParkingSpot(inspectionErrand?.parking_spot?.id);

  const premises =
    apartment || indp || commonArea || brfPremises || parkingSpot;
  const kind = apartment
    ? PREMISES_TYPES.APARTMENT
    : indp
    ? PREMISES_TYPES.INDP
    : commonArea
    ? PREMISES_TYPES.COMMON_AREA
    : brfPremises
    ? PREMISES_TYPES.BRF_PREMIS
    : PREMISES_TYPES.PARKING_SPOT;

  const componentQueryKey = getComponentQueryKeyFromKind(kind);

  const componentQ = buildQueryString({
    [componentQueryKey || "id__in"]: premises?.id || "-1",
  });
  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const [reportedBy] = useUser(inspectionErrand?.reported_by?.id);

  const reportCommentsQuery = buildQueryString({
    id__in: inspectionErrand?.report_comments?.map((n) => n.id) || [],
  });
  const [reportComments] = useFilteredNotes(reportCommentsQuery);

  const notesQ = buildQueryString({
    id__in: inspectionErrand?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const { status, display } = getErrandStatus({
    status: inspectionErrand?.status,
    plannedStart: inspectionErrand?.planned_start,
    plannedEnd: inspectionErrand?.planned_end,
    actualStart: inspectionErrand?.execute_start,
    actualEnd: inspectionErrand?.execute_end,
  });

  const filteredComponents = components?.filter((c) => {
    const currentProductUsage = getCurrentProductTypeUsage(c);
    const componentTypeId =
      currentProductUsage?.product_type?.component_type?.id;

    if (excludeComponentTypeIds?.includes(componentTypeId)) {
      return false;
    }
    return true;
  });

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components: filteredComponents });
  }, [filteredComponents]);

  const handleAcceptErrand = () => {
    setLoadingAction(true);
    dispatch(
      acceptInspectionErrand({
        inspectionId: inspectionErrandId,
        errorCallback: () => {
          setLoadingAction(false);
        },
        successCallback: () => {
          setLoadingAction(false);
        },
      })
    );
  };

  const onDeclineSuccess = () => {
    setLoadingAction(false);
    push(overviewUrl());
  };

  const handleDeclineErrand = () => {
    setLoadingAction(true);
    dispatch(
      declineInspectionErrand({
        inspectionId: inspectionErrandId,
        successCallback: onDeclineSuccess,
        errorCallback: () => {
          setLoadingAction(false);
        },
      })
    );
  };

  const pauseErrand = () => {
    setLoadingUpdate(true);
    dispatch(
      update({
        id: inspectionErrandId,
        forceData: {
          status: 2,
        },
        successCallback: () => {
          setLoadingUpdate(false);
        },
        errorCallback: () => setLoadingUpdate(false),
      })
    );
  };

  const executeErrand = () => {
    if (inspectionErrand?.status !== 1) {
      setLoadingUpdate(true);
      dispatch(
        update({
          id: inspectionErrandId,
          forceData: {
            status: 1,
          },
          successCallback: () => {
            setLoadingUpdate(false);

            push(executeUrl({ id: inspectionErrandId }));
          },
          errorCallback: () => setLoadingUpdate(false),
        })
      );
    } else {
      push(executeUrl({ id: inspectionErrandId }));
    }
  };

  const reactivateErrand = () => {
    setReactivateErrandLoading(true);

    dispatch(
      update({
        id: inspectionErrandId,
        forceData: {
          status: 1,
        },
        successCallback: () => {
          setReactivateErrandLoading(false);
        },
        errorCallback: () => setReactivateErrandLoading(false),
      })
    );
  };

  const takeOverErrand = (newPerformer) => {
    setReactivateErrandLoading(true);
    dispatch(
      update({
        id: inspectionErrandId,
        forceData: {
          performer: newPerformer,
        },
        successCallback: () => {
          setReactivateErrandLoading(false);
        },
        errorCallback: () => setReactivateErrandLoading(false),
      })
    );
  };

  return (
    <>
      {canEditErrand && (
        <InspectionErrandPlanForm
          id={inspectionErrandId}
          isOpen={planErrandOpen}
          onCheckout={() => setPlanErrandOpen(false)}
          instance={inspectionErrand}
        />
      )}

      <ConfirmationModal
        isOpen={confirmExecuteOpen}
        title="Påbörja utförande"
        acceptTitle="Starta"
        closeFunction={() => setConfirmExecuteOpen(false)}
        renderContent={() => {
          return (
            <div>
              Är du säker på att du vill påbörja utförandet? Klicka på "Starta"
              för att starta igång utförandet.
            </div>
          );
        }}
        acceptCallback={executeErrand}
      />

      <DetailInnerWrapper>
        {inspectionErrand && currentUser && (
          <PerformerStatusBar
            errand={inspectionErrand}
            currentUser={currentUser}
            onAccept={handleAcceptErrand}
            onDecline={handleDeclineErrand}
            onPerform={
              inspectionErrand?.status === 1
                ? () => push(executeUrl({ id: inspectionErrandId }))
                : () => setConfirmExecuteOpen(true)
            }
            onPlan={() => setPlanErrandOpen(true)}
            onPause={pauseErrand}
            loading={loadingAction}
            canPause={inspectionErrand?.status === 1}
          />
        )}

        {[3, 4].includes(inspectionErrand?.status) && canEditErrand && (
          <FinishedErrandStatusBar
            onReactivate={reactivateErrand}
            loading={reactivateErrandLoading}
          />
        )}

        {[0, 1, 2].includes(inspectionErrand?.status) &&
          canEditErrand &&
          !inspectionErrand?.performer && (
            <AdminTakeOverErrandStatusBar
              errand={inspectionErrand}
              onTakeOver={takeOverErrand}
              loading={reactivateErrandLoading}
            />
          )}

        <DetailPageBox>
          {(loadingUpdate || inspectionErrandLoading) && <OverlaySpinner />}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, marginRight: 24 }}>
              <InspectionErrandAddressList premises={premises} kind={kind} />

              <InspectionErrandFault
                inspectionErrand={inspectionErrand}
                reportComments={reportComments}
              />
            </div>

            <div style={{ flex: 1, flexDirection: "column" }}>
              <DetailInfo
                infoObj={getInfoObj({
                  inspectionErrand,
                  reportedBy,
                  status,
                  display,
                  components,
                  durationStr,
                })}
              />
              <InnerBox>
                <Notes
                  notes={notes}
                  contentType={"errands.inspectionerrand"}
                  objectId={inspectionErrandId}
                  title="Interna anteckningar"
                />
              </InnerBox>
            </div>
          </div>
        </DetailPageBox>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Komponenter i objekt</OverviewTitle>
              <OverviewSubtitle>
                Exkluderar komponenter av komponenttyper som är exkluderade på
                besiktningsinställningen.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <RoomOverview
            rooms={componentsByRoom}
            selectOnly
            isNonRoomType={kind === PREMISES_TYPES.PARKING_SPOT}
            contentKey={
              kind === PREMISES_TYPES.PARKING_SPOT ? "parking_spot" : null
            }
            contentId={inspectionErrand?.parking_spot?.id} // only parking spot is non room type for insepction errand
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const getTime = (timeString) => {
  return timeString ? moment(timeString).format("YYYY-MM-DD HH:mm") : "-";
};

const isDelayed = (timeString, forText) => {
  const planned = moment(timeString);
  const today = moment();
  const delayed = planned.isBefore(today);
  if (delayed) {
    if (forText) {
      return true;
    }
    return 3;
  } else {
    if (forText) {
      return false;
    }
    return 1;
  }
};

const executeEndDelayed = (execute_end, planned_end, forText) => {
  const actualEnd = moment(execute_end);
  const planned = moment(planned_end);
  const delayed = planned.isBefore(actualEnd);
  if (delayed) {
    if (forText) {
      return true;
    }
    return 3;
  } else {
    if (forText) {
      return false;
    }
    return 1;
  }
};

function getInfoObj({
  inspectionErrand,
  reportedBy,
  status,
  display,
  components,
  durationStr,
}) {
  const getReportedBy = () => {
    const user = reportedBy;

    if (user?.tenant) {
      const obj = {
        id: user.tenant?.id,
        str_representation: user?.str_representation,
      };
      return <LinkedObject obj={obj} urlMethod={tenantDetailUrl} />;
    }
    return <LinkedObject obj={user} urlMethod={userDetailUrl} />;
  };

  const getPerformer = (errand) => {
    const foundPerformer = getChosenPerformer(errand);
    if (foundPerformer) {
      return (
        <LinkedObject
          obj={{ ...foundPerformer, id: foundPerformer.userId }}
          urlMethod={userDetailUrl}
        />
      );
    }
    return <div>Ingen utförare delegerad </div>;
  };

  let data = [
    {
      title: "Skapat",
      value: <DateCell date={inspectionErrand?.created_at} />,
    },
    {
      title: "Status",
      value: <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>,
    },
    {
      title: `${
        isDelayed(inspectionErrand?.planned_start, true)
          ? "Planerad start(Försenad)"
          : "Planerad start"
      }`,
      value: (
        <StatusLabel
          state={
            inspectionErrand?.planned_start
              ? isDelayed(inspectionErrand?.planned_start)
              : 10
          }
        >
          {getTime(inspectionErrand?.planned_start)}
        </StatusLabel>
      ),
    },
    {
      title: `${
        isDelayed(inspectionErrand?.planned_end, true)
          ? "Planerat slut(Försenad)"
          : "Planerat slut"
      }`,
      value: (
        <StatusLabel
          state={
            inspectionErrand?.planned_end
              ? isDelayed(inspectionErrand?.planned_end)
              : 10
          }
        >
          {getTime(inspectionErrand?.planned_end)}
        </StatusLabel>
      ),
    },
    {
      title: "Förväntad arbetstid",
      value: durationStr,
    },

    {
      title: "Antal komponenter",
      value: components?.length || "-",
    },
    {
      title: "Besiktningstyp",
      value: inspectionErrand?.setting?.str_representation,
    },

    {
      title: "Hyresgäst har förberett för tillträde",
      value: (
        <StatusLabel state={inspectionErrand?.can_use_key ? 0 : 3}>
          {inspectionErrand?.can_use_key ? "Ja" : "Nej"}
        </StatusLabel>
      ),
    },
    {
      title: "Utförare",
      value: getPerformer(inspectionErrand),
    },
    {
      title: "Rapporterat av",
      value: getReportedBy(),
    },
  ];

  if (inspectionErrand?.status === 3 || inspectionErrand?.status === 4) {
    const executeEnd = {
      title: `${
        executeEndDelayed(
          inspectionErrand?.execute_end,
          inspectionErrand?.planned_end,
          true
        )
          ? "Avklarad(Försenad)"
          : "Avklarad"
      }`,
      value: (
        <StatusLabel
          state={
            inspectionErrand?.execute_end
              ? executeEndDelayed(
                  inspectionErrand?.execute_end,
                  inspectionErrand?.planned_end
                )
              : 10
          }
        >
          {getTime(inspectionErrand?.execute_end)}
        </StatusLabel>
      ),
    };
    data.splice(2, 1, executeEnd);

    {
      /*Visa om det blev löst eller inte*/
    }
    if (inspectionErrand?.active_rating) {
      const componentIsOk = {
        title: "Fel avhjälpt",
        value: (
          <StatusLabel state={inspectionErrand?.active_rating?.is_ok ? 1 : 6}>
            {inspectionErrand?.active_rating?.is_ok ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      };
      data.splice(3, 1, componentIsOk);
    }
  }

  const infoObj = {
    Ärendeinformation: data,
  };

  return infoObj;
}
