import { axiosInstance } from "../base/store/axios";
import constants from "./constants";

async function generateAvyId(realEstateId) {
  const data = await axiosInstance.post(constants.GENERATE_ID_URL, {
    id: realEstateId,
  });

  return data;
}

export { generateAvyId };
