export default {
  SET_AVAILABLE_REPORTS: "SET_AVAILABLE_REPORTS",
  SET_CURRENT_REPORT_DATA: "SET_CURRENT_REPORT_DATA",
  SET_CURRENT_REPORT_STRUCTURE: "SET_CURRENT_REPORT_STRUCTURE",
  LIST_URL: "/reporting/generators/performedreport/list/",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS",
  SET_SELECTED_REPORT_TAB: "SET_SELECTED_REPORT_TAB",
  PURGE_ALL: "PURGE_ALL_REPORTS",
  INSERT_CUSTOM_REPORTS: "INSERT_CUSTOM_REPORTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_REPORTS",
  PURGE_FORM_ERRORS: "PURGE_FORM_ERRORS_REPORTS",
  SET_CURRENT_GET_REPORTS_PARAMS: "SET_CURRENT_GET_REPORTS_PARAMS_REPORTS",
  UPDATE_REPORT_IN_ALL: "UPDATE_REPORT_IN_ALL_EXCEL_REPORTS",
  SET_HOT_REFRESH: "SET_HOT_REFRESH_REPORTS",
  SET_BADGE_FILTER_COUNTS: "SET_BADGE_FILTER_COUNTS_REPORTS",
  SET_TEMPLATE_PATHING: "SET_TEMPLATE_PATHING_REPORTS",
  PURGE_TEMPLATE_PATHING: "PURGE_TEMPLATE_PATHING_REPORTS",
  REMOVE_REPORT_IN_ALL: "REMOVE_REPORT_IN_ALL_REPORTS",

  STORE_NAME: "newExcelReports",
};
