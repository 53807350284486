import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateKivraSettings } from "../../../store/billectaIntegrations/store/actions";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import { TextInput, Checkbox } from "../../Forms/Base/Fields";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import constants from "../../../store/billectaIntegrations/store/constants";
import { useKivraForm } from "../../../store/billectaIntegrations/hooks/form";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useCompanyInvoicing } from "../../../store/invoicingCompany";
export default function KivraIntegrationForm({
  open,
  closeFunction,
  companies,
  currentCompany,
}) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const isEnabled = useSelector((state) => {
    if (currentCompany) {
      return state.billectaIntegrations?.kivraSettings[currentCompany.id];
    } else {
      return null;
    }
  });

  // const [companyInvoicing, setCompanyInvoicing] = useCompanyInvoicing(companyInvoicingId)
  useKivraForm("PUT", open);

  const [companyInvoicing, companyInvoicingLoading] =
    useCompanyInvoicing(currentCompany?.invoicing_details?.id);

  const currentFormInstance = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "",
  });

  const handleSubmit = () => {
    const successMsg = isEnabled
      ? "Kivra integrationen har avaktiverats"
      : "Kivra integrationen har aktiverats";
    const errorMsg = isEnabled
      ? "Avaktivering av Kivra integrationen misslyckades"
      : "Aktivering av Kivra integrationen misslyckades";
    dispatch(
      updateKivraSettings({
        companies: companies,
        creditorId: currentCompany?.billecta_id,
        organisationId: currentFormInstance?.OrganisationId,
        sendByMail: currentFormInstance?.SendByMailIfKivraIsNotAvailable,
        enable: !isEnabled,
        successCallback: () => {
          closeFunction();
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: successMsg,
            })
          );
        },
        errorCallback: () => {
          closeFunction();
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: errorMsg,
            })
          );
        },
      })
    );
  };

  React.useEffect(() => {
    let vatNumber = null;
    if (companyInvoicing?.vat_number) {
      vatNumber = companyInvoicing.vat_number;
    } else if (currentCompany?.orgnr) {
      vatNumber = `SE${currentCompany.orgnr}01`;
    }
    if (vatNumber != null) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {
            OrganisationId: vatNumber,
          },
        })
      );
    }
  }, [currentCompany, companyInvoicing]);

  return (
    <StandardModal
      isOpen={open}
      closeFunction={closeFunction}
      withActionBar
      actionBarAcceptTitle={
        !isEnabled ? "Aktivera integration" : "Inaktivera integration"
      }
      title="Integration - Kivra"
      saveFunction={handleSubmit}
      actionBarAcceptIsWarning={isEnabled}
    >
      {loading && <OverlaySpinner />}
      <FormAreaTitle>Kivra</FormAreaTitle>
      <FormAreaDescription>
        Integrationen Kivra tillåter bolaget att skicka avier via Kivras
        digitala brevlåda
      </FormAreaDescription>
      <TextInput
        extraStyles={{ minWidth: "290px" }}
        storeName={constants.STORE_NAME}
        fieldKey={"OrganisationId"}
        method={"PUT"}
      />
      <Checkbox
        storeName={constants.STORE_NAME}
        fieldKey={"SendByMailIfKivraIsNotAvailable"}
        method={"PUT"}
        title="Skicka via post om leverans med Kivra ej är möjlig"
      />
    </StandardModal>
  );
}
