import * as React from "react";

// style, design
import { LinkedObject, TimeCell, ToolTipCell } from "../../Displays";
import { NotificationDot } from "../../Layout/Sidebar/styles";
import SimpleBooleanFilter from "../../Lists/Base/CompleteList/Filters/BooleanFilter";
import SimpleDateFilter from "../../Lists/Base/CompleteList/Filters/DateFilter";
import SimpleTextFilter from "../../Lists/Base/CompleteList/Filters/TextFilter";
import CategoryFilter from "./Filters/CategoryFilter";

export default (invoices) => [
  {
    Header: " ",
    accessor: "read",
    Cell: ({ value }) => (
      <div>
        {!value ? <NotificationDot style={{ height: 14, width: 14 }} /> : ""}
      </div>
    ),
    Filter: SimpleBooleanFilter,
    filter: "text",
  },
  {
    Header: "Händelse",
    accessor: "category",
    Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
    Filter: CategoryFilter,
    filter: "textExact",
  },
  {
    Header: "Fakturanummer / ID",
    accessor: "related_id",
    Cell: ({ value }) => {
      const relatedInvoice = invoices?.find((i) => i.ActionPublicId === value);

      return <ToolTipCell text={relatedInvoice?.InvoiceNumber || value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fakturamottagare",
    accessor: "related_id_",
    Cell: ({ row }) => {
      if (row.original.related_tenant_config) {
        return (
          <LinkedObject
            obj={row.original.related_tenant_config}
            urlMethod={() =>
              `/error-redirect?contentType=invoicingdebtor&id=${row.original.related_tenant_config.id}`
            }
          />
        );
      }
      const relatedInvoice = invoices?.find(
        (i) => i.ActionPublicId === row.original.related_id
      );

      return <ToolTipCell text={relatedInvoice?.DebtorName || "-"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Tid för händelse",
    accessor: "added_time",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
];
