import styled from "styled-components";

export const PipeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  margin-bottom: 24px;
`;

export const Pipe = styled.div`
  position: relative;
  background-color: white;
  min-height: 200px;
  padding: 12px;
  padding-bottom: 46px;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  overflow: hidden;
`;

export const ColorBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: ${(p) => p.color};
`;

export const PipeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const PipeFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: ${(p) => p.theme.borders.standard};
`;

export const PipeTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  flex-direction: row;
  display: flex;
  align-items: center;
  line-height: 110%;
`;

export const PipeDescription = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
  text-overflow: ellipsis;
`;
