import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TextField, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingCostCenters";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Kostnadsställe</FormAreaTitle>

      <TextInput
        title="Kod för kostnadsställe"
        storeName={storeName}
        fieldKey={"title"}
        method={method}
      />
      <TextField
        title="Beskrivning"
        storeName={storeName}
        fieldKey={"description"}
        method={method}
      />
    </>
  );
};
