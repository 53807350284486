import * as React from "react";

import { ToolTipCell, EmailTag, PhoneTag, BooleanLabel } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import StateFilter from "./Filters/StateFilter";
import { getMemberDisplay, getMemberState } from "./listDefs";

export default (isMembers = false) => [
  {
    Header: "Status",
    accessor: "state",
    Cell: ({ row }) => (
      <StatusLabel
        state={
          isMembers ? getMemberState(row.original.state) : row.original.state
        }
        contentType="tenant"
      >
        {isMembers
          ? getMemberDisplay(row.original.state)
          : row.original.state_display}
      </StatusLabel>
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },

  {
    Header: "Namn",
    accessor: "str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },

  {
    Header: "Email",
    accessor: "user.email",
    Cell: ({ value }) => <EmailTag mail={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
