import classNames from "classnames";
import * as React from "react";

const SkeletonTableItem = () => {
  return <div className="w-full bg-gray-200 h-[20px] animate-pulse"></div>;
};

export const generateSkeletonPage = (
  columns,
  pageSize,
  customColumnsAmount
) => {
  let skelPage = [];

  const cols = columns.slice();

  for (let j = 0; j < customColumnsAmount; j++) {
    cols.push({
      id: "customColumn" + j,
    });
  }

  for (let i = 0; i < 10; i++) {
    skelPage.push(
      <tr className={classNames("bg-white border-b even:bg-slate-50 hover:bg-blue-100 cursor-pointer")} style={{
        opacity: `${(100 - (i * 10)) / 100}`
      }}>
        {cols.map((col, index) => {
          return (
            <td className="p-2 whitespace-nowrap align-middle">
              <div className="flex items-center text-xm text-start">
                <SkeletonTableItem  />
              </div>
            </td>
          );
        })}
      </tr>
    );
  }

  return <tbody>{skelPage}</tbody>;
};
