import Axios from "axios";
import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function fetchAvailableExcelReports() {
  const { data } = await axiosInstance.get(
    constants.EXCEL_AVAILABLE_REPORTS_URL
  );

  return data;
}

async function fetchAvailablePptReports() {
  const { data } = await axiosInstance.get(constants.PPT_AVAILABLE_REPORTS_URL);

  return data;
}

async function runExcelReport({ id, postData }) {
  const { data } = await axiosInstance.post(
    `${constants.EXCEL_CREATE_REPORT_URL}${id}/`,
    postData
  );

  return data;
}
async function runPptReport({ id, postData }) {
  const { data } = await axiosInstance.post(
    `${constants.PPT_CREATE_REPORT_URL}${id}/`,
    postData
  );

  return data;
}

async function rerunExcelReport({ id }) {
  const { data } = await axiosInstance.post(
    `${constants.EXCEL_RERUN_REPORT_URL}${id}/`
  );

  return data;
}

async function rerunPptReport({ id }) {
  const { data } = await axiosInstance.post(
    `${constants.PPT_RERUN_REPORT_URL}${id}/`
  );

  return data;
}

async function getPdfFromPowerPoint({ reportId }) {
  const { data } = await axiosInstance.get(
    `${constants.PPT_PDF_URL}${reportId}/`
  );

  return data;
}

export {
  fetchAvailableExcelReports,
  fetchAvailablePptReports,
  rerunExcelReport,
  runExcelReport,
  runPptReport,
  rerunPptReport,
  getPdfFromPowerPoint,
};
