const defs = [
  {
    title: "Dörr",
    key: "DEVICE",
    visited: true,
    hasError: false,
    fieldKeys: [
      "apartment",
      "industrial_premises",
      "common_area",
    ],
  },
  {
    title: "Integrationer",
    key: "INTEGRATION",
    visited: false,
    hasError: false,
    fieldKeys: [
      "parakey_id",
    ],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
