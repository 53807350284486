import * as React from "react";

import { constants } from "../../../../../store/marketIndustrialPremisesCommodities";
import { TextField } from "../../../Base/Fields";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Beskrivning</OverviewTitle>
          <OverviewSubtitle>Beskrivning av lägenheten</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      <TextField
        {...{
          storeName,
          method,
          description: "Minst 100, max 300 tecken",
          fieldKey: "informational_description",
          title: "Beskrivning (100 - 300 tecken)",
        }}
        rows="5"
      />
    </>
  );
};
