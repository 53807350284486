import {
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import BrfPremisesTable from "src/components/Tables/BrfPremises/FullTable";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";
import ErrandComponentPlacementTable from "src/components/Tables/ErrandComponents/FullTable";

import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";
import { getCurrentProductTypeUsage } from "src/components/TechnicalManagement/utils";

import { useApartmentPaginationCount } from "src/store/apartments";
import { useBrfPremisesPaginationCount } from "src/store/brfPremises";
import { useBuildingPaginationCount } from "src/store/buildings";
import { useCommonAreasPaginationCount } from "src/store/commonAreas/hooks/retrieve";
import { useIndustrialPremisesPaginationCount } from "src/store/industrialPremises";
import { useParkingSpotsPaginationCount } from "src/store/parkingSpots/hooks/retrieve";
import { useRealEstatePaginationCount } from "src/store/realEstates";
import FaultReportForm from "./FaultReportForm";

const TYPES = {
  REAL_ESTATE: "realestate",
  BUILDING: "building",
  COMMON: "common_areas",
  APARTMENT: "apartments",
  INDP: "industrial_premises_list",
  PARKING: "parking_spot",
  BRF: "brf_premises",
};

export default function ExecuteRoundingObjectRenderer({
  area,
  realestate,
  setSelectedRealEstate,
  canGoBack,
  goBackToStart,
}) {
  const [selectedType, setSelectedType] = React.useState(null);
  const [selectedObject, setSelectedObject] = React.useState(null);
  const [selectedComponentPlacement, setSelectedComponentPlacement] =
    React.useState(null);

  const currentComponentType = getCurrentProductTypeUsage(
    selectedComponentPlacement
  );
  const componentType =
    currentComponentType?.product_type?.component_type?.str_representation;

  console.log({ area });

  const [apartmentCount] = useApartmentPaginationCount({
    filters: {
      component_types: area?.component_types?.map((cp) => cp.id) || [-1],
      realestate_ids: realestate?.id,
    },
  });

  const [indpCount] = useIndustrialPremisesPaginationCount({
    filters: {
      component_types: area?.component_types?.map((cp) => cp.id) || [-1],
      realestate_ids: realestate?.id,
    },
  });

  const [commonCount] = useCommonAreasPaginationCount({
    filters: {
      component_types: area?.component_types?.map((cp) => cp.id) || [-1],
      realestate_ids: realestate?.id,
    },
  });

  const [parkingCount] = useParkingSpotsPaginationCount({
    filters: {
      component_types: area?.component_types?.map((cp) => cp.id) || [-1],
      realestate_ids: realestate?.id,
    },
  });

  const [brfCount] = useBrfPremisesPaginationCount({
    filters: {
      component_types: area?.component_types?.map((cp) => cp.id) || [-1],
      realestate_ids: realestate?.id,
    },
  });

  const [buildingCount] = useBuildingPaginationCount({
    filters: {
      component_types: area?.component_types?.map((cp) => cp.id) || [-1],
      realestate_ids: realestate?.id,
    },
  });

  const [realEstateHasComponents] = useRealEstatePaginationCount({
    filters: {
      component_types: area?.component_types?.map((cp) => cp.id) || [-1],
      id: realestate?.id,
    },
  });

  if (selectedComponentPlacement) {
    return (
      <>
        <FaultReportForm
          {...{
            selectedObject,
            setSelectedComponentPlacement,
            selectedComponentPlacement,
            realestate,
            componentType,
            TYPES,
            selectedType,
            goBackToStart,
          }}
        />
      </>
    );
  }

  // if object or realestate is selected
  if (selectedObject || selectedType === TYPES.REAL_ESTATE) {
    return (
      <>
        <div className="mb-6">
          <PrimaryBtn
            secondary
            onClick={() => {
              if (selectedObject) {
                setSelectedObject(null);
              } else {
                setSelectedType(null);
              }
            }}
          >
            <ChevronLeftIcon width={16} className="mr-1" /> TIllbaka till val av
            objekt
          </PrimaryBtn>
        </div>

        <ErrandComponentPlacementTable
          persistantQuery={{
            [selectedType]: selectedObject?.id || realestate?.id,
            component_types: area?.component_types?.map((cp) => cp.id) || [], // TODO FILTER DOESN'T work
          }}
          onRowClicked={(obj) => setSelectedComponentPlacement(obj)}
        />
      </>
    );
  }

  if (selectedType) {
    let Comp = null;
    switch (selectedType) {
      case TYPES.BUILDING:
        Comp = (
          <BuildingTable
            persistantQuery={{
              component_types: area?.component_types?.map((cp) => cp.id) || [],
              realestate_ids: realestate?.id,
            }}
            onRowClicked={(obj) => setSelectedObject(obj)}
          />
        );
        break;

      case TYPES.COMMON:
        Comp = (
          <CommonAreaTable
            persistantQuery={{
              component_types: area?.component_types?.map((cp) => cp.id) || [],
              realestate_ids: realestate?.id,
            }}
            onRowClicked={(obj) => setSelectedObject(obj)}
          />
        );
        break;

      case TYPES.APARTMENT:
        Comp = (
          <ApartmentTable
            persistantQuery={{
              component_types: area?.component_types?.map((cp) => cp.id) || [],
              realestate_ids: realestate?.id,
            }}
            onRowClicked={(obj) => setSelectedObject(obj)}
          />
        );
        break;
      case TYPES.INDP:
        Comp = (
          <IndustrialPremisesTable
            persistantQuery={{
              component_types: area?.component_types?.map((cp) => cp.id) || [],
              realestate_ids: realestate?.id,
            }}
            onRowClicked={(obj) => setSelectedObject(obj)}
          />
        );
        break;
      case TYPES.BRF:
        Comp = (
          <BrfPremisesTable
            persistantQuery={{
              component_types: area?.component_types?.map((cp) => cp.id) || [],
              realestate_ids: realestate?.id,
            }}
            onRowClicked={(obj) => setSelectedObject(obj)}
          />
        );
        break;
      case TYPES.PARKING:
        Comp = (
          <ParkingSpotsTable
            persistantQuery={{
              component_types: area?.component_types?.map((cp) => cp.id) || [],
              realestate_ids: realestate?.id,
            }}
            onRowClicked={(obj) => setSelectedObject(obj)}
          />
        );
        break;
    }

    return (
      <>
        <div className="mb-6">
          <PrimaryBtn secondary onClick={() => setSelectedType(null)}>
            <ChevronLeftIcon width={16} className="mr-1" /> TIllbaka till val av
            objektstyp
          </PrimaryBtn>
        </div>

        {Comp}
      </>
    );
  }

  return (
    <>
      {canGoBack && (
        <div className="mb-6">
          <PrimaryBtn secondary onClick={() => setSelectedRealEstate(null)}>
            <ChevronLeftIcon width={16} className="mr-1" /> TIllbaka till
            fastigheter
          </PrimaryBtn>
        </div>
      )}

      <div className="space-y-2">
        {realEstateHasComponents > 0 && (
          <PrimaryBtn
            secondary
            onClick={() => setSelectedType(TYPES.REAL_ESTATE)}
          >
            Visa komponenter på fastigheten{" "}
            <ChevronDoubleRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
        {buildingCount > 0 && (
          <PrimaryBtn secondary onClick={() => setSelectedType(TYPES.BUILDING)}>
            Visa {buildingCount} byggnader som innehåller relevanta komponenter
            <ChevronDoubleRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
        {commonCount > 0 && (
          <PrimaryBtn secondary onClick={() => setSelectedType(TYPES.COMMON)}>
            Visa {commonCount} gemensamma utrymmen som innehåller relevanta
            komponenter
            <ChevronDoubleRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
        {apartmentCount > 0 && (
          <PrimaryBtn
            secondary
            onClick={() => setSelectedType(TYPES.APARTMENT)}
          >
            Visa {apartmentCount} lägenheter som innehåller relevanta
            komponenter
            <ChevronDoubleRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
        {brfCount > 0 && (
          <PrimaryBtn secondary onClick={() => setSelectedType(TYPES.BRF)}>
            Visa {brfCount} bostadsrätter som innehåller relevanta komponenter
            <ChevronDoubleRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
        {indpCount > 0 && (
          <PrimaryBtn secondary onClick={() => setSelectedType(TYPES.INDP)}>
            Visa {indpCount} lokaler som innehåller relevanta komponenter
            <ChevronDoubleRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
        {parkingCount > 0 && (
          <PrimaryBtn secondary onClick={() => setSelectedType(TYPES.PARKING)}>
            Visa {parkingCount} fordonsplatser som innehåller relevanta
            komponenter
            <ChevronDoubleRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
      </div>
    </>
  );
}
