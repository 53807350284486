import styled from "styled-components";

export const DeleteTitle = styled.div`
  color: ${(p) => p.theme.colors.black};
  font-weight: 600;
  font-size: 1.4rem;
`;

export const DeleteSubtitle = styled.div`
  color: ${(p) => p.theme.colors.black};
`;

export const DeleteDataWrapper = styled.div`
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  border-radius: 5px;
`;

export const DeleteDataType = styled.div`
  margin: 10px 0;
  color: black;
`;

export const DeleteDataTypeTitle = styled.div`
  font-weight: 600;
  display: inline-block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
`;

export const DeleteDataItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 0;
`;
