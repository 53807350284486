import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function massUpdateInvoicingStatus({ patchObj }) {
  const res = await axiosInstance.patch(
    constants.MASS_INVOICE_STATUS_UPDATE_URL,
    patchObj
  );

  return res;
}

async function createMany(postData) {
  const resp = axiosInstance.post(constants.LIST_URL, postData);

  return resp;
}

async function getPreview({ id, basedOn }) {
  const data = axiosInstance.get(
    `${constants.PREVIEW_URL}${id}/${basedOn ? `?based_on=${basedOn}` : ""}`
  );

  return data;
}

async function generateNow({ id, postData }) {
  const data = axiosInstance.post(`${constants.GENERATE_URL}${id}/`, postData);

  return data;
}

async function getPeriodInfo({ id, basedOn }) {
  const data = axiosInstance.get(
    `${constants.PERIOD_URL}${id}/${basedOn ? `?based_on=${basedOn}` : ""}`
  );

  return data;
}

export {
  getPeriodInfo,
  getPreview,
  generateNow,
  massUpdateInvoicingStatus,
  createMany,
};
