import baseReducer from "../../billecta/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_ACCOUNT_BALANCE: {
      return { ...newState, [payload.debtorId]: payload.accountBalance };
    }
    default:
      return newState;
  }
};
