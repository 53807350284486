export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INSPECTION_ERRAND_SETTING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INSPECTION_ERRAND_SETTING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INSPECTION_ERRAND_SETTING",
  DESTROY_FORM: "DESTROY_FORM_INSPECTION_ERRAND_SETTING",
  SET_FORM_ERROR: "SET_FORM_ERROR_INSPECTION_ERRAND_SETTING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INSPECTION_ERRAND_SETTING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INSPECTION_ERRAND_SETTING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INSPECTION_ERRAND_SETTING",
  RESET_STATE: "RESET_STATE_INSPECTION_ERRAND_SETTING",
  CLEAR_FETCHED: "CLEAR_FETCHED_INSPECTION_ERRAND_SETTING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INSPECTION_ERRAND_SETTING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INSPECTION_ERRAND_SETTING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INSPECTION_ERRAND_SETTING",

  LIST_URL: "/errands/settings/inspectionerrandsetting/list/",
  POST_URL: "/errands/settings/inspectionerrandsetting/",
  PATCH_URL: "/errands/settings/inspectionerrandsetting/",
  GET_URL: "/errands/settings/inspectionerrandsetting/",

  STORE_NAME: "inspectionErrandSettings",
};
