import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { setActiveFormInstance } from "../../../../store/base";
import {
  useErrandArticleForm,
  destroyPatchForm,
  destroyPostForm,
  create,
  update,
  constants,
  detailUrl,
} from "../../../../store/errandArticles";
import ModalChapter from "../../Base/Chapters/ModalChapter";

import chapterDefs from "./chapterDefs";
import renderChapter from "./renderChapter";

export default ({ method, id, onCheckout, isOpen, instance }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const storeName = constants.STORE_NAME;

  const formLoaded = Boolean(useErrandArticleForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, [isOpen, instance]);

  const checkout = (success, data) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));

      push(detailUrl({ id: data.id }));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (data) => {
    checkout(true, data);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: (_, data) => onSuccess(data),
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Skapa artikel" : "Uppdatera artikel"}
      onDone={onCheckout}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
