import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { constants } from "../../../../../store/otherContracts";

import DocumentUploader from "../../../Base/Fields/DocumentUploader";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const docFileName = useFormInstanceField({
    storeName,
    fieldKey: "editabledoc.doc",
  });
  const docTitle = useFormInstanceField({
    storeName,
    fieldKey: "editabledoc.title",
  });

  React.useEffect(() => {
    if (docFileName && !docTitle) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { "editabledoc.title": docFileName.substr(0, 60) },
        })
      );
    }
  }, [docFileName]);

  return (
    <>
      <>
        <DocumentUploader
          title="Dokument för avtal"
          description="Ladda upp det existerande avtalet om det finns ett sådant"
          storeName={storeName}
          fieldKey={"editabledoc.doc"}
          method={method}
        />
      </>
    </>
  );
};
