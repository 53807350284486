import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useComponentType } from "../../store/componentTypes";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ComponentTypeDetailOverview from "./componentTypeDetail/Overview";
import Errands from "./componentTypeDetail/Errands";

export default function ComponentTypeDetail() {
  const { path, url } = useRouteMatch();
  const { id } = useParams();

  const [componentType, componentTypeLoading] = useComponentType(id);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ComponentTypeDetailOverview,
    },
    {
      path: "/errands",
      label: "Felanmälningar under komponenttyp",
      component: Errands,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title={`Komponenttyp${
          componentType?.str_representation
            ? ` - ${componentType.str_representation}`
            : ""
        }`}
        breadCrumbs={[
          {
            label: "Inställningar Teknisk Förvaltning",
            url: "/errand-settings/component-types",
          },
          {
            label: componentType?.str_representation || "Komponenttyp",
          },
        ]}
        eventContentType="errands.componenttype"
        eventObjectId={id}
        eventIdQueryStr="componenttype"
        {...{ subPages: subPageLinks }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}
