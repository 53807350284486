import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import LeaseContractsTable from "../../../components/Tables/LeaseContracts/FullTableNewVersion";
import OtherTable from "src/components/Tables/OtherContracts/FullTableNewVersion";
import BasicDocTable from "../../../components/Tables/BasicDocs/FullTable";

import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

import {
  buildQueryString,
  updateActiveFormInstance,
  usePermissionCheck,
} from "../../../store/base";

import { useDispatch } from "react-redux";
import { createOptionsUrl } from "../../../store/leaseContracts";

import { constants as basicConstants } from "../../../store/basicDocs";

import BasicModal from "../../../components/Forms/BasicDoc/ChapterForm/ModalForm";

import { useApartment } from "../../../store/apartments";
import NewContractGeneration, {
  NEW_CONTRACT_GENERATION_KINDS,
} from "../../../components/Details/Apartment/NewContractGeneration";
import PrimaryBtn from "../../../components/Forms/Base/Buttons/PrimaryBtn";
import Modal from "../../../components/Forms/Base/Modals/Modal";

export default function ApartmentContracts() {
  const [createBasicActive, setCreateBasicActive] = React.useState(false);
  const [newGenerationOpen, setNewGenerationOpen] = React.useState(false);

  const dispatch = useDispatch();

  const { apartmentId } = useParams();
  const [apartment] = useApartment(apartmentId);

  const canAddLease = usePermissionCheck("add_can_contract");

  const leaseQuery = { apartments: apartmentId };

  const otherQuery = {
    content_type__model: "apartment",
    object_id: apartment?.id,
  };

  const basicQuery = {
    apartment: apartment?.id,
  };

  const initiateBasicCreation = () => {
    const data = {
      content_type: "apartment",
      object_id: apartment.id,
    };
    dispatch(
      updateActiveFormInstance({ storeName: basicConstants.STORE_NAME, data })
    );
    setCreateBasicActive(true);
  };

  return (
    <>
      {createBasicActive && (
        <BasicModal
          method="POST"
          onCheckout={() => setCreateBasicActive(false)}
        />
      )}

      {newGenerationOpen && (
        <Modal closeFunction={() => setNewGenerationOpen(false)}>
          <NewContractGeneration
            kind={NEW_CONTRACT_GENERATION_KINDS.APARTMENT}
            premises={apartment}
          />
        </Modal>
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Hyresavtal</OverviewTitle>
            {canAddLease && (
              <PrimaryBtn onClick={() => setNewGenerationOpen(true)}>
                Ny avtalsgeneration
              </PrimaryBtn>
            )}
          </OverviewTitleWrapper>

          <LeaseContractsTable
            isBare
            ignoreLocalStorage
            persistantQuery={leaseQuery}
          />
        </DetailPageBox>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Övriga avtal</OverviewTitle>
          </OverviewTitleWrapper>

          <OtherTable persistantQuery={otherQuery} isBare ignoreLocalStorage />
        </DetailPageBox>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Dokument</OverviewTitle>

            {canAddLease && (
              <PrimaryButton
                title="Lägg till nytt dokument"
                clicked={initiateBasicCreation}
              />
            )}
          </OverviewTitleWrapper>

          <BasicDocTable
            persistantQuery={basicQuery}
            isBare
            ignoreLocalStorage
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
