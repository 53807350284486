import { axiosInstance } from "../../base/store/axios";

import roomConstants from "../../rooms/store/constants";

async function connectRoomsToPremises({ id, data }) {
  const endpoint = `${roomConstants.PATCH_URL}${id}/`;

  const res = await axiosInstance.patch(endpoint, data);

  return res;
}

export { connectRoomsToPremises };
