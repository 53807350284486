import { PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { cloneDeep, get, isEqual } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import Alert, { ALERT_TYPES } from "../Alert/Alert";
import PrimaryBtn from "../Forms/Base/Buttons/PrimaryBtn";
import CheckField from "../Forms/Base/Fields/CheckField";
import LocalSelectField from "../Forms/Base/Fields/LocalSelectField";
import TextInputField from "../Forms/Base/Fields/TextInputField";
import {
  FIELD_TYPES,
  getInitialPlacement,
  getPageFromBoundsAndPosition,
  toInternalFontSize,
  toExternalFontSize,
} from "./utils";

const LOCKED_KINDS = [
  "first_name",
  "last_name",
  "company",
  "company_number",
  "personal_number",
  "email",
  "phone",
  "corporate_name",
  "full_name",
];

const FONT_SIZES_OPTIONS = [
  { v: 16, d: "Liten" },
  { v: 18, d: "Normal" },
  { v: 20, d: "Stor" },
];

export default function DigitalDocEditField({
  partyKey,
  fieldKey,
  fieldInstructionKey,
  method,
  placement,
  placementKey,
  storeName,
  closeFunction,
  pageBounds,
}) {
  const [fontSize, setFontSize] = React.useState(null);
  const dispatch = useDispatch();
  const party = useFormInstanceField({
    storeName,
    fieldKey: partyKey,
  });

  const field = useFormInstanceField({
    storeName,
    fieldKey,
  });

  const isObligatory = field?.is_obligatory;

  React.useEffect(() => {
    const { f } = toInternalFontSize({
      f: placement.fsrel,
      pageData: pageBounds[placement.page],
    });

    setFontSize(f);
  }, []);

  const onFontSizeUpdated = (fs) => {
    const newSize = toExternalFontSize({
      f: fs,
      pageData: pageBounds[placement.page],
    }).f;
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`${placementKey}.fsrel`]: newSize,
        },
      })
    );
    setFontSize(fs);
  };

  const removeField = () => {
    const fields = party?.fields || [];

    if (!fields.length) {
      return;
    }

    if (!field) {
      return;
    }

    const newFields = fields.filter((f) => f.name !== field.name);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`${partyKey}.fields`]: newFields },
      })
    );
  };

  const removePlacement = () => {
    const placements = get(field, `placements`, []);
    if (!placements.length) {
      return;
    }

    const newPlacements = placements.filter((p) => !isEqual(p, placement));

    // delete the position
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`${fieldKey}.placements`]: newPlacements },
      })
    );
  };

  const handleRemoveField = () => {
    removeField();
    closeFunction();
  };

  const handleRemovePlacement = () => {
    removePlacement();
    closeFunction();
  };

  const addPosition = () => {
    const scrollTop = window.scrollY;
    const { page: currentPage, start: currentPageStart } =
      getPageFromBoundsAndPosition({
        pageBounds,
        y: scrollTop,
      });
    const currentFields = cloneDeep(party.fields);

    const extraTopOffset = scrollTop - currentPageStart;

    const matchingFieldIdx = currentFields.findIndex(
      (f) => f.name === field.name
    );

    if (!currentFields[matchingFieldIdx].placements?.length) {
      currentFields[matchingFieldIdx].placements = [];
    }

    currentFields[matchingFieldIdx].placements.push({
      value:
        currentFields[matchingFieldIdx].kind === FIELD_TYPES.RADIO
          ? currentFields[matchingFieldIdx].placements?.length + 1
          : undefined, // set value for this radio to its length+1
      ...getInitialPlacement({
        fontSize: 16,
        extraTopOffset,
        isCheckbox:
          currentFields[matchingFieldIdx].kind === FIELD_TYPES.CHECKBOX ||
          currentFields[matchingFieldIdx].kind === FIELD_TYPES.RADIO, // is checkbox or radio,
        pageBounds: pageBounds,
        page: currentPage,
      }),
    });

    const key = `${fieldKey.split(".")[0]}.fields`;
    dispatch(
      updateActiveFormInstance({ storeName, data: { [key]: currentFields } })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: `Fältet "${field.name}" lades till på dokumentet`,
        description: "Dra i fältet för att ändra positionen",
      })
    );
  };

  return (
    <>
      <Alert type={ALERT_TYPES.INFO} title="Redigera fält">
        Redigera det valda fältet. Vissa fält måste finnas på parten, då kan
        enbart placeringen av fältet tas bort men det ligger kvar på parten.
      </Alert>

      <div className="grid grid-cols-1 gap-2 mb-6">
        {!LOCKED_KINDS.includes(field.kind) && (
          <PrimaryBtn error onClick={handleRemoveField}>
            Ta bort fält från part <TrashIcon width={16} className="ml-1" />
          </PrimaryBtn>
        )}
        <PrimaryBtn secondary onClick={handleRemovePlacement}>
          Ta bort placering <XMarkIcon width={16} className="ml-1" />
        </PrimaryBtn>
        <PrimaryBtn onClick={addPosition}>
          Lägg till placering <PlusIcon width={16} className="ml-1" />
        </PrimaryBtn>
      </div>

      <div className="grid grid-cols-1 gap-4 mb-6">
        <LocalSelectField
          title="Tillhör part"
          value={null}
          onChange={() => {}}
          description="Byt för att flytta fält till annan part"
        />

        <TextInputField
          title="Namn på fält"
          storeName={storeName}
          fieldKey={`${fieldKey}.name`}
          instructionsKey={`${fieldInstructionKey}.name`}
          method={method}
        />

        {![
          FIELD_TYPES.CHECKBOX,
          FIELD_TYPES.RADIO,
          FIELD_TYPES.SIGNATURE,
        ].includes(field.kind) && (
          <>
            <TextInputField
              title="Värde på fält"
              storeName={storeName}
              fieldKey={`${fieldKey}.value`}
              instructionsKey={`${fieldInstructionKey}.value`}
              method={method}
            />

            <LocalSelectField
              choices={FONT_SIZES_OPTIONS}
              value={fontSize}
              onChange={onFontSizeUpdated}
              title="Storlek"
            />
          </>
        )}

        {[FIELD_TYPES.CHECKBOX].includes(field.kind) && (
          <CheckField
            title="Värde på fält"
            storeName={storeName}
            fieldKey={`${fieldKey}.is_checked`}
            instructionsKey={`${fieldInstructionKey}.is_checked`}
            method={method}
          />
        )}

        <CheckField
          title="Kräver ifyllning"
          storeName={storeName}
          fieldKey={`${fieldKey}.is_obligatory`}
          instructionsKey={`${fieldInstructionKey}.is_obligatory`}
          method={method}
        />

        {isObligatory && (
          <CheckField
            title="Ska fyllas i av avsändare"
            storeName={storeName}
            fieldKey={`${fieldKey}.should_be_filled_by_sender`}
            instructionsKey={`${fieldInstructionKey}.should_be_filled_by_sender`}
            method={method}
          />
        )}
      </div>
    </>
  );
}
