export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_EXTERNALSYNC",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_EXTERNALSYNC",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_EXTERNALSYNC",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_EXTERNALSYNC",
  DESTROY_FORM: "DESTROY_FORM_EXTERNALSYNC",
  SET_FORM_ERROR: "SET_FORM_ERROR_EXTERNALSYNC",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_EXTERNALSYNC",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_EXTERNALSYNC",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_EXTERNALSYNC",
  RESET_STATE: "RESET_STATE_EXTERNALSYNC",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_EXTERNALSYNC",
  REMOVE_OBJECT: "REMOVE_OBJECT_EXTERNALSYNC",
  CLEAR_FETCHED: "CLEAR_FETCHED_EXTERNALSYNC",

  LIST_URL: "/external/base/externalsyncerror/list/",
  POST_URL: "/external/base/externalsyncerror/",
  PATCH_URL: "/external/base/externalsyncerror/",
  GET_URL: "/external/base/externalsyncerror/",

  STORE_NAME: "externalSyncError",
};
