export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGINVOICING",
  DESTROY_FORM: "DESTROY_FORM_PARKINGINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGINVOICING",
  RESET_STATE: "RESET_STATE_PARKINGINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PARKINGINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PARKINGINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGINVOICING",
  SET_PERIODS_FOR_CONTRACT: "SET_PERIODS_FOR_CONTRACT_PARKINGINVOICING",
  CLEAR_PERIODS_FOR_CONTRACT: "CLEAR_PERIODS_FOR_CONTRACT_PARKINGINVOICING",

  LIST_URL: "/accounting/contract/parkinginvoicing/list/",
  POST_URL: "/accounting/contract/parkinginvoicing/",
  PATCH_URL: "/accounting/contract/parkinginvoicing/",
  GET_URL: "/accounting/contract/parkinginvoicing/",
  MASS_INVOICE_STATUS_UPDATE_URL:
    "/accounting/contract/masspause_parkinginvoicing/",
  PREVIEW_URL: "/accounting/contract/preview_parkinginvoicing/",
  GENERATE_URL: "/accounting/contract/generatenow_parkinginvoicing/",
  PERIOD_URL: "/accounting/contract/periodinfo_parkinginvoicing/",

  STORE_NAME: "invoicingParking",
};
