
import { getWriteOffQuota, summarizeCostRows } from "./errandUtils";
import { stripHtml } from "./stringUtils";
import {decidedExport} from "./decidedExport";
import moment from "moment";

export default ({errand, reportedBy, performedBy, setting, authedUser}) => {
  
    const url = "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/public_media/Other/Kostnadsunderlag+(Felanm%C3%A4lan-formul%C3%A4r).pdf";
    
    const getTitle = () => {
        return `${errand.title}${errand?.errand_id ? ` (${errand.errand_id})` : ""} - Kostnadsunderlag`
    }
    const getKeyWords = () => {
        return ["Kostnadsunderlag"];
    }

    const prepareCostRows = (rows) => {
        if (rows.length <= 8) { return rows }

        [].sort()

        // join the last rows, sort to push all other's liable first
        const sliced = rows.sort((a, b) => b?.liable - a?.liable).slice(8, rows.length);
        const onlyLiable = undefined;
        sliced.forEach((s) => {
            // mixed
            if (onlyLiable === -1) { return; }

            // initial iteration
            if (onlyLiable === undefined) { onlyLiable = s.liable; return; }

            // no match => mixed
            if (onlyLiable !== s.liable) { onlyLiable = -1; return }
        })

        const {net, vat} = summarizeCostRows(sliced, errand?.component);
        const vatDecimal = net ? vat/net : 0;
        const newRow = {
            title:"Övrigt",
            unit_amount:1,
            unit_cost:net,
            vat:vatDecimal,
            liable:onlyLiable === -1 || onlyLiable === undefined ? 2 : onlyLiable 
        }
        return [...rows.slice(0, 8), newRow]
    }

    const handleCostFields = (form, _) => {
        const rows = prepareCostRows(errand?.costs || []);
        rows.forEach((r, index) => {
            // description
            try {
                const component = form.getTextField(`costs.title${index}`);
                component.setText(r?.title || "");
                component.enableReadOnly();
            } catch { return; }

            const amount = r?.unit_amount || 0;
            const cost = r?.unit_cost || 0;
            const vat = r?.vat || 0;

            // amount
            try {
                const component = form.getTextField(`costs.unit_amount${index}`);
                component.setText(amount.toString());
                component.enableReadOnly();
            } catch {}

            // price
            try {
                const component = form.getTextField(`costs.unit_cost${index}`);
                component.setText(cost.toString());
                component.enableReadOnly();
            } catch {}

            // vat
            try {
                const component = form.getTextField(`costs.vat${index}`);
                component.setText(vat.toString());
                component.enableReadOnly();
            } catch {}

            // amount
            try {
                const component = form.getTextField(`costs.total_value${index}`);
                component.setText((cost*amount*(1+(vat/100))).toString());
                component.enableReadOnly();
            } catch {}

            // liable
            try {
                const component = form.getTextField(`costs.liable${index}`);
                component.setText(r?.liable_display || "");
                component.enableReadOnly();
            } catch {}
        });

        const {net, vat} = summarizeCostRows(rows, errand?.component);
        const total = net + vat;

        // pre vat
        try {
            const component = form.getTextField(`costs.value`);
            component.setText(net.toString());
            component.enableReadOnly();
        } catch {}

        // pure vat
        try {
            const component = form.getTextField(`costs.total_vat`);
            component.setText(vat.toString());
            component.enableReadOnly();
        } catch {}

        // total value
        try {
            const component = form.getTextField(`costs.total`);
            component.setText(total.toString());
            component.enableReadOnly();
        } catch {}
    }

    const handleArticleFields = (form, _) => {
        const rows = prepareCostRows(errand?.costs || []).filter((c) => Boolean(c.article));
        rows.forEach((r, index) => {
            const article = r.article;

            try {
                const component = form.getTextField(`article_title${index}`);
                component.setText(article?.title || "");
                component.enableReadOnly();
            } catch { return; }

            try {
                const component = form.getTextField(`article_unit_amount${index}`);
                component.setText(article?.unit_display?.toString() || "");
                component.enableReadOnly();
            } catch {}

            try {
                const component = form.getTextField(`article_unit_cost${index}`);
                component.setText(article?.material_cost?.toString() || "");
                component.enableReadOnly();
            } catch {}

            const duration = moment.duration(article?.labor_time);
            if (moment.isDuration(duration)) { 
                const hours = duration.hours();
                const minutes = duration.minutes() / 60;
                try {
                    const component = form.getTextField(`deb_time${index}`);
                    component.setText((hours + minutes).toString());
                    component.enableReadOnly();
                } catch {}
             }


            try {
                const component = form.getTextField(`deb_cost${index}`);
                component.setText(article?.labor_time_cost?.toString() || "");
                component.enableReadOnly();
            } catch {}

            const { net, vat } = summarizeCostRows([r], errand?.component);

            try {
                const component = form.getTextField(`article_total_cost${index}`);
                component.setText((net + vat).toString());
                component.enableReadOnly();
            } catch {}

            try {
                const component = form.getTextField(`write_off${index}`);
                component.setText(article?.write_off_years?.toString() || "");
                component.enableReadOnly();
            } catch {}

            // let baseAmount = `${r?.unit_amount || 0}*${article?.material_cost || 0} [antal enheter*material kostnad]`;
            
            // const forLandlord = ![0, 2].includes(r?.liable)
            // const quota = getWriteOffQuota(errand?.component, article?.code, forLandlord);
            // baseAmount += `\n*${quota} [*avskrivningstid, ${forLandlord ? "hyresvärd" : "hyresgäst"}]`
            // baseAmount += `\n*${minutes+hours}*${article?.labor_time_cost || 0} [arbetade timmar*timkostnad]`

            // try {
            //     const component = form.getTextField(`article_cost_sum${index}`);
            //     component.setText(baseAmount);
            //     component.enableReadOnly();
            // } catch { return; }
        })
    }

    const fieldMapping = {
        title: { getValue:() => errand.title },
        errand_number: { getValue:() => errand?.errand_id },
        address: { getValue:() => errand?.address?.str_representation },
        created_at: { getValue:() => {
            const date = moment(errand?.created_at);
            if (date.isValid()) { return date.format("dddd, MMMM Do YYYY, HH:mm:ss") }
            return "";
        }},
        "reported_by.full_name": { getValue:() => reportedBy?.str_representation },
        "reported_by.email": { getValue:() => reportedBy?.email },
        "reported_by.phone": { getValue:() => reportedBy?.phone },
        status_display: { getValue:() => errand?.status_display },
        execute_end: { getValue:() => {
            const date = moment(errand?.execute_end);
            if (date.isValid()) { return date.format("dddd, MMMM Do YYYY, HH:mm:ss") }
            return "";
        } },
        "performer.full_name": { getValue:() => performedBy?.str_representation },
        "performer.email": { getValue:() => performedBy?.email },
        "performer.phone": { getValue:() => performedBy?.phone },
        "performer.corporate_name": { getValue:() => performedBy?.corporate_name },
        "auth.full_name": { getValue:() => authedUser?.str_representation },
        "auth.email": { getValue:() => authedUser?.email },
        "auth.phone": { getValue:() => authedUser?.phone },
        "auth.corporate_name": { getValue:() => authedUser?.corporate_name },
        "costs": { getValue:() => {}, handleValue:handleCostFields },
        fault_type: { getValue:() => setting?.str_representation },
        note: { getValue:() => stripHtml(errand?.note) },
        completion_note: { getValue:() => {
            return stripHtml(errand?.errand_rating?.note);
        }, handleValue: (form, value) => {
            try {
                const component = form.getTextField("completion_note");
                component.setText(value);
                component.enableReadOnly();
                component.enableMultiline()
              } catch { return; }
        }},
        "article": {getValue: () => {}, handleValue: handleArticleFields}
    }

    return decidedExport({getTitle, getKeyWords, fieldMapping, url})
};
