import * as React from "react";

export default () => {
  return (
    <div>
      Lägg till tilläggskostnader och rabatter som ska aviseras för på
      bostadsrätten.
      <br />
      <br />
      Exempel på tillägg kan vara:
      <br /> - Bredband/telefoni
      <br /> - Schablonbelopp för el/värme/vatten
      <br />
      <br />
      <strong>
        OBS: Här läggs enbart tilläggskostnader till. Föreningsavgiften för
        bostadsrätten kommer alltid med på avier.
      </strong>
    </div>
  );
};
