export default [
  {
    title: "Dokument",
    key: "DOCUMENT",
    visited: true,
    hasError: false,
    fieldKeys: ["title", "category", "doc", "docData_"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
