import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { update, useServiceContract } from "../../../../store/serviceContracts";
import { DateCell } from "../../../Displays";
import { PrimaryButton, TextButton } from "../../../Forms/Base/Buttons";
import UpdateServiceContractCancellationForm from "../../../Forms/ServiceContract/EditCancelForm/ModalForm";
import { StatusLabel } from "../../../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import { DetailPageBox } from "../../../sharedStyles";
import DetailInfo from "../../OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../OverviewInfo/styles";

export default function ServiceContractManualCancellation() {
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [confirmRegretOpen, setConfirmRegretOpen] = React.useState(false);
  const { serviceContractId } = useParams();
  const [serviceContract] = useServiceContract(serviceContractId);

  const cancelledByTenantNotConfirmed =
    serviceContract?.landlord_cancelled === false &&
    !serviceContract?.closed_confirmed;
  const cancelCancellation = () => {
    setLoading(true);
    const data = {
      closed_date: null,
      closed_renew_date: null,
      closed_on: null,
      status: 0,
      closed_cause: null,
      closed_signed: false,
      closed_confirmed: false,
      landlord_cancelled: null,
      cancelled_doc: null,
    };

    dispatch(
      update({
        id: serviceContractId,
        forceData: data,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  const handleConfirmCancelled = () => {
    setLoading(true);

    const data = {
      closed_confirmed: true,
    };

    dispatch(
      update({
        id: serviceContractId,
        forceData: data,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  const infoObj = {
    "Detaljer kring uppsägning": [
      {
        title: "Typ av uppsägning",
        value: (
          <StatusLabel state={6}>
            {serviceContract?.status === 6
              ? "Uppsagt för avflyttning"
              : "Uppsagt för villkorsändring"}
          </StatusLabel>
        ),
      },
      {
        title: "Uppsagt av",
        value: serviceContract?.landlord_cancelled ? "Hyresvärd" : "Hyresgäst",
      },
      {
        title: "Datum för uppsägning",
        value: <DateCell date={serviceContract?.closed_on} />,
      },
      {
        title: "Datum för avflytt",
        value: <DateCell date={serviceContract?.closed_date} />,
        hidden: serviceContract?.state !== 6,
      },
      {
        title: "Datum för villkorsändring",
        value: <DateCell date={serviceContract?.closed_renew_date} />,
        hidden: serviceContract?.state !== 7,
      },
      {
        title: "Uppsägning bekräftad",
        value: (
          <StatusLabel state={serviceContract?.closed_confirmed ? 1 : 6}>
            {serviceContract?.closed_confirmed ? "Bekräftad" : "Ej bekräftad"}
          </StatusLabel>
        ),
      },
    ],
    "Anledning till uppsägning": [
      { title: serviceContract?.closed_cause || "Ingen anledning angiven" },
    ],
  };

  return (
    <>
      <UpdateServiceContractCancellationForm
        isOpen={editOpen}
        id={serviceContractId}
        instance={serviceContract}
        onCheckout={() => setEditOpen(false)}
      />

      <ConfirmationModal
        isOpen={confirmRegretOpen}
        closeFunction={() => setConfirmRegretOpen(false)}
        title="Är du säker på att du vill ångra uppsägningen?"
        renderContent={() => (
          <div>
            Detta innebär att avtalet återställs till den statusen det skulle
            haft om uppsägningen inte inträffat. Observera att all data
            förknippad med uppsägningen kommer att raderas, t.ex. "Anledning
            till uppsägning".
          </div>
        )}
        acceptTitle="Ja, ångra uppsägning"
        acceptCallback={cancelCancellation}
      />

      <DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Avtalet är uppsagt</OverviewTitle>
            <OverviewSubtitle>
              <TextButton
                title="Redigera uppsägning"
                clicked={() => setEditOpen(true)}
                iconType="edit"
                iconPlacement="right"
              />
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          {cancelledByTenantNotConfirmed && (
            <PrimaryButton
              title="Bekräfta uppsägning"
              clicked={handleConfirmCancelled}
            />
          )}
        </OverviewTitleWrapper>

        <DetailInfo infoObj={infoObj} />

        <TextButton
          red
          title="Ångra uppsägning"
          clicked={() => setConfirmRegretOpen(true)}
          iconType="close"
          iconPlacement="right"
        />
      </DetailPageBox>
    </>
  );
}
