import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useProductType } from "../../store/productTypes";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ProductTypeDetailOverview from "./productTypeDetail/Overview";

export default function ProductTypeDetail() {
  const { path, url } = useRouteMatch();
  const { id } = useParams();

  const [productType] = useProductType(id);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ProductTypeDetailOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title={`Komponentmodell${
          productType?.str_representation
            ? ` - ${productType.str_representation}`
            : ""
        }`}
        breadCrumbs={[
          {
            label: "Inställningar Teknisk Förvaltning",
            url: "/errand-settings/product-types",
          },
          {
            label: productType?.str_representation || "Komponentmodell",
          },
        ]}
        eventContentType="errands.producttype"
        eventObjectId={id}
        eventIdQueryStr="producttype"
        {...{ subPages: subPageLinks }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}
