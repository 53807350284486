export default [
  {
    title: "Styrelse",
    key: "BOARD",
    visited: true,
    hasError: false,
    fieldKeys: ["participators"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["name", "brf_company", "category"],
  },
];
