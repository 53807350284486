import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { logout } from "../../../../store/app/actions";

import { TextButton } from "../../../Forms/Base/Buttons";
import * as SC from "../styles";

import userIcon from "../../../../assets/svg/material/user_black.svg";
import userIconActive from "../../../../assets/svg/material/user_blue.svg";

import groupIcon from "../../../../assets/svg/material/group_black.svg";
import groupIconActive from "../../../../assets/svg/material/group_blue.svg";

import peopleIcon from "../../../../assets/svg/material/people_black.svg";
import peopleIconActive from "../../../../assets/svg/material/people_blue.svg";

import accountBalanceIcon from "../../../../assets/svg/material/account_balance_black.svg";
import accountBalanceIconActive from "../../../../assets/svg/material/account_balance_blue.svg";

import labelIcon from "../../../../assets/svg/material/label_black.svg";
import labelIconActive from "../../../../assets/svg/material/label_blue.svg";

import devIcon from "../../../../assets/svg/material/integration_instructions_black.svg";
import devIconActive from "../../../../assets/svg/material/integration_instructions_blue.svg";

import automationIcon from "../../../../assets/svg/material/route_black.svg";
import automationIconActive from "../../../../assets/svg/material/route_blue.svg";

import settingsIcon from "../../../../assets/svg/material/settings_black.svg";
import settingsIconActive from "../../../../assets/svg/material/settings_blue.svg";

import stackedLinesIcon from "../../../../assets/svg/material/stacked_lines_black.svg";
import stackedLinesIconActive from "../../../../assets/svg/material/stacked_lines_blue.svg";

import doorIcon from "../../../../assets/svg/material/meeting_room_red.svg";

import { useAllPermissionCheck } from "../../../../store/base";

import MiniLogo from "../../Sidebar/MiniLogo";

export default ({ getActivePage }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleLogout = () => {
    dispatch(logout());
  };

  const hasTenantPortalPermission = useAllPermissionCheck([
    "allow_tenantportal",
    "view_can_tenantportal",
  ]);

  const hasUserAutomationPermission = useAllPermissionCheck([
    "allow_userautomation",
    "view_can_userautomation",
  ]);

  return (
    <SC.ConfigCenterSidebarWrapper>
      <div style={{ display: "flex", alignSelf: "center" }}>
        <MiniLogo overrideWidth={150} />
      </div>
      <TextButton
        title="Tillbaka"
        iconType="arrow-back"
        clicked={() => push("/")}
        extraStyle={{
          color: "#5165FB",
          marginBottom: "8px",
          margin: "20px 20px 10px 20px",
        }}
      />
      <SC.ConfigCenterSideBarGroup
        to="/configcenter/profile/user"
        active={getActivePage("/configcenter/profile", false)}
        icon={
          getActivePage("/configcenter/profile", false)
            ? userIconActive
            : userIcon
        }
      >
        Min Profil
      </SC.ConfigCenterSideBarGroup>

      <SC.ConfigCenterSideBarGroup
        to="/configcenter/groups-and-users/user"
        active={getActivePage("/configcenter/groups-and-users", true)}
        icon={
          getActivePage("/configcenter/groups-and-users", true)
            ? groupIconActive
            : groupIcon
        }
      >
        Användare & behörigheter
      </SC.ConfigCenterSideBarGroup>
      {hasTenantPortalPermission && (
        <SC.ConfigCenterSideBarGroup
          to="/configcenter/tenant-portal"
          active={getActivePage("/configcenter/tenant-portal", true)}
          icon={
            getActivePage("/configcenter/tenant-portal", true)
              ? peopleIconActive
              : peopleIcon
          }
        >
          Mina Sidor
        </SC.ConfigCenterSideBarGroup>
      )}
      <SC.ConfigCenterSideBarGroup
        to="/configcenter/rental-notifications/invoicing-setting"
        active={getActivePage("/configcenter/rental-notifications", true)}
        icon={
          getActivePage("/configcenter/rental-notifications", true)
            ? accountBalanceIconActive
            : accountBalanceIcon
        }
      >
        Avisering
      </SC.ConfigCenterSideBarGroup>

      <SC.ConfigCenterSideBarGroup
        to="/configcenter/indexations/settings"
        active={getActivePage("/configcenter/indexations", true)}
        icon={
          getActivePage("/configcenter/indexations", true)
            ? stackedLinesIconActive
            : stackedLinesIcon
        }
      >
        Indexuppräkning
      </SC.ConfigCenterSideBarGroup>

      <SC.ConfigCenterSideBarGroup
        to="/configcenter/categories"
        active={getActivePage("/configcenter/categories", true)}
        icon={
          getActivePage("/configcenter/categories", true)
            ? labelIconActive
            : labelIcon
        }
      >
        Kategorisering
      </SC.ConfigCenterSideBarGroup>
      <SC.ConfigCenterSideBarGroup
        to="/configcenter/integrations"
        active={getActivePage("/configcenter/integrations", true)}
        icon={
          getActivePage("/configcenter/integrations", true)
            ? devIconActive
            : devIcon
        }
      >
        Integrationer
      </SC.ConfigCenterSideBarGroup>
      {hasUserAutomationPermission && (
        <SC.ConfigCenterSideBarGroup
          to="/configcenter/userautomation"
          active={getActivePage("/configcenter/userautomation", true)}
          icon={
            getActivePage("/configcenter/userautomation", true)
              ? automationIconActive
              : automationIcon
          }
        >
          Automatisering
        </SC.ConfigCenterSideBarGroup>
      )}
      <SC.ConfigCenterSideBarGroup
        to="/configcenter/general"
        active={getActivePage("/configcenter/general", true)}
        icon={
          getActivePage("/configcenter/general", true)
            ? settingsIconActive
            : settingsIcon
        }
      >
        Övrigt
      </SC.ConfigCenterSideBarGroup>
      <SC.ConfigCenterSideBarGroup
        style={{ color: "#ea5050" }}
        icon={doorIcon}
        onClick={handleLogout}
      >
        Logga ut
      </SC.ConfigCenterSideBarGroup>
    </SC.ConfigCenterSidebarWrapper>
  );
};
