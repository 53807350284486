export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_REPORT_RATING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_REPORT_RATING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_REPORT_RATING",
  DESTROY_FORM: "DESTROY_FORM_REPORT_RATING",
  SET_FORM_ERROR: "SET_FORM_ERROR_REPORT_RATING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_REPORT_RATING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_REPORT_RATING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_REPORT_RATING",
  RESET_STATE: "RESET_STATE_REPORT_RATING",
  CLEAR_FETCHED: "CLEAR_FETCHED_REPORT_RATING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_REPORT_RATING",
  
  LIST_URL: "/errands/component_ratings/",
  POST_URL: "/errands/component_rating/",
  PATCH_URL: "/errands/component_rating/",
  GET_URL: "/errands/component_rating/",

  STORE_NAME: "errandRatings",
};
