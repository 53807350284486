import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { TableLink } from "../Forms/Base/Buttons";

export default ({
  obj,
  urlMethod,
  forceId,
  extraUrlMethodArgs = {},
  openInNewTab,
  small,
}) => {
  const { push } = useHistory();

  const onClick = (e) => {
    e?.stopPropagation && e.stopPropagation();

    if (openInNewTab) {
      window
        .open(
          urlMethod({
            id: forceId || fetchedObj.id,
            ...extraUrlMethodArgs,
          }),
          "blank"
        )
        .focus();
    } else {
      push(
        urlMethod({
          id: forceId || fetchedObj.id,
          ...extraUrlMethodArgs,
        })
      );
    }
  };

  if (!obj && !obj?.id) {
    return <span className={"eui-textTruncate"}>-</span>;
  }

  let [fetchedObj] = typeof obj === "function" ? obj() : [obj];

  if (!fetchedObj) {
    return <span className={"eui-textTruncate"}>-</span>;
  }

  return (
    <TableLink
      {...{ small }}
      clicked={onClick}
      title={fetchedObj.str_representation || "-"}
    />
  );
};
