import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";

import { overiewUrl } from "../../../../store/errandRoles";

import {
  InputFieldDescription,
  InputFieldTitle,
} from "../../Base/Fields/styles";
import * as SC from "../../ReportErrandSetting/ClassificationList/styles";
import { ArrowDownIcon, ArrowUpIcon } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../Base/Buttons";
import { useHistory } from "react-router";
import ConfirmModal from "../../Base/Modals/ConfirmModal";
import TableSelectField from "../../Base/Fields/TableSelectField";
import ErrandRoleTable from "src/components/Tables/ErrandRoles/FullTable";

export default ({ storeName, method, goToRoleButton }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [goToRolesOpen, setGoToRolesOpen] = React.useState(false);

  const performers = useFormInstanceField({
    storeName,
    fieldKey: "performers",
  });

  const addRoleRow = () => {
    const perfomersClone = performers ? cloneDeep(performers) : [];

    perfomersClone.push({
      title: "",
      order: perfomersClone.length,
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { performers: perfomersClone },
      })
    );
  };

  const onRoleSelected = (data, index) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`performers[${index}].title`]: data.title,
        },
      })
    );
  };

  const handleChangeOrder = (order, direction) => {
    const performersClone = cloneDeep(performers);
    const first = performersClone.find((p) => p.order === order);
    let second;
    if (direction === "up") {
      second = performersClone.find((p) => p.order === first.order - 1);
      first.order = first.order - 1;
      second.order = second.order + 1;
    } else if (direction === "down") {
      second = performersClone.find((p) => p.order === first.order + 1);
      second.order = second.order - 1;
      first.order = first.order + 1;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          performers: performersClone.sort((a, b) => a.order - b.order),
        },
      })
    );
  };

  const removeFromList = (index) => {
    const copy = cloneDeep(performers);
    copy.splice(index, 1);
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          performers: copy,
        },
      })
    );
  };

  const getReadableIndex = (index) => {
    const strIndex = (index + 1).toString();
    const lastChar = strIndex.substr(strIndex.length - 1);

    if (lastChar === "1" || lastChar === "2") {
      return `${strIndex}:a`;
    } else {
      return `${strIndex}:e`;
    }
  };

  return (
    <div style={{ maxWidth: "600px", width: "100%" }}>
      {goToRolesOpen && (
        <ConfirmModal
          onAccept={() => push(overiewUrl())}
          closeFunction={() => setGoToRolesOpen(false)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">OBS</h3>
          <p>
            Om du går till rollöversikten så kommer den ifyllda datan i
            formuläret inte att sparas. Du kan även kolla på befintliga roller i
            fliken Roller under Teknisk Förvaltning i sidomenyn. Skulle du vilja
            byta vald roll efter skapande av felanmälningstyp går det att göra
            genom att redigera typen.
          </p>
        </ConfirmModal>
      )}

      <InputFieldTitle style={{ marginBottom: "6px" }}>
        Lägg till utförare i prioritetsordning
      </InputFieldTitle>

      <InputFieldDescription>
        Utförarna kommer i tur och ordning att bli tillfrågade att avhjälpa
        ärendet
      </InputFieldDescription>

      <SC.ListWrapper>
        <SC.ListHeader>
          <SC.ComponentName>Roll</SC.ComponentName>

          <SC.ComponentArea>Prioritet</SC.ComponentArea>

          <SC.HeaderSpacer />
        </SC.ListHeader>

        <SC.ListContent>
          {performers?.map((performer, index) => {
            return (
              <SC.ListItem key={performer.order}>
                <SC.ComponentName>
                  {!performer.role ? (
                    <TableSelectField
                      placeholder="Lägg till roll"
                      storeName={storeName}
                      method={method}
                      fieldKey={`performers[${index}].role`}
                      instructionsKey={"performers.role"}
                      TableComponent={ErrandRoleTable}
                      changeCallback={(data) => onRoleSelected(data, index)}
                    />
                  ) : (
                    performer?.title || performer?.str_representation
                  )}
                </SC.ComponentName>
                <SC.ComponentArea
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ marginRight: "4px" }}>
                    {getReadableIndex(performer.order)}
                  </div>
                  {performers.length > 1 && (
                    <>
                      {index !== 0 && (
                        <ArrowUpIcon
                          onClick={() =>
                            handleChangeOrder(performer.order, "up")
                          }
                        />
                      )}{" "}
                      {index !== performers.length - 1 && (
                        <ArrowDownIcon
                          onClick={() =>
                            handleChangeOrder(performer.order, "down")
                          }
                        />
                      )}
                    </>
                  )}
                </SC.ComponentArea>
                <SC.RemoveButton onClick={() => removeFromList(index)} />
              </SC.ListItem>
            );
          })}
        </SC.ListContent>
        <SC.ListBottom>
          {goToRoleButton && (
            <SecondaryButton
              extraStyle={{ marginLeft: "12px" }}
              title="Gå till rollöversikt"
              clicked={() => setGoToRolesOpen(true)}
            />
          )}
          <PrimaryButton
            extraStyle={{ marginLeft: "12px" }}
            newDesign
            title="Lägg till rad"
            clicked={addRoleRow}
          />
        </SC.ListBottom>
      </SC.ListWrapper>
    </div>
  );
};
