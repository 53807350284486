import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

// style, design

import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/userGroups";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  overrideRowClick,
}) => {
  const { push } = useHistory();

  const columns = React.useMemo(() => columnDefs(), []);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "user_groups_full_table"}
      title={"Grupper"}
      {...{
        isBare,
        ignoreLocalStorage,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
      onRowClicked={(row) => {
        if (onRowClicked) {
          onRowClicked(row);
          return;
        }

        if (overrideRowClick) {
          overrideRowClick(row);
          return;
        }
        push(`/configcenter/groups-and-users/group/${row.id}`);
      }}
    />
  );
};
