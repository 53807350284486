import yourBlockLogo from "../../assets/img/yourblock.svg";
import bokameraLogo from "../../assets/img/bokamera.svg";
import outlookLogo from "../../assets/svg/outlook-icon-blue.svg";
import infometricLogo from "../../assets/svg/infometric_logo.svg";
import freshChatLogo from "../../assets/img/freshchat.png";
import infotraderLogo from "../../assets/img/infotrader.png";
import unit4Logo from "../../assets/img/unit4.svg";
import bankgiroLogo from "../../assets/img/bankgirot-logo.png";
import kivraLogo from "../../assets/img/kivra.svg";
import fortnoxLogo from "../../assets/img/fortnox-logo.svg";
import vismaLogo from "../../assets/img/visma-logo.jpg";
import scriveLogo from "../../assets/img/scrive_logo.svg";
import fastdocLogo from "../../assets/svg/fastdoc.svg";
import homeQLogo from "../../assets/img/homeq.png";
import elvacoLogo from "../../assets/img/elvaco-logo.jpeg";
import twilioLogo from "../../assets/svg/twilio-logo.svg";
import sbfLogo from "../../assets/svg/sbf_logo.svg";
import intercomLogo from "../../assets/svg/intercom-logo.svg";
import avyTmplLogo from "../../assets/img/logotyp-avy-tmpl.png";
import parakeyLogo from "../../assets/img/parakey_logo.svg";
import sftpLogo from "../../assets/svg/sftp-icon.svg";
import xledgerLogo from "../../assets/img/xledger.png";

export default [
  {
    title: "Parakey",
    subtitle: "Digitala Nycklar",
    image: parakeyLogo,
    description: "Parakey tillhandahåller digitala nycklar",
    path: "/configcenter/integrations/parakey",
    visitUrl: "https://www.parakey.co/",
    categories: [],
  },
  {
    title: "Scrive",
    subtitle: "Digital signering",
    image: scriveLogo,
    description:
      "Integrationen mot Scrive tillåter Pigello-användare att skicka ut dokument och avtal för digital signering direkt via Pigello.",
    path: "/configcenter/integrations/scrive",
    visitUrl: "https://www.scrive.com/sv",
    categories: [],
  },
  {
    title: "Avy-Tmpl",
    subtitle: "Boendeapp",
    image: avyTmplLogo,
    description:
      "Med denna integration kan era hyresgäster se avier, skapa felanmälan i Pigello m.m genom Avy-Tmpl Boendeapp.",
    path: "/configcenter/integrations/avy-tmpl",
    visitUrl: "https://www.avy.se/produkt/#boendeapp",
    categories: [],
  },
  {
    title: "HomeQ",
    subtitle: "Annonsering av lägenheter",
    image: homeQLogo,
    description:
      "Med integrationen mot HomeQ kan hela annonser sättas upp i HomeQ via Pigello Marknad. När nya hyresgäster har matchats i HomeQ sköts avtalsflödet i Pigello.",
    path: "/configcenter/integrations/homeq",
    visitUrl: "https://product.homeq.se/",
    categories: [],
  },

  {
    title: "Bostadsförmedlingen Stockholm",
    subtitle: "Stockholms stads bostadskö",
    image: sbfLogo,
    description:
      "Med integrationen mot Bostadsförmedlningen Stockholm kan annonser från Pigello publiceras där via Pigello Marknad, från skapande av annons till signering av hyresavtal.",
    path: "/configcenter/integrations/bostadsformedlingen-stockholm",
    visitUrl: "https://bostad.stockholm.se/",
    categories: [],
  },
  {
    title: "Fastighetsägarna Dokument",
    subtitle: "Mallar för avtal",
    image: fastdocLogo,
    description:
      "Med integrationen mot Fastighetsägarna så hämtas enkelt mallar för hundratals olika avtal och dokument in direkt i Pigellos avtalsmodul",
    path: "/configcenter/integrations/fastdok",
    visitUrl: "https://www.fastighetsagarna.se/tjanster/dokument/",
    categories: [],
  },
  {
    title: "Bankgirots Autogiro",
    subtitle: "Bankkoppling",
    image: bankgiroLogo,
    description:
      "Med Autogiro kopplat mot Pigellos hyresaviseringsmodul kan fakturor automatiskt debiteras från mottagarens bankkonto.",
    path: "/configcenter/integrations/autogiro",
    visitUrl: "https://www.bankgirot.se/autogiro",
    categories: ["company", "brf"],
  },
  {
    title: "Kivra",
    subtitle: "Digital brevlåda",
    image: kivraLogo,
    description:
      "Integrationen Kivra tillåter Pigellos hyresaviseringsmodul att skicka fakturor direkt till Kivra",
    path: "/configcenter/integrations/kivra",
    visitUrl: "https://kivra.se/sv/foretag",
    categories: ["company", "brf"],
  },
  {
    title: "Fortnox bokföring",
    subtitle: "Bokföringssystem",
    image: fortnoxLogo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos Hyresaviseringsmodul med integrationen mot Fortnox.",
    path: "/configcenter/integrations/fortnox-accounting",
    visitUrl: "https://www.fortnox.se/",
    categories: ["company", "brf"],
  },
  {
    title: "Visma eEkonomi",
    subtitle: "Bokföringssystem",
    image: vismaLogo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos Hyresaviseringsmodul med integrationen mot Visma eEkonomi.",
    path: "/configcenter/integrations/visma-accounting",
    visitUrl: "https://vismaspcs.se/produkter/bokforingsprogram/visma-eekonomi",
    categories: ["company", "brf"],
  },
  {
    title: "Unit4 ERP",
    subtitle: "Bokföringssystem",
    image: unit4Logo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos Hyresaviseringsmodul med integrationen mot Unit4 ERP.",
    path: "/configcenter/integrations/unit-4-erp",
    visitUrl: "https://www.unit4.com/sv",
    categories: ["company", "brf"],
  },
  {
    title: "Xledger",
    subtitle: "Bokföringssystem",
    image: xledgerLogo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos Hyresaviseringsmodul med integrationen mot Xledger.",
    path: "/configcenter/integrations/xledger",
    visitUrl: "https://xledger.com/se/erp-losning/",
    categories: ["company", "brf"],
  },
  {
    title: "Infometric",
    subtitle: "Leverantör av mätdata",
    image: infometricLogo,
    description:
      "Med hjälp av integrationen mot Infometric kan mätdata från El, värme, vatten osv. automatiskt hämtas in i Pigellos IMD-modul.",
    path: "/configcenter/integrations/infometric",
    visitUrl: "https://infometric.se/",
    categories: [],
  },
  {
    title: "Elvaco",
    subtitle: "Leverantör av mätdata",
    image: elvacoLogo,
    description:
      "Med hjälp av integrationen mot Elvaco kan mätdata från El, värme, vatten osv. automatiskt hämtas in i Pigellos IMD-modul.",
    path: "/configcenter/integrations/elvaco",
    visitUrl: "https://www.elvaco.se/",
    categories: [],
  },
  {
    title: "YourBlock",
    subtitle: "Bostadsportal",
    image: yourBlockLogo,
    description:
      "Your Block är en mobil plattform som förenklar kommunikation och samarbete i företag och föreningsliv. Ett mobilt intranät 2.0 för företag, bostads- och villaägarföreningar och övrigt föreningsliv.",
    path: "/configcenter/integrations/yourblock",
    visitUrl: "https://yourblock.com/",
    categories: [],
  },
  {
    title: "Outlook",
    subtitle: "Microsofts tjänst för E-post",
    image: outlookLogo,
    description: `Integrationen mot Outlook tillåter användare att se sina kalenderhändelser från Outlook direkt i Pigellos "Min arbetsdag" och kalendern.`,
    path: "/configcenter/integrations/outlook",
    visitUrl: "https://outlook.live.com/owa/",
    categories: [],
  },
  {
    title: "BokaMera",
    subtitle: "Verktyg för bokningar",
    image: bokameraLogo,
    description: `Integrationen mellan BokaMera och Pigello gör det möjligt att boka in avhjälpande av garantifel och felanmälningar kopplat till den tekniska förvaltningen via BokaMera i Pigello.`,
    path: "/configcenter/integrations/bokamera",
    visitUrl: "https://bokamera.se/for-developers",
    categories: [],
  },
  {
    title: "Twilio SMS",
    subtitle: "SMS-notifikationer",
    image: twilioLogo,
    description: `Twilio SMS-integrationen låser upp möjligheten att skicka SMS-notiser till hyresgäster.`,
    path: "/configcenter/integrations/twilio-sms",
    visitUrl: "https://www.twilio.com/",
    categories: [],
  },
  {
    title: "Intercom",
    subtitle: "Supportverktyg",
    image: intercomLogo,
    description: `Intercoms verktyg för chattsupport kan användas i Pigellos "Mina sidor" för att enkelt erbjuda chattsupport till boende.`,
    path: "/configcenter/integrations/intercom",
    visitUrl: "https://www.intercom.com/",
    categories: [],
  },
  {
    title: "Freshchat",
    subtitle: "Supportverktyg",
    image: freshChatLogo,
    description: `FreshChats verktyg för chattsupport kan användas i Pigellos "Mina sidor" för att enkelt erbjuda chattsupport till boende.`,
    path: "/configcenter/integrations/freshchat",
    visitUrl: "https://www.freshworks.com/se/live-chat-software/",
    categories: [],
  },
  {
    title: "Infotrader",
    subtitle: "Aggregator av data",
    image: infotraderLogo,
    description: `Med hjälp av Infotrader hämtas data om fastigheter automatiskt in vid skapande av en ny fastighet i Pigello`,
    alwaysOn: true,
    categories: [],
  },
  {
    title: "SFTP",
    subtitle: "Nattligt jobb för SFTP",
    image: sftpLogo,
    path: "/configcenter/integrations/sftp",
    description: `SFTP-jobb är till för att generera och skicka filer till externa system. Används oftast för att skicka över t.e.x bokföringsfiler automatiskt.`,
    categories: ["company", "brf"],
  },
];
