import * as React from "react";

import classNames from "classnames";

import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/outline";
import LocalSelectField from "../../../Forms/Base/Fields/LocalSelectField";
import { ExpandList } from "./ExpandList";
import LocalCheckField from "../../../Forms/Base/Fields/LocalCheckField";
import { cloneDeep } from "lodash";

export const Header = ({
  headerGroups,
  hideHeaders,
  onHeaderClick,
  orderBy,
  onRowSelected,
  selectedRows,
  setSelectedRows,
  results,
  getFullColumnData,
}) => {
  const isAllRowsToggled = React.useMemo(() => {
    if (!selectedRows) return false;

    let vals = Object.values(selectedRows);

    if (vals.length === 0) return false;

    let idsToCheck = results.map((obj) => obj.id);

    for (let id of idsToCheck) {
      if (!selectedRows[id]) {
        return false;
      }
    }

    return true;
  }, [selectedRows, results]);

  const toggleAllRows = (val) => {
    if (!results) return;

    let newSelected = cloneDeep(selectedRows);

    let newBool = isAllRowsToggled ? false : true;

    for (let data of results) {
      newSelected[data.id] = newBool;
    }

    setSelectedRows(newSelected);

    onRowSelected(
      Object.keys(newSelected).filter((id) => newSelected[id] === true)
    );
  };

  const renderOnAllRowSelect = () => {
    return (
      <th className="px-2 pt-4 pb-2 whitespace-nowrap flex items-end justify-center ">
        <div className="relative w-5 h-5">
          <div className="absolute top-1 left-[-4px]">
            <LocalCheckField
              large
              onChange={toggleAllRows}
              value={isAllRowsToggled}
            />
          </div>
        </div>
      </th>
    );
  };

  return (
    <thead
      className={classNames(
        "text-xs text-slate-700 uppercase bg-slate-100",
        hideHeaders && "hidden"
      )}
    >
      {headerGroups.map((headerGroup, index) => (
        <tr className="" {...headerGroup.getHeaderGroupProps()}>
          {onRowSelected && renderOnAllRowSelect()}
          {headerGroup.headers.map((column) => {
            return (
              <HeaderItem
                getFullColumnData={getFullColumnData}
                onHeaderClick={onHeaderClick}
                column={column}
                orderBy={orderBy}
                key={`${column.id || column.accessor}${index}`}
              />
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export const HeaderItem = ({
  column,
  onHeaderClick,
  orderBy,
  getFullColumnData,
}) => {
  let order;

  if (orderBy) {
    order = orderBy.includes(column.id) ? (
      <ChevronDoubleDownIcon width={16} />
    ) : orderBy.includes(`-${column.id}`) ? (
      <ChevronDoubleUpIcon width={16} />
    ) : undefined;
  }

  let colData = getFullColumnData(column.id || column.accessor);

  // if (column.id === "total_area") console.log("115", column);

  return (
    <th
      className={classNames(
        "px-2 py-4 whitespace-nowrap",
        !column.disableSortBy ? "cursor-pointer" : "cursor-text"
      )}
      {...column.getHeaderProps()}
      onClick={() => onHeaderClick(column)}
    >
      <div
        className={classNames(
          "flex items-center",
          colData?.alignRight ? "text-right" : "text-left"
        )}
      >
        {column.render("Header")}
        <>
          {order ? (
            <span className="ml-2 rounded p-0.5 shadow bg-white">{order}</span>
          ) : (
            <>
              {colData?.alignRight !== true && (
                <span className="ml-2 p-0.5 w-[20px] h-[20px]"></span>
              )}
            </>
          )}
        </>
      </div>
    </th>
  );
};

export const Body = ({
  props,
  prepareRow,
  page,
  rowClassName,
  children,
  onRowClicked,
  getContentForExpandedRow,
  expandedRows,
  tableWidth,
  checkRowHighlighted,
  onRowSelected,
  selectedRows,
  setSelectedRows,
  getFullColumnData,
}) => {
  const bodyRef = React.useRef();

  const selectedARow = (row, val) => {
    let clone = cloneDeep(selectedRows);
    if (!clone) clone = {};

    clone[row.original.id] = val;

    setSelectedRows(clone);

    onRowSelected(Object.keys(clone).filter((id) => clone[id] === true));
  };

  const renderRowCheckField = (row) => {
    return (
      <td
        onClick={(e) => e.stopPropagation()}
        className="p-2 whitespace-nowrap align-middle"
      >
        <div
          className="flex justify-center items-center"
          onClick={(evt) => evt.stopPropagation()}
        >
          <LocalCheckField
            large
            value={selectedRows?.[row.original.id]}
            onChange={(value) => selectedARow(row, value)}
          />
        </div>
      </td>
    );
  };

  const renderExpander = (row) => {
    let content = getContentForExpandedRow(row);
    return (
      <tr
        key={row.index + "expand"}
        className={classNames(
          "",
          checkRowHighlighted && checkRowHighlighted(row) && "bg-blue-100"
        )}
      >
        <td>
          <div
            className="absolute left-0 flex"
            //-2 for borders
            style={{ marginLeft: "1px", width: tableWidth - 2 + "px" }}
          >
            <ExpandList content={content} />
          </div>
          <div className="max-w-[1px] overflow-hidden opacity-0">
            <ExpandList content={content} hidden={true} />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <tbody {...props} ref={bodyRef}>
      {page.map((row, i) => {
        prepareRow(row);

        return (
          <>
            <tr
              onClick={() => onRowClicked(row.original, row)}
              className={classNames(
                "bg-white border-b even:bg-slate-50 hover:bg-blue-100 cursor-pointer",
                checkRowHighlighted &&
                  checkRowHighlighted(row.original, row) &&
                  "!bg-blue-100"
              )}
              {...row.getRowProps()}
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
              key={row.index}
            >
              {onRowSelected && renderRowCheckField(row)}
              {row.cells.map((cell, index) => {
                let colData = getFullColumnData(
                  cell.column.id || cell.column.accessor
                );

                return (
                  <td
                    className="p-2 whitespace-nowrap align-middle"
                    {...cell.getCellProps()}
                    onClick={(evt) => {
                      if (cell.column.id !== "__expander") return;
                      evt.stopPropagation();
                    }}
                    key={cell.column.id + index}
                  >
                    <div
                      className={classNames(
                        "flex items-center text-sm",
                        rowClassName,
                        colData?.alignRight
                          ? "text-right justify-end"
                          : "text-start"
                      )}
                    >
                      {cell.render("Cell")}
                    </div>
                  </td>
                );
              })}
            </tr>
            {expandedRows.includes(row.index) && renderExpander(row)}
          </>
        );
      })}
      {children}
    </tbody>
  );
};

export const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  return (
    <div className="flex pt-2 mb-24 space-x-1 justify-between w-[98%]">
      <div className="flex space-x-1">
        {canPreviousPage && (
          <button
            className="relative h-[42px] flex cursor-pointer items-center border border-solid border-gray-300 hover:bg-gray-200 text-black p-2.5 rounded transition-colors"
            onClick={() => previousPage()}
          >
            <ChevronLeftIcon width={16} />
            <p className="text-xs ml-2">Föregående sida</p>
          </button>
        )}
        {canNextPage && (
          <button
            className="relative flex cursor-pointer items-center border border-solid border-gray-300 hover:bg-gray-200 text-black p-2.5 rounded transition-colors"
            onClick={() => nextPage()}
          >
            <p className="text-xs mr-2">Nästa sida</p>
            <ChevronRightIcon width={16} />
          </button>
        )}
      </div>
      <div className="flex space-x-1">
        {(canNextPage || canPreviousPage) && pageCount !== 0 && (
          <div className="w-24">
            <LocalSelectField
              removePlaceholder={true}
              selectClassName={"cursor-pointer text-xs"}
              value={pageIndex + 1}
              choices={Array.from(new Array(pageCount || 1)).map(
                (obj, index) => {
                  return {
                    v: index + 1,
                    d: `Sida ${index + 1}`,
                  };
                }
              )}
              onChange={(val) => gotoPage(parseInt(val) - 1)}
            />
          </div>
        )}
        {pageCount !== 0 && (
          <div className="w-24">
            <LocalSelectField
              removePlaceholder={true}
              selectClassName={"cursor-pointer text-xs"}
              value={pageSize}
              choices={[5, 10, 25, 50, 100].map((num) => {
                return {
                  v: num,
                  d: `Visa ${num}`,
                };
              })}
              onChange={(val) => {
                gotoPage(0);
                setPageSize(parseInt(val));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
