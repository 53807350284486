import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import {
  useInvoicingRecordPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/invoicingRecords";
import { useHistory } from "react-router-dom";

export default function InvoicingRecordTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  withDetailUrl = false,
}) {
  const { push } = useHistory();

  const columns = React.useMemo(() => {
    const data = columnDefs(push);
    return data;
  }, [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "invoicing_record_full_table"}
      title={"Aviseringsloggar"}
      onRowClickedWithDetail={(obj) =>
        withDetailUrl ? detailUrl({ id: obj.id }) : {}
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
