import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import InvoicingErrorsTable from "../../../components/Tables/InvoicingErrors/FullTable";

import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import { buildQueryString } from "../../../store/base";
import {
  INVOICE_TYPES,
  invoicingToContractDetailUrl as leaseInvoicingToContractDetailUrl,
  previewAndGenerateUrl,
  useLeaseInvoicing,
} from "../../../store/invoicingLease";
import {
  invoicingToContractDetailUrl as otherInvoicingToContractDetailUrl,
  useOtherInvoicing,
} from "../../../store/invoicingOther";
import {
  invoicingToContractDetailUrl as parkingInvoicingToContractDetailUrl,
  useParkingInvoicing,
} from "../../../store/invoicingParking";
import {
  invoicingToContractDetailUrl as brfInvoicingToContractDetailUrl,
  useBrfInvoicing,
} from "../../../store/invoicingBrf";
import { useHistory, useParams } from "react-router-dom";
import { NotificationDot } from "../../../components/Dashboard/styles";
import { useFilteredInvoicingRecords } from "../../../store/invoicingRecords";
import { DateCell, LinkedObject } from "../../../components/Displays";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import theme from "../../../theme";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const getInvoiceTypeFromRecord = ({ record }) => {
  if (record?.lease_invoicing) return INVOICE_TYPES.LEASE;
  if (record?.other_invoicing) return INVOICE_TYPES.OTHER;
  if (record?.parking_invoicing) return INVOICE_TYPES.PARKING;
  if (record?.brf_invoicing) return INVOICE_TYPES.BRF;
};
const getInvoiceObjKeyFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return "lease_invoicing";
  if (type === INVOICE_TYPES.OTHER) return "other_invoicing";
  if (type === INVOICE_TYPES.PARKING) return "parking_invoicing";
  if (type === INVOICE_TYPES.BRF) return "brf_invoicing";
};
const getInvoiceObjFromRecord = ({ record }) => {
  if (record?.lease_invoicing) return record?.lease_invoicing;
  if (record?.other_invoicing) return record?.other_invoicing;
  if (record?.parking_invoicing) return record?.parking_invoicing;
  if (record?.brf_invoicing) return record?.brf_invoicing;
};

const getInvoicingContentTypeFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return "accounting.leaseinvoicing";
  if (type === INVOICE_TYPES.OTHER) return "accounting.otherinvoicing";
  if (type === INVOICE_TYPES.PARKING) return "accounting.parkinginvoicing";
  if (type === INVOICE_TYPES.BRF) return "accounting.brfinvoicing";
};

const getInvoiceObj = ({
  leaseInvObj,
  otherInvObj,
  parkingInvObj,
  brfInvObj,
}) => {
  return leaseInvObj || otherInvObj || parkingInvObj || brfInvObj;
};

const getContractIdFromInvoiceObj = ({ invoiceObj }) => {
  return (
    invoiceObj?.lease_contract?.id ||
    invoiceObj?.other_contract?.id ||
    invoiceObj?.parking_contract?.id ||
    invoiceObj?.brf_premises?.id
  );
};

export default function InvoiceRecordOverview() {
  const [errorCount] = useInvoicingErrorPaginationCount();
  const { push } = useHistory();

  const { invoiceRecordId } = useParams();

  const query = buildQueryString({
    id__in: invoiceRecordId || [],
  });
  const [invoiceRecords] = useFilteredInvoicingRecords(query);
  const invoiceRecord = invoiceRecords?.[0];

  const invoiceType = getInvoiceTypeFromRecord({ record: invoiceRecord });
  const nestedInvoiceObj = getInvoiceObjFromRecord({ record: invoiceRecord });
  const invoiceObjKey = getInvoiceObjKeyFromType(invoiceType);
  const contentType = getInvoicingContentTypeFromType(invoiceType);

  const [leaseInvObj] = useLeaseInvoicing(invoiceRecord?.lease_invoicing?.id);
  const [otherInvObj] = useOtherInvoicing(invoiceRecord?.other_invoicing?.id);
  const [parkingInvObj] = useParkingInvoicing(
    invoiceRecord?.parking_invoicing?.id
  );
  const [brfInvObj] = useBrfInvoicing(invoiceRecord?.brf_invoicing?.id);

  const wholeInvoiceObj = getInvoiceObj({
    leaseInvObj,
    otherInvObj,
    parkingInvObj,
    brfInvObj,
  });

  const contractId = getContractIdFromInvoiceObj({
    invoiceObj: wholeInvoiceObj,
  });

  const errorQ = {
    [invoiceObjKey]: nestedInvoiceObj?.id,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBoxFlexWrapper>
        <DetailPageBox
          style={{ flex: 1, maxWidth: "33%", alignSelf: "flex-start" }}
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Information</OverviewTitle>
              <OverviewSubtitle>
                Klicka på "Försök igen" när problemet är löst för att manuellt
                tvinga aviseringen
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <DetailInfo infoObj={getInfoObj({ record: invoiceRecord })} />

          <PrimaryButton
            title="Försök igen"
            clicked={() => {
              push(
                previewAndGenerateUrl({
                  type: invoiceType,
                  id: nestedInvoiceObj?.id,
                  contractId,
                  basedOn: invoiceRecord?.invoice_date,
                  hasGenerated: false,
                  withGenerate: true,
                })
              );
            }}
          />
        </DetailPageBox>

        <DetailPageBox
          style={{ flex: 2, maxWidth: "66%", alignSelf: "flex-start" }}
          error
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
                small
              >
                Felmeddelanden{" "}
                <NotificationDot
                  style={{
                    backgroundColor: theme.colors.red,
                    borderColor: theme.colors.red,
                  }}
                >
                  {errorCount}
                </NotificationDot>
              </OverviewTitle>

              <OverviewSubtitle>
                Dessa fel kan innebära att bolag, hyresgäster,
                aviseringsinställningar, kostnadsställen, produkter, kostnader,
                projekt och fakturor inte kan synkas som de ska mot
                aviseringssystemet.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <InvoicingErrorsTable
            isBare
            ignoreLocalStorage
            persistantQuery={errorQ}
          />
        </DetailPageBox>
      </DetailPageBoxFlexWrapper>
    </DetailInnerWrapper>
  );
}

function getInfoObj({ record }) {
  const detailUrl = record?.lease_invoicing
    ? leaseInvoicingToContractDetailUrl
    : record?.other_invoicing
    ? otherInvoicingToContractDetailUrl
    : record?.parking_invoicing
    ? parkingInvoicingToContractDetailUrl
    : record?.brf_invoicing
    ? brfInvoicingToContractDetailUrl
    : null;
  const source =
    record?.lease_invoicing ||
    record?.other_invoicing ||
    record?.parking_invoicing ||
    record?.brf_invoicing;

  return {
    Logginformation: [
      {
        title: "Källa",
        value: <LinkedObject obj={source} urlMethod={detailUrl} />,
      },
      {
        title: "Period",
        value: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <DateCell date={record?.period_start} /> -{" "}
            <DateCell date={record?.period_end} />
          </div>
        ),
      },
      {
        title: "Avidatum",
        value: <DateCell date={record?.invoice_date} />,
      },
      {
        title: "Förfallodatum",
        value: <DateCell date={record?.due_date} />,
      },
    ],
  };
}
