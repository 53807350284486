import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";

import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

import ParkingSpotsTable from "../../../components/Tables/Parking/ParkingSpots/FullTable";
import { useParkingSpecification } from "../../../store/parkingSpecifications";
import ParkingSpecificationForm from "../../../components/Forms/ParkingSpecification/ChapterForm/ModalForm";

export default function ParkingSpecificationOverview() {
  const { specificationId } = useParams();
  const [specification] = useParkingSpecification(specificationId);
  const [editOpen, setEditOpen] = React.useState(false);

  const spotQ = {
    specification: specificationId,
  };

  const canEdit = usePermissionCheck("change_can_parking");

  return (
    <>
      {editOpen && (
        <ParkingSpecificationForm
          id={specificationId}
          method="PATCH"
          instance={specification}
          onCheckout={() => setEditOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>

            {canEdit && (
              <PrimaryButton
                title="Redigera"
                clicked={() => setEditOpen(true)}
              />
            )}
          </OverviewTitleWrapper>

          <DetailInfo
            infoObj={getInfoObj({
              specification,
            })}
          />
        </DetailPageBox>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>
              Fordonsplatser med specifikation
            </OverviewTitle>
          </OverviewTitleWrapper>
          <ParkingSpotsTable
            persistantQuery={spotQ}
            isBare
            ignoreLocalStorage
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ specification }) {
  const infoObj = {};

  infoObj["Översikt"] = [
    {
      title: "Namn",
      value: specification?.str_representation || "-",
    },
    {
      title: "Max höjd (CM)",
      value: specification?.height_limit || "-",
    },
    ,
    {
      title: "Max bredd (CM)",
      value: specification?.width_limit || "-",
    },
    {
      title: "Max längd (CM)",
      value: specification?.length_limit || "-",
    },
    {
      title: "Max vikt (KG)",
      value: specification?.weight_limit || "-",
    },
  ];

  return infoObj;
}
