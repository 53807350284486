import * as React from "react";
import { constants } from "../../../../../store/parkingContracts";

// style, design
import TableFileM2m from "../../../Base/Fields/TableFileM2m";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <TableFileM2m
        title="Bilagor"
        description="Ladda upp eventuella bilagor till avtalet"
        storeName={storeName}
        method={method}
        fieldKey={"cancelled_doc.attachments"}
      />
    </>
  );
};
