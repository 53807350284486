import * as React from "react";
import * as SC from "./styles";

export default function InvoiceTableFilters({
  selectedFilter,
  updateFilter,
  filterCounts,
}) {
  return (
    <SC.FilterWrapper>
      <SC.ClosedInvoiceFilterButton
        selected={selectedFilter === "Completed"}
        onClick={() => updateFilter("Completed")}
      >
        Slutbetalda ({filterCounts?.Completed || 0})
      </SC.ClosedInvoiceFilterButton>

      <SC.NonPaidFilterButton
        selected={selectedFilter === "InvoiceSent"}
        onClick={() => updateFilter("InvoiceSent")}
      >
        Obetalda ({filterCounts?.InvoiceSent || 0})
      </SC.NonPaidFilterButton>

      <SC.LateInvoiceFilterButton
        selected={selectedFilter === "Manual"}
        onClick={() => updateFilter("Manual")}
      >
        Förfallna ({filterCounts?.Manual || 0})
      </SC.LateInvoiceFilterButton>

      <SC.NonAttestedFilterButton
        selected={selectedFilter === "Created"}
        onClick={() => updateFilter("Created")}
      >
        Ej attesterade ({filterCounts?.Created || 0})
      </SC.NonAttestedFilterButton>

      <SC.ReminderFilterButton
        selected={selectedFilter === "ReminderInvoiceSent"}
        onClick={() => updateFilter("ReminderInvoiceSent")}
      >
        Påminnelser ({filterCounts?.ReminderInvoiceSent || 0})
      </SC.ReminderFilterButton>

      <SC.SentToDebtCollectionFilterButton
        selected={selectedFilter === "SentToDebtCollection"}
        onClick={() => updateFilter("SentToDebtCollection")}
      >
        Kravhantering ({filterCounts?.SentToDebtCollection || 0})
      </SC.SentToDebtCollectionFilterButton>
    </SC.FilterWrapper>
  );
}
