import * as React from "react";
import { destroyForm, usePermissionCheck } from "../../store/base";

import Alert from "../Alert/Alert";
import {
  useEditabledoc,
  handleContractEditableDocUrl,
  EDITABLE_DOC_CONTRACT_TYPES,
} from "../../store/editabledocs";
import { useHistory } from "react-router-dom";
import Modal from "../Forms/Base/Modals/Modal";
import DateSelect from "../Forms/Base/Fields/DateSelect";
import MediaField from "../Forms/Base/Fields/MediaField";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

const getContractType = (storeName) => {
  switch (storeName) {
    case "leaseContracts":
      return EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT;
    case "otherContracts":
      return EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT;
    case "parkingContracts":
      return EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT;
  }
};

export default function ManualSign({ contract, constants, update }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [editableDoc, editableDocLoading] = useEditabledoc(
    contract?.editabledoc?.id
  );

  const canEdit = usePermissionCheck("change_can_contract");
  const contractType = getContractType(storeName);

  const [markSignedFormOpen, setMarkSignedFormOpen] = React.useState(false);
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);

  const { push } = useHistory();

  const isFastDoc = editableDoc?.fastdoc_id;

  React.useEffect(() => {
    return () => {
      dispatch(destroyForm({ constants, method: "PATCH" }));
    };
  }, []);

  const handleCreateEditableDoc = () => {
    setLoadingCreate(true);
    dispatch(
      update({
        id: contract.id,
        forceData: {
          editabledoc: {
            title: "doc",
          },
        },
        successCallback: () => {
          setLoadingCreate(false);
          push(
            handleContractEditableDocUrl({
              contractType: contractType,
              id: contract?.id,
            })
          );
        },
        errorCallback: () => {
          setLoadingCreate(false);
        },
      })
    );
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    // new signed doc data
    if (data.editabledoc?.doc?._tempData) {
      data.editabledoc = {
        ...data.editabledoc,
        fastdoc_id: null,
        parties: [],
      };
    }

    dataClone.is_signed = true;

    return dataClone;
  };

  const onUpdate = () => {
    setLoadingUpdate(true);

    dispatch(
      update({
        id: contract.id,
        preProcess,
        successCallback: () => {
          setLoadingUpdate(false);
          setMarkSignedFormOpen(false);
        },
        errorCallback: () => {
          setLoadingUpdate(false);
        },
      })
    );
  };

  if ((contract?.editabledoc && editableDocLoading) || !canEdit) return null;

  return (
    <>
      {markSignedFormOpen && (
        <Modal
          onAccept={onUpdate}
          closeFunction={() => setMarkSignedFormOpen(false)}
          title="Markera avtal som signerat"
        >
          {loadingUpdate && <OverlaySpinner />}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <DateSelect
              title="Signeringsdatum"
              storeName={storeName}
              fieldKey="signed_date"
              method="PATCH"
            />
          </div>

          <MediaField
            title="Signerat dokument"
            storeName={storeName}
            fieldKey="editabledoc.doc"
            method="PATCH"
            description={`Ladda upp det signerade dokumentet. ${
              isFastDoc
                ? "OBS: Ladda ej upp ett dokument om avtalet har signerats via Fastighetsägarna Dokument. Det signerade avtalet hämtas automatiskt in."
                : ""
            }`}
            allowedFormats={[".pdf"]}
            fileKey="doc"
          />
        </Modal>
      )}

      <Alert
        title="Väntar på signering"
        primaryTitle="Markera som signerat"
        primaryAction={() => setMarkSignedFormOpen(true)}
        secondaryTitle={
          editableDoc ? "Hantera dokument" : "Skapa digitalt dokument"
        }
        secondaryAction={
          editableDoc
            ? () =>
                push(
                  handleContractEditableDocUrl({
                    contractType,
                    id: contract.id,
                  })
                )
            : handleCreateEditableDoc
        }
      >
        {loadingCreate && <OverlaySpinner />}
        {isFastDoc ? (
          <>
            Detta avtal inväntar manuell signering/signering via
            Fastighetsägarna Dokument. <br /> <br />{" "}
            <strong>Om det är signerat via Fastighetsägarna Dokument</strong>,
            markera avtalet som signerat{" "}
            <strong className="underline">utan</strong> att ladda upp det
            signerade dokumentet. Det signerade avtalet hämtas automatiskt in
            från Fastighetsägarna. <br /> <br />
            <strong>Om det är manuellt signerat</strong>, ladda upp det
            signerade avtalet (frivilligt) och ange signeringsdatum för att
            markera avtalet som signerat.
          </>
        ) : (
          <>
            Detta avtal inväntar manuell signering. Markera avtalet som signerat
            och ladda upp det signerade avtalet (frivilligt) för att markera
            avtalet som aktivt.
          </>
        )}
      </Alert>
    </>
  );
}
