export const constructStrRep = (obj) => {
  return obj.str_representation || "Lån";
};
export const detailUrl = ({ id }) => {
  return `/loans/detail/${id}`;
};
export const createUrl = (brfCompanyId, paymentGroupId) => {
  return paymentGroupId == null ? `/loans/create/${brfCompanyId}` : `/loans/create/${brfCompanyId}/${paymentGroupId}`;
};

export const updateUrl = (id, brfCompanyId) => {
  return `/loans/update/${id}/${brfCompanyId}`
};
