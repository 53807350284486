import * as React from "react";
import { PrimaryButton } from "../../../../Base/Buttons";
import { constants } from "../../../../../../store/IMDMeasureType";
import {
  updateActiveFormInstance,
  updateFormErrors,
  useFormInstanceField,
} from "../../../../../../store/base";
import parseExcell from "../parseExcel";
import { useDispatch } from "react-redux";

export default ({ onSubmit }) => {
  const dispatch = useDispatch();

  const storeName = constants.STORE_NAME;

  const importData = useFormInstanceField({ storeName, fieldKey: "_import" });
  const hasId = useFormInstanceField({
    storeName,
    fieldKey: "_import_measure_type.id",
  });
  const hasValues =
    Boolean(importData) &&
    importData._xlsxFileImport != null &&
    importData._sheetName != null &&
    importData._headerRow != null &&
    importData._sensorIdColumnName != null &&
    importData._dateColumnName != null &&
    importData._valueColumnName != null &&
    Boolean(hasId);

  const onNext = () => {
    let { result, error } = parseExcell({ importData });

    if (error) {
      const xlsxError = result["_xlsxFileImport"];
      delete result["_xlsxFileImport"];

      const stillError = Object.keys(result).length > 0;
      if (stillError) {
        dispatch(updateFormErrors({ storeName, data: { _import: result } }));
      }

      if (xlsxError) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { "_import._xlsxFileImportError": xlsxError },
          })
        );
      }
    } else {
      dispatch(updateFormErrors({ storeName, data: {} }));
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { "_import._xlsxFileImportError": undefined },
        })
      );
      onSubmit(result);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200,
      }}
    >
      <div>
        <PrimaryButton
          title="Importera mätvärden"
          clicked={onNext}
          disabled={!hasValues}
        />
      </div>
    </div>
  );
};
