import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { TextInput, Select } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/tenantPortalSettingTerms";
import DocumentUploader from "../../../Base/Fields/DocumentUploader";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Dokumentinformation</FormAreaTitle>
      <FormAreaDescription>
        Fyll i information nedan gällande dokumentet.
      </FormAreaDescription>

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey="title"
        title="Titel"
        description={`Fyll i titel för dokumentet, det är den här titel som visas i "Mina sidor". Med andra ord har det ingen betydelse vad filnamnet är på dokumentet du laddar upp.`}
      />

      <Select
        storeName={storeName}
        fieldKey="category"
        method={method}
        title="Kategori"
        description='Välj om dokumentet ska listas som "Infomation" eller "Regler" i "Mina sidor".'
      />

      <DocumentUploader
        storeName={storeName}
        title="Dokument"
        fieldKey="doc"
        method={method}
      />
    </>
  );
};
