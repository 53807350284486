import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InnerBox } from "../../../../sharedStyles";
import PrimaryBtn from "../../../Base/Buttons/PrimaryBtn";
import ManyMediaField from "../../../Base/Fields/ManyMediaField";

export default function Attachemnts({ title, description, storeName, method }) {
  const dispatch = useDispatch();
  const attachments = useFormInstanceField({
    storeName,
    fieldKey: "Attachments",
  });

  const clearAttachments = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          Attachments: [],
        },
      })
    );
  };
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>
          {description && <OverviewSubtitle>{description}</OverviewSubtitle>}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {method !== "POST" && attachments?.some((f) => f.File?.FilePublicId) ? (
        <div className="flex p-4 flex-col border border-solid border-slate-200 rounded bg-white mb-12">
          <div className="text-sm ">
            Denna faktura har tidigare bilagor. Rensa de tidigare bilagorna för
            att kunna lägg till nya.
          </div>

          <div className="flex justify-start mt-2">
            <PrimaryBtn error onClick={clearAttachments}>
              Rensa bilagor{" "}
            </PrimaryBtn>
          </div>
        </div>
      ) : (
        <ManyMediaField
          fieldKey="Attachments"
          fileKey="File"
          title="Bilagor"
          allowedFormats={[".pdf", ".png", ".jpg", ".jpeg"]}
          {...{ storeName, method }}
        />
      )}
    </>
  );
}
