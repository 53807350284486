import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];

    // helper files
    const helperFiles = formInstance?.files || [];
    const responseFiles = responseData?.files || [];

    if (helperFiles.length === responseFiles.length) {
      responseFiles.forEach((f, index) => {
        const file = helperFiles[index]?._tempData?.data;

        const url = f?.file?.put;

        if (file && url) {
          urls.push(url);
          files.push(file);
        }
      });
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
