import { getPatchForm } from "..";
import { useDispatch, useSelector } from "react-redux";
import { getFormFetchProcessName } from "../../base";

export function useConfigCenterForm(method, storeName, id) {
  const dispatch = useDispatch();

  const processName = getFormFetchProcessName(getPatchForm);

  const existing = useSelector((state) => state[storeName].forms["PATCH"]);
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(processName)
  );

  // first check if it exists in store
  if (existing) {
    return existing;
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result
  if (isFetching) {
    return undefined;
  }

  // return useForm(params);
  // we're not waiting, so we should initiate a retrival
  if (method === "PATCH") {
    dispatch(getPatchForm());
  }

  return undefined;
}
