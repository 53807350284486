export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRFINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRFINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRFINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRFINVOICING",
  DESTROY_FORM: "DESTROY_FORM_BRFINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRFINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRFINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRFINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRFINVOICING",
  RESET_STATE: "RESET_STATE_BRFINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRFINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_BRFINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_BRFINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRFINVOICING",
  SET_PERIODS_FOR_CONTRACT: "SET_PERIODS_FOR_CONTRACT_BRFINVOICING",
  CLEAR_PERIODS_FOR_CONTRACT: "CLEAR_PERIODS_FOR_CONTRACT_BRFINVOICING",

  LIST_URL: "/accounting/contract/brfinvoicing/list/",
  POST_URL: "/accounting/contract/brfinvoicing/",
  PATCH_URL: "/accounting/contract/brfinvoicing/",
  GET_URL: "/accounting/contract/brfinvoicing/",
  MASS_INVOICE_STATUS_UPDATE_URL:
    "/accounting/contract/masspause_brfinvoicing/",
  PREVIEW_URL: "/accounting/contract/preview_brfinvoicing/",
  GENERATE_URL: "/accounting/contract/generatenow_brfinvoicing/",
  PERIOD_URL: "/accounting/contract/periodinfo_brfinvoicing/",

  STORE_NAME: "invoicingBrf",
};
