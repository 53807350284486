import styled from "styled-components";
import loginBg from "../../assets/img/login_bg.jpg";

export const Wrapper = styled.div`
  width: 94%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
`;

export const LoginContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  grid-row-gap: 24px;
  padding: 24px 12px;
`;

export const LoginInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginPageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const BackgroundImage = styled.div`
  background-image: url(${loginBg});
  background-position: 83%;
  background-size: cover;
  position: relative;
  min-height: 100vh;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const BackgroundImageFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 700px;
  height: 100%;
  background-image: linear-gradient(
    105deg,
    rgba(20, 20, 50, 0.09),
    rgba(20, 20, 159, 0.14) 40%,
    rgba(10, 10, 80, 0.14)
  );
`;
