import styled, { css } from "styled-components";

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  margin: 0 8px 8px 0;
  overflow: hidden;
`;

export const Image = styled.div`
  cursor: pointer;
  height: 250px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.src});
`;

export const ImageDescription = styled.div`
  color: ${(p) => p.theme.colors.primaryText};
  width: 100%;
  padding: 8px;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  max-height: 60px;
  overflow-y: auto;
`;

export const ColorCode = styled.div`
  margin-left: 6px;
  padding: 6px;
  border-radius: 6px;
  font-weight: 600;
  color: ${(p) => (p.color === "dark" ? "#fff" : "#000")};
  background-color: #${(p) => p.backgroundColor};
  width: ${(p) => p.width};
`;
