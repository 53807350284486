import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

export default function KU55PrintView() {
  const [data, setData] = useState();
  const [extraData, setExtraData] = useState();
  const [error, setError] = useState(false);

  const triggerPrint = () => {
    setTimeout(() => {
      window.print();
    }, 500);
  };

  useEffect(() => {
    const localData = localStorage.getItem("ku55printdata");
    const extraData = localStorage.getItem("ku55printdataExtra");

    if (!localData) {
      setError(true);
      return;
    }

    try {
      const parsed = JSON.parse(localData);
      const parsedExtra = JSON.parse(extraData);
      setData(parsed);
      setExtraData(parsedExtra);
      triggerPrint();
    } catch (e) {
      setError(true);
    }
  }, []);

  if (!data) return <div>Laddar...</div>;

  if (error)
    return <div>Något gick fel. Stäng detta fönster och försök igen.</div>;

  return (
    <div className="w-[1000px] min-w-[1000px] max-w-[1000px] h-[1300px] min-h-[1300px] max-h-[1200px] overflow-hidden  p-24">
      <div className="flex justify-between w-full">
        <h1 className="text-2xl font-semibold">Kopia - Kontrolluppgift</h1>
        <h1 className="text-2xl font-semibold">KU55</h1>
      </div>

      <div className="text-xl mb-12">Överlåtelse av bostadsrätt</div>

      <div className="grid grid-cols-2 gap-x-8 items-start">
        <div></div>
        <div className="text-lg font-semibold">Överlåtare</div>
        <div className="grid">
          <div className="border border-black grid grid-cols-2 min-h-[48px]">
            <div className="border-r border-black">
              <div className="flex items-center justify-between">
                <div className="text-xs font-medium"></div>{" "}
                <div className="text-end">570</div>
              </div>
              <div className="text-xs">Specifikationsnummer</div>
            </div>
            <div className="flex items-center p-2">
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:Specifikationsnummer faltkod="570"'
                ]
              }
            </div>

            <div className="col-span-2 border-t border-t-black min-h-[48px]">
              <div className="flex items-start">
                <div className="max-w-[120px] text-xs">
                  Denna kontrolluppgift ska{" "}
                </div>

                <div className="flex">
                  <div>
                    <div>210</div>
                    <div className="border min-h-[24px] min-w-[24px] max-w-[24px] border-black m-1">
                      {data["ku:Blankettinnehall"]["ku:KU55"][
                        'ku:Rattelse faltkod="210"'
                      ] ? (
                        <XMarkIcon className="h-4 w-4" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mt-6  text-xs">
                    Rätta tidigare kontrolluppgift
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <div>205</div>
                    <div className="border min-h-[24px] min-w-[24px] max-w-[24px] border-black m-1"></div>
                  </div>
                  <div className="mt-6  text-xs">
                    Ta bort tidigare inlämnad kontrolluppgift
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-lg font-semibold my-2">Uppgiftslämnare</div>

          <div className="grid grid-cols-2 h-12 border border-b-0 border-black">
            <div className="col-span-1 border-r border-black">
              <div className="flex items-center justify-between">
                <div className="text-xs"></div>{" "}
                <div className="text-end">201</div>
              </div>
              <div className="text-xs">Organisationsnummer</div>
            </div>
            <div className="col-span-1 flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  "ku:UppgiftslamnareKU55"
                ]['ku:UppgiftslamnarId faltkod="201"']
              }
            </div>
          </div>
          <div className="h-12 border border-black text-xs">
            Namn
            <div className="text-sm">
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  "ku:UppgiftslamnareKU55"
                ]['ku:NamnUppgiftslamnare faltkod="202"']
              }
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="grid grid-cols-2 border border-b-0 border-black h-12">
            <div className="border-r border-black">
              <div className="flex items-center justify-between">
                <div className="text-xs"></div>{" "}
                <div className="text-end">215</div>
              </div>
              <div className="text-xs">Personnummer</div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"]["ku:InkomsttagareKU55"][
                  'ku:Inkomsttagare faltkod="215"'
                ]
              }
            </div>
          </div>
          <div className="border border-b-0 border-black h-12">
            <div className="text-xs">Namn</div>
            <div className="text-sm">
              {
                data["ku:Blankettinnehall"]["ku:KU55"]["ku:InkomsttagareKU55"][
                  'ku:Fornamn faltkod="216"'
                ]
              }{" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"]["ku:InkomsttagareKU55"][
                  'ku:Efternamn faltkod="217"'
                ]
              }
            </div>
          </div>
          <div className="border border-b-0 border-black h-12">
            <div className="text-xs">Gatuadress</div>
            <div className="text-sm">
              {
                data["ku:Blankettinnehall"]["ku:KU55"]["ku:InkomsttagareKU55"][
                  'ku:Gatuadress faltkod="218"'
                ]
              }
            </div>
          </div>
          <div className="grid grid-cols-3 border border-black text-xs">
            <div className="col-span-1 h-12 border-r border-black ">
              Postnummer
              <div className="text-sm">
                {
                  data["ku:Blankettinnehall"]["ku:KU55"][
                    "ku:InkomsttagareKU55"
                  ]['ku:Postnummer faltkod="219"']
                }
              </div>
            </div>
            <div className="col-span-2 h-12 text-xs">
              Postort{" "}
              <div className="text-sm">
                {
                  data["ku:Blankettinnehall"]["ku:KU55"][
                    "ku:InkomsttagareKU55"
                  ]['ku:Postort faltkod="220"']
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-8 items-start mt-4">
        <div className="text-lg font-semibold">Bostadsrätten</div>
        <div className="text-lg font-semibold">Bostadföretaget oäkta</div>

        <div className="min-h-[48px] border grid grid-cols-2 border-black">
          <div className=" border-r border-black">
            <div className="flex items-center justify-between">
              <div className="text-xs"></div>{" "}
              <div className="text-end">630</div>
            </div>
            <div className="text-xs">Bostadsrättens/lägenhetens beteckning</div>
          </div>
          <div className="flex items-center p-2">
            {" "}
            {
              data["ku:Blankettinnehall"]["ku:KU55"][
                'ku:BostRattBeteckning faltkod="630"'
              ]
            }
          </div>
        </div>
        <div className="min-h-[48px] border  border-black">
          <div>638</div>
          <div className="flex items-center">
            <div className="border border-black h-6 w-6 min-h-[24px] min-w-[24px] mx-1">
              {data["ku:Blankettinnehall"]["ku:KU55"][
                'ku:OaktaBostadsftg faltkod="638"'
              ] ? (
                <XMarkIcon className="h-4 w-4" />
              ) : (
                ""
              )}
            </div>
            <div className="text-xs">
              Bostadsföretaget var oäkta (inte privatbostadsföretag) vid
              ingången av inkomståret
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-8 items-start mt-4">
        <div className="col-span-2 text-lg font-semibold">
          Individuella eller gemensamma uppgifter
        </div>

        <div className="col-span-1 border border-black">
          <div className="col-span-2  border-t-black min-h-[48px]">
            <div className="text-end">646</div>
            <div className="flex items-start">
              <div className="max-w-[120px] text-xs">
                Uppgifterna i denna blankett är{" "}
              </div>

              <div className="flex">
                <div>
                  <div className="border min-h-[24px] min-w-[24px] max-w-[24px] border-black m-1">
                    {data["ku:Blankettinnehall"]["ku:KU55"][
                      'ku:GemensamIndividuell faltkod="646"'
                    ] === "G" ? (
                      <XMarkIcon className="h-4 w-4" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="  text-xs">Gemensamma för flera delägare</div>
              </div>
              <div className="flex">
                <div>
                  <div className="border min-h-[24px] min-w-[24px] max-w-[24px] border-black m-1">
                    {data["ku:Blankettinnehall"]["ku:KU55"][
                      'ku:GemensamIndividuell faltkod="646"'
                    ] === "I" ? (
                      <XMarkIcon className="h-4 w-4" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" text-xs">Individuella</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-8 items-start mt-4">
        <div className=" text-lg font-semibold">Uppgifter om överlåtelsen</div>
        <div className=" text-lg font-semibold">
          Uppgifter om överlåtarens förvärv
        </div>

        <div className="border border-black">
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex justify-between items-center">
                <div className="text-xs"></div>{" "}
                <div className="text-end">631</div>
              </div>
              <div className="text-xs">Överlåtelsedatum (ÅÅÅÅMMDD)</div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:Overlatelsedatum faltkod="631"'
                ]
              }
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="text-end">632</div>
              <div className="text-xs">Överlåten andel i procent</div>
            </div>
            <div className="p-2">
              {extraData?.transactionPercentage != null
                ? extraData?.transactionPercentage
                : "Ej angett"}
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-2 min-h-[24px]">
              <div className="text-start">633</div>
              <div className="flex items-center">
                <div className="border min-h-[24px] min-w-[24px] max-w-[24px] border-black m-1">
                  {data["ku:Blankettinnehall"]["ku:KU55"][
                    'ku:OverlatelseArvBodeln faltkod="633"'
                  ] ? (
                    <XMarkIcon className="h-4 w-4" />
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-xs">
                  Överlåtelsen har skett genom arv, gåva, bodelning eller
                  liknande
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex items-center justify-between">
                <div className="text-xs"></div>{" "}
                <div className="text-end">634</div>
              </div>
              <div className="text-xs">Överlåtelsepris </div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:Overlatelsepris faltkod="634"'
                ]
              }
              kr
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex items-center justify-between">
                <div className="text-xs"></div>{" "}
                <div className="text-end">635</div>
              </div>
              <div className="text-xs">
                Bostadsrättens andel av innestående medel på inre
                reparationsfond vid överlåtelsen
              </div>
            </div>
            <div className="flex items-center p-2">
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:InreRepFondOverlatelse faltkod="635"'
                ]
              }
              kr
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex items-center justify-between">
                <div className="text-xs"></div>{" "}
                <div className="text-end">636</div>
              </div>
              <div className="text-xs">Kapitaltillskott</div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:Kapitaltillskott faltkod="636"'
                ]
              }
              kr
            </div>
          </div>
          <div className="grid grid-cols-2  border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="text-end">639</div>
              <div className="text-xs">Tilläggsköpeskilling</div>
            </div>
          </div>
        </div>

        <div className="border border-black">
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex items-center justify-between">
                <div className="text-xs"></div>
                <div className="text-end">640</div>
              </div>
              <div className="text-xs">Förvärvsdatum (ÅÅÅÅMMDD)</div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:Forvarvsdatum faltkod="640"'
                ]
              }
            </div>
          </div>

          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-2 min-h-[24px]">
              <div className="text-start">642</div>
              <div className="flex items-center">
                <div className="border min-h-[24px] min-w-[24px] max-w-[24px] border-black m-1"></div>

                <div className="text-xs">
                  Förvärvet har helt eller delvis skett genom arv, gåva,
                  bodelning eller liknande
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex justify-between items-center">
                <div className="text-xs"></div>
                <div className="text-end">643</div>
              </div>
              <div className="text-xs">Förvärspris </div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:Forvarvspris faltkod="643"'
                ]
              }
              kr
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex items-center justify-between">
                <div className="text-xs"></div>
                <div className="text-end">644</div>
              </div>
              <div className="text-xs">
                Bostadsrättens andel av innestående medel på inre
                reparationsfond vid förvärvet
              </div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:InreRepFondForvarv faltkod="644"'
                ]
              }
              kr
            </div>
          </div>
          <div className="grid grid-cols-2  border-black">
            <div className="col-span-1 border-r border-black min-h-[24px]">
              <div className="flex items-center justify-between">
                <div className="text-xs font-medium"></div>{" "}
                <div className="text-end">645</div>
              </div>
              <div className="text-xs">
                Bostadsrättens andel av föreningens behållna förmögenhet den 1
                januari 1974
              </div>
            </div>
            <div className="flex items-center p-2">
              {" "}
              {
                data["ku:Blankettinnehall"]["ku:KU55"][
                  'ku:AndelBRFFormogenhet1974 faltkod="645"'
                ]
              }
            </div>
          </div>
        </div>
      </div>

      <div className="my-12 text-center text-2xl font-semibold">
        Detta är överlåtarens utskrift.
        <br />
        Utskriften får INTE skickas till Skatteverket.
      </div>
    </div>
  );
}
