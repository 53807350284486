import React from "react";
import { TextButton } from "../../Base/Buttons";

import * as SC from "./styles";

export default function WhiteListAlternatives({
  group,
  openDetailModal,
  readableTitle,
  current,
  whiteListContentTypes,
  baseobjectMap,
  disabled,
}) {
  let allView = group?.permissions
    ?.map((p) => {
      const [action, can, perm, rest] = p.split("_");

      if (
        p.includes("view") &&
        whiteListContentTypes.includes(`${perm}${rest ? `_${rest}` : ""}`)
      ) {
        return `${perm}${rest ? `_${rest}` : ""}`;
      }
    })
    .filter((i) => i);

  if (allView.includes("baseobject")) {
    baseobjectMap.forEach((b) => allView.push(b));
    allView = allView.filter((p) => p !== "baseobject");
  }
  if (allView.includes("reporterrand")) {
    allView.push("reporterrandsetting");
  }
  if (allView.includes("inspectionerrand")) {
    allView.push("inspectionerrandsetting");
  }
  if (allView.includes("roundingerrand")) {
    allView.push("roundingerrandsetting");
  }
  if (allView.includes("goverrand")) {
    allView.push("goverrandsetting");
  }

  // const showAmountPicked = (contentType) => {
  //   return current[contentType]?.length || 0;
  // };

  return (
    <SC.DetailPermsWrapper>
      {allView?.map((i) => (
        <SC.DetailPermItemWrapper
          disabled={disabled}
          onClick={() => {
            if (disabled) return;
            openDetailModal(i);
          }}
        >
          <SC.DetainPermItem style={{ flex: 4 }}>
            {readableTitle(i)}
          </SC.DetainPermItem>
          {/* <SC.DetainPermItem style={{ flex: 1 }}>
            {showAmountPicked(i)} valda
          </SC.DetainPermItem> */}
          <TextButton style={{ flex: 1 }} iconType="arrow" />
        </SC.DetailPermItemWrapper>
      ))}
    </SC.DetailPermsWrapper>
  );
}
