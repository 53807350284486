import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import EditabledocTable from "../../../components/Tables/EditableDocs/FullTable";
import { buildQueryString } from "../../../store/base";

export default function Templates() {
  const persistantQuery = {
    is_template: true,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Mallar</OverviewTitle>
        </OverviewTitleWrapper>

        <EditabledocTable persistantQuery={persistantQuery} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
