import * as React from "react";
import { DoubleFieldWrapper } from "../Base/Chapters/styles";
import { Select, TextInput, TimePicker } from "../Base/Fields";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  return (
    <>
      <Select
        title="Status för projekt"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.status`,
          instructionsKey: `${parentInstructionsPath}.status`,
        }}
      />

      <TextInput
        title="Titel"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.title`,
          instructionsKey: `${parentInstructionsPath}.title`,
        }}
      />

      <TextInput
        title="Beskrivning"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.description`,
          instructionsKey: `${parentInstructionsPath}.description`,
        }}
      />

      <DoubleFieldWrapper>
        <TimePicker
          title="Startdatum för projekt"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.start_date`,
            instructionsKey: `${parentInstructionsPath}.start_date`,
          }}
        />
        <TimePicker
          extraStyle={{ marginLeft: 12 }}
          title="Slutdatum för projekt"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.end_date`,
            instructionsKey: `${parentInstructionsPath}.end_date`,
          }}
        />
      </DoubleFieldWrapper>

      <TextInput
        title="Projektledare"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.project_leader`,
          instructionsKey: `${parentInstructionsPath}.project_leader`,
        }}
      />

      <TextInput
        title="Kontaktperson"
        {...{
          storeName,
          method,
          fieldKey: `${parentPath}.contact_name`,
          instructionsKey: `${parentInstructionsPath}.contact_name`,
        }}
      />
    </>
  );
};
