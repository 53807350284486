import styled from "styled-components";

export const BasicDocImage = styled.div`
  width: 100%;
  height: 600px;
  background-image: url(${(p) => p.img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
