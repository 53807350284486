import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import { useFormInstanceField } from "../../../store/base";
import { PARTY_KIND } from "../../../store/editabledocs/utils";
import Alert, { ALERT_TYPES } from "../../Alert/Alert";
import { TextButton } from "../../Forms/Base/Buttons";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import LocalSelectField from "../../Forms/Base/Fields/LocalSelectField";

export default function DigitalDocFieldSuggestions({
  onDone,
  partyName,
  partyIdx,
  onFieldAdded,
  storeName,
}) {
  const [baseFieldKey, setBaseFieldKey] = React.useState(null);
  const [baseFieldName, setBaseFieldName] = React.useState(null);
  const [apartmentIndex, setApartmentIndex] = React.useState(0);
  const [industrialPremisesIndex, setIndustrialPremisesIndex] =
    React.useState(0);
  const [parkingSpotIndex, setParkingSpotIndex] = React.useState(0);

  const parties = useFormInstanceField({
    storeName,
    fieldKey: "parties",
  });
  const currentParty = parties?.[partyIdx];

  const isLandlord = currentParty?.category === PARTY_KIND.LANDLORD;

  const renderFields = () => {
    if (!isLandlord) {
      return (
        <div className="flex flex-wrap">
          <PrimaryBtn
            className="mr-2 mb-2"
            secondary
            onClick={() => {
              onFieldAdded({
                kind: "full_name",
                name: "Fullständigt namn",
                value: `${
                  currentParty?.fields?.find((f) => f.kind === "first_name")
                    .value
                } ${
                  currentParty?.fields?.find((f) => f.kind === "last_name")
                    .value
                }`,
                is_obligatory: false,
              });
            }}
          >
            Fullständigt namn <PlusIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            secondary
            className="mr-2 mb-2"
            onClick={() => {
              onFieldAdded({
                kind: "invoicing_address",
                name: "Faktureringsadress",
                value: `${
                  currentParty?.fields?.find(
                    (f) => f.kind === "invoicing_address"
                  )?.value || "Faktureringsadress"
                }`,
                is_obligatory: false,
              });
            }}
          >
            Faktureringsadress
            <PlusIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            secondary
            className="mr-2 mb-2"
            onClick={() => {
              onFieldAdded({
                kind: "separate_address",
                name: "Övrig adress: Gatuadress",
                value: `${
                  currentParty?.fields?.find(
                    (f) => f.kind === "separate_address"
                  )?.value || "Övrig adress: Gatuadress"
                }`,
                is_obligatory: false,
              });
            }}
          >
            Övrig adress: Gatuadress <PlusIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            secondary
            className="mr-2 mb-2"
            onClick={() => {
              onFieldAdded({
                kind: "separate_address_postalcode",
                name: "Övrig adress: Postnr.",
                value: `${
                  currentParty?.fields?.find(
                    (f) => f.kind === "separate_address_postalcode"
                  )?.value || "Övrig adress: Postnr."
                }`,
                is_obligatory: false,
              });
            }}
          >
            Övrig adress: Postnr. <PlusIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            secondary
            className="mr-2 mb-2"
            onClick={() => {
              onFieldAdded({
                kind: "separate_address_city",
                name: "Övrig adress: Postort",
                value: `${
                  currentParty?.fields?.find(
                    (f) => f.kind === "separate_address_city"
                  )?.value || "Övrig adress: Postort"
                }`,
                is_obligatory: false,
              });
            }}
          >
            Övrig adress: Postort <PlusIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            secondary
            className="mr-2 mb-2"
            onClick={() => {
              onFieldAdded({
                kind: "sign_date_contract",
                name: "Signeringsdatum (autofylls ej)",
                value: `${
                  currentParty?.fields?.find(
                    (f) => f.kind === "sign_date_contract"
                  )?.value || "Signeringsdatum"
                }`,
                is_obligatory: false,
              });
            }}
          >
            Signeringsdatum <PlusIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            secondary
            className="mr-2 mb-2"
            onClick={() => {
              onFieldAdded({
                kind: "sign_place_contract",
                name: "Ort för signering (autofylls ej)",
                value: `${
                  currentParty?.fields?.find(
                    (f) => f.kind === "sign_place_contract"
                  )?.value || "Ort för signering"
                }`,
                is_obligatory: false,
              });
            }}
          >
            Ort för signering <PlusIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
        </div>
      );
    }

    if (!baseFieldKey) {
      return (
        <div className="flex flex-col space-y-2">
          <PrimaryBtn
            onClick={() => {
              setBaseFieldKey("landlord");
              setBaseFieldName("uthyrande bolag");
            }}
            secondary
          >
            Uthyrande bolag <ChevronRightIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            onClick={() => {
              setBaseFieldKey("contract");
              setBaseFieldName("avtal");
            }}
            secondary
          >
            Avtal <ChevronRightIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            onClick={() => {
              setBaseFieldKey("apartment");
              setBaseFieldName("lägenhet");
            }}
            secondary
          >
            Lägenheter <ChevronRightIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            onClick={() => {
              setBaseFieldKey("industrialPremises");
              setBaseFieldName("lokal");
            }}
            secondary
          >
            Lokaler <ChevronRightIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            onClick={() => {
              setBaseFieldKey("parkingSpots");
              setBaseFieldName("fordonsplats");
            }}
            secondary
          >
            Fordonsplatser <ChevronRightIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
          <PrimaryBtn
            onClick={() => {
              setBaseFieldKey("invoicing");
              setBaseFieldName("avisering");
            }}
            secondary
          >
            Avisering <ChevronRightIcon width={16} className="ml-1" />{" "}
          </PrimaryBtn>
        </div>
      );
    }

    const fieldSuggestions = getFieldSuggestions({
      currentParty,
      baseFieldKey,
      baseFieldName,
      industrialPremisesIndex,
      apartmentIndex,
      parkingSpotIndex,
    });

    return (
      <>
        <TextButton
          title="Byt kategori"
          clicked={() => {
            setBaseFieldKey(null);
            setBaseFieldName(null);
          }}
          iconType="arrow-back"
          extraStyle={{ marginBottom: 12 }}
        />

        {baseFieldKey === "apartment" && (
          <div className="mb-4">
            <LocalSelectField
              title="Lägenhet i ordningen"
              choices={[
                { d: 1, v: 0 },
                { d: 2, v: 1 },
                { d: 3, v: 2 },
              ]}
              value={apartmentIndex}
              onChange={(val) => setApartmentIndex(val)}
            />
          </div>
        )}

        {baseFieldKey === "industrialPremises" && (
          <div className="mb-4">
            <LocalSelectField
              title="Lägenhet i ordningen"
              choices={[
                { d: 1, v: 0 },
                { d: 2, v: 1 },
                { d: 3, v: 2 },
              ]}
              value={industrialPremisesIndex}
              onChange={(val) => setIndustrialPremisesIndex(val)}
            />
          </div>
        )}

        {baseFieldKey === "parkingSpots" && (
          <div className="mb-4">
            <LocalSelectField
              title="Lägenhet i ordningen"
              choices={[
                { d: 1, v: 0 },
                { d: 2, v: 1 },
                { d: 3, v: 2 },
              ]}
              value={parkingSpotIndex}
              onChange={(val) => setParkingSpotIndex(val)}
            />
          </div>
        )}

        <div className="flex flex-wrap">
          {fieldSuggestions.length === 0 && (
            <Alert
              title="Alla fält tillagda"
              type={ALERT_TYPES.DANGER}
              primaryTitle="Byt kategori"
              primaryAction={() => {
                setBaseFieldKey(null);
                setBaseFieldName(null);
              }}
            >
              Alla föreslagna fält inom denna kategorin är tillagda på parten.
            </Alert>
          )}
          {fieldSuggestions.map((f) => (
            <PrimaryBtn
              secondary
              key={f.kind}
              className="mb-2 mr-2"
              onClick={() => {
                onFieldAdded({
                  kind: f.kind,
                  name: f.name,
                  value: f.value,
                  is_obligatory: false,
                });
              }}
            >
              {f.name} <PlusIcon width={16} className="ml-1" />
            </PrimaryBtn>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-2">
      <PrimaryBtn secondary onClick={onDone} className="mb-2">
        <ChevronLeftIcon width={16} className="mr-1" /> Tillbaka till partens
        fält
      </PrimaryBtn>

      <Alert title="Föreslagna autofyllnadsfält" type={ALERT_TYPES.INFO}>
        Visar föreslagna fält för {partyName}. Tryck på ett fält för att lägga
        till det på dokumentet. Fält som läggs till läggs till på den aktuella
        parten.
      </Alert>

      {renderFields()}
    </div>
  );
}

const getFieldSuggestions = ({
  currentParty,
  baseFieldKey,
  baseFieldName,
  apartmentIndex,
  industrialPremisesIndex,
  parkingSpotIndex,
}) => {
  const fields = [];

  if (baseFieldKey === "landlord") {
    fields.push({
      name: "Adress uthyrande bolag",
      kind: "landlord_company_adress",
      is_obligatory: false,
      value: "Adress uthyrande bolag",
    });

    fields.push({
      name: "Särskilda bestämmelser (autofylls ej)",
      kind: "special_contract_terms",
      is_obligatory: false,
      value: "Särskilda bestämmelser",
    });
  }

  if (baseFieldKey === "contract") {
    fields.push({
      name: "Fullständigt namn hyresvärd",
      kind: "landlord_full_name",
      is_obligatory: false,
      value: `${
        currentParty?.fields?.find((f) => f.kind === "first_name")?.value
      } ${currentParty?.fields?.find((f) => f.kind === "last_name")?.value}`,
    });

    fields.push({
      name: "Fastighetsbeteckning",
      value: "Fastighetsbeteckning",
      kind: "contract_real_estate_key",
      is_obligatory: false,
    });

    fields.push({
      name: "Avtals-ID",
      value: "Avtals-ID",
      kind: "contract_id",
      is_obligatory: false,
    });

    fields.push({
      name: "Startdatum",
      value: "Startdatum",
      kind: "contract_start_date",
      is_obligatory: false,
    });

    fields.push({
      name: "Slutdatum",
      value: "Slutdatum",
      kind: "contract_end_date",
      is_obligatory: false,
    });

    fields.push({
      name: "Uppsägningstid",
      value: "Uppsägningstid",
      kind: "contract_notify_interval",
      is_obligatory: false,
    });

    fields.push({
      name: "Total area",
      value: "Total area",
      kind: "total_area",
      is_obligatory: false,
    });

    fields.push({
      name: "Förlängningstid",
      value: "Förlängningstid",
      kind: "contract_renew_interval",
      is_obligatory: false,
    });

    fields.push({
      name: "Hyra per månad",
      value: "Hyra per månad",
      kind: "contract_total_rent_month",
      is_obligatory: false,
    });

    fields.push({
      name: "Hyra per år",
      value: "Hyra per år",
      kind: "contract_total_rent_year",
      is_obligatory: false,
    });

    fields.push({
      name: "Deposition", // todo in utils
      value: "Deposition",
      kind: "contract_deposit",
      is_obligatory: false,
    });

    fields.push({
      name: "Signeringsdatum (autofylls ej)",
      kind: "sign_date_contract",
      is_obligatory: false,
      value: "Signeringsdatum",
    });
    fields.push({
      name: "Ort för signering (autofylls ej)",
      kind: "sign_place_contract",
      is_obligatory: false,
      value: "Ort för signering",
    });
  }

  const indexKey =
    baseFieldKey === "apartment"
      ? apartmentIndex
      : baseFieldKey === "industrialPremises"
      ? industrialPremisesIndex
      : parkingSpotIndex;

  if (baseFieldKey === "apartment" || baseFieldKey === "industrialPremises") {
    fields.push({
      name: `Area ${baseFieldName} ${indexKey + 1}`,
      value: `Area ${baseFieldName} ${indexKey + 1}`,
      kind: `area_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Våning ${baseFieldName} ${indexKey + 1}`,
      value: `Våning ${baseFieldName} ${indexKey + 1}`,
      kind: `floor_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Kategori ${baseFieldName} ${indexKey + 1}`,
      value: `Kategori ${baseFieldName} ${indexKey + 1}`,
      kind: `category_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Objektsnummer ${baseFieldName} ${indexKey + 1}`,
      value: `Objektsnummer ${baseFieldName} ${indexKey + 1}`,
      kind: `object_id_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    if (baseFieldKey === "apartment") {
      fields.push({
        name: `Lägenhetsnummer ${baseFieldName} ${indexKey + 1}`,
        value: `Lägenhetsnummer ${baseFieldName} ${indexKey + 1}`,
        kind: `apartment_id_${baseFieldKey}_${indexKey}`,
        is_obligatory: false,
      });
    }

    fields.push({
      name: `Adress ${baseFieldName} ${indexKey + 1}`,
      value: `Adress ${baseFieldName} ${indexKey + 1}`,
      kind: `address_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Postnr. ${baseFieldName} ${indexKey + 1}`,
      value: `Postnr. ${baseFieldName} ${indexKey + 1}`,
      kind: `zip_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Postort ${baseFieldName} ${indexKey + 1}`,
      value: `Postort ${baseFieldName} ${indexKey + 1}`,
      kind: `city_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Kommun ${baseFieldName} ${indexKey + 1}`,
      value: `Kommun ${baseFieldName} ${indexKey + 1}`,
      kind: `municipality_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });
  }

  if (baseFieldKey === "parkingSpots") {
    fields.push({
      name: `Platsnr. ${baseFieldName} ${indexKey + 1}`,
      value: `Platsnr. ${baseFieldName} ${indexKey + 1}`,
      kind: `title_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Område ${baseFieldName} ${indexKey + 1}`,
      value: `Område ${baseFieldName} ${indexKey + 1}`,
      kind: `parkinglot_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Adress ${baseFieldName} ${indexKey + 1}`,
      value: `Adress ${baseFieldName} ${indexKey + 1}`,
      kind: `address_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Postnr. ${baseFieldName} ${indexKey + 1}`,
      value: `Postnr. ${baseFieldName} ${indexKey + 1}`,
      kind: `zip_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Postort ${baseFieldName} ${indexKey + 1}`,
      value: `Postort ${baseFieldName} ${indexKey + 1}`,
      kind: `city_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Kommun ${baseFieldName} ${indexKey + 1}`,
      value: `Kommun ${baseFieldName} ${indexKey + 1}`,
      kind: `municipality_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });
  }

  if (baseFieldKey === "invoicing") {
    fields.push({
      name: `Bankgironummer`,
      value: `Bankgironummer`,
      kind: `bankgiro`,
      is_obligatory: false,
    });

    fields.push({
      name: `Autogironummer`,
      value: `Autogironummer`,
      kind: `autogiro`,
      is_obligatory: false,
    });
  }
  // only suggest those that have not yet been added
  return fields.filter((f) => {
    const fieldWithMatchingName = currentParty.fields?.find(
      (existingField) => existingField.name === f.name
    );

    return !fieldWithMatchingName;
  });
};
