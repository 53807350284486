import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  constants,
  create,
  useIndustrialPremisesForm,
  destroyPatchForm,
  destroyPostForm,
  detailUrl,
  update,
} from "../../../../store/industrialPremises";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { cloneDeep } from "lodash";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TableSelectField from "../../Base/Fields/TableSelectField";
import TextInputField from "../../Base/Fields/TextInputField";
import { AddressField } from "../../Base/Fields";
import FloorSelector from "../../Base/FieldComponents/FloorSelector";
import SelectField from "../../Base/Fields/SelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import ManyMediaField from "../../Base/Fields/ManyMediaField";
import { useIsImpersonation } from "src/store/app/hooks";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "src/components/Details/OverviewInfo/styles";
import { InnerBox } from "src/components/sharedStyles";
import useActiveFilters from "src/hooks/useActiveFilters";

export default function IndustrialPremisesModalForm({
  method,
  id,
  onCheckout,
  instance,
  skipRedirect,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;
  const isAdmin = useIsImpersonation();

  const formLoaded = Boolean(useIndustrialPremisesForm(method, id));
  const floors = useFormInstanceField({ storeName, fieldKey: "floors" });
  const { filteredRealEstates } = useActiveFilters();

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST" && !skipRedirect) {
      push(detailUrl({ id: returned.id }));
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!dataClone.area) {
      dataClone.area = 0;
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  const landlord = useFormInstanceField({
    storeName,
    fieldKey: "landlord",
  });
  const vacancyBookingCompany = useFormInstanceField({
    storeName,
    fieldKey: "vacancy_booking_company",
  });

  React.useEffect(() => {
    if (landlord && !vacancyBookingCompany) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            vacancy_booking_company: landlord,
          },
        })
      );
    }
  }, [landlord]);

  return (
    <Modal
      title={method === "POST" ? "Lägg till lokal" : "Uppdatera lokal"}
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">
        Ange information om objektet
      </div>

      <div className="grid grid-cols-2 mb-6 gap-6">
        <TextInputField
          title="Objektsnummer"
          storeName={storeName}
          method={method}
          fieldKey="premises_id"
        />
      </div>

      <AddressField
        title="Adress"
        storeName={storeName}
        method={method}
        fieldKey="address"
      />

      <FloorSelector
        storeName={storeName}
        fieldKey={"floors"}
        floors={floors}
      />

      <div className="grid grid-cols-2 gap-6 mb-5">
        <SelectField
          title="Typ av lokal"
          storeName={storeName}
          method={method}
          fieldKey="category"
          extraStyles={{ minWidth: 290, marginRight: 12 }}
        />
        <TextInputField
          isNumber
          storeName={storeName}
          method={method}
          fieldKey="area"
          title="Area i m&sup2;"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            id__in: filteredRealEstates,
          }}
          placeholder="Välj byggnad..."
          title="Byggnad"
          method={method}
          fieldKey="building"
          TableComponent={BuildingTable}
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj hyresvärd..."
          title="Hyresvärd"
          method={method}
          fieldKey="landlord"
          TableComponent={CompanyTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj bolag för vakansbokning..."
          title="Bolag för vakansbokning"
          method={method}
          fieldKey="vacancy_booking_company"
          description="Välj vilket bolag vakanser ska bokas på."
          TableComponent={CompanyTable}
        />
      </div>

      {isAdmin && (
        <>
          <InnerBox style={{ marginBottom: 24 }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Adminfunktioner</OverviewTitle>
            </OverviewTitleWrapper>

            <TableSelectField
              storeName={storeName}
              placeholder="Välj hyresgäst..."
              title="Hyregäst"
              method={method}
              fieldKey="tenant"
              TableComponent={TenantTable}
            />

            <TableSelectField
              storeName={storeName}
              fieldKey={"bi_tenants"}
              method={method}
              title={"Ytterligare hyresgäster"}
              placeholder={"Välj hyresgäster..."}
              isMany
              TableComponent={TenantTable}
            />
          </InnerBox>
        </>
      )}

      <ManyMediaField
        storeName={storeName}
        method={method}
        fieldKey="images"
        fileKey="image"
        title="Bilder på objektet"
        allowedFormats={[".jpg", ".jpeg", ".png"]}
      />
    </Modal>
  );
}
