import { cloneDeep } from "lodash";
import {
  get,
  post,
  put,
  getAllFetchProcessName,
  destroyForm,
  addToProcess,
  updateActiveFormInstance,
} from "../../base";
import constants from "./constants";
import { store } from "../../store";
import * as services from "./services";

export const getPaymentMeansInstance = ({
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const instance = await services.getPaymentMeansInstance(creditorId);

      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );

      successCallback && successCallback(instance);
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};
