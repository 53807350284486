export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SERVICEINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SERVICEINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SERVICEINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SERVICEINVOICING",
  DESTROY_FORM: "DESTROY_FORM_SERVICEINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_SERVICEINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SERVICEINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SERVICEINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SERVICEINVOICING",
  RESET_STATE: "RESET_STATE_SERVICEINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SERVICEINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_SERVICEINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_SERVICEINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_SERVICEINVOICING",

  LIST_URL: "/accounting/contract/serviceinvoicing/list/",
  POST_URL: "/accounting/contract/serviceinvoicing/",
  PATCH_URL: "/accounting/contract/serviceinvoicing/",
  GET_URL: "/accounting/contract/serviceinvoicing/",

  STORE_NAME: "invoicingService",
};
