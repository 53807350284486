Promise.progress = async function progress(iterable, onprogress, onfailed) {
  // consume iterable synchronously and convert to array of promises
  const promises = Array.from(iterable).map(this.resolve, this);
  let resolved = 0;
  let failed = 0;

  // helper function for emitting progress events
  const progress = (increment) =>
    this.resolve(
      onprogress(
        new ProgressEvent("progress", {
          total: promises.length,
          loaded: (resolved += increment),
        })
      )
    );

  const fail = (increment) => {
    this.reject(
      onfailed(
        new ProgressEvent("progress", {
          total: promises.length,
          failed: (failed += increment),
        })
      )
    );
  };

  // lift all progress events off the stack
  await this.resolve();
  // emit 0 progress event
  await progress(0);

  // emit a progress event each time a promise resolves
  return this.all(
    promises.map((promise) =>
      promise.catch(() => fail(1)).finally(() => progress(1))
    )
  );
};
