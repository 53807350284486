export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_REPORT_ERRAND_INVOICE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_REPORT_ERRAND_INVOICE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_REPORT_ERRAND_INVOICE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_REPORT_ERRAND_INVOICE",
  DESTROY_FORM: "DESTROY_FORM_REPORT_ERRAND_INVOICE",
  SET_FORM_ERROR: "SET_FORM_ERROR_REPORT_ERRAND_INVOICE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_REPORT_ERRAND_INVOICE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_REPORT_ERRAND_INVOICE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_REPORT_ERRAND_INVOICE",
  RESET_STATE: "RESET_STATE_REPORT_ERRAND_INVOICE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_REPORT_ERRAND_INVOICE",
  REMOVE_OBJECT: "REMOVE_OBJECT_REPORT_ERRAND_INVOICE",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_REPORT_ERRAND_INVOICE",
  CLEAR_FETCHED: "CLEAR_FETCHED_REPORT_ERRAND_INVOICE",

  LIST_URL: "/accounting/contract/reporterrandinvoicing/list/",
  POST_URL: "/accounting/contract/reporterrandinvoicing/",
  PATCH_URL: "/accounting/contract/reporterrandinvoicing/",
  GET_URL: "/accounting/contract/reporterrandinvoicing/",

  STORE_NAME: "reportErrandInvoice",
};
