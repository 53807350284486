import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, TimeCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import ErrandIdTextFilter from "src/components/Lists/Base/CompleteList/Filters/ErrandIdTextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as brfDetailUrl } from "../../../store/brfPremises";
import { detailUrl as commonDetailUrl } from "../../../store/commonAreas";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import {
  constants as reportErrandConstants,
  useReportErrand,
  useReportErrandForm,
  update,
  destroyPatchForm,
  // reportErrandComponentUrl,
} from "../../../store/reportErrands";
import { detailUrl as industrialDetailUrl } from "../../../store/industrialPremises";
import {
  ErrandStatusLabel,
  StatusLabel,
} from "src/components/Lists/Base/CompleteList/styles";
import {
  getErrandStatus,
  getPerformer,
  getRequestedPerformer,
  summarizeCostRows,
} from "../../utils/errandUtils";
import { detailUrl as buildingDetailUrl } from "../../../store/buildings";
import { detailUrl as realestateDetailUrl } from "../../../store/realEstates";
import { detailUrl as userDetailUrl } from "../../../store/users";
import { useSelector } from "react-redux";

import ListTagsCell from "../../Displays/ListTagsCell";
import { toMoneyString } from "../../utils/stringUtils";

export default () => {
  // export default ({ allComponents }) => {
  return [
    {
      Header: "Status",
      // accessor: "status",
      id: "errand_status",
      Cell: ({ row }) => {
        const { status, display } = getErrandStatus({
          status: row.original?.status,
          plannedStart: row.original?.planned_start,
          plannedEnd: row.original?.planned_end,
          actualStart: row.original?.execute_start,
          actualEnd: row.original?.execute_end,
        });
        return <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },

    {
      Header: "Ärendenummer",
      accessor: "errand_id",
      id: "id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: ErrandIdTextFilter,
      filter: "text",
    },
    {
      Header: "Prioritet",
      accessor: "priority_display",

      Cell: ({ value }) => <ToolTipCell text={value ?? "Saknar prioritet"} />,
      disableFilters: true,
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Objekt",
      accessor: "connected_obj.str_representation",
      Cell: ({ row }) => {
        const obj = row.original.connected_obj;
        const detailUrl =
          obj?.content_type === "standard.apartment"
            ? apartmentDetailUrl
            : obj?.content_type === "standard.industrialpremises"
            ? indpDetailUrl
            : obj?.content_type === "standard.commonarea"
            ? commonDetailUrl
            : obj?.content_type === "standard.parkingspot"
            ? parkingDetailUrl
            : brfDetailUrl;
        return <LinkedObject obj={obj} urlMethod={detailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Objektstyp",
      accessor: "connected_obj.content_type",
      Cell: ({ value }) => {
        const str_rep =
          value === "standard.apartment"
            ? "Lägenhet"
            : value === "standard.industrialpremises"
            ? "Lokal"
            : value === "standard.commonarea"
            ? "Gemensamt utrymme"
            : value === "standard.parkingspot"
            ? "Fordonsplays"
            : value === "brf.brfpremis"
            ? "Bostadsrätt"
            : "Okänd";
        return <div>{str_rep}</div>;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Ärendetyp",
      accessor: "setting.str_representation",
      id: "errandType",
      Cell: ({ value }) => {
        return <ToolTipCell text={value} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Fel avhjälpt",
      accessor: "active_rating",
      Cell: ({ row }) => {
        const active_rating = row.original?.active_rating;
        const errandStatus = row.original?.status;

        let displayValue = "Inte utfört";
        let displayStatus = 10;
        if ([3, 4].includes(errandStatus) && active_rating) {
          displayStatus = active_rating?.is_ok ? 0 : 7;
          displayValue = active_rating?.is_ok ? "Ja" : "Nej";
        } else if (errandStatus === 1 || errandStatus === 2) {
          displayStatus = 3;
          displayValue = "Inte fastställt";
        }

        return <StatusLabel state={displayStatus}>{displayValue}</StatusLabel>;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Skapat",
      accessor: "created_at",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Vald utförare",
      accessor: "performer.str_representation",
      Cell: ({ row }) => {
        const performer = getPerformer(row.original);
        if (performer) {
          return (
            <LinkedObject
              obj={{ ...performer, id: performer.userId }}
              urlMethod={userDetailUrl}
            />
          );
        }
        return <div> Ingen utförare delegerad </div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Förfrågad utförare",
      accessor: "requested_performer.str_representation",
      Cell: ({ row }) => {
        const performer = getRequestedPerformer(row.original);
        if (performer) {
          return (
            <LinkedObject
              obj={{ ...performer, id: performer.userId }}
              urlMethod={userDetailUrl}
            />
          );
        }
        return <div>Ingen utförare förfrågad</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Rapporterat av",
      accessor: "reported_by",
      Cell: ({ value }) => {
        return <LinkedObject obj={value} urlMethod={userDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Planerat utförandedatum",
      accessor: "planned_start",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Utförandedatum",
      accessor: "execute_end",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Adress",
      accessor: "dynamic_address.base",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Taggar",
      accessor: "tags",
      Cell: ({ row }) => {
        const tags = useSelector(
          (state) =>
            state[reportErrandConstants.STORE_NAME].all[row.original?.id]?.tags
        );
        return (
          <ListTagsCell
            tags={tags}
            storeName={reportErrandConstants.STORE_NAME}
            instanceId={row?.original?.id}
            formMethod={useReportErrandForm}
            instanceMethod={useReportErrand}
            updateMethod={update}
            destroyPatchForm={destroyPatchForm}
          />
        );
      },
      // Filter: TagFilter,
      // filter: "selectMany",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];
};

//Exporting columDefs as funciton to play nicely with detailPerms logic (components/Details/Account/UserGroups/WhiteListTable.js)

export const reportErrandColumnDefs = () => {
  return [
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const { status, display } = getErrandStatus({
          status: row.original?.status,
          plannedStart: row.original?.planned_start,
          plannedEnd: row.original?.planned_end,
          actualStart: row.original?.execute_start,
          actualEnd: row.original?.execute_end,
        });
        return <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Ärendenummer",
      accessor: "errand_id",
      id: "id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: ErrandIdTextFilter,
      filter: "text",
    },
    {
      Header: "Ärendetyp",
      accessor: "setting_display",
      id: "errandType",
      Cell: ({ value }) => {
        return <ToolTipCell text={value} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fel avhjälpt",
      accessor: "active_rating",
      Cell: ({ row }) => {
        const active_rating = row.original?.active_rating;
        const errandStatus = row.original?.status;

        let displayValue = "Inte utfört";
        let displayStatus = 10;
        if (errandStatus === 3 && active_rating) {
          displayStatus = active_rating?.is_ok ? 0 : 7;
          displayValue = active_rating?.is_ok ? "Ja" : "Nej";
        } else if (errandStatus === 1 || errandStatus === 2) {
          displayStatus = 3;
          displayValue = "Inte fastställt";
        }

        return <StatusLabel state={displayStatus}>{displayValue}</StatusLabel>;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Kopplad till",
      id: "connectedTo",
      Cell: ({ row }) => {
        const { realestate, building, apartment, industrial_premises } =
          row.original.component;
        let kind = "realestate";
        let connection = realestate;
        if (apartment) {
          kind = "apartment";
          connection = apartment;
        } else if (industrial_premises) {
          kind = "industrialpremises";
          connection = industrial_premises;
        } else if (building) {
          kind = "building";
          connection = building;
        }

        switch (kind) {
          case "apartment":
            return (
              <LinkedObject obj={connection} urlMethod={apartmentDetailUrl} />
            );
          case "industrialpremises":
            return (
              <LinkedObject obj={connection} urlMethod={industrialDetailUrl} />
            );
          case "building":
            return (
              <LinkedObject obj={connection} urlMethod={buildingDetailUrl} />
            );
          case "realestate":
            return (
              <LinkedObject obj={connection} urlMethod={realestateDetailUrl} />
            );
          default:
            return "Ingen koppling hittades";
        }
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Skapat",
      accessor: "created_at",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Vald utförare",
      accessor: "performer.str_representation",
      Cell: ({ row }) => {
        const performer = getPerformer(row.original);
        if (performer) {
          return (
            <LinkedObject
              obj={{ ...performer, id: performer.userId }}
              urlMethod={userDetailUrl}
            />
          );
        }
        return <div> Ingen utförare delegerad </div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Begärda utförare",
      accessor: "requested_performer.str_representation",
      Cell: ({ row }) => {
        const performer = getRequestedPerformer(row.original);
        if (performer) {
          return (
            <LinkedObject
              obj={{ ...performer, id: performer.userId }}
              urlMethod={userDetailUrl}
            />
          );
        }
        return <div> Ingen utförare delegerad </div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Planerat utförandedatum",
      accessor: "planned_start",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Utförandedatum",
      accessor: "execute_end",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Taggar",
      accessor: "tags",
      Cell: ({ row }) => {
        const tags = useSelector(
          (state) =>
            state[reportErrandConstants.STORE_NAME].all[row.original?.id]?.tags
        );
        return (
          <ListTagsCell
            tags={tags}
            storeName={reportErrandConstants.STORE_NAME}
            instanceId={row?.original?.id}
            formMethod={useReportErrandForm}
            instanceMethod={useReportErrand}
            updateMethod={update}
            destroyPatchForm={destroyPatchForm}
          />
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Total kostnad",
      accessor: "costs",
      Cell: ({ row }) => {
        const { net, vat } = summarizeCostRows(
          row.original?.costs,
          row.original?.component
        );
        return <ToolTipCell text={toMoneyString(net + vat)} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];
};
