import * as React from "react";
import { useTransition } from "@react-spring/web";
import { useHistory } from "react-router-dom";

// style, design
import * as SC from "./styles";
import { PrimaryButton } from "../Forms/Base/Buttons";

// store, state
import { detailUrl } from "../../store/realEstates";
import { useDispatch } from "react-redux";
import { setActiveFilteredRealEstates } from "../../store/overview/store/actions";

export default ({ realEstateData }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [displayedRealEstate, setDisplayedRealEstate] = React.useState(null);
  const [displayedImage, setDisplayedImage] = React.useState(null);

  // keep UI looking OK when no premises from props
  React.useEffect(() => {
    if (realEstateData) {
      setDisplayedRealEstate(realEstateData.realEstate);
      setDisplayedImage(realEstateData.image);
    } else {
      setTimeout(() => {
        setDisplayedRealEstate(null);
        setDisplayedImage(null);
      }, 300);
    }
  }, [realEstateData]);

  const modalTransition = useTransition(!!realEstateData, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const goToRealEstate = () => {
    if (!displayedRealEstate?.id) return;
    dispatch(
      setActiveFilteredRealEstates({ realEstateIds: [displayedRealEstate?.id] })
    );
    push("/");
  };

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.RealEstateCard style={{ opacity: styles.opacity }}>
          <SC.RealEstateTitle>
            {displayedRealEstate?.str_representation}
          </SC.RealEstateTitle>

          <SC.RealEstateInfoBox>
            <SC.RealEstateImage src={displayedImage} />
            <SC.RealEstateProperties>
              <div>{displayedRealEstate?.type_code_display}</div>
              <div>
                {displayedRealEstate?.main_address?.str_representation ||
                  displayedRealEstate?.addresses?.[0]?.base}{" "}
              </div>
              <div>{displayedRealEstate?.area}&#13217;</div>
              <div>{displayedRealEstate?.owner?.str_representation}</div>
            </SC.RealEstateProperties>
          </SC.RealEstateInfoBox>

          <PrimaryButton
            newDesign
            clicked={goToRealEstate}
            title="Filtrera på fastighet"
          />
        </SC.RealEstateCard>
      )
  );
};
