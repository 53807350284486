export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BLUEPRINT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BLUEPRINT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BLUEPRINT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BLUEPRINT",
  DESTROY_FORM: "DESTROY_FORM_BLUEPRINT",
  SET_FORM_ERROR: "SET_FORM_ERROR_BLUEPRINT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BLUEPRINT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BLUEPRINT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BLUEPRINT",
  RESET_STATE: "RESET_STATE_BLUEPRINT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BLUEPRINT",
  REMOVE_OBJECT: "REMOVE_OBJECT_BLUEPRINT",
  CLEAR_FETCHED: "CLEAR_FETCHED_BLUEPRINT",

  LIST_URL: "/standard/lowlevel/blueprint/list/",
  POST_URL: "/standard/lowlevel/blueprint/",
  PATCH_URL: "/standard/lowlevel/blueprint/",
  GET_URL: "/standard/lowlevel/blueprint/",

  STORE_NAME: "blueprints",

  SET_CURRENT_BUILDING: "SET_CURRENT_BUILDING",
  SET_CURRENT_FLOOR: "SET_CURRENT_FLOOR",
};
