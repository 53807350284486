import * as React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//store, state
import { useRoundingErrand } from "../../../store/roundingErrands";
import { update as updateFault } from "../../../store/roundingErrandFaults";

import { usePermissionCheck } from "../../../store/base";

import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import MediaDisplay from "src/components/Details/Images/MediaDisplay";
import RoundingFaultTable from "src/components/Tables/RoundingFaults/FullTable";
import ReportErrandForm from "src/components/Forms/ReportErrand/ChapterForm/ModalForm";

export default function Errand() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { roundingErrandId } = useParams();

  const [createReportErrandOpen, setCreateReportErrandOpen] =
    React.useState(false);
  const [createReportErrandFault, setCreateReportErrandFault] =
    React.useState(null);

  const canReportErrand = usePermissionCheck("add_can_reporterrand");

  const [roundingErrand, roundingErrandLoading] =
    useRoundingErrand(roundingErrandId);

  return (
    <>
      {createReportErrandOpen && (
        <ReportErrandForm
          preventGoToDetail
          method="POST"
          isOpen={true}
          onCheckout={() => setCreateReportErrandOpen(false)}
          instance={{
            title: `Ronderingsavvikelse ${createReportErrandFault?.title}`,
            report_comments: [
              {
                content: createReportErrandFault?.description,
              },
            ],
            rounding_fault: {
              ...createReportErrandFault,
              str_representation: createReportErrandFault?.title,
            },
            component_placement: {
              ...createReportErrandFault?.component_placement,
              _dontClear: true,
            },
          }}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <DetailInfo
            infoObj={{
              Utfall: [
                {
                  title: "Kommentar från utförande",
                  value:
                    roundingErrand?.completion_comment ||
                    "Ingen kommentar har lämnats",
                },
              ],
            }}
          />

          <div className="text-sm font-medium mb-2">
            Dokument från utförande
          </div>

          <MediaDisplay files={roundingErrand?.completion_files} />
        </DetailPageBox>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Kontrollerade komponenter</OverviewTitle>
          </OverviewTitleWrapper>

          {!createReportErrandOpen && (
            <RoundingFaultTable
              persistantQuery={{
                roundingerrands: roundingErrandId,
              }}
              createReportErrandCallback={
                canReportErrand
                  ? (obj) => {
                      setCreateReportErrandFault(obj);
                      setCreateReportErrandOpen(true);
                    }
                  : null
              }
              onRowClicked={() => {}}
            />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
