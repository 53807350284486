import * as FileSaver from "file-saver";
import moment from "moment";
import * as XLSX from "xlsx";

import { getErrandStatus, summarizeCostRows } from "../../utils/errandUtils";

import { getAllErrandTags } from "../../../store/errandComponents";

import { constants as reportErrandConstants } from "../../../store/reportErrands";

import { renderFloors } from "../../Forms/Base/FieldComponents/FloorSelector";

import {
  toSqmString,
  toMoneyString,
  toMoneyPerSqmString,
} from "../../utils/stringUtils";

import { redirectMethodFromKind } from "../../../store/utils";

import { durationParse } from "../../Displays/utils";

import { appendQueryString, buildQueryString } from "../../../store/base";
import {
  getContractFromAndTo,
  getCostBetweenMonthDates,
  getIndexBetweenMonths,
  getMonthsForCost,
  getSpecifiedPeriodDates,
  PRODUCT_CATEGORIES,
} from "src/components/Lists/utils/costCalculations";

export const prepareFilters = (filters, instructions) => {
  if (!filters || !filters.length) {
    return "";
  }

  let result = "";

  filters.forEach((f) => {
    const instruction = instructions?.[f?.id];

    if (!instruction) {
      result = appendQueryString(
        result,
        buildQueryString({ [f.id.replace(".", "__")]: f.value })
      );
      return;
    }

    let querystring = "";
    if (instruction.handler) {
      querystring = instruction.handler(f);
    } else {
      const operator = instruction.operator;
      querystring = buildQueryString({
        [`${f.id.replace(".", "__")}__${operator}`]: f.value,
      });
    }

    result = appendQueryString(result, querystring);
  });

  return result;
};

export const prepareOrdering = (ordering) => {
  if (!ordering || !ordering.length) {
    return "";
  }

  const prepared = ordering.map((o) => {
    const key = o.id.replace(".", "__");
    return o.desc ? `-${key}` : key;
  });

  return buildQueryString({ order_by: prepared });
};

export const buildRangeFilter = (filterObject) => {
  const gte = (filterObject?.value || [])[0];
  const lte = (filterObject?.value || [])[1];
  // don't filter
  if (!gte && !lte) {
    return "";
  }

  let filter = {};
  if (gte) {
    filter[`${filterObject.id}__gte`] = gte;
  }
  if (lte) {
    filter[`${filterObject.id}__lte`] = lte;
  }
  return buildQueryString(filter);
};

export const buildManyIdFilter = (filterObject) => {
  if (!filterObject?.value?.length) {
    return "";
  }
  return buildQueryString({
    [`${filterObject.id}__id__in`]: filterObject.value.map((f) => f.id),
  });
};

const cleanKey = (key) => {
  //\ , / , * , ? , : , [ , ].
  return key
    .replaceAll("/", "_")
    .replaceAll("*", ".")
    .replaceAll("?", ".")
    .replaceAll(":", ".")
    .replaceAll("[", "(")
    .replaceAll("]", ")");
};

export const performDataExport = ({ data, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const wb = {
    Sheets: {
      export: XLSX.utils.json_to_sheet(data),
    },
    SheetNames: ["export"],
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName + fileExtension);
};

export const performExcelExport = ({ data, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let sheets = {};
  let sheetNames = [];
  Object.keys(data).forEach((k) => {
    const key = cleanKey(k);

    sheetNames.push(key);
    sheets[key] = XLSX.utils.json_to_sheet(data[k]);
  });

  const wb = { Sheets: sheets, SheetNames: sheetNames };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName + fileExtension);
};

// helper to make columns look better
function fitToColumn(arrayOfArray) {
  // get maximum character of each column
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
    ),
  }));
}

// helper function to generate 2d array from table
function tableToArray(tbl, opt_cellValueGetter) {
  opt_cellValueGetter =
    opt_cellValueGetter ||
    function (td) {
      return td.textContent || td.innerText;
    };
  var twoD = [];
  for (
    var rowCount = tbl.rows.length, rowIndex = 0;
    rowIndex < rowCount;
    rowIndex++
  ) {
    twoD.push([]);
  }
  for (var rowIndex = 0, tr; rowIndex < rowCount; rowIndex++) {
    var tr = tbl.rows[rowIndex];
    for (
      var colIndex = 0, colCount = tr.cells.length, offset = 0;
      colIndex < colCount;
      colIndex++
    ) {
      var td = tr.cells[colIndex],
        text = opt_cellValueGetter(td, colIndex, rowIndex, tbl);
      while (twoD[rowIndex].hasOwnProperty(colIndex + offset)) {
        offset++;
      }
      for (
        var i = 0, colSpan = parseInt(td.colSpan, 10) || 1;
        i < colSpan;
        i++
      ) {
        for (
          var j = 0, rowSpan = parseInt(td.rowSpan, 10) || 1;
          j < rowSpan;
          j++
        ) {
          twoD[rowIndex + j][colIndex + offset + i] = text;
        }
      }
    }
  }
  return twoD;
}

function isNumeric(n) {
  // const isNum = !isNaN(+n);
  const isNum = /^-?\d+$/.test(n);
  return isNum;
}

export const performTableToBookExcelExport = ({
  element,
  fileName,
  userStr,
}) => {
  const workbook = XLSX.utils.book_new();

  const exportInfo = [
    [""],
    [""],
    [""],
    [""],
    [""],
    [""],
    ["Export från Pigello Fastighetssystem"],
    [""],
    [""],
    ["Export skapad", moment().format("YYYY-MM-DD HH:mm")],
    ["Exporterad av", userStr || "Anonym"],
  ];

  const exportData = tableToArray(element);

  const combinedExport = [...exportData, ...exportInfo];

  // fix values not allowed in excel
  const formattedCombinedExport = combinedExport.map((row) => {
    const formattedRow = row.map((cell) => {
      if (cell == null) {
        return "";
      }

      if (cell == "-" || cell == " - " || cell == " - ") {
        return "";
      }

      let formattedNumCell = cell.replace(/\s+/g, "");
      formattedNumCell = formattedNumCell.replace(/−/g, "-");

      if (isNumeric(formattedNumCell)) {
        return parseInt(formattedNumCell);
      }

      return cell;
    });

    return formattedRow;
  });

  const ws1 = XLSX.utils.aoa_to_sheet(formattedCombinedExport);
  ws1["!cols"] = fitToColumn(formattedCombinedExport);
  XLSX.utils.book_append_sheet(workbook, ws1, "export");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  workbook.Props = {
    Title: "Pigello Export",
    Author: userStr || "Pigello",
    CreatedDate: moment(),
  };

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName + fileExtension);
};

export const performArrayDataToBookExcelExport = ({
  element,
  allColumns,
  fileName,
  userStr,
  realEstates,
  useSqm,
  tableType,
  // allComponents
}) => {
  const allTags = getAllErrandTags({ constants: reportErrandConstants });

  const workbook = XLSX.utils.book_new();

  const exportInfo = [
    [""],
    [""],
    [""],
    [""],
    [""],
    [""],
    ["Export från Pigello Fastighetssystem"],
    [""],
    [""],
    ["Export skapad", moment().format("YYYY-MM-DD HH:mm")],
    ["Exporterad av", userStr || "Anonym"],
  ];

  let exportData = [];
  // Format data into a acceptible shape:

  // Create XLSX headers first
  // Headers used to be grabbed from table DOM (old logic)
  // Now importing them directly from array
  // Grab Accessor from allColumns
  // New object of {header: accessor}

  let headers = [];
  let accessors = [];
  allColumns.map((c) => {
    if (c.Header) headers.push(c.Header);
    if (c.Header) {
      accessors.push({
        [c.Header]: [c.accessor ? c.accessor : "", c.id ? c.id : ""],
      });
    }
  });
  exportData.push(headers);

  let statusMap = ["Nuvarande hyresgäst", "Kommande", "Potentiell", "Tidigare"];

  if (tableType === "leaseContracts") {
    statusMap = [
      "Aktiv",
      "Tillsvidare",
      "Utgående",
      "Kommande",
      "Ej signerat",
      "Uppsagt för utflytt",
      "Uppsagt för förnyelse",
      "Ointressant",
      "Tidigare avtal",
      "Okänt",
    ];
  }

  // We are exporting entire list
  const useSpecifiedPeriod = false;
  const showMonthlyCosts = false;

  // index, brent, brent increase, proptax, parkeringar, deposit
  const nonPositiveInclusion = [
    PRODUCT_CATEGORIES.CATEGORY_INDEX,
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
    PRODUCT_CATEGORIES.CATEGORY_PROP_TAX,
    PRODUCT_CATEGORIES.CATEGORY_PARKING,
    PRODUCT_CATEGORIES.CATEGORY_DEPOSIT,
  ];

  const baseRentinclusion = [
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
  ];

  const start = moment({ year: moment().year(), month: 0, day: 1 }).format(
    "YYYY-MM"
  );
  const end = moment({ year: moment().year(), month: 11, day: 31 }).format(
    "YYYY-MM"
  );

  element.map((e) => {
    let newElement = [];

    headers.map((h) => {
      // We are using headers array because of order and sometimes we don't have an accessor
      // Header is a key in accessors array objects
      const accessor = accessors.find((a) => a[h])[h];

      // h could be a path like user.phone or a function
      if (accessor[0] && typeof accessor[0] === "string") {
        const path = accessor[0].split(".");

        let value = e[path[0]];
        if (accessor[0] === "notify_interval" && value) {
          const parsed = durationParse(value);
          if (parsed === undefined) {
            value = "";
          } else {
            const shadowDate = e.notify_time;
            if (!shadowDate) {
              value = parsed.presentation + " " + parsed.type;
            } else {
              const shadowTime = shadowDate
                ? moment(shadowDate).format("YYYY-MM-DD")
                : "-";
              value =
                parsed.presentation + " " + parsed.type + " " + shadowTime;
            }
          }
        }

        if (accessor[0] === "renew_interval" && value) {
          const forever = e.renew_indefinetely;
          if (forever) {
            value = "Tillsvidare";
          } else {
            const parsed = durationParse(value);
            if (parsed === undefined) {
              value = "";
            } else {
              value = parsed.presentation + " " + parsed.type;
            }
          }
        }

        if (Array.isArray(value)) {
          // In case of bi_tenants we have an array as value
          value = value.map((r) => r.str_representation).join("");
        } else if (path.length > 1 && typeof value === "object") {
          // We got the case where accessor is: block.str_representation and in data we have e.block = null
          if (value === null) {
            value = "";
          } else {
            value = value[path[1]];
            value =
              accessor[0] === "user.is_active"
                ? value
                  ? "Aktiv"
                  : "Inaktiv"
                : value;
          }
        } else if (value === null) {
          if (accessor[0] === "closed_date") {
            if (e.closed_date) {
              value = e.closed_date;
            } else if (e.renewed_to) {
              value = e.renewed_to + " (Förlängt)";
            } else if (e.end_date) {
              value = e.end_date;
            } else {
              value = "";
            }
          } else {
            value = "";
          }
        }

        if (
          tableType === "leaseContractDocs" ||
          tableType === "otherContractDocs" ||
          tableType === "parkingContractDocs"
        ) {
          if (accessor[0] === "status")
            value = e.status_display ? e.status_display : "-";
          if (accessor[0] === "inhouse_time")
            value = e.inhouse_time
              ? moment(e.inhouse_time).format("YYYY-MM-DD")
              : "-";
        }

        if (tableType === "leaseContracts") {
          if (accessor[0] === "state") {
            const intValue = value;
            value = statusMap[intValue - 1];

            if (
              [6, 7].includes(intValue) &&
              !e.closed_signed &&
              e.uses_cancellation_e_signing
            ) {
              value += ` (Ej signerat)`;
            }
            const isDraft = e.draft;

            if (isDraft) {
              value = "Utkast";
            }
          }
        } else if (
          tableType === "otherContracts" ||
          tableType === "parkingContracts" ||
          tableType === "apartments" ||
          tableType === "industrialPremises" ||
          tableType === "reportErrands"
        ) {
          if (accessor[0] === "state") {
            let label = e.state_display;

            const state = e.state;
            if (
              [6, 7].includes(state) &&
              !e.closed_signed &&
              e.uses_cancellation_e_signing
            ) {
              label += ` (Ej signerat)`;
            } else {
              label += e.subletted ? " (andrahand)" : "";
            }

            if (e.draft) {
              label = "Utkast";
            }

            value = label;
          }
          if (accessor[0] === "tenant") {
            const obj = e.tenant;
            let tenant_val = "";
            if (!obj && !obj?.id) {
              tenant_val = "-";
            } else {
              let [fetchedObj] = typeof obj === "function" ? obj() : [obj];

              if (!fetchedObj) {
                tenant_val = "-";
              } else {
                tenant_val = fetchedObj.str_representation
                  ? fetchedObj.str_representation
                  : "-";
              }
            }

            value = tenant_val;
          }
          if (accessor[0] === "other_invoicing_active") {
            const billWithLease = e.other_invoicing_bill_with_lease;
            let bill_val = "";
            if (billWithLease) {
              bill_val = "Samaviseras";
            } else {
              bill_val =
                e.other_invoicing_active === true
                  ? "Konfigurerad"
                  : e.other_invoicing_active === null
                  ? "Ej konfigurerad"
                  : e.other_invoicing_activeue === false
                  ? "Pausad"
                  : "Okänd";
            }

            value = bill_val;
          }

          if (accessor[0] === "parking_invoicing_active") {
            const billWithLease = e.parking_invoicing_bill_with_lease;

            if (billWithLease) {
              value = "Samaviseras";
            } else {
              value =
                e.parking_invoicing_active === true
                  ? "Konfigurerad"
                  : e.parking_invoicing_active === null
                  ? "Ej konfigurerad"
                  : e.parking_invoicing_active === false
                  ? "Pausad"
                  : "Okänd";
            }
          }

          if (accessor[0] === "dynamic_floors") {
            value = e.dynamic_floors ? e.dynamic_floors.join(", ") : "-";
          }

          if (accessor[0] === "category") {
            value = e.category_display;
          }

          if (accessor[0] === "base_rent_year") {
            value = e.base_rent ? e.base_rent * 12 : "0";
          }

          if (accessor[0] === "leasecontract_amount") {
            value = e.leasecontract_amount
              ? `${e.leasecontract_amount} st`
              : "0 st";
          }

          if (accessor[0] === "content_type") {
            const contentType = e.content_type;
            const id = e.object_id;

            const urlMethod = redirectMethodFromKind(contentType);

            value = "-";

            // Fix this later,  fetchSingleMethodFromKind is a hook, cannot be called from here...

            // const objMethod = fetchSingleMethodFromKind(contentType);

            // const [obj, isLoading] = objMethod ? objMethod(id) : [undefined, false];

            // if (!contentType || !id || !obj) {
            //   value = "-";
            // }else{
            //   if (!urlMethod) {
            //     value = "-";
            // }else{
            //   value = obj.str_representation
            // }
            // }
          }

          if (accessor[0] === "main_contract") {
            const obj = e.main_contract;
            value = obj ? "Visa" : "-";
          }

          if (accessor[0] === "closed_date") {
            const closedDate = e.closed_date;
            const renewedTo = e.renewed_to;
            const endDate = e.end_date;

            if (closedDate) {
              value = closedDate;
            } else {
              if (renewedTo) {
                value = renewedTo + " (Förlängt)";
              } else {
                if (endDate) {
                  value = endDate;
                }
              }
            }

            if (!value) value = "-";
          }
        } else {
          value = accessor[0] === "state" ? statusMap[value] : value;
        }

        if (tableType === "industrialPremises") {
          if (
            accessor[0] === "realestate.str_representation" ||
            accessor[0] === "realestate"
          ) {
            value = e.realestate ? e.realestate.str_representation : "-";
          }
          if (accessor[0] === "tenant.str_representation") {
            value = e.tenant?.str_representtion
              ? e.tenant.str_representation
              : "-";
          }
          if (accessor[0] === "dynamic_floors") {
            value = renderFloors(e.dynamic_floors);
          }

          if (accessor[0] === "area") {
            value = toSqmString(e.area);
          }

          if (accessor[0] === "base_rent") {
            value = e.base_rent_is_m2
              ? toMoneyPerSqmString(e.base_rent)
              : toMoneyString(e.base_rent);
          }
          if (accessor[0] === "base_rent_year") {
            const base_rent = e.base_rent * 12;

            value = e.base_rent_is_m2
              ? toMoneyPerSqmString(base_rent)
              : toMoneyString(base_rent);
          }
        }

        if (accessor[0] === "industrial_premises_list") {
          const searchStrArr = [];

          const apartments = e.apartments;
          const industrials = e.industrial_premises_list;

          if (apartments?.length) {
            apartments.forEach((a) => {
              searchStrArr.push(a.str_representation);
            });
          }

          if (industrials?.length) {
            industrials.forEach((a) => {
              searchStrArr.push(a.str_representation);
            });
          }

          value = searchStrArr.join(", ");
        }

        value =
          accessor[0] === "debtor_invoice_config"
            ? value
              ? "Konfigurerad"
              : "Ej Konfigurerad"
            : value;
        value =
          accessor[0] === "lease_invoicing_active"
            ? value === true
              ? "Konfigurerad"
              : value === null
              ? "Ej konfigurerad"
              : value === false
              ? "Pausad"
              : "Okänd"
            : value;

        if (accessor[0] === "user.realestates") {
          const rs = realEstates?.filter((b) => {
            return value?.some((r) => r.id === b.id);
          });
          value = rs?.map((r) => r.str_representation)?.join(", ");
        }

        if (
          tableType === "reportErrands" ||
          tableType === "inspectionErrands"
        ) {
          if (accessor[0] === "status") {
            const { status, display } = getErrandStatus({
              status: e.status,
              plannedStart: e.planned_start,
              plannedEnd: e.planned_end,
              actualStart: e.execute_start,
              actualEnd: e.execute_end,
            });

            value = display;
          }
          if (accessor[0] === "active_rating") {
            const active_rating = e.active_rating;
            const errandStatus = e.status;

            let displayValue = "Inte utfört";
            let displayStatus = 10;
            if (errandStatus === 3 && active_rating) {
              displayStatus = active_rating?.is_ok ? 0 : 7;
              displayValue = active_rating?.is_ok ? "Ja" : "Nej";
            } else if (errandStatus === 1 || errandStatus === 2) {
              displayStatus = 3;
              displayValue = "Inte fastställt";
            }

            value = displayValue;
          }

          if (accessor[0] === "reported_by") {
            // This is a user id, we need tenant connected to the user,
            // Originally hook is used to fetch the user, we can't use hook here

            value = "-";

            // const [user] = useUser(e.reported_by?.id);
            // if (user?.tenant) {
            //   value = user? user.str_representation: "-";
            // }
          }

          if (accessor[0] === "planned_start") {
            value = e.planned_start
              ? moment(e.planned_start).format("YYYY-MM-DD, HH:mm")
              : "-";
          }
          if (accessor[0] === "execute_end") {
            value = e.execute_end
              ? moment(e.execute_end).format("YYYY-MM-DD, HH:mm")
              : "-";
          }
          if (accessor[0] === "tags") {
            value = "";
            const tags = allTags[e.id]?.tags;

            tags?.length
              ? tags.map((t, index) => {
                  value += t.title;
                  if (index < tags.length - 1) value += ", ";
                })
              : (value = "-");
          }

          if (accessor[0] === "costs") {
            const { net, vat } = summarizeCostRows(e.costs, e.component);

            value = Math.round(net + vat).toLocaleString("sv");
          }

          if (accessor[0] === "setting") {
            value = "-";

            // useInspectionErrandSetting is using hook...
            // const setting = useInspectionErrandSetting(e.setting?.id);

            // value = setting?.str_representation;
          }
        }

        if (typeof value !== "string") {
          value = value ? value.toString() : "-";
        }

        newElement.push(value);
      } else {
        // Here we don't have an accessor and that means we have to calculate the value
        // accessor[1] is the id
        if (accessor[1].includes("product_title_")) {
          const keyMapping = parseInt(
            accessor[1].replace("product_title_", "")
          );
          const { contractFrom, contractTo } = getContractFromAndTo(e);

          const { specifiedStartDate, specifiedEndDate } =
            getSpecifiedPeriodDates({
              start,
              end,
              contract: e,
            });

          const filteredCosts = getCostBetweenMonthDates(
            e.cost_set ? e.cost_set : e.costs,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo
          );

          const totalArea = e.total_area || 1; // 1 if no area to avoid Infinity

          let total = filteredCosts.reduce((acc, curr) => {
            const months = getMonthsForCost(
              curr,
              useSpecifiedPeriod ? specifiedStartDate : contractFrom,
              useSpecifiedPeriod ? specifiedEndDate : contractTo,
              useSpecifiedPeriod
            );

            const productCategory = curr.product_category
              ? curr.product_category
              : curr.product?.category
              ? curr.product.category
              : undefined;

            return (
              acc +
              (productCategory === keyMapping && curr.value >= 0
                ? (curr.value *
                    curr.unit_amount *
                    (showMonthlyCosts ? 1 : months)) /
                  (useSqm ? totalArea : 1)
                : 0)
            );
          }, 0);

          if (typeof total !== "string") {
            // Get rid of the decimal part
            total = parseInt(Math.round(total));
            total = total.toString();
          }
          newElement.push(total);
        } else if (accessor[1].includes("discounts")) {
          const { contractFrom, contractTo } = getContractFromAndTo(e);

          const { specifiedStartDate, specifiedEndDate } =
            getSpecifiedPeriodDates({
              start,
              end,
              contract: e,
            });

          const filteredCosts = getCostBetweenMonthDates(
            e.cost_set ? e.cost_set : e.costs,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo
          );

          const totalArea = e.total_area || 1; // 1 if no area to avoid Infinity

          let total = filteredCosts.reduce((acc, curr) => {
            const months = getMonthsForCost(
              curr,
              useSpecifiedPeriod ? specifiedStartDate : contractFrom,
              useSpecifiedPeriod ? specifiedEndDate : contractTo,
              useSpecifiedPeriod
            );
            return (
              acc +
              (curr.value < 0
                ? (curr.value *
                    curr.unit_amount *
                    (showMonthlyCosts ? 1 : months)) /
                  (useSqm ? totalArea : 1)
                : 0)
            );
          }, 0);

          if (typeof total !== "string") {
            // Get rid of the decimal part
            total = parseInt(Math.round(total));
            total = total.toString();
          }
          newElement.push(total);
        } else if (accessor[1].includes("addons")) {
          const { contractFrom, contractTo } = getContractFromAndTo(e);

          const { specifiedStartDate, specifiedEndDate } =
            getSpecifiedPeriodDates({
              start,
              end,
              contract: e,
            });

          const filteredCosts = getCostBetweenMonthDates(
            e.cost_set ? e.cost_set : e.costs,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo
          );

          const totalArea = e.total_area || 1; // 1 if no area to avoid Infinity

          let total = filteredCosts.reduce((acc, curr) => {
            const months = getMonthsForCost(
              curr,
              useSpecifiedPeriod ? specifiedStartDate : contractFrom,
              useSpecifiedPeriod ? specifiedEndDate : contractTo,
              useSpecifiedPeriod
            );

            const productCategory = curr.product_category
              ? curr.product_category
              : curr.product?.category
              ? curr.product.category
              : undefined;

            return (
              acc +
              (curr.value >= 0 &&
              (productCategory === null ||
                !nonPositiveInclusion.includes(productCategory))
                ? (curr.value *
                    curr.unit_amount *
                    (showMonthlyCosts ? 1 : months)) /
                  (useSqm ? totalArea : 1)
                : 0)
            );
          }, 0);

          if (typeof total !== "string") {
            // Get rid of the decimal part
            total = parseInt(Math.round(total));
            total = total.toString();
          }
          newElement.push(total);
          // }
        } else if (accessor[1] === "baseRent") {
          const { contractFrom, contractTo } = getContractFromAndTo(e);

          const { specifiedStartDate, specifiedEndDate } =
            getSpecifiedPeriodDates({
              start,
              end,
              contract: e,
            });

          const filteredCosts = getCostBetweenMonthDates(
            e.cost_set ? e.cost_set : e.costs,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo
          );

          const totalArea = e.total_area || 1; // 1 if no area to avoid Infinity

          let total = filteredCosts.reduce((acc, curr) => {
            const months = getMonthsForCost(
              curr,
              useSpecifiedPeriod ? specifiedStartDate : contractFrom,
              useSpecifiedPeriod ? specifiedEndDate : contractTo,
              useSpecifiedPeriod
            );

            const productCategory = curr.product_category
              ? curr.product_category
              : curr.product?.category
              ? curr.product.category
              : undefined;

            return (
              acc +
              (baseRentinclusion.includes(productCategory) && curr.value >= 0
                ? (curr.value *
                    curr.unit_amount *
                    (showMonthlyCosts ? 1 : months)) /
                  (useSqm ? totalArea : 1)
                : 0)
            );
          }, 0);

          if (typeof total !== "string") {
            // Get rid of the decimal part
            total = parseInt(Math.round(total));

            total = total.toString();
          }
          newElement.push(total);
        } else if (accessor[1].includes("baseRentIndex")) {
          const { contractFrom, contractTo } = getContractFromAndTo(e);

          const { specifiedStartDate, specifiedEndDate } =
            getSpecifiedPeriodDates({
              start,
              end,
              contract: e,
            });

          let indexValue = getIndexBetweenMonths(
            e.indexations,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo
          );

          if (typeof indexValue === "string") {
            newElement.push(indexValue);
          } else {
            const filteredCosts = getCostBetweenMonthDates(
              e.cost_set ? e.cost_set : e.costs,
              useSpecifiedPeriod ? specifiedStartDate : contractFrom,
              useSpecifiedPeriod ? specifiedEndDate : contractTo
            );

            const monthsCount = useSpecifiedPeriod
              ? specifiedEndDate.diff(specifiedStartDate, "months") + 1
              : contractTo.diff(contractFrom, "months") + 1;

            const totalArea = e.total_area || 1; // 1 if no area to avoid Infinity

            let total =
              filteredCosts.reduce((acc, curr) => {
                const months = getMonthsForCost(
                  curr,
                  useSpecifiedPeriod ? specifiedStartDate : contractFrom,
                  useSpecifiedPeriod ? specifiedEndDate : contractTo,
                  useSpecifiedPeriod
                );

                const productCategory = curr.product_category
                  ? curr.product_category
                  : curr.product?.category
                  ? curr.product.category
                  : undefined;

                return (
                  acc +
                  (baseRentinclusion.includes(productCategory) &&
                  curr.value >= 0
                    ? (curr.value *
                        curr.unit_amount *
                        (showMonthlyCosts ? 1 : months)) /
                      (useSqm ? totalArea : 1)
                    : 0)
                );
              }, 0) +
              indexValue /
                (useSqm ? totalArea : 1) /
                (showMonthlyCosts ? monthsCount : 1);

            if (typeof total !== "string") {
              // Get rid of the decimal part
              total = parseInt(Math.round(total));
              total = total.toString();
            }
            newElement.push(total);
          }
        } else if (accessor[1].includes("indexRent")) {
          const { contractFrom, contractTo } = getContractFromAndTo(e);

          const { specifiedStartDate, specifiedEndDate } =
            getSpecifiedPeriodDates({
              start,
              end,
              contract: e,
            });

          const indexValue = getIndexBetweenMonths(
            e.indexations,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo
          );

          const months = useSpecifiedPeriod
            ? specifiedEndDate.diff(specifiedStartDate, "months") + 1
            : contractTo.diff(contractFrom, "months") + 1;

          const totalArea = e.total_area || 1; // 1 if no area to avoid Infinity

          if (typeof indexValue === "string") {
            newElement.push(indexValue);
          } else {
            let calculatedIndexValue =
              indexValue /
              (useSqm ? totalArea : 1) /
              (showMonthlyCosts ? months : 1);

            if (typeof calculatedIndexValue !== "string") {
              // Get rid of the decimal part
              calculatedIndexValue = parseInt(Math.round(calculatedIndexValue));
              calculatedIndexValue = calculatedIndexValue.toString();
            }
            newElement.push(calculatedIndexValue);
          }
        } else if (accessor[1] === "parking_spots") {
          let mapping = [];
          const parkingSpots = e?.parking_spots;

          newElement.push("-");
        } else if (
          accessor[1] === "connectedTo" &&
          tableType === "inspectionErrands"
        ) {
          const components = e.components;
          let connection = "-";
          if (components?.length > 0) {
            components.forEach((c) => {
              const { realestate, building, apartment, industrial_premises } =
                c;
              if (apartment) {
                connection = apartment;
                return;
              } else if (industrial_premises) {
                connection = industrial_premises;
                return;
              } else if (building) {
                connection = building;
                return;
              } else if (realestate) {
                connection = realestate;
                return;
              }
            });
          }

          newElement.push(connection);
        }
      }
    });
    exportData.push(newElement);
  });

  const combinedExport = [...exportData, ...exportInfo];

  // fix values not allowed in excel
  const formattedCombinedExport = combinedExport.map((row) => {
    const formattedRow = row.map((cell) => {
      if (cell == null) {
        return "";
      }

      if (cell == "-" || cell == " - " || cell == " - ") {
        return "";
      }

      let formattedNumCell = cell.replace(/\s+/g, "");
      formattedNumCell = formattedNumCell.replace(/−/g, "-");

      if (isNumeric(formattedNumCell)) {
        return parseInt(formattedNumCell);
      }

      return cell;
    });

    return formattedRow;
  });

  const ws1 = XLSX.utils.aoa_to_sheet(formattedCombinedExport);
  ws1["!cols"] = fitToColumn(formattedCombinedExport);
  XLSX.utils.book_append_sheet(workbook, ws1, "export");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  workbook.Props = {
    Title: "Pigello Export",
    Author: userStr || "Pigello",
    CreatedDate: moment(),
  };

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName + fileExtension);
};
