import * as React from "react";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import { AddressField } from "../Base/Fields";
import M2mField from "../Base/Fields/M2mField";
import TableSelectField from "../Base/Fields/TableSelectField";
import TextInputField from "../Base/Fields/TextInputField";

export default function TenantNestedChildren({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
  isMember,
}) {
  return (
    <>
      <div className="mb-6 text-base font-medium">
        {isMember ? "Medlem" : "Hyresgäst"}
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          description="OBS: Anges endast vid företagshyresgäst/medlem."
          title="Bolagsnamn"
          fieldKey={`${parentPath}.user.corporate_name`}
          instructionsKey={`${parentInstructionsPath}.user.corporate_name`}
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          title={"Förnamn"}
          fieldKey={`${parentPath}.user.first_name`}
          instructionsKey={`${parentInstructionsPath}.user.first_name`}
          method={method}
        />
        <TextInputField
          storeName={storeName}
          title={"Efternamn"}
          fieldKey={`${parentPath}.user.last_name`}
          instructionsKey={`${parentInstructionsPath}.user.last_name`}
          method={method}
        />

        <TextInputField
          storeName={storeName}
          fieldKey={`${parentPath}.user.email`}
          instructionsKey={`${parentInstructionsPath}.user.email`}
          title="E-post"
          method={method}
        />
        <TextInputField
          storeName={storeName}
          title="Telefonnummer"
          fieldKey={`${parentPath}.user.phone`}
          instructionsKey={`${parentInstructionsPath}.user.phone`}
          method={method}
        />
        <TextInputField
          storeName={storeName}
          fieldKey={`${parentPath}.user.legal_id`}
          instructionsKey={`${parentInstructionsPath}.user.legal_id`}
          title={"Personnummer/Organisationsnummer"}
          method={method}
        />

        <TextInputField
          title={isMember ? "Medlemsnummer" : "Hyresgästnummer"}
          storeName={storeName}
          fieldKey={`${parentPath}.customer_id`}
          instructionsKey={`${parentInstructionsPath}.customer_id`}
          method={method}
        />

        <TableSelectField
          storeName={storeName}
          title="Tillhör fastigheter"
          placeholder="Välj fastigheter..."
          method={method}
          fieldKey={`${parentPath}.user.realestates`}
          instructionsKey={`${parentInstructionsPath}.user.realestates`}
          TableComponent={RealEstateTable}
          isMany
        />
      </div>

      <hr />
      <div className="mb-6 text-base font-medium">
        Ytterligare kontaktuppgifter
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <M2mField
          title="Kontaktpersoner"
          fieldKey={`${parentPath}.contact_persons`}
          instructionsKey={`${parentInstructionsPath}.contact_persons`}
          method={method}
          storeName={storeName}
          instanceTitle="kontaktperson"
        >
          {(parentPath, instructionsKey) => (
            <>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <TextInputField
                  title="Titel"
                  storeName={storeName}
                  fieldKey={`${parentPath}.title`}
                  method={method}
                  instructionsKey={`${instructionsKey}.title`}
                />

                <TextInputField
                  title="Namn"
                  storeName={storeName}
                  fieldKey={`${parentPath}.name`}
                  method={method}
                  instructionsKey={`${instructionsKey}.name`}
                />

                <TextInputField
                  title="Telefon"
                  storeName={storeName}
                  fieldKey={`${parentPath}.phone`}
                  method={method}
                  instructionsKey={`${instructionsKey}.phone`}
                />

                <TextInputField
                  title="E-post"
                  storeName={storeName}
                  fieldKey={`${parentPath}.email`}
                  type="email"
                  method={method}
                  instructionsKey={`${instructionsKey}.email`}
                />
              </div>
            </>
          )}
        </M2mField>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <AddressField
          fieldKey={`${parentPath}.separate_address`}
          instructionsKey={`${parentInstructionsPath}.separate_address`}
          {...{
            storeName,
            title: "Övrig adress",
            method,
          }}
        />
      </div>
    </>
  );
}
