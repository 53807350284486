import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import moment from "moment";
import { useFilteredMeasureValues } from "../../../store/IMDMeasureValue";
import { useFilteredSensors } from "../../../store/IMDSensor";

import { buildQueryString } from "../../../store/base";
import SensorTable from "../../../components/Tables/Sensors/FullTable";
import SensorGraphs from "../../IMD/SensorGraphs";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

const TABS = {
  GRAPH: "Grafer",
  SENSORS: "Sensorer",
};

export default function IndustrialPremisesIMD() {
  const { industrialPremisesId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState("GRAPH");

  const persistantQuery = industrialPremisesId
    ? { industrial_premises: industrialPremisesId }
    : null;
  const filterMethod = (obj) =>
    industrialPremisesId &&
    obj?.industrial_premises?.id === parseInt(industrialPremisesId);

  const [sensors, sensorsLoading] = useFilteredSensors(
    !industrialPremisesId
      ? null
      : buildQueryString({ industrial_premises: industrialPremisesId })
  );

  const startDay = moment().subtract(90, "days");
  const [measureValues, measuresLoading] = useFilteredMeasureValues(
    !industrialPremisesId || sensorsLoading || !sensors
      ? null
      : buildQueryString({
          sensor__in: sensors.map((v) => v.id),
          for_date__gte: `${startDay.format("YYYY-MM-DD")} 00:00:00`,
        })
  );

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>IMD</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>
        {selectedTab === "SENSORS" && (
          <SensorTable persistantQuery={persistantQuery} />
        )}

        {selectedTab === "GRAPH" && (
          <>
            {measureValues?.length > 0 ? (
              <SensorGraphs sensors={sensors} measureValues={measureValues} />
            ) : (
              <BodyText>
                Inga mätvärden registrerade på sensorer kopplade till detta
                objekt
              </BodyText>
            )}
          </>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
