import * as React from "react";

export default ({ isManualSign }) => {
  return (
    <div>
      Kontrollera att uppgifterna stämmer. Tryck på "Spara" för att gå vidare.
      <br />
      <br />
      {!isManualSign &&
        "I nästa steg kommer det digitala dokumentet för signering att sättas upp."}
    </div>
  );
};
