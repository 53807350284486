export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SERVICEPARTNER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SERVICEPARTNER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SERVICEPARTNER",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SERVICEPARTNER",
  DESTROY_FORM: "DESTROY_FORM_SERVICEPARTNER",
  SET_FORM_ERROR: "SET_FORM_ERROR_SERVICEPARTNER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SERVICEPARTNER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SERVICEPARTNER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SERVICEPARTNER",
  RESET_STATE: "RESET_STATE_SERVICEPARTNER",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SERVICEPARTNER",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_SERVICEPARTNER",
  CLEAR_FETCHED: "CLEAR_FETCHED_SERVICEPARTNER",

  LIST_URL: "/standard/service/servicepartner/list/",
  POST_URL: "/standard/service/servicepartner/",
  PATCH_URL: "/standard/service/servicepartner/",
  GET_URL: "/standard/service/servicepartner/",

  STORE_NAME: "servicePartners",
};
