import * as React from "react";
import { useDispatch } from "react-redux";

import { cloneDeep } from "lodash";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import LeaseContractsTable from "src/components/Tables/LeaseContracts/FullTableNewVersion";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import useActiveFilters from "src/hooks/useActiveFilters";
import { useParkingInvoicing } from "src/store/invoicingParking";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import {
  constants,
  destroyPatchForm,
  update,
  useParkingContract,
  useParkingContractForm,
} from "../../../../store/parkingContracts";
import GenericField from "../../Base/FieldComponents/GenericField";
import { MonthInput, RadioGroup } from "../../Base/Fields";
import DateSelect from "../../Base/Fields/DateSelect";
import SelectField from "../../Base/Fields/SelectField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import TextInputField from "../../Base/Fields/TextInputField";
import Modal from "../../Base/Modals/Modal";

export default function UpdateParkingContractModalForm({
  id,
  onCheckout,

  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useParkingContractForm(method, id));

  const { filteredRealEstates } = useActiveFilters();

  const [parkingContract] = useParkingContract(id);
  const [invoicingObj] = useParkingInvoicing(
    parkingContract?.parking_invoicing?.id
  );

  const endDate = useFormInstanceField({
    storeName,
    fieldKey: "end_date",
  });

  const renewIndefinetely = useFormInstanceField({
    storeName,
    fieldKey: "renew_indefinetely",
  });

  const mainContract = useFormInstanceField({
    storeName,
    fieldKey: "main_contract",
  });

  const persistantQuery = {
    realestate__in: filteredRealEstates,
    draft: false,
  };

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: {
            ...instance,
            renew_indefinetely:
              instance.renew_indefinetely === false &&
              instance.renew_interval == null
                ? "no_renewal"
                : instance.renew_indefinetely,
          },
        })
      );
    }
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (dataClone.renew_indefinetely === "no_renewal") {
      dataClone.renew_indefinetely = false;
      dataClone.renew_interval = null;
    }

    if (!dataClone.main_contract) {
      dataClone.main_contract = null;
    }
    delete dataClone.editabledoc;

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        preProcess,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title="Uppdatera avtal"
      closeFunction={onDone}
      onAccept={onSubmit}
      acceptTitle="Spara"
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Avtals-ID"
          description="Ange det interna ID:t för avtalet"
          storeName={storeName}
          fieldKey="id_number"
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          disabled={invoicingObj?.bill_with_lease}
          storeName={storeName}
          placeholder="Välj fastighet..."
          title="Fastighet som avtalet ligger under"
          description="Ange vilken fastighet som avtalet ligger under. Om ett huvudavtal väljs så krävs ej detta fält"
          method={method}
          fieldKey="realestate"
          TableComponent={RealEstateTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Status på avtalet"
          storeName={storeName}
          method={method}
          fieldKey="status"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          disabled={invoicingObj?.bill_with_lease}
          storeName={storeName}
          method={method}
          persistantQuery={persistantQuery}
          title="Koppla till huvudavtal"
          description={
            invoicingObj?.bill_with_lease
              ? 'OBS: Huvudavtal kan ej redigeras om aviseringsinställningen är inställd på "Samavisering"'
              : ""
          }
          placeholder="Välj huvudavtal..."
          fieldKey="main_contract"
          TableComponent={LeaseContractsTable}
        />
      </div>

      {!mainContract && (
        <GenericField
          storeName={storeName}
          method={method}
          ctFieldKey="content_type"
          idFieldKey="object_id"
          realEstateIds={filteredRealEstates}
        />
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          title="Startdatum"
          description="Datum när avtalet träder i kraft"
          storeName={storeName}
          fieldKey="start_date"
          method={method}
        />

        <DateSelect
          storeName={storeName}
          fieldKey="end_date"
          title="Slutdatum"
          description="Lämna tomt om avtalet är tillsvidare"
          method={method}
        />
      </div>

      <MonthInput
        title="Uppsägningstid"
        storeName={storeName}
        fieldKey="notify_interval"
        method={method}
      />

      {endDate && (
        <RadioGroup
          storeName={storeName}
          fieldKey="renew_indefinetely"
          title="Förlängning vid passerat slutdatum"
          description="Välj hur ett passerat slutdatum utan att avtalet har sagts upp ska hanteras."
          method={method}
          forceHorizontalToolTipPlacement="left"
          forceVerticalToolTipPlacement="top"
          defaultValue={false}
          options={[
            {
              label:
                "Förläng med vald förlängningstid när slutdatumet passeras",
              value: false,
            },
            {
              label: "Förläng på obestämd tid när slutdatumet passeras",
              value: true,
            },
            {
              label:
                "Förläng ej när slutdatumet passeras (kräver uppsägningstid)",
              value: "no_renewal",
            },
          ]}
        />
      )}

      {!!endDate && !renewIndefinetely && (
        <MonthInput
          storeName={storeName}
          title="Förlängningstid"
          description="Ange i hur långa intervall avtalet förlängs"
          fieldKey="renew_interval"
          method={method}
        />
      )}

      <MonthInput
        title="Varna så här lång tid innan avtalet går ut "
        description="Pigello skickar en notis om att avtalet är på väg att gå ut så här lång tid innan slutdatum + uppsägningstid"
        storeName={storeName}
        fieldKey="warning_interval"
        method={method}
      />
    </Modal>
  );
}
