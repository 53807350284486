import * as React from "react";

import { CATEGORIES } from "../utils";

import { categoriesToStructures } from "../ReportsOverviewTable";
import { useLocation } from "react-router-dom";

import { pathing, BASE_PATH, DEFAULT_PATHNAME } from "../ReportsOverviewTable";

import Badge, { BADGE_TYPES } from "../../../components/Badge/Badge";

import { useSpring, config, animated } from "@react-spring/web";

const LoadingBadge = ({ index }) => {
  const [showing, setShowing] = React.useState(false);
  const showingRef = React.useRef(false);

  React.useEffect(() => {
    showingRef.current = showing;
  }, [showing]);

  const styles = useSpring({
    config: { duration: 300, ...config.gentle },
    opacity: showing ? 0 : 1,
  });

  const switchShow = React.useCallback(() => {
    setShowing(!showingRef.current);
  }, [showing]);

  React.useEffect(() => {
    let interval;

    let timeout = setTimeout(() => {
      interval = setInterval(switchShow, 600);
    }, (index + 1) * 100);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  return (
    <animated.div style={styles}>
      <Badge
        className="border border-solid border-transparent w-16 "
        type={BADGE_TYPES.GRAY}
      >
        <p className="opacity-0">loading</p>
      </Badge>
    </animated.div>
  );
};

const StandardBadge = ({ index, onClick, catKey, currentBadgeFilter }) => {
  const [showing, setShowing] = React.useState(false);

  const styles = useSpring({
    config: { duration: 300, ...config.gentle },
    opacity: showing ? 1 : 0,
  });

  React.useEffect(() => {
    setTimeout(() => {
      setShowing(true);
    }, (index + 1) * 100);
  }, []);

  return (
    <animated.div style={styles} className="cursor-pointer">
      {CATEGORIES[catKey].badge(currentBadgeFilter === catKey, false, onClick)}
    </animated.div>
  );
};

export const useBadBadgeFilter = ({
  pageQueryInfo,
  gotoPage,
  setBadgeQuery,
  hasFetchedBadgeFilterCounts,
  currentBadgeFilterCounts,
}) => {
  const location = useLocation();

  const pageQueryInfoRef = React.useRef(pageQueryInfo);

  React.useEffect(() => {
    pageQueryInfoRef.current = pageQueryInfo;
  }, [pageQueryInfo]);

  const getPathingObject = () => {
    if (
      pathing.hasOwnProperty(location.pathname.replace(BASE_PATH + "/", ""))
    ) {
      return pathing[location.pathname.replace(BASE_PATH + "/", "")];
    }
    return pathing[DEFAULT_PATHNAME];
  };

  const choosenPath = React.useMemo(() => {
    return getPathingObject();
  }, [location.pathname]);

  React.useEffect(() => {
    setCurrentBadgeFilter(undefined);
  }, [choosenPath.pathId]);

  const [currentBadgeFilter, setCurrentBadgeFilter] = React.useState(undefined);

  const updateBadgeFilter = (key) => {
    //This should never run.
    if (!pageQueryInfoRef.current) return;

    if (pageQueryInfoRef.current.page !== 0) {
      gotoPage(0);
    }
    if (currentBadgeFilter === key) {
      setCurrentBadgeFilter(undefined);
      return;
    }
    setCurrentBadgeFilter(key);
  };

  const buildBadgesForFilter = () => {
    let badges = [];

    if (choosenPath?.canChooseBadgeFilter === false) {
      return badges;
    }

    if (!hasFetchedBadgeFilterCounts) {
      for (let i = 0; i < 3; i++) {
        badges.push(<LoadingBadge key={i + "badge"} index={i} />);
      }

      return badges;
    }

    for (let key of Object.keys(CATEGORIES)) {
      if (key === "missing") continue;
      if (currentBadgeFilterCounts && currentBadgeFilterCounts?.[key] === false)
        continue;

      badges.push(
        <StandardBadge
          catKey={key}
          key={key + "badge" + badges.length}
          onClick={() => updateBadgeFilter(key)}
          currentBadgeFilter={currentBadgeFilter}
          index={badges.length}
        />
      );
    }

    return badges;
  };

  const [badgesForFilter, setBadgesForFilter] = React.useState(
    buildBadgesForFilter()
  );

  React.useEffect(() => {
    setBadgesForFilter(buildBadgesForFilter());
  }, [
    currentBadgeFilter,
    choosenPath,
    currentBadgeFilterCounts,
    hasFetchedBadgeFilterCounts,
  ]);

  React.useEffect(() => {
    let localCurrentBadgeFilter = currentBadgeFilter
      ? "" + currentBadgeFilter
      : "";

    if (!choosenPath.canFilterBadges) localCurrentBadgeFilter = "";

    if (choosenPath.specialBadgeFilter) {
      localCurrentBadgeFilter = choosenPath.specialBadgeFilter;
    }

    if (!localCurrentBadgeFilter || localCurrentBadgeFilter.length === 0) {
      setBadgeQuery({});
      return;
    }

    let badgeFilter = "";

    if (
      categoriesToStructures[localCurrentBadgeFilter] &&
      choosenPath.canFilterBadges
    ) {
      let index = 0;
      for (let reportType of categoriesToStructures[localCurrentBadgeFilter]) {
        if (index !== 0) badgeFilter += ",";
        badgeFilter += reportType;
        index++;
      }
    }

    if (badgeFilter.length !== 0) {
      setBadgeQuery({
        report__in: badgeFilter,
      });
    }
  }, [currentBadgeFilter, choosenPath]);

  return { badgesForFilter, currentBadgeFilter, setCurrentBadgeFilter };
};
