import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { constants } from "../../../store/massConfigPremisesComponents";

export default function MassConfig() {
  const { push } = useHistory();

  const handleStart = () => {
    push(constants.PATH);
  };
  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>
                Konfigurering av rum och komponenter
              </OverviewTitle>
              <OverviewSubtitle>
                Sätt upp rum och komponenter för flera objekt samtidigt. I
                huvudsak är detta flöde tänkt att användas för initial
                konfiguration av den Tekniska förvaltningen, men kan även
                användas för att komplettera existerande uppsättning.
                <br />
                <br />
                Flödet fungerar genom att först ställa in vilka objekt i
                systemet som ska påverkas, för att sedan skapa en uppsättning av
                upp rum/komponenter som kommer att appliceras på alla dessa
                objekt. <br />
                <strong>OBS:</strong> Notera att alla komponenttyper och
                komponentmodeller som ska användas i konfigureringen måste vara
                skapade sen innan.
                <br />
                <br />
                Pigello rekommenderar att detta görs i samråd med vårt
                implementationsteam för bästa resultat, kontakta oss om du är
                osäker på hur systemet ska konfigureras.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <InnerBox
            style={{
              minHeight: 250,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrimaryButton
              title="Starta flödet för uppsättning av rum och komponenter"
              clicked={handleStart}
            />
          </InnerBox>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
