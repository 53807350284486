import * as React from "react";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ErrandRoleUsersTable from "src/components/Tables/RoleUsers/FullTable";

import { constants } from "../../../../../store/roundingErrands";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import DurationField from "../../../Base/Fields/DurationField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Delegering av ärende</FormAreaTitle>

      <FormAreaDescription>
        Delegera vem som ska utföra ärendet.
      </FormAreaDescription>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="performer"
          title="Utförare (hård delegering)"
          placeholder="Välj utförare..."
          description="Hård delegering innebär att utföraren sätts på ärendet utan att förfrågas först."
          TableComponent={ErrandRoleUsersTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="requested_performer"
          title="Förfrågad utförare (mjuk delegering)"
          placeholder="Välj utförare..."
          description="Mjuk delegering innebär att utföraren förfrågad om att utföra ärendent och kan välja att acceptera eller neka via Pigellos web-portal eller App."
          TableComponent={ErrandRoleUsersTable}
        />
      </div>

      <DurationField
        storeName={storeName}
        method={method}
        title="Påminn utförare så här lång tid innan planerad start"
        fieldKey="remind_executor_duration"
        menuPlacement="top"
      />
    </>
  );
};
