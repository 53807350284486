import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  useFortnoxCredentialForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/fortnoxCredential";
import renderChapter from "./renderChapter";
import { updateActiveFormInstance } from "../../../../store/base";

export default ({ method, id, isOpen, onCheckout, instance, preProcess }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useFortnoxCredentialForm(method, id));

  React.useEffect(() => {
    if (formLoaded && instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, [formLoaded, isOpen]);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            if (!preProcess) { return data; }
            return preProcess(data, "POST");
          }
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            if (!preProcess) { return data; }
            return preProcess(data, "PATCH");
          }
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={
        method === "POST" ? "Lägg till fortnox koppling" : "Uppdatera fortnox koppling"
      }
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
