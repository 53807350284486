import * as React from "react";

import {
  DatePicker,
  MonthInput,
  RadioGroup,
  Select,
  TextField,
  TextInput,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/leaseContracts";
import { useFormInstanceField } from "../../../../../store/base";
import { TextButton } from "../../../Base/Buttons";

export default ({ method, contractType }) => {
  const storeName = constants.STORE_NAME;

  const [canceledOpen, setCanceledOpen] = React.useState(false);
  const endDate = useFormInstanceField({
    storeName,
    fieldKey: "end_date",
  });

  const renewIndefinetely = useFormInstanceField({
    storeName,
    fieldKey: "renew_indefinetely",
  });

  const status = useFormInstanceField({
    storeName,
    fieldKey: "status",
  });

  return (
    <>
      <TextInput
        title="Avtals-ID"
        description="Ange det interna ID:t för avtalet"
        storeName={storeName}
        fieldKey="id_number"
        method={method}
      ></TextInput>

      <DatePicker
        title="Startdatum"
        description="Datum när avtalet träder i kraft"
        storeName={storeName}
        fieldKey="start_date"
        method={method}
      />

      <DatePicker
        storeName={storeName}
        fieldKey="end_date"
        title="Slutdatum"
        description="Lämna tomt om avtalet är tillsvidare"
        method={method}
      />

      <MonthInput
        title="Uppsägningstid"
        storeName={storeName}
        fieldKey="notify_interval"
        method={method}
      />

      {endDate && (
        <RadioGroup
          storeName={storeName}
          fieldKey="renew_indefinetely"
          title="Förlängning vid passerat slutdatum"
          description="Välj hur ett passerat slutdatum utan att avtalet har sagts upp ska hanteras."
          method={method}
          forceHorizontalToolTipPlacement="left"
          forceVerticalToolTipPlacement="top"
          defaultValue={false}
          options={[
            {
              label:
                "Förläng med vald förlängningstid när slutdatumet passeras",
              value: false,
            },
            {
              label: "Förläng på obestämd tid när slutdatumet passeras",
              value: true,
            },
            {
              label:
                "Förläng ej när slutdatumet passeras (kräver uppsägningstid)",
              value: "no_renewal",
            },
          ]}
        />
      )}

      {!!endDate && !renewIndefinetely && (
        <MonthInput
          storeName={storeName}
          title="Förlängningstid"
          description="Ange i hur långa intervall avtalet förlängs"
          fieldKey="renew_interval"
          method={method}
        />
      )}

      <MonthInput
        title="Varna så här lång tid innan avtalet går ut "
        description="Pigello skickar en notis om att avtalet är på väg att gå ut så här lång tid innan slutdatum + uppsägningstid"
        storeName={storeName}
        fieldKey="warning_interval"
        method={method}
      />

      {!canceledOpen && contractType != "new" && (
        <TextButton
          title="Är avtalet uppsagt? Klicka här för att ange detaljer om uppsägningen"
          clicked={() => setCanceledOpen(true)}
          iconType="edit"
          iconPlacement="right"
        />
      )}

      {canceledOpen && (
        <>
          <RadioGroup
            {...{
              storeName,
              method,
              fieldKey: "landlord_cancelled",
              title: "Ange vem som sa upp hyresavtalet",
            }}
            options={[
              { label: "Uppsagt av Hyresvärd", value: true },
              { label: "Uppsagt av Hyresgäst ", value: false },
            ]}
            defaultValue={true}
          />

          <Select
            {...{
              storeName,
              method,
              fieldKey: "status",
              title: "Typ av uppsägning",
            }}
            filterChoices={(c) => [6, 7].includes(c.v)}
          />

          <DatePicker
            {...{
              storeName,
              method,
              fieldKey: "closed_on",
              title: "Uppsägningsdatum",
              description: "Ange vilket datum hyresavtalet sas upp",
            }}
          />

          {status === 6 && (
            <DatePicker
              {...{
                storeName,
                method,
                fieldKey: "closed_date",
                title: "Datum för utflytt",
                description: "Ange vilket datum utflytt ska ske",
              }}
            />
          )}
          {status === 7 && (
            <DatePicker
              {...{
                storeName,
                method,
                fieldKey: "closed_renew_date",
                title: "Datum för avtalsändring",
                description:
                  "Ange vilket datum det nya hyresavtalet träder i kraft",
              }}
            />
          )}

          <TextField
            {...{
              storeName,
              method,
              fieldKey: "closed_cause",
              title: "Anledning till uppsägning",
            }}
          />
        </>
      )}
    </>
  );
};
