import * as React from "react";
import { PrimaryButton } from "../../../Base/Buttons";
import { useFormInstanceField } from "../../../../../store/base";
import ToolTip from "../../../../ToolTip/ToolTip";

export default ({ onSubmit, storeName }) => {
  const [showWarning, setShowWarning] = React.useState(false);

  const hasSettingSelected = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const invoicingActive = useFormInstanceField({
    storeName,
    fieldKey: "is_active",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        minHeight: 200,
      }}
      onMouseOver={() => setShowWarning(true)}
      onMouseLeave={() => setShowWarning(false)}
    >
      <div>
        <PrimaryButton
          disabled={invoicingActive && !hasSettingSelected}
          title="Spara och gå vidare till debiteringsrader"
          clicked={onSubmit}
        />
      </div>
      {invoicingActive && !hasSettingSelected && showWarning && (
        <ToolTip
          title="Ingen inställning vald"
          content="Välj inställning för att gå vidare"
        />
      )}
    </div>
  );
};
