import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";

import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/tenants";
import {
  useAllPermissionCheck,
  useFrequentPermissions,
} from "../../../store/base";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { BADGE_TYPES } from "../../Badge/Badge";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function TenantTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  isMembers,
}) {
  const [realEstates] = useFilteredRealEstates();

  const { hasBillectaViewPermission } = useFrequentPermissions();
  const hasTenantPortal = useAllPermissionCheck([
    "view_can_tenantportal",
    "allow_tenantportal",
  ]);

  const filters = {
    "Person/org-nr": {
      queryKey: "legal_id", //matches filters.py
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Email: {
      queryKey: "email",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    "Tel-nummer": {
      queryKey: "phone",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    'Aktiv i "Mina sidor"': {
      queryKey: "invite_accepted",
      type: "boolean",
      operators: OPERATOR_TEMPLATES.BOOLEAN,
    },
    'Status inbjudan "Mina sidor"': {
      queryKey: "invite_accepted",
      type: "boolean",
      operators: OPERATOR_TEMPLATES.BOOLEAN,
    },
  };

  const badges = {
    Nuvarande: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state: 0,
      },
    },
    Kommande: {
      color: BADGE_TYPES.CYAN,
      querySet: {
        state: 1,
      },
    },
    Potentiell: {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state: 2,
      },
    },
    Tidigare: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state: 3,
      },
    },
  };

  const columns = React.useMemo(
    () =>
      columnDefs(
        realEstates,
        isMembers,
        hasTenantPortal,
        hasBillectaViewPermission
      ),
    [realEstates, hasTenantPortal, hasBillectaViewPermission, persistantQuery]
  );

  return (
    <BaseTable
      tableId={tableId || isMembers ? "member_full_table" : "tenant_full_table"}
      title={isMembers ? "Medlemmar" : "Hyresgäster"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
          isMember: isMembers,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
