import classNames from "classnames";
import * as React from "react";
import { TextButton } from "../../../Forms/Base/Buttons";
import { useHistory } from "react-router-dom";

// content = [
//   {
//     title: "Min titel",
//     rows: [
//       ["Title coole", "Mitt coola värde"],
//       [
//         "title coole2",
//         "Mitt andra coola värde",
//         "Mitt andra andra coola värde",
//       ],
//     ],
//   },
//   {}, //Creates a divider
//   {
//     title: "Min coola titel",
//     rows: [["Mitt värde"], ["Värde1", "Värde2", "värde3", "value4"]],
//   },
// ];

export const ExpandList = ({ content, hidden = false }) => {
  console.log("content,", content);
  const history = useHistory();

  const renderRowIndex = (data, index, textClassName) => {
    return (
      <div className="flex flex-col space-y-2 ml-1">
        {data.rows.map((innerRows, rIndex) => {
          let toRender = innerRows[index];

          if (!React.isValidElement(toRender) && typeof toRender !== "string") {
            //assume its an object with onClick and text

            toRender = ((_toRender) => (
              <TextButton
                clicked={() => {
                  history.push(_toRender.onClick());
                }}
                title={_toRender.text}
              />
            ))(toRender);
          } else {
            toRender = (
              <p
                className={classNames(
                  "w-full whitespace-nowrap",
                  textClassName && textClassName
                )}
              >
                {toRender}
              </p>
            );
          }

          return (
            <div
              key={`${index}${rIndex}`}
              className={classNames(
                "flex items-center justify-between space-x-4"
              )}
            >
              {toRender}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="bg-slate-100 w-full p-4 shrink flex">
      <div className="flex flex-col shrink shadow bg-white rounded p-3">
        {!content ? (
          <p>Ingen data</p>
        ) : (
          <>
            {content.map((data, index) => {
              if (Object.keys(data).length === 0) {
                //divider
                return (
                  <div
                    key={index + "divider"}
                    className="mt-2 mb-2 border-b border-gray-300"
                  ></div>
                );
              }
              return (
                <div className="" key={data.title + index}>
                  <p
                    className={classNames(
                      "text-lg font-semibold mt-4 first-of-type:mt-0",
                      hidden && "whitespace-nowrap"
                    )}
                  >
                    {data.title}
                  </p>
                  <div className="flex mt-2 space-x-6 justify-between">
                    {renderRowIndex(data, 0)}
                    {renderRowIndex(data, 1, "text-right pr-1")}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
