import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../../components/Displays";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import useQuery from "../../../components/utils/useQuery";
import {
  connectUrl,
  overviewUrl,
  useSensorPaginationCount,
} from "../../../store/IMDSensor";

export default function SensorHandlePage() {
  const { push } = useHistory();
  const query = useQuery();

  // post import data
  const postImport = query.get("postimport") == "true";
  const sensorAmount = query.get("sensor_amount");
  const fromDate = query.get("from_date");
  const toDate = query.get("to_date");
  const momentFromDate = moment(fromDate);
  const momentToDate = moment(toDate);

  // post connection data
  const postConnect = query.get("postconnect") == "true";
  const possibleCount = query.get("possible");
  const connectedCount = query.get("connected");

  const [nonConnectedCount] = useSensorPaginationCount({
    filters: {
      apartment__isnull: true,
      industrial_premises__isnull: true,
      parking_spot__isnull: true,
      brf_premis__isnull: true,
      common_area__isnull: true,
    },
  });

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <TextButton
          extraStyle={{ marginBottom: 24 }}
          title="Tillbaka till översikt av IMD-sensorer"
          iconType="arrow-back"
          clicked={() => push(overviewUrl())}
        />

        {postImport && (
          <InfoBox
            boxTheme="ok"
            title="Import av mätvärden lyckades"
            extraStyles={{ marginBottom: 24 }}
            renderText={() => (
              <>
                <br />
                <div>
                  Antal sensorer som värden importerades för:{" "}
                  <strong>{sensorAmount}</strong>
                </div>

                <br />
                <div>
                  Datumintervall för importerade värden:{" "}
                  <strong>
                    {momentFromDate.format("YYYY-MM-DD")} -{" "}
                    {momentToDate.format("YYYY-MM-DD")}
                  </strong>
                </div>
              </>
            )}
          />
        )}

        {postConnect && (
          <InfoBox
            boxTheme="ok"
            title="Koppling av sensorer mot objekt genomfördes"
            extraStyles={{ marginBottom: 24 }}
            renderText={() => (
              <>
                <br />
                <div>
                  <strong>{connectedCount}</strong> av{" "}
                  <strong>{possibleCount}</strong> möjliga sensorer kopplades.
                </div>
              </>
            )}
          />
        )}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Hantera IMD-sensorer</OverviewTitle>
            <OverviewSubtitle>
              Det finns{" "}
              <strong>
                {nonConnectedCount > 0 ? nonConnectedCount : "inga"}
              </strong>{" "}
              ej kopplade sensorer
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <InnerBox>
          <BodyText style={{ marginBottom: 24 }}>
            Vid koppling av sensorer hanteras <strong>50st</strong> sensorer åt
            gången för att underlätta hanteringen. Sensorer kopplas mot objekt
            (Hyreslägenheter, Lokaler, Bostadsrätter, Fordonsplatser, Gemensamma
            utrymmen).
          </BodyText>

          <BodyText style={{ marginBottom: 24 }}>
            <strong>
              Det finns {nonConnectedCount || 0} sensorer kvar att kopppla till
              objekt.
            </strong>
          </BodyText>

          <div>
            <PrimaryButton
              disabled={nonConnectedCount < 1}
              title="Koppla nästa 50st sensorer till objekt"
              clicked={() => push(connectUrl())}
            />
          </div>
        </InnerBox>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
