import * as React from "react";
import * as ISC from "../../../Details/InfoTable/styles";

export default ({ instance }) => {
  return (
    <ISC.InfoTable>
      <ISC.InfoTableTitleWrapper>
        <ISC.InfoTableTitle>
          <ISC.InfoTableTitle>Titel</ISC.InfoTableTitle>
          <ISC.InfoTableSubtitle>
            {instance?.title || "-"}
          </ISC.InfoTableSubtitle>
        </ISC.InfoTableTitle>
      </ISC.InfoTableTitleWrapper>

      <ISC.InfoTableContent>
          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Titel</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.title || "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Medelande</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.message || "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Tid</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.scheduled_for || "Nu"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Fastnålad</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {!!instance?.pinned ? "Ja" : "Nej"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

      </ISC.InfoTableContent>
    </ISC.InfoTable>
  );
};
