import * as React from "react";

// style, design
import { DateCell, LinkedObject, ToolTipCell } from "../../Displays";

import { detailUrl as brfPremisDetailUrl } from "../../../store/brfPremises";
import { detailUrl as paymentGroupDetailUrl } from "../../../store/paymentGroup";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import { toMoneyString } from "../../utils/stringUtils";

export default () => [
  {
    Header: "Lägenhet",
    accessor: "brf_premis",
    Cell: ({ value, row }) => {
      return <LinkedObject obj={value} urlMethod={brfPremisDetailUrl} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Avgiftsgrupp",
    accessor: "payment_group",
    Cell: ({ value, row }) => {
      return <LinkedObject obj={value} urlMethod={paymentGroupDetailUrl} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Lån - Kapitaltillskott",
    accessor: "loan_capital",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Lån - Ej kapitaltillskott",
    accessor: "loan_non_capital",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Lån - Ränteavgift",
    accessor: "loan_interest",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Lån - Låst ränta, avgift",
    accessor: "loan_firm_interest_fee",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Ej lån",
    accessor: "non_loan",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Tilläggsdatum",
    accessor: "registration_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Registreringsdatum",
    accessor: "performed_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Period start",
    accessor: "period_start",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Period slut",
    accessor: "period_end",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
];
