export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PIPE_ATTRIBUTES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PIPE_ATTRIBUTES",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PIPE_ATTRIBUTES",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PIPE_ATTRIBUTES",
  DESTROY_FORM: "DESTROY_FORM_PIPE_ATTRIBUTES",
  SET_FORM_ERROR: "SET_FORM_ERROR_PIPE_ATTRIBUTES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PIPE_ATTRIBUTES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PIPE_ATTRIBUTES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PIPE_ATTRIBUTES",
  RESET_STATE: "RESET_STATE_PIPE_ATTRIBUTES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PIPE_ATTRIBUTES",
  REMOVE_OBJECT: "REMOVE_OBJECT_PIPE_ATTRIBUTES",
  CLEAR_FETCHED: "CLEAR_FETCHED_PIPE_ATTRIBUTES",

  LIST_URL: "/market/crm/dynamicpipeattribute/list/",
  POST_URL: "/market/crm/dynamicpipeattribute/",
  PATCH_URL: "/market/crm/dynamicpipeattribute/",
  GET_URL: "/market/crm/dynamicpipeattribute/",

  STORE_NAME: "pipeAttributes",
};
