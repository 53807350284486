import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import SendoutTable from "../../../components/Tables/Sendouts/FullTable";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

import ModalForm from "../../../components/Forms/Sendout/ChapterForm/ModalForm";

export default function Sendouts() {
  const [addSendoutActive, setAddSendoutActive] = React.useState(false);
  const [editSendout, setEditSendout] = React.useState(undefined);

  return (
    <>
      <ModalForm
        method="POST"
        isOpen={addSendoutActive}
        onCheckout={() => setAddSendoutActive(false)}
      />
      <ModalForm
        method="PATCH"
        isOpen={!!editSendout}
        onCheckout={() => setEditSendout(false)}
        instance={editSendout}
        id={editSendout?.id}
      />
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Utskick till hyresgäster</OverviewTitle>
            <PrimaryButton
              title="Nytt utskick"
              clicked={() => {
                setAddSendoutActive(true);
                setEditSendout(undefined);
              }}
            />
          </OverviewTitleWrapper>

          <SendoutTable onRowClicked={(row) => setEditSendout(row.original)} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
