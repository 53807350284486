import * as React from "react";
import { DateCell, LinkedObject } from "../../../Displays";
import { detailUrl as indexDetailUrl } from "../../../../store/indexsetting";
import { XCircleIcon } from "@heroicons/react/24/outline";
import * as SC from "./styles";
import moment from "moment";

import { toMoneyString } from "../../../utils/stringUtils";
import {
  _earliestDate,
  _latestDate,
} from "../../../Lists/utils/costCalculations";
import Badge, { BADGE_TYPES } from "../../../Badge/Badge";

export default ({
  invoicingObj,
  realEstate,
  defaultCostCenter,
  defaultProject,
  setDeleteCostOpen,
  handleRowDown,
  handleRowUp,
  contract,
}) => [
  {
    Header: " ",
    id: "orderOnInvoice",
    Cell: ({ row, data }) => {
      const rowOrder = row.original.order;
      const allOrders = data.map((fc) => fc.order);
      const maxUp = Math.max.apply(null, allOrders);
      const maxDown = Math.min.apply(null, allOrders);
      const canDown = rowOrder < maxUp;
      const canUp = rowOrder > maxDown;

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {canUp && (
            <SC.OrderUp onClick={(e) => handleRowUp(e, row.original)} />
          )}
          {canDown && (
            <SC.OrderDown onClick={(e) => handleRowDown(e, row.original)} />
          )}
        </div>
      );
    },
  },

  {
    Header: "Debiteringsrad",
    accessor: "title",
    Cell: ({ value, row }) => {
      const isDiscount = row.original.value < 0;
      const isRentIncrease = row.original.is_rent_increase_cost;
      const doNotDebit = row.original.do_not_debit;

      const state = getCostRowState({
        costRow: row.original,
        invoicingObj,
        contract,
      });

      const stateRender =
        state === COST_ROW_STATES.ACTIVE ? (
          <Badge small type={BADGE_TYPES.GREEN}>
            Aktiv
          </Badge>
        ) : state === COST_ROW_STATES.EARLIER ? (
          <Badge small type={BADGE_TYPES.YELLOW}>
            Tidigare
          </Badge>
        ) : state === COST_ROW_STATES.COMING ? (
          <Badge small type={BADGE_TYPES.DEFAULT}>
            Kommande
          </Badge>
        ) : (
          <Badge small type={BADGE_TYPES.GRAY}>
            Okänd
          </Badge>
        );

      return (
        <div className="flex flex-col">
          <div className="flex mb-1 items-center space-x-1">
            {stateRender}
            <Badge
              small
              type={doNotDebit ? BADGE_TYPES.RED : BADGE_TYPES.GREEN}
            >
              {doNotDebit ? "Debiteras Inte" : "Debiteras"}
            </Badge>
            {isDiscount && (
              <Badge small type={BADGE_TYPES.YELLOW}>
                Rabatt
              </Badge>
            )}
            {isRentIncrease && (
              <Badge small type={BADGE_TYPES.INDIGO}>
                {"Hyreshöjning"}
              </Badge>
            )}
          </div>
          <div className="text-sm font-medium">{value}</div>
        </div>
      );
    },
  },

  {
    Header: "Debiteras",
    accessor: "value",
    Cell: ({ value, row }) => {
      const productVat = row.original.product?.vat;
      const rowVat = row.original.vat;
      const val =
        rowVat != null
          ? `${rowVat}% moms`
          : productVat != null
          ? `${productVat}% moms (från produkt)`
          : invoicingObj?.vat != null
          ? `${invoicingObj?.vat}% moms (från grundmomssats)`
          : "-";

      return (
        <div className="flex flex-col">
          <div className="text-sm font-medium">
            {toMoneyString(value || 0, true)}/mån
          </div>
          <div className="text-xs">{val}</div>
        </div>
      );
    },
  },
  {
    Header: "Period",
    id: "period",
    Cell: ({ row }) => {
      const { start_date, end_date } = row.original;

      if (!start_date && !end_date) {
        return <div className="flex items-center space-x-2">Med avtalet</div>;
      }

      return (
        <div className="flex items-center space-x-2">
          {start_date ? <DateCell date={start_date} /> : "Avtalets start"}
          <div>-</div>
          {end_date ? <DateCell date={end_date} /> : "Avtalets slut"}
        </div>
      );
    },
  },

  {
    Header: "Bokföring",
    id: "accounting",
    Cell: ({ row }) => {
      const { product, project, cost_center } = row.original;

      const costCenterTitle = cost_center?.title
        ? cost_center.title
        : cost_center?.str_representation
        ? cost_center.str_representation
        : defaultCostCenter
        ? `${defaultCostCenter.str_representation} (från inställning)`
        : realEstate?.cost_center
        ? `${realEstate.cost_center.str_representation} (från fastighet)`
        : "-";

      const projectTitle = project?.title
        ? project.title
        : project?.str_representation
        ? project.str_representation
        : defaultProject
        ? `${defaultProject.str_representation} (från inställning)`
        : realEstate?.project
        ? `${realEstate.project.str_representation} (från fastighet)`
        : "-";

      return (
        <div className="flex flex-col">
          <div className="text-xs">
            Produkt:{" "}
            <span className="font-medium">
              {" "}
              {product?.title || product?.str_representation || "-"}{" "}
              {product?.account_no ? `(${product.account_no})` : ""}
            </span>
          </div>
          <div className="text-xs">
            Projekt:<span className="font-medium"> {projectTitle}</span>
          </div>
          <div className="text-xs">
            Kostnadsställe:
            <span className="font-medium">{costCenterTitle}</span>
          </div>
        </div>
      );
    },
  },
  {
    Header: "Indexuppräkning",
    accessor: "indexation",
    Cell: ({ value }) => {
      return <LinkedObject obj={value} urlMethod={indexDetailUrl} />;
    },
  },
  {
    Header: " ",
    id: "removeRow",
    Cell: ({ row }) => {
      const id = row.original.id || row.original._tempId;

      return (
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          onClick={(e) => {
            e.stopPropagation();
            setDeleteCostOpen(id);
          }}
        >
          <XCircleIcon width={24} />
        </button>
      );
    },
  },
];

export const COST_ROW_STATES = {
  ACTIVE: "Aktiv",
  COMING: "Kommande",
  EARLIER: "Tidigare",
};

export const getCostRowState = ({ costRow, invoicingObj, contract }) => {
  if (!costRow) return null;
  const now = moment();

  const usedStartDate = _latestDate({
    dates:
      [
        now.format("YYYY-MM-DD"),
        invoicingObj?.current_period_start,
        contract?.start_date,
      ]?.filter((v) => v) || [],
  });
  let usedEndDate = _earliestDate({
    dates:
      [
        contract?.renewed_to,
        contract?.closed_renew_date,
        contract?.closed_date,
        invoicingObj?.current_period_end,
      ]?.filter((v) => v) || [],
  });

  if ((!contract && !invoicingObj) || !usedEndDate) {
    usedEndDate = now;
  }

  if (!costRow.start_date && !costRow.end_date) return COST_ROW_STATES.ACTIVE;

  if (costRow.start_date) {
    const start = moment(costRow.start_date);
    if (usedEndDate.isBefore(start)) return COST_ROW_STATES.COMING;
  }

  if (costRow.end_date) {
    const end = moment(costRow.end_date);
    if (usedStartDate.isAfter(end)) return COST_ROW_STATES.EARLIER;
  }

  return COST_ROW_STATES.ACTIVE;
};
