import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useLoan, updateUrl as loanUpdateUrl, constants } from "../../store/loan";
import { detailUrl as paymentGroupDetailUrl } from "../../store/paymentGroup";

import { detailUrl as brfCompanyDetailUrl } from "../../store/brfCompanies"

import Loan from "./Detail/Loan"

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useAllPermissionCheck } from "../../store/base";

export default function LoanDetail() {
  const { loanId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace, push } = useHistory()

  usePermissionRedirect(["allow_brf", "view_can_brf"]);

  const canDelete = useAllPermissionCheck([
    "delete_can_brf",
  ]);
  const canEdit = useAllPermissionCheck(["change_can_brf"])
  
  const [loan, paymentGroupLoading] = useLoan(loanId)
  const brfCompanyId = loan?.brf_company?.id;
  const paymentGroupId = loan?.payment_group?.id;
 
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleActions = [
     {
         "name":"Redigera",
         onClick: () => push(loanUpdateUrl(loanId, brfCompanyId)),
         disabled: !brfCompanyId,
         hidden: !canEdit
     },
     {
         "name":"Radera",
         onClick: () => setDeleteOpen(true),
         isDelete: true,
         hidden: !canDelete
     }
  ];

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: Loan,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  let breadCrumbs = [
    { url: "/brf-companies", label: "BRF" },
    { label: loan?.brf_company?.str_representation || "Laddar...", url: brfCompanyId != null ? brfCompanyDetailUrl({id: brfCompanyId}) + "/loans" : undefined },
  ]
  if (paymentGroupId) {
    breadCrumbs.push(
      { label: loan?.payment_group?.str_representation || "Laddar...", url: paymentGroupDetailUrl({id: paymentGroupId}) },
    )
  }
  breadCrumbs.push(
    { label: loan?.str_representation || "Laddar..." }
  )

  return (
    <>
        <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={loan}
        constants={constants}
        deletedCallback={() => paymentGroupId != null ? replace(paymentGroupDetailUrl({id:paymentGroupId})) : replace(brfCompanyDetailUrl({id:brfCompanyId}) + "/loans")}
      />
        {!loan && <OverlaySpinner/>}
        <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Lån ${loan?.str_representation || "laddar..."}`}
          breadCrumbs={breadCrumbs}
          eventContentType="brf.loan"
          eventObjectId={loanId}
          eventIdQueryStr="loan"
          {...{
            handleActions,
            subPages: subPageLinks
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
