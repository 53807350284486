import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import NonConnectedRadioGroup from "../../../Base/Old/NonConnected/NonConnectedRadioGroup";
import { AD_CREATE_TYPES } from "../../../../../views/Market/ManyHandling/ManyAdRootObjectSelect";
import { useAllPermissionCheck } from "../../../../../store/base";

// style, design

export default ({ storeName, updateDefaultSettings, type }) => {
  const dispatch = useDispatch();

  const hasHomeQPerm = useAllPermissionCheck(["allow_homeq", "view_can_homeq"]);
  const hasSbfPerm = useAllPermissionCheck(["view_can_sbf", "view_can_market"]);
  const defaultSettingsInstance = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const updateAttr = ({ attr, val }) => {
    dispatch(updateDefaultSettings({ attr, val }));
  };

  return (
    <>
      {type === AD_CREATE_TYPES.APARTMENT && hasHomeQPerm && (
        <NonConnectedRadioGroup
          id={`homeQ_publish`}
          title="Publicera på HomeQ"
          options={[
            {
              label: "Ja",
              value: true,
            },
            {
              label: "Nej",
              value: false,
            },
          ]}
          value={defaultSettingsInstance.publish_homeq}
          onChange={(val) => {
            updateAttr({ attr: "publish_homeq", val });
          }}
        />
      )}

      {/* <NonConnectedRadioGroup
        id={`publish_pigello`}
        title="Publicera på Pigello Marknadsplats"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.publish_pigello}
        onChange={(val) => {
          updateAttr({
            attr: "publish_pigello",

            val,
          });
        }}
      /> */}

      {type === AD_CREATE_TYPES.APARTMENT && hasSbfPerm && (
        <NonConnectedRadioGroup
          id={`sbf_publish`}
          title="Publicera på Stockholms Bostadsförmedling"
          options={[
            {
              label: "Ja",
              value: true,
            },
            {
              label: "Nej",
              value: false,
            },
          ]}
          value={defaultSettingsInstance.publish_sbf}
          onChange={(val) => {
            updateAttr({
              attr: "publish_sbf",

              val,
            });
          }}
        />
      )}
    </>
  );
};
