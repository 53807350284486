import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";

//store, state
import {
  updateActiveFormInstance,
  setActiveFormInstance,
  usePermissionCheck,
} from "../../store/base";
import {
  useRoundingErrandSetting,
  constants,
  overviewUrl,
} from "../../store/roundingErrandSettings";

import usePermissionRedirect from "../../hooks/usePermissionRedirect";

//components & styles
import GovOverview from "./settingdetail/Overview";
import GovErrand from "./settingdetail/Errands";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import RoundingErrandSettingModalForm from "src/components/Forms/RoundingErrandSetting/ChapterForm/ModalForm";

function GovErrandSettingDetail() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { settingId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();

  const [govErrandSetting, govErrandSettingLoading] =
    useRoundingErrandSetting(settingId);

  const [editErrandOpen, setEditErrandOpen] = React.useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  usePermissionRedirect(["view_can_goverrand", "allow_errand_gov"]);

  const canEdit = usePermissionCheck("change_can_goverrand");

  const canDelete = usePermissionCheck("delete_can_goverrand");

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: GovOverview,
    },
    {
      path: "/errands",
      label: `Ärenden `,
      component: GovErrand,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const onEdit = () => {
    const errandClone = cloneDeep(govErrandSetting);

    dispatch(updateActiveFormInstance({ storeName, data: errandClone }));

    setEditErrandOpen(true);
  };

  const handleActions = [];

  if (canEdit) {
    handleActions.push({
      name: "Redigera",
      onClick: onEdit,
    });
  }

  if (canDelete) {
    handleActions.push({
      isDelete: true,
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  React.useEffect(() => {
    return () => {
      dispatch(setActiveFormInstance({ storeName, data: {} }));
    };
  }, []);

  return (
    <>
      {govErrandSettingLoading && <OverlaySpinner />}
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={govErrandSetting}
        constants={constants}
        deletedCallback={() => replace(overviewUrl({ govErrand: true }))}
      />

      {canEdit && editErrandOpen && (
        <RoundingErrandSettingModalForm
          method="PATCH"
          id={govErrandSetting?.id}
          instance={govErrandSetting}
          isGov
          onCheckout={() => setEditErrandOpen(false)}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Myndighetskrav ${
            govErrandSetting?.str_representation || "laddar..."
          }`}
          breadCrumbs={[
            { url: "/gov-errands", label: "Myndighetskrav" },
            { label: govErrandSetting?.str_representation || "Laddar..." },
          ]}
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default GovErrandSettingDetail;
