import * as React from "react";
import * as SC from "./styles";

import Logo from "./Logo";
import boltIcon from "../../../assets/svg/bolt_purple.svg";
import accountBalanceIcon from "../../../assets/svg/material/account_balance_black.svg";
import accountBalanceIconActive from "../../../assets/svg/material/account_balance_blue.svg";
import keyIcon from "../../../assets/svg/material/key_black.svg";
import buildingIcon from "../../../assets/svg/material/building_black.svg";
import buildingIconActive from "../../../assets/svg/material/building_blue.svg";
import rentalsIcon from "../../../assets/svg/material/rentals_black.svg";
import carIcon from "../../../assets/svg/material/car_black.svg";
import documentIcon from "../../../assets/svg/material/document_black.svg";
import peopleIcon from "../../../assets/svg/material/people_black.svg";
import peopleIconActive from "../../../assets/svg/material/people_blue.svg";
import workIcon from "../../../assets/svg/material/work_black.svg";
import workIconActive from "../../../assets/svg/material/work_blue.svg";
import realestateIcon from "../../../assets/svg/material/category_black.svg";
import realestateIconActive from "../../../assets/svg/material/category_blue.svg";
import statsIcon from "../../../assets/svg/material/stats_black.svg";
import IMDIcon from "../../../assets/svg/material/lightbulb_black.svg";
import IMDIconActive from "../../../assets/svg/material/lightbulb_blue.svg";
import statsIconActive from "../../../assets/svg/material/stats_blue.svg";
import dotEmptyIcon from "../../../assets/svg/material/dot_black_empty.svg";
import dotFilledIcon from "../../../assets/svg/material/dot_blue_filled.svg";
import taskIcon from "../../../assets/svg/material/task_black.svg";
import taskIconActive from "../../../assets/svg/material/task_blue.svg";
import cooperativeIcon from "../../../assets/svg/material/gavel_black.svg";
import cooperativeIconActive from "../../../assets/svg/material/gavel_blue.svg";
import memberIcon from "../../../assets/svg/material/contact_page_black.svg";
import devIcon from "../../../assets/svg/material/integration_instructions_black.svg";
import memberIconActive from "../../../assets/svg/material/contact_page_blue.svg";
import marketIcon from "../../../assets/svg/material/market_black.svg";
import marketIconActive from "../../../assets/svg/material/market_blue.svg";
import useActiveFilters from "../../../hooks/useActiveFilters";
import excelIcon from "../../../assets/svg/excelGreen.svg";
import handyman from "../../../assets/svg/material/handyman_black.svg";
import portFolioIcon from "../../../assets/svg/material/portfolio_black.svg";
import portFolioIconActive from "../../../assets/svg/material/portfolio_blue.svg";

import {
  useAllPermissionCheck,
  useAnyPermissionCheck,
  useFrequentPermissions,
} from "../../../store/base";
import { useBillectaEventsPaginationCount } from "../../../store/billectaEvents";
import { REPORT_EVENTS_CATEGORIES } from "../../../store/billectaEvents/hooks/retrieve";
import { useFilteredCompanies } from "../../../store/companies";
import MiniLogo from "./MiniLogo";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIsImpersonation } from "../../../store/app/hooks";

const contractChapterItems = [
  "/lease-contracts",
  "/other-contracts",
  "/service-contracts",
  "/parking-contracts",
  "/documents",
  "/templates",
];

const rentalsItems = [
  "/apartments",
  "/industrial-premises",
  "/cooperative-premises",
  "/common-areas",
];

const errandItems = [
  "/rounding-errands",
  "/rounding-settings",
  "/report-errands",
  "/inspection-errands",
  "/gov-errands",
  "/gov-settings",
];

const marketingItems = ["/leads", "/pipes", "/marketing", "/queues"];

export default ({ sideBarOpen, setSideBarOpen }) => {
  const [open, setOpen] = React.useState("");
  const { location } = useHistory();

  const { pathname } = location;

  const user_type = useSelector((state) => state.app?.user?.user_type);
  const username = useSelector((state) => state.app.user?.username);
  const isAdmin = useIsImpersonation();

  const { filteredRealEstates, filteredCompanies, filteredBrfCompanies } =
    useActiveFilters();

  const [companies] = useFilteredCompanies();
  const billectaIds = companies
    ?.map((c) => c.billecta_id)
    ?.filter((id) => !!id);

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const hasReportErrandPermission = useAllPermissionCheck([
    "allow_errand_faults",
    "view_can_reporterrand",
  ]);
  const hasInspectionErrandPermission = useAllPermissionCheck([
    "allow_errand_inspections",
    "view_can_inspectionerrand",
  ]);
  const hasRoundingErrandPermission = useAllPermissionCheck([
    "allow_errand_roundings",
    "view_can_roundingerrand",
  ]);
  const hasGovInspectionPermission = useAllPermissionCheck([
    "allow_errand_gov",
    "view_can_goverrand",
  ]);

  const hasInsightsPermission = useAllPermissionCheck([
    "allow_insights",
    "view_can_insights",
  ]);

  const hasAnyCustomerErrands = useAnyPermissionCheck([
    "allow_errand_gov",
    "allow_errand_roundings",
    "allow_errand_inspections",
    "allow_errand_faults",
  ]);
  const hasAnyErrandsPerms = useAnyPermissionCheck([
    "view_can_reporterrand",
    "view_can_roundingerrand",
    "view_can_inspectionerrand",
    "view_can_goverrand",
  ]);

  const hasReportViewPerms = user_type === 0 || user_type === 1;

  const hasKeyPerms = useAllPermissionCheck(["allow_keys", "view_can_keys"]);

  const shouldShowErrands = hasAnyErrandsPerms && hasAnyCustomerErrands;

  const canImd = useAllPermissionCheck(["allow_imd", "view_can_imd"]);
  const hasBrfPermission = useAllPermissionCheck(["allow_brf", "view_can_brf"]);
  const canMarket = useAllPermissionCheck(["allow_market", "view_can_market"]);

  const [billectaEventsCount] = useBillectaEventsPaginationCount({
    read: false,
    relatedCreditorIds: billectaIds?.length ? billectaIds : [],
    categories: [
      REPORT_EVENTS_CATEGORIES.INVOICE_UNDELIVERED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_APPROVAL_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_CHANGED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL_RETRY,
    ],
  });

  React.useEffect(() => {
    if (sideBarOpen) return;

    setOpen(null);
  }, [pathname]);

  const getIsActive = (match, exact) => {
    if (exact) return window.location.pathname === match;
    return window.location.pathname?.split("/")?.includes(match);
  };

  const getGovOrRoundingActive = (match1, match2) => {
    if (window.location.pathname?.split("/")?.includes(match1)) return true;
    if (window.location.pathname?.split("/")?.includes(match2)) return true;
    return false;
  };

  const handleToggle = (key) => {
    if (open === key) {
      setOpen("");
    } else {
      setOpen(key);
    }
  };

  const getOuterIsActive = (type) => {
    if (sideBarOpen) return;

    switch (type) {
      case "CONTRACT":
        return contractChapterItems.some((i) =>
          window.location.pathname.includes(i)
        );
      case "RENTALS":
        return rentalsItems.some((i) => window.location.pathname.includes(i));
      case "ERRANDS":
        return errandItems.some((i) => window.location.pathname.includes(i));
      case "MARKETING":
        return marketingItems.some((i) => window.location.pathname.includes(i));

      default:
        return false;
    }
  };

  React.useEffect(() => {
    if (!sideBarOpen) {
      setOpen(null);
      return;
    }
    contractChapterItems.forEach((i) => {
      if (window.location.pathname.includes(i)) {
        setOpen("contracts");
      }
    });

    rentalsItems.forEach((i) => {
      if (window.location.pathname.includes(i)) {
        setOpen("rentals");
      }
    });

    errandItems.forEach((i) => {
      if (window.location.pathname.includes(i)) {
        setOpen("errands");
      }
    });

    marketingItems.forEach((i) => {
      if (window.location.pathname.includes(i)) {
        setOpen("marketing");
      }
    });
  }, [sideBarOpen]);

  let showPortfolio =
    localStorage.getItem("sidebar__hidden__portfolio") == "true" ? true : false;
  const renderContent = () => {
    return (
      <>
        <SC.Chapter
          closedMode={!sideBarOpen}
          style={{ marginTop: sideBarOpen ? "24px" : "12px" }}
        >
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/"
            active={getIsActive("/", true)}
            icon={getIsActive("/", true) ? taskIconActive : taskIcon}
          >
            {sideBarOpen && "Min arbetsdag"}
          </SC.SidebarItem>

        {hasInsightsPermission ? (
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/insights"
            active={getIsActive("insights")}
            icon={boltIcon}
          >
            {sideBarOpen && "Insights"}
          </SC.SidebarItem>
        ) : (
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/dashboard"
            active={getIsActive("dashboard")}
            icon={getIsActive("dashboard") ? statsIconActive : statsIcon}
          >
            {sideBarOpen && "Dashboard"}
          </SC.SidebarItem>
        )}
        </SC.Chapter>

        <SC.Chapter closedMode={!sideBarOpen}>
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/companies"
            active={getIsActive("companies")}
            filterActive={filteredCompanies?.length > 0}
            icon={getIsActive("companies") ? workIconActive : workIcon}
          >
            {sideBarOpen && "Bolag"}
          </SC.SidebarItem>

          {hasBrfPermission && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/brf-companies"
              active={getIsActive("brf-companies")}
              filterActive={filteredBrfCompanies?.length > 0}
              icon={
                getIsActive("brf-companies")
                  ? cooperativeIconActive
                  : cooperativeIcon
              }
            >
              {sideBarOpen && "BRF"}
            </SC.SidebarItem>
          )}

          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/realestates"
            active={getIsActive("realestates")}
            filterActive={
              filteredCompanies?.length === 0 &&
              filteredBrfCompanies?.length === 0 &&
              filteredRealEstates?.length > 0
            }
            icon={
              getIsActive("realestates") ? realestateIconActive : realestateIcon
            }
          >
            {sideBarOpen && "Fastigheter"}
          </SC.SidebarItem>
          {showPortfolio && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/portfolios"
              active={getIsActive("portfolios")}
              icon={
                getIsActive("portfolios") ? portFolioIconActive : portFolioIcon
              }
            >
              {sideBarOpen && "Portföljer"}
            </SC.SidebarItem>
          )}
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/buildings"
            active={getIsActive("buildings")}
            icon={getIsActive("buildings") ? buildingIconActive : buildingIcon}
          >
            {sideBarOpen && "Byggnader"}
          </SC.SidebarItem>
        </SC.Chapter>

        <SC.Chapter closedMode={!sideBarOpen}>
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/tenants"
            active={getIsActive("tenants")}
            icon={getIsActive("tenants") ? peopleIconActive : peopleIcon}
          >
            {sideBarOpen && "Hyresgäster"}
          </SC.SidebarItem>

          {hasBrfPermission && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/members"
              active={getIsActive("members")}
              icon={getIsActive("members") ? memberIconActive : memberIcon}
            >
              {sideBarOpen && "Medlemmar"}
            </SC.SidebarItem>
          )}

          <SC.SidebarToggleItem
            closedMode={!sideBarOpen}
            onClick={() => handleToggle("contracts")}
            isOpen={open === "contracts"}
            icon={documentIcon}
            active={getOuterIsActive("CONTRACT")}
          >
            {sideBarOpen && "Avtal & Dokument"}
          </SC.SidebarToggleItem>
          {open === "contracts" && (
            <>
              <SC.SidebarItem
                closedModeInner={!sideBarOpen}
                to="/lease-contracts"
                active={getIsActive("lease-contracts")}
                inner
                icon={
                  getIsActive("lease-contracts") ? dotFilledIcon : dotEmptyIcon
                }
              >
                Hyresavtal
              </SC.SidebarItem>
              <SC.SidebarItem
                closedModeInner={!sideBarOpen}
                to="/other-contracts"
                active={getIsActive("other-contracts")}
                inner
                icon={
                  getIsActive("other-contracts") ? dotFilledIcon : dotEmptyIcon
                }
              >
                Övriga avtal
              </SC.SidebarItem>
              <SC.SidebarItem
                to="/service-contracts"
                active={getIsActive("service-contracts")}
                inner
                closedModeInner={!sideBarOpen}
                icon={
                  getIsActive("service-contracts")
                    ? dotFilledIcon
                    : dotEmptyIcon
                }
              >
                Serviceavtal
              </SC.SidebarItem>

              <SC.SidebarItem
                to="/parking-contracts"
                active={getIsActive("parking-contracts")}
                inner
                closedModeInner={!sideBarOpen}
                icon={
                  getIsActive("parking-contracts")
                    ? dotFilledIcon
                    : dotEmptyIcon
                }
              >
                Parkeringsavtal
              </SC.SidebarItem>

              <SC.SidebarItem
                to="/documents"
                active={getIsActive("documents")}
                inner
                closedModeInner={!sideBarOpen}
                icon={getIsActive("documents") ? dotFilledIcon : dotEmptyIcon}
              >
                Dokument
              </SC.SidebarItem>

              <SC.SidebarItem
                to="/templates"
                active={getIsActive("templates")}
                inner
                closedModeInner={!sideBarOpen}
                last
                icon={getIsActive("templates") ? dotFilledIcon : dotEmptyIcon}
              >
                Avtalsmallar
              </SC.SidebarItem>
            </>
          )}

          <SC.SidebarToggleItem
            closedMode={!sideBarOpen}
            onClick={() => handleToggle("rentals")}
            isOpen={open === "rentals"}
            icon={rentalsIcon}
            active={getOuterIsActive("RENTALS")}
          >
            {sideBarOpen && <>{hasBrfPermission ? "Objekt" : "Hyresobjekt"}</>}
          </SC.SidebarToggleItem>
          {open === "rentals" && (
            <>
              {hasBrfPermission && (
                <SC.SidebarItem
                  to="/brf-premises"
                  active={getIsActive("brf-premises")}
                  inner
                  closedModeInner={!sideBarOpen}
                  icon={
                    getIsActive("brf-premises") ? dotFilledIcon : dotEmptyIcon
                  }
                >
                  Bostadsrätter
                </SC.SidebarItem>
              )}

              {/* <SC.SidebarItem
              to="/owner-apartments"
              active={getIsActive("/owner-apartments")}
              inner
              closedModeInner={!sideBarOpen}

              icon={
                getIsActive("/owner-apartments") ? dotFilledIcon : dotEmptyIcon
              }
            >
              Ägarlägenheter
            </SC.SidebarItem> */}

              <SC.SidebarItem
                to="/apartments"
                active={getIsActive("apartments")}
                inner
                closedModeInner={!sideBarOpen}
                icon={getIsActive("apartments") ? dotFilledIcon : dotEmptyIcon}
              >
                Hyreslägenheter
              </SC.SidebarItem>

              <SC.SidebarItem
                to="/industrial-premises"
                active={getIsActive("industrial-premises")}
                inner
                closedModeInner={!sideBarOpen}
                icon={
                  getIsActive("industrial-premises")
                    ? dotFilledIcon
                    : dotEmptyIcon
                }
              >
                Lokaler
              </SC.SidebarItem>

              <SC.SidebarItem
                to="/common-areas"
                active={getIsActive("common-areas")}
                inner
                closedModeInner={!sideBarOpen}
                last
                icon={
                  getIsActive("common-areas") ? dotFilledIcon : dotEmptyIcon
                }
              >
                Gemensamma ytor
              </SC.SidebarItem>
            </>
          )}

          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/parking"
            icon={carIcon}
            active={getIsActive("parking")}
          >
            {sideBarOpen && "Parkeringar"}
          </SC.SidebarItem>

          {hasKeyPerms && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/keys"
              icon={keyIcon}
              active={getIsActive("keys")}
            >
              {sideBarOpen && "Nycklar"}
            </SC.SidebarItem>
          )}
        </SC.Chapter>

        <SC.Chapter closedMode={!sideBarOpen}>
          {hasBillectaViewPermission && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/rental-notifications"
              active={getIsActive("rental-notifications")}
              icon={
                getIsActive("rental-notifications")
                  ? accountBalanceIconActive
                  : accountBalanceIcon
              }
            >
              {!sideBarOpen && billectaEventsCount > 0 && (
                <SC.ActionDot>
                  {billectaEventsCount > 99 ? "99+" : billectaEventsCount}
                </SC.ActionDot>
              )}
              {sideBarOpen && (
                <>
                  Hyresavisering{" "}
                  {billectaEventsCount ? `(${billectaEventsCount})` : ""}
                </>
              )}
            </SC.SidebarItem>
          )}

          {shouldShowErrands &&
            (hasReportErrandPermission ||
              (hasInspectionErrandPermission && hasReportErrandPermission) ||
              (hasRoundingErrandPermission && hasReportErrandPermission) ||
              (hasGovInspectionPermission && hasRoundingErrandPermission)) && (
              <>
                <SC.SidebarToggleItem
                  closedMode={!sideBarOpen}
                  onClick={() => handleToggle("errands")}
                  isOpen={open === "errands"}
                  icon={handyman}
                  active={getOuterIsActive("ERRANDS")}
                >
                  {sideBarOpen && "Teknisk förvaltning"}
                </SC.SidebarToggleItem>
                {open === "errands" && (
                  <>
                    {hasReportErrandPermission && (
                      <SC.SidebarItem
                        to="/report-errands"
                        inner
                        closedModeInner={!sideBarOpen}
                        active={getIsActive("report-errands")}
                        icon={
                          getIsActive("report-errands")
                            ? dotFilledIcon
                            : dotEmptyIcon
                        }
                      >
                        Felanmälningar
                      </SC.SidebarItem>
                    )}
                    {hasInspectionErrandPermission &&
                      hasReportErrandPermission && (
                        <SC.SidebarItem
                          to="/inspection-errands"
                          inner
                          closedModeInner={!sideBarOpen}
                          active={getIsActive("inspection-errands")}
                          icon={
                            getIsActive("inspection-errands")
                              ? dotFilledIcon
                              : dotEmptyIcon
                          }
                        >
                          Besiktningar
                        </SC.SidebarItem>
                      )}
                    {hasRoundingErrandPermission &&
                      hasReportErrandPermission && (
                        <SC.SidebarItem
                          to="/rounding-errands"
                          inner
                          closedModeInner={!sideBarOpen}
                          active={getGovOrRoundingActive(
                            "rounding-errands",
                            "rounding-settings"
                          )}
                          icon={
                            getGovOrRoundingActive(
                              "rounding-errands",
                              "rounding-settings"
                            )
                              ? dotFilledIcon
                              : dotEmptyIcon
                          }
                        >
                          Ronderingar
                        </SC.SidebarItem>
                      )}
                    {((hasGovInspectionPermission &&
                      hasRoundingErrandPermission) ||
                      (hasReportErrandPermission &&
                        hasRoundingErrandPermission)) && (
                      <SC.SidebarItem
                        to="/gov-errands"
                        inner
                        closedModeInner={!sideBarOpen}
                        active={getGovOrRoundingActive(
                          "gov-errands",
                          "gov-settings"
                        )}
                        icon={
                          getGovOrRoundingActive("gov-errands", "gov-settings")
                            ? dotFilledIcon
                            : dotEmptyIcon
                        }
                      >
                        Myndighetskrav
                      </SC.SidebarItem>
                    )}
                    {user_type !== 2 &&
                      (hasReportErrandPermission ||
                        hasInspectionErrandPermission) && (
                        <>
                          <SC.SidebarItem
                            to="/errand-settings"
                            inner
                            closedModeInner={!sideBarOpen}
                            active={getIsActive("errand-settings")}
                            icon={
                              getIsActive("errand-settings")
                                ? dotFilledIcon
                                : dotEmptyIcon
                            }
                          >
                            Inställningar
                          </SC.SidebarItem>
                        </>
                      )}
                  </>
                )}
              </>
            )}

          {canImd && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/imd"
              active={getIsActive("imd")}
              icon={getIsActive("imd") ? IMDIconActive : IMDIcon}
            >
              {sideBarOpen && <>IMD</>}
            </SC.SidebarItem>
          )}

          {canMarket && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/market"
              active={getIsActive("market")}
              icon={getIsActive("market") ? marketIconActive : marketIcon}
            >
              {sideBarOpen && <>Marknad</>}
            </SC.SidebarItem>
          )}
        </SC.Chapter>

          {hasReportViewPerms && (
          <SC.Chapter closedMode={!sideBarOpen}>
              <SC.SidebarItem
                closedMode={!sideBarOpen}
                to="/reports"
                active={getIsActive("reports")}
                style={{ color: "rgb(52, 106, 68)" }}
                icon={excelIcon}
              >
                {sideBarOpen && "Rapporter"}
              </SC.SidebarItem>
          </SC.Chapter>
        )}

        {process.env.NODE_ENV === "development" && (
          <SC.Chapter closedMode={!sideBarOpen}>
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/iframe/test"
              active={getIsActive("iframe")}
              icon={devIcon}
            >
              {sideBarOpen && "IFrame testmiljö"}
            </SC.SidebarItem>
          </SC.Chapter>
        )}
      </>
    );
  };

  const renderOpenClose = () => {
    if (!sideBarOpen) {
      return (
        <div style={{ width: 25, margin: "4px auto" }}>
          <SC.ExpandMenuIcon onClick={() => setSideBarOpen(true)} />
        </div>
      );
    } else {
      return (
        <div style={{ position: "absolute", right: 6, top: 76 }}>
          <SC.CloseMenuIcon onClick={() => setSideBarOpen(false)} />
        </div>
      );
    }
  };

  return (
    <SC.MainWrapper open={sideBarOpen}>
      {sideBarOpen ? <Logo /> : <MiniLogo />}

      {renderOpenClose()}

      {renderContent()}
    </SC.MainWrapper>
  );
};
