import * as React from "react";

export default () => {
  return (
    <div>
      Välj den mätartyp som importen gäller för.
      <br />
      <br />
      Finns inte mätartypen så kan den skapas upp under "IMD" -&gt; "Mätartyper"
    </div>
  );
};
