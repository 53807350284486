import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import FullPageSpinner from "../../../components/Loaders/FullPageSpinner";
import StandardModal from "../../../components/Modals/StandardModal";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  detailUrl as brfCompanyDetailUrl,
  getKU55Data,
  insertKU55Logs,
  useBrfCompany,
} from "../../../store/brfCompanies";
import KU55ConfigTable from "../../Tables/BrfPremises/KU55ConfigFullTable";

import { detailUrl as brfOwnerDetailUrl } from "../../../store/brfOwner";
import { detailUrl as brfPremisDetailUrl } from "../../../store/brfPremises";

import { set } from "lodash";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";
import NonConnectedCheckbox from "../Base/Old/NonConnected/NonConnectedCheckbox";
import NonConnectedInputField from "../Base/Old/NonConnected/NonConnectedInputField";

import { EllipsisVerticalIcon, PrinterIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useDispatch } from "react-redux";
import BrfManualCapitalAddonModalForm from "src/components/Forms/BrfManualCapitalAddon/ChapterForm/ModalForm";
import { toMoneyString } from "src/components/utils/stringUtils";
import { buildQueryString } from "src/store/base";
import { useFilteredBrfManualCapitalAddons } from "src/store/brfManualCapitalAddon";
import convert from "xml-js";
import { TOAST_TYPES, addToast } from "../../../store/toasts";
import Alert from "../../Alert/Alert";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import PrimaryBtn from "../Base/Buttons/PrimaryBtn";
import StackedButtons from "../Base/Buttons/StackedButtons";
import NonConnectedNumberInput from "../Base/Old/NonConnected/NonConnectedNumberInput";
import NonConnectedSelect from "../Base/Old/NonConnected/NonConnectedSelect";
import LocalTextInputField from "../Base/Fields/LocalTextInputField";

export default function KU55HandlingSelection() {
  const dispatch = useDispatch();

  const [exclusions, setExclusions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { brfCompanyId } = useParams();
  const { push, goBack } = useHistory();

  const [transactionPercentage, setTransactionPercentage] = React.useState("");

  const [customCapitalAdditions, setCustomCapitalAdditions] = React.useState(
    []
  );
  const [customCapitalAdditionsOpen, setCustomCapitalAdditionsOpen] =
    React.useState(false);

  const [errors, setErrors] = React.useState(undefined);

  const [brfCompany, brfCompanyLoading] = useBrfCompany(brfCompanyId);

  const [commonData, setCommonData] = React.useState(undefined);
  const [includedTransactions, setIncludedTransactions] =
    React.useState(undefined);
  const [selectionIndex, setSelectionIndex] = React.useState(0);

  React.useEffect(() => {
    setTransactionPercentage("");
  }, [selectionIndex]);

  const firstAvailableYear = 2022;
  const currentYear = moment().year();
  const [forYear, setForYear] = React.useState(currentYear);

  const [askExclude, setAskExclude] = React.useState(false);

  const [createManualAddonOpen, setCreateManualAddonOpen] =
    React.useState(false);

  const [editManualAddonOpen, setEditManualAddonOpen] = React.useState(false);

  const [manualAddons, manualAddonsLoading] = useFilteredBrfManualCapitalAddons(
    buildQueryString({ brf_company: brfCompanyId })
  );

  const includeQ = {
    brf_company: brfCompanyId,
    id__in: exclusions ?? [],
  };

  const excludeQ = {
    brf_company: brfCompanyId,

    "id__in!": exclusions?.length ? exclusions : undefined,
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const res = await getKU55Data(
        brfCompanyId,
        forYear,
        false,
        exclusions ?? [],
        customCapitalAdditions
      );
      const data = res?.data?.data;
      setLoading(false);
      setIncludedTransactions(data.data);

      if (!data.data) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title:
              "Ingen av de valda objekten har några transaktioner för den angivna perioden",
          })
        );
      }
      setCommonData(data.common);
    } catch (error) {
      if (error?.response?.data) {
        setErrors(error.response?.data);
      } else {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title:
              "Ingen av de valda objekten har några transaktioner för den angivna perioden",
          })
        );
      }
      setLoading(false);
    }
  };

  const handleExclude = (row) => {
    const id = row.original.id;

    const newExl = [...exclusions, id];

    setExclusions(newExl);
  };

  const handleInclude = (row) => {
    const nexExcl = [...(exclusions ?? [])].filter(
      (item) => item !== row.original.id
    );
    setExclusions(nexExcl);
  };

  const isSelectionMode = includedTransactions == null;

  const updateTransaction = (index, key, value) => {
    const res = [...includedTransactions];

    const item = res[index];
    if (item == null) {
      return;
    }

    set(item, `data.${key}`, value);
    res.splice(index, 1, item);
    setIncludedTransactions(res);
  };

  const _convertFieldItemToXmlJS = (item) => {
    let res = {};

    Object.keys(item).forEach((key) => {
      let val = item[key];

      let attrs = {};
      let actualName = key;

      const spl = key.split(" ");
      if (spl.length == 2) {
        actualName = spl[0];

        if (spl[1].includes("=")) {
          attrs[spl[1].split("=")[0]] = spl[1]
            .split("=")[1]
            .replaceAll('"', "");
        }
      }

      if (val.constructor == Object) {
        res[actualName] = {
          _attributes: attrs,
          ..._convertFieldItemToXmlJS(val),
        };
      } else {
        if (val === "" || val == null || val === false) {
          return;
        }
        if (val === true) {
          val = "1";
        }

        res[actualName] = {
          _attributes: attrs,
          _text: val,
        };
      }
    });

    return res;
  };

  const onNextItemHandling = async () => {
    if (selectionIndex + 1 == includedTransactions.length) {
      const data = {
        _declaration: {
          _attributes: {
            version: "1.0",
            encoding: "utf-8",
            standalone: "no",
          },
        },
        [commonData.wrapper_tag]: {
          _attributes: commonData.wrapper_attributes,
          ...commonData.common_children,
          "ku:Blankett": includedTransactions?.map((item) => {
            return {
              _attributes: { nummer: "2324" },
              ..._convertFieldItemToXmlJS(item.data),
            };
          }),
        },
      };

      const result = convert.js2xml(data, { compact: true });
      if (!result) {
        dispatch(
          addToast({
            title: "Misslyckades med att generera fil",
            description: "Försök igen",
            type: TOAST_TYPES.ERROR,
          })
        );
      } else {
        const logData = includedTransactions?.map((incl) => [
          incl.meta.transaction_id,
          incl.meta.seller_id,
        ]);
        try {
          await insertKU55Logs(brfCompanyId, logData);
        } catch (error) {
          dispatch(
            addToast({
              title: "Misslyckades med att generera fil",
              description: "Försök igen",
              type: TOAST_TYPES.ERROR,
            })
          );

          return;
        }

        const blob = new Blob([result], { type: "application/xml" });
        const link = window.URL.createObjectURL(blob);

        const atag = document.createElement("a");
        atag.href = link;
        atag.download = `${
          brfCompany?.company?.name ?? brfCompanyId
        }_ku55_${forYear}.xml`;
        atag.click();

        dispatch(
          addToast({
            title: "Underlag Laddades ner",
            type: TOAST_TYPES.SUCCESS,
          })
        );

        push(brfCompanyDetailUrl({ id: brfCompanyId }));
      }
    } else {
      setSelectionIndex(selectionIndex + 1);
    }
  };

  const onPreviousItemHandling = () => {
    if (selectionIndex == 0) {
      setIncludedTransactions(undefined);
      setCommonData(undefined);
    } else {
      setSelectionIndex(selectionIndex - 1);
    }
  };

  const onExcludeItem = () => {
    const res = [...includedTransactions];

    if (
      selectionIndex == includedTransactions.length - 1 &&
      selectionIndex != 0
    ) {
      setSelectionIndex(selectionIndex - 1);
    }

    res.splice(selectionIndex, 1);

    setIncludedTransactions(res);

    dispatch(
      addToast({
        title: "Transaktionen för säljaren togs bort",
        type: TOAST_TYPES.SUCCESS,
      })
    );
  };

  let actionOptions = !isSelectionMode
    ? [
        {
          title:
            selectionIndex + 1 == includedTransactions.length
              ? "Ladda ner fil och avsluta"
              : "Nästa",
          onClick: onNextItemHandling,
        },
        {
          title: "Exkludera",
          type: "error",
          onClick: () => setAskExclude(true),
          iconType: "close",
        },
      ]
    : [];

  if (selectionIndex !== 0) {
    actionOptions.splice(1, 0, {
      title: "Föregående",
      onClick: onPreviousItemHandling,
      iconType: "arrow-back",
    });
  }

  return (
    <>
      {loading && <FullPageSpinner />}

      <ConfirmationModal
        isOpen={askExclude}
        title={"Exkludera transaktionen från underlaget"}
        renderContent={() =>
          "Är du säker på att du vill exkludera denna transaktion från underlaget?"
        }
        acceptCallback={onExcludeItem}
        closeFunction={() => setAskExclude(false)}
      />
      <StandardModal
        isOpen={!!errors}
        forceCloseFunction={() => setErrors(undefined)}
        title={"Felmeddelanden"}
      >
        {(typeof errors == "string"
          ? [errors]
          : typeof errors === "object"
          ? Object.values(errors || {})
          : errors ?? []
        )?.map((value) => {
          const isPremis = value?.content_type == "brf.brfpremis";
          const isBrfMember = value?.content_type == "brf.brfowner";

          if (!isPremis && !isBrfMember) {
            return <div>{value || "Okänt fel"}</div>;
          }
          return (
            <>
              <TextButton
                title={`${isPremis ? "Lägenheten" : "Medlemmen"} ${
                  value.str_representation
                }`}
                clicked={() =>
                  isPremis
                    ? push(brfPremisDetailUrl({ id: value.id }))
                    : push(brfOwnerDetailUrl({ id: value.id }))
                }
              ></TextButton>{" "}
              har felet: {value.message}
              <br />
              <br />
            </>
          );
        })}
      </StandardModal>
      <DetailInnerWrapper>
        <TextButton
          title="Tillbaka"
          clicked={
            isSelectionMode
              ? () => goBack()
              : () => {
                  setIncludedTransactions(undefined);
                  setCommonData(undefined);
                  setSelectionIndex(0);
                }
          }
          iconType={"arrow-back"}
        />
        <DetailPageBox>
          {isSelectionMode && (
            <Alert title="Hur fungerar export av KU55?">
              <p className="mt-2">
                Detta verktyg genererar underlag för KU55 som ska lämnas till
                Skatteverket. Transaktioner som skett under det angivna året
                hämtas automatiskt genom att klicka på knappen fortsätt och
                uppgifterna för bostadsrätten fylls då i automatiskt. Dessa
                uppgifter kan sen laddas som en fil som ni kan ladda upp på
                Skatteverkets hemsida.
              </p>

              <p className="mt-2">
                Notera att denna funktion inte skickar uppgifterna direkt till
                Skatteverket utan enbart genererar en fil.
              </p>

              <p className="mt-2">
                <strong>
                  Om information kring föreningens lån saknas i Pigello
                </strong>{" "}
                används funktionen nedan, "Ställ in manuella kapitaltillskott",
                för att fylla i informationen manuellt. Föreningens totala
                amortering anges då för respektive år. Detta gör att
                kapitaltillskottet räknas ut för respektive objekt.
              </p>

              <DetailPageBox>
                <OverviewTitle small>Manuella kapitaltillskott</OverviewTitle>
                <OverviewSubtitle className="mb-2">
                  <TextButton
                    title={"Lägg till"}
                    iconType="add"
                    iconPlacement="right"
                    clicked={() => setCreateManualAddonOpen(true)}
                  />
                </OverviewSubtitle>

                {createManualAddonOpen && (
                  <BrfManualCapitalAddonModalForm
                    method={"POST"}
                    brfCompanyId={brfCompanyId}
                    closeFunction={() => setCreateManualAddonOpen(false)}
                  />
                )}
                {!!editManualAddonOpen && (
                  <BrfManualCapitalAddonModalForm
                    method={"PATCH"}
                    instance={editManualAddonOpen}
                    id={editManualAddonOpen.id}
                    closeFunction={() => setEditManualAddonOpen(false)}
                  />
                )}

                {!manualAddons?.length && !manualAddonsLoading && (
                  <InnerBox>Inga manuella kapitaltillskott tillagda</InnerBox>
                )}

                {manualAddons?.map((addon) => (
                  <InnerBox>
                    <div className="flex justify-between items-start">
                      <div>
                        <div className="text-xs">{addon.as_of_date}</div>
                        <div className="font-medium">
                          {toMoneyString(addon.value)} SEK
                        </div>
                      </div>

                      <button
                        className="bg-slate-100 border border-slate-200 hover:bg-blue-100 rounded p-2"
                        onClick={() => setEditManualAddonOpen(addon)}
                      >
                        <EllipsisVerticalIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </InnerBox>
                ))}
              </DetailPageBox>
            </Alert>
          )}
          <OverviewTitleWrapper style={{ alignItems: "flex-start" }}>
            <OverviewTitleWithSubtitleWrapper>
              {isSelectionMode ? (
                <>
                  <OverviewTitle>
                    Objekt som ska användas för{" "}
                    {brfCompany?.company?.name ?? ""} KU55
                  </OverviewTitle>
                  <OverviewSubtitle style={{ marginRight: 40 }}>
                    Kontrollera att KU55 underlag ska genereras för dessa objekt
                  </OverviewSubtitle>
                </>
              ) : (
                <>
                  <OverviewTitle>
                    Verifiera och Revidera underlag för{" "}
                    {brfCompany?.company?.name ?? ""} KU55
                  </OverviewTitle>
                  <OverviewSubtitle style={{ marginRight: 40 }}>
                    Kontrollera att underlaget stämmer, justera eventuella
                    värden, och fortsätt sedan med att generera själva KU55
                    underlaget
                  </OverviewSubtitle>
                </>
              )}
            </OverviewTitleWithSubtitleWrapper>

            {isSelectionMode ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ marginRight: "8px" }}>Avseende år</div>
                  <NonConnectedSelect
                    extraStyles={{ minWidth: "200px" }}
                    noMargin
                    choices={[
                      ...Array(currentYear - firstAvailableYear + 1).keys(),
                    ].map((tic) => {
                      return {
                        d: firstAvailableYear + tic,
                        c: firstAvailableYear + tic,
                      };
                    })}
                    value={forYear}
                    onUpdate={(val) => (val != null ? setForYear(val) : null)}
                    getOptionValue={(item) => item.c}
                    getOptionLabel={(item) => item.d}
                  />
                </div>
                <PrimaryButton title="Fortsätt" clicked={onSubmit} />
              </div>
            ) : (
              !!includedTransactions?.length && (
                <div>
                  <div>
                    {selectionIndex + 1} av {includedTransactions.length}
                  </div>
                  <StackedButtons options={actionOptions} />
                </div>
              )
            )}
          </OverviewTitleWrapper>

          {isSelectionMode ? (
            <>
              <KU55ConfigTable
                hideTitle
                persistantQuery={excludeQ}
                onExcludeClicked={(row) => handleExclude(row)}
              />

              <OverviewTitle style={{ marginTop: "32px" }}>
                Objekt som ej används
              </OverviewTitle>
              <KU55ConfigTable
                hideTitle
                persistantQuery={includeQ}
                onExcludeClicked={(row) => handleInclude(row)}
                showAsInclude={true}
              />
            </>
          ) : (
            <>
              {!!includedTransactions?.length ? (
                <InnerBox>
                  <OverviewTitleWrapper className="border-b pb-2">
                    <OverviewTitle small>
                      <div className="flex justify-between items-end">
                        <div className="mb-2">
                          För lägenhet{" "}
                          {
                            includedTransactions[selectionIndex].meta
                              .str_representation
                          }
                        </div>
                        <PrimaryBtn
                          className="ml-2"
                          secondary
                          onClick={() => {
                            localStorage.setItem(
                              "ku55printdata",
                              JSON.stringify(
                                includedTransactions[selectionIndex].data
                              )
                            );
                            localStorage.setItem(
                              "ku55printdataExtra",
                              JSON.stringify({
                                transactionPercentage,
                              })
                            );
                            window.open(
                              `${window.location.origin}/ku55pdfprintview`,
                              "Test",
                              {
                                toolbar: false,
                                menubar: false,
                                location: false,
                              }
                            );
                          }}
                        >
                          Öppna för utskrift{" "}
                          <PrinterIcon className="h-4 w-4 ml-2" />
                        </PrimaryBtn>
                        <div className="ml-2">
                          <LocalTextInputField
                            value={transactionPercentage}
                            onChange={(val) => setTransactionPercentage(val)}
                            title="Överlåten andel i % (enbart för utskrift)"
                          />
                        </div>
                      </div>
                    </OverviewTitle>
                  </OverviewTitleWrapper>

                  <DetailInfo
                    infoObj={getInfoObjForTransaction(
                      includedTransactions[selectionIndex].data,
                      null,
                      null,
                      (key, value) =>
                        updateTransaction(selectionIndex, key, value)
                    )}
                  />
                </InnerBox>
              ) : (
                <InnerBox
                  style={{
                    minHeight: 200,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginBottom: "8px" }}>
                    Det finns inga transaktioner att rapportera in
                  </div>
                  <PrimaryButton
                    title={"Gå tillbaka"}
                    clicked={() => setIncludedTransactions(undefined)}
                  />
                </InnerBox>
              )}
            </>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const CHECKBOX = 0;
const STRING = 1;
const INT = 2;

const fieldNumberMapping = {
  210: ["Rättelse", CHECKBOX],
  203: ["Inkomstår", STRING],
  570: ["Specifikationsnummer", STRING],
  631: ["Överlåtelsedatum", STRING],
  632: ["Överlåten andel i %", STRING],
  633: [
    "Överlåtelsen har skett genom arv, gåva, bodelning eller liknande",
    CHECKBOX,
  ],
  634: ["Överlåtelsepris", INT],
  635: [
    "Bostadsrättens andel av innestående medel på inre reparationsfond vid överlåtelsen",
    INT,
  ],
  636: ["Kapitaltillskott", INT],
  638: ["Oäkta Bostadsförening", CHECKBOX],
  640: ["Förvärvsdatum", STRING],
  643: ["Förvärvspris", INT],
  644: [
    "Bostadsrättens andel av innestående medel på inre reparationsfond vid förvärvet",
    INT,
  ],
  645: ["Andel av behållen förmögenhet 1974", INT],
  646: ["Gemensam/Individuell", STRING],
  215: ["Inkomsttagare", STRING],
  216: ["Förnamn", STRING],
  217: ["Efternamn", STRING],
  218: ["Gatuadress", STRING],
  219: ["Postnummer", STRING],
  220: ["Postort", STRING],
  226: ["Organisationsnamn", STRING],
  201: ["Uppgiftslämnare", STRING],
  202: ["Namn Uppgiftslämnare", STRING],
  630: ["Bostadsrättsbeteckning", STRING],
};
const fieldNameMapping = {
  Arendeinformation: "Generellt",
  Arendeagare: "Ärende Ägare",
  KU55: "Transaktioner",
  InkomsttagareKU55: "Överlåtare",
  UppgiftslamnareKU55: "Uppgiftslämnare",
};

const getInfoObjForTransaction = (
  transaction,
  title,
  totalKey,
  updateTransaction
) => {
  let data = [];
  let mainObj = {
    [title ?? "Information"]: data,
  };

  Object.keys(transaction).forEach((key) => {
    const val = transaction[key];

    const keyParts = key.split(" ");

    let actualTitle = key.replaceAll("ku:", "");

    let actualValue = val;

    if (keyParts.length == 2 && keyParts[1].includes("=")) {
      const fieldNumber = keyParts[1].split("=")[1].replaceAll('"', "");
      const [_title, fieldInputKind] = fieldNumberMapping[fieldNumber] ?? [
        keyParts[0].replaceAll("ku:", ""),
        null,
      ];
      actualTitle = `${_title} (${fieldNumber})`;

      if (fieldInputKind != null) {
        switch (fieldInputKind) {
          case CHECKBOX:
            actualValue = (
              <NonConnectedCheckbox
                id={`c-${key}`}
                title="&nbsp;"
                value={!!val}
                onChange={(checked) =>
                  updateTransaction(
                    !!totalKey ? `${totalKey}.${key}` : key,
                    checked
                  )
                }
                noMargin
              />
            );
            break;
          case STRING:
            actualValue = (
              <NonConnectedInputField
                id={`i-${key}`}
                title=""
                value={val}
                onChange={(event) =>
                  updateTransaction(
                    !!totalKey ? `${totalKey}.${key}` : key,
                    event.target.value
                  )
                }
                noMargin
                extraStyles={{ marginLeft: "8px" }}
              />
            );
            break;
          case INT:
            actualValue = (
              <NonConnectedNumberInput
                id={`n-${key}`}
                noTitle
                value={val}
                onUpdate={(val) => {
                  updateTransaction(
                    !!totalKey ? `${totalKey}.${key}` : key,
                    val
                  );
                }}
                noMargin
                extraStyle={{ marginLeft: "8px" }}
              />
            );
            break;

          default:
            break;
        }
      }
    } else {
      actualTitle = fieldNameMapping[actualTitle] ?? actualTitle;
    }

    if (val.constructor == Object) {
      mainObj = {
        ...mainObj,
        ...getInfoObjForTransaction(
          val,
          actualTitle,
          !!totalKey ? `${totalKey}.${key}` : key,
          updateTransaction
        ),
      };
    } else {
      data.push({
        title: actualTitle,
        value:
          typeof actualValue === "boolean"
            ? actualValue
              ? "Ja"
              : "Nej"
            : actualValue,
      });
    }
  });

  let res = {};
  Object.keys(mainObj).forEach((k) => {
    if (mainObj[k].length) {
      res[k] = mainObj[k];
    }
  });
  return res;
};
