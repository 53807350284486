import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

import BRFTable from "../../../components/Tables/BrfPremises/FullTable";
import ApartmentTable from "../../../components/Tables/Apartments/FullTableNewVersion";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import CommonAreasTable from "src/components/Tables/CommonArea/FullTable";
import ParkingSpotsTable from "../../../components/Tables/Parking/ParkingSpots/FullTable";

const TABS = {
  APARTMENTS: "Lägenheter",
  INDUSTRIAL_PREMISES: "Lokaler",
  COMMON_AREAS: "Gemensamma ytor",
  PARKING_SPOTS: "Fordonsplatser",
  BRF: "Bostadsrätter",
};

export default function IMDObjects() {
  const { filteredRealEstates } = useActiveFilters();
  const [selectedTab, setSelectedTab] = React.useState("APARTMENTS");

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
    imd_sensor_count_gte: 1,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Objekt med IMD-sensorer kopplade</OverviewTitle>
        </OverviewTitleWrapper>

        <PageSlider
          {...{
            TABS,
            selectedTab,
            onTabSelected: (tab) => setSelectedTab(tab),
          }}
        />

        {selectedTab === "APARTMENTS" && (
          <ApartmentTable {...{ persistantQuery }} ignoreLocalStorage />
        )}

        {selectedTab === "INDUSTRIAL_PREMISES" && (
          <IndustrialPremisesTable
            {...{ persistantQuery }}
            ignoreLocalStorage
          />
        )}

        {selectedTab === "COMMON_AREAS" && (
          <CommonAreasTable {...{ persistantQuery }} ignoreLocalStorage />
        )}

        {selectedTab === "PARKING_SPOTS" && (
          <ParkingSpotsTable {...{ persistantQuery }} ignoreLocalStorage />
        )}

        {selectedTab === "BRF" && (
          <BRFTable {...{ persistantQuery }} ignoreLocalStorage />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
