export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_REPORTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_REPORTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_REPORTS",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_REPORTS",
  DESTROY_FORM: "DESTROY_FORM_REPORTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_REPORTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_REPORTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_REPORTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_REPORTS",
  RESET_STATE: "RESET_STATE_REPORTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_REPORTSS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_REPORTS",
  REMOVE_OBJECT: "REMOVE_OBJECTL_REPORTS",
  SET_AVAILABLE_EXCEL_REPORTS: "SET_AVAILABLE_EXCEL_REPORTS",
  SET_AVAILABLE_PPT_REPORTS: "SET_AVAILABLE_PPT_REPORTS",
  SET_EXCLUDED_COLUMNS: "SET_EXCLUDED_COLUMNS_REPORTS",
  SET_EXCLUDED_SHEETS: "SET_EXCLUDED_SHEETS_REPORTS",
  SET_EXCLUDED_OBJECT_TYPES: "SET_EXCLUDED_OBJECT_TYPES_REPORTS",
  SET_REPORT_TITLE: "SET_REPORT_TITLE_REPORTS",
  SET_KWARGS: "SET_KWARGS_REPORTS",
  CLEAR_SETTINGS: "CLEAR_SETTINGS_REPORTS",
  SET_AVAILABLE_KWARGS: "SET_AVAILABLE_KWARGS",

  LIST_URL: "/reporting/generators/excell/performedreport/list/",
  GET_URL: "/reporting/generators/excell/performedreport/",
  EXCEL_AVAILABLE_REPORTS_URL:
    "/reporting/generators/excell/available_reports/",
  EXCEL_CREATE_REPORT_URL: "/reporting/generators/excell/run_report/",
  EXCEL_RERUN_REPORT_URL: "/reporting/generators/excell/rerun_report/",

  PPT_AVAILABLE_REPORTS_URL:
    "/reporting/generators/powerpoint/available_reports/",
  PPT_CREATE_REPORT_URL: "/reporting/generators/powerpoint/run_report/",
  PPT_RERUN_REPORT_URL: "/reporting/generators/powerpoint/rerun_report/",

  STORE_NAME: "excelReports",
};
