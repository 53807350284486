import * as React from "react";
import { constants } from "../../../../../store/leaseContracts";
import TableSelectField from "../../../Base/Fields/TableSelectField";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import TenantNestedChildren from "src/components/Forms/Tenant/NestedChildren";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey="tenant"
          method={method}
          placeholder="Välj hyresgäst..."
          title="Hyresgäst"
          description="Välj primär hyresgäst på avtalet. Denna hyresgäst är även mottagare av avier för avtalet om avisering är aktiverat."
          TableComponent={TenantTable}
          createRenderFunction={(obj) => {
            return obj?.user?.corporate_name
              ? obj.user.corporate_name
              : `${obj?.user?.first_name} ${obj?.user?.last_name}`;
          }}
        >
          {(parentPath) => (
            <TenantNestedChildren
              storeName={storeName}
              method={method}
              parentInstructionsPath={"tenant"}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey="bi_tenants"
          isMany
          method={method}
          placeholder="Välj hyresgäster..."
          title="Ytterligare hyresgäster"
          description="Välj ytterligare hyresgäst på avtalet."
          TableComponent={TenantTable}
          createRenderFunction={(obj) =>
            obj?.user?.corporate_name
              ? obj.user.corporate_name
              : `${obj?.user?.first_name} ${obj?.user?.last_name}`
          }
        >
          {(parentPath) => (
            <TenantNestedChildren
              storeName={storeName}
              method={method}
              parentInstructionsPath={"tenant"}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey="landlord"
          method={method}
          placeholder="Välj hyresvärd..."
          title="Hyresvärd"
          description="Välj hyresvärd på avtalet. Denna hyresvärd kommer även att sättas som hyresvärd på de objekt avtalet gäller så fort avtalet börjar gälla."
          TableComponent={CompanyTable}
        />
      </div>
    </>
  );
};
