import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import * as SharedStyles from "../../../components/sharedStyles";

import SettingsTable from "../../../components/Tables/InvoicingSettings/FullTable";

import InvoicingSettingModalForm from "../../../components/ConfigCenter/InvoicingSetting/ChapterForm/ModalForm";

import PageTitle from "../../Layouts/PageTitle/PageTitle";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const breadCrumbs = [
  {
    label: "Aviseringsinställningar",
  },
];

export default function InvoicingSettings(props) {
  const [createInvoicingSettingOpen, setCreateInvoicingSettingOpen] =
    React.useState(false);

  const ACTION = (
    <div style={{ marginTop: "20px" }}>
      <PrimaryButton
        newDesign
        clicked={() => setCreateInvoicingSettingOpen(true)}
        title="Lägg till ny inställning för avisering"
      ></PrimaryButton>
    </div>
  );
  return (
    <>
      <InvoicingSettingModalForm
        method="POST"
        isOpen={createInvoicingSettingOpen}
        onCheckout={() => setCreateInvoicingSettingOpen(false)}
      />

      <SharedStyles.DetailInnerWrapper>
        <SharedStyles.BareLayoutTitleWrapper>
          <PageTitle
            title="Inställningar - avisering"
            breadCrumbs={breadCrumbs}
          />
        </SharedStyles.BareLayoutTitleWrapper>

        <SharedStyles.DetailPageBox config={true}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Aviseringsinställningar</OverviewTitle>
              <OverviewSubtitle>{ACTION}</OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <SettingsTable />
        </SharedStyles.DetailPageBox>
      </SharedStyles.DetailInnerWrapper>
    </>
  );
}
