import * as React from "react";
import StandardModal from "../../Modals/StandardModal";
import FullTable from "src/components/Tables/OtherContracts/FullTableNewVersion";
import { cleanOtherInvoicingClone } from "../../../store/leaseContracts/utils";
import { constants } from "../../../store/invoicingParking";
import { setActiveFormInstance } from "../../../store/base";
import { useDispatch } from "react-redux";

export default ({
  getPersistantQueryString,
  isOpen,
  closeFunction,
  onDone,
  forceData,
}) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [cloneSource, setCloneSource] = React.useState(null);

  const handleRowClicked = (row) => {
    const { original } = row;

    setCloneSource(original);
  };

  const checkRowHighlighted = (row) => {
    const { original } = row;

    if (cloneSource?.id === original.id) return true;

    return false;
  };

  const handleCloneSourcePicked = async () => {
    const clone = await cleanOtherInvoicingClone(cloneSource);

    dispatch(
      setActiveFormInstance({
        storeName,
        data: { ...clone, ...(forceData || {}) },
      })
    );

    onDone(clone);
  };

  return (
    <StandardModal
      {...{ isOpen, closeFunction }}
      title="Välj avtal att kopiera från"
      withActionBar
      saveFunction={handleCloneSourcePicked}
      actionBarAcceptTitle="Kopiera från valt avtal"
      canAccept={!!cloneSource}
    >
      <FullTable
        {...{ persistantQueryString: getPersistantQueryString() }}
        hideExport
        hideColumns
        hideTitle
        onRowClicked={handleRowClicked}
        checkRowHighlighted={checkRowHighlighted}
      />
    </StandardModal>
  );
};
