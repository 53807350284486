import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { exportEditableDoc } from "../../../components/DigitalDoc/utils";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import Pdf from "../../../components/Pdf/Pdf";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { useEditabledoc, editUrl } from "../../../store/editabledocs";

export default function Template() {
  const { editableDocId } = useParams();
  const [docData, setDocData] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const { push } = useHistory();

  const [editableDoc] = useEditabledoc(editableDocId);

  const printDocument = async ({ editableDoc }) => {
    setLoading(true);

    const data = await exportEditableDoc({ editableDoc, forShow: true });
    setDocData(data);

    setLoading(false);
  };

  React.useEffect(() => {
    if (editableDoc && docData === undefined) {
      printDocument({ editableDoc });
    }
  }, [editableDoc]);

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Förhandsgranskning</OverviewTitle>
            <PrimaryButton
              title="Redigera mall"
              clicked={() => push(editUrl({ id: editableDocId }))}
            />
          </OverviewTitleWrapper>

          <InnerBox>
            {loading && <OverlaySpinner />}
            {docData ? (
              <Pdf uint8={docData} />
            ) : editableDoc?.fastdoc_id ? (
              "Kan inte förhandsvisa FastDok-mall. Tryck på Redigera mall för att autentisera mot FastDok och se mallen."
            ) : (
              "Laddar in mall..."
            )}
          </InnerBox>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
