import * as React from "react";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { EmailTag, PhoneTag } from "../../Displays";
import { BodyText, InnerBox } from "../../sharedStyles";

export default function LeadContacts({ lead }) {
  const buildDetailObj = (contact) => {
    return {
      [contact.str_representation]: [
        {
          title: "Titel",
          value: contact.title,
        },
        {
          title: "E-post",
          value: <EmailTag mail={contact.email} />,
        },
        {
          title: "Telefon",
          value: <PhoneTag phone={contact.phone} />,
        },
      ],
    };
  };

  return (
    <InnerBox style={{ border: 0, boxShadow: 0 }}>
      <OverviewTitleWrapper>
        <OverviewTitle>Kontaktpersoner</OverviewTitle>
      </OverviewTitleWrapper>

      {lead.contact_persons?.map((c) => (
        <DetailInfo key={c.id} infoObj={buildDetailObj(c)} />
      ))}

      {lead.contact_persons?.length === 0 && (
        <InnerBox>
          <BodyText>Inga kontaktpersoner angivna på leaden</BodyText>
        </InnerBox>
      )}
    </InnerBox>
  );
}
