import styled, { css } from "styled-components";
import chevronLeftBlue from "../../assets/svg/play_blue_faded.svg";

export const LayoutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 501;
`;

export const ToolTipWrapper = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.primaryBlueFaded};
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};

  &::after {
    content: "";
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${chevronLeftBlue});
    height: 14px;
    width: 14px;
  }

  ${(p) =>
    p.placement === "top"
      ? css`
          left: calc(50% - ${(p) => p.w / 2}px);
          bottom: calc(100% + 14px);
          &::after {
            bottom: -11px;
            transform: rotate(90deg);
            left: calc(50% - 7px);
          }
        `
      : p.placement === "right"
      ? css`
          left: calc(100% + 14px);
          top: calc(50% - ${(p) => p.h / 2}px);
          &::after {
            top: calc(50% - 7px);
            left: -11px;
            transform: rotate(180deg);
          }
        `
      : p.placement === "bottom"
      ? css`
          left: calc(50% - ${(p) => p.w / 2}px);
          top: calc(100% + 14px);
          &::after {
            top: -11px;
            transform: rotate(270deg);
            left: calc(50% - 7px);
          }
        `
      : p.placement === "left"
      ? css`
          right: calc(100% + 14px);
          top: calc(50% - ${(p) => p.h / 2}px);
          &::after {
            top: calc(50% - 7px);
            right: -11px;
          }
        `
      : null}
`;

export const ToolTipTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  margin-bottom: 8px;
  line-height: ${(p) => p.theme.fontSizes.headerXSmall};
  white-space: wrap;
  pointer-events: auto;
  text-align: left;
`;

export const ToolTipContent = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
  min-width: 200px;
  line-height: ${(p) => p.theme.fontSizes.body};
  white-space: wrap;
  pointer-events: auto;
  text-align: left;
`;

export const ToolTipClose = styled.div`
  cursor: pointer;
  margin-top: 8px;
  pointer-events: auto;
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  text-decoration: underline;
`;
