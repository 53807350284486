import * as React from "react";
import { useTable, usePagination } from "react-table";
import { buildQueryString } from "../../../store/base";

import { useInProgress } from "../../../store/base";

import { Header, Body, Pagination } from "./BaseTableStyles";

import { useBadBadgeFilter } from "./useBadBadgeFilter";

import { debounce } from "lodash";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";

import classNames from "classnames";

import Alert from "../../../components/Alert/Alert";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

export default function BaseTable({
  columns,
  constants,
  paginationMethod,
  persistantQueryString = {},
  rowClassName = "",
  hideHeaders = false,
  title,
  emptyAction,
  emptyText,
  runEmptyActionMethod,
  getBadgeFilterCounts,
  onRowClicked,
  updateLocalQuery,
  didDelete,
  setDidDelete,
}) {
  const storeName = constants.STORE_NAME;

  const initialState = {
    pageSize: 25,
    pageIndex: 0,
  };

  const [currentSearchString, setCurrentSearchString] = React.useState("");
  //Seperate these search string, current for debounce
  //using for the actual search
  const [usingSearchString, setUsingSearchString] = React.useState("");

  const [badgeQuery, setBadgeQuery] = React.useState({});

  const [pageQuerySize, setPageQuerySize] = React.useState(
    initialState.pageSize
  );

  const [currentBackendPage, setCurrentBackendPage] = React.useState(
    initialState.pageIndex + 1
  ); //+1 for backend query

  const [queryString, setQueryString] = React.useState(
    buildQueryString({
      _page: currentBackendPage,
      _page_size: initialState.pageSize,
      ...persistantQueryString,
    })
  );

  const [hasFetchedBadgeFilterCounts, setHasFetchedBadgeFilterCounts] =
    React.useState(false);
  const [currentBadgeFilterCounts, setCurrentBadgeFilterCounts] =
    React.useState({});

  React.useEffect(() => {
    let searchObject = {};

    if (usingSearchString.length !== 0) {
      searchObject.title__icontains = usingSearchString;
    }

    let queryObject = {
      _page: currentBackendPage,
      _page_size: pageQuerySize,
      ...persistantQueryString,
      ...badgeQuery,
      ...searchObject,
    };

    setQueryString(buildQueryString(queryObject));

    updateLocalQuery && updateLocalQuery(queryObject);

    setHasFetchedBadgeFilterCounts(false);

    getBadgeFilterCounts(queryObject, (res) => {
      if (Object.keys(res).length === 0) return;
      setCurrentBadgeFilterCounts(res);
      setHasFetchedBadgeFilterCounts(true);
    });
  }, [
    persistantQueryString,
    pageQuerySize,
    currentBackendPage,
    badgeQuery,
    usingSearchString,
  ]);

  //isQueryLoading, might get a bit fuck up when using custom template pagination. DO NOT USE
  const [queriedItems, isQueryLoading, backendCountFlag] =
    paginationMethod(queryString);

  const isFetching = useInProgress({ storeName, name: queryString });
  let results = queriedItems?.results ? queriedItems.results : [];

  let pageCount = queriedItems?.count
    ? Math.ceil(queriedItems?.count / pageQuerySize)
    : 0;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: results,
      initialState,
      manualPagination: true,
      pageCount,
    },
    usePagination
  );

  React.useEffect(() => {
    if (!isFetching && !isQueryLoading) {
      if (
        queriedItems?.results &&
        queriedItems.results.length === 0 &&
        currentBackendPage !== 1 &&
        didDelete
      ) {
        //The user deleted a report, which was the last report on the page.
        onDeleteReport();
        setDidDelete(false);

        if (usingSearchString.length !== 0) {
          gotoPage(0);
          setPageSize(25);
          setCurrentSearchString("");
          return;
        }

        if (currentBackendPage > pageCount) {
          //eg. pageCount === 3, pageIndex === 2
          gotoPage(pageCount - 1);
        }
      } else if (
        queriedItems?.results &&
        queriedItems.results.length !== 0 &&
        usingSearchString.length !== 0 &&
        didDelete
      ) {
        //The user deleted a report when searching
        onDeleteReport();
        setDidDelete(false);
        gotoPage(0);
        setCurrentSearchString("");
        return;
      } else if (didDelete) {
        setDidDelete(false);
        onDeleteReport();
      }
    }
  }, [queriedItems?.results, currentBackendPage, usingSearchString]);

  React.useEffect(() => {
    setCurrentBackendPage(pageIndex + 1);
  }, [pageIndex]);

  React.useEffect(() => {
    setPageQuerySize(pageSize);
  }, [pageSize]);

  const { badgesForFilter, setCurrentBadgeFilter } = useBadBadgeFilter({
    pageQueryInfo: {
      page: pageIndex,
      size: pageQuerySize,
    },
    setPageSize,
    gotoPage,
    setBadgeQuery,
    hasFetchedBadgeFilterCounts: hasFetchedBadgeFilterCounts,
    currentBadgeFilterCounts: currentBadgeFilterCounts,
  });

  const onDeleteReport = () => {
    setCurrentBadgeFilter(undefined);
  };

  const doSearch = (str) => {
    if (str.length < 3 && str.length !== 0) return;

    if (pageIndex !== 0) {
      gotoPage(0);
    }

    setUsingSearchString(str);
  };

  const debounceSearch = React.useCallback(debounce(doSearch, 500), [
    pageIndex,
    pageQuerySize,
    currentBackendPage,
  ]);

  React.useEffect(() => {
    debounceSearch(currentSearchString);
  }, [currentSearchString]);

  return (
    <div className="w-full">
      <div className="flex mb-2 mt-1 items-center">
        <p className="font-semibold text-xl">{title}</p>
        <input
          value={currentSearchString}
          onChange={({ target: { value } }) => setCurrentSearchString(value)}
          placeholder={`Sök bland ${title?.toLowerCase() || "rapporter"}...`}
          className="border border-solid border-gray-300 min-w-[300px] p-2 bg-gray-50 rounded ml-3 text-sm"
        />
      </div>

      <div className="flex space-x-2 mb-4">{badgesForFilter}</div>

      {isFetching || isQueryLoading ? (
        <OverlaySpinner />
      ) : (
        <>
          <table {...getTableProps} className="w-full overflow-x-auto">
            <Header
              headerGroups={headerGroups}
              hideHeaders={hideHeaders}
            ></Header>
            <Body
              props={getTableBodyProps()}
              prepareRow={prepareRow}
              page={page}
              rowClassName={rowClassName}
              onRowClicked={onRowClicked}
            >
              {(queriedItems?.count === 0 ||
                queriedItems?.results.length === 0) &&
                usingSearchString.length === 0 && (
                  <div className="w-[100%] min-w-[350px] mt-2">
                    <Alert title={emptyText[0]}>
                      <div
                        className={classNames(
                          "mb-6",
                          emptyAction?.disabled && "mb-0"
                        )}
                      >
                        {emptyText
                          .slice(1, emptyText.length)
                          .map((text, index) => {
                            return <p key={index}>{text}</p>;
                          })}
                      </div>
                      {!emptyAction?.disabled && (
                        <PrimaryButton
                          clicked={() => runEmptyActionMethod(emptyAction)}
                          title={emptyAction.text}
                        ></PrimaryButton>
                      )}
                    </Alert>
                  </div>
                )}
              {(queriedItems?.count === 0 ||
                queriedItems?.results.length === 0) &&
                usingSearchString.length !== 0 && (
                  <div className="w-[50%] min-w-[350px] mt-2">
                    <Alert title={`Hittade inga resultat för din sökning`}>
                      <div className="mb-4"></div>
                      <PrimaryButton
                        clicked={() => {
                          setCurrentSearchString("");
                        }}
                        title={"Rensa sökning"}
                      ></PrimaryButton>
                    </Alert>
                  </div>
                )}
            </Body>
          </table>
          {queriedItems?.count !== 0 && queriedItems?.results.length !== 0 && (
            <Pagination
              {...{
                canPreviousPage,
                canNextPage,
                pageCount,
                gotoPage,
                nextPage: () => {
                  window.scrollTo(0, 0);
                  nextPage();
                },
                previousPage: () => {
                  window.scrollTo(0, 0);
                  previousPage();
                },
                setPageSize,
                pageIndex,
                pageSize,
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
