import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import useActiveFilters from "../../../hooks/useActiveFilters";

import ParkingContractWithCostsTable from "../../../components/Tables/Parking/ParkingContractsWithCosts/FullTableNewVersion";

export const PERSISTANT_CATEGORY_KEY = "persistant_category_parkingcontract";

export default function ParkingContractsCosts() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate__in: filteredRealEstates,
    draft: false,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Parkeringsavtal</OverviewTitle>
          </OverviewTitleWrapper>

          <ParkingContractWithCostsTable persistantQuery={persistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
