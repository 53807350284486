export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_COSTCENTERS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_COSTCENTERS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_COSTCENTERS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_COSTCENTERS",
  DESTROY_FORM: "DESTROY_FORM_COSTCENTERS",
  SET_FORM_ERROR: "SET_FORM_ERROR_COSTCENTERS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_COSTCENTERS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_COSTCENTERS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_COSTCENTERS",
  RESET_STATE: "RESET_STATE_COSTCENTERS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_COSTCENTERS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_COSTCENTERS",
  CLEAR_FETCHED: "CLEAR_FETCHED_COSTCENTERS",

  LIST_URL: "/accounting/costs/costcenter/list/",
  POST_URL: "/accounting/costs/costcenter/",
  PATCH_URL: "/accounting/costs/costcenter/",
  GET_URL: "/accounting/costs/costcenter/",

  STORE_NAME: "invoicingCostCenters",
};
