import React from "react";
import {
  StarIcon,
  HomeIcon,
  ShareIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";
import { CATEGORIES } from "../utils";

export const SidebarContainer = ({ children }) => (
  <div className="min-h-[calc(100vh-103px)] h-full max-w-[230px] min-w-[230px] bg-blue p-4 pr-2 pt-6 flex">
    {children}
  </div>
);

export const SidebarButton = ({
  Icon,
  pathing,
  onClick,
  name,
  currentPath,
}) => {
  const selected = currentPath === `/${name}`;

  return (
    <div
      onClick={() => onClick(name)}
      className={` ${
        selected ? `bg-primaryblue text-white` : ""
      } transition-colors hover:bg-primaryblue hover:text-white flex flex-nowrap items-center py-4 px-2 h-6 mt-1.5 rounded cursor-pointer`}
    >
      {Icon && <Icon width={16} />}
      <p className="ml-2 text-left text-sm">
        {pathing?.[name]?.title || "Okänt fel"}
      </p>
    </div>
  );
};

export const Sidebar = ({
  clicked,
  currentPath,
  pathing,
  categoriesRemoved,
}) => {
  return (
    <SidebarContainer>
      {/* Inner container for sidebar */}
      <div className="w-full">
        <h3 className="font-semibold text-xl">Rapporter</h3>

        {/* Static sidebar options */}
        <div className="mt-6">
          <SidebarButton
            Icon={StarIcon}
            name="favorites"
            currentPath={currentPath}
            onClick={clicked}
            pathing={pathing}
          />
          <SidebarButton
            Icon={HomeIcon}
            name="my_reports"
            currentPath={currentPath}
            onClick={clicked}
            pathing={pathing}
          />
          <SidebarButton
            Icon={ArchiveBoxIcon}
            name="earlier_reports"
            currentPath={currentPath}
            onClick={clicked}
            pathing={pathing}
          />
          <SidebarButton
            Icon={ShareIcon}
            name="shared"
            currentPath={currentPath}
            onClick={clicked}
            pathing={pathing}
          />
        </div>

        {/* Horizontal line */}
        <div className="border-t border-gray mt-6"></div>

        {/* Category sidebar options */}
        <div className="mt-6">
          <SidebarButton
            name="all_reports"
            currentPath={currentPath}
            onClick={clicked}
            pathing={pathing}
          />
          {Object.keys(CATEGORIES)
            .filter((cat) => cat !== "missing")
            .filter((cat) => !categoriesRemoved.includes(cat))
            .map((cat, index) => {
              return (
                <SidebarButton
                  key={index}
                  name={cat}
                  currentPath={currentPath}
                  onClick={clicked}
                  pathing={pathing}
                />
              );
            })}
        </div>
      </div>
    </SidebarContainer>
  );
};
