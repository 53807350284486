import { useSelector } from "react-redux";

export default function useScreenSize() {
  const isMobile = useSelector((state) => state.app.isMobile);
  const isSmallDesktop = useSelector((state) => state.app.isSmallDesktop);

  return {
    isMobile,
    isSmallDesktop,
  };
}
