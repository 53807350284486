import * as React from "react";
// style, design
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { AddressField, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingCompany";

import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Faktureringsinformation</FormAreaTitle>

      <AddressField
        title="Fakturaadress"
        {...{
          storeName,
          method,
          fieldKey: `invoice_address`,
        }}
      />

      <TextInput
        title="Fakturaadress c/o"
        {...{
          storeName,
          method,
          fieldKey: `care_of`,
        }}
      />

      <TextInput
        title="Momsnummer"
        {...{
          storeName,
          method,
          fieldKey: `vat_number`,
        }}
      />

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Attention"
          {...{
            storeName,
            method,
            fieldKey: `attention`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="Bolagets säte"
          {...{
            storeName,
            method,
            fieldKey: `residence`,
          }}
        />
      </DoubleFieldWrapper>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Firmatecknare*</OverviewTitle>
        </OverviewTitleWrapper>

        <TextInput
          title="Titel"
          {...{
            storeName,
            method,
            fieldKey: `signatory_contact.title`,
          }}
        />

        <TextInput
          title="Namn"
          {...{
            storeName,
            method,
            fieldKey: `signatory_contact.name`,
          }}
        />

        <TextInput
          title="Telefon"
          {...{
            storeName,
            method,
            fieldKey: `signatory_contact.phone`,
          }}
        />

        <TextInput
          title="E-post"
          {...{
            storeName,
            method,
            fieldKey: `signatory_contact.email`,
          }}
        />
      </InnerBox>
    </>
  );
};
