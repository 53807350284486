import {
  uploadBase64ToS3,
  uploadMultiBase64ToS3,
} from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    const urls = [];
    const files = [];
    const b64Prefix = "data:application/pdf;base64,";

    // company img
    const docPath = responseData?.company?.image?.put;
    const base64file = formInstance?.company?.image?._tempData?.data;
    if (base64file && docPath) {
      urls.push(docPath);
      files.push(base64file);
    }

    //docs
    const financialPlanPath = responseData?.financial_plan?.put;
    const financialPlanData = formInstance?.financial_planData_;

    if (financialPlanPath && financialPlanData) {
      urls.push(financialPlanPath);
      files.push(financialPlanData);
    }

    // rule_documents
    const attachments = formInstance?.rule_documents || [];
    const responseAttachments = responseData?.rule_documents || [];

    if (attachments.length === responseAttachments.length) {
      responseAttachments.forEach((a, index) => {
        const attachment = attachments[index];
        let file = attachment?.file?._tempData?.data;
        if (file && !file.includes(b64Prefix)) {
          file = b64Prefix + file;
        }

        const url = a?.file?.put;

        if (file && url) {
          urls.push(url);
          files.push(file);
        }
      });
    }

    if (urls.length && files.length && urls.length === files.length)
      await uploadMultiBase64ToS3({
        signedUrls: urls,
        files: files,
      });

    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
