import * as React from "react";
import { detailUrl as apartmentDetail } from "../../../store/apartments";
import { detailUrl as commonAreaDetail } from "../../../store/commonAreas";
import { detailUrl as indpDetail } from "../../../store/industrialPremises";
import { detailUrl as brfDetail } from "../../../store/brfPremises";
import { detailUrl as parkingDetail } from "../../../store/parkingSpots";

import { LinkedObject } from "../../Displays";
import { InnerBox } from "../../sharedStyles";
import DetailInfo from "../OverviewInfo/DetailInfo/DetailInfo";
import { OverviewTitle, OverviewTitleWrapper } from "../OverviewInfo/styles";

const LINKS = {
  APARTMENT: apartmentDetail,
  INDP: indpDetail,
  COMMON_AREA: commonAreaDetail,
  BRF_PREMIS: brfDetail,
  PARKING_SPOT: parkingDetail,
};

export default function InspectionErrandAddressList({ premises, kind }) {
  const detailUrlMethod = LINKS[kind];
  return (
    <InnerBox style={{ marginBottom: 24 }}>
      <OverviewTitleWrapper>
        <OverviewTitle small>Adress och objekt</OverviewTitle>
      </OverviewTitleWrapper>

      <DetailInfo infoObj={getInfoObj({ premises, detailUrlMethod })} />
    </InnerBox>
  );
}

function getInfoObj({ premises, detailUrlMethod }) {
  return {
    Detaljer: [
      {
        title: "Objekt",
        value: <LinkedObject obj={premises} urlMethod={detailUrlMethod} />,
      },
      {
        title: "Adress",
        value: premises?.address?.base?.split(",")?.[0] || "-",
      },
      {
        title: "Postnr.",
        value: premises?.address?.postal_code || "-",
      },
      {
        title: "Kommun",
        value: premises?.address?.municipality || "-",
      },
      {
        title: "Stad",
        value: premises?.address?.city || "-",
      },
    ],
  };
}
