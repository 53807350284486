import * as React from "react";
import { useHistory } from "react-router-dom";
import * as SC from "./styles";

export default function ErrorPage() {
  const { replace } = useHistory();
  return (
    <>
      <SC.Header>500</SC.Header>
      <SC.SubHeader>
        Något gick fel...
        <br /> Vårt tech-team har fått en notis om detta.
      </SC.SubHeader>
      <SC.SubHeader onClick={() => replace("/")}>
        <br /> Tryck här för att gå till startsidan
      </SC.SubHeader>

      <SC.Gears>
        <SC.Gear className="gear-one" style={{ left: -130 }}>
          <SC.Bar />
          <SC.Bar />
          <SC.Bar />
        </SC.Gear>
        <SC.Gear className="gear-two" style={{ top: -75 }}>
          <SC.Bar />
          <SC.Bar />
          <SC.Bar />
        </SC.Gear>
        <SC.Gear className="gear-three" style={{ top: -235, left: 130 }}>
          <SC.Bar />
          <SC.Bar />
          <SC.Bar />
        </SC.Gear>
      </SC.Gears>
    </>
  );
}
