import * as React from "react";

import { Checklist } from "./Chapters";

export default ({ key, method, storeName }) => {
  switch (key) {
    default:
      return <Checklist method={method} storeName={storeName} />;
  }
};
