import * as React from "react";
import { useDispatch } from "react-redux";
import { setActiveFormInstance } from "../../../../store/base";
import {
  useRealEstateTaxRecordForm,
  constants,
  create,
  update,
} from "../../../../store/realEstateTaxRecord";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import StandardModal from "../../../Modals/StandardModal";
import { NumberInput, Select } from "../../Base/Fields";

export default ({ method, id, onCheckout, isOpen }) => {
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  useRealEstateTaxRecordForm(method, id);

  React.useEffect(() => {
    if (!isOpen) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );
    }
  }, [isOpen]);

  const onSave = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: () => {
            setLoading(false);
            onCheckout();
          },
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    } else {
      dispatch(
        update({
          id,
          successCallback: () => {
            setLoading(false);
            onCheckout();
          },
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    }
  };

  return (
    <StandardModal
      isOpen={isOpen}
      title={
        method === "PATCH" ? "Uppdatera" : "Lägg till ny skatteinställning"
      }
      closeFunction={onCheckout}
      saveFunction={onSave}
      withActionBar
    >
      {loading && <OverlaySpinner />}

      <NumberInput
        title="Avseende år"
        fieldKey="for_year"
        {...{ storeName, method }}
      />

      <Select
        title="Taxeringstyp"
        storeName={storeName}
        fieldKey={`tax_rule`}
        {...{ storeName, method }}
      />

      <NumberInput
        title="Byggnadsvärde för bostäder"
        fieldKey="build_housing_value"
        {...{ storeName, method }}
      />

      <NumberInput
        title="Markvärde för bostäder"
        fieldKey="ground_housing_value"
        {...{ storeName, method }}
      />

      <NumberInput
        title="Byggnadsvärde för lokaler"
        fieldKey="build_premises_value"
        {...{ storeName, method }}
      />

      <NumberInput
        title="Markvärde för lokaler"
        fieldKey="gound_premises_value"
        {...{ storeName, method }}
      />

      <NumberInput
        title="Byggnadsvärde för industri"
        fieldKey="build_industrial_value"
        {...{ storeName, method }}
      />

      <NumberInput
        title="Markvärde för industri"
        fieldKey="ground_premises_value" // really ground industrial value, typo on backend
        {...{ storeName, method }}
      />
    </StandardModal>
  );
};
