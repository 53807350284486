import * as React from "react";
import Table from "../../../components/Billecta/Table/BasicTable";
import { RemoveButton } from "../../../components/Billecta/Invoices/InvoiceComponents/Multiple/styles";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";

import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { DateCell } from "../../../components/Displays";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import {
  READABLE_BALCONY_OPTIONS,
  READABLE_KITCHEN_OPTIONS,
  READABLE_MULTI_OPTIONS,
} from "../../../components/Forms/MarketApartmentCommodities/FlowForm/Chapters/Amenities";
import { READABLE_WATER_OPTIONS } from "../../../components/Forms/MarketApartmentCommodities/FlowForm/Chapters/Included";
import { AD_CREATE_TYPES } from "./ManyAdRootObjectSelect";

export default function CommoditiesTable({
  commoditiesInstance,
  setEditId,
  onSelected,
  type,
}) {
  const data = React.useMemo(() => {
    return commoditiesInstance;
  }, [commoditiesInstance]);

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "",
        id: "remove",
        Cell: ({ row }) => {
          return (
            <RemoveButton
              onClick={(e) => {
                e.stopPropagation();
                onSelected(row);
              }}
            />
          );
        },
      },

      {
        Header: "El inkl.",
        accessor: "electricity_included",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Värme inkl.",
        accessor: "heating_included",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "TV-paket inkl.",
        accessor: "cable_package_included",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Internet inkl.",
        accessor: "internet_included",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Hiss",
        accessor: "elevator",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Skafferi",
        accessor: "pantry",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Uteplats",
        accessor: "patio",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Tillg. anp.",
        accessor: "accessibility_adapted",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Handikappsanp.",
        accessor: "handicap_adapted",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Säkerhetsdörr",
        accessor: "security_door",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Tvättstuga",
        accessor: "laundry_room",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Dusch",
        accessor: "shower",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Utfällb. säng",
        accessor: "bed_in_wall",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Spis",
        accessor: "stove",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Gasspis",
        accessor: "gas_cooker",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Öppen spis",
        accessor: "tiled_stove",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Kökstyp",
        accessor: "kitchen",
        Cell: ({ value }) => {
          return <div>{READABLE_KITCHEN_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Vatten",
        accessor: "water",
        Cell: ({ value }) => {
          return <div>{READABLE_WATER_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Renov. senast",
        accessor: "renovation_date",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
      {
        Header: "Balkong",
        accessor: "balcony",
        Cell: ({ value }) => {
          return <div>{READABLE_BALCONY_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Köksfläkt",
        accessor: "kitchen_fan",
        Cell: ({ value }) => {
          return <div>{READABLE_MULTI_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Diskmaskin",
        accessor: "dishwasher",
        Cell: ({ value }) => {
          return <div>{READABLE_MULTI_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Tvättmaskin",
        accessor: "washing_machine",
        Cell: ({ value }) => {
          return <div>{READABLE_MULTI_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Torktumlare",
        accessor: "dryer",
        Cell: ({ value }) => {
          return <div>{READABLE_MULTI_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Badkar",
        accessor: "bathtub",
        Cell: ({ value }) => {
          return <div>{READABLE_MULTI_OPTIONS[value] || "Inget valt"}</div>;
        },
      },
      {
        Header: "Tillg. fordonspl.",
        accessor: "available_parking_spots",
        Cell: ({ value }) => {
          return <div>{value}st</div>;
        },
      },
      {
        Header: "Tillg. garagepl.",
        accessor: "available_garage_spots",
        Cell: ({ value }) => {
          return <div>{value}st</div>;
        },
      },
      {
        Header: "Inkl. fordonspl.",
        accessor: "included_parking_spots",
        Cell: ({ value }) => {
          return <div>{value}st</div>;
        },
      },
      {
        Header: "Inkl. garagepl.",
        accessor: "included_garage_spots",
        Cell: ({ value }) => {
          return <div>{value}st</div>;
        },
      },
    ];

    if (type === AD_CREATE_TYPES.APARTMENT) {
      cols.splice(1, 0, {
        Header: "Lgh. nr",
        accessor: "apartment_id",
        Cell: ({ value, row }) => {
          const url = apartmentDetailUrl({ id: row.original.id });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      });
      cols.splice(2, 0, {
        Header: "Obj. nr",
        accessor: "premises_id",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      });
    }

    if (type === AD_CREATE_TYPES.INDP) {
      cols.splice(1, 0, {
        Header: "Obj. nr",
        accessor: "premises_id",
        Cell: ({ value, row }) => {
          const url = indpDetailUrl({ id: row.original.id });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      });
    }

    return cols;
  }, []);

  return (
    <Table
      columns={tableColumns}
      data={data}
      onRowClicked={(row) => setEditId(row.original.id)}
    />
  );
}
