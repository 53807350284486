import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { useChecklist } from "../../../store/checklists";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";

import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import ChecklistForm from "../../../components/Forms/Checklist/ChapterForm/ModalForm";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import { useFilteredChecklistRows } from "../../../store/checklistRows";
import Table from "../../../components/Billecta/Table/BasicTable";
import ChecklistRowForm from "../../../components/Forms/ChecklistRow/ChapterForm/ModalForm";
import ChecklistRowHandleModal from "../../../components/Forms/ChecklistRow/HandleModal";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";

export default function ChecklistDetailOverview() {
  const { id } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);
  const [createRowOpen, setCreateRowOpen] = React.useState(false);
  const [handleRowOpen, setHandleRowOpen] = React.useState(false);
  const [editRowOpen, setEditRowOpen] = React.useState(false);

  const [checklist] = useChecklist(id);

  const checklistRowQ = buildQueryString({
    check_list: id,
  });

  const canEdit = useAllPermissionCheck(["change_can_reporterrand"]);
  const canAdd = useAllPermissionCheck(["add_can_reporterrand"]);

  const [checklistRows, checklistRowsLoading] =
    useFilteredChecklistRows(checklistRowQ);

  const tableData = React.useMemo(() => {
    return checklistRows;
  }, [checklistRows]);

  const tableCols = React.useMemo(() => {
    return [
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Visas för självavhjälpande",
        accessor: "hide_for_tenants",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 3 : 0}>
              {value ? "Nej" : "Ja"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Visas för utförande",
        accessor: "hide_for_non_tenants",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 3 : 0}>
              {value ? "Nej" : "Ja"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Antal filer",
        accessor: "files",
        Cell: ({ value }) => {
          return value?.length || 0;
        },
      },
    ];
  }, [checklistRows]);

  return (
    <>
      <ChecklistRowHandleModal
        isOpen={!!handleRowOpen}
        closeFunction={() => setHandleRowOpen(false)}
        row={handleRowOpen}
        onEdit={() => {
          setEditRowOpen(handleRowOpen);
          setHandleRowOpen(false);
        }}
      />

      <ChecklistRowForm
        method="POST"
        instance={{ check_list: { id } }}
        isOpen={createRowOpen}
        onCheckout={() => setCreateRowOpen(false)}
      />

      <ChecklistRowForm
        method="PATCH"
        id={editRowOpen?.id}
        instance={editRowOpen}
        isOpen={!!editRowOpen}
        onCheckout={() => setEditRowOpen(false)}
      />

      <ChecklistForm
        method="PATCH"
        id={id}
        instance={checklist}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 1, maxWidth: "33%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>
                <OverviewSubtitle>
                  {canEdit && (
                    <TextButton
                      iconType="edit"
                      iconPlacement="right"
                      title="Redigera checklista"
                      clicked={() => setEditOpen(true)}
                    />
                  )}
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getDetailInfoObj({ checklist })} />
          </DetailPageBox>
          <DetailPageBox
            style={{ flex: 2, maxWidth: "66%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Rader i checklista</OverviewTitle>
                <OverviewSubtitle>
                  Tryck på en rad för att redigera eller radera den
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>

              {canAdd && (
                <PrimaryButton
                  title="Lägg till rad i checklista"
                  clicked={() => setCreateRowOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            {checklistRowsLoading ? (
              <InnerBox style={{ height: 200 }}>
                <OverlaySpinner />
              </InnerBox>
            ) : (
              <Table
                onRowClicked={({ original }) => setHandleRowOpen(original)}
                columns={tableCols}
                data={tableData || []}
                hideSearch
              />
            )}
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getDetailInfoObj({ checklist }) {
  return {
    Komponenttyp: [
      {
        title: "Namn",
        value: checklist?.name,
      },
      {
        title: "Aktiv för självavhjälpande",
        value: (
          <StatusLabel state={checklist?.hide_for_tenants ? 3 : 0}>
            {checklist?.hide_for_tenants ? "Nej" : "Ja"}
          </StatusLabel>
        ),
      },
      {
        title: "Aktiv för utförande",
        value: (
          <StatusLabel state={checklist?.hide_for_non_tenants ? 3 : 0}>
            {checklist?.hide_for_non_tenants ? "Nej" : "Ja"}
          </StatusLabel>
        ),
      },
    ],
  };
}
