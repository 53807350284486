import * as React from "react";
import { PrimaryButton } from "../../../Base/Buttons";

export default ({ onSubmit }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200,
      }}
    >
      <div>
        <PrimaryButton
          title="Spara och gå vidare till debiteringsrader"
          clicked={onSubmit}
        />
      </div>
    </div>
  );
};
