import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { InfoBox } from "../../../Displays";

export default React.memo(
  ({
    options = [],
    infoBox,
    overrideOnSelected,
    closeFunction,
    value,
    onChange,
    extraStyles,

    // top, bottom
    forceVerticalToolTipPlacement,
    //left ,right
    forceHorizontalToolTipPlacement,
  }) => {
    const onSelected = async (item) => {
      if (overrideOnSelected) {
        closeFunction();
        return await overrideOnSelected(item);
      } else {
        onChange(item.id);
      }
    };

    return (
      <>
        {infoBox && <InfoBox title={infoBox.title} text={infoBox.text} />}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "800px",
            margin: "0 auto",
          }}
        >
          {options.map((o) => {
            return (
              <SC.ImageRadioGroupWrapper
                active={o.id === value}
                onClick={() => onSelected(o)}
              >
                <div className="w-[90%] h-[80%] flex items-center justify-center">
                  {o.image}
                </div>
                <SC.ImageRadioGroupLabel>{o.label}</SC.ImageRadioGroupLabel>
              </SC.ImageRadioGroupWrapper>
            );
          })}
        </div>
      </>
    );
  }
);
