import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";

import { usePermissionCheck } from "../../store/base";

//components & styles
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { useRoundingArea, constants } from "src/store/roundingAreas";
import { overviewUrl } from "src/store/roundingErrandSettings";
import RoundingAreaModalForm from "src/components/Forms/RoundingArea/ModalForm";
import RoundingErrandAreaOverview from "./detail/Overview";

export default function RoundingAreaDetail() {
  const { areaId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();

  const [roundingArea, roundingAreaLoading] = useRoundingArea(areaId);

  const [editOpen, setEditOpen] = React.useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const canEdit = usePermissionCheck("change_can_roundingerrand");
  const canDelete = usePermissionCheck("delete_can_roundingerrand");

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: RoundingErrandAreaOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canEdit) {
    handleActions.push({
      name: "Redigera",
      onClick: () => setEditOpen(true),
    });
  }

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  return (
    <>
      {roundingAreaLoading && <OverlaySpinner />}
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={roundingArea}
        constants={constants}
        deletedCallback={() => replace(overviewUrl({ govErrand: false }))}
      />

      {canEdit && editOpen && roundingArea && (
        <RoundingAreaModalForm
          method="PATCH"
          id={roundingArea?.id}
          instance={roundingArea}
          onCheckout={() => setEditOpen(false)}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Ronderingsområde ${
            roundingArea?.str_representation || "laddar..."
          }`}
          breadCrumbs={[
            {
              url: "/rounding-errands/errand-areas",
              label: "Ronderingsområden",
            },
            { label: roundingArea?.str_representation || "Laddar..." },
          ]}
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
