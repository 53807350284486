import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../../store/companies";
import { useFrequentPermissions } from "../../../../store/base";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  integrationSettings,
  integrationName,
}) => {
  const { hasBillectaViewPermission } = useFrequentPermissions();

  const columns = React.useMemo(
    () =>
      columnDefs(
        hasBillectaViewPermission,
        integrationSettings,
        integrationName
      ),
    [
      persistantQuery,
      hasBillectaViewPermission,
      integrationSettings,
      integrationName,
    ]
  );

  const filters = {};
  const badges = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "configcenter_companies_full_table"}
        title={"Bolag"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
};
