export const KEY_MAP = {
  "accounts.user": "Användare",
  "accounts.tenant": "Hyresgäst",
  "standard.company": "Bolag",
  "standard.building": "Byggnad",
  "standard.basicdoc": "Dokument",
  "standard.apartment": "Lägenhet",
  "standard.industrialpremises": "Lokal",
  "standard.leasecontract": "Hyresavtal",
  "standard.othercontract": "Övrigt avtal",
  "standard.parkinglot": "Parkeringsområde",
  "standard.parkingspot": "Fordonsplats",
  "standard.parkingcontract": "Parkeringsavtal",
  "standard.servicepartner": "Servicepartner",
  "standard.servicecontract": "Serviceavtal",
  "accounting.project": "Projekt",
  "accounting.costcenter": "Kostnadsställe",
  "accounting.product": "Produkt",
  "accounting.debthandlingsetting": "Betalningsvillkor",
  "accounting.imdinvoiceconfig": "IMD-inställning",
  "accounting.invoicingsetting": "Aviseringsinställning",
  "accounting.leaseinvoicing": "Hyresavtal avisering",
  "accounting.otherinvoicing": "Övrigt avtal avisering",
  "accounting.parkinginvoicing": "Parkeringsavtal avisering",
  "accounting.brfinvoicing": "Bostadsrätt avisering",
  "accounting.serviceinvoicing": "Serviceavtal avisering",
  "accounting.reporterrandinvoicing": "Felanmälan avisering",
  "accounting.inspectionerrandinvoicing": "Besiktning avisering",
  "errands.reporterrandsetting": "Felanmälningsinställning",
  "errands.inspectionerrandsetting": "Besiktningsinställning",
  "errands.roundingerrandsetting": "Ronderingsschema",
  "errands.inspectionerrand": "Besiktning",
  "errands.reporterrand": "Felanmälan",
  "errands.roundingerrand": "Rondering",
  "brf.brfpremis": "Bostadsrätt",
  "brf.brfcompany": "BRF",
  "imd.sensor": "IMD-sensor",
};

//// MORE MAPPINGS

export const URL_MAP = {
  "accounts.tenant": (id) => `/tenants/detail/${id}`,
  "accounts.user": (id) => `/configcenter/groups-and-users/user/${id}`,
  "standard.othercontract": (id) => `/other-contracts/detail/${id}`,
  "standard.leasecontract": (id) => `/lease-contracts/detail/${id}`,
  "standard.servicecontract": (id) => `/service-contracts/detail/${id}`,
  "standard.servicepartner": (id) => `/service-partners/${id}`,
  "standard.parkingcontract": (id) => `/parking-contracts/detail/${id}`,
  "standard.basicdoc": (id) => `/documents/detail/${id}`,
  "standard.apartment": (id) => `/apartments/detail/${id}`,
  "standard.industrialpremises": (id) => `/industrial-premises/detail/${id}`,
  "standard.building": (id) => `/buildings/detail/${id}`,
  "standard.parkingspot": (id) => `/parking-spots/detail/${id}`,
  "standard.parkinglot": (id) => `/parking-lots/detail/${id}`,
  "standard.company": (id) => `/companies/detail/${id}`,
  "brf.brfpremis": (id) => `/brf-premises/detail/${id}`,
  "brf.brfcompany": (id) => `/brf-companies/detail/${id}`,
  "errands.inspectionerrand": (id) => `/inspection-errands/${id}`,
  "errands.reporterrand": (id) => `/report-errands/${id}`,
  "errands.roundingerrand": (id) => `/rounding-errands/${id}`,
  "accounting.project": (id) =>
    `/rental-notifications/invoicing-objects/projects/${id}`,
  "accounting.costcenter": (id) =>
    `/rental-notifications/invoicing-objects/cost-centers/${id}`,
  "accounting.product": (id) =>
    `/rental-notifications/invoicing-objects/products/${id}`,
  "accounting.debthandlingsetting": (id) =>
    `/configcenter/rental-notifications/debt-setting/${id}/overview`,
  "accounting.imdinvoiceconfig": (id) =>
    `/configcenter/rental-notifications/invoicing-setting/${id}/overview`,
  "accounting.invoicingsetting": (id) =>
    `/configcenter/rental-notifications/imd-setting/${id}/overview`,
  "accounting.leaseinvoicing": (id) =>
    `/error-redirect?contentType=invoicinglease&id=${id}`,
  "accounting.otherinvoicing": (id) =>
    `/error-redirect?contentType=invoicingother&id=${id}`,
  "accounting.parkinginvoicing": (id) =>
    `/error-redirect?contentType=invoicingparking&id=${id}`,
  "accounting.brfinvoicing": (id) =>
    `/error-redirect?contentType=invoicingbrf&id=${id}`,
};

export const HIGHLIGHT_MAP = {
  industrial_premises_list: "Lokaler",
  apartments: "Lägenheter",
  tenant: "Hyresgäst",
  user: "Användare",
  str_representation: "Beskrivning",
  contact_persons: "Kontaktpersoner",
  keyword: "Nyckelord",
  email: "E-post",
  first_name: "Förnamn",
  last_name: "Efternamn",
  apartment_id: "Lägenhetsnummer",
  premises_id: "Objektsnummer",
  id_number: "Avtals-id",
  email_invoice: "Faktura E-post",
  legal_id: "Orgnr/Personnummer",
  address: "Adress",
  invoice_address: "Fakturaadress",
  addresses: "Adresser",
  base: "Adress",
  realestate: "Fastighet",
  main_address: "Huvudadress",
  key: "Nyckel",
  title: "Titel",
  company: "Företag",
  corporate_name: "Bolagsnamn",
  message: "Meddelande",
  setting: "Inställning",
  name: "Namn",
  billing_company: "Fakturerande bolag",
  your_reference: "Kunds referens",
  our_reference: "Vår referens",
  owner: "Ägare",
  internal_premises_id: "Lägenhetsnummer",
  _content_type: "Typ",
  "content_object-industrialpremises": "Kopplat objekt",
  "content_object-apartment": "Kopplat objekt",
  "content_object-tenant": "Kopplat objekt",
};
