import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import PrimaryBtn from "../../components/Forms/Base/Buttons/PrimaryBtn";
import IndexSettingModalForm from "../../components/Forms/IndexSetting/ModalForm/ModalForm";
import IndexSettingsTable from "../../components/Tables/IndexSetting/FullTable";
import { DetailInnerWrapper } from "../../components/sharedStyles";

export default function IndexationSettings() {
  const [createOpen, setCreateOpen] = React.useState(false);

  return (
    <>
      {createOpen && (
        <IndexSettingModalForm
          method="POST"
          closeFunction={() => setCreateOpen(false)}
          instance={{
            index_amount: 100,
            final_rounding_mode: 2,
            index_around_month: 1,
            include_cost_title: true,
            use_cost_product: true,
          }}
        />
      )}

      <DetailInnerWrapper>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Indexinställningar</OverviewTitle>
            <OverviewSubtitle style={{ marginRight: 24 }}>
              Dessa inställningar används på debiteringsrader för att automatisk
              räkna upp index utifrån inställningen.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <PrimaryBtn onClick={() => setCreateOpen(true)}>
            Skapa ny inställning
          </PrimaryBtn>
        </OverviewTitleWrapper>

        <IndexSettingsTable />
      </DetailInnerWrapper>
    </>
  );
}
