export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title || "Aviseringsinställning";
};

export const detailUrl = ({ id }) => {
  return `/configcenter/rental-notifications/invoicing-setting/${id}`;
};

// contracts with these states should not be invoicing
export const NON_INVOICING_STATES = [5, 8, 10];
