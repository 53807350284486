import * as React from "react";
import { useParams, useHistory } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { EmailTag, PhoneTag } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useTenant, update } from "../../../store/tenants";

import { constants as anonymizationConstants } from "../../../store/anonymization";
import {
  post as postAction,
  deleteObject as deleteAction,
} from "../../../store/base/store/actions";

import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import EditModal from "../../../components/Forms/Tenant/ChapterForm/ModalForm";
import {
  buildQueryString,
  useAllPermissionCheck,
  usePermissionCheck,
} from "../../../store/base";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useFilteredNotes } from "../../../store/notes";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import {
  useDebtorInvoicing,
  update as updateDebtor,
} from "../../../store/invoicingDebtor";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useFilteredApartments } from "../../../store/apartments";
import { useFilteredIndustrialPremises } from "../../../store/industrialPremises";
import TenantPortalStatusCard from "../../../components/Details/TenantPortalSettings/StatusCard";
import SmallApartmentSummary from "../../../components/SummaryCards/Apartment/Small";
import SmallIndustrialPremisesSummary from "../../../components/SummaryCards/IndustrialPremises/Small";

const getContactPerson = (tenant) => {
  let currentTenantContact;

  if (tenant?.user?.first_name) {
    currentTenantContact = tenant.user.first_name;
  }
  if (tenant?.user?.last_name) {
    if (currentTenantContact) {
      currentTenantContact = `${currentTenantContact} ${tenant.user.last_name}`;
    } else {
      currentTenantContact = tenant.user.last_name;
    }
  }
  if (!currentTenantContact) {
    currentTenantContact = "-";
  }

  return currentTenantContact;
};

function TenantOverview() {
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const { tenantId } = useParams();
  const { push } = useHistory();

  const canAddUser = usePermissionCheck("add_can_user");
  const canEditTenant = usePermissionCheck("change_can_tenant");
  const canEditUser = usePermissionCheck("change_can_user");
  const canHandleTenantPortal = useAllPermissionCheck([
    "allow_tenantportal",
    "view_can_tenantportal",
    "change_can_tenantportal",
  ]);
  const yourBlockActive = usePermissionCheck("allow_yourblock");
  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const tenantPortalActive = canAddUser && canHandleTenantPortal;

  const [tenant] = useTenant(tenantId);
  const [debtorInvoiceConfig, debtorInvoiceConfigLoading] = useDebtorInvoicing(
    tenant?.debtor_invoice_config?.id
  );

  const apartmentQuery = buildQueryString({ broad_tenant: tenantId });
  const [apartments, apartmentsLoading] = useFilteredApartments(apartmentQuery);
  const industrialQuery = buildQueryString({ broad_tenant: tenantId });
  const [indps, indpsLoading] = useFilteredIndustrialPremises(industrialQuery);

  const [confirmConvertOpen, setConfirmConvertOpen] = React.useState(false);
  const [forceKind, setForceKind] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const canAnonymizeUser =
    usePermissionCheck("add_can_anonymization") && tenant;
  const canUndoAnonymizeUser =
    usePermissionCheck("delete_can_anonymization") && tenant;

  const [isAnonymous, setIsAnonymous] = React.useState(
    tenant?.user?.anonymization
  );

  React.useEffect(() => {
    setIsAnonymous(tenant?.user?.anonymization);
  }, [!!tenant?.user?.anonymization]);

  const notesQ = buildQueryString({
    id__in: tenant?.user?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const kind = tenant?.user?.corporate_name ? "corporate" : "private";

  const onConvertTenant = () => {
    if (kind === "private") {
      setForceKind("corporate");

      if (debtorInvoiceConfig) {
        setLoading(true);
        dispatch(
          updateDebtor({
            preventDefaultToast: true,
            id: debtorInvoiceConfig.id,
            forceData: {
              e_invoice_bank: null,
            },
            successCallback: () => {
              setLoading(false);
              setEditOpen(true);
              dispatch(
                addToast({
                  type: TOAST_TYPES.INFO,
                  title: "Betaluppfifter rensades från hyresgäst",
                  description: `Ställ in eventuella nya uppgifter efter att ha uppdaterat bolagsnamnet under "Avisering" -> "Redigera"`,
                })
              );
            },
          })
        );
      } else {
        setEditOpen(true);
      }
    } else {
      if (debtorInvoiceConfig) {
        setLoading(true);
        dispatch(
          updateDebtor({
            preventDefaultToast: true,
            id: debtorInvoiceConfig.id,
            forceData: {
              intermediator: null,
              gln: null,
            },
            successCallback: () => {
              dispatch(
                addToast({
                  type: TOAST_TYPES.INFO,
                  title: "Betaluppfifter rensades från hyresgäst",
                  description: `Ställ in eventuella nya uppgifter under "Avisering" -> "Redigera"`,
                })
              );
            },
          })
        );

        dispatch(
          update({
            id: tenantId,
            forceData: {
              user: {
                id: tenant.user.id,
                corporate_name: null,
              },
            },
            successCallback: () => {
              setLoading(false);
            },
          })
        );
      } else {
        dispatch(
          update({
            id: tenantId,
            forceData: {
              user: {
                id: tenant.user.id,
                corporate_name: null,
              },
            },
            successCallback: () => {
              setLoading(false);
            },
          })
        );
      }
    }
  };

  const onAnonymize = () => {
    if (!isAnonymous) {
      dispatch(
        postAction({
          url: anonymizationConstants.POST_URL,
          constants: anonymizationConstants,
          forceData: { user: { id: tenant.user.id } },
          successCallback: (_, data) => setIsAnonymous({ id: data.id }),
        })
      );
    } else {
      dispatch(
        deleteAction({
          instance: isAnonymous,
          constants: anonymizationConstants,
          successCallback: () => setIsAnonymous(undefined),
        })
      );
    }
  };

  return (
    <>
      {editOpen && (
        <EditModal
          method="PATCH"
          id={tenant?.id}
          instance={tenant}
          onCheckout={() => setEditOpen(false)}
          kind={forceKind || kind}
        />
      )}

      <ConfirmationModal
        isOpen={confirmConvertOpen}
        title="Bekräfta konvertering"
        renderContent={() => (
          <div>
            Vid byte av hyresgästtyp kommer eventuella betaluppgifter att
            rensas. Dessa inställningar måste ställas in på nytt under
            "Avisering" -> "Redigera" efter att bytet är gjort.
          </div>
        )}
        closeFunction={() => setConfirmConvertOpen(false)}
        acceptCallback={onConvertTenant}
        acceptTitle="Konvertera hyresgäst"
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "60%", alignSelf: "flex-start" }}>
            {loading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              {canEditTenant && canEditUser && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            <DetailInfo
              infoObj={getInfoObj({
                tenant,
                yourBlockActive,
                avyTmplActive,
                setConfirmConvertOpen,

                push,
              })}
            />

            {canAnonymizeUser && !isAnonymous && (
              <TextButton
                red
                iconType="lock"
                iconPlacement="right"
                title={"Anonymisera hyresgäst"}
                clicked={onAnonymize}
                extraStyle={{ marginTop: 8 }}
              />
            )}
            {canUndoAnonymizeUser && isAnonymous && (
              <TextButton
                red
                iconType="sync"
                iconPlacement="right"
                title={"Stäng av anonymisering"}
                clicked={onAnonymize}
                extraStyle={{ marginTop: 8 }}
              />
            )}
          </DetailPageBox>

          <div style={{ width: "40%", alignSelf: "flex-start" }}>
            {tenantPortalActive && tenant && (
              <TenantPortalStatusCard user={tenant?.user} />
            )}

            {(apartments?.length > 0 || indps?.length > 0) && (
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small>Hyresobjekt</OverviewTitle>
                </OverviewTitleWrapper>
                <div className="grid grid-cols-1 gap-3">
                  {apartments?.map((ap) => (
                    <SmallApartmentSummary key={ap.id} id={ap.id} />
                  ))}
                  {indps?.map((ip) => (
                    <SmallIndustrialPremisesSummary key={ip.id} id={ip.id} />
                  ))}
                </div>
              </DetailPageBox>
            )}

            <DetailPageBox>
              <Notes
                notes={notes}
                contentType="accounts.user"
                objectId={tenant?.user?.id}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({
  tenant,
  yourBlockActive,
  avyTmplActive,
  setConfirmConvertOpen,
}) {
  const infoObj = {
    Hyresgäst: [
      {
        title: "Status",
        value: (
          <StatusLabel contentType="tenant" state={tenant?.state}>
            {tenant?.state_display}
          </StatusLabel>
        ),
      },
      {
        title: "Synkad mot YourBlock",
        value: (
          <StatusLabel state={tenant?.is_yourblock_synced ? 0 : 3}>
            {tenant?.is_yourblock_synced ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !yourBlockActive,
      },
      {
        title: "Synkad mot Avy-Tmpl",
        value: (
          <StatusLabel state={!!tenant?.avytmpl_id ? 0 : 3}>
            {!!tenant?.avytmpl_id ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !avyTmplActive,
      },
      {
        title: "Typ av hyresgäst",
        value: (
          <div style={{ textAlign: "end" }}>
            {tenant?.user?.corporate_name ? "Bolag" : "Privatperson"}
            <br />
            <TextButton
              iconType="sync"
              iconPlacement="right"
              title={
                tenant?.user?.corporate_name
                  ? "Konvertera till privatperson"
                  : "Konvertera till bolag"
              }
              clicked={() => setConfirmConvertOpen(true)}
            />
          </div>
        ),
      },
      { title: "Kontaktperson", value: getContactPerson(tenant) },
      {
        title: tenant?.is_brf ? "Medlemsnummer" : "Hyresgästnummer",
        value: tenant?.customer_id,
      },

      { title: "E-post", value: <EmailTag mail={tenant?.user?.email} /> },
      { title: "Telefon", value: <PhoneTag phone={tenant?.user?.phone} /> },
      {
        title: tenant?.user?.corporate_name ? "Orgnr" : "Personnummer",
        value: tenant?.user?.legal_id || "-",
      },

      {
        title: "Tillhör fastigheter",
        value:
          tenant?.user?.realestates?.length > 0
            ? tenant?.user?.realestates?.map((re, idx) => {
                return (
                  <>
                    <div>{re.str_representation}</div>
                    {idx < tenant.user.realestates.length - 1 && <br />}
                  </>
                );
              })
            : "Ingen specifik",
      },
    ],
  };

  if (tenant?.contact_persons?.length > 0) {
    tenant.contact_persons.forEach((cp, idx) => {
      infoObj[
        `Kontaktperson ${tenant.contact_persons.length > 1 ? idx + 1 : ""}`
      ] = [
        {
          title: "Titel",
          value: cp.title,
        },
        {
          title: "Namn",
          value: cp.name,
        },
        {
          title: "Telefon",
          value: cp.phone,
          hidden: !cp.phone,
        },
        {
          title: "E-post",
          value: cp.email,
          hidden: !cp.email,
        },
      ];
    });
  }

  return infoObj;
}

export default TenantOverview;
