import Confirm from "./Confirm";

import ParkingSpot from "./ParkingSpot";
import Costs from "./Costs";

export default {
  PARKINGSPOT: ParkingSpot,
  COSTS: Costs,
  CONFIRM: Confirm,
};
