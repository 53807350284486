import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

import PaymentGroupSubtable from "./SubTable";
// store, state

import { constants, detailUrl } from "../../../store/paymentGroup";

export default function PaymentGroupTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  initialOrderBy = [],
}) {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  // not filterable on backend, but we want to enable filtering on it
  const renderSubRow = ({ original, tableWidth }) => {
    return <PaymentGroupSubtable paymentGroup={original} />;
  };

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "payment_group_full_table"}
      title={"Hyresavtal"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      getContentForExpandedRow={(original) => renderSubRow(original)}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
        initialOrderBy,
      }}
    />
  );
}
