import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import moment from "moment";

import { DetailLayoutWrapper } from "../../components/sharedStyles";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { useDispatch } from "react-redux";
import {
  attestInvoice,
  billectaStateMap,
  deleteInvoice,
  pauseInvoice,
  resumeInvoice,
  sendManualReminderInvoice,
  useInvoiceForm,
  sendInvoice,
  disputeInvoice,
  undisputeInvoice,
  creditorInvoiceDetailUrl,
  clearInvoiceIdPreview,
  sendDebtCollectionInvoice,
} from "../../store/billectaInvoicing";
import { useInvoice } from "../../store/billectaInvoicing/hooks/retrieve";
import { useFilteredCompanies } from "../../store/companies";
import { buildQueryString } from "../../store/base";
import InvoicePreview from "./invoiceDetail/InvoicePreview";
import InvoiceOverview from "./invoiceDetail/Overview";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { toMoneyString } from "../../components/utils/stringUtils";
import InvoiceEvents from "./invoiceDetail/Events";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import {
  creditorDetailUrl,
  editUrl,
} from "../../store/billectaInvoicing/utils";

import { clearIntegrationData } from "../../store/billectaIntegrations";
import { InfoBox } from "../../components/Displays";

import InvoiceNotFound from "../../components/Billecta/Invoices/NotFound";
import InvoiceSendForm from "../../components/Forms/Invoice/SendForm";
import InvoiceSendReminderForm from "../../components/Forms/Invoice/SendReminderForm";
import InvoiceSendToDebtCollection from "../../components/Forms/Invoice/SendToDebtCollection";
import ConfirmModal from "../../components/Forms/Base/Modals/ConfirmModal";
import CopyInvoiceForm from "../../components/Forms/Invoice/CopyInvoiceForm";
import CreditInvoiceForm from "../../components/Forms/Invoice/CreditInvoiceForm";
import PrimaryBtn from "../../components/Forms/Base/Buttons/PrimaryBtn";
import ConnectToDebitInvoiceForm from "../../components/Forms/Invoice/ConnectToDebitInvoiceForm";
import MatchCreditToDebitForm from "../../components/Forms/Invoice/MatchCreditToDebitForm";
import AdjustPaymentForm from "../../components/Forms/Invoice/AdjustPaymentForm";

function InvoiceDetail() {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();

  const { creditorId, invoiceId } = useParams();
  useInvoiceForm("PUT");
  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [confirmationFunction, setConfirmationFunction] = React.useState(null);

  const [sendInvoiceOpen, setSendInvoiceOpen] = React.useState(false);
  const [copyInvoiceOpen, setCopyInvoiceOpen] = React.useState(false);
  const [creditInvoiceOpen, setCreditInvoiceOpen] = React.useState(false);
  const [sendInvoiceMethod, setSendInvoiceMethod] = React.useState(null);
  const [connectCreditToDebitOpen, setConnectCreditToDebitOpen] =
    React.useState(false);
  const [matchCreditToDebitOpen, setMatchCreditToDebitOpen] =
    React.useState(false);
  const [reminderInvoiceSettingsOpen, setReminderInvoiceSettingsOpen] =
    React.useState(false);
  const [sendToDebtCollectionOpen, setSendToDebtCollectionOpen] =
    React.useState(false);
  const [adjustPaymentOpen, setAdjustPaymentOpen] = React.useState(false);

  const [reminderInvoiceDeliveryMethod, setReminderInvoiceDeliveryMethod] =
    React.useState(null);
  const [reminderFee, setReminderFee] = React.useState(0);
  const [reminderPaymentTermsInDays, setReminderPaymentTermsInDays] =
    React.useState(null);

  const [debtCollectionDeliveryMethod, setDebtCollectionDeliveryMethod] =
    React.useState(null);
  const [debtCollectionReason, setDebtCollectionReason] = React.useState(null);
  const [debtCollectionReminderAmount, setDebtCollectionReminderAmount] =
    React.useState(null);
  const [startDebtCollectionLevel, setStartDebtCollectionLevel] =
    React.useState(null);
  const [endDebtCollectionLevel, setEndDebtCollectionLevel] =
    React.useState(null);
  const [debtCollectionPaymentTerms, setDebtCollectionPaymentTerms] =
    React.useState(null);
  const [
    debtCollectionInterestPercentage,
    setDebtCollectionInterestPercentage,
  ] = React.useState(undefined);
  const [interestTypeDebtCollection, setInterestTypeDebtCollection] =
    React.useState(null);
  const [interestStartInDaysAfterDueDate, setInterestStartInDaysAfterDueDate] =
    React.useState(undefined);
  const [reasonForHigherInterest, setReasonForHigherInterest] =
    React.useState(null);
  const [debtCollectionEviction, setDebtCollectionEviction] =
    React.useState(null);
  const [debtCollectionSocialWelfare, setDebtCollectionSocialWelfare] =
    React.useState(null);

  const [invoice, invoiceLoading, invoiceNotFound] = useInvoice({
    creditorId,
    invoiceId,
  });

  const isVacancyBookingInvoice =
    invoice?.ActionType === "VerificationInvoiceAction";
  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const setDebtsToDefaults = () => {
    if (
      !debtCollectionDeliveryMethod &&
      ["Email", "Mail", "Kivra"].includes(invoice?.DeliveryMethod)
    ) {
      setDebtCollectionDeliveryMethod(invoice?.DeliveryMethod);
    }
    if (debtCollectionReminderAmount == null) {
      setDebtCollectionReminderAmount(
        invoice?.DebtCollectionDetails?.NumberOfReminders ?? 1
      );
    }
    if (!startDebtCollectionLevel) {
      setStartDebtCollectionLevel(
        invoice?.DebtCollectionDetails?.StartDebtCollectionActionLevel
      );
    }
    if (!endDebtCollectionLevel) {
      setEndDebtCollectionLevel(
        invoice?.DebtCollectionDetails?.EndDebtCollectionActionLevel
      );
    }
    if (
      debtCollectionPaymentTerms == null &&
      invoice?.DebtCollectionDetails?.PaymentTermsInDays != null &&
      invoice.DebtCollectionDetails.PaymentTermsInDays >= 10
    ) {
      setDebtCollectionPaymentTerms(
        invoice?.DebtCollectionDetails?.PaymentTermsInDays
      );
    }
    if (debtCollectionEviction == null) {
      setDebtCollectionEviction(
        invoice?.DebtCollectionDetails?.Eviction ?? false
      );
    }
    if (debtCollectionSocialWelfare == null) {
      setDebtCollectionSocialWelfare(
        invoice?.DebtCollectionDetails?.InformSocialWelfare ?? false
      );
    }
    if (debtCollectionReason == null) {
      const _str = invoice?.Records?.map((row) => row.ArticleDescription);
      if (_str != null) {
        setDebtCollectionReason(_str.join(", "));
      }
    }
  };

  React.useEffect(() => {
    if (!sendInvoiceMethod) {
      setSendInvoiceMethod(invoice?.DeliveryMethod);
    }
    setDebtsToDefaults(invoice);
  }, [invoice]);

  const hasAttested = !!invoice?.State?.AttestedDate;
  const canDelete = !hasAttested;
  const isPaused = invoice?.State.IsPaused;
  const canAttest = moment(invoice?.InvoiceDate).isSameOrBefore(moment());
  const isDisputed = !!invoice?.State?.DisputedDate;
  const isCreditInvoice = invoice?.ActionType === "CreditInvoiceAction";

  const paidAmount = invoice?.PaidAmount?.ValueForView;

  React.useEffect(() => {
    return () => {
      dispatch(clearIntegrationData());
    };
  }, []);

  const successCallbackBase = ({ title, body }) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title,
        description: body,
      })
    );
  };
  const errorCallbackBase = ({ title, body, toastTimeOut = 4000 }) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title,
        description: body,
        toastTimeOut,
      })
    );
  };

  const handleConfirmAttestInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill attestera fakturan?");
    setConfirmationFunction(() => handleAttestInvoice);
  };
  const handleAttestInvoice = () => {
    setLoading(true);
    dispatch(
      attestInvoice({
        invoiceId: invoiceId,
        creditorId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan attesterades" });
          setLoading(false);
          dispatch(clearInvoiceIdPreview());
        },
        errorCallback: (message) => {
          errorCallbackBase({
            title: "Fakturan kunde inte attesteras",
            body: message || null,
          });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmDeleteInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill radera fakturan?");
    setConfirmationFunction(() => handleDeleteInvoice);
  };

  const handleDeleteInvoice = () => {
    setLoading(true);
    dispatch(
      deleteInvoice({
        invoiceId,
        creditorId,
        successCallback: () => {
          successCallbackBase({ title: "Raderingen lyckades" });
          push(creditorDetailUrl({ creditorId }));
        },
        errorCallback: (message) => {
          errorCallbackBase({})({
            title: "Något gick fel",
            body: message || "Fakturan kunde inte raderas",
          });
        },
      })
    );
  };

  const handleConfirmPauseInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill pausa fakturan?");
    setConfirmationFunction(() => handlePauseInvoice);
  };

  const handlePauseInvoice = () => {
    setLoading(true);
    dispatch(
      pauseInvoice({
        id: invoiceId,
        creditorId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan pausades" });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmResumeInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill aktivera fakturan?");
    setConfirmationFunction(() => handleResumeInvoice);
  };

  const handleResumeInvoice = () => {
    setLoading(true);
    dispatch(
      resumeInvoice({
        id: invoiceId,
        creditorId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan aktiverades" });
          setLoading(false);
        },
      })
    );
  };

  const handleSendManualReminderInvoice = () => {
    setLoading(true);

    dispatch(
      sendManualReminderInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        reminderInvoiceDeliveryMethod,
        reminderFee,
        reminderPaymentTermsInDays,
        successCallback: () => {
          successCallbackBase({ title: "Påminnelse skickades" });
          setLoading(false);
          setReminderInvoiceSettingsOpen(false);

          setReminderFee(0);
          setReminderPaymentTermsInDays(null);
          setReminderInvoiceDeliveryMethod(null);
        },
        errorCallback: (message) => {
          errorCallbackBase({
            title: "Kunde ej skicka påminnelse",
            body: message || "Kontrollera inställningarna och försök igen",
          });
        },
      })
    );
  };

  const handleSendToDebtCollection = () => {
    setLoading(true);

    dispatch(
      sendDebtCollectionInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        payload: {
          SourcePublicId: invoiceId,
          DeliveryMethod: debtCollectionDeliveryMethod,
          CommunicationLanguage: "SV",
          ReasonDescription: debtCollectionReason,
          NumberOfReminders: debtCollectionReminderAmount,
          StartDebtCollectionActionLevel: startDebtCollectionLevel,
          EndDebtCollectionActionLevel: endDebtCollectionLevel,
          PaymentTermsInDays: debtCollectionPaymentTerms,
          InterestPercentage: debtCollectionInterestPercentage,
          InterestType: interestTypeDebtCollection,
          OurReference: invoice?.OurReference ?? null,
          YourReference: invoice?.YourReference ?? null,
          InterestStartInDaysAfterDueDate: interestStartInDaysAfterDueDate,
          ReasonForHigherInterest: reasonForHigherInterest,
          DeliveryAddressOverride: null,
          PaymentOverride: null,
          Eviction: debtCollectionEviction,
          InformSocialWelfare: debtCollectionSocialWelfare,
        },
        successCallback: () => {
          successCallbackBase({ title: "Kravhantering skickades" });
          setLoading(false);
          setSendToDebtCollectionOpen(false);

          setDebtsToDefaults(invoice);
        },
        errorCallback: (message) => {
          setLoading(false);

          errorCallbackBase({
            title: "Kunde ej skicka till kravhantering",
            body: message || "Kontrollera inställningarna och försök igen",
            toastTimeOut: 10000,
          });
        },
      })
    );
  };

  const handleSendInvoice = () => {
    setLoading(true);

    dispatch(
      sendInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        method: sendInvoiceMethod,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan skickades" });
          setLoading(false);
          setSendInvoiceOpen(false);
        },
        errorCallback: (e) => {
          errorCallbackBase({
            title: "Fakturan skickades inte",
            body: e || "Något gick fel. Försök igen.",
          });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmDisputeInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill markera som bestriden?");
    setConfirmationFunction(() => handleDisputeInvoice);
  };

  const handleDisputeInvoice = () => {
    setLoading(true);

    dispatch(
      disputeInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan markerades som bestriden" });
          setLoading(false);
        },
        errorCallback: (e) => {
          errorCallbackBase({
            title: "Fakturan kunde ej bestridas",
            body: e || "Något gick fel. Försök igen.",
          });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmUndisputeInvoice = () => {
    setConfirmationTitle(
      "Är du säker på att du vill markera som EJ bestriden?"
    );
    setConfirmationFunction(() => handleUndisputeInvoice);
  };

  const handleUndisputeInvoice = () => {
    setLoading(true);

    dispatch(
      undisputeInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        successCallback: () => {
          successCallbackBase({
            title: "Fakturan markerades som EJ bestriden",
          });
          setLoading(false);
        },
        errorCallback: (e) => {
          errorCallbackBase({
            title: "Bestridningen kunde ej tas bort",
            body: e || "Något gick fel. Försök igen.",
          });
          setLoading(false);
        },
      })
    );
  };

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: InvoiceOverview,
    },
    {
      path: "/preview",
      label: `Granska ${isVacancyBookingInvoice ? "underlag" : "faktura"}`,
      component: InvoicePreview,
    },
    {
      path: "/events",
      label: `Händelser ${
        invoice?.Events?.length ? `(${invoice?.Events?.length})` : ""
      }`,
      component: InvoiceEvents,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const renderActions = () => {
    return (
      <div className="flex items-center">
        {isCreditInvoice && hasAttested && !invoice?.CreditingInvoice && (
          <PrimaryBtn onClick={() => setMatchCreditToDebitOpen(true)}>
            Nyttja kredit mot debet
          </PrimaryBtn>
        )}
        {isCreditInvoice && !hasAttested && !invoice?.CreditingInvoice && (
          <PrimaryBtn
            className="mr-2"
            secondary
            onClick={() => setConnectCreditToDebitOpen(true)}
          >
            Koppla mot debetfaktura
          </PrimaryBtn>
        )}
        {invoice && !hasAttested && canAttest && (
          <PrimaryBtn onClick={handleConfirmAttestInvoice}>
            Attestera
          </PrimaryBtn>
        )}
      </div>
    );
  };

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel
          state={
            isPaused
              ? "Pausad"
              : isCreditInvoice
              ? "Kreditfaktura"
              : billectaStateMap[invoice?.State?.Stage]
          }
        >
          {isPaused
            ? "Pausad"
            : isCreditInvoice
            ? "Kreditfaktura"
            : billectaStateMap[invoice?.State?.Stage]}
        </StatusLabel>

        {isPaused && isCreditInvoice && (
          <StatusLabel style={{ marginLeft: 12 }} state={"Kreditfaktura"}>
            Kreditfaktura
          </StatusLabel>
        )}

        {isDisputed && (
          <StatusLabel style={{ marginLeft: 12 }} state={6}>
            Bestriden
          </StatusLabel>
        )}

        {invoice && (
          <StatusLabel style={{ marginLeft: 12 }} state={1}>
            Fakturerat:{" "}
            {toMoneyString(invoice?.InvoicedAmount?.ValueForView, true)}
          </StatusLabel>
        )}

        {invoice && (
          <StatusLabel style={{ marginLeft: 12 }} state={4}>
            Kvarstående:{" "}
            {toMoneyString(invoice?.CurrentAmount?.ValueForView, true)}
          </StatusLabel>
        )}
      </>
    );
  };

  const handleActions = [];

  if (!hasAttested && canAttest) {
    handleActions.push({
      name: "Attestera",
      onClick: handleConfirmAttestInvoice,
    });
  }

  if (!hasAttested) {
    handleActions.push({
      name: "Redigera",
      onClick: () => push(editUrl({ creditorId, invoiceId })),
    });
  }

  if (invoice?.PaidAmount?.ValueForView > 0) {
    handleActions.push({
      name: "Justera felaktig inbetalning",
      onClick: () => setAdjustPaymentOpen(true),
    });
  }

  handleActions.push({
    name: "Kopiera till ny faktura",
    onClick: () => setCopyInvoiceOpen(true),
  });

  if (invoice?.CanCreateCreditInvoice) {
    handleActions.push({
      name: "Skapa kreditfaktura",
      onClick: () => setCreditInvoiceOpen(true),
    });
  }

  if (invoice?.CanPause && hasAttested) {
    handleActions.push({
      name: "Pausa",
      onClick: handleConfirmPauseInvoice,
    });
  }

  if (invoice?.CanResume) {
    handleActions.push({
      name: "Aktivera",
      onClick: handleConfirmResumeInvoice,
    });
  }

  if (invoice?.CanSendManualReminderInvoice) {
    handleActions.push({
      name: "Skicka påminnelse",
      onClick: () => setReminderInvoiceSettingsOpen(true),
    });
  }

  if (
    hasAttested &&
    (invoice?.CanResendManualInvoice || invoice?.CanSendManualInvoice)
  ) {
    handleActions.push({
      name: "Skicka faktura",
      onClick: () => setSendInvoiceOpen(true),
    });
  }

  if (invoice?.CanDispute) {
    handleActions.push({
      name: "Markera som bestriden",
      onClick: handleConfirmDisputeInvoice,
    });
  }

  if (invoice?.CanSendToDebtCollection) {
    handleActions.push({
      name: "Skicka till kravhantering",
      onClick: () => setSendToDebtCollectionOpen(true),
    });
  }

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: handleConfirmDeleteInvoice,
      isDelete: true,
    });
  }

  if (invoiceNotFound) {
    return (
      <InvoiceNotFound
        goBack={() => push(creditorInvoiceDetailUrl({ creditorId }))}
      />
    );
  }
  return (
    <>
      {sendInvoiceOpen && (
        <InvoiceSendForm
          closeFunction={() => setSendInvoiceOpen(false)}
          sendInvoice={handleSendInvoice}
          value={sendInvoiceMethod}
          setValue={(val) => setSendInvoiceMethod(val)}
        />
      )}

      {connectCreditToDebitOpen && (
        <ConnectToDebitInvoiceForm
          companyId={company?.id}
          debtorId={invoice?.Debtor?.DebtorPublicId}
          creditorId={creditorId}
          closeFunction={() => setConnectCreditToDebitOpen(false)}
        />
      )}

      {matchCreditToDebitOpen && (
        <MatchCreditToDebitForm
          debtorId={invoice?.Debtor?.DebtorPublicId}
          creditorId={creditorId}
          originalId={invoiceId}
          closeFunction={() => setMatchCreditToDebitOpen(false)}
        />
      )}

      {copyInvoiceOpen && (
        <CopyInvoiceForm
          creditorId={creditorId}
          copyFrom={invoiceId}
          closeFunction={() => setCopyInvoiceOpen(false)}
        />
      )}

      {creditInvoiceOpen && (
        <CreditInvoiceForm
          creditorId={creditorId}
          originalId={invoiceId}
          originalIsClosed={!!invoice?.State?.ClosedDate}
          closeFunction={() => setCreditInvoiceOpen(false)}
        />
      )}

      {adjustPaymentOpen && (
        <AdjustPaymentForm
          originalInvoiceId={invoiceId}
          creditorId={creditorId}
          closeFunction={() => setAdjustPaymentOpen(false)}
          originalPaidAmount={paidAmount}
          originalInvoiceDate={invoice?.InvoiceDate}
          originalClosedDate={invoice?.State?.ClosedDate}
          companyId={company?.id}
        />
      )}

      {reminderInvoiceSettingsOpen && (
        <InvoiceSendReminderForm
          {...{
            closeFunction: () => setReminderInvoiceSettingsOpen(false),
            handleSendManualReminderInvoice,
            reminderFee,
            reminderInvoiceDeliveryMethod,
            reminderPaymentTermsInDays,
            setReminderFee,
            setReminderInvoiceDeliveryMethod,
            setReminderPaymentTermsInDays,
          }}
        />
      )}

      {sendToDebtCollectionOpen && (
        <InvoiceSendToDebtCollection
          {...{
            closeFunction: () => setSendToDebtCollectionOpen(false),
            handleSendToDebtCollection,
            debtCollectionDeliveryMethod,
            setDebtCollectionDeliveryMethod,
            debtCollectionEviction,
            setDebtCollectionEviction,
            debtCollectionReason,
            setDebtCollectionReason,
            debtCollectionReminderAmount,
            setDebtCollectionReminderAmount,
            startDebtCollectionLevel,
            setStartDebtCollectionLevel,
            endDebtCollectionLevel,
            setEndDebtCollectionLevel,
            debtCollectionPaymentTerms,
            setDebtCollectionPaymentTerms,
            interestTypeDebtCollection,
            setInterestTypeDebtCollection,
            interestStartInDaysAfterDueDate,
            setInterestStartInDaysAfterDueDate,
            reasonForHigherInterest,
            setReasonForHigherInterest,
            debtCollectionSocialWelfare,
            setDebtCollectionSocialWelfare,
            debtCollectionInterestPercentage,
            setDebtCollectionInterestPercentage,
          }}
        />
      )}

      {!!confirmationFunction && (
        <ConfirmModal
          onAccept={confirmationFunction}
          closeFunction={() => setConfirmationFunction(null)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            {confirmationTitle}
          </h3>
        </ConfirmModal>
      )}

      <DetailLayoutWrapper>
        {loading && <FullPageSpinner />}
        <DetailPageHeaderMenu
          title={`${
            isVacancyBookingInvoice ? "Vakansbokningsunderlag" : "Faktura"
          } ${
            invoice?.InvoiceNumber
              ? `nr ${invoice?.InvoiceNumber}`
              : invoiceLoading
              ? "(Laddar...)"
              : "(Ej attesterad)"
          }`}
          {...{
            subPages: subPageLinks,
            breadCrumbs: [
              {
                label: "Hyresavisering",
                url: "/rental-notifications",
              },
              {
                url: `/rental-notifications/company/${creditorId}/${
                  isVacancyBookingInvoice ? "vacancy-bookings" : "invoices"
                }`,
                label: company?.str_representation,
              },
              {
                label: `Faktura ${
                  invoice?.InvoiceNumber
                    ? `nr ${invoice?.InvoiceNumber}`
                    : invoiceLoading
                    ? "(Laddar...)"
                    : "(Ej attesterad)"
                }`,
              },
            ],
            renderActions,
            handleActions,
            renderInfoPills: isVacancyBookingInvoice ? null : renderInfoPills,
          }}
        />

        {isDisputed && (
          <>
            <div style={{ padding: "6px 24px 0 24px" }}>
              <InfoBox
                boxTheme="warning"
                title="Faktura bestriden"
                text={`Denna faktura markerades som bestriden ${moment(
                  invoice?.State.DisputedDate
                ).format("YYYY-MM-DD HH:mm")}.`}
                renderButton={{
                  title: "Klicka här för att ta bort bestridningen",
                  clicked: handleConfirmUndisputeInvoice,
                  iconType: "close",
                  iconPlacement: "right",
                }}
              />
            </div>
          </>
        )}

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default InvoiceDetail;
