import * as React from "react";

// store, state
import { constants } from "../../../../../store/industrialPremises";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { InnerBox } from "../../../../sharedStyles";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { cloneDeep } from "lodash";
import Table from "../../../../Billecta/Table/BasicTable";
import { useDispatch } from "react-redux";

import NestedPremisesStatusChildren from "../../../PremisesStatus/NestedChildren";
import { DatePicker } from "../../../Base/Fields";
import { lightOrDark } from "../../../../DigitalDoc/utils";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import PremisesStatusTable from "src/components/Tables/PremisesStatuses/FullTable";
import DateSelect from "src/components/Forms/Base/Fields/DateSelect";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [editIndex, setEditIndex] = React.useState(null);

  const statuses =
    useFormInstanceField({
      storeName,
      fieldKey: "customer_statuses",
    }) || [];

  const addStatusRow = () => {
    const statusClone = cloneDeep(statuses) || [];
    statusClone.push({
      start_date: null,
      end_date: null,
      status: null,
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          customer_statuses: statusClone,
        },
      })
    );

    setEditIndex(statusClone.length - 1);
  };

  const handleEditStatus = (row) => {
    setEditIndex(row.index);
  };

  const removeStatus = (idx) => {
    const statusClone = cloneDeep(statuses) || [];

    statusClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          customer_statuses: statusClone,
        },
      })
    );
  };

  const checkRowError = () => {};

  const getStatusChildren = (parentPath) => {
    const parentInstructionsPath = `customer_statuses.status`;

    return (
      <>
        <NestedPremisesStatusChildren
          {...{ storeName, method, parentPath, parentInstructionsPath }}
        />
      </>
    );
  };

  const tableStatuses = React.useMemo(() => {
    return statuses;
  }, [statuses]);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 8px",
                borderRadius: 5,
                backgroundColor: value?.color_code,
                color:
                  lightOrDark(value?.color_code) === "light" ? "#000" : "#fff",
              }}
            >
              {value?.name || "-"}
            </div>
          );
        },
      },

      {
        Header: "Börjar gälla",
        accessor: "start_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;
          return <div>{value}</div>;
        },
      },
      {
        Header: "Slutar gälla",
        accessor: "end_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;

          return <div>{value}</div>;
        },
      },
      {
        Header: "Ta bort",
        id: "remove",
        Cell: ({ row }) => {
          return (
            <TextButton
              title="Ta bort status"
              red
              clicked={() => removeStatus(row.index)}
            />
          );
        },
      },
    ],
    [statuses]
  );

  if (editIndex != null) {
    return (
      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Hantera status</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectFieldWithCreate
            storeName={storeName}
            TableComponent={PremisesStatusTable}
            title={"Status"}
            method={method}
            fieldKey={`customer_statuses[${editIndex}].status`}
            instructionsKey={"customer_statuses.status"}
            placeholder="Välj status..."
            createDisplayKey="name"
          >
            {(parentPath) => getStatusChildren(parentPath)}
          </TableSelectFieldWithCreate>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <DateSelect
            {...{
              storeName,
              title: "Gäller från",
              method,
              fieldKey: `customer_statuses[${editIndex}].start_date`,
              instructionsKey: "customer_statuses.start_date",
            }}
          />

          <DateSelect
            {...{
              storeName,
              title: "Gäller till",
              method,
              fieldKey: `customer_statuses[${editIndex}].end_date`,
              instructionsKey: "customer_statuses.end_date",
            }}
          />
        </div>

        <div className="flex justify-end">
          <PrimaryBtn onClick={() => setEditIndex(null)}>Spara</PrimaryBtn>
        </div>
      </InnerBox>
    );
  }

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Statusar på objekt</OverviewTitle>
          <OverviewSubtitle>
            Dessa statusar kan t.ex. användas för kategorisering och
            vakansbokningar
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Statusar på objekt</OverviewTitle>
          <PrimaryButton title="Lägg till" clicked={addStatusRow} />
        </OverviewTitleWrapper>

        <Table
          onRowClicked={handleEditStatus}
          columns={tableColumns}
          data={tableStatuses}
          checkRowError={checkRowError}
          hideSearch
        />
      </InnerBox>
    </>
  );
};
