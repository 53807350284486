import styled from "styled-components";

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const FilterButton = styled.div`
  cursor: pointer;
  border: thin solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: #e8e8e8;
  color: #2e2e2e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 100%;
  margin-right: 6px;

  opacity: ${(p) => (p.selected ? 1 : 0.5)};
`;

export const ClosedInvoiceFilterButton = styled(FilterButton)`
  background-color: #c7ffd0;
  color: #14a82c;
  border-color: #14a82c;
`;

export const TypeFilterButton = styled(FilterButton)`
  background-color: #e4f1ff;
  color: #5165fb;
  border-color: #5165fb;
`;

export const NonAttestedFilterButton = styled(FilterButton)`
  background-color: #ffeabd;
  color: #a67611;
  border-color: #a67611;
`;

export const ReminderFilterButton = styled(FilterButton)`
  background-color: #c29eff;
  color: #4700ba;
  border-color: #4700ba;
`;

export const UninterestingFilterButton = styled(FilterButton)`
  background-color: #f3f4f6;
  color: #444a55;
  border-color: #444a55;
`;

export const LateInvoiceFilterButton = styled(FilterButton)`
  background-color: #ffecf2;
  color: #fa3d56;
  border-color: #fa3d56;
`;

export const SentToDebtCollectionFilterButton = styled(FilterButton)`
  background-color: #ffecf2;
  color: #fa3d56;
  border-color: #fa3d56;
`;

export const NonPaidFilterButton = styled(FilterButton)`
  background-color: #d6e4ff;
  color: #114ec2;
  border-color: #114ec2;
`;

export const UnmatchedPaymentFilterButton = styled(FilterButton)`
  background-color: #ffecf2;
  color: #fa3d56;
  border-color: #fa3d56;
`;

export const MatchedPaymentFilterButton = styled(FilterButton)`
  background-color: #d6e4ff;
  color: #114ec2;
  border-color: #114ec2;
`;

export const OverpaidFilterButton = styled(FilterButton)`
  background-color: #ffeabd;
  color: #a67611;
  border-color: #a67611;
`;

export const ActiveFilterButton = styled(FilterButton)`
  background-color: #c7ffd0;
  color: #14a82c;
  border-color: #14a82c;
`;

export const PausedFilterButton = styled(FilterButton)`
  background-color: #ffeabd;
  color: #a67611;
  border-color: #a67611;
`;
