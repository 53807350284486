import {
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  UserGroupIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import Alert, { ALERT_TYPES } from "../Alert/Alert";
import PrimaryBtn from "../Forms/Base/Buttons/PrimaryBtn";
import TextInputField from "../Forms/Base/Fields/TextInputField";
import DigitalDocEditField from "./EditField";
import DigitalDocHandleAttachments from "./MenuActions/HandleAttachments";
import DigitalDocHandleFields from "./MenuActions/HandleFields";
import HandleParties from "./MenuActions/HandleParties";
import DigitalDocHandleSettings from "./MenuActions/HandleSettings";

export default function DigitalDocMenu({
  open,
  storeName,
  closeFunction,
  isTemplateEditor,
  pageBounds,
  method,
  clearDocument,
  isFastDoc,
  usesESigning,
}) {
  const dispatch = useDispatch();
  const [handlePartiesOpen, setHandlePartiesOpen] = React.useState(false);
  const [handleSettingsOpen, setHandleSettingsOpen] = React.useState(false);
  const [handleAddFieldActive, setHandleAddFieldActive] = React.useState(false);
  const [handleAttachmentsActive, setHandleAttachmentsActive] =
    React.useState(false);

  // current editing field
  const handleFieldData = useFormInstanceField({
    storeName,
    fieldKey: "_handleFieldAttrs",
  });

  const onClearHandleField = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _handleFieldAttrs: null,
        },
      })
    );
  };

  return (
    <>
      {handlePartiesOpen && (
        <HandleParties
          isTemplateEditor={isTemplateEditor}
          usesESigning={usesESigning}
          storeName={storeName}
          closeFunction={() => setHandlePartiesOpen(false)}
          method={method}
        />
      )}
      {handleSettingsOpen && (
        <DigitalDocHandleSettings
          storeName={storeName}
          isTemplateEditor={isTemplateEditor}
          usesESigning={usesESigning}
          closeFunction={() => setHandleSettingsOpen(false)}
          method={method}
        />
      )}

      <div
        className={classNames(
          "z-30 absolute flex flex-col transition-all duration-200 ease-in-out top-0 left-0 px-6 pt-16 pb-24 bg-white min-h-screen max-h-screen border-r border-solid border-gray-300 w-1/4 min-w-[400px] max-w-[500px] overflow-y-auto",
          {
            "translate-x-[-100%]": !open,
          }
        )}
      >
        <div className="absolute bg-white px-6 min-h-[55px] top-0 left-0 right-0  flex justify-between items-center border-b border-solid border-gray-300">
          <div className="text-sm font-medium">Hantera dokument</div>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            onClick={closeFunction}
          >
            <XCircleIcon width={24} />
          </button>
        </div>

        {handleAddFieldActive ? (
          <DigitalDocHandleFields
            onDone={() => setHandleAddFieldActive(false)}
            isTemplateEditor={isTemplateEditor}
            storeName={storeName}
            pageBounds={pageBounds}
            method={method}
            isFastDoc={isFastDoc}
          />
        ) : handleAttachmentsActive ? (
          <DigitalDocHandleAttachments
            storeName={storeName}
            method={method}
            clearDocument={clearDocument}
            isFastDoc={isFastDoc}
            onDone={() => setHandleAttachmentsActive(false)}
          />
        ) : (
          <div className="flex flex-1 flex-col space-between">
            <div className="flex flex-col space-y-2">
              <div className="mb-4">
                <TextInputField
                  storeName={storeName}
                  method={method}
                  fieldKey="title"
                  title={isTemplateEditor ? "Namn på mall" : "Namn på dokument"}
                />
              </div>
              <div className="font-medium text-sm">Hantera dokument</div>

              {(isTemplateEditor || usesESigning || !isFastDoc) && (
                <>
                  <PrimaryBtn
                    onClick={() => setHandlePartiesOpen(true)}
                    secondary
                  >
                    Hantera parter <UserGroupIcon width={16} className="ml-1" />{" "}
                  </PrimaryBtn>

                  <PrimaryBtn
                    onClick={() => setHandleAddFieldActive(true)}
                    secondary
                  >
                    Lägg till fält{" "}
                    <DocumentTextIcon width={16} className="ml-1" />
                  </PrimaryBtn>

                  <PrimaryBtn
                    onClick={() => setHandleAttachmentsActive(true)}
                    secondary
                  >
                    Bilagor{" "}
                    <DocumentDuplicateIcon width={16} className="ml-1" />
                  </PrimaryBtn>
                </>
              )}
              <PrimaryBtn onClick={() => setHandleSettingsOpen(true)} secondary>
                Inställningar för dokument{" "}
                <Cog6ToothIcon width={16} className="ml-1" />
              </PrimaryBtn>
            </div>

            <div className="mt-auto flex flex-col">
              <Alert
                type={ALERT_TYPES.DANGER}
                title="Byte av dokument"
                primaryAction={clearDocument}
                primaryTitle="Byt dokument"
              >
                Vid byte av dokument kommer fältplaceringarna att ligga kvar.
                Kontrollera att placeringarna är rätt efter bytet.
              </Alert>
            </div>
          </div>
        )}
      </div>

      <div
        className={classNames(
          "z-20 absolute flex flex-col transition-all duration-200 ease-in-out top-0 left-0 px-6 pt-16 pb-24 bg-white min-h-screen max-h-screen border-r border-solid border-gray-300 w-1/4 min-w-[400px] max-w-[500px] overflow-y-auto",
          {
            "translate-x-[100%]": handleFieldData && open,
            "translate-x-[-100%]": !handleFieldData && !open,
          }
        )}
      >
        <div className="absolute bg-white px-6 min-h-[55px] top-0 left-0 right-0  flex justify-between items-center border-b border-solid border-gray-300">
          <div className="text-sm font-medium">Hantera fält</div>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            onClick={onClearHandleField}
          >
            <XCircleIcon width={24} />
          </button>
        </div>

        {handleFieldData && (
          <DigitalDocEditField
            {...{
              ...handleFieldData,
              storeName,
              method,
              closeFunction: () => onClearHandleField(),
              pageBounds,
            }}
          />
        )}
      </div>
    </>
  );
}
