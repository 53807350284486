import * as React from "react";
import { useTransition } from "@react-spring/web";

// style, design
import * as SC from "./styles";
import viewIcon from "../../../assets/svg/material/view_black.svg";
import editIcon from "../../../assets/svg/material/edit_black.svg";

export default function FormModal({ isOpen, onClose, onSelect }) {
  const modalTransition = useTransition(!!isOpen, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateY(100%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.BackDrop onClick={onClose} style={{ opacity: styles.opacity }}>
          <SC.Modal
            onClick={(e) => e.stopPropagation()}
            style={{ ...styles, opacity: 1 }}
          >
            <SC.ModalContent>
              <SC.ModalTitle>
                Välj om fälten på det nedladdade dokumentet ska vara redigerbara
                eller inte.
              </SC.ModalTitle>

              <SC.OptionsWrapper>
                <SC.Option onClick={() => onSelect("view")}>
                  <SC.OptionIcon imgSrc={viewIcon} />
                  <SC.OptionLabel>Ej redigerbara</SC.OptionLabel>
                </SC.Option>
                <SC.Option onClick={() => onSelect("edit")}>
                  <SC.OptionIcon imgSrc={editIcon} />
                  <SC.OptionLabel>Redigerbara</SC.OptionLabel>
                </SC.Option>
              </SC.OptionsWrapper>
            </SC.ModalContent>
          </SC.Modal>
        </SC.BackDrop>
      )
  );
}
