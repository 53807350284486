import * as React from "react";

// style, design
import { FormAreaDescription, FormAreaTitle } from "../../../Base/Chapters/styles";

export default () => {

  return (
    <>
      <FormAreaTitle>Granska och slutför</FormAreaTitle>
      <FormAreaDescription>
        Kontrollera att uppgifterna stämmer. Tryck på "Skapa hyresgäst" för
        att slutföra.
      </FormAreaDescription>
    </>
  );
};
