import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { DateCell, LinkedObject } from "../../Displays";
import { toMoneyString } from "../../utils/stringUtils";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import ContractInvoicingTableFilters from "../ExternalFilters/ContractInvoicingTableFilters";
import { cloneDeep } from "lodash";

export default function ContractInvoicingTable({
  invoices = [],
  handleRowClicked,
}) {
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [selectedTypes, setSelectedTypes] = React.useState([]);
  const [filterCounts, setFilterCounts] = React.useState({});
  const [typeCounts, setTypeCounts] = React.useState({});

  const filteredByTypes = React.useMemo(() => {
    let displayedInvoices = invoices;

    // filter types
    if (selectedTypes?.length) {
      displayedInvoices = displayedInvoices.filter((i) => {
        return selectedTypes.includes(i.content_type);
      });
    }

    return displayedInvoices;
  }, [invoices, selectedTypes]);

  const filteredByFilters = React.useMemo(() => {
    let displayedInvoices = invoices;

    // filter external filters
    if (selectedFilters?.length) {
      displayedInvoices = displayedInvoices.filter((i) => {
        return selectedFilters.includes(i.status);
      });
    }

    return displayedInvoices;
  }, [invoices, selectedFilters]);

  React.useEffect(() => {
    let newFilterCounts = {};

    filteredByTypes.forEach((i) => {
      if (newFilterCounts[i.status]) {
        newFilterCounts[i.status] += 1;
      } else {
        newFilterCounts[i.status] = 1;
      }
    });
    setFilterCounts(newFilterCounts);
  }, [filteredByTypes, invoices]);

  React.useEffect(() => {
    let newTypeCounts = {};

    filteredByFilters.forEach((i) => {
      if (newTypeCounts[i.content_type]) {
        newTypeCounts[i.content_type] += 1;
      } else {
        newTypeCounts[i.content_type] = 1;
      }
    });
    setTypeCounts(newTypeCounts);
  }, [invoices, filteredByFilters]);

  const toggleFilter = React.useCallback(
    (filter) => {
      let filterClone = cloneDeep(selectedFilters);
      if (selectedFilters?.includes(filter)) {
        filterClone = filterClone.filter((f) => f !== filter);
      } else {
        filterClone.push(filter);
      }

      setSelectedFilters(filterClone);
    },
    [selectedFilters]
  );
  const toggleTypes = React.useCallback(
    (filter) => {
      let filterClone = cloneDeep(selectedTypes);
      if (selectedTypes?.includes(filter)) {
        filterClone = filterClone.filter((f) => f !== filter);
      } else {
        filterClone.push(filter);
      }

      setSelectedTypes(filterClone);
    },
    [selectedTypes]
  );

  const data = React.useMemo(() => {
    let displayedInvoices = invoices;

    // filter external filters
    if (selectedFilters?.length) {
      displayedInvoices = displayedInvoices.filter((i) => {
        return selectedFilters.includes(i.status);
      });
    }

    // filter types
    if (selectedTypes?.length) {
      displayedInvoices = displayedInvoices.filter((i) => {
        return selectedTypes.includes(i.content_type);
      });
    }

    return displayedInvoices;
  }, [invoices, selectedFilters, selectedTypes]);

  const statusMap = {
    bi_invoiced: "Samaviseras",
    active: "Aviseras",
    inactive: "Aviseras ej",
    closed: "Tidigare",
  };
  const statusStateMap = {
    bi_invoiced: 4,
    active: 0,
    inactive: 3,
    closed: 9,
  };

  const contentTypeMap = {
    "accounting.leaseinvoicing": "Hyresavtal",
    "accounting.otherinvoicing": "Övrigt avtal",
    "accounting.parkinginvoicing": "Parkeringsavtal",
    "accounting.brfinvoicing": "Bostadsrätt",
  };

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: "Aviseringsstatus",
        accessor: (row) => statusMap[row.status] || row.status,
        Cell: ({ row }) => {
          return (
            <StatusLabel state={statusStateMap[row.original.status]}>
              {statusMap[row.original.status] || row.original.status}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Avtalstyp",
        accessor: (row) => contentTypeMap[row.content_type],
        Cell: ({ value }) => {
          return <Field>{value}</Field>;
        },
      },
      {
        Header: "Avtalsstatus",
        accessor: "contract_status_display",
        Cell: ({ value, row }) => {
          return (
            <StatusLabel state={row.original.contract_status}>
              {value}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Avtals/objektsnr.",
        accessor: "str_representation",
        Cell: ({ value }) => {
          return <Field>{value}</Field>;
        },
      },
      {
        Header: "Fakturerad part",
        accessor: (row) => row.tenant?.str_representation,
        Cell: ({ row }) => {
          return (
            <LinkedObject
              obj={row.original.tenant}
              urlMethod={tenantDetailUrl}
            />
          );
        },
      },

      {
        Header: "Belopp",
        id: (row) => {
          const total = row.cost_set?.reduce(
            (acc, cur) => acc + (cur.total_value || 0),
            0
          );
          return total;
        },
        Cell: ({ row }) => {
          const total = row.original.cost_set?.reduce(
            (acc, cur) => acc + (cur.total_value || 0),
            0
          );
          return <Field>{toMoneyString(total, true)}</Field>;
        },
      },

      {
        Header: "Genereras",
        accessor: "current_invoice_date",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Period från",
        accessor: "current_period_start",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Period till",
        accessor: "current_period_end",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Förfaller",
        accessor: "current_due_date",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
    ];

    return cols;
  }, []);

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        onRowClicked={handleRowClicked}
        tableId="contractinvoicingtable"
        forceInitialPageSize={50}
        externalFilters={() => (
          <ContractInvoicingTableFilters
            toggleFilter={toggleFilter}
            selectedFilters={selectedFilters}
            filterCounts={filterCounts}
            toggleTypes={toggleTypes}
            selectedTypes={selectedTypes}
            typeCounts={typeCounts}
          />
        )}
      />
    </>
  );
}
