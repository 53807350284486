import { cloneDeep } from "lodash";

import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import { store } from "../../store";
import { createUploadHandler } from "./uploadHandler";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const preProcess = (data) => {
  const dataClone = cloneDeep(data);

  // logo
  if (dataClone?.company?.image?._tempData) {
    dataClone.company.image = dataClone.company.image._tempData.file_name;
  } else {
    delete dataClone.company.image;
  }

  if (dataClone.financial_planData_) {
    delete dataClone.financial_planData_;
  } else {
    delete dataClone.financial_plan;
  }

  // the rule documents
  (dataClone?.rule_documents || []).forEach((a) => {
    if (a.file._tempData) {
      // will never be sent to backend
      a.file = a.file.file;
      delete a._tempData;

      if (a.id) {
        // file has not been edited, so we can just use the id
        delete a?.file;
      }
    }
  });

  return dataClone;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const uploadCallback = async (responseData, dispatch) => {
    const state = store.getState();
    const formInstance = state[constants.STORE_NAME].formInstance;

    await createUploadHandler({ formInstance, responseData, dispatch });

    return;
  };

  return post({
    url: constants.POST_URL,
    constants,
    preProcess: preProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const uploadCallback = async (responseData, dispatch) => {
    const state = store.getState();
    const formInstance = state[constants.STORE_NAME].formInstance;

    await createUploadHandler({ formInstance, responseData, dispatch });

    return;
  };
  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    preProcess: preProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback,
  });
};
