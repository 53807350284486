import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import { usePermissionCheck } from "../../store/base";

import BuildingModal from "../../components/Forms/Building/ChapterForm/ModalForm";
import Buildings from "./overview/Buildings";
import { useBuildingPaginationCount } from "../../store/buildings/hooks/retrieve";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function MainBuildingOverview() {
  const { filteredRealEstates } = useActiveFilters();
  const { path, url } = useRouteMatch();
  const [createBuildingOpen, setCreateBuildingOpen] = React.useState(false);
  const canAdd = usePermissionCheck("add_can_baseobject");
  usePermissionRedirect(["view_can_baseobject"]);

  const [buildingCount] = useBuildingPaginationCount({
    filters: { realestate_ids: filteredRealEstates },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: Buildings,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal byggnader: {buildingCount != null ? buildingCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <>
      {canAdd && (
        <PrimaryBtn onClick={() => setCreateBuildingOpen(true)}>
          Lägg till byggnad
        </PrimaryBtn>
      )}
    </>
  );

  return (
    <>
      {createBuildingOpen && (
        <BuildingModal
          method="POST"
          isOpen={createBuildingOpen}
          onCheckout={() => setCreateBuildingOpen(false)}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Byggnader`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
