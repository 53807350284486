import * as React from "react";
import { useDispatch } from "react-redux";
import { matchCreditToDebit } from "../../../store/billectaInvoicing";
import { getOpenDebitInvoicesForDebtor } from "../../../store/billectaSearch";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Table from "../../Billecta/Table/BasicTable";

import { DateCell } from "../../Displays";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { toMoneyString } from "../../utils/stringUtils";
import Modal from "../Base/Modals/Modal";

export default function MatchCreditToDebitForm({
  creditorId,
  closeFunction,
  debtorId,
  originalId,
}) {
  const dispatch = useDispatch();
  const [selectedInvoiceId, setSelectedInvoiceId] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [invoiceOptions, setInvoiceOptions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const match = () => {
    setLoading(true);
    dispatch(
      matchCreditToDebit({
        debitInvoiceActionId: selectedInvoiceId,
        creditInvoiceActionId: originalId,
        creditorId,
        updateSingular: true,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Kredit nyttjades på vald debet ",
            })
          );
          closeFunction();
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej nyttja kredit på vald debet",
            })
          );

          closeFunction();
        },
      })
    );
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(
      getOpenDebitInvoicesForDebtor({
        creditorId,
        debtorId,
        successCallback: (invoices) => {
          setLoading(false);
          setInvoiceOptions(invoices);
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta debetfakturor",
            })
          );

          closeFunction();
        },
      })
    );
  }, []);

  const rows = React.useMemo(() => {
    return invoiceOptions || [];
  }, [invoiceOptions]);

  const cols = React.useMemo(() => {
    return [
      {
        Header: "Fakturanr.",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },

      {
        Header: "Fakturadatum",
        accessor: "InvoiceDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "DueDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Fakturerat belopp",
        accessor: "InvoicedAmount.ValueForView",
        Cell: (props) => {
          return <div>{toMoneyString(props.value, true)}</div>;
        },
      },
      {
        Header: "Kvarstående belopp",
        accessor: "CurrentAmount.ValueForView",
        Cell: (props) => {
          return <div>{toMoneyString(props.value, true)}</div>;
        },
      },
      {
        Header: "OCR",
        accessor: "OCR",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },
    ];
  }, [invoiceOptions]);

  return (
    <Modal
      closeFunction={closeFunction}
      title="Välj faktura att nyttja kredit på"
      onAccept={match}
      canAccept={!!selectedInvoiceId}
      acceptTitle="Nyttja kredit på vald debet"
      denyTitle="Avbryt"
    >
      {loading && <OverlaySpinner />}

      <Table
        columns={cols}
        hideSearch
        selectedRow={selectedRow}
        data={rows}
        onRowClicked={({ original, id }) => {
          setSelectedRow(id);
          setSelectedInvoiceId(original.ActionPublicId);
        }}
      />
    </Modal>
  );
}
