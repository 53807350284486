import * as React from "react";
import { detailUrl as apartmentDetailUrl } from "../../../../store/apartments";
import { detailUrl as industrialDetailUrl } from "../../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../../store/parkingSpots";

import {
  getCostBetweenMonthDates,
  getIndexBetweenMonths,
  getMonthsForCost,
  getSpecifiedPeriodDates,
  PRODUCT_CATEGORY_MAPPING,
} from "src/components/Lists/utils/costCalculations";

import { toMoneyPerSqmString, toMoneyString } from "../../../utils/stringUtils";

import moment from "moment";
import { durationParse } from "src/components/Displays/utils";
import { renderFloors } from "src/components/Forms/Base/FieldComponents/FloorSelector";
import { getErrandStatus } from "src/components/utils/errandUtils";
import { unpackErrorStr } from "src/components/Tables/InvoicingErrors/listDefs";

export const AVAILABLE_OPERATORS = {
  CONTAINS: "__icontains",
  NOT_CONTAINS: "__icontains!",
  BOOLEAN: "",
  IN: "__in",
  NOT_IN: "__in!",
  GREATER_THAN: "__gte",
  LESS_THAN: "__lte",
  EXACT: "__iexact",
  EXACT_NUMBER: "",
};

export const OPERATOR_TEMPLATES = {
  SEARCH: [
    AVAILABLE_OPERATORS.CONTAINS,
    AVAILABLE_OPERATORS.NOT_CONTAINS,
    AVAILABLE_OPERATORS.EXACT,
  ],
  BOOLEAN: [AVAILABLE_OPERATORS.BOOLEAN],
  SELECT: [AVAILABLE_OPERATORS.IN, AVAILABLE_OPERATORS.NOT_IN],
  DATE: [AVAILABLE_OPERATORS.GREATER_THAN, AVAILABLE_OPERATORS.LESS_THAN],
  NUMBER: [
    AVAILABLE_OPERATORS.EXACT_NUMBER,
    AVAILABLE_OPERATORS.CONTAINS,
    AVAILABLE_OPERATORS.NOT_CONTAINS,
    AVAILABLE_OPERATORS.GREATER_THAN,
    AVAILABLE_OPERATORS.LESS_THAN,
  ],
};

export const OPERATORS_TO_FIELD = {
  [AVAILABLE_OPERATORS.CONTAINS]: "input",
  [AVAILABLE_OPERATORS.NOT_CONTAINS]: "input",
  [AVAILABLE_OPERATORS.BOOLEAN]: "boolean",
  [AVAILABLE_OPERATORS.IN]: "select",
  [AVAILABLE_OPERATORS.NOT_IN]: "select",
  [AVAILABLE_OPERATORS.GREATER_THAN]: ["number", "date"],
  [AVAILABLE_OPERATORS.LESS_THAN]: ["number", "date"],
  [AVAILABLE_OPERATORS.EXACT]: "input",
  [AVAILABLE_OPERATORS.EXACT_NUMBER]: ["number", "boolean"],
};

export const OPERATORS_TO_TEXT = {
  [AVAILABLE_OPERATORS.CONTAINS]: "innehåller",
  [AVAILABLE_OPERATORS.NOT_CONTAINS]: "innehåller ej",
  [AVAILABLE_OPERATORS.BOOLEAN]: "är",
  [AVAILABLE_OPERATORS.IN]: "innehåller",
  [AVAILABLE_OPERATORS.NOT_IN]: "innehåller ej",
  [AVAILABLE_OPERATORS.GREATER_THAN]: {
    number: "min",
    date: "från",
  },
  [AVAILABLE_OPERATORS.LESS_THAN]: {
    number: "max",
    date: "till",
  },
  [AVAILABLE_OPERATORS.EXACT]: "exakt",
  [AVAILABLE_OPERATORS.EXACT_NUMBER]: {
    number: "exakt",
    boolean: "är",
  },
};

const excelAccessorBillectaIdMethod = (value, completeObj) => {
  if (!value || value.length === 0) return "Inaktiv";

  return "Aktiv";
};

const excelAccessorGetNotificationTextMethod = (value) => {
  if (value === true) return "Konfigurerad";
  if (value === null) return "Ej konfigurerad";
  if (value === false) return "Pausad";
  return "Okänd";
};

const excelAccessorHandleObjectWithStrRepMethod = (value) => {
  if (typeof value === "object" && !Array.isArray(value) && value !== null) {
    if (value.hasOwnProperty("str_representation")) {
      return value.str_representation;
    }
    return "-";
  }
  return value;
};

const excelAccessorParseDateMethod = (value) => {
  if (!value) return "-";
  return moment(value).format("YYYY-MM-DD, HH:mm");
};

export const EXCEL_ACCESSOR_MAP = {
  invite_pending: "user.invite_pending",
  billecta_id: excelAccessorBillectaIdMethod,
  "company.billecta_id": excelAccessorBillectaIdMethod,
  debtor_invoice_config: (value) => {
    if (
      !(typeof value === "object" && !Array.isArray(value) && value !== null)
    ) {
      return "Ej konfigurerad";
    }

    if (!value.hasOwnProperty("id")) return "Ej konfigurerad";
    if (value.id) return "Konfigurerad";
  },
  recipient_count: (value, obj) => {
    let realValue = obj.recipients;
    if (!realValue) return "-";
    if (!Array.isArray(realValue)) return "-";
    return realValue.length;
  },
  scheduled_for: excelAccessorParseDateMethod,
  lease_invoicing_active: (value) => {
    return excelAccessorGetNotificationTextMethod(value);
  },
  other_invoicing_active: (value) => {
    return excelAccessorGetNotificationTextMethod(value);
  },
  parking_invoicing_active: excelAccessorGetNotificationTextMethod,
  brf_invoicing_active: excelAccessorGetNotificationTextMethod,
  notify_interval: (value) => {
    return durationParse(value, true);
  },
  renew_interval: (value) => {
    return durationParse(value, true);
  },
  tenant: excelAccessorHandleObjectWithStrRepMethod,
  address: excelAccessorHandleObjectWithStrRepMethod,
  realestate: excelAccessorHandleObjectWithStrRepMethod,
  dynamic_floors: (value) => {
    if (!value) return "-";
    if (!Array.isArray(value)) return "-";

    return renderFloors(value);
  },
  base_rent: (value, obj) => {
    //obj.base_rent_is_m2 seems to be deprecated, keeping it in-case it ever comes back
    return obj.base_rent_is_m2
      ? toMoneyPerSqmString(value)
      : toMoneyString(value);
  },
  base_rent_year: (value, obj) => {
    if (!obj.hasOwnProperty("base_rent")) return "-";

    let newVal = obj.base_rent * 12;

    return obj.base_rent_is_m2
      ? toMoneyPerSqmString(newVal)
      : toMoneyString(newVal);
  },
  closed_date: (value, obj) => {
    if (value != null) return value;
    if (obj.renewed_to) return obj.renewed_to;
    return obj.end_date;

    // {
    //   row.original.closed_date ? (
    //     <DateCell date={row.original.closed_date} />
    //   ) : row.original.renewed_to ? (
    //     <>
    //       <DateCell date={row.original.renewed_to} />
    //       <span> (Förlängt)</span>
    //     </>
    //   ) : (
    //     <DateCell date={row.original.end_date} />
    //   );
    // }
  },
  inhouse_time: excelAccessorParseDateMethod,
  created_at: excelAccessorParseDateMethod,
  planned_start: excelAccessorParseDateMethod,
  execute_end: excelAccessorParseDateMethod,
  "connected_obj.content_type": (value) => {
    return value === "standard.apartment"
      ? "Lägenhet"
      : value === "standard.industrialpremises"
      ? "Lokal"
      : value === "standard.commonarea"
      ? "Gemensamt utrymme"
      : value === "standard.parkingspot"
      ? "Fordonsplays"
      : value === "brf.brfpremis"
      ? "Bostadsrätt"
      : "Okänd";
  },
  active_rating: (value, obj) => {
    const active_rating = obj?.active_rating;
    const errandStatus = obj?.status;
    let displayValue = "Inte utfört";
    if ([3, 4].includes(errandStatus) && active_rating) {
      displayValue = active_rating?.is_ok ? "Ja" : "Nej";
    } else if (errandStatus === 1 || errandStatus === 2) {
      displayValue = "Inte fastställt";
    }
    return displayValue;
  },
  reported_by: excelAccessorHandleObjectWithStrRepMethod,
  connected_to_type: (value, obj) => {
    if (obj.apartment) {
      return "Lägenhet";
    }
    if (obj.industrial_premises) {
      return "Lokal";
    }
    if (obj.common_area) {
      return "Gemensamt utrymme";
    }
    if (obj.parking_spot) {
      return "Fordonsplats";
    }
    if (obj.brf_premis) {
      return "Bostadsrätt";
    }
  },
  connected_to: (value, obj) => {
    let val = null;

    if (obj.apartment) {
      val = obj.apartment;
    } else if (obj.industrial_premises) {
      val = obj.industrial_premises;
    } else if (obj.common_area) {
      val = obj.common_area;
    } else if (obj.parking_spot) {
      val = obj.parking_spot;
    } else if (obj.brf_premis) {
      val = obj.brf_premis;
    }

    if (val == null) return "-";

    return excelAccessorHandleObjectWithStrRepMethod(val);
  },
  paused: (value) => {
    return value ? "Inaktiv" : "Aktiv";
  },
  errand_status: (value, obj) => {
    const { status, display } = getErrandStatus({
      status: obj?.status,
      plannedStart: obj?.planned_start,
      plannedEnd: obj?.planned_end,
      actualStart: obj?.execute_start,
      actualEnd: obj?.execute_end,
    });
    return display;
  },
  setting: excelAccessorHandleObjectWithStrRepMethod,
  faults: (value, obj) => {
    const faults = value;
    if (obj.status === 3 || obj.status === 4) {
      if (faults.length > 0) {
        return `${faults.length} st`;
      } else {
        return "Nej";
      }
    }
    return "-";
  },
  updated_at: excelAccessorParseDateMethod,
  error: (value) => {
    if (!value) return "-";
    if (Object.keys(value).length === 0) return "-";

    const error = unpackErrorStr(value);

    return error.join(", ");
  },
  // content_type: (value, obj) => {
  //   if (!obj.content_type || !obj.object_id) return "-";
  //   console.log(
  //     "to fetch",
  //     {
  //       contentType: obj.content_type,
  //       id: obj.object_id,
  //     },
  //     obj
  //   );
  //   return {
  //     doObjectLinkFetch: true,
  //     contentType: obj.content_type,
  //     id: obj.object_id,
  //   };
  // },
};

export const ALWAYS_HIDDEN_EXCEL_COLUMNS = [
  "logotype_from_buildings",
  "delete",
  "content_type",
  "main_contract",
  "__expander",
  "realestates",
  "related_object",
  // "connected_obj.content_type",
];

export const EXCEL_ENUM_MAP = {
  state: {
    0: "Nuvarande",
    1: "Kommande",
    2: "Potentiell",
    3: "Tidigare",
  },
};

export const OVERRIDE_COLUMN_WIDTH_WITH_ACCESSOR = {
  state: 20, //excel character width
};

export const getExpandedContentForContracts = (row, options) => {
  const contract = row.original;
  const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
    start: options.startDate,
    end: options.endDate,
    contract,
  });

  const contents = [];

  const costs = getCostBetweenMonthDates(
    contract.costs || contract.cost_set,
    specifiedStartDate,
    specifiedEndDate
  );

  costs.sort((a, b) => {
    const bRate =
      b.end_date && b.start_date ? 3 : b.end_date ? 2 : b.start_date ? 1 : 0;
    const aRate =
      a.end_date && a.start_date ? 3 : a.end_date ? 2 : a.start_date ? 1 : 0;

    if (bRate === aRate) {
      return (a.product_category ?? -1) - (b.product_category ?? -1);
    }
    return aRate - bRate;
  });

  let costInfo = {
    title: "Kostnader",
    rows: [],
  };

  const indexBetweenMonths = getIndexBetweenMonths(
    row.original.indexations,
    specifiedStartDate,
    specifiedEndDate
  );

  let totalCost =
    typeof indexBetweenMonths === "number" ? indexBetweenMonths : 0;

  costs.forEach((c) => {
    const productKey = PRODUCT_CATEGORY_MAPPING[c.product_category] ?? "Övriga";

    const start = moment(c.start_date);
    const end = moment(c.end_date);

    let dtString = "";
    if (start._isValid && end._isValid) {
      dtString = ` (${start.format("YYYY-MM-DD")} - ${end.format(
        "YYYY-MM-DD"
      )})`;
    } else if (start._isValid) {
      dtString = ` (f.o.m ${start.format("YYYY-MM-DD")})`;
    } else if (end._isValid) {
      dtString = ` (t.o.m ${end.format("YYYY-MM-DD")})`;
    }

    const months = getMonthsForCost(
      c,
      specifiedStartDate,
      specifiedEndDate,
      true
    );
    const totVal = c.value * c.unit_amount * months;

    costInfo.rows.push([
      `${c.title} (${productKey})`,
      `${toMoneyString(totVal, true)}${dtString}`,
    ]);

    totalCost += totVal;
  });

  if (typeof indexBetweenMonths !== "string") {
    costInfo.rows.push([
      "Indexuppräkning",
      toMoneyString(indexBetweenMonths, true),
    ]);
  }

  if (costInfo.rows.length !== 0) {
    costInfo.rows.push([
      <b>Totalt</b>,
      <b>
        {toMoneyString(totalCost, true)} (
        {specifiedStartDate.format("YYYY-MM-DD")} -{" "}
        {specifiedEndDate.format("YYYY-MM-DD")})
      </b>,
    ]);

    contents.push(costInfo);
  }

  let premisInfo = {
    title: "Objekt",
    rows: [],
  };

  const apartments = row.original.apartments ?? [];
  apartments.forEach((a) => {
    premisInfo.rows.push([
      "Lägenhet",
      {
        onClick: () =>
          apartmentDetailUrl({
            id: a.id,
          }),
        text: a.str_representation,
      },
    ]);
  });

  const indps = row.original.industrial_premises_list ?? [];
  indps.forEach((i) => {
    premisInfo.rows.push([
      "Lokal",
      {
        onClick: () =>
          industrialDetailUrl({
            id: i.id,
          }),
        text: i.str_representation,
      },
    ]);
  });

  const parkings = row.original.parking_spots ?? [];
  parkings.forEach((p) => {
    premisInfo.rows.push([
      "Parkering",
      {
        onClick: () =>
          parkingDetailUrl({
            id: p.id,
          }),
        text: p.str_representation,
      },
    ]);
  });

  if (premisInfo.rows.length !== 0) {
    if (costInfo.rows.length !== 0) contents.push({}); //divider
    contents.push(premisInfo);
  }

  if (contents.length === 0) {
    contents.push({
      title: "Ingen data",
      rows: [],
    });
  }

  return contents;
};
