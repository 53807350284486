import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  constants,
  purgeAll,
  getAvailableReports,
  updateMiscForReport,
  updateReportInAll,
} from "../../store/newExcelReports";
import { TextButton } from "../../components/Forms/Base/Buttons";

import {
  PencilIcon,
  StarIcon as StarIconOutline,
} from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";

import { CATEGORIES, EXPORT_TYPES_ICON } from "./utils";

import { STRUCTURES, templates } from "./templates";

import Modal from "../../components/Forms/Base/Modals/Modal";

import {
  useFilterInputs,
  Aggregation,
  Columns,
  useShare,
} from "../../components/ExcelReports";

import { usePostData, useDataHandler } from "../../store/newExcelReports";
import ModalSpinner from "../../components/Loaders/ModalSpinner";

import LocalCheckField from "../../components/Forms/Base/Fields/LocalCheckField";
import PrimaryBtn from "../../components/Forms/Base/Buttons/PrimaryBtn";
import Badge, { BADGE_TYPES } from "../../components/Badge/Badge";
import { cloneDeep, isEqual } from "lodash";

import { store } from "../../store/store";

import { useParams } from "react-router-dom";

import * as SC from "../../components/Loaders/styles";

import ConfirmModal from "../../components/Forms/Base/Modals/ConfirmModal";
import { TOAST_TYPES, addToast } from "../../store/toasts";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";

import InfoModal from "../../components/ExcelReports/InfoModal";

import { INFO_TEXTS_FOR_FILTERS } from "./utils";

const ExportTypeComponent = ({ text, icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center p-2 cursor-pointer transition-colors hover:bg-blue-100"
    >
      {icon}
      <p className="ml-2 text-sm">{text}</p>
    </div>
  );
};

//px
const TITLE_MAX_WIDTH = 865;
const TITLE_MIN_WIDTH = 240;

export default function TableView() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [isSavingAsTemplate, setIsSavingAsTemplate] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState({
    title: "Laddar...",
    text: "Laddar...",
  });

  const [isFullPageLoading, setIsFullPageLoading] = React.useState(true);

  const [isAggregating, setIsAggregating] = React.useState(false);

  const user = useSelector((state) => state.app.user);

  const [usingHotRefresh, setUsingHotRefresh] = React.useState(false);

  const currentReportData = React.useMemo(() => {
    if (isNaN(params.id)) {
      const found = templates.pigello.find(
        (obj) => obj.baseTemplateName === params.id
      );

      if (!found) return null;

      found.base_template = true;
      found.is_template = true;

      return found;
    }

    if (!store.getState()[constants.STORE_NAME].all[params.id]) {
      setUsingHotRefresh(true);
      return store.getState()[constants.STORE_NAME].hotRefresh;
    }

    return store.getState()[constants.STORE_NAME].all[params.id];
  }, [params.id]);

  const currentReportStructure = React.useMemo(() => {
    if (!currentReportData) return null;
    return STRUCTURES[currentReportData.report];
  }, []);

  const allAvailableReports = useSelector(
    (state) => state[constants.STORE_NAME].allAvailableReports
  );

  const selectedReportsOverviewTab = useSelector(
    (state) => state[constants.STORE_NAME].selectedReportTab
  );

  const hasActiveTemplate = React.useMemo(
    () =>
      !(
        (!currentReportData || Object.keys(currentReportData).length === 0) &&
        (!currentReportStructure ||
          Object.keys(currentReportStructure).length === 0)
      ),

    [currentReportData, currentReportStructure]
  );

  const goToOverview = (url) => {
    if (url) {
      history.push(url);
    } else {
      if (usingHotRefresh) {
        dispatch(purgeAll());
      }

      if (selectedReportsOverviewTab) {
        return history.push(`/reports/overview/${selectedReportsOverviewTab}`);
      }
      return history.push("/reports/overview");
    }
  };

  React.useEffect(() => {
    //Do this on rebuild of whole project
    //Stop the user to accidentally lose all changes
    //"This page is asking you to confirm that you want to leave — information you’ve entered may not be saved."

    if (Object.keys(allAvailableReports).length === 0) {
      dispatch(getAvailableReports());
      return;
    }

    //add a toast?
    if (!hasActiveTemplate || !currentReportStructure) {
      goToOverview();
    }
  }, [
    hasActiveTemplate,
    allAvailableReports,
    currentReportData,
    currentReportStructure,
  ]);

  if (!hasActiveTemplate || currentReportStructure === null) {
    return (
      <p>
        Ett fel har uppstått. Du kommer bli omdirigerad till föregående sida...
      </p>
    );
  }

  const isUserOwnerOfReport = React.useMemo(() => {
    return user?.id === currentReportData?.performed_by?.id;
  }, [user, currentReportData]);

  const isPerformedReport = React.useMemo(
    () =>
      currentReportData.id !== undefined &&
      currentReportData.is_template === false,
    [currentReportData]
  );

  const isCustomTemplate = React.useMemo(
    () =>
      currentReportData.id !== undefined &&
      currentReportData.is_template === true,
    [currentReportData]
  );

  const isBaseTemplate = React.useMemo(() => {
    return !isPerformedReport && !isCustomTemplate;
  }, [isPerformedReport, isCustomTemplate]);

  const DEFAULTS = React.useMemo(() => {
    if (isBaseTemplate) {
      return currentReportData?.defaults;
    }
    return undefined;
  }, [isBaseTemplate, currentReportData]);

  const [filterInputStates, setFilterInputStates] = React.useState([]);

  const [askBeforeRemovingAllColumns, setAskBeforeRemovingAllColumns] =
    React.useState(false);

  const [selectedReport, setSelectedReport] = React.useState(
    allAvailableReports[currentReportStructure.createPath]
  );

  React.useEffect(() => {
    if (
      Object.keys(allAvailableReports).length !== 0 &&
      !allAvailableReports.hasOwnProperty(currentReportStructure.createPath)
    ) {
      //User doesnt have permission to view this report,
      //or invalid params

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Saknar behörighet",
          description:
            "Din användare saknar behörighet att ta ut denna rapport",
        })
      );

      goToOverview();
    }

    setSelectedReport(allAvailableReports[currentReportStructure.createPath]);
  }, [currentReportStructure, allAvailableReports]);

  const [currentTitle, setCurrentTitle] = React.useState(
    currentReportData.title === currentReportData?.str_representation
      ? ""
      : currentReportData.title
  );

  const infoText = React.useMemo(() => {
    if (!currentReportStructure) return "";
    if (currentReportStructure.infoText) return currentReportStructure.infoText;
    return "";
  }, [currentReportStructure]);

  //To make templating simpler.
  //only have to write ["billing_company"] instead of [{ value: "billing_company", name: "Bolagsnamn" }]
  const getColumnDataForColumns = (columns) => {
    if (!selectedReport) return [];
    let data = [];

    //If column_order.length === 0 in base template, load all columns
    if (columns === "*") {
      selectedReport.options.editable_columns.forEach((col) => {
        col.value = col.identifier;
        data.push(col);
      });

      return data;
    }

    for (let col of columns) {
      const found = selectedReport.options.editable_columns.find(
        (obj) => obj.identifier === col
      );

      if (!found) continue;
      //LocalSelectManyDropdown wants the '.value' prop
      found.value = found.identifier;
      data.push(found);
    }

    return data;
  };

  const getColumnDataForUnusedColumns = (usedColumns) => {
    if (!selectedReport) return [];
    let arr = [];

    for (let obj of selectedReport.options.editable_columns) {
      obj.value = obj.identifier;

      const found = usedColumns.find(
        (_obj) => _obj.identifier === obj.identifier
      );

      if (!found) arr.push(obj);
    }

    return arr;
  };

  const [orderOfColumns, setOrderOfColumns] = React.useState(
    isBaseTemplate && currentReportData.column_order.length === 0
      ? getColumnDataForColumns("*")
      : getColumnDataForColumns(currentReportData.column_order)
  );

  const [unusedColumns, setUnusedColumns] = React.useState(
    getColumnDataForUnusedColumns(orderOfColumns)
  );

  React.useEffect(() => {
    let newCols =
      isBaseTemplate && currentReportData.column_order.length === 0
        ? getColumnDataForColumns("*")
        : getColumnDataForColumns(currentReportData.column_order);
    setOrderOfColumns(newCols);
    setUnusedColumns(getColumnDataForUnusedColumns(newCols));
  }, [selectedReport]);

  const removeAllActiveColumns = () => {
    setOrderOfColumns([]);
    setUnusedColumns(getColumnDataForUnusedColumns([]));
  };

  const getDefaultAggregations = () => {
    if (!DEFAULTS) return [];
    if (!DEFAULTS.hasOwnProperty("aggregations")) return [];
    return DEFAULTS.aggregations;
  };

  const [aggregations, setAggregations] = React.useState(
    isBaseTemplate
      ? getDefaultAggregations()
      : currentReportData.summarize_by
      ? currentReportData.summarize_by
      : []
  );

  const [isStarredPatching, setIsStarredPatching] = React.useState(false);

  const [isStarred, setIsStarred] = React.useState(
    currentReportData.is_starred || false
  );

  const toggleIsStarred = () => {
    if (isStarredPatching) return;
    setIsStarredPatching(true);

    dispatch(
      updateMiscForReport({
        reportId: currentReportData.id,
        postData: {
          is_starred: !isStarred,
        },
        onSuccess: (response) => {
          setIsStarred(!isStarred);
          setIsStarredPatching(false);

          dispatch(updateReportInAll(response.data));
        },
        onError: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Ett fel har uppstått",
              description:
                "Kunde inte ändra favoriseringen. Ladda om sidan och försök igen",
            })
          );
          setIsStarredPatching(false);
        },
      })
    );
  };

  //tells if all the states have been set at least once or not,
  //used for calculating if any changes have been made.
  const [hasCalculatedAllData, setHasCalculatedAllData] = React.useState(false);
  const [initialPostData, setInitialPostData] = React.useState(null);

  const [category] = React.useState(
    !currentReportStructure.category || currentReportStructure.category === ""
      ? CATEGORIES["missing"]
      : CATEGORIES?.[currentReportStructure.category]
  );

  const onAllDataCalculatedFirstTime = () => {
    setInitialPostData(buildPostData());

    setHasCalculatedAllData(true);
  };

  const [isSharing, setIsSharing] = React.useState(false);
  const [sharingState, setSharingState] = React.useState([]);
  const [currentSharingReport, setCurrentSharingReport] =
    React.useState(undefined);

  const { ShareElement } = useShare({
    setIsSharing,
    currentSharingReport,
    setCurrentSharingReport,
    shouldDispatchUpdate: false,
    setSharingState,
  });

  const openShareSettings = () => {
    //shared_with will never load data from backend here.
    //doesn't have to.
    setCurrentSharingReport({
      shared_with: sharingState,
    });

    setIsSharing(true);
  };

  const {
    filterInputs,
    filterInputsLast,
    createParametersMap,
    choicesMapping,
    groupableElement,
    groupableColumns,
    groupingState,
  } = useFilterInputs({
    filterInputStates,
    setFilterInputStates,
    currentReportData,
    currentReportStructure,
    selectedReport: !selectedReport ? undefined : selectedReport,
    hasCalculatedAllData,
    onAllDataCalculatedFirstTime,
    orderOfColumns,
    DEFAULTS,
    setIsFullPageLoading,
    aggregations,
  });

  const { buildPostData } = usePostData({
    createParametersMap,
    filterInputStates,
    selectedReport: !selectedReport ? undefined : selectedReport,
    orderOfColumns,
    unusedColumns,
    currentTitle,
    aggregations,
    shared_with: sharingState,
    is_starred: isStarred,
    groupingState,
  });

  React.useEffect(() => {
    setInitialPostData(buildPostData());
  }, []);

  const [hasUpdatedAnyData, setHasUpdatedAnyData] = React.useState(false);

  const { doReport, patchChangesToTemplate } = useDataHandler({
    setIsLoading,
    isLoading,
    setLoadingText,
    loadingText,
    setIsSavingAsTemplate,
    isPerformedReport,
    currentTitle,
    currentReportData,
    currentReportStructure,
    setInitialPostData,
    setHasUpdatedAnyData,
    buildPostData,
  });

  React.useEffect(() => {
    if (!hasCalculatedAllData) return;
    if (!isCustomTemplate) return;
    if (hasUpdatedAnyData) return;

    let initial = cloneDeep(initialPostData);
    let now = buildPostData();

    delete now.is_starred;
    delete initial.is_starred;

    setHasUpdatedAnyData(!isEqual(initial, now));
  }, [
    filterInputStates,
    choicesMapping,
    orderOfColumns,
    unusedColumns,
    currentTitle,
    isStarred,
    groupingState,
    aggregations,
  ]);

  const titleRef = React.useRef();

  const invisibleTitleRef = React.useRef();

  const isTitleTextTooLong = React.useMemo(() => {
    if (currentTitle.length > 256) return true;
    return false;
  }, [currentTitle]);

  React.useEffect(() => {
    if (!titleRef.current || !invisibleTitleRef.current) return;

    invisibleTitleRef.current.innerHTML = currentTitle;

    let width = Math.round(
      invisibleTitleRef.current.getBoundingClientRect().width
    );

    if (width >= TITLE_MAX_WIDTH) width = TITLE_MAX_WIDTH;
    if (width <= TITLE_MIN_WIDTH) width = TITLE_MIN_WIDTH;

    titleRef.current.style.width = `${width}px`;
  }, [currentTitle]);

  return (
    <div className="h-[calc(100vh-73px)] w-full flex flex-col">
      <div className="w-full flex text-xs mt-2 text-center opacity-80 justify-center">
        Klicka på "Exportera som" och välj format till höger för att hämta
        rapporten
      </div>
      {/* Header */}
      <div className="p-5 pt-0 border-b border-gray-300 relative">
        <div className="flex">
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => goToOverview()}
          />
        </div>
        <div className="flex mt-2 items-center relative justify-between">
          <div className="flex items-center">
            <p
              ref={invisibleTitleRef}
              className="w-auto pointer-events-none mr-4 font-semibold outline-none p-2 opacity-0 fixed rounded border border-transparent"
            ></p>
            <div className="flex mr-4 items-center">
              <PencilIcon width={16} />
              <div className="flex flex-col items-center relative">
                <input
                  ref={titleRef}
                  className={`max-w-[${TITLE_MAX_WIDTH}px] ml-2 bg-gray-100 w-auto font-semibold outline-none px-2 py-1 rounded border border-transparent transition-colors hover:border-blue-200 focus:border-blue-400 ${
                    isTitleTextTooLong && "!border-red-500"
                  }`}
                  value={currentTitle}
                  onChange={({ target }) => setCurrentTitle(target.value)}
                  placeholder="Namnge rapporten"
                />
                {isTitleTextTooLong && (
                  <p className="text-sm top-9 text-red-500 absolute">
                    Titeln får max vara 256 tecken lång
                  </p>
                )}
              </div>
            </div>
            {category.badge()}
            {(isCustomTemplate || isBaseTemplate) && (
              <Badge className="ml-3" type={BADGE_TYPES.YELLOW}>
                {isCustomTemplate ? "Mall" : "Pigello"}
              </Badge>
            )}
            {infoText.length !== 0 && (
              <InfoModal
                title="Vad visar denna rapport?"
                popupClassName="!min-w-[400px]"
                text={infoText}
              />
            )}
          </div>
          <div className="flex items-center">
            <div className="flex items-center mr-2 space-x-1">
              <span className="text-sm font-medium">Exportera som: </span>

              <PrimaryBtn secondary onClick={() => doReport("json")}>
                {EXPORT_TYPES_ICON["json"]}
                <div className="ml-1 text-slate-600">JSON</div>
              </PrimaryBtn>
              <PrimaryBtn secondary onClick={() => doReport("pdf")}>
                {EXPORT_TYPES_ICON["pdf"]}
                <div className="ml-1 text-slate-600">PDF</div>
              </PrimaryBtn>
              <PrimaryBtn secondary onClick={() => doReport("xlsx")}>
                {EXPORT_TYPES_ICON["xlsx"]}
                <div className="ml-1 text-slate-600">Excel</div>
              </PrimaryBtn>
            </div>
            {/* get component  for button */}
            {hasUpdatedAnyData && isCustomTemplate && (
              <PrimaryBtn
                onClick={patchChangesToTemplate}
                className="hover:bg-indigo-600 flex items-center bg-indigo-500 text-white p-2 rounded transition-colors"
              >
                Uppdatera mall
              </PrimaryBtn>
            )}
            {!isCustomTemplate && (
              <PrimaryBtn
                onClick={() => setIsSavingAsTemplate(true)}
                className="hover:bg-indigo-600 flex items-center bg-indigo-500 text-white p-2 rounded transition-colors"
              >
                Spara rapport som mall
              </PrimaryBtn>
            )}

            {(isCustomTemplate || (!isCustomTemplate && !isPerformedReport)) &&
              isUserOwnerOfReport && (
                <>
                  {isStarredPatching ? (
                    <SC.SpinningCircleContainer
                      style={{ width: "24px", height: "24px" }}
                      viewBox="0 0 50 50"
                    >
                      <SC.SpinningCircle
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        stroke="#93bfec"
                        strokeWidth="3"
                      />
                    </SC.SpinningCircleContainer>
                  ) : (
                    <>
                      {isStarred ? (
                        <StarIconSolid
                          className="ml-3 cursor-pointer"
                          onClick={toggleIsStarred}
                          height={24}
                        />
                      ) : (
                        <StarIconOutline
                          className="ml-3 cursor-pointer"
                          onClick={toggleIsStarred}
                          height={24}
                        />
                      )}
                    </>
                  )}
                </>
              )}
          </div>
        </div>
      </div>
      {/* filters */}
      <div className="w-full px-4 py-2 border-b border-solid border-gray-300 pb-4">
        <div className="flex flex-wrap">
          {filterInputs}
          {groupableElement !== undefined && groupableElement}
          <div key={"aggregations"} className="min-w-[14rem] mt-2 mr-2">
            <div className="flex">
              <p className="font-medium text-gray-900 text-sm flex items-center">
                Aggregeringar
              </p>
              <InfoModal
                popupClassName="!min-w-[400px]"
                text={INFO_TEXTS_FOR_FILTERS.aggregations}
              />
            </div>
            <button
              onClick={() => setIsAggregating(true)}
              className="inline-flex w-full bg-white items-center border border-solid border-slate-300 text-gray-900 rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center"
            >
              <p>Hantera</p>
            </button>
          </div>
          {filterInputsLast}
        </div>
      </div>

      {/* table headers */}
      <div className="grow">
        <Columns
          orderOfColumns={orderOfColumns}
          setOrderOfColumns={setOrderOfColumns}
          selectedReport={selectedReport}
          unusedColumns={unusedColumns}
          setUnusedColumns={setUnusedColumns}
          setAskBeforeRemovingAllColumns={setAskBeforeRemovingAllColumns}
        />
      </div>

      {isAggregating && (
        <Aggregation
          setIsAggregating={setIsAggregating}
          aggregations={aggregations}
          setAggregations={setAggregations}
          groupableColumns={groupableColumns}
        />
      )}

      {isSavingAsTemplate && (
        <Modal
          closeFunction={() => setIsSavingAsTemplate(false)}
          title="Spara rapport som ny rapportmall"
          acceptTitle="Spara"
          denyTitle="Avbryt"
          onAccept={() => doReport("xlsx", true)}
        >
          <p className="mb-0">Namn på rapportmall</p>
          <input
            className="border border-gray-300 rounded p-2 !mt-2"
            value={currentTitle}
            onChange={({ target }) => setCurrentTitle(target.value)}
            placeholder="Titel på rapportmall"
          />

          <PrimaryBtn
            onClick={() => openShareSettings()}
            className="hover:bg-indigo-600 !flex items-center bg-indigo-500 text-white p-2 rounded transition-colors"
          >
            <p className="">Dela</p>
          </PrimaryBtn>

          <LocalCheckField
            value={isStarred}
            onChange={(val) => setIsStarred(val)}
            title="Markera som favorit"
            className=""
          />
        </Modal>
      )}

      {isLoading && (
        <ModalSpinner text={loadingText.text} title={loadingText.title} />
      )}

      {isFullPageLoading && <OverlaySpinner extraStyle={{ zIndex: 20 }} />}

      {isSharing && ShareElement}

      {askBeforeRemovingAllColumns && (
        <ConfirmModal
          closeFunction={() => setAskBeforeRemovingAllColumns(false)}
          onAccept={removeAllActiveColumns}
          acceptTitle="Ja"
          denyTitle="Avbryt"
        >
          <p>Vill du ta bort alla kolumner?</p>
        </ConfirmModal>
      )}
    </div>
  );
}
