import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";

import * as SC from "../Base/Fields/styles";
import { useApartment } from "../../../store/apartments";
import { useIndustrialPremises } from "../../../store/industrialPremises";
import { useFilteredTenants, useTenant } from "../../../store/tenants";
import NonConnectedRadioGroup from "../Base/Old/NonConnected/NonConnectedRadioGroup";
import { useErrandComponent } from "../../../store/errandComponents";

import { useUser } from "../../../store/users";
import { InnerBox, Pill, PillsWrapper } from "../../sharedStyles";
import { useBrfPremises } from "../../../store/brfPremises";
import { useParkingSpot } from "../../../store/parkingSpots";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import TableSelectField from "../Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";

const TYPES = {
  TENANT: "TENANT",
  ADMIN: "ADMIN",
};

export default ({ method, storeName }) => {
  const dispatch = useDispatch();

  const [type, setType] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);

  const reportedByKey = "reported_by";
  const currentValue = useFormInstanceField({
    storeName,
    fieldKey: reportedByKey,
  });

  const [currentUser, currentUserLoading] = useUser(currentValue?.id);

  React.useEffect(() => {
    if (initialized) return;
    if (!currentValue) {
      setInitialized(true);
    }

    if (currentValue && currentUser) {
      setInitialized(true);

      setType(currentUser?.user_type === 3 ? TYPES.TENANT : TYPES.ADMIN);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            reported_by: { id: currentUser.id },
          },
        })
      );
    }
  }, [currentUser]);

  const currentComponent = useFormInstanceField({
    storeName,
    fieldKey: "component_placement",
  });

  const selectedApartment = useFormInstanceField({
    storeName,
    fieldKey: "apartment",
  });
  const selectedIndp = useFormInstanceField({
    storeName,
    fieldKey: "industrial_premises",
  });
  const selectedParkingSpot = useFormInstanceField({
    storeName,
    fieldKey: "parking_spot",
  });
  const selectedBrfPremis = useFormInstanceField({
    storeName,
    fieldKey: "brf_premis",
  });

  // for report errands
  const [selectedComponent, setSelectedComponent] = useErrandComponent(
    currentComponent?.id
  );

  const room = selectedComponent?.room;

  const [apartment, apartmentLoading] = useApartment(
    room?.apartment?.id || selectedApartment?.id
  );
  const [indp, indpLoading] = useIndustrialPremises(
    room?.industrial_premises?.id || selectedIndp?.id
  );
  const [brfPremis, brfPremisLoading] = useBrfPremises(
    room?.brf_premis?.id || selectedBrfPremis?.id
  );
  const [parkingSpot, parkingLoading] = useParkingSpot(
    selectedComponent?.parking_spot?.id || selectedParkingSpot?.id
  );

  const setUser = (user) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [reportedByKey]: user,
        },
      })
    );
  };

  const [tenant, tenantLoading] = useTenant(
    (apartment || indp || parkingSpot || brfPremis)?.tenant?.id
  );

  const otQ = buildQueryString({
    id__in:
      (apartment || indp || parkingSpot)?.bi_tenants?.map((bt) => bt.id) || [],
  });
  const [otherTenants] = useFilteredTenants(otQ);

  const authedUser = useSelector((state) => state.app?.user);

  return (
    <>
      <InnerBox>
        {!currentValue && (
          <NonConnectedRadioGroup
            id="reportedByType"
            value={type}
            title="Ange om det är en administratör eller hyresgäst som anmält ärendet"
            options={[
              {
                label: "Ärendet anmäldes av en hyresgäst",
                value: TYPES.TENANT,
              },
              {
                label: "Ärendet anmäldes av en administratör",
                value: TYPES.ADMIN,
              },
            ]}
            onChange={(data) => setType(data)}
          />
        )}

        {type === TYPES.TENANT &&
          (tenant || otherTenants?.length > 0) &&
          !currentValue && (
            <>
              <OverviewTitleWrapper>
                <OverviewTitle small>Föreslagna hyresgäster</OverviewTitle>
              </OverviewTitleWrapper>
              <PillsWrapper style={{ marginBottom: 24 }}>
                {tenant && (
                  <Pill onClick={() => setUser(tenant.user)}>
                    {tenant?.str_representation}
                  </Pill>
                )}
                {otherTenants?.map((ot) => (
                  <Pill onClick={() => setUser(ot.user)} key={ot.id}>
                    {ot?.str_representation}
                  </Pill>
                ))}
              </PillsWrapper>
            </>
          )}

        {type === TYPES.ADMIN && authedUser && !currentValue && (
          <>
            <OverviewTitleWrapper style={{ marginBottom: 12 }}>
              <SC.InputFieldTitle small>Föreslagen admin</SC.InputFieldTitle>
            </OverviewTitleWrapper>
            <PillsWrapper style={{ marginBottom: 24 }}>
              <Pill onClick={() => setUser(authedUser)}>
                {authedUser?.str_representation}
              </Pill>
            </PillsWrapper>
          </>
        )}

        {type != null && (
          <>
            {!currentValue && (
              <OverviewTitleWrapper style={{ marginBottom: 12 }}>
                <SC.InputFieldTitle small>
                  Välj en specifik anmälare
                </SC.InputFieldTitle>
              </OverviewTitleWrapper>
            )}

            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                fieldKey={reportedByKey}
                persistantQuery={{
                  [`user_type${type === TYPES.TENANT ? "" : "!"}`]: 3, //Om hyresgäst: user_type = 3, om hyresvärd: user_type blir inte 3
                }}
                method={method}
                title="Anmälare"
                placeholder={"Välj anmälare..."}
                TableComponent={UsersTable}
              />
            </div>
          </>
        )}
      </InnerBox>
    </>
  );
};
