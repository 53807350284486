import React from "react";

import * as SC from "../../Layout/Insights/Widgets/styles";

import { useConfigCenter } from "../../../store/configcenter";

export default function QuickDetail({ height, width, valueDict }) {
  const icon = valueDict["icon"];
  const [configCenter, configCenterLoading] = useConfigCenter();
  let color = []
  if (!configCenter) {
    color = ["4151c9", "495be2", "5165fb", "6274fb", "7484fc"][valueDict["color"]]
  } else {
  color = configCenter?.insights_color_scheme[valueDict["color"]];

  }
  return (
    <SC.InternalComponentContainer width={width} height={height}>
      <SC.InternalComponentFlexContainer
        flexDirection="row"
        fullHeight="100%"
        alignItems="center"
      >
        <SC.InternalComponentIconBubble bgnColor={color} icon={icon} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "70%",
            marginLeft: "10px",
          }}
        >
          <SC.WidgetDataText>{valueDict.current_value}</SC.WidgetDataText>
          <SC.WidgetTextSupplement>
            {valueDict.quick_detail_text}
          </SC.WidgetTextSupplement>
        </div>
      </SC.InternalComponentFlexContainer>
    </SC.InternalComponentContainer>
  );
}
