export const WidgetPlacementDefaults = [
    {
        "id": "default_barchart_realestate_count",
        "str_representation": "Widgetplacering",
        "widget": "barchart",
        "kpi": "realestate_count",
        "kpi_display": "Antal fastigheter",
        "grid_row": 1,
        "grid_column": 1,
        "grid_row_end": 3,
        "grid_column_end": 4,
        "start_date": null,
        "end_date": null,
        "interval_type": null,
        "interval_type_display": null,
        "month_granularity": null,
        "day_granularity": null,
        "double_result": false,
        "aggregates": [
            "company"
        ],
        "body_parameters": {
            "tenant_age_intervals": null,
            "building_age_intervals": null
        },
    },
    {
        "id": "default_barchart_occupancy",
        "str_representation": "Widgetplacering",
        "widget": "barchart",
        "kpi": "occupancy",
        "kpi_display": "Uthyrningsgrad",
        "grid_row": 1,
        "grid_column": 4,
        "grid_row_end": 3,
        "grid_column_end": 7,
        "start_date": null,
        "end_date": null,
        "interval_type": "l1y",
        "interval_type_display": "Tidigare året",
        "month_granularity": 1,
        "day_granularity": null,
        "double_result": false,
        "aggregates": null,
        "body_parameters": {
            "instance_filter": ["industrialpremises"]
        },
    },
    {
        "id": "default_donutchart_apartment_count",
        "str_representation": "Widgetplacering",
        "widget": "donutchart",
        "kpi": "apartment_count",
        "kpi_display": "Antal bostäder",
        "grid_row": 3,
        "grid_column": 1,
        "grid_row_end": 5,
        "grid_column_end": 3,
        "start_date": null,
        "end_date": null,
        "interval_type": null,
        "interval_type_display": null,
        "month_granularity": null,
        "day_granularity": null,
        "double_result": false,
        "aggregates": [
            "lease_object"
        ],
        "body_parameters": {
            "tenant_age_intervals": null,
            "building_age_intervals": null
        },
    },
    {
        "id": "default_donut_apartment_occupancy",
        "str_representation": "Widgetplacering",
        "widget": "donutchart",
        "kpi": "apartment_occupancy",
        "kpi_display": "Uthyrningsgrad BOA (%)",
        "grid_row": 3,
        "grid_column": 3,
        "grid_row_end": 5,
        "grid_column_end": 5,
        "start_date": null,
        "end_date": null,
        "interval_type": "l1d",
        "interval_type_display": "Igår",
        "month_granularity": null,
        "day_granularity": null,
        "double_result": false,
        "aggregates": null,
        "body_parameters": null,
    },
    {
        "id": "default_donut_indp_occupancy",
        "str_representation": "Widgetplacering",
        "widget": "donutchart",
        "kpi": "industrialpremises_occupancy",
        "kpi_display": "Uthyrningsgrad LOA (%)",
        "grid_row": 3,
        "grid_column": 5,
        "grid_row_end": 5,
        "grid_column_end": 7,
        "start_date": null,
        "end_date": null,
        "interval_type": "l1d",
        "interval_type_display": "Igår",
        "month_granularity": null,
        "day_granularity": null,
        "double_result": false,
        "aggregates": null,
        "body_parameters": null,
    }
]