import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import ComponentsTable from "../../../components/Tables/ErrandComponentTypes/FullTable";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ComponentTypeForm from "../../../components/Forms/ComponentType/ChapterForm/ModalForm";

export default function ComponentTypes() {
  const [addOpen, setAddOpen] = React.useState(false);
  return (
    <>
      <ComponentTypeForm
        method="POST"
        onCheckout={() => setAddOpen(false)}
        isOpen={addOpen}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Komponenttyper</OverviewTitle>
              <OverviewSubtitle>
                Komponenttyper är den mest grundläggande kategorin på
                komponenterna i systemet. Dessa kategoriserar upp alla
                komponenter i kategorier såsom "Kylskåp", "Parkettgolv", osv.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              extraStyle={{ marginLeft: 12 }}
              title="Lägg till komponenttyp"
              clicked={() => setAddOpen(true)}
            />
          </OverviewTitleWrapper>

          <ComponentsTable />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
