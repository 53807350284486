import * as React from "react";

import { constants } from "../../../../../../../store/tags";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../../../Details/OverviewInfo/styles";
import { ColorPicker, TextInput } from "../../../../../Base/Fields";



export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitle>Taggar</OverviewTitle>
      </OverviewTitleWrapper>

      <hr />

      <ColorPicker
        title="Färg"
        {...{ storeName, method, fieldKey: "color" }}
      />

      <TextInput
        title="Namn på taggen"
        {...{ storeName, method, fieldKey: "title" }}
      />
    </>
  );
};
