import styled, { css } from "styled-components";

export const OverviewTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const OverviewTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  font-size: ${(p) => p.theme.fontSizes.headerMedium};
  color: ${(p) => p.theme.colors.primaryText};
  display: flex;
  align-items: center;
  line-height: 100%;

  ${(p) =>
    p.small &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerSmall};
      font-size: ${(p) => p.theme.fontSizes.headerSmall};
    `}

  ${(p) =>
    p.medium &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerMedium};
      font-size: ${(p) => p.theme.fontSizes.headerMedium};
    `}
  @media(${(p) => p.theme.mediaQueries.desktopSmall}) {
    ${(p) =>
      !p.noColumn &&
      css`
        flex-direction: column;
        align-items: flex-start;
      `}
  }
`;

export const OverviewTitleWithSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OverviewSubtitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.secondaryText};
  margin-top: 4px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-left: 12px;
  }
`;

export const OverviewContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: "Inter", sans-serif;
`;

export const OverviewItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 23%;
  min-width: 200px;
  margin-right: 2%;
  margin-bottom: 24px;
`;

export const OverviewItemTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.secondaryText};
`;

export const OverviewItemValue = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.data};
  font-size: ${(p) => p.theme.fontSizes.data};
  color: ${(p) => p.theme.colors.primaryText};
`;
