import styled, { css } from "styled-components";

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  border-radius: 5px;

  ${(p) =>
    p.leftToggled &&
    css`
      flex-direction: row-reverse;
    `}

  &:hover {
    background-color: ${(p) => p.theme.colors.blueLight};
  }
`;

export const EmptyToggleWrapper = styled.div`
cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
box-sizing: border-box;
display: flex;
width: 43px;
height: 24px;
border-radius: 30px;
flex: 1 1 0%;

position: relative;
`;

export const Title = styled.div`
  color: ${(p) => p.theme.colors.primaryText};
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  display: flex;
  align-items: center;

  ${(p) =>
    p.addSpace &&
    css`
      margin-right: 8px;
    `}
`;

export const ToggleBox = styled.div`
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  box-sizing: border-box;
  width: 43px;
  height: 24px;
  border-radius: 30px;
  background-color: ${(p) => p.theme.colors.gray1};
  border: ${(p) => p.theme.borders.dark};

  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const ToggleDot = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: ${(p) => p.theme.borders.dark};

  top: 1px;
  left: ${(p) => (p.active ? "20px" : "1px")};
  background-color: ${(p) =>
    p.active ? p.theme.colors.blue : p.theme.colors.gray1};
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);

  transition: all 0.2s ease-in-out;

  ${(p) =>
    p.isRedGreen &&
    css`
      background-color: ${(p) =>
        p.active ? p.theme.colors.green : p.theme.colors.red};
    `}
`;
