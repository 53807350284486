import Completion from "./Completion";
import Confirm from "./Confirm";
import Inspection from "./Inspection";
import Costs from "./Costs";

export default {
  INSPECTION: Inspection,
  COMPLETION: Completion,
  COSTS: Costs,
  CONFIRM: Confirm,
};
