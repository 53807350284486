import * as React from "react";
import Select from "react-select";

// style, design
import * as SC from "../../Fields/styles";
import DescriptionToolTip from "../../Layout/DescriptionToolTip";

export default React.memo(
  ({
    isMulti,
    noMargin,
    noTitle,
    noHelp,
    extraStyles,
    disabled,
    allowNull = true,
    onUpdate,
    newLabelCallback,
    choices,
    value,
    id,
    label,
    helpText,
    description,
    error,
    getOptionLabel,
    getOptionValue,
    placeholder,
    required,
    customSelectorMethod, // method for matching values with choices to display correctly, i.e when we only store the value in store

    // top, bottom - controls render direction of optionns
    menuPlacement,

    // allows for react select to anchor the select list to the body.
    // Excellent for overflow problems but MUST NOT be used in modals
    bodyPortaled,
  }) => {
    const onChange = (data) => {
      let formattedData = isMulti ? [] : allowNull ? null : undefined;
      if (data !== null) {
        if (isMulti) {
          formattedData = data.map((d) => {
            if (d.constructor === Object) {
              return getOptionValue(d);
            }
            return d;
          });
        } else {
          formattedData =
            data.constructor === Object ? getOptionValue(data) : data;
        }
      }

      onUpdate(formattedData, data);
      if (newLabelCallback) {
        newLabelCallback(getOptionLabel(data));
      }
    };

    const getSelected = () => {
      if (customSelectorMethod) {
        return customSelectorMethod({ value, choices });
      }

      return isMulti
        ? value
        : (choices || []).find((c) => getOptionValue(c) == value) || null;
    };

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {!noTitle && (
          <SC.InputFieldTitle style={{ display: "flex", alignItems: "center" }}>
            {label}
            {required && "*"}{" "}
            {description && !noHelp && (
              <DescriptionToolTip description={description} />
            )}
          </SC.InputFieldTitle>
        )}
        {helpText && !noHelp && (
          <SC.InputFieldDescription>{helpText}</SC.InputFieldDescription>
        )}

        <SC.SelectWrapper>
          <Select
            {...{ menuPlacement }}
            id={id}
            placeholder={placeholder || "Välj..."}
            onChange={onChange}
            defaultValue={isMulti ? [] : null}
            value={getSelected()}
            options={choices}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            isMulti={isMulti}
            isDisabled={disabled}
            isClearable={allowNull}
            menuPortalTarget={document.querySelector("body")}
            {...{
              menuPortalTarget: bodyPortaled
                ? document.querySelector("body")
                : null,
            }}
          />
        </SC.SelectWrapper>

        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
