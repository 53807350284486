import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import validationErrorMap from "../../../../Billecta/Invoices/InvoiceComponents/validationErrorMap";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { NumberInput, Select } from "../../../Base/Fields";
import CheckField from "../../../Base/Fields/CheckField";
import LocalCheckField from "../../../Base/Fields/LocalCheckField";
import SelectField from "../../../Base/Fields/SelectField";
import TextInputField from "../../../Base/Fields/TextInputField";

export default function DebtCollection({
  title,
  description,
  method,
  storeName,
  validationErrors,
}) {
  const dispatch = useDispatch();
  const useDebtCollection = !!useFormInstanceField({
    storeName,
    fieldKey: "DebtCollectionDetails",
  });

  const handleActivateDebtCollection = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          DebtCollectionDetails: {
            DaysDelayAfterDueDate: 20,
            NumberOfReminders: 1,
            PaymentTermsInDays: 10,
            SendToDebtCollection: true,
            StartDebtCollectionActionLevel: "Reminders",
          },
        },
      })
    );
  };
  const handleInactivateDebtCollection = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          DebtCollectionDetails: undefined,
        },
      })
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>
          {description && <OverviewSubtitle>{description}</OverviewSubtitle>}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {useDebtCollection ? (
        <>
          <div className="mb-6">
            <LocalCheckField
              title="Använd automatiserad kravhantering"
              value={true}
              onChange={handleInactivateDebtCollection}
            />
          </div>

          <div className="grid grid-cols-2 mb-6 gap-6">
            <SelectField
              title="Krav inleds med"
              fieldKey="DebtCollectionDetails.StartDebtCollectionActionLevel"
              {...{ method, storeName }}
              forceError={
                validationErrors[
                  "StartDebtCollectionActionLevelLatePaymentFeeNotCompany"
                ] &&
                validationErrorMap[
                  "StartDebtCollectionActionLevelLatePaymentFeeNotCompany"
                ]
              }
            />

            <TextInputField
              isNumber
              title="Krav inleds ... dagar efter förfallodatum"
              fieldKey="DebtCollectionDetails.DaysDelayAfterDueDate"
              {...{ method, storeName }}
              forceError={
                (validationErrors[
                  "DebtCollectionDetails.DaysDelayAfterDueDate"
                ] &&
                  validationErrorMap[
                    "DebtCollectionDetails.DaysDelayAfterDueDate"
                  ]) ||
                (validationErrors[
                  "DebtCollectionDetails.DaysDelayAfterDueDate_Reminder"
                ] &&
                  validationErrorMap[
                    "DebtCollectionDetails.DaysDelayAfterDueDate_Reminder"
                  ])
              }
            />
          </div>

          <div className="grid grid-cols-2 mb-6 gap-6">
            <TextInputField
              isNumber
              title="Betalas inom ... dagar"
              fieldKey="DebtCollectionDetails.PaymentTermsInDays"
              {...{ method, storeName }}
              forceError={
                validationErrors["DebtCollectionDetails.PaymentTermsInDays"] &&
                validationErrorMap["DebtCollectionDetails.PaymentTermsInDays"]
              }
            />
            <TextInputField
              isNumber
              title="Skicka ... påminnelser om krav"
              fieldKey="DebtCollectionDetails.NumberOfReminders"
              {...{ method, storeName }}
            />
          </div>

          <div className="mb-6">
            <CheckField
              title="Socialnämnden
          skall informeras vid betalningsföreläggande"
              description="Kravet utgör bostadsavgifter för privatperson där Socialnämnden
          skall informeras vid betalningsföreläggande"
              fieldKey="DebtCollectionDetails.InformSocialWelfare"
              {...{ method, storeName }}
            />
          </div>

          <div className="mb-6">
            <CheckField
              title="Avhysning skall ske vid
          betalningsföreläggande"
              description="Kravet utgör bostadsavgifter där avhysning skall ske vid
          betalningsföreläggande"
              fieldKey="DebtCollectionDetails.Eviction"
              {...{ method, storeName }}
            />
          </div>
        </>
      ) : (
        <div className="mb-6">
          <LocalCheckField
            title="Använd automatiserad kravhantering"
            value={false}
            onChange={handleActivateDebtCollection}
          />
        </div>
      )}
    </>
  );
}
