import styled, { css } from "styled-components";

export const Card = styled.div`
  border: thin solid #dddddd;
  border-radius: 5px;
  padding: 12px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  margin-bottom: 12px;

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;
