import * as React from "react";
import Badge, { BADGE_TYPES } from "../../components/Badge/Badge";

import { CodeBracketIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import ExcelIconPath from "../../assets/svg/excelGreen.svg";

export function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const compareUndefinedValues = (obj, obj1, key) => {
  //obj[key] === undefined // true here

  if (
    obj1[key] === undefined ||
    obj1[key] === null ||
    obj1[key] === false ||
    obj1[key]?.length === 0
  )
    return true;

  return false;
};

const compareArrays = (arr, arr1) => {
  if (arr.length === 0 && arr1 === undefined) return true;
  if (arr.length !== arr1.length) return false;

  let eq = true;
  let index = 0;
  for (let prop of arr) {
    if (!eq) break;
    switch (typeof prop) {
      case "undefined":
        eq = compareUndefinedValues(arr, arr1, index);
        break;
      case "object":
        eq = deepCompareObjects(arr[index], arr1[index]);
        break;
      case "array":
        eq = compareArrays(arr[index], arr1[index]);
        break;
      default:
        if (arr[index] !== arr[index]) eq = false;
        break;
    }

    index++;
  }

  return eq;
};

export const deepCompareObjects = (obj, obj1) => {
  let equal = true;

  for (let key of Object.keys(obj)) {
    if (!equal) break;
    if (obj1.hasOwnProperty(key)) {
      // if(typeof obj[key] ===)
      // console.log(typeof obj1[key], key, 500);

      switch (typeof obj[key]) {
        case "undefined":
          equal = compareUndefinedValues(obj, obj1, key);
          break;
        case "object":
          // console.log("object");
          equal = deepCompareObjects(obj[key], obj1[key]);
          break;
        case "array":
          // console.log("array");
          equal = compareArrays(obj[key], obj1[key]);
          break;
        default:
          if (obj[key] !== obj1[key]) equal = false;
          break;
      }

      continue;
    }
    equal = false;
  }

  return equal;
};

export const INFO_TEXTS_FOR_FILTERS = {
  aggregations:
    "Med aggregeringar kan ni välja att göra egna sammanställningar och beräkningar i rapporten. Välj exempelvis att summera eller beräkna antalet utifrån vald aggregeringstyp. Ni kan även kombinera flertalet attribut samtidigt.",
  groupings:
    "Välj att gruppera rapporten för att även visa en flik/sida per valda kombinationer av kategorier nedan.",
};

export const EXPORT_TYPES_ICON = {
  pdf: <DocumentTextIcon className="h-4 w-4 text-red-400" />,
  json: <CodeBracketIcon className="h-4 w-4" />,
  xlsx: (
    <div
      className="h-4 w-4 bg-contain bg-no-repeat"
      style={{ backgroundImage: `url(${ExcelIconPath})` }}
    ></div>
  ),
};

export const CATEGORIES = {
  base: {
    name: "Bas",
    badge: (selected = false, small = false, onClick) => (
      <Badge
        onClick={onClick}
        small={small}
        className={`${
          selected
            ? "border border-solid border-gray-400"
            : "border border-solid border-transparent"
        }`}
        type={BADGE_TYPES.INDIGO}
      >
        Bas
      </Badge>
    ),
  },
  brf: {
    name: "Brf",
    badge: (selected = false, small = false, onClick) => (
      <Badge
        onClick={onClick}
        small={small}
        className={`${
          selected
            ? "border border-solid border-gray-400"
            : "border border-solid border-transparent"
        }`}
        type={BADGE_TYPES.PINK}
      >
        Brf
      </Badge>
    ),
  },
  tenants: {
    name: "Hyresgäster",
    badge: (selected = false, small = false, onClick) => (
      <Badge
        onClick={onClick}
        small={small}
        className={`${
          selected
            ? "border border-solid border-gray-400"
            : "border border-solid border-transparent"
        }`}
        type={BADGE_TYPES.CYAN}
      >
        Hyresgäster
      </Badge>
    ),
  },
  object: {
    name: "Objekt",
    badge: (selected = false, small = false, onClick) => (
      <Badge
        onClick={onClick}
        small={small}
        className={`${
          selected
            ? "border border-solid border-gray-400"
            : "border border-solid border-transparent"
        }`}
        type={BADGE_TYPES.GRAY}
      >
        Objekt
      </Badge>
    ),
  },
  index: {
    name: "Hyreshöjning",
    badge: (selected = false, small = false, onClick) => (
      <Badge
        onClick={onClick}
        small={small}
        className={`${
          selected
            ? "border border-solid border-gray-400"
            : "border border-solid border-transparent"
        }`}
        type={BADGE_TYPES.PURPLE}
      >
        Hyreshöjning
      </Badge>
    ),
  },
  notification: {
    name: "Avisering",
    badge: (selected = false, small = false, onClick) => (
      <Badge
        onClick={onClick}
        small={small}
        className={`${
          selected
            ? "border border-solid border-gray-400"
            : "border border-solid border-transparent"
        }`}
        type={BADGE_TYPES.GREEN}
      >
        Avisering
      </Badge>
    ),
  },

  missing: {
    name: "Saknas",
    badge: (selected = false, small = false, onClick) => (
      <Badge
        onClick={onClick}
        small={small}
        className={`${
          selected
            ? "border border-solid border-gray-400"
            : "border border-solid border-transparent"
        }`}
        type={BADGE_TYPES.RED}
      >
        Saknas
      </Badge>
    ),
  },
};

export const KWARG_MAP = {
  start_date: {
    name: "Startdatum",
    type: "date",
    errorPath: "additional_kwargs.start_date",
  },
  end_date: {
    name: "Slutdatum",
    type: "date",
    errorPath: "additional_kwargs.end_date",
  },
  date: {
    name: "Datum",
    type: "date",
    errorPath: "additional_kwargs.date",
  },
  realestate_ids: {
    name: "Fastigheter",
    type: "tableSelect",
    typeName: "realestates",
    placeholder: "Välj fastigheter...",
    many: true,
    //only set if the state returns an array with objects when it should be an array with ids
    arrayContentType: "id",
    errorPath: "filters.realestate_ids",
    dependencies: {
      building_ids: {
        internalComparator: "id",
        externalComparator: "realestate.id",
      },
    },
  },
  building_ids: {
    name: "Byggnader",
    type: "tableSelect",
    typeName: "buildings",
    placeholder: "Välj byggnader...",
    many: true,
    arrayContentType: "id",
    errorPath: "filters.buildings",
    filterBy: ["realestate_ids"],
  },
  company_ids: {
    name: "Bolag",
    type: "tableSelect",
    typeName: "companies",
    placeholder: "Välj bolag...",
    many: true,
    arrayContentType: "id",
    errorPath: "filters.company_ids",
  },
  billing_company_ids: {
    name: "Fakturerande bolag",
    type: "tableSelect",
    typeName: "companies",
    placeholder: "Välj bolag...",
    many: true,
    arrayContentType: "id",
    errorPath: "filters.billing_company_ids",
  },
  include_non_attested: {
    name: "Inkludera ej attesterade avier",
    text: "Inkludera",
    type: "checkbox",
    errorPath: "filters.include_non_attested",
  },
  include_vacancies: {
    name: "Vakanta objekt",
    text: "Inkludera",
    type: "checkbox",
    errorPath: "filters.include_vacancies",
  },
  exclude_main_data: {
    name: "Inkludera endast grupperingar & aggregeringar",
    text: "Inkludera",
    type: "checkbox",
    default: false, //without default, default will be true
    errorPath: "exclude_main_data",
    showOnStaticInputsHasValue: ["aggregations", "groupings"], //not many
    infoText: "Välj att enbart ta med grupperade och aggregerade värden",
    last: true, //will be placed after aggregations & groupings
  },
  allow_due_date_not_passed: {
    name: "Inkludera ej förfallna avier",
    text: "Inkludera",
    type: "checkbox",
    errorPath: "filters.allow_due_date_not_passed",
  },
  include_parking_spots: {
    name: "Inkludera parkeringar",
    text: "Inkludera",
    type: "checkbox",
    errorPath: "filters.include_parking_spots",
  },
  for_transaction_date: {
    // name: "Filtrera utifrån transaktionsdatum",
    // text: "Filtrera",
    // type: "checkbox",
    name: "Perioden baseras på",
    type: "select",
    choices: [
      { v: true, d: "Transaktionsdatum" },
      { v: false, d: "Händelsedatum" },
    ],
    arrayContentType: "boolean",

    errorPath: "filters.for_transaction_date",
  },
  premises_kind: {
    name: "Typ av objekt",
    type: "selectMany",
    choices: true,
    //this is probably useless
    many: true,
    errorPath: "filters.premises_kind",
    isIdArray: true,
  },
  premises_category: {
    name: "Objektskategorier",
    type: "selectMany",
    choices: true,
    errorPath: "filters.premises_category",
  },
  tenant_kind: {
    name: "Typ av hyresgäster",
    type: "selectMany",
    choices: true,
    //this is probably useless
    many: true,
    errorPath: "filters.tenant_kind",
    isIdArray: true,
  },
  include_post_period: {
    name: "Inkludera efter period",
    default: true,
    type: "checkbox",
  },
};

export const MOCK_MAP = {
  has_imd: {
    // method: '(() => "Ja")()',
    data: ["Ja", "Nej"],
    process: true, //will make [data] to (() => "[data]")() eg. will render as string
  },
  start_date: {
    data: 'moment(faker.date.past()).format("YYYY-MM-DD")',
  },
  end_date: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  invoiced_from: {
    data: 'moment(faker.date.past()).format("YYYY-MM-DD")',
  },
  invoiced_to: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  has_invoiced_to: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  payment_delay_days: {
    data: "faker.random.numeric(2)",
  },
  has_autogiro: {
    data: ["Ja", "Nej"],
  },
  is_vacant: {
    data: ["Ja", "Nej"],
  },
  realestate_key: {
    data: "FASTIGHET X",
    process: true,
  },
  contract_kind: {
    data: "Avtalstyp",
    process: true,
  },
  main_contract: {
    data: "Avtal X",
    process: true,
  },
  contract_status: {
    data: ["Kommande", "Aktivt", "Föregående", "Ej signerat", "Uppsagt"],
  },
  closed_kind: {
    data: ["Uppsagt för avflyttning", "Uppsagt för avtalsändring"],
  },
  premises_id: {
    data: "faker.random.numeric(4)",
  },
  premises_kind: {
    data: ["Lägenhet", "Lokal", "Fordonsplats"],
  },
  premises_category: {
    data: ["1 RoK", "2 RoK", "3 RoK", "Butikslokal", "Kontorsplatser", "Lager"],
  },
  deposition: {
    data: "faker.random.numeric(Math.floor(Math.random() * (10)) + 1)",
  },
  index_base_year: {
    data: "faker.mersenne.rand(2000, 2022)",
  },
  index_min_points: {
    data: "0",
    process: true,
  },
  index_allow_reduction: {
    data: ["Ja", "Nej"],
  },
  taxation_kind: {
    data: "Total taxering",
    process: true,
  },
  contract_rest_liftime_value: {
    data: "-",
    process: true,
  },
  has_multiple_index_rows: {
    data: ["Ja", "Nej"],
  },
  yearly_cold_rent: {
    data: 'faker.random.numeric(Math.floor(Math.random() * (6)) + 1) + " kr"',
  },
  invoice_period: {
    data: "Per månad",
    process: true,
  },
  realestate_owner: {
    data: "faker.company.name()",
  },
  realestate_owner_since: {
    data: 'moment(faker.date.past()).format("YYYY-MM-DD")',
  },
  project: {
    data: "Projekt X",
    process: true,
  },
  cost_center: {
    data: "Kostnadställe X",
    process: true,
  },
  popular_name: {
    data: "FASTIGHET X",
    process: true,
  },
  realestate_popular_name: {
    data: "FASTIGHET X",
    process: true,
  },
  responsible_user: {
    data: "faker.name.fullName()",
  },
  type_code: {
    data: [
      "Hyreshusenhet, bostäder (320)",
      "Hyreshusenhet, bostäder och lokaler (321)",
    ],
  },
  taxation_year: {
    data: "faker.mersenne.rand(2000, 2022)",
  },
  tenant: {
    data: "faker.name.fullName()",
  },
  renewed_to: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  closed_on: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  apartment_id: {
    data: "faker.random.numeric(4)",
  },
  phone: {
    data: '"+46-" + faker.random.numeric(9)',
  },
  contact_person_phone: {
    data: '"+46-" + faker.random.numeric(9)',
  },
  care_of: {
    data: "-",
    process: true,
  },
  your_reference: {
    data: "faker.name.fullName()",
  },
  delivery_method: {
    data: ["Epost", "Post", "Kivra", "E-faktura"],
  },
  e_invoice_bank: {
    data: ["Handelsbanken", "Swedbank", "SEB", "Nordea"],
  },
  intermediator: {
    data: "Intermediatör",
    process: true,
  },
  gln: {
    data: "GLN",
    process: true,
  },
  autogiro_bank: {
    data: ["Handelsbanken", "Swedbank", "SEB", "Nordea"],
  },
  invoice_status: {
    data: ["Pausad", "Konfigurerad", "Ej konfigurerad"],
  },
  closed_by: {
    data: ["Hyresgäst", "Hyresvärd"],
  },
  debt_status: {
    data: ["Påminnelse", "Ej betald"],
  },
  payment_method: {
    data: "BG",
    process: true,
  },
  payment_reference: {
    data: "-",
    process: true,
  },
  payment_ocr: {
    data: "faker.random.numeric(16)",
  },
  vacancy_status: {
    data: ["Vakant", "Ej vakant"],
  },
  last_performed_increase: {
    data: "faker.mersenne.rand(2000, 2022)",
  },
  last_rent_increase: {
    data: "faker.mersenne.rand(2000, 2022)",
  },
  contract_or_premis: {
    data: ["Avtal", "Objekt"],
  },
  cost_title: {
    data: ["Hyra", "Tillägg"],
  },
  product: {
    data: "Produkt X",
    process: true,
  },
  index_against_year: {
    data: "faker.mersenne.rand(2000, 2022)",
  },
  index_against_year_value: {
    data: "faker.finance.amount()",
  },
  index_rounding_mode: {
    data: "Avrunda inte",
    process: true,
  },
  index_product: {
    data: "Produkt X",
    process: true,
  },
  index_year_value_delta: {
    data: 'faker.random.numeric(2) + "%"',
  },
  premises_statuses: {
    data: "-",
    process: true,
  },
  do_debit: {
    data: ["Ja", "Nej"],
  },
  index_table: {
    data: "KPI (1980 = 100)",
    process: true,
  },
  vacancy_booked_to: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  invoice_period_shift: {
    data: ["Innevarande", "Kommande", "Tidigare"],
  },
  revenue_account: {
    data: "3000",
    process: true,
  },
  verification_text: {
    data: "-",
    process: true,
  },
  account_no: {
    data: "3000",
    process: true,
  },
  payment_kind: {
    data: "BG", //TODO: Maybe incorrect?
    process: true,
  },
  payer_bankgiro: {
    data: "-",
    process: true,
  },
  tax_mode: {
    data: "Total taxering",
    process: true,
  },
  period_start: {
    data: 'moment(faker.date.past()).format("YYYY-MM-DD")',
  },
  period_end: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  period_end_bookkeeping: {
    data: 'moment(faker.date.future()).format("YYYY-MM-DD")',
  },
  quantity: {
    data: "faker.random.numeric(1)",
  },
  unit: {
    data: "st",
    process: true,
  },
  invoice_payment_status: {
    data: "Stängd",
    process: true,
  },
  has_auto_debt: {
    data: ["Ja", "Nej"],
  },
  vat_key_since: {
    data: 'moment(faker.date.past()).format("YYYY-MM-DD")',
  },
  customer_id: {
    data: "faker.random.numeric(5)",
  },
  contact_persons: {
    data: "faker.name.fullName()",
  },
  invoice_country: {
    data: "Sverige",
    process: true,
  },
  invoice_phone: {
    data: '"+46-" + faker.random.numeric(9)',
  },
  contact_person_title: {
    data: "-",
    process: true,
  },
  floors: {
    data: "faker.random.numeric(1)",
  },
  is_fee: {
    data: ["Ja", "Nej"],
  },
  has_index: {
    data: ["Ja", "Nej"],
  },
  pledge_owner: {
    data: "faker.name.fullName()",
  },
  transaction_kind: {
    data: "Vanlig transaktion",
    process: true,
  },
  parking_lot: {
    data: "Område X",
    process: true,
  },
  notify_time: {
    data: 'moment(faker.date.past()).format("YYYY-MM-DD")',
  },
};

// export const CATEGORIES = {
//   object: {
//     name: "Objekt",
//     badge: (selected = false) => (
//       <Badge
//         className={`${
//           selected
//             ? "border border-solid border-gray-400"
//             : "border border-solid border-transparent"
//         }`}
//         type={BADGE_TYPES.GREEN}
//
//       >
//         Objekt
//       </Badge>
//     ),
//   },
//   rent: {
//     name: "Hyra",
//     badge: (selected = false) => (
//       <Badge
//         className={`${
//           selected
//             ? "border border-solid border-gray-400"
//             : "border border-solid border-transparent"
//         }`}
//         type={BADGE_TYPES.GRAY}
//
//       >
//         Hyra
//       </Badge>
//     ),
//   },
//   missing: {
//     name: "Saknas",
//     badge: (selected = false) => (
//       <Badge
//         className={`${
//           selected
//             ? "border border-solid border-gray-400"
//             : "border border-solid border-transparent"
//         }`}
//         type={BADGE_TYPES.RED}
//
//       >
//         Saknas
//       </Badge>
//     ),
//   },
// };
