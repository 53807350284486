import Confirm from "./Confirm";

import KeyPermission from "./KeyPermission";
import Object from "./Object";

import Integrations from "./Integrations";


export default {
  PERMISSION: KeyPermission,
  OBJECT: Object,
  CONFIRM: Confirm,
  INTEGRATION: Integrations
};
