import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  invoiceDetailUrl,
  getInvoiceIdPreview,
} from "../../../store/billectaInvoicing";
import { useDispatch, useSelector } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import moment from "moment";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import {
  getAllInvoices,
  mergeInvoices,
  setInvoicesClosedFromDate,
  attestAllInvoicesForCreditor,
} from "../../../store/billectaInvoicing/store/actions";
import { createUrl } from "../../../store/billectaInvoicing";
import ContainerSpinner from "../../../components/Loaders/ContainerSpinner";
import InvoicesTable from "../../../components/Billecta/Invoices/InvoicesTable";

import PrimaryBtn from "../../../components/Forms/Base/Buttons/PrimaryBtn";
import AttestInvoicesModal from "../../../components/Billecta/AttestInvoicesModal";
import MergeInvoicesForm from "../../../components/Forms/Invoice/MergeInvoicesForm";
import ConfirmModal from "../../../components/Forms/Base/Modals/ConfirmModal";
import { CubeIcon, DocumentPlusIcon } from "@heroicons/react/24/outline";

export default function Invoices() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { creditorId } = useParams();

  const [mergeInvoicesOptionsOpen, setMergeInvoicesOptionsOpen] =
    React.useState(false);
  const [mergeInvoicesInvoiceDate, setMergeInvoicesInvoiceDate] =
    React.useState(null);
  const [mergeInvoicesDueDate, setMergeInvoicesDueDate] = React.useState(null);
  const [mergeTenantsCount, setMergeTenantsCount] = React.useState(0);

  const [selectedInvoices, setSelectedInvoices] = React.useState([]);

  const [attestInvoicesOpen, setAttestInvoicesOpen] = React.useState(false);

  const [confirmAttestCreditorOpen, setConfirmAttestCreditorOpen] =
    React.useState(false);

  const handleSelectedInvoicesUpdated = React.useCallback((invoices) => {
    setSelectedInvoices(invoices || []);
  }, []);

  const invoices = useSelector((state) => state.billectaInvoicing.invoices);
  const invoicesClosedFromDate = useSelector(
    (state) => state.billectaInvoicing.invoicesClosedFromDate
  );

  const handleDownloadInvoice = (invoice) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Hämtar faktura...",
      })
    );
    dispatch(
      getInvoiceIdPreview({
        invoiceId: invoice?.ActionPublicId,
        download: true,
      })
    );
  };

  const handleRowClicked = (row) => {
    push(
      invoiceDetailUrl({
        creditorId,
        invoiceId: row.original.ActionPublicId,
      })
    );
  };

  const handleMergeInitiated = () => {
    const selInvoices = selectedInvoices?.map((s) => s.original);

    const uniqueTenantsCount = selInvoices?.reduce((acc, cur) => {
      if (acc.includes(cur.DebtorPublicId)) return acc;

      acc.push(cur.DebtorPublicId);

      return acc;
    }, [])?.length;

    const earliestInvoiceDate = selInvoices.reduce((acc, cur) => {
      const curDate = moment(cur.InvoiceDate);
      if (!acc) return curDate.format("YYYY-MM-DD");

      const accDate = moment(acc);

      if (accDate.isAfter(curDate)) return curDate.format("YYYY-MM-DD");

      return accDate.format("YYYY-MM-DD");
    }, null);

    const latestDueDate = selInvoices.reduce((acc, cur) => {
      const curDate = moment(cur.DueDate);
      if (!acc) return curDate.format("YYYY-MM-DD");

      const accDate = moment(acc);

      if (accDate.isBefore(curDate)) return curDate.format("YYYY-MM-DD");

      return accDate.format("YYYY-MM-DD");
    }, null);

    setMergeInvoicesInvoiceDate(earliestInvoiceDate);
    setMergeInvoicesDueDate(latestDueDate);
    setMergeTenantsCount(uniqueTenantsCount);

    setMergeInvoicesOptionsOpen(true);
  };

  const renderClosedDatePicker = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 8 }}>Visa fakturor som är stängda sedan</div>

        <NonConnectedDatePicker
          inputExtraStyle={{ maxWidth: 160 }}
          id="billecta_date_from"
          value={
            invoicesClosedFromDate ||
            moment().subtract(3, "months").format("YYYY-MM-DD")
          }
          onChange={(date) => {
            dispatch(
              setInvoicesClosedFromDate({
                date,
                creditorId,
              })
            );
          }}
          clearable={false}
          noMargin
          maxDate={moment().toDate()}
        />
      </div>
    );
  };

  const attestAll = () => {
    dispatch(
      attestAllInvoicesForCreditor({
        creditorId,
        successCallback: () => {
          addToast({
            type: TOAST_TYPES.SUCCESS,
            title: "Attestering av alla fakturor under bolag påbörjades",
            description:
              "Observera att beroende på mängd så kan detta ta upp till cirka 10 minuter. Omladdning av sidan kan krävas för att se resultatet.",
          });
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något fick fel",
              description: "Fakturorna kommer ej attesteras.",
            })
          );
        },
      })
    );
  };

  const attestActionDisabled =
    selectedInvoices?.length >= 50 ||
    selectedInvoices.some((i) => i.original.AttestedDate);

  const totalNonAttestedCount = invoices?.filter(
    (i) => !i.AttestedDate
  )?.length;

  const isSameDebtor = selectedInvoices?.every(
    (i) =>
      i.original.DebtorPublicId ===
      selectedInvoices?.[0].original.DebtorPublicId
  );

  const mergeActionDisabled =
    selectedInvoices.some((i) => i.original.AttestedDate) || !isSameDebtor;

  const selectedInvoiceIds = React.useMemo(() => {
    return selectedInvoices?.map((i) => i.original.ActionPublicId);
  }, [selectedInvoices]);

  return (
    <>
      {confirmAttestCreditorOpen && (
        <ConfirmModal
          onAccept={attestAll}
          closeFunction={() => setConfirmAttestCreditorOpen(false)}
        >
          <div className="space-y-2">
            <h3 className="mb-2 text-base font-normal text-slate-700 ">
              Bekräfta attestering av alla ej attesterade fakturor. <br />
            </h3>

            <p>
              Detta innebär att{" "}
              <strong>
                alla fakturor som ännu inte attesterats under detta bolag
              </strong>{" "}
              markeras som attesterade.
            </p>

            <p>Denna åtgärd kan inte ångras.</p>

            <p>
              Attesteringen sker i bakgrunden och är oftast klar inom cirka 10
              minuter. För att se resultatet kan omladdning av sidan krävas.
            </p>
          </div>
        </ConfirmModal>
      )}

      {attestInvoicesOpen && (
        <AttestInvoicesModal
          creditorId={creditorId}
          invoiceIds={selectedInvoiceIds}
          closeFunction={() => setAttestInvoicesOpen(false)}
        />
      )}

      {mergeInvoicesOptionsOpen && (
        <MergeInvoicesForm
          creditorId={creditorId}
          debtorCount={mergeTenantsCount}
          initialInvoiceDate={mergeInvoicesInvoiceDate}
          initialDueDate={mergeInvoicesDueDate}
          closeFunction={() => setMergeInvoicesOptionsOpen(false)}
          selectedInvoices={selectedInvoices}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Fakturor</OverviewTitle>

            <div className="flex items-center space-x-2">
              <PrimaryBtn
                secondaryHighlight={totalNonAttestedCount > 0}
                disabled={totalNonAttestedCount === 0}
                onClick={() => setConfirmAttestCreditorOpen(true)}
              >
                {totalNonAttestedCount > 0 ? (
                  <>
                    Attestera alla {totalNonAttestedCount} fakturor{" "}
                    <CubeIcon width={16} className="ml-2" />
                  </>
                ) : (
                  <>Inga fakturor att attestera</>
                )}
              </PrimaryBtn>
              <PrimaryBtn onClick={() => push(createUrl({ creditorId }))}>
                Skapa ny faktura{" "}
                <DocumentPlusIcon width={16} className="ml-2" />
              </PrimaryBtn>
            </div>
          </OverviewTitleWrapper>

          {!!invoices ? (
            <InvoicesTable
              invoices={invoices}
              handleDownloadInvoice={handleDownloadInvoice}
              handleSelectedInvoicesUpdated={handleSelectedInvoicesUpdated}
              selectedInvoices={selectedInvoices}
              handleAttestSelectedInvoices={() => setAttestInvoicesOpen(true)}
              handleMergeSelectedInvoices={handleMergeInitiated}
              attestSelectedDisabled={attestActionDisabled}
              mergeActionDisabled={mergeActionDisabled}
              handleRowClicked={handleRowClicked}
              renderBesideSearch={renderClosedDatePicker}
            />
          ) : (
            <ContainerSpinner />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
