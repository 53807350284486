import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import Pdf from "../../../components/Pdf/Pdf";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  useInvoice,
  useInvoicePreview,
} from "../../../store/billectaInvoicing/hooks/retrieve";

export default function InvoicePreview() {
  const { invoiceId, creditorId } = useParams();
  const [invoice, invoiceLoading] = useInvoice({ creditorId, invoiceId });

  const [invoicePreview, invoicePreviewLoading] = useInvoicePreview({
    invoiceId,
  });

  const isVacancyBookingInvoice =
    invoice?.ActionType === "VerificationInvoiceAction";

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = invoicePreview.pdf;
    a.download = `${invoiceId}.pdf`;
    a.click();
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox style={{ minHeight: "800px" }}>
        {invoicePreviewLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Förhandsgranskning{" "}
              {isVacancyBookingInvoice ? "underlag" : "faktura"}
            </OverviewTitle>
            {invoicePreview?.pdf && (
              <TextButton
                iconType="download"
                title="Ladda ner"
                clicked={handleDownload}
              />
            )}
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {invoicePreview && !invoicePreviewLoading && (
          <InnerBox>
            <Pdf url={invoicePreview.pdf} />
          </InnerBox>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
