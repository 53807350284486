import * as React from "react";
import { Redirect, useParams } from "react-router";

import { useOtherInvoicing } from "../../../store/invoicingOther";
import { detailUrl } from "../../../store/invoicingOther";

export default function InvoicingOtherRedirect() {
  const { id } = useParams();

  const [otherInvoicing] = useOtherInvoicing(id);

  const otherContractId = otherInvoicing?.other_contract?.id;

  // wait for sufficient data to redirect
  if (!otherContractId) return null;

  return <Redirect to={detailUrl({ id: otherContractId })} />;
}
