export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PORTAL_SETTING_TERMS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PORTAL_SETTING_TERMS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PORTAL_SETTING_TERMS",
  DESTROY_FORM: "DESTROY_FORM_PORTAL_SETTING_TERMS",
  SET_FORM_ERROR: "SET_FORM_ERROR_PORTAL_SETTING_TERMS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PORTAL_SETTING_TERMS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PORTAL_SETTING_TERMS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PORTAL_SETTING_TERMS",
  RESET_STATE: "RESET_STATE_PORTAL_SETTING_TERMS",

  LIST_URL: "/accounts/tenants/tenant_terms/list/",
  POST_URL: "/accounts/tenants/tenant_terms/",
  PATCH_URL: "/accounts/tenants/tenant_terms/",
  GET_URL: "/accounts/tenants/tenant_terms/",

  STORE_NAME: "tenantPortalSettingTerms",
};
