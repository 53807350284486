import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingIMDSettings";
import { Checkbox, NumberInput, TextInput } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>IMD-debitering</FormAreaTitle>

      <TextInput
        {...{
          method,
          storeName,
          fieldKey: "title",
          title: "Titel på inställning",
        }}
      />

      <Checkbox
        {...{
          method,
          storeName,
          fieldKey: "display_consumtion",
          title: "Visa konsumtion på avier",
          description:
            "Om detta val är aktivt kommer konsumtionen som ligger till grund för debiteringen att visas på avier. Om den är inaktiv klumpas summan ihop på en rad.",
        }}
      />

      <Checkbox
        {...{
          method,
          storeName,
          fieldKey: "include_period_string",
          title: "Visa period som debiteras för på avi",
        }}
      />

      <NumberInput
        title="Förskjuten debitering, antal perioder"
        description={`Som standard (1 periods förskjutning) debiteras t.ex. januaris konsumtion i februari. För att exempelvis debitera januaris konsumtion i mars, ange 2, för att debitera i april, ange 3 osv.`}
        {...{ storeName, method, fieldKey: "invoice_month_offset" }}
      />
    </>
  );
};
