import * as React from "react";
import constants from "../store/constants";
import {
  useFilteredObjects,
  useObject,
  usePagination,
  useForm,
} from "../../base";
import {
  getSingle,
  getPatchForm,
  performFilter,
  filterPagination,
} from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/actions";

export function useInsightsPage(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}


export function useInsightsPages(queryString) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
  };
  return useFilteredInsightsPageObjects(params);
}

export function useFilteredInsightsPageObjects({ fetchMethod, querystring, storeName }) {
  const dispatch = useDispatch();

  const [result, setResult] = React.useState([]);
  const [returnFetching, setReturnFetching] = React.useState(undefined);

  const [haventRetried, setHaventRetried] = React.useState(false);

  // undefined, null e.g -> "";
  const formattedQueryString = querystring || "";
  
  const userLoggedin = useSelector((state) => !!state.app.authorization);

  const hasAttemptedLogin = useSelector(
    (state) => !!state.app.hasAttemptedLogin
  );

  const existing = useSelector(
    (state) => state[storeName].filtered[formattedQueryString]
  );
  const tempCopyExisting = useSelector(
    (state) => state[storeName].filteredCopy[formattedQueryString]
  );
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(formattedQueryString)
  );

  const filteredObjects = useSelector((state) => {
    if (!existing) {
      return undefined;
    }

    const distinct = existing.filter((id, index) => {
      return existing.indexOf(id) === index;
    });

    let res = [];
    distinct.forEach((id) => {
      const obj = state[storeName].all[id];
      if (obj) {
        res.push(obj);
      }
    });

    return res;
  });

  const tempCopyfilteredObjects = useSelector((state) => {
    if (!tempCopyExisting) {
      return undefined;
    }

    const distinct = tempCopyExisting.filter((id, index) => {
      return tempCopyExisting.indexOf(id) === index;
    });

    let res = [];
    distinct.forEach((id) => {
      const obj = state[storeName].allCopy[id];
      if (obj) {
        res.push(obj);
      }
    });

    return res;
  });

  const performDataCheck = () => {
    if (!userLoggedin) {
      if (hasAttemptedLogin) {
        dispatch(logout());
        setResult([]);
        setReturnFetching(false);
        return;
      }

      setReturnFetching(isFetching);
      setResult([]);
      return;
    }

    // first check if it exists in store
    if (filteredObjects && (Object.keys(filteredObjects).length > 1 && haventRetried)) {
      // it exists, apply filter if we need to, otherwice just return
      setResult(filteredObjects);
      setReturnFetching(false);
      return;
    }

    

    if (tempCopyfilteredObjects && (filteredObjects && Object.keys(filteredObjects).length > 1 && haventRetried)) {
      if (!isFetching && querystring != null) {
        dispatch(fetchMethod(formattedQueryString));
      }

      setResult(tempCopyfilteredObjects);
      setReturnFetching(isFetching);
      return;
    }

    if (filteredObjects) {
      if (!haventRetried && !isFetching) {
        setHaventRetried(true)
      }

    }

    if (isFetching || querystring === null) {
      // if it doesn't exist check if it is currently beeing fetched
      // if so, we wait for that
      setResult([]);
      setReturnFetching(isFetching);
      return;
    }

    // we're not waiting, so we should initiate a retrival
    dispatch(fetchMethod(formattedQueryString));

    setResult([]);
    setReturnFetching(true);
    return;
  };

  React.useEffect(() => {
    performDataCheck();
  }, [
    storeName,
    fetchMethod,
    querystring,
    userLoggedin,
    hasAttemptedLogin,
    existing,
    tempCopyExisting,
    isFetching,
    returnFetching,
  ]);

  return [result, returnFetching];
}
