import { axiosInstance } from "../../base/store/axios";
import constants from "../store/constants";

async function getBookKeepingInstance(creditorId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/accounting_settings/${creditorId}/`
  );

  return data;
}

async function updateBookKeeping({ data, creditorId }) {
  const { data: response } = await axiosInstance.put(
    `accounting/gateways/accounting_settings/${creditorId}/`,
    data
  );

  return response;
}

async function getPeriodLockPeriod({ creditorId }) {
  const { data: response } = await axiosInstance.get(
    `${constants.PERIOD_LOCKING_URL}${creditorId}/`
  );

  return response;
}

async function lockBookkeepingPeriod({ creditorId, date }) {
  const { data: response } = await axiosInstance.post(
    `${constants.PERIOD_LOCKING_URL}${creditorId}/?to=${date}`
  );

  return response;
}

async function removeLockBookkeepingPeriod({ creditorId }) {
  const { data: response } = await axiosInstance.delete(
    `${constants.PERIOD_LOCKING_URL}${creditorId}/`
  );

  return response;
}

export {
  getBookKeepingInstance,
  updateBookKeeping,
  getPeriodLockPeriod,
  lockBookkeepingPeriod,
  removeLockBookkeepingPeriod,
};
