import * as React from "react";
import { useHistory } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString, usePermissionCheck } from "../../../store/base";

import MeasureTypeTable from "../../../components/Tables/MeasureType/FullTable";
import IMDMeasureTypeModalForm from "../../../components/Forms/IMDMeasureType/ChapterForm/ModalForm";

export default function IMDSensors() {
  const { push } = useHistory();
  const [createOpen, setCreateOpen] = React.useState(false);

  const { filteredRealEstates } = useActiveFilters();
  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  const canAddImd = usePermissionCheck("add_can_imd");
  const canChangeImd = usePermissionCheck("change_can_imd");

  return (
    <>
      <IMDMeasureTypeModalForm
        method="POST"
        isOpen={createOpen}
        onCheckout={() => setCreateOpen(false)}
      />
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>IMD Mätartyper</OverviewTitle>

            <div>
              {canAddImd && (
                <PrimaryButton
                  title="Lägg till ny mätartyp"
                  clicked={() => setCreateOpen(true)}
                />
              )}
            </div>
          </OverviewTitleWrapper>

          <MeasureTypeTable {...{ persistantQuery }} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
