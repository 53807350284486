import * as React from "react";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { getErrandStatus } from "../utils";
import * as SC from "./styles";

export default function ReportErrandBox({ errand, onClick }) {
  const { colorStatus, display } = getErrandStatus(errand);
  return (
    <SC.Card onClick={onClick}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{ fontSize: 14 }}
        >{`${errand.errand_id} - ${errand.str_representation}`}</div>
        <StatusLabel contentType="errand" state={colorStatus}>
          {display}
        </StatusLabel>
      </div>
    </SC.Card>
  );
}
