import * as React from "react";

export default () => {
  return (
    <div>
      Välj parterna som ska stå på avtalet.
      <br />
      <br />
    </div>
  );
};
