export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRF_COMPANY",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRF_COMPANY",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRF_COMPANY",
  DESTROY_FORM: "DESTROY_FORM_BRF_COMPANY",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRF_COMPANY",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRF_COMPANY",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRF_COMPANY",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRF_COMPANY",
  RESET_STATE: "RESET_STATE_BRF_COMPANY",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRF_COMPANY",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRF_COMPANY",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRF_COMPANY",

  LIST_URL: "/brf/brfcompany/list/",
  POST_URL: "/brf/brfcompany/",
  PATCH_URL: "/brf/brfcompany/",
  GET_URL: "/brf/brfcompany/",

  GET_KU55_URL : "/brf/ku55/ku55_generator/",
  POST_KU55_LOG_URL: "/brf/ku55/ku55_generator_marker/",

  STORE_NAME: "brfCompany",
};
