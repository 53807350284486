import * as React from "react";
import {
  useBrfInvoicing,
  previewInvoice as brfPreviewInvoice,
} from "src/store/invoicingBrf";
import {
  previewInvoice as leasePreviewInvoice,
  useLeaseInvoicing,
} from "src/store/invoicingLease";
import {
  useOtherInvoicing,
  previewInvoice as otherPreviewInvoice,
} from "src/store/invoicingOther";
import {
  useParkingInvoicing,
  previewInvoice as parkingPreviewInvoice,
} from "src/store/invoicingParking";
import { useParams } from "react-router-dom";
import { useLeaseContract } from "src/store/leaseContracts";
import { useOtherContract } from "src/store/otherContracts";
import { useParkingContract } from "src/store/parkingContracts";
import { useBrfPremises } from "src/store/brfPremises";
import { useDispatch } from "react-redux";

const getContractInvocingHookFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return useLeaseInvoicing;
  if (otherContractId) return useOtherInvoicing;
  if (parkingContractId) return useParkingInvoicing;
  if (brfPremisesId) return useBrfInvoicing;
};

const getContractHookFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return useLeaseContract;
  if (otherContractId) return useOtherContract;
  if (parkingContractId) return useParkingContract;
  if (brfPremisesId) return useBrfPremises;
};

const getContractIdFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leaseContractId;
  if (otherContractId) return otherContractId;
  if (parkingContractId) return parkingContractId;
  if (brfPremisesId) return brfPremisesId;
};

const getInvoicingKeyFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return "lease_invoicing";
  if (otherContractId) return "other_invoicing";
  if (parkingContractId) return "parking_invoicing";
  if (brfPremisesId) return "brf_invoicing";
};
const getPreviewMethodFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leasePreviewInvoice;
  if (otherContractId) return otherPreviewInvoice;
  if (parkingContractId) return parkingPreviewInvoice;
  if (brfPremisesId) return brfPreviewInvoice;
};

export default function useNextPeriodValue() {
  const dispatch = useDispatch();

  const { leaseContractId, otherContractId, parkingContractId, brfPremisesId } =
    useParams();
  const contractParams = {
    leaseContractId,
    otherContractId,
    parkingContractId,
    brfPremisesId,
  };
  const contractInvoicingHook =
    getContractInvocingHookFromParams(contractParams);
  const contractHook = getContractHookFromParams(contractParams);
  const invoicingKey = getInvoicingKeyFromParams(contractParams);
  const previewMethod = getPreviewMethodFromParams(contractParams);

  const contractId = getContractIdFromParams(contractParams);

  const [nextInvoiceData, setNextInvoiceData] = React.useState(null);

  const [contract, contractLoading] = contractHook(contractId);

  const [invoicingObj, invoicingObjLoading] = contractInvoicingHook(
    contract?.[invoicingKey]?.id
  );
  React.useEffect(() => {
    if (!nextInvoiceData && invoicingObj?.current_invoice_date) {
      dispatch(
        previewMethod({
          id: invoicingObj.id,
          basedOn: invoicingObj?.current_invoice_date,
          successCallback: (_, invoiceData) => {
            setNextInvoiceData(invoiceData);
          },
          errorCallback: () => {
            return;
          },
        })
      );
    }
  }, [invoicingObj]);

  const nextInvoiceTotal = nextInvoiceData?.Records?.reduce((acc, cur) => {
    const curVal = ((cur.UnitPrice.Value || 0) / 100) * (cur.Quantity || 0);

    return acc + curVal;
  }, 0);

  return nextInvoiceTotal;
}
