import moment from "moment";

export const constructStrRep = (obj) => {
  return obj.str_representation || "Nyckel";
};
export const detailUrl = ({ id }) => {
  return `/keys/keys/detail/${id}`;
};
export const overviewUrl = () => {
  return `/keys/keys`;
};
export const createUrl = () => {
  return `/keys/keys/create`;
};
export const updateUrl = ({id}) => {
  return `/keys/keys/update/${id}`;
};

export const STATUS_MAPPING = {
  0:"Kommande",
  1:"Aktiv",
  2:"Tidigare",
  3:"Bör tas tillbaka",
  4:"Bör lämnas ut",
  5: "Okänt",
  6: "Borttappad"
}

export const calculateStatus = (key) => {
  if (key == null) { return 5 }

  const userHasIt = key.possession === 0;
  const lost = key.possession === 2;
  
  if (lost) {
    return 6;
  }

  const today = moment();
  const start = key.start_time == null ? null : moment(key.start_time);
  const end = key.end_time == null ? null : moment(key.end_time);
  

  if (start != null && today >= start && !userHasIt) {
    return 4;
  }
  if (start != null && today < start) {
    return 0;
  }

  if (end != null && today > end && userHasIt) {
    return 3;
  }
  if (end != null && today > end) {
    return 2;
  }

  return 1
}
