import { cloneDeep } from "lodash";
import * as React from "react";
import {
  FiFile,
  FiImage,
  FiPlay,
  FiThumbsDown,
  FiThumbsUp,
  FiX,
} from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  constants,
  create,
  update,
  useFilteredInspectionErrandRatings,
} from "src/store/inspectionErrandExecutionRating";
import { useInspectionErrandSetting } from "src/store/inspectionErrandSettings";
import { TOAST_TYPES, addToast } from "src/store/toasts";
import { useApartment } from "../../../../../store/apartments";
import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../../../store/base";
import { useBrfPremises } from "../../../../../store/brfPremises";
import { useFilteredChecklistRows } from "../../../../../store/checklistRows";
import { useFilteredChecklists } from "../../../../../store/checklists";
import { useCommonArea } from "../../../../../store/commonAreas";
import { useFilteredErrandComponents } from "../../../../../store/errandComponents";
import { useIndustrialPremises } from "../../../../../store/industrialPremises";
import {
  useInspectionErrand,
  constants as inspectionContants,
  update as updateInspectionErrand,
} from "../../../../../store/inspectionErrands";
import { useParkingSpot } from "../../../../../store/parkingSpots";
import theme from "../../../../../theme";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import StandardModal from "../../../../Modals/StandardModal";
import RoomOverview from "../../../../TechnicalManagement/Component/RoomOverview";
import {
  ExecuteIconContainer,
  FileContainer,
  FileName,
  FileType,
  FileTypeDisplay,
  FilesContainer,
} from "../../../../TechnicalManagement/Component/styles";
import {
  getComponentsByRooms,
  getCurrentProductTypeUsage,
  getInspectionErrandChecklistIdForComponent,
} from "../../../../TechnicalManagement/utils";
import { InnerBox } from "../../../../sharedStyles";
import { TextButton } from "../../../Base/Buttons";
import { RadioGroup, TextField } from "../../../Base/Fields";
import MediaField from "../../../Base/Fields/MediaField";
import OverlaySpinnerLoadingBar from "src/components/Loaders/OverlaySpinnerLoadingBar";
import moment from "moment";

const FILE_TYPE_MAPPER = {
  jpg: "Bild",
  mp4: "Video",
  pdf: "Dokument",
};

const ICON_MAPPER = {
  jpg: (
    <FiImage style={{ marginRight: 12 }} color={theme.colors.red} size={24} />
  ),
  mp4: (
    <FiPlay style={{ marginRight: 12 }} color={theme.colors.green} size={24} />
  ),
  pdf: (
    <FiFile style={{ marginRight: 12 }} color={theme.colors.blue} size={24} />
  ),
};

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { inspectionErrandId } = useParams();

  const [loadingSetAllOK, setLoadingSetAllOK] = React.useState(false);
  const [loadingSetOkStart, setLoadingSetOkStart] = React.useState(moment());
  const [fakeLoadedCount, setFakeLoadedCount] = React.useState(0);

  const [executionRatings] = useFilteredInspectionErrandRatings(
    buildQueryString({ inspection_errand: inspectionErrandId })
  );

  const [handleExecutionRatingId, setHandleExecutionRatingId] =
    React.useState(null);
  const [handleExecutionChecklist, setHandleExecutionChecklist] =
    React.useState(null);

  const [currentOkChecklistRows, setCurrentOkChecklistRows] = React.useState(
    []
  );
  const [currentNotOkChecklistRows, setCurrentNotOkChecklistRows] =
    React.useState([]);

  const [inspectionErrand, inspectionErrandLoading] =
    useInspectionErrand(inspectionErrandId);
  const [inspectionErrandSetting] = useInspectionErrandSetting(
    inspectionErrand?.setting?.id
  );

  const excludeComponentTypeIds =
    inspectionErrandSetting?.exclude_component_types?.map((t) => t.id);

  const [apartment, apartmentLoading] = useApartment(
    inspectionErrand?.apartment?.id
  );
  const [industrialPremises, industrialPremisesLoading] = useIndustrialPremises(
    inspectionErrand?.industrial_premises?.id
  );
  const [commonArea, commonAreaLoading] = useCommonArea(
    inspectionErrand?.common_area?.id
  );
  const [brfPremises, brfPremisesLoading] = useBrfPremises(
    inspectionErrand?.brf_premis?.id
  );
  const [parkingSpot, parkingSpotLoading] = useParkingSpot(
    inspectionErrand?.parking_spot?.id
  );

  const currentPremises =
    apartment || industrialPremises || commonArea || brfPremises || parkingSpot;

  const key = inspectionErrand?.apartment
    ? "apartments"
    : inspectionErrand?.industrial_premises
    ? "industrial_premises_list"
    : inspectionErrand?.common_area
    ? "common_areas"
    : inspectionErrand?.brf_premis
    ? "brf_premises"
    : "parking_spot";

  const componentQ = buildQueryString({
    [key]: currentPremises?.id || "-1",
  });
  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const filteredComponents = components?.filter((c) => {
    const currentProductUsage = getCurrentProductTypeUsage(c);
    const componentTypeId =
      currentProductUsage?.product_type?.component_type?.id;

    if (excludeComponentTypeIds?.includes(componentTypeId)) {
      return false;
    }
    return true;
  });

  const checklistIds = React.useMemo(() => {
    // product type checklist has precedence over component type
    const ids = filteredComponents?.reduce((acc, cur) => {
      const checklistId = getInspectionErrandChecklistIdForComponent(cur);

      if (checklistId) {
        return [...acc, checklistId];
      } else {
        return acc;
      }
    }, []);

    return ids;
  }, [filteredComponents]);

  const checklistQ = buildQueryString({
    id__in: checklistIds || [],
  });

  const [checklists, checklistsLoading] = useFilteredChecklists(checklistQ);

  const checklistRowQ = buildQueryString({
    check_list: handleExecutionChecklist?.checklistId || -1,
    hide_for_non_tenants: false,
  });

  const [checklistRows, checklistRowsLoading] =
    useFilteredChecklistRows(checklistRowQ);

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components: filteredComponents });
  }, [filteredComponents]);

  const handleOnRate = ({ component, isOk }) => {
    const compId = component.id;

    const connected = executionRatings?.find(
      (e) => e.component_placement?.id == compId
    );

    if (connected == null || connected < 0) {
      dispatch(
        create({
          forceData: {
            id: compId,
            component_placement: {
              id: compId,
            },
            inspection_errand: { id: inspectionErrandId },
            is_ok: isOk,
          },
          successCallback: (_, returnedData) => {
            if (!isOk) {
              setHandleExecutionRatingId(returnedData.id);
            }
          },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: connected,
        })
      );
      setHandleExecutionRatingId(connected.id);
    }
  };

  const handleOnChecklistRate = ({ component, checklistId }) => {
    setHandleExecutionChecklist({ component, checklistId });
  };

  const markAllAsOk = () => {
    if (!filteredComponents?.length) return;

    setLoadingSetAllOK(true);
    setLoadingSetOkStart(moment());

    const executionRatingsClone = cloneDeep(executionRatings || []);
    const newRatings = [];

    try {
      for (let i = 0; i < filteredComponents.length; i++) {
        const comp = filteredComponents[i];

        const existing = executionRatingsClone?.find(
          (e) => e.component_placement?.id === comp.id
        );

        if (existing) continue;

        newRatings.push({
          component_placement: comp,
          is_ok: true,
          inspection_errand: {
            id: inspectionErrandId,
          },
        });
      }

      dispatch(
        updateInspectionErrand({
          id: inspectionErrandId,
          forceData: {
            execution_ratings: [
              ...(newRatings ?? []),
              ...(executionRatingsClone ?? []),
            ],
          },
          successCallback: (_, returned) => {
            setLoadingSetAllOK(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Alla komponenter markerades som godkända",
              })
            );

            dispatch({
              type: inspectionContants.RESET_STATE,
            });
            dispatch({
              type: constants.RESET_STATE,
            });
          },
          errorCallback: () => {
            setLoadingSetAllOK(false);

            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Något gick fel, försök igen.",
                description: `Alla komponenter kunde inte godkännas`,
              })
            );
          },
        })
      );
    } catch (e) {
      setLoadingSetAllOK(false);

      console.log(e);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel, försök igen.",
          description: `Alla komponenter kunde inte godkännas`,
        })
      );
    }
  };

  const handleNotOkChecklistRow = (row) => {
    const id = row.id;

    let okClone = cloneDeep(currentOkChecklistRows || []);
    let notOkClone = cloneDeep(currentNotOkChecklistRows || []);

    if (okClone.includes(id)) {
      okClone = okClone.filter((n) => n !== id);
    }

    if (!notOkClone.includes(id)) {
      notOkClone.push(id);
    }

    setCurrentNotOkChecklistRows(notOkClone);
    setCurrentOkChecklistRows(okClone);
  };
  const handleOkChecklistRow = (row) => {
    const id = row.id;

    let okClone = cloneDeep(currentOkChecklistRows || []);
    let notOkClone = cloneDeep(currentNotOkChecklistRows || []);

    if (notOkClone.includes(id)) {
      notOkClone = notOkClone.filter((n) => n !== id);
    }

    if (!okClone.includes(id)) {
      okClone.push(id);
    }

    setCurrentNotOkChecklistRows(notOkClone);
    setCurrentOkChecklistRows(okClone);
  };

  const handleClearChecklistRow = (row) => {
    const id = row.id;

    let okClone = cloneDeep(currentOkChecklistRows || []);
    let notOkClone = cloneDeep(currentNotOkChecklistRows || []);

    notOkClone = notOkClone.filter((n) => n !== id);
    okClone = okClone.filter((n) => n !== id);

    setCurrentNotOkChecklistRows(notOkClone);
    setCurrentOkChecklistRows(okClone);
  };

  const handleChecklistFinished = () => {
    // check if component already rated
    const currentComponent = handleExecutionChecklist.component;
    const connected = executionRatings?.find(
      (e) => e.component_placement?.id == currentComponent.id
    );

    if (connected?.id) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ok_check_list_rows: currentOkChecklistRows?.map((c) => ({
              id: c,
            })),
            not_ok_check_list_rows: currentNotOkChecklistRows?.map((c) => ({
              id: c,
            })),
            is_ok: currentNotOkChecklistRows?.length > 0 ? false : true,
          },
        })
      );

      setHandleExecutionRatingId(connected.id);

      setCurrentNotOkChecklistRows([]);
      setCurrentOkChecklistRows([]);
      setHandleExecutionChecklist(null);

      dispatch(
        update({
          id: connected.id,
        })
      );
    } else {
      const newRating = {
        not_ok_check_list_rows: currentNotOkChecklistRows?.map((c) => ({
          id: c,
        })),
        ok_check_list_rows: currentOkChecklistRows?.map((c) => ({ id: c })),
        is_ok: currentNotOkChecklistRows?.length > 0 ? false : true,
        component_placement: handleExecutionChecklist.component,
      };

      dispatch(
        create({
          forceData: {
            ...newRating,
            inspection_errand: {
              id: inspectionErrandId,
            },
          },
          successCallback: (_, returnedData) => {
            setCurrentNotOkChecklistRows([]);
            setCurrentOkChecklistRows([]);
            setHandleExecutionChecklist(null);
            setHandleExecutionRatingId(returnedData.id);
          },
        })
      );
    }
  };

  const clearRating = ({ component }) => {
    const connected = executionRatings?.find(
      (e) => e.component_placement?.id == component.id
    );

    // never delete saved rating, only edit
    if (connected?.id) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: connected,
        })
      );
      // open checklist if checklist
      const checkl = getInspectionErrandChecklistIdForComponent(component);
      if (checkl) {
        handleOnChecklistRate({ component, checklistId: checkl });
      } else {
        setHandleExecutionRatingId(connected.id);
      }
      return;
    }
  };

  // fake progress indicator :)
  const getLoadedCount = () => {
    const diff = Math.round(-loadingSetOkStart.diff(moment()) / 100 / 2);

    setFakeLoadedCount(diff > components.length ? components.length : diff);

    if (loadingSetAllOK) {
      setTimeout(() => {
        getLoadedCount();
      }, 500);
    }
  };

  React.useEffect(() => {
    let interval;
    if (loadingSetAllOK) {
      interval = setInterval(getLoadedCount, 500);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [loadingSetAllOK]);

  return (
    <>
      {loadingSetAllOK && components && (
        <StandardModal
          isOpen={true}
          title={" Godkänner alla komponenter, detta kan ta lite tid..."}
        >
          <OverlaySpinnerLoadingBar
            currentCount={fakeLoadedCount}
            totalCount={components.length}
          />
        </StandardModal>
      )}

      <StandardModal
        isOpen={handleExecutionRatingId != null}
        title="Besikta komponent"
        closeFunction={() => setHandleExecutionRatingId(null)}
        withActionBar
        actionBarCancelTitle="Avbryt"
        saveFunction={() => {
          dispatch(
            update({
              id: handleExecutionRatingId,
              successCallback: () => setHandleExecutionRatingId(null),
            })
          );
        }}
      >
        <RadioGroup
          overrideInstructions={{ _readOnly: false }}
          storeName={storeName}
          method={"PATCH"}
          defaultValue={null}
          overrideInst
          options={[
            { label: "Godkänd", value: true },
            { label: "Ej godkänd", value: false },
          ]}
          title="Resultat av besiktning"
          fieldKey={`is_ok`}
        />

        <TextField
          overrideInstructions={{ _readOnly: false }}
          storeName={storeName}
          fieldKey={`note`}
          method={"PATCH"}
          title={"Kommentar"}
        />

        <MediaField
          storeName={storeName}
          overrideInstructions={{ _readOnly: false }}
          method={"PATCH"}
          fieldKey={`file`}
          allowedFormats={[".png", ".jpg", ".jpeg"]}
          title="Bild"
          fileKey="file"
        />
      </StandardModal>

      <StandardModal
        title="Gå igenom checklista för komponent"
        withActionBar
        actionBarAcceptTitle="Klar"
        isOpen={handleExecutionChecklist != null}
        closeFunction={() => setHandleExecutionChecklist(null)}
        saveFunction={handleChecklistFinished}
        canAccept={
          (currentOkChecklistRows?.length || 0) +
            (currentNotOkChecklistRows?.length || 0) ===
          (checklistRows?.length || 0)
        }
      >
        {checklistRowsLoading && <OverlaySpinner />}
        {checklistRows?.map((cr) => {
          return (
            <InnerBox key={cr.id} style={{ marginBottom: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ maxWidth: "80%" }}>
                  <div style={{ fontWeight: 500 }}>{cr.title}</div>
                  <div
                    style={{
                      fontSize: theme.fontSizes.headerXSmall,
                      fontSize: theme.fontSizes.headerXSmall,
                    }}
                  >
                    {cr.description}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!currentNotOkChecklistRows.includes(cr.id) && (
                    <ExecuteIconContainer
                      onClick={() => handleOkChecklistRow(cr)}
                      style={{
                        backgroundColor: currentOkChecklistRows.includes(cr.id)
                          ? theme.colors.greenLight
                          : "#fff",
                      }}
                    >
                      <FiThumbsUp
                        color={theme.colors.greenDark}
                        size={theme.fontSizes.headerSmall}
                      />
                    </ExecuteIconContainer>
                  )}

                  {!currentOkChecklistRows.includes(cr.id) && (
                    <ExecuteIconContainer
                      onClick={() => handleNotOkChecklistRow(cr)}
                      style={{
                        marginLeft: 6,
                        backgroundColor: currentNotOkChecklistRows.includes(
                          cr.id
                        )
                          ? theme.colors.redLight
                          : "#fff",
                      }}
                    >
                      <FiThumbsDown
                        color={theme.colors.red}
                        size={theme.fontSizes.headerSmall}
                      />
                    </ExecuteIconContainer>
                  )}

                  {(currentNotOkChecklistRows.includes(cr.id) ||
                    currentOkChecklistRows.includes(cr.id)) && (
                    <ExecuteIconContainer
                      onClick={() => handleClearChecklistRow(cr)}
                      style={{
                        marginLeft: 6,
                        height: 32,
                        width: 32,
                        backgroundColor: "#fff",
                      }}
                    >
                      <FiX
                        color={theme.colors.graphite}
                        size={theme.fontSizes.headerXSmall}
                      />
                    </ExecuteIconContainer>
                  )}
                </div>
              </div>

              {cr?.files?.length > 0 && (
                <>
                  <FilesContainer>
                    <div
                      style={{
                        fontSize: theme.fontSizes.headerSmall,
                        marginBottom: 0,
                      }}
                    >
                      Filer
                    </div>
                    <div
                      style={{
                        marginBottom: 12,
                        fontSize: theme.fontSizes.headerXSmall,
                      }}
                    >
                      Klicka på en fil för att öppna den
                    </div>

                    {cr.files?.map((file) => {
                      const fileType = file.str_representation?.split(".")[1];
                      const fileName = file.str_representation?.split(".")[0];

                      return (
                        <FileContainer
                          key={file.id}
                          onClick={() =>
                            window.open(file?.file?.get, "_blank").focus()
                          }
                        >
                          {ICON_MAPPER[fileType]}
                          <div>
                            <FileType>{FILE_TYPE_MAPPER[fileType]}</FileType>
                            <FileName>{fileName}</FileName>
                          </div>

                          <FileTypeDisplay>{fileType}</FileTypeDisplay>
                        </FileContainer>
                      );
                    })}
                  </FilesContainer>
                </>
              )}
            </InnerBox>
          );
        })}
      </StandardModal>

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Besiktning av komponenter</OverviewTitle>
          <OverviewSubtitle>
            Klicka på en komponent för att ge den ett omdöme
          </OverviewSubtitle>

          <OverviewSubtitle>
            <TextButton
              title={
                loadingSetAllOK
                  ? "Laddar..."
                  : "Eller tryck här för att markera alla komponenter som godkända"
              }
              disabled={loadingSetAllOK}
              iconType="done"
              iconPlacement="right"
              clicked={() => markAllAsOk()}
            />
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {Object.keys(componentsByRoom)?.length > 0 && (
        <RoomOverview
          isNonRoomType={!!parkingSpot}
          contentKey={!!parkingSpot ? "parking_spot" : null}
          contentId={parkingSpot?.id} // only parking spot is nonroomtype for inspection errands
          rooms={componentsByRoom}
          handleEditRoom={() => {}}
          ratings={executionRatings}
          showRatings
          isExecute
          onRate={handleOnRate}
          onChecklistRate={handleOnChecklistRate}
          checklists={checklists}
          clearRating={clearRating}
        />
      )}
    </>
  );
};
