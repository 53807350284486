import * as React from "react";
import { useHistory } from "react-router";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import * as SharedStyles from "../../../components/sharedStyles";
import { useAllPermissionCheck } from "../../../store/base";


import PageTitle from "../../Layouts/PageTitle/PageTitle";

export default () => {
  const { push } = useHistory();


  const canEditConfigCenter = useAllPermissionCheck([
    "view_can_configcenter",
    "change_can_configcenter",
  ]);

  const goToEdit = () => {
    push(`/configcenter/general/sidebar/edit/`);
  };

  const breadCrumbs = [
    {
      label: "Sidomeny",
      url: "/configcenter/general/sidebar",
    },
  ];

  let actions = [];

  if (canEditConfigCenter)
    actions.push({
      name: "Redigera",
      onClick: () => goToEdit(),
    });  
    
  const showPortfolio = localStorage.getItem("sidebar__hidden__portfolio") == "true" ? true : false

  return (
    <SharedStyles.BareLayoutWrapper fillWidth>
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="Sidomeny" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Sidomeny</OverviewTitle>
          {actions?.length > 0 && (
            <PopupButton title="Hantera" actions={actions} />
          )}
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox style={{ marginBottom: 12 }}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Hantera länkar i sidomenyn
              </OverviewTitle>
              <OverviewSubtitle>
              Här kan du dölja/visa vissa länkar i sidomenyn
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <SharedStyles.InnerBox>
              <>
                <strong>
                Portfölj: {showPortfolio ? "Visas": "Dold"}
                </strong>
              </>
          </SharedStyles.InnerBox>
        </SharedStyles.InnerBox>

      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
