import * as React from "react";
import { useHistory, useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PopupButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import CustomDimensionForm from "../../../components/Forms/InvoicingCustomDimension/ChapterForm/ModalForm";
import CustomDimensionEntryForm from "../../../components/Forms/InvoicingCustomDimensionEntry/ChapterForm/ModalForm";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import {
  useInvoicingCustomDimension,
  constants,
} from "../../../store/invoicingCustomDimensions";
import EntriesTable from "../../../components/Tables/InvoicingCustomDimensionEntries/FullTable";
import { buildQueryString } from "../../../store/base";

export default function InvoicingCustomDimension() {
  const { customDimensionId } = useParams();

  const [editOpen, setEditOpen] = React.useState(false);
  const [addEntryOpen, setAddEntryOpen] = React.useState(false);
  const [customDimension] = useInvoicingCustomDimension(customDimensionId);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const { replace } = useHistory();

  const persistantQuery = {
    dimension: customDimensionId,
  };
  const persistantFilterMethod = (obj) =>
    obj.dimension?.id == customDimensionId;

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={customDimension}
        constants={constants}
        deletedCallback={() =>
          replace("/rental-notifications/invoicing-objects")
        }
      />

      <CustomDimensionForm
        method="PATCH"
        id={customDimensionId}
        instance={customDimension}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <CustomDimensionEntryForm
        method="POST"
        customDimension={customDimension}
        instance={{ dimension: { id: customDimensionId } }}
        isOpen={addEntryOpen}
        onCheckout={() => setAddEntryOpen(false)}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Anpassad dimension ${
            customDimension?.str_representation || "Laddar..."
          }`}
          breadCrumbs={[
            {
              url: "/rental-notifications/invoicing-objects",
              label: "Produkter & Bokföring",
            },
            {
              label: `Anpassad dimension ${
                customDimension?.str_representation || "Laddar..."
              }`,
            },
          ]}
        />

        <DetailInnerWrapper>
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>
                <OverviewSubtitle>
                  <TextButton
                    title={`Lägg till ${customDimension?.title || ""}`}
                    iconType="add"
                    iconPlacement="right"
                    clicked={() => setAddEntryOpen(true)}
                  />
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>

              <PopupButton
                leftAligned
                title="Hantera"
                actions={[
                  {
                    name: "Redigera",
                    onClick: () => setEditOpen(true),
                  },
                  {
                    name: "Radera",
                    isDelete: true,
                    onClick: () => setDeleteOpen(true),
                  },
                ]}
              />
            </OverviewTitleWrapper>

            <InnerBox>
              <OverviewTitleWrapper style={{ marginBottom: 12 }}>
                <OverviewTitle small>Instanser av dimension</OverviewTitle>
              </OverviewTitleWrapper>
              <EntriesTable {...{ persistantQuery }} />
            </InnerBox>
          </DetailPageBox>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
