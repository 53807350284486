import * as React from "react";
import { useParams } from "react-router-dom";

import LoanForm from "../../../components/Forms/Loan/FlowForm/FlowForm";

export default () => {
  const { brfCompanyId, id } = useParams();

  return <LoanForm method="PATCH" id={id} brfCompanyId={brfCompanyId}/>;
};
