import * as React from "react";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

import * as SC from "./styles";
import { PrimaryButton, SecondaryButton, TextButton } from "../Buttons";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

import { useFilePicker } from "../../../../hooks/useFilePicker";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import PremisesTable from "../../../PremisesTable/PremisesTable";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";

const MAX_FILE_SIZE = 100;
const ALLOWED_FORMATS = [".pdf"];

export default function TableFileM2m({
  storeName,
  method,
  fieldKey,
  instructionsKey,
  fileKey,
  allowedFileFormats,
  overrideInstructions,
  title,
  description,
  extraStyles = {},
  onChangeCallback,
  disabled,
  onMergeWithFile
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [askMerging, setAskMerging] = React.useState(null);

  const { files, onClick, errors, HiddenFileInput } = useFilePicker({
    maxFileSize: MAX_FILE_SIZE,
  });

  const actualFileKey = fileKey || "pdf";

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || overrideInstructions;
  const value = useFormInstanceField({ storeName, fieldKey });
  let error = useFormError({ storeName, fieldKey });

  if (error?.constructor === Object && error?.non_field_errors) {
    error = error.non_field_errors;
  }

  const fileFormats = allowedFileFormats || ALLOWED_FORMATS;

  React.useEffect(() => {
    handleChange();
  }, [files]);

  const removeFile = (id) => {
    let valueCopy = cloneDeep(value);

    valueCopy = valueCopy.filter((v) => v.id !== id && v._referenceId !== id);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [fieldKey]: valueCopy },
      })
    );

    onChangeCallback && onChangeCallback(valueCopy);
  };

  const handleChange = async () => {
    if (files.length === 0) {
      return;
    } else {
      setLoading(true);

      let newFiles = [];
      if (value?.length) {
        newFiles = [...value];
      }

      for (let i = 0; i < files.length; i++) {
        const restFile = await toBase64(files[i]);
        newFiles.push(restFile);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: newFiles },
        })
      );
      setLoading(false);

      onChangeCallback && onChangeCallback(newFiles);
    }
  };

  const toBase64 = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileData = {
          [actualFileKey]: file.name,
          _tempData: {
            file_name: file.name,
            file_size: file.size,
            data: reader.result.toString(),
          },
          _referenceId: `${file.name}&${file.size}&${Math.floor(
            Math.random() * 100
          )}`,
        };

        resolve(fileData);
      };
    });
  };

  const openFile = (file) => {
    // existing file
    if (file?.[actualFileKey]?.get) {
      window.open(
        file?.[actualFileKey]?.get,
        "",
        `height=${window.screen.height || window.innerHeight},width=${
          window.screen.width || window.innerWidth
        }`
      );
    } else {
      const blob = base64toBlob(file._tempData.data.split("base64,")[1]);
      const blobUrl = URL.createObjectURL(blob);
      window.open(
        blobUrl,
        "",
        `height=${window.screen.height || window.innerHeight},width=${
          window.screen.width || window.innerWidth
        }`
      );
    }
  };

  if (!instructions) {
    return null;
  }
  if (instructions?._readOnly) {
    return null;
  }

  const headers = onMergeWithFile == null ? ["Filnamn", "Visa", "Ta bort"] : ["Filnamn", "Visa", "Ta bort", "Sammanfoga med dokument"];
  const rows = onMergeWithFile == null ? 
    (value || []).map((a) => [
      a?.str_representation || a[actualFileKey],
      <TextButton title="Visa fil" clicked={() => openFile(a)} />,
      <TextButton
        title="Ta bort"
        red
        clicked={() => removeFile(a.id || a._referenceId)}
      />,
    ]) :
    (value || []).map((a) => [
      a?.str_representation || a[actualFileKey],
      <TextButton title="Visa fil" clicked={() => openFile(a)} />,
      <TextButton
        title="Ta bort"
        red
        clicked={() => removeFile(a.id || a._referenceId)}
      />,
      <TextButton title="Sammanfoga" clicked={() => setAskMerging(a)} />,
    ])

    const performMerge = async () => {
      const success = await onMergeWithFile(askMerging);
      setAskMerging(null);

      dispatch(addToast({
        title: `Sammanfogning ${success ? "Lyckades" : "Misslyckades"}`,
        type: success ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR
      }))
    }

  return (
    <>
    <ConfirmationModal
    isOpen={askMerging != null}
    title={"Vill du sammanfoga detta dokument?"}
    closeFunction={() => setAskMerging(null)}
    acceptCallback={performMerge}
    />
      <SC.InputSpacing style={{ ...extraStyles }}>
        <SC.InputFieldTitle>
          {title}
          {instructions._required ? "*" : ""}
        </SC.InputFieldTitle>
        {description && (
          <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
        )}
        <SC.InputFieldDescription>
          Tillåtna filformat: {fileFormats.join(", ")}. Maximal storlek:{" "}
          {MAX_FILE_SIZE}mb.
        </SC.InputFieldDescription>

        {loading && <OverlaySpinner />}

        <PremisesTable
          headers={headers}
          rows={rows}
        />

        {!disabled && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <PrimaryButton
              title={
                loading
                  ? "Laddar..."
                  : value && value.length
                  ? "Ladda upp fler filer"
                  : "Ladda upp filer"
              }
              clicked={onClick}
            />
          </div>
        )}

        <HiddenFileInput accept={fileFormats.join(", ")} multiple={true} />
        {errors?.hasInvalidFileSize && (
          <SC.ErrorMessage>
            En eller fler av filerna är för stora (får vara max {MAX_FILE_SIZE}
            mb)
          </SC.ErrorMessage>
        )}
        {errors?.hasInvalidImage && (
          <SC.ErrorMessage>
            En eller fler av filerna är inte tillåtna. Välj en ny i ett tillåtet
            format och storlek.
          </SC.ErrorMessage>
        )}
        {error && <SC.ErrorMessage>{JSON.stringify(error)}</SC.ErrorMessage>}
      </SC.InputSpacing>
    </>
  );
}

export function base64toBlob(base64Data) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: "application/pdf" });
}
