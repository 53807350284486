export const constructStrRep = (obj) => {
  return obj.name;
};
export const detailUrl = ({ id }) => {
  return `/brf-companies/detail/${id}`;
};

export const overviewUrl = () => {
  return `/brf-companies`;
};

export const brfActivateFortnoxUrl = ({ id }) => {
  return `/brf-companies/detail/${id}/activate-fortnox`;
};

export const ku55Url = ({id}) => {
  return `/brf-companies/ku55/${id}`;
}
