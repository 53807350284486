export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ROOM",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ROOM",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ROOM",
  DESTROY_FORM: "DESTROY_FORM_ROOM",
  SET_FORM_ERROR: "SET_FORM_ERROR_ROOM",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ROOM",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ROOM",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ROOM",
  RESET_STATE: "RESET_STATE_ROOM",
  CLEAR_FETCHED: "CLEAR_FETCHED_ROOM",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_ROOM",
  REMOVE_OBJECT: "REMOVE_OBJECT_ROOM",

  LIST_URL: "/standard/lowlevel/room/list/",
  POST_URL: "/standard/lowlevel/room/",
  PATCH_URL: "/standard/lowlevel/room/",
  GET_URL: "/standard/lowlevel/room/",

  STORE_NAME: "rooms",
};
