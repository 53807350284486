import * as React from "react";

export default () => {
  return (
    <div>
      Välj de parter som ska stå med på avtalet.
      <br />
      <br /> Om det är fler än en hyresgäst anger du de ytterligare
      hyresgästerna som "Ytterligare hyresgäster".
      <br />
      <br />
      Observera att valet av hyresvärd kommer sätta hyresvärden på de valda
      hyresobjekten. Om inget hyresobjekt väljs kommer valet att ignoreras.
    </div>
  );
};
