// style, design
import {
  BooleanLabel,
  DateCell,
  LinkedObject,
  ToolTipCell,
} from "../../Displays";
import * as React from "react";

import { detailUrl as productDetailUrl } from "../../../store/invoicingProducts";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { toMoneyString } from "../../utils/stringUtils";

export default () => [
  {
    Header: "",
    id: "__expander",
    Cell: ({ row }) => {
      return (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <span>▼</span> : <span>{">"}</span>}
        </span>
      );
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Titel",
    accessor: "title",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Är aktiv",
    accessor: "is_active",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Är huvudavgift",
    accessor: "is_main_fee",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Årlig avgift",
    accessor: "yearly_fee",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Total avgift att betala",
    accessor: "total_fee_to_pay",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Avbetalt den",
    accessor: "payoff_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Automatisk räntepålägg",
    accessor: "auto_add_loan_interest_to_fee",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "För alla lägenheter",
    accessor: "for_all_brf_premises",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Startdatum",
    accessor: "start_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Avsluta debitering för klar-betalade",
    accessor: "close_for_fully_payed_premis",
    Cell: ({ value, row }) => {
      return (
        <BooleanLabel
          value={value && row.original.total_fee_to_pay != null}
          offLabel={"Nej"}
          onLabel={"Ja"}
        />
      );
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Debitering tillför till räntebetalningar",
    accessor: "fee_contributes_to_interest_fees",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Alla avgifter är kapitaltillskottsgrundande",
    accessor: "all_payments_loan_capital_contribution",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Distribuera betalning jämnt",
    accessor: "distribute_fee_on_loan_proportionally",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Produkt",
    accessor: "product",
    Cell: ({ value, row }) => {
      return <LinkedObject obj={value} urlMethod={productDetailUrl} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
