import * as React from "react";
import { useParams } from "react-router-dom";

import ContributionForm from "../../../components/Forms/PaymentContributionRecord/FlowForm/FlowForm";

export default () => {
  const { paymentGroupId, brfPremisesId, id } = useParams();

  return <ContributionForm method="PATCH" id={id} paymentGroupId={paymentGroupId} brfPremisesId={brfPremisesId}/>;
};
