import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import { IntegrationCompaniesWrapper } from "../../../components/Layout/Accounts/styles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  buildQueryString,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../../store/base";

import CompanyTable from "../../../components/Tables/ConfigCenter/Companies/FullTable";
import companyListDefs from "../../../components/Tables/ConfigCenter/Companies/listDefs";

import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import StandardModal from "../../../components/Modals/StandardModal";

import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";

import {
  constants as companyConstants,
  useCompanyPagination,
  useFilteredCompanies,
  performFilter as performCompanyFilter,
} from "../../../store/companies";

import { useDispatch, useSelector } from "react-redux";
import { bulkGetIntegrationSettings } from "../../../store/billectaIntegrations/store/actions";
import { useHistory } from "react-router-dom";
import { uid } from "uid";
import { useFilteredBrfCompanies } from "../../../store/brfCompanies";

export default function FortnoxAccountingIntegration() {
  const current = INTEGRATIONS.find((i) => i.title === "Fortnox bokföring");
  const [pickCompaniesOpen, setPickCompaniesOpen] = React.useState(false);
  const [addCompaniesLoading, setAddCompaniesLoading] = React.useState(false);

  const { hasBillectaViewPermission } = useFrequentPermissions();
  const integrationName = "Fortnox";
  const dispatch = useDispatch();
  const [companies, companiesLoading] = useFilteredCompanies(
    buildQueryString({ billecta_id__isnull: false })
  );

  const [brfCompanies] = useFilteredBrfCompanies("");

  const withCompanyInvoicingQuery = {
    billecta_id__isnull: false,
  };

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const { push } = useHistory();

  const fortnoxSettings = useSelector(
    (state) => state.billectaIntegrations.fortnoxSettings
  );

  const handleRenewFortNoxConnection = ({ companyId, fortnoxSetting }) => {
    const state = uid();

    const returnUrl = `https://${window.location.hostname}/configcenter/integrations/activate-fortnox-complete/company/${companyId}`;

    const voucherSeries = fortnoxSetting?.VoucherSeries;
    const startDate = fortnoxSetting?.StartDate;

    if (!startDate || !voucherSeries) {
      push(`activate-fortnox/company/${companyId}`);

      return;
    }

    localStorage.setItem("fnx_state", state);
    localStorage.setItem("fnx_voucher_series", voucherSeries);
    localStorage.setItem("fnx_start_date", startDate);
    localStorage.setItem("fnx_is_update", true);
    localStorage.setItem("fnx_redirect_uri", returnUrl);

    window.location.href = `https://apps.fortnox.se/oauth-v1/auth?client_id=32hsGLkR78Av&scope=settings%20project%20price%20bookkeeping%20costcenter&state=${state}&access_type=offline&response_type=code&redirect_uri=${returnUrl}`;
  };

  const onCompanySelected = (original) => {
    setPickCompaniesOpen(false);

    const setting = fortnoxSettings?.[original.id];
    const connectedBrfCompany = brfCompanies?.find(
      (brfC) => brfC.company?.id === original.id
    );

    if (setting) {
      handleRenewFortNoxConnection({
        companyId: original?.id,
        fortnoxSetting: setting,
        brfCompanyId: connectedBrfCompany?.id,
      });
    } else {
      push(`activate-fortnox/company/${original.id}`);
    }
  };

  const companyColumns = React.useMemo(
    () =>
      companyListDefs(
        hasBillectaViewPermission,
        fortnoxSettings,
        integrationName
      ),
    [withCompanyInvoicingQuery, hasBillectaViewPermission, fortnoxSettings]
  );

  React.useEffect(() => {
    if (companies != null && !companiesLoading) {
      dispatch(
        bulkGetIntegrationSettings({
          companies,
          integrationSetting: "fortnoxSettings",
        })
      );
    }
  }, [companiesLoading]);

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper config={true}>
            <DetailPageBox
              style={{ flex: 1, width: "100%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Fortnox hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Fortnox-integrationen bokför automatiskt händelser i Pigellos
                  hyresaviseringsmodul mot Fortnox.
                  <br />
                  <br />
                  Integrationen aktiveras under bolagsinställningarna på
                  respektive bolag
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <IntegrationCompaniesWrapper>
              <DetailPageBox
                style={{ flex: 1, marginRight: "5px", alignSelf: "flex-start" }}
              >
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Hantera integration</OverviewTitle>
                    <OverviewSubtitle>
                      Välj vilket bolag integrationen ska hanteras för
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                  <PrimaryButton
                    extraStyle={{ width: "30%", alignSelf: "end" }}
                    newDesign
                    clicked={() => setPickCompaniesOpen(true)}
                    title="Hantera"
                  />
                </OverviewTitleWrapper>

                <CompanyTable
                  {...{
                    persistantQuery: withCompanyInvoicingQuery,
                    isBare: true,
                    ignoreLocalStorage: true,
                    integrationSettings: fortnoxSettings,
                    integrationName: integrationName,
                  }}
                />
              </DetailPageBox>
            </IntegrationCompaniesWrapper>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
        {/* Opens on clicking "Hantera" on Company Table, pick company to manage integration */}
        <StandardModal
          isOpen={pickCompaniesOpen}
          closeFunction={() => setPickCompaniesOpen(false)}
          withActionBar
          title="Hantera integration för Bolag"
        >
          {addCompaniesLoading && <OverlaySpinner />}
          <BaseTable
            constants={companyConstants}
            columns={companyColumns}
            persistantQuery={withCompanyInvoicingQuery}
            {...{
              onRowClicked: onCompanySelected,
            }}
          />
        </StandardModal>
      </DetailLayoutWrapper>
    </>
  );
}
