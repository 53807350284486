import * as React from "react";

import { Sendout, Confirm, Recipients, Settings } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "SENDOUT":
      return <Sendout method={method} />;
    case "SETTINGS":
      return <Settings method={method} />;
    case "RECIPIENTS":
      return <Recipients method={method} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
