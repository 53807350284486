import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";

import LoanTable from "../../../components/Tables/Loan/FullTable";

import { usePaymentGroup } from "../../../store/paymentGroup";
import { createUrl as loanCreateUrl } from "../../../store/loan";

import { TextButton } from "../../../components/Forms/Base/Buttons";

export default function PaymentGroupLoans() {
  const { paymentGroupId } = useParams();
  const { push } = useHistory();

  const [paymentGroup, paymentGroupLoading] = usePaymentGroup(paymentGroupId);
  const brfCompanyId = paymentGroup?.brf_company?.id;

  const canAddLoan = useAllPermissionCheck(["add_can_brf"]);

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "100%",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Lån</OverviewTitle>
                {canAddLoan && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() =>
                        push(loanCreateUrl(brfCompanyId, paymentGroupId))
                      }
                    />
                  </div>
                )}
              </OverviewTitleWrapper>

              <LoanTable
                persistantQuery={{
                  payment_group: paymentGroupId,
                }}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
