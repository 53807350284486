import * as React from "react";
import { useParams } from "react-router-dom";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useExternalSyncErrorPaginationCount } from "../../../store/externalSyncErrors";
import { useMarketingRequirement } from "../../../store/marketRequirements";
import SyncErrorTable, {
  SYNC_ERROR_MAP,
} from "../../../components/Tables/ExternalSyncErrors/FullTable";
import { buildQueryString } from "../../../store/base";

export default function RequirementOverview() {
  const { requirementId } = useParams();
  const [requirement] = useMarketingRequirement(requirementId);

  const [errorCount] = useExternalSyncErrorPaginationCount({
    filters: {
      requirement: requirementId,
      api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
    },
  });
  const hasSyncError = errorCount > 0;

  const errorQuery = {
    requirement: requirementId,
    api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
  };

  return (
    <DetailInnerWrapper>
      {hasSyncError && (
        <DetailPageBox error>
          <SyncErrorTable
            {...{
              persistantQuery: errorQuery,
            }}
          />
        </DetailPageBox>
      )}
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <DetailInfo infoObj={getInfoObj({ requirement })} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

function getInfoObj({ requirement }) {
  return {
    Kravprofil: [
      {
        title: "Namn",
        value: requirement?.name,
      },
      {
        title: "Typ av inkomstkrav",
        value: requirement?.rent_calculation_type_display,
      },
      {
        title: "Inkomstkrav X hyran",
        value: requirement?.rent_factor,
        hidden: requirement?.rent_calculation_type !== 0,
      },
      {
        title: "Inkomstkrav X hyran",
        value: requirement?.rent_factor,
        hidden: requirement?.rent_calculation_type !== 0,
      },
      {
        title: "Inkomst efter hyra",
        value: requirement?.living_standard_factor,
        hidden: requirement?.rent_calculation_type !== 1,
      },
      {
        title: "Sökande med borgensman tillåts",
        value: requirement?.warrantor ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Inkomstkrav X hyran borgensman",
        value: requirement?.warrantor_rent_factor,
        hidden: !requirement?.warrantor,
      },
    ],
    Anställningstyper: [
      {
        title: "Fast anställning",
        value: requirement?.employment_permanent ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Visstidsanställning",
        value: requirement?.employment_temporary ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
    ],
    Inkomsttyper: [
      {
        title: "Fast inkomst",
        value: requirement?.income_fixed ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Inkomst från visstidsanställning",
        value: requirement?.income_variable ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Inkomst från studiebidrag",
        value: requirement?.income_student ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Inkomst från bostadsbidrag",
        value: requirement?.income_housing_support ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Inkomst från bostadstillägg",
        value: requirement?.income_housing_supplement ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Inkomst från försörjningsstöd",
        value: requirement?.income_living_expense_supplement ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
    ],
    Speciallägenhet: [
      {
        title: "Seniorboende",
        value: requirement?.senior ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Lägsta ålder",
        value: requirement?.senior_age,
        hidden: !requirement?.senior,
      },
      {
        title: "Ungdomsboende",
        value: requirement?.youth ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Lägsta ålder",
        value: requirement?.youth_age,
        hidden: !requirement?.youth,
      },
      {
        title: "Studentboende",
        value: requirement?.student,
        hidden: requirement?.student ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Investeringsstödsboende",
        value: requirement?.benefit_apartment,
        hidden: requirement?.benefit_apartment ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
    ],
    Kredit: [
      {
        title: "Max antal betalningsanmärkningar",
        value: requirement?.payment_remarks,
      },
      {
        title: "Tillåt sökande med skuldsaldo",

        value: requirement?.outstanding_debt ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Tillåt sökande med skuldsanering",

        value: requirement?.debt_sanitization ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Tillåt sökande med personlig konkurs",

        value: requirement?.bankruptcy ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
    ],
    Övrigt: [
      {
        title: "Rökning tillåten",
        value: requirement?.smoking ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Husdjur tillåtna",
        value: requirement?.pets ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Max flyttar senaste 3 åren",
        value: requirement?.move_frequency,
      },
      {
        title: "Max antal extra hyresgäster ",
        value: requirement?.extra_residents,
      },
    ],
  };
}
