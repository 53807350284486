const defs = [
  {
    title: "Välj mätartyp",
    key: "MEASURE_TYPE",
    visited: true,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Välj fil",
    key: "PICK_FILE",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Inställningar",
    key: "IMPORT_SETTINGS",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Kolumner",
    key: "COLUMN_SETTINGS",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default ({} = {}) => {
  return defs;
};
