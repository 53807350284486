import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import LeaseContractWithCostsTable from "../../../components/Tables/LeaseContractsWithCosts/FullTableNewVersionRework";

import useActiveFilters from "../../../hooks/useActiveFilters";

export default function BlockLeaseContracts() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantBlockQuery = {
    realestate__in: filteredRealEstates,
    draft: false,
    block__isnull: false,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Blockavtal</OverviewTitle>
          </OverviewTitleWrapper>

          <LeaseContractWithCostsTable
            persistantQuery={persistantBlockQuery}
            withCosts
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
