import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useTodoContractStatistics } from "../../../store/overview";

import LeaseContractsTable from "../../../components/Tables/LeaseContracts/FullTableNewVersion";
import OtherContractsTable from "../../../components/Tables/OtherContracts/FullTableNewVersion";
import ParkingContractsTable from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersion";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const READABLE_TYPES = {
  cancelled_unconfirmed: "Uppsagda men ej bekräftade",
  not_billed_for: "Aviseras ej för",
  not_signed: "Ej signerade",
  warning: "Utgående avtal",
};

const READABLE_CONTENT_TYPES = {
  "standard.othercontract": "Övriga avtal",
  "standard.parkingcontract": "Parkeringsavtal",
  "standard.leasecontract": "Hyresavtal",
};

const TABLE_MAP = {
  "standard.leasecontract": LeaseContractsTable,
  "standard.othercontract": OtherContractsTable,
  "standard.parkingcontract": ParkingContractsTable,
};

export default function ContractsTodo() {
  const [openTable, setOpenTable] = React.useState(null);
  const [rawTodoContractStats, contractLoading] = useTodoContractStatistics();

  const filteredStatKeys = Object.keys(rawTodoContractStats?.data || {}).filter(
    (key) => rawTodoContractStats?.data?.[key]?.length > 0
  );

  const byContentType = (data) => {
    const contentTypes = [];

    data.forEach((d) => {
      if (contentTypes.includes(d.content_type)) return;

      contentTypes.push(d.content_type);
    });

    return contentTypes;
  };

  return (
    <DetailInnerWrapper>
      {filteredStatKeys?.length > 0 ? (
        <>
          {filteredStatKeys.map((key) => {
            const data = rawTodoContractStats?.data[key];

            const contentTypes = byContentType(data);

            return (
              <DetailPageBox key={key}>
                <OverviewTitleWrapper>
                  <OverviewTitle>
                    {READABLE_TYPES[key]} ({data?.length})
                  </OverviewTitle>
                </OverviewTitleWrapper>

                {contentTypes?.map((ct) => {
                  const contentTypeData = data.filter(
                    (d) => d.content_type === ct
                  );

                  const TableComp = TABLE_MAP[ct];

                  const ids = contentTypeData?.map((d) => d.id);

                  const persistantQuery = {
                    id__in: ids,
                  };

                  const open = openTable === `${key}${ct}`;

                  return (
                    <InnerBox style={{ marginBottom: 12 }} key={ct}>
                      <OverviewTitleWrapper
                        style={{ marginBottom: open ? 24 : 0 }}
                      >
                        <OverviewTitleWithSubtitleWrapper>
                          <OverviewTitle small>
                            {READABLE_CONTENT_TYPES[ct]} (
                            {contentTypeData?.length})
                          </OverviewTitle>
                          <OverviewSubtitle>
                            {!open
                              ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa avtal`
                              : `Klicka på ett avtal för att komma till detaljsidan`}
                          </OverviewSubtitle>
                        </OverviewTitleWithSubtitleWrapper>

                        <PrimaryButton
                          title={!open ? "Visa detaljer" : "Stäng"}
                          clicked={
                            !open
                              ? () => setOpenTable(`${key}${ct}`)
                              : () => setOpenTable(null)
                          }
                        />
                      </OverviewTitleWrapper>

                      {open && (
                        <TableComp
                          {...{
                            isBare: true,
                            ignoreLocalStorage: true,
                            persistantQuery,
                          }}
                        />
                      )}
                    </InnerBox>
                  );
                })}
              </DetailPageBox>
            );
          })}
        </>
      ) : (
        <DetailPageBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OverviewTitle style={{ padding: "48px 0 " }}>
            {contractLoading ? "Laddar..." : "Inget att hantera"}
          </OverviewTitle>
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
