import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { usePermissionCheck, useAllPermissionCheck } from "../../../store/base";

import { useHistory } from "react-router-dom";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import {
  useSbfCredentials,
  addCredential,
  updateCredential,
  deleteCredential,
} from "../../../store/stockholmsBF";
import { useFilteredCompanies } from "../../../store/companies";
import MenuButton from "../../../components/Forms/Base/Buttons/MenuButton";

import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
const GUIDE_STEPS = {
  INTRO: 1,
  INFO: 2,
};

export default function BostadsformedlingenStockholmIntegration() {
  const dispatch = useDispatch();
  const current = INTEGRATIONS.find(
    (i) => i.title === "Bostadsförmedlingen Stockholm"
  );
  const { push } = useHistory();

  const [activateOpen, setActivateOpen] = React.useState(false);
  const [updateAuthOpen, setUpdateAuthOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [sbfLegalId, setSbfLegalId] = React.useState(false);
  const [sbfPassword, setsbfPassword] = React.useState(false);
  const [activateLoading, setActivateLoading] = React.useState(false);
  const [getStartedOpen, setGetStartedOpen] = React.useState(false);
  const [guideStep, setGuideStep] = React.useState(GUIDE_STEPS.INTRO);
  const [displayedWebhook, setDisplayedWebhook] = React.useState(false);

  const [credentials] = useSbfCredentials();
  const [companies] = useFilteredCompanies("");

  const customerHasSbf = usePermissionCheck("allow_sbf");
  const userHasSbfAndMarket = useAllPermissionCheck([
    "view_can_sbf",
    "view_can_market",
  ]);
  const customerHasMarket = usePermissionCheck("allow_market");
  const hasPerm = customerHasSbf && customerHasMarket;
  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const addCompany = () => {
    setActivateLoading(true);
    dispatch(
      addCredential({
        legalId: sbfLegalId,
        password: sbfPassword,
        successCallback: () => {
          setActivateOpen(false);
          setActivateLoading(false);
          setGetStartedOpen(true);
          setSbfLegalId(null);
          setsbfPassword(null);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Bolaget lades till för integration",
              description:
                "Följ instruktionerna för integrationen för att komma igång",
            })
          );
        },
        errorCallback: () => {
          setActivateLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Tillägg av bolag misslyckades",
              description: "Kontrollera orgnr och lösenord och försök igen",
            })
          );
        },
      })
    );
  };

  const updateCompany = () => {
    setActivateLoading(true);
    dispatch(
      updateCredential({
        id: updateAuthOpen,
        legalId: sbfLegalId,
        password: sbfPassword,
        successCallback: () => {
          setUpdateAuthOpen(false);
          setActivateLoading(false);
          setSbfLegalId(null);
          setsbfPassword(null);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Bolagets integration uppdaterades",
              description:
                "OBS: Tänk på att skicka de nya Webhook-uppgifterna till Bostadsförmedligen Stockholm om ändringarna har lett till en förändring av den (orgnr eller lösenord uppdaterat)",
            })
          );
        },
        errorCallback: () => {
          setActivateLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdatering av bolag misslyckades",
              description: "Kontrollera orgnr och lösenord och försök igen",
            })
          );
        },
      })
    );
  };

  const deleteCompany = () => {
    dispatch(
      deleteCredential({
        id: confirmDeleteOpen,
        successCallback: () => {
          setConfirmDeleteOpen(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Bolagets integration raderades",
            })
          );
        },
        errorCallback: () => {
          setConfirmDeleteOpen(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Radering av integration mot bolag misslyckades",
              description:
                "Försök igen, kontakta Pigello om problement kvarstår",
            })
          );
        },
      })
    );
  };

  const guideStepIntro = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>
            Kom igång med Bostadsförmedlningen Stockholm
          </OverviewTitle>
          <OverviewSubtitle>
            För att komma igång ordentligt med integrationen och se till att
            integrationen fungerar som den ska så rekommenderar Pigello att läsa
            igenom denna information.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <strong>Kom igång med integrationen</strong>
      <ul>
        <li>
          1. Meddela Bostadsförmedlingen Stockholm att integrationen är gjord
          och skicka Webhook-uppgifterna till dem så att de kan börja skicka
          automatiska uppdateringar till Pigello.{" "}
          <strong>
            Du hittar Webhook-uppgifterna under bolagen till höger på
            integrationssidan, tryck på "Visa" för att se och kopiera dem.
          </strong>
        </li>
        <br />

        <li>
          2. Koppla ihop en Pigello-kravprofil med detta bolag. Välj eller skapa
          en kravprofil som överensstämmer så bra som möjligt med de kraven ni
          har i Bostadsförmedlingen Stockholm.
        </li>
        <br />

        <li>3. Integrationen är nu redo att användas för detta bolag!</li>
      </ul>
      <br />
    </>
  );

  const continueGuide = () => {
    switch (guideStep) {
      case GUIDE_STEPS.INTRO:
        push(`/market/requirements`);
        setGetStartedOpen(false);
        break;
    }
  };

  const guideTitles = () => {
    switch (guideStep) {
      case GUIDE_STEPS.INTRO:
        return ["Gå till kravprofiler", "Synka kravprofil senare"];
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmDeleteOpen}
        title="Bekräfta radering"
        renderContent={() => (
          <div>
            Vid inaktivering av integrationen upphör alla försök att synka data
            mot Bostadsförmedlingen Stockholm för detta bolag
          </div>
        )}
        closeFunction={() => setConfirmDeleteOpen(false)}
        acceptCallback={deleteCompany}
      />

      <StandardModal
        isOpen={activateOpen}
        closeFunction={() => {
          setGuideStep(GUIDE_STEPS.INTRO);
          setActivateOpen(false);
        }}
        withActionBar
        actionBarAcceptTitle="Aktivera integration för bolag"
        saveFunction={addCompany}
        canAccept={!!sbfLegalId && !!sbfPassword}
        title="Lägg till bolag för integration"
      >
        {activateLoading && <OverlaySpinner />}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Lägg till bolag för integration</OverviewTitle>
            <OverviewSubtitle>
              Bostadsförmedlingen Stockholm-integrationen aktiveras per bolag ni
              har uppsatt hos dem. Lägg till det bolaget ni vill att
              integrationen ska gälla för. Fler bolag kan läggas till.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={CompanyTable}
            value={companies?.find((c) => c.orgnr == sbfLegalId)}
            required
            fieldTitle="Bolag"
            title="Välj bolag"
            onChange={(val) => setSbfLegalId(val.orgnr)}
          />
        </div>

        <NonConnectedTextInput
          label="Lösenord på Bostadsförmedlningen Stockholm"
          value={sbfPassword}
          type="password"
          id="_sbf_password"
          onChange={(val) => setsbfPassword(val)}
        />
      </StandardModal>

      <StandardModal
        isOpen={updateAuthOpen}
        closeFunction={() => {
          setUpdateAuthOpen(false);
          setSbfLegalId(null);
          setsbfPassword(null);
        }}
        small
        withActionBar
        actionBarAcceptTitle="Uppdatera integration"
        saveFunction={updateCompany}
        canAccept={!!sbfPassword && !!sbfLegalId}
        title="Uppdatera integration"
      >
        {activateLoading && <OverlaySpinner />}
        <NonConnectedTextInput
          label="Orgnummer på bolag"
          value={sbfLegalId}
          id="_sbf_legalid"
          onChange={(val) => setSbfLegalId(val)}
        />
        <NonConnectedTextInput
          label="Lösenord på Bostadsförmedlingen Stockholm"
          value={sbfPassword}
          type="password"
          id="_sbf_password"
          onChange={(val) => setsbfPassword(val)}
        />
      </StandardModal>

      <StandardModal
        isOpen={getStartedOpen}
        closeFunction={() => setGetStartedOpen(false)}
        withActionBar
        title="Kom igång med Bostadsförmedlingen Stockholm"
        saveFunction={continueGuide}
        actionBarAcceptTitle={guideTitles()[0]}
        actionBarCancelTitle={guideTitles()[1]}
      >
        {guideStep === GUIDE_STEPS.INTRO && guideStepIntro}
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Bostadsförmedlningen Stockholms hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Integrationen mot Bostadsförmedlingen Stockholm aktiveras
                  genom att lägga till en koppling mellan de bolagen ni har i
                  Bostadsförmedlingen Stockholm och Pigello. Efter detta kan
                  kravprofiler matchas och annonser publiceras på
                  Bostadsförmedlingen Stockholm via Pigello.
                  <br />
                  <br />
                  Så fungerar integrationen:
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Skapa en annons för en eller flera lägenheter och välj att
                  publicera på Bostadsförmedlingen Stockholm.
                  <br />
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Annonsen publiceras på Bostadsförmedlingen Stockholm och
                  tillåter intressenter att ställa sig i kö/göra intresseanmälan
                  <br />
                  <br />
                  <strong>Steg 3:</strong>
                  <br />
                  Inkomna intressenter hamnar automatiskt i det projektet som är
                  angivet på annonsen
                  <br />
                  <br />
                  <strong>Steg 4:</strong>
                  <br />
                  När en intressent är accepterad som hyresgäst i projektet så
                  stängs annonsen hos Bostadsförmedlingen Stockholm och avtalet
                  signeras enkelt direkt i Pigello.
                </BodyText>
              </InnerBox>
            </DetailPageBox>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                maxWidth: "39%",
                alignSelf: "flex-start",
              }}
            >
              <DetailPageBox>
                {hasPerm !== true ? (
                  <>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Aktivera integration
                        </OverviewTitle>
                        <OverviewSubtitle>
                          Bostadsförmedlingen Stockholm-integrationen aktiveras
                          av Pigello på begäran. Kontakta oss för att veta mer.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>
                    <TextButton
                      title="Kontakta oss"
                      iconType="arrow"
                      iconPlacement="right"
                      clicked={() => window.Intercom("show")}
                    />
                  </>
                ) : !userHasSbfAndMarket ? (
                  <>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>Behörighet saknas</OverviewTitle>
                        <OverviewSubtitle>
                          Din användare saknar behörighet att använda denna
                          integration
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>
                  </>
                ) : credentials?.length === 0 ? (
                  <>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Integration ej konfigurerad
                        </OverviewTitle>
                        <OverviewSubtitle>
                          Konfigurera integrationen genom att lägga till ett
                          bolag som ska integreras.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>

                    <PrimaryBtn onClick={() => setActivateOpen(true)}>
                      Lägg till bolag
                    </PrimaryBtn>
                  </>
                ) : (
                  <>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>Integration aktiv</OverviewTitle>
                        <OverviewSubtitle>
                          Bostadsförmedlingen Stockholm-integrationen är aktiv.
                          Följ instruktionerna för integrationen för att använda
                          den. Tryck på "Lägg till ytterligare bolag" för att
                          lägga till fler integrerade bolag.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>

                    {credentials?.map((credential) => {
                      const connectedCompany = companies?.find(
                        (c) => c.orgnr == credential.legal_id
                      );

                      return (
                        <InnerBox
                          key={credential.id}
                          style={{ marginBottom: 8 }}
                        >
                          <OverviewTitleWrapper>
                            <OverviewTitleWithSubtitleWrapper>
                              <OverviewTitle small>
                                {connectedCompany?.str_representation ||
                                  credential.legal_id}
                              </OverviewTitle>
                              {connectedCompany && (
                                <OverviewSubtitle>
                                  {credential.legal_id}
                                </OverviewSubtitle>
                              )}
                            </OverviewTitleWithSubtitleWrapper>

                            <MenuButton
                              leftAligned
                              actions={[
                                {
                                  name: "Redigera",
                                  onClick: () => {
                                    setUpdateAuthOpen(credential.id);
                                    setSbfLegalId(credential.legal_id);
                                    setsbfPassword(credential.password);
                                  },
                                },
                                {
                                  name: "Radera",
                                  isDelete: true,

                                  onClick: () => {
                                    setConfirmDeleteOpen(credential.id);
                                  },
                                },
                              ]}
                            />
                          </OverviewTitleWrapper>

                          <InnerBox>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ fontSize: 14, fontWeight: 400 }}>
                                Webhook-uppgifter
                              </div>
                              <TextButton
                                title={
                                  displayedWebhook === credential.id
                                    ? "Dölj"
                                    : "Visa"
                                }
                                clicked={
                                  displayedWebhook === credential.id
                                    ? () => setDisplayedWebhook(false)
                                    : () => setDisplayedWebhook(credential.id)
                                }
                              />
                            </div>

                            {displayedWebhook === credential.id ? (
                              <>
                                <hr style={{ margin: "8px 0" }} />{" "}
                                <NonConnectedTextInput
                                  renderHelpText={() => (
                                    <TextButton
                                      title="Kopiera"
                                      iconType="copy"
                                      clicked={() =>
                                        copyToClipBoard(credential.webhook_url)
                                      }
                                    />
                                  )}
                                  value={credential.webhook_url}
                                  disabled
                                  label="URL"
                                />
                                <NonConnectedTextInput
                                  noMargin
                                  renderHelpText={() => (
                                    <TextButton
                                      title="Kopiera"
                                      iconType="copy"
                                      clicked={() =>
                                        copyToClipBoard(
                                          credential.webhook_password
                                        )
                                      }
                                    />
                                  )}
                                  value={credential.webhook_password}
                                  disabled
                                  label="Lösenord"
                                />
                              </>
                            ) : null}
                          </InnerBox>
                        </InnerBox>
                      );
                    })}

                    <PrimaryButton
                      extraStyle={{ marginTop: 24 }}
                      title="Lägg till ytterligare bolag"
                      clicked={() => setActivateOpen(true)}
                    />
                  </>
                )}
              </DetailPageBox>
            </div>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
