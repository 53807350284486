import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, buildQueryString } from "../../base";
import {
  filterPagination,
  createNewReport,
  reRunReport,
  downloadReportFile,
  createReportTemplate,
  updateReportTemplate,
  hotLoadReportTemplate,
  updateReportInAll,
  setHotRefresh,
  setTemplatePathing,
  purgeAll,
} from "../store/actions";
import constants from "../store/constants";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

import { useHistory, useLocation } from "react-router-dom";

import { KWARG_MAP } from "../../../views/NewReports/utils";
import { cloneDeep } from "lodash";

import {
  pathing,
  BASE_PATH,
  DEFAULT_PATHNAME,
  builtTemplates,
} from "../../../views/NewReports/ReportsOverviewTable";

export const useDataHandler = ({
  setIsLoading,
  isLoading,
  setIsSavingAsTemplate,
  isPerformedReport,
  currentTitle,
  currentReportData,
  currentReportStructure,
  setInitialPostData,
  setHasUpdatedAnyData,
  buildPostData,
  setLoadingText,
  loadingText,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const setLoadingOnlyText = (text) => {
    setLoadingText({
      ...loadingText,
      text,
    });
  };

  const patchChangesToTemplate = () => {
    if (isLoading) return;
    setLoadingOnlyText("Uppdaterar mall...");
    setIsLoading(true);
    const fullPostData = buildPostData();

    dispatch(
      updateReportTemplate({
        reportData: currentReportData,
        reportStructure: currentReportStructure,
        postData: fullPostData,
        onSuccess: updatedTemplateSuccess,
        onError: updatedTemplateError,
      })
    );
  };

  const doReport = (format, is_template = false) => {
    if (isLoading) return;
    setIsLoading(true);
    setLoadingOnlyText("Skapar rapport...");

    const fullPostData = buildPostData(format);

    let required = [!(!currentTitle || currentTitle.length === 0)];
    let reqMessages = ["Namnge rapporten"];

    if (required.includes(false)) {
      setIsLoading(false);

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Ett fel är uppstått",
          description: reqMessages[required.indexOf(false)],
        })
      );

      return;
    }

    if (is_template) {
      dispatch(
        createReportTemplate({
          reportData: currentReportData,
          reportStructure: currentReportStructure,
          postData: fullPostData,
          onSuccess: (res, postData) =>
            createNewReportSuccess(res, is_template, postData),
          onError: (err) => createNewReportError(err, is_template),
        })
      );
      return;
    }

    if (!isPerformedReport) {
      //is a pigello template
      dispatch(
        createNewReport({
          reportData: currentReportData,
          reportStructure: currentReportStructure,
          postData: fullPostData,
          onSuccess: createNewReportSuccess,
          onError: createNewReportError,
        })
      );
    } else {
      dispatch(
        reRunReport({
          reportData: currentReportData,
          reportStructure: currentReportStructure,
          postData: fullPostData,
          onSuccess: createNewReportSuccess,
          onError: createNewReportError,
        })
      );
    }
  };

  const resetHasUpdatedAnyData = (postData) => {
    setHasUpdatedAnyData(false);
    setInitialPostData(postData);
  };

  const updatedTemplateSuccess = (res, postData) => {
    dispatch(updateReportInAll(res.data));

    setIsLoading(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Uppdateringarna sparades",
      })
    );

    resetHasUpdatedAnyData(postData);
  };

  const updatedTemplateError = (err) => {
    setIsLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Kunde inte spara uppdateringarna",
        description: "Kontrollera fälten och försök igen",
      })
    );
  };

  const createNewReportError = (err) => {
    setIsLoading(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Rapporten kunde inte skapas",
        description: "Kontrollera fälten och försök igen",
      })
    );
  };

  const createNewReportSuccess = (res, is_template = false, postData) => {
    dispatch(purgeAll());

    if (!is_template) {
      downloadReportFile(
        res.data.result.get,
        `${res.data.title || res.data.str_representation}.${res.data.format}`,
        res.data.format
      );

      setIsLoading(false);

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title:
            'Rapporten laddas ner. Du kan även hitta den under "Tidigare exporter"',
        })
      );

      return;
    }

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Rapportmallen har skapats",
        description: 'Du kan nu hitta den under "Mina rapportmallar"',
      })
    );

    dispatch(
      hotLoadReportTemplate({
        id: res.data.id,
        onSuccess: ({ data }) => {
          dispatch(setHotRefresh(data));

          history.push(`/reports/table-view/${data.id}`);

          setIsLoading(false);
        },
        onError: (err) => {
          setIsLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde inte gå till rapportmallen",
              description: 'Gå till "Mina rapportmallar" för att hitta mallen.',
            })
          );
        },
      })
    );

    resetHasUpdatedAnyData(postData);

    setIsSavingAsTemplate(false);
  };

  return {
    doReport,
    patchChangesToTemplate,
  };
};

export const usePostData = ({
  createParametersMap,
  filterInputStates,
  selectedReport,
  orderOfColumns,
  unusedColumns,
  currentTitle,
  aggregations,
  shared_with,
  is_starred,
  groupingState,
}) => {
  const buildPostData = (format) => {
    const fullPostData = {};

    const kwargs = {};

    let index = 0;
    for (let key of createParametersMap.keys()) {
      kwargs[key] = filterInputStates[index];
      index++;
    }

    let filters = {};

    for (let key in kwargs) {
      if (selectedReport.options.filters.hasOwnProperty(key)) {
        filters[key] = kwargs[key];
        delete kwargs[key];
      }
    }

    if (kwargs.hasOwnProperty("exclude_main_data")) {
      fullPostData["exclude_main_data"] = kwargs["exclude_main_data"];
      delete kwargs["exclude_main_data"];
    }

    delete kwargs["filters"];

    for (let key in filters) {
      let filter = filters[key];
      let newFilter = undefined;
      let map = KWARG_MAP[key];

      if (map?.arrayContentType === "id") {
        if (filter && filter.length !== 0) {
          newFilter = [];

          for (let item of filter) {
            newFilter.push(item.id);
          }
        }
      }

      if (map?.arrayContentType === "boolean") {
        newFilter = filter === "true" ? true : false;
      }

      if (newFilter !== undefined) {
        filters[key] = newFilter;
      }
    }

    fullPostData["filters"] = filters;

    fullPostData["additional_kwargs"] = kwargs;

    //.slice() and .map() doesnt work. it keeps updating the whole aggregations state
    let localAggregations = JSON.parse(JSON.stringify(aggregations));

    let aggregationsToRemove = [];

    //Remove all empty strings from aggregations
    let aggrIndex = 0;
    for (let aggr of localAggregations) {
      while (aggr.group_by.indexOf("") !== -1) {
        let index = aggr.group_by.indexOf("");
        aggr.group_by.splice(index, 1);
      }
      while (aggr.modes.indexOf("") !== -1) {
        let index = aggr.modes.indexOf("");
        aggr.modes.splice(index, 1);
      }

      if (aggr.group_by.length === 0 || aggr.modes.length === 0)
        aggregationsToRemove.push(aggrIndex);

      aggrIndex++;
    }

    for (let i = aggregationsToRemove.length - 1; i >= 0; i--) {
      localAggregations.splice(aggregationsToRemove[i], 1);
    }

    fullPostData["summarize_by"] = localAggregations;

    fullPostData["group_by"] = groupingState;

    fullPostData["column_order"] = orderOfColumns.map((col) => {
      return col.identifier;
    });

    fullPostData["excluded_columns"] = unusedColumns.map((col) => {
      return col.identifier;
    });

    if (!format) {
      fullPostData["format"] = "xlsx";
    } else fullPostData["format"] = format;

    fullPostData["title"] = currentTitle;

    fullPostData["shared_with"] = shared_with;

    fullPostData["is_starred"] = is_starred;

    return fullPostData;
  };

  return { buildPostData };
};

export function useReportPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export function useReportTemplatePagination(querystring) {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryInfo = new URLSearchParams(querystring);

  const entries = queryInfo.entries();
  const queryObject = paramsToObject(entries);

  const pageQueryInfo = {
    page: parseInt(queryInfo.get("_page")),
    size: parseInt(queryInfo.get("_page_size")),
  };

  const templatePathing = useSelector(
    (state) => state[constants.STORE_NAME].templatePathing
  );

  const getPathingObject = () => {
    if (
      pathing.hasOwnProperty(location.pathname.replace(BASE_PATH + "/", ""))
    ) {
      return pathing[location.pathname.replace(BASE_PATH + "/", "")];
    }
    return pathing[DEFAULT_PATHNAME];
  };

  const choosenPath = getPathingObject();

  let hasChangedWithPathId = true;

  if (queryObject.path_id____ !== choosenPath.pathId) {
    hasChangedWithPathId = false;
  }

  let queryForTemplatePathing = cloneDeep(queryObject);
  delete queryForTemplatePathing._page;
  queryForTemplatePathing = buildQueryString(queryForTemplatePathing);

  const allAvailableReports = useSelector(
    (state) => state[constants.STORE_NAME].allAvailableReports
  );

  const buildTemplatePathing = (customReportsCount) => {
    //calculate pages based on templates for this path

    let customReports = customReportsCount;

    let templatesForPath = choosenPath.templates.slice();

    if (templatePathing.hasOwnProperty(queryForTemplatePathing)) {
      return [
        templatePathing[queryForTemplatePathing],
        templatePathing[queryForTemplatePathing].totalCount,
      ];
    }

    //search logic for templates
    if (queryObject.title__icontains) {
      let indexesToRemove = [];
      let searchString = queryObject.title__icontains.toLowerCase();

      templatesForPath = templatesForPath.map((tIndex, index) => {
        let template = builtTemplates[tIndex];

        if (template.title.toLowerCase().includes(searchString)) return tIndex;
        indexesToRemove.push(index);
        return undefined;
      });

      for (let i = indexesToRemove.length - 1; i >= 0; i--) {
        templatesForPath.splice(indexesToRemove[i], 1);
      }
    }
    //badge logic for templates
    if (queryObject.report__in && choosenPath.canFilterBadges) {
      let indexesToRemove = [];
      let acceptableReportTypes = queryObject.report__in.split(",");

      templatesForPath = templatesForPath.map((tIndex, index) => {
        let template = builtTemplates[tIndex];

        if (acceptableReportTypes.includes(template.report)) {
          return tIndex;
        } else {
          indexesToRemove.push(index);
          return undefined;
        }
      });

      for (let i = indexesToRemove.length - 1; i >= 0; i--) {
        templatesForPath.splice(indexesToRemove[i], 1);
      }
    }

    //Permission logic for templates
    if (allAvailableReports.length !== 0) {
      let allAvailableReportsKeys = Object.keys(allAvailableReports);
      let indexesToRemove = [];

      templatesForPath = templatesForPath.map((tIndex, index) => {
        let template = builtTemplates[tIndex];

        if (allAvailableReportsKeys.includes(template.report)) {
          return tIndex;
        } else {
          indexesToRemove.push(index);
          return undefined;
        }
      });

      for (let i = indexesToRemove.length - 1; i >= 0; i--) {
        templatesForPath.splice(indexesToRemove[i], 1);
      }
    }

    let totalReportsForPath = customReports + templatesForPath.length;

    let totalPagesWitoutTemplates = Math.ceil(
      customReports / pageQueryInfo.size
    );

    let totalPages = Math.ceil(totalReportsForPath / pageQueryInfo.size);

    let reportsOnLastCustomReportsPage =
      customReports - pageQueryInfo.size * (totalPagesWitoutTemplates - 1);

    let templatesOnLastCustomReportsPage =
      pageQueryInfo.size - reportsOnLastCustomReportsPage;

    let templateIndexesOnLastCustomReportsPage = [];

    if (templatesOnLastCustomReportsPage > templatesForPath.length) {
      templateIndexesOnLastCustomReportsPage = templatesForPath;
    } else {
      templateIndexesOnLastCustomReportsPage = templatesForPath.splice(
        0,
        templatesOnLastCustomReportsPage
      );
    }

    let templatePagePathing = {
      [totalPagesWitoutTemplates]: templateIndexesOnLastCustomReportsPage,
    };

    let standaloneTemplatePages = totalPages - totalPagesWitoutTemplates;
    templatePagePathing.templateOnlyPages = [];
    templatePagePathing.totalCount = totalReportsForPath;

    if (standaloneTemplatePages > 0) {
      for (let i = 0; i < standaloneTemplatePages; i++) {
        let pageIndex = i + totalPagesWitoutTemplates + 1;
        templatePagePathing.templateOnlyPages.push(pageIndex);

        if (pageQueryInfo.size >= templatesForPath.length) {
          templatePagePathing[pageIndex] = templatesForPath;
          break;
        }

        templatePagePathing[pageIndex] = templatesForPath.splice(
          0,
          pageQueryInfo.size
        );
      }
    }

    let clone = cloneDeep(templatePathing);

    clone[queryForTemplatePathing] = templatePagePathing;

    dispatch(setTemplatePathing(clone));

    return [templatePagePathing, totalReportsForPath];
  };

  const generateTemplates = (pathing) => {
    let templates = [];

    if (!pathing || !pathing.hasOwnProperty(pageQueryInfo.page))
      return templates;

    for (let tIndex of pathing[pageQueryInfo.page]) {
      let template = builtTemplates[tIndex];
      templates.push(template);
    }
    return templates;
  };

  let queryStringForPagination = cloneDeep(queryObject);
  delete queryStringForPagination.path_id____;

  queryStringForPagination = buildQueryString(queryStringForPagination);

  let shouldPaginationStop = templatePathing[queryForTemplatePathing]
    ?.templateOnlyPages
    ? templatePathing[queryForTemplatePathing]?.templateOnlyPages.includes(
        pageQueryInfo.page
      )
    : false;

  // let shouldSavePaginationLoadingState = false;

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryStringForPagination,
    stop: {
      shouldStop: shouldPaginationStop,
      shouldSaveLoading: false,
    },
  };

  let [queriedItems, isQueryLoading, backendCountFlag] = usePagination(params);

  const buildItems = (didStopPagination = false) => {
    if (!didStopPagination) {
      if (isQueryLoading) return { results: [] };
      if (queriedItems?.results === undefined) return { results: [] };
    }

    let [pathing, totalReportsForPath] = buildTemplatePathing(backendCountFlag);

    let templatesToRender = generateTemplates(pathing);

    if (!hasChangedWithPathId) isQueryLoading = true;

    if (didStopPagination) {
      //dont return queried items, have stopped pagination, quertied items will be empty
      //this is an only template page
      return {
        count: pathing.totalCount,
        results: templatesToRender,
      };
    }

    return {
      ...queriedItems,
      results: [...queriedItems.results, ...templatesToRender],
      count: totalReportsForPath,
    };
  };

  const items = React.useMemo(
    () => buildItems(shouldPaginationStop),
    [queriedItems, shouldPaginationStop]
  );

  return [items, isQueryLoading, backendCountFlag];
}
