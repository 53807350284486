import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);

  const { type, payload } = action;

  switch (type) {
    case constants.SET_USER_ACTIVITIES: {
      return { ...newState, userActivities: payload.activities };
    }

    default:
      return newState;
  }
};
