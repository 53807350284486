import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

export default ({ method }) => {
  return (
    <>
      <FormAreaTitle>Granska och slutför</FormAreaTitle>
      <FormAreaDescription>
        Kontrollera att uppgifterna stämmer. Tryck på "
        {method === "POST" ? "Skapa" : "Uppdatera"} bolagsinformation" för att
        slutföra.
      </FormAreaDescription>
    </>
  );
};
