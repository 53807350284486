import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  useBrfPremises,
  useBrokerDoc,
} from "../../../store/brfPremises/hooks/retrieve";
import ContainerSpinner from "../../../components/Loaders/ContainerSpinner";
import Pdf from "../../../components/Pdf/Pdf";
import { TextButton } from "../../../components/Forms/Base/Buttons";

export default function BrfPremisesBrokerDoc() {
  const { brfPremisesId } = useParams();

  const [brfPremises] = useBrfPremises(brfPremisesId);
  const [brokerDocPdf, loading] = useBrokerDoc(brfPremisesId);

  const download = () => {
    const linkSource = brokerDocPdf;
    const downloadLink = document.createElement("a");
    const fileName = `mäklarbild_${brfPremises?.str_representation}.pdf`;
    downloadLink.target = "_blank";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Mäklarbild</OverviewTitle>
            {brokerDocPdf && (
              <OverviewSubtitle>
                <TextButton
                  title="Ladda ned"
                  iconType="download"
                  iconPlacement="right"
                  clicked={download}
                />
              </OverviewSubtitle>
            )}
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {loading && <ContainerSpinner />}

        {brokerDocPdf && <Pdf url={brokerDocPdf} />}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
