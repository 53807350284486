import * as React from "react";
import { useParams } from "react-router";

import { DetailInnerWrapper } from "../../../components/sharedStyles";
import DocumentViewer from "../../../components/Details/OverviewInfo/DocumentViewer/DocumentViewer";
import {
  useServiceContract,
  constants,
  update,
  detailUrl,
  useServiceContractForm,
} from "../../../store/serviceContracts";

export default function ServiceContractDocumentTabs() {
  const { serviceContractId } = useParams();
  const [serviceContract] = useServiceContract(serviceContractId);

  useServiceContractForm("PATCH", serviceContractId);
  return (
    <DetailInnerWrapper>
      <DocumentViewer
        contract={serviceContract}
        contractConstants={constants}
        contractUpdate={update}
        {...{ detailUrl }}
      />
    </DetailInnerWrapper>
  );
}
