import * as React from "react";

import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

import { constants, detailUrl } from "../../../store/reportErrands";
import { getChosenPerformer } from "../../utils/errandUtils";
import { useUser } from "../../../store/users";
import { useReportErrandSetting } from "../../../store/reportErrandSettings";
import exportErrandCosts from "../../utils/errandExport";
import { useSelector } from "react-redux";
import { BADGE_TYPES } from "src/components/Badge/Badge";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  detailUrlMethod,
}) => {
  const [costValue, setCostValue] = React.useState(undefined);

  const onCostExport = (data) => {
    setCostValue({
      reportedBy: data?.reportedBy,
      performedBy: getChosenPerformer(data)?.id,
      setting: data?.setting,
      errand: data,
    });
  };

  const [costRepBy, costRepByLoading] = useUser(costValue?.reportedBy);
  const [costPerfBy, costPerfByLoading] = useUser(costValue?.performedBy);
  const [costSetting, costSettingLoading] = useReportErrandSetting(
    costValue?.setting
  );
  const authedUser = useSelector((state) => state.app?.user) || {};

  React.useEffect(() => {
    if (
      !costValue ||
      costRepByLoading ||
      costPerfByLoading ||
      costSettingLoading
    ) {
      return;
    }
    exportErrandCosts({
      errand: costValue?.errand,
      reportedBy: costRepBy,
      performedBy: costPerfBy,
      authedUser,
      setting: costSetting,
    });
    setCostValue(undefined);
  }, [costValue]);

  const columns = React.useMemo(
    () => columnDefs({ costExportCallback: onCostExport }),
    // () => columnDefs({ costExportCallback: onCostExport, allComponents }),
    [persistantQuery]
  );

  const redirectMethod =
    detailUrlMethod || ((obj) => detailUrl({ id: obj.id }));

  const badges = {
    Avklarade: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        status__in: [3],
      },
    },
    "Avklarade av hyresgäst": {
      color: BADGE_TYPES.CYAN,
      querySet: {
        status__in: [4],
      },
    },
    Påbörjade: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        status__in: [1],
      },
    },
    Pausade: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        status__in: [2],
      },
    },
    "Ej påbörjade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        status__in: [0],
      },
    },
  };
  const filters = {
    Ärendenummer: {
      queryKey: "errand_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Titel: {
      queryKey: "title",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Skapat: {
      queryKey: "created_at",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    "Planerat utförandedatum": {
      queryKey: "planned_start",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    Utförandedatum: {
      queryKey: "execute_end",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  // Found problems:
  // reported_by -> hook call useUser - problem with excel export. Showing only "-"
  // Tags are also using hook so they are showing only "-" for the excel export

  return (
    <>
      <BaseTable
        tableId={tableId || "reporterrand_full_table"}
        title={"Felanmälningar"}
        onRowClickedWithDetail={(obj) => redirectMethod(obj)}
        initialOrderBy={["-created_at"]}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
};
