export default [
  {
    title: "Dimensioner",
    key: "ACCOUNTING",
    visited: true,
    hasError: false,
    fieldKeys: ["project", "cost_center"],
  },
  // {
  //   title: "Granska",
  //   key: "CONFIRM",
  //   visited: false,
  //   hasError: false,
  //   fieldKeys: [""],
  // },
];
