import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import OtherList from "src/components/Tables/OtherContracts/FullTableNewVersion";
import { useOtherContractPaginationCount } from "../../../store/otherContracts/hooks/retrieve";
import { buildQueryString } from "../../../store/base";
import useActiveFilters from "../../../hooks/useActiveFilters";
import {
  FilterButton,
  FilterWrapper,
} from "../../../components/Filters/styles";
import { cloneDeep } from "lodash";
// import OtherContractWithCostsTable from "../../../components/Lists/OtherContractsWithCosts/FullTable";
import OtherContractWithCostsTable from "../../../components/Tables/OtherContractsWithCosts/FullTableNewVersion";

export const PERSISTANT_CATEGORY_KEY = "persistant_category_othercontract";

export default function OtherContractCosts() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate__in: filteredRealEstates,
    draft: false,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Övriga avtal</OverviewTitle>
          </OverviewTitleWrapper>

          <OtherContractWithCostsTable persistantQuery={persistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
