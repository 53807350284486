import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

export const updateQuotas = ({
  quotas,
  brfCompanyId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const url = `${constants.URL}${brfCompanyId}/`;

      const res = await axiosInstance.put(url, quotas);

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

export const updatePaymentGroupQuotas = ({
  quotas,
  paymentGroupId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const url = `${constants.URL_PAYMENT_GROUP}${paymentGroupId}/`;

      const res = await axiosInstance.put(url, quotas);

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};
