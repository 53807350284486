import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  useTenantForm,
  constants,
  create,
  update,
  destroyPatchForm,
  destroyPostForm,
  detailUrl,
} from "../../../../store/tenants";

import { create as createBrfTenant } from "src/store/brfOwner";

import { constants as userConstants } from "../../../../store/users";
import {
  clearFetched,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";

import useActiveFilters from "../../../../hooks/useActiveFilters";
import { cloneDeep } from "lodash";
import { setActiveFormInstance } from "../../../../store/base/store/actions";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import { AddressField } from "../../Base/Fields";
import M2mField from "../../Base/Fields/M2mField";

export default function TenantModalForm({
  method,
  id,
  kind,
  onCheckout,
  customSuccessHandling,
  isMember,
  instance,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;

  const { filteredRealEstates } = useActiveFilters();

  const formInstance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (method === "POST" && filteredRealEstates?.length > 0) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            "user.realestates": filteredRealEstates.map((re) => ({ id: re })),
          },
        })
      );
    }
  }, [filteredRealEstates]);

  const formLoaded = Boolean(useTenantForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));

      // clear users, new tenant = new user but cache does not know
      dispatch(clearFetched(userConstants));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout(success);
  };

  const onSuccess = (user, returned) => {
    setLoading(false);
    checkout(true);

    if (customSuccessHandling) {
      customSuccessHandling(user, returned);
    } else if (method === "POST") {
      push(detailUrl({ id: returned.id, showInvoicingSettingPrompt: true })); // check if billecta perm for showinvoicingsettingprompt
    }
  };

  const brfOnSuccess = (user, returned) => {
    setLoading(false);
    checkout(true);

    if (customSuccessHandling) {
      customSuccessHandling(user, returned.tenant);
    } else if (method === "POST") {
      push(
        detailUrl({
          id: returned.tenant.id,
          isMember: true,
          showInvoicingSettingPrompt: true,
        })
      ); // check if billecta perm for showinvoicingsettingprompt
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (dataClone?.contact_persons?.length) {
      for (let person of dataClone.contact_persons) {
        if (person?.phone) {
          person.phone = person.phone.replace(/-/g, "");
        }
      }
    }

    if (method === "POST" && isMember) {
      dataClone.is_brf = true;
    }

    if (!dataClone.customer_id) {
      dataClone.customer_id = null;
    }

    return dataClone;
  };

  const brfPreProcess = (data) => {
    const dataClone = {
      tenant: cloneDeep(data),
    };

    if (dataClone?.tenant?.contact_persons?.length) {
      for (let person of dataClone.contact_persons) {
        if (person?.phone) {
          person.phone = person.phone.replace(/-/g, "");
        }
      }
    }

    if (!dataClone?.tenant?.customer_id) {
      dataClone.tenant.customer_id = null;
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    // members is created by posting to brf owners instead of tenant endpoint
    if (isMember && method === "POST") {
      dispatch(
        createBrfTenant({
          forceData: formInstance,
          preProcess: brfPreProcess,
          successCallback: brfOnSuccess,
          errorCallback: () => setLoading(false),
        })
      );

      return;
    }

    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={
        method === "POST"
          ? isMember
            ? "Lägg till medlem"
            : "Lägg till hyresgäst"
          : isMember
          ? "Uppdatera medlem"
          : "Uppdatera hyresgäst"
      }
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {loading && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">
        {isMember ? "Medlem" : "Hyresgäst"}
      </div>

      {kind === "corporate" ? (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            storeName={storeName}
            title="Bolagsnamn"
            fieldKey={"user.corporate_name"}
            method={method}
          />
        </div>
      ) : null}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          title={kind === "corporate" ? "Kontaktperson förnamn" : "Förnamn"}
          fieldKey={"user.first_name"}
          method={method}
        />
        <TextInputField
          storeName={storeName}
          title={kind === "corporate" ? "Kontaktperson efternamn" : "Efternamn"}
          fieldKey={"user.last_name"}
          method={method}
        />

        <TextInputField
          storeName={storeName}
          fieldKey={"user.email"}
          title="E-post"
          method={method}
        />
        <TextInputField
          storeName={storeName}
          fieldKey={"user.phone"}
          title="Telefonnummer"
          method={method}
        />
        <TextInputField
          storeName={storeName}
          fieldKey={"user.legal_id"}
          title={kind === "corporate" ? "Organisationsnummer" : "Personnummer"}
          method={method}
        />

        <TextInputField
          title={isMember ? "Medlemsnummer" : "Hyresgästnummer"}
          storeName={storeName}
          fieldKey={"customer_id"}
          method={method}
        />

        <TableSelectField
          storeName={storeName}
          title="Tillhör fastigheter"
          placeholder="Välj fastigheter..."
          method={method}
          fieldKey="user.realestates"
          TableComponent={RealEstateTable}
          isMany
        />
      </div>

      <hr />
      <div className="mb-6 text-base font-medium">
        Ytterligare kontaktuppgifter
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <M2mField
          title="Kontaktpersoner"
          fieldKey={`contact_persons`}
          instructionsKey={`contact_persons`}
          method={method}
          storeName={storeName}
          instanceTitle="kontaktperson"
        >
          {(parentPath, instructionsKey) => (
            <>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <TextInputField
                  title="Titel"
                  storeName={storeName}
                  fieldKey={`${parentPath}.title`}
                  method={method}
                  instructionsKey={`${instructionsKey}.title`}
                />

                <TextInputField
                  title="Namn"
                  storeName={storeName}
                  fieldKey={`${parentPath}.name`}
                  method={method}
                  instructionsKey={`${instructionsKey}.name`}
                />

                <TextInputField
                  title="Telefon"
                  storeName={storeName}
                  fieldKey={`${parentPath}.phone`}
                  method={method}
                  instructionsKey={`${instructionsKey}.phone`}
                />

                <TextInputField
                  title="E-post"
                  storeName={storeName}
                  fieldKey={`${parentPath}.email`}
                  type="email"
                  method={method}
                  instructionsKey={`${instructionsKey}.email`}
                />
              </div>
            </>
          )}
        </M2mField>
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <AddressField
          {...{
            storeName,
            title: "Övrig adress",
            method,
            fieldKey: "separate_address",
          }}
        />
      </div>
    </Modal>
  );
}
