import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: true, display: "Aktiverat" },
    { value: null, display: "Ej aktiverat" },
    { value: false, display: "Pausad" },
  ];
  return <SelectFilter column={column} choices={choices} />;
};
