import {
  get,
  options,
  destroyForm,
  patch,
  getPagination,
  getFiltered,
} from "../../base";
import constants from "./constants";
import { markNotificationsAsRead } from "./services";

export const performFilter = (querystring, callback, taskToken) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPatchForm = () => {
  return options({ url: constants.PATCH_URL, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const markAsRead = (readlist, success) => {
  return async (dispatch) => {
    await markNotificationsAsRead(readlist);

    dispatch(destroyForm({ constants, method: "PATCH", success }));
  };
};

export const update = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return patch({
    url: constants.PATCH_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};
