import Parties from "./Parties";
import ContractDetails from "./ContractDetails";

import Confirm from "./Confirm";
import SignStatus from "./SignStatus";
import ParkingSpots from "./ParkingSpots";
export default {
  PARTIES: Parties,
  PARKING_SPOTS: ParkingSpots,

  CONTRACT: ContractDetails,
  CONFIRM: Confirm,
  SIGN_STATUS: SignStatus,
};
