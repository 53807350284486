import styled, { css } from "styled-components";
import checkWhite from "../../assets/svg/check_white.svg";

export const BottomBarWrapper = styled.div`
  position: fixed;
  height: 80px;
  width: 100vw;
  left: 0;
  bottom: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2);
  border-top: ${(p) => p.theme.borders.standard};
`;

export const BottomBarInnerWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 40px;
`;

export const StepDisplayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto;
`;

export const Step = styled.div`
  padding: 4px 8px;
  position: relative;
  border-radius: 8px;
  background-color: ${(p) =>
    p.active
      ? p.theme.colors.primaryBlue
      : p.finished
      ? p.theme.colors.green
      : "#fff"};
  color: ${(p) =>
    p.active
      ? "#fff"
      : p.finished
      ? p.theme.colors.greenLight
      : p.theme.colors.gray3};
  border: ${(p) => p.theme.borders.standard};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  opacity: ${(p) => (p.active ? 1 : p.finished ? 0.8 : 0.9)};

  ${(p) =>
    p.finished &&
    css`
      &::after {
        height: 20px;
        width: 20px;

        border-radius: 50%;
        background-color: ${(p) => p.theme.colors.green};
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        left: 2px;
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        background-image: url(${checkWhite});
      }
    `}
`;

export const StepSeparator = styled.div`
  margin: 0 12px;
  height: 2px;
  width: 48px;
  border-radius: 5px;
  background-color: ${(p) => p.theme.colors.gray2};
`;
