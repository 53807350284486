import * as React from "react";

import classNames from "classnames";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import LocalSelectField from "../../../components/Forms/Base/Fields/LocalSelectField";

export const Header = ({ children, headerGroups, hideHeaders }) => {
  return (
    <thead className={classNames(hideHeaders && "hidden")}>
      {headerGroups.map((headerGroup, index) => (
        <tr className=" " {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            return (
              <HeaderItem
                column={column}
                key={`${column.id || column.accessor}${index}`}
              />
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export const HeaderItem = ({ column }) => {
  return (
    <th
      className="text-start bg-gray-300 p-2 last-of-type:rounded-tr first-of-type:rounded-tl"
      {...column.getHeaderProps()}
    >
      {column.render("Header")}
    </th>
  );
};

export const Body = ({
  props,
  prepareRow,
  page,
  rowClassName,
  children,
  onRowClicked,
}) => {
  return (
    <tbody {...props}>
      {page.map((row, i) => {
        prepareRow(row);
        return (
          <tr
            onClick={() => onRowClicked(row)}
            className="border-solid border-b border-gray-200 p-2 odd:bg-gray-50 cursor-pointer"
            {...row.getRowProps()}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          >
            {row.cells.map((cell) => {
              return (
                <td className="pl-2 align-middle" {...cell.getCellProps()}>
                  <div
                    className={classNames(
                      "flex items-center text-start text-sm",
                      rowClassName
                    )}
                  >
                    {cell.render("Cell")}
                  </div>
                </td>
              );
            })}
          </tr>
        );
      })}
      {children}
    </tbody>
  );
};

export const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  return (
    <div className="flex pt-2 mb-24 space-x-1 justify-between w-[98%]">
      <div className="flex space-x-1">
        {canPreviousPage && (
          <button
            className="relative h-[42px] flex cursor-pointer items-center border border-solid border-gray-300 hover:bg-gray-200 text-black p-2.5 rounded transition-colors"
            onClick={() => previousPage()}
          >
            <ChevronLeftIcon width={16} />
            <p className="text-xs ml-2">Föregående sida</p>
          </button>
        )}
        {canNextPage && (
          <button
            className="relative flex cursor-pointer items-center border border-solid border-gray-300 hover:bg-gray-200 text-black p-2.5 rounded transition-colors"
            onClick={() => nextPage()}
          >
            <p className="text-xs mr-2">Nästa sida</p>
            <ChevronRightIcon width={16} />
          </button>
        )}
      </div>
      <div className="flex space-x-1">
        {(canNextPage || canPreviousPage) && pageCount !== 0 && (
          <div className="w-24">
            <LocalSelectField
              removePlaceholder={true}
              selectClassName={"cursor-pointer text-xs"}
              value={pageIndex + 1}
              choices={Array.from(new Array(pageCount || 1)).map(
                (obj, index) => {
                  return {
                    v: index + 1,
                    d: `Sida ${index + 1}`,
                  };
                }
              )}
              onChange={(val) => gotoPage(parseInt(val) - 1)}
            />
          </div>
        )}
        {pageCount !== 0 && (
          <div className="w-24">
            <LocalSelectField
              removePlaceholder={true}
              selectClassName={"cursor-pointer text-xs"}
              value={pageSize}
              choices={[5, 10, 25, 50, 100].map((num) => {
                return {
                  v: num,
                  d: `Visa ${num}`,
                };
              })}
              onChange={(val) => {
                // setPageQueryInfo({ page: 1, size: parseInt(val) })
                // console.log(val, 134)
                gotoPage(0);
                setPageSize(parseInt(val));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
