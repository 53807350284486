import * as React from "react";

import { TextInput, TextField, NumberInput, Checkbox } from "../../../Base/Fields";
// store, state
import { constants, usePipe } from "../../../../../store/pipes";
import {
  updateActiveFormInstance,
  useFormField,
  useFormInstanceField,
} from "../../../../../store/base";
import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";
import StandardModal from "../../../../Modals/StandardModal";
import { InfoBox } from "../../../../Displays";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import {
  AttributeContainer,
  AttributeDescription,
  AttributeTitle,
  AttributeWrapper,
  AttributeType,
  RemoveButton,
  AlternativesWrapper,
  AlternativesTitle,
  Alternative,
} from "../styles";
import {
  InputFieldDescription,
  InputFieldTitle,
  InputSpacing,
} from "../../../Base/Fields/styles";
import { useParams } from "react-router-dom";

export const ATTRIBUTE_KINDS = {
  0: "Värde",
  1: "Textfält",
  3: "Intervall",
};

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [createOpen, setCreateOpen] = React.useState(null);
  const { pipeId } = useParams();

  const dynamicAttributes = useFormInstanceField({
    storeName,
    fieldKey: "dynamic_attributes",
  });
  const dynamicAttributesKinds = useFormField({
    storeName,
    method,
    fieldKey: "dynamic_attributes.kind._choices",
  });

  const [pipe] = usePipe(pipeId);

  const isHomeQPipe =
    useFormInstanceField({
      storeName,
      fieldKey: "is_homeq_pipe",
    }) || pipe?.is_homeq_pipe;

  const currentField = dynamicAttributes?.find((d) => d._index === createOpen);

  const currentFieldAlternatives = currentField?.pipeattributechoices_set;
  const currentFieldIndex = dynamicAttributes?.findIndex(
    (d) => d._index === createOpen
  );

  const handleCreateNewField = () => {
    const dynamicAttributesClone = dynamicAttributes
      ? cloneDeep(dynamicAttributes)
      : [];

    const newField = {
      _index: dynamicAttributesClone.length,
      title: "",
      description: "",
      kind: null,
    };

    dynamicAttributesClone.push(newField);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { dynamic_attributes: dynamicAttributesClone },
      })
    );

    setCreateOpen(newField._index);
  };

  const removeField = (field, e) => {
    e.stopPropagation();
    let dynamicAttributesClone = cloneDeep(dynamicAttributes).filter((d) => {
      if (field._index != null) {
        return d._index !== field._index;
      } else {
        return d.id !== field.id;
      }
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { dynamic_attributes: dynamicAttributesClone },
      })
    );
  };

  const handleKindSelected = (kind) => {
    const dynamicAttributesClone = cloneDeep(dynamicAttributes);

    const cur = dynamicAttributesClone[currentFieldIndex];
    cur.kind = kind;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          dynamic_attributes: dynamicAttributesClone,
        },
      })
    );
  };

  const handleAddAttribute = () => {
    const attributesClone = cloneDeep(currentFieldAlternatives || []);

    switch (currentField.kind) {
      case 0: //value
        attributesClone.push({
          float_value: 0,
        });
        break;
      case 1: // text
        attributesClone.push({
          char_value: "",
        });
        break;
      case 3: // interval
        attributesClone.push({
          float_value: 0,
          max_float_value: 0,
        });
        break;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`dynamic_attributes[${currentFieldIndex}].pipeattributechoices_set`]:
            attributesClone,
        },
      })
    );
  };

  const handleClose = () => {
    if (currentField?.id) {
      setCreateOpen(null);
      return;
    }

    if (!currentField?.title || currentField?.kind == null) {
      let dynamicAttributesClone = cloneDeep(dynamicAttributes).filter(
        (d) => d._index !== currentField._index
      );

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { dynamic_attributes: dynamicAttributesClone },
        })
      );

      setCreateOpen(null);
    } else {
      setCreateOpen(null);
    }
  };

  const removeFieldAlternative = (index) => {
    const attributesClone = cloneDeep(currentFieldAlternatives || []);

    attributesClone.splice(index, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`dynamic_attributes[${currentFieldIndex}].pipeattributechoices_set`]:
            attributesClone,
        },
      })
    );
  };

  const renderAlternativeField = (alternative, i) => {
    switch (currentField.kind) {
      case 0: // value
        return (
          <NumberInput
            title="Värde"
            instructionsKey={
              "dynamic_attributes.pipeattributechoices_set.float_value"
            }
            fieldKey={`dynamic_attributes[${currentFieldIndex}].pipeattributechoices_set[${i}].float_value`}
            storeName={storeName}
            method={method}
            noTitle
            noMargin
            placeholder="Skriv alternativets värde"
            rowSize
          />
        );
      case 1: // text
        return (
          <TextInput
            title="Namn på alternativet"
            instructionsKey={
              "dynamic_attributes.pipeattributechoices_set.char_value"
            }
            fieldKey={`dynamic_attributes[${currentFieldIndex}].pipeattributechoices_set[${i}].char_value`}
            storeName={storeName}
            method={method}
            noTitle
            noMargin
            placeholder="Skriv alternativet"
            rowSize
          />
        );

      case 3: // interval
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <NumberInput
              title="Från"
              instructionsKey={
                "dynamic_attributes.pipeattributechoices_set.float_value"
              }
              fieldKey={`dynamic_attributes[${currentFieldIndex}].pipeattributechoices_set[${i}].float_value`}
              storeName={storeName}
              method={method}
              noTitle
              noMargin
              placeholder="Från..."
              rowSize
            />
            <span>&nbsp; &mdash; &nbsp;</span>
            <NumberInput
              title="Till"
              instructionsKey={
                "dynamic_attributes.pipeattributechoices_set.max_float_value"
              }
              fieldKey={`dynamic_attributes[${currentFieldIndex}].pipeattributechoices_set[${i}].max_float_value`}
              storeName={storeName}
              method={method}
              noTitle
              placeholder="Till..."
              noMargin
              rowSize
            />
          </div>
        );
    }
  };

  if (isHomeQPipe) {
    return (
      <>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Fält för leads i projektet</OverviewTitle>
            <OverviewSubtitle>
              Lägg till ytterligare fält för leads i projektet om det behövs.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <hr />

        <InfoBox
          boxTheme="warning"
          title="Kan ej lägga till extra fält på HomeQ-projekt"
          text="Denna funktion är endast tillgänglig för interna Pigello-leads. I ett HomeQ-projekt sköter HomeQ fälttyperna."
        />
      </>
    );
  }

  return (
    <>
      <StandardModal
        isOpen={createOpen != null}
        modalInModal
        title="Nytt fält"
        withActionBar
        saveFunction={handleClose}
        canAccept={currentField?.title && currentField.kind != null}
        actionBarCancelTitle="Stäng"
        closeFunction={handleClose}
      >
        {!!currentField && (
          <>
            <TextInput
              title="Namn på fält"
              fieldKey={`dynamic_attributes[${currentFieldIndex}].title`}
              method={method}
              storeName={storeName}
              instructionsKey={"dynamic_attributes.title"}
            />

            <TextField
              title="Beskrivning"
              fieldKey={`dynamic_attributes[${currentFieldIndex}].description`}
              method={method}
              storeName={storeName}
              instructionsKey={"dynamic_attributes.description"}
            />

            <Checkbox
            title="Visa på hyresgästkort"
            fieldKey={`dynamic_attributes[${currentFieldIndex}].pinn_on_card`}
            method={method}
            storeName={storeName}
            instructionsKey={"dynamic_attributes.pinn_on_card"}
            />

            <InputSpacing>
              <InputFieldTitle>Typ av fält*</InputFieldTitle>
              <InputFieldDescription>
                Typen av fält bestämmer vad som får anges på fältet. Beroende på
                vilken typ som väljs kan olika typer av värden sättas. Tryck på
                en fälttyp för att välja den och få mer information om just den
                typen.
              </InputFieldDescription>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {dynamicAttributesKinds.map((c) => (
                  <AttributeType
                    selectable
                    selected={currentField.kind === c.v}
                    style={{ marginRight: 8 }}
                    kind={c.v}
                    onClick={
                      currentFieldAlternatives?.length > 0
                        ? null
                        : () => handleKindSelected(c.v)
                    }
                  >
                    {ATTRIBUTE_KINDS[c.v]}
                  </AttributeType>
                ))}
              </div>
              {currentFieldAlternatives?.length > 0 && (
                <InputFieldDescription style={{ fontSize: 12 }}>
                  <strong>OBS:</strong> Du kan inte ändra ett fälts typ efter
                  att alternativ är tillagda. Ta bort alla alternativ för att
                  byta fälttyp.
                </InputFieldDescription>
              )}

              {currentField.kind === 0 && (
                <InputFieldDescription>
                  Värde: Ett värde i siffror. Kan användas för att representera
                  belopp, storlek osv. Lägg till alternativ för att göra värdet
                  på fältet valbart bland alternativ istället för att anges
                  fritt.
                </InputFieldDescription>
              )}

              {currentField.kind === 1 && (
                <InputFieldDescription>
                  Textfält: Enkelt textfält som kan användas för att skriva in
                  information i textform. Lägg till alternativ för att göra
                  värdet på fältet valbart bland alternativ istället för
                  fritext.
                </InputFieldDescription>
              )}

              {currentField.kind === 3 && (
                <InputFieldDescription>
                  Intervall: Ett intervall mellan två siffervärden. Kan t.ex.
                  användas för omsättning, storlek, antal anställda osv. Lägg
                  till alternativ för att göra intervallet på fältet valbart
                  bland alternativ istället för att anges fritt.
                </InputFieldDescription>
              )}

              {currentField.kind != null && (
                <>
                  <AlternativesTitle>
                    <div>Alternativ</div>
                    <TextButton
                      title="Lägg till alternativ"
                      iconType="add"
                      clicked={handleAddAttribute}
                    />
                  </AlternativesTitle>
                  <AlternativesWrapper>
                    {currentFieldAlternatives?.length ? (
                      currentFieldAlternatives.map((alternative, i) => (
                        <Alternative>
                          {renderAlternativeField(alternative, i)}

                          <RemoveButton
                            onClick={() => removeFieldAlternative(i)}
                          />
                        </Alternative>
                      ))
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          color: "#5C667B",
                          fontSize: 12,
                          fontWeight: 500,
                          justifyContent: "center",
                          padding: "0 24px",
                          maxWidth: 400,
                          margin: "0 auto",
                          height: "100px",
                          textAlign: "center",
                        }}
                      >
                        Inga alternativ tillagda. Detta innebär att vilket värde
                        som helst (av rätt typ) får skrivas in i fältet.
                      </div>
                    )}
                  </AlternativesWrapper>
                </>
              )}
            </InputSpacing>
          </>
        )}
      </StandardModal>

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Fält för leads i projektet</OverviewTitle>
          <OverviewSubtitle>
            Lägg till ytterligare fält för leads i projektet om det behövs.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <InfoBox
        boxTheme="info"
        title="Observera att följande fält alltid finns på leadsen"
        text="Förnamn, Efternamn, Bolagsnamn, Kontaktpersoner, Nuvarande
        hyresgäst, E-post, Telefon, Skickad av, Lägenhet, Lokal,
        Parkeringsplats, Budget, Förväntat konverteringsdatum, Ansvarig
        förvaltare."
      />

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Ytterligare fält</OverviewTitle>
          <PrimaryButton
            title="Lägg till fält"
            clicked={handleCreateNewField}
          />
        </OverviewTitleWrapper>

        <AttributeContainer>
          {(dynamicAttributes || []).length > 0 ? (
            dynamicAttributes.map((attribute) => (
              <AttributeWrapper
                onClick={
                  attribute?._index != null
                    ? () => setCreateOpen(attribute?._index)
                    : null
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <AttributeType selected={true} kind={attribute.kind}>
                    {ATTRIBUTE_KINDS[attribute.kind]}
                  </AttributeType>

                  <RemoveButton onClick={(e) => removeField(attribute, e)} />
                </div>
                <AttributeTitle>{attribute.title}</AttributeTitle>
                <AttributeDescription>
                  {attribute.description}
                </AttributeDescription>
                {attribute.pipeattributechoices_set?.length > 0 ? (
                  <AttributeDescription>
                    {attribute.pipeattributechoices_set?.length} alternativ
                  </AttributeDescription>
                ) : (
                  <>
                    {attribute.kind === 0 && (
                      <AttributeDescription>Fritt värde</AttributeDescription>
                    )}
                    {attribute.kind === 1 && (
                      <AttributeDescription>Fritext</AttributeDescription>
                    )}
                    {attribute.kind === 3 && (
                      <AttributeDescription>
                        Fritt intervall
                      </AttributeDescription>
                    )}
                  </>
                )}
              </AttributeWrapper>
            ))
          ) : (
            <div>Inga ytterligare fält i detta projekt</div>
          )}
        </AttributeContainer>
      </InnerBox>
    </>
  );
};
