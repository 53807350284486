import * as React from "react";

import {
    OverviewSubtitle,
    OverviewTitle,
    OverviewTitleWithSubtitleWrapper,
    OverviewTitleWrapper,
  } from "../../../components/Details/OverviewInfo/styles";
import {
    PrimaryButton,
    TextButton,
    } from "../../../components/Forms/Base/Buttons";
import {
    DetailInnerWrapper,
    DetailPageBox,
    DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";

import { useKeyPermission } from "../../../store/keypermission";

import { retrieveDevices } from "../../../store/parakey";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { retrieveParakeyAccess } from "../../../store/parakey/utils";
import { BooleanLabel, LinkedObject, TimeCell } from "../../../components/Displays";
import { buildQueryString } from "../../../store/base";
import { useFilteredKeyDevices } from "../../../store/keydevice";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as industrialDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as commonDetailUrl } from "../../../store/commonAreas";

export default function ParakeyPermissionDetail({permissionId}) {
  const [permission, loading] = useKeyPermission(permissionId);

  const [parakeyDevices, setParakeyDevices] = React.useState(undefined);
  const [parakeyPermission, setParakeyPermission] = React.useState(undefined);

  const keyDeviceQueryString = buildQueryString({parakey_id__in:!parakeyDevices ? [] : parakeyDevices.map((item) => item.id)});
  const [keyDevices, keyDevicesLoading] = useFilteredKeyDevices(keyDeviceQueryString);

  const loadParakeyDevices = async () => {
    const data = await retrieveDevices(permission.parakey_id);
    setParakeyDevices(data);
  }
  const loadParakeyPermission = async() => {
    const data = await retrieveParakeyAccess(permission.parakey_id);
    setParakeyPermission(data);
  }

  React.useEffect(() => {
    if (permission?.parakey_id && parakeyDevices === undefined) {
      loadParakeyDevices();
    }
    if (permission?.parakey_id && parakeyPermission === undefined) {
      loadParakeyPermission();
    }
  }, [permission]);

  const parakeyDeviceInfoObj = {
    "Parakey Enheter": (parakeyDevices ?? []).map((device) => {
      const matchingKeyDevice = !keyDevices ? undefined : keyDevices.find((dvc) => dvc.parakey_id === device.id);
      let val = device.category;
      if (matchingKeyDevice) {
        if (matchingKeyDevice.apartment) {
          val = <><LinkedObject obj={matchingKeyDevice.apartment} urlMethod={apartmentDetailUrl}/>{` (${device.category})`}</>
        } else if (matchingKeyDevice.industrial_premises) {
          val = <><LinkedObject obj={matchingKeyDevice.industrial_premises} urlMethod={industrialDetailUrl}/>{` (${device.category})`}</>
        } else if (matchingKeyDevice.common_area) {
          val = <><LinkedObject obj={matchingKeyDevice.common_area} urlMethod={commonDetailUrl}/>{` (${device.category})`}</>
        }
      }
      return {
        title: device.name,
        value: val
      }
    })
  };

  const parakeyPermInfoObj = {
    "Detaljer":[
      {
        title: "Namn",
        value: parakeyPermission?.name ?? "-"
      },
      {
        title: "ID",
        value: parakeyPermission?.id ?? "-"
      },
      {
        title: "Gäller f.o.m",
        value: parakeyPermission?.startDate ? <TimeCell date={parakeyPermission.startDate}/> : "-"
      },
      {
        title: "Gäller t.o.m",
        value: parakeyPermission?.endDate ? <TimeCell date={parakeyPermission.endDate}/> : "-"
      },
      {
        title: "Aktiv",
        value: parakeyPermission?.active != null ? <BooleanLabel value={parakeyPermission.active} onLabel={"Aktiv"} offLabel={"Inaktiv"}/> : "-"
      }
    ]
  }

  const _deviceWithDomain = !!parakeyDevices?.length ? parakeyDevices.find((c) => c?.domain_ids?.length) : undefined;
  const domainId = _deviceWithDomain == null ? null : _deviceWithDomain.domain_ids[0];

  return (
            <>
            <OverviewTitleWrapper>
                <OverviewTitle>Parakey-styrd behörighet</OverviewTitle>
            </OverviewTitleWrapper>
            <div style={{marginBottom: "16px"}}>
                <div>För en Parakey-styrd behörighet justeras dem anknyta enheterna i</div>
                <TextButton
                title="Parakey"
                clicked={() => window.open(!!domainId ? `https://my.parakey.co/#!/place/${domainId}/keys/${permission.parakey_id}` : "https://my.parakey.co", "_blank").focus()}
                iconType="arrow-right"
                iconPlacement="right"
                />
            </div>
            <div style={{marginBottom: "16px"}}>
                <DetailInfo infoObj={parakeyPermInfoObj}/>
            </div>
                {
                !!parakeyDevices?.length ? <DetailInfo infoObj={parakeyDeviceInfoObj}/> : <div>Enheter saknas</div>
                }
            </>
  );
}
