import * as React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import sv from "date-fns/locale/sv";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { format as formatDate } from "date-fns";
import {
  CalendarIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
registerLocale("sv", sv);

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const DATE_LENGTHS_CHECK = {
  monthYear: 6,
  year: 3,
  default: 9,
};

const DEFAULT_FORMAT = "yyyy-MM-dd";
export default function DateSelect({
  title,
  forceRequired,
  fieldKey,
  instructionsKey,
  storeName,
  description,
  method,
  format,
  placeholder,
  forceError,
  showYearPicker,
  showMonthYearPicker,
}) {
  const dispatch = useDispatch();
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const pickerOpenRef = React.useRef(pickerOpen);
  React.useEffect(() => {
    pickerOpenRef.current = pickerOpen;
  }, [pickerOpen]);

  const usedFormat = format || DEFAULT_FORMAT;

  const wrapperRef = React.useRef();

  const instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });
  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey });

  const [inputValue, setInputValue] = React.useState(
    value === null || value === undefined ? "" : value
  );

  React.useEffect(() => {
    let date = new Date(value);

    if (
      isValidDate(date) &&
      value !== null &&
      value !== undefined &&
      value?.length !== 0
    ) {
      setInputValue(
        formatDate(
          date,
          showYearPicker ? "yyyy" : showMonthYearPicker ? "yyyy-MM" : usedFormat
        )
      );
      return;
    }

    setInputValue(value === null || value === undefined ? "" : value);
  }, [value]);

  const onChange = (date) => {
    setInputValue(
      formatDate(
        date,
        showYearPicker ? "yyyy" : showMonthYearPicker ? "yyyy-MM" : usedFormat
      )
    );
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: formatDate(date, usedFormat),
        },
      })
    );
  };

  const onInputChange = (evt) => {
    let val = evt.target.value;

    const len = showYearPicker
      ? DATE_LENGTHS_CHECK.year
      : showMonthYearPicker
      ? DATE_LENGTHS_CHECK.monthYear
      : DATE_LENGTHS_CHECK.default;

    if (val.length > len) {
      let date = new Date(val);

      if (isValidDate(date)) {
        return onChange(date);
      }
    }
    setInputValue(evt.target.value);
  };

  const onClear = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: instructions?._allowNull ? null : undefined,
        },
      })
    );
  };

  const clickListener = React.useCallback((evt) => {
    if (!pickerOpenRef.current) return;

    let foundWrapper = false;

    let currentElem = evt.target;

    while (currentElem.parentElement) {
      if (currentElem === wrapperRef.current) {
        foundWrapper = true;
        break;
      }
      currentElem = currentElem.parentElement;
    }

    if (!foundWrapper) {
      setPickerOpen(false);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("click", clickListener);

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, []);

  if (!instructions || instructions?._readOnly) return null;

  return (
    <div className="relative" ref={wrapperRef}>
      <div>
        <div className="font-medium text-gray-900 text-sm flex items-center">
          {(error || forceError) && (
            <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
          )}{" "}
          {title}
          {instructions._required || forceRequired ? "*" : ""}
        </div>
        {description && (
          <div className="text-xs font-normal text-gray-500 bg-transparent mb-1 ">
            {description}
          </div>
        )}
      </div>

      {(error || forceError) && (
        <div className="text-xs font-normal text-red-600 mb-1">
          {forceError || error}
        </div>
      )}

      <button
        onClick={(e) => {
          e.preventDefault();
          setPickerOpen(true);
        }}
        className={`inline-flex w-full bg-white items-center border border-solid border-slate-300 text-gray-900  rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center group`}
      >
        <CalendarIcon width={16} className="mr-1" />
        {pickerOpen ? (
          <input
            className="outline-none max-w-[125px] box-border group-hover:bg-sky-100"
            autoFocus={true}
            value={inputValue}
            onChange={(evt) => onInputChange(evt)}
            placeholder={(showYearPicker
              ? "yyyy"
              : showMonthYearPicker
              ? "yyyy-MM"
              : usedFormat
            ).toUpperCase()}
          />
        ) : (
          <>
            {value
              ? formatDate(
                  new Date(value),
                  showYearPicker
                    ? "yyyy"
                    : showMonthYearPicker
                    ? "yyyy-MM"
                    : usedFormat
                )
              : placeholder || "Välj ett datum"}
          </>
        )}
        {value && (
          <XMarkIcon
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
            width={16}
            className="ml-auto"
          />
        )}
      </button>

      {pickerOpen && (
        <div className="absolute z-20 top-[100%]">
          <DatePicker
            inline
            onSelect={() => setPickerOpen(false)}
            selected={value ? new Date(value) : null}
            onChange={onChange} //only when value has changed
            locale="sv"
            showYearPicker={showYearPicker}
            showMonthYearPicker={showMonthYearPicker}
          />
        </div>
      )}
    </div>
  );
}
