import * as React from "react";
import * as SC from "./styles";

import blueprintIcon from "../../../assets/svg/blueprint.svg";

export default function WaitingBlueprint() {
  return (
    <SC.NoBuildingsWrapper>
      <SC.TitleIcon src={blueprintIcon} />
      <SC.Title>Väntar på behandling</SC.Title>
      <SC.Subtitle>
        Vi har tagit emot din planritning men ännu inte börjat behandla den.
      </SC.Subtitle>
    </SC.NoBuildingsWrapper>
  );
}
