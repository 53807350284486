import * as React from "react";
import { useDispatch } from "react-redux";

import * as SC from "./styles";
import changeIcon from "../../../../assets/svg/shuffle.svg";
import uploadFile from "../../../../assets/svg/add-file.svg";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { useFilePicker } from "../../../../hooks/useFilePicker";
import { TextButton } from "../Buttons";

const MAX_FILE_SIZE = 100;
const ALLOWED_FORMATS = [".pdf"];

export default ({
  storeName,
  method,
  fieldKey,
  instructionsKey,
  allowedFormats,
  title,
  description,
  manualInstructions,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  let instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });
  instructions = instructions ?? manualInstructions;

  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey });

  const { files, onClick, errors, HiddenFileInput, onClear } = useFilePicker({
    maxFileSize: MAX_FILE_SIZE,
  });

  const formats = allowedFormats || ALLOWED_FORMATS;

  React.useEffect(() => {
    handleChange();
  }, [files]);

  const handleChange = async () => {
    if (files.length === 0) {
      return;
    } else {
      setLoading(true);
      const def = instructions?._allowNull ? null : undefined;
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: def } })
      );
      const restFile = await toBase64(files[0]);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [fieldKey]: restFile.name,
            [`${fieldKey}Data_`]: restFile.data,
          },
        })
      );
      setLoading(false);
    }
  };

  const handleClear = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [fieldKey]: null, [`${fieldKey}Data_`]: undefined },
      })
    );
  };

  const toBase64 = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileData = {
          name: file.name,
          data: reader.result.toString(),
        };

        resolve(fileData);
      };
    });
  };

  if (!instructions) {
    return null;
  }
  if (instructions._readOnly) {
    return null;
  }

  let existingFileName = value?.get?.split("/Docs/")[1];
  existingFileName = existingFileName?.split("?")[0];

  return (
    <SC.InputSpacing>
      <SC.InputFieldTitle>
        {title}
        {instructions._required ? "*" : ""}
      </SC.InputFieldTitle>
      {description && (
        <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
      )}
      <SC.InputFieldDescription>
        Tillåtna filformat: {formats.join(", ")}. Maximal storlek:{" "}
        {MAX_FILE_SIZE}mb.
      </SC.InputFieldDescription>
      <SC.FileUploaderWrapper onClick={onClick}>
        {loading && <OverlaySpinner />}
        <SC.FileUploaderIcon iconSrc={value?.get ? changeIcon : uploadFile} />
        <SC.FileUploaderLabel>
          {loading ? "Laddar..." : value ? "Byt ut fil" : "Välj en fil"}
        </SC.FileUploaderLabel>
        {value && (
          <>
            <div style={{ fontSize: 11, marginTop: 8, textAlign: "center" }}>
              Vald fil:
              <br />{" "}
              {existingFileName
                ? existingFileName
                : typeof value === "string"
                ? value
                : "Fil utan namn"}
            </div>

            <TextButton
              extraStyle={{ margin: "2px auto" }}
              red
              title="Ta bort fil"
              clicked={() => {
                // filepicker
                onClear();
                // internal
                handleClear();
              }}
              iconType="close"
              iconPlacement="right"
            />
          </>
        )}
        <HiddenFileInput accept={formats.join(", ")} multiple={false} />
      </SC.FileUploaderWrapper>
      {errors?.hasInvalidFileSize && (
        <SC.ErrorMessage>
          Filen är för stor (får vara max {MAX_FILE_SIZE}mb)
        </SC.ErrorMessage>
      )}
      {errors?.hasInvalidImage && (
        <SC.ErrorMessage>
          Filen är inte tillåten. Välj en ny i ett tillåtet format och storlek.
        </SC.ErrorMessage>
      )}
      {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.InputSpacing>
  );
};
