import constants from "./constants";

const INITIAL_STATE = {
  suggestionSearchResults: [],
  infoTraderData: {},

  infoTraderDataLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_SEARCH_SUGGESTIONS:
      return { ...state, suggestionSearchResults: payload.results };
    case constants.SET_INFOTRADER_DATA:
      return {
        ...state,
        infoTraderData: payload.data,
        infoTraderDataLoading: false,
      };
    case constants.SET_LOADING_INFOTRADER_DATA:
      return { ...state, infoTraderDataLoading: payload.loading };

    default:
      return state;
  }
};
