import * as React from "react";
import { useHistory, useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import CustomDimensionEntryForm from "../../../components/Forms/InvoicingCustomDimensionEntry/ChapterForm/ModalForm";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

import {
  useInvoicingCustomDimensionEntry,
  constants,
} from "../../../store/invoicingCustomDimensionEntries";
import { useInvoicingCustomDimension } from "../../../store/invoicingCustomDimensions";

export default function InvoicingCustomDimensionEntry() {
  const { customDimensionEntryId } = useParams();

  const [editEntryOpen, setEditEntryOpen] = React.useState(false);
  const [customDimensionEntry] = useInvoicingCustomDimensionEntry(
    customDimensionEntryId
  );
  const [customDimension] = useInvoicingCustomDimension(
    customDimensionEntry?.dimension?.id
  );
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const { replace } = useHistory();

  const detailInfo = {
    Dimension: [
      {
        title: "Dimension",
        value: customDimension?.title,
      },
      {
        title: "Kod",
        value: customDimensionEntry?.dimension_entry_id,
      },
      {
        title: "Beskrivning",
        value: customDimensionEntry?.description,
      },
    ],
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={customDimensionEntry}
        constants={constants}
        deletedCallback={() =>
          replace("/rental-notifications/invoicing-objects")
        }
      />

      <CustomDimensionEntryForm
        method="PATCH"
        id={customDimensionEntryId}
        customDimension={customDimension}
        instance={customDimensionEntry}
        isOpen={editEntryOpen}
        onCheckout={() => setEditEntryOpen(false)}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Instans ${
            customDimensionEntry?.str_representation || "Laddar..."
          }`}
          breadCrumbs={[
            {
              url: "/rental-notifications/invoicing-objects",
              label: "Produkter & Bokföring",
            },
            {
              url: `/rental-notifications/invoicing-objects/custom-dimensions/${customDimensionEntry?.dimension?.id}`,
              label: `Anpassad dimension ${customDimension?.str_representation}`,
            },
            {
              label: `${
                customDimensionEntry?.str_representation || "Laddar..."
              }`,
            },
          ]}
        />

        <DetailInnerWrapper>
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>

              <PopupButton
                leftAligned
                title="Hantera"
                actions={[
                  {
                    name: "Redigera",
                    onClick: () => setEditEntryOpen(true),
                  },
                  {
                    name: "Radera",
                    isDelete: true,
                    onClick: () => setDeleteOpen(true),
                  },
                ]}
              />
            </OverviewTitleWrapper>

            <DetailInfo infoObj={detailInfo} />
          </DetailPageBox>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
