import { useDispatch, useSelector } from "react-redux";

import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
  buildQueryString,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
  fetchOverviewStats,
} from "../store/actions";
import constants from "../store/constants";
import { getRoundingOverviewStatsProcessName } from "../utils";

export function useRoundingErrand(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useRoundingErrands(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredRoundingErrands(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useRoundingErrandsPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useRoundingErrandsPaginationCount({
  status,
  isGov,
  realEstates,
  settingId,
}) {
  let queryString = "";
  if (settingId) {
    queryString = buildQueryString({
      setting: settingId,
      realestate_ids: realEstates,
      is_not_gov: !isGov || true,
      _page: 1,
      _page_size: 0,
    });
  } else {
    queryString = buildQueryString({
      status: status,
      realestate_ids: realEstates,
      is_not_gov: !isGov || true,
      _page: 1,
      _page_size: 0,
    });
  }

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}

export function useRoundingErrandOverviewStats() {
  const dispatch = useDispatch();
  const possibleProcessName = getRoundingOverviewStatsProcessName();
  const storeName = constants.STORE_NAME;

  const existing = useSelector((state) => state[storeName].overviewStats);
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(possibleProcessName)
  );

  // first check if it exists in store
  if (existing) {
    return [existing, isFetching];
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result

  if (isFetching) {
    return [undefined, isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(fetchOverviewStats());

  return [undefined, isFetching];
}
