export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BASICDOC",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BASICDOC",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BASICDOC",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BASICDOC",
  DESTROY_FORM: "DESTROY_FORM_BASICDOC",
  SET_FORM_ERROR: "SET_FORM_ERROR_BASICDOC",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BASICDOC",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BASICDOC",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BASICDOC",
  RESET_STATE: "RESET_STATE_BASICDOC",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BASICDOC",
  REMOVE_OBJECT: "REMOVE_OBJECT_BASICDOC",
  CLEAR_FETCHED: "CLEAR_FETCHED_BASICDOC",

  LIST_URL: "/standard/lowlevel/basicdoc/list/",
  POST_URL: "/standard/lowlevel/basicdoc/",
  PATCH_URL: "/standard/lowlevel/basicdoc/",
  GET_URL: "/standard/lowlevel/basicdoc/",

  STORE_NAME: "basicDocs",
};
