import * as React from "react";
import * as SC from "./styles";

export default function ToggleYearlyMonthly({
  isYearly,
  setIsYearly,
  extraStyles = {},
}) {
  return (
    <SC.ToggleBoxWrapper style={{ ...extraStyles }}>
      <SC.ToggleBoxItem
        selected={isYearly}
        onClick={(e) => {
          e.stopPropagation();
          setIsYearly(true);
        }}
      >
        Per år
      </SC.ToggleBoxItem>
      <SC.ToggleBoxItem
        selected={!isYearly}
        onClick={(e) => {
          e.stopPropagation();
          setIsYearly(false);
        }}
      >
        Per månad
      </SC.ToggleBoxItem>
    </SC.ToggleBoxWrapper>
  );
}
