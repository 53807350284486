import { isEqual } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAllValues } from "src/store/widgetPlacements";
import { constants } from "../store/overview";
import { setActiveFilteredRealEstates } from "../store/overview/store/actions";
import clearTableFilters from "../utils/clearTableFilters";

export default function useActiveFilters() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const filteredRealEstateIds = useSelector(
    (state) => state[storeName].filteredRealEstateIds
  );
  const filteredCompanyIds = useSelector(
    (state) => state[storeName].filteredCompanyIds
  );

  const filteredBrfCompanyIds = useSelector(
    (state) => state[storeName].filteredBrfCompanyIds
  );

  const [currentCompanyIds, setCurrentCompanyIds] = React.useState([]);
  const [currentRealEstateIds, setCurrentRealEstateIds] = React.useState([]);

  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    const persistantFilters = JSON.parse(
      localStorage.getItem("active_filters")
    );

    if (!persistantFilters) return;
    setCurrentCompanyIds(persistantFilters.filteredCompanyIds);
    setCurrentRealEstateIds(persistantFilters.filteredRealEstateIds);
    setHasMounted(true);
    if (
      !isEqual(persistantFilters, {
        filteredCompanyIds,
        filteredBrfCompanyIds,
        filteredRealEstateIds,
      })
    ) {
      dispatch(
        setActiveFilteredRealEstates({
          realEstateIds: persistantFilters.filteredRealEstateIds,
          companyIds: persistantFilters.filteredCompanyIds,
          brfCompanyIds: persistantFilters.filteredBrfCompanyIds,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (!hasMounted) return;
    if (
      !isEqual(filteredCompanyIds, currentCompanyIds) ||
      !isEqual(filteredRealEstateIds, currentRealEstateIds)
    ) {
      setCurrentCompanyIds(filteredCompanyIds);
      setCurrentRealEstateIds(filteredRealEstateIds);
      console.log("CLEARING 1");
      dispatch(clearAllValues());
    }
  }, [
    filteredRealEstateIds,
    filteredCompanyIds,
    filteredBrfCompanyIds,
    hasMounted,
  ]);

  React.useEffect(() => {
    if (filteredRealEstateIds || filteredBrfCompanyIds || filteredCompanyIds) {
      localStorage.setItem(
        "active_filters",
        JSON.stringify({
          filteredCompanyIds,
          filteredBrfCompanyIds,
          filteredRealEstateIds,
        })
      );
    }
  }, [filteredRealEstateIds, filteredCompanyIds, filteredBrfCompanyIds]);

  const resetFilters = () => {
    clearTableFilters();
    localStorage.setItem(
      "active_filters",
      JSON.stringify({
        filteredCompanyIds: null,
        filteredBrfCompanyIds: null,
        filteredRealEstateIds: null,
      })
    );
    dispatch(
      setActiveFilteredRealEstates({
        realEstateIds: null,
        companyIds: null,
        brfCompanyIds: null,
      })
    );
    console.log("CLEARING 2");
    dispatch(clearAllValues());
  };

  return {
    filterActive:
      !!filteredCompanyIds ||
      !!filteredRealEstateIds ||
      !!filteredBrfCompanyIds,
    filteredRealEstates: filteredRealEstateIds?.filter((r) => !!r), // remove null values
    // not really filtered by companies or brfCompanies but by realestates under a company, this is for display only
    filteredCompanies: filteredCompanyIds,
    filteredBrfCompanies: filteredBrfCompanyIds,
    resetFilters,
  };
}
