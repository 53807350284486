import * as React from "react";
import * as SC from "./styles";

import { PanZoom } from "react-easy-panzoom";
import { SvgProxy } from "react-svgmt";
import axios from "axios";

import generateProxies from "../proxies";
import useResizeObserver from "../../../hooks/useResizeObserver";

import zoomInIcon from "../../../assets/svg/zoom-in.svg";
import zoomOutIcon from "../../../assets/svg/zoom-out.svg";
import rotateRightIcon from "../../../assets/svg/rotate-right.svg";
import rotateLeftIcon from "../../../assets/svg/rotate-left.svg";

import { PrimaryButton, RemoveButton } from "../../Forms/Base/Buttons";
import PremisesInfo from "./PremisesInfo";

import { useAnyPermissionCheck, usePermissionCheck } from "../../../store/base";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

const viewBoxRegex = new RegExp('(?:viewbox=")([^"]+)(?=#*)');

export default ({
  blueprint,
  currentRooms,
  handleBlueprintState,
  BLUEPRINT_STATES,
  setHandleBlueprintState,
  selectedPremises,
  selectedRooms,
  currentBuildingPremises,
  handleRoomClicked,
  handleOutsideRoomClicked,
  openConnectModal,
  saveEdit,
}) => {
  const dispatch = useDispatch();
  const panZoomRef = React.useRef();
  const containerRef = React.useRef();
  const [rotation, setRotation] = React.useState(0);
  const [pageDimensions, setPageDimensions] = React.useState(false);
  const [SVGLoaded, setSVGLoaded] = React.useState(false);
  const [SVG, setSVG] = React.useState(null);
  const [SVGViewBox, setSVGViewBox] = React.useState(null);

  const canHandleAnyPremises = useAnyPermissionCheck([
    "change_can_baseobject",
    "add_can_baseobject",
  ]);

  // for apartment, indp and commanArea - All share permission
  const canChange = usePermissionCheck("change_can_baseobject");

  const resizeCallback = (event) => {
    if (!event.length) return;
    const { contentRect } = event[0];

    if (!contentRect) return;
    const { width, height } = contentRect;
    setPageDimensions({ width, height: (height || 1000) - 90 });
  };

  React.useEffect(() => {
    getSvgXml();

    return () => {
      setSVGLoaded(false);
      setSVG(null);
    };
  }, [blueprint.svg_file]);

  React.useEffect(() => {
    getDimensions();

    return () => {
      setPageDimensions(false);
    };
  }, []);

  const getSvgXml = async () => {
    try {
      const svg = await axios.get(blueprint.svg_file?.get, {
        headers: {
          "cross-origin": "Anonnymous",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const svgXML = svg.data;
      const viewBox = svgXML.match(viewBoxRegex)[1];

      setSVGViewBox(viewBox);
      setSVG(svgXML);
      setSVGLoaded(true);
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel",
          description: "Planritningen kunde inte hämtas",
        })
      );
    }
  };

  const handleZoomIn = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn(5);
    }
  };

  const handleZoomOut = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn(-5);
    }
  };

  const rotateRight = () => {
    setRotation(rotation + 90);
  };

  const rotateLeft = () => {
    setRotation(rotation - 90);
  };

  const handleClick = (e) => {
    const id = parseInt(e.target["id"]);

    if (!isNaN(id)) {
      const room = currentRooms.find((r) => r.id == id);
      handleRoomClicked(room);
    } else {
      handleOutsideRoomClicked();
    }
  };

  const Proxy = (data) => {
    return (
      <SvgProxy
        key={data.selector}
        selector={data.selector}
        style={data.style}
      />
    );
  };

  const proxies = generateProxies.init({
    currentRooms,
    currentBuildingPremises,
    handleBlueprintState,
    selectedPremises,
    selectedRooms,
    BLUEPRINT_STATES,
  });

  const renderProxies = proxies && proxies.map(Proxy);

  useResizeObserver({ callback: resizeCallback, element: containerRef });

  const getHeight = () => {
    if (containerRef?.current) {
      // 90 is blueprint menu height
      const height = window.innerHeight;

      return height;
    } else {
      return window.innerHeight - 150;
    }
  };

  const getDimensions = () => {
    if (containerRef?.current) {
      const { height, width } = containerRef.current.getBoundingClientRect();

      setPageDimensions({ height, width });
    }
  };

  const renderHandleButtons = () => {
    const permModel = selectedPremises?.kind;

    let hasPerm = false;

    if (permModel === "commonarea") {
      hasPerm = canChange;
    } else if (permModel === "industrialpremises") {
      hasPerm = canChange;
    } else if (permModel === "apartment") {
      hasPerm = canChange;
    }

    if (selectedPremises && handleBlueprintState === BLUEPRINT_STATES.EDITING) {
      return (
        <>
          {hasPerm && (
            <PrimaryButton
              extraStyle={{ marginRight: "12px" }}
              title="Uppdatera rum"
              clicked={saveEdit}
            />
          )}
          <RemoveButton
            title="Avbryt"
            clicked={() => setHandleBlueprintState(BLUEPRINT_STATES.PASSIVE)}
          />
        </>
      );
    }

    if (selectedPremises) {
      if (!hasPerm) return null;

      return (
        <PrimaryButton
          extraStyle={{ marginRight: "12px" }}
          title="Redigera rum"
          clicked={() => {
            setHandleBlueprintState(BLUEPRINT_STATES.EDITING);
          }}
        />
      );
    }

    if (handleBlueprintState === BLUEPRINT_STATES.CREATING) {
      if (!canHandleAnyPremises) return null;

      return (
        <>
          <PrimaryButton
            extraStyle={{ marginRight: "12px" }}
            disabled={selectedRooms.length === 0}
            title={`Koppla ${
              selectedRooms.length ? selectedRooms.length : ""
            } rum`}
            clicked={openConnectModal}
          />
          <RemoveButton
            title="Avbryt"
            clicked={() => setHandleBlueprintState(BLUEPRINT_STATES.PASSIVE)}
          />
        </>
      );
    }

    if (handleBlueprintState === BLUEPRINT_STATES.PASSIVE) {
      if (!canHandleAnyPremises) return null;

      return (
        <PrimaryButton
          clicked={() => setHandleBlueprintState(BLUEPRINT_STATES.CREATING)}
          title="Koppla rum till hyresobjekt"
        />
      );
    }
  };

  return (
    <SC.BlueprintWrapper
      id="bpwrapper"
      height={getHeight()}
      ref={containerRef}
      onClick={handleClick}
    >
      <SC.ConnectWrapper>{renderHandleButtons()}</SC.ConnectWrapper>
      <SC.ControllerWrapper>
        <SC.ControllerBox onClick={() => handleZoomIn()} src={zoomInIcon} />
        <SC.ControllerBox onClick={() => handleZoomOut()} src={zoomOutIcon} />
        <SC.ControllerBox onClick={() => rotateRight()} src={rotateRightIcon} />
        <SC.ControllerBox onClick={() => rotateLeft()} src={rotateLeftIcon} />
      </SC.ControllerWrapper>
      <PremisesInfo premises={selectedPremises} />
      {pageDimensions && SVGLoaded ? (
        <PanZoom
          id="pzoom"
          className="panzoom"
          style={{ height: "100%", zIndex: 0, width: "100%" }}
          ref={panZoomRef}
          autoCenter={true}
          autoCenterZoomLevel={1}
          boundaryRatioVertical={0.5}
          boundaryRatioHorizontal={0.5}
          enableBoundingBox
          minZoom={0.1}
          maxZoom={3}
          disableScrollZoom={true}
        >
          <SC.StyledSVGLoader
            id="svgloader"
            svgXML={SVG}
            viewBox={SVGViewBox}
            height={pageDimensions.height}
            width={pageDimensions.width}
            preserveAspectRatio="xMinYMin meet"
            rotation={rotation}
          >
            {renderProxies}
          </SC.StyledSVGLoader>
        </PanZoom>
      ) : null}
    </SC.BlueprintWrapper>
  );
};
