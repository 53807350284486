import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  useCalendarEventForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/calendarEvents";
import renderChapter from "./renderChapter";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { cloneDeep } from "lodash";

export default function CalendarEventForm({
  method,
  id,
  isOpen,
  onCheckout,
  instance,
  calendars,
  modalInModal,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useCalendarEventForm(method, id));

  const selectedCalendar = useFormInstanceField({
    storeName,
    fieldKey: "calendar",
  });

  const startDate = useFormInstanceField({
    storeName,
    fieldKey: "start",
  });

  const endDate = useFormInstanceField({
    storeName,
    fieldKey: "end",
  });

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (dataClone.participants?.length) {
      const p = dataClone.participants.map((user) => ({ user: user }));
      dataClone.participants = p;
    }

    return dataClone;
  };

  React.useEffect(() => {
    if (formLoaded && instance && isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    } else if (formLoaded && calendars?.length === 1) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: { calendar: calendars[0] },
        })
      );
    }
  }, [formLoaded, isOpen, instance]);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const onSubmit = () => {
    if (!selectedCalendar) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Välj en kalender",
          description:
            "För att kunna spara händelsen krävs det att en kalender är vald",
        })
      );

      return;
    }

    if (!startDate || !endDate) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Välj start och slut på händelsen",
          description:
            "För att kunna spara händelsen krävs det att ett tidsintervall är valt",
        })
      );

      return;
    }

    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            return preProcess(data, "POST");
          },
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            return preProcess(data, "PATCH");
          },
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method, calendars)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Lägg till händelse" : "Uppdatera händelse"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
      modalInModal={modalInModal}
    />
  );
}
