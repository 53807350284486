import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { useFormInstanceField } from "../../../store/base";
import Alert, { ALERT_TYPES } from "../../Alert/Alert";
import { CATEGORY_MAP, lightOrDark } from "../../DigitalDoc/utils";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import DigitalDocPartyFields from "./PartyFields";

export default function DigitalDocHandleFields({
  storeName,
  isTemplateEditor,
  isFastDoc,
  onDone,
  pageBounds,
}) {
  const [activePartyIdx, setActivePartyIdx] = React.useState(null);
  const parties = useFormInstanceField({
    storeName,
    fieldKey: "parties",
  });

  const renderPartyName = (p, idx) => {
    const pFields = p.fields;

    const firstName = pFields.find((f) => f.kind === "first_name")?.value;
    const lastName = pFields.find((f) => f.kind === "last_name")?.value;
    const companyName = pFields.find((f) => f.kind === "company")?.value;

    let str = "";
    if (firstName) {
      str += `${firstName} `;
    }
    if (lastName) str += `${lastName} `;

    if (companyName) str += `${companyName} `;

    if (str) return str + `(${CATEGORY_MAP[p.category]})`;

    return `Part ${idx + 1} (${CATEGORY_MAP[p.category]})`;
  };

  if (activePartyIdx != null) {
    return (
      <DigitalDocPartyFields
        onDone={() => setActivePartyIdx(null)}
        isTemplateEditor={isTemplateEditor}
        partyIdx={activePartyIdx}
        storeName={storeName}
        partyName={renderPartyName(parties?.[activePartyIdx], activePartyIdx)}
        pageBounds={pageBounds}
        isFastDoc={isFastDoc}
      />
    );
  }

  return (
    <div className="grid grid-cols-1 gap-2">
      <PrimaryBtn secondary onClick={onDone}>
        <ChevronLeftIcon width={16} className="mr-1" /> Tillbaka till menyn
      </PrimaryBtn>

      <Alert title="Välj part" type={ALERT_TYPES.INFO}>
        Välj en part att hantera fält för. Fält som tillhör avtalet, objektet
        eller uthyrande bolag ligger på hyresvärden.
      </Alert>

      {parties?.map((party, idx) => (
        <button
          onClick={() => setActivePartyIdx(idx)}
          key={party.id || idx}
          style={{
            backgroundColor: party._color,
            color: lightOrDark(party._color) === "light" ? "#444a55" : "#fff",
            borderColor:
              lightOrDark(party._color) === "light" ? "#444a55" : "#fff",
          }}
          className="
         border border-solid hover:opacity-70  focus:ring-4 focus:outline-none font-medium rounded text-sm px-4 py-2 text-center inline-flex items-center"
        >
          {renderPartyName(party, idx)}

          <ChevronRightIcon className="ml-auto" width={16} />
        </button>
      ))}
    </div>
  );
}
