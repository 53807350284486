import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TextField, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingCustomDimensionEntries";

export default ({ method, customDimension }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>
        Instans av {customDimension?.title || "dimension"}
      </FormAreaTitle>

      <TextInput
        title={`Kod för instansen av ${
          customDimension?.title || "dimensionen"
        }`}
        storeName={storeName}
        fieldKey={"dimension_entry_id"}
        method={method}
      />
      <TextField
        title="Beskrivning"
        storeName={storeName}
        fieldKey={"description"}
        method={method}
      />
    </>
  );
};
