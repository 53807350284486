import * as React from "react";
import LocalCheckField from "./LocalCheckField";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export default function ({
  value,
  choices,
  onChange,
  onClose,
  gray = false,
  withIcon = false,
  primaryBtnClass = "",
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const renderOptions = React.useMemo(() => {
    return choices.map((obj, index) => {
      return (
        <LocalCheckField
          value={value && value[index] ? value[index] : false}
          onChange={(val) => {
            let clone = [];

            if (!value) {
              clone = Array.from(new Array(choices.length)).map(() => false);
            } else {
              clone = value.slice();
            }

            clone[index] = val;
            onChange(clone);
          }}
          key={index}
          title={obj.d}
          labelClassName="!cursor-pointer !font-normal"
          className="shadow p-2"
        />
      );
    });
  });

  const getTextForButton = () => {
    if (!value || !choices || choices.length === 0)
      return <p>Välj ett eller flera alternativ</p>;

    let displayFound = "";
    let trueCount = 0;

    let index = 0;
    for (let bool of value) {
      if (bool && displayFound.length === 0) {
        displayFound = choices[index].d;
      } else if (bool) {
        trueCount++;
      }

      index++;
    }

    if (displayFound.length === 0)
      displayFound = <p>Välj ett eller flera alternativ</p>;
    else {
      displayFound = (
        <p>
          {displayFound} {trueCount !== 0 && <span>+{trueCount}</span>}
        </p>
      );
    }

    return displayFound;
  };

  return (
    <div className="w-full relative">
      <PrimaryBtn
        onClick={() => setIsOpen(!isOpen)}
        secondary={!gray ? true : false}
        gray={gray}
        className={primaryBtnClass}
      >
        {getTextForButton()}
        {withIcon && (
          <ChevronDownIcon className="absolute right-2 text-black" width={16} />
        )}
      </PrimaryBtn>
      {isOpen && (
        <div className="absolute z-20 w-full">
          <div className="bg-white rounded-t shadow p-2 w-full border border-solid border-gray-300 space-y-3 max-h-[400px] overflow-y-auto">
            {renderOptions}
          </div>
          <div
            onClick={(evt) => {
              evt.stopPropagation();
              setIsOpen(false);
            }}
            className="bg-primaryblue cursor-pointer w-full text-center text-white p-2 rounded-b transition-colors hover:bg-primaryblue/80"
          >
            Klar
          </div>
        </div>
      )}
    </div>
  );
}

//Insights might get fucked by the change above. Safe keeping this code until merge
// {isOpen && (
//         <>
//           <div className="bg-white absolute z-20 rounded shadow p-2 w-full border border-solid border-gray-300 space-y-3 max-h-[400px] overflow-y-auto">
//             {renderOptions}
//             <div
//               onClick={(evt) => {
//                 evt.stopPropagation();
//                 setIsOpen(false);
//               }}
//               className="bg-primaryblue cursor-pointer w-full text-center text-white p-2 rounded transition-colors hover:bg-primaryblue/80"
//             >
//               Klar
//             </div>
//           </div>
//         </>
//       )}
