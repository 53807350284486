import * as React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

//store, state
import {
  useRoundingErrand,
  constants,
  acceptRoundingErrand,
  declineRoundingErrand,
  update,
  executeUrl,
} from "../../../store/roundingErrands";
import {
  detailUrl as roundingSettingDetailUrl,
  useRoundingErrandSetting,
} from "../../../store/roundingErrandSettings";

import { useFilteredNotes } from "../../../store/notes";
import { useUser, detailUrl as userDetailUrl } from "../../../store/users";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { buildQueryString, usePermissionCheck } from "../../../store/base";

import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { DateCell, LinkedObject, TimeCell } from "../../../components/Displays";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  ErrandStatusLabel,
  StatusLabel,
} from "../../../components/Lists/Base/CompleteList/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  getChosenPerformer,
  getErrandStatus,
} from "../../../components/utils/errandUtils";

import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import PerformerStatusBar from "src/components/TechnicalManagement/ErrandComponents/PerformerStatusBar";
import FinishedErrandStatusBar from "src/components/TechnicalManagement/ErrandComponents/FinishedErrandStatusBar";
import AdminTakeOverErrandStatusBar from "src/components/TechnicalManagement/ErrandComponents/AdminTakeOverErrandStatusBar";
import RoundingAreasTable from "src/components/Tables/RoundingAreas/FullTable";
import RoundingErrandPlanForm from "src/components/Forms/RoundingErrand/PlanForm/ModalForm";
import ConfirmModal from "src/components/Forms/Base/Modals/ConfirmModal";

function RoundingErrandOverview() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { roundingErrandId } = useParams();
  const currentUser = useSelector((state) => state.app.user);
  const userId = currentUser?.id;

  const canEditErrand = usePermissionCheck("change_can_roundingerrand");

  const [roundingErrand, roundingErrandLoading] =
    useRoundingErrand(roundingErrandId);
  const [roundingErrandSetting] = useRoundingErrandSetting(
    roundingErrand?.setting?.id
  );

  const [loadingAction, setLoadingAction] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [reactivateErrandLoading, setReactivateErrandLoading] =
    React.useState(false);
  const [confirmExecuteOpen, setConfirmExecuteOpen] = React.useState(false);
  const [planErrandOpen, setPlanErrandOpen] = React.useState(false);

  const [reportedBy] = useUser(roundingErrand?.reported_by?.id);

  const notesQ = buildQueryString({
    id__in: roundingErrand?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const handleAcceptErrand = () => {
    setLoadingAction(true);
    dispatch(
      acceptRoundingErrand({
        id: roundingErrand?.id,
        errorCallback: () => {
          setLoadingAction(false);
        },
        successCallback: () => {
          setLoadingAction(false);
        },
      })
    );
  };

  const onDeclineSuccess = () => {
    setLoadingAction(false);
    push("/rounding-errands");
  };

  const handleDeclineErrand = () => {
    setLoadingAction(true);
    dispatch(
      declineRoundingErrand({
        id: roundingErrand?.id,
        successCallback: onDeclineSuccess,
        errorCallback: () => {
          setLoadingAction(false);
        },
      })
    );
  };

  const pauseErrand = () => {
    setLoadingUpdate(true);
    dispatch(
      update({
        id: roundingErrand?.id,
        forceData: {
          status: 2,
        },
        successCallback: () => {
          setLoadingUpdate(false);
        },
        errorCallback: () => setLoadingUpdate(false),
      })
    );
  };

  const executeErrand = () => {
    if (roundingErrand?.status !== 1) {
      setLoadingUpdate(true);
      dispatch(
        update({
          id: roundingErrand?.id,
          forceData: {
            status: 1,
          },
          successCallback: () => {
            setLoadingUpdate(false);

            push(executeUrl({ id: roundingErrandId }));
          },
          errorCallback: () => setLoadingUpdate(false),
        })
      );
    } else {
      push(executeUrl({ id: roundingErrandId }));
    }
  };

  const reactivateErrand = () => {
    setReactivateErrandLoading(true);

    dispatch(
      update({
        id: roundingErrand?.id,
        forceData: {
          status: 1,
        },
        successCallback: () => {
          setReactivateErrandLoading(false);
        },
        errorCallback: () => setReactivateErrandLoading(false),
      })
    );
  };

  const takeOverErrand = (newPerformer) => {
    setReactivateErrandLoading(true);
    dispatch(
      update({
        id: roundingErrand?.id,
        forceData: {
          performer: newPerformer,
        },
        successCallback: () => {
          setReactivateErrandLoading(false);
        },
        errorCallback: () => setReactivateErrandLoading(false),
      })
    );
  };

  const startErrand = () => {
    setLoadingUpdate(true);

    dispatch(
      update({
        id: roundingErrand?.id,
        forceData: {
          status: 1,
        },
        successCallback: () => {
          setLoadingUpdate(false);
        },
        errorCallback: () => setLoadingUpdate(false),
      })
    );
  };

  const { status, display } = getErrandStatus({
    status: roundingErrand?.status,
    plannedStart: roundingErrand?.planned_start,
    plannedEnd: roundingErrand?.planned_end,
    actualStart: roundingErrand?.execute_start,
    actualEnd: roundingErrand?.execute_end,
  });

  const infoObjProps = {
    status,
    display,
    reportedBy,
  };

  return (
    <>
      {canEditErrand && planErrandOpen && (
        <RoundingErrandPlanForm
          id={roundingErrandId}
          instance={roundingErrand}
          method="PATCH"
          onCheckout={() => setPlanErrandOpen(false)}
        />
      )}

      {confirmExecuteOpen && (
        <ConfirmModal
          onAccept={executeErrand}
          closeFunction={() => setConfirmExecuteOpen(false)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Bekräfta påbörjande av ärende. <br />
          </h3>

          <p>
            Ärendet kommer att markeras som startat och utförandet påbörjas.
          </p>
        </ConfirmModal>
      )}

      <DetailInnerWrapper>
        {roundingErrand && currentUser && (
          <PerformerStatusBar
            errand={roundingErrand}
            currentUser={currentUser}
            onAccept={handleAcceptErrand}
            onDecline={handleDeclineErrand}
            onPerform={
              roundingErrand?.status === 1
                ? () => push(executeUrl({ id: roundingErrandId }))
                : () => setConfirmExecuteOpen(true)
            }
            onPlan={() => setPlanErrandOpen(true)}
            onPause={pauseErrand}
            loading={loadingAction}
            canPause={roundingErrand?.status === 1}
            canStart={[0, 2].includes(roundingErrand?.status)}
            onStart={startErrand}
          />
        )}

        {[3, 4].includes(roundingErrand?.status) && canEditErrand && (
          <FinishedErrandStatusBar
            onReactivate={reactivateErrand}
            loading={reactivateErrandLoading}
          />
        )}

        {[0, 1, 2].includes(roundingErrand?.status) &&
          canEditErrand &&
          !roundingErrand?.performer &&
          roundingErrand?.requested_performer?.user?.id !== userId && (
            <AdminTakeOverErrandStatusBar
              errand={roundingErrand}
              onTakeOver={takeOverErrand}
              loading={reactivateErrandLoading}
            />
          )}

        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 2, maxWidth: "66%", alignSelf: "flex-start" }}
          >
            {(loadingUpdate || roundingErrandLoading) && <OverlaySpinner />}
            <DetailInfo
              infoObj={getInfoObj(roundingErrand, infoObjProps)}
              extraChapterStyles={{ marginRight: 0 }}
            />
          </DetailPageBox>
          <DetailPageBox
            style={{ flex: 1, maxWidth: "33%", alignSelf: "flex-start" }}
          >
            <Notes
              notes={notes}
              contentType={"errands.roundingerrand"}
              objectId={roundingErrand?.id}
              title="Anteckningar"
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>
              Ronderingsområden som innefattas av ärende
            </OverviewTitle>
          </OverviewTitleWrapper>

          <RoundingAreasTable
            persistantQuery={{
              id__in: roundingErrandSetting?.areas?.map((a) => a.id) || [],
            }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const getTime = (timeString) => {
  return timeString ? moment(timeString).format("YYYY-MM-DD HH:mm:ss") : "-";
};

const isDelayed = (timeString, forText) => {
  const planned = moment(timeString);
  const today = moment();
  const delayed = planned.isBefore(today);
  if (delayed) {
    if (forText) {
      return true;
    }
    return 3;
  } else {
    if (forText) {
      return false;
    }
    return 1;
  }
};

function getInfoObj(roundingErrand, infoObjProps) {
  const getReportedBy = () => {
    const user = infoObjProps?.reportedBy;

    if (user?.tenant) {
      const obj = {
        id: user.tenant?.id,
        str_representation: user?.str_representation,
      };
      return <LinkedObject obj={obj} urlMethod={tenantDetailUrl} />;
    }
    return <LinkedObject obj={user} urlMethod={userDetailUrl} />;
  };

  const getPerformer = (errand) => {
    const foundPerformer = getChosenPerformer(errand);
    if (foundPerformer) {
      return <LinkedObject obj={foundPerformer} urlMethod={userDetailUrl} />;
    }
    return <div> Ingen utförare delegerad </div>;
  };

  let data = [
    {
      title: "Status",
      value: (
        <ErrandStatusLabel state={infoObjProps?.status}>
          {infoObjProps?.display}
        </ErrandStatusLabel>
      ),
    },
    {
      title: "Genererad av",
      value: (
        <LinkedObject
          obj={roundingErrand?.setting}
          urlMethod={roundingSettingDetailUrl}
        />
      ),
    },
    {
      title: "Skapat",
      value: <DateCell date={roundingErrand?.created_at} />,
    },
    {
      title: "Rapporterat av",
      value: getReportedBy(),
    },
    {
      title: "Utförare",
      value: getPerformer(roundingErrand),
    },
    {
      title: `${
        isDelayed(roundingErrand?.planned_start, true)
          ? "Planerad start(Försenad)"
          : "Planerad start"
      }`,
      value: (
        <StatusLabel
          state={
            roundingErrand?.planned_start
              ? isDelayed(roundingErrand?.planned_start)
              : 10
          }
        >
          {getTime(roundingErrand?.planned_start)}
        </StatusLabel>
      ),
    },
    {
      title: `${
        isDelayed(roundingErrand?.planned_end, true)
          ? "Planerat slut(Försenad)"
          : "Planerat slut"
      }`,
      value: (
        <StatusLabel
          state={
            roundingErrand?.planned_end
              ? isDelayed(roundingErrand?.planned_end)
              : 10
          }
        >
          {getTime(roundingErrand?.planned_end)}
        </StatusLabel>
      ),
    },
  ];

  const infoObj = {
    Ärende: data,
  };

  return infoObj;
}

export default RoundingErrandOverview;
