import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
  buildQueryString,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
} from "../store/actions";
import constants from "../store/constants";

export function useKeyPermission(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useKeyPermissions(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredKeyPermissions(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useKeyPermissionPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useKeyPermissionPaginationCount({ filters }) {
  const queryString = buildQueryString({
    _page: 1,
    _page_size: 0,
    ...filters,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}
