import * as React from "react";
import PopupHandle from "../../../Details/OverviewInfo/PopupHandle/PopupHandle";
import * as SC from "./styles";

export default ({
  disabled,
  title,
  extraStyle,
  fillWidth,
  actions = [],
  leftAligned,
}) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef();

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  return (
    <SC.PrimaryButton
      newDesign
      ref={ref}
      style={{ ...extraStyle, display: "flex", alignItems: "center" }}
      {...{ fillWidth }}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
      <svg
        width="14"
        height="14"
        x="0"
        y="0"
        viewBox="0 0 444.819 444.819"
        style={{ enableBbackground: "new 0 0 512 512", marginLeft: "4px" }}
      >
        <path
          d="M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561   L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416   C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848   c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693   C444.819,130.287,441.295,121.629,434.252,114.203z"
          fill="#ffffff"
          data-original="#000000"
        />
      </svg>
      <PopupHandle
        {...{ actions, open, closeFunction: () => setOpen(false), leftAligned }}
      />
    </SC.PrimaryButton>
  );
};
