import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PopupButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import ApartmentAdForm from "../../../components/Forms/MarketApartmentAd/ChapterForm/ModalForm";
import ApartmentAdsList from "../../../components/Tables/MarketApartmentAds/FullTable";
import IndpAdsList from "../../../components/Tables/MarketIndustrialPremisesAds/FullTable";
import ParkingAdsList from "../../../components/Tables/MarketParkingAds/FullTable";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import Toggle from "../../../components/Toggle/Toggle";
import StandardModal from "../../../components/Modals/StandardModal";
import { createManyMenuUrl } from "../../../store/marketApartmentAds";
import { createManyMenuUrl as createManyCommoditiesMenuUrl } from "../../../store/marketApartmentCommodities";
import { useHistory } from "react-router-dom";
import { AD_CREATE_TYPES } from "../ManyHandling/ManyAdRootObjectSelect";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import IndustrialPremisesAdForm from "../../../components/Forms/MarketIndustrialPremisesAd/ChapterForm/ModalForm";
import ParkingSpotAdForm from "../../../components/Forms/MarketParkingSpotAd/ChapterForm/ModalForm";

const TABS = {
  APARTMENTS: "Lägenheter",
  INDUSTRIAL: "Lokaler",
  PARKING: "Parkering",
};

const CREATE_TYPES = {
  SINGLE: 1,
  MASS: 2,
  MASS_COMMODITIES: 3,
};
export default function Ads() {
  const [createApartmentOpen, setCreateApartmentOpen] = React.useState(false);
  const [createIndpOpen, setCreateIndpOpen] = React.useState(false);
  const [createParkingOpen, setCreateParkingOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("APARTMENTS");
  const [showInactive, setShowInactive] = React.useState(false);

  const adQuery = {};

  const hasCreatePerm = useAllPermissionCheck(["add_can_market"]);

  const { push } = useHistory();
  const [nextStep, setNextStep] = React.useState(CREATE_TYPES.SINGLE);
  const [selectTypeOpen, setSelectTypeOpen] = React.useState(false);

  const handleCreateManyCommodities = () => {
    setNextStep(CREATE_TYPES.MASS_COMMODITIES);
    setSelectTypeOpen(true);
  };
  const handleCreateManyAds = () => {
    setNextStep(CREATE_TYPES.MASS);
    setSelectTypeOpen(true);
  };
  const handleCreateAd = () => {
    setNextStep(CREATE_TYPES.SINGLE);
    setSelectTypeOpen(true);
  };

  const actions = [];

  if (hasCreatePerm) {
    actions.push(
      {
        name: "Ställ in annonsdetaljer för flera objekt",
        onClick: handleCreateManyCommodities,
      },
      {
        name: "Skapa flera annonser",
        onClick: handleCreateManyAds,
      },
      {
        name: "Skapa en annons",
        onClick: handleCreateAd,
      }
    );
  }

  return (
    <>
      <StandardModal
        small
        isOpen={selectTypeOpen}
        closeFunction={() => setSelectTypeOpen(false)}
        title="Välj objektstyp"
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewSubtitle small>
              Välj typ av objekt att skapa{" "}
              {nextStep === CREATE_TYPES.SINGLE
                ? "annonsen"
                : nextStep === CREATE_TYPES.MASS
                ? "annonserna"
                : "annonsdetaljer"}{" "}
              för
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div>
          <TextButton
            title={`Skapa ${
              nextStep === CREATE_TYPES.SINGLE
                ? "annons"
                : nextStep === CREATE_TYPES.MASS
                ? "annonser"
                : "annonsdetaljer"
            } för lägenhet`}
            clicked={() => {
              setSelectTypeOpen(false);
              if (nextStep === CREATE_TYPES.SINGLE) {
                setCreateApartmentOpen(true);
              } else if (nextStep === CREATE_TYPES.MASS) {
                push(createManyMenuUrl({ type: AD_CREATE_TYPES.APARTMENT }));
              } else {
                push(
                  createManyCommoditiesMenuUrl({
                    type: AD_CREATE_TYPES.APARTMENT,
                  })
                );
              }
            }}
            iconType="arrow"
            iconPlacement="right"
          />
        </div>
        <div>
          <TextButton
            title={`Skapa ${
              nextStep === CREATE_TYPES.SINGLE
                ? "annons"
                : nextStep === CREATE_TYPES.MASS
                ? "annonser"
                : "annonsdetaljer"
            } för lokal`}
            clicked={() => {
              setSelectTypeOpen(false);
              if (nextStep === CREATE_TYPES.SINGLE) {
                setCreateIndpOpen(true);
              } else if (nextStep === CREATE_TYPES.MASS) {
                push(createManyMenuUrl({ type: AD_CREATE_TYPES.INDP }));
              } else {
                push(
                  createManyCommoditiesMenuUrl({
                    type: AD_CREATE_TYPES.INDP,
                  })
                );
              }
            }}
            iconType="arrow"
            iconPlacement="right"
          />
        </div>
        {nextStep !== CREATE_TYPES.MASS_COMMODITIES && (
          <div>
            <TextButton
              title={`Skapa ${
                nextStep === CREATE_TYPES.SINGLE
                  ? "annons"
                  : nextStep === CREATE_TYPES.MASS
                  ? "annonser"
                  : "annonsdetaljer"
              } för parkeringsplats`}
              clicked={() => {
                setSelectTypeOpen(false);
                if (nextStep === CREATE_TYPES.SINGLE) {
                  setCreateParkingOpen(true);
                } else if (nextStep === CREATE_TYPES.MASS) {
                  push(createManyMenuUrl({ type: AD_CREATE_TYPES.PARKING }));
                }
              }}
              iconType="arrow"
              iconPlacement="right"
            />
          </div>
        )}
      </StandardModal>

      <ApartmentAdForm
        method="POST"
        isOpen={createApartmentOpen}
        onCheckout={() => setCreateApartmentOpen(false)}
      />
      <IndustrialPremisesAdForm
        method="POST"
        isOpen={createIndpOpen}
        onCheckout={() => setCreateIndpOpen(false)}
      />
      <ParkingSpotAdForm
        method="POST"
        isOpen={createParkingOpen}
        onCheckout={() => setCreateParkingOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Annonser</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>

            <PopupButton actions={actions} title="Lägg till..." />
          </OverviewTitleWrapper>

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Annonsöversikt</OverviewTitle>
                <OverviewSubtitle>
                  <div style={{ maxWidth: 190, marginTop: 4 }}>
                    <Toggle
                      extraTitleStyle={{
                        fontSize: 12,
                        fontWeight: 500,
                        marginRight: 6,
                      }}
                      title="Visa inaktiva annonser"
                      value={showInactive}
                      onToggle={() => setShowInactive(!showInactive)}
                    />
                  </div>
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>

              <PageSlider
                {...{
                  TABS,
                  selectedTab,
                  onTabSelected: (tab) => setSelectedTab(tab),
                }}
              />
            </OverviewTitleWrapper>

            {selectedTab === "APARTMENTS" && (
              <ApartmentAdsList persistantQuery={adQuery} />
            )}

            {selectedTab === "INDUSTRIAL" && (
              <IndpAdsList persistantQuery={adQuery} />
            )}

            {selectedTab === "PARKING" && (
              <ParkingAdsList persistantQuery={adQuery} />
            )}
          </InnerBox>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
