import * as React from "react";
import { MonthInput, RadioGroup } from "../../../Base/Fields";

// store, state
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { constants } from "../../../../../store/parkingContracts";

import useActiveFilters from "../../../../../hooks/useActiveFilters";
import GenericField from "../../../Base/FieldComponents/GenericField";

import { useDispatch } from "react-redux";
import DateSelect from "src/components/Forms/Base/Fields/DateSelect";
import SelectField from "src/components/Forms/Base/Fields/SelectField";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";
import LeaseContractsTable from "src/components/Tables/LeaseContracts/FullTableNewVersion";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default ({ method, contractType }) => {
  const { filteredRealEstates } = useActiveFilters();
  const dispatch = useDispatch();

  const storeName = constants.STORE_NAME;
  const endDate = useFormInstanceField({
    storeName,
    fieldKey: "end_date",
  });

  const renewIndefinetely = useFormInstanceField({
    storeName,
    fieldKey: "renew_indefinetely",
  });

  const mainContract = useFormInstanceField({
    storeName,
    fieldKey: "main_contract",
  });

  const parkingSpot = useFormInstanceField({
    storeName,
    fieldKey: "parking_spots",
  });

  const contractRealestateId = useFormInstanceField({
    storeName,
    fieldKey: "realestate.id",
  });

  const parkingSpotRealestate = parkingSpot?.[0].realestate;

  React.useEffect(() => {
    if (!contractRealestateId) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            realestate: parkingSpotRealestate,
          },
        })
      );
    }
  }, [parkingSpotRealestate]);

  const persistantQuery = {
    realestate__in: filteredRealEstates,
    draft: false,
  };

  return (
    <React.Fragment>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Avtals-ID"
          description="Ange det interna ID:t för avtalet"
          storeName={storeName}
          fieldKey="id_number"
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj fastighet..."
          title="Fastighet som avtalet ligger under"
          description="Ange vilken fastighet som avtalet ligger under."
          method={method}
          fieldKey="realestate"
          TableComponent={RealEstateTable}
        />
      </div>

      {contractType === "existing" && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <SelectField
            title="Status på avtalet"
            storeName={storeName}
            method={method}
            fieldKey="status"
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          persistantQuery={persistantQuery}
          title="Koppla till huvudavtal"
          placeholder="Välj huvudavtal..."
          fieldKey="main_contract"
          TableComponent={LeaseContractsTable}
        />
      </div>
      {!mainContract && (
        <GenericField
          storeName={storeName}
          method={method}
          ctFieldKey="content_type"
          idFieldKey="object_id"
          realEstateIds={filteredRealEstates}
        />
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          title="Startdatum"
          description="Datum när avtalet träder i kraft"
          storeName={storeName}
          fieldKey="start_date"
          method={method}
        />

        <DateSelect
          storeName={storeName}
          fieldKey="end_date"
          title="Slutdatum"
          description="Lämna tomt om avtalet är tillsvidare"
          method={method}
        />
      </div>

      <MonthInput
        title="Uppsägningstid"
        storeName={storeName}
        fieldKey="notify_interval"
        method={method}
      />

      {endDate && (
        <RadioGroup
          storeName={storeName}
          fieldKey="renew_indefinetely"
          title="Förlängning vid passerat slutdatum"
          description="Välj hur ett passerat slutdatum utan att avtalet har sagts upp ska hanteras."
          method={method}
          forceHorizontalToolTipPlacement="left"
          forceVerticalToolTipPlacement="top"
          defaultValue={false}
          options={[
            {
              label:
                "Förläng med vald förlängningstid när slutdatumet passeras",
              value: false,
            },
            {
              label: "Förläng på obestämd tid när slutdatumet passeras",
              value: true,
            },
            {
              label:
                "Förläng ej när slutdatumet passeras (kräver uppsägningstid)",
              value: "no_renewal",
            },
          ]}
        />
      )}

      {!!endDate && !renewIndefinetely && (
        <MonthInput
          storeName={storeName}
          title="Förlängningstid"
          description="Ange i hur långa intervall avtalet förlängs"
          fieldKey="renew_interval"
          method={method}
        />
      )}

      <MonthInput
        title="Varna så här lång tid innan avtalet går ut "
        description="Pigello skickar en notis om att avtalet är på väg att gå ut så här lång tid innan slutdatum + uppsägningstid"
        storeName={storeName}
        fieldKey="warning_interval"
        method={method}
      />
    </React.Fragment>
  );
};
