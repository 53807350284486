export const constructStrRep = (obj) => {
  return obj.str_representation || "Andel";
};
export const detailUrl = ({ id }) => {
  return `/brf-payment-group-quotas/detail/${id}`;
};
export const createUrl = (paymentGroupId, brfPremisesId) => {
  if (paymentGroupId) {
    return `/brf-payment-group-quotas/create/payment-group/${paymentGroupId}`;
  }
  return `/brf-payment-group-quotas/create/brf-premises/${brfPremisesId}`;
};

export const updateUrl = (id, paymentGroupId) => {
  return `/brf-payment-group-quotas/update/${id}/${paymentGroupId}`;
};
