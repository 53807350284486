import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { usePermissionCheck } from "../../store/base";
import { useChecklist, constants, overviewUrl } from "../../store/checklists";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ChecklistDetailOverview from "./checklistDetail/Overview";

export default function ChecklistDetail() {
  const { path, url } = useRouteMatch();
  const { id } = useParams();

  const { push } = useHistory();
  const canDelete = usePermissionCheck("delete_can_reporterrand");

  const [checklist] = useChecklist(id);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ChecklistDetailOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        instance={checklist}
        constants={constants}
        closeFunction={() => setDeleteModalOpen(false)}
        deletedCallback={() => push(overviewUrl())}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Checklista${
            checklist?.str_representation
              ? ` - ${checklist.str_representation}`
              : ""
          }`}
          breadCrumbs={[
            {
              label: "Inställningar Teknisk Förvaltning",
              url: "/errand-settings/checklists",
            },
            {
              label: checklist?.str_representation || "Checklista",
            },
          ]}
          eventContentType="errands.checklist"
          eventObjectId={id}
          eventIdQueryStr="checklist"
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
