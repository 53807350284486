
import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, BooleanLabel } from "../../Displays";


import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import { detailUrl as realEstatePortfolioDetailUrl } from "../../../store/realEstatePortfolios";
import { detailUrl as companyDetailUrl } from "../../../store/companies";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";


export default () => [
  {
    Header: "Nyckeltal",
    accessor: "kpi_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Målvärde",
    accessor: "value",
    Cell: ({ value }) => <ToolTipCell text={value ? `${value?.toLocaleString("sv")}` : "-"} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Operator",
    accessor: "condition_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Aktuellt värde",
    accessor: "current_value",
    Cell: ({ value }) => <ToolTipCell text={(value !== undefined) ? `${value?.toLocaleString("sv")}` : "-"} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Uppnått",
    id: "_achieved",
    accessor: "achieved",
    Cell: ({ value }) => <BooleanLabel value={value} onLabel={"Ja"} offLabel={"Nej"} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },

    {
      Header: "Fastighet",
      accessor: "realestate.id",
      Cell: ({ row }) => {
        const value = row.original.realestate;
        return <LinkedObject obj={value} urlMethod={realEstateDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Portfölj",
      accessor: "portfolio.str_representation",
      Cell: ({ row }) => {
        const value = row.original.portfolio;
        return <LinkedObject obj={value} urlMethod={realEstatePortfolioDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Bolag",
      accessor: "company.str_representation",
      Cell: ({ row }) => {
        const value = row.original.company;
        return <LinkedObject obj={value} urlMethod={companyDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
];
