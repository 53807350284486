import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import ComponentTypeNestedChildren from "../../../ComponentType/NestedChildren";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";
import ComponentTypesTable from "src/components/Tables/ErrandComponentTypes/FullTable";
import ChecklistTable from "src/components/Tables/Checklists/FullTable";
import UsersTable from "src/components/Tables/Users/FullTable";

export default function ProductType({ method, storeName }) {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för komponentmodell</OverviewTitle>
          <OverviewSubtitle>
            Ange namn och inställningar för komponentmodellen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          placeholder={"Välj komponenttyp..."}
          title="Komponenttyp för modell"
          method={method}
          fieldKey={"component_type"}
          TableComponent={ComponentTypesTable}
        >
          {(parentPath) => (
            <>
              <ComponentTypeNestedChildren
                {...{
                  storeName,
                  method,
                  parentPath,
                  parentInstructionsPath: `component_type`,
                }}
              />
            </>
          )}
        </TableSelectFieldWithCreate>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          {...{
            storeName,
            method,
            fieldKey: "name",
            title: "Namn på komponentmodell",
          }}
        />

        <TextInputField
          {...{
            storeName,
            method,
            fieldKey: "code",
            title: "Artikelnr.",
          }}
        />
        <TextInputField
          {...{
            storeName,
            method,
            fieldKey: "url_reference",
            title: "URL-länk till produkt",
          }}
        />

        <TextInputField
          {...{
            storeName,
            method,
            fieldKey: "warranty_months",
            title: "Antal månaders garanti",
          }}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey={"report_errand_checklist"}
          description="Denna checklista kommer att gälla för felanmälan på alla komponenter som ligger under denna komponentmodell. Vilka rader på checklistan som visas beror på inställningarna på den givna checklistan."
          method={method}
          title="Checklista för felanmälan"
          placeholder={"Välj checklista..."}
          TableComponent={ChecklistTable}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey={"inspection_errand_checklist"}
          description="Denna checklista kommer att gälla för besiktning på alla komponenter som ligger under denna komponentmodell. Vilka rader på checklistan som visas beror på inställningarna på den givna checklistan."
          method={method}
          title="Checklista för besiktningar"
          placeholder={"Välj checklista..."}
          TableComponent={ChecklistTable}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey={"send_faults_to_user"}
          description="Skicka en automatisk rapport om fel till användare per E-post. Användbart om ärendet t.ex. gäller komponenter med garanti som ska hanteras av en tredjepart."
          method={method}
          title="Skicka rapport om fel till användare"
          placeholder={"Välj användare..."}
          TableComponent={UsersTable}
        />
      </div>
    </>
  );
}
