export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_NOTIFICATIONS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_NOTIFICATIONS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_NOTIFICATIONS",
  DESTROY_FORM: "DESTROY_FORM_NOTIFICATIONS",
  SET_FORM_ERROR: "SET_FORM_ERROR_NOTIFICATIONS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_NOTIFICATIONS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_NOTIFICATIONS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_NOTIFICATIONS",
  RESET_STATE: "RESET_STATE_NOTIFICATIONS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_NOTIFICATIONS",
  CLEAR_FETCHED: "CLEAR_FETCHED_NOTIFICATIONS",
  LIST_URL: "/events/notification/list/",
  GET_URL: "/events/notification/",
  POST_URL: "/events/notification/",

  STORE_NAME: "notificationsDisplay",
};
