export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ADDRESS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ADDRESS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ADDRESS",
  DESTROY_FORM: "DESTROY_FORM_ADDRESS",
  SET_FORM_ERROR: "SET_FORM_ERROR_ADDRESS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ADDRESS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ADDRESS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ADDRESS",
  RESET_STATE: "RESET_STATE_ADDRESS",
  CLEAR_FETCHED: "CLEAR_FETCHED_ADDRESS",

  LIST_URL: "/general/adress/list/",
  POST_URL: "/general/adress/",
  PATCH_URL: "/general/adress/",
  LOOKUP_URL: "/external/address/lookup/",
  LOOKUP_DETAIL_URL: "/external/address/detail/",
  GET_URL: "/general/adress/",

  STORE_NAME: "addresses",
};
