export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRFMANUALCAPITALADDON",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRFMANUALCAPITALADDON",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRFMANUALCAPITALADDON",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRFMANUALCAPITALADDON",
  DESTROY_FORM: "DESTROY_FORM_BRFMANUALCAPITALADDON",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRFMANUALCAPITALADDON",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRFMANUALCAPITALADDON",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRFMANUALCAPITALADDON",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRFMANUALCAPITALADDON",
  RESET_STATE: "RESET_STATE_BRFMANUALCAPITALADDON",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRFMANUALCAPITALADDON",
  REMOVE_OBJECT: "REMOVE_OBJECT_BRFMANUALCAPITALADDON",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRFMANUALCAPITALADDON",

  LIST_URL: "/brf/manualcapitaladdon/list/",
  POST_URL: "/brf/manualcapitaladdon/",
  PATCH_URL: "/brf/manualcapitaladdon/",
  GET_URL: "/brf/manualcapitaladdon/",

  STORE_NAME: "brfManualCapitalAddon",
};
