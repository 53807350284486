import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";

import {
  useReportErrandForm,
  destroyPatchForm,
  destroyPostForm,
  constants,
  detailUrl,
  update,
} from "../../../../store/reportErrands";
import { cloneDeep } from "lodash";
import { setActiveFormInstance } from "../../../../store/base";

export default function ExecuteReportErrandModalForm({
  method,
  id,
  isOpen,
  onCheckout,
  canPause = false,
  instance,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (instance && isOpen) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: { ...instance, status: 3 },
        })
      );
    }
  }, [isOpen, instance]);

  const formLoaded = Boolean(useReportErrandForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    onCheckout(success);
  };

  const onSuccess = (user, returned) => {
    setLoading(false);
    checkout(true);

    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onError = (e) => {
    setLoading(false);
  };

  const preProcess = (data) => {
    const clone = cloneDeep(data);
    if (clone.status === 1 || clone.status === 2 || clone.status === 0) {
      clone.status = 3;
    }
    return clone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "PATCH") {
      dispatch(
        update({
          id,
          preProcess,
          successCallback: onSuccess,
          errorCallback: onError,
        })
      );
    }
  };

  const onPause = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        preProcess: (d) => {
          const dClone = cloneDeep(d);
          dClone.status = 2;
          return dClone;
        },
        successCallback: onSuccess,
        errorCallback: onError,
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs()}
      onRenderChapter={(key) => renderChapter({ key, method, id })}
      submitCallback={onSubmit}
      title={method === "POST" ? "Skapa avhjälpande" : "Slutför avhjälpande"}
      onDone={onDone}
      secondarySubmitCallback={canPause ? () => onPause() : null}
      secondarySubmitTitle="Pausa ärendet"
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
