import * as React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { constants as leaseContractConstants } from "../../../../../store/leaseContracts";
import {
  useFilteredOtherContracts,
  constants as otherConstants,
  useOtherContractForm,
  update as otherContractUpdate,
} from "../../../../../store/otherContracts";
import {
  useFilteredParkingContracts,
  update as parkingContractUpdatee,
  constants as parkingConstants,
} from "../../../../../store/parkingContracts";
import {
  OverviewItemTitle,
  OverviewItemValue,
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { DurationCell } from "../../../../Displays";
import { StatusLabel } from "../../../../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import { toMoneyString } from "../../../../utils/stringUtils";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";

import {
  ConnectedContractActions,
  ConnectedContractContent,
  ConnectedContractItem,
  ConnectedContractTitle,
  ConnectedContractWrapper,
} from "../../../Base/Layout/styles";
import NonConnectedDatePicker from "../../../Base/Old/NonConnected/NonConnectedDatePicker";
import { InnerBox } from "../../../../sharedStyles";

const ConnectedContract = ({
  contract,
  proposedCloseDate,
  constants,
  updateFunction,
  getUrl,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const url = getUrl(contract.id);
  const openContract = () => {
    window.open(url, "_blank").focus();
  };

  useOtherContractForm("PATCH", contract.id);

  React.useEffect(() => {
    if (proposedCloseDate) {
      setDate(proposedCloseDate);
    }
  }, [proposedCloseDate]);

  const successCallback = () => {
    setLoading(false);
  };

  const errorCallback = () => {
    setLoading(false);
  };

  const closeContract = () => {
    setLoading(true);
    const data = {
      closed_date: moment(date).format("YYYY-MM-DD"),
      closed_confirmed: true,
      closed_on: moment().format("YYYY-MM-DD"),
      closed_signed: true,
      closed_cause: "Uppsagt i samband med uppsägning av huvudavtal",
      status: 6,
      landlord_cancelled: true,
    };

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data,
      })
    );

    dispatch(
      updateFunction({ id: contract.id, successCallback, errorCallback })
    );
  };

  // only show actions if not closed
  const showActions = ![6, 7].includes(contract.state);

  return (
    <ConnectedContractWrapper>
      {loading && <OverlaySpinner />}
      <ConnectedContractTitle>
        {contract.str_representation}

        <StatusLabel
          style={{ alignSelf: "center", marginLeft: "6px" }}
          state={contract.state}
        >
          {contract.state_display}
        </StatusLabel>

        <TextButton
          title="Visa avtal"
          clicked={openContract}
          extraStyle={{ marginLeft: "auto" }}
        />
      </ConnectedContractTitle>

      <ConnectedContractContent>
        {contract.category_display && (
          <ConnectedContractItem>
            <OverviewItemTitle>Kategori</OverviewItemTitle>
            <OverviewItemValue>
              {contract.category_display || "-"}
            </OverviewItemValue>
          </ConnectedContractItem>
        )}

        <ConnectedContractItem>
          <OverviewItemTitle>Uppsägningstid</OverviewItemTitle>
          <OverviewItemValue>
            <DurationCell durationStamp={contract.notify_interval} />
          </OverviewItemValue>
        </ConnectedContractItem>

        <ConnectedContractItem>
          <OverviewItemTitle>Årsintäkt (kalenderår)</OverviewItemTitle>
          <OverviewItemValue>
            {toMoneyString(contract.calendar_year_cost, true)}
          </OverviewItemValue>
        </ConnectedContractItem>
      </ConnectedContractContent>

      {showActions && (
        <ConnectedContractActions>
          <NonConnectedDatePicker
            noMargin
            label="Slutar gälla"
            value={date}
            onChange={(d) => setDate(d)}
          />
          <PrimaryButton
            disabled={!date}
            newDesign
            title="Säg upp"
            clicked={closeContract}
          />
        </ConnectedContractActions>
      )}
    </ConnectedContractWrapper>
  );
};

export default ({ leaseContractId }) => {
  // connected other contracts
  const query = buildQueryString({
    main_contract: leaseContractId,
  });

  const [connectedOtherContracts, otherContractsLoading] =
    useFilteredOtherContracts(query);

  const formInstanceStatus = useFormInstanceField({
    storeName: leaseContractConstants.STORE_NAME,
    fieldKey: "status",
  });

  const formInstanceCloseDate = useFormInstanceField({
    storeName: leaseContractConstants.STORE_NAME,
    fieldKey: "closed_date",
  });
  const formInstanceCloseRenewDate = useFormInstanceField({
    storeName: leaseContractConstants.STORE_NAME,
    fieldKey: "closed_date",
  });

  const formattedCloseDate = formInstanceCloseDate
    ? moment(
        formInstanceStatus === 6
          ? formInstanceCloseDate
          : formInstanceCloseRenewDate
      ).format("YYYY-MM-DD")
    : null;

  // connectd parking contracts
  const [connectedParkingContracts, parkingContractsLoading] =
    useFilteredParkingContracts(query);

  const getOtherContractUrl = (id) =>
    `${window.location.origin}/other-contracts/detail/${id}`;
  const getParkingContractUrl = (id) =>
    `${window.location.origin}/parking-contracts/detail/${id}`;

  const isLoading = [otherContractsLoading, parkingContractsLoading].some(
    (l) => l
  );

  return (
    <div style={{ padding: "20px" }}>
      {isLoading && <OverlaySpinner />}

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Kopplade avtal</OverviewTitle>
          {connectedOtherContracts?.length > 0 ||
          connectedParkingContracts?.length > 0 ? (
            <OverviewSubtitle>
              Hantera avtal som är kopplade till detta hyresavtal.{" "}
              <strong>
                Observera att ett avtal som sägs upp härifrån sägs upp direkt,
                oberoende på huruvida uppsägningen av detta avtal slutförs.
              </strong>
            </OverviewSubtitle>
          ) : (
            <OverviewSubtitle>Inga kopplade avtal hittades.</OverviewSubtitle>
          )}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {connectedOtherContracts && connectedOtherContracts.length > 0 ? (
        <InnerBox style={{ marginBottom: 24 }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Övriga avtal</OverviewTitle>
          </OverviewTitleWrapper>

          {connectedOtherContracts.map((c) => (
            <ConnectedContract
              proposedCloseDate={formattedCloseDate}
              contract={c}
              getUrl={getOtherContractUrl}
              constants={otherConstants}
              updateFunction={otherContractUpdate}
            />
          ))}
        </InnerBox>
      ) : null}

      {connectedParkingContracts && connectedParkingContracts.length > 0 ? (
        <InnerBox style={{ marginBottom: 24 }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Parkeringsavtal</OverviewTitle>
          </OverviewTitleWrapper>

          {connectedParkingContracts.map((c) => (
            <ConnectedContract
              proposedCloseDate={formattedCloseDate}
              contract={c}
              getUrl={getParkingContractUrl}
              constants={parkingConstants}
              updateFunction={parkingContractUpdatee}
            />
          ))}
        </InnerBox>
      ) : null}
    </div>
  );
};
