import * as React from "react";

import { Article } from "./Chapters";

export default (key, method) => {
  switch (key) {
    default:
      return <Article {...{ method }} />;
  }
};
