export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_TAGS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_TAGS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_TAGS",
  DESTROY_FORM: "DESTROY_FORM_TAGS",
  SET_FORM_ERROR: "SET_FORM_ERROR_TAGS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_TAGS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_TAGS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_TAGS",
  RESET_STATE: "RESET_STATE_TAGS",
  CLEAR_FETCHED: "CLEAR_FETCHED_TAGS",
  REMOVE_OBJECT: "REMOVE_OBJECT_TAGS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_TAGS",

  LIST_URL: "/general/tag/list/",
  POST_URL: "/general/tag/",
  PATCH_URL: "/general/tag/",
  GET_URL: "/general/tag/",

  STORE_NAME: "tags",
};
