import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
} from "../../base";
import { getAll, performFilter, filterPagination } from "../store/actions";
import constants from "../store/constants";

export function useServicePartner(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getAll,
    usingAll: true,
  };
  return useObject(params);
}

export function useServicePartners(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredServicePartners(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useServicePartnerPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}
