import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { useCanUseScrive } from "../../../store/editabledocs/hooks/retrieve";
import SelectField from "../../Forms/Base/Fields/SelectField";
import TextAreaField from "../../Forms/Base/Fields/TextAreaField";
import TextInputField from "../../Forms/Base/Fields/TextInputField";
import Modal from "../../Forms/Base/Modals/Modal";

export default function DigitalDocHandleSettings({
  closeFunction,
  storeName,
  isTemplateEditor,
  usesESigning,
  method,
}) {
  const dispatch = useDispatch();

  const [canUseScrive] = useCanUseScrive();

  const invitationMessage = useFormInstanceField({
    storeName,
    fieldKey: "invitation_message",
  });
  const confirmationMessage = useFormInstanceField({
    storeName,
    fieldKey: "confirmation_message",
  });

  // set SMS messages to match email messages
  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          sms_invitation_message: invitationMessage,
          sms_confirmation_message: confirmationMessage,
        },
      })
    );
  }, [invitationMessage, confirmationMessage]);
  return (
    <Modal title="Inställningar för dokument" closeFunction={closeFunction}>
      {isTemplateEditor ? (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            title="Namn på mall"
            storeName={storeName}
            fieldKey="title"
            method={method}
          />

          <SelectField
            title="Kategori"
            storeName={storeName}
            fieldKey="category"
            method={method}
          />
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            title="Namn på dokument"
            description="Detta namn kommer vara synligt för alla parter som hanterar dokumentet"
            storeName={storeName}
            fieldKey="title"
            method={method}
          />
        </div>
      )}

      {canUseScrive && (isTemplateEditor || usesESigning) && (
        <>
          {" "}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              isNumber
              title="Antal dagar att signera (Scrive)"
              description="Ange hur många dagar parterna har på sig att signera avtalet innan signeringen misslyckas"
              storeName={storeName}
              fieldKey={"days_to_sign"}
              placeholder="90 dagar är standard om inget anges"
              method={method}
            />
            <TextInputField
              isNumber
              title="Påminnelse skickas efter ... dagar (Scrive)"
              storeName={storeName}
              fieldKey={"days_to_remind"}
              description="Om inte parten har signerat kommer en påminnelse automatiskt att skickas ut efter ... antal dagar"
              placeholder="Ingen påminnelse skickas om inget anges"
              method={method}
            />
          </div>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextAreaField
              title="Inbjudningsmeddelande (Scrive)"
              description="Detta meddelande kommer att skickas i meddelandet när parten bjuds in till att signera."
              storeName={storeName}
              fieldKey={"invitation_message"}
              method={method}
            />
            <TextAreaField
              title="Bekräftelsemeddelande (Scrive)"
              description="Detta meddelande kommer att skickas i meddelandet när parten informeras om att signeringen är avslutad."
              storeName={storeName}
              fieldKey={"confirmation_message"}
              method={method}
            />
          </div>
        </>
      )}
    </Modal>
  );
}
