import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { RadioGroup, TextInput } from "../../../Base/Fields";

export default function Checklist({ method, storeName }) {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för checklista</OverviewTitle>
          <OverviewSubtitle>
            Ange namn och inställningar för checklistan
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />
      <TextInput
        {...{
          storeName,
          method,
          fieldKey: "name",
          title: "Namn på checklista",
        }}
      />

      <RadioGroup
        title={"Gäller för självavhjälpande"}
        options={[
          {
            label:
              "Ja, denna checklista ska visas för hyresgäst för självavhjälpande",
            value: false,
          },
          {
            label:
              "Nej, denna checklista ska ej visas för hyresgäst för självavhjälpande",
            value: true,
          },
        ]}
        defaultValue={true}
        storeName={storeName}
        fieldKey={`hide_for_tenants`}
        method={method}
      />
      <RadioGroup
        title={"Gäller för utförande"}
        options={[
          {
            label: "Ja, denna checklista ska visas vid utförande av ärende",
            value: false,
          },
          {
            label: "Nej, denna checklista ska ej visas vid utförande av ärende",
            value: true,
          },
        ]}
        defaultValue={false}
        storeName={storeName}
        fieldKey={`hide_for_non_tenants`}
        method={method}
      />
    </>
  );
}
