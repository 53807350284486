import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function createGateway({ postData }) {
  const { data } = await axiosInstance.post(constants.POST_URL, postData);

  return data;
}
async function updateGateway({ patchData }) {
  const { data } = await axiosInstance.patch(
    `${constants.PATCH_URL}${patchData.id}/`,
    patchData
  );

  return data;
}

export { createGateway, updateGateway };
