import * as React from "react";

import { Confirm, Project } from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "PROJECT":
      return <Project {...{ method }} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
