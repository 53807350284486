export default () => {
  let fields = [
    {
      title: "Uppsägning",
      key: "CANCELATION",
      visited: false,
      hasError: false,
      fieldKeys: [
        "status",
        "closed_date",
        "closed_renew_date",
        "closed_cause",
        "landlord_cancelled",
        "closed_signed",
      ],
    },

    {
      title: "Kopplade avtal",
      key: "CONNECTED_CONTRACTS",
      visited: false,
      hasError: false,
      fieldKeys: [],
    },
    {
      title: "Granska och slutför",
      key: "CONFIRM",
      visited: false,
      hasError: false,
      fieldKeys: [""],
    },
  ];

  return fields;
};
