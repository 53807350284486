import * as React from "react";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";

// style, design
import { DateCell, LinkedObject, TimeCell, ToolTipCell } from "../../Displays";
import { TextButton } from "../../Forms/Base/Buttons";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { getReadableInvoiceCreator } from "../InvoicingRecord/listDefs";

export const getReadableInvoiceRecordDescription = (record) => {
  if (record?.billecta_id) {
    if (!record?.merged) {
      return "Bokning genererades";
    } else {
      return "Bokning genererades och sammanfördes";
    }
  } else {
    return "Bokning misslyckades att genereras";
  }
};

export default (push) => [
  {
    Header: "Uppdaterades",
    accessor: "updated_at",
    Cell: ({ value, row }) => {
      return <TimeCell date={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Källa",
    id: "source",
    Cell: ({ row }) => {
      let obj;
      let urlMethod;

      if (row.original.apartment) {
        obj = row.original.apartment;
        urlMethod = apartmentDetailUrl;
      }

      if (!obj || !urlMethod) return "Källa saknas";

      return <LinkedObject {...{ obj, urlMethod }} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Händelse",
    id: "event",
    Cell: ({ row }) => (
      <ToolTipCell text={getReadableInvoiceRecordDescription(row.original)} />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Genererad av",
    id: "from_auto",
    Cell: ({ row }) => (
      <ToolTipCell text={getReadableInvoiceCreator(row.original)} />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Period start",
    accessor: "period_start",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Period slut",
    accessor: "period_end",
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Datum för bokning",
    accessor: "invoice_date",
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Kopplat underlag",
    id: "invoice",
    Cell: ({ row }) => {
      if (!row.original.billecta_id)
        return <ToolTipCell text={"Ingen kopplad avi"} />;

      return (
        <TextButton
          title="Gå till underlag"
          iconType="arrow"
          iconPlacement="right"
          clicked={() =>
            push(
              invoiceDetailUrl({
                creditorId: row.original.billecta_id?.creditor_id,
                invoiceId: row.original.billecta_id?.object_id,
              })
            )
          }
        />
      );
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
