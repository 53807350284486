import baseReducer from "../base/store/reducer";

export default (state, action, constants) => {
    const { type, payload } = action;
    switch (type) {

        case constants.REMOVE_TO_IN_PROGRESS:
            return removeFromProgress(state, payload.name);
    
        default:
            return baseReducer(state, action, constants);
    }
}

const removeFromProgress = (state, name) => {
    if (!(typeof name === "string")) { 
        return state; 
    }
    const newProgress = state.inProgress.filter((call) => call !== name);
    return {...state, inProgress:newProgress};
}