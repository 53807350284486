import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import { useInspectionErrand } from "../../../store/inspectionErrands";

import ErrandCostSummary, {
  ERRAND_COST_TYPES,
} from "../../../components/Details/OverviewInfo/Costs/ErrandCosts/CostSummary";

export default function InspectionErrandCosts() {
  const { inspectionErrandId } = useParams();
  const [inspectionErrand] = useInspectionErrand(inspectionErrandId);

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          {inspectionErrand && (
            <ErrandCostSummary
              kind={ERRAND_COST_TYPES.INSPECTION_ERRAND}
              id={inspectionErrandId}
              errand={inspectionErrand}
              costs={inspectionErrand?.costs}
            />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
