import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useParams } from "react-router";

import RealEstateTable from "../../../components/Tables/RealEstate/FullTable";

function CompanyRealEstates() {
  const { companyId } = useParams();

  const realEstateQuery = {
    company: companyId,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Fastigheter under bolaget</OverviewTitle>
          </OverviewTitleWrapper>

          <RealEstateTable persistantQuery={realEstateQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export default CompanyRealEstates;
