export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_DEBTORINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_DEBTORINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_DEBTORINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_DEBTORINVOICING",
  DESTROY_FORM: "DESTROY_FORM_DEBTORINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_DEBTORINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_DEBTORINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_DEBTORINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_DEBTORINVOICING",
  RESET_STATE: "RESET_STATE_DEBTORINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_DEBTORINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_DEBTORINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_DEBTORINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_DEBTORINVOICING",

  SET_BILLECTA_SEARCH_RESULT: "SET_BILLETA_SEARCH_RESULT_DEBTORINVOICING",

  LIST_URL: "/accounting/debtor/debtor_invoice_config/list/",
  POST_URL: "/accounting/debtor/debtor_invoice_config/",
  PATCH_URL: "/accounting/debtor/debtor_invoice_config/",
  GET_URL: "/accounting/debtor/debtor_invoice_config/",

  STORE_NAME: "invoicingDebtor",
};
