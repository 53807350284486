import * as React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import sv from "date-fns/locale/sv";

import { format as formatDate } from "date-fns";
import {
  CalendarIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
registerLocale("sv", sv);

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const DEFAULT_FORMAT = "yyyy-MM-dd";
export default function LocalDateSelect({
  title,
  required,
  value,
  onChange,
  description,
  format,
  error,
  maxDate,
  disabled = false,
  alwaysOpen = false, // always show only date picker
  inline = false, // show date picker inline on open
  showYearPicker,
  showMonthYearPicker,
}) {
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const pickerOpenRef = React.useRef(pickerOpen);
  React.useEffect(() => {
    pickerOpenRef.current = pickerOpen;
  }, [pickerOpen]);

  const usedFormat = format || DEFAULT_FORMAT;

  const wrapperRef = React.useRef();

  const onUpdate = (date) => {
    setInputValue(
      formatDate(
        date,
        showYearPicker ? "yyyy" : showMonthYearPicker ? "yyyy-MM" : usedFormat
      )
    );
    onChange(
      formatDate(
        date,
        showYearPicker ? "yyyy" : showMonthYearPicker ? "yyyy-MM" : usedFormat
      )
    );
  };

  const [inputValue, setInputValue] = React.useState(
    value === null ? "" : value
  );

  const onInputChange = (evt) => {
    let val = evt.target.value;

    if (val.length > 9) {
      let date = new Date(val);

      if (isValidDate(date)) {
        return onUpdate(date);
      }
    }
    setInputValue(evt.target.value);
  };

  const onClear = () => onChange(null);

  const clickListener = React.useCallback((evt) => {
    if (!pickerOpenRef.current) return;

    let foundWrapper = false;

    let currentElem = evt.target;

    while (currentElem.parentElement) {
      if (currentElem === wrapperRef.current) {
        foundWrapper = true;
        break;
      }
      currentElem = currentElem.parentElement;
    }

    if (!foundWrapper) {
      setPickerOpen(false);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("click", clickListener);

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, []);

  if (disabled && pickerOpen) setPickerOpen(false);

  return (
    <div className="relative" ref={wrapperRef}>
      <div>
        <div className="font-medium text-gray-900 text-sm flex items-center">
          {error && (
            <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
          )}{" "}
          {title}
          {required ? "*" : ""}
        </div>
        {description && (
          <div className="text-xs font-normal text-gray-500 bg-transparent mb-1 ">
            {description}
          </div>
        )}
      </div>

      {error && (
        <div className="text-xs font-normal text-red-600 mb-1">{error}</div>
      )}

      {!alwaysOpen && (
        <button
          onClick={(e) => {
            e.preventDefault();
            setPickerOpen(true);
          }}
          className={classNames(
            `inline-flex w-full bg-white items-center border border-solid border-slate-300 text-gray-900  rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 group hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center `,
            disabled &&
              "cursor-not-allowed hover:bg-transparent focus:ring-0 focus:border-slate-300"
          )}
        >
          <CalendarIcon width={16} className="mr-1" />
          {pickerOpen ? (
            <input
              className="outline-none max-w-[125px] box-border group-hover:bg-sky-100"
              autoFocus={true}
              value={inputValue}
              onChange={(evt) => onInputChange(evt)}
              placeholder={usedFormat.toUpperCase()}
            />
          ) : (
            <>
              {value
                ? formatDate(
                    new Date(value),
                    showYearPicker
                      ? "yyyy"
                      : showMonthYearPicker
                      ? "yyyy-MM"
                      : usedFormat
                  )
                : "Välj ett datum"}
            </>
          )}
          {value && (
            <XMarkIcon
              onClick={(e) => {
                e.stopPropagation();
                onClear();
                setPickerOpen(false);
                setInputValue("");
              }}
              width={16}
              className={"ml-auto"}
            />
          )}
        </button>
      )}

      {alwaysOpen ? (
        <DatePicker
          inline
          onSelect={() => setPickerOpen(false)}
          selected={value ? new Date(value) : null}
          onChange={onUpdate} //only when value has changed
          locale="sv"
          maxDate={maxDate}
          showYearPicker={showYearPicker}
          showMonthYearPicker={showMonthYearPicker}
        />
      ) : (
        <>
          {pickerOpen && (
            <div
              className={classNames("z-20 top-[100%]", { absolute: !inline })}
            >
              <DatePicker
                inline
                onSelect={() => setPickerOpen(false)}
                selected={value ? new Date(value) : null}
                onChange={onUpdate} //only when value has changed
                locale="sv"
                maxDate={maxDate}
                showYearPicker={showYearPicker}
                showMonthYearPicker={showMonthYearPicker}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
