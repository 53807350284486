import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";
import { TextButton } from "../Forms/Base/Buttons";
import * as SC from "./styles";

export default ({
  sourceTitle = "Från vald inställning",
  title,
  content = [],
  warnings = [],
  onOverride,
  overrideTitle = "Skapa specifik inställning",
  extraStyle = {},
}) => {
  return (
    <SC.DefaultSettingWrapper style={{ ...extraStyle }}>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>

          <OverviewSubtitle>{sourceTitle}</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {content.map((c) => (
        <div style={{ marginBottom: 24 }} key={c.title}>
          <SC.DataTitle>{c.title}</SC.DataTitle>
          <SC.DataContent>{c.content}</SC.DataContent>
        </div>
      ))}

      {warnings?.length > 0 &&
        warnings.map((w) => (
          <div style={{ marginBottom: 24 }} key={w.title}>
            <SC.DataTitle style={{ color: "#ea5050" }}>{w.title}</SC.DataTitle>
            <SC.DataContent>{w.content}</SC.DataContent>
          </div>
        ))}

      {onOverride && (
        <TextButton
          title={overrideTitle}
          clicked={onOverride}
          iconType="edit"
        />
      )}
    </SC.DefaultSettingWrapper>
  );
};
