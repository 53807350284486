import * as React from "react";

// store, state
import { constants } from "../../../../../store/paymentContribution";

import useActiveFilters from "../../../../../hooks/useActiveFilters";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import PaymentGroupTable from "src/components/Tables/PaymentGroup/FullTable";
import BrfPremisesTable from "src/components/Tables/BrfPremises/FullTable";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();

  const storeName = constants.STORE_NAME;

  return (
    <div className="grid grid-cols-2 gap-6 mb-6">
      <TableSelectField
        storeName={storeName}
        TableComponent={PaymentGroupTable}
        persistantQuery={{
          realestate_ids: filteredRealEstates,
        }}
        placeholder="Välj avgiftsgrupp..."
        title="Avgiftsgrupp"
        method={method}
        fieldKey="payment_group"
      />

      <TableSelectField
        storeName={storeName}
        TableComponent={BrfPremisesTable}
        persistantQuery={{
          realestate_ids: filteredRealEstates,
        }}
        placeholder="Välj bostadsrätt..."
        title="Bostadsrätt"
        method={method}
        fieldKey="brf_premis"
      />
    </div>
  );
};
