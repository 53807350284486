import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default (realestates) => [
  {
    Header: "",
    accessor: "color_code",
    Cell: ({ value }) => {
      return (
        <div
          style={{
            height: "30px",
            width: "30px",
            borderRadius: "50%",
            backgroundColor: value || "#f1f1f1",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
      );
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Namn",
    accessor: "name",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Beskrivning",
    accessor: "description",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fastighet",
    accessor: "realestate",
    Cell: ({ value }) => {
      const re = realestates?.find((r) => r.id === value);

      return re ? <ToolTipCell text={re.str_representation} /> : "-";
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
