import * as React from "react";
import moment from "moment";
import { useParams, useHistory } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import BrfPremisesModal from "../../../components/Forms/BrfPremises/ChapterForm/ModalForm";
import BrfContractModal from "../../../components/Forms/BrfContract/ChapterForm/ModalForm";
import BrfPledgeModalForm from "../../../components/Forms/BrfPledges/ChapterForm/ModalForm";

import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { LinkedObject } from "../../../components/Displays";
import {
  detailUrl as buildingDetailUrl,
  useBuilding,
} from "../../../store/buildings";
import {
  detailUrl as brfCompanyDetailUrl,
  useBrfCompany,
} from "../../../store/brfCompanies";
import {
  toMoneyString,
  toSqmString,
} from "../../../components/utils/stringUtils";
import {
  buildQueryString,
  deleteObject,
  useAllPermissionCheck,
} from "../../../store/base";
import { useFilteredNotes } from "../../../store/notes";
import { useBrfPremises } from "../../../store/brfPremises";

import DescriptionToolTip from "../../../components/Forms/Base/Layout/DescriptionToolTip";
import InnerFund from "../../../components/Forms/BrfPremises/InnerFund";

import { useFilteredBrfOwners } from "../../../store/brfOwner";
import { createUrl as brfPaymentQuotaCreateUrl } from "../../../store/brfPaymentGroupQuota";

import {
  useFilteredBrfPledges,
  constants as pledgeConstants,
} from "../../../store/brfPledges";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import { DateCell } from "../../../components/Displays/DateCells";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { renderFloors } from "../../../components/Forms/Base/FieldComponents/FloorSelector";

import BrfPaymentGroupQuotaTable from "../../../components/Tables/BrfPaymentGroupQuota/FullTable";
import SmallTenantSummary from "../../../components/SummaryCards/Tenant/Small";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function BrfPremisesOverview() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [editOpen, setEditOpen] = React.useState(false);
  const [addBrfContractOpen, setBrfContractOpen] = React.useState(false);
  const [addBrfPledgeOpen, setAddBrfPledgeOpen] = React.useState(false);
  const [editBrfPledgeOpen, setEditBrfPledgeOpen] = React.useState(null);
  const [innerFundOpen, setInnerFundOpen] = React.useState(false);

  const [confirmRemovePledgeOpen, setConfirmRemovePledgeOpen] =
    React.useState(false);

  const { brfPremisesId } = useParams();

  const canEdit = useAllPermissionCheck([
    "change_can_baseobject",
    "change_can_brf",
  ]);
  const canAdd = useAllPermissionCheck(["add_can_brf", "add_can_baseobject"]);

  const [brfPremises] = useBrfPremises(brfPremisesId);
  const [brfCompany] = useBrfCompany(brfPremises?.brf_company?.id);
  const [building] = useBuilding(brfPremises?.building?.id);

  const brfOwnerIds = [];
  if (brfPremises?.owner?.id) {
    brfOwnerIds.push(brfPremises?.owner?.id);
  }
  if (brfPremises?.bi_owners?.length) {
    brfPremises.bi_owners.forEach((bo) => brfOwnerIds.push(bo.id));
  }
  const brfOwnersQuery = buildQueryString({
    id__in: brfOwnerIds || [],
  });
  const [owners] = useFilteredBrfOwners(brfOwnersQuery);

  const pledgesQ = buildQueryString({
    id__in: brfPremises?.pledges?.map((p) => p.id),
  });
  const [pledges] = useFilteredBrfPledges(pledgesQ);

  const notesQ = buildQueryString({
    id__in: brfPremises?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const today = moment().format("YYYY-MM-DD");
  const premisesRep = brfPremises?.str_representation;
  const initialTransactionData = {
    seller: brfPremises?.owner?.tenant?.id
      ? {
          id: brfPremises?.owner?.tenant?.id,
        }
      : undefined,
    brf_premis: {
      id: brfPremisesId,
    },
    id_number: `${premisesRep} - ${today}`,
  };

  const onAddTransaction = () => {
    setBrfContractOpen(true);
  };

  const onRemovePledge = () => {
    const instance = pledges?.find((p) => p.id === confirmRemovePledgeOpen);
    dispatch(
      deleteObject({
        instance,
        constants: pledgeConstants,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Pansättningen löstes",
            })
          );
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Pantsättning kunde ej lösas",
            })
          );
        },
      })
    );

    setConfirmRemovePledgeOpen(false);
  };

  const renderPledgesTable = () => {
    return (
      <PremisesTable
        onRowClicked={(_, idx) => {
          setEditBrfPledgeOpen(pledges[idx]);
        }}
        keyMapping={pledges?.map((p) => p.id)}
        headers={[
          "Lös pantsättning",
          "Referens",
          "Ansvarig ägare",
          "Bank",
          "Värde",
          "Registrerad",
          "Gäller från",
          "Kontakt på bank",
          "Kontakt telefon",
          "Kontakt E-post",
          "Dokument",
        ]}
        rows={pledges?.map((p, idx) => {
          return [
            <TextButton
              title="Lös pantsättning"
              clicked={() => setConfirmRemovePledgeOpen(p.id)}
              iconType="close"
              iconPlacement="right"
            />,
            p.reference_number,
            p.owner?.str_representation,
            p.contact?.title,
            toMoneyString(p.value, true),
            <DateCell date={p.register_date} />,
            <DateCell date={p.from_date} />,
            p.contact?.name,
            p.contact?.phone,
            p.contact?.email,
            p.doc?.get ? (
              <TextButton
                title="Ladda ner"
                iconType="download"
                iconPlacement="right"
                clicked={() => {
                  window.open(p.doc.get, "_blank").focus();
                }}
              />
            ) : (
              "Saknar dokument"
            ),
          ];
        })}
      />
    );
  };

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmRemovePledgeOpen}
        title="Bekräfta lösning av pantsättning"
        renderContent={() => (
          <div>Detta kommer att radera pantsättningen från Pigello</div>
        )}
        closeFunction={() => setConfirmRemovePledgeOpen(false)}
        acceptCallback={() => onRemovePledge()}
        acceptTitle="Lös pantsättning"
      />

      <InnerFund
        isOpen={innerFundOpen}
        onCheckout={() => setInnerFundOpen(false)}
        id={brfPremisesId}
      />

      {addBrfPledgeOpen && (
        <BrfPledgeModalForm
          onCheckout={() => setAddBrfPledgeOpen(false)}
          instance={{ brf_premis: { id: brfPremisesId } }}
          method="POST"
        />
      )}

      {editBrfPledgeOpen && (
        <BrfPledgeModalForm
          id={editBrfPledgeOpen?.id}
          onCheckout={() => setEditBrfPledgeOpen(false)}
          instance={editBrfPledgeOpen}
          method="PATCH"
        />
      )}

      {addBrfContractOpen && (
        <BrfContractModal
          method="POST"
          brfPremisesId={brfPremisesId}
          instance={initialTransactionData}
          onCheckout={() => setBrfContractOpen(false)}
        />
      )}

      {editOpen && (
        <BrfPremisesModal
          instance={brfPremises}
          method="PATCH"
          id={brfPremisesId}
          onCheckout={() => setEditOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              display: "flex",
              marginRight: 24,
              flexDirection: "column",
              width: "65%",
              alignSelf: "flex-start",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Objektsöversikt</OverviewTitle>
                {canEdit && (
                  <PrimaryButton
                    title="Redigera"
                    clicked={() => setEditOpen(true)}
                  />
                )}
              </OverviewTitleWrapper>

              <DetailInfo
                infoObj={getInfoObj({
                  brfPremises,
                  building,
                  brfCompany,
                })}
              />
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Andelar</OverviewTitle>
                {canAdd && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() =>
                        push(brfPaymentQuotaCreateUrl(undefined, brfPremisesId))
                      }
                    />
                  </div>
                )}
              </OverviewTitleWrapper>

              <BrfPaymentGroupQuotaTable
                persistantQuery={{
                  brf_premis: brfPremisesId,
                }}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Pantsättningar</OverviewTitle>
                  <OverviewSubtitle>
                    Lista över pantnoteringar för bostadsrätten
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
                <PrimaryBtn onClick={() => setAddBrfPledgeOpen(true)}>
                  Lägg till
                </PrimaryBtn>
              </OverviewTitleWrapper>

              {renderPledgesTable()}
            </DetailPageBox>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "35%",
              alignSelf: "flex-start",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Innehavare</OverviewTitle>
              </OverviewTitleWrapper>

              {owners?.length ? (
                <>
                  {owners.map((owner) => {
                    return (
                      <div key={owner.id} className="mb-2">
                        <SmallTenantSummary
                          key={owner.id}
                          id={owner.tenant?.id}
                          showBrfPremisesDetails
                          quota={owner.quota}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <TextButton
                  title="Lägg till"
                  iconType="add"
                  iconPlacement="right"
                  clicked={onAddTransaction}
                />
              )}
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    small
                  >
                    Inre fond
                    <DescriptionToolTip
                      forceHorizontalPlacement="left"
                      description="Avsättningar från medlemsavgiften som kan användas till
                  renoveringar osv."
                    />
                  </OverviewTitle>

                  <OverviewSubtitle>
                    <TextButton
                      title="Uppdatera"
                      iconType="edit"
                      iconPlacement="right"
                      clicked={() => setInnerFundOpen(true)}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <strong>Avsättningskvot:</strong>
                {brfPremises?.fund_quota || 0}%
                <strong style={{ marginTop: 8 }}>Saldo:</strong>{" "}
                {toMoneyString(brfPremises?.fund_value, true)}
              </InnerBox>
            </DetailPageBox>

            <DetailPageBox>
              <Notes
                notes={notes}
                contentType="brf.brfpremis"
                objectId={brfPremisesId}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ brfPremises, building }) {
  const infoObj = {
    Översikt: [
      {
        title: "Lägenhetsnr.",
        value: brfPremises?.premises_id,
      },
      {
        title: "Objektsnr.",
        value: brfPremises?.internal_premises_id,
      },
      {
        title: "BRF",
        value: (
          <LinkedObject
            obj={brfPremises?.brf_company}
            urlMethod={brfCompanyDetailUrl}
          />
        ),
      },
      {
        title: "Fastighet",
        value: brfPremises?.realestate?.str_representation,
      },
      {
        title: "Byggnad",
        value: <LinkedObject obj={building} urlMethod={buildingDetailUrl} />,
      },
      {
        title: "Våning",
        value: renderFloors(brfPremises?.dynamic_floors),
      },
      {
        title: "Area",
        value: toSqmString(brfPremises?.area),
      },

      {
        title: "Andel av förening",
        value:
          brfPremises?.quota != null
            ? `${brfPremises.quota.toFixed(5)} %`
            : "-",
      },
      {
        title: "Upplåtelseavgift",
        value: toMoneyString(brfPremises?.placement_fee, true),
      },
      {
        title: "Insatskapital",
        value: toMoneyString(brfPremises?.investment_capital, true),
      },
    ],
    Adress: [
      {
        title: "Adress",
        value: brfPremises?.address
          ? brfPremises?.address?.base?.split(",")?.[0]
          : "-",
      },
      {
        title: "Postnr.",
        value: brfPremises?.address?.postal_code || "-",
      },
      {
        title: "Stad",
        value: brfPremises?.address?.city || "-",
      },
      {
        title: "Land",
        value: brfPremises?.address?.country || "-",
      },
    ],
  };
  return infoObj;
}
