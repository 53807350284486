import styled, { css } from "styled-components";
import closeCircleIcon from "../../../../assets/svg/material/close-circle-black.svg";

export const AttributeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const AttributeWrapper = styled.div`
  width: 48%;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  padding: 12px;
  border-radius: 5px;

  margin-bottom: 4%;
  &:nth-of-type(odd) {
    margin-right: 4%;
  }

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

export const AttributeType = styled.div`
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  padding: 4px 8px;
  border-radius: 30px;
  background-color: ${(p) =>
    p.kind === 0
      ? p.theme.colors.yellow
      : p.kind === 1
      ? p.theme.colors.green
      : p.theme.colors.purple};
  color: ${(p) =>
    p.kind === 0 ? p.theme.colors.primaryText : p.kind === 1 ? "#fff" : "#fff"};
  margin-bottom: 6px;
  display: inline-block;

  opacity: ${(p) => (p.selected ? 1 : 0.3)};

  ${(p) =>
    p.selectable &&
    css`
      cursor: pointer;
    `}
`;

export const RemoveButton = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeCircleIcon});
`;

export const AttributeTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  margin-bottom: 12px;
`;

export const AttributeDescription = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
`;

export const AlternativesTitle = styled.div`
  margin-top: 12px;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AlternativesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: thin solid ${(p) => p.theme.colors.gray2};
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  min-height: 100px;
`;

export const Alternative = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: thin solid ${(p) => p.theme.colors.gray1};
`;
