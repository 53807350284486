import * as React from "react";

import { constants } from "../../../../../store/blueprints";

import { NumberInput, NonNestedSelect } from "../../../Base/Fields";
import { useFilteredBuildings } from "../../../../../store/buildings";
import { buildQueryString } from "../../../../../store/base";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import MediaField from "../../../Base/Fields/MediaField";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default function Blueprint({ method }) {
  const { filteredRealEstates } = useActiveFilters();
  const storeName = constants.STORE_NAME;

  const realEstateQuery = buildQueryString({
    realestate_ids: filteredRealEstates,
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Planritning</OverviewTitle>
          <OverviewSubtitle>
            Lägg till planritning och information
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <MediaField
        storeName={storeName}
        method={method}
        fieldKey={`svg_file`}
        instructionsKey={`svg_file`}
        allowedFormats={[".svg", ".png", ".jpg", ".jpeg", ".pdf"]}
        title="Planritning"
        fileKey="svg_file"
      />

      <NumberInput
        title="Våning planritningen avser"
        storeName={storeName}
        fieldKey={"floor"}
        method={method}
      />

      <NonNestedSelect
        title="Byggnad"
        storeName={storeName}
        fieldKey={"building"}
        method={method}
        fetchMethod={useFilteredBuildings}
        fetchQueryString={realEstateQuery}
      />
    </>
  );
}
