import { useForm } from "../../base";

import { getPatchForm, getPostForm, constants } from "..";

export function useCompanyInvoicingForm(method, id) {
  let params = {
    storeName: constants.STORE_NAME,
    method: method,
    id: id,
  };

  if (method === "POST") {
    params.fetchMethod = getPostForm;
  } else {
    params.fetchMethod = getPatchForm;
  }

  return useForm(params);
}
