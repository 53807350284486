const DELETE_ITEMS = [
  "persistant_sort_by_",
  "persistant_page_",
  "persistant_global_filter_",
  "persistant_filters_",
  "persistant_category_",
];

export default function () {
  Object.keys(localStorage).forEach((key) => {
    const del = DELETE_ITEMS.some((di) => key.includes(di));

    if (del) {
      localStorage.removeItem(key);
    }
  });
}
