import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { handleContractEditableDocUrl } from "../../store/editabledocs";
import { useCanUseScrive } from "../../store/editabledocs/hooks/retrieve";
import {
  SCRIVE_BADGE_TYPE_MAP,
  SCRIVE_DESCRIPTION_MAP,
} from "../../store/editabledocs/utils";
import { isCancelSigning } from "../../store/leaseContracts";

import OverlaySpinner from "../Loaders/OverlaySpinner";
import { InnerBox } from "../sharedStyles";
import Parties from "../Details/OverviewInfo/Signing/Parties";
import ScriveEvents from "../Details/OverviewInfo/Signing/ScriveEvents";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";

import Alert from "../Alert/Alert";
import Badge from "../Badge/Badge";
import ConfirmModal from "../Forms/Base/Modals/ConfirmModal";
import {
  activateScriveSigning,
  performScriveAction,
  SCRIVE_ACTIONS,
} from "../../store/editabledocs/store/actions";
import Modal from "../Forms/Base/Modals/Modal";
import { insertIntoAll } from "../../store/base";
import LocalTextInputField from "../Forms/Base/Fields/LocalTextInputField";

export default function DigitalSign({
  contract,
  editableDoc,
  constants,
  update,
  contractType,
}) {
  const dispatch = useDispatch();
  const [canUseScrive] = useCanUseScrive();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [confirmSignOpen, setConfirmSignOpen] = React.useState(false);
  const [confirmRemindOpen, setConfirmRemindOpen] = React.useState(false);
  const [confirmCancelOpen, setConfirmCancelOpen] = React.useState(false);
  const [confirmProlongOpen, setConfirmProlongOpen] = React.useState(false);
  const [confirmSignRestartOpen, setConfirmSignRestartOpen] =
    React.useState(false);
  const [prolongDays, setProlongDays] = React.useState(10);
  const [showInfoOpen, setShowInfoOpen] = React.useState(false);
  const hasScrive = editableDoc?.has_scrive;
  const isDraft = contract.draft;
  const isDigitalSign = contract.uses_e_signing;
  const isPreparation = editableDoc?.status === "preparation";
  const hasParties = editableDoc?.parties?.length > 0;
  const canPerformEditableDocActions = !isCancelSigning(contract);

  const canStartScriveSign =
    canUseScrive &&
    !isDraft &&
    isDigitalSign &&
    canPerformEditableDocActions &&
    hasParties &&
    isPreparation;

  const onScriveActionPerformed = (_, returned) => {
    setLoading(false);
    // update the contract's editabledoc
    const data = { ...contract, editabledoc: returned };
    dispatch(insertIntoAll(constants, data));
  };

  const onConfirmSign = () => {
    setLoading(true);
    // confirm modal only open if editable doc exists
    dispatch(
      activateScriveSigning({
        id: editableDoc.id,
        successCallback: () => {
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
    setConfirmSignOpen(false);
  };

  const onConfirmRemind = () => {
    setLoading(true);

    dispatch(
      performScriveAction({
        id: editableDoc.id,
        action: SCRIVE_ACTIONS.REMIND,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
    setConfirmRemindOpen(false);
  };

  const onConfirmCancel = () => {
    setLoading(true);
    dispatch(
      performScriveAction({
        id: editableDoc.id,
        action: SCRIVE_ACTIONS.CANCEL,
        successCallback: onScriveActionPerformed,
        errorCallback: () => setLoading(false),
      })
    );
    setConfirmCancelOpen(false);
  };

  const onConfirmProlong = () => {
    setLoading(true);
    dispatch(
      performScriveAction({
        id: editableDoc.id,
        data: { days: prolongDays },
        action: SCRIVE_ACTIONS.PROLONG,
        successCallback: onScriveActionPerformed,
        errorCallback: () => setLoading(false),
      })
    );
    setConfirmProlongOpen(false);
  };

  const onConfirmRestart = () => {
    setLoading(true);
    dispatch(
      performScriveAction({
        id: editableDoc.id,
        action: SCRIVE_ACTIONS.RESTART,
        successCallback: onScriveActionPerformed,
        errorCallback: () => setLoading(false),
      })
    );
    setConfirmSignRestartOpen(false);
  };

  const handleCreateEditableDoc = () => {
    setLoading(true);
    dispatch(
      update({
        id: contract.id,
        forceData: {
          editabledoc: {
            title: "doc",
          },
        },
        successCallback: () => {
          setLoading(false);
          push(
            handleContractEditableDocUrl({
              contractType: contractType,
              id: contract?.id,
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  const renderInfoText = () => {
    switch (editableDoc?.status) {
      case undefined:
        return (
          <>
            Dokument för signering saknas. Tryck på{" "}
            <strong>Skapa dokument</strong> för att komma igång.
          </>
        );
      case "preparation":
        return (
          <>
            Färdigställ dokumentet inför att skicka iväg det för signering via
            Scrive. Tryck på <strong>Starta signering</strong> för att starta
            signeringsprocessen och skicka ut avtalet till de berörda parterna.
            {!hasParties && (
              <>
                <br /> <br />
                <strong>
                  Dokumentet saknar parter. Se till att de parter som ska
                  signera dokumentet är ifyllda på dokumentet för att kunna
                  starta signeringen.
                </strong>
              </>
            )}
          </>
        );

      case "pending":
        return (
          <>
            Dokumentet inväntar signering av de inbjudna parterna. Så fort
            dokumentet är signerat kommer beviset automatiskt att hämtas in till
            Pigello och avtalet markeras som signerat.
            <br /> <br />
            Använd knapparna nedan för att hantera signeringsprocessen.
          </>
        );

      case "timedout":
        return (
          <>
            Tidsgränsen för signeringen har gått ut och processen har därmed
            pausats. Processen kan förlängas med upp till 90 dagar eller
            avbrytas.
            <br /> <br />
            Använd knapparna nedan för att hantera signeringsprocessen.
          </>
        );

      case "canceled":
        return (
          <>
            Signeringsprocessen har avbrutits. Tryck på{" "}
            <strong>Återställ signeringsprocess</strong> för att redigera
            och/eller starta om signeringsprocessen.
          </>
        );

      case "rejected":
        return (
          <>
            Signeringsprocessen har avbrutits på grund av att en part nekade
            signeringen. Tryck på <strong>Återställ signeringsprocess</strong>{" "}
            för att redigera och/eller starta om signeringsprocessen.
          </>
        );

      default:
        return "";
    }
  };

  const getPrimaryAction = () => {
    switch (editableDoc?.status) {
      case "preparation": {
        if (canStartScriveSign) {
          return {
            title: "Starta signering",
            action: () => setConfirmSignOpen(true),
          };
        } else {
          return null;
        }
      }

      case "pending": {
        return {
          title: "Skicka påminnelse",
          action: () => setConfirmRemindOpen(true),
        };
      }

      case "timedout": {
        return {
          title: "Förläng signeringsprocess",
          action: () => setConfirmProlongOpen(true),
        };
      }

      case "canceled": {
        return {
          title: "Återställ signeringsprocess",
          action: () => setConfirmSignRestartOpen(true),
        };
      }

      case "rejected": {
        return {
          title: "Återställ signeringsprocess",
          action: () => setConfirmSignRestartOpen(true),
        };
      }

      default:
        return null;
    }
  };

  const getSecondaryAction = () => {
    switch (editableDoc?.status) {
      case undefined:
        return {
          title: "Skapa dokument",
          action: () => handleCreateEditableDoc(),
        };

      case "preparation":
        return {
          title: "Hantera dokument",
          action: () =>
            push(
              handleContractEditableDocUrl({ id: contract.id, contractType })
            ),
        };

      case "pending": {
        return {
          title: "Avbryt signeringsprocess",
          action: () => setConfirmCancelOpen(true),
        };
      }

      case "timedout": {
        return {
          title: "Återställ signeringsprocess",
          action: () => setConfirmSignRestartOpen(true),
        };
      }

      default:
        return null;
    }
  };

  return (
    <>
      {confirmSignOpen && (
        <ConfirmModal
          acceptTitle={"Starta signering"}
          onAccept={onConfirmSign}
          isInfo
          closeFunction={() => setConfirmSignOpen(false)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Bekräfta startande av signeringsprocess <br />
          </h3>

          <p>
            Delaktiga parter: <br />
            {editableDoc?.parties?.map((p) => (
              <div className="my-2" key={p.id}>
                <div>{p.str_representation}</div>
                <div className="text-xs"> Roll: {p.signatory_role_display}</div>
              </div>
            ))}
          </p>
        </ConfirmModal>
      )}

      {confirmRemindOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmRemindOpen(false)}
          onAccept={onConfirmRemind}
          acceptTitle="Skicka påminnelse"
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Bekräfta skickande av påminnelse
          </h3>

          <p>
            Parterna som ännu inte har signerat avtalet kommer att få ett
            meddelande med en påminnelse om att signera.
          </p>
        </ConfirmModal>
      )}

      {confirmCancelOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmCancelOpen(false)}
          onAccept={onConfirmCancel}
          acceptTitle="Avbryt signering"
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Avbryt signeringsprocess
          </h3>

          <p>
            Eventuella signaturer kommer att makuleras och processen behöver
            startas om från början igen för att kunna slutföras.
          </p>
        </ConfirmModal>
      )}

      {confirmProlongOpen && (
        <Modal
          title="Förläng signeringsprocess"
          canAccept={prolongDays < 90 && prolongDays > 1}
          closeFunction={() => setConfirmProlongOpen(false)}
          onAccept={onConfirmProlong}
        >
          <LocalTextInputField
            title="Förläng med ... dagar"
            value={prolongDays}
            onChange={(val) => setProlongDays(val)}
          />
        </Modal>
      )}

      {confirmSignRestartOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmSignRestartOpen(false)}
          onAccept={onConfirmRestart}
          acceptTitle="Återställ"
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Återställ signeringsprocess
          </h3>

          <p>
            Signeringsprocessen kommer återställas till innan signeringen
            startades. Dokumentet påverkas inte.
          </p>
        </ConfirmModal>
      )}

      {showInfoOpen && (
        <Modal
          title="Information om signering"
          closeFunction={() => setShowInfoOpen(false)}
        >
          <div className="flex">
            {hasScrive && isDigitalSign && (
              <InnerBox>
                <ScriveEvents editableDoc={editableDoc} />
              </InnerBox>
            )}

            <InnerBox
              style={{ marginLeft: hasScrive && isDigitalSign ? 12 : 0 }}
            >
              <OverviewTitleWrapper>
                <OverviewTitle small>Parter</OverviewTitle>
              </OverviewTitleWrapper>
              <Parties editabledoc={editableDoc} />
            </InnerBox>
          </div>
        </Modal>
      )}

      <Alert
        title="Digital signering av avtal med Scrive"
        primaryTitle={getPrimaryAction()?.title}
        primaryAction={getPrimaryAction()?.action}
        secondaryTitle={getSecondaryAction()?.title}
        secondaryAction={getSecondaryAction()?.action}
        tertiaryAction={hasParties ? () => setShowInfoOpen(true) : null}
        tertiaryTitle="Visa information om signering"
      >
        {loading && <OverlaySpinner />}
        Status på signering:{" "}
        <Badge type={SCRIVE_BADGE_TYPE_MAP[editableDoc?.status]}>
          {SCRIVE_DESCRIPTION_MAP[editableDoc?.status]}
        </Badge>
        <br /> <br />
        {renderInfoText()}
      </Alert>
    </>
  );
}
