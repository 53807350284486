import * as React from "react";
import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
} from "../../base";
import { axiosInstance } from "../../base/store/axios";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
} from "../store/actions";
import constants from "../store/constants";

export function useUser(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

// special for creating new anonymous users
export function useCreateUser(inviteToken) {
  const [user, setUser] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetchAnonymousUser = async () => {
    try {
      setIsFetching(true);

      const { data } = await axiosInstance.get(
        `/accounts/users/user/create/${inviteToken}/`
      );

      setUser(data);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
      setError(e);
    }
  };

  if (!user && !isFetching && !error) {
    fetchAnonymousUser();
  }

  return [user, isFetching, error];
}

export function useUsers(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredUsers(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useUserPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}
