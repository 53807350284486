import styled, { css } from "styled-components";
import { animated } from "@react-spring/web";

export const PopupContainer = styled(animated.div)`
  position: absolute;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  box-shadow: 0 4px 17px -3px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  top: calc(100%);
  display: flex;
  flex-direction: column;
  cursor: default;

  min-width: 300px;
  max-width: 300px;
  min-height: 120px;

  z-index: 998;
  font-family: "Inter", sans-serif;

  ${(p) =>
    p.positionRight
      ? css`
      right: 0;
    :
    `
      : css`
          left: 0;
        `}
`;
