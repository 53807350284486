export const detailUrl = ({ id }) => `/lease-contracts/detail/${id}/invoicing`;

export const invoicingToContractDetailUrl = ({ id }) =>
  `/error-redirect/invoicinglease/${id}`;

export const previewAndGenerateUrl = ({
  type,
  id,
  contractId,
  basedOn,
  hasGenerated,
  withGenerate,
}) =>
  `/invoicing/preview/${type}/${contractId}/${id}/${basedOn}?withGenerate=${withGenerate}&hasGenerated=${hasGenerated}`;

export const INVOICE_TYPES = {
  LEASE: "leaseinvoicing",
  PARKING: "parkinginvoicing",
  OTHER: "otherinvoicing",
  BRF: "brfinvoicing",
};
