import * as React from "react";

export default () => {
  const isGov = window.location.href.includes("gov");

  return (
    <div>
      Registrering av avvikelser.
      <br />
      <br />
      Fyll i alla eventuella avvikelser som uppstår under{" "}
      {isGov ? "besiktningen" : "ronderingen"}.
    </div>
  );
};
