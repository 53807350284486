import * as React from "react";
import { useAllPermissionCheck } from "../../../../../store/base";
import { useCanUseScrive } from "../../../../../store/editabledocs/hooks/retrieve";

import StepOptions from "../../../Base/StepOptions/StepOptions";

const STEPS = {
  CONTRACT_TYPE: 1,
  SIGNING_TYPE: 2,
};

export default function ContractType({ onDone }) {
  const [step, setStep] = React.useState(STEPS.CONTRACT_TYPE);
  const [canUseScrive] = useCanUseScrive();

  const [contractType, setContractType] = React.useState(null);
  const [signingType, setSigningType] = React.useState(null);

  const hasFastDocPermission = useAllPermissionCheck([
    "allow_fastdoc",
    "view_can_fastdoc",
  ]);

  return (
    <>
      {step === STEPS.CONTRACT_TYPE && (
        <StepOptions
          title="Välj typ av avtal"
          onSelected={(type) => {
            setContractType(type);

            if (canUseScrive && type === "new") {
              setStep(STEPS.SIGNING_TYPE);
            } else {
              onDone({
                contractType: type,
                signingType: "manual",
              });
            }
          }}
          options={[
            {
              id: "new",
              title: "Nytt avtal",
              renderDescription: () => (
                <div>
                  Skapa ett nytt avtal med antingen manuell eller digital
                  signering.
                </div>
              ),
            },
            {
              id: "existing",
              title: "Existerande avtal",
              renderDescription: () => (
                <div>
                  Lägg till ett existerande avtal som redan är signerat.
                  <br />
                  Vid skapande av existerande avtal så hoppas dokumentsteget
                  över.
                </div>
              ),
            },
          ]}
        />
      )}

      {step === STEPS.SIGNING_TYPE && (
        <StepOptions
          title="Välj typ av signering"
          goBack={() => setStep(STEPS.CONTRACT_TYPE)}
          onSelected={(type) => {
            setSigningType(type);
            onDone({
              contractType,
              signingType: type,
            });
          }}
          options={[
            {
              id: "digital",
              title: "Digital signering genom Scrive",
              renderDescription: () => (
                <div>
                  Avtalet signeras digital genom Scrive och uppdateras
                  automatiskt vid avklarad signering
                </div>
              ),
            },
            {
              id: "manual",
              title: hasFastDocPermission
                ? "Manuell eller genom Fastighetsägarna dokument"
                : "Manuell signering",
              renderDescription: () => (
                <div>
                  Avtalet signeras manuellt
                  {hasFastDocPermission
                    ? "/genom Fastighetsägarna Dokument"
                    : ""}{" "}
                  genom att markera det som signerat i systemet och ladda upp
                  det signerade avtalet.
                </div>
              ),
            },
          ]}
        />
      )}
    </>
  );
}
