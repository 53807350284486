import * as React from "react";
import { LinkedObject } from "../../Displays";
import { detailUrl as checklistDetailUrl } from "../../../store/checklists";
import { detailUrl as userDetailUrl } from "../../../store/users";

// style, design

export default () => {
  return [
    {
      Header: "Komponenttyp",
      accessor: "str_representation",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Checklista felanmälan",
      accessor: "report_errand_checklist.str_representation",
      Cell: ({ row }) => {
        return (
          <LinkedObject
            obj={row.original.report_errand_checklist}
            urlMethod={checklistDetailUrl}
          />
        );
      },
    },
    {
      Header: "Checklista besiktning",
      accessor: "inspection_errand_checklist.str_representation",
      Cell: ({ row }) => {
        return (
          <LinkedObject
            obj={row.original.inspection_errand_checklist}
            urlMethod={checklistDetailUrl}
          />
        );
      },
    },
    {
      Header: "Checklista Rondering",
      accessor: "rounding_errand_checklist.str_representation",
      Cell: ({ row }) => {
        return (
          <LinkedObject
            obj={row.original.rounding_errand_checklist}
            urlMethod={checklistDetailUrl}
          />
        );
      },
    },
    {
      Header: "Kopplade artiklar",
      accessor: "default_articles",
      Cell: ({ value }) => {
        return <div>{value?.length || "0"}</div>;
      },
    },
    {
      Header: "Rapport skickas till",
      accessor: "send_faults_to_user",
      Cell: ({ value }) => {
        return <LinkedObject obj={value} urlMethod={userDetailUrl} />;
      },
    },
  ];
};
