import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

import LeaseContractsWithCostsTable from "../../../components/Tables/LeaseContractsWithCosts/FullTableNewVersionRework";
import BrfPremisesTable from "../../../components/Tables/BrfPremises/FullTable";
import ParkingContractWithCostsTable from "../../../components/Tables/Parking/ParkingContractsWithCosts/FullTableNewVersion";

const TABS = {
  LEASECONTRACT: "Hyresavtal",
  PARKINGCONTRACTS: "Parkeringsavtal",
  BRF: "Bostadsrätter",
};

export default function IMDContracts() {
  const { filteredRealEstates } = useActiveFilters();
  const [selectedTab, setSelectedTab] = React.useState("LEASECONTRACT");

  const contractPersistantQuery = {
    "imd_inactive!": null,
    realestate_ids: filteredRealEstates,
  };

  const brfPersistantQuery = {
    realestate_ids: filteredRealEstates,
    imd_sensor_count_gte: 1,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Debiteras med IMD</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        {selectedTab === "LEASECONTRACT" && (
          <LeaseContractsWithCostsTable
            tableId={"imd_contracts_view_full_lease_contract_table"}
            persistantQuery={contractPersistantQuery}
            withCosts
          />
        )}

        {selectedTab === "PARKINGCONTRACTS" && (
          <ParkingContractWithCostsTable
            tableId={"imd_contracts_view_full_parking_contract_table"}
            persistantQuery={contractPersistantQuery}
            withCosts
          />
        )}

        {selectedTab === "BRF" && (
          <BrfPremisesTable
            tableId={"imd_contracts_view_full_brf_contract_table"}
            persistantQuery={brfPersistantQuery}
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
