import * as React from "react";

// style, design

import { constants } from "../../../../../store/reportErrandSettings";
import { NonNestedSelect } from "../../../Base/Fields";
import { useFilteredUsers } from "../../../../../store/users";
import { buildQueryString } from "../../../../../store/base";
import DelegationOrder from "../../DelegationOrder/DelegationOrder";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const trusteeKey = "responsible_trustee";
  // ROLES
  const filteredUsersQuery = buildQueryString({
    user_type__in: [0, 1, 2],
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Utförare</OverviewTitle>
          <OverviewSubtitle>
            Ange vem som är ansvarig förvaltare för denna typ av felanmälan samt
            vilka som ska tillfrågas om utförande
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />
      <NonNestedSelect
        storeName={storeName}
        method={method}
        fieldKey={trusteeKey}
        fetchMethod={useFilteredUsers}
        fetchQueryString={filteredUsersQuery}
        title="Ansvarig"
      />

      <DelegationOrder
        storeName={storeName}
        method={method}
        goToRoleButton
        modalInModal
      />
    </>
  );
};
