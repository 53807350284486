export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INSIGHTSPAGE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INSIGHTSPAGE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INSIGHTSPAGE",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INSIGHTSPAGE",
  DESTROY_FORM: "DESTROY_FORM_INSIGHTSPAGE",
  SET_FORM_ERROR: "SET_FORM_ERROR_INSIGHTSPAGE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INSIGHTSPAGE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INSIGHTSPAGE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INSIGHTSPAGE",
  RESET_STATE: "RESET_STATE_INSIGHTSPAGE",
  CLEAR_FETCHED: "CLEAR_FETCHED_INSIGHTSPAGES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INSIGHTSPAGE",
  REMOVE_OBJECT: "REMOVE_OBJECT_INSIGHTSPAGE",
  CLEAR_INSIGHTSPAGE_VALUES: "CLEAR_INSIGHTSPAGE_VALUES",
  SET_LOCAL_FILTERED_IDS: "SET_LOCAL_FILTERED_INSIGHTSPAGE_IDS",

  LIST_URL: "/insights/insightspage/list/",
  POST_URL: "/insights/insightspage/",
  PATCH_URL: "/insights/insightspage/",
  GET_URL: "/insights/insightspage/",

  STORE_NAME: "insightsPage",
};
