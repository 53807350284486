import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import FormLayout from "./components/Layout/FormLayout";

import ConfigCenterLayout from "./components/Layout/Accounts/ConfigCenter/ConfigCenterLayout";

import MainTenantOverview from "./views/Tenant/MainOverview";
import TenantDetail from "./views/Tenant/MainDetail";

import LeaseContractOverview from "./views/LeaseContract/MainOverview";
import LeaseCreateContract from "./views/LeaseContract/Create/CreateContract";
import LeaseCreateCosts from "./views/LeaseContract/Create/CreateCosts";

import LeaseCancel from "./views/LeaseContract/Cancel";

import OtherCancel from "./views/OtherContract/Cancel";
import ParkingCancel from "./views/ParkingContract/Cancel";

import Login from "./views/Auth/Login";

import EditableDocHandleContract from "./views/EditableDoc/HandleContract";

import ServiceCreate from "./views/ServiceContract/create/CreateContract";

import ResetPasswordWithToken from "./views/Auth/ResetPassword";
import BillectaKyc from "./views/Billecta/detail/Kyc";
import CreateAnonymousUser from "./views/Auth/CreateAnonymousUser";
import CreateParkingLot from "./views/Parking/CreateParkingLot";
import EditParkingLot from "./views/Parking/EditParkingLot";
import CreateParkingSpot from "./views/Parking/CreateParkingSpot";
import EditParkingSpot from "./views/Parking/EditParkingSpot";

import MainReportErrandOverview from "./views/ReportErrands/MainOverview";
import MainReportErrandDetail from "./views/ReportErrands/MainDetail";

import MainRoundingErrandOverview from "./views/RoundingErrands/MainOverview";
import MainRoundingErrandDetail from "./views/RoundingErrands/MainDetail";
import MainRoundingErrandSettingDetail from "./views/RoundingErrands/MainDetailSetting";
import RoundingErrandExecute from "./views/RoundingErrands/Execute/ExecuteErrand";

import MainGovErrandOverview from "./views/GovErrands/MainOverview";
import MainGovErrandDetail from "./views/GovErrands/MainDetail";
import MainGovErrandSettingDetail from "./views/GovErrands/MainDetailSetting";
import GovErrandExecute from "./views/GovErrands/Execute/ExecuteErrand";

import UserSetupCreateUser from "./views/Account/UserSetup/CreateUser";
import UserSetupInviteUser from "./views/Account/UserSetup/InviteUser";
import UserSetupSetUserPermissions from "./views/Account/UserSetup/SetUserPermissions";
import UserSetupPickExistingGroup from "./views/Account/UserSetup/PickExistingGroup";
import UserSetupCreateNewGroup from "./views/Account/UserSetup/CreateNewGroup";
import UserSetupCreateNewWhiteListSettings from "./views/Account/UserSetup/CreateNewWhiteListSettings";

import BaseLayout from "./components/Layout/BaseLayout";

import MyWorkday from "./views/Dashboard/MyWorkday";

import MarketOverview from "./views/Market/MainOverview.js";
import CompanyDetail from "./views/Companies/MainDetail";
import MainApartmentDetail from "./views/Apartment/MainDetail";

import IframeTest from "./views/IframeTest/Test";

import LeaseContractDetail from "./views/LeaseContract/MainDetail";
import BillectaOverview from "./views/Billecta/MainOverview";
import BillectaDetail from "./views/Billecta/MainDetail";
import InvoicingProduct from "./views/ConfigCenter/InvoicingSettings/InvoicingProduct";
import InvoicingProject from "./views/ConfigCenter/InvoicingSettings/InvoicingProject";
import InvoicingCostCenter from "./views/ConfigCenter/InvoicingSettings/InvoicingCostCenter";
import ErrorRedirect from "./views/InvoicingErrors/ErrorRedirect";
import MainIndustrialPremisesDetail from "./views/IndustrialPremises/MainDetail";
import IMDOverview from "./views/IMD/Overview";
import TransactionOverview from "./views/Transactions/Overview";

import ConnectSensors from "./views/IMD/connect/ConnectSensors";
import SensorHandlePage from "./views/IMD/connect/HandlePage";
import PerformIMDImport from "./views/IMD/importValues/PerformImport";
import IMDMeasureTypeDetail from "./views/IMD/MeasureTypeDetail";
import BuildingDetail from "./views/Building/MainDetail";
import InvoiceDetail from "./views/Billecta/InvoiceDetail";
import MainApartmentsOverview from "./views/Apartment/MainOverview";
import MainIndustrialPremisesOverview from "./views/IndustrialPremises/MainOverview";
import MainParkingOverview from "./views/Parking/MainOverview";
import MainCompanyOverview from "./views/Companies/MainOverview";
import MainRealEstatesOverview from "./views/RealEstate/MainOverview";
import PortfoliosOverview from "./views/Portfolios/MainOverview";
import PortfolioDetail from "./views/Portfolios/MainDetail";
import MainBuildingOverview from "./views/Building/MainOverview";
import MainParkingLotDetails from "./views/Parking/MainLotDetails";
import MainParkingSpotDetails from "./views/Parking/MainSpotDetails";
import MainDocumentsOverview from "./views/BasicDoc/MainOverview";
import MainDocumentDetail from "./views/BasicDoc/MainDetail";
import MainCommonAreasOverview from "./views/CommonAreas/MainOverview";
import MainCommonAreaDetail from "./views/CommonAreas/MainDetail";
import OtherContractOverview from "./views/OtherContract/MainOverview";
import OtherContractDetail from "./views/OtherContract/MainDetail";
import OtherContractCreate from "./views/OtherContract/create/CreateContract";
import OtherContractCostForm from "./views/OtherContract/create/CreateCosts";
import ParkingContractOverview from "./views/ParkingContract/MainOverview";
import ParkingContractDetail from "./views/ParkingContract/MainDetail";
import ParkingContractCreate from "./views/Parking/create/CreateContract";
import ParkingContractCostForm from "./views/ParkingContract/create/CreateCosts";
import ServiceContractOverview from "./views/ServiceContract/MainOverview";
import ServiceContractDetail from "./views/ServiceContract/MainDetail";
import ServiceCancel from "./views/ServiceContract/Cancel";
import ServiceContractCostForm from "./views/ServiceContract/create/CreateCosts";
import MainBrfCompanyOverview from "./views/BrfCompanies/MainOverview";
import ServicePartnerDetail from "./views/ServicePartner/MainDetail";
import BrfCompanyDetail from "./views/BrfCompanies/MainDetail";
import MainBrfPremisesOverview from "./views/BrfPremises/MainOverview";
import MainBrfPremisesDetail from "./views/BrfPremises/MainDetail";
import MainMemberOverview from "./views/BrfMembers/MainOverview";
import MemberDetail from "./views/BrfMembers/MainDetail";
import BrfPremisesCostsForm from "./views/BrfPremises/create/Costs";
import PermissionDeniedPage from "./views/Auth/PermissionDenied";
import TemplateOverview from "./views/EditableDoc/MainOverview";
import CreateTemplate from "./views/EditableDoc/CreateTemplate";

import KeyOverview from "./views/Keys/MainOverview";
import KeyDetail from "./views/Keys/Detail/Key";
import KeyPermissionDetail from "./views/Keys/Detail/KeyPermission";
import KeyCreateForm from "./views/Keys/Form/CreateKey";
import KeyUpdateForm from "./views/Keys/Form/UpdateKey";
import KeyPermissionCreateForm from "./views/Keys/Form/CreateKeyPermission";
import KeyPermissionUpdateForm from "./views/Keys/Form/UpdateKeyPermission";
import KeyDeviceCreateForm from "./views/Keys/Form/CreateKeyDevice";
import KeyDeviceUpdateForm from "./views/Keys/Form/UpdateKeyDevice";

import Calendar from "./views/Calendar/Calendar";
import OutlookActivation from "./views/Calendar/OutlookActivation";
import EditTemplate from "./views/EditableDoc/EditTemplate";
import EditableDocMainDetail from "./views/EditableDoc/MainDetail";
import CancelledDocHandleContract from "./views/EditableDoc/CancelContract";
import RealEstateDetail from "./views/RealEstate/MainDetail";
import InvoicingCustomDimension from "./views/ConfigCenter/InvoicingSettings/InvoicingCustomDimension";
import InvoicingCustomDimensionEntry from "./views/ConfigCenter/InvoicingSettings/InvoicingCustomDimensionEntry";
import MyWorkdayTasks from "./views/Dashboard/MyWorkdayTasks";
import Todos from "./views/Dashboard/Todos";
import MainInspectionErrandsOverview from "./views/InspectionErrands/MainOverview";
import MainInspectionErrandsDetail from "./views/InspectionErrands/MainDetail";
import ExecuteInspectionErrand from "./views/InspectionErrands/Execute";
import PipeDetail from "./views/Market/PipeDetail";
import RequirementDetail from "./views/Market/RequirementMainDetail";
import ManyAdRootObjectSelect from "./views/Market/ManyHandling/ManyAdRootObjectSelect";
import CreateManyAds from "./views/Market/ManyHandling/CreateManyAds";
import ManyAdsDefaultSettings from "./views/Market/ManyHandling/ManyAdsDefaultSettings";
import ApartmentAdDetail from "./views/Market/ApartmentAdDetail";
import IndustrialPremisesAdDetail from "./views/Market/IndustrialPremisesAdDetail";
import ParkingSpotAdDetail from "./views/Market/ParkingSpotAdDetail";
import ManyCommoditiesRootObjectSelect from "./views/Market/ManyHandling/ManyCommoditiesRootObjectSelect";
import ManyCommoditiesDefaultSettings from "./views/Market/ManyHandling/ManyCommoditiesDefaultSettings";
import CreateManyCommodities from "./views/Market/ManyHandling/CreateManyCommodities";
import ErrorPage from "./views/Error/Error";
import PrepareLeadsForContract from "./views/Market/ManyHandling/PrepareLeadsForContract";
import LeadsContractProposals from "./views/Market/ManyHandling/LeadsContractProposals";
import LeadsCostsProposals from "./views/Market/ManyHandling/LeadsCostsProposals";
import PrepareDocForFieldMatching from "./views/Market/ManyHandling/PrepareDocForFieldMatch";
import HandleLeadEditableDoc from "./views/Market/ManyHandling/HandleLeadEditableDoc";
import PublishNewProductionAdsDefaultSettings from "./views/Market/ManyHandling/PublishNewProductionAdsDefaultSettings";
import PublishNewProductionAds from "./views/Market/ManyHandling/PublishNewProductionAds";
import MainReports, {
  TableView as ExcelReportsTableView,
} from "./views/NewReports/index";

import PreviewGenerateInvoiceBase from "./views/LeaseContract/PreviewGenerateInvoiceBase";
import MainInvoiceRecordDetail from "./views/InvoiceRecord/MainDetail";
import MainVacancyRecordDetail from "./views/InvoiceRecord/VacancyMainDetail";
import HomeQRedirectPage from "./views/Market/HomeQRedirectPage";
import CreateApartment from "./views/Apartment/CreateApartment";
import EditApartment from "./views/Apartment/EditApartment";
import CreateIndp from "./views/IndustrialPremises/CreateIndustrialPremises";
import MainErrandSettingsOverview from "./views/ErrandSettings/MainOverview";
import ComponentTypeDetail from "./views/ErrandSettings/ComponentTypeDetail";
import ProductTypeDetail from "./views/ErrandSettings/ProductTypeDetail";
import ArticleDetail from "./views/ErrandSettings/ArticleDetail";
import ChecklistDetail from "./views/ErrandSettings/ChecklistDetail";
import ComponentDetail from "./views/ErrandSettings/ComponentDetail";
import MassConfig from "./views/ErrandSettings/MassConfig";
import MassConfigPreview from "./views/ErrandSettings/MassConfigPreview";
import MassConfigRoomComponentSetup from "./views/ErrandSettings/MassConfigRoomComponentSetup";
import MassConfigSummaryView from "./views/ErrandSettings/MassConfigSummary";
import RolesHandling from "./views/ErrandSettings/RolesHandling";

import GroupDetailBox from "./components/Layout/Accounts/ConfigCenter/Boxes/GroupDetailBox";
import ProfileDetailBox from "./components/Layout/Accounts/ConfigCenter/Boxes/ProfileDetailBox";
import CategoriesDetailBox from "./components/Layout/Accounts/ConfigCenter/Boxes/CategoriesDetailBox";
import IntegrationsDetailBox from "./components/Layout/Accounts/ConfigCenter/Boxes/IntegrationsDetailBox";
import InvoicingDetailBox from "./components/Layout/Accounts/ConfigCenter/Boxes/InvoicingDetailBox";
import TenantsDetailBox from "./components/Layout/Accounts/ConfigCenter/Boxes/TenantsDetailBox";
import GeneralDetailBox from "./components/Layout/Accounts/ConfigCenter/Boxes/GeneralDetailBox";

import InsightsMain from "./views/Insights/InsightsMain.js";

import PaymentGroupCreate from "./views/PaymentGroup/Forms/CreatePaymentGroup";
import PaymentGroupUpdate from "./views/PaymentGroup/Forms/UpdatePaymentGroup";
import PaymentGroupDetail from "./views/PaymentGroup/MainDetail";

import LoanCreate from "./views/Loan/Forms/CreateLoan";
import LoanUpdate from "./views/Loan/Forms/UpdateLoan";
import LoanDetail from "./views/Loan/MainDetail";

import BrfPaymentGrouptQuotaCreate from "./views/BrfPaymentGroupQuota/Forms/CreateQuota";
import BrfPaymentGrouptQuotaUpdate from "./views/BrfPaymentGroupQuota/Forms/UpdateQuota";
import BrfPaymentGrouptQuotaDetail from "./views/BrfPaymentGroupQuota/MainDetail";

import BrfPaymentContributionCreate from "./views/PaymentContribution/Forms/CreateContribution";
import BrfPaymentContributionUpdate from "./views/PaymentContribution/Forms/UpdateContribution";
import MainSpecificationDetails from "./views/Parking/MainSpecificationDetails";
import ApartmentRentIncrease from "./views/Apartment/RentIncrease";
import IndustrialPremisesRentIncrease from "./views/IndustrialPremises/RentIncrease";
import LeaseContractOptionsForm from "./components/Forms/LeaseContract/ContractOptionsForm/FlowForm";
import ParkingContractOptionsForm from "./components/Forms/ParkingContract/ContractOptionsForm/FlowForm";
import OtherContractOptionsForm from "./components/Forms/OtherContract/ContractOptionsForm/FlowForm";
import CreateInvoice from "./views/Billecta/CreateInvoice";
import EditInvoice from "./views/Billecta/EditInvoice";
import CreditInvoice from "./views/Billecta/CreditInvoice";

import KU55Handling from "./views/BrfCompanies/KU55";
import IndexationsConfigCenterPage from "./components/Layout/Accounts/ConfigCenter/Boxes/Indexations";
import CompanyAwaitActivateVisma from "./views/Companies/detail/AwaitActivationVisma";
import RoundingAreaDetail from "./views/RoundingAreas/MainDetail";
import DefaultInsightsPage from "./views/Insights/DefaultInsightsPage";
import KU55PrintView from "./views/BrfCompanies/KU55printview";

export default () => {
  return (
    <Switch>
      {/* Dashboard */}
      <BaseLayout exact path="/" component={MyWorkday} />
      <BaseLayout path="/my-workday" component={MyWorkdayTasks} />
      <BaseLayout path="/todos" component={Todos} />
      <BaseLayout
        path="/dashboard"
        component={
          window.location.host === "demo.app.pigello.se" ||
          window.location.host === "localhost:3000"
            ? DefaultInsightsPage
            : InsightsMain
        }
      />
      <BaseLayout exact path="/transactions" component={TransactionOverview} />
      <BaseLayout exact path="/calendar" component={Calendar} />
      <BaseLayout exact path="/outlookActivate" component={OutlookActivation} />
      {/** Creation and Editing */}
      <FormLayout
        path="/invoicing/preview/:type/:contractId/:id/:basedOn"
        component={PreviewGenerateInvoiceBase}
      />
      <FormLayout
        exact
        path="/inspection-errands/execute/:inspectionErrandId"
        component={ExecuteInspectionErrand}
      />
      <FormLayout
        exact
        path="/imd/connect-sensors"
        component={ConnectSensors}
      />

      <FormLayout
        exact
        path="/imd/manual-import"
        component={PerformIMDImport}
      />
      <FormLayout
        exact
        path="/imd/handle-sensors"
        component={SensorHandlePage}
      />
      <FormLayout
        exact
        path="/lease-contracts/create/type"
        component={LeaseContractOptionsForm}
      />
      <FormLayout
        exact
        path="/lease-contracts/create/costs/:contractId"
        component={LeaseCreateCosts}
      />
      <FormLayout
        exact
        path="/lease-contracts/create/:contractType/:premisesKind"
        component={LeaseCreateContract}
      />
      <FormLayout
        exact
        path="/other-contracts/create/type"
        component={OtherContractOptionsForm}
      />
      <FormLayout
        exact
        path="/other-contracts/create/:contractType"
        component={OtherContractCreate}
      />
      <FormLayout
        exact
        path="/other-contracts/create/costs/:contractId"
        component={OtherContractCostForm}
      />

      <FormLayout
        exact
        path="/parking-contracts/create/type"
        component={ParkingContractOptionsForm}
      />
      <FormLayout
        exact
        path="/parking-contracts/create/:contractType"
        component={ParkingContractCreate}
      />
      <FormLayout
        exact
        path="/parking-contracts/create/costs/:contractId"
        component={ParkingContractCostForm}
      />
      <FormLayout
        exact
        path="/service-contracts/create"
        component={ServiceCreate}
      />
      <FormLayout
        exact
        path="/service-contracts/create/costs/:contractId"
        component={ServiceContractCostForm}
      />
      <FormLayout
        exact
        path="/brf-premises/create/costs/:contractId"
        component={BrfPremisesCostsForm}
      />
      <FormLayout
        exact
        path="/editable-doc/create"
        component={CreateTemplate}
      />
      <FormLayout
        exact
        path="/editable-doc/edit/:editableDocId"
        component={EditTemplate}
      />
      <FormLayout
        exact
        path="/editable-doc/handle/:contractType/:id"
        component={EditableDocHandleContract}
      />
      <FormLayout
        exact
        path="/cancelled-doc/handle/:contractType/:id"
        component={CancelledDocHandleContract}
      />
      <FormLayout
        exact
        path="/lease-contracts/cancel/:leaseContractId"
        component={LeaseCancel}
      />
      <FormLayout
        exact
        path="/other-contracts/cancel/:otherContractId"
        component={OtherCancel}
      />
      <FormLayout
        exact
        path="/parking-contracts/cancel/:parkingContractId"
        component={ParkingCancel}
      />
      <FormLayout
        exact
        path="/service-contracts/cancel/:serviceContractId"
        component={ServiceCancel}
      />

      <FormLayout exact path="/apartments/create" component={CreateApartment} />
      <FormLayout exact path="/apartments/edit/:id" component={EditApartment} />
      <FormLayout
        exact
        path="/industrial-premises/create"
        component={CreateIndp}
      />
      <FormLayout
        exact
        path="/parking-lots/create"
        component={CreateParkingLot}
      />
      <FormLayout
        exact
        path="/parking-lots/edit/:parkingLotId"
        component={EditParkingLot}
      />
      <FormLayout
        exact
        path="/parking-spots/create"
        component={CreateParkingSpot}
      />
      <FormLayout
        exact
        path="/parking-spots/edit/:parkingSpotId"
        component={EditParkingSpot}
      />
      <FormLayout
        exact
        path="/rounding-errands/execute/:errandId"
        component={RoundingErrandExecute}
      />
      <FormLayout
        exact
        path="/gov-errands/execute/:errandId"
        component={GovErrandExecute}
      />
      <FormLayout
        exact
        path="/configcenter/groups-and-users/user-setup/create-user"
        component={UserSetupCreateUser}
      />
      <FormLayout
        exact
        path="/configcenter/groups-and-users/user-setup/set-user-permissions/existing-group/:userId"
        component={UserSetupPickExistingGroup}
      />
      <FormLayout
        exact
        path="/configcenter/groups-and-users/user-setup/set-user-permissions/create-group/:userId"
        component={UserSetupCreateNewGroup}
      />
      <FormLayout
        exact
        path="/configcenter/groups-and-users/user-setup/set-user-permissions/create-white-list-setting/:groupId/:userId"
        component={UserSetupCreateNewWhiteListSettings}
      />
      <FormLayout
        exact
        path="/configcenter/groups-and-users/user-setup/set-user-permissions/:userId"
        component={UserSetupSetUserPermissions}
      />
      <FormLayout
        exact
        path="/configcenter/groups-and-users/user-setup/invite-user/:groupId/:userId"
        component={UserSetupInviteUser}
      />

      <FormLayout
        exact
        path="/market/ads/publish-new-production/settings/:pipeId"
        component={PublishNewProductionAdsDefaultSettings}
      />
      <FormLayout
        exact
        path="/market/ads/publish-new-production/handle/:pipeId"
        component={PublishNewProductionAds}
      />

      <FormLayout
        exact
        path="/market/ads/create-many/:type"
        component={ManyAdRootObjectSelect}
      />
      <FormLayout
        exact
        path="/market/ads/create-many/:type/default-settings"
        component={ManyAdsDefaultSettings}
      />
      <FormLayout
        exact
        path="/market/ads/create-many/:type/handle"
        component={CreateManyAds}
      />
      <FormLayout
        exact
        path="/market/ads/create-many-commodities/:type"
        component={ManyCommoditiesRootObjectSelect}
      />
      <FormLayout
        exact
        path="/market/ads/create-many-commodities/:type/default-settings"
        component={ManyCommoditiesDefaultSettings}
      />
      <FormLayout
        exact
        path="/market/ads/create-many-commodities/:type/handle"
        component={CreateManyCommodities}
      />

      <FormLayout
        exact
        path="/market/pipes/contract/prepare-leads-for-contract/"
        component={PrepareLeadsForContract}
      />
      <FormLayout
        exact
        path="/market/pipes/contract/proposals/:type"
        component={LeadsContractProposals}
      />
      <FormLayout
        exact
        path="/market/pipes/contract/cost-proposals/:type"
        component={LeadsCostsProposals}
      />
      <FormLayout
        exact
        path="/market/pipes/contract/pick-template/:type"
        component={PrepareDocForFieldMatching}
      />
      <FormLayout
        exact
        path="/market/pipes/contract/approve-documents/:type"
        component={HandleLeadEditableDoc}
      />

      <FormLayout
        exact
        path="/errand-settings/mass-config/setup"
        component={MassConfig}
      />
      <FormLayout
        exact
        path="/errand-settings/mass-config/preview"
        component={MassConfigPreview}
      />
      <FormLayout
        exact
        path="/errand-settings/mass-config/room-component-config"
        component={MassConfigRoomComponentSetup}
      />
      <FormLayout
        exact
        path="/errand-settings/mass-config/summary"
        component={MassConfigSummaryView}
      />
      <FormLayout
        path="/keys/keys/create/user-keypermission/:userId/:permissionId"
        component={KeyCreateForm}
      />
      <FormLayout
        exact
        path="/keys/keys/create/user/:userId"
        component={KeyCreateForm}
      />
      <FormLayout
        exact
        path="/keys/keys/create/keypermission/:permissionId"
        component={KeyCreateForm}
      />
      <FormLayout exact path="/keys/keys/create" component={KeyCreateForm} />
      <FormLayout
        exact
        path="/keys/keys/update/:id"
        component={KeyUpdateForm}
      />
      <FormLayout
        exact
        path="/keys/keypermissions/create/:objectType/:objectId"
        component={KeyPermissionCreateForm}
      />
      <FormLayout
        exact
        path="/keys/keypermissions/create"
        component={KeyPermissionCreateForm}
      />
      <FormLayout
        exact
        path="/keys/keypermissions/update/:id"
        component={KeyPermissionUpdateForm}
      />

      <FormLayout
        exact
        path="/keys/keydevices/create/:objectType/:objectId"
        component={KeyDeviceCreateForm}
      />
      <FormLayout
        exact
        path="/keys/keydevices/create"
        component={KeyDeviceCreateForm}
      />
      <FormLayout
        exact
        path="/keys/keydevices/update/:id"
        component={KeyDeviceUpdateForm}
      />

      <FormLayout
        exact
        path="/payment-groups/create/:brfCompanyId"
        component={PaymentGroupCreate}
      />
      <FormLayout
        exact
        path="/payment-groups/update/:id/:brfCompanyId"
        component={PaymentGroupUpdate}
      />

      <FormLayout
        exact
        path="/loans/create/:brfCompanyId/:paymentGroupId"
        component={LoanCreate}
      />
      <FormLayout
        exact
        path="/loans/create/:brfCompanyId/"
        component={LoanCreate}
      />

      <FormLayout
        exact
        path="/errand-settings/roles/handle-role/:id"
        component={RolesHandling}
      />
      <FormLayout
        path="/loans/update/:id/:brfCompanyId"
        component={LoanUpdate}
      />

      <FormLayout
        exact
        path="/brf-payment-group-quotas/create/payment-group/:paymentGroupId"
        component={BrfPaymentGrouptQuotaCreate}
      />
      <FormLayout
        exact
        path="/brf-payment-group-quotas/create/brf-premises/:brfPremisesId"
        component={BrfPaymentGrouptQuotaCreate}
      />

      <FormLayout
        exact
        path="/brf-payment-group-quotas/update/:id/:paymentGroupId"
        component={BrfPaymentGrouptQuotaUpdate}
      />
      <FormLayout
        exact
        path="/brf-payment-contributions/create/:paymentGroupId/:brfPremisesId"
        component={BrfPaymentContributionCreate}
      />

      <FormLayout
        exact
        path="/brf-payment-contributions/update/:id"
        component={BrfPaymentContributionUpdate}
      />

      <FormLayout
        exact
        path="/brf-companies/ku55/:brfCompanyId"
        component={KU55Handling}
      />

      <FormLayout
        exact
        path="/rental-notifications/company/:creditorId/invoice/create"
        component={CreateInvoice}
      />
      <FormLayout
        exact
        path="/rental-notifications/company/:creditorId/invoice/:invoiceId/edit"
        component={EditInvoice}
      />
      <FormLayout
        exact
        path="/rental-notifications/company/:creditorId/invoice/:invoiceId/credit"
        component={CreditInvoice}
      />

      {/*creation and editing end */}

      {/* visma integration complete */}
      <FormLayout
        exact
        path="/companies/detail/activate_visma_complete"
        component={CompanyAwaitActivateVisma}
      />

      {/*details */}

      <BaseLayout path="/keys/keys/detail/:id" component={KeyDetail} />
      <BaseLayout
        path="/keys/keypermissions/detail/:id"
        component={KeyPermissionDetail}
      />
      <BaseLayout
        path="/rental-notifications/invoice-records/detail/:invoiceRecordId"
        component={MainInvoiceRecordDetail}
      />
      <BaseLayout
        path="/rental-notifications/vacancy-bookings/detail/:vacancyBookingId"
        component={MainVacancyRecordDetail}
      />
      <BaseLayout
        path="/realestates/detail/:realEstateId"
        component={RealEstateDetail}
      />
      <BaseLayout
        path="/imd/measure-types/detail/:id"
        component={IMDMeasureTypeDetail}
      />
      <BaseLayout path="/tenants/detail/:tenantId" component={TenantDetail} />
      <BaseLayout path="/members/detail/:tenantId" component={MemberDetail} />
      <BaseLayout
        path="/lease-contracts/detail/:leaseContractId"
        component={LeaseContractDetail}
      />
      <BaseLayout
        path="/other-contracts/detail/:otherContractId"
        component={OtherContractDetail}
      />
      <BaseLayout
        path="/documents/detail/:basicDocId"
        component={MainDocumentDetail}
      />
      <BaseLayout
        path="/templates/detail/:editableDocId"
        component={EditableDocMainDetail}
      />
      <BaseLayout
        path="/service-contracts/detail/:serviceContractId"
        component={ServiceContractDetail}
      />
      <BaseLayout
        path="/parking-contracts/detail/:parkingContractId"
        component={ParkingContractDetail}
      />
      <BaseLayout
        path="/parking-lots/detail/:parkingLotId"
        component={MainParkingLotDetails}
      />
      <BaseLayout
        path="/parking-spots/detail/:parkingSpotId"
        component={MainParkingSpotDetails}
      />
      <BaseLayout
        path="/parking-specifications/detail/:specificationId"
        component={MainSpecificationDetails}
      />
      <BaseLayout
        path="/apartments/detail/:apartmentId"
        component={MainApartmentDetail}
      />
      <BaseLayout
        path="/industrial-premises/detail/:industrialPremisesId"
        component={MainIndustrialPremisesDetail}
      />
      <BaseLayout
        path="/common-areas/detail/:commonAreaId"
        component={MainCommonAreaDetail}
      />
      <BaseLayout
        path="/brf-premises/detail/:brfPremisesId"
        component={MainBrfPremisesDetail}
      />
      <BaseLayout
        path="/buildings/detail/:buildingId"
        component={BuildingDetail}
      />
      <BaseLayout
        path="/report-errands/detail/:reportErrandId"
        component={MainReportErrandDetail}
      />
      <BaseLayout
        path="/inspection-errands/detail/:inspectionErrandId"
        component={MainInspectionErrandsDetail}
      />
      <BaseLayout
        path="/inspection-errands/detail/:inspectionErrandId"
        component={MainInspectionErrandsDetail}
      />
      <BaseLayout
        path="/rounding-errands/detail/:roundingErrandId"
        component={MainRoundingErrandDetail}
      />
      <BaseLayout
        path="/rounding-areas/detail/:areaId"
        component={RoundingAreaDetail}
      />
      <BaseLayout
        path="/rounding-settings/detail/:settingId"
        component={MainRoundingErrandSettingDetail}
      />
      <BaseLayout
        path="/gov-errands/detail/:govErrandId"
        component={MainGovErrandDetail}
      />
      <BaseLayout
        path="/gov-settings/detail/:settingId"
        component={MainGovErrandSettingDetail}
      />
      <BaseLayout
        path="/errand-settings/component-types/detail/:id"
        component={ComponentTypeDetail}
      />
      <BaseLayout
        path="/errand-settings/components/detail/:id"
        component={ComponentDetail}
      />
      <BaseLayout
        path="/errand-settings/product-types/detail/:id"
        component={ProductTypeDetail}
      />
      <BaseLayout
        path="/errand-settings/articles/detail/:id"
        component={ArticleDetail}
      />
      <BaseLayout
        path="/errand-settings/checklists/detail/:id"
        component={ChecklistDetail}
      />
      <BaseLayout
        path="/companies/detail/:companyId"
        component={CompanyDetail}
      />
      <BaseLayout
        path="/brf-companies/detail/:brfCompanyId"
        component={BrfCompanyDetail}
      />

      <FormLayout path="/market/pipes/detail/:pipeId" component={PipeDetail} />

      <BaseLayout
        path="/market/requirements/detail/:requirementId"
        component={RequirementDetail}
      />
      <BaseLayout
        path="/market/apartment-ads/detail/:adId"
        component={ApartmentAdDetail}
      />
      <BaseLayout
        path="/market/industrial-premises-ads/detail/:adId"
        component={IndustrialPremisesAdDetail}
      />
      <BaseLayout
        path="/market/parking-spot-ads/detail/:adId"
        component={ParkingSpotAdDetail}
      />

      <BaseLayout
        path="/payment-groups/detail/:paymentGroupId"
        component={PaymentGroupDetail}
      />
      <BaseLayout path="/loans/detail/:loanId" component={LoanDetail} />
      <BaseLayout
        path="/brf-payment-group-quotas/detail/:brfPaymentGroupQuotaId"
        component={BrfPaymentGrouptQuotaDetail}
      />

      {/*details end */}
      {/* overview */}
      <BaseLayout path="/imd" component={IMDOverview} />
      <FormLayout
        exact
        path="/reports/table-view/:id"
        component={ExcelReportsTableView}
      />
      <BaseLayout path="/reports" component={MainReports} />

      <BaseLayout path="/lease-contracts" component={LeaseContractOverview} />
      <BaseLayout path="/other-contracts" component={OtherContractOverview} />
      <BaseLayout
        path="/parking-contracts"
        component={ParkingContractOverview}
      />
      <BaseLayout
        path="/service-contracts"
        component={ServiceContractOverview}
      />
      <BaseLayout path="/tenants" component={MainTenantOverview} />
      <BaseLayout path="/members" component={MainMemberOverview} />
      <BaseLayout
        path="/service-partners/:servicePartnerId"
        component={ServicePartnerDetail}
      />
      <BaseLayout path="/apartments" component={MainApartmentsOverview} />
      <BaseLayout
        path="/industrial-premises"
        component={MainIndustrialPremisesOverview}
      />
      <BaseLayout path="/common-areas" component={MainCommonAreasOverview} />
      <BaseLayout path="/parking" component={MainParkingOverview} />
      <BaseLayout path="/companies" component={MainCompanyOverview} />
      <BaseLayout path="/realestates" component={MainRealEstatesOverview} />
      <BaseLayout path="/portfolios/:portfolioId" component={PortfolioDetail} />
      <BaseLayout path="/portfolios" component={PortfoliosOverview} />
      <BaseLayout path="/buildings" component={MainBuildingOverview} />
      <BaseLayout path="/documents" component={MainDocumentsOverview} />
      <BaseLayout path="/brf-companies" component={MainBrfCompanyOverview} />
      <BaseLayout path="/brf-premises" component={MainBrfPremisesOverview} />
      <BaseLayout path="/templates" component={TemplateOverview} />
      <BaseLayout
        exact
        path="/apartment-rent-increase"
        component={ApartmentRentIncrease}
      />
      <BaseLayout
        exact
        path="/industrial-premises-rent-increase"
        component={IndustrialPremisesRentIncrease}
      />

      <BaseLayout path="/report-errands" component={MainReportErrandOverview} />
      <BaseLayout
        path="/inspection-errands"
        component={MainInspectionErrandsOverview}
      />

      <BaseLayout
        path="/errand-settings"
        component={MainErrandSettingsOverview}
      />
      <BaseLayout
        path="/rounding-errands"
        component={MainRoundingErrandOverview}
      />
      <BaseLayout path="/gov-errands" component={MainGovErrandOverview} />
      <BaseLayout path="/market" component={MarketOverview} />

      <BaseLayout path="/keys" component={KeyOverview} />

      {/* overview end */}
      {/* Billecta */}
      <BaseLayout path="/error-redirect" component={ErrorRedirect} />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/products/:productId"
        component={InvoicingProduct}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/projects/:projectId"
        component={InvoicingProject}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/cost-centers/:costCenterId"
        component={InvoicingCostCenter}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/custom-dimensions/:customDimensionId"
        component={InvoicingCustomDimension}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/custom-dimensions-entries/:customDimensionEntryId"
        component={InvoicingCustomDimensionEntry}
      />
      <BaseLayout
        path="/rental-notifications/company/:creditorId/invoice/:invoiceId"
        component={InvoiceDetail}
      />
      <BaseLayout
        exact
        path="/rental-notifications/company/:creditorId/kyc"
        component={BillectaKyc}
      />
      <BaseLayout
        path="/rental-notifications/company/:creditorId"
        component={BillectaDetail}
      />
      <BaseLayout path="/rental-notifications" component={BillectaOverview} />

      <BaseLayout path="/insights" component={InsightsMain} />

      <ConfigCenterLayout
        path="/configcenter/profile"
        component={ProfileDetailBox}
      />
      <ConfigCenterLayout
        path="/configcenter/groups-and-users"
        component={GroupDetailBox}
      />
      <ConfigCenterLayout
        path="/configcenter/tenant-portal"
        component={TenantsDetailBox}
      />

      <ConfigCenterLayout
        path="/configcenter/rental-notifications"
        component={InvoicingDetailBox}
      />

      <ConfigCenterLayout
        path="/configcenter/indexations"
        component={IndexationsConfigCenterPage}
      />

      <ConfigCenterLayout
        path="/configcenter/categories"
        component={CategoriesDetailBox}
      />

      <ConfigCenterLayout
        path="/configcenter/integrations"
        component={IntegrationsDetailBox}
      />

      <ConfigCenterLayout
        path="/configcenter/general"
        component={GeneralDetailBox}
      />

      <BaseLayout
        exact
        path="/permission-denied"
        component={PermissionDeniedPage}
      />
      {/* Login & signup */}
      <Route exact path="/login" component={Login} />
      <Route
        exact
        path="/reset-password/:resetToken"
        component={ResetPasswordWithToken}
      />
      <Route
        exact
        path="/invitation/:createToken"
        component={CreateAnonymousUser}
      />

      <Route path="/ku55pdfprintview" exact component={KU55PrintView} />

      {/* HomeQ redirect  */}
      <FormLayout path="/homeq-ad-redirect" component={HomeQRedirectPage} />

      <Route exact path="/error/oops" component={ErrorPage} />
      {process.env.NODE_ENV === "development" && (
        <>
          <BaseLayout exact path="/iframe/test" component={IframeTest} />
        </>
      )}
      {/* Redirections */}
      <Redirect from="*" to="/" />
    </Switch>
  );
};
