import InvoicingBase from "./InvoicingBase";
import Confirm from "./Confirm";
import DebtSettings from "./DebtSettings";
import IMDSettings from "./IMDSettings";

const mapping = {
  INVOICING_BASE: InvoicingBase,
  DEBT_SETTINGS: DebtSettings,
  IMD_SETTINGS: IMDSettings,
  CONFIRM: Confirm,
};

export default mapping;
