import * as React from "react";
import * as SC from "./styles";

export default ({
  clicked,
  disabled,
  title,
  extraStyle,
  fillWidth,
  newDesign,
  pulse,
  inverted,

  // IMPORTANT - only absolute elements, like a popupmenu
  children,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };
  return (
    <SC.PrimaryButton
      newDesign={newDesign}
      inverted={inverted}
      style={{ ...extraStyle }}
      {...{ fillWidth, pulse }}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
      {children}
    </SC.PrimaryButton>
  );
};
