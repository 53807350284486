import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";

import SensorsList from "../../../components/Tables/Sensors/FullTable";

export default function MeasureTypeSensors() {
  const { id } = useParams();

  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
    measure_type: id,
  };
  const persistantFilterMethod = (obj) => {
    if (!filteredRealEstates) return obj?.measure_type?.id === parseInt(id);
    return (
      obj?.measure_type?.id === parseInt(id) &&
      filteredRealEstates.includes(obj.realestate.id)
    );
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Sensorer under mätartyp</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <SensorsList persistantQuery={persistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
