import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../../store/base";
import { constants } from "../../../../../../store/IMDMeasureType";
import { NumberInput, TextInput } from "../../../../Base/Fields";

export default () => {
  const dispatch = useDispatch();

  const storeName = constants.STORE_NAME;

  const hasSheetNameValue = Boolean(
    useFormInstanceField({ storeName, fieldKey: "_import._sheetName" })
  );
  const hasHeaderRowValue = Boolean(
    useFormInstanceField({ storeName, fieldKey: "_import._headerRow" })
  );
  React.useEffect(() => {
    let data = {};
    if (!hasSheetNameValue) {
      data["_import._sheetName"] = "Blad1";
    }
    if (!hasHeaderRowValue) {
      data["_import._headerRow"] = 1;
    }

    if (Object.keys(data).length > 0) {
      dispatch(updateActiveFormInstance({ storeName, data }));
    }
  }, []);

  return (
    <div>
      <TextInput
        storeName={storeName}
        fieldKey={"_import._sheetName"}
        title={"Namn på fliken där värdena ligger"}
        manualInstructions={{
          _readOnly: false,
          _required: true,
        }}
        description={
          'Fliknamnet i excelfilen som datan som ska importeras finns i. Vanligtvis är detta "Blad1" i svenska Excel och "Sheet1" i engelska versionen.'
        }
      />
      <NumberInput
        storeName={storeName}
        fieldKey={"_import._headerRow"}
        title={"Rubrikens rad i dokumentet"}
        manualInstructions={{
          _readOnly: false,
          _required: true,
        }}
        description={
          "Raden i excelfilen som kolumnernas rubriker står på. Vanligtvis är detta första raden (rad 1)"
        }
      />
    </div>
  );
};
