import * as React from "react";
import { useAsyncDebounce } from "react-table";
import moment from "moment";
import Pikaday from "pikaday";
import "../../../../../../node_modules/pikaday/css/pikaday.css";

import * as SC from "./styles";

const FORMAT = "YYYY-MM-DD";

const PICKER_i18n = {
  previousMonth: "Föregående månad",
  nextMonth: "Nästa månad",
  months: [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ],
  weekdays: [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
  ],
  weekdaysShort: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
};

export default function SimpleDateFilter({ column }) {
  const { setFilter } = column;
  const [dpFrom, setDpFrom] = React.useState(null);
  const [dpTo, setDpTo] = React.useState(null);
  const pickerRefFrom = React.useRef(null);
  const pickerRefTo = React.useRef(null);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const onChange = useAsyncDebounce((value) => {
    if (value && value?.length) {
      setFilter(value);
    }
  }, 200);

  const onFromChange = (val) => {
    if (val) {
      setFromDate(moment(val).format(FORMAT));
    }
  };
  const onToChange = (val) => {
    if (val) {
      setToDate(moment(val).format(FORMAT));
    }
  };

  React.useEffect(() => {
    const filterQuery = [fromDate, toDate].filter((d) => d);

    onChange(filterQuery);
  }, [fromDate, toDate, onChange]);

  React.useEffect(() => {
    // prevent double inits on hot reloads (dev env bug)
    if (dpTo) return;
    const datepicker = new Pikaday({
      field: pickerRefTo.current,
      firstDay: 1,
      format: "YYYY-MM-DD",
      setDefaultDate: false,
      onSelect: onToChange,
      i18n: PICKER_i18n,
    });
    setDpTo(datepicker);
  }, [dpTo]);

  React.useEffect(() => {
    // prevent double inits on hot reloads (dev env bug)
    if (dpFrom) return;
    const datepicker = new Pikaday({
      field: pickerRefFrom.current,
      format: "YYYY-MM-DD",
      firstDay: 1,
      setDefaultDate: false,
      onSelect: onFromChange,
      i18n: PICKER_i18n,
    });
    setDpFrom(datepicker);
  }, [dpFrom]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SC.DateIntervalPicker
        type="text"
        autocomplete="false"
        ref={pickerRefFrom}
        id={`${column.id}-from`}
        name={`${column.id}from`}
        placeholder="Från"
      />
      <div style={{ margin: "0 4px" }}>-</div>
      <SC.DateIntervalPicker
        type="text"
        autocomplete="false"
        ref={pickerRefTo}
        id={`${column.id}-to`}
        name={`${column.id}to`}
        placeholder="Till"
      />
    </div>
  );
}
