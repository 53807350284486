import * as React from "react";
import * as SC from "./styles";

export default () => {
  return (
    <div>
      <div style={{ fontSize: 16, fontWeight: 600 }}>
        Hur fungerar en hyreshöjning?
      </div>
      <SC.Paragraph>
        I Pigello kan du registrera hyreshöjningar på enskilda objekt eller på
        större bestånd. Genom att gå in på en fastighet kan du registrera en
        hyreshöjning på samtliga objekt i fastigheten. Du kan också filtrera
        systemet på en eller flera fastigheter eller bolag för att sedan
        mass-markera de objekt som ska ingå i en hyreshöjning.
      </SC.Paragraph>{" "}
      <SC.Paragraph>
        <strong style={{ marginBottom: 8 }}>
          Såhär gör du för att registrera en hyreshöjning i SEK (under en
          fastighet):
        </strong>
        <SC.List>
          <li>Klicka på Fastigheter i sidomenyn</li>
          <li>
            Klicka på fastigheten som du vill registrera en hyreshöjning på
          </li>

          <li>Klicka på den blå länken uppe till vänster</li>

          <li>Skriv in förändringen i SEK</li>
          <li>Ange ett datum på när hyreshöjningen ska träda i kraft</li>
          <li>
            Du kan även välja att skriva in en och samma hyreshöjning i SEK för
            samtliga objekt i listan genom att klicka på den blå länken Tryck
            här för att ställa in hyreshöjning i SEK för samtliga objekt i
            listan
          </li>
        </SC.List>
      </SC.Paragraph>
      <SC.Paragraph>
        <strong style={{ marginBottom: 8 }}>
          Såhär gör du för att registrera en hyreshöjning i % (under en
          fastighet):
        </strong>
        <SC.List>
          <li>Klicka på Fastigheter i sidomenyn</li>

          <li>
            Klicka på fastigheten som du vill registrera en hyreshöjning på
          </li>

          <li>Klicka på den blå länken uppe till vänster</li>

          <li>Skriv in förändringen i SEK</li>

          <li>Ange ett datum på när hyreshöjningen ska träda i kraft</li>

          <li>
            Du kan även välja att skriva in en och samma hyreshöjning i % för
            samtliga objekt i listan genom att klicka på den blå länken "Tryck
            här för att ställa in hyreshöjning i % för samtliga objekt i listan"
          </li>
        </SC.List>
      </SC.Paragraph>
      <SC.Paragraph>
        OBS! Om ett objekt saknar referenshyra så krävs detta innan du kan
        registrera en hyreshöjning på det specifika objektet. Du kan göra samma
        hyreshöjning genom att gå till Objekt i sidomenyn och sedan klicka på
        den blå knappen Lägg till … hyreshöjning uppe till höger. När en
        hyreshöjning är registrerad kommer du få en notis under fliken Att
        hantera uppe till vänster som beskriver att du kan uppdatera aviseringen
        för objektet.
      </SC.Paragraph>
    </div>
  );
};
