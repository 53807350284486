import * as React from "react";

// style, design
import { ColorPicker, TextInput } from "../Base/Fields";

export default function NestedPremisesStatusChildren({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  return (
    <>
      <TextInput
        title="Namn på status"
        storeName={storeName}
        fieldKey={`${parentPath}.name`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.name`}
      />

      <ColorPicker
        {...{
          storeName,
          fieldKey: `${parentPath}.color_code`,
          method,
          instructionsKey: `${parentInstructionsPath || parentPath}.color_code`,
          title: "Färg",
        }}
      />
    </>
  );
}
