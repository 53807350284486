import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useFilteredYourBlockList, constants } from "../../../store/yourBlock";
import YourBlockIntegrationForm from "../../../components/Forms/YourBlock/ChapterForm/ModalForm";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import StandardModal from "../../../components/Modals/StandardModal";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import {
  clearTodoOverviewData,
  useTodoYourBlockStatistics,
} from "../../../store/overview";
import { LinkedObject } from "../../../components/Displays";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { matchToYourBlock } from "../../../store/yourBlock/store/actions";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

export default function YourBlockIntegration() {
  const dispatch = useDispatch();
  const [integrations] = useFilteredYourBlockList("");
  const [realEstates] = useFilteredRealEstates("");
  const [showErrorsOpen, setShowErrorsOpen] = React.useState(false);
  const [selectOnlyPigelloOpen, setSelectOnlyPigelloOpen] =
    React.useState(false);
  const [onlyPigello, setOnlyPigello] = React.useState(false);
  const [activateYourBlockOpen, setActivateYourBlockOpen] =
    React.useState(false);
  const [editYourBlockOpen, setEditYourBlockOpen] = React.useState(false);
  const current = INTEGRATIONS.find((i) => i.title === "YourBlock");

  const hasYourBlockPermission = useAllPermissionCheck([
    "view_can_yourblock",
    "allow_yourblock",
  ]);

  const [rawYourBlockStatistics, yourblockLoading] =
    useTodoYourBlockStatistics();

  const errorData = rawYourBlockStatistics?.data?.unsynced;

  const errorsByRealEstate = {};

  Object.keys(errorData || {}).forEach((key) => {
    const data = errorData[key];
    if (!data) return;
    data.forEach((d) => {
      if (key === "realestates") {
        if (errorsByRealEstate[d.id]) {
          errorsByRealEstate[d.id].push({ ...d, key });
        } else {
          errorsByRealEstate[d.id] = [{ ...d, key }];
        }
      } else if (d.realestates?.length > 0) {
        d.realestates.forEach((re) => {
          if (errorsByRealEstate[re]) {
            errorsByRealEstate[re].push({ ...d, key });
          } else {
            errorsByRealEstate[re] = [{ ...d, key }];
          }
        });
      }
    });
  });

  const realEstateQ = buildQueryString({
    id__in: Object.keys(errorsByRealEstate || []),
  });

  const [errorRealEstates] = useFilteredRealEstates(realEstateQ);

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  const realEstateColumns = ["Fastighet", "Integration aktiv", "Synk aktiv"];
  const realEstateRows = realEstates?.map((r) => {
    const active = integrations?.find((i) => i.realestate?.id === r.id);
    const syncActive = active?.sync_active;

    return [
      r.str_representation,
      <StatusLabel state={active ? 0 : 3}>
        {active ? "Aktiv" : "Ej aktiverad"}
      </StatusLabel>,
      active ? (
        <StatusLabel state={syncActive ? 0 : 3}>
          {syncActive ? "Aktiv" : "Inaktiv"}
        </StatusLabel>
      ) : (
        "-"
      ),
    ];
  });

  const handleRealEstateClicked = (_, idx) => {
    const realEstate = realEstates?.[idx];
    const existing = integrations?.find(
      (i) => i.realestate?.id === realEstate?.id
    );

    if (existing) {
      setEditYourBlockOpen(existing);
    } else {
      setActivateYourBlockOpen({ realestate: realEstate });
      setSelectOnlyPigelloOpen(true);
    }
  };

  const matchRealEstateToYourBlock = (realestate) => {
    const integration = integrations?.find(
      (i) => i.realestate?.id === realestate?.id
    );

    if (!integration) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel",
        })
      );
      return;
    }

    dispatch(
      matchToYourBlock({
        integrationId: integration.id,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Matchningsförsök gjordes",
              description:
                "Om det fortfarande finns ej matchade objekt, kontrollera datan och försök igen",
            })
          );
          dispatch({
            type: constants.CLEAR_FETCHED,
            payload: {
              clearAll: true,
            },
          });
          dispatch(clearTodoOverviewData());
        },
        errorCallback: (err) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
            })
          );
        },
      })
    );
  };

  const currentErrorApartments = errorsByRealEstate?.[showErrorsOpen]?.filter(
    (e) => e.key === "apartments"
  );
  const currentErrorRealEstates = errorsByRealEstate?.[showErrorsOpen]?.filter(
    (e) => e.key === "realestates"
  );
  const currentErrorTenants = errorsByRealEstate?.[showErrorsOpen]?.filter(
    (e) => e.key === "tenants"
  );

  return (
    <>
      <StandardModal
        title="Ej matchat i fastighet"
        isOpen={showErrorsOpen}
        closeFunction={() => setShowErrorsOpen(false)}
        withActionBar
        actionBarCancelTitle="Stäng"
      >
        {currentErrorRealEstates?.length > 0 && (
          <PremisesTable
            extraStyles={{ marginBottom: 24 }}
            title="Fastigheter"
            headers={["Fastighet", "Meddelande"]}
            rows={currentErrorRealEstates.map((a) => [
              <LinkedObject obj={a} urlMethod={realEstateDetailUrl} />,
              a.reason || "-",
            ])}
          />
        )}

        {currentErrorApartments?.length > 0 && (
          <PremisesTable
            extraStyles={{ marginBottom: 24 }}
            title="Lägenheter"
            headers={["Objekt", "Meddelande"]}
            rows={currentErrorApartments.map((a) => [
              <LinkedObject obj={a} urlMethod={apartmentDetailUrl} />,
              a.reason || "-",
            ])}
          />
        )}

        {currentErrorTenants?.length > 0 && (
          <PremisesTable
            extraStyles={{ marginBottom: 24 }}
            title="Hyresgäster/medlemmar"
            headers={["Boende", "Meddelande"]}
            rows={currentErrorTenants.map((a) => [
              <LinkedObject obj={a} urlMethod={tenantDetailUrl} />,
              a.reason || "-",
            ])}
          />
        )}
      </StandardModal>

      <StandardModal
        small
        title="Typ av integration"
        isOpen={selectOnlyPigelloOpen}
        closeFunction={() => {
          setSelectOnlyPigelloOpen(false);
          setActivateYourBlockOpen(false);
        }}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Välj typ av integration</OverviewTitle>
            <OverviewSubtitle>
              Välj om datan enbart finns i Pigello eller i båda systemen
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div style={{ marginBottom: 12 }}>
          <TextButton
            title="Datan finns enbart i Pigello"
            clicked={() => {
              setOnlyPigello(true);
              setSelectOnlyPigelloOpen(false);
            }}
            iconType="arrow"
            iconPlacement="right"
          />
        </div>

        <TextButton
          title="Datan finns i båda systemen"
          clicked={() => {
            setOnlyPigello(false);
            setSelectOnlyPigelloOpen(false);
          }}
          iconType="arrow"
          iconPlacement="right"
        />
      </StandardModal>

      <YourBlockIntegrationForm
        method="POST"
        isOpen={activateYourBlockOpen && !selectOnlyPigelloOpen}
        instance={activateYourBlockOpen}
        onlyPigello={onlyPigello}
        onCheckout={() => setActivateYourBlockOpen(false)}
      />

      <YourBlockIntegrationForm
        method="PATCH"
        id={editYourBlockOpen?.id}
        onlyPigello
        isOpen={editYourBlockOpen}
        instance={editYourBlockOpen}
        onCheckout={() => setEditYourBlockOpen(false)}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: "YourBlock" },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <div
              style={{
                flex: 1,
                maxWidth: "60%",
                alignSelf: "flex-start",
                flexDirection: "column",
                display: "flex",
                marginRight: 12,
              }}
              
            >
              {errorRealEstates?.length > 0 && hasYourBlockPermission && (
                <DetailPageBox error>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Ej matchat/synkat</OverviewTitle>
                      <OverviewSubtitle>
                        Tryck på "Matcha mot YourBlock" för en fastighet när du
                        vill matcha ihop lägenheter/hyresgäster som finns i
                        YourBlock mot vad som finns i Pigello
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <PremisesTable
                    headers={[
                      "Fastighet",
                      "Ej matchat",
                      "Matcha mot YourBlock",
                    ]}
                    rows={errorRealEstates?.map((er) => [
                      er.key,
                      <TextButton
                        title={`Visa ${
                          errorsByRealEstate[er.id]?.length
                        } ej matchade`}
                        clicked={() => setShowErrorsOpen(er.id)}
                      />,
                      <TextButton
                        title="Matcha mot YourBlock"
                        iconType="sync"
                        iconPlacement="right"
                        clicked={() => matchRealEstateToYourBlock(er)}
                      />,
                    ])}
                  />
                </DetailPageBox>
              )}
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle>Integrationsinformation</OverviewTitle>
                    <OverviewSubtitle>
                      <TextButton
                        title="Besök YourBlock´s hemsida"
                        iconType="launch"
                        iconPlacement="right"
                        clicked={goToWebsite}
                      />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <InnerBox>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Hur fungerar integrationen?
                      </OverviewTitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>
                  <BodyText>
                    YourBlock-integrationen ställs in per fastighet. Objekt och
                    hyresgäster matchas mellan Pigello och YourBlock
                    kontinuerligt.
                    <br />
                    Metoden för integration skiljer sig beroende på om datan
                    redan finns i YourBlock eller inte.
                    <br />
                    <br />
                    <strong>
                      För fastigheter där datan enbart finns i Pigello:
                    </strong>
                    <br />
                    Finns datan enbart i Pigello så synkas automatiskt
                    fastigheten, objekten och hyresgästerna/medlemmarna till
                    YourBlock.
                    <br />
                    <br />
                    <strong>
                      För fastigheter där datan finns i båda systemen:
                    </strong>{" "}
                    <br />
                    Finns datan i båda systemen matchas objekt och
                    hyresgäster/medlemmar mellan de två olika systemen.
                  </BodyText>
                </InnerBox>
              </DetailPageBox>
            </div>

            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Aktivera integration</OverviewTitle>
                  <OverviewSubtitle>
                    Klicka på en fastighet för att påbörja/konfigurera
                    integration
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {hasYourBlockPermission ? (
                <PremisesTable
                  onRowClicked={handleRealEstateClicked}
                  headers={realEstateColumns}
                  rows={realEstateRows}
                />
              ) : (
                <InnerBox>Behörighet till YourBlock saknas.</InnerBox>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
