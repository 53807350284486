import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";

export default function InvoicingErrors() {
  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Aviseringsrelaterade fel</OverviewTitle>
            <OverviewSubtitle>
              Tryck på en rad för att komma till källan för felet
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <InvoicingErrorTable />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
