import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

// store, state
import { constants, constructStrRep } from "../../../../../store/calendar";
import { useOutlookCalendars } from "../../../../../store/outlookCalendar";

import { Checkbox, TextInput, ColorPicker } from "../../../Base/Fields";
import DurationField from "../../../Base/Fields/DurationField";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useDispatch } from "react-redux";
import UnconnectedSelect from "../../../Base/Fields/UnconnectedSelect";
import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { OutlookIcon } from "../../../../Calendar/styles";

export default ({ method }) => {
  const dispatch = useDispatch();

  const storeName = constants.STORE_NAME;

  const outlookCalendarValue = useFormInstanceField({
    storeName,
    fieldKey: "outlook_calendar_id",
  });

  const [outlookCalendars, outlookCalendarsLoading] = useOutlookCalendars();

  const selectedOutlookCalendar = (data) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { outlook_calendar_id: data?.id || undefined },
      })
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Kalender</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för hur kalendern ska fungera.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <TextInput
        storeName={storeName}
        title={"Namn på kalendern"}
        method={method}
        fieldKey={"title"}
      />

      <DurationField
        storeName={storeName}
        title={
          "Skicka notiser för händelser i denna kalender såhär lång tid innan"
        }
        method={method}
        fieldKey={"remind_before"}
      />

      <Checkbox
        storeName={storeName}
        title={"Visa min status som upptagen för händelser i denna kalender"}
        method={method}
        fieldKey={"display_as_occupied"}
      />

      <ColorPicker
        title="Färg på händelser i denna kalender"
        storeName={storeName}
        method={method}
        fieldKey="color_code"
      />

      {method === "POST" && (
        <InnerBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                small
              >
                <OutlookIcon blue />
                Outlook-kalender
              </OverviewTitle>
              <OverviewSubtitle>
                En kalender som är kopplad till en Outlook-kalender hämtar
                händelser ifrån den kopplade kalendern. Inga händelser kan
                skapas i denna kalender ifrån Pigello. Enbart en
                Outlook-kalender kan kopplas till varje kalender i Pigello.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <UnconnectedSelect
            title={"Koppla till Outlook-kalender"}
            fetchMethod={() => [outlookCalendars, outlookCalendarsLoading]}
            changeCallback={(data) => selectedOutlookCalendar(data)}
            formatDisplayValue={(data) => constructStrRep(data)}
            value={outlookCalendarValue}
            clearable={true}
          />
        </InnerBox>
      )}
    </>
  );
};
