import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import LeadsTable from "../../Tables/Leads/FullTable";
import { buildQueryString } from "../../../store/base";

export default function ConnectedLeads() {
  const { pipeId } = useParams();

  const persistantQuery = {
    pipe: pipeId,
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Leads i projekt</OverviewTitle>
          <OverviewSubtitle>
            Dessa leads har visat intresse på någon av annonserna i projektet
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <LeadsTable {...{ persistantQuery }} />
    </>
  );
}
