import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  useIndustrialPremisesForm,
  destroyPatchForm,
  update,
} from "../../../../store/industrialPremises";
import renderChapter from "./renderChapter";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";

export default function IndustrialPremisesStatusModalForm({
  method = "PATCH",
  id,
  isOpen,
  onCheckout,
  instance,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useIndustrialPremisesForm(method, id));

  React.useEffect(() => {
    if (formLoaded && instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, [formLoaded, isOpen]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        preProcess,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={"Uppdatera statusar på objekt"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
