export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_OTHERCONTRACT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_OTHERCONTRACT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_OTHERCONTRACT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_OTHERCONTRACT",
  DESTROY_FORM: "DESTROY_FORM_OTHERCONTRACT",
  SET_FORM_ERROR: "SET_FORM_ERROR_OTHERCONTRACT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_OTHERCONTRACT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_OTHERCONTRACT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_OTHERCONTRACT",
  RESET_STATE: "RESET_STATE_OTHERCONTRACT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_OTHERCONTRACT",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_OTHERCONTRACT",
  CLEAR_FETCHED: "CLEAR_FETCHED_OTHERCONTRACT",

  LIST_URL: "/standard/mainlevel/contracts/othercontract/list/",
  POST_URL: "/standard/mainlevel/contracts/othercontract/",
  PATCH_URL: "/standard/mainlevel/contracts/othercontract/",
  GET_URL: "/standard/mainlevel/contracts/othercontract/",
  GENERATE_INDEX_URL:
    "/pdfgenerator/contract/indexation/index_summary/othercontract/",

  STORE_NAME: "otherContracts",
};
