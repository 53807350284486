const defs = [
  {
    title: "Fordonsplatser",
    key: "PARKING_SPOTS",
    visited: false,
    hasError: false,
    fieldKeys: ["parking_spots"],
  },
  {
    title: "Parter",
    key: "PARTIES",
    visited: true,
    hasError: false,
    fieldKeys: ["tenant", "landlord"],
  },

  {
    title: "Avtal",
    key: "CONTRACT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "main_contract",
      "id_number",
      "category",
      "status",
      "start_date",
      "end_date",
      "renewed_to",
      "renew_interval",
      "warning_interval",
      "notify_interval",
      "content_type",
      "object_id",
    ],
  },

  {
    title: "Signeringsstatus",
    key: "SIGN_STATUS",
    visited: false,
    hasError: false,
    fieldKeys: ["is_signed", "signed_date"],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default ({ contractType }) => {
  const exclude = [];

  // only include signing if existing contract, new contracts uses SCRIVE_SETTINGS
  if (contractType === "new") {
    exclude.push("SIGN_STATUS");
  }

  return defs.filter((d) => !exclude.includes(d.key));
};
