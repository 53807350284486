import * as React from "react";
import { useDispatch } from "react-redux";
import {
  useBrfPlannedRaisesForm,
  constants,
  create,
  update,
} from "../../../store/brfPlannedRaises";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { DatePicker, NumberInput } from "../Base/Fields";

export default ({ method, id, onCheckout, isOpen }) => {
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  useBrfPlannedRaisesForm(method, id);

  const onSave = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: () => {
            setLoading(false);
            onCheckout();
          },
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    } else {
      dispatch(
        update({
          id,
          successCallback: () => {
            setLoading(false);
            onCheckout();
          },
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    }
  };

  return (
    <StandardModal
      isOpen={isOpen}
      title={
        method === "PATCH" ? "Uppdatera" : "Lägg till planerad avgiftshöjning"
      }
      closeFunction={onCheckout}
      saveFunction={onSave}
      withActionBar
      small
    >
      {loading && <OverlaySpinner />}
      <DatePicker
        title="Planerat datum för höjning"
        fieldKey="date"
        {...{ storeName, method }}
      />

      <NumberInput
        title="Ökning av föreningsavgift, per månad i SEK"
        fieldKey="increase"
        {...{ storeName, method }}
      />
    </StandardModal>
  );
};
