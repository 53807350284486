import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import BasicTable from "../../../components/Billecta/Table/BasicTable";
import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  buildQueryString,
  clearFetched,
  useAllPermissionCheck,
} from "../../../store/base";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

import KeyPermissionTable from "../../../components/Tables/KeyPermissions/FullTable";
import KeyDeviceTable from "../../../components/Tables/KeyDevices/FullTable";

import {
  createDomain,
  constants,
  updateDomain,
  useFilteredParakeyDomains,
} from "../../../store/parakey";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import {
  bulkUploadKeys,
  collectAccess,
  collectDoors,
  collectKeys,
} from "../../../store/parakey/utils";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import Toggle from "../../../components/Toggle/Toggle";

const TABS = {
  ACCESS: "Behörigheter",
  DOORS: "Dörrar",
};

export default function ParakeyIntegration() {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = React.useState("ACCESS");
  const [showOnlySynced, setShowOnlySynced] = React.useState(false);

  const [collectAccessOpen, setCollectAccessOpen] = React.useState(false);
  const [collectKeyOpen, setCollectKeyOpen] = React.useState(false);
  const [collectDoorOpen, setCollectDoorOpen] = React.useState(false);
  const [bulkCreateOpen, setBulkCreateOpen] = React.useState(false);

  const [addGatewayOpen, setAddGatewayOpen] = React.useState(false);
  const [deletionOpen, setDeletionOpen] = React.useState(false);
  const [handleOpen, setHandleOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);

  const [gateWays, gatewaysLoading] = useFilteredParakeyDomains("");

  const deleteGateway = gateWays?.find((g) => g.id == deletionOpen);

  const [loading, setLoading] = React.useState(false);
  const [domainId, setDomainId] = React.useState("");

  const viewCan = useAllPermissionCheck([
    "allow_parakey",
    "view_can_parakey",
    "allow_keys",
    "view_can_keys",
  ]);
  const canAddImd = useAllPermissionCheck([
    "allow_parakey",
    "add_can_parakey",
    "allow_keys",
    "add_can_keys",
  ]);
  const canChangeImd = useAllPermissionCheck([
    "allow_parakey",
    "change_can_parakey",
    "allow_keys",
    "change_can_keys",
  ]);
  const canDeleteImd = useAllPermissionCheck([
    "allow_parakey",
    "delete_can_parakey",
    "allow_keys",
    "delete_can_keys",
  ]);

  const persistantQuery = {
    parakey_id__isnull: !showOnlySynced,
  };

  const performAccessCollect = async () => {
    try {
      await collectAccess();
      dispatch(
        addToast({
          title: "Hämtning/Matchning lyckades",
          type: TOAST_TYPES.SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: "Kunde ej hämta/matcha nyckel-behörigheter",
          type: TOAST_TYPES.ERROR,
        })
      );
    }
  };
  const performKeyCollect = async () => {
    try {
      await collectKeys();
      dispatch(
        addToast({
          title: "Hämtning/Matchning lyckades",
          type: TOAST_TYPES.SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: "Kunde ej hämta/matcha nycklar",
          type: TOAST_TYPES.ERROR,
        })
      );
    }
  };
  const performDoorCollect = async () => {
    try {
      await collectDoors();
      dispatch(
        addToast({
          title: "Hämtning/Matchning lyckades",
          type: TOAST_TYPES.SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: "Kunde ej hämta/matcha dörrar",
          type: TOAST_TYPES.ERROR,
        })
      );
    }
  };
  const performBulkCreate = async () => {
    try {
      await bulkUploadKeys();
      dispatch(
        addToast({
          title: "Bulk uppladdning lyckades",
          type: TOAST_TYPES.SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: "Bulk uppladdning misslyckades",
          type: TOAST_TYPES.ERROR,
        })
      );
    }
  };

  const clearInput = () => {
    setDomainId("");
  };

  const performModalSave = () => {
    setLoading(true);
    const postData = {
      domain_id: domainId,
    };
    dispatch(
      createDomain({
        postData,
        successCallback: () => {
          setAddGatewayOpen(false);
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Domän lades till",
              description:
                "Behörigheter, nycklar och dörrar kan nu hämtas och matchas automatiskt även för denna domän",
            })
          );

          dispatch(clearFetched(constants, true));
          clearInput();
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Domän kunde ej läggas till",
              description: "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };
  const performModalEditSave = () => {
    setLoading(true);
    const patchData = {
      id: editOpen,
      domain_id: domainId,
    };
    dispatch(
      updateDomain({
        patchData,
        successCallback: () => {
          setEditOpen(false);
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Domän uppdaterades",
              description:
                "Behörigheter nycklar, och dörrar kan nu hämtas och matchas automatiskt även för denna domän",
            })
          );

          dispatch(clearFetched(constants, true));
          clearInput();
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Domän kunde ej uppdateras",
              description: "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const performModalClose = () => {
    setAddGatewayOpen(false);
    setEditOpen(false);

    clearInput();
  };

  const current = INTEGRATIONS.find((i) => i.title === "Parakey");

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  const data = React.useMemo(() => {
    return gateWays;
  }, [gateWays]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Domän ID",
        accessor: "domain_id",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },
    ],
    []
  );

  return (
    <>
      <ConfirmationModal
        isOpen={collectAccessOpen}
        title={
          "Vill du att systemet automatiskt ska hämta och matcha nyckel-behörigheter från Parakey?"
        }
        renderContent={() =>
          "Systemet kommer försöka matcha behörigheter baserat på deras namn, och skapa dem som inte finns"
        }
        closeFunction={() => setCollectAccessOpen(false)}
        acceptCallback={performAccessCollect}
      />
      <ConfirmationModal
        isOpen={collectKeyOpen}
        title={
          "Vill du att systemet automatiskt ska hämta och matcha nycklar från Parakey?"
        }
        renderContent={() =>
          "Systemet kommer försöka matcha nycklar baserat på behörigheter och nyckel-användare"
        }
        closeFunction={() => setCollectKeyOpen(false)}
        acceptCallback={performKeyCollect}
      />
      <ConfirmationModal
        isOpen={collectDoorOpen}
        title={
          "Vill du att systemet automatiskt ska hämta och matcha dörrar från Parakey?"
        }
        renderContent={() =>
          "Systemet komma försöka matcha dörrar baserat på deras namn och systemets objektsnummer för lägenheter, lokaler och gemensamma ytor"
        }
        closeFunction={() => setCollectDoorOpen(false)}
        acceptCallback={performDoorCollect}
      />
      <ConfirmationModal
        isOpen={bulkCreateOpen}
        title={
          "Vill du att systemet ska ladda upp alla nycklar som ej är synkade till Parakey?"
        }
        renderContent={() =>
          "Systemet kommer ladda upp alla nycklar vars behörigheter är synkade till Parakey och vars användare har en email-adress"
        }
        closeFunction={() => setBulkCreateOpen(false)}
        acceptCallback={performBulkCreate}
      />
      <DeleteModal
        isOpen={!!deletionOpen}
        instance={deleteGateway}
        constants={constants}
        closeFunction={() => setDeletionOpen(false)}
      />

      <StandardModal
        isOpen={addGatewayOpen}
        saveFunction={performModalSave}
        closeFunction={performModalClose}
        title={"Lägg till domän"}
        canAccept={domainId != null && domainId != ""}
        withActionBar
      >
        {loading && <OverlaySpinner />}
        <NonConnectedTextInput
          id="domain_id"
          value={domainId}
          onChange={setDomainId}
          label={"ID på domän"}
          helpText="Ni hittar detta värde i Parakeys portal"
        />
      </StandardModal>

      <StandardModal
        isOpen={editOpen}
        saveFunction={performModalEditSave}
        closeFunction={performModalClose}
        title={"Redigera domän"}
        canAccept={domainId != null && domainId != ""}
        withActionBar
      >
        {loading && <OverlaySpinner />}
        <NonConnectedTextInput
          id="domain_id"
          value={domainId}
          onChange={setDomainId}
          label={"ID på domän"}
          helpText="Ni hittar detta värde i Parakeys portal"
        />
      </StandardModal>

      <StandardModal
        isOpen={handleOpen}
        closeFunction={() => setHandleOpen(false)}
        title={"Hantera domän"}
        small
      >
        <OverviewTitleWrapper>
          <OverviewTitle>Hantera domän</OverviewTitle>
        </OverviewTitleWrapper>
        {canChangeImd && (
          <div style={{ marginTop: 12 }}>
            <TextButton
              title="Redigera domän"
              clicked={() => {
                const handleId = handleOpen;
                const editObj = gateWays?.find((g) => g.id == handleId);
                setDomainId(editObj.domain_id);
                setEditOpen(handleId);
                setHandleOpen(false);
              }}
              iconType="edit"
              iconPlacement="right"
            />
          </div>
        )}

        {canDeleteImd && (
          <TextButton
            extraStyle={{ marginTop: 12 }}
            red
            title="Radera domän"
            clicked={() => {
              const deleteId = handleOpen;
              setDeletionOpen(deleteId);
              setHandleOpen(false);
            }}
            iconType="close"
            iconPlacement="right"
          />
        )}
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Parakey hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Parakey-integrationen ställs in per domän ni har. När en domän
                  kopplas på kan ni välja att låta systemet hämta in
                  nyckel-behörigheter, nycklar och dörrar.
                  <br />
                  <br />
                  Det går sedan att manuellt matcha behörigheter, nycklar och
                  dörrar i Pigello mot Parakey i formulären.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hantera integration</OverviewTitle>
                  {viewCan && (
                    <OverviewSubtitle>
                      Lägg till en domän genom att trycka på "Lägg till domän".
                      Tillagda domäner hittas längre ner på sidan.
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {canAddImd ? (
                <>
                  <PrimaryButton
                    title="Lägg till domän"
                    clicked={() => setAddGatewayOpen(true)}
                  />

                  {!!data?.length && (
                    <>
                      <TextButton
                        title={"Hämta och Matcha Nyckelbehörigheter"}
                        clicked={() => setCollectAccessOpen(true)}
                        extraStyle={{ marginTop: "8px" }}
                        iconType={"sync"}
                      />

                      <TextButton
                        title={"Hämta och Matcha Dörrar"}
                        clicked={() => setCollectDoorOpen(true)}
                        extraStyle={{ marginTop: "8px" }}
                        iconType={"sync"}
                      />

                      <TextButton
                        title={"Ladda upp/Synca nycklar"}
                        clicked={() => setBulkCreateOpen(true)}
                        extraStyle={{ marginTop: "16px" }}
                        iconType={"sync"}
                      />
                    </>
                  )}
                </>
              ) : (
                <InnerBox>
                  Behörighet saknas för att hantera denna integration
                </InnerBox>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>

          {viewCan && (
            <>
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle>Domäner</OverviewTitle>
                    <OverviewSubtitle>
                      Klicka på en domän för att redigera/radera den
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <BasicTable
                  data={data ?? []}
                  columns={columns}
                  onRowClicked={(row) => {
                    if (canDeleteImd || canChangeImd) {
                      setHandleOpen(row.original.id);
                    } else {
                      dispatch(
                        addToast({
                          type: TOAST_TYPES.INFO,
                          title: "Behörighet saknas",
                        })
                      );
                    }
                  }}
                  tableId="parakeytable"
                />
              </DetailPageBox>

              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle>Synkstatus på objekt</OverviewTitle>
                    <OverviewSubtitle>
                      Överblick över status på synk mellan objekt i Parakey och
                      Pigello.
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>

                  <PageSlider
                    {...{
                      selectedTab,
                      onTabSelected: (tab) => setSelectedTab(tab),
                      TABS,
                    }}
                  />
                </OverviewTitleWrapper>

                <InnerBox>
                  <Toggle
                    extraTitleStyle={{
                      fontSize: 14,
                      marginBottom: 12,
                      fontWeight: 500,
                      marginRight: 6,
                    }}
                    title={
                      !showOnlySynced
                        ? "Visar endast ej synkade objekt"
                        : "Visar endast synkade objekt"
                    }
                    value={showOnlySynced}
                    onToggle={() => setShowOnlySynced(!showOnlySynced)}
                  />
                </InnerBox>
                {selectedTab === "ACCESS" && (
                  <KeyPermissionTable persistantQuery={persistantQuery} />
                )}
                {selectedTab === "DOORS" && (
                  <KeyDeviceTable persistantQuery={persistantQuery} />
                )}
              </DetailPageBox>
            </>
          )}
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
