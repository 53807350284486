import * as React from "react";

// store, state
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { fetchMethodFromKind } from "../../../../store/utils";

import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import BuildingTable from "src/components/Tables/Buildings/FullTable";

import TableSelectField from "../Fields/TableSelectField";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import BasicDocTable from "src/components/Tables/BasicDocs/FullTable";
import { useDispatch } from "react-redux";
import SelectField from "../Fields/SelectField";
import UsersTable from "src/components/Tables/Users/FullTable";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import LeaseContractsTable from "src/components/Tables/LeaseContracts/FullTableNewVersion";

export default React.memo(
  ({
    storeName,
    method,
    ctFieldKey,
    idFieldKey,
    realEstateIds,
    selectDescription,
  }) => {
    const dispatch = useDispatch();

    const selectedCt = useFormInstanceField({
      storeName,
      fieldKey: ctFieldKey,
    });
    const retrievedFetchMethod = fetchMethodFromKind(selectedCt, true);

    const querystring = buildQueryString({ realestate_ids: realEstateIds });
    const fetchMethod = retrievedFetchMethod
      ? () => retrievedFetchMethod(querystring)
      : undefined;

    React.useEffect(() => {
      //Clear choice when content_type switched
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [idFieldKey]: undefined,
          },
        })
      );
    }, [selectedCt]);

    const getTableComponentFromCt = (ct) => {
      switch (ct) {
        case "standard.realestate":
          return RealEstateTable;

        case "standard.building":
          return BuildingTable;

        case "standard.industrialpremises":
          return IndustrialPremisesTable;

        case "standard.apartment":
          return ApartmentTable;

        case "standard.basicdoc":
          return BasicDocTable;

        case "accounts.user":
          return UsersTable;

        case "accounts.tenant":
          return TenantTable;

        case "standard.leasecontract":
          return LeaseContractsTable;

        default:
          return RealEstateTable;
      }
    };

    let tableComponent = getTableComponentFromCt(selectedCt);

    return (
      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Koppla till objekt av typ"
          storeName={storeName}
          fieldKey={ctFieldKey}
          method={method}
          description={selectDescription}
          tempUseChoiceFilter={true}
          filterChoices={(choice, ...rest) => {
            if (choice.v === "standard.blueprint") return false;
            if (choice.v === "management.customer") return false;
            return true;
          }}
        />

        {!!fetchMethod ? (
          <TableSelectField
            storeName={storeName}
            TableComponent={tableComponent}
            title="Koppla till objekt"
            placeholder="Koppla till objekt"
            fieldKey={idFieldKey}
            method={method}
            setOnlyId
          />
        ) : null}
      </div>
    );
  }
);
