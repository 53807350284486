import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import {
  updateActiveFormInstance,
  useFormField,
  useFormInstanceField,
} from "../../../store/base";

import { Checkbox, TimePicker } from "../../Forms/Base/Fields";
import { uid } from "uid";
import { cloneDeep } from "lodash";

import {
  CreateNewButton,
  PrimaryButton,
  TextButton,
} from "../../Forms/Base/Buttons";
import * as SC from "../../Forms/ReportErrandSetting/ClassificationList/styles";
import {
  InputFieldDescription,
  InputFieldTitle,
} from "../../Forms/Base/Fields/styles";

export default ({ method, storeName, noPropose }) => {
  const dispatch = useDispatch();

  const fieldKey = "proposed_starts";
  const plannedKey = "planned_start";

  const proposedValues = useFormInstanceField({ storeName, fieldKey }) || [];

  const plannedValue =
    useFormInstanceField({ storeName, fieldKey: plannedKey }) || [];

  const plannedStartInstructions = useFormField({
    storeName,
    method,
    fieldKey: plannedKey,
  });
  const proposedStartsInstructions = useFormField({
    storeName,
    method,
    fieldKey: fieldKey,
  });

  const canPropose =
    proposedStartsInstructions && !proposedStartsInstructions?._readOnly;
  const canPlan =
    plannedStartInstructions && !plannedStartInstructions?._readOnly;

  const tenantSetsTime = useFormInstanceField({
    storeName,
    fieldKey: "tenant_sets_time",
  });
  const isTenant = useSelector((state) => state.app?.user?.user_type === 3);
  const disableSelectTime =
    (tenantSetsTime && !isTenant) || (isTenant && !tenantSetsTime);

  const onCreateClicked = () => {
    // create a new empty spot
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: [
            ...proposedValues,
            { _reduxId: uid(8), time: moment().set({ minute: 0 }).format() },
          ],
        },
      })
    );
  };

  const onSelectTime = (time) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [plannedKey]: time },
      })
    );
  };

  const onRemoveClicked = (index) => {
    const copy = cloneDeep(proposedValues);
    copy.splice(index, 1);
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [fieldKey]: copy },
      })
    );
  };

  return (
    <>
      {proposedValues && !proposedValues.length ? (
        <>
          <TimePicker
            noMargin
            storeName={storeName}
            fieldKey={plannedKey}
            method={method}
            disabled={disableSelectTime || !canPlan}
            title={"Planerat startdatum"}
          />
          {canPropose && !noPropose && (
            <CreateNewButton
              title="Eller tryck här för att föreslå tider"
              clicked={() => onCreateClicked()}
            />
          )}
        </>
      ) : (
        <div style={{ maxWidth: "600px", width: "100%" }}>
          <InputFieldTitle>Föreslagna tider</InputFieldTitle>

          <InputFieldDescription>
            Lägg till tider som utföraren eller hyresgästen sedan får välja
            mellan
          </InputFieldDescription>

          <SC.ListWrapper style={{ marginBottom: "36px" }}>
            <SC.ListHeader>
              <SC.ComponentName>Föreslagen tid</SC.ComponentName>

              <SC.ComponentArea>Hantera</SC.ComponentArea>

              <SC.HeaderSpacer />
            </SC.ListHeader>

            <SC.ListContent>
              {proposedValues.map((item, index) => (
                <SC.ListItem key={item.id || item._reduxId}>
                  <SC.ComponentName>
                    <TimePicker
                      noMargin
                      noTitle
                      key={item?.id || item._reduxId}
                      storeName={storeName}
                      fieldKey={`${fieldKey}[${index}].time`}
                      instructionsKey={`${fieldKey}.time`}
                      method={method}
                      disabled={!canPropose}
                    />
                  </SC.ComponentName>

                  <SC.ComponentArea>
                    {!disableSelectTime && canPlan && (
                      <TextButton
                        clicked={
                          plannedValue && plannedValue === item.time
                            ? () => onSelectTime(null)
                            : () => onSelectTime(item.time)
                        }
                        title={
                          plannedValue && plannedValue === item.time
                            ? "Vald"
                            : "Välj"
                        }
                      />
                    )}
                  </SC.ComponentArea>

                  <SC.RemoveButton onClick={() => onRemoveClicked(index)} />
                </SC.ListItem>
              ))}
            </SC.ListContent>

            <SC.ListBottom>
              <PrimaryButton
                newDesign
                title="Lägg till föreslagen tid"
                clicked={() => onCreateClicked()}
              />
            </SC.ListBottom>
          </SC.ListWrapper>

          <Checkbox
            title="Hyresgästen väljer tid"
            description={`Detta innebär att hyresgästen via "Mina sidor" får välja bland de föreslagna tiderna`}
            storeName={storeName}
            fieldKey={"tenant_sets_time"}
            method={method}
          />
        </div>
      )}
    </>
  );
};
