import * as React from "react";

import { Confirm, Performers, Type } from "./Chapters";

export default ({ key, method, id }) => {
  switch (key) {
    case "TYPE":
      return <Type {...{ method, id }} />;
    case "PERFORMERS":
      return <Performers {...{ method }} />;
    default:
      return <Confirm method={method} />;
  }
};
