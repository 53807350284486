import styled, { keyframes } from "styled-components";
import closeCircleIcon from "../../../../../assets/svg/close-circle.svg";

export const RemoveButton = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeCircleIcon});
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.textPrimary};
  padding-top: 60px;
`;

export const LoaderTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  font-size: ${(p) => p.theme.fontSizes.headerMedium};
  margin-bottom: 32px;
`;

export const LoaderBar = styled.div`
  margin: 32px 0;
  min-width: 300px;
  max-width: 500px;
  width: 80%;
  height: 12px;
  background-color: ${(p) => p.theme.colors.gray3};
  border-radius: 30px;
  position: relative;
  overflow: hidden;

  box-shadow: ${(p) => p.theme.boxShadows.default};
  border: ${(p) => p.theme.borders.standard};
`;

export const slideAnimation = keyframes`
   0% {
     width: 0%;
     z-index: 5;
    }

    100% {
        width:200%;
        z-index: 1;
    }
   
`;

export const LoaderProgressBar1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  animation: ${slideAnimation} 2s linear infinite;
`;

export const LoaderProgressBar2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: ${(p) => p.theme.colors.primaryBlueFaded};
  animation: ${slideAnimation} 2s linear infinite;
  animation-delay: 1s;
`;
