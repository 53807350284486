import * as React from "react";
import { useParams, useRouteMatch, useHistory } from "react-router";
import {
  BooleanLabel,
  LinkedObject,
  TimeCell,
  ToolTipCell,
} from "../../../components/Displays";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PopupButton,
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";

import ApartmentsTable from "../../../components/Tables/Apartments/FullTableNewVersion";
import IndpTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";

import {
  useKey,
  updateUrl,
  calculateStatus,
  STATUS_MAPPING,
  update,
  destroyPatchForm,
  constants,
} from "../../../store/key";
import { detailUrl as keyPermissionDetailUrl } from "../../../store/keypermission";

import { DetailLayoutWrapper } from "../../../components/sharedStyles";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { useKeyPermission } from "../../../store/keypermission";
import {
  buildQueryString,
  setActiveFormInstance,
  useAllPermissionCheck,
} from "../../../store/base";
import { StatusLabel } from "src/components/Tables/Keys/styles";
import StandardModal from "../../../components/Modals/StandardModal";
import { useDispatch } from "react-redux";
import FullPageSpinner from "../../../components/Loaders/FullPageSpinner";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import ParakeyPermissionDetail from "./ParakeyPermission";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

export default function KeyDetail() {
  const { id } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const canUpdate = useAllPermissionCheck(["change_can_keys"]);
  const canParakey = useAllPermissionCheck([
    "allow_parakey",
    "view_can_parakey",
  ]);
  const canDelete = useAllPermissionCheck(["delete_can_keys"]);

  const [key, loading] = useKey(id);
  const [permission, permissionLoading] = useKeyPermission(key?.permission?.id);

  const [askKeyHandout, setAskKeyHandout] = React.useState(false);
  const [askKeyLost, setAskKeyLost] = React.useState(false);
  const [keyHandoutLoading, setKeyHandoutLoading] = React.useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const apartmentQueryString = buildQueryString({
    id__in: permission?.apartments?.map((a) => a.id),
  });
  const indpQueryString = buildQueryString({
    id__in: permission?.industrial_premises?.map((a) => a.id),
  });
  const commonQueryString = buildQueryString({
    id__in: permission?.common_areas?.map((a) => a.id),
  });

  const apartmentFilterMethod = (ap) =>
    permission?.apartments != null &&
    permission.apartments.some((c) => c.id === ap.id);
  const industrialFilterMethod = (indp) =>
    permission?.industrial_premises != null &&
    permission.industrial_premises.some((c) => c.id === indp.id);
  const commonFilterMethod = (com) =>
    permission?.common_areas != null &&
    permission.common_areas.some((c) => c.id === com.id);

  const keyStatus = calculateStatus(key);

  let infoObj = {
    Detaljer: [
      {
        title: "Status",
        value: (
          <StatusLabel status={keyStatus}>
            {STATUS_MAPPING[keyStatus]}
          </StatusLabel>
        ),
      },
      {
        title: "Motpart",
        value: key?.user ? (
          <ToolTipCell text={key?.user?.str_representation} />
        ) : (
          "-"
        ),
      },
      {
        title: "Innehavande status",
        value:
          key == null ? (
            "-"
          ) : (
            <StatusLabel status={key.possession == 2 ? 6 : 5}>
              {key.possession_display}
            </StatusLabel>
          ),
      },
      {
        title: "Namn",
        value: key?.key_name ? <ToolTipCell text={key?.key_name} /> : "-",
      },
      {
        title: "Serienummer",
        value: key?.serial_number ? (
          <ToolTipCell text={key?.serial_number} />
        ) : (
          "-"
        ),
      },
      {
        title: "Elektronisk nyckel",
        value:
          key?.electronic_key != null ? (
            <BooleanLabel
              value={key.electronic_key}
              onLabel="Ja"
              offLabel="Nej"
            />
          ) : (
            "-"
          ),
      },
      {
        title: "Pinkod",
        value: key?.pin_code ?? "-",
      },
      {
        title: "Gäller f.o.m",
        value: key?.start_time ? <TimeCell date={key.start_time} /> : "-",
      },
      {
        title: "Gäller t.o.m",
        value: key?.end_time ? <TimeCell date={key.end_time} /> : "-",
      },
      {
        title: "Blockerad auto-tid",
        value:
          key?.block_automatic_time_conforming != null ? (
            <BooleanLabel
              value={key.block_automatic_time_conforming}
              onLabel="Ja"
              offLabel="Nej"
            />
          ) : (
            "-"
          ),
      },
      {
        title: "Autoskapad",
        value:
          key?.auto_created != null ? (
            <BooleanLabel
              value={key.auto_created}
              onLabel="Ja"
              offLabel="Nej"
            />
          ) : (
            "-"
          ),
      },
      {
        title: "Nyckelbehörighet",
        value: key?.permission ? (
          <LinkedObject
            obj={key.permission}
            urlMethod={keyPermissionDetailUrl}
          />
        ) : (
          "-"
        ),
      },
    ],
  };

  const performKeyHandoutToggle = () => {
    setKeyHandoutLoading(true);

    dispatch(
      update({
        id: key.id,
        successCallback: () => {
          setAskKeyHandout(false);
          setKeyHandoutLoading(false);
        },
        errorCallback: () => {
          setKeyHandoutLoading(false);
        },
        forceData: { possession: key.possession == 0 ? 1 : 0, id: key.id },
      })
    );
  };

  const performKeyLostToggle = () => {
    setKeyHandoutLoading(true);

    dispatch(
      update({
        id: key.id,
        successCallback: () => {
          setAskKeyLost(false);
          setKeyHandoutLoading(false);
        },
        errorCallback: () => {
          setKeyHandoutLoading(false);
        },
        forceData: { possession: key.possession == 2 ? 1 : 2, id: key.id },
      })
    );
  };

  return (
    <>
      {keyHandoutLoading && <FullPageSpinner />}
      <ConfirmationModal
        isOpen={askKeyHandout}
        title={key?.possession === 0 ? "Ta tillbaka nyckel" : "Lämna ut nyckel"}
        closeFunction={() => setAskKeyHandout(false)}
        acceptCallback={performKeyHandoutToggle}
        renderContent={() =>
          `Är du säker på att du vill ${
            key?.possession === 0 ? "Ta tillbaka nyckeln" : "Lämna ut nyckeln"
          }?`
        }
      />
      <ConfirmationModal
        isOpen={askKeyLost}
        title={
          key?.possession === 2
            ? "Nyckeln är återfunnen"
            : "Nyckeln har tappats bort"
        }
        closeFunction={() => setAskKeyLost(false)}
        acceptCallback={performKeyLostToggle}
        renderContent={() =>
          `Är du säker på att du vill ${
            key?.possession === 2
              ? "Markera nyckeln som återfunnen"
              : "Markera nyckeln som borttappad"
          }?`
        }
      />
      {canDelete && (
        <DeleteModal
          isOpen={deleteModalOpen}
          instance={key}
          closeFunction={() => setDeleteModalOpen(false)}
          constants={constants}
          deletedCallback={() => push("/keys/keys")}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Nyckel${
            key?.str_representation ? ` - ${key.str_representation}` : ""
          }`}
          breadCrumbs={[
            {
              label: "Nycklar",
              url: "/keys/keys",
            },
            {
              label: "Detaljsida",
            },
          ]}
          eventContentType="keyhandling.key"
          eventObjectId={id}
          eventIdQueryStr="key"
        />

        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox style={{ minWidth: "39%", alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Översikt</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
                <PopupButton
                  title="Hantera"
                  disabled={!key || !canUpdate}
                  actions={[
                    {
                      name: "Uppdatera",
                      onClick: () => push(updateUrl({ id: key.id })),
                    },
                    {
                      name:
                        key?.possession === 0
                          ? "Ta tillbaka nyckel"
                          : "Lämna ut nyckel",
                      onClick: () => setAskKeyHandout(true),
                      hidden:
                        !key || key.electronic_key || key.possession === 3,
                    },
                    {
                      name:
                        key?.possession === 2
                          ? "Nyckeln är återfunnen"
                          : "Nyckeln har tappats bort",
                      onClick: () => setAskKeyLost(true),
                      hidden: !key || key.electronic_key,
                    },
                    {
                      name: "Radera",
                      onClick: () => setDeleteModalOpen(true),
                      isDelete: true,
                      hidden: key == null,
                    },
                  ]}
                />
              </OverviewTitleWrapper>
              <DetailInfo infoObj={infoObj} />
            </DetailPageBox>

            <DetailPageBox style={{ minWidth: "60%", alignSelf: "flex-start" }}>
              {permission == null ||
              permission.parakey_id == null ||
              !canParakey ? (
                <>
                  <InnerBox style={{ marginBottom: 12 }}>
                    <OverviewTitleWrapper>
                      <OverviewTitle small>Gemensamma ytor</OverviewTitle>
                    </OverviewTitleWrapper>
                    <CommonAreaTable
                      persistantQueryString={commonQueryString}
                      persistantFilterMethod={commonFilterMethod}
                      //   toggleFormCallback={canAddLease ? initiateLeaseCreation : undefined}
                      hideSearch
                      hideExport
                      hideFilters
                      hideTitle
                    />
                  </InnerBox>

                  <InnerBox style={{ marginBottom: 12 }}>
                    <OverviewTitleWrapper style={{ marginTop: "16px" }}>
                      <OverviewTitle small>Lägenheter</OverviewTitle>
                    </OverviewTitleWrapper>
                    <ApartmentsTable
                      persistantQueryString={apartmentQueryString}
                      persistantFilterMethod={apartmentFilterMethod}
                      //   toggleFormCallback={canAddLease ? initiateLeaseCreation : undefined}
                      hideSearch
                      hideExport
                      hideFilters
                      hideTitle
                    />
                  </InnerBox>

                  <InnerBox>
                    <OverviewTitleWrapper style={{ marginTop: "16px" }}>
                      <OverviewTitle small>Lokaler</OverviewTitle>
                    </OverviewTitleWrapper>
                    <IndpTable
                      persistantQueryString={indpQueryString}
                      persistantFilterMethod={industrialFilterMethod}
                      //   toggleFormCallback={canAddLease ? initiateLeaseCreation : undefined}
                      hideSearch
                      hideExport
                      hideFilters
                      hideTitle
                    />
                  </InnerBox>
                </>
              ) : (
                <ParakeyPermissionDetail permissionId={permission.id} />
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
