import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import AvyTmplRealEstateTable from "../../../components/Tables/RealEstate/AvyTmplFullTable";
import AvyTmplRealEstateForm from "../../../components/Forms/RealEstate/AvyTmplForm/ModalForm";
import SyncErrorTable, {
  SYNC_ERROR_MAP,
} from "../../../components/Tables/ExternalSyncErrors/FullTable";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import StandardModal from "../../../components/Modals/StandardModal";
import { useDispatch } from "react-redux";
import { generateAvyIdForRealEstate } from "../../../store/avyTmpl/actions";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { cloneDeep } from "lodash";

export default function AvyTmplIntegration() {
  const dispatch = useDispatch();
  const current = INTEGRATIONS.find((i) => i.title === "Avy-Tmpl");
  const [formOpen, setFormOpen] = React.useState(false);
  const [generateIdOpen, setGenerateIdOpen] = React.useState(false);
  const [generateIdLoading, setGenerateIdLoading] = React.useState(false);
  const [generatedId, setGeneratedId] = React.useState(null);

  const isActivatedForCustomer = usePermissionCheck("allow_avytmpl");
  const canViewAvyTmpl = usePermissionCheck("view_can_avytmpl");

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const generateId = () => {
    setGenerateIdLoading(true);
    dispatch(
      generateAvyIdForRealEstate({
        realEstateId: generateIdOpen?.id,
        successCallback: (data) => {
          setGenerateIdLoading(false);
          setGeneratedId(data.avytmpl_id);

          const instance = cloneDeep(generateIdOpen);
          setGenerateIdOpen(false);
          setFormOpen(instance);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "ID genererades",
              description:
                "Följ vidare instruktioner för aktivering av integrationen",
            })
          );
        },
        errorCallback: () => {
          setGenerateIdLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                "ID kunde ej genereras. Försök igen, kontakta Pigello om problemet kvarstår.",
            })
          );
        },
      })
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  const errorQuery = {
    api_kind__in: [SYNC_ERROR_MAP.AVYTMPL],
  };
  const errorFilter = (obj) => {
    return [SYNC_ERROR_MAP.AVYTMPL].includes(obj.api_kind);
  };

  return (
    <>
      <AvyTmplRealEstateForm
        id={formOpen?.id}
        instance={formOpen}
        forceAvyId={generatedId}
        isOpen={!!formOpen}
        onCheckout={() => setFormOpen(false)}
      />

      <StandardModal
        title="Generera ID för Avy-Tmpl"
        isOpen={!!generateIdOpen}
        instance={generateIdOpen}
        closeFunction={() => setGenerateIdOpen(false)}
        saveFunction={generateId}
        canAccept={!generateIdLoading}
        withActionBar
        small
        actionBarAcceptTitle="Generera ID"
      >
        {generateIdLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              Steg 1 - Generera ID och skicka till Avy-Tmpl
            </OverviewTitle>
            <OverviewSubtitle>
              För att integrationen ska fungera så genereras ett ID i Pigello
              som sedan manuellt måste ges till ansvariga på Avy-Tmpl. Efter att
              detta ID:t genererats och installerats av Avy-Tmpl så kan
              synkningen slås på mellan Pigello och Avy-Tmpl.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Avy-Tmpl hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Avy-Tmpl-integrationen tillåter data att delas mellan Pigello
                  och Avy-Tmpl. Detta sker genom synkning på fastighetsnivå.
                  <br />
                  <br />
                  För att komma igång med en fastighet så måste ett ID
                  genereras. Detta ID:t skickas sedan manuellt till Avy-Tmpl för
                  installation. När ID:t är installerat kan synkning av
                  fastighet aktiveras i Pigello.
                  <br />
                  <br />
                  När en fastighet väl är synkad delas data om objekt, avtal och
                  hyresgäster mellan plattformarna för att enkelt hålla datan
                  uppdaterad.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hantera integration</OverviewTitle>
                  <OverviewSubtitle>
                    Aktivera Avy-Tmpl för en fastighet
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {!isActivatedForCustomer ? (
                <InnerBox>
                  Denna integration är inte aktiverad. Kontakta Pigello för att
                  aktivera integrationen.
                </InnerBox>
              ) : !canViewAvyTmpl ? (
                <InnerBox>
                  Den inloggade användaren saknar behörighet att administrera
                  denna integration.
                </InnerBox>
              ) : (
                <AvyTmplRealEstateTable
                  onRowClicked={(original) => {
                    if (original.avytmpl_id) {
                      setFormOpen(original);
                    } else {
                      setGenerateIdOpen(original);
                    }
                  }}
                />
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>

          {isActivatedForCustomer && canViewAvyTmpl && (
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Felmeddelanden</OverviewTitle>
              </OverviewTitleWrapper>

              <SyncErrorTable
                {...{
                  persistantQuery: errorQuery,
                }}
              />
            </DetailPageBox>
          )}
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
