import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { TextButton } from "src/components/Forms/Base/Buttons";
export default ({ onExcludeClicked }) => [
  {
    Header: "",
    id: "exclude",
    Cell: ({ row }) => {
      return (
        <TextButton
          red
          iconType="close"
          title="Exkludera"
          clicked={() => onExcludeClicked(row)}
        />
      );
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Beteckning",
    accessor: "key",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Bolag",
    accessor: "company.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Typ",
    accessor: "type_code_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Huvudadress",
    accessor: "main_address.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
