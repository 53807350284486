import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  constants,
  create,
  useSensorForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/IMDSensor";
import {
  deleteObject,
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TableSelectField from "../../Base/Fields/TableSelectField";
import TextInputField from "../../Base/Fields/TextInputField";
import MeasureTypeTable from "src/components/Tables/MeasureType/FullTable";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import BrfPremisesTable from "src/components/Tables/BrfPremises/FullTable";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";

export default ({ method, id, onCheckout, instance, preProcess }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useSensorForm(method, id));
  const storeName = constants.STORE_NAME;

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            if (!preProcess) {
              return data;
            }
            return preProcess(data, "POST");
          },
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            if (!preProcess) {
              return data;
            }
            return preProcess(data, "PATCH");
          },
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  const objSelected = (key) => {
    const all = [
      "apartment",
      "industrial_premises",
      "brf_premis",
      "common_area",
      "parking_spot",
    ].filter((v) => v !== key);
    let data = {};
    all.forEach((k) => {
      data[k] = null;
    });

    dispatch(updateActiveFormInstance({ storeName, data }));
  };

  const handleDeleteSensor = () => {
    setLoading(true);

    dispatch(
      deleteObject({
        instance,
        constants,
        successCallback: () => onDone(),
        errorCallback: () => setLoading(false),
        skipDestroyDeleteData: true,
      })
    );
  };

  return (
    <Modal
      title={method === "POST" ? "Lägg till sensor" : "Uppdatera sensor"}
      closeFunction={onDone}
      onAccept={onSubmit}
      canDelete={method === "PATCH"}
      onDelete={handleDeleteSensor}
    >
      {(!formLoaded || loading) && <OverlaySpinner />}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Sensor id"
          storeName={storeName}
          method={method}
          fieldKey="sensor_id"
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj mätartyp..."
          title="Mätartyp"
          method={method}
          fieldKey="measure_type"
          TableComponent={MeasureTypeTable}
        />
      </div>

      <div className="mb-6 text-base font-medium">
        Välj vilket objekt som sensor hör till
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj lägenhet..."
          title="Lägenhet"
          method={method}
          fieldKey="apartment"
          changeCallback={(_) => objSelected("apartment")}
          TableComponent={ApartmentTable}
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj lokal..."
          title="Lokal"
          method={method}
          fieldKey="industrial_premises"
          changeCallback={(_) => objSelected("industrial_premises")}
          TableComponent={IndustrialPremisesTable}
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj BRF..."
          title="BRF"
          method={method}
          fieldKey="brf_premis"
          changeCallback={(_) => objSelected("brf_premis")}
          TableComponent={BrfPremisesTable}
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj parkering..."
          title="Parkering"
          method={method}
          fieldKey="parking_spot"
          changeCallback={(_) => objSelected("parking_spot")}
          TableComponent={ParkingSpotsTable}
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj gemensamt utrymme..."
          title="Gemensamt Utrymme"
          method={method}
          fieldKey="common_area"
          changeCallback={(_) => objSelected("common_area")}
          TableComponent={CommonAreaTable}
        />
      </div>
    </Modal>
  );
};
