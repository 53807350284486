import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import useActiveFilters from "../../hooks/useActiveFilters";
import { buildQueryString, usePermissionCheck } from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import PortfoliosTable from "../../components/Tables/RealEstatePortfolios/FullTable";
import { useFilteredRealEstatePortfolios } from "../../store/realEstatePortfolios";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";

import PortfolioForm from "../../components/Forms/RealEstatePortfolio/RealEstatePortfolioForm";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";

export default function Portfolios() {
  const { push } = useHistory();

  const { filteredRealEstates } = useActiveFilters();

  const persistantQueryString = buildQueryString({
    realestate_ids: filteredRealEstates,
  });

  const canAdd = usePermissionCheck("add_can_realestate");

  const [portfolioFormOpen, setPortfolioFormOpen] = React.useState(false);

  const persistantFilterMethod = (obj) => {
    if (!filteredRealEstates) return obj;
    return obj.realestates
      ?.map((r) => r.id)
      ?.some((r) => filteredRealEstates.includes(r));
  };

  const renderActions = () => (
    <>
      {canAdd && (
        <PrimaryButton
          newDesign
          clicked={() => setPortfolioFormOpen(true)}
          title="Lägg till portfölj"
        />
      )}
    </>
  );

  const handleTableRowClicked = (row) => {
    push(`portfolios/${row.original.id}`);
  };

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu title={`Portföljer`} {...{ renderActions }} />
      <PortfolioForm
        method="POST"
        open={portfolioFormOpen}
        onCheckout={() => setPortfolioFormOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Portföljer</OverviewTitle>
          </OverviewTitleWrapper>
          <PortfoliosTable
            persistantQueryString={persistantQueryString}
            perstantFilterMethod={persistantFilterMethod}
            handleRowClicked={handleTableRowClicked}
            hideTitle
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </DetailLayoutWrapper>
  );
}
