import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import User from "../../components/Details/Account/User";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import {
  PopupButton,
  PrimaryButton,
} from "../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import * as SharedStyles from "../../components/sharedStyles";
import {
  buildQueryString,
  setActiveFormInstance,
  updateActiveFormInstance,
  useAllPermissionCheck,
  usePermissionCheck,
} from "../../store/base";
import { constants, useUser } from "../../store/users";
import { inviteUsers, update } from "../../store/users/store/actions";
import {
  constants as groupConstants,
  useFilteredUserGroups,
  update as updateGroup,
  useUserGroupForm,
  destroyPatchForm as destroyPatchGroupForm,
} from "../../store/userGroups";
import {
  post as postAction,
  deleteObject as deleteAction,
  destroyForm,
} from "../../store/base/store/actions";
import { constants as anonymizationConstants } from "../../store/anonymization";

import PageTitle from "../Layouts/PageTitle/PageTitle";
import { removeObject } from "../../store/base/store/actions";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import PageSlider from "../Layouts/PageSlider/PageSlider";
import useQuery from "../../components/utils/useQuery";
import RenderGroup from "../../components/Forms/UserGroup/Permissions/RenderGroup";
import StandardModal from "../../components/Modals/StandardModal";
import GroupList from "../../components/Account/GroupList";
import WhitelistPermsForm from "../../components/Forms/WhitelistPerms/WhitelistPermsForm";
import { InfoBox } from "../../components/Displays";
import { useSupervisorCheck } from "../../store/base/hooks/permissions";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

const TABS = {
  USERS: "Användarinformation",
  PERMS: "Behörigheter",
};

const STEPS = {
  GROUP: "GROUP",
  PERMS: "PERMS",
  DETAIL: "DETAIL",
};

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { userId } = useParams();
  const [user] = useUser(userId);

  const isUtilUser = user?.util_user;

  const query = useQuery();
  const tab = query.get("tab");

  const [selectedTab, setSelectedTab] = React.useState("USERS");
  const [selectGroupOpen, setSelectGroupOpen] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [initialGroup, setInitialGroup] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(STEPS.GROUP);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [inviteConfirmOpen, setInviteConfirmOpen] = React.useState(false);
  // special check, needs both change user perm and detail perm, use form to see if current user has detail perm
  usePermissionRedirect(["view_can_user"]);

  const canEditUser = usePermissionCheck("change_can_user");
  const canDeleteUser =
    usePermissionCheck("delete_can_user") && user?.id !== user?.supervisor?.id;
  const canInvite = !user?.is_active && !user?.invite_accepted;
  const canAnonymizeUser = usePermissionCheck("add_can_anonymization");
  const canUndoAnonymizeUser = usePermissionCheck("delete_can_anonymization");
  const isCurrentUser = useSelector((state) => state.app.user?.id === user?.id);

  const canChangeGroup = useAllPermissionCheck([
    "change_can_permissions",
    "view_can_permissions",
  ]);
  const isSupervisor = useSupervisorCheck();
  const groupFormLoaded = useUserGroupForm("PATCH");

  const [isAnonymous, setIsAnonymous] = React.useState(user?.anonymization);

  React.useEffect(() => {
    setIsAnonymous(user?.anonymization);
  }, [!!user?.anonymization]);

  // only subgroups
  const groupsQuery = buildQueryString({
    users__id: user?.id,
  });
  const [groups] = useFilteredUserGroups(groupsQuery);
  setInviteConfirmOpen;
  const group = groups?.find((g) => g.users.find((u) => u.id === user?.id));
  const [loading, setLoading] = React.useState(false);
  const [changeGroupLoading, setChangeGroupLoading] = React.useState(false);

  const goToEdit = () => {
    push(`/configcenter/groups-and-users/user/edit/${userId}`);
  };

  const persistantQuery = {};

  const handleGroupSelected = (original) => {
    setSelectedGroup(original);
  };

  const checkRowHighlighted = (original) => {
    return original?.id === selectedGroup?.id;
  };

  const toggleActiveStatus = () => {
    setLoading(true);
    const data = {
      is_active: !user.is_active,
    };

    dispatch(
      setActiveFormInstance({
        storeName: constants.STORE_NAME,
        data,
      })
    );

    dispatch(
      update({
        id: user.id,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  const inviteUser = () => {
    setLoading(true);
    dispatch(
      inviteUsers({
        userIds: [user.id],
        successCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Användaren bjöds in",
            })
          );

          dispatch(removeObject({ constants, objectId: user.id }));
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Användaren kunde inte bjudas in",
              description: "Försök igen",
            })
          );
          setLoading(false);
        },
      })
    );
  };

  const handleInviteUser = () => {
    inviteUser();
    setInviteConfirmOpen(false);
  };

  const handleNextClicked = () => {
    switch (currentStep) {
      case STEPS.GROUP:
        setCurrentStep(STEPS.PERMS);
        break;
      case STEPS.PERMS:
        setCurrentStep(STEPS.DETAIL);
        break;
      case STEPS.DETAIL:
        handleSaveGroupSwitch();
    }
  };

  const setUserInNewGroup = () => {
    if (initialGroup?.name !== selectedGroup?.name) {
      const selectedGroupClone = cloneDeep(selectedGroup);
      selectedGroupClone.users.push({ id: parseInt(userId) });
      dispatch(
        updateActiveFormInstance({
          storeName: groupConstants.STORE_NAME,
          data: selectedGroupClone,
        })
      );
      dispatch(
        updateGroup({
          id: selectedGroup.id,
          successCallback: () => {
            setSelectGroupOpen(false);
            destroyPatchGroupForm(true);
            setChangeGroupLoading(false);
          },
          errorCallback: () => {
            setSelectGroupOpen(false);
            destroyPatchGroupForm(true);
            setChangeGroupLoading(false);
          },
        })
      );
    } else {
      setSelectedGroup(false);
      destroyPatchGroupForm(true);
      setChangeGroupLoading(false);
    }
    setCurrentStep(STEPS.GROUP);
  };

  const handleSaveGroupSwitch = () => {
    setChangeGroupLoading(true);
    if (initialGroup) {
      const groupClone = cloneDeep(initialGroup);
      const newUserList = groupClone.users.filter(
        (u) => u.id !== parseInt(userId)
      );
      groupClone.users = newUserList;
      dispatch(
        updateActiveFormInstance({
          storeName: groupConstants.STORE_NAME,
          data: groupClone,
        })
      );
      dispatch(
        updateGroup({
          id: initialGroup.id,
          successCallback: () => setUserInNewGroup(),
          errorCallback: () => {},
        })
      );
    } else {
      setUserInNewGroup();
    }
  };

  const onAnonymize = () => {
    if (!isAnonymous) {
      dispatch(
        postAction({
          url: anonymizationConstants.POST_URL,
          constants: anonymizationConstants,
          forceData: { user: { id: user.id } },
          successCallback: (_, data) => setIsAnonymous({ id: data.id }),
        })
      );
    } else {
      dispatch(
        deleteAction({
          instance: isAnonymous,
          constants: anonymizationConstants,
          successCallback: () => setIsAnonymous(undefined),
        })
      );
    }
  };

  React.useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  React.useEffect(() => {
    if (group) {
      setSelectedGroup(group);
      setInitialGroup(group);
    }
  }, [group]);

  React.useEffect(() => {
    return () => {
      dispatch(
        destroyForm({
          constants,
          method: "PATCH",
        })
      );
    };
  }, []);

  const breadCrumbs = [
    {
      label: "Användare & behörigheter",
      url: "/configcenter/groups-and-users/user",
    },
    {
      label: user ? user.str_representation : "Laddar...",
    },
  ];

  let actions = [];

  if (canEditUser) {
    actions.push({
      name: "Redigera",
      onClick: () => goToEdit(),
    });
    if (user?.invite_accepted && !user?.is_active) {
      actions.push({
        name: "Aktivera användare",
        onClick: () => toggleActiveStatus(),
      });
    }
    if (user?.is_active)
      actions.push({
        name: "Inaktivera användare",
        onClick: () => toggleActiveStatus(),
      });
  }
  if (canInvite) {
    actions.push({
      name: user?.invite_pending
        ? "Inbjudan skickad. Bjud in användare via e-post igen"
        : "Bjud in användare via e-post",
      onClick: () => setInviteConfirmOpen(true),
    });
  }
  if (user?.is_active && isCurrentUser) {
    actions.push({
      name: "Återställ lösenord",
      onClick: () => push("/configcenter/profile/reset-password"),
    });
  }
  if (canAnonymizeUser && !isAnonymous) {
    actions.push({
      name: "Anonymisera användare",
      onClick: () => onAnonymize(),
    });
  }
  if (canUndoAnonymizeUser && isAnonymous) {
    actions.push({
      name: "Stäng av anonymisering",
      onClick: () => onAnonymize(),
    });
  }
  if (canDeleteUser) {
    actions.push({
      name: "Ta bort användare",
      onClick: () => setDeleteModalOpen(true),
    });
  }

  return (
    <SharedStyles.BareLayoutWrapper>
      {(!user || loading) && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle
          title={`Användare ${user?.str_representation || ""}`}
          breadCrumbs={breadCrumbs}
        />
        {canChangeGroup && (
          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => {
                setSelectedTab(tab);
              },
            }}
          />
        )}
      </SharedStyles.BareLayoutTitleWrapper>
      <StandardModal
        isOpen={inviteConfirmOpen}
        closeFunction={() => setInviteConfirmOpen(false)}
        saveFunction={() => handleInviteUser()}
        title="Inbjudning av användare till Pigello"
        withActionBar
        actionBarAcceptTitle="Bjud in till Pigello"
        actionBarCancelTitle="Avbryt inbjudning"
        canAccept={user?.email}
      >
        {user?.email ? (
          <OverviewTitle>
            Är du säker på att du vill bjuda in {user?.str_representation} till
            Pigello via epost adressen {user?.email}?
          </OverviewTitle>
        ) : (
          <OverviewTitle>
            Email adress saknas, vänligen redigera användaren och ange en giltig
            email.
          </OverviewTitle>
        )}

        <InfoBox text="Var noga att dubbelkolla att email adressen till användaren är korrekt då ett inbjudningsmail kommer att skickas ut med ytterliggare instruktioner hur användaren aktiverar och loggar in." />
      </StandardModal>
      {selectedTab === "USERS" && (
        <SharedStyles.DetailPageBoxFlexWrapper>
          <SharedStyles.DetailPageBox
            style={{ flex: 3, alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitle>
                {user?.str_representation ||
                  `${user?.first_name} ${user?.last_name} ${
                    user?.corporate_name ? `(${user?.corporate_name})` : ""
                  }`}
              </OverviewTitle>
              {actions.length > 0 && !isUtilUser && (
                <PopupButton title="Hantera" actions={actions} />
              )}
            </OverviewTitleWrapper>

            {user && !isUtilUser && <User user={user} group={group} />}

            {isUtilUser && (
              <SharedStyles.InnerBox>
                Denna användare är en extern förvaltare och kan därmed inte
                redigeras.
              </SharedStyles.InnerBox>
            )}
            <DeleteModal
              isOpen={deleteModalOpen}
              instance={user}
              constants={constants}
              closeFunction={() => setDeleteModalOpen(false)}
              deletedCallback={() => push("/configcenter/groups-and-users")}
            />
          </SharedStyles.DetailPageBox>
        </SharedStyles.DetailPageBoxFlexWrapper>
      )}

      {selectedTab === "PERMS" && (
        <SharedStyles.DetailPageBox>
          {changeGroupLoading && <OverlaySpinner />}
          {group ? (
            <>
              <OverviewTitleWrapper>
                <OverviewTitle>Behörigheter grupp {group?.name}</OverviewTitle>
                {(canChangeGroup || isSupervisor) && (
                  <PrimaryButton
                    title="Byt grupp"
                    clicked={() => setSelectGroupOpen(true)}
                  />
                )}
              </OverviewTitleWrapper>

              <RenderGroup group={group} disabled={true} />
            </>
          ) : (
            <OverviewTitleWrapper>
              <OverviewTitle>Användaren tillhör ingen grupp</OverviewTitle>
              {(canChangeGroup || isSupervisor) && (
                <PrimaryButton
                  title="Lägg till i grupp"
                  clicked={() => setSelectGroupOpen(true)}
                />
              )}
            </OverviewTitleWrapper>
          )}
          <StandardModal
            isOpen={selectGroupOpen}
            closeFunction={() => {
              setCurrentStep(STEPS.GROUP);
              setSelectedGroup(initialGroup);
              setSelectGroupOpen(false);
            }}
            saveFunction={() => handleNextClicked()}
            disabled={!!selectedGroup}
            title="Byt grupp"
            withActionBar
            actionBarAcceptTitle={
              currentStep === STEPS.DETAIL ? "Genomför byte" : "Nästa"
            }
            large
          >
            {currentStep === STEPS.GROUP && (
              <GroupList
                overrideRowClick={handleGroupSelected}
                checkRowHighlighted={checkRowHighlighted}
              />
            )}
            {currentStep === STEPS.PERMS && (
              <>
                <InfoBox
                  title="Information om vald grupp"
                  text="Nedan specifieras vilka behörigheter användaren får när den ligger i denna grupp"
                />
                <RenderGroup group={selectedGroup} disabled={true} />
              </>
            )}
            {currentStep === STEPS.DETAIL && (
              <>
                <InfoBox
                  title="Information om vald grupp"
                  text="Nedan specifieras vilka extrabehörigheter användaren får tillgång till via denna grupp."
                />
                <WhitelistPermsForm
                  group={selectedGroup}
                  disabled={true}
                  hideTitle
                />
              </>
            )}
          </StandardModal>
        </SharedStyles.DetailPageBox>
      )}
    </SharedStyles.BareLayoutWrapper>
  );
};
