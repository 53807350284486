import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import { getInstance, getTemplate } from "../../store/editabledocs";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import { Document, pdfjs } from "react-pdf";
import DigitalDocPDFPage from "./PdfPage";
import DigitalDocFieldSet from "./FieldSet";
import DigitalDocGhostPDFPage from "./GhostPdfPage";

const numberOfPagesKey = `_pageNo`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

export default function DigitalDocPDFRenderer({
  storeName,
  pageBounds,
  updatePageBounds,
  isTemplateEditor,
}) {
  const dispatch = useDispatch();
  const [pageWidth, setPageWidth] = React.useState(0);
  const [loadingDoc, setLoadingDoc] = React.useState(false);

  const [data, setData] = React.useState(null);
  const [pages, setPages] = React.useState(null);

  const outerWrapperRef = React.useRef();

  const url = useFormInstanceField({
    storeName,
    fieldKey: "doc.get",
  });
  const fastdocId = useFormInstanceField({
    storeName,
    fieldKey: "fastdoc_id",
  });
  const b64 = useFormInstanceField({
    storeName,
    fieldKey: "doc._tempData.data",
  });
  const isTemplate = useFormInstanceField({
    storeName,
    fieldKey: "is_template",
  });
  const id = useFormInstanceField({
    storeName,
    fieldKey: "id",
  });
  const editableDocPageAmount = useFormInstanceField({
    storeName,
    fieldKey: numberOfPagesKey,
  });
  const status = useFormInstanceField({
    storeName,
    fieldKey: "status",
  });

  const isSigned = status && status !== "preparation";

  const setDocumentWidth = () => {
    if (outerWrapperRef?.current?.offsetWidth) {
      if (outerWrapperRef?.current?.offsetWidth > 1200) {
        setPageWidth(1200);
      } else {
        setPageWidth(outerWrapperRef.current.offsetWidth);
      }
    }
  };

  React.useLayoutEffect(() => {
    setDocumentWidth();

    window.addEventListener("resize", setDocumentWidth);

    return () => {
      window.removeEventListener("resize", setDocumentWidth);
    };
  }, []);

  const loadFastdoc = async () => {
    if (isTemplate || (!id && fastdocId)) {
      // collect the template
      setLoadingDoc(true);
      const { data, unauthed } = await getTemplate(fastdocId);
      setLoadingDoc(false);
      if (data == null) {
        setData(null);

        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde inte hämta dokument från Fastighetsägarna Dokument",
          })
        );

        return;
      }

      const dataUri = "data:application/pdf;base64," + data;
      setData(dataUri);
    } else {
      // collect the actual pdf insetad of template pdf
      setLoadingDoc(true);
      const { data, unauthed } = await getInstance(id, fastdocId);
      setLoadingDoc(false);
      if (data == null) {
        setData(null);

        dispatch(
          addToast({
            title: "Kunde inte hämta dokument från Fastighetsägarna Dokument",
            type: TOAST_TYPES.ERROR,
          })
        );

        return;
      }

      const dataUri = "data:application/pdf;base64," + data;
      setData(dataUri);
    }
  };

  React.useEffect(() => {
    if (data != null) {
      // if data is set and all of these are removed - set data to undefined
      if (!url && !b64 && !fastdocId) {
        setData(null);
      } else if (b64) {
        setData(b64);
      }
      return;
    }

    if (url || b64) {
      setData(url || b64);
    } else if (fastdocId) {
      loadFastdoc();
    }
  }, [url, b64, fastdocId]);

  const onLoadSuccess = (data) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [numberOfPagesKey]: data.numPages,
        },
      })
    );
  };

  React.useEffect(() => {
    if (editableDocPageAmount == null) {
      setPages(null);
    } else {
      // generate array of pages
      setPages(Array.from(Array(editableDocPageAmount).keys()));
    }
  }, [editableDocPageAmount]);

  const hasSetAllBounds =
    Object.keys(pageBounds)?.length === editableDocPageAmount;

  const renderDocData =
    editableDocPageAmount != null && pages?.length > 0 && !loadingDoc;

  return (
    <div className="w-full bg-slate-50 min-h-[1200px] pt-4 flex justify-center">
      {loadingDoc && <OverlaySpinner />}
      <div
        ref={outerWrapperRef}
        className="w-full max-w-[1000px] relative flex flex-col items-center overflow-hidden"
      >
        {!!data && (
          <Document
            width={pageWidth}
            file={data}
            onLoadSuccess={onLoadSuccess}
            loading="Laddar dokument..."
          >
            {renderDocData && hasSetAllBounds && (
              <DigitalDocFieldSet
                pageBounds={pageBounds}
                disabled={isSigned}
                storeName={storeName}
              />
            )}

            {renderDocData &&
              !hasSetAllBounds &&
              pages.map((pageIndex) => {
                if (pageIndex > 0 && !pageBounds[pageIndex]) return null; // wait for pagebounds to be set for prev page
                return (
                  <>
                    <DigitalDocGhostPDFPage
                      key={`page-${pageIndex}`}
                      pageWidth={pageWidth}
                      pageNumber={pageIndex + 1}
                      updatePageBounds={updatePageBounds}
                    />
                  </>
                );
              })}

            {renderDocData &&
              hasSetAllBounds &&
              pages.map((pageIndex) => {
                return (
                  <>
                    <DigitalDocPDFPage
                      key={`page-${pageIndex}`}
                      pageWidth={pageWidth}
                      pageNumber={pageIndex + 1}
                    />
                  </>
                );
              })}
          </Document>
        )}
      </div>
    </div>
  );
}
