import * as React from "react";

export default () => {
  return (
    <div>
      Välj motparten som ska stå på avtalet.
      <br />
      <br />
      Observera att om avtalet är ett tilläggsavtal så måste denna motpart även
      finnas med på huvudavtalet.
    </div>
  );
};
