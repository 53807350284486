export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ROUNDING_ERRAND_FAULTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ROUNDING_ERRAND_FAULTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ROUNDING_ERRAND_FAULTS",
  DESTROY_FORM: "DESTROY_FORM_ROUNDING_ERRAND_FAULTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_ROUNDING_ERRAND_FAULTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ROUNDING_ERRAND_FAULTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ROUNDING_ERRAND_FAULTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ROUNDING_ERRAND_FAULTS",
  RESET_STATE: "RESET_STATE_ROUNDING_ERRAND_FAULTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_ROUNDING_ERRAND_FAULTS",
  SET_OVERVIEW_STATS: "SET_ROUNDING_ERRAND_OVERVIEW_STATS_FAULTS",
  POST_ACCEPT_DECLINE: "POST_ACCEPT_DECLINE_ROUNDING_ERRAND_FAULTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ROUNDINGERRAND_FAULTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_ROUNDINGERRAND_FAULTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_ROUNDINGERRAND_FAULTS",

  LIST_URL: "/errands/main/roundingfault/list/",
  POST_URL: "/errands/main/roundingfault/",
  PATCH_URL: "/errands/main/roundingfault/",
  GET_URL: "/errands/main/roundingfault/",

  STORE_NAME: "roundingFaults",
};
