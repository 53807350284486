import { useObject } from "../../base";
import { getSingle } from "../store/actions"
import constants from "../store/constants"

export function useNotificationSetting() {
    const params = {
        storeName:constants.STORE_NAME,
        id:constants.SETTING_STATE_ID,
        fetchMethod: getSingle
    }
    return useObject(params);
}