export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_OUTLOOK_CALENDAR_EVENT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_OUTLOOK_CALENDAR_EVENT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_OUTLOOK_CALENDAR_EVENT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_OUTLOOK_CALENDAR_EVENT",
  DESTROY_FORM: "DESTROY_FORM_OUTLOOK_CALENDAR_EVENT",
  SET_FORM_ERROR: "SET_FORM_ERROR_OUTLOOK_CALENDAR_EVENT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_OUTLOOK_CALENDAR_EVENT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_OUTLOOK_CALENDAR_EVENT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_OUTLOOK_CALENDAR_EVENT",
  RESET_STATE: "RESET_STATE_OUTLOOK_CALENDAR_EVENT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_OUTLOOK_CALENDAR_EVENT",
  REMOVE_OBJECT: "REMOVE_OBJECT_OUTLOOK_CALENDAR_EVENT",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_OUTLOOK_CALENDAR_EVENT",
  CLEAR_FETCHED: "CLEAR_FETCHED_OUTLOOK_CALENDAR_EVENT",

  LIST_URL: "/events/outlook/events/",

  STORE_NAME: "outlookCalendarEvents",
};
