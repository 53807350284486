import * as React from "react";
import {
  getAdTypeForRanking,
  getAttrForRanking,
  useLeadRankings,
} from "../../../store/leads";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TimeCell } from "../../Displays";
import { TextButton } from "../../Forms/Base/Buttons";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";

import { InnerBox } from "../../sharedStyles";

export default function AdLeadInfo({ ad, onLeadDetailOpened }) {
  const adType = getAdTypeForRanking(ad);
  const attr = getAttrForRanking(ad);

  const [rankings, rankingsLoading] = useLeadRankings({
    ids: [ad[attr]?.id],
    type: adType,
  });

  const rankedLeads = rankings?.[ad?.[attr]?.id];

  return (
    <InnerBox style={{ border: 0, borderRadius: 0, boxShadow: 0 }}>
      <OverviewTitleWrapper>
        <OverviewTitle>Leads</OverviewTitle>
      </OverviewTitleWrapper>

      <InnerBox>
        {(rankedLeads || []).map((l, idx) => {
          const lead = ad.leads?.find((le) => le.id === l.id);

          return (
            <InnerBox>
              <DetailInfo
                infoObj={{
                  [idx + 1]: [
                    {
                      title: "Lead",
                      value: (
                        <TextButton
                          title={lead?.str_representation}
                          clicked={() => onLeadDetailOpened(lead?.id)}
                        />
                      ),
                    },
                    {
                      title: "Status",
                      value: (
                        <StatusLabel
                          state={lead.confirmed ? 0 : lead.denied ? 6 : 4}
                        >
                          {lead.confirmed
                            ? "Bekräftad"
                            : lead.denied
                            ? "Nekad"
                            : "Väntande"}
                        </StatusLabel>
                      ),
                    },
                    {
                      title: "Skapad",
                      value: <TimeCell date={lead.created_at} />,
                    },
                  ],
                }}
              />
            </InnerBox>
          );
        })}
      </InnerBox>
    </InnerBox>
  );
}
