export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title || obj.errand_number;
};
export const detailUrl = ({ id }) => {
  return `/inspection-errands/detail/${id}`;
};

export const overviewUrl = () => {
  return `/inspection-errands/`;
};

export const allUrl = () => {
  return `/inspection-errands`;
};

export const executeUrl = ({ id }) => {
  return `/inspection-errands/execute/${id}`;
};

export const getInspectionOverviewStatsProcessName = () => {
  return `INSPECTION_ERRAND_OVERVIEW_STATS`;
};
