import { constants } from "..";

const INITIAL_STATE = {
  credentialsCheckInProgress: false,
  credentials: null,
  sbfRequirements: null,
  requirementsInProgress: false,
  requirementConnections: null,
  requirementConnectionsInProgress: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_CREDENTIALS:
      return {
        ...state,
        credentials: payload.credentials,
        credentialsCheckInProgress: false,
      };

    case constants.SET_CREDENTIAL:
      return {
        ...state,
        credentials: [...state.credentials, payload.credential],
        credentialsCheckInProgress: false,
      };

    case constants.UPDATE_CREDENTIAL:
      const updatedId = payload.credential?.id;
      const newCredentials = state.credentials.map((cred) => {
        if (updatedId == cred.id) {
          return payload.credential;
        } else {
          return cred;
        }
      });

      return {
        ...state,
        credentials: newCredentials,
        credentialsCheckInProgress: false,
      };

    case constants.REMOVE_CREDENTIAL:
      const newCreds = state.credentials.filter((cred) => {
        if (payload.id == cred.id) {
          return false;
        } else {
          return true;
        }
      });

      return {
        ...state,
        credentials: newCreds,
        credentialsCheckInProgress: false,
      };

    case constants.SET_SBF_REQUIREMENTS: {
      return {
        ...state,
        sbfRequirements: payload.requirements,
        requirementsInProgress: false,
      };
    }
    case constants.SET_SBF_REQUIREMENT_CONNECTIONS: {
      return {
        ...state,
        requirementConnections: payload.connections,
        requirementConnectionsInProgress: false,
      };
    }

    case constants.SET_SBF_REQUIREMENTS_INPROGRESS: {
      return { ...state, requirementsInProgress: payload.inProgress };
    }

    case constants.SET_SBF_REQUIREMENT_CONNECTIONS_INPROGRESS: {
      return { ...state, requirementConnectionsInProgress: payload.inProgress };
    }

    case constants.SET_CREDENTIALS_IN_PROGRESS:
      return { ...state, credentialsCheckInProgress: payload.inProgress };

    default:
      return state;
  }
};
