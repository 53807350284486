import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import CommonAreaModal from "../../../components/Forms/CommonArea/ChapterForm/ModalForm";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { LinkedObject } from "../../../components/Displays";
import { detailUrl as buildingDetailUrl } from "../../../store/buildings";
import { toSqmString } from "../../../components/utils/stringUtils";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import { useFilteredNotes } from "../../../store/notes";
import { useRealEstate } from "../../../store/realEstates";
import { useBuilding } from "../../../store/buildings";
import { useCommonArea } from "../../../store/commonAreas";
import { renderFloors } from "../../../components/Forms/Base/FieldComponents/FloorSelector";

export default function CommonAreaOverview() {
  const [editOpen, setEditOpen] = React.useState(false);
  const { commonAreaId } = useParams();

  const canEdit = usePermissionCheck("change_can_baseobject");

  const [commonArea] = useCommonArea(commonAreaId);
  const [building] = useBuilding(commonArea?.building?.id);
  const [realEstate] = useRealEstate(commonArea?.realestate?.id);

  const notesQ = buildQueryString({
    id__in: commonArea?.notes?.map((n) => n.id) || [],
  });

  const [notes] = useFilteredNotes(notesQ);

  return (
    <>
      {editOpen && (
        <CommonAreaModal
          instance={commonArea}
          method="PATCH"
          id={commonArea?.id}
          onCheckout={() => setEditOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "65%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Objektsöversikt</OverviewTitle>
              {canEdit && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            <DetailInfo
              infoObj={getInfoObj({ commonArea, building, realEstate })}
            />
          </DetailPageBox>

          <DetailPageBox style={{ width: "35%", alignSelf: "flex-start" }}>
            <Notes
              notes={notes}
              contentType="standard.commonarea"
              objectId={commonAreaId}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ commonArea, realEstate, building }) {
  const infoObj = {
    Översikt: [
      {
        title: "Fastighet",
        value: realEstate?.str_representation,
      },
      {
        title: "Byggnad",
        value: <LinkedObject obj={building} urlMethod={buildingDetailUrl} />,
      },
      {
        title: "Våning",
        value: renderFloors(commonArea?.dynamic_floors),
      },
      {
        title: "Area",
        value: toSqmString(commonArea?.area),
      },
      {
        title: "Kategori",
        value: commonArea?.category_display,
      },
    ],
    Adress: [
      {
        title: "Adress",
        value: commonArea?.address
          ? commonArea?.address?.base?.split(",")?.[0]
          : "-",
      },
      {
        title: "Postnr.",
        value: commonArea?.address?.postal_code || "-",
      },
      {
        title: "Stad",
        value: commonArea?.address?.city || "-",
      },
      {
        title: "Land",
        value: commonArea?.address?.country || "-",
      },
    ],
  };

  return infoObj;
}
