import { cloneDeep } from "lodash";
import moment from "moment";

const removeReadOnlyFields = (records) => {
  if (!records) return records;

  const recordsCopy = cloneDeep(records);

  const updated = recordsCopy.map((r) => ({
    ...r,
    UnitPrice: { ...r.UnitPrice, ValueForView: undefined },
  }));

  return updated;
};

const handleOrenToSek = (records) => {
  // if no records, return default null value
  if (!records) return records;

  // convert
  const recordsCopy = cloneDeep(records);

  // use the returned ValueForView (which is in SEK)
  const updated = recordsCopy.map((r) => ({
    ...r,
    UnitPrice: {
      ...r.UnitPrice,
      Value: r.UnitPrice.ValueForView || r.UnitPrice.Value, // if not value for view, it's a proposal and the value is already correct
    },
  }));

  return updated;
};

const removeInvoiceFeeReadOnlyFields = (InvoiceFee) => {
  if (!InvoiceFee) return InvoiceFee;

  return {
    ...InvoiceFee,
    ValueForView: undefined,
  };
};

const handleInvoiceFeeOrenToSek = (InvoiceFee) => {
  // if no invoice fee, return default null value

  if (!InvoiceFee?.Value) return InvoiceFee;

  return {
    ...InvoiceFee,
    Value: InvoiceFee.Value / 100,
  };
};

const cleanCreditRecords = (records) => {
  if (!records) return null;

  return records.map((r) => ({
    UnitPrice: {
      CurrencyCode: r.UnitPrice.CurrencyCode,
      Value: r.UnitPrice.Value * -1,
    },
    Units: r.Units,
    ArticleDescription: r.ArticleDescription,
    Quantity: r.Quantity,
    RecordType: r.RecordType,
    VAT: r.VAT,
    VatIsIncluded: r.VatIsIncluded,
    ProductPublicId: r.ProductPublicId,
    CostCenter: r.CostCenter,
    Project: r.Project,
    PeriodStart: r.PeriodStart,
    PeriodEnd: r.PeriodEnd,
  }));
};

const cleanCreditInvoiceFee = (invoiceFee) => {
  if (!invoiceFee) return null;

  return {
    Value: invoiceFee.Value * -1,
    CurrencyCode: invoiceFee.CurrencyCode,
  };
};

export const cleanPutInvoice = (invoice) => {
  const invoiceClone = cloneDeep(invoice);

  return {
    ActionPublicId: invoiceClone.ActionPublicId,
    ActionType: invoiceClone.ActionType,
    CreditorPublicId: invoiceClone.CreditorPublicId,
    DebtorPublicId: invoiceClone.Debtor?.DebtorPublicId,
    InvoiceDate: invoiceClone.InvoiceDate,
    DueDate: invoiceClone.DueDate,
    DeliveryDate: invoiceClone.DeliveryDate,
    Records: removeReadOnlyFields(handleOrenToSek(invoiceClone.Records)),
    InterestPercentage:
      invoiceClone.InterestType === "NoInterest"
        ? undefined
        : invoiceClone.InterestPercentage,
    ReasonForHigherInterest: invoiceClone.ReasonForHigherInterest,
    InterestStartInDaysAfterDueDate:
      invoiceClone.InterestType === "NoInterest"
        ? undefined
        : invoiceClone.InterestStartInDaysAfterDueDate,
    InterestType:
      invoiceClone.InterestType === "NoInterest"
        ? undefined
        : invoiceClone.InterestType,
    OurReference: invoiceClone.OurReference,
    YourReference: invoiceClone.YourReference,
    DeliveryMethod: invoiceClone.DeliveryMethod,
    CommunicationLanguage: invoiceClone.CommunicationLanguage,
    Message: invoiceClone.Message,
    InvoiceNumber: invoiceClone.InvoiceNumber,
    InvoiceFee: removeInvoiceFeeReadOnlyFields(
      handleInvoiceFeeOrenToSek(invoiceClone.InvoiceFee)
    ),
    Autogiro: invoiceClone.Autogiro,
    FreightFee: invoiceClone.FreightFee,
    VatIsIncluded: invoiceClone.VatIsIncluded,
    SendByMailIfEmailNotViewedInDays:
      invoiceClone.SendByMailIfEmailNotViewedInDays,
    DeliveryAddressOverride: invoiceClone.DeliveryAddressOverride,
    DebtCollectionDetails: invoiceClone.DebtCollectionDetails,
    ReminderInvoiceDetails: invoiceClone.ReminderInvoiceDetails,
    ReverseVATDetails: invoiceClone.ReverseVATDetails,
    RotRutDetails: invoiceClone.RotRutDetails,
    PaymentOverride: invoiceClone.PaymentOverride,
    Attachments: invoiceClone.Attachments,
    CreditCard: invoiceClone.CreditCard,
    InvoicePDF: invoiceClone.InvoicePDF,
    CreditingInvoicePublicId: invoiceClone.CreditingInvoicePublicId,
    ExternalReference: invoiceClone.ExternalReference,
    IsLocked: invoiceClone.IsLocked,
    UseDebtorBalance: invoiceClone.UseDebtorBalance,
    InvoiceSendDateOverride: invoiceClone.InvoiceSendDateOverride,
  };
};

export const invoiceToCreditInvoice = ({
  formattedInvoice,
  newInvoiceDate,
}) => {
  const initialDaysDiff = moment(formattedInvoice.DueDate).diff(
    moment(formattedInvoice.InvoiceDate)
  );

  const newDueDate = moment(newInvoiceDate)
    .add(initialDaysDiff)
    .format("YYYY-MM-DD");

  return {
    CreditorPublicId: formattedInvoice.CreditorPublicId,
    DebtorPublicId: formattedInvoice.DebtorPublicId,
    InvoiceDate: newInvoiceDate,
    DueDate: newDueDate,
    Records: cleanCreditRecords(formattedInvoice.Records),
    InvoiceFee: cleanCreditInvoiceFee(formattedInvoice.InvoiceFee),
    Message: formattedInvoice.Message,
    UseDebtorBalance: formattedInvoice.UseDebtorBalance,
    DeliveryMethod: formattedInvoice.DeliveryMethod,
    YourReference: formattedInvoice.YourReference,
    OurReference: formattedInvoice.OurReference,
    Autogiro: formattedInvoice.Autogiro,
  };
};
