import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useParams } from "react-router";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import { NotificationDot } from "../../../components/Dashboard/styles";
import { useBrfCompany } from "../../../store/brfCompanies";

export default function BrfCompanyInvoicingErrors() {
  const { brfCompanyId } = useParams();
  const [brfCompany] = useBrfCompany(brfCompanyId);

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      billecta_creditor_id: brfCompany?.company?.billecta_id,
    },
  });

  const errorQuery = {
    billecta_creditor_id: brfCompany?.company?.billecta_id,
  };

  return (
    <>
      <DetailInnerWrapper>
        {errorCount > 0 && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
                small
              >
                Felmeddelanden under bolag
                <NotificationDot>{errorCount}</NotificationDot>
              </OverviewTitle>
            </OverviewTitleWrapper>

            <InvoicingErrorTable
              isBare
              ignoreLocalStorage
              persistantQuery={errorQuery}
            />
          </DetailPageBox>
        )}
      </DetailInnerWrapper>
    </>
  );
}
