import * as React from "react";

import { lightOrDark } from "../../DigitalDoc/utils";

import { TextButton } from "../../Forms/Base/Buttons";
import LeadModalForm from "../../Forms/Lead/ChapterForm/ModalForm";
import SideModal from "../../Modals/SideModal";
import { Pill, PillsWrapper } from "../../sharedStyles";
import { strToColor } from "./Lead";
import * as SC from "./styles";
import { useFilteredPipeAttributes } from "../../../store/pipeAttributes";
import { buildQueryString } from "../../../store/base";
import { useParams } from "react-router-dom";
import { useFilteredStages, useStage } from "../../../store/pipeStages";
import {
  acceptLeads,
  constants as leadConstants,
  rejectLeads,
  update as updateLead,
} from "../../../store/leads";
import LeadMilestones from "./LeadMilestones";
import LeadContacts from "./LeadContacts";
import useKeyPress from "../../../hooks/useKeyPress";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import LeadInfo from "./LeadInfo";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ConfirmLeadModal from "./ConfirmLeadModal";
import { getRejectionReasons } from "../../../store/homeQ/store/actions";
import RejectLeadModal from "./RejectLeadModal";
import DeleteModal from "../../Forms/Delete/DeleteModal";
import { usePipe } from "../../../store/pipes";
import { useIsHomeQAuthenticated } from "../../../store/homeQ";
import LeadNotes from "./LeadNotes";

const PAGES = {
  INFO: 1,
  CONTACTS: 2,
  EVENTS: 3,
  NOTES: 4,
};

export default function LeadDetail({
  closeFunction,
  onLeadDetailOpened,
  isOpen,
  leads,
  lead = {},
  updateLeadsInstance,
}) {
  const dispatch = useDispatch();
  const { pipeId } = useParams();
  const pipeQ = buildQueryString({
    pipe: pipeId,
  });
  const [pipe] = usePipe(pipeId);
  const isHomeQPipe = pipe?.is_homeq_pipe;
  const [page, setPage] = React.useState(PAGES.INFO);
  const [acceptLeadConfirmOpen, setAcceptLeadConfirmOpen] =
    React.useState(false);
  const [rejectLeadConfirmOpen, setRejectLeadConfirmOpen] =
    React.useState(false);
  const [acceptLeadLoading, setAcceptLeadLoading] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [pipeAttributeChoices] = useFilteredPipeAttributes(pipeQ);
  const [stage] = useStage(lead?.stage?.id);

  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [homeQActivated] = useIsHomeQAuthenticated();

  const stageQ = buildQueryString({
    pipe: pipeId,
  });
  const [stages] = useFilteredStages(stageQ);
  const { maxStageId } = stages?.reduce(
    (acc, cur) => {
      if (cur.order > acc.order) {
        return {
          order: cur.order,
          maxStageId: cur.id,
        };
      } else {
        return acc;
      }
    },
    { order: -1, maxStageId: null }
  ) || { order: -1, maxStageId: null };
  const { minStageId } = stages?.reduce(
    (acc, cur) => {
      if (cur.order < acc.order) {
        return {
          order: cur.order,
          minStageId: cur.id,
        };
      } else {
        return acc;
      }
    },
    { order: maxStageId, minStageId: null }
  ) || { order: maxStageId, minStageId: null };

  const [loading, setLoading] = React.useState(false);

  const leadName = lead?.corporate_name
    ? lead?.corporate_name?.split(" ").join("+")
    : `${lead?.first_name || ""}+${lead?.last_name || ""}`;
  const leadColor = strToColor(leadName);
  const leadTextColor = lightOrDark(leadColor) === "light" ? "000" : "fff";

  const currentStage = lead?.stage?.id;
  const leadsInCurrentStage = leads?.filter(
    (l) => l.stage?.id === currentStage
  );
  const currentLeadIndex = leadsInCurrentStage?.findIndex(
    (l) => l.id === lead?.id
  );

  const prevLead = leadsInCurrentStage?.[currentLeadIndex - 1];
  const nextLead = leadsInCurrentStage?.[currentLeadIndex + 1];

  const leftPressed = useKeyPress("ArrowLeft");
  const rightPressed = useKeyPress("ArrowRight");

  React.useEffect(() => {
    if (homeQActivated) {
      // get rejection reasons for homeq accept/deny
      dispatch(getRejectionReasons());
    }
  }, [homeQActivated]);

  React.useEffect(() => {
    if (leftPressed && !!prevLead) {
      onLeadDetailOpened(prevLead.id);
    }
  }, [leftPressed]);
  React.useEffect(() => {
    if (rightPressed && !!nextLead) {
      onLeadDetailOpened(nextLead.id);
    }
  }, [rightPressed]);

  const handleAcceptLead = ({
    acceptType,
    homeQRejectionReason,
    sbfRejectionReason,
    prioOrder,
  }) => {
    setAcceptLeadLoading(true);
    dispatch(
      acceptLeads({
        ids: [acceptLeadConfirmOpen],
        acceptType,
        homeQRejectionReason,
        sbfRejectionReason,
        prioOrder,
        successCallback: () => {
          setAcceptLeadLoading(false);
          const leadId = acceptLeadConfirmOpen;
          setAcceptLeadConfirmOpen(false);
          onLeadDetailOpened(leadId);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Leaden bekräftades",

              description:
                "Leaden flyttas automatiskt till det sista steget i projektet",
            })
          );

          dispatch(
            updateLead({
              id: acceptLeadConfirmOpen,
              preventDefaultToast: true,
              forceData: {
                stage: {
                  id: maxStageId,
                },
              },
              successCallback: () => {
                updateLeadsInstance();
              },
            })
          );
        },
        errorCallback: (message) => {
          setAcceptLeadLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const handleRejectLead = ({ homeQRejectionReason, sbfRejectionReason }) => {
    setAcceptLeadLoading(true);
    dispatch(
      rejectLeads({
        ids: [rejectLeadConfirmOpen],
        homeQRejectionReason,
        sbfRejectionReason,
        successCallback: () => {
          setAcceptLeadLoading(false);
          const leadId = rejectLeadConfirmOpen;
          setRejectLeadConfirmOpen(false);
          onLeadDetailOpened(leadId);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Leaden nekades",
              description:
                "Leaden flyttas automatiskt till det första steget i projektet",
            })
          );

          dispatch(
            updateLead({
              id: rejectLeadConfirmOpen,
              forceData: {
                stage: {
                  id: minStageId,
                },
              },
              successCallback: () => {
                updateLeadsInstance();
              },
            })
          );
        },
        errorCallback: (message) => {
          setAcceptLeadLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const onDelete = () => {
    setDeleteOpen(lead.id);
    closeFunction();
  };

  return (
    <>
      {deleteOpen && (
        <DeleteModal
          isOpen={!!deleteOpen}
          instance={leads?.find((l) => l.id == deleteOpen)}
          constants={leadConstants}
          closeFunction={(success) => {
            const id = deleteOpen;
            setDeleteOpen(false);

            if (!success) {
              onLeadDetailOpened(id);
            }
          }}
          deletedCallback={updateLeadsInstance}
        />
      )}

      {acceptLeadConfirmOpen && (
        <ConfirmLeadModal
          callback={handleAcceptLead}
          isOpen={acceptLeadConfirmOpen}
          closeFunction={() => {
            const id = acceptLeadConfirmOpen;
            onLeadDetailOpened(id);
            setAcceptLeadConfirmOpen(false);
          }}
          isHomeQPipe={isHomeQPipe}
          loading={acceptLeadLoading}
          leads={[leads?.find((l) => l.id === acceptLeadConfirmOpen)]}
        />
      )}
      {rejectLeadConfirmOpen && (
        <RejectLeadModal
          callback={handleRejectLead}
          isOpen={rejectLeadConfirmOpen}
          closeFunction={() => {
            const id = rejectLeadConfirmOpen;
            onLeadDetailOpened(id);
            setRejectLeadConfirmOpen(false);
          }}
          isHomeQPipe={isHomeQPipe}
          loading={acceptLeadLoading}
          leads={[leads?.find((l) => l.id === rejectLeadConfirmOpen)]}
        />
      )}

      {editOpen && (
        <LeadModalForm
          method="PATCH"
          id={editOpen}
          onCheckout={(success) => {
            const id = editOpen;
            onLeadDetailOpened(id);
            setEditOpen(false);

            if (success) {
              updateLeadsInstance();
            }
          }}
          isOpen={!!editOpen}
        />
      )}

      <SideModal
        {...{
          isOpen,
          closeFunction,
          extraBackdropStyle: { zIndex: 100000000 },
        }}
      >
        {loading && <OverlaySpinner />}
        <SC.HeaderWrapper>
          <SC.StagesTitle>{stage?.name || ""}</SC.StagesTitle>
          <SC.LeadDetailHeader>
            {prevLead ? (
              <TextButton
                title={prevLead?.str_representation}
                iconType="arrow-back"
                clicked={() => onLeadDetailOpened(prevLead.id)}
              />
            ) : (
              <div></div>
            )}

            <SC.LeadHeader style={{ border: "none" }}>
              <SC.LeadImage
                name={leadName}
                tetColor={leadTextColor}
                color={leadColor.replace("#", "")}
              />

              <SC.LeadDataWrapper>
                <SC.LeadName style={{ fontWeight: 600, fontSize: 22 }}>
                  {lead.corporate_name || lead.str_representation}
                </SC.LeadName>
                {lead.corporate_name && (
                  <SC.LeadName
                    style={{
                      fontSize: 12,
                      lineHeight: "100%",
                      marginBottom: 0,
                    }}
                  >
                    {lead.first_name || lead.last_name
                      ? `${lead.first_name || ""} ${lead.last_name || ""}`
                      : "Kontakt saknas"}
                  </SC.LeadName>
                )}
              </SC.LeadDataWrapper>
            </SC.LeadHeader>

            {nextLead ? (
              <TextButton
                title={nextLead?.str_representation}
                iconType="arrow"
                iconPlacement="right"
                extraStyle={{ marginRight: 0 }}
                clicked={() => onLeadDetailOpened(nextLead.id)}
              />
            ) : (
              <div></div>
            )}
          </SC.LeadDetailHeader>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 8px",
            }}
          >
            <PillsWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pill
                onClick={() => setPage(PAGES.INFO)}
                active={page === PAGES.INFO}
              >
                Info
              </Pill>
              <Pill
                onClick={() => setPage(PAGES.CONTACTS)}
                active={page === PAGES.CONTACTS}
              >
                Kontakter
              </Pill>
              <Pill
                onClick={() => setPage(PAGES.EVENTS)}
                active={page === PAGES.EVENTS}
              >
                Händelser
              </Pill>
              <Pill
                onClick={() => setPage(PAGES.NOTES)}
                active={page === PAGES.NOTES}
              >
                Anteckningar
              </Pill>
            </PillsWrapper>

            <PillsWrapper>
              {!lead.confirmed && !lead.denied && (
                <>
                  <Pill
                    style={{
                      border: "thin solid #2ec177",
                      backgroundColor: "white",
                      color: "#2ec177",
                    }}
                    onClick={() => {
                      setAcceptLeadConfirmOpen(lead.id);
                      closeFunction();
                    }}
                  >
                    Bekräfta
                  </Pill>
                  <Pill
                    style={{
                      border: "thin solid #ea5050",
                      backgroundColor: "white",
                      color: "#ea5050",
                    }}
                    onClick={() => {
                      setRejectLeadConfirmOpen(lead.id);
                      closeFunction();
                    }}
                  >
                    Neka
                  </Pill>
                </>
              )}

              {lead.confirmed && (
                <>
                  <Pill
                    style={{
                      backgroundColor: "#2ec177",
                      color: "white",
                    }}
                  >
                    Bekräftad
                  </Pill>

                  <Pill
                    style={{
                      border: "thin solid #ea5050",
                      backgroundColor: "white",
                      color: "#ea5050",
                    }}
                    onClick={() => {
                      setRejectLeadConfirmOpen(lead.id);
                      closeFunction();
                    }}
                  >
                    Neka
                  </Pill>
                </>
              )}

              {lead.denied && (
                <>
                  <Pill
                    style={{
                      backgroundColor: "#ea5050",
                      color: "white",
                    }}
                  >
                    Nekad
                  </Pill>
                  <Pill
                    style={{
                      border: "thin solid #2ec177",
                      backgroundColor: "white",
                      color: "#2ec177",
                    }}
                    onClick={() => {
                      setAcceptLeadConfirmOpen(lead.id);
                      closeFunction();
                    }}
                  >
                    Bekräfta
                  </Pill>
                </>
              )}
            </PillsWrapper>
          </div>
        </SC.HeaderWrapper>

        <SC.InnerLeadDetailContent>
          {page === PAGES.INFO && lead && (
            <LeadInfo
              {...{
                lead,
                pipeAttributeChoices,
                isHomeQPipe: pipe?.is_homeq_pipe,
                closeFunction,
                setEditOpen,
              }}
            />
          )}
          {page === PAGES.CONTACTS && lead && <LeadContacts lead={lead} />}

          {page === PAGES.EVENTS && lead && <LeadMilestones lead={lead} />}
          {page === PAGES.INFO && lead && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextButton
                title="Radera lead"
                iconType="close"
                iconPlacement="right"
                red
                extraStyle={{ marginTop: 24, marginRight: 0 }}
                clicked={onDelete}
              />
            </div>
          )}
          {page === PAGES.NOTES && lead && <LeadNotes lead={lead} />}
        </SC.InnerLeadDetailContent>
      </SideModal>
    </>
  );
}
