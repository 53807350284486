export const constructStrRep = (obj) => {
  if (obj.str_representation) return obj.str_representation;
  if (obj.sensor_id) return obj.sensor_id;
  return "Mätartyp";
};
export const detailUrl = ({ id }) => {
  return `/imd/measure-types/detail/${id}`;
};
export const overviewUrl = () => {
  return `/imd/measure-types`;
};
