import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { TextInput } from "../../../Base/Fields";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ChecklistTable from "src/components/Tables/Checklists/FullTable";
import UsersTable from "src/components/Tables/Users/FullTable";

export default function ComponentType({ method, storeName }) {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för komponenttyp</OverviewTitle>
          <OverviewSubtitle>
            Ange namn och inställningar för komponenttypen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />
      <TextInput
        {...{
          storeName,
          method,
          fieldKey: "name",
          title: "Namn på komponenttyp",
        }}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey={"report_errand_checklist"}
          description="Denna checklista kommer att gälla för felanmälan på alla komponenter som ligger under denna komponenttyp. Vilka rader på checklistan som visas beror på inställningarna på den givna checklistan. Observera att om en checklista är definerad på komponentmodellen för komponenten kommer den att ha företräde."
          method={method}
          title="Checklista för felanmälan"
          placeholder={"Välj checklista..."}
          TableComponent={ChecklistTable}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          description="Denna checklista kommer att gälla för besiktning på alla komponenter som ligger under denna komponenttyp. Vilka rader på checklistan som visas beror på inställningarna på den givna checklistan. Observera att om en checklista är definerad på komponentmodellen för komponenten kommer den att ha företräde."
          method={method}
          title="Checklista för besiktningar"
          placeholder={"Välj checklista..."}
          fieldKey={"inspection_errand_checklist"}
          TableComponent={ChecklistTable}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          description="Denna checklista kommer att gälla för ronderingar på alla komponenter som ligger under denna komponenttyp. Vilka rader på checklistan som visas beror på inställningarna på den givna checklistan. Ronderingar kan ej ha checklistor kopplade till komponentmodellen."
          method={method}
          title="Checklista för ronderingar"
          placeholder={"Välj checklista..."}
          fieldKey={"rounding_errand_checklist"}
          TableComponent={ChecklistTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey={"send_faults_to_user"}
          description="Skicka en automatisk rapport om fel till användare per E-post. Användbart om ärendet t.ex. gäller komponenter med garanti som ska hanteras av en tredjepart."
          method={method}
          title="Skicka rapport om fel till användare"
          placeholder={"Välj användare..."}
          TableComponent={UsersTable}
        />
      </div>
    </>
  );
}
