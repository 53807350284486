import * as React from "react";

import { constants } from "../../../../../store/reportErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { ImageM2MContent, TextField } from "../../../Base/Fields";
import ManyMediaField from "../../../Base/Fields/ManyMediaField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Avhjälpande</OverviewTitle>
          <OverviewSubtitle>Information kring avhjälpandet</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextField
        storeName={storeName}
        fieldKey="completion_comment"
        method={method}
        title="Kommentar vid utförande"
      />

      <ManyMediaField
        storeName={storeName}
        fieldKey={"completion_files"}
        method={method}
        title="Bilder på avhjälpt fel"
        fileKey={"file"}
        allowedFormats={[".png", ".jpg", ".jpeg"]}
      />

      {/* <ImageM2MContent
        storeName={storeName}
        fieldKey={"completion_files"}
        method={method}
        title="Bilder på avhjälpt fel"
        fileKey={"file"}
        allowedFileFormats={[".png", ".jpg", ".pdf"]}
        contentKey={"description"}
      /> */}
    </>
  );
};
