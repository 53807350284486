import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";

import { usePermissionCheck } from "../../store/base";
import {
  useInspectionErrand,
  constants,
  update,
  overviewUrl,
  executeUrl,
} from "../../store/inspectionErrands";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

import ErrandOverview from "./detail/Overview";
import ErrandReport from "./detail/Report";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { ErrandStatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ModalForm from "../../components/Forms/InspectionErrand/ChapterForm/ModalForm";
import { getErrandStatus } from "../../components/utils/errandUtils";
import getActions from "../../components/Details/InspectionErrand/infoActions";

import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import InspectionErrandDelegationForm from "../../components/Forms/InspectionErrand/DelegateErrandChapterForm/ModalForm";
import InspectionErrandCosts from "./detail/Costs";
import Protocol from "./detail/Protocol";

export default function MainInspectionErrandsDetail() {
  const dispatch = useDispatch();

  const { inspectionErrandId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace, push } = useHistory();
  const userId = useSelector((state) => state.app.userId);
  const userType = useSelector((state) => state.app.user?.user_type);

  const [inspectionErrand] = useInspectionErrand(inspectionErrandId);

  const [editErrandOpen, setEditErrandOpen] = React.useState(false);
  const [delegateErrandOpen, setDelegateErrandOpen] = React.useState(false);
  const [pausedErrandOpen, setPausedErrandOpen] = React.useState(false);
  const [confirmExecuteOpen, setConfirmExecuteOpen] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  usePermissionRedirect(["view_can_inspectionerrand", "allow_errand_faults"]);

  const canEditErrand = usePermissionCheck("change_can_inspectionerrand");
  const canDeleteErrand = usePermissionCheck("delete_can_inspectionerrand");

  const SUB_PAGES = [
    {
      path: "/errand",
      label: "Besiktning",
      component: ErrandOverview,
    },
    {
      path: "/protocol",
      label: "Protokoll",
      component: Protocol,
    },
  ];

  if (inspectionErrand?.status === 3 || inspectionErrand?.status === 4) {
    SUB_PAGES.push({
      path: "/report",
      label: "Resultat",
      component: ErrandReport,
    });
  }

  if (inspectionErrand?.status !== 0 && inspectionErrand) {
    SUB_PAGES.push({
      path: "/costs",
      label: `Kostnader (${inspectionErrand?.costs?.length || 0})`,
      component: InspectionErrandCosts,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const canUserExecuteReportErrand = () => {
    let currentPerformer = false;
    if (inspectionErrand?.performer) {
      currentPerformer = inspectionErrand?.performer?.user?.id;
    }

    //Errand already done
    if (inspectionErrand?.status === 3 || inspectionErrand?.status === 4) {
      return false;
    }

    if (!canEditErrand) {
      return false;
    }
    if (userType === 0 || userType === 1) {
      return canEditErrand;
    }
    if (currentPerformer === userId) {
      return canEditErrand;
    }
  };

  const canUserEditErrand = () => {
    let currentPerformer = false;
    if (inspectionErrand?.performer) {
      currentPerformer = inspectionErrand?.performer?.user?.id;
    }

    if (!canEditErrand) {
      return false;
    }
    if (userType === 0 || userType === 1) {
      return canEditErrand;
    }
    if (currentPerformer === userId) {
      return canEditErrand;
    }
  };

  const canDelegateErrand = () => {
    //errand already done
    if (inspectionErrand?.status === 3 || inspectionErrand?.status === 4) {
      return false;
    }

    //is admin or standarduser with correct permissions
    if (userType === 0 || userType === 1) {
      return canEditErrand;
    }
  };

  const onExecute = () => {
    if (inspectionErrand?.status === 2) {
      setPausedErrandOpen(true);
    } else {
      setConfirmExecuteOpen(true);
    }
  };

  const setErrandPaused = () => {
    setUpdateLoading(true);

    dispatch(
      update({
        id: inspectionErrandId,
        forceData: {
          status: 2,
        },
        successCallback: () => setUpdateLoading(false),
        errorCallback: () => setUpdateLoading(false),
      })
    );
  };

  const setErrandActive = ({ startExecute }) => {
    setUpdateLoading(true);

    dispatch(
      update({
        id: inspectionErrandId,
        forceData: {
          status: 1,
        },
        successCallback: () => {
          setUpdateLoading(false);
          if (startExecute) {
            push(executeUrl({ id: inspectionErrandId }));
          }
        },
        errorCallback: () => setUpdateLoading(false),
      })
    );
  };

  const resumeAndExecuteErrand = () => {
    setErrandActive({ startExecute: true });
  };

  let handleActions = getActions({
    disableExecute: !canUserExecuteReportErrand(),
    disableDelete: !canDeleteErrand,
    disableEdit: !canUserEditErrand(),
    disableDelegate: !canDelegateErrand(),
    onDelegate: () => setDelegateErrandOpen(true),
    onExecute,
    onEdit: () => setEditErrandOpen(true),
    onDelete: () => setDeleteModalOpen(true),
    setErrandPaused,
    setErrandActive,
    status: inspectionErrand?.status,
  });

  const { status, display } = getErrandStatus({
    status: inspectionErrand?.status,
    plannedStart: inspectionErrand?.planned_start,
    plannedEnd: inspectionErrand?.planned_end,
    actualStart: inspectionErrand?.execute_start,
    actualEnd: inspectionErrand?.execute_end,
  });

  const renderInfoPills = () => {
    return (
      <>
        <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>
      </>
    );
  };

  return (
    <>
      {updateLoading && <OverlaySpinner />}

      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={inspectionErrand}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      {canEditErrand && (
        <ModalForm
          method="PATCH"
          id={inspectionErrand?.id}
          instance={inspectionErrand}
          isOpen={editErrandOpen}
          onCheckout={() => setEditErrandOpen(false)}
        />
      )}

      {delegateErrandOpen && (
        <InspectionErrandDelegationForm
          method="PATCH"
          id={inspectionErrand?.id}
          instance={inspectionErrand}
          isOpen={delegateErrandOpen}
          onCheckout={() => setDelegateErrandOpen(false)}
        />
      )}

      <ConfirmationModal
        isOpen={pausedErrandOpen}
        title="Ärendet pausat"
        acceptTitle="Återuppta och utför ärendet"
        closeFunction={() => setPausedErrandOpen(false)}
        renderContent={() => {
          return (
            <div>
              Ärendet är pausat, klicka på "Återuppta och utför ärendet" för att
              starta igång och utföra.
            </div>
          );
        }}
        acceptCallback={resumeAndExecuteErrand}
      />

      <ConfirmationModal
        isOpen={confirmExecuteOpen}
        title="Påbörja besiktning"
        acceptTitle="Starta"
        closeFunction={() => setConfirmExecuteOpen(false)}
        renderContent={() => {
          return (
            <div>
              Är du säker på att du vill påbörja besiktningen? Klicka på
              "Starta" för att starta igång utförandet.
            </div>
          );
        }}
        acceptCallback={() => setErrandActive({ startExecute: true })}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Besiktning ${
            inspectionErrand?.str_representation || "laddar..."
          } - ${inspectionErrand?.errand_id || ""}`}
          breadCrumbs={[
            { url: "/inspection-errands", label: "Besiktningar" },
            {
              label:
                `${inspectionErrand?.str_representation} - ${inspectionErrand?.errand_id}` ||
                "Laddar...",
            },
          ]}
          eventContentType="errands.inspectionerrand"
          eventObjectId={inspectionErrandId}
          eventIdQueryStr="inspectionerrand"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
