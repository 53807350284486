import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import ErrorTable, {
  SYNC_ERROR_MAP,
} from "../../../components/Tables/ExternalSyncErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { overviewUrl as pipeOverviewUrl } from "../../../store/pipes";
import { ItemTitle } from "../../../components/Details/OverviewInfo/DetailInfo/styles";
import { NotificationDot } from "../../../components/Dashboard/styles";
import { useHistory } from "react-router-dom";
import { useExternalSyncErrorPaginationCount } from "../../../store/externalSyncErrors";
import { useApartmentAdsPaginationCount } from "../../../store/marketApartmentAds";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useLeadPerStageCount } from "../../../store/leads";

export default function MarketOverview() {
  const { push } = useHistory();

  const [leadsPerStageData, leadsPerStageDataLoading] = useLeadPerStageCount();
  const formattedLeadPerStageData = React.useMemo(() => {
    if (!leadsPerStageData) return null;
    const formatted = {};

    Object.keys(leadsPerStageData).forEach((key) => {
      const stage = leadsPerStageData[key];

      //get total leads
      const totalLeads = stage.confirmed + stage.denied + stage.latent;

      //group with stages with same name
      if (formatted[stage.name]) {
        formatted[stage.name] = formatted[stage.name] += totalLeads;
      } else {
        formatted[stage.name] = totalLeads;
      }
    });

    return formatted;
  }, [leadsPerStageData]);

  const [errorCount] = useExternalSyncErrorPaginationCount({
    filters: {
      api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
    },
  });

  const [apartmentConfirmedClosedCount, apartmentConfirmedClosedCountLoading] =
    useApartmentAdsPaginationCount({
      filters: {
        confirmed: true,
        closed: true,
      },
    });
  const [apartmentConfirmedCount, apartmentConfirmedCountLoading] =
    useApartmentAdsPaginationCount({
      filters: {
        confirmed: true,
        closed: false,
      },
    });
  const [apartmentActiveCount, apartmentActiveCountLoading] =
    useApartmentAdsPaginationCount({
      filters: {
        is_pending: false,
        confirmed: false,
        closed: false,
      },
    });
  const [apartmentPausedCount, apartmentPausedCountLoading] =
    useApartmentAdsPaginationCount({
      filters: {
        is_pending: true,
        confirmed: false,
        closed: false,
      },
    });

  const adsLoading =
    apartmentConfirmedClosedCountLoading ||
    apartmentConfirmedCountLoading ||
    apartmentActiveCountLoading ||
    apartmentPausedCountLoading;

  const errorQuery = {
    api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <div style={{ display: "flex" }}>
          <InnerBox style={{ flex: 1, marginRight: 12, marginBottom: 12 }}>
            {leadsPerStageDataLoading && <OverlaySpinner />}

            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Leads</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
              <TextButton
                title="Gå till projekt"
                iconType="arrow"
                iconPlacement="right"
                extraStyle={{ marginLeft: "auto", marginRight: 0 }}
                clicked={() => push(pipeOverviewUrl())}
              />
            </OverviewTitleWrapper>

            <div style={{ display: "flex" }}>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Inkomna</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {formattedLeadPerStageData?.["Inkomna"] || 0}
                </div>
              </InnerBox>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Intressanta</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {formattedLeadPerStageData?.["Intressanta"] || 0}
                </div>
              </InnerBox>
            </div>
            <div style={{ display: "flex" }}>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Kontaktade</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {formattedLeadPerStageData?.["Kontaktade"] || 0}
                </div>
              </InnerBox>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Redo för signering</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {formattedLeadPerStageData?.["Redo för signering"] || 0}
                </div>
              </InnerBox>
            </div>
          </InnerBox>

          <InnerBox style={{ flex: 1, marginRight: 12, marginBottom: 12 }}>
            {adsLoading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Annonser</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
              <TextButton
                title="Gå till annonser"
                iconType="arrow"
                iconPlacement="right"
                extraStyle={{ marginLeft: "auto", marginRight: 0 }}
                clicked={() => push("/market/ads")}
              />
            </OverviewTitleWrapper>

            <div style={{ display: "flex" }}>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Aktiva</ItemTitle>
                <div style={{ fontSize: 18 }}>{apartmentActiveCount || 0}</div>
              </InnerBox>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Pausade</ItemTitle>
                <div style={{ fontSize: 18 }}>{apartmentPausedCount || 0}</div>
              </InnerBox>
            </div>
            <div style={{ display: "flex" }}>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Reserverad</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {apartmentConfirmedCount || 0}
                </div>
              </InnerBox>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Slutförda</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {apartmentConfirmedClosedCount || 0}
                </div>
              </InnerBox>
            </div>
          </InnerBox>
        </div>

        <InnerBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle
                small
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                Felmeddelanden{" "}
                <NotificationDot>
                  {errorCount > 99 ? "99+" : errorCount || 0}
                </NotificationDot>
              </OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <ErrorTable
            {...{
              persistantQuery: errorQuery,
            }}
          />
        </InnerBox>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
