import { logout } from "../../app/actions";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import * as services from "./services";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const getCreatePatchForm = (id) => {
  const url = `${constants.CREATE_PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  preProcess,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    preProcess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    preProcess,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    forceData,
  });
};

export const createNewUser = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.CREATE_PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    preProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    unauthenticatedCall: true,
  });
};

export const resetPassword = ({
  username,
  email,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.resetPassword({ username, email });
      successCallback && successCallback();
      dispatch(logout);
    } catch (e) {
      console.error(e);
      errorCallback && errorCallback();
    }
  };
};

export const inviteUsers = ({ userIds, successCallback, errorCallback }) => {
  return async () => {
    try {
      await services.inviteUsers(userIds);
      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};
