export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INVOICES_INVOICE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INVOICES_INVOICE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INVOICES_INVOICE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INVOICES_INVOICE",
  DESTROY_FORM: "DESTROY_FORM_INVOICES_INVOICE",
  SET_FORM_ERROR: "SET_FORM_ERROR_INVOICES_INVOICE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INVOICES_INVOICE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INVOICES_INVOICE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INVOICES_INVOICE",
  RESET_STATE: "RESET_STATE_INVOICES_INVOICE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INVOICES_INVOICE",
  REMOVE_OBJECT: "REMOVE_OBJECT_INVOICES_INVOICE",
  CLEAR_FETCHED: "CLEAR_FETCHED_INVOICES_INVOICE",

  LIST_URL: "/accounting/invoices/invoice/list/",
  POST_URL: "/accounting/invoices/invoice/",
  PATCH_URL: "/accounting/invoices/invoice/",
  GET_URL: "/accounting/invoices/invoice/",

  STORE_NAME: "invoice",
};
