import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_CUSTOM_FORM_ERROR:
      const { key, message } = payload;
      newState.formErrors = {
        ...newState.formErrors,
        [key]: [message],
      };
      break;
  }

  return newState;

  // return baseReducer(state, action, constants);
};
