import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";

import { useParams } from "react-router";
import { useFilteredSensors } from "../../../store/IMDSensor";
import { useFilteredMeasureValues } from "../../../store/IMDMeasureValue";
import SensorGraphs from "../../IMD/SensorGraphs";
import { buildQueryString } from "../../../store/base";
import moment from "moment";
import { useParkingContract } from "../../../store/parkingContracts";

export default function ParkingIMD() {
  const { parkingContractId } = useParams();
  const [parkingContract] = useParkingContract(parkingContractId);

  const parkingSpotIds = parkingContract?.parking_spots?.map((a) => a.id);

  const [sensors, sensorsLoading] = useFilteredSensors(
    buildQueryString({ parking_spot__in: parkingSpotIds || [] })
  );

  const startDay = moment().subtract(90, "days");
  const [measureValues, measuresLoading] = useFilteredMeasureValues(
    sensorsLoading || !sensors
      ? null
      : buildQueryString({
          sensor__in: sensors.map((v) => v.id),
          for_date__gte: `${startDay.format("YYYY-MM-DD")} 00:00:00`,
        })
  );

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>IMD-konsumtion på objekt under avtal</OverviewTitle>
        </OverviewTitleWrapper>

        {!sensors?.length > 0 && (
          <InnerBox>
            Inga IMD-sensorer kopplade till objekt under detta avtal
          </InnerBox>
        )}

        {sensors?.length > 0 && (
          <SensorGraphs sensors={sensors} measureValues={measureValues} />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
