export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTAREPORTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTAREPORTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTAREPORTS",
  DESTROY_FORM: "DESTROY_FORM_BILLECTAREPORTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTAREPORTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTAREPORTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTAREPORTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTAREPORTS",
  RESET_STATE: "RESET_STATE_BILLECTAREPORTS",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTAREPORTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS__BILLECTAREPORTS",

  BILLING_REPORT_URL: "/accounting/gateways/billing_report/",
  PRODUCT_SALES_URL: "/accounting/gateways/product_sales/",
  INCOMING_PAYMENTS_URL: "/accounting/gateways/payments/incoming_payments/",
  INCOMING_PAYMENTS_EXPORT_URL: "/accounting/gateways/payments/export/",

  SET_DASHBOARD_BILLING_REPORT: "SET_DASHBOARD_BILLING_REPORT",
  SET_DASHBOARD_DUEDATE_REPORT: "SET_DASHBOARD_DUEDATE_REPORT",
  SET_ADVANCED_INCOMING_PAYMENTS:
    "SET_ADVANCED_INCOMING_PAYMENTS_BILLECTAREPORTS",
  SET_INCOMING_DASHBOARD_PAYMENTS:
    "SET_INCOMING_DASHBOARD_PAYMENTS_BILLECTAREPORTS",

  STORE_NAME: "billectaReports",
};
