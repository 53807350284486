import * as React from "react";
import { useParams } from "react-router";
import {
  BodyText,
  CardTitle,
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import { useReportErrand } from "../../../store/reportErrands";
import * as SC from "../../../components/Details/ReportErrand/styles";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { usePermissionCheck } from "../../../store/base";
import ExecuteModalForm from "../../../components/Forms/ReportErrand/ExecuteChapterForm/ModalForm";
import { cloneDeep } from "lodash";

function ErrandReport() {
  const { reportErrandId } = useParams();
  const [reportErrand, reportErrandLoading] = useReportErrand(reportErrandId);
  const [editExecuteInstance, setEditExecuteInstance] = React.useState({});
  const canEditErrand = usePermissionCheck("change_can_reporterrand");
  const [executeErrandOpen, setExecuteErrandOpen] = React.useState(false);

  const openImage = (src) => {
    window.open(src, "_blank").focus();
  };

  const renderDoneErrandImage = () => {
    if (!reportErrand?.active_rating?.file?.get)
      return (
        <BodyText style={{ marginTop: "12px" }}>Ärendet saknar bilder</BodyText>
      );

    return (
      <SC.ImageWrapper>
        <SC.Image
          onClick={() => openImage(reportErrand?.active_rating?.file?.get)}
          src={reportErrand?.active_rating?.file?.get}
        />
      </SC.ImageWrapper>
    );
  };

  const infoObj = {
    Detaljer: [
      {
        title: "Avhjälpt",
        value: (
          <StatusLabel state={reportErrand?.active_rating?.is_ok ? 1 : 6}>
            {" "}
            {reportErrand?.active_rating?.is_ok ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Kommentar kring ärende",
        value: reportErrand?.completion_comment
          ? reportErrand?.completion_comment
          : "Ingen kommentar lämnades",
      },
      {
        title: "Kommentar kring komponent",
        value: reportErrand?.active_rating?.note
          ? reportErrand?.active_rating.note
          : "Ingen kommentar lämnades",
      },
    ],
  };

  const handleEditExecute = () => {
    const reportErrandClone = cloneDeep(reportErrand);

    reportErrandClone.execution_rating = { ...reportErrandClone.active_rating };

    setEditExecuteInstance(reportErrandClone);
    setExecuteErrandOpen(true);
  };

  return (
    <>
      {canEditErrand && (
        <ExecuteModalForm
          method="PATCH"
          id={reportErrand?.id}
          isOpen={executeErrandOpen}
          instance={editExecuteInstance}
          onCheckout={() => setExecuteErrandOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Utfall av felanmälan</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
            {canEditErrand && (
              <PrimaryButton
                title="Redigera utfall"
                clicked={() => handleEditExecute()}
              />
            )}
          </OverviewTitleWrapper>

          <DetailInfo infoObj={infoObj} />

          <CardTitle small>Bild från utförandet</CardTitle>
          <SC.ImageContainer>{renderDoneErrandImage()}</SC.ImageContainer>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export default ErrandReport;
