import * as React from "react";
import Alert, { ALERT_TYPES } from "../Alert/Alert";
import Badge, { BADGE_TYPES } from "../Badge/Badge";
import DetailInfo from "../Details/OverviewInfo/DetailInfo/DetailInfo";
import { DateCell } from "../Displays";

export default function CanceledInfo({
  contract,
  confirmCancelCancellation,
  onEdit,
}) {
  const infoObj = {
    "Detaljer kring uppsägning": [
      {
        title: "Typ av uppsägning",
        value: (
          <Badge type={BADGE_TYPES.RED}>
            {contract?.status === 6
              ? "Uppsagt för avflyttning"
              : "Uppsagt för villkorsändring"}
          </Badge>
        ),
      },
      {
        title: "Uppsagt av",
        value: contract?.landlord_cancelled ? "Hyresvärd" : "Hyresgäst",
      },
      {
        title: "Datum för uppsägning",
        value: <DateCell date={contract?.closed_on} />,
      },
      {
        title: "Datum för avflytt",
        value: <DateCell date={contract?.closed_date} />,
        hidden: contract?.status !== 6,
      },
      {
        title: "Datum för villkorsändring",
        value: <DateCell date={contract?.closed_renew_date} />,
        hidden: contract?.status !== 7,
      },
      {
        title: "Uppsägning bekräftad",
        value: (
          <Badge
            state={
              contract?.closed_confirmed ? BADGE_TYPES.GREEN : BADGE_TYPES.RED
            }
          >
            {contract?.closed_confirmed ? "Bekräftad" : "Ej bekräftad"}
          </Badge>
        ),
      },
    ],
    "Anledning till uppsägning": [
      { title: contract?.closed_cause || "Ingen anledning angiven" },
    ],
  };

  return (
    <Alert
      type={ALERT_TYPES.DANGER}
      title={`Detta avtal är uppsagt`}
      primaryAction={confirmCancelCancellation}
      primaryTitle="Ångra uppsägning"
      secondaryAction={onEdit}
      secondaryTitle="Redigera uppsägning"
    >
      <div className="text-slate-800 -mb-[24px]">
        <DetailInfo infoObj={infoObj} />
      </div>
    </Alert>
  );
}
