import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "leasecontract", display: "Hyresavtal" },
    { value: "othercontract", display: "Avtal" },
    { value: "other", display: "Övrigt" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
