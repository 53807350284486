export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGSPECIFICATIONS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGSPECIFICATIONS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGSPECIFICATIONS",
  DESTROY_FORM: "DESTROY_FORM_PARKINGSPECIFICATIONS",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGSPECIFICATIONS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGSPECIFICATIONS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGSPECIFICATIONS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGSPECIFICATIONS",
  RESET_STATE: "RESET_STATE_PARKINGSPECIFICATIONS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGSPECIFICATIONS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PARKINGSPECIFICATIONS",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PARKINGSPECIFICATIONS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGSPECIFICATIONS",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGSPECIFICATIONS",
  LIST_URL: "/standard/parking/parkingspecification/list/",
  POST_URL: "/standard/parking/parkingspecification/",
  PATCH_URL: "/standard/parking/parkingspecification/",
  GET_URL: "/standard/parking/parkingspecification/",

  STORE_NAME: "parkingSpecifications",
};
