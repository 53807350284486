import * as React from "react";

export default () => {
  return (
    <div>
      Kontrollera att uppgifterna stämmer. Tryck sedan på "Importera mätvärden"
      för att genomföra importen.
    </div>
  );
};
