import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];

    // logo
    const logoFile = formInstance?.logo?._tempData?.data;
    const logoUrl = responseData?.logo?.put;
    //hello_image
    const helloImageFile = formInstance?.hello_image?._tempData?.data;
    const helloImageUrl = responseData?.hello_image?.put;

    if (logoFile && logoUrl) {
      urls.push(logoUrl);
      files.push(logoFile);
    }

    if (helloImageFile && helloImageUrl) {
      urls.push(helloImageUrl);
      files.push(helloImageFile);
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
