import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import AcceptInviteForm from "../../components/Forms/User/AcceptInviteForm";
import { useCreateUser } from "../../store/users/hooks/retrieve";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";

const isDev = window.location.origin.includes("demo");

export default () => {
  const { createToken } = useParams();
  const { replace } = useHistory();

  const [userData, userDataLoading, error] = useCreateUser(createToken);

  const Header = () => {
    return null;
    // <EuiPageHeader>
    //   <EuiPageHeaderSection>
    //     <EuiTitle size="m">
    //       <h1>{"Välkommen till Pigello fastighetssystem"}</h1>
    //     </EuiTitle>
    //     <div>
    //       Ange information om ditt användarkonto så är du snart igång!
    //     </div>
    //   </EuiPageHeaderSection>
    // </EuiPageHeader>
  };

  const ErrorHeader = () => (
    <>
      {/* <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle size="m">
            <h1>{"Inbjudan hittades inte"}</h1>
          </EuiTitle>
          <div>Kontakta systemadministratören och be om en ny inbjudan</div>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <div style={{ margin: "44px auto" }}>
        <strong>Har du redan skapat ett konto?</strong>
      </div>

      <PrimaryButton
        title={`Hyresgäst/BRF-medlem - Logga in i "Mina sidor"`}
        clicked={() => {
          window.location.replace(
            `https://${isDev ? "demo." : ""}portal.pigello.se/login`
          );
        }}
      />

      <div style={{ margin: "24px auto" }}>eller</div>

      <PrimaryButton
        title={`Hyresvärd - Logga in i Pigello Admin`}
        clicked={() => {
          replace("/login");
        }}
      /> */}
    </>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "60px",
          textAlign: "center",
        }}
      >
        {error ? (
          <>{ErrorHeader()}</>
        ) : (
          <>
            {Header()}
            <AcceptInviteForm method="PATCH" id={createToken} />
          </>
        )}
      </div>
    </>
  );
};
