import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";

import columnDefs from "./listDefs";

import {
  useChecklistPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/checklists";

export default function ChecklistTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  detailUrlMethod,
}) {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const redirectMethod =
    detailUrlMethod || ((obj) => detailUrl({ id: obj.id }));

  const filters = {};
  const badges = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "checklists_full_table"}
        title={"Checklistor"}
        onRowClickedWithDetail={(obj) => redirectMethod(obj)}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
