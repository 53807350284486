export default [
  {
    title: "Taggar",
    key: "TAG",
    hasError: false,
    fieldKeys: ["title", "color"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
