import baseReducer, {
  removeFromBackgroundTasks,
  removeFromProgress,
  INITIAL_STATE,
} from "../../base/store/reducer";
import { getHasSignedUpProcessName } from "../utils";
import constants from "./constants";

const STATE = {
  ...INITIAL_STATE,
  scriveHistory: {},
  hasSignedUp: undefined,
  canUse: undefined,
  hasAttempted: false,
};

export default (state = STATE, action) => {
  const { type, payload } = action;

  if (type === constants.INSERT_INTO_HISTORY) {
    return insertIntoHistory(state, payload.result, payload.name, payload.id);
  }
  if (type === constants.SET_HAS_SIGNED_UP) {
    return setHasSignedUp(state, { ...payload });
  }

  return baseReducer(state, action, constants);
};

const insertIntoHistory = (state, data, name, id) => {
  const inProgress = removeFromProgress(state.inProgress, name);
  const backgroundTasks = removeFromBackgroundTasks(
    state.backgroundTasks,
    name
  );
  return {
    ...state,
    scriveHistory: { ...state.scriveHistory, [id]: data },
    inProgress: inProgress,
    backgroundTasks: backgroundTasks,
  };
};

const setHasSignedUp = (state, value) => {
  const name = getHasSignedUpProcessName();
  const inProgress = removeFromProgress(state.inProgress, name);
  const backgroundTasks = removeFromBackgroundTasks(
    state.backgroundTasks,
    name
  );
  return {
    ...state,
    hasSignedUp: value.hasSignedUp,
    canUse: value.canUse,
    inProgress: inProgress,
    backgroundTasks: backgroundTasks,
    hasAttempted: true,
  };
};
