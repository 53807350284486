export const constructStrRep = (obj) => {
  return obj.str_representation || "Rapport";
};
export const overviewUrl = () => {
  return `/reports`;
};
export const detailUrlExcel = ({ id }) => {
  return `/reports/excel/detail/${id}`;
};
export const detailUrlPowerPoint = ({ id }) => {
  return `/reports/powerpoint/detail/${id}`;
};
export const availableReportsUrl = () => {
  return `/reports/create/available-reports`;
};

export const createExcelReportUrl = ({ category, id }) => {
  return `/reports/create-excel/${category}/${id}`;
};
export const createPowerpointReportUrl = ({ category, id }) => {
  return `/reports/create-powerpoint/${category}/${id}`;
};

export const viewExcelReportUrl = ({ reportUrl, id }) => {
  const encodedUrl = `${encodeURIComponent(reportUrl)}`;

  return `/reports/view/excel?report_url=${encodedUrl}${
    id ? `&report_id=${id}` : ""
  }`;
};
export const viewPowerPointReportUrl = ({ reportUrl, id }) => {
  const encodedUrl = `${encodeURIComponent(reportUrl)}`;

  return `/reports/view/powerpoint?report_url=${encodedUrl}${
    id ? `&report_id=${id}` : ""
  }`;
};

export const CATEGORY_MAPPER = {
  standard: "Basrapporter",
  invoicing: "Faktureringsrapporter",
  imd: "IMD-rapporter",
  brf: "BRF-rapporter",
  errands: "Teknisk förvaltning",
};
