import * as React from "react";

import { DatePicker, RadioGroup } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/serviceContracts";
import { useFormInstanceField } from "../../../../../store/base";
import moment from "moment";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const isSigned = useFormInstanceField({
    storeName,
    fieldKey: "is_signed",
  });

  return (
    <>
      <RadioGroup
        storeName={storeName}
        fieldKey="is_signed"
        title="Signeringsstatus"
        description="Ange om avtalet är signerat eller ej"
        method={method}
        forceHorizontalToolTipPlacement="left"
        forceVerticalToolTipPlacement="top"
        defaultValue={false}
        options={[
          {
            label: "Avtalet är signerat",
            value: true,
          },
          { label: "Avtalet är ej signerat", value: false },
        ]}
      />

      {isSigned && (
        <DatePicker
          storeName={storeName}
          title="Signeringsdatum"
          description="Ange datumet då avtalet signerades"
          fieldKey="signed_date"
          method={method}
          maxDate={moment().toDate()}
        />
      )}
    </>
  );
};
