import baseReducer from "../../base/store/reducer";
import constants from "./constants";

const INITIAL_STATE = {
    currentConfigCenter: {},
    all: {},
    allCopy: {},
    inProgress: [],
    paginations: {},
    paginationsCopy: {},
    filtered: {},
    filteredCopy: {},
    backgroundTasks: {},
    hasFetchedAll: false,
  
    forms: {},
    formInstance: {},
    formErrors: {},
  };

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;


    let newState =  baseReducer(state, action, constants);
    
    switch (type) {
    case constants.GET_CURRENT_CONFIGCENTER: {
        return {
          ...newState,
          currentConfigCenter: payload,
        };
      }

    case constants.SET_CURRENT_CONFIGCENTER: {
        return {
            ...newState,
            currentConfigCenter: payload.configCenter,
        }
    }
  
      default:
        return newState;
    }
  };
  