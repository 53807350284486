import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import BasicTable from "../../../components/Billecta/Table/BasicTable";
import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { clearFetched, useAllPermissionCheck } from "../../../store/base";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ElvacoTable from "../../../components/Tables/ElvacoGateways/FullTable";
import {
  createGateWay,
  constants,
  useElvacoGateway,
  updateGateWay,
  useFilteredElvacoGateways,
} from "../../../store/elvaco";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

export default function ElvacoIntegration() {
  const dispatch = useDispatch();

  const [addGatewayOpen, setAddGatewayOpen] = React.useState(false);
  const [deletionOpen, setDeletionOpen] = React.useState(false);
  const [handleOpen, setHandleOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);

  const [gateWays] = useFilteredElvacoGateways("");
  const deleteGateway = gateWays?.find((g) => g.id == deletionOpen);

  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [hostname, setHostname] = React.useState("");
  const [hostport, setHostport] = React.useState("");
  const [title, setTitle] = React.useState("");

  const viewCan = useAllPermissionCheck([
    "allow_imd",
    "view_can_imd",
    "allow_elvaco",
  ]);
  const canAddImd = useAllPermissionCheck([
    "allow_imd",
    "add_can_imd",
    "allow_elvaco",
  ]);
  const canChangeImd = useAllPermissionCheck([
    "allow_imd",
    "change_can_imd",
    "allow_elvaco",
  ]);
  const canDeleteImd = useAllPermissionCheck([
    "allow_imd",
    "delete_can_imd",
    "allow_elvaco",
  ]);

  const clearInput = () => {
    setUsername("");
    setPassword("");
    setHostname("");
    setHostport("");
    setTitle("");
  };

  const performModalSave = () => {
    setLoading(true);
    const postData = {
      name: title,
      host: hostname,
      port: hostport,
      username,
      password,
    };
    dispatch(
      createGateWay({
        postData,
        successCallback: () => {
          setAddGatewayOpen(false);
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Gateway lades till",
              description: "Sensorer och mätdata hämtas automatiskt in",
            })
          );

          dispatch(clearFetched(constants, true));
          clearInput();
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Gateway kunde ej läggas till",
              description: "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };
  const performModalEditSave = () => {
    setLoading(true);
    const patchData = {
      id: editOpen,
      name: title,
      host: hostname,
      port: hostport,
      username,
      password,
    };
    dispatch(
      updateGateWay({
        patchData,
        successCallback: () => {
          setEditOpen(false);
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Gateway uppdaterades",
              description: "Sensorer och mätdata hämtas automatiskt in",
            })
          );

          dispatch(clearFetched(constants, true));
          clearInput();
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Gateway kunde ej uppdateras",
              description: "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const performModalClose = () => {
    setAddGatewayOpen(false);
    setEditOpen(false);

    clearInput();
  };

  const current = INTEGRATIONS.find((i) => i.title === "Elvaco");

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  const data = React.useMemo(() => {
    return gateWays;
  }, [gateWays]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Namn",
        accessor: "name",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },
      {
        Header: "Host",
        accessor: "host",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },
      {
        Header: "Port",
        accessor: "port",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },
    ],
    []
  );

  return (
    <>
      <DeleteModal
        isOpen={!!deletionOpen}
        instance={deleteGateway}
        constants={constants}
        closeFunction={() => setDeletionOpen(false)}
      />

      <StandardModal
        isOpen={addGatewayOpen}
        saveFunction={performModalSave}
        closeFunction={performModalClose}
        title={"Lägg till gateway"}
        canAccept={
          username != null &&
          username !== "" &&
          password != null &&
          password != "" &&
          hostname != null &&
          hostname != "" &&
          hostport != null &&
          hostport != "" &&
          title != null &&
          title != ""
        }
        withActionBar
      >
        {loading && <OverlaySpinner />}
        <NonConnectedTextInput
          id="name"
          value={title}
          onChange={setTitle}
          label={"Internt namn på gateway"}
          helpText="T.ex. Fastighetsbeteckningen för fastigheten gatewayen ligger i"
        />
        <NonConnectedTextInput
          id="hostname"
          value={hostname}
          onChange={setHostname}
          label={"Host"}
          helpText='Host-adressen för gateway. T.ex. "jaqu33919.dyndns.org". (OBS: Utan http://, enbart adressen)'
        />
        <NonConnectedTextInput
          id="port"
          value={hostport}
          onChange={setHostport}
          label={"Port"}
          helpText="Porten för gatewayen hos hosten. T.ex. 1438."
        />
        <NonConnectedTextInput
          id="username"
          value={username}
          onChange={setUsername}
          label={"Användarnamn"}
        />
        <NonConnectedTextInput
          id="password"
          value={password}
          onChange={setPassword}
          type="password"
          label={"Lösenord"}
        />
      </StandardModal>

      <StandardModal
        isOpen={editOpen}
        saveFunction={performModalEditSave}
        closeFunction={performModalClose}
        title={"Redigera gateway"}
        canAccept={
          username != null &&
          username !== "" &&
          password != null &&
          password != "" &&
          hostname != null &&
          hostname != "" &&
          hostport != null &&
          hostport != "" &&
          title != null &&
          title != ""
        }
        withActionBar
      >
        {loading && <OverlaySpinner />}
        <NonConnectedTextInput
          id="name"
          value={title}
          onChange={setTitle}
          label={"Internt namn på gateway"}
          helpText="T.ex. Fastighetsbeteckningen för fastigheten gatewayen ligger i"
        />
        <NonConnectedTextInput
          id="hostname"
          value={hostname}
          onChange={setHostname}
          label={"Host"}
          helpText='Host-adressen för gateway. T.ex. "jaqu33919.dyndns.org". (OBS: Utan http://, enbart adressen)'
        />
        <NonConnectedTextInput
          id="port"
          value={hostport}
          onChange={setHostport}
          label={"Port"}
          helpText="Porten för gatewayen hos hosten. T.ex. 1438."
        />
        <NonConnectedTextInput
          id="username"
          value={username}
          onChange={setUsername}
          label={"Användarnamn"}
        />
        <NonConnectedTextInput
          id="password"
          value={password}
          onChange={setPassword}
          type="password"
          label={"Lösenord"}
        />
      </StandardModal>

      <StandardModal
        isOpen={handleOpen}
        closeFunction={() => setHandleOpen(false)}
        title={"Hantera gateway"}
        small
      >
        <OverviewTitleWrapper>
          <OverviewTitle>Hantera gateway</OverviewTitle>
        </OverviewTitleWrapper>
        {canChangeImd && (
          <div style={{ marginTop: 12 }}>
            <TextButton
              title="Redigera gateway"
              clicked={() => {
                const handleId = handleOpen;
                const editObj = gateWays?.find((g) => g.id == handleId);
                setUsername(editObj.username);
                setPassword(editObj.password);
                setHostname(editObj.host);
                setHostport(editObj.port);
                setTitle(editObj.name);
                setEditOpen(handleId);
                setHandleOpen(false);
              }}
              iconType="edit"
              iconPlacement="right"
            />
          </div>
        )}

        {canDeleteImd && (
          <TextButton
            extraStyle={{ marginTop: 12 }}
            red
            title="Radera gateway"
            clicked={() => {
              const deleteId = handleOpen;
              setDeletionOpen(deleteId);
              setHandleOpen(false);
            }}
            iconType="close"
            iconPlacement="right"
          />
        )}
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Elvacos hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Elvaco-integrationen ställs in per gateway (basstation/server)
                  ni har. När en gateway väl är kopplad så hämtas mätvärden och
                  sensorer automatiskt in från gatewayen till Pigello.
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Aktivera Elvaco-integrationen genom att koppla ihop era
                  gateways till Pigello. Tryck på{" "}
                  <strong>Lägg till gateway</strong> för att göra detta.
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Sensorer och mätvärden hämtas löpande in från Elvaco.
                  <br />
                  <strong>Steg 3:</strong>
                  <br />
                  Koppla ihop sensorerna från Elvaco mot objekt i Pigello för
                  att matcha mätvärden mot lägenheter, lokaler och
                  fordonsplatser.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hantera integration</OverviewTitle>
                  {viewCan && (
                    <OverviewSubtitle>
                      Lägg till en gateway genom att trycka på "Lägg till
                      gateway". Tillagda gateways hittas längre ner på sidan.
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {canAddImd ? (
                <PrimaryButton
                  title="Lägg till gateway"
                  clicked={() => setAddGatewayOpen(true)}
                />
              ) : (
                <InnerBox>
                  Behörighet saknas för att hantera denna integration
                </InnerBox>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>

          {viewCan && (
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Gateways</OverviewTitle>
                  <OverviewSubtitle>
                    Klicka på en gateway för att redigera/radera den
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <BasicTable
                data={data ?? []}
                columns={columns}
                onRowClicked={(row) => {
                  if (canDeleteImd || canChangeImd) {
                    setHandleOpen(row.original.id);
                  } else {
                    dispatch(
                      addToast({
                        type: TOAST_TYPES.INFO,
                        title: "Behörighet saknas",
                      })
                    );
                  }
                }}
                tableId="elvacotable"
              />
            </DetailPageBox>
          )}
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
