import * as React from "react";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  destroyPatchForm,
  destroyPostForm,
  update,
  detailUrl,
} from "../../../../store/tenantPortalSettings";
import { constants as realestateConstants } from "../../../../store/realEstates";
import renderChapter from "./renderChapter";
import {
  clearFetched,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { useTenantPortalSettingsForm } from "../../../../store/tenantPortalSettings";
import { useHistory } from "react-router";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";

export default ({ method, id, isOpen, onCheckout, existingData }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { push } = useHistory();

  const formLoaded = Boolean(useTenantPortalSettingsForm(method, id));
  const verboseKey = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "verbose_key",
  });

  React.useEffect(() => {
    if (formLoaded && existingData) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: { ...existingData },
        })
      );
    }
  }, [formLoaded, isOpen]);

  React.useEffect(() => {
    if (method === "POST") {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: { color_code: "000000" },
        })
      );
    }
  }, [formLoaded]);

  const preProcessData = (data) => {
    const cloned = cloneDeep(data);

    if (method === "PATCH" && cloned?.apptus_sso_host != null && cloned?.apptus_sso_customer_name_field != null && cloned?.apptus_sso_hash_key === null) {
      cloned.apptus_sso_hash_key = undefined;
    }
    if (method === "PATCH" && cloned?.rco_sso_host != null && cloned?.rco_sso_customer_name_field != null && cloned?.rco_sso_hash_key === null) {
      cloned.rco_sso_hash_key = undefined;
    }

    return cloned
  }

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success, returnData) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
      if (success) {
        push(detailUrl({ id: returnData?.id }));
      }
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returnData) => {
    checkout(true, returnData);
    setLoading(false);
    dispatch(clearFetched(realestateConstants, true));
  };

  const onSubmit = () => {
    if (verboseKey?.includes("/")) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Felaktig URL-nyckel",
          description:
            "Läs instruktionerna och ändra URL-nyckeln till ett tillåtet format",
        })
      );
      return;
    }

    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          // preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          preProcess: preProcessData,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Skapa inställning" : "Uppdatera inställning"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
