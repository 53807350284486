export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTAACCOUNTINGREPORTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTAACCOUNTINGREPORTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTAACCOUNTINGREPORTS",
  DESTROY_FORM: "DESTROY_FORM_BILLECTAACCOUNTINGREPORTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTAACCOUNTINGREPORTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTAACCOUNTINGREPORTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTAACCOUNTINGREPORTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTAACCOUNTINGREPORTS",
  RESET_STATE: "RESET_STATE_BILLECTAACCOUNTINGREPORTS",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTAACCOUNTINGREPORTS",

  SET_REPORTS: "SET_REPORTS_BILLECTAACCOUNTINGREPORTS",

  STORE_NAME: "billectaAccountingReports",
};
