import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import GroupUserList from "../../components/Account/GroupUserList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../components/Displays";
import {
  PopupButton,
  PrimaryButton,
} from "../../components/Forms/Base/Buttons";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import RenderGroup from "../../components/Forms/UserGroup/Permissions/RenderGroup";
import WhitelistPermsForm from "../../components/Forms/WhitelistPerms/WhitelistPermsForm";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import StandardModal from "../../components/Modals/StandardModal";
import * as SharedStyles from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import {
  buildQueryString,
  updateActiveFormInstance,
  useAllPermissionCheck,
  useFormInstanceField,
} from "../../store/base";
import { useSupervisorCheck } from "../../store/base/hooks/permissions";
import {
  constants,
  useUserGroup,
  useUserGroupForm,
  update,
} from "../../store/userGroups";
import { useFilteredUsers } from "../../store/users";
import { performUpdate } from "../../store/whitelistPerms";
import PageSlider from "../Layouts/PageSlider/PageSlider";
import PageTitle from "../Layouts/PageTitle/PageTitle";

const TABS = {
  DETAILS: "Detaljer",
  USERS: "Användare",
};

const SUB_TABS = {
  PERMS: "Behörigheter",
  DETAILPERMS: "Detaljbehörigheter",
};

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { groupId } = useParams();
  const [group] = useUserGroup(groupId);
  const query = useQuery();

  const userQuery = buildQueryString({
    usergroup__id: groupId,
  });
  const [users] = useFilteredUsers(userQuery);

  const canChangeGroup = useAllPermissionCheck([
    "view_can_permissions",
    "change_can_permissions",
  ]);
  const canRemoveGroup = useAllPermissionCheck([
    "view_can_permissions",
    "delete_can_permissions",
  ]);

  const isSupervisor = useSupervisorCheck();

  const formLoaded = useUserGroupForm("PATCH", groupId);

  const [selectedTab, setSelectedTab] = React.useState("DETAILS");
  const [selectedSubTab, setSelectedSubTab] = React.useState("PERMS");

  const [warningModalOpen, setWarningModalOpen] = React.useState(false);
  const [detailPermWarningModalOpen, setDetailPermWarningModalOpen] =
    React.useState(false);

  const [deleteGroupOpen, setDeleteGroupOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [saveDetailPermsLoading, setSaveDetailPermsLoading] =
    React.useState(false);

  const [editPermsDisabled, setEditPermsDisabled] = React.useState(true);
  const [editDetailPermsDisabled, setEditDetailPermsDisabled] =
    React.useState(true);

  const instance = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "",
  });

  const handleConfirmEditPerms = () => {
    setEditPermsDisabled(false);
    setWarningModalOpen(false);
  };

  const handleConfirmEditDetailPerms = () => {
    setEditDetailPermsDisabled(false);
    setDetailPermWarningModalOpen(false);
  };

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({ storeName: constants.STORE_NAME, data: group })
    );
  }, [group]);

  const onSuccess = () => {
    setSaveDetailPermsLoading(false);
    setEditDetailPermsDisabled(true);
  };

  const onError = () => {
    setSaveDetailPermsLoading(false);
    setEditDetailPermsDisabled(true);
  };

  const handleSaveDetailPerms = () => {
    setSaveDetailPermsLoading(true);
    dispatch(
      performUpdate({ successCallback: onSuccess, errorCallback: onError })
    );
  };

  const handleSavePermissions = () => {
    setLoading(true);

    dispatch(
      update({
        id: instance.id,
        successCallback: () => {
          window.scrollTo(0, 0);
          setLoading(false);
          setEditPermsDisabled(true);
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  const deletedCallback = () => {
    push(`/configcenter/groups-and-users/group`);
  };

  React.useEffect(() => {
    const tab = query.get("tab");
    if (tab) {
      setSelectedTab(tab);
    }
  }, []);

  let actions = [];

  if (canChangeGroup || isSupervisor) {
    if (editPermsDisabled) {
      actions.push({
        name: "Redigera gruppbehörigheter",
        onClick: () => setWarningModalOpen(true),
      });
    }
    if (!editPermsDisabled)
      actions.push({
        name: "Spara ändringar",
        onClick: () => handleSavePermissions(),
      });
  }
  if (canRemoveGroup || isSupervisor)
    actions.push({
      name: "Ta bort grupp",
      onClick: () => setDeleteGroupOpen(true),
    });

  let detailActions = [];

  if (canChangeGroup || isSupervisor) {
    if (editDetailPermsDisabled) {
      detailActions.push({
        name: "Redigera detailjbehörigheter",
        onClick: () => setDetailPermWarningModalOpen(true),
      });
    }
    if (!editDetailPermsDisabled)
      detailActions.push({
        name: "Spara ändringar",
        onClick: () => handleSaveDetailPerms(),
      });
  }

  const breadCrumbs = [
    {
      label: "Användare & behörigheter",
      url: "/configcenter/groups-and-users/group",
    },
    {
      label: group ? group.name : "Laddar...",
    },
  ];

  return (
    <SharedStyles.BareLayoutWrapper>
      {(!group || loading || saveDetailPermsLoading) && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle
          title={`Grupp ${group?.name || ""}`}
          breadCrumbs={breadCrumbs}
        /> 
         <PageSlider
          {...{
            TABS,
            selectedTab,
            onTabSelected: (tab) => {
              setSelectedTab(tab);
            },
          }}
        />
      </SharedStyles.BareLayoutTitleWrapper>

      {selectedTab === "DETAILS" && (
        <SharedStyles.DetailPageBox>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "40px",
            }}
          >
            <PageSlider
              {...{
                TABS: SUB_TABS,
                selectedTab: selectedSubTab,
                onTabSelected: (tab) => setSelectedSubTab(tab),
              }}
            />
            <div>
              {selectedSubTab === "PERMS" && !!actions.length && (
                <PopupButton title="Hantera" actions={actions} />
              )}
              {selectedSubTab === "DETAILPERMS" && !!detailActions.length && (
                <PopupButton title="Hantera" actions={detailActions} />
              )}
            </div>
          </div>
          <DeleteModal
            isOpen={deleteGroupOpen}
            closeFunction={() => setDeleteGroupOpen(false)}
            constants={constants}
            instance={instance}
            deletedCallback={deletedCallback}
          />
          <StandardModal
            isOpen={warningModalOpen}
            closeFunction={() => setWarningModalOpen(false)}
            saveFunction={() => handleConfirmEditPerms()}
            title="Redigering av behörigheter"
            withActionBar
            actionBarAcceptTitle="Fortsätt med redigering"
            actionBarCancelTitle="Avbryt"
          >
            <InfoBox
              title="OBS"
              text="Du editerar nu behörigheter för denna grupp, tänk på att ändringar som genomförs påverkar alla användare i gruppen."
            />
          </StandardModal>

          <StandardModal
            isOpen={detailPermWarningModalOpen}
            closeFunction={() => setDetailPermWarningModalOpen(false)}
            saveFunction={() => handleConfirmEditDetailPerms()}
            title="Redigering av detaljbehörigheter"
            withActionBar
            actionBarAcceptTitle="Fortsätt med redigering"
            actionBarCancelTitle="Avbryt"
          >
            <InfoBox
              title="OBS"
              text="Du editerar nu detaljbehörigheter för denna grupp, tänk på att ändringar som genomförs påverkar alla användare i gruppen."
            />
          </StandardModal>
          {selectedSubTab === "PERMS" && (
            <>
              {instance && (
                <RenderGroup group={instance} disabled={editPermsDisabled} />
              )}
              <div
                style={{
                  margin: 12,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {!editPermsDisabled && (
                  <PrimaryButton
                    newDesign
                    title="Spara"
                    clicked={() => handleSavePermissions()}
                  />
                )}
              </div>
            </>
          )}

          {selectedSubTab === "DETAILPERMS" && (
            <>
              {group && (
                <WhitelistPermsForm
                  group={group}
                  isLoading={saveDetailPermsLoading}
                  disabled={editDetailPermsDisabled}
                />
              )}
              <div
                style={{
                  margin: 12,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {!editDetailPermsDisabled && (
                  <PrimaryButton
                    newDesign
                    title="Spara detaljbehörigheter"
                    clicked={() => handleSaveDetailPerms()}
                  />
                )}
              </div>
            </>
          )}
        </SharedStyles.DetailPageBox>
      )}

      {selectedTab === "USERS" && (
        <SharedStyles.DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Användare i grupp</OverviewTitle>
              <OverviewSubtitle>
                Dessa användare finns i denna grupp, för att ta bort eller lägga
                till nya användare, navigera till den specifika användarens
                detaljsida.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <GroupUserList groupId={groupId} />
        </SharedStyles.DetailPageBox>
      )}
    </SharedStyles.BareLayoutWrapper>
  );
};
