import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import ServicePartnerTable from "../../../components/Tables/ServicePartners/FullTable";

import useActiveFilters from "../../../hooks/useActiveFilters";

export default function ServicePartners() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantPartnerQuery = {
    realestate__in: filteredRealEstates,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Servicepartners</OverviewTitle>
          </OverviewTitleWrapper>

          <ServicePartnerTable persistantQuery={persistantPartnerQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
