import * as React from "react";

import { Select } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketParkingAds";

import { useMarketingRequirementsPaginationCount } from "../../../../../store/marketRequirements";

import { InfoBox } from "../../../../Displays";
import { Link } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import MarketRequirementsTable from "src/components/Tables/MarketRequirements/FullTable";
import PipesTable from "src/components/Tables/Pipes/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const [marketingRequirementsCount, marketingRequirementsCountLoading] =
    useMarketingRequirementsPaginationCount();
  const hasRequirements =
    !marketingRequirementsCountLoading && marketingRequirementsCount > 0;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för intressenter</OverviewTitle>
          <OverviewSubtitle>
            Ställ in kravprofil samt hur leads ska hanteras
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <Select
        fieldKey="pilot_mode"
        title="Hur ska ansökningar hanteras?"
        {...{ storeName, method }}
      />

      {!hasRequirements && (
        <>
          <InfoBox
            title="Kravprofiler saknas"
            boxTheme="warning"
            renderText={() => (
              <>
                För att kunna skapa en annons krävs det att den har en
                kravprofil kopplad till sig. Gå till{" "}
                <Link to="/market/requirements">
                  Annonscenter &gt; Kravprofiler
                </Link>{" "}
                för att lägga till.
              </>
            )}
          />
        </>
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          TableComponent={MarketRequirementsTable}
          storeName={storeName}
          disabled={!hasRequirements}
          method={method}
          fieldKey="requirement"
          title="Kravprofil för annons"
          placeholder="Välj kravprofil..."
          description={
            "Kravprofiler bestämmer vad som krävs för att kunna anmäla intresse för annonsen. Läggs till i Annonscentret"
          }
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          TableComponent={PipesTable}
          storeName={storeName}
          disabled={!hasRequirements}
          method={method}
          fieldKey="dump_in_pipe"
          title="Projekt för uppsamling av intressenter"
          placeholder="Välj projekt..."
          description={
            "När en lead visar intresse eller ställer sig i kö för denna annons så hamnar de i detta projekt"
          }
        />
      </div>
    </>
  );
};
