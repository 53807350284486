import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { NumberInput, RadioGroup, TableSelect } from "../../../Base/Fields";

// store, states
import { constants } from "../../../../../store/invoicingCompany";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Standardinställningar</FormAreaTitle>

      <NumberInput
        title="Administrationsavgift för utskick av avi"
        {...{
          storeName,
          method,
          fieldKey: `admin_fee`,
        }}
      />

      <RadioGroup
        title="Använd öresavrundning på avier"
        options={[
          { label: "Använd öresavrundning", value: true },
          { label: "Använd ej öresavrundning", value: false },
        ]}
        defaultValue={true}
        {...{
          storeName,
          method,
          fieldKey: `use_cent_rounding`,
        }}
      />

      <RadioGroup
        title="Visa tidigare obetalda avier på avi"
        options={[
          { label: "Visa på avier", value: true },
          { label: "Visa ej på avier", value: false },
        ]}
        defaultValue={true}
        {...{
          storeName,
          method,
          fieldKey: `show_unpaid_next`,
        }}
      />

      <RadioGroup
        title="Nyttja kundsaldo på avier"
        options={[
          { label: "Nyttja kundsaldo", value: true },
          { label: "Nyttja ej kundsaldo", value: false },
        ]}
        defaultValue={true}
        {...{
          storeName,
          method,
          fieldKey: `use_debtor_balance`,
        }}
      />
    </>
  );
};
