import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "office", display: "Kontor" },
    { value: "shop", display: "Butik" },
    { value: "industrial", display: "Industri" },
    { value: "warehouse", display: "Lagerlokal" },
    { value: "restaurant", display: "Restaurang" },
    { value: "education", display: "Utbildning" },
    { value: "bakery", display: "Bageri" },
    { value: "barber", display: "Frisör" },
    { value: "healt_care", display: "Sjukvård och Hälsa" },
    { value: "meeting_room", display: "Mötesrum" },
    { value: "food", display: "Livsmedelsindustri" },
    { value: "hotel", display: "Hotell" },
    { value: "other", display: "Övrigt" },
    { value: "ground", display: "Mark" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
