export default () => [
  {
    title: "Integration",
    key: "INTEGRATION",
    visited: true,
    hasError: false,
    fieldKeys: [
      "email",
      "password",
      "realestate",
      "client_id",
      "client_secret",
      "sync_active",
    ],
  },

  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
