import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import {
  useIndexSettingForm,
  constants,
  create,
  update,
  detailUrl,
  destroyPatchForm,
  destroyPostForm,
} from "../../../../store/indexsetting";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import PrimaryBtn from "../../Base/Buttons/PrimaryBtn";
import DateSelect from "../../Base/Fields/DateSelect";
import LocalRadioGroupField from "../../Base/Fields/LocalRadioGroupField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import TextInputField from "../../Base/Fields/TextInputField";
import Modal from "../../Base/Modals/Modal";

import {
  constants as productConstants,
  performFilter as productPerformFilter,
  useInvoicingProductPagination,
} from "../../../../store/invoicingProducts";
import productListDefs from "../../../Tables/InvoicingProducts/listDefs";
import {
  constants as tableConstants,
  performFilter as tablePerformFilter,
  useIndexTablePagination,
} from "../../../../store/indexTable";
import tableListDefs from "../../../Tables/IndexTable/listDefs";
import CheckField from "../../Base/Fields/CheckField";
import SelectField from "../../Base/Fields/SelectField";
import {
  setActiveFormInstance,
  useFormInstanceField,
  updateActiveFormInstance,
} from "../../../../store/base";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import IndexTablesTable from "src/components/Tables/IndexTable/FullTable";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";

export const INDEX_TYPES = {
  SET: "SET",
  CUSTOM_DYNAMIC: "CUSTOM_DYNAMIC",
  DYNAMIC: "DYNAMIC",
};

export const decideSettingType = (setting) => {
  if (setting?.index_quota) return "SET";

  if (setting?.table) return "CUSTOM_DYNAMIC";

  return "DYNAMIC";
};

export const INDEX_ALTERNATIVES = [
  {
    title: "Fast indexuppräkning",
    description: "Fast ökning varje år",
    value: INDEX_TYPES.SET,
  },
  {
    title: "Dynamisk utifrån KPI 1980 (Oktober)",
    description: "Dynamisk utifrån SCB:s tabell",
    value: INDEX_TYPES.DYNAMIC,
  },
  {
    title: "Dynamisk utifrån egen tabell",
    description: "Anpassad tabell med egna värden",
    value: INDEX_TYPES.CUSTOM_DYNAMIC,
  },
];

export default function IndexSettingModalForm({
  method,
  id,
  closeFunction,
  instance,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const formLoaded = useIndexSettingForm(method, id);
  const { push } = useHistory();

  const [actionLoading, setActionLoading] = React.useState(false);
  const [selectedAlternative, setSelectedAlternative] = React.useState(null);

  React.useEffect(() => {
    if (instance) {
      const type = decideSettingType(instance);

      if (method === "PATCH") {
        setSelectedAlternative(type);
      }
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }

    return () => {
      dispatch(destroyPatchForm());
      dispatch(destroyPostForm());
    };
  }, []);

  const includeCostTitle = useFormInstanceField({
    storeName,
    fieldKey: "include_cost_title",
  });
  const useCostProduct = useFormInstanceField({
    storeName,
    fieldKey: "use_cost_product",
  });

  const preProcess = (data) => {
    let clone = cloneDeep(data);

    if (!clone.initial_zero_value_months) {
      clone.initial_zero_value_months = 0;
    }

    return clone;
  };

  const onSubmit = () => {
    setActionLoading(true);

    if (method === "POST") {
      dispatch(
        create({
          successCallback: (_, returned) => {
            setActionLoading(false);

            push(detailUrl({ id: returned.id }));
            closeFunction();
          },
          errorCallback: () => {
            setActionLoading(false);
          },
          preProcess,
        })
      );
    } else {
      dispatch(
        update({
          id,
          successCallback: (_, returned) => {
            setActionLoading(false);
            closeFunction();
          },
          errorCallback: () => {
            setActionLoading(false);
          },
          preProcess,
        })
      );
    }
  };

  const clearIndexSetting = () => {
    dispatch(
      setActiveFormInstance({
        storeName,
        data: {
          index_amount: 100,
          index_quota: null,
          final_rounding_mode: 2,
          index_around_month: 1,
          include_cost_title: true,
          use_cost_product: true,
        },
      })
    );

    setSelectedAlternative(null);
  };

  const loading = !formLoaded || actionLoading;
  return (
    <Modal
      closeFunction={closeFunction}
      title={
        method === "POST"
          ? "Skapa ny indexinställning"
          : "Uppdatera indexinställning"
      }
      acceptTitle={"Spara"}
      onAccept={onSubmit}
    >
      {loading && <OverlaySpinner />}

      {!selectedAlternative && (
        <div className="mb-6">
          <LocalRadioGroupField
            value={selectedAlternative}
            onChange={(val) => setSelectedAlternative(val)}
            required
            title="Typ av indexinställning"
            description="Välj hur denna indexinställning ska räkna upp debiteringsrader"
            options={INDEX_ALTERNATIVES}
            id="_type"
          />
        </div>
      )}

      {selectedAlternative === INDEX_TYPES.SET && (
        <>
          <div className="mb-6">
            <PrimaryBtn secondary onClick={clearIndexSetting}>
              <ArrowLeftIcon width={16} className="mr-1" /> Byt typ av index
            </PrimaryBtn>
          </div>

          <div className="mb-6 text-base font-medium">Fast indexuppräkning</div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Namn på inställning"
              fieldKey="title"
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Andel som räknas upp i %"
              description="Andel av debiteringsraden som räknas upp i %."
              fieldKey="index_amount"
              {...{ method, storeName }}
            />
            <TextInputField
              title="Fast uppräkning i %"
              description="Uppräkning i %."
              fieldKey="index_quota"
              {...{ method, storeName }}
            />
          </div>
        </>
      )}

      {(selectedAlternative === INDEX_TYPES.DYNAMIC ||
        selectedAlternative === INDEX_TYPES.CUSTOM_DYNAMIC) && (
        <>
          <div className="mb-6">
            <PrimaryBtn secondary onClick={clearIndexSetting}>
              <ArrowLeftIcon width={16} className="mr-1" /> Byt typ av index
            </PrimaryBtn>
          </div>

          <div className="mb-6 text-base font-medium">
            Dynamisk utifrån{" "}
            {selectedAlternative === INDEX_TYPES.DYNAMIC
              ? "KPI 1980 (Oktober)"
              : "egen indextabell"}
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Namn på inställning"
              fieldKey="title"
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {selectedAlternative === INDEX_TYPES.CUSTOM_DYNAMIC && (
              <TableSelectField
                description="Tabell som värden för uppräkning ska hämtas ifrån."
                storeName={storeName}
                fieldKey="table"
                method={method}
                placeholder="Välj indextabell"
                title="Indextabell"
                TableComponent={IndexTablesTable}
              />
            )}
            <TextInputField
              title="Andel som räknas upp i %"
              description="Andel av debiteringsraden som räknas upp i %."
              fieldKey="index_amount"
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Minimal indexkvot i %"
              description="Om den egentliga årliga uppräkningen är lägre än denna kommer denna att användas istället. Den årliga uppräkningen innebär differensen mellan kvoten för innevarande indexperiod och den tidigare indexperioden."
              fieldKey="index_min_val"
              {...{ method, storeName }}
            />
            <TextInputField
              title="Maximal indexkvot i %"
              description="Om den egentliga årliga uppräkningen är högre än denna kommer denna att användas istället. Den årliga uppräkningen innebär differensen mellan kvoten för innevarande indexperiod och den tidigare indexperioden."
              fieldKey="index_max_val"
              {...{ method, storeName }}
            />
            <TextInputField
              title="Minsta förändring i enheter"
              description="Antal enheter ett KPI-värde måste ändras med för att en uppräkning ska göras, 0.01 tillåts, 3.0 tillåts. En ändring från 300,0 till 303,0 är 3 enheter. En ändring från 300,0 till 302,6 är alltså mindre än 3 enheter och resulterar då ej i en ny uppräkning."
              fieldKey="index_min_points"
              {...{ method, storeName }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              isNumber
              title="Antal decimaler vid beräkning av KPI"
              placeholder="17 (Lämna tom för standard)"
              description="Ange hur många decimaler som används vid beräkning med KPI-värde. Anges inget används 17 decimaler som standard."
              fieldKey="calculate_with_decimals"
              {...{ method, storeName }}
            />

            <SelectField
              title="Metod för avrundning av KPI"
              description="Ange hur beräknat värde ska avrundas. Anges inget avrundas ej värdet."
              fieldKey="final_rounding_mode"
              {...{ method, storeName }}
            />
          </div>

          <div className="mb-6">
            <CheckField
              description="Om detta är aktivt kan indexuppräkningen leda till en nedjustering av debiteringsraden."
              title="Tillåt sänkning"
              fieldKey="allow_reduction"
              {...{ method, storeName }}
            />
          </div>
        </>
      )}

      {selectedAlternative != null && (
        <>
          <hr />
          <div className="mb-6 text-base font-medium">Datum & Perioder</div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <DateSelect
              description="Lämna tom om systemet ska räkna ut basåret. Basår angett på avtalet som debiteringsraden ligger på skriver över detta värde."
              title="Basår"
              showYearPicker
              fieldKey="index_base_date"
              {...{ method, storeName }}
            />

            {selectedAlternative === INDEX_TYPES.CUSTOM_DYNAMIC && (
              <SelectField
                description="Basmånad som ska användas för beräkning av indexuppräkning. Anges inget används Oktober enligt standard."
                title="Basmånad"
                placeholder="Oktober"
                fieldKey="index_base_month"
                forceChoices={MONTHS_OPTIONS}
                {...{ method, storeName }}
              />
            )}
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <SelectField
              description="Ange vilken månad som ska användas som brytpunkt för det nya indexåret. Anges inget används Januari som standard."
              title="Startmånad för nytt indexår"
              fieldKey="index_around_month"
              forceChoices={MONTHS_OPTIONS}
              {...{ method, storeName }}
            />

            <TextInputField
              isNumber
              title="Antal nådemånader"
              description="Ange antal månader från det att index börjar gälla första gången till att det ska debiteras."
              fieldKey="initial_zero_value_months"
              step={1}
              {...{ method, storeName }}
            />
          </div>

          <hr />
          <div className="mb-6 text-base font-medium">
            Avisering & Bokföring
          </div>

          <div className="mb-6">
            <CheckField
              description="Visa vilken debiteringsrad som indexuppräkningen kommer ifrån på avier."
              title="Visa källa på avier"
              fieldKey="include_cost_title"
              {...{ method, storeName }}
            />
          </div>
          {!includeCostTitle && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TextInputField
                description="Ange hur indexuppräkningen ska anges på avier."
                title="Namn på avier"
                fieldKey="indexation_value_title"
                {...{ method, storeName }}
              />
            </div>
          )}

          <div className="mb-6">
            <CheckField
              description="Om detta alternativ är valt ignoreras den valda produkten på denna indexinställning."
              title="Använd produkt från debiteringsrad"
              fieldKey="use_cost_product"
              {...{ method, storeName }}
            />
          </div>

          {!useCostProduct && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                fieldKey="product"
                method={method}
                placeholder="Välj produkt"
                title="Produkt för bokföring"
                TableComponent={InvoicingProductTable}
              />
            </div>
          )}
        </>
      )}
    </Modal>
  );
}

export const MONTHS_OPTIONS = [
  {
    d: "Januari",
    v: 1,
  },
  {
    d: "Februari",
    v: 2,
  },
  {
    d: "Mars",
    v: 3,
  },
  {
    d: "April",
    v: 4,
  },
  {
    d: "Maj",
    v: 5,
  },
  {
    d: "Juni",
    v: 6,
  },
  {
    d: "Juli",
    v: 7,
  },
  {
    d: "Augusti",
    v: 8,
  },
  {
    d: "September",
    v: 9,
  },
  {
    d: "Oktober",
    v: 10,
  },
  {
    d: "November",
    v: 11,
  },
  {
    d: "December",
    v: 12,
  },
];
