import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_CURRENT_BUILDING:
      return { ...newState, currentBuilding: payload.building };

    case constants.SET_CURRENT_FLOOR:
      return { ...newState, currentFloor: payload.floor };

    default:
      return newState;
  }
};
