import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "../../../store/base";
import { axiosInstance } from "../../../store/base/store/axios";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import {
  useFilteredDebtInvoices,
  constants as debtInvoiceConstants,
  useDebtInvoicePaginationCount,
} from "../../../store/debtInvoice";
import {
  useFilteredInvoices,
  useInvoicePaginationCount,
  constants as invoiceConstants,
} from "../../../store/invoice";
import {
  useFilteredInvoicePayments,
  constants as invoicePaymentConstants,
  useInvoicePaymentPaginationCount,
} from "../../../store/invoicePayment";
import {
  useFilteredReminderInvoices,
  constants as reminderInvoiceConstants,
  useReminderInvoicePaginationCount,
} from "../../../store/reminderInvoice";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Badge, { BADGE_TYPES } from "../../Badge/Badge";
import InvoicingEventsTable from "./InvoicingEventsTable";
import {
  FAILED_AUTOGIRO_COLS,
  FAILED_INVOICE_DELIVERIES_COLS,
  FAILED_REMINDER_DELIVERIES_COLS,
  PAYMENTS_COLS,
  SENT_DEBT_COLLECTION_COLS,
  SENT_INVOICES_COLS,
  SENT_REMINDERS_COLS,
} from "./listDefs";

const CATEGORIES = {
  FAILED_INVOICE_DELIVERIES: "FAILED_INVOICE_DELIVERIES",
  SENT_INVOICES: "SENT_INVOICES",
  FAILED_REMINDER_DELIVERIES: "FAILED_REMINDER_DELIVERIES",
  SENT_REMINDERS: "SENT_REMINDERS",
  PAYMENTS: "PAYMENTS",
  FAILED_AUTOGIRO: "FAILED_AUTOGIRO",
  SENT_DEBT_COLLECTION: "SENT_DEBT_COLLECTION",
};

const CATEGORY_COLORS = {
  FAILED_INVOICE_DELIVERIES: BADGE_TYPES.RED,
  FAILED_REMINDER_DELIVERIES: BADGE_TYPES.RED,
  SENT_REMINDERS: BADGE_TYPES.PURPLE,
  SENT_INVOICES: BADGE_TYPES.INDIGO,
  PAYMENTS: BADGE_TYPES.GREEN,
  FAILED_AUTOGIRO: BADGE_TYPES.RED,
  SENT_DEBT_COLLECTION: BADGE_TYPES.ORANGE,
};

const READABLE_CATEGORIES = {
  FAILED_INVOICE_DELIVERIES: "Misslyckade fakturaleveranser",
  FAILED_REMINDER_DELIVERIES: "Misslyckade påminnelseleveranser",
  SENT_REMINDERS: "Skickade påminnelser",
  SENT_INVOICES: "Skickade fakturor",
  PAYMENTS: "Betalningar",
  FAILED_AUTOGIRO: "Misslyckade autogirodragningar",
  SENT_DEBT_COLLECTION: "Skickat till kravhantering",
};

const CATEGORY_URLS = {
  FAILED_INVOICE_DELIVERIES: invoiceConstants.LIST_URL,
  FAILED_REMINDER_DELIVERIES: reminderInvoiceConstants.LIST_URL,
  SENT_REMINDERS: reminderInvoiceConstants.LIST_URL,
  SENT_INVOICES: invoiceConstants.LIST_URL,
  PAYMENTS: invoicePaymentConstants.LIST_URL,
  FAILED_AUTOGIRO: invoiceConstants.LIST_URL,
  SENT_DEBT_COLLECTION: debtInvoiceConstants.LIST_URL,
};

const CATEGORY_COMPANY_QUERY = {
  FAILED_INVOICE_DELIVERIES: "company",
  FAILED_REMINDER_DELIVERIES: "companies",
  SENT_REMINDERS: "companies",
  SENT_INVOICES: "company",
  PAYMENTS: "company",
  FAILED_AUTOGIRO: "company",
  SENT_DEBT_COLLECTION: "companies",
};

const CATEGORY_QUERIES = {
  FAILED_INVOICE_DELIVERIES: {
    order_by: "-delivery_fail_time",
    delivery_fail_time__isnull: false,
    kind__in: [0],
  },
  SENT_INVOICES: {
    order_by: "-sent_time",
    delivery_fail_time__isnull: true,
    sent_time__isnull: false,
    kind__in: [0],
  },
  FAILED_AUTOGIRO: {
    order_by: "-autogiro_failed_time",
    autogiro_failed_time__isnull: false,
    kind__in: [0],
  },
  SENT_REMINDERS: {
    order_by: "-created_at",
    delivery_fail_time__isnull: true,
  },
  FAILED_REMINDER_DELIVERIES: {
    order_by: "-delivery_fail_time",
    delivery_fail_time__isnull: false,
  },
  PAYMENTS: {
    order_by: "-payment_date",
  },
  SENT_DEBT_COLLECTION: {
    order_by: "-created_at",
  },
};

const CATEGORY_COLUMNS = {
  FAILED_INVOICE_DELIVERIES: FAILED_INVOICE_DELIVERIES_COLS,
  FAILED_REMINDER_DELIVERIES: FAILED_REMINDER_DELIVERIES_COLS,
  SENT_INVOICES: SENT_INVOICES_COLS,
  SENT_REMINDERS: SENT_REMINDERS_COLS,
  FAILED_AUTOGIRO: FAILED_AUTOGIRO_COLS,
  PAYMENTS: PAYMENTS_COLS,
  SENT_DEBT_COLLECTION: SENT_DEBT_COLLECTION_COLS,
};

const PAGE_SIZE = 25;

export default function InvoicingEvents({ companyId }) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const [count, setCount] = React.useState(0);
  const [pageData, setPageData] = React.useState(null);
  const [previousPage, setPreviousPage] = React.useState("");
  const [nextPage, setNextPage] = React.useState("");
  const [fetching, setFetching] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(
    CATEGORIES.FAILED_INVOICE_DELIVERIES
  );

  const { push } = useHistory();

  // const [invoicesL] = useFilteredInvoices("");
  // const [invoicePayments] = useFilteredInvoicePayments("");
  // const [debtInvoices] = useFilteredDebtInvoices("");
  // const [reminderInvoices] = useFilteredReminderInvoices("");

  // console.log({ invoicesL, invoicePayments, debtInvoices, reminderInvoices });

  const [failedInvoiceDeliveriesCount] = useInvoicePaginationCount({
    filters: {
      ...CATEGORY_QUERIES.FAILED_INVOICE_DELIVERIES,
      company: companyId,
    },
  });
  const [sentInvoicesCount] = useInvoicePaginationCount({
    filters: { ...CATEGORY_QUERIES.SENT_INVOICES, company: companyId },
  });
  const [sentRemindersCount] = useReminderInvoicePaginationCount({
    filters: { ...CATEGORY_QUERIES.SENT_REMINDERS, companies: companyId },
  });
  const [failedReminderDelivieriesCount] = useReminderInvoicePaginationCount({
    filters: {
      ...CATEGORY_QUERIES.FAILED_REMINDER_DELIVERIES,
      companies: companyId,
    },
  });
  const [paymentsCount] = useInvoicePaymentPaginationCount({
    filters: { ...CATEGORY_QUERIES.PAYMENTS, company: companyId },
  });
  const [sentToDebtCollection] = useDebtInvoicePaginationCount({
    filters: { ...CATEGORY_QUERIES.SENT_DEBT_COLLECTION, companies: companyId },
  });

  const COUNTS = {
    FAILED_INVOICE_DELIVERIES: failedInvoiceDeliveriesCount,
    SENT_REMINDERS: sentRemindersCount,
    SENT_INVOICES: sentInvoicesCount,
    FAILED_REMINDER_DELIVERIES_COLS: failedReminderDelivieriesCount,
    PAYMENTS: paymentsCount,
    SENT_DEBT_COLLECTION: sentToDebtCollection,
  };

  const fetchData = async () => {
    try {
      const url = CATEGORY_URLS[selectedCategory];
      const queryObj = cloneDeep(CATEGORY_QUERIES[selectedCategory] || {});

      queryObj._page = page;
      queryObj._page_size = pageSize;
      queryObj[CATEGORY_COMPANY_QUERY[selectedCategory]] = companyId;

      const q = buildQueryString(queryObj);

      const { results, next, previous, count } = await getData({
        query: q,
        url,
      });

      setFetching(false);
      setNextPage(next);
      setPreviousPage(previous);
      setPageData(results);
      setCount(count);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (fetching) return;
    setFetching(true);
    fetchData();
  }, [page, selectedCategory, pageSize]);

  const onCategorySelected = (c) => {
    if (c === selectedCategory) return;
    setPage(1);
    setPageData(null);
    setPreviousPage("");
    setNextPage("");
    setSelectedCategory(c);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };
  const handlePreviousPage = () => {
    setPage(page - 1);
  };
  const handleSetPage = (p) => {
    setPage(p);
  };

  const handleRowClicked = ({ original }) => {
    const billectaInvoiceId =
      original?.billecta_id?.object_id ||
      original?.invoice_billecta_id?.object_id;
    const billectaCreditorId =
      original?.billecta_id?.creditor_id ||
      original?.invoice_billecta_id?.creditor_id;
    if (billectaInvoiceId && billectaCreditorId) {
      push(
        invoiceDetailUrl({
          creditorId: billectaCreditorId,
          invoiceId: billectaInvoiceId,
        })
      );
    } else {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Denna rad saknar en kopplad faktura att gå till",
        })
      );
    }
  };

  const tableData = React.useMemo(() => {
    return pageData || [];
  }, [pageData]);

  const tableCols = React.useMemo(() => {
    return CATEGORY_COLUMNS[selectedCategory];
  }, [selectedCategory]);

  return (
    <div className="flex flex-col bg white rounded border-slate-100 border border-solid shadow-sm text-slate-700  p-3">
      <div className="mb-4">
        <div className="font-medium text-lg">Aviseringshändelser</div>
        <div className="text-xs">
          Visar aviseringshändelser sorterat på händelsedatum. Klicka på en
          kategori för att visa händelser för kategorin. Klicka på en händelse
          för att komma till den kopplade fakturan.
        </div>
      </div>

      <div className="flex flex-wrap">
        {Object.keys(CATEGORIES).map((c) => (
          <div key={c} className="mr-2 mb-2">
            <Badge
              onClick={() => onCategorySelected(c)}
              selected={selectedCategory === c}
              type={CATEGORY_COLORS[c]}
            >
              {READABLE_CATEGORIES[c]} ({COUNTS[c] || 0})
            </Badge>
          </div>
        ))}
      </div>

      {tableData && tableCols && (
        <div>
          <InvoicingEventsTable
            data={tableData}
            columns={tableCols}
            canNext={!!nextPage}
            canPrevious={!!previousPage}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            fetching={fetching}
            currentPage={page}
            pageCount={Math.ceil(count / pageSize)}
            onSetPage={handleSetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onRowClicked={handleRowClicked}
          />
        </div>
      )}
    </div>
  );
}

async function getData({ query, url }) {
  try {
    const { data } = await axiosInstance(`${url}?${query}`);

    return data;
  } catch (e) {
    throw Error(e?.response?.data);
  }
}
