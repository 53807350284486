const defs = [
  {
    title: "Typ av ärende",
    key: "TYPE",
    visited: true,
    hasError: false,
    fieldKeys: ["title", "category"],
  },
  // {
  //   title: "Fakturering",
  //   key: "INVOICING",
  //   visited: false,
  //   hasError: false,
  //   fieldKeys: [
  //     "use_billing",
  //     "default_billing_company",
  //     "default_invoicing_setting",
  //   ],
  // },
  {
    title: "Utförare",
    key: "PERFORMERS",
    visited: false,
    hasError: false,
    fieldKeys: ["responsible_trustee", "performers"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
