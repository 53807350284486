export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CUSTOMDIMENSIONS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CUSTOMDIMENSIONS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CUSTOMDIMENSIONS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CUSTOMDIMENSIONS",
  DESTROY_FORM: "DESTROY_FORM_CUSTOMDIMENSIONS",
  SET_FORM_ERROR: "SET_FORM_ERROR_CUSTOMDIMENSIONS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CUSTOMDIMENSIONS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CUSTOMDIMENSIONS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CUSTOMDIMENSIONS",
  RESET_STATE: "RESET_STATE_CUSTOMDIMENSIONS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CUSTOMDIMENSIONS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_CUSTOMDIMENSIONS",
  CLEAR_FETCHED: "CLEAR_FETCHED_CUSTOMDIMENSIONS",

  LIST_URL: "/accounting/costs/dimension/list/",
  POST_URL: "/accounting/costs/dimension/",
  PATCH_URL: "/accounting/costs/dimension/",
  GET_URL: "/accounting/costs/dimension/",

  STORE_NAME: "invoicingCustomDimensions",
};
