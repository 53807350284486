import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;
  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.UP_LEAD_PAGINATION_OFFSET: {
      return handleSetPaginationOffset(state, payload.pipeId);
    }

    case constants.SET_FILTERS: {
      return {
        ...newState,
        activeFilters: payload.filters,
        [`leadPaginationOffset_pipe${payload.pipeId}`]: 0,
        [`leadPaginationPreviews_pipe${payload.pipeId}`]: [],
      };
    }

    case constants.SET_SEARCH_TERM: {
      return {
        ...newState,
        activeFilters: payload.searchTerm
          ? { _lead_search: payload.searchTerm }
          : {},
      };
    }

    case constants.RESET_PAGINATION_OFFSET: {
      return {
        ...newState,
        [`leadPaginationOffset_pipe${payload.pipeId}`]: 0,
        [`leadPaginationPreviews_pipe${payload.pipeId}`]: [],
      };
    }

    default:
      return newState;
  }
};

const handleSetPaginationOffset = (state, pipeId) => {
  const paginationOffsetCurrent = state.leadPaginationOffset || 0;
  const newPaginationOffset = paginationOffsetCurrent + 20;

  const relevantStages = Object.values(state.all).filter(
    (stage) => stage.pipe === pipeId
  );

  const fetchedPreviews = [];
  if (relevantStages?.length) {
    relevantStages.forEach((stage) => {
      const previews = stage.leads.preview;

      if (previews?.length) {
        fetchedPreviews.push(previews);
      }
    });
  }

  const flatFetchedPreviews = fetchedPreviews.flat();

  return {
    ...state,
    [`leadPaginationOffset_pipe${pipeId}`]: newPaginationOffset,
    [`leadPaginationPreviews_pipe${pipeId}`]: flatFetchedPreviews,
  };
};
