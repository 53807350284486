import * as React from "react";

import SelectFilter from "../../../../Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "indoor", display: "Inomhus" },
    { value: "outdoor", display: "Utomhus" },
    { value: "garage", display: "Garage" },
    { value: "house", display: "Parkeringshus" },
    { value: "other", display: "Övrigt" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
