import { axiosInstance } from "../../base/store/axios";

async function search({ searchParams }) {
  const { data: response } = await axiosInstance.post(
    `accounting/gateways/search/`,
    searchParams
  );

  return response;
}

export { search };
