export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRF_PAYMENT_GROUP_QUOTA",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRF_PAYMENT_GROUP_QUOTA",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRF_PAYMENT_GROUP_QUOTA",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRF_PAYMENT_GROUP_QUOTA",
  DESTROY_FORM: "DESTROY_FORM_BRF_PAYMENT_GROUP_QUOTA",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRF_PAYMENT_GROUP_QUOTA",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRF_PAYMENT_GROUP_QUOTA",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRF_PAYMENT_GROUP_QUOTA",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRF_PAYMENT_GROUP_QUOTA",
  RESET_STATE: "RESET_STATE_BRF_PAYMENT_GROUP_QUOTA",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRF_PAYMENT_GROUP_QUOTA",
  REMOVE_OBJECT: "REMOVE_OBJECT_BRF_PAYMENT_GROUP_QUOTA",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRF_PAYMENT_GROUP_QUOTA",

  LIST_URL: "/brf/loans/brfpaymentgroupquota/list/",
  POST_URL: "/brf/loans/brfpaymentgroupquota/",
  PATCH_URL: "/brf/loans/brfpaymentgroupquota/",
  GET_URL: "/brf/loans/brfpaymentgroupquota/",

  STORE_NAME: "brfPaymentGroupQuota",
};
