export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ERRAND_COSTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ERRAND_COSTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ERRAND_COSTS",
  DESTROY_FORM: "DESTROY_FORM_ERRAND_COSTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_ERRAND_COSTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ERRAND_COSTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ERRAND_COSTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ERRAND_COSTS",
  RESET_STATE: "RESET_STATE_ERRAND_COSTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_ERRAND_COSTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_ERRAND_COSTS",
  SET_PUBLIC_COSTS: "SET_PUBLIC_ERRAND_COSTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ERRAND_COSTS",

  LIST_URL: "/errands/costs/errandcost/list/",
  POST_URL: "/errands/costs/errandcost/",
  PATCH_URL: "/errands/costs/errandcost/",
  GET_URL: "/errands/costs/errandcost/",

  STORE_NAME: "errandCosts",
};
