import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, set } from "lodash";

// styles, design
import ChapterModal from "../Modals/ChapterModal";

// store, state
import reducer, { ACTIONS } from "./uiReducer";
import {
  useChapterErrors,
  setActiveFormInstance,
} from "../../../../store/base";
import { InfoBox } from "../../../Displays";

export default ({
  storeName,
  chapterDefs,
  onRenderChapter,
  submitCallback,
  title,
  onDone,
  loading,
  isOpen,
  infoData,
  infoDataType,
  isDocument,
  overrideRenderChapter,
  secondarySubmitCallback = false,
  secondarySubmitTitle,
  modalInModal,
  small,
  ...rest
}) => {
  const dispatch = useDispatch();

  const initialState = {
    activeChapter: chapterDefs[0],
    chapters: chapterDefs,
    isFirstStep: true,
    isLastStep: false,
  };

  const [state, localDispatch] = React.useReducer(reducer, { ...initialState });
  const chapterErrors = useChapterErrors({ storeName, chapterDefs });
  const chapterRef = React.useRef(chapterErrors);

  const formInstance = useSelector((state) => state[storeName].formInstance);

  const isLastStep =
    state.activeChapter.key ===
    state.chapters[
      Object.keys(state.chapters)[Object.keys(state.chapters).length - 1]
    ].key;

  // listen for changes in total error status of chapter and update status
  React.useEffect(() => {
    if (JSON.stringify(chapterRef.current) !== JSON.stringify(chapterErrors)) {
      localDispatch({
        type: ACTIONS.SET_CHAPTER_ERRORS,
        payload: {
          keys: chapterErrors,
        },
      });
      chapterRef.current = chapterErrors;
    }
  }, [chapterErrors]);

  React.useEffect(() => {
    if (!isOpen) {
      localDispatch({
        type: ACTIONS.RESET_STATE,
        payload: {
          initialState,
        },
      });
    }
  }, [isOpen]);

  const onSubmit = () => {
    // if not last step, go to next step
    if (!isLastStep) {
      return goToNextStep();
    }

    // clean the instance data from non-valid update values
    let cleanedInstance = {};
    chapterDefs.forEach((def) => {
      (def.fieldKeys || []).forEach((k) => {
        const value = get(formInstance, k, undefined);

        // if we set undefined values to a parent,
        // the parent will be an empty dict, which backend doesn allow
        // e.g editabledoc.parties = undefined -> {editabledoc:{}}
        if (value === undefined) {
          return;
        }

        set(cleanedInstance, k, value);
      });
    });

    dispatch(setActiveFormInstance({ storeName, data: cleanedInstance }));

    submitCallback();
  };

  const secondarySubmit = () => {
    // clean the instance data from non-valid update values
    let cleanedInstance = {};
    chapterDefs.forEach((def) => {
      (def.fieldKeys || []).forEach((k) => {
        const value = get(formInstance, k, undefined);

        // if we set undefined values to a parent,
        // the parent will be an empty dict, which backend doesn allow
        // e.g editabledoc.parties = undefined -> {editabledoc:{}}
        if (value === undefined) {
          return;
        }

        set(cleanedInstance, k, value);
      });
    });

    dispatch(setActiveFormInstance({ storeName, data: cleanedInstance }));

    secondarySubmitCallback();
  };

  const { activeChapter, chapters } = state;

  const onChapterClicked = (key) => {
    localDispatch({
      type: ACTIONS.SET_ACTIVE_CHAPTER,
      payload: {
        key,
      },
    });
  };

  const goToNextStep = () => {
    localDispatch({
      type: ACTIONS.GO_TO_NEXT_STEP,
    });
  };

  const goToPreviousStep = () => {
    localDispatch({
      type: ACTIONS.GO_TO_PREVIOUS_STEP,
    });
  };

  return (
    <ChapterModal
      isOpen={isOpen}
      title={title}
      chapters={chapters}
      activeChapter={activeChapter}
      chapterClicked={onChapterClicked}
      loading={loading}
      onDone={onDone}
      cancelCallback={state.isFirstStep ? onDone : goToPreviousStep}
      cancelTitle={state.isFirstStep ? "Avbryt" : "Tillbaka"}
      secondarySubmit={secondarySubmitCallback ? secondarySubmit : false}
      secondarySubmitTitle={secondarySubmitTitle}
      submitCallback={onSubmit}
      submitTitle={
        isLastStep
          ? isDocument
            ? "Skapa dokument"
            : title || "Spara"
          : "Nästa"
      }
      modalInModal={modalInModal}
    >
      {infoData && (
        <InfoBox
          boxTheme={infoDataType || "info"}
          title={infoData.title}
          text={infoData.text}
        />
      )}

      {overrideRenderChapter
        ? overrideRenderChapter()
        : onRenderChapter(activeChapter.key)}
    </ChapterModal>
  );
};
