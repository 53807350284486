import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { Checkbox, NumberInput, Select } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingSettings";
import { useFormInstanceField } from "../../../../../store/base";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const useDeposition = useFormInstanceField({
    storeName,
    fieldKey: "automatic_deposit_handling",
  }); 

  return (
    <>
      <FormAreaTitle>Depositionshantering </FormAreaTitle>
      <Checkbox
      storeName={storeName}
      fieldKey={"automatic_deposit_handling"}
      method={method}
      title={"Använd automatisk depositionshantering"}
      />
      {!!useDeposition && 
      <NumberInput
      storeName={storeName}
      fieldKey={"deposit_quota"}
      method={method}
      title={"Depositionsandel"}
      subtitle={"Andel av bashyran som tas ut i deposition"}
      />
      }
    </>
  );
};
