import * as React from "react";

import { Confirm, Contract } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "CONTRACT":
      return <Contract method={method} />;

    default:
      return <Confirm />;
  }
};
