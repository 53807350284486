import * as React from "react";
import { constants } from "../../../../../store/leaseContracts";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import DateSelect from "../../../Base/Fields/DateSelect";
import TextInputField from "../../../Base/Fields/TextInputField";
import SelectField from "../../../Base/Fields/SelectField";

export default function Tax({ method }) {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Fastighetsskatt</OverviewTitle>
          <OverviewSubtitle>
            Andel av fastighetsskatten som ska debiteras hyresgästen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          showYearPicker
          placeholder="Från vald inställning"
          title="Basår för indexuppräkning"
          description="Fyll i detta år om basåret på indexinställningar på debiteringsrader kopplade till detta avtal ska skrivas över. Om detta fält lämnas tomt används basåret från indexinställningen."
          {...{ storeName, method, fieldKey: "index_base_date" }}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Hyresgästs andel av fastighetsskatt (%)"
          description="Ange hur stor andel av fastighetsskatten som ska debiteras hyresgästen."
          storeName={storeName}
          fieldKey="tenant_tax_billing_quota"
          method={method}
        />

        <SelectField
          title="Taxeringstyp"
          description="Ange vilken typ av taxering som gäller för objekt under detta avtal."
          storeName={storeName}
          fieldKey="tax_mode"
          method={method}
        />
      </div>
    </>
  );
}
