export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTAACCOUNTBALANCE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTAACCOUNTBALANCE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTAACCOUNTBALANCE",
  DESTROY_FORM: "DESTROY_FORM_BILLECTAACCOUNTBALANCE",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTAACCOUNTBALANCE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTAACCOUNTBALANCE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTAACCOUNTBALANCE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTAACCOUNTBALANCE",
  RESET_STATE: "RESET_STATE_BILLECTAACCOUNTBALANCE",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTAACCOUNTBALANCE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BILLECTACCOUNTBALANCE",

  SET_ACCOUNT_BALANCE: "SET_ACCOUNT_BALANCE_BILLETAACCOUNTSBALANCE",
  GET_URL: "accounting/gateways/retrieve_debtor_balance/",
  POST_URL: "accounting/gateways/debtor_balance/",

  STORE_NAME: "billectaAccountBalance",
};
