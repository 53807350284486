import { axiosInstance } from "../../base/store/axios";

async function generateReport(data) {
  const { data: response } = await axiosInstance.patch(
    `accounting/gateways/bookkeep_export/?action=accountingexports`,
    data
  );

  return response;
}

async function generateDynamicReport(data) {
  const { data: response } = await axiosInstance.patch(
    `accounting/gateways/dynamic_bookkeep_export/?action=accountingexports`,
    data
  );

  return response;
}

async function getAccountingReports(creditorId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/bookkeep_export/${creditorId}/?action=accountingexports`
  );

  return data;
}

async function getFileData(fileId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/files/${fileId}/?action=file`
  );

  return data;
}

export {
  generateReport,
  getAccountingReports,
  getFileData,
  generateDynamicReport,
};
