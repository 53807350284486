import * as React from "react";
import { useParams } from "react-router";
import BlueprintContainer from "../../../components/Blueprints/BlueprintContainer";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useApartment } from "../../../store/apartments";
import { buildQueryString } from "../../../store/base";
import { useFilteredBlueprints } from "../../../store/blueprints";
import { useFilteredRooms } from "../../../store/rooms";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import PremisesImageContainer from "../../../components/Details/Images/PremisesImageContainer";
import { BLUEPRINT_PREMISE_OPTIONS } from "../../../components/Blueprints/BlueprintDisplay/Controller";

const TABS = {
  BLUEPRINT: "Planritning",
  IMAGES: "Bilder",
};

export default function ApartmentImagesAndBlueprints() {
  const { apartmentId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState("BLUEPRINT");

  const [apartment] = useApartment(apartmentId);

  const apartmentQuery = buildQueryString({
    apartment: apartment?.id,
  });

  const [blueprints, blueprintsLoading] = useFilteredBlueprints(apartmentQuery);
  const blueprint = blueprints?.[0] || undefined;

  const roomQuery = blueprint
    ? buildQueryString({ blueprint: blueprint.id })
    : null;

  const [rooms, roomsLoading] = useFilteredRooms(roomQuery);

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>{TABS[selectedTab]}</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        {selectedTab === "BLUEPRINT" && (
          <BlueprintContainer
            blueprint={blueprint}
            premises={apartment}
            rooms={rooms || []}
            premisesType={BLUEPRINT_PREMISE_OPTIONS.APARTMENT}
          />
        )}

        {selectedTab === "IMAGES" && (
          <PremisesImageContainer
            images={apartment?.images}
            instance={apartment}
            kind="apartment"
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
