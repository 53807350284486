import { XCircleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import classNames from "classnames";

export default function Modal({
  closeFunction,
  children,
  title,
  onAccept,
  onDelete,
  acceptTitle,
  denyTitle,
  canAccept = true,
  canClose = true,
  canDelete = false,
  className = "",
}) {
  return (
    <div
      id="large-modal"
      tabindex="-1"
      aria-modal="true"
      role="dialog"
      className={classNames(
        "overflow-y-auto p-6 overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-[rgba(0,0,0,0.2)] backdrop-blur-sm flex justify-center",
        className
      )}
    >
      <div className="relative w-full max-w-4xl h-full md:h-auto">
        <div className="relative bg-white rounded shadow">
          <div className="flex justify-between items-center p-5 rounded-t border-b ">
            <h3 className="text-l font-medium text-gray-900 ">{title}</h3>
            {canClose && (
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                onClick={closeFunction}
              >
                <XCircleIcon width={24} />
              </button>
            )}
          </div>

          <div className="p-6 space-y-6 max-h-[70vh] overflow-y-auto">
            {children}
          </div>

          <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 ">
            {onDelete && canDelete && (
              <PrimaryBtn secondaryError onClick={onDelete} className="mr-auto">
                Radera
              </PrimaryBtn>
            )}

            <PrimaryBtn
              disabled={!canAccept}
              onClick={onAccept ? onAccept : closeFunction}
            >
              {onAccept ? acceptTitle || "Spara" : "Klar"}
            </PrimaryBtn>
            {onAccept && (
              <PrimaryBtn error onClick={closeFunction}>
                {denyTitle || "Avbryt"}
              </PrimaryBtn>
            )}
          </div>
        </div>

        <div className="min-h-4">&nbsp;</div>
      </div>
    </div>
  );
}
