import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";
import SelectFilterNewTable from "src/components/Lists/Base/CompleteList/Filters/SelectFilterNewTable";

export default ({ column }) => {
  const choices = [
    { value: 3, display: "Tidigare" },
    { value: 0, display: "Nuvarande" },
    { value: 1, display: "Kommande" },
    { value: 2, display: "Potentiell" },
  ];

  // return <SelectFilter choices={choices} column={column} />;
  return <SelectFilterNewTable choices={choices} column={column} />;
};

export const IsActive = ({ column }) => {
  const choices = [
    { value: true, display: "Aktiv" },
    { value: false, display: "Inaktiv" },
  ];

  // return <SelectFilter choices={choices} column={column} />;
  return <SelectFilterNewTable choices={choices} column={column} />;
};

export const IsConfigured = ({ column }) => {
  const choices = [
    { value: true, display: "Konfigurerad" },
    { value: false, display: "Ej Konfigurerad" },
  ];

  // return <SelectFilter choices={choices} column={column} />;
  return <SelectFilterNewTable choices={choices} column={column} />;
};

export const falsySort = (rowA, rowB, columnId, desc) => {
  if (!rowA.values[columnId] && !rowB.values[columnId]) {
    return 0;
  }

  if (!rowA.values[columnId]) {
    return desc ? -1 : 1;
  }

  if (!rowB.values[columnId]) {
    return desc ? 1 : -1;
  }

  return rowA.values[columnId].localeCompare(rowB.values[columnId]);
};
