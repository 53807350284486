import * as React from "react";

// style, design
import {
  ToolTipCell,
  LinkedObject,
  DateCell,
  DurationCell,
  GenericCell,
} from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import SimpleIntervalFilter from "src/components/Lists/Base/CompleteList/Filters/IntervalFilter";
import StateFilter from "./Filters/StateFilter";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as leaseDetailUrl } from "../../../store/leaseContracts";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import SimpleBooleanFilter from "src/components/Lists/Base/CompleteList/Filters/BooleanFilter";
import CategoryFilter from "./Filters/CategoryFilter";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ value, row }) => {
        const isDraft = row.original.draft;

        let label = row.original.state_display;

        if (isDraft) {
          label = "Utkast";
        }

        return <StatusLabel state={row.original.state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Avtalsnummer",
      accessor: "id_number",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Aviseringsstatus",
      accessor: "other_invoicing_active",
      Cell: ({ value, row }) => {
        const billWithLease = row.original.other_invoicing_bill_with_lease;

        if (billWithLease) {
          return <StatusLabel state={4}>Samaviseras</StatusLabel>;
        }

        return (
          <StatusLabel
            state={
              value === true ? 1 : value === null ? 6 : value === false ? 3 : 10
            }
          >
            {value === true
              ? "Konfigurerad"
              : value === null
              ? "Ej konfigurerad"
              : value === false
              ? "Pausad"
              : "Okänd"}
          </StatusLabel>
        );
      },
      Filter: SimpleBooleanFilter,
      filter: "text",
    },
    {
      Header: "Motpart",
      accessor: "tenant",
      Cell: ({ value }) => (
        <LinkedObject obj={value} urlMethod={tenantDetailUrl} />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Fastighet",
      accessor: "realestate?.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    // searchable
    {
      Header: "Kategori",
      accessor: "category",
      Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
      Filter: CategoryFilter,
      filter: "text",
    },
    {
      Header: "Huvudavtal",
      accessor: "main_contract",
      Cell: ({ value }) =>
        value ? (
          <LinkedObject
            obj={{ id: value?.id, str_representation: "Visa" }}
            urlMethod={leaseDetailUrl}
          />
        ) : (
          "-"
        ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Objektskoppling",
      accessor: "content_type",
      Cell: ({ row }) => (
        <GenericCell
          contentType={row.original.content_type}
          id={row.original.object_id}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    // searchable
    {
      Header: "Startdatum",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Slutdatum",
      accessor: (row, _) =>
        row.closed_date
          ? row.closed_date
          : row.renewed_to
          ? row.renewed_to
          : row.end_date,
      id: "end_date",
      Cell: ({ row }) => (
        <>
          {row.original.closed_date ? (
            <DateCell date={row.original.closed_date} />
          ) : row.original.renewed_to ? (
            <>
              <DateCell date={row.original.renewed_to} />
              <span> (Förlängt)</span>
            </>
          ) : (
            <DateCell date={row.original.end_date} />
          )}
        </>
      ),
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Uppsägningstid",
      accessor: "notify_interval",
      Cell: ({ row }) => (
        <DurationCell
          durationStamp={row.original.notify_interval}
          shadowDate={row.original.notify_time}
        />
      ),
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Förlängningstid",
      accessor: "renew_interval",
      Cell: ({ row }) => {
        const forever = row.original.renew_indefinetely;
        if (forever) {
          return "Tillsvidare";
        }
        return <DurationCell durationStamp={row.original.renew_interval} />;
      },
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    // searchable
    {
      Header: "Skapat av",
      accessor: "owner.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
