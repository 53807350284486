import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";

//store, state
import { setActiveFormInstance, usePermissionCheck } from "../../store/base";
import {
  useReportErrand,
  constants,
  update,
  overviewUrl,
} from "../../store/reportErrands";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

//components & styles
import ErrandOverview from "./detail/Overview";
import ErrandReport from "./detail/Report";
import ErrandStatistics from "./detail/Statistics";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { ErrandStatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ModalForm from "../../components/Forms/ReportErrand/ChapterForm/ModalForm";
import ExecuteModalForm from "../../components/Forms/ReportErrand/ExecuteChapterForm/ModalForm";
import DelegateModalForm from "../../components/Forms/ReportErrand/DelegateErrandChapterForm/ModalForm";
import { getErrandStatus } from "../../components/utils/errandUtils";
import getActions from "../../components/Details/ReportErrand/infoActions";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/Forms/Base/Buttons";
import { InfoBox } from "../../components/Displays";
import StandardModal from "../../components/Modals/StandardModal";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import ReportErrandCosts from "./detail/Costs";
import Protocol from "./detail/Protocol";
import NonConnectedRadioGroup from "../../components/Forms/Base/Old/NonConnected/NonConnectedRadioGroup";

function ReportErrandsDetail() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { reportErrandId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const { id, user_type } = useSelector((state) => state.app?.user);

  const [reportErrand, reportErrandLoading] = useReportErrand(reportErrandId);

  const [markErrandDoneOpen, setMarkErrandDoneOpen] = React.useState(false);
  const [errandDoneRatingOk, setErrandDoneRatingOk] = React.useState(true);
  const [errandDoneTenant, setErrandDoneTenant] = React.useState(false);

  const [editErrandOpen, setEditErrandOpen] = React.useState(false);
  const [delegateErrandOpen, setDelegateErrandOpen] = React.useState(false);
  const [executeErrandOpen, setExecuteErrandOpen] = React.useState(false);
  const [confirmExecuteOpen, setConfirmExecuteOpen] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  usePermissionRedirect(["view_can_reporterrand", "allow_errand_faults"]);

  const canEditErrand = usePermissionCheck("change_can_reporterrand");
  const canDeleteErrand = usePermissionCheck("delete_can_reporterrand");

  const SUB_PAGES = [
    {
      path: "/errand",
      label: "Felanmälan",
      component: ErrandOverview,
    },
    {
      path: "/protocol",
      label: "Protokoll",
      component: Protocol,
    },
  ];

  if (reportErrand?.status === 3 || reportErrand?.status === 4) {
    SUB_PAGES.push({
      path: "/report",
      label: "Utfall",
      component: ErrandReport,
    });
  }

  if (reportErrand?.status !== 0 && reportErrand) {
    SUB_PAGES.push({
      path: "/costs",
      label: `Kostnader (${reportErrand?.costs?.length || 0})`,
      component: ReportErrandCosts,
    });
  }

  SUB_PAGES.push({
    path: "/statistics",
    label: "Relaterade felanmälningar",
    component: ErrandStatistics,
  });

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const canUserExecuteReportErrand = () => {
    let currentPerformer = false;
    if (reportErrand?.performer) {
      currentPerformer = reportErrand?.performer?.user?.id;
    }

    //Errand already done
    if (reportErrand?.status === 3 || reportErrand?.status === 4) {
      return false;
    }

    if (!canEditErrand) {
      return false;
    }
    if (user_type === 0 || user_type === 1) {
      return canEditErrand;
    }
    if (currentPerformer === id) {
      return canEditErrand;
    }
  };
  const canUserEditErrand = () => {
    let currentPerformer = false;
    if (reportErrand?.performer) {
      currentPerformer = reportErrand?.performer?.user?.id;
    }

    if (!canEditErrand) {
      return false;
    }
    if (user_type === 0 || user_type === 1) {
      return canEditErrand;
    }
    if (currentPerformer === id) {
      return canEditErrand;
    }
  };

  const canDelegateErrand = () => {
    //errand already done
    if (reportErrand?.status === 3 || reportErrand?.status === 4) {
      return false;
    }

    //is admin or standarduser with correct permissions
    if (user_type === 0 || user_type === 1) {
      return canEditErrand;
    }
  };

  const onExecute = () => {
    if (reportErrand?.status !== 1) {
      setConfirmExecuteOpen(true);
    } else {
      setExecuteErrandOpen(true);
    }
  };

  const setErrandDone = () => {
    setUpdateLoading(true);

    dispatch(
      update({
        id: reportErrandId,
        forceData: {
          execution_rating: {
            is_ok: errandDoneRatingOk,
            component_placement: reportErrand?.component_placement,
          },
          status: errandDoneTenant ? 4 : 3,
        },
        successCallback: () => {
          setUpdateLoading(false);
          setMarkErrandDoneOpen(false);
        },
        errorCallback: () => {
          setUpdateLoading(false);
          setMarkErrandDoneOpen(false);
        },
      })
    );
  };

  const setErrandPaused = () => {
    setUpdateLoading(true);
    setExecuteErrandOpen(false);

    dispatch(
      update({
        id: reportErrandId,
        forceData: {
          status: 2,
        },
        successCallback: () => setUpdateLoading(false),
        errorCallback: () => setUpdateLoading(false),
      })
    );
  };

  const setErrandActive = ({ startExecute }) => {
    if (reportErrand?.status !== 1) {
      setUpdateLoading(true);
      dispatch(
        update({
          id: reportErrandId,
          forceData: {
            status: 1,
          },
          successCallback: () => {
            setUpdateLoading(false);
            if (startExecute) {
              setExecuteErrandOpen(true);
            }
          },
          errorCallback: () => setUpdateLoading(false),
        })
      );
    } else {
      setExecuteErrandOpen(true);
    }
  };

  const onEdit = () => {
    setEditErrandOpen(true);
  };

  const onDelete = () => {
    setDeleteModalOpen(true);
  };

  const onDelegate = () => {
    setDelegateErrandOpen(true);
  };

  let handleActions = getActions({
    disableExecute: !canUserExecuteReportErrand(),
    disableDelete: !canDeleteErrand,
    disableEdit: !canUserEditErrand(),
    disableDelegate: !canDelegateErrand(),
    onDelegate,
    onExecute,
    onEdit,
    onDelete,
    setMarkErrandDoneOpen,
    setErrandPaused,
    setErrandActive,
    status: reportErrand?.status,
  });

  const { status, display } = getErrandStatus({
    status: reportErrand?.status,
    plannedStart: reportErrand?.planned_start,
    plannedEnd: reportErrand?.planned_end,
    actualStart: reportErrand?.execute_start,
    actualEnd: reportErrand?.execute_end,
  });

  const renderInfoPills = () => {
    return (
      <>
        <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>
      </>
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(setActiveFormInstance({ storeName, data: {} }));
    };
  }, []);

  return (
    <>
      {updateLoading && <OverlaySpinner />}

      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={reportErrand}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      {canEditErrand && (
        <ModalForm
          method="PATCH"
          id={reportErrandId}
          instance={reportErrand}
          isOpen={editErrandOpen}
          onCheckout={() => setEditErrandOpen(false)}
        />
      )}

      {delegateErrandOpen && (
        <DelegateModalForm
          method="PATCH"
          id={reportErrandId}
          isOpen={delegateErrandOpen}
          instance={reportErrand}
          onCheckout={() => setDelegateErrandOpen(false)}
        />
      )}

      {canEditErrand && (
        <ExecuteModalForm
          method="PATCH"
          id={reportErrandId}
          isOpen={executeErrandOpen}
          instance={reportErrand}
          canPause
          onCheckout={() => setExecuteErrandOpen(false)}
          onPaused={() => setErrandPaused()}
        />
      )}

      <ConfirmationModal
        isOpen={confirmExecuteOpen}
        title="Påbörja utförande"
        acceptTitle="Starta"
        closeFunction={() => setConfirmExecuteOpen(false)}
        renderContent={() => {
          return (
            <div>
              Är du säker på att du vill påbörja utförandet? Klicka på "Starta"
              för att starta igång utförandet.
            </div>
          );
        }}
        acceptCallback={() => setErrandActive({ startExecute: true })}
      />

      <StandardModal
        isOpen={markErrandDoneOpen}
        closeFunction={() => setMarkErrandDoneOpen(false)}
        title="Markera ärendet som avklarat"
        small
        withActionBar
        saveFunction={() => setErrandDone()}
        actionBarCancelTitle="Avbryt"
      >
        <NonConnectedRadioGroup
          id="ratingok"
          value={errandDoneRatingOk}
          title="Ange om felet är avhjälpt"
          options={[
            {
              label: "Felet avhjälptes",
              value: true,
            },
            {
              label: "Felet kvarstår",
              value: false,
            },
          ]}
          onChange={(data) => setErrandDoneRatingOk(data)}
        />

        <NonConnectedRadioGroup
          id="ratingtenant"
          value={errandDoneTenant}
          title="Ärendet avhjälpt av"
          options={[
            {
              label: "Utförare avhjälpte ärendet",
              value: false,
            },
            {
              label: "Hyresgäst avhjälpte ärendet",
              value: true,
            },
          ]}
          onChange={(data) => setErrandDoneTenant(data)}
        />
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Felanmälan ${
            reportErrand?.str_representation || "laddar..."
          } - ${reportErrand?.errand_id || ""}`}
          eventContentType="errands.reporterrand"
          eventObjectId={reportErrandId}
          eventIdQueryStr="reporterrand"
          breadCrumbs={[
            { url: "/report-errands", label: "Felanmälningar" },
            {
              label:
                `${reportErrand?.str_representation} - ${reportErrand?.errand_id}` ||
                "Laddar...",
            },
          ]}
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default ReportErrandsDetail;
