import * as React from "react";
import theme from "../../../theme";
import * as SC from "./styles";

export default function ContractInvoicingTableFilters({
  selectedFilters,
  toggleFilter,
  filterCounts,
  selectedTypes,
  toggleTypes,
  typeCounts,
}) {
  return (
    <>
      <div
        style={{
          fontWeight: 500,
          fontSize: 14,
          marginTop: 12,
          color: theme.colors.primaryText,
        }}
      >
        Filtrera på typ av avtal/objekt
      </div>
      <SC.FilterWrapper style={{ marginTop: 2 }}>
        <SC.TypeFilterButton
          selected={selectedTypes.includes("accounting.leaseinvoicing")}
          onClick={() => toggleTypes("accounting.leaseinvoicing")}
        >
          Hyresavtal ({typeCounts?.["accounting.leaseinvoicing"] || 0})
        </SC.TypeFilterButton>

        <SC.TypeFilterButton
          selected={selectedTypes.includes("accounting.parkinginvoicing")}
          onClick={() => toggleTypes("accounting.parkinginvoicing")}
        >
          Parkeringsavtal ({typeCounts?.["accounting.parkinginvoicing"] || 0})
        </SC.TypeFilterButton>

        <SC.TypeFilterButton
          selected={selectedTypes.includes("accounting.otherinvoicing")}
          onClick={() => toggleTypes("accounting.otherinvoicing")}
        >
          Övriga avtal ({typeCounts?.["accounting.otherinvoicing"] || 0})
        </SC.TypeFilterButton>

        <SC.TypeFilterButton
          selected={selectedTypes.includes("accounting.brfinvoicing")}
          onClick={() => toggleTypes("accounting.brfinvoicing")}
        >
          Bostadrätter ({typeCounts?.["accounting.brfinvoicing"] || 0})
        </SC.TypeFilterButton>
      </SC.FilterWrapper>

      <div
        style={{
          fontWeight: 500,
          fontSize: 14,
          marginTop: 12,
          color: theme.colors.primaryText,
        }}
      >
        Filtrera på aviseringsstatus
      </div>

      <SC.FilterWrapper style={{ marginTop: 2 }}>
        <SC.ClosedInvoiceFilterButton
          selected={selectedFilters.includes("active")}
          onClick={() => toggleFilter("active")}
        >
          Aviseras ({filterCounts?.active || 0})
        </SC.ClosedInvoiceFilterButton>

        <SC.NonPaidFilterButton
          selected={selectedFilters.includes("bi_invoiced")}
          onClick={() => toggleFilter("bi_invoiced")}
        >
          Samaviseras ({filterCounts?.bi_invoiced || 0})
        </SC.NonPaidFilterButton>

        <SC.NonAttestedFilterButton
          selected={selectedFilters.includes("inactive")}
          onClick={() => toggleFilter("inactive")}
        >
          Inaktiva ({filterCounts?.inactive || 0})
        </SC.NonAttestedFilterButton>

        <SC.UninterestingFilterButton
          selected={selectedFilters.includes("closed")}
          onClick={() => toggleFilter("closed")}
        >
          Tidigare ({filterCounts?.closed || 0})
        </SC.UninterestingFilterButton>
      </SC.FilterWrapper>
    </>
  );
}
