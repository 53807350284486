import styled, { css } from "styled-components";

import initiatedIcon from "../../../../assets/svg/scrive/initiated.svg";
import readIcon from "../../../../assets/svg/scrive/read.svg";
import deliveredIcon from "../../../../assets/svg/scrive/delivered.svg";
import problemIcon from "../../../../assets/svg/scrive/problem.svg";
import sealIcon from "../../../../assets/svg/scrive/seal.svg";
import signedIcon from "../../../../assets/svg/scrive/signed.svg";
import sentIcon from "../../../../assets/svg/scrive/sent.svg";
import rejectedIcon from "../../../../assets/svg/scrive/rejected.svg";
import timeoutedIcon from "../../../../assets/svg/scrive/timeouted.svg";
import extendedIcon from "../../../../assets/svg/scrive/extended.svg";

export const Event = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 8px;
  padding: 8px;
  font-family: "Inter", sans-serif;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const EventDate = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const EventText = styled.div`
  color: ${(p) => p.theme.colors.primaryText};
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
`;

export const EventTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const EventStatusIcon = styled.div`
  height: ${(p) => p.theme.fontSizes.headerSmall};
  width: ${(p) => p.theme.fontSizes.headerSmall};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  ${(p) =>
    p.status === "initiated" &&
    css`
      background-image: url(${initiatedIcon});
    `}

  ${(p) =>
    (p.status === "cancelled" || p.status === "rejected") &&
    css`
      background-image: url(${rejectedIcon});
    `}

    ${(p) =>
    p.status === "timeouted" &&
    css`
      background-image: url(${timeoutedIcon});
    `}


     ${(p) =>
    (p.status === "problem" || p.status === "deliveryproblem") &&
    css`
      background-image: url(${problemIcon});
    `}

      ${(p) =>
    p.status === "sent" &&
    css`
      background-image: url(${sentIcon});
    `}

       ${(p) =>
    p.status === "delivered" &&
    css`
      background-image: url(${deliveredIcon});
    `}

      ${(p) =>
    (p.status === "read" || p.status === "opened") &&
    css`
      background-image: url(${readIcon});
    `}

      ${(p) =>
    p.status === "signed" &&
    css`
      background-image: url(${signedIcon});
    `}
    
       ${(p) =>
    (p.status === "extended" || p.status === "prolonged") &&
    css`
      background-image: url(${extendedIcon});
    `}

      ${(p) =>
    p.status === "sealed" &&
    css`
      background-image: url(${sealIcon});
    `}
`;

export const PartyTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.primaryText};
`;

export const PartyRole = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.secondaryText};
  margin-bottom: 12px;
`;

export const PartyItem = styled.div`
  margin-top: 6px;
`;

export const PartyItemTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.primaryText};
`;
