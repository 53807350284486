import styled, { css } from "styled-components";
import editBlack from "../../assets/svg/material/edit_black.svg";
import editWhite from "../../assets/svg/material/edit_white.svg";
import checkWhite from "../../assets/svg/material/check_circle_white.svg";
import outlookWhite from "../../assets/svg/outlook-icon.svg";
import outlookBlack from "../../assets/svg/outlook-icon-black.svg";
import outlookBlue from "../../assets/svg/outlook-icon-blue.svg";

export const EditCalendarIcon = styled.div`
  height: 16px;
  width: 16px;
  padding-left: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${editWhite});

  ${(p) =>
    p.light &&
    css`
      background-image: url(${editBlack});
    `}
`;

export const OutlookIcon = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 6px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${outlookWhite});

  ${(p) =>
    p.light &&
    css`
      background-image: url(${outlookBlack});
    `}

  ${(p) =>
    p.blue &&
    css`
      background-image: url(${outlookBlue});
    `}
`;

export const OutlookActiveIcon = styled.div`
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${checkWhite});
  margin-left: 6px;
`;
