import moment from "moment";

export const convertedStatus = ({
  status,
  plannedStart,
  plannedEnd,
  actualStart,
  actualEnd,
}) => {
  const now = moment();
  let start;
  let end;
  let saidStart;
  let saidEnd;
  switch (status) {
    case -1:
      return -1; // aborted
    case 0:
      start = moment(plannedStart);
      end = moment(plannedEnd);

      if ((start.isValid() && now > start) || (end.isValid() && now > end)) {
        return 0; // delayed
      }
      return 1; // not started
    case 1:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);

      if (end.isValid() && now > end) {
        return 2; // started, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 3; // started, delayed start
      }

      return 4;
    case 2:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);

      if (end.isValid() && now > end) {
        return 5; // paused, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 6; // paused, delayed start
      }

      return 7;
    case 3:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);
      saidEnd = moment(actualEnd);

      if (end.isValid() && saidEnd.isValid() && saidEnd > end) {
        return 8; // done, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 9; // done, delayed start
      }

      return 10;
    case 4:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);
      saidEnd = moment(actualEnd);

      if (end.isValid() && saidEnd.isValid() && saidEnd > end) {
        return 11; // done tenant, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 12; // done tenant, delayed start
      }

      return 13; // done
    default:
      return 14; // unknown
  }
};

export const getErrandStatus = ({
  status,
  plannedStart,
  plannedEnd,
  actualStart,
  actualEnd,
}) => {
  const actualStatus = convertedStatus({
    status,
    plannedStart,
    plannedEnd,
    actualStart,
    actualEnd,
  });
  const getDisplay = () => {
    switch (actualStatus) {
      case -1:
        return "Avbryten";
      case 0:
        return "Försenad";
      case 1:
        return "Ej påbörjad";
      case 2:
        return "Påbörjad (försenat avklarande)";
      case 3:
        return "Påbörjad (försenad start)";
      case 4:
        return "Påbörjad";
      case 5:
        return "Pausad (försenat avklarande)";
      case 6:
        return "Pausad (försenad start)";
      case 7:
        return "Pausad";
      case 8:
        return "Avklarad (försenat avklarande)";
      case 9:
        return "Avklarad (försenad start)";
      case 10:
        return "Avklarad";
      case 11:
        return "Avklarat av hyresgäst (försenat avklarande)";
      case 12:
        return "Avklarat av hyresgäst (försenat start)";
      case 13:
        return "Avklarat av hyresgäst";
      default:
        return "Okänd";
    }
  };
  return { status: actualStatus, display: getDisplay() };
};

export const getErrandStatusColor = ({ status }) => {
  switch (status) {
    case -1:
      return "#69707D";
    case 0:
      return "rgb(235, 55, 43)";
    case 1:
      return "#8ECAE6";
    case 2:
      return "#2ec9b8";
    case 3:
      return "#2ec9b8";
    case 4:
      return "#2ec9b8";
    case 5:
      return "#fdb738";
    case 6:
      return "#fdb738";
    case 7:
      return "#fdb738";
    case 8:
      return "rgb(102,187,106)";
    case 9:
      return "rgb(102,187,106)";
    case 10:
      return "rgb(102,187,106)";
    case 11:
      return "#69707D";
    case 12:
      return "#69707D";
    case 13:
      return "#69707D";
    default:
      return "#69707D";
  }
};

export const getChosenPerformer = (errand) => {
  //Om performer är satt, så gäller den
  //Om performer inte är satt, men requested finns, gäller den (Förfrågad)
  //Om inget finns, Så är listan av tillgängliga tom
  //Vid nekande går det till nästa på rolllista, sätts på requested, är alla slut tillkommer nytt attribut -> ny endpoint finns att starta om listan
  const performer = errand?.performer;
  const requested_performer = errand?.requested_performer;

  if (performer) {
    return {
      id: performer?.id,
      userId: performer.user?.id,
      str_representation: performer.str_representation,
    };
  } else if (requested_performer) {
    return {
      id: requested_performer?.id,
      userId: requested_performer.user?.id,
      str_representation: `${requested_performer?.str_representation} (Förfrågad)`,
    };
  }
  return null;
};

export const getPerformer = (errand) => {
  const performer = errand?.performer;

  if (performer) {
    return {
      id: performer?.id,
      userId: performer.user?.id,
      str_representation: performer.str_representation,
    };
  }
  return null;
};

export const getRequestedPerformer = (errand) => {
  const requested_performer = errand?.requested_performer;

  if (requested_performer) {
    return {
      id: requested_performer?.id,
      userId: requested_performer.user?.id,
      str_representation: `${requested_performer?.str_representation} (Förfrågad)`,
    };
  }
  return null;
};

export const getReportErrandCost = (reportErrand) => {
  return (reportErrand?.costs || []).reduce((acc, curr) => {
    acc += (curr?.unit_cost || 0) * (curr?.unit_amount || 0);
  }, 0.0);
};

export const getWriteOffQuota = (component, code, forLandlord) => {
  const writeOffs = component?.write_offs;
  if (!writeOffs) {
    return 1;
  }

  const writeOff = writeOffs[code];
  const lifeStart = writeOff?.life_start;
  const lifeEnd = writeOff?.life_end;

  if (!lifeStart || !lifeEnd) {
    return 1;
  }

  const lifeStartM = moment(lifeStart);
  const lifeEndM = moment(lifeEnd);

  if (!lifeStartM.isValid() || !lifeEndM.isValid()) {
    return 1;
  }

  const today = moment();

  const livedTime = moment.duration(today.diff(lifeStartM)).milliseconds();
  const lifeTime = moment.duration(lifeEndM.diff(lifeStartM)).milliseconds();

  if (!lifeTime) {
    return forLandlord ? 1 : 0;
  }

  const quota = livedTime / lifeTime;

  if (forLandlord) {
    return 1 - quota;
  }
  return quota;
};

export const summarizeCostRows = (rows, component) => {
  return (rows || []).reduce(
    (acc, curr) => {
      const rowUnits = curr?.unit_amount || 0;
      const rowVat = curr?.vat || 0;

      let rowUnitCost = curr?.unit_cost || 0;

      let rowNet = rowUnitCost * rowUnits;

      if (curr?.article) {
        // write off!
        const forLandlord = ![0, 2].includes(curr?.liable);
        const quota = getWriteOffQuota(
          component,
          curr?.article?.code,
          forLandlord
        );
        rowNet *= quota;
      }

      const laborCost = curr?.labor_time_cost || 0;

      let laborMomentTime = moment.duration(curr?.work_duration);
      let laborTime = 0;
      if (moment.isDuration(laborMomentTime)) {
        const hours = laborMomentTime.hours();
        const minutes = laborMomentTime.minutes() / 60;
        laborTime = hours + minutes;
      }

      rowNet += laborCost * laborTime;

      const rowVatValue = rowNet * (rowVat / 100);

      return { net: rowNet + acc.net, vat: rowVatValue + acc.vat };
    },
    { net: 0, vat: 0 }
  );
};

export const getErrandRequestedStatus = (requested) => {
  const accepted = Boolean(requested?.accept_time);
  const declined = Boolean(requested?.decline_time);

  if (declined) {
    return 1;
  }
  if (accepted) {
    return 2;
  }
  return 0;
};

export const getErrandRequestedString = (requested) => {
  const status = getErrandRequestedStatus(requested);
  switch (status) {
    case 0:
      return "Inväntande";
    case 1:
      return "Nekad";
    case 2:
      return "Accepterad";
    default:
      return "Okänt";
  }
};

export const getErrandRequestedRelevantDate = (requested) => {
  const status = getErrandRequestedStatus(requested);
  switch (status) {
    case 0:
      return requested?.asked_time;
    case 1:
      return requested?.decline_time;
    case 2:
      return requested?.accept_time;
    default:
      return undefined;
  }
};

export const getErrandRequestedColor = (requested) => {
  const status = getErrandRequestedStatus(requested);
  switch (status) {
    case 0:
      return "gray";
    case 1:
      return "red";
    case 2:
      return "green";
    default:
      return "gray";
  }
};
