export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SENSOR",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SENSOR",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SENSOR",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SENSOR",
  DESTROY_FORM: "DESTROY_FORM_SENSOR",
  SET_FORM_ERROR: "SET_FORM_ERROR_SENSOR",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SENSOR",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SENSOR",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SENSOR",
  RESET_STATE: "RESET_STATE_SENSOR",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SENSOR",
  REMOVE_OBJECT: "REMOVE_OBJECT_SENSOR",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_SENSOR",
  CLEAR_FETCHED: "CLEAR_FETCHED_SENSOR",

  CLEAR_SENSORS_FROM_FORM_INSTANCE: "CLEAR_SENSORS_FROM_FORM_INSTANCE",

  LIST_URL: "/imd/sensor/list/",
  POST_URL: "/imd/sensor/",
  PATCH_URL: "/imd/sensor/",
  GET_URL: "/imd/sensor/",

  STORE_NAME: "IMDSensor",
};
