import {
  useFilteredRealEstates,
  useRealEstates,
  detailUrl as useRealEstateDetailRedirect,
  useRealEstate,
  constants as realEstateConstants,
} from "./realEstates";
import {
  useCompanies,
  useFilteredCompanies,
  detailUrl as useCompanyDetailRedirect,
  useCompany,
  constants as companyConstants,
} from "./companies";
import {
  useFilteredLeaseContracts,
  useLeaseContracts,
  detailUrl as useLeaseDetailRedirect,
  useLeaseContract,
  constants as leaseConstants,
} from "./leaseContracts";
import {
  useBuildings,
  useFilteredBuildings,
  detailUrl as useBuildingDetailRedirect,
  useBuilding,
  constants as buildingConstants,
} from "./buildings";
import {
  useFilteredTenants,
  useTenants,
  detailUrl as useTenantDetailRedirect,
  useTenant,
  constants as tenantConstants,
} from "./tenants";
import {
  useFilteredUsers,
  useUsers,
  detailUrl as useUserDetailRedirect,
  useUser,
  constants as userConstants,
} from "./users";
import {
  useFilteredIndustrialPremises,
  useIndustrialPremisesList,
  detailUrl as useIndustrialDetailRedirect,
  useIndustrialPremises,
  constants as industrialConstants,
} from "./industrialPremises";
import {
  useApartments,
  useFilteredApartments,
  detailUrl as useApartmentDetailRedirect,
  useApartment,
  constants as apartmentConstants,
} from "./apartments";
import {
  useCommonAreas,
  useFilteredCommonAreas,
  detailUrl as commonAreaDetailUrl,
  useCommonArea,
  constants as commonAreaConstants,
} from "./commonAreas";
import {
  useFilteredOtherContracts,
  useOtherContracts,
  detailUrl as useOtherDetailRedirect,
  useOtherContract,
  constants as otherConstants,
} from "./otherContracts";
import {
  useBasicDocs,
  useFilteredBasicDocs,
  detailUrl as useBasicDetailRedirect,
  useBasicDoc,
  constants as basicConstants,
} from "./basicDocs";
import {
  useAddresses,
  useFilteredAddresses,
  constants as addressConstants,
} from "./addresses";
import {
  constants as serviceContractConstants,
  useServiceContract,
  useServiceContracts,
  useFilteredServiceContracts,
  detailUrl as serviceContractDetailUrl,
} from "./serviceContracts";
import { constants as servicePartnerConstants } from "./servicePartners";
import {
  constants as parkingContractConstants,
  useParkingContract,
  useFilteredParkingContracts,
  useParkingContracts,
  detailUrl as parkingContractDetailUrl,
} from "./parkingContracts";
import {
  constants as parkingLotConstants,
  useParkingLot,
  useFilteredParkingLots,
  useParkingLots,
  detailUrl as parkingLotDetailUrl,
} from "./parkingLots";
import {
  constants as parkingSpotConstants,
  detailUrl as parkingSpotDetailUrl,
  useParkingSpot,
  useParkingSpots,
  useFilteredParkingSpots,
} from "./parkingSpots";
import {
  constants as imdMeasureTypeConstants,
  detailUrl as imdMeasureTypeDetailUrl,
  useMeasureType,
  useMeasureTypes,
  useFilteredMeasureTypes,
} from "./IMDMeasureType";
import {
  constants as imdSensorConstants,
  detailUrl as imdSensorDetailUrl,
  useSensor,
  useSensors,
  useFilteredSensors,
} from "./IMDSensor";
import { constants as parkingZoneConstants } from "./parkingZones";
import { constants as parkingSpecificationConstants } from "./parkingSpecifications";
import {
  constants as reportErrandConstants,
  detailUrl as reportErrandDetailUrl,
  useReportErrand,
} from "./reportErrands";
import {
  constants as inspectionErrandConstants,
  detailUrl as inspectionErrandDetailUrl,
  useInspectionErrand,
} from "./inspectionErrands";
import {
  useFilteredBrfPremises,
  useBrfPremises,
  useBrfPremisesList,
  constants as brfpremisConstants,
  detailUrl as brfPremisDetailUrl,
} from "./brfPremises";
import {
  constants as brfcompanyConstants,
  useFilteredBrfCompanies,
  useBrfCompanies,
  useBrfCompany,
  detailUrl as brfCompanyDetailUrl,
} from "./brfCompanies";
import { constants as reportErrandSettingConstants } from "./reportErrandSettings";
import { constants as reportsConstants } from "./excelReports";
import { constants as roundingErrandSettingConstants } from "./roundingErrandSettings";
import { constants as roundingErrandFaultConstants } from "./roundingErrandFaults";
import { constants as inspectionErrandSettingConstants } from "./inspectionErrandSettings";
import { constants as errandArticleConstants } from "./errandArticles";
import { constants as componentTypeConstants } from "./componentTypes";
import { constants as errandComponentConstants } from "./errandComponents";
import { constants as checklistConstants } from "./checklists";
import { constants as checklistRowConstants } from "./checklistRows";
import { constants as errandProductTypeConstants } from "./productTypes";
import { constants as roundingComponentConstants } from "./roundingComponents";
import { constants as roundingAreaConstants } from "./roundingAreas";
import { constants as errandRatingConstants } from "./errandRatings";
import { constants as sendoutConstants } from "./sendout";
import { constants as tenantgroupConstants } from "./tenantGroup";
import { constants as brfcontractConstants } from "./brfContract";
import { constants as marketingCommoditiesConstants } from "./marketApartmentCommodities";
import { constants as marketingAdsConstants } from "./marketApartmentAds";
import { constants as marketingRequirementsConstants } from "./marketRequirements";
import { constants as invoicingErrorConstants } from "./invoicingErrors";
import { constants as syncErrorConstants } from "./externalSyncErrors";
import { constants as invoicingProjectConstants } from "./invoicingProjects";
import { constants as invoicingCostCenterConstants } from "./invoicingCostCenters";
import { constants as invoicingProductsConstants } from "./invoicingProducts";
import { constants as invoicingDebtSettingConstants } from "./invoicingDebtSettings";
import { constants as invoicingCompanyConstants } from "./invoicingCompany";
import { constants as invoicingIMDConstants } from "./invoicingIMDSettings";
import { constants as invoicingSettingConstants } from "./invoicingSettings";
import { constants as invoicingLeaseConstants } from "./invoicingLease";
import { constants as invoicingOtherConstants } from "./invoicingOther";
import { constants as invoicingParkingConstants } from "./invoicingParking";
import { constants as invoicingBRFConstants } from "./invoicingBrf";
import { constants as invoicingDebtorConstants } from "./invoicingDebtor";
import { constants as fortnoxCredentialConstants } from "./fortnoxCredential";
import { constants as fortnoxConfigConstants } from "./fortnoxConfig";
import { constants as fortnoxAccountConstants } from "./fortnoxAccount";
import { constants as fortnoxSupplierConstants } from "./fortnoxSupplierInvoice";
import { constants as fortnoxTransactionConstants } from "./fortnoxTransaction";
import { constants as calendarConstants } from "./calendar";
import { constants as calendarEventConstants } from "./calendarEvents";
import { constants as recipientGroupConstants } from "./recipientGroup";
import { constants as billectaAccountingReportConstants } from "./billectaAccountingReports";
import { constants as billectaAccountsRecievableConstants } from "./billectaAccountsReceivable";
import { constants as billectaBookKeepingConstants } from "./billectaBookKeeping";
import { constants as billectaEventConstants } from "./billectaEvents";
import { constants as billectaIntegrationConstants } from "./billectaIntegrations";
import { constants as billectaInvoicingConstants } from "./billectaInvoicing";
import { constants as billectaKycConstants } from "./billectaKyc";
import { constants as billectaPaymentMeanConstants } from "./billectaPaymentMeans";
import { constants as tagConstants, performFilter as filterTags } from "./tags";
import { constants as keyConstants } from "./key";
import { constants as keyDeviceConstants } from "./keydevice";
import { constants as keyPermissionConstants } from "./keypermission";
import { constants as pipeConstants } from "./pipes";
import { constants as pipeStageConstants } from "./pipeStages";
import { constants as leadConstants } from "./leads";
import { constants as pipeAttributeConstants } from "./pipeAttributes";
import { constants as brfPaymentGroupQuotaConstants } from "./brfPaymentGroupQuota";
import { constants as loanConstants } from "./loan";
import { constants as paymentGroupConstants } from "./paymentGroup";
import { constants as paymentContributionConstants } from "./paymentContribution";
import { constants as overviewConstants } from "./overview";

import { constants as insightsPageConstants } from "./insightsPage";
import { constants as insightsGoalConstants } from "./insightsGoal";
import { constants as realestatePortfolioConstants } from "./realEstatePortfolios";

import { store } from "./store";
import { getBackgroundAddStateType } from "./base";
import { getCancelToken } from "./base/store/axios";
import { useBlueprints, useFilteredBlueprints } from "./blueprints";
import { clearFetched } from "./base/store/actions";
import { debounce } from "lodash";
import { isDev } from "../index";

export const fetchMethodFromKind = (kind, filter) => {
  switch (kind) {
    case "standard.realestate":
      return filter ? useFilteredRealEstates : useRealEstates;
    case "standard.company":
      return filter ? useFilteredCompanies : useCompanies;
    case "standard.leasecontract":
      return filter ? useFilteredLeaseContracts : useLeaseContracts;
    case "accounts.user":
      return filter ? useFilteredUsers : useUsers;
    case "accounts.tenant":
      return filter ? useFilteredTenants : useTenants;
    case "standard.blueprint":
      return filter ? useFilteredBlueprints : useBlueprints;
    case "standard.building":
      return filter ? useFilteredBuildings : useBuildings;
    case "standard.industrialpremises":
      return filter ? useFilteredIndustrialPremises : useIndustrialPremisesList;
    case "standard.apartment":
      return filter ? useFilteredApartments : useApartments;
    case "standard.commonarea":
      return filter ? useFilteredCommonAreas : useCommonAreas;
    case "standard.othercontract":
      return filter ? useFilteredOtherContracts : useOtherContracts;
    case "standard.basicdoc":
      return filter ? useFilteredBasicDocs : useBasicDocs;
    case "general.address":
      return filter ? useFilteredAddresses : useAddresses;
    case "brf.brfpremis":
      return filter ? useFilteredBrfPremises : useBrfPremisesList;
    case "brf.brfcompany":
      return filter ? useFilteredBrfCompanies : useBrfCompanies;
    case "standard.parkingcontract":
      return filter ? useFilteredParkingContracts : useParkingContracts;
    case "standard.servicecontract":
      return filter ? useFilteredServiceContracts : useServiceContracts;
    case "standard.parkingspot":
      return filter ? useFilteredParkingSpots : useParkingSpots;
    case "standard.parkinglot":
      return filter ? useFilteredParkingLots : useParkingLots;
    case "imd.measuretype":
      return filter ? useFilteredMeasureTypes : useMeasureTypes;
    case "imd.sensor":
      return filter ? useFilteredSensors : useSensors;
    default:
      return undefined;
  }
};

export const fetchSingleMethodFromKind = (kind) => {
  switch (kind) {
    case "standard.realestate":
      return useRealEstate;
    case "standard.company":
      return useCompany;
    case "standard.leasecontract":
      return useLeaseContract;
    case "accounts.user":
      return useUser;
    case "accounts.tenant":
      return useTenant;
    case "standard.building":
      return useBuilding;
    case "standard.industrialpremises":
      return useIndustrialPremises;
    case "standard.apartment":
      return useApartment;
    case "standard.commonarea":
      return useCommonArea;
    case "standard.othercontract":
      return useOtherContract;
    case "standard.basicdoc":
      return useBasicDoc;
    case "brf.brfpremis":
      return useBrfPremises;
    case "brf.brfcompany":
      return useBrfCompany;
    case "standard.parkingcontract":
      return useParkingContract;
    case "standard.servicecontract":
      return useServiceContract;
    case "standard.parkingspot":
      return useParkingSpot;
    case "standard.parkinglot":
      return useParkingLot;
    case "imd.measuretype":
      return useMeasureType;
    case "imd.sensor":
      return useSensor;
    case "errands.reporterrand":
      return useReportErrand;
    case "errands.inspectionerrand":
      return useInspectionErrand;
    default:
      return undefined;
  }
};

export const redirectMethodFromKind = (kind) => {
  switch (kind) {
    case "standard.realestate":
      return useRealEstateDetailRedirect;
    case "standard.company":
      return useCompanyDetailRedirect;
    case "standard.leasecontract":
      return useLeaseDetailRedirect;
    case "accounts.user":
      return useUserDetailRedirect;
    case "accounts.tenant":
      return useTenantDetailRedirect;
    case "standard.building":
      return useBuildingDetailRedirect;
    case "standard.industrialpremises":
      return useIndustrialDetailRedirect;
    case "standard.commonarea":
      return commonAreaDetailUrl;
    case "standard.apartment":
      return useApartmentDetailRedirect;
    case "standard.othercontract":
      return useOtherDetailRedirect;
    case "standard.basicdoc":
      return useBasicDetailRedirect;
    case "brf.brfpremis":
      return brfPremisDetailUrl;
    case "brf.brfcompany":
      return brfCompanyDetailUrl;
    case "standard.parkingcontract":
      return parkingContractDetailUrl;
    case "standard.servicecontract":
      return serviceContractDetailUrl;
    case "standard.parkingspot":
      return parkingSpotDetailUrl;
    case "standard.parkinglot":
      return parkingLotDetailUrl;
    case "imd.measuretype":
      return imdMeasureTypeDetailUrl;
    case "imd.sensor":
      return imdSensorDetailUrl;
    case "errands.reporterrand":
      return reportErrandDetailUrl;
    case "errands.inspectionerrand":
      return inspectionErrandDetailUrl;
    case "events.todo":
      return () => "/";
    default:
      if (isDev()) {
        return () =>
          alert(
            `No implemention for that type (${kind}). Please, implement me!`
          );
      }
      return undefined;
  }
};

export const resetAllStates = () => {
  return async (dispatch) => {
    dispatch({
      type: realEstateConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: companyConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: apartmentConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: industrialConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: addressConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: tenantConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: userConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: otherConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: basicConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: overviewConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: buildingConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: leaseConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: parkingContractConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: parkingLotConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: parkingSpotConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: parkingZoneConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: parkingSpecificationConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: reportErrandConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: inspectionErrandConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: reportErrandSettingConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: reportsConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: roundingErrandSettingConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: roundingErrandFaultConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: inspectionErrandSettingConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: errandArticleConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: componentTypeConstants.RESET_STATE,
      payload: {},
    });

    dispatch({
      type: errandComponentConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: roundingComponentConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: roundingAreaConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: errandRatingConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaAccountingReportConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaAccountsRecievableConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaBookKeepingConstants.RESET_STATE,
      payload: {},
    });

    dispatch({
      type: billectaEventConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaIntegrationConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaInvoicingConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaKycConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaPaymentMeanConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: serviceContractConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: servicePartnerConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: tagConstants.RESET_STATE,
      payload: {},
    });
  };
};

export const getWantedBackgroundTasks = () => {
  // currently all the background tasks we want is to fetch all data
  // for leasecontracts, apartments, industrialpremises
  // tenants, othercontracts and basicdocs

  // if you add other actions than filter actions, make sure
  // that they accept the taskToken parameter

  let result = [];
  const querystring = "";
  const state = store.getState();

  const allWanted = [
    // [leaseConstants.STORE_NAME, filterLeases],
    // [apartmentConstants.STORE_NAME, filterApartments],
    // [industrialConstants.STORE_NAME, filterIndustrials],
    // [tenantConstants.STORE_NAME, filterTenants],
    // [otherConstants.STORE_NAME, filterOthers],
    // [basicConstants.STORE_NAME, filterBasics],
    // [serviceContractConstants.STORE_NAME, filterServiceContracts],
    // [parkingContractConstants.STORE_NAME, filterParkingContracts],
  ];

  allWanted.forEach((w) => {
    const [storeName, filterAction] = w;

    // we already got it, or we've already activated it
    if (
      state[storeName].filtered[querystring] ||
      state[storeName].inProgress.includes(querystring)
    ) {
      return;
    }

    // assumes an array of holding values [filterAction, name, "state type to add background task"]
    result.push([
      (dispatch) =>
        dispatch(filterAction(querystring, undefined, getCancelToken())),
      querystring,
      getBackgroundAddStateType(storeName),
    ]);
  });

  return result;
};

export const getConstants = (key) => {
  switch (key) {
    case "events.calendar":
      return calendarConstants;
    case "events.todo":
      return calendarEventConstants;
    case "events.recipientgroup":
      return recipientGroupConstants;
    case "events.sendout":
      return sendoutConstants;
    case "standard.realestate":
      return realEstateConstants;
    case "standard.company":
      return companyConstants;
    case "standard.leasecontract":
      return leaseConstants;
    case "standard.building":
      return buildingConstants;
    case "accounts.tenant":
      return tenantConstants;
    case "accounts.user":
      return userConstants;
    case "standard.industrialpremises":
      return industrialConstants;
    case "standard.apartment":
      return apartmentConstants;
    case "standard.commonarea":
      return commonAreaConstants;
    case "standard.othercontract":
      return otherConstants;
    case "standard.basicdoc":
      return basicConstants;
    case "standard.servicecontract":
      return serviceContractConstants;
    case "standard.parkingcontract":
      return parkingContractConstants;
    case "standard.parkinglot":
      return parkingLotConstants;
    case "standard.parkingspot":
      return parkingSpotConstants;
    case "standard.parkingzone":
      return parkingZoneConstants;
    case "standard.parkingspecification":
      return parkingSpecificationConstants;
    case "errands.reporterrand":
      return reportErrandConstants;
    case "errands.inspectionerrand":
      return inspectionErrandConstants;
    case "errands.reporterrandsetting":
      return reportErrandSettingConstants;
    case "reporting.performedreport":
      return reportsConstants;
    case "errands.roundingerrandsetting":
      return roundingErrandSettingConstants;
    case "errands.roundingerrandfault":
      return roundingErrandFaultConstants;
    case "errands.inspectionerrandsetting":
      return inspectionErrandSettingConstants;
    case "errands.costarticle":
      return errandArticleConstants;
    case "errands.componenttype":
      return componentTypeConstants;
    case "errands.producttype":
      return errandProductTypeConstants;
    case "errands.componentplacement":
      return errandComponentConstants;
    case "errands.checklist":
      return checklistConstants;
    case "errands.checklistrow":
      return checklistRowConstants;
    case "errands.roundingcomponent":
      return roundingComponentConstants;
    case "errands.roundingarea":
      return roundingAreaConstants;
    case "errands.componentrating":
      return errandRatingConstants;
    case "accounting.reportevent":
      return billectaEventConstants;
    case "general.tag":
      return tagConstants;
    case "market.pipe":
      return pipeConstants;
    case "market.stage":
      return pipeStageConstants;
    case "market.lead":
      return leadConstants;
    case "market.dynamicpipeattribute":
      return pipeAttributeConstants;
    case "brf.brfpremis":
      return brfpremisConstants;
    case "brf.brfcontract":
      return brfcontractConstants;
    case "brf.brftenantgroup":
      return tenantgroupConstants;
    case "brf.brfcompany":
      return brfcompanyConstants;
    case "market.ad":
      return marketingAdsConstants;
    case "market.commodities":
      return marketingCommoditiesConstants;
    case "market.requirement":
      return marketingRequirementsConstants;
    case "accounting.billectaouterror":
      return invoicingErrorConstants;
    case "apihandler.externalsyncerror":
      return syncErrorConstants;
    case "accounting.project":
      return invoicingProjectConstants;
    case "accounting.costcenter":
      return invoicingCostCenterConstants;
    case "accounting.product":
      return invoicingProductsConstants;
    case "accounting.debthandlingsetting":
      return invoicingDebtSettingConstants;
    case "accounting.companyinvoiceconfig":
      return invoicingCompanyConstants;
    case "accounting.imdinvoicingconfig":
      return invoicingIMDConstants;
    case "accounting.invoicingsetting":
      return invoicingSettingConstants;
    case "accounting.leaseinvoicing":
      return invoicingLeaseConstants;
    case "accounting.otherinvoicing":
      return invoicingOtherConstants;
    case "accounting.parkinginvoicing":
      return invoicingParkingConstants;
    case "accounting.brfinvoicing":
      return invoicingBRFConstants;
    case "accounting.debtorinvoiceconfig":
      return invoicingDebtorConstants;
    case "accounting.fortnoxcredential":
      return fortnoxCredentialConstants;
    case "accounting.fortnoxconfig":
      return fortnoxConfigConstants;
    case "accounting.fortnoxaccount":
      return fortnoxAccountConstants;
    case "accounting.fortnoxtransaction":
      return fortnoxTransactionConstants;
    case "accounting.fortnoxsuppliererrandinvoice":
      return fortnoxSupplierConstants;
    case "imd.measuretype":
      return imdMeasureTypeConstants;
    case "imd.imdsensor":
      return imdSensorConstants;
    case "keyhandling.key":
      return keyConstants;
    case "keyhandling.keydevice":
      return keyDeviceConstants;
    case "keyhandling.keypermission":
      return keyPermissionConstants;
    case "brf.loan":
      return loanConstants;
    case "brf.paymentgroup":
      return paymentGroupConstants;
    case "brf.paymentgroupcontribution":
      return paymentContributionConstants;
    case "brf.paymentgroupquota":
      return brfPaymentGroupQuotaConstants;
    case "insights.insightspage":
      return insightsPageConstants;
    case "insights.insightsgoal":
      return insightsGoalConstants;
    case "standard.realestateportfolio":
      return realestatePortfolioConstants;
    default:
      return undefined;
  }
};

/**
 * object with debounce function dynaså att man inte bara kommer till / utan att man mically created when needed
 * key            :  value
 * socketStoreName: debounced function
 */
const debouncedDefs = {};

const updateLocalStore = ({ socketStoreName, dispatch }) => {
  // console.log("[UPDATE LOCAL STORAGE] - [socketStoreName]: ", socketStoreName);
  const constants = getConstants(socketStoreName);
  dispatch(clearFetched(constants, true));
};

export const handleSocketUpdates = (event, dispatch) => {
  // data is an array of strings, "list___.${app_label}_${model_name}"  for clearing lists
  const data = JSON.parse(event?.data);

  let handled = [];

  data.forEach((cacheStr) => {
    const clenadCacheStr = cacheStr.replace("list___.", "");
    const [appLabel, modelName] = clenadCacheStr.split("_");
    const socketStoreName = `${appLabel}.${modelName}`;
    if (handled.includes(socketStoreName)) {
      return;
    }

    const constants = getConstants(socketStoreName);

    // not found or we don't care about it;
    if (!constants) return;

    window.dispatchEvent(
      new CustomEvent("TABLE_SOCKET_CHANGES", {
        bubbles: true,
        detail: { storeName: constants.STORE_NAME },
      })
    );

    // const queryString = buildQueryString({
    //   id__in: id || [],
    // });

    /**
     * If we already have the debounced function setup, call it
     * else
     * create it and then call it
     */

    if (debouncedDefs[socketStoreName]) {
      const debouncedFunc = debouncedDefs[socketStoreName];
      // console.log(
      //   "[EXISTING DEBOUNCE CALL] - [socketStoreName]: ",
      //   socketStoreName
      // );
      debouncedFunc({ socketStoreName, dispatch });
    } else {
      debouncedDefs[socketStoreName] = debounce(updateLocalStore, 1000);
      const debouncedFunc = debouncedDefs[socketStoreName];
      // console.log(
      //   "[NEW DEBOUNCE CALL] - [socketStoreName]: ",
      //   socketStoreName
      // );
      debouncedFunc({ socketStoreName, dispatch });
    }

    handled.push(socketStoreName);
    return;
  });
};
