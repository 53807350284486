import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingCompany";

import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InfoBox } from "../../../../Displays";
import InvoicingCustomDimensionsEntriesTable from "src/components/Tables/InvoicingCustomDimensionEntries/FullTable";
import InvoicingCustomDimensionsTable from "src/components/Tables/InvoicingCustomDimensions/FullTable";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const selectedDimension = useFormInstanceField({
    storeName,
    fieldKey: "_interal_dimension.id",
  });
  const selectedDimensionEntry = useFormInstanceField({
    storeName,
    fieldKey: "additional_dimension.dimension_entry.id",
  });

  const selectedOverrideDimension = useFormInstanceField({
    storeName,
    fieldKey: "_interal_payment_dimension_override.id",
  });
  const selectedOverrideDimensionEntry = useFormInstanceField({
    storeName,
    fieldKey: "payment_dimension_override.dimension_entry.id",
  });

  const persistantQuery = {
    dimension: selectedDimension,
  };
  const persistantOverrideQuery = {
    dimension: selectedOverrideDimension,
  };

  const clearDimension = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          additional_dimension: null,
        },
      })
    );
  };

  const clearOverrideDimension = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          payment_dimension_override: null,
        },
      })
    );
  };

  return (
    <>
      <FormAreaTitle>Detaljerade bokföringsinställningar</FormAreaTitle>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Anpassad bokföringsdimension för bolag
          </OverviewTitle>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          {!selectedDimensionEntry && (
            <TableSelectField
              forceRender
              storeName={storeName}
              method={method}
              fieldKey="_interal_dimension"
              title="Anpassad dimension"
              placholder={"Välj anpassad dimension..."}
              TableComponent={InvoicingCustomDimensionsTable}
            />
          )}

          {(selectedDimension || selectedDimensionEntry) && (
            <TableSelectField
              storeName={storeName}
              method={method}
              fieldKey="additional_dimension.dimension_entry"
              title="Instans av dimension"
              placeholder={"Välj instans av dimension..."}
              persistantQuery={persistantQuery}
              TableComponent={InvoicingCustomDimensionsEntriesTable}
            />
          )}
        </div>

        {selectedDimensionEntry && (
          <TextButton
            title="Ta bort den valda dimensionen"
            iconType="sync"
            iconPlacement="right"
            red
            clicked={clearDimension}
          />
        )}
      </InnerBox>

      <hr />
      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Anpassad bokföringsdimension för överskrivning av dimension vid
            inbetalningar
          </OverviewTitle>
        </OverviewTitleWrapper>

        <InfoBox
          title="Överskrivning av dimension vid inbetalningar"
          text='Den valda dimensionen kommer att skriva över den anpassade dimensionen för t.ex. hyresgäster om transaktionen sker på ett av de konton som är listade som inbetalningskonton under "Bokföringsinställningar" -> "Betalsätt". Detta sker dock enbart om SIE-ID:t för dimensionerna överrensstämmer.'
        />

        <div className="grid grid-cols-2 gap-6 mb-6">
          {!selectedOverrideDimensionEntry && (
            <TableSelectField
              forceRender
              storeName={storeName}
              method={method}
              fieldKey="_interal_payment_dimension_override"
              title="Anpassad dimension för överskrivning"
              placeholder={"Välj anpassad dimension..."}
              TableComponent={InvoicingCustomDimensionsTable}
            />
          )}

          {(selectedOverrideDimension || selectedOverrideDimensionEntry) && (
            <TableSelectField
              storeName={storeName}
              fieldKey="payment_dimension_override.dimension_entry"
              method={method}
              title="Instans av dimension"
              placeholder={"Välj instans av dimension..."}
              persistantQuery={persistantOverrideQuery}
              TableComponent={InvoicingCustomDimensionsEntriesTable}
            />
          )}
        </div>

        {selectedOverrideDimensionEntry && (
          <TextButton
            title="Ta bort den valda dimensionen"
            iconType="sync"
            iconPlacement="right"
            red
            clicked={clearOverrideDimension}
          />
        )}
      </InnerBox>
    </>
  );
};
