import { cloneDeep } from "lodash";
import { updateActiveFormInstance } from "../../base";
import constants from "./constants";
import { constants as paymentMeansConstants } from "../../billectaPaymentMeans";
import { store } from "../../store";
import * as services from "./services";
import * as paymentMeansServices from "../../billectaPaymentMeans/store/services";

export const getBookKeepingInstance = ({ creditorId, successCallback }) => {
  return async (dispatch) => {
    const instance = await services.getBookKeepingInstance(creditorId);

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: instance,
      })
    );

    successCallback && successCallback();
  };
};

/**
 * Handles submitting of both paymentmeans and book keeping settings
 * (which are handled separately by Billecta),
 * since we have joined the two forms for UI purposes
 */
export const updateBookKeepingSettings = ({
  successCallback,
  errorCallback,
  creditorId,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const bookKeepingFormInstance = cloneDeep(
        state[constants.STORE_NAME].formInstance
      );
      const paymentMeansInstance = cloneDeep(
        state[paymentMeansConstants.STORE_NAME].formInstance
      );

      const promises = [
        services.updateBookKeeping({
          data: bookKeepingFormInstance,
          creditorId,
        }),
        paymentMeansServices.updatePaymentMeans({
          // need to be array
          data: Object.values(paymentMeansInstance),
          creditorId,
        }),
      ];

      const [bookKeepingResponse, paymentMeansResponse] = await Promise.all(
        promises
      );

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.data?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const getPeriodLockPeriod = ({ creditorId }) => {
  return async (dispatch) => {
    const { To: res } = await services.getPeriodLockPeriod({ creditorId });

    dispatch({
      type: constants.SET_PERIOD_LOCK_PERIOD,
      payload: {
        lockPeriod: res,
        creditorId,
      },
    });
  };
};

export const lockPeriod = ({
  creditorId,
  date,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.lockBookkeepingPeriod({ creditorId, date });

      const { To: newData } = await services.getPeriodLockPeriod({
        creditorId,
      });

      dispatch({
        type: constants.SET_PERIOD_LOCK_PERIOD,
        payload: {
          lockPeriod: newData,
          creditorId,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.Message;
      console.log(e);
      errorCallback && errorCallback(message);
    }
  };
};

export const deleteLockPeriod = ({
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.removeLockBookkeepingPeriod({
        creditorId,
      });

      const { To: newData } = await services.getPeriodLockPeriod({
        creditorId,
      });

      dispatch({
        type: constants.SET_PERIOD_LOCK_PERIOD,
        payload: {
          lockPeriod: newData,
          creditorId,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.Message;
      console.log(e);
      errorCallback && errorCallback(message);
    }
  };
};
