import { cloneDeep } from "lodash";
import * as React from "react";
import Select from "react-select";

// style, design
import * as SC from "../../Forms/Base/Fields/styles";
import { InnerBox } from "../../sharedStyles";
import Toggle from "../../Toggle/Toggle";

export default React.memo(
  ({
    listDefs,
    setHidden,
    extraStyles,
    value,
    setValue,
    localStorageHiddenColumnId,
  }) => {
    const choices = listDefs.map((l) => {
      return {
        str_representation: l.Header,
        id: l.id || l.accessor,
      };
    });

    const formattedChoices = choices.map((c) => c.id);

    const onChange = (columnId) => {
      let valueCopy = cloneDeep(value);

      if (value.includes(columnId)) {
        valueCopy = valueCopy.filter((v) => v !== columnId);
      } else {
        valueCopy.push(columnId);
      }
      setValue(valueCopy);

      const hidden = formattedChoices.filter(
        (c) => !valueCopy.some((d) => d === c)
      );
      setHidden(hidden);
      if (localStorageHiddenColumnId) {
        localStorage.setItem(localStorageHiddenColumnId, hidden);
      }
    };
    return (
      <InnerBox style={{ maxWidth: 600 }}>
        <SC.InputSpacing style={{ ...extraStyles }}>
          {choices.map((c) => (
            <React.Fragment key={c.id}>
              <Toggle
                value={value.includes(c.id)}
                title={c.str_representation || "Kolumn utan namn"}
                onToggle={() => onChange(c.id)}
              />
              <hr style={{ margin: "4px 0" }} />
            </React.Fragment>
          ))}
        </SC.InputSpacing>
      </InnerBox>
    );
  }
);
