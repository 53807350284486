import * as React from "react";
import * as SharedStyles from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import BrfCompanyTable from "../../../components/Tables/BrfCompanies/FullTable";
import { useFilteredBrfCompanies } from "../../../store/brfCompanies";
import Alert from "src/components/Alert/Alert";

export default function BrfCompanies() {
  const { filteredRealEstates, filteredBrfCompanies } = useActiveFilters();

  const cooperativeQuery = buildQueryString({
    company__in: filteredBrfCompanies,
  });

  const [matchingBrfCompanies] = useFilteredBrfCompanies(cooperativeQuery);

  let ids = filteredBrfCompanies;

  if (filteredRealEstates && matchingBrfCompanies) {
    ids = [...ids, ...matchingBrfCompanies.map((m) => m.id)];
  }

  const persistantQuery = {
    id__in: ids,
    brfcompany__isnull: false,
  };

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Översikt</OverviewTitle>
        </OverviewTitleWrapper>

        <Alert title="Filtrera globalt på BRF:er">
          Genom att markera ett eller flera bostadsrättsföreningar i listan och
          klicka på "Filtrera" så filtrerar du systemet globalt på fastigheter
          och objekt som ligger under dessa bostadsrättsföreningar.
        </Alert>

        <BrfCompanyTable withSelect {...{ persistantQuery }} />
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
