import * as React from "react";
import { useRouteMatch, Redirect, Route, Switch } from "react-router-dom";

import * as SC from "../../styles";

import useQuery from "../../../../utils/useQuery";

import IntegrationsOverview from "../../../../../views/Integrations/MainOverview";

import YourBlockIntegration from "../../../../../views/Integrations/Integrations/Yourblock";
import OutlookIntegration from "../../../../../views/Integrations/Integrations/Outlook";
import InfometricIntegration from "../../../../../views/Integrations/Integrations/Infometric";
import ElvacoIntegration from "../../../../../views/Integrations/Integrations/Elvaco";
import IntercomIntegration from "../../../../../views/Integrations/Integrations/Intercom";
import FreshChatIntegration from "../../../../../views/Integrations/Integrations/Freshchat";
import BokaMeraIntegration from "../../../../../views/Integrations/Integrations/Bokamera";
import Unit4ErpIntegration from "../../../../../views/Integrations/Integrations/Unit4Erp";
import XLedgerIntegration from "../../../../../views/Integrations/Integrations/XLedger";
import TwilioSMSIntegration from "../../../../../views/Integrations/Integrations/TwilioSMS";
import AutogiroIntegration from "../../../../../views/Integrations/Integrations/Autogiro";
import AvyTmplIntegration from "../../../../../views/Integrations/Integrations/AvyTmpl";
import FortnoxAccountingIntegration from "../../../../../views/Integrations/Integrations/FortnoxAccounting";
import KivraIntegration from "../../../../../views/Integrations/Integrations/Kivra";
import VismaEEconomyIntegration from "../../../../../views/Integrations/Integrations/VismaEEconomy";
import ScriveIntegration from "../../../../../views/Integrations/Integrations/Scrive";
import FastDocIntegration from "../../../../../views/Integrations/Integrations/FastDoc";
import HomeQIntegration from "../../../../../views/Integrations/Integrations/HomeQ";
import BostadsformedlingenStockholmIntegration from "../../../../../views/Integrations/Integrations/StockholmsBF";
import ParakeyIntegration from "../../../../../views/Integrations/Integrations/Parakey";
import CompanyActivateFortnox from "../../../../../views/Companies/detail/ActivateFortnox";
import CompanyAwaitActivateFortnox from "../../../../../views/Companies/detail/AwaitActivationFortnox";
import BrfCompanyActivateFortnox from "../../../../../views/BrfCompanies/detail/ActivateFortnox";
import BrfCompanyAwaitActivateFortnox from "../../../../../views/BrfCompanies/detail/AwaitActivationFortnox";
import SFTPIntegration from "../../../../../views/Integrations/Integrations/SFTP";
import CompanyActivateVisma from "../../../../../views/Companies/detail/ActivateVisma";

export const TABS = {
  COMPANY: "COMPANY",
  ALL: "ALL",
};

export default () => {
  const { path, url } = useRouteMatch();
  const query = useQuery();
  const tab = query.get("tab");

  const SUB_PAGES = [
    {
      path: "/yourblock",
      component: YourBlockIntegration,
    },
    {
      path: "/outlook",
      component: OutlookIntegration,
    },
    {
      path: "/infometric",
      component: InfometricIntegration,
    },
    {
      path: "/elvaco",
      component: ElvacoIntegration,
    },
    {
      path: "/intercom",
      component: IntercomIntegration,
    },
    {
      path: "/freshchat",
      component: FreshChatIntegration,
    },
    {
      path: "/bokamera",
      component: BokaMeraIntegration,
    },
    {
      path: "/unit-4-erp",
      component: Unit4ErpIntegration,
    },
    {
      path: "/xledger",
      component: XLedgerIntegration,
    },
    {
      path: "/twilio-sms",
      component: TwilioSMSIntegration,
    },
    {
      path: "/autogiro",
      component: AutogiroIntegration,
    },
    {
      path: "/avy-tmpl",
      component: AvyTmplIntegration,
    },
    {
      path: "/kivra",
      component: KivraIntegration,
    },
    {
      path: "/fortnox-accounting",
      component: FortnoxAccountingIntegration,
    },
    {
      path: "/activate-fortnox/company/:companyId",
      component: CompanyActivateFortnox,
    },
    {
      path: "/activate-fortnox/brf/:brfCompanyId",
      component: BrfCompanyActivateFortnox,
    },
    {
      path: "/activate-fortnox-complete/company/:companyId",
      component: CompanyAwaitActivateFortnox,
    },
    {
      path: "/activate-fortnox-complete/brf/:brfCompanyId",
      component: BrfCompanyAwaitActivateFortnox,
    },
    {
      path: "/visma-accounting",
      component: VismaEEconomyIntegration,
    },
    {
      path: "/activate-visma/company/:companyId",
      component: CompanyActivateVisma,
    },
    {
      path: "/scrive",
      component: ScriveIntegration,
    },
    {
      path: "/fastdok",
      component: FastDocIntegration,
    },
    {
      path: "/homeq",
      component: HomeQIntegration,
    },
    {
      path: "/bostadsformedlingen-stockholm",
      component: BostadsformedlingenStockholmIntegration,
    },
    {
      path: "/parakey",
      component: ParakeyIntegration,
    },
    {
      path: "/sftp",
      component: SFTPIntegration,
    },
    {
      path: "/",
      component: IntegrationsOverview,
    },
  ];

  return (
    <SC.ConfigCenterDetailWrapper>
      <SC.ConfigCenterSideBarDetailWrapper>
        <SC.DetailPageBox>
          <SC.DetailPageItem
            active={[TABS.ALL, null].includes(tab)}
            to={`/configcenter/integrations?tab=${TABS.ALL}`}
          >
            Alla integrationer
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={tab === TABS.COMPANY}
            to={`/configcenter/integrations?tab=${TABS.COMPANY}`}
          >
            Bolag
          </SC.DetailPageItem>
        </SC.DetailPageBox>
      </SC.ConfigCenterSideBarDetailWrapper>

      <SC.ConfigCenterDetailElement>
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              exact={sp.exact}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[20].path}`} path={`${path}`} />
        </Switch>
      </SC.ConfigCenterDetailElement>
    </SC.ConfigCenterDetailWrapper>
  );
};
