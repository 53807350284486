import * as React from "react";

export default () => {
  return (
    <div>
      Specificera debiteringsrader för objektet. Dessa debiteringsrader kommer
      automatiskt att debiteras för på avtal som inkluderar detta objekt.
    </div>
  );
};
