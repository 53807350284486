import * as React from "react";

import { NumberInput, Select } from "..";

export const INTERVAL_MAP_ROUNDING = {
  mode: {
    title: "Typ av ronderingsintervall",
    description: `Typ av intervall för när ronderingar ska genereras. Vid kvartalsvisa ronderingar väljs t.ex. "Per kvartal".`,
  },
  month: {
    interval: {
      title: "Månadsintervall",
      description: `Ange i vilket månadsintervall ronderingar ska genereras. Ska en rondering genereras varje månad anges 1, varannan månad 2 osv.`,
    },
    dayInMonth: {
      title: "Dag i månad som rondering ska genereras",
      description: `Ange vilken dag i månaden som en rondering ska genereras.`,
    },
  },
  quarterly: {
    interval: {
      title: "Kvartalsintervall",
      description: `Ange i vilket kvartalsintervall ronderingar ska genereras. Ska en rondering genereras varje kvartal anges "1", varannat kvartal "2" osv.`,
    },
    onMonth: {
      title: "Månad i kvartal som rondering ska genereras",
      description: `Ange vilken månaden i kvartalet som en rondering ska genereras. T.ex. om ronderingen ska genereras 1:a månaden i kvartalet så anges "1".`,
    },
    dayInMonth: {
      title: "Dag i månad som rondering ska genereras",
      description: `Ange vilken dag i månaden som en rondering ska genereras.`,
    },
  },
  yearly: {
    interval: {
      title: "Årsintervall",
      description: `Ange i vilket årsintervall ronderingar ska genereras. Ska en rondering genereras varje år anges "1", varannat år "2" osv.`,
    },
    onMonth: {
      title: "Månad som rondering ska genereras",
      description: `Ange vilken månaden på året som en rondering ska genereras. T.ex. om ronderingen ska genereras i Januari så anges "1", Juli så anges "7" osv.`,
    },
    dayInMonth: {
      title: "Dag i månad som ronderingen ska genereras",
      description: `Ange vilken dag i månaden som en rondering ska genereras.`,
    },
  },
  daily: {
    interval: {
      title: "Dagsintervall",
      description: `Ange i vilket dagsintervall ronderingar ska genereras. Ska en rondering genereras varje dag anges "1", varannan dag "2" osv.`,
    },
  },
};

export const INTERVAL_MAP_GOV = {
  mode: {
    title: "Typ av intervall",
    description: `Typ av intervall för när myndighetskravet ska genereras. Vid kvartalsvisa myndighetskrav väljs t.ex. "Per kvartal".`,
  },
  month: {
    interval: {
      title: "Månadsintervall",
      description: `Ange i vilket månadsintervall myndighetskravet ska genereras. Ska myndighetskravet genereras varje månad anges 1, varannan månad 2 osv.`,
    },
    dayInMonth: {
      title: "Dag i månad som myndighetskravet ska genereras",
      description: `Ange vilken dag i månaden som myndighetskravet ska genereras.`,
    },
  },
  quarterly: {
    interval: {
      title: "Kvartalsintervall",
      description: `Ange i vilket kvartalsintervall myndighetskravet ska genereras. Ska myndighetskravet genereras varje kvartal anges "1", varannat kvartal "2" osv.`,
    },
    onMonth: {
      title: "Månad i kvartal som myndighetskravet ska genereras",
      description: `Ange vilken månaden i kvartalet som myndighetskravet ska genereras. T.ex. om myndighetskravet ska genereras 1:a månaden i kvartalet så anges "1".`,
    },
    dayInMonth: {
      title: "Dag i månad som myndighetskravet ska genereras",
      description: `Ange vilken dag i månaden som myndighetskravet ska genereras.`,
    },
  },
  yearly: {
    interval: {
      title: "Årsintervall",
      description: `Ange i vilket årsintervall myndighetskravet ska genereras. Ska myndighetskravet genereras varje år anges "1", varannat år "2" osv.`,
    },
    onMonth: {
      title: "Månad som myndighetskravet ska genereras",
      description: `Ange vilken månaden på året som myndighetskravet ska genereras. T.ex. om myndighetskravet ska genereras i Januari så anges "1", Juli så anges "7" osv.`,
    },
    dayInMonth: {
      title: "Dag i månad som myndighetskravet ska genereras",
      description: `Ange vilken dag i månaden som myndighetskravet ska genereras.`,
    },
  },
  daily: {
    interval: {
      title: "Dagsintervall",
      description: `Ange i vilket dagsintervall myndighetskravet ska genereras. Ska myndighetskravet genereras varje dag anges "1", varannan dag "2" osv.`,
    },
  },
};

export const INTERVAL_MAP_SERVICECONTRACT = {
  mode: {
    title: "Typ av intervall",
    description: `Typ av intervall för när tjänst/leverans ska genereras. Vid kvartalsvis tjänst/leverans väljs t.ex. "Per kvartal".`,
  },
  month: {
    interval: {
      title: "Månadsintervall",
      description: `Ange i vilket månadsintervall tjänst/leverans ska genereras. Ska tjänst/leverans genereras varje månad anges 1, varannan månad 2 osv.`,
    },
    dayInMonth: {
      title: "Dag i månad som tjänst/leverans ska genereras",
      description: `Ange vilken dag i månaden som tjänst/leverans ska genereras.`,
    },
  },
  quarterly: {
    interval: {
      title: "Kvartalsintervall",
      description: `Ange i vilket kvartalsintervall tjänst/leverans ska genereras. Ska tjänst/leverans genereras varje kvartal anges "1", varannat kvartal "2" osv.`,
    },
    onMonth: {
      title: "Månad i kvartal som tjänst/leverans ska genereras",
      description: `Ange vilken månaden i kvartalet som tjänst/leverans ska genereras. T.ex. om tjänst/leverans ska genereras 1:a månaden i kvartalet så anges "1".`,
    },
    dayInMonth: {
      title: "Dag i månad som tjänst/leverans ska genereras",
      description: `Ange vilken dag i månaden som tjänst/leverans ska genereras.`,
    },
  },
  yearly: {
    interval: {
      title: "Årsintervall",
      description: `Ange i vilket årsintervall tjänst/leverans ska genereras. Ska tjänst/leverans genereras varje år anges "1", varannat år "2" osv.`,
    },
    onMonth: {
      title: "Månad som tjänst/leverans ska genereras",
      description: `Ange vilken månaden på året som tjänst/leverans ska genereras. T.ex. om tjänst/leverans ska genereras i Januari så anges "1", Juli så anges "7" osv.`,
    },
    dayInMonth: {
      title: "Dag i månad som tjänst/leverans ska genereras",
      description: `Ange vilken dag i månaden som tjänst/leverans ska genereras.`,
    },
  },
  daily: {
    interval: {
      title: "Dagsintervall",
      description: `Ange i vilket dagsintervall tjänst/leverans ska genereras. Ska tjänst/leverans genereras varje dag anges "1", varannan dag "2" osv.`,
    },
  },
};

export default function Interval({
  mode,
  storeName,
  method,
  baseFieldKey,
  config,
}) {
  return (
    <>
      <Select
        title={config?.mode?.title}
        description={config?.mode?.description}
        {...{
          storeName,
          method,
          fieldKey: `${baseFieldKey || "interval"}.mode`,
        }}
      />

      {/* Per month */}
      {mode === 0 && (
        <>
          <NumberInput
            title={config?.month?.interval?.title}
            description={config?.month?.interval?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.interval`,
            }}
          />

          <NumberInput
            title={config?.month?.dayInMonth?.title}
            description={config?.month?.dayInMonth?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.day_in_month`,
            }}
          />
        </>
      )}

      {/* Quarterly */}
      {mode === 1 && (
        <>
          <NumberInput
            title={config?.quarterly?.interval?.title}
            description={config?.quarterly?.interval?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.interval`,
            }}
          />

          <NumberInput
            title={config?.quarterly?.onMonth?.title}
            description={config?.quarterly?.onMonth?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.on_month`,
            }}
          />

          <NumberInput
            title={config?.quarterly?.dayInMonth?.title}
            description={config?.quarterly?.dayInMonth?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.day_in_month`,
            }}
          />
        </>
      )}

      {/* yearly */}
      {mode === 2 && (
        <>
          <NumberInput
            title={config?.yearly?.interval?.title}
            description={config?.yearly?.interval?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.interval`,
            }}
          />

          <NumberInput
            title={config?.yearly?.onMonth?.title}
            description={config?.yearly?.onMonth?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.on_month`,
            }}
          />

          <NumberInput
            title={config?.yearly?.dayInMonth?.title}
            description={config?.yearly?.dayInMonth?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.day_in_month`,
            }}
          />
        </>
      )}

      {/* daily */}
      {mode === 3 && (
        <>
          <NumberInput
            title={config?.daily?.interval?.title}
            description={config?.daily?.interval?.description}
            {...{
              storeName,
              method,
              fieldKey: `${baseFieldKey || "interval"}.interval`,
            }}
          />
        </>
      )}
    </>
  );
}
