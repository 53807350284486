import * as React from "react";
import { useDispatch } from "react-redux";

import * as SC from "../../Fields/styles";

import DescriptionToolTip from "../../Layout/DescriptionToolTip";

export default React.memo(
  ({ value, title, required, error, onUpdate, description }) => {
    const [internalValue, setInternalValue] = React.useState("00 00 00 00");
    const yRef = React.useRef();
    const mRef = React.useRef();

    React.useEffect(() => {
      if (value) {
        setInternalValue(value);
      }
    }, [value]);

    const onChange = () => {
      const values = internalValue ? internalValue.split(" ") : ["00", "00"];

      let formattedValues = values
        .map((v) => {
          if (v.length === 0) return "00";
          if (v.length === 1) return `0${v}`;
          return v;
        })
        .join(" ");

      if (formattedValues === "00 00 00 00") {
        formattedValues = null;
      }

      onUpdate(formattedValues);
    };

    const handleInput = ({ target: { value } }, type) => {
      switch (type) {
        case "y":
          return handleYearUpdated(value);
        case "m":
          return handleMonthUpdated(value);
        default:
          return;
      }
    };

    const handleYearUpdated = (yearValue) => {
      let cleanedYearValue = yearValue.replace(/[^-0-9/]+/, "");
      if (cleanedYearValue.length > 2) return;

      if (cleanedYearValue > 99 || cleanedYearValue < 0) return;

      const monthValue = mRef?.current?.value;

      setInternalValue(`${cleanedYearValue} ${monthValue} 00 00`);
    };

    const handleMonthUpdated = (monthValue) => {
      let cleanedMonthValue = monthValue.replace(/[^-0-9]+/, "");
      if (cleanedMonthValue.length > 2) return;

      if (cleanedMonthValue > 11 || cleanedMonthValue < 0) return;

      const yearValue = yRef?.current?.value;

      setInternalValue(`${yearValue} ${cleanedMonthValue} 00 00`);
    };

    const splitValue = internalValue ? internalValue.split(" ") : ["00", "00"];
    const [yValue, mValue] = splitValue;

    return (
      <SC.InputSpacing>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SC.InputFieldTitle>
            {title}
            {required ? "*" : ""}
          </SC.InputFieldTitle>
          {description && <DescriptionToolTip description={description} />}
        </div>
        <SC.IntervalFieldWrapper>
          <SC.IntervalFieldPart>
            <SC.IntervalFieldTitle>År</SC.IntervalFieldTitle>
            <SC.IntervalField
              ref={yRef}
              type="number"
              value={yValue}
              onBlur={onChange}
              onChange={(e) => handleInput(e, "y")}
            />
          </SC.IntervalFieldPart>

          <SC.IntervalFieldPart>
            <SC.IntervalFieldTitle>Månader</SC.IntervalFieldTitle>
            <SC.IntervalField
              ref={mRef}
              type="number"
              value={mValue}
              onBlur={onChange}
              onChange={(e) => handleInput(e, "m")}
            />
          </SC.IntervalFieldPart>
        </SC.IntervalFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
