import * as React from "react";

export default () => {
  return (
    <div>
      Välj excelfilen som ska importeras
      <br />
    </div>
  );
};
