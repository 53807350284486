import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/IMDSensor";
import { useFilteredRealEstates } from "../../../store/realEstates";

import SensorForm from "../../Forms/Sensor/ChapterForm/ModalForm";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  allowEdit,
}) => {
  const [editingSensor, setEditingSensor] = React.useState(undefined);

  const [realEstates] = useFilteredRealEstates();

  const columns = React.useMemo(() => {
    const data = columnDefs();
    return data;
  }, [persistantQuery, realEstates]);

  //TODO: Check if more filters are applicable
  const badges = {};
  const filters = {
    "Sensor id": {
      queryKey: "sensor_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    //Waiting for backend [DEV-712]
    // "Importerad från": {
    //   queryKey: "imported_from_display",
    //   type: "text",
    //   operators: OPERATOR_TEMPLATES.SEARCH,
    // },
  };

  return (
    <>
      {!!editingSensor && (
        <SensorForm
          method={"PATCH"}
          id={editingSensor?.id}
          instance={editingSensor}
          onCheckout={() => setEditingSensor(undefined)}
        />
      )}

      <BaseTable
        tableId={tableId || "sensors_full_table"}
        title={"Sensorer"}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked: allowEdit
            ? (original) => setEditingSensor(original)
            : undefined,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
};
