import * as React from "react";
import { useDispatch } from "react-redux";
import { DateCell } from "../Displays";

import { StatusLabel } from "../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../Loaders/OverlaySpinner";

import UpdateLeaseContractCancellationForm from "../Forms/LeaseContract/EditCancelForm/ModalForm";
import UpdateOtherContractCancellationForm from "../Forms/OtherContract/EditCancelForm/ModalForm";
import UpdateParkingContractCancellationForm from "../Forms/ParkingContract/EditCancelForm/ModalForm";

import Alert from "../Alert/Alert";
import { handleContractCancelledDocUrl } from "../../store/editabledocs";
import Modal from "../Forms/Base/Modals/Modal";
import { useHistory } from "react-router-dom";
import MediaField from "../Forms/Base/Fields/MediaField";
import ConfirmModal from "../Forms/Base/Modals/ConfirmModal";
import CanceledInfo from "./CanceledInfo";

const getForm = (storeName) => {
  switch (storeName) {
    case "leaseContracts":
      return UpdateLeaseContractCancellationForm;
    case "otherContracts":
      return UpdateOtherContractCancellationForm;
    case "parkingContracts":
      return UpdateParkingContractCancellationForm;
  }
};

export default function ManualCancellation({
  contract,
  update,
  constants,
  cancelledDoc,
  contractType,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [editOpen, setEditOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [confirmRegretOpen, setConfirmRegretOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [markSignedFormOpen, setMarkSignedFormOpen] = React.useState(false);
  const isFastDoc = cancelledDoc?.fastdoc_id;
  const [showInfoOpen, setShowInfoOpen] = React.useState(false);

  const [confirmCancelCancellationOpen, setConfirmCancelCancellationOpen] =
    React.useState(false);

  const { push } = useHistory();
  const CancellationForm = getForm(constants.STORE_NAME);

  const cancelledByTenantNotConfirmed =
    contract?.landlord_cancelled !== true && !contract?.closed_confirmed;
  const cancelCancellation = () => {
    setLoading(true);
    const data = {
      closed_date: null,
      closed_renew_date: null,
      closed_on: null,
      status: 0,
      closed_cause: null,
      closed_signed: false,
      closed_confirmed: false,
      landlord_cancelled: null,
      cancelled_doc: null,
    };

    dispatch(
      update({
        id: contract?.id,
        forceData: data,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  const handleConfirmCancelled = () => {
    setLoading(true);

    const data = {
      closed_confirmed: true,
      closed_signed: true,
    };

    dispatch(
      update({
        id: contract?.id,
        forceData: data,
        successCallback: () => {
          setMarkSignedFormOpen(false);
          setLoading(false);
        },
        errorCallback: () => setLoading(false),
      })
    );
  };

  const handleCreateCancelledDoc = () => {
    setLoading(true);
    dispatch(
      update({
        id: contract.id,
        forceData: {
          cancelled_doc: {
            title: "canceldoc",
          },
        },
        successCallback: () => {
          setLoading(false);
          push(
            handleContractCancelledDocUrl({
              contractType,
              id: contract?.id,
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  const infoObj = {
    "Detaljer kring uppsägning": [
      {
        title: "Typ av uppsägning",
        value: (
          <StatusLabel state={6}>
            {contract?.status === 6
              ? "Uppsagt för avflyttning"
              : "Uppsagt för villkorsändring"}
          </StatusLabel>
        ),
      },
      {
        title: "Uppsagt av",
        value: contract?.landlord_cancelled ? "Hyresvärd" : "Hyresgäst",
      },
      {
        title: "Datum för uppsägning",
        value: <DateCell date={contract?.closed_on} />,
      },
      {
        title: "Datum för avflytt",
        value: <DateCell date={contract?.closed_date} />,
        hidden: contract?.status !== 6,
      },
      {
        title: "Datum för villkorsändring",
        value: <DateCell date={contract?.closed_renew_date} />,
        hidden: contract?.status !== 7,
      },
      {
        title: "Uppsägning bekräftad",
        value: (
          <StatusLabel state={contract?.closed_confirmed ? 1 : 6}>
            {contract?.closed_confirmed ? "Bekräftad" : "Ej bekräftad"}
          </StatusLabel>
        ),
      },
    ],
    "Anledning till uppsägning": [
      { title: contract?.closed_cause || "Ingen anledning angiven" },
    ],
  };

  if (contract?.closed_signed) {
    return (
      <>
        <CancellationForm
          isOpen={editOpen}
          id={contract?.id}
          instance={contract}
          onCheckout={() => setEditOpen(false)}
        />

        {confirmCancelCancellationOpen && (
          <ConfirmModal
            closeFunction={() => setConfirmCancelCancellationOpen(false)}
            onAccept={cancelCancellation}
            acceptTitle="Ångra uppsägning"
          >
            <h3 className="mb-2 text-base font-normal text-slate-700 ">
              Bekräfta återställning av uppsägning
            </h3>

            <p>
              Avtalet kommer att återställas till den statusen det hade innan
              uppsägningen.
            </p>
          </ConfirmModal>
        )}

        <CanceledInfo
          contract={contract}
          onEdit={() => setEditOpen(true)}
          confirmCancelCancellation={() =>
            setConfirmCancelCancellationOpen(true)
          }
        />
      </>
    );
  }

  return (
    <>
      {markSignedFormOpen && (
        <Modal
          onAccept={handleConfirmCancelled}
          closeFunction={() => setMarkSignedFormOpen(false)}
          title="Markera uppsägning som signerad"
        >
          {loadingUpdate && <OverlaySpinner />}

          <MediaField
            title="Signerat dokument"
            storeName={storeName}
            fieldKey="cancelled_doc.doc"
            method="PATCH"
            description={`Ladda upp det signerade dokumentet. ${
              isFastDoc
                ? "OBS: Ladda ej upp ett dokument om avtalet har signerats via Fastighetsägarna Dokument. Det signerade avtalet hämtas automatiskt in."
                : ""
            }`}
            allowedFormats={[".pdf"]}
            fileKey="doc"
          />
        </Modal>
      )}

      {showInfoOpen && (
        <Modal
          title="Information om signering"
          closeFunction={() => setShowInfoOpen(false)}
        >
          <CanceledInfo
            contract={contract}
            confirmCancelCancellation={() =>
              setConfirmCancelCancellationOpen(true)
            }
            onEdit={() => setEditOpen(true)}
          />
        </Modal>
      )}

      <Alert
        title="Väntar på signering av uppsägning"
        primaryTitle="Markera som signerat"
        primaryAction={() => setMarkSignedFormOpen(true)}
        secondaryTitle={
          cancelledDoc ? "Hantera dokument" : "Skapa digitalt dokument"
        }
        secondaryAction={
          cancelledDoc
            ? () =>
                push(
                  handleContractCancelledDocUrl({
                    contractType,
                    id: contract.id,
                  })
                )
            : handleCreateCancelledDoc
        }
        tertiaryAction={() => setShowInfoOpen(true)}
        tertiaryTitle="Visa information & hantera uppsägning"
      >
        {loading && <OverlaySpinner />}
        {isFastDoc ? (
          <>
            Detta avtal inväntar manuell signering/signering av uppsägning via
            Fastighetsägarna Dokument. <br /> <br />{" "}
            <strong>Om det är signerat via Fastighetsägarna Dokument</strong>,
            markera avtalet som signerat{" "}
            <strong className="underline">utan</strong> att ladda upp det
            signerade dokumentet. Det signerade avtalet hämtas automatiskt in
            från Fastighetsägarna. <br /> <br />
            <strong>Om det är manuellt signerat</strong>, ladda upp det
            signerade avtalet (frivilligt) för att markera avtalet som uppsagt.
          </>
        ) : (
          <>
            Detta avtal inväntar manuell signering. Markera dokumentet som
            signerat och ladda upp det signerade avtalet (frivilligt) för att
            markera avtalet som uppsagt.
          </>
        )}
      </Alert>

      <CancellationForm
        isOpen={editOpen}
        id={contract?.id}
        instance={contract}
        onCheckout={() => setEditOpen(false)}
      />

      {confirmCancelCancellationOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmCancelCancellationOpen(false)}
          onAccept={cancelCancellation}
          acceptTitle="Ångra uppsägning"
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Bekräfta återställning av uppsägning
          </h3>

          <p>
            Avtalet kommer att återställas till den statusen det hade innan
            uppsägningen.
          </p>
        </ConfirmModal>
      )}
    </>
  );
}
