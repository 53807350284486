import * as React from "react";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import ProductTypeNestedChildren from "../../../ProductType/NestedChildren";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import ProductTypesTable from "src/components/Tables/ErrandProductTypes/FullTable";
import DateSelect from "src/components/Forms/Base/Fields/DateSelect";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";

export default function Component({ method, storeName, patchIndex }) {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för Komponent</OverviewTitle>
          <OverviewSubtitle>Uppdatera komponentinstallationen</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          TableComponent={ProductTypesTable}
          placeholder={"Välj komponentmodell..."}
          title="Komponentmodell"
          method={method}
          fieldKey={`product_types[${patchIndex}].product_type`}
          instructionsKey={`product_types.product_type`}
          createDisplayKey="name"
        >
          {(parentPath) => (
            <ProductTypeNestedChildren
              {...{
                storeName,
                method,
                parentPath,
                parentInstructionsPath: `product_types.product_type`,
              }}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          {...{
            storeName,
            title: "Installerades",
            method,
            fieldKey: `product_types[${patchIndex}].from_date`,
            instructionsKey: "product_types.from_date",
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          {...{
            storeName,
            title: "Serienummer",
            description:
              "Serienummer används för att identifiera en specifik komponent",
            method,
            fieldKey: `product_types[${patchIndex}].code`,
            instructionsKey: "product_types.code",
          }}
        />
      </div>
    </>
  );
}
