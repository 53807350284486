import * as React from "react";
import Select from "react-select";

// style, design
import * as SC from "./styles";

export default React.memo(
  ({
    fetchMethod,
    fetchQueryString,
    changeCallback,
    noMargin,
    disabled,
    value,
    title,
    helpText,
    id,
    clearable,
    extraStyles,
    placeholder,
    isMulti,
    formatDisplayValue
  }) => {

    const [choices, isLoading] = fetchMethod(fetchQueryString || "");
    const onChange = (data) => {
      changeCallback(data);
    };

    const selected = isMulti ? value : choices?.find((c) => c.id === value) || null;

    const getDisplayValue = (option) => {
      if (formatDisplayValue) { return formatDisplayValue(option) }
      return option.str_representation
    }

    return (
      <SC.InputSpacing style={{ ...extraStyles }}{...{ noMargin }}>
        {!!title && (
          <SC.InputFieldTitle>{title}</SC.InputFieldTitle>
        )}
        {!!helpText && (
          <SC.InputFieldDescription>
            {helpText}
          </SC.InputFieldDescription>
        )}
        {choices ? (
          <SC.SelectWrapper>
            <Select
              isDisabled={disabled}
              id={id}
              placeholder={placeholder || "Välj..."}
              onChange={onChange}
              defaultValue={null}
              value={selected}
              options={choices}
              getOptionValue={(option) => option.id}
              getOptionLabel={getDisplayValue}
              isClearable={clearable}
              isMulti={isMulti}
            />
          </SC.SelectWrapper>
        ) : (
          <SC.SelectLoader>Laddar alternativ...</SC.SelectLoader>
        )}
      </SC.InputSpacing>
    );
  }
);
