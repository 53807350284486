import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
export const SidebarWrapper = styled.div`
  width: 25%;
  max-width: 250px;
  min-width: 250px;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  color: ${(p) => p.theme.colors.primaryText};
  background-color: #fff;
  border-right: ${(p) => p.theme.borders.standard};
`;

export const SidebarTitle = styled.div`
  padding: 0px 20px 0 40px;
  font-size: ${(p) => p.theme.fontSizes.headerMedium};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
`;

export const SidebarItems = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
`;

export const SideBarChapter = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  // margin-left: ${(p) => (p.logu ? "5px" : "none")}
`;

export const SideBarChapterTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  border-bottom: 2px solid whitesmoke;
`;

export const SidebarItem = styled(Link)`
  color: ${(p) => p.theme.colors.primaryText};
  margin: 4px 0;

  ${(p) =>
    p.active &&
    css`
      color: ${(p) => p.theme.colors.primaryBlue};

      &:hover {
        color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed !important;
      color: ${p.theme.colors.secondaryText};

      &:hover {
        color: ${p.theme.colors.secondaryText};
      }
    `}
    ${(p) =>
    p.icon &&
    css`
      &:before {
        position: absolute;
        top: calc(50% - 9px);
        left: ${(p) => (p.closedMode ? "9px" : "12px")};
        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        content: "";
        background-position: center;
        background-size: contain;
        background-image: url(${p.icon});
      }
    `}
`;

// ConfigCenter
export const ConfigCenterSidebarWrapper = styled.div`
  width: 150px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  color: ${(p) => p.theme.colors.primaryText};
  background-color: #fff;
  border-right: ${(p) => p.theme.borders.standard};
`;

export const ConfigCenterDetailWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 100vw;
  height: 100%;
  background-color: white;
`;
export const ConfigCenterDetailElement = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  max-width: calc(100vw - 350px);
`;
export const ConfigCenterSideBarDetailWrapper = styled.div`
  height: 100%;
  background-color: ${(p) => p.theme.colors.grayBackground};
  border-right: ${(p) => p.theme.borders.standard};
  min-width: 200px;
  max-width: 200px;
`;

export const ConfigCenterSideBarGroup = styled(Link)`
  width: 110px;
  min-height: 63px;
  max-height: 70px;
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: "Inter", sans-serif;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;

  color: ${(p) => p.theme.colors.primaryText};

  &:hover {
    background-color: #e4f1ff;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  //   ${(p) =>
    p.inner &&
    p.last &&
    css`
      margin-bottom: 12px;
    `}

  background-color: ${(p) => (p.isOpen ? " #f1f1f1" : "#fff")};
  padding: ${(p) =>
    p.filterActive ? "4px 36px 4px 36px" : "4px 14px 4px 14px"};
  transition: ${(p) =>
    p.closedMode ? "" : "background-color, color 0.2s ease-in-out"};

  &:hover {
    background-color: #f1f1f1;
  }

  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => p.theme.colors.blueLight};
      color: ${(p) => p.theme.colors.primaryBlue};

      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

  ${(p) =>
    p.icon &&
    css`
      &:before {
        top: calc(50% - 9px);
        left: ${(p) => (p.closedMode ? "9px" : "12px")};
        height: 40px;
        width: 30px;

        background-repeat: no-repeat;
        content: "";
        background-position: center;
        background-size: contain;
        background-image: url(${p.icon});
      }
    `}

    margin: 5px;
`;
export const ConfigCenterSidebarItem = styled(Link)`
  color: ${(p) => p.theme.colors.primaryText};
  margin: 4px 0;
  position: relative;

  ${(p) =>
    p.active &&
    css`
      color: ${(p) => p.theme.colors.primaryBlue};

      &:hover {
        color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed !important;
      color: ${p.theme.colors.secondaryText};

      &:hover {
        color: ${p.theme.colors.secondaryText};
      }
    `}
    ${(p) =>
    p.icon &&
    css`
      &:before {
        background-image: url(${p.icon});
        position: absolute;
        top: calc(50% - 9px);
        left: -20px;
        height: 18px;
        width: 18px;

        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        content: "";
        background-position: center;
        background-size: contain;
      }
    `}
`;
export const DetailPageBox = styled.div`
  position: relative;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-self: start;
  margin: 20px 8px 12px 20px;
  width: 80%;
  max-width: 85%;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;

  ${(p) =>
    p.flexStart &&
    css`
      align-self: flex-start;
    `}

  ${(p) =>
    p.centerAll &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export const DetailPageItem = styled(Link)`
  position: relative;
  color: #444a55;
  padding: 8px 8px;
  margin: ${(p) => (p.separateMode ? "20px 2px 2px 2px" : "2px")};
  border-radius: 5px;

  :hover {
    color: #7e8eff;
    background-color: #e4f1ff;
  }

  ${(p) =>
    p.active &&
    css`
      color: ${(p) => p.theme.colors.primaryBlue};
      background-color: #e4f1ff;

      &:hover {
        color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

  ${(p) =>
    p.separateMode &&
    css`
      border-top: ${(p) =>
    p.error ? p.theme.borders.standard : p.theme.borders.standard};
    `}
`;

export const IntegrationCompaniesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
