const defs = [
  {
    title: "Avgiftsgrupp",
    key: "GENERAL",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "for_all_brf_premises",
      "is_main_fee"
    ],
  },
  {
    title: "Avgifter",
    key: "FEES",
    visited: false,
    hasError: false,
    fieldKeys: [
      "yearly_fee",
      "total_fee_to_pay",
      "auto_add_loan_interest_to_fee",
      "start_date"
    ],
  },
  {
    title: "Debitering",
    key: "DEBIT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "is_active",
      "close_for_fully_payed_premis",
      "fee_contributes_to_interest_fess",
      "distribute_fee_on_loan_proportionally",
      "product",
      "all_payments_loan_capital_contribution"
    ],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
