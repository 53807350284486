import * as React from "react";
import NonConnectedCheckbox from "../../../components/Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedSelect from "../../../components/Forms/Base/Old/NonConnected/NonConnectedSelect";
import NonConnectedTextField from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextField";

import StandardModal from "../../../components/Modals/StandardModal";
import { useFilteredMarketingRequirements } from "../../../store/marketRequirements";
import { useFilteredPipes } from "../../../store/pipes";
import { PILOT_OPTIONS } from "./CreateManyAds";

export default function HandleAd({
  editInstance,
  closeFunction,
  isOpen,
  handleAttrUpdated,
  handleDateAccessUpdates,
  handleDatePublishUpdates,
  isPublishNewProduction,
}) {
  const [availablePipes, setAvailablePipes] = React.useState([]);
  const [availableRequirements, setAvailableRequirements] = React.useState([]);
  const [requirementProfiles] = useFilteredMarketingRequirements("");
  const [pipes] = useFilteredPipes("");

  React.useEffect(() => {
    if (requirementProfiles?.length && !availableRequirements?.length) {
      setAvailableRequirements(requirementProfiles);
    }
  }, [requirementProfiles]);

  React.useEffect(() => {
    if (pipes?.length && !availablePipes?.length) {
      setAvailablePipes(pipes);
    }
  }, [pipes]);

  return (
    <StandardModal
      title={`Redigera annons för ${editInstance?.apartment_id}`}
      isOpen={isOpen}
      closeFunction={closeFunction}
      withActionBar
      actionBarCancelTitle="Klar"
    >
      {!!editInstance && (
        <>
          {!isPublishNewProduction && (
            <NonConnectedDatePicker
              label="Publiceringsdatum"
              value={editInstance.date_publish}
              onChange={(val) => {
                handleDatePublishUpdates({ id: editInstance.id, val });
              }}
              clearable={false}
              id={`date_publish_${editInstance.id}`}
            />
          )}
          <NonConnectedDatePicker
            label="Tillträdesdatum"
            value={editInstance.access_date}
            onChange={(val) => {
              handleDateAccessUpdates({ id: editInstance.id, val });
            }}
            clearable={false}
            id={`access_date_${editInstance.id}`}
          />
          {!isPublishNewProduction && (
            <NonConnectedSelect
              label="Annonsläge"
              extraStyles={{ minWidth: 200 }}
              id={`pilot_mode_${editInstance.id}`}
              value={editInstance.pilot_mode}
              choices={PILOT_OPTIONS}
              getOptionLabel={(o) => o.d}
              getOptionValue={(o) => o.v}
              allowNull={false}
              onUpdate={(val) => {
                handleAttrUpdated({
                  attr: "pilot_mode",
                  id: editInstance.id,
                  val,
                });
              }}
            />
          )}
          <NonConnectedSelect
            extraStyles={{ minWidth: 200 }}
            label="Kravprofil"
            id={`requirement_profile_${editInstance.id}`}
            value={editInstance.requirement}
            choices={availableRequirements}
            getOptionLabel={(o) => o.str_representation}
            getOptionValue={(o) => o.id}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "requirement",
                id: editInstance.id,
                val,
              });
            }}
          />
          {!isPublishNewProduction && (
            <NonConnectedSelect
              extraStyles={{ minWidth: 200 }}
              label="Projekt för leads"
              id={`pipe_${editInstance.id}`}
              value={editInstance.dump_in_pipe}
              choices={availablePipes}
              getOptionLabel={(o) => o.str_representation}
              getOptionValue={(o) => o.id}
              allowNull={false}
              onUpdate={(val) => {
                handleAttrUpdated({
                  attr: "dump_in_pipe",
                  id: editInstance.id,
                  val,
                });
              }}
            />
          )}
          {!isPublishNewProduction && (
            <NonConnectedCheckbox
              title="Pausad"
              id={`paused_${editInstance.id}`}
              value={editInstance.is_paused}
              onChange={(val) => {
                handleAttrUpdated({
                  attr: "is_paused",
                  id: editInstance.id,
                  val,
                });
              }}
            />
          )}
          <NonConnectedCheckbox
            title="Tidigare tillträde möjligt"
            id={`prior_access_${editInstance.id}`}
            value={editInstance.prior_access}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "prior_access",
                id: editInstance.id,
                val,
              });
            }}
          />
          {!isPublishNewProduction && (
            <NonConnectedCheckbox
              title="Prioritera nuvarande hyresgäster"
              id={`prioritize_customers_${editInstance.id}`}
              value={editInstance.prioritize_customers}
              onChange={(val) => {
                handleAttrUpdated({
                  attr: "prioritize_customers",
                  id: editInstance.id,
                  val,
                });
              }}
            />
          )}
          {/* {!isPublishNewProduction &&   <NonConnectedCheckbox
            title="Publicera på Pigello Marknad"
            id={`publich_pigello_${editInstance.id}`}
            value={editInstance.publish_pigello}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "publish_pigello",
                id: editInstance.id,
                val,
              });
            }}
          />
          } */}
          {!isPublishNewProduction && (
            <NonConnectedCheckbox
              title="Publicera på HomeQ"
              id={`publich_homeq_${editInstance.id}`}
              value={editInstance.publish_homeq}
              onChange={(val) => {
                handleAttrUpdated({
                  attr: "publish_homeq",
                  id: editInstance.id,
                  val,
                });
              }}
            />
          )}
          {!isPublishNewProduction && (
            <NonConnectedCheckbox
              title="Publicera på Bostadsförmedlingen Stockholm"
              id={`publich_sbrf_${editInstance.id}`}
              value={editInstance.publish_sbrf}
              onChange={(val) => {
                handleAttrUpdated({
                  attr: "publish_sbrf",
                  id: editInstance.id,
                  val,
                });
              }}
            />
          )}
          {!isPublishNewProduction && (
            <NonConnectedTextField
              title="Annonsbeskrivning"
              id={`description${editInstance.id}`}
              value={editInstance.description}
              handleChange={(val) => {
                handleAttrUpdated({
                  attr: "description",
                  id: editInstance.id,
                  val,
                });
              }}
            />
          )}
        </>
      )}
    </StandardModal>
  );
}
