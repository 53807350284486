import * as React from "react";
import DocumentUploader from "../../../../Base/Fields/DocumentUploader";
import { constants } from "../../../../../../store/IMDMeasureType";
import { useFormInstanceField } from "../../../../../../store/base";

export default () => {
  const storeName = constants.STORE_NAME;
  const xlsxError = useFormInstanceField({
    storeName,
    fieldKey: "_import._xlsxFileImportError",
  });

  let formattedError = null;
  if (xlsxError) {
    formattedError = [];
    for (let index = 0; index < xlsxError.length; index++) {
      const element = xlsxError[index];
      if (Object.keys(element).length == 0) {
        continue;
      }

      const strArr = [];
      Object.keys(element).forEach((key) => {
        strArr.push(`${key}: ${element[key]}`);
      });

      const str = `Rad ${index + 1} - ${strArr.join(", ")}`;
      formattedError.push(<div>{str}</div>);
    }
  }

  return (
    <div>
      <DocumentUploader
        allowedFormats={[".xlsx"]}
        storeName={storeName}
        fieldKey={"_import._xlsxFileImport"}
        title={"Excelfil"}
        manualInstructions={{
          _readOnly: false,
          _required: true,
        }}
      />
      {formattedError && <div>{formattedError}</div>}
    </div>
  );
};
