import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, TimeCell, DateCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";

import {
  ErrandStatusLabel,
  StatusLabel,
} from "src/components/Lists/Base/CompleteList/styles";
import { getErrandStatus, getChosenPerformer } from "../../utils/errandUtils";

import { detailUrl as userDetailUrl } from "../../../store/users";
import { detailUrl as areaDetailUrl } from "../../../store/roundingAreas";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import { detailUrl as compsDetailUrl } from "../../../store/componentTypes";
import { getRoundingIntervalStr } from "src/views/RoundingErrands/settingdetail/Overview";
import moment from "moment";

export default ({ isGov }) => {
  const cols = [
    {
      Header: "Status",
      // accessor: "status",
      id: "errand_status",
      Cell: ({ row }) => {
        const { status, display } = getErrandStatus({
          status: row.original?.status,
          plannedStart: row.original?.planned_start,
          plannedEnd: row.original?.planned_end,
          actualStart: row.original?.execute_start,
          actualEnd: row.original?.execute_end,
        });
        return <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },

    {
      Header: "Ärendenummer",
      accessor: "errand_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Ärende",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Ärendetyp",
      accessor: "setting",
      Cell: ({ value }) => {
        return <ToolTipCell text={value?.str_representation} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Områden",
      accessor: "setting.areas",
      Cell: ({ value }) => {
        return (
          <div className="flex">
            {value.map((v) => (
              <span key={v.id} className="mr-2">
                <LinkedObject obj={v} urlMethod={areaDetailUrl} />
              </span>
            ))}
          </div>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Fastigheter",
      id: "realest",
      Cell: ({ row }) => {
        const realEstates = row.original.setting?.areas?.reduce((acc, cur) => {
          return [
            ...acc,
            ...cur.realestates.filter(
              (r) => !acc.map((a) => a.id).includes(r.id)
            ),
          ];
        }, []);

        if (!realEstates?.length) return "-";
        return (
          <div className="flex">
            {realEstates.map((v) => (
              <span key={v.id} className="mr-2">
                <LinkedObject obj={v} urlMethod={realEstateDetailUrl} />
              </span>
            ))}
          </div>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Komponenttyper",
      id: "comps",
      Cell: ({ row }) => {
        const comps = row.original.setting?.areas?.reduce((acc, cur) => {
          return [
            ...acc,
            ...cur.component_types.filter(
              (r) => !acc.map((a) => a.id).includes(r.id)
            ),
          ];
        }, []);

        if (!comps?.length) return "-";
        return (
          <div className="flex">
            {comps.map((v) => (
              <span key={v.id} className="mr-2">
                <LinkedObject obj={v} urlMethod={compsDetailUrl} />
              </span>
            ))}
          </div>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Skapat",
      accessor: "created_at",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },

    {
      Header: "Vald utförare",
      id: "chosen_performer",
      Cell: ({ row }) => {
        const obj = getChosenPerformer(row.original);
        return <LinkedObject obj={obj} urlMethod={userDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Rapporterad av",
      accessor: "reported_by",
      Cell: ({ value }) => {
        return <LinkedObject obj={value} urlMethod={userDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Planerat startdatum",
      accessor: "planned_start",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Planerat utförandedatum",
      id: "execution_plan",
      Cell: ({ row }) => {
        const plannedStart = moment(row.original.planned_start);

        const setting = plannedStart.clone().add({
          month: row.original.setting?.expected_duration_months,
          days: row.original.setting?.expected_duration_days,
        });

        return <TimeCell date={setting.format("YYYY-MM-DD HH:mm")} />;
      },
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Utförandedatum",
      accessor: "execute_end",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Nästa planerade ärende",
      accessor: "planned_end",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Nästa ärende",
      accessor: "next_recurrence",
      Cell: ({ row }) => {
        if (row.original.next_recurrance)
          return <DateCell date={row.original.planned_end} />;

        return <DateCell date={row.original.planned_end} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Intervall",
      id: "interval",
      Cell: ({ row }) => (
        <div>{getRoundingIntervalStr(row.original?.setting)}</div>
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];

  if (isGov) {
    cols.splice(4, 0, {
      Header: "Besiktande part",
      accessor: "inspecting_party",
      Cell: ({ value }) => <div>{value || "-"}</div>,
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    });
  }

  return cols;
};

export const roundingErrandColumnDefs = (realEstates) => {
  return [
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const { status, display } = getErrandStatus({
          status: row.original?.status,
          plannedStart: row.original?.planned_start,
          plannedEnd: row.original?.planned_end,
          actualStart: row.original?.execute_start,
          actualEnd: row.original?.execute_end,
        });
        return <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },

    {
      Header: "Ärendenummer",
      accessor: "errand_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Avvikelser",
      accessor: "faults",
      Cell: ({ row }) => {
        const faults = row.original.faults;
        if (row.original.status === 3 || row.original.status === 4) {
          if (faults.length > 0) {
            return <StatusLabel state={8}>{faults.length} st</StatusLabel>;
          } else {
            return <StatusLabel state={0}>Nej</StatusLabel>;
          }
        }
        return <StatusLabel state={5}> - </StatusLabel>;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Skapat",
      accessor: "created_at",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },

    {
      Header: "Vald utförare",
      id: "chosen_performer",
      Cell: ({ row }) => {
        const obj = getChosenPerformer(row.original);
        return <LinkedObject obj={obj} urlMethod={userDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Rapporterad av",
      accessor: "reported_by",
      Cell: ({ value }) => {
        return <LinkedObject obj={value} urlMethod={userDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Planerat utförandedatum",
      accessor: "planned_start",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Utförandedatum",
      accessor: "execute_end",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },

    {
      Header: "Fastigheter",
      accessor: "realestates",
      Cell: ({ value }) => {
        const settingRealEstateIds = value;
        const matches = realEstates?.filter((r) =>
          settingRealEstateIds.includes(r.id)
        );

        const estates = matches?.map((m) => m.str_representation);
        return <div>{estates?.length ? estates.join(", ") : "-"}</div>;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];
};
