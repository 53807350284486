const defs = [
  {
    title: "Ingår i hyra",
    key: "INCLUDED",
    visited: true,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Bekvämligheter",
    key: "AMENITIES",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
  {
    title: "Parkeringsmöjligheter",
    key: "PARKING",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },

  {
    title: "Gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
