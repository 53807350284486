import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TextField, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingCustomDimensions";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Anpassad dimension</FormAreaTitle>

      <TextInput
        title="Namn på dimension"
        storeName={storeName}
        fieldKey={"title"}
        method={method}
      />
      <TextInput
        title='SIE-ID på dimension som ska användas (minst "20")'
        description="SIE-formatet gör plats för egna anpassade dimensioner på ID 20 och uppåt"
        storeName={storeName}
        fieldKey={"dimension_id"}
        method={method}
      />
    </>
  );
};
