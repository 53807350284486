import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  downloadReportFile,
  deleteReport,
  setHotRefresh,
  removeReportInAll,
  setBadgeFilterCounts,
  purgeTemplatePathing,
} from "../../../store/newExcelReports";

import { STRUCTURES } from "../templates";

import { addToast, TOAST_TYPES } from "../../../store/toasts";

export function useReportMethods({ setDidDelete }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [deleteReportInfo, setDeleteReportInfo] = React.useState({
    report: undefined,
    show: false,
    loading: false,
    queryString: undefined,
  });

  const downloadAgain = (report) => {
    downloadReportFile(
      report.result.get,
      `${report.title}.${report.format}`,
      report.format
    );
  };

  const resetDeleteReportInfo = () => {
    setDeleteReportInfo({
      report: undefined,
      show: false,
      loading: false,
      queryString: undefined,
    });
  };

  const removeReport = (evt, report, queryString) => {
    evt.stopPropagation();

    setDeleteReportInfo({
      report,
      show: true,
      loading: false,
      queryString,
    });
  };

  function doRemoveReport() {
    setDeleteReportInfo({
      ...deleteReportInfo,
      show: false,
      loading: true,
    });

    dispatch(
      deleteReport({
        id: deleteReportInfo.report.id,
        onSuccess: () => {
          dispatch(
            removeReportInAll(
              deleteReportInfo.report,
              deleteReportInfo.queryString
            )
          );

          dispatch(purgeTemplatePathing());
          //TODO: purge badge filter counts?

          setDidDelete(true);

          resetDeleteReportInfo();

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Rapporten har raderats",
            })
          );
        },
        onError: () => {
          resetDeleteReportInfo();

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde inte radera rapporten",
              description: "Ladda om sidan och försök igen",
            })
          );
        },
      })
    );
  }

  const goToReportTable = (report) => {
    if (!STRUCTURES?.[report.report]) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Ett fel har inträffat",
          description: "Ladda om sidan och försök igen",
        })
      );
      return;
    }

    dispatch(setHotRefresh({}));

    if (report.base_template) {
      history.push("/reports/table-view/" + report.baseTemplateName);
    } else {
      history.push("/reports/table-view/" + report.id);
    }
  };

  return {
    goToReportTable,
    downloadAgain,
    doRemoveReport,
    removeReport,
    deleteReportInfo,
    resetDeleteReportInfo,
  };
}
