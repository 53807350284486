import * as React from "react";
import { useHistory } from "react-router";
import { TextButton } from "../Buttons";
import * as SC from "./styles";

export default ({
  chapters,
  activeChapter,
  chapterClicked,
  title,
  onDone,
  askSaveWork,
}) => {
  const { goBack } = useHistory();

  return (
    <SC.Wrapper>
      {title && (
        <SC.FormTitleWrapper>
          <TextButton
            iconType="arrow-back"
            title="Tillbaka"
            inverted
            clicked={() => {
              if (askSaveWork) {
                askSaveWork();
              } else {
                onDone();
                goBack();
              }
            }}
          />
          <SC.FormTitle>{title}</SC.FormTitle>
        </SC.FormTitleWrapper>
      )}
      {chapters.map((chapter, index) => {
        const isActive = activeChapter.key === chapter.key;

        return (
          <React.Fragment key={chapter.key}>
            <SC.ChapterItem onClick={() => chapterClicked(chapter.key)}>
              <SC.StatusDot active={isActive} hasError={chapter.hasError} />
              {/* title only for fullscreen */}
              <SC.ChapterTitle {...{ isFullScreen: !!title }}>
                {chapter.title}
              </SC.ChapterTitle>
            </SC.ChapterItem>

            {index !== chapters.length - 1 && (
              <SC.StatusBar {...{ isFullScreen: !!title }} />
            )}
          </React.Fragment>
        );
      })}
    </SC.Wrapper>
  );
};
