import * as React from "react";

// style, design
import { BooleanLabel, DateCell, ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import { toMoneyString } from "../../utils/stringUtils";

export default () => [
  {
    Header: "",
    id: "__expander",
    Cell: ({ row }) => {
      return (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <span>▼</span> : <span>{">"}</span>}
        </span>
      );
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Andel av avgiftsgrupp",
    accessor: "payment_group_quota",
    Cell: ({ value, row }) => {
      return (
        <ToolTipCell text={value != null ? `${value.toFixed(2)} %` : "-"} />
      );
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Startdatum",
    accessor: "start_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Slutdatum",
    accessor: "end_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Initial belopp",
    accessor: "initial_value",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Kvarvarande belopp",
    accessor: "left_on_loan",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Årlig amortering",
    accessor: "yearly_amortization",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Amorteringsfrekvens (månader)",
    accessor: "amortization_every_months",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Fast räntesats",
    accessor: "fixed_interest",
    Cell: ({ value, row }) => {
      return (
        <ToolTipCell text={value != null ? `${value.toFixed(2)} %` : "-"} />
      );
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Rörlig ränta",
    accessor: "use_adjusting_interest",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Rörlig ränta justeras f.o.m",
    accessor: "adjusting_interest_adjust_from",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    Filter: SimpleDateFilter,
    filter: "between",
  },
  {
    Header: "Räntejusteringsfrekvens (mån)",
    accessor: "adjusting_interest_adjust_every_months",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Ej genomförd räntejustering",
    accessor: "next_missing_interest_adjustment_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Nästa räntejustering",
    accessor: "next_interest_adjustment_date",
    Cell: ({ value, row }) => {
      return <DateCell date={value} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Årsavgift för låst ränta",
    accessor: "non_adjusting_interest_yearly_fee",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={toMoneyString(value, true)} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Dagar i år",
    accessor: "days_in_year",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Godtagbart för kapitaltillskott",
    accessor: "consider_capital_contribution",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} offLabel={"Nej"} onLabel={"Ja"} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Bank",
    accessor: "total_fee_to_pay",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Referensnummer",
    accessor: "reference_number",
    Cell: ({ value, row }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
