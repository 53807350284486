import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import Alert, { ALERT_TYPES } from "../../components/Alert/Alert";

import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
} from "../../components/sharedStyles";
import { useIsImpersonation } from "../../store/app/hooks";

import ReportsOverview from "./ReportsOverviewTable";

import TableView from "./TableView";

export default function MainReports() {
  const { path, url } = useRouteMatch();

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ReportsOverview,
    },
  ];

  return (
    <DetailLayoutWrapper>
      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}

export { TableView };
