import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";
import {
  performFilter,
  constants,
} from "../../../store/roundingErrandSettings";
import { useRoundingErrandSettingsPagination } from "../../../store/roundingErrandSettings";
import { useHistory } from "react-router";
import { useFilteredRealEstates } from "../../../store/realEstates";

export default function RoundingErrandSettingTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  title,
}) {
  const { push } = useHistory();

  const [realestates] = useFilteredRealEstates("");

  const goToDetail = (original) => {
    const id = original?.id;
    const kind = original?.kind;
    if (!id) return;
    if (kind != null) {
      push(`/gov-settings/detail/${id}`);
    } else {
      push(`/rounding-settings/detail/${id}`);
    }
  };

  const columns = React.useMemo(() => columnDefs(realestates), [realestates]);

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "rounding_errand_settings_full_table"}
        title={title || "Ronderingsscheman"}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClickedWithDetail: goToDetail,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
