import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import * as SharedStyles from "../../../components/sharedStyles";

import VacancyBookingSettingTable from "../../../components/Tables/VancancyBookingSettings/FullTable";

import VacancyBookingSettingModalForm from "../../../components/Forms/VacancyBookingSetting/ChapterForm/ModalForm";

import PageTitle from "../../Layouts/PageTitle/PageTitle";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const breadCrumbs = [
  {
    label: "Vakansbokningsinställningar",
  },
];

export default function InvoicingSettings(props) {
  const [createVacancySettingOpen, setCreateVacancySettingOpen] =
    React.useState(false);

  const ACTION = (
    <div style={{ marginTop: "20px" }}>
      <PrimaryButton
        newDesign
        clicked={() => setCreateVacancySettingOpen(true)}
        title="Lägg till ny inställning för vakansbokning"
      ></PrimaryButton>
    </div>
  );

  return (
    <>
      <VacancyBookingSettingModalForm
        method="POST"
        isOpen={createVacancySettingOpen}
        onCheckout={() => setCreateVacancySettingOpen(false)}
      />

      <SharedStyles.DetailInnerWrapper>
        <SharedStyles.BareLayoutTitleWrapper>
          <PageTitle
            title="Inställningar - vakansbokning"
            breadCrumbs={breadCrumbs}
          />
        </SharedStyles.BareLayoutTitleWrapper>

        <SharedStyles.DetailPageBox config={true}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Vakansbokning</OverviewTitle>
              <OverviewSubtitle>{ACTION}</OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <VacancyBookingSettingTable />
        </SharedStyles.DetailPageBox>
      </SharedStyles.DetailInnerWrapper>
    </>
  );
}
