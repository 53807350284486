import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import SettingsTable from "../../../components/Tables/ReportErrandSettings/FullTable";
import InspectionSettingsTable from "../../../components/Tables/InspectionErrandSettings/FullTable";

import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useAllPermissionCheck } from "../../../store/base";
import ReportSettingModalForm from "../../../components/Forms/ReportErrandSetting/ChapterForm/ModalForm";
import InspectionSettingModalForm from "../../../components/Forms/InspectionErrandSetting/ChapterForm/ModalForm";

export default function Settings() {
  const [createReportSettingOpen, setCreateReportSettingOpen] =
    React.useState(false);
  const [createInspectionSettingOpen, setCreateInspectionSettingOpen] =
    React.useState(false);

  const canAddReportErrands = useAllPermissionCheck([
    "add_can_reporterrand",
    "allow_errand_faults",
  ]);
  const canAddInspections = useAllPermissionCheck([
    "add_can_inspectionerrand",
    "allow_errand_inspections",
  ]);

  return (
    <>
      {canAddReportErrands && (
        <ReportSettingModalForm
          method="POST"
          isOpen={createReportSettingOpen}
          onCheckout={() => setCreateReportSettingOpen(false)}
        />
      )}
      {canAddInspections && (
        <InspectionSettingModalForm
          method="POST"
          isOpen={createInspectionSettingOpen}
          onCheckout={() => setCreateInspectionSettingOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Ärendetyper</OverviewTitle>
              <OverviewSubtitle>
                Ärendetyper är till för att enkelt kategorisera ärenden. En
                ärendetyp kan även automatiskt delegera ärendet till rätt roll
                för utförande.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <DetailPageBoxFlexWrapper>
            <InnerBox style={{ marginRight: 6, flex: 1 }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Felanmälan</OverviewTitle>
                  <OverviewSubtitle>
                    {canAddReportErrands && (
                      <TextButton
                        iconType="add"
                        iconPlacement="right"
                        title="Lägg till inställning"
                        clicked={() => setCreateReportSettingOpen(true)}
                      />
                    )}
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              <SettingsTable hideTitle hideSearch hideExport />
            </InnerBox>
            <InnerBox style={{ marginLeft: 6, flex: 1 }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Besiktningar</OverviewTitle>
                  <OverviewSubtitle>
                    {canAddInspections && (
                      <TextButton
                        iconType="add"
                        iconPlacement="right"
                        title="Lägg till inställning"
                        clicked={() => setCreateInspectionSettingOpen(true)}
                      />
                    )}
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              <InspectionSettingsTable hideTitle hideSearch />
            </InnerBox>
          </DetailPageBoxFlexWrapper>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
