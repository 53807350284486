import { cloneDeep, isEqual, set } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LocalFormSelectField from "src/components/Forms/Base/Fields/LocalFormSelectField";
import FilterForm from "src/components/Forms/Insights/PageTopBar/FilterForm";
import { useFilteredCompanies } from "src/store/companies";
import { useRealEstatePortfolio } from "src/store/realEstatePortfolios";
import { useFilteredRealEstates } from "src/store/realEstates";
import { addToast, TOAST_TYPES } from "src/store/toasts";
import { clearAllValues } from "src/store/widgetPlacements";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import { useInsightsPages, update, constants } from "../../../store/insightsPage";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import LocalSelectField from "../../Forms/Base/Fields/LocalSelectField";

import * as SC from "./styles";

const inputsReducer = (state, action) => {
  if (action?.type === "all") {
    return action.value;
  }
  if (action?.type === "overrideMultiProps") {
    return {
      ...state,
      ...action.value,
    };
  }
  if (action?.key.includes(".")) {
    let newState = cloneDeep(state);
    set(newState, action?.key, action?.value);
    return newState;
  }
  return {
    ...state,
    [action.key]: action.value,
  };
};

export default function PageTopBar({
  _currentPage,
  openModal,
  setGoalOverviewOpen,
  defaultPage,
}) {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = React.useState(null)

  const [insightsPages, insightsPagesLoading] = useInsightsPages();

  const hasViewPermission = useAllPermissionCheck([
    "allow_insights",
    "view_can_insights",
  ]);
  const localFilterMode = hasViewPermission && defaultPage
  const hasCreatePermission = useAllPermissionCheck([
    "allow_insights",
    "add_can_insights",
  ]);

  const hasChangePermission = useAllPermissionCheck([
    "allow_insights",
    "change_can_insights",
  ]);
  const reduxRealEstateIds = useSelector((state) => state[constants.STORE_NAME].resultData?.localRealEstateIds);
  const reduxCompanyIds = useSelector((state) => state[constants.STORE_NAME].resultData?.localCompanyIds);
  const reduxPortfolioId = useSelector((state) => state[constants.STORE_NAME].resultData?.localPortfolioId);
  let realestateQuery = buildQueryString({id: -1}) 
  let companyQuery = buildQueryString({id: -1}) 
  let portfolioQuery = null
  if (localFilterMode) {
    if (reduxRealEstateIds?.length > 0) {
      realestateQuery = buildQueryString({id__in: reduxRealEstateIds})
    }
    if (reduxCompanyIds?.length > 0) {
      companyQuery = buildQueryString({id__in: reduxCompanyIds})
    }
    if (reduxPortfolioId) {
      portfolioQuery = reduxPortfolioId    
    }
  } else {
    if (currentPage?.query_parameters?.realestate_ids?.length > 0) {
      realestateQuery = buildQueryString({id__in: currentPage?.query_parameters?.realestate_ids})
    }
    if (currentPage?.query_parameters?.___company_ids?.length > 0) {
      companyQuery = buildQueryString({id__in: currentPage?.query_parameters?.___company_ids})
    }
    if (currentPage?.query_parameters?.___portfolio_id) {
      portfolioQuery = currentPage?.query_parameters.___portfolio_id
    }
  }
  const [realestates, realestatesLoading] = useFilteredRealEstates(realestateQuery);
  const [selectedCompanies, selectedCompaniesLoading] = useFilteredCompanies(companyQuery);
  const [selectedPortfolio, selectedPortfolioLoading] = useRealEstatePortfolio(portfolioQuery);

  const [pageChoices, setPageChoices] = React.useState([]);

  const [selectedInstance, setSelectedInstance] = React.useState(null);
  React.useEffect(() => {
    if (!_currentPage && localFilterMode) {
      const _page = {...defaultPage}
      _page["query_parameters"] = {
        realestate_ids: reduxRealEstateIds,
        ___company_ids: reduxCompanyIds,
        ___portfolio_id: reduxPortfolioId,
      }
    setCurrentPage(_page)
    } else {
    const _page = _currentPage || defaultPage
    setCurrentPage(_page)
    }
  }, [_currentPage])


  React.useEffect(() => {
    if (insightsPagesLoading) return;
    const newPageChoices = [];
    if (!defaultPage) {
      newPageChoices.push({
        d: "Dashboard",
        v: "/insights",
      });
    }

    for (let page of insightsPages) {
      if (page.id != currentPage?.id) {
        newPageChoices.push({
          d: page.title,
          v: `/insights/page/${page.id}`,
        });
      }
    }
    setPageChoices(newPageChoices);
  }, [currentPage, insightsPagesLoading]);

  const [hasCurrentPageLoaded, setHasCurrentPageLoaded] = React.useState(false);
  const [oldRealestateIds, setOldRealestateIds] = React.useState([]);

  const [inputsState, inputsStateDispatch] = React.useReducer(inputsReducer, {
    title: "",
    width: 6,
    height: 20,
    query_parameters: {},
  });

  React.useEffect(() => {
    if (!currentPage || hasCurrentPageLoaded) return;
    setHasCurrentPageLoaded(true);
    inputsStateDispatch({
      type: "all",
      value: currentPage,
    });
    setOldRealestateIds(currentPage?.query_parameters?.realestate_ids);
  }, [currentPage]);

  const [selectedRealEstates, setSelectedRealEstates] = React.useState([]);
  React.useEffect(() => {
    if (realestates.length === 0) return;
    setSelectedRealEstates(realestates);
  }, [realestates]);

  const onCloseRealEstateSelect = (
    realestates,
    companies,
    portfolio,
    loadingCallback
  ) => {
    let realEstateIds = [];
    let _realestateIds = [];
    let companyIds = [];

    if (realestates) {
      setSelectedRealEstates(realestates);
      realestates.map((elem) => {
        realEstateIds.push(elem.id) 
        _realestateIds.push(elem.id)
      })
        ;
    }
    if (companies) {
      companies.map((elem) => {
        elem.realestates.map((_obj) => realEstateIds.push(_obj));
        companyIds.push(elem.id);
      });
    }
    if (portfolio) {
      portfolio?.realestates?.map((_obj) => realEstateIds.push(_obj.id));
    }
    const queryObj = {
          realestate_ids: realEstateIds,
          ___company_ids: companyIds,
          ___portfolio_id: portfolio?.id,
    }
    inputsStateDispatch({
      type: "overrideMultiProps",
      value: {
        query_parameters: {
          ...queryObj
        },
      },
    });
    setCurrentPage(inputsState)
    onSubmit(queryObj)
    loadingCallback(false);
  };
  const onClearAllRealEstateSelect = () => {
    const queryObj = {
      realestate_ids: [],
      ___company_ids: [],
      ___portfolio_id: null,
    }
    inputsStateDispatch({
      type: "overrideMultiProps",
      value: {
        query_parameters: {
        ...queryObj
      }
    }});
    setSelectedInstance([])
    onSubmit(queryObj)
  };

  const onSubmit = (queryObj) => {
    const _inputsState = {...inputsState}
    if (queryObj) _inputsState["query_parameters"] = queryObj

    if (localFilterMode && queryObj) {
      dispatch({
        type: constants.SET_LOCAL_FILTERED_IDS,
        payload: { realestateIds: queryObj.realestate_ids, companyIds: queryObj.___company_ids, portfolioId: queryObj.___portfolio_id },
      });
      dispatch(clearAllValues())
    } else {
    dispatch(
      update({
        id: currentPage?.id,
        errorCallback: () => {
          dispatch(
            addToast({
              title: "Filtrering misslyckades",
              description: "Ladda om sidan och försök igen",
              type: TOAST_TYPES.ERROR,
            })
          );
        },
        successCallback: (_, returned) => {
          setCurrentPage(returned)
          dispatch(clearAllValues())
        },
        forceData: _inputsState,
        preventDefaultToast: true,
      })
    );
    }

  };

  React.useEffect(() => {
    if (!hasCurrentPageLoaded || localFilterMode) return;
    if (
      isEqual(oldRealestateIds, inputsState?.query_parameters?.realestate_ids)
    )
      return;

    onSubmit();
  }, [inputsState?.query_parameters?.realestate_ids]);

  React.useEffect(() => {
    if (selectedCompanies?.length > 0) {
      setSelectedInstance(selectedCompanies);
    } else if (selectedPortfolio) {
      setSelectedInstance([selectedPortfolio]);
    } else if (selectedRealEstates) {
      setSelectedInstance(selectedRealEstates);
    }

  }, [selectedPortfolio, selectedRealEstates, selectedCompanies]);

  const instanceObj = {
    selectedCompanies,
    selectedPortfolio
  }
  if (!selectedPortfolio && (!selectedCompanies || selectedCompanies?.length === 0 )) instanceObj["realestates"] = realestates

  return (
    <SC.TopBarContainer>
      {hasViewPermission && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            {currentPage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                className="mr-1"
              >
                <LocalSelectField
                  placeholder={currentPage.title}
                  choices={pageChoices}
                  onChange={(val) => {
                    window.insightsPageId = null
                    push(val)}}
                  value={currentPage}
                  className="w-[200px] mr-1 h-[42px]"
                  id="insights_page_selector"
                  title="Visar"
                  labelClassName="!text-xs"
                />

                { hasChangePermission && (
                  <div className="mt-4 h-[42px]">
                    <PrimaryBtn
                      secondary
                      onClick={() => openModal("PATCH")}
                      className="h-full"
                    >
                      Redigera
                    </PrimaryBtn>
                  </div>
                )}
              </div>
            )}
            {currentPage && (
              <LocalFormSelectField
                FormComponent={FilterForm}
                onChange={(d) => {
                  setSelectedRealEstates(d);
                }}
                isMany
                value={selectedInstance}
                formInstance={currentPage}
                miscInstances={instanceObj}
                onDone={(realestates, companies, portfolio, loadingCallback) =>
                  onCloseRealEstateSelect(
                    realestates,
                    companies,
                    portfolio,
                    loadingCallback
                  )
                }
                onClearAll={() => onClearAllRealEstateSelect()}
                tooltipPopOverClassName={"!left-0"}
                popOverHeightMultiplier={5}
                title="Filtrering"
                className="text-2 mr-1 !h-[42px]"
                labelClassName="text-xs"
              />
            )}
          </div>
          <div
            style={{
              marginRight: "25px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
              }}
            >
              {hasCreatePermission && (
                <PrimaryBtn
                  primary
                  onClick={() => openModal("POST")}
                  className="mr-1 h-[42px]"
                >
                  Lägg till ny sida
                </PrimaryBtn>
              )}

              <PrimaryBtn secondary onClick={() => setGoalOverviewOpen(true)}>
                Mål
              </PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    </SC.TopBarContainer>
  );
}
