import { getPatchForm, getPostForm, constants } from "../index";
import { useForm } from "../../base";

export function useRealEstatePortfolioForm(method, id) {
    let params = {
        storeName: constants.STORE_NAME,
        method: method,
        id: id
    };
    if (method === "POST") {params.fetchMethod = getPostForm}
    else {params.fetchMethod = getPatchForm}
    
    return useForm(params)
}