const defs = [
  {
    title: "Annonsinställningar",
    key: "AD_DETAILS",
    visited: true,
    hasError: false,
    fieldKeys: [],
  },

  {
    title: "Gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
