import * as React from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import ProjectForm from "../../../components/Forms/InvoicingProject/ChapterForm/ModalForm";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  useInvoicingProject,
  constants,
} from "../../../store/invoicingProjects";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import StandardModal from "../../../components/Modals/StandardModal";

function InvoicingProject() {
  const { projectId } = useParams();

  const [editOpen, setEditOpen] = React.useState(false);
  const [project] = useInvoicingProject(projectId);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [cantDeleteInfoOpen, setCantDeleteInfoOpen] = React.useState(false);

  const { replace } = useHistory();

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      project: projectId,
    },
  });

  const errorQuery = {
    project: projectId,
  };

  const detailInfo = {
    "Information om projekt": [
      {
        title: "Status",
        value: project?.status_display,
      },
      {
        title: "Titel",
        value: project?.title,
      },
      {
        title: "Beskrivning",
        value: project?.description,
      },
      {
        title: "Projektledare",
        value: project?.project_leader,
      },
      {
        title: "Kontaktperson",
        value: project?.contact_name,
      },
      {
        title: "Startdatum",
        value: project?.start_date
          ? moment(project?.start_date).format("YYYY-MM-DD HH:mm")
          : "-",
      },
      {
        title: "Slutdatum",
        value: project?.end_date
          ? moment(project?.end_date).format("YYYY-MM-DD HH:mm")
          : "-",
      },
    ],
  };

  return (
    <>
      <StandardModal
        withActionBar
        title="Kan ej radera projekt"
        isOpen={cantDeleteInfoOpen}
        actionBarCancelTitle="Stäng"
        closeFunction={() => setCantDeleteInfoOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Detta projekt kan ej raderas då det används
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          Projekt som används kan ej raderas av bokföringsskäl.
        </BodyText>
      </StandardModal>

      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={project}
        constants={constants}
        errorCallback={() => setCantDeleteInfoOpen(true)}
        deletedCallback={() =>
          replace("/rental-notifications/invoicing-objects")
        }
      />

      <ProjectForm
        method="PATCH"
        id={project?.id}
        instance={project}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Projekt ${project?.str_representation || "Laddar..."}`}
          breadCrumbs={[
            {
              url: "/rental-notifications/invoicing-objects",
              label: "Produkter & Bokföring",
            },
            { label: `Projekt ${project?.str_representation || "Laddar..."}` },
          ]}
        />

        <DetailInnerWrapper>
          {errorCount > 0 && (
            <DetailPageBox error>
              <OverviewTitleWrapper>
                <OverviewTitle>Felmeddelanden</OverviewTitle>
              </OverviewTitleWrapper>

              <InvoicingErrorTable
                isBare
                ignoreLocalStorage
                persistantQuery={errorQuery}
              />
            </DetailPageBox>
          )}
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              <PopupButton
                leftAligned
                title="Hantera"
                actions={[
                  {
                    name: "Redigera",
                    onClick: () => setEditOpen(true),
                  },
                  {
                    name: "Radera",
                    isDelete: true,
                    onClick: () => setDeleteOpen(true),
                  },
                ]}
              />
            </OverviewTitleWrapper>

            <DetailInfo infoObj={detailInfo} />
          </DetailPageBox>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}

export default InvoicingProject;
