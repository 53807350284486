import * as React from "react";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../../Forms/Base/Buttons";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";

export default ({ buildingId, goBack, selectPremises, selectedPremises }) => {
  const q = {
    building: buildingId,
  };

  return (
    <>
      <TextButton
        title="Tillbaka"
        clicked={goBack}
        iconType="arrow-back"
        extraStyle={{ marginBottom: 12 }}
      />

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Koppla rum till lokal</OverviewTitle>
          <OverviewSubtitle>
            Välj en lokal att koppla de valda ytorna till
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTableSelectField
          TableComponent={IndustrialPremisesTable}
          placeholder="Välj lokal..."
          title="Lokal"
          value={selectedPremises}
          persistantQuery={q}
          onChange={(obj) => {
            selectPremises(obj);
          }}
        />
      </div>
    </>
  );
};
