import { useDispatch, useSelector } from "react-redux";
import { useInProgress } from "../../base";
import { getAccountBalanceForDebtor } from "..";

import constants from "../store/constants";

export function useAccountBalanceForDebtor(debtorId) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const inProcessName = `account_balance_${debtorId}`;

  const accountBalance = useSelector(
    (state) => state[constants.STORE_NAME][debtorId]
  );

  const inProgress = useInProgress({ storeName, name: inProcessName });

  if (inProgress) return [undefined, true];

  if (!accountBalance && debtorId) {
    dispatch(getAccountBalanceForDebtor({ debtorId, inProcessName }));
    return [undefined, true];
  }

  return [accountBalance, false];
}
