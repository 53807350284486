import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import CompaniesTable from "../../../../components/Tables/Companies/FullTable";
import InvoicingLeaseTable from "../../../../components/Tables/InvoicingLease/FullTable";
import InvoicingParkingTable from "../../../../components/Tables/InvoicingParking/FullTable";
import InvoicingOtherTable from "../../../../components/Tables/InvoicingOther/FullTable";
import InvoicingBrfTable from "../../../../components/Tables/InvoicingBrf/FullTable";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../../components/sharedStyles";
import { buildQueryString } from "../../../../store/base";
import PageSlider from "../../../Layouts/PageSlider/PageSlider";

export const TABS = {
  COMPANY: "Bolag",
  LEASE: "Hyresavtal",
  PARKING: "Parkeringsavtal",
  OTHER: "Övriga avtal",
  BRF: "Bostadsrätter",
};

function InvoicingSettingUsedBy() {
  const { settingId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState("COMPANY");

  const persistantQuery = {
    setting: settingId,
  };

  const companyPersistantQuery = {
    invoicing_config: settingId,
  };

  const companyPersistantFilterMethod = (obj) =>
    obj.invoicing_config?.id == settingId;

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Används av</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        {selectedTab === "COMPANY" && (
          <CompaniesTable
            {...{
              persistantQuery: companyPersistantQuery,
            }}
          />
        )}

        {selectedTab === "LEASE" && (
          <InvoicingLeaseTable {...{ persistantQuery }} />
        )}

        {selectedTab === "PARKING" && (
          <InvoicingParkingTable {...{ persistantQuery }} />
        )}

        {selectedTab === "OTHER" && (
          <InvoicingOtherTable {...{ persistantQuery }} />
        )}

        {selectedTab === "BRF" && (
          <InvoicingBrfTable {...{ persistantQuery }} />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default InvoicingSettingUsedBy;
