import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  create,
  destroyPatchForm,
  destroyPostForm,
  update,
  constants,
  detailUrl,
  useCompanyForm,
} from "../../../../store/companies";
import Modal from "../../Base/Modals/Modal";
import TextInputField from "../../Base/Fields/TextInputField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import UserTable from "src/components/Tables/Users/FullTable";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";
import VacancyBookingSettingTable from "src/components/Tables/VancancyBookingSettings/FullTable";
import { updateActiveFormInstance } from "src/store/base";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import { AddressField } from "../../Base/Fields";
import MediaField from "../../Base/Fields/MediaField";

export default function CompanyModalForm({ method, id, onCheckout, instance }) {
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const { push } = useHistory();
  const formLoaded = Boolean(useCompanyForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    if (method === "POST") {
      push(detailUrl({ id: returned.id, showInvoicingSettingPrompt: true }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={method === "POST" ? "Skapa bolag" : "Uppdatera bolag"}
      onAccept={onSubmit}
      acceptTitle="Spara"
      closeFunction={onDone}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Bolagsuppgifter</div>

      <div class="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Bolagsnamn"
          storeName={storeName}
          fieldKey="name"
          method={method}
        />
        <TextInputField
          title="Orgnr"
          storeName={storeName}
          fieldKey="orgnr"
          method={method}
        />

        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"owner"}
          persistantQuery={{ "user_type__in!": [3, 5] }}
          placeholder="Välj ansvarig användare..."
          title="Ansvarig användare (signeringspart)"
          description={
            "Välj vilken användare som föreslås som signerande part på digitala dokument under detta bolag"
          }
          TableComponent={UserTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 ">
        <AddressField
          title="Bolagets adress"
          storeName={storeName}
          fieldKey="address"
          method={method}
        />
      </div>

      <MediaField
        storeName={storeName}
        method={method}
        fieldKey={`image`}
        instructionsKey={`image`}
        allowedFormats={[".png", ".jpg", ".jpeg"]}
        title="Bolagets logga"
        fileKey="image"
      />

      <hr />

      <div className="mb-6 text-base font-medium">Avisering & Bokföring</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"invoicing_config"}
          placeholder="Välj aviseringsinställning..."
          title="Föreslagen aviseringsinställning"
          description={
            "Välj vilken basinställning för avisering som ska föreslås för avtal som skapas under bolaget"
          }
          TableComponent={InvoicingSettingTable}
        />

        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"vacancy_booking_config"}
          placeholder="Välj inställning för vakansbokning..."
          title="Inställning för vakansbokning"
          description={
            "Välj vilken basinställning för vakansbokning som ska användas för objekt under bolaget"
          }
          TableComponent={VacancyBookingSettingTable}
        />
      </div>
    </Modal>
  );
}
