import Areas from "./Areas";
import Confirm from "./Confirm";
import Document from "./Document";
import Errand from "./Errand";

// Map of chapterdef KEY: Component
export default {
  FAULTS: Areas,
  DOCUMENT: Document,
  ERRAND: Errand,
  CONFIRM: Confirm,
};
