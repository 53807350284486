import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import Costs from "../../components/Details/OverviewInfo/Costs/Costs";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { setActiveFormInstance, usePermissionCheck } from "../../store/base";

import { constants as invoicingConstants } from "../../store/invoicingParking";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import {
  useServiceContract,
  cancelUrl,
  constants,
} from "../../store/serviceContracts";
import ServieContractDocumentTabs from "./detail/Documents";
import ServiceContractOverview from "./detail/Overview";

export default function ServiceContractDetail() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  const { serviceContractId } = useParams();

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [serviceContract, serviceContractLoading] =
    useServiceContract(serviceContractId);
  const canDelete = usePermissionCheck("delete_can_contract");
  const canEdit = usePermissionCheck("change_can_contract");
  const isCancelled =
    serviceContract?.closed_date || serviceContract?.closed_renew_date;
  const isSigned = serviceContract?.is_signed;

  // clear form states
  React.useEffect(() => {
    return () => {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: invoicingConstants.STORE_NAME,
          data: {},
        })
      );
    };
  }, []);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Avtal",
      component: ServiceContractOverview,
    },
    {
      path: "/costs",
      label: "Kostnader",
      component: Costs,
    },
    {
      path: "/docs",
      label: "Dokument",
      component: ServieContractDocumentTabs,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleCancelClicked = () => {
    push(cancelUrl({ id: serviceContractId, isManualSign: true }));
  };

  const handleActions = [];

  if (canEdit && !isCancelled && isSigned) {
    handleActions.push({
      name: "Säg upp",
      onClick: handleCancelClicked,
    });
  }

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={serviceContract?.state}>
          {serviceContract?.state_display}
        </StatusLabel>
        {serviceContract?.is_signed && (
          <StatusLabel style={{ marginLeft: 12 }} state={1}>
            Signerat
          </StatusLabel>
        )}
      </>
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        constants={constants}
        instance={serviceContract}
        deletedCallback={() => push("/service-contracts")}
      />

      <DetailLayoutWrapper>
        {serviceContractLoading && <OverlaySpinner />}
        <DetailPageHeaderMenu
          title={`Serviceavtal ${serviceContract?.id_number || "Laddar..."}`}
          breadCrumbs={[
            { url: "/service-contracts", label: "Serviceavtal" },
            { label: serviceContract?.id_number || "Laddar..." },
          ]}
          eventContentType="standard.servicecontract"
          eventObjectId={serviceContractId}
          eventIdQueryStr="servicecontract"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
