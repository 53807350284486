import styled from "styled-components";

export const PremisesWrapper = styled.div`
  width: 100%;
  min-height: 150px;
  padding: 20px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 24px;
`;

export const PremisesTitle = styled.div`
  font-weight: 600;
  margin-bottom: 6px;
`;

export const PremisesInfo = styled.div`
  font-size: 0.8rem;
`;
