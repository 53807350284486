import * as React from "react";

import {
  TextInput,
  Select,
  AddressField,
  NumberInput,
  Checkbox,
  TextField,
  RadioGroup,
  DatePicker,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/loan";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <NumberInput
      title={"Årlig amortering"}
      fieldKey={"yearly_amortization"}
      storeName={storeName}
      method={method}
      description={"Det totala beloppet som ska amorteras varje år"}
      />
      <NumberInput
      title={"Amorteringsfrekvens (månader)"}
      fieldKey={"amortization_every_months"}
      storeName={storeName}
      method={method}
      description={"Ange exempelvis 3 för att indikera på att amortering görs var tredje månad (om startdatumet är i januari resulterar detta i amortering varje kvartal)"}
      />
    </>
  );
};
