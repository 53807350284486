import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    {
      value: "office",
      display: "Kontor",
    },
    {
      value: "education",
      display: "Utbildning",
    },
    {
      value: "meetingroom",
      display: "Mötesrum",
    },
    {
      value: "storage",
      display: "Förråd",
    },
    {
      value: "ground",
      display: "Mark",
    },
    {
      value: "other",
      display: "Annan",
    },
    {
      value: "gym",
      display: "Gym",
    },
    {
      value: "leisure",
      display: "Fritidsverksamhet",
    },
    {
      value: "workshop",
      display: "Verkstad",
    },
    {
      value: "library",
      display: "Bibliotek",
    },
    {
      value: "laundry",
      display: "Tvättstuga",
    },
    {
      value: "community",
      display: "Gemensamhetsutrymme",
    },
    {
      value: "entrance",
      display: "Entré",
    },
    {
      value: "staircase",
      display: "Trappuppgång",
    },
    {
      value: "elevator_room",
      display: "Hissrum",
    },
    {
      value: "utility_room",
      display: "Förråd",
    },
    {
      value: "machine_room",
      display: "Maskinrum",
    },
    {
      value: "playground",
      display: "Lekplats",
    },
    {
      value: "garden",
      display: "Trädgård",
    },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
