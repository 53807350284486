import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import Pipes from "./Overview/Pipes";

import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import MarketRequirements from "./Overview/Requirements";
import Ads from "./Overview/Ads";
import MarketOverview from "./Overview/Overview";

export default function MainMarketOverview() {
  const { path, url } = useRouteMatch();

  usePermissionRedirect(["view_can_market", "allow_market"]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: MarketOverview,
    },
    {
      path: "/pipes",
      label: "Projekt",
      component: Pipes,
    },
    // {
    //   path: "/queues",
    //   label: "Köer",
    //   component: () => <div></div>,
    // },
    {
      path: "/ads",
      label: "Annonser",
      component: Ads,
    },

    // {
    //   path: "/marketplaces",
    //   label: "Marknadsplatser",
    //   component: () => <div></div>,
    // },
    {
      path: "/requirements",
      label: "Kravprofiler",
      component: MarketRequirements,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return <></>;
  };

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}></div>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Marknad`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
