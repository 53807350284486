import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];

    // errand execution ratings files
    const errandRatingFiles = [];
    const errandRatingFilePutUrls = [];

    const putUrl = responseData?.file?.put;

    if (formInstance.file?._tempData && putUrl) {
      errandRatingFiles.push(formInstance.file._tempData.data);
      errandRatingFilePutUrls.push(putUrl);
    }

    if (errandRatingFiles?.length && errandRatingFilePutUrls?.length) {
      errandRatingFilePutUrls.forEach((u) => urls.push(u));
      errandRatingFiles.forEach((f) => files.push(f));
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
