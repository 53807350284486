import * as React from "react";

// store, state
import { constants } from "../../../../../store/vacancyBookingSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import PremisesStatusTable from "src/components/Tables/PremisesStatuses/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Statusar som ska påverkas</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för vilka statusar som ska och inte ska påverkas av
            denna inställning under de bolag den sätts på
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"not_for_statuses"}
          placeholder="Välj statusar..."
          title="Exkluderade statusar"
          description={
            "Objekt med dessa statusar kommer INTE att vakansbokas under denna inställning, även om de på grund av en av de inkluderade statusarna inkluderas"
          }
          TableComponent={PremisesStatusTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"only_for_statuses"}
          placeholder="Välj statusar..."
          title="Inkluderade statusar"
          renderSubtitle={() => (
            <div>
              Om ingen status väljs gäller inställningen för alla objekt under
              bolaget inställningen sätts på (exklusive eventuella exkluderade
              statusar)
            </div>
          )}
          description={
            "Objekt med dessa statusar kommer att vakansbokas under denna inställning, dock ENBART om de ej också har en status som är exkluderad i fältet övanför"
          }
          TableComponent={PremisesStatusTable}
        />
      </div>
    </>
  );
};
