import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default (canParakey) => {
  let res = [
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value, row }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Antal gemensamma utrymmen",
      id: "common_areas_amount",
      Cell: ({ value, row }) => {
        const val =
          row.original?.parakey_id != null
            ? "-"
            : `${row.original.common_areas.length} st`;
        return <ToolTipCell text={val} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Antal lägenheter",
      accessor: "apartments_amount",
      Cell: ({ value, row }) => {
        const val =
          row.original?.parakey_id != null
            ? "-"
            : `${row.original.apartments.length} st`;
        return <ToolTipCell text={val} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Antal lokaler",
      accessor: "industrial_premises_amount",
      Cell: ({ value, row }) => {
        const val =
          row.original?.parakey_id != null
            ? "-"
            : `${row.original.industrial_premises.length} st`;
        return <ToolTipCell text={val} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];

  if (canParakey) {
    res.splice(0, 0, {
      Header: "Parakey ID",
      accessor: "parakey_id",
      Cell: ({ value, row }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    });
  }

  return res;
};
