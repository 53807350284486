import * as React from "react";
import { RadioGroup } from "../../../Base/Fields";

import { TextButton } from "../../../Base/Buttons";
import { useFormInstanceField } from "../../../../../store/base";
import InvoicingSettingSummary from "../../../../Summaries/InvoicingSettingSummary";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";

export default ({
  method,
  onGoToCreateNew,
  onCopyAndCreateNew,
  isBrf,
  storeName,
}) => {
  const [detailOpen, setDetailsOpen] = React.useState(false);

  const selectedSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const invoicingActive = useFormInstanceField({
    storeName,
    fieldKey: "is_active",
  });

  return (
    <>
      <RadioGroup
        defaultValue={true}
        title="Avisera för detta avtal"
        {...{ storeName, fieldKey: "is_active", method: "POST" }}
        options={[
          { label: "Ja, avisera för detta avtal via Pigello", value: true },
          { label: "Nej, avisera ej för detta avtal", value: false },
        ]}
      />
      {invoicingActive && (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              method={method}
              fieldKey={"billing_company"}
              placeholder="Välj fakturerande bolag..."
              title="Fakturerande bolag"
              description={
                isBrf
                  ? "Välj vilken förening denna bostadsrätt ska aviseras ifrån. Om inget bolag väljs kommer föreningen som bostadsrätten tillhör att användas"
                  : "Välj vilket bolag detta avtal ska aviseras ifrån. Om inget bolag väljs kommer bolaget som hyresobjektet tillhör att användas"
              }
              TableComponent={CompanyTable}
            />
          </div>

          <div className="grid grid-cols-1 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              method={method}
              fieldKey={"setting"}
              placeholder="Välj aviseringsinställning..."
              title="Aviseringsinställning"
              description={
                isBrf
                  ? "Välj vilken basinställning för avisering som ska användas för bostadsrätten"
                  : "Välj vilken basinställning för avisering som ska användas för avtalet"
              }
              renderSubtitle={
                selectedSetting && !detailOpen
                  ? () => (
                      <TextButton
                        title="Visa detaljer på inställning"
                        clicked={() => setDetailsOpen(true)}
                        iconType="info"
                      />
                    )
                  : null
              }
              TableComponent={InvoicingSettingTable}
            />
          </div>
          {!selectedSetting && (
            <TextButton
              title="Eller tryck här för att skapa en ny inställning"
              iconType="add"
              iconPlacement="right"
              clicked={() => onGoToCreateNew()}
            />
          )}
          {selectedSetting && detailOpen && (
            <InvoicingSettingSummary
              onCopy={onCopyAndCreateNew}
              setting={selectedSetting}
            />
          )}
        </>
      )}
    </>
  );
};
