import { throttle } from "lodash";
import * as React from "react";
import { useChapterErrors, useFormInstanceField } from "../../../../store/base";
import Pdf from "../../../Pdf/Pdf";
import ToolTip from "../../../ToolTip/ToolTip";
import { TextButton } from "../Buttons";
import FlowFormChapter from "./FlowFormChapter";
import * as SC from "./styles";
import { isInViewport } from "./utils";

// ...rest is whatever else gets passed down, which can be specific flags and functions depending on what form is in use
export default ({
  chapterDefs,
  chapters,
  descriptions,
  method,
  title,
  displayDocumentFieldKey,
  storeName,
  onSubmit,
  onSaveDraft,
  topButtonConfig,
  ...rest
}) => {
  const [topIndexInView, setTopIndexInView] = React.useState(null);
  const [documentOpen, setDocumentOpen] = React.useState(false);

  // primary error chapter, if more than 1 the first index should be top
  const [topErrorChapter, setTopErrorChapter] = React.useState(null);
  const [topErrorElement, setTopErrorElement] = React.useState(null);
  // determine wether the "top" error chapter in is view
  const [topErrorChapterInView, setTopErrorChapterInView] =
    React.useState(false);
  // determine direction of error arrow
  const [topErrorChapterIsAbove, setTopErrorChapterIsAbove] =
    React.useState(false);

  const chapterErrors = useChapterErrors({ storeName, chapterDefs });

  const displayDocument = useFormInstanceField({
    storeName,
    fieldKey: displayDocumentFieldKey,
  });

  const displayDocumentIsGetUrl = displayDocument?.includes("https://");

  const checkTopIndexInView = () => {
    if (chapterDefs.length) {
      const minHeight = window.scrollY;
      const viewPortFocus = minHeight + 100;

      let elementInFocus = null;
      let elementInFocusDistance = null;
      let elementInFocusIndex = null;

      let tempTopErrorChapter = null;
      let tempTopErrorChapterInView = false;
      let tempTopErrorChapterIsAbove = false;

      chapterDefs.forEach((chapterDef, i) => {
        const el = document.getElementById(chapterDef.key);

        if (!el) return;

        // only set one error chapter, index determines priority
        if (
          chapterErrors?.length &&
          chapterErrors.includes(chapterDef.key) &&
          !tempTopErrorChapter
        ) {
          tempTopErrorChapter = chapterDef;
          const [isInView, isAbove] = isInViewport(el);
          tempTopErrorChapterInView = isInView;
          tempTopErrorChapterIsAbove = isAbove;
          setTopErrorElement(el);
        }

        const focusOfElement = el.offsetTop + 100;

        const currDistance = Math.abs(viewPortFocus - focusOfElement);

        if (elementInFocus === null || currDistance < elementInFocusDistance) {
          elementInFocus = el;
          elementInFocusDistance = currDistance;
          elementInFocusIndex = i;
        }
      });

      setTopIndexInView(elementInFocusIndex);

      if (tempTopErrorChapter) {
        setTopErrorChapter(tempTopErrorChapter);
        setTopErrorChapterInView(tempTopErrorChapterInView);
        setTopErrorChapterIsAbove(tempTopErrorChapterIsAbove);
      }
    }
  };

  const throttledCheckTopIndexInView = throttle(checkTopIndexInView, 20);

  React.useEffect(() => {
    //initial check
    checkTopIndexInView();

    window.addEventListener("scroll", throttledCheckTopIndexInView);

    return () => {
      window.removeEventListener("scroll", throttledCheckTopIndexInView);
    };
  });

  React.useEffect(() => {
    checkTopIndexInView();
  }, [chapterErrors]);

  const goToErrorChapter = () => {
    topErrorElement &&
      topErrorElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onNavigateNext = () => {
    const next = document.getElementById(chapterDefs[topIndexInView + 1].key);

    next.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const onNavigatePrev = () => {
    const prev = document.getElementById(chapterDefs[topIndexInView - 1].key);

    prev.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <SC.FlowFormWrapper>
      {displayDocument && (
        <SC.DisplayDocumentWrapper open={documentOpen}>
          {!documentOpen && (
            <SC.OpenDisplayDocumentWrapper
              onClick={() => setDocumentOpen(true)}
            >
              <ToolTip
                placement="right"
                title="Visa dokument"
                content="Tryck här för att snabbt granska det uppladdade dokumentet."
                canClose
                persistantKey="tooltip_hide_document_flowform_tip"
              />
            </SC.OpenDisplayDocumentWrapper>
          )}

          <SC.DisplayDocumentTopWrapper>
            {documentOpen && (
              <SC.CloseDisplayDocumentWrapper
                onClick={() => setDocumentOpen(false)}
              />
            )}
          </SC.DisplayDocumentTopWrapper>

          <SC.InnerDocumentWrapper>
            {displayDocumentIsGetUrl ? (
              <Pdf url={displayDocument} />
            ) : (
              <Pdf b64={displayDocument} />
            )}
          </SC.InnerDocumentWrapper>
        </SC.DisplayDocumentWrapper>
      )}
      {topButtonConfig && (
        <TextButton
          extraStyle={{ marginBottom: 12 }}
          title={topButtonConfig.title}
          iconType={topButtonConfig.iconType}
          iconPlacement={topButtonConfig.iconPlacement}
          clicked={topButtonConfig.clicked}
        />
      )}
      {title && <SC.FlowFormTitle>{title}</SC.FlowFormTitle>}
      {chapterErrors?.length > 0 &&
        !topErrorChapterInView &&
        topErrorChapter && (
          <SC.ErrorIndicatorContainer onClick={goToErrorChapter}>
            <SC.ErrorIndicatorIcon isAbove={topErrorChapterIsAbove} />
            <SC.ErrorIndicatorText>
              Gå till fel i {topErrorChapter?.title}
            </SC.ErrorIndicatorText>
          </SC.ErrorIndicatorContainer>
        )}
      {chapterDefs.map((chapter, idx) => {
        const CurrentDescription = descriptions?.[chapter.key];
        const CurrentChapter = chapters?.[chapter.key];

        return (
          <FlowFormChapter
            {...{
              CurrentDescription,
              CurrentChapter,
              chapter,
              hasError: chapterErrors?.includes(chapter.key),
              method,
              chapterIndex: idx,
              chapterCount: chapterDefs.length,
              withNavigator: idx === topIndexInView,
              onNavigateNext,
              onNavigatePrev,
              onSubmit,
              onSaveDraft,
              storeName,
              ...rest,
            }}
          />
        );
      })}
    </SC.FlowFormWrapper>
  );
};
