import constants from "../store/constants";
import {
  useFilteredObjects,
  useObject,
  usePagination,
  useForm,
} from "../../base";
import {
  getSingle,
  getPatchForm,
  performFilter,
  filterPagination,
} from "../store/actions";

export function useRealEstatePortfolio(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useFilteredRealEstatePortfolios(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useRealEstatePortfolioPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}
