import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import {
  useAllPermissionCheck,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../store/base";
import { useDebtorInvoicing } from "../../store/invoicingDebtor";
import {
  useTenant,
  constants,
  overviewUrl,
  detailUrl,
  destroyPatchForm,
} from "../../store/tenants";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import TenantContracts from "./detail/Contracts";
import TenantInvoicing from "./detail/Invoicing";
import TenantOverview from "./detail/Overview";
import TenantPremises from "./detail/Premises";
import TenantErrands from "./detail/Errands";
import TenantKeys from "./detail/Keys";

import { useDispatch } from "react-redux";

function TenantDetail() {
  const dispatch = useDispatch();
  const { tenantId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const [tenant] = useTenant(tenantId);
  const [invoiceSettingPromptOpen, setInvoicingSettingPromtOpen] =
    React.useState(false);
  const { push } = useHistory();
  const query = useQuery();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  usePermissionRedirect(["view_can_tenant"]);

  const canDeleteTenant = usePermissionCheck("delete_can_tenant");
  const { hasBillectaViewPermission, hasBillectaFullPermission } =
    useFrequentPermissions();
  const canViewReportErrands = useAllPermissionCheck([
    "allow_errand_faults",
    "view_can_reporterrand",
  ]);

  const canKeys = useAllPermissionCheck(["allow_keys", "view_can_keys"]);

  const showInvoicingSettingPrompt = query.get("showInvoicingSettingPrompt");

  const [debtorInvoiceConfig] = useDebtorInvoicing(
    tenant?.debtor_invoice_config?.id
  );

  React.useEffect(() => {
    if (hasBillectaFullPermission && showInvoicingSettingPrompt == "true") {
      setInvoicingSettingPromtOpen(true);
    }
  }, [showInvoicingSettingPrompt, hasBillectaFullPermission]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm());
    };
  }, []);

  React.useEffect(() => {
    if (tenant?.is_brf) {
      replace(detailUrl({ id: tenantId, isMember: true }));
    }
  }, [tenant]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Hyresgäst",
      component: TenantOverview,
    },
    {
      path: "/contracts",
      label: "Avtal",
      component: TenantContracts,
    },
    {
      path: "/premises",
      label: "Hyresobjekt",
      component: TenantPremises,
    },
  ];

  if (hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      component: TenantInvoicing,
    });
  }

  if (canViewReportErrands) {
    SUB_PAGES.push({
      path: "/errands",
      label: "Felanmälan",
      component: TenantErrands,
    });
  }

  if (canKeys) {
    SUB_PAGES.push({
      path: "/keys",
      label: "Nycklar",
      component: TenantKeys,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canDeleteTenant) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  const goToInvoicing = () => {
    push(`/tenants/detail/${tenantId}/invoicing?opensettings=true`);
  };

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel contentType="tenant" state={tenant?.state}>
          {tenant?.state_display}
        </StatusLabel>
        <StatusLabel
          style={{ marginLeft: 12 }}
          state={debtorInvoiceConfig ? 1 : 6}
        >
          {debtorInvoiceConfig
            ? "Uppsatt för avisering"
            : "Ej uppsatt för avisering"}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={tenant}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <ConfirmationModal
        isOpen={invoiceSettingPromptOpen}
        closeFunction={() => setInvoicingSettingPromtOpen(false)}
        title="Vill du konfigurera aviseringsinställningar för denna hyresgäst direkt?"
        acceptTitle="Konfigurera nu"
        acceptCallback={goToInvoicing}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Hyresgäst ${tenant?.str_representation || "laddar..."}`}
          breadCrumbs={[
            { url: "/tenants", label: "Hyresgäster" },
            { label: tenant?.str_representation || "Laddar..." },
          ]}
          eventContentType="accounts.tenant"
          eventObjectId={tenantId}
          eventIdQueryStr="tenant"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default TenantDetail;
