import * as React from "react";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as requirementDetailUrl } from "../../../store/marketRequirements";
import { detailUrl as pipeDetailUrl } from "../../../store/pipes";
import { getAdStateAndLabel } from "../../../store/marketApartmentAds/utils";

// style, design
import { DateCell, LinkedObject, ToolTipCell } from "../../Displays";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: (row) => {
        const { stateDisplay } = getAdStateAndLabel(row);

        return stateDisplay;
      },
      Cell: ({ row }) => {
        const { stateDisplay, state } = getAdStateAndLabel(row.original);

        return <StatusLabel state={state}>{stateDisplay}</StatusLabel>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Lokal",
      accessor: (row) => {
        return row.industrial_premises?.str_representation;
      },
      Cell: ({ row }) => (
        <LinkedObject
          obj={row.original.industrial_premises}
          urlMethod={indpDetailUrl}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Publiceringsdatum",
      accessor: "date_publish",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Projekt",
      accessor: (row) => {
        return row.dump_in_pipe?.str_representation;
      },
      Cell: ({ row }) => {
        return (
          <LinkedObject
            obj={row.original.dump_in_pipe}
            urlMethod={pipeDetailUrl}
          />
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kravprofil",
      accessor: (row) => {
        return row.requirement?.str_representation;
      },
      Cell: ({ row }) => (
        <LinkedObject
          obj={row.original.requirement}
          urlMethod={requirementDetailUrl}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Hanteringsläge",
      accessor: "pilot_mode_display",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Tillträde",
      accessor: "access_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
  ];

  return cols;
};
