import * as React from "react";
import { useSelector } from "react-redux";

import { constants } from "../../../../../store/sendout";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import Summary from "../Summary";

export default ({ method }) => {
  const instance = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Granska och slutför</OverviewTitle>
          <OverviewSubtitle>
            Kontrollera att uppgifterna stämmer. Tryck på{" "}
            {method === "POST" ? "Skapa nytt utskick" : '"Uppdatera utskick"'}{" "}
            för att slutföra.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <Summary {...{ instance }} />
    </>
  );
};
