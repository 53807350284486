import * as React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { matchPaymentsToInvoice } from "../../../store/billectaInvoicing";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import MatchInvoicesTable from "./MatchInvoicesTable";
import MatchPaymentsTable from "./MatchPaymentsTable";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Modal from "../../Forms/Base/Modals/Modal";
import LocalDateSelect from "../../Forms/Base/Fields/LocalDateSelect";
import { TextButton } from "../../Forms/Base/Buttons";

const today = moment().format("YYYY-MM-DD");

const STEPS = {
  DATE: 1,
  INVOICE: 2,
  SUMMARY: 3,
};

export default function MatchPaymentsModal({
  selectedPayments,
  possibleInvoices = [],
  closeFunction,
  resetSelected,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [step, setStep] = React.useState(STEPS.DATE);

  const payments = selectedPayments.map((sp) => ({ ...sp.original }));
  const [paymentDate, setPaymentDate] = React.useState(today);

  React.useEffect(() => {
    if (
      paymentDate === today &&
      payments?.length === 1 &&
      payments[0]?.PaymentDate
    ) {
      setPaymentDate(payments[0].PaymentDate);
    }
  }, [payments.length]);

  const { creditorId } = useParams();

  const matchSelected = () => {
    setLoading(true);
    const invoiceId = selectedInvoice?.original?.ActionPublicId;
    const paymentIds = payments.map((p) => p.UnhandledPaymentPublicId);
    if (!invoiceId || !paymentIds?.length) {
      setLoading(false);
      return;
    }
    dispatch(
      matchPaymentsToInvoice({
        paymentIds,
        invoiceId,
        creditorId,
        paymentDate,
        successCallback: () => {
          setLoading(false);
          resetSelected();
          closeFunction();
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Betalningar matchades",
              description: "Saldor på de berörda fakturorna uppdaterades",
            })
          );
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message ? message : "",
            })
          );

          setLoading(false);
        },
        alternativeErrorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message ? message : "",
            })
          );

          resetSelected();
          closeFunction();

          setLoading(false);
        },
      })
    );
  };

  const acceptFunc =
    step === STEPS.DATE
      ? () => setStep(STEPS.INVOICE)
      : step === STEPS.INVOICE
      ? () => setStep(STEPS.SUMMARY)
      : step === STEPS.SUMMARY
      ? matchSelected
      : null;
  const canAccept =
    step === STEPS.DATE
      ? !!paymentDate
      : step === STEPS.INVOICE
      ? () => !!selectedInvoice
      : step === STEPS.SUMMARY
      ? selectedInvoice && !!paymentDate
      : false;

  return (
    <Modal
      title={`Matcha betalning${selectedPayments.length > 1 ? "ar" : ""}`}
      closeFunction={closeFunction}
      onAccept={acceptFunc}
      canAccept={canAccept}
      acceptTitle={step === STEPS.SUMMARY ? "Spara matchning" : "Fortsätt"}
    >
      {loading && <OverlaySpinner />}

      {step === STEPS.DATE && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalDateSelect
            value={paymentDate}
            title="Betaldatum"
            description="Välj vilket datum betalningen ska bokföras på. Om inget datum väljs kommer dagens datum automatiskt att bokföras som betaldatum."
            onChange={(val) => setPaymentDate(val)}
            maxDate={new Date().value}
          />
        </div>
      )}

      {step === STEPS.INVOICE && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            extraStyle={{ marginBottom: 12 }}
            clicked={() => setStep(STEPS.DATE)}
          />

          <div className="font-medium text-base">
            Välj faktura att matcha betalning
            {selectedPayments.length > 1 ? "ar" : ""} mot
          </div>
          <MatchInvoicesTable
            handleRowClicked={(row) => setSelectedInvoice(row)}
            invoices={possibleInvoices}
            selectedRow={selectedInvoice}
          />
        </>
      )}

      {step === STEPS.SUMMARY && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            extraStyle={{ marginBottom: 12 }}
            clicked={() => setStep(STEPS.INVOICE)}
          />

          <MatchPaymentsTable payments={payments || []} />

          <div className="font-medium text-base">Matchas mot</div>

          <MatchInvoicesTable
            hideSearch
            invoices={selectedInvoice ? [selectedInvoice.original] : []}
          />
        </>
      )}
    </Modal>
  );
}
