import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import {
  buildQueryString,
  setActiveFormInstance,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import {
  handleManyInvoicingUrl,
  constants,
  createOptionsUrl,
} from "../../store/leaseContracts";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";

import useActiveFilters from "../../hooks/useActiveFilters";
import LeaseContracts from "./overview/LeaseContracts";
// import LeaseCosts from "./overview/LeaseCosts";
import LeaseCosts from "./overview/LeaseCostsRework";

import LeaseContractsDocs from "./overview/LeaseContractsDocs";
import { useLeaseContractPaginationCount } from "../../store/leaseContracts/hooks/retrieve";
import BlockLeaseContracts from "./overview/BlockContracts";
import LeaseContractDrafts from "./overview/Drafts";
import HandleMassInvoincingLeaseContract from "./overview/HandleMassInvoicing";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useDispatch } from "react-redux";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function LeaseContractOverview() {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["view_can_contract"]);

  const canAdd = usePermissionCheck("add_can_contract");
  const { filteredRealEstates } = useActiveFilters();
  const { hasBillectaFullPermission } = useFrequentPermissions();

  const [activeCount] = useLeaseContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: false,
  });

  const [draftCount] = useLeaseContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: true,
  });

  React.useEffect(() => {
    dispatch(
      setActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {},
      })
    );
  }, []);

  const persistantQueryString = buildQueryString({
    realestate__in: filteredRealEstates,
    draft: false,
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Avtal",
      component: LeaseContracts,
    },
    {
      path: "/overview-with-costs",
      label: "Avtal med kostnader",
      component: LeaseCosts,
    },
    {
      path: "/blocks-contracts",
      label: "Blockavtal",
      component: BlockLeaseContracts,
    },
    {
      path: "/drafts",
      label: `Utkast${draftCount ? ` (${draftCount})` : ""}`,
      component: LeaseContractDrafts,
    },
    {
      path: "/handle-invoicing-statuses",
      hiddenLabel: true,
      component: HandleMassInvoincingLeaseContract,
    },
    {
      path: "/docs",
      label: "Digital signering",
      component: LeaseContractsDocs,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal hyresavtal: {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {hasBillectaFullPermission && (
        <PrimaryBtn secondary onClick={() => push(handleManyInvoicingUrl())}>
          Masshantera aviseringsstatus
        </PrimaryBtn>
      )}
      {canAdd && (
        <PrimaryBtn className="ml-2" onClick={() => push(createOptionsUrl())}>
          Lägg till avtal
        </PrimaryBtn>
      )}
    </div>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Hyresavtal`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
