import * as React from "react";

export default () => {
  return (
    <div>
      Skapa ny basinställning för avisering. Denna inställning kan återanvändas
      vid skapande av framtida avtal.
      <br />
      <br />
      Kostnader och datumspecifika inställningar för avisering ställs in i nästa
      steg.
    </div>
  );
};
