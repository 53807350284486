import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "../../../store/base";
import { adjustPayment, createUrl } from "../../../store/billectaInvoicing";
import { getPaymentMeansInstance } from "../../../store/billectaPaymentMeans";

import { addToast, TOAST_TYPES } from "../../../store/toasts";
import MatchInvoicesTable from "../../Billecta/Payments/MatchInvoicesTable";
import StatusFlag, { STATUS_FLAG_STATES } from "../../Displays/StatusFlag";

import OverlaySpinner from "../../Loaders/OverlaySpinner";
import LocalCheckField from "../Base/Fields/LocalCheckField";
import LocalDateSelect from "../Base/Fields/LocalDateSelect";
import LocalSelectField from "../Base/Fields/LocalSelectField";
import LocalTextInputField from "../Base/Fields/LocalTextInputField";
import Modal from "../Base/Modals/Modal";

export default function AdjustPaymentForm({
  creditorId,
  closeFunction,
  originalPaidAmount,
  originalInvoiceId,
  originalInvoiceDate,
  originalClosedDate,
  companyId,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [paymentMeans, setPaymentMeans] = React.useState(null);
  const [invoiceDateCredit, setInvoiceDateCredit] = React.useState(
    moment(originalInvoiceDate).format("YYYY-MM-DD")
  );
  const [attestCredit, setAttestCredit] = React.useState(false);
  const [registerCreditPayment, setRegisterCreditPayment] =
    React.useState(false);
  const [creditPaymentCode, setCreditPaymentCode] = React.useState(false);
  const [creditPaymentRef, setCreditPaymentRef] = React.useState("");
  const [creditPaymentDate, setCreditPaymentDate] = React.useState(
    moment(originalClosedDate).format("YYYY-MM-DD")
  );
  const [debitPaymentCode, setDebitPaymentCode] = React.useState(false);
  const [debitPaymentRef, setDebitPaymentRef] = React.useState("");
  const [debitPaymentDate, setDebitPaymentDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [matchPaymentToInvoice, setMatchPaymentToInvoice] =
    React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [canSave, setCanSave] = React.useState(false);
  const invoices = useSelector((state) => state.billectaInvoicing.invoices);
  const filteredInvoices = React.useMemo(() => {
    return (invoices || [])?.filter((i) =>
      [
        "Manual",
        "InvoiceSent",
        "Attested",
        "SentToDebtCollection",
        "ReminderInvoiceSent",
      ].includes(i.Stage)
    );
  }, [invoices]);

  React.useEffect(() => {
    dispatch(
      getPaymentMeansInstance({
        creditorId,
        successCallback: (data) => {
          setPaymentMeans(data);
        },
        errorCallback: (e) => {
          console.log(e);
        },
      })
    );
  }, []);

  const onAdjustPayment = () => {
    setLoading(true);
    dispatch(
      adjustPayment({
        companyId,
        originalInvoiceId,
        originalPaidAmount,
        invoiceDateCredit,
        attestCredit,
        registerCreditPayment,
        creditPaymentCode,
        creditPaymentDate,
        creditPaymentRef,
        matchPaymentToInvoice,
        debitPaymentCode,
        debitPaymentDate,
        debitPaymentRef,
        debitPaymentInvoiceId: selectedInvoice?.original?.ActionPublicId,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Kreditfaktura skapades och inställningar applicerades",
              description:
                "Kontrollera fakturakopia och spara för att slutföra",
            })
          );
          push(
            createUrl({
              creditorId,
              query: buildQueryString({
                copyFrom: originalInvoiceId,
                newInvoiceDate: debitPaymentDate,
              }),
            })
          );
        },
        errorCallback: (error) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                "Kontrollera om kreditfaktura skapades för att undvika dubletter",
            })
          );
        },
      })
    );
  };

  const checkCanSave = () => {
    if (!invoiceDateCredit) {
      setCanSave(false);
      return;
    }

    if (registerCreditPayment && (!creditPaymentCode || !creditPaymentDate)) {
      setCanSave(false);
      return;
    }

    if (moment(creditPaymentDate).isBefore(moment(invoiceDateCredit))) {
      setCanSave(false);
      return;
    }

    if (
      matchPaymentToInvoice &&
      (!selectedInvoice || !debitPaymentCode || !debitPaymentDate)
    ) {
      setCanSave(false);
      return;
    }

    setCanSave(true);
  };

  React.useEffect(() => {
    checkCanSave();
  }, [
    invoiceDateCredit,
    registerCreditPayment,
    creditPaymentCode,
    creditPaymentDate,
    matchPaymentToInvoice,
    debitPaymentCode,
    debitPaymentDate,
    selectedInvoice,
  ]);

  return (
    <Modal
      closeFunction={closeFunction}
      title="Justera felaktig inbetalning"
      onAccept={onAdjustPayment}
      canAccept={canSave}
      acceptTitle="Justera felaktig inbetalning och kopiera faktura"
      denyTitle="Avbryt"
    >
      {loading && <OverlaySpinner />}

      <div className="mb-6">
        <StatusFlag state={STATUS_FLAG_STATES.INFO}>
          <InformationCircleIcon width={24} className="mr-1" /> Justering av
          felaktig inbetalning sker i flera steg för att säkerställa att allting
          bokförs korrekt.
        </StatusFlag>
      </div>

      <div className="mb-6">
        <div className="font-medium">1. Kreditera denna faktura</div>
        <div className="text-sm">
          Genom att skapa en kredit och registrera en utbetalning på denna så
          kvittas den felaktiga inbetalningen i bokföringen. Välj om
          kreditfakturan ska attesteras direkt. Om krediten attesteras direkt
          kan även en automatisk betalning av krediten registreras direkt. Välj
          då betalsätt för denna betalning.
        </div>

        <div className="grid grid-cols-2 gap-6 my-6">
          <LocalDateSelect
            id="_creditDate"
            title="Fakturadatum på kredit"
            value={invoiceDateCredit}
            onChange={(val) => setInvoiceDateCredit(val)}
            required
          />
        </div>

        {moment().isSameOrAfter(moment(invoiceDateCredit)) ? (
          <div className="mb-6">
            <LocalCheckField
              id="_attestcredit"
              title="Attestera kreditfakturan direkt"
              value={attestCredit}
              onChange={(val) => setAttestCredit(val)}
            />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <StatusFlag state={STATUS_FLAG_STATES.ERROR}>
              <ExclamationTriangleIcon width={24} className="mr-2" />
              Kan ej attestera kreditfakturan direkt då fakturadatumen ligger i
              framtiden
            </StatusFlag>
          </div>
        )}

        {attestCredit && (
          <div className="mb-6">
            <LocalCheckField
              id="_registercreditpayment"
              title="Registrera automatisk betalning av kredit"
              value={registerCreditPayment}
              onChange={(val) => setRegisterCreditPayment(val)}
            />
          </div>
        )}

        {registerCreditPayment && (
          <div className="grid grid-cols-3 gap-6 mb-12">
            <LocalTextInputField
              id="_ref"
              title="Referens"
              description="Datumet har föreslagits från fakturadatumet."
              value={creditPaymentRef}
              onChange={(val) => setCreditPaymentRef(val)}
            />
            <LocalSelectField
              placeholder="Välj konto"
              title="Välj konto utifrån betalsätt"
              description="Ange samma betalsätt som inbetalningen."
              required
              choices={(paymentMeans || [])?.map((pm) => ({
                d: `${pm.Description} ${pm.BookkeepingAccount} (${pm.PaymentCode})`,
                v: pm.PaymentCode,
              }))}
              onChange={(val) => setCreditPaymentCode(val)}
              value={creditPaymentCode}
              id="pm_account"
            />
            <LocalDateSelect
              id="_paymentdate"
              title="Betaldatum"
              description="Datumet har föreslagits från inbetalningen."
              value={creditPaymentDate}
              onChange={(val) => setCreditPaymentDate(val)}
              required
            />
          </div>
        )}
      </div>

      <div className="mb-6">
        <div className="font-medium">
          2. (Frivilligt) Välj faktura som det inbetalade beloppet ska
          registreras mot
        </div>
        <div className="text-sm">
          Välj vilken faktura beloppet som betalades in felaktigt på denna
          faktura ska registreras mot istället. En ny inbetalning kommer att
          registreras mot denna faktura eftersom den felaktiga inbetalningen har
          bokats bort genom kreditfakturan. Detta kan istället göras senare
          genom att trycka på "Registrera inbetalning" inne på en faktura.
        </div>

        <div className="my-6">
          <LocalCheckField
            id="_matchpaymenttoinvoice"
            title="Registrera inbetalat belopp på faktura"
            value={matchPaymentToInvoice}
            onChange={(val) => setMatchPaymentToInvoice(val)}
          />
        </div>

        {matchPaymentToInvoice && (
          <>
            <div className="grid grid-cols-3 gap-6 mb-0">
              <LocalTextInputField
                id="_refdeb"
                title="Referens"
                value={debitPaymentRef}
                onChange={(val) => setDebitPaymentRef(val)}
                required
              />
              <LocalSelectField
                placeholder="Välj konto"
                title="Välj konto utifrån betalsätt"
                required
                choices={(paymentMeans || [])?.map((pm) => ({
                  d: `${pm.Description} ${pm.BookkeepingAccount} (${pm.PaymentCode})`,
                  v: pm.PaymentCode,
                }))}
                onChange={(val) => setDebitPaymentCode(val)}
                value={debitPaymentCode}
                id="pm_accountdeb"
              />
              <LocalDateSelect
                id="_paymentdatedeb"
                title="Betaldatum"
                value={debitPaymentDate}
                onChange={(val) => setDebitPaymentDate(val)}
                required
              />
            </div>

            <div className="mb-12">
              <MatchInvoicesTable
                handleRowClicked={(row) => setSelectedInvoice(row)}
                invoices={filteredInvoices || []}
                selectedRow={selectedInvoice}
              />
            </div>
          </>
        )}
      </div>

      <div className="mb-6">
        <div className="font-medium">
          3. Kopiera originalfaktura till ny faktura
        </div>
        <div className="text-sm">
          Tryck på "Justera felaktig inbetalning och kopiera faktura" för att
          spara kreditering och övriga val. I nästa stegs föreslås en ny faktura
          baserat på originalfakturan.
        </div>
      </div>
    </Modal>
  );
}
