import { useDispatch, useSelector } from "react-redux";
import {
  buildQueryString,
  useAllPermissionCheck,
  useFilteredObjects,
  useObject,
  useObjects,
  usePagination,
  usePermissionCheck,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";

import {
  getAtlasFastdocTemplates,
  getAtlasTemplates,
  getFastdocTemplates,
  getPrivateTemplates,
  getScriveHistory,
  checkHasSignedUp,
  getAll,
  performFilter,
  filterPagination,
  getSingle,
} from "../store/actions";

import constants from "../store/constants";
import { getHasSignedUpProcessName, getHistoryProcessName } from "../utils";

export const usePrivateTemplates = () => {
  const params = {
    storeName: constants.STORE_NAME,
    querystring: buildQueryString({
      is_template: true,
      is_public: false,
      fastdoc_id__isnull: true,
    }),
    fetchMethod: getPrivateTemplates,
  };

  return useFilteredObjects(params);
};

export const usePrivateFastdocTemplates = () => {
  const params = {
    storeName: constants.STORE_NAME,
    querystring: buildQueryString({
      is_template: true,
      is_public: false,
      fastdoc_id__isnull: false,
    }),
    fetchMethod: getPrivateTemplates,
  };

  return useFilteredObjects(params);
};

export const useAtlasTemplates = () => {
  const params = {
    storeName: constants.STORE_NAME,
    querystring: buildQueryString({ fastdoc_id__isnull: true }),
    fetchMethod: getAtlasTemplates,
  };

  return useFilteredObjects(params);
};

export const useAtlasFastdocTemplates = () => {
  const params = {
    storeName: constants.STORE_NAME,
    querystring: buildQueryString({ fastdoc_id__isnull: false }),
    fetchMethod: getAtlasFastdocTemplates,
  };

  return useFilteredObjects(params);
};

/**
 * Querystring is just for differentiationg instances from templates
 * in state
 */
export const useFastdocTemplates = () => {
  const params = {
    storeName: constants.STORE_NAME,
    querystring: constants.FASTDOC_TEMPLATE_QS_CONSTANT,
    fetchMethod: getFastdocTemplates,
  };

  return useFilteredObjects(params);
};

export const useScriveHistory = ({ id }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const existing = useSelector((state) => state[storeName].scriveHistory[id]);
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(getHistoryProcessName(id))
  );

  // first check if it exists in store
  if (existing) {
    return [existing, isFetching];
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result

  if (isFetching) {
    return [undefined, isFetching];
  }

  // if id wasn't set, return undefined as well
  if (!id) {
    return [undefined, isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(getScriveHistory(id));

  return [undefined, isFetching];
};

export const useHasSignedUpScrive = () => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const processName = getHasSignedUpProcessName();

  const existing = useSelector((state) => state[storeName].hasSignedUp);
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(processName)
  );

  // first check if it exists in store
  if (existing !== undefined) {
    return [existing, isFetching];
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result
  if (isFetching) {
    return [undefined, isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(checkHasSignedUp());

  return [undefined, isFetching];
};

export const useCanUseScrive = () => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const processName = getHasSignedUpProcessName();

  const hasScriveIntegration = useAllPermissionCheck([
    "add_can_e_signing",
    "allow_scrive",
  ]);

  const existing = useSelector((state) => state[storeName].canUse);
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(processName)
  );

  // first check if it exists in store
  if (existing !== undefined) {
    return [existing && hasScriveIntegration, isFetching];
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result
  if (isFetching) {
    return [undefined, isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(checkHasSignedUp());

  return [undefined, isFetching];
};

export function useEditabledoc(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };

  return useObject(params);
}

export function useEditabledocs(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredEditabledocs(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useEditabledocPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useEditableDocPaginationCount({ filters = {} }) {
  const queryString = buildQueryString({
    _page: 1,
    _page_size: 0,
    is_template: true,
    ...filters,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}
