import * as React from "react";
import { useHistory } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import {
  constants,
  detailUrl,
  useFilteredTenantPortalSettings,
} from "../../../store/tenantPortalSettings";
import { useFilteredRealEstates } from "../../../store/realEstates";

export default function TenantPortalSettingsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const [portalSettings, portalSettingsLoading] =
    useFilteredTenantPortalSettings("");

  const [realestates, realestatesLoading] = useFilteredRealEstates();

  const columns = React.useMemo(
    () => columnDefs(realestates),
    [portalSettings, persistantQuery]
  );

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "tenant_portal_settings_full_table"}
      title={"Inställningar"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
