import styled from "styled-components";
import closeIcon from "../../../assets/svg/material/close-circle_red.svg";

export const RemoveButton = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeIcon});
`;
