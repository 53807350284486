import * as React from "react";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ModalForm from "../../../components/Forms/TenantPortalSettings/ChapterForm/ModalForm";
import FullTable from "../../../components/Tables/TenantPortalSettings/FullTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import * as SharedStyles from "../../../components/sharedStyles";
import usePermissionRedirect from "../../../hooks/usePermissionRedirect";
import { useFilteredTenantPortalSettings } from "../../../store/tenantPortalSettings";
import PageTitle from "../../Layouts/PageTitle/PageTitle";

const breadCrumbs = [
  {
    label: "Mina sidor",
    url: "/configcenter/tenant-portal",
  },
];

export default function MyPages() {
  const [portalSettings, portalSettingsLoading] =
    useFilteredTenantPortalSettings();

  const [newSettingOpen, setNewSettingOpen] = React.useState(false);
  const query = {};

  const filterMethod = (obj) => obj;

  const canAddPortalSetting = usePermissionRedirect([
    "allow_tenantportal",
    "add_can_tenantportal",
  ]);

  return (
    <SharedStyles.DetailInnerWrapper>
      {!portalSettings && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle
          title="Mina sidor - Inställningar"
          breadCrumbs={breadCrumbs}
        />
      </SharedStyles.BareLayoutTitleWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Mina sidor - Inställningar</OverviewTitle>
            <OverviewSubtitle>
              Klicka på en inställning nedan för att redigera den.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
          {canAddPortalSetting && (
            <PrimaryButton
              title="Skapa ny inställning för Mina sidor"
              clicked={() => setNewSettingOpen(true)}
            />
          )}
        </OverviewTitleWrapper>
        <FullTable persistantQuery={query} isBare ignoreLocalStorage />
        <ModalForm
          method="POST"
          isOpen={newSettingOpen}
          onCheckout={setNewSettingOpen}
        />
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
