import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { NumberInput, RadioGroup, Select } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingDebtSettings";
import { useFormInstanceField } from "../../../../../store/base";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const useDebtCollection = useFormInstanceField({
    storeName,
    fieldKey: "use_debt_collection",
  });

  const debtStartMode = useFormInstanceField({
    storeName,
    fieldKey: "debt_start_mode",
  });
  const sendToDebtAfterDays = useFormInstanceField({
    storeName,
    fieldKey: "send_to_debt_after_days",
  });

  const useReminders = useFormInstanceField({
    storeName,
    fieldKey: "use_reminders",
  });
  const sendReminderAfterDays = useFormInstanceField({
    storeName,
    fieldKey: "send_reminder_after_days",
  });
  const reminderTermDays = useFormInstanceField({
    storeName,
    fieldKey: "reminder_term_days",
  });

  const minDaysForDebtCollection = useReminders
    ? (sendReminderAfterDays || 3) + (reminderTermDays || 6)
    : 10;

  return (
    <>
      <FormAreaTitle>Automatisk kravhantering</FormAreaTitle>

      <RadioGroup
        title="Använd automatisk kravhantering"
        description="Om denna inställning är aktiv kommer en kravhanteringsprocess automatiskt att påbörjas efter förfallodatum på obetald faktura"
        {...{
          storeName,
          method,
          fieldKey: "use_debt_collection",
        }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      {useDebtCollection && (
        <>
          <NumberInput
            title="Antal dagar efter förfallodatum som krav inleds"
            {...{
              storeName,
              method,
              forceError:
                minDaysForDebtCollection >= sendToDebtAfterDays
                  ? "Kravet kan inte inledas innan eventuell påminnelse har förfallit, alternativt tidigast 10 dagar efter förfallodatum"
                  : null,
              fieldKey: "send_to_debt_after_days",
            }}
          />
          <Select
            title="Börja krav på nivå"
            description={`Välj vilken nivå kravet ska inledas på. OBS: Förseningsavgift går enbart att använda på bolagskunder. Motsvarigheten för privatkunder är "Påminnelser"`}
            {...{
              storeName,
              method,
              fieldKey: "debt_start_mode",
            }}
          />
          {debtStartMode === 3 && (
            <Select
              title="Antal påminnelser att skicka innan inkassokrav"
              {...{
                storeName,
                method,
                fieldKey: "number_of_reminders",
              }}
            />
          )}

          <NumberInput
            title="Betalningsvillkor för krav i antal dagar"
            {...{
              storeName,
              method,
              fieldKey: "debt_term_days",
            }}
          />
          <RadioGroup
            title="Kravet utgör bostadsavgifter där avhysning skall ske vid betalningsföreläggande"
            {...{
              storeName,
              method,
              fieldKey: "eviction",
            }}
            forceHorizontalToolTipPlacement="left"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
          <RadioGroup
            title="Kravet utgör bostadsavgifter för privatperson där Socialstyrelsen skall informeras vid betalningsföreläggande"
            {...{
              storeName,
              method,
              fieldKey: "inform_social_welfare",
            }}
            forceHorizontalToolTipPlacement="left"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
        </>
      )}
    </>
  );
};
