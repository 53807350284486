import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import NonConnectedInputField from "../../components/Forms/Base/Old/NonConnected/NonConnectedInputField";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import { resetPassword } from "../../store/users";
import PageTitle from "../Layouts/PageTitle/PageTitle";

export default () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleResetPassword = () => {
    if (!email || !username) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Användarnamn och/eller email är ej ifyllt",
          description: "Vänligen fyll i både användarnamn och email",
        })
      );

      return;
    }

    setLoading(true);
    dispatch(
      resetPassword({
        username,
        email,
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Kontrollera uppgifterna och försök igen",
            })
          );
        },
      })
    );
  };

  const breadCrumbs = [
    {
      label: "Min profil",
      url: "/configcenter/profile/user",
    },
    {
      label: "Återställ lösenord",
    },
  ];

  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle title="Återställ lösenord" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>
      <DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Återställ lösenord</OverviewTitle>
            <OverviewSubtitle>
              När du återställer ditt lösenord skickas en länk till
              e-postadressen du registrerade dig med. Via den länken kan du
              uppdatera ditt lösenord.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <NonConnectedInputField
          title="Användarnamn"
          value={username}
          onChange={({ target: { value } }) => setUsername(value)}
          id="username"
        />
        <NonConnectedInputField
          title="E-post"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          id="email"
        />

        <div>
          <PrimaryButton
            extraStyle={{ minWidth: "200px" }}
            title="Återställ"
            disabled={!email || !username}
            clicked={handleResetPassword}
            newDesign
          />
        </div>
      </DetailPageBox>
    </BareLayoutWrapper>
  );
};
