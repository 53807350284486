export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ANONYMIZATION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ANONYMIZATION",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ANONYMIZATION",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ANONYMIZATION",
  DESTROY_FORM: "DESTROY_FORM_ANONYMIZATION",
  SET_FORM_ERROR: "SET_FORM_ERROR_ANONYMIZATION",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SENSOR",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SENSOR",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SENSOR",
  RESET_STATE: "RESET_STATE_SENSOR",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SENSOR",
  REMOVE_OBJECT: "REMOVE_OBJECT_SENSOR",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_SENSOR",
  CLEAR_FETCHED: "CLEAR_FETCHED_ANONYMIZATION",

  LIST_URL: "/accounts/gdpr/anonymization/list/",
  POST_URL: "/accounts/gdpr/anonymization/",
  PATCH_URL: "/accounts/gdpr/anonymization/",
  GET_URL: "/accounts/gdpr/anonymization/",

  STORE_NAME: "anonymization",
};
