import clearTableFilters from "../../../utils/clearTableFilters";
import { get } from "../../base";
import constants from "./constants";

export const getSummarizedStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.SUMMARIZED_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.SUMMARIZED_STATISTICS_URL,
    constants,
    name: constants.SUMMARIZED_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getCostStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.COST_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.COST_STATISTICS_URL,
    constants,
    name: constants.COST_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getHeavyCostStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.HEAVY_COST_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.HEAVY_COST_STATISTICS_URL,
    constants,
    name: constants.HEAVY_COST_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoContractStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_CONTRACT_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_CONTRACT_STATISTICS_URL,
    constants,
    name: constants.TODO_CONTRACT_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoYourBlockStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.YOUR_BLOCK_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.YOUR_BLOCK_STATISTICS_URL,
    constants,
    name: constants.YOUR_BLOCK_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoPremisesStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_PREMISES_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_PREMISES_STATISTICS_URL,
    constants,
    name: constants.TODO_PREMISES_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoTenantStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_TENANT_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_TENANT_STATISTICS_URL,
    constants,
    name: constants.TODO_TENANT_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoUserStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_USER_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_USER_STATISTICS_URL,
    constants,
    name: constants.TODO_USER_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoImdStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_IMD_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_IMD_STATISTICS_URL,
    constants,
    name: constants.TODO_IMD_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoBillingStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_BILLING_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_BILLING_STATISTICS_URL,
    constants,
    name: constants.TODO_BILLING_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoBillingInvoiceStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_BILLING_INVOICE_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_BILLING_INVOICE_STATISTICS_URL,
    constants,
    name: constants.TODO_BILLING_INVOICE_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getTodoErrandStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.TODO_ERRAND_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.TODO_ERRAND_STATISTICS_URL,
    constants,
    name: constants.TODO_ERRAND_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getReportErrandStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.REPORT_ERRAND_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.REPORT_ERRAND_STATISTICS_URL,
    constants,
    name: constants.REPORT_ERRAND_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getInspectionErrandStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.INSPECTION_ERRAND_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.INSPECTION_ERRAND_STATISTICS_URL,
    constants,
    name: constants.INSPECTION_ERRAND_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const getRoundingErrandStatistics = () => {
  const preProcess = (data) => {
    return { id: constants.ROUNDING_ERRAND_STATISTICS_ID, data: data };
  };
  return get({
    url: constants.ROUNDING_ERRAND_STATISTICS_URL,
    constants,
    name: constants.ROUNDING_ERRAND_STATISTICS_PROCESS_NAME,
    preProcess,
  });
};

export const clearTodoOverviewData = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_TODO_DATA,
    });
  };
};

export const setActiveFilteredRealEstates = ({
  realEstateIds = [],
  companyIds = [],
  portfolioIds = [],
  brfCompanyIds = [],
}) => {
  return async (dispatch) => {
    clearTableFilters();
    dispatch({
      type: constants.SET_ACTIVE_FILTERED,
      payload: {
        realEstateIds,
        companyIds,
        brfCompanyIds,
        portfolioIds,
      },
    });
  };
};
