import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { buildQueryString } from "../../../store/base";
import { REPORT_EVENTS_CATEGORIES } from "../../../store/billectaEvents/hooks/retrieve";
import { Pill, PillsWrapper } from "../../sharedStyles";
import Notifications from "../Events/Notifications";

const notificationsCategories = [
  REPORT_EVENTS_CATEGORIES.INVOICE_UNDELIVERED,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_APPROVAL_FAIL,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_CHANGED,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL_RETRY,
  REPORT_EVENTS_CATEGORIES.INVOICE_UNMATCHED_PAYMENT,
  REPORT_EVENTS_CATEGORIES.INVOICE_OVERPAYED,
];

const debtCollectionCategories = [
  REPORT_EVENTS_CATEGORIES.INVOICE_DEBT_COLLECT,
  REPORT_EVENTS_CATEGORIES.INVOICE_REMINDER_SENT,
];

const otherCategories = [
  REPORT_EVENTS_CATEGORIES.INVOICE_CREATED,
  REPORT_EVENTS_CATEGORIES.INVOICE_SENT,
  REPORT_EVENTS_CATEGORIES.INVOICE_FULLY_PAYED,
];

const CATEGORIES = {
  IMPORTANT: 0,
  DEBT: 1,
  OTHER: 2,
};

export default function BillectaNotifications({ showRead }) {
  const { creditorId } = useParams();
  const invoices = useSelector((state) => state.billectaInvoicing.invoices);
  const [selectedCategory, setSelectedCategory] = React.useState(
    CATEGORIES.IMPORTANT
  );

  const billectaNotificationsQuery = buildQueryString({
    related_creditor_id__in: [creditorId],
    category__in: notificationsCategories,
    order_by: "-added_time",
    read: showRead ? undefined : false,
  });

  const billectaNotificationsFilter = (obj) => {
    return (
      obj.related_creditor_id === creditorId &&
      notificationsCategories.includes(obj.category) &&
      ((!showRead && obj.read) || showRead)
    );
  };

  const billectaDebtNotificationsQuery = buildQueryString({
    related_creditor_id__in: [creditorId],
    category__in: debtCollectionCategories,
    order_by: "-added_time",
    read: showRead ? undefined : false,
  });

  const billectaDebtNotificationsFilter = (obj) => {
    return (
      obj.related_creditor_id === creditorId &&
      debtCollectionCategories.includes(obj.category) &&
      ((!showRead && obj.read) || showRead)
    );
  };

  const billectaOtherNotificationsQuery = buildQueryString({
    related_creditor_id__in: [creditorId],
    category__in: otherCategories,
    order_by: "-added_time",
    read: showRead ? undefined : false,
  });

  const billectaOtherNotificationsFilter = (obj) => {
    return (
      obj.related_creditor_id === creditorId &&
      otherCategories.includes(obj.category) &&
      ((!showRead && obj.read) || showRead)
    );
  };

  return (
    <>
      <PillsWrapper>
        <Pill
          active={selectedCategory === CATEGORIES.IMPORTANT}
          onClick={() => setSelectedCategory(CATEGORIES.IMPORTANT)}
        >
          Viktiga notiser
        </Pill>
        <Pill
          active={selectedCategory === CATEGORIES.DEBT}
          onClick={() => setSelectedCategory(CATEGORIES.DEBT)}
        >
          Kravhantering
        </Pill>
        <Pill
          active={selectedCategory === CATEGORIES.OTHER}
          onClick={() => setSelectedCategory(CATEGORIES.OTHER)}
        >
          Övriga händelser
        </Pill>
      </PillsWrapper>

      {selectedCategory === CATEGORIES.IMPORTANT && (
        <Notifications
          hideColumns
          hideExport
          hideFilters
          invoices={invoices}
          persistantFilterMethod={billectaNotificationsFilter}
          persistantQueryString={billectaNotificationsQuery}
          hideSearch
        />
      )}

      {selectedCategory === CATEGORIES.DEBT && (
        <Notifications
          hideColumns
          hideExport
          hideFilters
          invoices={invoices}
          persistantFilterMethod={billectaDebtNotificationsFilter}
          persistantQueryString={billectaDebtNotificationsQuery}
          hideSearch
        />
      )}

      {selectedCategory === CATEGORIES.OTHER && (
        <Notifications
          hideColumns
          hideExport
          hideFilters
          invoices={invoices}
          persistantFilterMethod={billectaOtherNotificationsFilter}
          persistantQueryString={billectaOtherNotificationsQuery}
          hideSearch
        />
      )}
    </>
  );
}
