import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import {
  TimePicker,
  TextField,
  AsyncSelect,
  AddressField,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/calendarEvents";
import { useFilteredCalendars } from "../../../../../store/calendar";

import { TextInput } from "../../../Base/Fields";
import { buildQueryString } from "../../../../../store/base";
import { useSelector } from "react-redux";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";
export default ({ method, calendars }) => {
  const storeName = constants.STORE_NAME;

  const userId = useSelector((state) => state.app.user?.id);

  return (
    <>
      <FormAreaTitle>Händelse</FormAreaTitle>
      <FormAreaDescription>Ange information om händelsen</FormAreaDescription>
      <AsyncSelect
        storeName={storeName}
        method={method}
        fieldKey={"calendar"}
        fetchMethod={() =>
          useFilteredCalendars(
            buildQueryString({
              outlook_calendar_id__isnull: true,
              owner: userId,
            })
          )
        }
        title={"Kalender"}
      />

      <TextInput
        storeName={storeName}
        title={"Titel på händelse"}
        method={method}
        fieldKey={"title"}
      />

      <TextField
        storeName={storeName}
        title={"Beskrivning"}
        method={method}
        fieldKey={"description"}
      />
      <DoubleFieldWrapper>
        <TimePicker
          storeName={storeName}
          title={"Start"}
          method={method}
          fieldKey={"start"}
          extraStyle={{ marginRight: 12 }}
        />
        <TimePicker
          storeName={storeName}
          title={"Slut"}
          method={method}
          fieldKey={"end"}
        />
      </DoubleFieldWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="participants"
          TableComponent={UsersTable}
          persistantQuery={{
            user_type__in: [0, 1, 2],
            "user_id!": userId,
          }}
          title="Deltagare"
          placeholder="Välj deltagare..."
          description="Inbjudna deltagare kommer att se denna händelse i sina kalendrar och få notiser om händelsen."
          isMany
        />
      </div>

      <AddressField
        storeName={storeName}
        method={method}
        fieldKey={"place"}
        title={"Plats"}
      />
    </>
  );
};
