import styled, { css } from "styled-components";
import { animated } from "@react-spring/web";
import closeIcon from "../../../../assets/svg/close-circle.svg";

export const BackDrop = styled(animated.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(p) => p.theme.zIndex.modalBackdrop};

  ${(p) =>
    p.nestedModal &&
    css`
      height: 100%;
      width: 100%;
    `}
`;

export const Modal = styled(animated.div)`
  height: 70vh;
  min-height: 600px;
  width: 90%;
  max-width: 1000px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  z-index: ${(p) => p.theme.zIndex.modal};

  ${(p) =>
    p.nestedModal &&
    css`
      height: 100%;
      width: 100%;
    `}
`;

export const ModalActionBar = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  border-top: 1px solid ${(p) => p.theme.colors.fadedBorder};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ModalContent = styled.div`
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  padding: 20px 20px 240px 20px;
  overflow-y: auto;
  position: relative;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.fadedBorder};
  color: ${(p) => p.theme.colors.blueDark};
  font-weight: 600;
`;

export const ModalLayout = styled.div`
  width: 100%;
  ${"" /* 100% of modal height - header and actionbar */}
  height: calc(100% - 120px);
  display: flex;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  height: 32px;
  width: 32px;
  position: absolute;
  top: calc(50% - 16px);
  right: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeIcon});
`;
