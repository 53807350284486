import * as React from "react";
import SelectField from "../Base/Fields/SelectField";
import TextInputField from "../Base/Fields/TextInputField";
import { BLOCKED_PRODUCT_CATEGORIES } from "./ChapterForm/Chapters/Product";

export default function ProductNestedFields({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Titel"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.title`,
            instructionsKey: `${parentInstructionsPath}.title`,
          }}
        />

        <SelectField
          title="Kategori"
          filterChoices={(option) => {
            return !BLOCKED_PRODUCT_CATEGORIES.includes(option.v);
          }}
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.category`,
            instructionsKey: `${parentInstructionsPath}.category`,
          }}
        />

        <SelectField
          title="Standard momssats"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.vat`,
            instructionsKey: `${parentInstructionsPath}.vat`,
          }}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Intäktskonto"
          description="Konto 3000 är standard. Om du vill ha ett specifikt konto för vissa produkter kan du välja det här."
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.account_no`,
            instructionsKey: `${parentInstructionsPath}.account_no`,
          }}
        />
        <TextInputField
          title="Utgående moms"
          description="Lämna blankt om du vill använda momskontot som angetts i bokföringsinställningar."
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.account_no_vat`,
            instructionsKey: `${parentInstructionsPath}.account_no_vat`,
          }}
        />
        <TextInputField
          title="Konto försäljning EU"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.account_no_eu`,
            instructionsKey: `${parentInstructionsPath}.account_no_eu`,
          }}
        />
        <TextInputField
          title="Konto försäljning EU Moms"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.account_no_eu_vat`,
            instructionsKey: `${parentInstructionsPath}.account_no_eu_vat`,
          }}
        />
      </div>
    </>
  );
}
