import * as React from "react";

import { constants } from "../../../../../store/keypermission";

import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import useActiveFilters from "../../../../../hooks/useActiveFilters";

import { useDispatch } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";

export default ({ method }) => {
  const dispatch = useDispatch();

  const { filteredRealEstates } = useActiveFilters();

  const storeName = constants.STORE_NAME;

  const parakeyId = useFormInstanceField({ storeName, fieldKey: "parakey_id" });

  React.useEffect(() => {
    if (parakeyId != null) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { apartments: [], industrial_premises: [], common_areas: [] },
        })
      );
    }
  }, [parakeyId]);

  return (
    <>
      {parakeyId == null ? (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              fieldKey={"apartments"}
              method={method}
              persistantQuery={{
                realestate_ids: filteredRealEstates,
              }}
              title={"Lägenheter"}
              placeholder={"Välj lägenheter..."}
              isMany
              TableComponent={ApartmentTable}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              fieldKey={"industrial_premises"}
              method={method}
              persistantQuery={{
                realestate_ids: filteredRealEstates,
              }}
              title={"Lokaler"}
              placeholder={"Välj lokaler..."}
              isMany
              TableComponent={IndustrialPremisesTable}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              fieldKey={"common_areas"}
              method={method}
              persistantQuery={{
                realestate_ids: filteredRealEstates,
              }}
              title={"Gemensamma ytor"}
              placeholder={"Välj gemensamma ytor..."}
              isMany
              TableComponent={CommonAreaTable}
            />
          </div>
        </>
      ) : (
        <div>Objekt kan ej väljas för en Parakey-styrd behörighet</div>
      )}
    </>
  );
};
