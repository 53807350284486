import * as React from "react";

import { Confirm, IMD } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "IMD":
      return <IMD {...{ method }} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
