import { cloneDeep } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import { store } from "../../store";
import constants from "./constants";
import * as services from "./services";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const setLeadsForContract = ({ leads }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CONTRACT_LEADS,
      payload: {
        leads,
      },
    });
  };
};

export const removeLeadForContract = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_CONTRACT_LEAD,
      payload: { id },
    });
  };
};

export const removeContractProposal = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_CONTRACT_PROPOSAL,
      payload: { id },
    });
  };
};

export const updateContractProposal = ({ id, newProposal }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.UPDATE_CONTRACT_PROPOSAL,
      payload: {
        id,
        newProposal,
      },
    });
  };
};

export const removeCostProposal = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_COST_PROPOSAL,
      payload: { id },
    });
  };
};

export const updateCostProposal = ({ id, newProposal }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.UPDATE_COST_PROPOSAL,
      payload: {
        id,
        newProposal,
      },
    });
  };
};

export const getLeadContractWarning = ({ successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const leads = state[constants.STORE_NAME].contractLeads;

      if (!leads?.length) return;

      const ids = leads.map((l) => l.id);

      const { data } = await services.getLeadContractWarnings(ids);

      successCallback();

      dispatch({
        type: constants.SET_CONTRACT_LEADS_WARNINGS,
        payload: {
          warnings: data,
        },
      });
    } catch (e) {
      errorCallback();
    }
  };
};

export const getLeadContractProposals = ({
  successCallback,
  errorCallback,
  type,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const leads = state[constants.STORE_NAME].contractLeads;

      if (!leads?.length) return;

      const postData = {
        ids: leads?.map((l) => l.id),
      };

      const { data } = await services.getLeadContractProposals(postData);

      dispatch({
        type: constants.SET_CONTRACT_PROPOSALS,
        payload: {
          proposals:
            type === "leasecontracts"
              ? Object.keys(data.leasecontract_proposals || {}).map(
                  (proposalKey) => ({
                    ...data.leasecontract_proposals[proposalKey],
                    _reduxId: proposalKey,
                  })
                )
              : Object.keys(data.parkingcontract_proposals || {}).map(
                  (proposalKey) => ({
                    ...data.parkingcontract_proposals[proposalKey],
                    _reduxId: proposalKey,
                  })
                ),
        },
      });

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

export const getLeadCostProposals = ({
  successCallback,
  errorCallback,
  type,
  createdContracts,
}) => {
  return async (dispatch) => {
    try {
      const ids = createdContracts?.map((d) => d.id);
      if (!ids?.length) return;

      // save the contracts for data and for retries
      dispatch({
        type: constants.SET_CREATED_CONTRACTS,
        payload: {
          createdContracts,
        },
      });

      const postData = {
        ids,
      };

      const { data } = await services.getLeadCostsProposals(postData, type);

      dispatch({
        type: constants.SET_COST_PROPOSALS,
        payload: {
          proposals: Object.keys(data || {}).map((proposalKey) => ({
            ...data[proposalKey],
            _reduxId: proposalKey,
          })),
        },
      });

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

export const setSigningType = ({ type }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CONTRACT_SIGNING_TYPE,
      payload: {
        type,
      },
    });
  };
};

export const skipDocument = () => {
  return async (dispatch) => {
    const state = store.getState();
    const index = state[constants.STORE_NAME].docIndex;
    const contracts = state[constants.STORE_NAME].createdContracts;

    const skippedContract = contracts?.[index];

    if (!skippedContract) return;

    dispatch({
      type: constants.SET_DOCUMENT_DENIED,
      payload: {
        contract: skippedContract,
      },
    });
  };
};

export const setDocumentApprovalIndex = ({ index }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_DOCUMENT_APPROVAL_INDEX,
      payload: {
        index,
      },
    });
  };
};

export const setDocumentDenied = ({ contract }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_DOCUMENT_DENIED,
      payload: {
        contract,
      },
    });
  };
};

export const clearContractCreationData = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_CONTRACT_CREATION_DATA,
    });
  };
};

export const insertIntoCreatedContracts = ({ contract }) => {
  return async (dispatch) => {
    const state = store.getState();
    const newCreatedContracts =
      cloneDeep(state[constants.STORE_NAME].createdContracts) || [];

    newCreatedContracts.push(contract);

    dispatch({
      type: constants.SET_CREATED_CONTRACTS,
      payload: {
        createdContracts: newCreatedContracts,
      },
    });
  };
};

export const removeFromCreatedContracts = ({ contract }) => {
  return async (dispatch) => {
    const state = store.getState();
    const newCreatedContracts = (
      state[constants.STORE_NAME].createdContracts || []
    ).filter((c) => c.id !== contract.id);

    dispatch({
      type: constants.SET_CREATED_CONTRACTS,
      payload: {
        createdContracts: newCreatedContracts || [],
      },
    });
  };
};
