export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_COMPONENT_RATING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_COMPONENT_RATING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_COMPONENT_RATING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_COMPONENT_RATING",
  DESTROY_FORM: "DESTROY_FORM_COMPONENT_RATING",
  SET_FORM_ERROR: "SET_FORM_ERROR_COMPONENT_RATING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_COMPONENT_RATING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_COMPONENT_RATING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_COMPONENT_RATING",
  RESET_STATE: "RESET_STATE_COMPONENT_RATING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_COMPONENT_RATING",
  REMOVE_OBJECT: "REMOVE_OBJECT_COMPONENT_RATING",
  CLEAR_FETCHED: "CLEAR_FETCHED_COMPONENT_RATING",

  LIST_URL: "/errands/components/componentrating/list/",
  GET_URL: "/errands/components/componentrating/",

  STORE_NAME: "componentRatings",
};
