import Confirm from "./Confirm";

import Key from "./Key";
import Permission from "./Permissions";


export default {
  KEY: Key,
  PERMISSIONS: Permission,
  CONFIRM: Confirm,
};
