import {
  ChevronLeftIcon,
  FolderPlusIcon,
  IdentificationIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Alert, { ALERT_TYPES } from "../../Alert/Alert";
import {
  FIELD_TYPES,
  getInitialPlacement,
  getPageFromBoundsAndPosition,
} from "../utils";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import DigitalDocHandleAddField from "./HandleAddField";
import DigitalDocFieldSuggestions from "./FieldSuggestions";

export default function DigitalDocPartyFields({
  onDone,
  partyIdx,
  isTemplateEditor,
  isFastDoc,
  partyName,
  storeName,
  pageBounds,
}) {
  const [addNewFieldOpen, setAddNewFieldOpen] = React.useState(false);
  const [showProposedFields, setShowProposedFields] = React.useState(false);
  const dispatch = useDispatch();
  const parties = useFormInstanceField({
    storeName,
    fieldKey: "parties",
  });
  const currentParty = parties?.[partyIdx];

  const onFieldAdded = (field) => {
    const scrollTop = window.scrollY;
    const { page: currentPage, start: currentPageStart } =
      getPageFromBoundsAndPosition({
        pageBounds,
        y: scrollTop,
      });

    const extraTopOffset = scrollTop - currentPageStart;

    const currentFields = cloneDeep(currentParty.fields);

    const matchingFieldIdx = currentFields.findIndex(
      (f) => f.name === field.name
    );

    if (matchingFieldIdx > -1 && currentFields?.[matchingFieldIdx]) {
      if (!currentFields[matchingFieldIdx].placements?.length) {
        currentFields[matchingFieldIdx].placements = [];
      }

      currentFields[matchingFieldIdx].placements.push({
        value:
          currentFields[matchingFieldIdx].kind === FIELD_TYPES.RADIO
            ? currentFields[matchingFieldIdx].placements?.length + 1
            : undefined, // set value for this radio to its length+1
        ...getInitialPlacement({
          fontSize: 16,
          extraTopOffset,
          isCheckbox:
            currentFields[matchingFieldIdx].kind === FIELD_TYPES.CHECKBOX ||
            currentFields[matchingFieldIdx].kind === FIELD_TYPES.RADIO, // is checkbox or radio,
          pageBounds: pageBounds,
          page: currentPage,
        }),
      });
    } else {
      const placement = getInitialPlacement({
        fontSize: 16,
        page: currentPage,
        extraTopOffset,
        isCheckbox:
          field.kind === FIELD_TYPES.CHECKBOX ||
          field.kind === FIELD_TYPES.RADIO,
        pageBounds: pageBounds,
      });

      if (field.kind === FIELD_TYPES.RADIO) {
        placement.value = 1;
      }

      const newField = {
        ...field,
        placements: [placement],
      };

      currentFields.push(newField);
    }

    const key = `parties[${partyIdx}].fields`;
    dispatch(
      updateActiveFormInstance({ storeName, data: { [key]: currentFields } })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: `Fältet "${field.name}" lades till på dokumentet`,
        description: "Dra i fältet för att ändra positionen",
      })
    );
  };

  const totalPages = Object.keys(pageBounds).length;

  const partySignField = currentParty?.fields.find(
    (f) => f.kind === FIELD_TYPES.SIGNATURE
  );

  const canAddSignField =
    totalPages !== (partySignField ? partySignField.placements.length : 0);

  // don't allow more than one signature field
  const partyFields = currentParty?.fields
    ?.filter((f) => f.kind !== "signature")
    ?.map((field) => {
      return (
        <div key={field.name} className="mr-2 mb-2">
          <PrimaryBtn secondary onClick={() => onFieldAdded(field)}>
            {field.name} <PlusIcon width={16} className="ml-1" />
          </PrimaryBtn>
        </div>
      );
    });

  if (showProposedFields) {
    return (
      <DigitalDocFieldSuggestions
        onDone={() => setShowProposedFields(false)}
        onFieldAdded={onFieldAdded}
        partyName={partyName}
        partyIdx={partyIdx}
        storeName={storeName}
      />
    );
  }

  return (
    <>
      {addNewFieldOpen && (
        <DigitalDocHandleAddField
          {...{
            storeName,
            isTemplateEditor,
            closeFunction: () => setAddNewFieldOpen(false),
            partyIdx,
            partyName,
            onFieldAdded,
          }}
        />
      )}

      <div className="grid grid-cols-1 gap-2">
        <PrimaryBtn secondary onClick={onDone} className="mb-2">
          <ChevronLeftIcon width={16} className="mr-1" /> Tillbaka till parter
        </PrimaryBtn>

        <Alert title="Lägg till fält på dokument" type={ALERT_TYPES.INFO}>
          Hanterar fält för {partyName}. Tryck på ett fält för att lägga till
          det på dokumentet.
        </Alert>
      </div>
      {canAddSignField && (
        <div className="mr-2 mb-2">
          <PrimaryBtn
            onClick={() =>
              onFieldAdded({
                partyIndex: partyIdx,
                kind: "signature",
                name: "Signatur",
                is_obligatory: true,
              })
            }
          >
            Signaturfält (max 1 per part per sida){" "}
            <IdentificationIcon width={16} className="ml-1" />
          </PrimaryBtn>
        </div>
      )}
      {!isFastDoc && (
        <>
          <div className="flex flex-wrap">{partyFields}</div>

          <div className="mr-2 mb-4">
            <PrimaryBtn onClick={() => setAddNewFieldOpen(true)}>
              Lägg till nytt fält <FolderPlusIcon width={16} className="ml-1" />
            </PrimaryBtn>
          </div>
        </>
      )}

      {isTemplateEditor && !isFastDoc && (
        <Alert
          title="Föreslagna autofyllnadsfält"
          type={ALERT_TYPES.SUCCESS}
          primaryTitle="Gå till föreslagna fält"
          primaryAction={() => setShowProposedFields(true)}
        >
          Dessa fält kan läggas till på mallar för att sedan autofyllas när
          mallen används på ett avtal. Exempel på fält är avtalsnr., bashyra,
          uthyrd area osv.
        </Alert>
      )}
    </>
  );
}
