import * as React from "react";
import { constants } from "../../../../../store/errandRoles";
import { TextInput } from "../../../Base/Fields";

import {
  OverviewTitle,
  OverviewSubtitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default function Role({ method }) {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Roll</OverviewTitle>
          <OverviewSubtitle>
            Användare kopplat till fastigheter för rollen ställs in på
            redigeringssidan
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey={"title"}
        title={"Titel på roll"}
      />
    </>
  );
}
