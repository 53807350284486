import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveFormInstance } from "../../../store/base";
import {
  useRealEstatePortfolio,
  create,
  update,
  constants,
  destroyPatchForm,
  destroyPostForm,
  useRealEstatePortfolioForm,
} from "../../../store/realEstatePortfolios";

import {
  OverviewTitleWrapper,
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
} from "../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { TextInput } from "../Base/Fields";
import { TextButton } from "../Base/Buttons";
import { isEqual } from "lodash";
import DeleteModal from "../Delete/DeleteModal";
import { useHistory } from "react-router-dom";
import TableSelectField from "../Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default function RealEstatePortfolioForm({
  method,
  portfolioId,
  open,
  onCheckout,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;

  const [realestatePortfolio, realestatePortfolioLoading] =
    useRealEstatePortfolio(portfolioId);

  const formLoaded = Boolean(useRealEstatePortfolioForm(method, portfolioId));

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const hasChanged = useSelector((state) => {
    return !isEqual(realestatePortfolio, state[storeName].formInstance);
  });

  React.useEffect(() => {
    if (!realestatePortfolio) return;

    dispatch(
      setActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: realestatePortfolio || {},
      })
    );
  }, [realestatePortfolio]);

  const onSuccess = (_, returnedObject) => {
    setLoading(false);
    checkout(true);
    push(`/portfolios/${returnedObject?.id}`);
  };

  const onSubmit = () => {
    if (method === "POST") {
      dispatch(
        create({
          id: realestatePortfolio?.id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id: realestatePortfolio?.id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    onCheckout();
  };

  const handleDeletedPortfolio = () => {
    checkout(true);
  };

  if (!formLoaded) {
    return <OverlaySpinner />;
  }

  return (
    <StandardModal
      isOpen={open}
      closeFunction={checkout}
      saveFunction={onSubmit}
      withActionBar={true}
      title={method === "PATCH" ? "Uppdatera Portfölj" : "Skapa Portfölj"}
      canAccept={hasChanged}
    >
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => handleDeletedPortfolio()}
        constants={constants}
        instance={realestatePortfolio}
        modalInModal
      />
      {loading && open && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>
            {method === "PATCH" ? "Uppdatera" : "Skapa"} portfölj
          </OverviewTitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        rowSize
        storeName={constants.STORE_NAME}
        method={method}
        fieldKey={"title"}
        title="Titel"
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          TableComponent={RealEstateTable}
          placeholder="Välj fastighet..."
          title="Fastigheter"
          method={method}
          fieldKey="realestates"
          isMany
        />
      </div>
      {method === "PATCH" && (
        <OverviewSubtitle>
          <TextButton
            title="Radera"
            red={true}
            iconType="delete"
            iconPlacement="right"
            clicked={() => setDeleteModalOpen(true)}
          />
        </OverviewSubtitle>
      )}
    </StandardModal>
  );
}
