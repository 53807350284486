import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  destroyPatchForm,
  constants,
  update,
  useOtherContractForm,
} from "../../../../store/otherContracts";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";

export default function UpdateOtherContractModalForm({
  id,
  onCheckout,
  isOpen,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useOtherContractForm(method, id));

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: {
            ...instance,
            renew_indefinetely:
              instance.renew_indefinetely === false &&
              instance.renew_interval == null
                ? "no_renewal"
                : instance.renew_indefinetely,
          },
        })
      );
    }
  }, [isOpen]);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!dataClone.main_contract) {
      dataClone.main_contract = null;
    }

    if (dataClone.renew_indefinetely === "no_renewal") {
      dataClone.renew_indefinetely = false;
      dataClone.renew_interval = null;
    }

    delete dataClone.editabledoc;

    if (dataClone.object_id) dataClone.object_id = dataClone.object_id.id;

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        preProcess,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={storeName}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter({ key, method })}
      submitCallback={onSubmit}
      title={"Uppdatera avtal"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
