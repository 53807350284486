import * as React from "react";
import { PrimaryButton, SecondaryButton } from "../../../Base/Buttons";

export default ({ onSubmit, onSaveDraft }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200,
      }}
    >
      <div>
        <PrimaryButton
          title="Spara och gå vidare till debiteringsrader"
          clicked={onSubmit}
        />
      </div>

      {onSaveDraft && (
        <>
          {" "}
          <div style={{ margin: "20px auto", fontWeight: 500, fontSize: 14 }}>
            eller
          </div>
          <div>
            <SecondaryButton title="Spara som utkast" clicked={onSaveDraft} />
          </div>{" "}
        </>
      )}
    </div>
  );
};
