import * as React from "react";

import {
  Confirm,
  Premises,
  Type,
  PerformerSettings,
  Planning,
} from "./Chapters";

export default ({ key, method, status }) => {
  switch (key) {
    case "TYPE":
      return <Type {...{ method, status }} />;

    case "PREMISES":
      return <Premises {...{ method, status }} />;

    case "PERFORMERS":
      return <PerformerSettings {...{ method }} />;

    case "PLANNING":
      return <Planning {...{ method }} />;
    default:
      return <Confirm />;
  }
};
