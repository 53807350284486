export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INSIGHTSGOAL",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INSIGHTSGOAL",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INSIGHTSGOAL",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INSIGHTSGOAL",
  DESTROY_FORM: "DESTROY_FORM_INSIGHTSGOAL",
  SET_FORM_ERROR: "SET_FORM_ERROR_INSIGHTSGOAL",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INSIGHTSGOAL",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INSIGHTSGOAL",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INSIGHTSGOAL",
  RESET_STATE: "RESET_STATE_INSIGHTSGOAL",
  CLEAR_FETCHED: "CLEAR_FETCHED_INSIGHTSGOALS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INSIGHTSGOAL",
  REMOVE_OBJECT: "REMOVE_OBJECT_INSIGHTSGOAL",

  LIST_URL: "/insights/insightsgoal/list/",
  POST_URL: "/insights/insightsgoal/",
  PATCH_URL: "/insights/insightsgoal/",
  GET_URL: "/insights/insightsgoal/",

  STORE_NAME: "insightsGoal",
};
