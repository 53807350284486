import * as React from "react";
import { useHistory, useParams } from "react-router";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  UserTypeChoice,
  UserTypeWrapper,
} from "../../../components/Forms/User/styles";
import { BareLayoutWrapper, InnerBox } from "../../../components/sharedStyles";
import { SecondaryButton } from "../../../components/Forms/Base/Buttons";
import StandardModal from "../../../components/Modals/StandardModal";
import { InfoBox } from "../../../components/Displays";
import { useUser } from "../../../store/users";

export default () => {
  const { push } = useHistory();
  const { userId } = useParams();
  const [user] = useUser(userId);

  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);

  const goToExistingGroup = () => {
    push(
      `/configcenter/groups-and-users/user-setup/set-user-permissions/existing-group/${userId}`
    );
  };

  const goToCreateNewGroup = () => {
    push(
      `/configcenter/groups-and-users/user-setup/set-user-permissions/create-group/${userId}`
    );
  };

  return (
    <BareLayoutWrapper fillWidth style={{ paddingBottom: 120 }}>
      <StepDisplay
        steps={[
          "1. Skapa användare",
          "2. Ställ in behörigheter",
          "3. Bjud in användare",
        ]}
        activeIndex={1}
      />
      <StandardModal
        isOpen={cancelModalOpen}
        closeFunction={() => setCancelModalOpen(false)}
        saveFunction={() => push(`/configcenter/groups-and-users/user/${userId}`)}
        withActionBar
        title="Avbryt skapandet av användare"
        actionBarCancelTitle="Stäng"
        actionBarAcceptTitle="Avbryt ändå"
      >
        <OverviewTitle>Är du säker på att du vill avbryta?</OverviewTitle>
        <InfoBox
          text={`Var uppmärksam att användaren ${user?.str_representation} redan är skapad, men inte
          inbjuden. Avbryter du i detta steg så kommer användarkontot finnas under "Användare och Grupper", men inte vara tilldelad någon grupp.`}
        />
        <div style={{ marginTop: "24px" }}>
          Klicka på <strong>"Avbryt ändå"</strong> för att avbryta och gå direkt
          till den nyskapade användarens detaljsida.
        </div>
      </StandardModal>

      <InnerBox
        style={{
          maxWidth: 800,
          padding: 48,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Välj grupp för behörigheter</OverviewTitle>
            <OverviewSubtitle>
              Gruppen avgör vilka delar av systemet användaren har tillgång
              till.{" "}
              <strong>
                Pigello sätter automatiskt upp grupper för ett antal vanliga
                användningsområden som finns under "Existerande grupp"
              </strong>
              .
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <UserTypeWrapper>
          <UserTypeChoice onClick={goToExistingGroup}>
            Existerande grupp
          </UserTypeChoice>
          <UserTypeChoice onClick={goToCreateNewGroup}>Ny grupp</UserTypeChoice>
        </UserTypeWrapper>
        <div>
          <SecondaryButton
            title="Avbryt"
            clicked={() => setCancelModalOpen(true)}
            newDesign
          />
        </div>
      </InnerBox>
    </BareLayoutWrapper>
  );
};
