import styled, { css } from "styled-components";
import { animated } from "@react-spring/web";

export const BackDrop = styled(animated.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(p) => p.theme.zIndex.modalBackdrop};
`;

export const Modal = styled(animated.div)`
  min-height: 300px;
  width: 50%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  z-index: ${(p) => p.theme.zIndex.modal};
  font-family: "Inter", sans-serif;
`;

export const ModalContent = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.div`
  color: ${(p) => p.theme.colors.textPrimary};
  font-weight: 600;
  max-width: 80%;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 24px;
`;

export const ModalDescription = styled.div`
  font-weight: 500;
  max-width: 80%;
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 24px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Option = styled.div`
  cursor: pointer;
  height: 130px;
  width: 130px;
  margin: 14px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: ${(p) => p.theme.boxShadows.small};
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
`;

export const OptionIcon = styled.div`
  height: 70px;
  width: 70px;
  margin-bottom: 6px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.imgSrc});
`;
export const OptionLabel = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  color: ${(p) => p.theme.colors.textPrimary};
  text-align: center;
`;

export const OptionLabelDescription = styled.div`
  font-weight: 500;
  font-size: 0.7rem;
  color: ${(p) => p.theme.colors.textPrimary};
  text-align: center;
`;
