export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTABOOKKEEPING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTABOOKKEEPING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTABOOKKEEPING",
  DESTROY_FORM: "DESTROY_FORM_BILLECTABOOKKEEPING",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTABOOKKEEPING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTABOOKKEEPING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTABOOKKEEPING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTABOOKKEEPING",
  RESET_STATE: "RESET_STATE_BILLECTABOOKKEEPING",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTABOOKKEEPING",
  SET_PERIOD_LOCK_PERIOD: "SET_PERIOD_LOCK_PERIOD_BILLECTABOOKKEEPING",

  PERIOD_LOCKING_URL: "/accounting/gateways/period_locking/",

  STORE_NAME: "billectaBookKeeping",
};
