import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import {
  constants,
  useUserGroupForm,
  useFilteredUserGroups,
} from "../../../store/userGroups";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import NonConnectedSelect from "../Base/Old/NonConnected/NonConnectedSelect";
import defaultGroups from "./Permissions/defaultGroups";
import RenderGroup from "./Permissions/RenderGroup";

export default () => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  // const groups = defaultGroups;
  const [groups, groupsLoading] = useFilteredUserGroups("");

  const selectedGroup = useFormInstanceField({
    storeName,
    fieldKey: "id",
  });
  const wholeSelectedGroup = groups?.find((g) => g.id === selectedGroup);

  useUserGroupForm("PATCH");

  const handlePickedGroup = (groupId) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          id: groupId,
        },
      })
    );
  };

  if (groupsLoading) {
    return <OverlaySpinner />;
  }

  return (
    <>
      {groups?.length && (
        <NonConnectedSelect
          choices={groups}
          value={selectedGroup || null}
          id="groups"
          placeholder="Välj grupp för att se behörigheter..."
          onUpdate={(group) => handlePickedGroup(group)}
          getOptionValue={(choice) => choice?.id}
          getOptionLabel={(choice) => choice?.name}
          bodyPortaled
        />
      )}

      <RenderGroup group={wholeSelectedGroup} disabled />
    </>
  );
};
