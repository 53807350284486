import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { LinkedObject } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { useApartment, editUrl } from "../../../store/apartments";
import { useBuilding } from "../../../store/buildings";
import { detailUrl as buildingDetailUrl } from "../../../store/buildings";
import { constants as rentIncreaseConstants } from "../../../store/rentIncrease";
import { detailUrl as companyDetailUrl } from "../../../store/companies";

import {
  toMoneyString,
  toSqmString,
} from "../../../components/utils/stringUtils";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  buildQueryString,
  deleteObject,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../../store/base";
import { useFilteredNotes } from "../../../store/notes";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import { getApartmentIncreases } from "../../../store/rentIncrease/store/actions";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import { renderFloors } from "../../../components/Forms/Base/FieldComponents/FloorSelector";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import moment from "moment";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { useCompany } from "../../../store/companies";
import ApartmentStatusModalForm from "../../../components/Forms/Apartment/StatusForm/ModalForm";
import { lightOrDark } from "../../../components/DigitalDoc/utils";
import Table from "../../../components/Billecta/Table/BasicTable";
import VacancyBookingRecordTable from "../../../components/Tables/InvoicingVacancyRecord/FullTable";
import ApartmentVacancyBookingModalForm from "../../../components/Forms/Apartment/VacancyBookingForm/ModalForm";
import SmallTenantSummary from "../../../components/SummaryCards/Tenant/Small";
import SmallCompanySummary from "../../../components/SummaryCards/Company/Small";
import Cookies from "js-cookie";

const RENT_INCREASE_TABS = {
  COMING: "Kommande",
  HISTORICAL: "Genomförda",
};

export default function ApartmentOverview() {
  const dispatch = useDispatch();
  const { apartmentId } = useParams();
  const userType = useSelector((state) => state.app.user?.user_type);
  const { push } = useHistory();
  const [statusesOpen, setStatusesOpen] = React.useState(false);
  const [vacancyBookingConfigOpen, setVacancyBookingConfigOpen] =
    React.useState(false);
  const [removeRentIncreaseOpen, setRemoveRentIncreaseOpen] =
    React.useState(false);
  const [selectedRentIncreaseTab, setSelectedRentIncreaseTab] =
    React.useState("COMING");

  const canEdit = usePermissionCheck("change_can_baseobject");
  const yourBlockActive = usePermissionCheck("allow_yourblock");
  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const customerId = React.useMemo(() => {
    try {
      return JSON.parse(Cookies.get("intercom_customer") ?? {})?.id;
    } catch (e) {
      return null;
    }
  }, []);

  const canViewSensitiveInfoTemp = useSelector(
    (state) =>
      (state.app.user?.user_type === 1 || state.app.user?.user_type === 0) &&
      (customerId != 128 || state.app.user?.user_type === 0)
  ); /// TEMP SOLUTION
  const { hasBillectaViewPermission } = useFrequentPermissions();

  const [apartment] = useApartment(apartmentId);
  const [building] = useBuilding(apartment?.building?.id);

  const [vacancyBookedAt] = useCompany(apartment?.vacancy_booking_company?.id);

  const inInvoicingVacancyRecordsQ = {
    apartment: apartmentId,
    order_by: "-updated_at",
  };

  const notesQ = buildQueryString({
    id__in: apartment?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const increases = useSelector(
    (state) => state[rentIncreaseConstants.STORE_NAME].currentIncreases
  );
  const rentIncreaseQuery = buildQueryString({
    apartment: apartmentId,
  });

  React.useEffect(() => {
    dispatch(getApartmentIncreases({ query: rentIncreaseQuery }));
  }, []);

  const comingIncreases = increases?.filter(
    (i) => !i.applied && moment(i.apply_from).isAfter(moment())
  );
  const historicalIncreases = increases?.filter(
    (i) => !comingIncreases?.map((c) => c.id).includes(i.id)
  );

  const goToRentIncreases = () => {
    push(`/apartment-rent-increase?apartmentid=${apartment.id}`);
  };

  const handleConfirmRemoveRentIncrease = (id) => {
    setRemoveRentIncreaseOpen(id);
  };

  const removeRentIncrease = () => {
    dispatch(
      deleteObject({
        instance: {
          id: removeRentIncreaseOpen,
        },
        constants: rentIncreaseConstants,
        noToast: true,
        skipDestroyDeleteData: true,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Hyreshöjningen togs bort",
              description: "Den planerade höjningen genomförs ej",
            })
          );
          dispatch({
            type: rentIncreaseConstants.SET_CURRENT_APARTMENT_INCREASES,
            payload: {
              increases: [],
            },
          });
          dispatch(getApartmentIncreases({ query: rentIncreaseQuery }));
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Den planerade höjningen kunde ej tas bort",
            })
          );
        },
      })
    );
  };

  const renderRentIncreases = () => {
    return (
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Hyreshöjningar</OverviewTitle>
            <OverviewSubtitle>
              <TextButton
                title="Lägg till hyreshöjning"
                iconType="add"
                iconPlacement="right"
                clicked={goToRentIncreases}
              />
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="mb-2">
          <PageSlider
            {...{
              TABS: RENT_INCREASE_TABS,
              selectedTab: selectedRentIncreaseTab,
              onTabSelected: (tab) => setSelectedRentIncreaseTab(tab),
            }}
          />
        </div>
        {selectedRentIncreaseTab === "COMING" && (
          <>
            {comingIncreases?.length ? (
              <PremisesTable
                headers={[
                  "Datum för höjning",
                  "Höjning av referenshyra",
                  "Ta bort",
                ]}
                rows={comingIncreases?.map((ci) => [
                  ci.apply_from,
                  `${ci.quota.toFixed(2)}%`,
                  <TextButton
                    red
                    title="Ta bort"
                    iconType="close"
                    iconPlacement="right"
                    clicked={() => handleConfirmRemoveRentIncrease(ci.id)}
                  />,
                ])}
              />
            ) : (
              <div>Inga planerade höjningar</div>
            )}
          </>
        )}

        {selectedRentIncreaseTab === "HISTORICAL" && (
          <>
            {historicalIncreases?.length ? (
              <PremisesTable
                headers={["Datum för höjning", "Höjning av referenshyra"]}
                rows={historicalIncreases?.map((ci) => [
                  ci.apply_from,
                  `${ci.quota.toFixed(2)}%`,
                ])}
              />
            ) : (
              <div>Inga genomförda höjningar</div>
            )}
          </>
        )}
      </DetailPageBox>
    );
  };

  const statuses = React.useMemo(() => {
    return apartment?.customer_statuses;
  }, [apartment]);

  const statusColumns = React.useMemo(() => {
    return [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 8px",
                borderRadius: 5,
                backgroundColor: value?.color_code,
                color:
                  lightOrDark(value?.color_code) === "light" ? "#000" : "#fff",
              }}
            >
              {value?.name || "-"}
            </div>
          );
        },
      },

      {
        Header: "Börjar gälla",
        accessor: "start_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;
          return <div>{value}</div>;
        },
      },
      {
        Header: "Slutar gälla",
        accessor: "end_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;

          return <div>{value}</div>;
        },
      },
    ];
  }, [apartment]);

  return (
    <>
      <ApartmentStatusModalForm
        id={apartmentId}
        isOpen={statusesOpen}
        onCheckout={() => setStatusesOpen(false)}
        instance={apartment}
      />
      {vacancyBookingConfigOpen && (
        <ApartmentVacancyBookingModalForm
          id={apartmentId}
          onCheckout={() => setVacancyBookingConfigOpen(false)}
          instance={apartment}
        />
      )}
      <ConfirmationModal
        isOpen={!!removeRentIncreaseOpen}
        title="Bekräfta borttagande"
        renderContent={() => (
          <div>
            Observera att även debiteringsraden som har förberetts inne på avtal
            kopplade till detta objekt måste tas bort. Detta kommer{" "}
            <strong>EJ</strong> att ske automatiskt. Du hittar dessa
            debiteringsrader under Avtalet -&gt; Kostnader
          </div>
        )}
        closeFunction={() => setRemoveRentIncreaseOpen(false)}
        acceptCallback={removeRentIncrease}
        acceptTitle="Ta bort hyreshöjning"
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              width: "60%",
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Objektsöversikt</OverviewTitle>
                {canEdit && (
                  <PrimaryButton
                    title="Redigera"
                    clicked={() => push(editUrl({ id: apartmentId }))}
                  />
                )}
              </OverviewTitleWrapper>

              <DetailInfo
                infoObj={getInfoObj({
                  apartment,
                  building,
                  vacancyBookedAt,
                  yourBlockActive,
                  avyTmplActive,
                  canViewSensitiveInfoTemp,
                })}
              />
            </DetailPageBox>

            {hasBillectaViewPermission && (
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle>Vakansbokningar</OverviewTitle>
                    <OverviewSubtitle>
                      Logg över genererade vakansbokningar. Tryck på en rad för
                      att komma till underlaget.
                    </OverviewSubtitle>
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera inställning för vakansbokningar"
                        clicked={() => setVacancyBookingConfigOpen(true)}
                        iconType="edit"
                        iconPlacement="right"
                      />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <VacancyBookingRecordTable
                  persistantQuery={inInvoicingVacancyRecordsQ}
                  isBare
                  ignoreLocalStorage
                  withInvoiceDetailUrl
                  hideFilters
                />
              </DetailPageBox>
            )}
          </div>

          {canViewSensitiveInfoTemp && (
            <div
              style={{
                width: "40%",
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {apartment?.tenant && (
                <DetailPageBox>
                  <OverviewTitleWrapper>
                    <OverviewTitle small>Hyresgäst</OverviewTitle>
                  </OverviewTitleWrapper>

                  <SmallTenantSummary id={apartment?.tenant?.id} />
                </DetailPageBox>
              )}
              {apartment?.landlord && (
                <DetailPageBox>
                  <OverviewTitleWrapper>
                    <OverviewTitle small>Hyresvärd</OverviewTitle>
                  </OverviewTitleWrapper>

                  <SmallCompanySummary id={apartment?.landlord?.id} />
                </DetailPageBox>
              )}
              <DetailPageBox>
                <OverviewTitleWrapper style={{ marginBottom: 4 }}>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Anpassade statusar</OverviewTitle>
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera statusar"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setStatusesOpen(true)}
                      />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                {statuses?.length ? (
                  <Table data={statuses} columns={statusColumns} hideSearch />
                ) : (
                  <InnerBox style={{ marginTop: 8, marginBottom: 12 }}>
                    Inga statusar satta på objektet
                  </InnerBox>
                )}
                <hr style={{ marginTop: 0 }} />
                <TextButton
                  title="Gå till statusöversikt"
                  iconType="arrow"
                  iconPlacement="right"
                  clicked={() => push("/configcenter/premises-statuses")}
                />
              </DetailPageBox>

              {renderRentIncreases()}
              <DetailPageBox>
                <Notes
                  notes={notes}
                  contentType="standard.apartment"
                  objectId={apartmentId}
                />
              </DetailPageBox>
            </div>
          )}
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({
  apartment,
  building,
  yourBlockActive,
  avyTmplActive,
  vacancyBookedAt,
  canViewSensitiveInfoTemp,
}) {
  const infoObj = {
    Översikt: [
      {
        title: "Synkad mot YourBlock",
        value: (
          <StatusLabel state={apartment?.yourblock_id ? 0 : 3}>
            {apartment?.yourblock_id ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !yourBlockActive,
      },
      {
        title: "Synkad mot Avy-Tmpl",
        value: (
          <StatusLabel state={apartment?.avytmpl_id ? 0 : 3}>
            {apartment?.avytmpl_id ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !avyTmplActive,
      },
      {
        title: "Lägenhetsnummer",
        value: apartment?.apartment_id,
      },
      {
        title: "Objektsnummer",
        value: apartment?.premises_id,
      },

      {
        title: "Fastighet",
        value: apartment?.realestate?.str_representation,
      },
      {
        title: "Byggnad",
        value: <LinkedObject obj={building} urlMethod={buildingDetailUrl} />,
      },

      {
        title: "Vakanser bokas på",
        value: (
          <LinkedObject obj={vacancyBookedAt} urlMethod={companyDetailUrl} />
        ),
      },
      {
        title: "Våning",
        value: renderFloors(apartment?.dynamic_floors),
      },
      {
        title: "Kategori",
        value: apartment?.category_display,
      },
      {
        title: "Area",
        value: toSqmString(apartment?.area),
      },
      {
        title: "Referenshyra/månad",
        value: toMoneyString(apartment?.base_rent, true),
        hidden: !canViewSensitiveInfoTemp,
      },

      // {
      //   title: "Under förhandlingsordning",
      //   value: apartment?.negotiated_rent_increase ? "Ja" : "Nej",
      //   hidden: !canViewSensitiveInfoTemp,
      // },
      // {
      //   title: "Under renovering",
      //   value: apartment?.renovating ? "Ja" : "Nej",
      // },

      // {
      //   title: "Uthyrd i andrahand",
      //   value: apartment?.subletted ? "Ja" : "Nej",
      // },
    ],
    Adress: [
      {
        title: "Adress",
        value: apartment?.address
          ? apartment?.address?.base?.split(",")?.[0]
          : "-",
      },
      {
        title: "Postnr.",
        value: apartment?.address?.postal_code || "-",
      },
      {
        title: "Stad",
        value: apartment?.address?.city || "-",
      },
      {
        title: "Land",
        value: apartment?.address?.country || "-",
      },
    ],
  };

  return infoObj;
}
