import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InvoicePreviewForm from "../../components/Forms/Invoice/PreviewForm/PreviewForm";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import useQuery from "../../components/utils/useQuery";
import { prepareCreditInvoice } from "../../store/billectaInvoicing";
import { addToast, TOAST_TYPES } from "../../store/toasts";

export default function CreditInvoice() {
  const dispatch = useDispatch();
  const [ready, setReady] = React.useState(false);
  const { invoiceId } = useParams();

  const query = useQuery();

  const newInvoiceDate = query.get("newInvoiceDate");
  const connectToOriginal = query.get("connectToOriginal");

  const { goBack } = useHistory();

  React.useEffect(() => {
    dispatch(
      prepareCreditInvoice({
        invoiceId,
        newInvoiceDate,
        connectToOriginal,
        successCallback: () => setReady(true),
        errorCallback: (e) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta detaljer för kreditering",
              description: e?.Message || e || "",
            })
          );

          goBack();
        },
      })
    );
  }, [invoiceId]);

  if (!ready) return <FullPageSpinner />;

  return <InvoicePreviewForm method="POST" isCredit id={invoiceId} />;
}
