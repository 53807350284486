import * as React from "react";
import { useDispatch } from "react-redux";

import {
  useSFTPCatalogueForm,
  constants,
  create,
  update,
  destroyPatchForm,
  destroyPostForm,
  useFilteredSFTPCatalogues,
} from "../../../../store/SFTPCatalogue";
import { cloneDeep } from "lodash";
import { setActiveFormInstance } from "../../../../store/base/store/actions";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import DateSelect from "../../Base/Fields/DateSelect";
import { Select } from "../../Base/Fields";
import SelectField from "../../Base/Fields/SelectField";

export default function SFTPCatalogueModalForm({
  method,
  id,
  onCheckout,
  instance,
  onDelete,
}) {
  const [existingSFTPCatalogues] = useFilteredSFTPCatalogues("");
  const companiesWithSFTPActivated = existingSFTPCatalogues?.map(
    (s) => s.company?.id
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  const formLoaded = Boolean(useSFTPCatalogueForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout(success);
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={method === "POST" ? "Lägg till SFTP-jobb" : "Uppdatera SFTP-jobb"}
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
      onDelete={onDelete}
      canDelete={!!onDelete && method === "PATCH"}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Konfigurera SFTP-jobb</div>

      {method === "POST" && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            persistantQuery={{
              "id__in!": companiesWithSFTPActivated?.length
                ? companiesWithSFTPActivated
                : undefined,
            }}
            title="Gäller för bolag/BRF"
            placeholder="Välj bolag/brf..."
            method={method}
            fieldKey="company"
            TableComponent={CompanyTable}
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title={"Host"}
          storeName={storeName}
          fieldKey={"host"}
          method={method}
        />
        <TextInputField
          title={"Port"}
          storeName={storeName}
          fieldKey={"port"}
          method={method}
        />
        <TextInputField
          title={"Katalog"}
          storeName={storeName}
          fieldKey={"base_path"}
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title={"Användarnamn"}
          storeName={storeName}
          fieldKey={"username"}
          method={method}
        />
        <TextInputField
          title={"Lösenord"}
          storeName={storeName}
          fieldKey={"password"}
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          title={"Börjar köras"}
          storeName={storeName}
          fieldKey={"start_day"}
          method={method}
        />
        <SelectField
          title={"Dagsintervall"}
          storeName={storeName}
          fieldKey={"day_interval"}
          forceChoices={[
            { v: 1, d: "Varje dag" },
            { v: 2, d: "2 dagar" },
            { v: 3, d: "3 dagar" },
            { v: 4, d: "4 dagar" },
            { v: 5, d: "5 dagar" },
            { v: 6, d: "6 dagar" },
            { v: 7, d: "7 dagar" },
            { v: 8, d: "8 dagar" },
            { v: 9, d: "9 dagar" },
            { v: 10, d: "10 dagar" },
            { v: 14, d: "14 dagar" },
            { v: 30, d: "30 dagar" },
          ]}
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title={"Prefix i filnamn"}
          storeName={storeName}
          fieldKey={"file_name_prefix"}
          method={method}
        />
      </div>
    </Modal>
  );
}
