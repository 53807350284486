import React from "react";
import ReactApexChart from "react-apexcharts";

import * as SC from "../../Layout/Insights/Widgets/styles";
import {
  getInfoForWidget,
  kpiDataMapping,
  WIDGET_DONUTCHART,
} from "./WidgetInfo";

export default function DonutChartDetailed({
  height,
  width,
  valueDict,
  widgetPlacement,
}) {
  const chartOptions = valueDict["chart_options"];
  const chartSeries = valueDict["chart_series"];
  const additionalData = valueDict["additional_data"];
  const topData = additionalData?.top_data;
  const containerHeight = additionalData["containerHeight"];
  const containerWidth = additionalData["containerWidth"];
  const chartType = getInfoForWidget(WIDGET_DONUTCHART).apex_chart_type;
  const columnDefs = kpiDataMapping[widgetPlacement.kpi].column_defs;

  const renderRow = (col, idx, col_index) => {
    if (!columnDefs || !topData) return
    if (col.formatter)
      return (
        <SC.WidgetTableData  isNumber={col.type} isFirst={col_index === 0} isLast={col_index === columnDefs.length -1}>
          {col.formatter(topData[col.accessor][idx], widgetPlacement?.kpi, widgetPlacement)}
        </SC.WidgetTableData>
      );
    return (
      <SC.WidgetTableData style={{ fontSize: "10px" }} align="center" isNumber={col.type} isFirst={col_index === 0} isLast={columnDefs ? col_index === columnDefs.length -1 : false}>
        {topData[col.accessor][idx]}
      </SC.WidgetTableData>
    );
  };

  if (chartSeries.length === 0) {
    return (
      <SC.InternalComponentContainer width={width} height={height}>
        <div
          style={{ alignSelf: "center", textAlign: "center", width: "100%", fontSize: "0.9rem" }}
        >
          Det verkar inte finnas någon data att visa
        </div>
      </SC.InternalComponentContainer>
    );
  }

  return (
    <SC.InternalComponentContainer width={width} height={height}>
      <SC.InternalComponentFlexContainer flexDirection="row">
        <ReactApexChart
          options={{ ...chartOptions }}
          series={chartSeries}
          height={containerHeight - 100}
          width={containerWidth * 0.45}
          type={chartType}
        />

        <SC.WidgetTableContainer>
          <SC.WidgetTable
            width={containerWidth * 0.55 - 35}
            height={containerHeight - 100}
          >
            <tr>
              {columnDefs.map((col, ind) => (
                <SC.WidgetTableHeader className={"text-xs text-slate-700 uppercase bg-slate-100"} key={`${JSON.stringify(col)}__${ind}`}>{col.title}</SC.WidgetTableHeader>
              ))}
            </tr>
            {chartOptions?.labels.map((place, idx) => (
              <SC.WidgetTableRow className="bg-white border-b even:bg-slate-50 hover:bg-blue-100" key={`${JSON.stringify(place)}__${idx}`} align="middle" isEven={idx % 2 == 0}>
                {columnDefs.map((col, ind) => renderRow(col, idx, ind))}
              </SC.WidgetTableRow>
            ))}
          </SC.WidgetTable>
        </SC.WidgetTableContainer>
      </SC.InternalComponentFlexContainer>
    </SC.InternalComponentContainer>
  );
}
