import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/leads";

import { detailUrl as pipeDetailUrl } from "../../../store/pipes";
import { useHistory } from "react-router-dom";

export default function LeadsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const columns = React.useMemo(() => {
    const data = columnDefs();
    return data;
  }, [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "leads_full_table"}
      title={"Leads"}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked: onRowClicked
          ? (obj) => onRowClicked(obj)
          : (obj) => {
              window.open(
                pipeDetailUrl({ id: obj.pipe?.id, openLead: obj.id })
              );
            },
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
