import * as React from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";

import debounce from "debounce-promise";

// style, design
import * as SC from "./styles";

// store, state
import { constants, useAddresses } from "../../../../store/addresses";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { axiosInstance } from "../../../../store/base/store/axios";
import { LinkButton } from "../Buttons";
import { TextInput } from ".";
import TextInputField from "./TextInputField";

export default React.memo(
  ({
    storeName,
    method,
    fieldKey,
    instructionsKey,
    noTitle,
    children,
    title,
  }) => {
    const dispatch = useDispatch();

    const [addressDetailsOpen, setAddressDetailsOpen] = React.useState(false);

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    React.useEffect(() => {
      if (!!error) {
        setAddressDetailsOpen(true);
      }
    }, [error]);

    const [choices, isLoading] = useAddresses();

    const wait = 500;
    const loadOptions = (inputValue) => getAsyncOptions(inputValue);
    const debouncedLoadOptions = debounce(loadOptions, wait, {
      leading: true,
    });

    React.useEffect(() => {
      if (addressDetailsOpen) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              [`${instructionsKey || fieldKey}.id`]: undefined,
              [`${instructionsKey || fieldKey}.str_representation`]: undefined,
              [`${instructionsKey || fieldKey}.homepal_id`]: undefined,
              [`${instructionsKey || fieldKey}.realestates`]: undefined,
              [`${instructionsKey || fieldKey}.backend_refreshed_at`]:
                undefined,
            },
          })
        );
      }
    }, [addressDetailsOpen]);

    const getAsyncOptions = (inputValue) => {
      if (inputValue.length < 3) return new Promise((resolve) => resolve([]));

      return new Promise((resolve) => {
        axiosInstance
          .get(`${constants.LOOKUP_URL}${inputValue}/`)
          .then(({ data }) => {
            resolve(data);
          });
      });
    };

    const onChange = async (data) => {
      let formattedData = instructions._allowNull ? null : undefined;
      if (data !== null) {
        if (data.id) {
          formattedData = {
            id: data.id,
            str_representation: data.str_representation,
          };
        } else {
          const { data: details } = await axiosInstance.get(
            `${constants.LOOKUP_DETAIL_URL}${data.place_id}/`
          );
          formattedData = details;
        }
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
    };

    const getOptionLabel = (option) => {
      return option.str_representation || option.description || option.base;
    };

    const getOptionValue = (option) => {
      return option.id || option.base;
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing>
        {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        {!addressDetailsOpen && (
          <>
            {!noTitle && (
              <SC.InputFieldTitle>
                {title || instructions._label}
                {instructions._required ? "*" : ""}
              </SC.InputFieldTitle>
            )}
            {instructions._helpText && (
              <SC.InputFieldDescription>
                {instructions._helpText}
              </SC.InputFieldDescription>
            )}
            {choices ? (
              <SC.SelectWrapper>
                <AsyncSelect
                  id={instructions._internalId}
                  placeholder="Skriv för att söka eller välj sparad adress"
                  onChange={onChange}
                  defaultValue={null}
                  cacheOptions={true}
                  defaultOptions={choices}
                  value={value}
                  loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
                  getOptionValue={(option) => getOptionValue(option)}
                  getOptionLabel={(option) => getOptionLabel(option)}
                  isClearable={!instructions._required}
                />
              </SC.SelectWrapper>
            ) : (
              <SC.SelectLoader>Laddar alternativ...</SC.SelectLoader>
            )}

            <LinkButton
              extraStyle={{ padding: "0" }}
              title={"Visa och redigera detaljerad adress"}
              clicked={() => setAddressDetailsOpen(!addressDetailsOpen)}
            />
          </>
        )}

        {addressDetailsOpen && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              storeName={storeName}
              title="Adress"
              fieldKey={`${fieldKey}.base`}
              instructionsKey={`${instructionsKey || fieldKey}.base`}
              method={method}
            />
            <TextInputField
              storeName={storeName}
              title="Postnummer"
              fieldKey={`${fieldKey}.postal_code`}
              instructionsKey={`${instructionsKey || fieldKey}.postal_code`}
              method={method}
            />
            <TextInputField
              storeName={storeName}
              title="Stad"
              fieldKey={`${fieldKey}.city`}
              instructionsKey={`${instructionsKey || fieldKey}.city`}
              method={method}
            />
            <TextInputField
              storeName={storeName}
              title="Kommun"
              fieldKey={`${fieldKey}.municipality`}
              instructionsKey={`${instructionsKey || fieldKey}.municipality`}
              method={method}
            />
            <TextInputField
              storeName={storeName}
              title="Län"
              fieldKey={`${fieldKey}.state`}
              instructionsKey={`${instructionsKey || fieldKey}.state`}
              method={method}
            />
            <TextInputField
              storeName={storeName}
              title="Land"
              fieldKey={`${fieldKey}.country`}
              instructionsKey={`${instructionsKey || fieldKey}.country`}
              method={method}
            />
          </div>
        )}

        {children && children}
      </SC.InputSpacing>
    );
  }
);
