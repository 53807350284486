import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { update } from "../../../../store/calendarEvents";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { redirectMethodFromKind } from "../../../../store/utils";
import { TextButton } from "../../../Forms/Base/Buttons";
import CalendarEventForm from "../../../Forms/CalendarEvent/ChapterForm/ModalForm";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import * as SC from "./styles";

export default function CalendarDisplayItem({
  modalInModal,
  event,
  hideObjectLink,
}) {
  const [editOpen, setEditOpen] = React.useState(false);
  const { push } = useHistory();

  const hasConnectedObj = event?.object_id && event?.content_type;

  const urlMethod = redirectMethodFromKind(event?.content_type);

  return (
    <>
      <CalendarEventForm
        method={"PATCH"}
        id={event?.id}
        isOpen={editOpen}
        onCheckout={() => {
          setEditOpen(false);
        }}
        instance={event}
        modalInModal={modalInModal}
      />

      <SC.TodoItemWrapper>
        {event?.end && (
          <SC.TodoEndDate>
            <SC.TodoTime>
              {moment(event.start).format("YYYY-MM-DD HH:mm")} -
              {moment(event.end).format("YYYY-MM-DD HH:mm")}
            </SC.TodoTime>
          </SC.TodoEndDate>
        )}
        <SC.TodoItemTitleWrapper>
          <SC.TodoText onClick={() => setEditOpen(true)}>
            {event?.title}
          </SC.TodoText>
        </SC.TodoItemTitleWrapper>

        <SC.TodoItemDescription style={{ paddingLeft: 0 }}>
          Kalender: {event?.calendar?.str_representation}
        </SC.TodoItemDescription>

        <SC.TodoItemDescription style={{ paddingLeft: 0 }}>
          Beskrivning: {event?.description}
        </SC.TodoItemDescription>

        <SC.TodoItemDescription style={{ paddingLeft: 0 }}>
          Plats: {event?.place?.str_representation || "-"}
        </SC.TodoItemDescription>

        {hasConnectedObj && urlMethod && !hideObjectLink && (
          <TextButton
            title="Gå till kopplat objekt"
            iconType="arrow"
            iconPlacement="right"
            extraStyle={{ marginTop: 6 }}
            clicked={() => push(urlMethod({ id: event.object_id }))}
          />
        )}
      </SC.TodoItemWrapper>
    </>
  );
}
