import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import useActiveFilters from "../../../hooks/useActiveFilters";

import RoundingAreasTable from "src/components/Tables/RoundingAreas/FullTable";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import RoundingAreaModalForm from "src/components/Forms/RoundingArea/ModalForm";

export default function Areas() {
  const { filteredRealEstates } = useActiveFilters();

  const [createOpen, setCreateOpen] = React.useState(false);
  const query = {
    realestate_ids: filteredRealEstates,
  };

  return (
    <>
      {createOpen && (
        <RoundingAreaModalForm
          method="POST"
          onCheckout={() => setCreateOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper className="mb-0">
            <OverviewTitle>Ronderingsområden</OverviewTitle>
            <PrimaryBtn onClick={() => setCreateOpen(true)}>
              Lägg till område
            </PrimaryBtn>
          </OverviewTitleWrapper>
          <RoundingAreasTable persistantQuery={query} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
