export const detailUrl = ({ id, skipSigning }) => {
  return `/other-contracts/detail/${id}${skipSigning ? "?skip-sign=true" : ""}`;
};
export const overviewUrl = () => {
  return `/other-contracts`;
};

export const editUrl = ({ id, existing, digitalSign }) => {
  return `/other-contracts/edit${existing ? "-existing" : ""}/${id}${
    existing ? "" : `?signtype=${digitalSign ? "digital-sign" : "manual-sign"}`
  }`;
};

export const cancelUrl = ({ id, digitalSign }) => {
  return `/other-contracts/cancel/${id}?signtype=${
    digitalSign ? "digital-sign" : "manual-sign"
  }`;
};

export const createOptionsUrl = () => `/other-contracts/create/type`;

export const createUrl = ({ existing, digitalSign }) => {
  return `/other-contracts/create/${
    existing ? "existing" : "new"
  }?manual_sign=${!digitalSign}`;
};
export const createCostsUrl = ({ id, cloneFrom }) => {
  return `/other-contracts/create/costs/${id}${
    cloneFrom ? `?clone-from=${cloneFrom}` : ""
  }`;
};

export const handleManyInvoicingUrl = () => {
  return "/other-contracts/handle-invoicing-statuses";
};
