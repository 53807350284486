export const detailUrl = ({ id, skipSigning }) => {
  return `/parking-contracts/detail/${id}${
    skipSigning ? "?skip-sign=true" : ""
  }`;
};
export const overviewUrl = () => {
  return `/parking-contracts`;
};

export const createOptionsUrl = () => `/parking-contracts/create/type`;

export const createUrl = ({ existing, digitalSign }) => {
  return `/parking-contracts/create/${
    existing ? "existing" : "new"
  }?manual_sign=${!digitalSign}`;
};

export const cancelUrl = ({ id, isManualSign }) => {
  return `/parking-contracts/cancel/${id}${
    isManualSign ? "?manual_sign=true" : ""
  }`;
};
export const handleManyInvoicingUrl = () => {
  return "/parking-contracts/handle-invoicing-statuses";
};

export const createCostsUrl = ({ id, cloneFrom }) => {
  return `/parking-contracts/create/costs/${id}${
    cloneFrom ? `?clone-from=${cloneFrom}` : ""
  }`;
};
