import * as React from "react";
import Blueprint from "./Blueprint";

export default ({ blueprint, premises = {}, rooms = [], premisesType }) => {
  const NoBlueprint = (
    <div
      style={{
        width: "100%",
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Detta objekt är inte kopplat till en planritning
    </div>
  );

  if (!blueprint) {
    return NoBlueprint;
  }

  return (
    <Blueprint
      blueprint={blueprint}
      premises={premises}
      rooms={rooms}
      premisesType={premisesType}
    />
  );
};
