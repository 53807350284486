import AdDetails from "./AdDetails";
import Confirm from "./Confirm";
import Description from "./Description";
import Publish from "./Publish";

export default {
  AD_DETAILS: AdDetails,
  PUBLISH: Publish,
  DESCRIPTION: Description,
  CONFIRM: Confirm,
};
