import {
  ExclamationTriangleIcon,
  FolderPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import * as React from "react";
import Badge, { BADGE_TYPES } from "../../../Badge/Badge";

import PopOver, { POP_OVER_POSITIONS } from "../../../PopOver/PopOver";
import Modal from "../Modals/Modal";

export default function LocalTableSelectField({
  value,
  onChange,
  error,
  required,
  TableComponent,
  placeholder,
  title,
  description,
  disabled,
  persistantQuery,
  persistantQueryString, // temp
  keepOpenOnSelect = false,
  isMany = false,
  displayKey = "str_representation", // key on selected object to display if isMany
  modalClassName = "",
  onDone,
  onClose,
  onClearAll,
  tooltipPopOverClassName = "",
  popOverHeightMultiplier = false,
  className = "",
  labelClassName = "",
}) {
  const [pickerOpen, setPickerOpen] = React.useState(false);

  persistantQuery = persistantQuery ?? persistantQueryString;

  const onSelected = (selected) => {
    if (isMany) {
      let valueClone = cloneDeep(value);

      if (!valueClone) {
        valueClone = [selected];
      } else if (valueClone.find((s) => s.id === selected.id)) {
        valueClone = valueClone.filter((s) => s.id !== selected.id);
      } else {
        valueClone.push(selected);
      }

      onChange(valueClone);
    } else {
      onChange(selected);
    }

    if (!keepOpenOnSelect && !isMany) {
      onClose && onClose();
      setPickerOpen(false);
    }
  };

  const checkRowHighlighted = (original) => {
    if (isMany) {
      return value?.some((v) => v.id === original.id);
    } else {
      return value?.id === original.id;
    }
  };

  const onClear = () => {
    onChange(isMany ? [] : null);
  };

  const onRemoveIdx = (idx) => {
    let valueClone = cloneDeep(value);

    valueClone.splice(idx, 1);

    onChange(valueClone);
  };

  const renderTitle = () => {
    if (isMany) {
      if (value?.length > 0) {
        return (
          <div>
            {value[0][displayKey]}{" "}
            {value?.length > 1 ? (
              <Badge type={BADGE_TYPES.DEFAULT}>
                +{value.length - 1}{" "}
                <PopOver
                  tooltipClassName={classNames("p-0", tooltipPopOverClassName)}
                  position={POP_OVER_POSITIONS.BOTTOM}
                  heightMultiplier={popOverHeightMultiplier}
                >
                  <div className="flex flex-col w-full">
                    {value?.map((v, idx) => {
                      return (
                        <div className="border-b border-solid cursor-default border-slate-200 p-2 flex justify-between bg-white hover:bg-blue-100">
                          <div className={classNames("text-sm")}>
                            {v[displayKey]}
                          </div>
                          <XMarkIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              onRemoveIdx(idx);
                            }}
                            width={16}
                            className="ml-auto cursor-pointer"
                          />
                        </div>
                      );
                    })}
                  </div>
                </PopOver>
              </Badge>
            ) : (
              ""
            )}
          </div>
        );
      } else {
        return <div>{placeholder || title}</div>;
      }
    } else {
      return (
        <div>{value ? value?.str_representation : placeholder || title}</div>
      );
    }
  };
  return (
    <>
      <div className={classNames("", className)}>
        <div>
          <div
            className={classNames(
              "font-medium text-gray-900 text-sm flex items-center",
              labelClassName
            )}
          >
            {error && (
              <ExclamationTriangleIcon
                width={16}
                className="text-red-600 mr-1"
              />
            )}{" "}
            {title}
            {required ? "*" : ""}
          </div>
          {description && (
            <div className="text-xs font-normal text-gray-500 bg-transparent mb-1 ">
              {description}
            </div>
          )}
        </div>

        {error && (
          <div className="text-xs font-normal text-red-600 mb-1">{error}</div>
        )}

        <button
          onClick={(e) => {
            e.preventDefault();
            setPickerOpen(true);
          }}
          disabled={disabled}
          className={`inline-flex w-full ${
            disabled ? "bg-slate-200 opacity-80 cursor-not-allowed" : "bg-white"
          } items-center border border-solid border-slate-300 text-gray-900  rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center `}
        >
          {renderTitle()}
          {(isMany && value?.length > 0) || (!isMany && value) ? (
            <XMarkIcon
              onClick={(e) => {
                e.stopPropagation();
                onClear();
                onClearAll && onClearAll();
              }}
              width={16}
              className="ml-auto"
            />
          ) : (
            <FolderPlusIcon width={16} className="ml-auto" />
          )}
        </button>
      </div>

      {pickerOpen && (
        <Modal
          className={modalClassName}
          closeFunction={() => {
            setPickerOpen(false);
            onClose && onClose();
          }}
          title={placeholder}
          onAccept={() => {
            onDone && onDone();
            setPickerOpen(false);
          }}
        >
          <TableComponent
            ignoreLocalStorage
            {...{
              persistantQuery,
              checkRowHighlighted,
              onRowClicked: (original) => onSelected(original),
            }}
          />
        </Modal>
      )}
    </>
  );
}
