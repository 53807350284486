export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_LOAN",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_LOAN",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_LOAN",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_LOAN",
  DESTROY_FORM: "DESTROY_FORM_LOAN",
  SET_FORM_ERROR: "SET_FORM_ERROR_LOAN",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_LOAN",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_LOAN",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_LOAN",
  RESET_STATE: "RESET_STATE_LOAN",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_LOAN",
  REMOVE_OBJECT: "REMOVE_OBJECT_LOAN",
  CLEAR_FETCHED: "CLEAR_FETCHED_LOAN",

  LIST_URL: "/brf/loans/loan/list/",
  POST_URL: "/brf/loans/loan/",
  PATCH_URL: "/brf/loans/loan/",
  GET_URL: "/brf/loans/loan/",

  STORE_NAME: "loan",
};
