import { insertIntoAll } from "../../base";
import { constants as parkingLotContants } from "../../parkingLots";

const handleUpdateConnectedStateOnCreate = ({
  sentData,
  receivedData,
  dispatch,
}) => {
  if (!receivedData) return;

  if (!sentData.parking_lot?.id && receivedData.parking_lot) {
    dispatch(insertIntoAll(parkingLotContants, receivedData.parking_lot));
  }
};

export { handleUpdateConnectedStateOnCreate };
