import * as React from "react";
import { constants } from "../../../../../../store/IMDMeasureType";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../../Details/OverviewInfo/styles";
import { TextInput } from "../../../../Base/Fields";

export default () => {
  const storeName = constants.STORE_NAME;

  return (
    <div>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Specificera kolumner för värden</OverviewTitle>
          <OverviewSubtitle>
            Dessa fält specificerar vad rubriken är på de kolumner som
            innehåller de obligatoriska värdena för att import ska kunna
            genomföras.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        storeName={storeName}
        fieldKey={"_import._sensorIdColumnName"}
        title={"Rubriknamn på kolumn för sensor-ID"}
        manualInstructions={{
          _readOnly: false,
          _required: true,
        }}
        description={"Rubriken för kolumnen där sensor-ID:n är specificerade"}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"_import._dateColumnName"}
        title={"Rubriknamn på kolumn för datum"}
        manualInstructions={{
          _readOnly: false,
          _required: true,
        }}
        description={"Kolumnnamnet för datumet"}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"_import._valueColumnName"}
        title={"Rubriknamn på kolumn för mätvärde"}
        manualInstructions={{
          _readOnly: false,
          _required: true,
        }}
        description={"Kolumnnamnet för mätvärdet"}
      />

      <hr />

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>
            Specificera kolumner för objektkoppling
          </OverviewTitle>
          <OverviewSubtitle>
            Dessa fält specificerar vad rubriken är på de kolumner som
            innehåller de frivilliga objektsnumren på objekt som automatiskt ska
            kopplas till sensorerna i det importerade dokumentet.
            <br />
            <br />
            Observera att den automatiska kopplingen ej fungerar om det finns
            dubbletter av objektsnummer i systemet, t.ex. om det finns två
            hyreslägenheter med objektsnummer "1001" i systemet kommer den
            automatiska kopplingen att ignoreras.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        storeName={storeName}
        fieldKey={"_import._apartmentColumnName"}
        title={"Rubriknamn på kolumn för hyreslägenhets objektsnummer"}
        subtitleHelp="Frivilligt, om detta specificeras kommer Pigello automatisk försöka koppla till rätt objekt"
        manualInstructions={{
          _readOnly: false,
          _required: false,
        }}
        description={"Rubriknamnet för kopplad hyreslägenhet"}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"_import._industrialColumnName"}
        title={"Rubriknamn på kolumn för lokals objektsnummer"}
        subtitleHelp="Frivilligt, om detta specificeras kommer Pigello automatisk försöka koppla till rätt objekt"
        manualInstructions={{
          _readOnly: false,
          _required: false,
        }}
        description={"Kolumnnamnet för lokals objektsnummer"}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"_import._brfColumnName"}
        subtitleHelp="Frivilligt, om detta specificeras kommer Pigello automatisk försöka koppla till rätt objekt"
        title={"Rubriknamn på kolumn för bostadsrätts objektsnummer"}
        manualInstructions={{
          _readOnly: false,
          _required: false,
        }}
        description={"Kolumnnamnet för bostadsrätts objektsnummer"}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"_import._parkingColumnName"}
        subtitleHelp="Frivilligt, om detta specificeras kommer Pigello automatisk försöka koppla till rätt objekt"
        title={"Rubriknamn på kolumn för fordonsplats objektsnummer"}
        manualInstructions={{
          _readOnly: false,
          _required: false,
        }}
        description={"Kolumnnamnet för fordonsplats"}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"_import._commonAreaColumnName"}
        subtitleHelp="Frivilligt, om detta specificeras kommer Pigello automatisk försöka koppla till rätt objekt"
        title={"Rubriknamn på kolumn för gemensam ytas objektsnummer"}
        manualInstructions={{
          _readOnly: false,
          _required: false,
        }}
        description={"Kolumnnamnet för gemensam ytas objektsnummer"}
      />
    </div>
  );
};
