import * as React from "react";
import { durationParse } from "../../../components/Displays/utils";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  toMoneyString,
  toSqmString,
} from "../../../components/utils/stringUtils";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";

import { buildQueryString, usePermissionCheck } from "../../../store/base";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useFilteredNotes } from "../../../store/notes";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useParams } from "react-router";
import {
  useLeaseContract,
  constants as leaseConstants,
  update as updateLeaseContract,
} from "../../../store/leaseContracts";
import { DateCell } from "../../../components/Displays";
import UpdateLeaseContractModalForm from "../../../components/Forms/LeaseContract/EditContractForm/ModalForm";
import UpdateLeaseContractTenantsModalForm from "../../../components/Forms/LeaseContract/EditContractTenantForm/ModalForm";
import UpdateLeaseContractPremisesModalForm from "../../../components/Forms/LeaseContract/EditContractPremisesForm/ModalForm";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  useEditabledoc,
} from "../../../store/editabledocs";
import UpdateLeaseContractLandlordModalForm from "../../../components/Forms/LeaseContract/EditContractLandlordForm/ModalForm";

import moment from "moment";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import { useFilteredApartments } from "../../../store/apartments";
import { useFilteredIndustrialPremises } from "../../../store/industrialPremises";
import { useLeaseInvoicing } from "../../../store/invoicingLease";
import DigitalSign from "../../../components/Signing/DigitalSign";
import ManualSign from "../../../components/Signing/ManualSign";
import ManualCancellation from "../../../components/Signing/ManualCancellation";
import DigitalCancellation from "../../../components/Signing/DigitalCancellation";
import SmallTenantSummary from "../../../components/SummaryCards/Tenant/Small";
import SmallCompanySummary from "../../../components/SummaryCards/Company/Small";
import SmallApartmentSummary from "../../../components/SummaryCards/Apartment/Small";
import SmallIndustrialPremisesSummary from "../../../components/SummaryCards/IndustrialPremises/Small";
import {
  getMonthsForCost,
  getContractFromAndTo,
  getCostBetweenMonthDates,
  getIndexBetweenMonths,
  getSpecifiedPeriodDates,
  PRODUCT_CATEGORY_MAPPING,
} from "../../../components/Lists/utils/costCalculations";
import useNextPeriodValue from "../useNextPeriodValue";

function LeaseContractOverview() {
  const dispatch = useDispatch();
  const { leaseContractId } = useParams();
  const [contract] = useLeaseContract(leaseContractId);
  const [invoicingObj] = useLeaseInvoicing(contract?.lease_invoicing?.id);
  const [editableDoc] = useEditabledoc(contract?.editabledoc?.id);
  const [cancelledDoc] = useEditabledoc(contract?.cancelled_doc?.id);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editTenantsOpen, setEditTenantsOpen] = React.useState(false);
  const [editLandlordOpen, setEditLandlordOpen] = React.useState(false);
  const [editPremisesOpen, setEditPremisesOpen] = React.useState(false);
  const [draftLoading, setDraftLoading] = React.useState(false);

  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const canEdit = usePermissionCheck("change_can_contract");

  const showSigningDetails = !contract?.is_signed;
  const isDigitalSign = contract?.uses_e_signing;

  const isCancelled = contract?.status === 6 || contract?.status === 7;
  const isDigitalCancelSign = contract?.uses_cancellation_e_signing;
  const isDraft = contract?.draft;

  const nextInvoiceTotal = useNextPeriodValue();

  const notesQ = buildQueryString({
    id__in: contract?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const tenantIds = [];
  if (contract?.tenant?.id) {
    tenantIds.push(contract.tenant.id);
  }
  const biTenantIds = contract?.bi_tenants?.map((bt) => bt.id) || [];
  biTenantIds.forEach((id) => tenantIds.push(id));

  const apartmentQuery = buildQueryString({
    id__in: contract?.apartments?.map((ap) => ap.id) || [],
  });
  const [apartments] = useFilteredApartments(apartmentQuery);

  const indpQuery = buildQueryString({
    id__in: contract?.industrial_premises_list?.map((ip) => ip.id) || [],
  });
  const [industrialPremises] = useFilteredIndustrialPremises(indpQuery);

  const combinedCosts = [
    ...(invoicingObj?.cost_set || []),
    ...(apartments?.map((a) => a.cost_set)?.flat() || []),
    ...(industrialPremises?.map((i) => i.cost_set)?.flat() || []),
  ];

  const convertDraftToReal = () => {
    setDraftLoading(true);

    dispatch(
      updateLeaseContract({
        id: leaseContractId,
        forceData: {
          draft: false,
        },
        successCallback: () => {
          setDraftLoading(false);
        },
      })
    );
  };
  return (
    <>
      <UpdateLeaseContractModalForm
        instance={contract}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        id={leaseContractId}
      />

      {editTenantsOpen && (
        <UpdateLeaseContractTenantsModalForm
          instance={contract}
          onCheckout={() => setEditTenantsOpen(false)}
          id={leaseContractId}
        />
      )}

      {editLandlordOpen && (
        <UpdateLeaseContractLandlordModalForm
          instance={contract}
          onCheckout={() => setEditLandlordOpen(false)}
          id={leaseContractId}
        />
      )}

      {editPremisesOpen && (
        <UpdateLeaseContractPremisesModalForm
          instance={contract}
          onCheckout={() => setEditPremisesOpen(false)}
          id={leaseContractId}
        />
      )}

      <DetailInnerWrapper>
        {isDraft && (
          <DetailPageBox>
            {draftLoading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Detta avtal är ett utkast</OverviewTitle>

                <OverviewSubtitle>
                  Tryck på "Konvertera från utkast till avtal" om avtalet är
                  redo att användas.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Konvertera från utkast till avtal"
              clicked={convertDraftToReal}
              iconType="done"
              iconPlacement="right"
            />
          </DetailPageBox>
        )}

        {contract && showSigningDetails && !isDraft && (
          <>
            {isDigitalSign ? (
              <DigitalSign
                {...{
                  contract,
                  editableDoc,
                  constants: leaseConstants,
                  update: updateLeaseContract,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT,
                }}
              />
            ) : (
              <ManualSign
                {...{
                  contract,
                  constants: leaseConstants,
                  update: updateLeaseContract,
                }}
              />
            )}
          </>
        )}

        {contract && isCancelled && (
          <>
            {isDigitalCancelSign ? (
              <DigitalCancellation
                {...{
                  contract,
                  cancelledDoc,
                  constants: leaseConstants,
                  update: updateLeaseContract,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT,
                }}
              />
            ) : (
              <ManualCancellation
                {...{
                  contract,
                  cancelledDoc,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT,
                  update: updateLeaseContract,
                  constants: leaseConstants,
                }}
              />
            )}
          </>
        )}

        <DetailPageBoxFlexWrapper>
          <div style={{ width: "65%", marginRight: 12 }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>
                    Översikt över {contract?.id_number}
                  </OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera avtalsdetaljer"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {contract && (
                <DetailInfo
                  infoObj={getInfoObj({
                    contract,
                    combinedCosts,
                    avyTmplActive,
                    nextInvoiceTotal,
                  })}
                />
              )}
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hyresobjekt</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera hyresobjekt"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditPremisesOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <div
                className={`grid grid-cols-${
                  contract?.apartments?.length +
                    contract?.industrial_premises_list?.length >
                  1
                    ? "2"
                    : "1"
                } gap-3`}
              >
                {contract?.apartments?.map((ap) => (
                  <SmallApartmentSummary id={ap.id} />
                ))}

                {contract?.industrial_premises_list?.map((ip) => (
                  <SmallIndustrialPremisesSummary id={ip.id} />
                ))}
              </div>
            </DetailPageBox>
          </div>

          <div style={{ width: "35%" }}>
            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hyresgäster</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera hyresgäster"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditTenantsOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <div className="grid grid-cols-1 gap-3">
                {tenantIds?.map((tId) => (
                  <SmallTenantSummary
                    key={tId}
                    id={tId}
                    showContractDetails
                    isPrimaryTenant={tId === contract?.tenant?.id}
                  />
                ))}
              </div>
            </DetailPageBox>

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hyresvärd</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera hyresvärd"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditLandlordOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <SmallCompanySummary id={contract?.landlord?.id} />
            </DetailPageBox>

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <Notes
                notes={notes}
                contentType="standard.leasecontract"
                objectId={leaseContractId}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

export function getOverviewPageCostsDetail(contract, costsData) {
  if (!contract) return [];
  const { contractFrom, contractTo } = getContractFromAndTo(contract);

  const indexEndOfYear = contractFrom
    .clone()
    .add({ year: 1 })
    .subtract({ days: 1 });

  const { specifiedEndDate: specifiedIndexEndDate } = getSpecifiedPeriodDates({
    start: contractFrom.format("YYYY-MM-DD"),
    end: indexEndOfYear.format("YYYY-MM-DD"),
    contract,
  });

  const costs = getCostBetweenMonthDates(costsData, contractFrom, contractTo);

  const indexValueForPeriod = getIndexBetweenMonths(
    contract?.indexations,
    contractFrom,
    specifiedIndexEndDate
  );

  costs.sort((a, b) => {
    const bRate =
      b.end_date && b.start_date ? 3 : b.end_date ? 2 : b.start_date ? 1 : 0;
    const aRate =
      a.end_date && a.start_date ? 3 : a.end_date ? 2 : a.start_date ? 1 : 0;

    if (bRate === aRate) {
      return (a.product_category ?? -1) - (b.product_category ?? -1);
    }
    return aRate - bRate;
  });

  let costInfo = [];

  let totalCost =
    typeof indexValueForPeriod === "number" ? indexValueForPeriod : 0;

  costs.forEach((c) => {
    const productKey = PRODUCT_CATEGORY_MAPPING[c.product_category] ?? "Övriga";

    const start = moment(c.start_date);
    const end = moment(c.end_date);

    let dtString = "";
    if (start._isValid && end._isValid) {
      dtString = ` (${start.format("YYYY-MM-DD")} - ${end.format(
        "YYYY-MM-DD"
      )})`;
    } else if (start._isValid) {
      dtString = ` (f.o.m ${start.format("YYYY-MM-DD")})`;
    } else if (end._isValid) {
      dtString = ` (t.o.m ${end.format("YYYY-MM-DD")})`;
    }

    const months = getMonthsForCost(c, contractFrom, contractTo);
    const totVal = c.value * c.unit_amount * months;
    costInfo.push({
      title: `${c.title.substr(0, 15)}${
        c.title?.length > 15 ? "..." : ""
      } (${productKey})`,
      value: `${toMoneyString(totVal)}${dtString}`,
    });

    totalCost += totVal;
  });

  if (indexValueForPeriod && indexValueForPeriod > 0) {
    costInfo.push({
      title: "Indexuppräkning",
      value: toMoneyString(indexValueForPeriod),
    });
  }

  if (costInfo.length) {
    costInfo.push({
      title: <b>Totalt</b>,
      value: <b>{toMoneyString(totalCost)}</b>,
    });
  } else {
    costInfo.push({
      title: "Kostnader Saknas",
      value: "",
    });
  }

  return costInfo;
}

function getInfoObj({
  contract,
  combinedCosts,
  avyTmplActive,
  nextInvoiceTotal,
}) {
  const { contractFrom, contractTo } = getContractFromAndTo(contract);

  const infoObj = {
    Avtalsdetaljer: [
      {
        title: "Avtalsstatus",
        value: (
          <StatusLabel state={contract?.state}>
            {contract?.state_display}
          </StatusLabel>
        ),
      },
      { title: "Avtals-ID", value: contract?.id_number },

      { title: "Startdatum", value: <DateCell date={contract?.start_date} /> },
      { title: "Slutdatum", value: <DateCell date={contract?.end_date} /> },
      {
        title: "Förlängt till",
        value: <DateCell date={contract?.renewed_to} />,
        hidden: !contract?.renewed_to,
      },
      {
        title: "Uppsägningstid",
        value: contract?.notify_interval
          ? durationParse(contract?.notify_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Förlängningstid",
        value: contract?.renew_interval
          ? durationParse(contract?.renew_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Varningstid",
        value: contract?.warning_interval
          ? durationParse(contract?.warning_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Signerades",
        value: <DateCell date={contract?.signed_date} />,
      },
      {
        title: "Hyresvärd",
        value: contract?.landlord?.str_representation || "-",
      },
    ],

    "Övrig information": [
      {
        title: "Faktureras nästa period",
        value: `${toMoneyString(nextInvoiceTotal ?? 0)} SEK`,
        hidden: !contract?.lease_invoicing?.id,
      },
      { title: "Total area", value: toSqmString(contract?.total_area) },
      {
        title: "Synkad mot Avy-Tmpl",
        value: (
          <StatusLabel state={contract?.synced_with_avytmpl ? 0 : 3}>
            {contract?.synced_with_avytmpl ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !avyTmplActive,
      },
    ],

    [`Intäkter (${contractFrom.format("YYYY-MM")} - ${contractTo.format(
      "YYYY-MM"
    )})`]: contract ? getOverviewPageCostsDetail(contract, combinedCosts) : [],
  };

  return infoObj;
}

export default LeaseContractOverview;
