export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CHECKLIST",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CHECKLIST",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CHECKLIST",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CHECKLIST",
  DESTROY_FORM: "DESTROY_FORM_CHECKLIST",
  SET_FORM_ERROR: "SET_FORM_ERROR_CHECKLIST",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CHECKLIST",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CHECKLIST",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CHECKLIST",
  RESET_STATE: "RESET_STATE_CHECKLIST",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CHECKLIST",
  REMOVE_OBJECT: "REMOVE_OBJECT_CHECKLIST",
  CLEAR_FETCHED: "CLEAR_FETCHED_CHECKLIST",

  LIST_URL: "/errands/checklist/checklist/list/",
  POST_URL: "/errands/checklist/checklist/",
  PATCH_URL: "/errands/checklist/checklist/",
  GET_URL: "/errands/checklist/checklist/",

  STORE_NAME: "checklist",
};
