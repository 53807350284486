import styled, { css } from "styled-components";
import refreshIcon from "../../../../assets/svg/refresh.svg";

export const OuterWrapper = styled.div`
  position: relative;
  max-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: 12px 0;
  border-radius: 5px;
  border: thin solid #dddddd;

  ${(p) =>
    p.isNotificationsTable &&
    css`
      border: none;
      box-shadow: none;
      background-color: #fff;
    `}

  ${(p) =>
    p.fullscreenModeActive &&
    css`
      position: fixed;
      top: ${(p) => (p.withExternalFilters ? "120px" : "70px")};
      left: 0;
      right: 0;
      bottom: 40px;
      margin: 0;
      background-color: white;
      z-index: 666;
      overflow: auto;
    `}
`;

export const FullScreenMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${(p) => (p.withExternalFilters ? "120px" : "70px")};
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 2;
  padding: 12px;
`;

export const InnerFullScreenMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  border-radius: 5px;

  ${(p) =>
    p.fullscreenModeActive &&
    css`
      max-height: ${p.withExternalFilters
        ? "calc(100vh - 163px)"
        : "calc(100vh - 113px)"};
    `}
`;

export const TableLoadingWrapper = styled.div`
  overflow-x: auto;
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: 12px 0;
  min-height: 160px;
  position: relative;
`;

export const TableEmptyText = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  padding: 12px;
`;

export const TableEmptyWrapper = styled.div`
  overflow-x: auto;
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: 12px 0;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const Table = styled.table`
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: "Inter", sans-serif;
  min-width: 100%;
  position: relative;

  & th,
  td {
    padding: 10px 12px;
    display: table-cell;
    white-space: nowrap;
  }

  & td {
    vertical-align: middle;
  }

  & thead tr {
    background-color: ${(p) => p.theme.colors.activeGray};
    color: ${(p) => p.theme.colors.primary};
    text-align: left;
    white-space: nowrap;
  }

  & tbody tr:nth-of-type(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }

  ${(p) =>
    p.fullscreenModeActive &&
    css`
      & thead {
        position: sticky;
        top: 0;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
      }
    `}

  & tbody tr {
    border-bottom: thin solid #dddddd;

    transition: color, background-color 50ms ease-in-out;

    &:hover {
      background-color: #f3f4f6;
      color: ${(p) => p.theme.colors.textPrimary};
    }

    &.active-row {
      background-color: ${(p) => p.theme.colors.blueLight};
      color: ${(p) => p.theme.colors.blueDark};

      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.blueDark};
      }
    }

    cursor: pointer;
  }

  ${(p) =>
    p.isNotificationsTable &&
    css`
      border-collapse: collapse;
      font-size: 0.9em;
      font-family:"Inter", sans-serif;
      min-width: 100%;

      & th {
        border-bottom: 2px solid black;
        background: #fafbfd;
      }
      & th,
      td {
        padding: 10px 12px;
        vertical-align: middle;
        display: table-cell;
        white-space: nowrap;
      }

      & thead tr {
        color: ${(p) => p.theme.colors.primary};
        text-align: left;
        white-space: nowrap;
      }

      & tbody td:first-child{
         padding-top: 16px;
      }
      & tbody tr {
        border:none;
        transition: color, background-color 50ms ease-in-out;

        &:hover {
          background-color: #eff2ff;
          color: ${(p) => p.theme.colors.textPrimary};
        }

          &:hover {
            background-color: ${(p) => p.theme.colors.blueLight};
            color: ${(p) => p.theme.colors.blueDark};
          }
        }

        &.clickable-row {
          cursor: pointer;
        }
      }
    `}
`;

export const StatusLabel = styled.div`
  align-self: flex-start;
  padding: 2px 6px;
  font-weight: 600;
  font-size: ${(p) => (p.large ? p.theme.fontSizes.headerSmall : "0.8rem")};
  border-radius: 3px;
  color: ${(p) => p.theme.colors.primary};
  display: inline-flex;

  ${"" /* ******* contract statuses  ******* */}

  ${(p) =>
    p.state === "neutral" &&
    css`
      background-color: ${(p) => p.theme.colors.gray1};
      color: ${(p) => p.theme.colors.graphite};
    `}


  ${(p) =>
    (p.state === 0 || p.state === 1) &&
    css`
      background-color: #eafaf8;
      color: #2ec9b8;
    `}

    ${(p) =>
    p.state === 5 &&
    css`
      background-color: #f9efff;
      color: #cc68fb;
    `}

       ${(p) =>
    (p.state === 10 || p.state === 9) &&
    css`
      background-color: #f3f4f6;
      color: #5c667b;
    `}

         ${(p) =>
    p.state === 3 &&
    css`
      background-color: #fff8ec;
      color: #fdb738;
    `}

     ${(p) =>
    p.state === 2 &&
    css`
      background-color: #eafaf8;
      color: #2ec9b8;
    `}

       ${(p) =>
    p.state === 8 &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

        ${(p) =>
    p.state === 4 &&
    css`
      background-color: #eff2ff;
      color: #6883fb;
    `}

        ${(p) =>
    (p.state === 7 || p.state === 6) &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

   ${"" /* billecta statuses  */}

    ${(p) =>
    (p.state === "Okänd" || p.state === "Kreditfaktura") &&
    css`
      background-color: #e8e8e8;
      color: #2e2e2e;
    `}

      ${(p) =>
    p.state === "Förfallen (Ej hanterad)" &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

    ${(p) =>
    p.state === "Ej attesterad" &&
    css`
      background-color: #ffeabd;
      color: #a67611;
    `}

     ${(p) =>
    p.state === "Pausad" &&
    css`
      background-color: #ffeabd;
      color: #a67611;
    `}

     ${(p) =>
    p.state === "Skickad" &&
    css`
      background-color: #d6e4ff;
      color: #114ec2;
    `}

     ${(p) =>
    p.state === "Påminnelse" &&
    css`
      background-color: #c29eff;
      color: #4700ba;
    `}

       ${(p) =>
    p.state === "Kravhantering" &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

      ${(p) =>
    (p.state === "Slutbetald" || p.state === "Bokförd") &&
    css`
      background-color: #c7ffd0;
      color: #14a82c;
    `}

         ${(p) =>
    p.state === "Avbruten" &&
    css`
      background-color: #e8e8e8;
      color: #2e2e2e;
    `}

        ${(p) =>
    p.state === "Kravhantering" &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

        ${(p) =>
    p.state === "Attesterad" &&
    css`
      background-color: #cafaf3;
      color: #0f7a6b;
    `}

    ${"" /* ******* tenant statuses  ******** */}

  ${"" /* potential  */}
  ${(p) =>
    p.contentType === "tenant" &&
    p.state === 2 &&
    css`
      background-color: #f9efff;
      color: #cc68fb;
    `}

       ${"" /* active  */}
      ${(p) =>
    p.contentType === "tenant" &&
    p.state === 0 &&
    css`
      background-color: #eafaf8;
      color: #2ec9b8;
    `}

        ${"" /* former  */}
     ${(p) =>
    p.contentType === "tenant" &&
    p.state === 3 &&
    css`
      background-color: #f2f2f2;
      color: #111111;
    `}

      ${"" /* future  */}
      ${(p) =>
    p.contentType === "tenant" &&
    p.state === 1 &&
    css`
      color: rgb(97, 119, 220);
      background-color: rgb(240, 243, 254);
    `}

        ${(p) =>
    p.contentType === "premises" &&
    p.state === 9 &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

         ${(p) =>
    p.state === "BETA" &&
    css`
      background-color: #fdb738;
      color: #444a55;
    `}


      ${(p) =>
    p.contentType === "errand" &&
    p.state === 1 &&
    css`
      background-color: #c7ffd0;
      color: #14a82c;
    `}
      ${(p) =>
    p.contentType === "errand" &&
    p.state === 2 &&
    css`
      background-color: #fff8ec;
      color: #fdb738;
    `}
      ${(p) =>
    p.contentType === "errand" &&
    p.state === 3 &&
    css`
      background-color: #d6e4ff;
      color: #114ec2;
    `}
      ${(p) =>
    p.contentType === "errand" &&
    p.state === 4 &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}
      ${(p) =>
    p.contentType === "errand" &&
    p.state === 5 &&
    css`
      background-color: #f2f2f2;
      color: #111111;
    `}
      ${(p) =>
    p.contentType === "errand" &&
    p.state === 6 &&
    css`
      background-color: #f9efff;
      color: #cc68fb;
    `}
`;

export const ErrandStatusLabel = styled.div`
  padding: 2px 6px;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 3px;
  color: ${(p) => p.theme.colors.primary};
  display: inline-flex;

  ${(p) =>
    p.state === -1 &&
    css`
      background-color: #f2f2f2;
      color: #111111;
    `}

  ${(p) =>
    p.state === 0 &&
    css`
      background-color: #f2ebff;
      color: #9258f0;
    `}

    ${(p) =>
    p.state === 1 &&
    css`
      background-color: #f2ebff;
      color: #9258f0;
    `}

     ${(p) =>
    [2, 3, 4].includes(p.state) &&
    css`
      color: rgb(97, 119, 220);
      background-color: rgb(240, 243, 254);
    `}
    ${(p) =>
    [5, 6, 7].includes(p.state) &&
    css`
      color: ${(p) => p.theme.colors.yellow};
      background-color: ${(p) => p.theme.colors.yellowLight};
    `}
    ${(p) =>
    [8, 9, 10].includes(p.state) &&
    css`
      color: ${(p) => p.theme.colors.green};
      background-color: ${(p) => p.theme.colors.greenLight};
    `}
    ${(p) =>
    [11, 12, 13].includes(p.state) &&
    css`
      color: ${(p) => p.theme.colors.green};
      background-color: ${(p) => p.theme.colors.greenLight};
    `}

      ${(p) =>
    p.state === "paused" &&
    css`
      color: ${(p) => p.theme.colors.red};
      background-color: ${(p) => p.theme.colors.redLight};
    `}
      ${(p) =>
    p.state === "active" &&
    css`
      color: ${(p) => p.theme.colors.green};
      background-color: ${(p) => p.theme.colors.greenLight};
    `}
`;

export const FilterWrapper = styled.div`
  padding: 6px 0 12px 0;
`;

export const TableActionsWrapper = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.activeGray};
  color: ${(p) => p.theme.colors.primary};
`;

export const ToggleFilterButton = styled.div`
  cursor: pointer;
  height: 30px;
  display: flex;
  font-weight: 600;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;

  background-color: rgba(241, 242, 246, 0.35);
  border: thin solid rgba(0, 0, 0, 0.14);
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0 8px;
`;

export const ToggleColumnsButton = styled.div`
  cursor: pointer;
  height: 30px;
  display: flex;
  font-weight: 600;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;

  background-color: rgba(241, 242, 246, 0.35);
  border: thin solid rgba(0, 0, 0, 0.14);
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0 8px;
`;

export const RefreshButton = styled.div`
  height: 32px;
  width: 32px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(${refreshIcon});
`;
