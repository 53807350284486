import Confirm from "./Confirm";

import General from "./General";
import Payment from "./Payment";
import Registration from "./Registration";

export default {
  GENERAL: General,
  CONFIRM: Confirm,
  PAYMENT: Registration,
  REGISTRATION: Payment,
};
