export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ERRAND_PRODUCT_TYPES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ERRAND_PRODUCT_TYPES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ERRAND_PRODUCT_TYPES",
  DESTROY_FORM: "DESTROY_FORM_ERRAND_PRODUCT_TYPES",
  SET_FORM_ERROR: "SET_FORM_ERROR_ERRAND_PRODUCT_TYPES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ERRAND_PRODUCT_TYPES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ERRAND_PRODUCT_TYPES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ERRAND_PRODUCT_TYPES",
  RESET_STATE: "RESET_STATE_ERRAND_PRODUCT_TYPES",
  CLEAR_FETCHED: "CLEAR_FETCHED_ERRAND_PRODUCT_TYPES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_PRODUCT_TYPES",
  REMOVE_OBJECT: "REMOVE_OBJECT_ERRAND_PRODUCT_TYPES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ERRAND_PRODUCT_TYPES",

  LIST_URL: "/errands/components/producttype/list/",
  POST_URL: "/errands/components/producttype/",
  PATCH_URL: "/errands/components/producttype/",
  GET_URL: "/errands/components/producttype/",

  STORE_NAME: "productTypes",
};
