import React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import InsightsPage from "./InsightsPage";
import DefaultInsightsPage from "./DefaultInsightsPage";
import { widgetInfo } from "src/components/Insights/Widgets/WidgetInfo";
import { DetailInnerWrapper, DetailPageBox } from "src/components/sharedStyles";
import { CogIcon } from "@heroicons/react/24/outline";
import {
  OverviewSubtitle,
  OverviewTitle,
} from "src/components/Details/OverviewInfo/styles";
export default function InsightsMain() {
  const { path, url } = useRouteMatch();

  const [images, setImages] = React.useState({});
  const myRef = React.useRef(images);

  React.useEffect(() => {
    myRef.current = images;
  }, [images]);

  const allImageUrls = React.useMemo(
    () =>
      Object.keys(widgetInfo).map((_key) => {
        return {
          imageURL: widgetInfo[_key].image_url,
          key: _key,
        };
      }),
    []
  );

  React.useEffect(() => {
    const fetched = {};
    const fetchedImg = (imgMap, myImg) => {
      fetched[imgMap.key] = myImg;
      if (Object.keys(fetched).length === allImageUrls.length) {
        setImages(fetched);
      }
    };
    const fetchImg = async (imgMap) => {
      fetch(imgMap.imageURL)
        .then(async (res) => {
          const myUrl = URL.createObjectURL(await res.blob());
          const myImg = <img src={myUrl} />;
          fetchedImg(imgMap, myImg);
        })
        .catch((err) => console.log(err));
    };

    for (let imageUrl of allImageUrls) {
      fetchImg(imageUrl);
    }
  }, [allImageUrls]);

  if (
    window.location.host !== "demo.app.pigello.se" &&
    window.location.host !== "localhost:3000"
  ) {
    return (
      <DetailInnerWrapper>
        <DetailPageBox>
          <div className="min-h-400 w-full flex flex-col justify-center items-center">
            <CogIcon
              width={72}
              className="my-8"
              style={{ animation: "spin 3s linear infinite" }}
            />
            <OverviewTitle small>
              Uppdatering av Insights-verktyget pågår
            </OverviewTitle>

            <OverviewSubtitle className="text-center">
              Vi ber om ursäkt för avbrottet i tjänsten. Den uppdaterade
              versionen av verktyget planeras att släppas inom kort. <br />
              Vi återkommer med information om vad uppdateringen innhåller inom
              kort!
            </OverviewSubtitle>
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    );
  }

  return (
    <>
      <Switch>
        <Route path={`${path}/page/:id`}>
          <InsightsPage widgetImages={images} />
        </Route>
        <Route exact path={`${path}/`} component={DefaultInsightsPage} />
      </Switch>
    </>
  );
}
