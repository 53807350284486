export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_REALESTATE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_REALESTATE",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_REALESTATE",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_REALESTATE",
  DESTROY_FORM: "DESTROY_FORM_REALESTATE",
  SET_FORM_ERROR: "SET_FORM_ERROR_REALESTATE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_REALESTATE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_REALESTATE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_REALESTATE",
  RESET_STATE: "RESET_STATE_REALESTATE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_REALESTATE",
  REMOVE_OBJECT: "REMOVE_OBJECT_REALESTATE",
  CLEAR_FETCHED: "CLEAR_FETCHED_REALESTATE",

  LIST_URL: "/standard/toplevel/realestate/list/",
  POST_URL: "/standard/toplevel/realestate/",
  PATCH_URL: "/standard/toplevel/realestate/",
  GET_URL: "/standard/toplevel/realestate/",

  STORE_NAME: "realEstates",
};
