import * as React from "react";
import { Redirect, useParams } from "react-router";

import { detailUrl } from "../../../store/invoicingProjects";

export default function InvoicingProjectRedirect() {
  const { id } = useParams();

  // wait for sufficient data to redirect
  if (!id) return null;

  return <Redirect to={detailUrl({ id })} />;
}
