import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];

    // errand report_files
    const errandReportImages = formInstance?.report_files;
    const errandReportPutUrls = responseData?.report_files?.map(
      (f) => f?.file?.put
    );

    if (errandReportImages?.length) {
      errandReportPutUrls.forEach((url, index) => {
        const image = errandReportImages?.[index];

        if (image?._tempData && url) {
          urls.push(url);
          files.push(image?._tempData?.data);
        }
      });
    }

    // errand completion_files
    const errandImages = formInstance?.completion_files;
    const errandImagesPutUrls = responseData?.completion_files?.map(
      (f) => f?.file?.put
    );

    if (errandImages?.length) {
      errandImagesPutUrls.forEach((url, index) => {
        const image = errandImages?.[index];

        if (image?._tempData && url) {
          urls.push(url);
          files.push(image?._tempData?.data);
        }
      });
    }

    // errand execution ratings files
    const errandRatingFiles = [];
    const errandRatingFilePutUrls = [];
    if (formInstance?.execution_ratings?.length) {
      const matches = formInstance?.execution_ratings;

      matches.forEach((m) => {
        const putUrl = responseData?.active_ratings?.find(
          (ar) => ar.component_placement?.id === m.component_placement?.id
        )?.file?.put;

        if (m.file?._tempData && putUrl) {
          errandRatingFiles.push(m.file._tempData.data);
          errandRatingFilePutUrls.push(putUrl);
        }
      });
    }

    if (errandRatingFiles?.length && errandRatingFilePutUrls?.length) {
      errandRatingFilePutUrls.forEach((u) => urls.push(u));
      errandRatingFiles.forEach((f) => files.push(f));
    }

    // cost files
    let costFiles = [];
    const reportPutUrls =
      responseData?.costs?.map((c) => c?.evidence?.put) || [];

    if (formInstance.costs?.length) {
      formInstance.costs.forEach((c, index) => {
        const currentPutUrl = reportPutUrls[index];

        if (c.evidenceData_ && !!currentPutUrl) {
          costFiles.push(c.evidenceData_);
          urls.push(reportPutUrls[index]);
        }
      });
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
