import * as React from "react";
import * as SC from "./styles";
import { useTransition } from "@react-spring/web";

export default ({
  actions,
  open,
  closeFunction,
  leftAligned,
  menuButtonLeftAligned,
}) => {
  const ref = React.useRef();

  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!ref.current) return;

    if (ref.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    closeFunction();
  };

  const modalTransition = useTransition(!!open, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateY(50%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.PopupMenu
          ref={ref}
          style={{ ...styles, opacity: 1 }}
          onClick={(e) => e.preventDefault()}
          {...{ leftAligned, menuButtonLeftAligned }}
        >
          {actions?.map((a) => (
            <SC.PopupItem
              onClick={
                a.disabled
                  ? null
                  : () => {
                      a.onClick();
                      closeFunction();
                    }
              }
              active={a.active}
              hidden={a.hidden}
              disabled={a.disabled}
              isDelete={a.isDelete}
            >
              <SC.PopupText>{`${a.name}`}</SC.PopupText>
            </SC.PopupItem>
          ))}
        </SC.PopupMenu>
      )
  );
};
