import * as React from "react";

import { DatePicker, RadioGroup, TextField } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketApartmentAds";

import { buildQueryString } from "../../../../../store/base";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";

export default ({ method, hideApartment }) => {
  const { filteredRealEstates } = useActiveFilters();
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Annonsinställningar</OverviewTitle>
          <OverviewSubtitle>
            Ställ in hur annonsen ska se ut och fungera
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {!hideApartment && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey="apartment"
            TableComponent={ApartmentTable}
            persistantQuery={{
              realestate_ids: filteredRealEstates,
            }}
            title="Lägenhet annons gäller för"
            description="Observera att enbart lägenheter med annonsdetaljer inlagda kan väljas. Annonsdetaljer kan läggas till under Lägenhet > Annonsdetaljer"
            placeholder="Välj lägenhet..."
          />
        </div>
      )}

      <DatePicker
        fieldKey="date_publish"
        title="Publiceringsdatum"
        {...{ storeName, method }}
      />

      <TextField
        title="Annonsbeskrivning"
        description="Denna beskrivning kommer tillsammans med bilder på objektet samt annonsdetaljerna publiceras på aktiva annonsplatser"
        {...{ storeName, method }}
        fieldKey="description"
      />

      <RadioGroup
        extraStyles={{ flex: 1 }}
        fieldKey="is_pending"
        title="Pausad"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />
    </>
  );
};
