import moment from "moment";
import * as React from "react";

export default function useDurationString(durationValue) {
  const durationArr = React.useMemo(() => {
    const dur = moment.duration(durationValue);
    if (!dur.isValid()) return null;
    return [dur.days(), dur.hours(), dur.minutes()];
  }, [durationValue]);

  const renderDuration = () => {
    const str = durationArr
      ? `${
          durationArr[0]
            ? `${durationArr[0]} dag${durationArr[0] > 1 ? "ar" : ""} `
            : ""
        }${durationArr[1] ? `${durationArr[1]}h ` : ""}${
          durationArr[2] ? `${durationArr[2]}min` : ""
        }`
      : "-";

    if (!str) return "-";

    return str;
  };

  return renderDuration();
}
