import { PDFDocument } from "pdf-lib";
import fileDownload from "js-file-download";

export const decidedExport = async ({
  getTitle,
  getKeyWords,
  fieldMapping,
  url,
}) => {
  const loadForPrint = async () => {
    const arrBuff = await fetch(url).then((res) => res.arrayBuffer());
    return await PDFDocument.load(arrBuff);
  };

  const decoratePdf = (pdf) => {
    const title = getTitle();
    const keyWords = getKeyWords ? getKeyWords() : [];
    let baseKeys = [
      "Pigello",
      "Pigello Solutions",
      "Pigello Property Solutions",
      "Pigello Fastighetssystem",
    ];
    baseKeys.concat(keyWords);

    pdf.setTitle(title);
    pdf.setSubject(title);
    pdf.setKeywords(baseKeys);
    pdf.setProducer("Pigello Property Systems");
    pdf.setCreator("Pigello Property Systems");
  };

  const printDocument = async () => {
    const pdf = await loadForPrint();

    const form = pdf.getForm();

    // handle fields
    Object.keys(fieldMapping).forEach((key) => {
      const instructions = fieldMapping[key];
      let value = instructions.getValue();
      if (!value) {
        value = "";
      }
      if (instructions?.handleValue) {
        instructions.handleValue(form, value);
      } else {
        // default behavior is text
        try {
          const component = form.getTextField(key);
          component.setText(value);
          component.enableReadOnly();
        } catch {
          return;
        }
      }
    });

    // decoreate it with nice things
    decoratePdf(pdf);

    const pdfBytes = await pdf.save();

    fileDownload(pdfBytes, "download.pdf");
    return pdfBytes;
  };

  try {
    return await printDocument();
  } catch (error) {
    return undefined;
  }
};
