import { useDispatch, useSelector } from "react-redux";
import {
  useObjects,
  usePagination,
  useFilteredObjects,
  buildQueryString,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import { useInProgress } from "../../base";
import { getAll, performFilter, filterPagination } from "../store/actions";
import constants from "../store/constants";
import { getImportantMessages } from "..";

export function useImportantMessages(creditorId) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const inProcessName = "important_messages";

  const messages = useSelector((state) => state[storeName].importantMessages);

  const inProgress = useInProgress({ storeName, name: inProcessName });

  if (inProgress) return [undefined, true];

  if (!messages) {
    dispatch(getImportantMessages({ inProcessName }));
    return [undefined, true];
  }

  if (creditorId) {
    return [messages.filter((m) => m.CreditorPublicId === creditorId), false];
  }

  return [messages, false];
}

export function useBillectaEvents(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredBillectaEvents(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useBillectaEventsPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useBillectaEventsPaginationCount({
  relatedCreditorIds = [],
  read,
  categories,
}) {
  const queryString = buildQueryString({
    related_creditor_id__in: relatedCreditorIds,
    read: !!read,
    category__in: categories,
    _page: 1,
    _page_size: 0,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}

export const REPORT_EVENTS_CATEGORIES = {
  INVOICE_CREATED: "inv_created",
  INVOICE_FULLY_PAYED: "inv_fully_payed",
  INVOICE_SALE_ACCEPTED: "inv_sale_accepted",
  INVOICE_SALE_DENIED: "inv_sale_denied",
  INVOICE_ATTESTED: "inv_attested",
  INVOICE_PAYMENT: "inv_payment",
  INVOICE_UNDELIVERED: "inv_undelivered",
  INVOICE_UNMATCHED_PAYMENT: "inv_payment_unmatched",
  INVOICE_OVERPAYED: "inv_overpayed",
  INVOICE_SENT: "inv_sent",
  AUTOGIRO_APPROVAL_FAIL: "autogiro_approve_failed",
  AUTOGIRO_CHANGED: "autogiro_changed",
  AUTOGIRO_PAYMENT_FAIL: "autogiro_payment_failed",
  AUTOGIRO_PAYMENT_FAIL_RETRY: "autogiro_payment_failed_retry",
  INVOICE_REMINDER_SENT: "inv_reminder_sent",
  INVOICE_DEBT_COLLECT: "inv_debt_collect",
};
