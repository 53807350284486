import * as React from "react";
import { useAsyncDebounce } from "react-table";
import * as SC from "./styles";

export default function GlobalSearchBox({
  globalFilter,
  setGlobalFilter,
  extraStyles,
  preventAutoFocus,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value.toLowerCase() || undefined);
  }, 400);

  React.useEffect(() => {
    if (!globalFilter) {
      setValue("");
    }
  }, [globalFilter]);

  return (
    <SC.SearchBoxWrapper style={{ ...extraStyles }}>
      <SC.SearchInput
        value={value || ""}
        {...{ autoFocus: !preventAutoFocus }}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Sök i tabellen`}
      />
    </SC.SearchBoxWrapper>
  );
}
