import * as React from "react";

import { useDispatch } from "react-redux";
import DigitalDocHeader from "./Header";
import {
  constants,
  destroyPatchForm,
  destroyPostForm,
  useEditabledocForm,
  update as updateEditableDoc,
} from "../../store/editabledocs";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import DigitalDocPDFRenderer from "./PdfRenderer";
import { cloneDeep } from "lodash";
import DigitalDocTemplateSelector from "./TemplateSelector/TemplateSelector";
import useSetPartyColors from "./useSetPartyColors";
import ConfirmModal from "../Forms/Base/Modals/ConfirmModal";
import LocalCheckField from "../Forms/Base/Fields/LocalCheckField";
import { useHistory } from "react-router-dom";

export default function DigitalDocContainer({
  method = "PATCH",
  id,
  onSubmit,
  isTemplateEditor,
  onClearDocumentCallback,
  contract,
  detailUrl,
  isCancelledDoc,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { goBack, push } = useHistory();
  useEditabledocForm(method, id);
  useSetPartyColors({ fieldKey: "parties", storeName });

  const [isSwitchingDocument, setIsSwitchingDocument] = React.useState(false);
  const [shouldClearFieldsAndParties, setShouldClearFieldsAndParties] =
    React.useState(false);
  const [confirmCancelOpen, setConfirmCancelOpen] = React.useState(false);

  const usesESigning = isCancelledDoc
    ? contract?.uses_cancellation_e_signing
    : contract?.uses_e_signing;

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const hasDoc =
    instance?.doc?._tempData?.data ||
    instance?.doc?.get ||
    instance?.fastdoc_id;

  const isFastDoc = instance?.fastdoc_id;

  const [pageBounds, setPageBounds] = React.useState({});

  const clearPageBoundsData = () => {
    setPageBounds({});
  };

  /**
   *  Each page is represented as
   *  [pageNumber]: {start, end, height, width}
   */
  const updatePageBounds = ({
    pageNumber,
    height,
    width,
    pageBottomMargin = 16,
  }) => {
    const pageBoundsClone = cloneDeep(pageBounds);
    const beforePageEnd = pageBoundsClone[pageNumber - 1]?.end || 0; // 0 if first page

    pageBoundsClone[pageNumber] = {
      start: beforePageEnd + (beforePageEnd === 0 ? 0 : 1),
      end: beforePageEnd + Math.round(height) + pageBottomMargin,
      height: Math.round(height), // + pageBottomMargin,
      width: Math.round(width),
    };

    setPageBounds(pageBoundsClone);
  };

  const closeIsSwitchingDocument = () => {
    setShouldClearFieldsAndParties(false);
    setIsSwitchingDocument(false);
  };

  const switchDocument = () => {
    if (shouldClearFieldsAndParties) {
      // clear doc fields
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            doc: undefined,
            fastdoc_id: undefined,
            parties: undefined,
          },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            doc: undefined,
            fastdoc_id: undefined,
          },
        })
      );
    }

    // if existing, delete doc from database to allow fastdoc to be picked from templates, ask backend team if unclear
    if (id) {
      dispatch(
        updateEditableDoc({
          forceData: {}, // emtpy to not affect anything but doc, can't put doc here due to preprocess logic
          deleteDoc: true,
          id,
          preventDefaultToast: true,
        })
      );
    }
    // callback used for autofill
    onClearDocumentCallback && onClearDocumentCallback();

    // clear page bounds data
    clearPageBoundsData();
  };

  const clearDocument = () => {
    setIsSwitchingDocument(true);
  };

  const handleCancel = () => {
    if (isTemplateEditor) {
      goBack();
    } else if (contract?.id) {
      push(detailUrl({ id: contract.id }));
    }
  };

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(true));
      dispatch(destroyPostForm(true));
    };
  }, []);

  return (
    <>
      {confirmCancelOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmCancelOpen(false)}
          onAccept={handleCancel}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Avbryt redigering av dokument <br />
          </h3>

          <p>
            Eventuella ändringar kommer att återställas. Detta kan ej ångras.
          </p>
        </ConfirmModal>
      )}

      {isSwitchingDocument && (
        <ConfirmModal
          closeFunction={closeIsSwitchingDocument}
          onAccept={switchDocument}
        >
          <div className="flex flex-col justify-start">
            <LocalCheckField
              title="Rensa fält och parter"
              value={shouldClearFieldsAndParties}
              onChange={(val) => setShouldClearFieldsAndParties(val)}
            />
            <p className="text-start mt-2">
              <strong>Vid byte till en mall </strong>kommer fält och parter att
              ersättas med vad som är inställt för den mallen.
            </p>

            {!isFastDoc && (
              <p className="text-start mt-2">
                <strong>
                  Dokumentet som används just nu kommer att raderas. Detta kan
                  ej ångras.
                </strong>
              </p>
            )}
          </div>
        </ConfirmModal>
      )}

      {hasDoc ? (
        <>
          <DigitalDocHeader
            storeName={storeName}
            pageBounds={pageBounds}
            clearPageBoundsData={clearPageBoundsData}
            method={method}
            isTemplateEditor={isTemplateEditor}
            clearDocument={clearDocument}
            onSubmit={onSubmit}
            isFastDoc={isFastDoc}
            usesESigning={usesESigning}
            setConfirmCancelOpen={setConfirmCancelOpen}
          />
          <DigitalDocPDFRenderer
            storeName={storeName}
            pageBounds={pageBounds}
            updatePageBounds={updatePageBounds}
            method={method}
            isTemplateEditor={isTemplateEditor}
          />
        </>
      ) : (
        <DigitalDocTemplateSelector
          {...{
            storeName,
            isCancelledDoc: false,
            usesESigning,
            contract,
            method,
            isTemplateEditor,
            detailUrl,
          }}
        />
      )}
    </>
  );
}
