import * as React from "react";

import ApartmentImageUploaderForm from "../../Forms/Apartment/ApartmentImageUploaderForm";
import CommonAreaImageUploaderForm from "../../Forms/CommonArea/CommonAreaImageUploaderForm";
import BrfPremisesImageUploaderForm from "../../Forms/BrfPremises/CoopPremisesImageUploaderForm";
import IndustrialPremisesImageUploaderForm from "../../Forms/IndustrialPremises/IndustrialPremisesImageUploaderForm";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import MediaDisplay from "./MediaDisplay";
import { InnerBox } from "../../sharedStyles";
export default ({ images = [], instance, kind }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="min-h-12 p-2 border rounded">
        {images?.length === 0 && (
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: 12 }}>
              Detta hyresobjekt har inga bilder
            </div>
            <PrimaryButton
              title={"Lägg till bilder"}
              clicked={() => setOpen(true)}
            />
          </div>
        )}

        {images?.length > 0 && (
          <InnerBox>
            <MediaDisplay files={images} fileKey="image" />
            <div className="p-2 flex justify-end mt-6">
              <PrimaryButton
                title="Lägg till bilder"
                clicked={() => setOpen(true)}
              />
            </div>
          </InnerBox>
        )}

        {instance && kind === "brfpremises" ? (
          <BrfPremisesImageUploaderForm
            method={"PATCH"}
            open={open}
            setOpen={setOpen}
            instance={instance}
          />
        ) : instance && kind === "apartment" ? (
          <ApartmentImageUploaderForm
            method={"PATCH"}
            open={open}
            setOpen={setOpen}
            instance={instance}
          />
        ) : instance && kind === "commonarea" ? (
          <CommonAreaImageUploaderForm
            method={"PATCH"}
            open={open}
            setOpen={setOpen}
            instance={instance}
          />
        ) : (
          <IndustrialPremisesImageUploaderForm
            method={"PATCH"}
            open={open}
            setOpen={setOpen}
            instance={instance}
          />
        )}
      </div>
    </>
  );
};
