export const durationParse = (durationStamp, verbose) => {
  if (!durationStamp) {
    return undefined;
  }
  const split = durationStamp.split(" ");

  const years = parseInt(split[0]);
  const months = parseInt(split[1]);
  const weeks = parseInt(split[2]);
  const days = parseInt(split[3]);

  let presentation = "-";
  let type = "";

  if (years !== 0) {
    presentation = years;
    type = "år";
  } else if (months !== 0) {
    presentation = months;
    type = "månader";
  } else if (weeks !== 0) {
    presentation = weeks;
    type = "veckor";
  } else if (days !== 0) {
    presentation = days;
    type = "dagar";
  }

  if (verbose) {
    return `${presentation} ${type}`;
  }

  return { presentation, type };
};
