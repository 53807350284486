import * as React from "react";

import SelectPremisesType from "./SelectPremisesType";
import IndustrialPremisesSelect from "./IndustrialPremisesSelect";
import ApartmentSelect from "./ApartmentSelect";
import CommonAreaSelect from "./CommonAreaSelect";
import StandardModal from "../../Modals/StandardModal";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { BLUEPRINT_PREMISE_OPTIONS } from "../BlueprintDisplay/Controller";

const STEPS = {
  SELECT_PREMISES_TYPE: "SELECT_PREMISES_TYPE",
  SELECT_NEW_OR_EXISTING: "SELECT_SELECT_NEW_OR_EXISTINGNEW_OR_OLD",
  SELECT_INDUSTRIAL_PREMISES: "SELECT_INDUSTRIAL_PREMISES",
  SELECT_APARTMENT: "SELECT_APARTMENT",
  SELECT_COMMON_AREA: "SELECT_COMMON_AREA",
};

export default ({ buildingId, connect, closeFunction, isOpen, loading }) => {
  const [currentStep, setCurrentStep] = React.useState(
    STEPS.SELECT_PREMISES_TYPE
  );
  const [premisesType, setPremisesType] = React.useState();
  const [selectedPremises, setSelectedPremises] = React.useState();

  const handleSaveConnection = () => {
    connect({
      selectedPremises,
      premisesType,
    });
  };

  const handleSelectPremisesType = (type) => {
    setPremisesType(type);

    switch (type) {
      case BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES:
        setCurrentStep(STEPS.SELECT_INDUSTRIAL_PREMISES);
        break;
      case BLUEPRINT_PREMISE_OPTIONS.APARTMENT:
        setCurrentStep(STEPS.SELECT_APARTMENT);
        break;

      case BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA:
        setCurrentStep(STEPS.SELECT_COMMON_AREA);
        break;
      default:
        return;
    }
  };

  const selectPremises = (premises) => {
    setSelectedPremises(premises);
  };

  const goBack = () => {
    setSelectedPremises(undefined);
    setCurrentStep(STEPS.SELECT_PREMISES_TYPE);
  };

  return (
    <StandardModal
      isOpen={isOpen}
      closeFunction={closeFunction}
      title="Koppla objekt till planritning"
      withActionBar
      saveFunction={!!selectedPremises && handleSaveConnection}
    >
      {loading && <OverlaySpinner />}
      {currentStep === STEPS.SELECT_PREMISES_TYPE && (
        <SelectPremisesType
          handleSelectPremisesType={handleSelectPremisesType}
          OPTIONS={BLUEPRINT_PREMISE_OPTIONS}
        />
      )}

      {currentStep === STEPS.SELECT_APARTMENT && (
        <ApartmentSelect
          goBack={goBack}
          selectPremises={selectPremises}
          buildingId={buildingId}
          selectedPremises={selectedPremises}
          saveConnection={handleSaveConnection}
        />
      )}
      {currentStep === STEPS.SELECT_INDUSTRIAL_PREMISES && (
        <IndustrialPremisesSelect
          goBack={goBack}
          selectPremises={selectPremises}
          buildingId={buildingId}
          selectedPremises={selectedPremises}
          saveConnection={handleSaveConnection}
        />
      )}
      {currentStep === STEPS.SELECT_COMMON_AREA && (
        <CommonAreaSelect
          goBack={goBack}
          selectPremises={selectPremises}
          buildingId={buildingId}
          selectedPremises={selectedPremises}
          saveConnection={handleSaveConnection}
        />
      )}
    </StandardModal>
  );
};
