import styled, { css } from "styled-components";

export const TableWrapper = styled.div`
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: 24px 0;
`;

export const InnerTableWrapper = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: "Inter", sans-serif;
  min-width: 100%;

  & th,
  td {
    padding: 12px 14px;
    vertical-align: middle;
    display: table-cell;
    white-space: nowrap;
  }

  & thead tr {
    background-color: ${(p) => p.theme.colors.activeGray};
    color: ${(p) => p.theme.colors.primary};
    text-align: left;
    white-space: nowrap;
  }

  & tbody tr:nth-of-type(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }

  & tbody tr {
    border-bottom: thin solid #dddddd;

    transition: color, background-color 50ms ease-in-out;

    &:hover {
      background-color: #eff2ff;
      color: ${(p) => p.theme.colors.textPrimary};
    }

    &.active-row {
      background-color: ${(p) => p.theme.colors.blueLight};
      color: ${(p) => p.theme.colors.blueDark};

      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.blueDark};
      }
    }

    &.clickable-row {
      cursor: pointer;
    }
  }
`;

export const Field = styled.div``;

export const SearchBoxWrapper = styled.div`
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  max-width: 550px;
  margin-top: 24px;
  overflow: hidden;
`;

export const SearchInput = styled.input.attrs({
  type: "text",
})`
  width: 100%;
  border: 0;
  padding: 12px 14px;
  font-weight: 500;
  background-color: #eceff1;
  color: #69707d;
  font-size: 1rem;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  ${(p) =>
    p.fullscreenModeActive &&
    css`
      border-top: 1px solid ${(p) => p.theme.colors.fadedBorder};
      position: fixed;
      bottom: 0;
      background-color: white;
      z-index: 99999999;
      width: 100vw;
      left: 0;
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  color: ${(p) => p.theme.colors.primary};
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-flex;
  padding: 12px;
  font-weight: 600;
`;

export const PaginationInput = styled.input.attrs()`
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  position: relative;
  border-radius: 5px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: ${(p) => p.theme.spacing(2)};
  width: 48px;
  height: 36px;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  &:required {
    box-shadow: none;
  }
`;

export const SelectWrapper = styled.div`
  width: 120px;
`;
