import React from "react";
import * as SharedStyles from "../../components/sharedStyles";
import { useHistory, useParams } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle/PageTitle";
import {
  useUserGroup,
  useUserGroupForm,
  update,
  constants,
} from "../../store/userGroups";
import { OverviewTitle } from "../../components/Details/OverviewInfo/styles";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "../../components/Toggle/Toggle";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/Forms/Base/Buttons";
import { setActiveFormInstance, useFormField } from "../../store/base";
import { cloneDeep } from "lodash";
import { setWorkableObject } from "../../store/userGroups/store/actions";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";

import WhiteListTable from "../../components/Details/Account/UserGroups/WhiteListTable";
import DetailPermsTable from "../../components/DetailPermsTable/DetailPermsTable";
import {
  addCurrentObject,
  getCurrentRows,
  performUpdate,
  setRows,
} from "../../store/whitelistPerms/store/actions";

const WHITELIST_CONTENT_TYPES = [
  // "workorder",
  "roundingerrandsetting", //klar
  "inspectionerrandsetting", //klar
  "reporterrandsetting", //klar
  "roundingerrand", //klar
  "inspectionerrand", //klar
  "reporterrand", //klar
  // "calendar",
  // "todo",
  // "signee",
  "editabledoc", //klar
  "blueprint",
  // "room",
  "basicdoc", //klar
  // "key",
  // "parkingspecification",
  // "parkingzone", //klar SAKNAR DOCK COLUMNDEFS
  "parkinglot", //klar
  "parkingspot", //klar
  "servicepartner", //klar
  "company", //klar
  "realestate", //klar
  "building", //klar
  "apartment", //klar
  "industrialpremises", //klar
  "commonarea", //klar
  "leasecontract", //klar
  "othercontract", //klar
  "parkingcontract", //klar
  "servicecontract", //klar
];

export default function GroupContentTypeDetail() {
  const dispatch = useDispatch();
  const { contentType, groupId } = useParams();
  const { push } = useHistory();
  const [group] = useUserGroup(groupId);

  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectorOpen, setSelectorOpen] = React.useState(false);
  const [activeActions, setActiveActions] = React.useState({
    view: true,
    change: false,
    delete: false,
  });
  const [savePermsLoading, setSavePermsLoading] = React.useState(false);

  const whiteList = WHITELIST_CONTENT_TYPES.includes(contentType);
  const readableContentType = getReadablePermissionType(contentType);

  const workableObjects = useSelector(
    (state) => state.userGroups.workableObjects
  );

  const formLoaded = useUserGroupForm("PATCH", groupId);

  const possibleChangedPermissions = useFormField({
    storeName: constants.STORE_NAME,
    method: "PATCH",
    fieldKey: "permissions._choices",
  });

  const current = useSelector((state) => state.whitelistPerms?.current);

  React.useEffect(() => {
    dispatch(addCurrentObject({ groupId, contentType }));
  }, []);

  React.useEffect(() => {
    if (whiteList) {
      dispatch(getCurrentRows({ groupId, contentType }));
    }
  }, []);

  const handleSavePermissions = (newGroup) => {
    //clear permissions that can't be touched;
    if (!possibleChangedPermissions) return;
    newGroup.permissions = newGroup.permissions.filter((p) => {
      const allowed = possibleChangedPermissions.find((pp) => pp.v === p);

      return allowed;
    });

    setLoading(true);
    dispatch(
      setActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: newGroup,
      })
    );

    dispatch(
      update({
        id: newGroup.id,
        successCallback: () => {
          window.scrollTo(0, 0);
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  const buildWorkableObjects = () => {
    if (!possibleChangedPermissions?.length > 0) return;
    let workable = {};
    possibleChangedPermissions.forEach((pp) => {
      // split action and permission type
      let [action, permissionType, ...rest] = pp.v.split("_");

      // join the rests, usually subtypes of the permission type
      rest = rest.join("_");

      // build new obj
      const newObj = {
        permissionType,
        action,
        rest,
        original: pp.v,
        active: group?.permissions?.find((p) => p === pp.v),
        readablePermissionType: getReadablePermissionType(permissionType),
        readableActionType: getReadableActionType(action),
        readableRest: getReadableRest(rest),
      };

      if (workable[permissionType]) {
        workable[permissionType].push(newObj);
      } else {
        workable[permissionType] = [newObj];
      }
    });
    if (whiteList) {
      handleActiveActions(workable[contentType]);
    }
    dispatch(setWorkableObject(workable));
  };

  const handleBlockDetailPermAction = (action, active) => {
    const rowClone = cloneDeep(current.rows);

    rowClone.forEach((r) => {
      if (action === "view") {
        r.operations.view = active ? false : true;
      }
      if (action === "change") {
        r.operations.change = active ? false : true;
      }
      if (action === "delete") {
        r.operations.delete = active ? false : true;
      }
    });
    dispatch(setRows({ newRows: rowClone }));
    handleSaveDetailPerms();
  };

  const handleTogglePerm = (permObject) => {
    if (whiteList) {
      handleBlockDetailPermAction(permObject.action, permObject.active);
    }
    const permId = permObject.original;
    const groupClone = cloneDeep(group);

    let groupCurrentPermissions = group.permissions;

    if (groupCurrentPermissions.includes(permId)) {
      groupCurrentPermissions = groupCurrentPermissions.filter(
        (p) => p !== permId
      );
    } else {
      groupCurrentPermissions.push(permId);
    }

    groupClone.permissions = groupCurrentPermissions;

    handleSavePermissions(groupClone);
  };

  React.useEffect(() => {
    buildWorkableObjects();
  }, [possibleChangedPermissions, group]);

  const handleActiveActions = (workable) => {
    const editableItems = workable
      .map((w) => {
        if (
          w.action === "view" ||
          w.action === "change" ||
          w.action === "delete"
        ) {
          return w;
        }
      })
      .filter((i) => i);
    const tempActiveActions = cloneDeep(activeActions);
    editableItems.forEach((i) => {
      if (i.action === "view") {
        tempActiveActions.view = i.active ? true : false;
      }
      if (i.action === "change") {
        tempActiveActions.change = i.active ? true : false;
      }
      if (i.action === "delete") {
        tempActiveActions.delete = i.active ? true : false;
      }
    });
    setActiveActions(tempActiveActions);
  };

  const getItems = () => {
    let items = workableObjects[contentType];
    setItems(items);
  };

  React.useEffect(() => {
    if (!workableObjects) {
      push(`/configcenter/groups-and-users/group/${groupId}`);
    }
    if (workableObjects) {
      getItems();
    }
  }, [workableObjects]);

  const breadCrumbs = [
    {
      label: "Användare & behörigheter",
      url: "/configcenter/groups-and-users/group",
    },
    {
      label: group ? group.name : "Laddar...",
      url: `/configcenter/groups-and-users/group/${group?.id}`,
    },
    {
      label: contentType ? readableContentType : "Laddar...",
    },
  ];

  const handleDetailPermToggled = (obj, action) => {
    const rowClone = cloneDeep(current.rows);

    const match = rowClone.find((r) => r.object_id === obj.object_id);
    switch (action) {
      case "VIEW":
        match.operations.view = !match.operations.view;
        break;
      case "CHANGE":
        match.operations.change = !match.operations.change;
        break;
      case "DELETE":
        match.operations.delete = !match.operations.delete;
        break;

      default:
        return;
    }

    dispatch(setRows({ newRows: rowClone }));
  };

  const onSuccess = () => {
    setSavePermsLoading(false);
  };

  const onError = () => {
    setSavePermsLoading(false);
  };

  const handleSaveDetailPerms = () => {
    setSavePermsLoading(true);
    dispatch(
      performUpdate({ successCallback: onSuccess, errorCallback: onError })
    );
  };

  const detailPermsHeaders = ["Objektsnamn", "Visa", "Ändra", "Ta bort"];

  let errorRowIndexes = [];

  const detailPermsRows = current?.rows;

  return (
    <SharedStyles.BareLayoutWrapper>
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle
          title={`Kategori ${readableContentType || ""}`}
          breadCrumbs={breadCrumbs}
        />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitle>Grundläggande behörigheter</OverviewTitle>
        {items &&
          items.map((i) => {
            return (
              <Toggle
                disabled={loading}
                isRedGreen
                extraStyle={{ margin: "4px 0" }}
                value={i.active}
                onToggle={() => {
                  handleTogglePerm(i);
                }}
                title={`${i.readableActionType}${
                  i.readableRest ? ` - ${i.readableRest}` : ""
                }`}
              />
            );
          })}
      </SharedStyles.DetailPageBox>

      {whiteList && (
        <SharedStyles.DetailPageBox>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <OverviewTitle>Specifika objekt</OverviewTitle>
            <PrimaryButton
              title="Hantera objekt"
              clicked={() => setSelectorOpen(true)}
            />
            {detailPermsRows?.length > 0 && (
              <SecondaryButton
                title="Spara"
                clicked={() => handleSaveDetailPerms()}
              />
            )}
            {savePermsLoading && <OverlaySpinner />}
          </div>

          <WhiteListTable
            contentType={contentType}
            groupId={groupId}
            isOpen={selectorOpen}
            closeFunction={setSelectorOpen}
          />
          <DetailPermsTable
            extraContainerStyles={{ marginTop: 40 }}
            headers={detailPermsHeaders}
            rows={detailPermsRows}
            loading={false}
            errorRowIndexes={errorRowIndexes}
            handleDetailPermToggled={handleDetailPermToggled}
            activeActions={activeActions}
          />
        </SharedStyles.DetailPageBox>
      )}
    </SharedStyles.BareLayoutWrapper>
  );
}

const getReadablePermissionType = (permissionType) => {
  switch (permissionType) {
    case "tenant":
      return "Hyresgäst";
    case "tenantrevenue":
      return "Omsättningsbaserad hyra";
    case "user":
      return "Användare";
    case "component":
      return "Komponenter";
    case "message":
      return "Meddelanden";
    case "notification":
      return "Notiser";
    case "permissiondetail":
      return "Behörigheter - detaljer";
    case "permissionrule":
      return "Behörigheter - regler";
    case "apartment":
      return "Bostadslägenheter";
    case "basicdoc":
      return "Dokument";
    case "blueprint":
      return "Planritningar";
    case "building":
      return "Byggnader";
    case "commonarea":
      return "Gemensamma ytor";
    case "company":
      return "Företag";
    case "editabledoc":
      return "Mallar & Avtalsdokument";
    case "industrialpremises":
      return "Lokaler";
    case "key":
      return "Nycklar";
    case "leasecontract":
      return "Hyresavtal";
    case "othercontract":
      return "Övriga avtal";
    case "premisesimages":
      return "Bilder på lokaler & lägenheter";
    case "realestate":
      return "Fastigheter";
    case "room":
      return "Rum";
    case "servicecontract":
      return "Serviceavtal";
    case "servicepartner":
      return "Servicepartner";
    case "inspectionerrand":
      return "Besiktningar";
    case "inspectionerrandsetting":
      return "Besiktningstyper";
    case "reporterrand":
      return "Felanmälningar";
    case "reporterrandsetting":
      return "Felanmälningstyper";
    case "roundingerrand":
      return "Ronderingar & myndighetskrav";
    case "roundingerrandsetting":
      return "Rondering & myndighetskravtyper";
    default:
      return permissionType;
  }
};

const getReadableActionType = (actionType) => {
  switch (actionType) {
    case "view":
      return "Visa";
    case "add":
      return "Lägga till";
    case "change":
      return "Ändra & redigera";
    case "delete":
      return "Ta bort";
    case "handle":
      return "Hantera";
    case "tenant":
      return "Hyresgäst";
    default:
      return actionType;
  }
};

const getReadableRest = (rest) => {
  switch (rest) {
    case "email_invoice":
      return "E-post fakturering";
    case "invoice_address":
      return "Fakturaadress";
    case "email_invoice_cc":
      return "E-post fakturering cc";
    case "email_invoice_bcc":
      return "E-post fakturering bcc";
    case "portal":
      return "Mina sidor";
    case "note":
      return "Anteckningar";
    case "cancel":
      return "Uppsägning";
    case "address":
      return "Adress";
    case "bankgiro":
      return "Bankgiro";
    case "image":
      return "Bilder";
    case "plusgiro":
      return "Plusgiro";
    case "not_read_by":
      return "Ej läst av";
    case "cost":
      return "Kostnader";
    case "guarantee_ends_at":
      return "Garantier";
    case "product":
      return "Produkter";

    case "purchased_at":
      return "Inköpsdatum";

    case "corporate_name":
      return "Bolagsnamn";

    case "email":
      return "E-post";
    case "first_name":
      return "Förnamn";
    case "last_name":
      return "Efternamn";
    case "legal_id":
      return "Personnummer / Orgnr";
    case "phone":
      return "Telefon";
    case "recipients":
      return "Mottagare";
    default:
      return rest;
  }
};
