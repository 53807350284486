import * as React from "react";

import columnDefs from "./ku55ConfigListDefs";

// store, state
import { constants } from "../../../store/brfPremises";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";

export default ({
  persistantQuery,
  checkRowHighlighted,
  onRowClicked,
  ignoreLocalStorage,
  isBare,
  onExcludeClicked,
  showAsInclude,
  tableId,
}) => {
  // const filterInstructions = {
  //   premises_id: { operator: "icontains" },
  //   internal_premises_id: { operator: "icontains" },
  //   "address.str_representation": { operator: "icontains" },
  //   "building.str_representation": { operator: "icontains" },
  //   "realestate.str_representation": { operator: "icontains" },
  //   area: { handler: (filterObject) => buildRangeFilter(filterObject) },
  // };

  const columns = React.useMemo(
    () => columnDefs({ onExcludeClicked, showAsInclude }),
    [persistantQuery, onExcludeClicked, showAsInclude]
  );

  const filters = {};
  const badges = {};

  return (
    <BaseTable
      tableId={tableId || "brfpremises_ku55_full_table"}
      title="Objekt för KU55 export"
      {...{
        checkRowHighlighted,
        onRowClicked,
        ignoreLocalStorage,
        isBare,
        filters,
        badges,
        constants,
        columns,
        persistantQuery,
      }}
    />
  );

  // return (
  //   <BaseTableOld
  //     storeName={storeName}
  //     columns={columns}
  //     tableType="brfpremises"
  //     persistantQueryString={persistantQueryString}
  //     paginationMethod={useBrfPremisesPagination}
  //     filterInstructions={filterInstructions}
  //     localStorageHiddenColumnId={localStorageHiddenColumnId}
  //     detailUrlMethod={(obj) => {}}
  //     title={hideTitle ? undefined : "Bostadsrätter"}
  //     {...{
  //       hideFilters,
  //       hideExport: true,
  //       hideSearch,
  //       hideColumns: true,
  //       withPersistantSortBy,
  //       withPersistantGlobalFilter,
  //       externalFilters,
  //       persistantCategories,
  //       clearCategories,
  //     }}
  //   />
  // );
};
