import * as React from "react";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import { useFormInstanceField } from "../../../../../store/base";
import { useFilteredRealEstates } from "../../../../../store/realEstates";
import { constants } from "../../../../../store/parkingContracts";
import { constants as parkingSpotConstants } from "../../../../../store/parkingSpots";

import ParkingSpotChildren from "../../../ParkingSpot/NestedChildren";

import NewContractGeneration, {
  NEW_CONTRACT_GENERATION_KINDS,
} from "../../../../Details/Apartment/NewContractGeneration";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import { useDispatch } from "react-redux";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const { filteredRealEstates } = useActiveFilters();
  const storeName = constants.STORE_NAME;

  const [realEstates] = useFilteredRealEstates();

  const proposalCopyIsDone = useFormInstanceField({
    storeName,
    fieldKey: "_proposalIsDone",
  });

  const selectedParkingSpots = useFormInstanceField({
    storeName,
    fieldKey: "parking_spots",
  });

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  const getParkingSpotChildren = (parentPath) => {
    const parentInstructionsPath = "parking_spots";

    return (
      <ParkingSpotChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          tableStoreName={parkingSpotConstants.STORE_NAME}
          fieldKey={"parking_spots"}
          method={method}
          persistantQuery={persistantQuery}
          title={"Fordonsplatser"}
          isMany
          placeholder={"Välj fordonsplatser..."}
          TableComponent={ParkingSpotsTable}
          createDisplayKey="title"
        >
          {(parentPath) => getParkingSpotChildren(parentPath)}
        </TableSelectFieldWithCreate>
      </div>

      {selectedParkingSpots?.length === 1 && (
        <NewContractGeneration
          kind={NEW_CONTRACT_GENERATION_KINDS.PARKING_SPOT}
          isDone={proposalCopyIsDone}
          premises={selectedParkingSpots?.[0]}
          onDone={() => {
            dispatch(
              addToast({
                type: TOAST_TYPES.INFO,
                title: "Föreslagna värden klistrades in",
                description:
                  "Kontrollera att värdena stämmer överens med vad som ska gälla för avtalet",
              })
            );
          }}
          skipRedirect
          overrideBtnTitle="Hämta inställningar från valt objekt"
        />
      )}
    </>
  );
};
