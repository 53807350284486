import * as React from "react";

export default () => {
  return (
    <div>
      Bestämmelser för indexuppräkning.
      <br />
      <br /> Bestämmelser för indexuppräkning. Välj om uppräkningen ska ske med
      KPI (1980 = 100) eller utifrån en fast procentsats.
    </div>
  );
};
