import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  margin-bottom: 24px;
`;

export const GridItem = styled.div`
  position: relative;
  background-color: white;
  min-height: 200px;
  padding: 12px;
  padding-bottom: 46px;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
`;

export const IntegrationHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 8px;
  margin-bottom: 16px;
`;

export const IntegrationFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: ${(p) => p.theme.borders.standard};
`;

export const IntegrationLogo = styled.div`
  height: 55px;
  width: 80px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border-radius: 3px;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.image});
`;

export const IntegrationTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const IntegrationTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  line-height: 110%;
`;
export const IntegrationSubtitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const IntegrationDescription = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
  text-overflow: ellipsis;
`;
