import * as React from "react";
import * as SC from "./styles";

export default ({ onClick, disabled, title, extraStyle }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <SC.OutlookButton
      style={{ ...extraStyle }}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
    </SC.OutlookButton>
  );
};
