import { BLUEPRINT_PREMISE_OPTIONS } from "./BlueprintDisplay/Controller";
import * as Colors from "./Colors";

const OPTION_TO_ATTR = {
  APARTMENT: "apartment",
  INDUSTRIAL_PREMISES: "industrial_premises",
  COMMON_AREA: "common_area",
};

const premisesByRoom = (room, premises, premisesType) => {
  const match = premises?.find((p) => {
    if (
      (p.type === BLUEPRINT_PREMISE_OPTIONS.APARTMENT ||
        premisesType === BLUEPRINT_PREMISE_OPTIONS.APARTMENT) &&
      room.apartment?.id === p.id
    ) {
      return true;
    }
    if (
      (p.type === BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES ||
        premisesType === BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES) &&
      room.industrial_premises?.id === p.id
    ) {
      return true;
    }
    if (
      (p.type === BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA ||
        premisesType === BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA) &&
      room.common_area?.id === p.id
    ) {
      return true;
    }

    return false;
  });
  return match;
};

export default {
  getSelectedProxy(room) {
    const style = `fill: ${Colors.BLUEPRINT_ACTIVE}; opacity: .8`;
    const selector = `[id='${room.id}']`;

    return { style, selector };
  },

  getUnSelectedProxy(room) {
    const style = `fill: ${Colors.BLUEPRINT_UNSELECTED}; opacity: .6`;
    const selector = `[id='${room.id}']`;

    return { style, selector };
  },

  getDisabledProxy(room) {
    const style = `fill:${Colors.BROWN}; opacity: .8`;
    const selector = `[id='${room.id}']`;

    return { style, selector };
  },

  getCommonProxy(room) {
    const selector = `[id='${room.id}']`;
    const style = `fill:${Colors.BLUEPRINT_COMMON}; opacity: .8`;
    return { style, selector };
  },

  getRenovationProxy(room, premises) {
    const selector = `[id='${room.id}']`;

    const colors = {
      0: Colors.RENOVATING_ACTIVE, // active
      1: Colors.RENOVATING_OK, // ok
      2: Colors.RENOVATING_FURTHER_CONTRACT, // further_contract
      3: Colors.RENOVATING_WARNING, // warning
      4: Colors.RENOVATING_UPCOMING, // upcoming
      5: Colors.RENOVATING_NOT_SIGNED, // not_signed
      6: Colors.RENOVATING_CLOSED, // closed
      7: Colors.RENOVATING_CLOSED_RENEW, // closed_renew
      8: Colors.RENOVATING_UNINTERESTING,
      9: Colors.RENOVATING_DANGER, //danger
      10: Colors.RENOVATING_UNKNOWN, //unknown
    };

    const style = `fill: ${colors[premises.state]};opacity: .8`;
    return { style, selector };
  },

  getNormalStateProxy(room, premises) {
    const selector = `[id='${room.id}']`;

    const colors = {
      0: Colors.BLUEPRINT_ACTIVE,
      5: Colors.BLUEPRINT_NOT_SIGNED,
      2: Colors.BLUEPRINT_FURTHER_CONTRACT,
      8: Colors.BLUEPRINT_UNINTERESTING,
      10: Colors.BLUEPRINT_UNKNOWN,
      4: Colors.BLUEPRINT_UPCOMING,
      1: Colors.BLUEPRINT_OK,
      3: Colors.BLUEPRINT_WARNING,
      9: Colors.BLUEPRINT_DANGER,
      6: Colors.BLUEPRINT_CLOSED,
      7: Colors.BLUEPRINT_CLOSED_RENEW,
    };

    const style = `fill: ${colors[premises.state]};opacity: .8`;
    return { style, selector };
  },

  getStateProxy(room, premises) {
    if (!premises) {
      return this.getUnSelectedProxy(room);
    }

    if (
      premises.type === BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA ||
      this.premisesType === BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA
    ) {
      return this.getCommonProxy(room, premises);
    }

    if (premises.renovating) {
      return this.getRenovationProxy(room, premises);
    }

    return this.getNormalStateProxy(room, premises);
  },

  createEditingProxy(room, premises) {
    if (this.selectedRooms.includes(room.id)) {
      return this.getSelectedProxy(room);
    }

    if (premises && premises?.id === this.selectedPremises?.id) {
      return this.getUnSelectedProxy(room);
    }

    if (premises) {
      return this.getDisabledProxy(room);
    }

    return this.getUnSelectedProxy(room);
  },

  createPassiveProxy(room, premises) {
    const roomSelected =
      this.selectedPremises &&
      room[OPTION_TO_ATTR[this.selectedPremises.type || this.premisesType]]
        ?.id == this.selectedPremises.id;

    if (this.selectedPremises && !roomSelected) {
      return this.getDisabledProxy(room);
    }

    return this.getStateProxy(room, premises);
  },

  createCreatingProxy(room, premises) {
    if (this.selectedRooms.includes(room.id)) {
      return this.getSelectedProxy(room);
    }

    if (premises) {
      return this.getDisabledProxy(room);
    }

    return this.getUnSelectedProxy(room);
  },

  createProxies() {
    const proxies = this.currentRooms.map((room) => {
      const premises = premisesByRoom(
        room,
        this.currentBuildingPremises,
        this.premisesType
      );

      if (this.handleBlueprintState === this.BLUEPRINT_STATES.PASSIVE) {
        return this.createPassiveProxy(room, premises);
      }

      if (this.handleBlueprintState === this.BLUEPRINT_STATES.CREATING) {
        return this.createCreatingProxy(room, premises);
      }

      return this.createEditingProxy(room, premises);
    });

    return proxies;
  },

  init({
    currentRooms,
    currentBuildingPremises,
    handleBlueprintState,
    selectedPremises,
    selectedRooms,
    premisesType,

    BLUEPRINT_STATES,
  }) {
    this.currentRooms = currentRooms;
    this.premisesType = premisesType;
    this.currentBuildingPremises = currentBuildingPremises;
    this.selectedPremises = selectedPremises;
    this.selectedRooms = selectedRooms;
    this.handleBlueprintState = handleBlueprintState;
    this.BLUEPRINT_STATES = BLUEPRINT_STATES;

    return this.createProxies();
  },
};
