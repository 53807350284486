import * as React from "react";
import moment from "moment";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { billectaStateMap } from "../../../store/billectaInvoicing";

const DATE_FORMAT = "YYYY-MM-DD";

export default ({ invoices, handleRowClicked, selectedRow, hideSearch }) => {
  const data = React.useMemo(() => {
    let displayedInvoices = [];

    // map extra data
    displayedInvoices = invoices.map((i) => ({
      ...i,
      total: `${(i.InvoicedAmount.Value / 100)?.toLocaleString("sv")} ${
        i.InvoicedAmount.CurrencyCode
      }`,
      totalLeft: `${(i.CurrentAmount.Value / 100)?.toLocaleString("sv")} ${
        i.CurrentAmount.CurrencyCode
      }`,
    }));

    return displayedInvoices;
  }, [invoices]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "Stage",
        Cell: (props) => {
          if (props.row?.original?.IsDisputed) {
            return (
              <StatusLabel state={6}>
                {billectaStateMap[props.value]} (Bestriden)
              </StatusLabel>
            );
          }

          if (props.row?.original?.ActionType === "CreditInvoiceAction") {
            return (
              <StatusLabel state={"Kreditfaktura"}>Kreditfaktura</StatusLabel>
            );
          }
          return (
            <StatusLabel state={billectaStateMap[props.value]}>
              {billectaStateMap[props.value]}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Fakturanummer",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Hyresgäst",
        accessor: "DebtorName",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Belopp",
        accessor: "total",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Kvarvarande belopp",
        accessor: "totalLeft",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Attesterad",
        accessor: "AttestedDate",
        Cell: (props) => {
          return <Field>{props.value ? "Ja" : "Nej"}</Field>;
        },
      },
      {
        Header: "Fakturadatum",
        accessor: "InvoiceDate",
        Cell: (props) => {
          return <Field>{moment(props.value).format(DATE_FORMAT)}</Field>;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "DueDate",
        Cell: (props) => {
          return <Field>{moment(props.value).format(DATE_FORMAT)}</Field>;
        },
      },
      {
        Header: "Skapad",
        accessor: "Created",
        Cell: (props) => {
          return <Field>{moment(props.value).format(DATE_FORMAT)}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        hideSearch={hideSearch}
        columns={columns}
        onRowClicked={handleRowClicked}
        selectedRow={selectedRow?.id}
        tableId="matchinvoicestable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
