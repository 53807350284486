export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGZONE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGZONE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGZONE",
  DESTROY_FORM: "DESTROY_FORM_PARKINGZONE",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGZONE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGZONE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGZONE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGZONE",
  RESET_STATE: "RESET_STATE_PARKINGZONE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGZONE",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PARKINGZONE",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PARKINGZONE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGZONE",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGZONE",

  LIST_URL: "/standard/parkingzone/list/",
  POST_URL: "/standard/parkingzone/",
  PATCH_URL: "/standard/parkingzone/",
  GET_URL: "/standard/parkingzone/",

  STORE_NAME: "parkingZones",
};
