import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default function listDefs(hasBillectaViewPermission) {
  const cols = [
    {
      Header: "Logotyp",
      accessor: "image.get",
      Cell: ({ value }) => (
        <div
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "5px",
            backgroundImage: `url(${value})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Namn",
      accessor: "name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Orgnr",
      accessor: "orgnr",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  if (hasBillectaViewPermission) {
    cols.splice(3, 0, {
      Header: "Fakturering aktiverad",
      accessor: "billecta_id",
      Cell: ({ value }) => (
        <StatusLabel state={value ? 0 : 3}>
          {value ? "Aktiv" : "Inaktiv"}
        </StatusLabel>
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    });
  }

  return cols;
}

//Exporting columDefs as funciton to play nicely with detailPerms logic (components/Details/Account/UserGroups/WhiteListTable.js)

export const companyColumnDefs = () => {
  return [
    {
      Header: "Logotyp",
      accessor: "image.get",
      Cell: ({ value }) => (
        <div
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "5px",
            backgroundImage: `url(${value})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Namn",
      accessor: "name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Orgnr",
      accessor: "orgnr",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fakturering aktiverad",
      accessor: "billecta_id",
      Cell: ({ value }) => (
        <StatusLabel state={value ? 0 : 3}>
          {value ? "Aktiv" : "Inaktiv"}
        </StatusLabel>
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];
};
