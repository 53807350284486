import { cloneDeep } from "lodash";
import baseReducer from "../../base/store/reducer";
import constants from "./constants";

const INITIAL_STATE = {
  all: {},
  allCopy: {},
  inProgress: [],
  paginations: {},
  paginationsCopy: {},
  filtered: {},
  filteredCopy: {},
  backgroundTasks: {},
  hasFetchedAll: false,
  forms: {},
  formInstance: {},
  formErrors: {},
  ads: [], // array of objs
  defaultSettings: {
    date_publish: null,
    access_date: null,
    pilot_mode: null,
    is_paused: false,
    prior_access: false,
    prioritize_customers: false,
    requirement: null,
    dump_in_pipe: null,
    description: "",
    publish_pigello: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.ADD_INDP: {
      return handleAddIndp(newState, payload);
    }

    case constants.ADD_MULTIPLE_INDPS: {
      return handleAddMultipleIndps(newState, payload);
    }

    case constants.REMOVE_INDP: {
      return handleRemoveIndp(newState, payload);
    }

    case constants.SET_ADS: {
      return { ...newState, ads: payload.newAds };
    }

    case constants.SET_DEFAULT_SETTINGS: {
      return { ...newState, defaultSettings: payload.settings };
    }

    default:
      return newState;
  }
};

const handleAddIndp = (state, payload) => {
  const { indp } = payload;
  const stateClone = cloneDeep(state);

  const { ads } = stateClone;

  ads.push({
    ...indp,
  });

  return stateClone;
};

const handleAddMultipleIndps = (state, payload) => {
  const { indps } = payload;

  const stateClone = cloneDeep(state);

  const { ads } = stateClone;

  indps.forEach((a) => {
    ads.push({
      ...a,
    });
  });

  return stateClone;
};

const handleRemoveIndp = (state, payload) => {
  const { indp } = payload;
  const stateClone = cloneDeep(state);

  const { ads } = stateClone;

  const newAds = ads.filter((i) => i.id !== indp.id);
  stateClone.ads = newAds;
  return stateClone;
};
