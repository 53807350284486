import styled, { css } from "styled-components";

export const AllItemsWrapper = styled.div`
  display: flex;
  padding: 12px;
  border-radius: 12px;
`;

export const SingleItemWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: ${(p) => p.theme.borders.dark};
  border-right: none;

  &:nth-child(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }

  &:last-child {
    border-right: ${(p) => p.theme.borders.dark};
  }
`;

export const ActionTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  border-bottom: ${(p) => p.theme.borders.dark};
  position: relative;
  padding: 4px;
  padding-left: 8px;
`;

export const ActionItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const ActionItemTitle = styled.div`
  padding: 4px 8px;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.body};
`;
