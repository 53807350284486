import styled from "styled-components";
import checkCirle from "../../../assets/svg/material/check_circle_blue.svg";

export const CheckCircle = styled.div`
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${checkCirle});
`;

export const PickerWrapper = styled.div`
  display: flex;
`;

export const PickerContainer = styled.div`
  display: flex;
  border: thin solid rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  flex: 1;
  padding-bottom: 12px;
`;
