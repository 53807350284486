export const constructStrRep = (obj) => {
  return obj.name;
};
export const detailUrl = ({ id, showInvoicingSettingPrompt }) => {
  return `/companies/detail/${id}${
    showInvoicingSettingPrompt ? "?showInvoicingSettingPrompt=true" : ""
  }`;
};

export const overviewUrl = () => {
  return `/companies`;
};

export const activateFortnoxUrl = ({ id }) => {
  return `/companies/detail/${id}/activate-fortnox`;
};
