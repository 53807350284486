import * as React from "react";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../../../store/base";
import Table from "../../../../Billecta/Table/BasicTable";
import { constants } from "../../../../../store/reportErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useDispatch } from "react-redux";
import { toMoneyString } from "../../../../utils/stringUtils";
import { DateCell } from "../../../../Displays";
import { cloneDeep } from "lodash";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import { PrimaryButton } from "../../../Base/Buttons";
import { InnerBox } from "../../../../sharedStyles";
import { useFilteredErrandCosts } from "../../../../../store/errandCosts";
import HandleErrandCost from "../../../../Details/OverviewInfo/Costs/ErrandCosts/HandleErrandCost";
import { ERRAND_LIABLE_MAP } from "../../../../Details/OverviewInfo/Costs/ErrandCosts/CostSummary";

export default () => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [handleCostIndex, setHandleCostIndex] = React.useState(null);
  const [hasSetInitial, setHasSetInitial] = React.useState(false);

  const formCosts = useFormInstanceField({
    storeName,
    fieldKey: "costs",
  });

  const costIds = formCosts?.filter((c) => c.id)?.map((c) => c.id);

  const q = buildQueryString({
    id__in: costIds || [],
  });

  const [currentCosts, currentCostsLoading] = useFilteredErrandCosts(q);

  // only set costs if all have an id, else we have done changes
  React.useEffect(() => {
    if (
      !hasSetInitial &&
      currentCosts?.length > 0 &&
      currentCosts?.length === formCosts?.length &&
      currentCosts?.every((c) => !!c.id)
    ) {
      setHasSetInitial(true);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            costs: currentCosts,
          },
        })
      );
    }
  }, [hasSetInitial, currentCosts]);

  const costErrors = useFormError({
    storeName,
    fieldKey: "costs",
  });

  const tableCosts = React.useMemo(() => {
    if (!formCosts) return [];

    return formCosts;
  }, [formCosts]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Ansvarig",
        accessor: "liable_display",
        Cell: ({ value, row }) => {
          return <div>{value || ERRAND_LIABLE_MAP[row.original?.liable]}</div>;
        },
        TotalFooter: () => {
          return <div style={{ fontWeight: 600 }}>Total:</div>;
        },
      },
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Artikel",
        accessor: "article.str_representation",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Antal",
        accessor: "unit_amount",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Pris/enhet",
        accessor: "unit_cost",
        Cell: ({ value }) => {
          return <div>{toMoneyString(value, true)}</div>;
        },
      },
      {
        Header: "Summa",
        id: "sum",
        Cell: ({ row }) => {
          return (
            <div>
              {toMoneyString(
                (row.original.unit_amount || 0) * (row.original.unit_cost || 0),
                true
              )}
            </div>
          );
        },
        TotalFooter: ({ row }) => {
          const totalSum = formCosts?.reduce((acc, cur) => {
            const curTot = cur.unit_cost * cur.unit_amount;

            return acc + curTot;
          }, 0);

          return (
            <div style={{ fontWeight: 600 }}>
              {toMoneyString(totalSum, true)}
            </div>
          );
        },
      },
      {
        Header: "Momssats (%)",
        accessor: "vat",
        Cell: ({ value }) => {
          return <div>{value}%</div>;
        },
      },
      {
        Header: "Tillagd",
        accessor: "created_at",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
    ];

    return cols;
  }, [formCosts]);

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  const handleEditCost = (row) => {
    setHandleCostIndex(row.index);
  };

  const handleAddCost = () => {
    const costsCopy = [...(formCosts || [])];
    const newIndex = costsCopy.length;

    costsCopy.push({ order: newIndex + 1 });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          costs: costsCopy,
        },
      })
    );

    setHandleCostIndex(newIndex);
  };

  const handleRemoveCost = (index) => {
    const costsCopy = cloneDeep(formCosts);

    costsCopy.splice(index, 1);

    setHandleCostIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          costs: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );
  };

  const handleEditCostDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostIndex(null);
  };

  return (
    <>
      <HandleErrandCost
        costs={formCosts}
        handleCostIndex={handleCostIndex}
        closeFunction={() => setHandleCostIndex(null)}
        onUpdate={handleEditCostDone}
        method={"PATCH"}
        storeName={storeName}
        onRemove={() => handleRemoveCost(handleCostIndex)}
        modalInModal
      />

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Kostnader</OverviewTitle>
          <OverviewSubtitle>
            Ange kostnader som uppstod vid utförande
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>

        <PrimaryButton title="Lägg till" clicked={handleAddCost} />
      </OverviewTitleWrapper>
      {formCosts?.length > 0 ? (
        <Table
          onRowClicked={handleEditCost}
          columns={tableColumns}
          data={tableCosts}
          checkRowError={checkRowError}
          hideSearch
          withTotalSummaryFooter
        />
      ) : (
        <InnerBox
          style={{
            marginBottom: 12,
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            Inga kostnader är tillagda än
            <div style={{ marginTop: 24 }}>
              <PrimaryButton
                title="Lägg till kostnad"
                clicked={() => handleAddCost()}
              />
            </div>
          </>
        </InnerBox>
      )}
    </>
  );
};
