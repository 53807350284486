import * as React from "react";
import { buildQueryString } from "../../../store/base";
import { useFilteredParkingLots } from "../../../store/parkingLots";

import {
  AddressField,
  AsyncSelect,
  NumberInput,
  TextInput,
} from "../Base/Fields";
import useActiveFilters from "../../../hooks/useActiveFilters";
import TableSelectField from "../Base/Fields/TableSelectField";
import ParkingSpecificationsTable from "src/components/Tables/Parking/ParkingSpecifications/FullTable";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  const { filteredRealEstates } = useActiveFilters();

  const realEstateFilter = buildQueryString({
    realestate_ids: filteredRealEstates,
  });

  return (
    <>
      <TextInput
        storeName={storeName}
        fieldKey={`${parentPath}.title`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.title`}
        title="Plats nr."
      />

      <AddressField
        title="Huvudsaklig adress"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.address`}
        instructionsKey={`${parentInstructionsPath || parentPath}.address`}
      />

      <NumberInput
        title="Bashyra (SEK/månad)"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.rent`}
        instructionsKey={`${parentInstructionsPath || parentPath}.rent`}
      />

      <AsyncSelect
        title="Parkeringsområde"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.parking_lot`}
        instructionsKey={`${parentInstructionsPath || parentPath}.parking_lot`}
        fetchMethod={() => useFilteredParkingLots(realEstateFilter)}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj specifikation och regler..."
          title="Specifikation och regler"
          method={method}
          fieldKey={`${parentPath}.specification`}
          instructionsKey={`${
            parentInstructionsPath || parentPath
          }.specification`}
          TableComponent={ParkingSpecificationsTable}
        />
      </div>
    </>
  );
};
