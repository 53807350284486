import * as React from "react";

// style, design
import { LinkedObject, TimeCell, ToolTipCell } from "../../Displays";

import { detailUrl as measureTypeUrl } from "../../../store/IMDMeasureType";
import { detailUrl as realEstateUrl } from "../../../store/realEstates";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";

export default () => [
  {
    Header: "Från datum",
    accessor: "from_date",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Pris",
    accessor: "value",
    Cell: ({ value }) => <ToolTipCell text={value.toLocaleString("sv")} />,
    Filter: NumberRangeFilter,
    filter: "between",
  },
  {
    Header: "Mätartyp",
    accessor: "measure_type",
    Cell: ({ value }) => (
      <LinkedObject obj={value} urlMethod={measureTypeUrl} />
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Fastighet",
    accessor: "realestate",
    Cell: ({ value }) => <LinkedObject obj={value} urlMethod={realEstateUrl} />,
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
];
