import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useErrandArticle } from "../../store/errandArticles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ArticleDetailOverview from "./articleDetail/Overview";

export default function ArticleDetail() {
  const { path, url } = useRouteMatch();
  const { id } = useParams();

  const [article] = useErrandArticle(id);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ArticleDetailOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title={`Artikel${
          article?.str_representation ? ` - ${article.str_representation}` : ""
        }`}
        breadCrumbs={[
          {
            label: "Inställningar Teknisk Förvaltning",
            url: "/errand-settings/articles",
          },
          {
            label: article?.str_representation || "Artikel",
          },
        ]}
        eventContentType="errands.article"
        eventObjectId={id}
        eventIdQueryStr="article"
        {...{ subPages: subPageLinks }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}
