import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import CommonAreaOverview from "./detail/Overview";
import CommonAreaImagesAndBlueprints from "./detail/ImagesAndBlueprints";
import CommonAreaIMD from "./detail/IMD";
import CommonAreaKeys from "./detail/Keys";

import { useAllPermissionCheck, usePermissionCheck } from "../../store/base";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { useCommonArea, constants } from "../../store/commonAreas";
import TFPremisesBase from "../../components/TechnicalManagement/PremisesBase";

export default function MainCommonAreaDetail() {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const { path, url } = useRouteMatch();
  const { push } = useHistory();

  const canDelete = usePermissionCheck("delete_can_baseobject");
  const canErrands = useAllPermissionCheck([
    "allow_errand_faults",
    "view_can_reporterrand",
  ]);
  const canImd = useAllPermissionCheck(["allow_imd", "view_can_imd"]);

  const canKeys = useAllPermissionCheck(["allow_keys", "view_can_keys"]);

  const { commonAreaId } = useParams();

  const [commonArea] = useCommonArea(commonAreaId);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Objekt",
      component: CommonAreaOverview,
    },
    {
      path: "/images-and-blueprint",
      label: "Bilder & ritning",
      component: CommonAreaImagesAndBlueprints,
    },
  ];

  if (canErrands) {
    SUB_PAGES.push({
      path: "/errands",
      label: "Teknisk förvaltning",
      component: TFPremisesBase,
    });
  }

  if (canImd) {
    SUB_PAGES.push({
      path: "/imd",
      label: "IMD",
      component: CommonAreaIMD,
    });
  }

  if (canKeys) {
    SUB_PAGES.push({
      path: "/keys",
      label: "Nycklar",
      component: CommonAreaKeys,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel contentType="premises" state={commonArea?.state}>
          {commonArea?.state_display}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      {canDelete && (
        <DeleteModal
          isOpen={deleteModalOpen}
          closeFunction={() => setDeleteModalOpen(false)}
          constants={constants}
          instance={commonArea}
          deletedCallback={() => push("/common-areas")}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Gemensam yta ${
            commonArea?.str_representation || "Laddar..."
          }`}
          breadCrumbs={[
            { url: "/common-areas", label: "Gemensamma ytor" },
            { label: commonArea?.str_representation || "Laddar..." },
          ]}
          eventContentType="standard.commonarea"
          eventObjectId={commonAreaId}
          eventIdQueryStr="commonarea"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
