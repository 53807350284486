import * as React from "react";

// style, design
import * as SC from "../../Fields/styles";

import DescriptionToolTip from "../../Layout/DescriptionToolTip";

export default React.memo(
  ({
    validation,
    noTitle,
    noMargin,
    rowSize,
    disabled,
    children,
    allowNull = true,
    required = false,
    label,
    helpText,
    error,
    id,
    value,
    onUpdate,
    extraStyle = {},
  }) => {
    const onChange = (data) => {
      const isInvalid = validation && !validation(data);
      if (isInvalid) {
        data = allowNull ? null : required ? 0 : "";
      }

      // update
      onUpdate(data);
    };

    return (
      <SC.InputSpacing {...{ noMargin }} style={extraStyle}>
        {!noTitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.InputFieldTitle>
              {label}
              {required ? "*" : ""}
            </SC.InputFieldTitle>
            {helpText && <DescriptionToolTip description={helpText} />}
          </div>
        )}

        <SC.InputFieldWrapper error={!!error}>
          <SC.InputField
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            id={id}
            required={required}
            type="number"
            disabled={disabled}
            {...{ rowSize }}
          />
        </SC.InputFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        {children && children}
      </SC.InputSpacing>
    );
  }
);
