import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import UnconnectedSelect from "../../components/Forms/Base/Fields/UnconnectedSelect";
import NonConnectedDatePicker from "../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { BareLayoutWrapper } from "../../components/sharedStyles";
import { buildQueryString } from "../../store/base";
import { useFilteredFortnoxConfigs } from "../../store/fortnoxConfig";
import { useFilteredFortnoxTransactions } from "../../store/fortnoxTransaction";
import { update as fortnoxAccountUpdate } from "../../store/fortnoxAccount";

import { InnerBox } from "../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";

import { addToast, TOAST_TYPES } from "../../store/toasts";
import { Data } from "../../components/Details/Dashboard/StatsBar/styles";
import ReactApexChart from "react-apexcharts";
import NonConnectedSelect from "../../components/Forms/Base/Old/NonConnected/NonConnectedSelect";
import NonConnectedCheckbox from "../../components/Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import DetailInfo from "../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import FortnoxCredentialForm from "../../components/Forms/FortnoxCredential/ChapterForm/ModalForm";
import FortnoxConfigForm from "../../components/Forms/FortnoxConfig/ChapterForm/ModalForm";

import { TextButton } from "../../components/Forms/Base/Buttons";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

const MAX_TIME_DIFF = 365 * 2;

export default () => {
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [handlingCredential, setHandlingCredential] = React.useState(null);
  const [handlingConf, setHandlingConf] = React.useState(null);
  usePermissionRedirect(["allow_fortnox", "view_can_fortnox"]);

  const [configs, configsLoading] = useFilteredFortnoxConfigs();

  const queryString = !(selectedCompany && selectedStartDate && selectedEndDate)
    ? null
    : buildQueryString({
        period_start__gte: selectedStartDate.format("YYYY-MM-DD"),
        period_end__lte: selectedEndDate.format("YYYY-MM-DD"),
        configuration: selectedCompany.id,
      });

  const [transactions, transactionsLoading] =
    useFilteredFortnoxTransactions(queryString);

  const [includeAccounts, setIncludeAccounts] = React.useState([]);

  React.useEffect(() => {
    if (!transactions) {
      return;
    }

    let result = [];
    transactions.forEach((t) => {
      if (t.account.include_in_netto && !result.includes(t.account.id)) {
        result.push(t.account.id);
      }
    });

    setIncludeAccounts(result);
  }, [selectedCompany?.id, transactionsLoading]);

  const buildTransactionMapping = () => {
    if (!transactions) {
      return null;
    }

    let serieMapping = {};
    transactions.forEach((transaction) => {
      const transactionAccountId = `${transaction.account.id}`;

      // this transaction should not be included - as it's account is not included
      //if (!includeAccounts.includes(transactionAccountId)) { return; }

      // add this directly here
      if (!Object.keys(serieMapping).includes(transactionAccountId)) {
        serieMapping[transactionAccountId] = {
          name: transaction.account.str_representation,
          data: {},
          account: transaction.account,
        };
      } else if (!serieMapping[transactionAccountId]["name"]) {
        serieMapping[transactionAccountId]["name"] =
          transaction.account.str_representation;
        serieMapping[transactionAccountId]["account"] = transaction.account;
      }

      let start = moment(transaction.period_start);
      const end = moment(transaction.period_end);
      let diff = end.diff(start, "days") + 1;

      if (diff <= 0) {
        diff = 1;
      }
      const avgValue = transaction.value / diff;

      if (start > end) {
        start = end;
      }
      while (start <= end) {
        Object.keys(serieMapping).forEach((accountId) => {
          let mapping = serieMapping[accountId];
          if (!mapping) {
            mapping = {
              //"name":transactions[accountId].account.str_representation,
              data: {},
              //"account":transactions[accountId].account
            };
            serieMapping[accountId] = mapping;
          }

          const dateKey = start.format("YYYY-DD-MM");
          let dateValue = mapping["data"][dateKey];
          if (dateValue == null) {
            mapping["data"][dateKey] =
              accountId === transactionAccountId ? avgValue : 0;
          } else {
            mapping["data"][dateKey] +=
              accountId === transactionAccountId ? avgValue : 0;
          }
        });
        start.add(1, "days");
      }
    });

    return serieMapping;
  };

  const transactionMapping = React.useMemo(() => {
    return buildTransactionMapping();
  }, [
    selectedCompany?.id,
    JSON.stringify(includeAccounts),
    selectedStartDate,
    selectedEndDate,
    transactionsLoading,
  ]);

  const buildTransactionSeries = () => {
    const serieMapping = buildTransactionMapping();
    if (!serieMapping) {
      return [];
    }

    let result = [];
    Object.keys(serieMapping).forEach((accountId) => {
      if (!includeAccounts.includes(parseInt(accountId))) {
        return;
      }

      const data = serieMapping[accountId]["data"];
      const keys = Object.keys(data);
      keys.sort((a, b) => moment(a) - moment(b));

      result.push({
        data: keys.map((dateKey) => {
          return { x: dateKey, y: data[dateKey] };
        }),
        name: serieMapping[accountId]["name"],
      });
    });
    return result;
  };

  const transactionSeries = React.useMemo(() => {
    return buildTransactionSeries();
  }, [
    selectedCompany?.id,
    JSON.stringify(includeAccounts),
    selectedStartDate,
    selectedEndDate,
    transactionsLoading,
  ]);

  const buildAccountSummary = () => {
    if (!transactionMapping) {
      return {};
    }

    let result = {};
    Object.values(transactionMapping).forEach((serie) => {
      result[serie.account.id] = {
        account: serie.account,
        total: Object.values(serie.data).reduce((acc, curr) => acc + curr, 0),
      };
    });

    return result;
  };

  const toggleInclude = (account, turnOn) => {
    dispatch(
      fortnoxAccountUpdate({
        id: account.id,
        forceData: { include_in_netto: turnOn },
        successCallback: () => {
          const index = includeAccounts.findIndex((a) => a.id === account.id);
          if (index < 0) {
            setIncludeAccounts([...includeAccounts, account.id]);
          } else {
            setIncludeAccounts(
              includeAccounts.filter((a) => a.id !== account.id)
            );
          }
        },
      })
    );
  };

  const accountSummary = React.useMemo(() => {
    return buildAccountSummary();
  }, [
    selectedCompany?.id,
    JSON.stringify(includeAccounts),
    selectedStartDate,
    selectedEndDate,
    transactionsLoading,
  ]);

  const buildAccountDetail = () => {
    let infoObj = {};

    Object.values(accountSummary).forEach((data) => {
      infoObj[data.account.str_representation] = [
        {
          title: "Total",
          value: data.total,
        },
        {
          title: "Konto",
          value: data.account.account,
        },
        {
          title: "Inkludera i driftnetto",
          value: (
            <NonConnectedCheckbox
              id={data.account.id}
              value={data.account.include_in_netto}
              onChange={(turnOn) => toggleInclude(data.account, turnOn)}
            />
          ),
        },
      ];
    });

    return <DetailInfo infoObj={infoObj} />;
  };

  const buildSummary = () => {
    let total = 0;
    let result = [];
    Object.keys(accountSummary).forEach((accountId) => {
      if (!includeAccounts.includes(parseInt(accountId))) {
        return;
      }

      const data = accountSummary[accountId];
      total += data.total;
      result.push({
        title: data.account.str_representation,
        value: data.total,
      });
    });

    result.push({
      title: "Totalt",
      value: total,
    });

    return <DetailInfo infoObj={{ Summering: result }} />;
  };

  const validateDates = (start, end) => {
    if (start && end && end.diff(start, "days") > MAX_TIME_DIFF) {
      dispatch(
        addToast({
          title: `Intervallet får inte vara längre än ${MAX_TIME_DIFF} dagar långt`,
          type: TOAST_TYPES.ERROR,
        })
      );
      return false;
    }
    if (start && end && end.diff(start, "days") < 0) {
      dispatch(
        addToast({
          title: "Startdatumet måste vara innen slutdatumet",
          type: TOAST_TYPES.ERROR,
        })
      );
      return false;
    }

    return true;
  };

  const performStartDateSelection = (value) => {
    const date = moment(value);
    if (!date._isValid) {
      setSelectedStartDate(null);
      return;
    }
    if (!validateDates(date, selectedEndDate)) {
      return;
    }

    setSelectedStartDate(date);
  };

  const performEndDateSelection = (value) => {
    const date = moment(value);
    if (!date._isValid) {
      setSelectedEndDate(null);
      return;
    }
    if (!validateDates(selectedStartDate, date)) {
      return;
    }

    setSelectedEndDate(date);
  };

  const performCompanySelection = (value) => {
    setSelectedCompany(configs.find((c) => c.id === value));
  };

  const lineChartOptions = {
    chart: {
      type: "area",
      //height: 800,
      //width: 800,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      //type: "datetime",
    },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
    grid: {
      show: false,
    },
  };

  return (
    <BareLayoutWrapper>
      <FortnoxCredentialForm
        isOpen={handlingCredential !== null}
        method={"POST"}
        id={handlingCredential?.id}
        instance={handlingCredential}
        onCheckout={() => setHandlingCredential(null)}
      />
      <FortnoxConfigForm
        isOpen={handlingConf !== null}
        method={"PATCH"}
        id={handlingConf?.id}
        instance={handlingConf}
        onCheckout={() => setHandlingConf(null)}
      />

      <NonConnectedSelect
        choices={configs}
        onUpdate={performCompanySelection}
        label="Företag"
        value={selectedCompany?.id}
        getOptionValue={(o) => o.id}
        getOptionLabel={(o) => o.str_representation}
      />
      <TextButton
        title={"Lägg till fortnox koppling"}
        clicked={() => setHandlingCredential(undefined)}
      />
      <TextButton
        title={"Redigera konfiguration"}
        clicked={() => setHandlingConf(selectedCompany)}
        disabled={!selectedCompany}
      />

      <NonConnectedDatePicker
        id={"start"}
        value={
          !!selectedStartDate ? selectedStartDate.format("YYYY-MM-DD") : null
        }
        label={"Från"}
        onChange={performStartDateSelection}
      />
      <NonConnectedDatePicker
        id={"end"}
        value={!!selectedEndDate ? selectedEndDate.format("YYYY-MM-DD") : null}
        label={"Till"}
        onChange={performEndDateSelection}
      />

      {buildAccountDetail()}

      {buildSummary()}

      <InnerBox style={{ flex: 1, maxWidth: "100%" }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Driftnetto</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <InnerBox style={{ color: "#444A55" }}>
          {!!transactionSeries?.length ? (
            <ReactApexChart
              series={transactionSeries}
              options={lineChartOptions}
              type="area"
              //width={600}
            />
          ) : (
            <Data>Ingen data</Data>
          )}
        </InnerBox>
      </InnerBox>
    </BareLayoutWrapper>
  );
};
