import * as React from "react";
import { useParams } from "react-router";

import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useRoundingArea } from "src/store/roundingAreas";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import ComponentTypesTable from "src/components/Tables/ErrandComponentTypes/FullTable";

export default function RoundingErrandAreaOverview() {
  const { areaId } = useParams();

  const [roundingArea, roundingAreaLoading] = useRoundingArea(areaId);

  return (
    <>
      {roundingAreaLoading && <OverlaySpinner />}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>
              Ronderingsområde {roundingArea?.str_representation || ""}
            </OverviewTitle>
          </OverviewTitleWrapper>

          <div>{roundingArea?.description || "Beskrivning saknas..."}</div>
        </DetailPageBox>

        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ flex: 1, maxWidth: "49%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Fastigheter som ingår i område
              </OverviewTitle>
            </OverviewTitleWrapper>

            <RealEstateTable
              persistantQuery={{
                id__in: roundingArea?.realestates?.map((r) => r.id) || [],
              }}
            />
          </DetailPageBox>

          <DetailPageBox style={{ flex: 1, maxWidth: "49%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Komponenttyper som ingår i område
              </OverviewTitle>
            </OverviewTitleWrapper>

            <ComponentTypesTable
              persistantQuery={{
                id__in: roundingArea?.component_types?.map((c) => c.id) || [],
              }}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
