import * as React from "react";
import * as SC from "./styles";
import { cloneDeep, debounce } from "lodash";
import Stage from "./Stage";
import Toggle from "../../Toggle/Toggle";
import { useParams } from "react-router-dom";

import { DragDropContext } from "react-beautiful-dnd";

import { buildQueryString } from "../../../store/base";
import {
  createManyStages,
  useFilteredStages,
  constants as stageConstants,
} from "../../../store/pipeStages";
import StandardModal from "../../Modals/StandardModal";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import CreateStages from "./CreateStages";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import StageModalForm from "../../Forms/Stage/ChapterForm/ModalForm";
import DeleteModal from "../../Forms/Delete/DeleteModal";
import LeadModalForm from "../../Forms/Lead/ChapterForm/ModalForm";
import { useFilteredLeads, update as updateLead } from "../../../store/leads";
import { DetailPageBox } from "../../sharedStyles";
import LeadDetail from "./LeadDetail";

import "./bgcolor.css";
import ConnectedLeads from "./ConnectedLeads";
import useQuery from "../../utils/useQuery";
import { uid } from "uid";
import { useFilteredApartmentAds } from "../../../store/marketApartmentAds";
import { clearContractCreationData, usePipe } from "../../../store/pipes";
import { useFilteredPipeAttributes } from "../../../store/pipeAttributes";

export default function Pipe() {
  const dispatch = useDispatch();
  const { pipeId } = useParams();
  const stagesQ = buildQueryString({
    pipe: pipeId,
  });
  const [stages, stagesLoading] = useFilteredStages(stagesQ);
  const leadsQ = buildQueryString({
    pipe: pipeId,
    exclude_only_closed_ads: true,
  });
  const [pipe] = usePipe(pipeId);
  const [leads, leadsLoading] = useFilteredLeads(leadsQ);
  const [currentLeadId, setCurrentLeadId] = React.useState(false);
  const [sortedStages, setSortedStages] = React.useState([]);
  const [stagesTopDistance, setStagesTopDistance] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [displayList, setDisplayList] = React.useState(false);
  const [proposedStagesOpen, setProposedStagesOpen] = React.useState(false);
  const [editStageId, setEditStageId] = React.useState(null);
  const [createStageOpen, setCreateStageOpen] = React.useState(false);
  const [loadingStagesCreation, setLoadingStagesCreation] =
    React.useState(false);

  const [pipeAttributeChoices, pipeAttributeChoicesLoading] = useFilteredPipeAttributes(buildQueryString({pipe: pipeId}));

  const [deleteStageId, setDeleteStageId] = React.useState(null);
  const [createLeadOpen, setCreateLeadOpen] = React.useState(false);
  // ads for homeQ leads collection, only apartments
  const adsQ = buildQueryString({
    dump_in_pipe: pipeId,
    closed: false,
    "homeq_id__isnull!": true,
  });
  const [apartmentAds, apartmentAdsLoading] = useFilteredApartmentAds(adsQ);
  const apartmentAdIds = apartmentAds?.map((a) => a.id);

  const query = useQuery();
  const openLead = query.get("open");

  // actual rendered out leads instance, easy to manipulate on drag and such
  const [currentLeadsInstance, setCurrentLeadsInstance] = React.useState(false);
  const [forceUpdateId, setForceUpdateId] = React.useState(null);

  // actual data leads instance, used as source of truth
  const leadsInstance = React.useMemo(() => {
    if (!stages?.length || !leads?.length) return {};
    let leadsObj = {
      version: uid(),
    };

    stages.forEach((s) => {
      leadsObj[s.id] = [];
    });

    const includedLeads = leads.filter((l) => {
      if (searchTerm) {
        if (l.str_representation.toLowerCase()?.includes(searchTerm)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    includedLeads.sort((a, b) => {
      return a.legal_id > b.legal_id ? 1 : b.legal_id > a.legal_id ? -1 : 0;
    });

    includedLeads.forEach((l) => {
      if (leadsObj[l.stage.id]) {
        leadsObj[l.stage.id].push(l);
      }
    });
    return leadsObj;
  }, [leads?.length, stages?.length, searchTerm, forceUpdateId]);

  React.useEffect(() => {
    if (leadsInstance.version !== currentLeadsInstance?.version) {
      setCurrentLeadsInstance(leadsInstance);
    }
  }, [leadsInstance]);

  React.useEffect(() => {
    if (openLead) {
      setCurrentLeadId(parseInt(openLead));
    }
  }, []);

  const stagesRef = React.useRef();

  React.useEffect(() => {
    if (sortedStages?.length !== stages?.length) {
      const stagesClone = cloneDeep(stages || []);
      stagesClone.sort((a, b) => a.order - b.order);

      setSortedStages(stagesClone);
    }
  }, [stages]);

  // clear contract creation stage
  React.useEffect(() => {
    dispatch(clearContractCreationData());
  }, []);

  const getStagesPosition = () => {
    const clientRect = stagesRef.current?.getBoundingClientRect();

    setStagesTopDistance(clientRect?.top + document.body.scrollTop);
  };

  const createDefaultStages = (stages) => {
    setLoadingStagesCreation(true);

    dispatch(
      createManyStages({
        stages: stages.map((s) => ({ ...s, pipe: { id: pipeId } })),
        successCallback: () => {
          setLoadingStagesCreation(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Steg skapades",
              description: "Detta projekt är nu redo att kopplas mot annonser",
            })
          );
        },
        errorCallback: () => {
          setLoadingStagesCreation(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Steg kunde ej skapas",
              description: "Försök igen",
            })
          );
        },
      })
    );
  };

  React.useLayoutEffect(() => {
    getStagesPosition();
    window.addEventListener("resize", debounce(getStagesPosition, 100));

    return () => {
      window.removeEventListener("resize", debounce(getStagesPosition));
    };
  }, []);

  React.useEffect(() => {
    if (!stagesLoading && !stages?.length) {
      setProposedStagesOpen(true);
    } else {
      setProposedStagesOpen(false);
    }
  }, [stages, stagesLoading]);

  const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list, index, element) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

  const onNewLeadAdded = (lead) => {
    const listCopy = { ...currentLeadsInstance };
    const destinationList = listCopy[lead.stage.id];
    listCopy[lead.stage.id] = addToList(destinationList, 0, lead);
    setCurrentLeadsInstance(listCopy);
  };

  const onDragEnd = (result) => {
    if (!result.destination || result.destination?.droppableId == "undefined") {
      return;
    }

    const listCopy = { ...currentLeadsInstance };
    const original = { ...currentLeadsInstance };

    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );

    dispatch(
      updateLead({
        id: result.draggableId,
        preventDefaultToast: true,
        forceData: {
          id: result.draggableId,
          stage: { id: result.destination.droppableId },
        },
        errorCallback: () => {
          setCurrentLeadsInstance(original);
        },
      })
    );

    setCurrentLeadsInstance(listCopy);
  };

  const resetLeadsInstance = () => {
    setForceUpdateId(uid());
  };

  return (
    <>
      <LeadDetail
        lead={leads?.find((l) => l.id === currentLeadId)}
        leads={leads}
        isOpen={!!currentLeadId}
        closeFunction={() => setCurrentLeadId(null)}
        onLeadDetailOpened={(leadId) => setCurrentLeadId(leadId)}
        updateLeadsInstance={resetLeadsInstance}
      />

      <StageModalForm
        method="PATCH"
        id={editStageId}
        isOpen={!!editStageId}
        onCheckout={() => setEditStageId(null)}
      />
      <StageModalForm
        method="POST"
        instance={{ pipe: { id: pipeId }, order: stages?.length + 1 }}
        isOpen={!!createStageOpen}
        onCheckout={() => setCreateStageOpen(false)}
      />

      <DeleteModal
        isOpen={!!deleteStageId}
        instance={stages?.find((s) => s.id === deleteStageId)}
        constants={stageConstants}
        closeFunction={() => setDeleteStageId(null)}
      />

      <LeadModalForm
        method="POST"
        onCheckout={() => setCreateLeadOpen(false)}
        isOpen={createLeadOpen}
        onLeadCreated={onNewLeadAdded}
      />

      <StandardModal
        canClose={false}
        title="Lägg till steg i projekt"
        isOpen={proposedStagesOpen}
        closeFunction={() => setProposedStagesOpen(false)}
      >
        {loadingStagesCreation && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Detta projekt saknar steg</OverviewTitle>
            <OverviewSubtitle>
              Lägg till steg genom att antingen välja Pigellos förslag eller
              skapa egna steg. Steg kan alltid läggas till, tas bort och
              redigeras i efterhand.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <CreateStages
          isHomeQPipe={pipe?.is_homeq_pipe}
          createDefaultStages={createDefaultStages}
        />
      </StandardModal>

      <SC.PipeWrapper>
        <SC.ActionBar>
          {displayList ? (
            <div></div>
          ) : (
            <SC.SearchBarContainer>
              <SC.SearchIcon />
              <SC.SearchBar
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </SC.SearchBarContainer>
          )}

          <div style={{ display: "flex", alignItems: "center" }}>
            <Toggle
              extraTitleStyle={{
                fontSize: 12,
                fontWeight: 500,
                marginRight: 6,
              }}
              title="Visa som lista"
              value={displayList}
              onToggle={() => setDisplayList(!displayList)}
            />
          </div>
        </SC.ActionBar>

        {displayList ? (
          <DetailPageBox
            style={{
              borderRadius: 0,
              marginTop: 0,
              minHeight: window.innerHeight - stagesTopDistance,
            }}
          >
            <ConnectedLeads />
          </DetailPageBox>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <SC.StagesWrapper topDistance={stagesTopDistance} ref={stagesRef}>
              {(leadsLoading || stagesLoading) && <OverlaySpinner />}
              {sortedStages?.map((stage, idx) => (
                <Stage
                  key={stage.id}
                  isSignStage={idx === stages.length - 1 && idx !== 0}
                  isIncomingStage={idx === 0}
                  stage={stage}
                  leads={currentLeadsInstance?.[stage.id] || []}
                  color={STAGE_COLORS[idx]}
                  onEdit={() => setEditStageId(stage.id)}
                  onDelete={() => setDeleteStageId(stage.id)}
                  onCreateLead={() => setCreateLeadOpen(true)}
                  onLeadDetailOpened={(leadId) => setCurrentLeadId(leadId)}
                  updateLeadsInstance={resetLeadsInstance}
                  isHomeQPipe={pipe?.is_homeq_pipe}
                  pipeAttributeChoices={pipeAttributeChoices}
                />
              ))}
              <Stage
                isCreateStage
                onCreate={() => setCreateStageOpen(true)}
                stage={{ name: "Nytt steg" }}
              />
            </SC.StagesWrapper>
          </DragDropContext>
        )}
      </SC.PipeWrapper>
    </>
  );
}

export const STAGE_COLORS = [
  "#304d63",
  "#b2e7e8",
  "#8fb9aa",
  "#f2d096",
  "#ed8975",
  "#ff9190",
  "#534666",
  "#304d63",
  "#b2e7e8",
  "#8fb9aa",
  "#f2d096",
  "#ed8975",
  "#ff9190",
  "#534666",
  "#304d63",
  "#b2e7e8",
  "#8fb9aa",
  "#f2d096",
  "#ed8975",
  "#ff9190",
  "#534666",
  "#304d63",
  "#b2e7e8",
  "#8fb9aa",
  "#f2d096",
  "#ed8975",
  "#ff9190",
  "#534666",
];
