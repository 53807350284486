import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../../../store/base";
import { constants } from "../../../../../store/invoicingBrf";

import { useInvoicingCostCenter } from "../../../../../store/invoicingCostCenters";
import { useInvoicingProject } from "../../../../../store/invoicingProjects";
import { detailUrl as indexDetailUrl } from "../../../../../store/indexsetting";

import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import Table from "../../../../Billecta/Table/BasicTable";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";
import {
  DatePicker,
  RadioGroup,
  RentField,
  Select,
  TextInput,
} from "../../../Base/Fields";
import { InnerBox } from "../../../../sharedStyles";
import { toMoneyString } from "../../../../utils/stringUtils";

import ProductNestedChildrenMethod from "../../../InvoicingProduct/NestedChildren";
import CostCenterNestedChildrenMethod from "../../../InvoicingCostCenter/NestedChildren";
import ProjectNestedChildrenMethod from "../../../InvoicingProject/NestedChildren";
import { StatusLabel } from "../../../../Lists/Base/CompleteList/styles";
import { InfoBox, LinkedObject } from "../../../../Displays";
import { cloneDeep } from "lodash";
import {
  OrderUp,
  OrderDown,
} from "../../../../Details/OverviewInfo/Costs/styles";

import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
import SelectField from "src/components/Forms/Base/Fields/SelectField";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";
import IndexSettingsTable from "src/components/Tables/IndexSetting/FullTable";
import IndexSettingNestedFields from "../../../IndexSetting/NestedFields";

export default ({
  method,
  contract,
  skipInvoicing,
  goBackToInvoicingSetup,
}) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [handleCostIndex, setHandleCostIndex] = React.useState(null);

  const totalArea = contract?.area || null;

  const topRef = React.useRef();

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const costs =
    useFormInstanceField({
      storeName,
      fieldKey: "cost_set",
    }) || [];

  const baseVAT = useFormInstanceField({
    storeName,
    fieldKey: "vat",
  });

  const costErrors = useFormError({
    storeName,
    fieldKey: "cost_set",
  });

  const [defaultProject] = useInvoicingProject(defaultSetting?.project?.id);
  const [defaultCostCenter] = useInvoicingCostCenter(
    defaultSetting?.cost_center?.id
  );

  const goToTop = () => {
    setTimeout(() => {
      topRef.current &&
        topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 150);
  };

  const handleRowDown = (event, row) => {
    event.stopPropagation();

    const instanceClone = cloneDeep(costs);

    const rowOrder = row.order;
    const newOrder = rowOrder + 1;

    const rowThatChangedOrder = instanceClone.find((r) => r.order === rowOrder);
    const rowThatPreviuoslyHadOrder = instanceClone.find(
      (r) => r.order === newOrder
    );

    rowThatChangedOrder.order = newOrder;
    rowThatPreviuoslyHadOrder.order = rowOrder;

    instanceClone.sort((a, b) => {
      return a.order - b.order;
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: instanceClone,
        },
      })
    );
  };

  const handleRowUp = (event, row) => {
    event.stopPropagation();

    const instanceClone = cloneDeep(costs);

    const rowOrder = row.order;
    const newOrder = rowOrder - 1;

    const rowThatChangedOrder = instanceClone.find((r) => r.order === rowOrder);
    const rowThatPreviuoslyHadOrder = instanceClone.find(
      (r) => r.order === newOrder
    );

    rowThatChangedOrder.order = newOrder;
    rowThatPreviuoslyHadOrder.order = rowOrder;

    instanceClone.sort((a, b) => {
      return a.order - b.order;
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: instanceClone,
        },
      })
    );
  };

  const tableCosts = React.useMemo(() => {
    return costs;
  }, [costs]);

  const tableColumns = React.useMemo(() => [
    {
      Header: " ",
      id: "orderOnInvoice",
      Cell: ({ row }) => {
        const rowOrder = row.original.order;
        const allOrders = costs.map((fc) => fc.order);
        const maxUp = Math.max.apply(null, allOrders);
        const maxDown = Math.min.apply(null, allOrders);
        const canDown = rowOrder < maxUp;
        const canUp = rowOrder > maxDown;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {canUp && <OrderUp onClick={(e) => handleRowUp(e, row.original)} />}
            {canDown && (
              <OrderDown onClick={(e) => handleRowDown(e, row.original)} />
            )}
          </div>
        );
      },
    },
    {
      Header: "Debiteras",
      accessor: "do_not_debit",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 8 : 1}>
            {value ? "Debiteras Inte" : "Debiteras"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Typ",
      accessor: "_",
      Cell: ({ row }) => {
        const isDiscount = row.original.value < 0;
        return (
          <StatusLabel state={isDiscount ? 3 : 1}>
            {isDiscount ? "Rabatt" : "Kostnad"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Produkt",
      accessor: "product",
      Cell: ({ value }) => {
        const title = value?.title || "";

        return <div>{title}</div>;
      },
    },
    {
      Header: "SEK/månad",
      accessor: "value",
      Cell: ({ value }) => {
        return <div>{toMoneyString(value || 0)}</div>;
      },
    },
    {
      Header: "Momssats (%)",
      accessor: "vat",
      Cell: ({ value, row }) => {
        const productVat = row.original.product?.vat;
        const val =
          value != null
            ? value
            : productVat != null
            ? `${productVat} (från produkt)`
            : baseVAT != null
            ? `${baseVAT} (från grundmomssats)`
            : "-";
        return <div>{val}</div>;
      },
    },
    {
      Header: "Börjar aviseras för",
      accessor: "start_date",
      Cell: ({ value }) => {
        if (!value) return <div>Löpande</div>;
        return <div>{value}</div>;
      },
    },
    {
      Header: "Slutar aviseras för",
      accessor: "end_date",
      Cell: ({ value }) => {
        if (!value) return <div>Löpande</div>;

        return <div>{value}</div>;
      },
    },
    {
      Header: "Kostnadsställe",
      accessor: "cost_center",
      Cell: ({ value }) => {
        const title = value?.title
          ? value.title
          : defaultCostCenter
          ? `${defaultCostCenter.str_representation} (från inställning)`
          : "-";

        return <div>{title}</div>;
      },
    },
    {
      Header: "Projekt",
      accessor: "project",
      Cell: ({ value }) => {
        const title = value?.title
          ? value.title
          : defaultProject
          ? `${defaultProject.str_representation} (från inställning)`
          : "-";

        return <div>{title}</div>;
      },
    },
    {
      Header: "Indexuppräkning",
      accessor: "indexation",
      Cell: ({ value }) => {
        return <LinkedObject obj={value} urlMethod={indexDetailUrl} />;
      },
    },
  ]);

  const handleEditCost = (row) => {
    setHandleCostIndex(row.index);
  };

  const handleAddCost = () => {
    const costsCopy = [...costs];
    const newIndex = costsCopy.length;
    costsCopy.push({ order: newIndex + 1 });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: costsCopy,
        },
      })
    );

    setHandleCostIndex(newIndex);
  };

  const handleRemoveCost = (index) => {
    const costsCopy = cloneDeep(costs);
    const removedRowOrder = costsCopy[index].order;
    costsCopy.splice(index, 1);

    // fix orders
    if (costsCopy.length) {
      costsCopy.forEach((c) => {
        if (c.order > removedRowOrder) {
          c.order = c.order - 1;
        }
      });
    }

    setHandleCostIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );

    goToTop();
  };

  const handleEditCostDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostIndex(null);

    goToTop();
  };

  const renderCostErrors = () => {
    return (
      <InfoBox
        title="En eller flera kostnadsrader innehåller fel"
        text="Kontrollera de rödmarkerade raderna. Klicka på raden för att se detaljer kring felet"
        boxTheme="warning"
      />
    );
  };

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  if (handleCostIndex != null) {
    return (
      <>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Hantera rad {costs[handleCostIndex]?.title || ""}
          </OverviewTitle>
        </OverviewTitleWrapper>

        <RadioGroup
          title="Debiteras"
          description="Debitera denna kostnad"
          storeName={storeName}
          method={method}
          options={[
            { label: "Ja, Debitera", value: false },
            { label: "Nej, Debitera inte", value: true },
          ]}
          fieldKey={`cost_set[${handleCostIndex}].do_not_debit`}
          instructionsKey="cost_set.do_not_debit"
          defaultValue={false}
        />

        <TextInput
          title="Beskrivning"
          storeName={storeName}
          method={method}
          fieldKey={`cost_set[${handleCostIndex}].title`}
          instructionsKey="cost_set.title"
        />

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectFieldWithCreate
            storeName={storeName}
            TableComponent={InvoicingProductTable}
            createDisplayKey="title"
            placeholder="Välj produkt..."
            title="Produkt"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].product`}
            instructionsKey={"cost_set.product"}
          >
            {(parentPath) =>
              ProductNestedChildrenMethod({
                storeName,
                method,
                parentPath,
                parentInstructionsPath: `cost_set.product`,
              })
            }
          </TableSelectFieldWithCreate>
        </div>

        <RentField
          {...{ method, storeName }}
          fieldKey={`cost_set[${handleCostIndex}].value`}
          instructionsKey="cost_set.value"
          title="Debiteras (SEK)"
          subtitle="Rabatter anges negativt"
          description="Värde på debiteringen i SEK. Rabatter anges negativt, t.ex. -1000"
          area={totalArea}
        />

        <InnerBox style={{ marginBottom: 24 }}>
          <InfoBox
            boxTheme="info"
            text={`Som standard gäller raden under hela avtalet. Välj ett startdatum för att skriva över.`}
          />
          <DatePicker
            noMargin
            title="Startdatum"
            subtitle="Lämna tom om raden gäller från avtalets start"
            description="Kostnaden ska faktureras från detta datum. Lämnas tomt om kostnaden löper med avtalet."
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].start_date`}
            instructionsKey="cost_set.start_date"
          />
        </InnerBox>

        <InnerBox style={{ marginBottom: 24 }}>
          <InfoBox
            boxTheme="info"
            text={`Som standard gäller raden löpande. Välj ett slutdatum för att skriva över.`}
          />
          <DatePicker
            noMargin
            title="Slutdatum"
            subtitle="Lämna tom om raden gäller löpande"
            description="Kostnaden ska faktureras till detta datum. Lämnas tomt om kostnaden är löpande."
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].end_date`}
            instructionsKey="cost_set.end_date"
          />
        </InnerBox>

        {baseVAT != null ? (
          <InnerBox style={{ marginBottom: 24 }}>
            <InfoBox
              boxTheme="info"
              text={`Standardmoms vald på bostadsrätten: ${baseVAT}%. Välj en momssats för att skriva över.`}
            />
            <Select
              noMargin
              title="Momssats"
              description="Momssats som ska appliceras på kostnaden. Lämnas tomt om grundmomssatsen ska appliceras på kostnaden."
              storeName={storeName}
              method={method}
              fieldKey={`cost_set[${handleCostIndex}].vat`}
              instructionsKey="cost_set.vat"
            />
          </InnerBox>
        ) : (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <SelectField
              title="Momssats"
              description="Momssats som ska appliceras på kostnaden. Lämnas tomt om grundmomssatsen ska appliceras på kostnaden."
              storeName={storeName}
              method={method}
              fieldKey={`cost_set[${handleCostIndex}].vat`}
              instructionsKey="cost_set.vat"
            />
          </div>
        )}

        {defaultProject ? (
          <>
            <InnerBox style={{ marginBottom: 24 }}>
              <InfoBox
                boxTheme="info"
                text={`Standardprojekt från den valda aviseringsinställningen: ${defaultProject.str_representation}. Välj ett projekt för att skriva över standardprojektet.`}
              />

              <div className="grid grid-cols-2 gap-6 mb-6">
                <TableSelectFieldWithCreate
                  storeName={storeName}
                  TableComponent={InvoicingProjectsTable}
                  createDisplayKey="title"
                  placeholder="Välj projekt..."
                  title="Projekt"
                  method={method}
                  fieldKey={`cost_set[${handleCostIndex}].project`}
                  instructionsKey={"cost_set.project"}
                >
                  {(parentPath) =>
                    ProjectNestedChildrenMethod({
                      storeName,
                      method,
                      parentPath,
                      parentInstructionsPath: `cost_set.project`,
                    })
                  }
                </TableSelectFieldWithCreate>
              </div>
            </InnerBox>
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectFieldWithCreate
                storeName={storeName}
                TableComponent={InvoicingProjectsTable}
                createDisplayKey="title"
                placeholder="Välj projekt..."
                title="Projekt"
                method={method}
                fieldKey={`cost_set[${handleCostIndex}].project`}
                instructionsKey={"cost_set.project"}
              >
                {(parentPath) =>
                  ProjectNestedChildrenMethod({
                    storeName,
                    method,
                    parentPath,
                    parentInstructionsPath: `cost_set.project`,
                  })
                }
              </TableSelectFieldWithCreate>
            </div>
          </>
        )}

        {defaultCostCenter ? (
          <InnerBox style={{ marginBottom: 24 }}>
            <InfoBox
              boxTheme="info"
              text={`Standardkostnadsställe från den valda aviseringsinställningen: ${defaultCostCenter.str_representation}. Välj ett kostnadsställe för att skriva över standardkostandsstället.`}
            />

            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectFieldWithCreate
                storeName={storeName}
                TableComponent={InvoicingCostCenterTable}
                createDisplayKey="title"
                placeholder="Välj kostnadsställe..."
                title="Kostnadsställe"
                method={method}
                fieldKey={`cost_set[${handleCostIndex}].cost_center`}
                instructionsKey={"cost_set.cost_center"}
              >
                {(parentPath) =>
                  CostCenterNestedChildrenMethod({
                    storeName,
                    method,
                    parentPath,
                    parentInstructionsPath: `cost_set.cost_center`,
                  })
                }
              </TableSelectFieldWithCreate>
            </div>
          </InnerBox>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectFieldWithCreate
                storeName={storeName}
                TableComponent={InvoicingCostCenterTable}
                createDisplayKey="title"
                placeholder="Välj kostnadsställe..."
                title="Kostnadsställe"
                method={method}
                fieldKey={`cost_set[${handleCostIndex}].cost_center`}
                instructionsKey={"cost_set.cost_center"}
              >
                {(parentPath) =>
                  CostCenterNestedChildrenMethod({
                    storeName,
                    method,
                    parentPath,
                    parentInstructionsPath: `cost_set.cost_center`,
                  })
                }
              </TableSelectFieldWithCreate>
            </div>
          </>
        )}

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectFieldWithCreate
            storeName={storeName}
            TableComponent={IndexSettingsTable}
            createDisplayKey="title"
            placeholder="Välj inställning..."
            title="Indexuppräkning"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].indexation`}
            instructionsKey={"cost_set.indexation"}
          >
            {(parentPath) => (
              <IndexSettingNestedFields
                storeName={storeName}
                method={method}
                parentPath={parentPath}
                parentInstructionsPath={"cost_set.indexation"}
              />
            )}
          </TableSelectFieldWithCreate>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextButton
            title="Ta bort rad"
            red
            clicked={() => handleRemoveCost(handleCostIndex)}
          />
          <PrimaryButton title="Klar" clicked={handleEditCostDone} />
        </div>
      </>
    );
  }

  return (
    <>
      {skipInvoicing && (
        <InfoBox
          boxTheme="info"
          title="Bostadsrätten sätts ej upp för avisering"
          text={`Du har valt att ej sätta upp bostadsrätten för avisering. Tryck på "Sätt upp för avisering" för att gå tillbaka och välja aviseringsinställning.`}
          renderButton={{
            title: "Sätt upp för avisering",
            iconType: "arrow-back",
            clicked: goBackToInvoicingSetup,
          }}
        />
      )}

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Inställningar</OverviewTitle>
          <OverviewSubtitle>
            Grundläggande inställningar för kostnader på fakturor
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <Select
        title="Grundmomssats (%)"
        description="Momssats som ska ligga till grund för alla rader som EJ har en momssats specificerad. Om en rad har en momssats specificerad gäller den."
        {...{ storeName, method, fieldKey: "vat" }}
      />

      <hr />
      <OverviewTitleWrapper style={{ scrollMargin: 50 }} ref={topRef}>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Kostnader & Rabatter</OverviewTitle>
          <OverviewSubtitle>
            Tryck på en rad för att redigera eller ta bort den
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {costErrors?.length && renderCostErrors()}

      {costs?.length > 0 ? (
        <>
          <Table
            onRowClicked={handleEditCost}
            columns={tableColumns}
            data={tableCosts}
            checkRowError={checkRowError}
            hideSearch
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <PrimaryButton title="Lägg till rad" clicked={handleAddCost} />
          </div>
        </>
      ) : (
        <InnerBox
          style={{
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Inga kostnader eller rabatter är tillagda än
          <div style={{ marginTop: 24 }}>
            <PrimaryButton title="Lägg till rad" clicked={handleAddCost} />
          </div>
        </InnerBox>
      )}
    </>
  );
};
