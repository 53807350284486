import { cloneDeep } from "lodash";

const TYPES = {
  CONTRACT_INVOICE: "CONTRACT_INVOICE",
  INVOICE: "INVOICE",
};

export default function checkRequired({
  formInstance,
  type,
  setRequired,
  dontValidateStartEndDateContractInvoice,
}) {
  const instance = cloneDeep(formInstance);

  let required = [];

  //check dates
  if (type === TYPES.INVOICE) {
    if (!instance.InvoiceDate) {
      required.push(`"Fakturadatum" saknas`);
    }
    if (!instance.DueDate) {
      required.push(`"Betalas senast" saknas`);
    }
  }

  if (type === TYPES.CONTRACT_INVOICE) {
    if (!instance.RecurrenceDetails?.RecurrenceInterval) {
      required.push(`"Faktureringsintervall" måste anges`);
    }

    if (!instance.PaymentTermsInDays && !instance.PayLastDayOfMonth) {
      required.push(`"Betalningsvillkor dagar" måste anges`);
    }

    if (!dontValidateStartEndDateContractInvoice) {
      if (!instance.RecurrenceDetails?.Start) {
        required.push(`"Startdatum" saknas`);
      }
      if (
        !instance.RecurrenceDetails?.NoEndDate &&
        !instance.RecurrenceDetails?.End
      ) {
        required.push(
          `Ange om perioden saknar slutdatum alternativt ett "Slutdatum"`
        );
      }
    }
  }

  // check delivery method
  if (!instance.DeliveryMethod) {
    required.push(`"Leveransmetod" saknas`);
  }

  // records check
  if (instance.Records?.length) {
    const { Records } = instance;

    Records.forEach((record, index) => {
      const isMessageRow = record.RecordType === "Message";
      if (!record.ArticleDescription || record.ArticleDescription === "") {
        required.push(`Fakturarad ${index + 1} saknar beskrivning`);
      }
      if (!record.ProductPublicId && !isMessageRow) {
        required.push(`Fakturarad ${index + 1} saknar produkt`);
      }

      if (record.VAT == null && !isMessageRow) {
        required.push(`Fakturarad ${index + 1} saknar moms`);
      }

      // record period
    });
  }

  setRequired(required);
}
