import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import ParkingContractList from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersion";
import NewContractGeneration, {
  NEW_CONTRACT_GENERATION_KINDS,
} from "../../../components/Details/Apartment/NewContractGeneration";
import { useParkingSpot } from "../../../store/parkingSpots";
import PrimaryBtn from "../../../components/Forms/Base/Buttons/PrimaryBtn";
import Modal from "../../../components/Forms/Base/Modals/Modal";

export default function ParkingSpotContracts() {
  const { parkingSpotId } = useParams();
  const [parkingSpot] = useParkingSpot(parkingSpotId);
  const [newGenerationOpen, setNewGenerationOpen] = React.useState(false);

  const contractPersistantQuery = {
    parking_spots: parseInt(parkingSpotId),
  };

  const canAdd = usePermissionCheck("add_can_parking");

  return (
    <>
      {newGenerationOpen && (
        <Modal closeFunction={() => setNewGenerationOpen(false)}>
          <NewContractGeneration
            kind={NEW_CONTRACT_GENERATION_KINDS.PARKING_SPOT}
            premises={parkingSpot}
          />
        </Modal>
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Avtal som gäller fordonsplatsen</OverviewTitle>

            {canAdd && (
              <PrimaryBtn onClick={() => setNewGenerationOpen(true)}>
                Ny avtalsgeneration
              </PrimaryBtn>
            )}
          </OverviewTitleWrapper>

          <ParkingContractList
            persistantQuery={contractPersistantQuery}
            isBare
            ignoreLocalStorage
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
