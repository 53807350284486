import * as React from "react";
import { TextButton } from "../Forms/Base/Buttons";
import * as SC from "./styles";

/**
 * Themes : "warning", "info", "ok"
 */
export default function InfoBox({
  boxTheme = "info",
  title,
  text,
  renderText,
  renderButton,
  extraStyles = {},
}) {
  return (
    <SC.InfoBoxContainer {...{ boxTheme }} style={{ ...extraStyles }}>
      <SC.InfoBoxTitle>{title}</SC.InfoBoxTitle>
      <SC.InfoBoxText>{renderText ? renderText() : text}</SC.InfoBoxText>
      {renderButton && (
        <TextButton
          extraStyle={{ margin: "12px 0", ...(renderButton.extraStyle || {}) }}
          title={renderButton.title}
          clicked={renderButton.clicked}
          iconType={renderButton.iconType}
          iconPlacement={renderButton.iconPlacement}
        />
      )}
    </SC.InfoBoxContainer>
  );
}
