import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/tenantPortalSettings";
import ManyRelationalOneWay from "../../../Base/FieldComponents/ManyRelationalOneWay";
import ContactNestedChildren from "../../../Tenant/ContactNestedChildren";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const getContactChildren = (index) => {
    const parentPath = `contact_persons[${index}]`;
    const parentInstructionsPath = "contact_persons";

    return (
      <ContactNestedChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Kontaktuppgifter</OverviewTitle>
          <OverviewSubtitle>
            Lägg till kontaktpersoner som ska visas i "Mina sidor".
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <ManyRelationalOneWay
        storeName={constants.STORE_NAME}
        method={method}
        fieldKey="contact_persons"
        instructionsKey="contact_persons"
        getChildren={getContactChildren}
        modalTitle="Lägg till kontaktperson"
        modalInModal
        constructStrRep={(obj) => {
          return obj.title || obj.name || "Kontakt utan titel/namn";
        }}
      />
    </>
  );
};
