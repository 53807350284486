import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useQuery from "../../../components/utils/useQuery";

import {
  constants as apartmentAdConstants,
  removeApartment,
  setAccessDateForId as setAccessDateForIdApartment,
  useFilteredApartmentAds,
  addMultipleApartments as addMultiple,
  setAds,
  publishNewProductionApartments,
} from "../../../store/marketApartmentAds";

import { detailUrl as pipeDetailUrl } from "../../../store/pipes";

import { buildQueryString } from "../../../store/base";
import { useFilteredApartments } from "../../../store/apartments";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import HandleAd from "./HandleAd";
import PublishNewProductionTable from "./PublishNewProductionTable";

export default function PublishNewProductionAds() {
  const dispatch = useDispatch();
  const query = useQuery();
  const { pipeId } = useParams();
  const realEstateId = query.get("realestate");
  const { goBack } = useHistory();
  const [hasSetFromQuery, setHasSetFromQuery] = React.useState(false);
  const [existingAdsLength, setExistingAdsLength] = React.useState(null);
  const [createConfirmOpen, setCreateConfirmOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const constants = apartmentAdConstants;
  const remove = removeApartment;
  const setAccessDate = setAccessDateForIdApartment;
  const storeName = constants.STORE_NAME;
  const { push } = useHistory();

  const defaultSettings = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const adsInstance = useSelector((state) => state[storeName].ads) || [];

  const apartmentQuery = buildQueryString({ realestate_ids: realEstateId });

  const [apartmentsMatchingRealEstate, apartmentsMatchingRealEstateLoading] =
    useFilteredApartments(apartmentQuery);

  const existingAdsForApartmentsQ = buildQueryString({
    apartment__in: apartmentsMatchingRealEstate?.map((a) => a.id),
  });

  const [existingAdsForApartments, existingAdsForApartmentsLoading] =
    useFilteredApartmentAds(existingAdsForApartmentsQ);

  const waitForData =
    !!hasSetFromQuery ||
    !!apartmentsMatchingRealEstateLoading ||
    !!existingAdsForApartmentsLoading ||
    apartmentsMatchingRealEstate?.length === 0;

  React.useEffect(() => {
    let filteredOut = 0;

    if (waitForData) {
      return;
    }

    let combined = apartmentsMatchingRealEstate || [];

    combined = combined.filter((a) => {
      if (existingAdsForApartments?.find((c) => c.apartment?.id === a.id)) {
        filteredOut += 1;
        return false;
      }
      return true;
    });

    if (combined?.length === 0 && filteredOut > 0) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Alla objekt i detta projekt har redan publicerats",
        })
      );
      setHasSetFromQuery(true);
      dispatch(
        setAds({
          newAds: [],
        })
      );
      return;
    }

    if (combined?.length > 0) {
      setHasSetFromQuery(true);

      if (combined.length > 50) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Hämtar in data...",
            description: "OBS: Detta kan ta några sekunder vid större volymer",
          })
        );

        setTimeout(() => {
          dispatch(
            setAds({
              newAds: combined?.map((c) => ({
                id: c.id,
                apartment_id: c.apartment_id,
                premises_id: c.premises_id,
                address: c.address,
                ...defaultSettings,
              })),
            })
          );
        }, 1000);
      } else {
        dispatch(
          setAds({
            newAds: combined?.map((c) => ({
              id: c.id,
              apartment_id: c.apartment_id,
              premises_id: c.premises_id,
              address: c.address,
              ...defaultSettings,
            })),
          })
        );
      }

      if (filteredOut > 0) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Vissa objekt filtrerades bort",
            description: `${filteredOut} objekt har redan publicerats.`,
          })
        );
      }
    }
  }, [
    apartmentsMatchingRealEstate,
    apartmentsMatchingRealEstateLoading,
    existingAdsForApartments,
    existingAdsForApartmentsLoading,
    waitForData,
  ]);

  React.useEffect(() => {
    if (
      existingAdsForApartments?.length > 0 &&
      existingAdsForApartments?.length !== existingAdsLength &&
      apartmentsMatchingRealEstate?.length !== 0 &&
      hasSetFromQuery &&
      adsInstance?.length > 0
    ) {
      setExistingAdsLength(existingAdsForApartments.length);
      setHasSetFromQuery(false);
    }
  }, [existingAdsForApartments]);

  React.useEffect(() => {
    return () => {
      dispatch(setAds({ newAds: [] }));
    };
  }, []);

  const onSuccess = ({ failed }) => {
    setLoading(false);

    // check errors
    const errorIds = Object.keys(failed || {});

    if (errorIds.length > 0) {
      let adsInstanceClone = cloneDeep(adsInstance);

      errorIds.forEach((apartmentId) => {
        const errorApartment = adsInstanceClone.find(
          (ap) => ap.id == apartmentId
        );

        errorApartment.error = failed[apartmentId];
      });

      adsInstanceClone = adsInstanceClone.filter((ap) =>
        errorIds.includes(ap.id.toString())
      );

      dispatch(setAds({ newAds: adsInstanceClone }));
    }

    if (errorIds.length === 0) {
      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Annonserna publiceras...",
          description:
            "Detta kan ta en liten stund. Pigello uppdateras automatiskt när de är skapade",
        })
      );
      push(`${pipeDetailUrl({ id: pipeId })}`);
    } else if (errorIds?.length < adsInstance.length) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Vissa av annonserna hade fel",
          description:
            "Kontrollera felmeddelandena längst till vänster i listan och försök igen när felen är lösta",
        })
      );
      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Vissa av annonserna publiceras...",
          description:
            "Detta kan ta en liten stund. Pigello uppdateras automatiskt när de är skapade. De har automatiskt rensats ur listan,",
        })
      );
    } else {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Ingen av annonserna kunde publiceras",
          description:
            "Kontrollera felmeddelandena längst till vänster i listan och försök igen när felen är lösta",
        })
      );
    }
  };
  const onError = () => {
    setLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "En eller fler rader innehåller fel eller saknar information",
        description: "Kontrollera datan och försök igen",
      })
    );
  };

  const onSubmit = () => {
    setLoading(true);
    const postObj = buildPostObj({ adsInstance });

    dispatch(
      publishNewProductionApartments({
        postObj,
        pipeId,
        successCallback: onSuccess,
        errorCallback: onError,
      })
    );
  };

  const onSelected = (row) => {
    const { original } = row;

    const match = adsInstance.find((i) => i.id === original.id);

    if (match) {
      dispatch(remove({ apartment: original }));
    }
  };

  const handleDateAccessUpdates = ({ id, val }) => {
    dispatch(setAccessDate({ id, val }));
  };

  const handleAttrUpdated = ({ attr, id, val }) => {
    const newAds = cloneDeep(adsInstance);

    const cur = newAds.find((obj) => obj.id === id);

    cur[attr] = val;

    dispatch(setAds({ newAds }));
  };

  return (
    <>
      <ConfirmationModal
        isOpen={createConfirmOpen}
        title={`Publicera annonser?`}
        renderContent={() => (
          <div>
            <hr />
            Du är påväg att publicera {adsInstance?.length} annonser. Gå vidare?
          </div>
        )}
        closeFunction={() => setCreateConfirmOpen(false)}
        acceptCallback={onSubmit}
      />

      <HandleAd
        {...{
          editInstance: adsInstance?.find((ad) => ad.id === editId),
          closeFunction: () => setEditId(null),
          isOpen: !!editId,
          handleAttrUpdated,
          handleDateAccessUpdates,
        }}
        isPublishNewProduction
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            extraStyle={{ marginBottom: 24 }}
            title="Tillbaka till val av standardinställningar"
            iconType="arrow-back"
            clicked={() => goBack()}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>
                Publicera annonser i nyproduktionsprojekt
              </OverviewTitle>
              <OverviewSubtitle style={{ marginRight: 24 }}>
                Ta bort de annonser som inte ska publiceras.
                <br /> Tryck på "Publicera annonser" när du är klar.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Publicera annonser"
              clicked={() => setCreateConfirmOpen(true)}
            />
          </OverviewTitleWrapper>
          <hr />

          {adsInstance?.length > 0 && (
            <PublishNewProductionTable
              {...{ adsInstance, setEditId, onSelected }}
            />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const buildPostObj = ({ adsInstance }) => {
  const postObj = {};

  adsInstance.forEach((ad) => {
    const adObj = {
      access_date: ad.access_date,
      prior_access: ad.prior_access,
      requirement: ad.requirement,
    };

    postObj[ad.id] = adObj;
  });

  return postObj;
};
