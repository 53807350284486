import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { usePaymentGroup, updateUrl as paymentGroupUpdateUrl, constants } from "../../store/paymentGroup";
import { detailUrl as brfCompanyDetailUrl } from "../../store/brfCompanies"

import PaymentGroup from "./Detail/PaymentGroup"
import Quotas from "./Detail/Quotas"
import Loans from "./Detail/Loan"

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useAllPermissionCheck } from "../../store/base";

export default function PaymentGroupDetail() {
  const { paymentGroupId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace, push } = useHistory()

  usePermissionRedirect(["allow_brf", "view_can_brf"]);

  const canDelete = useAllPermissionCheck([
    "delete_can_brf",
  ]);
  const canEdit = useAllPermissionCheck(["change_can_brf"])
  
  const [paymentGroup, paymentGroupLoading] = usePaymentGroup(paymentGroupId)
  const brfCompanyId = paymentGroup?.brf_company?.id;
 
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleActions = [
     {
         "name":"Redigera",
         onClick: () => push(paymentGroupUpdateUrl(paymentGroupId, brfCompanyId)),
         disabled: !brfCompanyId,
         hidden: !canEdit
     },
     {
         "name":"Radera",
         onClick: () => setDeleteOpen(true),
         isDelete: true,
         hidden: !canDelete
     }
  ];

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: PaymentGroup,
    },
    {
      path: "/loans",
      label: "Lån",
      component: Loans,
    },
    {
      path: "/quotas",
      label: "Andelstal",
      component: Quotas,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <>
        <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={paymentGroup}
        constants={constants}
        deletedCallback={() => replace(brfCompanyDetailUrl({id: brfCompanyId}) + "/loans")}
      />
        {!paymentGroup && <OverlaySpinner/>}
        <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Avgiftsgrupp ${paymentGroup?.str_representation || "laddar..."}`}
          breadCrumbs={[
            { url: "/brf-companies", label: "BRF" },
            { label: paymentGroup?.brf_company?.str_representation || "Laddar...", url: brfCompanyId != null ? brfCompanyDetailUrl({id: brfCompanyId}) + "/loans" : undefined },
            { label: paymentGroup?.str_representation || "Laddar..." },
          ]}
          eventContentType="brf.paymentgroup"
          eventObjectId={paymentGroupId}
          eventIdQueryStr="paymentgroup"
          {...{
            handleActions,
            subPages: subPageLinks
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
