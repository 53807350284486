export default [
  {
    title: "Aviseringsinställning",
    key: "INVOICING_SETTING",
    visited: true,
    hasError: false,
    fieldKeys: ["bill_with_lease", "setting", "billing_company", "imd_setting"],
  },
  {
    title: "Avtalsspecifikt",
    key: "INVOICING_CONTRACT_SETTING",
    visited: true,
    hasError: false,
    fieldKeys: [
      "start_date", 
      "delivery_method", 
      "interval_setting",
      "override_email_address",
      "override_care_of",
      "override_attention",
      "override_mail_address"
    ],
  },
  {
    title: "Information på avier",
    key: "INVOICING_INFO",
    hasError: false,
    fieldKeys: [
      "our_reference",
      "your_reference",
      "external_reference",
      "message",
      "include_auto_message",
    ],
  },
  {
    title: "Deposition",
    key: "DEPOSIT",
    hasError: false,
    fieldKeys: ["deposit_value", "deposit_payed"],
  },
  {
    title: "Hantering av avier",
    key: "INVOICE_HANDLING",
    hasError: false,
    fieldKeys: [
      "require_cost_accept",
      "admin_fee",
      "auto_merge",
      "auto_attest",
    ],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
