import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: white;
`;
export const DataTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;
export const DataContent = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const PrimaryCard = styled.div`
  flex: 1;
  min-width: 400px;
  margin-right: 12px;
  position: relative;
  background-color: white;
  padding: 24px 12px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 12px 12px 12px 0;

  & > ${DataRow} {
    &:nth-child(even) {
      background-color: rgba(241, 242, 246, 0.35);
    }
  }
`;

export const SecondaryCard = styled.div`
  flex: 1;
  min-width: 400px;
  position: relative;
  background-color: ${(p) => p.theme.colors.gray1};
  padding: 24px 12px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 12px 12px 12px 0;

  & > ${DataRow} {
    &:nth-child(odd) {
      background-color: rgba(241, 242, 246, 0.35);
    }
  }
`;

export const ActionBanner = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  color: white;
  padding: 24px;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 30px;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;

export const DefaultSettingWrapper = styled.div`
  background-color: white;
  width: 100%;
  padding: 12px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;
`;
