import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { TextButton } from "../../Forms/Base/Buttons";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { DateCell } from "../../Displays";

export default function VacancyBookingsTable({
  invoices,
  handleDownloadInvoice,
  handleRowClicked,
  renderBesideSearch,
}) {
  const data = React.useMemo(() => {
    let displayedInvoices = [];

    // map extra data
    displayedInvoices = invoices.map((i) => ({
      ...i,
      total: `${(i.InvoicedAmount.Value / 100)?.toLocaleString("sv")}`,
      totalLeft: `${(i.CurrentAmount.Value / 100)?.toLocaleString("sv")}`,
    }));

    return displayedInvoices;
  }, [invoices]);

  const billectaStateMap = {
    None: "Okänd",
    Created: "Ej attesterad",
    ReminderInvoiceSent: "Påminnelse",
    Manual: "Förfallen (Ej hanterad)",
    Completed: "Bokförd",
    Cancelled: "Avbruten",
    InvoiceSent: "Skickad",
    Attested: "Attesterad",
    CreditInvoice: "Kreditfaktura",
    SentToDebtCollection: "Kravhantering",
    SalesRequested: "SalesRequested",
    SaleRequestAccepted: "SaleRequestAccepted",
    SalesRequestedCancelled: "SalesRequestedCancelled",
    SalesRequestedDenied: "SalesRequestedDenied",
  };

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: "Status",
        accessor: "Stage",
        Cell: (props) => {
          if (props.row?.original?.IsDisputed) {
            return (
              <StatusLabel state={6}>
                {billectaStateMap[props.value]} (Bestriden)
              </StatusLabel>
            );
          }

          if (props.row?.original?.ActionType === "CreditInvoiceAction") {
            if (props.row?.original?.AttestedDate == null) {
              return (
                <StatusLabel state={"Kreditfaktura"}>
                  Kreditfaktura (Ej attesterad)
                </StatusLabel>
              );
            }
            return (
              <StatusLabel state={"Kreditfaktura"}>Kreditfaktura</StatusLabel>
            );
          }
          return (
            <StatusLabel state={billectaStateMap[props.value]}>
              {billectaStateMap[props.value]}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Fakturanr.",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },

      {
        Header: "Bolag",
        accessor: "DebtorName",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Belopp (SEK)",
        accessor: "total",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },

      {
        Header: "Attesterad",
        accessor: "AttestedDate",
        Cell: (props) => {
          return <Field>{props.value ? "Ja" : "Nej"}</Field>;
        },
      },
      {
        Header: "Datum för bokning",
        accessor: "InvoiceDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },

      {
        Header: "Skapad",
        accessor: "Created",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
    ];

    if (handleDownloadInvoice) {
      cols.push({
        Header: "Ladda ner",
        Cell: (props) => {
          return (
            <Field style={{ textAlign: "center" }}>
              <TextButton
                title="Ladda ner"
                iconType="download"
                iconPlacement="right"
                clicked={() => handleDownloadInvoice(props.cell.row.original)}
              />
            </Field>
          );
        },
      });
    }

    return cols;
  }, []);

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        onRowClicked={handleRowClicked}
        renderBesideSearch={renderBesideSearch}
        tableId="invoicestable"
        forceInitialPageSize={50}
        withPersistantSortBy
        withPersistantGlobalFilter
      ></BasicTable>
    </>
  );
}
