export default [
  {
    title: "Uppsägning",
    key: "CANCELLATION",
    visited: true,
    hasError: false,
    fieldKeys: [
      "closed_date",
      "closed_renew_date",
      "closed_cause",
      "status",
      "closed_on",
      "landlord_cancelled",
    ],
  },
  {
    title: "Uppdatera",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
