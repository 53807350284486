const defs = [
  {
    title: "Parkeringsplats",
    key: "PARKINGSPOT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "parking_lot",
      "specification",
      "address",
      "vacancy_booking_company",
      "realestate",
    ],
  },
  {
    title: "Debiteringsrader",
    key: "COSTS",
    visited: true,
    hasError: false,
    fieldKeys: ["cost_set"],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
