import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import PageTitle from "../Layouts/PageTitle/PageTitle";

import BillectaOutErrorList from "../../components/Tables/InvoicingErrors/FullTable";

const breadCrumbs = [
  {
    label: "Aviseringsvarningar",
  },
];

export default function DetailedBillectaOutErrors() {
  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle title="Aviseringsvarningar" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Detaljerade aviseringsvarningar</OverviewTitle>
            <OverviewSubtitle>
              OBS: Denna lista är tänkt att fungera som en logg för felsökning
              snarare än att användas i det dagliga arbetet. Kontakta Pigello om
              du är osäker på om data i denna lista påverkar er avisering.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <BillectaOutErrorList />
      </DetailPageBox>
    </BareLayoutWrapper>
  );
}
