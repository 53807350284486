export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ERRAND_COMPONENT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ERRAND_COMPONENT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ERRAND_COMPONENT",
  DESTROY_FORM: "DESTROY_FORM_ERRAND_COMPONENT",
  SET_FORM_ERROR: "SET_FORM_ERROR_ERRAND_COMPONENT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ERRAND_COMPONENT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ERRAND_COMPONENT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ERRAND_COMPONENT",
  RESET_STATE: "RESET_STATE_ERRAND_COMPONENT",
  CLEAR_FETCHED: "CLEAR_FETCHED_ERRAND_COMPONENT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ERRAND_COMPONENT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_COMPONENT",

  LIST_URL: "/errands/components/componentplacement/list/",
  POST_URL: "/errands/components/componentplacement/",
  PATCH_URL: "/errands/components/componentplacement/",
  GET_URL: "/errands/components/componentplacement/",

  STORE_NAME: "errandComponents",
};
