import * as React from "react";
import { useParams } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { buildManyIdFilter, buildRangeFilter } from "../../Lists/Base/utils";

import {
  useProductTypePagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/productTypes";

export default function ProductTypesTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  detailUrlMethod,
}) {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const redirectMethod =
    detailUrlMethod || ((obj) => detailUrl({ id: obj.id }));

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "errand_product_types_full_table"}
        title={"Komponentmodeller"}
        onRowClickedWithDetail={(obj) => redirectMethod(obj)}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
