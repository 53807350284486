import * as React from "react";
import moment from "moment";
import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";

const DATE_FORMAT = "YYYY-MM-DD";

export default ({ payments }) => {
  const data = React.useMemo(() => {
    let displayedPayments = [];

    // map extra data
    displayedPayments = payments.map((i) => ({
      ...i,
      total: `${(i.Amount.Value / 100)?.toLocaleString("sv")} ${
        i.Amount.CurrencyCode
      }`,
    }));

    return displayedPayments;
  }, [payments]);

  const billectaStateMap = {
    Unhandled: "danger",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "State",
        Cell: (props) => {
          return (
            <StatusLabel state={billectaStateMap[props.value]}>
              {billectaStateMap[props.value] === "danger"
                ? "Ej matchad"
                : "Matchad"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Referens",
        accessor: "Reference",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },

      {
        Header: "Belopp",
        accessor: "total",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },

      {
        Header: "Inbetalningsdatum",
        accessor: "PaymentDate",
        Cell: (props) => {
          return <Field>{moment(props.value).format(DATE_FORMAT)}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        hideSearch
        data={data}
        columns={columns}
        tableId="matchpaymentstable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
