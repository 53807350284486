import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";

import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/brfContract";

export default function TransactionsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  initialSortBy,
  isAdmin,
  onEdit,
  onDelete,
}) {
  const initialOrderBy = initialSortBy?.map((obj) => {
    return obj.desc ? `-${obj.id}` : obj.id;
  });

  const columns = React.useMemo(
    () => columnDefs(isAdmin, onEdit, onDelete),
    [persistantQuery, isAdmin]
  );

  const filters = {};
  const badges = {};

  return (
    <BaseTable
      tableId={tableId || "tenant_transactions_full_table"}
      title={"Ägarbyten"}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
        initialOrderBy,
      }}
    />
  );
}
