import * as React from "react";
import { useParams } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./inviteListDefs";

// store, state
import {
  useTenantPagination,
  performFilter,
  constants,
  detailUrl,
  useFilteredTenants,
} from "../../../store/tenants";
import { buildQueryString } from "../../../store/base";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default React.memo(
  ({
    persistantQuery,
    isBare,
    ignoreLocalStorage,
    tableId,
    onRowClicked,
    checkRowHighlighted,
    onRowSelected,
    handleInvite,
    isMembers,
    inviteLoading,
  }) => {
    // const [selectedRows, setSelectedRows] = React.useState([persistantQuery]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedTenants, selectedTenantsLoading] = useFilteredTenants(
      buildQueryString({ id__in: selectedRows || [] })
    );

    const userIds = selectedTenants?.map((t) => t.user.id);

    const columns = React.useMemo(() => columnDefs(isMembers), [isMembers]);

    const filters = {
      Namn: {
        queryKey: "str_representation",
        type: "text",
        operators: OPERATOR_TEMPLATES.SEARCH,
      },
      Email: {
        queryKey: "email",
        type: "text",
        operators: OPERATOR_TEMPLATES.SEARCH,
      },
    };
    const badges = {};

    return (
      <>
        {inviteLoading && <OverlaySpinner />}

        <BaseTable
          tableId={tableId || "members_tenants_invite_full_table"}
          title={isMembers ? "Medlemmar" : "Hyresgäster"}
          onRowClickedWithDetail={(obj) =>
            detailUrl({
              id: obj.id,
            })
          }
          onRowSelected={(ids) => setSelectedRows(ids)}
          hideExport
          {...{
            isBare,
            ignoreLocalStorage,
            onRowClicked,
            columns,
            persistantQuery,
            badges,
            filters,
            constants,
            checkRowHighlighted,
          }}
        />

        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="mt-2"
        >
          <PrimaryButton
            extraStyle={{ minWidth: 200 }}
            title={`Bjud in ${selectedRows?.length} ${
              isMembers ? "medlemmar" : "hyresgäster"
            }`}
            newDesign
            disabled={
              !selectedRows?.length || inviteLoading || selectedTenantsLoading
            }
            clicked={() => handleInvite(userIds)}
          />
        </div>
      </>
    );
  }
);
