const defs = [
  {
    title: "Andel",
    key: "GENERAL",
    visited: true,
    hasError: false,
    fieldKeys: [
      "payment_group",
      "brf_premis",
    ],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
