import * as React from "react";
import moment from "moment";
import { ToolTipCell } from "../../Displays";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export const _earliestDate = ({ dates }) => {
  let earliest = null;

  for (let index = 0; index < dates.length; index++) {
    let element = dates[index];
    if (element == null) {
      continue;
    }
    if (typeof element === "string") {
      element = moment(element);
    }

    if ((element?._isValid && earliest == null) || element < earliest) {
      earliest = element;
    }
  }
  if (earliest?._isValid) {
    return earliest?.clone();
  } else return earliest;
};
export const _latestDate = ({ dates }) => {
  let latest = null;

  for (let index = 0; index < dates.length; index++) {
    let element = dates[index];
    if (element == null) {
      continue;
    }
    if (typeof element === "string") {
      element = moment(element);
    }

    if ((element?._isValid && latest == null) || element > latest) {
      latest = element;
    }
  }

  return latest?.clone();
};

export const INDEX_STATES = {
  0: "Felaktig period",
  1: "Indexuppräkning ej aktiverat",
  2: "Index kan ej räknas ut för vald period",
};

export const PRODUCT_CATEGORIES = {
  CATEGORY_INDEX: 0,
  CATEGORY_BASE_RENT: 1,
  CATEGORY_PROP_TAX: 2,
  CATEGORY_ELECTRIC: 3,
  CATEGORY_WATER_HOT: 4,
  CATEGORY_WATER_COLD: 5,
  CATEGORY_HEAT: 6,
  CATEGORY_COOLING: 7,
  CATEGORY_WATER: 8,
  CATEGORY_PARKING: 9,
  CATEGORY_DEPOSIT: 10,
  CATEGORY_RENT_INCREASE: 11,
  CATEGORY_MEMBER_FEE: 12,
  CATEGORY_ERRAND_ARTICLE: 13,
  CATEGORY_IMD: 14,
  CATEGORY_GAS: 15,
  CATEGORY_DISTRICT_COOLING: 16,
  CATEGORY_DISTRICT_HEAT: 17,
  CATEGORY_VA: 18,
  CATEGORY_COLD: 19,
  CATEGORY_SIGN: 20,
  CATEGORY_MAINTENENCE: 21,
  CATEGORY_WIFI: 22,
  CATEGORY_GARBAGE: 23,
  CATEGORY_SNOW: 24,
  CATEGORY_CLEANING: 25,
  CATEGORY_VENTILATION: 26,
  CATEGORY_ADJUSTMENT: 27,
};

export const PRODUCT_CATEGORY_MAPPING = {
  1: "Bashyra",
  2: "Fastighetsskatt",
  3: "El",
  4: "Varmvatten",
  5: "Kallvatten",
  6: "Värme",
  7: "Kyla",
  8: "Vatten",
  9: "Parkering",
  10: "Deposition",
  11: "Hyreshöjning",
  12: "Medlemsavgift",
  13: "Ärende artikel",
  14: "IMD",
  15: "Gas",
  16: "Fjärrkyla",
  17: "Fjärrvärme",
  18: "Vatten och avlopp",
  19: "Kyla",
  20: "Skylt",
  21: "Drift/Underhåll",
  22: "Bredband/Wi-Fi",
  23: "Avfallshantering",
  24: "Snöröjning",
  25: "Städning",
  26: "Ventilation",
  27: "Ombyggnad/Hyresgästanpassning",
};

const setDatesRange = (endDate, startDate, convertEndDate) => {
  const now = moment();
  let returnString = false;
  if (typeof endDate === "string") {
    endDate = moment(endDate);
    returnString = true;
  }
  if (endDate.year() <= now.year() || convertEndDate) {
    endDate = endDate?.clone()?.set({ year: now.year(), month: 11 });
  }
  startDate?.set({ year: now.year(), month: 0 });

  if (returnString) {
    endDate = endDate.format("YYYY-MM-DD");
  }

  if (endDate?._isValid) {
    return endDate?.clone();
  } else {
    return endDate;
  }
};
export const getContractFromAndTo = (contract) => {
  if (!contract) return {};
  const now = moment();

  const contractStart = moment(contract.start_date);
  const periodStart = moment(contract.start_date || now.format("YYYY-MM-DD"))
    ?.clone()
    ?.set({ year: now.format("YYYY") });

  const periodEnd = moment(contract.start_date || now.format("YYYY-MM-DD"))
    ?.clone()
    ?.set({ year: now.format("YYYY") })
    ?.subtract({ month: 1 })
    ?.add({ year: 1 });

  let endDates = [
    periodEnd.format("YYYY-MM-DD"),
    contract.renewed_to,
    contract.closed_renew_date,
    contract.closed_date,
  ];
  if (!contract.renewed_to && contract.end_date !== null) {
    endDates.push(contract.end_date);
  }

  // when periodStart.month is after current month, and we have no contract end date we have + 1 year gap
  endDates = endDates.filter((d) => d !== null);
  if (periodStart.month() > now.month() && contractStart.isBefore(now)) {
    if (endDates.length > 1) {
      periodStart.subtract({ year: 1 });
    } else {
      endDates[0] = setDatesRange(endDates[0], periodStart, true);
    }
  }

  const contractTo = _earliestDate({
    dates: endDates,
  });

  const contractFrom = _latestDate({
    dates: [periodStart.format("YYYY-MM-DD"), contract.start_date],
  });

  // if original start date year is in some previous year...
  if (moment(contract.start_date).year() < now.year()) {
    contractFrom?.clone().set({ year: now.year(), month: 0 });
  }

  // When we have contract slutdatum in older year we are setting start date to be current year, so we are getting the situation where start date > end date
  // In this case set the same scenario as we have for previous problem
  if (contractFrom > contractTo) {
    setDatesRange(contractTo, contractFrom);
  } else if (
    endDates.length === 2 &&
    contract.renewed_to !== null &&
    contractTo.year() > now.year()
  ) {
    // If the only contract date available for a slutdatum is a 'renew' date and year of that date is not the curent year...
    setDatesRange(contractTo, contractFrom, true);
  }

  // If there is no slutdatum or slutdatum is greater than the current year then slutdatum should be the end of the current year
  if (endDates.length === 1 || contractTo.year() > now.year()) {
    contractTo?.clone()?.set({ year: now.year(), month: 11 });
  }

  return {
    contractFrom,
    contractTo,
  };
};

export const getSpecifiedPeriodDates = ({ start, end, contract }) => {
  const startDates = [start, contract.start_date];

  if (!startDates?.length) {
    const n = moment().format("YYYY-MM-DD");
    startDates.push(n);
  }

  const specifiedStartDate = _latestDate({
    dates: startDates.filter((v) => v),
  });

  const endDates = [
    end,
    contract.renewed_to,
    contract.closed_renew_date,
    contract.closed_date,
  ];
  if (!contract.renewed_to) {
    endDates.push(contract.end_date);
  }

  if (!endDates?.length) {
    const n = moment().format("YYYY-MM-DD");

    endDates.push(n);
  }

  const specifiedEndDate = _earliestDate({
    dates: endDates.filter((v) => v),
  });

  return {
    specifiedStartDate,
    specifiedEndDate,
  };
};

export const getCostBetweenMonthDates = (costs, start, end) => {
  if (!costs?.length || !start || !end) {
    return [];
  }

  if (end.diff(start, "days") <= 0) {
    return [];
  }

  return costs.filter(
    (c) =>
      !(
        (c.end_date && moment(c.end_date).isBefore(start)) ||
        (c.start_date && moment(c.start_date).isAfter(end))
      ) && !c?.do_not_debit
  );
};

export const getMonthsForCost = (cost, start, end) => {
  // lowest end
  let aStart;
  if (cost.start_date) {
    let cStart = moment(cost.start_date);
    if (cStart > start) {
      aStart = cStart;
    } else {
      aStart = start;
    }
  } else {
    aStart = start;
  }

  let aEnd;
  if (cost.end_date) {
    let cEnd = moment(cost.end_date);
    if (cEnd < end) {
      aEnd = cEnd;
    } else {
      aEnd = end;
    }
  } else {
    aEnd = end;
  }

  if (aEnd.isSame(aStart, "month")) return 1;

  return aEnd.diff(aStart, "months") + 1;
};

export const contractOverviewCosts = ({ contract, costsData }) => {
  if (!contract) return 0;
  const { contractFrom, contractTo } = getContractFromAndTo(contract);

  const indexEndOfYear = contractFrom
    ?.clone()
    ?.add({ year: 1 })
    ?.subtract({ days: 1 });

  const { specifiedEndDate: specifiedIndexEndDate } = getSpecifiedPeriodDates({
    start: contractFrom.format("YYYY-MM-DD"),
    end: indexEndOfYear.format("YYYY-MM-DD"),
    contract,
  });

  const costs = getCostBetweenMonthDates(costsData, contractFrom, contractTo);

  const indexValueForPeriod = getIndexBetweenMonths(
    contract?.indexations,
    contractFrom,
    specifiedIndexEndDate
  );

  const total = costs.reduce((acc, cur) => {
    const months = getMonthsForCost(cur, contractFrom, contractTo);
    const totVal = (cur.value || 0) * (cur.unit_amount || 0) * (months || 0);

    return acc + totVal;
  }, 0);

  return (
    (total || 0) +
    (typeof indexValueForPeriod == "string" ? 0 : indexValueForPeriod)
  );
};

export const getIndexBetweenMonths = (indexes, start, end) => {
  if (!indexes || indexes?.length <= 0) {
    return INDEX_STATES[1];
  }

  if (end.diff(start, "days") <= 0) {
    return INDEX_STATES[0];
  }

  const usedForPeriod = indexes.filter((indx) => {
    const indexStart = moment(indx.start_date);
    const indexEnd = moment(indx.end_date);
    if (start.isAfter(indexEnd)) return false;
    if (end.isBefore(indexStart)) return false;

    return true;
  });

  const totVal = usedForPeriod.reduce((acc, cur) => {
    const indexStart = moment(cur.start_date);
    const indexEnd = moment(cur.end_date);
    const monthlyValue = cur.value / 12;

    const startF = start.format("YYYY-MM-DD");
    const endF = end.format("YYYY-MM-DD");

    let monthCount = end.diff(start, "months");

    if (indexStart.isAfter(start)) {
      const diff = indexStart.diff(start, "months");

      monthCount -= diff;
    }

    if (indexEnd.isBefore(end)) {
      const diff = indexEnd.diff(end, "months");

      monthCount -= diff;
    }

    return acc + monthlyValue * monthCount;
  }, 0);

  return totVal;
};

export const getCostColumns = (
  startDate,
  endDate,
  excludeColumns,
  useSqm,
  showMonthlyCosts,
  useSpecifiedPeriod
) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const _exclusions = (excludeColumns || [])?.map((c) => PRODUCT_CATEGORIES[c]);

  let result = [];

  Object.keys(PRODUCT_CATEGORY_MAPPING).forEach((c) => {
    const keyMapping = parseInt(c);

    if (_exclusions.includes(keyMapping)) {
      return;
    }

    const title = PRODUCT_CATEGORY_MAPPING[c];

    result.push({
      Header: `${title} ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
        useSqm ? "(per m²)" : ""
      }`,
      alignRight: true,
      id: `product_title_${c}`,
      Cell: ({ row }) => {
        const { contractFrom, contractTo } = getContractFromAndTo(row.original);

        const { specifiedStartDate, specifiedEndDate } =
          getSpecifiedPeriodDates({
            start,
            end,
            contract: row.original,
          });

        const filteredCosts = getCostBetweenMonthDates(
          row.original.costs,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo
        );

        const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

        const total = filteredCosts.reduce((acc, curr) => {
          const months = getMonthsForCost(
            curr,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo,
            useSpecifiedPeriod
          );

          return (
            acc +
            (curr.product_category === keyMapping && curr.value >= 0
              ? (curr.value *
                  curr.unit_amount *
                  (showMonthlyCosts ? 1 : months)) /
                (useSqm ? totalArea : 1)
              : 0)
          );
        }, 0);

        return (
          <ToolTipCell
            text={`${Math.round(total).toLocaleString("sv")} ${
              useSqm && totalArea === 1 ? "(Area saknas)" : ""
            }`}
          />
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    });
  });

  result.push({
    Header: `Rabatter ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `discounts`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(
          curr,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo,
          useSpecifiedPeriod
        );
        return (
          acc +
          (curr.value < 0
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  // index, brent, brent increase, proptax, parkeringar, deposit
  const nonPositiveInclusion = [
    PRODUCT_CATEGORIES.CATEGORY_INDEX,
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
    PRODUCT_CATEGORIES.CATEGORY_PROP_TAX,
    PRODUCT_CATEGORIES.CATEGORY_PARKING,
    PRODUCT_CATEGORIES.CATEGORY_DEPOSIT,
  ];

  result.push({
    Header: `Tillägg ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `addons`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(
          curr,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo,
          useSpecifiedPeriod
        );

        return (
          acc +
          (curr.value >= 0 &&
          (curr.product_category === null ||
            !nonPositiveInclusion.includes(curr.product_category))
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  const baseRentinclusion = [
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
  ];
  result.push({
    Header: `Total bashyra ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `baseRent`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(
          curr,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo,
          useSpecifiedPeriod
        );
        return (
          acc +
          (baseRentinclusion.includes(curr.product_category) && curr.value >= 0
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);

      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  result.push({
    Header: `Bashyra m. Index ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `baseRentIndex`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      let indexValue = getIndexBetweenMonths(
        row.original.indexations,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );
      if (typeof indexValue === "string") {
        return <StatusLabel state={3}>{indexValue}</StatusLabel>;
      }

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const monthsCount = useSpecifiedPeriod
        ? specifiedEndDate.diff(specifiedStartDate, "months") + 1
        : contractTo.diff(contractFrom, "months") + 1;

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total =
        filteredCosts.reduce((acc, curr) => {
          const months = getMonthsForCost(
            curr,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo,
            useSpecifiedPeriod
          );
          return (
            acc +
            (baseRentinclusion.includes(curr.product_category) &&
            curr.value >= 0
              ? (curr.value *
                  curr.unit_amount *
                  (showMonthlyCosts ? 1 : months)) /
                (useSqm ? totalArea : 1)
              : 0)
          );
        }, 0) +
        indexValue /
          (useSqm ? totalArea : 1) /
          (showMonthlyCosts ? monthsCount : 1);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  result.push({
    Header: `Indexuppräkning ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `indexRent`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const indexValue = getIndexBetweenMonths(
        row.original.indexations,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const months = useSpecifiedPeriod
        ? specifiedEndDate.diff(specifiedStartDate, "months") + 1
        : contractTo.diff(contractFrom, "months") + 1;

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      if (typeof indexValue === "string") {
        return <StatusLabel state={3}>{indexValue}</StatusLabel>;
      }
      const calculatedIndexValue =
        indexValue / (useSqm ? totalArea : 1) / (showMonthlyCosts ? months : 1);
      return (
        <ToolTipCell
          text={Math.round(calculatedIndexValue).toLocaleString("sv")}
        />
      );
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  return result;
};

export const getObjectCostColumns = (
  startDate,
  endDate,
  excludeColumns,
  useSqm,
  showMonthlyCosts
) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const _exclusions = (excludeColumns || [])?.map((c) => PRODUCT_CATEGORIES[c]);

  let result = [];

  Object.keys(PRODUCT_CATEGORY_MAPPING).forEach((c) => {
    const keyMapping = parseInt(c);

    if (_exclusions.includes(keyMapping)) {
      return;
    }

    const title = PRODUCT_CATEGORY_MAPPING[c];

    result.push({
      Header: `${title} ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
        useSqm ? "(per m²)" : ""
      }`,
      alignRight: true,
      id: `product_title_${c}`,
      Cell: ({ row }) => {
        // We don't have any costs on row
        const filteredCosts = getCostBetweenMonthDates(
          row.original.cost_set,
          start,
          end
        );

        const totalArea = row.original.area || 1; // 1 if no area to avoid Infinity

        const total = filteredCosts.reduce((acc, curr) => {
          const months = getMonthsForCost(curr, start, end);

          return (
            acc +
            (curr.product?.category === keyMapping && curr.value >= 0
              ? (curr.value *
                  curr.unit_amount *
                  (showMonthlyCosts ? 1 : months)) /
                (useSqm ? totalArea : 1)
              : 0)
          );
        }, 0);

        return (
          <ToolTipCell
            text={`${Math.round(total).toLocaleString("sv")} ${
              useSqm && totalArea === 1 ? "(Area saknas)" : ""
            }`}
          />
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    });
  });

  result.push({
    Header: `Rabatter ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `discounts`,
    Cell: ({ row }) => {
      const filteredCosts = getCostBetweenMonthDates(
        row.original.cost_set,
        start,
        end
      );

      const totalArea = row.original.area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(curr, start, end);
        return (
          acc +
          (curr.value < 0
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  // index, brent, brent increase, proptax, parkeringar, deposit
  const nonPositiveInclusion = [
    PRODUCT_CATEGORIES.CATEGORY_INDEX,
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
    PRODUCT_CATEGORIES.CATEGORY_PROP_TAX,
    PRODUCT_CATEGORIES.CATEGORY_PARKING,
    PRODUCT_CATEGORIES.CATEGORY_DEPOSIT,
  ];

  result.push({
    Header: `Tillägg ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `addons`,
    Cell: ({ row }) => {
      const filteredCosts = getCostBetweenMonthDates(
        row.original.cost_set,
        start,
        end
      );

      const totalArea = row.original.area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(curr, start, end);

        return (
          acc +
          (curr.value >= 0 &&
          (curr.product?.category === null ||
            !nonPositiveInclusion.includes(curr.product?.category))
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  const baseRentinclusion = [
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
  ];
  result.push({
    Header: `Total bashyra ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    alignRight: true,
    id: `baseRent`,
    Cell: ({ row }) => {
      const filteredCosts = getCostBetweenMonthDates(
        row.original.cost_set,
        start,
        end
      );

      const totalArea = row.original.area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(curr, start, end);
        return (
          acc +
          (baseRentinclusion.includes(curr.product?.category) && curr.value >= 0
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);

      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  return result;
};
