import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);
  const { type, payload } = action;

  switch (type) {
    case constants.SET_AVAILABLE_EXCEL_REPORTS: {
      return {
        ...newState,
        availableExcelReports: payload.availableExcelReports,
      };
    }

    case constants.SET_AVAILABLE_PPT_REPORTS: {
      return {
        ...newState,
        availablePptReports: payload.availablePptReports,
      };
    }

    case constants.SET_EXCLUDED_COLUMNS: {
      return { ...newState, excludedColumns: payload.excludedColumns };
    }
    case constants.SET_EXCLUDED_SHEETS: {
      return { ...newState, excludedSheets: payload.excludedSheets };
    }

    case constants.SET_EXCLUDED_OBJECT_TYPES: {
      return { ...newState, excludedObjectTypes: payload.excludedObjectTypes };
    }

    case constants.SET_REPORT_TITLE: {
      return { ...newState, title: payload.title };
    }
    case constants.SET_KWARGS: {
      return { ...newState, kwargs: payload.kwargs };
    }
    case constants.SET_AVAILABLE_KWARGS: {
      return { ...newState, availableKwargs: payload.kwargs };
    }

    case constants.CLEAR_SETTINGS: {
      return {
        ...newState,
        excludedColumns: undefined,
        excludedObjectTypes: undefined,
        exludedSheets: undefined,
        title: undefined,
        kwargs: undefined,
      };
    }

    default:
      return newState;
  }
};
