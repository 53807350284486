import * as React from "react";
import * as SC from "./styles";

import blueprintIcon from "../../../assets/svg/blueprint.svg";

export default function ProcessBlueprint() {
  return (
    <SC.NoBuildingsWrapper>
      <SC.TitleIcon src={blueprintIcon} />
      <SC.Title>Under behandling</SC.Title>
      <SC.Subtitle>
        Vi har tagit emot din planritning och behandlar den. När den är klar
        kommer den att visas här.
      </SC.Subtitle>
    </SC.NoBuildingsWrapper>
  );
}
