import moment from "moment";

export const constructStrRep = (obj) => {
  return obj.id || "Annons";
};

export const detailUrl = ({ id }) => {
  return `/market/apartment-ads/detail/${id}`;
};

export const overviewUrl = () => {
  return `/market/ads`;
};

export const createManyMenuUrl = ({ type }) =>
  `/market/ads/create-many/${type}`;

export const createManyDefaultSettingUrl = ({ type }) =>
  `/market/ads/create-many/${type}/default-settings`;

export const createManyUrl = ({ type }) =>
  `/market/ads/create-many/${type}/handle`;

export const publishNewProductionSettingsUrl = ({ realEstateId, pipeId }) =>
  `/market/ads/publish-new-production/settings/${pipeId}?realestate=${realEstateId}`;

export const publishNewProductionUrl = ({ realEstateId, pipeId }) =>
  `/market/ads/publish-new-production/handle/${pipeId}?realestate=${realEstateId}`;

export const getAdStatus = (ad) => {
  return ad.pending
    ? { label: "Pausad", state: 8 }
    : moment(ad.date_publish).isSameOrBefore(moment())
    ? { label: "Ej pausad", state: 0 }
    : { label: "Inväntar publicering", state: 3 };
};

export const getAdStateAndLabel = (ad) => {
  const stateDisplay =
    ad?.closed && ad?.confirmed
      ? "Slutförd"
      : ad?.closed
      ? "Avbruten"
      : ad?.confirmed
      ? "Reserverad"
      : ad?.is_pending
      ? "Pausad"
      : "Aktiv";
  const state =
    ad?.closed && ad?.confirmed
      ? 9
      : ad?.closed
      ? 6
      : ad?.confirmed
      ? 4
      : ad?.is_pending
      ? 3
      : 0;

  return { stateDisplay, state };
};
