import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import {
  constants,
  detailUrl,
  useFilteredRealEstatePortfolios,
} from "../../../store/realEstatePortfolios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveFilteredRealEstates } from "src/store/overview/store/actions";
import { buildQueryString } from "src/store/base";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function RealEstatePortfolioTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  withSelect = true,
}) {
  const dispatch = useDispatch();

  const { push } = useHistory();
  const [selected, setSelected] = React.useState([]);

  const [selectedPortfolios] = useFilteredRealEstatePortfolios(
    buildQueryString({
      id__in: selected || [],
    })
  );

  const selectedRealEstateIds = [
    ...new Set(
      selectedPortfolios?.reduce((acc, cur) => {
        return [...acc, ...(cur.realestates?.map((r) => r.id) || [])];
      }, [])
    ),
  ];

  const setFilter = () => {
    dispatch(
      setActiveFilteredRealEstates({
        portfolioIds: selected?.map((s) => parseInt(s)) || [],
        realEstateIds: selectedRealEstateIds || [],
      })
    );
    push("/");
  };

  const columns = React.useMemo(() => {
    const data = columnDefs();
    return data;
  }, [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "realestateportfolios_full_table"}
        title={"Portföljer"}
        onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
        initialOrderBy={["-created_at"]}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected: withSelect ? setSelected : undefined,
        }}
      />

      {selected?.length > 0 && withSelect && (
        <div className="fixed bottom-0 left-0 right-0 px-24 py-2 bg-white border-t border-solid border-slate-100 shadow-sm flex justify-end">
          <PrimaryBtn highlight onClick={setFilter}>
            Filtrera systemet på {selected?.length} portföljer
          </PrimaryBtn>
        </div>
      )}
    </>
  );
}
