import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import { getReadableInvoiceRecordDescription } from "../../Tables/InvoicingRecord/listDefs";
import { InnerBox } from "../../sharedStyles";
import * as SC from "./styles";

export default function InvoicingRecord({ record }) {
  const { push } = useHistory();

  const goToInvoice = () => {
    push(
      invoiceDetailUrl({
        invoiceId: record?.billecta_id?.object_id,
        creditorId: record?.billecta_id?.creditor_id,
      })
    );
  };
  return (
    <InnerBox
      style={{ padding: 8 }}
      onClick={record?.billecta_id ? goToInvoice : null}
    >
      <SC.CreatedDate>
        <div>{moment(record?.updated_at).format("YYYY-MM-DD HH:mm")}</div>
        <div>
          {record?.from_auto
            ? "System"
            : `${record?.owner?.str_representation || "Användare"}`}
        </div>
      </SC.CreatedDate>
      <SC.Description>
        {getReadableInvoiceRecordDescription(record)}
      </SC.Description>
      <SC.Dates>
        Avser perioden{" "}
        <span style={{ fontWeight: 600 }}>{record?.period_start}</span> till{" "}
        <span style={{ fontWeight: 600 }}>{record?.period_end}</span>
      </SC.Dates>
      <SC.Dates>
        Aviseras <span style={{ fontWeight: 600 }}>{record?.invoice_date}</span>{" "}
        och förfaller{" "}
        <span style={{ fontWeight: 600 }}>{record?.due_date}</span>
      </SC.Dates>
    </InnerBox>
  );
}
