import * as React from "react";

import TextInputField from "../Base/Fields/TextInputField";

export default function CostCenterNestedFields({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Kod för kostnadsställe"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.title`,
            instructionsKey: `${parentInstructionsPath}.title`,
          }}
        />
        <TextInputField
          title="Beskrivning"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.description`,
            instructionsKey: `${parentInstructionsPath}.description`,
          }}
        />
      </div>
    </>
  );
}
