import * as React from "react";
import { useDispatch } from "react-redux";

import {
  useUnit4ERPForm,
  constants,
  create,
  update,
  destroyPatchForm,
  destroyPostForm,
} from "../../../../store/Unit4ERP";
import { cloneDeep } from "lodash";
import { setActiveFormInstance } from "../../../../store/base/store/actions";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import { useFilteredSFTPCatalogues } from "src/store/SFTPCatalogue";
import M2mField from "../../Base/Fields/M2mField";
import DateSelect from "../../Base/Fields/DateSelect";
import CheckField from "../../Base/Fields/CheckField";

export default function Unit4ModalForm({
  method,
  id,
  onCheckout,
  instance,
  onDelete,
}) {
  const [existingSFTPCatalogues] = useFilteredSFTPCatalogues("");
  const companiesWithSFTPActivated = existingSFTPCatalogues?.map(
    (s) => s.company?.id
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  const formLoaded = Boolean(useUnit4ERPForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout(success);
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={
        method === "POST"
          ? "Lägg till Unit4 ERP-integration"
          : "Uppdatera integration"
      }
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
      onDelete={onDelete}
      canDelete={!!onDelete && method === "PATCH"}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Konfigurera integration</div>

      {method === "POST" && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            persistantQuery={{ id__in: companiesWithSFTPActivated || [] }}
            title="Gäller för bolag/BRF"
            placeholder="Välj bolag/brf..."
            method={method}
            fieldKey="company"
            TableComponent={CompanyTable}
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title={"Namn"}
          storeName={storeName}
          fieldKey={"title"}
          method={method}
        />
        <TextInputField
          title={"Enhetskod (t.ex. orgnr)"}
          storeName={storeName}
          fieldKey={"entity_code"}
          method={method}
        />
        <TextInputField
          title={"Överskrivning av verifikationsserie"}
          storeName={storeName}
          fieldKey={"voucher_serie_override"}
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title={"Momskod 25%"}
          storeName={storeName}
          fieldKey={"vat_code_25"}
          method={method}
        />
        <TextInputField
          title={"Momskod 12%"}
          storeName={storeName}
          fieldKey={"vat_code_12"}
          method={method}
        />
        <TextInputField
          title={"Momskod 6%"}
          storeName={storeName}
          fieldKey={"vat_code_6"}
          method={method}
        />
        <TextInputField
          title={"Momskod 0%"}
          storeName={storeName}
          fieldKey={"vat_code_0"}
          method={method}
        />
        <TextInputField
          title={"Momskod ingen moms"}
          storeName={storeName}
          fieldKey={"vat_code_none"}
          method={method}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <M2mField
          title="Överskrivning av dimensioner"
          fieldKey="dimension_overrides"
          method={method}
          storeName={storeName}
          instanceTitle="överskrivning av dimension"
        >
          {(parentPath) => (
            <>
              <div className="grid grid-cols-2 gap-2">
                <TextInputField
                  title={"Dimension"}
                  storeName={storeName}
                  instructionsKey={"dimension_overrides.dimension_id"}
                  fieldKey={`${parentPath}.dimension_id`}
                  method={method}
                />
                <TextInputField
                  title={"Skriv över med"}
                  storeName={storeName}
                  instructionsKey={"dimension_overrides.override_with"}
                  fieldKey={`${parentPath}.override_with`}
                  method={method}
                />
              </div>
              <DateSelect
                title={"Skapades"}
                storeName={storeName}
                fieldKey={`${parentPath}.created_at`}
                instructionsKey={"dimension_overrides.created_at"}
                method={method}
              />
              <CheckField
                title={"Exkludera dimension"}
                description="Radera denna dimension vid överföring"
                storeName={storeName}
                instructionsKey={"dimension_overrides.exclude_dimension"}
                fieldKey={`${parentPath}.exclude_dimension`}
                method={method}
              />
            </>
          )}
        </M2mField>
      </div>
    </Modal>
  );
}
