import { store } from "../store";
import constants from "./constants";

export const TOAST_TYPES = {
  INFO: "INFO",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const TOAST_COLOR_TYPES = {
  INFO: {
    background: "#499dff",
    color: "#000",
  },
  SUCCESS: {
    background: "#2ec177",
    color: "#000",
  },
  ERROR: {
    background: "#ea5050",
    color: "#000",
  },
};

export function addToast({
  title,
  description,
  type,
  action,
  toastTimeOut = 4000,
  skipAnimation,
}) {
  return async (dispatch) => {
    let id = Math.random(100);
    dispatch({
      payload: {
        title,
        description,
        action,
        type,
        open: false,
        style: TOAST_COLOR_TYPES[type],
        id,
        skipAnimation,
      },
      type: constants.ADD_TOAST,
    });

    dispatch({
      type: constants.OPEN_TOAST,
      payload: {
        id,
      },
    });

    setTimeout(() => {
      dispatch(removeToast(id));
    }, toastTimeOut);
  };
}

export function removeToast(id, force) {
  return async (dispatch) => {
    const state = store.getState().toasts;

    if (state.lockedToasts.includes(id) && !force) {
      setTimeout(() => {
        dispatch(removeToast(id));
      }, 500);
    } else {
      dispatch({
        payload: { id },
        type: constants.CLOSE_TOAST,
      });

      setTimeout(() => {
        dispatch({
          payload: { id },
          type: constants.REMOVE_TOAST,
        });
      }, 2000);
    }
  };
}

export function lockToast(id) {
  return async (dispatch) => {
    dispatch({
      payload: { id },
      type: constants.LOCK_TOAST,
    });
  };
}

export function unlockToast(id) {
  return async (dispatch) => {
    dispatch({
      payload: { id },
      type: constants.UNLOCK_TOAST,
    });
  };
}
