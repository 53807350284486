import { addToProcess } from "../../base";
import { removeFromProgress } from "../../base/store/actions";
import constants from "./constants";
import * as services from "./services";

export const getAccountBalanceForDebtor = ({ debtorId, inProcessName }) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, inProcessName || "account_balance");

      const data = await services.getAccountBalanceForDebtor(debtorId);

      dispatch({
        type: constants.SET_ACCOUNT_BALANCE,
        payload: {
          debtorId,
          accountBalance: data,
        },
      });

      removeFromProgress(
        dispatch,
        constants,
        inProcessName || "account_balance"
      );
      // successCallback && successCallback();
    } catch (e) {
      console.error(e);
      //errorCallback && errorCallback()
    }
  };
};

export const addManualTransactionForDebtor = ({
  postObj,
  debtorId,
  errorCallback,
  successCallback,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.addManualTransactionForDebtor({
        postObj,
        debtorId,
      });

      // remove old account balance
      dispatch({
        type: constants.SET_ACCOUNT_BALANCE,
        payload: {
          debtorId,
          accountBalance: undefined,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};
