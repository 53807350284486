import * as React from "react";

import SelectFilter from "../../../Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "1", display: "1 RoK" },
    { value: "2", display: "2 RoK" },
    { value: "3", display: "3 RoK" },
    { value: "4", display: "4 RoK" },
    { value: "5", display: "5 RoK" },
    { value: "6", display: "6 RoK" },
    { value: "other", display: "Övrigt" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
