import { useForm } from "../../base";

import { constants, getPatchForm, getPostForm } from "..";

export function useBrfManualCapitalAddonForm(method, id) {
  let params = {
    storeName: constants.STORE_NAME,
    method: method,
    id: id,
  };

  if (method === "POST") {
    params.fetchMethod = getPostForm;
  } else {
    params.fetchMethod = getPatchForm;
  }

  return useForm(params);
}
