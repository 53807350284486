import styled from "styled-components";

export const SignBox = styled.div`
  height: 100px;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(p) => p.url});
`;
