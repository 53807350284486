import * as React from "react";
import classNames from "classnames";

export const PRIMARY_BTN_TYPES = {
  STANDARD: "STANDARD",
  ERROR: "ERROR",
  SECONDARY: "SECONDARY",
  SECONDARY_ERROR: "SECONDARY_ERROR",
  WARNING: "WARNING",
  WARNING: "SECONDARY_WARNING",
};

export default function PrimaryBtn({
  onClick,
  children,
  warning,
  secondaryWarning,
  error,
  highlight,
  secondaryHighlight,
  secondary,
  gray,
  secondaryError,
  className = "",
  disabled,
}) {
  const [hovered, setHovered] = React.useState(false);

  return (
    <button
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      disabled={disabled}
      type="button"
      className={classNames(
        "focus:ring-4 focus:outline-none whitespace-nowrap font-medium rounded text-sm px-4 py-2 text-center inline-flex items-center",
        {
          "text-white bg-slate-400 cursor-not-allowed focus:ring-slate-200":
            disabled,
          "text-slate-500 bg-slate-100 ring-1 ring-slate-300":
            gray && !disabled,
          "text-white bg-green-500 hover:bg-green-500/90 focus:ring-green-200":
            highlight && !disabled,
          "bg-white text-green-500 hover:bg-green-50 focus:ring-green-200 border border-solid border-green-500":
            secondaryHighlight && !disabled,
          "text-white bg-red-500 hover:bg-red-500/90 focus:ring-red-200":
            error && !disabled,
          "text-white bg-yellow-600 hover:bg-yellow-700  focus:ring-yellow-500":
            warning && !disabled,
          "bg-white text-orange-400 hover:bg-orange-100  focus:ring-orange-200 border border-solid border-orange-400":
            secondaryWarning && !disabled,
          "bg-white text-primaryblue/100 hover:bg-sky-50 focus:ring-sky-200 border border-solid border-primaryblue/30":
            secondary && !disabled,
          "bg-white text-red-500 hover:bg-red-50 focus:ring-red-200 border border-solid border-red-500":
            secondaryError && !disabled,
          "text-white bg-primaryblue hover:bg-primaryblue/90 focus:ring-sky-200":
            !disabled &&
            !highlight &&
            !secondaryHighlight &&
            !error &&
            !warning &&
            !secondaryWarning &&
            !secondary &&
            !secondaryError &&
            !gray,
        },
        className
      )}
    >
      {typeof children === "function" ? children({ hovered }) : children}
    </button>
  );
}
