import * as React from "react";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";

import { PrimaryButton } from "../../../../components/Forms/Base/Buttons";
import PremisesStatusModalForm from "../../../../components/Forms/PremisesStatus/ChapterForm/ModalForm";
import PremisesStatusTable from "../../../../components/Tables/PremisesStatuses/FullTable";
import {
  BareLayoutWrapper,
  DetailPageBox,
} from "../../../../components/sharedStyles";
import * as SharedStyles from "../../../../components/sharedStyles";
import PageTitle from "../../../Layouts/PageTitle/PageTitle";

const breadCrumbs = [
  {
    label: "Objektsstatusar",
  },
];

export default function PremisesStatusesOverview() {
  const [createOpen, setCreateOpen] = React.useState(false);

  return (
    <>
      <PremisesStatusModalForm
        method="POST"
        isOpen={createOpen}
        onCheckout={() => setCreateOpen(false)}
      />

      <BareLayoutWrapper>
        <SharedStyles.BareLayoutTitleWrapper>
          <PageTitle title="Objektsstatusar" breadCrumbs={breadCrumbs} />
        </SharedStyles.BareLayoutTitleWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Objektsstatusar i systemet</OverviewTitle>
              <OverviewSubtitle>
                Dessa statusar kan sättas på hyreslägenheter, lokaler och
                fordonsplatser för bland annat kategorisering och
                vakansbokningar
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Lägg till ny status"
              clicked={() => setCreateOpen(true)}
            />
          </OverviewTitleWrapper>

          <PremisesStatusTable isBare ignoreLocalStorage />
        </DetailPageBox>
      </BareLayoutWrapper>
    </>
  );
}
