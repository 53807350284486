export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MEASUREVALUE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MEASUREVALUE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MEASUREVALUE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MEASUREVALUE",
  DESTROY_FORM: "DESTROY_FORM_MEASUREVALUE",
  SET_FORM_ERROR: "SET_FORM_ERROR_MEASUREVALUE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MEASUREVALUE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MEASUREVALUE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MEASUREVALUE",
  RESET_STATE: "RESET_STATE_MEASUREVALUE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MEASUREVALUE",
  REMOVE_OBJECT: "REMOVE_OBJECT_MEASUREVALUE",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_MEASUREVALUE",
  CLEAR_FETCHED: "CLEAR_FETCHED_MEASUREVALUE",

  LIST_URL: "/imd/sensormeasurevalue/list/",
  POST_URL: "/imd/sensormeasurevalue/",
  PATCH_URL: "/imd/sensormeasurevalue/",
  GET_URL: "/imd/sensormeasurevalue/",

  STORE_NAME: "IMDMeasureValue",
};
