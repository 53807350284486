import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
  buildQueryString,
} from "../../base";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
} from "../store/actions";
import constants from "../store/constants";
import { usePaginationCount } from "../../base/hooks/retrieve";

export function useParkingContract(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useParkingContracts(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredParkingContracts(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useParkingContractPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useParkingContractPaginationCount({
  statuses,
  realEstates,
  draft,
}) {
  const queryString = buildQueryString({
    state__in: statuses,
    realestate_ids: realEstates,
    _page: 1,
    _page_size: 0,
    draft,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}
