import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, BooleanLabel } from "../../Displays";

import SimpleTextFilter from "../../Lists/Base/CompleteList/Filters/TextFilter";

import CategoryFilter from "./Filters/CategoryFilter";
import NumberRangeFilter from "../../Lists/Base/CompleteList/Filters/NumberRangeFilter";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as brfCompanyDetailUrl } from "../../../store/brfCompanies";
import { detailUrl as buildingDetailUrl } from "../../../store/buildings";

import InvoiceFilter from "../LeaseContracts/Filters/InvoiceFilter";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { renderFloors } from "../../Forms/Base/FieldComponents/FloorSelector";
import SimpleNumberFilter from "../../Lists/Base/CompleteList/Filters/NumberFilter";
import { toMoneyString } from "../../utils/stringUtils";

export default (hasBillectaViewPermission) => {
  const cols = [
    {
      Header: "Lägenhetsnummer",
      accessor: "premises_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Objektsnummer",
      accessor: "internal_premises_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Adress",
      accessor: "address",
      Cell: ({ value }) => {
        let text = "";

        if (!value) {
          text = "Ingen adress";
        } else {
          text = value?.base || value.str_representation;
        }

        return <ToolTipCell text={text} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Innehavare",
      accessor: "tenant.str_representation",
      Cell: ({ row }) => {
        return row.original.tenant ? (
          <LinkedObject
            obj={{
              id: row.original.tenant.id,
              str_representation: `${row.original.tenant.str_representation}${
                row.original?.bi_tenants?.length > 0
                  ? ` + ${row.original?.bi_tenants?.length}`
                  : ""
              }`,
            }}
            urlMethod={tenantDetailUrl}
          />
        ) : (
          "-"
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "BRF",
      accessor: "brf_company.str_representation",
      Cell: ({ row }) => {
        return row.original.brf_company ? (
          <LinkedObject
            obj={row.original.brf_company}
            urlMethod={brfCompanyDetailUrl}
          />
        ) : (
          "-"
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Andel av BRF",
      accessor: "quota",
      Cell: ({ row }) => (
        <ToolTipCell
          text={row.original.quota ? `${row.original.quota}%` : "0"}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Upplåtelseavgift",
      accessor: "placement_fee",
      Cell: ({ value }) => <ToolTipCell text={toMoneyString(value, true)} />,
      Filter: SimpleNumberFilter,
      filter: "between",
    },
    {
      Header: "Insatskapital",
      accessor: "investment_capital",
      Cell: ({ value }) => <ToolTipCell text={toMoneyString(value, true)} />,
      Filter: SimpleNumberFilter,
      filter: "between",
    },
    {
      Header: "Fastighet",
      accessor: "realestate.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Byggnad",
      accessor: "building.str_representation",
      Cell: ({ row }) => {
        return row.original.building ? (
          <LinkedObject
            obj={row.original.building}
            urlMethod={buildingDetailUrl}
          />
        ) : (
          "-"
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Våning",
      accessor: "dynamic_floors",
      // for convenience, backend allow filtering on summed_floors
      id: "dynamicFloors",
      Cell: ({ value }) => <ToolTipCell text={renderFloors(value)} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Area (m2)",
      accessor: "area",
      Cell: ({ value }) => <ToolTipCell text={value.toLocaleString("sv")} />,
      Filter: NumberRangeFilter,
      filter: "between",
    },
    {
      Header: "Kategori",
      accessor: "category",
      Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
      Filter: CategoryFilter,
      filter: "text",
    },
  ];

  if (hasBillectaViewPermission) {
    cols.splice(2, 0, {
      Header: "Aviseringsstatus",
      accessor: "brf_invoicing_active",
      Cell: ({ value }) => (
        <StatusLabel
          state={
            value === true ? 1 : value === null ? 6 : value === false ? 3 : 10
          }
        >
          {value === true
            ? "Konfigurerad"
            : value === null
            ? "Ej konfigurerad"
            : value === false
            ? "Pausad"
            : "Okänd"}
        </StatusLabel>
      ),
      Filter: InvoiceFilter,
      filter: "textExact",
    });
  }

  return cols;
};
