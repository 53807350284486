import * as React from "react";
import classNames from "classnames";

export default function LocalCheckField({
  value,
  onChange,
  title,
  description,
  required,
  disabled,
  error,
  className,
  labelClassName,
  rounded,
  large,
}) {
  return (
    <div className={className && className}>
      <div className="flex">
        <div className="flex items-center h-5 relative">
          {rounded && (
            <label
              className={classNames(
                "absolute rounded-full border border-solid border-primaryblue w-4 h-4 cursor-pointer flex items-center justify-center",
                large && "w-5 h-5"
              )}
              for="checkbox"
            >
              <label
                className={classNames(
                  "w-2 h-2 rounded-full cursor-pointer",
                  value && "bg-primaryblue"
                )}
              ></label>
            </label>
          )}
          <input
            id={`check-${title?.replace(" ", "-")}`}
            type="checkbox"
            value=""
            checked={value}
            disabled={disabled}
            onChange={({ target: { checked } }) => onChange(checked)}
            className={classNames(
              `w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 ${
                disabled ? "cursor-not-allowed" : ""
              }`,
              rounded && "invisible",
              large && "w-5 h-5"
            )}
          />
        </div>
        <div className="ml-2 text-sm">
          <label
            htmlFor={`check-${title?.replace(" ", "-")}`}
            className={classNames(
              "font-medium text-gray-900 cursor-pointer",
              labelClassName && labelClassName
            )}
          >
            {title}
            {required ? "*" : ""}
          </label>
          {description && (
            <p
              id="helper-checkbox-text"
              className="text-xs font-normal text-gray-500 bg-transparent "
            >
              {description}
            </p>
          )}
          {error && (
            <p
              id="helper-checkbox-text"
              className="text-xs font-normal text-red-700  bg-transparent"
            >
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
