import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import AsyncSelect from "../Fields/AsyncSelect";
import NewSingleItem from "../Decorations/NewSingleItem";
import StandardModal from "../../../Modals/StandardModal";

// store, state
import {
  updateActiveFormInstance,
  updateFormErrors,
  useFormField,
} from "../../../../store/base";

export default React.memo(
  ({
    storeName,
    method,
    fieldKey,
    fetchMethod,
    children,
    constructStrRep,
    instructionsKey,
    modalTitle,
    tryNestedUpdate,
    modalInModal,
    title,
  }) => {
    const dispatch = useDispatch();

    const [handleIsActive, setHandleActive] = React.useState(false);
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });

    const allowNull = instructions?._allowNull;
    const defValue = allowNull !== undefined && allowNull ? null : undefined;

    const onCreateClicked = () => {
      // clear all data
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: defValue } })
      );

      // clear all errors
      dispatch(
        updateFormErrors({ storeName, data: { [fieldKey]: undefined } })
      );

      // open up handleModal
      setHandleActive(true);
    };

    const onRemoveCreated = () => {
      // clear all data
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: defValue } })
      );

      // clear all errors
      dispatch(
        updateFormErrors({ storeName, data: { [fieldKey]: undefined } })
      );
    };

    const onEditClicked = () => {
      // open up handleMOdal
      setHandleActive(true);
    };

    return (
      <>
        <AsyncSelect
          storeName={storeName}
          method={method}
          fieldKey={fieldKey}
          fetchMethod={fetchMethod}
          onCreateClicked={onCreateClicked}
          instructionsKey={instructionsKey}
          title={title}
        />
        <NewSingleItem
          storeName={storeName}
          fieldKey={fieldKey}
          onRemove={onRemoveCreated}
          onEdit={onEditClicked}
          constructStrRep={constructStrRep}
          instructionsKey={instructionsKey}
          method={method}
          tryNestedUpdate={tryNestedUpdate}
        />
        <StandardModal
          modalInModal={modalInModal}
          isOpen={handleIsActive}
          withActionBar
          title={modalTitle ? modalTitle : false}
          closeFunction={() => setHandleActive(false)}
          saveFunction={() => setHandleActive(false)}
        >
          {children}
        </StandardModal>
      </>
    );
  }
);
