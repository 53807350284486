export default [
  {
    title: "Komponenttyp",
    key: "COMPONENT_TYPE",
    visited: true,
    hasError: false,
    fieldKeys: [
      "name",
      "report_errand_checklist",
      "inspection_errand_checklist",
      "rounding_errand_checklist",
      "send_faults_to_user",
    ],
  },
];
