import * as React from "react";

export default () => {
  return (
    <div>
      Ange information samt regler och specifikationer för parkeringsplatsen.{" "}
      <br /> <br />
      <strong>OBS:</strong> Om ingen specifikation väljs kommer den att ärvas
      från parkeringsområdet.
    </div>
  );
};
