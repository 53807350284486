import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import StandardModal from "../../../components/Modals/StandardModal";
import { BodyText, DetailLayoutWrapper } from "../../../components/sharedStyles";
import { useInvoicingIMD, constants } from "../../../store/invoicingIMDSettings";
import { useInvoicingSettingPaginationCount } from "../../../store/invoicingSettings/hooks/retrieve";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import InvoicingIMDSettingUsedBy from "./debtSetting/UsedBy";
import InvoicingIMDSettingOverview from "./imdSetting/Overview";

function IMDSettingDetail() {
  const { settingId } = useParams();
  const { path, url } = useRouteMatch();

  const [imdSetting] = useInvoicingIMD(settingId);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [cantDeleteInfoOpen, setCantDeleteInfoOpen] = React.useState(false);

  const { replace } = useHistory();
  const [usedByCount] = useInvoicingSettingPaginationCount({
    filters: { imd_setting: settingId },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: InvoicingIMDSettingOverview,
    },
    {
      path: "/used-by",
      label: `Används av (${usedByCount || 0})`,
      component: InvoicingIMDSettingUsedBy,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [
    {
      name: "Radera",
      isDelete: true,

      onClick:
        usedByCount > 0
          ? () => setCantDeleteInfoOpen(true)
          : () => setDeleteOpen(true),
    },
  ];

  return (
    <>
      <StandardModal
        withActionBar
        title="Kan ej radera inställning"
        isOpen={cantDeleteInfoOpen}
        actionBarCancelTitle="Stäng"
        closeFunction={() => setCantDeleteInfoOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Denna inställning kan ej raderas då den används av {usedByCount}{" "}
            objekt
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          För att kunna radera denna inställning måste de
          aviseringsinställningarna som använder sig av den byta inställning för
          betalningsvillkor. Under fliken "Används av" finns en detaljerad lista
          över vilka objekt som använder sig av denna inställning.
        </BodyText>
      </StandardModal>

      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={imdSetting}
        constants={constants}
        deletedCallback={() => replace("/configcenter/rental-notifications")}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Inställning för IMD-debitering ${
            imdSetting?.str_representation || "Laddar..."
          }`}
          subPages={subPageLinks}
          breadCrumbs={[
            {
              url: "/configcenter/rental-notifications/imd-setting",
              label: "IMD-Debiteringsinställningar",
            },
            {
              label: `Inställning för IMD-debitering ${
                imdSetting?.str_representation || "Laddar..."
              }`,
            },
          ]}
          handleActions={handleActions}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default IMDSettingDetail;
