import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useAllPermissionCheck } from "../store/base";
import { useSupervisorCheck } from "../store/base/hooks/permissions";



export default function usePermissionRedirect(permissions = []) {
  const isSupervisor = useSupervisorCheck()
  const user = useSelector((state) => state.app.user);
  const { replace } = useHistory();

  const hasPermissions = useAllPermissionCheck(permissions);

  if (isSupervisor) {
    return true;
  }
  
  if (!user?.permissions || permissions?.length === 0) return;
  if (!hasPermissions) {
    replace(`/permission-denied?perms=${permissions.join(",")}`);
  }

  return true;
}
