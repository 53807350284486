import * as React from "react";

export const STATUS_FLAG_STATES = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
  SUCCESS: "SUCCESS",
};

const BORDER_COLOR_MAP = {
  ERROR: "border-red-500",
  WARNING: "border-yellow-500",
  INFO: "border-primaryblue",
  SUCCESS: "border-green-500",
};

const BG_COLOR_MAP = {
  ERROR: "bg-red-50",
  WARNING: "bg-yellow-50",
  INFO: "bg-primaryblue/5",
  SUCCESS: "bg-green-50",
};

const TEXT_COLOR_MAP = {
  ERROR: "text-red-500",
  WARNING: "text-yellow-900",
  INFO: "text-primaryblue",
  SUCCESS: "text-green-600",
};

export default function StatusFlag({ state, children, className }) {
  return (
    <div
      className={`rounded inline-flex font-medium text-sm p-2 ${BG_COLOR_MAP[state]} ${TEXT_COLOR_MAP[state]} items-center border ${BORDER_COLOR_MAP[state]}  ${className}`}
    >
      {children}
    </div>
  );
}
