import * as React from "react";
import { useFormInstanceField } from "../../../../../store/base";

// store, state
import { constants } from "../../../../../store/parkingContracts";
import DetailInfo from "../../../../Details/OverviewInfo/DetailInfo/DetailInfo";
import { DateCell, DurationCell } from "../../../../Displays";
import {
  DatePicker,
  RadioGroup,
  Select,
  TextField,
} from "../../../Base/Fields";

export default ({ method, parkingContract }) => {
  const storeName = constants.STORE_NAME;

  const status = useFormInstanceField({
    storeName,
    fieldKey: "status",
  });

  const infoObj = {
    "Avtalets villkor": [
      {
        title: "Uppsägningstid",
        value: (
          <DurationCell durationStamp={parkingContract?.notify_interval} />
        ),
      },
      {
        title: "Förlägningstid",
        value: <DurationCell durationStamp={parkingContract?.renew_interval} />,
      },
      {
        title: "Slutdatum",
        value: <DateCell data={parkingContract?.end_date} />,
      },
    ],
  };

  return (
    <>
      <DetailInfo infoObj={infoObj} />

      <RadioGroup
        {...{
          storeName,
          method,
          fieldKey: "landlord_cancelled",
          title: "Ange vem som sa upp avtalet",
        }}
        options={[
          { label: "Uppsagt av Hyresvärd", value: true },
          { label: "Uppsagt av Hyresgäst ", value: false },
        ]}
        defaultValue={true}
      />

      <Select
        {...{
          storeName,
          method,
          fieldKey: "status",
          title: "Typ av uppsägning",
        }}
        filterChoices={(c) => [6, 7].includes(c.v)}
      />

      <DatePicker
        {...{
          storeName,
          method,
          fieldKey: "closed_on",
          title: "Uppsägningsdatum",
          description: "Ange vilket datum avtalet sas upp",
        }}
      />

      {status === 6 && (
        <DatePicker
          {...{
            storeName,
            method,
            fieldKey: "closed_date",
            title: "Datum för utflytt",
            description: "Ange vilket datum utflytt ska ske",
          }}
        />
      )}
      {status === 7 && (
        <DatePicker
          {...{
            storeName,
            method,
            fieldKey: "closed_renew_date",
            title: "Datum för avtalsändring",
            description: "Ange vilket datum det nya avtalet träder i kraft",
          }}
        />
      )}

      <TextField
        {...{
          storeName,
          method,
          fieldKey: "closed_cause",
          title: "Anledning till uppsägning",
        }}
      />
    </>
  );
};
