import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingOther";
import { useFormInstanceField } from "../../../../../store/base";

import { useInvoicingSetting } from "../../../../../store/invoicingSettings";
import InvoicingSettingSummary from "../../../../Summaries/InvoicingSettingSummary";
import { RadioGroup } from "../../../Base/Fields";

import { useParams } from "react-router-dom";
import { useOtherContract } from "../../../../../store/otherContracts";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";
import CompanyTable from "src/components/Tables/Companies/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const [existingSetting] = useInvoicingSetting(defaultSetting?.id);

  const { otherContractId } = useParams();

  const [otherContract] = useOtherContract(otherContractId);

  return (
    <>
      <FormAreaTitle>Aviseringsinställning</FormAreaTitle>

      {!!otherContract?.main_contract && (
        <RadioGroup
          title="Avisera för detta avtal tillsammans med huvudavtalet"
          {...{ storeName, fieldKey: "bill_with_lease", method }}
          defaultValue={true}
          options={[
            { label: "Samavisera med huvudavtalet", value: true },
            { label: "Avisera detta avtal separat", value: false },
          ]}
        />
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"billing_company"}
          placeholder="Välj fakturerande bolag..."
          title="Fakturerande bolag"
          description={
            "Välj vilket bolag detta avtal ska aviseras ifrån. Om inget bolag väljs kommer bolaget som hyresobjektet tillhör att användas"
          }
          TableComponent={CompanyTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"setting"}
          placeholder="Välj aviseringsinställning..."
          title="Aviseringsinställning"
          description={
            "Välj vilken basinställning för avisering som ska användas för avtalet"
          }
          TableComponent={InvoicingSettingTable}
        />
      </div>

      {defaultSetting?.id && (
        <>
          {existingSetting ? (
            <InvoicingSettingSummary setting={existingSetting} />
          ) : null}
        </>
      )}
    </>
  );
};
