export default [
  {
    title: "Steg",
    key: "STAGE",
    visited: true,
    hasError: false,
    fieldKeys: ["name", "description", "order"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["pipe"],
  },
];
