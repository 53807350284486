import { constants } from "..";
import { addToProcess } from "../../base";
import { removeFromProgress } from "../../base/store/actions";
import * as services from "./services";

export const performSearch = ({
  searchParams,
  successCallback,
  errorCallback,

  // custom response handling - both needed
  resultDispatchType,
  searchId,
}) => {
  return async (dispatch) => {
    try {
      const resp = await services.search({ searchParams });

      if (resultDispatchType && searchId) {
        dispatch({
          type: resultDispatchType,
          payload: {
            result: resp,
            searchId,
          },
        });
      } else {
        dispatch({
          type: constants.SET_SEARCH_RESULT,
          payload: {
            result: resp,
          },
        });
      }

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

export const performMultiCreditorReturnSearch = ({
  searchInstances,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const calls = searchInstances.map((s) =>
        services.search({ searchParams: s })
      );
      const resp = await Promise.all(calls);

      successCallback(resp);
    } catch (e) {
      errorCallback(e);
    }
  };
};

export const performMultipleInvoiceSearches = ({
  searchInstances = [], // array of searchparams
  successCallback,
  errorCallback,
  typeConstants,
  name,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, typeConstants, name);
      const searches = searchInstances.map((s) =>
        services.search({ searchParams: s })
      );
      const responses = await Promise.all(searches);

      const invoices = responses.reduce((acc, cur) => {
        const currentInvoices = cur.InvoiceActions;

        return [...acc, ...currentInvoices];
      }, []);

      if (typeConstants && name) {
        dispatch({
          type: typeConstants.SET_BILLECTA_SEARCH_RESULT,
          payload: {
            result: invoices,
            name,
          },
        });
      } else {
        throw Error("constants or searchId missing");
      }

      successCallback && successCallback();

      removeFromProgress(dispatch, typeConstants, name);
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback();
    }
  };
};

export const performDebtorSearch = ({
  debtorId,
  creditorId,
  errorCallback,
  name,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, name);

      const searchParams = {
        DebtorPublicIds: [debtorId],
        CreditorPublicId: creditorId,
      };
      const resp = await services.search({ searchParams });

      dispatch({
        type: constants.SET_CUSTOM_SEARCH_RESULT,
        payload: {
          result: resp.InvoiceActions || [],
          name,
        },
      });

      removeFromProgress(dispatch, constants, name);
    } catch (e) {
      console.log(e);
      errorCallback();
    }
  };
};

export const getOpenDebitInvoicesForDebtor = ({
  debtorId,
  creditorId,
  successCallback,
  errorCallback,
  withNonAttested = false,
}) => {
  return async (dispatch) => {
    try {
      const searchParams = {
        DebtorPublicIds: [debtorId],
        CreditorPublicId: creditorId,
        Status: ["Open"],
        Types: ["InvoiceAction", "PaymentAdviceAction"],
      };

      const resp = await services.search({ searchParams });

      if (withNonAttested) {
        successCallback(resp.InvoiceActions);
      } else {
        successCallback(resp.InvoiceActions?.filter((i) => !!i.AttestedDate));
      }
    } catch (e) {
      errorCallback(e?.response?.Message);
    }
  };
};

export const clearSearch = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_SEARCH_RESULT,
      payload: {
        result: null,
      },
    });
  };
};

export const clearSearchWithId = ({ name, typeConstants }) => {
  return async (dispatch) => {
    dispatch({
      type:
        typeConstants?.SET_BILLECTA_SEARCH_RESULT ||
        constants.SET_CUSTOM_SEARCH_RESULT,
      payload: {
        name,
        result: null,
      },
    });
  };
};
