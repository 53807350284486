import { PlusIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { useCanUseScrive } from "../../../store/editabledocs/hooks/retrieve";
import Alert, { ALERT_TYPES } from "../../Alert/Alert";
import { SIGN_ROLE_MAP, CATEGORY_MAP } from "../../DigitalDoc/utils";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import Modal from "../../Forms/Base/Modals/Modal";
import PremisesTable from "../../PremisesTable/PremisesTable";
import HandleParty from "./HandleParty";

export default function HandleParties({
  storeName,
  isTemplateEditor = false,
  usesESigning,
  closeFunction,
  method,
}) {
  const dispatch = useDispatch();

  const [canUseScrive] = useCanUseScrive();

  const [editIndex, setEditIndex] = React.useState(null);

  const parties = useFormInstanceField({
    storeName,
    fieldKey: "parties",
  });

  const removeParty = (idx) => {
    const partiesClone = cloneDeep(parties);

    partiesClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          parties: partiesClone,
        },
      })
    );
  };

  const handleAddParty = () => {
    const hasTenant = !!parties?.find((p) => p.category === "tenant");
    const hasLandlord = !!parties?.find((p) => p.category === "landlord");
    const initialData = {
      category:
        hasTenant && hasLandlord
          ? "bi_tenant"
          : hasTenant
          ? "landlord"
          : "tenant",
      fields: [
        {
          kind: "first_name",
          name: "Förnamn",
          value: isTemplateEditor
            ? `Förnamn part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "last_name",
          name: "Efternamn",
          value: isTemplateEditor
            ? `Efternamn part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "personal_number",
          name: "Personnummer",
          value: isTemplateEditor
            ? `Personnummer part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "company",
          name: "Företagsnamn",
          value: isTemplateEditor
            ? `Företagsnamn part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "company_number",
          name: "Organisationnummer",
          value: isTemplateEditor
            ? `Orgnr part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "email",
          name: "Email",
          is_obligatory: true,
          value: isTemplateEditor
            ? `Email part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "phone",
          name: "Telefonnummer",
          value: isTemplateEditor
            ? `Telefonnummer part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "separate_address",
          name: "Övrig adress: Gatuadress",
          value: isTemplateEditor
            ? `Övrig adress: Gatuadress part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "separate_address_postalcode",
          name: "Övrig adress: Postnr.",
          value: isTemplateEditor
            ? `Övrig adress: Postnr. part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "separate_address_city",
          name: "Övrig adress: Postort",
          value: isTemplateEditor
            ? `Övrig adress: Postort. part ${(parties || [])?.length + 1}`
            : "",
        },
      ],
      // settings defaults
      signatory_role: "signing_party",
      delivery_method: "email",
      confirmation_delivery_method: "email",
      authentication_method_to_sign: "standard",
      authentication_method_to_view: "standard",
    };

    const partiesCopy = cloneDeep(parties || []);
    partiesCopy.push(initialData);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          parties: partiesCopy,
        },
      })
    );

    setEditIndex(partiesCopy.length - 1);
  };

  const handleEditParty = (_, idx) => {
    setEditIndex(idx);
  };

  const hasMultipleTenants =
    parties?.filter((p) => p.category === "tenant")?.length > 1;
  const hasMultipleLandlords =
    parties?.filter((p) => p.category === "landlord")?.length > 1;

  if (editIndex != null && parties[editIndex])
    return (
      <HandleParty
        storeName={storeName}
        onDone={() => setEditIndex(null)}
        idx={editIndex}
        isTemplateEditor={isTemplateEditor}
        usesESigning={usesESigning}
        method={method}
      />
    );

  return (
    <Modal title="Hantera parter" closeFunction={closeFunction}>
      {hasMultipleTenants && (
        <Alert
          type={ALERT_TYPES.DANGER}
          title="Endast en huvudsaklig hyresgäst tillåten"
        >
          Endast en part får ha kategorin "Hyresgäst". De övriga hyresgästerna
          ska ha kategorin "Övrig hyresgäst".
        </Alert>
      )}
      {hasMultipleLandlords && (
        <Alert type={ALERT_TYPES.DANGER} title="Endast en hyresvärd tillåten">
          Endast en part får ha kategorin "Hyresvärd". De övriga parterna som ej
          är hyresgäster ska ha kategorin "Annan".
        </Alert>
      )}

      <PremisesTable
        onRowClicked={handleEditParty}
        headers={["Färg", "Namn", "E-post", "Roll (Scrive)", "Ta bort"]}
        rows={parties?.map((p, idx) => {
          const partyFields = p.fields;
          const firstName = partyFields.find(
            (f) => f.kind === "first_name"
          )?.value;
          const lastName = partyFields.find(
            (f) => f.kind === "last_name"
          )?.value;
          const company = partyFields.find((f) => f.kind === "company")?.value;

          const email = partyFields.find((f) => f.kind === "email")?.value;

          return [
            <div
              style={{ backgroundColor: p._color }}
              className={`w-[22px] h-[22px] min-h-[22px] min-w-[22px] rounded-full`}
            >
              &nbsp;
            </div>,
            `${
              isTemplateEditor
                ? `Part ${idx + 1} (${CATEGORY_MAP[p.category] || "-"})`
                : `${firstName || ""} ${lastName || ""} ${company || ""}`
            }`,
            email,
            canUseScrive ? SIGN_ROLE_MAP[p.signatory_role] : "-",
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              onClick={(e) => {
                e.stopPropagation();
                removeParty(idx);
              }}
            >
              <XCircleIcon width={24} />
            </button>,
          ];
        })}
      />

      <div className="flex justify-end mt-6">
        <PrimaryBtn onClick={handleAddParty}>
          Lägg till part <PlusIcon className="ml-1" width={16} />
        </PrimaryBtn>
      </div>
    </Modal>
  );
}
