import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/apartments";

import { useSelector } from "react-redux";

import moment from "moment";

import Cookies from "js-cookie";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";
import { BADGE_TYPES } from "../../Badge/Badge";

export default function ApartmentTable({
  persistantQuery,
  tableId,
  ignoreLocalStorage,
  isBare,
  withCosts = true,
  onlyWithCosts,
  ads,
  checkRowHighlighted,
  onRowClicked,
}) {
  const sDate = moment({ year: moment().year(), month: 0, day: 1 });
  const eDate = moment({ year: moment().year(), month: 11, day: 31 });
  const [startDate, setStartDate] = React.useState(sDate.format("YYYY-MM"));
  const [endDate, setEndDate] = React.useState(eDate.format("YYYY-MM"));

  const [useSpecifiedPeriod, setUseSpecifiedPeriod] = React.useState(false);
  const [useSqm, setUseSqm] = React.useState(false);
  const [showMonthlyCosts, setShowMonthlyCosts] = React.useState(false);

  const customerId = React.useMemo(() => {
    try {
      return JSON.parse(Cookies.get("intercom_customer") ?? {})?.id;
    } catch (e) {
      return null;
    }
  }, []);

  /// TEMP
  const canViewSensitiveInfoTemp = useSelector(
    (state) =>
      (state.app.user?.user_type === 1 || state.app.user?.user_type === 0) &&
      (customerId != 128 || state.app.user?.user_type === 0)
  );

  const columns = React.useMemo(
    () =>
      columnDefs({
        startDate,
        endDate,
        canViewSensitiveInfoTemp,
        ads,
        withCosts,
        onlyWithCosts,
        useSqm,
        showMonthlyCosts,
        useSpecifiedPeriod,
      }),
    [
      startDate,
      endDate,
      persistantQuery,
      ads,
      withCosts,
      onlyWithCosts,
      useSqm,
      showMonthlyCosts,
      useSpecifiedPeriod,
      canViewSensitiveInfoTemp,
    ]
  );
  // Referenshyra (SEK/månad) and Referenshyra and leasecontract_amount ( base_rent_year = e.base_rent?e.base_rent * 12) disabled filter/order for now

  //SEEMS TO BE A LOT OF EDGE CASES HERE (check export)

  const filters = {
    Lägenhetsnummer: {
      queryKey: "apartment_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },

    Objektsnummer: {
      queryKey: "premises_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Kategori: {
      queryKey: "category",
      type: "select",
      operators: OPERATOR_TEMPLATES.SELECT,
      choices: [
        {
          v: "1",
          d: "1 RoK",
        },
        {
          v: "1.5",
          d: "1.5 RoK",
        },
        {
          v: "2",
          d: "2 RoK",
        },
        {
          v: "2.5",
          d: "2.5 RoK",
        },
        {
          v: "3",
          d: "3 RoK",
        },
        {
          v: "3.5",
          d: "3.5 RoK",
        },
        {
          v: "4",
          d: "4 RoK",
        },
        {
          v: "4.5",
          d: "4.5 RoK",
        },
        {
          v: "5",
          d: "5 RoK",
        },
        {
          v: "5.5",
          d: "5.5 RoK",
        },
        {
          v: "6",
          d: "6 RoK",
        },
        {
          v: "6.5",
          d: "6.5 RoK",
        },
        {
          v: "other",
          d: "Annan",
        },
      ],
    },
    Adress: {
      queryKey: "address_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Fastighet: {
      queryKey: "realestate_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Byggnad: {
      queryKey: "building_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Area: {
      queryKey: "area",
      type: "number",
      operators: OPERATOR_TEMPLATES.NUMBER,
    },
    Hyresgäst: {
      queryKey: "tenant_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Hyresvärd: {
      queryKey: "landlord_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <BaseTable
      tableId={tableId || "apartments_full_table"}
      title="Hyreslägenheter"
      onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
      {...{
        checkRowHighlighted,
        onRowClicked,
        ignoreLocalStorage,
        isBare,
        badges,
        filters,
        constants,
        columns,
        persistantQuery,
      }}
    />
  );
}
