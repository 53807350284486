import * as React from "react";

// style, design

import columnDefs from "./listDefs";

// store, state
import {
  constants,
  detailUrl,
  useFilteredBrfCompanies,
} from "../../../store/brfCompanies";
import { buildQueryString, useFrequentPermissions } from "../../../store/base";
import { useDispatch } from "react-redux";
import { setActiveFilteredRealEstates } from "../../../store/overview/store/actions";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { useHistory } from "react-router";
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function BrfTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  withSelect,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [selected, setSelected] = React.useState([]);

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const columns = React.useMemo(
    () => columnDefs(hasBillectaViewPermission),
    [persistantQuery, hasBillectaViewPermission]
  );

  const [selectedBrfCompanies, selectedBrfCompaniesLoading] =
    useFilteredBrfCompanies(
      buildQueryString({
        id__in: selected || [],
      })
    );

  const realEstateIds = (selectedBrfCompanies || [])
    ?.map((c) => c.realestates)
    .flat();

  const realEstateQuery = buildQueryString({
    id__in: realEstateIds || [],
  });

  const [realEstates, realEstatesLoading] =
    useFilteredRealEstates(realEstateQuery);

  const setFilter = () => {
    dispatch(
      setActiveFilteredRealEstates({
        realEstateIds: realEstates?.map((r) => r.id),
        brfCompanyIds: selected || [],
      })
    );
    push("/");
  };

  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "brfcompany_full_table"}
        title={"Bostadsrättsföreningar"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected: withSelect ? (obj) => setSelected(obj) : undefined,
        }}
      />
      {selected?.length > 0 && withSelect && (
        <div className="fixed bottom-0 left-0 right-0 px-24 py-2 bg-white border-t border-solid border-slate-100 shadow-sm flex justify-end">
          <PrimaryBtn highlight onClick={setFilter}>
            Filtrera systemet på {selected?.length} BRF:er
          </PrimaryBtn>
        </div>
      )}
    </>
  );
}
