import * as React from "react";

// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TextField } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/tenantPortalSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Jour och akutnummer</OverviewTitle>
          <OverviewSubtitle>
            Fyll i information kring Jour och akutnummer
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextField
        storeName={storeName}
        fieldKey={"jour_info"}
        method={method}
        title="Jourinformation"
      />
    </>
  );
};
