import * as React from "react";

import { ToolTipCell, LinkedObject, DateCell } from "../../Displays";
import { detailUrl as companyDetailUrl } from "../../../store/companies";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default ({ triggerManual }) => [
  {
    Header: " ",
    id: "manual_trigger",
    Cell: ({ row }) => (
      <PrimaryBtn
        secondary
        onClick={(e) => {
          e.stopPropagation();
          triggerManual(row.original.id);
        }}
      >
        Trigga manuellt jobb
      </PrimaryBtn>
    ),
  },
  {
    Header: "Bolag",
    accessor: "company",
    Cell: ({ value }) => (
      <LinkedObject obj={value} urlMethod={companyDetailUrl} />
    ),
  },
  {
    Header: "Host",
    accessor: "host",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Port",
    accessor: "port",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Katalog",
    accessor: "base_path",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Filnamnsprefix",
    accessor: "file_name_prefix",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Börjar köras",
    accessor: "start_day",
    Cell: ({ value }) => <DateCell date={value} />,
  },
  {
    Header: "Intervall (dagar)",
    accessor: "day_interval",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
];
