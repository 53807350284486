import * as React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getAvailablePeriods as leaseGetAvailablePeriods } from "../../../store/invoicingLease";
import { getAvailablePeriods as otherGetAvailablePeriods } from "../../../store/invoicingOther";
import { getAvailablePeriods as brfGetAvailablePeriods } from "../../../store/invoicingBrf";
import { getAvailablePeriods as parkingGetAvailablePeriods } from "../../../store/invoicingParking";
import theme from "../../../theme";
import { ItemTitle } from "../../Details/OverviewInfo/DetailInfo/styles";

import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { InnerBox } from "../../sharedStyles";
import { useParams } from "react-router-dom";
import { toMoneyString } from "../../utils/stringUtils";

const getPeriodMethodFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leaseGetAvailablePeriods;
  if (otherContractId) return otherGetAvailablePeriods;
  if (parkingContractId) return parkingGetAvailablePeriods;
  if (brfPremisesId) return brfGetAvailablePeriods;
};

export default function PeriodModal({
  isOpen,
  closeFunction,
  invoicingRecords = [],
  nextInvoicePeriod,
  basedOn,
  id,
  onSelected,
  nextInvoiceTotal,
}) {
  const { leaseContractId, otherContractId, brfPremisesId, parkingContractId } =
    useParams();
  const periodMethod = getPeriodMethodFromParams({
    leaseContractId,
    otherContractId,
    parkingContractId,
    brfPremisesId,
  });
  const dispatch = useDispatch();
  const [availablePeriodsLoading, setAvailablePeriodsLoading] =
    React.useState(null);
  const [availablePeriods, setAvailablePeriods] = React.useState(null);

  React.useEffect(() => {
    if (!basedOn) return;

    setAvailablePeriodsLoading(true);

    dispatch(
      periodMethod({
        id,
        basedOn,
        successCallback: (data) => {
          setAvailablePeriodsLoading(false);

          setAvailablePeriods(data);
        },
        errorCallback: (error) => {
          setAvailablePeriodsLoading(false);

          console.log(error);
        },
      })
    );
  }, [basedOn]);

  const sortedKeys = Object.keys(availablePeriods || {}).sort();

  return (
    <StandardModal
      withActionBar
      title="Välj period avin ska baseras på"
      {...{ isOpen, closeFunction }}
    >
      {availablePeriodsLoading && <OverlaySpinner />}

      {sortedKeys.map((key) => {
        const periodStart = availablePeriods[key]?.period_start;
        const periodEnd = availablePeriods[key]?.period_end;

        const matchingRecord = invoicingRecords?.find((ir) => {
          return ir.period_start == periodStart && ir.period_end == periodEnd;
        });

        const isNext = periodStart == nextInvoicePeriod?.period_start;

        return (
          <InnerBox
            key={key}
            onClick={() =>
              onSelected({
                basedOn: key,
                periodStart,
                periodEnd,
              })
            }
          >
            <ItemTitle style={{ fontSize: 12 }}>
              Avidatum: <span style={{ fontWeight: 600 }}>{key}</span>
            </ItemTitle>
            <ItemTitle>
              Avser period{" "}
              <span style={{ fontWeight: 600 }}>
                {availablePeriods[key].period_start}
              </span>{" "}
              -{" "}
              <span style={{ fontWeight: 600 }}>
                {availablePeriods[key].period_end}
              </span>
            </ItemTitle>
            {!!matchingRecord?.billecta_id && (
              <ItemTitle style={{ color: theme.colors.red }}>
                Avi redan genererad för period
              </ItemTitle>
            )}
            {!!matchingRecord && !!matchingRecord.billecta_id === false && (
              <ItemTitle style={{ color: theme.colors.red }}>
                Generering av avi misslyckades
              </ItemTitle>
            )}

            {isNext && nextInvoiceTotal != null && (
              <ItemTitle
                style={{ fontWeight: 500, color: theme.colors.greenDark }}
              >
                Nästa autogenerering{" "}
                {nextInvoiceTotal === 0 ? (
                  <span style={{ color: theme.colors.red }}>
                    (Genereras ej då beloppet är 0 SEK)
                  </span>
                ) : (
                  `(${toMoneyString(nextInvoiceTotal, true)})`
                )}
              </ItemTitle>
            )}
          </InnerBox>
        );
      })}
    </StandardModal>
  );
}
