import * as React from "react";
import { useDispatch } from "react-redux";

import {
  useRoundingErrandForm,
  destroyPatchForm,
  constants,
  update,
} from "../../../../store/roundingErrands";
import { cloneDeep } from "lodash";
import { setActiveFormInstance } from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import { TimePicker } from "../../Base/Fields";
import DurationField from "../../Base/Fields/DurationField";
import TextInputField from "../../Base/Fields/TextInputField";

export default function RoundingErrandPlanForm({
  id,
  onCheckout,
  instance,
  method,
  isGov,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useRoundingErrandForm("PATCH", id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, [instance]);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));
    onCheckout(success);
  };

  const onSuccess = () => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
        preProcess,
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={"Uppdatera planering"}
      onAccept={onSubmit}
      closeFunction={onDone}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}

      {isGov && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            storeName={storeName}
            fieldKey="inspecting_party"
            title="Besiktande part"
            method={method}
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-6">
        <TimePicker
          storeName={storeName}
          fieldKey={"planned_start"}
          method={method}
          title="Planerat startdatum"
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <TimePicker
          storeName={storeName}
          fieldKey={"planned_end"}
          method={method}
          title="Planerat slutdatum"
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <DurationField
          description="Ange hur lång den förväntade faktistka arbetstiden är"
          storeName={storeName}
          fieldKey={"expected_duration"}
          method={method}
          extraStyles={{ marginTop: "12px" }}
          title="Förväntad arbetstid"
        />
      </div>
    </Modal>
  );
}
