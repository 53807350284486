import { buildQueryString } from "../../base";
import { upload } from "../../base/store/services";
import constants from "./constants";

export const getKU55Data = async (
  brfCompanyId,
  forYear,
  isCorrection,
  excludeIds,
  customCapitalAdditions
) => {
  const q = buildQueryString({
    for_date: `${forYear}-01-01`,
    isCorrection: isCorrection,
    exclude_ids: excludeIds,
  });

  const convertedCapitalAdditions = {};

  if (customCapitalAdditions?.length) {
    for (const cc of customCapitalAdditions) {
      convertedCapitalAdditions[cc.year] = parseInt(cc.value);
    }
  }

  return await upload({
    url: `${constants.GET_KU55_URL}${brfCompanyId}/?${q}`,
    data: {
      custom_capital_addons: convertedCapitalAdditions,
    },
  });
};

export const insertKU55Logs = async (brfCompanyId, transData) => {
  return await upload({
    url: `${constants.POST_KU55_LOG_URL}${brfCompanyId}/`,
    data: { data: transData },
  });
};
