import * as React from "react";

// style, design
// import BaseTable from "src/components/Lists/Base/CompleteList/Table";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/rentIncrease";

import { BADGE_TYPES } from "../../Badge/Badge";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function RentIncreasesTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  // const storeName = constants.STORE_NAME;

  const columns = React.useMemo(() => {
    const data = columnDefs();
    return data;
  }, [persistantQuery]);

  const filters = {
    Lägenhetsnummer: {
      queryKey: "apartment_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },

    Objektsnummer: {
      queryKey: "premises_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Kategori: {
      queryKey: "category",
      type: "select",
      operators: OPERATOR_TEMPLATES.SELECT,
      choices: [
        {
          v: "1",
          d: "1 RoK",
        },
        {
          v: "1.5",
          d: "1.5 RoK",
        },
        {
          v: "2",
          d: "2 RoK",
        },
        {
          v: "2.5",
          d: "2.5 RoK",
        },
        {
          v: "3",
          d: "3 RoK",
        },
        {
          v: "3.5",
          d: "3.5 RoK",
        },
        {
          v: "4",
          d: "4 RoK",
        },
        {
          v: "4.5",
          d: "4.5 RoK",
        },
        {
          v: "5",
          d: "5 RoK",
        },
        {
          v: "5.5",
          d: "5.5 RoK",
        },
        {
          v: "6",
          d: "6 RoK",
        },
        {
          v: "6.5",
          d: "6.5 RoK",
        },
        {
          v: "other",
          d: "Annan",
        },
      ],
    },
    Adress: {
      queryKey: "address__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Fastighet: {
      queryKey: "realestate__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Byggnad: {
      queryKey: "building__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Area: {
      queryKey: "area",
      type: "number",
      operators: OPERATOR_TEMPLATES.NUMBER,
    },
    Hyresgäst: {
      queryKey: "tenant__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Hyresvärd: {
      queryKey: "landlord__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <>
      <BaseTable
        title="Hyreshöjningar"
        tableId={tableId || "apartments_rent_increase_full_table"}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
