import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/companies";
import { buildQueryString, useFrequentPermissions } from "../../../store/base";
import { useDispatch } from "react-redux";
import { setActiveFilteredRealEstates } from "../../../store/overview/store/actions";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { useHistory } from "react-router";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function CompanyTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  withSelect,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [selected, setSelected] = React.useState([]);

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const columns = React.useMemo(
    () => columnDefs(hasBillectaViewPermission),
    [persistantQuery, hasBillectaViewPermission]
  );

  const realEstateQuery = buildQueryString({
    company__in: selected || [],
  });

  const [realEstates, realEstatesLoading] =
    useFilteredRealEstates(realEstateQuery);

  const setFilter = () => {
    dispatch(
      setActiveFilteredRealEstates({
        realEstateIds: realEstates?.map((r) => r?.id),
        companyIds: selected || [],
      })
    );
    push("/");
  };

  const filters = {
    Namn: {
      queryKey: "name",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Orgnr: {
      queryKey: "orgnr",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  return (
    <>
      <BaseTable
        tableId={tableId || "company_full_table"}
        title={"Bolag"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected: withSelect ? (obj) => setSelected(obj) : undefined,
        }}
      />
      {selected?.length > 0 && withSelect && (
        <div className="fixed bottom-0 left-0 right-0 px-24 py-2 bg-white border-t border-solid border-slate-100 shadow-sm flex justify-end">
          <PrimaryBtn highlight onClick={setFilter}>
            Filtrera systemet på {selected?.length} bolag
          </PrimaryBtn>
        </div>
      )}
    </>
  );
}
