import * as React from "react";
import { RadioGroup } from "../../../Base/Fields";
import { constants } from "../../../../../store/invoicingParking";

export default function BillWithMainContract({ method }) {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <RadioGroup
        title="Avisera för detta avtal tillsammans med huvudavtalet"
        {...{ storeName, fieldKey: "bill_with_lease", method }}
        defaultValue={true}
        options={[
          { label: "Samavisera med huvudavtalet", value: true },
          { label: "Avisera detta avtal separat", value: false },
        ]}
      />
    </>
  );
}
