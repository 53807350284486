import { useDispatch, useSelector } from "react-redux";
import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
  buildQueryString,
  useInProgress,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
  getLeadRankings,
  getLeadPerStageCount,
} from "../store/actions";
import constants from "../store/constants";

export function useLead(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useLeads(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredLeads(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useLeadPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useLeadPaginationCount({ filters = {} }) {
  const querystring = buildQueryString({
    _page: 1,
    _page_size: 0,
    ...filters,
  });
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePaginationCount(params);
}

export function useLeadRankings({ ids, type }) {
  const dispatch = useDispatch();

  // remove undefinedz
  const filteredIds = ids?.filter((i) => i);

  const requestName = `${type}${filteredIds?.join(",")}`;

  const inProgress = useInProgress({
    name: requestName,
    storeName: constants.STORE_NAME,
  });
  const existing = useSelector(
    (state) => state[constants.STORE_NAME].rankings?.[requestName]
  );

  if (!filteredIds || filteredIds?.length === 0) return [undefined, false];
  if (inProgress) return [undefined, true];

  if (!existing && filteredIds?.length > 0) {
    dispatch(getLeadRankings({ ids: filteredIds, type }));
    return [undefined, true];
  }

  return [existing, false];
}

export function useLeadPerStageCount() {
  const dispatch = useDispatch();

  const requestName = `stage_lead_count`;

  const inProgress = useInProgress({
    name: requestName,
    storeName: constants.STORE_NAME,
  });

  const existing = useSelector(
    (state) => state[constants.STORE_NAME].leadsPerStageData
  );

  if (inProgress) return [undefined, true];

  if (!existing) {
    dispatch(getLeadPerStageCount({ requestName }));
    return [undefined, true];
  }

  return [existing, false];
}
