import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";



import PremisesStatusesDetail from "../../../../../views/ConfigCenter/Categories/PremisesStatuses/MainDetail";
import PremisesStatusesOverview from "../../../../../views/ConfigCenter/Categories/PremisesStatuses/MainOverview";

import TagsDetail from "../../../../../views/ConfigCenter/Categories/Tags/MainDetail";
import TagsOverview from "../../../../../views/ConfigCenter/Categories/Tags/MainOverview";



import * as SC from "../../styles";

export default () => {
  const { path, url } = useRouteMatch();

  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  const SUB_PAGES = [
    {
      path: "/tags/:tagsId",
      component: TagsDetail,
    },
    {
      path: "/tags",
      component: TagsOverview,
    },
    {
      path: "/premises-statuses/:statusId",
      component: PremisesStatusesDetail,
    },
    {
      path: "/premises-statuses",
      component: PremisesStatusesOverview,
    }
  ];

return (
  <SC.ConfigCenterDetailWrapper>
    <SC.ConfigCenterSideBarDetailWrapper>
      <SC.DetailPageBox>
        <SC.DetailPageItem
          active={getIsActive("/configcenter/categories/tags", false)}
          to={"/configcenter/categories/tags"}
        >
          Taggar
        </SC.DetailPageItem>
        <SC.DetailPageItem
          active={getIsActive("/configcenter/categories/premises-statuses", false)}
          to={"/configcenter/categories/premises-statuses"}
        >
          Objektstatusar
        </SC.DetailPageItem>
      </SC.DetailPageBox>
    </SC.ConfigCenterSideBarDetailWrapper>


    <SC.ConfigCenterDetailElement>
      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            exact={sp.exact}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect
          exact
          to={`${url}${SUB_PAGES[1].path}`}
          path={`${path}`}
        />
      </Switch>
    </SC.ConfigCenterDetailElement>
  </SC.ConfigCenterDetailWrapper>

);
};
