import PickFile from "./PickFile";
import ImportSettings from "./ImportSettings";
import ColumnSettings from "./ColumnSettings";

import Confirm from "./Confirm";
import MeasureType from "./MeasureType";
// Map of chapterdef KEY: Component
export default {
  MEASURE_TYPE: MeasureType,
  PICK_FILE: PickFile,
  IMPORT_SETTINGS: ImportSettings,
  COLUMN_SETTINGS: ColumnSettings,
  CONFIRM: Confirm,
};
