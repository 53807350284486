export const constructStrRep = (obj) => {
  return obj.title || "Avbetalning";
};
export const detailUrl = ({ id }) => {
  return `/payment-groups/detail/${id}`;
};
export const createUrl = (brfCompanyId) => {
  return `/payment-groups/create/${brfCompanyId}`;
};
export const updateUrl = (id, brfCompanyId) => {
  return `/payment-groups/update/${id}/${brfCompanyId}`;
};
