import * as React from "react";
import Select from "react-select";

import * as SC from "./styles";

export default ({ onChange, currentPage, maxPage, absolutePositioned }) => {
  const [pages, setPages] = React.useState();
  const [hovered, setHovered] = React.useState();

  React.useEffect(() => {
    const pages = [...Array(maxPage).keys()].map((i) => ({
      value: i + 1,
      label: `Sida ${i + 1}`,
    }));
    setPages(pages);
  }, [maxPage]);

  const selected = pages?.find((p) => p.value === currentPage);

  return (
    <SC.PagePickerWrapper
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...{ absolutePositioned }}
    >
      <SC.PageButton
        disabled={currentPage === 1}
        onClick={() => onChange(currentPage - 1)}
        isPrevious
      />

      <div style={{ minWidth: "150px", position: "relative" }}>
        {hovered && (
          <span
            style={{
              backgroundColor: "#499dff",
              color: "#fff",
              position: "absolute",
              bottom: "120%",
              left: 0,
              padding: 6,
              borderRadius: 3,
              boxShadow: "0px 1px 4px 1px rgba(0,0,0,0.2)",
              fontSize: 12,
            }}
          >
            Använd piltangent höger och vänster för att bläddra sidor
          </span>
        )}
        <Select
          onChange={(o) => onChange(o.value)}
          menuPlacement="top"
          value={selected}
          options={pages}
          getOptionLabel={(o) => o.label}
          getOptionValue={(o) => o.value}
        />
      </div>

      <SC.PageButton
        disabled={currentPage === maxPage}
        onClick={() => onChange(currentPage + 1)}
      />
    </SC.PagePickerWrapper>
  );
};
