import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

// style, design
import * as SC from "../Base/Chapters/styles";
import { DoubleFieldWrapper } from "../Base/Chapters/styles";
import { TextInput, Select, TimePicker } from "../Base/Fields";
import { LinkButton, PrimaryButton } from "../Base/Buttons";
import FullPageSpinner from "../../Loaders/FullPageSpinner";

// store, state
import {
  constants,
  destroyPatchForm,
  destroyPostForm,
  create,
  update,
  useUserForm,
} from "../../../store/users";
import { usePermissionCheck } from "../../../store/base";

import { useHistory } from "react-router-dom";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { InfoBox } from "../../Displays";
import TableSelectField from "../Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default ({ method, untouchedInstance, nextUrl }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { push, goBack } = useHistory();

  const formLoaded = Boolean(useUserForm(method, untouchedInstance?.id));

  const hasChanged = useSelector((state) => {
    return !isEqual(untouchedInstance, state[storeName].formInstance);
  });

  const userType = useSelector(
    (state) => state[storeName].formInstance.user_type
  );

  const canSubmit = usePermissionCheck(
    `${method === "POST" ? "add" : "change"}_can_user`
  );

  const checkout = (success) => {
    setLoading(false);
    if (method === "POST") {
      dispatch(destroyPostForm(success));
      push(nextUrl || `/user/settings/users/`);
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
      push(nextUrl || `/user/settings/users/`);
    }
  };

  // const preProcess = (data) => {
  //   const dataClone = cloneDeep(data);
  //   dataClone.realestates = dataClone.realestates.map((r) => {
  //     return { id: r };
  //   });

  //   return dataClone;
  // };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id: untouchedInstance.id,
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  if (!formLoaded) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <SC.FormArea>
        {loading && <OverlaySpinner />}
        <TextInput
          storeName={storeName}
          fieldKey={"corporate_name"}
          method={method}
          title="Företagsnamn"
        />

        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"first_name"}
            method={method}
            title="Förnamn"
          />
          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"last_name"}
            method={method}
            title="Efternamn"
          />
        </DoubleFieldWrapper>

        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"email"}
            method={method}
            title="Epost"
          />
          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"phone"}
            method={method}
            title="Telefon"
          />
        </DoubleFieldWrapper>

        <TextInput
          storeName={storeName}
          fieldKey={"legal_id"}
          method={method}
          title="Personnummer/Orgnummer"
        />

        <TimePicker
          storeName={storeName}
          method={method}
          fieldKey="active_to"
          title="Aktiv till och med"
          description="Sätt ett datum när kontot ska inaktiveras vid försök till inloggning. T.ex om det ska vara ett konto till en sommarjobbare etc."
        />
        {userType === 0 ? (
          ""
        ) : (
          <div className="grid grid-cols-2 gap-7 mb-6">
            <TableSelectField
              storeName={storeName}
              placeholder="Välj fastigheter..."
              title="Fastigheter"
              method={method}
              fieldKey={`realestates`}
              TableComponent={RealEstateTable}
              isMany
            />
          </div>
        )}

        <Select
          bodyPortaled
          storeName={storeName}
          fieldKey={"user_type"}
          method={method}
          filterChoices={(obj) => [0, 1, 2].includes(obj.v)}
          title="Användartyp"
        />

        {userType === 0 && (
          <InfoBox
            title="Admin"
            text="En administratör har tillgång till alla delar av systemet som den
            blir tilldelad på oavsett fastigheter. Du kan dock begränsa vilka
            rättigheter en admin ska ha genom att tilldela admin-användaren en
            behörighetsgrupp. "
          />
        )}
        {userType === 1 && (
          <InfoBox
            title="Standard"
            text="En standardanvändare har enbart rättighet till de delar av 
          systemet som ligger under respektive fastighet som användaren blir tilldelad på,
           samt de rättigheter som standardanvändaren får av behörighetsgruppen som
           tilldelas på användaren."
          />
        )}
        {userType === 2 && (
          <InfoBox
            title="Arbetare"
            text="En utförare är en användare som jobbar med teknisk förvaltning 
          och har enbart tillgång till de objekt och ärenden som utföraren har blivit tilldelad 
          på. Du kan utöver detta även begränsa vilka rättigheter en utförare ska ha genom att
           tilldela utförare-användaren en behörighetsgrupp."
          />
        )}
        {userType === 4 && (
          <InfoBox
            title="Gäst"
            text="En gästanvändare har enbart tillgång till de specifika objekt som en gästanvändare 
            blir tilldelad till, samt de rättigheter som gästanvändaren får av behörighetsgruppen 
            som tilldelas på användaren."
          />
        )}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <LinkButton clicked={() => goBack()} title={"Avbryt"} />
          <PrimaryButton
            newDesign
            clicked={onSubmit}
            title={method === "POST" ? "Lägg till" : "Uppdatera"}
            disabled={!canSubmit || !hasChanged}
          />
        </div>
      </SC.FormArea>
    </>
  );
};
