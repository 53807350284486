import * as React from "react";

// style, design
import { TextInput, NumberInput } from "../Base/Fields";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  return (
    <>
      <TextInput
        title="Namn"
        storeName={storeName}
        fieldKey={`${parentPath}.name`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.name`}
      />

      <TextInput
        title="Adress"
        storeName={storeName}
        fieldKey={`${parentPath}.address`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.address`}
      />

      <TextInput
        title="Personnummer/orgnr"
        storeName={storeName}
        fieldKey={`${parentPath}.ssn_number`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.ssn_number`}
      />

      <TextInput
        title="Land"
        storeName={storeName}
        fieldKey={`${parentPath}.country`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.country`}
      />
    </>
  );
};
