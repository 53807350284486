import * as React from "react";

import {
  DatePicker,
  MonthInput,
  RadioGroup,
  Select,
  TextInput,
} from "../../../Base/Fields";

import { constants } from "../../../../../store/serviceContracts";
import { useFormInstanceField } from "../../../../../store/base";

import GenericField from "../../../Base/FieldComponents/GenericField";
import useActiveFilters from "../../../../../hooks/useActiveFilters";

import CategoryNestedChildren from "../../../ServiceCategories/NestedChildren";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import ServiceCategoryTable from "src/components/Tables/ServiceCategories/FullTable";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();

  const storeName = constants.STORE_NAME;
  const endDate = useFormInstanceField({
    storeName,
    fieldKey: "end_date",
  });

  const renewIndefinetely = useFormInstanceField({
    storeName,
    fieldKey: "renew_indefinetely",
  });

  return (
    <>
      <TextInput
        title="Avtals-ID"
        description="Ange det interna ID:t för avtalet"
        storeName={storeName}
        fieldKey="id_number"
        method={method}
      ></TextInput>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          isMany
          storeName={storeName}
          placeholder="Välj fastigheter..."
          title="Fastigheter som avtalet ligger under"
          description="Ange vilken/vilka fastigheter som avtalet ligger under."
          method={method}
          fieldKey="realestates"
          TableComponent={RealEstateTable}
        />
      </div>

      <Select
        title="Status på avtalet"
        storeName={storeName}
        method={method}
        fieldKey="status"
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          placeholder="Välj kategori..."
          title="Kategori"
          method={method}
          fieldKey={`category`}
          TableComponent={ServiceCategoryTable}
        >
          {(parentPath) => (
            <CategoryNestedChildren
              storeName={storeName}
              method={method}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      <GenericField
        storeName={storeName}
        method={method}
        ctFieldKey="content_type"
        idFieldKey="object_id"
        realEstateIds={filteredRealEstates}
      />

      <DatePicker
        title="Startdatum"
        description="Datum när avtalet träder i kraft"
        storeName={storeName}
        fieldKey="start_date"
        method={method}
      />

      <DatePicker
        storeName={storeName}
        fieldKey="end_date"
        title="Slutdatum"
        description="Lämna tomt om avtalet är tillsvidare"
        method={method}
      />

      <MonthInput
        title="Uppsägningstid"
        storeName={storeName}
        fieldKey="notify_interval"
        method={method}
      />

      {endDate && (
        <RadioGroup
          storeName={storeName}
          fieldKey="renew_indefinetely"
          title="Förlängning vid passerat slutdatum"
          description="Välj hur ett passerat slutdatum utan att avtalet har sagts upp ska hanteras."
          method={method}
          forceHorizontalToolTipPlacement="left"
          forceVerticalToolTipPlacement="top"
          defaultValue={false}
          options={[
            {
              label:
                "Förläng med vald förlängningstid när slutdatumet passeras",
              value: false,
            },
            {
              label: "Förläng på obestämd tid när slutdatumet passeras",
              value: true,
            },
            {
              label:
                "Förläng ej när slutdatumet passeras (kräver uppsägningstid)",
              value: "no_renewal",
            },
          ]}
        />
      )}

      {!!endDate && !renewIndefinetely && (
        <MonthInput
          storeName={storeName}
          title="Förlängningstid"
          description="Ange i hur långa intervall avtalet förlängs"
          fieldKey="renew_interval"
          method={method}
        />
      )}

      <MonthInput
        title="Varna så här lång tid innan avtalet går ut "
        description="Pigello skickar en notis om att avtalet är på väg att gå ut så här lång tid innan slutdatum + uppsägningstid"
        storeName={storeName}
        fieldKey="warning_interval"
        method={method}
      />
    </>
  );
};
