import * as React from "react";
import { Route } from "react-router";

import ConfigCenterSidebar from "./ConfigCenterSidebar";

import TopArea from "../../TopArea/TopArea";

export default function ConfigCenterLayout({
  component: RouteComponent,
  path,
  exact,
}) {
  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => (
        <div style={{ display: "flex", minHeight: "100vh" }}>
          <ConfigCenterSidebar getActivePage={getIsActive} />
          <div
            style={{
              maxWidth: `calc(100vw - 150px)`,
              minWidth: `calc(100vw - 150px)`,
              minHeight: `100vh`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TopArea />
            <div style={{ display: "flex", height: "100%" }}>
              <RouteComponent {...matchProps} />
            </div>
          </div>
        </div>
      )}
    />
  );
}
