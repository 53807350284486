import styled, { css } from "styled-components";

import backIcon from "../../../../assets/svg/back.svg";
import infoIcon from "../../../../assets/svg/info.svg";

export const FormHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: ${(p) => p.theme.colors.graphite};

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    padding: 8px;
  }
`;

export const Spacer = styled.div`
  min-width: 100px;
`;

export const BackButton = styled.div`
  color: ${(p) => p.theme.colors.textPrimary};
  padding-left: 12px;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 18px;
    width: 20px;
    top: calc(50% - 10px);
    left: -10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${backIcon});
  }
`;

export const PageTitle = styled.h1`
  font-size: 1.2rem;

  font-weight: 600;
  color: ${(p) => p.theme.colors.white};
`;

export const InfoIcon = styled.div`
  position: relative;
  margin-left: 8px;
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${infoIcon});
`;

export const InfoContent = styled.div`
  background-color: #fff;
  position: absolute;

  font-weight: 500;
  color: ${(p) => p.theme.colors.primary} !important;
  opacity: 1;
  font-size: 0.8rem;
  min-width: 240px;
  max-width: 240px;
  padding: 8px;
  left: calc(50% - 120px);
  border: thin solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  z-index: 2;
  white-space: normal;
  line-height: 100%;
  text-align: left;
  ${(p) =>
    p.align === "left" &&
    css`
      left: calc(-240px + 100%);
    `}
  ${(p) =>
    p.align === "right" &&
    css`
      left: 0px;
    `}
    ${(p) =>
    p.yAlign === "bottom" &&
    css`
      top: 140%;
    `}
    ${(p) =>
    p.yAlign !== "bottom" &&
    css`
      bottom: 140%;
    `};
`;

export const ConnectedContractWrapper = styled.div`
  background-color: white;
  border: thin solid #ddd;
  position: relative;
  border-radius: 5px;
  padding: 12px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  margin-bottom: 24px;
`;

export const ConnectedContractTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${(p) => p.theme.colors.textPrimary};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  flex-wrap: wrap;

  margin-bottom: 24px;
`;

export const ConnectedContractContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const ConnectedContractItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 30%;
  min-width: 170px;
  margin-right: 3%;
  margin-bottom: 12px;
`;

export const ConnectedContractActions = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
