import styled from "styled-components";

export const OverviewHeader = styled.div`
  background-color: ${(p) => p.theme.colors.blueDark};
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
`;

export const OverviewDataPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DataPointIcon = styled.div`
  height: 32px;
  width: 32px;
  margin-bottom: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(p) => p.src});
`;

export const DataPointTitle = styled.div`
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.darkBlueContrastText};
`;

export const DataPointValue = styled.div`
  font-size: 2rem;
  line-height: 180%;
  font-weight: 600;
`;

export const StatisticsCard = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
`;

export const DataSection = styled.div`
  flex-grow: 1;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
`;

export const InnerSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChartWrapper = styled.div`
  min-width: 400px;
  min-height: 300px;
`;

export const CardDataPointTitle = styled.div`
  color: ${(p) => p.theme.colors.darkBlue};
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 12px;
  border-bottom: 2px solid ${(p) => p.theme.colors.darkBlue};
`;

export const ValueWithTitle = styled.div`
  margin: 12px 0;
`;

export const TitledValueTitle = styled.div`
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.blueDark};
  opacity: 0.8;
  font-size: 0.6rem;
  font-weight: 600;
`;

export const TitledValue = styled.div`
  font-weight: 600;
  line-height: 1;
  font-size: 1.4rem;
`;
export const TitledValueSuffix = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
`;

export const NotificationDot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  min-width: 28px;
  min-height: 28px;
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  font-size: 12px;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.primaryBlue};
  border: thin solid ${(p) => p.theme.colors.primaryBlueFaded};
  border-radius: 50%;
  margin-left: 8px;
`;
