import * as React from "react";

import SelectFilter from "../../../../Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "mixed", display: "Mixad" },
    { value: "motorcycle", display: "Motorcykel" },
    { value: "car", display: "Bil" },
    { value: "bicycle", display: "Cyklar" },
    { value: "other", display: "Övrigt" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
