import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import useActiveFilters from "../../../hooks/useActiveFilters";
import BrfPremisesTable from "../../../components/Tables/BrfPremises/FullTable";

export default function BrfPremises() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  //TODO: ??
  // const persistantFilterMethod = (obj) => {
  //   if (!filteredRealEstates) return obj;
  //   return filteredRealEstates.includes(obj.realestate?.id);
  // };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Översikt</OverviewTitle>
        </OverviewTitleWrapper>

        <BrfPremisesTable persistantQuery={persistantQuery} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
