import * as React from "react";
import moment from "moment";
import { useParams, useHistory } from "react-router";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import { buildQueryString } from "../../../store/base";
import { useTenant } from "../../../store/tenants";
import { createUrl as keyPermissionCreateUrl } from "../../../store/keypermission";
import { createUrl as keyCreateUrl } from "../../../store/key";

import KeyTable from "src/components/Tables/Keys/FullTable";
import KeyPermissionTable from "../../../components/Tables/KeyPermissions/FullTable";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useFilteredIndustrialPremises } from "../../../store/industrialPremises";
import { useFilteredApartments } from "../../../store/apartments";

export default function TenantKeys() {
  const { push } = useHistory();
  const { tenantId } = useParams();

  const [tenant, tenantLoading] = useTenant(tenantId);

  const keyQuery = { user: tenant?.user?.id };

  const [indps, indpsLoading] = useFilteredIndustrialPremises(
    buildQueryString({ broad_tenant: tenantId })
  );
  const [apartments, apartmentsLoading] = useFilteredApartments(
    buildQueryString({ broad_tenant: tenantId })
  );

  const apsSorted = apartments == null ? [] : apartments;
  apsSorted.sort((a, b) => a.state - b.state);

  const indpsSorted = indps == null ? [] : indps;
  indpsSorted.sort((a, b) => a.state - b.state);

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ minWidth: "60%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle small>Nycklar</OverviewTitle>
              <div>
                <TextButton
                  title="Lägg till"
                  iconType="add"
                  iconPlacement="right"
                  clicked={() =>
                    tenant
                      ? push(keyCreateUrl() + `/user/${tenant.user.id}`)
                      : push(keyCreateUrl())
                  }
                />
              </div>
            </OverviewTitleWrapper>
            <KeyTable persistantQuery={keyQuery} isBare ignoreLocalStorage />
          </DetailPageBox>
          <DetailPageBox style={{ minWidth: "39%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle small>Nyckelbehörigheter</OverviewTitle>
              <div>
                <TextButton
                  title="Lägg till"
                  iconType="add"
                  iconPlacement="right"
                  clicked={() =>
                    push(
                      keyPermissionCreateUrl() +
                        (apsSorted.length > 0
                          ? `/apartment/${apsSorted[0].id}`
                          : indpsSorted.length > 0
                          ? `/industrial-premises/${indpsSorted[0].id}`
                          : "")
                    )
                  }
                />
              </div>
            </OverviewTitleWrapper>
            <KeyPermissionTable
              persistantQuery={keyQuery}
              isBare
              ignoreLocalStorage
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
