import * as React from "react";
export default function Exclusions() {
  return (
    <>
      Exkludera skapande av rum/komponenter utifrån dessa regler. T.ex. kan
      skapande av rum med kategorin "Kök" hoppas över för objekt som redan har
      ett rum med kategorin "Kök"
    </>
  );
}
