import * as React from "react";
import * as SharedStyles from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import OverviewMap from "../../../components/Maps/OverviewMap";

export default function RealEstateMap() {
  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Karta över fastigheter</OverviewTitle>
        </OverviewTitleWrapper>

        <OverviewMap />
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
