import * as React from "react";

export default function ActionBanner({
  illustrationSvg,
  renderTitle,
  renderActions,
  renderDescription,
  fillWidth = false,
}) {
  return (
    <div className="w-full flex justify-center">
      <div className={`w-full ${!fillWidth ? "max-w-[1200px] px-[20px]" : ""}`}>
        <div className=" w-full mx-auto pr-48 flex border rounded shadow shadow-slate-50 relative overflow-hidden border-slate-200">
          <div className="flex flex-col p-4">
            <div className="text-lg font-medium text-slate-700 flex items-center">
              {renderTitle && renderTitle()}
            </div>
            {renderDescription && (
              <div className="text-sm text-slate-600 mt-2">
                {renderDescription()}
              </div>
            )}

            {renderActions && (
              <div className="flex items-center mt-6">{renderActions()}</div>
            )}
          </div>

          {illustrationSvg && (
            <div
              className={`absolute right-[12px] bottom-[0px] min-h-[105px] min-w-[200px] bg-no-repeat bg-contain bg-center`}
              style={{ backgroundImage: `url(${illustrationSvg})` }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
