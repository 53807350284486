import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import { useDispatch } from "react-redux";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import SFTPCatalogueModalForm from "src/components/Forms/SFTPCatalogue/ChapterForm/ModalForm";
import SFTPCatalogueTable from "src/components/Tables/SFTPCatalogue/FullTable";
import DeleteModal from "src/components/Forms/Delete/DeleteModal";
import { constants, triggerManualSFTP } from "src/store/SFTPCatalogue";
import Modal from "src/components/Forms/Base/Modals/Modal";
import LocalDateSelect from "src/components/Forms/Base/Fields/LocalDateSelect";
import { addToast, TOAST_TYPES } from "src/store/toasts";

export default function SftpIntegration() {
  const dispatch = useDispatch();
  const current = INTEGRATIONS.find((i) => i.title === "SFTP");

  const [modalOpen, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(null);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [triggerManualId, setTriggerManualId] = React.useState(null);

  const [manualStartDate, setManualStartDate] = React.useState("");
  const [manualEndDate, setManualEndDate] = React.useState("");

  const onManualTrigger = () => {
    dispatch(
      triggerManualSFTP({
        id: triggerManualId,
        startDate: manualStartDate,
        endDate: manualEndDate,
        successCallback: (data) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "SFTP-jobb kördes",
              description: `${data.file_amount} filer skickades.`,
            })
          );

          setTriggerManualId(null);
          setManualStartDate("");
          setManualEndDate("");
        },
        errorCallback: (e) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: JSON.stringify(e),
            })
          );
        },
      })
    );
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={deleteOpen}
        constants={constants}
      />

      {modalOpen && (
        <SFTPCatalogueModalForm
          method="POST"
          onCheckout={() => setModalOpen(false)}
        />
      )}
      {editModalOpen && (
        <SFTPCatalogueModalForm
          method="PATCH"
          id={editModalOpen?.id}
          instance={editModalOpen}
          onDelete={() => {
            setDeleteOpen(editModalOpen);
            setEditModalOpen(false);
          }}
          onCheckout={() => setEditModalOpen(false)}
        />
      )}

      {triggerManualId && (
        <Modal
          title="Trigga manuellt SFTP-jobb"
          closeFunction={() => setTriggerManualId(null)}
          onAccept={onManualTrigger}
          canAccept={!!manualStartDate && !!manualEndDate}
          acceptTitle="Trigga jobb"
        >
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalDateSelect
              value={manualStartDate}
              onChange={(val) => setManualStartDate(val)}
              title="Inkludera transaktioner från..."
            />
            <LocalDateSelect
              value={manualEndDate}
              onChange={(val) => setManualEndDate(val)}
              title="Inkludera transaktioner till..."
            />
          </div>
        </Modal>
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Integrationsinformation</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>
                    Hur fungerar SFTP-jobb i Pigello?
                  </OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              <BodyText>
                <p className="mb-4">
                  SFTP-jobb sätts upp per bolag och körs enligt valt intervall.
                  Till SFTP-jobbet kopplas ytterligare integrationer, t.ex.
                  Xledger eller Unit4 ERP, vilket bestämmer vilka filer som
                  genereras och skickas till SFTP-adressen. Observera att själva
                  SFTP:n sätts upp med en tredjeparts leverantör.
                </p>

                <p className="mb-4">
                  <strong>Steg 1</strong>
                  <br />
                  Sätt upp ett SFTP-jobb för det bolag som bokföringsmaterial
                  ska skickas för.
                </p>
                <p className="mb-4">
                  <strong>Steg 2</strong>
                  <br />
                  Aktivera en av integrationerna som körs över SFTP (Xledger,
                  Unit4 ERP) och koppla till SFTP-jobbet för det bolag det
                  gäller.
                </p>
              </BodyText>
            </InnerBox>
          </DetailPageBox>

          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>SFTP-jobb</OverviewTitle>
                <OverviewSubtitle>
                  Enbart ett jobb kan sättas upp per bolag.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>

              <PrimaryBtn onClick={() => setModalOpen(true)}>
                Lägg till SFTP-jobb
              </PrimaryBtn>
            </OverviewTitleWrapper>

            <SFTPCatalogueTable
              onRowClicked={(obj) => setEditModalOpen(obj)}
              triggerManual={(id) => setTriggerManualId(id)}
            />
          </DetailPageBox>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
