import * as React from "react";

import {
  TextInput,
  RadioGroup,
  DatePicker,
  NumberInput,
  TableSelect,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/loan";

import useActiveFilters from "../../../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../../../store/base";
import { useFilteredRealEstates } from "../../../../../store/realEstates";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import PaymentGroupTable from "src/components/Tables/PaymentGroup/FullTable";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();

  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    setIsReady(true);
  }, []);

  const storeName = constants.STORE_NAME;

  const persistantFilterMethod = (obj) => {
    if (!filteredRealEstates) return obj;
    return filteredRealEstates.includes(obj.realestate?.id);
  };

  const realEstateQuery = buildQueryString({
    id__in: filteredRealEstates,
  });

  const [realEstates] = useFilteredRealEstates(realEstateQuery);

  return (
    <>
      {isReady && (
        <>
          {" "}
          <DatePicker
            title="Startdatum"
            storeName={storeName}
            method={method}
            fieldKey="start_date"
            description={
              "Lånet gäller f.o.m denna månad (amortering görs avseende denna månad)"
            }
          />
          <DatePicker
            title="Slutdatum"
            storeName={storeName}
            method={method}
            fieldKey="end_date"
            description={
              "Lånet gäller t.o.m denna månad (amortering görs till och med denna månad)"
            }
          />
        </>
      )}
      <NumberInput
        title="Initialt belånat värde"
        storeName={storeName}
        method={method}
        fieldKey="initial_value"
        description={
          "Det initiala värdet som lånet innefattar, d.v.s värdet per angivet startdatum, då ingen amortering har gjorts"
        }
      />

      <div className=" grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            realestate_ids: filteredRealEstates,
          }}
          placeholder="Välj avgiftsgrupp..."
          title="Avgiftsgrupp"
          method={method}
          fieldKey="payment_group"
          TableComponent={PaymentGroupTable}
        />
      </div>
      <NumberInput
        title="Andel av avgiftsgrupp"
        storeName={storeName}
        method={method}
        fieldKey="payment_group_quota"
        description={
          "Andelen av avgiftsgruppen avgör hur stor del av en inbetald avgift som avser detta lån och dess avgifter i den avsedda perioden"
        }
      />
    </>
  );
};
