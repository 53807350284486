export default [
  {
    title: "Kostnadsställe",
    key: "COST_CENTER",
    visited: true,
    hasError: false,
    fieldKeys: ["title", "description"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
