import styled from "styled-components";
import { animated } from "@react-spring/web";
import { SvgLoader } from "react-svgmt";

export const BlueprintWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: ${(p) => p.height}px;
  width: 100%;
  background-color: #fff;
`;

export const ControllerWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const ControllerBox = styled.div`
  cursor: pointer;
  margin-bottom: 6px;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 5px;
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.src});
`;

export const ConnectWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const PremisesInfoContainer = styled(animated.div)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 12px;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.fadedBorder};
  border-radius: 5px;
  z-index: 1;
  width: 30%;
  min-width: 300px;
  max-width: 400px;
`;

export const PremisesTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 6px;
  text-transform: uppercase;
`;

export const PremisesInfo = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  margin: 3px 0;
`;

export const StyledSVGLoader = styled(SvgLoader)`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  transform-origin: "center";
  transform: rotate(${(p) => p.rotation}deg);
  transform-style: preserve-3d;
  will-change: transform;

  & > svg {
    transform-style: preserve-3d;
    will-change: transform;

    & > * {
      transform-style: preserve-3d;
      will-change: transform;
    }
  }
`;
