import * as React from "react";
import { useDispatch } from "react-redux";
import LocalRadioGroupField from "src/components/Forms/Base/Fields/LocalRadioGroupField";
import TextAreaField from "src/components/Forms/Base/Fields/TextAreaField";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";
import { updateActiveFormInstance, useFormInstanceField } from "src/store/base";

export default function Errand({ storeName, method, isGov }) {
  const dispatch = useDispatch();

  const nonFaultErrandHandling = useFormInstanceField({
    storeName,
    fieldKey: "_handleNonFaults",
  });

  const setNonHandleFaultsHandling = (val) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _handleNonFaults: val,
        },
      })
    );
  };

  return (
    <>
      <div className="mb-6">
        <LocalRadioGroupField
          title="Markering av ej avvikande komponenter"
          description="Ange hur ej avvikande komponenter ska hanteras."
          value={nonFaultErrandHandling}
          onChange={(type) => setNonHandleFaultsHandling(type)}
          options={[
            {
              title: "Markera ej avvikande komponenter som OK",
              description:
                "Detta kommmer lägga till en OK-markering på alla komponenter som ingår i ronden och ej har markerats som avvikande.",
              value: true,
            },
            {
              title: "Markera ej avvikande komponenter som Ej ronderade",
              description:
                "Detta kommmer lägga till en Ej ronderad-markering på alla komponenter som ingår i ronden och ej har markerats som avvikande.",
              value: null,
            },
          ]}
        />
      </div>

      {isGov && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            storeName={storeName}
            fieldKey="inspecting_party"
            title="Besiktande part"
            method={method}
          />
        </div>
      )}

      <div className="grid grid-cols-1 gap-6 mb-6">
        <TextAreaField
          storeName={storeName}
          fieldKey="completion_comment"
          method={method}
          title="Kommentar vid utförande"
        />
      </div>
    </>
  );
}
