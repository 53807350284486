export default [
  {
    title: "Statusar på objekt",
    key: "STATUS",
    hasError: false,
    fieldKeys: ["name", "color_code"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
