export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INDEX_SETTING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INDEX_SETTING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INDEX_SETTING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INDEX_SETTING",
  DESTROY_FORM: "DESTROY_FORM_INDEX_SETTING",
  SET_FORM_ERROR: "SET_FORM_ERROR_INDEX_SETTING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INDEX_SETTING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INDEX_SETTING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INDEX_SETTING",
  RESET_STATE: "RESET_STATE_INDEX_SETTING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INDEX_SETTING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDEX_SETTING",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_INDEX_SETTING",
  CLEAR_FETCHED: "CLEAR_FETCHED_INDEX_SETTING",

  LIST_URL: "/standard/index/indexsetting/list/",
  GET_URL: "/standard/index/indexsetting/",
  POST_URL: "/standard/index/indexsetting/",
  PATCH_URL: "/standard/index/indexsetting/",

  STORE_NAME: "indexSetting",
};
