import * as React from "react";
import DateSelect from "../Base/Fields/DateSelect";
import SelectField from "../Base/Fields/SelectField";
import TextInputField from "../Base/Fields/TextInputField";

export default function ProjectNestedFields({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Status för projekt"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.status`,
            instructionsKey: `${parentInstructionsPath}.status`,
          }}
        />

        <TextInputField
          title="Titel"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.title`,
            instructionsKey: `${parentInstructionsPath}.title`,
          }}
        />

        <TextInputField
          title="Beskrivning"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.description`,
            instructionsKey: `${parentInstructionsPath}.description`,
          }}
        />

        <TextInputField
          title="Projektledare"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.project_leader`,
            instructionsKey: `${parentInstructionsPath}.project_leader`,
          }}
        />

        <TextInputField
          title="Kontaktperson"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.contact_name`,
            instructionsKey: `${parentInstructionsPath}.contact_name`,
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          title="Startdatum för projekt"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.start_date`,
            instructionsKey: `${parentInstructionsPath}.start_date`,
          }}
        />
        <DateSelect
          title="Slutdatum för projekt"
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.end_date`,
            instructionsKey: `${parentInstructionsPath}.end_date`,
          }}
        />
      </div>
    </>
  );
}
