import * as React from "react";

// style, design
import { ToolTipCell, BooleanLabel } from "../../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default function listDefs(
  hasBillectaViewPermission,
  integrationSettings,
  integrationName
) {
  const cols = [
    {
      Header: "Logotyp",
      accessor: "image.get",

      Cell: ({ value }) => (
        <div
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "5px",
            backgroundImage: `url(${value})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: integrationName,
      id: "_integrationBool",

      Cell: ({ row }) => {
        const hasSetting =
          integrationSettings == null
            ? false
            : integrationSettings[row.original.id];
        return <BooleanLabel value={hasSetting} onLabel="Ja" offLabel="Nej" />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Namn",
      accessor: "name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "OrgNr",
      accessor: "orgnr",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  if (hasBillectaViewPermission) {
    cols.splice(4, 0, {
      Header: "Fakturering aktiverad",
      accessor: "billecta_id",
      Cell: ({ value }) => (
        <StatusLabel state={value ? 0 : 3}>
          {value ? "Aktiv" : "Inaktiv"}
        </StatusLabel>
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    });
  }
  return cols;
}
