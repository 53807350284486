import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import classNames from "classnames";

export default function LocalBareSelectField({
  disabled,
  placeholder,
  choices,
  onChange,
  value,
  id,
  className = "",
  selectClassName = "",
  removePlaceholder = false,
}) {
  return (
    <div
      className={classNames(
        "border-b border-solid border-primaryblue",
        className
      )}
    >
      <select
        disabled={disabled}
        id={id}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        style={{
          backgroundSize: "1.5em 1.5em",
        }}
        className={classNames(
          "bg-transparent appearance-none mx-1 bg-no-repeat bg-[right_0.2rem_center] bg-chevrondown text-primaryblue text-sm rounded focus:ring-blue-500 focus:border-blue-500 inline-block px-1 ",
          selectClassName
        )}
      >
        {removePlaceholder ? (
          <></>
        ) : (
          <option selected>{placeholder || "Välj ett alternativ..."}</option>
        )}

        {choices?.map((choice) => {
          return (
            <option key={choice.v} value={choice.v}>
              {choice.d}
            </option>
          );
        })}
      </select>
    </div>
  );
}
