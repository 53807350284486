import React from "react";

import * as SC from "../../Layout/Insights/Widgets/styles";

export default function OverviewDiff({ height, width, valueDict }) {
  return (
    <SC.InternalComponentContainer width={width} height={height}>
      <SC.InternalComponentFlexContainer flexDirection="column">
        <SC.WidgetDataText>{valueDict.primary_value}</SC.WidgetDataText>
        <SC.WidgetTextSupplement>
          {valueDict.primary_label}
        </SC.WidgetTextSupplement>
        <SC.WidgetTextSeparator />
        <SC.WidgetDataText>{valueDict.secondary_value}</SC.WidgetDataText>
        <SC.WidgetTextSupplement>
          {valueDict.secondary_label}
        </SC.WidgetTextSupplement>
        <SC.WidgetTextSeparator />
        <SC.WidgetDataText>{valueDict.diff_value}</SC.WidgetDataText>
        <SC.WidgetTextSupplement>
          {valueDict.diff_label}
        </SC.WidgetTextSupplement>
      </SC.InternalComponentFlexContainer>
    </SC.InternalComponentContainer>
  );
}
