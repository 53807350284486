import * as React from "react";

import { Confirm, Contract, Tax } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "CONTRACT":
      return <Contract method={method} />;
    case "TAX":
      return <Tax method={method} />;
    default:
      return <Confirm method={method} />;
  }
};
