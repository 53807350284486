import * as React from "react";
import { usePrivateTemplates } from "../../../store/editabledocs";
import Table from "../../Billecta/Table/BasicTable";

export default function PrivateTemplates({
  privateTemplateName,
  choseTemplate,
}) {
  const [privateTemplates, isLoadingPrivateTemplates] = usePrivateTemplates();

  const data = React.useMemo(() => {
    return privateTemplates;
  }, [privateTemplates]);

  const cols = React.useMemo(() => {
    return [
      {
        Header: "Namn",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Kategori",
        accessor: "category_display",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
    ];
  }, []);

  return (
    <>
      <Table
        onRowClicked={({ original }) =>
          choseTemplate({
            id: original.id,
            type: privateTemplateName,
            object: original,
          })
        }
        columns={cols}
        data={data || []}
      />
    </>
  );
}
