import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  detailUrl,
  useInvoicingDebtSettingForm,
} from "../../../../store/invoicingDebtSettings";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { cloneDeep } from "lodash";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import StandardModal from "../../../Modals/StandardModal";
import { InfoBox } from "../../../Displays";
import UpdateInvoiceSettingSummary from "../../../Summaries/UpdateInvoiceSettingSummary";
import { askUpdateSharedSetting } from "../../../../store/invoicingSettings/store/actions";
import {
  OverviewSubtitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";

export default function InvoicingDebtSettingModalForm({
  method,
  id,
  onCheckout,
  isOpen,
  instance = {},
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const [showAskConfirm, setShowAskConfirm] = React.useState(false);
  const [confirmData, setConfirmData] = React.useState({});

  const formLoaded = Boolean(useInvoicingDebtSettingForm(method, id));
  const formInstance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });
  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    dataClone.interest_payment_terms_days = 365;

    if (!dataClone.use_reminders) {
      dataClone.use_reminders = false;
    }
    if (!dataClone.use_debt_collection) {
      dataClone.use_debt_collection = false;
    }
    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          preProcess,
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  const getAskConfirmData = () => {
    setLoading(true);
    const data = cloneDeep(formInstance);

    dispatch(
      askUpdateSharedSetting({
        currentConstants: constants,
        debtSettingData: preProcess(data),
        successCallback: (resData) => {
          setLoading(false);
          setConfirmData(resData);
          setShowAskConfirm(true);
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kan inte genomföras",
              description: "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const askConfirmData = () => {
    const hasAnyNonUpdateFields = Object.keys(confirmData || {}).some((k) => {
      const contracts = confirmData[k];
      return Object.keys(contracts)?.length > 0;
    });

    const infoText = hasAnyNonUpdateFields
      ? `Dessa avtal och objekt har specifika attribut som ej kommer att påverkas av uppdateringen. Bekräfta att uppdateringen ska genomföras genom att trycka på "Bekräfta uppdatering"`
      : `Alla avtal och objekt som använder denna inställning kommer att uppdateras. Bekräfta att uppdateringen ska genomföras genom att trycka på "Bekräfta uppdatering"`;

    return (
      <StandardModal
        isOpen={true}
        modalInModal
        closeFunction={() => setShowAskConfirm(false)}
        withActionBar
        saveFunction={onSubmit}
        actionBarAcceptTitle="Bekräfta uppdatering"
      >
        <InfoBox
          boxTheme="yellow"
          title="Kontrollera uppdateringen"
          text={infoText}
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewSubtitle>
              {`Uppdateringarna kommer att synkas mot aviseringssystemet direkt.
              Om någon synkning misslyckas kommer ett felmeddelande om detta
              dyka upp under "Hyresaviering -> Översikt" samt det specifika
              objektet.`}
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {hasAnyNonUpdateFields && (
          <UpdateInvoiceSettingSummary
            {...{
              confirmData,
            }}
          />
        )}
      </StandardModal>
    );
  };

  return (
    <ModalChapter
      storeName={storeName}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter({ key, method })}
      submitCallback={method === "POST" ? onSubmit : getAskConfirmData}
      title={
        method === "POST" ? "Skapa inställning" : "Kontrollera uppdatering"
      }
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
      overrideRenderChapter={showAskConfirm ? askConfirmData : false}
    />
  );
}
