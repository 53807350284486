import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  useCommonAreaForm,
  constants,
  create,
  destroyPatchForm,
  destroyPostForm,
  detailUrl,
  update,
} from "../../../../store/commonAreas";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import SelectField from "../../Base/Fields/SelectField";
import ManyMediaField from "../../Base/Fields/ManyMediaField";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import TableSelectField from "../../Base/Fields/TableSelectField";
import FloorSelector from "../../Base/FieldComponents/FloorSelector";
import { AddressField } from "../../Base/Fields";
import useActiveFilters from "src/hooks/useActiveFilters";

export default ({ method, id, onCheckout, instance, skipRedirect }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const storeName = constants.STORE_NAME;
  const { filteredRealEstates } = useActiveFilters();

  const formLoaded = Boolean(useCommonAreaForm(method, id));
  const floors = useFormInstanceField({ storeName, fieldKey: "floors" });

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST" && !skipRedirect) {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={
        method === "POST" ? "Skapa gemensam yta" : "Uppdatera gemensam yta"
      }
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Ytans ID"
          storeName={storeName}
          method={method}
          fieldKey="common_area_id"
        />

        <SelectField
          {...{ fieldKey: "category", method, storeName, title: "Kategori" }}
        />

        <TextInputField
          isNumber
          title="Area (m2)"
          storeName={storeName}
          method={method}
          fieldKey="area"
        />
      </div>
      <FloorSelector
        storeName={storeName}
        fieldKey={"floors"}
        floors={floors}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{ id__in: filteredRealEstates }}
          placeholder="Välj byggnad..."
          title="Byggnad"
          method={method}
          fieldKey="building"
          TableComponent={BuildingTable}
        />
      </div>

      <AddressField
        title="Adress"
        storeName={storeName}
        method={method}
        fieldKey="address"
      />

      <ManyMediaField
        storeName={storeName}
        method={method}
        fieldKey="images"
        fileKey="image"
        title="Bilder på objektet"
        allowedFormats={[".jpg", ".jpeg", ".png"]}
      />
    </Modal>
  );
};
