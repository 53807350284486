import * as React from "react";
import { Wrapper } from "../";
import { Link } from "react-router-dom";
import {
  ArrowsPointingOutIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  HomeIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import { useApartment, detailUrl } from "../../../store/apartments";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import { toMoneyString } from "../../utils/stringUtils";

export default function SmallApartmentSummary({ id, className }) {
  const [apartment, apartmentLoading] = useApartment(id);

  if (!id) return null;

  return (
    <Wrapper className={className}>
      <div className="flex">
        <div className="mr-3 shrink-0">
          <HomeIcon width={32} className="text-slate-500" />
        </div>
        <div>
          <div className="mb-1 text-base font-semibold leading-none text-gray-700 hover:underline">
            <Link to={detailUrl({ id })}>
              {apartment
                ? `Lägenhet ${apartment?.apartment_id} ${
                    apartment?.premises_id ? `(${apartment.premises_id})` : ""
                  }`
                : "Laddar..."}
            </Link>
          </div>

          <p className="mb-3 text-sm font-normal">
            {apartment?.category_display}
          </p>

          <ul className="text-sm font-light">
            <li className="flex items-center mb-1">
              <BuildingOfficeIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <Link
                to={realEstateDetailUrl({ id: apartment?.realestate?.id })}
                className="text-blue-600 hover:underline"
              >
                {apartment?.realestate?.str_representation || "-"}
              </Link>
            </li>
            <li className="flex items-center mb-1">
              <ArrowsPointingOutIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <span>{apartment?.area ? `${apartment.area}㎡` : "-"}</span>
            </li>
            <li className="flex items-center mb-1">
              <Square3Stack3DIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <span>
                {apartment?.floors?.map(
                  (f, idx) =>
                    `${idx === 0 ? "Våning " : ""}${f}${
                      idx < apartment.floors.length - 1 ? ", " : ""
                    }`
                )}
              </span>
            </li>
            <li className="flex items-center mb-1">
              <BanknotesIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <span>{toMoneyString(apartment?.base_rent, true) || "-"}</span>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}
