import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  useCalendarEventForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/calendarEvents";
import renderChapter from "./renderChapter";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";

export default function TodoItemForm({
  method,
  id,
  isOpen,
  onCheckout,
  instance,
  modalInModal,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useCalendarEventForm(method, id));

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (dataClone.participants?.length) {
      // (Only when updating existing users) Explanation for part: { user: user } here 'user' is actually a participant, so after this we are getting the structure: user: { id: "participant_id", user: { id: "user_id" }}
      // backend is considering that the first user.id is actually a user id
      // Quick and dirty fix: we are changing the first user.id to be actual user id
      let p = dataClone.participants.map((participant) => {
        // existing participant
        if (participant.user?.id) {
          return participant;
        } else {
          // new participant, set user
          return {
            user: participant,
          };
        }
      });

      dataClone.participants = p;
    }

    if (!dataClone?.title) {
      dataClone.title = "Todo";
    }

    return dataClone;
  };

  React.useEffect(() => {
    if (formLoaded && instance && isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, [formLoaded, isOpen, instance]);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            return preProcess(data, "POST");
          },
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess: (data) => {
            return preProcess(data, "PATCH");
          },
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Lägg till uppgift" : "Uppdatera uppgift"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
      modalInModal={modalInModal}
    />
  );
}
