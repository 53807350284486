import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";

import columnDefs from "./listDefs";

// store, state

import { constants, updateUrl } from "../../../store/paymentContribution";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "payment_contribution_full_table"}
      title={"Hyresavtal"} //TODO: FinalBaseTable: Correct?
      onRowClickedWithDetail={(obj) =>
        updateUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
