import * as React from "react";
import { useAllPermissionCheck } from "../../../../../store/base";
import { useCanUseScrive } from "../../../../../store/editabledocs/hooks/retrieve";

import StepOptions from "../../../Base/StepOptions/StepOptions";

const STEPS = {
  CONTRACT_TYPE: 1,
  PREMISES_TYPE: 2,
  SIGNING_TYPE: 3,
};

export default function ContractType({ onDone }) {
  const [canUseScrive] = useCanUseScrive();

  const [step, setStep] = React.useState(STEPS.CONTRACT_TYPE);

  const [contractType, setContractType] = React.useState(null);
  const [premisesType, setPremisesType] = React.useState(null);
  const [signingType, setSigningType] = React.useState(null);

  const hasFastDocPermission = useAllPermissionCheck([
    "allow_fastdoc",
    "view_can_fastdoc",
  ]);

  return (
    <>
      {step === STEPS.CONTRACT_TYPE && (
        <StepOptions
          title="Välj typ av avtal"
          onSelected={(type) => {
            setContractType(type);
            setStep(STEPS.PREMISES_TYPE);
          }}
          options={[
            {
              id: "new",
              title: "Nytt avtal",
              renderDescription: () => (
                <div>
                  Skapa ett nytt avtal med antingen manuell eller digital
                  signering.
                </div>
              ),
            },
            {
              id: "existing",
              title: "Existerande avtal",
              renderDescription: () => (
                <div>
                  Lägg till ett existerande avtal som redan är signerat.
                  <br />
                  Vid skapande av existerande avtal så hoppas dokumentsteget
                  över.
                </div>
              ),
            },
          ]}
        />
      )}

      {step === STEPS.PREMISES_TYPE && (
        <StepOptions
          title="Välj typ av objekt som avtalet gäller för"
          goBack={() => setStep(STEPS.CONTRACT_TYPE)}
          onSelected={(type) => {
            setPremisesType(type);

            if (canUseScrive && contractType === "new") {
              setStep(STEPS.SIGNING_TYPE);
            } else {
              onDone({
                contractType,
                premisesType: type,
              });
            }
          }}
          options={[
            {
              id: "apartments",
              title: "Hyreslägenheter",
              renderDescription: () => (
                <div>Avtalet gäller en eller flera hyreslägenheter.</div>
              ),
            },
            {
              id: "industrialpremises",
              title: "Lokaler",
              renderDescription: () => (
                <div>Avtalet gäller en eller flera lokaler</div>
              ),
            },
            {
              id: "both",
              title: "Både hyreslägenheter och lokaler",
              renderDescription: () => (
                <div>Avtalet gäller både hyreslägenheter och lokaler</div>
              ),
            },
          ]}
        />
      )}

      {step === STEPS.SIGNING_TYPE && (
        <StepOptions
          title="Välj typ av signering"
          goBack={() => setStep(STEPS.PREMISES_TYPE)}
          onSelected={(type) => {
            setSigningType(type);
            onDone({
              contractType,
              premisesType,
              signingType: type,
            });
          }}
          options={[
            {
              id: "digital",
              title: "Digital signering genom Scrive",
              renderDescription: () => (
                <div>
                  Avtalet signeras digital genom Scrive och uppdateras
                  automatiskt vid avklarad signering
                </div>
              ),
            },
            {
              id: "manual",
              title: hasFastDocPermission
                ? "Manuell eller genom Fastighetsägarna dokument"
                : "Manuell signering",
              renderDescription: () => (
                <div>
                  Avtalet signeras manuellt
                  {hasFastDocPermission
                    ? "/genom Fastighetsägarna Dokument"
                    : ""}{" "}
                  genom att markera det som signerat i systemet och ladda upp
                  det signerade avtalet.
                </div>
              ),
            },
          ]}
        />
      )}
    </>
  );
}
