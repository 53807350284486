import * as React from "react";

// style, design

import { RadioGroup, TableSelect, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/yourBlock";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useFormInstanceField } from "../../../../../store/base";
import { useRealEstate } from "../../../../../store/realEstates";
import { InnerBox } from "../../../../sharedStyles";

export default ({ method, onlyPigello }) => {
  const storeName = constants.STORE_NAME;

  const realEstateId = useFormInstanceField({
    storeName,
    fieldKey: "realestate.id",
  });
  const [realEstate] = useRealEstate(realEstateId);
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för integration</OverviewTitle>
          <OverviewSubtitle>
            Integration för fastigheten{" "}
            {realEstate?.str_representation || "Laddar..."}
          </OverviewSubtitle>
          <OverviewSubtitle>
            Ange autentisering och inställningar för hur integrationen ska
            fungera
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>YourBlock-konto (kontoägare)</OverviewTitle>
            <OverviewSubtitle>
              <strong>OBS:</strong> Om kontot ej finns i YourBlock kommer det
              att skapas upp och denna användare kommer att bli kontoägare. Vid
              koppling mot existerande YourBlock-konto är det kontoägarens konto
              som ska loggas in med.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <TextInput
          {...{
            method,
            storeName,
            fieldKey: "email",
            title: "E-post för inlogg i YourBlock",
          }}
        />

        <TextInput
          {...{
            method,
            storeName,
            fieldKey: "password",
            title: "Lösenord för inlogg i YourBlock",
          }}
        />
      </InnerBox>

      {!onlyPigello && (
        <>
          <TextInput
            {...{
              method,
              storeName,
              fieldKey: "client_id",
              title: "Klient-ID",
              description:
                "Klient-ID hos YourBlock för API-integration. Kontakta YourBlock om du är osäker på vad denna är.",
            }}
          />
          <TextInput
            {...{
              method,
              storeName,
              fieldKey: "client_secret",
              title: "Klient-nyckel",
              description:
                "Klient-nyckel hos YourBlock för API-integration. Kontakta YourBlock om du är osäker på vad denna är. ",
            }}
          />
        </>
      )}

      <RadioGroup
        title="Synk aktiv"
        description="Välj om synken mellan YourBlock och Pigello ska vara aktiv eller ej"
        storeName={storeName}
        method={method}
        defaultValue={false}
        options={[
          { label: "Aktiv", value: true },
          {
            label: `Ej aktiv ${
              !onlyPigello
                ? "(Rekommenderas för att undvika dubletter vid initial koppling)"
                : ""
            }`,
            value: false,
          },
        ]}
        fieldKey={`sync_active`}
      />
    </>
  );
};
