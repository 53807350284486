export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ERRANDROLE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ERRANDROLE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ERRANDROLE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ERRANDROLE",
  DESTROY_FORM: "DESTROY_FORM_ERRANDROLE",
  SET_FORM_ERROR: "SET_FORM_ERROR_ERRANDROLE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ERRANDROLE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ERRANDROLE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ERRANDROLE",
  RESET_STATE: "RESET_STATE_ERRANDROLE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_ERRANDROLE",
  REMOVE_OBJECT: "REMOVE_OBJECT_ERRANDROLE",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_ERRANDROLE",
  CLEAR_FETCHED: "CLEAR_FETCHED_ERRANDROLE",

  LIST_URL: "/errands/roles/role/list/",
  POST_URL: "/errands/roles/role/",
  PATCH_URL: "/errands/roles/role/",
  GET_URL: "/errands/roles/role/",

  STORE_NAME: "errandRoles",
};
