import * as React from "react";

// style, design
import { BooleanLabel } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import BooleanFilter from "src/components/Lists/Base/CompleteList/Filters/BooleanFilter";

export default () => [
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Använder senast registrerade värde vid tom period",
    accessor: "assume_previous_value",
    Cell: ({ value, row }) => {
      return <BooleanLabel value={value} onLabel={"Ja"} offLabel={"Nej"} />;
    },
    Filter: BooleanFilter,
    filter: "text",
  },
];
