import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useHistory } from "react-router-dom";
import { axiosInstance } from "../../../store/base/store/axios";

export default function OutlookIntegration() {
  const [outlookActivationStatus, setOutlookActivationStatus] =
    React.useState(undefined);

  const current = INTEGRATIONS.find((i) => i.title === "Outlook");

  const { push } = useHistory();

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const checkOutlookStatus = async () => {
    const url = "events/outlook/has_credential/";
    try {
      const result = await axiosInstance.get(url);
      setOutlookActivationStatus(result?.data?.result);
    } catch (err) {
      setOutlookActivationStatus(null);
    }
  };

  React.useEffect(() => {
    if (outlookActivationStatus !== undefined) {
      return;
    }
    checkOutlookStatus();
  }, [outlookActivationStatus]);

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: "Outlook" },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Microsofts Outlook-hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Outlook-integrationen ställs in globalt.
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Aktivera Outlook-integrationen genom att godkänna att Pigello
                  får hämta data från ditt Microsoft365-konto.
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Skapa en ny kalender och välj en av dina Outlook-kalendrar att
                  koppla den till. <strong>OBS:</strong> Pigello kan enbart läsa
                  från Outlook, och därmed kan inga kalenderhändelser skapas i
                  denna kalender direkt från Pigello.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              {outlookActivationStatus !== true ? (
                <>
                  {" "}
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Aktivera integration</OverviewTitle>
                      <OverviewSubtitle>
                        Outlook-integrationen aktiveras i kalendern
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>
                  <TextButton
                    title="Gå till kalendern"
                    iconType="arrow"
                    iconPlacement="right"
                    clicked={() => push("/calendar")}
                  />
                </>
              ) : (
                <>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Integration aktiv</OverviewTitle>
                      <OverviewSubtitle>
                        Outlook-integrationen har aktiverats. Gå till kalendern
                        för att hantera Outlook-kalendrar
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <TextButton
                    title="Gå till kalendern"
                    iconType="arrow"
                    iconPlacement="right"
                    clicked={() => push("/calendar")}
                  />
                </>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
