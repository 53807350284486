import * as React from "react";

import { constants } from "../../../../../store/reportErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { TextButton } from "../../../Base/Buttons";

import { Checkbox, Select, TextInput, TimePicker } from "../../../Base/Fields";
import DurationField from "../../../Base/Fields/DurationField";
import ReportedBySelection from "../../../ErrandBase/ReportedBySelection";

import { InfoBox } from "../../../../Displays";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ErrandRoleUsersTable from "src/components/Tables/RoleUsers/FullTable";
import RoundingFaultTable from "src/components/Tables/RoundingFaults/FullTable";

export default ({ method }) => {
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = React.useState(false);
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Detaljer</OverviewTitle>
          <OverviewSubtitle>
            Detaljerade inställningar för felanmälan
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TimePicker
        storeName={storeName}
        method={method}
        fieldKey={"created_at"}
        defaultNow={true}
        title="Mottaget den"
      />

      {method === "PATCH" && (
        <TextInput
          storeName={storeName}
          method={method}
          fieldKey={"video_url"}
          title="Video URL"
          description="Länk till video där ytterliggare detaljer kring ärendet samlats in."
        />
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          TableComponent={RoundingFaultTable}
          persistantQuery={{ fault_report__isnull: true, is_ok: false }}
          title="Koppla till ronderingsavvikelse"
          storeName={storeName}
          method={method}
          fieldKey="rounding_fault"
        />
      </div>

      <Select
        storeName={storeName}
        method={method}
        fieldKey={"priority"}
        title="Prioritet på ärendet"
      />

      <ReportedBySelection
        method={method}
        storeName={storeName}
        title="Rapporterat av"
      />

      {!advancedSettingsOpen && (
        <TextButton
          extraStyle={{ marginTop: 24 }}
          title="Visa avancerade inställningar"
          iconType="add"
          clicked={() => setAdvancedSettingsOpen(true)}
        />
      )}

      {advancedSettingsOpen && (
        <>
          <hr />

          <TimePicker
            storeName={storeName}
            fieldKey={"planned_start"}
            method={method}
            title="Planerat startdatum"
          />

          <TimePicker
            storeName={storeName}
            fieldKey={"planned_end"}
            method={method}
            title="Planerat slutdatum"
          />

          <DurationField
            description="Ange hur lång den förväntade faktistka arbetstiden är"
            storeName={storeName}
            fieldKey={"expected_duration"}
            method={method}
            extraStyles={{ marginTop: "12px" }}
            title="Förväntad arbetstid"
          />

          <hr />

          <InfoBox
            title="OBS"
            text="Vid val av specifik utförare kommer inte automatisk delegering att användas."
          />

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              method={method}
              fieldKey="performer"
              instructionsKey="performer"
              persistantQuery={{}}
              title="Utförare"
              placeholder="Välj utförare"
              TableComponent={ErrandRoleUsersTable}
            />
          </div>

          <Checkbox
            storeName={storeName}
            method={method}
            fieldKey={"can_use_key"}
            title={"Hyresgäst har förberett för tillträde"}
            description={
              "Hyresgästen har föreberett lägenheten/lokalen för tillträde inför utförande"
            }
          />

          <DurationField
            storeName={storeName}
            method={method}
            title="Påminn utförare så här lång tid innan planerad start"
            fieldKey="remind_executor_duration"
            menuPlacement="top"
          />
        </>
      )}
    </>
  );
};

const listDefs = () => [
  {
    Header: "Namn",
    accessor: "str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Roll",
    accessor: "participation.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Fastighet",
    accessor: "participation.realestate.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
];
