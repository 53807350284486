import * as React from "react";
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { ErrandStatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default (realEstates) => [
  {
    Header: "Status",
    accessor: "paused",
    Cell: ({ value }) => {
      return (
        <ErrandStatusLabel state={value ? "paused" : "active"}>
          {value ? "Inaktiv" : "Aktiv"}
        </ErrandStatusLabel>
      );
    },
  },
  {
    Header: "Titel",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Nästkommande ärende",
    accessor: "next_recurrence",
    Cell: ({ value }) => {
      let dateForShow = "-";
      if (value) {
        const date = value.split("T")[0];
        dateForShow = date;
      }
      return <ToolTipCell text={dateForShow} />;
    },
  },
  {
    Header: "Fastigheter",
    accessor: "realestates",
    Cell: ({ value }) => {
      const settingRealEstateIds = value;
      const matches = realEstates?.filter((r) =>
        settingRealEstateIds.includes(r.id)
      );

      const estates = matches?.map((m) => m.str_representation);
      return <div>{estates?.length ? estates.join(", ") : "-"}</div>;
    },
  },
];
