export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MARKETING_REQUIREMENTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MARKETING_REQUIREMENTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MARKETING_REQUIREMENTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MARKETING_REQUIREMENTS",
  DESTROY_FORM: "DESTROY_FORM_MARKETING_REQUIREMENTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_MARKETING_REQUIREMENTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MARKETING_REQUIREMENTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MARKETING_REQUIREMENTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MARKETING_REQUIREMENTS",
  RESET_STATE: "RESET_STATE_MARKETING_REQUIREMENTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MARKETING_REQUIREMENTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_MARKETING_REQUIREMENTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_MARKETING_REQUIREMENTS",

  LIST_URL: "/market/ads/requirement/list/",
  POST_URL: "/market/ads/requirement/",
  PATCH_URL: "/market/ads/requirement/",
  GET_URL: "/market/ads/requirement/",

  STORE_NAME: "marketRequirements",
};
