import * as React from "react";
import * as SC from "./styles";

export default function ToggleSqm({ isSqm, setIsSqm, extraStyles = {} }) {
  return (
    <SC.ToggleBoxWrapper style={{ ...extraStyles }}>
      <SC.ToggleBoxItem
        selected={!isSqm}
        onClick={(e) => {
          e.stopPropagation();
          setIsSqm(false);
        }}
      >
        Total
      </SC.ToggleBoxItem>
      <SC.ToggleBoxItem
        selected={isSqm}
        onClick={(e) => {
          e.stopPropagation();
          setIsSqm(true);
        }}
      >
        Per m²
      </SC.ToggleBoxItem>
    </SC.ToggleBoxWrapper>
  );
}
