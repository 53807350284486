import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  BooleanLabel,
  DateCell,
  LinkedObject,
  ToolTipCell,
} from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import PaymentContributionTable from "../../../components/Tables/PaymentContributionRecord/FullTable";

import {
  detailUrl as paymentGroupDetailUrl,
  usePaymentGroup,
} from "../../../store/paymentGroup";
import { detailUrl as brfPremisDetailUrl } from "../../../store/brfPremises";
import { useBrfPaymentGroupQuota } from "../../../store/brfPaymentGroupQuota";
import PaymentGroupSubtable from "../../../components/Tables/PaymentGroup/SubTable";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { createUrl as paymentContributionCreateUrl } from "../../../store/paymentContribution";
import { toMoneyString } from "../../../components/utils/stringUtils";

export default function BrfPaymentGroupDetail() {
  const { push } = useHistory();

  const { brfPaymentGroupQuotaId } = useParams();

  const [instance, instanceLoading] = useBrfPaymentGroupQuota(
    brfPaymentGroupQuotaId
  );
  const [fullPaymentGroup, fullPaymentGroupLoading] = usePaymentGroup(
    instance?.payment_group?.id
  );

  const canAdd = useAllPermissionCheck(["add_can_brf"]);

  const getInfoObj = () => {
    return {
      Inställningar: [
        {
          title: "Avgiftsgrupp",
          value: (
            <LinkedObject
              obj={instance.payment_group}
              urlMethod={paymentGroupDetailUrl}
            />
          ),
        },
        {
          title: "Lägenhet",
          value: (
            <LinkedObject
              obj={instance.brf_premis}
              urlMethod={brfPremisDetailUrl}
            />
          ),
        },
        {
          title: "Andel",
          value: <ToolTipCell text={`${instance.quota.toFixed(5)} %`} />,
        },
        {
          title: "Total årsavgift",
          value: (
            <ToolTipCell
              text={toMoneyString(instance.total_yearly_payment_fee, true, 2)}
            />
          ),
        },
        {
          title: "Total månadsavgift",
          value: (
            <ToolTipCell
              text={toMoneyString(
                instance.total_yearly_payment_fee / 12,
                true,
                2
              )}
            />
          ),
        },
        {
          title: "Total kvarvarande skuld",
          value:
            instance.current_debt_to_pay != null ? (
              <ToolTipCell
                text={toMoneyString(instance.current_debt_to_pay, true, 2)}
              />
            ) : (
              "-"
            ),
        },
      ],
    };
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "49%",
              marginRight: "24px",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Detaljer</OverviewTitle>
              </OverviewTitleWrapper>

              {instance && (
                <>
                  <DetailInfo infoObj={getInfoObj()} />

                  <OverviewTitleWrapper>
                    <OverviewTitle>
                      Skulder/Betalningar (ej mot aviseringsstatus)
                    </OverviewTitle>
                    {canAdd && (
                      <div>
                        <TextButton
                          title="Lägg till"
                          iconType="add"
                          iconPlacement="right"
                          clicked={() =>
                            push(
                              paymentContributionCreateUrl(
                                instance?.payment_group?.id,
                                instance?.brf_premis?.id
                              )
                            )
                          }
                        />
                      </div>
                    )}
                  </OverviewTitleWrapper>

                  <PaymentContributionTable
                    persistantQuery={{
                      payment_group: instance.payment_group?.id,
                      brf_premis: instance.brf_premis?.id,
                    }}
                    isBare
                    ignoreLocalStorage
                  />
                </>
              )}
            </DetailPageBox>
          </div>

          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "49%",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Förhandsgranskning</OverviewTitle>
              </OverviewTitleWrapper>

              {fullPaymentGroup && (
                <PaymentGroupSubtable
                  paymentGroup={fullPaymentGroup}
                  cutWithQuota={instance.quota / 100}
                  cutAgainstTodayDebt={
                    fullPaymentGroup.close_for_fully_payed_premis
                      ? instance.current_debt_to_pay
                      : null
                  }
                />
              )}
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
