import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  constants as homeQConstants,
  useIsHomeQAuthenticated,
} from "../../../store/homeQ";
import { LEAD_ACCEPT_TYPES } from "../../../store/leads/store/actions";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../../Forms/Base/Buttons";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { InnerBox } from "../../sharedStyles";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import NonConnectedTextField from "../../Forms/Base/Old/NonConnected/NonConnectedTextField";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { usePermissionCheck } from "../../../store/base";
import * as ReportErrandStyles from "../../Forms/ReportErrandSetting/ClassificationList/styles";
import {
  ArrowDownIcon,
  ArrowUpIcon,
} from "../../Forms/ReportErrandSetting/DelegationOrder/styles";

const STEPS = {
  BASE: 1,
  ORDER: 2,
  HOMEQ_REJECTION_REASON: 3,
  SBF_REJECTION_REASON: 4,
  CONFIRM: 5,
};

const READABLE_INTEGRATIONS = {
  0: "HomeQ",
  1: "Bostadsförmedlingen Stockholm",
};

export default function ConfirmLeadModal({
  callback,
  openLeadCallback,
  loading,
  isOpen,
  leads,
  closeFunction,
  isHomeQPipe,
}) {
  const dispatch = useDispatch();

  const [homeQActivated] = useIsHomeQAuthenticated();
  const showHomeQ = homeQActivated && isHomeQPipe;
  const sbfActivated = usePermissionCheck("allow_sbf");

  const [activeLeads, setActiveLeads] = React.useState(null);

  const [order, setOrder] = React.useState([]); // initial, HomeQ -> SBF

  React.useEffect(() => {
    if (showHomeQ && sbfActivated) {
      setOrder([0, 1]);
    } else if (showHomeQ) {
      setOrder([0]);
    } else if (sbfActivated) {
      setOrder([1]);
    } else {
      setOrder([]);
    }
  }, [showHomeQ, sbfActivated]);

  React.useEffect(() => {
    if (isOpen) {
      setActiveLeads(leads);
    } else {
      setActiveLeads(null);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (activeLeads && activeLeads.length === 0) {
      closeFunction();

      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Bekräftaflödet avbröts",
        })
      );
    }
  }, [activeLeads]);

  const storeName = homeQConstants.STORE_NAME;
  const [selectedHomeQRejectionReason, setSelectedHomeQRejectionReason] =
    React.useState(null);
  const [selectedSbfRejectionReason, setSelectedSbfRejectionReason] =
    React.useState(null);
  const [selectedType, setSelectedType] = React.useState(null);
  // this fetch dispatch is run from main pipe to prevent double fetches
  const rejectionReasons = useSelector(
    (state) => state[storeName].rejectionReasons
  );

  const [step, setStep] = React.useState(STEPS.BASE);

  const {
    apartments: acceptApartments,
    indps: acceptsIndps,
    parkings: acceptParkings,
  } = buildAcceptByObjectObj(activeLeads);

  const openObjInNewTab = (obj, type) => {
    switch (type) {
      case "apartment": {
        window.open(apartmentDetailUrl({ id: obj.id }), "_blank").focus();
        break;
      }
      case "indp": {
        window.open(indpDetailUrl({ id: obj.id }), "_blank").focus();
        break;
      }
      case "parking": {
        window.open(parkingDetailUrl({ id: obj.id }), "_blank").focus();
        break;
      }
    }
  };

  const excludeLead = (leadId) => {
    const newActiveLeads = activeLeads.filter((l) => l.id !== leadId);

    setActiveLeads(newActiveLeads);
  };

  const checkNext = () => {
    switch (step) {
      case STEPS.CONFIRM: {
        callback({
          acceptType: selectedType,
          homeQRejectionReason: selectedHomeQRejectionReason,
          sbfRejectionReason: selectedSbfRejectionReason,
          prioOrder: order,
          acceptedLeads: activeLeads,
        });
        break;
      }

      case STEPS.ORDER: {
        if (showHomeQ) {
          setStep(STEPS.HOMEQ_REJECTION_REASON);
        } else if (sbfActivated) {
          setStep(STEPS.SBF_REJECTION_REASON);
        } else {
          setStep(STEPS.CONFIRM);
        }

        break;
      }

      case STEPS.HOMEQ_REJECTION_REASON: {
        if (sbfActivated) {
          setStep(STEPS.SBF_REJECTION_REASON);
        } else {
          setStep(STEPS.CONFIRM);
        }

        break;
      }

      case STEPS.SBF_REJECTION_REASON: {
        setStep(STEPS.CONFIRM);
        break;
      }

      default:
        break;
    }
  };

  const handleChangeOrder = () => {
    // hacky solution, no more needed for now

    if (order[0] === 0) {
      setOrder([1, 0]);
    } else {
      setOrder([0, 1]);
    }
  };

  return (
    <StandardModal
      title={`Bekräfta ${activeLeads?.length || ""} lead${
        activeLeads?.length > 1 ? "s" : ""
      }`}
      isOpen={isOpen}
      closeFunction={() => {
        setStep(STEPS.BASE);
        closeFunction();
      }}
      withActionBar={step !== STEPS.BASE}
      actionBarAcceptTitle={
        step === STEPS.CONFIRM ? "Bekräfta leads" : "Gå vidare"
      }
      saveFunction={checkNext}
    >
      {loading && <OverlaySpinner />}
      {step === STEPS.BASE && (
        <>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj hur bekräftelsen ska hanteras
              </OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Bekräfta i en källa</OverviewTitle>
                <OverviewSubtitle>
                  Bekräfta leaden i en plattform. I nästa steg bestäms
                  prioritetsordningen. Detta alternativ rekommenderas om leads
                  kommer från flera olika källor.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            <TextButton
              title="Bekräfta i en källa"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => {
                setSelectedType(LEAD_ACCEPT_TYPES.ACCEPT_IN_ORDER);

                setStep(STEPS.ORDER);
              }}
            />
          </InnerBox>

          <InnerBox style={{ marginTop: 12 }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Bekräfta i alla källor</OverviewTitle>
                <OverviewSubtitle>
                  {" "}
                  Bekräfta leaden i annonsen på alla tillgängliga plattformar.
                  Detta alternativ rekommenderas om alla leads i projektet
                  kommer från samma källa, t.ex. HomeQ
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <TextButton
              title="Bekräfta i alla källor"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => {
                setSelectedType(LEAD_ACCEPT_TYPES.ACCEPT_IN_ALL_SOURCES);

                if (showHomeQ) {
                  setStep(STEPS.HOMEQ_REJECTION_REASON);
                } else if (sbfActivated) {
                  setStep(STEPS.SBF_REJECTION_REASON);
                } else {
                  setStep(STEPS.CONFIRM);
                }
              }}
            />
          </InnerBox>
        </>
      )}

      {step === STEPS.ORDER && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj i vilken ordning källorna ska prioriteras
              </OverviewTitle>
              <OverviewSubtitle>
                Om en lead har flera källor så kommer den enbart att bekräftas
                på den högst prioriterade källan. <strong>OBS:</strong> Leaden
                kommer alltid att bekräftas i Pigello, detta gäller endast
                integrationer
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <InnerBox>
            <ReportErrandStyles.ListContent>
              {order.map((key, idx) => (
                <ReportErrandStyles.ListItem key={key}>
                  <ReportErrandStyles.ComponentName>
                    {READABLE_INTEGRATIONS[key]}
                  </ReportErrandStyles.ComponentName>
                  <ReportErrandStyles.ComponentArea
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div style={{ marginRight: "4px" }}>{idx + 1}</div>
                    {order.length > 1 && (
                      <>
                        {idx !== 0 && (
                          <ArrowUpIcon onClick={() => handleChangeOrder()} />
                        )}{" "}
                        {idx !== order.length - 1 && (
                          <ArrowDownIcon onClick={() => handleChangeOrder()} />
                        )}
                      </>
                    )}
                  </ReportErrandStyles.ComponentArea>
                </ReportErrandStyles.ListItem>
              ))}
            </ReportErrandStyles.ListContent>
          </InnerBox>
        </>
      )}

      {step === STEPS.HOMEQ_REJECTION_REASON && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj vilken anledning till nekande eventuella övriga sökande i
                HomeQ ska få
              </OverviewTitle>
              <OverviewSubtitle>
                Om en annons har fler sökande så krävs det att en anledning till
                att de nekas objektet specificeras för övriga sökande
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <NonConnectedSelect
            id="rejection_reason"
            value={selectedHomeQRejectionReason}
            choices={rejectionReasons}
            getOptionLabel={(o) => o.description}
            getOptionValue={(o) => o.id}
            placeholder="Välj en anledning"
            onUpdate={(val) => setSelectedHomeQRejectionReason(val)}
          />
        </>
      )}

      {step === STEPS.SBF_REJECTION_REASON && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj vilken anledning till nekande eventuella övriga sökande i
                Stockholms Bostadsförmedling ska få
              </OverviewTitle>
              <OverviewSubtitle>
                Om en annons har fler sökande så krävs det att en anledning till
                att de nekas objektet specificeras för övriga sökande
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <NonConnectedTextField
            id="sbf_rejection_reason"
            value={selectedSbfRejectionReason}
            placeholder="Välj en anledning"
            handleChange={(val) => setSelectedSbfRejectionReason(val)}
          />
        </>
      )}

      {step === STEPS.CONFIRM && (
        <>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            clicked={() => setStep(STEPS.BASE)}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Bekräfta acceptans av leads</OverviewTitle>
              <OverviewSubtitle>
                Vänligen bekräfta att du vill bekräfta valda leads. Observera
                att övriga sökande på samma annons automatiskt kommer att nekas.{" "}
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>
                  Dessa objekt kommer att hyras ut
                </OverviewTitle>
                <OverviewSubtitle>
                  Om 2 eller fler sökande på samma annons försöker bekräftas
                  samtidigt kommer detta resultera i ett felmeddelande
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {acceptApartments.map((ap) => {
              const isWarning = ap.leads.length > 1;

              return (
                <InnerBox
                  error={isWarning}
                  key={ap.id}
                  style={{ marginBottom: 8 }}
                >
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Lägenhet {ap.str_representation}
                      </OverviewTitle>
                      <OverviewSubtitle>
                        <TextButton
                          title="Öppna i ny flik"
                          clicked={() => openObjInNewTab(ap, "apartment")}
                          iconType="launch"
                          iconPlacement="right"
                        />{" "}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <div style={{ fontWeight: 500, fontSize: 14 }}>Leads</div>
                  {isWarning && (
                    <div
                      style={{ color: "red", fontWeight: 500, fontSize: 14 }}
                    >
                      OBS: Fler leads på samma objekt. Vänligen åtgärda detta
                      innan du bekräftar uthyrningen av objektet.
                    </div>
                  )}
                  {ap.leads.map((l) => (
                    <div
                      key={l.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <TextButton
                        extraStyle={{ marginRight: 4 }}
                        title={l.str_representation}
                        clicked={
                          openLeadCallback
                            ? () => openLeadCallback(l)
                            : () => {}
                        }
                      />
                      -
                      <TextButton
                        extraStyle={{ marginLeft: 4 }}
                        title="Exludera från bekräftelsen"
                        clicked={() => excludeLead(l.id)}
                        iconType="close"
                        iconPlacement="right"
                      />
                    </div>
                  ))}
                </InnerBox>
              );
            })}

            {acceptsIndps.map((ap) => {
              const isWarning = ap.leads.length > 1;

              return (
                <InnerBox
                  error={isWarning}
                  key={ap.id}
                  style={{ marginBottom: 8 }}
                >
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Lägenhet {ap.str_representation}
                      </OverviewTitle>
                      <OverviewSubtitle>
                        <TextButton
                          title="Öppna i ny flik"
                          clicked={() => openObjInNewTab(ap, "indp")}
                          iconType="launch"
                          iconPlacement="right"
                        />{" "}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <div style={{ fontWeight: 500, fontSize: 14 }}>Leads</div>
                  {isWarning && (
                    <div
                      style={{ color: "red", fontWeight: 500, fontSize: 14 }}
                    >
                      OBS: Fler leads på samma objekt. Vänligen åtgärda detta
                      innan du bekräftar uthyrningen av objektet.
                    </div>
                  )}
                  {ap.leads.map((l) => (
                    <div
                      key={l.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <TextButton
                        extraStyle={{ marginRight: 4 }}
                        title={l.str_representation}
                        clicked={
                          openLeadCallback
                            ? () => openLeadCallback(l)
                            : () => {}
                        }
                      />
                      -
                      <TextButton
                        extraStyle={{ marginLeft: 4 }}
                        title="Exludera från bekräftelsen"
                        clicked={() => excludeLead(l.id)}
                        iconType="close"
                        iconPlacement="right"
                      />
                    </div>
                  ))}
                </InnerBox>
              );
            })}

            {acceptParkings.map((ap) => {
              const isWarning = ap.leads.length > 1;

              return (
                <InnerBox
                  error={isWarning}
                  key={ap.id}
                  style={{ marginBottom: 8 }}
                >
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        Lägenhet {ap.str_representation}
                      </OverviewTitle>
                      <OverviewSubtitle>
                        <TextButton
                          title="Öppna i ny flik"
                          clicked={() => openObjInNewTab(ap, "parking")}
                          iconType="launch"
                          iconPlacement="right"
                        />{" "}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <div style={{ fontWeight: 500, fontSize: 14 }}>Leads</div>
                  {isWarning && (
                    <div
                      style={{ color: "red", fontWeight: 500, fontSize: 14 }}
                    >
                      OBS: Fler leads på samma objekt. Vänligen åtgärda detta
                      innan du bekräftar uthyrningen av objektet.
                    </div>
                  )}
                  {ap.leads.map((l) => (
                    <div
                      key={l.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <TextButton
                        extraStyle={{ marginRight: 4 }}
                        title={l.str_representation}
                        clicked={
                          openLeadCallback
                            ? () => openLeadCallback(l)
                            : () => {}
                        }
                      />
                      -
                      <TextButton
                        extraStyle={{ marginLeft: 4 }}
                        title="Exludera från bekräftelsen"
                        clicked={() => excludeLead(l.id)}
                        iconType="close"
                        iconPlacement="right"
                      />
                    </div>
                  ))}
                </InnerBox>
              );
            })}
          </InnerBox>
        </>
      )}
    </StandardModal>
  );
}

export function buildAcceptByObjectObj(leads = []) {
  /**
     These objects contain key:value pairs that look like 

        {
            8: {
                leads: [
                    ...lead
                ],
                id: 8,
                str_representation: "1231 (2 rok)",
            }
        }
     */
  const apartments = {};
  const indps = {};
  const parkings = {};

  for (let i = 0; i < (leads?.length || 0); i++) {
    let cur = leads[i];

    if (cur.apartment) {
      if (apartments[cur.apartment.id]) {
        apartments[cur.apartment.id].leads.push(cur);
      } else {
        apartments[cur.apartment.id] = {
          ...cur.apartment,
          leads: [cur],
        };
      }
    } else if (cur.industrial_premises) {
      if (indps[cur.industrial_premises.id]) {
        indps[cur.industrial_premises.id].leads.push(cur);
      } else {
        indps[cur.industrial_premises.id] = {
          ...cur.industrial_premises,
          leads: [cur],
        };
      }
    } else if (cur.parking_spot) {
      if (parkings[cur.parking_spot.id]) {
        parkings[cur.parking_spot.id].leads.push(cur);
      } else {
        parkings[cur.parking_spot.id] = {
          ...cur.parking_spot,
          leads: [cur],
        };
      }
    }
  }

  // return as arrays for easy rendering. Sort to bring warnings to top
  return {
    apartments: Object.values(apartments).sort(
      (a, b) => b.leads.length - a.leads.length
    ),
    indps: Object.values(indps).sort((a, b) => b.leads.length - a.leads.length),
    parkings: Object.values(parkings).sort(
      (a, b) => b.leads.length - a.leads.length
    ),
  };
}
