import * as React from "react";

// style, design

import columnDefs from "./errandConfigListDefs";

// store, state
import { constants } from "../../../store/realEstates";

import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function RealEstateErrandConfigTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onExcludeClicked,
}) {
  const columns = React.useMemo(
    () => columnDefs({ onExcludeClicked }),
    [persistantQuery, onExcludeClicked]
  );

  const filters = {
    Beteckning: {
      queryKey: "key",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  return (
    <>
      <BaseTable
        tableId={tableId || "realestate_errands_full_table"}
        title={"Fastigheter"}
        onRowClickedWithDetail={(obj) => {}}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          filters,
          constants,
          checkRowHighlighted,
        }}
      />
    </>
  );
}
