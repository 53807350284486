import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";

export default () => {
  const cols = [
    {
      Header: "Synkstatus",
      accessor: (row) => (!!row.homeq_id ? "Synkad" : "Ej synkad"),
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value === "Synkad" ? 0 : 3}>
            {value === "Synkad" ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        );
      },
      Filter: SimpleTextFilter,
      filter: "textExact",
    },
    {
      Header: "Lägenhetsnummer",
      accessor: "apartment_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Objektsnummer",
      accessor: "premises_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Adress",
      id: "address",
      accessor: (row) => row.address?.str_representation,
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastighet",
      accessor: (row) => row.realestate?.str_representation,
      Cell: ({ row }) => {
        const value = row.original.realestate;
        return <LinkedObject obj={value} urlMethod={realEstateDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Byggnad",
      accessor: "building.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
