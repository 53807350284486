import styled from "styled-components";

export const MenuWrapper = styled.div`
  height: 90px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid ${(p) => p.theme.colors.fadedBorder};
  z-index: 12;
`;
