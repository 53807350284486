import * as React from "react";

import { useHistory } from "react-router";

import GroupList from "../../components/Account/GroupList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import * as SharedStyles from "../../components/sharedStyles";

import { useAllPermissionCheck } from "../../store/base";
import { useSupervisorCheck } from "../../store/base/hooks/permissions";

import PageTitle from "../Layouts/PageTitle/PageTitle";

export default () => {
  const { push } = useHistory();

  const canAddGroup = useAllPermissionCheck([
    "view_can_permissions",
    "add_can_permissions",
  ]);

  const isSupervisor = useSupervisorCheck();

  const goToAddGroup = () => {
    push("/configcenter/groups-and-users/group/add-group");
  };

  const breadCrumbs = [
    {
      label: "Användare & behörigheter",
      url: "/configcenter/groups-and-users/group",
    },
    {
      label: "Behörighetsgrupper"
    }
  ];


  return (
    <SharedStyles.BareLayoutWrapper>
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="Behörighetsgrupper" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

          <SharedStyles.DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Skapade grupper</OverviewTitle>
                <OverviewSubtitle>
                  Tryck på en grupp för att se detaljer och redigera medlemmar
                  och behörigheter
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
              {(canAddGroup || isSupervisor) && (
                <PrimaryButton
                  newDesign
                  title="Lägg till grupp"
                  clicked={goToAddGroup}
                />
              )}
            </OverviewTitleWrapper>
            <GroupList />
          </SharedStyles.DetailPageBox>

    </SharedStyles.BareLayoutWrapper>
  );
};
