import * as React from "react";
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Select, TextInput } from "../../../Base/Fields";

import { constants } from "../../../../../store/invoicingProducts";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
export const BLOCKED_PRODUCT_CATEGORIES = [28, 29];

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Produkt</FormAreaTitle>

      <div className="grid grid-cols-2 gap-6 max-w-[600px]">
        <TextInput
          title="Titel"
          {...{
            storeName,
            method,
            fieldKey: `title`,
          }}
        />
        <TextInput
          title="Enhet"
          description="Ange vilken enhet som ska användas"
          {...{
            storeName,
            method,
            fieldKey: `unit`,
          }}
        />
      </div>

      <DoubleFieldWrapper>
        <Select
          extraStyles={{ minWidth: "290px", marginRight: 20 }}
          title="Kategori"
          description="Typ av kostnad"
          filterChoices={(option) => {
            return !BLOCKED_PRODUCT_CATEGORIES.includes(option.v);
          }}
          {...{
            storeName,
            method,
            fieldKey: `category`,
          }}
        />

        <Select
          extraStyles={{ minWidth: "290px" }}
          title="Standard momssats"
          description="Standardmoms för denna produkt"
          {...{
            storeName,
            method,
            fieldKey: `vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Intäktskonto"
          description="3000 kontot är standardkontot för intäkter, om du vill ha ett specifikt konto för vissa produkter kan du välja det här"
          {...{
            storeName,
            method,
            fieldKey: `account_no`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="Utgående moms"
          description="Ange momskonto om du vill att försäljningen/kostnaden ska bokföras på annat momskonto än vad som angetts i bokföringsinställningar. Lämna blankt om du vill använda momskontot som anges i bokföringsinställningar."
          {...{
            storeName,
            method,
            fieldKey: `account_no_vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Konto försäljning EU"
          {...{
            storeName,
            method,
            fieldKey: `account_no_eu`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="Konto försäljning EU Moms"
          {...{
            storeName,
            method,
            fieldKey: `account_no_eu_vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Konto köp"
          {...{
            storeName,
            method,
            fieldKey: `account_no_purchase`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="Konto köp Moms"
          {...{
            storeName,
            method,
            fieldKey: `account_no_purchase_vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Konto försäljning icke-EU"
          {...{
            storeName,
            method,
            fieldKey: `account_no_non_eu`,
          }}
        />
      </DoubleFieldWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={constants.STORE_NAME}
          fieldKey={"vacancy_booking_product"}
          TableComponent={InvoicingProductTable}
          method={method}
          title="Skriv över produkt med denna vid vakansbokning"
          placeholder={"Välj produkt..."}
        />
      </div>
    </>
  );
};
