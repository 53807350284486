import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../../../components/Displays";
import { PrimaryButton } from "../../../../components/Forms/Base/Buttons";
import StandardModal from "../../../../components/Modals/StandardModal";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../../components/sharedStyles";

import InvoiceSettingsTable from "../../../../components/Tables/InvoicingSettings/FullTable";
import { buildQueryString } from "../../../../store/base";
import IMDSettingSummary from "../../../../components/Summaries/IMDSettingSummary";
import { useInvoicingIMD } from "../../../../store/invoicingIMDSettings";
import InvoicingIMDSettingModalForm from "../../../../components/Forms/InvoicingIMDSetting/ChapterForm/ModalForm";

function InvoicingIMDSettingOverview() {
  const { settingId } = useParams();
  const [imdSetting] = useInvoicingIMD(settingId);
  const [editOpen, setEditOpen] = React.useState(false);
  const [warnEditOpen, setWarnEditOpen] = React.useState(false);

  const persistantQuery = {
    imd_setting: settingId,
  };

  return (
    <>
      <StandardModal
        isOpen={warnEditOpen}
        title="Information om redigering"
        withActionBar
        saveFunction={() => {
          setEditOpen(true);
          setWarnEditOpen(false);
        }}
        closeFunction={() => setWarnEditOpen(false)}
        actionBarAcceptIsWarning
        actionBarAcceptTitle="Redigera ändå"
      >
        <InfoBox
          title="OBS"
          text={
            "Ändringar av denna inställning kommer att påverka aviseringsinställningarna som använder sig av den. Detta innebär att avtal och objekt som använder sig av de påverkade aviseringsinställningarna också påverkas. Kontrollera att detta är önskvärt."
          }
          boxTheme="yellow"
        />

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Används av</OverviewTitle>
          </OverviewTitleWrapper>

          <InvoiceSettingsTable {...{ persistantQuery }} />
        </DetailPageBox>
      </StandardModal>

      <InvoicingIMDSettingModalForm
        method="PATCH"
        id={settingId}
        instance={imdSetting}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>

            <PrimaryButton
              title="Redigera"
              clicked={() => setWarnEditOpen(true)}
            />
          </OverviewTitleWrapper>

          <IMDSettingSummary setting={imdSetting} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export default InvoicingIMDSettingOverview;
