import styled from "styled-components";

export const SearchBoxWrapper = styled.div`
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  max-width: 550px;
  overflow: hidden;
`;

export const SearchInput = styled.input.attrs({
  type: "text",
})`
  width: 100%;
  border: 0;
  padding: 12px 14px;
  font-weight: 500;
  background-color: #eceff1;
  color: #69707d;
  font-size: 1rem;
`;
