import * as React from "react";
import { useSelector } from "react-redux";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import * as SC from "./styles";
import {
  PopupButton,
  PrimaryButton,
  TextButton,
} from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import InvoiceTableFilters from "../ExternalFilters/InvoiceTableFilters";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { DELIVERY_METHOD_MAPPER_EXCL_PRICES } from "../../../store/billectaInvoicing/utils";
import { DateCell } from "../../Displays";
import { BodyText } from "../../sharedStyles";
import moment from "moment";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";

const PERSISTANT_FILTER_KEY = `persistant_category_invoicestable`;

export default function InvoicesTable({
  invoices,
  handleDownloadInvoice,
  handleSelectedInvoicesUpdated,
  selectedInvoices,
  handleAttestSelectedInvoices,
  handleMergeSelectedInvoices,
  handleRowClicked,
  attestSelectedDisabled,
  mergeActionDisabled,
  noActions,
  noFilters,
  renderBesideSearch,
}) {
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [filterCounts, setFilterCounts] = React.useState(null);
  const [hasPersistantFilter, setHasPersistantFilter] = React.useState(
    !!localStorage.getItem(PERSISTANT_FILTER_KEY)
  );

  React.useEffect(() => {
    let newFilterCounts = {};

    invoices.forEach((i) => {
      if (newFilterCounts[i.Stage]) {
        newFilterCounts[i.Stage] += 1;
      } else {
        newFilterCounts[i.Stage] = 1;
      }

      setFilterCounts(newFilterCounts);
    });
  }, [invoices]);

  const updateFilter = React.useCallback(
    (filter) => {
      if (selectedFilter === filter) {
        setSelectedFilter(null);
        localStorage.removeItem(PERSISTANT_FILTER_KEY);
      } else {
        setSelectedFilter(filter);
        localStorage.setItem(PERSISTANT_FILTER_KEY, filter);
      }
    },
    [selectedFilter]
  );

  React.useEffect(() => {
    if (hasPersistantFilter) {
      const item = localStorage.getItem(PERSISTANT_FILTER_KEY);

      const filter = item ? item : null;

      updateFilter(filter);
    }
  }, [hasPersistantFilter]);

  const data = React.useMemo(() => {
    let displayedInvoices = [];

    // map extra data
    displayedInvoices = invoices.map((i) => ({
      ...i,
      total: `${(i.InvoicedAmount.Value / 100)?.toLocaleString("sv")}`,
      totalLeft: `${(i.CurrentAmount.Value / 100)?.toLocaleString("sv")}`,
    }));

    // filter external filters
    if (selectedFilter) {
      displayedInvoices = displayedInvoices.filter((i) => {
        return i.Stage === selectedFilter;
      });
    }

    return displayedInvoices;
  }, [invoices, selectedFilter]);

  const billectaStateMap = {
    None: "Okänd",
    Created: "Ej attesterad",
    ReminderInvoiceSent: "Påminnelse",
    Manual: "Förfallen (Ej hanterad)",
    Completed: "Slutbetald",
    Cancelled: "Avbruten",
    InvoiceSent: "Skickad",
    Attested: "Attesterad",
    CreditInvoice: "Kreditfaktura",
    SentToDebtCollection: "Kravhantering",
    SalesRequested: "SalesRequested",
    SaleRequestAccepted: "SaleRequestAccepted",
    SalesRequestedCancelled: "SalesRequestedCancelled",
    SalesRequestedDenied: "SalesRequestedDenied",
  };

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: "Status",
        accessor: "Stage",
        Cell: (props) => {
          if (props.row?.original?.IsDisputed) {
            return (
              <StatusLabel state={6}>
                {billectaStateMap[props.value]} (Bestriden)
              </StatusLabel>
            );
          }

          if (props.row?.original?.ActionType === "CreditInvoiceAction") {
            if (props.row?.original?.AttestedDate == null) {
              return (
                <StatusLabel state={"Kreditfaktura"}>
                  Kreditfaktura (Ej attesterad)
                </StatusLabel>
              );
            }
            return (
              <StatusLabel state={"Kreditfaktura"}>Kreditfaktura</StatusLabel>
            );
          }
          return (
            <StatusLabel state={billectaStateMap[props.value]}>
              {billectaStateMap[props.value]}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Fakturanr.",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },

      {
        Header: "Hyresgäst",
        accessor: "DebtorName",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Originalbelopp (SEK)",
        accessor: "total",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Kvarvarande originalbelopp (SEK)",
        accessor: "totalLeft",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Leveransmetod",
        accessor: "DeliveryMethod",
        Cell: (props) => {
          return (
            <Field>{DELIVERY_METHOD_MAPPER_EXCL_PRICES[props.value]}</Field>
          );
        },
      },
      {
        Header: "Attesterad",
        accessor: "AttestedDate",
        Cell: (props) => {
          return <Field>{props.value ? "Ja" : "Nej"}</Field>;
        },
      },
      {
        Header: "Fakturadatum",
        accessor: "InvoiceDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "DueDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },

      {
        Header: "Skapad",
        accessor: "Created",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Betalningsinfo",
        id: "paid_relation",
        Cell: ({ row }) => {
          const closedDate = moment(row.original.ClosedDate);
          const dueDate = moment(row.original.DueDate);
          const now = moment();
          const attested = !!row.original.AttestedDate;
          const isCredit = row.original.ActionType === "CreditInvoiceAction";

          if (!attested || isCredit) return <Field> - </Field>;
          // not paid, late
          if (!closedDate.isValid() && now.isAfter(dueDate)) {
            const lateDays = now.diff(dueDate, "days");

            return (
              <StatusLabel state={6}>
                Ej betalad, försenad med {lateDays} dag
                {lateDays > 1 ? "ar" : ""}
              </StatusLabel>
            );
          }

          if (closedDate.isSameOrBefore(dueDate)) {
            return (
              <StatusLabel state={"Slutbetald"}>Betalad i tid</StatusLabel>
            );
          }

          if (closedDate.isAfter(dueDate)) {
            const latePaymentDays = closedDate.diff(dueDate, "days");
            return (
              <StatusLabel state={"Påminnelse"}>
                Betalad {latePaymentDays} dag
                {latePaymentDays > 1 ? "ar" : ""} för sent
              </StatusLabel>
            );
          }

          return <StatusLabel state={9}>Inväntar betalning</StatusLabel>;
        },
      },
    ];

    if (handleDownloadInvoice) {
      cols.push({
        Header: "Ladda ner",
        Cell: (props) => {
          return (
            <Field style={{ textAlign: "center" }}>
              <TextButton
                title="Ladda ner"
                iconType="download"
                iconPlacement="right"
                clicked={() => handleDownloadInvoice(props.cell.row.original)}
              />
            </Field>
          );
        },
      });
    }

    return cols;
  }, []);

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        onRowClicked={handleRowClicked}
        withSelectableRows={!!handleSelectedInvoicesUpdated}
        handleSelectedUpdated={handleSelectedInvoicesUpdated}
        renderBesideSearch={renderBesideSearch}
        tableId="invoicestable"
        forceInitialPageSize={50}
        withPersistantSortBy
        withPersistantGlobalFilter
        clearExternalFilters={() => {
          updateFilter(null);
          setHasPersistantFilter(false);
        }}
        hasPersistantExternalFilter={hasPersistantFilter}
        externalFilters={
          noFilters
            ? null
            : () => (
                <InvoiceTableFilters
                  updateFilter={updateFilter}
                  selectedFilter={selectedFilter}
                  filterCounts={filterCounts}
                />
              )
        }
        handleSelectedHeader={() => (
          <>
            {!noActions && (
              <SC.HandleSelectedContainer>
                {selectedInvoices?.length > 0 ? (
                  <div className="flex flex-col">
                    <BodyText>
                      Hantera <strong>{selectedInvoices?.length} </strong>
                      {selectedInvoices?.length > 1
                        ? "valda fakturor"
                        : "vald faktura"}
                    </BodyText>

                    {mergeActionDisabled && (
                      <div className="text-xs text-red-500">
                        Kan ej sammanföra dessa fakturor då de är utställda till
                        olika hyresgäster eller är attesterade.
                      </div>
                    )}
                    {attestSelectedDisabled && (
                      <div className="text-xs text-red-500">
                        {selectedInvoices?.length <= 50
                          ? "Kan ej attestera dessa fakturor då de redan är attesterade eller har ett fakturadatum i framtiden."
                          : 'Kan ej attestera mer än 50 fakturor i taget. Använd funktionen "Attestera alla fakturor" istället.'}
                      </div>
                    )}
                  </div>
                ) : (
                  <BodyText>Markera fakturor för att masshantera</BodyText>
                )}

                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PrimaryBtn
                    secondary
                    disabled={
                      mergeActionDisabled || selectedInvoices?.length < 2
                    }
                    className="ml-2"
                    onClick={handleMergeSelectedInvoices}
                  >
                    Sammanför {selectedInvoices?.length || ""}
                  </PrimaryBtn>

                  <PrimaryBtn
                    disabled={
                      attestSelectedDisabled || !selectedInvoices?.length
                    }
                    className="ml-2"
                    onClick={handleAttestSelectedInvoices}
                  >
                    Attestera {selectedInvoices?.length || ""}
                  </PrimaryBtn>
                </div>
              </SC.HandleSelectedContainer>
            )}
          </>
        )}
      ></BasicTable>
    </>
  );
}
