import * as React from "react";

import { FormAreaTitle } from "../../../Base/Chapters/styles";

import { constants } from "../../../../../store/invoicingSettings";

import projectListDefs from "../../../../Tables/InvoicingProjects/listDefs";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Bokföringsinställningar</FormAreaTitle>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey="project"
          method={method}
          title="Standardprojekt för kostnader på avier under bolaget"
          placeholder="Välj standardprojekt"
          description="Om inget annat projekt specifikt väljs på en kostnad så kommer kostnaden automatiskt bokföras under detta projekt"
          TableComponent={InvoicingProjectsTable}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey="cost_center"
          method={method}
          title="Standard kostnadsställe för kostnader på avier under bolaget"
          placeholder="Välj kostnadsställe..."
          description="Om inget annat kostnadsställe specifikt väljs på en kostnad så kommer kostnaden automatiskt bokföras under detta kostnadsställe"
          TableComponent={InvoicingCostCenterTable}
        />
      </div>
    </>
  );
};
