export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INVOICE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INVOICE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INVOICE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INVOICE",
  DESTROY_FORM: "DESTROY_FORM_INVOICE",
  SET_FORM_ERROR: "SET_FORM_ERROR_INVOICE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INVOICE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INVOICE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INVOICE",
  RESET_STATE: "RESET_STATE_INVOICE",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_INVOICE",

  CONTRACT_LIST_URL: "/accounting/contract_invoices/",
  CONTRACT_GET_URL: "/accounting/contract_invoice/",
  INVOICE_LIST_URL: "/accounting/invoices/open/",
  INVOICE_LIST_URL_CLOSED: "/accounting/invoices/closed/",
  INVOICE_GET_URL: "/accounting/invoice/",
  MANUAL_INVOICE_PROPOSAL_URL: "/accounting/propose/debtor/",
  ENSURE_DEBTOR_IDS_URL:
    "/accounting/debtor/debtor_invoice_config/ensured_ids/",
  ENSURE_PRODUCT_IDS_URL: "/accounting/costs/product/ensured_ids/",
  ENSURE_PROJECT_IDS_URL: "/accounting/costs/project/ensured_ids/",
  ENSURE_COSTCENTER_IDS_URL: "/accounting/costs/costcenter/ensured_ids/",

  STORE_NAME: "billectaInvoicing",

  SET_ALL_INVOICES: "SET_ALL_INVOICES",
  SET_PAYMENTS: "SET_PAYMENTS",
  SET_CURRENT_INVOICE: "SET_CURRENT_INVOICE",
  SET_CURRENT_REMINDER_INVOICE: "SET_CURRENT_REMINDER_INVOICE",
  SET_CURRENT_DEBT_INVOICE_INFO: "SET_CURRENT_DEBT_INVOICE_INFO",
  SET_CURRENT_INVOICE_PREVIEW_PDF: "SET_CURRENT_INVOICE_PREVIEW_PDF",
  CLEAR_CURRENT_INVOICE_PREVIEW_PDF: "CLEAR_CURRENT_INVOICE_PREVIEW_PDF",

  SET_DEBTOR_INVOICE_SEARCH_RESULT:
    "SET_DEBTOR_INVOICE_SEARCH_RESULT_BILLETAINVOICING",
  SET_CONNECTED_PAYMENT_DATE_FROM:
    "SET_CONNECTED_PAYMENT_DATE_FROM_BILLECTAINVOICING",

  SET_CLOSED_FROM_DATE: "SET_CLOSED_FROM_DATE_BILLECTAINVOICING",

  SET_ATTEST_INVOICES_LOADING: "SET_ATTEST_INVOICES_LOADING",
  SET_DELETE_INVOICES_LOADING: "SET_DELETE_INVOICES_LOADING",
};
