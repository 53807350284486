import * as React from "react";
import moment from "moment";
import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";

const FORMAT = "YYYY-MM-DD HH:mm";

const DATE_SELECTION_TYPE_MAP = {
  EventDate: "Händelsedatum",
  TransactionDate: "Transaktionsdatum",
};

export default ({ reports, handleRowClicked }) => {
  const data = React.useMemo(() => {
    return reports;
  }, [reports]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Filnamn",
        accessor: "File",
        Cell: (props) => {
          return <Field>{props.value?.FileName}</Field>;
        },
      },
      {
        Header: "Format",
        accessor: "Format",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Skapad",
        accessor: "Created",
        Cell: (props) => {
          return <Field>{moment(props.value).format(FORMAT)}</Field>;
        },
      },
      {
        Header: "Från",
        accessor: "Start",
        Cell: (props) => {
          return <Field>{moment(props.value).format(FORMAT)}</Field>;
        },
      },
      {
        Header: "Till",
        accessor: "End",
        Cell: (props) => {
          return <Field>{moment(props.value).format(FORMAT)}</Field>;
        },
      },
      {
        Header: "Perioden baseras på",
        accessor: "DateSelectionType",
        Cell: (props) => {
          return <Field>{DATE_SELECTION_TYPE_MAP[props.value]}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        onRowClicked={handleRowClicked}
        tableId="accountingreportstable"
        withPersistantGlobalFilter
        withPersistantSortBy
      />
    </>
  );
};
