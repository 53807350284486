import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { RadioGroup, TextField, TextInput } from "../../../Base/Fields";
import ManyMediaField from "../../../Base/Fields/ManyMediaField";

export default function ChecklistRow({ method, storeName }) {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för rad</OverviewTitle>
          <OverviewSubtitle>
            Ange titel, beskrivning och inställningar för rad
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />
      <TextInput
        {...{
          storeName,
          method,
          fieldKey: "title",
          title: "Titel på rad",
        }}
      />

      <TextField
        {...{
          storeName,
          method,
          fieldKey: "description",
          title: "Beskrivning",
        }}
      />

      <RadioGroup
        title={"Gäller för självavhjälpande"}
        options={[
          {
            label: "Ja, denna rad ska visas för hyresgäst för självavhjälpande",
            value: false,
          },
          {
            label:
              "Nej, denna rad ska ej visas för hyresgäst för självavhjälpande",
            value: true,
          },
        ]}
        defaultValue={true}
        storeName={storeName}
        fieldKey={`hide_for_tenants`}
        method={method}
      />
      <RadioGroup
        title={"Gäller för utförande"}
        options={[
          {
            label: "Ja, denna rad ska visas vid utförande av ärende",
            value: false,
          },
          {
            label: "Nej, denna rad ska ej visas vid utförande av ärende",
            value: true,
          },
        ]}
        defaultValue={false}
        storeName={storeName}
        fieldKey={`hide_for_non_tenants`}
        method={method}
      />

      <ManyMediaField
        {...{
          storeName,
          method,
          fieldKey: "files",
          title: "Filer som visas på raden",
          description:
            "Kan vara en video, bilder eller en pdf som förklarar hur besiktning eller självavhjälpande ska utföras/åtgärdas",
        }}
      />
    </>
  );
}
