export default [
  {
    title: "Inkomst & Arbete",
    key: "INCOME",
    visited: true,
    hasError: false,
    fieldKeys: [
      "name",
      "rent_calculation_type",
      "name",
      "rent_factor",
      "income_variable",
      "income_fixed",
      "living_standard_factor",
      "warrantor",
      "warrantor_rent_factor",
      "rent_factor",
      "income_student",
      "income_housing_support",
      "income_housing_supplement",
      "income_living_expense_supplement",
      "employment_temporary",
      "employment_permanent",
    ],
  },
  {
    title: "Speciallägenhet",
    key: "SPECIAL",
    visited: false,
    hasError: false,
    fieldKeys: [
      "senior",
      "senior_age",
      "youth",
      "youth_age",
      "student",
      "benefit_apartment",
    ],
  },
  {
    title: "Kredit",
    key: "CREDIT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "payment_remarks",
      "outstanding_debt",
      "debt_sanitization",
      "bankruptcy",
    ],
  },
  {
    title: "Övrigt",
    key: "OTHER",
    visited: false,
    hasError: false,
    fieldKeys: ["move_frequency", "extra_residents", "smoking", "pets"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
