import * as React from "react";
import { useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, LinkedObject } from "../../../components/Displays";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { durationParse } from "../../../components/Displays/utils";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";

import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  useEditabledoc,
} from "../../../store/editabledocs";
import { detailUrl as leaseDetailUrl } from "../../../store/leaseContracts";
import { useFilteredNotes } from "../../../store/notes";
import {
  useParkingContract,
  update as updateContract,
  constants as parkingConstants,
} from "../../../store/parkingContracts";
import UpdateParkingContractTenantModalForm from "../../../components/Forms/ParkingContract/EditContractTenantForm/ModalForm";
import UpdateParkingContractParkingSpotsModalForm from "../../../components/Forms/ParkingContract/EditContractParkingSpots/ModalForm";
import UpdateParkingContractModalForm from "../../../components/Forms/ParkingContract/EditContractForm/ModalForm";
import { getOverviewPageCostsDetail } from "../../LeaseContract/Detail/Overview";
import { useDispatch } from "react-redux";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useFilteredParkingSpots } from "../../../store/parkingSpots";
import { useParkingInvoicing } from "../../../store/invoicingParking";
import DigitalSign from "../../../components/Signing/DigitalSign";
import ManualSign from "../../../components/Signing/ManualSign";
import DigitalCancellation from "../../../components/Signing/DigitalCancellation";
import ManualCancellation from "../../../components/Signing/ManualCancellation";
import SmallTenantSummary from "../../../components/SummaryCards/Tenant/Small";
import SmallParkingSpotSummary from "../../../components/SummaryCards/ParkingSpot/Small";
import { getContractFromAndTo } from "../../../components/Lists/utils/costCalculations";
import SmallCompanySummary from "../../../components/SummaryCards/Company/Small";
import UpdateParkingContractLandlordModalForm from "../../../components/Forms/ParkingContract/EditContractLandlordForm/ModalForm";

export default function ParkingContractOverview() {
  const dispatch = useDispatch();
  const { parkingContractId } = useParams();
  const [contract] = useParkingContract(parkingContractId);
  const [invoicingObj] = useParkingInvoicing(contract?.parking_invoicing?.id);
  const [editableDoc] = useEditabledoc(contract?.editabledoc?.id);
  const [cancelledDoc] = useEditabledoc(contract?.cancelled_doc?.id);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editTenantOpen, setEditTenantOpen] = React.useState(false);
  const [editLandlordOpen, setEditLandlordOpen] = React.useState(false);
  const [editParkingSpotsOpen, setEditParkingSpotsOpen] = React.useState(false);
  const [draftLoading, setDraftLoading] = React.useState(false);

  const canEdit = usePermissionCheck("change_can_contract");
  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const showSigningDetails = !contract?.is_signed;
  const isDigitalSign = contract?.uses_e_signing;
  const isDraft = contract?.draft;

  const isCancelled = contract?.closed_date || contract?.closed_renew_date;
  const isDigitalCancelSign = contract?.uses_cancellation_e_signing;

  const notesQ = buildQueryString({
    id__in: contract?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const parkingSpotQuery = buildQueryString({
    id__in: contract?.parking_spots?.map((ap) => ap.id) || [],
  });
  const [parkingSpots] = useFilteredParkingSpots(parkingSpotQuery);

  const combinedCosts = [
    ...(invoicingObj?.cost_set || []),
    ...(parkingSpots?.map((p) => p.cost_set)?.flat() || []),
  ];

  const convertDraftToReal = () => {
    setDraftLoading(true);

    dispatch(
      updateContract({
        id: parkingContractId,
        forceData: {
          draft: false,
        },
        successCallback: () => {
          setDraftLoading(false);
        },
      })
    );
  };

  return (
    <>
      {editOpen && (
        <UpdateParkingContractModalForm
          instance={contract}
          onCheckout={() => setEditOpen(false)}
          id={parkingContractId}
        />
      )}

      {editTenantOpen && (
        <UpdateParkingContractTenantModalForm
          instance={contract}
          onCheckout={() => setEditTenantOpen(false)}
          id={parkingContractId}
        />
      )}

      {editLandlordOpen && (
        <UpdateParkingContractLandlordModalForm
          instance={contract}
          onCheckout={() => setEditLandlordOpen(false)}
          id={parkingContractId}
        />
      )}

      {editParkingSpotsOpen && (
        <UpdateParkingContractParkingSpotsModalForm
          instance={contract}
          onCheckout={() => setEditParkingSpotsOpen(false)}
          id={parkingContractId}
        />
      )}

      <DetailInnerWrapper>
        {isDraft && (
          <DetailPageBox>
            {draftLoading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Detta avtal är ett utkast</OverviewTitle>

                <OverviewSubtitle>
                  Tryck på "Konvertera från utkast till avtal" om avtalet är
                  redo att användas.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Konvertera från utkast till avtal"
              clicked={convertDraftToReal}
              iconType="done"
              iconPlacement="right"
            />
          </DetailPageBox>
        )}

        {contract && showSigningDetails && !isDraft && (
          <>
            {isDigitalSign ? (
              <DigitalSign
                {...{
                  contract,
                  editableDoc,
                  constants: parkingConstants,
                  update: updateContract,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT,
                }}
              />
            ) : (
              <ManualSign
                {...{
                  contract,
                  constants: parkingConstants,
                  update: updateContract,
                }}
              />
            )}
          </>
        )}

        {contract && isCancelled && (
          <>
            {isDigitalCancelSign ? (
              <DigitalCancellation
                {...{
                  contract,
                  cancelledDoc,
                  constants: parkingConstants,
                  update: updateContract,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT,
                }}
              />
            ) : (
              <ManualCancellation
                {...{
                  contract,
                  cancelledDoc,
                  contractType: EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT,
                  update: updateContract,
                  constants: parkingConstants,
                }}
              />
            )}
          </>
        )}

        <DetailPageBoxFlexWrapper>
          <div style={{ width: "65%", marginRight: 12 }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>
                    Översikt över {contract?.id_number}
                  </OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera avtalsdetaljer"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {contract && (
                <DetailInfo
                  infoObj={getInfoObj({
                    contract,
                    combinedCosts,
                    avyTmplActive,
                  })}
                />
              )}
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Fordonsplatser</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera hyresobjekt"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditParkingSpotsOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <div
                className={`grid grid-cols-${
                  contract?.parking_spots?.length > 1 ? "2" : "1"
                } gap-3`}
              >
                {contract?.parking_spots?.map((p) => (
                  <SmallParkingSpotSummary key={p.id} id={p.id} />
                ))}
              </div>
            </DetailPageBox>
          </div>

          <div style={{ width: "35%" }}>
            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hyresgäst</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera hyresgäst"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditTenantOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <div className="grid grid-cols-1 gap-6">
                <SmallTenantSummary id={contract?.tenant?.id} />
              </div>
            </DetailPageBox>
            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hyresvärd</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera hyresvärd"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditLandlordOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <div className="grid grid-cols-1 gap-6">
                <SmallCompanySummary id={contract?.landlord?.id} />
              </div>
            </DetailPageBox>

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <Notes
                notes={notes}
                contentType="standard.parkingcontract"
                objectId={parkingContractId}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ contract, combinedCosts, avyTmplActive }) {
  const { contractFrom, contractTo } = getContractFromAndTo(contract);

  const infoObj = {
    Avtalsdetaljer: [
      {
        title: "Avtalsstatus",
        value: (
          <StatusLabel state={contract?.state}>
            {contract?.state_display}
          </StatusLabel>
        ),
      },
      { title: "Avtals-ID", value: contract?.id_number },
      {
        title: "Huvudavtal",
        value: (
          <LinkedObject
            obj={contract?.main_contract}
            urlMethod={leaseDetailUrl}
          />
        ),
        hidden: !contract?.main_contract?.id,
      },
      {
        title: "Fastighet",
        value: contract?.realestate?.str_representation,
      },
      { title: "Startdatum", value: <DateCell date={contract?.start_date} /> },
      { title: "Slutdatum", value: <DateCell date={contract?.end_date} /> },
      {
        title: "Förlängt till",
        value: <DateCell date={contract?.renewed_to} />,
        hidden: !contract?.renewed_to,
      },
      {
        title: "Uppsägningstid",
        value: contract?.notify_interval
          ? durationParse(contract?.notify_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Förlängningstid",
        value: contract?.renew_interval
          ? durationParse(contract?.renew_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Varningstid",
        value: contract?.warning_interval
          ? durationParse(contract?.warning_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Signerades",
        value: <DateCell date={contract?.signed_date} />,
      },
    ],

    "Övrig information": [
      {
        title: "Faktureras nästa period",
        value: `${toMoneyString(contract?.next_period_value)} SEK`,
        hidden: !contract?.parking_invoicing?.id,
      },
      {
        title: "Synkad mot Avy-Tmpl",
        value: (
          <StatusLabel state={contract?.synced_with_avytmpl ? 0 : 3}>
            {contract?.synced_with_avytmpl ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !avyTmplActive,
      },
    ],

    [`Intäkter (${contractFrom.format("YYYY-MM")} - ${contractTo.format(
      "YYYY-MM"
    )})`]: contract ? getOverviewPageCostsDetail(contract, combinedCosts) : [],
  };

  return infoObj;
}
