import constants from "./constants"
import { getAllFetchProcessName, getSingleFetchProcessName, getFiltered, get, options, getPagination, destroyForm, post, patch} from "../../base"

export const getAll = () => {
    return get({
        url: constants.LIST_URL,
        constants,
        name: getAllFetchProcessName()
    });
};

export const getSingle = (id) => {
    const url = `${constants.GET_URL}${id}`;
    return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  
  return options({ url, constants, method: "PATCH"});
};

export const getPostForm = (id) => {
  return options({ url: constants.POST_URL, constants, method: "POST"});
}

export const destroyPatchForm = (success) => {
  return destroyForm({constants, method: "PATCH", success});
};

export const destroyPostForm = (success) => {
  return destroyForm({constants, method: "POST", success});
};

export const create = ({id, processSuccess, processError, successCallback, errorCallback}) => {
  return post({url:`${constants.POST_URL}`, constants, processSuccess, processError, successCallback, errorCallback});
}

export const update = ({id, processSuccess, processError, successCallback, errorCallback}) => {
  return patch({url:`${constants.PATCH_URL}${id}`, constants, processSuccess, processError, successCallback, errorCallback});
}

export const performFilter = (
  querystring,
  callback,
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};