export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INDUSTRIALPREMISESCANCELLATIONS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INDUSTRIALPREMISESCANCELLATIONS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INDUSTRIALPREMISESCANCELLATIONS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INDUSTRIALPREMISESCANCELLATIONS",
  DESTROY_FORM: "DESTROY_FORM_INDUSTRIALPREMISESCANCELLATIONS",
  SET_FORM_ERROR: "SET_FORM_ERROR_INDUSTRIALPREMISESCANCELLATIONS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INDUSTRIALPREMISESCANCELLATIONS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INDUSTRIALPREMISESCANCELLATIONS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INDUSTRIALPREMISESCANCELLATIONS",
  RESET_STATE: "RESET_STATE_INDUSTRIALPREMISESCANCELLATIONS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INDUSTRIALPREMISESCANCELLATIONS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIALPREMISESCANCELLATIONS",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_INDUSTRIALPREMISESCANCELLATIONS",
  CLEAR_FETCHED: "CLEAR_FETCHED_INDUSTRIALPREMISESCANCELLATIONS",

  LIST_URL: "/standard/mainlevel/premises/cancellations/industrialpremises/",

  STORE_NAME: "industrialPremisesCancellations",
};
