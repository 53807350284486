import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { payload, type } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_BILLECTA_SEARCH_RESULT: {
      return {
        ...newState,
        [payload.name]: payload.result,
      };
    }

    default:
      return newState;
  }
};
