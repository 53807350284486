import { cloneDeep } from "lodash";
import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";
import constants from "./constants";

const STATE = {
  ...INITIAL_STATE,

  filteredRealEstateIds: null,
  filteredCompanyIds: null,
  filteredBrfCompanyIds: null,
  filteredPortfolioIds: null,
};

export default (state = STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.RESET_STATE:
      return STATE;

    case constants.SET_ACTIVE_FILTERED:
      return handleFilterChanged(state, payload);

    case constants.CLEAR_TODO_DATA: {
      const stateClone = cloneDeep(state);

      delete stateClone.all._todoContractStats;
      delete stateClone.all._todoPremisesStats;
      delete stateClone.all._todoImdStats;
      delete stateClone.all._todoTenantStats;
      delete stateClone.all._todoUserStats;
      delete stateClone.all._todoErrandStats;
      delete stateClone.all._todoYourBlockStats;
      return stateClone;
    }

    default:
      return baseReducer(state, action, constants);
  }
};

const handleFilterChanged = (state, payload) => {
  const { realEstateIds, companyIds, brfCompanyIds, portfolioIds } = payload;

  return {
    ...state,
    filteredRealEstateIds: realEstateIds,
    filteredCompanyIds: companyIds,
    filteredBrfCompanyIds: brfCompanyIds,
    filteredPortfolioIds: portfolioIds,
  };
};
