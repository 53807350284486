import {
    get,
    options,
    destroyForm,
    patch
} from "../../base";
import constants from "./constants";

const preProcessSingleSetting = (data) => {
    data.id = constants.SETTING_STATE_ID;
    return data;
}

export const getSingle = () => {
    return get({url:constants.GET_URL, constants, name:constants.SETTING_STATE_ID, preProcess:preProcessSingleSetting})
}

export const getPatchForm = () => {
    return options({url:constants.PATCH_URL, constants, method:"PATCH"});
}

export const destroyPatchForm = (success) => {
    return destroyForm({constants, method:"PATCH", success});
}

export const update = ({processSuccess, processError, successCallback, errorCallback}) => {
    return patch({url:constants.PATCH_URL, constants, processSuccess, processError, successCallback, errorCallback});
}