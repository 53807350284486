import * as React from "react";

export default () => {
  return (
    <div>
      Kontrollera att uppgifterna stämmer. Tryck sedan på "Fortsätt" för att
      spara och gå vidare till granskning.
    </div>
  );
};
