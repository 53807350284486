export default {
  SET_CREDENTIALS_IN_PROGRESS: "SET_CREDENTIALS_IN_PROGRESS_SBF",
  SET_CREDENTIALS: "SET_CREDENTIALS_SBF",
  SET_CREDENTIAL: "SET_CREDENTIAL_SBF",
  UPDATE_CREDENTIAL: "UPDATE_CREDENTIAL_SBF",
  REMOVE_CREDENTIAL: "REMOVE_CREDENTIAL_SBF",
  SET_SBF_REQUIREMENTS: "SET_SBF_REQUIREMENTS",
  SET_SBF_REQUIREMENTS_INPROGRESS: "SET_SBF_REQUIREMENTS_INPROGRESS",
  SET_SBF_REQUIREMENT_CONNECTIONS: "SET_SBF_REQUIREMENT_CONNECTIONS",
  SET_SBF_REQUIREMENT_CONNECTIONS_INPROGRESS:
    "SET_SBF_REQUIREMENT_CONNECTIONS_INPROGRESS",

  REQUIREMENT_MATCH: "/market/sbf/sbfrequirementconnection/",
  REQUIREMENT_GET: "/market/sbf/sbfrequirementlookup/",
  REQUIREMENT_CONNECTIONS: "/market/sbf/sbfcompanyrequirementmapping/list/",
  CREDENTIAL_LIST: "/market/sbf/sbfcredential/list/",
  CREATE_CREDENTIAL: "/market/sbf/sbfcredential/", // config of auth
  HANDLE_CREDENTIAL: "/market/sbf/sbfcredential/", // handle credentials

  STORE_NAME: "stockholmsBF",
};
