import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import PlainForm from "../../../components/Forms/ConfigCenter/General/Sidebar/PlainForm";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import PageTitle from "../../Layouts/PageTitle/PageTitle";

export default () => {
  const breadCrumbs = [
    {
      label: "Sidomeny",
      url: "/configcenter/general/sidebar",
    },
    {
      label: "Redigera",
    },
  ];

  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle title="Sidomeny" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Sidomeny</OverviewTitle>
            <OverviewSubtitle>
              Tryck på "Uppdatera" för att spara ändringar
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

          <PlainForm />
      </DetailPageBox>
    </BareLayoutWrapper>
  );
};
