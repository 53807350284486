import axios from "axios";
import { axiosInstance } from "../base/store/axios";

import { FEEDBACK_URL } from "../..";
import { getToken } from "../../utils";

export async function getUser(userId) {
  try {
    const { data: user } = await axiosInstance.get(
      `/accounts/users/user/${userId}/`
    );
    return user;
  } catch (e) {
    console.error("Could not fetch user");
  }
}

export async function sendFeedback(data) {
  try {
    const res = await axios.post(FEEDBACK_URL, data, {
      headers: {
        Authorization: `Token ${getToken().token}`,
        "Content-Type": "application/json",
      },
    });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
