import { clearFetched, insertIntoAll } from "../../base";
import { constants as tenantConstants } from "../../tenants";

const handleUpdateConnectedStateOnCreate = ({
  sentData,
  receivedData,
  dispatch,
}) => {
  if (!receivedData) return;

  if (!sentData.tenant?.id && receivedData.tenant) {
    dispatch(insertIntoAll(tenantConstants, receivedData.tenant));
    dispatch(clearFetched(tenantConstants));
  }
};

export { handleUpdateConnectedStateOnCreate };
