import * as React from "react";
import * as SC from "./styles";
import Ad from "./Ad";
import { TextButton } from "../../Forms/Base/Buttons";
import {
  acceptLeads,
  getAdTypeForRanking,
  LEAD_RANKING_TYPES,
} from "../../../store/leads";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  costProposalUrl,
  pickTemplateUrl,
  prepareForContractUrl,
  setLeadsForContract,
} from "../../../store/pipes";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import MenuButton from "../../Forms/Base/Buttons/MenuButton";
import ConfirmLeadModal from "./ConfirmLeadModal";
import StandardModal from "../../Modals/StandardModal";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";

export default function AdStage({
  isSignStage,
  stage,
  color,
  ads,
  onLeadDetailOpened,
  onAdDetailOpened,
  isNoInterestStage,
  apartmentLeadRankings,
  parkingLeadRankings,
  indpLeadRankings,
  onCreateLead,
  isHomeQPipe,
  updateLeadsInstance,
}) {
  const dispatch = useDispatch();
  const leadContainerRef = React.useRef();
  const [confirmLeadsOpen, setConfirmLeadsOpen] = React.useState(false);
  const [confirmLeadsLoading, setConfirmLeadsLoading] = React.useState(false);
  const [chooseCostsTypeOpen, setChooseCostsTypeOpen] = React.useState(false);
  const [chooseDocsTypeOpen, setChooseDocsTypeOpen] = React.useState(false);
  const { push } = useHistory();

  const leads = React.useMemo(() => {
    return ads?.map((a) => a.leads || [])?.flat();
  }, [ads]);

  const leadIds = React.useMemo(() => {
    return leads?.map((l) => l.id);
  }, [leads]);

  const allLeadsConfirmed = React.useMemo(() => {
    return ads.every((ad) => {
      const leadsForAd = ad.leads;

      return (leadsForAd || []).some((l) => l.confirmed);
    });
  }, [ads]);

  const getTopLeadForAd = (ad) => {
    const rankingType = getAdTypeForRanking(ad);

    const rankings =
      rankingType === LEAD_RANKING_TYPES.APARTMENT
        ? apartmentLeadRankings
        : rankingType === LEAD_RANKING_TYPES.INDP
        ? indpLeadRankings
        : parkingLeadRankings;

    const leadsForObject = rankings?.[
      ad.apartment?.id || ad.industrial_premises?.id || ad.parking_spot?.id
    ]?.filter((l) => leadIds.includes(l.id));

    const topLead = leadsForObject?.[0];

    return topLead;
  };

  const handlePrepareLeadsForContract = () => {
    let topLeads = [];

    ads.forEach((ad) => {
      const topLead = getTopLeadForAd(ad);

      if (topLead) {
        topLeads.push(topLead);
      }
    });

    const signLeads = topLeads
      .map((tl) => {
        const lead = leads.find((l) => l.id === tl.id);

        return lead;
      })
      ?.filter((o) => o);

    if (signLeads?.length > 0) {
      dispatch(setLeadsForContract({ leads: signLeads }));

      push(prepareForContractUrl());
    } else {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Inga leads att signera med kunde hittas",
          description: "Kontrollera att alla objekten har en topplead",
        })
      );
    }
  };

  const handleAcceptLeads = ({
    acceptType,
    homeQRejectionReason,
    sbfRejectionReason,
    prioOrder,
    acceptedLeads,
  }) => {
    setConfirmLeadsLoading(true);
    dispatch(
      acceptLeads({
        ids: acceptedLeads?.map((l) => l.id) || leads.map((l) => l.id),
        acceptType,
        homeQRejectionReason,
        sbfRejectionReason,
        prioOrder,
        successCallback: () => {
          setConfirmLeadsLoading(false);
          setConfirmLeadsOpen(false);
          updateLeadsInstance();

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Leads bekräftades",
              description: "Avtal kan nu sättas upp för leads i detta steg",
            })
          );
        },
        errorCallback: (message) => {
          setConfirmLeadsLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  return (
    <>
      {isSignStage && (
        <StandardModal
          small
          isOpen={chooseCostsTypeOpen}
          closeFunction={() => setChooseCostsTypeOpen(false)}
          title="Välj typ av avtal"
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj typ av avtal att sätta upp kostnader för
              </OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <div>
            <TextButton
              title="Hyresavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => push(costProposalUrl({ type: "leasecontracts" }))}
            />
          </div>
          <div style={{ marginTop: 8 }}>
            <TextButton
              title="Parkeringsavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() =>
                push(costProposalUrl({ type: "parkingcontracts" }))
              }
            />
          </div>
        </StandardModal>
      )}

      {isSignStage && (
        <StandardModal
          small
          isOpen={chooseDocsTypeOpen}
          closeFunction={() => setChooseDocsTypeOpen(false)}
          title="Välj typ av avtal"
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj typ av avtal att sätta upp signeringsdokument för
              </OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <div>
            <TextButton
              title="Hyresavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => push(pickTemplateUrl({ type: "leasecontracts" }))}
            />
          </div>
          <div style={{ marginTop: 8 }}>
            <TextButton
              title="Parkeringsavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() =>
                push(pickTemplateUrl({ type: "parkingcontracts" }))
              }
            />
          </div>
        </StandardModal>
      )}

      {isSignStage && (
        <ConfirmLeadModal
          isOpen={confirmLeadsOpen}
          closeFunction={() => setConfirmLeadsOpen(false)}
          callback={handleAcceptLeads}
          loading={confirmLeadsLoading}
          leads={leads}
          isHomeQPipe={isHomeQPipe}
          openLeadCallback={(l) => {
            onLeadDetailOpened(l.id);
          }}
        />
      )}

      <SC.Stage>
        <SC.StageHeader color={color}>
          <>
            <SC.StageTitle>
              <div>
                {stage.name} ({ads?.length || 0})
              </div>

              <MenuButton
                actions={[
                  {
                    name: "Bekräfta leads",
                    onClick: () => setConfirmLeadsOpen(true),
                    hidden: !(isSignStage && !allLeadsConfirmed),
                  },
                  {
                    name: "Sätt upp avtal",
                    onClick: handlePrepareLeadsForContract,
                    hidden: !isSignStage || !allLeadsConfirmed,
                  },
                  {
                    name: "Gå direkt till kostnadsförslag",
                    onClick: () => setChooseCostsTypeOpen(true),
                    hidden: !isSignStage,
                  },
                  {
                    name: "Gå direkt till förslag på signerings-dokument",
                    onClick: () => setChooseDocsTypeOpen(true),

                    hidden: !isSignStage,
                  },
                ]}
              />
            </SC.StageTitle>

            {isSignStage && allLeadsConfirmed && (
              <>
                <hr style={{ margin: "8px 0" }} />
                <TextButton
                  title="Sätt upp avtal"
                  iconType="add"
                  iconPlacement="right"
                  clicked={handlePrepareLeadsForContract}
                />
              </>
            )}
            {isSignStage && !allLeadsConfirmed && (
              <>
                <hr style={{ margin: "8px 0" }} />
                <TextButton
                  title="Bekräfta toppleads"
                  iconType="done"
                  iconPlacement="right"
                  clicked={() => setConfirmLeadsOpen(true)}
                />
              </>
            )}
          </>
        </SC.StageHeader>

        <SC.LeadContainer
          style={{ backgroundColor: "rgba(0,0,0,0.02)" }}
          ref={leadContainerRef}
        >
          <div style={{ padding: 4 }}>
            {(ads || []).map((ad, index) => (
              <Ad
                {...{ isNoInterestStage }}
                key={ad.id}
                ad={ad}
                topLeadObj={getTopLeadForAd(ad)}
                onClick={() => onAdDetailOpened(ad.id)}
                onLeadClicked={(leadId) => onLeadDetailOpened(leadId)}
                onCreateLead={onCreateLead}
                isHomeQPipe={isHomeQPipe}
              />
            ))}
            <div
              style={{
                minHeight: 200,
                width: "100%",
              }}
            ></div>
          </div>
        </SC.LeadContainer>
      </SC.Stage>
    </>
  );
}
