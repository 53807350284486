import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IntegrationCompaniesWrapper } from "../../../components/Layout/Accounts/styles";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";

import { buildQueryString, useFrequentPermissions } from "../../../store/base";

import CompanyTable from "../../../components/Tables/ConfigCenter/Companies/FullTable";
import companyListDefs from "../../../components/Tables/ConfigCenter/Companies/listDefs";

import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import StandardModal from "../../../components/Modals/StandardModal";
// import Table from "../../../components/Lists/Base/CompleteList/Table";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import AutogiroIntegrationForm from "../../../components/Billecta/Integrations/AutogiroIntegrationForm";

import {
  constants as companyConstants,
  useCompanyPagination,
  useFilteredCompanies,
  performFilter as performCompanyFilter,
} from "../../../store/companies";
import { bulkGetIntegrationSettings } from "../../../store/billectaIntegrations/store/actions";

import INTEGRATIONS from "../integrationsList";

import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

export default function AutogiroIntegration() {
  const current = INTEGRATIONS.find((i) => i.title === "Bankgirots Autogiro");
  const [pickCompaniesOpen, setPickCompaniesOpen] = React.useState(false);
  const [autogiroOpen, setAutogiroOpen] = React.useState(false);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [addCompaniesLoading, setAddCompaniesLoading] = React.useState(false);

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const integrationName = "Autogiro";

  const dispatch = useDispatch();
  const [companies, companiesLoading] = useFilteredCompanies(
    buildQueryString({ billecta_id__isnull: false })
  );

  const withCompanyInvoicingQuery = {
    billecta_id__isnull: false,
  };

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  //TODO: FinalBaseTable: wtf does this do?
  // const checkRowHighlighted = (row) => {
  //   const { original } = row;
  //   const match = selectedCompanies?.find((s) => s.id === original.id);

  //   return match;
  // };

  const onCompanySelected = (original) => {
    // const { original } = row;
    setSelectedCompany(original);
    setPickCompaniesOpen(false);
    setAutogiroOpen(true);
  };

  const autogiroSettings = useSelector(
    (state) => state.billectaIntegrations.autogiroSettings
  );

  const companyColumns = React.useMemo(
    () =>
      companyListDefs(
        hasBillectaViewPermission,
        autogiroSettings,
        integrationName
      ),
    [withCompanyInvoicingQuery, hasBillectaViewPermission, autogiroSettings]
  );

  React.useEffect(() => {
    if (companies != null && !companiesLoading) {
      dispatch(
        bulkGetIntegrationSettings({
          companies,
          integrationSetting: "autogiroSettings",
        })
      );
    }
  }, [companiesLoading]);

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper config={true}>
            <DetailPageBox
              style={{ flex: 1, width: "100%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Bankgirots hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Autogiro-integrationen tillåter aviserande bolag att sätta upp
                  automatisk debitering mot fakturamottagare via Pigellos
                  hyresaviseringsmodul.
                  <br />
                  <br />
                  Integrationen aktiveras under bolagsinställningarna på
                  respektive bolag
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <IntegrationCompaniesWrapper>
              <DetailPageBox
                style={{ flex: 1, marginRight: "5px", alignSelf: "flex-start" }}
              >
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Hantera integration</OverviewTitle>
                    <OverviewSubtitle>
                      Välj vilket bolag integrationen ska hanteras för
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                  <PrimaryButton
                    extraStyle={{ width: "30%", alignSelf: "end" }}
                    newDesign
                    clicked={() => setPickCompaniesOpen(true)}
                    title="Hantera"
                  />
                </OverviewTitleWrapper>

                <CompanyTable
                  {...{
                    persistantQueryString: withCompanyInvoicingQuery,
                    isBare: true,
                    ignoreLocalStorage: true,
                    integrationSettings: autogiroSettings,
                    integrationName: integrationName,
                  }}
                />
              </DetailPageBox>
            </IntegrationCompaniesWrapper>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
        {/* Opens on clicking "Hantera" on Company Table, pick company to manage integration */}
        <StandardModal
          isOpen={pickCompaniesOpen}
          closeFunction={() => setPickCompaniesOpen(false)}
          withActionBar
          title="Hantera integration för Bolag"
        >
          {addCompaniesLoading && <OverlaySpinner />}
          <BaseTable
            constants={companyConstants}
            columns={companyColumns}
            persistantQuery={withCompanyInvoicingQuery}
            {...{
              onRowClicked: onCompanySelected,
            }}
          />
        </StandardModal>
        {/* Opens on selecting a company in StandardModal above, displays Kivra form to activate for selected company  */}
        <AutogiroIntegrationForm
          open={autogiroOpen}
          closeFunction={() => setAutogiroOpen(false)}
          companies={companies}
          currentCompany={selectedCompany}
        />
      </DetailLayoutWrapper>
    </>
  );
}
