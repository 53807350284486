import * as React from "react";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import { constants } from "../../../../../store/inspectionErrands";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InnerBox } from "../../../../sharedStyles";
import { TextButton } from "../../../Base/Buttons";

import { useFilteredErrandComponents } from "../../../../../store/errandComponents";
import RoomOverview from "../../../../TechnicalManagement/Component/RoomOverview";
import { useDispatch } from "react-redux";
import {
  ERRAND_OBJ_TYPES,
  getComponentQueryKeyFromType,
} from "../../../ReportErrand/ChapterForm/Chapters/Component";
import {
  getComponentsByRooms,
  getCurrentProductTypeUsage,
} from "../../../../TechnicalManagement/utils";
import { InfoBox } from "../../../../Displays";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";
import BrfPremisesTable from "src/components/Tables/BrfPremises/FullTable";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";
import { useInspectionErrandSetting } from "src/store/inspectionErrandSettings";

const getAttrNameFromType = (type) => {
  if (type === ERRAND_OBJ_TYPES.APARTMENT) return "apartment";
  if (type === ERRAND_OBJ_TYPES.INDUSTRIAL_PREMISES)
    return "industrial_premises";
  if (type === ERRAND_OBJ_TYPES.COMMON_AREA) return "common_area";
  if (type === ERRAND_OBJ_TYPES.BRF_PREMIS) return "brf_premis";
  if (type === ERRAND_OBJ_TYPES.PARKING_SPOT) return "parking_spot";
};

export default () => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [selectedType, setSelectedType] = React.useState(null);

  const formInstance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  React.useEffect(() => {
    if (formInstance?.apartment) {
      setSelectedType(ERRAND_OBJ_TYPES.APARTMENT);
    } else if (formInstance?.industrial_premises) {
      setSelectedType(ERRAND_OBJ_TYPES.INDUSTRIAL_PREMISES);
    } else if (formInstance?.common_area) {
      setSelectedType(ERRAND_OBJ_TYPES.COMMON_AREA);
    } else if (formInstance?.brf_premis) {
      setSelectedType(ERRAND_OBJ_TYPES.BRF_PREMIS);
    } else if (formInstance?.parking_spot) {
      setSelectedType(ERRAND_OBJ_TYPES.PARKING_SPOT);
    }
  }, []);

  const componentQueryKey = getComponentQueryKeyFromType(selectedType);
  const attrName = getAttrNameFromType(selectedType);

  const selectedSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const [inspectionSetting] = useInspectionErrandSetting(selectedSetting?.id);
  const excludeComponentTypeIds =
    inspectionSetting?.exclude_component_types?.map((t) => t.id);

  const selectedPremises = useFormInstanceField({
    storeName,
    fieldKey: attrName,
  });

  const componentQ = buildQueryString({
    [componentQueryKey || "id__in"]: selectedPremises?.id || "-1",
  });
  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const filteredComponents = components?.filter((c) => {
    const currentProductUsage = getCurrentProductTypeUsage(c);
    const componentTypeId =
      currentProductUsage?.product_type?.component_type?.id;

    if (excludeComponentTypeIds?.includes(componentTypeId)) {
      return false;
    }
    return true;
  });

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components: filteredComponents });
  }, [filteredComponents]);

  const handleSetPremises = (premises) => {
    if (!premises) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [attrName]: null,
          },
        })
      );

      return;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [attrName]: premises,
        },
      })
    );
  };

  React.useEffect(() => {
    if (selectedSetting && selectedSetting?.category != null) {
      switch (selectedSetting.category) {
        case 0: {
          setSelectedType(ERRAND_OBJ_TYPES.APARTMENT);
          return;
        }
        case 1: {
          setSelectedType(ERRAND_OBJ_TYPES.INDUSTRIAL_PREMISES);
          return;
        }
        case 4: {
          setSelectedType(ERRAND_OBJ_TYPES.COMMON_AREA);
          return;
        }
        case 5: {
          setSelectedType(ERRAND_OBJ_TYPES.PARKING_SPOT);
          return;
        }
        case 6: {
          setSelectedType(ERRAND_OBJ_TYPES.BRF_PREMIS);
          return;
        }
        default:
          return;
      }
    }
  }, []);

  if (selectedSetting == null)
    return (
      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Komponent</OverviewTitle>
            <OverviewSubtitle>
              Välj vilken komponent ärendet gäller. Välj objekt för att filtrera
              fram rätt komponent.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <hr />

        <InfoBox
          title="Besiktningstyp saknas"
          text="Välj typ av besiktning först för att välja objekt"
        />
      </InnerBox>
    );

  if (selectedPremises) {
    return (
      <InnerBox>
        {!formInstance?._dontClear && (
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Valt objekt: {selectedPremises?.str_representation}
              </OverviewTitle>
              <OverviewSubtitle>
                <TextButton
                  title="Byt objekt"
                  clicked={() => {
                    dispatch(
                      updateActiveFormInstance({
                        storeName,
                        data: {
                          apartment: null,
                          industrial_premises: null,
                          brf_premis: null,
                          parking_spot: null,
                          common_area: null,
                        },
                      })
                    );
                  }}
                  iconType="close"
                />
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
        )}
        <InnerBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Komponenter i objekt</OverviewTitle>
              <OverviewSubtitle>
                Exkluderar komponenter som är av komponenttyper som är
                exkluderade på vald besiktningstyp.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          {Object.keys(componentsByRoom)?.length > 0 ? (
            <RoomOverview
              rooms={componentsByRoom}
              selectOnly
              onSelect={() => {}}
              isNonRoomType={selectedType === ERRAND_OBJ_TYPES.PARKING_SPOT}
              contentKey={
                selectedType === ERRAND_OBJ_TYPES.PARKING_SPOT
                  ? "parking_spot"
                  : null
              }
              contentId={
                selectedType === ERRAND_OBJ_TYPES.PARKING_SPOT
                  ? selectedPremises?.id
                  : null
              } // only parking spot is nonRoomType for inspections
            />
          ) : (
            <InnerBox>
              {componentsLoading
                ? "Laddar..."
                : "Detta objekt innehåller inga komponenter att besikta."}
            </InnerBox>
          )}
        </InnerBox>
      </InnerBox>
    );
  }

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Objekt att besikta</OverviewTitle>
          <OverviewSubtitle>
            Välj vilken objekt som ska besiktas
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {selectedType == null && (
        <InnerBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>Välj typ av objekt</OverviewTitle>
          </OverviewTitleWrapper>
          {(selectedSetting?.category === 0 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Lägenhet"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(ERRAND_OBJ_TYPES.APARTMENT)}
            />
          )}

          {(selectedSetting?.category === 1 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Lokal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() =>
                setSelectedType(ERRAND_OBJ_TYPES.INDUSTRIAL_PREMISES)
              }
            />
          )}

          {(selectedSetting?.category === 4 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Gemensamt utrymme"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(ERRAND_OBJ_TYPES.COMMON_AREA)}
            />
          )}

          {(selectedSetting?.category === 5 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Parkeringsplats"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(ERRAND_OBJ_TYPES.PARKING_SPOT)}
            />
          )}

          {(selectedSetting?.category === 6 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Bostadsrätt"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(ERRAND_OBJ_TYPES.BRF_PREMIS)}
            />
          )}
        </InnerBox>
      )}

      {selectedType != null && selectedPremises == null && (
        <InnerBox>
          <TextButton
            title="Byt typ av objekt"
            iconType="arrow-back"
            extraStyle={{ marginBottom: 12 }}
            clicked={() => {
              handleSetPremises(null);
              setSelectedType(null);
            }}
          />
          <OverviewTitleWrapper>
            <OverviewTitle small>Välj objekt</OverviewTitle>
          </OverviewTitleWrapper>

          {selectedType === ERRAND_OBJ_TYPES.APARTMENT && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                value={selectedPremises}
                TableComponent={ApartmentTable}
                title="Lägenhet"
                placeholder="Välj lägenhet..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => handleSetPremises(val)}
              />
            </div>
          )}

          {selectedType === ERRAND_OBJ_TYPES.INDUSTRIAL_PREMISES && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                value={selectedPremises}
                TableComponent={IndustrialPremisesTable}
                title="Lokal"
                placeholder="Välj lokal..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => handleSetPremises(val)}
              />
            </div>
          )}

          {selectedType === ERRAND_OBJ_TYPES.COMMON_AREA && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                value={selectedPremises}
                TableComponent={CommonAreaTable}
                title="Gemensamt utrymme"
                placeholder="Välj utrymme..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => handleSetPremises(val)}
              />
            </div>
          )}

          {selectedType === ERRAND_OBJ_TYPES.BRF_PREMIS && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                value={selectedPremises}
                TableComponent={BrfPremisesTable}
                title="Bostadsrätt"
                placeholder="Välj bostadsrätt..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => handleSetPremises(val)}
              />
            </div>
          )}

          {selectedType === ERRAND_OBJ_TYPES.PARKING_SPOT && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                value={selectedPremises}
                TableComponent={ParkingSpotsTable}
                title="Fordonsplats"
                placeholder="Välj fordonsplats..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => handleSetPremises(val)}
              />
            </div>
          )}
        </InnerBox>
      )}
    </>
  );
};
