export const overviewUrl = () => {
  return "/realestates";
};

export const detailUrl = ({ id }) => {
  return `/realestates/detail/${id}`;
};

export const editUrl = ({ id }) => {
  return `/realestates/edit/${id}`;
};

export const createUrl = () => {
  return `/realestates/create`;
};

export const constructStrRep = (obj) => {
  return obj?.str_representation || obj?.key || "Fastighet";
};
