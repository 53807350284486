import * as React from "react";

import * as SC from "../../Fields/styles";

// Non connected component, used for toggling other fields and such
export default React.memo(
  ({
    id,
    description,
    title,
    value,
    disabled,
    onChange,
    noMargin,
    small,
    extraStyles = {},
    radioStyle,
  }) => {
    const handleChange = (checked) => {
      onChange(checked);
    };

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {description && (
          <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
        )}
        <SC.CheckBoxWrapper {...{ small, noMargin }} key={id}>
          <SC.CheckBox
            {...{ radioStyle }}
            id={id}
            disabled={disabled}
            checked={Boolean(value)}
            onChange={({ target: { checked } }) => handleChange(checked)}
          />
          <label htmlFor={id}>{title}</label>
        </SC.CheckBoxWrapper>
      </SC.InputSpacing>
    );
  }
);
