export default [
  {
    title: "Inställning",
    key: "MEASURE_TYPE",
    hasError: false,
    fieldKeys: [
      "title",
      "kind",
      "product_category",
      "prefix",
      "unit",
      "billable",
      "product",
    ],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
