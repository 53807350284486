import * as services from "./services";

export const generateAvyIdForRealEstate = ({
  realEstateId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const { data } = await services.generateAvyId(realEstateId);

      successCallback && successCallback(data);
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback(e);
    }
  };
};
