export default [
  {
    title: "Händelse",
    key: "MAIN",
    visited: true,
    hasError: false,
    fieldKeys: [
      "start",
      "end",
      "remind_before",
      "remind_content",
      "title",
      "description",
      "participants",
      "place",
      "calendar",
      "content_type",
      "object_id"
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
