import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";
import chapterDefs from "./chapterDefs";
import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  detailUrl,
  useParkingInvoicingForm,
  clearPeriodsForContract,
} from "../../../../store/invoicingParking";
import { updateActiveFormInstance } from "../../../../store/base";
import StandardModal from "../../../Modals/StandardModal";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import { InnerBox } from "../../../sharedStyles";
import { cloneDeep } from "lodash";

export default function InvoicingParkingModalForm({
  method,
  id,
  onCheckout,
  isOpen,
  instance = {},
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const [showErrorsOpen, setShowErrorsOpen] = React.useState(false);

  const formLoaded = Boolean(useParkingInvoicingForm(method, id));

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.parking_contract?.id }));
    }

    dispatch(clearPeriodsForContract({ id: returned?.id }));
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!dataClone.imd_setting) {
      dataClone.imd_setting = null;
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  const renderErrors = () => {
    try {
      const vals = Object.values(showErrorsOpen || {})?.map((err) => (
        <InnerBox key={err}>{err?.Message || err}</InnerBox>
      ));

      return vals;
    } catch (e) {
      return (
        <InnerBox>
          Detta fel kan inte visas, kontakta Pigello om problemet kvarstår
        </InnerBox>
      );
    }
  };
  return (
    <>
      <StandardModal
        isOpen={showErrorsOpen}
        withActionBar
        actionBarCancelTitle="Stäng"
        title="Problem som kan påverka aviseringen"
        closeFunction={() => setShowErrorsOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              Dessa fel kan leda till problem med aviseringen
            </OverviewTitle>
            <OverviewSubtitle>
              Ändringarna har sparats, men dessa problem bör åtgärdas innan
              nästa avisering, annars kommer genereringen att misslyckas
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <hr />

        {renderErrors()}
      </StandardModal>

      <ModalChapter
        storeName={storeName}
        chapterDefs={chapterDefs}
        onRenderChapter={(key) => renderChapter({ key, method })}
        submitCallback={onSubmit}
        title={
          method === "POST" ? "Skapa inställning" : "Uppdatera inställning"
        }
        onDone={onDone}
        loading={!formLoaded || loading}
        isOpen={isOpen}
      />
    </>
  );
}
