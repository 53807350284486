import Included from "./Included";
import Confirm from "./Confirm";
import Amenities from "./Amenities";
import Parking from "./Parking";

export default {
  INCLUDED: Included,
  AMENITIES: Amenities,
  PARKING: Parking,
  CONFIRM: Confirm,
};
