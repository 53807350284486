import * as React from "react";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../../Forms/Base/Buttons";
import { InnerBox } from "../../sharedStyles";
import { toMoneyString } from "../../utils/stringUtils";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { EmailTag, LinkedObject, PhoneTag } from "../../Displays";

export default function LeadInfo({
  lead,
  pipeAttributeChoices,
  isHomeQPipe,
  closeFunction,
  setEditOpen,
}) {
  const openFile = (doc) => {
    window.open(
      doc.file?.get,
      "",
      `height=${window.screen.height || window.innerHeight},width=${
        window.screen.width || window.innerWidth
      }`
    );
  };
  return (
    <InnerBox style={{ border: 0, borderRadius: 0, boxShadow: 0 }}>
      <OverviewTitleWrapper>
        <OverviewTitle>Information</OverviewTitle>
        {!isHomeQPipe && (
          <TextButton
            title="Redigera lead"
            clicked={() => {
              setEditOpen(lead.id);
              closeFunction();
            }}
            iconType="edit"
            iconPlacement="right"
            extraStyle={{ marginRight: 0 }}
          />
        )}
      </OverviewTitleWrapper>

      <DetailInfo infoObj={getInfoObj({ lead, pipeAttributeChoices })} />

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Dokument</OverviewTitle>
        </OverviewTitleWrapper>

        {lead.documents?.length > 0 ? (
          <>
            {lead.documents.map((doc) => (
              <InnerBox key={doc.id}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{doc.str_representation}</div>
                  <TextButton
                    extraStyle={{ marginRight: 0 }}
                    title="Visa fil"
                    iconType="download"
                    iconPlacement="right"
                    clicked={() => openFile(doc)}
                  />
                </div>
              </InnerBox>
            ))}
          </>
        ) : (
          <InnerBox>Denna lead har inga dokument</InnerBox>
        )}
      </InnerBox>
    </InnerBox>
  );
}

function getInfoObj({ lead, pipeAttributeChoices }) {
  let infoObj = {
    Grundläggande: [
      {
        title: "Typ",
        value: lead.corporate_name ? "Bolag" : "Privatperson",
      },
      {
        title: "Kopplad hyresgäst",
        value: <LinkedObject obj={lead.tenant} urlMethod={tenantDetailUrl} />,
      },
      {
        title: "Namn",
        value: lead.corporate_name || lead.str_representation,
      },
      {
        title: "Kontakt",
        value: `${lead.first_name || ""} ${lead.last_name || ""}`,
        hidden: !lead.corporate_name,
      },
      {
        title: "E-post",
        value: <EmailTag mail={lead.email} />,
      },
      {
        title: "Telefon",
        value: <PhoneTag phone={lead?.phone} />,
      },
      {
        title: lead.corporate_name ? "Org. nr" : "Personnr.",
        value: lead?.legal_id,
      },
      {
        title: "Budget/värde",
        value: toMoneyString(lead?.value, true),
      },
      {
        title: "Medsökande",
        value: lead?.together_with?.str_representation || "-",
      },
    ],
    "Intresserad av objekt": [
      {
        title: "Typ",
        value: lead.apartment
          ? "Lägenhet"
          : lead.industrial_premises
          ? "Lokal"
          : lead.parking_spot
          ? "Parkeringsplats"
          : "-",
      },
      {
        title: "Objekt",
        value: lead.apartment ? (
          <LinkedObject obj={lead.apartment} urlMethod={apartmentDetailUrl} />
        ) : lead.industrial_premises ? (
          <LinkedObject
            obj={lead.industrial_premises}
            urlMethod={indpDetailUrl}
          />
        ) : lead.parking_spot ? (
          <LinkedObject obj={lead.parking_spot} urlMethod={parkingDetailUrl} />
        ) : (
          "-"
        ),
      },
    ],
    "Inkomst/Omsättning": [
      {
        title: "Yrke/bransch",
        value: lead.profession,
      },
      {
        title: "Årinkomst/omsättning",
        value: toMoneyString(lead.yearly_income, true),
      },
      {
        title: "År för inkomst/omsättning",
        value: lead.yearly_income_year,
      },
    ],
  };
  if (pipeAttributeChoices?.length) {
    infoObj["Projektspecifikt"] = pipeAttributeChoices.map((p) => {
      const connectedValue = lead?.pipeattributevalue_set?.find(
        (pv) => pv.pipe_attribute?.id === p.id
      );

      const value = p?.kind === 0
        ? connectedValue?.float_value
        : p?.kind === 1
        ? connectedValue?.char_value :
        connectedValue?.float_value != null && connectedValue?.max_float_value != null ?
        `${connectedValue?.float_value} - ${connectedValue?.max_float_value}` :
        null
      return {
        title: p.title,
        value: value
      };
    });
  }

  return infoObj;
}
