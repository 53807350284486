import * as React from "react";
import { useParams, useHistory } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { useIndustrialPremises } from "../../../store/industrialPremises";
import IndustrialPremisesModal from "../../../components/Forms/IndustrialPremises/ChapterForm/ModalForm";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { LinkedObject } from "../../../components/Displays";
import { detailUrl as buildingDetailUrl } from "../../../store/buildings";
import {
  toMoneyString,
  toSqmString,
} from "../../../components/utils/stringUtils";
import {
  buildQueryString,
  deleteObject,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../../store/base";
import { constants as rentIncreaseConstants } from "../../../store/rentIncrease";

import { useFilteredNotes } from "../../../store/notes";
import { useBuilding } from "../../../store/buildings";
import { renderFloors } from "../../../components/Forms/Base/FieldComponents/FloorSelector";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/Billecta/Table/BasicTable";
import { lightOrDark } from "../../../components/DigitalDoc/utils";
import IndustrialPremisesStatusModalForm from "../../../components/Forms/IndustrialPremises/StatusForm/ModalForm";
import {
  useCompany,
  detailUrl as companyDetailUrl,
} from "../../../store/companies";
import VacancyBookingRecordTable from "../../../components/Tables/InvoicingVacancyRecord/FullTable";
import IndustrialPremisesVacancyBookingModalForm from "../../../components/Forms/IndustrialPremises/VacancyBookingForm/ModalForm";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import { getIndpIncreases } from "../../../store/rentIncrease/store/actions";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import moment from "moment";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import SmallCompanySummary from "../../../components/SummaryCards/Company/Small";
import SmallTenantSummary from "../../../components/SummaryCards/Tenant/Small";
import Cookies from "js-cookie";

const RENT_INCREASE_TABS = {
  COMING: "Kommande",
  HISTORICAL: "Genomförda",
};

export default function IndustrialPremisesOverview() {
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const { industrialPremisesId } = useParams();
  const [statusesOpen, setStatusesOpen] = React.useState(false);
  const [vacancyBookingConfigOpen, setVacancyBookingConfigOpen] =
    React.useState(false);
  const userType = useSelector((state) => state.app.user?.user_type);

  const increases = useSelector(
    (state) => state[rentIncreaseConstants.STORE_NAME].currentIndpIncreases
  );
  const rentIncreaseQuery = buildQueryString({
    industrial_premises: industrialPremisesId,
  });

  React.useEffect(() => {
    dispatch(getIndpIncreases({ query: rentIncreaseQuery }));
  }, []);

  const comingIncreases = increases?.filter(
    (i) => !i.applied && moment(i.apply_from).isAfter(moment())
  );
  const historicalIncreases = increases?.filter(
    (i) => !comingIncreases?.map((c) => c.id).includes(i.id)
  );

  const canEdit = usePermissionCheck("change_can_baseobject");
  const { push } = useHistory();

  const customerId = React.useMemo(() => {
    try {
      return JSON.parse(Cookies.get("intercom_customer") ?? {})?.id;
    } catch (e) {
      return null;
    }
  }, []);

  const canViewSensitiveInfoTemp = useSelector(
    (state) =>
      (state.app.user?.user_type === 1 || state.app.user?.user_type === 0) &&
      (customerId != 128 || state.app.user?.user_type === 0)
  );

  const [industrialPremises] = useIndustrialPremises(industrialPremisesId);
  const [building] = useBuilding(industrialPremises?.building?.id);
  const [landlord] = useCompany(industrialPremises?.landlord?.id);
  const [vancacyBookingCompany] = useCompany(
    industrialPremises?.vacancy_booking_company?.id
  );
  const [removeRentIncreaseOpen, setRemoveRentIncreaseOpen] =
    React.useState(false);
  const [selectedRentIncreaseTab, setSelectedRentIncreaseTab] =
    React.useState("COMING");

  const { hasBillectaViewPermission } = useFrequentPermissions();
  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const inInvoicingVacancyRecordsQ = {
    industrial_premises: industrialPremisesId,
    order_by: "-updated_at",
  };

  const notesQ = buildQueryString({
    id__in: industrialPremises?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const goToRentIncreases = () => {
    push(`/industrial-premises-rent-increase?indpId=${industrialPremisesId}`);
  };

  const handleConfirmRemoveRentIncrease = (id) => {
    setRemoveRentIncreaseOpen(id);
  };

  const removeRentIncrease = () => {
    dispatch(
      deleteObject({
        instance: {
          id: removeRentIncreaseOpen,
        },
        constants: rentIncreaseConstants,
        noToast: true,
        skipDestroyDeleteData: true,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Hyreshöjningen togs bort",
              description: "Den planerade höjningen genomförs ej",
            })
          );
          dispatch({
            type: rentIncreaseConstants.SET_CURRENT_INDP_INCREASES,
            payload: {
              increases: [],
            },
          });
          dispatch(getIndpIncreases({ query: rentIncreaseQuery }));
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Den planerade höjningen kunde ej tas bort",
            })
          );
        },
      })
    );
  };

  const renderRentIncreases = () => {
    return (
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Hyreshöjningar</OverviewTitle>
            <OverviewSubtitle>
              <TextButton
                title="Lägg till hyreshöjning"
                iconType="add"
                iconPlacement="right"
                clicked={goToRentIncreases}
              />
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="mb-2">
          <PageSlider
            {...{
              TABS: RENT_INCREASE_TABS,
              selectedTab: selectedRentIncreaseTab,
              onTabSelected: (tab) => setSelectedRentIncreaseTab(tab),
            }}
          />
        </div>

        {selectedRentIncreaseTab === "COMING" && (
          <>
            {comingIncreases?.length ? (
              <PremisesTable
                headers={[
                  "Datum för höjning",
                  "Höjning av referenshyra",
                  "Ta bort",
                ]}
                rows={comingIncreases?.map((ci) => [
                  ci.apply_from,
                  `${ci.quota.toFixed(2)}%`,
                  <TextButton
                    red
                    title="Ta bort"
                    iconType="close"
                    iconPlacement="right"
                    clicked={() => handleConfirmRemoveRentIncrease(ci.id)}
                  />,
                ])}
              />
            ) : (
              <div>Inga planerade höjningar</div>
            )}
          </>
        )}

        {selectedRentIncreaseTab === "HISTORICAL" && (
          <>
            {historicalIncreases?.length ? (
              <PremisesTable
                headers={["Datum för höjning", "Höjning av referenshyra"]}
                rows={historicalIncreases?.map((ci) => [
                  ci.apply_from,
                  `${ci.quota.toFixed(2)}%`,
                ])}
              />
            ) : (
              <div>Inga genomförda höjningar</div>
            )}
          </>
        )}
      </DetailPageBox>
    );
  };

  const statuses = React.useMemo(() => {
    return industrialPremises?.customer_statuses;
  }, [industrialPremises]);

  const statusColumns = React.useMemo(() => {
    return [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 8px",
                borderRadius: 5,
                backgroundColor: value?.color_code,
                color:
                  lightOrDark(value?.color_code) === "light" ? "#000" : "#fff",
              }}
            >
              {value?.name || "-"}
            </div>
          );
        },
      },

      {
        Header: "Börjar gälla",
        accessor: "start_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;
          return <div>{value}</div>;
        },
      },
      {
        Header: "Slutar gälla",
        accessor: "end_date",
        Cell: ({ value }) => {
          if (!value) return <div>-</div>;

          return <div>{value}</div>;
        },
      },
    ];
  }, [industrialPremises]);

  return (
    <>
      {vacancyBookingConfigOpen && (
        <IndustrialPremisesVacancyBookingModalForm
          id={industrialPremisesId}
          onCheckout={() => setVacancyBookingConfigOpen(false)}
          instance={industrialPremises}
        />
      )}
      <IndustrialPremisesStatusModalForm
        id={industrialPremisesId}
        isOpen={statusesOpen}
        onCheckout={() => setStatusesOpen(false)}
        instance={industrialPremises}
      />

      {editOpen && (
        <IndustrialPremisesModal
          instance={industrialPremises}
          method="PATCH"
          id={industrialPremises?.id}
          onCheckout={() => setEditOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "65%",
              alignSelf: "flex-start",
              marginRight: 12,
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Objektsöversikt</OverviewTitle>
                {canEdit && (
                  <PrimaryButton
                    title="Redigera"
                    clicked={() => setEditOpen(true)}
                  />
                )}
              </OverviewTitleWrapper>

              <DetailInfo
                infoObj={getInfoObj({
                  industrialPremises,
                  building,
                  avyTmplActive,
                  canViewSensitiveInfoTemp,
                  landlord,
                  vancacyBookingCompany,
                })}
              />
            </DetailPageBox>

            {hasBillectaViewPermission && (
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle>Vakansbokningar</OverviewTitle>
                    <OverviewSubtitle>
                      Logg över genererade vakansbokningar. Tryck på en rad för
                      att komma till underlaget.
                    </OverviewSubtitle>
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera inställning för vakansbokningar"
                        clicked={() => setVacancyBookingConfigOpen(true)}
                        iconType="edit"
                        iconPlacement="right"
                      />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <VacancyBookingRecordTable
                  persistantQuery={inInvoicingVacancyRecordsQ}
                  isBare
                  ignoreLocalStorage
                  hideFilters
                  withInvoiceDetailUrl
                />
              </DetailPageBox>
            )}
          </div>

          {canViewSensitiveInfoTemp && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "35%",
                alignSelf: "flex-start",
              }}
            >
              {industrialPremises?.tenant && (
                <DetailPageBox>
                  <OverviewTitleWrapper>
                    <OverviewTitle small>Hyresgäst</OverviewTitle>
                  </OverviewTitleWrapper>

                  <SmallTenantSummary id={industrialPremises?.tenant?.id} />
                </DetailPageBox>
              )}
              {industrialPremises?.landlord && (
                <DetailPageBox>
                  <OverviewTitleWrapper>
                    <OverviewTitle small>Hyresvärd</OverviewTitle>
                  </OverviewTitleWrapper>

                  <SmallCompanySummary id={industrialPremises?.landlord?.id} />
                </DetailPageBox>
              )}

              <DetailPageBox style={{ alignSelf: "flex-start", width: "100%" }}>
                <OverviewTitleWrapper style={{ marginBottom: 4 }}>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Anpassade statusar</OverviewTitle>
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera statusar"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setStatusesOpen(true)}
                      />
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                {statuses?.length ? (
                  <Table data={statuses} columns={statusColumns} hideSearch />
                ) : (
                  <InnerBox style={{ marginTop: 8, marginBottom: 12 }}>
                    Inga statusar satta på objektet
                  </InnerBox>
                )}
                <hr style={{ marginTop: 0 }} />
                <TextButton
                  title="Gå till statusöversikt"
                  iconType="arrow"
                  iconPlacement="right"
                  clicked={() => push("/configcenter/premises-statuses")}
                />
              </DetailPageBox>

              {renderRentIncreases()}

              <DetailPageBox>
                <Notes
                  notes={notes}
                  contentType="standard.industrialpremises"
                  objectId={industrialPremisesId}
                />
              </DetailPageBox>
            </div>
          )}
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({
  industrialPremises,
  building,
  canViewSensitiveInfoTemp,
  landlord,
  avyTmplActive,
  vancacyBookingCompany,
}) {
  const infoObj = {
    Översikt: [
      {
        title: "Synkad mot Avy-Tmpl",
        value: (
          <StatusLabel state={industrialPremises?.avytmpl_id ? 0 : 3}>
            {industrialPremises?.avytmpl_id ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !avyTmplActive,
      },
      {
        title: "Objektsnummer",
        value: industrialPremises?.premises_id,
      },

      {
        title: "Fastighet",
        value: industrialPremises?.realestate?.str_representation,
      },
      {
        title: "Byggnad",
        value: <LinkedObject obj={building} urlMethod={buildingDetailUrl} />,
      },

      {
        title: "Vakanser bokas på",
        value: (
          <LinkedObject
            obj={vancacyBookingCompany}
            urlMethod={companyDetailUrl}
          />
        ),
      },
      {
        title: "Våning",
        value: renderFloors(industrialPremises?.dynamic_floors),
      },
      {
        title: "Area",
        value: toSqmString(industrialPremises?.area),
      },
      {
        title: "Referenshyra/månad",
        value: toMoneyString(industrialPremises?.base_rent, true),
        hidden: !canViewSensitiveInfoTemp,
      },
      // {
      //   title: "Under renovering",
      //   value: industrialPremises?.renovating ? "Ja" : "Nej",
      // },

      // {
      //   title: "Uthyrd i andrahand",
      //   value: industrialPremises?.subletted ? "Ja" : "Nej",
      // },
    ],
    Adress: [
      {
        title: "Adress",
        value: industrialPremises?.address
          ? industrialPremises?.address?.base?.split(",")?.[0]
          : "-",
      },
      {
        title: "Postnr.",
        value: industrialPremises?.address?.postal_code || "-",
      },
      {
        title: "Stad",
        value: industrialPremises?.address?.city || "-",
      },
      {
        title: "Land",
        value: industrialPremises?.address?.country || "-",
      },
    ],
  };

  return infoObj;
}
