export default [
  {
    title: "Aviseringsinställning",
    key: "INVOICING_SETTING",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "message",
      "require_cost_accept",
      "auto_merge",
      "auto_attest",
      "invoices_with_rent_increase_rows",
      "prefix_object_costs",
    ],
  },
  {
    title: "Intervall",
    key: "INTERVAL",
    hasError: false,
    fieldKeys: ["interval_setting"],
  },
  {
    title: "Bokföring",
    key: "ACCOUNTING",
    hasError: false,
    fieldKeys: ["project", "cost_center"],
  },
  {
    title: "Betalningsvillkor",
    key: "DEBT_SETTING",
    hasError: false,
    fieldKeys: ["debt_setting"],
  },
  {
    title: "IMD",
    key: "IMD_SETTING",
    hasError: false,
    fieldKeys: ["imd_setting"],
  },
  {
    title: "Deposition",
    key: "DEPOSIT",
    hasError: false,
    fieldKeys: ["automatic_deposit_handling", "deposit_quota"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["id"],
  },
];
