import * as React from "react";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import PlainForm from "../../../components/Forms/ConfigCenter/General/Insights/PlainForm";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { destroyForm, updateActiveFormInstance } from "../../../store/base";
import { useConfigCenter, constants } from "../../../store/configcenter";

import PageTitle from "../../Layouts/PageTitle/PageTitle";

export default () => {
  const dispatch = useDispatch();
  const [configCenter, configCenterLoading] = useConfigCenter();

  React.useEffect(() => {
    if (configCenter) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: configCenter,
        })
      );
    }
  }, [configCenter]);

  const breadCrumbs = [
    {
      label: "Insights",
      url: "/configcenter/general/insights",
    },
    {
      label: "Redigera",
    },
  ];

  if (!configCenter)
    return (
      <DetailPageBox style={{ height: "600px" }}>
        <OverlaySpinner />
      </DetailPageBox>
    );

  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle title="Insights" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Insights</OverviewTitle>
            <OverviewSubtitle>
              Tryck på "Uppdatera" för att spara ändringar
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {configCenter && (
          <PlainForm
            method="PATCH"
            untouchedInstance={configCenter}
            nextUrl={"/configcenter/general/insights"}
          />
        )}
      </DetailPageBox>
    </BareLayoutWrapper>
  );
};
