import {
  usePagination,
  useFilteredObjects,
  buildQueryString,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import { performFilter, filterPagination } from "../store/actions";
import constants from "../store/constants";

export function useFilteredVacancyInvoicingRecords(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useVacancyInvoicingRecordPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useVacancyInvoicingRecordPaginationCount({ filters } = {}) {
  const queryString = buildQueryString({
    _page: 1,
    _page_size: 0,
    ...filters,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}
