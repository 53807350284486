import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { cloneDeep } from "lodash";

import { useRoundingErrandSetting } from "../../../store/roundingErrandSettings";
import { updateActiveFormInstance } from "../../../store/base";
import { constants, create, detailUrl } from "../../../store/roundingErrands";

//components & styles
import ErrandsTable from "../../../components/Tables/RoundingErrands/FullTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import Alert, { ALERT_TYPES } from "src/components/Alert/Alert";

function RoundingErrand() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { settingId } = useParams();

  const [roundingErrandSetting, roundingErrandSettingLoading] =
    useRoundingErrandSetting(settingId);

  const { id } = useSelector((state) => state.app?.user);
  const { push } = useHistory();

  const [createErrandLoading, setCreateErrandLoading] = React.useState(false);

  const getPlannedEnd = (plannedStart) => {
    if (roundingErrandSetting?.month_interval) {
      const plannedEnd = moment(plannedStart).add(
        "months",
        roundingErrandSetting?.month_interval
      );

      return `${plannedEnd.format("YYYY-MM-DD")} 00:00:00`;
    } else {
      const plannedEnd = moment(plannedStart).add(
        "weeks",
        roundingErrandSetting?.month_interval
      );

      return `${plannedEnd.format("YYYY-MM-DD")} 00:00:00`;
    }
  };

  const generateNextErrand = () => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm");
    const plannedStart = `${roundingErrandSetting?.next_recurrence} 00:00:00`;
    const newErrand = {
      setting: { id: roundingErrandSetting?.id },
      title: roundingErrandSetting?.str_representation,
      created_at: currentTime,
      reported_by: { id: id },
      planned_start: plannedStart,
      planned_end: getPlannedEnd(plannedStart),
    };

    dispatch(updateActiveFormInstance({ storeName, data: newErrand }));
    onSubmit();
  };

  const checkout = () => {
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
  };

  const onSuccess = (_, returned) => {
    setCreateErrandLoading(false);

    if (returned?.id) {
      push(detailUrl({ id: returned.id, isGov: false }));
    }
    checkout();
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setCreateErrandLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: () => setCreateErrandLoading(false),
        preProcess,
      })
    );
  };

  const errandQuery = {
    setting: settingId,
  };

  const initialOrderBy = ["-planned_start"];

  return (
    <DetailInnerWrapper>
      {createErrandLoading && <OverlaySpinner />}
      <DetailPageBox>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle style={{ marginBottom: 8 }}>Ärenden</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
        </div>

        {roundingErrandSetting?.next_recurrence &&
        !roundingErrandSetting?.paused ? (
          <Alert
            title={`Nästa autogenererade ärende skapas ${roundingErrandSetting?.next_recurrence}`}
            primaryAction={generateNextErrand}
            primaryTitle="Generera nästa ärende direkt"
          >
            Utifrån denna schemainställning så kommer nästa rondering under
            detta ronderingsschema genereras{" "}
            <strong>{roundingErrandSetting?.next_recurrence}</strong>. Tryck på{" "}
            <strong>"Generera nästa ärende direkt"</strong> för att istället
            skapa upp ärendet direkt.
          </Alert>
        ) : (
          <Alert
            title={`Schemat är pausat eller saknar startdatum`}
            type={ALERT_TYPES.WARNING}
          >
            För att kunna generera ärenden så måste schemat vara aktivt och ha
            ett startdatum. Redigera schemainställningen för att åtgärda detta.
          </Alert>
        )}
        <ErrandsTable
          initialOrderBy={initialOrderBy}
          persistantQuery={errandQuery}
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default RoundingErrand;
