import * as React from "react";
import { buildQueryString } from "../../../../store/base";
import { useFilteredCosts } from "../../../../store/costs";
import { DateCell, LinkedObject } from "../../../Displays";
import Modal from "../../../Forms/Base/Modals/Modal";

import { toMoneyString } from "../../../utils/stringUtils";
import { COST_ROW_STATES, getCostRowState } from "./costCols";
import { detailUrl as indexDetailUrl } from "../../../../store/indexsetting";
import Table from "../../../Billecta/Table/BasicTable";
import Badge, { BADGE_TYPES } from "../../../Badge/Badge";

export default function IndexConnectedCosts({
  closeFunction,
  costIds,
  invoicingObj = {},
  contract = {},
}) {
  const q = buildQueryString({
    id__in: costIds || [],
  });

  const [costs] = useFilteredCosts(q);

  const tableCosts = React.useMemo(() => {
    return costs;
  }, [costs]);

  // console.log(tableCosts, 2);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: "Debiteringsrad",
        accessor: "title",
        Cell: ({ value, row }) => {
          const isDiscount = row.original.value < 0;
          const isRentIncrease = row.original.is_rent_increase_cost;
          const doNotDebit = row.original.do_not_debit;

          const state = getCostRowState({
            costRow: row.original,
            invoicingObj,
            contract,
          });

          const stateRender =
            state === COST_ROW_STATES.ACTIVE ? (
              <Badge small type={BADGE_TYPES.GREEN}>
                Aktiv
              </Badge>
            ) : state === COST_ROW_STATES.EARLIER ? (
              <Badge small type={BADGE_TYPES.YELLOW}>
                Tidigare
              </Badge>
            ) : state === COST_ROW_STATES.COMING ? (
              <Badge small type={BADGE_TYPES.DEFAULT}>
                Kommande
              </Badge>
            ) : (
              <Badge small type={BADGE_TYPES.GRAY}>
                Okänd
              </Badge>
            );

          return (
            <div className="flex flex-col">
              <div className="flex mb-1 items-center space-x-1">
                {stateRender}
                <Badge
                  small
                  type={doNotDebit ? BADGE_TYPES.RED : BADGE_TYPES.GREEN}
                >
                  {doNotDebit ? "Debiteras Inte" : "Debiteras"}
                </Badge>
                {isDiscount && (
                  <Badge small type={BADGE_TYPES.YELLOW}>
                    Rabatt
                  </Badge>
                )}
                {isRentIncrease && (
                  <Badge small type={BADGE_TYPES.INDIGO}>
                    {"Hyreshöjning"}
                  </Badge>
                )}
              </div>
              <div className="font-medium text-sm">{value}</div>
            </div>
          );
        },
      },

      {
        Header: "Debiteras",
        accessor: "value",
        Cell: ({ value, row }) => {
          // console.log(row, 80);

          const productVat = row.original.product?.vat;
          const rowVat = row.original.vat;
          const val =
            rowVat != null
              ? `${rowVat}% moms`
              : productVat != null
              ? `${productVat}% moms (från produkt)`
              : invoicingObj?.vat != null
              ? `${invoicingObj?.vat}% moms (från grundmomssats)`
              : "-";

          return (
            <div className="flex flex-col">
              <div className="text-sm font-medium">
                {toMoneyString(value || 0, true)}/mån
              </div>
              <div className="text-xs">{val}</div>
            </div>
          );
        },
      },
      {
        Header: "Period",
        id: "period",
        Cell: ({ row }) => {
          const { start_date, end_date } = row.original;

          if (!start_date && !end_date) {
            return (
              <div className="flex items-center space-x-2">Med avtalet</div>
            );
          }

          return (
            <div className="flex items-center space-x-2">
              {start_date ? <DateCell date={start_date} /> : "Avtalets start"}
              <div>-</div>
              {end_date ? <DateCell date={end_date} /> : "Avtalets slut"}
            </div>
          );
        },
      },

      {
        Header: "Indexuppräkning",
        accessor: "indexation",
        Cell: ({ value }) => {
          return <LinkedObject obj={value} urlMethod={indexDetailUrl} />;
        },
      },
    ],
    [costs]
  );

  return (
    <Modal title="Källor till indexuppräkning" closeFunction={closeFunction}>
      <Table
        extraStyles={{ marginTop: 0 }}
        columns={tableColumns}
        data={tableCosts || []}
        hideSearch
      />
    </Modal>
  );
}
