import * as React from "react";
import {
  buildQueryString,
  updateActiveFormInstance,
  useAnyPermissionCheck,
  usePermissionCheck,
} from "../../../store/base";

import TenantGroupForm from "../../../components/Forms/TenantGroup/ChapterForm/ModalForm";

import { useDispatch } from "react-redux";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { useFilteredTenantGroups, constants } from "../../../store/tenantGroup";
import { useParams } from "react-router";
import {
  PopupButton,
  PrimaryButton,
} from "../../../components/Forms/Base/Buttons";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useBrfCompany } from "../../../store/brfCompanies";
import Table from "../../../components/Billecta/Table/BasicTable";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

export default function BrfCompanyBoard() {
  const { brfCompanyId } = useParams();
  const dispatch = useDispatch();
  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteBoardOpen, setDeleteBoardOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [brfCompany] = useBrfCompany(brfCompanyId);
  const groupsQuery = buildQueryString({
    brf_company: brfCompanyId,
  });
  const [groups, groupsLoading] = useFilteredTenantGroups(groupsQuery);
  const board = groups?.find((g) => g.category === 0);

  const canEditGroup = useAnyPermissionCheck([
    "change_can_baseobject",
    "change_can_tenant",
  ]);
  const canDeleteGroup = useAnyPermissionCheck([
    "delete_can_baseobject",
    "delete_can_tenant",
  ]);

  const tableMembers = React.useMemo(() => {
    return board?.participators;
  }, [board?.participators]);

  const tableColumns = React.useMemo(() => [
    {
      Header: "Namn",
      accessor: "str_representation",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Visa namn",
      accessor: "show_name",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 1 : 3}>
            {value ? "Ja" : "Nej"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Visa telefon",
      accessor: "show_phone",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 1 : 3}>
            {value ? "Ja" : "Nej"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Visa e-post",
      accessor: "show_email",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 1 : 3}>
            {value ? "Ja" : "Nej"}
          </StatusLabel>
        );
      },
    },
  ]);

  const handleEditBoard = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          ...board,
        },
      })
    );

    setEditOpen(true);
  };

  let actions = [];
  if (canEditGroup) {
    if (board) {
      actions.push({
        name: "Uppdatera styrelse",
        onClick: () => handleEditBoard(),
      });
    } else {
      actions.push({
        name: "Lägg till styrelse",
        onClick: () => setCreateOpen(true),
      });
    }
  }
  if (canDeleteGroup) {
    actions.push({
      name: "Ta bort styrelse",
      onClick: () => setDeleteBoardOpen(true),
    });
  }
  return (
    <DetailInnerWrapper>
      <TenantGroupForm
        method="POST"
        isOpen={createOpen}
        onCheckout={() => setCreateOpen(false)}
        cooperative={brfCompany}
      />

      <TenantGroupForm
        method="PATCH"
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        cooperative={brfCompany}
        id={board?.id}
      />

      <DeleteModal
        isOpen={deleteBoardOpen}
        closeFunction={() => setDeleteBoardOpen(false)}
        constants={constants}
        instance={board}
      />

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Styrelse</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>

          {!groupsLoading && (
            <>
              {actions.length > 0 && (
                <PopupButton title="Hantera" actions={actions} />
              )}
            </>
          )}
        </OverviewTitleWrapper>

        {board?.participators?.length > 0 ? (
          <>
            <Table columns={tableColumns} data={tableMembers} hideSearch />
          </>
        ) : (
          <InnerBox
            style={{
              display: "flex",
              minHeight: 200,
              alignItems: "center",
              justifyContent: "center",
              padding: 40,
              textAlign: "center",
            }}
          >
            {!board
              ? `Ingen styrelse uppsatt. Klicka på "Lägg till styrelse" i "Hantera" för att lägga
            till medlemmar till styrelsen.`
              : `Inga medlemmar i styrelsen, klicka på "Uppdatera styrelse" i "Hantera" för att lägga till. `}
          </InnerBox>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
