import { useForm } from "../../base";

import { getPatchForm, constants } from "..";

export function useSettingForm() {
  let params = {
    storeName: constants.STORE_NAME,
    method: "PATCH",
    fetchMethod: getPatchForm,
    id: "dummy-id",
  };

  return useForm(params);
}
