import * as React from "react";
import { NumberInput, TextInput } from "../Base/Fields";

export default function ContactNestedChildren({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  return (
    <>
      <TextInput
        title="Titel"
        storeName={storeName}
        fieldKey={`${parentPath}.title`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.title`}
      />

      <TextInput
        title="Namn"
        storeName={storeName}
        fieldKey={`${parentPath}.name`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.name`}
      />

      <NumberInput
        title="Telefon"
        storeName={storeName}
        fieldKey={`${parentPath}.phone`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.phone`}
        forceInputType="text"
      />

      <TextInput
        title="E-post"
        storeName={storeName}
        fieldKey={`${parentPath}.email`}
        type="email"
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.email`}
      />
    </>
  );
}
