export const detailUrl = ({ id }) => {
  return `/parking-spots/detail/${id}`;
};
export const overviewUrl = () => {
  return `/parking/parking-spots`;
};
export const createUrl = () => {
  return `/parking-spots/create`;
};
export const editUrl = ({ id }) => {
  return `/parking-spots/edit/${id}`;
};

export const constructStrRep = (obj) => {
  return obj?.str_representation || obj?.title || "Parkeringsplats";
};
