import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useBuilding } from "../../../store/buildings";
import BuildingForm from "../../../components/Forms/Building/ChapterForm/ModalForm";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  buildQueryString,
  useAllPermissionCheck,
  usePermissionCheck,
} from "../../../store/base";
import { useRealEstate } from "../../../store/realEstates";

import { detailUrl as keyPermissionDetailUrl } from "../../../store/keypermission";

import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useFilteredNotes } from "../../../store/notes";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { LinkedObject } from "../../../components/Displays";

export default function BuildingInfo() {
  const [editOpen, setEditOpen] = React.useState(false);
  const { push } = useHistory();
  const { buildingId } = useParams();
  const [building] = useBuilding(buildingId);
  const canEdit = usePermissionCheck("change_can_baseobject");
  const canRentIncrease = useAllPermissionCheck([
    "change_can_baseobject",
    "change_can_contract",
  ]);
  const [realEstate] = useRealEstate(building?.realestate?.id);
  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const notesQ = buildQueryString({
    id__in: building?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  return (
    <>
      {editOpen && (
        <BuildingForm
          id={building?.id}
          method="PATCH"
          instance={building}
          onCheckout={() => setEditOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 6, maxWidth: "64%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitle>Byggnadsinformation</OverviewTitle>
              {canEdit && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            {building && (
              <DetailInfo
                infoObj={getinfoObj({ building, realEstate, avyTmplActive })}
              />
            )}
          </DetailPageBox>

          <div
            style={{
              display: "flex",
              flex: 4,
              flexDirection: "column",
              maxWidth: "35%",
              alignSelf: "flex-start",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hyreshöjning</OverviewTitle>
                  <OverviewSubtitle>
                    Genomför hyreshöjning för objekt i denna byggnad
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {canRentIncrease && (
                <>
                  <div>
                    <TextButton
                      title="Hyreshöjning för lägenheter"
                      iconType="arrow"
                      iconPlacement="right"
                      clicked={() =>
                        push(`/apartment-rent-increase?building=${buildingId}`)
                      }
                    />
                  </div>

                  <div style={{ marginTop: 12 }}>
                    <TextButton
                      title="Hyreshöjning för lokaler"
                      iconType="arrow"
                      iconPlacement="right"
                      clicked={() =>
                        push(
                          `/industrial-premises-rent-increase?building=${buildingId}`
                        )
                      }
                    />
                  </div>
                </>
              )}
            </DetailPageBox>

            <DetailPageBox>
              <Notes
                notes={notes}
                contentType="standard.building"
                objectId={buildingId}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getinfoObj({ building, realEstate, avyTmplActive }) {
  const addressStr = `${
    building.addresses?.[0]?.base?.split?.(",")?.[0] || "-"
  } ${
    building.addresses?.length > 1 ? `+ ${building.addresses.length - 1}` : ""
  }`;

  const infoObj = {
    Byggnadsinformation: [
      {
        title: "Namn",
        value: building.name,
      },
      {
        title: "Adresser",
        value: addressStr,
      },

      {
        title: "Fastighet",
        value: realEstate?.str_representation,
      },
      {
        title: "Synkad mot Avy-Tmpl",
        value: (
          <StatusLabel state={building?.avytmpl_id ? 0 : 3}>
            {building?.avytmpl_id ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        ),
        hidden: !avyTmplActive,
      },
      {
        title: "Standard nyckelbehörighet",
        value: !building?.default_key_permission ? (
          "-"
        ) : (
          <LinkedObject
            obj={building.default_key_permission}
            urlMethod={keyPermissionDetailUrl}
          />
        ),
      },
    ],
    Detaljer: [
      {
        title: "Byggnadsår",
        value: building.building_year || "-",
      },
      {
        title: "Renoveringsdatum",
        value: building.renovation_date || "-",
      },
      {
        title: "Typ av grund",
        value: building.foundation_type || "-",
      },
      {
        title: "Typ av stomme",
        value: building.building_frame_type || "-",
      },
      {
        title: "Typ av bjälklag",
        value: building.joists_type || "-",
      },
      {
        title: "Typ av fasad",
        value: building.facade_type || "-",
      },
      {
        title: "Typ av yttertak",
        value: building.outer_roof_type || "-",
      },
      {
        title: "Typ av fönster",
        value: building.window_type || "-",
      },
      {
        title: "Typ av ventilation",
        value: building.ventilation_type || "-",
      },
      {
        title: "Typ av dränering",
        value: building.drain_type || "-",
      },
      {
        title: "Typ av värme",
        value: building.heat_type || "-",
      },
      {
        title: "Typ av elförsörjning",
        value: building.electricity_type || "-",
      },
      {
        title: "Takhöjd",
        value: building.roof_height || "-",
      },

      {
        title: "Har hiss",
        value: building.has_elevator ? "Ja" : "Nej",
      },
    ],
  };

  return infoObj;
}
