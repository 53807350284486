import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";

//store, state
import {
  setActiveFormInstance,
  useAllPermissionCheck,
  useAnyPermissionCheck,
} from "../../store/base";
import {
  useInspectionErrandsPaginationCount,
  constants,
} from "../../store/inspectionErrands";
import useActiveFilters from "../../hooks/useActiveFilters";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

//components & styles
import Inspections from "./overview/Inspections";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

export default function MainInspectionErrandsOverview() {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { filteredRealEstates } = useActiveFilters();

  usePermissionRedirect([
    "allow_errand_inspections",
    "view_can_inspectionerrand",
  ]);

  const [activeCount] = useInspectionErrandsPaginationCount({
    realEstates: filteredRealEstates,
  });

  const SUB_PAGES = [
    {
      path: "/inspections",
      label: "Besiktningar",
      component: Inspections,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0} style={{ marginRight: 12 }}>
          Antal ärenden: {activeCount == null ? "Laddar..." : activeCount}
        </StatusLabel>
      </>
    );
  };

  React.useEffect(() => {
    dispatch(setActiveFormInstance({ storeName, data: {} }));
  }, []);

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Besiktningar`}
          {...{ subPages: subPageLinks, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
