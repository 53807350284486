import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  destroyPatchForm,
  constants,
  update,
  useParkingContractForm,
} from "../../../../store/parkingContracts";
import { updateActiveFormInstance } from "../../../../store/base";

export default function UpdateParkingContractCancellationForm({
  id,
  onCheckout,
  isOpen,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useParkingContractForm(method, id));

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={storeName}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter({ key, method })}
      submitCallback={onSubmit}
      title={"Uppdatera uppsägning"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
