import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PopupButton } from "../../components/Forms/Base/Buttons";
import { useAllPermissionCheck, useAnyPermissionCheck } from "../../store/base";
import { useApartmentPaginationCount, createUrl } from "../../store/apartments";
import Apartments from "./overview/Apartments";
import ApartmentCancellations from "./overview/ApartmentCancellations";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import RentIncreases from "./overview/RentIncreases";

export default function MainApartmentsOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  const { filteredRealEstates } = useActiveFilters();

  usePermissionRedirect(["view_can_baseobject"]);

  const canAdd = useAnyPermissionCheck(["add_can_baseobject"]);
  const canRentIncrease = useAllPermissionCheck([
    "change_can_baseobject",
    "change_can_contract",
  ]);

  let actions = [];

  if (canAdd) {
    actions.push({
      onClick: () => push(createUrl()),
      name: "Lägenhet",
    });
  }

  if (canRentIncrease) {
    actions.push({
      onClick: () => push(`/apartment-rent-increase`),
      name: "Hyreshöjning",
    });
  }

  const [activeCount] = useApartmentPaginationCount({
    filters: { realestate_ids: filteredRealEstates },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Objekt",
      component: Apartments,
    },
    {
      path: "/rent-increase-overview",
      label: "Hyreshöjningar",
      component: RentIncreases,
    },
    {
      path: "/cancellations",
      label: "Utflyttsöversikt",
      component: ApartmentCancellations,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal hyreslägenheter:{" "}
          {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <>
      {actions?.length > 0 && (
        <PopupButton title="Lägg till..." actions={actions} leftAligned />
      )}
    </>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Hyreslägenheter`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
