import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import useActiveFilters from "../../../hooks/useActiveFilters";
import ParkingSpecificationsTable from "../../../components/Tables/Parking/ParkingSpecifications/FullTable";

export default function ParkingSpecifications() {
  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Specifikationer</OverviewTitle>
        </OverviewTitleWrapper>

        <ParkingSpecificationsTable />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
