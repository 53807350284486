import * as React from "react";
import { constants } from "../../../../../store/roundingErrands";
import { PrimaryButton } from "../../../Base/Buttons";
import { DatePicker } from "src/components/Forms/Base/Fields";

export default ({ onSubmit }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200,
      }}
    >
      <DatePicker
        storeName={constants.STORE_NAME}
        method={"PATCH"}
        title="Utförandedatum"
        forceRequired
        fieldKey={`execute_end`}
      />
      <div>
        <PrimaryButton
          title="Registrera och slutför ärende"
          clicked={onSubmit}
        />
      </div>
    </div>
  );
};
