import * as React from "react";

// style, design
// import BaseTable from "src/components/Lists/Base/CompleteList/Table";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/serviceContracts";
import { getDateRangePicker } from "src/components/Tables/LeaseContractsWithCosts/FullTableNewVersion";
import moment from "moment";
import { getExpandedContentForContracts } from "src/components/Lists/Base/FinalTable/utils";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";
import { BADGE_TYPES } from "../../Badge/Badge";

export default function ServiceContractsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  withCosts = false,
}) {
  //TODO: Fix exports for this table
  // const exportParser = {
  //   state: (data) => data.state_display,
  //   recurrence_mode: (data) => data.recurrence_mode_display,
  //   notify_interval: (data) => durationParse(data.notify_interval),
  //   renew_interval: (data) => durationParse(data.renew_interval),
  // };

  // const exportExclusions = ["content_type"];

  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const filters = {
    Avtalsnummer: {
      queryKey: "id_number",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Hyresgäst: {
      queryKey: "tenant_name",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Aviseringsstatus: {
      queryKey: "lease_invoicing_active",
      type: "boolean",
      operators: OPERATOR_TEMPLATES.BOOLEAN,
    },
    Fastighet: {
      queryKey: "realestate_key",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Startdatum: {
      queryKey: "start_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    Slutdatum: {
      queryKey: "end_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <>
      <BaseTable
        title="Serviceavtal"
        tableId={tableId || "servicecontracts_full_table"}
        // isRowExpandable={withCosts} //correct?
        // getContentForExpandedRow={(row) => {
        //   getExpandedContentForContracts(row, {
        //     startDate,
        //     endDate,
        //   });
        // }}
        onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
