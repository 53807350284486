import { cloneDeep } from "lodash";
import {
  destroyForm,
  get,
  getAllFetchProcessName,
  getFiltered,
  getPagination,
  options,
  patch,
  post,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import { store } from "../../store";
import constants from "./constants";
import { createUploadHandler } from "./uploadHandler";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  // execution_ratings files

  if (cloned?.file?._tempData) {
    cloned.file = cloned.file?._tempData?.file_name;
  } else {
    delete cloned.file;
  }

  return cloned;
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
  preventDefaultToast,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return post({
    url: constants.POST_URL,
    constants,
    preProcess: fullPreProcess,
    processSuccess,
    processError,
    successCallback,
    uploadCallback: uploadCallback,
    errorCallback,
    forceData,
    preventDefaultToast,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
  preventDefaultToast,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return patch({
    url,
    constants,
    preProcess: fullPreProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback: uploadCallback,
    forceData,
    preventDefaultToast,
  });
};
