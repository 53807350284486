import * as React from "react";

import { 
    Confirm, 
    Price
} from "./Chapters";

export default (key, method) => {
    switch (key) {
        case "PRICE":
            return <Price method={method} />;
        default:
            return <Confirm />;
    }
};