import * as React from "react";

import { Component } from "./Chapters";

export default ({ key, method, storeName, patchIndex }) => {
  switch (key) {
    default:
      return (
        <Component
          method={method}
          storeName={storeName}
          patchIndex={patchIndex}
        />
      );
  }
};
