export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTAEVENTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTAEVENTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTAEVENTS",
  DESTROY_FORM: "DESTROY_FORM_BILLECTAEVENTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTAEVENTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTAEVENTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTAEVENTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTAEVENTS",
  RESET_STATE: "RESET_STATE_BILLECTAEVENTS",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTAEVENTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BILLECTAEVENTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_BILLECTAEVENTS",

  LIST_URL: "/accounting/general/report_event/list/",
  MARK_READ_URL: "/accounting/general/report_event/mark_read/",

  IMPORTANT_MESSAGES_URL: "/accounting/gateways/notifications/",

  SET_IMPORTANT_MESSAGES: "SET_IMPORTANT_MESSAGES_BILLECTAEVENTS",
  DELETE_IMPORTANT_MESSAGE: "DELETE_IMPORTANT_MESSAGE_BILLECTAEVENTS",

  STORE_NAME: "billectaEvents",
};
