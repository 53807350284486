import * as React from "react";

import {
  TextInput,
  TextField,
  ColorPicker,
  NonNestedSelect,
  RadioGroup,
} from "../../../Base/Fields";
// store, state
import { constants, usePipe } from "../../../../../store/pipes";
import {
  constants as homeQConstants,
  useIsHomeQAuthenticated,
} from "../../../../../store/homeQ";
import { useFilteredRealEstates } from "../../../../../store/realEstates";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import {
  buildQueryString,
  updateActiveFormInstance,
  useAllPermissionCheck,
  useFormField,
  useFormInstanceField,
} from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableProjects } from "../../../../../store/homeQ";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";
import { InfoBox } from "../../../../Displays";

export default ({ method, id }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [pipe] = usePipe(id);

  const { filteredRealEstates } = useActiveFilters();

  const realEstateQuery = buildQueryString({
    id__in: filteredRealEstates || undefined,
  });

  const homeQProjects = useSelector(
    (state) => state[homeQConstants.STORE_NAME].projects
  );

  const hasHomeQPerm = useAllPermissionCheck(["allow_homeq", "view_can_homeq"]);
  const [homeQActivated] = useIsHomeQAuthenticated();

  const projectFormInstructions = useFormField({
    storeName,
    method,
    fieldKey: "homeq_project_id",
  });

  const canSetProject =
    !!projectFormInstructions && !projectFormInstructions?._readOnly;

  const isHomeQPipe =
    useFormInstanceField({
      storeName,
      fieldKey: "is_homeq_pipe",
    }) || pipe?.is_homeq_pipe;
  const homeQProjectSelected = useFormInstanceField({
    storeName,
    fieldKey: "homeq_project_id",
  });

  React.useEffect(() => {
    if (hasHomeQPerm && homeQActivated) {
      dispatch(getAvailableProjects());
    }
  }, [hasHomeQPerm, homeQActivated]);

  const setHomeQProject = (id) =>
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { homeq_project_id: id },
      })
    );

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Projekt</OverviewTitle>
          <OverviewSubtitle>Ange information om projektet</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {hasHomeQPerm && homeQActivated && (
        <>
          <RadioGroup
            {...{
              storeName,
              fieldKey: "is_homeq_pipe",
              method,
              title: "Är detta ett HomeQ-projekt?",
              description:
                "HomeQ-projekt kan enbart innehålla annonser som är publicerade på HomeQ. Skapandet av annonser och kontraktshanteringen sköts i Pigello, övriga steg hanteras i HomeQ för bästa användarupplevelsen.",
            }}
            options={[
              {
                label: "Ja, detta är ett projekt för HomeQ-annonser",
                value: true,
              },
              {
                label: "Nej, detta projekt kommer ej innehålla HomeQ-annonser",
                value: false,
              },
            ]}
            defaultValue={false}
          />
          {isHomeQPipe &&
            homeQActivated &&
            canSetProject &&
            !homeQProjectSelected && (
              <NonConnectedSelect
                label="Om nyproduktion, välj projekt i HomeQ att koppla till"
                description="Nyproduktion fungerar annorlunda i HomeQ än övriga annonser. Om du är osäker på hur detta fungerar, kontakta HomeQ"
                helpText="OBS: Vid val av nyproduktionsprojekt måste även en fastighet att koppla till projektet väljas"
                onUpdate={(val) => {
                  setHomeQProject(val);
                }}
                value={parseInt(homeQProjectSelected)}
                choices={homeQProjects}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => parseInt(o.id)}
              />
            )}

          {isHomeQPipe && canSetProject && homeQProjectSelected && (
            <InfoBox
              title="Detta projekt är kopplat mot ett HomeQ-projekt"
              renderButton={{
                title: "Byt HomeQ-projekt",
                clicked: () => setHomeQProject(null),
                iconType: "sync",
              }}
            />
          )}
        </>
      )}

      <NonNestedSelect
        title="Tillhör fastighet"
        helpText={
          isHomeQPipe &&
          "OBS: Fastighet måste väljas om det är nyproduktion via HomeQ"
        }
        description="Ange en fastighet om projektet enbart ska tillhöra en viss fastighet. Om ingen fastighet anges kommer projektet vara globalt."
        storeName={storeName}
        method={method}
        fieldKey={"realestate"}
        fetchMethod={useFilteredRealEstates}
        fetchQueryString={realEstateQuery}
      />

      <ColorPicker
        title="Tema"
        storeName={storeName}
        method={method}
        fieldKey="color_code"
      />

      <TextInput
        title="Namn på projekt"
        storeName={storeName}
        method={method}
        fieldKey="name"
      />
      <TextField
        title="Beskrivning"
        storeName={storeName}
        method={method}
        fieldKey="description"
      />
    </>
  );
};
