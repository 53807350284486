import * as React from "react";

export default ({ text }) => {
  const content = () => {
    if (!text && text !== 0) {
      return "-";
    }

    return text;
  };

  return (
    <div>
      <>{content()}</>
    </div>
  );
};
