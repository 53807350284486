export const addBetaScript = () => {
  const scriptTag = document.createElement("script");
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=G-W9F2X8HM72`;
  scriptTag.async = true;

  const script = document.createElement("script");

  script.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-W9F2X8HM72');`;

  document.head.appendChild(script);
  document.head.appendChild(scriptTag);
};

export const addAlphaScript = () => {
  const scriptTag = document.createElement("script");
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=G-E1H1KE6XLT`;
  scriptTag.async = true;

  const script = document.createElement("script");

  script.innerHTML = ` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-E1H1KE6XLT');`;

  document.head.appendChild(script);
  document.head.appendChild(scriptTag);
};
