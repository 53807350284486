import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  detailUrl,
  useBuildingForm,
} from "../../../../store/buildings";
import {
  updateActiveFormInstance,
  useAllPermissionCheck,
} from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import TableSelectField from "../../Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import { ManyAddressField } from "../../Base/Fields";
import MediaField from "../../Base/Fields/MediaField";
import DateSelect from "../../Base/Fields/DateSelect";
import CheckField from "../../Base/Fields/CheckField";
import KeyPermissionsTable from "src/components/Tables/KeyPermissions/FullTable";

export default function BuildingModalForm({
  method,
  id,
  onCheckout,
  instance = {},
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = useBuildingForm(method, id);

  const canKeys = useAllPermissionCheck(["allow_keys", "view_can_keys"]);

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }

    return () => {
      return () => {
        dispatch(destroyPatchForm());
        dispatch(destroyPostForm());
      };
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  return (
    <Modal
      title={method === "POST" ? "Lägg till byggnad" : "Uppdatera byggnad"}
      closeFunction={() => onCheckout(false)}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Byggnad</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          {...{
            title: "Namn på byggnad",
            description: "Ange namnet på byggnaden.",
            method,
            storeName,
            fieldKey: "name",
          }}
        />
        <TableSelectField
          fieldKey="realestate"
          method={method}
          title="Fastighet"
          placeholder="Välj fastighet..."
          description="Välj fastighet som byggnaden tillhör."
          storeName={storeName}
          TableComponent={RealEstateTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <ManyAddressField
          title="Adresser"
          storeName={storeName}
          fieldKey="addresses"
          method={method}
        />
      </div>

      <div className="mb-6">
        <MediaField
          storeName={storeName}
          method={method}
          fieldKey={`image`}
          instructionsKey={`image`}
          allowedFormats={[".png", ".jpg", ".jpeg"]}
          title="Bild på byggnaden"
          fileKey="image"
        />
      </div>

      <hr />

      <div className="mb-6 text-base font-medium">Detaljer</div>

      <div className="grid grid-cols-2 gap-6 mb-6 ">
        <DateSelect
          title="Byggnadsår"
          storeName={storeName}
          fieldKey="building_year"
          method={method}
        />
        <DateSelect
          title="Renoveringsdatum"
          storeName={storeName}
          fieldKey="renovation_date"
          method={method}
        />
        <TextInputField
          title="Typ av grund"
          storeName={storeName}
          fieldKey={"foundation_type"}
          method={method}
        />
        <TextInputField
          title="Typ av bjälklag"
          storeName={storeName}
          fieldKey={"joists_type"}
          method={method}
        />
        <TextInputField
          title="Stomme"
          storeName={storeName}
          fieldKey={"building_frame_type"}
          method={method}
        />
        <TextInputField
          title="Fasad"
          storeName={storeName}
          fieldKey={"facade_type"}
          method={method}
        />
        <TextInputField
          title="Yttertak"
          storeName={storeName}
          fieldKey={"outer_roof_type"}
          method={method}
        />
        <TextInputField
          title="Fönster"
          storeName={storeName}
          fieldKey={"window_type"}
          method={method}
        />
        <TextInputField
          title="Ventilation"
          storeName={storeName}
          fieldKey={"ventilation_type"}
          method={method}
        />
        <TextInputField
          title="Avlopp"
          storeName={storeName}
          fieldKey={"drain_type"}
          method={method}
        />
        <TextInputField
          title="Värme"
          storeName={storeName}
          fieldKey={"heat_type"}
          method={method}
        />
        <TextInputField
          title="El"
          storeName={storeName}
          fieldKey={"electricity_type"}
          method={method}
        />
        <TextInputField
          title="Takhöjd"
          storeName={storeName}
          fieldKey={"roof_height"}
          method={method}
        />
      </div>

      <div className="mb-6">
        <CheckField
          title="Byggnaden har hiss"
          storeName={storeName}
          fieldKey={"has_elevator"}
          method={method}
        />
      </div>

      {canKeys && (
        <>
          <hr />

          <div className="mb-6 text-base font-medium">Nyckelbehörighet</div>

          <div className="grid grid-cols-2 gap-6 mb-6 ">
            <TableSelectField
              storeName={storeName}
              title="Nyckelbehörighet som standard"
              placeholder="Välj nyckelbehörighet"
              method={method}
              fieldKey="default_key_permission"
              TableComponent={KeyPermissionsTable}
            />
          </div>
        </>
      )}
    </Modal>
  );
}
