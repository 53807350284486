import * as React from "react";

import { ToolTipCell, LinkedObject, DateCell } from "../../Displays";
import { detailUrl as companyDetailUrl } from "../../../store/companies";

export default () => [
  {
    Header: "Bolag",
    accessor: "company",
    Cell: ({ value }) => (
      <LinkedObject obj={value} urlMethod={companyDetailUrl} />
    ),
  },
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Enhetskod",
    accessor: "entity_code",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Verifikationsserie skrivs över med ",
    accessor: "voucher_serie_override",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Ingen Moms",
    accessor: "vat_code_none",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "0% Moms",
    accessor: "vat_code_0",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "6% Moms",
    accessor: "vat_code_6",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "12% Moms",
    accessor: "vat_code_12",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "25% Moms",
    accessor: "vat_code_25",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
];
