export default [
  {
    title: "Inställning",
    key: "SETTING",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "day_in_month",
      "month_offset",
      "for_apartments",
      "for_industrial_premises",
      "for_parking_spots",
      "override_bookkeeping_account",
    ],
  },
  {
    title: "Statusar",
    key: "STATUSES",
    visited: true,
    hasError: false,
    fieldKeys: ["not_for_statuses", "only_for_statuses"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["id"],
  },
];
