export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title;
};

export const createUrl = () => {
  return `/report-errands/settings/create`;
};

export const overviewUrl = () => {
  return `/report-errands`;
};

export const detailUrl = () => {
  return `/report-errands`;
};
