import * as React from "react";

import { Confirm, Interval } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "INTERVAL":
      return <Interval method={method} />;
    default:
      return <Confirm />;
  }
};
