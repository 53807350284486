import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";
import { getIntervalStr } from "../Displays/InvoicingHelpers";
import { PrimaryButton } from "../Forms/Base/Buttons";
import { StatusLabel } from "../Lists/Base/CompleteList/styles";
import * as SC from "./styles";

export default function IMDSettingSummary({ setting, onCopy }) {
  return (
    <SC.Container>
      <SC.PrimaryCard>
        <OverviewTitleWrapper style={{ padding: "0 12px" }}>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Inställning för IMD-debitering</OverviewTitle>
            <OverviewSubtitle>{setting?.str_representation}</OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <SC.DataRow>
          <SC.DataTitle>Visa konsumtion på avier</SC.DataTitle>
          <SC.DataContent>
            {setting?.display_consumtion ? (
              <StatusLabel state={0}>Aktiv</StatusLabel>
            ) : (
              <StatusLabel state={6}>Inaktiv</StatusLabel>
            )}
          </SC.DataContent>
        </SC.DataRow>

        <SC.DataRow>
          <SC.DataTitle>Visa period för konsumtion på avier</SC.DataTitle>
          <SC.DataContent>
            {setting?.include_period_string ? (
              <StatusLabel state={0}>Aktiv</StatusLabel>
            ) : (
              <StatusLabel state={6}>Inaktiv</StatusLabel>
            )}
          </SC.DataContent>
        </SC.DataRow>

        <SC.DataRow>
          <SC.DataTitle>Antal perioders förskjuten debitering</SC.DataTitle>
          <SC.DataContent>{setting?.invoice_month_offset}</SC.DataContent>
        </SC.DataRow>

        {onCopy && (
          <SC.ActionBanner>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle style={{ color: "white" }} small>
                  Vill du göra ändringar?
                </OverviewTitle>
                <OverviewSubtitle style={{ color: "white" }}>
                  Aviseringsinställningar kan inte redigeras från denna vy då
                  det kan påverka andra avtal som använder sig av denna
                  inställning. Kopiera denna inställning och skapa ny för att
                  redigera inställningarna.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <PrimaryButton
                inverted
                title="Skapa ny inställning utifrån den valda"
                clicked={onCopy}
              />
            </div>
          </SC.ActionBanner>
        )}
      </SC.PrimaryCard>
    </SC.Container>
  );
}
