import cloneDeep from "lodash/cloneDeep";

export const ACTIONS = {
  SET_ACTIVE_CHAPTER: "SET_ACTIVE_CHAPTER",
  GO_TO_NEXT_STEP: "GO_TO_NEXT_STEP",
  GO_TO_PREVIOUS_STEP: "GO_TO_PREVIOUS_STEP",
  SET_CHAPTER_ERRORS: "SET_CHAPTER_ERROR",
};

const INITIAL_CHAPTER_STATE = {
  activeChapter: undefined,
  chapters: [],
  isFirstStep: true,
  isLastStep: false,
};

export default function reducer(state = INITIAL_CHAPTER_STATE, action) {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_CHAPTER:
      return handleSetActiveChapter(state, action);

    case ACTIONS.SET_CHAPTER_ERRORS:
      return handleSetChapterErrors(state, action);

    case ACTIONS.GO_TO_NEXT_STEP:
      return handleGoToNextStep(state);

    case ACTIONS.GO_TO_PREVIOUS_STEP:
      return handleGoToPreviousStep(state);

    case ACTIONS.RESET_STATE:
      return { ...action.payload.initialState };

    default:
      return state;
  }
}

const handleSetActiveChapter = (state, action) => {
  const stateClone = cloneDeep(state);
  const key = action.payload.key;

  const current = stateClone.chapters.find((c) => c.key === key);
  if (!current) return state;

  current.visited = true;
  stateClone.activeChapter = current;

  const chapterKeys = stateClone.chapters.map((c) => c.key);
  stateClone.isFirstStep = chapterKeys.indexOf(current.key) === 0;
  stateClone.isLastStep =
    chapterKeys.indexOf(current.key) === chapterKeys.length - 1;

  return {
    ...stateClone,
  };
};

const handleSetChapterErrors = (state, action) => {
  const keys = action.payload.keys;

  const chaptersCopy = [...state.chapters];
  chaptersCopy.forEach((chapter) => {
    chapter.hasError = keys.includes(chapter.key);
  });

  return {
    ...state,
    chapters: chaptersCopy,
  };
};
const handleGoToNextStep = (state) => {
  const stateClone = cloneDeep(state);
  const chapterKeys = stateClone.chapters.map((c) => c?.key);

  const index = chapterKeys.indexOf(stateClone.activeChapter?.key);
  const newActive = stateClone.chapters[index + 1];

  if (!newActive.key) {
    return state;
  }

  newActive.visited = true;

  stateClone.activeChapter = newActive;
  stateClone.isFirstStep = false;
  stateClone.isLastStep = index + 1 === chapterKeys.length - 1;

  return {
    ...stateClone,
  };
};
const handleGoToPreviousStep = (state) => {
  const stateClone = cloneDeep(state);
  const chapterKeys = stateClone.chapters.map((c) => c.key);
  const index = chapterKeys.indexOf(stateClone.activeChapter?.key);
  const newActive = stateClone.chapters[index - 1];

  if (!newActive.key) {
    return state;
  }

  newActive.visited = true;

  stateClone.activeChapter = newActive;
  stateClone.isFirstStep = index - 1 === 0;
  stateClone.isLastStep = index - 1 === chapterKeys.length - 1;

  return {
    ...stateClone,
  };
};
