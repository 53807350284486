import styled, { css } from "styled-components";
import addCircleIcon from "../../../assets/svg/material/add-circle-white.svg";
import arrowDown from "../../../assets/svg/material/keyboard-arrow-down-black.svg";
import arrowUp from "../../../assets/svg/material/keyboard-arrow-up-black.svg";

export const Container = styled.div`
  border-radius: 5px;
  border: thin solid #ddd;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  min-height: 400px;
  height: ${(p) => p.maxElementCount * 42 + 200}px;
  display: flex;
  overflow: hidden;
`;

export const AddRoleButton = styled.div`
  padding: 12px;
  align-self: flex-end;
  margin-top: auto;
  width: 100%;
  background-color: ${(p) => p.theme.colors.blue};
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const AddRoleIcon = styled.div`
  margin-left: 6px;
  height: 16px;
  width: 16px;
  background-image: url(${addCircleIcon});
  background-repeat: none;
  background-size: contain;
  background-position: center;
`;

export const RealEstateItem = styled.div`
  cursor: pointer;
  padding: 10px 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.25);
  background-color: #fff;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(p) =>
    !p.selected &&
    css`
      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight} !important;
      }
    `}

  ${(p) =>
    p.selected &&
    css`
      background-color: ${(p) => p.theme.colors.blue} !important;
      color: #fff;
    `}
`;

export const UserItem = styled.div`
  padding: 10px 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.25);
  background-color: #fff;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserInner = styled.div`
  flex: 2;
`;

export const Header = styled.div`
  padding: 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.65);
  background-color: rgba(240, 240, 240, 0.85);
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const UserHeader = styled.div`
  padding: 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.65);
  background-color: rgba(240, 240, 240, 0.85);
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RealEstateContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  ${RealEstateItem}:nth-child(odd) {
    background-color: rgba(241, 242, 246, 0.95);
  }
`;

export const UsersContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 100%;

  border-left: thin solid #ddd;

  ${UserItem}:nth-child(odd) {
    background-color: rgba(241, 242, 246, 0.95);
  }
`;

export const ArrowUpIcon = styled.div`
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${arrowUp});
`;

export const ArrowDownIcon = styled.div`
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${arrowDown});
`;
