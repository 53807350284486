import * as React from "react";

import { Confirm, Board } from "./Chapters";

export default ({ key, method, cooperative }) => {
  switch (key) {
    case "BOARD":
      return <Board {...{ method, cooperative }} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
