import * as React from "react";
import { useParams } from "react-router-dom";

import KeyDeviceForm from "../../../components/Forms/KeyDevice/FlowForm/FlowForm";

export default () => {
  const { objectType, objectId } = useParams();
  const apartment = objectType === "apartment" ? objectId : undefined;
  const industrial = objectType === "industrial-premises" ? objectId : undefined;
  const common = objectType === "common-area" ? objectId : undefined;

  return <KeyDeviceForm method="POST" apartment={apartment} industrialPremises={industrial} commonArea={common}/>;
};
