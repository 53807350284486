import moment from "moment";
import * as React from "react";
import { buildQueryString } from "../../store/base";
import { useMutationPagination } from "../../store/mutations";
import {
  BookOpenIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

export default function MutationsDisplay({ contentType, objectId }) {
  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const wrapperRef = React.useRef();

  React.useLayoutEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!wrapperRef.current) return;

    if (wrapperRef.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  const q = buildQueryString({
    _page: 1,
    _page_size: 20,
    order_by: "-time",
  });
  const [result, loading] = useMutationPagination(q);
  const r = result?.results;
  const count = r?.count;

  const latest = r?.[0];

  if (!latest) return null;
  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        className="cursor-pointer flex transition-all ease-in-out duration-300 items-center rounded p-1 text-[12px] text-sky-600 bg-slate-50  hover:bg-sky-100"
      >
        <BookOpenIcon width={12} className="mr-2" />
        Visa uppdateringar, senaste{" "}
        {moment(latest?.time).format("YYYY-MM-DD HH:mm")}{" "}
      </div>

      <div
        ref={wrapperRef}
        className={`z-50 fixed ${
          isOpen ? "-translate-x-full" : ""
        } transition-all w-[400px] h-screen ease-in-out duration-300 flex flex-col p-2 bg-slate-800 shadow-xl top-0 -right-[400px]`}
      >
        <div className="mt-2 text-slate-200 text-base font-medium border-b flex items-center justify-between">
          Uppdateringar
          <XCircleIcon
            className="cursor-pointer"
            onClick={() => setIsOpen(false)}
            width={24}
          />
        </div>

        <div className="mb-2 text-slate-400 text-sm">
          Visar uppdateringar på detta objekt i omvänd kronologisk ordning
        </div>

        <div className="overflow-y-scroll flex flex-col">
          {results?.map((r, i) => (
            <div className={`flex flex-col m-1 p-1 rounded-sm bg-slate-100`}>
              <div className="text-slate-500 text-[12px]">
                {moment(r.time).format("YYYY-MM-DD HH:mm")}
              </div>
              <div className="text-slate-700">
                {getCategoryDisplay(r.category)} av {r.owner?.id}
              </div>

              {r.category === 1 && Object.keys(r.changes || {})?.length > 0 && (
                <div
                  className={`w-full mt-1 bg-sky-100 shadow-inner  rounded p-2 flex flex-col `}
                >
                  {Object.keys(r.changes)?.map((key) => (
                    <div className="mb-1">
                      <div className="text-slate-500 text-[12px]">{key}</div>
                      <div className="text-slate-700 flex flex-wrap">
                        <span className="line-through">
                          {renderDynamicValue(r.changes[key]?.old) ||
                            "Ej angivet"}
                        </span>
                        <ChevronRightIcon className="mx-1" width={12} />
                        {renderDynamicValue(r.changes[key]?.new) ||
                          "Ej angivet"}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const getCategoryDisplay = (category) => {
  switch (category) {
    case 0:
      return "Skapades";
    case 1:
      return "Uppdaterades";
    case 2:
      return "Uppdaterades";
    case 3:
      return "Uppdaterades";
  }
};

const renderDynamicValue = (value) => {
  try {
    if (!isNaN(value)) return value;
    // check type and try to return a good looking value
    if (value && moment(value).isValid()) {
      if (value.includes("T")) {
        return moment(value).format("YYYY-MM-DD HH:mm");
      } else {
        return moment(value).format("YYYY-MM-DD HH:mm");
      }
    }

    return value;
  } catch (e) {
    alert(e, value);

    return value;
  }
};
