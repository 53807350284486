import * as React from "react";
import { useHistory } from "react-router";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import * as SharedStyles from "../../../components/sharedStyles";
import { useAllPermissionCheck } from "../../../store/base";

import { useConfigCenter } from "../../../store/configcenter";

import PageTitle from "../../Layouts/PageTitle/PageTitle";

export default () => {
  const { push } = useHistory();

  const [configCenter] = useConfigCenter();

  const canEditConfigCenter = useAllPermissionCheck([
    "view_can_configcenter",
    "change_can_configcenter",
  ]);

  const goToEdit = () => {
    push(`/configcenter/general/e-signing/edit/`);
  };

  const breadCrumbs = [
    {
      label: "E-Signering",
      url: "/configcenter/general/e-signing",
    },
  ];

  let actions = [];

  if (canEditConfigCenter)
    actions.push({
      name: "Redigera",
      onClick: () => goToEdit(),
    });

  return (
    <SharedStyles.BareLayoutWrapper fillWidth>
      {!configCenter && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="E-Signering" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>E-Signering</OverviewTitle>
          {actions?.length > 0 && (
            <PopupButton title="Hantera" actions={actions} />
          )}
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox style={{ marginBottom: 12 }}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Standard signerande part på digitala avtal
              </OverviewTitle>
              <OverviewSubtitle>
                Inställning som bestämmer vem som autofylls på digitala
                avtals-mallar för hyresvärdens räkning.{" "}
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <SharedStyles.InnerBox>
            {configCenter?.default_signatory_party_display || "-"}
            {configCenter?.default_signatory_user?.str_representation ? (
              <>
                <br />
                <strong>
                  {configCenter?.default_signatory_user?.str_representation}
                </strong>
              </>
            ) : (
              ""
            )}
          </SharedStyles.InnerBox>
        </SharedStyles.InnerBox>

      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
