export const ACTION_TYPES = {
  view: "Visa",
  add: "Lägga till",
  change: "Redigera",
  delete: "Ta bort",
};

export const getModalInformation = (title) => {
  switch (title) {
    case "reporterrand":
      return {
        title: "Felanmälning",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Teknisk förvaltning - Felanmälan",
        companyPermType: "allow_errand_faults",
        info: [
          {
            kind: "view",
            actions: [
              "Visa felanmälningar",
              "Visa felanmälningstyper",
              "Visa komponenter",
            ],
          },
          {
            kind: "add",
            actions: [
              "Skapa felanmälningar",
              "Skapa felanmälningstyper",
              "Skapa komponenter",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera felanmälningar",
              "Redigera felanmälningstyper",
              "Redigera komponenter",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort felanmälningar",
              "Ta bort felanmälningstyper",
              "Ta bort komponenter",
            ],
          },
        ],
      };

    case "inspectionerrand":
      return {
        title: "Besiktningar",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Teknisk förvaltning - Besiktningar",
        companyPermType: "allow_errand_inspections",
        info: [
          {
            kind: "view",
            actions: [
              "Visa besiktningar",
              "Visa besiktningstyper",
              "Visa komponenter",
            ],
          },
          {
            kind: "add",
            actions: [
              "Skapa besiktningar",
              "Skapa besiktningstyper",
              "Skapa komponenter",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera besiktningar",
              "Redigera besiktningstyper",
              "Redigera komponenter",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort besiktningar",
              "Ta bort besiktningstyper",
              "Ta bort komponenter",
            ],
          },
        ],
      };

    case "roundingerrand":
      return {
        title: "Ronderingar",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Teknisk förvaltning - Ronderingar",
        companyPermType: "allow_errand_roundings",
        info: [
          {
            kind: "view",
            actions: [
              "Visa ronderingar",
              "Visa ronderingstyper",
              "Visa komponenter",
            ],
          },
          {
            kind: "add",
            actions: [
              "Skapa ronderingar",
              "Skapa ronderingstyper",
              "Skapa komponenter",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera ronderingar",
              "Redigera ronderingstyper",
              "Redigera komponenter",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort ronderingar",
              "Ta bort ronderingstyper",
              "Ta bort komponenter",
            ],
          },
        ],
      };

    case "goverrand":
      return {
        title: "Myndighetskrav",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Teknisk förvaltning - Myndighetskrav",
        companyPermType: "allow_errand_gov",
        info: [
          {
            kind: "view",
            actions: [
              "Visa myndighetskrav",
              "Visa myndighetskravsscheman",
              "Visa komponenter",
            ],
          },
          {
            kind: "add",
            actions: [
              "Skapa myndighetskrav",
              "Skapa myndighetskravsscheman",
              "Skapa komponenter",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera myndighetskrav",
              "Redigera myndighetskravsscheman",
              "Redigera komponenter",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort myndighetskrav",
              "Ta bort myndighetskravsscheman",
              "Ta bort komponenter",
            ],
          },
        ],
      };

    case "Fastigheter":
      return {
        title: "Fastigheter",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa fastigheter"],
          },
          {
            kind: "add",
            actions: ["Skapa fastigheter"],
          },
          {
            kind: "change",
            actions: ["Redigera fastigheter"],
          },
          {
            kind: "delete",
            actions: ["Ta bort fastigheter"],
          },
        ],
      };

    case "BRF":
      return {
        title: "BRF",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Bostadsrättförening",
        companyPermType: "allow_brf",
        info: [
          {
            kind: "view",
            actions: ["Visa brf", "Visa andelstal"],
          },
          {
            kind: "add",
            actions: ["Skapa brf", "Lägga till andelstal"],
          },
          {
            kind: "change",
            actions: ["Redigera brf", "Redigera andelstal"],
          },
          {
            kind: "delete",
            actions: ["Ta bort brf", "Ta bort andelstal"],
          },
        ],
      };

    case "Företag":
      return {
        title: "Företag",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa företag"],
          },
          {
            kind: "add",
            actions: ["Skapa nya företag"],
          },
          {
            kind: "change",
            actions: ["Redigera företag"],
          },
          {
            kind: "delete",
            actions: ["Ta bort företag"],
          },
        ],
      };

    case "Objektshantering":
      return {
        title: "Objektshantering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: [
              "Visa hyreslägenheter",
              "Visa lokaler",
              "Visa block",
              "Visa planritningar",
              "Visa byggnader",
              "Visa bostadsrätter",
              "Visa hyreshöjning",
            ],
          },
          {
            kind: "add",
            actions: [
              "Lägg till nya hyreslägenheter",
              "Lägg till nya lokaler",
              "Lägg till nya block",
              "Lägg till planritningar",
              "Lägg till byggnader",
              "Lägg till bostadsrätter",
              "Lägga till hyreshöjning",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera hyreslägenheter",
              "Redigera lokaler",
              "Redigera block",
              "Redigera planritningar",
              "Redigera byggnader",
              "Redigera bostadsrätter",
              "Hantera bilder på objekt",
              "Hantera anteckningar på objekt",
              "Redigera hyreshöjning",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort hyreslägenheter",
              "Ta bort lokaler",
              "Ta bort block",
              "Ta bort planritningar",
              "Ta bort byggnader",
              "Ta bort bostadsrätter",
              "Ta bort hyreshöjning",
            ],
          },
        ],
      };

    case "Parkering":
      return {
        title: "Parkering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: [
              "Visa parkeringsområden",
              "Visa parkeringsspecifikationer",
              "Visa fordonsplatser",
              "Visa parkeringszoner",
            ],
          },
          {
            kind: "add",
            actions: [
              "Lägg till nya parkeringsområden",
              "Lägg till nya parkeringsspecifikationer",
              "Lägg till nya fordonsplatser",
              "Lägg till nya parkeringszoner",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera parkeringsområden",
              "Redigera parkeringsspecifikationer",
              "Redigera fordonsplatser",
              "Redigera parkeringszoner",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort parkeringsområden",
              "Ta bort parkeringsspecifikationer",
              "Ta bort fordonsplatser",
              "Ta bort parkeringszoner",
            ],
          },
        ],
      };

    case "Hyresgäster":
      return {
        title: "Hyresgäster",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa hyresgäster"],
          },
          {
            kind: "add",
            actions: ["Lägg till nya hyresgäster"],
          },
          {
            kind: "change",
            actions: ["Hantera hyresgäster", "Hantera hyresgästinställningar"],
          },
          {
            kind: "delete",
            actions: ["Ta bort hyresgäster"],
          },
        ],
      };

    case "Avtal":
      return {
        title: "Avtal",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: [
              "Visa hyresavtal",
              "Visa bilagor",
              "Visa övriga avtal",
              "Visa parkeringsavtal",
              "Visa serviceavtal",
              "Visa servicepartners",
              "Visa avtalsmallar",
              "Visa signeringsparter",
            ],
          },
          {
            kind: "add",
            actions: [
              "Lägg till hyresavtal",
              "Lägg till bilagor",
              "Lägg till övriga avtal",
              "Lägg till parkeringsavtal",
              "Lägg till serviceavtal",
              "Lägg till servicepartners",
              "Lägg till avtalsmallar",
              "Lägg till signeringsparter",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera hyresavtal",
              "Redigera bilagor",
              "Redigera övriga avtal",
              "Redigera parkeringsavtal",
              "Redigera serviceavtal",
              "Redigera servicepartners",
              "Redigera avtalsmallar",
              "Hantera signeringsparter",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort hyresavtal",
              "Ta bort bilagor",
              "Ta bort övriga avtal",
              "Ta bort parkeringsavtal",
              "Ta bort serviceavtal",
              "Ta bort servicepartners",
              "Ta bort avtalsmallar",
              "Ta bort signeringsparter",
            ],
          },
        ],
      };

    case "Mina sidor - admin":
      return {
        title: "Mina sidor - admin",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Mina sidor",
        companyPermType: "allow_tenantportal",
        info: [
          {
            kind: "view",
            actions: [
              "Visa inställningar för mina sidor",
              "Visa regler och information",
            ],
          },
          {
            kind: "add",
            actions: [
              "Konfigurera inställningar för mina sidor",
              "Lägg till regler och information",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera inställningar för mina sidor",
              "Hantera mina sidor",
              "Redigera regler och information",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Nollställ konfiguration för mina sidor",
              "Ta bort regler och information",
            ],
          },
        ],
      };

    case "Leads":
      return {
        title: "Leads",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Leads",
        companyPermType: "allow_market",
        info: [
          {
            kind: "view",
            actions: ["Visa leads", "Visa projekt"],
          },
          {
            kind: "add",
            actions: ["Lägg till leads", "Skapa nya projekt"],
          },
          {
            kind: "change",
            actions: ["Redigera leads", "Redigera projekt"],
          },
          {
            kind: "delete",
            actions: ["Ta bort leads", "Ta bort projekt"],
          },
        ],
      };

    case "Marknad":
      return {
        title: "Marknad",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Marknad",
        companyPermType: "allow_market",
        info: [
          {
            kind: "view",
            actions: ["Visa projekt", "Visa annonser", "Visa kravprofiler"],
          },
          {
            kind: "add",
            actions: [
              "Lägg till nya projekt",
              "Lägg till nya annonser",
              "Lägg till nya kravprofiler",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera projekt",
              "Redigera annonser",
              "Redigera kravprofiler",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort projekt",
              "Ta bort annonser",
              "Ta bort kravprofiler",
            ],
          },
        ],
      };

    case "HomeQ":
      return {
        title: "HomeQ",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "HomeQ",
        companyPermType: "allow_market",
        info: [
          {
            kind: "view",
            actions: ["Visa HomeQ annonser"],
          },
          {
            kind: "add",
            actions: ["Lägga till HomeQ annonser"],
          },
          {
            kind: "change",
            actions: ["Redigera HomeQ annonser"],
          },
          {
            kind: "delete",
            actions: ["Ta bort HomeQ annonser"],
          },
        ],
      };

    case "Bostadsförm. Stockholm":
      return {
        title: "Bostadsförm. Stockholm",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Bostadsförm. Stockholm",
        companyPermType: "allow_market",
        info: [
          {
            kind: "view",
            actions: ["Visa Bostadsförmedligen Stockholm annonser"],
          },
          {
            kind: "add",
            actions: ["Lägga till Bostadsförmedligen Stockholm annonser"],
          },
          {
            kind: "change",
            actions: ["Redigera Bostadsförmedligen Stockholm annonser"],
          },
          {
            kind: "delete",
            actions: ["Ta bort Bostadsförmedligen Stockholm annonser"],
          },
        ],
      };

    case "Användarhantering":
      return {
        title: "Användarhantering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa användare"],
          },
          {
            kind: "add",
            actions: ["Lägg till användare"],
          },
          {
            kind: "change",
            actions: ["Redigera användare"],
          },
          {
            kind: "delete",
            actions: ["Ta bort användare"],
          },
        ],
      };

    case "Anonymisering":
      return {
        title: "Anonymisering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Påverkar inte i dagsläget."],
          },
          {
            kind: "add",
            actions: ["Anonymisera en användare eller hyresgäst"],
          },
          {
            kind: "change",
            actions: ["Påverkar inte i dagsläget."],
          },
          {
            kind: "delete",
            actions: ["Ta bort anonymisering av en användare eller hyresgäst"],
          },
        ],
      };

    case "Konfigurationer":
      return {
        title: "Konfigurationer",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa konfigurationer under övriga inställningar."],
          },
          {
            kind: "change",
            actions: ["Redigera konfigurationer under övriga inställningar."],
          },
        ],
      };

    case "Nyckelhantering":
      return {
        title: "Nyckelhantering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa nycklar"],
          },
          {
            kind: "add",
            actions: ["Lägg till nycklar"],
          },
          {
            kind: "change",
            actions: ["Redigera nycklar"],
          },
          {
            kind: "delete",
            actions: ["Ta bort nycklar"],
          },
        ],
      };
    case "Insights":
      return {
        title: "Insights",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa insights"],
          },
          {
            kind: "add",
            actions: ["Lägg till insights"],
          },
          {
            kind: "change",
            actions: ["Redigera insights"],
          },
          {
            kind: "delete",
            actions: ["Ta bort insights"],
          },
        ],
      };

    case "Behörighetshantering":
      return {
        title: "Behörighetshantering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa användargrupper", "Visa behörigheter"],
          },
          {
            kind: "add",
            actions: ["Lägg till användargrupper", "Lägg till behörigheter"],
          },
          {
            kind: "change",
            actions: ["Redigera användargrupper", "Redigera behörigheter"],
          },
          {
            kind: "delete",
            actions: ["Ta bort användargrupper", "Ta bort behörigheter"],
          },
        ],
      };

    case "Fakturering/Avisering":
      return {
        title: "Fakturering/Avisering",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Billecta integration",
        companyPermType: "allow_billecta",
        info: [
          {
            kind: "view",
            actions: [
              "Visa enskilda fakturor",
              "Visa 3-fakturering",
              "Visa massfakturering",
              "Visa avtalsavisering",
              "Visa inbetalningar",
              "Visa omatchade inbetalningar",
              "Visa påminnelser",
              "Visa kreditfakturor",
              "Visa kravhantering",
              "Visa faktureringsinställningar",
              "Visa bokföringsinställningar",
              "Visa produkter",
              "Visa kostnadsställen",
              "Visa projekt",
              "Skapa SIE4 exporter",
              "Exportera kundreskontra",
              "Exportera rapporter",
              "Visa bokföringsintegrationer",
              "Visa bankgirointegration",
            ],
          },
          {
            kind: "add",
            actions: [
              "Skapa enskilda fakturor",
              "Skapa 3-fakturering",
              "Skapa massfakturor",
              "Sätt upp avtalsavisering",
              "Lägg till inbetalningar manuellt",
              "Matcha omatchade inbetalnngar",
              "Skicka påminnelser",
              "Skapa kreditfatkuror",
              "Aktivera kravhantering",
              "Sätt upp faktureringsinställningar",
              "Sätt upp bokföringsinställningar",
              "Skapa produkter",
              "Skapa kostnadsställen",
              "Skapa projekt",
              "Skapa SIE4 exporter",
              "Exportera kundreskontra",
              "Exportera rapporter",
              "Hantera bolagsinställningar för fakturering",
              "Hantera bankgirointegration",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera enskilda fakturor",
              "Redigera avtalsavisering",
              "Redigera ej attesterade kreditfakturor",
              "Redigera kravhantering",
              "Redigera faktureringsinställnigar",
              "Redigera bokföringsinställningar",
              "Redigera produkter",
              "Redigera kostnadsställen",
              "Redigera projekt",
              "Skapa SIE4 exporter",
              "Exportera kundreskontra",
              "Exportera rapporter",
              "Hantera bolagsinställningar för fakturering",
              "Hantera bankgirointegration",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort ej attesterade fakturor",
              "Ta bort omatchade inbetalningar",
              "Ta bort ej attesterade kreditfakturor",
              "Ta bort kravhantering",
              "Ta bort produkter",
              "Ta bort kostnadsställen",
              "Ta bort projekt",
              "Inaktivera bankgirointegration",
            ],
          },
        ],
      };

    case "E-signering":
      return {
        title: "E-singering",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "E-signering med Scrive",
        companyPermType: "allow_scrive",
        info: [
          {
            kind: "view",
            actions: ["Visa en e-signeringsprocess"],
          },
          {
            kind: "add",
            actions: ["Starta e-signeringsprocess"],
          },
          {
            kind: "change",
            actions: ["Redigera påbörjad e-signeringsprocess"],
          },
          {
            kind: "delete",
            actions: ["Avsluta e-signeringsprocess"],
          },
        ],
      };

    case "Fastdok":
      return {
        title: "Fastighetsägarna dokument",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Fastighetsägarna dokument",
        companyPermType: "allow_fastdoc",
        info: [
          {
            kind: "view",
            actions: ["Visa fastighetsägarna dokument"],
          },
          {
            kind: "add",
            actions: ["Använda fastighetsägarna dokument"],
          },
          {
            kind: "change",
            actions: ["Redigera fastighetsägarna dokument"],
          },
          {
            kind: "delete",
            actions: ["Ta bort fastighetsägarna dokument"],
          },
        ],
      };

    case "Driftnetto (Fortnox)":
      return {
        title: "Driftnetto (Fortnox)",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Fortnox",
        companyPermType: "allow_fortnox",
        info: [
          {
            kind: "view",
            actions: ["Visa driftnetto"],
          },
          {
            kind: "add",
            actions: ["Konfiguera integration mot Fortnox "],
          },
          {
            kind: "change",
            actions: ["Redigera konfiguration"],
          },
          {
            kind: "delete",
            actions: ["Ta bort konfiguration"],
          },
        ],
      };

    case "IMD":
      return {
        title: "IMD",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "IMD",
        companyPermType: "allow_imd",
        info: [
          {
            kind: "view",
            actions: ["Visa IMD"],
          },
          {
            kind: "add",
            actions: ["Lägga till IMG saker"],
          },
          {
            kind: "change",
            actions: ["Redigera IMD saker"],
          },
          {
            kind: "delete",
            actions: ["Ta bort IMD saker"],
          },
        ],
      };
  }
};
