import * as React from "react";

// style, design
import {
  ToolTipCell,
  LinkedObject,
  DateCell,
  DurationCell,
  MultiEntryLinks,
} from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import SimpleIntervalFilter from "src/components/Lists/Base/CompleteList/Filters/IntervalFilter";
import StateFilter, {
  sortInvoicingActive,
  sortEndDate,
} from "./Filters/StateFilter";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as industrialDetailUrl } from "../../../store/industrialPremises";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

import InvoiceFilter from "./Filters/InvoiceFilter";
import { getCostColumns } from "src/components/Lists/utils/costCalculations";

export default (
  startDate,
  endDate,
  withCosts,
  hasBillectaViewPermission,
  useSqm,
  showMonthlyCosts,
  useSpecifiedPeriod
) => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }) => {
        let label = row.original.state_display;
        const state = row.original.state;
        if (
          [6, 7].includes(state) &&
          !row.original.closed_signed &&
          row.original.uses_cancellation_e_signing
        ) {
          label += ` (Ej signerat)`;
        }
        const isDraft = row.original.draft;

        if (isDraft) {
          label = "Utkast";
        }

        return <StatusLabel state={state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Avtalsnummer",
      accessor: "id_number",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Hyresgäst",
      accessor: "tenant.str_representation",
      id: "tenant_name",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.tenant} urlMethod={tenantDetailUrl} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Övriga hyresgäster",
      id: "bi_tenant_name",
      accessor: "bi_tenants",
      Cell: ({ row }) => {
        let mapping = [];
        let restLength = 0;

        const biTenants = row.original.bi_tenants ?? [];
        biTenants.forEach((b) => {
          mapping.push({ obj: b, urlMethod: tenantDetailUrl });
        });

        if (mapping.length > 2) {
          restLength = mapping.length - 2;
          mapping = mapping.slice(0, 2);
        }

        return <MultiEntryLinks mappings={mapping} restLength={restLength} />;
      },
      disableSortBy: true,
      disableFilters: true,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Block",
      accessor: "block.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastighet",
      accessor: "realestate.str_representation",
      id: "realestate.key",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Hyresobjekt",
      accessor: "industrial_premises_list",
      Cell: ({ row }) => {
        let searchStrArr = [];

        const apartments = row.original?.apartments;
        const industrials = row.original?.industrial_premises_list;

        if (apartments?.length) {
          apartments.forEach((a) => {
            searchStrArr.push(a.str_representation);
          });
        }

        if (industrials?.length) {
          industrials.forEach((a) => {
            searchStrArr.push(a.str_representation);
          });
        }

        searchStrArr = searchStrArr.join(", ");

        let mapping = [];
        let restLength = 0;

        if (apartments?.length) {
          apartments.forEach((a) => {
            mapping.push({ obj: a, urlMethod: apartmentDetailUrl });
          });
        }

        if (industrials?.length) {
          industrials.forEach((a) => {
            mapping.push({ obj: a, urlMethod: industrialDetailUrl });
          });
        }

        if (mapping.length > 2) {
          restLength = mapping.length - 2;
          mapping = mapping.slice(0, 2);
        }

        return <MultiEntryLinks mappings={mapping} restLength={restLength} />;
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Area (m2)",
      accessor: "total_area",
      alignRight: true,
      Cell: ({ value }) => (
        <ToolTipCell text={(value || 0).toLocaleString("sv")} />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Startdatum",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Slutdatum",
      accessor: "closed_date",
      id: "end_date",
      Cell: ({ row }) => (
        <>
          {row.original.closed_date ? (
            <DateCell date={row.original.closed_date} />
          ) : row.original.renewed_to ? (
            <>
              <DateCell date={row.original.renewed_to} />
              <span> (Förlängt)</span>
            </>
          ) : (
            <DateCell date={row.original.end_date} />
          )}
        </>
      ),
      Filter: SimpleDateFilter,
      filter: "betweenDates",
      sortType: sortEndDate,
    },
    {
      Header: "Uppsägningstid",
      accessor: "notify_interval",
      Cell: ({ row }) => (
        <DurationCell
          durationStamp={row.original.notify_interval}
          shadowDate={row.original.notify_time}
        />
      ),
      Filter: SimpleIntervalFilter,
      filter: "text",
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Förlängningstid",
      accessor: "renew_interval",
      Cell: ({ row }) => {
        const forever = row.original.renew_indefinetely;
        if (forever) {
          return "Tillsvidare";
        }
        return <DurationCell durationStamp={row.original.renew_interval} />;
      },
      Filter: SimpleIntervalFilter,
      filter: "text",
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Skapat av",
      accessor: "owner.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  // if (withCosts) {
  //   cols.splice(0, 0, {
  //     Header: "",
  //     id: "__expander",
  //     Cell: ({ row }) => {
  //       return (
  //         <span {...row.getToggleRowExpandedProps()}>
  //           {row.isExpanded ? <span>▼</span> : <span>{">"}</span>}
  //         </span>
  //       );
  //     },
  //     disableFilters: true,
  //     disableGlobalFilter: true,
  //     disableSortBy: true,
  //   });
  // }

  if (hasBillectaViewPermission) {
    cols.splice(3, 0, {
      Header: "Aviseringsstatus",
      accessor: "lease_invoicing_active",
      Cell: ({ value }) => (
        <StatusLabel
          state={
            value === true ? 1 : value === null ? 6 : value === false ? 3 : 10
          }
        >
          {value === true
            ? "Konfigurerad"
            : value === null
            ? "Ej konfigurerad"
            : value === false
            ? "Pausad"
            : "Okänd"}
        </StatusLabel>
      ),
      Filter: InvoiceFilter,
      filter: "textExact",
      sortType: sortInvoicingActive,
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    });
  }

  if (withCosts) {
    return cols.concat(
      getCostColumns(
        startDate,
        endDate,
        [
          "CATEGORY_MEMBER_FEE",
          "CATEGORY_PARKING",
          "CATEGORY_IMD",
          "CATEGORY_DEPOSIT",
        ],
        useSqm,
        showMonthlyCosts,
        useSpecifiedPeriod
      )
    );
  }

  return cols;
};
