import * as React from "react";

import * as SC from "./styles";
import { FiXSquare, FiCheckSquare } from "react-icons/fi";
import StandardModal from "../../Modals/StandardModal";
import { OverviewTitle, OverviewTitleWrapper } from "../OverviewInfo/styles";
import { InnerBox } from "../../sharedStyles";
import MediaDisplay from "../Images/MediaDisplay";
import theme from "../../../theme";

export default function ChecklistSummary({ checklistRows = [], notOkIds }) {
  const [detailOpen, setDetailOpen] = React.useState(false);
  return (
    <>
      <StandardModal
        isOpen={detailOpen}
        closeFunction={() => setDetailOpen(false)}
        title={"Detaljer"}
        withActionBar
        actionBarCancelTitle="Stäng"
      >
        <OverviewTitleWrapper>
          <OverviewTitle small>{detailOpen?.title}</OverviewTitle>
        </OverviewTitleWrapper>
        <InnerBox
          style={{ marginBottom: 24 }}
          dangerouslySetInnerHTML={{ __html: detailOpen?.description }}
        />

        {detailOpen?.files?.length > 0 && (
          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Instruktionsfiler för kontroll
              </OverviewTitle>
            </OverviewTitleWrapper>
            <MediaDisplay files={detailOpen?.files} />
          </InnerBox>
        )}
      </StandardModal>

      <SC.ChecklistSummaryContainer>
        {checklistRows.map((row) => (
          <SC.ChecklistSummaryRow onClick={() => setDetailOpen(row)}>
            <SC.ChecklistDescription>
              <SC.ChecklistTitle>{row.title}</SC.ChecklistTitle>
            </SC.ChecklistDescription>
            {notOkIds?.includes(row.id) ? (
              <FiXSquare color={theme.colors.red} size="24" />
            ) : (
              <FiCheckSquare color={theme.colors.green} size="24" />
            )}
          </SC.ChecklistSummaryRow>
        ))}
      </SC.ChecklistSummaryContainer>
    </>
  );
}
