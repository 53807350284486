import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import PrimaryBtn from "../../components/Forms/Base/Buttons/PrimaryBtn";
import IndexTableModalForm from "../../components/Forms/IndexTable/ModalForm/ModalForm";
import IndexTablesTable from "../../components/Tables/IndexTable/FullTable";
import { DetailInnerWrapper } from "../../components/sharedStyles";

export default function IndexationTablesTable() {
  const [createOpen, setCreateOpen] = React.useState(false);

  return (
    <>
      {createOpen && (
        <IndexTableModalForm
          method="POST"
          closeFunction={() => setCreateOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Anpassade tabeller</OverviewTitle>
            <OverviewSubtitle style={{ marginRight: 24 }}>
              Dessa tabeller används på indexinställningar för att anpassa
              uppräkningen.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <PrimaryBtn onClick={() => setCreateOpen(true)}>
            Skapa ny tabell
          </PrimaryBtn>
        </OverviewTitleWrapper>

        <IndexTablesTable />
      </DetailInnerWrapper>
    </>
  );
}
