export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SFTP_CATALOGUE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SFTP_CATALOGUE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SFTP_CATALOGUE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SFTP_CATALOGUE",
  DESTROY_FORM: "DESTROY_FORM_SFTP_CATALOGUE",
  SET_FORM_ERROR: "SET_FORM_ERROR_SFTP_CATALOGUE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SFTP_CATALOGUE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SFTP_CATALOGUE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SFTP_CATALOGUE",
  RESET_STATE: "RESET_STATE_SFTP_CATALOGUE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SFTP_CATALOGUE",
  REMOVE_OBJECT: "REMOVE_OBJECT_SFTP_CATALOGUE",
  CLEAR_FETCHED: "CLEAR_FETCHED_SFTP_CATALOGUE",

  LIST_URL: "/accounting/general/sftpcatalogue/list/",
  POST_URL: "/accounting/general/sftpcatalogue/",
  PATCH_URL: "/accounting/general/sftpcatalogue/",
  GET_URL: "/accounting/general/sftpcatalogue/",

  STORE_NAME: "SFTPCatalogue",
};
