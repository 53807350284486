import * as React from "react";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";
import Modal from "../../Base/Modals/Modal";

import {
  useIndexTableRowForm,
  constants,
  create,
  update,
} from "../../../../store/indexTableRow";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveFormInstance } from "../../../../store/base";
import DateSelect from "../../Base/Fields/DateSelect";

export default function IndexTableRowModalForm({
  method,
  id,
  closeFunction,
  instance,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { push } = useHistory();
  const formLoaded = useIndexTableRowForm(method, id);

  const [actionLoading, setActionLoading] = React.useState(false);

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  const onSubmit = () => {
    setActionLoading(true);

    if (method === "POST") {
      dispatch(
        create({
          successCallback: (_, returned) => {
            setActionLoading(false);
            closeFunction();
          },
          errorCallback: () => {
            setActionLoading(false);
          },
        })
      );
    } else {
      dispatch(
        update({
          id,
          successCallback: (_, returned) => {
            setActionLoading(false);
            closeFunction();
          },
          errorCallback: () => {
            setActionLoading(false);
          },
        })
      );
    }
  };

  const loading = !formLoaded || actionLoading;
  return (
    <Modal
      closeFunction={closeFunction}
      title={
        method === "POST" ? "Skapa ny indextabell" : "Uppdatera indextabell"
      }
      acceptTitle={"Spara"}
      onAccept={onSubmit}
    >
      {loading && <OverlaySpinner />}

      <div className="mb-6 grid grid-cols-2 gap-6">
        <DateSelect
          title="Gäller för månad"
          fieldKey="for_date"
          showMonthYearPicker
          {...{ method, storeName }}
        />
        <TextInputField
          title="Värde"
          fieldKey="value"
          {...{ method, storeName }}
        />
      </div>
    </Modal>
  );
}
