export const constructStrRep = (obj) => {
  return obj.premises_id || "Lokal";
};
export const detailUrl = ({ id }) => {
  return `/industrial-premises/detail/${id}`;
};
export const overviewUrl = () => {
  return `/industrial-premises`;
};
export const createUrl = () => {
  return `/industrial-premises/create`;
};
