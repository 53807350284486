import * as React from "react";
import { useAsyncDebounce } from 'react-table'

export default function SimpleNumberFilter({column:{filterValue, setFilter}}) {
  
    const [value, setValue] = React.useState(filterValue)
    const onChange = useAsyncDebounce(value => {
      setFilter(value || undefined)
    }, 200)

    return (
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value || undefined);
          onChange(e.target.value || undefined);
        }}
        placeholder={"GO DESIGN INTERVAL?"}
      />
    )
  }