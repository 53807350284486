import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { EmailTag, InfoBox, PhoneTag } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useTenant, constants } from "../../../store/tenants";

import { constants as anonymizationConstants } from "../../../store/anonymization";
import {
  post as postAction,
  deleteObject as deleteAction,
} from "../../../store/base/store/actions";

import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import EditModal from "../../../components/Forms/Tenant/ChapterForm/ModalForm";
import {
  buildQueryString,
  updateActiveFormInstance,
  useAllPermissionCheck,
  usePermissionCheck,
} from "../../../store/base";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { useDispatch } from "react-redux";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useFilteredNotes } from "../../../store/notes";
import {
  getMemberDisplay,
  getMemberState,
} from "src/components/Tables/Tenants/listDefs";
import TenantPortalStatusCard from "../../../components/Details/TenantPortalSettings/StatusCard";

const getContactPerson = (member) => {
  let currentTenantContact;

  if (member?.user?.first_name) {
    currentTenantContact = member.user.first_name;
  }
  if (member?.user?.last_name) {
    if (currentTenantContact) {
      currentTenantContact = `${currentTenantContact} ${member.user.last_name}`;
    } else {
      currentTenantContact = member.user.last_name;
    }
  }
  if (!currentTenantContact) {
    currentTenantContact = "-";
  }

  return currentTenantContact;
};

export default function MemberOverview() {
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const { tenantId } = useParams();

  const canAddUser = usePermissionCheck("add_can_user");
  const canEditTenant = usePermissionCheck("change_can_tenant");
  const canEditUser = usePermissionCheck("change_can_user");
  const canHandleTenantPortal = useAllPermissionCheck([
    "allow_tenantportal",
    "view_can_tenantportal",
    "change_can_tenantportal",
  ]); //CUSTOMERPERMISSION?

  const tenantPortalActive = canAddUser && canHandleTenantPortal;

  const [member] = useTenant(tenantId);

  const canToggleAnonymous = true && member;

  const [isAnonymous, setIsAnonymous] = React.useState(
    member?.user?.anonymization
  );

  React.useEffect(() => {
    setIsAnonymous(member?.user?.anonymization);
  }, [!!member?.user?.anonymization]);

  const notesQ = buildQueryString({
    id__in: member?.user?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const kind = member?.user?.corporate_name ? "corporate" : "private";

  const onAnonymize = () => {
    if (!isAnonymous) {
      dispatch(
        postAction({
          url: anonymizationConstants.POST_URL,
          constants: anonymizationConstants,
          forceData: { user: { id: member.user.id } },
          successCallback: (_, data) => setIsAnonymous({ id: data.id }),
        })
      );
    } else {
      dispatch(
        deleteAction({
          instance: isAnonymous,
          constants: anonymizationConstants,
          successCallback: () => setIsAnonymous(undefined),
        })
      );
    }
  };

  const onEdit = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: member,
      })
    );
    setEditOpen(true);
  };

  return (
    <>
      {editOpen && (
        <EditModal
          method="PATCH"
          isMember
          id={member?.id}
          onCheckout={() => setEditOpen(false)}
          kind={kind}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "60%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              {canEditTenant && canEditUser && (
                <PrimaryButton title="Redigera" clicked={onEdit} />
              )}
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getInfoObj(member)} />

            {canToggleAnonymous && (
              <TextButton
                red
                title={
                  isAnonymous ? "Stäng av anonymisering" : "Anonymisera medlem"
                }
                clicked={onAnonymize}
                extraStyle={{ marginLeft: "8px" }}
              />
            )}
          </DetailPageBox>

          <div style={{ width: "40%", alignSelf: "flex-start" }}>
            <DetailPageBox>
              <Notes
                notes={notes}
                contentType="accounts.user"
                objectId={member?.user?.id}
              />
            </DetailPageBox>

            {tenantPortalActive && member && (
              <TenantPortalStatusCard user={member?.user} />
            )}
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj(member) {
  const infoObj = {
    Hyresgäst: [
      {
        title: "Status",
        value: (
          <StatusLabel
            contentType="tenant"
            state={getMemberState(member?.state)}
          >
            {getMemberDisplay(member?.state)}
          </StatusLabel>
        ),
      },
      {
        title: "Typ av medlem",
        value: member?.user?.corporate_name ? "Bolag" : "Privatperson",
      },
      { title: "Kontaktperson", value: getContactPerson(member) },
      {
        title: "Medlemsnummer",
        value: member?.customer_id,
      },

      { title: "E-post", value: <EmailTag mail={member?.user?.email} /> },
      { title: "Telefon", value: <PhoneTag phone={member?.user?.phone} /> },
      {
        title: member?.user?.corporate_name ? "Orgnr" : "Personnummer",
        value: member?.user?.legal_id || "-",
      },

      {
        title: "Tillhör fastigheter",
        value:
          member?.user?.realestates?.length > 0
            ? member?.user?.realestates?.map((re, idx) => {
                return (
                  <>
                    <div>{re.str_representation}</div>
                    {idx < member.user.realestates.length - 1 && <br />}
                  </>
                );
              })
            : "Ingen specifik",
      },
    ],
  };

  return infoObj;
}
