import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import DigitalDocContainer from "../../components/DigitalDoc/Container";
import { fastDocTemplateName } from "../../components/DigitalDoc/TemplateSelector/TemplateContainer";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import {
  update,
  useEditabledoc,
  constants,
  detailUrl,
  destroyPatchForm,
  performDocAndFastDocValidation,
} from "../../store/editabledocs";
import {
  handleDocError,
  performPageFieldsValidation,
} from "../../store/editabledocs/utils";

export default function EditTemplate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { editableDocId } = useParams();

  const [editableDoc] = useEditabledoc(editableDocId);
  const { push } = useHistory();

  const editableDocFormInstance = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "id",
  });

  React.useEffect(() => {
    if (editableDocFormInstance != null) {
      return;
    }

    if (editableDoc) {
      // to default and show which template that has been chosen
      if (editableDoc.fastdoc_id) {
        editableDoc._chosenTemplate = {
          id: editableDoc.fastdoc_id,
          pay: false,
          name: fastDocTemplateName,
        };
      }

      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: editableDoc,
        })
      );
    }
  }, [editableDoc]);

  const successCallback = (_, returned) => {
    setLoading(false);

    dispatch(destroyPatchForm(true));
    push(detailUrl({ id: returned.id }));
  };

  const errorCallback = (data, returnedData) => {
    setLoading(false);
    handleDocError(data, returnedData);
  };

  const preProcess = (data) => {
    let clone = performPageFieldsValidation(
      performDocAndFastDocValidation(cloneDeep(data))
    );

    return clone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id: editableDocId,
        preProcess,
        successCallback,
        errorCallback,
        preventDefaultToast: true,
      })
    );
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      <DigitalDocContainer
        {...{
          method: "PATCH",
          id: editableDocId,
          onSubmit,
          isTemplateEditor: true,
        }}
      />
    </>
  );
}
