import * as React from "react";
import { useFormInstanceField } from "../../../../../store/base";

import { constants } from "../../../../../store/invoicingSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { TextButton } from "../../../Base/Buttons";
import {
  NumberInput,
  RadioGroup,
  Select,
  TextField,
  TextInput,
} from "../../../Base/Fields";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";

export default ({ method, onGoBackToSelect }) => {
  const storeName = constants.STORE_NAME;

  const intervalMode = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.mode",
  });

  const autoDepositHandling = useFormInstanceField({
    storeName,
    fieldKey: "automatic_deposit_handling",
  });

  return (
    <>
      <TextButton
        title="Avbryt och gå tillbaka till val av existerande inställning"
        clicked={onGoBackToSelect}
        iconType="arrow-back"
        red
      />

      <hr />
      <OverviewTitleWrapper style={{ scrollMargin: 50 }}>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Aviseringsinställningar</OverviewTitle>
          <OverviewSubtitle>
            Grundläggande inställningar för avisering
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        title="Titel på inställning"
        {...{ storeName, method, fieldKey: "title" }}
      />

      <TextField
        title="Meddelande på fakturor"
        {...{ storeName, method, fieldKey: "message" }}
      />

      <RadioGroup
        title="Kräv godkännande av kostnader för avisering"
        description="Om denna inställning är aktiv krävs det att en användare som har tillgång till aviseringsmodulen (Pigello Hyra) godkänner ändringar eller tillägg av kostnader och rabatter. Exempel: En användare som har rätt att redigera avtalet lägger till en rabatt på 200 SEK. Denna rabatt kommer ej att tas hänsyn till i aviseringen innan en användare som har tillgång till Pigello Hyra har godkänt rabatten."
        {...{ storeName, method, fieldKey: "require_cost_accept" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      <RadioGroup
        title="Sammanför automatiskt fakturor till samma kund"
        description="Om detta är aktivt kommer fakturor som genereras med denna inställning automatiskt slås ihop med andra icke-attesterade fakturor till samma kund."
        {...{ storeName, method, fieldKey: "auto_merge" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={true}
      />

      <RadioGroup
        title="Autoattestera fakturor"
        description="Om detta är aktivt kommer alla fakturor som genereras att automatiskt attesteras och bokföras så fort de skapas."
        {...{ storeName, method, fieldKey: "auto_attest" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      <RadioGroup
        title="Lägg till vilket objekt debiteringsrader kommer ifrån"
        description='Om detta är aktivt kommer debiteringsrader att prefixas med objektsnumret, t.ex. kommer hyran från lägenhet 1002 (2 RoK) att skrivas ut som "1002 (2 RoK) - Hyra'
        {...{ storeName, method, fieldKey: "prefix_object_costs" }}
        forceHorizontalToolTipPlacement="right"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      <TextInput
        title="Antal perioder innan hyreshöjningar slås ihop med hyra"
        description="Vid en hyreshöjning skapas en ny debiteringsrad temporärt innan den slås ihop med hyran. Ange hur många perioder hyreshöjningen ska ligga på en separat debiteringsrad"
        {...{ storeName, method, fieldKey: "invoices_with_rent_increase_rows" }}
      />

      <RadioGroup
        title="Automatisk depositionshantering"
        description="Om detta är aktivt kommer en depositionsfaktura automatiskt att skapas upp vid signering av avtal under denna inställning."
        {...{ storeName, method, fieldKey: "automatic_deposit_handling" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      {autoDepositHandling && (
        <NumberInput
          title="Andel av bashyra som ska betalas i deposition som standard (%)"
          {...{
            storeName,
            fieldKey: "deposit_quota",
            method,
          }}
        />
      )}

      <hr />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Generering och utskick</OverviewTitle>
          <OverviewSubtitle>
            Inställningar som berör när och hur fakturor genereras och skickas
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <Select
        title="Typ av faktureringsintervall"
        description={`Typ av intervall för när fakturor ska genereras och skickas. Vid månadsvis fakturering väljs t.ex. "Per månad".`}
        {...{ storeName, method, fieldKey: "interval_setting.mode" }}
      />

      {/* Per month */}
      {intervalMode === 0 && (
        <>
          <NumberInput
            title="Månadsintervall"
            description={`Ange i vilket månadsintervall fakturor ska genereras. Ska en faktura genereras varje månad anges 1, varannan månad 2 osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i varje månad som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      {/* Quarterly */}
      {intervalMode === 1 && (
        <>
          <NumberInput
            title="Kvartalsintervall"
            description={`Ange i vilket kvartalsintervall fakturor ska genereras. Ska en faktura genereras varje kvartal anges "1", varannat kvartal "2" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          <NumberInput
            title="Månad i kvartal som faktura ska genereras"
            description={`Ange vilken månaden i kvartalet som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas 1:a månaden kvartalet så anges "1".`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.on_month",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      {/* yearly */}
      {intervalMode === 2 && (
        <>
          <NumberInput
            title="Årsintervall"
            description={`Ange i vilket årsintervall fakturor ska genereras. Ska en faktura genereras varje år anges "1", varannat år "2" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          <NumberInput
            title="Månad som faktura ska genereras"
            description={`Ange vilken månaden på året som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas i Januari så anges "1", Juli så anges "7" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.on_month",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      <Select
        title="Faktureringsperiod"
        filterChoices={(c) => {
          if ([1, 2].includes(intervalMode)) {
            return c.v === -1 ? false : true;
          }
          return true;
        }}
        {...{ storeName, method, fieldKey: "interval_setting.invoice_period" }}
      />

      <hr />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Bokföring</OverviewTitle>
          <OverviewSubtitle>
            Inställningar som berör hur kostnader ska bokföras
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey="project"
          method={method}
          title="Standardprojekt för kostnader på avier under bolaget"
          placeholder="Välj standardprojekt"
          description="Om inget annat projekt specifikt väljs på en kostnad så kommer kostnaden automatiskt bokföras under detta projekt"
          TableComponent={InvoicingProjectsTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          fieldKey="cost_center"
          method={method}
          title="Standard kostnadsställe för kostnader på avier under bolaget"
          placeholder="Välj kostnadsställe"
          description="Om inget annat kostnadsställe specifikt väljs på en kostnad så kommer kostnaden automatiskt bokföras under detta kostnadsställe"
          TableComponent={InvoicingCostCenterTable}
        />
      </div>
    </>
  );
};
