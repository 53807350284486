import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "supplier", display: "Leverantörsavtal" },
    { value: "addon", display: "Tillägsavtal" },
    { value: "termination", display: "Uppsägning" },
    { value: "agreement", display: "Överenskommelse" },
    { value: "transfer", display: "Överlåtelse" },
    { value: "other", display: "Övrigt" },
    { value: "lease", display: "Arrendeavtal" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
