import * as React from "react";
import * as SC from "../../Forms/Base/Fields/styles";
import { InnerBox } from "../../sharedStyles";

export default function MediaDisplay({ files = [], fileKey = "file" }) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        borderRadius: 12,
        minWidth: 480,
      }}
    >
      {files?.map((file) => {
        return (
          <SC.FileDisplay
            style={{ cursor: "pointer" }}
            onClick={() => window.open(file[fileKey]?.get, "_blank").focus()}
            key={file.id}
            id={file.id}
            src={file[fileKey]?.get}
            name={file.str_representation}
          >
            <SC.FileDisplayName>{file.str_representation}</SC.FileDisplayName>
          </SC.FileDisplay>
        );
      })}

      {files?.length === 0 && <InnerBox>Filer saknas</InnerBox>}
    </div>
  );
}
