export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_COMPANYINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_COMPANYINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_COMPANYINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_COMPANYINVOICING",
  DESTROY_FORM: "DESTROY_FORM_COMPANYINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_COMPANYINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_COMPANYINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_COMPANYINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_COMPANYINVOICING",
  RESET_STATE: "RESET_STATE_COMPANYINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_COMPANYINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_COMPANYINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_COMPANYINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_COMPANYINVOICING",

  LIST_URL: "/accounting/configs/companyinvoiceconfig/list/",
  POST_URL: "/accounting/configs/companyinvoiceconfig/",
  PATCH_URL: "/accounting/configs/companyinvoiceconfig/",
  GET_URL: "/accounting/configs/companyinvoiceconfig/",

  STORE_NAME: "invoicingCompany",
};
