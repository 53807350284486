import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_BROKER_IMAGE: {
      return { ...newState, [payload.attrName]: payload.brokerDoc };
    }

    default:
      return newState;
  }

  return newState;
};
