import baseReducer, { removeFromBackgroundTasks, removeFromProgress, INITIAL_STATE } from "../../base/store/reducer";
import { getPublicErrandArticleProcessName } from "../utils";

import constants from "./constants";

const STATE = {
    ...INITIAL_STATE,
    publicArticles: {}
  };

export default (state = STATE, action) => {
    const { type, payload } = action;

    if (type === constants.SET_PUBLIC_ARTICLES) {
        return setPublicArticles(state, payload.result, payload.querystring);
    }

    return baseReducer(state, action, constants);
}

const setPublicArticles = (state, result, querystring) => {
    const name = getPublicErrandArticleProcessName(querystring);

    const inProgress = removeFromProgress(state.inProgress, name);
    const backgroundTasks = removeFromBackgroundTasks(
        state.backgroundTasks,
        name
    );

    return {
        ...state,
        inProgress: inProgress,
        backgroundTasks: backgroundTasks,
        publicArticles:{...state.publicArticles, [querystring]:result}
    };
}
