import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

import usePermissionRedirect from "../../hooks/usePermissionRedirect";

import { DetailLayoutWrapper } from "../../components/sharedStyles";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import Settings from "./overview/Settings";
import ErrandRoles from "./overview/Roles";
import ComponentTypes from "./overview/ComponentTypes";
import ProductTypes from "./overview/ProductTypes";
import Checklists from "./overview/Checklists";
import Articles from "./overview/Articles";
import MassConfig from "./overview/MassConfig";

export default function MainErrandSettingsOverview() {
  const { path, url } = useRouteMatch();

  usePermissionRedirect(["allow_errand_faults", "view_can_reporterrand"]);

  const SUB_PAGES = [
    {
      path: "/settings",
      label: "Ärendetyper",
      component: Settings,
    },
    {
      path: "/roles",
      label: "Utföranderoller",
      component: ErrandRoles,
    },
    {
      path: "/component-types",
      label: "Komponenttyper",
      component: ComponentTypes,
    },
    {
      path: "/product-types",
      label: "Komponentmodeller",
      component: ProductTypes,
    },
    {
      path: "/articles",
      label: "Artiklar",
      component: Articles,
    },
    {
      path: "/checklists",
      label: "Checklistor",
      component: Checklists,
    },
    {
      path: "/mass-config",
      label: "Konfiguration",
      component: MassConfig,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Inställningar för Teknisk förvaltning`}
          {...{ subPages: subPageLinks }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
