import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import IndexSettingDetail from "../../../../../views/Indexations/SettingDetail";
import IndexationSettings from "../../../../../views/Indexations/Settings";
import IndexationTableDetail from "../../../../../views/Indexations/TableDetail";
import IndexationTables from "../../../../../views/Indexations/Tables";

import * as SC from "../../styles";

export default () => {
  const { path, url } = useRouteMatch();

  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  const SUB_PAGES = [
    {
      path: "/settings",
      exact: true,
      component: IndexationSettings,
    },
    {
      path: "/tables",
      exact: true,
      component: IndexationTables,
    },
    {
      path: "/tables/detail/:id",
      component: IndexationTableDetail,
    },
    {
      path: "/settings/detail/:id",

      component: IndexSettingDetail,
    },
  ];

  return (
    <SC.ConfigCenterDetailWrapper>
      <SC.ConfigCenterSideBarDetailWrapper>
        <SC.DetailPageBox>
          <SC.DetailPageItem
            active={getIsActive("/configcenter/indexations/settings", false)}
            to={"/configcenter/indexations/settings"}
          >
            Indexinställningar
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={getIsActive("/configcenter/indexations/tables", false)}
            to={"/configcenter/indexations/tables"}
          >
            Tabeller
          </SC.DetailPageItem>
        </SC.DetailPageBox>
      </SC.ConfigCenterSideBarDetailWrapper>

      <SC.ConfigCenterDetailElement>
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              exact={sp.exact}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </SC.ConfigCenterDetailElement>
    </SC.ConfigCenterDetailWrapper>
  );
};
