import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useApartmentForm,
  constants,
  create,
  update,
  destroyPostForm,
  destroyPatchForm,
  detailUrl,
  useApartment,
} from "../../../../store/apartments";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import { updateActiveFormInstance } from "src/store/base";

export default function ApartmentForm({ method = "POST", id }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useApartmentForm(method, id));
  const { replace } = useHistory();

  const [editApartment] = useApartment(id);

  React.useEffect(() => {
    if (editApartment && method === "PATCH") {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: editApartment,
        })
      );
    }
  }, [editApartment]);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(detailUrl({ id: returned.id }));
  };

  React.useEffect(() => {
    return () => {
      if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      } else {
        dispatch(destroyPostForm(false));
      }
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    } else {
      dispatch(destroyPostForm(success));
    }
  };

  const onSubmit = () => {
    setLoading(true);

    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: onSuccess,
          errorCallback: onError,
          id,
          preProcess: (data) => preProcess({ data }),
        })
      );
    } else {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: onError,
          preProcess: (data) => preProcess({ data }),
        })
      );
    }
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,

          descriptions,
          method,
          onSubmit,

          title:
            method === "POST" ? "Lägg till lägenhet" : "Uppdatera lägenhet",
        }}
      />
    </>
  );
}

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  if (!copy.area) {
    copy.area = 0;
  }

  if (!copy.tenant) {
    copy.tenant = null;
  }

  return copy;
};
