import * as React from "react";

export default () => {
  return (
    <div>
      Detaljer gällande avtalet.
      <br />
      <br />
      Inställningar för schemaläggning och kostnader görs i ett senare steg.
    </div>
  );
};
