import * as React from "react";
import * as SC from "./styles";

import blueprintIcon from "../../../assets/svg/blueprint.svg";

export default function NoBuildings() {
  return (
    <SC.NoBuildingsWrapper>
      <SC.TitleIcon src={blueprintIcon} />
      <SC.Title>Inga planritningar</SC.Title>
      <SC.Subtitle>
        Den valda byggnaden har inga planritningar. Tryck på knappen uppe till
        höger för att skapa en planritning.
      </SC.Subtitle>
    </SC.NoBuildingsWrapper>
  );
}
