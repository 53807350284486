import * as React from "react";

import { Confirm, Component, Type, Description, Details } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "TYPE":
      return <Type {...{ method }} />;

    case "COMPONENT":
      return <Component {...{ method }} />;

    case "DESCRIPTION":
      return <Description {...{ method }} />;

    case "DETAILS":
      return <Details {...{ method }} />;

    default:
      return <Confirm />;
  }
};
