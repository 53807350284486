import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { usePermissionCheck } from "../../store/base";
import { useParkingLot, constants } from "../../store/parkingLots";
import { useParkingSpotsPaginationCount } from "../../store/parkingSpots/hooks/retrieve";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ParkingLotOverview from "./lotDetails/Overview";

export default function MainParkingLotDetails() {
  const { parkingLotId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();

  const canDelete = usePermissionCheck("delete_can_parking");

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [parkingLot] = useParkingLot(parkingLotId);

  const parkingLotName = React.useMemo(() => {
    return parkingLot?.str_representation.replace("Zone", "Zon");
  }, [parkingLot]);

  const [parkingSpotCount] = useParkingSpotsPaginationCount({
    filters: {
      parking_lot: parkingLotId,
    },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ParkingLotOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={4}>
          Antal fordonsplatser på området: {parkingSpotCount || 0}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={parkingLot}
        constants={constants}
        deletedCallback={() => replace("/parking/parking-lots")}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Parkeringsområde ${parkingLotName || "Laddar..."}`}
          breadCrumbs={[
            { url: "/parking/parking-lots", label: "Parkeringsområden" },
            { label: parkingLotName || "Laddar..." },
          ]}
          eventContentType="standard.parkinglot"
          eventObjectId={parkingLotId}
          eventIdQueryStr="parkinglot"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
