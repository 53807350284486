import * as React from "react";
import moment from "moment";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import {
  useAllPermissionCheck,
  useAnyPermissionCheck,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../store/base";
import ContractsTodo from "./MyWorkday/ContractsTodo";
import InvoicingTodo from "./MyWorkday/InvoicingTodo";
import ObjectsTodo from "./MyWorkday/ObjectsTodo";
import UsersTodo from "./MyWorkday/UsersTodo";
import ImdTodo from "./MyWorkday/ImdTodo";
import ErrandsTodo from "./MyWorkday/ErrandsTodo";
import IntegrationsTodo from "./MyWorkday/IntegrationsTodo";

export default function MyWorkdayTasks() {
  const { path, url } = useRouteMatch();

  const { hasBillectaFullPermission } = useFrequentPermissions();
  const hasContractPermission = usePermissionCheck("view_can_contract");
  const hasObjectPermission = usePermissionCheck("view_can_baseobject");
  const hasTenantOrUserPermission = useAnyPermissionCheck([
    "view_can_tenant",
    "view_can_user",
  ]);
  const hasImdPermission = useAllPermissionCheck(["view_can_imd", "allow_imd"]);
  const hasAnyErrandPermission = useAnyPermissionCheck([
    "view_can_reporterrand",
    "view_can_inspectionerrand",
    "view_can_roundingerrand",
    "view_can_goverrand",
  ]);
  const hasYourBlockPermission = useAllPermissionCheck([
    "view_can_yourblock",
    "allow_yourblock",
  ]);

  const hasIntegrationPermission = hasYourBlockPermission; // add more integrations and check if any

  const SUB_PAGES = [];

  if (hasBillectaFullPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      component: InvoicingTodo,
    });
  }

  if (hasContractPermission) {
    SUB_PAGES.push({
      path: "/contracts",
      label: "Avtal",
      component: ContractsTodo,
    });
  }

  if (hasObjectPermission) {
    SUB_PAGES.push({
      path: "/objects",
      label: "Objekt",
      component: ObjectsTodo,
    });
  }

  if (hasTenantOrUserPermission) {
    SUB_PAGES.push({
      path: "/users",
      label: "Hyresgäster & användare",
      component: UsersTodo,
    });
  }

  if (hasImdPermission) {
    SUB_PAGES.push({
      path: "/imd",
      label: "IMD",
      component: ImdTodo,
    });
  }

  if (hasAnyErrandPermission) {
    SUB_PAGES.push({
      path: "/errands",
      label: "Teknisk förvaltning",
      component: ErrandsTodo,
    });
  }

  if (hasIntegrationPermission) {
    SUB_PAGES.push({
      path: "/integrations",
      label: "Integrationer",
      component: IntegrationsTodo,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const renderInfoPills = () => (
    <StatusLabel state={"neutral"}>{moment().format("LL")}</StatusLabel>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Min arbetsdag - Att hantera`}
          {...{
            subPages: subPageLinks,
            renderInfoPills,
            breadCrumbs: [
              { label: "Översikt", url: "/" },
              { label: "Att hantera" },
            ],
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          {SUB_PAGES?.length > 0 && (
            <Redirect
              exact
              to={`${url}${SUB_PAGES[0].path}`}
              path={`${path}`}
            />
          )}
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
