import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import PrimaryBtn from "../Buttons/PrimaryBtn";

export default function ConfirmModal({
  closeFunction,
  children,
  onAccept,
  acceptTitle = "Fortsätt",
  denyTitle = "Avbryt",
  overrideConfirm = false,
  isInfo = false, // for blue instead of read button
}) {
  const confirm = () => {
    onAccept();
    if (overrideConfirm) return;
    closeFunction();
  };
  return (
    <div
      id="popup-modal"
      tabindex="-1"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 bg-slate-700/70 flex justify-center md:inset-0 h-modal md:h-full"
    >
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white pt-6 rounded shadow ">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={closeFunction}
          >
            <XCircleIcon width={24} />
          </button>
          <div className="p-6 text-center text-slate-500">
            {isInfo ? (
              <InformationCircleIcon className="mx-auto mb-4" width={58} />
            ) : (
              <ExclamationCircleIcon className="mx-auto mb-4" width={58} />
            )}
            {children}
          </div>

          <div className="flex items-center justify-center p-6 space-x-2 rounded-b border-t border-gray-200 ">
            <PrimaryBtn className="mr-2" error={!isInfo} onClick={confirm}>
              {acceptTitle}
            </PrimaryBtn>

            <PrimaryBtn secondary onClick={closeFunction}>
              {denyTitle}
            </PrimaryBtn>
          </div>
        </div>
      </div>
    </div>
  );
}
