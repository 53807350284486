import * as React from "react";

// style, design
import { TextInput, Select } from "../Base/Fields";

export default ({ storeName, method, parentPath, parentInstructionsPath }) => {
  return (
    <>
      <TextInput
        title="Namn på kategori"
        storeName={storeName}
        fieldKey={`${parentPath}.title`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.title`}
      />

      <Select
        title="Typ av kategori"
        storeName={storeName}
        fieldKey={`${parentPath}.content_type`}
        method={method}
      />
    </>
  );
};
