import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  setActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { DetailPageBox, DetailPageBoxFlexWrapper } from "../../sharedStyles";
import HandleForeignAccounts from "./HandleForeignAccounts";
import HandlePaymentMethods from "./HandlePaymentMethods";

import { constants } from "../../../store/invoicingCompany";

export default function CompanyPaymentMethods({ companyInvoiceConfig }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [formDirty, setFormDirty] = React.useState(false);
  const formCosts = useFormInstanceField({
    storeName,
    fieldKey: "companypaymentmethod_set",
  });

  React.useEffect(() => {
    // not fetched costs yet
    if ((formDirty && !!formCosts) || !companyInvoiceConfig) {
      return;
    }

    dispatch(
      setActiveFormInstance({
        storeName,
        data: companyInvoiceConfig,
      })
    );

    setFormDirty(true);
  }, [formDirty, companyInvoiceConfig]);

  return (
    <DetailPageBoxFlexWrapper>
      <DetailPageBox
        style={{
          flex: 1,
          maxWidth: "calc(50% - 6px)",
          alignSelf: "flex-start",
          marginRight: "12px",
        }}
      >
        <HandlePaymentMethods
          companyInvoiceConfig={cloneDeep(companyInvoiceConfig)}
        />
      </DetailPageBox>
      <DetailPageBox
        style={{ maxWidth: "calc(50% - 6px)", alignSelf: "flex-start" }}
      >
        <HandleForeignAccounts
          companyInvoiceConfig={cloneDeep(companyInvoiceConfig)}
        />
      </DetailPageBox>
    </DetailPageBoxFlexWrapper>
  );
}
