import { useDispatch, useSelector } from "react-redux";

import constants from "../store/constants";

import { CLEANED_PAYMENT_MEANS } from "./instructions";

export function usePaymentMeansForm(method) {
  const dispatch = useDispatch();

  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: CLEANED_PAYMENT_MEANS,
        name: "book_keeping",
        method: method,
      },
    });
  }

  return hasForm;
}
