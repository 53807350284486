import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import ParkingList from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersion";
import { PERSISTANT_CATEGORY_KEY } from "./ParkingContracts";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";

export default function ParkingContractDrafts() {
  const { filteredRealEstates } = useActiveFilters();

  const [persistantCategories, setPersistantCategories] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [readyToRenderTable, setReadyToRenderTable] = React.useState(false);

  const handleClearPersistantCategories = () => {
    setSelectedFilters([]);
    setPersistantCategories(null);
    localStorage.removeItem(PERSISTANT_CATEGORY_KEY);
  };

  const draftPersistantQueryString = buildQueryString({
    realestate__in: filteredRealEstates,
    draft: true,
  });
  const draftPersistantFilterMethod = (obj) => {
    if (!filteredRealEstates) return obj.draft === true;
    return (
      filteredRealEstates.includes(obj.realestate?.id) && obj.draft === true
    );
  };

  React.useEffect(() => {
    const persistantCategories = localStorage.getItem(PERSISTANT_CATEGORY_KEY);
    const parsed = persistantCategories ? JSON.parse(persistantCategories) : [];

    setSelectedFilters(parsed);

    if (parsed?.length) {
      setPersistantCategories(parsed);
    }
    setReadyToRenderTable(true);
  }, []);

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Utkast av parkeringsavtal</OverviewTitle>
          </OverviewTitleWrapper>

          {readyToRenderTable && (
            <ParkingList
              persistantQueryString={draftPersistantQueryString}
              persistantFilterMethod={draftPersistantFilterMethod}
              hideTitle
              withPersistantSortBy
              withPersistantGlobalFilter
              persistantCategories={persistantCategories}
              clearCategories={handleClearPersistantCategories}
            />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
