import * as React from "react";

import { constants } from "../../../../../store/keydevice";
import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../../../store/base";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import { useFilteredRealEstates } from "../../../../../store/realEstates";

import { useDispatch } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";
import ApartmentTable from "../../../../Tables/Apartments/FullTableNewVersion";

export default ({ method }) => {
  const dispatch = useDispatch();
  const { filteredRealEstates } = useActiveFilters();

  const storeName = constants.STORE_NAME;

  const realEstateQuery = buildQueryString({
    id__in: filteredRealEstates,
  });

  const didSelect = (kind, row) => {
    if (row != null) {
      switch (kind) {
        case "apartment":
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: { industrial_premises: null, common_area: null },
            })
          );
          break;
        case "industrial":
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: { apartment: null, common_area: null },
            })
          );
          break;
        case "common":
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: { industrial_premises: null, apartment: null },
            })
          );
          break;

        default:
          break;
      }
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            realestate_ids: filteredRealEstates,
          }}
          TableComponent={ApartmentTable}
          placeholder="Välj lägenhet..."
          title="Lägenhet"
          method={method}
          fieldKey="apartment"
          changeCallback={(row) => didSelect("apartment", row)}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{ realestate_ids: filteredRealEstates }}
          placeholder="Välj lokal..."
          TableComponent={IndustrialPremisesTable}
          title="Lokal"
          method={method}
          fieldKey="industrial_premises"
          changeCallback={(row) => didSelect("industrial", row)}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            realestate_ids: filteredRealEstates,
          }}
          TableComponent={CommonAreaTable}
          placeholder="Välj gemensam yta..."
          title="Gemensam Yta"
          method={method}
          fieldKey="common_area"
          changeCallback={(row) => didSelect("common", row)}
        />
      </div>
    </>
  );
};
