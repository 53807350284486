import axios from "axios";
import { clearUserData, formattedToken } from "../../../utils";

import { store } from "../../store";
import { currentEnv, ENVIRONMENTS } from "../../..";
import { addToast, TOAST_TYPES } from "../../toasts";

import { logout } from "../../app/actions";

export const performLogoutRedirect = () => {
  const currentPath = window.location.pathname;

  try {
    window.Intercom("shutdown");
  } catch (e) {
    console.error(e);
  }

  switch (currentEnv) {
    // case ENVIRONMENTS.PROD: {
    //   window.location.replace("https://pigello.se/logga-in");
    //   break;
    // }

    default: {
      if (!window.location.href.includes(`${window.location.origin}/login`)) {
        window.location.replace(
          `${window.location.origin}/login?loggedout=true&next=${currentPath}`
        );
      }
    }
  }
};

const baseUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // return "https://v2api.pigello.se";

    // return "https://v2staging.api.pigello.se";
    return "https://v2alpha.api.pigello.se";

    return "http://localhost:8000";
  } else if (window.location.host.includes("demo.app.pigello")) {
    return "https://v2alpha.api.pigello.se";
  } else if (window.location.host.includes("staging.app.pigello")) {
    return "https://v2staging.api.pigello.se";
  } else {
    return "https://v2api.pigello.se";
  }
};
export const axiosInstance = axios.create({
  baseURL: baseUrl(),
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
  },
});

function errorRedirect(status) {
  switch (status) {
    case 400:
      return;
    case 401:
      clearUserData();
      store.dispatch(logout());
      break;

    case 500:
      store.dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel på servern",
          description:
            "Detta kan bero på ett tillfälligt fel eller ett anrop som tog för lång tid på sig. Kontrollera datan som skickades eller hämtades.",
        })
      );
      // window.location.pathname = "/error/oops";
      break;

    default:
      break;
  }
}

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = formattedToken();
  return config;
});

const IGNORE_401_LOGOUT_URLS = ["market/authenticate/"];

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let status = 500;
    if (error.response) {
      status = error.response.status;
    }

    if (status === 500 || status === 401) {
      let dontLogout = false;
      if (status === 401) {
        // check if 401 is in ignored unaythenticated calls
        dontLogout = IGNORE_401_LOGOUT_URLS.some((url) => {
          if (error.response.config.url.includes(url)) {
            return true;
          }
        });
      }

      if (!dontLogout) {
        errorRedirect(status);
      }
    }

    throw error;
  }
);

export const getCancelToken = () => {
  return axios.CancelToken.source();
};
