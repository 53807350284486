import * as React from "react";

export default () => {
  return (
    <div>
      Tryck sedan på "Fortsätt" för att gå vidare till granskning av de valda
      objekten.
    </div>
  );
};
