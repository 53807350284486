import * as React from "react";
import { constants } from "../../../../../store/parkingContracts";
import DocumentUploader from "../../../Base/Fields/DocumentUploader";

// style, design

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <DocumentUploader
        title="Dokument för uppsägning"
        description="Ladda upp det eventuella dokumentet för uppsägningen"
        storeName={storeName}
        method={method}
        fieldKey={"cancelled_doc.doc"}
      />
    </>
  );
};
