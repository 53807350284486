import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { usePermissionCheck } from "../../store/base";
// import { useDebtorInvoicing } from "../../store/invoicingDebtor";
import {
  useServicePartner,
  constants,
  overviewUrl,
} from "../../store/servicePartners";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ServicePartnerContracts from "./detail/Contracts";
import ServicePartnerOverview from "./detail/Overview";

export default function ServicePartnerDetail() {
  const { servicePartnerId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const [servicePartner] = useServicePartner(servicePartnerId);
  //   const [invoiceSettingPromptOpen, setInvoicingSettingPromtOpen] =
  //     React.useState(false);
  usePermissionRedirect(["view_can_contract"]);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const canDeleteServicePartner = usePermissionCheck("delete_can_contract");
  //   const { hasBillectaViewPermission, hasBillectaFullPermission } =
  //     useFrequentPermissions();
  //   const showInvoicingSettingPrompt = query.get("showInvoicingSettingPrompt");

  //   const [debtorInvoiceConfig] = useDebtorInvoicing(
  //     servicePartner?.debtor_invoice_config?.id
  //   );

  //   React.useEffect(() => {
  //     if (hasBillectaFullPermission && showInvoicingSettingPrompt == "true") {
  //       setInvoicingSettingPromtOpen(true);
  //     }
  //   }, [showInvoicingSettingPrompt, hasBillectaFullPermission]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Servicepartner",
      component: ServicePartnerOverview,
    },
    {
      path: "/contracts",
      label: "Avtal",
      component: ServicePartnerContracts,
    },
  ];

  //   if (hasBillectaViewPermission) {
  //     SUB_PAGES.push({
  //       path: "/invoicing",
  //       label: "Avisering",
  //       component: TenantInvoicing,
  //     });
  //   }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canDeleteServicePartner) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  //   const goToInvoicing = () => {
  //     push(
  //       `/service-contracts/service-partners/${servicePartner}/invoicing?opensettings=true`
  //     );
  //   };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={servicePartner}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      {/* <ConfirmationModal
        isOpen={invoiceSettingPromptOpen}
        closeFunction={() => setInvoicingSettingPromtOpen(false)}
        title="Vill du konfigurera aviseringsinställningar för denna servicepartner direkt?"
        acceptTitle="Konfigurera nu"
        acceptCallback={goToInvoicing}
      /> */}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Servicepartner ${
            servicePartner?.str_representation || "laddar..."
          }`}
          breadCrumbs={[
            {
              url: "/service-contracts/service-partners",
              label: "Servicepartners",
            },
            { label: servicePartner?.str_representation || "Laddar..." },
          ]}
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
