import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useQuery from "../../../components/utils/useQuery";
import Table from "../../../components/Lists/Base/CompleteList/Table";

import {
  constants as apartmentConstants,
  useApartmentPagination,
  performFilter as performApartmentFilter,
  useFilteredApartments,
} from "../../../store/apartments";
import {
  constants as indpConstants,
  useIndustrialPremisesPagination,
  performFilter as performIndpFilter,
  useFilteredIndustrialPremises,
} from "../../../store/industrialPremises";

import apartmentListDefs from "../../../components/Tables/Apartments/listDefs";
import indpListDefs from "../../../components/Tables/IndustrialPremises/listDefs";
import { AD_CREATE_TYPES } from "./ManyAdRootObjectSelect";
import {
  constants as apartmentCommoditiesConstants,
  removeApartment,
  addApartment,
  addMultipleApartments,
  setRenovationDateForId as setRenovationDateForIdApartment,
  setCommodities as apartmentSetCommodities,
  createMany as apartmentCreateMany,
  createManyMenuUrl,
  useFilteredApartmentCommodities,
} from "../../../store/marketApartmentCommodities";

import {
  constants as indpCommoditiesConstants,
  removeIndp,
  addIndp,
  addMultipleIndps,
  setRenovationDateForId as setRenovationDateForIdIndp,
  setCommodities as indpSetCommodities,
  createMany as indpCreateMany,
  useFilteredIndustrialPremisesCommodities,
} from "../../../store/marketIndustrialPremisesCommodities";

import { buildQueryString, clearFetched } from "../../../store/base";
import { useDispatch, useSelector } from "react-redux";
import StandardModal from "../../../components/Modals/StandardModal";
import { buildRangeFilter } from "../../../components/Lists/Base/utils";

import { cloneDeep } from "lodash";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import HandleCommodities from "./HandleCommodities";
import CommoditiesTable from "./CommoditiesTable";

const fetchAllTreshold = 25;
const forceFrontendColumns = [
  "state",
  "leasecontracts",
  "othercontracts",
  "floors",
];
const filterInstructions = {
  premises_id: { operator: "icontains" },
  area: { handler: (filterObject) => buildRangeFilter(filterObject) },
};

const getConstants = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentCommoditiesConstants;
  if (type === AD_CREATE_TYPES.INDP) return indpCommoditiesConstants;

  return null;
};
const getAddDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return addApartment;
  if (type === AD_CREATE_TYPES.INDP) return addIndp;

  return null;
};
const getAddMultpleDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return addMultipleApartments;
  if (type === AD_CREATE_TYPES.INDP) return addMultipleIndps;

  return null;
};
const getRemoveDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return removeApartment;
  if (type === AD_CREATE_TYPES.INDP) return removeIndp;

  return null;
};
const getRenovationDateDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT)
    return setRenovationDateForIdApartment;
  if (type === AD_CREATE_TYPES.INDP) return setRenovationDateForIdIndp;

  return null;
};

const getSetCommoditiesDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentSetCommodities;
  if (type === AD_CREATE_TYPES.INDP) return indpSetCommodities;

  return null;
};
const getCreateManyDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentCreateMany;
  if (type === AD_CREATE_TYPES.INDP) return indpCreateMany;

  return null;
};

export default function CreateManyCommodities() {
  const dispatch = useDispatch();
  const query = useQuery();
  const { type } = useParams();
  const { goBack } = useHistory();
  const [hasSetFromQuery, setHasSetFromQuery] = React.useState(false);
  const [selectorOpen, setSelectorOpen] = React.useState(false);

  const [createConfirmOpen, setCreateConfirmOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const constants = getConstants(type);
  const add = getAddDispatch(type);
  const remove = getRemoveDispatch(type);
  const addMultiple = getAddMultpleDispatch(type);
  const setRenovationDateForId = getRenovationDateDispatch(type);
  const setCommodities = getSetCommoditiesDispatch(type);
  const createMany = getCreateManyDispatch(type);
  const storeName = constants.STORE_NAME;
  const [editId, setEditId] = React.useState(null);
  const { push } = useHistory();

  const commoditiesInstance =
    useSelector((state) => state[storeName].commodities) || [];

  const buildingId = query.get("building");

  const apartmentColumns = React.useMemo(() => apartmentListDefs(), []);
  const indpColumns = React.useMemo(() => indpListDefs(), []);

  const apartmentQuery = buildQueryString({});
  const apartmentFilter = (obj) => {
    return obj;
  };

  const buildingQueryObj = {
    building: buildingId,
  };
  if (!buildingId) {
    buildingQueryObj.building = -1;
  }
  const buildingQuery = buildQueryString(buildingQueryObj);

  const [apartmentsMatchingBuilding, apartmentsMatchingBuildingLoading] =
    useFilteredApartments(
      type === AD_CREATE_TYPES.APARTMENT ? buildingQuery : "id__in=-1"
    );

  const [
    industrialPremisesMatchingBuilding,
    industrialPremisesMatchingBuildingLoading,
  ] = useFilteredIndustrialPremises(
    type === AD_CREATE_TYPES.INDP ? buildingQuery : "id__in=-1"
  );
  const apartmentCommoditiesFilter = buildQueryString({
    apartment__in: apartmentsMatchingBuilding?.map((a) => a.id),
  });
  const indpCommoditiesFilter = buildQueryString({
    industrial_premises__in: industrialPremisesMatchingBuilding?.map(
      (a) => a.id
    ),
  });

  const [
    apartmentCommoditiesAlreadyExisting,
    apartmentCommoditiesAlreadyExistingLoading,
  ] = useFilteredApartmentCommodities(
    type === AD_CREATE_TYPES.APARTMENT ? apartmentCommoditiesFilter : null
  );
  const [
    industrialPremisesCommoditiesAlreadyExisting,
    industrialPremisesCommoditiesAlreadyExistingLoading,
  ] = useFilteredIndustrialPremisesCommodities(
    type === AD_CREATE_TYPES.INDP ? indpCommoditiesFilter : null
  );

  const defaultSettings = useSelector(
    (state) => state[storeName].defaultSettings
  );

  React.useEffect(() => {
    let filteredOut = 0;
    if (
      hasSetFromQuery ||
      apartmentsMatchingBuildingLoading ||
      industrialPremisesCommoditiesAlreadyExistingLoading ||
      apartmentCommoditiesAlreadyExistingLoading ||
      industrialPremisesMatchingBuildingLoading ||
      (apartmentsMatchingBuilding?.length === 0 &&
        type === AD_CREATE_TYPES.APARTMENT) ||
      (industrialPremisesMatchingBuilding?.length === 0 &&
        type === AD_CREATE_TYPES.INDP)
    )
      return;

    let combined = [
      ...(type === AD_CREATE_TYPES.APARTMENT
        ? apartmentsMatchingBuilding || []
        : []),
      ...(type === AD_CREATE_TYPES.INDP
        ? industrialPremisesMatchingBuilding || []
        : []),
    ];

    combined = combined.filter((a) => {
      if (
        type === AD_CREATE_TYPES.APARTMENT &&
        apartmentCommoditiesAlreadyExisting?.find(
          (c) => c.apartment?.id === a.id
        )
      ) {
        filteredOut += 1;
        return false;
      }
      if (
        type === AD_CREATE_TYPES.INDP &&
        industrialPremisesCommoditiesAlreadyExisting?.find(
          (c) => c.industrial_premises?.id === a.id
        )
      ) {
        filteredOut += 1;
        return false;
      }
      return true;
    });

    if (combined?.length === 0 && filteredOut > 0) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Alla objekt i denna byggnad inställda redan",
        })
      );
      setHasSetFromQuery(true);
      return;
    }

    if (combined?.length > 0) {
      setHasSetFromQuery(true);

      if (combined.length > 50) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Hämtar in data...",
            description: "OBS: Detta kan ta några sekunder vid större volymer",
          })
        );

        setTimeout(() => {
          if (type === AD_CREATE_TYPES.APARTMENT) {
            dispatch(
              addMultiple({
                apartments: combined?.map((c) => ({
                  id: c.id,
                  apartment_id: c.apartment_id,
                  premises_id: c.premises_id,
                  ...defaultSettings,
                })),
              })
            );
          } else if (type === AD_CREATE_TYPES.INDP) {
            dispatch(
              addMultiple({
                indps: combined?.map((c) => ({
                  id: c.id,

                  premises_id: c.premises_id,
                  ...defaultSettings,
                })),
              })
            );
          }
        }, 1000);
      } else {
        if (type === AD_CREATE_TYPES.APARTMENT) {
          dispatch(
            addMultiple({
              apartments: combined?.map((c) => ({
                id: c.id,
                apartment_id: c.apartment_id,
                premises_id: c.premises_id,
                ...defaultSettings,
              })),
            })
          );
        } else if (type === AD_CREATE_TYPES.INDP) {
          dispatch(
            addMultiple({
              indps: combined?.map((c) => ({
                id: c.id,

                premises_id: c.premises_id,
                ...defaultSettings,
              })),
            })
          );
        }
      }
      if (filteredOut > 0) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Vissa objekt filtrerades bort",
            description: `${filteredOut} objekt har redan inställningar för annonsdetaljer.`,
          })
        );
      }
    }
  }, [
    apartmentsMatchingBuilding,
    industrialPremisesMatchingBuildingLoading,
    apartmentCommoditiesAlreadyExistingLoading,
    apartmentCommoditiesAlreadyExisting,
    industrialPremisesCommoditiesAlreadyExistingLoading,
    industrialPremisesCommoditiesAlreadyExisting,
    apartmentsMatchingBuilding,
    industrialPremisesMatchingBuilding,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(setCommodities({ newCommodities: [] }));
    };
  }, []);

  const onSuccess = () => {
    setLoading(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Annonsdetaljerna sparas...",
        description:
          "Detta kan ta en liten stund. Pigello uppdateras automatiskt när de är sparade",
      })
    );

    dispatch(clearFetched(constants, true));

    push(
      `${createManyMenuUrl({ type })}?created_amount=${
        commoditiesInstance?.length
      }&postcreation=true`
    );
  };
  const onError = () => {
    setLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "En eller fler rader innehåller fel eller saknar information",
        description: "Kontrollera datan och försök igen",
      })
    );
  };

  const onSubmit = () => {
    setLoading(true);
    const postData = buildPostObj({ type, commoditiesInstance });

    dispatch(
      createMany({
        postData,
        successCallback: onSuccess,
        errorCallback: onError,
      })
    );
  };

  const onSelected = (row) => {
    const { original } = row;

    if (commoditiesInstance.find((i) => i.id === original.id)) {
      if (type === AD_CREATE_TYPES.APARTMENT) {
        dispatch(remove({ apartment: original }));
      } else if (type === AD_CREATE_TYPES.INDP) {
        dispatch(remove({ indp: original }));
      }
    } else {
      if (type === AD_CREATE_TYPES.APARTMENT) {
        dispatch(add({ apartment: original }));
      } else if (type === AD_CREATE_TYPES.INDP) {
        dispatch(add({ indp: original }));
      }
    }
  };

  const checkRowHighlighted = (row) => {
    const { original } = row;

    let match = false;

    match = commoditiesInstance?.find((s) => s.id === original.id);

    return match;
  };

  const handleRenovationDateUpdates = ({ id, val }) => {
    dispatch(setRenovationDateForId({ id, val }));
  };

  const handleAttrUpdated = ({ attr, id, val }) => {
    const newCommodities = cloneDeep(commoditiesInstance);

    const cur = newCommodities.find((obj) => obj.id === id);

    cur[attr] = val;

    dispatch(setCommodities({ newCommodities }));
  };

  return (
    <>
      <ConfirmationModal
        isOpen={createConfirmOpen}
        title={`Skapa annonser?`}
        renderContent={() => (
          <div>
            <hr />
            Du är påväg att sätta upp annonsdetaljer för{" "}
            {commoditiesInstance?.length} objekt. Gå vidare?
          </div>
        )}
        closeFunction={() => setCreateConfirmOpen(false)}
        acceptCallback={onSubmit}
      />

      <HandleCommodities
        editInstance={commoditiesInstance?.find((c) => c.id === editId)}
        isOpen={!!editId}
        handleAttrUpdated={handleAttrUpdated}
        handleRenovationDateUpdates={handleRenovationDateUpdates}
        closeFunction={() => setEditId(null)}
      />

      <StandardModal
        isOpen={selectorOpen}
        closeFunction={() => setSelectorOpen(false)}
        title={`Välj ${
          type === AD_CREATE_TYPES.APARTMENT ? "hyreslägenheter" : "lokaler"
        }`}
        withActionBar
        actionBarCancelTitle="Klar"
      >
        {type === AD_CREATE_TYPES.APARTMENT && (
          <Table
            storeName={apartmentConstants.STORE_NAME}
            columns={apartmentColumns}
            persistantQueryString={apartmentQuery}
            persistantFilterMethod={apartmentFilter}
            paginationMethod={useApartmentPagination}
            filterInstructions={filterInstructions}
            fetchAllTreshold={fetchAllTreshold}
            filterAction={performApartmentFilter}
            forceFrontendColumns={forceFrontendColumns}
            {...{
              hideExport: true,
              hideColumns: true,
              onRowClicked: onSelected,
              checkRowHighlighted,
            }}
          />
        )}

        {type === AD_CREATE_TYPES.INDP && (
          <Table
            storeName={indpConstants.STORE_NAME}
            columns={indpColumns}
            persistantQueryString={apartmentQuery}
            persistantFilterMethod={apartmentFilter}
            paginationMethod={useIndustrialPremisesPagination}
            filterInstructions={filterInstructions}
            fetchAllTreshold={fetchAllTreshold}
            filterAction={performIndpFilter}
            forceFrontendColumns={forceFrontendColumns}
            {...{
              hideExport: true,
              hideColumns: true,
              onRowClicked: onSelected,
              checkRowHighlighted,
            }}
          />
        )}
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            extraStyle={{ marginBottom: 24 }}
            title="Tillbaka till val av standardinställningar"
            iconType="arrow-back"
            clicked={() => goBack()}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>
                Skapa flera inställningar för annonsdetaljer
              </OverviewTitle>
              <OverviewSubtitle style={{ marginRight: 24 }}>
                För att lägga till ett objekt, klicka på "Lägg till objekt "
                nedan. Klicka på en rad för att redigera den.
                <br /> Tryck på "Slutför" när du är klar.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Slutför"
              clicked={() => setCreateConfirmOpen(true)}
            />
          </OverviewTitleWrapper>
          <hr />

          <TextButton
            title="Lägg till objekt"
            iconType="add"
            iconPlacement="right"
            clicked={() => setSelectorOpen(true)}
          />
          <CommoditiesTable
            {...{ commoditiesInstance, setEditId, onSelected, type }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export const PILOT_OPTIONS = [
  {
    v: 0,
    d: "Automatiskt",
  },
  {
    v: 1,
    d: "Manuellt val låst",
  },
  {
    v: 2,
    d: "Manuellt val",
  },
  {
    v: 3,
    d: "Extern",
  },
];

const preProcess = (data) => {
  const dataClone = cloneDeep(data);

  if (!dataClone.available_parking_spots) {
    dataClone.available_parking_spots = 0;
  }
  if (!dataClone.available_garage_spots) {
    dataClone.available_garage_spots = 0;
  }
  if (!dataClone.included_parking_spots) {
    dataClone.included_parking_spots = 0;
  }
  if (!dataClone.included_garage_spots) {
    dataClone.included_garage_spots = 0;
  }

  return dataClone;
};

const buildPostObj = ({ type, commoditiesInstance }) => {
  const postObj = commoditiesInstance.map((commodities) => {
    let commoditiesObj;
    switch (type) {
      case AD_CREATE_TYPES.APARTMENT:
        commoditiesObj = {
          ...preProcess(commodities),
          apartment: {
            id: commodities.id,
          },
        };

        delete commoditiesObj.id;
        delete commoditiesObj.premises_id;
        delete commoditiesObj.apartment_id;
        break;
      case AD_CREATE_TYPES.INDP:
        commoditiesObj = {
          ...preProcess(commodities),
          industrial_premises: {
            id: commodities.id,
          },
        };

        delete commoditiesObj.id;
        delete commoditiesObj.premises_id;
        break;
      default:
        break;
    }
    return commoditiesObj;
  });

  return postObj;
};
