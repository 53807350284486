import * as React from "react";
import { PrimaryButton } from "../../../Base/Buttons";

export default ({ method, onSubmit }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 200,
        }}
      >
        <div>
          <PrimaryButton
            title={
              method === "POST"
                ? "Skapa parkeringsområde"
                : "Uppdatera parkeringsområde"
            }
            clicked={onSubmit}
          />
        </div>
      </div>
    </>
  );
};
