import * as React from "react";

import { TextInput, ColorPicker } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/tenantPortalSettings";

import { useFormInstanceField } from "../../../../../store/base";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const { filteredRealEstates } = useActiveFilters();

  let realestateQuery = {};
  if (method === "POST") {
    realestateQuery = {
      tenantportalsetting__isnull: true,
    };
  } else if (method === "PATCH") {
    realestateQuery = {
      id__in: filteredRealEstates,
    };
  }

  const verboseKey = useFormInstanceField({
    storeName,
    fieldKey: "verbose_key",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställning för "Mina sidor"</OverviewTitle>
          <OverviewSubtitle>
            Fyll i information kring inställningen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey="company_name"
        title="Bolagsnamn"
        description="Visas på inloggningssidan"
      />

      {method === "POST" && (
        <TextInput
          readOnlyPrefix="https://portal.pigello.se/"
          storeName={storeName}
          method={method}
          forceError={
            verboseKey?.includes("http") ||
            verboseKey?.includes("/") ||
            verboseKey?.includes(".")
              ? "OBS: Felaktig URL-nyckel, kontrollera instruktionerna och anpassa URL-nyckeln efter dessa."
              : false
          }
          title="URL-nyckel för konfiguration (OBS. Kan ej ändras efter skapande)"
          subtitleHelp={`Exemelvis "bolagsnamn". OBS: EJ "http://portal.pigello.se/bolagsnamn". Använd enbart bokstäver, ej specialtecken såsom /, :, ., osv.`}
          fieldKey="verbose_key"
          description={`URL-nyckeln är namnet på länken till denna konfiguration för "Mina sidor". Exempel 'https://www.portal.pigello.se/URL-nyckel'. Kontakta Pigello om du har frågor kring inställningarna`}
        />
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={realestateQuery}
          placeholder="Välj fastigheter..."
          title="Fastigheter"
          description="Fastigheter som denna konfiguration gäller för (OBS. Lämna tom för att konfigurationen ska användas för alla fastigheter som inte har en specifik konfiguration)"
          method={method}
          fieldKey={`realestates`}
          isMany
          TableComponent={RealEstateTable}
        />
      </div>

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey="hello_phrase"
        title={`Välkomstrubrik på startsidan i "Mina sidor"`}
      />

      <TextInput
        storeName={storeName}
        method={method}
        title="Välkomstmeddelande"
        fieldKey="hello_description"
        placeholder="Max 50 tecken"
      />

      <ColorPicker
        title="Färgkod"
        storeName={storeName}
        method={method}
        fieldKey="color_code"
        blockPicker
      />
    </>
  );
};
