import moment from "moment";
import * as React from "react";
import * as ISC from "../../../Details/InfoTable/styles";

export default ({ instance }) => {
  return (
    <ISC.InfoTable>
      <ISC.InfoTableTitleWrapper>
        <ISC.InfoTableTitle>
          <ISC.InfoTableTitle>
            {instance?.from_date
              ? moment(instance?.from_date).format("YYYY-MM-DD HH:mm:ss")
              : "Datum saknas"}
          </ISC.InfoTableTitle>
          <ISC.InfoTableSubtitle>Från Datum</ISC.InfoTableSubtitle>
        </ISC.InfoTableTitle>
      </ISC.InfoTableTitleWrapper>

      <ISC.InfoTableContent>
        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Från datum</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.from_date
              ? moment(instance?.from_date).format("YYYY-MM-DD HH:mm:ss")
              : "Datum saknas"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Pris</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.value ?? "Pris saknas"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Fastighet</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.realestate?.str_representation || "Fastighet saknas"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>
      </ISC.InfoTableContent>
    </ISC.InfoTable>
  );
};
