import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "./PaymentsTable";
import ContainerSpinner from "../../Loaders/ContainerSpinner";
import MatchPaymentsModal from "./MatchPaymentsModal";
import DevCreateUnmatchedPayment from "./DevCreateUnmatchedPayment";
import { useParams } from "react-router";
import DeletePaymentsModal from "./DeletePaymentsModal";
import { DetailInnerWrapper, DetailPageBox } from "../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { clearAllPaymentsNotificationsForCreditor } from "../../../store/billectaEvents";

const POSSIBLE_INVOICE_STAGES = [
  "Manual",
  "InvoiceSent",
  "Attested",
  "SentToDebtCollection",
  "ReminderInvoiceSent",
  "Completed",
];

export default function PaymentsContainer() {
  const dispatch = useDispatch();
  const [selectedPayments, setSelectedPayments] = React.useState([]);
  const [matchModalOpen, setMatchModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(null);
  const { creditorId } = useParams();

  const handleSelectedPaymentsUpdated = React.useCallback((payments) => {
    setSelectedPayments(payments || []);
  }, []);

  const payments = useSelector((state) => state.billectaInvoicing.payments);
  const invoices = useSelector((state) => state.billectaInvoicing.invoices);

  const possibleInvoices = invoices?.filter((i) => {
    return POSSIBLE_INVOICE_STAGES.includes(i.Stage);
  });

  const handleOpenMatchModal = () => {
    setMatchModalOpen(true);
  };

  const handleRemovePayment = (original) => {
    const { UnhandledPaymentPublicId: paymentId } = original;
    setDeleteModalOpen(paymentId);
  };

  React.useEffect(() => {
    if (payments?.length > 0) {
      dispatch(clearAllPaymentsNotificationsForCreditor({ creditorId }));
    }
  }, [payments]);

  const matchSelectedDisabled = selectedPayments.some(
    (i) => i.original.State !== "Unhandled"
  );

  const isDev =
    window.location.host.includes("localhost") ||
    window.location.host.includes("demo.app");

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Betalningar att hantera</OverviewTitle>
            <OverviewSubtitle>
              Dessa betalningar kunde antingen ej matchas automatiskt mot en
              faktura eller så överstiger den inbetalade summan beloppet på
              fakturan.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        {deleteModalOpen && (
          <DeletePaymentsModal
            closeFunction={() => setDeleteModalOpen(null)}
            paymentId={deleteModalOpen}
          />
        )}

        {matchModalOpen && (
          <MatchPaymentsModal
            isOpen={matchModalOpen}
            closeFunction={() => setMatchModalOpen(false)}
            selectedPayments={selectedPayments}
            possibleInvoices={possibleInvoices}
            resetSelected={() => setSelectedPayments([])}
          />
        )}

        {payments ? (
          <Table
            payments={payments}
            handleSelectedPaymentsUpdated={handleSelectedPaymentsUpdated}
            selectedPayments={selectedPayments}
            matchSelectedDisabled={matchSelectedDisabled}
            handleMatchSelectedPayments={handleOpenMatchModal}
            handleRemovePayment={handleRemovePayment}
          />
        ) : (
          <ContainerSpinner />
        )}

        {isDev && <DevCreateUnmatchedPayment />}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
