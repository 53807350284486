import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  constants,
  create,
  useBasicDocForm,
  destroyPatchForm,
  destroyPostForm,
  detailUrl,
  update,
} from "../../../../store/basicDocs";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import Modal from "../../Base/Modals/Modal";
import TableSelectField from "../../Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import TextInputField from "../../Base/Fields/TextInputField";
import MediaField from "../../Base/Fields/MediaField";
import GenericField from "../../Base/FieldComponents/GenericField";

export default ({
  method,
  id,
  instance = { category: "document" },
  onCheckout,
}) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useBasicDocForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const preProcess = (data) => {
    const clonedData = cloneDeep(data);

    // the main file
    if (clonedData?.doc?._tempData) {
      // file has been edited, editabledoc.doc has been set
      // so delete docData (on the clone, we have the reference on formInstance)
      clonedData.doc = clonedData?.doc?.file;
    } else {
      // file has not been edited, don't send anything
      delete clonedData?.doc;
    }

    if (typeof clonedData.object_id === "object") {
      if (clonedData.object_id.hasOwnProperty("id")) {
        clonedData.object_id = clonedData.object_id.id;
      }
    }

    console.log("DATA", clonedData);

    return clonedData;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={method === "POST" ? "Lägg till dokument" : "Uppdatera dokument"}
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">
        Ange information och ladda upp dokument
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Namn på dokument"
          storeName={storeName}
          method={method}
          fieldKey="title"
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj fastighet..."
          title="Koppla till fastighet"
          method={method}
          fieldKey="realestate"
          TableComponent={RealEstateTable}
        />
      </div>
      <GenericField
        storeName={storeName}
        method={method}
        ctFieldKey="content_type"
        idFieldKey="object_id"
      />

      <MediaField
        title="Dokument"
        method={method}
        storeName={storeName}
        fieldKey={"doc"}
        allowedFormats={[
          ".jpg",
          ".png",
          ".jpeg",
          ".pdf",
          ".docx",
          ".odf",
          ".txt",
          ".md",
        ]}
      />
    </Modal>
  );
};
