import styled from "styled-components";

export const InputFieldTitle = styled.p`
  font-weight: bold;
  font-size: 0.8rem;
  color: ${(p) => p.theme.colors.black};
  margin: 0;
`;

export const SelectWrapper = styled.div`
  min-width: 250px;
  margin-right: 12px;
  z-index: 200;
`;
