export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_TENANTGROUPS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_TENANTGROUPS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_TENANTGROUPS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_TENANTGROUPS",
  DESTROY_FORM: "DESTROY_FORM_TENANTGROUPS",
  SET_FORM_ERROR: "SET_FORM_ERROR_TENANTGROUPS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_TENANTGROUPS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_TENANTGROUPS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_TENANTGROUPS",
  RESET_STATE: "RESET_STATE_TENANTGROUPS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_TENANTGROUPS",
  REMOVE_OBJECT: "REMOVE_OBJECT_TENANTGROUPS",
  CLEAR_FETCHED: "CLEAR_FETCHED_TENANTGROUPS",

  LIST_URL: "/brf/brfgroup/list/",
  POST_URL: "/brf/brfgroup/",
  PATCH_URL: "/brf/brfgroup/",
  GET_URL: "/brf/brfgroup/",

  STORE_NAME: "tenantGroups",
};
