import * as React from "react";
import { useDispatch } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

// style, design
import * as SC from "../Fields/styles";
import { CreateNewButton } from "../Buttons";

// store, state
import {
  updateActiveFormInstance,
  updateFormErrors,
  useFormError,
  useFormInstanceField,
  useFormField,
} from "../../../../store/base";
import StandardModal from "../../../Modals/StandardModal";

export default ({
  storeName,
  fieldKey,
  title,
  description,
  instructionsKey,
  method,
  modalTitle,
  getChildren,
  constructStrRep,
  modalInModal,
}) => {
  const dispatch = useDispatch();

  // -1: off, >0: on
  const [handleIsActive, setHandleActive] = React.useState(-1);
  const currentValue = useFormInstanceField({ storeName, fieldKey }) || [];
  const currentError = useFormError({ storeName, fieldKey });

  const instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });

  const onCreateClicked = () => {
    const length = currentValue.length;

    // create a new empty spot
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: [...currentValue, { _index: length }],
        },
      })
    );

    // open up handleModal
    setHandleActive(currentValue.length);
  };

  const onEditClicked = (index) => {
    // open up handleModal
    setHandleActive(index);
  };

  const onRemoveClicked = (index) => {
    let copy = cloneDeep(currentValue);
    copy.splice(index, 1);
    copy.forEach((c, index) => (c._index = index));

    dispatch(
      updateActiveFormInstance({ storeName, data: { [fieldKey]: copy } })
    );

    // also remove the errors
    if (currentError && Array.isArray(currentError)) {
      let errorCopy = cloneDeep(currentError);
      errorCopy.splice(index, 1);

      dispatch(
        updateFormErrors({ storeName, data: { [fieldKey]: errorCopy } })
      );
    }
  };

  const checkCreation = () => {
    // make sure that at least one value has been filled out
    // otherwice just remove the value
    const last = currentValue.slice(-1)[0];
    if (last) {
      const keys = Object.keys(last);
      if (keys.length === 1 && keys[0] === "_index") {
        // remove it
        let updated = [...currentValue];
        updated.splice(-1, 1);
        dispatch(
          updateActiveFormInstance({ storeName, data: { [fieldKey]: updated } })
        );
      }
    }
  };

  const onAbort = () => {
    checkCreation();
    setHandleActive(-1);
  };

  const onSave = () => {
    checkCreation();
    setHandleActive(-1);
  };

  if (!instructions) {
    return null;
  }
  if (instructions._readOnly) {
    return null;
  }

  return (
    <>
      <StandardModal
        isOpen={handleIsActive >= 0}
        withActionBar
        title={modalTitle ? modalTitle : false}
        closeFunction={onAbort}
        saveFunction={onSave}
        modalInModal={modalInModal}
        children={handleIsActive >= 0 ? getChildren(handleIsActive) : null}
      />

      <SC.InputSpacing>
        <SC.InputFieldTitle>{title}</SC.InputFieldTitle>
        <SC.InputFieldDescription>{description}</SC.InputFieldDescription>

        <SC.CustomM2MLabelsWrapper>
          {currentValue.map((item, index) => {
            let hasErr =
              currentError &&
              Array.isArray(currentError) &&
              currentError[item._index];
            if (hasErr) {
              const fieldErr = currentError[item._index];
              if (
                fieldErr.constructor === Object &&
                !Object.keys(fieldErr).length
              ) {
                hasErr = false;
              }
            }

            return (
              <>
                <SC.M2mLabel key={index}>
                  {item._index !== undefined ? (
                    <div
                      onClick={() => onEditClicked(item._index)}
                      style={hasErr ? { color: "red" } : {}}
                    >
                      {constructStrRep(item)}
                    </div>
                  ) : (
                    constructStrRep(item)
                  )}
                  <SC.M2mLabelRemoveButton
                    onClick={() => onRemoveClicked(item._index)}
                  />
                </SC.M2mLabel>
              </>
            );
          })}
        </SC.CustomM2MLabelsWrapper>
        {!!currentError && (
          <SC.ErrorMessage>Några av objekten innehåller fel</SC.ErrorMessage>
        )}

        <CreateNewButton title={`Lägg till fler`} clicked={onCreateClicked} />
      </SC.InputSpacing>
    </>
  );
};
