export const constructStrRep = (obj) => {
  return obj.str_representation || "Annonsdetaljer";
};

export const overviewUrl = () => {
  return `/market/ads`;
};

export const createManyMenuUrl = ({ type }) =>
  `/market/ads/create-many-commodities/${type}`;

export const createManyDefaultSettingUrl = ({ type }) =>
  `/market/ads/create-many-commodities/${type}/default-settings`;

export const createManyUrl = ({ type }) =>
  `/market/ads/create-many-commodities/${type}/handle`;
