import * as React from "react";

import Table from "../../Billecta/Table/BasicTable";

import moment from "moment";
import { cloneDeep } from "lodash";
import { toMoneyString } from "../../utils/stringUtils";

export default function LoanSubTable({ loan }) {
  const boldOrNotBold = (value, row) => {
    if (row.original?._finalRow) {
      return <b>{value}</b>;
    }

    return value;
  };

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Period start",
        accessor: "period_start",
        Cell: ({ value, row }) => <div>{boldOrNotBold(value, row)}</div>,
      },
      {
        Header: "Period slut",
        accessor: "period_end",
        Cell: ({ value, row }) => <div>{boldOrNotBold(value, row)}</div>,
      },
      {
        Header: "Totalt",
        accessor: "total",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Amortering",
        accessor: "amortization_value",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Ränteavgift",
        accessor: "interest_fee_to_pay",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Låst ränta, Avgift",
        accessor: "firm_interest_fee_to_pay",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Skuld efter amortering",
        accessor: "left_on_loan_after_payment",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
    ];
  }, []);

  let earliestStart = null;
  let latestEnd = null;

  let total_amortization = 0;
  let total_interest = 0;
  let total_interest_firm_fee = 0;
  let total = 0;

  let data = cloneDeep(loan.amortization_previews ?? []);
  data.forEach((item) => {
    const start = moment(item.period_start);
    const end = moment(item.period_end);
    if (start && (!earliestStart || earliestStart > start)) {
      earliestStart = start;
    }
    if (end && (!latestEnd || latestEnd < end)) {
      latestEnd = end;
    }

    total_amortization += item.amortization_value;
    total_interest += item.interest_fee_to_pay;
    total_interest_firm_fee += item.firm_interest_fee_to_pay ?? 0;
    total += item.total;
  });

  if (earliestStart != null && latestEnd != null) {
    data.push({
      period_start: earliestStart.format("YYYY-MM-DD"),
      period_end: latestEnd.format("YYYY-MM-DD"),
      amortization_value: total_amortization,
      interest_fee_to_pay: total_interest,
      firm_interest_fee_to_pay: total_interest_firm_fee,
      total: total,
      _finalRow: true,
    });
  }

  return (
    <Table
      columns={tableColumns}
      data={data}
      hideSearch
      forceInitialPageSize={data.length}
      extraStyles={{ marginTop: 0 }}
    />
  );
}
