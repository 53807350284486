import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";

import constants from "./constants";

const STATE = {
  ...INITIAL_STATE,
};

export default (state = STATE, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.CLEAR_SENSORS_FROM_FORM_INSTANCE:
      newState.formInstance.sensors = [];
      break;
    default:
      return newState;
  }

  return newState;
};
