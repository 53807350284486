import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import { useDispatch } from "react-redux";
import { attestMultipleInvoices } from "../../store/billectaInvoicing";
import Modal from "../Forms/Base/Modals/Modal";

export default function AttestInvoicesModal({
  invoiceIds,
  creditorId,
  closeFunction,
}) {
  const dispatch = useDispatch();
  const [attestedCount, setAttestedCount] = React.useState(0);
  const [failedCount, setFailedCount] = React.useState(0);
  const [started, setStarted] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [failed, setFailed] = React.useState(0);
  const [done, setDone] = React.useState(false);

  const onAttest = () => {
    setStarted(true);
    setInProgress(true);
    dispatch(
      attestMultipleInvoices({
        invoiceIds,
        creditorId,
        onProgress: ({ loaded }) => {
          setAttestedCount(loaded);
        },
        onFailed: ({ failed }) => {
          setFailedCount(failed);
        },
        successCallback: () => {
          setDone(true);
          setInProgress(false);
        },
        errorCallback: () => {
          setFailed(true);
          setInProgress(false);
        },
      })
    );
  };

  const attestedPercentage =
    ((attestedCount + failedCount) / invoiceIds?.length) * 100;
  return (
    <Modal
      title="Attestera fakturor"
      onAccept={done || inProgress ? null : onAttest}
      acceptTitle={done ? "Klar" : `Attestera ${invoiceIds?.length} fakturor`}
      closeFunction={inProgress ? null : closeFunction}
      canAccept={!inProgress}
      canClose={!inProgress}
    >
      {failed ? (
        <>
          <div className="flex flex-col items-center justify-center">
            <ExclamationTriangleIcon width={64} className="text-red-600 mb-4" />
            <div className="text-slate-600">Attestering mislyckades</div>
          </div>
        </>
      ) : done ? (
        <>
          <div className="flex flex-col items-center justify-center">
            <CheckBadgeIcon width={64} className="text-green-600 mb-4" />
            <div className="text-slate-600">Fakturorna attesterades</div>

            {failedCount > 0 && (
              <div className="text-red-500 mt-4 flex items-center">
                <ExclamationTriangleIcon width={16} className="mr-1" />
                {failedCount} kunde ej attesteras
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between mb-1">
            <span className="text-base font-medium text-blue-700 ">
              {started
                ? "Attesterar fakturor..."
                : 'Tryck på "Attestera" för att påbörja attestering'}
            </span>
            <span className="text-sm font-medium text-blue-700 ">
              {attestedCount}/{invoiceIds?.length}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 ">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${attestedPercentage}%` }}
            ></div>
          </div>
        </>
      )}
    </Modal>
  );
}
