import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    instructionsKey,
    overrideInstructions,
    noMargin,
    extraStyles = {},
    noHelp,
    small,
    alwaysShowHelp,
    title,
    description,
    options = [],
    defaultValue,
    disabled,

    // top, bottom
    forceVerticalToolTipPlacement,
    //left ,right
    forceHorizontalToolTipPlacement,
  }) => {
    const dispatch = useDispatch();

    const instructions =
      useFormField({
        storeName,
        method,
        fieldKey: instructionsKey || fieldKey,
      }) || overrideInstructions;
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    React.useEffect(() => {
      if (value === undefined && defaultValue === null) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: defaultValue },
          })
        );
      }

      if (value == null && defaultValue != null) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: defaultValue },
          })
        );
      }
    }, []);

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );
    };

    return (
      <SC.InputSpacing {...{ small, noMargin }} style={extraStyles}>
        <SC.InputFieldTitle
          style={{ display: "flex", alignItems: "center", marginBottom: 12 }}
        >
          {title} {instructions._required ? "*" : ""}{" "}
          {(instructions._helpText || description) &&
            !alwaysShowHelp &&
            !noHelp && (
              <DescriptionToolTip
                forceHorizontalPlacement={forceHorizontalToolTipPlacement}
                forceVerticalPlacement={forceVerticalToolTipPlacement}
                description={description || instructions._helpText}
              />
            )}
        </SC.InputFieldTitle>
        {options.map((o) => {
          return (
            <SC.CheckBoxWrapper small noMargin key={`${fieldKey}_${o.label}`}>
              <SC.CheckBox
                disabled={disabled}
                small
                radioStyle
                id={`${fieldKey}_${o.label}`}
                checked={value === o.value}
                onChange={() => onChange(o.value)}
              />
              <label htmlFor={`${fieldKey}_${o.label}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {o.label}
                </div>
              </label>
            </SC.CheckBoxWrapper>
          );
        })}

        {alwaysShowHelp && <div>{instructions._helpText}</div>}
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
