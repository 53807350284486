import {
  BellAlertIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import * as React from "react";

export const ALERT_TYPES = {
  INFO: "INFO",
  DANGER: "DANGER",
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  GRAY: "GRAY",
};

const TYPE_CONTAINER_STYLES = {
  INFO: "border-blue-300 bg-blue-50",
  DANGER: "border-red-300 bg-red-50 ",
  SUCCESS: "border-green-300 bg-green-50",
  WARNING: "border-yellow-300  bg-yellow-50",
  GRAY: "border-gray-300 bg-gray-50",
};

const TYPE_TEXT_STYLES = {
  INFO: "text-blue-900",
  DANGER: "text-red-900 ",
  SUCCESS: "text-green-700",
  WARNING: "text-yellow-700",
  GRAY: "text-gray-700",
};

const TYPE_BUTTON_STYLES = {
  INFO: "text-white bg-blue-900 hover:bg-blue-800",
  DANGER: "text-white bg-red-900 hover:bg-red-800",
  SUCCESS: "text-white bg-green-700 hover:bg-green-800",
  WARNING: "text-white bg-yellow-700 hover:bg-yellow-800 ",
  GRAY: "text-white bg-gray-700 hover:bg-gray-800",
};

const TYPE_SECONDARY_BUTTON_STYLES = {
  INFO: "text-blue-900 bg-transparent border border-solid border-blue-900 hover:bg-blue-800 hover:text-white",
  DANGER:
    "text-red-900 bg-transparent border border-solid border-red-900 hover:bg-red-900 hover:text-white",
  SUCCESS:
    "text-green-700 bg-transparent border border-solid border-green-700 hover:bg-green-800 hover:text-white",
  WARNING:
    "text-yellow-700 bg-transparent border border-solid border-yellow-700 hover:bg-yellow-800 hover:text-white ",
  GRAY: "text-gray-700 bg-transparent border border-solid border-gray-700 hover:bg-gray-800 hover:text-white ",
};

export default function Alert({
  type = ALERT_TYPES.INFO,
  title,
  children,
  primaryTitle,
  secondaryTitle,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  tertiaryTitle,
  className = ""
}) {
  const renderIcon = () => {
    switch (type) {
      case ALERT_TYPES.DANGER:
        return (
          <ExclamationTriangleIcon
            width={24}
            className={`${TYPE_TEXT_STYLES[type]} mr-2`}
          />
        );
      case ALERT_TYPES.SUCCESS:
        return (
          <CheckCircleIcon
            width={24}
            className={`${TYPE_TEXT_STYLES[type]} mr-2`}
          />
        );
      case ALERT_TYPES.WARNING:
        return (
          <BellAlertIcon
            width={24}
            className={`${TYPE_TEXT_STYLES[type]} mr-2`}
          />
        );

      default:
        return (
          <InformationCircleIcon
            width={24}
            className={`${TYPE_TEXT_STYLES[type]} mr-2`}
          />
        );
    }
  };
  return (
    <div
      className={classNames(`relative p-4 mb-4 border ${TYPE_CONTAINER_STYLES[type]} rounded-lg`, className)}
      role="alert"
    >
      <div className="flex items-center">
        {renderIcon()}

        <h3 className={`text-lg font-medium ${TYPE_TEXT_STYLES[type]}`}>
          {title}
        </h3>
      </div>
      <div
        className={`mt-2 mb-4 text-sm ${TYPE_TEXT_STYLES[type]} overflow-hidden`}
      >
        {children}
      </div>
      <div className="flex space-x-2">
        {primaryAction && (
          <button
            className={` ${TYPE_BUTTON_STYLES[type]} focus:ring-4 focus:outline-none whitespace-nowrap font-medium rounded text-sm px-4 py-2 text-center inline-flex items-center `}
            onClick={(e) => {
              e.preventDefault();
              primaryAction();
            }}
            type="button"
          >
            {primaryTitle}
          </button>
        )}
        {secondaryAction && (
          <button
            className={` ${TYPE_SECONDARY_BUTTON_STYLES[type]} focus:ring-4 focus:outline-none whitespace-nowrap font-medium rounded text-sm px-4 py-2 text-center inline-flex items-center `}
            onClick={(e) => {
              e.preventDefault();
              secondaryAction();
            }}
            type="button"
          >
            {secondaryTitle}
          </button>
        )}

        {tertiaryAction && (
          <button
            className={` ${TYPE_SECONDARY_BUTTON_STYLES[type]} focus:ring-4 focus:outline-none whitespace-nowrap font-medium rounded text-sm px-4 py-2 text-center inline-flex items-center `}
            onClick={(e) => {
              e.preventDefault();
              tertiaryAction();
            }}
            type="button"
          >
            {tertiaryTitle}
          </button>
        )}
      </div>
    </div>
  );
}
