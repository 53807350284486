import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Header = styled.h1`
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 40px;
`;
