import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

// style, design
import * as SC from "../../../Base/Chapters/styles";
import { NumberInput} from "../../../Base/Fields";
import FullPageSpinner from "../../../../Loaders/FullPageSpinner";
import { LinkButton, PrimaryButton } from "../../../Base/Buttons";

// store, state
import {
  constants,
  update,
  destroyPatchForm,
  useConfigCenterForm,
} from "../../../../../store/configcenter";

import {
  setActiveFormInstance,
  usePermissionCheck,
} from "../../../../../store/base";

import { useHistory } from "react-router-dom";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import { InfoBox } from "../../../../Displays";
import { OverviewTitle } from "../../../../Details/OverviewInfo/styles";

export default ({ method, untouchedInstance, nextUrl }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { push, goBack } = useHistory();

  const formLoaded = Boolean(
    useConfigCenterForm(method, storeName, untouchedInstance?.id)
  );

  const hasChanged = useSelector((state) => {
    return !isEqual(untouchedInstance, state[storeName].formInstance);
  });

  const canSubmit = usePermissionCheck(`change_can_configcenter`);

  React.useEffect(() => {
    dispatch(
      setActiveFormInstance({
        storeName,
        data: untouchedInstance,
      })
    );
  }, [untouchedInstance]);

  const checkout = (success) => {
    setLoading(false);
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
      push(nextUrl || `/configcenter/general/gdpr-cleaning`);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: (data, returnData) => {
            // set current configcenter so form and overview are updated properly
            dispatch({
              type: constants.SET_CURRENT_CONFIGCENTER,
              payload: {
                returnData,
              },
            });
            checkout(true);
          },
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  if (!formLoaded) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <SC.FormArea>
        {loading && <OverlaySpinner />}

        <OverviewTitle small>GDPR-Inställningar</OverviewTitle>
        <InfoBox
          extraStyles={{ maxWidth: "810px" }}
          title="Anonymisering av hyresgäster"
          text="Om en tid har angetts här så kommer Pigello automatiskt att anonymisera tidigare hyresgäster efter angiven tid"
        />
        <NumberInput
          storeName={storeName}
          fieldKey={"tenant_anonymization_grace_period"}
          method={method}
          title="Antal dagar efter avtal som hyresgäst automatiskt anonymiseras"
        />
        <InfoBox
          extraStyles={{ maxWidth: "810px" }}
          title="Radering av hyresgäster"
          text="Om en tid har angetts här så kommer Pigello automatiskt att radera tidigare hyresgäster efter angiven tid"
        />
        <NumberInput
          storeName={storeName}
          fieldKey={"tenant_deletion_grace_period"}
          method={method}
          title="Antal dagar efter avtal som hyresgäst automatiskt raderas"
        />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <LinkButton clicked={() => goBack()} title={"Avbryt"} />
          <PrimaryButton
            newDesign
            clicked={onSubmit}
            title={method === "POST" ? "Lägg till" : "Uppdatera"}
            disabled={!canSubmit || !hasChanged}
          />
        </div>
      </SC.FormArea>
    </>
  );
};
