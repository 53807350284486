import baseReducer from "../../billecta/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_SEARCH_INVOICES:
      return { ...newState, [payload.processName]: payload.invoices };

    case constants.SET_ACCOUNTS_RECEIVABLE_BY_DATE: {
      return {
        ...newState,
        [`${payload.creditorId}-${payload.date}-${payload.excludeInvoicesWithPeriodOutsideDate}`]:
          payload.invoices,
      };
    }

    case constants.CLEAR_DATA: {
      return { forms: {} };
    }

    default:
      return newState;
  }
};
