import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PopupButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { useHistory } from "react-router-dom";
import { useAllPermissionCheck } from "../../../store/base";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { axiosInstance } from "../../../store/base/store/axios";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

export default function InfometricIntegration() {
  const dispatch = useDispatch();
  const [infometricIsActivated, setInfometricIsActivated] =
    React.useState(undefined);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deletionOpen, setDeletionOpen] = React.useState(false);

  const [username, setUsername] = React.useState(undefined);
  const [password, setPassword] = React.useState(undefined);

  const canChangeImd = useAllPermissionCheck(["allow_imd", "change_can_imd"]);
  const canDeleteImd = useAllPermissionCheck(["allow_imd", "delete_can_imd"]);

  const checkHasActivated = async () => {
    // undeinfed is not collected - null is bad collection
    // true false is the result
    if (infometricIsActivated !== undefined) {
      return infometricIsActivated;
    }

    try {
      const result = await axiosInstance.get("imd/infometric/check_activated/");
      const activated = result?.data?.is_activated ?? null;
      setInfometricIsActivated(activated);
    } catch (err) {
      setInfometricIsActivated(null);
    }
  };

  React.useEffect(() => {
    checkHasActivated();
  }, []);

  const clearInput = () => {
    setUsername(undefined);
    setPassword(undefined);
  };

  const performModalSave = async () => {
    setModalOpen(false);

    try {
      if (infometricIsActivated) {
        await axiosInstance.put("imd/infometric/handle/", {
          username: username,
          password: password,
        });
      } else {
        await axiosInstance.post("imd/infometric/handle/", {
          username: username,
          password: password,
        });
      }
      dispatch(
        addToast({
          title: infometricIsActivated
            ? "Infometric-informationen har uppdaterats"
            : "Infometric-integrationen har aktiverats",
          type: TOAST_TYPES.SUCCESS,
        })
      );

      if (!infometricIsActivated) {
        setInfometricIsActivated(true);
      }
    } catch (err) {
      dispatch(
        addToast({
          title: infometricIsActivated
            ? "Kunde inte uppdatera Infometric-informationen"
            : "Kunde inte aktivera Infometric-integrationen",
          type: TOAST_TYPES.ERROR,
        })
      );
    }

    clearInput();
  };

  const performDeletion = async () => {
    try {
      await axiosInstance.delete("imd/infometric/handle/");
      dispatch(
        addToast({
          title: "Infometric-integrationen har tagits bort",
          type: TOAST_TYPES.SUCCESS,
        })
      );
      setInfometricIsActivated(false);
    } catch (error) {
      dispatch(
        addToast({
          title: "Kunde inte ta bort Infometric-integrationen",
          type: TOAST_TYPES.ERROR,
        })
      );
    }

    setDeletionOpen(false);
  };

  const performModalClose = () => {
    setModalOpen(false);

    clearInput();
  };

  const infoMetricActions = [
    {
      name: "Uppdatera information",
      onClick: () => setModalOpen(true),
      hidden: infometricIsActivated !== true || !canChangeImd,
    },
    {
      name: "Aktivera integration",
      onClick: () => setModalOpen(true),
      hidden: infometricIsActivated !== false,
    },
    {
      name: "Ta bort integration",
      onClick: () => setDeletionOpen(true),
      hidden: infometricIsActivated !== true || !canDeleteImd,
    },
  ];

  const current = INTEGRATIONS.find((i) => i.title === "Infometric");

  const { push } = useHistory();

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <ConfirmationModal
        isOpen={deletionOpen}
        closeFunction={() => setDeletionOpen(false)}
        acceptCallback={performDeletion}
        acceptTitle={"Radera"}
        renderContent={() => (
          <div>Är du säker på att du vill ta bort integrationen?</div>
        )}
      />
      <StandardModal
        isOpen={modalOpen}
        saveFunction={performModalSave}
        closeFunction={performModalClose}
        title={
          infometricIsActivated
            ? "Uppdatera Infometric Information"
            : "Aktivera infometric"
        }
        canAccept={
          username != null &&
          username !== "" &&
          password != null &&
          password != ""
        }
        withActionBar
      >
        <NonConnectedTextInput
          id="username"
          value={username}
          onChange={setUsername}
          label={infometricIsActivated ? "Nytt användarnamn" : "Användarnamn"}
        />
        <NonConnectedTextInput
          id="password"
          value={password}
          onChange={setPassword}
          label={infometricIsActivated ? "Nytt lösenord" : "Lösenord"}
        />
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Infometrics hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Infometric-integrationen ställs in globalt. När den väl är
                  kopplad så hämtas mätvärden och sensorer automatiskt in från
                  Infometric till Pigello.
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Aktivera Infometric-integrationen genom att logga in mot
                  Infometric i Pigello.
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Sensorer och mätvärden hämtas löpande in från Infometric.
                  <br />
                  <strong>Steg 3:</strong>
                  <br />
                  Koppla ihop sensorerna från Infometric mot objekt i Pigello
                  för att matcha mätvärden mot lägenheter, lokaler och
                  fordonsplatser.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hantera integration</OverviewTitle>
                  <OverviewSubtitle>
                    {infometricIsActivated === true ? (
                      <div>Denna integrationen är aktiverad</div>
                    ) : infometricIsActivated === false ? (
                      <div>Denna integrationen är inte aktiverad</div>
                    ) : (
                      <div>
                        {infometricIsActivated === undefined
                          ? "Laddar..."
                          : "Kunde ej hämta informationen som behövs, behörighet saknas"}
                      </div>
                    )}
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              {infoMetricActions?.length > 0 && (
                <div>
                  <PopupButton
                    leftAligned
                    title="Hantera"
                    actions={infoMetricActions}
                  />
                </div>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
