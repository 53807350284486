export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ERRAND_COMPONENT_TYPES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ERRAND_COMPONENT_TYPES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ERRAND_COMPONENT_TYPES",
  DESTROY_FORM: "DESTROY_FORM_ERRAND_COMPONENT_TYPES",
  SET_FORM_ERROR: "SET_FORM_ERROR_ERRAND_COMPONENT_TYPES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ERRAND_COMPONENT_TYPES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ERRAND_COMPONENT_TYPES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ERRAND_COMPONENT_TYPES",
  RESET_STATE: "RESET_STATE_ERRAND_COMPONENT_TYPES",
  CLEAR_FETCHED: "CLEAR_FETCHED_ERRAND_COMPONENT_TYPES",
  SET_ASK_DELETE_DATA: "SET_DELETE_DATA_COMPONENT_TYPES",
  REMOVE_OBJECT: "REMOVE_OBJECT_ERRAND_COMPONENT_TYPES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ERRAND_COMPONENT_TYPES",

  LIST_URL: "/errands/components/componenttype/list/",
  POST_URL: "/errands/components/componenttype/",
  PATCH_URL: "/errands/components/componenttype/",
  GET_URL: "/errands/components/componenttype/",

  STORE_NAME: "componentTypes",
};
