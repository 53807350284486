import { getToken } from "../../../utils";
import { axiosInstance } from "../../base/store/axios";

const URL = "/general/global_search/";

async function performSearch(query) {
  const body = {
    limit: 30,
    query,
    categories: [
      "accounts.tenant",
      "standard.company",
      "standard.building",
      "standard.blueprint",
      "standard.basicdoc",
      "standard.apartment",
      "standard.industrialpremises",
      "standard.leasecontract",
      "standard.othercontract",
      "standard.parkinglot",
      "standard.parkingspot",
      "standard.parkingcontract",
      "standard.servicepartner",
      "standard.servicecontract",
      "accounting.project",
      "accounting.costcenter",
      "accounting.product",
      "accounting.debthandlingsetting",
      "accounting.imdinvoiceconfig",
      "accounting.invoicingsetting",
      "accounting.leaseinvoicing",
      "accounting.otherinvoicing",
      "accounting.parkinginvoicing",
      "accounting.brfinvoicing",
      "accounting.serviceinvoicing",
      "accounting.reporterrandinvoicing",
      "accounting.inspectionerrandinvoicing",
      "accounting.leaseimdinvoicing",
      "accounting.parkingimdinvoicing",
      "accounting.brfimdinvoicing",
      "errands.reporterrandsetting",
      "errands.inspectionerrandsetting",
      "errands.roundingerrandsetting",
      "errands.inspectionerrand",
      "errands.reporterrand",
      "errands.roundingerrand",
      "brf.brfpremis",
      "brf.brfcompany",
      "imd.sensor",
    ],
  };

  const token = getToken().token;
  try {
    const { data: results } = await axiosInstance.post(URL, body, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });

    return results;
  } catch (e) {
    console.log(e);
  }
}

export { performSearch };
