import * as React from "react";
import { useSelector } from "react-redux";
import { InfoBox } from "../../../../Displays";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";

import FloorSelector from "../../../Base/FieldComponents/FloorSelector";
import { MASS_CONFIG_OBJECT_TYPES_KEYS } from "./Type";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default function Query({ updateFunction, storeName }) {
  const instance = useSelector((state) => state[storeName].instance);

  return (
    <>
      {!instance?.type ? (
        <>
          <InfoBox
            title="Välj typ av objekt "
            text="Välj typ av objekt först för att kunna specificera filtrering"
          />
        </>
      ) : (
        <>
          {instance.type !== MASS_CONFIG_OBJECT_TYPES_KEYS.PARKING &&
            instance.type !== MASS_CONFIG_OBJECT_TYPES_KEYS.BUILDING &&
            instance.type !== MASS_CONFIG_OBJECT_TYPES_KEYS.REALESTATE && (
              <>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Filtrering</OverviewTitle>
                    <OverviewSubtitle>
                      Ange endast filtrering om du vill filtrera specifikt på
                      det attributet. Tomma fält innebär att filtreringen inte
                      tar effekt alls. T.ex. lämnas Våningsplan tomt om du vill
                      att alla objekt oavsett våningsplan ska inkluderas.
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <div className="grid grid-cols-2 gap-6 mb-6">
                  <LocalTableSelectField
                    TableComponent={BuildingTable}
                    value={instance.buildings}
                    title="Byggnader"
                    placeholder="Välj byggnader..."
                    description="Välj byggnader där objekt ska påverkas"
                    onChange={(val) =>
                      updateFunction({ key: "buildings", value: val })
                    }
                    isMany
                  />
                </div>

                <NonConnectedSelect
                  label="Kategorier"
                  helpText="Välj vilka kategorier på objekt som ska påverkas"
                  choices={CATEGORY_CHOICES[instance.type]}
                  value={instance?.categories}
                  id="categories"
                  isMulti
                  onUpdate={(val) =>
                    updateFunction({ key: "categories", value: val })
                  }
                  getOptionValue={(choice) => choice.v}
                  getOptionLabel={(choice) => choice.d}
                  bodyPortaled
                  customSelectorMethod={({ value, choices }) =>
                    value?.map((v) => choices.find((c) => c.v == v))
                  }
                />

                <FloorSelector
                  customUpdateHandler={(value) =>
                    updateFunction({ key: "floors", value })
                  }
                  floors={instance.floors}
                />
              </>
            )}

          {(instance.type === MASS_CONFIG_OBJECT_TYPES_KEYS.PARKING ||
            instance.type === MASS_CONFIG_OBJECT_TYPES_KEYS.BUILDING ||
            instance.type === MASS_CONFIG_OBJECT_TYPES_KEYS.REALESTATE) && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={RealEstateTable}
                value={instance.realestates}
                title="Fastigheter"
                placeholder="Välj fastigheter..."
                description="Välj fastigheter där objekt ska påverkas"
                onChange={(val) =>
                  updateFunction({ key: "realestates", value: val })
                }
                isMany
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

const CATEGORY_CHOICES = {
  APARTMENT: [
    {
      v: "1",
      d: "1 RoK",
    },
    {
      v: "1.5",
      d: "1.5 RoK",
    },
    {
      v: "2",
      d: "2 RoK",
    },
    {
      v: "2.5",
      d: "2.5 RoK",
    },
    {
      v: "3",
      d: "3 RoK",
    },
    {
      v: "3.5",
      d: "3.5 RoK",
    },
    {
      v: "4",
      d: "4 RoK",
    },
    {
      v: "4.5",
      d: "4.5 RoK",
    },
    {
      v: "5",
      d: "5 RoK",
    },
    {
      v: "5.5",
      d: "5.5 RoK",
    },
    {
      v: "6",
      d: "6 RoK",
    },
    {
      v: "6.5",
      d: "6.5 RoK",
    },
    {
      v: "other",
      d: "Annan",
    },
  ],
  INDP: [
    {
      v: "office",
      d: "Kontor",
    },
    {
      v: "shop",
      d: "Butik",
    },
    {
      v: "industrial",
      d: "Industri",
    },
    {
      v: "warehouse",
      d: "Lager",
    },
    {
      v: "restaurant",
      d: "Restaurang",
    },
    {
      v: "education",
      d: "Utbildning",
    },
    {
      v: "bakery",
      d: "Bageri",
    },
    {
      v: "barber",
      d: "Frisör",
    },
    {
      v: "healt_care",
      d: "Sjukvård och Hälsa",
    },
    {
      v: "meetingroom",
      d: "Mötesrum",
    },
    {
      v: "food",
      d: "Livsmedelsindustri",
    },
    {
      v: "hotel",
      d: "Hotell",
    },
    {
      v: "storage",
      d: "Förråd",
    },
    {
      v: "ground",
      d: "Mark",
    },
    {
      v: "other",
      d: "Annan",
    },
    {
      v: "gym",
      d: "Gym",
    },
    {
      v: "leisure",
      d: "Fritidsverksamhet",
    },
    {
      v: "workshop",
      d: "Verkstad",
    },
    {
      v: "veterinary",
      d: "Veterinär",
    },
    {
      v: "library",
      d: "Bibliotek",
    },
    {
      v: "atm",
      d: "Bankomat",
    },
    {
      v: "premises",
      d: "Lokal",
    },
    {
      v: "spot",
      d: "Uppställningsplats",
    },
  ],
  COMMON: [
    {
      v: "office",
      d: "Kontor",
    },
    {
      v: "education",
      d: "Utbildning",
    },
    {
      v: "meetingroom",
      d: "Mötesrum",
    },
    {
      v: "storage",
      d: "Förråd",
    },
    {
      v: "ground",
      d: "Mark",
    },
    {
      v: "other",
      d: "Annan",
    },
    {
      v: "gym",
      d: "Gym",
    },
    {
      v: "leisure",
      d: "Fritidsverksamhet",
    },
    {
      v: "workshop",
      d: "Verkstad",
    },
    {
      v: "library",
      d: "Bibliotek",
    },
    {
      v: "laundry",
      d: "Tvättstuga",
    },
    {
      v: "community",
      d: "Gemensamhetsutrymme",
    },
    {
      v: "entrance",
      d: "Entré",
    },
    {
      v: "staircase",
      d: "Trapphus",
    },
    {
      v: "elevator_room",
      d: "Hissrum",
    },
    {
      v: "utility_room",
      d: "Förråd",
    },
    {
      v: "machine_room",
      d: "Maskinrum",
    },
    {
      v: "playground",
      d: "Lekplats",
    },
    {
      v: "garden",
      d: "Trädgård",
    },
  ],
  BRF: [
    { v: 0, d: "Annan" },
    {
      v: 1,
      d: "1 rum",
    },
    {
      v: 1.5,
      d: "1.5 rum",
    },
    {
      v: 2,
      d: "2 rum",
    },
    {
      v: 2.5,
      d: "2.5 rum",
    },
    {
      v: 3,
      d: "3 rum",
    },
    {
      v: 4,
      d: "4 rum ",
    },
    {
      v: 5,
      d: "5 rum",
    },
    {
      v: 6,
      d: "6 rum",
    },
  ],
  PARKING: [],
};
