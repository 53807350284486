import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  usePortalSettingTermsForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/tenantPortalSettingTerms";
import {
  constants as settingConstants,
  update as settingUpdate,
  useTenantPortalSetting,
} from "../../../../store/tenantPortalSettings";
import renderChapter from "./renderChapter";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";

export default ({ method, id, isOpen, onCheckout, existingData }) => {
  const dispatch = useDispatch();

  const { id: routeId } = useParams();
  const [currentSetting] = useTenantPortalSetting(id || routeId);

  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(usePortalSettingTermsForm(method, id));

  React.useEffect(() => {
    if (formLoaded && existingData) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: { ...existingData },
        })
      );
    }
  }, [formLoaded, isOpen]);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const addTermToSetting = (returned) => {
    const settingClone = cloneDeep(currentSetting);

    settingClone.terms.push({ id: returned.id });
    dispatch(
      updateActiveFormInstance({
        storeName: settingConstants.STORE_NAME,
        data: settingClone,
      })
    );
    dispatch(
      settingUpdate({
        id: id || routeId,
        successCallback: onFullSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onFullSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const onSuccess = (_, returned) => {
    if (method === "POST") {
      addTermToSetting(returned);
    } else {
      checkout(true);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Lägg till dokument" : "Uppdatera dokument"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
