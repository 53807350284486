import { checkTrailingUrlSlash } from "../../base";
import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

export async function performApartmentUpdate({ body }) {
  const res = await axiosInstance.post(
    checkTrailingUrlSlash(constants.APARTMENT_RENT_INCREASE_UPDATE_URL),
    body
  );
  return res;
}
export async function performIndpUpdate({ body }) {
  const res = await axiosInstance.post(
    checkTrailingUrlSlash(constants.INDP_RENT_INCREASE_UPDATE_URL),
    body
  );
  return res;
}

export async function getRentIncreases(querystr) {
  const res = await axiosInstance.get(
    `${constants.LIST_URL}${querystr ? `?${querystr}` : ""}`
  );

  return res.data;
}
