import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useFilteredIndustrialPremisesCommodities } from "../../../store/marketIndustrialPremisesCommodities";

import { detailUrl as pipeDetailUrl } from "../../../store/pipes";
import { detailUrl as requirementDetailUrl } from "../../../store/marketRequirements";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import AdForm from "../../../components/Forms/MarketIndustrialPremisesAd/ChapterForm/ModalForm";
import CommoditiesForm from "../../../components/Forms/MarketIndustrialPremisesCommodities/ChapterForm/ModalForm";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { buildQueryString } from "../../../store/base";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import SyncErrorTable, {
  SYNC_ERROR_MAP,
} from "../../../components/Tables/ExternalSyncErrors/FullTable";
import { useExternalSyncErrorPaginationCount } from "../../../store/externalSyncErrors";
import { DateCell, LinkedObject } from "../../../components/Displays";
import LeadsTable from "../../../components/Tables/Leads/FullTable";
import { getAdStateAndLabel } from "../../../store/marketApartmentAds/utils";
import {
  useFilteredIndustrialPremisesAds,
  detailUrl as adDetailUrl,
} from "../../../store/marketIndpAds";

export default function IndustrialPremisesMarket() {
  const { industrialPremisesId } = useParams();
  const indpQuery = buildQueryString({
    industrialpremises: industrialPremisesId,
  });

  const [commodities, commoditiesLoading] =
    useFilteredIndustrialPremisesCommodities(indpQuery);
  const currentCommodities = commodities?.[0];
  const [marketingAds, marketingAdsLoading] =
    useFilteredIndustrialPremisesAds(indpQuery);

  const { push } = useHistory();

  const hasActiveAd = marketingAds?.find((m) => !m.confirmed && !m.closed);

  const [createOpen, setCreateOpen] = React.useState(false);
  const [createAdOpen, setCreateAdOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editAdId, setEditAdId] = React.useState(null);

  const [syncErrorCount] = useExternalSyncErrorPaginationCount({
    filters: {
      api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
      industrialpremises: industrialPremisesId,
    },
  });

  const hasSyncError = syncErrorCount > 0;

  const errorQuery = {
    api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
    industrialpremises: industrialPremisesId,
  };

  const leadQuery = {
    industrialpremises: industrialPremisesId,
  };

  return (
    <>
      <CommoditiesForm
        method="POST"
        isOpen={createOpen}
        instance={{ industrial_premises: { id: industrialPremisesId } }}
        onCheckout={() => setCreateOpen(false)}
      />
      <CommoditiesForm
        method="PATCH"
        id={currentCommodities?.id}
        isOpen={editOpen}
        instance={currentCommodities}
        onCheckout={() => setEditOpen(null)}
      />

      <AdForm
        method="POST"
        isOpen={createAdOpen}
        instance={{ industrial_premises: { id: industrialPremisesId } }}
        onCheckout={() => setCreateAdOpen(false)}
        hideIndp
      />

      <AdForm
        method="PATCH"
        isOpen={!!editAdId}
        id={editAdId}
        instance={marketingAds?.find((ad) => ad.id == editAdId)}
        onCheckout={() => setEditAdId(null)}
        hideIndp
      />

      <DetailInnerWrapper>
        {hasSyncError && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle>Felmeddelanden</OverviewTitle>
            </OverviewTitleWrapper>
            <SyncErrorTable
              hideTitle
              {...{
                persistantQuery: errorQuery,
              }}
            />
          </DetailPageBox>
        )}
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 6, maxWidth: "60%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Annonsdetaljer</OverviewTitle>
                <OverviewSubtitle>
                  {!currentCommodities ? (
                    <TextButton
                      iconType="edit"
                      iconPlacement="right"
                      title="Lägg till annonsdetaljer"
                      clicked={() => setCreateOpen(true)}
                    />
                  ) : (
                    <TextButton
                      iconType="edit"
                      iconPlacement="right"
                      title="Uppdatera annonsdetaljer"
                      clicked={() => setEditOpen(true)}
                    />
                  )}
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {!commoditiesLoading && !commodities?.length ? (
              <div
                style={{
                  width: "100%",
                  height: 200,
                  padding: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <span>
                  Inga annonsdetaljer är konfigurerade.
                  <br />
                  Annonsdetaljer används för att specificera vad som finns att
                  tillgå i denna lokal, t.ex. balkong, diskmaskin osv.
                  <br /> Tryck på <strong>
                    "Lägg till annonsdetaljer"
                  </strong>{" "}
                  för att lägga till.
                </span>
              </div>
            ) : (
              <>
                <DetailInfo
                  infoObj={getInfoObj({ commodities: currentCommodities })}
                />

                <InnerBox>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Beskrivning</OverviewTitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <InnerBox>
                    {currentCommodities?.informational_description
                      ?.split("\n")
                      ?.map((item, i) => {
                        return (
                          <p style={{ fontSize: 12 }} key={i}>
                            {item}
                          </p>
                        );
                      })}
                  </InnerBox>
                </InnerBox>
              </>
            )}
          </DetailPageBox>

          <div style={{ flex: 4, maxWidth: "39%", alignSelf: "flex-start" }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Annonser</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {marketingAds?.length > 0 ? (
                <>
                  {marketingAds.map((ad) => {
                    const { stateDisplay, state } = getAdStateAndLabel(ad);
                    return (
                      <InnerBox style={{ marginBottom: 8 }}>
                        <OverviewTitleWrapper>
                          <OverviewTitleWithSubtitleWrapper>
                            <OverviewTitle small>
                              Publ. {ad.date_publish}
                            </OverviewTitle>
                          </OverviewTitleWithSubtitleWrapper>
                          <StatusLabel state={state}>
                            {stateDisplay}
                          </StatusLabel>
                        </OverviewTitleWrapper>

                        <DetailInfo
                          infoObj={{
                            Detaljer: [
                              {
                                title: "Planerad inflytt",
                                value: <DateCell date={ad.access_date} />,
                              },
                              {
                                title: "Kravprofil",
                                value: (
                                  <LinkedObject
                                    urlMethod={requirementDetailUrl}
                                    obj={ad.requirement}
                                  />
                                ),
                              },
                              {
                                title: "Projekt",
                                value: (
                                  <LinkedObject
                                    urlMethod={pipeDetailUrl}
                                    obj={ad.dump_in_pipe}
                                  />
                                ),
                              },
                              {
                                title: "Pigello marknad",
                                value: (
                                  <StatusLabel
                                    state={ad.publish_pigello ? 0 : 3}
                                  >
                                    {ad.publish_pigello ? "Aktiv" : "Inaktiv"}
                                  </StatusLabel>
                                ),
                              },
                            ],
                          }}
                        />

                        <TextButton
                          title="Gå till annons"
                          iconType="edit"
                          iconPlacement="right"
                          clicked={() => push(adDetailUrl({ id: ad.id }))}
                        />
                      </InnerBox>
                    );
                  })}
                </>
              ) : (
                <InnerBox style={{ marginBottom: 8 }}>
                  Inga annonser har skapats för denna lokal
                </InnerBox>
              )}

              <InnerBox style={{ marginTop: 24 }}>
                {hasActiveAd && (
                  <OverviewSubtitle style={{ marginBottom: 12 }}>
                    Endast en annons i taget kan vara aktiv för ett objekt.
                    Avbryt den nuvarande aktiva annonsen för att kunna skapa en
                    ny.
                  </OverviewSubtitle>
                )}
                <PrimaryButton
                  disabled={hasActiveAd}
                  title="Skapa ny annons"
                  clicked={() => setCreateAdOpen(true)}
                />
              </InnerBox>
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Leads på detta objekt</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <LeadsTable
            {...{
              persistantQuery: leadQuery,
            }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ commodities }) {
  const obj = {
    "Ingår i hyra": [
      {
        title: "Elektricitet",
        value: commodities?.electricity_included ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Internet",
        value: commodities?.internet_included ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Värme",
        value: commodities?.heating_included ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Kabel-TV",
        value: commodities?.cable_package_included ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Vatten",
        value: commodities?.water_display,
      },
    ],
    Bekvämligheter: [
      {
        title: "Renoverades senast",
        value: commodities?.renovation_date || "-",
      },
      {
        title: "Typ av kök",
        value: commodities?.kitchen_display || "-",
      },
      {
        title: "Köksfläkt",
        value: commodities?.kitchen_fan_display,
      },
      {
        title: "Diskmaskin",
        value: commodities?.dishwasher_display,
      },
      {
        title: "Tvättmasking",
        value: commodities?.washing_machine_display,
      },
      {
        title: "Torktumlare",
        value: commodities?.dryer_display,
      },
      {
        title: "Badkar",
        value: commodities?.bathtub_display,
      },
      {
        title: "Hiss",
        value: commodities?.elevator ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Skafferi",
        value: commodities?.pantry ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Uteplats",
        value: commodities?.patio ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Handikappanpassad",
        value: commodities?.handicap_adapted ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Tillgänglighetsanpassad",
        value: commodities?.accessibility_adapted ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Säkerhetsdörr",
        value: commodities?.security_door ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Tvättstuga",
        value: commodities?.laundry_room ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Dusch",
        value: commodities?.shower ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Utfällbar säng",
        value: commodities?.bed_in_wall ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Spis",
        value: commodities?.stove ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Gasspis",
        value: commodities?.gas_cooker ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
      {
        title: "Öppen spis",
        value: commodities?.tiled_stove ? (
          <StatusLabel state={0}>Ja</StatusLabel>
        ) : (
          <StatusLabel state={6}>Nej</StatusLabel>
        ),
      },
    ],
    Parkering: [
      {
        title: "Tillgängliga parkeringsplatser",
        value: commodities?.available_parking_spots || 0,
      },
      {
        title: "Inkluderade parkeringsplatser",
        value: commodities?.included_parking_spots || 0,
      },
      {
        title: "Tillgängliga garageplatser",
        value: commodities?.available_garage_spots || 0,
      },
      {
        title: "Inkluderade garageplatser",
        value: commodities?.included_garage_spots || 0,
      },
    ],
  };

  return obj;
}
