import * as React from "react";
import * as ISC from "../../../../components/Details/InfoTable/styles";
import { PrimaryButton } from "../../../../components/Forms/Base/Buttons";
import ModalForm from "../../../../components/Forms/User/ChapterForm/ModalForm";
import { buildQueryString } from "../../../../store/base";
import { useFilteredRealEstates } from "../../../../store/realEstates";

export default ({ user }) => {
  const realestatesIds = user?.realestates?.map((r) => r.id);
  const realEstateQuery = buildQueryString({ id__in: realestatesIds });
  const [realestates] = useFilteredRealEstates(realEstateQuery);
  const [editUserModalOpen, setEditUserModalOpen] = React.useState(false);

  const getRealestateNames = () => {
    return realestates.map((r) => r.str_representation).join(", ");
  };

  return (
    <>
      <PrimaryButton
        title="Ändra användarinformation"
        clicked={() => setEditUserModalOpen(true)}
      />
      <ModalForm
        method={"PATCH"}
        id={user?.id}
        isOpen={editUserModalOpen}
        onCheckout={() => setEditUserModalOpen(false)}
        existingData={user}
      />
      <ISC.InfoTable>
        <ISC.InfoTableTitleWrapper>
          <ISC.InfoTableTitle>
            <ISC.InfoTableTitle>Användarinformation</ISC.InfoTableTitle>
          </ISC.InfoTableTitle>
        </ISC.InfoTableTitleWrapper>

        <ISC.InfoTableContent>
          {user?.corporate_name && (
            <ISC.InfoTableRow>
              <ISC.InfoTableRowTitle>Företagsnamn:</ISC.InfoTableRowTitle>
              <ISC.InfoTableRowValue>
                {user.corporate_name}
              </ISC.InfoTableRowValue>
            </ISC.InfoTableRow>
          )}
          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Förnamn:</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {user?.first_name || "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Efternamn:</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {user?.last_name || "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>
              {user?.is_company ? "Org nr: " : "Personnummer: "}
            </ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {user?.legal_id ? user.legal_id : "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Epost/användarnamn:</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {user?.email ? user.email : "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Telefon:</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {user?.phone ? user.phone : "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Användartyp:</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {user?.user_type_display ? user.user_type_display : "-"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Fastigheter:</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {user?.realestates?.length
                ? getRealestateNames()
                : "Inga fastigheter valda"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>
        </ISC.InfoTableContent>
      </ISC.InfoTable>
    </>
  );
};
