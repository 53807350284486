import * as React from "react";
import classNames from "classnames";

export const POP_OVER_POSITIONS = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};

const getPositionStyles = ({ height, width, position }) => {
  switch (position) {
    case POP_OVER_POSITIONS.TOP: {
      return { bottom: height, left: width / 2 - 256 / 2 };
    }
    case POP_OVER_POSITIONS.BOTTOM: {
      return {
        top: height,
        left: width / 2 - 256 / 2,
      };
    }
    case POP_OVER_POSITIONS.LEFT: {
      return { bottom: height / 2, right: width };
    }
    case POP_OVER_POSITIONS.RIGHT: {
      return {
        bottom: height / 2,
        left: width,
      };
    }

    default:
      return;
  }
};

export default function PopOver({ children, position, tooltipClassName = "", heightMultiplier }) {
  const [show, setShow] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const container = React.useRef();

  React.useLayoutEffect(() => {
    if (container.current) {
      try {
        const box = container.current?.getBoundingClientRect();

        const height = box.height;
        const width = box.width;

        setHeight(height || 0);
        setWidth(width || 0);
      } catch (e) {
        return;
      }
    }
  }, []);
  const additionalStyle = heightMultiplier ? {maxHeight: `${heightMultiplier * height}px`, overflowY: "auto"} : {}
  return (
    <div
      ref={container}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className={"absolute top-0 left-0 right-0 bottom-0 bg-transparent"}
    >
      {show && (
        <div
          role="tooltip"
          className={classNames(
            "absolute z-10 p-2 inline-block w-64 text-sm font-light text-gray-500 bg-white border border-solid border-gray-200 rounded shadow-sm text-start",
            tooltipClassName
          )}
          style={{...getPositionStyles({ height, width, position }), ...additionalStyle}}
        >
          {children}
        </div>
      )}
    </div>
  );
}
