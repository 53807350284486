import baseReducer from "../../billecta/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_PERIOD_LOCK_PERIOD: {
      return {
        ...newState,
        [`periodLockPeriod_${payload.creditorId}`]: payload.lockPeriod,
      };
    }

    default:
      return newState;
  }
};
