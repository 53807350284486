import * as React from "react";

import useQuery from "../../components/utils/useQuery";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import { OverviewSubtitle } from "../../components/Details/OverviewInfo/styles";
import IntegrationsGrid from "../../components/Integrations/IntegrationsGrid";
import { DetailLayoutWrapper } from "../../components/sharedStyles";

export default function IntegrationsOverview() {
  const [selectedTab, setSelectedTab] = React.useState("ALL");

  const query = useQuery();
  const tab = query.get("tab");
  React.useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  const renderInfoPills = () => (
    <OverviewSubtitle>
      Här listas alla integrationer som kan kopplas mot Pigello.
    </OverviewSubtitle>
  );
  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title="Integrationer"
        config={true}
        {...{ renderInfoPills }}
      />
      {}

      {["ALL", null].includes(selectedTab) && <IntegrationsGrid />}
      {selectedTab === "COMPANY" && <IntegrationsGrid category={"company"} />}
      {selectedTab === "BRF" && <IntegrationsGrid category={"brf"} />}
    </DetailLayoutWrapper>
  );
}
