import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  SecondaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import ParkingSelectTable from "../../../components/Tables/Parking/ParkingContracts/SelectTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { massUpdateInvoicingStatus } from "../../../store/invoicingParking";
import { MASS_INVOICING_UPDATE_ACTIONS } from "../../../store/invoicingLease/store/actions";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { overviewUrl } from "../../../store/parkingContracts";
import { useHistory } from "react-router";

const UPDATE_TYPES = {
  ACTIVATE: 0,
  INACTIVATE: 1,
};

export default function HandleMassInvoincingParkingContract() {
  const { filteredRealEstates } = useActiveFilters();
  const dispatch = useDispatch();
  const [updateType, setUpdateType] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [confirmationFunction, setConfirmationFunction] = React.useState(null);
  const [realEstates] = useFilteredRealEstates("");
  const { push } = useHistory();
  const realEstateIds = filteredRealEstates || realEstates?.map((r) => r.id);

  const activePersistantQuery = {
    parking_invoicing_active: true,
    parking_invoicing_bill_with_lease: false,
    realestate_ids: filteredRealEstates,
  };

  const inactivePersistantQuery = {
    parking_invoicing_active: false,
    parking_invoicing_bill_with_lease: false,
    realestate_ids: filteredRealEstates,
  };

  //TODO: FinalBaseTable: is this enough? Or do we need to parse ids in some way
  const activateInvoicings = (ids) => {
    setLoading(true);

    // const ids = selected
    //   .map((s) => s.original.parking_invoicing?.id)
    //   ?.filter((id) => !!id);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.ACTIVATE,
        ids,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för avtalen aktiverades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda avtalen`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  const handleActivateAllFilteredRealEstates = () => {
    setLoading(true);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.ACTIVATE,
        realEstateIds,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för avtalen aktiverades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda avtalen`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  //TODO: FinalBaseTable: is this enough? Or do we need to parse ids in some way
  const inActivateInvoicings = (ids) => {
    setLoading(true);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.PAUSE,
        ids,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för avtalen pausades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda avtalen`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  const handlePauseAllFilteredRealEstates = () => {
    setLoading(true);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.PAUSE,
        realEstateIds,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för avtalen aktiverades",
              description: `Eventuella fel syns under "Hyresavisering" och de berörda avtalen`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  return (
    <DetailInnerWrapper>
      <ConfirmationModal
        isOpen={!!confirmationFunction}
        closeFunction={() => setConfirmationFunction(null)}
        title={confirmationTitle}
        acceptCallback={confirmationFunction}
      />

      {updateType === null && (
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>
              Masshantering av aviseringsstatus för parkeringsavtal
            </OverviewTitle>
          </OverviewTitleWrapper>

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Vilken typ av uppdatering ska genomföras?
              </OverviewTitle>
            </OverviewTitleWrapper>

            <BodyText style={{ maxWidth: 800 }}>
              Välj vilken typ av uppdatering som ska utföras. Vid uppdatering av
              status för flera avtal samtidigt så kommer eventuella fel hamna
              under "Felmeddelanden" under sidan "Hyresavisering" samt inne på
              det specifika avtalets aviseringsflik.{" "}
            </BodyText>

            <TextButton
              extraStyle={{ marginTop: 24 }}
              title="Aktivera avisering för avtal med pausad avisering"
              iconType="done"
              iconPlacement="right"
              clicked={() => setUpdateType(UPDATE_TYPES.ACTIVATE)}
            />

            <TextButton
              extraStyle={{ marginTop: 24 }}
              title="Pausa avisering för avtal med aktiv avisering"
              iconType="pause"
              iconPlacement="right"
              clicked={() => setUpdateType(UPDATE_TYPES.PAUSE)}
            />
          </InnerBox>
        </DetailPageBox>
      )}

      {updateType === UPDATE_TYPES.ACTIVATE && (
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            title="Gå tillbaka till val av uppdatering"
            iconType="arrow-back"
            clicked={() => setUpdateType(null)}
            extraStyle={{ marginBottom: 24 }}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper style={{ marginRight: 48 }}>
              <OverviewTitle small>
                Aktivera avisering för avtal med pausad avisering
              </OverviewTitle>
              <OverviewSubtitle>
                Välj de avtalen du vill aktivera den pausade aviseringen för.
                Tryck på <strong>"Aktivera avisering"</strong> under listan för
                att spara. Tryck på <strong>"Aktivera alla"</strong> för att
                aktivera avisering för alla avtal med pausad avisering.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <div style={{ display: "flex", alignItems: "center" }}>
              <SecondaryButton
                title="Aktivera alla"
                clicked={() => {
                  setConfirmationTitle(
                    "Aktivera avisering för alla avtal under de filtrerade fastigheterna? Om ingen filtrering är aktiv kommer aktiveringen ske för alla fastigheter i systemet."
                  );

                  setConfirmationFunction(
                    () => handleActivateAllFilteredRealEstates
                  );
                }}
              />
            </div>
          </OverviewTitleWrapper>

          <ParkingSelectTable
            persistantQuery={inactivePersistantQuery}
            onSubmit={activateInvoicings}
            submitLabel={"Aktivera pausad avisering"}
          />
        </DetailPageBox>
      )}

      {updateType === UPDATE_TYPES.PAUSE && (
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            title="Gå tillbaka till val av uppdatering"
            iconType="arrow-back"
            clicked={() => setUpdateType(null)}
            extraStyle={{ marginBottom: 24 }}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper style={{ marginRight: 48 }}>
              <OverviewTitle small>
                Pausa avisering för avtal med aktiv avisering
              </OverviewTitle>
              <OverviewSubtitle>
                Välj de avtal du vill pausa den aktiva aviseringen för. Tryck på{" "}
                <strong>"Pausa avisering"</strong> under listan för att spara.
                Tryck på <strong>"Pausa alla"</strong> för att pausa avisering
                för alla avtal med aktiv avisering.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <div style={{ display: "flex", alignItems: "center" }}>
              <SecondaryButton
                title="Pausa alla"
                clicked={() => {
                  setConfirmationTitle(
                    "Pausa avisering för alla avtal under de filtrerade fastigheterna? Om ingen filtrering är aktiv kommer aktiveringen ske för alla fastigheter i systemet."
                  );
                  setConfirmationFunction(
                    () => handlePauseAllFilteredRealEstates
                  );
                }}
              />
            </div>
          </OverviewTitleWrapper>

          <ParkingSelectTable
            persistantQuery={activePersistantQuery}
            onSubmit={inActivateInvoicings}
            submitLabel={"Pausa aktiv avisering"}
          />
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
