import * as React from "react";
import { useDispatch } from "react-redux";
import LocalCheckField from "../Forms/Base/Fields/LocalCheckField";

export default function LocalSelectManyDropdown({
  values, // an array of selected values //un supported check LocalSelectManyField
  onDone,
  choices, // an array of all values.
  className,
  enabled,
}) {
  const buildRenderStates = () => {
    return choices.map((obj) => {
      return false;
    });
  };

  const [renderStates, setRenderStates] = React.useState(buildRenderStates());

  const [prevRenderStates, setPrevRenderStates] = React.useState(
    buildRenderStates()
  );

  React.useEffect(() => {
    let states = buildRenderStates();

    setRenderStates(states);
    setPrevRenderStates(states);
  }, [choices]);

  const hasUpdatedRenderState = React.useMemo(() => {
    let same = true;

    for (let index in renderStates) {
      if (renderStates[index] !== prevRenderStates[index]) {
        same = false;
        break;
      }
    }

    return !same;
  }, [renderStates]);

  const done = () => {
    let selected_columns = [];

    for (let index in renderStates) {
      if (renderStates[index] !== prevRenderStates[index]) {
        selected_columns.push(choices[index]);
      }
    }

    onDone(selected_columns, renderStates, prevRenderStates);
  };

  const clickedOption = (val, index) => {
    let newRenderStates = renderStates.slice();
    newRenderStates[index] = val;
    setRenderStates(newRenderStates);
  };

  const dropdownOptions = React.useMemo(() => {
    return choices.map((obj, index) => {
      return (
        <div
          onClick={() => clickedOption(!renderStates[index], index)}
          className="w-full p-4 border-t border-r border-l whitespace-normal bg-white break-all border-solid border-gray-200 cursor-pointer transition-colors hover:bg-blue-200"
          key={index}
        >
          <LocalCheckField
            className="pointer-events-none"
            value={renderStates[index]}
            onChange={() => {}}
            title={obj.name}
          />
        </div>
      );
    });
  }, [renderStates]);

  return (
    <div
      className={`w-full absolute left-0 top-[100%] border-b border-gray-200 ${
        !enabled && "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`max-h-[30vh] overflow-y-scroll overflow-x-hidden ${
          className && className
        }`}
      >
        {dropdownOptions.length !== 0 ? (
          dropdownOptions
        ) : (
          <div className="w-full p-4 border-t border-r border-l border-solid border-gray-200">
            <p>Inga kolumner kvar</p>
          </div>
        )}
      </div>
      {hasUpdatedRenderState && enabled && (
        <div
          onClick={done}
          className="absolute bottom-[-3.5rem] left-0 w-full h-14 cursor-pointer bg-blue-400 flex items-center justify-center transition-colors hover:bg-blue-600"
        >
          <p className="text-white">Lägg till</p>
        </div>
      )}
    </div>
  );
}
