import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "../../../store/base";
import { createUrl } from "../../../store/billectaInvoicing";
import LocalDateSelect from "../Base/Fields/LocalDateSelect";
import Modal from "../Base/Modals/Modal";

export default function CopyInvoiceForm({
  creditorId,
  closeFunction,
  copyFrom,
}) {
  const { push } = useHistory();
  const [value, setValue] = React.useState(moment().format("YYYY-MM-DD"));

  const goToCopyForm = () => {
    push(
      createUrl({
        creditorId,
        query: buildQueryString({ copyFrom, newInvoiceDate: value }),
      })
    );
  };
  return (
    <Modal
      closeFunction={closeFunction}
      title="Kopiera faktura"
      onAccept={goToCopyForm}
      acceptTitle="Fortsätt"
      denyTitle="Avbryt"
    >
      <LocalDateSelect
        title="Fakturadatum för den nya fakturan"
        description="Välj vilket fakturadatum den nya fakturan ska ha. Förfallodatumet räknas ut utifrån intervallet på originalfakturan."
        id="_invoiceDate"
        value={value}
        onChange={(val) => setValue(val)}
      />
    </Modal>
  );
}
