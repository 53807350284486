import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../../components/Displays";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useQuery from "../../../components/utils/useQuery";
import {
  createManyDefaultSettingUrl,
  overviewUrl,
} from "../../../store/marketApartmentAds";

import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export const AD_CREATE_TYPES = {
  APARTMENT: "apartments",
  INDP: "industrial-premises",
  PARKING: "parking-spots",
};

export default function ManyAdRootObjectSelect() {
  const query = useQuery();
  const { push } = useHistory();
  const { type } = useParams();
  const [selectedBuilding, setSelectedBuilding] = React.useState(null);

  const createdAmount = query.get("created_amount");
  const postCreation = query.get("postcreation") == "true";

  const handleBuildingSelected = () => {
    if (selectedBuilding) {
      push(
        `${createManyDefaultSettingUrl({ type })}/?building=${
          selectedBuilding?.id
        }`
      );
    }
  };

  const handleManualSelected = () => {
    push(createManyDefaultSettingUrl({ type }));
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <TextButton
          extraStyle={{ marginBottom: 24 }}
          title="Tillbaka till översikt av annonser"
          iconType="arrow-back"
          clicked={() => push(overviewUrl())}
        />

        {postCreation && (
          <InfoBox
            boxTheme="ok"
            title="Skapande av annonser lyckades"
            extraStyles={{ marginBottom: 24 }}
            renderText={() => (
              <>
                <br />
                <div>
                  Antal annonser som skapades: <strong>{createdAmount}</strong>
                </div>

                <br />
                <div>
                  Annonserna sätts upp i bakgrunden.{" "}
                  <strong>
                    Eventuella fel som uppstår visas under översikten inne på
                    Marknadsmodulen
                  </strong>
                </div>
              </>
            )}
          />
        )}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Skapa flera annonser</OverviewTitle>
            <OverviewSubtitle>
              Välj vilken byggnad{" "}
              {type === AD_CREATE_TYPES.APARTMENT
                ? "lägenheter"
                : type === AD_CREATE_TYPES.INDP
                ? "lokaler"
                : "fordonsplatser"}{" "}
              ska hämtas ifrån. I nästa steg ställs annonserna för de hämtade
              objekten in.
              <br />
              <strong>
                Observera att objekt kan läggas till och tas bort i nästa steg.
              </strong>
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <hr />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="min-w-[200px]">
            <LocalTableSelectField
              value={selectedBuilding}
              onChange={(val) => setSelectedBuilding(val)}
              TableComponent={BuildingTable}
              placeholder="Välj byggnad..."
              onClose={handleBuildingSelected}
            />
          </div>

          <PrimaryBtn className="ml-2" secondary onClick={handleManualSelected}>
            Eller välj objekt manuellt
            <ChevronRightIcon width={16} className="ml-1" />
          </PrimaryBtn>
        </div>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
