import { axiosInstance } from "../base/store/axios";

async function createMassComponents({ url, query, data, restrictions }) {
  const fullUrl = `${url}?${query}`;

  const res = await axiosInstance.post(fullUrl, { data, restrictions });

  return res;
}

export { createMassComponents };
