import * as React from "react";

import { Confirm, Stage } from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "STAGE":
      return <Stage method={method} />;
    default:
      return <Confirm method={method} />;
  }
};
