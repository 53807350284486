export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_FORTNOXCREDENTIAL",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_FORTNOXCREDENTIAL",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_FORTNOXCREDENTIAL",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_FORTNOXCREDENTIAL",
  DESTROY_FORM: "DESTROY_FORM_FORTNOXCREDENTIAL",
  SET_FORM_ERROR: "SET_FORM_ERROR_FORTNOXCREDENTIAL",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_FORTNOXCREDENTIAL",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_FORTNOXCREDENTIAL",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_FORTNOXCREDENTIAL",
  RESET_STATE: "RESET_STATE_FORTNOXCREDENTIAL",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_FORTNOXCREDENTIAL",
  REMOVE_OBJECT: "REMOVE_OBJECT_FORTNOXCREDENTIAL",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_FORTNOXCONFIG",
  CLEAR_FETCHED: "CLEAR_FETCHED_FORTNOXCREDENTIAL",

  LIST_URL: "/accounting/fortnox/fortnoxcredential/list/",
  POST_URL: "/accounting/fortnox/fortnoxcredential/",
  PATCH_URL: "/accounting/fortnox/fortnoxcredential/",
  GET_URL: "/accounting/fortnox/fortnoxcredential/",

  STORE_NAME: "fortnoxCredential",
};
