import styled, { css } from "styled-components";
import addIcon from "../../../assets/svg/material/add-blue.svg";
import addCircleIcon from "../../../assets/svg/material/add-circle-white.svg";
import closeCircleIcon from "../../../assets/svg/material/close-circle_red.svg";
import closeCircleIconWhite from "../../../assets/svg/material/close-circle-white.svg";
import removeIcon from "../../../assets/svg/material/remove_blue.svg";
import arrowIcon from "../../../assets/svg/material/arrow-right-blue.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const RoomOverviewHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 14px;
  font-size: 0.9em;
  font-weight: ${(p) => p.theme.fontSizes.headerSmall};
  background-color: white;
  cursor: pointer;
  border-bottom: thin solid #dddddd;

  &:nth-of-type(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }
  &:hover {
    background-color: #eff2ff;
    color: ${(p) => p.theme.colors.textPrimary};
  }
`;

export const RoomTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OpenButton = styled.div`
  height: 24px;
  width: 24px;
  background-image: url(${addIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: thin solid #dddddd;
  border-radius: 50%;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
`;

export const CloseButton = styled(OpenButton)`
  background-image: url(${removeIcon});
`;

export const RemoveButton = styled(OpenButton)`
  background-image: url(${(p) =>
    p.white ? closeCircleIconWhite : closeCircleIcon});
  height: 20px;
  width: 20px;
  border: none;
`;

export const ArrowButton = styled(OpenButton)`
  background-image: url(${arrowIcon});
  background-size: 70%;
  height: 20px;
  width: 20px;
  margin-right: 2px;
`;

export const RoomMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 12px;
`;

export const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ComponentArea = styled.div`
  background-color: rgba(140, 140, 140, 0.2);
`;

export const Component = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 8px 14px 8px 28px;
  font-size: 0.8em;
  font-weight: ${(p) => p.theme.fontSizes.headerSmall};
  background-color: rgba(0, 0, 0, 0.05);
  cursor: ${(p) => (p.isExecute ? "default" : "pointer")};
  border-radius: 5px;

  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background-color: ${(p) => p.theme.colors.primaryBlue};
    left: 9px;
    top: calc(50% - 4px);
    z-index: 1;
  }

  &:nth-of-type(even) {
    background-color: white;
  }
  &:hover {
    background-color: #eff2ff;
    color: ${(p) => p.theme.colors.textPrimary};
  }
`;

export const RatingFile = styled.div`
  height: 50px;
  width: 50px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border: ${(p) => p.theme.borders.standard};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.url});
  cursor: pointer;
`;

export const Container = styled.div`
  border-radius: 5px;
  border: thin solid #ddd;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  min-height: 400px;
  height: ${(p) => p.maxElementCount * 42 + 200}px;
  display: flex;
  overflow: hidden;
`;

export const AddRoomButton = styled.div`
  padding: 12px;
  align-self: flex-end;
  margin-top: auto;
  width: 100%;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const AddComponentButton = styled.div`
  padding: 12px;
  align-self: flex-end;
  margin-top: auto;
  width: 100%;
  background-color: ${(p) => p.theme.colors.blue};
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const AddRoomIcon = styled.div`
  margin-left: 6px;
  height: 16px;
  width: 16px;
  background-image: url(${addCircleIcon});
  background-repeat: none;
  background-size: contain;
  background-position: center;
`;

export const RoomItem = styled.div`
  cursor: pointer;
  padding: 10px 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.25);
  background-color: #fff;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(p) =>
    !p.selected &&
    css`
      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight} !important;
      }
    `}

  ${(p) =>
    p.selected &&
    css`
      background-color: ${(p) => p.theme.colors.blue} !important;
      color: #fff;
    `}
`;

export const ComponentItem = styled.div`
  padding: 10px 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.25);
  background-color: #fff;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ComponentTypeInner = styled.div`
  flex: 2;
`;

export const Header = styled.div`
  padding: 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.65);
  background-color: rgba(240, 240, 240, 0.85);
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const ComponentHeader = styled.div`
  padding: 12px;
  border-bottom: thin solid rgba(100, 100, 100, 0.65);
  background-color: rgba(240, 240, 240, 0.85);
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  ${RoomItem}:nth-child(odd) {
    background-color: rgba(241, 242, 246, 0.95);
  }
`;

export const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 100%;

  ${ComponentItem}:nth-child(odd) {
    background-color: rgba(241, 242, 246, 0.95);
  }
`;

export const ExecuteIconContainer = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  border: thin solid #ddd;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: ${(p) => p.theme.colors.gray1};
  border-radius: 5px;
  margin-top: 24px;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding: 6px 8px;
  margin-top: 6px;
  border: thin solid ${(p) => p.theme.colors.elephant};
  border-radius: 5px;
  background-color: #fff;
`;

export const FileType = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;
export const FileName = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
`;

export const FileTypeDisplay = styled.div`
  display: flex;
  flex-direction: row;
  border: thin solid ${(p) => p.theme.colors.elephant};
  margin-left: auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  background-color: #fff;
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;
