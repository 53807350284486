export default [
  {
    title: "Annonsinställningar",
    key: "AD",
    visited: true,
    hasError: false,
    fieldKeys: ["date_publish", "is_pending", "description", "apartment"],
  },
  {
    title: "Sökande",
    key: "LEADS",
    visited: false,
    hasError: false,
    fieldKeys: ["pilot_mode", "requirement", "dump_in_pipe"],
  },
  {
    title: "Villkor",
    key: "CONTRACT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "access_date",
      "prior_access",
      "prioritize_customers",
      "is_short_lease",
      "short_lease_min_date",
      "short_lease_max_date",
    ],
  },
  {
    title: "Publicering",
    key: "PUBLISH",
    visited: false,
    hasError: false,
    fieldKeys: ["publish_homeq", "publish_pigello", "publish_sbf"],
  },
  // {
  //   title: "Visning",
  //   key: "VIEWING",
  //   visited: false,
  //   hasError: false,
  //   fieldKeys: [
  //     "viewing_type",
  //     "viewing_time",
  //     "viewing_notes",
  //     "viewing_showing_party",
  //   ],
  // },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
