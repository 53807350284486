import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import illustrationCopy from "../../../../assets/illustrations/illustration_copy.svg";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useOtherContractForm,
  constants,
  create,
  destroyPostForm,
  createCostsUrl,
} from "../../../../store/otherContracts";
import useQuery from "../../../utils/useQuery";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import StepDisplay from "../../../StepDisplay/StepDisplay";
import ActionBanner from "../../../Displays/ActionBanner";
import {
  CheckBadgeIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import PrimaryBtn from "../../Base/Buttons/PrimaryBtn";
import CloneSourcePickerOtherContract from "../../Pickers/CloneSourcePickerOtherContract";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";

export default ({ method = "POST" }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const [cloneOpen, setCloneOpen] = React.useState(false);
  const [didClone, setDidClone] = React.useState(false);
  const [cloneSourceId, setCloneSourceId] = React.useState(null);
  const formLoaded = Boolean(useOtherContractForm(method));
  const { contractType } = useParams();
  const { replace } = useHistory();

  const query = useQuery();
  const isManualSign = query.get("manual_sign") === "true";

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(createCostsUrl({ id: returned.id, cloneFrom: cloneSourceId }));
  };

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(false));
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  // TODO - Ask before save draft
  const onSaveDraft = () => {
    onSubmit(true);
  };

  const onSubmit = (draft = false) => {
    setLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) =>
          preProcess({ data, draft, contractType, isManualSign }),
      })
    );
  };

  const renderSteps = () => {
    const steps = ["1. Avtalstyp", "2. Avtal", "3. Debitering & Avisering"];

    if (contractType !== "existing") {
      steps.push("3. Dokument för signering");
    }

    return <StepDisplay steps={steps} activeIndex={1} />;
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      {renderSteps()}

      <CloneSourcePickerOtherContract
        onDone={(_, cloneSource) => {
          setCloneOpen(false);
          setDidClone(true);
          setCloneSourceId(cloneSource?.id);
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: `Inställningar kopierades från ett avtal`,
            })
          );
        }}
        isOpen={cloneOpen}
        closeFunction={() => setCloneOpen(false)}
      />

      <ActionBanner
        renderTitle={
          didClone
            ? () => (
                <>
                  Avtalet kopierades
                  <CheckBadgeIcon
                    className="text-green-600 ml-1"
                    width={24}
                  />{" "}
                </>
              )
            : () => "Kopiera från avtal"
        }
        renderDescription={
          didClone
            ? () => (
                <>
                  Attributen från det valda avtalet kopierades till formuläret.
                </>
              )
            : () => (
                <>
                  Kopiera inställningar från ett annat avtal i systemet.
                  Attribut som ofta skiljer sig från avtal till avtal, t.ex.
                  hyresgäst, startdatum och slutdatum tas ej med vid kopiering.
                </>
              )
        }
        illustrationSvg={illustrationCopy}
        renderActions={() => (
          <>
            <PrimaryBtn onClick={() => setCloneOpen(true)}>
              <DocumentDuplicateIcon width={18} className="mr-1" />
              {didClone ? "Välj ett annat avtal" : "Kopiera från avtal"}
            </PrimaryBtn>
          </>
        )}
      />

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs({
            contractType,
            isManualSign,
          }),
          chapters,
          contractType,
          descriptions,
          method,
          onSubmit,
          onSaveDraft,
          displayDocumentFieldKey: "editabledoc.docData_",
          title: method === "POST" ? "Lägg till avtal" : "Uppdatera avtal",
        }}
      />
    </>
  );
};

const preProcess = ({ data, draft, contractType, isManualSign }) => {
  const copy = cloneDeep(data);

  copy.draft = draft ? true : false;
  copy.pre_existing = contractType === "existing";
  copy.uses_e_signing = !isManualSign;

  if (!copy.bill_with_lease) {
    copy.bill_with_lease = false;
  }

  if (copy.renew_indefinetely === "no_renewal") {
    copy.renew_indefinetely = false;
    copy.renew_interval = null;
  }

  // always create an editable doc
  if (!copy.editabledoc?.title) {
    set(copy, "editabledoc.title", copy.id_number || "Dokument");
  }

  // can't send null to backend
  if (copy.renew_indefinetely === null) {
    delete copy.renew_indefinetely;
  }

  if (copy.object_id) copy.object_id = copy.object_id.id;

  return copy;
};
