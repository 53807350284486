import { cloneDeep } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import { createUploadHandler } from "./uploadHandler";
import { store } from "../../store";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (querystring, callback, taskToken) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  (cloned?.images || []).forEach((a) => {
    // will never be sent to backend
    delete a._tempData;
    delete a._referenceId;

    if (a.id) {
      // file has not been edited, so we can just use the id
      delete a.image;
    }
  });
  return cloned;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    preProcess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return patch({
    url,
    constants,
    processSuccess,
    preProcess: fullPreProcess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback,
  });
};
