import * as React from "react";

import { Confirm, Company } from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "COMPANY":
      return <Company method={method} />;
    default:
      return <Confirm />;
  }
};
