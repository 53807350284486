import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useHistory } from "react-router-dom";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import XledgerModalForm from "src/components/Forms/Xledger/ChapterForm/ModalForm";

import { constants } from "src/store/xLedger";
import DeleteModal from "src/components/Forms/Delete/DeleteModal";
import XledgerIntegrationsTable from "src/components/Tables/XledgerIntegration/FullTable";
import { useExternalSyncErrorPaginationCount } from "src/store/externalSyncErrors";
import Alert, { ALERT_TYPES } from "src/components/Alert/Alert";
import SyncErrorTable from "src/components/Tables/ExternalSyncErrors/FullTable";

export default function XLedgerIntegration() {
  const current = INTEGRATIONS.find((i) => i.title === "Xledger");

  const [modalOpen, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(null);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const { push } = useHistory();

  const [errorCount, errorCountLoading] = useExternalSyncErrorPaginationCount({
    filters: {
      api_kind: 4,
    },
  });

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={deleteOpen}
        constants={constants}
      />

      {modalOpen && (
        <XledgerModalForm
          method="POST"
          onCheckout={() => setModalOpen(false)}
        />
      )}
      {editModalOpen && (
        <XledgerModalForm
          method="PATCH"
          id={editModalOpen?.id}
          instance={editModalOpen}
          onDelete={() => {
            setDeleteOpen(editModalOpen);
            setEditModalOpen(false);
          }}
          onCheckout={() => setEditModalOpen(false)}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          {errorCount > 0 && !errorCountLoading && (
            <Alert
              type={ALERT_TYPES.DANGER}
              title="OBS: Synkfel - Se felmeddelanden för information"
            >
              <SyncErrorTable
                persistantQuery={{
                  api_kind: 4,
                }}
              />
            </Alert>
          )}

          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Integrationsinformation</OverviewTitle>
                <OverviewSubtitle>
                  <TextButton
                    title="Besök Xledgers hemsida"
                    iconType="launch"
                    iconPlacement="right"
                    clicked={goToWebsite}
                  />
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>
                    Hur fungerar integrationen?
                  </OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              <BodyText>
                Xledger är ett ekonomisystem. Genom integrationen mellan Pigello
                och Xledger kan bokföringen av transaktioner i Pigellos
                hyresaviseringsmodul automatiskt synkas via en SFTP-koppling.
                <br />
                <p className="mb-4">
                  Integrationen fungerar genom att bokföringsunderlag genereras
                  och skickas över till Xledger via SFTP.
                </p>
                <p className="mb-4">
                  <strong>Steg 1</strong>
                  <br />
                  Sätt upp ett{" "}
                  <a
                    className="underline text-blue-500"
                    href={`${window.location.origin}/configcenter/integrations/sftp`}
                    target="_blank"
                  >
                    SFTP-jobb
                  </a>{" "}
                  för det bolag som bokföringsmaterial ska skickas för.
                </p>
                <p className="mb-4">
                  <strong>Steg 2</strong>
                  <br />
                  Aktivera integrationen för det bolag det gäller.{" "}
                  <strong>OBS: </strong> SFTP-jobbet i steg 1 måste vara uppsatt
                  innan detta kan göras.
                </p>
              </BodyText>
            </InnerBox>
          </DetailPageBox>

          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Aktiverade integrationer</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>

              <PrimaryBtn onClick={() => setModalOpen(true)}>
                Sätt upp Xledger SFTP-integration för ett bolag
              </PrimaryBtn>
            </OverviewTitleWrapper>

            <XledgerIntegrationsTable
              onRowClicked={(obj) => setEditModalOpen(obj)}
            />
          </DetailPageBox>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
