export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title;
};
export const detailUrl = ({ id }) => {
  return `/errand-settings/component-types/detail/${id}`;
};

export const overviewUrl = () => {
  return `/errand-settigns/component-types`;
};
