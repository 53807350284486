import * as React from "react";
import { useParams } from "react-router";
import {
  useLeaseContract,
  constants,
  update,
  useLeaseContractForm,
  detailUrl,
} from "../../../store/leaseContracts";
import { DetailInnerWrapper } from "../../../components/sharedStyles";
import DocumentViewer from "../../../components/Details/OverviewInfo/DocumentViewer/DocumentViewer";

export default function LeaseContractDocumentTabs() {
  const { leaseContractId } = useParams();
  const [leaseContract] = useLeaseContract(leaseContractId);

  useLeaseContractForm("PATCH", leaseContractId);
  return (
    <DetailInnerWrapper>
      <DocumentViewer
        contract={leaseContract}
        contractConstants={constants}
        contractUpdate={update}
        {...{ detailUrl }}
      />
    </DetailInnerWrapper>
  );
}
