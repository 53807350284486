import * as React from "react";
import FlowFormBase from "../../Base/FlowForm/FlowFormBase";

import chapters from "./Chapters";
import descriptions from "./Descriptions";

const chapterDefs = [
  {
    title: "Basinställning",
    key: "INVOICING_BASE",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "message",
      "require_cost_accept",
      "auto_merge",
      "auto_attest",
      "automatic_deposit_handling",
      "deposit_quota",
      "interval_setting",
      "project",
      "cost_center",
      "prefix_object_costs",
      "invoices_with_rent_increase_rows",
    ],
  },
  {
    title: "Betalningsvillkor",
    key: "DEBT_SETTINGS",
    visited: false,
    hasError: false,
    fieldKeys: ["debt_setting"],
  },
  {
    title: "IMD-debitering",
    key: "IMD_SETTINGS",
    visited: false,
    hasError: false,
    fieldKeys: ["imd_setting"],
  },
  {
    title: "Skapa och gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [""],
  },
];

export default function CreateInvoicingSettingBase({
  storeName,
  method,
  onSubmit,
  onGoBackToSelect,
  title,
}) {
  return (
    <FlowFormBase
      {...{
        storeName,
        chapterDefs,
        chapters,
        descriptions,
        method,
        onSubmit,
        onGoBackToSelect,
        title,
      }}
    />
  );
}
