export default [
  {
    name: "Pigello - Teknisk förvaltning",
    id: 1,
    isBase: true,
    permissions: [
      "view_can_reporterrand",
      "add_can_reporterrand",
      "change_can_reporterrand",
      "delete_can_reporterrand",
      "view_can_roundingerrand",
      "add_can_roundingerrand",
      "change_can_roundingerrand",
      "delete_can_roundingerrand",
      "view_can_inspectionerrand",
      "add_can_inspectionerrand",
      "change_can_inspectionerrand",
      "delete_can_inspectionerrand",
      "view_can_goverrand",
      "add_can_goverrand",
      "change_can_goverrand",
      "delete_can_goverrand",
      "view_can_baseobject",
      "view_can_realestate",
      "view_can_parking",
      "view_can_tenant",
    ],
    users: [],
  },
  {
    name: "Pigello - Hyresadministratör",
    id: 2,
    isBase: true,
    permissions: [
      "view_can_contract",
      "add_can_contract",
      "change_can_contract",
      "delete_can_contract",
      "view_can_billing",
      "add_can_billing",
      "change_can_billing",
      "delete_can_billing",
      "view_can_e_signing",
      "add_can_e_signing",
      "change_can_e_signing",
      "delete_can_e_signing",
      "view_can_fastdoc",
      "add_can_fastdoc",
      "change_can_fastdoc",
      "delete_can_fastdoc",
      "view_can_realestate",
      "view_can_parking",
      "add_can_parking",
      "change_can_parking",
      "delete_can_parking",
      "view_can_tenant",
      "add_can_tenant",
      "change_can_tenant",
      "delete_can_tenant",
      "view_can_company",
      "view_can_baseobject",
      "add_can_baseobject",
      "change_can_baseobject",
      "delete_can_baseobject",
      "view_can_tenantportal",
      "add_can_tenantportal",
      "change_can_tenantportal",
      "delete_can_tenantportal",
    ],
    users: [],
  },
  {
    name: "Pigello - Uthyrning",
    id: 3,
    isBase: true,
    permissions: [
      "view_can_market",
      "add_can_market",
      "change_can_market",
      "delete_can_market",
      "view_can_lead",
      "add_can_lead",
      "change_can_lead",
      "delete_can_lead",
      "view_can_homeq",
      "add_can_homeq",
      "change_can_homeq",
      "delete_can_homeq",
      "view_can_contract",
      "add_can_contract",
      "change_can_contract",
      "delete_can_contract",
      "view_can_e_signing",
      "add_can_e_signing",
      "change_can_e_signing",
      "delete_can_e_signing",
      "view_can_fastdoc",
      "add_can_fastdoc",
      "change_can_fastdoc",
      "delete_can_fastdoc",
      "view_can_realestate",
      "view_can_parking",
      "add_can_parking",
      "change_can_parking",
      "delete_can_parking",
      "view_can_tenant",
      "add_can_tenant",
      "change_can_tenant",
      "delete_can_tenant",
      "view_can_company",
      "view_can_baseobject",
      "add_can_baseobject",
      "change_can_baseobject",
      "delete_can_baseobject",
      "view_can_tenantportal",
      "add_can_tenantportal",
      "change_can_tenantportal",
      "delete_can_tenantportal",
    ],
    users: [],
  },
  {
    name: "Pigello - Systemadministratör",
    id: 4,
    isBase: true,
    permissions: [
      "view_can_reporterrand",
      "add_can_reporterrand",
      "change_can_reporterrand",
      "delete_can_reporterrand",
      "view_can_inspectionerrand",
      "add_can_inspectionerrand",
      "change_can_inspectionerrand",
      "delete_can_inspectionerrand",
      "view_can_roundingerrand",
      "add_can_roundingerrand",
      "change_can_roundingerrand",
      "delete_can_roundingerrand",
      "view_can_goverrand",
      "add_can_goverrand",
      "change_can_goverrand",
      "delete_can_goverrand",
      "view_can_realestate",
      "add_can_realestate",
      "change_can_realestate",
      "delete_can_realestate",
      "view_can_brf",
      "add_can_brf",
      "change_can_brf",
      "delete_can_brf",
      "view_can_company",
      "add_can_company",
      "change_can_company",
      "delete_can_company",
      "view_can_baseobject",
      "add_can_baseobject",
      "change_can_baseobject",
      "delete_can_baseobject",
      "view_can_parking",
      "add_can_parking",
      "change_can_parking",
      "delete_can_parking",
      "view_can_tenant",
      "add_can_tenant",
      "change_can_tenant",
      "delete_can_tenant",
      "view_can_contract",
      "add_can_contract",
      "change_can_contract",
      "delete_can_contract",
      "view_can_tenantportal",
      "add_can_tenantportal",
      "change_can_tenantportal",
      "delete_can_tenantportal",
      "view_can_lead",
      "add_can_lead",
      "change_can_lead",
      "delete_can_lead",
      "view_can_market",
      "add_can_market",
      "change_can_market",
      "delete_can_market",
      "view_can_user",
      "add_can_user",
      "change_can_user",
      "delete_can_user",
      "view_can_permissions",
      "add_can_permissions",
      "change_can_permissions",
      "delete_can_permissions",
      "view_can_billing",
      "add_can_billing",
      "change_can_billing",
      "delete_can_billing",
      "view_can_e_signing",
      "add_can_e_signing",
      "change_can_e_signing",
      "delete_can_e_signing",
      "view_can_fastdoc",
      "add_can_fastdoc",
      "change_can_fastdoc",
      "delete_can_fastdoc",
      "view_can_homeq",
      "view_can_avytmpl",
      "add_can_avytmpl",
      "change_can_avytmpl",
      "delete_can_avytmpl",
      "add_can_homeq",
      "change_can_homeq",
      "delete_can_homeq",
      "view_can_imd",
      "add_can_imd",
      "change_can_imd",
      "delete_can_imd",
      "view_can_fortnox",
      "add_can_fortnox",
      "change_can_fortnox",
      "delete_can_fortnox",
      "view_can_anonymization",
      "add_can_anonymization",
      "change_can_anonymization",
      "delete_can_anonymization",
    ],
    users: [],
  },
];
