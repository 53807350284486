import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useBrfPaymentGroupQuota, updateUrl as brfQuotaUpdateUrl, constants } from "../../store/brfPaymentGroupQuota";
import { detailUrl as paymentGroupDetailUrl } from "../../store/paymentGroup";

import BrfPaymentQuota from "./Detail/BrfPaymentGroupQuota"

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useAllPermissionCheck } from "../../store/base";

export default function PaymentGroupDetail() {
  const { brfPaymentGroupQuotaId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace, push } = useHistory()
  usePermissionRedirect(["allow_brf", "view_can_baseobject", "view_can_brf"]);

  const canDelete = useAllPermissionCheck([
    "delete_can_baseobject",
    "delete_can_brf",
  ]);
  const canEdit = useAllPermissionCheck(["change_can_baseobject", "change_can_brf"])
  
  const [brfQuota, paymentGroupLoading] = useBrfPaymentGroupQuota(brfPaymentGroupQuotaId)
  const paymentGroupId = brfQuota?.payment_group?.id;
 
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleActions = [
     {
         "name":"Redigera",
         onClick: () => push(brfQuotaUpdateUrl(brfPaymentGroupQuotaId, paymentGroupId)),
         disabled: !paymentGroupId,
         hidden: !canEdit
     },
     {
         "name":"Radera",
         onClick: () => setDeleteOpen(true),
         isDelete: true,
         disabled: brfQuota?.quota != 0,
         hidden: !canDelete
     }
  ];

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: BrfPaymentQuota,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  let breadCrumbs = [
    { url: "/brf-companies", label: "BRF" },
    { label: brfQuota?.payment_group?.str_representation || "Laddar...", url: paymentGroupId != null ? paymentGroupDetailUrl({id: paymentGroupId}) : undefined },
    { label: brfQuota?.str_representation || "Laddar..." }
  ]

  return (
    <>
        <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={brfQuota}
        constants={constants}
        deletedCallback={() => replace(paymentGroupDetailUrl({id:paymentGroupId}))}
      />
        {!brfQuota && <OverlaySpinner/>}
        <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Andel ${brfQuota?.str_representation || "laddar..."}`}
          breadCrumbs={breadCrumbs}
          eventContentType="brf.brfpaymentgroupquota"
          eventObjectId={brfPaymentGroupQuotaId}
          eventIdQueryStr="brfpaymentgroupquota"
          {...{
            handleActions,
            subPages: subPageLinks
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
