import * as React from "react";

// style, design
import columnDefs from "./HomeQlistDefs";

// store, state
import { constants, detailUrl } from "../../../store/realEstates";
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";

export default function RealEstateHomeQTable({
  persistantQuery,
  isBare,
  tableId,
  onRowClicked,
  checkRowHighlighted,
}) {
  const columns = React.useMemo(() => {
    const data = columnDefs();
    return data;
  }, [persistantQuery]);

  const filters = [];

  return (
    <>
      <BaseTable
        tableId={tableId || "realestate_full_table"}
        title={"Fastigheter"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage: true,
          onRowClicked,
          columns,
          persistantQuery,
          filters,
          constants,
          checkRowHighlighted,
        }}
      />
    </>
  );
}
