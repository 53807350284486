import { useDispatch, useSelector } from "react-redux";
import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
  buildQueryString,
  useInProgress,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
  getAvailablePeriods,
} from "../store/actions";
import constants from "../store/constants";

export function useParkingInvoicing(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useParkingInvoicings(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredParkingInvoicings(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useParkingInvoicingPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useParkingInvoicingPaginationCount({ filters = {} }) {
  const queryString = buildQueryString({
    _page: 1,
    _page_size: 0,
    ...filters,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}

export const usePeriodForInvoicingParking = ({ id, basedOn }) => {
  const name = `periods_${id}`;
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const currentResult = useSelector((state) => state[storeName][name]);

  const inProgress = useInProgress({ storeName, name });

  if (!id || !basedOn) {
    return [undefined, false];
  }

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (!currentResult) {
    dispatch(
      getAvailablePeriods({
        id,
        basedOn,
        savePeriodsForContract: true,
        processName: name,
      })
    );
    return [undefined, true];
  }

  return [currentResult, false];
};
