import * as React from "react";
import PremisesTable from "../PremisesTable/PremisesTable";

const MAPPER = {
  leaseinvoicing: "Hyresavtal",
  otherinvoicing: "Övrigt avtal",
  parkinginvoicing: "Parkeringsavtal",
  brfinvoicing: "Bostadsrätt",
};

const ATTR_MAPPER = {
  auto_attest: "Automatisk attestering",
  auto_merge: "Automatisk sammanslagning",
  interval_setting: "Aviseringsintervall",
  message: "Meddelande på fakturor",
};

export default function UpdateInvoiceSettingSummary({ confirmData }) {
  const [combinedInvoicings, setCombinedInvoicings] = React.useState([]);

  React.useEffect(() => {
    const res = [];
    // lease contracts
    Object.keys(confirmData.leaseInvoicing || {}).forEach((k) => {
      res.push({
        content_type: "leaseinvoicing",
        id: k,
        str_representation: confirmData.leaseInvoicing[k].str_representation,
        attrs: confirmData.leaseInvoicing[k].invoicingsetting,
      });
    });

    // other contracts
    Object.keys(confirmData.otherInvoicing || {}).forEach((k) => {
      res.push({
        content_type: "otherinvoicing",
        id: k,
        str_representation: confirmData.otherInvoicing[k].str_representation,

        attrs: confirmData.otherInvoicing[k].invoicingsetting,
      });
    });

    // parking contracts
    Object.keys(confirmData.parkingInvoicing || {}).forEach((k) => {
      res.push({
        content_type: "otherinvoicing",
        id: k,
        str_representation: confirmData.parkingInvoicing[k].str_representation,

        attrs: confirmData.parkingInvoicing[k].invoicingsetting,
      });
    });

    // brf contracts
    Object.keys(confirmData.brfInvoicing || {}).forEach((k) => {
      res.push({
        content_type: "brfinvoicing",
        id: k,
        str_representation: confirmData.brfInvoicing[k].str_representation,

        attrs: confirmData.brfInvoicing[k].invoicingsetting,
      });
    });

    setCombinedInvoicings(res);
  }, [confirmData]);

  const rows = React.useMemo(() => {
    return combinedInvoicings.map((ci) => [
      `${MAPPER[ci.content_type]} ${ci.str_representation}`,
      ci.attrs.map((a) => ATTR_MAPPER[a]).join(", "),
    ]);
  }, [combinedInvoicings]);

  return (
    <>
      <PremisesTable
        title="Objekt med attribut som ej påverkas"
        headers={["Objekt", "Attribut som ej påverkas av uppdateringen"]}
        rows={rows}
      />
    </>
  );
}
