export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_USERGROUPS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_USERGROUPS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_USERGROUPS",
  DESTROY_FORM: "DESTROY_FORM_USERGROUPS",
  SET_FORM_ERROR: "SET_FORM_ERROR_USERGROUPS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_USERGROUPS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_USERGROUPS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_USERGROUPS",
  RESET_STATE: "RESET_STATE_USERGROUPS",
  SET_WORKABLE_OBJECTS: "SET_WORKABLE_OBJECTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_USERGROUPS",
  REMOVE_OBJECT: "REMOVE_OBJECT_USERGROUPS",
  CLEAR_FETCHED: "CLEAR_FETCHED_USERGROUPS",

  LIST_URL: "/accounts/groups/usergroup/list/",
  POST_URL: "/accounts/groups/usergroup/",
  PATCH_URL: "/accounts/groups/usergroup/",
  GET_URL: "/accounts/groups/usergroup/",
  WHITELIST_URL: "/permissions/permission_handling/",

  STORE_NAME: "userGroups",
};
