import * as React from "react";
import { buildQueryString } from "../../../store/base";
import { useFilteredMilestones } from "../../../store/leadMilestones";
import { useFilteredNotes } from "../../../store/notes";
import Notes from "../../Details/OverviewInfo/Notes/Notes";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { BodyText, InnerBox } from "../../sharedStyles";

export default function LeadNotes({ lead }) {

  const notesQ = buildQueryString({
    id__in: lead?.notes?.map((n) => n.id) || [],
  });

  const [notes] = useFilteredNotes(notesQ);

  return (
    <InnerBox style={{ border: 0, boxShadow: 0 }}>
      <OverviewTitleWrapper>
        <OverviewTitle>Anteckningar</OverviewTitle>
      </OverviewTitleWrapper>

      <Notes
        notes={notes}
        contentType="market.lead"
        objectId={lead.id}
        modalInModal
        />
    </InnerBox>
  );
}
