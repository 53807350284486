import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  detailUrl,
} from "../../../../store/realEstates";
import { useRealEstateForm } from "../../../../store/realEstates";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import TextInputField from "../../Base/Fields/TextInputField";
import TableSelectField from "../../Base/Fields/TableSelectField";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";
import MediaField from "../../Base/Fields/MediaField";
import UserTable from "src/components/Tables/Users/FullTable";
import SelectField from "../../Base/Fields/SelectField";
import DateSelect from "../../Base/Fields/DateSelect";
import { AddressField } from "../../Base/Fields";
import RadioGroupField from "../../Base/Fields/RadioGroupField";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import CompanyTable from "src/components/Tables/Companies/FullTable";

import PrimaryBtn from "../../Base/Buttons/PrimaryBtn";
import { FolderOpenIcon } from "@heroicons/react/24/outline";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import Modal from "../../Base/Modals/Modal";
import { cloneDeep } from "lodash";

export default ({
  method,
  id,

  onCheckout,
  successCallback,
  instance,
}) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useRealEstateForm(method, id));

  const company = useFormInstanceField({
    storeName,
    fieldKey: "company",
  });

  const userQuery = {
    user_type__in: [0, 1, 2],
  };

  const clearCompany = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          company: null,
        },
      })
    );
  };

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, []);

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    delete dataClone.should_sync_to_avytmpl;

    return dataClone;
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }

    successCallback && successCallback();
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };
  return (
    <Modal
      title={method === "POST" ? "Skapa fastighet" : "Uppdatera fastighet"}
      onAccept={onSubmit}
      closeFunction={onDone}
      acceptTitle="Spara"
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Ägande bolag</div>

      {!company || (company && company.id) ? (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              fieldKey={"company"}
              method={method}
              placeholder="Välj ägande bolag..."
              title="Ägande bolag"
              description="Välj bolag som fastigheten ligger under."
              TableComponent={CompanyTable}
            />
          </div>
        </>
      ) : (
        <>
          <PrimaryBtn secondary onClick={clearCompany}>
            <FolderOpenIcon className="mr-1" width={16} />
            Välj ett existerande bolag istället för att skapa ett nytt{" "}
          </PrimaryBtn>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Namn på ägande bolag"
              {...{ storeName, method, fieldKey: "company.name" }}
            />
            <TextInputField
              title="Orgnr. på ägande bolag"
              {...{ storeName, method, fieldKey: "company.orgnr" }}
            />
          </div>

          <div className="grid grid-cols-2">
            <AddressField
              title="Adress till ägande bolag"
              {...{ storeName, method, fieldKey: "company.address" }}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              method={method}
              fieldKey={"company.invoicing_config"}
              placeholder="Välj aviseringsinställning..."
              title="Föreslagen aviseringsinställning på ägande bolag"
              description={
                "Välj vilken basinställning för avisering som ska föreslås för avtal som skapas under bolaget"
              }
              TableComponent={InvoicingSettingTable}
            />
          </div>
          <MediaField
            storeName={storeName}
            method={method}
            fieldKey={`company.image`}
            instructionsKey={`company.image`}
            allowedFormats={[".png", ".jpg", ".jpeg"]}
            title="Bolagets logotyp"
            fileKey="file"
          />
        </>
      )}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={userQuery}
          method={method}
          fieldKey={"owner"}
          placeholder="Välj ansvarig för fastigheten..."
          title="Ansvarig för fastigheten"
          TableComponent={UserTable}
        />
      </div>

      <hr />
      <div className="mb-6 text-base font-medium">Fastigheten</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Fastighetsbeteckning"
          storeName={storeName}
          fieldKey="key"
          method={method}
        />

        <TextInputField
          title="Publikt namn på fastighet"
          fieldKey="popular_name"
          storeName={storeName}
          method={method}
        />

        <SelectField
          title="Typ av fastighet"
          storeName={storeName}
          fieldKey="type_code"
          method={method}
        />

        <TextInputField
          isNumber
          title="Taxerad area"
          storeName={storeName}
          fieldKey="area"
          method={method}
        />
        <TextInputField
          isNumber
          title="Tomtarea"
          storeName={storeName}
          fieldKey="land_area"
          method={method}
        />
        <TextInputField
          isNumber
          step=".01"
          title="Tomträttsavgäld"
          storeName={storeName}
          fieldKey="ground_rent"
          method={method}
        />

        <DateSelect
          title="Ägare sedan"
          storeName={storeName}
          fieldKey="owner_since"
          method={method}
        />
      </div>

      <div>
        <AddressField
          title="Huvudadress"
          storeName={storeName}
          fieldKey="main_address"
          method={method}
        />
      </div>

      <hr />
      <div className="mb-6 text-base font-medium">Taxering</div>

      <div className="mb-6">
        <RadioGroupField
          {...{
            title: "Använd retroaktiv debitering efter ny taxeringsbestämmelse",
            storeName,
            fieldKey: "tenant_tax_retroactive_addon",
            method,
          }}
          defaultvalue={false}
          options={[
            {
              title: "Ja, debitera retroaktivt",
              value: true,
            },
            {
              title: "Nej, debitera ej retroaktivt",
              value: false,
            },
          ]}
        />
      </div>
      <div className="mb-6">
        <RadioGroupField
          {...{
            title: "Använd retroaktiv reducering efter ny taxeringsbestämmelse",
            storeName,
            fieldKey: "tenant_tax_retroactive_subtraction",
            method,
          }}
          defaultvalue={false}
          options={[
            {
              title: "Ja, debitera retroaktivt",
              value: true,
            },
            {
              title: "Nej, debitera ej retroaktivt",
              value: false,
            },
          ]}
        />
      </div>

      <hr />
      <div className="mb-6 text-base font-medium">Bokföring</div>

      <TableSelectField
        storeName={storeName}
        method={method}
        fieldKey={"cost_center"}
        placeholder="Välj kostnadsställe..."
        title="Kostnadsställe"
        description={
          "Detta kostnadsställe kommer gälla för objekt under denna fastighet om inget annat anges"
        }
        TableComponent={InvoicingCostCenterTable}
        className={"!mt-0"}
      />

      <TableSelectField
        storeName={storeName}
        method={method}
        fieldKey={"project"}
        placeholder="Välj projekt..."
        title="Projekt"
        description={
          "Detta projekt kommer gälla för objekt under denna fastighet om inget annat anges"
        }
        TableComponent={InvoicingProjectsTable}
        className={"!mt-0"}
      />
    </Modal>
  );
};
