import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";

import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import { useParams } from "react-router";
import { useFilteredTenantPortalSettings } from "../../store/tenantPortalSettings";
import { useFilteredRealEstates } from "../../store/realEstates";

export default function TenantPortalInfo() {
  const [portalSettings] = useFilteredTenantPortalSettings("");

  const { id } = useParams();
  const portalSetting = portalSettings?.find((ps) => ps.id == id);

  const [allRealEstates] = useFilteredRealEstates("");
  const allRealEstateIds = allRealEstates?.map((r) => r.id);

  const includedRealEstateIds = portalSetting?.realestates.map((id) => id.id);

  const excludedRealEstateIds = portalSettings?.reduce((acc, cur) => {
    const currentRealEstateIds = cur.realestates?.map((r) => r.id);

    return [...acc, ...currentRealEstateIds];
  }, []);

  const includedRealEstatesIfSettingEmpty = allRealEstateIds?.filter(
    (rid) => !excludedRealEstateIds?.includes(rid)
  );

  let persistantQuery = {};

  if (includedRealEstateIds?.length > 0) {
    persistantQuery = {
      realestate_ids: includedRealEstateIds,
    };
  } else {
    persistantQuery = {
      realestate_ids: includedRealEstatesIfSettingEmpty,
    };
  }

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Hyresgäster under denna inställnining</OverviewTitle>
          </OverviewTitleWrapper>

          <TenantTable persistantQuery={persistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
