import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ChecklistForm from "../../../components/Forms/Checklist/ChapterForm/ModalForm";
import ChecklistTable from "../../../components/Tables/Checklists/FullTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

export default function Checklists() {
  const [addOpen, setAddOpen] = React.useState(false);

  return (
    <>
      <ChecklistForm
        method="POST"
        onCheckout={() => setAddOpen(false)}
        isOpen={addOpen}
        instance={{
          hide_for_tenants: false,
          hide_for_non_tenants: false,
        }}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Checklistor</OverviewTitle>
              <OverviewSubtitle>
                Checklistor har två huvudsakliga användningsområden:
                Självavhjälpande för hyresgäster samt egenkontroll vid
                besiktning eller felavhjälpande av en komponent. Checklistor
                säkerställer att viktiga processer efterlevs och bidrar till
                bättre statistik vid uppföljning av ärenden.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              extraStyle={{ marginLeft: 12 }}
              title="Lägg till checklista"
              clicked={() => setAddOpen(true)}
            />
          </OverviewTitleWrapper>
          <ChecklistTable />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
