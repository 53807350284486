import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

// style, design
import * as SC from "../Base/Chapters/styles";
import { DoubleFieldWrapper } from "../Base/Chapters/styles";
import { TextInput, Checkbox } from "../Base/Fields";
import { PrimaryButton } from "../Base/Buttons";

// store, state
import {
  constants,
  destroyPostForm,
  create,
  update,
} from "../../../store/billectaKyc";
import { constants as companyConstants } from "../../../store/companies";
import { useKycForm } from "../../../store/billectaKyc/hooks/form";
import ManyRelationalOneWay from "../Base/FieldComponents/ManyRelationalOneWay";
import UsPersonNestedChildren from "./UsPersonNestedChildren";
import MajorityHoldersNestedChildren from "./MajorityHoldersNestedChildren";
import BeneficialOwnersNestedChildren from "./BeneficialOwnersNestedChildren";
import { useHistory } from "react-router-dom";
import { clearFetched } from "../../../store/base";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import TextInputField from "../Base/Fields/TextInputField";
import CheckField from "../Base/Fields/CheckField";
import PrimaryBtn from "../Base/Buttons/PrimaryBtn";

export default ({ company, existingKyc }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  // Always POST for KYC
  const method = "POST";

  const formLoaded = Boolean(useKycForm());

  const managesCurrencyExchanges = useSelector(
    (state) => !!state[storeName].formInstance?.managing_currency_exchanges
  );
  const managesCash = useSelector(
    (state) => !!state[storeName].formInstance?.manages_cash
  );
  const hasUsOwners = useSelector(
    (state) => !!state[storeName].formInstance?.has_us_owner
  );
  const hasMajorityOwners = useSelector(
    (state) => !!state[storeName].formInstance?.has_owners_with_majority
  );
  const hasBeneficialOwners = useSelector(
    (state) => !state[storeName].formInstance?.has_no_benifitial_owners
  );

  const getUsPersonChildren = (index) => {
    const parentPath = `us_persons[${index}]`;
    const parentInstructionsPath = "us_persons";

    return (
      <UsPersonNestedChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  const getMajorityHoldersChildren = (index) => {
    const parentPath = `majority_holders[${index}]`;
    const parentInstructionsPath = "majority_holders";

    return (
      <MajorityHoldersNestedChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  const getBeneficialHoldersChildren = (index) => {
    const parentPath = `benifitial_owners[${index}]`;
    const parentInstructionsPath = "benifitial_owners";

    return (
      <BeneficialOwnersNestedChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  const checkout = (success) => {
    setLoading(false);
    if (success) {
      dispatch(clearFetched(companyConstants, true));
    }

    push(`/rental-notifications/company/${company.billecta_id}`);
    dispatch(destroyPostForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    if (existingKyc) {
      dispatch(
        update({
          id: existingKyc.id,
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    } else {
      dispatch(
        create({
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  return (
    <>
      {formLoaded ? (
        <div>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Bolagsnamn"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="name"
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Namn på signerande part"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="signing_name"
            />

            <TextInputField
              title="Personnummer på signerande part"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="signing_legal_id"
            />
          </div>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="E-post"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="email"
            />

            <TextInputField
              title="Telefon"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="phone"
            />
          </div>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Medborgare i land"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="citizen_ship_country"
            />

            <TextInputField
              title="Skatteresidens"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="tax_country"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Politiskt utsatt person"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="has_pep_business_relation"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget har majoritetsägare"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="has_owners_with_majority"
            />
          </div>

          {hasMajorityOwners && (
            <ManyRelationalOneWay
              storeName={constants.STORE_NAME}
              title="Majoritetsägare"
              method={method}
              fieldKey="majority_holders"
              getChildren={getMajorityHoldersChildren}
              modalTitle="Lägg till majoritetsägare"
              constructStrRep={(obj) => {
                return obj.name;
              }}
            />
          )}

          <div className=" mb-6">
            <CheckField
              title="Inga huvudmän"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="has_no_benifitial_owners"
            />
          </div>

          {hasBeneficialOwners && (
            <ManyRelationalOneWay
              storeName={constants.STORE_NAME}
              method={method}
              title="Ägare"
              fieldKey="benifitial_owners"
              getChildren={getBeneficialHoldersChildren}
              modalTitle="Lägg till ägare"
              constructStrRep={(obj) => {
                return obj.name;
              }}
            />
          )}

          <div className=" mb-6">
            <CheckField
              title="Någon av ägarna är politiskt utsatt"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_anyone_pep"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget är ett finansbolag"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_financial_institute"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget är ett aktivt icke-finansiellt bolag"
              description={`Ett företag definieras som aktiv icke‐finansiell enhet bl.a. om något avföljande stämmer: Minst 50 procent av företagets inkomster före skatt kom från försäljning av varor och/eller tjänster under föregående räkenskapsår Företaget är, eller ägs av stat, kommun eller landsting. Företagets eller företagsgruppens aktier handlas på en etablerad värdepappersmarknad
          `}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_active_non_financial_institute"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="has_us_owner"
              title="Bolaget har amerikanska ägare"
            />
          </div>

          {hasUsOwners && (
            <ManyRelationalOneWay
              storeName={constants.STORE_NAME}
              method={method}
              fieldKey="us_persons"
              getChildren={getUsPersonChildren}
              modalTitle="Lägg till amerikansk medborgare"
              constructStrRep={(obj) => {
                return obj.name;
              }}
            />
          )}

          <div className=" mb-6">
            <CheckField
              title="Bolaget är skatteskyldigt i U.S.A."
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="taxable_in_us"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget hanterar kontanter"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="manages_cash"
            />
          </div>

          {managesCash && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TextInputField
                title="Anledning till kontanthantering"
                method={method}
                storeName={constants.STORE_NAME}
                fieldKey="managing_cash_purpose"
              />
            </div>
          )}

          <div className=" mb-6">
            <CheckField
              title="Bolaget hanterar valutaväxling"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="managing_currency_exchanges"
            />
          </div>

          {managesCurrencyExchanges && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TextInputField
                title="Anledning till hantering av valutaväxling"
                method={method}
                storeName={constants.STORE_NAME}
                fieldKey="managing_currency_exchange_purpose"
              />
            </div>
          )}
          <div className=" mb-6">
            <CheckField
              title="Bolaget handlar med fastigheter"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_real_estate_sales"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bygger fastigheter"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_real_estate_construction"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver restaurang"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_restaurant"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver kafé"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_cafe"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver mäkleri"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_broker"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver nattklubb"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_night_club"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver kioskverksamhet"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_kiosk"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver kasino/hasard-spel online"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_online_gambling"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver städverksamhet"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_cleaning"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver vadslagningsverksamhet"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_betting"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver taxiverksamhet"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_taxi"
            />
          </div>
          <div className=" mb-6">
            <CheckField
              title="Bolaget bedriver frisörverksamhet"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="is_barber"
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Bolagets övriga affärsverksamheter"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="other_service"
            />
          </div>

          <div className=" mb-6">
            <CheckField
              title="Jag/vi intygar att informationen angiven är korrekt"
              description={`
            Jag/vi försäkrar på heder och samvete att här lämnade uppgifter är riktiga 
och jag/vi förbinder oss att utan dröjsmål anmäla förändringar i detta 
avseende till Billecta AB. Undertecknade godkänner även att uppgifter om 
företaget/enheten och verkliga huvudmän rapporteras till relevanta 
skattemyndigheter för det fall rättsliga rapporteringsplikt föreligger.`}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="accept_value"
              alwaysShowHelp
            />
          </div>

          <PrimaryBtn onClick={onSubmit}>Registera blankett</PrimaryBtn>
        </div>
      ) : (
        <OverlaySpinner />
      )}
    </>
  );
};
