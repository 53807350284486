import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  updateActiveFormInstance,
  useFormField,
  useFormInstanceField,
  usePermissionCheck,
} from "../../../store/base";
import {
  useEditabledoc,
  useEditabledocForm,
  constants,
  update,
  destroyPatchForm,
} from "../../../store/editabledocs";

import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";

import { OverviewTitleWithSubtitleWrapper } from "../../../components/Details/OverviewInfo/styles";

import Alert from "../../../components/Alert/Alert";

export default function Settings() {
  const dispatch = useDispatch();
  const { editableDocId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;
  const method = "PATCH";

  const [editableDoc] = useEditabledoc(editableDocId);
  useEditabledocForm("PATCH", editableDocId);
  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const canChange = usePermissionCheck("change_can_contract");

  React.useEffect(() => {
    if (editableDoc) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: editableDoc,
        })
      );
    }
  }, [editableDoc]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  const saveUpdate = () => {
    setLoading(true);
    const cleanData = {
      title: instance.title,
      category: instance.category,
      days_to_sign: instance.days_to_sign,
      days_to_remind: instance.days_to_remind,
      invitation_message: instance.invitation_message,
      confirmation_message: instance.confirmation_message,
    };

    dispatch(
      update({
        id: editableDocId,
        forceData: cleanData,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  const categoryInstructions = useFormField({
    storeName,
    method,
    fieldKey: "category",
  });

  const getDisplayCategory = (cat) => {
    if (!categoryInstructions || !categoryInstructions._choices) return "-";
    const found = categoryInstructions._choices.find((obj) => obj.v === cat);
    if (!found) return "-";
    return found.d;
  };

  const getDisplayFromBoolean = (bool) => {
    if (!bool) return "Nej";
    return "Ja";
  };

  //WTF, Select?
  const infoObj = {
    Inställningar: [
      {
        title: "Namn på mall",
        value: instance.title,
      },
      {
        title: "Kategori",
        value: getDisplayCategory(instance.category),
      },
    ],
    "Scrive-signering": [
      {
        title: "Antal dagar att signera",
        value: instance.days_to_sign,
      },
      {
        title: "Påminnelse skickas efter X dagar",
        value: instance.days_to_remind,
      },
      {
        title: "Inbjudningsmeddelande",
        value: instance.invitation_message,
      },
      {
        title: "Bekräftelsemeddelande",
        value: instance.confirmation_message,
      },
    ],
  };

  return (
    <DetailInnerWrapper>
      <Alert title="Redigera inställningar">
        <p>
          Inställningarna för mallen redigeras inne på mallen. Gå till "Mall"
          för att redigera inställningarna.
        </p>
      </Alert>
      <DetailPageBox>
        {loading && <OverlaySpinner />}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Inställningar för mall</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <DetailInfo infoObj={infoObj} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
