import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TableSelectField from "../Base/Fields/TableSelectField";
import TextAreaField from "../Base/Fields/TextAreaField";
import TextInputField from "../Base/Fields/TextInputField";
import {
  constants,
  useRoundingAreaForm,
  destroyPatchForm,
  destroyPostForm,
  create,
  update,
  detailUrl,
} from "../../../store/roundingAreas";
import { updateActiveFormInstance } from "src/store/base";
import { cloneDeep } from "lodash";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import Modal from "../Base/Modals/Modal";
import ComponentTypesTable from "src/components/Tables/ErrandComponentTypes/FullTable";

export default function RoundingAreaModalForm({
  method,
  id,
  onCheckout,
  instance,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useRoundingAreaForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }

    return () => {
      dispatch(destroyPostForm());
      dispatch(destroyPatchForm());
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    onCheckout(success);
  };

  const onSuccess = (data, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "PATCH") {
      dispatch(updateActiveFormInstance({ storeName, data: returned }));
    }

    if (method === "POST") {
      push(detailUrl({ id: returned.id, isGov: false }));
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  React.useEffect(() => {
    return () => {
      dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    };
  }, []);

  return (
    <Modal
      title={
        method === "POST"
          ? "Skapa ronderingsområde"
          : "Uppdatera ronderingsområde"
      }
      closeFunction={onDone}
      onAccept={onSubmit}
    >
      {(!formLoaded || loading) && <OverlaySpinner />}

      <div className="mb-6 text-base font-medium">Ronderingsområde</div>
      <div className="grid grid-cols-2 gap-2 mb-2">
        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey={`title`}
          title="Namn på område"
        />
        <TableSelectField
          title="Fastigheter i område"
          storeName={storeName}
          method={method}
          fieldKey={`realestates`}
          TableComponent={RealEstateTable}
          isMany
        />
      </div>

      <div className="grid grid-cols-1 gap-2 mb-2">
        <TextAreaField
          storeName={storeName}
          method={method}
          fieldKey={`description`}
          title="Områdesbeskrivning"
        />
      </div>

      <div className="mb-6 text-base font-medium">
        Komponenttyper som ingår i område
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <TableSelectField
          title="Komponettyper som ingår i område"
          storeName={storeName}
          method={method}
          fieldKey={`component_types`}
          TableComponent={ComponentTypesTable}
          isMany
        />
      </div>
    </Modal>
  );
}
