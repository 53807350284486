export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PREMISES_STATUSES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PREMISES_STATUSES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PREMISES_STATUSES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PREMISES_STATUSES",
  DESTROY_FORM: "DESTROY_FORM_PREMISES_STATUSES",
  SET_FORM_ERROR: "SET_FORM_ERROR_PREMISES_STATUSES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PREMISES_STATUSES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PREMISES_STATUSES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PREMISES_STATUSES",
  RESET_STATE: "RESET_STATE_PREMISES_STATUSES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PREMISES_STATUSES",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PREMISES_STATUSES",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PREMISES_STATUSES",
  CLEAR_FETCHED: "CLEAR_FETCHED_PREMISES_STATUSES",

  LIST_URL: "/standard/mainlevel/premises/premisesstatus/list/",
  POST_URL: "/standard/mainlevel/premises/premisesstatus/",
  PATCH_URL: "/standard/mainlevel/premises/premisesstatus/",
  GET_URL: "/standard/mainlevel/premises/premisesstatus/",

  STORE_NAME: "premisesStatuses",
};
