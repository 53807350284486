import * as React from "react";

// style, design
import {
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import {
  AsyncSelect,
  TextInput,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/fortnoxCredential";
import { useFilteredCompanies } from "../../../../../store/companies";
import { buildQueryString } from "../../../../../store/base";

export default ({ method }) => {

  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Fortnox Koppling</FormAreaTitle>
      <AsyncSelect
      storeName={storeName}
      method={method}
      fieldKey={"company"}
      fetchMethod={() => useFilteredCompanies(buildQueryString({has_no_fortnox_credential:true}))}
      title="Företag"
      />
      <TextInput
      storeName={storeName}
      method={method}
      fieldKey={"auth_code"}
      title="API kod"
      />
    </>
  );
};
