import * as React from "react";
import * as SC from "./styles";

import Select from "react-select";

export default function Pagination({
  canPreviousPage,
  canNextPage,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
  fullscreenModeActive,
}) {
  const lastPage = pageOptions[pageOptions.length - 1];
  return (
    <SC.PaginationWrapper {...{ fullscreenModeActive }}>
      <span>
        Sida{" "}
        <strong>
          {pageIndex + 1} av {pageOptions.length}
        </strong>{" "}
      </span>
      {/* TODO: check if this fix is acceptable */}
      <SC.ButtonWrapper
        style={{
          flexDirection: "row-reverse",
          minWidth: "160px",
        }}
      >
        {canNextPage && (
          <>
            {" "}
            <SC.PaginationButton
              onClick={() => gotoPage(lastPage)}
              disabled={!canNextPage}
            >
              {">>"}
            </SC.PaginationButton>
            <SC.PaginationButton
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </SC.PaginationButton>
          </>
        )}
        {canPreviousPage && (
          <>
            <SC.PaginationButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </SC.PaginationButton>
            <SC.PaginationButton
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </SC.PaginationButton>
          </>
        )}
      </SC.ButtonWrapper>
      {(canNextPage || canPreviousPage) && (
        <span>
          Gå till sida:{" "}
          <SC.PaginationInput
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>
      )}{" "}
      <SC.SelectWrapper>
        <Select
          style={{ width: "120px" }}
          value={{
            label: `Visa ${pageSize === 10000 ? "Alla" : pageSize}`,
            value: pageSize,
          }}
          menuPlacement="top"
          onChange={(v) => {
            setPageSize(Number(v.value));
          }}
          options={[5, 10, 25, 50, 100, 10000].map((pageSize) => ({
            label: `Visa ${pageSize === 10000 ? "Alla" : pageSize}`,
            value: pageSize,
          }))}
        />
      </SC.SelectWrapper>
    </SC.PaginationWrapper>
  );
}
