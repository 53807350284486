export default [
  {
    title: "Komponent",
    key: "COMPONENT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "product_types",
      "room",
      "parking_spot",
      "archived",
      "realestate",
      "building",
    ],
  },
];
