import * as React from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";

import debounce from "debounce-promise";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { axiosInstance } from "../../../../store/base/store/axios";
import { constants, useAddresses } from "../../../../store/addresses";

export default React.memo(
  ({ storeName, method, fieldKey, instructionsKey, title, description }) => {
    const dispatch = useDispatch();
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const [choices, isLoading] = useAddresses();

    const wait = 500;
    const loadOptions = (inputValue) => getAsyncOptions(inputValue);
    const debouncedLoadOptions = debounce(loadOptions, wait, {
      leading: true,
    });

    const getAsyncOptions = (inputValue) => {
      if (inputValue.length < 3) return new Promise((resolve) => resolve([]));

      return new Promise((resolve) => {
        axiosInstance
          .get(`${constants.LOOKUP_URL}${inputValue}/`)
          .then(({ data }) => {
            resolve(data);
          });
      });
    };

    const onChange = async (data) => {
      const promises = [];
      const cached = [];

      if (data !== null) {
        data.forEach((d) => {
          if (d.id) {
            cached.push({ id: d.id, str_representation: d.str_representation });
          } else if (d.place_id) {
            promises.push(
              axiosInstance.get(`${constants.LOOKUP_DETAIL_URL}${d.place_id}/`)
            );
          }
        });
      }

      const details = await Promise.all(promises);
      const formattedData = [...details.map((fd) => fd.data), ...cached];

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
    };

    const getOptionLabel = (option) => {
      return option.str_representation || option.description || option.base;
    };

    const getOptionValue = (option) => {
      return option.id || option.base;
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing>
        <SC.InputFieldTitle>
          {title || "Adresser"}
          {instructions._required ? "*" : ""}
        </SC.InputFieldTitle>
        {description && (
          <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
        )}
        {choices ? (
          <SC.SelectWrapper>
            <AsyncSelect
              id={instructions._internalId}
              placeholder="Skriv för att söka eller välj sparad adress"
              onChange={onChange}
              defaultValue={[]}
              cacheOptions={true}
              defaultOptions={choices}
              value={value}
              loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
              getOptionValue={(option) => getOptionValue(option)}
              getOptionLabel={(option) => getOptionLabel(option)}
              isClearable={!instructions._required}
              isMulti={true}
            />
          </SC.SelectWrapper>
        ) : (
          <SC.SelectLoader>Laddar alternativ...</SC.SelectLoader>
        )}

        {!!error && (
          <SC.ErrorMessage>Några av objekten innehåller fel</SC.ErrorMessage>
        )}
      </SC.InputSpacing>
    );
  }
);
