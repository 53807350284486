import * as React from "react";

// style, design
import { LinkedObject, ToolTipCell } from "../../../Displays";

import SimpleTextFilter from "../../../Lists/Base/CompleteList/Filters/TextFilter";

import StateFilter from "./Filters/StateFilter";
import CategoryFilter from "./Filters/CategoryFilter";
import VehicleSupportFilter from "./Filters/VehicleSupportFilter";

import { StatusLabel } from "../../../Lists/Base/CompleteList/styles";
import { detailUrl as lotDetailurl } from "../../../../store/parkingLots";
import { detailUrl as tenantDetailUrl } from "../../../../store/tenants";
import { detailUrl as companyDetailUrl } from "../../../../store/companies";
import { detailUrl as realEstateDetailUrl } from "../../../../store/realEstates";

import { toMoneyString } from "../../../utils/stringUtils";
import { lightOrDark } from "../../../DigitalDoc/utils";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }) => {
        return (
          <StatusLabel contentType="premises" state={row.original.state}>
            {row.original.state_display}
          </StatusLabel>
        );
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Platsnr",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kategori",
      accessor: (row, _) => row?.specification?.category_display || null,
      id: "category",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: CategoryFilter,
      filter: "textExact",
    },
    {
      Header: "Typ av plats",
      accessor: (row, _) => row?.specification?.vehicle_support_display || null,
      id: "vehicle_support",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: VehicleSupportFilter,
      filter: "textExact",
    },
    {
      Header: "Adress",
      accessor: (row, _) => row?.address?.str_representation || null,
      id: "address",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Fastighet",
      accessor: "realestate.str_representation",
      Cell: ({ row }) => {
        // (row) => row.realestate?.str_representation
        const value = row.original.realestate;
        return <LinkedObject obj={value} urlMethod={realEstateDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Område",
      accessor: "parking_lot.str_representation",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.parking_lot} urlMethod={lotDetailurl} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Hyra (SEK/månad)",
      accessor: "rent",
      Cell: ({ row, value }) => <ToolTipCell text={toMoneyString(value)} />,
      // Filter: NumberRangeFilter,
      // filter: "between",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Hyra (SEK/år)",
      id: "yearly_rent",
      Cell: ({ row }) => {
        const value = row.original.rent * 12;

        return <ToolTipCell text={toMoneyString(value)} />;
      },
      // Filter: NumberRangeFilter,
      // filter: "between",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Hyresgäst",
      accessor: "tenant.str_representation",
      id: "tenant_name",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.tenant} urlMethod={tenantDetailUrl} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Hyresvärd",
      accessor: "landlord.str_representation",
      id: "landlord",
      Cell: ({ row }) => (
        <LinkedObject
          obj={row.original.landlord}
          urlMethod={companyDetailUrl}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Anpassade statusar",
      accessor: "customer_statuses",
      Cell: ({ value }) => {
        if (!value?.length) return <div>-</div>;

        return value.slice(0, 2).map((status) => {
          return (
            <StatusLabel
              style={{
                backgroundColor: status.status.color_code || "#f1f1f1",
                color:
                  lightOrDark(status.status.color_code || "#f1f1f1") === "light"
                    ? "#000"
                    : "#fff",
              }}
            >
              {status?.str_representation}
            </StatusLabel>
          );
        });
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];

  // if (withCosts && onlyWithCosts) {
  //   cols.splice(0, 0, {
  //     Header: "",
  //     id: "__expander",
  //     Cell: ({ row }) => {
  //       return (
  //         <span {...row.getToggleRowExpandedProps()}>
  //           {row.isExpanded ? <span>▼</span> : <span>{">"}</span>}
  //         </span>
  //       );
  //     },
  //     disableFilters: true,
  //     disableGlobalFilter: true,
  //     disableSortBy: true,
  //   });
  // }

  // if (withCosts && onlyWithCosts) {
  //   return cols.concat(
  //     getObjectCostColumns(
  //       startDate,
  //       endDate,
  //       ["CATEGORY_MEMBER_FEE", "CATEGORY_IMD", "CATEGORY_DEPOSIT"],
  //       useSqm,
  //       showMonthlyCosts
  //     )
  //   );
  // }

  return cols;
};
