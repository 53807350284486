import * as React from "react";
import * as SC from "./styles";

import { PrimaryButton, RemoveButton } from "../Forms/Base/Buttons";
import { FloorSelector } from "./MenuItems";

import ModalForm from "../Forms/Blueprint/ChapterForm/ModalForm";
import DeleteModal from "../Forms/Delete/DeleteModal";
import { constants } from "../../store/blueprints";
import { updateActiveFormInstance, usePermissionCheck } from "../../store/base";
import { useDispatch } from "react-redux";

export default function BlueprintMenu({
  currentBlueprint,
  floors,
  buildingId,
}) {
  const dispatch = useDispatch();
  const [createBlueprintOpen, setCreateBlueprintOpen] = React.useState(false);
  const [deleteBlueprintOpen, setDeleteBlueprintOpen] = React.useState(false);

  const canAddBlueprint = usePermissionCheck("add_can_baseobject");
  const canDeleteBlueprint = usePermissionCheck("delete_can_baseobject");

  const handleCreateBlueprint = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          building: buildingId,
        },
      })
    );

    setCreateBlueprintOpen(true);
  };

  return (
    <>
      <ModalForm
        method="POST"
        isOpen={createBlueprintOpen}
        onCheckout={() => setCreateBlueprintOpen(false)}
      />
      <DeleteModal
        isOpen={deleteBlueprintOpen}
        closeFunction={() => setDeleteBlueprintOpen(false)}
        instance={currentBlueprint}
        constants={constants}
      />

      <SC.MenuWrapper>
        {floors?.length > 0 && <FloorSelector floors={floors} />}
        {canAddBlueprint && (
          <PrimaryButton
            extraStyle={{ marginLeft: "auto" }}
            title="Lägg till planritning"
            clicked={handleCreateBlueprint}
          />
        )}

        {currentBlueprint &&
        currentBlueprint.state === "set" &&
        canDeleteBlueprint ? (
          <RemoveButton
            extraStyle={{ marginLeft: "24px" }}
            title=""
            clicked={() => setDeleteBlueprintOpen(true)}
          />
        ) : null}
      </SC.MenuWrapper>
    </>
  );
}
