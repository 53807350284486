import * as React from "react";
import moment from "moment";
import { useParams } from "react-router";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useFilteredMeasureValues } from "../../../store/IMDMeasureValue";
import { useFilteredSensors } from "../../../store/IMDSensor";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import { buildQueryString } from "../../../store/base";
import SensorTable from "../../../components/Tables/Sensors/FullTable";
import SensorGraphs from "../../IMD/SensorGraphs";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

export default function BrfPremisesIMD() {
  const TABS = {
    GRAPH: "Grafer",
    SENSORS: "Sensorer",
  };
  const { brfPremisesId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState("GRAPH");

  const persistantQuery = brfPremisesId ? { brf_premis: brfPremisesId } : null;

  const [sensors, sensorsLoading] = useFilteredSensors(
    !brfPremisesId ? null : buildQueryString({ brf_premis: brfPremisesId })
  );

  const startDay = moment().subtract(90, "days");
  const [measureValues, measuresLoading] = useFilteredMeasureValues(
    !brfPremisesId || sensorsLoading || !sensors
      ? null
      : buildQueryString({
          sensor__in: sensors?.map((v) => v.id),
          for_date__gte: `${startDay.format("YYYY-MM-DD")} 00:00:00`,
        })
  );

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>IMD</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        {selectedTab === "SENSORS" && (
          <SensorTable persistantQuery={persistantQuery} />
        )}

        {selectedTab === "GRAPH" && (
          <>
            {measureValues?.length > 0 ? (
              <SensorGraphs sensors={sensors} measureValues={measureValues} />
            ) : (
              <BodyText>
                Inga mätvärden registrerade på sensorer kopplade till detta
                objekt
              </BodyText>
            )}
          </>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
