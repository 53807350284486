import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../store/rentIncrease";
import {
  useFilteredIndustrialPremises,
  overviewUrl as indpOverviewUrl,
  useIndustrialPremises,
} from "../../store/industrialPremises";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { PrimaryButton, TextButton } from "../../components/Forms/Base/Buttons";
import StandardModal from "../../components/Modals/StandardModal";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../components/sharedStyles";
import useActiveFilters from "../../hooks/useActiveFilters";
import { buildQueryString } from "../../store/base";

import {
  addIndp,
  addMultipleIndps,
  performIndpUpdate,
  removeIndp,
  setIndpIncreaseDateForId,
  setIndpIncreases,
} from "../../store/rentIncrease/store/actions";
import PremisesTable from "../../components/PremisesTable/PremisesTable";
import NonConnectedNumberInput from "../../components/Forms/Base/Old/NonConnected/NonConnectedNumberInput";
import NonConnectedDatePicker from "../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { cloneDeep } from "lodash";
import { RemoveButton } from "../../components/Billecta/Invoices/InvoiceComponents/Multiple/styles";
import { InfoBox } from "../../components/Displays";
import useQuery from "../../components/utils/useQuery";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { useHistory } from "react-router-dom";
import RentIncreaseGuide from "../../components/Details/Apartment/RentIncreaseGuide";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import NonConnectedRentField from "../../components/Forms/Base/Old/NonConnected/NonConnectedRentField";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import moment from "moment";
import { toMoneyString } from "../../components/utils/stringUtils";
import { PRODUCT_CATEGORIES } from "../../store/invoicingProducts";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import Modal from "src/components/Forms/Base/Modals/Modal";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import LocalTextInputField from "src/components/Forms/Base/Fields/LocalTextInputField";
import LocalCheckField from "src/components/Forms/Base/Fields/LocalCheckField";

const EDIT_ALL_TYPES = {
  PERCENTAGE: "PERCENTAGE",
  SEK: "SEK",
  DATES: "DATES",
};

export default function IndustrialPremisesRentIncrease() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { filteredRealEstates } = useActiveFilters();
  const [loading, setLoading] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [canSave, setCanSave] = React.useState(false);
  const [selectorOpen, setSelectorOpen] = React.useState(false);
  const [hasSetFromQuery, setHasSetFromQuery] = React.useState(false);
  const [isEditingAll, setIsEditingAll] = React.useState(false);
  const [editAllSek, setEditAllSek] = React.useState(null);
  const [editAllPercent, setEditAllPercent] = React.useState(null);
  const [editAllDate, setEditAllDate] = React.useState(null);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const [retroActivePeriods, setRetroActivePeriods] = React.useState(1);
  const [retroActiveCappedForContract, setRetroActiveCappedForContract] =
    React.useState(false);

  const { push } = useHistory();

  const query = useQuery();

  const realEstate = query.get("realestate");
  const building = query.get("building");
  const indpId = query.get("indpid");

  const realEstateQueryObj = {
    realestate_ids: realEstate ? [realEstate] : [],
  };
  if (!realEstate) {
    realEstateQueryObj.id = -1;
  }

  const buildingQueryObj = {
    building: building,
  };
  if (!building) {
    buildingQueryObj.id = -1;
  }
  const realEstateQuery = buildQueryString(realEstateQueryObj);
  const buildingQuery = buildQueryString(buildingQueryObj);

  // get eventual apartments from eventual query
  const [indpsMatchingRealEstate, realEstateMatchingLoading] =
    useFilteredIndustrialPremises(realEstateQuery);
  const [indpsMatchingBuilding, buildingMatchingLoading] =
    useFilteredIndustrialPremises(buildingQuery);
  const [singularIndp] = useIndustrialPremises(indpId);

  const increases = useSelector((state) => state[storeName].indpIncreases);

  React.useEffect(() => {
    if (hasSetFromQuery) return;

    const combined = [
      ...(indpsMatchingBuilding || []),
      ...(indpsMatchingRealEstate || []),
    ];

    if (singularIndp) {
      combined.push(singularIndp);
    }

    if (combined?.length) {
      setHasSetFromQuery(true);

      dispatch(addMultipleIndps({ indps: combined }));
    }
  }, [indpsMatchingRealEstate, indpsMatchingBuilding, singularIndp]);

  React.useEffect(() => {
    if (increases.length) {
      const anyRowHasError = increases.find((i) => !i.base_rent);

      if (anyRowHasError) {
        setCanSave(false);
      } else {
        setCanSave(true);
      }
    }
  }, [increases]);

  React.useEffect(() => {
    return () => {
      dispatch(setIndpIncreases({ newIncreases: [] }));
    };
  }, []);

  const checkRowHighlighted = (original) => {
    let match = false;

    match = increases?.find((s) => s.id === original.id);

    return match;
  };

  const onSelected = (original) => {
    if (increases.find((i) => i.id === original.id)) {
      dispatch(removeIndp({ indp: original }));
    } else {
      dispatch(addIndp({ indp: original }));
    }
  };

  const clearNonValidPremises = () => {
    let newIncreases = cloneDeep(increases);

    newIncreases = newIncreases.filter((i) => !!i.base_rent);

    dispatch(setIndpIncreases({ newIncreases }));
  };

  const handleIncreaseUpdated = ({ id, val, totalCost }) => {
    const newIncreases = cloneDeep(increases);

    const cur = newIncreases.find((obj) => obj.id === id);

    const quota = ((val / totalCost) * 100).toFixed(2);

    cur.quota = quota;
    cur.increase_in_sek = val;
    cur.new_rent = (parseFloat(totalCost) + parseFloat(val)).toFixed(2);

    dispatch(setIndpIncreases({ newIncreases }));
  };

  const handleIncreasePercentageUpdated = ({ id, val, totalCost }) => {
    const newIncreases = cloneDeep(increases);

    const cur = newIncreases.find((obj) => obj.id === id);

    const increaseInSek = (totalCost * (val / 100)).toFixed(2);

    cur.quota = val;
    cur.increase_in_sek = increaseInSek;
    cur.new_rent = (parseFloat(totalCost) + parseFloat(increaseInSek)).toFixed(
      2
    );

    dispatch(setIndpIncreases({ newIncreases }));
  };

  const handleNewRentUpdated = ({ id, val, totalCost }) => {
    const newIncreases = cloneDeep(increases);
    const cur = newIncreases.find((obj) => obj.id === id);

    const increaseInSek = val - totalCost.toFixed(2);
    const quota = (increaseInSek / totalCost) * 100;

    cur.quota = quota;
    cur.increase_in_sek = increaseInSek;
    cur.new_rent = val;

    dispatch(setIndpIncreases({ newIncreases }));
  };

  const handleApplyFromUpdates = ({ id, val }) => {
    dispatch(setIndpIncreaseDateForId({ id, val }));
  };

  const handleEditAllPercentageUpdated = (val) => {
    setEditAllPercent(val);
    setEditAllSek(0);
  };

  const handleEditAllSekUpdated = (val) => {
    setEditAllPercent(0);
    setEditAllSek(val);
  };

  const handleEditAllApplyFromDates = (val) => {
    setEditAllDate(val);
  };

  const handleSetAll = () => {
    const newIncreases = cloneDeep(increases);
    let val;

    if (isEditingAll === EDIT_ALL_TYPES.SEK) {
      val = editAllSek;
      newIncreases.forEach((i) => {
        if (!i.base_rent) return;
        const quota = ((val / i.base_rent) * 100).toFixed(2);

        i.quota = quota;
        i.increase_in_sek = val;
        i.new_rent = parseFloat(i.base_rent) + parseFloat(val);
      });
    } else if (isEditingAll === EDIT_ALL_TYPES.PERCENTAGE) {
      val = editAllPercent;
      newIncreases.forEach((i) => {
        if (!i.base_rent) return;

        const increaseInSek = (i.base_rent * (val / 100)).toFixed(2);

        i.quota = val;
        i.increase_in_sek = increaseInSek;
        i.new_rent = parseFloat(i.base_rent) + parseFloat(increaseInSek);
      });
    } else if (isEditingAll === EDIT_ALL_TYPES.DATES) {
      newIncreases.forEach((i) => {
        if (!i.base_rent) return;

        i.apply_from = editAllDate;
      });

      dispatch(setIndpIncreases({ newIncreases: [] }));
    }

    // timeout for date update to work, otherwise date comps spams updates
    setTimeout(() => {
      dispatch(setIndpIncreases({ newIncreases }));
    }, 100);
    setIsEditingAll(false);
  };

  const handleCreate = () => {
    setLoading(true);
    dispatch(
      performIndpUpdate({
        retroActivePeriods,
        retroActiveCappedForContract,
        successCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Hyreshöjningen sparades",
            })
          );
          push(indpOverviewUrl());
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const premisesHeaders = [
    "",
    "Obj. nr.",
    "Ref. hyra (SEK/mån)",
    "Förändring (SEK/mån)",
    "Förändring (%)",
    "Ny ref. hyra",
    "Genomförs",
  ];

  let errorRowIndexes = React.useMemo(() => {
    let indexes = [];
    increases.forEach((indp, idx) => {
      if (!indp.base_rent) {
        indexes.push(idx);
      }
    });

    return indexes;
  }, [increases]);

  const premisesRows = React.useMemo(() => {
    return increases.map((indp, idx) => {
      const baseDate = moment(indp.apply_from);
      const costsOnDate = indp.cost_set?.filter((c) => {
        if (
          ![
            PRODUCT_CATEGORIES.BASE_RENT,
            PRODUCT_CATEGORIES.RENT_INCREASE,
          ].includes(c.product?.category)
        )
          return false;

        if (c.start_date) {
          const costStartDate = moment(c.start_date);
          if (costStartDate.isAfter(baseDate)) return false;
        }

        if (c.end_date) {
          const costEndDate = moment(c.end_date);
          if (costEndDate.isBefore(baseDate)) return false;
        }

        return true;
      });

      const totalCost = costsOnDate?.reduce(
        (acc, cur) => acc + (cur.value || 0),
        0
      );

      const row = [
        <RemoveButton onClick={() => onSelected(indp)} />,
        indp.premises_id || "-",
        totalCost ? toMoneyString(totalCost, true) : "-",
        <NonConnectedNumberInput
          disabled={!totalCost}
          value={indp.increase_in_sek}
          onUpdate={(val) =>
            handleIncreaseUpdated({ id: indp.id, val, totalCost })
          }
          rowSize
          noTitle
          noMargin
          id={`inc_${indp.id}`}
        />,
        <NonConnectedNumberInput
          disabled={!totalCost}
          value={indp.quota}
          onUpdate={(val) =>
            handleIncreasePercentageUpdated({
              id: indp.id,
              val,
              totalCost,
            })
          }
          rowSize
          noTitle
          noMargin
          id={`inc_proc_${indp.id}`}
        />,
        <NonConnectedRentField
          disabled={!totalCost}
          value={indp.new_rent}
          onUpdate={(val) => handleNewRentUpdated({ id: indp.id, val })}
          rowSize
          noTitle
          hideCalculations
          noMargin
          key={idx}
          area={indp.area || null}
          id={`inc_newrent_${indp.id}`}
        />,
        <NonConnectedDatePicker
          disabled={!totalCost}
          value={indp.apply_from}
          onChange={(val) => {
            handleApplyFromUpdates({ id: indp.id, val });
          }}
          noMargin
          rowSize
          clearable={false}
          id={`inc_apply_${indp.id}`}
        />,
      ];

      return row;
    });
  }, [increases]);

  return (
    <>
      <ConfirmationModal
        isOpen={confirmationOpen}
        title="Bekräfta tillägg av hyreshöjningar"
        renderContent={() => (
          <div>
            Observera att denna åtgärd inte går att ångra. Hyreshöjningar
            påverkar debiteringsrader på avtal som är kopplade till de
            hyreshöjda objekten och därmed även eventuell avisering kopplat till
            avtalen.
          </div>
        )}
        closeFunction={() => setConfirmationOpen(false)}
        acceptCallback={handleCreate}
        acceptTitle="Fortsätt"
      />

      <StandardModal
        isOpen={infoOpen}
        closeFunction={() => setInfoOpen(false)}
        title="Hur fungerar hyreshöjning?"
        withActionBar
        actionBarCancelTitle="Stäng"
      >
        <RentIncreaseGuide />
      </StandardModal>

      {selectorOpen && (
        <Modal
          closeFunction={() => setSelectorOpen(false)}
          title="Välj lokaler"
        >
          <IndustrialPremisesTable
            ignoreLocalStorage
            onRowClicked={onSelected}
            checkRowHighlighted={checkRowHighlighted}
            persistantQuery={{
              realestate_ids: filteredRealEstates,
            }}
            withCosts={false}
          />
        </Modal>
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          {(loading ||
            realEstateMatchingLoading ||
            buildingMatchingLoading) && <OverlaySpinner />}
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Hyreshöjning av lokaler</OverviewTitle>
              <OverviewSubtitle>
                <TextButton
                  iconType="info"
                  title="Hur fungerar hyreshöjning?"
                  clicked={() => setInfoOpen(true)}
                />
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            <PrimaryBtn onClick={() => setSelectorOpen(true)}>
              Lägg till lokaler
            </PrimaryBtn>
          </OverviewTitleWrapper>

          {!isEditingAll && (
            <>
              <TextButton
                title="Ställ in hyreshöjning i SEK för samtliga lokaler i listan"
                iconType="edit"
                iconPlacement="right"
                clicked={() => setIsEditingAll(EDIT_ALL_TYPES.SEK)}
              />

              <TextButton
                title="Ställ in hyreshöjning i % för samtliga lokaler i listan"
                iconType="edit"
                iconPlacement="right"
                clicked={() => setIsEditingAll(EDIT_ALL_TYPES.PERCENTAGE)}
                extraStyle={{ margin: "12px auto 0 0 " }}
              />

              <TextButton
                title="Ställ in datum för höjning för samtliga lokaler i listan"
                iconType="edit"
                iconPlacement="right"
                clicked={() => setIsEditingAll(EDIT_ALL_TYPES.DATES)}
                extraStyle={{ margin: "12px auto 0 0 " }}
              />
            </>
          )}

          {isEditingAll && (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                {isEditingAll === EDIT_ALL_TYPES.SEK && (
                  <NonConnectedRentField
                    id="edit_all_sek"
                    title="Höjning i SEK"
                    value={editAllSek}
                    extraStyles={{ minWidth: 500 }}
                    onUpdate={(val) => handleEditAllSekUpdated(val)}
                  />
                )}
                {isEditingAll === EDIT_ALL_TYPES.PERCENTAGE && (
                  <NonConnectedNumberInput
                    id="edit_all_percentage"
                    label="Höjning i %"
                    value={editAllPercent}
                    extraStyle={{ minWidth: 300 }}
                    onUpdate={(val) => handleEditAllPercentageUpdated(val)}
                  />
                )}
                {isEditingAll === EDIT_ALL_TYPES.DATES && (
                  <NonConnectedDatePicker
                    label="Datum för höjning"
                    value={editAllDate}
                    onChange={(val) => handleEditAllApplyFromDates(val)}
                    clearable={false}
                    id={`inc_apply_all_`}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrimaryButton
                  title="Ställ in för alla lokaler i listan"
                  clicked={handleSetAll}
                />
                <span style={{ margin: "0 8px" }}>eller</span>
                <TextButton
                  title="Avbryt"
                  extraStyle={{ color: "#ea5050" }}
                  clicked={() => setIsEditingAll(false)}
                />
              </div>
            </>
          )}

          <InnerBox className="mt-4">
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>
                  Hantering av retroaktiv hyreshöjning
                </OverviewTitle>
                <OverviewSubtitle>
                  Ställ in hur eventuell retroaktiv hyreshöjning ska hanteras.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            <div>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <LocalTextInputField
                  isNumber
                  step="1"
                  title="Fördela retroaktiv höjning över ... månader"
                  description="Den retroaktiva höjningen kan fördelas ut över x antal månader. en retrokaktiv höjning på 9 000SEK fördelat över 3 månader kommer fördelas till 3 000SEK per månad kommande 3 aviserade månader. Vid kvartals/års-avisering är det fortfarande månader som räknas."
                  value={retroActivePeriods}
                  onChange={(val) => setRetroActivePeriods(val)}
                />
              </div>

              <div className=" mb-6">
                <LocalCheckField
                  title="Begränsa fördelning till sista avtalsdag"
                  description="Om detta är aktivt kommer fördelning max kunna sättas till återstående månader på ett avtal. Exempel: Fördelningen är inställd på 3 månader, men den nuvarande hyresgästen flyttar ut om 2 månader. Då sätts fördelningen till 2 månader för just det objektet."
                  value={retroActiveCappedForContract}
                  onChange={(val) => setRetroActiveCappedForContract(val)}
                />
              </div>
            </div>
          </InnerBox>

          {errorRowIndexes?.length > 0 && (
            <InfoBox
              boxTheme="warning"
              title={`${errorRowIndexes.length} ${
                errorRowIndexes?.length === 1 ? "lokal" : "lokaler"
              } saknar referenshyra`}
              renderButton={{
                title: "Rensa alla rödmarkerade rader",
                iconType: "close",
                iconPlacement: "right",
                clicked: () => clearNonValidPremises(),
              }}
              text="För att kunna genomföra en hyreshöjning krävs det att lägenheten har en referenshyra att utgå igrån. Ta bort de rödmarkerade raderna och åtgärda detta inne på respektive lägenhet"
            />
          )}

          <PremisesTable
            extraContainerStyles={{ marginTop: 20 }}
            headers={premisesHeaders}
            rows={premisesRows}
            loading={false}
            errorRowIndexes={errorRowIndexes}
            keyMapping={increases?.map((i) => i.id)}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <PrimaryButton
              disabled={!canSave}
              title={`Registrera hyreshöjningar för ${increases?.length} lokaler`}
              clicked={() => setConfirmationOpen(true)}
            />
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
