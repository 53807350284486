import { axiosInstance } from "../../base/store/axios";

async function markNotificationsAsRead(readList) {
  const { data } = await axiosInstance.post(`events/notifications/mark_read/`, {
    notification_ids: readList,
  });

  return data;
}

export { markNotificationsAsRead };
