import * as React from "react";
import Select from "react-select";
import { useAsyncDebounce } from "react-table";
import NonConnectedTextInput from "../../../../Forms/Base/Old/NonConnected/NonConnectedTextInput";

const CHOICES = [
  { value: "year", display: "År" },
  { value: "month", display: "Månader" },
  { value: "week", display: "Veckor" },
  { value: "day", display: "Dagar" },
];

export default function SimpleIntervalFilter({ column, flatRows }) {
  const { filterValue, setFilter } = column;

  const toFilter = (value) => {
    const type = value[1];
    let val = value[0];

    if (!val || isNaN(parseInt(val))) {
      return undefined;
    }
    if (parseInt(val) < 10) {
      val = `0${val}`;
    }
    switch (type) {
      case "month":
        return `00 ${val} 00 00`;

      case "year":
        return `${val} 00 00 00`;

      case "week":
        return `00 00 ${val} 00`;

      case "day":
        return `00 00 00 ${val}`;

      default:
        return undefined;
    }
  };

  const fromFilter = (value) => {
    if (!value) {
      return ["", "month"];
    }

    const split = value.split(" ");

    let years = parseInt(split[0]);
    let months = parseInt(split[1]);
    let weeks = parseInt(split[2]);
    let days = parseInt(split[3]);

    if (years !== 0 && years >= 0) {
      return [`${years}`, "year"];
    } else if (months !== 0 && months >= 0) {
      return [`${months}`, "month"];
    } else if (weeks !== 0 && weeks >= 0) {
      return [`${weeks}`, "week"];
    } else if (days !== 0 && days >= 0) {
      return [`${days}`, "day"];
    }

    return ["", "month"];
  };

  const count = flatRows?.length || 0;
  const [value, setValue] = React.useState(fromFilter(filterValue));

  const onChange = useAsyncDebounce((val) => {
    setFilter(toFilter(val));
  }, 200);

  return (
    <div style={{ display: "flex", width: "240px" }}>
      <NonConnectedTextInput
        extraStyles={{ maxWidth: "48%", marginRight: "2%" }}
        noMargin
        rowSize
        value={value[0] || ""}
        onChange={(val) => {
          setValue([val || "", value[1]]);
          onChange([val || "", value[1]]);
        }}
        placeholder={`Antal`}
      />

      <div style={{ flex: 1 }}>
        <Select
          id={column.id}
          menuPortalTarget={document.querySelector("body")}
          placeholder="Välj..."
          onChange={(selected) => {
            setValue([value[0], selected?.value || "month"]);
            onChange([value[0], selected?.value || "month"]);
          }}
          defaultValue={CHOICES[1]}
          value={CHOICES.find((c) => c.value === filterValue)}
          options={CHOICES}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.display}
        />
      </div>
      {/* <select
        value={value[1] || "months"}
        onChange={(e) => {
          setValue([value[0], e.target.value || "month"]);
          onChange([value[0], e.target.value || "month"]);
        }}
      >
        <option value="year">År</option>
        <option value="month">Månader</option>
        <option value="week">Veckor</option>
        <option value="day">Dagar</option>
      </select> */}
    </div>
  );
}
