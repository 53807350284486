export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_NOTE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_NOTE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_NOTE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_NOTE",
  DESTROY_FORM: "DESTROY_FORM_NOTE",
  SET_FORM_ERROR: "SET_FORM_ERROR_NOTE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_NOTE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_NOTE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_NOTE",
  RESET_STATE: "RESET_STATE_NOTE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_NOTE",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_NOTE",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_NOTE",
  CLEAR_FETCHED: "CLEAR_FETCHED_NOTE",

  LIST_URL: "/general/note/list/",
  POST_URL: "/general/note/",
  PATCH_URL: "/general/note/",
  GET_URL: "/general/note/",
  REVERSE_POST_URL: "/general/note/reverse_adding/",

  STORE_NAME: "notes",
};
