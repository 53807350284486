import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { useAllPermissionCheck } from "../../../store/base";
import SettingModalForm from "../../../components/Forms/RoundingErrandSetting/ChapterForm/ModalForm";

import SettingsTable from "../../../components/Tables/RoundingErrandSettings/FullTable";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function Settings() {
  const { filteredRealEstates } = useActiveFilters();

  const [createSettingOpen, setCreateSettingOpen] = React.useState(false);

  const canAdd = useAllPermissionCheck([
    "add_can_roundingerrand",
    "allow_errand_roundings",
  ]);

  const persistantQuerySettings = {
    realestate_ids: filteredRealEstates,
    kind__isnull: true,
  };

  return (
    <>
      {canAdd && createSettingOpen && (
        <SettingModalForm
          method="POST"
          onCheckout={() => setCreateSettingOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper className="mb-0">
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Ronderingsscheman</OverviewTitle>
              <OverviewSubtitle className="max-w-[90%]">
                Ronderingsscheman bestämmer vad för typ av rondering som ska
                genereras samt i vilket intervall. De ronderingar som genereras
                under ett schema följer de inställningarna som är inställda på
                schemat.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            {canAdd && (
              <PrimaryBtn onClick={() => setCreateSettingOpen(true)}>
                Lägg till ronderingsschema
              </PrimaryBtn>
            )}
          </OverviewTitleWrapper>
          <SettingsTable persistantQuery={persistantQuerySettings} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
