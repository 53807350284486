import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { LinkedObject } from "../../Displays";
import { lightOrDark } from "../../DigitalDoc/utils";
import * as SC from "./styles";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { TextButton } from "../../Forms/Base/Buttons";

export default function Lead({
  lead,
  togetherWith,
  index,
  onClick,
  onTogetherWithClick,
  pipeAttributeChoices,
}) {
  const leadName = lead?.corporate_name
    ? lead?.corporate_name?.split(" ").join("+")
    : `${lead?.first_name || ""}+${lead?.last_name || ""}`;
  const leadColor = strToColor(leadName);
  const leadTextColor = lightOrDark(leadColor) === "light" ? "000" : "fff";

  let pinnedFields = [];
  if (pipeAttributeChoices?.length) {
    pipeAttributeChoices.forEach((p) => {
      if (!p.pinn_on_card) {
        return;
      }

      const connectedValue = lead?.pipeattributevalue_set?.find(
        (pv) => pv.pipe_attribute?.id === p.id
      );

      const value =
        p?.kind === 0
          ? connectedValue?.float_value
          : p?.kind === 1
          ? connectedValue?.char_value
          : connectedValue?.float_value != null &&
            connectedValue?.max_float_value != null
          ? `${connectedValue?.float_value} - ${connectedValue?.max_float_value}`
          : null;

      if (value == null) {
        return;
      }

      pinnedFields.push({
        title: p.title,
        value: value,
      });
    });
  }

  return (
    <Draggable draggableId={`${lead?.id}`} index={index}>
      {(provided, snapshot) => {
        return (
          <SC.LeadBox
            onClick={onClick}
            style={{ maxWidth: "100%" }}
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {pinnedFields.map((field) => {
              return (
                <SC.CustomFieldPill>
                  {field.title}: {field.value}
                </SC.CustomFieldPill>
              );
            })}

            <SC.LeadHeader>
              <SC.LeadImage
                name={leadName}
                tetColor={leadTextColor}
                color={leadColor.replace("#", "")}
              />

              <SC.LeadDataWrapper>
                <SC.LeadName>
                  {lead?.corporate_name || lead?.str_representation}
                </SC.LeadName>
                {lead?.corporate_name && (
                  <SC.LeadName
                    style={{
                      fontSize: 12,
                      lineHeight: "100%",
                      marginBottom: 0,
                    }}
                  >
                    {lead?.first_name || lead?.last_name
                      ? `${lead?.first_name || ""} ${lead?.last_name || ""}`
                      : "Kontakt saknas"}
                  </SC.LeadName>
                )}

                {togetherWith && (
                  <SC.LeadName
                    style={{
                      fontSize: 12,
                      lineHeight: "100%",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextButton
                      iconType="add"
                      extraStyle={{ fontSize: 12 }}
                      title={togetherWith?.str_representation}
                      clicked={onTogetherWithClick}
                    />
                  </SC.LeadName>
                )}
                <div style={{ margin: 0, lineHeight: "100%" }}>
                  {lead?.apartment?.id && (
                    <LinkedObject
                      small
                      obj={lead.apartment}
                      urlMethod={apartmentDetailUrl}
                    />
                  )}
                  {lead?.industrial_premises?.id && (
                    <LinkedObject
                      small
                      obj={lead.industrial_premises}
                      urlMethod={indpDetailUrl}
                    />
                  )}
                  {lead?.parking_spot?.id && (
                    <LinkedObject
                      small
                      obj={lead?.parking_spot}
                      urlMethod={parkingDetailUrl}
                    />
                  )}
                </div>
              </SC.LeadDataWrapper>
            </SC.LeadHeader>
            <SC.LeadFooter>
              {lead?.confirmed ? (
                <SC.LeadConfirmedWrapper>
                  <SC.LeadConfirmed />{" "}
                  <SC.LeadName style={{ fontSize: 12 }}>Bekräftad</SC.LeadName>
                </SC.LeadConfirmedWrapper>
              ) : lead?.denied ? (
                <SC.LeadConfirmedWrapper>
                  <SC.LeadConfirmed isDenied />{" "}
                  <SC.LeadName style={{ fontSize: 12 }}>Nekad</SC.LeadName>
                </SC.LeadConfirmedWrapper>
              ) : (
                <div></div>
              )}
              <SC.LeadDateWrapper>
                <SC.LeadStageInfo>I detta steg sedan:</SC.LeadStageInfo>
                {lead?.last_stage_change}
              </SC.LeadDateWrapper>
            </SC.LeadFooter>
          </SC.LeadBox>
        );
      }}
    </Draggable>
  );
}

export function strToColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}
