import styled, { css } from "styled-components";

export const InsightsWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(p) => p.minWidth}px;
  width: ${(p) => p.containerWidth}px;
  height: ${(p) => p.containerHeight}px;
  border-radius: 5px;
  background-color: white;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
`;
export const NoDataWidget = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-image: url(${(p) => p.img});
  background-repeat: no-repeat;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
`;

export const InsightsChartWrapper = styled.div`
  padding: 5px 15px 0 15px;
  height: 100%;
`;

export const InsightsEditButton = styled.link`
  position: relative;
  -webkit-appearance: none;
  white-space: nowrap;
  border: none;
  box-sizing: border-box;
  color: gray;
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: none;
  text-align: center;

  &:hover {
    color: black;
  }
  &:before {
    content: "a"; // use non-empty string
    color: rgba(0, 0, 0, 0); // make it invisible
    font-size: 50px;
    top: calc(50% - 9px);
    height: 40px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${(p) => p.icon});
  }
`;

export const InsightsSettingsContainer = styled.div`
  width: 100%;
  align-self: flex-end;
  margin-bottom: 5px;
  padding-bottom: 3px;
  border: ${(p) => p.theme.borders.standard};
  box-sizing: content-box;
`;

export const InternalComponentContainer = styled.div`
  // width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;

export const InternalComponentFlexContainer = styled.div`
  display: flex;
  flex-direction: ${(p) => p.flexDirection};
  justify-content: ${(p) => p.justifyContent};
  height: ${(p) => p.fullHeight};
  align-items: ${(p) => p.alignItems};
`;

export const WidgetTableContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const WidgetTable = styled.table`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 5px;
  border-collapse: collapse;
`;
export const WidgetTableHeader = styled.th`
  vertical-align: middle;
  --tw-text-opacity: 1;
  text-transform: uppercase;
  font-size: 0.60rem;
  line-height: 1rem
`;

export const WidgetTableRow = styled.tr`
  align: center;
  vertical-align: middle;
  background-color: ${(p) => (p.isEven ? "rgb(248 250 252 / var(--tw-bg-opacity))" : "white")};
`;

export const WidgetTableData = styled.td`
  font-size: 10px;
  text-align: ${(p) => (p.isNumber ? "right": "")};
  vertical-align: middle;
  padding-left: ${(p) => (p.isFirst ? "10px" : "5px")};
  padding-right: ${(p) => (p.isLast ? "10px": "")};

  @media only screen and (min-width: 2500px) {
    font-size: 18px !important;
  }
`;

export const WidgetDataText = styled.p`
  font-weight: 800;
  font-size: 18px;
  font-size: 0.9vw;
  margin-left: ${(p) => p.marginLeft};

  @media only screen and (min-width: 2500px) {
    font-size: 1.2vw;
  }
`;

export const WidgetTextSupplement = styled.p`
  font-weight: 300;
  font-size: 0.7vw;
  color: #ababab;
  align-self: ${(p) => p.align};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "0")}px;
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "0")}px;
`;
export const WidgetTextSeparator = styled.div`
  width: 90%;
  padding: 0;
  margin-top: 1px;
  margin-bottom: 3px;
  border-bottom: 1px solid #efefef;
`;

export const InternalComponentIconBubble = styled.div`
  border-radius: 30px;
  width: 50px;
  height: 50px;
  background-color: #${(p) => p.bgnColor};
  position: relative;
  ${(p) =>
    p.icon &&
    css`
      &:before {
        position: absolute;
        content: "";
        height: 35px;
        width: 35px;
        background-position-y: 2px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(${p.icon});
        left: 7px;
        top: 5px;
      }
    `}
`;
