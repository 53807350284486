import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import PipeModalForm from "../../components/Forms/Pipe/ChapterForm/ModalForm";
import Pipe from "../../components/Market/Pipe/Pipe";
import AdPipe from "../../components/Market/Pipe/AdPipe";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useAllPermissionCheck } from "../../store/base";
import { usePipe, constants } from "../../store/pipes";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useQuery from "../../components/utils/useQuery";

import { HomeQL } from "../../components/Market/Pipe/styles";
import { DetailPageBox } from "../../components/sharedStyles";
import { useRealEstate } from "../../store/realEstates";
import NewProduction from "../../components/Market/Pipe/NewProduction";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import { publishNewProductionSettingsUrl } from "../../store/marketApartmentAds";

export default function PipeDetail() {
  const { pipeId } = useParams();
  const [pipe] = usePipe(pipeId);
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [realEstate] = useRealEstate(pipe?.realestate?.id);

  const query = useQuery();

  // auto open lead
  const open = query.get("open");
  usePermissionRedirect(["view_can_lead", "allow_market"]);

  const canEdit = useAllPermissionCheck([
    "change_can_lead",
    "change_can_market",
  ]);
  const canDelete = useAllPermissionCheck([
    "delete_can_lead",
    "delete_can_market",
  ]);

  const { url, path } = useRouteMatch();
  const { push } = useHistory();
  const SUB_PAGES = [
    {
      path: "/connected",
      label: "Annonser",
      component: AdPipe,
    },
  ];

  if (!pipe?.is_homeq_pipe) {
    SUB_PAGES.unshift({
      path: "/pipe",
      label: "Leads",
      component: Pipe,
    });
  }

  if (pipe?.is_homeq_pipe && pipe?.homeq_project_id) {
    SUB_PAGES.push({
      path: "/new-production-objects",
      label: "Nyproduktionsobjekt",
      component: NewProduction,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const renderLogo = () => {
    if (!pipe?.is_homeq_pipe) return null;

    return (
      <DetailPageBox
        style={{
          borderRadius: 5,
          padding: 0,
          width: "55px",
          height: "45px",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 8,
        }}
      >
        <HomeQL />
      </DetailPageBox>
    );
  };

  const handleActions = [];

  if (canEdit) {
    handleActions.push({
      name: "Redigera",
      onClick: () => setEditOpen(true),
    });
  }
  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  const renderActions = () => {
    if (!pipe?.homeq_project_id) {
      return null;
    }

    return (
      <PrimaryButton
        title="Publicera annonser"
        clicked={() =>
          push(
            publishNewProductionSettingsUrl({
              realEstateId: pipe?.realestate?.id,
              pipeId: pipe?.id,
            })
          )
        }
      />
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        instance={pipe}
        constants={constants}
        closeFunction={() => setDeleteOpen(false)}
      />
      <PipeModalForm
        method="PATCH"
        id={pipeId}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailPageHeaderMenu
        title={`Projekt ${pipe?.name || "laddar..."}`}
        subtitle={
          realEstate
            ? `Gäller för ${
                pipe?.homeq_project_id ? "nyproduktion" : "objekt"
              } i ${realEstate?.str_representation}`
            : ""
        }
        goBackFunction={() => push("/market/pipes")}
        {...{
          subPages: subPageLinks,
          handleActions,
          renderLogo,
          renderActions,
        }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect
          exact
          to={`${url}${SUB_PAGES[0].path}${open ? `?open=${open}` : ""}`}
          path={`${path}`}
        />
      </Switch>
    </>
  );
}
