export const detailUrl = ({ id, openLead }) => {
  return `/market/pipes/detail/${id}${openLead ? `?open=${openLead}` : ""}`;
};
export const overviewUrl = () => {
  return "/market/pipes";
};

export const prepareForContractUrl = () => {
  return "/market/pipes/contract/prepare-leads-for-contract/";
};
export const contractProposalUrl = ({ type }) => {
  return `/market/pipes/contract/proposals/${type}`;
};

export const costProposalUrl = ({ type }) => {
  return `/market/pipes/contract/cost-proposals/${type}`;
};

export const pickTemplateUrl = ({ type }) => {
  return `/market/pipes/contract/pick-template/${type}`;
};

export const approveDocumentsUrl = ({ type }) => {
  return `/market/pipes/contract/approve-documents/${type}`;
};
