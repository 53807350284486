import * as React from "react";
import PopupHandle from "../../../Details/OverviewInfo/PopupHandle/PopupHandle";
import * as SC from "./styles";

export default ({ disabled, extraStyle, leftAligned, actions = [], light }) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef();

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  return (
    <SC.MenuButton
      ref={ref}
      style={{ ...extraStyle, display: "flex", alignItems: "center" }}
      disabled={disabled}
      onClick={handleClick}
      {...{ light }}
    >
      <PopupHandle
        {...{
          actions,
          menuButtonLeftAligned: leftAligned,
          open,
          closeFunction: () => setOpen(false),
        }}
      />
    </SC.MenuButton>
  );
};
