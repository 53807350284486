import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  detailUrl,
  useVancyBookingSettingForm,
} from "../../../../store/vacancyBookingSettings";
import { updateActiveFormInstance } from "../../../../store/base";

export default function VacancyBookingSettingModalForm({
  method,
  id,
  onCheckout,
  isOpen,
  instance = {},
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useVancyBookingSettingForm(method, id));

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={storeName}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter({ key, method })}
      submitCallback={onSubmit}
      title={method === "POST" ? "Skapa inställning" : "Spara uppdatering"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
