import constants from "./constants";
import { cloneDeep } from "lodash";

export default function toasts(
  state = {
    toastList: [],
    lockedToasts: [],
  },
  action
) {
  const { payload, type } = action;

  switch (type) {
    case constants.ADD_TOAST:
      return handleAddToast(state, payload);

    case constants.OPEN_TOAST:
      return handleOpenToast(state, payload);

    case constants.CLOSE_TOAST:
      return handleCloseToast(state, payload);

    case constants.REMOVE_TOAST:
      return handleRemoveToast(state, payload);

    case constants.LOCK_TOAST: {
      return handleLockToast(state, payload);
    }

    case constants.UNLOCK_TOAST: {
      return handleUnlockToast(state, payload);
    }

    default:
      return state;
  }
}

const handleAddToast = (state, payload) => {
  const stateClone = cloneDeep(state);

  stateClone.toastList.push(payload);

  return stateClone;
};

const handleOpenToast = (state, payload) => {
  const stateClone = cloneDeep(state);

  const cur = stateClone.toastList.find((t) => t.id === payload.id);
  if (cur) {
    cur.open = true;
  }

  return stateClone;
};

const handleCloseToast = (state, payload) => {
  const stateClone = cloneDeep(state);

  const cur = stateClone.toastList.find((t) => t.id === payload.id);
  if (cur) {
    cur.open = false;
  }

  return stateClone;
};

const handleRemoveToast = (state, payload) => {
  const stateClone = cloneDeep(state);

  let list = stateClone.toastList;
  stateClone.toastList = list?.filter((toast) => toast.id !== payload.id);

  return stateClone;
};

const handleLockToast = (state, payload) => {
  const stateClone = cloneDeep(state);
  stateClone.lockedToasts.push(payload.id);
  return stateClone;
};

const handleUnlockToast = (state, payload) => {
  const stateClone = cloneDeep(state);
  stateClone.lockedToasts = stateClone.lockedToasts.filter(
    (i) => i !== payload.id
  );
  return stateClone;
};
