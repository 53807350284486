import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../../store/parkingSpots";

import moment from "moment";
import {
  OPERATOR_TEMPLATES,
  getExpandedContentForContracts,
} from "src/components/Lists/Base/FinalTable/utils";
import { BADGE_TYPES } from "src/components/Badge/Badge";

export default function ParkingSpotsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  withCosts,
}) {
  const sDate = moment({ year: moment().year(), month: 0, day: 1 });
  const eDate = moment({ year: moment().year(), month: 11, day: 31 });
  const [startDate, setStartDate] = React.useState(sDate.format("YYYY-MM"));
  const [endDate, setEndDate] = React.useState(eDate.format("YYYY-MM"));

  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {
    Uthyrt: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [1],
      },
    },
    Tillsvidare: {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Vakant: {
      color: BADGE_TYPES.CYAN,
      querySet: {
        state__in: [9],
      },
    },
    Okänt: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [8, 10],
      },
    },
  };
  const filters = {
    Platsnr: {
      queryKey: "title",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    // Hyresgäst: {
    //   queryKey: "tenant_name",
    //   type: "text",
    //   operators: OPERATOR_TEMPLATES.SEARCH,
    // },
    Hyresvärd: {
      queryKey: "landlord",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Fastighet: {
      queryKey: "realestate",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },

    // Kategori: {
    //   queryKey: "specification_category",
    //   type: "select",
    //   operators: OPERATOR_TEMPLATES.SELECT,
    //   choices: [
    //     {
    //       v: "garage",
    //       d: "Garage",
    //     },
    //     {
    //       v: "indoor",
    //       d: "Inomhus",
    //     },
    //     {
    //       v: "outdoor",
    //       d: "Utomhus",
    //     },
    //     {
    //       v: "house",
    //       d: "Parkeringshus",
    //     },
    //     {
    //       v: "other",
    //       d: "Övrigt",
    //     },
    //   ],
    // },
  };

  return (
    <BaseTable
      tableId={tableId || "parkingspots_full_table"}
      title={"Fordonsplatser"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      isRowExpandable={withCosts}
      getContentForExpandedRow={(row) =>
        getExpandedContentForContracts(row, {
          startDate,
          endDate,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
