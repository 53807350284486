export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ERRAND_ARTICLES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ERRAND_ARTICLES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ERRAND_ARTICLES",
  DESTROY_FORM: "DESTROY_FORM_ERRAND_ARTICLES",
  SET_FORM_ERROR: "SET_FORM_ERROR_ERRAND_ARTICLES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ERRAND_ARTICLES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ERRAND_ARTICLES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ERRAND_ARTICLES",
  RESET_STATE: "RESET_STATE_ERRAND_ARTICLES",
  CLEAR_FETCHED: "CLEAR_FETCHED_ERRAND_ARTICLES",
  REMOVE_OBJECT: "REMOVE_OBJECT_ERRAND_ARTICLES",
  SET_PUBLIC_ARTICLES: "SET_PUBLIC_ERRAND_ARTICLES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ERRAND_ARTICLES",

  LIST_URL: "/errands/costs/costarticle/list/",
  POST_URL: "/errands/costs/costarticle/",
  PATCH_URL: "/errands/costs/costarticle/",
  GET_URL: "/errands/costs/costarticle/",

  STORE_NAME: "errandArticles",
};
