import * as React from "react";
import { useHistory } from "react-router-dom";
import ComponentForm from "../../Forms/Component/ChapterForm/ModalForm";
import {
  getCurrentProductTypeUsage,
  getInspectionErrandChecklistIdForComponent,
} from "../utils";
import { detailUrl } from "../../../store/errandComponents";
import * as SC from "./styles";
import theme from "../../../theme";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { InnerBox } from "../../sharedStyles";
import {
  PopupButton,
  PrimaryButton,
  TextButton,
} from "../../Forms/Base/Buttons";
import DeleteModal from "../../Forms/Delete/DeleteModal";
import { constants as roomConstants } from "../../../store/rooms";
import {
  FiThumbsUp,
  FiThumbsDown,
  FiList,
  FiX,
  FiMonitor,
  FiBox,
  FiChevronUp,
  FiHexagon,
} from "react-icons/fi";
import * as MdIcon from "react-icons/md";
import * as FaIcon from "react-icons/fa";
import * as GiIcon from "react-icons/gi";
import moment from "moment";

// takes rooms in the format described in PremisesBase.js
export default function RoomOverview({
  rooms = {},
  handleEditRoom,
  selectOnly,
  showRatings,
  onSelect,
  ratings,
  allOpen,
  isNonRoomType,
  isExecute,
  onRate,
  onChecklistRate,
  checklists,
  clearRating,
  showArchived,
  contentKey,
  contentId,
}) {
  const { push } = useHistory();
  const [openRooms, setOpenRooms] = React.useState([]);
  const [deleteRoom, setDeleteRoom] = React.useState(null);
  const [addComponentOpen, setAddComponentOpen] = React.useState(false);
  const [archivedOpen, setArchivedOpen] = React.useState([]);

  React.useEffect(() => {
    if (allOpen || isNonRoomType) {
      let newOpenRooms = [];

      Object.keys(rooms).forEach((roomId) => newOpenRooms.push(roomId));

      setOpenRooms(newOpenRooms);
    }
  }, [allOpen, rooms, isNonRoomType]);

  const toggleOpen = (roomId) => {
    let openCopy = [...openRooms];
    if (openRooms.includes(roomId)) {
      openCopy = openCopy.filter((r) => r !== roomId);
    } else {
      openCopy.push(roomId);
    }

    setOpenRooms(openCopy);
  };

  const toggleArchivedOpen = (roomId) => {
    let openCopy = [...archivedOpen];
    if (archivedOpen.includes(roomId)) {
      openCopy = openCopy.filter((r) => r !== roomId);
    } else {
      openCopy.push(roomId);
    }

    setArchivedOpen(openCopy);
  };

  const goToComponent = (c) => {
    push(detailUrl({ id: c.id }));
  };

  return (
    <>
      {!selectOnly && !isExecute && (
        <ComponentForm
          method="POST"
          instance={{
            [isNonRoomType ? [contentKey] : "room"]: {
              id: isNonRoomType ? contentId : addComponentOpen, // contentId is only relevant for non rooom type premises, i.e. realestate, parking and building
            },
          }}
          isOpen={!!addComponentOpen}
          onCheckout={() => setAddComponentOpen(false)}
        />
      )}

      {!selectOnly && !isExecute && (
        <DeleteModal
          isOpen={!!deleteRoom}
          instance={deleteRoom}
          constants={roomConstants}
          closeFunction={() => setDeleteRoom(null)}
        />
      )}

      <SC.Wrapper>
        {isNonRoomType &&
          Object.keys(rooms || {})?.length === 0 &&
          !selectOnly && (
            <InnerBox
              style={{
                minHeight: 200,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PrimaryButton
                title="Lägg till komponent"
                clicked={() => setAddComponentOpen(true)}
              />
            </InnerBox>
          )}

        {Object.keys(rooms).map((roomId) => {
          const room = rooms[roomId];
          const isOpen = openRooms.includes(roomId);

          const cIds = room?.components?.map((c) => c.id);
          const roomRatings = ratings?.filter((r) => {
            return cIds.includes(r.component_placement?.id);
          });

          const okRatings = roomRatings?.filter((r) => r.is_ok === true);
          const nonOkRatings = roomRatings?.filter((r) => r.is_ok === false);

          return (
            <>
              <SC.RoomOverviewHeader onClick={() => toggleOpen(roomId)}>
                <SC.RoomTitle>
                  {isNonRoomType ? (
                    <div>{room?.str_representation}</div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 4,
                      }}
                    >
                      <div
                        style={{
                          marginRight: 6,
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#fff",
                          alignItems: "center",
                          border: "thin solid rgba(241,241,241,.8)",
                          padding: 4,
                          borderRadius: 5,
                          boxShadow: "0 2px 6px 0 rgba(0,0,0,.22)",
                        }}
                      >
                        {renderRoomIconBasedOnCategory(room.kind)}
                      </div>
                      <div>
                        {room?.room_id} ({room?.kind_display})
                      </div>
                    </div>
                  )}
                  {!roomRatings?.length > 0 && (
                    <div style={{ fontSize: ".8em" }}>
                      {
                        room?.components?.filter(
                          (c) => showArchived || !c.archived
                        ).length
                      }{" "}
                      komponent
                      {room?.components?.filter(
                        (c) => showArchived || !c.archived
                      )?.length > 1
                        ? "er"
                        : ""}
                    </div>
                  )}

                  {okRatings?.length > 0 && (
                    <div
                      style={{
                        fontSize: ".8em",
                        color: theme.colors.greenDark,
                      }}
                    >
                      {okRatings?.length} komponent
                      {okRatings?.length > 1 ? "er" : ""} godkänd
                      {okRatings?.length > 1 ? "a" : ""}
                    </div>
                  )}
                  {nonOkRatings?.length > 0 && (
                    <div style={{ fontSize: ".8em", color: theme.colors.red }}>
                      {nonOkRatings?.length} komponent
                      {nonOkRatings?.length > 1 ? "er" : ""} ej godkänd
                      {nonOkRatings?.length > 1 ? "a" : ""}
                    </div>
                  )}
                </SC.RoomTitle>

                {!isNonRoomType && (
                  <div style={{ marginLeft: "auto" }}>
                    {isOpen ? <SC.CloseButton /> : <SC.OpenButton />}
                  </div>
                )}
              </SC.RoomOverviewHeader>

              {isOpen && (
                <SC.ComponentArea>
                  {!selectOnly && !isExecute && (
                    <SC.RoomMenu>
                      <div>
                        <div
                          style={{
                            fontSize: theme.fontSizes.headerXSmall,
                            fontWeight: theme.fontWeights.headerSmall,
                          }}
                        >
                          {isNonRoomType ? "Objektet" : "Rummet"} innehåller{" "}
                          {
                            room?.components?.filter(
                              (c) => showArchived || !c.archived
                            ).length
                          }{" "}
                          komponenter
                        </div>

                        <TextButton
                          title={`Lägg till komponent i ${
                            isNonRoomType ? "objekt" : "rum"
                          }`}
                          clicked={() =>
                            setAddComponentOpen(
                              isNonRoomType ? roomId.split("-")[1] : roomId
                            )
                          }
                          iconType="add"
                          iconPlacement="right"
                        />
                      </div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        {!isNonRoomType && (
                          <PopupButton
                            leftAligned
                            title="Hantera rum"
                            actions={[
                              {
                                name: "Redigera",
                                onClick: () => handleEditRoom(room),
                              },
                              {
                                name: "Radera rum",
                                onClick: () => setDeleteRoom(room),
                                hidden: room.components?.length > 0,
                              },
                            ]}
                          />
                        )}
                      </div>
                    </SC.RoomMenu>
                  )}

                  <InnerBox
                    style={{
                      margin:
                        selectOnly || isExecute
                          ? " 12px 12px 24px 12px"
                          : "0 12px 24px 12px",
                      width: "calc(100% - 24px)",
                    }}
                  >
                    <SC.ComponentContainer>
                      {room.components.length === 0 && (
                        <InnerBox
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: 200,
                          }}
                        >
                          Detta rum innehåller inga komponenter
                        </InnerBox>
                      )}
                      {room.components
                        ?.filter((c) => showArchived || !c.archived)
                        ?.map((c, idx) => {
                          const componentProductTypeUsage =
                            getCurrentProductTypeUsage(c);
                          const productType =
                            componentProductTypeUsage?.product_type;
                          const componentType = productType?.component_type;

                          // For rating display
                          const connectedRating = ratings?.find(
                            (r) => r.component_placement?.id === c.id
                          );

                          const componentChecklistId =
                            getInspectionErrandChecklistIdForComponent(c);

                          const hasChecklist = checklists?.find(
                            (checklist) =>
                              checklist?.id === componentChecklistId
                          );

                          const warrantyEndDate = moment(
                            componentProductTypeUsage?.warranty_end
                          );

                          return (
                            <SC.Component
                              isExecute={isExecute}
                              isFirst={idx === 0}
                              isLast={idx === room.components.length - 1}
                              onClick={
                                isExecute
                                  ? null
                                  : onSelect
                                  ? () => onSelect(c)
                                  : () => goToComponent(c)
                              }
                            >
                              <SC.RoomTitle style={{ maxWidth: "70%" }}>
                                <div style={{ fontWeight: 500 }}>
                                  {componentType
                                    ? componentType?.str_representation
                                    : "Okänd"}
                                </div>
                                <div>
                                  {productType
                                    ? productType?.str_representation
                                    : "Komponentmodell saknas på denna komponent"}
                                </div>
                                {productType && warrantyEndDate?.isValid() && (
                                  <div>
                                    Garanti till:{" "}
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        color: moment().isSameOrAfter(
                                          warrantyEndDate
                                        )
                                          ? theme.colors.red
                                          : moment()
                                              .add({ month: 2 })
                                              .isSameOrAfter(warrantyEndDate)
                                          ? theme.colors.yellow
                                          : theme.colors.green,
                                      }}
                                    >
                                      {componentProductTypeUsage?.warranty_end}
                                    </span>
                                  </div>
                                )}

                                {showRatings && connectedRating && (
                                  <div style={{ marginTop: 6 }}>
                                    <div>
                                      <span style={{ fontWeight: 500 }}>
                                        Kommentar:
                                      </span>{" "}
                                      {connectedRating?.note ||
                                        "Ingen kommentar lämnades"}
                                    </div>

                                    {connectedRating?.file_temp_data && (
                                      <div style={{ fontWeight: 500 }}>
                                        Fil uppladdad
                                      </div>
                                    )}

                                    {connectedRating?.file?.get && (
                                      <SC.RatingFile
                                        url={connectedRating?.file?.get}
                                        onClick={
                                          connectedRating?.file?.get
                                            ? () =>
                                                window
                                                  .open(
                                                    connectedRating?.file?.get,
                                                    "_blank"
                                                  )
                                                  .focus()
                                            : () => {}
                                        }
                                      />
                                    )}

                                    {isExecute && (
                                      <TextButton
                                        title="Uppdatera betyg"
                                        extraStyle={{ marginTop: 6 }}
                                        clicked={() => onRate({ component: c })}
                                        iconType="edit"
                                        iconPlacement="right"
                                      />
                                    )}
                                  </div>
                                )}
                              </SC.RoomTitle>

                              <div
                                style={{
                                  marginLeft: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {connectedRating && !isExecute && (
                                  <>
                                    <StatusLabel
                                      style={{ marginRight: 6 }}
                                      state={
                                        connectedRating?.is_ok
                                          ? 0
                                          : connectedRating?.is_ok === false
                                          ? 6
                                          : 9
                                      }
                                    >
                                      {connectedRating?.is_ok
                                        ? "Godkänd"
                                        : connectedRating?.is_ok === false
                                        ? "Ej godkänd"
                                        : "Inget betyg lämnades"}
                                    </StatusLabel>
                                  </>
                                )}
                                {!isExecute && !selectOnly && (
                                  <SC.ArrowButton />
                                )}

                                {isExecute && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {hasChecklist ? (
                                      <>
                                        <SC.ExecuteIconContainer
                                          onClick={
                                            connectedRating
                                              ? () => {}
                                              : () =>
                                                  onChecklistRate({
                                                    component: c,
                                                    checklistId:
                                                      hasChecklist.id,
                                                  })
                                          }
                                          style={{
                                            backgroundColor:
                                              connectedRating?.is_ok === true
                                                ? theme.colors.greenLight
                                                : connectedRating?.is_ok ===
                                                  false
                                                ? theme.colors.redLight
                                                : "#fff",
                                          }}
                                        >
                                          <FiList
                                            color={
                                              connectedRating?.is_ok === true
                                                ? theme.colors.green
                                                : connectedRating?.is_ok ===
                                                  false
                                                ? theme.colors.red
                                                : theme.colors.primaryBlue
                                            }
                                            size={theme.fontSizes.headerSmall}
                                          />
                                        </SC.ExecuteIconContainer>

                                        {connectedRating && (
                                          <SC.ExecuteIconContainer
                                            onClick={() =>
                                              clearRating({
                                                component: c,
                                              })
                                            }
                                            style={{
                                              backgroundColor: "#fff",
                                              height: 32,
                                              width: 32,
                                              marginLeft: 6,
                                            }}
                                          >
                                            <FiX
                                              color={theme.colors.graphite}
                                              size={
                                                theme.fontSizes.headerXSmall
                                              }
                                            />
                                          </SC.ExecuteIconContainer>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {(!connectedRating ||
                                          connectedRating?.is_ok === true) && (
                                          <SC.ExecuteIconContainer
                                            onClick={
                                              connectedRating
                                                ? () => {}
                                                : () =>
                                                    onRate({
                                                      component: c,
                                                      isOk: true,
                                                    })
                                            }
                                            style={{
                                              backgroundColor:
                                                connectedRating?.is_ok === true
                                                  ? theme.colors.greenLight
                                                  : "#fff",
                                            }}
                                          >
                                            <FiThumbsUp
                                              color={theme.colors.greenDark}
                                              size={theme.fontSizes.headerSmall}
                                            />
                                          </SC.ExecuteIconContainer>
                                        )}
                                        {(!connectedRating ||
                                          connectedRating?.is_ok === false) && (
                                          <SC.ExecuteIconContainer
                                            onClick={
                                              connectedRating
                                                ? () => {}
                                                : () =>
                                                    onRate({
                                                      component: c,
                                                      isOk: false,
                                                    })
                                            }
                                            style={{
                                              marginLeft: 6,
                                              backgroundColor:
                                                connectedRating?.is_ok === false
                                                  ? theme.colors.redLight
                                                  : "#fff",
                                            }}
                                          >
                                            <FiThumbsDown
                                              color={theme.colors.red}
                                              size={theme.fontSizes.headerSmall}
                                            />
                                          </SC.ExecuteIconContainer>
                                        )}

                                        {connectedRating && (
                                          <SC.ExecuteIconContainer
                                            onClick={() =>
                                              clearRating({
                                                component: c,
                                              })
                                            }
                                            style={{
                                              backgroundColor: "#fff",
                                              height: 32,
                                              width: 32,
                                              marginLeft: 6,
                                            }}
                                          >
                                            <FiX
                                              color={theme.colors.graphite}
                                              size={
                                                theme.fontSizes.headerXSmall
                                              }
                                            />
                                          </SC.ExecuteIconContainer>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </SC.Component>
                          );
                        })}
                    </SC.ComponentContainer>
                    {!isExecute &&
                      !selectOnly &&
                      !archivedOpen.includes(roomId) &&
                      room.components?.filter((c) => c.archived)?.length >
                        0 && (
                        <TextButton
                          extraStyle={{ marginTop: 12 }}
                          title={`Visa ${
                            room.components?.filter((c) => c.archived)?.length
                          } arkiverad${
                            room.components?.filter((c) => c.archived)
                              ?.length == 1
                              ? ""
                              : "e"
                          } komponent${
                            room.components?.filter((c) => c.archived)
                              ?.length == 1
                              ? ""
                              : "er"
                          }`}
                          clicked={() => toggleArchivedOpen(roomId)}
                        />
                      )}
                  </InnerBox>

                  {archivedOpen?.includes(roomId) && (
                    <InnerBox
                      style={{
                        margin: " 12px 12px 24px 12px",
                        width: "calc(100% - 24px)",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: 12,
                          fontWeight: theme.fontWeights.headerSmall,
                          fontSize: theme.fontSizes.headerXSmall,
                        }}
                      >
                        Arkiverade komponenter
                      </div>
                      <SC.ComponentsContainer>
                        {room.components
                          ?.filter((c) => c.archived)
                          ?.map((c, idx) => {
                            const componentProductTypeUsage =
                              getCurrentProductTypeUsage(c);
                            const productType =
                              componentProductTypeUsage?.product_type;
                            const componentType = productType?.component_type;

                            return (
                              <SC.Component
                                isFirst={idx === 0}
                                isLast={idx === room.components.length - 1}
                                onClick={() => goToComponent(c)}
                              >
                                <SC.RoomTitle style={{ maxWidth: "70%" }}>
                                  <div style={{ fontWeight: 500 }}>
                                    {componentType?.str_representation}
                                  </div>
                                  <div>{productType?.str_representation}</div>
                                </SC.RoomTitle>

                                <div
                                  style={{
                                    marginLeft: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <SC.ArrowButton />
                                </div>
                              </SC.Component>
                            );
                          })}
                      </SC.ComponentsContainer>
                      <TextButton
                        extraStyle={{ marginTop: 12 }}
                        title={`Dölj arkiverade komponenter`}
                        clicked={() => toggleArchivedOpen(roomId)}
                      />
                    </InnerBox>
                  )}
                </SC.ComponentArea>
              )}
            </>
          );
        })}
      </SC.Wrapper>
    </>
  );
}

const renderRoomIconBasedOnCategory = (category) => {
  switch (category) {
    //kitchen
    case 0:
      return (
        <MdIcon.MdKitchen
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //hallway
    case 1:
      return (
        <FaIcon.FaShoePrints
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //bathroom
    case 2:
      return (
        <MdIcon.MdHotTub
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //toilet
    case 3:
      return (
        <FaIcon.FaToiletPaper
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //pantry
    case 4:
      return (
        <GiIcon.GiHoneypot
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //living room
    case 5:
      return (
        <FiMonitor
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //library
    case 6:
      return (
        <MdIcon.MdLibraryBooks
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //storage
    case 7:
      return (
        <FiBox
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //dining room
    case 8:
      return (
        <MdIcon.MdAirlineSeatReclineNormal
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //office
    case 9:
      return (
        <MdIcon.MdComputer
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //laundry
    case 10:
      return (
        <GiIcon.GiClothespin
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //closet
    case 11:
      return (
        <FaIcon.FaTshirt
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //bedroom
    case 12:
      return (
        <FaIcon.FaBed
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //Nursery
    case 13:
      return (
        <MdIcon.MdChildCare
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //Gym
    case 14:
      return (
        <GiIcon.GiWeightLiftingUp
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //Basement
    case 15:
      return (
        <GiIcon.GiStairs
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //Attic
    case 16:
      return (
        <FiChevronUp
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );
    //Other
    case 17:
      return (
        <FiHexagon
          color={theme.colors.graphite}
          size={theme.fontSizes.headerSmall}
        />
      );

    default:
      return null;
  }
};
