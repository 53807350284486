export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTASEARCH",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTASEARCH",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTASEARCH",
  DESTROY_FORM: "DESTROY_FORM_BILLECTASEARCH",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTASEARCH",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTASEARCH",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTASEARCH",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTASEARCH",
  RESET_STATE: "RESET_STATE_BILLECTASEARCH",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTASEARCH",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BILLECTASEARCH",

  SET_SEARCH_RESULT: "SET_SEARCH_RESULT_BILLECTASEARCH",
  SET_CUSTOM_SEARCH_RESULT: "SET_CUSTOM_SEARCH_RESULT_BILLECTASEARCH",

  STORE_NAME: "billectaSearch",
};
