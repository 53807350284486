import * as React from "react";
import { useHistory } from "react-router-dom";
import { TextButton } from "../../../Forms/Base/Buttons";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import * as SC from "./styles";

export default function TaskCard({ title, items, link, loading }) {
  const { push } = useHistory();

  const done = items?.length === 0 && !loading;
  return (
    <SC.TaskCardWrapper done={done} onClick={() => push(`/my-workday/${link}`)}>
      {loading && <OverlaySpinner />}
      <SC.TaskCardHeader done={done}>
        <span style={{ zIndex: 1 }}>{title}</span>{" "}
        {!done && <SC.TaskCardHeaderIcon />}
      </SC.TaskCardHeader>
      <SC.TaskCardContent>
        <SC.TaskCardItems>
          {done && <SC.TaskCardDone>Inget att hantera</SC.TaskCardDone>}
          {items.map((i) => (
            <SC.TaskCardItem key={i}>{i}</SC.TaskCardItem>
          ))}
        </SC.TaskCardItems>
      </SC.TaskCardContent>
    </SC.TaskCardWrapper>
  );
}
