import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingLease";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import InvoicingSettingDefault from "../../../../Summaries/InvoicingSettingDefault";
import { cloneDeep } from "lodash";
import { TextButton } from "../../../Base/Buttons";
import { NumberInput, RadioGroup } from "../../../Base/Fields";
import { StatusLabel } from "../../../../Lists/Base/CompleteList/styles";
import { useInvoicingSetting } from "../../../../../store/invoicingSettings";
import { useDispatch } from "react-redux";
import { useCompanyInvoicing } from "../../../../../store/invoicingCompany";
import { useCompany } from "../../../../../store/companies";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const requireCostAccept = useFormInstanceField({
    storeName,
    fieldKey: "require_cost_accept",
  });

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const adminFee = useFormInstanceField({
    storeName,
    fieldKey: "admin_fee",
  });

  const autoMerge = useFormInstanceField({
    storeName,
    fieldKey: "auto_merge",
  });

  const autoAttest = useFormInstanceField({
    storeName,
    fieldKey: "auto_attest",
  });

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const selectedBillingCompany = useFormInstanceField({
    storeName,
    fieldKey: "billing_company",
  });

  const [billingCompany] = useCompany(selectedBillingCompany?.id);
  const [companyInvoicingConfig] = useCompanyInvoicing(
    billingCompany?.invoicing_details?.id
  );

  const [existingSetting] = useInvoicingSetting(defaultSetting?.id);

  return (
    <>
      <FormAreaTitle>Fakturahantering</FormAreaTitle>

      {requireCostAccept != null ||
      existingSetting?.require_cost_accept == null ? (
        <>
          {existingSetting?.require_cost_accept != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.require_cost_accept = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <RadioGroup
            title="Kräv godkännande av kostnader för avisering"
            description="Om denna inställning är aktiv krävs det att en användare som har tillgång till aviseringsmodulen (Pigello Hyra) godkänner ändringar eller tillägg av kostnader och rabatter. Exempel: En användare som har rätt att redigera avtalet lägger till en rabatt på 200 SEK. Denna rabatt kommer ej att tas hänsyn till i aviseringen innan en användare som har tillgång till Pigello Hyra har godkänt rabatten."
            {...{ storeName, method, fieldKey: "require_cost_accept" }}
            forceHorizontalToolTipPlacement="left"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Kostnadsgodkännande"
          content={[
            {
              title: "Kräv godkännande av kostnader",
              content: existingSetting?.require_cost_accept ? (
                <StatusLabel state={0}>Ja</StatusLabel>
              ) : (
                <StatusLabel state={6}>Nej</StatusLabel>
              ),
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  require_cost_accept: false,
                },
              })
            );
          }}
        />
      )}

      {adminFee != null || companyInvoicingConfig?.admin_fee == null ? (
        <>
          {existingSetting?.admin_fee != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              sourceTitle="Från valt bolag"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.admin_fee = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <NumberInput
            title="Administrationsavgift"
            description="Ange kostnaden för administrationsavgift av faktura. "
            {...{ storeName, method, fieldKey: "admin_fee" }}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Administrationsavgift"
          content={[
            {
              title: "Administrationsavgift",
              content: companyInvoicingConfig?.admin_fee || 0,
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  admin_fee: 0,
                },
              })
            );
          }}
        />
      )}

      {autoMerge != null || existingSetting?.auto_merge == null ? (
        <>
          {existingSetting?.auto_merge != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.auto_merge = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <RadioGroup
            title="Slå ihop fakturor som genererats för detta avtal"
            description="Om denna inställning är aktiv kommer fakturor som genereras för detta avtal automatiskt slås ihop med andra icke-attesterade fakturor till samma kund."
            {...{ storeName, method, fieldKey: "auto_merge" }}
            forceHorizontalToolTipPlacement="left"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={true}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Slå ihop fakturor"
          content={[
            {
              title: "Slå ihop fakturor som genererats för detta avtal",
              content: existingSetting?.auto_merge ? (
                <StatusLabel state={0}>Ja</StatusLabel>
              ) : (
                <StatusLabel state={6}>Nej</StatusLabel>
              ),
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  auto_merge: false,
                },
              })
            );
          }}
        />
      )}

      {autoAttest != null || existingSetting?.auto_attest == null ? (
        <>
          {existingSetting?.auto_attest != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.auto_attest = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <RadioGroup
            title="Autoattestera fakturor som genereras för detta avtal"
            description="Om denna inställning är aktiv kommer alla fakturor som genereras automatiskt att attesteras och bokföras så fort de skapas."
            {...{ storeName, method, fieldKey: "auto_attest" }}
            forceHorizontalToolTipPlacement="left"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Automatisk attestering"
          content={[
            {
              title: "Autoattestera fakturor som genereras för detta avtal",
              content: existingSetting?.auto_attest ? (
                <StatusLabel state={0}>Ja</StatusLabel>
              ) : (
                <StatusLabel state={6}>Nej</StatusLabel>
              ),
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  auto_attest: false,
                },
              })
            );
          }}
        />
      )}
    </>
  );
};
