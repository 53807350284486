export default {
  DueDate:
    "Förfallodatumet är innan fakturadatumet. Fakturan kommer ej kunna attesteras.",
  DueDate_before_today: "Förfallodatumet måste ligga i framtiden.",
  InvoiceFee_Value_Positive:
    "Administrationsavgiften får ej vara positiv på en kreditfaktura",
  DeliveryMethod_Email:
    "Mottagaren saknar e-postadress för leverans. Lägg till en e-postadress på mottagaren eller välj en annan leveransmetod.",
  DeliveryMethod_Mail:
    "Mottagaren saknar fysisk adress för leverans. Lägg till en address på mottagaren eller välj en annan leveransmetod.",
  DeliveryMethod_Kivra_Disabled:
    "Kivra-integrationen är inte konfigurerad för detta bolag. Aktivera integrationen för att kunna nyttja Kivra som leveransmetod.",

  DeliveryMethod_EInvoice_Disabled:
    "E-fakturering har inte aktiverats. Kontakta Pigello för att aktivera detta.",
  "DebtCollectionDetails.DaysDelayAfterDueDate":
    "Minst 1 dag är god kravsed. Fakturan kommer ej kunna attesteras.",
  "DebtCollectionDetails.DaysDelayAfterDueDate_Reminder":
    "Datumet för att skicka fakturan till kravhantering måste vara minst 1 dag efter påminnelsefakturans utskicksdatum.",
  StartDebtCollectionActionLevelLatePaymentFeeNotCompany:
    "Förseningsavgift (företag) kan inte användas på kravhantering mot privatpersoner.",
  "DebtCollectionDetails.PaymentTermsInDays":
    "Minst 10 dagars betalvillkor är god kravsed. Fakturan kommer ej kunna attesteras.",
  ReasonForHigherInterest:
    "Anledning till hög ränta måste anges om räntan är över 8%.",
  InterestStartInDaysAfterDueDate:
    "Minst 8 dagar innan ränta börjar räknas är god kravsed. Fakturan kommer ej kunna attesteras.",
  Credit_invoice_records_positivevalue:
    "Kreditfakturor får enbart innehålla negativa värden",
};
