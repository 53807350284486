import * as React from "react";

export default () => {
  return (
    <div>
      Välj vilka plattformer annonserna ska publiceras på. Detta kan redigeras
      per annons i nästa steg.
      <br />
      <br />
      <strong>OBS: </strong>
      <br />
      Vissa plattformar kan kräva att detaljerade annonsinställningar är
      specificerade på objekten som annonseras för.{" "}
    </div>
  );
};
