import axios from "axios";

/**
 * Takes a signed url and a base64 encoded file and stores on S3
 * @param {Object} {signedUrl (String), base64 (String)}
 */
export async function uploadBase64ToS3({ signedUrl, base64 }) {
  try {
    const fileType = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

    const file = await b64toBlob(base64, fileType);

    const result = await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": fileType,
      },
    });

    return result;
  } catch (e) {
    console.error(e);
    return false;
  }
}

const b64toBlob = (base64) => {
  return fetch(`${base64}`).then((res) => res.blob());
};

/**
 * Takes signed urls and base64 encoded files and stores on S3
 * @param {Object} {signedUrls {Array}, files {Array}}
 */
export async function uploadMultiBase64ToS3({ signedUrls, files }) {
  let promises = signedUrls.map((url, index) => {
    const base64 = files[index];
    return uploadBase64ToS3({ signedUrl: url, base64 });
  });

  const responses = await Promise.all(promises);

  return responses;
}
