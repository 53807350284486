export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_Unit4ERP",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_Unit4ERP",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_Unit4ERP",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_Unit4ERP",
  DESTROY_FORM: "DESTROY_FORM_Unit4ERP",
  SET_FORM_ERROR: "SET_FORM_ERROR_Unit4ERP",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_Unit4ERP",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_Unit4ERP",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_Unit4ERP",
  RESET_STATE: "RESET_STATE_Unit4ERP",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_Unit4ERP",
  REMOVE_OBJECT: "REMOVE_OBJECT_Unit4ERP",
  CLEAR_FETCHED: "CLEAR_FETCHED_Unit4ERP",

  LIST_URL: "/accounting/bookkeeping/erp/erpvoucherconfig/list/",
  POST_URL: "/accounting/bookkeeping/erp/erpvoucherconfig/",
  PATCH_URL: "/accounting/bookkeeping/erp/erpvoucherconfig/",
  GET_URL: "/accounting/bookkeeping/erp/erpvoucherconfig/",

  STORE_NAME: "Unit4ERP",
};
