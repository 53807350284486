import * as React from "react";

import { useHistory } from "react-router";

import UserList from "../../components/Account/UserList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import * as SharedStyles from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";

import { useAllPermissionCheck, usePermissionCheck } from "../../store/base";
import { useSupervisorCheck } from "../../store/base/hooks/permissions";

import PageTitle from "../Layouts/PageTitle/PageTitle";

export default () => {
  const { push } = useHistory();

  const query = useQuery();
  const tab = query.get("tab");

  const canAddGroup = useAllPermissionCheck([
    "view_can_permissions",
    "add_can_permissions",
  ]);
  const canViewGroups = usePermissionCheck("view_can_permissions");
  const canViewUsers = usePermissionCheck("view_can_user");
  const isSupervisor = useSupervisorCheck();

  const canAddUser = usePermissionCheck("add_can_user");

  const [selectedTab, setSelectedTab] = React.useState("USERS");
  const [showGroupGuide, setShowGroupGuide] = React.useState(false);

  const hasHiddenGuide = localStorage.getItem("hide_group_guide");

  React.useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }

    if (!hasHiddenGuide) {
      setShowGroupGuide(true);
    }
  }, [tab]);

  const initiateAddNewUser = () => {
    push("/configcenter/groups-and-users/user-setup/create-user");
  };

  const breadCrumbs = [
    {
      label: "Användare & behörigheter",
      url: "/configcenter/groups-and-users",
    },
  ];

  if (selectedTab === "GROUPS") {
    breadCrumbs.push({
      label: "Grupper",
    });
  } else if (selectedTab === "USERS") {
    breadCrumbs.push({
      label: "Användare",
    });
  }

  return (
    <SharedStyles.BareLayoutWrapper>
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="Användare" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        {canViewUsers || isSupervisor ? (
          <>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Användare</OverviewTitle>
                <OverviewSubtitle>
                  Tryck på en användare för att se detaljer och redigera
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
              {(canAddUser || isSupervisor) && (
                <>
                  <PrimaryButton
                    title="Lägg till användare"
                    newDesign
                    clicked={initiateAddNewUser}
                  />
                </>
              )}
            </OverviewTitleWrapper>
            <UserList />
          </>
        ) : (
          <>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>
                  Behörighet att se användare saknas
                </OverviewTitle>
                <OverviewSubtitle>
                  {isSupervisor
                    ? "Du kan slå på behörighet själv att se användare via Grupper upp till höger."
                    : "För att få se lista över användare krävs rätt behörighet."}
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
          </>
        )}
      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
