import * as React from "react";
import * as SC from "./styles";
export default ({ suggestions, handleSuggestionClicked }) => {
  const renderSuggestions = () => {
    return suggestions.map((suggestion) => (
      <li
        key={suggestion.id}
        onClick={(e) =>
          handleSuggestionClicked({ suggestion: suggestion, event: e })
        }
      >
        {suggestion.text}
      </li>
    ));
  };

  return <SC.Suggestions>{renderSuggestions()}</SC.Suggestions>;
};
