import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  destroyPatchForm,
  constants,
  update,
} from "../../../../store/realEstates";
import { useRealEstateForm } from "../../../../store/realEstates";
import ModalChapter from "../../Base/Chapters/ModalChapter";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../../store/base";

export default function AccountingModalRealEstate({
  method = "PATCH",
  id,
  isOpen,
  onCheckout,
  successCallback,
  instance,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useRealEstateForm(method, id));

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  React.useEffect(() => {
    if (formLoaded && instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, [formLoaded, isOpen]);

  React.useEffect(() => {
    if (!isOpen) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );
    }
  }, [isOpen]);

  const onSuccess = () => {
    setLoading(false);
    checkout(true);

    successCallback && successCallback();
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      loading={!formLoaded || loading}
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={"Uppdatera bokföringsinsdimensioner"}
      onDone={onDone}
      isOpen={isOpen}
    />
  );
}
