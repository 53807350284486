export function toMoneyString(value, withCurrency, decimals) {
  if (value == null) {
    return "-";
  }

  if (decimals) {
    value = value.toFixed(decimals)
  } else {
    value = Math.round(value)
  }
  
  if (withCurrency) return value.toLocaleString("sv") + " SEK";

  return value.toLocaleString("sv");
}

export function toSqmString(area) {
  if (area === undefined || area === null) {
    return "-";
  }
  return `${area.toLocaleString("sv")} m²`;
}

export function toMoneyPerSqmString(value) {
  if (value === undefined || value === null) {
    return "-";
  }
  return `${value.toLocaleString("sv")} SEK/m²`;
}

export const stripHtml = (value) => {
  if (!value) {
    return "";
  }
  value = value.replaceAll("<br />", "\n");

  const plc = document.createElement("DIV");
  plc.innerHTML = value;
  return plc.textContent || plc.innerText || "";
};
