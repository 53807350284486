import Document from "./Document";
import Faults from "./Faults";
import Errand from "./Errand";
import Confirm from "./Confirm";

export default {
  DOCUMENT: Document,
  FAULTS: Faults,
  ERRAND: Errand,
  CONFIRM: Confirm,
};
