import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import LeaseContractConnectedContracts from "./Detail/ConnectedContracts";
import LeaseContractDocumentTabs from "./Detail/Documents";
import LeaseContractOverview from "./Detail/Overview";
import Costs from "../../components/Details/OverviewInfo/Costs/Costs";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { toMoneyString } from "../../components/utils/stringUtils";
import {
  buildQueryString,
  setActiveFormInstance,
  useAllPermissionCheck,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../store/base";
import { useInvoicingErrorPaginationCount } from "../../store/invoicingErrors/hooks/retrieve";

import {
  cancelUrl,
  constants,
  useLeaseContract,
} from "../../store/leaseContracts";
import { constants as editableDocConstants } from "../../store/editabledocs";
import {
  constants as invoicingConstants,
  useLeaseInvoicing,
} from "../../store/invoicingLease";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import { ESignOrManual } from "../../components/Forms/Pickers";
import { useCanUseScrive } from "../../store/editabledocs/hooks/retrieve";

import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import LeaseIMD from "./Detail/IMD";
import ContractInvoicingBase from "./Detail/ContractInvoicingBase";
import { useFilteredApartments } from "../../store/apartments";
import { useFilteredIndustrialPremises } from "../../store/industrialPremises";
import {
  contractOverviewCosts,
  getContractFromAndTo,
} from "../../components/Lists/utils/costCalculations";

function LeaseContractDetail() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  const { leaseContractId } = useParams();

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [cancelTypeOpen, setCancelTypeOpen] = React.useState(false);
  const [leaseContract, leaseContractLoading] =
    useLeaseContract(leaseContractId);
  const [invoicingObj, invoicingObjLoading] = useLeaseInvoicing(
    leaseContract?.lease_invoicing?.id
  );

  const { hasBillectaViewPermission } = useFrequentPermissions();
  usePermissionRedirect(["view_can_contract"]);

  const canDelete = usePermissionCheck("delete_can_contract");
  const canEdit = usePermissionCheck("change_can_contract");
  const [canUseScrive] = useCanUseScrive();
  const canImd = useAllPermissionCheck(["allow_imd", "view_can_imd"]);

  const apartmentQuery = buildQueryString({
    id__in: leaseContract?.apartments?.map((ap) => ap.id) || [],
  });
  const [apartments] = useFilteredApartments(apartmentQuery);

  const indpQuery = buildQueryString({
    id__in: leaseContract?.industrial_premises_list?.map((ip) => ip.id) || [],
  });
  const [industrialPremises] = useFilteredIndustrialPremises(indpQuery);

  const combinedCosts = [
    ...(invoicingObj?.cost_set || []),
    ...(apartments?.map((a) => a.cost_set)?.flat() || []),
    ...(industrialPremises?.map((i) => i.cost_set)?.flat() || []),
  ];

  const isCancelled =
    leaseContract?.closed_date || leaseContract?.closed_renew_date;
  const isSigned = leaseContract?.is_signed;

  // clear form states
  React.useEffect(() => {
    return () => {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: invoicingConstants.STORE_NAME,
          data: {},
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: editableDocConstants.STORE_NAME,
          data: {},
        })
      );
    };
  }, []);

  const [invoicingErrorCount] = useInvoicingErrorPaginationCount({
    filters: {
      leaseinvoicing: leaseContract?.lease_invoicing?.id || -1,
    },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Avtal",
      component: LeaseContractOverview,
    },
    {
      path: "/costs",
      label: "Debiteringsrader",
      component: Costs,
    },
    {
      path: "/docs",
      label: "Dokument",
      component: LeaseContractDocumentTabs,
    },
    {
      path: "/connected-contracts",
      label: "Kopplade avtal",
      component: LeaseContractConnectedContracts,
    },
  ];

  if (hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      hasError: invoicingErrorCount,
      component: ContractInvoicingBase,
    });
  }

  if (canImd) {
    SUB_PAGES.push({
      path: "/imd",
      label: "IMD",
      component: LeaseIMD,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleCancelClicked = () => {
    if (canUseScrive) {
      setCancelTypeOpen(true);
    } else {
      push(cancelUrl({ id: leaseContractId, isManualSign: true }));
    }
  };

  const onCancelTypeSelected = (cancelType) => {
    push(
      cancelUrl({
        id: leaseContractId,
        isManualSign: cancelType !== "digital",
      })
    );
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
  };

  const handleActions = [];

  if (canEdit && !isCancelled && isSigned) {
    handleActions.push({
      name: "Säg upp",
      onClick: handleCancelClicked,
    });
  }

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: handleDelete,
      isDelete: true,
    });
  }

  const renderInfoPills = () => {
    if (!leaseContract) return null;
    const { contractFrom, contractTo } = getContractFromAndTo(leaseContract);

    return (
      <>
        <StatusLabel state={leaseContract?.state}>
          {leaseContract?.state_display}
        </StatusLabel>
        {isSigned && !isCancelled && (
          <StatusLabel style={{ marginLeft: 12 }} state={1}>
            Signerat
          </StatusLabel>
        )}
        <StatusLabel style={{ marginLeft: 12 }} state={"Attesterad"}>
          Antal hyresobjekt:{" "}
          {(leaseContract?.apartments?.length || 0) +
            (leaseContract?.industrial_premises_list?.length || 0)}
        </StatusLabel>
        <StatusLabel style={{ marginLeft: 12 }} state={4}>
          Årsintäkt ({contractFrom.format("YYYY-MM")} -{" "}
          {contractTo.format("YYYY-MM")}):{" "}
          {toMoneyString(
            contractOverviewCosts({
              costsData: combinedCosts,
              contract: leaseContract,
            }),
            true
          )}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        constants={constants}
        instance={leaseContract}
        deletedCallback={() => push("/lease-contracts")}
      />

      <ESignOrManual
        isOpen={cancelTypeOpen}
        onClose={() => setCancelTypeOpen(false)}
        onSelect={onCancelTypeSelected}
        description="Bekräfta uppsägning"
        digitalTitle="Signera digitalt"
        manualTitle="Bekräfta manuellt"
        infoText="Välj om uppsägningen ska signeras digitalt eller enbart bekräftas"
      />

      <DetailLayoutWrapper>
        {leaseContractLoading && <OverlaySpinner />}
        <DetailPageHeaderMenu
          title={`Hyresavtal ${leaseContract?.id_number || "Laddar..."}${
            leaseContract?.block?.id ? " (Blockavtal)" : ""
          }`}
          breadCrumbs={[
            { url: "/lease-contracts", label: "Hyresavtal" },
            { label: leaseContract?.id_number || "Laddar..." },
          ]}
          eventContentType="standard.leasecontract"
          eventObjectId={leaseContractId}
          eventIdQueryStr="leasecontract"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default LeaseContractDetail;
