import * as React from "react";
import moment from "moment";
import * as SC from "./styles";
import * as OverviewStyles from "../styles";
import { useScriveHistory } from "../../../../store/editabledocs/hooks/retrieve";
import { TextButton } from "../../../Forms/Base/Buttons";
import { BodyText } from "../../../sharedStyles";

/**
 * Possible scrive statuses for events 
 initiated -
draft
cancelled -
rejected - 
timeouted - 
problem -
deliveryproblem -
sent - 
delivered - 
read - 
opened - 
signed - 
prolonged - 
sealed
extended - 
* https://apidocs.scrive.com/#history-items
 */

export default ({ editableDoc }) => {
  const [showAll, setShowAll] = React.useState(false);
  // use history hook will not fetch if no id
  const id = editableDoc?.id;

  const [history, isLoadingHistory] = useScriveHistory({ id });
  const data = history?.events || [];

  // draft is ilogical to our end-users, as they don't
  // know that this happens inside scrive
  const filteredData = data?.filter((d) => d.status !== "draft") || [];

  React.useEffect(() => {
    if (filteredData?.length && filteredData?.length < 5) {
      setShowAll(true);
    }
  }, [filteredData]);

  const displayedData = showAll ? filteredData : filteredData.slice(0, 5);

  return (
    <>
      <OverviewStyles.OverviewTitleWrapper>
        <OverviewStyles.OverviewTitle small>
          Händelser
        </OverviewStyles.OverviewTitle>
      </OverviewStyles.OverviewTitleWrapper>
      {displayedData.length > 0 ? (
        <>
          {displayedData.map((d) => {
            return (
              <SC.Event key={`${d.time}-${d.text}`}>
                <SC.EventTitle>
                  <SC.EventStatusIcon status={d.status} />
                  <SC.EventDate
                    style={{ marginLeft: "auto", fontSize: ".9rem" }}
                  >
                    {moment(d.time).format("YYYY-MM-DD HH:mm")}
                  </SC.EventDate>
                </SC.EventTitle>

                <SC.EventText style={{ fontSize: ".9rem", maxWidth: "70%" }}>
                  {d.text}
                </SC.EventText>
              </SC.Event>
            );
          })}

          {!showAll && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 24,
              }}
            >
              <BodyText>Visar 5 av {filteredData?.length} händelser.</BodyText>
              <TextButton
                title={`Visa alla händelser`}
                extraStyle={{ marginRight: 0 }}
                iconType="add"
                iconPlacement="right"
                clicked={() => setShowAll(true)}
              />
            </div>
          )}
        </>
      ) : (
        <div>
          {isLoadingHistory ? "Laddar händelser..." : "Historik saknas"}
        </div>
      )}
    </>
  );
};
