import moment from "moment";

export const constructStrRep = (obj) => {
  return obj.str_representation || "Dörr";
};
export const createUrl = () => {
  return `/keys/keydevices/create`;
};
export const updateUrl = ({id}) => {
  return `/keys/keydevices/update/${id}`;
};
