import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);
  const { type, payload } = action;

  switch (type) {
    case constants.SET_AVAILABLE_PPT_REPORTS: {
      return {
        ...newState,
        availablePptReports: payload.availablePptReports,
      };
    }

    default:
      return newState;
  }
};
