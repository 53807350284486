import * as React from "react";

// style, design
import { ToolTipCell, DateCell, EmailTag } from "../../Displays";
import AnonymousCell from "../../Displays/AnonymousCell";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default (groups) => {
  const defs = [
    {
      Header: "Status",
      accessor: "_is_active", //Fake flag to show invited status aswell
      Cell: ({ row }) => {
        const isActive = row.original.is_active;
        const invited = row.original.invite_pending;
        return (
          <StatusLabel state={isActive ? 0 : invited ? 4 : 3}>
            {isActive ? "Aktiv" : invited ? "Inbjuden" : "Inaktiv"}
          </StatusLabel>
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Namn",
      accessor: "str_representation",
      Cell: ({ value, row }) =>
        !!row?.original?.anonymization ? (
          <AnonymousCell />
        ) : (
          <ToolTipCell text={value} />
        ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Användartyp",
      accessor: (row) => {
        if (row.util_user) {
          return "Förvaltare";
        } else {
          return row.user_type_display;
        }
      },
      Cell: ({ value }) => <ToolTipCell text={value} />,
    },

    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => {
        return value ? <EmailTag mail={value} /> : "-";
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Aktiv till",
      accessor: "active_to",
      Cell: ({ value }) => {
        return value ? (
          <DateCell date={value} />
        ) : (
          <ToolTipCell text="Tillsvidare" />
        );
      },
    },
  ];
  if (!!groups?.length) {
    defs.push({
      Header: "Behörighetsgrupp",
      accessor: "usergroup_set",
      Cell: ({ value }) => {
        const userGroupId = value[0]?.id;
        const groupMatch = groups?.find((g) => g.id === userGroupId);
        return <ToolTipCell text={groupMatch?.name || "Ingen grupp vald"} />;
      },
    });
  }

  return defs;
};
