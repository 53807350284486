import * as React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useExpanded,
} from "react-table";
import { isEqual } from "lodash";

// style, design
import * as SC from "./styles";

// store, state
import { store } from "../../../../store/store";
import {
  appendQueryString,
  buildQueryString,
  useInProgress,
} from "../../../../store/base";
import {
  performTableToBookExcelExport,
  prepareFilters,
  prepareOrdering,
} from "../utils";
import Pagination from "../Pagination/Pagination";
import GlobalSearchBox from "../GlobalSearchBox/GlobalSearchBox";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import {
  CreateNewButton,
  SecondaryButton,
  TextButton,
} from "../../../Forms/Base/Buttons";
import ExportExcel from "../../../Forms/Base/Buttons/ExportExcel";
import ColumnSelector from "../ColumnSelector";
import StandardModal from "../../../Modals/StandardModal";
import { FormAreaDescription } from "../../../Forms/Base/Chapters/styles";
import { CheckBox } from "../../../Forms/Base/Fields/styles";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { CloseCircleButton } from "../../../Modals/styles";

const BACKEND_RETURNED_PAGE_SIZE = 100;
// 10 000 temp solution since the tables are worthless
const ALLOWED_PAGE_SIZES = [5, 10, 25, 50, 100, 10000];
const INITIAL_PAGE_INDEX = 0;
export const DEFAULT_SHEET_KEY = "Data";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, row, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "18px",
          height: "18px",
        }}
      >
        <CheckBox
          id={row?.id || "all"}
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
        <label
          style={{ height: "26px", width: "26px" }}
          htmlFor={row?.id || "all"}
        ></label>
      </div>
    );
  }
);

/**
 * @param {string} persistantQueryString querystring that will be present on all async requests (Optional)
 *
 * @param {function} persistantFilterMethod method that will always be called if frontend handles filtering, ordering etc.
 * This filtering should provide the same result as the provided persistantQueryString does on async calls.
 * Example of usage [].filter(persistantFilterMethod)
 * (Optional if persistantQueryString is omitted)
 *
 * @param {function} paginationMethod method that is used to perform all async queries (required)
 * @param {function} filterAction method that is used to perform fetch all remaining elements, when filtering, orderin etc.
 * is about to be handed over to frontend. This method will be called with dispatch, it is assumed to be an action.
 * (Required)
 *
 * @param {Object} filterInstructions Object<fieldKey: String, instruction: Object<operator:<str>, <handler>:<function>>>
 * this is used to format filter values that will be included in the querystring used for async filtering
 * Example: {"state":{operator:"icontains"}, "user.legal_id":{operator:"icontains"}}
 * Note that operators are only supported for regular attributes, custom attributes that supports filtering
 * doesn't allow for operators to be specified and will result in a 400 response.
 * This applies to e.g filtering on str_representation, which is a custom attribute
 * (Optional)
 *
 * @param {Number} backendPageSize Amount of items reurned in each paginated async query, defaults to 50 (Optional)
 * @param {Array} allowedPageSizes Allowed page sizes, each page size must follow backendPageSize % pageSize === 0, defaults to [5, 10, 25, 50] (Optional)
 * @param {Object} initialTableState Initial state of the table
 * Example: {pageSize: initialPageSize, pageIndex:  withPersistantSortBy ? getInitialPage() :INITIAL_PAGE_INDEX}
 * Defaults to {pageSize: allowedPageSizes[0], pageIndex: 0}
 * (Optional)
 *
 * @param {Number} fetchAllTreshold Maximum amount of elements missing out of maximum possible amount of elemts
 * before we can fetch all values from backend, and hand over filtering, sorting etc. responsibility to frontend
 * (Optional)
 *
 * @param {function} detailUrlMethod method to retrieve url for detail viewing a row (Required)
 *
 * @param {function} toggleFormCallback Callback made upon click on 'Create' button that is attached to table component (Optional)
 * @param {string} title String that is shown on above table (Optional)
 * @param {Array} forceFrontendColumns Array of fieldKey's that when filtered/ordered on triggers component (Optional)
 * to fetch all remaining elements and hand over responsibility of filtering/ordering to frontend (Optional)
 *
 * @param {Boolean} forceFrontendOnGlobalSearch Indicates whether a global search will trigger component (Optional)
 * to fetch all remaining elements and hand over responsibility of filtering/ordering to frontend
 */
export default React.memo(
  ({
    storeName,
    columns,
    persistantQueryString,
    persistantFilterMethod,
    initialSortBy, // array of sort instructions, for example [{ id:"added_time", desc:true }]
    paginationMethod,
    filterAction,
    filterInstructions,
    backendPageSize = BACKEND_RETURNED_PAGE_SIZE,
    allowedPageSizes = ALLOWED_PAGE_SIZES,
    forceInitialPageSize,
    hidePagination,
    initialTableState,
    fetchAllTreshold,
    detailUrlMethod,
    toggleFormCallback,
    formCallbackLabel,
    title,
    forceFrontendColumns,
    forceFrontendOnGlobalSearch,
    exportSheetController,
    hideSearch,
    hideExport,
    hideFilters,
    hideColumns,
    isNotificationsTable,
    onRowClicked,
    checkRowHighlighted,
    withSelectableRows,
    withPersistantSortBy,
    withPersistantGlobalFilter,
    persistantCategories,
    clearCategories,
    handleSelectedUpdated,
    localStorageHiddenColumnId,
    renderRowSubComponent,
    subRowComponentBackgroundColor = "#979FAE",
    withFullScreen,
    preventAutoFocus,

    // external filter components, see errands for example
    externalFilters,
    blockAllMode,
  }) => {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const PERSISTANT_SORT_BY_KEY = `persistant_sort_by_${storeName}`;
    const PERSISTANT_PAGE_KEY = `persistant_page_${storeName}`;
    const PERSISTANT_PAGE_SIZE_KEY = `persistant_page_size_${storeName}`;
    const PERSISTANT_FILTERS_KEY = `persistant_filters_${storeName}`;
    const PERSISTANT_GLOBAL_FILTER_KEY = `persistant_global_filter_${storeName}`;

    const tableRef = React.useRef();
    const tableWrapperRef = React.useRef();

    const user = useSelector((state) => state.app.user?.str_representation);

    const [defaultHiddenColumns, setDefaultHiddenColumns] =
      React.useState(null);
    const [fullscreenModeActive, setFullscreenModeActive] =
      React.useState(false);

    const [usedBackendPageSize, setUsedBackendPageSize] =
      React.useState(backendPageSize);

    // warn our devs
    // const bannedSize = allowedPageSizes.some((v) => usedBackendPageSize % v !== 0);
    // if (bannedSize) {
    //   throw Error(
    //     "all allowed pagesizes must be divisible by backend page size"
    //   );
    // }

    if (!exportSheetController) {
      exportSheetController = () => [DEFAULT_SHEET_KEY];
    }

    const getInitialGlobalFilter = () => {
      try {
        const persistantGlobalFilter = localStorage.getItem(
          PERSISTANT_GLOBAL_FILTER_KEY
        );
        const parsed = persistantGlobalFilter
          ? JSON.parse(persistantGlobalFilter)
          : "";

        return parsed;
      } catch (e) {
        console.log(e);
        return "";
      }
    };

    const getInitialSortBy = () => {
      try {
        const persistantSortBy = localStorage.getItem(PERSISTANT_SORT_BY_KEY);
        const parsed = JSON.parse(persistantSortBy) || [];
        return parsed;
      } catch (e) {
        console.log(e);
        return [];
      }
    };

    const getInitialPage = () => {
      try {
        const persistantPage = localStorage.getItem(PERSISTANT_PAGE_KEY);
        const parsed = JSON.parse(persistantPage) || 0;
        return parsed;
      } catch (e) {
        return INITIAL_PAGE_INDEX;
      }
    };

    const getInitialFilters = () => {
      try {
        const persitantFilters = localStorage.getItem(PERSISTANT_FILTERS_KEY);
        const parsed = JSON.parse(persitantFilters) || [];
        return parsed;
      } catch (e) {
        return [];
      }
    };

    const getInitialPageSize = () => {
      try {
        const persistantPageSize = localStorage.getItem(
          PERSISTANT_PAGE_SIZE_KEY
        );
        const parsed = JSON.parse(persistantPageSize) || allowedPageSizes[2];

        // eternal spinner if page size is larger than fetch size
        if (parsed === 10000) return allowedPageSizes[2];
        return parsed;
      } catch (e) {
        return 0;
      }
    };

    // configure initial state for table
    const initialPageSize = withPersistantSortBy
      ? getInitialPageSize()
      : forceInitialPageSize || allowedPageSizes[2];

    const [initialState, setInitialState] = React.useState({
      ...initialTableState,
      pageSize: initialPageSize,
      pageIndex: withPersistantSortBy ? getInitialPage() : INITIAL_PAGE_INDEX,
      sortBy:
        withPersistantSortBy && getInitialSortBy()?.length > 0
          ? getInitialSortBy()
          : initialSortBy || [],
      globalFilter: withPersistantGlobalFilter ? getInitialGlobalFilter() : "",
      filters: withPersistantSortBy ? getInitialFilters() : [],
    });
    // generate a querystring for backend pagination
    const paginatedQs = React.useCallback(
      (pageIndex, pageSize) => {
        // current page and pageSize will together define the querystring
        // BACKEND_RETURNED_PAGE_SIZE/pageSize -> amount of pages
        // that fits in one paginated query

        // e.g page size = 3, 3 pages fit in one paginated query
        // page 1 -> query 1
        // page 2 -> query 1
        // ...
        // page 4 -> query 2
        const pageFit = usedBackendPageSize / pageSize;
        const p = Math.ceil((pageIndex + 1) / pageFit);

        return buildQueryString({
          _page: pageFit < 1 ? 1 : p,
          _page_size: pageFit < 1 ? 10000 : usedBackendPageSize,
        });
      },
      [usedBackendPageSize]
    );

    // setup initial querystring for backend, before table has even loaded
    let initialQueryString = appendQueryString(
      persistantQueryString,
      paginatedQs(
        withPersistantSortBy ? getInitialPage() : INITIAL_PAGE_INDEX,
        withPersistantSortBy ? getInitialPageSize() : initialPageSize
      )
    );

    // set the initial states of isManual and shownItems according to whether we've already
    // have fetched all data
    const hasLoadedAll = useSelector(
      (state) => !blockAllMode && state[storeName].filtered[""] !== undefined
    );
    const [isManual, setIsManual] = React.useState(!hasLoadedAll);
    const [shownItems, setShownItems] = React.useState(
      hasLoadedAll ? Object.values(store.getState()[storeName].all) : []
    );

    const [queryString, setQueryString] = React.useState(null);

    const [allItems, setAllItems] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [maxItemCount, setMaxItemCount] = React.useState(undefined);

    const [toggleFilter, setToggleFilter] = React.useState(true);
    const [columnModalOpen, setColumnModalOpen] = React.useState(false);
    const [internalShownColumns, setInternalShownColumns] = React.useState(
      columns.map((c) => c.id || c.accessor)
    );

    const [queriedItems, isLoading, backendCountFlag] =
      paginationMethod(queryString);
    const queriedItemsRef = React.useRef(null);

    const isFetching = useInProgress({ storeName, name: queryString });

    const refreshData = () => {
      setShownItems(
        hasLoadedAll ? Object.values(store.getState()[storeName].all) : []
      );
    };

    const handleRowClicked = (row) => {
      //default behavior push on row clicked
      if (!onRowClicked && detailUrlMethod) {
        return push(detailUrlMethod(row.original));
      } else if (onRowClicked) {
        onRowClicked(row);
      }
    };

    // split items that are returned from backend, since
    // backend can return more items than the current pageSize displays
    const splitItems = React.useCallback(
      (items, pageIndex, pageSize) => {
        // e.g page size = 10, backend returned = 20
        // page 1 -> first half
        // page 2 -> second half
        // page 3 -> first half (page - 1 % 2 -> 1 = conformed index)
        // e.g page size = 5, backend returned = 20
        // page 1 -> first quarter
        // page 2 -> second querter...

        const itemLength = items.length;
        if (itemLength <= pageSize) {
          return items;
        }

        const quota = usedBackendPageSize / pageSize;
        const relativeIndex = pageIndex % quota;
        return items.slice(
          relativeIndex * pageSize,
          (relativeIndex + 1) * pageSize
        );
      },
      [usedBackendPageSize]
    );

    const cleanedItems = React.useMemo(
      () =>
        !isManual && persistantFilterMethod
          ? shownItems.filter(persistantFilterMethod)
          : shownItems,
      [isManual, persistantFilterMethod, shownItems]
    );

    const filterTypes = React.useMemo(
      () => ({
        textExact: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];

            // special case for state, where 0 and 1 essentially is the same (active, ok)
            if (
              id === "state" &&
              (rowValue === 0 || rowValue === 1) &&
              (filterValue === 0 || filterValue === 1)
            ) {
              return true;
            }
            return rowValue !== undefined ? rowValue === filterValue : true;
          });
        },
        selectMany: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];

            if (!Array.isArray(rowValue)) {
              return false;
            }
            if (!filterValue?.length) {
              return true;
            }

            return rowValue.some((r) => {
              return filterValue?.some((f) => f.id === r.id);
            });
          });
        },
        betweenDates: (rows, id, filterValue) => {
          if (!filterValue || !filterValue[0] || !filterValue[1]) return rows;
          let start = moment(filterValue[0]).subtract(1, "day");
          let end = moment(filterValue[1]).add(1, "day");
          return rows.filter((row) => {
            const rowValue = row.values[id];

            if (!rowValue) return false;

            try {
              const rowDate = moment(rowValue);
              return moment(rowDate).isBetween(start, end);
            } catch (e) {
              return false;
            }
          });
        },
      }),
      []
    );

    // initiate a table
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      pageCount: tablePageCount,
      prepareRow,
      selectedFlatRows,
      state: { filters, sortBy, pageIndex, pageSize, globalFilter, expanded },
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      setGlobalFilter,
      allColumns,
      setHiddenColumns,
      setAllFilters,
      setSortBy,
      visibleColumns,
    } = useTable(
      {
        columns,
        data: cleanedItems,
        manualFilters: isManual,
        manualSortBy: isManual,
        manualPagination: isManual,
        manualGlobalFilter: isManual,
        isMultiSortEvent: () => true,
        initialState,
        pageCount,
        autoResetFilters: false,
        autoResetSelectedRows: false,
        autoResetGlobalFilter: false,
        autoResetSortBy: false,
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
      (hooks) => {
        if (withSelectableRows) {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox
                    {...{ row }}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            ...columns,
          ]);
        }
      }
    );

    React.useEffect(() => {
      if (pageSize === 10000) {
        setUsedBackendPageSize(10000);
      } else {
        setUsedBackendPageSize(100);
      }
    }, [pageSize]);

    // Inform about persistant sortBy / globalfilter
    React.useEffect(() => {
      if (withPersistantGlobalFilter || withPersistantSortBy) {
        const initalSortBy = getInitialSortBy();
        let initialFilters = getInitialFilters();
        const initialGlobalFilter = getInitialGlobalFilter();

        //special check for filters, some values are automatic but doesn't actually filter anything
        if (initialFilters?.length) {
          const startDateFilter = initialFilters.find(
            (f) => f.id === "start_date"
          );
          const endDateFilter = initialFilters.find((f) => f.id === "end_date");

          // empty start date filter
          if (startDateFilter && !startDateFilter.value.some((v) => v)) {
            initialFilters = initialFilters.filter(
              (i) => i.id !== "start_date"
            );
          }

          // empty end date filter
          if (endDateFilter && !endDateFilter.value.some((v) => v)) {
            initialFilters = initialFilters.filter((i) => i.id !== "end_date");
          }
        }

        if (
          withPersistantGlobalFilter &&
          withPersistantSortBy &&
          initalSortBy?.length &&
          initialGlobalFilter?.length &&
          (initialFilters?.length || persistantCategories?.length)
        ) {
          // inform about all
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: "Sortering, filtrering och sökning aktiv",
              description:
                "Den sparade filtreringen, sorteringen och sökningen för tabellen används",
              action: {
                title: "Återställ filter, sortering och sökning",
                clicked: () => {
                  setSortBy([]);
                  setGlobalFilter("");
                  setAllFilters([]);
                  clearCategories && clearCategories();
                },
                successObj: {
                  title: "Filter, sortering och sökning återställdes",
                },
              },
            })
          );
        } else if (
          withPersistantGlobalFilter &&
          withPersistantSortBy &&
          initalSortBy?.length &&
          initialGlobalFilter?.length
        ) {
          // inform about both sort and search
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: "Sortering och sökning aktiv",
              description:
                "Den sparade sorteringen och sökningen för tabellen används",
              action: {
                title: "Återställ sortering och sökning",
                clicked: () => {
                  setSortBy([]);
                  setGlobalFilter(null);
                },
                successObj: {
                  title: "Sortering och sökning återställdes",
                },
              },
            })
          );
        } else if (
          withPersistantSortBy &&
          initalSortBy?.length &&
          (initialFilters?.length || persistantCategories?.length)
        ) {
          // inform about persistant sort by and filters
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: "Sortering och filtrering aktiv",
              description:
                "Den sparade sorteringen och filtreringen för tabellen används",
              action: {
                title: "Återställ sortering och filter",
                clicked: () => {
                  setSortBy([]);
                  setAllFilters([]);
                  clearCategories && clearCategories();
                },
                successObj: {
                  title: "Sortering och filter återställdes",
                },
              },
            })
          );
        } else if (withPersistantSortBy && initalSortBy?.length) {
          // inform about persistant sort by
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: "Sortering aktiv",
              description: "Den sparade sorteringen för tabellen används",
              action: {
                title: "Återställ sortering",
                clicked: () => {
                  setSortBy([]);
                },
                successObj: {
                  title: "Sortering återställdes",
                },
              },
            })
          );
        } else if (
          withPersistantSortBy &&
          (initialFilters?.length || persistantCategories?.length)
        ) {
          // inform about persistant filters
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: "Filtrering aktiv",
              description: "Den sparade filtreringen för tabellen används",
              action: {
                title: "Återställ filter",
                clicked: () => {
                  setAllFilters([]);
                  clearCategories && clearCategories();
                },
                successObj: {
                  title: "Filter återställdes",
                },
              },
            })
          );
        } else if (withPersistantGlobalFilter && initialGlobalFilter?.length) {
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: "Sökning aktiv",
              description: "Den sparade sökningen för tabellen används",
              action: {
                title: "Återställ sökning",
                clicked: () => {
                  setGlobalFilter("");
                },
                successObj: {
                  title: "Sökningen rensades",
                },
              },
            })
          );
        }
      }
    }, []);

    // persistant sort between remounts
    React.useEffect(() => {
      if (withPersistantSortBy) {
        localStorage.setItem(PERSISTANT_SORT_BY_KEY, JSON.stringify(sortBy));
      }
    }, [sortBy]);
    React.useEffect(() => {
      if (withPersistantSortBy) {
        localStorage.setItem(PERSISTANT_PAGE_KEY, JSON.stringify(pageIndex));
      }
    }, [pageIndex]);
    React.useEffect(() => {
      if (withPersistantSortBy) {
        localStorage.setItem(
          PERSISTANT_PAGE_SIZE_KEY,
          JSON.stringify(pageSize)
        );
      }
    }, [pageSize]);

    // persistant filters (not the same as the prop, this is for user filtered options persisted from last session)
    React.useEffect(() => {
      if (withPersistantSortBy) {
        localStorage.setItem(PERSISTANT_FILTERS_KEY, JSON.stringify(filters));
      }
    }, [filters]);

    // persistant global filter between remounts
    React.useEffect(() => {
      if (withPersistantGlobalFilter) {
        localStorage.setItem(
          PERSISTANT_GLOBAL_FILTER_KEY,
          globalFilter ? JSON.stringify(globalFilter) : ""
        );
      }
    }, [globalFilter]);

    /**
     * WILL BE CALLED WHEN ALL DATA HAS BEEN FETCHED (OR IS BEEING FETCHED)
     * FROM BACKEND, AND THE FRONTEND WILL HANDLE ALL FILTERING, ORDERING,
     * NO MORE ASYNC ACTIONS WILL BE PERFORMED
     */
    const setFrontendResponsibility = React.useCallback(() => {
      // we've fetched more items than will ever be shown here
      // just turn off manual, we don't even have to fetch the rest
      setIsManual(false);

      // settings this shit to manual means we have to make sure that
      // initial state is a copy of current state, since the table will be
      // completely reloaded
      setInitialState({ filters, sortBy, pageIndex, pageSize, globalFilter });
    }, [filters, sortBy, pageIndex, pageSize, globalFilter]);

    /**
     * CHECKS HOW MUCH DATA RELATED TO THIS TABLE WE HAVE
     * takes out all queries stored in state.paginations and state.filtered
     * which are related to the persistantQueryString, and can therefore be in this table
     * It returns the count of those elements
     */
    const checkRelatedQueries = React.useCallback(() => {
      // check all filters and all paginations, with keys including
      // our persistantQueryString, these together make up candidates that
      // can possibly populate the entire table, at maximum capacity

      const state = store.getState()[storeName];
      // undefined -> ""
      const formattedQueryString = persistantQueryString || "";

      // can't count duplicates
      let all = new Set();

      Object.keys(state.filtered).forEach((k) => {
        if (k.includes(formattedQueryString)) {
          all = new Set([...all, ...state.filtered[k]]);
        }
      });

      Object.keys(state.paginations).forEach((k) => {
        if (k.includes(formattedQueryString)) {
          all = new Set([...all, ...state.paginations[k].results]);
        }
      });

      return all.size;
    }, [persistantQueryString, storeName]);

    /**
     * WILL MAKE A LAST ASYNC CALL TO BACKEND, TO FETCH ALL DATA,
     * THIS WILL SET STATE VARIABLES TO INDICATE THAT NO MORE ASYNC CALLS SHOULD BE
     * MADE AFTER THIS
     */
    const delegateToFrontend = React.useCallback(
      (callback) => {
        const allObjects = store.getState()[storeName].all;

        const query = appendQueryString(
          persistantQueryString,
          buildQueryString({ "id__in!": Object.keys(allObjects) })
        );

        setFrontendResponsibility();
        dispatch(
          filterAction(query, (data) => {
            setShownItems(data.concat(Object.values(allObjects)));
            if (callback) {
              callback();
            }
          })
        );
      },
      [
        dispatch,
        filterAction,
        persistantQueryString,
        setFrontendResponsibility,
        storeName,
      ]
    );

    React.useEffect(() => {
      if (handleSelectedUpdated) handleSelectedUpdated(selectedFlatRows);
    }, [selectedFlatRows]);

    React.useEffect(() => {
      if (!isManual || !hasLoadedAll) {
        return;
      }
      // we've loaded all, set shownItems and set isManual
      setFrontendResponsibility();
      setShownItems(Object.values(store.getState()[storeName].all));
    }, [hasLoadedAll, isManual, setFrontendResponsibility, storeName]);

    /**
     * HANDLES CHANGES IN QUERIED ITEMS
     *
     * IF isManual IS FALSE, THIS WILL DO NOTHING
     * IF isManual IS TRUE, THIS WILL ALWAYS CHANGE THE DATA
     * IN THE TABLE WHEN TRIGGERED IF queriedItems FROM BACKEND
     * HAS CHANGED
     */
    React.useEffect(() => {
      if (!isManual || isEqual(queriedItemsRef.current, queriedItems)) {
        return;
      }

      const results = queriedItems?.results || [];
      const data = splitItems(results, pageIndex, pageSize);

      // some other things that depen on queriedItems
      const itemCount = queriedItems?.count || 0;
      const currentPageCount = Math.ceil(itemCount / pageSize);
      if (currentPageCount !== pageCount) {
        setPageCount(currentPageCount);
      }

      // if the queryString is the initialQuerystring
      // then we know the absolut max amount of items that can be used in table
      // if max amount - amount in all is below treshold
      // we fetch all fucking data and set manual to false
      // to let the table take over all filtering, ordering etc.
      let currentMaxItemCount = maxItemCount;
      if (
        queryString === initialQueryString &&
        itemCount !== maxItemCount &&
        isManual
      ) {
        // we'll want to know what the maximum possible amount of items is
        currentMaxItemCount = itemCount;
        setMaxItemCount(currentMaxItemCount);
      }

      if (currentMaxItemCount && isManual && results.length && !blockAllMode) {
        const allData = store.getState()[storeName].all;

        // the diff is somewhat inacurrate, since it uses all values
        // in state.all, which can be populated by completely different
        // queries, by other components, and so we also use specificDiff
        // which compares to this result, if it may e.g be the result of initialQueryString

        const diff = currentMaxItemCount - checkRelatedQueries();

        if (diff <= 0) {
          setFrontendResponsibility();
          setShownItems(Object.values(allData));
        } else if (fetchAllTreshold !== undefined && diff < fetchAllTreshold) {
          // this method will call setFrontendResponsibility();
          delegateToFrontend();
        } else {
          setAllItems(results);
          setShownItems(data);
        }
      } else {
        setAllItems(results);
        setShownItems(data);
      }

      queriedItemsRef.current = queriedItems;
    }, [
      queriedItems,
      pageCount,
      isManual,
      fetchAllTreshold,
      initialQueryString,
      pageIndex,
      pageSize,
      queryString,
      splitItems,
      storeName,
      maxItemCount,
      setFrontendResponsibility,
      delegateToFrontend,
      checkRelatedQueries,
    ]);

    const pageRef = React.useRef(pageIndex);
    const pageSizeRef = React.useRef(pageSize);

    /**
     * HANDLE CHANGES IN FILTERING, PAGINATION, SORTING ETC. WHICH
     * CHANGES THE QUERYSTRING
     *
     * IF isManual IS FALSE, THIS WILL DO NOTHING
     * IF isManual IS TRUE, THIS WILL TRIGGER
     * A CHANGE OF THE queryString WHICH IN TURN TRIGGERS
     * AN ASYNC REQUEST TO BACKEND -> GET'S SET INTO PROGRESS -> LOADING STARTS
     * THIS WILL AFTER LOADING TRIGGER THE useEffect THAT HANDLES
     * CHANGES IN QUERIED ITEMS
     */
    React.useEffect(() => {
      if (!isManual) {
        return;
      }
      let query = persistantQueryString;

      if (forceFrontendOnGlobalSearch && globalFilter) {
        // this is relevant if we e.g allow for global filtering on ALL fields
        // and some of the fields doesn't support searching on backend
        delegateToFrontend();
        return;
      }

      if (forceFrontendColumns && forceFrontendColumns.length) {
        if (
          Object.values(filters).some((f) =>
            forceFrontendColumns.includes(f.id)
          ) ||
          Object.values(sortBy).some((s) => forceFrontendColumns.includes(s.id))
        ) {
          // some columns can't be filtered/ordered on frontend, which means
          // we have to delegeate all responsibility to frontend, immediately
          delegateToFrontend();
          return;
        }
      }

      const filterQs = prepareFilters(filters, filterInstructions);
      if (filterQs) {
        query = appendQueryString(query, filterQs);
      }

      if (globalFilter) {
        query = appendQueryString(
          query,
          buildQueryString({ search: globalFilter })
        );
      }

      const orderQs = prepareOrdering(sortBy);
      if (orderQs) {
        query = appendQueryString(query, orderQs);
      }

      const paginationQs = paginatedQs(pageIndex, pageSize);
      if (paginationQs) {
        query = appendQueryString(query, paginationQs);
      }

      if (queryString !== query) {
        query = query.replaceAll("user__", "");
        setQueryString(query);
      } else if (
        pageIndex !== pageRef.current ||
        pageSize !== pageSizeRef.current
      ) {
        // querystring didn't change, however, pageIndex and pageSize
        // changes may imply a change in shown items
        setShownItems(splitItems(allItems, pageIndex, pageSize));

        // this may also imply a change in amount of pages
        // page count will change with the quota of prev pageSize
        const count = queriedItems?.count;
        if (count !== undefined) {
          setPageCount(Math.ceil(count / pageSize));
        }
      }

      pageRef.current = pageIndex;
      pageSizeRef.current = pageSize;
    }, [
      filters,
      sortBy,
      pageIndex,
      pageSize,
      isManual,
      paginatedQs,
      filterInstructions,
      queryString,
      persistantQueryString,
      allItems,
      splitItems,
      delegateToFrontend,
      forceFrontendColumns,
      globalFilter,
      forceFrontendOnGlobalSearch,
      pageCount,
      initialPageSize,
      queriedItems,
    ]);

    React.useEffect(() => {
      // hidden columns from local state
      const defaultHiddenC = localStorage
        .getItem(localStorageHiddenColumnId)
        ?.split(",");

      if (defaultHiddenC && defaultHiddenC?.length) {
        setHiddenColumns(defaultHiddenC);

        const shown = columns.filter(
          (c) =>
            !(
              defaultHiddenC.includes(c.id) ||
              defaultHiddenC.includes(c.accessor)
            )
        );
        setInternalShownColumns(shown.map((s) => s.id || s.accessor));
      }
    }, [visibleColumns]);

    const parseForExport = () => {
      try {
        performTableToBookExcelExport({
          element: tableRef.current,
          fileName: "Pigello_Fastighetssystem_Export",
          userStr: user,
        });

        dispatch(
          addToast({
            title: "Exporten slutförd",
            description: "Filen hämtas automatiskt",
            type: TOAST_TYPES.INFO,
          })
        );
      } catch (e) {
        console.error(e);
        dispatch(
          addToast({
            title: "Något gick fel",
            description: "Exporten kunde inte utföras",
            type: TOAST_TYPES.ERROR,
          })
        );
      }
    };

    const onExport = () => {
      parseForExport();
    };

    return (
      <>
        {title && (
          <div style={{ color: "#0f0f0f", fontWeight: "500" }}>{title}</div>
        )}

        {(!hideSearch || !hideExport) && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: externalFilters ? "24px 0 0 0" : "24px 0",
            }}
          >
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  flex: 1,
                }}
              >
                {!hideSearch && (
                  <GlobalSearchBox
                    extraStyles={{
                      minWidth: "400px",
                      marginRight: "12px",
                      maxWidth: "500px",
                    }}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    preventAutoFocus={preventAutoFocus}
                  />
                )}

                {!hideFilters && (
                  <SecondaryButton
                    extraStyle={{
                      marginRight: "6px",
                      borderColor: "#006BB4",
                      color: "#006BB4",
                    }}
                    title={toggleFilter ? "Visa filter" : "Dölj filter"}
                    clicked={() => setToggleFilter(!toggleFilter)}
                  />
                )}

                {!hideColumns && (
                  <SecondaryButton
                    extraStyle={{ borderColor: "#006BB4", color: "#006BB4" }}
                    title={`Välj kolumner (${internalShownColumns.length}/${columns?.length})`}
                    clicked={() => setColumnModalOpen(true)}
                  />
                )}
              </div>

              {/* <CreateNewButton title="Lägg till" clicked={toggleFormCallback} /> */}
              {!hideExport && (
                <ExportExcel
                  title="Exportera"
                  onClick={isFetching ? undefined : onExport}
                />
              )}
            </>
          </div>
        )}

        {externalFilters && externalFilters}

        {cleanedItems?.length || fullscreenModeActive ? (
          <SC.OuterWrapper
            {...{
              isNotificationsTable,
              fullscreenModeActive,
              withExternalFilters: !!externalFilters,
            }}
          >
            {withFullScreen && (
              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: "100%",
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                  borderColor: "rgba(0,0,0,.2)",
                  borderWidth: "thin",
                  padding: "10px 1px",
                  backgroundColor: "#5165FB",
                }}
              >
                <TextButton
                  inverted
                  iconType="launch"
                  iconPlacement="right"
                  clicked={() => setFullscreenModeActive(true)}
                />
              </div>
            )}
            <StandardModal
              isOpen={columnModalOpen}
              closeFunction={() => setColumnModalOpen(false)}
              withActionBar
              title="Välj synliga kolumner"
              actionBarCancelTitle="Stäng"
            >
              <FormAreaDescription>
                Välj vilka kolumner som ska visas i tabellen.
              </FormAreaDescription>
              <ColumnSelector
                value={internalShownColumns}
                setValue={setInternalShownColumns}
                listDefs={columns}
                setHidden={setHiddenColumns}
                localStorageHiddenColumnId={localStorageHiddenColumnId}
              />
            </StandardModal>

            {toggleFormCallback && (
              <SC.TableActionsWrapper>
                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {toggleFormCallback && (
                    <CreateNewButton
                      title={`${formCallbackLabel || "Lägg till"}`}
                      clicked={toggleFormCallback}
                    />
                  )}
                  <SC.RefreshButton
                    style={{ marginLeft: "24px" }}
                    onClick={() => refreshData()}
                  />
                </div>
              </SC.TableActionsWrapper>
            )}

            {fullscreenModeActive && (
              <SC.FullScreenMenu
                {...{ withExternalFilters: !!externalFilters }}
              >
                <SC.InnerFullScreenMenu>
                  {!hideSearch && (
                    <GlobalSearchBox
                      extraStyles={{
                        minWidth: "400px",
                        marginRight: "12px",
                        maxWidth: "500px",
                      }}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  )}
                  {!hideFilters && (
                    <SecondaryButton
                      extraStyle={{
                        marginRight: "6px",
                        borderColor: "#006BB4",
                        color: "#006BB4",
                      }}
                      title={toggleFilter ? "Visa filter" : "Dölj filter"}
                      clicked={() => setToggleFilter(!toggleFilter)}
                    />
                  )}

                  {!hideColumns && (
                    <SecondaryButton
                      extraStyle={{ borderColor: "#006BB4", color: "#006BB4" }}
                      title={`Välj kolumner (${internalShownColumns.length}/${columns?.length})`}
                      clicked={() => setColumnModalOpen(true)}
                    />
                  )}

                  {!hideExport && (
                    <ExportExcel
                      extraStyle={{ marginLeft: "6px" }}
                      title="Exportera"
                      onClick={isFetching ? undefined : onExport}
                    />
                  )}

                  <CloseCircleButton
                    style={{
                      position: "relative",
                      top: 0,
                      right: 0,
                      marginLeft: "auto",
                    }}
                    onClick={() => setFullscreenModeActive(false)}
                  />
                </SC.InnerFullScreenMenu>

                {externalFilters && (
                  <SC.InnerFullScreenMenu style={{ marginTop: -12 }}>
                    {externalFilters}
                  </SC.InnerFullScreenMenu>
                )}
              </SC.FullScreenMenu>
            )}

            <SC.TableWrapper
              {...{
                fullscreenModeActive,
                withExternalFilters: !!externalFilters,
              }}
              ref={tableWrapperRef}
            >
              <SC.Table
                ref={tableRef}
                {...{ isNotificationsTable, fullscreenModeActive }}
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className={fullscreenModeActive ? "fullscreen" : ""}
                          {...column.getHeaderProps()}
                        >
                          <div {...column.getSortByToggleProps()}>
                            {column.render("Header")}
                            {/** Sorting */}
                            {column.canSort ? (
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            ) : null}
                          </div>

                          {/* Filtering */}
                          {!toggleFilter ? (
                            <SC.FilterWrapper>
                              {column.canFilter && !hideFilters
                                ? column.render("Filter")
                                : null}
                            </SC.FilterWrapper>
                          ) : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();
                    return (
                      <React.Fragment key={rowProps.key}>
                        <tr
                          {...rowProps}
                          className={
                            checkRowHighlighted && checkRowHighlighted(row)
                              ? "active-row"
                              : ""
                          }
                        >
                          {row.cells.map((cell) => {
                            const props = cell.getCellProps();
                            return (
                              <td
                                {...props}
                                onClick={
                                  !props.key.includes("__expander")
                                    ? () => handleRowClicked(row)
                                    : undefined
                                }
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                        {!!renderRowSubComponent && row.isExpanded ? (
                          <tr>
                            <td
                              style={{
                                padding: 0,
                                backgroundColor: subRowComponentBackgroundColor,
                              }}
                              colSpan={visibleColumns.length}
                            >
                              {renderRowSubComponent({
                                row,
                                tableWidth:
                                  tableWrapperRef?.current?.offsetWidth,
                              })}
                            </td>
                          </tr>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </SC.Table>
            </SC.TableWrapper>
          </SC.OuterWrapper>
        ) : isFetching ? (
          <SC.TableLoadingWrapper>
            <OverlaySpinner />
          </SC.TableLoadingWrapper>
        ) : (
          <SC.TableEmptyWrapper>
            <SC.TableEmptyText>
              Det verkar inte finnas någon data att visa här än
            </SC.TableEmptyText>
            {toggleFormCallback && (
              <CreateNewButton title="Lägg till" clicked={toggleFormCallback} />
            )}
          </SC.TableEmptyWrapper>
        )}

        {!hidePagination && pageOptions?.length > 0 && (
          <Pagination
            {...{
              canPreviousPage,
              canNextPage,
              pageOptions,
              pageCount,
              gotoPage,
              nextPage,
              previousPage,
              setPageSize,
              pageIndex,
              pageSize,
              fullscreenModeActive,
            }}
          />
        )}
      </>
    );
  }
);
