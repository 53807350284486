import * as React from "react";

export default () => {
  return (
    <div>
      Specifiera inställningar för avisering av avtalet.
      <br />
      <br />I första hand kommer inställningen som är vald att användas. Om
      attribut skriver över inställningen som är vald kommer att dessa att
      användas istället.
    </div>
  );
};
