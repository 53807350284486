import Cancelation from "./Cancelation";
import Attachments from "./Attachments";
import Document from "./Document";
import Confirm from "./Confirm";

export default {
  CANCELATION: Cancelation,
  ATTACHMENTS: Attachments,
  DOCUMENT: Document,
  CONFIRM: Confirm,
};
