import * as services from "./services";
import constants from "./constants";
import { constants as apartmentConstants } from "../../apartments";
import { constants as indpConstants } from "../../industrialPremises";
import { store } from "../../store";
import {
  clearFetched,
  get,
  getAllFetchProcessName,
  getFiltered,
  getPagination,
  getSingleFetchProcessName,
} from "../../base";
import { cloneDeep } from "lodash";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const performApartmentUpdate = ({
  successCallback,
  errorCallback,
  retroActivePeriods,
  retroActiveCappedForContract,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const increases = state[constants.STORE_NAME].increases;

      const converted = {
        options: {
          month_amount: parseInt(retroActivePeriods),
          limit_on_contract_end: retroActiveCappedForContract,
        },
      };

      increases.forEach((i) => {
        converted[i.id] = {
          quota: parseFloat(i.quota),
          apply_from: i.apply_from,
          month_amount: retroActivePeriods,
          limit_on_contract_end: retroActiveCappedForContract,
        };
      });

      // make call
      await services.performApartmentUpdate({ body: converted });

      dispatch(clearFetched(apartmentConstants, true));

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e);
    }
  };
};

export const performIndpUpdate = ({
  successCallback,
  errorCallback,
  retroActivePeriods,
  retroActiveCappedForContract,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const increases = state[constants.STORE_NAME].indpIncreases;

      const converted = {
        options: {
          month_amount: parseInt(retroActivePeriods),
          limit_on_contract_end: retroActiveCappedForContract,
        },
      };

      increases.forEach((i) => {
        converted[i.id] = {
          quota: parseFloat(i.quota),
          apply_from: i.apply_from,
        };
      });

      // make call
      await services.performIndpUpdate({ body: converted });

      dispatch(clearFetched(indpConstants, true));

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e);
    }
  };
};

export const addApartment = ({ apartment }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.ADD_APARTMENT,
      payload: {
        apartment,
      },
    });
  };
};

export const addMultipleApartments = ({ apartments }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.ADD_MULTIPLE_APARTMENTS,
      payload: {
        apartments,
      },
    });
  };
};

export const removeApartment = ({ apartment }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_APARTMENT,
      payload: {
        apartment,
      },
    });
  };
};

export const setApartmentIncreases = ({ newIncreases }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_APARTMENT_INCREASES,
      payload: {
        newIncreases,
      },
    });
  };
};

export const setApartmentIncreaseDateForId = ({ id, val }) => {
  return async (dispatch) => {
    const increases = cloneDeep(store.getState().rentIncrease.increases);

    const cur = increases.find((i) => i.id === id);

    cur.apply_from = val;

    dispatch({
      type: constants.SET_APARTMENT_INCREASES,
      payload: {
        newIncreases: increases,
      },
    });
  };
};

export const getApartmentIncreases = (conf = {}) => {
  const { query } = conf;
  return async (dispatch) => {
    const increases = await services.getRentIncreases(query);

    dispatch({
      type: constants.SET_CURRENT_APARTMENT_INCREASES,
      payload: {
        increases,
      },
    });
  };
};

export const addIndp = ({ indp }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.ADD_INDP,
      payload: {
        indp,
      },
    });
  };
};

export const addMultipleIndps = ({ indps }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.ADD_MULTIPLE_INDPS,
      payload: {
        indps,
      },
    });
  };
};

export const removeIndp = ({ indp }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_INDP,
      payload: {
        indp,
      },
    });
  };
};

export const setIndpIncreases = ({ newIncreases }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_INDP_INCREASES,
      payload: {
        newIncreases,
      },
    });
  };
};

export const setIndpIncreaseDateForId = ({ id, val }) => {
  return async (dispatch) => {
    const increases = cloneDeep(store.getState().rentIncrease.increases);

    const cur = increases.find((i) => i.id === id);

    cur.apply_from = val;

    dispatch({
      type: constants.SET_INDP_INCREASES,
      payload: {
        newIncreases: increases,
      },
    });
  };
};

export const getIndpIncreases = (conf = {}) => {
  const { query } = conf;
  return async (dispatch) => {
    const increases = await services.getRentIncreases(query);

    dispatch({
      type: constants.SET_CURRENT_INDP_INCREASES,
      payload: {
        increases,
      },
    });
  };
};
