import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
} from "../../base";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
} from "../store/actions";
import constants from "../store/constants";

export function useOutlookCalendars(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };

  return useObjects(params);
}
