import constants from "./constants";
import * as services from "./services";
import { store } from "../../store";
import { getFiltered } from "../../base";
import { addToast, TOAST_TYPES } from "../../toasts";

export const getIsHomeQAuthenticated = () => {
  return async (dispatch) => {
    const state = store.getState();
    const inProgress = state[constants.STORE_NAME].authCheckInProgress;

    if (inProgress) return;

    try {
      dispatch({
        type: constants.SET_AUTH_CHECK_IN_PROGRESS,
        payload: {
          inProgress: true,
        },
      });

      const res = await services.checkActivated();

      const authed = res.data.is_activated;

      dispatch({
        type: constants.SET_AUTHENTICATED,
        payload: {
          authenticated: authed,
        },
      });
    } catch (e) {
      dispatch({
        type: constants.SET_AUTHENTICATED,
        payload: {
          authenticated: false,
        },
      });
    }
  };
};

export const authenticateHomeQ = ({
  username,
  password,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const authObj = {
        username,
        password,
      };

      const { data } = await services.authenticate(authObj);

      dispatch({
        type: constants.SET_AUTHENTICATED,
        payload: {
          authenticated: true,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      console.log(e);
      dispatch({
        type: constants.SET_AUTHENTICATED,
        payload: {
          authenticated: false,
        },
      });
      errorCallback && errorCallback(e);
    }
  };
};

export const updateAuthHomeQ = ({
  username,
  password,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const authObj = {
        username,
        password,
      };

      const { data } = await services.updateAuth(authObj);

      dispatch({
        type: constants.SET_AUTHENTICATED,
        payload: {
          authenticated: true,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      console.log(e);
      dispatch({
        type: constants.SET_AUTHENTICATED,
        payload: {
          authenticated: false,
        },
      });
      errorCallback && errorCallback(e);
    }
  };
};

export const deleteAuthHomeQ = ({ successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      const { data } = await services.inactivateAuth();

      dispatch({
        type: constants.SET_AUTHENTICATED,
        payload: {
          authenticated: false,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      console.log(e);

      errorCallback && errorCallback(e);
    }
  };
};

export const syncHomeQ = ({ postData, successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      const { data } = await services.syncHomeQ(postData);

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const collectHomeQ = ({ postData, successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      const { data } = await services.collectHomeQ(postData);

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const getRejectionReasons = () => {
  return async (dispatch) => {
    try {
      const { reasons } = await services.getRejectionReasons();

      dispatch({
        type: constants.SET_REJECTION_REASONS,
        payload: {
          rejectionReasons: reasons,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta anledningar till nekande",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );
    }
  };
};

export const getAvailableProjects = () => {
  return async (dispatch) => {
    try {
      const projects = await services.getProjects();
      dispatch({
        type: constants.SET_PROJECTS,
        payload: {
          projects,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta projekt från HomeQ",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );
    }
  };
};
