import moment from "moment";

export const getComponentsByRooms = ({ components, premisesRooms = [] }) => {
  if (!components?.length && !premisesRooms?.length) return {};

  // structure:
  // roomId: {
  //  ...room,
  //  components: []
  //}
  const rooms = {};

  let i = 0;
  for (i; i < components.length; i++) {
    const current = components[i];
    const nonRoomContentId =
      current.parking_spot?.id ||
      current.building?.id ||
      current?.realestate?.id;

    if (rooms[current.room?.id]) {
      rooms[current.room.id].components.push(current);
    } else if (rooms[`nonroom-${nonRoomContentId}`]) {
      rooms[`nonroom-${nonRoomContentId}`].components.push(current);
    } else {
      if (current.parking_spot || current.building || current.realestate) {
        rooms[`nonroom-${nonRoomContentId}`] = {
          ...(current.parking_spot || current.building || current.realestate),
          str_representation: current.parking_spot
            ? "Fordonsplats"
            : current.building
            ? "Byggnad"
            : "Fastighet",
          components: [current],
        };
      } else {
        rooms[current.room.id] = {
          ...current.room,
          components: [current],
        };
      }
    }
  }

  let k = 0;

  for (k; k < premisesRooms.length; k++) {
    const current = premisesRooms[k];

    if (!rooms[current.id]) {
      rooms[current.id] = {
        ...current,
        components: [],
      };
    }
  }

  return rooms;
};

export const getCurrentProductTypeUsage = (component) => {
  if (!component) return null;
  const current = component.product_types?.reduce((acc, cur) => {
    if (!acc) return cur;

    if (moment(cur.from_date).isAfter(moment(acc.from_date))) return cur;

    return acc;
  }, null);

  return current;
};

// Returns a sorted array of product usages and ratings with TYPE attr, for component history overview
export const getSortedComponentHistory = ({ component, ratings }) => {
  if (!component && !ratings) return [];

  const productUsages = component?.product_types;

  const combined = [
    ...(productUsages?.map((p) => ({ ...p, type: "PRODUCT" })) || []),
    ...(ratings?.map((r) => ({ ...r, type: "RATING" })) || []),
  ];

  return combined.sort((a, b) => {
    return moment(a.from_date || a.created_at).isBefore(
      b.from_date || b.created_at
    )
      ? 1
      : -1;
  });
};

export const getInspectionErrandChecklistIdForComponent = (component) => {
  const currentProductTypeUsage = getCurrentProductTypeUsage(component);

  if (currentProductTypeUsage?.product_type?.inspection_errand_checklist) {
    return currentProductTypeUsage?.product_type.inspection_errand_checklist.id;
  } else if (
    currentProductTypeUsage?.product_type?.component_type
      ?.inspection_errand_checklist
  ) {
    return currentProductTypeUsage?.product_type.component_type
      .inspection_errand_checklist.id;
  }

  return null;
};

export const convertedStatus = ({
  status,
  plannedStart,
  plannedEnd,
  actualStart,
  actualEnd,
}) => {
  const now = moment();
  let start;
  let end;
  let saidStart;
  let saidEnd;
  switch (status) {
    case -1:
      return -1; // aborted
    case 0:
      start = moment(plannedStart);
      end = moment(plannedEnd);

      if ((start.isValid() && now > start) || (end.isValid() && now > end)) {
        return 0; // delayed
      }
      return 1; // not started
    case 1:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);

      if (end.isValid() && now > end) {
        return 2; // started, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 3; // started, delayed start
      }

      return 4;
    case 2:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);

      if (end.isValid() && now > end) {
        return 5; // paused, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 6; // paused, delayed start
      }

      return 7;
    case 3:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);
      saidEnd = moment(actualEnd);

      if (end.isValid() && saidEnd.isValid() && saidEnd > end) {
        return 8; // done, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 9; // done, delayed start
      }

      return 10;
    case 4:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);
      saidEnd = moment(actualEnd);

      if (end.isValid() && saidEnd.isValid() && saidEnd > end) {
        return 11; // done tenant, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 12; // done tenant, delayed start
      }

      return 13; // done
    default:
      return 14; // unknown
  }
};

const COLOR_STATUS_MAP = {
  "-1": 5,
  0: 4,
  1: 6,
  2: 3,
  3: 3,
  4: 3,
  5: 2,
  6: 2,
  7: 2,
  8: 1,
  9: 1,
  10: 1,
  11: 5,
  12: 5,
  13: 5,
};
export const getErrandStatus = (errand) => {
  if (!errand)
    return { status: 14, colorStatus: COLOR_STATUS_MAP[14], display: "Okänd" };
  const {
    status,
    planned_start: plannedStart,
    planned_end: plannedEnd,
    execute_start: actualStart,
    execute_end: actualEnd,
  } = errand;
  const actualStatus = convertedStatus({
    status,
    plannedStart,
    plannedEnd,
    actualStart,
    actualEnd,
  });
  const getDisplay = () => {
    switch (actualStatus) {
      case -1:
        return "Avbryten";
      case 0:
        return "Försenad";
      case 1:
        return "Ej påbörjad";
      case 2:
        return "Påbörjad (försenad)";
      case 3:
        return "Påbörjad (försenad)";
      case 4:
        return "Påbörjad";
      case 5:
        return "Pausad (försenad)";
      case 6:
        return "Pausad (försenad)";
      case 7:
        return "Pausad";
      case 8:
        return "Avklarad (försenad)";
      case 9:
        return "Avklarad (försenad)";
      case 10:
        return "Avklarad";
      case 11:
        return "Avklarat av hyresgäst (försenad)";
      case 12:
        return "Avklarat av hyresgäst (försenad)";
      case 13:
        return "Avklarat av hyresgäst";
      default:
        return "Okänd";
    }
  };
  return {
    status: actualStatus,
    colorStatus: COLOR_STATUS_MAP[actualStatus],
    display: getDisplay(),
  };
};
