import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs, { getDetailUrl, getErrorSource } from "./listDefs";

import { constants } from "../../../store/invoicingErrors";
import { useHistory } from "react-router";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useDispatch } from "react-redux";

export default function InvoicingErrorTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const handleRowClicked = (row) => {
    const objectInitiatorObject = row.original.initiator_object;
    const objectIntitatorContentType = row.original.initiator_content_type;

    // equals "Angår objekt" in listdefs
    const objectDetailUrlMethod = getDetailUrl(objectIntitatorContentType);

    const error = row.original.error;

    const errorSource = getErrorSource(error);

    const sourceInitiatorObject = errorSource?.object_id
      ? { id: errorSource.object_id, str_representation: "Gå till källa" }
      : null;
    const sourceIntitatorContentType = errorSource?.content_type;

    // equals "Källa till fel" in listdefs
    const errorSourceDetailUrlMethod = getDetailUrl(sourceIntitatorContentType);

    if (errorSourceDetailUrlMethod && sourceInitiatorObject) {
      push(errorSourceDetailUrlMethod({ id: sourceInitiatorObject.id }));
    } else if (objectDetailUrlMethod && objectInitiatorObject) {
      push(objectDetailUrlMethod({ id: objectInitiatorObject.id }));
    } else {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Källa till fel kunde inte hittas",
          description: "Kontakta Pigello för att få hjälp med ärendet",
        })
      );
    }
  };

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "error_invoicing_full_table"}
      title={"Faktureringsfel"}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked: handleRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
