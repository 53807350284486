import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./HomeQListDefs";

// store, state
import { constants, detailUrl } from "../../../store/apartments";

export default function HomeQApartmentTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  // const exportParser = {
  //   state: (data) => data.state_display,
  //   category: (data) => data.category_display,
  // };

  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
      title="Lägenheter"
      tableId={tableId || "homeq_apartments_full_table"}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
