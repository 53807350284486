import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveFormInstance } from "../../../store/base";
import { useUserForm, constants, update } from "../../../store/users";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import Pdf from "../../Pdf/Pdf";

export default () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state) => state.app?.user);

  useUserForm("PATCH", user?.id);

  React.useEffect(() => {
    if (user && user.terms_accepted_date === null) {
      setOpen(true);
    }
  }, [user]);

  const successCallback = () => {
    setLoading(false);
    setOpen(false);
  };
  const errorCallback = () => {
    // fail silently
    setLoading(false);
    setOpen(false);
  };

  const acceptTerms = () => {
    setLoading(true);
    const data = {
      terms_accepted_date: moment().format("YYYY-MM-DD HH:mm"),
    };

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data,
      })
    );

    dispatch(update({ id: user.id, successCallback, errorCallback }));
  };

  return (
    <StandardModal
      high
      large
      isOpen={open}
      withActionBar
      title="Villkor för användande av tjänst"
      actionBarAcceptTitle="Godkänn villkor"
      canCancel={false}
      canClose={false}
      noBottomPadding
      saveFunction={acceptTerms}
    >
      {loading && <OverlaySpinner />}
      <Pdf
        url={
          "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/public_media/Other/Terms_of_Service.pdf"
        }
      />
    </StandardModal>
  );
};
