export const constructStrRep = (obj) => {
  return obj?.str_representation || obj?.title || obj?.code;
};
export const detailUrl = ({ id }) => {
  return `/errand-settings/articles/detail/${id}`;
};

export const overviewUrl = () => {
  return `/errand-settings/articles`;
};

export const getPublicErrandArticleProcessName = (querystring) => {
  return `PUBLIC_ERRANDS_${querystring}`;
};
