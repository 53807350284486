import * as React from "react";
import { useParams } from "react-router-dom";
import { buildQueryString } from "../../../store/base";
import { usePipe } from "../../../store/pipes";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { DetailPageBox } from "../../sharedStyles";
import ApartmentsTable from "src/components/Tables/Apartments/FullTableNewVersion";
import { useFilteredApartmentAds } from "../../../store/marketApartmentAds";

export default function NewProduction() {
  const { pipeId } = useParams();

  const [pipe, pipeLoading] = usePipe(pipeId);

  const adsQ = buildQueryString({
    dump_in_pipe: pipeId,
  });

  const [apartmentAdsInPipe, apartmentAdsInPipeLoading] =
    useFilteredApartmentAds(adsQ);

  const apartmentQ = {
    realestate_ids: pipe?.realestate?.id,
  };

  return (
    <DetailPageBox
      style={{
        borderRadius: 0,
        marginTop: 0,
        minHeight: window.innerHeight,
      }}
    >
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Nyproduktion i fastigheten</OverviewTitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {!apartmentAdsInPipeLoading && !pipeLoading && (
        <ApartmentsTable
          {...{
            persistantQuery: apartmentQ,
          }}
          ads={apartmentAdsInPipe}
          withCosts={false}
          tableId="new_prod_table"
        />
      )}
    </DetailPageBox>
  );
}
