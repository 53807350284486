import * as React from "react";

import { constants } from "../../../../../store/reportErrandSettings";

import { Checkbox } from "../../../Base/Fields";
import { useFormInstanceField } from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const billingActive = useFormInstanceField({
    storeName,
    fieldKey: "use_billing",
  });
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Fakturering</OverviewTitle>
          <OverviewSubtitle>
            Fyll i om fakturering ska användas för denna ärendetyp. Fyll i bolag
            och faktureringsinställning
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <Checkbox
        storeName={storeName}
        method={method}
        fieldKey="use_billing"
        title="Använd fakturering"
        description="Bocka i om fakturering ska användas på ärenden som är skapade med denna felanmälningstyp"
      />

      {billingActive && (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              method={method}
              fieldKey={"default_billing_company"}
              persistantQuery={{}}
              placeholder="Välj bolag..."
              description={
                "Välj det bolag som ska fakturera när en faktura skapas efter ett ärende."
              }
              title={"Välj bolag som fakturerar*"}
              TableComponent={CompanyTable}
            />
          </div>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              method={method}
              fieldKey={"default_invoicing_setting"}
              persistantQuery={{}}
              placeholder="Välj inställning..."
              description={
                "Välj den inställning som ska användas när en faktura skapas efter ett ärende."
              }
              title={"Välj faktureringsinställning"}
              TableComponent={InvoicingSettingTable}
            />
          </div>
        </>
      )}
    </>
  );
};
