import * as React from "react";
import moment from "moment";

import { ToolTipCell } from "../../Displays";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import UnitFilter from "./Filters/UnitFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default () => [
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Artikelnr.",
    accessor: "code",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Aktiv",
    accessor: "is_active",
    Cell: ({ value }) => (
      <StatusLabel state={value ? 0 : 8}>{value ? "Ja" : "Nej"}</StatusLabel>
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Enhet",
    accessor: "unit",
    Cell: ({ row }) => <ToolTipCell text={row.original?.unit_display} />,
    Filter: UnitFilter,
    filter: "textExact",
  },
  {
    Header: "Kostnad per enhet",
    accessor: "cost_per_unit",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: NumberRangeFilter,
    filter: "between",
  },

  {
    Header: "Standard antal",
    accessor: "standard_unit_amount",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: NumberRangeFilter,
    filter: "between",
  },
];
