import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: "pcs", display: "St" },
    { value: "m2", display: "m2" },
    { value: "lpm", display: "Lpm" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
