import styled from "styled-components";
import closeIcon from "../../../assets/svg/close-circle.svg";

export const Wrapper = styled.div`
  padding: 12px;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterImages = styled.div`
  margin-right: 12px;
  height: 26px;
  min-width: calc(26px + ${(p) => p.spacerWidth}px);
  position: relative;
`;

export const FilterImage = styled.div`
  background-color: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  left: ${(p) => p.leftPosition}px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.8);
  border: thin solid ${(p) => p.theme.colors.gray3};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.src});
`;

export const Title = styled.div`
  color: #fff;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const ResetButton = styled.div`
  cursor: pointer;
  background-color: #fff;
  color: ${(p) => p.theme.colors.secondaryText};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 4px 26px 4px 14px;
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  position: relative;

  &:hover {
    background-color: ${(p) => p.theme.colors.gray1};
  }

  &:after {
    position: absolute;
    top: calc(50% - 7px);
    right: 8px;
    content: "";
    height: 14px;
    width: 14px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${closeIcon});
  }
`;
