import * as React from "react";
import { usePermissionCheck } from "../../../../store/base";
import { DetailPageBox } from "../../../sharedStyles";
import { OverviewTitle, OverviewTitleWrapper } from "../../OverviewInfo/styles";
import {
  constants,
  update,
  useServiceContractForm,
} from "../../../../store/serviceContracts";
import ManualSignForm from "../../../Forms/LeaseContract/ManualSignForm";

export default function ServiceContractManualSign({ contract }) {
  const canEdit = usePermissionCheck("change_can_contract");

  const formLoaded = Boolean(useServiceContractForm("PATCH", contract?.id));

  return (
    <DetailPageBox>
      <OverviewTitleWrapper>
        <OverviewTitle>Manuell signering</OverviewTitle>
      </OverviewTitleWrapper>

      <ManualSignForm
        id={contract?.id}
        canEdit={canEdit}
        {...{ constants, updateFunction: update, formLoaded }}
      />
    </DetailPageBox>
  );
}
