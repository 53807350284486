export default [
  {
    title: "Ingår i hyra",
    key: "INCLUDED",
    visited: true,
    hasError: false,
    fieldKeys: [
      "electricity_included",
      "internet_included",
      "heating_included",
      "cable_package_included",
      "water",
    ],
  },
  {
    title: "Bekvämligheter",
    key: "COMMODITIES",
    visited: false,
    hasError: false,
    fieldKeys: [
      "renovation_date",
      "elevator",
      "kitchen",
      "pantry",
      "patio",
      "handicap_adapted",
      "accessibility_adapted",
      "security_door",
      "laundry_room",
      "shower",
      "balcony",
      "kitchen_fan",
      "dishwasher",
      "washing_machine",
      "dryer",
      "bathtub",
      "bed_in_wall",
      "stove",
      "tiled_stove",
      "gas_cooker",
    ],
  },
  {
    title: "Parkering",
    key: "PARKING",
    visited: false,
    hasError: false,
    fieldKeys: [
      "included_parking_spots",
      "included_garage_spots",
      "available_garage_spots",
      "available_parking_spots",
    ],
  },
  {
    title: "Beskrivning",
    key: "DESCRIPTION",
    visited: true,
    hasError: false,
    fieldKeys: ["informational_description"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["apartment", "industrial_premises", "brf_premis"],
  },
];
