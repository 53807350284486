import * as React from "react";
import * as SC from "./styles";

/**
 * TOOLTIP
 *
 * Can be used either on hover with onMouseOver, onMouseLeave on parent component
 * or as an instruction that is open until closed. For this to work, canClose is set
 * to true and a persistant key is passed to save to local storage that is has
 * been dismissed.
 */
export default ({
  placement = "top",
  title,
  content,
  renderContent,
  closeText = "Dölj tips",
  canClose = false,
  persistantKey = null,
}) => {
  const toolTipRef = React.useRef();
  const layoutRef = React.useRef();

  const [show, setShow] = React.useState(true);
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);

  const setDimensions = () => {
    if (!toolTipRef.current) return;

    const h = toolTipRef.current.offsetHeight;
    const w = toolTipRef.current.offsetWidth;

    setWidth(w);
    setHeight(h);
  };

  const closeToolTip = (e) => {
    e.stopPropagation();
    localStorage.setItem(persistantKey, true);
    setShow(false);
  };

  React.useLayoutEffect(() => {
    if (toolTipRef.current) {
      setDimensions();
    }
  }, [toolTipRef.current]);

  React.useEffect(() => {
    window.addEventListener("resize", setDimensions);

    return () => {
      window.removeEventListener("resize", setDimensions);
    };
  }, []);

  React.useEffect(() => {
    if (persistantKey) {
      const pk = localStorage.getItem(persistantKey);
      if (pk) {
        setShow(false);
      }
    }
  }, []);

  if (!show) return null;

  return (
    <SC.LayoutWrapper ref={layoutRef}>
      <SC.ToolTipWrapper
        ref={toolTipRef}
        {...{ w: width, placement, h: height }}
      >
        {title && <SC.ToolTipTitle>{title}</SC.ToolTipTitle>}

        {renderContent && (
          <SC.ToolTipContent>{renderContent()}</SC.ToolTipContent>
        )}
        {content && <SC.ToolTipContent>{content}</SC.ToolTipContent>}

        {canClose && persistantKey && (
          <SC.ToolTipClose onClick={closeToolTip}>{closeText}</SC.ToolTipClose>
        )}
      </SC.ToolTipWrapper>
    </SC.LayoutWrapper>
  );
};
