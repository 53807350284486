import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import ArticleTable from "../../../components/Tables/ErrandArticles/FullTable";
import { useAnyPermissionCheck } from "../../../store/base";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ArticleForm from "../../../components/Forms/ErrandArticle/ChapterForm/ModalForm";

export default function Articles() {
  const [addArticleOpen, setAddArticleOpen] = React.useState(false);

  const canAddArticle = useAnyPermissionCheck([
    "add_can_reporterrand",
    "add_can_inspectionerrand",
    "add_can_roundingerrand",
    "add_can_goverrand",
  ]);

  return (
    <>
      <ArticleForm
        isOpen={addArticleOpen}
        onCheckout={() => setAddArticleOpen(false)}
        method={"POST"}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Artiklar</OverviewTitle>
              <OverviewSubtitle>
                Artiklar används för att koppla kostnader till ett avhjälpande.
                Artiklar ligger kopplade antingen till en komponenttyp eller en
                komponentmodell och kan i utförandet läggas till på ett ärende
                som en kostnad.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            {canAddArticle && (
              <PrimaryButton
                extraStyle={{ marginLeft: 20 }}
                title="Skapa artikel"
                clicked={() => setAddArticleOpen(true)}
              />
            )}
          </OverviewTitleWrapper>
          <ArticleTable />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
