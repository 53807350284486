import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function getLeadContractWarnings(ids) {
  const query = ids.join(`&ids=`);
  const res = await axiosInstance.get(
    `${constants.CONTRACT_WARNING_URL}?ids=${query}`
  );

  return res;
}

async function getLeadContractProposals(postData) {
  const res = await axiosInstance.post(
    constants.LEAD_CONTRACT_PROPOSAL_URL,
    postData
  );

  return res;
}

async function getLeadCostsProposals(postData, type) {
  const res = await axiosInstance.post(
    `${constants.LEAD_COST_PROPOSAL_URL}${
      type === "leasecontracts" ? "leasecontract" : "parkingcontract"
    }/`,
    postData
  );

  return res;
}

export {
  getLeadContractWarnings,
  getLeadContractProposals,
  getLeadCostsProposals,
};
