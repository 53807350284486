import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  useApartmentCommoditiesForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/marketApartmentCommodities";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";

export default ({ method, id, isOpen, onCheckout, instance }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useApartmentCommoditiesForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    // if (method === "POST") {
    //   push(detailUrl({ id: returned.id }));
    // }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!dataClone.available_parking_spots) {
      dataClone.available_parking_spots = 0;
    }
    if (!dataClone.available_garage_spots) {
      dataClone.available_garage_spots = 0;
    }
    if (!dataClone.included_parking_spots) {
      dataClone.included_parking_spots = 0;
    }
    if (!dataClone.included_garage_spots) {
      dataClone.included_garage_spots = 0;
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={
        method === "POST"
          ? "Lägg till annonsdetaljer"
          : "Uppdatera annonsdetaljer"
      }
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
