import * as React from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { updateActiveFormInstance } from "../../store/base";

import DigitalDocEditableFieldBase from "./Fields/EditableFieldBase";
import {
  FIELD_TYPES,
  getPageFromBoundsAndPosition,
  toExternalCoordinates,
  toInternalCoordinates,
  toInternalSize,
} from "./utils";

export default function DigitalDocDraggableField({
  storeName,
  placement,
  partyKey,
  fieldKey,
  placementKey,
  disabled,
  pageBounds,
  kind,
}) {
  const dispatch = useDispatch();
  const [canInteract, setCanInteract] = React.useState(true);
  const originalPageData = pageBounds[placement.page];

  if (!originalPageData) return null;

  const { x, y } = toInternalCoordinates({
    xRel: placement.xrel,
    yRel: placement.yrel,
    pageData: originalPageData,
  });

  // update the position of the field placement
  const onStop = (_, data) => {
    const { y, x } = data;

    const { page } = getPageFromBoundsAndPosition({
      pageBounds,
      y,
    });

    const newPageData = pageBounds[page];

    const { x: pageRelativeX, y: pageRelativeY } = toExternalCoordinates({
      y,
      x,
      page,
      pageData: newPageData,
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`${placementKey}.xrel`]: pageRelativeX,
          [`${placementKey}.yrel`]: pageRelativeY,
          [`${placementKey}.page`]: page,
        },
      })
    );

    setTimeout(() => setCanInteract(true), 200);
  };

  return (
    <Draggable
      axis="both"
      handle=".handle"
      // bounds={"#draggablebounds"}
      defaultPosition={{ x, y }}
      position={{ x, y }}
      grid={[1, 1]}
      scale={1}
      onStop={onStop}
      onDrag={() => setCanInteract(false)}
      cancel=".no-move"
    >
      <div
        className="absolute z-10 bg-transparent"
        style={{
          position: "absolute",
          fontFamily: "Arial",
          top: [FIELD_TYPES.RADIO, FIELD_TYPES.CHECKBOX].includes(kind)
            ? -toInternalSize({
                w: placement.wrel,
                h: placement.hrel,
                pageData: originalPageData,
              })?.h / 10
            : kind === FIELD_TYPES.SIGNATURE
            ? -toInternalSize({
                w: placement.wrel,
                h: placement.hrel,
                pageData: originalPageData,
              })?.h / 14
            : -toInternalSize({
                w: placement.wrel,
                h: placement.hrel,
                pageData: originalPageData,
              })?.h / 3,
        }}
      >
        <DigitalDocEditableFieldBase
          canInteract={canInteract}
          disabled={disabled}
          storeName={storeName}
          placement={placement}
          placementKey={placementKey}
          partyKey={partyKey}
          fieldKey={fieldKey}
          pageBounds={pageBounds}
        />
      </div>
    </Draggable>
  );
}
