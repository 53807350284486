import * as React from "react";
import constants from "../store/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getHandleInvoice,
  getInvoiceIdPreview,
  getDebtCollectionInfo,
} from "..";
import { useInProgress } from "../../base";
import { getReminderInvoice } from "../store/actions";

export const useInvoice = ({ invoiceId, creditorId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [notFound, setNotFound] = React.useState(false);

  if (!invoiceId || !creditorId)
    throw Error("Invoice id or creditor id missing");

  const currentInvoice = useSelector(
    (state) => state[storeName].currentInvoice
  );

  const inProgress = useInProgress({ storeName, name: invoiceId });

  if (notFound) return [undefined, false, true];

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (!currentInvoice || currentInvoice?.ActionPublicId !== invoiceId) {
    dispatch(
      getHandleInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        notFoundCallback: () => setNotFound(true),
      })
    );
    return [undefined, true];
  }

  return [currentInvoice, false];
};

export const useDebtCollectionAction = ({ debtCollectionId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const currentDebtInvoiceInfo = useSelector(
    (state) => state[storeName].currentDebtInvoiceInfo
  );

  const inProgress = useInProgress({
    storeName,
    name: `debtInfo_${debtCollectionId}`,
  });
  if (!debtCollectionId) return [undefined, false];

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (
    !currentDebtInvoiceInfo ||
    currentDebtInvoiceInfo?.ActionPublicId !== debtCollectionId
  ) {
    dispatch(getDebtCollectionInfo({ debtCollectionId }));
    return [undefined, true];
  }

  return [currentDebtInvoiceInfo, false];
};

// reminder invoice id is really only used to determine if there is a reminder invoice or not
export const useReminderInvoice = ({ reminderInvoiceId, invoiceId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const currentReminderInvoice = useSelector(
    (state) => state[storeName].currentReminderInvoice
  );

  const inProgress = useInProgress({
    storeName,
    name: `reminder_${reminderInvoiceId}`,
  });
  if (!reminderInvoiceId) return [undefined, false];

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (
    !currentReminderInvoice ||
    currentReminderInvoice?.SourceActionPublicId !== invoiceId
  ) {
    dispatch(getReminderInvoice({ reminderInvoiceId, invoiceId }));
    return [undefined, true];
  }

  return [currentReminderInvoice, false];
};

export const useInvoicePreview = ({ invoiceId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  if (!invoiceId) throw Error("Invoice id missing");

  const currentPreview = useSelector(
    (state) => state[storeName].currentInvoicePreview
  );

  const inProgress = useInProgress({ storeName, name: `preview_${invoiceId}` });

  if (inProgress) return [undefined, true];

  if (!currentPreview || currentPreview?.id !== invoiceId) {
    dispatch(getInvoiceIdPreview({ invoiceId }));
    return [undefined, true];
  }

  return [currentPreview, false];
};
