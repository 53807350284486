export default [
  {
    title: "Fortnox Koppling",
    key: "FORTNOX",
    visited: true,
    hasError: false,
    fieldKeys: [
      "company",
      "auth_code",
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
