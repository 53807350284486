import * as React from "react";
import theme from "../../../theme";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { DetailPageBox } from "../../sharedStyles";

export default function FinishedErrandStatusBar({ onReactivate, loading }) {
  return (
    <>
      <DetailPageBox style={{ backgroundColor: theme.colors.primaryBlue }}>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle style={{ color: "#fff" }} small>
              Detta ärende är avklarat
            </OverviewTitle>
            <OverviewSubtitle style={{ color: "#fff" }}>
              Tryck på "Återuppta ärende" för att aktivera ärendet igen
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div style={{ display: "flex" }}>
          <PrimaryButton
            extraStyle={{
              backgroundColor: theme.colors.red,
            }}
            title="Återuppta ärende"
            clicked={onReactivate}
          />
        </div>
      </DetailPageBox>
    </>
  );
}
