import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { addToProcess, buildQueryString } from "../../base";
import * as services from "./services";
import { constants } from "..";
import { search } from "../../billectaSearch/store/services";
import { removeFromProgress } from "../../base/store/actions";
import { downloadAccountingReport } from "../../billectaAccountingReports/store/actions";

export const getDashboardBillingReport = ({
  fromDate,
  toDate,
  creditorIds,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, "billing_report_dashboard");

      if (!fromDate || !toDate) throw Error();
      const query = buildQueryString({
        from: fromDate,
        to: toDate,
      });

      const promises = creditorIds.map((c) =>
        services.getBillingReport({ creditorId: c, query })
      );

      const resp = await Promise.all(promises);

      const data = resp.map((r) => r.data).flat();

      data.sort((a, b) => {
        const aDate = moment(a.InvoiceDate);
        const bDate = moment(b.InvoiceDate);

        return aDate.isAfter(bDate) ? 1 : bDate.isAfter(aDate) ? -1 : 0;
      });

      dispatch({
        type: constants.SET_DASHBOARD_BILLING_REPORT,
        payload: {
          data,
          creditorIds,
        },
      });

      removeFromProgress(dispatch, constants, "billing_report_dashboard");
    } catch (e) {}
  };
};

export const getDashboardDueDateReport = ({
  fromDate,
  toDate,
  creditorIds,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, "duedate_report_dashboard");

      if (!fromDate || !toDate) throw Error();

      const promises = creditorIds.map((c) => {
        const searchParams = {
          CreditorPublicId: c,
          DueDateFrom: fromDate,
          DueDateTo: toDate,
        };
        return search({ searchParams });
      });

      const resp = await Promise.all(promises);

      const data = resp.map((r) => r.InvoiceActions).flat();

      dispatch({
        type: constants.SET_DASHBOARD_DUEDATE_REPORT,
        payload: {
          data,
          creditorIds,
        },
      });

      removeFromProgress(dispatch, constants, "duedate_report_dashboard");
    } catch (e) {}
  };
};

const exportPaymentReportExcel = (data) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const wb = {
    Sheets: {
      Fakturabetalningar: XLSX.utils.json_to_sheet(data.ValuesOnActions),
      "Ohanterade betalningar": XLSX.utils.json_to_sheet(
        data.UnhandledPayments
      ),
    },
    SheetNames: ["Fakturabetalningar", "Ohanterade betalningar"],
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, "inbetalningsrapport" + fileExtension);
};

/**
 * 
IncomingPaymentsReportExportTypeView:
BgMax = 0,
TotalIN = 1,

IncomingPaymentReportPaymentTypeView:
Payments = 0,
Creditations = 1,
Overpayments = 2,
UnmatchedPayments = 3,
WriteOffs = 4,
Centroundings = 5,
ReminderPayments =6
 */

export const getAdvancedPaymentsReport = ({
  Creditors,
  From,
  To,
  Format,
  DateSelectionType,
  ResultSelectionType,
  Types,
  PaymentMeanCodes,
  quickview,
  exportExcel,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const params = JSON.stringify({
        Creditors,
        From: moment(From).format("YYYY-MM-DD"),
        To: moment(To).format("YYYY-MM-DD"),
        Types, // categories
        PaymentMeanCodes,
        Format, // export type, if is excel or quickview, this dosn't matter but need to be set to 1
        ProjectNumbers: [],
        DateSelectionType,
        ResultSelectionType,
      });

      const resp = await services.getPaymentsReport({
        params,
        exportFile: !quickview && !exportExcel,
      });

      const data = resp.data;

      if (quickview) {
        dispatch({
          type: constants.SET_ADVANCED_INCOMING_PAYMENTS,
          payload: {
            data,
          },
        });
      } else if (exportExcel) {
        exportPaymentReportExcel(data);
      } else {
        dispatch(
          downloadAccountingReport({
            fileId: data.FilePublicId,
            fileName: data.FileName,
          })
        );
      }

      successCallback && successCallback(data);
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const getDashboardPaymentsReport = ({
  creditorIds,
  fromDate,
  toDate,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, "payment_report_dashboard");
      const params = JSON.stringify({
        Creditors: creditorIds,
        From: fromDate,
        To: toDate,
        Format: 1,
        Types: [0, 1, 2, 3, 4, 5, 6],
        PaymentMeanCodes: [],
        ProjectNumbers: [],
      });

      const resp = await services.getPaymentsReport({
        params,
      });

      const data = resp.data;

      dispatch({
        type: constants.SET_INCOMING_DASHBOARD_PAYMENTS,
        payload: {
          data,
          creditorIds,
        },
      });

      removeFromProgress(dispatch, constants, "payment_report_dashboard");
    } catch (e) {}
  };
};
