import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import { constants } from "../../../../../store/invoicingSettings";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import {
  Checkbox,
  NumberInput,
  RadioGroup,
  Select,
  TextField,
  TextInput,
} from "../../../Base/Fields";

import DebtSettingSummary from "../../../../Summaries/DebtSettingSummary";
import listDefs from "../../../../Tables/InvoicingDebtSettings/listDefs";
import { TextButton } from "../../../Base/Buttons";
import { cloneDeep } from "lodash";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import { InvoicingDebtSetting } from "src/components/Forms/InvoicingSetting/ChapterForm/Chapters";
import InvoicingDebtSettingTable from "src/components/Tables/InvoicingDebtSettings/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [createNewActive, setCreateNewActive] = React.useState(false);

  const selectedDebtSetting = useFormInstanceField({
    storeName,
    fieldKey: "debt_setting",
  });

  const alwaysLastDay = useFormInstanceField({
    storeName,
    fieldKey: "debt_setting.contract_pay_last_day_month",
  });

  const useReminders = useFormInstanceField({
    storeName,
    fieldKey: "debt_setting.use_reminders",
  });

  const useDebtCollection = useFormInstanceField({
    storeName,
    fieldKey: "debt_setting.use_debt_collection",
  });

  const handleOnCopy = () => {
    const debtSettingClone = cloneDeep(selectedDebtSetting);

    delete debtSettingClone.title;
    delete debtSettingClone.id;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          debt_setting: debtSettingClone,
        },
      })
    );

    setCreateNewActive(true);
  };

  if (!createNewActive)
    return (
      <>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Betalningsvillkor</OverviewTitle>
            <OverviewSubtitle>
              Grundläggande inställningar för villkoren på fakturor
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey="debt_setting"
            title={"Inställning för betalningsvillkor"}
            placeholder={"Välj inställning..."}
            description="Inställningen för betalningsvillkor bestämmer bland annat hur lång betaltid det är på fakturor, hur påminnelser och kravhantering hanteras och vilken typ av dröjsmålsränta som tillämpas."
            TableComponent={InvoicingDebtSettingTable}
          />
        </div>

        {!selectedDebtSetting && (
          <TextButton
            title="Eller tryck här för att skapa en ny inställning för betalningsvillkor"
            iconType="add"
            iconPlacement="right"
            clicked={() => setCreateNewActive(true)}
          />
        )}

        {selectedDebtSetting && (
          <DebtSettingSummary
            setting={selectedDebtSetting}
            onCopy={handleOnCopy}
          />
        )}
      </>
    );

  return (
    <>
      <TextButton
        title="Välj existerande inställning istället"
        extraStyle={{ marginBottom: 36 }}
        iconType="arrow-back"
        red
        clicked={() => {
          setCreateNewActive(false);
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: {
                debt_setting: null,
              },
            })
          );
        }}
      />

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Betalningsvillkor</OverviewTitle>
          <OverviewSubtitle>
            Grundläggande inställningar för villkoren på fakturor
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        title="Titel på inställning"
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.title",
        }}
      />

      <Checkbox
        small
        extraStyles={{ marginBottom: 12 }}
        title="Fakturor förfaller alltid den sista dagen i månaden för utskick"
        description="Om denna inställning är aktiv så kommer förfallodatumet alltid vara den sista i månaden för fakturan."
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.contract_pay_last_day_month",
        }}
        forceHorizontalToolTipPlacement="left"
      />

      <Checkbox
        small
        extraStyles={{ marginBottom: 12 }}
        title="Flytta förfallodatum till närmaste vardag vid helgdag på förfallodatum"
        description="Om denna inställning är aktiv så kommer förfallodatumet aldrig att infalla på helgen."
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.attempt_no_weekend_pay_day",
        }}
        forceHorizontalToolTipPlacement="left"
      />

      {!alwaysLastDay && (
        <NumberInput
          title="Betalningsvillkor i dagar"
          description="Ange antal dagar från fakturadatum till förfallodatum. Skickas fakturan ut den 1:a och denna inställning sätts till 10 så kommer förfallodatumet vara den 11:e"
          {...{
            storeName,
            method,
            fieldKey: "debt_setting.contract_payment_term_days",
          }}
        />
      )}

      <hr />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Påminnelser</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för påminnelseutskick
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <RadioGroup
        title="Använd påminnelser"
        description="Om denna inställning är aktiv kommer påminnelser att skickas ut efter fakturornas förfallodatum."
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.use_reminders",
        }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      {useReminders && (
        <>
          <NumberInput
            title="Antal dagar efter förfallodatum som påminnelse skickas"
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.send_reminder_after_days",
            }}
          />

          <NumberInput
            title="Betalningsvillkor i antal dagar för påminnelser"
            description="Antal dagar efter utskick av påminnelse som påminnelsefakturan förfaller"
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.reminder_term_days",
            }}
          />

          <NumberInput
            title="Påminnelseavgift"
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.reminder_fee",
            }}
          />

          <NumberInput
            title="Nådedagar för påminnelseavgift"
            description="Nådedagar innebär att om betalning inkommer ett visst antal dagar efter att påminnelsen skickas ut så stryks påminnelseavgiften."
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.reminder_grace_days",
            }}
          />

          <NumberInput
            title="Minsta summa på faktura för att påminnelse ska skickas"
            description="Minsta totalsumman på en faktura för att påminnelse ska skickas ut. Om minsta summan är t.ex. 1 000 SEK så kommer inte påminnelsefakturor skickas på förfallna fakturor som understiger detta belopp."
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.reminder_grace_amount",
            }}
          />
        </>
      )}

      <hr />

      <hr />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Kravhantering</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för automatisk kravhantering
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <RadioGroup
        title="Använd automatisk kravhantering"
        description="Om denna inställning är aktiv kommer en kravhanteringsprocess automatiskt att påbörjas efter förfallodatum på obetald faktura"
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.use_debt_collection",
        }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      {useDebtCollection && (
        <>
          <NumberInput
            title="Antal dagar efter förfallodatum som krav inleds"
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.send_to_debt_after_days",
            }}
          />

          <Select
            title="Börja krav på nivå"
            description={`Välj vilken nivå kravet ska inledas på. OBS: Förseningsavgift går enbart att använda på bolagskunder. Motsvarigheten för privatkunder är "Påminnelser"`}
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.debt_start_mode",
            }}
          />

          {selectedDebtSetting?.debt_start_mode === 3 && (
            <Select
              title="Antal påminnelser att skicka innan inkassokrav"
              {...{
                storeName,
                method,
                fieldKey: "debt_setting.number_of_reminders",
              }}
            />
          )}

          <NumberInput
            title="Betalningsvillkor för krav i antal dagar"
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.debt_term_days",
            }}
          />

          <RadioGroup
            title="Kravet utgör bostadsavgifter där avhysning skall ske vid betalningsföreläggande"
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.eviction",
            }}
            forceHorizontalToolTipPlacement="left"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />

          <RadioGroup
            title="Kravet utgör bostadsavgifter för privatperson där Socialstyrelsen skall informeras vid betalningsföreläggande"
            {...{
              storeName,
              method,
              fieldKey: "debt_setting.inform_social_welfare",
            }}
            forceHorizontalToolTipPlacement="left"
            options={[
              { label: "Ja", value: true },
              { label: "Nej", value: false },
            ]}
            defaultValue={false}
          />
        </>
      )}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Dröjsmålsränta</OverviewTitle>
          <OverviewSubtitle>Inställningar för dröjsmålsränta</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <Select
        title="Typ av dröjsmålsränta"
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.interest_type",
        }}
      />

      <NumberInput
        title="Dröjsmålsränta i procent"
        description="Observera att ränta över det som anses vara rimligt kräver att anledning till högre ränta anges"
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.interest_percentage",
        }}
      />

      <TextField
        title="Anledning till förhöjd dröjsmålsränta"
        description="Specificera anledning till en högre ränta än vad som anses rimligt"
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.reason_higher_interest",
        }}
      />

      <NumberInput
        title="Antal dagar efter förfallodatum som ränta börjar räknas"
        {...{
          storeName,
          method,
          fieldKey: "debt_setting.interest_start_due_date",
        }}
      />
    </>
  );
};
