import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/brfOwner";

export default function BrfOwnerTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  // const exportParser = {
  //   state: (data) => data.state_display,
  // };

  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "brf_owner_full_table"}
      title={"Bostadsrättsinnehavare"}
      onRowClickedWithDetail={(obj) => {}}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
