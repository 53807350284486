import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

import { constants, detailUrl } from "../../../store/roundingErrandFaults";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";
import { useHistory } from "react-router-dom";
import { BADGE_TYPES } from "src/components/Badge/Badge";

export default function RoundingFaultTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  initialOrderBy,
  hideBadges,

  createReportErrandCallback,
}) {
  const { push } = useHistory();

  const columns = React.useMemo(() => {
    const data = columnDefs({
      createReportErrandCallback,
    });
    return data;
  }, [, persistantQuery, createReportErrandCallback]);

  const badges = {
    Avvikelse: {
      color: BADGE_TYPES.RED,
      querySet: {
        is_ok: false,
      },
    },
    OK: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        is_ok: true,
      },
    },
    "Ej kontrollerad": {
      color: BADGE_TYPES.GRAY,
      querySet: {
        is_ok__isnull: true,
      },
    },
    Felanmäld: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        fault_report__isnull: false,
      },
    },
    "Ej felanmäld": {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        fault_report__isnull: true,
      },
    },
  };
  const filters = {
    Titel: {
      queryKey: "title",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  return (
    <BaseTable
      tableId={tableId || "rouding_errands_faults_full_table"}
      title={"Ronderingsavvikelser"}
      onRowClickedWithDetail={(obj) => push(detailUrl({ id: obj.id }))}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        hideBadges,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
        initialOrderBy,
      }}
    />
  );
}
