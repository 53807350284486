import Cancelation from "./Cancelation";

import ConnectedContracts from "./ConnectedContracts";
import Confirm from "./Confirm";

export default {
  CANCELATION: Cancelation,
  CONNECTED_CONTRACTS: ConnectedContracts,
  CONFIRM: Confirm,
};
