import * as React from "react";

// style, design
import { ToolTipCell } from "../../../Displays";

import SimpleTextFilter from "../../../Lists/Base/CompleteList/Filters/TextFilter";

export default (realEstates) => [
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Antal fordonsplatser",
    accessor: "spot_amount",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Kategori",
    accessor: "specification.category_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Typ av plats",
    accessor: "specification.vehicle_support_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fastigheter",
    accessor: (row, _) => {
      const rs = realEstates?.filter((b) =>
        row.realestates.some((r) => r.id === b.id)
      );
      return rs.map((r) => r.str_representation).join(", ");
    },
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
