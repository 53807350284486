import * as React from "react";
import { useTenant, detailUrl } from "../../../store/tenants";
import { Wrapper } from "../";
import { Link } from "react-router-dom";
import {
  AtSymbolIcon,
  BuildingStorefrontIcon,
  IdentificationIcon,
  PhoneIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

export default function SmallTenantSummary({
  id,
  showContractDetails,
  isPrimaryTenant,
  className,
  showBrfPremisesDetails,
  quota,
}) {
  const [tenant, tenantLoading] = useTenant(id);
  const [showLegalId, setShowLegalId] = React.useState(false);
  const isMember = tenant?.is_brf;

  if (!id) return null;

  return (
    <Wrapper className={className}>
      <div className="flex">
        <div className="mr-3 shrink-0">
          {tenant?.user?.corporate_name ? (
            <BuildingStorefrontIcon width={32} className="text-slate-500" />
          ) : (
            <UserCircleIcon width={32} className="text-slate-500" />
          )}
        </div>
        <div>
          <div
            className={`mb-${
              showContractDetails || showBrfPremisesDetails ? "1" : "3"
            } text-base font-semibold leading-none text-gray-700 hover:underline`}
          >
            <Link to={detailUrl({ id, isMember })}>
              {tenant?.str_representation || "Laddar..."}
            </Link>
          </div>
          {showContractDetails && (
            <p className="mb-3 text-sm font-normal">
              {isPrimaryTenant ? "Primär hyresgäst" : "Övrig hyresgäst"}
            </p>
          )}
          {showBrfPremisesDetails && (
            <p className="mb-3 text-sm font-normal">Äger {quota}%</p>
          )}

          <ul className="text-sm font-light">
            <li className="flex items-center mb-1">
              <PhoneIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <a
                href={`tel:${tenant?.user?.phone}`}
                className="text-blue-600 hover:underline"
              >
                {tenant?.user?.phone || "-"}
              </a>
            </li>
            <li className="flex items-center mb-1">
              <AtSymbolIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <a
                href={`mailto:${tenant?.user?.email}`}
                className="text-blue-600 hover:underline"
              >
                {tenant?.user?.email || "-"}
              </a>
            </li>
            <li className="flex items-center mb-1">
              <IdentificationIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              {tenant?.user?.legal_id &&
              !tenant?.user?.corporate_name &&
              !showLegalId ? (
                <span
                  onClick={() => setShowLegalId(true)}
                  className="cursor-pointer text-blue-600 hover:underline"
                >
                  Visa personnummer
                </span>
              ) : (
                <span>{tenant?.user?.legal_id || "-"}</span>
              )}
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}
