import * as React from "react";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import * as SC from "./styles";

export default ({ title, subtitle, renderSubtitle, breadCrumbs, isBeta }) => {
  const [showBetaInfo, setShowBetaInfo] = React.useState(false);

  return (
    <SC.TitleWrapper>
      <SC.Title>
        {title}{" "}
        {isBeta && (
          <StatusLabel
            onMouseOver={() => setShowBetaInfo(true)}
            onMouseLeave={() => setShowBetaInfo(false)}
            style={{ marginLeft: 8, position: "relative", cursor: "pointer" }}
            state={"BETA"}
          >
            BETA
            {showBetaInfo && (
              <SC.BetaBox>
                Denna del av systemet är ny och under utveckling. Pigello utför
                noggranna tester innan funktionen släpps officiellt och därför
                kan viss funktionalitet saknas eller inte fungera som det ska.
                Vänligen informera oss om det är något som inte verkar fungera
                som det ska så tittar vi på det direkt.
              </SC.BetaBox>
            )}
          </StatusLabel>
        )}
      </SC.Title>

      <SC.BreadCrumbWrapper>
        {subtitle ? (
          <SC.Subtitle>{subtitle}</SC.Subtitle>
        ) : renderSubtitle ? (
          <SC.Subtitle>{renderSubtitle()}</SC.Subtitle>
        ) : null}
        {breadCrumbs?.map((b) => (
          <SC.BreadCrumb to={b.url}>{b.label}</SC.BreadCrumb>
        ))}
      </SC.BreadCrumbWrapper>
    </SC.TitleWrapper>
  );
};
