import * as services from "./services";
import constants from "./constants";
import { getFormInstanceStateType } from "../base";
import { constants as realEstateConstants } from "../realEstates";
import moment from "moment";

export const searchSuggestions = (searchTerm) => {
  return async (dispatch) => {
    const results = await services.infoTraderLookup(searchTerm);

    dispatch({
      type: constants.SET_SEARCH_SUGGESTIONS,
      payload: {
        results: Array.isArray(results) ? results : [],
      },
    });
  };
};

export const fetchInfoTraderData = ({
  municipality,
  key,
  hasApartments,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.SET_LOADING_INFOTRADER_DATA,
        payload: {
          loading: true,
        },
      });
      const data = await services.fetchInfoTraderData({
        municipality,
        key,
        hasApartments,
      });

      const bestResult = data?.[0];

      bestResult.owner_since = bestResult.owner_since
        ? moment(bestResult?.owner_since?.split("+")?.[0]).format("YYYY-MM-DD")
        : null;

      dispatch({
        type: constants.SET_INFOTRADER_DATA,
        payload: {
          data: bestResult,
        },
      });

      dispatch({
        type: getFormInstanceStateType(realEstateConstants.STORE_NAME),
        payload: {
          result: bestResult,
          clean: true,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};
