import * as React from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { InnerBox } from "../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";

export default ({ sensors, measureValues }) => {
  let mappedGraphData = {};
  (measureValues ?? []).forEach((row) => {
    let existing = mappedGraphData?.[row?.sensor?.id];
    if (!existing) {
      existing = {
        sensorName: row?.sensor?.str_representation,
        values: [],
      };
    }
    existing["values"].push([moment(row.for_date), row.value]);
    mappedGraphData[row?.sensor?.id] = existing;
  });

  const chartOptions = {
    chart: {
      type: "area",
      height: 200,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "text",
    },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
    grid: {
      show: false,
      padding: {
        left: 60,
      },
    },
  };

  const getSensorGraph = (sensor) => {
    const graphData = mappedGraphData?.[sensor?.id];
    if (!graphData) {
      return null;
    }

    const values = graphData["values"];
    if (!values.length) {
      return null;
    }

    values.sort((a, b) => a[0] - b[0]);

    const options = {
      ...chartOptions,
      labels: values?.map((l) => l?.[0]?.format("YYYY-MM-DD HH:mm")),
    };
    const xvalues = values.map((l) => l[1]);

    return (
      <InnerBox style={{ flex: 2 }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              {graphData["sensorName"]} senaste 90 dagarna
            </OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <InnerBox style={{ color: "#444A55" }}>
          <ReactApexChart
            type="area"
            height={200}
            options={options}
            series={[
              {
                name: graphData["sensorName"],
                data: xvalues,
              },
            ]}
          />
        </InnerBox>
      </InnerBox>
    );
  };
  const getSensorGraphs = () => {
    if (!sensors) {
      return null;
    }

    return sensors.map((s) => getSensorGraph(s));
  };

  return <>{getSensorGraphs()}</>;
};
