import * as React from "react";

import { Integration, Confirm } from "./Chapters";

export default ({ key, method, onlyPigello }) => {
  switch (key) {
    case "INTEGRATION":
      return <Integration method={method} onlyPigello={onlyPigello} />;

    default:
      return <Confirm {...{ method }} />;
  }
};
