export default [
  {
    title: "Servicepartner",
    key: "COMPANY",
    visited: true,
    hasError: false,
    fieldKeys: ["user"],
  },
  {
    title: "Skapa",
    key: "CONFIRM",
    visited: false,
    hasError: false,
  },
];
