import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingLease";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import { useInvoicingSetting } from "../../../../../store/invoicingSettings";

import InvoicingSettingSummary from "../../../../Summaries/InvoicingSettingSummary";

import { cloneDeep } from "lodash";
import InvoicingSettingDefault from "../../../../Summaries/InvoicingSettingDefault";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import { InnerBox } from "../../../../sharedStyles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";
import IMDSettingsTable from "src/components/Tables/InvoicingIMDSettings/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const imdSetting = useFormInstanceField({
    storeName,
    fieldKey: "imd_setting",
  });

  const [existingSetting] = useInvoicingSetting(defaultSetting?.id);

  return (
    <>
      <FormAreaTitle>Aviseringsinställning</FormAreaTitle>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"billing_company"}
          placeholder="Välj fakturerande bolag..."
          title="Fakturerande bolag"
          description={
            "Välj vilket bolag detta avtal ska aviseras ifrån. Om inget bolag väljs kommer bolaget som hyresobjektet tillhör att användas"
          }
          TableComponent={CompanyTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"setting"}
          placeholder="Välj aviseringsinställning..."
          title="Aviseringsinställning"
          description={
            "Välj vilken basinställning för avisering som ska användas för avtalet"
          }
          TableComponent={InvoicingSettingTable}
        />
      </div>

      <InnerBox style={{ marginBottom: 24 }}>
        {imdSetting != null || !existingSetting?.imd_setting ? (
          <>
            {existingSetting?.imd_setting != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  instanceClone.imd_setting = null;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                method={method}
                fieldKey={"imd_setting"}
                placeholder="Välj debiteringsinställning..."
                title="Debiteringsinställning för IMD"
                description={
                  "Välj vilken basinställning för debitering av IMD som ska användas för avtalet"
                }
                TableComponent={IMDSettingsTable}
              />
            </div>
          </>
        ) : (
          <InvoicingSettingDefault
            title="Inställning för IMD-debitering"
            content={[
              {
                title: "Inställning",
                content: existingSetting?.imd_setting?.str_representation,
              },
            ]}
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    imd_setting: {},
                  },
                })
              );
            }}
          />
        )}
      </InnerBox>

      {defaultSetting?.id && (
        <>
          {existingSetting ? (
            <InvoicingSettingSummary setting={existingSetting} />
          ) : null}
        </>
      )}
    </>
  );
};
