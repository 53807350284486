import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";
import { constants, detailUrl } from "../../../store/roundingAreas";

export default function RoundingAreasTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  title,
}) {
  const columns = React.useMemo(() => columnDefs(), []);

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "rounding_errand_areas_full_table"}
        title={title || "Ronderingsområden"}
        onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
