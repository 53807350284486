import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import {
  useCommonAreasPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/commonAreas";
import { useFilteredRealEstates } from "../../../store/realEstates";

import { BADGE_TYPES } from "../../Badge/Badge";

export default function CommonAreaTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  // const exportParser = {
  //   state: (data) => data.state_display,
  //   category: (data) => data.category_display,
  // };

  const [realEstates] = useFilteredRealEstates();
  const columns = React.useMemo(
    () => columnDefs(realEstates),
    [realEstates, persistantQuery]
  );

  const filters = {};
  const badges = {};

  return (
    <BaseTable
      tableId={tableId || "common_areas_full_table"}
      title={"Gemensamma ytor"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
