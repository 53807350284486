import * as React from "react";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import uniqBy from "lodash/uniqBy";

// styling, design
import * as SC from "./styles";
import ActiveRealEstateCard from "./ActiveRealEstateCard";

// store, state
import { useFilteredRealEstates } from "../../store/realEstates";
import { useFilteredBuildings } from "../../store/buildings";
import useActiveFilters from "../../hooks/useActiveFilters";
import { buildQueryString } from "../../store/base";
import { cloneDeep, isEqual } from "lodash";

const TOKEN =
  "pk.eyJ1IjoiYXRsYXNmYXN0aWdoZXRlciIsImEiOiJja2hwYW9mZmYwdXNqMnlrNjhsODFqY3B1In0.LMeZbTXQkeQEtatNYAUt7w";

export default React.memo(() => {
  const mapRef = React.useRef();
  const [mapInstance, setMapInstance] = React.useState(null);
  const [renderedAddresses, setRenderedAddresses] = React.useState([]);
  const [markers, setMarkers] = React.useState([]);
  const [activeRealEstateData, setActiveRealEstateData] = React.useState(null);

  const [realEstatesClone, setRealEstatesClone] = React.useState(null);
  const [buildingsClone, setBuildingsClone] = React.useState(null);

  const { filteredRealEstates } = useActiveFilters();

  const query = buildQueryString({
    id__in: filteredRealEstates,
  });

  const [realEstates, realEstatesLoading] = useFilteredRealEstates(query);

  React.useEffect(() => {
    if (!realEstatesClone && realEstates?.length) {
      setRealEstatesClone(cloneDeep(realEstates));
    }
  }, [realEstates]);

  const buildingIds = realEstatesClone
    ?.map((r) => r.buildings?.map((b) => b.id))
    ?.filter((id) => !!id)
    ?.flat();

  const buildingQuery = buildQueryString({
    id__in: buildingIds,
  });

  const [buildings] = useFilteredBuildings(buildingQuery);

  React.useEffect(() => {
    if (!buildingsClone && buildings?.length) {
      setBuildingsClone(cloneDeep(buildings));
    }
  }, [buildings]);

  const loadMapBoxCss = React.useCallback(() => {
    const existsAlready = document.querySelector(
      'link[href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.css"]'
    );
    if (existsAlready) return;
    const head = document.getElementsByTagName("HEAD")[0];
    const styleSheet = document.createElement("link");
    styleSheet.rel = "stylesheet";
    styleSheet.type = "text/css";
    styleSheet.href =
      "https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.css";
    head.appendChild(styleSheet);
  }, []);

  const initMap = React.useCallback(() => {
    const mapEl = mapRef?.current;
    if (!mapEl) return;

    const { offsetWidth, offsetHeight } = mapEl;

    const [lng, lat] = ["14.9004992", "62.758535"];
    mapboxgl.accessToken = TOKEN;
    const map = new mapboxgl.Map({
      container: mapEl,
      width: offsetWidth,
      height: offsetHeight,
      center: [lng, lat],
      style: "mapbox://styles/atlasfastigheter/ckpmkn6dg0bq917l2fbeh8o0x",
      zoom: 2,
      attributionControl: false,
      logoPosition: "bottom-right",
    });
    setMapInstance(map);
    //   map.dragPan.disable();
    map.addControl(new mapboxgl.NavigationControl());

    map.on("load", function () {
      map.resize();
    });

    map.on("drag", () => {
      setActiveRealEstateData(null);
    });

    map.on("click", (e) => {
      setActiveRealEstateData(null);
    });
  }, []);

  const handleMarkerClicked = React.useCallback(
    (id) => {
      const building = buildingsClone?.find((b) => {
        const bAddressIds = b.addresses.map((a) => a.id);
        return bAddressIds.includes(id);
      });

      const realEstate = realEstatesClone?.find(
        (r) => r.id === building?.realestate?.id
      );

      setActiveRealEstateData({ realEstate, image: building?.image?.get });
    },
    [buildingsClone, realEstatesClone]
  );

  const filterByRealEstate = (buildings) => {
    return uniqBy(buildings, "realestate").filter((e) => !Array.isArray(e));
  };

  const getRenderedBuildings = React.useCallback(() => {
    return filterByRealEstate(buildingsClone);
  }, [buildingsClone, realEstatesClone]);

  const fitToBounds = React.useCallback(() => {
    const bound = new mapboxgl.LngLatBounds();

    const bounds = markers.map((marker) => [
      marker._lngLat.lng,
      marker._lngLat.lat,
    ]);
    bounds.forEach((b) => {
      bound.extend(b);
    });
    mapInstance.fitBounds(bound, { padding: 120, maxZoom: 15 });
  }, [mapInstance, markers]);

  const getRenderedAddresses = React.useCallback(() => {
    const renderedBuildings = getRenderedBuildings();
    const rendered = [];

    renderedBuildings.forEach((building) => {
      const address = building.addresses.find((a) => {
        return !!a.lonlat;
      });

      if (address) {
        const [lon, lat] = address.lonlat.split(";");

        rendered.push({ ...address, lon, lat });
      }
    });

    const result = uniqBy(rendered, "id");
    setRenderedAddresses(result);
  }, [getRenderedBuildings, renderedAddresses]);

  const handleUpdateMarkers = React.useCallback(() => {
    markers.forEach((m) => {
      m.remove();
    });
    setMarkers([]);

    let newMarkers = [];

    if (renderedAddresses && renderedAddresses.length) {
      renderedAddresses.forEach((address) => {
        const marker = new mapboxgl.Marker()
          .setLngLat([address.lon, address.lat])
          .addTo(mapInstance);
        marker.getElement().addEventListener("click", (e) => {
          e.stopPropagation();
          handleMarkerClicked(address.id);
        });
        newMarkers.push(marker);
      });
    }
    setMarkers(newMarkers);
  }, [handleMarkerClicked, mapInstance, markers, renderedAddresses]);

  React.useEffect(() => {
    if (!markers || markers.length === 0) return;
    fitToBounds();
  }, [markers, fitToBounds]);

  React.useEffect(() => {
    getRenderedAddresses();
  }, [buildingsClone, realEstatesClone]);

  React.useEffect(() => {
    handleUpdateMarkers();
  }, [renderedAddresses]);

  React.useEffect(() => {
    loadMapBoxCss();
  }, [loadMapBoxCss]);

  React.useEffect(() => {
    if (!mapInstance && mapRef?.current) {
      initMap();
    }
  }, [mapRef, initMap, mapInstance]);

  return (
    <SC.MapWrapper ref={mapRef}>
      <ActiveRealEstateCard realEstateData={activeRealEstateData} />
    </SC.MapWrapper>
  );
});
