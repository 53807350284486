import * as React from "react";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { PlusIcon, TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";

import { faker } from "@faker-js/faker/locale/sv";

import LocalSelectManyDropdown from "./LocalSelectManyDropdown";

import { MOCK_MAP } from "../../views/NewReports/utils";

import moment from "moment";

const NUMBER_OF_ROWS = 20;

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const ColumnData = ({ rows, isLast = false, headerId, xScroll }) => {
  if (!document.getElementById(headerId)) return <p>Något gick fel...</p>;

  const rect = document.getElementById(headerId).getBoundingClientRect();
  rect.width = rect.width.toFixed(2);

  return (
    <div
      key={`${headerId}-data`}
      className={`bg-white left-[-1px] w-[calc(100%+2px)] top-[calc(3.5rem-1px)] ${
        isLast && ""
      } border-solid border-gray-300`}
      style={{
        width: `${rect.width}px`,
      }}
    >
      {rows}
    </div>
  );
};

export function Columns({
  unusedColumns,
  setUnusedColumns,
  orderOfColumns,
  setOrderOfColumns,
  setAskBeforeRemovingAllColumns,
}) {
  const [isAddingColumns, setIsAddingColumns] = React.useState(false);
  const windowSize = useWindowSize();

  const [fakeData, setFakeData] = React.useState({});

  const getFakeMethod = (col) => {
    if (MOCK_MAP.hasOwnProperty(col.identifier)) {
      let data = MOCK_MAP[col.identifier].data;

      if (Array.isArray(data)) {
        return data;
      }

      if (MOCK_MAP[col.identifier]?.process)
        return `(() => "${MOCK_MAP[col.identifier].data}")()`;
      return MOCK_MAP[col.identifier].data;
    }

    let text = "unset";

    if (col.money) {
      return `faker.finance.amount() + " kr"`;
    }
    if (col.percentage) return 'faker.random.numeric(2) + "%"';
    if (col.area) return 'faker.random.numeric(3) + "m2"';

    if (col.identifier.includes("company")) {
      return "faker.company.name()";
    }

    if (
      col.identifier.includes("address") ||
      col.identifier.includes("street")
    ) {
      return "faker.address.streetAddress()";
    }

    if (col.identifier.includes("name")) {
      return "faker.name.fullName()";
    }

    if (col.identifier.includes("number")) {
      return "faker.random.numeric(10)";
    }

    if (col.identifier.includes("city")) {
      return "faker.address.city()";
    }

    if (
      col.identifier.includes("date") ||
      col.identifier.includes("interval")
    ) {
      return 'moment(faker.date.future()).format("YYYY-MM-DD")';
    }

    if (col.identifier.includes("legal_id")) {
      //personnummer?
      return 'moment(faker.date.birthdate()).format("YYYYMMDD") + "-" + faker.random.numeric(4)';
    }

    if (col.identifier.includes("postal_code")) {
      return "faker.address.zipCode()";
    }

    if (col.identifier.includes("month")) {
      return "faker.date.month()";
    }

    if (col.identifier.includes("email")) {
      return "faker.internet.email()";
    }

    if (col.identifier.includes("amount")) {
      return "faker.random.numeric(Math.floor(Math.random() * (2)) + 1)";
    }

    return text;
  };

  const buildColumnsData = () => {
    let newData = {};

    const temp = (text, index) => {
      return (
        <div
          key={index}
          className="w-full text-xs border-b border-r border-l border-solid even:bg-gray-100 border-gray-300 overflow-hidden p-2 flex items-center"
        >
          <p>{text}</p>
        </div>
      );
    };

    const currentXScroll =
      document.getElementById("overflow-container-table-columns")?.scrollLeft ||
      0;

    let index = 0;
    for (let obj of orderOfColumns) {
      index++;
      //Build rows dynamically from type of column.

      let texts = fakeData?.[obj.identifier];

      if (!texts) texts = [];

      let rows = Array.from(new Array(NUMBER_OF_ROWS)).map((o, index) => {
        return temp(texts[index], index);
      });

      newData[obj.identifier] = (isDraggingOver) => (
        <ColumnData
          key={`${obj.identifier}-data-comp`}
          rows={rows}
          isLast={
            index === Object.keys(orderOfColumns).length || isDraggingOver
          }
          headerId={obj.identifier}
          xScroll={currentXScroll}
        />
      );
    }

    return newData;
  };

  const [columnsData, setColumnsData] = React.useState(buildColumnsData());

  //TODO: might remove, might not
  const updateColumnsData = () => {
    setColumnsData(buildColumnsData());
  };

  const buildAndSetFakeData = () => {
    if (Object.keys(fakeData).length === orderOfColumns.length) return fakeData;

    let newData = {};

    let unsets = [];

    for (let col of orderOfColumns) {
      if (fakeData.hasOwnProperty(col.identifier)) {
        newData[col.identifier] = fakeData[col.identifier];
        continue;
      }

      let methodStr = getFakeMethod(col);

      if (methodStr === "unset") {
        unsets.push(col.name);
        console.log(col, "unset");
      }

      let texts = [];

      if (Array.isArray(methodStr)) {
        texts = Array.from(new Array(NUMBER_OF_ROWS)).map(() => {
          return methodStr?.[Math.floor(Math.random() * methodStr.length)];
        });
      } else {
        texts = Array.from(new Array(NUMBER_OF_ROWS)).map(() => {
          if (methodStr === "unset") return "unset";

          return window.eval.call(
            window,
            `(function (faker, moment) {return ${methodStr}})`
          )(faker, moment);
        });
      }

      newData[col.identifier] = texts;
    }

    setFakeData(newData);

    return newData;
  };

  React.useEffect(() => {
    buildAndSetFakeData();
    updateColumnsData();
  }, [orderOfColumns, fakeData]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (
      !result.destination ||
      result.destination.index === undefined ||
      result.destination.index === null
    )
      return;

    const newColumns = reorder(
      orderOfColumns,
      result.source.index,
      result.destination.index
    );

    setOrderOfColumns(newColumns);
  };

  const removeColumn = (evt, colData, index) => {
    //Do not run dnd
    evt.stopPropagation();

    let newUnused = unusedColumns.slice();
    let newUsed = orderOfColumns.slice();

    newUnused = [colData, ...newUnused];
    newUsed.splice(index, 1);

    if (!newUnused[index]) delete newUnused[index];

    setUnusedColumns(newUnused);
    setOrderOfColumns(newUsed);
  };

  const chosenNewColumnsToAdd = (selected) => {
    setIsAddingColumns(false);

    let newUnused = [];

    let newUsed = orderOfColumns.slice();

    //remove all unused columns that are now used.
    for (let unusedIndex in unusedColumns) {
      let found = false;

      for (let select of selected) {
        if (select.value === unusedColumns[unusedIndex].value) {
          found = true;
          break;
        }
      }

      if (!found) newUnused.push(unusedColumns[unusedIndex]);
    }

    for (let select of selected) {
      newUsed.push(select);
    }

    setUnusedColumns(newUnused);
    setOrderOfColumns(newUsed);
  };

  const mockDisplayRef = React.useRef();

  const setStylesToMocDisplay = () => {
    if (!mockDisplayRef.current);

    if (orderOfColumns.length === 0) {
      mockDisplayRef.current.style.opacity = "0";
      return;
    }

    let rect = mockDisplayRef.current.parentElement.getBoundingClientRect();

    mockDisplayRef.current.style.top = (rect.y || rect.top) + "px";
    mockDisplayRef.current.style.width =
      (rect.width > windowSize.width ? windowSize.width : rect.width) + "px";
    mockDisplayRef.current.style.opacity = "1";
  };

  React.useEffect(() => {
    setStylesToMocDisplay();
  }, [mockDisplayRef, orderOfColumns, windowSize, columnsData]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, topSnapshot) => (
          <div className="flex flex-nowrap items-start h-full" style={{}}>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex flex-col flex-nowrap items-start overflow-x-scroll h-full whitespace-nowrap w-full relative"
              style={{
                // dnd takes over background color
                backgroundColor: "#FFFFFF",
              }}
              id="overflow-container-table-columns"
            >
              <div className="flex flex-nowrap items-start whitespace-nowrap w-full relative">
                {orderOfColumns.map((colData, index) => {
                  return (
                    <Draggable
                      key={colData.identifier}
                      draggableId={`id-${colData.identifier}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        //an actual header
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`flex-shrink-0 z-10 sticky top-0 mt-0 max-w-[500px] min-w-[200px] w-fit p-4 border-l border-b border-solid border-gray-300 bg-transparent transition-colors ${
                            snapshot.isDragging
                              ? "bg-blue-100 border-r"
                              : "bg-slate-200"
                          } border-r`}
                          id={`${colData.identifier}`}
                        >
                          <div className="">
                            <p className="font-semibold text-sm">
                              {colData.name}
                            </p>
                          </div>
                          <XCircleIcon
                            className="w-4 h-4 absolute cursor-pointer top-0.5 right-0.5 text-gray-400 rounded-full bg-transparent transition-colors hover:bg-gray-100 hover:text-red-400"
                            onClick={(e) => removeColumn(e, colData, index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

                <div
                  className={`h-auto w-60 min-w-[15rem] flex relative border-r border-b border-l border-solid border-gray-300 ${
                    topSnapshot.draggingFromThisWith && "hidden"
                  }`}
                >
                  <div
                    className={`w-full h-[52px] cursor-pointer flex justify-between items-center space-x-2`}
                  >
                    <div
                      onClick={() => setIsAddingColumns(!isAddingColumns)}
                      className="flex grow items-center justify-start pl-4 h-full hover:bg-blue-200 transition-colors"
                    >
                      <p className="text-sm">Lägg till</p>
                      <PlusIcon
                        className={`h-5 w-5 ml-1 transition-transform ${
                          isAddingColumns ? "rotate-45" : "rotate-0"
                        }`}
                      />
                    </div>
                    <div className="flex h-full items-center relative !ml-0">
                      <div className="h-full w-[1px] min-w-[1px] bg-gray-200"></div>
                      <button
                        onClick={() => setAskBeforeRemovingAllColumns(true)}
                        className="w-[52px] h-full group flex items-center justify-center transition-colors hover:bg-gray-200"
                      >
                        <TrashIcon className="h-4 w-4 transition-colors group-hover:text-red-400" />
                      </button>
                    </div>
                  </div>
                  <LocalSelectManyDropdown
                    values={[]}
                    choices={unusedColumns}
                    className={`max-h-[40vh]`}
                    onDone={chosenNewColumnsToAdd}
                    enabled={isAddingColumns}
                  />
                </div>
              </div>
              {provided.placeholder}

              <div
                className={`flex grow h-0 overflow-y-scroll relative ${
                  topSnapshot.draggingFromThisWith && "mt-[-3.5rem]"
                }`}
              >
                <div
                  ref={mockDisplayRef}
                  className="fixed flex flex-col overflow-hidden top-0 bottom-0 left-0 right-0 pointer-events-none items-center justify-center"
                >
                  <div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-50 opacity-25"></div>
                  <p className="text-5xl opacity-30 font-semibold -rotate-12 text-red-700">
                    OBS: Exempeldata
                  </p>
                  <div className="bg-gray-50 p-2 rounded mt-2 -rotate-12">
                    <p className="text-sm text-black opacity-60 font-semibold">
                      Klicka på exportera uppe till höger för att se rapporten
                    </p>
                  </div>
                </div>
                {Object.keys(columnsData).map((key) => {
                  return columnsData[key](topSnapshot.draggingFromThisWith);
                })}
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
