import * as React from "react";

// style, design

import { RadioGroup, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/leaseContracts";

import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useDispatch } from "react-redux";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import { useFilteredRealEstates } from "../../../../../store/realEstates";

import { buildRangeFilter } from "../../../../Lists/Base/utils";
import { useParams } from "react-router";
import Table from "../../../../Billecta/Table/BasicTable";
import { StatusLabel } from "../../../../Lists/Base/CompleteList/styles";
import { toMoneyString, toSqmString } from "../../../../utils/stringUtils";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import NewContractGeneration, {
  NEW_CONTRACT_GENERATION_KINDS,
} from "../../../../Details/Apartment/NewContractGeneration";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import TableSelectFieldWithCreate from "../../../Base/Fields/TableSelectFieldWithCreate";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import ApartmentNestedFields from "../../../Apartment/NestedChildren";
import IndustrialPremisesNestledFields from "../../../IndustrialPremises/NestedChildren";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { filteredRealEstates } = useActiveFilters();
  const { premisesKind } = useParams();

  const selectedIndps = useFormInstanceField({
    storeName,
    fieldKey: "industrial_premises_list",
  });
  const selectedApartments = useFormInstanceField({
    storeName,
    fieldKey: "apartments",
  });
  const selectedRealEstate = useFormInstanceField({
    storeName,
    fieldKey: "realestate",
  });

  const proposalCopyIsDone = useFormInstanceField({
    storeName,
    fieldKey: "_proposalIsDone",
  });
  const selectedLandlord = useFormInstanceField({
    storeName,
    fieldKey: "landlord",
  });
  const landlordId = selectedLandlord?.id;
  const landlordRealestatesQuery = buildQueryString({
    company: landlordId || -1,
  });
  const [connectedRealestates] = useFilteredRealEstates(
    landlordRealestatesQuery
  );
  const connectedRealEstatesIds = connectedRealestates?.map((r) => {
    return r.id;
  });

  const isBlock = useFormInstanceField({
    storeName,
    fieldKey: "_isBlock",
  });

  const combinedPremises = [
    ...(selectedIndps || []),
    ...(selectedApartments || []),
  ];

  React.useEffect(() => {
    if (selectedRealEstate || combinedPremises?.length === 0) return;

    const forceRe = combinedPremises?.[0].realestates?.[0];
    if (forceRe != null) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            realestate: { id: forceRe },
          },
        })
      );
    }
  }, [combinedPremises]);

  let filterRealEstateIds = [...(filteredRealEstates || [])];
  if (selectedRealEstate) {
    filterRealEstateIds.push(selectedRealEstate.id);
  }
  if (connectedRealEstatesIds?.length) {
    filterRealEstateIds = [...connectedRealEstatesIds, ...filterRealEstateIds];
  }

  const tableData = React.useMemo(() => {
    return combinedPremises;
  }, [combinedPremises]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Status",
        accessor: "state_display",
        Cell: ({ value, row }) => {
          return (
            <StatusLabel
              contentType={"premises"}
              state={row.original.state || 4}
            >
              {value || "Nytt objekt"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Objektsnummer",
        accessor: "premises_id",
        Cell: ({ row }) => {
          return row.original?.premises_id || "-";
        },
      },
      {
        Header: "Adress",
        accessor: "address",
        Cell: ({ row }) => {
          return row.original?.address?.str_representation || "-";
        },
      },
      {
        Header: "Byggnad",
        accessor: "building",
        Cell: ({ row }) => {
          return row.original?.building?.str_representation || "-";
        },
      },
      {
        Header: "Area",
        accessor: "area",
        Cell: ({ row }) => {
          return toSqmString(row.original?.area || 0) || "-";
        },
      },
      {
        Header: "Referenshyra / år (SEK)",
        accessor: "rent",
        Cell: ({ row }) => {
          return toMoneyString(row.original?.base_rent || 0) || "-";
        },
      },
    ];

    return cols;
  }, []);

  const showProposal = combinedPremises?.length === 1;
  const proposalKind = selectedIndps?.[0]
    ? NEW_CONTRACT_GENERATION_KINDS.INDUSTRIAL_PREMISES
    : NEW_CONTRACT_GENERATION_KINDS.APARTMENT;
  const proposalPremises = selectedIndps?.[0] || selectedApartments?.[0];

  return (
    <>
      {/* <NonNestedSelect
        title="Filtrera på fastighet"
        description="Välj en fastighet att filtrera hyresobjekten i listan på."
        storeName={storeName}
        fieldKey={"realestate"}
        method={method}
        fetchMethod={useFilteredRealEstates}
        fetchQueryString={realEstateQuery}
        placeholder="Välj fastighet att filtrera på..."
      /> */}

      {(premisesKind === "industrialpremises" || premisesKind === "both") && (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectFieldWithCreate
              storeName={storeName}
              fieldKey={"industrial_premises_list"}
              method={method}
              title="Lokaler"
              placeholder="Välj lokaler..."
              description="Välj lokaler på avtalet."
              createDisplayKey="premises_id"
              TableComponent={IndustrialPremisesTable}
              isMany
            >
              {(parentPath) => (
                <IndustrialPremisesNestledFields
                  storeName={storeName}
                  method={method}
                  parentPath={parentPath}
                  parentInstructionsPath={"industrial_premises_list"}
                />
              )}
            </TableSelectFieldWithCreate>
          </div>
        </>
      )}

      {(premisesKind === "apartments" || premisesKind === "both") && (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectFieldWithCreate
              storeName={storeName}
              fieldKey={"apartments"}
              method={method}
              title="Lägenheter"
              placeholder="Välj lägenheter..."
              description="Välj hyreslägenheter på avtalet."
              createDisplayKey="apartment_id"
              TableComponent={ApartmentTable}
              isMany
            >
              {(parentPath) => (
                <ApartmentNestedFields
                  storeName={storeName}
                  method={method}
                  parentPath={parentPath}
                  parentInstructionsPath={"apartments"}
                />
              )}
            </TableSelectFieldWithCreate>
          </div>
        </>
      )}

      {showProposal && (
        <NewContractGeneration
          kind={proposalKind}
          isDone={proposalCopyIsDone}
          premises={proposalPremises}
          onDone={() => {
            dispatch(
              addToast({
                type: TOAST_TYPES.INFO,
                title: "Föreslagna värden klistrades in",
                description:
                  "Kontrollera att värdena stämmer överens med vad som ska gälla för avtalet",
              })
            );
          }}
          skipRedirect
          overrideBtnTitle="Hämta inställningar från valt objekt"
        />
      )}

      {combinedPremises?.length > 1 && (
        <>
          {" "}
          <RadioGroup
            title="Ange om avtalet är ett blockavtal"
            options={[
              { label: "Nej, detta är ej ett blockavtal", value: false },
              { label: "Ja, detta är ett blockavtal", value: true },
            ]}
            overrideInstructions={{ readOnly: false }}
            defaultValue={false}
            {...{
              storeName,
              method,
              fieldKey: `_isBlock`,
            }}
          />
          {isBlock && (
            <>
              <TextInput
                {...{
                  storeName,
                  method,
                  fieldKey: "block.name",
                  title: "Namn på block",
                }}
              />
            </>
          )}
        </>
      )}

      {combinedPremises?.length > 0 && (
        <>
          <OverviewTitleWrapper style={{ marginBottom: 0, marginTop: 24 }}>
            <OverviewTitle small>Valda hyresobjekt</OverviewTitle>
          </OverviewTitleWrapper>
          <Table hideSearch data={tableData} columns={tableColumns} />
        </>
      )}
    </>
  );
};
