import * as React from "react";

import { Blueprint, Confirm } from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "BLUEPRINT":
      return <Blueprint method={method} />;

    default:
      return <Confirm />;
  }
};
