import * as React from "react";
import * as SC from "./styles";

export default function PageSlider({
  onTabSelected,
  selectedTab,
  TABS,
  highlightTabs = [],
}) {
  const currentIndex = Object.keys(TABS).indexOf(selectedTab);
  const tabsCount = Object.keys(TABS).length;

  const isActive = (key) => {
    return currentIndex === Object.keys(TABS).indexOf(key);
  };

  return (
    <SC.SliderTabWrapper>
      <SC.Slider itemCount={tabsCount} currentItem={currentIndex} />
      {Object.keys(TABS).map((key) => (
        <SC.SliderTab
          key={key}
          itemCount={tabsCount}
          onClick={() => onTabSelected(key)}
          active={isActive(key)}
          highlighted={!isActive(key) && highlightTabs.includes(key)}
        >
          {TABS[key]}
        </SC.SliderTab>
      ))}
    </SC.SliderTabWrapper>
  );
}
