export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BUILDING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BUILDING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BUILDING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BUILDING",
  DESTROY_FORM: "DESTROY_FORM_BUILDING",
  SET_FORM_ERROR: "SET_FORM_ERROR_BUILDING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BUILDING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BUILDING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BUILDING",
  RESET_STATE: "RESET_STATE_BUILDING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BUILDING",
  REMOVE_OBJECT: "REMOVE_OBJECT_BUILDING",
  CLEAR_FETCHED: "CLEAR_FETCHED_BUILDING",

  LIST_URL: "/standard/toplevel/building/list/",
  POST_URL: "/standard/toplevel/building/",
  PATCH_URL: "/standard/toplevel/building/",
  GET_URL: "/standard/toplevel/building/",

  STORE_NAME: "buildings",
};
