import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import BottomBar from "../../../components/StepDisplay/BottomBar";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import SetupUserForm from "../../../components/Forms/User/SetupUserForm";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { BareLayoutWrapper, InnerBox } from "../../../components/sharedStyles";
import { updateActiveFormInstance } from "../../../store/base";
import { constants, create } from "../../../store/users";

export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { push } = useHistory();

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);
    if (dataClone?.realestate) {
      dataClone.realestates = dataClone.realestates.map((r) => {
        return { id: r.id };
      });
    }
    dataClone.invite = false; // always false since we have an invite step in flow

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      create({
        preProcess,
        successCallback: (_, { id }) => {
          setLoading(false);
          push(
            `/configcenter/groups-and-users/user-setup/set-user-permissions/${id}`
          );
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <BareLayoutWrapper fillWidth style={{ paddingBottom: 120 }}>
      <StepDisplay
        steps={[
          "1. Skapa användare",
          "2. Ställ in behörigheter",
          "3. Bjud in användare",
        ]}
        activeIndex={0}
      />

      <InnerBox
        style={{
          maxWidth: 800,
          padding: 48,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SetupUserForm />
      </InnerBox>

      <BottomBar
        primaryActionTitle="Spara och gå vidare"
        disabled={false}
        primaryAction={onSubmit}
      />
      {loading && <OverlaySpinner />}
    </BareLayoutWrapper>
  );
};
