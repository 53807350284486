import styled from "styled-components";
import checkMark from "../../../../../assets/svg/check_white.svg";

export const Suggestions = styled.ul`
  -webkit-padding-start: 0;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  left: -1px;
  list-style-type: none;
  padding: 0;
  padding: none;
  position: absolute;
  top: 100%;
  width: calc(100% + 2px);
  z-index: 999;

  & li {
    align-items: center;
    display: flex;
    height: 40px;
    list-style: none;
    margin: 0;
    padding: 10px;
    width: 100%;
    z-index: 999;
    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }
    &:first-child {
      border-top: 1px solid rgb(221, 221, 221);
    }
    &:hover {
      background-color: #bfeefd;
    }
  }
`;

export const ListCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ListCheckBox = styled.input.attrs({
  type: "checkbox",
})`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 32px;
    cursor: ${(p) => (p.disabled ? "" : "pointer")};
    line-height: 20px;
    display: inline-block;
    color: ${(p) => p.theme.colors.primary};
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: #fff;
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: 20px;
    height: 20px;
    background-color: ${(p) => p.theme.colors.blueDark};
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 3px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  &:checked + label:after {
    background-image: url(${checkMark});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;
