import * as React from "react";

import {
  Confirm,
  DebtSettings,
  Interest,
  Reminders,
  DebtCollection,
} from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "DEBT_SETTING":
      return <DebtSettings {...{ method }} />;
    case "REMINDERS":
      return <Reminders {...{ method }} />;
    case "INTEREST":
      return <Interest {...{ method }} />;
    case "DEBT_COLLECTION":
      return <DebtCollection {...{ method }} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
