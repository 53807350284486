export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_APARTMENTCANCELLATION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_APARTMENTCANCELLATION",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_APARTMENTCANCELLATION",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_APARTMENTCANCELLATION",
  DESTROY_FORM: "DESTROY_FORM_APARTMENTCANCELLATION",
  SET_FORM_ERROR: "SET_FORM_ERROR_APARTMENTCANCELLATION",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_APARTMENTCANCELLATION",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_APARTMENTCANCELLATION",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_APARTMENTCANCELLATION",
  RESET_STATE: "RESET_STATE_APARTMENTCANCELLATION",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_APARTMENTCANCELLATION",
  REMOVE_OBJECT: "REMOVE_OBJECT_APARTMENTCANCELLATION",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_APARTMENTCANCELLATION",
  CLEAR_FETCHED: "CLEAR_FETCHED_APARTMENTCANCELLATION",

  LIST_URL: "/standard/mainlevel/premises/cancellations/apartment/",

  STORE_NAME: "apartmentCancellations",
};
