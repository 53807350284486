export default [
  {
    title: "IMD-debitering",
    key: "IMD",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "display_consumtion",
      "invoice_month_offset",
      "include_period_string",
    ],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["id"],
  },
];
