import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  overflow-x: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: 12px 0;
  border-radius: 5px;
  border: thin solid #dddddd;
`;

export const TableEmptyWrapper = styled.div`
  overflow-x: auto;
  border-radius: 5px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const TableEmptyText = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  padding: 12px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: "Inter", sans-serif;
  min-width: 100%;

  & th,
  td {
    padding: 10px 12px;
    display: table-cell;
    white-space: nowrap;
  }

  & td {
    vertical-align: middle;
  }

  & thead tr {
    background-color: ${(p) => p.theme.colors.activeGray};
    color: ${(p) => p.theme.colors.primary};
    text-align: left;
    white-space: nowrap;
  }

  & tbody tr:nth-of-type(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }

  & tbody tr {
    border-bottom: thin solid #dddddd;

    transition: color, background-color 50ms ease-in-out;

    &:hover {
      background-color: #f3f4f6;
      color: ${(p) => p.theme.colors.textPrimary};
    }

    &.active-row {
      background-color: ${(p) => p.theme.colors.blueLight};
      color: ${(p) => p.theme.colors.blueDark};

      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.blueDark};
      }
    }

    cursor: pointer;
  }
`;
