import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf";

import * as SC from "./styles";
import PagePicker from "./PagePicker";
import useKeyPress from "../../hooks/useKeyPress";
import { debounce } from "lodash";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const Pdf = React.memo(
  ({
    url,
    uint8,
    b64,
    fullscreenFormLayout,
    loading,
    documentMissingTitle,
  }) => {
    const containerRef = React.useRef();
    const [pageAmount, setPageAmount] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [loaded, setLoaded] = React.useState(false);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [data, setData] = React.useState(null);

    const pageDownPress = useKeyPress("ArrowLeft");
    const pageUpPress = useKeyPress("ArrowRight");

    const onLoadSuccess = (data) => {
      setLoaded(true);
      setPageAmount(data?._pdfInfo?.numPages);
    };

    const handleSetWidth = () => {
      if (containerRef?.current) {
        setWidth(containerRef?.current?.offsetWidth);
        setHeight(containerRef?.current?.offsetHeight);
      }
    };

    const debouncedSetWidth = debounce(handleSetWidth, 100);

    React.useEffect(() => {
      if (!data) return;
      if (pageDownPress && pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }

      if (pageUpPress && pageNumber < pageAmount) {
        setPageNumber(pageNumber + 1);
      }
    }, [pageDownPress, pageUpPress]);

    React.useEffect(() => {
      debouncedSetWidth();
    }, [containerRef, loaded]);

    React.useEffect(() => {
      window.addEventListener("resize", debouncedSetWidth);

      return () => {
        window.removeEventListener("resize", debouncedSetWidth);
      };
    }, []);

    React.useEffect(() => {
      setLoaded(false);
      if (url) {
        setData({ url });
      } else if (uint8) {
        setData({ data: uint8 });
      } else if (b64) {
        setData({ data: atob(b64.split(",")[1]) });
      }
    }, [url, uint8, b64]);

    if (!data || (!url && !uint8 && !b64)) {
      return (
        <div className="w-full flex justify-center pt-[150px]">
          {loading
            ? "Laddar dokument..."
            : documentMissingTitle || "Dokument saknas"}
        </div>
      );
    }

    return (
      <SC.DocumentWrapper
        {...{ fullscreenFormLayout }}
        style={{ maxWidth: "100%" }}
        ref={containerRef}
      >
        <Document
          file={data}
          onLoadSuccess={onLoadSuccess}
          loading={() => (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: "150px",
              }}
            >
              Laddar dokument...
            </div>
          )}
        >
          <Page
            width={width}
            // height={height}
            pageNumber={pageNumber}
            renderInteractiveForms={false}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            loading={() => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "150px",
                }}
              >
                Laddar dokument...
              </div>
            )}
          />
        </Document>
        {loaded && (
          <PagePicker
            onChange={(page) => setPageNumber(page)}
            maxPage={pageAmount}
            currentPage={pageNumber}
          />
        )}
      </SC.DocumentWrapper>
    );
  }
);

export default Pdf;
