import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import CategoryFilter from "./Filters/CategoryFilter";

import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import { renderFloors } from "../../Forms/Base/FieldComponents/FloorSelector";

export default (realEstates) => [
  {
    Header: "Ytans ID",
    accessor: "common_area_id",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Kategori",
    accessor: "category",
    Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
    Filter: CategoryFilter,
    filter: "textExact",
  },
  {
    Header: "Adress",
    accessor: "address.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fastighet",
    accessor: "realestate.str_representation",
    Cell: ({ value }) => {
      return <ToolTipCell text={value} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Byggnad",
    accessor: "building.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },

  {
    Header: "Våning",
    accessor: "dynamic_floors",
    // for convenience, backend allow filtering on summed_floors
    id: "dynamicFloors",
    Cell: ({ value }) => <ToolTipCell text={renderFloors(value)} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Area (m2)",
    accessor: "area",
    Cell: ({ value }) => (
      <ToolTipCell text={(value || 0).toLocaleString("sv")} />
    ),
    Filter: NumberRangeFilter,
    filter: "between",
  },
];
