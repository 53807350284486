export default [
  {
    title: "Statusar på objekt",
    key: "STATUSES",
    visited: true,
    hasError: false,
    fieldKeys: ["customer_statuses"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
