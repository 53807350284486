import * as React from "react";
import { useHistory } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/users";

import { useSelector } from "react-redux";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function UsersTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  groups,
}) {
  const { push } = useHistory();
  const currentUserId = useSelector((state) => state.app.user?.id);

  const columns = React.useMemo(() => columnDefs(groups), [persistantQuery]);

  const badges = {};
  const filters = {
    Email: {
      queryKey: "email",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  return (
    <BaseTable
      tableId={tableId || "users_full_table"}
      title={"Användare"}
      {...{
        isBare,
        ignoreLocalStorage,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
        onRowClicked,
      }}
      onRowClicked={(row) => {
        if (onRowClicked) {
          onRowClicked(row);
        } else {
          const id = row?.id;
          if (id == currentUserId) {
            push("/configcenter/profile/user");
            return;
          }
          push(`/configcenter/groups-and-users/user/${id}`);
        }
      }}
    />
  );
}
