import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import classNames from "classnames";

export default function LocalSelectField({
  disabled,
  placeholder,
  title,
  description,
  error,
  required,
  choices,
  onChange,
  value,
  id,
  className = "",
  selectClassName = "",
  removePlaceholder = false,
  canClear = false,
  labelClassName = ""
}) {
  return (
    <div className={classNames(className)}>
      <label
        htmlFor={id || title}
        className={classNames("text-sm font-medium flex items-center text-gray-900", labelClassName)}
      >
        {error && (
          <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
        )}
        {title}
        {required ? "*" : ""}
      </label>
      {description && (
        <p
          id={`${id || title}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {error && (
        <div className="text-xs mb-1 font-normal text-red-600 ">{error}</div>
      )}
      <div className="flex items-center relative">
        <select
          disabled={disabled}
          id={id || title}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          style={{
            backgroundSize: "1.5em 1.5em",
          }}
          className={classNames(
            "bg-white appearance-none bg-no-repeat bg-[right_0.5rem_center] bg-chevrondown border border-solid border-slate-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ",
            selectClassName
          )}
        >
          {!removePlaceholder && (
            <option selected value={""}>
              {placeholder || "Välj ett alternativ..."}
            </option>
          )}

          {choices?.map((choice) => {
            if (choice.choices) {
              return (
                <optgroup label={choice.d}>
                  {choice.choices.map((_choice) => (
                    <option key={_choice.v} value={_choice.v}>
                      {_choice.d}
                    </option>
                  )
                  )}
                </optgroup>
              )
            }
            return (
              <option key={choice.v} value={choice.v}>
                {choice.d}
              </option>
            );
          })}
        </select>
        {canClear && value && (
          <button
            className="absolute right-[35px] text-black border-r border-solid border-gray-300 pr-2 h-[50%]"
            onClick={() => onChange("")}
            style={{
              borderLeft: "none",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <XMarkIcon width={16} />
          </button>
        )}
      </div>
    </div>
  );
}
