import * as React from "react";

// style, design

import columnDefs from "./billectaListDefs";

// store, state
import { constants, detailUrl } from "../../../store/companies";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";

export default ({
  billectaEvents,
  persistantQuery,
  isBare,
  tableId,
  onRowClicked,
  checkRowHighlighted,
}) => {
  const columns = React.useMemo(
    () => columnDefs(billectaEvents),
    [persistantQuery, billectaEvents]
  );

  const filters = [];

  return (
    <>
      <BaseTable
        tableId={tableId || "billectacompany_full_table"}
        title={"Bolag"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
            showInvoicingSettingPrompt: true,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage: true,
          onRowClicked,
          columns,
          persistantQuery,
          filters,
          constants,
          checkRowHighlighted,
        }}
      />
    </>
  );
};
