import { insertIntoAll } from "../../base";
import { constants as servicePartnerConstants } from "../../servicePartners";

const handleUpdateConnectedStateOnCreate = ({
  sentData,
  receivedData,
  dispatch,
}) => {
  if (!receivedData) return;

  if (!sentData.opponent_party?.id && receivedData.opponent_party) {
    dispatch(
      insertIntoAll(servicePartnerConstants, receivedData.opponent_party)
    );
  }
};

export { handleUpdateConnectedStateOnCreate };
