import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Table from "../../../components/Billecta/Table/BasicTable";
import BackendTable from "../../../components/Lists/Base/CompleteList/Table";

import leaseListDefs from "src/components/Tables/LeaseContracts/listDefs";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { RemoveButton } from "../../../components/Forms/RoundingErrandSetting/styles";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  detailUrl as apartmentDetailUrl,
  useFilteredApartments,
} from "../../../store/apartments";
import { detailUrl as companyDetailUrl } from "../../../store/companies";
import {
  detailUrl as indpDetailUrl,
  useFilteredIndustrialPremises,
} from "../../../store/industrialPremises";
import { createMany as leaseCreateMany } from "../../../store/invoicingLease";
import { createMany as parkingCreateMany } from "../../../store/invoicingParking";
import { detailUrl as invoicingSettingDetailUrl } from "../../../store/invoicingSettings";
import {
  constants as leaseContractConstants,
  performFilter as leaseContractPerformFilter,
  useLeaseContractPagination,
} from "../../../store/leaseContracts";
import {
  detailUrl as parkingDetailUrl,
  useFilteredParkingSpots,
} from "../../../store/parkingSpots";
import {
  constants,
  getLeadCostProposals,
  insertIntoCreatedContracts,
  pickTemplateUrl,
  removeCostProposal,
  removeFromCreatedContracts,
  updateCostProposal,
} from "../../../store/pipes";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { TOAST_TYPES, addToast } from "../../../store/toasts";

import moment from "moment";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import StandardModal from "../../../components/Modals/StandardModal";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { buildQueryString } from "../../../store/base";
import HandleCostProposal from "./HandleCostProposal";

export default function LeadsCostsProposals() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { goBack, push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectorOpen, setSelectorOpen] = React.useState(false);

  const { type } = useParams();
  const key = type === "leasecontracts" ? "lease_contract" : "parking_contract";

  const costProposals = useSelector((state) => state[storeName].costProposals);

  const createdContracts = useSelector(
    (state) => state[storeName].createdContracts
  );
  const costProposalsWithStartDate = React.useMemo(() => {
    return costProposals?.map((cp) => {
      const contract =
        createdContracts?.find((c) => c.id === cp[key]?.id) || {};
      return {
        ...cp,
        start_date:
          cp.start_date ??
          contract?.start_date ??
          moment().format("YYYY-MM-DD"),
      };
    });
  }, [costProposals]);

  const apartmentIdsInContracts = createdContracts?.reduce((acc, cur) => {
    if (cur?.apartments?.length)
      return [...acc, ...cur.apartments.map((a) => a.id)];

    return acc;
  }, []);
  const indpIdsInContracts = createdContracts?.reduce((acc, cur) => {
    if (cur?.industrial_premises_list?.length)
      return [...acc, ...cur.industrial_premises_list.map((a) => a.id)];

    return acc;
  }, []);
  const parkingIdsInContracts = createdContracts?.reduce((acc, cur) => {
    if (cur?.parking_spots?.length)
      return [...acc, ...cur.parking_spots.map((a) => a.id)];

    return acc;
  }, []);

  const apartmentQ = buildQueryString({
    id__in: apartmentIdsInContracts || [],
  });
  const indpQ = buildQueryString({
    id__in: indpIdsInContracts || [],
  });
  const parkingQ = buildQueryString({
    id__in: parkingIdsInContracts || [],
  });

  const [apartments] = useFilteredApartments(apartmentQ);
  const [industrialPremises] = useFilteredIndustrialPremises(indpQ);
  const [parkingSpots] = useFilteredParkingSpots(parkingQ);

  const currentEditProposal = costProposalsWithStartDate?.find(
    (p) => p._reduxId == editId
  );
  const currentEditProposalDetails = {
    _contract: {
      ...(createdContracts?.find(
        (c) => c.id === currentEditProposal?.[key]?.id
      ) || {}),
      apartments: createdContracts
        ?.find((c) => c.id === currentEditProposal?.[key]?.id)
        ?.apartments?.map((ap) => apartments?.find((a) => a.id == ap.id)),
      industrial_premises_list: createdContracts
        ?.find((c) => c.id === currentEditProposal?.[key]?.id)
        ?.industrial_premises_list?.map((ap) =>
          industrialPremises?.find((a) => a.id == ap.id)
        ),
      parking_spots: createdContracts
        ?.find((c) => c.id === currentEditProposal?.[key]?.id)
        ?.parking_spots?.map((ap) => parkingSpots?.find((a) => a.id == ap.id)),
    },
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const data = React.useMemo(() => {
    return costProposalsWithStartDate?.map((cp) => {
      return {
        ...cp,
        _contract: {
          ...(createdContracts?.find((c) => c.id === cp[key]?.id) || {}),
          apartments: createdContracts
            ?.find((c) => c.id === cp[key]?.id)
            ?.apartments?.map((ap) => apartments?.find((a) => a.id == ap.id)),
          industrial_premises_list: createdContracts
            ?.find((c) => c.id === cp[key]?.id)
            ?.industrial_premises_list?.map((ap) =>
              industrialPremises?.find((a) => a.id == ap.id)
            ),
          parking_spots: createdContracts
            ?.find((c) => c.id === cp[key]?.id)
            ?.parking_spots?.map((ap) =>
              parkingSpots?.find((a) => a.id == ap.id)
            ),
        },
      };
    });
  }, [
    costProposalsWithStartDate,
    createdContracts,
    apartments,
    industrialPremises,
    parkingSpots,
  ]);

  const removeProposal = (id) => {
    dispatch(removeCostProposal({ id }));

    const contract = createdContracts?.find((c) => c.id == id);

    if (contract) {
      dispatch(removeFromCreatedContracts({ contract }));
    }
  };

  const updateProposalAttr = ({ attr, val }) => {
    const newProposal = { ...currentEditProposal, [attr]: val };
    dispatch(updateCostProposal({ id: editId, newProposal }));
  };

  const createCosts = () => {
    setLoading(true);
    const postData = costProposalsWithStartDate;

    const method =
      type === "leasecontracts" ? leaseCreateMany : parkingCreateMany;

    dispatch(
      method({
        postData,
        successCallback: (data) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Kostnadsinställningar sparades",
              description: "Avtalen är nu redo för signeringsdokument",
            })
          );

          push(pickTemplateUrl({ type }));
        },
        errorCallback: (errors) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                "Kostnadsinställningarna sparades ej. Kontrollera de rödmarkerade raderna och försök igen",
            })
          );
          console.log({ errors });
        },
      })
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "remove",
        Cell: ({ row }) => {
          return (
            <RemoveButton
              onClick={() =>
                removeProposal(
                  row.original._reduxId,
                  row.original._contract?.id
                )
              }
            />
          );
        },
      },

      {
        Header: "Avtals-ID",
        accessor: "_contract.id_number",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Startdatum för avisering",
        accessor: "start_date",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Hyresgäst",
        accessor: "_contract.tenant",
        Cell: ({ value }) => {
          if (!value) return "-";
          return (
            <TextButton
              title={value?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(tenantDetailUrl({ id: value?.id }))}
            />
          );
        },
      },
      {
        Header: "Objekt",
        accessor: (row) =>
          row._contract?.apartments?.[0]?.str_representation ||
          row._contract?.industrial_premises_list?.[0]?.str_representation ||
          row._contract?.parking_spots?.[0]?.str_representation,
        Cell: ({ row }) => {
          const obj =
            row.original._contract?.apartments?.[0] ||
            row.original._contract?.industrial_premises_list?.[0] ||
            row.original._contract?.parking_spots?.[0];

          const detailUrl = row.original._contract?.apartments?.length
            ? apartmentDetailUrl
            : row.original._contract?.industrial_premises_list?.length
            ? indpDetailUrl
            : parkingDetailUrl;

          if (!obj) return "";

          return (
            <TextButton
              title={obj?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(detailUrl({ id: obj.id }))}
            />
          );
        },
      },

      {
        Header: "Fakturerande bolag",
        accessor: "billing_company",
        Cell: ({ value }) => {
          if (!value) return "-";

          return (
            <TextButton
              title={value?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(companyDetailUrl({ id: value?.id }))}
            />
          );
        },
      },

      {
        Header: "Aviseringsinställning",
        accessor: "setting",
        Cell: ({ value }) => {
          if (!value) return "-";
          return (
            <TextButton
              title={value?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() =>
                openInNewTab(invoicingSettingDetailUrl({ id: value?.id }))
              }
            />
          );
        },
      },

      {
        Header: "Antal debiteringsrader",
        accessor: (row) =>
          (row.cost_set?.length || 0) +
            (row._contract?.apartments?.[0]?.cost_set?.filter(
              (c) => !c?.do_not_debit
            )?.length || 0) +
            (row._contract?.industrial_premises_list?.[0]?.cost_set?.filter(
              (c) => !c?.do_not_debit
            )?.length || 0) +
            (row._contract?.parking_spots?.[0]?.cost_set?.filter(
              (c) => !c?.do_not_debit
            )?.length || 0) || "Saknar debiteringsrader",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },

      {
        Header: "Bashyra/månad",
        accessor: (row) =>
          (row.cost_set
            ?.filter((c) => c.product?.category === 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0) +
          (row._contract?.apartments?.[0]?.cost_set
            ?.filter((c) => c.product?.category === 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0) +
          (row._contract?.industrial_premises_list?.[0]?.cost_set
            ?.filter((c) => c.product?.category === 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0) +
          (row._contract?.parking_spots?.[0]?.cost_set
            ?.filter((c) => c.product?.category === 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0),
        Cell: ({ value }) => {
          return <div>{toMoneyString(value, true)}</div>;
        },
      },

      {
        Header: "Övriga kostnader/månad",
        accessor: (row) =>
          (row.cost_set
            ?.filter((c) => c.product?.category !== 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0) +
          (row._contract?.apartments?.[0]?.cost_set
            ?.filter((c) => c.product?.category !== 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0) +
          (row._contract?.industrial_premises_list?.[0]?.cost_set
            ?.filter((c) => c.product?.category !== 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0) +
          (row._contract?.parking_spots?.[0]?.cost_set
            ?.filter((c) => c.product?.category !== 1 && !c?.do_not_debit)
            ?.map((c) => c.value || 0)
            ?.reduce((acc, cur) => acc + cur, 0) || 0),
        Cell: ({ value }) => {
          return <div>{toMoneyString(value, true)}</div>;
        },
      },
    ],
    [createdContracts, costProposalsWithStartDate]
  );

  const checkRowError = (row) => {
    return false;
  };

  const checkRowWarning = (row) => {
    const lacksCosts =
      !row.original.cost_set?.filter((c) => c.product?.category === 1)?.length >
        0 &&
      !row.original._contract?.apartments?.[0]?.cost_set?.filter(
        (c) => c.product?.category === 1
      )?.length > 0 &&
      !row.original._contract?.industrial_premises_list?.[0]?.cost_set?.filter(
        (c) => c.product?.category === 1
      )?.length > 0 &&
      !row.original._contract?.parking_spots?.[0]?.cost_set?.filter(
        (c) => c.product?.category === 1
      )?.length > 0;

    return lacksCosts;
  };

  const checkRowHighlighted = (row) => {
    const match = createdContracts?.find((cc) => cc.id == row?.original?.id);

    return !!match;
  };

  const onRowSelected = (row) => {
    if (checkRowHighlighted(row)) {
      dispatch(removeFromCreatedContracts({ contract: row.original }));
    } else {
      dispatch(insertIntoCreatedContracts({ contract: row.original }));
    }
  };
  const leaseColumns = React.useMemo(() => leaseListDefs({}), []);

  const leaseQuery = buildQueryString({
    lease_invoicing__isnull: true,
  });
  const leaseFilter = (obj) => !obj.lease_invoicing;

  const handleGetCostProposals = () => {
    setLoading(true);

    setSelectorOpen(false);

    dispatch(
      getLeadCostProposals({
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
        type,
        createdContracts,
      })
    );
  };

  return (
    <>
      <StandardModal
        isOpen={selectorOpen}
        closeFunction={handleGetCostProposals}
        title="Välj avtal"
        withActionBar
        actionBarCancelTitle="Klar"
      >
        <BackendTable
          storeName={leaseContractConstants.STORE_NAME}
          columns={leaseColumns}
          persistantQueryString={leaseQuery}
          persistantFilterMethod={leaseFilter}
          paginationMethod={useLeaseContractPagination}
          filterAction={leaseContractPerformFilter}
          {...{
            hideExport: true,
            hideColumns: true,
            onRowClicked: onRowSelected,
            checkRowHighlighted,
          }}
        />
      </StandardModal>

      {confirmOpen && (
        <ConfirmationModal
          isOpen={confirmOpen}
          title="Bekräfta skapande av kostnader"
          renderContent={() => (
            <div>
              {costProposalsWithStartDate?.length} kostnadsinställningar kommer
              att sättas upp. I nästa steg hanteras dokument för signering.
            </div>
          )}
          closeFunction={() => setConfirmOpen(false)}
          acceptCallback={createCosts}
        />
      )}

      {costProposalsWithStartDate?.length > 0 && currentEditProposal && (
        <HandleCostProposal
          {...{
            currentEditProposal,
            currentEditProposalDetails,
            updateProposalAttr,
            editId,
            setEditId,
          }}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            extraStyle={{ marginBottom: 8 }}
            title="Gå tillbaka till projektet"
            clicked={() => goBack()}
            iconType="arrow-back"
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Kostnadsförslag</OverviewTitle>
              <OverviewSubtitle>
                Kontrollera kostnadsförslagen för avtalen. Tryck på en rad för
                att ändra i kostnaderna. Tryck på "Spara inställningar" när du
                är klar. I nästa steg kommer dokument för signering att sättas
                upp. <br />
                <StatusLabel state={3}>Gulmarkerade</StatusLabel> rader
                indikerar på att bashyra saknas.
                <br />
                <StatusLabel state={6}>Rödmarkerade</StatusLabel> rader
                indikerar på fel i skapandet. Tryck på "Redigera" för detaljerad
                information om felet på en rad.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {data?.length > 0 ? (
            <>
              <Table
                onRowClicked={(row) => setEditId(row.original._reduxId)}
                data={data}
                columns={columns}
                checkRowError={checkRowError}
                checkRowWarning={checkRowWarning}
              />
            </>
          ) : (
            <InnerBox
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 42,
                textAlign: "center",
              }}
            >
              Inga avtal specificerade.
              <br /> Gå tillbaka till projektet för att komma igång med
              avtalsskapandet från grunden, eller välj avtal utan
              kostnadsinställningar att ställa in för.
              <br />
              <div style={{ marginTop: 12 }}>
                <PrimaryButton
                  title="Välj avtal"
                  clicked={() => setSelectorOpen(true)}
                />
              </div>
            </InnerBox>
          )}

          {data?.length > 0 ? (
            <div
              style={{
                display: "flex",
                marginTop: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                title="Spara inställningar"
                clicked={() => setConfirmOpen(true)}
              />{" "}
            </div>
          ) : (
            <></>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
