import * as React from "react";

import columnDefs from "./listDefs";

import { constants, detailUrl } from "../../../store/buildings";
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import { OPERATOR_TEMPLATES } from "../../Lists/Base/FinalTable/utils";

export default function BuildingTable({
  persistantQuery,
  tableId,
  ignoreLocalStorage,
  isBare,
  checkRowHighlighted,
  onRowClicked,
}) {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const filters = {
    Adress: {
      queryKey: "address__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Fastighet: {
      queryKey: "realestate__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  return (
    <BaseTable
      tableId={tableId || "buildings_full_table"}
      title="Byggnader"
      onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
      {...{
        checkRowHighlighted,
        onRowClicked,
        ignoreLocalStorage,
        isBare,
        filters,
        constants,
        columns,
        persistantQuery,
      }}
    />
  );
}
