import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import {
  authenticateHomeQ,
  useIsHomeQAuthenticated,
  syncHomeQ,
  collectHomeQ,
  deleteAuthHomeQ,
  updateAuthHomeQ,
} from "../../../store/homeQ";
import { useHistory } from "react-router-dom";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useDispatch } from "react-redux";
import Toggle from "../../../components/Toggle/Toggle";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import HomeQApartmentTable from "../../../components/Tables/Apartments/HomeQFullTable";
import RealEstateHomeQTable from "../../../components/Tables/RealEstate/HomeQFullTable";
import RequirementsHomeQTable from "../../../components/Tables/MarketRequirements/HomeQFullTable";
import { InfoBox } from "../../../components/Displays";

const SYNC_GUIDE_STEPS = {
  INTRO: 1,
  SYNC: 2,
  DONE: 3,
};

const FETCH_GUIDE_STEPS = {
  INTRO: 1,
  GET: 2,
  DONE: 3,
};

const TABS = {
  REALESTATES: "Fastigheter",
  APARTMENTS: "Lägenheter",
  REQUIREMENTS: "Kravprofiler",
};
export default function HomeQIntegration() {
  const dispatch = useDispatch();
  const current = INTEGRATIONS.find((i) => i.title === "HomeQ");

  const [selectedTab, setSelectedTab] = React.useState("REALESTATES");
  const [showOnlySynced, setShowOnlySynced] = React.useState(false);
  const [activateOpen, setActivateOpen] = React.useState(false);
  const [updateAuthOpen, setUpdateAuthOpen] = React.useState(false);
  const [confirmInactivateOpen, setConfirmInactivateOpen] =
    React.useState(false);
  const [homeQUsername, setHomeQUsername] = React.useState(false);
  const [homeQPassword, setHomeQPassword] = React.useState(false);
  const [activateLoading, setActivateLoading] = React.useState(false);
  const [getStartedSyncOpen, setGetStartedSyncOpen] = React.useState(false);
  const [getStartedFetchOpen, setGetStartedFetchOpen] = React.useState(false);
  const [guideStepSync, setGuideStepSync] = React.useState(
    SYNC_GUIDE_STEPS.INTRO
  );
  const [guideStepFetch, setGuideStepFetch] = React.useState(
    FETCH_GUIDE_STEPS.INTRO
  );
  const [syncRealEstates, setSyncRealestates] = React.useState(true);
  const [syncApartments, setSyncApartments] = React.useState(true);

  const [syncRequirements, setSyncRequirements] = React.useState(true);

  const [syncLoading, setSyncLoading] = React.useState(false);
  const [homeQActivated] = useIsHomeQAuthenticated();
  const customerHasHomeQ = usePermissionCheck("allow_homeq");
  const customerHasMarket = usePermissionCheck("allow_market");
  const hasPerm = customerHasHomeQ && customerHasMarket;
  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const persistantQuery = {
    "homeq_id__isnull!": showOnlySynced,
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  const authenticate = () => {
    setActivateLoading(true);
    dispatch(
      authenticateHomeQ({
        username: homeQUsername,
        password: homeQPassword,
        successCallback: () => {
          setActivateOpen(false);
          setActivateLoading(false);
          setGetStartedSyncOpen(true);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Integrationen aktiverades",
              description:
                "Följ instruktionerna för integrationen för att komma igång",
            })
          );
        },
        errorCallback: () => {
          setActivateLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Aktivering misslyckades",
              description:
                "Kontrollera användarnamn och lösenord och försök igen",
            })
          );
        },
      })
    );
  };

  const updateAuth = () => {
    setActivateLoading(true);
    dispatch(
      updateAuthHomeQ({
        username: homeQUsername,
        password: homeQPassword,
        successCallback: () => {
          setActivateOpen(false);
          setActivateLoading(false);
          setGetStartedSyncOpen(true);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Integrationen uppdaterades",
              description:
                "Följ instruktionerna för integrationen för att komma igång",
            })
          );
        },
        errorCallback: () => {
          setActivateLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdatering misslyckades",
              description:
                "Kontrollera användarnamn och lösenord och försök igen",
            })
          );
        },
      })
    );
  };

  const syncGuideStepIntro = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Kom igång med HomeQ</OverviewTitle>
          <OverviewSubtitle>
            För att komma igång ordentligt med integrationen och se till att all
            data synkas ordentligt så rekommenderar Pigello att följa denna
            guide. Tryck på <strong>Starta guide</strong> för att starta guiden.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <strong>Steg i guide</strong>
      <ul>
        <li>
          1. Synka data mellan Pigello och HomeQ. Detta förhindrar att dubletter
          skapas när HomeQ-data i nästa guide hämtas till Pigello.
        </li>
        <li>
          2. Kontrollera att all önskad data synkades ordentligt. Gå igenom
          <strong>fastigheter, lägenheter och kravprofiler</strong> i tabellen
          nedanför. När denna data är kontrollerad och stämmer kan nästa guide
          gås igenom, där HomeQ data som ännu inte finns hos Pigello kan hämtas
          in utan risk för dubletter.
        </li>
      </ul>
      <br />
      <br />
      <strong>OBS: </strong>Har ni ingen data i HomeQ än? Då kan denna guide
      skippas genom att trycka på "Hoppa över guide".
    </>
  );

  const fetchGuideStepIntro = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Kom igång med HomeQ</OverviewTitle>
          <OverviewSubtitle>
            För att komma igång ordentligt med integrationen och se till att all
            data hämtas ordentligt så rekommenderar Pigello att följa denna
            guide. Tryck på <strong>Starta guide</strong> för att starta guiden.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <strong>Steg i guide</strong>
      <ul>
        <li>
          1. Hämta data till Pigello från HomeQ. Detta säkerställer att all
          relevant data finns i båda systemen.
        </li>
        <li>2. HomeQ är nu redo att användas via Pigello</li>
      </ul>
      <br />
      <br />
      <strong>OBS: </strong>Har ni ingen data i HomeQ än? Då kan denna guide
      skippas genom att trycka på "Hoppa över guide".
    </>
  );

  const syncStep = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Synka data mellan Pigello och HomeQ</OverviewTitle>
          <OverviewSubtitle>
            I detta steg kopplas data från Pigello mot data från HomeQ för att
            hålla de uppdaterade och förhindra dubletter vid datainhämtning
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Instruktioner</OverviewTitle>
        </OverviewTitleWrapper>

        <InnerBox style={{ marginBottom: 12 }}>
          <BodyText>
            1. Välj vilken typ av data som ska synkas mellan de två systemen.
          </BodyText>
          <Toggle
            extraTitleStyle={{
              fontSize: 14,
              marginBottom: 12,
              marginTop: 12,
              fontWeight: 500,
              marginRight: 6,
            }}
            title="Fastigheter"
            value={syncRealEstates}
            onToggle={() => setSyncRealestates(!syncRealEstates)}
          />
          <Toggle
            extraTitleStyle={{
              fontSize: 14,
              marginBottom: 12,
              fontWeight: 500,
              marginRight: 6,
            }}
            title="Lägenheter"
            value={syncApartments}
            onToggle={() => setSyncApartments(!syncApartments)}
          />
          <Toggle
            extraTitleStyle={{
              fontSize: 14,
              marginBottom: 12,
              fontWeight: 500,
              marginRight: 6,
            }}
            title="Kravprofiler"
            value={syncRequirements}
            onToggle={() => setSyncRequirements(!syncRequirements)}
          />
        </InnerBox>

        <BodyText>
          2. Tryck på <strong>Synka Pigello mot HomeQ</strong> för att komma
          igång.
        </BodyText>
      </InnerBox>
    </>
  );

  const guideStepCollect = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Hämta data från HomeQ</OverviewTitle>
          <OverviewSubtitle>
            I detta steg hämtas objekt som ej finns i Pigello från HomeQ. Efter
            att de har hämtats hålls de automatiskt synkade i framtiden.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Instruktioner</OverviewTitle>
        </OverviewTitleWrapper>
        <InnerBox style={{ marginBottom: 12 }}>
          <BodyText>
            1. Välj vilken typ av data som ska hämtas från HomeQ.
          </BodyText>
          <Toggle
            extraTitleStyle={{
              fontSize: 14,
              marginBottom: 12,
              marginTop: 12,
              fontWeight: 500,
              marginRight: 6,
            }}
            title="Fastigheter"
            value={syncRealEstates}
            onToggle={() => setSyncRealestates(!syncRealEstates)}
          />
          <Toggle
            extraTitleStyle={{
              fontSize: 14,
              marginBottom: 12,
              fontWeight: 500,
              marginRight: 6,
            }}
            title="Lägenheter"
            value={syncApartments}
            onToggle={() => setSyncApartments(!syncApartments)}
          />
          <Toggle
            extraTitleStyle={{
              fontSize: 14,
              marginBottom: 12,
              fontWeight: 500,
              marginRight: 6,
            }}
            title="Kravprofiler"
            value={syncRequirements}
            onToggle={() => setSyncRequirements(!syncRequirements)}
          />
        </InnerBox>

        <BodyText>
          2. Tryck på <strong>Hämta data från Pigello till HomeQ</strong> för
          att slutföra.
        </BodyText>
      </InnerBox>
    </>
  );

  const syncDoneStep = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Synkning slutförd</OverviewTitle>
          <OverviewSubtitle>
            Nästa steg är att kontrollera att datan synkades ordentligt, för att
            sedan hämta in data som finns i HomeQ men inte Pigello.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
    </>
  );
  const fetchDoneStep = (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Hämtning slutförd</OverviewTitle>
          <OverviewSubtitle>
            Nu är HomeQ-integrationen redo att användas i Pigello!
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
    </>
  );

  const sync = () => {
    setSyncLoading(true);
    const postData = {
      realestates: syncRealEstates,
      apartments: syncApartments,
      requirements: syncRequirements,
    };
    dispatch(
      syncHomeQ({
        postData,
        successCallback: () => {
          setSyncLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Data synkades mellan Pigello och HomeQ",
            })
          );

          setGuideStepSync(SYNC_GUIDE_STEPS.DONE);
        },
        errorCallback: () => {
          setSyncLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Data kunde ej synkas från HomeQ",
              description: "Kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const collect = () => {
    setSyncLoading(true);
    const postData = {
      realestates: syncRealEstates,
      apartments: syncApartments,
      requirements: syncRequirements,
    };
    dispatch(
      collectHomeQ({
        postData,
        successCallback: () => {
          setSyncLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Data hämtades HomeQ",
              description:
                "Objekten som hämtades synkas i framtiden automatiskt mellan Pigello och HomeQ",
            })
          );
          setGuideStepFetch(FETCH_GUIDE_STEPS.DONE);
        },
        errorCallback: () => {
          setSyncLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Data kunde ej hämtas från HomeQ",
              description: "Kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const inactivateAuth = () => {
    dispatch(
      deleteAuthHomeQ({
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Integrationen inaktiverades",
              description:
                "Synkningar mot HomeQ kommer ej längre att genomföras",
            })
          );
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Integrationen kunde ej inaktiveras",
              description: "Kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const continueSyncGuide = () => {
    switch (guideStepSync) {
      case SYNC_GUIDE_STEPS.INTRO:
        setGuideStepSync(SYNC_GUIDE_STEPS.SYNC);
        break;
      case SYNC_GUIDE_STEPS.SYNC:
        sync();
        break;
      case SYNC_GUIDE_STEPS.DONE:
        setGetStartedSyncOpen(false);
        setGuideStepSync(SYNC_GUIDE_STEPS.INTRO);
        break;
    }
  };

  const syncGuideTitles = () => {
    switch (guideStepSync) {
      case SYNC_GUIDE_STEPS.INTRO:
        return ["Starta guide", "Hoppa över guide"];
      case SYNC_GUIDE_STEPS.SYNC:
        return ["Synka Pigello mot HomeQ", "Avbryt guide"];

      case SYNC_GUIDE_STEPS.DONE:
        return ["Avsluta guide"];
    }
  };
  const continueFetchGuide = () => {
    switch (guideStepFetch) {
      case FETCH_GUIDE_STEPS.INTRO:
        setGuideStepFetch(FETCH_GUIDE_STEPS.GET);
        break;

      case FETCH_GUIDE_STEPS.GET:
        collect();
        break;
      case FETCH_GUIDE_STEPS.DONE:
        setGetStartedFetchOpen(false);
        setGuideStepFetch(FETCH_GUIDE_STEPS.INTRO);
        break;
    }
  };

  const fetchGuideTitles = () => {
    switch (guideStepFetch) {
      case FETCH_GUIDE_STEPS.INTRO:
        return ["Starta guide", "Hoppa över guide"];
      case FETCH_GUIDE_STEPS.GET:
        return ["Hämta data från HomeQ till Pigello", "Avbryt guide"];
      case FETCH_GUIDE_STEPS.DONE:
        return ["Avsluta guide"];
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={confirmInactivateOpen}
        title="Bekräfta inaktivering"
        renderContent={() => (
          <div>
            Vid inaktivering av integrationen upphör alla försök att synka data
            mot HomeQ
          </div>
        )}
        closeFunction={() => setConfirmInactivateOpen(false)}
        acceptCallback={inactivateAuth}
      />

      <StandardModal
        isOpen={activateOpen}
        closeFunction={() => {
          setGuideStepSync(SYNC_GUIDE_STEPS.INTRO);
          setActivateOpen(false);
        }}
        small
        withActionBar
        actionBarAcceptTitle="Aktivera integration"
        saveFunction={authenticate}
        canAccept={!!homeQPassword && !!homeQUsername}
        title="Aktivera HomeQ-integration"
      >
        {activateLoading && <OverlaySpinner />}
        <NonConnectedTextInput
          label="Användarnamn HomeQ"
          value={homeQUsername}
          onChange={(val) => setHomeQUsername(val)}
        />
        <NonConnectedTextInput
          type="password"
          label="Lösenord HomeQ"
          value={homeQPassword}
          onChange={(val) => setHomeQPassword(val)}
        />
      </StandardModal>

      <StandardModal
        isOpen={updateAuthOpen}
        closeFunction={() => {
          setGuideStepSync(SYNC_GUIDE_STEPS.INTRO);
          setUpdateAuthOpen(false);
        }}
        small
        withActionBar
        actionBarAcceptTitle="Uppdatera integration"
        saveFunction={updateAuth}
        canAccept={!!homeQPassword && !!homeQUsername}
        title="Uppdatera HomeQ-integration"
      >
        {activateLoading && <OverlaySpinner />}
        <NonConnectedTextInput
          label="Användarnamn HomeQ"
          value={homeQUsername}
          onChange={(val) => setHomeQUsername(val)}
        />
        <NonConnectedTextInput
          type="password"
          label="Lösenord HomeQ"
          value={homeQPassword}
          onChange={(val) => setHomeQPassword(val)}
        />
      </StandardModal>

      <StandardModal
        isOpen={getStartedSyncOpen}
        closeFunction={() => {
          setGetStartedSyncOpen(false);
          setGuideStepSync(SYNC_GUIDE_STEPS.INTRO);
        }}
        withActionBar
        title="Kom igång med HomeQ-integrationen"
        saveFunction={continueSyncGuide}
        actionBarAcceptTitle={syncGuideTitles()[0]}
        actionBarCancelTitle={syncGuideTitles()[1]}
      >
        {syncLoading && <OverlaySpinner />}
        {guideStepSync === SYNC_GUIDE_STEPS.INTRO && syncGuideStepIntro}
        {guideStepSync === SYNC_GUIDE_STEPS.SYNC && syncStep}
        {guideStepSync === SYNC_GUIDE_STEPS.DONE && syncDoneStep}
      </StandardModal>

      <StandardModal
        isOpen={getStartedFetchOpen}
        closeFunction={() => setGetStartedFetchOpen(false)}
        withActionBar
        title="Kom igång med HomeQ-integrationen"
        saveFunction={continueFetchGuide}
        actionBarAcceptTitle={fetchGuideTitles()[0]}
        actionBarCancelTitle={fetchGuideTitles()[1]}
      >
        {syncLoading && <OverlaySpinner />}
        {guideStepFetch === FETCH_GUIDE_STEPS.INTRO && fetchGuideStepIntro}
        {guideStepFetch === FETCH_GUIDE_STEPS.GET && guideStepCollect}
        {guideStepFetch === FETCH_GUIDE_STEPS.DONE && fetchDoneStep}
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök HomeQ´s hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  HomeQ-integrationen aktiveras genom att, efter att ha låst upp
                  integrationen, logga in mot HomeQ via Pigello. Efter detta kan
                  lägenheter, leads och fastigheter synkas mellan de två
                  systemen.
                  <br />
                  <br />
                  Så fungerar integrationen:
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Skapa en annons för en eller flera lägenheter och välj att
                  publicera på HomeQ.
                  <br />
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Annonsen publiceras på HomeQ och tillåter leads att ställa sig
                  i kö/göra intresseanmälan
                  <br />
                  <br />
                  <strong>Steg 3:</strong>
                  <br />
                  Inkomna leads hamnar automatiskt i det projektet som är
                  angivet på annonsen
                  <br />
                  <br />
                  <strong>Steg 4:</strong>
                  <br />
                  När en intressent är accepterad som hyresgäst i projekt så
                  stängs annonsen hos HomeQ och avtalet signeras enkelt direkt i
                  Pigello.
                </BodyText>
              </InnerBox>
            </DetailPageBox>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                maxWidth: "39%",
                alignSelf: "flex-start",
              }}
            >
              <DetailPageBox>
                {hasPerm !== true ? (
                  <>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Aktivera integration
                        </OverviewTitle>
                        <OverviewSubtitle>
                          HomeQ-integrationen aktiveras av Pigello på begäran.
                          Kontakta oss för att veta mer.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>
                    <TextButton
                      title="Kontakta oss"
                      iconType="arrow"
                      iconPlacement="right"
                      clicked={() => window.Intercom("show")}
                    />
                  </>
                ) : homeQActivated === false ? (
                  <>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Integration ej aktiverad
                        </OverviewTitle>
                        <OverviewSubtitle>
                          Aktivera integrationen genom att logga in med ert
                          HomeQ-konto.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>

                    <PrimaryButton
                      title="Aktivera HomeQ-integration"
                      clicked={() => setActivateOpen(true)}
                    />
                  </>
                ) : (
                  <>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>Integration aktiv</OverviewTitle>
                        <OverviewSubtitle>
                          HomeQ-integrationen är aktiv. Följ instruktionerna för
                          integrationen för att använda HomeQ.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>

                    <TextButton
                      extraStyle={{ marginTop: 12 }}
                      title="Uppdatera HomeQ-uppgifter"
                      iconType="edit"
                      iconPlacement="right"
                      clicked={() => setUpdateAuthOpen(true)}
                    />
                    <TextButton
                      red
                      extraStyle={{ marginTop: 12 }}
                      title="Inaktivera integration"
                      iconType="close"
                      iconPlacement="right"
                      clicked={() => setConfirmInactivateOpen(true)}
                    />
                  </>
                )}
              </DetailPageBox>

              {hasPerm && homeQActivated && (
                <DetailPageBox>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Hantera integration</OverviewTitle>
                      <OverviewSubtitle>
                        Hantera synkning och inhämtning av data mellan Pigello
                        och HomeQ
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <InnerBox style={{ marginBottom: 12 }}>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Synka data mellan Pigello och HomeQ
                        </OverviewTitle>
                        <OverviewSubtitle>
                          Kör "Kom igång"-guiden för att synka och hämta data
                          mellan de två systemen.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>

                    <TextButton
                      iconType="sync"
                      title="Synka data mellan Pigello och HomeQ"
                      clicked={() => setGetStartedSyncOpen(true)}
                    />
                  </InnerBox>
                  <InnerBox style={{ marginBottom: 12 }}>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Hämta data till Pigello från HomeQ
                        </OverviewTitle>
                        <OverviewSubtitle>
                          Kör "Kom igång"-guiden för att hämta data från HomeQ
                          till Pigello.
                        </OverviewSubtitle>
                      </OverviewTitleWithSubtitleWrapper>
                    </OverviewTitleWrapper>

                    <InfoBox
                      extraStyles={{ marginTop: 0, marginBottom: 24 }}
                      title="OBS"
                      text="Kontrollera att alla objekt som finns i HomeQ och Pigello har synkats i tabellen längst ner på sidan. Annars kommer det att skapas dubletter i Pigello när HomeQ-datan hämtas in."
                      boxTheme="warning"
                    />

                    <TextButton
                      iconType="sync"
                      title="Hämta data till Pigello från HomeQ"
                      clicked={() => setGetStartedFetchOpen(true)}
                    />
                  </InnerBox>
                </DetailPageBox>
              )}
            </div>
          </DetailPageBoxFlexWrapper>

          {homeQActivated && (
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Synkstatus på objekt</OverviewTitle>
                  <OverviewSubtitle>
                    Överblick över status på synk mellan objekt i HomeQ och
                    Pigello. När denna datan stämmer så kan objekt hämtas från
                    HomeQ utan risk för dubletter i Pigello.
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>

                <PageSlider
                  {...{
                    selectedTab,
                    onTabSelected: (tab) => setSelectedTab(tab),
                    TABS,
                  }}
                />
              </OverviewTitleWrapper>

              <InnerBox>
                <Toggle
                  extraTitleStyle={{
                    fontSize: 14,
                    marginBottom: 12,
                    fontWeight: 500,
                    marginRight: 6,
                  }}
                  title="Visa enbart synkade objekt"
                  value={showOnlySynced}
                  onToggle={() => setShowOnlySynced(!showOnlySynced)}
                />
              </InnerBox>
              {selectedTab === "REALESTATES" && (
                <RealEstateHomeQTable {...{ persistantQuery }} />
              )}

              {selectedTab === "APARTMENTS" && (
                <HomeQApartmentTable {...{ persistantQuery }} />
              )}
              {selectedTab === "REQUIREMENTS" && (
                <RequirementsHomeQTable {...{ persistantQuery }} />
              )}
            </DetailPageBox>
          )}
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
