import * as React from "react";

export default () => {
  return (
    <div>
      Ange hur ej avvikande komponenter ska markeras, samt allmän kommentar
      kring ronderingen.
      <br />
      <br />
    </div>
  );
};
