import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { usePermissionCheck } from "../../../store/base";
import { editUrl, useParkingLot } from "../../../store/parkingLots";
import ParkingSpotList from "../../../components/Tables/Parking/ParkingSpots/FullTable";
import RealEstateList from "../../../components/Tables/RealEstate/FullTable";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

export default function ParkingLotOverview() {
  const { parkingLotId } = useParams();
  const [parkingLot] = useParkingLot(parkingLotId);
  const { push } = useHistory();
  const canEdit = usePermissionCheck("change_can_parking");

  const parkingLotName = React.useMemo(() => {
    return parkingLot?.str_representation.replace("Zone", "Zon");
  }, [parkingLot]);

  const realEstateQuery = {
    id__in: parkingLot?.realestates?.map((r) => r.id) || [],
  };

  const persistantQuery = {
    parking_lot: parkingLotId,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Översikt</OverviewTitle>

          {canEdit && (
            <PrimaryButton
              title="Redigera"
              clicked={() => push(editUrl({ id: parkingLotId }))}
            />
          )}
        </OverviewTitleWrapper>

        <DetailInfo
          infoObj={getInfoObj({
            parkingLot,
            parkingLotName,
          })}
        />
      </DetailPageBox>

      <DetailPageBoxFlexWrapper>
        <DetailPageBox style={{ width: "59%", alignSelf: "flex-start" }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Fordonsplatser på området</OverviewTitle>
          </OverviewTitleWrapper>
          <ParkingSpotList persistantQuery={persistantQuery} />
        </DetailPageBox>

        <DetailPageBox style={{ width: "40%", alignSelf: "flex-start" }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Tillhör fastigheter</OverviewTitle>
          </OverviewTitleWrapper>
          <RealEstateList persistantQuery={realEstateQuery} noSelect />
        </DetailPageBox>
      </DetailPageBoxFlexWrapper>
    </DetailInnerWrapper>
  );
}

function getInfoObj({ parkingLot, parkingLotName }) {
  const infoObj = {};

  infoObj["Översikt"] = [
    {
      title: "Namn",
      value: parkingLotName,
    },

    {
      title: "Antal fordonsplatser",

      value: parkingLot?.spot_amount,
    },
    {
      title: "Kategori",

      value: parkingLot?.specification?.category_display,
    },
    {
      title: "Typ av plats",

      value: parkingLot?.specification?.vehicle_support_display,
    },
  ];

  infoObj["Specifikation & regler"] = [
    {
      title: "Specifikation",
      value: parkingLot?.specification?.str_representation || "Ingen vald",
    },
    {
      title: "Max höjd (CM)",
      value: parkingLot?.specification?.height_limit || "-",
    },
    ,
    {
      title: "Max bredd (CM)",
      value: parkingLot?.specification?.width_limit || "-",
    },
    {
      title: "Max längd (CM)",
      value: parkingLot?.specification?.length_limit || "-",
    },
    {
      title: "Max vikt (KG)",
      value: parkingLot?.specification?.weight_limit || "-",
    },
  ];

  return infoObj;
}
