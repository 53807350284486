import * as React from "react";
import { Wrapper } from "../";
import { Link } from "react-router-dom";
import {
  BanknotesIcon,
  MapPinIcon,
  TruckIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import { toMoneyString } from "../../utils/stringUtils";
import { useParkingSpot, detailUrl } from "../../../store/parkingSpots";
import { detailUrl as parkingLotDetailUrl } from "../../../store/parkingLots";

export default function SmallParkingSpotSummary({ id, className }) {
  const [parking, parkingLoading] = useParkingSpot(id);

  if (!id) return null;

  return (
    <Wrapper className={className}>
      <div className="flex">
        <div className="mr-3 shrink-0">
          <TruckIcon width={32} className="text-slate-500" />
        </div>
        <div>
          <div className="mb-1 text-base font-semibold leading-none text-gray-700 hover:underline">
            <Link to={detailUrl({ id })}>
              {parking ? `Fordonsplats ${parking?.title}` : "Laddar..."}
            </Link>
          </div>

          <p className="mb-3 text-sm font-normal">
            {parking?.category_display}
          </p>

          <ul className="text-sm font-light">
            <li className="flex items-center mb-1">
              <MapPinIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />

              <span>{parking?.address?.base?.split(",")?.[0] || "-"}</span>
            </li>
            <li className="flex items-center mb-1">
              <ViewColumnsIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <Link
                to={parkingLotDetailUrl({ id: parking?.parking_lot?.id })}
                className="text-blue-600 hover:underline"
              >
                {parking?.parking_lot?.str_representation || "-"}
              </Link>
            </li>

            <li className="flex items-center mb-1">
              <BanknotesIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />
              <span>{toMoneyString(parking?.rent, true) || "-"}</span>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}
