import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import NonConnectedDatePicker from "../../../Base/Old/NonConnected/NonConnectedDatePicker";

import NonConnectedRadioGroup from "../../../Base/Old/NonConnected/NonConnectedRadioGroup";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";

export default ({ storeName, updateDefaultSettings }) => {
  const dispatch = useDispatch();

  const defaultSettingsInstance = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const updateAttr = ({ attr, val }) => {
    dispatch(updateDefaultSettings({ attr, val }));
  };

  return (
    <>
      <NonConnectedDatePicker
        label="Renoverades senast"
        value={defaultSettingsInstance.renovation_date}
        onChange={(val) => {
          updateAttr({ attr: "renovation_date", val });
        }}
        clearable={false}
        id={`renovation_date`}
      />

      <NonConnectedRadioGroup
        id={`elevator`}
        title="Hiss finns"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.elevator}
        onChange={(val) => {
          updateAttr({ attr: "elevator", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`pantry`}
        title="Skafferi finns"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.pantry}
        onChange={(val) => {
          updateAttr({ attr: "pantry", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`patio`}
        title="Uteplats finns "
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.patio}
        onChange={(val) => {
          updateAttr({ attr: "patio", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`handicap_adapted`}
        title="Handikanpassat"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.handicap_adapted}
        onChange={(val) => {
          updateAttr({ attr: "handicap_adapted", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`accessibility_adapted`}
        title="Tillgänglighetsanpassad"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.accessibility_adapted}
        onChange={(val) => {
          updateAttr({ attr: "accessibility_adapted", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`security_door`}
        title="Säkerhetsdörr"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.security_door}
        onChange={(val) => {
          updateAttr({ attr: "security_door", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`laundry_room`}
        title="Tvättstuga"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.laundry_room}
        onChange={(val) => {
          updateAttr({ attr: "laundry_room", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`shower`}
        title="Dusch"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.shower}
        onChange={(val) => {
          updateAttr({ attr: "shower", val });
        }}
      />
      <NonConnectedRadioGroup
        id={`bed_in_wall`}
        title="Utfällbar säng"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.bed_in_wall}
        onChange={(val) => {
          updateAttr({ attr: "bed_in_wall", val });
        }}
      />
      <NonConnectedRadioGroup
        id={`stove`}
        title="Spis"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.stove}
        onChange={(val) => {
          updateAttr({ attr: "stove", val });
        }}
      />
      <NonConnectedRadioGroup
        id={`gas_cooker`}
        title="Gasspis"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.gas_cooker}
        onChange={(val) => {
          updateAttr({ attr: "gas_cooker", val });
        }}
      />
      <NonConnectedRadioGroup
        id={`tiled_stove`}
        title="Öppen spis"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.tiled_stove}
        onChange={(val) => {
          updateAttr({ attr: "tiled_stove", val });
        }}
      />

      <NonConnectedSelect
        id="kitchen"
        choices={KITCHEN_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Typ av kök"
        value={defaultSettingsInstance.kitchen}
        onUpdate={(val) => {
          updateAttr({ attr: "kitchen", val });
        }}
      />

      <NonConnectedSelect
        id="balcony"
        choices={BALCONY_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Balkong"
        value={defaultSettingsInstance.balcony}
        onUpdate={(val) => {
          updateAttr({ attr: "balcony", val });
        }}
      />

      <NonConnectedSelect
        id="kitchen_fan"
        choices={MULTI_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Köksfläkt"
        value={defaultSettingsInstance.kitchen_fan}
        onUpdate={(val) => {
          updateAttr({ attr: "kitchen_fan", val });
        }}
      />

      <NonConnectedSelect
        id="dishwasher"
        choices={MULTI_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Diskmaskin"
        value={defaultSettingsInstance.dishwasher}
        onUpdate={(val) => {
          updateAttr({ attr: "dishwasher", val });
        }}
      />

      <NonConnectedSelect
        id="washing_machine"
        choices={MULTI_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Tvättmaskin"
        value={defaultSettingsInstance.washing_machine}
        onUpdate={(val) => {
          updateAttr({ attr: "washing_machine", val });
        }}
      />

      <NonConnectedSelect
        id="dryer"
        choices={MULTI_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Torktumlare"
        value={defaultSettingsInstance.dryer}
        onUpdate={(val) => {
          updateAttr({ attr: "dryer", val });
        }}
      />

      <NonConnectedSelect
        id="bathtub"
        choices={MULTI_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        label="Badkar"
        value={defaultSettingsInstance.bathtub}
        onUpdate={(val) => {
          updateAttr({ attr: "bathtub", val });
        }}
      />
    </>
  );
};

export const MULTI_OPTIONS = [
  {
    v: 0,
    d: "Inget",
  },
  {
    v: 1,
    d: "Förberedd",
  },
  {
    v: 2,
    d: "Nuvarande",
  },
];

export const READABLE_MULTI_OPTIONS = {
  0: "Inget",
  1: "Förberett",
  2: "Nuvarande",
};

export const KITCHEN_OPTIONS = [
  {
    v: 0,
    d: "Kök",
  },
  {
    v: 1,
    d: "Kokvrå",
  },
  {
    v: 2,
    d: "Delat",
  },
];

export const READABLE_KITCHEN_OPTIONS = {
  0: "Kök",
  1: "Kokvrå",
  2: "Delat",
};

export const BALCONY_OPTIONS = [
  {
    v: 0,
    d: "Inget",
  },
  {
    v: 1,
    d: "Nuvarande",
  },
  {
    v: 2,
    d: "Syd",
  },
  {
    v: 3,
    d: "Sydöst",
  },
  {
    v: 4,
    d: "Sydväst",
  },
  {
    v: 5,
    d: "Väst",
  },
  {
    v: 6,
    d: "Öst",
  },
  {
    v: 7,
    d: "Nordöst",
  },
  {
    v: 8,
    d: "Nordväst",
  },
  {
    v: 9,
    d: "Norr",
  },
];

export const READABLE_BALCONY_OPTIONS = {
  0: "Inget",

  1: "Nuvarande",

  2: "Syd",

  3: "Sydöst",

  4: "Sydväst",

  5: "Väst",

  6: "Öst",

  7: "Nordöst",

  8: "Nordväst",

  9: "Norr",
};
