import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  constants,
  create,
  useParkingSpecificationForm,
  destroyPatchForm,
  destroyPostForm,
  detailUrl,
  update,
} from "../../../../store/parkingSpecifications";
import { setActiveFormInstance } from "../../../../store/base";

import Modal from "../../Base/Modals/Modal";
import TextInputField from "../../Base/Fields/TextInputField";
import SelectField from "../../Base/Fields/SelectField";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";

export default function ParkingSpecificationForm({
  method,
  id,
  instance,
  onCheckout,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useParkingSpecificationForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title="Uppdatera specifikation"
      closeFunction={onDone}
      onAccept={onSubmit}
      acceptTitle="Spara"
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Namn på specifikation"
          storeName={storeName}
          fieldKey={`title`}
          method={method}
        />
        <SelectField
          title="Typ av områden inom specifikationen"
          storeName={storeName}
          fieldKey={`category`}
          method={method}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Fordonstyper "
          description="Ange vilka typer av fordon som kan parkera på denna typ av specifikation"
          storeName={storeName}
          fieldKey={`vehicle_support`}
          method={method}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          isNumber
          title="Max höjd på fordon (CM)"
          storeName={storeName}
          fieldKey={`height_limit`}
          method={method}
        />
        <TextInputField
          isNumber
          title="Viktgräns för fordon (KG)"
          storeName={storeName}
          fieldKey={`weight_limit`}
          method={method}
        />
        <TextInputField
          isNumber
          title="Max bredd för fordon (CM)"
          storeName={storeName}
          fieldKey={`width_limit`}
          method={method}
        />
        <TextInputField
          isNumber
          title="Max längd för fordon (CM)"
          storeName={storeName}
          fieldKey={`length_limit`}
          method={method}
        />
      </div>
    </Modal>
  );
}
