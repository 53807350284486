import * as React from "react";
import { useAsyncDebounce } from "react-table";
import NonConnectedTextInput from "../../../../Forms/Base/Old/NonConnected/NonConnectedTextInput";

export default function SimpleTextFilter({
  column: { filterValue, setFilter },
  flatRows,
}) {
  const [value, setValue] = React.useState(filterValue);
  // errand_id is made out of id + hardcoded prefix
  // Get the prefix
  let pre = "";
  if(flatRows?.length > 0){
    pre = flatRows[0]?.original?.errand_id?.charAt(0);
  }
  const onChange = useAsyncDebounce((value) => {
    const val = value?.replace(pre, "")?.replace(pre?.toLowerCase(),"");
    setFilter(val || undefined);
  }, 200);

  return (
    <NonConnectedTextInput
      noMargin
      rowSize
      extraStyles={{ width: "300px" }}
      value={value || ""}
      onChange={(value) => {
        setValue(value || undefined);
        onChange(value || undefined);
      }}
      placeholder={`Sök`}
    />
  );
}
