import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import * as React from "react";
import PrimaryBtn from "../../../Forms/Base/Buttons/PrimaryBtn";
import LocalBareSelectField from "../../../Forms/Base/Fields/LocalBareSelectField";

/**
 * 
 * @param {boolean} canPrevious 
 * @param {boolean} canNext 
 * @param {number} pageCount 
 * @param {number} currentPage 
 * @param {number} pageSize 
 * @function onPageSizeUpdated
 * @function onPrevious
 * @function onNext
 * @function onSetPage

 * @returns Component 
 */
export default function Pagination({
  canPrevious,
  canNext,
  pageCount,
  currentPage,
  pageSize,
  onPageSizeUpdated,
  onPrevious,
  onNext,
  onSetPage,
}) {
  return (
    <div className="border-b border-l border-r px-2 py-3 flex justify-between items-center border-slate-200 bg-slate-100 rounded-b">
      <PrimaryBtn
        onClick={onPrevious}
        className={classNames(!canPrevious && "opacity-0 pointer-events-none")}
        secondary
      >
        <ChevronLeftIcon width={16} /> Föregående sida
      </PrimaryBtn>
      <div className="flex flex-col justify-center text-xs">
        {pageCount > 1 && (
          <div className="flex space-x-1 items-center justify-center mb-2">
            <button
              type="button"
              className={classNames(
                "text-primaryblue bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-xs px-1 inline-flex items-center ",
                {
                  "bg-primaryblue !text-white": 1 === currentPage,
                }
              )}
              onClick={() => onSetPage(1)}
            >
              1
            </button>

            {currentPage > 4 && <div>...</div>}

            {[...Array(pageCount).keys()]
              .map((p) => p + 1)
              .filter((p) => {
                if (
                  p === 1 ||
                  p === pageCount ||
                  p < currentPage - 2 ||
                  p > currentPage + 2
                )
                  return false;
                return true;
              })
              .map((p) => (
                <button
                  key={p}
                  type="button"
                  className={classNames(
                    "text-primaryblue bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-xs px-1 inline-flex items-center ",
                    {
                      "bg-primaryblue !text-white": p === currentPage,
                    }
                  )}
                  onClick={() => onSetPage(p)}
                >
                  {p}
                </button>
              ))}

            {pageCount - currentPage > 3 && <div>...</div>}

            <button
              type="button"
              className={classNames(
                "text-primaryblue bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-xs px-1 inline-flex items-center ",
                {
                  "bg-primaryblue !text-white": pageCount === currentPage,
                }
              )}
              onClick={() => onSetPage(pageCount)}
            >
              {pageCount}
            </button>
          </div>
        )}

        <div className="flex items-center">
          Visar{" "}
          <LocalBareSelectField
            removePlaceholder
            selectClassName="min-w-[60px]"
            value={pageSize}
            choices={[
              {
                d: "10",
                v: 10,
              },
              {
                d: "25",
                v: 25,
              },
              {
                d: "50",
                v: 50,
              },
              {
                d: "100",
                v: 100,
              },
              {
                d: "250",
                v: 250,
              },
            ]}
            onChange={(val) => onPageSizeUpdated(val)}
          />
          resultat per sida
        </div>
      </div>
      <PrimaryBtn
        className={classNames(!canNext && "opacity-0 pointer-events-none")}
        onClick={onNext}
        secondary
      >
        Nästa sida <ChevronRightIcon width={16} />
      </PrimaryBtn>
    </div>
  );
}
