import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Table from "../../../components/Billecta/Table/BasicTable";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  constants,
  costProposalUrl,
  getLeadContractProposals,
  getLeadCostProposals,
  pickTemplateUrl,
  removeContractProposal,
  updateContractProposal,
} from "../../../store/pipes";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import { detailUrl as companyDetailUrl } from "../../../store/companies";
import { createMany as createManyLeaseContracts } from "../../../store/leaseContracts";
import { createMany as createManyParkingContracts } from "../../../store/parkingContracts";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { RemoveButton } from "../../../components/Forms/RoundingErrandSetting/styles";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { DateCell, DurationCell } from "../../../components/Displays";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedMonthInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedMonthInput";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";

export default function LeadsContractProposals() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { goBack, push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const { type } = useParams();

  const leads = useSelector((state) => state[storeName].contractLeads);
  const proposals = useSelector((state) => state[storeName].contractProposals);
  const signingType = useSelector((state) => state[storeName].signingType);

  const currentEditProposal = proposals?.find((p) => p._reduxId == editId);

  React.useEffect(() => {
    if (leads?.length && !proposals) {
      setLoading(true);
      dispatch(
        getLeadContractProposals({
          type,
          successCallback: () => setLoading(false),
          errorCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Kunde ej hämta avtalsförslag",
                description: "Kontakta Pigello om problemet kvarstår",
              })
            );
          },
        })
      );
    }
  }, [leads]);

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const data = React.useMemo(() => {
    return proposals;
  }, [proposals]);

  const removeProposal = (id) => {
    dispatch(removeContractProposal({ id }));
  };

  const updateProposalAttr = ({ attr, val }) => {
    const newProposal = { ...currentEditProposal, [attr]: val };
    dispatch(updateContractProposal({ id: editId, newProposal }));
  };

  const demoCreate = () => {
    dispatch(
      getLeadCostProposals({
        createdContracts: DEMO_CONTRACT,
        type,
        successCallback: () => {
          push(pickTemplateUrl({ type }));
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta kostnads och-aviseringsförslag",
              description:
                "Försök igen. Kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const createContracts = () => {
    setLoading(true);
    const postData = proposals.map((p) => ({
      ...p,
      uses_e_signing: signingType === "digital",
      pre_existing: false,
    }));
    const method =
      type === "leasecontracts"
        ? createManyLeaseContracts
        : createManyParkingContracts; // parking

    dispatch(
      method({
        postData,
        successCallback: (data) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avtalen skapades",
              description:
                "De är nu redo för kostnads och-aviseringsinställningar",
            })
          );

          dispatch(
            getLeadCostProposals({
              createdContracts: data,
              type,
              successCallback: () => {
                setLoading(false);
                push(costProposalUrl({ type }));
              },
              errorCallback: () => {
                setLoading(false);
                dispatch(
                  addToast({
                    type: TOAST_TYPES.ERROR,
                    title: "Kunde ej hämta kostnads och-aviseringsförslag",
                    description:
                      "Försök igen. Kontakta Pigello om problemet kvarstår",
                  })
                );

                push(costProposalUrl({ type }));
              },
            })
          );
        },
        errorCallback: (errors) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                "Avtalen skapades ej. Kontrollera de rödmarkerade raderna och försök igen",
            })
          );
          console.log({ errors });
        },
      })
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "remove",
        Cell: ({ row }) => {
          return (
            <RemoveButton onClick={() => removeProposal(row.original.id)} />
          );
        },
      },

      {
        Header: "Avtals-ID",
        accessor: "id_number",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Hyresgäst",
        accessor: "tenant",
        Cell: ({ value }) => {
          if (!value) return "-";

          return (
            <TextButton
              title={value?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(tenantDetailUrl({ id: value?.id }))}
            />
          );
        },
      },
      {
        Header: "Objekt",
        accessor: (row) =>
          row.apartments?.[0]?.str_representation ||
          row.industrial_premises_list?.[0]?.str_representation ||
          row.parking_spots?.[0]?.str_representation,
        Cell: ({ row }) => {
          const obj =
            row.original.apartments?.[0] ||
            row.original.industrial_premises_list?.[0] ||
            row.original.parking_spots?.[0];

          const detailUrl = row.original.apartments?.length
            ? apartmentDetailUrl
            : row.original.industrial_premises_list?.length
            ? indpDetailUrl
            : parkingDetailUrl;

          if (!obj) return "-";

          return (
            <TextButton
              title={obj?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(detailUrl({ id: obj.id }))}
            />
          );
        },
      },

      {
        Header: "Hyresvärd",
        accessor: "landlord",
        Cell: ({ value }) => {
          if (!value) return "-";
          return (
            <TextButton
              title={value?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(companyDetailUrl({ id: value?.id }))}
            />
          );
        },
      },

      {
        Header: "Fastighet",
        accessor: "realestate",
        Cell: ({ value }) => {
          if (!value) return "-";
          return (
            <TextButton
              title={value?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() =>
                openInNewTab(realEstateDetailUrl({ id: value?.id }))
              }
            />
          );
        },
      },

      {
        Header: "Startdatum",
        accessor: "start_date",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
      {
        Header: "Slutdatum",
        accessor: "end_date",
        Cell: ({ value }) => {
          return value ? <DateCell date={value} /> : <div>Tillsvidare</div>;
        },
      },
      {
        Header: "Uppsägningstid",
        accessor: "notify_interval",
        Cell: ({ value }) => {
          return <DurationCell durationStamp={value} />;
        },
      },
      {
        Header: "Varningstid",
        accessor: "warning_interval",
        Cell: ({ value }) => {
          return <DurationCell durationStamp={value} />;
        },
      },
      {
        Header: "Förlängningstid",
        accessor: "renew_interval",
        Cell: ({ value }) => {
          return <DurationCell durationStamp={value} />;
        },
      },
    ],
    []
  );

  const checkRowError = (row) => {
    return false;
  };

  return (
    <>
      {confirmOpen && (
        <ConfirmationModal
          isOpen={confirmOpen}
          title="Bekräfta skapande av avtal"
          renderContent={() => (
            <div>
              {proposals?.length} avtal kommer att skapas upp. I nästa steg
              sätts kostnader och eventuell avisering upp, för att i sista
              steget hantera dokument för signering.
            </div>
          )}
          closeFunction={() => setConfirmOpen(false)}
          acceptCallback={createContracts}
        />
      )}

      {proposals?.length > 0 && currentEditProposal && (
        <StandardModal
          title="Redigera avtal"
          isOpen={!!editId}
          closeFunction={() => setEditId(null)}
          actionBarCancelTitle="Klar"
          withActionBar
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Redigera avtal</OverviewTitle>
              <OverviewSubtitle>
                Ändringar sparas direkt, tryck på "Klar" för att stänga modalen.{" "}
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <NonConnectedTextInput
            id="id_propsal"
            label="Avtals-ID"
            value={currentEditProposal?.id_number}
            onChange={(val) => updateProposalAttr({ attr: "id_number", val })}
          />

          <NonConnectedDatePicker
            label="Startdatum"
            id={`start_date_proposal`}
            value={currentEditProposal?.start_date}
            onChange={(val) => updateProposalAttr({ attr: "start_date", val })}
          />
          <NonConnectedDatePicker
            label="Slutdatum"
            id={`end_date_proposal`}
            value={currentEditProposal?.end_date}
            onChange={(val) => updateProposalAttr({ attr: "end_date", val })}
          />
          <NonConnectedMonthInput
            title="Uppsägningstid"
            id={`notify_interval_proposal`}
            value={currentEditProposal?.notify_interval}
            onUpdate={(val) =>
              updateProposalAttr({ attr: "notify_interval", val })
            }
          />
          <NonConnectedMonthInput
            title="Varningstid"
            id={`warning_interval_proposal`}
            value={currentEditProposal?.warning_interval}
            onUpdate={(val) =>
              updateProposalAttr({ attr: "warning_interval", val })
            }
          />
          <NonConnectedMonthInput
            title="Förlängningsid"
            id={`renew_interval_proposal`}
            value={currentEditProposal?.renew_interval}
            onUpdate={(val) =>
              updateProposalAttr({ attr: "renew_interval", val })
            }
          />
        </StandardModal>
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            extraStyle={{ marginBottom: 8 }}
            title="Gå tillbaka till projekt"
            clicked={() => goBack()}
            iconType="arrow-back"
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Avtalsförslag</OverviewTitle>
              <OverviewSubtitle>
                Kontrollera avtalen. Tryck på "Redigera" på en rad för att ändra
                i avtalet. Tryck på "Skapa avtal" när du är klar. I nästa steg
                kommer debiteringsrader och aviseringsinställningar sättas upp.
                <StatusLabel state={6}>Rödmarkerade</StatusLabel> rader
                indikerar på fel i skapandet. Tryck på "Redigera" för detaljerad
                information om felet på en rad.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {data?.length > 0 ? (
            <>
              <Table
                onRowClicked={(row) => setEditId(row.original._reduxId)}
                data={data}
                columns={columns}
                checkRowError={checkRowError}
              />
            </>
          ) : (
            <InnerBox>
              Inga leads specificerade.
              <br />
              <br />
              <TextButton
                title="Gå tillbaka till projektet för att komma
              igång med avtalsskapandet"
                clicked={() => goBack()}
                iconType="arrow-back"
              />
            </InnerBox>
          )}

          {data?.length > 0 ? (
            <div
              style={{
                display: "flex",
                marginTop: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                title="Skapa avtal"
                clicked={() => setConfirmOpen(true)}
              />{" "}
            </div>
          ) : (
            <></>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const DEMO_CONTRACT = [
  {
    id: 40,
    str_representation: "0007-7865",
    editabledoc: null,
    indexation: null,
    remaining_days: null,
    next_period_value: 0,
    indexations: {},
    state_display: "Ej signerat",
    cancelled_doc: null,
    apartments: [
      {
        id: 24,
        str_representation: "0007 (3 RoK)",
      },
    ],
    industrial_premises_list: [],
    block: null,
    tenant: {
      id: 34,
      str_representation: "Koskenkorva AB (U.M)",
    },
    bi_tenants: [],
    total_area: 120,
    lease_invoicing: null,
    lease_invoicing_active: null,
    imd_inactive: true,
    realestate: {
      id: 1,
      str_representation: "KALLIS",
    },
    start_date: null,
    end_date: null,
    signed_date: null,
    is_signed: false,
    renewed_to: null,
    id_number: "0007-7865",
    warning_interval: null,
    warning_time: null,
    notify_interval: null,
    notify_time: null,
    renew_interval: null,
    renew_indefinetely: false,
    status: 0,
    status_display: "Aktiv",
    closed_date: null,
    closed_renew_date: null,
    closed_on: null,
    closed_cause: null,
    closed_signed: false,
    closed_confirmed: false,
    landlord_cancelled: null,
    pre_existing: true,
    draft: false,
    uses_e_signing: false,
    uses_cancellation_e_signing: false,
    state: 5,
    revenue_rent_quota: null,
    minimum_revenue_rent: null,
    has_furniture: null,
    perm_detail: null,
    owner: null,
    landlord: {
      id: 1,
      str_representation: "Bolaget",
    },
    notes: [],
    costs: null,
  },
  {
    id: 41,
    str_representation: "HkTg-1",
    editabledoc: null,
    indexation: null,
    remaining_days: null,
    next_period_value: 0,
    indexations: {},
    state_display: "Ej signerat",
    cancelled_doc: null,
    apartments: [
      {
        id: 1,
        str_representation: "2001w (2 RoK)",
      },
    ],
    industrial_premises_list: [],
    block: null,
    tenant: {
      id: 31,
      str_representation: "Tor Andersch",
    },
    bi_tenants: [],
    total_area: 50,
    lease_invoicing: null,
    lease_invoicing_active: null,
    imd_inactive: true,
    realestate: {
      id: 1,
      str_representation: "KALLIS",
    },
    start_date: "2022-04-01",
    end_date: null,
    signed_date: null,
    is_signed: false,
    renewed_to: null,
    id_number: "HkTg-1",
    warning_interval: null,
    warning_time: null,
    notify_interval: null,
    notify_time: null,
    renew_interval: null,
    renew_indefinetely: false,
    status: 0,
    status_display: "Aktiv",
    closed_date: null,
    closed_renew_date: null,
    closed_on: null,
    closed_cause: null,
    closed_signed: false,
    closed_confirmed: false,
    landlord_cancelled: null,
    pre_existing: true,
    draft: false,
    uses_e_signing: false,
    uses_cancellation_e_signing: false,
    state: 5,
    revenue_rent_quota: null,
    minimum_revenue_rent: null,
    has_furniture: null,
    perm_detail: null,
    owner: null,
    landlord: {
      id: 1,
      str_representation: "Bolaget",
    },
    notes: [],
    costs: null,
  },
  {
    id: 42,
    str_representation: "s94-1358",
    editabledoc: null,
    indexation: null,
    remaining_days: null,
    next_period_value: 0,
    indexations: {},
    state_display: "Ej signerat",
    cancelled_doc: null,
    apartments: [
      {
        id: 753,
        str_representation: "s94 (3 RoK)",
      },
    ],
    industrial_premises_list: [],
    block: null,
    tenant: {
      id: 33,
      str_representation: "Yasmin Mushahdi",
    },
    bi_tenants: [],
    total_area: 120,
    lease_invoicing: null,
    lease_invoicing_active: null,
    imd_inactive: true,
    realestate: {
      id: 1,
      str_representation: "KALLIS",
    },
    start_date: "2022-03-31",
    end_date: null,
    signed_date: null,
    is_signed: false,
    renewed_to: null,
    id_number: "s94-1358",
    warning_interval: null,
    warning_time: null,
    notify_interval: null,
    notify_time: null,
    renew_interval: null,
    renew_indefinetely: false,
    status: 0,
    status_display: "Aktiv",
    closed_date: null,
    closed_renew_date: null,
    closed_on: null,
    closed_cause: null,
    closed_signed: false,
    closed_confirmed: false,
    landlord_cancelled: null,
    pre_existing: true,
    draft: false,
    uses_e_signing: false,
    uses_cancellation_e_signing: false,
    state: 5,
    revenue_rent_quota: null,
    minimum_revenue_rent: null,
    has_furniture: null,
    perm_detail: null,
    owner: null,
    landlord: {
      id: 1,
      str_representation: "Bolaget",
    },
    notes: [],
    costs: null,
  },
];
