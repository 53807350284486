import { get, unset } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

import {
  getFormInstanceStateType,
  getFormErrorStateType,
  getBackgroundAddStateType,
  getBackgroundRemoveStateType,
} from "../utils";

export const INITIAL_STATE = {
  all: {},
  allCopy: {},
  inProgress: [],
  paginations: {},
  paginationsCopy: {},
  filtered: {},
  filteredCopy: {},
  backgroundTasks: {},
  hasFetchedAll: false,

  forms: {},
  formInstance: {},
  formErrors: {},
};

export const INITIAL_PARTIAL_STATE = {
  all: {},
  allCopy: {},
  inProgress: [],
  backgroundTasks: {},
  hasFetchedAll: false,
};
export default (state = INITIAL_STATE, action, constants) => {
  const { type, payload } = action;

  switch (type) {
    case constants.INSERT_INTO_ALL:
      return insertIntoAll(
        state,
        payload.result,
        payload.name,
        payload.allMode
      );

    case constants.REMOVE_OBJECT:
      return removeObject(state, payload.id);

    case constants.ADD_TO_IN_PROGRESS:
      return insertIntoProgress(state, payload.name);

    case constants.REMOVE_FROM_IN_PROGRESS:
      return removeFromInProgress(state, payload.name);

    case constants.INSERT_INTO_FORMS:
      return insertIntoForms(
        state,
        payload.result,
        payload.method,
        payload.name
      );

    case constants.DESTROY_FORM:
      return destroyForm(state, payload.method, payload.success);

    case constants.SET_FORM_ERROR:
      return setFormErrors(state, payload.result);

    case constants.INSERT_INTO_PAGINATION:
      return insertPagination(state, payload.result, payload.querystring);

    case constants.INSERT_INTO_FILTERED:
      return insertFiltered({
        state,
        data: payload.result,
        querystring: payload.querystring,
        keepProgress: payload.keepProgress,
      });

    case constants.CLEAR_FETCHED:
      return clearFetched(state, payload.clearAll);

    case constants.RESET_STATE:
      return INITIAL_STATE;

    case getFormInstanceStateType(constants.STORE_NAME):
      return updateFormInstance(state, payload.result, payload.clean);

    case getFormErrorStateType(constants.STORE_NAME):
      return updateFormError(state, payload.result, payload.clean);

    case getBackgroundAddStateType(constants.STORE_NAME):
      return addBackgroundTask(state, payload.name, payload.taskToken);

    case getBackgroundRemoveStateType(constants.STORE_NAME):
      return cancelBackgroundTasks(state);

    case constants.SET_ASK_DELETE_DATA:
      return { ...state, deleteData: payload.deleteData };

    default:
      return state;
  }
};

export const baseInsightsReducer = (state = INITIAL_PARTIAL_STATE, action, constants) => {

  const { type, payload } = action;

  switch (type) {

    case constants.INSERT_INTO_ALL:
      return insertIntoAll(
        state,
        payload.result,
        payload.name,
        payload.allMode
      );

    case constants.REMOVE_OBJECT:
      return removeObject(state, payload.id);

    case constants.ADD_TO_IN_PROGRESS:
      return insertIntoProgress(state, payload.name);

    case constants.REMOVE_FROM_IN_PROGRESS:
      return removeFromInProgress(state, payload.name);

    case constants.RESET_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
}


/**
 *  Insert a fetch process (identified by it's name) into
 *  list of current processes
 */
export const insertIntoProgress = (state, name) => {
  return { ...state, inProgress: [...state.inProgress, name] };
};

export const removeFromInProgress = (state, name) => {
  return { ...state, inProgress: state.inProgress.filter((n) => n !== name) };
};

/**
 * Remove a fetch process from the list of
 * current processes
 */
export const removeFromProgress = (inProgress, name) => {
  if (!(typeof name === "string")) {
    return inProgress;
  }
  return inProgress.filter((call) => call !== name);
};
export const removeFromBackgroundTasks = (backgroundTasks, name) => {
  const taskCopy = cloneDeep(backgroundTasks);
  if (!(typeof name === "string")) {
    return taskCopy;
  }

  delete taskCopy.name;
  return taskCopy;
};

/**
 * Insert retrieved data into state object
 * that contains all data for a certain object
 *
 * Will also remove the fetch process from list of
 * current processes
 */
const insertIntoAll = (state, data, name, allMode) => {
  let allCopy = cloneDeep(state.all);

  // if it's a list -> insert every element
  if (Array.isArray(data)) {
    data.forEach((row) => {
      allCopy[row.id] = row;
    });
  } else {
    // if it's not a list -> insert only that element
    allCopy[data.id] = data;
  }

  const inProgress = removeFromProgress(state.inProgress, name);
  const backgroundTasks = removeFromBackgroundTasks(
    state.backgroundTasks,
    name
  );
  return {
    ...state,
    all: allCopy,
    allCopy,
    inProgress: inProgress,
    backgroundTasks: backgroundTasks,
    hasFetchedAll: state.hasFetchedAll || allMode,
  };
};

/**
 * Removes object and clears it from all references
 */
const removeObject = (state, objectId) => {
  // delete object
  const allCopy = cloneDeep(state.all);
  delete allCopy[objectId];

  // remove references in filtered
  const filteredCopy = cloneDeep(state.filtered);
  for (let k in filteredCopy) {
    filteredCopy[k] = filteredCopy[k].filter((id) => id !== objectId);
  }

  // remove references in paginations
  const paginationsCopy = cloneDeep(state.paginations);
  for (let p in paginationsCopy) {
    const pagination = paginationsCopy[p];
    pagination.count -= 1;
    pagination.results = pagination.results.filter((r) => r !== objectId);
  }

  return {
    ...state,
    pagination: paginationsCopy,
    filtered: filteredCopy,
    all: allCopy,
    allCopy,
  };
};

/**
 * Insert retrieved from instructions into state object
 * that contains all forms for a certain object
 *
 * Form will be stored by the chosen method (e.g POST)
 */
const insertIntoForms = (state, data, method, name) => {
  const inProgress = removeFromProgress(state.inProgress, name);
  const backgroundTasks = removeFromBackgroundTasks(
    state.backgroundTasks,
    name
  );
  return {
    ...state,
    forms: { ...state.forms, [method]: data },
    inProgress: inProgress,
    backgroundTasks: backgroundTasks,
  };
};

/**
 * Removes a form, specified by the method, from the state
 * object that stores all forms
 */
const destroyForm = (state, method, success) => {
  let result = { ...state, formInstance: {}, formErrors: {} };
  if (success) {
    // we reset all filters and paginations
    result.filtered = {};
    result.paginations = {};
  }

  if (method !== "POST") {
    // we only clean the instance and the errors on non-POST
    result.forms[method] = undefined;
  }

  return result;
};

const setFormErrors = (state, errors) => {
  return { ...state, formErrors: errors };
};
const updateFormInstance = (state, data, clean) => {
  // assumed to be on the format {"path.to.key":"value_for_key"}
  // in other words it is assumed to be a one-dimensional object

  if (clean) {
    return { ...state, formInstance: data };
  }

  if (!data) return state;

  let copy = cloneDeep(state.formInstance);
  Object.keys(data).forEach((key) => {
    set(copy, key, data[key]);
  });

  // remove error for updated field
  let errorCopy = cloneDeep(state.formErrors);
  Object.keys(data).forEach((key) => {
    if (get(errorCopy, key) != null) {
      unset(errorCopy, key);

      // if is array
      const arraySplitKey = key.split("[")?.[0];

      if (arraySplitKey) {
        const errorArray = get(errorCopy, arraySplitKey);

        const emptyFilteredErrors = errorArray?.filter((e) => {
          if (e.constructor === Object) {
            return Object.keys(e).length > 0;
          } else {
            return !!e;
          }
        });

        if (emptyFilteredErrors?.length < 1) {
          set(errorCopy, arraySplitKey, undefined);
        }
      }
    }
  });

  return { ...state, formInstance: copy, formErrors: errorCopy };
};

const updateFormError = (state, data, clean = false) => {
  // assumed to be on the format {"path.to.key":"value_for_key"}
  // in other words it is assumed to be a one-dimensional object
  let copy = {};

  if (!clean) {
    copy = cloneDeep(state.formErrors);
  }

  Object.keys(data).forEach((key) => {
    set(copy, key, data[key]);
  });

  return { ...state, formErrors: copy };
};

const insertPagination = (state, data, querystring) => {
  // well update state.paginations to look like
  // {querystring : {result:[<int:id>, <int:id>...], next:<str:url>, previous:<str:url>}}
  // we'll put all retrieved data into state.all

  const results = data.results;
  const next = data.next;
  const previous = data.previous;
  const count = data.count;

  // set value in state.paginations for key querystring
  let paginationCopy = cloneDeep(state.paginations);
  paginationCopy[querystring] = {
    results: results?.map((r) => r.id),
    next: next,
    previous: previous,
    count: count,
  };

  // instert into state.all
  let allCopy = cloneDeep(state.all);

  results.forEach((row) => {
    allCopy[row.id] = row;
  });

  const inProgress = removeFromProgress(state.inProgress, querystring);
  const backgroundTasks = removeFromBackgroundTasks(
    state.backgroundTasks,
    querystring
  );
  return {
    ...state,
    all: allCopy,
    allCopy,
    inProgress: inProgress,
    paginations: paginationCopy,
    paginationCopy,
    backgroundTasks: backgroundTasks,
  };
};

const insertFiltered = ({ state, data, querystring, keepProgress }) => {
  // well update state.filtered to look like
  // {querystring : [<int:id>...]}
  // we'll put all retrieved data into state.all

  // set value in state.filtered for key querystring
  let filteredCopy = cloneDeep(state.filtered);
  filteredCopy[querystring] = data.map((d) => d.id);

  // instert into state.all
  let allCopy = cloneDeep(state.all);

  data.forEach((row) => {
    allCopy[row.id] = row;
  });

  const inProgress = keepProgress
    ? state.inProgress
    : removeFromProgress(state.inProgress, querystring);
  const backgroundTasks = removeFromBackgroundTasks(
    state.backgroundTasks,
    querystring
  );
  return {
    ...state,
    all: allCopy,
    allCopy,
    inProgress: inProgress,
    filtered: filteredCopy,
    filteredCopy,
    backgroundTasks: backgroundTasks,
  };
};

const addBackgroundTask = (state, name, taskToken) => {
  // if the name existed previously, dont do anything
  if (state.backgroundTasks[name]) {
    return state;
  }

  // otherwice, add the task
  return {
    ...state,
    backgroundTasks: { ...state.backgroundTasks, [name]: taskToken },
  };
};

const cancelBackgroundTasks = (state) => {
  let inProgress = cloneDeep(state.inProgress);
  const backgroundTasks = cloneDeep(state.backgroundTasks);

  Object.keys(backgroundTasks).forEach((key) => {
    const taskToken = backgroundTasks[key];
    taskToken.cancel();

    // make sure that this i removed from inProgress
    inProgress = removeFromProgress(inProgress, key);
  });

  return { ...state, backgroundTasks: {}, inProgress: inProgress };
};

const clearFetched = (state, clearAll) => {
  if (clearAll) {
    return {
      ...state,
      filtered: {},
      paginations: {},
      all: {},
      hasFetchedAll: false,
    };
  }
  return { ...state, filtered: {}, paginations: {} };
};
