import * as React from "react";
import { TextButton } from "../../Forms/Base/Buttons";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import FastDocTemplates from "./FastDocTemplates";
import PrivateTemplates from "./PrivateTemplates";
import PrivateFastDocTemplates from "./PrivateFastDocTemplates";
import { cleanEditableDocTemplate } from "./utils";
import { updateActiveFormInstance } from "../../../store/base";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { cloneDeep } from "lodash";
import Alert from "../../Alert/Alert";

const OPTIONS = {
  PRIVATE: "PRIVATE",
  FASTDOC: "FASTDOC",
  PRIVATE_FASTDOC: "PRIVATE_FASTDOC",
};

export const fastDocTemplateName = "fastDocTemplates";
export const privateTemplateName = "private";
export const privateFastDocTemplateName = "privateFastDoc";

export default function DigitalDocTemplateContainer({
  cancel,
  hasFastDoc,
  storeName,
  usesESigning,
}) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = React.useState(OPTIONS.PRIVATE);

  const getTemplateOptions = ({ type, templates }) => {
    if (!templates?.length) {
      return null;
    }

    if (type === privateTemplateName) {
      return templates.map((value) => {
        return {
          value: value.id,
          label: value.title,
        };
      });
    } else if (type === fastDocTemplateName) {
      return templates.map((value) => {
        return {
          value: value._id,
          label: value.name,
        };
      });
    } else {
      // private fastdok template or pigello fastdok
      return templates.map((t) => ({
        value: t.fastdoc_id,
        object: t,
        label: t.title,
      }));
    }
  };

  const fetchInternalTemplate = ({ id, type, object }) => {
    let chosenOption = object;

    if (!chosenOption) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta mall",
          description: "Vänligen försök igen",
        })
      );

      return;
    }

    cleanEditableDocTemplate(cloneDeep(chosenOption)).then((cleaned) => {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ...cleaned,
            _chosenTemplate: {
              name: type,
              id,
            },
          },
        })
      );
    });
  };

  // object for private and public fastdoc based, ONLY FASTDOC!
  const fetchTemplate = async ({ id, type, object }) => {
    // private fastdoc has parties, directly from fastdoc doesn't
    if (object.parties) {
      cleanEditableDocTemplate(cloneDeep(object)).then((cleaned) => {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              ...cleaned,
              parties: usesESigning ? cleaned.parties : [],
              _chosenTemplate: {
                name: type,
                id,
              },
            },
          })
        );
      });
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            fastdoc_id: id,
            title: object.name,
            _chosenTemplate: {
              name: type,
              id,
            },
          },
        })
      );
    }
  };

  const choseTemplate = ({ id, type, object }) => {
    if (!id) {
      return;
    }

    switch (type) {
      case fastDocTemplateName: {
        fetchTemplate({ id, type, object });
        break;
      }

      case privateTemplateName: {
        fetchInternalTemplate({ id, type, object });
        break;
      }

      case privateFastDocTemplateName: {
        fetchTemplate({ id, type, object });
      }

      default:
        return null;
    }
  };

  return (
    <div className="max-w-[1000px] w-full mx-auto pt-12 flex flex-col px-12">
      <TextButton title="Gå tillbaka" clicked={cancel} iconType="arrow-back" />

      <h1 className="font-medium text-2xl mt-6">Välj mall att utgå ifrån</h1>

      <div className="flex space-x-2 mb-6 mt-12">
        <PrimaryBtn
          secondary={selectedOption !== OPTIONS.PRIVATE}
          onClick={() => setSelectedOption(OPTIONS.PRIVATE)}
        >
          Egna mallar
        </PrimaryBtn>
        {hasFastDoc && (
          <>
            <PrimaryBtn
              secondary={selectedOption !== OPTIONS.PRIVATE_FASTDOC}
              onClick={() => setSelectedOption(OPTIONS.PRIVATE_FASTDOC)}
            >
              Egna baserade på Fastighetsägarna Dokument
            </PrimaryBtn>
            <PrimaryBtn
              secondary={selectedOption !== OPTIONS.FASTDOC}
              onClick={() => setSelectedOption(OPTIONS.FASTDOC)}
            >
              Fastighetsägarna Dokument
            </PrimaryBtn>{" "}
          </>
        )}
      </div>

      {selectedOption && selectedOption !== OPTIONS.PRIVATE && (
        <Alert title="Information om mallar från Fastighetsägarna Dokument">
          Mallar från Fastighetsägarna dokument fungerar genom att Pigello
          autofyller fält från avtalet som mallen används på. Eventuella
          ändringar i dokumentet görs genom en SSO-inloggning till
          Fastighetsägarnas portal.
          <br />
          <br />
          Den enda fälttypen som går att placera ut på en mall från
          Fastighetsägarna dokument är signeringsfält som ska användas för
          eventuell signering i Scrive.
        </Alert>
      )}

      {selectedOption === OPTIONS.FASTDOC && (
        <FastDocTemplates
          {...{
            fastDocTemplateName,
            getTemplateOptions,
            choseTemplate,
          }}
        />
      )}

      {selectedOption === OPTIONS.PRIVATE && (
        <PrivateTemplates
          privateTemplateName={privateTemplateName}
          choseTemplate={choseTemplate}
        />
      )}

      {selectedOption === OPTIONS.PRIVATE_FASTDOC && (
        <PrivateFastDocTemplates
          privateFastDocTemplateName={privateFastDocTemplateName}
          choseTemplate={choseTemplate}
        />
      )}
    </div>
  );
}
