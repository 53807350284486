export default [
  {
    title: "Artikel",
    key: "ARTICLE",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "code",
      "description",
      "unit",
      "standard_unit_amount",
      "cost_per_unit",
      "product",
      "is_public",
      "is_active",
    ],
  },
];
