import * as React from "react";
import { Redirect, useParams } from "react-router";

import { useLeaseInvoicing } from "../../../store/invoicingLease";
import { detailUrl } from "../../../store/invoicingLease";

export default function InvoicingLeaseRedirect() {
  const { id } = useParams();

  const [leaseInvoicing] = useLeaseInvoicing(id);

  const leaseContractId = leaseInvoicing?.lease_contract?.id;

  // wait for sufficient data to redirect
  if (!leaseContractId) return null;

  return <Redirect to={detailUrl({ id: leaseContractId })} />;
}
