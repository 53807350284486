import * as React from "react";

import { Confirm, Pipe, Attributes } from "./Chapters";

export default (key, method, id) => {
  switch (key) {
    case "PIPE":
      return <Pipe method={method} id={id} />;
    case "ATTRIBUTES":
      return <Attributes method={method} />;
    default:
      return <Confirm method={method} />;
  }
};
