import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function createMany(postData) {
  const resp = axiosInstance.post(constants.LIST_URL, postData);

  return resp;
}

export { createMany };
