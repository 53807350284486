import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useTenant } from "../../../store/tenants";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import OtherTable from "src/components/Tables/OtherContracts/FullTableNewVersion";
import BasicDocTable from "../../../components/Tables/BasicDocs/FullTable";
import LeaseTable from "src/components/Tables/LeaseContracts/FullTableNewVersion";
import ParkingTable from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersion";

export default function MemberContracts() {
  const { tenantId } = useParams();
  const [member] = useTenant(tenantId);

  const leaseQuery = { broad_tenant: tenantId, draft: false };

  const otherQuery = { tenant: tenantId };

  const basicQuery = {
    tenant: tenantId,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Hyresavtal</OverviewTitle>
        </OverviewTitleWrapper>
        <LeaseTable persistantQuery={leaseQuery} isBare ignoreLocalStorage />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Parkeringsavtal</OverviewTitle>
        </OverviewTitleWrapper>
        <ParkingTable persistantQuery={otherQuery} isBare ignoreLocalStorage />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Övriga avtal</OverviewTitle>
        </OverviewTitleWrapper>
        <OtherTable persistantQuery={otherQuery} isBare ignoreLocalStorage />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Dokument</OverviewTitle>
        </OverviewTitleWrapper>
        <BasicDocTable persistantQuery={basicQuery} isBare ignoreLocalStorage />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
