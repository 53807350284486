import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: false, display: "Aktiv" },
    { value: true, display: "Ej aktiv" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
