import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  clearInstance,
} from "../../../../../store/massConfigPremisesComponents";
import NonConnectedRadioGroup from "../../../Base/Old/NonConnected/NonConnectedRadioGroup";

export const MASS_CONFIG_OBJECT_TYPES = {
  APARTMENT: "Lägenheter",
  BRF: "Bostadsrätter",
  INDP: "Lokaler",
  COMMON: "Gemensamma utrymmen",
  PARKING: "Fordonsplatser",
  BUILDING: "Byggnader",
  REALESTATE: "Fastigheter",
};
export const MASS_CONFIG_OBJECT_TYPES_KEYS = {
  APARTMENT: "APARTMENT",
  BRF: "BRF",
  INDP: "INDP",
  COMMON: "COMMON",
  PARKING: "PARKING",
  BUILDING: "BUILDING",
  REALESTATE: "REALESTATE",
};

export default function Type({ updateFunction, storeName }) {
  const dispatch = useDispatch();
  const instance = useSelector((state) => state[storeName].instance);

  return (
    <>
      <NonConnectedRadioGroup
        id="type"
        value={instance?.type}
        noMargin
        title="Välj vilken typ av objekt uppsättningen gäller"
        options={Object.keys(MASS_CONFIG_OBJECT_TYPES).map((key) => ({
          label: MASS_CONFIG_OBJECT_TYPES[key],
          value: key,
        }))}
        onChange={(data) => {
          dispatch(clearInstance());
          dispatch(clearData());
          setTimeout(() => {
            updateFunction({ key: "type", value: data });
          }, 50);
        }}
      />
    </>
  );
}
