import * as React from "react";

// style, design
import * as SharedStyles from "../../../components/sharedStyles";

// store, state

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../store/companies";
import { getVismaSettings } from "../../../store/billectaIntegrations";
import { uid } from "uid";
import { InfoBox } from "../../../components/Displays";
import { useVismaForm } from "../../../store/billectaIntegrations/hooks/form";
import { constants } from "../../../store/billectaIntegrations";
import { DatePicker, TextInput } from "../../../components/Forms/Base/Fields";
import moment from "moment";
import { updateActiveFormInstance } from "../../../store/base";

export default function CompanyActivateVisma() {
  const storeName = "billectaIntegrations";
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const returnUrl = `https://${window.location.hostname}/companies/detail/activate_visma_complete`;

  const [company] = useCompany(companyId);
  const creditorId = company?.billecta_id;

  const vismaSettings = useSelector(
    (state) => state.billectaIntegrations.vismaSettings
  );

  const instance = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );

  React.useEffect(() => {
    if (instance?.VismaCompanyName || instance?.VismaCompanyOrgNo) return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          ...instance,
          VismaCompanyName: company?.name,
          VismaCompanyOrgNo: company?.orgnr,
        },
      })
    );
  }, [company]);

  useVismaForm("PUT", true);

  React.useEffect(() => {
    if (!vismaSettings && creditorId) dispatch(getVismaSettings(creditorId));
  }, [creditorId]);

  const goToActivationPage = () => {
    const state = uid();
    const voucherSeries = instance?.VoucherSeries;
    const startDate = instance?.StartDate;
    const companyName = instance?.VismaCompanyName;
    const orgNo = instance?.VismaOrgNo;

    localStorage.setItem("visma_state", state);
    localStorage.setItem("visma_voucher_series", voucherSeries);
    localStorage.setItem("visma_start_date", startDate);
    localStorage.setItem("visma_company_name", companyName);
    localStorage.setItem("visma_company_org", orgNo);
    localStorage.setItem("visma_company_id", companyId);
    localStorage.setItem("visma_creditor_id", creditorId);

    window.location.href = `https://identity.vismaonline.com/connect/authorize?client_id=billecta&redirect_uri=${returnUrl}&state=${creditorId}&scope=offline_access+ea:api+ea:sales+ea:accounting+ea:purchase&response_type=code`;
  };

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Aktivera Visma-integration</OverviewTitle>
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox>
          {!!vismaSettings?.AccessCode && (
            <InfoBox
              boxTheme="warning"
              title="Redan aktiverad"
              text="Denna integration är redan aktiverad för detta bolag"
            />
          )}

          <SharedStyles.BodyText style={{ marginBottom: 12 }}>
            Aktivering av integrationen Visma sker genom att autentisera mot
            Visma via deras inloggningssida. Tryck på knappen för att
            autentisera och aktivera integrationen. När du autentiserat kommer
            du att skickas tillbaka till Pigello.
          </SharedStyles.BodyText>

          <DatePicker
            title="Startdatum för verifikationsserie"
            storeName={constants.STORE_NAME}
            fieldKey={"StartDate"}
            forceRequired
            method={"PUT"}
            minDate={moment().set({ year: "2010", month: 0, day: 1 }).toDate()}
          />

          <TextInput
            storeName={constants.STORE_NAME}
            fieldKey={"VismaCompanyName"}
            title="Bolagets namn i Visma"
            method={"PUT"}
          />

          <TextInput
            storeName={constants.STORE_NAME}
            fieldKey={"VismaCompanyOrgNo"}
            title="Bolagets orgnr i Visma"
            method={"PUT"}
          />

          <TextInput
            storeName={constants.STORE_NAME}
            fieldKey={"VoucherSeries"}
            method={"PUT"}
          />

          <div style={{ marginTop: 12 }}>
            <PrimaryButton
              title="Autentisera och aktivera Visma-integration"
              clicked={goToActivationPage}
              disabled={
                !instance?.VoucherSeries ||
                !instance?.StartDate ||
                !instance.VismaCompanyName ||
                !instance.VismaCompanyOrgNo
              }
            />
          </div>
        </SharedStyles.InnerBox>
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
