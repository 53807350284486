import * as React from "react";

import { constants } from "../../../../../store/leaseContracts";
import TextInputField from "../../../Base/Fields/TextInputField";
import DateSelect from "../../../Base/Fields/DateSelect";
import SelectField from "../../../Base/Fields/SelectField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <DateSelect
          showYearPicker
          placeholder="Från vald inställning"
          title="Basår för indexuppräkning"
          description="Fyll i detta år om basåret på indexinställningar på debiteringsrader kopplade till detta avtal ska skrivas över. Om detta fält lämnas tomt används basåret från indexinställningen."
          {...{ storeName, method, fieldKey: "index_base_date" }}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Hyresgästs andel av fastighetsskatt (%)"
          description="Ange hur stor andel av fastighetsskatten som ska debiteras hyresgästen."
          storeName={storeName}
          fieldKey="tenant_tax_billing_quota"
          method={method}
        />

        <SelectField
          title="Taxeringstyp"
          description="Ange vilken typ av taxering som gäller för objekt under detta avtal."
          storeName={storeName}
          fieldKey="tax_mode"
          method={method}
        />
      </div>
    </>
  );
};
