import styled from "styled-components";

export const StatsWrapper = styled.div`
  display: flex;
  font-family: "Inter", sans-serif;
  color: ${(p) => p.theme.colors.primaryText};
  flex-wrap: wrap;
  width: 100%;
`;

export const StatsItem = styled.div`
  background-color: white;
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  padding: 12px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-family: "Inter", sans-serif;
  margin: 0 4px 4px 0;
`;

export const DataHeader = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  margin-bottom: 12px;
`;

export const Data = styled.div`
  font-size: ${(p) => p.theme.fontSizes.data};
  font-weight: ${(p) => p.theme.fontWeights.data};
`;
