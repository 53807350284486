import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import { constants } from "../../../../../store/leaseContracts";
import { useIsImpersonation } from "src/store/app/hooks";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const isAdmin = useIsImpersonation();

  return (
    <>
      <FormAreaTitle>Granska och slutför</FormAreaTitle>
      <FormAreaDescription>
        Kontrollera att uppgifterna stämmer. Tryck på "Uppdatera hyresavtal" för
        att slutföra.
      </FormAreaDescription>

      {isAdmin && (
        <TableSelectField
          storeName={storeName}
          placeholder="Välj fastighet..."
          title="Koppla till fastighet"
          method={method}
          fieldKey="realestate"
          TableComponent={RealEstateTable}
        />
      )}
    </>
  );
};
