import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../../components/Displays";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { NumberInput } from "../../../components/Forms/Base/Fields";
import NonConnectedNumberInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedNumberInput";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  toMoneyString,
  toSqmString,
} from "../../../components/utils/stringUtils";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import { useBrfCompany } from "../../../store/brfCompanies";
import { updateQuotas } from "../../../store/brfQuotaHandling/store/actions";
import { useFilteredBrfPremises } from "../../../store/brfPremises";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";

export default function BrfCompanyQuotas() {
  const { brfCompanyId } = useParams();

  const [brfCompany] = useBrfCompany(brfCompanyId);
  const dispatch = useDispatch();

  const [isEditingQuotas, setIsEditingQuotas] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [confirmationTitle, setConfirmationTitle] = React.useState(null);
  const [confirmationRenderContent, setConfirmationRenderContent] =
    React.useState(null);
  const [confirmationFunction, setConfirmationFunction] = React.useState(null);

  const [quotas, setQuotas] = React.useState({});

  const hasBillectaPermission = useAllPermissionCheck([
    "allow_billecta",
    "view_can_billing",
  ]);

  const premisesQuery = buildQueryString({
    brf_company: brfCompanyId,
  });
  const [brfPremises, brfPremisesLoading] =
    useFilteredBrfPremises(premisesQuery);

  const totalQuota = brfPremises?.reduce((acc, cur) => {
    const _fromOverride = quotas[`${cur.id}`];
    return (
      acc + parseFloat((_fromOverride != null ? _fromOverride : cur.quota) || 0)
    );
  }, 0);

  const totalPrevQuota = brfPremises?.reduce((acc, cur) => {
    return acc + parseFloat(cur.quota || 0);
  }, 0);

  const totalArea = brfPremises?.reduce((acc, cur) => {
    return acc + parseFloat(cur.area || 0);
  }, 0);
  const totalInvestmentCapital = brfPremises?.reduce((acc, cur) => {
    return acc + parseFloat(cur.investment_capital || 0);
  }, 0);
  const totalPlacementFee = brfPremises?.reduce((acc, cur) => {
    return acc + parseFloat(cur.placement_fee || 0);
  }, 0);

  const handleInitialQuotasSet = () => {
    let res = {};
    brfPremises.forEach((c) => {
      res[`${c.id}`] = c.quota;
    });

    setQuotas(res);
  };

  const quotaValid = () => {
    if (totalQuota + 0.1 < 100 || totalQuota - 0.1 > 100) return false;

    return true;
  };

  const shouldShowWarning = () => {
    if (!brfPremises?.length || isEditingQuotas) return false;

    if (!quotaValid()) return true;

    return false;
  };

  const cancelEditing = () => {
    handleInitialQuotasSet();
    setIsEditingQuotas(false);
  };

  const handleQuotaUpdated = ({ id, val }) => {
    const quotaClone = cloneDeep(quotas);
    quotaClone[`${id}`] = val;

    setQuotas(quotaClone);
  };

  const prefillAreaQuotas = () => {
    const qClone = cloneDeep(quotas);
    brfPremises.forEach((p) => {
      const quota = totalArea ? (p.area * 100) / totalArea : 0;
      qClone[`${p.id}`] = quota;
    });

    setQuotas(qClone);
  };
  const prefillQuotaQuotas = () => {
    const qClone = cloneDeep(quotas);
    brfPremises.forEach((p) => {
      const _fromOverride = qClone[`${p.id}`];
      const _quota = parseFloat(
        (_fromOverride != null ? _fromOverride : p.quota) || 0
      );

      const quota = totalQuota ? (_quota * 100) / totalQuota : 0;
      qClone[`${p.id}`] = quota;
    });

    setQuotas(qClone);
  };
  const prefillInvestmentCapitalQuotas = () => {
    const qClone = cloneDeep(quotas);
    brfPremises.forEach((p) => {
      const quota = totalInvestmentCapital
        ? (p.investment_capital * 100) / totalInvestmentCapital
        : 0;
      qClone[`${p.id}`] = quota;
    });

    setQuotas(qClone);
  };

  const handleSaveQuotas = () => {
    setLoading(true);

    dispatch(
      updateQuotas({
        quotas: quotas,
        brfCompanyId: brfCompanyId,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Uppdatering lyckades",
            })
          );
          setIsEditingQuotas(false);
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Kontrollera uppgifterna och försök igen",
            })
          );
        },
      })
    );
  };

  let premisesHeaders = [
    "Objektsnummer",
    "Lägenhetsnummer",
    "Upplåtelseavgift",
    "Area",
    "Andel enligt area",
    "Insatskapital",
    "Andel enligt insatskapital",
    "Faktisk Andel",
    // isEditingQuotas ? "Ny andel (%)" : "Andel (%)",
    // isEditingQuotas
    //   ? "Ny andel av föreningsavgift (%)"
    //   : "Andel av föreningsavgift (%)",
    // isEditingQuotas ? "Ny avgift/månad (SEK)" : "Avgift/månad (SEK)",
    // "Fakturering aktiv",
  ];
  if (isEditingQuotas) {
    premisesHeaders.push("Andel enligt fördelning av andel");
    premisesHeaders.push("Ny andel");
  }

  // if (isEditingQuotas) {
  //   premisesHeaders.splice(3, 0, "Nuvarande andel (%)");
  //   premisesHeaders.splice(5, 0, "Nuvarande andel av föreningsavgift (%)");
  //   premisesHeaders.splice(7, 0, "Nuvarande avgift/månad (SEK)");
  // }

  const premisesRows = brfPremises?.map((cp) => {
    let row = [
      cp.internal_premises_id,
      cp.premises_id,
      cp.placement_fee,
      cp.area,
      `${(!totalArea ? 0 : (cp.area * 100) / totalArea).toFixed(5)} %`,
      cp.investment_capital,
      `${(!totalInvestmentCapital
        ? 0
        : (cp.investment_capital * 100) / totalInvestmentCapital
      ).toFixed(5)} %`,
      <b>{`${(quotas[`${cp.id}`] ?? cp.quota).toFixed(5)} %`}</b>,
    ];

    if (isEditingQuotas) {
      row.push(
        `${(!totalPrevQuota ? 0 : (cp.quota * 100) / totalPrevQuota).toFixed(
          5
        )}`
      );

      const _quota = quotas[`${cp.id}`];
      row.push(
        <NonConnectedNumberInput
          value={_quota ?? cp.quota}
          onUpdate={(val) =>
            handleQuotaUpdated({ id: cp.id, val: parseFloat(val) })
          }
          rowSize
          noTitle
          noMargin
          id={`q_${cp.id}`}
        />
      );
    }
    return row;
  });

  let summarizerRows = [
    "Total:",
    null,
    totalArea,
    "100 %",
    totalInvestmentCapital,
    "100 %",
    totalPrevQuota,
  ];

  if (isEditingQuotas) {
    summarizerRows.push("100 %");
    summarizerRows.push(`${totalQuota.toFixed(5)} %`);
  }

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmationTitle}
        title={confirmationTitle}
        renderContent={confirmationRenderContent}
        closeFunction={() => {
          setConfirmationFunction(null);
          setConfirmationTitle(null);
          setConfirmationRenderContent(null);
        }}
        acceptCallback={() => confirmationFunction()}
        acceptTitle="Fortsätt"
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>
                Andelstal för {brfCompany?.str_representation}
              </OverviewTitle>
              <OverviewSubtitle>
                Detta justerar andelstalen för lägenheterna, inte andelstalen
                för avgifterna som lägenheterna berörs av. Gå till "Avgifter &
                Lån" för att justera avgiftsgrupper och dess andelar.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            {brfPremises?.length > 0 && (
              <>
                {!isEditingQuotas ? (
                  <PrimaryButton
                    title="Redigera andelstal"
                    clicked={() => setIsEditingQuotas(true)}
                  />
                ) : (
                  <TextButton
                    extraStyle={{ color: "#ea5050" }}
                    title="Avbryt redigering"
                    clicked={cancelEditing}
                  />
                )}
              </>
            )}
          </OverviewTitleWrapper>

          {shouldShowWarning() && (
            <InfoBox
              boxTheme="warning"
              title="Andelstalen verkar inte stämma"
              text={`De sammanräknade andelarna som bostadsrätterna utgör uppgår inte till totalen av föreningen, eller så har inte alla bostadsrätter tilldelats en andel. Tryck på "Redigera andelstal" för att åtgärda detta.`}
            />
          )}

          {isEditingQuotas && (
            <InfoBox
              title="Redigering av andelstal"
              text={`Fördela andelarna på bostadsrätterna.
      Under tabellen med bostadsrätter finns en sammanställning 
      med hur mycket av den totala andelen som är fördelad. 
      För att förifylla andelarna baserat på arean, insatskapitalet, eller för att omfördela baserat på inskrivna värden, tryck på respektive knapp nedan. 
      Tryck på "Spara${
        hasBillectaPermission ? " och gå vidare" : ""
      }" för att komma vidare.
`}
            />
          )}

          {isEditingQuotas && (
            <>
              <TextButton
                extraStyle={{ marginTop: 40 }}
                title="Förifyll andelar baserat på area"
                iconType="arrow"
                iconPlacement="right"
                clicked={prefillAreaQuotas}
              />
              <TextButton
                extraStyle={{ marginTop: 8 }}
                title="Förifyll andelar baserat insatskapital"
                iconType="arrow"
                iconPlacement="right"
                clicked={prefillInvestmentCapitalQuotas}
              />
              <TextButton
                extraStyle={{ marginTop: 8 }}
                title="Omfördela andelar baserat på inskrivna andelar"
                iconType="arrow"
                iconPlacement="right"
                clicked={prefillQuotaQuotas}
              />

              {/* <TextButton
                extraStyle={{ marginTop: 12 }}
                title={`Kopiera "Andel av föreningsavgift" till "Andel av förening" för samtliga rader`}
                iconType="copy"
                iconPlacement="right"
                clicked={() => {
                  setConfirmationFunction(() => moveFeeQuotaToQuota);
                  setConfirmationTitle("Bekräfta kopiering");
                  setConfirmationRenderContent(() => () => (
                    <div>
                      Detta innebär att alla raders värden i{" "}
                      <strong>"Andel av förening"</strong> skrivs över av
                      värdena i radens{" "}
                      <strong>"Andel av föreningsavgift"</strong>
                    </div>
                  ));
                }}
              />

              <TextButton
                extraStyle={{ marginTop: 12 }}
                title={`Kopiera "Andel av förening" till "Andel av föreningsavgift" för samtliga rader`}
                iconType="copy"
                iconPlacement="right"
                clicked={() => {
                  setConfirmationFunction(() => moveQuotaToFeeQuota);
                  setConfirmationTitle("Bekräfta kopiering");
                  setConfirmationRenderContent(() => () => (
                    <div>
                      Detta innebär att alla raders värden i{" "}
                      <strong>"Andel av föreningsavgift"</strong> skrivs över av
                      värdena i radens <strong>"Andel av förening"</strong>
                    </div>
                  ));
                }}
              /> */}
            </>
          )}

          <PremisesTable
            extraContainerStyles={{ marginTop: 40 }}
            title={"Bostadsrätter i föreningen"}
            headers={premisesHeaders}
            rows={premisesRows}
            summarizerRows={summarizerRows}
            loading={brfPremisesLoading}
          />

          {isEditingQuotas && (
            <>
              {!quotaValid() && (
                <InfoBox
                  boxTheme="yellow"
                  title="OBS"
                  text="Andelstalen måste uppgå till mellan 99.9% - 100.1%, samt så måste alla bostadsrätter ha tilldelats en andel för att kunna
              fortsätta."
                />
              )}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <PrimaryButton
                  title={`Spara${
                    hasBillectaPermission ? " och gå vidare" : ""
                  }`}
                  clicked={handleSaveQuotas}
                  disabled={!quotaValid()}
                />
              </div>
            </>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
