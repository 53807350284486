export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INDEX_TABLE_ROW",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INDEX_TABLE_ROW",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INDEX_TABLE_ROW",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INDEX_TABLE_ROW",
  DESTROY_FORM: "DESTROY_FORM_INDEX_TABLE_ROW",
  SET_FORM_ERROR: "SET_FORM_ERROR_INDEX_TABLE_ROW",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INDEX_TABLE_ROW",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INDEX_TABLE_ROW",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INDEX_TABLE_ROW",
  RESET_STATE: "RESET_STATE_INDEX_TABLE_ROW",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INDEX_TABLE_ROW",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDEX_TABLE_ROW",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_INDEX_TABLE_ROW",
  CLEAR_FETCHED: "CLEAR_FETCHED_INDEX_TABLE_ROW",

  LIST_URL: "/standard/index/indextablerow/list/",
  GET_URL: "/standard/index/indextablerow/",
  POST_URL: "/standard/index/indextablerow/",
  PATCH_URL: "/standard/index/indextablerow/",

  STORE_NAME: "indexTableRow",
};
