export function isInViewport(element) {
  const rect = element.getBoundingClientRect();

  const elementBottom = rect.bottom;
  const elementHeight = element.offsetHeight;
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const offset = viewportHeight + elementHeight - viewportHeight;

  const activeWindow = viewportHeight + offset;

  const visible = elementBottom > 0 && elementBottom <= activeWindow;

  const isAbove = elementBottom > 0;

  return [visible, isAbove];
}
