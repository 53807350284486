import { InformationCircleIcon } from "@heroicons/react/24/outline";
import * as React from "react";

/**
 *
 * Options = {
 *  title: "Demo",
 *  Icon: Icon - Heroicon component
 * }
 */
export default function OptionsModal({
  closeFunction,
  options = [],
  onOptionSelected = () => {},
  title,
  info,
  withInfoLink,
  infoLinkText,
  onInfoLinkClicked = () => {},
}) {
  return (
    <div
      tabindex="-1"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-[rgba(0,0,0,0.2)] backdrop-blur-sm flex justify-center"
    >
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div className="relative bg-white rounded shadow">
          <button
            type="button"
            onClick={closeFunction}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-4 border-b rounded-t ">
            <h3 className="text-base font-semibold text-gray-900 lg:text-xl ">
              {title}
            </h3>
          </div>

          <div className="p-6">
            <p className="text-sm font-normal text-gray-500 ">{info}</p>
            <ul className="my-4 space-y-3">
              {options?.map((o) => (
                <li>
                  <div
                    onClick={() => onOptionSelected(o)}
                    className="cursor-pointer flex items-center p-3 text-base font-medium text-gray-700 rounded border border-slate-200 bg-white hover:bg-slate-200 group transition-all ease-in-out duration-300"
                  >
                    <div className="border border-slate-200 flex items-center justify-center p-1 rounded group-hover:bg-primaryblue group-hover:text-white transition-all ease-in-out duration-300">
                      <o.Icon width={16} />
                    </div>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      {o.title}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
            {withInfoLink && (
              <div>
                <div
                  onClick={onInfoLinkClicked}
                  className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline "
                >
                  <InformationCircleIcon width={22} className="mr-1" />
                  {infoLinkText}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
