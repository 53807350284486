export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INSPECTIONS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INSPECTIONS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INSPECTIONS",
  DESTROY_FORM: "DESTROY_FORM_INSPECTIONS",
  SET_FORM_ERROR: "SET_FORM_ERROR_INSPECTIONS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INSPECTIONS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INSPECTIONS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INSPECTIONS",
  RESET_STATE: "RESET_STATE_INSPECTIONS",
  CLEAR_FETCHED: "CLEAR_FETCHED_INSPECTIONS",
  SET_OVERVIEW_STATS: "SET_INSPECTIONS_OVERVIEW_STATS",
  POST_ACCEPT_DECLINE: "POST_ACCEPT_DECLINE_INSPECTIONS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INSPECTIONS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INSPECTIONS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INSPECTIONS",

  LIST_URL: "/errands/main/inspectionerrand/list/",
  POST_URL: "/errands/main/inspectionerrand/",
  PATCH_URL: "/errands/main/inspectionerrand/",
  GET_URL: "/errands/main/inspectionerrand/",
  ACCEPT_DECLINE_URL: "/errands/main/inspectionerrand/accepting/",

  STORE_NAME: "inspectionErrands",
};
