export default {
  ADD_TOAST: "ADD_TOAST_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST_TOAST",
  CLOSE_TOAST: "CLOSE_TOAST_TOAST",
  OPEN_TOAST: "OPEN_TOAST_TOAST",
  LOCK_TOAST: "LOCK_TOAST_TOAST",
  UNLOCK_TOAST: "UNCLOCK_TOAST_TOAST",

  STORE_NAME: "toasts",
};
