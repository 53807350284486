import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import {
  useAllPermissionCheck,
  useFrequentPermissions,
} from "../../store/base";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { useBrfPremises, constants } from "../../store/brfPremises";
import BrfPremisesOverview from "./detail/BrfPremises";
import BrfOwnerSwaps from "./detail/BrfOwnerSwap";
import BrfPremisesImagesAndBlueprints from "./detail/ImagesAndBlueprint";
import BrfPremisesIMD from "./detail/IMD";
import BrfPremisesBrokerDoc from "./detail/BrokerDoc";
import Costs from "../../components/Details/OverviewInfo/Costs/Costs";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import ContractInvoicingBase from "../LeaseContract/Detail/ContractInvoicingBase";
import TFPremisesBase from "../../components/TechnicalManagement/PremisesBase";

export default function MainBrfPremisesDetail() {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["allow_brf", "view_can_baseobject", "view_can_brf"]);

  const canDelete = useAllPermissionCheck([
    "delete_can_baseobject",
    "delete_can_brf",
  ]);
  const canErrands = useAllPermissionCheck([
    "allow_errand_faults",
    "view_can_reporterrand",
  ]);
  const canImd = useAllPermissionCheck(["allow_imd", "view_can_imd"]);

  const { hasBillectaFullPermission } = useFrequentPermissions();

  const { brfPremisesId } = useParams();

  const [brfPremises] = useBrfPremises(brfPremisesId);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Objekt",
      component: BrfPremisesOverview,
    },
    {
      path: "/owners",
      label: "Ägarbyten",
      component: BrfOwnerSwaps,
    },
    {
      path: "/costs",
      label: "Debiteringsrader",
      component: Costs,
    },
    {
      path: "/images-and-blueprint",
      label: "Bilder & ritning",
      component: BrfPremisesImagesAndBlueprints,
    },
    {
      path: "/broker-doc",
      label: "Mäklarbild",
      component: BrfPremisesBrokerDoc,
    },
  ];

  if (hasBillectaFullPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      component: ContractInvoicingBase,
    });
  }

  if (canErrands) {
    SUB_PAGES.push({
      path: "/errands",
      label: "Teknisk förvaltning",
      component: TFPremisesBase,
    });
  }

  if (canImd) {
    SUB_PAGES.push({
      path: "/imd",
      label: "IMD",
      component: BrfPremisesIMD,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
     // disabled: brfPremises?.quota != 0
    });
  }

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel
          state={
            brfPremises?.brf_invoicing_active == null
              ? 6
              : brfPremises?.brf_invoicing_active == false
              ? 3
              : 1
          }
        >
          Aviseringsstatus:{" "}
          {brfPremises?.brf_invoicing_active == null
            ? "Ej konfiguerad"
            : brfPremises?.brf_invoicing_active == false
            ? "Pausad"
            : "Konfigurerad"}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      {canDelete && (
        <DeleteModal
          isOpen={deleteModalOpen}
          closeFunction={() => setDeleteModalOpen(false)}
          constants={constants}
          instance={brfPremises}
          deletedCallback={() => push("/brf-premises")}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Bostadsrätt ${
            brfPremises?.str_representation || "laddar..."
          }`}
          breadCrumbs={[
            { url: "/brf-premises", label: "Bostadsrätter" },
            { label: brfPremises?.str_representation || "Laddar..." },
          ]}
          eventContentType="brf.brfpremis"
          eventObjectId={brfPremisesId}
          eventIdQueryStr="brfpremis"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
