import * as React from "react";

// style, design
import { LinkedObject, ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as industrialDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { detailUrl as cooperativePremisDetailUrl } from "../../../store/brfPremises";
import { detailUrl as commonAreaDetailUrl } from "../../../store/commonAreas";

import { detailUrl as measureDetailUrl } from "../../../store/IMDMeasureType";

export default () => [
  {
    Header: "Sensor id",
    accessor: "sensor_id",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Importerad från",
    accessor: "imported_from_display",
    Cell: ({ value }) => <ToolTipCell text={value || "Manuell import"} />,
    Filter: SimpleTextFilter,
    filter: "text",
    disableSortBy: true,
  },
  {
    Header: "Mätartyp",
    accessor: (row) => {
      return (
        row.measure_type?.title ||
        row.measure_type?.str_representation ||
        "Saknar typ"
      );
    },
    Cell: ({ row }) => {
      const value = row.original.measure_type;

      return <LinkedObject obj={value} urlMethod={measureDetailUrl} />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
    disableSortBy: true,
  },
  {
    Header: "Objektstyp koppling",
    accessor: (row) => {
      if (row.apartment) {
        return "Lägenhet";
      } else if (row.industrial_premises) {
        return "Lokal";
      } else if (row.parking_spot) {
        return "Parkering";
      } else if (row.brf_premis) {
        return "BRF";
      } else if (row.common_area) {
        return "Gemensam yta";
      }
    },
    Cell: ({ row }) => {
      const org = row.original;
      if (org.apartment) {
        return <ToolTipCell text="Lägenhet" />;
      } else if (org.industrial_premises) {
        return <ToolTipCell text="Lokal" />;
      } else if (org.parking_spot) {
        return <ToolTipCell text="Parkering" />;
      } else if (org.brf_premis) {
        return <ToolTipCell text="BRF" />;
      } else if (org.common_area) {
        return <ToolTipCell text="Gemensam yta" />;
      }

      return <ToolTipCell text="Ej kopplad" />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Objektkoppling",
    accessor: (row) => {
      if (row.apartment) {
        return row.apartment.str_representation;
      } else if (row.industrial_premises) {
        return row.industrial_premises.str_representation;
      } else if (row.parking_spot) {
        return row.parking_spot.str_representation;
      } else if (row.brf_premis) {
        return row.brf_premis.str_representation;
      } else if (row.common_area) {
        return row.common_area.str_representation;
      }

      return "Ej kopplad";
    },
    Cell: ({ row }) => {
      const org = row.original;
      if (org.apartment) {
        return (
          <LinkedObject obj={org.apartment} urlMethod={apartmentDetailUrl} />
        );
      } else if (org.industrial_premises) {
        return (
          <LinkedObject
            obj={org.industrial_premises}
            urlMethod={industrialDetailUrl}
          />
        );
      } else if (org.parking_spot) {
        return (
          <LinkedObject obj={org.parking_spot} urlMethod={parkingDetailUrl} />
        );
      } else if (org.brf_premis) {
        return (
          <LinkedObject
            obj={org.brf_premis}
            urlMethod={cooperativePremisDetailUrl}
          />
        );
      } else if (org.common_area) {
        return (
          <LinkedObject obj={org.common_area} urlMethod={commonAreaDetailUrl} />
        );
      }

      return <ToolTipCell text="Ej kopplad" />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Övrig information",
    accessor: (row) => JSON.stringify(row.meta_data),
    Cell: ({ row }) => {
      const value = row.original.meta_data;
      let metaStr = "-";
      if (value && typeof value === "object" && Object.keys(value).length > 0) {
        const metaData = Object.keys(value)
          .filter((key) => typeof value[key] === "string")
          .map((key) => {
            return `${key}: ${value[key]}`;
          });

        if (metaData?.length) {
          metaStr = metaData.join(", ");
        }
      }
      return <div>{metaStr}</div>;
    },
    disableSortBy: true,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
