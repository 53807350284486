import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { TextButton } from "../../../Base/Buttons";
import LocalTextInputField from "../../../Base/Fields/LocalTextInputField";
import TextInputField from "../../../Base/Fields/TextInputField";
import LocalTableSelectField from "../../../Base/Fields/LocalTableSelectField";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";

export default function Debtor({
  title,
  description,
  tenant,
  setTenant,
  tenantInvoiceConfig,
  storeName,
  method,
}) {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>
          {description && <OverviewSubtitle>{description}</OverviewSubtitle>}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {!tenant || !tenantInvoiceConfig ? (
        <LocalTableSelectField
          value={tenant}
          TableComponent={TenantTable}
          persistantQuery={{
            debtor_invoice_config__isnull: false,
          }}
          title="Mottagare"
          placeholder="Välj mottagare..."
          description="Välj mottagare för att hämta detaljer."
          onChange={(val) => setTenant(val)}
        />
      ) : (
        <>
          <div className="grid gap-6 mb-6 grid-cols-2">
            <LocalTextInputField
              title="Namn"
              id="_ro_strrep"
              value={tenant.str_representation || "-"}
              disabled
            />

            <LocalTextInputField
              title="Adress"
              id="_ro_strrep"
              value={tenantInvoiceConfig?.invoice_address?.base || "-"}
              disabled
            />
          </div>

          <div className="grid gap-6 mb-6 grid-cols-2">
            <LocalTextInputField
              title="E-post"
              id="_ro_strrep"
              value={tenantInvoiceConfig?.email_invoice || "-"}
              disabled
            />

            <LocalTextInputField
              title="Telefon"
              id="_ro_strrep"
              value={tenantInvoiceConfig?.invoice_phone || "-"}
              disabled
            />
          </div>

          <div className="grid gap-6 mb-6 grid-cols-2">
            <LocalTextInputField
              title="Hyresgästnummer"
              id="_ro_strrep"
              value={tenant?.customer_id || "-"}
              disabled
            />

            <TextInputField
              title="Mottagarens referens"
              storeName={storeName}
              method={method}
              fieldKey={`YourReference`}
            />
          </div>

          <TextButton
            title="Byt mottagare"
            clicked={() => setTenant(null)}
            iconType="sync"
            iconPlacement="right"
          />
        </>
      )}
    </>
  );
}
