import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";
import { constants, editUrl } from "../../../store/errandRoles";

export default function ErrandRoleTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  hideExport,
}) {
  const columns = React.useMemo(() => columnDefs(), []);

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "errand_roles_full_table"}
        title={"Utföranderoller"}
        onRowClickedWithDetail={(obj) =>
          editUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
          hideExport,
        }}
      />
    </>
  );
}
