import * as React from "react";
import * as SC from "./styles";
import { cloneDeep, debounce } from "lodash";
import Toggle from "../../Toggle/Toggle";
import { useParams } from "react-router-dom";

import { buildQueryString, setActiveFormInstance } from "../../../store/base";
import { createManyStages, useFilteredStages } from "../../../store/pipeStages";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import {
  useFilteredLeads,
  LEAD_RANKING_TYPES,
  useLeadRankings,
  constants as leadConstants,
  clearLeadRankings,
} from "../../../store/leads";
import { DetailPageBox } from "../../sharedStyles";
import LeadDetail from "./LeadDetail";

import "./bgcolor.css";
import {
  clearNewProductionFlowData,
  useFilteredApartmentAds,
} from "../../../store/marketApartmentAds";
import { useFilteredIndustrialPremisesAds } from "../../../store/marketIndpAds";
import { useFilteredParkingSpotAds } from "../../../store/marketParkingAds";
import AdStage from "./AdStage";
import { STAGE_COLORS } from "./Pipe";
import ConnectedAds from "./ConnectedAds";
import AdDetail from "./AdDetail";
import { uid } from "uid";
import LeadModalForm from "../../Forms/Lead/ChapterForm/ModalForm";
import CreateStages from "./CreateStages";
import { clearContractCreationData, usePipe } from "../../../store/pipes";
import theme from "../../../theme";
import StandardModal from "../../Modals/StandardModal";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

export const AD_KINDS = {
  APARTMENT: 1,
  INDP: 2,
  PARKING: 3,
};

export default function AdPipe() {
  const dispatch = useDispatch();
  const { pipeId } = useParams();
  const [pipe] = usePipe(pipeId);
  const isHomeQPipe = pipe?.is_homeq_pipe;
  const isNewProduction = pipe?.homeq_project_id;
  const stagesQ = buildQueryString({
    pipe: pipeId,
  });
  const [stages, stagesLoading] = useFilteredStages(stagesQ);
  const leadsQ = buildQueryString({
    pipe: pipeId,
  });
  const [leads, leadsLoading] = useFilteredLeads(leadsQ);

  const filteredLeads = React.useMemo(() => {
    return leads?.filter((l) => !l.denied);
  }, [leads]);

  const adsQ = buildQueryString({
    dump_in_pipe: pipeId,
    closed: false,
  });

  const [apartmentAdsInPipe, apartmentAdsInPipeLoading] =
    useFilteredApartmentAds(adsQ);
  const [indpAdsInPipe, indpAdsInPipeLoading] =
    useFilteredIndustrialPremisesAds(adsQ);
  const [parkingAdsInPipe, parkingAdsInPipeLoading] =
    useFilteredParkingSpotAds(adsQ);

  const adsInPipe = [
    ...(apartmentAdsInPipe?.map((ap) => ({
      ...ap,
      kind: AD_KINDS.APARTMENT,
    })) || []),
    ...(indpAdsInPipe?.map((ap) => ({
      ...ap,
      kind: AD_KINDS.INDP,
    })) || []),
    ...(parkingAdsInPipe?.map((ap) => ({
      ...ap,
      kind: AD_KINDS.PARKING,
    })) || []),
  ];

  const [currentLeadId, setCurrentLeadId] = React.useState(false);
  const [currentAdId, setCurrentAdId] = React.useState(false);
  const [createLeadOpen, setCreateLeadOpen] = React.useState(false);
  const [sortedStages, setSortedStages] = React.useState([]);
  const [stagesTopDistance, setStagesTopDistance] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [displayList, setDisplayList] = React.useState(false);
  const [proposedStagesOpen, setProposedStagesOpen] = React.useState(false);
  const [loadingStagesCreation, setLoadingStagesCreation] =
    React.useState(false);

  const stagesRef = React.useRef();

  React.useEffect(() => {
    if (!stagesLoading && !stages?.length) {
      setProposedStagesOpen(true);
    } else {
      setProposedStagesOpen(false);
    }
  }, [stagesRef, stagesLoading]);

  React.useEffect(() => {
    dispatch(clearNewProductionFlowData());
    dispatch(clearContractCreationData());
  }, []);

  const [apartmentLeadRankings, apartmentLeadRankingsLoading] = useLeadRankings(
    {
      ids: apartmentAdsInPipe?.map((a) => a.apartment.id),
      type: LEAD_RANKING_TYPES.APARTMENT,
    }
  );
  const [indpLeadRankings, indpLeadRankingsLoading] = useLeadRankings({
    ids: indpAdsInPipe?.map((a) => a.industrial_premises.id),
    type: LEAD_RANKING_TYPES.INDP,
  });
  const [parkingLeadRankings, parkingLeadRankingsLoading] = useLeadRankings({
    ids: parkingAdsInPipe?.map((a) => a.parking_spot.id),
    type: LEAD_RANKING_TYPES.PARKING,
  });

  const [currentAdsInstance, setCurrentAdsInstance] = React.useState({});
  const [forceUpdateId, setForceUpdateId] = React.useState(null);

  const adsInstance = React.useMemo(() => {
    if (!stages?.length || !adsInPipe?.length) return {};

    let adsObj = {
      version: uid(),
      "-1": [],
    };

    stages.forEach((s) => {
      adsObj[s.id] = [];
    });

    const includedLeads = cloneDeep(filteredLeads || []);
    includedLeads.sort((a, b) => {
      return a.legal_id > b.legal_id ? 1 : b.legal_id > a.legal_id ? -1 : 0;
    });

    const includedAds = adsInPipe.filter((ad) => {
      if (searchTerm) {
        if (ad.str_representation.toLowerCase()?.includes(searchTerm)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    includedAds.forEach((ad) => {
      // get leads for ad
      const leadsForAd = includedLeads.filter((l) => {
        if (ad.kind === AD_KINDS.APARTMENT) {
          return l.apartment?.id === ad.apartment?.id;
        } else if (ad.kind === AD_KINDS.INDP) {
          return l.industrial_premises?.id === ad.industrial_premises?.id;
        } else if (ad.kind === AD_KINDS.PARKING) {
          return l.parking_spot?.id === ad.parking_spot?.id;
        }
      });

      if (!leadsForAd || leadsForAd.length === 0) {
        adsObj["-1"].push({ ...ad, stageId: "-1" });
        return;
      }

      // get max stage of leads in ad
      const { maxStageId } = leadsForAd.reduce(
        (acc, cur) => {
          const curStage = stages.find((s) => s.id === cur.stage.id);
          if (!curStage) return acc;
          if (curStage.order > acc.order) {
            return { maxStageId: curStage.id, order: curStage.order };
          } else {
            return acc;
          }
        },
        { maxStageId: 0, order: 0 }
      );
      
      if (adsObj[maxStageId]) {
        adsObj[maxStageId].push({
          ...ad,
          leads: leadsForAd,
          stageId: maxStageId,
        });
      }
    });

    return adsObj;
  }, [
    stages?.length,
    filteredLeads?.length,
    adsInPipe?.length,
    forceUpdateId,
    searchTerm,
  ]);

  React.useEffect(() => {
    if (adsInstance?.version !== currentAdsInstance?.version) {
      setCurrentAdsInstance(adsInstance);
    }
  }, [adsInstance]);

  React.useEffect(() => {
    // add +1 to check for stages because of the "no interest stage" to prevent loop
    if (sortedStages?.length !== stages?.length + 1) {
      const stagesClone = cloneDeep(stages || []);

      // add no interest stage
      stagesClone.push({
        name: "Inga reservationer",
        id: "-1",
        description: "Dessa annonser har ännu inga leads",
        order: -9999,
      });

      stagesClone.sort((a, b) => a.order - b.order);

      setSortedStages(stagesClone);
    }
  }, [stages]);

  const getStagesPosition = () => {
    const clientRect = stagesRef.current?.getBoundingClientRect();

    setStagesTopDistance(clientRect?.top + document.body.scrollTop);
  };

  React.useLayoutEffect(() => {
    getStagesPosition();
    window.addEventListener("resize", debounce(getStagesPosition, 100));

    return () => {
      window.removeEventListener("resize", debounce(getStagesPosition));
    };
  }, []);

  const currentAd = Object.values(currentAdsInstance || {})
    ?.flat()
    ?.find((ad) => ad.id === currentAdId);
  const currentAds = currentAdsInstance[currentAd?.stageId];

  const resetAdsInstance = () => {
    setForceUpdateId(uid());
    dispatch(clearLeadRankings());
  };

  // reset ads instance if more ads added
  React.useEffect(() => {
    if (
      adsInPipe?.length !== Object.values(adsInstance)?.flat()?.length - 1 &&
      Object.values(adsInstance)?.flat()?.length - 1 > 0
    ) {
      resetAdsInstance();
    }
  }, [adsInPipe, adsInstance]);

  const createDefaultStages = (stages) => {
    setLoadingStagesCreation(true);

    dispatch(
      createManyStages({
        stages: stages.map((s) => ({ ...s, pipe: { id: pipeId } })),
        successCallback: () => {
          setLoadingStagesCreation(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Steg skapades",
              description: "Detta projekt är nu redo att kopplas mot annonser",
            })
          );
        },
        errorCallback: () => {
          setLoadingStagesCreation(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Steg kunde ej skapas",
              description: "Försök igen",
            })
          );
        },
      })
    );
  };

  const handleOpenCreateLead = ({ objKind, objId }) => {
    dispatch(
      setActiveFormInstance({
        storeName: leadConstants.STORE_NAME,
        data: {
          [objKind]: {
            id: objId,
          },
        },
      })
    );

    setCreateLeadOpen(true);
  };

  return (
    <>
      <AdDetail
        ad={currentAd}
        ads={currentAds}
        isOpen={!!currentAdId}
        closeFunction={() => setCurrentAdId(null)}
        onAdDetailOpened={(ad) => setCurrentAdId(ad)}
        onLeadDetailOpened={(leadId) => setCurrentLeadId(leadId)}
        isHomeQPipe={isHomeQPipe}
      />

      <LeadDetail
        lead={filteredLeads?.find((l) => l.id === currentLeadId)}
        leads={filteredLeads}
        isOpen={!!currentLeadId}
        closeFunction={() => setCurrentLeadId(null)}
        onLeadDetailOpened={(leadId) => setCurrentLeadId(leadId)}
        updateLeadsInstance={resetAdsInstance}
      />

      <StandardModal
        canClose={false}
        title="Lägg till steg i projekt"
        isOpen={proposedStagesOpen}
        closeFunction={() => setProposedStagesOpen(false)}
      >
        {loadingStagesCreation && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Detta projekt saknar steg</OverviewTitle>
            <OverviewSubtitle>
              Lägg till steg genom att antingen välja Pigellos förslag eller
              skapa egna steg. Steg kan alltid läggas till, tas bort och
              redigeras i efterhand.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <CreateStages
          isHomeQPipe={pipe?.is_homeq_pipe}
          createDefaultStages={createDefaultStages}
        />
      </StandardModal>

      {!isHomeQPipe && (
        <LeadModalForm
          method="POST"
          onCheckout={() => setCreateLeadOpen(false)}
          isOpen={createLeadOpen}
          onLeadCreated={resetAdsInstance}
          hideObjects
        />
      )}

      <SC.PipeWrapper>
        <SC.ActionBar>
          {displayList ? (
            <div></div>
          ) : (
            <SC.SearchBarContainer>
              <SC.SearchIcon />
              <SC.SearchBar
                placeholder="Sök bland annonser"
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </SC.SearchBarContainer>
          )}

          <div style={{ display: "flex", alignItems: "center" }}>
            <Toggle
              extraTitleStyle={{
                fontSize: 12,
                fontWeight: 500,
                marginRight: 6,
              }}
              title="Visa som lista"
              value={displayList}
              onToggle={() => setDisplayList(!displayList)}
            />
          </div>
        </SC.ActionBar>

        {displayList ? (
          <DetailPageBox
            style={{
              borderRadius: 0,
              marginTop: 0,
              minHeight: window.innerHeight - stagesTopDistance,
            }}
          >
            <ConnectedAds />
          </DetailPageBox>
        ) : (
          <>
            {isNewProduction && adsInPipe?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                  width: "100%",
                  fontWeight: theme.fontWeights.headerXSmall,
                  fontSize: theme.fontSizes.headerXSmall,
                }}
              >
                Tryck på "Publicera annonser" för att komma igång{" "}
              </div>
            ) : (
              <SC.StagesWrapper topDistance={stagesTopDistance} ref={stagesRef}>
                {(apartmentAdsInPipeLoading ||
                  indpAdsInPipeLoading ||
                  parkingAdsInPipeLoading ||
                  stagesLoading) && <OverlaySpinner />}
                {sortedStages?.map((stage, idx) => (
                  <AdStage
                    isNoInterestStage={stage.id == "-1"}
                    key={stage.id}
                    isSignStage={idx === stages.length && idx !== 0}
                    stage={stage}
                    ads={currentAdsInstance[stage.id] || []}
                    color={STAGE_COLORS[idx - 1] || "#ea5050"}
                    onLeadDetailOpened={(leadId) => setCurrentLeadId(leadId)}
                    onAdDetailOpened={(adId) => setCurrentAdId(adId)}
                    onCreateLead={handleOpenCreateLead}
                    isHomeQPipe={isHomeQPipe}
                    updateLeadsInstance={resetAdsInstance}
                    {...{
                      apartmentLeadRankings,
                      parkingLeadRankings,
                      indpLeadRankings,
                    }}
                  />
                ))}
              </SC.StagesWrapper>
            )}
          </>
        )}
      </SC.PipeWrapper>
    </>
  );
}
