import * as React from "react";

import {
  TextInput,
  DatePicker,
  RadioGroup,
  MonthInput,
} from "../../../Base/Fields";

import { constants } from "../../../../../store/leaseContracts";
import { useFormInstanceField } from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const endDate = useFormInstanceField({
    storeName,
    fieldKey: "end_date",
  });

  const renewIndefinetely = useFormInstanceField({
    storeName,
    fieldKey: "renew_indefinetely",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Avtalsdetaljer</OverviewTitle>
          <OverviewSubtitle>
            Ange detaljer kring bestämmelserna för avtalet
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      <TextInput
        title="Avtals-ID"
        description="Ange det interna ID:t för avtalet"
        storeName={storeName}
        fieldKey="id_number"
        method={method}
      ></TextInput>

      <DatePicker
        title="Startdatum"
        description="Datum när avtalet träder i kraft"
        storeName={storeName}
        fieldKey="start_date"
        method={method}
      />

      <DatePicker
        storeName={storeName}
        fieldKey="end_date"
        title="Slutdatum"
        description="Lämna tomt om avtalet är tillsvidare"
        method={method}
      />

      {endDate && (
        <RadioGroup
          storeName={storeName}
          fieldKey="renew_indefinetely"
          title="Förläng på obestämd tid vid passerat slutdatum"
          description="Välj hur ett passerat slutdatum utan att avtalet har sagts upp ska hanteras."
          method={method}
          forceHorizontalToolTipPlacement="left"
          forceVerticalToolTipPlacement="top"
          defaultValue={false}
          options={[
            {
              label:
                "Förläng med vald förlängningstid när slutdatumet passeras",
              value: false,
            },
            {
              label: "Förläng på obestämd tid när slutdatumet passeras",
              value: true,
            },
            {
              label:
                "Förläng ej när slutdatumet passeras (kräver uppsägningstid)",
              value: "no_renewal",
            },
          ]}
        />
      )}

      <MonthInput
        title="Uppsägningstid"
        storeName={storeName}
        fieldKey="notify_interval"
        method={method}
      />

      {!!endDate && !renewIndefinetely && (
        <MonthInput
          storeName={storeName}
          title="Förlängningstid"
          description="Ange i hur långa intervall avtalet förlängs"
          fieldKey="renew_interval"
          method={method}
        />
      )}

      <MonthInput
        title="Varna så här lång tid innan avtalet går ut "
        description="Pigello skickar en notis om att avtalet är på väg att gå ut så här lång tid innan slutdatum + uppsägningstid"
        storeName={storeName}
        fieldKey="warning_interval"
        method={method}
      />
    </>
  );
};
