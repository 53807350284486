import { useDispatch, useSelector } from "react-redux";
import { getIsHomeQAuthenticated } from "../store/actions";
import constants from "../store/constants";

export const useIsHomeQAuthenticated = () => {
  const dispatch = useDispatch();

  const authenticated = useSelector(
    (state) => state[constants.STORE_NAME].authenticated
  );
  const inProgress = useSelector(
    (state) => state[constants.STORE_NAME].authCheckInProgress
  );

  if (inProgress) return [undefined, true];

  if (authenticated === false) return [false, false];
  if (authenticated === true) return [true, false];

  // if not true or false, get it
  if (!inProgress && authenticated == null) dispatch(getIsHomeQAuthenticated());

  return [undefined, true];
};
