import * as React from "react";

import { Confirm, Statuses } from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "STATUSES":
      return <Statuses method={method} />;
    default:
      return <Confirm />;
  }
};
