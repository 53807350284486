import { cloneDeep } from "lodash";
import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);

  const { type, payload } = action;

  switch (type) {
    case constants.SET_LEAD_RANKING: {
      const { name, rankings } = payload;

      const stateClone = cloneDeep(state);

      if (stateClone.rankings) {
        stateClone.rankings[name] = rankings;
      } else {
        stateClone.rankings = {
          [name]: rankings,
        };
      }

      return stateClone;
    }

    case constants.CLEAR_LEAD_RANKING: {
      const stateClone = cloneDeep(state);

      delete stateClone.rankings;

      return stateClone;
    }

    case constants.SET_LEAD_PER_STAGE_COUNT: {
      return { ...newState, leadsPerStageData: payload.data };
    }

    default:
      return newState;
  }
};
