export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_OTHERINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_OTHERINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_OTHERINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_OTHERINVOICING",
  DESTROY_FORM: "DESTROY_FORM_OTHERINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_OTHERINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_OTHERINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_OTHERINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_OTHERINVOICING",
  RESET_STATE: "RESET_STATE_OTHERINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_OTHERINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_OTHERINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_OTHERINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_OTHERINVOICING",
  SET_PERIODS_FOR_CONTRACT: "SET_PERIOD_FOR_CONTRACT_OTHERINVOICING",
  CLEAR_PERIODS_FOR_CONTRACT: "CLEAR_PERIOD_FOR_CONTRACT_OTHERINVOICING",

  LIST_URL: "/accounting/contract/otherinvoicing/list/",
  POST_URL: "/accounting/contract/otherinvoicing/",
  PATCH_URL: "/accounting/contract/otherinvoicing/",
  GET_URL: "/accounting/contract/otherinvoicing/",
  MASS_INVOICE_STATUS_UPDATE_URL:
    "/accounting/contract/masspause_otherinvoicing/",
  PREVIEW_URL: "/accounting/contract/preview_otherinvoicing/",
  GENERATE_URL: "/accounting/contract/generatenow_otherinvoicing/",
  PERIOD_URL: "/accounting/contract/periodinfo_otherinvoicing/",

  STORE_NAME: "invoicingOther",
};
