import constants from "./constants";
import * as services from "./services";
import { store } from "../../store";
import { cloneDeep, set } from "lodash";

export const addCurrentObject = ({ groupId, contentType }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_OBJECT,
      payload: {
        contentType,
        groupId,
      },
    });
  };
};

export const addWhitelistObject = ({ object, contentType }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_WHITELIST_OBJECT,
      payload: {
        object,
        contentType,
      },
    });
  };
};

export const removeWhiteListObject = ({ object, contentType }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_WHITELIST_OBJECT,
      payload: {
        object,
        contentType,
      },
    });
  };
};

export const setRows = ({ newRows, contentType }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_ROWS,
      payload: {
        newRows,
        contentType,
      },
    });
  };
};

export const performUpdate = ({ successCallback, errorCallback, groupId }) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const current = state[constants.STORE_NAME].current;
      let keys = Object.keys(current).filter((i) => i !== "groupId");
      if (!keys.length || !current.groupId) {
        // no data! asume success
        if (successCallback) {
          successCallback();
        }
        return;
      }

      // turn groupId to Int from string
      let backendObject = {};

      set(backendObject, "group_id", parseInt(groupId ?? current.groupId));
      keys.forEach((k) => {
        set(backendObject, `data[${k}]`, current[k]);
      });

      // make call
      await services.performUpdate({ body: backendObject });

      // dispatch(clearFetched(apartmentConstants, true));

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e);
    }
  };
};

export const getCurrentRows = ({ groupId, contentType }) => {
  return async (dispatch) => {
    try {
      const { data } = await services.getCurrentRows({ groupId, contentType });

      dispatch({
        type: constants.SET_ROWS,
        payload: {
          newRows: data,
          contentType,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
};
