export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_USER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_USER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_USER",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_USER",
  DESTROY_FORM: "DESTROY_FORM_USER",
  SET_FORM_ERROR: "SET_FORM_ERROR_USER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_USER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_USER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_USER",
  RESET_STATE: "RESET_STATE_USER",
  CLEAR_FETCHED: "CLEAR_FETCHED_USERS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_USER",
  REMOVE_OBJECT: "REMOVE_OBJECT_USER",

  SET_USER_ACTIVITIES: "SET_USER_ACTIVITIES",

  LIST_URL: "/accounts/users/user/list/",
  POST_URL: "/accounts/users/user/",
  PATCH_URL: "/accounts/users/user/",
  CREATE_PATCH_URL: "/accounts/users/user/create/",
  GET_URL: "/accounts/users/user/",
  CREATE_GET_URL: "/accounts/users/user/create/",
  INVITE_USERS_URL: "/accounts/auth/users/send_invites/",

  STORE_NAME: "users",
};
