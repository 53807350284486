import * as React from "react";

import { ToolTipCell, EmailTag, PhoneTag } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default () => [
  {
    Header: "Namn",
    accessor: "tenant.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Person/org-nr",
    accessor: "tenant.user.legal_id",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Email",
    accessor: "tenant.user.email",
    Cell: ({ value }) => <EmailTag mail={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Tel-nummer",
    accessor: "tenant.user.phone",
    Cell: ({ value }) => <PhoneTag phone={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
