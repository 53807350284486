import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, InfoBox, LinkedObject } from "../../../components/Displays";
import { getIntervalStr } from "../../../components/Displays/InvoicingHelpers";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import InvoicingBrfModalForm from "../../../components/Forms/InvoicingBrf/ChapterForm/ModalForm";
import InvoicingLeaseModalForm from "../../../components/Forms/InvoicingLease/ChapterForm/ModalForm";
import InvoicingOtherModalForm from "../../../components/Forms/InvoicingOther/ChapterForm/ModalForm";
import InvoicingParkingModalForm from "../../../components/Forms/InvoicingParking/ChapterForm/ModalForm";
import ContractInvoices from "../../../components/Invoicing/ContractInvoices/ContractInvoices";
import InvoicingRecord from "../../../components/Invoicing/InvoicingRecord/InvoicingRecord";
import PeriodModal from "../../../components/Invoicing/PeriodModal/PeriodModal";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import StandardModal from "../../../components/Modals/StandardModal";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import InvoicingRecordTable from "../../../components/Tables/InvoicingRecord/FullTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { buildQueryString, clearFetched } from "../../../store/base";
import { useAccountBalanceForDebtor } from "../../../store/billectaAccountBalance";
import { clearSearchWithId } from "../../../store/billectaSearch";
import {
  createCostsUrl as brfCreateCostsUrl,
  useBrfPremises,
} from "../../../store/brfPremises";
import {
  detailUrl as companyDetailUrl,
  useCompany,
} from "../../../store/companies";
import {
  clearPeriodsForContract as brfClearPeriodsForContract,
  previewInvoice as brfPreviewInvoice,
  update as brfUpdate,
  useBrfInvoicing,
} from "../../../store/invoicingBrf";
import { useCompanyInvoicing } from "../../../store/invoicingCompany";
import { detailUrl as debtSettingDetailUrl } from "../../../store/invoicingDebtSettings";
import { useDebtorInvoicing } from "../../../store/invoicingDebtor";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import { detailUrl as IMDSettingDetailUrl } from "../../../store/invoicingIMDSettings";
import {
  INVOICE_TYPES,
  clearPeriodsForContract as leaseClearPeriodsForContract,
  detailUrl as leaseDetailUrl,
  previewInvoice as leasePreviewInvoice,
  update as leaseUpdate,
  previewAndGenerateUrl,
  useLeaseInvoicing,
} from "../../../store/invoicingLease";
import {
  clearPeriodsForContract as otherClearPeriodsForContract,
  previewInvoice as otherPreviewInvoice,
  update as otherUpdate,
  useOtherInvoicing,
} from "../../../store/invoicingOther";
import {
  clearPeriodsForContract as parkingClearPeriodsForContract,
  previewInvoice as parkingPreviewInvoice,
  update as parkingUpdate,
  useParkingInvoicing,
} from "../../../store/invoicingParking";
import {
  constants as invoicingRecordsConstants,
  useFilteredInvoicingRecords,
  useInvoicingRecordPaginationCount,
} from "../../../store/invoicingRecords";
import {
  NON_INVOICING_STATES,
  detailUrl as invoicingSettingDetailUrl,
  useInvoicingSetting,
} from "../../../store/invoicingSettings";
import {
  createCostsUrl as leaseCreateCostsUrl,
  useLeaseContract,
} from "../../../store/leaseContracts";
import {
  createCostsUrl as otherCreateCostsUrl,
  useOtherContract,
} from "../../../store/otherContracts";
import {
  createCostsUrl as parkingCreateCostsUrl,
  useParkingContract,
} from "../../../store/parkingContracts";
import {
  detailUrl as tenantDetailUrl,
  useTenant,
} from "../../../store/tenants";
import { TOAST_TYPES, addToast } from "../../../store/toasts";

const getInvoiceTypeFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return INVOICE_TYPES.LEASE;
  if (otherContractId) return INVOICE_TYPES.OTHER;
  if (parkingContractId) return INVOICE_TYPES.PARKING;
  if (brfPremisesId) return INVOICE_TYPES.BRF;
};
const getContractIdFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leaseContractId;
  if (otherContractId) return otherContractId;
  if (parkingContractId) return parkingContractId;
  if (brfPremisesId) return brfPremisesId;
};
const getContractHookFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return useLeaseContract;
  if (otherContractId) return useOtherContract;
  if (parkingContractId) return useParkingContract;
  if (brfPremisesId) return useBrfPremises;
};
const getContractInvocingHookFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return useLeaseInvoicing;
  if (otherContractId) return useOtherInvoicing;
  if (parkingContractId) return useParkingInvoicing;
  if (brfPremisesId) return useBrfInvoicing;
};

const getUpdateMethodFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leaseUpdate;
  if (otherContractId) return otherUpdate;
  if (parkingContractId) return parkingUpdate;
  if (brfPremisesId) return brfUpdate;
};

const getInvoicingKeyFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return "lease_invoicing";
  if (otherContractId) return "other_invoicing";
  if (parkingContractId) return "parking_invoicing";
  if (brfPremisesId) return "brf_invoicing";
};
const getInvoicingContentTypeFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return "accounting.leaseinvoicing";
  if (otherContractId) return "accounting.otherinvoicing";
  if (parkingContractId) return "accounting.parkinginvoicing";
  if (brfPremisesId) return "accounting.brfinvoicing";
};
const getInvoicingFilterStrFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return "leaseinvoicing";
  if (otherContractId) return "otherinvoicing";
  if (parkingContractId) return "parkinginvoicing";
  if (brfPremisesId) return "brfinvoicing";
};
const getCreateCostsUrlFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leaseCreateCostsUrl;
  if (otherContractId) return otherCreateCostsUrl;
  if (parkingContractId) return parkingCreateCostsUrl;
  if (brfPremisesId) return brfCreateCostsUrl;
};
const getPatchFormFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return InvoicingLeaseModalForm;
  if (otherContractId) return InvoicingOtherModalForm;
  if (parkingContractId) return InvoicingParkingModalForm;
  if (brfPremisesId) return InvoicingBrfModalForm;
};
const getClearPeriodMethodFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leaseClearPeriodsForContract;
  if (otherContractId) return otherClearPeriodsForContract;
  if (parkingContractId) return parkingClearPeriodsForContract;
  if (brfPremisesId) return brfClearPeriodsForContract;
};
const getPreviewMethodFromParams = ({
  leaseContractId,
  otherContractId,
  parkingContractId,
  brfPremisesId,
}) => {
  if (leaseContractId) return leasePreviewInvoice;
  if (otherContractId) return otherPreviewInvoice;
  if (parkingContractId) return parkingPreviewInvoice;
  if (brfPremisesId) return brfPreviewInvoice;
};

const GENERATE_SPECIFIC_PERIOD_TYPES = {
  PREVIEW: 1,
  GENERATE: 2,
};

export default function ContractInvoicingBase() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { leaseContractId, otherContractId, parkingContractId, brfPremisesId } =
    useParams();
  const contractParams = {
    leaseContractId,
    otherContractId,
    parkingContractId,
    brfPremisesId,
  };

  const [editOpen, setEditOpen] = React.useState(false);

  const invoiceType = getInvoiceTypeFromParams(contractParams);
  const contractId = getContractIdFromParams(contractParams);
  const contractHook = getContractHookFromParams(contractParams);
  const contractInvoicingHook =
    getContractInvocingHookFromParams(contractParams);
  const invoicingKey = getInvoicingKeyFromParams(contractParams);
  const contentType = getInvoicingContentTypeFromParams(contractParams);
  const createCostsUrl = getCreateCostsUrlFromParams(contractParams);
  const updateMethod = getUpdateMethodFromParams(contractParams);
  const clearPeriodMethod = getClearPeriodMethodFromParams(contractParams);
  const PatchForm = getPatchFormFromParams(contractParams);
  const filterStr = getInvoicingFilterStrFromParams(contractParams);
  const previewMethod = getPreviewMethodFromParams(contractParams);
  const isBrf = !!brfPremisesId;

  const [contract, contractLoading] = contractHook(contractId);
  const [loading, setLoading] = React.useState(false);
  const [allRecordsOpen, setAllRecordsOpen] = React.useState(false);
  const [confirmFunction, setConfirmFunction] = React.useState(null);
  const [confirmTitle, setConfirmTitle] = React.useState("");
  const [confirmText, setConfirmText] = React.useState("");
  const [nextInvoiceData, setNextInvoiceData] = React.useState(null);
  const [invoicingObj, invoicingObjLoading] = contractInvoicingHook(
    contract?.[invoicingKey]?.id
  );
  const debtorId = nextInvoiceData?.DebtorPublicId;
  const [accountBalance, accountBalanceLoading] =
    useAccountBalanceForDebtor(debtorId);
  const accountBalanceSum = accountBalance?.reduce((acc, cur) => {
    return acc + cur.Amount.ValueForView;
  }, 0);

  const nextInvoiceTotal = nextInvoiceData?.Records?.reduce((acc, cur) => {
    const curVal = ((cur.UnitPrice.Value || 0) / 100) * (cur.Quantity || 0);

    return acc + curVal;
  }, 0);

  const [setting, settingLoading] = useInvoicingSetting(
    invoicingObj?.setting?.id
  );
  const [billingCompany] = useCompany(invoicingObj?.billing_company?.id);
  const [companyInvoiceConfig] = useCompanyInvoicing(
    billingCompany?.invoicing_details?.id
  );
  const useDebtorBalance = companyInvoiceConfig?.use_debtor_balance;
  const showUnpaidOnInvoice = companyInvoiceConfig?.show_unpaid_next;
  const adminFee = companyInvoiceConfig?.admin_fee;
  const [tenant] = useTenant(contract?.tenant?.id);
  const [debtorInvoiceConfig] = useDebtorInvoicing(
    tenant?.debtor_invoice_config?.id
  );
  const [generateForSpecificPeriodOpen, setGenerateForSpecificPeriodOpen] =
    React.useState(false);

  const [invoicingRecordsCount] = useInvoicingRecordPaginationCount({
    filters: {
      [invoicingKey]: contract?.[invoicingKey]?.id,
    },
  });

  React.useEffect(() => {
    if (!nextInvoiceData && invoicingObj?.current_invoice_date) {
      dispatch(
        previewMethod({
          id: invoicingObj.id,
          basedOn: invoicingObj?.current_invoice_date,
          successCallback: (_, invoiceData) => {
            setNextInvoiceData(invoiceData);
          },
          errorCallback: () => {
            return;
          },
        })
      );
    }
  }, [invoicingObj]);

  const displayedInvoicingRecordsQ = {
    [invoicingKey]: contract?.[invoicingKey]?.id || "-1",
    order_by: "-updated_at",
  };

  //logs
  const [invoicingRecords] = useFilteredInvoicingRecords(
    buildQueryString(displayedInvoicingRecordsQ)
  );

  const invoicingActive =
    invoicingObj?.is_active &&
    invoicingObj?.billing_company &&
    invoicingObj?.setting;

  const displayedInvoicingRecords = [...(invoicingRecords?.slice(0, 2) || [])];

  const [invoicingErrorCount] = useInvoicingErrorPaginationCount({
    filters: {
      [filterStr]: invoicingObj?.id || -1,
    },
  });

  const invoicingErrorQ = {
    [filterStr]: invoicingObj?.id,
  };

  const shouldBeInvoicing = !NON_INVOICING_STATES.includes(contract?.state);
  const autogiroAvailableForDebtor = debtorInvoiceConfig?.use_autogiro;
  const autogiroActivatedForContract = invoicingObj?.use_autogiro !== false;
  const configMissing = !contractLoading && !contract?.[invoicingKey];
  const billWithLease = invoicingObj?.bill_with_lease; // determines wether isn't a main billed contract

  // clear invoicing records and invoices
  React.useEffect(() => {
    return () => {
      dispatch(clearFetched(invoicingRecordsConstants, true));
      dispatch(clearSearchWithId({ name: `${invoicingKey}_${contractId}` }));
    };
  }, []);

  const toggleInvoicingActive = () => {
    setLoading(true);
    const forceData = {
      is_active: !invoicingActive,
    };

    dispatch(
      updateMethod({
        forceData,
        id: invoicingObj?.id,
        successCallback: () => {
          setLoading(false);
          dispatch(clearPeriodMethod({ id: contract?.[invoicingKey]?.id }));
        },
        errorCallback: () => setLoading(false),
      })
    );
  };

  const toggleAutoGiro = () => {
    const newUseAutogiro = !autogiroActivatedForContract;

    dispatch(
      updateMethod({
        id: contract?.[invoicingKey]?.id,
        forceData: {
          use_autogiro: newUseAutogiro,
        },
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: `Autogiro ${
                newUseAutogiro ? "aktiverades" : "inaktiverades"
              }`,
            })
          );

          dispatch(clearPeriodMethod({ id: contract?.[invoicingKey]?.id }));
        },
      })
    );
  };

  if (configMissing) {
    return (
      <DetailInnerWrapper>
        <DetailPageBox centerAll style={{ minHeight: 400 }}>
          <OverviewTitle small>Aviseringsinställning saknas</OverviewTitle>
          <PrimaryButton
            extraStyle={{ marginTop: 24 }}
            title={
              isBrf
                ? "Ställ in avisering för denna bostadsrätt"
                : "Ställ in avisering för detta avtal"
            }
            clicked={() => push(createCostsUrl({ id: contractId }))}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    );
  }

  if (billWithLease) {
    return (
      <>
        <ConfirmationModal
          isOpen={!!confirmFunction}
          closeFunction={() => setConfirmFunction(null)}
          acceptCallback={confirmFunction}
          title={confirmTitle}
        />

        <PatchForm
          method="PATCH"
          id={invoicingObj?.id}
          instance={invoicingObj}
          isOpen={editOpen}
          onCheckout={() => setEditOpen(false)}
        />

        <DetailInnerWrapper>
          {invoicingErrorCount > 0 && (
            <DetailPageBox error>
              <OverviewTitleWrapper>
                <OverviewTitle small>Felmeddelanden</OverviewTitle>
              </OverviewTitleWrapper>
              <InvoicingErrorTable
                isBare
                ignoreLocalStorage
                persistantQueryString={invoicingErrorQ}
              />
            </DetailPageBox>
          )}

          <DetailPageBox centerAll style={{ minHeight: 400 }}>
            <OverviewTitle small>
              Detta avtal aviseras tillsammans med huvudavtalet
            </OverviewTitle>
            {contract?.main_contract?.id ? (
              <PrimaryButton
                extraStyle={{ marginTop: 24 }}
                title="Gå till huvudavtal"
                clicked={() =>
                  push(leaseDetailUrl({ id: contract?.main_contract?.id }))
                }
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                Huvudavtal saknas. Koppla detta avtal mot ett huvudavtal eller
                uppdatera aviseringsinställningarna för att komma igång med
                aviseringen.
              </div>
            )}

            <SecondaryButton
              extraStyle={{ marginTop: 24 }}
              title="Uppdatera aviseringsinställningarna för detta avtal"
              clicked={() => setEditOpen(true)}
            />
          </DetailPageBox>
        </DetailInnerWrapper>
      </>
    );
  }

  return (
    <>
      <PatchForm
        method="PATCH"
        id={invoicingObj?.id}
        instance={invoicingObj}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <ConfirmationModal
        isOpen={!!confirmFunction}
        closeFunction={() => setConfirmFunction(null)}
        acceptCallback={confirmFunction}
        title={confirmTitle}
        renderContent={() => <div>{confirmText}</div>}
      />

      {invoicingObj && (
        <PeriodModal
          isOpen={generateForSpecificPeriodOpen}
          closeFunction={() => setGenerateForSpecificPeriodOpen(false)}
          basedOn={invoicingObj.current_invoice_date}
          invoicingRecords={invoicingRecords}
          nextInvoicePeriod={{
            period_start: invoicingObj?.current_period_start,
            period_end: invoicingObj?.current_period_end,
          }}
          nextInvoiceTotal={nextInvoiceTotal}
          id={invoicingObj.id}
          onSelected={({ basedOn, periodStart, periodEnd }) => {
            const existingRecord = invoicingRecords?.find(
              (i) =>
                moment(i.period_start).isSame(moment(periodStart)) &&
                moment(i.period_end).isSame(moment(periodEnd)) &&
                i.billecta_id !== null
            );

            push(
              previewAndGenerateUrl({
                id: invoicingObj?.id,
                contractId,
                type: invoiceType,
                withGenerate:
                  generateForSpecificPeriodOpen ===
                  GENERATE_SPECIFIC_PERIOD_TYPES.GENERATE,
                basedOn: basedOn,
                hasGenerated: !!existingRecord,
              })
            );
          }}
        />
      )}

      <StandardModal
        isOpen={allRecordsOpen}
        closeFunction={() => setAllRecordsOpen(false)}
        title="Aviseringsloggar"
        withActionBar
      >
        <InvoicingRecordTable
          isBare
          ignoreLocalStorage
          persistantQuery={displayedInvoicingRecordsQ}
        />
      </StandardModal>

      <DetailInnerWrapper>
        {invoicingErrorCount > 0 && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle small>Felmeddelanden</OverviewTitle>
            </OverviewTitleWrapper>
            <InvoicingErrorTable
              isBare
              ignoreLocalStorage
              persistantQuery={invoicingErrorQ}
            />
          </DetailPageBox>
        )}

        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 2, maxWidth: "66%", alignSelf: "flex-start" }}
          >
            {!shouldBeInvoicing && (
              <InfoBox
                boxTheme="yellow"
                title="Avisering ej aktiverad"
                text="Statusen på avtalet gör att ingen avisering är aktiv. Detta kan bero på att avtalet ej är signerat eller att statusen är okänd."
              />
            )}

            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Avtalsavisering</OverviewTitle>
                <OverviewSubtitle>
                  Information och inställningar för avisering av detta avtal
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {invoicingObj && setting && (
              <DetailInfo
                infoObj={getInfoObj({
                  shouldBeInvoicing,
                  invoicingObj,
                  tenant,
                  billingCompany,
                  setting,
                  debtorInvoiceConfig,
                  companyInvoiceConfig,
                  autogiroActivatedForContract,
                  autogiroAvailableForDebtor,
                  toggleAutoGiro,
                  nextInvoiceTotal,
                  nextInvoiceData,
                  accountBalanceSum,
                  useDebtorBalance,
                  showUnpaidOnInvoice,
                  adminFee,
                })}
              />
            )}

            {(!invoicingObj?.setting || !invoicingObj?.billing_company) && (
              <InnerBox error>
                Detta avtal är ej uppsatt för avisering än. Specificera
                aviserande bolag och en aviseringsinställning för att komma
                igång med aviseringen.
                <br />
                <br />
                <TextButton
                  title="Aktivera avisering för detta avtal"
                  clicked={() => setEditOpen(true)}
                  iconType="edit"
                  iconPlacement="right"
                />
              </InnerBox>
            )}
          </DetailPageBox>

          <div
            style={{
              flex: 1,
              maxWidth: "33%",
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailPageBox style={{ marginBottom: 0 }}>
              {loading && <OverlaySpinner />}
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hantera avisering</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <TextButton
                extraStyle={{ marginBottom: 16 }}
                title="Redigera inställningar"
                clicked={() => setEditOpen(true)}
                iconType="edit"
              />

              {invoicingActive && shouldBeInvoicing && (
                <>
                  {" "}
                  <TextButton
                    extraStyle={{ marginBottom: 16 }}
                    title="Förhandsgranska avi för en specifik period"
                    clicked={() =>
                      setGenerateForSpecificPeriodOpen(
                        GENERATE_SPECIFIC_PERIOD_TYPES.PREVIEW
                      )
                    }
                    iconType="receipt"
                  />
                  <TextButton
                    extraStyle={{ marginBottom: 16 }}
                    title="Generera avi för en specifik period"
                    clicked={() =>
                      setGenerateForSpecificPeriodOpen(
                        GENERATE_SPECIFIC_PERIOD_TYPES.GENERATE
                      )
                    }
                    iconType="sync"
                  />
                </>
              )}

              {shouldBeInvoicing && (
                <TextButton
                  red={invoicingActive}
                  title={
                    invoicingActive
                      ? "Stäng av avisering"
                      : "Aktivera avisering"
                  }
                  clicked={
                    invoicingObj?.setting && invoicingObj?.billing_company
                      ? () => {
                          setConfirmFunction(
                            () => () => toggleInvoicingActive()
                          );
                          setConfirmTitle(
                            invoicingActive
                              ? "Vill du stänga av aviseringen?"
                              : "Vill du aktivera aviseringen?"
                          );
                          setConfirmText(
                            invoicingActive
                              ? "Inga fler avier kommer att genereras för detta avtal om aviseringen stängs av."
                              : "Avier kommer att börja genereras för detta avtal."
                          );
                        }
                      : () => setEditOpen(true)
                  }
                  iconType={invoicingActive ? "lock" : "add"}
                />
              )}
            </DetailPageBox>

            <DetailPageBox style={{ opacity: invoicingActive ? 1 : 0.7 }}>
              <OverviewTitleWrapper style={{ marginBottom: 8 }}>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>
                    Aviseringsloggar ({invoicingRecordsCount || 0})
                  </OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {invoicingRecordsCount === 0 && (
                <InnerBox>Inga händelser för detta avtal än</InnerBox>
              )}

              {displayedInvoicingRecords?.length > 0 && (
                <>
                  {displayedInvoicingRecords.map((record) => (
                    <InvoicingRecord {...{ record }} key={record.id} />
                  ))}

                  {invoicingRecords?.length >
                    displayedInvoicingRecords?.length && (
                    <TextButton
                      extraStyle={{ marginTop: 12 }}
                      title="Visa alla aviseringsloggar"
                      clicked={() => setAllRecordsOpen(true)}
                      iconType="arrow"
                      iconPlacement="right"
                    />
                  )}
                </>
              )}
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>

        {invoicingRecords?.length > 0 && (
          <ContractInvoices
            {...{
              debtorInvoiceConfig,
              billingCompany,
              invoicingKey,
              contractId,
              invoicingRecords,
              invoicingActive,
            }}
          />
        )}
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({
  invoicingObj,
  tenant,
  billingCompany,
  setting,
  debtorInvoiceConfig,
  companyInvoiceConfig,
  autogiroActivatedForContract,
  autogiroAvailableForDebtor,
  toggleAutoGiro,
  shouldBeInvoicing,
  nextInvoiceTotal,
  nextInvoiceData,
  accountBalanceSum,
  useDebtorBalance,
  showUnpaidOnInvoice,
  adminFee,
}) {
  const invoicingActive = invoicingObj?.is_active && shouldBeInvoicing;
  const infoObj = {};

  if (invoicingObj?.current_invoice_date) {
    infoObj["Nästa autogenerering"] = [
      {
        title: "Genereras",
        value: <DateCell date={invoicingObj?.current_invoice_date} />,
      },
      {
        title: "Period",
        value: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <DateCell date={invoicingObj?.current_period_start} />
            <span>-</span>
            <DateCell date={invoicingObj?.current_period_end} />
          </div>
        ),
      },
      {
        title: "Levereras med",
        value: invoicingObj?.delivery_method_display
          ? `${invoicingObj?.delivery_method_display} `
          : debtorInvoiceConfig?.delivery_method_display
          ? `${debtorInvoiceConfig?.delivery_method_display} `
          : companyInvoiceConfig?.delivery_method_display
          ? `${companyInvoiceConfig?.delivery_method_display} `
          : "Saknas",
      },
      {
        title: "Attesteras automatiskt",
        value: (
          <StatusLabel
            state={invoicingObj?.auto_attest || setting?.auto_attest ? 0 : 3}
          >
            {invoicingObj?.auto_attest || setting?.auto_attest ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Belopp",
        value: toMoneyString(nextInvoiceTotal || 0, true),
        hidden: nextInvoiceData == null,
      },
      {
        title: "Adminavgift",
        value: toMoneyString(adminFee || 0, true),
        hidden: nextInvoiceData == null,
      },
    ];

    infoObj["Kundsaldo"] = [
      {
        title: "Utstående kundsaldo",
        value: toMoneyString(accountBalanceSum, true),
      },
      {
        title: "Nyttja kundsaldo på avier",
        value: (
          <StatusLabel state={useDebtorBalance ? 0 : 3}>
            {useDebtorBalance ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Visa tidigare obetalda avier",
        value: (
          <StatusLabel state={showUnpaidOnInvoice ? 0 : 3}>
            {showUnpaidOnInvoice ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: (
          <div style={{ fontSize: 12, fontWeight: 400 }}>
            Dessa inställningar kommer från det fakturerande bolaget
          </div>
        ),
      },
    ];
  }

  infoObj["Inställningar"] = [
    {
      title: "Status",
      value: (
        <StatusLabel state={invoicingActive ? 0 : 3}>
          {invoicingActive ? "Aktiv" : "Inaktiv"}
        </StatusLabel>
      ),
    },
    {
      title: "Fakturerande bolag",
      value: <LinkedObject obj={billingCompany} urlMethod={companyDetailUrl} />,
    },
    {
      title: "Fakturerad part",
      value: <LinkedObject obj={tenant} urlMethod={tenantDetailUrl} />,
    },
    {
      title: "Aviseringsinställning",
      value: (
        <LinkedObject obj={setting} urlMethod={invoicingSettingDetailUrl} />
      ),
    },
    {
      title: "Betalvillkorsinställning",
      value: (
        <LinkedObject
          obj={setting?.debt_setting}
          urlMethod={debtSettingDetailUrl}
        />
      ),
    },
    {
      title: "IMD-debiteringsinställning",
      value: (
        <LinkedObject
          obj={invoicingObj?.imd_setting || setting?.imd_setting}
          urlMethod={IMDSettingDetailUrl}
        />
      ),
    },
    {
      title: "Startdatum för avisering",
      value: <DateCell date={invoicingObj?.start_date} />,
    },
    {
      title: "Intervall",
      value: getIntervalStr(
        invoicingObj?.interval_setting || setting?.interval_setting
      ),
    },

    {
      title: "Period",
      value:
        invoicingObj?.interval_setting?.invoice_period_display ||
        setting?.interval_setting?.invoice_period_display,
    },

    {
      title: " Leveransmetod",
      value: invoicingObj?.delivery_method_display
        ? `${invoicingObj?.delivery_method_display} (från inställning)`
        : debtorInvoiceConfig?.delivery_method_display
        ? `${debtorInvoiceConfig?.delivery_method_display} (från hyresgäst)`
        : companyInvoiceConfig?.delivery_method_display
        ? `${companyInvoiceConfig?.delivery_method_display} (från bolag)`
        : "Saknas",
    },
    {
      title: "E-post för leverans",
      value:
        invoicingObj?.override_email_address ||
        debtorInvoiceConfig?.email_invoice ||
        "Ingen angiven",
    },
    {
      title: "Telefonnummer för leverans",
      value: debtorInvoiceConfig?.invoice_phone || "Ingen angiven",
    },
  ];

  if (!shouldBeInvoicing) {
    delete infoObj["Information"];
  }

  if (autogiroAvailableForDebtor) {
    infoObj["Autogiro"] = [
      {
        title: `Använd autogiro`,
        value: (
          <StatusLabel state={autogiroActivatedForContract ? 1 : 5}>
            {autogiroActivatedForContract ? "Aktivt" : "Inaktivt"}
          </StatusLabel>
        ),
      },
      {
        title: (
          <TextButton
            title={`${
              autogiroActivatedForContract ? "Inaktivera" : "Aktivera"
            } autogiro`}
            extraStyle={{ marginTop: 12 }}
            iconPlacement="right"
            iconType={autogiroActivatedForContract ? "pause" : "done"}
            clicked={toggleAutoGiro}
          />
        ),
      },
    ];
  }

  return infoObj;
}
