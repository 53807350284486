import { axiosInstance } from "../../base/store/axios";

async function getConfigCenter() {
    const { data } = await axiosInstance.get(
      `/general/configcenter/`
    );
    return data;
  }
  

export {
    getConfigCenter
};