const defs = [
  {
    title: "Lån",
    key: "GENERAL",
    visited: true,
    hasError: false,
    fieldKeys: [
      "start_date",
      "end_date",
      "initial_value",
      "payment_group_quota",
      "payment_group"
    ],
  },
  {
    title: "Amortering",
    key: "AMORTIZATION",
    visited: false,
    hasError: false,
    fieldKeys: [
      "yearly_amortization",
      "amortization_every_months",
    ],
  },
  {
    title: "Ränta",
    key: "INTEREST",
    visited: false,
    hasError: false,
    fieldKeys: [
      "fixed_interest",
      "use_adjusting_interest",
      "adjusting_interest_adjust_from",
      "adjusting_interest_adjust_every_months",
      "non_adjusting_interest_yearly_fee"
    ],
  },
  {
    title: "Övrigt",
    key: "OTHER",
    visited: false,
    hasError: false,
    fieldKeys: [
      "days_in_year",
      "consider_capital_contribution",
      "bank",
      "reference_number"
    ],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
