export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ERRANDROLEUSER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ERRANDROLEUSER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ERRANDROLEUSER",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ERRANDROLEUSER",
  DESTROY_FORM: "DESTROY_FORM_ERRANDROLEUSER",
  SET_FORM_ERROR: "SET_FORM_ERROR_ERRANDROLEUSER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ERRANDROLEUSER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ERRANDROLEUSER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ERRANDROLEUSER",
  RESET_STATE: "RESET_STATE_ERRANDROLEUSER",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_ERRANDROLEUSER",
  REMOVE_OBJECT: "REMOVE_OBJECT_ERRANDROLEUSER",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_ERRANDROLEUSER",
  CLEAR_FETCHED: "CLEAR_FETCHED_ERRANDROLEUSER",

  LIST_URL: "/errands/roles/roleuser/list/",
  POST_URL: "/errands/roles/roleuser/",
  PATCH_URL: "/errands/roles/roleuser/",
  GET_URL: "/errands/roles/roleuser/",

  STORE_NAME: "errandRoleUsers",
};
