import * as React from "react";
import MediaField from "src/components/Forms/Base/Fields/MediaField";
import { constants } from "../../../../../store/serviceContracts";

// style, design

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <MediaField
        title="Dokument för uppsägning"
        description="Ladda upp det eventuella dokumentet för uppsägningen"
        storeName={storeName}
        method={method}
        fieldKey={"cancelled_doc.doc"}
      />
    </>
  );
};
