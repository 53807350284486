import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Table from "../../../components/Billecta/Table/BasicTable";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  constants,
  contractProposalUrl,
  getLeadContractWarning,
  removeLeadForContract,
  setSigningType,
} from "../../../store/pipes";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as leaseDetailUrl } from "../../../store/leaseContracts";
import { detailUrl as parkingContractDetailUrl } from "../../../store/parkingContracts";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { RemoveButton } from "../../../components/Forms/RoundingErrandSetting/styles";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ESignOrManual from "../../../components/Forms/Pickers/ESignOrManual";

export default function PrepareLeadsForContract() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { goBack, push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [selectSigningTypeOpen, setSelectSigningTypeOpen] =
    React.useState(false);
  const [contractType, setContractType] = React.useState("");

  const leads = useSelector((state) => state[storeName].contractLeads);
  const leadWarnings = useSelector(
    (state) => state[storeName].contractLeadWarnings
  );

  React.useEffect(() => {
    if (leads?.length && !leadWarnings) {
      setLoading(true);
      dispatch(
        getLeadContractWarning({
          successCallback: () => setLoading(false),
          errorCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Kunde ej hämta existerande avtal",
                description:
                  "Observera att du inte kommer varnas om existerande avtal. ",
              })
            );
          },
        })
      );
    }
  }, [leads]);

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const data = React.useMemo(() => {
    const mappedLeads = (leads || [])?.map((l) => {
      const objectWarnings = leadWarnings?.[l.id]?.object_conflicts;
      const tenantWarnings = leadWarnings?.[l.id]?.tenant_conflicts;

      return { ...l, tenantWarnings, objectWarnings };
    });

    return mappedLeads;
  }, [leads, leadWarnings]);

  const removeLead = (id) => {
    dispatch(removeLeadForContract({ id }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "remove",
        Cell: ({ row }) => {
          return <RemoveButton onClick={() => removeLead(row.original.id)} />;
        },
      },
      {
        Header: "Lead",
        accessor: "str_representation",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
      },
      {
        Header: "Objekt",
        accessor: (row) =>
          row.apartment?.str_representation ||
          row.industrial_premises?.str_representation ||
          row.parking_spot?.str_representation,
        Cell: ({ row }) => {
          const obj =
            row.original.apartment ||
            row.original.industrial_premises ||
            row.original.parking_spot;

          const detailUrl = row.original.apartment
            ? apartmentDetailUrl
            : row.original.industrial_premises
            ? indpDetailUrl
            : parkingDetailUrl;

          return (
            <TextButton
              title={obj?.str_representation}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(detailUrl({ id: obj.id }))}
            />
          );
        },
      },
      {
        Header: "Existerande avtal på objekt",
        accessor: "objectWarnings",
        Cell: ({ value }) => {
          const arr = value?.apartment_leasecontracts?.length
            ? value?.apartment_leasecontracts
            : value?.industrialpremises_leasecontracts?.length
            ? value?.industrialpremises_leasecontracts
            : value?.parkingspot_parkingcontracts?.length
            ? value?.parkingspot_parkingcontracts
            : null;

          const detailUrl = value?.apartment_leasecontracts?.length
            ? leaseDetailUrl
            : value?.industrialpremises_leasecontracts?.length
            ? leaseDetailUrl
            : parkingContractDetailUrl;

          if (!arr) return <div>Inga existerande</div>;

          return arr.map((conflict) => (
            <TextButton
              extraStyle={{ marginRight: 8 }}
              title={`Existerande avtal på objekt: ${conflict.id_number},`}
              clicked={() => openInNewTab(detailUrl({ id: conflict.id }))}
            />
          ));
        },
      },
      {
        Header: "Existerande avtal på hyresgäst",
        accessor: "tenantWarnings",
        Cell: ({ value }) => {
          const arr = value?.leasecontracts?.length
            ? value?.leasecontracts
            : value?.parkingcontracts?.length
            ? value?.parkingcontracts
            : null;

          const detailUrl = value?.leasecontracts?.length
            ? leaseDetailUrl
            : parkingContractDetailUrl;

          if (!arr) return <div>Inga existerande</div>;

          return arr.map((conflict) => (
            <TextButton
              extraStyle={{ marginRight: 8 }}
              title={`Existerande avtal på hyresgäst: ${conflict.id_number},`}
              clicked={() => openInNewTab(detailUrl({ id: conflict.id }))}
            />
          ));
        },
      },
    ],
    []
  );

  const checkRowError = (row) => {
    const tenantWarnings = row.original.tenantWarnings;

    const hasTenantWarning = tenantWarnings?.leasecontracts?.length
      ? true
      : tenantWarnings?.parkingcontracts?.length
      ? true
      : false;

    const objectWarnings = row.original.objectWarnings;

    const hasObjectWarning = objectWarnings?.apartment_leasecontracts?.length
      ? true
      : objectWarnings?.industrialpremises_leasecontracts?.length
      ? true
      : objectWarnings?.parkingspot_parkingcontracts?.length
      ? true
      : false;
    return hasTenantWarning || hasObjectWarning;
  };

  const handleSelectedSigningType = (type) => {
    dispatch(setSigningType({ type }));

    if (contractType === "leasecontracts") {
      push(contractProposalUrl({ type: "leasecontracts" }));
    } else {
      push(contractProposalUrl({ type: "parkingcontracts" }));
    }
  };

  return (
    <>
      <ESignOrManual
        isOpen={selectSigningTypeOpen}
        onClose={() => setSelectSigningTypeOpen(false)}
        onSelect={handleSelectedSigningType}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            extraStyle={{ marginBottom: 8 }}
            title="Gå tillbaka till projekt"
            clicked={() => goBack()}
            iconType="arrow-back"
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Sätt upp avtal för bekräftade leads</OverviewTitle>
              <OverviewSubtitle>
                Kontrollera att avtal ska skapas för alla leads i listan. Ta
                bort de som inte ska skapas avtal för och välj sedan avtalstyp
                längst ner på sidan för att komma vidare.{" "}
                <StatusLabel state={6}>Rödmarkerade</StatusLabel> rader
                uppmärksammar existerande avtal på antingen hyresgäst eller
                objekt. Dessa rader behöver ej tas bort för att fortsätta utan
                är bara till för att varna.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {data?.length > 0 ? (
            <Table
              data={data}
              columns={columns}
              checkRowError={checkRowError}
            />
          ) : (
            <InnerBox>
              Inga leads specificerade.
              <br />
              <br />
              <TextButton
                title="Gå tillbaka till projektet för att komma
              igång med avtalsskapandet"
                clicked={() => goBack()}
                iconType="arrow-back"
              />
            </InnerBox>
          )}

          {data?.length > 0 ? (
            <div
              style={{
                display: "flex",
                marginTop: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                title="Skapa hyresavtal"
                clicked={() => {
                  setContractType("leasecontracts");
                  setSelectSigningTypeOpen(true);
                }}
              />{" "}
              <div style={{ margin: "0 12px" }}>eller</div>{" "}
              <PrimaryButton
                title="Skapa parkeringsavtal"
                clicked={() => {
                  setContractType("parkingcontracts");
                  setSelectSigningTypeOpen(true);
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
