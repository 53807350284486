import * as React from "react";

import RoundingAreasTable from "src/components/Tables/RoundingAreas/FullTable";
import Alert from "src/components/Alert/Alert";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import ExecuteRoundingObjectRenderer from "./ObjectRenderer";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import RoundingFaultTable from "src/components/Tables/RoundingFaults/FullTable";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "src/components/Details/OverviewInfo/styles";
import ReportErrandForm from "src/components/Forms/ReportErrand/ChapterForm/ModalForm";

export default ({ areaIds, errandId }) => {
  const [selectedArea, setSelectedArea] = React.useState(null);
  const [selectedRealEstate, setSelectedRealEstate] = React.useState(null);
  const [addFaultActive, setAddFaultActive] = React.useState(false);
  const [createReportErrandOpen, setCreateReportErrandOpen] =
    React.useState(false);
  const [createReportErrandFault, setCreateReportErrandFault] =
    React.useState(null);

  const realEstatesCount = selectedArea?.realestates?.length;

  const resetFlow = () => {
    setSelectedArea(null);
    setSelectedRealEstate(null);
    setAddFaultActive(false);
  };

  const onErrandFaultsQ = {
    roundingerrands: errandId,
  };

  if (!addFaultActive) {
    return (
      <>
        {createReportErrandOpen && (
          <ReportErrandForm
            preventGoToDetail
            method="POST"
            isOpen={true}
            onCheckout={() => setCreateReportErrandOpen(false)}
            instance={{
              title: `Ronderingsavvikelse ${createReportErrandFault?.title}`,
              rounding_fault: {
                ...createReportErrandFault,
                str_representation: createReportErrandFault?.title,
              },
              report_comments: [
                {
                  content: createReportErrandFault?.description,
                },
              ],
              component_placement: {
                ...createReportErrandFault?.component_placement,
                _dontClear: true,
              },
            }}
          />
        )}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Registerade avvikelser</OverviewTitle>
            <OverviewSubtitle>
              Klicka på "Lägg till avvikelse" för att lägga till en ny
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <PrimaryBtn onClick={() => setAddFaultActive(true)}>
            Lägg till avvikelse
          </PrimaryBtn>
        </OverviewTitleWrapper>
        {!createReportErrandOpen && (
          <RoundingFaultTable
            isBare
            persistantQuery={onErrandFaultsQ}
            onRowClicked={() => {}}
            createReportErrandCallback={(obj) => {
              setCreateReportErrandFault(obj);
              setCreateReportErrandOpen(true);
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div className="mb-2">
        <PrimaryBtn secondaryError onClick={resetFlow}>
          <ChevronDoubleLeftIcon width={16} className="mr-1" /> Avbryt och gå
          tillbaka
        </PrimaryBtn>
      </div>
      <Alert title="Avvikelseregistrering">
        <p className="mb-2">
          1. Tryck på ett område för att hantera det specifika området. Under
          området listas fastigheter som ingår i området.
        </p>
        <p className="mb-2">
          2. Välj fastighet för att se en lista över alla objekt som innehåller
          minst 1 av de komponenttyper som ingår i ronderingen.
        </p>
        <p className="mb-2">
          3. Välj objekt för att lista de relevanta komponenterna och lägg sedan
          till en avvikelse på de komponenter som avvikelse har uppstått för.
        </p>
        <p className="mb-2">
          4. När en avvikelse är registrerad kan en felanmälan skapas kopplat
          till avvikelsen.
        </p>
      </Alert>

      {!selectedArea && (
        <RoundingAreasTable
          persistantQuery={{
            id__in: areaIds || [],
            component_types__isnull: false,
          }}
          onRowClicked={(obj) => setSelectedArea(obj)}
        />
      )}

      {selectedArea && !selectedRealEstate && (
        <>
          {areaIds?.length > 1 && (
            <div className="mb-6">
              <PrimaryBtn
                secondaryWarning
                onClick={() => setSelectedArea(null)}
              >
                <ChevronLeftIcon width={16} className="mr-1" /> TIllbaka till
                områden
              </PrimaryBtn>
            </div>
          )}
          <RealEstateTable
            persistantQuery={{
              id__in: selectedArea?.realestates?.map((r) => r.id) || [],
            }}
            onRowClicked={(obj) => setSelectedRealEstate(obj)}
          />
        </>
      )}

      {selectedArea && selectedRealEstate && (
        <>
          <ExecuteRoundingObjectRenderer
            area={selectedArea}
            realestate={selectedRealEstate}
            setSelectedRealEstate={setSelectedRealEstate}
            canGoBack={realEstatesCount}
            goBackToStart={resetFlow}
          />
        </>
      )}
    </>
  );
};
