import styled from "styled-components";

export const WorkerList = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  font-family: "Inter", sans-serif;
  color: ${(p) => p.theme.colors.primaryText};
`;

export const Worker = styled.div`
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  border-bottom: ${(p) => p.theme.borders.standard};
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  margin: 0 8px 8px 0;
  overflow: hidden;
`;

export const Image = styled.div`
  cursor: pointer;
  width: 200px;
  min-height: 125px;
  background-image: url(${(p) => p.src});
  background-repeat: no-repeat;
  background-image: contain;
  background-size: 100%;
`;

export const ImageDescription = styled.div`
  color: ${(p) => p.theme.colors.primaryText};
  width: 100%;
  padding: 8px;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  max-height: 60px;
  overflow-y: auto;
`;

export const ChecklistSummaryContainer = styled.div`
  border: thin solid #ddd;
  background-color: #fff;
  box-shadow: ${(p) => p.theme.colors.highlight};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
`;

export const ChecklistSummaryRow = styled.div`
  cursor: pointer;
  padding: 6px 12px 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChecklistDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChecklistTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;
