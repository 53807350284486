export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_NOTIF",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_NOTIF",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_NOTIF",
  DESTROY_FORM: "DESTROY_FORM_NOTIF",
  SET_FORM_ERROR: "SET_FORM_ERROR_NOTIF",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_NOTIF",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_NOTIF",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_NOTIF",
  RESET_STATE: "RESET_STATE_NOTIF",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_NOTIF",
  CLEAR_FETCHED: "CLEAR_FETCHED_NOTIF_SETTING",
  SETTING_STATE_ID: "AUTHED_NOTIF_SETTING",

  PATCH_URL: "/accounts/notifications/notification_setting/",
  GET_URL: "/accounts/notifications/notification_setting/",

  STORE_NAME: "notifications",
};
