import * as React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useTenant } from "../../../store/tenants";

import BrfPremisesTable from "../../../components/Tables/BrfPremises/FullTable";
import TransactionsTable from "../../../components/Tables/BrfContracts/FullTable";

import { detailUrl } from "../../../store/brfPremises";

export default function MemberPremises() {
  const { tenantId } = useParams();
  const { push } = useHistory();
  const [member] = useTenant(tenantId);

  const brfPremisesQuery = {
    tenant: tenantId,
  };

  const transactionQuery = {
    tenant_sellers: tenantId,
  };

  const onTransactionClicked = (row) => {
    if (row.original.brf_premis?.id) {
      push(detailUrl({ id: row.original.brf_premis.id }));
    }
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Bostadsrättsinnehav</OverviewTitle>
        </OverviewTitleWrapper>

        <BrfPremisesTable
          persistantQuery={brfPremisesQuery}
          isBare
          ignoreLocalStorage
        />
      </DetailPageBox>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Sålda bostadsrätter</OverviewTitle>
        </OverviewTitleWrapper>
        <TransactionsTable
          persistantQuery={transactionQuery}
          onRowClicked={onTransactionClicked}
          isBare
          ignoreLocalStorage
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
