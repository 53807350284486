import { buildQueryString, checkTrailingUrlSlash } from "../../base";
import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

export async function performUpdate({ body }) {
  const res = await axiosInstance.put(
    checkTrailingUrlSlash(constants.PUT_URL),
    body
  );
  return res;
}

export async function getCurrentRows({ groupId, contentType }) {
  const query = buildQueryString({
    group_id: groupId,
    content_type: contentType,
  });
  const url = `${constants.LIST_URL}?${query}`;
  const res = await axiosInstance.get(url);
  return res;
}
