export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRF_BRF_PLEDGES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRF_BRF_PLEDGES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRF_BRF_PLEDGES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRF_BRF_PLEDGES",
  DESTROY_FORM: "DESTROY_FORM_BRF_BRF_PLEDGES",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRF_BRF_PLEDGES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRF_BRF_PLEDGES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRF_BRF_PLEDGES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRF_BRF_PLEDGES",
  RESET_STATE: "RESET_STATE_BRF_BRF_PLEDGES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRF_BRF_PLEDGES",
  REMOVE_OBJECT: "REMOVE_OBJECT_BRF_BRF_PLEDGES",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRF_BRF_PLEDGES",

  LIST_URL: "/brf/brfpledge/list/",
  POST_URL: "/brf/brfpledge/",
  PATCH_URL: "/brf/brfpledge/",
  GET_URL: "/brf/brfpledge/",

  STORE_NAME: "brfPledges",
};
