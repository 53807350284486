import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "../../../store/base";
import { creditUrl } from "../../../store/billectaInvoicing";
import LocalCheckField from "../Base/Fields/LocalCheckField";
import LocalDateSelect from "../Base/Fields/LocalDateSelect";
import Modal from "../Base/Modals/Modal";

export default function CreditInvoiceForm({
  creditorId,
  closeFunction,
  originalId,
  originalIsClosed,
}) {
  const { push } = useHistory();
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [connect, setConnect] = React.useState(!originalIsClosed);

  const goToCreditForm = () => {
    push(
      creditUrl({
        creditorId,
        invoiceId: originalId,
        query: buildQueryString({
          newInvoiceDate: date,
          connectToOriginal: connect,
        }),
      })
    );
  };
  return (
    <Modal
      closeFunction={closeFunction}
      title="Kreditera faktura"
      onAccept={goToCreditForm}
      acceptTitle="Fortsätt"
      denyTitle="Avbryt"
    >
      {!originalIsClosed && (
        <LocalCheckField
          id="_connectInvoice"
          value={connect}
          onChange={(val) => setConnect(val)}
          title="Koppla kredit till debetfaktura"
          description="Om fakturorna är sammankopplade kommer originalfakturan automatiskt att minska utestående belopp på aktuell debetfaktura"
        />
      )}

      <LocalDateSelect
        title="Fakturadatum för den nya fakturan"
        description="Välj vilket fakturadatum den nya fakturan ska ha. Förfallodatumet räknas ut utifrån intervallet på originalfakturan."
        id="_invoiceDate"
        value={date}
        onChange={(val) => setDate(val)}
      />
    </Modal>
  );
}
