import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import TagsModalForm from "../../../../components/Forms/ConfigCenter/Categories/Tags/ChapterForm/ModalForm";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../../../components/sharedStyles";
import { useTag } from "../../../../store/tags";

import PageTitle from "../../../Layouts/PageTitle/PageTitle";
import PageSlider from "../../../Layouts/PageSlider/PageSlider";

import { TextButton } from "../../../../components/Forms/Base/Buttons";

import ReportErrandTable from "../../../../components/Tables/ReportErrands/FullTableNewVersion";
import RoundingErrandTable from "../../../../components/Tables/RoundingErrands/FullTable";
import InspectionErrandTable from "../../../../components/Tables/InspectionErrands/FullTable";

import { buildQueryString } from "../../../../store/base";

const TABS = {
  REPORT_ERRANDS: "Felanmälningar",
  INSPECTION_ERRANDS: "Besiktningar",
  ROUNDING_ERRANDS: "Ronderingar",
};

export default function TagsDetail() {
  const [editOpen, setEditOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("REPORT_ERRANDS");
  const { tagsId } = useParams();
  const [tags] = useTag(tagsId);
  const breadCrumbs = [
    {
      label: "Taggar",
      url: "/configcenter/categories/tags",
    },
    {
      label: tags?.title || "Laddar...",
    },
  ];

  const persistantQuery = { tags: tagsId };

  return (
    <>
      <TagsModalForm
        method="PATCH"
        id={tagsId}
        instance={tags}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <BareLayoutWrapper>
        <BareLayoutTitleWrapper>
          <PageTitle
            title={tags?.title || "Laddar..."}
            breadCrumbs={breadCrumbs}
          />
        </BareLayoutTitleWrapper>

        <DetailPageBox config={true}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Ärenden</OverviewTitle>
              <OverviewSubtitle>
                Dessa ärenden använder sig av denna tagg
              </OverviewSubtitle>
              <OverviewSubtitle>
                <TextButton
                  title="Redigera tagg"
                  clicked={() => setEditOpen(true)}
                  iconType="edit"
                  iconPlacement="right"
                />
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            <PageSlider
              {...{
                TABS,
                selectedTab,
                onTabSelected: (tab) => setSelectedTab(tab),
              }}
            />
          </OverviewTitleWrapper>

          {selectedTab === "REPORT_ERRANDS" && (
            <ReportErrandTable persistantQuery={persistantQuery} />
          )}
          {selectedTab === "ROUNDING_ERRANDS" && (
            <RoundingErrandTable persistantQuery={persistantQuery} />
          )}
          {selectedTab === "INSPECTION_ERRANDS" && (
            <InspectionErrandTable persistantQuery={persistantQuery} />
          )}
        </DetailPageBox>
      </BareLayoutWrapper>
    </>
  );
}
