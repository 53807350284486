import * as React from "react";
import { NumberInput, TextInput } from "../Base/Fields";
import ComponentTypeNestedChildren from "../ComponentType/NestedChildren";

import TableSelectFieldWithCreate from "../Base/Fields/TableSelectFieldWithCreate";
import ComponentTypesTable from "src/components/Tables/ErrandComponentTypes/FullTable";
import TextInputField from "../Base/Fields/TextInputField";

export default function ProductTypeNestedChildren({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          TableComponent={ComponentTypesTable}
          placeholder={"Välj komponenttyp..."}
          title="Komponenttyp för modell"
          method={method}
          fieldKey={`${parentPath}.component_type`}
          instructionsKey={`${parentInstructionsPath}.component_type`}
          createDisplayKey="name"
        >
          {(parentPath) => (
            <ComponentTypeNestedChildren
              {...{
                storeName,
                method,
                parentPath,
                parentInstructionsPath: `${parentInstructionsPath}.component_type`,
              }}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.name`,
            instructionsKey: `${parentInstructionsPath}.name`,
            title: "Namn på komponentmodell",
          }}
        />

        <TextInputField
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.code`,
            instructionsKey: `${parentInstructionsPath}.code`,
            title: "Artikelnr.",
          }}
        />
        <TextInputField
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.url_reference`,
            instructionsKey: `${parentInstructionsPath}.url_reference`,
            title: "URL-länk till produkt",
          }}
        />

        <TextInputField
          isNumber
          {...{
            storeName,
            method,
            fieldKey: `${parentPath}.warranty_months`,
            instructionsKey: `${parentInstructionsPath}.warranty_months`,
            title: "Antal månaders garanti",
          }}
        />
      </div>
    </>
  );
}
