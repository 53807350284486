import * as React from "react";
import { toMoneyString } from "../../../../utils/stringUtils";

// style, design
import * as SC from "../../Fields/styles";
import DescriptionToolTip from "../../Layout/DescriptionToolTip";

// store, state

const MODES = {
  YEARLY: 1,
  SQM_YEARLY: 2,
  MONTHLY: 3,
  SQM_MONTHLY: 4,
};

const CALCULATION_METHODS = {
  1: (val) => val / 12, //YEARLY:1,
  2: (val, area) => (val * area) / 12, //SQM_YEARLY:2,
  3: (val) => val, //MONTHLY:3,
  4: (val, area) => val * area, //SQM_MONTHLY:4
};
const REVERSE_CALCULATION_METHODS = {
  1: (val) => val * 12, //YEARLY:1,
  2: (val, area) => (val / area) * 12, //SQM_YEARLY:2,
  3: (val) => val, //MONTHLY:3,
  4: (val, area) => val / area, //SQM_MONTHLY:4
};

export default React.memo(
  ({
    id,
    value,
    onUpdate,
    required,
    extraStyles = {},
    noTitle,
    noMargin,
    rowSize,
    disabled,
    title,
    subtitle,
    description,
    changeCallback,
    placeholder,
    hideCalculations,
    area = null,
  }) => {
    const [mode, setMode] = React.useState(MODES.MONTHLY);
    const [internalValue, setInternaValue] = React.useState(null);

    React.useEffect(() => {
      const formattedData = parseFloat(value);
      const calculationMethod = REVERSE_CALCULATION_METHODS[mode];
      let calculatedValue =
        formattedData != null ? calculationMethod(formattedData, area) : null;

      if (calculatedValue !== internalValue) {
        setInternaValue(value);
      }
    }, [value]);

    const onChange = (data, viewMode) => {
      let formattedData = parseFloat(data);

      if (data === undefined || data === null || isNaN(formattedData)) {
        formattedData = 0;
      }

      setInternaValue(formattedData);

      const calculationMethod = CALCULATION_METHODS[viewMode];
      let calculatedValue =
        formattedData != null ? calculationMethod(formattedData, area) : null;

      onUpdate(calculatedValue);

      if (changeCallback) {
        changeCallback(data);
      }
    };

    const handleSetMode = (mode) => {
      setMode(mode);
    };

    const handleYearlyToggled = () => {
      switch (mode) {
        case MODES.YEARLY:
          handleSetMode(MODES.MONTHLY);
          onChange(internalValue, MODES.MONTHLY);
          break;
        case MODES.SQM_YEARLY:
          handleSetMode(MODES.SQM_MONTHLY);
          onChange(internalValue, MODES.SQM_MONTHLY);

          break;
        case MODES.MONTHLY:
          handleSetMode(MODES.YEARLY);
          onChange(internalValue, MODES.YEARLY);

          break;
        case MODES.SQM_MONTHLY:
          handleSetMode(MODES.SQM_YEARLY);
          onChange(internalValue, MODES.SQM_YEARLY);

          break;

        default:
          return;
      }
    };

    const handleSqmToggled = () => {
      switch (mode) {
        case MODES.YEARLY:
          handleSetMode(MODES.SQM_YEARLY);
          onChange(internalValue, MODES.SQM_YEARLY);
          break;
        case MODES.SQM_YEARLY:
          handleSetMode(MODES.YEARLY);
          onChange(internalValue, MODES.YEARLY);

          break;
        case MODES.MONTHLY:
          handleSetMode(MODES.SQM_MONTHLY);
          onChange(internalValue, MODES.SQM_MONTHLY);

          break;
        case MODES.SQM_MONTHLY:
          handleSetMode(MODES.MONTHLY);
          onChange(internalValue, MODES.MONTHLY);

          break;

        default:
          return;
      }
    };

    return (
      <SC.InputSpacing {...{ noMargin }} style={{ ...extraStyles }}>
        {!noTitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.InputFieldTitle>
              {title}
              {required ? "*" : ""}
            </SC.InputFieldTitle>
            {description && <DescriptionToolTip description={description} />}
          </div>
        )}
        {subtitle && (
          <SC.InputFieldDescription>{subtitle}</SC.InputFieldDescription>
        )}

        <SC.InputFieldWrapper
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <DescriptionToolTip
            description={
              "Välj hur värdet ska skrivas in. Om det finns en area att utgå ifrån kan värdet skrivas in per m2."
            }
          />
          <SC.InputField
            value={internalValue || null}
            onChange={({ target: { value } }) => onChange(value, mode)}
            id={id}
            required={required}
            placeholder={placeholder || ""}
            type="number"
            disabled={disabled}
            {...{ rowSize }}
          />

          <SC.RentFieldToggleBox
            disabled={area == null}
            onClick={area != null ? handleSqmToggled : () => {}}
            active={[MODES.SQM_YEARLY, MODES.SQM_MONTHLY].includes(mode)}
          >
            /m2
          </SC.RentFieldToggleBox>
          <SC.RentFieldToggleBox
            style={{
              display: "flex",
              alignItems: "center",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              minWidth: [MODES.SQM_YEARLY, MODES.YEARLY].includes(mode)
                ? 60
                : 90,
            }}
            onClick={handleYearlyToggled}
          >
            {[MODES.SQM_YEARLY, MODES.YEARLY].includes(mode) ? "/år" : "/månad"}
            <SC.RentFieldToggleIcon />
          </SC.RentFieldToggleBox>
        </SC.InputFieldWrapper>

        {!hideCalculations && (
          <>
            <SC.InputFieldDescription
              style={{
                marginTop: 1,
                marginBottom: 0,
              }}
            >
              Totalt per år: {toMoneyString((value || 0) * 12, true)}{" "}
              {area &&
                `(${toMoneyString(((value || 0) * 12) / area, true)}/m²)`}
            </SC.InputFieldDescription>

            <SC.InputFieldDescription
              style={{
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              Totalt per månad: {toMoneyString(value, true)}{" "}
              {area && `(${toMoneyString(value / area, true)}/m²)`}
            </SC.InputFieldDescription>

            {area && [MODES.SQM_YEARLY, MODES.SQM_MONTHLY].includes(mode) && (
              <SC.InputFieldDescription
                style={{
                  marginTop: 0,
                }}
              >
                Räknar på area: {area}m²
              </SC.InputFieldDescription>
            )}
          </>
        )}
      </SC.InputSpacing>
    );
  }
);
