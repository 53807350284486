import * as React from "react";

export default () => {
  return (
    <div>
      Grundinställningar för publicering av annonser i detta projekt.
      Inställningarna kan ändras per annons i nästa steg.
    </div>
  );
};
