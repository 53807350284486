import * as React from "react";
import { useDispatch } from "react-redux";
import illustrationApp from "../../../assets/illustrations/illustration_app.svg";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { inviteUsers, update } from "../../../store/users";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../Loaders/OverlaySpinner";

const STATES = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  INACTIVE: "INACTIVE",
  REACTIVATE: "REACTIVATE",
};

const STATUS = {
  ACTIVE: 0,
  PENDING: 4,
  REACTIVATE: 6,
  INACTIVE: 6,
};

const STATUS_DISPLAY = {
  ACTIVE: "Aktiv",
  PENDING: "Inbjuden",
  REACTIVATE: "Inaktiverad",
  INACTIVE: "Inaktiv",
};

const DESCRIPTION = {
  ACTIVE: 'Denna användare är aktiv i "Mina sidor".',

  PENDING:
    'Denna användare har fått en inbjudan till "Mina sidor" som ännu inte är accepterad.',
  REACTIVATE: "Denna användare har blivit inaktiverad.",
  INACTIVE: 'Denna användare är ej aktiv i "Mina sidor".',
};

const ACTION = {
  INACTIVE: "Bjud in",
  PENDING: "Skicka ny inbjudan",
  REACTIVATE: "Återaktivera",
  ACTIVE: "Inaktivera",
};

export default function TenantPortalStatusCard({ user }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const state = user.is_active
    ? STATES.ACTIVE
    : user.invite_pending
    ? STATES.PENDING
    : user.invite_accepted
    ? STATES.REACTIVATE
    : STATES.INACTIVE;

  const handleAction = () => {
    setLoading(true);

    if (user.is_active) {
      dispatch(
        update({
          id: user.id,
          forceData: {
            is_active: false,
          },
          successCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Åtkomst inaktiverades",
              })
            );
          },
          errorCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Något gick fel",
              })
            );
          },
        })
      );
    } else if (user.invite_accepted) {
      dispatch(
        update({
          id: user.id,
          forceData: {
            is_active: true,
          },
          successCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Åtkomst aktiverades",
              })
            );
          },
          errorCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Något gick fel",
              })
            );
          },
        })
      );
    } else {
      dispatch(
        inviteUsers({
          userIds: [user.id],
          successCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Användaren bjöds in",
              })
            );
          },
          errorCallback: () => {
            setLoading(false);
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Något gick fel",
              })
            );
          },
        })
      );
    }
  };

  return (
    <div className="my-4 relative flex p-4 flex-col border rounded border-slate-100 shadow shadow-slate-50 bg-white">
      {loading && <OverlaySpinner />}
      <div className="text-lg font-medium text-slate-700 flex items-center justify-between">
        Mina sidor
        <StatusLabel state={STATUS[state]}>{STATUS_DISPLAY[state]}</StatusLabel>
      </div>

      <div className="text-sm text-slate-600 mt-2">{DESCRIPTION[state]}</div>
      {(state === STATES.ACTIVE || state === STATES.REACTIVATE) && (
        <div className="text-sm text-slate-600 mt-2">
          Användarnamn:{" "}
          <span className="font-medium">{user.actual_username}</span>
        </div>
      )}

      <div className="flex flex-col pr-20">
        <div className="flex items-center mt-8">
          <PrimaryBtn error={state === STATES.ACTIVE} onClick={handleAction}>
            {ACTION[state]}
          </PrimaryBtn>
        </div>

        {(state === STATES.PENDING || state === STATES.INACTIVE) && (
          <div className="text-[12px] text-slate-600 mt-2">
            Inbjudan skickas till{" "}
            <span className="font-medium">{user.email}</span>
          </div>
        )}

        {state === STATES.REACTIVATE && (
          <div className="text-[12px] text-slate-600 mt-2">
            Användaren kommer kunna logga in igen
          </div>
        )}
      </div>

      <div
        className={`absolute right-[2px] bottom-[0px] min-h-[75px] min-w-[100px] bg-no-repeat bg-contain bg-center`}
        style={{ backgroundImage: `url(${illustrationApp})` }}
      ></div>
    </div>
  );
}
