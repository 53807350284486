import * as React from "react";
import { useParams } from "react-router";

import SettingOverview from "../../components/Details/TenantPortalSettings/SettingOverview";
import TermsOverview from "../../components/Details/TenantPortalSettings/TermsOverview";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import * as SharedStyles from "../../components/sharedStyles";
import { useFilteredRealEstates } from "../../store/realEstates";
import { useTenantPortalSetting } from "../../store/tenantPortalSettings";
import { useFilteredPortalSettingTerms } from "../../store/tenantPortalSettingTerms";

import { buildQueryString } from "../../store/base";

export default function EditTenantPortalSettings() {
  const { id } = useParams();
  const [portalSetting, portalSettingsLoading] = useTenantPortalSetting(id);

  const termIds = portalSetting?.terms?.map((t) => t.id);
  const termsQuery = buildQueryString({ id__in: termIds });
  const [portalSettingTerms, portalSettingTermsLoading] =
    useFilteredPortalSettingTerms(termsQuery);

  const [realestates, realestatesLoading] = useFilteredRealEstates();

  return (
    <>
      <SharedStyles.DetailInnerWrapper>
        {!portalSetting && <OverlaySpinner />}

        <SharedStyles.DetailPageBox>
          <SettingOverview
            currentSetting={portalSetting}
            realestates={realestates}
          />
        </SharedStyles.DetailPageBox>
        <SharedStyles.DetailPageBox>
          <TermsOverview terms={portalSettingTerms} />
        </SharedStyles.DetailPageBox>
      </SharedStyles.DetailInnerWrapper>
    </>
  );
}
