import * as React from "react";
import { useHistory } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import BillectaTable from "../../../components/Tables/Companies/BillectaTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useFilteredBillectaEvents } from "../../../store/billectaEvents";
import { overviewUrl } from "../../../store/billectaInvoicing";
import { NotificationDot } from "../../../components/Dashboard/styles";
import {
  REPORT_EVENTS_CATEGORIES,
  useImportantMessages,
} from "../../../store/billectaEvents/hooks/retrieve";
import { useInvoicingRecordPaginationCount } from "../../../store/invoicingRecords";
import theme from "../../../theme";
import { useVacancyInvoicingRecordPaginationCount } from "../../../store/invoicingVacancyRecords";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import { useFilteredCompanies } from "src/store/companies";

function InvoicingOverview() {
  const { push } = useHistory();
  const [showNonInvoicing, setShowNonInvoicing] = React.useState(false);

  const billectaEventsQuery = buildQueryString({
    read: false,
    category__in: [
      REPORT_EVENTS_CATEGORIES.INVOICE_UNDELIVERED,
      REPORT_EVENTS_CATEGORIES.INVOICE_UNMATCHED_PAYMENT,
      REPORT_EVENTS_CATEGORIES.INVOICE_OVERPAYED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_APPROVAL_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_CHANGED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL_RETRY,
    ],
  });
  const [billectaEvents] = useFilteredBillectaEvents(billectaEventsQuery);

  const goToCreditor = (original) => {
    push(overviewUrl({ creditorId: original.billecta_id }));
  };

  const q = buildQueryString({
    "billecta_id__isnull!": true,
  });
  const [companies] = useFilteredCompanies(q);

  const [otherInvoicingErrors] = useInvoicingErrorPaginationCount({
    filters: {},
  });

  const withInvoicingQuery = {
    billecta_id__isnull: false,
  };
  const withoutInvoicingQuery = {
    billecta_id__isnull: true,
  };

  const [failedInvoicingsCount] = useInvoicingRecordPaginationCount({
    filters: {
      billecta_id__isnull: true,
      skipped_generating: false,
    },
  });

  const [failedVacancyBookingsCount] = useVacancyInvoicingRecordPaginationCount(
    {
      filters: {
        billecta_id__isnull: true,
        skipped_generating: false,
      },
    }
  );

  const [importantMessages] = useImportantMessages();

  const filteredImportantMessages = importantMessages?.filter((m) =>
    companies?.find((c) => c.billecta_id === m.CreditorPublicId)
  );

  return (
    <DetailInnerWrapper>
      <DetailPageBoxFlexWrapper>
        <DetailPageBox
          style={{ alignSelf: "flex-start", flex: 2, maxWidth: "66%" }}
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Fakturerande bolag</OverviewTitle>
              <OverviewSubtitle>
                Gå in på ett bolag för att se fakturor och hantera
                bokföringsinställningar
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <BillectaTable
            {...{
              onRowClicked: goToCreditor,
              persistantQuery: withInvoicingQuery,
              billectaEvents,
            }}
          />

          {showNonInvoicing && (
            <BillectaTable
              {...{
                persistantQuery: withoutInvoicingQuery,
              }}
            />
          )}
          {!showNonInvoicing && (
            <TextButton
              extraStyle={{ marginTop: 24 }}
              title="Visa bolag som ej är uppsatta för fakturering"
              clicked={() => setShowNonInvoicing(true)}
              iconType="arrow"
              iconPlacement="right"
            />
          )}
        </DetailPageBox>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignSelf: "flex-start",
            maxWidth: "33%",
          }}
        >
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle
                  small
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Misslyckade aviseringar{" "}
                  <NotificationDot
                    style={
                      failedInvoicingsCount > 0
                        ? {
                            backgroundColor: theme.colors.red,
                            borderColor: theme.colors.red,
                          }
                        : {
                            backgroundColor: theme.colors.green,
                            borderColor: theme.colors.green,
                          }
                    }
                  >
                    {failedInvoicingsCount || 0}
                  </NotificationDot>
                </OverviewTitle>
                {failedInvoicingsCount === 0 ? (
                  <OverviewSubtitle>
                    Inga misslyckade aviseringar finns att hantera
                  </OverviewSubtitle>
                ) : (
                  <OverviewSubtitle>
                    Klicka på en "Visa misslyckade aviseringar" för att komma
                    till översikten
                  </OverviewSubtitle>
                )}
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {failedInvoicingsCount > 0 && (
              <TextButton
                title="Visa misslyckade aviseringar"
                iconType="arrow"
                iconPlacement="right"
                clicked={() => push("/rental-notifications/failed-generations")}
              />
            )}
          </DetailPageBox>

          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle
                  small
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Misslyckade vakansbokningar{" "}
                  <NotificationDot
                    style={
                      failedVacancyBookingsCount > 0
                        ? {
                            backgroundColor: theme.colors.red,
                            borderColor: theme.colors.red,
                          }
                        : {
                            backgroundColor: theme.colors.green,
                            borderColor: theme.colors.green,
                          }
                    }
                  >
                    {failedVacancyBookingsCount || 0}
                  </NotificationDot>
                </OverviewTitle>
                {failedVacancyBookingsCount === 0 ? (
                  <OverviewSubtitle>
                    Inga misslyckade bokningar finns att hantera
                  </OverviewSubtitle>
                ) : (
                  <OverviewSubtitle>
                    Klicka på en "Visa misslyckade vakansbokningar" för att
                    komma till översikten
                  </OverviewSubtitle>
                )}
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {failedVacancyBookingsCount > 0 && (
              <TextButton
                title="Visa misslyckade vakansbokningar"
                iconType="arrow"
                iconPlacement="right"
                clicked={() =>
                  push("/rental-notifications/failed-generations?tab=VACANCIES")
                }
              />
            )}
          </DetailPageBox>

          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle
                  small
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Viktiga meddelanden
                  <NotificationDot
                    style={
                      (filteredImportantMessages?.length || 0) > 0
                        ? {
                            backgroundColor: theme.colors.red,
                            borderColor: theme.colors.red,
                          }
                        : {
                            backgroundColor: theme.colors.green,
                            borderColor: theme.colors.green,
                          }
                    }
                  >
                    {filteredImportantMessages?.length || 0}
                  </NotificationDot>
                </OverviewTitle>
                {(filteredImportantMessages?.length || 0) === 0 ? (
                  <OverviewSubtitle>Inga viktiga meddelanden</OverviewSubtitle>
                ) : (
                  <OverviewSubtitle>
                    Viktiga meddelanden avseende t.ex. autogiromedgivanden,
                    bolagsinställningar osv.
                  </OverviewSubtitle>
                )}
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {(filteredImportantMessages?.length || 0) > 0 && (
              <TextButton
                title="Visa viktiga meddelanden"
                iconType="arrow"
                iconPlacement="right"
                clicked={() => push("/rental-notifications/notifications")}
              />
            )}
          </DetailPageBox>
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle
                  small
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Övriga aviseringsfel{" "}
                  <NotificationDot
                    style={
                      otherInvoicingErrors > 0
                        ? {
                            backgroundColor: theme.colors.red,
                            borderColor: theme.colors.red,
                          }
                        : {
                            backgroundColor: theme.colors.green,
                            borderColor: theme.colors.green,
                          }
                    }
                  >
                    {otherInvoicingErrors || 0}
                  </NotificationDot>
                </OverviewTitle>
                {otherInvoicingErrors === 0 ? (
                  <OverviewSubtitle>
                    Inga övriga fel finns att hantera
                  </OverviewSubtitle>
                ) : (
                  <OverviewSubtitle>
                    Dessa fel kan leda till att framtida aviseringar misslyckas.
                    Åtgärda dem i tid för att undvika misslyckade
                    autogenereringar av avier.
                  </OverviewSubtitle>
                )}
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {otherInvoicingErrors > 0 && (
              <TextButton
                title="Visa felmeddelanden"
                iconType="arrow"
                iconPlacement="right"
                clicked={() => push("/rental-notifications/invoicing-errors")}
              />
            )}
          </DetailPageBox>
        </div>
      </DetailPageBoxFlexWrapper>
    </DetailInnerWrapper>
  );
}

export default InvoicingOverview;
