import { cloneDeep } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  clearFetched,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import { createUploadHandler } from "./uploadHandler";
import { store } from "../../store";
import constants from "./constants";
import { constants as roomConstants } from "../../rooms";

import * as services from "./services";
import { BLUEPRINT_PREMISE_OPTIONS } from "../../../components/Blueprints/BlueprintDisplay/Controller";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};
export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (querystring, callback, taskToken) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const preProcess = (data) => {
  const dataClone = cloneDeep(data);
  if (dataClone.svg_file?._tempData) {
    dataClone.svg_file = dataClone.svg_file?._tempData?.file_name;
  } else {
    delete dataClone.svg_file;
  }
  return dataClone;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const uploadCallback = async (responseData, dispatch) => {
    const state = store.getState();
    const formInstance = state[constants.STORE_NAME].formInstance;

    await createUploadHandler({ formInstance, responseData, dispatch });

    return;
  };

  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    preProcess,
    uploadCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const url = `${constants.PATCH_URL}${id}`;

  const uploadCallback = async (responseData, dispatch) => {
    const state = store.getState();
    const formInstance = state[constants.STORE_NAME].formInstance;

    await createUploadHandler({ formInstance, responseData, dispatch });

    return;
  };

  return patch({
    url,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    preProcess,
    uploadCallback,
  });
};

export const connectRoomsToPremises = ({
  rooms,
  clearRooms,
  premisesId,
  premisesType,
  successCallback,
}) => {
  return async (dispatch) => {
    try {
      let calls = rooms.map((r) => {
        let data;

        if (premisesType === BLUEPRINT_PREMISE_OPTIONS.APARTMENT) {
          data = {
            apartment: { id: premisesId },
            industrial_premises: null,
            common_area: null,
          };
        } else if (
          premisesType === BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES
        ) {
          data = {
            apartment: null,
            industrial_premises: { id: premisesId },
            common_area: null,
          };
        } else if (premisesType === BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA) {
          data = {
            apartment: null,
            industrial_premises: null,
            common_area: { id: premisesId },
          };
        }
        return services.connectRoomsToPremises({
          id: r.id,
          data,
        });
      });

      if (clearRooms?.length) {
        clearRooms.forEach((r) => {
          calls.push(
            services.connectRoomsToPremises({
              id: r.id,
              data: {
                apartment: null,
                industrial_premises: null,
                common_area: null,
              },
            })
          );
        });
      }

      const res = await Promise.all(calls);

      dispatch(clearFetched(roomConstants, true));

      successCallback();
    } catch (e) {}
  };
};

export const setCurrentBuilding = ({ building }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_BUILDING,
      payload: {
        building,
      },
    });
  };
};

export const setCurrentFloor = ({ floor }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_FLOOR,
      payload: {
        floor,
      },
    });
  };
};
