import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(p) => p.gridWidth}, auto);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-bottom: 24px;
`;
export const Item = styled.div`
  display: flex;
  flex: 1;
  padding: 3px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2px;
`;

export const Chapter = styled.div`
  flex: 1;
  min-width: 300px;
  padding: 12px;
  border-radius: 8px;
  border: ${(p) => p.theme.borders.standard};
  background-color: ${(p) => (p.darkThemed ? p.theme.colors.gray3 : "white")};
  box-shadow: ${(p) => p.theme.boxShadows.default};

  ${(p) =>
    p.darkThemed &&
    css`
      color: white;
    `}

  & > ${Item} {
    &:nth-of-type(even) {
      background-color: ${(p) =>
        p.darkThemed ? "white" : " rgba(241, 242, 246, 0.35)"};

      ${(p) =>
        p.darkThemed &&
        css`
          color: ${p.theme.colors.primaryText};
        `}
    }

    &:nth-of-type(odd) {
      background-color: ${(p) =>
        p.darkThemed ? "rgba(241, 242, 246, 0.9)" : " white"};

      ${(p) =>
        p.darkThemed &&
        css`
          color: ${p.theme.colors.primaryText};
        `}
    }
  }
`;

export const ChapterTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  margin-bottom: 12px;
`;

export const ItemTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  text-align: left;
`;

export const ItemValue = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
  text-align: right;
`;
