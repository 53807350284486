import * as React from "react";
import { useDispatch } from "react-redux";

import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { toMoneyString } from "../../../utils/stringUtils";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const MODES = {
  YEARLY: 1,
  SQM_YEARLY: 2,
  MONTHLY: 3,
  SQM_MONTHLY: 4,
};

const CALCULATION_METHODS = {
  1: (val) => val / 12, //YEARLY:1,
  2: (val, area) => (val * area) / 12, //SQM_YEARLY:2,
  3: (val) => val, //MONTHLY:3,
  4: (val, area) => val * area, //SQM_MONTHLY:4
};

export default function RentInputField({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  disabled,
  title,
  description,
  placeholder,
  area = null,
  hideArea,
  forceError,
  forceInstructions,
  forceRequired,
  changeCallback,
}) {
  const [mode, setMode] = React.useState(MODES.MONTHLY);
  const [internalValue, setInternaValue] = React.useState(null);

  const dispatch = useDispatch();
  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || forceInstructions;

  const required =
    forceRequired || (instructions?._required && !instructions._allowNull);

  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey }) || forceError;

  React.useEffect(() => {
    if (value != null && internalValue == null) {
      setInternaValue(value);
    }
  }, [value]);

  const onChange = (data, viewMode) => {
    setInternaValue(data);

    const calculationMethod = CALCULATION_METHODS[viewMode];
    let calculatedValue =
      data != null
        ? calculationMethod(parseFloat(data.replace(",", ".")), area)
        : null;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: calculatedValue
            ? calculatedValue
            : instructions._required
            ? 0
            : instructions._allowNull
            ? null
            : "",
        },
      })
    );

    if (changeCallback) {
      changeCallback(data);
    }
  };

  const handleSetMode = (mode) => {
    setMode(mode);
  };

  const handleYearlyToggled = () => {
    switch (mode) {
      case MODES.YEARLY:
        handleSetMode(MODES.MONTHLY);
        onChange(internalValue, MODES.MONTHLY);
        break;
      case MODES.SQM_YEARLY:
        handleSetMode(MODES.SQM_MONTHLY);
        onChange(internalValue, MODES.SQM_MONTHLY);

        break;
      case MODES.MONTHLY:
        handleSetMode(MODES.YEARLY);
        onChange(internalValue, MODES.YEARLY);

        break;
      case MODES.SQM_MONTHLY:
        handleSetMode(MODES.SQM_YEARLY);
        onChange(internalValue, MODES.SQM_YEARLY);

        break;

      default:
        return;
    }
  };

  const handleSqmToggled = () => {
    switch (mode) {
      case MODES.YEARLY:
        handleSetMode(MODES.SQM_YEARLY);
        onChange(internalValue, MODES.SQM_YEARLY);
        break;
      case MODES.SQM_YEARLY:
        handleSetMode(MODES.YEARLY);
        onChange(internalValue, MODES.YEARLY);

        break;
      case MODES.MONTHLY:
        handleSetMode(MODES.SQM_MONTHLY);
        onChange(internalValue, MODES.SQM_MONTHLY);

        break;
      case MODES.SQM_MONTHLY:
        handleSetMode(MODES.MONTHLY);
        onChange(internalValue, MODES.MONTHLY);

        break;

      default:
        return;
    }
  };

  if (!instructions) {
    return null;
  }
  if (instructions._readOnly) {
    return null;
  }

  return (
    <div>
      {title && (
        <label
          htmlFor={fieldKey}
          className=" text-sm flex items-center font-medium text-gray-900 "
        >
          {(error || forceError) && (
            <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
          )}
          {title}
          {required ? "*" : ""}
        </label>
      )}
      {description && (
        <p
          id={`${fieldKey}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {(error || forceError) && (
        <div className="text-xs mb-1 font-normal text-red-600 ">
          {forceError || error}
        </div>
      )}

      <div className="flex overflow-hidden items-center bg-white border border-slate-300 text-gray-900 text-sm rounded w-full p-0">
        <input
          value={internalValue?.toString() || ""}
          disabled={disabled}
          onChange={({ target: { value } }) => onChange(value, mode)}
          id={fieldKey}
          className="flex-1 bg-white border-none  text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 focus:outline-none inline-block p-2.5"
          placeholder={placeholder || title}
          required={required}
        />

        {!hideArea && (
          <button
            onClick={(e) => {
              e.preventDefault();
              if (area != null) {
                handleSqmToggled();
              }
            }}
            className={`inline-flex  flex-shrink-0 whitespace-nowrap  ${
              ![MODES.SQM_YEARLY, MODES.SQM_MONTHLY].includes(mode)
                ? "bg-slate-200 opacity-50 cursor-not-allowed text-gray-900"
                : "bg-primaryblue text-white"
            } items-center   focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-primaryblue/80  focus:outline-none text-sm p-2.5 text-center`}
          >
            /{area}m2
          </button>
        )}

        <button
          onClick={(e) => {
            e.preventDefault();
            handleYearlyToggled();
          }}
          className={`inline-flex  flex-shrink-0 whitespace-nowrap  ${
            disabled
              ? "bg-slate-400 opacity-80 cursor-not-allowed text-gray-900"
              : "bg-primaryblue text-white"
          } items-center border-l border-t-0 border-r-0 border-b-0 border-slate-300  rounded-r focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-primaryblue/80  focus:outline-none text-sm p-2.5 text-center`}
        >
          {[MODES.SQM_YEARLY, MODES.YEARLY].includes(mode) ? "/år" : "/månad"}
        </button>
      </div>

      <p
        id={`${fieldKey}-montlhy`}
        className="text-xs my-1 font-normal text-gray-500 bg-transparent"
      >
        Totalt per år: {toMoneyString((value || 0) * 12, true)}{" "}
        {area && `(${toMoneyString(((value || 0) * 12) / area, true)}/m²)`}
      </p>
      <p
        id={`${fieldKey}-montlhy`}
        className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
      >
        Totalt per månad: {toMoneyString(value || 0, true)}{" "}
        {area && `(${toMoneyString((value || 0) / area, true)}/m²)`}
      </p>
    </div>
  );
}
