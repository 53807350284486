export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INVOICING_PROJECTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INVOICING_PROJECTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INVOICING_PROJECTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INVOICING_PROJECTS",
  DESTROY_FORM: "DESTROY_FORM_INVOICING_PROJECTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_INVOICING_PROJECTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INVOICING_PROJECTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INVOICING_PROJECTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INVOICING_PROJECTS",
  RESET_STATE: "RESET_STATE_INVOICING_PROJECTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INVOICING_PROJECTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_INVOICING_PROJECTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_INVOICING_PROJECTS",

  LIST_URL: "/accounting/costs/project/list/",
  POST_URL: "/accounting/costs/project/",
  PATCH_URL: "/accounting/costs/project/",
  GET_URL: "/accounting/costs/project/",

  STORE_NAME: "invoicingProjects",
};
