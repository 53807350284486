import styled from "styled-components";

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.colors.white};
  padding: 12px;
  margin: 0 12px 12px 0;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 8px;
  color: ${(p) => p.theme.colors.primaryText};
`;
