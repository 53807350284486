import { axiosInstance } from "../base/store/axios";

async function infoTraderLookup(searchTerm) {
  const { data: results } = await axiosInstance.get(
    `/external/infotrader/lookup/${searchTerm}/`
  );

  return results;
}

async function fetchInfoTraderData({ municipality, key, hasApartments }) {
  const { data } = await axiosInstance.get(
    `/external/infotrader/fetch/${municipality}/${key}/${
      hasApartments ? "?has_apartments=true" : ""
    }`
  );

  return data;
}

export { infoTraderLookup, fetchInfoTraderData };
