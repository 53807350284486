import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import {
  useAllPermissionCheck,
  useFrequentPermissions,
} from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import {
  createOptionsUrl,
  handleManyInvoicingUrl,
} from "../../store/parkingContracts";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import useActiveFilters from "../../hooks/useActiveFilters";

import { useParkingContractPaginationCount } from "../../store/parkingContracts/hooks/retrieve";

import ParkingContracts from "./overview/ParkingContracts";
import ParkingContractDocuments from "./overview/ParkingContractDocuments";
import ParkingContractsCosts from "./overview/ParkingContractsCosts";
import ParkingContractDrafts from "./overview/Drafts";
import HandleMassInvoincingParkingContract from "./overview/HandleMassInvoicing";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function ParkingContractOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["view_can_parking", "view_can_contract"]);

  const canAddParkingContract = useAllPermissionCheck([
    "add_can_contract",
    "add_can_parking",
  ]);

  const { filteredRealEstates } = useActiveFilters();
  const { hasBillectaFullPermission } = useFrequentPermissions();

  const [activeCount] = useParkingContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: false,
  });
  const [draftCount] = useParkingContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: true,
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Avtal",
      component: ParkingContracts,
    },
    {
      path: "/costs",
      label: "Avtal med kostnader",
      component: ParkingContractsCosts,
    },
    {
      path: "/drafts",
      label: `Utkast${draftCount ? ` (${draftCount})` : ""}`,
      component: ParkingContractDrafts,
    },
    {
      path: "/handle-invoicing-statuses",
      hiddenLabel: true,
      component: HandleMassInvoincingParkingContract,
    },
    {
      path: "/parking-contract-docs",
      label: "Digital signering",
      component: ParkingContractDocuments,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal parkeringsavtal:{" "}
          {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {hasBillectaFullPermission && (
        <PrimaryBtn secondary onClick={() => push(handleManyInvoicingUrl())}>
          Masshantera aviseringsstatus
        </PrimaryBtn>
      )}
      {canAddParkingContract && (
        <PrimaryBtn className="ml-2" onClick={() => push(createOptionsUrl())}>
          Lägg till avtal
        </PrimaryBtn>
      )}
    </div>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Parkeringsavtal`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
