import styled, { css } from "styled-components";
import pointIcon from "../../../../assets/svg/material/dot_black.svg";
import checkboxEmpty from "../../../../assets/svg/material/checkbox_empty_black.svg";
import checkboxFilled from "../../../../assets/svg/material/checkbox_filled_black.svg";
import arrowRight from "../../../../assets/svg/material/arrow-right-white.svg";
import check from "../../../../assets/svg/material/check_circle_green.svg";

export const TaskCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-right: 12px;
  margin-bottom: 12px;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const TaskCardWrapper = styled.div`
  position: relative;
  cursor: ${(p) => (p.done ? "" : "pointer")};
  width: 100%;
  flex: 1;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;
  background-color: white;

  ${(p) =>
    !p.done &&
    css`
      &:after {
        height: 37px;
        width: 100%;
        position: absolute;
        content: "";
        top: 0;
        left: -100%;
        transition: all 150ms ease-out;
        background-color: ${(p) => p.theme.colors.primaryBlue};
      }

      &:hover {
        &:after {
          left: 0;
        }
      }
    `}
`;

export const TaskCardHeader = styled.div`
  width: 100%;
  padding: 8px 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: white;
  background-color: ${(p) => p.theme.colors.gray4};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(p) =>
    p.done &&
    css`
      background-color: ${(p) => p.theme.colors.greenDark};
    `}
`;

export const TaskCardHeaderIcon = styled.div`
  height: 20px;
  width: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${arrowRight});
  z-index: 1;
`;

export const TaskCardContent = styled.div`
  background-color: white;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  z-index: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
`;

export const TaskCardItem = styled.div`
  position: relative;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.body};
  line-height: 110%;
  padding: 6px 12px 6px 24px;

  &:not(:first-of-type) {
    border-top: thin solid rgba(0, 0, 0, 0.09);
  }

  &:last-of-type {
    padding-bottom: 16px;
  }

  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 6px;
    top: 8px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${pointIcon});
    opacity: 0.6;
  }
`;

export const TaskCardItems = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  & > ${TaskCardItem} {
    &:nth-of-type(even) {
      background-color: rgba(241, 242, 246, 0.35);
    }

    &:nth-of-type(odd) {
      background-color: white;
    }
  }
`;

export const TaskCardDone = styled.div`
  position: relative;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  margin: 48px auto 8px auto;
  text-align: center;
  color: ${(p) => p.theme.colors.greenDark};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: calc(50% - 14px);
    top: -32px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${check});
  }
`;

export const TodoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TodoItemWrapper = styled.div`
  position: relative;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  padding: 4px 12px 12px 12px;
  margin-bottom: 12px;
  border-radius: 5px;
`;

export const TodoEndDate = styled.div`
  display: inline-block;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: 12px;
  padding: 4px;
  border-radius: 5px;
  margin-bottom: 2px;
`;

export const TodoTime = styled.strong`
  color: ${(p) =>
    p.status === "late" ? p.theme.colors.red : p.theme.colors.gray3};
`;

export const TodoItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TodoItemDescription = styled.div`
  padding-left: 42px;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
`;

export const TodoIcon = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${checkboxEmpty});
  cursor: pointer;

  &:hover {
    background-image: url(${checkboxFilled});
    opacity: 0.3;
  }

  ${(p) =>
    p.done &&
    css`
      background-image: url(${checkboxFilled});

      &:hover {
        background-image: url(${checkboxEmpty});
        opacity: 0.3;
      }
    `}
`;

export const TodoText = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const GetStartedHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const GetStartedTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
`;

export const GetStartedCompletionRate = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
`;

export const NoCalendarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(256, 256, 256, 0.7);
`;

export const NoCalendarsBox = styled.div`
  background-color: #fff;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border-radius: 8px;
  padding: 12px;
  min-width: 90%;
  max-width: 90%;
`;
