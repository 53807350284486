import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useAllPermissionCheck,
  useFormInstanceField,
} from "../../../store/base";

import { FormArea, FormAreaTitle } from "../../Forms/Base/Chapters/styles";
import DocumentUploader from "../../Forms/Base/Fields/DocumentUploader";

import { TextButton } from "../../Forms/Base/Buttons";

import TemplateContainer from "./Templates/Container";
import { FormAreaDescription } from "../../Billecta/Invoices/styles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { useHistory, useParams } from "react-router";
import { getDetailUrl } from "../../../views/EditableDoc/HandleContract";
import { axiosInstance } from "../../../store/base/store/axios";
import Alert, { ALERT_TYPES } from "../../Alert/Alert";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";

export const EDITABLEDOC_DOC_SELECTOR_STEPS = {
  SELECT_TYPE: "SELECT_TYPE",
  DOCUMENT: "DOCUMENT",
  TEMPLATES: "TEMPLATES",
};

export default function DocumentSelector({
  storeName,
  method,
  contract,
  editableDocFieldKey,
  editableDoc,
  isCancelledDoc,
  forceStep,
  canChangeStep = true,
}) {
  const dataPath = `docData_`;
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(
    EDITABLEDOC_DOC_SELECTOR_STEPS.SELECT_TYPE
  );
  const { push } = useHistory();
  const { contractType } = useParams();

  React.useEffect(() => {
    if (forceStep) {
      setStep(forceStep);
    }
  }, [forceStep]);

  const contractDetailUrl = getDetailUrl(contractType);

  const hasFastDocPermission = useAllPermissionCheck([
    "allow_fastdoc",
    "view_can_fastdoc",
  ]);

  const [isFastdocAuthed, setIsFastdocAuthed] = React.useState(null);

  const checkIsFastdocAuthed = async () => {
    if (!hasFastDocPermission) {
      return false;
    }
    try {
      const result = await axiosInstance.get(
        "external/fastdoc/check_activated/"
      );
      const activated = result?.data?.is_activated ?? null;
      setIsFastdocAuthed(activated);
    } catch (err) {
      setIsFastdocAuthed(false);
    }
  };

  React.useEffect(() => {
    if (isFastdocAuthed == null) {
      checkIsFastdocAuthed();
    }
  }, [hasFastDocPermission]);

  const chosenTemplate = useFormInstanceField({
    storeName,
    fieldKey: "_chosenTemplate",
  });

  const setChosenTemplate = ({ id, pay = false, type }) => {
    const data = {
      _chosenTemplate: {
        name: type,
        id,
        pay,
      },
    };

    dispatch(updateActiveFormInstance({ storeName, data }));
  };

  React.useEffect(() => {
    if (
      editableDoc?.fastdoc_id &&
      !editableDoc?.docData_ &&
      chosenTemplate == null
    ) {
      setChosenTemplate({
        id: editableDoc?.fastdoc_id,
        type: editableDoc.title,
        pay: false,
      });
    }
  }, [editableDoc, chosenTemplate]);

  return (
    <>
      <FormArea>
        {step === EDITABLEDOC_DOC_SELECTOR_STEPS.SELECT_TYPE && (
          <>
            {contract && (
              <Alert
                type={ALERT_TYPES.INFO}
                title={`Dokument för digital ${
                  isCancelledDoc ? "uppsägning" : "signering"
                } av avtal ${contract.id_number}`}
                secondaryTitle={
                  <div className="flex items-center">
                    Gå vidare till avtal{" "}
                    <ChevronRightIcon width={16} className="ml-1" />
                  </div>
                }
                secondaryAction={() =>
                  push(contractDetailUrl({ id: contract.id }))
                }
              >
                Här sätter du upp det digitala dokumentet för signering av
                avtalet. Tryck på "Gå vidare till avtal" för att hoppa över
                detta steg och göra det senare istället.
              </Alert>
            )}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Välj typ av dokument</OverviewTitle>
                <OverviewSubtitle>
                  Välj om en mall ska användas eller om du vill ladda upp ett
                  eget dokument
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div className="flex flex-col space-y-2">
              <div>
                <PrimaryBtn
                  onClick={() =>
                    setStep(EDITABLEDOC_DOC_SELECTOR_STEPS.TEMPLATES)
                  }
                >
                  Basera på mall{" "}
                  <ChevronRightIcon width={16} className="ml-1" />
                </PrimaryBtn>
              </div>

              <div>
                <PrimaryBtn
                  secondary
                  onClick={() =>
                    setStep(EDITABLEDOC_DOC_SELECTOR_STEPS.DOCUMENT)
                  }
                >
                  Ladda upp dokument
                  <ChevronRightIcon width={16} className="ml-1" />
                </PrimaryBtn>
              </div>
            </div>
          </>
        )}

        {step === EDITABLEDOC_DOC_SELECTOR_STEPS.DOCUMENT && (
          <>
            {canChangeStep && (
              <TextButton
                iconType="arrow-back"
                title="Tillbaka"
                clicked={() =>
                  setStep(EDITABLEDOC_DOC_SELECTOR_STEPS.SELECT_TYPE)
                }
                extraStyle={{ marginBottom: "24px" }}
              />
            )}
            <FormAreaTitle>Välj dokument</FormAreaTitle>
            <FormAreaDescription>Ladda upp ett dokument</FormAreaDescription>
          </>
        )}

        {step === EDITABLEDOC_DOC_SELECTOR_STEPS.TEMPLATES && (
          <>
            {canChangeStep && (
              <TextButton
                iconType="arrow-back"
                title="Tillbaka"
                clicked={() =>
                  setStep(EDITABLEDOC_DOC_SELECTOR_STEPS.SELECT_TYPE)
                }
                extraStyle={{ marginBottom: "24px" }}
              />
            )}

            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Välj mall</OverviewTitle>
                <OverviewSubtitle>
                  Välj vilken mall som ska användas som bas.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
          </>
        )}

        {step === EDITABLEDOC_DOC_SELECTOR_STEPS.DOCUMENT && (
          <DocumentUploader
            storeName={storeName}
            fieldKey={`${editableDocFieldKey}${
              editableDocFieldKey ? "." : ""
            }doc`}
            method={method}
          />
        )}

        {step === EDITABLEDOC_DOC_SELECTOR_STEPS.TEMPLATES && (
          <TemplateContainer
            hasFastDocPermission={hasFastDocPermission}
            storeName={storeName}
            dataPath={dataPath}
            editableDocPath={editableDocFieldKey}
            authed={isFastdocAuthed}
          />
        )}
      </FormArea>
    </>
  );
}
