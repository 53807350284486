import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import * as SC from "./styles";

// headers -> array of headers
// rows -> array of arrays with data. Each arrays length must match header length
// summarizerRow -> array of items with index corresponding to header index
// onclickmapping -> array of callback data for each row
export default ({
  title,
  headers = [],
  rows = [],
  onClickRowMapping = [],
  onRowClicked,
  summarizerRows = [],
  loading,
  renderEmpty,
  extraStyles = {},
  extraContainerStyles = {},
  errorRowIndexes = [], // array of indexes with errors
  selectedRowIndex,
  keyMapping = [], // array of keys to force update
}) => {
  return (
    <div style={{ ...extraContainerStyles }}>
      {title && (
        <OverviewTitleWrapper style={{ marginBottom: 12 }}>
          <OverviewTitle small>{title}</OverviewTitle>
        </OverviewTitleWrapper>
      )}
      <SC.Wrapper style={{ ...extraStyles }}>
        {headers.length > 0 && rows.length > 0 ? (
          <SC.Table>
            <thead>
              <tr role="row">
                {headers.map((h) => (
                  <th colspan={1} role="columnheader">
                    {h}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody role="rowgroup">
              {rows.map((r, idx) => (
                <tr
                  key={keyMapping[idx] || idx}
                  role="row"
                  onClick={
                    onRowClicked
                      ? () => onRowClicked(onClickRowMapping[idx], idx)
                      : null
                  }
                  style={
                    errorRowIndexes.includes(idx)
                      ? { backgroundColor: "#fddddd" }
                      : selectedRowIndex === idx
                      ? { backgroundColor: "rgba(0,0,256,.2)" }
                      : {}
                  }
                >
                  {r.map((c) => (
                    <td>{c}</td>
                  ))}
                </tr>
              ))}
            </tbody>

            {summarizerRows?.length > 0 && (
              <tfoot>
                <tr role="row">
                  {summarizerRows.map((s) => (
                    <th>{s}</th>
                  ))}
                </tr>
              </tfoot>
            )}
          </SC.Table>
        ) : (
          <SC.TableEmptyWrapper>
            {loading ? (
              <OverlaySpinner />
            ) : (
              <SC.TableEmptyText>
                {renderEmpty ? renderEmpty() : "Det finns ingen data att visa"}
              </SC.TableEmptyText>
            )}
          </SC.TableEmptyWrapper>
        )}
      </SC.Wrapper>
    </div>
  );
};
