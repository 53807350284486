export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_OVERVIEW",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_OVERVIEW",
  SET_CURRENT_TAB: "SET_CURRENT_TAB_OVERVIEW",
  SET_CURRENT_DETAIL_TAB: "SET_CURRENT_DETAIL_TAB_OVERVIEW",
  ADD_SELECTED_REALESTATE: "ADD_SELECTED_REALESTATE_OVERVIEW",
  REMOVE_SELECTED_REALESTATE: "REMOVE_SELECTED_REALESTATE_OVERVIEW",
  RESET_STATE: "RESET_STATE_OVERVIEW",

  SUMMARIZED_STATISTICS_URL: "/statistics/main/main_statistics/",
  SUMMARIZED_STATISTICS_ID: "_summarizedStats",
  SUMMARIZED_STATISTICS_PROCESS_NAME: "_summarizedStats",

  COST_STATISTICS_URL: "/statistics/contract/cost_statistics/",
  COST_STATISTICS_ID: "_costStats",
  COST_STATISTICS_PROCESS_NAME: "_costStats",

  HEAVY_COST_STATISTICS_URL: "/statistics/costs/detailed_cost_statistics/",
  HEAVY_COST_STATISTICS_ID: "_heavyCostStats",
  HEAVY_COST_STATISTICS_PROCESS_NAME: "_heavyCostStats",

  REPORT_ERRAND_STATISTICS_URL: "/statistics/errands/reporterrand_statistics/",
  REPORT_ERRAND_STATISTICS_ID: "_reportErrandStats",
  REPORT_ERRAND_STATISTICS_PROCESS_NAME: "_reportErrandStats",

  INSPECTION_ERRAND_STATISTICS_URL:
    "/statistics/errands/inspectionerrand_statistics/",
  INSPECTION_ERRAND_STATISTICS_ID: "_inspectionErrandStats",
  INSPECTION_ERRAND_STATISTICS_PROCESS_NAME: "_inspectionErrandStats",

  ROUNDING_ERRAND_STATISTICS_URL:
    "/statistics/errands/roundingerrand_statistics/",
  ROUNDING_ERRAND_STATISTICS_ID: "_roundingErrandStats",
  ROUNDING_ERRAND_STATISTICS_PROCESS_NAME: "_roundingErrandStats",

  TODO_CONTRACT_STATISTICS_URL:
    "/statistics/contract/todo_contract_statistics/",
  TODO_CONTRACT_STATISTICS_ID: "_todoContractStats",
  TODO_CONTRACT_STATISTICS_PROCESS_NAME: "_todoContractStats",

  TODO_PREMISES_STATISTICS_URL:
    "/statistics/premises/todo_premises_statistics/",
  TODO_PREMISES_STATISTICS_ID: "_todoPremisesStats",
  TODO_PREMISES_STATISTICS_PROCESS_NAME: "_todoPremisesStats",

  TODO_TENANT_STATISTICS_URL: "/statistics/tenant/todo_tenant_statistics/",
  TODO_TENANT_STATISTICS_ID: "_todoTenantStats",
  TODO_TENANT_STATISTICS_PROCESS_NAME: "_todoTenantStats",

  TODO_USER_STATISTICS_URL: "/statistics/user/todo_user_statistics/",
  TODO_USER_STATISTICS_ID: "_todoUserStats",
  TODO_USER_STATISTICS_PROCESS_NAME: "_todoUserStats",

  TODO_IMD_STATISTICS_URL: "/statistics/imd/todo_imd_statistics/",
  TODO_IMD_STATISTICS_ID: "_todoImdStats",
  TODO_IMD_STATISTICS_PROCESS_NAME: "_todoImdStats",

  TODO_BILLING_STATISTICS_URL: "/statistics/billing/todo_billing_statistics/",
  TODO_BILLING_STATISTICS_ID: "_todoBillingStats",
  TODO_BILLING_STATISTICS_PROCESS_NAME: "_todoBillingStats",

  YOUR_BLOCK_STATISTICS_URL: "/statistics/mypages/todo_yourblock_statistics/",
  YOUR_BLOCK_STATISTICS_ID: "_todoYourBlockStats",
  YOUR_BLOCK_STATISTICS_PROCESS_NAME: "_todoYourBlockStats",

  TODO_BILLING_INVOICE_STATISTICS_URL:
    "/statistics/billing/todo_billing_invoice_statistics/",
  TODO_BILLING_INVOICE_STATISTICS_ID: "_todoBillingInvoiceStats",
  TODO_BILLING_INVOICE_STATISTICS_PROCESS_NAME: "_todoBillingInvoiceStats",

  TODO_ERRAND_STATISTICS_URL:
    "/statistics/errands/todo_errand_statistic_endpoint/",
  TODO_ERRAND_STATISTICS_ID: "_todoErrandStats",
  TODO_ERRAND_STATISTICS_PROCESS_NAME: "_todoErrandStats",

  SET_ACTIVE_FILTERED: "SET_ACTIVE_FILTERED_OVERVIEW",

  CLEAR_TODO_DATA: "CLEAR_TODO_DATA_OVERVIEW",

  STORE_NAME: "overview",
};
