import * as React from "react";
import { useHistory } from "react-router";
import { detailUrl as parkingDetailUrl } from "../../../../store/parkingContracts";
import { detailUrl as otherDetailUrl } from "../../../../store/otherContracts";
import Table from "../../../Billecta/Table/BasicTable";
import { toMoneyString } from "../../../utils/stringUtils";

import { buildQueryString } from "../../../../store/base";
import { useFilteredParkingSpots } from "../../../../store/parkingSpots";
import { detailUrl as indexDetailUrl } from "../../../../store/indexsetting";
import { COST_ROW_STATES, getCostRowState } from "./costCols";
import { DateCell, LinkedObject } from "../../../Displays";
import Badge, { BADGE_TYPES } from "../../../Badge/Badge";

export default function BilledWithContract({
  billedContract,
  invoicingObj,
  selectedFilters = [],
  realEstate,
}) {
  const detailUrl =
    billedContract.contractKind === "other" ? otherDetailUrl : parkingDetailUrl;
  const { push } = useHistory();

  const parkingQ = buildQueryString({
    id__in: billedContract?.parking_spots?.map((p) => p.id) || [],
  });
  const [parkingSpots] = useFilteredParkingSpots(parkingQ);

  const parkingCosts = parkingSpots?.reduce((acc, cur) => {
    return [...acc, ...(cur.cost_set || [])];
  }, []);

  const tableCosts = React.useMemo(() => {
    const combined = [...(billedContract.costs || []), ...(parkingCosts || [])];

    if (!combined) return [];
    if (selectedFilters?.length) {
      return combined.filter((fc) => {
        const state = getCostRowState({
          costRow: fc,
          contract: billedContract,
          invoicingObj,
        });

        return selectedFilters.includes(state);
      });
    }

    return combined;
  }, [billedContract, parkingCosts, selectedFilters]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Debiteringsrad",
        accessor: "title",
        Cell: ({ value, row }) => {
          const isDiscount = row.original.value < 0;
          const isRentIncrease = row.original.is_rent_increase_cost;
          const doNotDebit = row.original.do_not_debit;

          const state = getCostRowState({
            costRow: row.original,
            invoicingObj,
            contract: billedContract,
          });

          const stateRender =
            state === COST_ROW_STATES.ACTIVE ? (
              <Badge small type={BADGE_TYPES.GREEN}>
                Aktiv
              </Badge>
            ) : state === COST_ROW_STATES.EARLIER ? (
              <Badge small type={BADGE_TYPES.YELLOW}>
                Tidigare
              </Badge>
            ) : state === COST_ROW_STATES.COMING ? (
              <Badge small type={BADGE_TYPES.DEFAULT}>
                Kommande
              </Badge>
            ) : (
              <Badge small type={BADGE_TYPES.GRAY}>
                Okänd
              </Badge>
            );

          return (
            <div className="flex flex-col">
              <div className="flex mb-1 items-center space-x-1">
                {stateRender}
                <Badge
                  small
                  type={doNotDebit ? BADGE_TYPES.RED : BADGE_TYPES.GREEN}
                >
                  {doNotDebit ? "Debiteras Inte" : "Debiteras"}
                </Badge>
                {isDiscount && (
                  <Badge small type={BADGE_TYPES.YELLOW}>
                    Rabatt
                  </Badge>
                )}
                {isRentIncrease && (
                  <Badge small type={BADGE_TYPES.INDIGO}>
                    {"Hyreshöjning"}
                  </Badge>
                )}
              </div>
              <div className="font-medium text-sm">{value}</div>
            </div>
          );
        },
      },
      {
        Header: "Debiteras",
        accessor: "value",
        Cell: ({ value, row }) => {
          const productVat = row.original.product?.vat;
          const rowVat = row.original.vat;
          const val =
            rowVat != null
              ? `${rowVat}% moms`
              : productVat != null
              ? `${productVat}% moms (från produkt)`
              : "-";

          return (
            <div className="flex flex-col">
              <div className="text-sm font-medium">
                {toMoneyString(value || 0, true)}/mån
              </div>
              <div className="text-xs">{val}</div>
            </div>
          );
        },
      },
      {
        Header: "Period",
        id: "period",
        Cell: ({ row }) => {
          const { start_date, end_date } = row.original;

          if (!start_date && !end_date) {
            return (
              <div className="flex items-center space-x-2">Med avtalet</div>
            );
          }

          return (
            <div className="flex items-center space-x-2">
              {start_date ? <DateCell date={start_date} /> : "Avtalets start"}
              <div>-</div>
              {end_date ? <DateCell date={end_date} /> : "Avtalets slut"}
            </div>
          );
        },
      },

      {
        Header: "Bokföring",
        id: "accounting",
        Cell: ({ row }) => {
          const { product, project, cost_center } = row.original;

          const costCenterTitle = cost_center?.title
            ? cost_center.title
            : cost_center?.str_representation
            ? cost_center.str_representation
            : realEstate?.cost_center
            ? `${realEstate.cost_center.str_representation} (från fastighet)`
            : "-";

          const projectTitle = project?.title
            ? project.title
            : project?.str_representation
            ? project.str_representation
            : realEstate?.project
            ? `${realEstate.project.str_representation} (från fastighet)`
            : "-";

          return (
            <div className="flex flex-col">
              <div className="text-xs">
                Produkt:{" "}
                <span className="font-medium">
                  {product?.title || product?.str_representation || "-"}{" "}
                  {product?.account_no ? `(${product.account_no})` : ""}
                </span>
              </div>
              <div className="text-xs">
                Projekt:<span className="font-medium"> {projectTitle}</span>
              </div>
              <div className="text-xs">
                Kostnadsställe:
                <span className="font-medium">{costCenterTitle}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Indexuppräkning",
        accessor: "indexation",
        Cell: ({ value }) => {
          return <LinkedObject obj={value} urlMethod={indexDetailUrl} />;
        },
      },
    ];

    return cols;
  }, [billedContract, parkingCosts]);

  return (
    <div className="flex flex-col">
      <div className="text-xs font-medium mb-1 mt-2">
        Debiteringsrader från samaviserat{" "}
        <span
          onClick={() => push(detailUrl({ id: billedContract?.id }))}
          className="text-blue-600 underline cursor-pointer"
        >
          {billedContract?.contractKind === "other"
            ? "övrigt avtal "
            : "parkeringsavtal "}
          {billedContract.id_number}
        </span>
      </div>
      <Table
        extraStyles={{ marginTop: 0 }}
        columns={tableColumns}
        data={tableCosts}
        hideSearch
      />
    </div>
  );
}
