import * as React from "react";
import { useHistory } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import { constants, createUrl } from "../../../../store/leaseContracts";

import StepDisplay from "../../../StepDisplay/StepDisplay";

export default function LeaseContractOptionsForm({ method = "POST" }) {
  const storeName = constants.STORE_NAME;
  const { push } = useHistory();

  const onDone = ({ contractType, premisesType, signingType }) => {
    push(
      createUrl({
        kind: premisesType,
        existing: contractType !== "new",
        digitalSign: signingType === "digital",
      })
    );
  };

  const renderSteps = () => {
    const steps = [
      "1. Avtalstyp",
      "2. Avtal",
      "3. Debitering & Avisering",
      "4. Dokument för signering",
    ];

    return <StepDisplay steps={steps} activeIndex={0} />;
  };

  return (
    <>
      {renderSteps()}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs,
          chapters,
          descriptions,
          method,
          onDone,
          title: "Avtalstyp",
        }}
      />
    </>
  );
}
