import * as React from "react";
import { ToolTipCell, LinkedObject } from "../../Displays";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import StateFilter from "./Filters/StateFilter";
import CategoryFilter from "./Filters/CategoryFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import { renderFloors } from "../../Forms/Base/FieldComponents/FloorSelector";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import { TextButton } from "../../Forms/Base/Buttons";

export default ({ onExcludeClicked }) => {
  const cols = [
    {
      Header: "",
      id: "exclude",
      Cell: ({ row }) => {
        return (
          <TextButton
            red
            iconType="close"
            title="Exkludera"
            clicked={() => onExcludeClicked(row)}
          />
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ value, row }) => {
        return (
          <StatusLabel state={row.original.state} contentType="premises">
            {row.original.state_display}
            {row.original.subletted ? " (andrahand)" : ""}
          </StatusLabel>
        );
      },
      Filter: StateFilter,
      filter: "textExact",
    },

    {
      Header: "Lägenhetsnummer",
      accessor: "apartment_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Objektsnummer",
      accessor: "premises_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kategori",
      accessor: "category",
      Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
      Filter: CategoryFilter,
      filter: "text",
    },
    {
      Header: "Adress",
      accessor: "address.str_representation",
      Cell: ({ row }) => {
        const value = row.original.address?.str_representation;
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastighet",
      accessor: "realestate.str_representation",
      Cell: ({ row }) => {
        // (row) => row.realestate?.str_representation
        const value = row.original.realestate;
        return <LinkedObject obj={value} urlMethod={realEstateDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Byggnad",
      accessor: "building.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Våning",
      accessor: "dynamic_floors",
      // for convenience, backend allow filtering on summed_floors
      id: "dynamicFloors",
      Cell: ({ value }) => <ToolTipCell text={renderFloors(value)} />,
      // Filter: SimpleTextFilter,
      // filter: "text",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Area (m2)",
      accessor: "area",
      Cell: ({ value }) => (
        <ToolTipCell text={(value || 0).toLocaleString("sv")} />
      ),
      Filter: NumberRangeFilter,
      filter: "between",
    },
  ];

  return cols;
};
