import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import { usePermissionCheck } from "../../store/base";

import { useRealEstatePaginationCount } from "../../store/realEstates";
import RealEstateForm from "../../components/Forms/RealEstate/ChapterForm/ModalForm";
import InfoTraderBox from "../../components/InfoTrader/InfoTraderBox";
import RealEstates from "./overview/RealEstates";
import PortfoliosOverview from "../Portfolios/MainOverview";
import RealEstateMap from "./overview/Map";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import Modal from "src/components/Forms/Base/Modals/Modal";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function MainRealEstatesOverview() {
  const [createRealEstateOpen, setCreateRealEstateOpen] = React.useState(false);
  usePermissionRedirect(["view_can_realestate"]);

  const [infoTraderOpen, setInfoTraderOpen] = React.useState(false);

  const { filteredRealEstates } = useActiveFilters();
  const { path, url } = useRouteMatch();

  const canAdd = usePermissionCheck("add_can_realestate");

  const [realEstateCount] = useRealEstatePaginationCount({
    filters: { realestate_ids: filteredRealEstates },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: RealEstates,
    },
    {
      path: "/map",
      label: "Karta",
      component: RealEstateMap,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal fastigheter:{" "}
          {realEstateCount != null ? realEstateCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <>
      {canAdd && (
        <PrimaryBtn onClick={() => setInfoTraderOpen(true)}>
          Lägg till fastighet
        </PrimaryBtn>
      )}
    </>
  );

  return (
    <>
      {createRealEstateOpen && (
        <RealEstateForm
          method={"POST"}
          onCheckout={() => setCreateRealEstateOpen(false)}
        />
      )}

      {infoTraderOpen && (
        <Modal
          title="Lägg till fastighet"
          closeFunction={() => setInfoTraderOpen(false)}
        >
          <InfoTraderBox
            setRenderForm={() => {
              setInfoTraderOpen(false);
              setCreateRealEstateOpen(true);
            }}
          />
        </Modal>
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Fastigheter`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
