import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
} from "../../Details/OverviewInfo/styles";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
} from "../../sharedStyles";
import * as SC from "./styles";

export default function InvoiceNotFound({ goBack }) {
  return (
    <DetailLayoutWrapper>
      <DetailInnerWrapper>
        <DetailPageBox>
          <div
            style={{
              height: 200,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <SC.NotFoundIcon />
            <OverviewTitle small>Fakturan kunde inte hittas</OverviewTitle>
            <OverviewSubtitle style={{ margin: "8px auto 32px auto" }}>
              Detta kan bero på att fakturan har slagits ihop med en annan
              faktura eller att den har tagits bort
            </OverviewSubtitle>
            <PrimaryButton title="Gå till fakturaöversikt" clicked={goBack} />
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </DetailLayoutWrapper>
  );
}
