import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
export default () => [
  {
    Header: "Färg",
    accessor: "color_code",
    Cell: ({ value }) => (
      <div
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          backgroundColor: value || "#f1f1f1",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      ></div>
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Namn",
    accessor: "name",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
