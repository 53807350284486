const defs = [
  {
    title: "Avisera tillsammans med huvudavtal",
    key: "BILL_WITH_MAIN_CONTRACT",
    visited: true,
    hasError: false,
    fieldKeys: ["bill_with_lease"],
  },
  {
    title: "Gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [""],
  },
];

export default () => {
  return defs;
};
