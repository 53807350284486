import * as React from "react";
import { getCurrentProductTypeUsage } from "../../TechnicalManagement/utils";

// style, design

export default () => {
  return [
    {
      Header: "Komponent",
      id: "comp",
      Cell: ({ row }) => {
        const productTypeUsage = getCurrentProductTypeUsage(row.original);

        return (
          <div>
            {productTypeUsage?.product_type?.str_representation ||
              "Saknar komponentmodell"}
          </div>
        );
      },
    },
  ];
};
