import * as React from "react";

import { TextButton } from "../../Forms/Base/Buttons";
import SideModal from "../../Modals/SideModal";
import { Pill, PillsWrapper } from "../../sharedStyles";
import * as SC from "./styles";
import { useHistory } from "react-router-dom";
import useKeyPress from "../../../hooks/useKeyPress";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import AdInfo from "./AdInfo";
import { detailUrl as apartmentDetailUrl } from "../../../store/marketApartmentAds";
import { AD_KINDS } from "./AdPipe";
import AdLeadInfo from "./AdLeadInfo";

const getDetailUrl = (kind) => {
  if (kind === AD_KINDS.APARTMENT) return apartmentDetailUrl;
};

const PAGES = {
  INFO: 1,
  LEADS: 2,
};

export default function AdDetail({
  closeFunction,
  isOpen,
  onAdDetailOpened,
  onLeadDetailOpened,
  ads,
  ad = {},
  currentStage,
  isHomeQPipe,
}) {
  const detailUrl = getDetailUrl(ad.kind);

  const { push } = useHistory();
  const [page, setPage] = React.useState(PAGES.INFO);

  const [loading, setLoading] = React.useState(false);

  const leftPressed = useKeyPress("ArrowLeft");
  const rightPressed = useKeyPress("ArrowRight");

  const currentAdIndex = ads?.findIndex((a) => a.id === ad.id);
  const nextAd = ads?.[currentAdIndex + 1];
  const prevAd = ads?.[currentAdIndex - 1];

  React.useEffect(() => {
    if (leftPressed && !!prevAd) {
      onAdDetailOpened(prevAd.id);
    }
  }, [leftPressed]);
  React.useEffect(() => {
    if (rightPressed && !!nextAd) {
      onAdDetailOpened(nextAd.id);
    }
  }, [rightPressed]);

  return (
    <>
      <SideModal
        {...{
          isOpen,
          closeFunction,
          extraBackdropStyle: { zIndex: 100000000 },
        }}
      >
        {loading && <OverlaySpinner />}
        <SC.HeaderWrapper>
          <SC.StagesTitle>{currentStage?.name || ""}</SC.StagesTitle>
          <SC.LeadDetailHeader>
            {prevAd ? (
              <TextButton
                title={prevAd?.str_representation}
                iconType="arrow-back"
                clicked={() => onAdDetailOpened(prevAd.id)}
              />
            ) : (
              <div></div>
            )}

            <SC.LeadHeader style={{ border: "none" }}>
              <SC.LeadDataWrapper>
                <SC.LeadName style={{ fontWeight: 600, fontSize: 22 }}>
                  {ad.str_representation}
                </SC.LeadName>

                <SC.LeadName
                  style={{
                    fontSize: 12,
                    lineHeight: "100%",
                    marginBottom: 0,
                  }}
                >
                  Publicering: {ad.date_publish}
                </SC.LeadName>
                <SC.LeadName
                  style={{
                    fontSize: 12,
                    lineHeight: "100%",
                    marginBottom: 0,
                  }}
                >
                  Planerad inflytt: {ad.access_date}
                </SC.LeadName>
                <SC.LeadName
                  style={{
                    fontSize: 12,
                    lineHeight: "100%",
                    marginBottom: 0,
                  }}
                >
                  <TextButton
                    extraStyle={{ marginTop: 4 }}
                    title={`Gå till annons ${isHomeQPipe ? "i Pigello" : ""}`}
                    iconType="arrow"
                    iconPlacement="right"
                    clicked={() => push(detailUrl({ id: ad?.id }))}
                  />
                </SC.LeadName>
              </SC.LeadDataWrapper>
            </SC.LeadHeader>

            {nextAd ? (
              <TextButton
                title={nextAd?.str_representation}
                iconType="arrow"
                iconPlacement="right"
                extraStyle={{ marginRight: 0 }}
                clicked={() => onAdDetailOpened(nextAd.id)}
              />
            ) : (
              <div></div>
            )}
          </SC.LeadDetailHeader>

          <PillsWrapper
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "0 8px",
            }}
          >
            <Pill
              onClick={() => setPage(PAGES.INFO)}
              active={page === PAGES.INFO}
            >
              Info
            </Pill>

            <Pill
              onClick={() => setPage(PAGES.LEADS)}
              active={page === PAGES.LEADS}
            >
              Leads
            </Pill>
          </PillsWrapper>
        </SC.HeaderWrapper>

        <SC.InnerLeadDetailContent>
          {page === PAGES.INFO && <AdInfo ad={ad} isHomeQPipe={isHomeQPipe} />}
          {page === PAGES.LEADS && !isHomeQPipe && (
            <AdLeadInfo ad={ad} onLeadDetailOpened={onLeadDetailOpened} />
          )}
        </SC.InnerLeadDetailContent>
      </SideModal>
    </>
  );
}
