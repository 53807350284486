import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "./Details/OverviewInfo/styles";
import { DetailInnerWrapper, DetailPageBox, InnerBox } from "./sharedStyles";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });

    this.setState({ ...this.state, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <DetailInnerWrapper>
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>
                  Något gick fel med denna widget
                </OverviewTitle>
                <OverviewSubtitle>
                  Kontakta Pigello om problemet kvarstår.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <InnerBox>{JSON.stringify(this.state.errorInfo)}</InnerBox>
          </DetailPageBox>
        </DetailInnerWrapper>
      );
    }

    return this.props.children;
  }
}
