import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);
  const { type, payload } = action;

  switch (type) {
    case constants.GET_VALUE_FOR_WIDGET_PLACEMENT: {
      return {
        ...newState,
        resultData: payload,
      };
    }

    case constants.CLEAR_VALUES: {
      return { ...INITIAL_STATE, resultData: {} };
    }
    
    case constants.CLEAR_SPECIFIC_VALUES: {
      const liResultData = Object.keys(newState.resultData)
       for (let key of liResultData) {
       try {
       let _key = parseInt(key)
       if (payload.includes(_key) ) {
        delete newState.resultData[parseInt(_key)]
        if (newState["all"][_key]) delete newState.all[_key]
       }
       } catch (err) {
        console.log("Invalid key when clearing values")
       }
       }
      return newState;
    }    
    
    // case constants.CLEAR_REALESTATE_IDS: {
    //   const liResultData = Object.keys(newState.resultData)
    //    for (let key of liResultData) {
    //    try {
    //    if (newState.resultData[key]?.realestate_ids ) {
    //     delete newState.resultData[parseInt(key)].realestate_ids
    //     if (newState["all"][key]) delete newState.all[key]
    //    }
    //    } catch (err) {
    //     console.log("Invalid key when clearing values")
    //    }
    //    }
    //   return newState;
    // }

    case constants.SET_VALUE_FOR_WIDGET_PLACEMENT: {
      const newResultData = { ...state.resultData, [payload.id]: payload.data };
      return {
        ...newState,
        resultData: newResultData,
      };
    }

    case constants.SET_DELETE_QUEUE_WIDGET_PLACEMENT: {
      return {
        ...newState,
        deleteQueue: { [payload.id]: true}
      }
    }

    case constants.CLEAR_DELETE_QUEUE_WIDGET_PLACEMENT: {
      const newDeleteQueue = { ...state.deleteQueue }
      delete newDeleteQueue[payload.id]
      return {
        ...newState,
        deleteQueue: { newDeleteQueue }
      }
    }

    default:
      return newState;
  }
};
