import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import * as SharedStyles from "../../../components/sharedStyles";

import DebtSettingsTable from "../../../components/Tables/InvoicingDebtSettings/FullTable";

import InvoicingDebtSettingModalForm from "../../../components/Forms/InvoicingDebtSetting/ChapterForm/ModalForm";

import PageTitle from "../../Layouts/PageTitle/PageTitle";

const breadCrumbs = [
  {
    label: "Betalningsvillkor",
  },
];

export default function InvoicingSettings(props) {
  const [createDebtHandlingSettingOpen, setCreateDebtHandlingSettingOpen] =
    React.useState(false);

  const ACTION = (
    <div style={{ marginTop: "20px" }}>
      <PrimaryButton
        newDesign
        clicked={() => setCreateDebtHandlingSettingOpen(true)}
        title="Lägg till ny inställning för betalningsvillkor"
      ></PrimaryButton>
    </div>
  );
  return (
    <>
      <InvoicingDebtSettingModalForm
        method="POST"
        isOpen={createDebtHandlingSettingOpen}
        onCheckout={() => setCreateDebtHandlingSettingOpen(false)}
      />

      <SharedStyles.DetailInnerWrapper>
        <SharedStyles.BareLayoutTitleWrapper>
          <PageTitle
            title="Inställningar - betalningsvillkor"
            breadCrumbs={breadCrumbs}
          />
        </SharedStyles.BareLayoutTitleWrapper>

        <SharedStyles.DetailPageBox config={true}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Betalningsvillkor</OverviewTitle>
              <OverviewSubtitle>{ACTION}</OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <DebtSettingsTable />
        </SharedStyles.DetailPageBox>
      </SharedStyles.DetailInnerWrapper>
    </>
  );
}
