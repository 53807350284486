import styled, { css } from "styled-components";

export const DetailPermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: ${(p) => p.theme.borders.dark};
  border-radius: 12px;
`;

export const DetailPermItemWrapper = styled.div`
  display: flex;
  border-bottom: ${(p) => p.theme.borders.dark};
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.blueLight};
  }
  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${(p) => p.theme.colors.gray1};
      &:hover {
        background-color: ${(p) => p.theme.colors.gray1};
      }
    `}

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

export const DetainPermItem = styled.div`
  padding-left: 8px;
  font-size: ${(p) => p.theme.fontSizes.data};
`;
