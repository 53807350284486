import * as React from "react";

// style, design
import { LinkedObject, ToolTipCell } from "../../Displays";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as industrialDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as commonDetailUrl } from "../../../store/commonAreas";

export default (canParakey) => {
  let res = [
    {
      Header: "Gemensam Yta",
      accessor: "common_area",
      Cell: ({ value, row }) => {
        return <LinkedObject obj={value} urlMethod={commonDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Lägenhet",
      accessor: "apartment",
      Cell: ({ value, row }) => {
        return <LinkedObject obj={value} urlMethod={apartmentDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Lokal",
      accessor: "industrial_premises",
      Cell: ({ value, row }) => {
        return <LinkedObject obj={value} urlMethod={industrialDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  if (canParakey) {
    res.splice(0, 0, {
      Header: "Parakey ID",
      accessor: "parakey_id",
      Cell: ({ value, row }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    });
  }

  return res;
};
