import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";

import {
  useInspectionErrandForm,
  destroyPatchForm,
  constants,
  update,
} from "../../../../store/inspectionErrands";
import { cloneDeep } from "lodash";
import { updateActiveFormInstance } from "../../../../store/base";

export default function InspectionErrandPlanForm({
  id,
  isOpen,
  onCheckout,
  instance,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useInspectionErrandForm("PATCH", id));

  React.useEffect(() => {
    if (instance && isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, [isOpen, instance]);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));
    onCheckout(success);
  };

  const onSuccess = () => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
        preProcess,
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs()}
      onRenderChapter={(key) => renderChapter()}
      submitCallback={onSubmit}
      title={"Uppdatera planering"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
