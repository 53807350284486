const defs = [
  {
    title: "Kostnader & Rabatter",
    key: "COSTS",
    visited: true,
    hasError: false,
    fieldKeys: ["cost_set", "vat"],
  },
  {
    title: "Schemaläggningsinställningar",
    key: "INTERVAL",
    visited: false,
    hasError: false,
    fieldKeys: ["interval_setting"],
  },
  {
    title: "Spara och gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [""],
  },
];

export default ({} = {}) => {
  return defs;
};
