import baseReducer from "../../base/store/reducer";
import constants from "./constants";
import { INITIAL_STATE } from "../../base/store/reducer";
import { cloneDeep, template } from "lodash";
import { buildQueryString } from "../../base";

//??
const INITIAL_STATE_ = {
  ...INITIAL_STATE,
  allAvailableReports: {},
  currentReportStructure: {},
  currentReportData: {},
  selectedReportTab: "all_reports",
  customPagination: {},
  badgeFilterCounts: {},
  templatePathing: {},
};

export default (state = INITIAL_STATE_, action) => {
  const newState = baseReducer(state, action, constants);
  const { type, payload } = action;

  switch (type) {
    case constants.PURGE_TEMPLATE_PATHING:
      if (payload !== undefined) {
        if (newState.templatePathing.hasOwnProperty(payload)) {
          let newTemplatePathing = newState.templatePathing;

          delete newTemplatePathing[payload];

          return {
            ...newState,
            templatePathing: newTemplatePathing,
          };
        }
        //if the querystring (payload) isnt found, dont do anything.
        return newState;
      }

      return {
        ...newState,
        templatePathing: {},
      };

    case constants.SET_TEMPLATE_PATHING:
      return {
        ...newState,
        templatePathing: payload,
      };

    case constants.SET_BADGE_FILTER_COUNTS:
      return {
        ...newState,
        badgeFilterCounts: payload,
      };

    case constants.SET_HOT_REFRESH:
      return {
        ...newState,
        hotRefresh: payload,
      };

    case constants.REMOVE_REPORT_IN_ALL:
      let newAll = newState.all;
      let report = payload.report;

      if (newAll.hasOwnProperty(report.id)) {
        delete newAll[report.id];
      }

      let query = payload.query;

      let paginationQueryString = cloneDeep(query);
      delete paginationQueryString.path_id____;
      paginationQueryString = buildQueryString(paginationQueryString);

      let templatePathingQueryString = cloneDeep(query);
      delete templatePathingQueryString._page;
      templatePathingQueryString = buildQueryString(templatePathingQueryString);

      let newPaginations = newState.paginations;
      let newTemplatePathing = newState.templatePathing;

      //lower count with one because it was just deleted.
      if (paginationQueryString && paginationQueryString.length !== 0) {
        if (newPaginations.hasOwnProperty(paginationQueryString)) {
          newPaginations[paginationQueryString].count -= 1;
        }
      }

      if (
        templatePathingQueryString &&
        templatePathingQueryString.length !== 0
      ) {
        if (newTemplatePathing.hasOwnProperty(templatePathingQueryString)) {
          newTemplatePathing[templatePathingQueryString].totalCount -= 1;
        }
      }

      return {
        ...newState,
        all: newAll,
        paginations: newPaginations,
        paginationsCopy: newPaginations,
        templatePathing: newTemplatePathing,
      };

    case constants.UPDATE_REPORT_IN_ALL:
      return {
        ...newState,
        all: {
          ...newState.all,
          [payload.id]: payload,
        },
      };

    case constants.SET_AVAILABLE_REPORTS:
      return {
        ...newState,
        allAvailableReports: payload.allAvailableReports,
      };

    case constants.SET_CURRENT_REPORT_DATA:
      return {
        ...newState,
        currentReportData: payload,
      };

    case constants.SET_CURRENT_REPORT_STRUCTURE:
      return {
        ...newState,
        currentReportStructure: payload,
      };

    case constants.SET_SELECTED_REPORT_TAB:
      return {
        ...newState,
        selectedReportTab: payload,
      };

    case constants.INSERT_CUSTOM_REPORTS:
      let obj = {};

      for (let item of payload.results) {
        obj[item.id] = item;
      }

      return {
        ...newState,
        all: obj,
        customPagination: {
          ...payload,
        },
      };

    case constants.PURGE_FORM_ERRORS:
      return {
        ...newState,
        formErrors: {},
      };

    case constants.PURGE_ALL:
      return {
        ...newState,
        all: {},
        formErrors: {},
        paginations: {},
        paginationsCopy: {},
        badgeFilterCounts: {},
        templatePathing: {},
      };

    default:
      return newState;
  }
};
