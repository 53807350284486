import styled from "styled-components";

export const NoBuildingsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  background-color: #fff;
`;

export const TitleIcon = styled.div`
  height: 60px;
  width: 60px;
  background-image: url(${(p) => p.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Title = styled.div`
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.textPrimaryLight};
  margin: 24px 0;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 300;
  letter-spacing: -0.04em;
`;

export const Subtitle = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  color: ${(p) => p.theme.colors.textPrimaryLight};
  line-height: 1.5rem;
  text-align: center;
`;
