import { useDispatch, useSelector } from "react-redux";
import { useInProgress } from "../../base";
import { constants } from "../index";

import { getAccountsReceivableForDate } from "../store/actions";

export const useAccountsReceivableForDate = ({
  date,
  creditorId,
  excludeInvoicesWithPeriodOutsideDate,
}) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const processName = `${date}-${creditorId}`;

  const invoices = useSelector(
    (state) =>
      state[storeName][
        `${creditorId}-${date}-${excludeInvoicesWithPeriodOutsideDate}`
      ]
  );

  const inProgress = useInProgress({ storeName, name: processName });

  if (!date || !creditorId) return [undefined, false];
  if (inProgress) return [undefined, true];

  // check if current search is the one we want, if not, we fetch it
  if (!invoices) {
    dispatch(
      getAccountsReceivableForDate({
        creditorId,
        date,
        processName,
        excludeInvoicesWithPeriodOutsideDate,
      })
    );
    return [undefined, true];
  }

  return [invoices, false];
};
