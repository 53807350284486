import styled, { css } from "styled-components";

export const StatusLabel = styled.div`
  align-self: flex-start;
  padding: 2px 6px;
  font-weight: 600;
  font-size: ${(p) => (p.large ? p.theme.fontSizes.headerSmall : "0.8rem")};
  border-radius: 3px;
  color: ${(p) => p.theme.colors.primary};
  display: inline-flex;

  ${(p) =>
    p.status === 0 &&
    css`
      background-color: #e4f1ff;
      color: #499dff;
    `}
  ${(p) =>
    p.status === 1 &&
    css`
      background-color: #e4fbef;
      color: #2ec177;
    `}
    ${(p) =>
    p.status === 2 &&
    css`
      background-color: #f9fbfd;
      color: #979fae;
    `}
    ${(p) =>
    p.status === 3 &&
    css`
      background-color: #fddddd;
      color: #ea5050;
    `}
    ${(p) =>
    p.status === 4 &&
    css`
      background-color: #f2ebff;
      color: #9258f0;
    `}
    ${(p) =>
    p.status === 6 &&
    css`
      background-color: #fddddd;
      color: #ea5050;
    `}
`;
