import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { cloneDeep } from "lodash";
import { updateActiveFormInstance } from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TextInputField from "../../Base/Fields/TextInputField";

import TextAreaField from "../../Base/Fields/TextAreaField";
import {
  useRoundingErrandFaultsForm,
  create,
  update,
  destroyPatchForm,
  destroyPostForm,
  constants,
} from "src/store/roundingErrandFaults";

export default function RoundingFaultModalForm({
  method,
  id,
  onCheckout,
  instance,
  customSuccessHandling,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);
  const [intervalType, setIntervalType] = React.useState(null);

  const formLoaded = Boolean(useRoundingErrandFaultsForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }

    return () => {
      dispatch(destroyPostForm());
      dispatch(destroyPatchForm());
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    onCheckout(success);
  };

  const onSuccess = (data, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "PATCH") {
      dispatch(updateActiveFormInstance({ storeName, data: returned }));
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: customSuccessHandling || onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: customSuccessHandling || onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={
        method === "POST"
          ? "Skapa ronderingsavvikelse"
          : "Uppdatera ronderingsavvikelse"
      }
      closeFunction={onDone}
      onAccept={onSubmit}
    >
      {(!formLoaded || loading) && <OverlaySpinner />}

      <div className="mb-6 text-base font-medium">Avvikelse</div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <TextInputField
          {...{
            method,
            storeName,
            fieldKey: "title",
            title: "Titel",
          }}
        />

        <TextAreaField
          {...{
            method,
            storeName,
            fieldKey: "description",
            title: "Beskrivning",
          }}
        />
      </div>

      {/* <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField  {...{
          method,
          storeName,
          fieldKey: "component_placement",
          title: "Komponent",
          TableComponent: ErrandComponentPlacementTable
        }} />
      </div> */}
    </Modal>
  );
}
