import * as React from "react";
import { PrimaryButton, SecondaryButton } from "../Forms/Base/Buttons";
import * as SC from "./styles";

export default ({
  disabled,
  primaryActionTitle,
  primaryAction,
  noInviteActionTitle,
  noInviteAction,
  cancelActionTitle,
  cancelAction,
}) => {
  return (
    <SC.BottomBarWrapper>
      <SC.BottomBarInnerWrapper>
        {cancelActionTitle && (
          <SecondaryButton
            title={cancelActionTitle}
            clicked={cancelAction}
            extraStyle={{ marginRight: "12px" }}
          />
        )}
        {noInviteActionTitle && (
          <PrimaryButton
            title={noInviteActionTitle}
            disabled={disabled}
            clicked={noInviteAction}
            extraStyle={{
              backgroundColor: "#ffBA33",
              color: "black",
              marginRight: "12px",
            }}
          />
        )}
        <PrimaryButton
          title={primaryActionTitle}
          disabled={disabled}
          clicked={primaryAction}
        />
      </SC.BottomBarInnerWrapper>
    </SC.BottomBarWrapper>
  );
};
