export const formatSearchResults = (data) => {
  const formatted = {};

  const { hits } = data;

  hits.forEach((hit) => {
    const contentType = hit._index.split("-")[1];

    if (formatted[contentType]) {
      formatted[contentType].push(hit);
    } else {
      formatted[contentType] = [hit];
    }
  });

  return formatted;
};
