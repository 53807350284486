import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useNote } from "../../../../../store/notes";
import { constants } from "../../../../../store/reportErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { TextField, TextInput } from "../../../Base/Fields";
import ManyMediaField from "../../../Base/Fields/ManyMediaField";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const description = useFormInstanceField({
    storeName,
    fieldKey: "report_comments[0]",
  });

  const [actualDescription] = useNote(description?.id);

  React.useEffect(() => {
    if (method !== "PATCH" || description?.content || !actualDescription)
      return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`report_comments[0]`]: actualDescription,
        },
      })
    );
  }, [description, method]);

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Beskrivning av fel</OverviewTitle>
          <OverviewSubtitle>
            Beskrivning av ärende samt bilder som underlättar för utföraren att
            avhjälpa felet
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        fieldKey={"title"}
        method={method}
        title="Titel"
      />

      <TextField
        storeName={storeName}
        fieldKey={`report_comments[0].content`}
        instructionsKey={"report_comments.content"}
        title="Beskrivning"
        method={method}
      />

      <ManyMediaField
        storeName={storeName}
        fieldKey={"report_files"}
        method={method}
        title="Bilder på felet"
        fileKey={"file"}
        allowedFormats={[".png", ".jpg", ".jpeg"]}
      />
    </>
  );
};
