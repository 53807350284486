import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  constants,
  useIndustrialPremises,
} from "../../store/industrialPremises";
import IndustrialPremisesOverview from "./detail/Overview";
import IndustrialPremisesImagesAndBlueprints from "./detail/ImagesAndBlueprints";
import IndustrialPremisesContracts from "./detail/Contracts";
import IndustrialPremisesIMD from "./detail/IMD";
import IndustrialPremisesKeys from "./detail/Keys";

import { useAllPermissionCheck, usePermissionCheck } from "../../store/base";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import IndustrialPremisesMarket from "./detail/Market";
import { useExternalSyncErrorPaginationCount } from "../../store/externalSyncErrors";
import { SYNC_ERROR_MAP } from "../../components/Tables/ExternalSyncErrors/FullTable";
import ObjectCosts from "../../components/Details/OverviewInfo/Costs/ObjectCosts";
import TFPremisesBase from "../../components/TechnicalManagement/PremisesBase";

export default function MainIndustrialPremisesDetail() {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["view_can_baseobject"]);

  const canDelete = usePermissionCheck("delete_can_baseobject");
  const canErrands = useAllPermissionCheck([
    "allow_errand_faults",
    "view_can_reporterrand",
  ]);
  const canMarket = useAllPermissionCheck(["allow_market", "view_can_market"]);
  const canImd = useAllPermissionCheck(["allow_imd", "view_can_imd"]);

  const canKeys = useAllPermissionCheck(["allow_keys", "view_can_keys"]);

  const { industrialPremisesId } = useParams();

  const [industrialPremises] = useIndustrialPremises(industrialPremisesId);

  const [marketErrorCount] = useExternalSyncErrorPaginationCount({
    filters: {
      api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
      industrialpremises: industrialPremisesId,
    },
  });

  const hasMarketError = marketErrorCount > 0;

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Objekt",
      component: IndustrialPremisesOverview,
    },
    {
      path: "/costs",
      label: "Debiteringsrader",
      component: ObjectCosts,
    },
    {
      path: "/images-and-blueprint",
      label: "Bilder & ritning",
      component: IndustrialPremisesImagesAndBlueprints,
    },
    {
      path: "/contracts",
      label: "Avtal",
      component: IndustrialPremisesContracts,
    },
  ];

  if (canErrands) {
    SUB_PAGES.push({
      path: "/errands",
      label: "Teknisk förvaltning",
      component: TFPremisesBase,
    });
  }

  if (canMarket) {
    SUB_PAGES.push({
      path: "/market",
      label: "Marknad",
      component: IndustrialPremisesMarket,
      hasError: hasMarketError,
    });
  }

  if (canImd) {
    SUB_PAGES.push({
      path: "/imd",
      label: "IMD",
      component: IndustrialPremisesIMD,
    });
  }

  if (canKeys) {
    SUB_PAGES.push({
      path: "/keys",
      label: "Nycklar",
      component: IndustrialPremisesKeys,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      isDelete: true,
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel contentType="premises" state={industrialPremises?.state}>
          {industrialPremises?.state_display}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      {canDelete && (
        <DeleteModal
          isOpen={deleteModalOpen}
          closeFunction={() => setDeleteModalOpen(false)}
          constants={constants}
          instance={industrialPremises}
          deletedCallback={() => push("/industrial-premises")}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Lokal ${
            industrialPremises?.str_representation || "laddar..."
          }`}
          breadCrumbs={[
            { url: "/industrial-premises", label: "Lokaler" },
            { label: industrialPremises?.str_representation || "Laddar..." },
          ]}
          eventContentType="standard.industrialpremises"
          eventObjectId={industrialPremisesId}
          eventIdQueryStr="industrialpremises"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
