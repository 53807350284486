import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default () => {
  const cols = [
    {
      Header: "Synkstatus",
      accessor: (row) => (!!row.homeq_id ? "Synkad" : "Ej synkad"),
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value === "Synkad" ? 0 : 3}>
            {value === "Synkad" ? "Synkad" : "Ej synkad"}
          </StatusLabel>
        );
      },
      Filter: SimpleTextFilter,
      filter: "textExact",
    },
    {
      Header: "Titel",
      accessor: "name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
