import { useDispatch, useSelector } from "react-redux";
import {
  useObject,
  useObjects,
  usePagination,
  useFilteredObjects,
  buildQueryString,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import {
  getAll,
  performFilter,
  filterPagination,
  getSingle,
  generateBrokerDoc,
} from "../store/actions";
import constants from "../store/constants";
import { useInProgress } from "../../base";

export function useBrfPremises(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useBrfPremisesList(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredBrfPremises(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useBrfPremisesPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useBrfPremisesPaginationCount({ filters }) {
  const queryString = buildQueryString({
    _page: 1,
    _page_size: 0,
    ...filters,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}

export function useBrokerDoc(brfPremisesId) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const attrName = `broker_doc_${brfPremisesId}`;

  const brokerDoc = useSelector((state) => state[storeName][attrName]);

  const inProgress = useInProgress({
    storeName,
    name: attrName,
  });

  if (!brfPremisesId) return [undefined, false];

  if (inProgress) return [undefined, true];

  if (!brokerDoc && !inProgress) {
    dispatch(generateBrokerDoc({ brfPremisesId, attrName }));
    return [undefined, true];
  }

  return [brokerDoc, false];
}
