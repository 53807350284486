import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";

import { useParams } from "react-router";
import { useLeaseContract } from "../../../store/leaseContracts";
import { useFilteredSensors } from "../../../store/IMDSensor";
import { useFilteredMeasureValues } from "../../../store/IMDMeasureValue";
import SensorGraphs from "../../IMD/SensorGraphs";
import { buildQueryString } from "../../../store/base";
import moment from "moment";
export default function LeaseIMD() {
  const { leaseContractId } = useParams();
  const [leaseContract] = useLeaseContract(leaseContractId);

  const apartmentIds = leaseContract?.apartments?.map((a) => a.id);
  const indpIds = leaseContract?.industrial_premises_list?.map((a) => a.id);

  const [apartmentSensors, apartmentSensorsLoading] = useFilteredSensors(
    buildQueryString({ apartment__in: apartmentIds || [] })
  );
  const [indpSensors, indpSensorsLoading] = useFilteredSensors(
    buildQueryString({ industrial_premises__in: indpIds || [] })
  );

  const startDay = moment().subtract(90, "days");
  const [apartmentMeasureValues, apartmentMeasuresLoading] =
    useFilteredMeasureValues(
      apartmentSensorsLoading || !apartmentSensors
        ? null
        : buildQueryString({
            sensor__in: apartmentSensors.map((v) => v.id),
            for_date__gte: `${startDay.format("YYYY-MM-DD")} 00:00:00`,
          })
    );
  const [indpMeasureValues, indpMeasuresLoading] = useFilteredMeasureValues(
    indpSensorsLoading || !indpSensors
      ? null
      : buildQueryString({
          sensor__in: indpSensors.map((v) => v.id),
          for_date__gte: `${startDay.format("YYYY-MM-DD")} 00:00:00`,
        })
  );

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>IMD-konsumtion på objekt under avtal</OverviewTitle>
        </OverviewTitleWrapper>

        {!apartmentSensors?.length > 0 && !indpSensors?.length > 0 && (
          <InnerBox>
            Inga IMD-sensorer kopplade till objekt under detta avtal
          </InnerBox>
        )}

        {apartmentSensors?.length > 0 && (
          <SensorGraphs
            sensors={apartmentSensors}
            measureValues={apartmentMeasureValues}
          />
        )}

        {indpSensors?.length > 0 && (
          <SensorGraphs
            sensors={indpSensors}
            measureValues={indpMeasureValues}
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
