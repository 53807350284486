import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

export async function fetchContractProposal(apartmentId) {
  const data = await axiosInstance.get(
    `${constants.PROPOSAL_URL}${apartmentId}/`
  );

  return data;
}
