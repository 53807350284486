import * as React from "react";
// style, design
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { AddressField, Select, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingDebtor";
import { useParams } from "react-router-dom";
import { InnerBox } from "../../../../sharedStyles";
import { TextButton } from "../../../Base/Buttons";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

export default ({ method, addressProposals }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const setInvoiceAddress = (address) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          invoice_address: address,
        },
      })
    );
  };

  const currentInvoiceAddress = useFormInstanceField({
    storeName,
    fieldKey: "invoice_address",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Faktureringsinformation</OverviewTitle>
          <OverviewSubtitle>Detaljer kring mottagarens adress</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {addressProposals?.length > 0 && currentInvoiceAddress == null && (
        <InnerBox style={{ marginBottom: 8 }}>
          <OverviewTitleWrapper style={{ marginBottom: 8 }}>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Föreslagna adresser</OverviewTitle>
              <OverviewSubtitle>
                Kicka på en adress för att använda som fakturaadress
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          {addressProposals.map((ap) => (
            <div key={ap.id}>
              <TextButton
                title={ap.str_representation}
                iconType="add"
                clicked={() => setInvoiceAddress(ap)}
              />
            </div>
          ))}
        </InnerBox>
      )}

      <AddressField
        title="Fakturaadress"
        {...{
          storeName,
          method,
          fieldKey: `invoice_address`,
        }}
      />

      <TextInput
        title="Fakturaadress c/o"
        {...{
          storeName,
          method,
          fieldKey: `care_of`,
        }}
      />

      <TextInput
        title="Adress 2"
        description="Fältet syns under adressen på avier/fakturor. Om kunden kräver specifik märkning redan i adressen är det bra att ange den här."
        {...{
          storeName,
          method,
          fieldKey: `invoice_address_extra`,
        }}
      />
    </>
  );
};
