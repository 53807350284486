export const detailUrl = ({ id }) => {
  return `/parking-specifications/detail/${id}`;
};
export const overviewUrl = () => {
  return `/parking/parking-specifications`;
};

export const constructStrRep = (obj) => {
  return obj?.title || "Parkeringsspecifikation";
};
