import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useKeyPermissionForm,
  constants,
  create,
  update,
  destroyPostForm,
  destroyPatchForm,
  detailUrl,
  useKeyPermission,
} from "../../../../store/keypermission";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import { setActiveFormInstance, updateActiveFormInstance } from "../../../../store/base";
import { useApartment, detailUrl as apartmentDetailUrl } from "../../../../store/apartments";
import { useIndustrialPremises, detailUrl as industrialDetailUrl } from "../../../../store/industrialPremises";
import { useCommonArea, detailUrl as commonDetailUrl } from "../../../../store/commonAreas";

export default function KeyPermissionForm({ method = "POST", id, apartment, industrialPremises, commonArea }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useKeyPermissionForm(method, id));
  const { replace } = useHistory();

  const [instance, instanceLoading] = useKeyPermission(id);
  const [hasSetInstance, setHasSetInstance] = React.useState(false);

  const [hasSetDefaultObject, setHasSetDefaultObject] = React.useState(false);
  const [apartmentDefault, apartmentDefaultLoading] = useApartment(apartment);
  const [industrialDefault, industrialDefaultLoading] = useIndustrialPremises(industrialPremises);
  const [commonDefault, commonDefaultLoading] = useCommonArea(commonArea);

  React.useEffect(() => {
    if (!hasSetInstance && instance != null) {
      dispatch(setActiveFormInstance({
        storeName: storeName,
        data: instance
      }));

      setHasSetInstance(true);
    }
  }, [hasSetInstance, instance])

  React.useEffect(() => {
    if (id != null) { return; }

    const obj = apartmentDefault != null ? {"apartments":[apartmentDefault], "title":apartmentDefault.str_representation} : 
                industrialDefault != null ? {"industrial_premises":[industrialDefault], "title":industrialDefault.str_representation} : 
                commonDefault != null ? {"common_areas":[commonDefault], "title":commonDefault.str_representation} :
                null

    if (!hasSetDefaultObject && obj != null) {
      dispatch(updateActiveFormInstance({
        storeName: storeName,
        data: obj
      }));

      setHasSetDefaultObject(true);
    }
  }, [hasSetDefaultObject, apartmentDefault, industrialDefault, commonDefault])

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    if (id != null) {
      replace(detailUrl({ id: returned.id }));
      return
    }

    if (apartmentDefault) {
      replace(apartmentDetailUrl({id: apartmentDefault.id}) + "/keys")
    } else if (industrialDefault) {
      replace(industrialDetailUrl({id: industrialDefault.id}) + "/keys")
    } else if (commonDefault) {
      replace(commonDetailUrl({id: commonDefault.id}) + "/keys")
    } else {
      replace(detailUrl({ id: returned.id }));
    }
  };

  React.useEffect(() => {
    return () => {
      if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      } else {
        dispatch(destroyPostForm(false));
      }
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    } else {
      dispatch(destroyPostForm(success));
    }
  };

  const onSubmit = () => {
    setLoading(true);

    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: onSuccess,
          errorCallback: onError,
          id,
        })
      );
    } else {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: onError,
        })
      );
    }
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,

          descriptions,
          method,
          onSubmit,

          title:
            method === "POST" ? "Lägg till nyckelbehörighet" : "Uppdatera nyckelbehörighet",
        }}
      />
    </>
  );
}
