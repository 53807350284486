import * as React from "react";

import { Confirm, Credit, Income, Other, SpecialApartment } from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "INCOME":
      return <Income method={method} />;
    case "SPECIAL":
      return <SpecialApartment method={method} />;
    case "CREDIT":
      return <Credit method={method} />;
    case "OTHER":
      return <Other method={method} />;
    default:
      return <Confirm method={method} />;
  }
};
