import * as React from "react";

import {
  NumberInput,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/paymentContribution";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <NumberInput
      title="Lån - Kapitaltillskott"
      description="Belopp som avsett ett lån och får ligga till grund för kapitaltillskott"
      storeName={storeName}
      method={method}
      fieldKey={"loan_capital"}
      />
      <NumberInput
      title="Lån - Ränteavgift"
      description="Belopp som avsett ränteavgiften för ett lån (ej avgift för låst ränta)"
      storeName={storeName}
      method={method}
      fieldKey={"loan_interest"}
      />
      <NumberInput
      title="Lån - Låst ränta, avgift"
      description="Belopp som avsett avgiften för en låst ränta"
      storeName={storeName}
      method={method}
      fieldKey={"loan_firm_interest_fee"}
      />
      <NumberInput
      title="Lån - Ej kapitaltillskott"
      description="Belopp som avsett ett lån men som inte får ligga till grund för kapitaltilsskott"
      storeName={storeName}
      method={method}
      fieldKey={"loan_non_capital"}
      />
      <NumberInput
      title="Ej Lån"
      description="Belopp som ej avsett ett lån"
      storeName={storeName}
      method={method}
      fieldKey={"non_loan"}
      />
    </>
  );
};
