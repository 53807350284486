import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import RentIncreasesTable from "src/components/Tables/RentIncreases/FullTable";

import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import moment from "moment";

const TABS = {
  PLANNED: "Planerade",
  APPLIED: "Genomförda",
};
export default function RentIncreases() {
  const [selectedTab, setSelectedTab] = React.useState("PLANNED");
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  if (selectedTab === "PLANNED")
    persistantQuery.apply_from__gt = moment().format("YYYY-MM-DD");
  else persistantQuery.apply_from__lte = moment().format("YYYY-MM-DD");

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Hyreshöjningar</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        <RentIncreasesTable persistantQuery={persistantQuery} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
