import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import TableSelectField from "../../Forms/Base/Fields/TableSelectField";
import Modal from "../../Forms/Base/Modals/Modal";

import {
  constants as userConstants,
  performFilter as userPerformFilter,
  useUserPagination,
} from "../../../store/users";
import userListDefs from "../../Tables/Users/listDefs";
import { cloneDeep } from "lodash";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import SelectField from "../../Forms/Base/Fields/SelectField";
import TextInputField from "../../Forms/Base/Fields/TextInputField";
import CheckField from "../../Forms/Base/Fields/CheckField";
import { useCanUseScrive } from "../../../store/editabledocs/hooks/retrieve";
import UsersTable from "src/components/Tables/Users/FullTable";

export default function HandleParty({
  idx,
  storeName,
  onDone,
  isTemplateEditor,
  usesESigning,
  method,
}) {
  const dispatch = useDispatch();

  const [canUseScrive] = useCanUseScrive();

  const parties = useFormInstanceField({ storeName, fieldKey: "parties" });
  const party = useFormInstanceField({
    storeName,
    fieldKey: `parties[${idx}]`,
  });

  const user = party?.user;
  const fields = party?.fields;

  const firstNameIndex = fields?.findIndex((f) => f.kind === "first_name");
  const lastNameIndex = fields?.findIndex((f) => f.kind === "last_name");
  const corporateNameIndex = fields?.findIndex((f) => f.kind === "company");
  const corporateLegalIdIndex = fields?.findIndex(
    (f) => f.kind === "company_number"
  );
  const emailIndex = fields?.findIndex((f) => f.kind === "email");
  const phoneIndex = fields?.findIndex((f) => f.kind === "phone");
  const legalIdIndex = fields?.findIndex((f) => f.kind === "personal_number");

  const firstNamePartyField = fields?.[firstNameIndex];
  const lastNamePartyField = fields?.[lastNameIndex];
  const emailPartyField = fields?.[emailIndex];
  const phonePartyField = fields?.[phoneIndex];
  const companyPartyField = fields?.[corporateNameIndex];
  const legalIdField = fields?.[legalIdIndex];

  const editPartySignMethod = party?.authentication_method_to_sign;
  const editPartyDeliveryMethod = party?.delivery_method;

  const hasUserPartyDifferences =
    (user?.first_name || null) != (firstNamePartyField?.value || null) ||
    (user?.legal_id || null) != (legalIdField?.value || null) ||
    (user?.last_name || null) != (lastNamePartyField?.value || null) ||
    (user?.phone || null) != (phonePartyField?.value || null) ||
    (user?.email || null) != (emailPartyField?.value || null) ||
    (user?.corporate_name || null) != (companyPartyField?.value || null);

  const handleSyncParty = () => {
    const partiesClone = cloneDeep(parties);

    partiesClone[idx].fields[firstNameIndex].value = user.first_name;
    partiesClone[idx].fields[lastNameIndex].value = user.last_name;
    partiesClone[idx].fields[emailIndex].value = user.email;
    partiesClone[idx].fields[phoneIndex].value = user.phone;
    partiesClone[idx].fields[legalIdIndex].value = user.legal_id;

    if (corporateNameIndex > 0) {
      partiesClone[idx].fields[corporateNameIndex].value = user.corporate_name;
    }
    if (corporateLegalIdIndex > 0) {
      partiesClone[idx].fields[corporateLegalIdIndex].value = user.legal_id;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setEmailObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!partiesClone[idx].fields[emailIndex]) return;

    partiesClone[idx].fields[emailIndex].is_obligatory = true;
    partiesClone[idx].fields[emailIndex].should_be_filled_by_sender = true;

    if (
      editPartySignMethod !== "sms_pin" &&
      !!partiesClone[idx]?.fields[phoneIndex]
    ) {
      partiesClone[idx].fields[phoneIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setPhoneObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!partiesClone[idx]?.fields[phoneIndex]) return;

    partiesClone[idx].fields[phoneIndex].is_obligatory = true;
    partiesClone[idx].fields[phoneIndex].should_be_filled_by_sender = true;

    if (
      editPartyDeliveryMethod !== "email" &&
      !!partiesClone[idx]?.fields?.[emailIndex]
    ) {
      partiesClone[idx].fields[emailIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setEmailAndPhoneObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!!partiesClone[idx].fields[phoneIndex]) {
      partiesClone[idx].fields[phoneIndex].is_obligatory = true;
      partiesClone[idx].fields[phoneIndex].should_be_filled_by_sender = true;
    }

    if (!!partiesClone[idx].fields[emailIndex]) {
      partiesClone[idx].fields[emailIndex].is_obligatory = true;
      partiesClone[idx].fields[emailIndex].should_be_filled_by_sender = true;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setPersonalNumberObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!!partiesClone[idx].fields[legalIdIndex]) {
      partiesClone[idx].fields[legalIdIndex].is_obligatory = true;
      partiesClone[idx].fields[legalIdIndex].should_be_filled_by_sender = true;
    }

    if (
      editPartyDeliveryMethod !== "mobile" &&
      !!partiesClone[idx].fields[phoneIndex]
    ) {
      partiesClone[idx].fields[phoneIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const clearPossibleObligatories = () => {
    const partiesClone = cloneDeep(parties);

    if (
      editPartyDeliveryMethod !== "email" &&
      !!partiesClone[idx].fields[emailIndex]
    ) {
      partiesClone[idx].fields[emailIndex].is_obligatory = false;
    }

    if (
      editPartyDeliveryMethod !== "mobile" &&
      editPartySignMethod !== "sms_pin" &&
      !!partiesClone[idx].fields[phoneIndex]
    ) {
      partiesClone[idx].fields[phoneIndex].is_obligatory = false;
    }

    if (
      editPartySignMethod !== "se_bankid" &&
      !!partiesClone[idx].fields[legalIdIndex]
    ) {
      partiesClone[idx].fields[legalIdIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  React.useEffect(() => {
    if (editPartyDeliveryMethod === "email") {
      if (
        !emailPartyField?.should_be_filled_by_sender ||
        !emailPartyField?.is_obligatory
      ) {
        setEmailObligatory();
      }
    } else if (editPartyDeliveryMethod === "mobile") {
      if (
        !phonePartyField?.should_be_filled_by_sender ||
        !phonePartyField?.is_obligatory
      ) {
        setPhoneObligatory();
      }
    } else if (editPartyDeliveryMethod === "email_mobile") {
      if (
        !phonePartyField?.should_be_filled_by_sender ||
        !phonePartyField?.is_obligatory ||
        !emailPartyField?.should_be_filled_by_sender ||
        !emailPartyField?.is_obligatory
      ) {
        setEmailAndPhoneObligatory();
      }
    } else {
      clearPossibleObligatories();
    }
  }, [editPartyDeliveryMethod]);
  // om leveranssätta är e-post - > kräv e-post av avsändar och visa rött annars
  // om leveranssätt är e-post/SMS _> kräv e-post och sms av avsändare och visa rött
  // om vleef aekf ls ätr sms -> räv telefon och visa rött

  React.useEffect(() => {
    if (editPartySignMethod === "standard") {
      clearPossibleObligatories();
    } else if (editPartySignMethod === "sms_pin") {
      setPhoneObligatory();
    } else if (editPartySignMethod === "se_bankid") {
      setPersonalNumberObligatory();
    }
  }, [editPartySignMethod]);
  // om signering är bankid -> sätt personnummer till krav
  // om signering är sms-pinkod -> sätt telefonnummer till krav
  return (
    <Modal
      title={`Hantera part ${
        party.first_name
          ? `${party.first_name} ${party.last_name || ""}`
          : idx + 1
      }`}
      closeFunction={onDone}
    >
      {!isTemplateEditor && (
        <div className="grid grid-cols-3 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            TableComponent={UsersTable}
            fieldKey={`parties[${idx}].user`}
            method={method}
            instructionsKey="parties.user"
            placeholder="Välj användare att koppla till"
            title="Kopplad användare"
          />

          <div></div>

          {user && hasUserPartyDifferences && (
            <div className="place-self-end">
              <PrimaryBtn onClick={handleSyncParty}>
                Synka fält mot användare{" "}
                <ArrowPathIcon width={16} className="ml-1" />
              </PrimaryBtn>
            </div>
          )}
        </div>
      )}

      <div className="grid grid-cols-3 gap-6 mb-6">
        <SelectField
          title="Typ av part"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].category`,
            instructionsKey: "parties.category",
          }}
        />

        <TextInputField
          title="Förnamn"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].fields[${firstNameIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
        />
        <TextInputField
          title="Efternamn"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].fields[${lastNameIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
        />
        <TextInputField
          title="Personnummer"
          description="Personnummer för signering"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].fields[${legalIdIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
        />
        <TextInputField
          title="E-post"
          description="E-post till signerande part"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].fields[${emailIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
        />
        <TextInputField
          title="Telefonnummer"
          description="Anges +467XXXXXXXX"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].fields[${phoneIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
        />
        <TextInputField
          title="Bolagsnamn"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].fields[${corporateNameIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
        />
        <TextInputField
          title="Org.nr"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].fields[${corporateLegalIdIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
        />
        {canUseScrive && (isTemplateEditor || usesESigning) && (
          <>
            {" "}
            <SelectField
              title="Ordning i signering (Scrive)"
              forceChoices={[
                { d: 1, v: 1 },
                { d: 2, v: 2 },
                { d: 3, v: 3 },
                { d: 4, v: 4 },
                { d: 5, v: 5 },
                { d: 6, v: 6 },
              ]}
              {...{
                storeName,
                method: method,
                fieldKey: `parties[${idx}].sign_order`,
                instructionsKey: "parties.sign_order",
              }}
            />
            <SelectField
              title="Roll (Scrive)"
              {...{
                storeName,
                method: method,
                fieldKey: `parties[${idx}].signatory_role`,
                instructionsKey: "parties.signatory_role",
              }}
            />
            <SelectField
              title="Leveranssätt (Scrive)"
              {...{
                storeName,
                method: method,
                fieldKey: `parties[${idx}].delivery_method`,
                instructionsKey: "parties.delivery_method",
              }}
            />
            <SelectField
              title="Signeringsmetod (Scrive)"
              {...{
                storeName,
                method: method,
                fieldKey: `parties[${idx}].authentication_method_to_sign`,
                instructionsKey: "parties.authentication_method_to_sign",
              }}
            />
          </>
        )}
      </div>

      {canUseScrive && (isTemplateEditor || usesESigning) && (
        <CheckField
          title="Dölj personnummer (Scrive)"
          description="Dölj personnummer för andra parter som ska signera avtalet"
          {...{
            storeName,
            method: method,
            fieldKey: `parties[${idx}].hide_personal_number`,
            instructionsKey: "parties.hide_personal_number",
          }}
        />
      )}
    </Modal>
  );
}
