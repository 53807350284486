import * as React from "react";

import { Select, TextField, TimePicker } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketIndpAds";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { buildQueryString } from "../../../../../store/base";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";
export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för visning</OverviewTitle>
          <OverviewSubtitle>
            Ange hur visning av objektet ser ut
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <Select
        fieldKey="viewing_type"
        title="Hur ska visning hanteras?"
        {...{ storeName, method }}
      />

      <TimePicker
        fieldKey="viewing_time"
        title="Datum och tid för visning"
        {...{ storeName, method }}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          fieldKey="viewing_showing_party"
          storeName={storeName}
          TableComponent={UsersTable}
          persistantQuery={{
            user_type: 3,
          }}
          placeholder="Välj hyresgäst..."
          title="Visning för"
          method={method}
        />
      </div>

      <TextField
        fieldKey="viewing_notes"
        title="Anteckningar för visning"
        {...{ storeName, method }}
      />
    </>
  );
};
