import styled from "styled-components";

export const Banner = styled.div`
  height: 40px;
  width: 100vw;
  padding: 0 30px;
  background-color: ${(p) => p.theme.colors.greenDark};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const BannerTitle = styled.div`
  color: #fff;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;
