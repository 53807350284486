import * as React from "react";
import * as SC from "./styles";

export default ({ clicked, title, extraStyle, withComma, small }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };
  return (
    <SC.TableLinkButton
      {...{ small }}
      style={{ ...extraStyle }}
      onClick={handleClick}
    >
      {title}
      {withComma && ", "}
    </SC.TableLinkButton>
  );
};
