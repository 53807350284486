import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import {
  buildQueryString,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../store/base";
import {
  detailUrl as brfCompanyDetailUrl,
  useFilteredBrfCompanies,
} from "../../store/brfCompanies";
import { overviewUrl, useCompany, constants } from "../../store/companies";
import { useInvoicingErrorPaginationCount } from "../../store/invoicingErrors/hooks/retrieve";
import { setActiveFilteredRealEstates } from "../../store/overview/store/actions";
import { useFilteredRealEstates } from "../../store/realEstates";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import CompanyBookkeepingSettings from "./detail/BookkeepingSettings";
import CompanyInvoicingErrors from "./detail/CompanyErrors";
import CompanyInvoicing from "./detail/Invoicing";
import CompanyOverview from "./detail/Overview";
import CompanyRealEstates from "./detail/RealEstates";
import MovedComponent from "../ConfigCenter/MovedComponent";

function CompanyDetail() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const { url, path } = useRouteMatch();
  const { push, replace } = useHistory();

  const [company] = useCompany(companyId);
  usePermissionRedirect(["view_can_company"]);

  const canDelete = usePermissionCheck("delete_can_company");
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const brfQ = buildQueryString({
    company: companyId,
  });
  const [matchingBrfCompanies] = useFilteredBrfCompanies(brfQ);

  const [invoiceSettingPromptOpen, setInvoicingSettingPromtOpen] =
    React.useState(false);
  const { hasBillectaViewPermission, hasBillectaFullPermission } =
    useFrequentPermissions();

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      company: companyId,
    },
  });

  const [errorsConnectedToCreditorCount] = useInvoicingErrorPaginationCount({
    filters: {
      billecta_creditor_id: company?.billecta_id,
    },
  });

  const realEstateQ = buildQueryString({
    company: companyId,
  });
  const [realEstates] = useFilteredRealEstates(realEstateQ);

  const query = useQuery();
  const showInvoicingSettingPrompt = query.get("showInvoicingSettingPrompt");

  React.useEffect(() => {
    if (hasBillectaFullPermission && showInvoicingSettingPrompt == "true") {
      setInvoicingSettingPromtOpen(true);
    }
  }, [showInvoicingSettingPrompt, hasBillectaFullPermission]);

  // redirect to brf if is brf
  React.useEffect(() => {
    if (matchingBrfCompanies?.length && company?.is_brf) {
      replace(brfCompanyDetailUrl({ id: matchingBrfCompanies?.[0]?.id }));
    }
  }, [matchingBrfCompanies, company]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Bolag",
      component: CompanyOverview,
    },
    {
      path: "/realestates",
      label: "Fastigheter",
      component: CompanyRealEstates,
    },
  ];

  if (hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      hasError: errorCount,
      component: CompanyInvoicing,
    });
  }

  if (company?.billecta_id && hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing-integrations",
      label: "Integrationer",
      component: MovedComponent,
    });
    SUB_PAGES.push({
      path: "/accounting",
      label: "Bokföring",
      component: CompanyBookkeepingSettings,
    });
  }

  if (
    company?.billecta_id &&
    hasBillectaViewPermission &&
    errorsConnectedToCreditorCount > 0
  ) {
    SUB_PAGES.push({
      path: "/creditor-related-errors",
      label: "Fel under bolag",
      hasError: errorsConnectedToCreditorCount > 0,
      component: CompanyInvoicingErrors,
    });
  }

  const subPageLinks = SUB_PAGES.filter((p) => !p.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderLogo = () => {
    if (company?.image?.get) {
      return (
        <div
          style={{
            height: 50,
            width: 70,
            marginRight: 8,
            boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${company.image.get})`,
          }}
        ></div>
      );
    }

    return null;
  };

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={company?.invoicing_details?.id ? 1 : 6}>
          {company?.invoicing_details?.id
            ? "Uppsatt för avisering"
            : "Ej uppsatt för avisering"}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => {
    return (
      <PrimaryButton
        title="Filtrera systemet på detta bolag"
        clicked={() => {
          dispatch(
            setActiveFilteredRealEstates({
              realEstateIds: realEstates?.map((r) => r?.id),
              companyIds: [parseInt(companyId)],
            })
          );
          push("/");
        }}
      />
    );
  };

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  const goToInvoicing = () => {
    push(`/companies/detail/${companyId}/invoicing?opensettings=true`);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={company}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <ConfirmationModal
        isOpen={invoiceSettingPromptOpen}
        closeFunction={() => setInvoicingSettingPromtOpen(false)}
        title="Vill du konfigurera aviseringsinställningar för detta bolag direkt?"
        acceptTitle="Konfigurera nu"
        acceptCallback={goToInvoicing}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Bolag ${company?.str_representation || "laddar..."}`}
          renderLogo={renderLogo}
          breadCrumbs={[
            { url: "/companies", label: "Bolag" },
            { label: company?.str_representation || "Laddar..." },
          ]}
          eventContentType="standard.company"
          eventObjectId={companyId}
          eventIdQueryStr="company"
          {...{
            subPages: subPageLinks,
            renderInfoPills,
            handleActions,
            renderActions,
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default CompanyDetail;
