export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INVOICINGSUMMARY",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INVOICINGSUMMARY",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INVOICINGSUMMARY",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INVOICINGSUMMARY",
  DESTROY_FORM: "DESTROY_FORM_INVOICINGSUMMARY",
  SET_FORM_ERROR: "SET_FORM_ERROR_INVOICINGSUMMARY",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INVOICINGSUMMARY",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INVOICINGSUMMARY",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INVOICINGSUMMARY",
  RESET_STATE: "RESET_STATE_INVOICINGSUMMARY",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INVOICINGSUMMARY",
  REMOVE_OBJECT: "REMOVE_OBJECT_INVOICINGSUMMARY",
  CLEAR_FETCHED: "CLEAR_FETCHED_INVOICINGSUMMARY",

  LIST_URL: "/accounting/contract/invoicesummary/",

  STORE_NAME: "invoicingSummary",
};
