import constants from "./constants";
import * as services from "./services";
import { addToProcess } from "../../base";
import { removeFromProgress } from "../../base/store/actions";

export const getAccountsReceivableForDate = ({
  date,
  creditorId,
  processName,
  errorCallback,
  excludeInvoicesWithPeriodOutsideDate,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, processName);
      const { data } = await services.getAccountsReceivableForDate({
        date,
        creditorId,
        excludeInvoicesWithPeriodOutsideDate,
      });

      dispatch({
        type: constants.SET_ACCOUNTS_RECEIVABLE_BY_DATE,
        payload: {
          creditorId,
          date,
          excludeInvoicesWithPeriodOutsideDate,
          invoices: data,
        },
      });

      removeFromProgress(dispatch, constants, processName);
    } catch (e) {
      console.log({ e });
      errorCallback && errorCallback(e);
    }
  };
};
