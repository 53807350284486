import * as React from "react";

// style, design
import {
  ToolTipCell,
  LinkedObject,
  GenericCell,
  DateCell,
  DurationCell,
} from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import CategoryFilter from "./Filters/CategoryFilter";
import SimpleIntervalFilter from "src/components/Lists/Base/CompleteList/Filters/IntervalFilter";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";

import { detailUrl } from "../../../store/leaseContracts";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

import { sortEndDate } from "src/components/Tables/LeaseContracts/Filters/StateFilter";

export default (hasBillectaViewPermission) => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }) => {
        let label = row.original.state_display;
        const state = row.original.state;
        if (
          [6, 7].includes(state) &&
          !row.original.closed_signed &&
          row.original.uses_cancellation_e_signing
        ) {
          label += ` (Ej signerat)`;
        } else if ([6, 7].includes(state) && !row.original.closed_confirmed) {
          label += ` (Ej bekräftat)`;
        }
        const isDraft = row.original.draft;

        if (isDraft) {
          label = "Utkast";
        }

        return <StatusLabel state={state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Avtalsnummer",
      accessor: "id_number",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Hyresgäst",
      accessor: "tenant.str_representation",
      Cell: ({ row }) => {
        const value = row.original?.tenant;
        return <LinkedObject obj={value} urlMethod={tenantDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastighet",
      accessor: "realestate.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    // searchable
    {
      Header: "Kategori",
      accessor: "category",
      Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
      Filter: CategoryFilter,
      filter: "text",
    },
    {
      Header: "Huvudavtal",
      accessor: "main_contract",
      Cell: ({ value }) =>
        value ? (
          <LinkedObject
            obj={{ id: value?.id, str_representation: "Visa" }}
            urlMethod={detailUrl}
          />
        ) : (
          "-"
        ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    {
      Header: "Objektskoppling",
      accessor: "content_type",
      Cell: ({ row }) => (
        <GenericCell
          contentType={row.original.content_type}
          id={row.original.object_id}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },

    // searchable
    {
      Header: "Startdatum",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    // Check this after deploy on staging
    // Potential problems with null values
    {
      Header: "Slutdatum",
      accessor: "closed_date",
      id: "end_date",
      Cell: ({ row }) => (
        <>
          {row.original.closed_date ? (
            <DateCell date={row.original.closed_date} />
          ) : row.original.renewed_to ? (
            <>
              <DateCell date={row.original.renewed_to} />
              <span> (Förlängt)</span>
            </>
          ) : (
            <DateCell date={row.original.end_date} />
          )}
        </>
      ),
      Filter: SimpleDateFilter,
      filter: "betweenDates",
      sortType: sortEndDate,
    },
    {
      Header: "Uppsägningstid",
      accessor: "notify_interval",
      Cell: ({ row }) => (
        <DurationCell
          durationStamp={row.original.notify_interval}
          shadowDate={row.original.notify_time}
        />
      ),
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Förlängningstid",
      accessor: "renew_interval",
      Cell: ({ row }) => {
        const forever = row.original.renew_indefinetely;
        if (forever) {
          return "Tillsvidare";
        }
        return <DurationCell durationStamp={row.original.renew_interval} />;
      },
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    // searchable
    {
      Header: "Skapat av",
      accessor: "owner.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  if (hasBillectaViewPermission) {
    cols.splice(3, 0, {
      Header: "Aviseringsstatus",
      accessor: "other_invoicing_active",
      Cell: ({ value, row }) => {
        const billWithLease = row.original.other_invoicing_bill_with_lease;

        if (billWithLease) {
          return <StatusLabel state={4}>Samaviseras</StatusLabel>;
        }
        return (
          <StatusLabel
            state={
              value === true ? 1 : value === null ? 6 : value === false ? 3 : 10
            }
          >
            {value === true
              ? "Konfigurerad"
              : value === null
              ? "Ej konfigurerad"
              : value === false
              ? "Pausad"
              : "Okänd"}
          </StatusLabel>
        );
      },
      // Filter: InvoiceFilter,
      // filter: "textExact",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    });
  }

  return cols;
};
