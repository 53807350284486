import * as React from "react";
import { useDispatch } from "react-redux";

import {
  destroyPatchForm,
  constants,
  update,
  useOtherContractForm,
} from "../../../../store/otherContracts";
import { updateActiveFormInstance } from "../../../../store/base";

import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TableSelectFieldWithCreate from "../../Base/Fields/TableSelectFieldWithCreate";
import TenantNestedChildren from "../../Tenant/NestedChildren";

export default function UpdateOtherContractTenantModalForm({
  id,
  onCheckout,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useOtherContractForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={"Uppdatera motpart på avtal"}
      closeFunction={onDone}
      onAccept={onSubmit}
      acceptTitle="Spara"
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Motpart på avtal</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey="tenant"
          method={method}
          title={"Motpart"}
          placeholder="Välj motpart..."
          TableComponent={TenantTable}
          createDisplayKey="user.first_name"
        >
          {(parentPath) => (
            <TenantNestedChildren
              storeName={storeName}
              method={method}
              parentInstructionsPath={"tenant"}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>
    </Modal>
  );
}
