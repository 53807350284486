import {
  ArrowsPointingOutIcon,
  PencilSquareIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../store/base";
import {
  FIELD_TYPES,
  CATEGORY_MAP,
  getFieldType,
  toExternalSize,
  toInternalFontSize,
  toInternalSize,
} from "../utils";

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function getTextToFitWidth(text, font, maxWidth) {
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;

  let textSample = text;
  const sampleLength = textSample.length;
  for (let index = 0; index < sampleLength; index++) {
    const check = textSample.slice(0, sampleLength - index);
    const fits = context.measureText(check).width <= maxWidth;
    if (fits) {
      return check;
    }
  }

  // can't fit the text
  return null;
}

export default function DigitalDocEditableFieldBase({
  storeName,
  disabled,
  placement,
  placementKey,
  canInteract,
  partyKey,
  fieldKey,
  pageBounds,
}) {
  const dispatch = useDispatch();
  const [showActions, setShowActions] = React.useState(false);
  const [displayName, setDisplayName] = React.useState(false);
  const hideActionsTimeOutRef = React.useRef();
  const currentPageData = pageBounds[placement.page];

  const kind = useFormInstanceField({
    storeName,
    fieldKey: `${fieldKey}.kind`,
  });
  const fsrel = useFormInstanceField({
    storeName,
    fieldKey: `${placementKey}.fsrel`,
  });

  const name = useFormInstanceField({
    storeName,
    fieldKey: `${fieldKey}.name`,
  });

  const fieldType = getFieldType(kind);

  const valueKey = fieldType === FIELD_TYPES.CHECKBOX ? "is_checked" : "value";
  const actualFieldKey = `${fieldKey}.${valueKey}`;
  const value = useFormInstanceField({
    storeName,
    fieldKey: actualFieldKey,
  });
  const radioOptionValue = useFormInstanceField({
    storeName,
    fieldKey: `${placementKey}.value`,
  });

  const fullParty = useFormInstanceField({
    storeName,
    fieldKey: partyKey,
  });
  const partyColor = fullParty?._color;

  const fieldError = useFormError({ storeName, fieldKey: fieldKey });
  const hasErr =
    fieldError &&
    (Array.isArray(fieldError)
      ? fieldError.length
      : fieldError.constructor === Object
      ? Object.keys(fieldError).length
      : true);

  const fieldInstructionKey = fieldKey.replaceAll(/\[[^\]]*\]/g, "");

  const instructions = useFormField({
    storeName,
    method: "PATCH",
    fieldKey: fieldInstructionKey,
  });

  const currentMaxSize = toInternalSize({
    w: 1 - placement.xrel,
    h: 1 - placement.hrel,
    pageData: currentPageData,
  });

  const updateSize = () => {
    // important, to make sure that it doesn't from automatic re-rendering
    const { f: fontSize } = toInternalFontSize({
      f: fsrel,
      pageData: currentPageData,
    });

    const canvasFontSize = fontSize === 20 ? 23 : fontSize === 18 ? 21 : 19;

    const defaultWantedTextWidth = value
      ? getTextWidth(value, `${canvasFontSize}px Arial`)
      : 100;

    // check if our value (probably changed to be more chars) fits according to max width
    if (defaultWantedTextWidth > currentMaxSize.w) {
      // only check if it's a string
      if (value && typeof value === "string") {
        const fittedValue = getTextToFitWidth(
          value,
          `${canvasFontSize}px Arial`,
          currentMaxSize.w
        );

        // only remove last char in value if it will pass the next time we run this test
        if (fittedValue != null) {
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: { [actualFieldKey]: fittedValue },
            })
          );
        }
      }
    }
    let size = {
      width: defaultWantedTextWidth,
      height: 38,
    };

    if (kind === FIELD_TYPES.CHECKBOX || kind === FIELD_TYPES.RADIO) {
      size = {
        width: 20,
        height: 20,
      };
    }

    if (kind === FIELD_TYPES.SIGNATURE) {
      size = {
        width: 200,
        height: 50,
      };
    }

    const { w, h } = toExternalSize({ size, pageData: currentPageData });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`${placementKey}.wrel`]: w,
          [`${placementKey}.hrel`]: h,
        },
      })
    );
  };

  React.useEffect(() => {
    updateSize();
  }, [value, kind]);

  const size = toInternalSize({
    w: placement.wrel,
    h: placement.hrel,
    pageData: currentPageData,
  });

  const fontSize = toInternalFontSize({
    f: placement.fsrel,
    pageData: currentPageData,
  })?.f;

  const onChange = (data) => {
    if (fieldType === FIELD_TYPES.RADIO) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [actualFieldKey]: radioOptionValue },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [actualFieldKey]: data },
        })
      );
    }
  };

  // set data for handle field component in redux
  const setHandleFieldAttributes = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _handleFieldAttrs: {
            partyKey: partyKey,
            fieldKey: fieldKey,
            fieldInstructionKey: fieldInstructionKey,
            placementKey,
            placement,
          },
        },
      })
    );
  };

  const removeField = () => {
    const fields = fullParty?.fields || [];

    if (!fields.length) {
      return;
    }

    if (!name) {
      return;
    }

    const newFields = fields.filter((f) => f.name !== name);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`${partyKey}.fields`]: newFields },
      })
    );
  };

  const renderFieldType = () => {
    switch (fieldType) {
      case FIELD_TYPES.CHECKBOX:
        return (
          <>
            <input
              type="checkbox"
              className={`w-4  h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2`}
              id={`${partyKey}${fieldKey}${placementKey}`}
              checked={Boolean(value)}
              onChange={({ target: { checked } }) => onChange(checked)}
            />
          </>
        );

      case FIELD_TYPES.SIGNATURE:
        return (
          <div className="bg-white/50 w-full h-full flex flex-col justify-center text-xs align-middle font-medium text-center">
            {fullParty?.user?.str_representation ||
              fullParty?.str_representation ||
              `Part ${CATEGORY_MAP[fullParty.category]}`}{" "}
            <span className="block">signerar här</span>
          </div>
        );

      case FIELD_TYPES.RADIO:
        return (
          <input
            onChange={({ target: { checked } }) => onChange(checked)}
            disabled={disabled}
            id={`${partyKey}${fieldKey}${placementKey}`}
            type="radio"
            checked={radioOptionValue === value}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
          />
        );
      default:
        return (
          <input
            maxLength={instructions?.max_length}
            type="text"
            style={{ fontSize }}
            id={`${partyKey}${fieldKey}${placementKey}`}
            className="w-full h-full outline-none p-0 border-none bg-transparent"
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
          />
        );
    }
  };

  return (
    <div
      onMouseOver={() => {
        clearTimeout(hideActionsTimeOutRef.current);
        setDisplayName(true);

        setShowActions(true);
      }}
      onMouseLeave={() => {
        hideActionsTimeOutRef.current = setTimeout(() => {
          setDisplayName(false);
          setShowActions(false);
        }, 2000);
      }}
    >
      {displayName && (
        <div className="absolute top-[-16px] left-0 font-medium text-xs">
          {name}
        </div>
      )}

      {!canInteract && (
        <div className="absolute bg-black w-[2px] h-[2px] top-[calc(50%-1px)] left-[-1px] after:absolute after:h-[200vh] after:w-[1px] after:bg-black after:bottom-[-100vh] after:right-[100%] before:absolute before:h-[1px] before:w-[200vw] before:bg-black before:bottom-[100%] before:right-[-100vw]"></div>
      )}

      {showActions && !disabled && (
        <>
          <ArrowsPointingOutIcon
            width={16}
            className="handle cursor-grab absolute z-20 right-[-12px] bottom-[-12px]"
          />
          <ArrowsPointingOutIcon
            width={16}
            className="handle cursor-grab absolute z-20 left-[-12px] bottom-[-12px]"
          />

          {fieldType !== FIELD_TYPES.SIGNATURE && (
            <>
              <PencilSquareIcon
                onClick={setHandleFieldAttributes}
                width={16}
                className="no-move handle cursor-pointer absolute z-20 right-[-12px] top-[-12px]"
              />
              <PencilSquareIcon
                onClick={setHandleFieldAttributes}
                width={16}
                className="no-move handle cursor-pointer absolute z-20 left-[-12px] top-[-12px]"
              />
            </>
          )}

          {fieldType === FIELD_TYPES.SIGNATURE && (
            <>
              <XCircleIcon
                onClick={removeField}
                width={16}
                className="no-move handle text-red-500 cursor-pointer absolute z-20 right-[-12px] top-[-12px]"
              />
              <XCircleIcon
                onClick={removeField}
                width={16}
                className="no-move handle  text-red-500  cursor-pointer absolute z-20 left-[-12px] top-[-12px]"
              />
            </>
          )}
        </>
      )}

      <div
        style={{
          width: [FIELD_TYPES.RADIO, FIELD_TYPES.CHECKBOX].includes(fieldType)
            ? undefined
            : size.w,
          height: [FIELD_TYPES.RADIO, FIELD_TYPES.CHECKBOX].includes(fieldType)
            ? undefined
            : size.h,
          maxWidth: [FIELD_TYPES.RADIO, FIELD_TYPES.CHECKBOX].includes(
            fieldType
          )
            ? undefined
            : toInternalSize({
                w: 1 - placement.xrel,
                h: 0,
                pageData: currentPageData,
              }).w,
          maxHeight: [
            FIELD_TYPES.RADIO,
            FIELD_TYPES.CHECKBOX,
            FIELD_TYPES.SIGNATURE,
          ].includes(fieldType)
            ? undefined
            : 38,
          borderColor: partyColor,
        }}
        tabIndex="0"
        className={classNames(
          "p-0 relative border-b-4 border-solid",
          "no-move",
          {
            "bg-white": showActions,
            "bg-transparent": !showActions,
            "min-h-[30px] min-w-[100px] overflow-auto": ![
              FIELD_TYPES.CHECKBOX,
              FIELD_TYPES.RADIO,
            ].includes(fieldType),
            "text-red-500 bg-red-500 border border-solid border-red-500":
              hasErr,
          }
        )}
      >
        {renderFieldType()}
      </div>
    </div>
  );
}
