export default [
  {
    title: "Schemaläggning",
    key: "INTERVAL",
    visited: true,
    hasError: false,
    fieldKeys: ["interval_setting"],
  },
  {
    title: "Uppdatera",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
