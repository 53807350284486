import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  usePaymentGroupForm,
  constants,
  create,
  update,
  destroyPostForm,
  destroyPatchForm,
  detailUrl,
  usePaymentGroup,
} from "../../../../store/paymentGroup";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import { setActiveFormInstance, updateActiveFormInstance } from "../../../../store/base";

export default function KeyForm({ method = "POST", id, brfCompanyId }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(usePaymentGroupForm(method, id));
  const { replace } = useHistory();

  const [instance, instanceLoading] = usePaymentGroup(id);
  const [hasSetInstance, setHasSetInstance] = React.useState(false);

  React.useEffect(() => {
    if (!hasSetInstance && instance != null) {
      dispatch(setActiveFormInstance({
        storeName: storeName,
        data: instance
      }));

      setHasSetInstance(true);
    }
  }, [hasSetInstance, instance])


  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(detailUrl({ id: returned.id }));
  };

  React.useEffect(() => {
    return () => {
      if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      } else {
        dispatch(destroyPostForm(false));
      }
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    } else {
      dispatch(destroyPostForm(success));
    }
  };

  const onSubmit = () => {
    setLoading(true);

    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: onSuccess,
          errorCallback: onError,
          preProcess: (data) => {
            const cop = cloneDeep(data);
            cop.brf_company = {id:brfCompanyId}

            return cop;
          },
          id,
        })
      );
    } else {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: onError,
          preProcess: (data) => {
            const cop = cloneDeep(data);
            cop.brf_company = {id:brfCompanyId}

            return cop;
          },
        })
      );
    }
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,

          descriptions,
          method,
          onSubmit,

          title:
            method === "POST" ? "Lägg till avgiftsgrupp" : "Uppdatera avgiftsgrupp",
        }}
      />
    </>
  );
}
