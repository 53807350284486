import * as React from "react";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import NonConnectedInputField from "../../Forms/Base/Old/NonConnected/NonConnectedInputField";
import { InnerBox } from "../../sharedStyles";
import * as SC from "./styles";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";

import LocalDateSelect from "src/components/Forms/Base/Fields/LocalDateSelect";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
const STATUS_CHOICES = [
  { value: "Open", label: "Öppna fakturor" },
  { value: "Closed", label: "Stängda fakturor" },
  { value: "WithInvoiceReminder", label: "Med påminnelse" },
  { value: "WithoutInvoiceReminder", label: "Utan påminnelse" },
];

export default ({
  invoiceNumber,
  setInvoiceNumber,
  invoiceDateFrom,
  setInvoiceDateFrom,
  invoiceDateTo,
  setInvoiceDateTo,
  dueDateFrom,
  setDueDateFrom,
  dueDateTo,
  setDueDateTo,
  periodDateFrom,
  setPeriodDateFrom,
  periodDateTo,
  setPeriodDateTo,
  createdDateFrom,
  setCreatedDateFrom,
  createdDateTo,
  setCreatedDateTo,
  attestedDateFrom,
  setAttestedDateFrom,
  attestedDateTo,
  setAttestedDateTo,
  closedDateFrom,
  setClosedDateFrom,
  closedDateTo,
  setClosedDateTo,
  selectedCostCenter,
  setSelectedCostCenter,
  selectedProject,
  setSelectedProject,
  selectedProducts,
  setSelectedProducts,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  return (
    <SC.FieldsWrapper>
      <InnerBox>
        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Fakturanummer/OCR
            </OverviewTitle>
            <NonConnectedInputField
              value={invoiceNumber}
              onChange={({ target: { value } }) => setInvoiceNumber(value)}
              id="invoice-number"
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Status
            </OverviewTitle>

            <NonConnectedSelect
              choices={STATUS_CHOICES}
              value={selectedStatuses?.map((s) =>
                STATUS_CHOICES.find((v) => v.value === s)
              )}
              id="status"
              isMulti
              onUpdate={(val) => setSelectedStatuses(val)}
              getOptionValue={(choice) => choice.value}
              getOptionLabel={(choice) => choice.label}
              bodyPortaled
            />
          </SC.FieldBox>
        </SC.Row>

        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Fakturadatum
            </OverviewTitle>
            <div className="grid grid-cols-2 gap-2 mb-2">
              <LocalDateSelect
                title="Från"
                value={invoiceDateFrom}
                onChange={(val) => setInvoiceDateFrom(val)}
                id="invoice-date-from"
              />
              <LocalDateSelect
                title="Till"
                value={invoiceDateTo}
                onChange={(val) => setInvoiceDateTo(val)}
                id="invoice-date-to"
              />
            </div>
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Förfallodatum
            </OverviewTitle>

            <div className="grid grid-cols-2 gap-2 mb-2">
              <LocalDateSelect
                title="Från"
                value={dueDateFrom}
                onChange={(val) => setDueDateFrom(val)}
                id="due-date-from"
              />
              <LocalDateSelect
                title="Till"
                value={dueDateTo}
                onChange={(val) => setDueDateTo(val)}
                id="due-date-to"
              />
            </div>
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Period
            </OverviewTitle>

            <div className="grid grid-cols-2 gap-2 mb-2">
              <LocalDateSelect
                title="Från"
                value={periodDateFrom}
                onChange={(val) => setPeriodDateFrom(val)}
                id="period-date-from"
              />
              <LocalDateSelect
                title="Till"
                value={periodDateTo}
                onChange={(val) => setPeriodDateTo(val)}
                id="period-date-to"
              />
            </div>
          </SC.FieldBox>
        </SC.Row>

        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Skapades
            </OverviewTitle>
            <div className="grid grid-cols-2 gap-2 mb-2">
              <LocalDateSelect
                title="Från"
                value={createdDateFrom}
                onChange={(val) => setCreatedDateFrom(val)}
                id="created-date-from"
              />
              <LocalDateSelect
                title="Till"
                value={createdDateTo}
                onChange={(val) => setCreatedDateTo(val)}
                id="created-date-to"
              />
            </div>
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Attesterades
            </OverviewTitle>

            <div className="grid grid-cols-2 gap-2 mb-2">
              <LocalDateSelect
                title="Från"
                value={attestedDateFrom}
                onChange={(val) => setAttestedDateFrom(val)}
                id="attested-date-from"
              />
              <LocalDateSelect
                title="Till"
                value={attestedDateTo}
                onChange={(val) => setAttestedDateTo(val)}
                id="attested-date-to"
              />
            </div>
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Avslutades
            </OverviewTitle>

            <div className="grid grid-cols-2 gap-2 mb-2">
              <LocalDateSelect
                title="Från"
                value={closedDateFrom}
                onChange={(val) => setClosedDateFrom(val)}
                id="closed-date-from"
              />
              <LocalDateSelect
                title="Till"
                value={closedDateTo}
                onChange={(val) => setClosedDateTo(val)}
                id="closed-date-to"
              />
            </div>
          </SC.FieldBox>
        </SC.Row>

        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Kostnadsställe
            </OverviewTitle>

            <LocalTableSelectField
              TableComponent={InvoicingCostCenterTable}
              value={selectedCostCenter}
              placeholder="Välj kostnadsställe"
              onChange={(d) => setSelectedCostCenter(d)}
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Projekt
            </OverviewTitle>

            <LocalTableSelectField
              TableComponent={InvoicingProjectsTable}
              value={selectedProject}
              placeholder="Välj projekt"
              onChange={(d) => setSelectedProject(d)}
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Produkter
            </OverviewTitle>

            <LocalTableSelectField
              TableComponent={InvoicingProductTable}
              isMany
              value={selectedProducts}
              placeholder="Välj produkter"
              onChange={(d) => setSelectedProducts(d)}
            />
          </SC.FieldBox>
        </SC.Row>
      </InnerBox>
    </SC.FieldsWrapper>
  );
};
