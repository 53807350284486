import * as React from "react";
import TagEditor from "../Forms/Base/FieldComponents/TagEditor";
import { EmptyTagLabel } from "./styles";
import TagCell from "./TagCell";

export default ({
  tags,
  storeName,
  instanceId,
  formMethod,
  instanceMethod,
  updateMethod,
  destroyPatchForm,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      {isOpen && (
        <TagEditor
          storeName={storeName}
          instanceId={instanceId}
          formMethod={formMethod}
          instanceMethod={instanceMethod}
          updateMethod={updateMethod}
          isOpen={isOpen}
          destroyPatchForm={destroyPatchForm}
          onDone={() => setIsOpen(false)}
        />
      )}

      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {!tags?.length ? (
          <EmptyTagLabel>Lägg till</EmptyTagLabel>
        ) : (
          (tags || []).map((t) => <TagCell tag={t} />)
        )}
      </div>
    </>
  );
};
