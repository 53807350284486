import {
  ExclamationTriangleIcon,
  FolderPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { cloneDeep, get } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

import Modal from "../Modals/Modal";

export default function TableSelectField({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  placeholder,
  title,
  description,
  forceError,
  forceRequired,
  forceInstructions,
  changeCallback,
  closeCallback,
  disabled,
  persistantQuery,
  TableComponent,
  keepOpenOnSelect = false,
  isMany = false,
  displayKey = "str_representation", // key on selected object to display if isMany
  renderSubtitle,
  forceRender = false, //force component to render without instructions
  className,
  setOnlyId = false,
  tableProps = {},
}) {
  const dispatch = useDispatch();
  const [pickerOpen, setPickerOpen] = React.useState(false);

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || forceInstructions;
  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey });

  const onChange = (selected) => {
    if (isMany) {
      let valueClone = cloneDeep(value);

      if (!valueClone) {
        valueClone = [selected];
      } else if (valueClone.find((s) => s.id === selected.id)) {
        valueClone = valueClone.filter((s) => s.id !== selected.id);
      } else {
        valueClone.push(selected);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [fieldKey]: valueClone,
          },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [fieldKey]: selected,
          },
        })
      );
    }

    if (!keepOpenOnSelect && !isMany) {
      setPickerOpen(false);
      closeCallback && closeCallback();
    }

    if (changeCallback) {
      changeCallback(selected);
    }
  };

  const checkRowHighlighted = (original) => {
    if (isMany) {
      return value?.some((v) => v.id === original.id);
    } else {
      let match = value?.id === original.id;

      return match;
    }
  };

  const onClear = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: isMany ? [] : instructions?._allowNull ? null : undefined,
        },
      })
    );

    changeCallback &&
      changeCallback(isMany ? [] : instructions?._allowNull ? null : undefined);
  };

  const onRemoveIdx = (idx) => {
    let valueClone = cloneDeep(value);

    valueClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: valueClone,
        },
      })
    );
  };

  const renderTitle = () => {
    if (isMany) {
      if (value?.length === 1) {
        return <div>{value[0][displayKey]}</div>;
      } else {
        return <div>{placeholder || title}</div>;
      }
    } else {
      return <div>{value ? value?.[displayKey] : placeholder || title}</div>;
    }
  };

  if ((!instructions || instructions?._readOnly) && !forceRender) return null;

  return (
    <>
      <div>
        <div>
          <div className="font-medium text-gray-900 text-sm flex items-center">
            {(error || forceError) && (
              <ExclamationTriangleIcon
                width={16}
                className="text-red-600 mr-1"
              />
            )}{" "}
            {title}
            {instructions?._required || forceRequired ? "*" : ""}
          </div>
          {description && (
            <div className="text-xs font-normal text-gray-500 bg-transparent mb-1 ">
              {description}
            </div>
          )}
        </div>

        {(error || forceError) && (
          <div className="text-xs font-normal text-red-600 mb-1">
            {forceError || error}
          </div>
        )}

        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setPickerOpen(true);
            }}
            disabled={disabled}
            className={classNames(
              "inline-flex w-full items-center border border-solid border-slate-300 text-gray-900  rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center",
              disabled
                ? "bg-slate-200 opacity-80 cursor-not-allowed"
                : "bg-white",
              isMany && value?.length > 1 && "rounded-b-none"
            )}
          >
            {renderTitle()}
            {(isMany && value?.length) || (!isMany && value) ? (
              <XMarkIcon
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
                width={16}
                className="ml-auto"
              />
            ) : (
              <FolderPlusIcon width={16} className="ml-auto" />
            )}
          </button>

          {isMany && value?.length > 1 && (
            <div className="flex flex-col w-full border border-t-0 border-solid border-slate-300 rounded-b overflow-hidden">
              {value?.map((v, idx) => (
                <div className="border-b cursor-default border-slate-200 p-2 flex justify-between bg-slate-50 hover:bg-blue-100 last-of-type:border-b-0">
                  <div
                    className={classNames("text-sm", {
                      "cursor-pointer text-primaryblue underline": !v.id,
                    })}
                  >
                    {get(v, displayKey)}
                  </div>
                  <XMarkIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveIdx(idx);
                    }}
                    width={16}
                    className="ml-auto cursor-pointer"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {renderSubtitle && renderSubtitle()}
      </div>

      {pickerOpen && (
        <Modal
          closeFunction={() => {
            setPickerOpen(false);
            closeCallback && closeCallback();
          }}
          title={placeholder}
          className={className}
        >
          <TableComponent
            ignoreLocalStorage
            {...{
              persistantQuery,
              checkRowHighlighted,
              onRowClicked: (original) => onChange(original),
              ...tableProps,
            }}
          />
        </Modal>
      )}
    </>
  );
}
