import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import CompaniesTable from "../../../../components/Tables/Companies/FullTable";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../../components/sharedStyles";
import { buildQueryString } from "../../../../store/base";

export default function VacancySettingUsedBy() {
  const { settingId } = useParams();

  const companyPersistantQueryString = buildQueryString({
    vacancy_booking_config: settingId,
  });

  const companyPersistantFilterMethod = (obj) =>
    obj.vacancy_booking_config?.id == settingId;

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Bolag som använder inställningen</OverviewTitle>
        </OverviewTitleWrapper>

        <CompaniesTable
          hideTitle
          hideColumns
          hideFilters
          hideExport
          {...{
            persistantQueryString: companyPersistantQueryString,
            persistantFilterMethod: companyPersistantFilterMethod,
          }}
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
