import { axiosInstance } from "../../base/store/axios";

async function triggerManual({ id, body }) {
  const { data } = await axiosInstance.post(
    `/accounting/general/sftpcatalogue/trigger/${id}/`,
    body
  );

  return data;
}

export { triggerManual };
