export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGSPOT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGSPOT",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGSPOT",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGSPOT",
  DESTROY_FORM: "DESTROY_FORM_PARKINGSPOT",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGSPOT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGSPOT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGSPOT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGSPOT",
  RESET_STATE: "RESET_STATE_PARKINGSPOT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGSPOT",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PARKINGSPOT",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PARKINGSPOT",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGSPOT",

  LIST_URL: "/standard/parking/parkingspot/list/",
  POST_URL: "/standard/parking/parkingspot/",
  PATCH_URL: "/standard/parking/parkingspot/",
  GET_URL: "/standard/parking/parkingspot/",
  PROPOSAL_URL: "/standard/parking/parkingspot/contract_proposal/",

  STORE_NAME: "parkingSpots",
};
