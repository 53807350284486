import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];
    const b64Prefix = "data:application/pdf;base64,";

    // main document
    const docPath = responseData?.editabledoc?.doc?.put;

    let base64file = formInstance?.editabledoc?.doc?._tempData?.data;
    if (base64file && !base64file.includes(b64Prefix)) {
      base64file = b64Prefix + base64file;
    }

    if (docPath && base64file) {
      urls.push(docPath);
      files.push(base64file);
    }

    // cancel document
    const cancelDocPath = responseData?.cancelled_doc?.doc?.put;

    let cancelBase64file = formInstance?.cancelled_doc?._tempData?.data;
    if (cancelBase64file && !cancelBase64file.includes(b64Prefix)) {
      cancelBase64file = b64Prefix + cancelBase64file;
    }

    if (cancelDocPath && cancelBase64file) {
      urls.push(cancelDocPath);
      files.push(cancelBase64file);
    }

    // attachments
    const attachments = formInstance?.editabledoc?.attachments || [];
    const responseAttachments = responseData?.editabledoc?.attachments || [];

    if (attachments.length === responseAttachments.length) {
      responseAttachments.forEach((a, index) => {
        const attachment = attachments[index];
        let file = attachment?._tempData?.data;
        if (file && !file.includes(b64Prefix)) {
          file = b64Prefix + file;
        }

        const url = a?.pdf?.put;

        if (file && url) {
          urls.push(url);
          files.push(file);
        }
      });
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
