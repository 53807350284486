import * as React from "react";
import { useAsyncDebounce } from "react-table";
import NonConnectedTextInput from "../../../../Forms/Base/Old/NonConnected/NonConnectedTextInput";

export default function NumberRangeFilter({
  column: { filterValue = [], setFilter, id },
  flatRows,
}) {
  const [min, max] = React.useMemo(() => {
    let min = flatRows.length ? flatRows[0].values[id] : 0;
    let max = flatRows.length ? flatRows[0].values[id] : 0;
    flatRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, flatRows]);

  const [value, setValue] = React.useState(filterValue);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 200);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minWidth: "180px",
      }}
    >
      <NonConnectedTextInput
        rowSize
        noMargin
        value={value[0] || ""}
        type="number"
        onChange={(val) => {
          setValue((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          onChange((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      <div style={{ margin: "0 4px", color: "#0f0f0f" }}>-</div>
      <NonConnectedTextInput
        rowSize
        noMargin
        value={value[1] || ""}
        type="number"
        onChange={(val) => {
          setValue((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          onChange((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}
