export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTAPAYMENTMEANS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTAPAYMENTMEANS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTAPAYMENTMEANS",
  DESTROY_FORM: "DESTROY_FORM_BILLECTAPAYMENTMEANS",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTAPAYMENTMEANS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTAPAYMENTMEANS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTAPAYMENTMEANS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTAPAYMENTMEANS",
  RESET_STATE: "RESET_STATE_BILLECTAPAYMENTMEANS",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTAPAYMENTMEANS",

  //   CONTRACT_LIST_URL: "/accounting/contract_invoices/",
  //   CONTRACT_GET_URL: "/accounting/contract_invoice/",
  //   INVOICE_LIST_URL: "/accounting/invoices/open/",
  //   INVOICE_LIST_URL_CLOSED: "/accounting/invoices/closed/",
  //   INVOICE_GET_URL: "/accounting/invoice/",
  //   CONTRACT_PROPOSAL_URL: "/accounting/propose/contract_invoice/",
  //   INVOICE_PROPOSAL_URL: "/accounting/propose/invoice/",
  //   MANUAL_INVOICE_PROPOSAL_URL: "/accounting/propose/debtor/",

  STORE_NAME: "billectaPaymentMeans",
};
