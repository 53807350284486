import * as React from "react";

import { RadioGroup } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketApartmentAds";

import useActiveFilters from "../../../../../hooks/useActiveFilters";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useDispatch } from "react-redux";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const publishedInSbf = useFormInstanceField({
    storeName,
    fieldKey: "publish_sbf",
  });

  React.useEffect(() => {
    if (publishedInSbf) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            publish_pigello: false,
            publish_homeq: false,
          },
        })
      );
    }
  }, [publishedInSbf]);

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Publicering av annons</OverviewTitle>
          <OverviewSubtitle>
            Ställ in var annonsen ska publiceras
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <RadioGroup
        disabled={publishedInSbf}
        extraStyles={{ flex: 1 }}
        fieldKey="publish_homeq"
        title="Publicera i HomeQ"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />

      {/* <RadioGroup
        disabled={publishedInSbf}
        extraStyles={{ flex: 1 }}
        fieldKey="publish_pigello"
        title="Publicera i Pigello Marknadsplats"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      /> */}

      <RadioGroup
        extraStyles={{ flex: 1 }}
        fieldKey="publish_sbf"
        title="Publicera i Bostadsförmedlingen Stockholm"
        description="Annonser som publiceras här får ej publiceras på andra annonsplatser"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />
    </>
  );
};
