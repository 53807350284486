import * as React from "react";

import { TimePicker } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/key";
import { buildQueryString } from "../../../../../store/base";
import useActiveFilters from "../../../../../hooks/useActiveFilters";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import KeyPermissionsTable from "src/components/Tables/KeyPermissions/FullTable";
import CheckField from "src/components/Forms/Base/Fields/CheckField";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();

  const storeName = constants.STORE_NAME;

  const realEstateQuery = buildQueryString({
    id__in: filteredRealEstates,
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            realestate_ids: filteredRealEstates,
          }}
          placeholder="Välj nyckelbehörighet..."
          title="Nyckelbehörighet"
          method={method}
          fieldKey="permission"
          TableComponent={KeyPermissionsTable}
        />
      </div>

      <TimePicker
        title="Gäller f.o.m"
        storeName={storeName}
        method={method}
        fieldKey="start_time"
      />

      <TimePicker
        title="Gäller t.o.m"
        storeName={storeName}
        method={method}
        fieldKey="end_time"
      />

      <div className="mb-6">
        <CheckField
          title="Blockera automatisk datumjustering"
          storeName={storeName}
          method={method}
          fieldKey="block_automatic_time_conforming"
        />
      </div>
    </>
  );
};
