import * as React from "react";
import * as SC from "./styles";

import settingsIcon from "../../../assets/svg/material/settings_black.svg";
import notifIcon from "../../../assets/svg/material/notifications_black.svg";
import helpIcon from "../../../assets/svg/material/help_outline_black.svg";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NotificationsPopup from "../../Popups/NotificationsPopup/NotificationsPopup";
import Search from "./Search";
import Logo from "../Sidebar/Logo";
import {
  ExportExcelButton,
  PrimaryButton,
  TextButton,
} from "../../Forms/Base/Buttons";
import useQuery from "../../utils/useQuery";
import {
  detailUrlExcel as excelReportDetailUrl,
  detailUrlPowerPoint as pptReportDetailUrl,
  overviewUrl as reportOverviewUrl,
} from "../../../store/excelReports";
import { downloadPowerPointPDF } from "../../../store/powerPointReports";
import ExportPowerPointButton from "../../Forms/Base/Buttons/ExportPowerPoint";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../Loaders/OverlaySpinner";

export default ({ isReportView = false, isPowerPointReport = false }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const user = useSelector((state) => state.app.user);

  const [notifsOpen, setNotifsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const query = useQuery();
  const report_url = query.get("report_url");
  const report_id = query.get("report_id");

  const userImg = user?.image?.get;
  const userName = `${user?.first_name ? `${user?.first_name}+` : ""}${
    user?.last_name ? `${user?.last_name}` : ""
  }`;

  const goToSupport = () => {
    window.open("https://pigello.se/support/guider/", "_blank").focus();
  };

  const goToSettings = () => {
    push("/configcenter/groups-and-users");
  };

  const goToProfile = () => {
    push("/configcenter/profile/user");
  };

  const downloadReport = () => {
    window.open(report_url, "_blank").focus();
  };

  const handleDownloadPDF = () => {
    setLoading(true);
    dispatch(
      downloadPowerPointPDF({
        reportId: report_id,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "PDF laddas ned",
            })
          );
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej ladda ned PDF",
              description: "Försök igen",
            })
          );
        },
      })
    );
  };

  const reportDetailUrl = isPowerPointReport
    ? pptReportDetailUrl
    : excelReportDetailUrl;

  return (
    <SC.Wrapper formPadding>
      {loading && <OverlaySpinner />}
      <Logo noBorder borderRight />

      {!isReportView && <Search openBlankWindow />}

      {isReportView && (
        <SC.ReportViewWrapper>
          <TextButton
            extraStyle={{ marginRight: 24 }}
            title={
              report_id ? "Tillbaka till rapport" : "Gå till rapportöversikt"
            }
            iconType="arrow-back"
            clicked={() =>
              push(
                report_id
                  ? reportDetailUrl({ id: report_id })
                  : reportOverviewUrl()
              )
            }
          />
          {isPowerPointReport ? (
            <ExportPowerPointButton
              title="Ladda ned rapport"
              onClick={downloadReport}
            />
          ) : (
            <ExportExcelButton
              title="Ladda ned rapport"
              onClick={downloadReport}
            />
          )}

          {isPowerPointReport && (
            <PrimaryButton
              extraStyle={{ marginLeft: 24 }}
              title="Ladda ned som PDF"
              clicked={handleDownloadPDF}
            />
          )}
        </SC.ReportViewWrapper>
      )}
      <SC.TopBarItemsContainer>
        <SC.SupportLink onClick={goToSupport}>Support</SC.SupportLink>
        <SC.IconButton onClick={goToSettings} icon={settingsIcon} />
        <SC.IconButton onClick={() => setNotifsOpen(true)} icon={notifIcon}>
          <NotificationsPopup open={notifsOpen} setOpen={setNotifsOpen} />
        </SC.IconButton>
        <SC.ProfileBadge
          onClick={goToProfile}
          name={userName}
          image={userImg}
        />
      </SC.TopBarItemsContainer>
    </SC.Wrapper>
  );
};
