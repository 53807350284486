import { axiosInstance } from "../../base/store/axios";

async function getAccountsReceivableForDate({
  date,
  creditorId,
  excludeInvoicesWithPeriodOutsideDate,
}) {
  const data = await axiosInstance.get(
    `/accounting/gateways/account_receivables/${creditorId}/?date=${date}&showOnlyFinancedInvoices=false&excludeInvoicesWithPeriodOutsideDate=${
      excludeInvoicesWithPeriodOutsideDate || false
    }`
  );

  return data;
}

export { getAccountsReceivableForDate };
