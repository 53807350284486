import * as React from "react";
import * as SC from "./styles";

export default ({ clicked, disabled, title, extraStyle, pulse }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };

  return null;

  // return (
  //   <SC.InsightsButton
  //     style={{ ...extraStyle }}
  //     {...{ pulse }}
  //     disabled={disabled}
  //     onClick={handleClick}
  //   >
  //     {title}
  //   </SC.InsightsButton>
  // );
};
