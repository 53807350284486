import * as React from "react";
import { useBrfPremises, useBrfPremisesForm } from "../../../store/brfPremises";
import StandardModal from "../../Modals/StandardModal";
import { NumberInput } from "../Base/Fields";

import { constants, update } from "../../../store/brfPremises";
import { useDispatch } from "react-redux";
import { updateActiveFormInstance } from "../../../store/base";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { cloneDeep } from "lodash";

export default ({ isOpen, onCheckout, id }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);
  const [cooperativePremises] = useBrfPremises(id);

  useBrfPremisesForm("PATCH", id);

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: cooperativePremises,
      })
    );
  }, [cooperativePremises]);

  const preProcess = (data) => {
    const clone = cloneDeep(data);
    const relevantData = {
      fund_value: clone.fund_value,
      fund_quota: clone.fund_quota,
      id: clone.id,
    };

    return relevantData;
  };

  const handleSave = () => {
    setLoading(true);
    dispatch(
      update({
        id,
        preProcess,
        successCallback: () => {
          setLoading(false);
          onCheckout();
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <StandardModal
      title="Inställningar för inre fond"
      closeFunction={onCheckout}
      withActionBar
      isOpen={isOpen}
      saveFunction={handleSave}
    >
      {loading && <OverlaySpinner />}
      <NumberInput
        title="Saldo i fond (SEK)"
        storeName={storeName}
        fieldKey="fund_value"
        method="PATCH"
      />
      <NumberInput
        title="Andel av avgift som läggs undan i fond i %"
        description="Ange hur stor andel av månadsavgiften för bostadsrätten som placeras i inre fonden. Detta värde kommer läggas på saldot varje månad."
        storeName={storeName}
        fieldKey="fund_quota"
        method="PATCH"
      />
    </StandardModal>
  );
};
