import { constants } from "..";

const INITIAL_STATE = {
  results: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_RESULTS: {
      return { ...state, results: payload.formattedSearchResults };
    }
    case constants.CLEAR_RESULTS: {
      return { ...state, results: null };
    }
    default:
      return state;
  }
};
