import * as React from "react";

export default () => {
  return (
    <div>
      Grundinställningar för annonser. Dessa kan ändras per annons i
      granskningen på nästa steg.
    </div>
  );
};
