import styled from "styled-components";
import { animated } from "@react-spring/web";
import placeHolderImage from "../../assets/svg/company.svg";

export const MapWrapper = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
`;

export const RealEstateCard = styled(animated.div)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 12px;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.fadedBorder};
  border-radius: 5px;
  z-index: 1;
  width: 100%;
  min-width: 300px;
  max-width: 450px;
  color: ${(p) => p.theme.colors.primaryText};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-family: "Inter", sans-serif;
`;

export const RealEstateTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 6px;
  text-transform: uppercase;
`;

export const RealEstateInfoBox = styled.div`
  display: flex;
  margin: 12px 0;
`;

export const RealEstateProperties = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
`;

export const RealEstateImage = styled.div`
  height: 100px;
  width: 120px;
  min-width: 100px;
  border-radius: 3px;
  background-position: center;
  background-size: ${(p) => (p.src ? "cover" : "60%")};
  background-repeat: no-repeat;
  background-image: url(${(p) => (p.src ? p.src : placeHolderImage)});
  background-color: ${(p) => p.theme.colors.grayLight};
  margin: 0 6px 0 0;
  box-shadow: ${(p) => p.theme.boxShadows.small};
`;
