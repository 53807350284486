import * as React from "react";

// style, design

import { Checkbox, TimePicker } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/sendout";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InfoBox } from "../../../../Displays";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för utskick</OverviewTitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {method === "PATCH" && (
        <InfoBox
          title="OBS"
          text='Vid redigering av ett utskick som redan har skickats ut kommer ändringarna enbart att reflekteras inne på "Mina sidor". Utskicket kommer alltså inte att skickas om till mottagarna per E-post eller övriga kommunikationskanaler.'
        />
      )}

      <hr />

      <TimePicker
        storeName={storeName}
        fieldKey={"scheduled_for"}
        title="Tid för utskick"
        method={method}
      />

      <Checkbox
        storeName={storeName}
        fieldKey={"pinned"}
        title="Utskicket ska vara fastnålat"
        description='Detta innebär att utskicket är fastnålat högst upp på startsidan i "Mina sidor"'
        method={method}
      />
    </>
  );
};
