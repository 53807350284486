import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { useAllPermissionCheck } from "../../../store/base";

import SettingsTable from "../../../components/Tables/RoundingErrandSettings/FullTable";
import RoundingErrandSettingModalForm from "src/components/Forms/RoundingErrandSetting/ChapterForm/ModalForm";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function Settings() {
  const { filteredRealEstates } = useActiveFilters();

  const [createSettingOpen, setCreateSettingOpen] = React.useState(false);

  const persistantQuerySettings = {
    realestate_ids: filteredRealEstates,
    kind__isnull: false,
  };

  const canAdd = useAllPermissionCheck([
    "add_can_goverrand",
    "allow_errand_gov",
  ]);

  return (
    <>
      {canAdd && createSettingOpen && (
        <RoundingErrandSettingModalForm
          method="POST"
          isGov
          onCheckout={() => setCreateSettingOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper className="mb-0">
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Myndighetskrav</OverviewTitle>
              <OverviewSubtitle className="max-w-[90%]">
                Myndighetskravsscheman bestämmer vad för typ av myndighetskrav
                som ska genereras samt i vilket intervall. De myndighetskrav som
                genereras under ett schema följer de inställningarna som är
                inställda på schemat.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            {canAdd && (
              <PrimaryBtn onClick={() => setCreateSettingOpen(true)}>
                Lägg till myndighetskravsschema
              </PrimaryBtn>
            )}
          </OverviewTitleWrapper>
          <SettingsTable
            title="Myndighetskrav"
            persistantQuery={persistantQuerySettings}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
