import baseReducer from "../../billecta/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_KIVRA_SETTINGS: {
      return { ...newState, kivraSettings: payload.kivraSettings };
    }
    
    case constants.BULK_SET_INTEGRATION_SETTINGS: {
      // kivraSetting:{1:{}, 2:{}}
      // {3:{}}
      // {..., kivraSetting:{1:{}, 2:{}, 3:{}}}
      newState[payload.integrationName] = {}
      return { ...newState, [payload.integrationName]:{...(newState?.[payload.integrationName] ?? {}), ...payload.data}}
    }

    case constants.SET_PE_SETTINGS: {
      return { ...newState, peSettings: payload.peSettings };
    }

    case constants.SET_FORTNOX_SETTINGS: {
      return { ...newState, fortnoxSettings: payload.fortnoxSettings };
    }

    case constants.SET_VISMA_SETTINGS: {
      return { ...newState, vismaSettings: payload.vismaSettings };
    }

    case constants.SET_AUTOGIRO_SETTINGS: {
      return { ...newState, autogiroSettings: payload.autogiroSettings };
    }

    case constants.SET_EINVOICE_SETTINGS: {
      return { ...newState, eInvoiceSettings: payload.eInvoiceSettings };
    }

    case constants.CLEAR_INTEGRATION_STATE: {
      return {
        ...newState,
        kivraSettings: null,
        peSettings: null,
        fortnoxSettings: null,
        vismaSettings: null,
        autogiroSettings: null,
        eInvoiceSettings: null,
      };
    }

    default:
      return newState;
  }
};
