import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { TimePicker, NumberInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/IMDPriceValue";
import useActiveFilters from "../../../../../hooks/useActiveFilters";

import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();
  const storeName = constants.STORE_NAME;

  const realEstateQuery = {
    id__in: filteredRealEstates,
  };

  return (
    <>
      <FormAreaTitle>Pris</FormAreaTitle>
      <FormAreaDescription>Ange information om priset</FormAreaDescription>
      <TimePicker
        title="Startdatum för detta pris"
        description="Priset kommer att gälla för alla debiteringar av denna mätartyp under den angivna fastigheten till dess att ett pris med nyare startdatum tar över"
        storeName={storeName}
        method={method}
        fieldKey="from_date"
      />

      <NumberInput
        title="Pris i SEK/enhet"
        description="Pris i SEK per enhet, exempelvis pris per kubikmeter vatten om det är det inställningen för mätartypen avser"
        storeName={storeName}
        method={method}
        fieldKey="value"
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={realEstateQuery}
          placeholder="Välj fastighet..."
          title="Gäller för fastighet"
          method={method}
          fieldKey="realestate"
          TableComponent={RealEstateTable}
        />
      </div>
    </>
  );
};
