import * as React from "react";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useParams, useHistory } from "react-router";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/Forms/Base/Buttons";
import { useCompanyInvoicing } from "../../../store/invoicingCompany";
import {
  detailUrl as settingDetailUrl,
  useInvoicingSetting,
} from "../../../store/invoicingSettings";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { EmailTag, LinkedObject, PhoneTag } from "../../../components/Displays";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import CompanyInvoicingForm from "../../../components/Forms/InvoicingCompany/ChapterForm/ModalForm";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import useQuery from "../../../components/utils/useQuery";
import { useBrfCompany } from "../../../store/brfCompanies";
import CompanyPaymentMethods from "../../../components/Invoicing/CompanyPaymentMethods/CompanyPaymentMethods";

export default function BrfCompanyInvoicing() {
  const { brfCompanyId } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);

  const { push } = useHistory();

  const [brfCompany] = useBrfCompany(brfCompanyId);
  const company = brfCompany?.company;

  const query = useQuery();
  const openSettings = query.get("opensettings") == "true";

  React.useEffect(() => {
    if (openSettings && brfCompany) {
      setCreateOpen(true);
    }
  }, [openSettings, brfCompany]);

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      company: company?.id,
    },
  });

  const [companyInvoiceConfig, companyInvoiceConfigLoading] =
    useCompanyInvoicing(company?.invoicing_details?.id);
  const [selectedInvoicingSetting] = useInvoicingSetting(
    company?.invoicing_config?.id
  );
  const initialPostData = {
    company: {
      id: company?.id,
    },
    vat_number: `SE${company?.orgnr}01`,
    invoice_address: company?.address,
  };

  const errorQuery = {
    company: company?.id,
  };

  return (
    <>
      <CompanyInvoicingForm
        method="POST"
        isOpen={createOpen}
        onCheckout={() => setCreateOpen(false)}
        instance={initialPostData}
      />

      <CompanyInvoicingForm
        method="PATCH"
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        instance={companyInvoiceConfig}
        id={companyInvoiceConfig?.id}
      />

      <DetailInnerWrapper>
        {errorCount > 0 && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle small>Felmeddelanden</OverviewTitle>
            </OverviewTitleWrapper>

            <InvoicingErrorTable
              isBare
              ignoreLocalStorage
              persistantQuery={errorQuery}
            />
          </DetailPageBox>
        )}

        <DetailPageBox>
          {companyInvoiceConfigLoading && <OverlaySpinner />}
          <OverviewTitleWrapper>
            <OverviewTitle>Bolagsinställningar</OverviewTitle>

            <div style={{ display: "flex", alignItems: "center" }}>
              {companyInvoiceConfig && company?.billecta_id && (
                <SecondaryButton
                  extraStyle={{ marginRight: 12 }}
                  title={
                    company?.kyc_report ? "Uppdatera KYC" : "Registrera KYC"
                  }
                  clicked={() => {
                    push(
                      `/rental-notifications/company/${company.billecta_id}/kyc`
                    );
                  }}
                />
              )}
              {companyInvoiceConfig && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditOpen(true)}
                />
              )}
            </div>
          </OverviewTitleWrapper>

          {!companyInvoiceConfig ? (
            <div
              style={{
                width: "100%",
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                title="Sätt upp aviseringsinställning för bolag"
                clicked={() => setCreateOpen(true)}
              />
            </div>
          ) : (
            <>
              <DetailInfo
                infoObj={getInfoObj({
                  companyInvoiceConfig,
                  selectedInvoicingSetting,
                })}
              />
            </>
          )}
        </DetailPageBox>

        {companyInvoiceConfig && (
          <CompanyPaymentMethods {...{ companyInvoiceConfig }} />
        )}
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ companyInvoiceConfig, selectedInvoicingSetting }) {
  const infoObj = {
    Bolagsinfo: [
      {
        title: "Momsnummer",
        value: companyInvoiceConfig?.vat_number || "-",
      },

      {
        title: "Fakturaadress",
        value:
          companyInvoiceConfig.invoice_address?.base?.split(",")?.[0] || "-",
      },
      {
        title: "Fakturaadress postnr.",
        value: companyInvoiceConfig.invoice_address?.postal_code || "-",
      },
      {
        title: "Fakturaadress stad",
        value: companyInvoiceConfig.invoice_address?.city || "-",
      },
      {
        title: "Fakturaadress land",
        value: companyInvoiceConfig.invoice_address?.country || "-",
      },
      {
        title: "Attention",
        value: companyInvoiceConfig.attention || "-",
      },
      {
        title: "Bolagets säte",
        value: companyInvoiceConfig.residence || "-",
      },
      {
        title: "c/o",
        value: companyInvoiceConfig.care_of || "-",
      },
    ],
    "Leverans av fakturor": [
      {
        title: "Bolagets referens",
        value: companyInvoiceConfig?.our_reference || "-",
      },
      {
        title: "Standard leveransmetod",
        value: companyInvoiceConfig.delivery_method_display || "-",
      },
      {
        title: "E-post på fakturor",
        value: <EmailTag mail={companyInvoiceConfig.invoice_email} />,
      },
      {
        title: "Telefon på fakturor",
        value: <PhoneTag phone={companyInvoiceConfig.invoice_phone} />,
      },
    ],
    Betalning: [
      {
        title: "Primär betalmetod",
        value: companyInvoiceConfig.primary_payment_method_display || "-",
      },
      {
        title: "Bankgiro",
        value: companyInvoiceConfig.bankgiro || "-",
      },
      {
        title: "Plusgiro",
        value: companyInvoiceConfig.plusgiro || "-",
      },
      {
        title: "Bankkonto clearing",
        value: companyInvoiceConfig.clearing_number || "-",
      },
      {
        title: "Bankkonto",
        value: companyInvoiceConfig.bank_account || "-",
      },
      {
        title: "IBAN",
        value: companyInvoiceConfig.iban || "-",
      },
      {
        title: "BIC/Swift",
        value: companyInvoiceConfig.bic || "-",
      },
      {
        title: "GLN",
        value: companyInvoiceConfig.gln || "-",
      },
    ],
    Standardinställningar: [
      {
        title: "Föreslagen inställning för avier",
        value: selectedInvoicingSetting ? (
          <LinkedObject
            obj={selectedInvoicingSetting}
            urlMethod={settingDetailUrl}
          />
        ) : (
          "Ingen specificerad"
        ),
      },
      {
        title: "Administrationavgift",
        value: companyInvoiceConfig.admin_fee + " SEK",
      },
      {
        title: "Använd öresavrundning",
        value: companyInvoiceConfig.use_cent_rounding ? "Ja" : "Nej",
      },
      {
        title: "Visa obetalda avier på avi",
        value: companyInvoiceConfig.show_unpaid_next ? "Ja" : "Nej",
      },
      {
        title: "Använd kundsaldo",
        value: companyInvoiceConfig.use_debtor_balance ? "Ja" : "Nej",
      },
    ],
  };

  if (companyInvoiceConfig.contact_person) {
    infoObj["Kontaktperson på avier"] = [
      {
        title: "Titel",
        value: companyInvoiceConfig.contact_person?.title || "-",
      },
      {
        title: "Namn",
        value: companyInvoiceConfig.contact_person?.name || "-",
      },
      {
        title: "Telefon",
        value: companyInvoiceConfig.contact_person?.phone || "-",
      },
      {
        title: "E-post",
        value: companyInvoiceConfig.contact_person?.email || "-",
      },
    ];
  }

  if (companyInvoiceConfig.claims_contact) {
    infoObj["Kontaktperson för krav"] = [
      {
        title: "Titel",
        value: companyInvoiceConfig.claims_contact?.title || "-",
      },
      {
        title: "Namn",
        value: companyInvoiceConfig.claims_contact?.name || "-",
      },
      {
        title: "Telefon",
        value: companyInvoiceConfig.claims_contact?.phone || "-",
      },
      {
        title: "E-post",
        value: companyInvoiceConfig.claims_contact?.email || "-",
      },
    ];
  }

  if (companyInvoiceConfig.signatory_contact) {
    infoObj["Firmatecknare"] = [
      {
        title: "Titel",
        value: companyInvoiceConfig.signatory_contact?.title || "-",
      },
      {
        title: "Namn",
        value: companyInvoiceConfig.signatory_contact?.name || "-",
      },
      {
        title: "Telefon",
        value: companyInvoiceConfig.signatory_contact?.phone || "-",
      },
      {
        title: "E-post",
        value: companyInvoiceConfig.signatory_contact?.email || "-",
      },
    ];
  }

  return infoObj;
}
