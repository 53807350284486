import * as React from "react";
import Table from "../../../components/Billecta/Table/BasicTable";
import { DateCell } from "../../../components/Displays";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import {
  detailUrl as requirementDetailUrl,
  useFilteredMarketingRequirements,
} from "../../../store/marketRequirements";
import { RemoveButton } from "../../../components/Billecta/Invoices/InvoiceComponents/Multiple/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";

export default function PublishNewProductionTable({
  adsInstance,
  setEditId,
  onSelected,
  type,
}) {
  const [requirements] = useFilteredMarketingRequirements("");

  const data = React.useMemo(() => {
    return adsInstance.map((ad) => ({
      ...ad,
      requirement: {
        id: ad.requirement,
        str_representation: requirements?.find((r) => r.id == ad.requirement)
          ?.str_representation,
      },
    }));
  }, [adsInstance, requirements]);

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "",
        id: "remove",
        Cell: ({ row }) => {
          return (
            <RemoveButton
              onClick={(e) => {
                e.stopPropagation();
                onSelected(row);
              }}
            />
          );
        },
      },

      {
        Header: "Lägenhetsnummer",
        accessor: "apartment_id",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },

      {
        Header: "Objektsnummer",
        accessor: "premises_id",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },

      {
        Header: "Adress",
        accessor: "address.base",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },

      {
        Header: "Tillträdesdatum",
        accessor: "access_date",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },

      {
        Header: "Kravprofil",
        accessor: "requirement.str_representation",
        Cell: ({ value, row }) => {
          const url = requirementDetailUrl({
            id: row.original.requirement?.id,
          });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      },

      {
        Header: "Tidigare inflytt möjlig",
        accessor: "prior_access",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
    ];

    if (data?.some((d) => !!d.error)) {
      cols.splice(1, 0, {
        Header: "Felmeddelande",
        accessor: "error",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      });
    }

    return cols;
  }, [type, data]);

  return (
    <Table
      columns={tableColumns}
      data={data}
      onRowClicked={(row) => setEditId(row.original.id)}
      checkRowError={(row) => !!row.original.error}
    />
  );
}
