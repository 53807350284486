import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { performMultipleInvoiceSearches } from "..";
import { useInProgress } from "../../base";
import { performDebtorSearch } from "../store/actions";
import constants from "../store/constants";

export const useInvoicesFromMultipleSearchResults = ({
  searchId,
  searchInstances,
  typeConstants,
  errorCallback,
}) => {
  const name = `search_${searchId}`;
  const dispatch = useDispatch();
  const storeName = typeConstants?.STORE_NAME;

  const currentResult = useSelector((state) => state[storeName][name]);

  const inProgress = useInProgress({ storeName, name });

  if (!searchId || !searchInstances || !typeConstants) {
    return [undefined, false];
  }

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (!currentResult) {
    dispatch(
      performMultipleInvoiceSearches({
        name,
        searchInstances,
        typeConstants,
        errorCallback,
      })
    );
    return [undefined, true];
  }

  return [currentResult, false];
};

export const useInvoicesForContractBasedOnDebtor = ({
  debtorId,
  creditorId,
  searchId,
  errorCallback,
}) => {
  const name = `${searchId}`;
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);
  const [results, setResult] = React.useState(undefined);

  const currentResult = useSelector((state) => state[storeName][name]);

  const inProgress = useInProgress({ storeName, name });

  React.useEffect(() => {
    if (!searchId || !debtorId) {
      setResult(undefined);
      setLoading(false);
      return;
    }

    if (inProgress) {
      setLoading(true);
      setResult(undefined);
      return;
    }

    if (!currentResult) {
      dispatch(
        performDebtorSearch({
          debtorId,
          creditorId,
          errorCallback,
          name,
        })
      );
      setLoading(true);
      setResult(undefined);
      return;
    }

    setResult(currentResult);
    setLoading(false);
  }, [
    debtorId,
    creditorId,
    searchId,
    errorCallback,
    inProgress,
    currentResult,
  ]);

  return [results, loading];
};
