import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import User from "../../components/Details/Account/User";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import * as SharedStyles from "../../components/sharedStyles";
import { buildQueryString, useAllPermissionCheck } from "../../store/base";
import { useFilteredUserGroups } from "../../store/userGroups";
import appConstants from "../../store/app/constants";

import ConfirmModal from "../../components/Forms/Base/Modals/ConfirmModal";
import { resetPassword } from "../../store/users";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import { logout } from "../../store/app/actions";

import { useUser } from "../../store/users";

import PageTitle from "../Layouts/PageTitle/PageTitle";

import { setToastForLoginPage } from "../../utils";

const STEPS = {
  GROUP: "GROUP",
  PERMS: "PERMS",
  DETAIL: "DETAIL",
};

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const userId = useSelector((state) => state.app.user?.id);
  const hasMfa = useSelector((state) => state.app.user?.has_mfa);
  const [user] = useUser(userId);

  const [passwordConfirmationActive, setPasswordConfirmationActive] =
    React.useState(false);

  const isUtilUser = user?.util_user;

  const showActivateMFA = hasMfa != true;

  const canEditUser = useAllPermissionCheck([
    "view_can_user",
    "change_can_user",
  ]);

  // only subgroups

  const groupsQuery = buildQueryString({
    users__id: user?.id,
  });
  const [groups] = useFilteredUserGroups(groupsQuery);
  const group = groups?.find((g) => g.users.find((u) => u.id === user?.id));

  const goToEdit = () => {
    push(`/configcenter/profile/user/edit/${userId}`);
  };

  const showResetPassword = () => {
    setPasswordConfirmationActive(true);
  };

  const resetPasswordErrorCallback = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Ett fel uppstod",
        description: "Kunde inte återställa ditt lösenord. Prova ingen.",
      })
    );
  };

  const resetPasswordSuccessCallback = () => {
    setToastForLoginPage({
      type: TOAST_TYPES.SUCCESS,
      title: "Lösenord återställt",
      description: `Ett mail har skickats till ${user.email} med instruktioner för att ändra lösenord.`,
    });

    dispatch(logout());
  };

  const doResetPassword = () => {
    //Won't work until merge with user.actual_username fixed

    if (!user?.actual_username || !user?.email) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Ett fel har uppstått",
          description: "Ladda om sidan och försök igen",
        })
      );

      return;
    }

    dispatch(
      resetPassword({
        username: user.actual_username,
        email: user.email,
        successCallback: resetPasswordSuccessCallback,
        errorCallback: resetPasswordErrorCallback,
      })
    );
  };

  const breadCrumbs = [
    {
      label: "Preferenser",
      url: "/configcenter/profile/user",
    },
    {
      label: "Uppgifter",
    },
  ];

  let actions = [];

  if (canEditUser) {
    actions.push({
      name: "Redigera",
      onClick: () => goToEdit(),
    });

    actions.push({
      name: "Återställ lösenord",
      onClick: showResetPassword,
    });
  }

  if (showActivateMFA) {
    actions.push({
      name: "Aktivera 2-faktors autentisering",
      onClick: () => {
        dispatch({
          type: appConstants.MFA_SETTING,
          payload: {
            show: true,
            enforce: false,
          },
        });
      },
    });
  }

  return (
    <SharedStyles.BareLayoutWrapper>
      {!user && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="Preferenser" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Min användare</OverviewTitle>
          {actions?.length > 0 && !isUtilUser && (
            <PopupButton title="Hantera" actions={actions} />
          )}
        </OverviewTitleWrapper>

        {user && !isUtilUser && <User user={user} group={group} />}

        {passwordConfirmationActive && (
          <ConfirmModal
            onAccept={() => doResetPassword()}
            closeFunction={() => setPasswordConfirmationActive(false)}
          >
            <h3 className="mb-2 text-base font-normal text-slate-700 ">
              Vill du verkligen återställa ditt lösenord?
            </h3>

            <p>
              Du kommer att loggas ut och ett mail kommer skickas till dig med
              vidare instruktioner.
            </p>
            <br></br>
            <p>Kontrollera att detta är rätt emailadress: {user.email}</p>
          </ConfirmModal>
        )}

        {isUtilUser && (
          <SharedStyles.InnerBox>
            Denna användare är en extern förvaltare och kan därmed inte
            redigeras.
          </SharedStyles.InnerBox>
        )}
      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
