export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_OUTLOOKCALENDAR",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_OUTLOOKCALENDAR",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_OUTLOOKCALENDAR",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_OUTLOOKCALENDAR",
  DESTROY_FORM: "DESTROY_FORM_OUTLOOKCALENDAR",
  SET_FORM_ERROR: "SET_FORM_ERROR_OUTLOOKCALENDAR",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_OUTLOOKCALENDAR",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_OUTLOOKCALENDAR",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_OUTLOOKCALENDAR",
  RESET_STATE: "RESET_STATE_OUTLOOKCALENDAR",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_OUTLOOKCALENDAR",
  REMOVE_OBJECT: "REMOVE_OBJECT_OUTLOOKCALENDAR",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_OUTLOOKCALENDAR",
  CLEAR_FETCHED: "CLEAR_FETCHED_OUTLOOKCALENDAR",

  LIST_URL: "/events/outlook/calendar_proposal/",

  STORE_NAME: "outlookCalendar",
};
