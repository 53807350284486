export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_ROUNDING_ERRAND_SETTING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_ROUNDING_ERRAND_SETTING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_ROUNDING_ERRAND_SETTING",
  DESTROY_FORM: "DESTROY_FORM_ROUNDING_ERRAND_SETTING",
  SET_FORM_ERROR: "SET_FORM_ERROR_ROUNDING_ERRAND_SETTING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_ROUNDING_ERRAND_SETTING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_ROUNDING_ERRAND_SETTING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_ROUNDING_ERRAND_SETTING",
  RESET_STATE: "RESET_STATE_ROUNDING_ERRAND_SETTING",
  CLEAR_FETCHED: "CLEAR_FETCHED_ROUNDING_ERRAND_SETTING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_ROUNDING_ERRAND_SETTING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_ROUNDING_ERRAND_SETTING",
  REMOVE_OBJECT: "REMOVE_OBJECT_ROUNDING_ERRAND_SETTING",
  SET_CUSTOM_FORM_ERROR: "SET_CUSTOM_FORM_ERROR",

  LIST_URL: "/errands/settings/roundingerrandsetting/list/",
  POST_URL: "/errands/settings/roundingerrandsetting/",
  PATCH_URL: "/errands/settings/roundingerrandsetting/",
  GET_URL: "/errands/settings/roundingerrandsetting/",

  STORE_NAME: "roundingErrandsSetting",
};
