import * as React from "react";
import { useSpring } from "@react-spring/web";

import * as SC from "./styles";

export default ({ extraStyle, currentCount, totalCount }) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const percentage = Math.round((currentCount / totalCount) * 100);
  return (
    <SC.CoverOverlay style={extraStyle ? { ...props, ...extraStyle } : props}>
      <div className="w-full h-full flex flex-col items-center"
      style={{
        position: "relative",
        top: "300px"
      }}>
        <SC.SpinningCircleContainer viewBox="0 0 50 50">
          <SC.SpinningCircle
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke="#93bfec"
            strokeWidth="3"
          />
        </SC.SpinningCircleContainer>
        <div className="flex flex-col justify-between mb-1 mt-2">
          <p className="text-base text-center font-medium text-blue-700 ">
            Laddar
          </p>
          <p className="text-sm text-center font-medium text-blue-700 ">
            {currentCount}/{totalCount}
          </p>
        </div>
        <div className="w-[40%] max-w-[250px] bg-gray-200 rounded-full h-2.5 mt-2">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </SC.CoverOverlay>
  );
};
