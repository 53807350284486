import * as React from "react";

import { destroyPatchForm } from "../../../../store/editabledocs";

import { useDispatch } from "react-redux";
import DigitalDocContainer from "../../../DigitalDoc/Container";

export default function EditableDocContractContainer({
  contract,
  onSubmit,
  detailUrl,
  onClearDocumentCallback,
  isCancelledDoc,
}) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(true));
    };
  }, []);

  return (
    <DigitalDocContainer
      onClearDocumentCallback={onClearDocumentCallback}
      onSubmit={onSubmit}
      contract={contract}
      detailUrl={detailUrl}
      isCancelledDoc={isCancelledDoc}
      id={
        isCancelledDoc ? contract?.cancelled_doc?.id : contract?.editabledoc?.id
      }
    />
  );
}
