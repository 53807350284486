import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import {
  getMemberDisplay,
  getMemberState,
} from "src/components/Tables/Tenants/listDefs";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import {
  useAllPermissionCheck,
  useFrequentPermissions,
} from "../../store/base";
import { useDebtorInvoicing } from "../../store/invoicingDebtor";
import { useTenant, constants, overviewUrl } from "../../store/tenants";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import MemberPremises from "./detail/BrfPremises";
import MemberContracts from "./detail/Contracts";
import MemberInvoicing from "./detail/Invoicing";
import MemberOverview from "./detail/Overview";
import MemberErrands from "./detail/Errands";
import TenantPremises from "../Tenant/detail/Premises";

export default function MemberDetail() {
  const { tenantId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const [member] = useTenant(tenantId);
  const [invoiceSettingPromptOpen, setInvoicingSettingPromtOpen] =
    React.useState(false);
  const { push } = useHistory();
  const query = useQuery();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  usePermissionRedirect(["allow_brf", "view_can_tenant", "view_can_brf"]);

  const canDeleteTenant = useAllPermissionCheck([
    "delete_can_tenant",
    "delete_can_brf",
  ]);
  const canViewReportErrands = useAllPermissionCheck([
    "allow_errand_faults",
    "view_can_reporterrand",
  ]);
  const { hasBillectaViewPermission, hasBillectaFullPermission } =
    useFrequentPermissions();
  const showInvoicingSettingPrompt = query.get("showInvoicingSettingPrompt");

  const [debtorInvoiceConfig] = useDebtorInvoicing(
    member?.debtor_invoice_config?.id
  );

  React.useEffect(() => {
    if (hasBillectaFullPermission && showInvoicingSettingPrompt == "true") {
      setInvoicingSettingPromtOpen(true);
    }
  }, [showInvoicingSettingPrompt, hasBillectaFullPermission]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Medlem",
      component: MemberOverview,
    },
    {
      path: "/brf-premises",
      label: "Bostadsrätter",
      component: MemberPremises,
    },
    {
      path: "/premises",
      label: "Hyresobjekt",
      component: TenantPremises,
    },
    {
      path: "/contracts",
      label: "Avtal & Dokument",
      component: MemberContracts,
    },
  ];

  if (hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      component: MemberInvoicing,
    });
  }
  if (canViewReportErrands) {
    SUB_PAGES.push({
      path: "/errands",
      label: "Ärenden",
      component: MemberErrands,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canDeleteTenant) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      iseDelete: true,
    });
  }

  const goToInvoicing = () => {
    push(`/members/detail/${tenantId}/invoicing?opensettings=true`);
  };

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel contentType="tenant" state={getMemberState(member?.state)}>
          {getMemberDisplay(member?.state)}
        </StatusLabel>
        <StatusLabel
          style={{ marginLeft: 12 }}
          state={debtorInvoiceConfig ? 1 : 6}
        >
          {debtorInvoiceConfig
            ? "Uppsatt för avisering"
            : "Ej uppsatt för avisering"}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={member}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <ConfirmationModal
        isOpen={invoiceSettingPromptOpen}
        closeFunction={() => setInvoicingSettingPromtOpen(false)}
        title="Vill du konfigurera aviseringsinställningar för denna medlem direkt?"
        acceptTitle="Konfigurera nu"
        acceptCallback={goToInvoicing}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Medlem ${member?.str_representation || "laddar..."}`}
          breadCrumbs={[
            { url: "/members", label: "Medlemmar" },
            { label: member?.str_representation || "Laddar..." },
          ]}
          eventContentType="accounts.tenant"
          eventObjectId={tenantId}
          eventIdQueryStr="tenant"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
