import * as React from "react";
// style, design

// store, state
import { constants } from "../../../../../store/invoicingDebtor";

import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import InvoicingCustomDimensionsTable from "src/components/Tables/InvoicingCustomDimensions/FullTable";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingCustomDimensionsEntriesTable from "src/components/Tables/InvoicingCustomDimensionEntries/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const selectedDimension = useFormInstanceField({
    storeName,
    fieldKey: "_interal_dimension.id",
  });
  const selectedDimensionEntry = useFormInstanceField({
    storeName,
    fieldKey: "additional_dimension.dimension_entry.id",
  });

  const persistantQuery = {
    dimension: selectedDimension,
  };

  const clearDimension = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          additional_dimension: null,
        },
      })
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Detaljerade bokföringsinställningar</OverviewTitle>
          <OverviewSubtitle>Specifika bokföringsdimensioner</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <div className="grid grid-cols-2 gap-6 mb-6">
        {!selectedDimensionEntry && (
          <TableSelectField
            forceRender
            storeName={storeName}
            method={method}
            fieldKey="_interal_dimension"
            title="Anpassad dimension"
            placeholder={"Välj anpassad dimension..."}
            TableComponent={InvoicingCustomDimensionsTable}
          />
        )}

        {(selectedDimension || selectedDimensionEntry) && (
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey="additional_dimension.dimension_entry"
            title="Instans av dimension"
            placeholder={"Välj instans av dimension..."}
            persistantQuery={persistantQuery}
            TableComponent={InvoicingCustomDimensionsEntriesTable}
          />
        )}
      </div>

      {selectedDimensionEntry && (
        <TextButton
          title="Ta bort den valda dimensionen"
          iconType="sync"
          iconPlacement="right"
          red
          clicked={clearDimension}
        />
      )}
    </>
  );
};
