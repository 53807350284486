import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useTenant } from "../../../store/tenants";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import ErrandsTable from "../../../components/Tables/ReportErrands/FullTableNewVersion";

function MemberErrands() {
  const { tenantId } = useParams();
  const [tenant] = useTenant(tenantId);

  const errandsQuery = {
    reported_by: tenant?.user?.id,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Felanmälningar</OverviewTitle>
        </OverviewTitleWrapper>
        <ErrandsTable persistantQuery={errandsQuery} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default MemberErrands;
