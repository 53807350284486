import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

// style, design
import * as SC from "../../../Base/Chapters/styles";
import { ColorPicker } from "../../../Base/Fields";
import FullPageSpinner from "../../../../Loaders/FullPageSpinner";
import { LinkButton, PrimaryButton } from "../../../Base/Buttons";

// store, state
import {
  constants,
  update,
  destroyPatchForm,
  useConfigCenterForm,
} from "../../../../../store/configcenter";

import {
  setActiveFormInstance,
  usePermissionCheck,
} from "../../../../../store/base";

import { useHistory } from "react-router-dom";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import { OverviewTitle } from "../../../../Details/OverviewInfo/styles";

export default ({ method, untouchedInstance, nextUrl }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { push, goBack } = useHistory();

  const formLoaded = Boolean(
    useConfigCenterForm(method, storeName, untouchedInstance?.id)
  );

  const hasChanged = useSelector((state) => {
    return !isEqual(untouchedInstance, state[storeName].formInstance);
  });

  const canSubmit = usePermissionCheck(`change_can_configcenter`);

  React.useEffect(() => {
    dispatch(
      setActiveFormInstance({
        storeName,
        data: untouchedInstance,
      })
    );
  }, [untouchedInstance]);

  const checkout = (success) => {
    setLoading(false);
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
      push(nextUrl || `/configcenter/insights`);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: (data, returnData) => {
            // set current configcenter so form and overview are updated properly
            dispatch({
              type: constants.SET_CURRENT_CONFIGCENTER,
              payload: {
                returnData,
              },
            });
            checkout(true);
          },
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  if (!formLoaded) {
    return <FullPageSpinner />;
  }

  const colorPickers = []
  for (let i=0; i < 5; i++) {
    colorPickers.push(
      <ColorPicker
        title={`Färgkod ${i+1}`}
        storeName={storeName}
        method={method}
        fieldKey={`insights_color_scheme[${i}]`}
        instructionsKey={"insights_color_scheme"}
        blockPicker
      />
    )

  }

  return (
    <>
      <SC.FormArea>
        {loading && <OverlaySpinner />}

        <OverviewTitle small>Färger för widgets</OverviewTitle>

        <div style={{ display: "flex", flexDirection: "row", marginBottom: "100px", width: "800px", justifyContent: "space-around", marginTop: "20px"}}>
        {colorPickers}
        </div>


        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <LinkButton clicked={() => goBack()} title={"Avbryt"} />
          <PrimaryButton
            newDesign
            clicked={onSubmit}
            title={method === "POST" ? "Lägg till" : "Uppdatera"}
            disabled={!canSubmit || !hasChanged}
          />
        </div>
      </SC.FormArea>
    </>
  );
};
