import * as React from "react";
import { Wrapper } from "../";
import { Link } from "react-router-dom";
import {
  BuildingOffice2Icon,
  IdentificationIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { useCompany, detailUrl } from "../../../store/companies";

export default function SmallCompanySummary({ id, className }) {
  const [company, companyLoading] = useCompany(id);

  if (!id) return null;

  return (
    <Wrapper className={className}>
      <div className="flex">
        <div className="mr-3 shrink-0">
          {company?.image?.get ? (
            <img
              className="w-8 h-8 rounded-lg object-cover"
              src={company.image.get}
            />
          ) : (
            <BuildingOffice2Icon width={32} className="text-slate-500" />
          )}
        </div>
        <div>
          <div className="mb-3 text-base font-semibold leading-none text-gray-700 hover:underline">
            <Link to={detailUrl({ id })}>
              {company?.str_representation || "Laddar..."}
            </Link>
          </div>

          <ul className="text-sm font-light">
            <li className="flex items-center mb-1">
              <IdentificationIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />

              <span>{company?.orgnr || "-"}</span>
            </li>
            <li className="flex items-center mb-1">
              <MapPinIcon
                width={16}
                className="mr-1 text-slate-500 font-semibold"
              />

              <span>{company?.address?.base?.split(",")?.[0] || "-"}</span>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}
