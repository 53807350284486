import * as React from "react";

// style, design
import { FormAreaDescription, FormAreaTitle } from "../Base/Chapters/styles";

// store, state

import {
  create,
  destroyPatchForm,
  destroyPostForm,
  update,
  detailUrl,
} from "../../../store/industrialPremises";
import StandardModal from "../../Modals/StandardModal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateActiveFormInstance } from "../../../store/base";
import {
  constants,
  useIndustrialPremisesForm,
} from "../../../store/industrialPremises";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import ManyMediaField from "../Base/Fields/ManyMediaField";
import { cloneDeep } from "lodash";

export default ({ method, open, setOpen, instance }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;

  const formLoaded = Boolean(useIndustrialPremisesForm(method, instance?.id));

  const preProcess = (data) => {
    const clone = cloneDeep(data);

    return { images: clone.images };
  };

  React.useEffect(() => {
    if (formLoaded && instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: { ...instance },
        })
      );
    }
  }, [formLoaded, open]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    setOpen(false);
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      let id = instance.id;
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  return (
    <>
      <StandardModal
        isOpen={open}
        closeFunction={() => setOpen(false)}
        title={"Bildhantering"}
        withActionBar
        saveFunction={onSubmit}
      >
        {(!formLoaded || loading) && <OverlaySpinner />}
        {formLoaded ? (
          <>
            <FormAreaTitle>Bilder</FormAreaTitle>
            <FormAreaDescription>
              Redigera och ladda upp bilder
            </FormAreaDescription>

            <ManyMediaField
              storeName={storeName}
              method={method}
              fieldKey="images"
              fileKey="image"
              title="Bilder på objektet"
              allowedFormats={[".jpg", ".jpeg", ".png"]}
            />
          </>
        ) : (
          "Laddar..."
        )}
      </StandardModal>
    </>
  );
};
