import * as React from "react";
import MediaDisplay from "../../Details/Images/MediaDisplay";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import StandardModal from "../../Modals/StandardModal";
import { InnerBox } from "../../sharedStyles";

export default function ChecklistRowHandleModal({
  isOpen,
  closeFunction,
  row,
  onEdit,
}) {
  return (
    <StandardModal
      saveFunction={onEdit}
      actionBarAcceptTitle="Redigera rad"
      actionBarCancelTitle="Stäng"
      withActionBar
      title="Hantera rad"
      isOpen={isOpen}
      closeFunction={closeFunction}
    >
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Hantera rad</OverviewTitle>
          <OverviewSubtitle>
            Tryck på redigera för att göra ändringar
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <DetailInfo infoObj={getInfoObj({ row })} />

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Instruktioner</OverviewTitle>
        </OverviewTitleWrapper>

        <InnerBox>{row?.description}</InnerBox>
      </InnerBox>

      <InnerBox style={{ marginTop: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Filer på rad</OverviewTitle>
        </OverviewTitleWrapper>
        <MediaDisplay files={row?.files} fileKey={"file"} />
      </InnerBox>
    </StandardModal>
  );
}

function getInfoObj({ row }) {
  return {
    "Rad i checklista": [
      {
        title: "Titel",
        value: row?.title,
      },
      {
        title: "Aktiv för självavhjälpande",
        value: (
          <StatusLabel state={row?.hide_for_tenants ? 3 : 0}>
            {row?.hide_for_tenants ? "Nej" : "Ja"}
          </StatusLabel>
        ),
      },
      {
        title: "Aktiv för utförande",
        value: (
          <StatusLabel state={row?.hide_for_non_tenants ? 3 : 0}>
            {row?.hide_for_non_tenants ? "Nej" : "Ja"}
          </StatusLabel>
        ),
      },
    ],
  };
}
