import styled from "styled-components";

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const FilterButton = styled.div`
  cursor: pointer;
  border: thin solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: #e8e8e8;
  color: #2e2e2e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 100%;
  margin-right: 6px;
  margin-bottom: 6px;

  opacity: ${(p) => (p.selected ? 1 : 0.5)};
`;
