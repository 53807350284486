import * as React from "react";
import * as ISC from "../../../Details/InfoTable/styles";

import { GenericCell, TimeCell } from "../../../Displays";

export default ({ instance }) => {
  return (
    <ISC.InfoTable>
      {/* <ISC.InfoTableTitleWrapper>
        <ISC.InfoTableTitle>
          <ISC.InfoTableTitle>
            {instance?.title ? instance.title : "Titel saknas"}
          </ISC.InfoTableTitle>
          <ISC.InfoTableSubtitle>Titel</ISC.InfoTableSubtitle>
        </ISC.InfoTableTitle>
      </ISC.InfoTableTitleWrapper> */}

      <ISC.InfoTableContent>
        {/* <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Kalender</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.calendar
              ? constructStrRep(instance.calendar)
              : "Kalender saknas"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow> */}

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Beskrivning</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.description
              ? instance.description
              : "Beskrivning saknas"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        {/* <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Start</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.start ? (
              <TimeCell date={instance.start} />
            ) : (
              "Start tid saknas"
            )}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow> */}

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Klar senast</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.end ? <TimeCell date={instance.end} /> : "-"}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Kopplat objekt</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.object_id && instance.content_type ? (
              <GenericCell
                contentType={instance.content_type}
                id={instance.object_id}
              />
            ) : (
              "Objekt saknas"
            )}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>
      </ISC.InfoTableContent>
    </ISC.InfoTable>
  );
};
