import * as React from "react";

// styles, design
import {
  AddressField,
  TextInput,
  Select,
  PositiveNumber,
  Checkbox,
} from "../Base/Fields";

import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { useDispatch } from "react-redux";
import FloorSelector from "../Base/FieldComponents/FloorSelector";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import TableSelectField from "../Base/Fields/TableSelectField";

export default function ApartmentNestedFields({
  storeName,
  method,
  parentPath,
  parentInstructionsPath,
}) {
  const dispatch = useDispatch();

  const floors = useFormInstanceField({
    storeName,
    fieldKey: `${parentPath}.floors`,
  });
  const dynamicFloors = useFormInstanceField({
    storeName,
    fieldKey: `${parentPath}.dynamic_floors`,
  });

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`${parentPath}.floors`]: dynamicFloors },
      })
    );
  }, []);

  return (
    <>
      <TextInput
        title="Lägenhetsnummer"
        storeName={storeName}
        fieldKey={`${parentPath}.apartment_id`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.apartment_id`}
      />

      <TextInput
        title="Objektsnummer"
        storeName={storeName}
        fieldKey={`${parentPath}.premises_id`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.premises_id`}
      />

      <AddressField
        title="Adress"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.address`}
        instructionsKey={`${parentInstructionsPath || parentPath}.address`}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj byggnad..."
          title="Byggnad"
          method={method}
          fieldKey={`${parentPath}.building`}
          instructionsKey={`${parentInstructionsPath || parentPath}.building`}
          TableComponent={BuildingTable}
        />
      </div>

      <Select
        title="Antal RoK"
        storeName={storeName}
        fieldKey={`${parentPath}.category`}
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.category`}
      />

      <PositiveNumber
        storeName={storeName}
        fieldKey={`${parentPath}.area`}
        title="Area i m&sup2;"
        method={method}
        instructionsKey={`${parentInstructionsPath || parentPath}.area`}
      />

      <PositiveNumber
        title="Referenshyra per månad"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.base_rent`}
        instructionsKey={`${parentInstructionsPath || parentPath}.base_rent`}
      />

      <FloorSelector
        storeName={storeName}
        fieldKey={`${parentPath}.floors`}
        floors={floors}
      />

      <Checkbox
        title="Objektet är uthyrt i andrahand"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.subletted`}
        instructionsKey={`${parentInstructionsPath || parentPath}.subletted`}
      />
      <Checkbox
        title="Objektet är under renovering"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.renovating`}
        instructionsKey={`${parentInstructionsPath || parentPath}.renovating`}
      />
      <Checkbox
        title="Objektet är under förhandlingsordning"
        description="Förhandlingsordning innebär att hyreshöjningar måste förhandlas genom Hyresgästföreningen"
        storeName={storeName}
        method={method}
        fieldKey={`${parentPath}.negotiated_rent_increase`}
        instructionsKey={`${
          parentInstructionsPath || parentPath
        }.negotiated_rent_increase`}
      />
    </>
  );
}
