import * as React from "react";
import { useSelector } from "react-redux";

import BlueprintMenu from "./BlueprintMenu";
import NoBlueprints from "./Info/NoBlueprints";
import BlueprintController from "./BlueprintDisplay/Controller";

export default function BlueprintLayout({ building, blueprints }) {
  const currentBuildingId = building?.id;

  const currentFloor = useSelector(
    (state) => state.blueprints.currentFloor?.floor
  );

  const floors = useSelector((state) => {
    if (!currentBuildingId) return [];
    const currentBlueprints = blueprints?.filter(
      (bp) => bp.building?.id === currentBuildingId
    );

    const currentFloors = currentBlueprints?.map((bp) => ({
      floor: bp.floor,
      blueprintId: bp.id,
      buildingId: currentBuildingId,
    }));

    return currentFloors;
  });

  const currentBlueprint = useSelector((state) => {
    if (!blueprints) return null;

    const current = blueprints?.find(
      (bp) => bp.building?.id === currentBuildingId && bp.floor === currentFloor
    );

    return current;
  });

  return (
    <>
      <BlueprintMenu
        floors={floors}
        buildingId={building?.id}
        blueprint={currentBlueprint}
      />
      {floors?.length && currentBlueprint ? (
        <>
          <BlueprintController currentBlueprint={currentBlueprint} />
        </>
      ) : (
        <NoBlueprints />
      )}
    </>
  );
}
