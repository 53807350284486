import { cloneDeep } from "lodash";
import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import InvoicingRecordTable from "../../../components/Tables/InvoicingRecord/FullTable";
import VacancyBookingRecordTable from "../../../components/Tables/InvoicingVacancyRecord/FullTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useQuery from "../../../components/utils/useQuery";
import { buildQueryString } from "../../../store/base";
import { useInvoicingRecordPaginationCount } from "../../../store/invoicingRecords";
import { useVacancyInvoicingRecordPaginationCount } from "../../../store/invoicingVacancyRecords";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

export default function FailedInvoicings() {
  const [selectedTab, setSelectedTab] = React.useState("INVOICINGS");
  const query = useQuery();
  const forceTab = query.get("tab");
  const [TABS, setTABS] = React.useState({
    INVOICINGS: "Aviseringar",
    VACANCIES: "Vakansbokningar",
  });

  React.useEffect(() => {
    if (forceTab) {
      setSelectedTab(forceTab);
    }
  }, [forceTab]);

  const [failedInvoicingsCount] = useInvoicingRecordPaginationCount({
    filters: {
      billecta_id__isnull: true,
      skipped_generating: false,
    },
  });
  const [failedVacancyBookingsCount] = useVacancyInvoicingRecordPaginationCount(
    {
      filters: {
        billecta_id__isnull: true,
        skipped_generating: false,
      },
    }
  );

  const persistantQuery = {
    billecta_id__isnull: true,
    skipped_generating: false,
  };

  React.useEffect(() => {
    const tabsCopy = cloneDeep(TABS);

    tabsCopy["VACANCIES"] = `Vakansbokningar (${
      failedVacancyBookingsCount || 0
    })`;
    tabsCopy["INVOICINGS"] = `Aviseringar (${failedInvoicingsCount || 0})`;

    setTABS(tabsCopy);
  }, [failedVacancyBookingsCount, failedInvoicingsCount]);

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Misslyckade{" "}
              {selectedTab === "INVOICINGS" ? "aviseringar" : "vakansbokningar"}
            </OverviewTitle>
            <OverviewSubtitle>
              Klicka på en rad för att se mer detaljer om vad den misslyckade
              körningen beror på
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>
        {selectedTab === "INVOICINGS" && (
          <InvoicingRecordTable withDetailUrl {...{ persistantQuery }} />
        )}

        {selectedTab === "VACANCIES" && (
          <VacancyBookingRecordTable withDetailUrl {...{ persistantQuery }} />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
