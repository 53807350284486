import React from "react";
import FullTable from "../../components/Tables/NotificationsCenter/FullTable";
import { buildQueryString } from "../../store/base";

export default function Notifications() {
  const persistantQuery = {
    order_by: "-created_at",
  };

  return <FullTable persistantQuery={persistantQuery} />;
}
