export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_YOURBLOCK",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_YOURBLOCK",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_YOURBLOCK",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_YOURBLOCK",
  DESTROY_FORM: "DESTROY_FORM_YOURBLOCK",
  SET_FORM_ERROR: "SET_FORM_ERROR_YOURBLOCK",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_YOURBLOCK",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_YOURBLOCK",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_YOURBLOCK",
  RESET_STATE: "RESET_STATE_YOURBLOCK",
  CLEAR_FETCHED: "CLEAR_FETCHED_YOURBLOCKS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_YOURBLOCK",
  REMOVE_OBJECT: "REMOVE_OBJECT_YOURBLOCK",

  LIST_URL: "/mypages/yourblock/yourblockcredential/list/",
  POST_URL: "/mypages/yourblock/yourblockcredential/",
  PATCH_URL: "/mypages/yourblock/yourblockcredential/",
  GET_URL: "/mypages/yourblock/yourblockcredential/",
  MATCH_URL: "/mypages/yourblock/match_yourblock/",

  STORE_NAME: "yourBlock",
};
