import { cloneDeep } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import { store } from "../../store";
import { createUploadHandler } from "./uploadHandler";
import constants from "./constants";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (querystring, callback, taskToken) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }
  // the logo file
  if (cloned?.logo?._tempData) {
    // file has been edited
    cloned.logo = cloned?.logo?._tempData?.file_name;
  } else {
    // file has not been edited, don't send anything
    delete cloned?.logo;
  }

  // the logo file
  if (cloned?.hello_image?._tempData) {
    // file has been edited
    cloned.hello_image = cloned?.hello_image?._tempData?.file_name;
  } else {
    // file has not been edited, don't send anything
    delete cloned?.hello_image;
  }
  return cloned;
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback,
    preProcess: fullPreProcess,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    preProcess: fullPreProcess,
    uploadCallback: uploadCallback,
  });
};
