import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useQuery from "../../../components/utils/useQuery";
import Table from "../../../components/Lists/Base/CompleteList/Table";

import {
  constants as apartmentConstants,
  useApartmentPagination,
  performFilter as performApartmentFilter,
} from "../../../store/apartments";
import apartmentListDefs from "../../../components/Tables/Apartments/listDefs";
import {
  constants as indpConstants,
  useIndustrialPremisesPagination,
  performFilter as performIndpFilter,
} from "../../../store/industrialPremises";
import indpListDefs from "../../../components/Tables/IndustrialPremises/listDefs";
import {
  constants as parkingConstants,
  useParkingSpotPagination,
  performFilter as performParkingFilter,
} from "../../../store/parkingSpots";
import parkingListDefs from "../../../components/Tables/Parking/ParkingSpots/listDefs";

import { AD_CREATE_TYPES } from "./ManyAdRootObjectSelect";
import {
  constants as apartmentAdConstants,
  removeApartment,
  addApartment,
  addMultipleApartments,
  setAccessDateForId as setAccessDateForIdApartment,
  setPublishDateForId as setPublishDateForIdApartment,
  setAds as apartmentSetAds,
  createMany as apartmentCreateMany,
  createManyMenuUrl,
  useFilteredApartmentAds,
} from "../../../store/marketApartmentAds";

import {
  constants as indpAdConstants,
  removeIndp,
  addIndp,
  addMultipleIndps,
  setAccessDateForId as setAccessDateForIdIndp,
  setPublishDateForId as setPublishDateForIdIndp,
  setAds as indpSetAds,
  createMany as indpCreateMany,
  useFilteredIndustrialPremisesAds,
} from "../../../store/marketIndpAds";

import {
  constants as parkingAdConstants,
  removeParkingSpot,
  addParkingSpot,
  addMultipleParkingSpots,
  setAccessDateForId as setAccessDateForIdParking,
  setPublishDateForId as setPublishDateForIdParking,
  setAds as parkingSetAds,
  createMany as parkingCreateMany,
  useFilteredParkingSpotAds,
} from "../../../store/marketParkingAds";

import { buildQueryString } from "../../../store/base";
import { useFilteredApartments } from "../../../store/apartments";
import { useFilteredIndustrialPremises } from "../../../store/industrialPremises";
import { useFilteredParkingSpots } from "../../../store/parkingSpots";
import { useDispatch, useSelector } from "react-redux";
import StandardModal from "../../../components/Modals/StandardModal";
import { buildRangeFilter } from "../../../components/Lists/Base/utils";
import { cloneDeep } from "lodash";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import CreateAdsTable from "./CreateAdsTable";
import HandleAd from "./HandleAd";

const fetchAllTreshold = 25;
const forceFrontendColumns = [
  "state",
  "leasecontracts",
  "othercontracts",
  "floors",
];
const filterInstructions = {
  premises_id: { operator: "icontains" },
  area: { handler: (filterObject) => buildRangeFilter(filterObject) },
};

const getConstants = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentAdConstants;
  if (type === AD_CREATE_TYPES.INDP) return indpAdConstants;
  if (type === AD_CREATE_TYPES.PARKING) return parkingAdConstants;

  return null;
};
const getAddDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return addApartment;
  if (type === AD_CREATE_TYPES.INDP) return addIndp;
  if (type === AD_CREATE_TYPES.PARKING) return addParkingSpot;

  return null;
};
const getAddMultpleDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return addMultipleApartments;
  if (type === AD_CREATE_TYPES.INDP) return addMultipleIndps;
  if (type === AD_CREATE_TYPES.PARKING) return addMultipleParkingSpots;

  return null;
};
const getRemoveDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return removeApartment;
  if (type === AD_CREATE_TYPES.INDP) return removeIndp;
  if (type === AD_CREATE_TYPES.PARKING) return removeParkingSpot;

  return null;
};
const getPublishDateDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return setPublishDateForIdApartment;
  if (type === AD_CREATE_TYPES.INDP) return setPublishDateForIdIndp;
  if (type === AD_CREATE_TYPES.PARKING) return setPublishDateForIdParking;

  return null;
};
const getAccessDateDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return setAccessDateForIdApartment;
  if (type === AD_CREATE_TYPES.INDP) return setAccessDateForIdIndp;
  if (type === AD_CREATE_TYPES.PARKING) return setAccessDateForIdParking;

  return null;
};
const getSetAdsDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentSetAds;
  if (type === AD_CREATE_TYPES.INDP) return indpSetAds;
  if (type === AD_CREATE_TYPES.PARKING) return parkingSetAds;

  return null;
};
const getCreateManyDispatch = (type) => {
  if (type === AD_CREATE_TYPES.APARTMENT) return apartmentCreateMany;
  if (type === AD_CREATE_TYPES.INDP) return indpCreateMany;
  if (type === AD_CREATE_TYPES.PARKING) return parkingCreateMany;

  return null;
};

export default function CreateManyAds() {
  const dispatch = useDispatch();
  const query = useQuery();
  const { type } = useParams();
  const { goBack } = useHistory();
  const [hasSetFromQuery, setHasSetFromQuery] = React.useState(false);
  const [selectorOpen, setSelectorOpen] = React.useState(false);
  const [createConfirmOpen, setCreateConfirmOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const constants = getConstants(type);
  const add = getAddDispatch(type);
  const remove = getRemoveDispatch(type);
  const addMultiple = getAddMultpleDispatch(type);
  const setPublishDate = getPublishDateDispatch(type);
  const setAccessDate = getAccessDateDispatch(type);
  const setAds = getSetAdsDispatch(type);
  const createMany = getCreateManyDispatch(type);
  const storeName = constants.STORE_NAME;
  const { push } = useHistory();

  const defaultSettings = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const adsInstance = useSelector((state) => state[storeName].ads) || [];

  const buildingId = query.get("building");

  const columns = React.useMemo(
    () =>
      type === AD_CREATE_TYPES.APARTMENT
        ? apartmentListDefs()
        : type === AD_CREATE_TYPES.INDP
        ? indpListDefs()
        : parkingListDefs(),
    []
  );

  const apartmentQuery = buildQueryString({});
  const apartmentFilter = (obj) => {
    return obj;
  };

  const buildingQueryObj = {
    building: buildingId,
  };
  if (!buildingId) {
    buildingQueryObj.id = -1;
  }
  const buildingQuery = buildQueryString(buildingQueryObj);

  const [apartmentsMatchingBuilding, apartmentsMatchingBuildingLoading] =
    useFilteredApartments(
      type === AD_CREATE_TYPES.APARTMENT ? buildingQuery : "id__in="
    );
  const [indpsMatchingBuilding, indpsMatchingBuildingLoading] =
    useFilteredIndustrialPremises(
      type === AD_CREATE_TYPES.INDP ? buildingQuery : "id__in="
    );
  const [parkingSpotsMatchingBuilding, parkingSpotsMatchingBuildingLoading] =
    useFilteredParkingSpots(
      type === AD_CREATE_TYPES.PARKING ? buildingQuery : "id__in="
    );

  const existingAdsForApartmentsQ = buildQueryString({
    closed: false,
    apartment__in: apartmentsMatchingBuilding?.map((a) => a.id),
  });
  const existingAdsForIndpQ = buildQueryString({
    closed: false,
    industrial_premises__in: indpsMatchingBuilding?.map((a) => a.id),
  });
  const existingAdsForParkingQ = buildQueryString({
    closed: false,
    parking_spot__in: parkingSpotsMatchingBuilding?.map((a) => a.id),
  });
  const [existingAdsForApartments, existingAdsForApartmentsLoading] =
    useFilteredApartmentAds(existingAdsForApartmentsQ);

  const [existingAdsForIndps, existingAdsForIndpsLoading] =
    useFilteredIndustrialPremisesAds(existingAdsForIndpQ);

  const [existingAdsForParkingSpots, existingAdsForParkingSpotsLoading] =
    useFilteredParkingSpotAds(existingAdsForParkingQ);

  const waitForData =
    !!hasSetFromQuery ||
    !!apartmentsMatchingBuildingLoading ||
    !!existingAdsForApartmentsLoading ||
    !!indpsMatchingBuildingLoading ||
    !!existingAdsForIndpsLoading ||
    !!parkingSpotsMatchingBuildingLoading ||
    !!existingAdsForParkingSpotsLoading ||
    !!(
      apartmentsMatchingBuilding?.length === 0 &&
      type === AD_CREATE_TYPES.APARTMENT
    ) ||
    !!(indpsMatchingBuilding?.length === 0 && type === AD_CREATE_TYPES.INDP) ||
    !!(
      parkingSpotsMatchingBuilding?.length === 0 &&
      type === AD_CREATE_TYPES.PARKING
    );

  React.useEffect(() => {
    let filteredOut = 0;

    if (waitForData) {
      return;
    }

    let combined = [
      ...(type === AD_CREATE_TYPES.APARTMENT
        ? apartmentsMatchingBuilding || []
        : []),
      ...(type === AD_CREATE_TYPES.INDP ? indpsMatchingBuilding || [] : []),
      ...(type === AD_CREATE_TYPES.PARKING
        ? parkingSpotsMatchingBuilding || []
        : []),
    ];

    combined = combined.filter((a) => {
      if (
        type === AD_CREATE_TYPES.APARTMENT &&
        existingAdsForApartments?.find((c) => c.apartment?.id === a.id)
      ) {
        filteredOut += 1;
        return false;
      }
      if (
        type === AD_CREATE_TYPES.INDP &&
        existingAdsForIndps?.find((c) => c.industrial_premises?.id === a.id)
      ) {
        filteredOut += 1;
        return false;
      }
      if (
        type === AD_CREATE_TYPES.PARKING &&
        existingAdsForParkingSpots?.find((c) => c.parking_spot?.id === a.id)
      ) {
        filteredOut += 1;
        return false;
      }
      return true;
    });

    if (combined?.length === 0 && filteredOut > 0) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Alla objekt i denna byggnad har aktiva annonser redan",
        })
      );
      setHasSetFromQuery(true);
      return;
    }

    if (combined?.length > 0) {
      setHasSetFromQuery(true);

      if (combined.length > 50) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Hämtar in data...",
            description: "OBS: Detta kan ta några sekunder vid större volymer",
          })
        );

        setTimeout(() => {
          if (type === AD_CREATE_TYPES.APARTMENT) {
            dispatch(
              addMultiple({
                apartments: combined?.map((c) => ({
                  id: c.id,
                  apartment_id: c.apartment_id,
                  premises_id: c.premises_id,
                  ...defaultSettings,
                })),
              })
            );
          } else if (type === AD_CREATE_TYPES.INDP) {
            dispatch(
              addMultiple({
                indps: combined?.map((c) => ({
                  id: c.id,
                  premises_id: c.premises_id,
                  ...defaultSettings,
                })),
              })
            );
          } else if (type === AD_CREATE_TYPES.PARKING) {
            dispatch(
              addMultiple({
                parkingSpots: combined?.map((c) => ({
                  id: c.id,
                  title: c.title,
                  ...defaultSettings,
                })),
              })
            );
          }
        }, 1000);
      } else {
        if (type === AD_CREATE_TYPES.APARTMENT) {
          dispatch(
            addMultiple({
              apartments: combined?.map((c) => ({
                id: c.id,
                apartment_id: c.apartment_id,
                premises_id: c.premises_id,
                ...defaultSettings,
              })),
            })
          );
        } else if (type === AD_CREATE_TYPES.INDP) {
          dispatch(
            addMultiple({
              indps: combined?.map((c) => ({
                id: c.id,
                premises_id: c.premises_id,
                ...defaultSettings,
              })),
            })
          );
        } else if (type === AD_CREATE_TYPES.PARKING) {
          dispatch(
            addMultiple({
              parkingSpots: combined?.map((c) => ({
                id: c.id,
                title: c.title,
                ...defaultSettings,
              })),
            })
          );
        }
      }

      if (filteredOut > 0) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Vissa objekt filtrerades bort",
            description: `${filteredOut} objekt har redan aktiva annonser.`,
          })
        );
      }
    }
  }, [
    apartmentsMatchingBuilding,
    apartmentsMatchingBuildingLoading,
    existingAdsForApartments,
    existingAdsForApartmentsLoading,
    indpsMatchingBuilding,
    indpsMatchingBuildingLoading,
    existingAdsForIndps,
    existingAdsForIndpsLoading,
    parkingSpotsMatchingBuilding,
    parkingSpotsMatchingBuildingLoading,
    existingAdsForParkingSpots,
    existingAdsForParkingSpotsLoading,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(setAds({ newAds: [] }));
    };
  }, []);

  const onSuccess = () => {
    setLoading(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Annonserna skapas...",
        description:
          "Detta kan ta en liten stund. Pigello uppdateras automatiskt när de är skapade",
      })
    );

    push(
      `${createManyMenuUrl({ type })}?created_amount=${
        adsInstance?.length
      }&postcreation=true`
    );
  };
  const onError = () => {
    setLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "En eller fler rader innehåller fel eller saknar information",
        description: "Kontrollera datan och försök igen",
      })
    );
  };

  const onSubmit = () => {
    setLoading(true);
    const postData = buildPostObj({ type, adsInstance });

    dispatch(
      createMany({
        postData,
        successCallback: onSuccess,
        errorCallback: onError,
      })
    );
  };

  const onSelected = (row) => {
    const { original } = row;

    const match = adsInstance.find((i) => i.id === original.id);

    if (match) {
      if (type === AD_CREATE_TYPES.APARTMENT) {
        dispatch(remove({ apartment: original }));
      } else if (type === AD_CREATE_TYPES.INDP) {
        dispatch(remove({ indp: original }));
      } else if (type === AD_CREATE_TYPES.PARKING) {
        dispatch(remove({ parkingSpot: original }));
      }
    } else {
      if (type === AD_CREATE_TYPES.APARTMENT) {
        dispatch(add({ apartment: original }));
      } else if (type === AD_CREATE_TYPES.INDP) {
        dispatch(add({ indp: original }));
      } else if (type === AD_CREATE_TYPES.PARKING) {
        dispatch(add({ parkingSpot: original }));
      }
    }
  };

  const checkRowHighlighted = (row) => {
    const { original } = row;

    let match = false;

    match = adsInstance?.find((s) => s.id === original.id);

    return match;
  };

  const handleDatePublishUpdates = ({ id, val }) => {
    dispatch(setPublishDate({ id, val }));
  };

  const handleDateAccessUpdates = ({ id, val }) => {
    dispatch(setAccessDate({ id, val }));
  };

  const handleAttrUpdated = ({ attr, id, val }) => {
    const newAds = cloneDeep(adsInstance);

    const cur = newAds.find((obj) => obj.id === id);

    cur[attr] = val;

    dispatch(setAds({ newAds }));
  };

  return (
    <>
      <ConfirmationModal
        isOpen={createConfirmOpen}
        title={`Skapa annonser?`}
        renderContent={() => (
          <div>
            <hr />
            Du är påväg att skapa {adsInstance?.length} annonser. Gå vidare?
          </div>
        )}
        closeFunction={() => setCreateConfirmOpen(false)}
        acceptCallback={onSubmit}
      />

      <HandleAd
        {...{
          editInstance: adsInstance?.find((ad) => ad.id === editId),
          closeFunction: () => setEditId(null),
          isOpen: !!editId,
          handleAttrUpdated,
          handleDateAccessUpdates,
          handleDatePublishUpdates,
        }}
      />

      <StandardModal
        isOpen={selectorOpen}
        closeFunction={() => setSelectorOpen(false)}
        title={`Välj ${
          type === AD_CREATE_TYPES.APARTMENT
            ? "hyreslägenheter"
            : type === AD_CREATE_TYPES.INDP
            ? "lokaler"
            : "fordonsplatser"
        }`}
        withActionBar
        actionBarCancelTitle="Klar"
      >
        {type === AD_CREATE_TYPES.APARTMENT && (
          <Table
            storeName={apartmentConstants.STORE_NAME}
            columns={columns}
            persistantQueryString={apartmentQuery}
            persistantFilterMethod={apartmentFilter}
            paginationMethod={useApartmentPagination}
            filterInstructions={filterInstructions}
            fetchAllTreshold={fetchAllTreshold}
            filterAction={performApartmentFilter}
            forceFrontendColumns={forceFrontendColumns}
            {...{
              hideExport: true,
              hideColumns: true,
              onRowClicked: onSelected,
              checkRowHighlighted,
            }}
          />
        )}
        {type === AD_CREATE_TYPES.INDP && (
          <Table
            storeName={indpConstants.STORE_NAME}
            columns={columns}
            persistantQueryString={apartmentQuery}
            persistantFilterMethod={apartmentFilter}
            paginationMethod={useIndustrialPremisesPagination}
            filterInstructions={filterInstructions}
            fetchAllTreshold={fetchAllTreshold}
            filterAction={performIndpFilter}
            forceFrontendColumns={forceFrontendColumns}
            {...{
              hideExport: true,
              hideColumns: true,
              onRowClicked: onSelected,
              checkRowHighlighted,
            }}
          />
        )}
        {type === AD_CREATE_TYPES.PARKING && (
          <Table
            storeName={parkingConstants.STORE_NAME}
            columns={columns}
            persistantQueryString={apartmentQuery}
            persistantFilterMethod={apartmentFilter}
            paginationMethod={useParkingSpotPagination}
            filterInstructions={filterInstructions}
            fetchAllTreshold={fetchAllTreshold}
            filterAction={performParkingFilter}
            forceFrontendColumns={forceFrontendColumns}
            {...{
              hideExport: true,
              hideColumns: true,
              onRowClicked: onSelected,
              checkRowHighlighted,
            }}
          />
        )}
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            extraStyle={{ marginBottom: 24 }}
            title="Tillbaka till val av standardinställningar"
            iconType="arrow-back"
            clicked={() => goBack()}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Skapa flera annonser</OverviewTitle>
              <OverviewSubtitle style={{ marginRight: 24 }}>
                För att lägga till ett objekt, klicka på "Lägg till objekt att
                annonsera för" nedan. Klicka på en rad för att redigera den.
                <br /> Tryck på "Skapa annonser" när du är klar.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Skapa annonser"
              clicked={() => setCreateConfirmOpen(true)}
            />
          </OverviewTitleWrapper>
          <hr />

          <TextButton
            title="Lägg till objekt att annonsera för"
            iconType="add"
            iconPlacement="right"
            clicked={() => setSelectorOpen(true)}
          />

          {adsInstance?.length > 0 && (
            <CreateAdsTable {...{ adsInstance, setEditId, onSelected, type }} />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export const PILOT_OPTIONS = [
  {
    v: 0,
    d: "Automatiskt",
  },
  {
    v: 1,
    d: "Manuellt val låst",
  },
  {
    v: 2,
    d: "Manuellt val",
  },
  {
    v: 3,
    d: "Extern",
  },
];

const buildPostObj = ({ type, adsInstance }) => {
  const postObj = adsInstance.map((ad) => {
    let adObj;
    switch (type) {
      case AD_CREATE_TYPES.APARTMENT:
        adObj = {
          ...ad,
          apartment: {
            id: ad.id,
          },
          requirement: {
            id: ad.requirement,
          },
          dump_in_pipe: {
            id: ad.dump_in_pipe,
          },
        };

        delete adObj.id;
        delete adObj.premises_id;
        delete adObj.apartment_id;
        break;
      case AD_CREATE_TYPES.INDP:
        adObj = {
          ...ad,
          industrial_premises: {
            id: ad.id,
          },
          requirement: {
            id: ad.requirement,
          },
          dump_in_pipe: {
            id: ad.dump_in_pipe,
          },
        };

        delete adObj.id;
        delete adObj.premises_id;

        break;
      case AD_CREATE_TYPES.PARKING:
        adObj = {
          ...ad,
          parking_spot: {
            id: ad.id,
          },
          requirement: {
            id: ad.requirement,
          },
          dump_in_pipe: {
            id: ad.dump_in_pipe,
          },
        };

        delete adObj.id;
        delete adObj.title;

        break;
      default:
        break;
    }
    return adObj;
  });

  return postObj;
};
