import * as React from "react";

import {
  TextInput,
  RadioGroup,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/paymentGroup";

export default ({ method }) => {

  const storeName = constants.STORE_NAME;

  return (
    <>
      <TextInput
        title="Titel"
        storeName={storeName}
        method={method}
        fieldKey="title"
        description={"Denna titel kommer användas för debiteringsradernas titlar"}
      />
      <RadioGroup
        title="Gäller för alla lägenheter i föreningen"
        storeName={storeName}
        method={method}
        fieldKey="for_all_brf_premises"
        description={"Detta innebär att alla lägenheter i föreningen automatisk kommer ha en andel av avgiften"}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={true}
      />
      <RadioGroup
        title="Är huvudavgift"
        storeName={storeName}
        method={method}
        fieldKey="is_main_fee"
        description={"Endast en avgiftsgrupp i föreningen kan markeras som huvudavgift"}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />
    </>
  );
};
