import axios from "axios";

export const getBase64 = async (url) => {
  try {
    const response = await axios.get(url, { responseType: "arraybuffer" });
    let b64 = Buffer.from(response.data, "binary").toString("base64");
    const prefix = "data:application/pdf;base64,";
    if (!b64.startsWith(prefix)) {
      b64 = prefix + b64;
    }

    return b64;
  } catch (error) {
    return undefined;
  }
};

export const cleanEditableDocTemplate = async (data) => {
  delete data?.id;
  delete data?.realestate;
  if (data?.parties?.length) {
    data.parties.forEach((p) => {
      delete p?.id;
      if (p?.fields?.length) {
        p.fields.forEach((f) => {
          delete f?.id;
          if (f?.placements?.length) {
            f.placements.forEach((plc) => {
              delete plc?.id;
            });
          }
        });
      }
    });
  }

  // if we have doc data, we need to fetch and
  // simulate choosing of the file
  if (data?.doc?.get) {
    const b64 = await getBase64(data.doc.get);
    if (b64) {
      data.docData_ = b64;
      data.doc = `${data?.title || "doc"}.pdf`;
    }
  }

  // set is_template to false, otherwise we will just create a new template
  data.is_template = false;

  return data;
};
