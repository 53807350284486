import {
  useFilteredObjects,
  useObject,
  useObjects,
  usePagination,
} from "../../base";
import {
  filterPagination,
  getAll,
  getSingle,
  performFilter,
} from "../store/actions";
import constants from "../store/constants";

export function useBrfManualCapitalAddon(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useBrfManualCapitalAddonList(filterMethod) {
  const params = {
    storeName: constants.STORE_NAME,
    filterMethod: filterMethod,
    fetchMethod: getAll,
  };
  return useObjects(params);
}

export function useFilteredBrfManualCapitalAddons(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useBrfManualCapitalAddonPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}
