import Parties from "./Parties";
import Attachments from "./Attachments";
import ContractDetails from "./ContractDetails";

import ExistingDocument from "./ExistingDocument";
import Confirm from "./Confirm";
import SignStatus from "./SignStatus";
export default {
  PARTIES: Parties,
  EXISTINGDOC: ExistingDocument,
  ATTACHMENTS: Attachments,
  CONTRACT: ContractDetails,

  CONFIRM: Confirm,
  SIGN_STATUS: SignStatus,
};
