import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import ApartmentCancellationsTable from "../../../components/Tables/ApartmentCancellations/FullTable";

import useActiveFilters from "../../../hooks/useActiveFilters";

export default function ApartmentCancellations() {
  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Utflyttsöversikt</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <ApartmentCancellationsTable />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
