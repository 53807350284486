import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import NonConnectedTextField from "../../../Base/Old/NonConnected/NonConnectedTextField";

// style, design

export default ({ storeName, updateDefaultSettings }) => {
  const dispatch = useDispatch();

  const defaultSettingsInstance = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const updateAttr = ({ attr, val }) => {
    dispatch(updateDefaultSettings({ attr, val }));
  };

  return (
    <>
      <NonConnectedTextField
        title="Annonsbeskrivning"
        rows={10}
        value={defaultSettingsInstance.description}
        handleChange={(val) => {
          updateAttr({ attr: "description", val });
        }}
        id={`description_`}
      />
    </>
  );
};
