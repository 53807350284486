export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_RECIPIENT_GROUP",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_RECIPIENT_GROUP",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_RECIPIENT_GROUP",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_RECIPIENT_GROUP",
  DESTROY_FORM: "DESTROY_FORM_RECIPIENT_GROUP",
  SET_FORM_ERROR: "SET_FORM_ERROR_RECIPIENT_GROUP",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_RECIPIENT_GROUP",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_RECIPIENT_GROUP",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_RECIPIENT_GROUP",
  RESET_STATE: "RESET_STATE_RECIPIENT_GROUP",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_RECIPIENT_GROUP",
  REMOVE_OBJECT: "REMOVE_OBJECT_RECIPIENT_GROUP",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_RECIPIENT_GROUP",
  CLEAR_FETCHED: "CLEAR_FETCHED_RECIPIENT_GROUP",

  LIST_URL: "/events/recipientgroup/list/",
  POST_URL: "/events/recipientgroup/",
  PATCH_URL: "/events/recipientgroup/",
  GET_URL: "/events/recipientgroup/",

  STORE_NAME: "recipientGroup",
};
