import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  BooleanLabel,
  DateCell,
  LinkedObject,
  ToolTipCell,
} from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import { useLoan } from "../../../store/loan";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import LoanSubTable from "../../../components/Tables/Loan/SubTable";

import { detailUrl as paymentGroupDetailUrl } from "../../../store/paymentGroup";
import HandleInterest from "./HandleInterests";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { useAllPermissionCheck } from "../../../store/base";

export default function PaymentGroupDetail() {
  const { loanId } = useParams();

  const [loan, loanLoading] = useLoan(loanId);

  const getInfoObj = () => {
    return {
      Inställningar: [
        {
          title: "Avgiftsgrupp",
          value: (
            <LinkedObject
              obj={loan.payment_group}
              urlMethod={paymentGroupDetailUrl}
            />
          ),
        },
        {
          title: "Andel av avgiftsgrupp",
          value: (
            <ToolTipCell
              text={
                loan.payment_group_quota != null
                  ? `${loan.payment_group_quota.toFixed(2)} %`
                  : "-"
              }
            />
          ),
        },
        {
          title: "Startdatum",
          value: <DateCell date={loan.start_date} />,
        },
        {
          title: "Slutdatum",
          value: <DateCell date={loan.end_date} />,
        },
        {
          title: "Initial värde",
          value: (
            <ToolTipCell text={toMoneyString(loan.initial_value, true, 2)} />
          ),
        },
        {
          title: "Kvarvarande belopp",
          value: (
            <ToolTipCell text={toMoneyString(loan.left_on_loan, true, 2)} />
          ),
        },
        {
          title: "Årlig amortering",
          value: (
            <ToolTipCell
              text={toMoneyString(loan.yearly_amortization, true, 2)}
            />
          ),
        },
        {
          title: "Amorteringsfrekvens (månader)",
          value: <ToolTipCell text={loan.amortization_every_months} />,
        },
        {
          title: "Fast räntesats",
          value: (
            <ToolTipCell
              text={
                loan.fixed_interest != null
                  ? `${loan.fixed_interest.toFixed(2)} %`
                  : "-"
              }
            />
          ),
        },
        {
          title: "Rörlig ränta",
          value: (
            <BooleanLabel
              value={loan.use_adjusting_interest}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Rörlig ränta justera f.o.m",
          value: <DateCell date={loan.adjusting_interest_adjust_from} />,
        },
        {
          title: "Räntejusteringsfrekvens (mån)",
          value: (
            <ToolTipCell text={loan.adjusting_interest_adjust_every_months} />
          ),
        },
        {
          title: "Ej genomförd räntejustering",
          value: <DateCell date={loan.next_missing_interest_adjustment_date} />,
        },
        {
          title: "Nästa räntejustering",
          value: <DateCell date={loan.next_interest_adjustment_date} />,
        },
        {
          title: "Årsavgift för låst ränta",
          value: (
            <ToolTipCell
              text={toMoneyString(
                loan.non_adjusting_interest_yearly_fee,
                true,
                2
              )}
            />
          ),
        },
        {
          title: "Dagar i år",
          value: <ToolTipCell text={loan.days_in_year} />,
        },
        {
          title: "Godtagbart för kapitaltillskott",
          value: (
            <BooleanLabel
              value={loan.consider_capital_contribution}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Bank",
          value: <ToolTipCell text={loan.bank} />,
        },
        {
          title: "Referensnummer",
          value: <ToolTipCell text={loan.reference_number} />,
        },
      ],
    };
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "49%",
              marginRight: "24px",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Detaljer</OverviewTitle>
              </OverviewTitleWrapper>

              {loan && <DetailInfo infoObj={getInfoObj()} />}
            </DetailPageBox>
          </div>

          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "49%",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Rörliga Räntevärden</OverviewTitle>
              </OverviewTitleWrapper>

              {loan?.use_adjusting_interest ? (
                <HandleInterest loan={loan} />
              ) : (
                <span>
                  Inga rörliga räntevärden hanteras för lån som inte använder
                  rörlig ränta
                </span>
              )}
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>

        <DetailPageBoxFlexWrapper>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "100%",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Förhandsgranskning</OverviewTitle>
              </OverviewTitleWrapper>

              {loan && <LoanSubTable loan={loan} />}
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
