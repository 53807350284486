import * as React from "react";
import { TextButton } from "../Forms/Base/Buttons";

export default function ExternalLinkedObject({
  obj,
  urlMethod,
  forceId,
  extraUrlMethodArgs = {},
}) {
  const onClick = (e) => {
    e?.stopPropagation && e.stopPropagation();

    window
      .open(
        urlMethod({
          id: forceId || fetchedObj.id,
          ...extraUrlMethodArgs,
        }),
        "blank"
      )
      .focus();
  };

  if (!obj && !obj?.id) {
    return <span>-</span>;
  }

  let [fetchedObj] = typeof obj === "function" ? obj() : [obj];

  if (!fetchedObj) {
    return <span>-</span>;
  }

  return (
    <TextButton
      title={fetchedObj.str_representation || "-"}
      clicked={onClick}
      iconType="launch"
      iconPlacement="right"
    />
  );
}
