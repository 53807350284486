import * as React from "react";
import { InnerBox } from "../../sharedStyles";
import { cloneDeep } from "lodash";
import Notes from "../OverviewInfo/Notes/Notes";

export default function InspectionErrandFault({
  inspectionErrand,
  reportComments,
}) {
  const reportCommentsClone = cloneDeep(reportComments);

  return (
    <>
      <InnerBox>
        <Notes
          notes={reportCommentsClone}
          contentType={"errands.inspectionerrand"}
          objectId={inspectionErrand?.id}
          attrName={"report_comments"}
          title="Kommentarer"
          fieldTitle="Kommentar"
          green
        />
      </InnerBox>
    </>
  );
}
