import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Checkbox, TextInput } from "../../../Base/Fields";
import { constants } from "../../../../../store/tenantGroup";
import { useFilteredTenants } from "../../../../../store/tenants";

import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useFilteredBrfPremises } from "../../../../../store/brfPremises";
import Table from "../../../../Billecta/Table/BasicTable";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import { InnerBox } from "../../../../sharedStyles";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import { cloneDeep, map } from "lodash";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { StatusLabel } from "../../../../Lists/Base/CompleteList/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";

export default ({ method, cooperative }) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const [handleMemberIndex, setHandleMemberIndex] = React.useState(null);
  const participators =
    useFormInstanceField({
      storeName,
      fieldKey: "participators",
    }) || [];

  const premisesQuery = buildQueryString({
    brf_company: cooperative?.id,
  });
  const [brfPremises, brfPremisesLoading] =
    useFilteredBrfPremises(premisesQuery);

  const tenantIdsFromPremises = brfPremises
    ?.map((premis) => {
      let ids = [];

      if (premis.tenant?.id) {
        ids.push(premis.tenant.id);
      }

      if (premis.bi_tenants?.length) {
        const biTenantIds = premis.bi_tenants.map((bt) => bt.id);

        ids = [...ids, ...biTenantIds];
      }

      return ids;
    })
    .flat()
    .filter((i) => i);

  const tenantQuery = buildQueryString({
    id__in: tenantIdsFromPremises,
  });

  const [tenants, tenantsLoading] = useFilteredTenants(tenantQuery);

  const userIdsFromTenants = tenants
    ?.map((tenant) => tenant?.user?.id)
    .filter((u) => u);
  const userQuery = { id__in: userIdsFromTenants };

  const tableMembers = React.useMemo(() => {
    return participators;
  }, [participators]);

  const tableColumns = React.useMemo(() => [
    {
      Header: "Namn",
      accessor: "_str_representation",
      Cell: (props) => {
        const name =
          props.row.original?.str_representation ||
          props.row.original?.user?.str_representation;
        return <div>{name}</div>;
      },
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Visa namn",
      accessor: "show_name",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 1 : 3}>
            {value ? "Ja" : "Nej"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Visa telefon",
      accessor: "show_phone",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 1 : 3}>
            {value ? "Ja" : "Nej"}
          </StatusLabel>
        );
      },
    },
    {
      Header: "Visa e-post",
      accessor: "show_email",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 1 : 3}>
            {value ? "Ja" : "Nej"}
          </StatusLabel>
        );
      },
    },
  ]);

  const handleAddMember = () => {
    const participatorsCopy = [...participators];
    const newIndex = participatorsCopy.length;
    participatorsCopy.push({
      show_name: true,
      show_phone: true,
      show_email: true,
    });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          participators: participatorsCopy,
        },
      })
    );

    setHandleMemberIndex(newIndex);
  };

  const handleEditMember = (row) => {
    setHandleMemberIndex(row.index);
  };

  const handleEditMemberDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleMemberIndex(null);
  };

  const handleRemoveMember = (index) => {
    const participatorsCopy = cloneDeep(participators);
    participatorsCopy.splice(index, 1);

    setHandleMemberIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          participators: participatorsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );
  };

  if (handleMemberIndex != null) {
    return (
      <>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Hantera rad{" "}
            {participators[handleMemberIndex]?.user?.str_representation || ""}
          </OverviewTitle>
        </OverviewTitleWrapper>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            method={method}
            fieldKey={`participators[${handleMemberIndex}].user`}
            instructionsKey={"participators.user"}
            title={"Välj medlemmar till styrelsen"}
            placeholder="Lägg till medlem i styrelse"
            persistantQuery={userQuery}
            TableComponent={UsersTable}
          />
        </div>

        <TextInput
          storeName={storeName}
          method={method}
          fieldKey={`participators[${handleMemberIndex}].title`}
          instructionsKey="participators.title"
          title="Roll i styrelse"
        />

        <Checkbox
          storeName={storeName}
          method={method}
          fieldKey={`participators[${handleMemberIndex}].show_name`}
          instructionsKey="participators.show_name"
          title="Visa namn"
          description="Hurvida medlemns namn ska visas i Mina Sidor under styrelsemedlemmar"
        />
        <Checkbox
          storeName={storeName}
          method={method}
          fieldKey={`participators[${handleMemberIndex}].show_phone`}
          instructionsKey="participators.show_phone"
          title="Visa telefon"
          description="Hurvida medlemns telefonnummer ska visas i Mina Sidor under styrelsemedlemmar"
        />
        <Checkbox
          storeName={storeName}
          method={method}
          fieldKey={`participators[${handleMemberIndex}].show_email`}
          instructionsKey="participators.show_email"
          title="Visa e-post"
          description="Hurvida medlemns e-post ska visas i Mina Sidor under styrelsemedlemmar"
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextButton
            title="Ta bort rad"
            red
            clicked={() => handleRemoveMember(handleMemberIndex)}
          />
          <PrimaryButton title="Klar" clicked={handleEditMemberDone} />
        </div>
      </>
    );
  }

  return (
    <>
      <FormAreaTitle>Lägg till medlemmar</FormAreaTitle>
      <FormAreaDescription>
        Lägg till de medlemmar i föreningen som sitter i styrelsen för{" "}
        {cooperative?.str_representation || "föreningen"}. Tryck på en rad för
        att redigera eller ta bort den
      </FormAreaDescription>

      {participators?.length > 0 ? (
        <>
          <Table
            onRowClicked={handleEditMember}
            columns={tableColumns}
            data={tableMembers}
            hideSearch
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <PrimaryButton title="Lägg till rad" clicked={handleAddMember} />
          </div>
        </>
      ) : (
        <InnerBox
          style={{
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Inga medlemmar är tillagda än
          <div style={{ marginTop: 24 }}>
            <PrimaryButton title="Lägg till rad" clicked={handleAddMember} />
          </div>
        </InnerBox>
      )}
    </>
  );
};
