export default [
  {
    title: "Fortnox Koppling",
    key: "FORTNOX",
    visited: true,
    hasError: false,
    fieldKeys: [
      "company",
      "auto_fetch_cost_centers",
      "auto_fetch_projects",
      "auto_fetch_products"
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
