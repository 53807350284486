import * as React from "react";
import { useDispatch } from "react-redux";

import {
  constants,
  useParkingSpotForm,
  destroyPatchForm,
  update,
} from "../../../../store/parkingSpots";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";
import Modal from "../../Base/Modals/Modal";
import TableSelectField from "../../Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";

export default function ParkingSpotVacancyBookingModalForm({
  method = "PATCH",
  id,
  onCheckout,
  instance,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;

  const formLoaded = Boolean(useParkingSpotForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: instance,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        preProcess,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={"Uppdatera bolag för vakansbokning"}
      closeFunction={onDone}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          title="Välj bolag för vakansbokning..."
          fieldTitle="Bolag för vakansbokning"
          method={method}
          fieldKey="vacancy_booking_company"
          TableComponent={CompanyTable}
        />
      </div>
    </Modal>
  );
}
