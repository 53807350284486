import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./selectListDefs";

// store, state

import { constants } from "../../../../store/parkingContracts";
import { PrimaryButton } from "../../../Forms/Base/Buttons";

export default function ParkingContractsSelectTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  onSubmit,
  submitLabel,
}) {
  const [selected, setSelected] = React.useState([]);

  const handleSelectedUpdated = (sel) => {
    setSelected(sel);
  };

  // const filterInstructions = {
  //   id_number: { operator: "icontains" },
  //   state: {
  //     handler: (filterObject) => {
  //       // for 0 and 1 we want to use an operator to check for any of the two
  //       // operator must be attached to current_state variable
  //       // filter on variable state is only supported on backend as a convenience
  //       if ([0, 1].includes(filterObject?.value)) {
  //         return buildQueryString({ state__in: [0, 1] });
  //       }
  //       return buildQueryString({ state: filterObject.value });
  //     },
  //   },
  //   start_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  //   end_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  // };

  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "parkingcontracts_full_table"}
        title={"Avtal"}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected: (ids) => handleSelectedUpdated(ids),
        }}
      />

      {selected?.length > 0 && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 42 }}
        >
          <PrimaryButton
            extraStyle={{ minWidth: "300px" }}
            clicked={() => onSubmit(selected)}
            title={submitLabel}
          />
        </div>
      )}
    </>
  );
}
