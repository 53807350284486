import * as React from "react";

// style, design
import { DoubleFieldWrapper } from "../../../Base/Chapters/styles";
import { RadioGroup, Select } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketIndustrialPremisesCommodities";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Ingår i hyran</OverviewTitle>
          <OverviewSubtitle>Ange vad som ingår i hyran</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="electricity_included"
          storeName={storeName}
          method={method}
          title="El"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="internet_included"
          storeName={storeName}
          method={method}
          title="Internet"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="heating_included"
          storeName={storeName}
          method={method}
          title="Värme"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="cable_package_included"
          storeName={storeName}
          method={method}
          title="Kabel-TV"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>

      <Select fieldKey="water" title="Vatten" {...{ storeName, method }} />
    </>
  );
};
