import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { getCurrentProductTypeUsage } from "../../components/TechnicalManagement/utils";
import { useErrandComponent } from "../../store/errandComponents";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { detailUrl as apartmentDetailUrl } from "../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../store/industrialPremises";
import { detailUrl as commonAreaDetailUrl } from "../../store/commonAreas";
import { detailUrl as brfDetailUrl } from "../../store/brfPremises";
import { detailUrl as parkingDetailUrl } from "../../store/parkingSpots";
import { detailUrl as buildingDetailUrl } from "../../store/buildings";
import { detailUrl as realEstateDetailUrl } from "../../store/realEstates";
import ComponentOverviewDetail from "./componentDetail/Overview";

export const getPremisesDetailUrlFromRoom = (component) => {
  if (!component) return null;
  const { room, parking_spot: parkingSpot, building, realestate } = component;
  if (parkingSpot) return parkingDetailUrl({ id: parkingSpot.id });
  if (building) return buildingDetailUrl({ id: building.id });
  if (realestate) return realEstateDetailUrl({ id: realestate.id });
  if (!room) return null;
  if (room.apartment) return apartmentDetailUrl({ id: room.apartment.id });
  if (room.industrial_premises)
    return indpDetailUrl({ id: room.industrial_premises.id });
  if (room.common_area) return commonAreaDetailUrl({ id: room.common_area.id });
  if (room.brf_premis) return brfDetailUrl({ id: room.brf_premis.id });
};

export default function ComponentDetail() {
  const { path, url } = useRouteMatch();
  const { id } = useParams();

  const [component, componentLoading] = useErrandComponent(id);

  const premisesDetailUrl = getPremisesDetailUrlFromRoom(component);

  const currentProductUsage = getCurrentProductTypeUsage(component);
  const productType = currentProductUsage?.product_type;
  const componentType = productType?.component_type;
  const componentName = `${componentType?.name} (${productType?.name})`;

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ComponentOverviewDetail,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title={`Komponent${componentName ? ` - ${componentName}` : ""}`}
        breadCrumbs={[
          {
            label: component?.room?.room_id
              ? component?.room?.room_id
              : component?.parking_spot
              ? "Fordonsplats"
              : "Rum",
            url: `${premisesDetailUrl}/errands`,
          },
          {
            label: componentName || "Komponent",
          },
        ]}
        eventContentType="errands.componentplacement"
        eventObjectId={id}
        eventIdQueryStr="componentplacement"
        {...{ subPages: subPageLinks }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}
