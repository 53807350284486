import Query from "./Query";
import Confirm from "./Confirm";
import Type from "./Type";
import Exclusions from "./Exclusions";

// Map of chapterdef KEY: Component
export default {
  TYPE: Type,
  QUERY: Query,
  EXCLUDE: Exclusions,
  CONFIRM: Confirm,
};
