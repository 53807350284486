import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import { usePermissionCheck } from "../../store/base";

import CompanyModal from "../../components/Forms/Company/ChapterForm/ModalForm";
import { useCompanyPaginationCount } from "../../store/companies/hooks/retrieve";
import Companies from "./overview/Companies";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function MainCompanyOverview() {
  const { filteredRealEstates } = useActiveFilters();
  const { path, url } = useRouteMatch();
  const [createCompanyOpen, setCreateCompanyOpen] = React.useState(false);
  usePermissionRedirect(["view_can_company"]);

  const canAdd = usePermissionCheck("add_can_company");

  const [companyCount] = useCompanyPaginationCount({
    filters: { realestate_ids: filteredRealEstates },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: Companies,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal bolag: {companyCount != null ? companyCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <>
      {canAdd && (
        <PrimaryBtn onClick={() => setCreateCompanyOpen(true)}>
          Lägg till bolag
        </PrimaryBtn>
      )}
    </>
  );

  return (
    <>
      {createCompanyOpen && (
        <CompanyModal
          method="POST"
          onCheckout={() => setCreateCompanyOpen(false)}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Bolag`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
