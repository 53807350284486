import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_PERIODS_FOR_CONTRACT: {
      return { ...newState, [payload.name]: payload.data };
    }
    case constants.CLEAR_PERIODS_FOR_CONTRACT: {
      return { ...newState, [payload.name]: null };
    }
    default:
      return newState;
  }
};
