import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useAllPermissionCheck } from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { CompanyOrPrivate } from "../../components/Forms/Pickers";
import InviteMembersModal from "../../components/Details/Member/InviteMembersModal";
import useActiveFilters from "../../hooks/useActiveFilters";
import { useTenantPaginationCount } from "../../store/tenants";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import Members from "./overview/Members";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import TenantModalForm from "src/components/Forms/Tenant/ChapterForm/ModalForm";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function MainMemberOverview() {
  const { path, url } = useRouteMatch();
  const { filteredRealEstates } = useActiveFilters();

  const [activeCount] = useTenantPaginationCount({
    filters: { realestateids: filteredRealEstates, is_brf: true },
  });

  const [chooseMemberActive, setChooseMemberActive] = React.useState(false);
  const [createMemberActive, setCreateMemberActive] = React.useState(null);
  const [inviteMembersOpen, setInviteMembersOpen] = React.useState(false);
  usePermissionRedirect(["allow_brf", "view_can_tenant", "view_can_brf"]);

  const canAdd = useAllPermissionCheck([
    "add_can_user",
    "add_can_tenant",
    "add_can_brf",
  ]);
  const tenantPortalActive = useAllPermissionCheck([
    "allow_tenantportal",
    "view_can_tenantportal",
  ]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: Members,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal medlemmar: {activeCount || "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <div>
      {tenantPortalActive && canAdd && (
        <PrimaryBtn
          secondary
          onClick={() => setInviteMembersOpen(true)}
          className="mr-2"
        >
          Bjud in till "Mina sidor"
        </PrimaryBtn>
      )}
      {canAdd && (
        <PrimaryBtn onClick={() => setChooseMemberActive(true)}>
          Lägg till medlem
        </PrimaryBtn>
      )}
    </div>
  );

  return (
    <>
      {tenantPortalActive && (
        <InviteMembersModal
          open={inviteMembersOpen}
          closeFunction={() => setInviteMembersOpen(false)}
        />
      )}

      {canAdd && createMemberActive !== null && (
        <TenantModalForm
          method="POST"
          isMember
          kind={createMemberActive}
          onCheckout={() => setCreateMemberActive(null)}
        />
      )}
      <CompanyOrPrivate
        isMember
        isOpen={chooseMemberActive}
        onClose={() => setChooseMemberActive(false)}
        onSelect={(kind) => {
          setChooseMemberActive(false);
          setCreateMemberActive(kind);
        }}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Medlemmar`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
