import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlowFormBase from "../../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useMeasureTypeForm,
  constants,
  destroyPostForm,
  performImport,
} from "../../../../../store/IMDMeasureType";
import FullPageSpinner from "../../../../Loaders/FullPageSpinner";
import { useFormInstanceField } from "../../../../../store/base";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";

export default ({}) => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const storeName = constants.STORE_NAME;

  const formLoaded = Boolean(useMeasureTypeForm("POST"));
  const measureId = useFormInstanceField({
    storeName,
    fieldKey: "_import_measure_type.id",
  });

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(true));
    };
  }, []);

  const onSuccess = (data) => {
    setLoading(false);
    const sensorAmount = data.sensorCount || -1;
    const fromDate = data.earliestDate || "";
    const toDate = data.latestDate || "";

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Importen lyckades",
        description: `Värden för ${sensorAmount} importerades`,
      })
    );

    replace(
      `/imd/handle-sensors?postimport=true&sensor_amount=${sensorAmount}&from_date=${fromDate}&to_date=${toDate}`
    );
  };

  const errorCallback = (errorMessage) => {
    setLoading(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Importen misslyckades",
        description:
          typeof errorMessage === "string"
            ? errorMessage
            : errorMessage
            ? JSON.stringify(errorMessage)
            : "Kontrollera filen och kolumnkopplingarna och försök igen",
      })
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      performImport({
        id: measureId,
        data: data.parsed,
        successCallback: () => onSuccess(data),
        errorCallback,
      })
    );
  };

  return (
    <>
      {(!formLoaded || loading) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs({}),
          chapters,
          descriptions,
          onSubmit,
          title: "Import av mätvärden",
        }}
      />
    </>
  );
};
