import * as React from "react";

import FullTable from "../Tables/UserGroups/FullTable";

export default ({ persistantQuery, overrideRowClick, checkRowHighlighted }) => {
  return (
    <>
      <FullTable
        persistantQuery={persistantQuery}
        {...{ overrideRowClick, checkRowHighlighted }}
      />
    </>
  );
};
