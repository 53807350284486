import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import { IntegrationCompaniesWrapper } from "../../../components/Layout/Accounts/styles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { buildQueryString } from "../../../store/base";

import CompanyTable from "../../../components/Tables/ConfigCenter/Companies/FullTable";

import { useFilteredCompanies } from "../../../store/companies";

import { useDispatch, useSelector } from "react-redux";
import { bulkGetIntegrationSettings } from "../../../store/billectaIntegrations/store/actions";
import { useHistory } from "react-router-dom";

export default function VismaEEconomyIntegration() {
  const current = INTEGRATIONS.find((i) => i.title === "Visma eEkonomi");

  const { push } = useHistory();

  const integrationName = "Visma eEkonomi";
  const dispatch = useDispatch();
  const [companies, companiesLoading] = useFilteredCompanies(
    buildQueryString({ billecta_id__isnull: false })
  );

  const withCompanyInvoicingQuery = {
    billecta_id__isnull: false,
  };

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const onCompanySelected = (original) => {
    push(`activate-visma/company/${original.id}`);
  };

  const vismaSettings = useSelector(
    (state) => state.billectaIntegrations.vismaSettings
  );

  React.useEffect(() => {
    if (companies != null && !companiesLoading) {
      dispatch(
        bulkGetIntegrationSettings({
          companies,
          integrationSetting: "vismaSettings",
        })
      );
    }
  }, [companiesLoading]);

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper config={true}>
            <DetailPageBox
              style={{ flex: 1, width: "100%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Vismas hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Visma-integrationen bokför automatiskt händelser i Pigellos
                  hyresaviseringsmodul mot Visma eEkonomi.
                  <br />
                  <br />
                  Integrationen aktiveras under bolagsinställningarna på
                  respektive bolag
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <IntegrationCompaniesWrapper>
              <DetailPageBox
                style={{ flex: 1, marginRight: "5px", alignSelf: "flex-start" }}
              >
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Hantera integration</OverviewTitle>
                    <OverviewSubtitle>
                      Välj vilket bolag integrationen ska hanteras för
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <CompanyTable
                  {...{
                    persistantQuery: withCompanyInvoicingQuery,
                    tableId: "integration_visma_companies",
                    integrationSettings: vismaSettings,
                    integrationName: integrationName,
                    onRowClicked: onCompanySelected,
                  }}
                />
              </DetailPageBox>
            </IntegrationCompaniesWrapper>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
