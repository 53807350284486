import * as React from "react";

import { Confirm, BookKeeping } from "./Chapters";

export default (key, method) => {
  switch (key) {
    default:
      return <BookKeeping method={method} />;

    // default:
    //   return <Confirm {...{ method }} />;
  }
};
