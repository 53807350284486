import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  addToProcess,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import * as services from "./services";
import { MASS_INVOICING_UPDATE_ACTIONS } from "../../invoicingLease/store/actions";
import { removeFromProgress } from "../../base/store/actions";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess,
    forceData,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const url = `${constants.PATCH_URL}${id}`;

  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess,
    forceData,
  });
};

export const massUpdateInvoicingStatus = ({
  action,
  ids,
  realEstateIds,
  successCallback,
  errorCallback,
}) => {
  // pause_mode: true/false
  // AND
  // (ids: [], list of invoicingObj ids to update
  // OR
  // realestate_ids: []  list of realestate ids when updating ALL for that realestate)
  return async (dispatch) => {
    try {
      const patchObj = {
        pause_mode:
          action === MASS_INVOICING_UPDATE_ACTIONS.ACTIVATE
            ? false
            : action === MASS_INVOICING_UPDATE_ACTIONS.PAUSE
            ? true
            : null,
        ids: ids?.length ? ids : undefined,
        realestate_ids: realEstateIds?.length ? realEstateIds : undefined,
      };

      const res = await services.massUpdateInvoicingStatus({ patchObj });

      successCallback && successCallback();
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback();
    }
  };
};

export const previewInvoice = ({
  id,
  basedOn = null,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await services.getPreview({ id, basedOn });

      successCallback && successCallback(data);
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data, e?.response?.status);
    }
  };
};

export const generateInvoice = ({
  id,
  basedOn,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const postData = { based_on: basedOn };

      // THIS IS FOR SPECIFIC PERIOD METHOD

      // if (forSpecificPeriod) {
      //   postData.override_invoice_date = overrideInvoiceDate;
      //   postData.override_due_date = overrideDueDate;
      //   postData.override_period_start = overridePeriodStart;
      //   postData.override_period_end = overridePeriodEnd;
      // }
      const { data } = await services.generateNow({ id, postData });

      successCallback && successCallback(data);
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data);
    }
  };
};

export const getAvailablePeriods = ({
  id,
  basedOn,
  successCallback,
  errorCallback,
  savePeriodsForContract = false,
  processName,
}) => {
  return async (dispatch) => {
    try {
      const name = processName || `periods_${id}`;
      addToProcess(dispatch, constants, name);
      const { data } = await services.getPeriodInfo({ id, basedOn });

      successCallback && successCallback(data);

      if (savePeriodsForContract) {
        dispatch({
          type: constants.SET_PERIODS_FOR_CONTRACT,
          payload: {
            name,
            data,
          },
        });
      }

      removeFromProgress(dispatch, constants, name);
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data);
    }
  };
};

export const clearPeriodsForContract = ({ id }) => {
  return async (dispatch) => {
    const name = `periods_${id}`;

    dispatch({
      type: constants.CLEAR_PERIODS_FOR_CONTRACT,
      payload: {
        name,
      },
    });
  };
};
