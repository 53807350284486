import * as React from "react";
import { Redirect, useParams } from "react-router";

import {
  useReportErrandInvoice,
  detailUrl,
} from "../../../store/reportErrandInvoice";

export default function ReportErrandInvoicingRedirect() {
  const { id } = useParams();

  const [reportErrandInvoice] = useReportErrandInvoice(id);

  const reportErrandId = reportErrandInvoice?.report_errand?.id;

  // wait for sufficient data to redirect
  if (!reportErrandId) return null;

  return <Redirect to={detailUrl({ id: reportErrandId })} />;
}
