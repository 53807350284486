import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);
  const { type, payload } = action;

  switch (type) {
    case constants.CLEAR_INSIGHTSPAGE_VALUES: {
      return { ...INITIAL_STATE, resultData: {} };
    }

    case constants.SET_LOCAL_FILTERED_IDS: {
      const newResultData = { ...state, localRealEstateIds: payload.realestateIds, localCompanyIds: payload.companyIds, localPortfolioId: payload.portfolioId };
      return {
        ...newState,
        resultData: newResultData,
      };
    }
    default:
      return newState;
  }

}