import { axiosInstance } from "../../base/store/axios";
import constants from "../store/constants";

async function getAccountBalanceForDebtor(debtorId) {
  const { data } = await axiosInstance.get(
    `${constants.GET_URL}?id=${debtorId}`
  );

  return data;
}

async function addManualTransactionForDebtor({ postObj, debtorId }) {
  const res = await axiosInstance.post(
    `${constants.POST_URL}${debtorId}/`,
    postObj
  );

  return res;
}

export { getAccountBalanceForDebtor, addManualTransactionForDebtor };
