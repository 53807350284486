import * as React from "react";

// style, design
import * as SharedStyles from "../../../components/sharedStyles";

// store, state

import BookKeepingContainer from "../../../components/Billecta/BookKeeping/BookKeepingContainer";
import AccountingReportsContainer from "../../../components/Billecta/AccountingReports/AccountingReportsContainer";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useParams } from "react-router";
import { useBrfCompany } from "../../../store/brfCompanies";

const TABS = {
  BOOKKEEPING: "Inställningar",
  EXPORT: "Exporter",
};

export default function BrfCompanyBookkeepingSettings() {
  const { brfCompanyId } = useParams();
  const [brfCompany] = useBrfCompany(brfCompanyId);
  const [selectedTab, setSelectedTab] = React.useState("BOOKKEEPING");

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Bokföring</OverviewTitle>
          <PageSlider
            {...{ TABS }}
            selectedTab={selectedTab}
            onTabSelected={setSelectedTab}
          />
        </OverviewTitleWrapper>

        {selectedTab === "EXPORT" && brfCompany && (
          <AccountingReportsContainer
            forceCompanyId={brfCompany?.company?.id}
          />
        )}
        {selectedTab === "BOOKKEEPING" && brfCompany && (
          <BookKeepingContainer forceCompanyId={brfCompany?.company?.id} />
        )}
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
