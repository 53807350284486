import * as React from "react";

// style, design
import * as SharedStyles from "../../../components/sharedStyles";

// store, state

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { detailUrl } from "../../../store/companies";

import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { updateVismaSettings } from "../../../store/billectaIntegrations/store/actions";
import useQuery from "../../../components/utils/useQuery";
import PrimaryBtn from "../../../components/Forms/Base/Buttons/PrimaryBtn";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export default function CompanyAwaitActivateVisma() {
  const dispatch = useDispatch();

  const query = useQuery();

  const code = query.get("code");
  const error = query.get("error");

  const { replace } = useHistory();

  const voucherSeries = localStorage.getItem("visma_voucher_series");
  const startDate = localStorage.getItem("visma_start_date");
  const VismaCompanyName = localStorage.getItem("visma_company_name");
  const VismaCompanyOrgNo = localStorage.getItem("visma_company_org");
  const VismaCompanyId = localStorage.getItem("visma_company_id");
  const VismaCreditorId = localStorage.getItem("visma_creditor_id");

  const creditorId = VismaCreditorId;

  const handleSaveCode = () => {
    dispatch(
      updateVismaSettings({
        creditorId,
        forceData: {
          AccessCode: code,
          VismaCompanyName: VismaCompanyName,
          VismaCompanyOrgNo: VismaCompanyOrgNo,
          VoucherSeries: voucherSeries,
          StartDate:
            startDate != null && startDate != "undefined"
              ? startDate
              : undefined,
        },
        successCallback: () => {
          localStorage.removeItem("visma_company_name");
          localStorage.removeItem("visma_company_org");
          localStorage.removeItem("visma_voucher_series");
          localStorage.removeItem("visma_start_date");
          localStorage.removeItem("visma_company_org");
          localStorage.removeItem("visma_company_id");
          localStorage.removeItem("visma_creditor_id");
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: `Visma-integration aktiverad`,
              description:
                "Gå till Integrationer -> Visma -> Hantera för att uppdatera verifikationsserie",
            })
          );
          replace(detailUrl({ id: VismaCompanyId }));
        },
        errorCallback: (e) => {
          localStorage.removeItem("visma_company_name");
          localStorage.removeItem("visma_company_org");
          localStorage.removeItem("visma_voucher_series");
          localStorage.removeItem("visma_start_date");
          localStorage.removeItem("visma_company_org");
          localStorage.removeItem("visma_company_id");
          localStorage.removeItem("visma_creditor_id");
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                e?.response?.data?.Message ||
                "Kontakta Pigello om problemet kvarstår",
            })
          );
          replace(detailUrl({ id: VismaCompanyId }));
        },
      })
    );
  };

  React.useEffect(() => {
    if (!creditorId) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "CreditorId missing",
          description: "Invalid configuration",
        })
      );
      return;
    }

    if (error) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Visma skickade ett fel",
          description: JSON.stringify(error),
        })
      );

      return;
    }

    if (!code) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Visma skickade ej tillbaka någon kod",
          description: "Försök igen, kontakta Pigello om problemet kvarstår",
        })
      );

      return;
    }

    handleSaveCode();
  }, [creditorId]);

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>{"Aktiverar"} Visma-integration...</OverviewTitle>
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox>
          <SharedStyles.BodyText>
            {!code || !creditorId || !!error
              ? "Något gick fel"
              : "Inväntar aktivering av Visma-integration..."}
          </SharedStyles.BodyText>

          <div className="mt-4">
            {(!code || !creditorId || !!error) && (
              <PrimaryBtn
                onClick={() => replace(detailUrl({ id: VismaCompanyId }))}
              >
                Gå tillbaka till bolag{" "}
                <ChevronRightIcon className="ml-1" width={16} />
              </PrimaryBtn>
            )}
          </div>
        </SharedStyles.InnerBox>
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
