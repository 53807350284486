export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MARKETING_PARKING_SPOT_ADS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MARKETING_PARKING_SPOT_ADS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MARKETING_PARKING_SPOT_ADS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MARKETING_PARKING_SPOT_ADS",
  DESTROY_FORM: "DESTROY_FORM_MARKETING_PARKING_SPOT_ADS",
  SET_FORM_ERROR: "SET_FORM_ERROR_MARKETING_PARKING_SPOT_ADS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MARKETING_PARKING_SPOT_ADS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MARKETING_PARKING_SPOT_ADS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MARKETING_PARKING_SPOT_ADS",
  RESET_STATE: "RESET_STATE_MARKETING_PARKING_SPOT_ADS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MARKETING_PARKING_SPOT_ADS",
  REMOVE_OBJECT: "REMOVE_OBJECT_MARKETING_PARKING_SPOT_ADS",
  CLEAR_FETCHED: "CLEAR_FETCHED_MARKETING_PARKING_SPOT_ADS",

  ADD_MULTIPLE_PARKING_SPOTS: "ADD_MULTIPLE_PARKING_SPOTS_PARKING_SPOT_ADS",
  ADD_PARKING_SPOT: "ADD_PARKING_SPOT_PARKING_SPOT_ADS",
  REMOVE_PARKING_SPOT: "REMOVE_PARKING_SPOT_PARKING_SPOT_ADS",
  SET_ADS: "SET_ADS_PARKING_SPOT_ADS",
  SET_DEFAULT_SETTINGS: "SET_DEFAULT_SETTINGS_PARKING_SPOT_ADS",

  LIST_URL: "/market/ads/parkingspotad/list/",
  POST_URL: "/market/ads/parkingspotad/",
  PATCH_URL: "/market/ads/parkingspotad/",
  GET_URL: "/market/ads/parkingspotad/",

  STORE_NAME: "marketParkingSpotAds",
};
