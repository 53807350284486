export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_WIDGET_PLACEMENT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_WIDGET_PLACEMENT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_WIDGET_PLACEMENT",

  GET_VALUE_FOR_WIDGET_PLACEMENT: "GET_VALUE_FOR_WIDGET_PLACEMENT",
  SET_VALUE_FOR_WIDGET_PLACEMENT: "SET_VALUE_FOR_WIDGET_PLACEMENT",
  CLEAR_VALUES: "CLEAR_VALUES_FOR_WIDGETPLACEMENTS",
  CLEAR_SPECIFIC_VALUES: "CLEAR_SPECIFIC_VALUES_FOR_WIDGETPLACEMENT",
  CLEAR_REALESTATE_IDS: "CLEAR_REALESTATE_IDS_FOR_WIDGETPLACEMENT",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_WIDGET_PLACEMENT",
  DESTROY_FORM: "DESTROY_FORM_WIDGET_PLACEMENT",
  SET_FORM_ERROR: "SET_FORM_ERROR_WIDGET_PLACEMENT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_WIDGET_PLACEMENT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_WIDGET_PLACEMENT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_WIDGET_PLACEMENT",
  RESET_STATE: "RESET_STATE_WIDGET_PLACEMENT",
  CLEAR_FETCHED: "CLEAR_FETCHED_WIDGET_PLACEMENTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_WIDGET_PLACEMENT",
  REMOVE_OBJECT: "REMOVE_OBJECT_WIDGET_PLACEMENT",

  CLEAR_DELETE_QUEUE_WIDGET_PLACEMENT: "CLEAR_DELETE_QUEUE_WIDGET_PLACEMENT",
  SET_DELETE_QUEUE_WIDGET_PLACEMENT: "SET_DELETE_QUEUE_WIDGET_PLACEMENT",

  LIST_URL: "/insights/widgetplacement/list/",
  POST_URL: "/insights/widgetplacement/",
  PATCH_URL: "/insights/widgetplacement/",
  GET_URL: "/insights/widgetplacement/",

  STORE_NAME: "widgetPlacements",
};
