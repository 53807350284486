import { cloneDeep } from "lodash";
import { getFormFetchProcessName } from "../../base";
import baseReducer, {
  removeFromBackgroundTasks,
  removeFromProgress,
  INITIAL_STATE,
} from "../../base/store/reducer";
import { getReportOverviewStatsProcessName } from "../utils";

import constants from "./constants";

const STATE = {
  ...INITIAL_STATE,
  overviewStats: undefined,
};

export default (state = STATE, action) => {
  const { type, payload } = action;

  if (type === constants.SET_OVERVIEW_STATS) {
    return setOverviewStats(state, payload.result);
  }
  if (type === constants.POST_ACCEPT_DECLINE) {
    return postAcceptDecline(state, payload.id);
  }
  if (type === constants.INITIATE_INVOICING) {
    return initiateInvoicing(state, payload.id);
  }

  return baseReducer(state, action, constants);
};

const setOverviewStats = (state, result) => {
  const name = getReportOverviewStatsProcessName();

  const inProgress = removeFromProgress(state.inProgress, name);
  const backgroundTasks = removeFromBackgroundTasks(
    state.backgroundTasks,
    name
  );

  return {
    ...state,
    inProgress: inProgress,
    backgroundTasks: backgroundTasks,
    overviewStats: result,
  };
};

const postAcceptDecline = (state, id) => {
  // refresh the instance
  const clone = cloneDeep(state);

  const formFetchName = getFormFetchProcessName("PATCH");
  const inProgress = removeFromProgress(state.inProgress, formFetchName);

  delete clone.all[id];
  clone.forms["PATCH"] = undefined;
  clone.formInstance = {};
  clone.paginations = {};
  clone.filtered = {};
  clone.inProgress = inProgress;

  return clone;
};

const initiateInvoicing = (state, id) => {
  // refresh the instance
  const clone = cloneDeep(state);

  const formFetchName = getFormFetchProcessName("PATCH");
  const inProgress = removeFromProgress(state.inProgress, formFetchName);

  delete clone.all[id];
  clone.forms["PATCH"] = undefined;
  clone.formInstance = {};
  clone.paginations = {};
  clone.filtered = {};
  clone.inProgress = inProgress;

  return clone;
};
