import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
export default () => [
  {
    Header: "Aktiverat",
    accessor: "should_sync_to_avytmpl",
    Cell: ({ value }) => (
      <StatusLabel state={value ? 0 : 3}>
        {value ? "Aktiverat" : "Ej aktiverat"}
      </StatusLabel>
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Beteckning",
    accessor: "key",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Bolag",
    accessor: "company.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Avy/Tmpl-ID Genererat",
    accessor: "avytmpl_id",
    Cell: ({ value }) => (
      <StatusLabel state={value ? 0 : 3}>
        {value ? "Genererat" : "Ej genererat"}
      </StatusLabel>
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
