import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { TextButton } from "src/components/Forms/Base/Buttons";
export default ({ onExcludeClicked }) => [
  {
    Header: "",
    id: "exclude",
    Cell: ({ row }) => {
      return (
        <TextButton
          red
          iconType="close"
          title="Exkludera"
          clicked={() => onExcludeClicked(row)}
        />
      );
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Namn",
    accessor: "name",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fastighet",
    accessor: "realestate.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Adress",
    accessor: "addresses[0].str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
