import * as React from "react";

import { constants } from "../../../../../store/reportErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { TimePicker } from "../../../Base/Fields";
import DurationField from "../../../Base/Fields/DurationField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Planering av ärende</OverviewTitle>
          <OverviewSubtitle>
            Planera in ärendets start, slut och förväntade arbetstid
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TimePicker
        storeName={storeName}
        fieldKey={"planned_start"}
        method={method}
        title="Planerat startdatum"
      />
      <TimePicker
        storeName={storeName}
        fieldKey={"planned_end"}
        method={method}
        title="Planerat slutdatum"
      />

      <DurationField
        description="Ange hur lång den förväntade faktistka arbetstiden är"
        storeName={storeName}
        fieldKey={"expected_duration"}
        method={method}
        extraStyles={{ marginTop: "12px" }}
        title="Förväntad arbetstid"
      />
    </>
  );
};
