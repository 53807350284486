import moment from "moment";
import * as XLSX from "xlsx";

function excelDateToJs(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}

export default ({ importData }) => {
  const headerRowValue = importData._headerRow - 1;
  if (headerRowValue < 0) {
    return {
      result: { _headerRow: "Detta värde måste vara mer än eller lika med 1" },
      error: true,
    };
  }

  const data = importData._xlsxFileImportData_;
  const workBook = XLSX.read(data.split("base64,")[1], { type: "base64" });

  const sheetName = importData._sheetName;
  if (!workBook.SheetNames.includes(sheetName)) {
    return {
      result: { _sheetName: "Fliken finns inte i excel filen" },
      error: true,
    };
  }

  const sheet = workBook.Sheets[sheetName];
  const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

  // remove empty arrays = removed but not deleted Excel rows
  const cleanedData = parsedData.filter((row) => !!row.length > 0);

  if (headerRowValue >= cleanedData.length - 1) {
    return {
      result: {
        _headerRow:
          "Kolumn rubrikerna kan inte vara placerade i slutet av filen",
      },
      error: true,
    };
  }

  const headerRow = cleanedData[headerRowValue];

  const sensorIdIndex = headerRow.findIndex(
    (a) => a === importData._sensorIdColumnName
  );
  if (sensorIdIndex == null || sensorIdIndex < 0) {
    return {
      result: {
        _sensorIdColumnName:
          "Detta värde hittades inte på den angivna rubrik raden",
      },
      error: true,
    };
  }

  const dateIndex = headerRow.findIndex(
    (a) => a === importData._dateColumnName
  );
  if (dateIndex == null || dateIndex < 0) {
    return {
      result: {
        _dateColumnName:
          "Detta värde hittades inte på den angivna rubrik raden",
      },
      error: true,
    };
  }

  const valueIndex = headerRow.findIndex(
    (a) => a === importData._valueColumnName
  );
  if (valueIndex == null || valueIndex < 0) {
    return {
      result: {
        _valueColumnName:
          "Detta värde hittades inte på den angivna rubrik raden",
      },
      error: true,
    };
  }

  const apartmentIndex = headerRow.findIndex(
    (a) => a === importData._apartmentColumnName
  );
  const industrialIndex = headerRow.findIndex(
    (a) => a === importData._industrialColumnName
  );
  const brfIndex = headerRow.findIndex((a) => a === importData._brfColumnName);
  const parkingIndex = headerRow.findIndex(
    (a) => a === importData._parkingColumnName
  );
  const commonIndex = headerRow.findIndex(
    (a) => a === importData._commonAreaColumnName
  );

  const valueRows = cleanedData.slice(headerRowValue + 1);

  let parsed = [];
  let errors = [];
  for (let index = 0; index <= headerRowValue; index++) {
    errors.push({});
  }

  let _sensorCounter = new Set();
  let _earliestDate = null;
  let _latestDate = null;

  for (let index = 0; index < valueRows.length; index++) {
    const element = valueRows[index];

    let err = {};

    const sensorId = element[sensorIdIndex];

    if (sensorId == null) {
      err[importData._sensorIdColumnName] =
        "Detta värde måste vara en ifylld sträng";
    }

    let date = element[dateIndex];
    if (date == null || isNaN(date)) {
      err[importData._dateColumnName] =
        "Detta värde måste vara ett ifyllt datum";
    } else {
      date = moment(excelDateToJs(date));
      if (!date._isValid) {
        err[importData._dateColumnName] =
          "Detta värde måste vara ett ifyllt datum";
      }
    }

    const value = element[valueIndex];
    if (value == null || isNaN(value)) {
      err[importData._valueColumnName] =
        "Detta värde måste vara ett ifyllt siffer-värde";
    }

    const apartment = element[apartmentIndex];
    const industrial = element[industrialIndex];
    const parking = element[parkingIndex];
    const brf = element[brfIndex];
    const common = element[commonIndex];

    if (
      [apartment, industrial, parking, brf, common].filter((a) => !!a).length >
      1
    ) {
      err = {
        ...err,
        _apartmentColumnName: "Det får endast finnas en koppling per rad",
        _industrialColumnName: "Det får endast finnas en koppling per rad",
        _parkingColumnName: "Det får endast finnas en koppling per rad",
        _brfColumnName: "Det får endast finnas en koppling per rad",
        _commonAreaColumnName: "Det får endast finnas en koppling per rad",
      };
    }

    const hasErr = Object.keys(err).length > 0;
    if (!hasErr) {
      parsed.push({
        date: date.format("YYYY-MM-DD HH:mm:ss"),
        value: value,
        sensor_id: sensorId.toString(),
        apartment: apartment,
        industrial_premises: industrial,
        parking_spot: parking,
        brf_premis: brf,
        common_area: common,
      });

      _sensorCounter.add(sensorId);
      if (!_earliestDate || date < _earliestDate) {
        _earliestDate = date;
      }
      if (!_latestDate || date > _latestDate) {
        _latestDate = date;
      }
    }
    errors.push(err);
  }

  const hasErr = errors.some((a) => Object.keys(a).length > 0);
  if (hasErr) {
    return { result: { _xlsxFileImport: errors }, error: true };
  } else if (parsed.length === 0) {
    return {
      result: {
        _xlsxFileImport: [
          { [sheetName]: "Inga värden kunde plockas ut för import" },
        ],
      },
      error: true,
    };
  }

  return {
    result: {
      parsed: parsed,
      earliestDate: _earliestDate
        ? _earliestDate.format("YYYY-MM-DD HH:mm:ss")
        : null,
      latestDate: _latestDate
        ? _latestDate.format("YYYY-MM-DD HH:mm:ss")
        : null,
      sensorCount: _sensorCounter.size,
    },
    error: false,
  };
};
