export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PAYMENT_CONTRIBUTION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PAYMENT_CONTRIBUTION",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PAYMENT_CONTRIBUTION",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PAYMENT_CONTRIBUTION",
  DESTROY_FORM: "DESTROY_FORM_PAYMENT_CONTRIBUTION",
  SET_FORM_ERROR: "SET_FORM_ERROR_PAYMENT_CONTRIBUTION",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PAYMENT_CONTRIBUTION",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PAYMENT_CONTRIBUTION",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PAYMENT_CONTRIBUTION",
  RESET_STATE: "RESET_STATE_PAYMENT_CONTRIBUTION",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PAYMENT_CONTRIBUTION",
  REMOVE_OBJECT: "REMOVE_OBJECT_PAYMENT_CONTRIBUTION",
  CLEAR_FETCHED: "CLEAR_FETCHED_PAYMENT_CONTRIBUTION",

  LIST_URL: "/brf/loans/paymentcontributionrecord/list/",
  POST_URL: "/brf/loans/paymentcontributionrecord/",
  PATCH_URL: "/brf/loans/paymentcontributionrecord/",
  GET_URL: "/brf/loans/paymentcontributionrecord/",

  STORE_NAME: "paymentContribution",
};
