import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import { cloneDeep } from "lodash";
import * as services from "./services";
export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  //remove contract_payment_term_days if it's an empty string, which happends if you set it, remove it then set "last in month"
  if (!cloned?.debt_setting?.contract_payment_term_days) {
    delete cloned?.debt_setting?.contract_payment_term_days;
  }
  //since askUpdateSharedSetting only exists in here, both checks exists here to catch both cases
  if (!cloned?.contract_payment_term_days) {
    delete cloned?.contract_payment_term_days;
  }

  return cloned;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  preventDefaultToast,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
    preventDefaultToast,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
  });
};

export const askUpdateSharedSetting = ({
  invoicingSettingData,
  debtSettingData,
  successCallback,
  errorCallback,
  currentConstants,
}) => {
  return async (dispatch) => {
    try {
      const data = {
        invoicingsetting: invoicingSettingData,
        debthandlingsetting: complementedPreProcess(debtSettingData),
      };

      const calls = [
        services.fetchAskUpdateSharedSettingLeaseInvoicing(data),
        services.fetchAskUpdateSharedSettingOtherInvoicing(data),
        services.fetchAskUpdateSharedSettingParkingInvoicing(data),
        services.fetchAskUpdateSharedSettingBrfInvoicing(data),
      ];

      const results = await Promise.all(calls);

      const [
        { data: leaseInvoicing },
        { data: otherInvoicing },
        { data: parkingInvoicing },
        { data: brfInvoicing },
      ] = results;

      successCallback &&
        successCallback({
          leaseInvoicing,
          otherInvoicing,
          parkingInvoicing,
          brfInvoicing,
        });
    } catch (e) {
      let returnedData = e?.response?.data;

      const co = currentConstants || constants;

      dispatch({
        type: co.SET_FORM_ERROR,
        payload: { result: returnedData },
      });

      errorCallback && errorCallback(e);
    }
  };
};
