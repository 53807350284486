import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import StandardModal from "../../../components/Modals/StandardModal";

import { useInvoicingSetting } from "../../../store/invoicingSettings";

import { cloneDeep } from "lodash";
import LocalDateSelect from "src/components/Forms/Base/Fields/LocalDateSelect";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";
import Table from "../../../components/Billecta/Table/BasicTable";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedRentField from "../../../components/Forms/Base/Old/NonConnected/NonConnectedRentField";
import NonConnectedSelect from "../../../components/Forms/Base/Old/NonConnected/NonConnectedSelect";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { InnerBox } from "../../../components/sharedStyles";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { useFrequentPermissions } from "../../../store/base";

export default function HandleCostProposal({
  currentEditProposal,
  currentEditProposalDetails,
  updateProposalAttr,
  editId,
  setEditId,
}) {
  const [editCost, setEditCost] = React.useState(false);
  const currentEditCost = currentEditProposal?.cost_set?.[editCost];

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const baseVat = currentEditProposal?.vat;
  const [setting] = useInvoicingSetting(currentEditProposal?.setting?.id);
  const tableCosts = React.useMemo(() => {
    return [
      ...(currentEditProposal?.cost_set || []),
      ...(currentEditProposalDetails?._contract?.apartments
        ?.map((a) => a.cost_set)
        .flat() || []),
      ...(currentEditProposalDetails?._contract?.parking_spots
        ?.map((a) => a.cost_set)
        .flat() || []),
      ...(currentEditProposalDetails?._contract?.industrial_premises_list
        ?.map((a) => a.cost_set)
        .flat() || []),
    ];
  }, [currentEditProposal, currentEditProposalDetails]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Debiteras",
        accessor: "do_not_debit",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 8 : 1}>
              {value ? "Debiteras Inte" : "Debiteras"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Typ",
        accessor: "_",
        Cell: ({ row }) => {
          const isDiscount = row.original.value < 0;
          return (
            <StatusLabel state={isDiscount ? 3 : 1}>
              {isDiscount ? "Rabatt" : "Kostnad"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Produkt",
        accessor: "product",
        Cell: ({ value }) => {
          const title = value?.str_representation || value?.title || "";

          return <div>{title}</div>;
        },
      },
      {
        Header: "SEK/månad",
        accessor: "value",
        Cell: ({ value }) => {
          return <div>{toMoneyString(value || 0)}</div>;
        },
      },
      {
        Header: "Momssats (%)",
        accessor: "vat",
        Cell: ({ value, row }) => {
          const productVat = row.original.product?.vat;
          const val =
            value != null
              ? `${value}`
              : productVat != null
              ? `${productVat} (från produkt)`
              : baseVat != null
              ? `${baseVat} (från grundmomssats)`
              : "-";
          return <div>{val}</div>;
        },
      },
      {
        Header: "Börjar aviseras för",
        accessor: "start_date",
        Cell: ({ value }) => {
          if (!value) return <div>Med avtalet</div>;
          return <div>{value}</div>;
        },
      },
      {
        Header: "Slutar aviseras för",
        accessor: "end_date",
        Cell: ({ value }) => {
          if (!value) return <div>Med avtalet</div>;

          return <div>{value}</div>;
        },
      },
    ];

    if (hasBillectaViewPermission) {
      cols.push({
        Header: "Kostnadsställe",
        accessor: "cost_center",
        Cell: ({ value }) => {
          const title = value?.title
            ? value.title
            : value?.str_representation
            ? value.str_representation
            : setting?.cost_center
            ? `${setting?.cost_center?.str_representation} (från inställning)`
            : "-";

          return <div>{title}</div>;
        },
      });

      cols.push({
        Header: "Projekt",
        accessor: "project",
        Cell: ({ value }) => {
          const title = value?.title
            ? value.title
            : value?.str_representation
            ? value.str_representation
            : setting?.project
            ? `${setting?.project?.str_representation} (från inställning)`
            : "-";

          return <div>{title}</div>;
        },
      });
    }

    return cols;
  }, [hasBillectaViewPermission, baseVat, currentEditProposal, setting]);

  const checkRowError = (row) => {
    return false;
  };

  const handleAddRow = () => {
    const newCostRows = cloneDeep(currentEditProposal?.cost_set || []);
    newCostRows.push({
      product: null,
      cost_center: null,
      project: null,
      title: "Ny kostnad",
      value: 0,
      order: 1,
      start_date: null,
      end_date: null,
      vat: null,
    });
    updateProposalAttr({ attr: "cost_set", val: newCostRows });
    setEditCost(newCostRows.length - 1); // set edit index
  };

  const handleEditRowVal = ({ attr, val }) => {
    const newCostRows = cloneDeep(currentEditProposal?.cost_set || []);

    newCostRows[editCost][attr] = val;
    updateProposalAttr({ attr: "cost_set", val: newCostRows });
  };

  return (
    <StandardModal
      title="Redigera kostnadsinställning"
      isOpen={!!editId}
      closeFunction={
        currentEditCost ? () => setEditCost(null) : () => setEditId(null)
      }
      actionBarCancelTitle="Klar"
      withActionBar
    >
      {!currentEditCost ? (
        <>
          {" "}
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Redigera kostnadsinställning</OverviewTitle>
              <OverviewSubtitle>
                Ändringar sparas direkt, tryck på "Klar" för att stänga modalen.{" "}
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalTableSelectField
              id="billing_company"
              TableComponent={CompanyTable}
              value={currentEditProposal?.billing_company}
              placeholder="Välj fakturerande bolag..."
              title="Fakturerande bolag"
              onChange={(val) =>
                updateProposalAttr({ attr: "billing_company", val })
              }
            />
          </div>
          {hasBillectaViewPermission && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={CompanyTable}
                id="setting"
                value={currentEditProposal?.setting}
                TableComponent={InvoicingSettingTable}
                placeholder="Välj aviseringsinställning..."
                title="Aviseringsinställning"
                onChange={(val) => updateProposalAttr({ attr: "setting", val })}
              />
            </div>
          )}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalDateSelect
              id="start_date"
              value={currentEditProposal?.start_date}
              onChange={(val) =>
                updateProposalAttr({ attr: "start_date", val })
              }
              title="Startdatum för avisering"
            />
          </div>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Debiteringsrader</OverviewTitle>
              <OverviewSubtitle style={{ maxWidth: "80%" }}>
                Tryck på en rad för att redigera den. Endast rader som tillhör
                avtalet kan redigeras. Redigering av det uthyrda objektets
                debiteringsrader görs inne på detaljsidan för respektive objekt.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <TextButton
              title="Lägg till rad"
              clicked={handleAddRow}
              iconType="add"
              iconPlacement="right"
              extraStyle={{ marginRight: 0, minWidth: "20%" }}
            />
          </OverviewTitleWrapper>
          <Table
            onRowClicked={(row) => setEditCost(row.index)}
            columns={tableColumns}
            data={tableCosts}
            checkRowError={checkRowError}
            hideSearch
          />
        </>
      ) : (
        <>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Redigera kostnad</OverviewTitle>
              <OverviewSubtitle>
                Ändringar sparas direkt, tryck på "Klar" för att gå tillbaka
                till kostnadsinställningar. Tryck på "Radera debiteringsrad"
                längst ner för att ta bort debiteringsraden.{" "}
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <InnerBox style={{ marginBottom: 24 }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Grundinställningar</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <NonConnectedTextInput
              id="cost_title"
              value={currentEditCost?.title}
              onChange={(val) => handleEditRowVal({ attr: "title", val })}
              label="Namn på kostnad"
            />

            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={InvoicingProductTable}
                id="product_cost"
                value={currentEditCost?.product}
                placeholder="Välj produkt..."
                title="Produkt"
                onChange={(val) => handleEditRowVal({ attr: "product", val })}
              />
            </div>

            <NonConnectedRentField
              id="value_cost"
              value={currentEditCost?.value}
              onUpdate={(val) => handleEditRowVal({ attr: "value", val })}
              title="Belopp"
            />

            <NonConnectedSelect
              id="vat_cost"
              value={currentEditCost?.vat}
              choices={[
                {
                  d: "0%",
                  v: 0,
                },
                {
                  d: "6%",
                  v: 6,
                },
                {
                  d: "12%",
                  v: 12,
                },
                {
                  d: "25%",
                  v: 25,
                },
              ]}
              getOptionLabel={(o) => o.d}
              getOptionValue={(o) => o.v}
              onUpdate={(val) => handleEditRowVal({ attr: "vat", val })}
              label="Momssats"
            />
          </InnerBox>

          <InnerBox style={{ marginBottom: 24 }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Intervall för avisering</OverviewTitle>
                <OverviewSubtitle>
                  <strong>OBS: </strong>Lämna dessa fält tomma om kostnaden
                  debiteras löpande under avtalet.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            <NonConnectedDatePicker
              id="startdate_cost"
              value={currentEditCost?.start_date}
              onChange={(val) => handleEditRowVal({ attr: "start_date", val })}
              label="Börjar debiteras"
            />
            <NonConnectedDatePicker
              id="enddate_cost"
              value={currentEditCost?.end_date}
              onChange={(val) => handleEditRowVal({ attr: "end_date", val })}
              label="Slutar debiteras"
            />
          </InnerBox>

          {hasBillectaViewPermission && (
            <InnerBox style={{ marginBottom: 24 }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Bokföring</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <div className="grid grid-cols-2 gap-6 mb-6">
                <LocalTableSelectField
                  TableComponent={InvoicingProjectsTable}
                  id="project_cost"
                  value={currentEditCost?.project}
                  placeholder="Välj projekt..."
                  title="Projekt"
                  onChange={(val) => handleEditRowVal({ attr: "project", val })}
                />
              </div>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <LocalTableSelectField
                  TableComponent={InvoicingCostCenterTable}
                  id="costcenter_cost"
                  value={currentEditCost?.cost_center}
                  placeholder="Välj kostnadsställe..."
                  title="Kostnadsställe"
                  onChange={(val) =>
                    handleEditRowVal({ attr: "cost_center", val })
                  }
                />
              </div>
            </InnerBox>
          )}

          <div>
            <PrimaryButton title="Klar" clicked={() => setEditCost(null)} />
          </div>

          <div style={{ marginTop: 12 }}>
            <TextButton
              title="Radera debiteringsrad"
              iconType="close"
              iconPlacement="right"
              clicked={() => {}}
              red
            />
          </div>
        </>
      )}
    </StandardModal>
  );
}
