export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_KYC_REPORTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_KYC_REPORTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_KYC_REPORTS",
  DESTROY_FORM: "DESTROY_FORM_KYC_REPORTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_KYC_REPORTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_KYC_REPORTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_KYC_REPORTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_KYC_REPORTS",
  RESET_STATE: "RESET_STATE_KYC_REPORTS",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_KYC_REPORTS",

  GET_URL: "/accounting/general/kyc_report/",
  POST_URL: "/accounting/general/kyc_report/",
  LIST_URL: "/acccount/general/kyc_report/list",

  STORE_NAME: "billectaKyc",
};
