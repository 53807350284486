import { cloneDeep } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  addToProcess,
} from "../../base";
import { removeFromProgress } from "../../base/store/actions";
import { getSingleFetchProcessName } from "../../base/utils";
import { store } from "../../store";
import { addToast, TOAST_TYPES } from "../../toasts";
import constants from "./constants";
import * as services from "./services";
import { createUploadHandler } from "./uploadHandler";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  // the attachments
  (cloned?.documents || []).forEach((a) => {
    if (a) {
      // will never be sent to backend
      delete a._tempData;
      delete a._referenceId;

      if (a.id) {
        // file has not been edited, so we can just use the id
        delete a?.file;
      }
    }
  });

  return cloned;
};

export const create = ({
  processSuccess,
  processError,
  preProcess,
  successCallback,
  errorCallback,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    preProcess: fullPreProcess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  preProcess,
  successCallback,
  errorCallback,
  forceData,
  preventDefaultToast,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    processSuccess,
    preProcess: fullPreProcess,
    processError,
    successCallback,
    errorCallback,
    forceData,
    preventDefaultToast,
    uploadCallback,
  });
};

export const LEAD_ACCEPT_TYPES = {
  ACCEPT_IN_ORDER: 1,
  ACCEPT_IN_ALL_SOURCES: 2,
};

export const acceptLeads = ({
  ids,
  acceptType,
  homeQRejectionReason,
  sbfRejectionReason,
  prioOrder,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      if (!acceptType) {
        errorCallback("Vänligen specificera bekräftelseläge");
        return;
      }
      if (
        acceptType === LEAD_ACCEPT_TYPES.ACCEPT_IN_ORDER &&
        prioOrder.length === 0
      ) {
        errorCallback("Vänligen specificera ordning på bekräftelse");
        return;
      }
      if (!ids?.length > 0) {
        errorCallback("Inga leads valda");
        return;
      }

      const postData = {
        ids,
        homeq_rejection_reason_id: homeQRejectionReason,
        sbf_rejection_reason: sbfRejectionReason,
        accept_in_all_sources:
          acceptType === LEAD_ACCEPT_TYPES.ACCEPT_IN_ALL_SOURCES,
        accept_in_order:
          acceptType === LEAD_ACCEPT_TYPES.ACCEPT_IN_ORDER
            ? prioOrder
            : undefined,
      };

      await services.confirmLeads({ postData });

      successCallback();
    } catch (e) {
      console.log(e.response.data);
      let message;

      if (e.response.data.object_clashes?.length > 0) {
        message =
          "Ett eller flera objekt har fler än en lead på sig som försöker bekräftas";
      }

      errorCallback(message);
    }
  };
};

export const rejectLeads = ({
  ids,
  successCallback,
  errorCallback,
  homeQRejectionReason,
  sbfRejectionReason,
}) => {
  return async (dispatch) => {
    try {
      if (!ids?.length > 0) {
        errorCallback("Inga leads valda");
        return;
      }

      const postData = {
        ids,
        homeq_rejection_reason_id: homeQRejectionReason,
        sbf_rejection_reason: sbfRejectionReason,
      };

      await services.rejecteads({ postData });

      successCallback();
    } catch (e) {
      console.log(e.response.data);
      let message; // add on potential error messages

      errorCallback(message);
    }
  };
};

export const LEAD_RANKING_TYPES = {
  APARTMENT: "APARTMENT",
  INDP: "INDP",
  PARKING: "PARKING",
};

export const getLeadRankings = ({ ids, type }) => {
  return async (dispatch) => {
    const name = `${type}${ids.join(",")}`;
    const state = store.getState();
    const inProgress = state[constants.STORE_NAME].inProgress.includes(name);

    if (inProgress) return;
    addToProcess(dispatch, constants, name);

    try {
      const url =
        type === LEAD_RANKING_TYPES.APARTMENT
          ? constants.LEADS_RANKING_APARTMENT_URL
          : type === LEAD_RANKING_TYPES.INDP
          ? constants.LEADS_RANKING_INDP_URL
          : constants.LEADS_RANKING_PARKING_URL;

      const { data } = await services.getRankings({ ids, url });

      dispatch({
        type: constants.SET_LEAD_RANKING,
        payload: {
          name,
          rankings: data,
        },
      });

      removeFromProgress(dispatch, constants, name);
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta toppleads",
          description:
            "Försök igen senare, kontakta Pigello om problemet kvarstår",
        })
      );
    }
  };
};

export const getLeadPerStageCount = ({ requestName }) => {
  return async (dispatch) => {
    const state = store.getState();
    const inProgress =
      state[constants.STORE_NAME].inProgress.includes(requestName);

    if (inProgress) return;
    addToProcess(dispatch, constants, requestName);

    try {
      const { data } = await services.getLeadPerStageCount();

      dispatch({
        type: constants.SET_LEAD_PER_STAGE_COUNT,
        payload: {
          data,
        },
      });

      removeFromProgress(dispatch, constants, requestName);
    } catch (e) {
      console.log(e);
    }
  };
};

export const clearLeadRankings = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_LEAD_RANKING,
    });
  };
};
