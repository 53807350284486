const defs = [
  {
    title: "Nyckel",
    key: "KEY",
    visited: true,
    hasError: false,
    fieldKeys: [
      "user",
      "possession",
      "key_name",
      "electronic_key",
      "pin_code"
    ],
  },
  {
    title: "Behörigheter",
    key: "PERMISSIONS",
    visited: false,
    hasError: false,
    fieldKeys: [
      "permission",
      "start_time",
      "end_time",
      "block_automatic_time_conforming"
    ],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
