import { axiosInstance } from "../../base/store/axios";
import { buildQueryString, checkTrailingUrlSlash } from "../../base";
import moment from "moment";
import constants from "./constants";
import { constants as tenantConstants } from "../../tenants";
import { constants as debtorConstants } from "../../invoicingDebtor";

export const CONTRACT_TYPES = {
  LEASE: "LEASE",
  PARKING: "PARKING",
  OTHER: "OTHER",
  BRF_PREMIS: "BRF_PREMIS",
};

async function generateInvoicePreview({ postObj }) {
  const {
    data: { data: preview },
  } = await axiosInstance.post(
    `/accounting/gateways/invoice-utils/?action=preview`,
    postObj
  );

  return preview;
}

async function downloadBillectaFile(id) {
  const {
    data: { data: preview },
  } = await axiosInstance.get(`/accounting/gateways/files/${id}/?action=file`);

  return preview;
}

async function generateInvoiceIdPreview(id) {
  const {
    data: { data: preview },
  } = await axiosInstance.get(
    `/accounting/gateways/invoice-utils/${id}/?action=preview`
  );

  return preview;
}

async function sendInvoice(invoiceActionId, method) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=sendinvoice&method=${method}`
  );

  return data;
}

async function cancelReminders(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=cancelreminder`
  );

  return data;
}

async function disputeInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=dispute`
  );

  return data;
}

async function undisputeInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=canceldispute`
  );

  return data;
}

async function getAllInvoices(creditorId, closedFromDate) {
  const toDate = moment().add(1, "day").format("YYYY-MM-DD");
  const fromDate =
    closedFromDate || moment().subtract(3, "months").format("YYYY-MM-DD");

  const promises = [
    axiosInstance.get(`/accounting/gateways/invoices/open/${creditorId}/`),
    axiosInstance.get(
      `/accounting/gateways/invoices/closed/${creditorId}/?from=${fromDate}&to=${toDate}`
    ),
  ];
  const [{ data: openData }, { data: closedData }] = await Promise.all(
    promises
  );

  return [...openData, ...closedData];
}

async function getAllContractInvoices(creditorId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/contract_invoices/${creditorId}/`
  );

  return data;
}

async function getAllConnectedPayments({
  creditorId,
  connectedFromDate,
  connectedToDate,
}) {
  const { data } = await axiosInstance.put(
    "/accounting/gateways/payments/connected/",
    {
      CreditorPublicId: creditorId,
      From: connectedFromDate,
      To: connectedToDate,
    }
  );

  return data;
}

async function getAllUnconnectedPayments(creditorId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/payments/unconnected/${creditorId}/`
  );

  return data;
}

async function getSingleInvoice(invoiceActionId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/invoice/${invoiceActionId}/`
  );

  return data;
}

async function getReminderInvoice(invoiceActionId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/invoice/reminder_invoice/${invoiceActionId}/`
  );

  return data;
}

async function getDebtCollectionInfo(invoiceActionId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/invoice/debt_collection/${invoiceActionId}/`
  );

  return data;
}

async function cancelDebtCollection(debtCollectionId) {
  const { data } = await axiosInstance.put(
    `/accounting/gateways/invoice/cancel_debt_collection/${debtCollectionId}/`
  );
  return data;
}

async function postponeDebtCollection(debtCollectionId, toDate) {
  const { data } = await axiosInstance.put(
    `/accounting/gateways/invoice/postpone_debt_collection/${debtCollectionId}/?date=${toDate}`
  );
  return data;
}

async function attestInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=attest`
  );

  return data;
}

async function mergeInvoices({ invoiceIds, invoiceDate, dueDate }) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/?action=merge&invoicedate=${invoiceDate}&duedate=${dueDate}&${invoiceIds
      .map((i) => `actionpublicid=${i}`)
      .join("&")}`
  );

  return data;
}

async function deleteInvoice(invoiceActionId) {
  const { data } = await axiosInstance.delete(
    `/accounting/gateways/invoice/${invoiceActionId}/`
  );

  return data;
}

async function pauseInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=pause`
  );

  return data;
}

// createinvoice - generera nu
// pause - pausa
// resume - resuma - speca tidigast start
// movenextrun ?query eller data - kolla dok billecta

async function resumeInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=resume`
  );

  return data;
}

async function creditInvoice({ creditInvoiceActionId, debitInvoiceActionId }) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${debitInvoiceActionId}/?action=creditaction&creditinvoiceactionpublicid=${creditInvoiceActionId}`
  );

  return data;
}

async function createCreditInvoice(postObj) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/invoice/`,
    postObj
  );

  return data;
}

async function registerInvoicePayment({ postObj, invoiceActionId }) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=registerpayment`,
    postObj
  );

  return data;
}

async function registerDebtHandlingPaymnet({ postObj, invoiceActionId }) {
  const { data } = await axiosInstance.post(
    "/accounting/gateways/invoice-utils/register_debt_handling_payment/",
    postObj
  );

  return data;
}

async function matchPaymentsToInvoice({
  paymentIds,
  invoiceId,
  paymentDate,
  creditorId,
}) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/payments/match/${creditorId}/`,
    paymentIds.map((pid) => ({
      UnhandledPaymentPublicId: pid,
      ActionPublicId: invoiceId,
      PaymentDate: paymentDate,
    }))
  );

  return data;
}

async function deletePayment({
  paymentId,
  bookKeepingAccount,
  transactionDate,
}) {
  const res = await axiosInstance.delete(
    `/accounting/gateways/payments/unconnected/?paymentpublicid=${paymentId}${
      bookKeepingAccount ? `&bookkeepingaccount=${bookKeepingAccount}` : ""
    }${transactionDate ? `&transactiondate=${transactionDate}` : ""}`
  );

  return res;
}

async function sendManualReminderInvoice({ body }) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/?action=sendreminderinvoice`,
    body
  );

  return data;
}

async function sendDebtCollectionInvoice({ body }) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/invoice/send_to_debt_collection/`,
    body
  );

  return data;
}

async function activateAutogiroForInvoice(invoiceActionId) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=autogiro`
  );

  return data;
}

async function inactivateAutogiroForInvoice(invoiceActionId) {
  const { data } = await axiosInstance.delete(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=autogiro`
  );

  return data;
}

async function convertPigelloProductsToBillecta({ products, companyId }) {
  try {
    const productIds = products?.map((p) => p.id);

    const { data: res } = await axiosInstance.patch(
      checkTrailingUrlSlash(`${constants.ENSURE_PRODUCT_IDS_URL}${companyId}`),
      {
        ids: productIds,
      }
    );

    const mapped = productIds.map((id) => res[id]);

    return mapped;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function convertPigelloCostCentersToBillecta({ costCenters, companyId }) {
  try {
    const costCenterIds = costCenters?.map((p) => p.id);

    const { data: res } = await axiosInstance.patch(
      checkTrailingUrlSlash(
        `${constants.ENSURE_COSTCENTER_IDS_URL}${companyId}`
      ),
      {
        ids: costCenterIds,
      }
    );

    const mapped = costCenterIds.map((id) => res[id]);

    return mapped;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function convertPigelloProjectsToBillecta({ projects, companyId }) {
  try {
    const projectIds = projects?.map((p) => p.id);

    const { data: res } = await axiosInstance.patch(
      checkTrailingUrlSlash(`${constants.ENSURE_PROJECT_IDS_URL}${companyId}`),
      {
        ids: projectIds,
      }
    );

    const mapped = projectIds.map((id) => res[id]);

    return mapped;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function convertBillectaDebtorToPigello({ DebtorPublicId }) {
  const debtorQuery = buildQueryString({
    billecta_object_ids: DebtorPublicId,
  });

  const { data: debtors } = await axiosInstance.get(
    `${debtorConstants.LIST_URL}?${debtorQuery}`
  );
  const debtor = debtors?.[0];

  const { data: tenant } = await axiosInstance.get(
    `${tenantConstants.GET_URL}${debtor.tenant.id}/`
  );

  return { debtor, tenant };
}

async function attestCreditor(creditorId) {
  await axiosInstance.post(`/accounting/gateways/invoice_bulk_attest/`, {
    CreditorPublicIds: [creditorId],
  });
}

export {
  generateInvoicePreview,
  downloadBillectaFile,
  getAllInvoices,
  getAllContractInvoices,
  generateInvoiceIdPreview,
  sendInvoice,
  cancelReminders,
  disputeInvoice,
  undisputeInvoice,
  getAllConnectedPayments,
  getAllUnconnectedPayments,
  getSingleInvoice,
  attestInvoice,
  mergeInvoices,
  deleteInvoice,
  pauseInvoice,
  resumeInvoice,
  creditInvoice,
  createCreditInvoice,
  registerInvoicePayment,
  matchPaymentsToInvoice,
  sendManualReminderInvoice,
  deletePayment,
  getReminderInvoice,
  convertPigelloProductsToBillecta,
  convertPigelloProjectsToBillecta,
  convertPigelloCostCentersToBillecta,
  activateAutogiroForInvoice,
  inactivateAutogiroForInvoice,
  sendDebtCollectionInvoice,
  getDebtCollectionInfo,
  cancelDebtCollection,
  postponeDebtCollection,
  registerDebtHandlingPaymnet,
  convertBillectaDebtorToPigello,
  attestCreditor,
};
