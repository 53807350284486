import * as React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { durationParse } from "../../Displays/utils";

import {
  useNotificationsPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/notificationsDisplay";

import { redirectMethodFromKind } from "../../../store/utils";

import { markAsRead } from "../../../store/notificationsDisplay";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  const { push } = useHistory();

  const dispatch = useDispatch();

  // const exportParser = {
  //   state: (data) => data.state_display,
  //   category: (data) => data.category_display,
  //   notify_interval: (data) => durationParse(data.notify_interval),
  //   renew_interval: (data) => durationParse(data.renew_interval),
  // };

  const onNotificationClicked = (original) => {
    const readByMe = original?.read_by_me;
    if (readByMe === false) {
      dispatch(markAsRead([original.id]));
    }

    const specificDetailUrl = redirectMethodFromKind(original.content_type);

    if (!specificDetailUrl) return;

    push(specificDetailUrl({ id: original.object_id }));
  };

  const columns = React.useMemo(() => columnDefs, []);

  const badges = {};
  const filters = {};

  return (
    <>
      <BaseTable
        tableId={tableId || "notifications_center_full_table"}
        title={"Notiser"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked: (original) => onNotificationClicked(original),
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
};
