import moment from "moment";
import * as React from "react";
import Badge, { BADGE_TYPES } from "../../Badge/Badge";
import { toMoneyString } from "../../utils/stringUtils";
export const SENT_INVOICES_COLS = [
  {
    Header: "Avinummer",
    accessor: "invoice_number",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
  {
    Header: "Skickades",
    accessor: "sent_time",
    Cell: ({ value }) => {
      return (
        <div>{value ? moment(value).format("YYYY-MM-DD HH:mm") : "-"}</div>
      );
    },
  },

  {
    Header: "Öppnades",
    accessor: "read_time",
    Cell: ({ value }) => {
      return (
        <div>{value ? moment(value).format("YYYY-MM-DD HH:mm") : "-"}</div>
      );
    },
  },
  {
    Header: "Motpart",
    accessor: "debtor.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Leveransmetod",
    accessor: "delivery_method_display",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
  {
    Header: "Förfallodatum",
    accessor: "due_date",
    Cell: ({ value }) => {
      return <div>{value ? moment(value).format("YYYY-MM-DD ") : "-"}</div>;
    },
  },
  {
    Header: "Betalad",
    accessor: "fully_payed",
    Cell: ({ value }) => {
      return (
        <Badge type={value ? BADGE_TYPES.GREEN : BADGE_TYPES.RED}>
          {value ? "Ja" : "Nej"}
        </Badge>
      );
    },
  },
  {
    Header: "Period från",
    accessor: "period_start",
    Cell: ({ value }) => {
      return <div>{value ? moment(value).format("YYYY-MM-DD ") : "-"}</div>;
    },
  },
  {
    Header: "Period till",
    accessor: "period_end",
    Cell: ({ value }) => {
      return <div>{value ? moment(value).format("YYYY-MM-DD ") : "-"}</div>;
    },
  },
];

export const FAILED_INVOICE_DELIVERIES_COLS = [
  {
    Header: "Misslyckades",
    accessor: "delivery_fail_time",
    Cell: ({ value }) => {
      return (
        <div>{value ? moment(value).format("YYYY-MM-DD HH:mm") : "-"}</div>
      );
    },
  },
  {
    Header: "Avinummer",
    accessor: "invoice_number",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
  {
    Header: "Motpart",
    accessor: "debtor.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Leveransmetod",
    accessor: "delivery_method_display",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
  {
    Header: "Förfaller",
    accessor: "due_date",
    Cell: ({ value }) => {
      return <div>{value ? moment(value).format("YYYY-MM-DD ") : "-"}</div>;
    },
  },
];

export const FAILED_AUTOGIRO_COLS = [
  {
    Header: "Misslyckades",
    accessor: "autogiro_failed_time",
    Cell: ({ value }) => {
      return (
        <div>{value ? moment(value).format("YYYY-MM-DD HH:mm") : "-"}</div>
      );
    },
  },
  {
    Header: "Avinummer",
    accessor: "invoice_number",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },

  {
    Header: "Försöker igen",
    accessor: "autogiro_retry_date",
    Cell: ({ value }) => {
      return <div>{value ? moment(value).format("YYYY-MM-DD") : "-"}</div>;
    },
  },
  {
    Header: "Förfallodatum",
    accessor: "due_date",
    Cell: ({ value }) => {
      return <div>{value ? moment(value).format("YYYY-MM-DD ") : "-"}</div>;
    },
  },
  {
    Header: "Betalad",
    accessor: "fully_payed",
    Cell: ({ value }) => {
      return (
        <Badge type={value ? BADGE_TYPES.GREEN : BADGE_TYPES.RED}>
          {value ? "Ja" : "Nej"}
        </Badge>
      );
    },
  },
];

export const SENT_REMINDERS_COLS = [
  {
    Header: "Skickades",
    accessor: "sent_time",
    Cell: ({ value }) => {
      return (
        <div>{value ? moment(value).format("YYYY-MM-DD HH:mm") : "-"}</div>
      );
    },
  },
  {
    Header: "Förseningsavgift",
    accessor: "fee_value",
    Cell: ({ value }) => {
      return <div className="text-right">{toMoneyString(value, true, 2)}</div>;
    },
  },
  {
    Header: "Förfallodatum",
    accessor: "due_date",
    Cell: ({ value }) => {
      return <div>{moment(value).format("YYYY-MM-DD ")}</div>;
    },
  },
  {
    Header: "Motpart",
    accessor: "debtor.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Betalad",
    accessor: "fully_payed",
    Cell: ({ value }) => {
      return (
        <Badge type={value ? BADGE_TYPES.GREEN : BADGE_TYPES.RED}>
          {value ? "Ja" : "Nej"}
        </Badge>
      );
    },
  },
];

export const FAILED_REMINDER_DELIVERIES_COLS = [
  {
    Header: "Misslyckades",
    accessor: "delivery_fail_time",
    Cell: ({ value }) => {
      return (
        <div>{value ? moment(value).format("YYYY-MM-DD HH:mm") : "-"}</div>
      );
    },
  },
  {
    Header: "Förseningsavgift",
    accessor: "fee_value",
    Cell: ({ value }) => {
      return <div>{toMoneyString(value, true, 2)}</div>;
    },
  },
  {
    Header: "Förfallodatum",
    accessor: "due_date",
    Cell: ({ value }) => {
      return <div>{value ? moment(value).format("YYYY-MM-DD ") : "-"}</div>;
    },
  },
  {
    Header: "Motpart",
    accessor: "debtor.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
];

export const PAYMENTS_COLS = [
  {
    Header: "Betalningsinformation",
    columns: [
      {
        Header: "Mot faktura",
        accessor: "invoice.str_representation",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Typ",
        accessor: "kind_display",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Datum",
        accessor: "payment_date",
        Cell: ({ value }) => {
          return <div>{value ? moment(value).format("YYYY-MM-DD") : "-"}</div>;
        },
      },
      {
        Header: "Referens",
        accessor: "reference_text",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Betalsätt",
        accessor: "payment_mean_code",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Summa",
        accessor: "value",
        Cell: ({ value }) => {
          return (
            <div className="text-right">{toMoneyString(value, true, 2)}</div>
          );
        },
      },
      {
        Header: "OCR",
        accessor: "ocr_number",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
    ],
  },

  {
    Header: "Avsändarinformation",
    columns: [
      {
        Header: "Avsändare",
        accessor: "debtor.str_representation",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Namn",
        accessor: "age",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Adress",
        accessor: "visits",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Bankgiro",
        accessor: "status",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Person/Orgnr",
        accessor: "legal_id",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
    ],
  },
];

export const SENT_DEBT_COLLECTION_COLS = [
  {
    Header: "Skapades",
    accessor: "created_at",
    Cell: ({ value }) => {
      return <div>{moment(value).format("YYYY-MM-DD")}</div>;
    },
  },
  {
    Header: "Kravavgift",
    accessor: "fee_value",
    Cell: ({ value }) => {
      return <div>{toMoneyString(value, true, 2)}</div>;
    },
  },
  {
    Header: "Upplupen ränta",
    accessor: "interest_value",
    Cell: ({ value }) => {
      return <div>{toMoneyString(value, true, 2)}</div>;
    },
  },
  {
    Header: "Leveransmetod",
    accessor: "delivery_method_display",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
  {
    Header: "Förfallodatum",
    accessor: "due_date",
    Cell: ({ value }) => {
      return <div>{moment(value).format("YYYY-MM-DD ")}</div>;
    },
  },
  {
    Header: "Betalad",
    accessor: "fully_payed",
    Cell: ({ value }) => {
      return (
        <Badge type={value ? BADGE_TYPES.GREEN : BADGE_TYPES.RED}>
          {value ? "Ja" : "Nej"}
        </Badge>
      );
    },
  },
];
