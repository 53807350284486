const defs = [
  {
    title: "Behörighet",
    key: "PERMISSION",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
    ],
  },
  {
    title: "Integrationer",
    key: "INTEGRATION",
    visited: false,
    hasError: false,
    fieldKeys: [
      "apptus_id",
      "parakey_id",
    ],
  },
  {
    title: "Objekt",
    key: "OBJECT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "apartments",
      "industrial_premises",
      "common_areas"
    ],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
