const defs = [
  {
    title: "Parkeringsområde",
    key: "PARKINGLOT",
    visited: true,
    hasError: false,
    fieldKeys: ["title", "specification", "parking_zone", "realestates"],
  },

  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
