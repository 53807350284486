import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/SFTPCatalogue";

export default function SFTPCatalogueTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  triggerManual,
}) {
  const columns = React.useMemo(
    () => columnDefs({ triggerManual }),
    [persistantQuery]
  );

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "sftp_catalogue_full_table"}
      title={"SFTP-jobb"}
      onRowClickedWithDetail={(obj) => {}}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
