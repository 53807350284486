import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";

export default function LocalTextAreaField({
  id,
  title,
  description,
  error,
  required,
  placeholder,
  onChange,
  value,
}) {
  return (
    <div>
      <label
        htmlFor={id || title}
        className="text-sm flex items-center font-medium text-gray-900 "
      >
        {error && (
          <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
        )}
        {title}
        {required ? "*" : ""}
      </label>
      {description && (
        <p
          id={`${id}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {error && (
        <div className="text-xs mb-1 font-normal text-red-600 ">{error}</div>
      )}
      <textarea
        id={id || title}
        rows="4"
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        className="block bg-white p-2.5 w-full text-sm text-gray-900 rounded border border-slate-300 focus:ring-blue-500 focus:border-blue-500   "
        placeholder={placeholder || title}
      ></textarea>
    </div>
  );
}
