import * as React from "react";

// style, design

import { constants } from "../../../../../store/reportErrandSettings";

import { Select, TextInput } from "../../../Base/Fields";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method, id }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Typ av ärende</OverviewTitle>
          <OverviewSubtitle>
            Ange ett namn för ärendetypen och vilken typ av ärende inställningen
            gäller.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey={"title"}
        title="Typ av ärende"
        description={`Beskriv vilken typ av ärende som ingår i denna inställning. Detta är den titeln hyresgästen kommer se vid anmälan av fel. Exempel på typ kan vara "Fel i lägenhet"`}
        alwaysShowDescription
      />

      <Select
        menuPlacement="top"
        storeName={storeName}
        method={method}
        fieldKey={"category"}
        title="Kategori"
        description={`Koppla felanmälningstypen till en specifik kategori, lämnas den tom gäller kategorin "Övrig". Det innebär att felanmälningstypen kan gälla oavsett specifierad plats/objekt.`}
      />
    </>
  );
};
