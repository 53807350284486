import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";

import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../components/sharedStyles";
export default function MovedComponent() {
  const { push } = useHistory();

  // can't check .includes in a switch case :(
  const getActivePage = () => {
    if (window.location.pathname === "/rental-notifications/settings") {
      return [
        "Aviseringsinställningar",
        "Konfigcentret",
        "/configcenter/rental-notifications",
      ];
    } else if (window.location.pathname.includes("/invoicing-integrations")) {
      return [
        "Bolag & BRF integrationer",
        "Konfigcentret",
        "/configcenter/integrations",
      ];
    }
    else if (window.location.pathname === "/tenants/tenant-portal") {
        return [
          "Mina sidor",
          "Konfigcentret",
          "/configcenter/tenant-portal",
        ];
      }
  };

  const [name, destination, link] = getActivePage();

  return (
    <DetailInnerWrapper style={{ paddingBottom: 200, alignItems: "center", width: "100%"}}>
      <DetailPageBox style={{width: "100%"}}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Flyttad Modul</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <>
          <InnerBox style={{ height: "200px", display: "flex", justifyContent: "center"}}>

              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small style={{alignSelf: "center"}}>
                  {name} har flyttats till {destination}
                </OverviewTitle>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrimaryButton
                    extraStyle={{ marginTop: 50 , marginBottom: 12}}
                    title={`Gå till ${name}`}
                    clicked={() => push(link)}
                  />
                </div>
              </OverviewTitleWithSubtitleWrapper>

          </InnerBox>
        </>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
