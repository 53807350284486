import * as React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import svLocale from "@fullcalendar/core/locales/sv";
import moment from "moment";
import { DetailPageBox } from "../../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../OverviewInfo/styles";
import { PrimaryButton, TextButton } from "../../../Forms/Base/Buttons";
import { useFilteredCalendars } from "../../../../store/calendar";
import { buildQueryString } from "../../../../store/base";
import {
  useFilteredCalendarEvents,
  constants as eventConstants,
} from "../../../../store/calendarEvents";
import { useFilteredOutlookCalendarEvents } from "../../../../store/outlookCalendarEvents";
import CalendarEventForm from "../../../Forms/CalendarEvent/ChapterForm/ModalForm";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as SC from "./styles";
import CalendarForm from "../../../Forms/Calendar/ChapterForm/ModalForm";
import EventInfo from "../../../Calendar/EventInfo";
import StandardModal from "../../../Modals/StandardModal";
import DeleteModal from "../../../Forms/Delete/DeleteModal";

export default function MyWorkdayCalendar() {
  const userId = useSelector((state) => state.app.userId);
  const myCalendarsQuery = buildQueryString({
    owner: userId || null,
  });
  const [allCalendars, allCalendarsLoading] =
    useFilteredCalendars(myCalendarsQuery);
  const [addCalendarEventOpen, setAddCalendarEventOpen] = React.useState(false);
  const [editCalendarEventOpen, setEditCalendarEventOpen] =
    React.useState(false);
  const [initialCalendarData, setInitialCalenderData] = React.useState({});
  const { push } = useHistory();
  const [addCalendarOpen, setAddCalendarOpen] = React.useState(false);
  const [viewingEvent, setViewingEvent] = React.useState(undefined);
  const [deletingEvent, setDeletingEvent] = React.useState(false);

  let outlookCalendars = [];
  let normalCalendars = [];
  const todayStart = moment().startOf("day").format();
  const todayEnd = moment().endOf("day").format();

  (allCalendars || []).forEach((c) => {
    if (c.is_outlook_calendar) {
      outlookCalendars.push(c);
    } else {
      normalCalendars.push(c);
    }
  });

  const eventQueryString = normalCalendars?.length
    ? buildQueryString({
        start__gte: todayStart,
        end__lte: todayEnd,
        calendar__in: normalCalendars.map((c) => c.id),
      })
    : null;

  const outlookEventQueryString = outlookCalendars?.length
    ? buildQueryString({
        calendar_ids: outlookCalendars.map((c) => c.id),
        start_date: todayStart,
        end_date: todayEnd,
      })
    : null;

  const [events, eventsLoading] = useFilteredCalendarEvents(eventQueryString);
  const [outlookEvents, outlookEventsLoading] =
    useFilteredOutlookCalendarEvents(outlookEventQueryString);

  const handleDateClicked = (date) => {
    setInitialCalenderData({
      start: moment(date.dateStr).format("YYYY-MM-DD HH:mm"),
      end: moment(date.dateStr).add({ hours: 1 }).format("YYYY-MM-DD HH:mm"),
    });
    setAddCalendarEventOpen(true);
  };

  const handleEditEvent = (event) => {
    const id = event?.event?.id;

    const matchedEvent = events?.find((e) => e.id == id);

    setEditCalendarEventOpen(matchedEvent);
  };

  const handleEventClicked = (event) => {
    const id = event?.event?.id;
    if (!id) {
      return;
    }

    const matchedEvent = (events ?? [])
      .concat(outlookEvents ?? [])
      .find((e) => `${e.id}` === id);
    if (!matchedEvent) {
      return;
    }

    setViewingEvent(matchedEvent);
  };

  const getCalendarColor = (calendarId) => {
    return allCalendars?.find((c) => c.id === calendarId)?.color_code;
  };

  const formatEvents = (events, isOutlookEvent) => {
    return events.map((e) => {
      return {
        id: e.id,
        title: e.title,
        start: e.start,
        end: e.end,
        allDay: false,
        editable: !isOutlookEvent,
        backgroundColor: getCalendarColor(e.calendar?.id),
      };
    });
  };

  const isOutlookEvent = (event) => {
    return outlookCalendars.find((c) => c.id === event?.calendar?.id);
  };

  const scrollTime = `${moment().hours() - 1}:00:00`;

  const cleanedEvents = formatEvents(events ?? []).concat(
    formatEvents(outlookEvents ?? [], true)
  );

  return (
    <>
      <CalendarEventForm
        method={"POST"}
        isOpen={addCalendarEventOpen}
        instance={initialCalendarData}
        onCheckout={() => {
          setAddCalendarEventOpen(false);
          setInitialCalenderData({});
        }}
      />

      <CalendarEventForm
        method={"PATCH"}
        isOpen={!!editCalendarEventOpen}
        instance={editCalendarEventOpen}
        id={editCalendarEventOpen?.id}
        onCheckout={() => setEditCalendarEventOpen(false)}
      />

      <CalendarForm
        method={"POST"}
        isOpen={addCalendarOpen}
        onCheckout={() => {
          setAddCalendarOpen(false);
        }}
      />

      <DeleteModal
        isOpen={!!deletingEvent}
        instance={deletingEvent}
        constants={eventConstants}
        closeFunction={() => {
          setDeletingEvent(undefined);
        }}
      />

      <StandardModal
        isOpen={!!viewingEvent}
        actionBarCancelTitle={"Stäng"}
        closeFunction={() => setViewingEvent(false)}
        title={viewingEvent?.title}
        withActionBar={true}
        renderCustomActionBar={
          viewingEvent && !isOutlookEvent(viewingEvent)
            ? () => {
                return (
                  <>
                    <TextButton
                      title={"Radera händelse"}
                      red
                      extraStyle={{ marginRight: "auto" }}
                      clicked={() => {
                        setDeletingEvent(viewingEvent);
                        setViewingEvent(false);
                      }}
                    />
                    <PrimaryButton
                      title={"Uppdatera händelse"}
                      clicked={() => {
                        handleEditEvent(viewingEvent);
                        setViewingEvent(false);
                      }}
                    />
                    <TextButton
                      extraStyle={{ marginLeft: 12 }}
                      title={"Stäng"}
                      clicked={() => {
                        setViewingEvent(false);
                      }}
                    />
                  </>
                );
              }
            : undefined
        }
      >
        {!!viewingEvent && <EventInfo instance={viewingEvent} />}
      </StandardModal>

      <DetailPageBox style={{ marginTop: 0, minWidth: 350, borderRadius: 8 }}>
        {allCalendars?.length === 0 && !allCalendarsLoading && (
          <>
            <SC.NoCalendarsWrapper>
              <SC.NoCalendarsBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Ingen kalender tillagd</OverviewTitle>
                    <OverviewSubtitle>
                      Lägg till en kalender för att kunna planera in händelser
                      direkt i Pigello kopplat till avtal, objekt, hyresgäster
                      osv.
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <TextButton
                  title="Lägg till kalender"
                  iconType="add"
                  iconPlacement="right"
                  clicked={() => setAddCalendarOpen(true)}
                />
              </SC.NoCalendarsBox>
            </SC.NoCalendarsWrapper>
          </>
        )}
        <OverviewTitleWrapper>
          <OverviewTitle small>Planerat idag</OverviewTitle>

          <TextButton
            title="Lägg till"
            clicked={() => setAddCalendarEventOpen(true)}
            extraStyle={{ marginRight: 0 }}
            iconType="add"
            iconPlacement="right"
          />
        </OverviewTitleWrapper>
        <FullCalendar
          contentHeight={450}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGrid"
          allDaySlot={false}
          nowIndicator
          events={cleanedEvents}
          headerToolbar={false}
          showNonCurrentDates={false}
          slotMinTime={"06:00:00"}
          slotMaxTime={"22:00:00"}
          scrollTime={scrollTime}
          buttonText={{
            today: "Idag",
            month: "Månad",
            week: "Vecka",
            day: "Dag",
            list: "Lista",
          }}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            hour: "2-digit",
            meridiem: false,
            hour12: false,
          }}
          eventClick={(e) => handleEventClicked(e)}
          dateClick={handleDateClicked}
          eventStartEditable={true}
          eventResizableFromStart={true}
          eventDurationEditable={true}
          droppable={true}
          editable={true}
          locale={svLocale}
        />

        <TextButton
          title="Gå till kalender"
          clicked={() => push("/calendar")}
          extraStyle={{ marginTop: 12 }}
          iconType="arrow"
          iconPlacement="right"
        />
      </DetailPageBox>
    </>
  );
}
