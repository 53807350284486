import * as React from "react";
import { useDispatch } from "react-redux";

import {
  destroyPatchForm,
  constants,
  update,
  useLeaseContractForm,
} from "../../../../store/leaseContracts";
import { updateActiveFormInstance } from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import TableSelectField from "../../Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import { cloneDeep } from "lodash";

export default function UpdateLeaseContractLandlordModalForm({
  id,
  onCheckout,
  isOpen,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useLeaseContractForm(method, id));

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, [isOpen]);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    delete dataClone.editabledoc;

    return dataClone;
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        preProcess,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title="Uppdatera hyresvärd på avtal"
      onAccept={onSubmit}
      acceptTitle="Spara"
      closeFunction={onDone}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Hyresgäster på avtal</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="landlord"
          title={"Hyresvärd"}
          placeholder="Välj hyresvärd..."
          TableComponent={CompanyTable}
        />
      </div>
    </Modal>
  );
}
