export default [
  {
    title: "Pris",
    key: "PRICE",
    visited: true,
    hasError: false,
    fieldKeys: [
      "from_date",
      "value",
      "realestate"
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["measure_type"],
  },
];
