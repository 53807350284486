import { cloneDeep } from "lodash";
import baseReducer from "../../base/store/reducer";
import constants from "./constants";

const INITIAL_STATE = {
  all: {},
  allCopy: {},
  inProgress: [],
  paginations: {},
  paginationsCopy: {},
  filtered: {},
  filteredCopy: {},
  backgroundTasks: {},
  hasFetchedAll: false,
  forms: {},
  formInstance: {},
  formErrors: {},
  ads: [], // array of objs
  defaultSettings: {
    date_publish: null,
    access_date: null,
    pilot_mode: null,
    is_paused: false,
    prior_access: false,
    prioritize_customers: false,
    requirement: null,
    dump_in_pipe: null,
    description: "",
    publish_pigello: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.ADD_PARKING_SPOT: {
      return handleAddParkingSpot(newState, payload);
    }

    case constants.ADD_MULTIPLE_PARKING_SPOTS: {
      return handleAddMultipleParkingSpots(newState, payload);
    }

    case constants.REMOVE_PARKING_SPOT: {
      return handleRemoveParkingSpot(newState, payload);
    }

    case constants.SET_ADS: {
      return { ...newState, ads: payload.newAds };
    }

    case constants.SET_DEFAULT_SETTINGS: {
      return { ...newState, defaultSettings: payload.settings };
    }

    default:
      return newState;
  }
};

const handleAddParkingSpot = (state, payload) => {
  const { parkingSpot } = payload;
  const stateClone = cloneDeep(state);

  const { ads } = stateClone;

  ads.push({
    ...parkingSpot,
  });

  return stateClone;
};

const handleAddMultipleParkingSpots = (state, payload) => {
  const { parkingSpots } = payload;

  const stateClone = cloneDeep(state);

  const { ads } = stateClone;

  parkingSpots.forEach((a) => {
    ads.push({
      ...a,
    });
  });

  return stateClone;
};

const handleRemoveParkingSpot = (state, payload) => {
  const { parkingSpot } = payload;
  const stateClone = cloneDeep(state);

  const { ads } = stateClone;

  const newAds = ads.filter((i) => i.id !== parkingSpot.id);
  stateClone.ads = newAds;
  return stateClone;
};
