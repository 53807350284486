import * as React from "react";
import moment from "moment";
import { useParams, useHistory } from "react-router";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import { buildQueryString, useAllPermissionCheck } from "../../../store/base";

import { createUrl as keyPermissionCreateUrl } from "../../../store/keypermission";
import { createUrl as keyCreateUrl } from "../../../store/key";
import {
  createUrl as keyDeviceCreateUrl,
  useFilteredKeyDevices,
} from "../../../store/keydevice";

import KeyTable from "src/components/Tables/Keys/FullTable";
import KeyPermissionTable from "../../../components/Tables/KeyPermissions/FullTable";
import KeyDeviceTable from "../../../components/Tables/KeyDevices/FullTable";

import { TextButton } from "../../../components/Forms/Base/Buttons";
import StandardModal from "../../../components/Modals/StandardModal";
import { useApartment } from "../../../store/apartments";
import { retrieveAccesses } from "../../../store/parakey";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";

export default function ApartmentKeys() {
  const { push } = useHistory();
  const { apartmentId } = useParams();

  const canParakey = useAllPermissionCheck([
    "allow_parakey",
    "view_can_parakey",
  ]);
  const canAddKey = useAllPermissionCheck(["allow_keys", "add_can_keys"]);

  const [parakeyAccess, setParakeyAccesses] = React.useState(undefined);
  const [parakeyKeyDevices, parakeyKeyDevicesLoading] = useFilteredKeyDevices(
    buildQueryString({ apartment: apartmentId, parakey_id__isnull: false })
  );

  const [selectPermissionModalOpen, setSelectPermissionModalOpen] =
    React.useState(false);
  const [selectedPermission, setSelectedPermission] = React.useState(undefined);
  const [selectedTenant, setSelectedTenant] = React.useState(undefined);

  const keyQuery = { apartments: apartmentId };

  const keyPermissionQuery = {
    apartments: apartmentId,
  };

  const keyDeviceQuery = { apartment: apartmentId };

  const [apartment, apartmentLoading] = useApartment(apartmentId);
  const allTenantIds =
    (!!apartment?.tenant ? [apartment?.tenant?.id] : []) +
    (!!apartment?.bi_tenants ? apartment.bi_tenants.map((b) => b.id) : []);

  const continueToKeyCreate = () => {
    setSelectPermissionModalOpen(false);

    if (selectedTenant && selectedPermission) {
      push(
        keyCreateUrl() +
          `/user-keypermission/${selectedTenant.user.id}/${selectedPermission.id}`
      );
    } else if (selectedTenant) {
      push(keyCreateUrl() + `/user/${selectedTenant.user.id}`);
    } else if (selectedPermission) {
      push(keyCreateUrl() + `/keypermission/${selectedPermission.id}`);
    } else {
      push(keyCreateUrl());
    }
  };

  const loadParakeyPermission = async () => {
    const doorIds = parakeyKeyDevices.map((i) => i.parakey_id);
    if (!doorIds.length) {
      setParakeyAccesses([]);
    } else {
      const data = await retrieveAccesses(doorIds);
      setParakeyAccesses(data);
    }
  };

  React.useEffect(() => {
    if (
      parakeyKeyDevices != null &&
      parakeyKeyDevices?.length &&
      parakeyAccess == null
    ) {
      loadParakeyPermission();
    }
  }, [parakeyKeyDevices]);

  return (
    <>
      <StandardModal
        isOpen={selectPermissionModalOpen}
        saveFunction={continueToKeyCreate}
        closeFunction={() => setSelectPermissionModalOpen(false)}
        withActionBar
        title="Välj nyckelbehörighet"
        actionBarAcceptTitle={"Gå vidare"}
        actionBarCancelTitle={"Avbryt"}
      >
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={KeyPermissionTable}
            persistantQuery={keyPermissionQuery}
            placeholder="Välj nyckelbehörighet..."
            title="Nyckelbehörighet"
            value={selectedPermission}
            onChange={(obj) => setSelectedPermission(obj)}
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={TenantTable}
            persistantQuery={{ id__in: allTenantIds }}
            placeholder="Välj hyresgäst..."
            title="Hyresgäst"
            value={selectedTenant}
            onChange={(obj) => setSelectedTenant(obj)}
          />
        </div>
      </StandardModal>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              minWidth: "58%",
              alignSelf: "flex-start",
              marginRight: "8px",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Nycklar</OverviewTitle>
                {canAddKey && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => setSelectPermissionModalOpen(true)}
                    />
                  </div>
                )}
              </OverviewTitleWrapper>
              <KeyTable persistantQuery={keyQuery} isBare ignoreLocalStorage />
            </DetailPageBox>

            {canParakey && (
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small>Parakey-kopplade Nycklar</OverviewTitle>
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => setSelectPermissionModalOpen(true)}
                    />
                  </div>
                </OverviewTitleWrapper>
                <KeyTable
                  persistantQueryString={{
                    parakey_permission_ids: !parakeyAccess
                      ? []
                      : parakeyAccess.map((item) => item.id),
                  }}
                  isBare
                  ignoreLocalStorage
                />
              </DetailPageBox>
            )}
          </div>
          <div style={{ minWidth: "39%", alignSelf: "flex-start" }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Nyckelbehörigheter</OverviewTitle>
                {canAddKey && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() =>
                        push(
                          keyPermissionCreateUrl() + `/apartment/${apartmentId}`
                        )
                      }
                    />
                  </div>
                )}
              </OverviewTitleWrapper>
              <KeyPermissionTable
                persistantQuery={keyPermissionQuery}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
            {canParakey && (
              <DetailPageBox>
                <OverviewTitleWrapper>
                  <OverviewTitle small>
                    Parakey-styrda Behörigheter
                  </OverviewTitle>
                </OverviewTitleWrapper>
                <KeyPermissionTable
                  persistantQuery={{
                    parakey_id__in: !parakeyAccess
                      ? []
                      : parakeyAccess.map((item) => item.id),
                  }}
                  isBare
                  ignoreLocalStorage
                />
              </DetailPageBox>
            )}
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Dörrar</OverviewTitle>
                {canAddKey && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() =>
                        push(keyDeviceCreateUrl() + `/apartment/${apartmentId}`)
                      }
                    />
                  </div>
                )}
              </OverviewTitleWrapper>
              <KeyDeviceTable
                persistantQuery={keyDeviceQuery}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
