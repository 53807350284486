import * as React from "react";

import FullTable from "../Tables/Users/FullTable";

export default ({ groupId }) => {
  const persistantQuery = {
    usergroup__id: groupId,
    user_type__in: [0, 1, 2],
  };

  return (
    <>
      <FullTable persistantQuery={persistantQuery} isBare ignoreLocalStorage />
    </>
  );
};
