export default [
  {
    title: "Instans av dimension",
    key: "CUSTOM_DIMENSION",
    visited: true,
    hasError: false,
    fieldKeys: ["dimension_entry_id", "description"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["dimension"],
  },
];
