import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PopupButton } from "../../components/Forms/Base/Buttons";
import useActiveFilters from "../../hooks/useActiveFilters";
import { createUrl as contractCreate } from "../../store/serviceContracts/utils";

import { useServiceContractPaginationCount } from "../../store/serviceContracts/hooks/retrieve";
import ServiceContracts from "./overview/ServiceContracts";
import ServiceContractDrafts from "./overview/Drafts";
import ServicePartners from "./overview/ServicePartners";
import { usePermissionCheck } from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import ServicePartnerModalForm from "../../components/Forms/ServicePartners/ChapterForm/ModalForm";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
export default function ServiceContractOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  const canAdd = usePermissionCheck("add_can_contract");
  const [createServicePartnerOpen, setCreateServicePartnerOpen] =
    React.useState(false);
  usePermissionRedirect(["view_can_contract"]);

  const { filteredRealEstates } = useActiveFilters();

  const [activeCount] = useServiceContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: false,
  });
  const [draftCount] = useServiceContractPaginationCount({
    realEstates: filteredRealEstates,
    draft: true,
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ServiceContracts,
    },
    {
      path: "/drafts",
      label: `Utkast${draftCount ? ` (${draftCount})` : ""}`,
      component: ServiceContractDrafts,
    },
    {
      path: "/service-partners",
      label: "Servicepartners",
      component: ServicePartners,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal serviceavtal: {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  let actions = [];

  if (canAdd) {
    actions = [
      {
        name: "Serviceavtal",
        onClick: () => push(contractCreate({})),
      },
      {
        name: "Servicepartner",
        onClick: () => setCreateServicePartnerOpen(true),
      },
    ];
  }

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {actions?.length > 0 && (
        <PopupButton leftAligned title="Lägg till" actions={actions} />
      )}
    </div>
  );

  return (
    <>
      <ServicePartnerModalForm
        method="POST"
        onCheckout={() => setCreateServicePartnerOpen(false)}
        isOpen={createServicePartnerOpen}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Serviceavtal`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
