import Confirm from "./Confirm";

import General from "./General";
import Amortization from "./Amortization";
import Interest from "./Interest";
import Other from "./Other";

export default {
  GENERAL: General,
  INTEREST: Interest,
  AMORTIZATION: Amortization,
  OTHER: Other,
  CONFIRM: Confirm,
};
