export const detailUrl = ({ id }) => {
  return `/service-contracts/detail/${id}`;
};
export const overviewUrl = () => {
  return `/service-contracts`;
};
export const createUrl = () => {
  return `/service-contracts/create`;
};
export const createCostsUrl = ({ id }) => {
  return `/service-contracts/create/costs/${id}`;
};
export const editUrl = ({ id, existing }) => {
  return `/service-contracts/edit${existing ? "-existing" : ""}/${id}`;
};

export const cancelUrl = ({ id }) => {
  return `/service-contracts/cancel/${id}`;
};
