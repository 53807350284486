import * as React from "react";
import { useDispatch } from "react-redux";
import { connectRoomsToPremises } from "../../../store/blueprints/store/actions";

import Blueprint from "./Blueprint";
import ProcessBlueprint from "../Info/ProcessBlueprint";
import WaitingBlueprint from "../Info/WaitingBlueprint";
import ConnectModal from "../ConnectBlueprintModal/ConnectModal";
import { useFilteredIndustrialPremises } from "../../../store/industrialPremises";
import { buildQueryString } from "../../../store/base";
import { useFilteredApartments } from "../../../store/apartments";
import { useFilteredCommonAreas } from "../../../store/commonAreas";
import { useFilteredRooms } from "../../../store/rooms";

const BLUEPRINT_STATES = {
  PASSIVE: "PASSIVE",
  CREATING: "CREATING",
  EDITING: "EDITING",
};

export const BLUEPRINT_PREMISE_OPTIONS = {
  APARTMENT: "APARTMENT",
  INDUSTRIAL_PREMISES: "INDUSTRIAL_PREMISES",
  COMMON_AREA: "COMMON_AREA",
};

const premisesByRoom = (room, premises) => {
  const match = premises?.find((p) => {
    if (
      p.type === BLUEPRINT_PREMISE_OPTIONS.APARTMENT &&
      room.apartment?.id === p.id
    ) {
      return true;
    }
    if (
      p.type === BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES &&
      room.industrial_premises?.id === p.id
    ) {
      return true;
    }
    if (
      p.type === BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA &&
      room.common_area?.id === p.id
    ) {
      return true;
    }

    return false;
  });
  return match;
};

const getClearRooms = ({ rooms, selectedRoomsWithData, selectedPremises }) => {
  const originalRooms = rooms.filter((r) => {
    if (selectedPremises.type === BLUEPRINT_PREMISE_OPTIONS.APARTMENT) {
      return r.apartment?.id === selectedPremises?.id;
    } else if (
      selectedPremises.type === BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES
    ) {
      return r.industrial_premises?.id === selectedPremises?.id;
    } else {
      return r.common_area?.id === selectedPremises?.id;
    }
  });

  const clearRooms = originalRooms.filter(
    (r) => !selectedRoomsWithData?.map((sr) => sr.id).includes(r.id)
  );

  return clearRooms;
};

export default function Controller({ currentBlueprint }) {
  const dispatch = useDispatch();

  const [connectModalOpen, setConnectModalOpen] = React.useState(false);
  const [loadingConnect, setLoadingConnect] = React.useState(false);
  const [selectedPremises, setSelectedPremises] = React.useState(null);
  const [selectedRooms, setSelectedRooms] = React.useState([]);
  const [handleBlueprintState, setHandleBlueprintState] = React.useState(
    BLUEPRINT_STATES.PASSIVE
  );

  const roomsQ = buildQueryString({
    blueprint: currentBlueprint?.id || "-1",
  });

  const [rooms, roomsLoading] = useFilteredRooms(roomsQ);

  const apartmentQ = buildQueryString({
    id__in: rooms?.map((r) => r.apartment?.id)?.filter((i) => i) || [],
  });
  const [apartments, apartmentsLoading] = useFilteredApartments(apartmentQ);

  const indpQ = buildQueryString({
    id__in:
      rooms?.map((r) => r.industrial_premises?.id)?.filter((i) => i) || [],
  });
  const [industrialPremises, industrialPremisesLoading] =
    useFilteredIndustrialPremises(indpQ);

  const commonQ = buildQueryString({
    id__in: rooms?.map((r) => r.common_area?.id)?.filter((i) => i) || [],
  });
  const [commonAreas, commonAreasLoading] = useFilteredCommonAreas(commonQ);

  React.useEffect(() => {
    if (handleBlueprintState === BLUEPRINT_STATES.EDITING) {
      const roomsToHighlight = rooms
        ?.filter((r) => {
          if (selectedPremises.type === BLUEPRINT_PREMISE_OPTIONS.APARTMENT) {
            return r.apartment?.id === selectedPremises?.id;
          } else if (
            selectedPremises.type ===
            BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES
          ) {
            return r.industrial_premises?.id === selectedPremises?.id;
          } else {
            return r.common_area?.id === selectedPremises?.id;
          }
        })
        ?.map((r) => r.id);
      setSelectedRooms(roomsToHighlight);
    } else {
      setSelectedRooms([]);
    }
  }, [handleBlueprintState]);

  const premises = [
    ...(industrialPremises?.map((i) => ({
      ...i,
      type: BLUEPRINT_PREMISE_OPTIONS.INDUSTRIAL_PREMISES,
    })) || []),
    ...(apartments?.map((i) => ({
      ...i,
      type: BLUEPRINT_PREMISE_OPTIONS.APARTMENT,
    })) || []),
    ...(commonAreas?.map((i) => ({
      ...i,
      type: BLUEPRINT_PREMISE_OPTIONS.COMMON_AREA,
    })) || []),
  ];

  const selectedRoomsWithData = (rooms || [])?.filter((room) =>
    selectedRooms.includes(room.id)
  );

  const handleConnect = ({ selectedPremises, premisesType }) => {
    setLoadingConnect(true);
    dispatch(
      connectRoomsToPremises({
        premisesId: selectedPremises?.id,
        rooms: selectedRoomsWithData,
        premisesType,
        successCallback: () => {
          setLoadingConnect(false);

          setHandleBlueprintState(BLUEPRINT_STATES.PASSIVE);
          setSelectedPremises(null);
        },
      })
    );
  };

  const handleSaveEdit = () => {
    setLoadingConnect(true);
    dispatch(
      connectRoomsToPremises({
        premisesId: selectedPremises?.id,
        rooms: selectedRoomsWithData,
        premisesType: selectedPremises?.type,
        clearRooms: getClearRooms({
          rooms,
          selectedRoomsWithData,
          selectedPremises,
        }),
        successCallback: () => {
          setLoadingConnect(false);
          setHandleBlueprintState(BLUEPRINT_STATES.PASSIVE);
          setSelectedPremises(null);
        },
      })
    );
  };

  const toggleRooms = (room) => {
    let updatedRooms = [...selectedRooms];
    const isConnectedToPremises = premisesByRoom(room, premises);

    const index = updatedRooms.indexOf(room.id);
    if (index !== -1) {
      updatedRooms.splice(index, 1);
    } else if (!isConnectedToPremises) {
      updatedRooms.push(room.id);
    }

    setSelectedRooms(updatedRooms);
  };

  const toggleEditRooms = (room) => {
    let updatedRooms = [...selectedRooms];
    const curPremises = premisesByRoom(room, premises);

    if (updatedRooms.includes(room.id)) {
      updatedRooms = updatedRooms.filter((rId) => rId !== room.id);
      setSelectedRooms(updatedRooms);
    } else if (curPremises) {
      if (curPremises.id === selectedPremises?.id) {
        updatedRooms.push(room.id);
        setSelectedRooms(updatedRooms);
      }
    } else {
      updatedRooms.push(room.id);
      setSelectedRooms(updatedRooms);
    }
  };

  const selectPremises = (room) => {
    const curPremises = premisesByRoom(room, premises);
    if (curPremises) {
      if (selectedPremises?.id === curPremises.id) {
        setSelectedPremises(null);
      } else if (selectedPremises) {
        return;
      } else {
        setSelectedPremises(curPremises);
      }
    } else {
      setSelectedPremises(null);
    }
  };

  const handleRoomClicked = (room) => {
    switch (handleBlueprintState) {
      case BLUEPRINT_STATES.PASSIVE: {
        selectPremises(room);
        break;
      }
      case BLUEPRINT_STATES.CREATING: {
        toggleRooms(room);
        break;
      }
      case BLUEPRINT_STATES.EDITING: {
        toggleEditRooms(room);
        break;
      }
      default:
        return;
    }
  };

  const handleOutsideRoomClicked = () => {
    if (handleBlueprintState === BLUEPRINT_STATES.PASSIVE) {
      setSelectedPremises(null);
    }
  };

  const connect = (data) => {
    setConnectModalOpen(false);
    handleConnect(data);
  };

  const blueprintState = currentBlueprint.state;

  return (
    <>
      <ConnectModal
        isOpen={connectModalOpen}
        buildingId={currentBlueprint.building?.id}
        connect={connect}
        closeFunction={() => {
          setConnectModalOpen(false);
          setSelectedPremises(null);
        }}
        loading={loadingConnect}
      />

      {blueprintState === "waiting" ? (
        <WaitingBlueprint />
      ) : blueprintState === "process" ? (
        <ProcessBlueprint />
      ) : (
        <Blueprint
          blueprint={currentBlueprint}
          currentRooms={rooms || []}
          BLUEPRINT_STATES={BLUEPRINT_STATES}
          handleBlueprintState={handleBlueprintState}
          setHandleBlueprintState={setHandleBlueprintState}
          selectedPremises={selectedPremises}
          selectedRooms={selectedRooms}
          currentBuildingPremises={premises}
          handleRoomClicked={handleRoomClicked}
          handleOutsideRoomClicked={handleOutsideRoomClicked}
          openConnectModal={() => setConnectModalOpen(true)}
          saveEdit={() => handleSaveEdit()}
        />
      )}
    </>
  );
}
