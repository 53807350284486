import * as React from "react";

import {
  DatePicker,
  NumberInput,
  RadioGroup,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/loan";
import { updateActiveFormInstance, useFormInstanceField } from "../../../../../store/base";
import { useDispatch } from "react-redux";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const hasAdjustingInterest = useFormInstanceField({storeName, fieldKey: "use_adjusting_interest"})

  React.useEffect(() => {
    if (!hasAdjustingInterest) {
      dispatch(updateActiveFormInstance({
        storeName,
        data: {
          "adjusting_interest_adjust_from":null,
          "adjusting_interest_adjust_every_months":null,
        }
      }))
    } else {
      dispatch(updateActiveFormInstance({
        storeName,
        data: {
          "non_adjusting_interest_yearly_fee":null
        }
      }))
    }
  }, [hasAdjustingInterest])

  return (
    <>
      <NumberInput
      title="Fast räntedel"
      description="Om avgiften är inaktiv kommer ingen debitering att ske"
      storeName={storeName}
      method={method}
      fieldKey={"fixed_interest"}
      />
      <RadioGroup
        title="Använd rörlig ränta"
        description="Den rörliga delen av räntan läggs till på den angivna fasta räntedelen"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"use_adjusting_interest"}
        defaultValue={true}
        />
     {hasAdjustingInterest && <>
      <DatePicker
      title="Rörlig ränta justeras f.o.m"
      description="Från och med det här datumet justeras den rörliga delen av räntan, om inget anges används startdaumet för lånet"
      storeName={storeName}
      method={method}
      fieldKey={"adjusting_interest_adjust_from"}
      />
      <NumberInput
      title={"Räntejusteringsfrekvens (månader)"}
      fieldKey={"adjusting_interest_adjust_every_months"}
      storeName={storeName}
      method={method}
      description={"Ange exempelvis 3 för att indikera på att den rörliga räntas justeras var tredje månad (om startdatumet för justering är i januari resulterar detta i justering i början av varje kvartal)"}
      />
      </> }

      { !hasAdjustingInterest && <NumberInput
      title={"Årlig avgift för fast/ej rörlig ränta"}
      fieldKey={"non_adjusting_interest_yearly_fee"}
      storeName={storeName}
      method={method}
      /> }
    </>
  );
};
