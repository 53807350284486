import * as React from "react";

import { constants } from "../../../../../store/parkingLots";

import ParkingSpecificationNestedChildren from "../../../ParkingSpecification/NestedChildren";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import TableSelectFieldWithCreate from "src/components/Forms/Base/Fields/TableSelectFieldWithCreate";
import ParkingSpecificationsTable from "src/components/Tables/Parking/ParkingSpecifications/FullTable";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Namn på parkeringsområdet"
          storeName={constants.STORE_NAME}
          method={method}
          fieldKey="title"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj fastigheter..."
          title="Fastigheter området tillhör"
          method={method}
          fieldKey="realestates"
          isMany
          TableComponent={RealEstateTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={constants.STORE_NAME}
          TableComponent={ParkingSpecificationsTable}
          placeholder="Välj specifikation..."
          title="Specifikation och regler"
          method={method}
          fieldKey="specification"
        >
          {(parentPath) => (
            <ParkingSpecificationNestedChildren
              storeName={constants.STORE_NAME}
              method={method}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>
    </>
  );
};
