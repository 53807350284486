import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../../components/sharedStyles";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import { useCompanyPaginationCount } from "../../../store/companies/hooks/retrieve";

import {
  useVacancyBookingSetting,
  constants,
} from "../../../store/vacancyBookingSettings";
import VacancySettingOverview from "./vacancyBooking/Overview";
import VacancySettingUsedBy from "./vacancyBooking/UsedBy";

export default function VacancySettingDetail() {
  const { settingId } = useParams();
  const { path, url } = useRouteMatch();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const { replace } = useHistory();

  const [setting] = useVacancyBookingSetting(settingId);

  const usedByCompanyFilter = {
    vacancy_booking_config: settingId,
  };

  const [usedByCompaniesCount] = useCompanyPaginationCount({
    filters: usedByCompanyFilter,
  });

  const totalCount = usedByCompaniesCount || 0;

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: VacancySettingOverview,
    },
    {
      path: "/used-by",
      label: `Används av (${totalCount})`,
      component: VacancySettingUsedBy,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [
    {
      name: "Radera",
      isDelete: true,
      onClick: () => setDeleteOpen(true),
    },
  ];

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={setting}
        constants={constants}
        deletedCallback={() => replace("/configcenter/rental-notifications")}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Inställning ${setting?.str_representation || "Laddar..."}`}
          subPages={subPageLinks}
          breadCrumbs={[
            {
              url: "/configcenter/rental-notifications/vacancy-booking-setting",
              label: "Vakansbokningsinställningar",
            },
            {
              label: `Inställning ${
                setting?.str_representation || "Laddar..."
              }`,
            },
          ]}
          handleActions={handleActions}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
