import * as React from "react";
import { Redirect, useParams } from "react-router";

import { useBrfInvoicing } from "../../../store/invoicingBrf";
import { detailUrl } from "../../../store/invoicingBrf";

export default function InvoicingBrfRedirect() {
  const { id } = useParams();

  const [brfInvoicing] = useBrfInvoicing(id);

  const brfPremisesId = brfInvoicing?.brf_premis?.id;

  // wait for sufficient data to redirect
  if (!brfPremisesId) return null;

  return <Redirect to={detailUrl({ id: brfPremisesId })} />;
}
