export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGCONTRACT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGCONTRACT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGCONTRACT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGCONTRACT",
  DESTROY_FORM: "DESTROY_FORM_PARKINGCONTRACT",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGCONTRACT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGCONTRACT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGCONTRACT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGCONTRACT",
  RESET_STATE: "RESET_STATE_PARKINGCONTRACT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGCONTRACT",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PARKINGCONTRACT",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PARKINGCONTRACT",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGCONTRACT",

  LIST_URL: "/standard/parking/parkingcontract/list/",
  POST_URL: "/standard/parking/parkingcontract/",
  PATCH_URL: "/standard/parking/parkingcontract/",
  GET_URL: "/standard/parking/parkingcontract/",
  GENERATE_INDEX_URL:
    "/pdfgenerator/contract/indexation/index_summary/parkingcontract/",

  STORE_NAME: "parkingContracts",
};
