import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useTodoErrandStatistics } from "../../../store/overview";

import ReportErrandTable from "../../../components/Tables/ReportErrands/FullTableNewVersion";
import InspectionErrandTable from "../../../components/Tables/InspectionErrands/FullTable";
import RoundingErrandTable from "../../../components/Tables/RoundingErrands/FullTable";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const READABLE_TYPES = {
  no_performer: "Saknar utförare",
  not_accepted: "Har ej accepterats",
  long_pause: "Har varit pausat länge",
  delayed_start: "Försenad start",
  delayed_end: "Gått över planerad tid",
};

const READABLE_CONTENT_TYPES = {
  "errands.reporterrand": "Felanmälningar",
  "errands.inspectionerrand": "Besiktingar",
  "errands.roundingerrand": "Ronderingar",
};

const TABLE_MAP = {
  "errands.reporterrand": ReportErrandTable,
  "errands.inspectionerrand": InspectionErrandTable,
  "errands.roundingerrand": RoundingErrandTable,
};

export default function ErrandsTodo() {
  const [openTable, setOpenTable] = React.useState(null);
  const [rawErrandStats, errandLoading] = useTodoErrandStatistics();

  const filteredStatKeys = Object.keys(rawErrandStats?.data || {}).filter(
    (key) => rawErrandStats?.data?.[key]?.length > 0
  );

  const byContentType = (data) => {
    const contentTypes = [];

    data.forEach((d) => {
      if (contentTypes.includes(d.content_type)) return;

      contentTypes.push(d.content_type);
    });

    return contentTypes;
  };

  return (
    <DetailInnerWrapper>
      {filteredStatKeys?.length > 0 ? (
        <>
          {filteredStatKeys.map((key) => {
            const data = rawErrandStats?.data[key];

            const contentTypes = byContentType(data);

            return (
              <DetailPageBox key={key}>
                <OverviewTitleWrapper>
                  <OverviewTitle>
                    {READABLE_TYPES[key]} ({data?.length})
                  </OverviewTitle>
                </OverviewTitleWrapper>

                {contentTypes?.map((ct) => {
                  const contentTypeData = data.filter(
                    (d) => d.content_type === ct
                  );

                  const TableComp = TABLE_MAP[ct];

                  const ids = contentTypeData?.map((d) => d.id);

                  const persistantQuery = {
                    id__in: ids,
                  };

                  const open = openTable === `${key}${ct}`;

                  return (
                    <InnerBox style={{ marginBottom: 12 }} key={ct}>
                      <OverviewTitleWrapper
                        style={{ marginBottom: open ? 24 : 0 }}
                      >
                        <OverviewTitleWithSubtitleWrapper>
                          <OverviewTitle small>
                            {READABLE_CONTENT_TYPES[ct]} (
                            {contentTypeData?.length})
                          </OverviewTitle>
                          <OverviewSubtitle>
                            {!open
                              ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa ärenden`
                              : `Klicka på ett ärende för att komma till detaljsidan`}
                          </OverviewSubtitle>
                        </OverviewTitleWithSubtitleWrapper>

                        <PrimaryButton
                          title={!open ? "Visa detaljer" : "Stäng"}
                          clicked={
                            !open
                              ? () => setOpenTable(`${key}${ct}`)
                              : () => setOpenTable(null)
                          }
                        />
                      </OverviewTitleWrapper>

                      {open && (
                        <TableComp
                          {...{
                            persistantQuery,
                          }}
                        />
                      )}
                    </InnerBox>
                  );
                })}
              </DetailPageBox>
            );
          })}
        </>
      ) : (
        <DetailPageBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OverviewTitle style={{ padding: "48px 0 " }}>
            {errandLoading ? "Laddar..." : "Inget att hantera"}
          </OverviewTitle>
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
