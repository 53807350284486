import { uploadBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    const docPath = responseData?.image?.put;
    if (!docPath) return;

    const base64file = formInstance?.image?._tempData?.data;

    if (!base64file) return;

    await uploadBase64ToS3({
      signedUrl: docPath,
      base64: base64file,
    });

    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
