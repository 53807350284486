import * as React from "react";

// style, design
// import BaseTable from "src/components/Lists/Base/CompleteList/Table";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { detailUrl } from "../../../store/apartments";
import { constants } from "../../../store/apartmentCancellations";
import moment from "moment";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import useActiveFilters from "../../../hooks/useActiveFilters";

// const PERSISTANT_CATEGORY_KEY = "persistant_category_apartmentcancellations";

import { BADGE_TYPES } from "../../Badge/Badge";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function ApartmentCancellationsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  // const storeName = constants.STORE_NAME;
  const { filteredRealEstates } = useActiveFilters();

  const [startDate, setStartDate] = React.useState(
    moment({ year: moment().year(), month: 0, day: 1 })
  );
  const [endDate, setEndDate] = React.useState(
    moment({ year: moment().year(), month: 11, day: 31 })
  );

  const exportParser = {
    state: (data) => data.state_display,
  };

  const [usePersistantQuery, setUsePersistantQuery] =
    React.useState(persistantQuery);

  React.useEffect(() => {
    setUsePersistantQuery({
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      realestate_ids: filteredRealEstates,
    });
  }, [startDate, endDate, persistantQuery]);

  const columns = React.useMemo(() => columnDefs(), [usePersistantQuery]);

  const filters = {
    Lägenhetsnummer: {
      queryKey: "apartment_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },

    Objektsnummer: {
      queryKey: "premises_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Kategori: {
      queryKey: "category",
      type: "select",
      operators: OPERATOR_TEMPLATES.SELECT,
      choices: [
        {
          v: "1",
          d: "1 RoK",
        },
        {
          v: "1.5",
          d: "1.5 RoK",
        },
        {
          v: "2",
          d: "2 RoK",
        },
        {
          v: "2.5",
          d: "2.5 RoK",
        },
        {
          v: "3",
          d: "3 RoK",
        },
        {
          v: "3.5",
          d: "3.5 RoK",
        },
        {
          v: "4",
          d: "4 RoK",
        },
        {
          v: "4.5",
          d: "4.5 RoK",
        },
        {
          v: "5",
          d: "5 RoK",
        },
        {
          v: "5.5",
          d: "5.5 RoK",
        },
        {
          v: "6",
          d: "6 RoK",
        },
        {
          v: "6.5",
          d: "6.5 RoK",
        },
        {
          v: "other",
          d: "Annan",
        },
      ],
    },
    Adress: {
      queryKey: "address__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Fastighet: {
      queryKey: "realestate__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Byggnad: {
      queryKey: "building__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Area: {
      queryKey: "area",
      type: "number",
      operators: OPERATOR_TEMPLATES.NUMBER,
    },
    Hyresgäst: {
      queryKey: "tenant__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Hyresvärd: {
      queryKey: "landlord__str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <>
      <div style={{ display: "flex", maxWidth: "100%" }}>
        <div style={{ display: "flex" }}>
          <NonConnectedDatePicker
            value={startDate.format("YYYY-MM-DD")}
            onChange={(value) => {
              value != null && setStartDate(moment(value));
            }}
            label={"Från"}
            extraStyle={{ marginRight: 12 }}
          />
          <NonConnectedDatePicker
            value={endDate.format("YYYY-MM-DD")}
            onChange={(value) => {
              value != null && setEndDate(moment(value));
            }}
            label={"Till"}
          />
        </div>
      </div>

      <BaseTable
        onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
        title="Utflyttningar"
        tableId={tableId || "apartmentcancellations_full_table"}
        disableBadgeFilterCounts
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery: usePersistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
