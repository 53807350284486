import * as React from "react";

import { 
    Confirm, 
    Main
} from "./Chapters";

export default (key, method, calendars) => {
    switch (key) {
        case "MAIN":
            return <Main method={method} calendars={calendars} />;
        default:
            return <Confirm />;
    }
};