import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Checkbox, NumberInput, RadioGroup } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketRequirements";
import { useFormInstanceField } from "../../../../../store/base";
import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const isSenior = useFormInstanceField({
    storeName,
    fieldKey: "senior",
  });

  const isYouth = useFormInstanceField({
    storeName,
    fieldKey: "youth",
  });

  return (
    <>
      <FormAreaTitle>Speciallägenhet</FormAreaTitle>

      <FormAreaDescription>
        Ange om kravprofilen ska specificera en viss typ av lägenhet
      </FormAreaDescription>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Seniorboende</OverviewTitle>
        </OverviewTitleWrapper>

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="senior"
          storeName={storeName}
          method={method}
          title="Seniorlägenhet"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        {isSenior && (
          <NumberInput
            {...{ storeName, method }}
            fieldKey="senior_age"
            title="Lägsta ålder"
          />
        )}
      </InnerBox>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Ungdomsboende</OverviewTitle>
        </OverviewTitleWrapper>

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="youth"
          storeName={storeName}
          method={method}
          title="Ungdomslägenhet"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        {isYouth && (
          <NumberInput
            {...{ storeName, method }}
            fieldKey="youth_age"
            title="Maxålder"
          />
        )}
      </InnerBox>
      <DoubleFieldWrapper>
        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="student"
          storeName={storeName}
          method={method}
          title="Studentlägenhet"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />

        <RadioGroup
          extraStyles={{ flex: 1 }}
          fieldKey="benefit_apartment"
          storeName={storeName}
          method={method}
          title="Invsteringsstödslägenhet"
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
          defaultValue={false}
        />
      </DoubleFieldWrapper>
    </>
  );
};
