import styled from "styled-components";

export const CreatedDate = styled.div`
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  color: ${(p) => p.theme.colors.secondaryText};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Description = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  margin: 0 0 2px 0;
`;

export const Dates = styled.div`
  font-size: 12px;
  margin: 0;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;
