import { axiosInstance } from "../../base/store/axios";

async function getKivraSettings(creditorId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/kivra_setting/${creditorId}/`
  );

  return data;
}

async function updateKivraSettings(data) {
  const { data: response } = await axiosInstance.put(
    "accounting/gateways/kivra_setting/",
    data
  );

  return response;
}
async function deleteKivraSettings(data) {
  const { data: response } = await axiosInstance.delete(
    `accounting/gateways/kivra_setting/?id=${data.CreditorPublicId}`
  );

  return response;
}

async function getVismaSettings(creditorId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/visma_setting/${creditorId}/`
  );

  return data;
}

async function updateVismaSettings(data) {
  const { data: response } = await axiosInstance.put(
    "accounting/gateways/visma_setting/",
    data
  );

  return response;
}

async function getAutogiroSettings(creditorId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/autogiro_setting/${creditorId}/`
  );

  return data;
}

async function getPEAccountingSettings(creditorId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/pe_setting/${creditorId}/`
  );

  return data;
}

async function updatePEAccountingSettings(data) {
  const { data: response } = await axiosInstance.put(
    "accounting/gateways/pe_setting/",
    data
  );

  return response;
}

async function deletePEAccountingSettings(creditorId) {
  const { data } = await axiosInstance.delete(
    `accounting/gateways/pe_setting/${creditorId}/`
  );

  return data;
}

async function getFortnoxSettings(creditorId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/fortnox_setting/${creditorId}/`
  );

  return data;
}

async function updateFortnoxSettings(data) {
  const { data: response } = await axiosInstance.put(
    "accounting/gateways/fortnox_setting/",
    data
  );

  return response;
}
async function updateAutogiroSettings(data) {
  const { data: response } = await axiosInstance.put(
    "accounting/gateways/autogiro_setting/",
    data
  );

  return response;
}

async function deleteFortnoxSettings(creditorId) {
  const { data } = await axiosInstance.delete(
    `accounting/gateways/fortnox_setting/${creditorId}/`
  );

  return data;
}

async function getEInvoiceSettings(creditorId) {
  const { data } = await axiosInstance.get(
    `accounting/gateways/e_invoice_setting/${creditorId}/`
  );

  return data;
}

export {
  getKivraSettings,
  updateKivraSettings,
  deleteKivraSettings,
  getVismaSettings,
  getPEAccountingSettings,
  getAutogiroSettings,
  updatePEAccountingSettings,
  deletePEAccountingSettings,
  getFortnoxSettings,
  updateFortnoxSettings,
  deleteFortnoxSettings,
  updateAutogiroSettings,
  getEInvoiceSettings,
  updateVismaSettings,
};
