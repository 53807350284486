import * as React from "react";
import { useParams } from "react-router";
import BlueprintLayout from "../../../components/Blueprints/BlueprintLayout";
import { DetailInnerWrapper } from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useFilteredBlueprints } from "../../../store/blueprints";
import { useBuilding } from "../../../store/buildings";

export default function BuildingBlueprints() {
  const { buildingId } = useParams();
  const [building] = useBuilding(buildingId);
  const blueprintQuery = buildQueryString({
    building: building?.id,
  });
  const [blueprints, blueprintsLoading] = useFilteredBlueprints(blueprintQuery);

  return <BlueprintLayout building={building} blueprints={blueprints} />;
}
