import * as React from "react";
import moment from "moment";
import * as SC from "./styles";
import { InnerBox } from "../../../sharedStyles";
import theme from "../../../../theme";

const DATE_FORMAT = "YYYY-MM-DD HH:mm";

export default ({ editabledoc }) => {
  const hasScrive = editabledoc?.has_scrive;
  const parties = editabledoc?.parties || [];

  const renderPartyItems = (party) => {
    return (
      <>
        {party.delivery_method && (
          <SC.PartyItem>
            <SC.PartyItemTitle>
              Levereras med {party.delivery_method_display}
            </SC.PartyItemTitle>
          </SC.PartyItem>
        )}

        {!party.sign_time && (
          <SC.PartyItem>
            <SC.PartyItemTitle>
              {party.seen_time
                ? `Öppnade dokumentet ${moment(party.seen_time).format(
                    DATE_FORMAT
                  )}`
                : "Ej öppnat dokumentet"}
            </SC.PartyItemTitle>
          </SC.PartyItem>
        )}

        {party.rejected_time && !party.sign_time && (
          <SC.PartyItem>
            <SC.PartyItemTitle>
              Nekade dokumentet
              {moment(party.rejected_time).format(DATE_FORMAT)}{" "}
              {party.rejection_reason
                ? `med kommentaren "${party.rejection_reason}`
                : null}
            </SC.PartyItemTitle>
          </SC.PartyItem>
        )}

        {party.sign_time && (
          <SC.PartyItem>
            <SC.PartyItemTitle>
              Signerade dokumentet {moment(party.sign_time).format(DATE_FORMAT)}
            </SC.PartyItemTitle>
          </SC.PartyItem>
        )}
      </>
    );
  };

  if (!parties?.length)
    return "Parter saknas. Uppdatera dokumentet för att åtgärda detta.";

  return parties.map((party) => {
    const hasSigned = party.sign_time;
    const hasRejected = party.rejected_time;
    const hasSeen = party.seen_time;

    const statusStyle = {};

    if (hasRejected) statusStyle["borderColor"] = theme.colors.red;
    if (hasSigned) statusStyle["borderColor"] = theme.colors.green;
    if (hasSeen) statusStyle["borderColor"] = theme.colors.blue;

    return (
      <InnerBox style={{ marginBottom: "12px", ...statusStyle }}>
        <SC.PartyTitle>{party.str_representation}</SC.PartyTitle>
        <SC.PartyRole>Roll: {party.signatory_role_display}</SC.PartyRole>
        {hasScrive && renderPartyItems(party)}
      </InnerBox>
    );
  });
};
