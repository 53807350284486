import styled, { css } from "styled-components";

import checkMark from "../../../../assets/svg/check_white.svg";
import calendarIcon from "../../../../assets/svg/calendar.svg";
import crossIcon from "../../../../assets/svg/cross_white.svg";
import imageIcon from "../../../../assets/svg/picture.svg";
import addFileIcon from "../../../../assets/svg/add-file.svg";
import documentIcon from "../../../../assets/svg/document.svg";
import closeIcon from "../../../../assets/svg/close.svg";
import closeIconWhite from "../../../../assets/svg/cross_white.svg";
import swapIconWhite from "../../../../assets/svg/material/swap_white.svg";
import closeBlack from "../../../../assets/svg/material/close-black.svg";

export const InputSpacing = styled.div`
  max-width: 600px;
  margin-bottom: ${(p) =>
    p.smallBottomSpacing ? p.theme.spacing(2) : p.theme.spacing(8)};

  ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;
    `}

  ${(p) =>
    p.noMargin &&
    css`
      margin-bottom: 0;
    `}

    ${(p) =>
    p.fillWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `}

    ${(p) =>
    p.small &&
    css`
      margin-bottom: ${(p) => p.theme.spacing(1)};
    `}
`;

export const InputFieldWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.colors.grayDark};
  border-radius: 5px;
  position: relative;
  width: 100%;
  padding: 2px;

  ${(p) =>
    p.noBorder &&
    css`
      border: 0;
      border-radius: 0;
    `}

  ${(p) =>
    p.valid &&
    css`
      &::after {
        content: "";
        background: url(${p.svg});
        height: 30px;
        right: 12px;
        top: 7px;
        position: absolute;
        width: 20px;
        background-size: cover;
        path {
          stroke: ${p.theme.colors.green};
        }
      }
    `}
  ${(p) =>
    p.error &&
    css`
      border: 1px solid ${p.theme.colors.red};
      box-shadow: 0 0 3px ${p.theme.colors.red};
    `};
`;

export const InputFieldTitle = styled.p`
  font-weight: bold;
  color: ${(p) => p.theme.colors.primaryText};
  margin: 0;

  ${(p) =>
    p.small &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
      font-size: ${(p) => p.theme.fontSizes.headerXSmall};
      color: ${(p) => p.theme.colors.primaryText};
    `}
`;

export const InputFieldDescription = styled.p`
  color: ${(p) => p.theme.colors.textSecondaryDark};
  margin: -4px 0 10px 0;
  font-size: 14px;
`;

export const TextField = styled.textarea`
  border: 0;
  position: relative;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: ${(p) => p.theme.spacing(3)};
  width: 100%;
  height: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  &:required {
    box-shadow: none;
  }

  resize: ${(p) => (p.noResize ? "none" : "vertical")};
`;

export const InputField = styled.input`
  border: 0;
  position: relative;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: ${(p) => p.theme.spacing(3)};
  width: 100%;
  height: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  &:required {
    box-shadow: none;
  }

  ${(p) =>
    p.rowSize &&
    css`
      padding: ${(p) => p.theme.spacing(2)};
    `}
`;

export const ErrorMessage = styled.p`
  margin: 0;
  color: ${(p) => p.theme.colors.red};
`;

export const FormWrapper = styled.form`
  box-sizing: border-box;
  flex: 1;
  max-width: 800px;
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
  padding: ${(p) => p.theme.spacing(3)};
  border-radius: 5px;
  padding-bottom: 140px;
  width: 100%;
`;

export const InnerFormWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.white};
  margin-bottom: ${(p) => p.theme.spacing(12)};
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.grayDark};

  ${(p) =>
    p.hideBorder &&
    css`
      border: none;
    `}
`;

export const FormTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(p) => p.theme.colors.black};

  margin: ${(p) => p.theme.spacing(2)} 0 ${(p) => p.theme.spacing(8)} 0;
`;

export const CheckBox = styled.input.attrs({
  type: "checkbox",
})`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: ${(p) => (p.small ? "22px" : "32px")};
    cursor: pointer;
    line-height: ${(p) => (p.small ? "18px" : "26px")};
    display: inline-block;
    color: ${(p) => p.theme.colors.black};

    ${(p) =>
      p.small &&
      css`
        font-size: 0.8rem;
      `}
    ${(p) =>
      p.disabled &&
      css`
        opacity: 0.8;
        cursor: not-allowed;
      `}
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: ${(p) => (p.small ? "18px" : "26px")};
    height: ${(p) => (p.small ? "18px" : "26px")};
    border: 1px solid #ddd;
    border-radius: ${(p) => (p.radioStyle ? "50%" : "3px")};
    background: #fff;
    ${(p) =>
      p.disabled &&
      css`
        opacity: 0.8;
      `}
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: ${(p) => (p.small ? "18px" : "26px")};
    height: ${(p) => (p.small ? "18px" : "26px")};
    background-color: ${(p) => p.theme.colors.blueDark};
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: ${(p) => (p.radioStyle ? "50%" : "3px")};
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    ${(p) =>
      p.disabled &&
      css`
        opacity: 0.8;
      `}
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  &:checked + label:after {
    background-image: url(${checkMark});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

export const CheckBoxWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(4)};
  &:last-child {
    margin-bottom: ${(p) => p.theme.spacing(12)};
  }

  ${(p) =>
    p.noMargin &&
    css`
      margin-bottom: 0;
      &:last-child {
        margin-bottom: 0;
      }
    `}

  ${(p) =>
    p.small &&
    css`
      margin-bottom: ${(p) => p.theme.spacing(1)};
      &:last-child {
        margin-bottom: ${(p) => p.theme.spacing(1)};
      }
    `}
`;

export const SelectWrapper = styled.div`
  width: 100%;

  ${(p) =>
    p.extraPadding &&
    css`
      padding-bottom: 32px;
    `}
`;

export const SelectLoader = styled.div`
  background-color: hsl(0, 0%, 100%);
  padding: 4px 8px;
  width: 100%;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  font-size: 1.2rem;
`;

export const CreateItemWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.colors.gray};
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 1002;
`;

export const FileUploaderWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px;
  border: 2px solid ${(p) => p.theme.colors.grayDark};
  border-radius: 5px;
`;
export const FileUploaderIcon = styled.div`
  height: 50px;
  width: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.iconSrc});
  margin-bottom: 12px;
`;
export const FileUploaderLabel = styled.div`
  font-weight: 600;
  color: ${(p) => p.theme.colors.textPrimary};
  font-size: 0.8rem;
  text-transform: uppercase;
`;

export const FileUploaderImage = styled.div`
  height: 128px;
  width: 200px;
  background-size: cover;
  background-position: center;
  background-image: url(${(p) => (p.src ? p.src : "")});
`;

export const NonModalFormWrapper = styled.div`
  max-width: 800px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border-radius: 5px;
  padding: 14px;
`;

export const IntervalFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IntervalFieldPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 18px;
`;

export const IntervalFieldTitle = styled.span`
  font-size: 0.75rem;
  margin-bottom: 0px;
  font-weight: 600;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const IntervalField = styled.input.attrs({
  type: "number",
  maxLength: 2,
})`
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  position: relative;
  border-radius: 5px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: ${(p) => p.theme.spacing(3)};
  width: 48px;
  height: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  &:required {
    box-shadow: none;
  }
`;

export const Suggestions = styled.ul`
  -webkit-padding-start: 0;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  left: -1px;
  list-style-type: none;
  padding: 0;
  padding: none;
  position: absolute;
  top: 100%;
  width: calc(100% + 2px);
  z-index: 999;

  & li {
    align-items: center;
    display: flex;
    height: 40px;
    list-style: none;
    margin: 0;
    padding: 10px;
    width: 100%;
    z-index: 999;
    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }
    &:first-child {
      border-top: 1px solid rgb(221, 221, 221);
    }
    &:hover {
      background-color: #bfeefd;
    }
  }
`;

export const DatePickerWrapper = styled.input.attrs({
  type: "text",
})`
  cursor: pointer;
  position: relative;
  padding: 8px 12px 8px 34px;
  font-size: 1rem;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 4px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  background-position: 8px;
  background-repeat: no-repeat;
  background-image: url(${calendarIcon});
  background-size: 18px;

  ${(p) =>
    p.fillWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `}

  @media(${(p) => p.theme.mediaQueries.mobile}) {
    max-width: 200px;
  }
`;

export const CustomM2MLabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const M2mLabel = styled.div`
  cursor: pointer;
  background-color: ${(p) => p.theme.colors.green};
  color: ${(p) => p.theme.colors.white};
  padding: 6px 12px;
  border-radius: 30px;
  margin-right: 6px;
  margin-bottom: 6px;
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const M2mLabelRemoveButton = styled.div`
  cursor: pointer;
  height: 0.7rem;
  width: 0.7rem;
  margin-left: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${crossIcon});
  background-size: contain;
`;

export const ImageUploaderWrapper = styled.div`
  padding: 20px;
  background-color: white;
`;

export const UploadedImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
`;

export const ImageWithContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 6px 6px 0;
  width: 210px;
  height: 210px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 3px;
  overflow: hidden;
`;

export const UploadedImage = styled.div`
  position: relative;
  height: 100px;
  width: 140px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 3px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.src});
  background-size: cover;
`;

export const UploadedImageWithContent = styled(UploadedImage)`
  border: none;
  border-radius: 0;
  margin: 0;
  height: 140px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const NoImage = styled.div`
  height: 100px;
  width: 140px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${imageIcon});
  background-size: contain;
`;

export const RemoveImageButton = styled.div`
  cursor: pointer;
  height: 18px;
  width: 18px;
  position: absolute;
  right: 6px;
  top: 6px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${crossIcon});
  background-size: 55%;
`;

export const ClearDateButton = styled.div`
  position: absolute;
  height: 14px;
  width: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeIcon});
  right: 10px;
  top: calc(50% - 7px);
  cursor: pointer;
`;

export const UploadededFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadedFile = styled.div`
  position: relative;
  height: 100px;
  width: 140px;
  border-radius: 3px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${documentIcon});
  background-size: 60%;
`;

export const UploadFileTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  color: ${(p) => p.theme.colors.primary};
`;

export const NoFile = styled.div`
  height: 100px;
  width: 140px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${addFileIcon});
  background-size: contain;
`;

export const DurationFieldWrapper = styled.div`
  display: flex;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.textPrimary};
  font-family: "Inter", sans-serif;
`;

export const DurationSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const SelectButton = styled.div`
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};
  color: ${(p) => (p.inverted ? p.theme.colors.primaryBlue : "#fff")};
  background-color: ${(p) =>
    p.hasError ? p.theme.colors.red : p.theme.colors.primaryBlue};
  border-radius: 30px;
  padding: 0 27px;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  display: flex;
  justify-content:center;
  align-items:center;
  text-align: center;
  transition: all 0.2s ease-in-out;
  height: 40px;
  line-height: 40px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  position:relative;
  -webkit-appearance: none;
  white-space:nowrap;
  border: none;
  box-sizing: border-box;

  &:hover {
    background-color: ${(p) =>
      p.inverted ? p.theme.colors.gray1 : p.theme.colors.primaryBlueFaded};
  }

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }
`;

export const ClearSelectButton = styled.div`
  height: 14px;
  width: 14px;
  margin-left: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeIconWhite});
  cursor: pointer;
`;

export const RentFieldToggleBox = styled.div`
  height: 100%;
  min-height: 100%;
  padding: 9.5px;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  border-left: thin solid ${(p) => p.theme.colors.fadedBorder};
  background-color: ${(p) => p.theme.colors.blue};
  color: white;
  cursor: pointer;

  ${(p) =>
    p.active === false &&
    css`
      background-color: ${(p) => p.theme.colors.gray1};
      color: ${(p) => p.theme.colors.gray3};
    `};

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
    `};
`;

export const RentFieldToggleIcon = styled.div`
  height: 18px;
  width: 18px;
  background-size: contain;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${swapIconWhite});
`;

export const FileDropArea = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border: 2px dashed #ccc;
  border-radius: 12px;
  min-width: 480px;
  max-width: 600px;
  padding: 20px;
  min-height: 200px;
  background-color: #f1f1f1;

  ${(p) =>
    p.highlighted &&
    css`
      background-color: ${p.theme.colors.blueLight};
    `}
`;

export const EmptyFile = styled.div`
  background-color: #fff;
  height: 180px;
  width: 140px;
  border: thin solid #ddd;
  border-radius: 6px;
  padding: 12px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  text-align: center;
`;

export const FileDisplay = styled(EmptyFile)`
  position: relative;
  background-color: #fff;
  height: 180px;
  width: 140px;
  border: thin solid #ddd;
  border-radius: 6px;
  padding: 12px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(p) => p.src});

  ${(p) =>
    p.name?.split(".")?.[1] &&
    css`
      &:after {
        position: absolute;
        content: "${p.name?.split(".")?.[1]}";
        left: calc(50% - 30px);
        top: calc(50& - 30px);
        width: 60px;
        height: 60px;
        font-size: 12px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: thin solid #ddd;
        background-color: rgba(256, 256, 256, 0.7);
        border-radius: 50%;
      }
    `}
`;

export const RemoveFileDisplayButton = styled.div`
  cursor: pointer;
  position: absolute;
  height: 24px;
  width: 24px;
  top: 6px;
  right: 6px;
  border: thin solid #444;
  border-radius: 50%;
  background-color: #fff;
  background-position: center;
  background-size: 78%;
  background-repeat: no-repeat;
  background-image: url(${closeBlack});
`;

export const FileDisplayName = styled.div`
  position: absolute;
  padding: 4px;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: rgba(256, 256, 256, 0.6);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImageRadioGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 250px;
    border-radius: 5px;
    margin: 5px;
    &:hover {
      background-color: #f1f1f1;
    }

    ${(p) =>
      p.active &&
      css`
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.primaryBlue};

        &:hover {
          background-color: ${(p) => p.theme.colors.blueLight};
          color: ${(p) => p.theme.colors.primaryBlue};
        }
      `}


`;

export const ImageRadioGroupImage = styled.div`
    width: 90%;
    height: 80%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(p) => p.bgnImage});
`;
export const ImageRadioGroupLabel = styled.p`
    font-weight: 700;
    text-align: center;
`;