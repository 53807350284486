export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title;
};
export const detailUrl = ({ id, isGov = false }) => {
  if (isGov) {
    return `/gov-settings/detail/${id}`;
  }
  return `/rounding-settings/detail/${id}`;
};

export const editUrl = ({ id }) => {
  return `/rounding-errand-setting/edit/${id}`;
};

export const createUrl = () => {
  return `/rounding-errand-setting/create`;
};

export const overviewUrl = ({ govErrand }) => {
  if (govErrand) return `/gov-errands`;

  return `/rounding-errands`;
};
