import * as React from "react";

import columnDefs from "./listDefs";

// store, state
import { 
    constants,
} from "../../../store/insightsGoal";
import BaseTable from "../Base/FinalTable/BaseTable";
import { TextButton } from "src/components/Forms/Base/Buttons";
import Alert from "src/components/Alert/Alert";
import classNames from "classnames";

export default function InsightsGoalsTable({
  persistantQuery,
  onTableRowClicked,
  tableId,
  ignoreLocalStorage,
  isBare,
}) {
  
  const [showAll, setShowAll] = React.useState(false);

  const columns = React.useMemo(
    () => columnDefs(),
    [persistantQuery]
  );

  return (
    <>
      {persistantQuery && (
        <Alert title={showAll ? "Visar alla mål": "Visar filtrerade mål"} className={classNames(showAll ? "pb-0": "pb-1")}>
          {!showAll && (
            "Visar endast Mål vars Nyckeltal visas på den aktuella Insightssidan samt vars fastighets/bolags/portföljsfiltering matchar sidans filtrering"
          )}
        </Alert>
      )}
      <TextButton
        title={showAll ? "Visa endast filtrerade mål" : "Visa alla mål"}
        iconPlacement="right"
        clicked={showAll ? () => setShowAll(false) : () => setShowAll(true)}
        extraStyle={{ marginTop: 0, marginBottom: 0}}
      />
      <BaseTable
        constants={constants}
        columns={columns}
        persistantQuery={showAll ? {} : persistantQuery}
        badges={{}}
        filters={{}}
        tableId={tableId || "insights__insightsgoal__fulltable"}
        title={"Mål"}
        onRowClicked={onTableRowClicked}
        {...{ ignoreLocalStorage, isBare }}
      />
    </>
  );
}
