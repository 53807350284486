import * as React from "react";
import { useParams } from "react-router";

import { DetailInnerWrapper } from "../../../components/sharedStyles";
import DocumentViewer from "../../../components/Details/OverviewInfo/DocumentViewer/DocumentViewer";
import {
  useParkingContract,
  useParkingContractForm,
  constants,
  detailUrl,
  update,
} from "../../../store/parkingContracts";

export default function ParkingContractDocumentTabs() {
  const { parkingContractId } = useParams();
  const [parkingContract] = useParkingContract(parkingContractId);

  useParkingContractForm("PATCH", parkingContractId);
  return (
    <DetailInnerWrapper>
      <DocumentViewer
        contract={parkingContract}
        contractUpdate={update}
        contractConstants={constants}
        {...{ detailUrl }}
      />
    </DetailInnerWrapper>
  );
}
