import * as React from "react";
import Modal from "..//Forms/Base/Modals/Modal";
import PrimaryBtn from "..//Forms/Base/Buttons/PrimaryBtn";
import LocalSelectField from "..//Forms/Base/Fields/LocalSelectField";

import { PlusIcon, MinusIcon, TrashIcon } from "@heroicons/react/24/outline";

import { cloneDeep } from "lodash";
import { TOAST_TYPES, addToast } from "../../store/toasts";
import { useDispatch } from "react-redux";

import InfoModal from "./InfoModal";

import { INFO_TEXTS_FOR_FILTERS } from "../../views/NewReports/utils";

export function Aggregation({
  setIsAggregating,
  aggregations,
  setAggregations,
  groupableColumns,
}) {
  const dispatch = useDispatch();

  function searchForArray(haystack, needle) {
    var i, j, current;
    for (i = 0; i < haystack.length; ++i) {
      if (needle.length === haystack[i].length) {
        current = haystack[i];
        for (j = 0; j < needle.length && needle[j] === current[j]; ++j);
        if (j === needle.length) return i;
      }
    }
    return -1;
  }

  const groupableChoices = React.useMemo(() => {
    return [
      ...groupableColumns.map((col) => {
        return { v: col.identifier, d: col.name };
      }),
    ];
  }, [groupableColumns]);

  const [localAggregations, setLocalAggregations] = React.useState(
    aggregations ? aggregations : []
  );

  const modeChoices = React.useMemo(() => {
    return [
      { v: "mean", d: "Beräkna medelvärdet" }, //  Compute mean of groups
      { v: "sum", d: "Beräkna summan" }, //  Compute sum of group values
      { v: "size", d: "Beräkna antal" }, //  Compute group sizes
      { v: "count", d: "Beräkna antal, ej tomma värden" }, // Compute count of group
      { v: "std", d: "Beräkna standardavvikelse" }, // Standard deviation of groups
      { v: "var", d: "Beräkna variation" }, // Compute variance of groups
      { v: "sem", d: "Beräkna den sammanlagda felvariansen" }, // Standard error of the mean of groups
      { v: "min", d: "Beräkna minimum" }, // Compute min of group values
      { v: "max", d: "Beräkna maximum" }, // Compute max of group values
    ];
  }, []);

  const updateAggrigations = () => {
    let groups = [];

    let canUpdate = true;

    for (let aggr of localAggregations) {
      if (searchForArray(groups, aggr.group_by) !== -1) {
        canUpdate = false;
        break;
      }

      groups.push(aggr.group_by);
    }

    setAggregations(localAggregations);

    return canUpdate;
  };

  const addAggregation = () => {
    let aggrs = localAggregations.slice();
    aggrs.push({
      group_by: [""], //states
      modes: [""], //states
    });
    setLocalAggregations(aggrs);
  };

  const removeAggregation = (aggrIndex) => {
    let aggrs = localAggregations.slice();
    aggrs.splice(aggrIndex, 1), setLocalAggregations(aggrs);
  };

  const updateStateForAggr = (aggrIndex, index, val, stateKey) => {
    let aggrs = localAggregations.slice();
    aggrs[aggrIndex][stateKey][index] = val;

    setLocalAggregations(aggrs);
  };

  const getValueFromAggr = (aggrIndex, index, stateKey) => {
    return localAggregations[aggrIndex][stateKey][index];
  };

  const createNewInput = (aggrIndex, stateKey) => {
    let aggrs = localAggregations.slice();
    aggrs[aggrIndex][stateKey].push("");
    setLocalAggregations(aggrs);
  };

  const removeInput = (aggrIndex, index, stateKey) => {
    let aggrs = localAggregations.slice();
    aggrs[aggrIndex][stateKey].splice(index, 1);
    setLocalAggregations(aggrs);
  };

  const buildAggregationPart = (aggr, aggrIndex, stateKey, _choices) => {
    let elems = [];

    let groupIndex = 0;

    let lastChoicesArr = [];

    const build = (_aggrIndex, _index) => {
      let newChoices = [];

      let choicesToRemove = localAggregations[_aggrIndex][stateKey].slice(
        0,
        _index
      );

      newChoices = _choices.filter((obj) => !choicesToRemove.includes(obj.v));

      lastChoicesArr = newChoices;

      return (
        <div key={_aggrIndex + "" + _index} className="relative">
          <LocalSelectField
            className="min-w-[180px]"
            selectClassName="pr-8"
            placeholder=""
            choices={newChoices}
            onChange={(val) =>
              updateStateForAggr(_aggrIndex, _index, val, stateKey)
            }
            value={getValueFromAggr(_aggrIndex, _index, stateKey)}
          />
          {aggr[stateKey].length > 1 && (
            <MinusIcon
              onClick={() => removeInput(_aggrIndex, _index, stateKey)}
              className="w-4 h-4 absolute top-[-6px] right-[-6px] rounded-full border border-solid border-gray-300 bg-white transition-colors hover:text-white hover:bg-red-500 cursor-pointer"
            />
          )}
        </div>
      );
    };

    for (let group of aggr[stateKey]) {
      if (groupIndex !== 0) {
        if (groupIndex + 1 === aggr[stateKey].length) {
          elems.push(<span>och</span>);
        } else {
          elems.push(<span>,</span>);
        }
      }

      elems.push(build(aggrIndex, groupIndex));
      groupIndex++;
    }

    if (groupIndex < _choices.length) {
      elems.push(
        <PlusIcon
          onClick={() => createNewInput(aggrIndex, stateKey)}
          className="w-7 h-7 cursor-pointer transition-colors hover:text-gray-400"
        />
      );
    }

    return elems;
  };

  const aggregationsRender = React.useMemo(() => {
    if (!localAggregations) return [];
    return localAggregations.map((aggr, index) => {
      let main = buildAggregationPart(
        aggr,
        index,
        "group_by",
        groupableChoices
      );
      let type = buildAggregationPart(aggr, index, "modes", modeChoices);

      return (
        <div
          key={index}
          className="flex flex-col border border-solid border-gray-200 p-4 rounded relative"
        >
          <p className="text-base">Aggergering {index + 1}</p>
          <TrashIcon
            onClick={() => removeAggregation(index)}
            className="w-8 h-8 absolute top-1.5 right-1.5 rounded p-1.5 cursor-pointer border border-solid bg-white border-gray-300 transition-colors hover:bg-red-500 hover:text-white"
          />
          <div className="pl-2">
            <div className="flex items-start mt-4">
              <p className="mt-3">Aggregera</p>
              <div className="flex flex-wrap items-center ml-2 space-x-2 space-y-2">
                {main}
              </div>
            </div>
            <div className="flex items-start mt-2">
              <p className="mt-3">på</p>
              <div className="flex flex-wrap items-center ml-2 space-x-2 space-y-2">
                {type}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [localAggregations]);

  return (
    <Modal
      title={
        <div className="flex">
          <p>Aggregera</p>
          <InfoModal
            infoStyle={{ paddingTop: "6px" }}
            popupClassName="!min-w-[400px]"
            text={INFO_TEXTS_FOR_FILTERS.aggregations}
          />
        </div>
      }
      closeFunction={() => {
        let canUpdate = updateAggrigations();
        if (!canUpdate) {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Samma aggregeringar",
              description:
                "Olika aggregeringar kan inte aggregera på samma kolumner",
            })
          );
          return;
        }
        setIsAggregating(false);
      }}
    >
      <div className="overflow-y-auto space-y-4">{aggregationsRender}</div>
      <PrimaryBtn onClick={addAggregation}>Lägg till aggregering</PrimaryBtn>
    </Modal>
  );
}
