import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/Forms/Base/Buttons";

// import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";

import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";

import useActiveFilters from "../../../hooks/useActiveFilters";
import { useAllPermissionCheck } from "../../../store/base";

import { CompanyOrPrivate } from "../../../components/Forms/Pickers";
import ModalForm from "../../../components/Forms/Tenant/ChapterForm/ModalForm";
import InviteTenantsModal from "../../../components/Details/Tenant/InviteTenantsModal";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function Tenants() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
    is_brf: false,
  };

  const canAdd = useAllPermissionCheck(["add_can_user", "add_can_tenant"]);
  const tenantPortalActive = useAllPermissionCheck([
    "allow_tenantportal",
    "view_can_tenantportal",
  ]); //CUSTOMERPERMISSION?

  const [chooseTenantActive, setChooseTenantActive] = React.useState(false);
  const [createTenantActive, setCreateTenantActive] = React.useState(null);
  const [inviteTenantsOpen, setInviteTenantsOpen] = React.useState(false);

  const renderActions = () => (
    <div style={{ alignItems: "center" }}>
      {tenantPortalActive && canAdd && (
        <PrimaryBtn
          secondary
          onClick={() => setInviteTenantsOpen(true)}
          className="mr-2"
        >
          Bjud in till "Mina sidor"
        </PrimaryBtn>
      )}
      {canAdd && (
        <PrimaryBtn onClick={() => setChooseTenantActive(true)}>
          Lägg till hyresgäst
        </PrimaryBtn>
      )}
    </div>
  );

  return (
    <>
      {tenantPortalActive && (
        <InviteTenantsModal
          open={inviteTenantsOpen}
          closeFunction={() => setInviteTenantsOpen(false)}
        />
      )}

      {canAdd && createTenantActive && (
        <ModalForm
          method="POST"
          instance={{}}
          kind={createTenantActive}
          onCheckout={() => setCreateTenantActive(null)}
        />
      )}
      <CompanyOrPrivate
        isOpen={chooseTenantActive}
        onClose={() => setChooseTenantActive(false)}
        onSelect={(kind) => {
          setChooseTenantActive(false);
          setCreateTenantActive(kind);
        }}
      />
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>
            {renderActions()}
          </OverviewTitleWrapper>

          <TenantTable persistantQuery={persistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
