import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import PremisesStatusModalForm from "../../../../components/Forms/PremisesStatus/ChapterForm/ModalForm";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../../../components/sharedStyles";
import { usePremisesStatus } from "../../../../store/premisesStatuses";
import PageSlider from "../../../Layouts/PageSlider/PageSlider";
import PageTitle from "../../../Layouts/PageTitle/PageTitle";

import ApartmentTable from "../../../../components/Tables/Apartments/FullTableNewVersion";
import IndpTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import ParkingTable from "../../../../components/Tables/Parking/ParkingSpots/FullTable";
import { TextButton } from "../../../../components/Forms/Base/Buttons";
import { buildQueryString } from "../../../../store/base";

const TABS = {
  APARTMENTS: "Lägenheter",
  INDP: "Lokaler",
  PARKING: "Fordonsplatser",
};

export default function PremisesStatusesDetail() {
  const [editOpen, setEditOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("APARTMENTS");
  const { statusId } = useParams();
  const [premisesStatus] = usePremisesStatus(statusId);
  const breadCrumbs = [
    {
      label: "Objektsstatusar",
      url: "/configcenter/categories/premises-statuses",
    },
    {
      label: premisesStatus?.name || "Laddar...",
    },
  ];

  const persistantQuery = {
    premises_statuses: statusId,
  };

  return (
    <>
      <PremisesStatusModalForm
        method="PATCH"
        id={statusId}
        instance={premisesStatus}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <BareLayoutWrapper>
        <BareLayoutTitleWrapper>
          <PageTitle
            title={premisesStatus?.name || "Laddar..."}
            breadCrumbs={breadCrumbs}
          />
        </BareLayoutTitleWrapper>
        <DetailPageBox config={true}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Objektsstatus</OverviewTitle>
              <OverviewSubtitle>
                Dessa objekt använder sig av denna status
              </OverviewSubtitle>
              <OverviewSubtitle>
                <TextButton
                  title="Redigera status"
                  clicked={() => setEditOpen(true)}
                  iconType="edit"
                  iconPlacement="right"
                />
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>

            <PageSlider
              {...{
                TABS,
                selectedTab,
                onTabSelected: (tab) => setSelectedTab(tab),
              }}
            />
          </OverviewTitleWrapper>

          {selectedTab === "APARTMENTS" && (
            <ApartmentTable persistantQuery={persistantQuery} />
          )}

          {selectedTab === "INDP" && (
            <IndpTable persistantQuery={persistantQuery} />
          )}

          {selectedTab === "PARKING" && (
            <ParkingTable persistantQuery={persistantQuery} />
          )}
        </DetailPageBox>
      </BareLayoutWrapper>
    </>
  );
}
