import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useTenant } from "../../../store/tenants";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import LeaseContractsTable from "../../../components/Tables/LeaseContracts/FullTableNewVersion";

import OtherContractsTable from "../../../components/Tables/OtherContracts/FullTableNewVersion";

import ParkingTable from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersion";
import BasicDocTable from "../../../components/Tables/BasicDocs/FullTable";
import { buildQueryString } from "../../../store/base";

function TenantContracts() {
  const { tenantId } = useParams();
  const [tenant] = useTenant(tenantId);

  const leaseQuery = { broad_tenant: tenantId };

  const otherQuery = { tenant: tenantId };

  const basicQuery = {
    tenant: tenantId,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Hyresavtal</OverviewTitle>
        </OverviewTitleWrapper>
        <LeaseContractsTable
          persistantQuery={leaseQuery}
          disableBadgeFilterCounts
        />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Parkeringsavtal</OverviewTitle>
        </OverviewTitleWrapper>
        <ParkingTable persistantQuery={otherQuery} disableBadgeFilterCounts />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Övriga avtal</OverviewTitle>
        </OverviewTitleWrapper>
        <OtherContractsTable
          persistantQuery={otherQuery}
          disableBadgeFilterCounts
        />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Dokument</OverviewTitle>
        </OverviewTitleWrapper>
        <BasicDocTable persistantQuery={basicQuery} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default TenantContracts;
