export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PRODUCTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PRODUCTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PRODUCTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PRODUCTS",
  DESTROY_FORM: "DESTROY_FORM_PRODUCTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_PRODUCTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PRODUCTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PRODUCTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PRODUCTS",
  RESET_STATE: "RESET_STATE_PRODUCTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PRODUCTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PRODUCTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_PRODUCTS",

  LIST_URL: "/accounting/costs/product/list/",
  POST_URL: "/accounting/costs/product/",
  PATCH_URL: "/accounting/costs/product/",
  GET_URL: "/accounting/costs/product/",

  STORE_NAME: "invoicingProducts",
};
