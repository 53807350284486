import React from "react";
import { usePermissionCheck } from "../../../../../store/base";
import { InfoBox } from "../../../../Displays";
import { ACTION_TYPES } from "./infoItems";
import * as SC from "./infoModalStyles";

export default function InfoModalContent({ infoObj }) {
  const hasCompanyPerm = usePermissionCheck(infoObj?.companyPermType);
  if (!infoObj) return null;
  return (
    <>
      {infoObj?.companyPerm && !hasCompanyPerm && (
        <InfoBox
          title="OBS"
          text={`För att nyttja ${infoObj.title} behöver ${infoObj.companyPermTitle} paketet vara köpt. Kontakta oss för att aktivera.`}
        />
      )}
      <SC.AllItemsWrapper>
        {infoObj?.info?.map((i) => {
          return (
            <SC.SingleItemWrapper>
              <SC.ActionTitle>{ACTION_TYPES[i?.kind]}</SC.ActionTitle>
              <SC.ActionItems>
                {i?.actions?.map((a) => {
                  return <SC.ActionItemTitle>{a}</SC.ActionItemTitle>;
                })}
              </SC.ActionItems>
            </SC.SingleItemWrapper>
          );
        })}
      </SC.AllItemsWrapper>
    </>
  );
}
