export default [
  {
    title: "Avtal",
    key: "CONTRACT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "main_contract",
      "id_number",
      "category",
      "status",
      "start_date",
      "end_date",
      "renewed_to",
      "renew_interval",
      "warning_interval",
      "notify_interval",
      "content_type",
      "object_id",
    ],
  },

  {
    title: "Uppdatera",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
