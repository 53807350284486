import * as React from "react";
import { useDispatch } from "react-redux";
import {
  setActiveFormInstance,
  useFrequentPermissions,
} from "../../../../store/base";
import {
  useCostForm,
  constants,
  destroyPatchForm,
  destroyPostForm,
  create,
  update,
} from "../../../../store/costs";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import CheckField from "../../Base/Fields/CheckField";
import DateSelect from "../../Base/Fields/DateSelect";
import RentInputField from "../../Base/Fields/RentInputField";
import SelectField from "../../Base/Fields/SelectField";
import TableSelectFieldWithCreate from "../../Base/Fields/TableSelectFieldWithCreate";
import TextInputField from "../../Base/Fields/TextInputField";
import Modal from "../../Base/Modals/Modal";

import IndexSettingNestedFields from "../../IndexSetting/NestedFields";
import CostCenterNestedFields from "../../InvoicingCostCenter/NestedFields";
import ProductNestedFields from "../../InvoicingProduct/NestedFields";
import ProjectNestedFields from "../../InvoicingProject/NestedFields";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";
import IndexSettingsTable from "src/components/Tables/IndexSetting/FullTable";

export default function CostModalForm({
  closeFunction,
  method,
  id,
  instance,
  areaForRentField,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const formLoaded = useCostForm(method, id);
  const [actionLoading, setActionLoading] = React.useState(false);

  const totalArea = areaForRentField || null;

  const { hasBillectaFullPermission } = useFrequentPermissions();

  React.useEffect(() => {
    if (instance) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }

    return () => {
      dispatch(destroyPatchForm());
      dispatch(destroyPostForm());
    };
  }, []);

  const onSubmit = () => {
    setActionLoading(true);

    if (method === "POST") {
      dispatch(
        create({
          successCallback: (_, returned) => {
            setActionLoading(false);
            closeFunction();
          },
          errorCallback: () => {
            setActionLoading(false);
          },
        })
      );
    } else {
      dispatch(
        update({
          id,
          successCallback: (_, returned) => {
            setActionLoading(false);
            closeFunction();
          },
          errorCallback: () => {
            setActionLoading(false);
          },
        })
      );
    }
  };

  const loading = !formLoaded || actionLoading;

  return (
    <Modal
      onAccept={onSubmit}
      closeFunction={closeFunction}
      title={
        method === "PATCH" ? "Redigera debiteringsrad" : "Skapa debiteringsrad"
      }
    >
      <>
        {loading && <OverlaySpinner />}

        <div className="mb-6">
          <CheckField
            title="Debitera ej för denna rad"
            description="Inaktiverar debitering för denna debiteringsrad. Används t.ex. när indexuppräkningen från raden ska debiteras men inte basbeloppet."
            fieldKey={`do_not_debit`}
            {...{ storeName, method }}
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TextInputField
            title="Beskrivning"
            storeName={storeName}
            method={method}
            fieldKey={`title`}
          />

          <TableSelectFieldWithCreate
            storeName={storeName}
            placeholder="Välj produkt..."
            title="Produkt"
            method={method}
            fieldKey={`product`}
            createDisplayKey="title"
            createTitle="Skapa ny produkt"
            TableComponent={InvoicingProductTable}
          >
            {(parentPath) => (
              <ProductNestedFields
                parentPath={parentPath}
                parentInstructionsPath={`product`}
                method={method}
                storeName={storeName}
              />
            )}
          </TableSelectFieldWithCreate>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <RentInputField
            {...{ method, storeName }}
            fieldKey={`value`}
            title="Debiteras (SEK)"
            description="Värde på debiteringen i SEK. Rabatter anges negativt, t.ex. -1000"
            area={totalArea}
          />

          <SelectField
            title="Momssats"
            placeholder="Utifrån grundmoms på avtalet"
            description="Lämnas tomt om grundmomssatsen ska appliceras på kostnaden."
            storeName={storeName}
            method={method}
            fieldKey={`vat`}
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <DateSelect
            title="Startdatum"
            description="Kostnaden ska faktureras från detta datum. Lämnas tomt om kostnaden löper med avtalet."
            storeName={storeName}
            placeholder="Med avtalet"
            method={method}
            fieldKey={`start_date`}
          />

          <DateSelect
            title="Slutdatum"
            description="Kostnaden ska faktureras till detta datum. Lämnas tomt om kostnaden löper med avtalet."
            storeName={storeName}
            placeholder="Med avtalet"
            method={method}
            fieldKey={`end_date`}
          />
        </div>

        {hasBillectaFullPermission && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectFieldWithCreate
              storeName={storeName}
              placeholder="Välj projekt..."
              title="Projekt"
              method={method}
              fieldKey={`project`}
              createDisplayKey="title"
              createTitle="Skapa nytt projekt"
              TableComponent={InvoicingProjectsTable}
            >
              {(parentPath) => (
                <ProjectNestedFields
                  method={method}
                  storeName={storeName}
                  parentPath={parentPath}
                  parentInstructionsPath={`project`}
                />
              )}
            </TableSelectFieldWithCreate>

            <TableSelectFieldWithCreate
              storeName={storeName}
              placeholder="Välj kostnadsställe..."
              title="Kostnadsställe"
              method={method}
              fieldKey={`cost_center`}
              createDisplayKey="title"
              createTitle="Skapa nytt kostnadsställe"
              TableComponent={InvoicingCostCenterTable}
            >
              {(parentPath) => (
                <CostCenterNestedFields
                  method={method}
                  storeName={storeName}
                  parentPath={parentPath}
                  parentInstructionsPath={`cost_center`}
                />
              )}
            </TableSelectFieldWithCreate>
          </div>
        )}

        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectFieldWithCreate
            storeName={storeName}
            placeholder="Välj inställning..."
            title="Indexuppräkningsinställning"
            method={method}
            fieldKey={`indexation`}
            createDisplayKey="title"
            createTitle="Skapa ny inställning"
            TableComponent={IndexSettingsTable}
          >
            {(parentPath) => (
              <IndexSettingNestedFields
                method={method}
                storeName={storeName}
                parentPath={parentPath}
                parentInstructionsPath={`indexation`}
              />
            )}
          </TableSelectFieldWithCreate>
        </div>
      </>
    </Modal>
  );
}
