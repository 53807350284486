import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import * as SharedStyles from "../../../components/sharedStyles";

import IMDSettingsTable from "../../../components/Tables/InvoicingIMDSettings/FullTable";

import InvoicingIMDSettingModalForm from "../../../components/Forms/InvoicingIMDSetting/ChapterForm/ModalForm";

import PageTitle from "../../Layouts/PageTitle/PageTitle";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const breadCrumbs = [
  {
    label: "IMD-Debiteringsinställningar",
  },
];

export default function InvoicingSettings(props) {
  const [createIMDSettingOpen, setCreateIMDSettingOpen] = React.useState(false);

  const ACTION = (
    <div style={{ marginTop: "20px" }}>
      <PrimaryButton
        newDesign
        clicked={() => setCreateIMDSettingOpen(true)}
        title="Lägg till ny inställning för IMD-debitering"
      ></PrimaryButton>
    </div>
  );

  return (
    <>
      <InvoicingIMDSettingModalForm
        method="POST"
        isOpen={createIMDSettingOpen}
        onCheckout={() => setCreateIMDSettingOpen(false)}
      />

      <SharedStyles.DetailInnerWrapper>
        <SharedStyles.BareLayoutTitleWrapper>
          <PageTitle title="Inställningar - IMD" breadCrumbs={breadCrumbs} />
        </SharedStyles.BareLayoutTitleWrapper>

        <SharedStyles.DetailPageBox config={true}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>IMD-Debitering</OverviewTitle>
              <OverviewSubtitle>{ACTION}</OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <IMDSettingsTable />
        </SharedStyles.DetailPageBox>
      </SharedStyles.DetailInnerWrapper>
    </>
  );
}
