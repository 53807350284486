import styled from "styled-components";

import arrowDownIcon from "../../../../assets/svg/arrow_down_black.svg";

export const OrderDown = styled.div`
  width: 14px;
  height: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${arrowDownIcon});
`;

export const OrderUp = styled(OrderDown)`
  transform: rotate(180deg);
`;
