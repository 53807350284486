import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Sidebar } from "./styles/sidebar";

import { useHistory } from "react-router";

import { templates, STRUCTURES } from "./templates";

import { CATEGORIES } from "./utils";

import LoadingSpinner from "../../components/Loaders/OverlaySpinner";

import Table from "./Table/CustomTable";
import { setSelectedReportTab } from "../../store/newExcelReports";
import { TOAST_TYPES, addToast } from "src/store/toasts";

export const DEFAULT_PATHNAME = "all_reports";
export const BASE_PATH = "/reports/overview";

export const pathing = {
  builtTemplates: false,
  favorites: {
    title: "Favoriter",
    //should only be able to favorize templates, hence is_template=true
    params: { is_template: true, is_starred: true, performed_by: "{userId}" },
    pathId: "favorites",
    templates: [],
    canFilterBadges: true,
    emptyText: [
      "Du har inga favoritmallar",
      "Du kan favoritmarkera en rapportmall genom att klicka på stjärnan inne på en mall.",
    ],
    emptyAction: {
      text: "Gå till alla rapportmallar",
      methodStr: "updateUrl",
      method: (updateUrl) => {
        updateUrl("all_reports");
      },
    },
  },
  my_reports: {
    title: "Mina rapportmallar",
    params: { is_template: true, performed_by: "{userId}" },
    pathId: "my_reports",
    templates: [],
    canFilterBadges: true,
    emptyText: [
      "Du har inga rapportmallar",
      "Här kan du se alla dina skapade rapportmallar.",
      'För att skapa en rapportmall, välj en Pigello-mall och klicka sedan på "Spara rapport som mall".',
    ],
    emptyAction: {
      text: "Gå till alla Pigello-mallar",
      methodStr: "updateUrl",
      method: (updateUrl) => {
        updateUrl("pigello_templates");
      },
    },
  },
  earlier_reports: {
    title: "Tidigare exporter",
    params: {
      is_template: false,
      performed_by: "{userId}",
      "report__in!": [
        "brf_brfpicturereportgenerator",
        "errands_reporterrandreportgenerator",
        "errands_inspectionerrandreportgenerator",
      ],
    },
    pathId: "earlier_reports",
    templates: [],
    canFilterBadges: true,
    emptyText: [
      "Du har inga exporterade rapporter",
      "Här kan du se alla dina exporterade rapporter.",
      'Exportera en rapport genom att gå in på en rapportmall och klicka på "Exportera till"',
    ],
    emptyAction: {
      text: "Gå till alla rapportmallar",
      methodStr: "updateUrl",
      method: (updateUrl) => {
        updateUrl("all_reports");
      },
    },
  },
  shared: {
    title: "Delat med mig",
    params: {
      "performed_by!": "{userId}",
    },
    pathId: "shared",
    templates: [],
    canFilterBadges: true,
    emptyText: [
      "Du har inga delade rapporter",
      'Du har inte blivit tilldelad någon rapport. För att dela en rapport trycker du på "Delad" eller "Privat" på en rapport och väljer vilka användare som ska få tillgång.',
    ],
    emptyAction: {
      text: "Gå till tidgare exporter",
      methodStr: "updateUrl",
      method: (updateUrl) => {
        updateUrl("earlier_reports");
      },
    },
  },
  all_reports: {
    title: "Alla rapportmallar",
    params: { is_template: true, performed_by: "{userId}" },
    pathId: "all_reports",
    templates: [],
    canFilterBadges: true,
    emptyText: ["Inga rapporter", "Inga rapportmallar tillgängliga"],
    emptyAction: {
      disabled: true,
    },
  },
};

export const categoriesToStructures = {};

for (let cat of Object.keys(CATEGORIES)) {
  categoriesToStructures[cat] = [];

  if (cat === "missing") continue;

  if (pathing.hasOwnProperty(cat)) continue;

  let category = CATEGORIES[cat];

  pathing[cat] = {
    title: category.name,
    params: { performed_by: "{userId}", is_template: true },
    pathId: cat,
    templates: [],
    canFilterBadges: true,
    specialBadgeFilter: cat,
    canChooseBadgeFilter: false,
    emptyText: [
      `Du har inga ${category.name.toLowerCase()} rapporter.`,
      `För att skapa en ${category.name.toLowerCase()} rapport gå till "Alla rapportmallar" och välj en mall med den kategorin`,
    ],
    emptyAction: {
      text: "Gå till alla rapportmallar",
      methodStr: "updateUrl",
      method: (updateUrl) => {
        updateUrl("all_reports");
      },
    },
  };
}

for (let structKey of Object.keys(STRUCTURES)) {
  let struct = STRUCTURES[structKey];

  if (!categoriesToStructures.hasOwnProperty(struct.category)) {
    categoriesToStructures["missing"].push(structKey);
    continue;
  }

  categoriesToStructures[struct.category].push(structKey);
}

export const builtTemplates = templates.pigello.map((obj, tIndex) => {
  obj.base_template = true;
  obj.is_template = true;

  let category = STRUCTURES[obj.report].category;

  if (category !== "missing") {
    if (pathing[category]) {
      pathing[category].templates.push(tIndex);
    }
  }

  for (let tab of obj.showOnTabs) {
    if (!pathing[tab]) continue;
    pathing[tab].templates.push(tIndex);
  }

  return obj;
});

export default function ReportsOverview() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(false);

  const [persistantQuery, setPersistantQuery] = React.useState("");

  const user = useSelector((state) => state.app.user);

  const userRef = React.useRef(user);

  React.useEffect(() => {
    userRef.current = user;
  }, [user]);

  //Keep track on options related to current path
  const [choosenPath, setChoosenPath] = React.useState(() => {
    if (
      pathing.hasOwnProperty(location.pathname.replace(BASE_PATH + "/", ""))
    ) {
      return pathing[location.pathname.replace(BASE_PATH + "/", "")];
    }
    return pathing[DEFAULT_PATHNAME];
  });

  const currentPath = React.useMemo(() => {
    return location.pathname.replace(BASE_PATH, "");
  }, [location.pathname]);

  const updateUrl = (which) => {
    history.push({
      pathname: `${BASE_PATH}/${which}`,
    });
  };

  React.useEffect(() => {
    if (
      !pathing.hasOwnProperty(location.pathname.replace(BASE_PATH + "/", ""))
    ) {
      updateUrl(DEFAULT_PATHNAME);
    }
  }, []);

  React.useEffect(() => {
    if (currentPath.length === 0) {
      sidebarOptionClicked(DEFAULT_PATHNAME);
      return;
    }
  }, []);

  React.useEffect(() => {
    const newPathing = pathing[currentPath.replace("/", "")];

    if (!newPathing) return;

    newPathing.pathId = currentPath.replace("/", "");

    dispatch(setSelectedReportTab(newPathing.pathId));

    setChoosenPath(newPathing);
  }, [currentPath]);

  const sidebarOptionClicked = (which) => {
    if (isLoading) return;
    if (`/${which}` === currentPath) return;

    updateUrl(which);
  };

  React.useEffect(() => {
    let params = {};

    if (!userRef?.current?.id) return;

    for (let key in choosenPath.params) {
      let param = choosenPath.params[key];

      if (typeof param === "string") {
        param = param.replace("{userId}", userRef.current?.id);
      }

      params[key] = param;
    }

    params["order_by"] = "-performed_at";

    params["path_id____"] = choosenPath.pathId;

    setPersistantQuery(params);
  }, [choosenPath, user?.id]);

  const runEmptyActionMethod = (options) => {
    switch (options.methodStr) {
      case "updateUrl": {
        options.method(updateUrl);
      }
    }
  };

  const [categoriesRemoved, setCategoriesRemoved] = React.useState([]);

  return (
    <div className="flex mt-0 grow">
      <Sidebar
        clicked={sidebarOptionClicked}
        currentPath={currentPath}
        pathing={pathing}
        categoriesRemoved={categoriesRemoved}
      />
      {/* Vertical line */}
      <div
        className="border-r border-gray ml-0 mr-4  w-[10px] h-auto "
        style={{
          boxShadow: "10px 0 15px -2px #f3f4f6",
        }}
      ></div>
      {/* Content wrapper */}
      <div className="w-full flex flex-col pt-6 min-w-[0px]">
        {/* Reports container */}
        <div className="w-[99%] h-full overflow-x-auto">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Table
              persistantQueryString={persistantQuery}
              isOnSharedPage={choosenPath.pathId === "shared"}
              choosenPath={choosenPath}
              runEmptyActionMethod={runEmptyActionMethod}
              setCategoriesRemoved={setCategoriesRemoved}
            />
          )}
        </div>
      </div>
    </div>
  );
}
