import * as React from "react";
import { useTable, usePagination, useExpanded } from "react-table";
import { buildQueryString } from "../../../../store/base";

import { Header, Body } from "./BaseTableStyles";

import Pagination from "./Pagination";

import { cloneDeep, debounce } from "lodash";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

import classNames from "classnames";

import { TextButton } from "../../../Forms/Base/Buttons";

import { useStandardPagination } from "./useStandardPagination";
import { useBadgeFilters } from "./useBadgeFilters";
import { useFilters } from "./useFilters";

import {
  ArchiveBoxXMarkIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  ViewColumnsIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

import PrimaryBtn from "../../../Forms/Base/Buttons/PrimaryBtn";
import Modal from "../../../Forms/Base/Modals/Modal";
import Toggle from "../../../Toggle/Toggle";
import { retrieve } from "../../../../store/base/store/services";
import { TOAST_TYPES, addToast } from "../../../../store/toasts";
import { useDispatch } from "react-redux";
import { useExcelHandler } from "./excelHandler";
import { useHistory } from "react-router-dom";
import { generateSkeletonPage } from "./SkeletonTableItem";

function randomString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default function BaseTable({
  columns,
  constants,
  persistantQuery,
  rowClassName = "",
  hideHeaders = false,
  hideSearch = false,
  hideExport = false,
  hideFilters = false,
  hideBadges = false,
  hideHiddenColumns = false,
  isBare = false,
  filters,
  title,
  onRowClicked,
  onRowClickedWithDetail,
  updateLocalQuery,
  badges,
  tableId,
  initialOrderBy = undefined,
  isRowExpandable = false,
  getContentForExpandedRow,
  ignoreLocalStorage = false,
  disableBadgeFilterCounts,
  checkRowHighlighted,
  onRowSelected,
  reportForExport,
}) {
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();

  if (isBare) {
    hideSearch = true;
    hideExport = true;
    hideFilters = true;
    hideBadges = true;
    hideHiddenColumns = true;
  }

  if (hideBadges) {
    disableBadgeFilterCounts = true;
  }

  if (ignoreLocalStorage) tableId += randomString(8);

  const history = useHistory();

  const handleOnRowClicked = (obj, row) => {
    if (!onRowClicked && !onRowClickedWithDetail) return;
    if (onRowClicked) onRowClicked(obj, row);
    else if (onRowClickedWithDetail)
      history.push(onRowClickedWithDetail(obj, row));
  };

  const goToReport = () => {
    history.push(`/reports/table-view/${reportForExport}`);
  };

  const getInitialOrderBy = (initial) => {
    if (ignoreLocalStorage) return initial;
    let stringifiedOrderBy = localStorage.getItem(`TABLE_ORDER_BY_${tableId}`);

    if (!stringifiedOrderBy || stringifiedOrderBy.length === 0) return initial;

    let parsed;

    try {
      parsed = JSON.parse(stringifiedOrderBy);
    } catch (err) {
      return initial;
    }

    if (parsed.length === 0) return initial;

    return parsed;
  };

  const [currentOrderBy, setCurrentOrderBy] = React.useState(
    getInitialOrderBy(initialOrderBy || [])
  );

  const getInitialHiddenColumns = () => {
    let stringifiedHiddenColumns = localStorage.getItem(
      `TABLE_HIDDEN_COLUMNS_${tableId}`
    );

    if (!stringifiedHiddenColumns || stringifiedHiddenColumns.length === 0)
      return [];

    let parsed;

    try {
      parsed = JSON.parse(stringifiedHiddenColumns);
    } catch (err) {
      return [];
    }

    return parsed;
  };

  const [internalHiddenColumns, setInternalHiddenColumns] = React.useState(
    getInitialHiddenColumns()
  );

  const getInitialPageNumber = () => {
    let currentPageNumber = localStorage.getItem(
      `TABLE_CURRENT_PAGE_NUMBER_${tableId}`
    );
    if (!currentPageNumber) return 0;

    let num = 0;

    try {
      num = parseInt(currentPageNumber);
    } catch (err) {
      num = 0;
    }

    return num;
  };

  const initialPageNumber = React.useMemo(() => getInitialPageNumber(), []);

  const [selectedRows, setSelectedRows] = React.useState({});

  const initialState = {
    pageSize: 25,
    pageIndex: initialPageNumber,
    hiddenColumns: internalHiddenColumns,
  };

  const [isManagingColumns, setIsManagingColumns] = React.useState(false);

  const [currentSearchString, setCurrentSearchString] = React.useState("");
  //Seperate these search string, current for debounce
  //using for the actual search
  const [usingSearchString, setUsingSearchString] = React.useState("");

  React.useEffect(() => {
    if (ignoreLocalStorage) return;
    let storedSearchString = localStorage.getItem(
      `TABLE_USING_SEARCH_${tableId}`
    );

    if (!storedSearchString || storedSearchString.length === 0) return;

    setCurrentSearchString(storedSearchString);
    setUsingSearchString(storedSearchString);
  }, []);

  React.useEffect(() => {
    if (ignoreLocalStorage) return;
    localStorage.setItem(`TABLE_USING_SEARCH_${tableId}`, usingSearchString);
  }, [usingSearchString]);

  const [pageQuerySize, setPageQuerySize] = React.useState(
    initialState.pageSize
  );

  const [currentBackendPage, setCurrentBackendPage] = React.useState(
    initialPageNumber + 1
  ); //+1 for backend query

  const getInitialQueryObject = () => {
    return {
      _page: currentBackendPage,
      _page_size: initialState.pageSize,
      ...persistantQuery,
    };
  };

  const [queryString, setQueryString] = React.useState("");

  const [queryObject, setQueryObject] = React.useState(() => {
    let obj = getInitialQueryObject();
    return obj;
  });
  const [lastFilterQuery, setLastFilterQuery] = React.useState("");
  const [lastBadgeQuery, setLastBadgeQuery] = React.useState("");

  const [isReadyForFetch, setIsReadyForFetch] = React.useState(false);

  const [badgesChecked, setBadgesChecked] = React.useState(false);
  const [filtersChecked, setFiltersChecked] = React.useState(false);

  React.useEffect(() => {
    if (isReadyForFetch) return;

    if (badgesChecked && filtersChecked) setIsReadyForFetch(true);
  }, [badgesChecked, filtersChecked]);

  const { filterQuery, filterElement, activeFilterElements, removeAllFilters } =
    useFilters({
      filterInstructions: filters,
      tableId,
      filtersChecked,
      setFiltersChecked,
      ignoreLocalStorage,
    });

  const { badgeFilters, badgeQuery, resetBadgeFilter } = useBadgeFilters({
    constants,
    badges,
    tableId,
    badgesChecked,
    setBadgesChecked,
    disableBadgeFilterCounts,
    ignoreLocalStorage,
    persistantQuery,
  });

  const resetAllFilters = () => {
    setCurrentSearchString("");
    removeAllFilters();
    resetBadgeFilter();
  };

  const usingAnyFilters = React.useMemo(() => {
    if (usingSearchString.length !== 0) return true;
    if (Object.keys(badgeQuery).length !== 0) return true;
    if (Object.keys(filterQuery).length !== 0) return true;

    return false;
  }, [badgeQuery, filterQuery, usingSearchString]);

  // console.log(tableId, "persistantQuery", persistantQuery);

  React.useEffect(() => {
    if (!isReadyForFetch) return;

    let searchObject = {};
    let sortObject = {};

    if (usingSearchString.length !== 0) {
      searchObject.search = usingSearchString;
    }

    if (currentOrderBy.length !== 0) {
      sortObject.order_by = currentOrderBy;
    }

    let useBackendPage = currentBackendPage + 0;

    //TODO: Optimize (if possible add strings instead of rebuilding query string for queryBojectX)
    let badgeQueryString = buildQueryString(badgeQuery);
    let filterQueryString = buildQueryString(filterQuery);

    if (
      badgeQueryString !== lastBadgeQuery ||
      filterQueryString !== lastFilterQuery
    ) {
      if (!isLoadingInitialQuery) {
        useBackendPage = 1;

        setCurrentBackendPage(useBackendPage);
      }

      setLastBadgeQuery(badgeQueryString);
      setLastFilterQuery(filterQueryString);
    }

    let _queryObject = {
      _page: useBackendPage,
      _page_size: pageQuerySize,
      ...persistantQuery,
      ...badgeQuery,
      ...searchObject,
      ...filterQuery,
      ...sortObject,
    };

    let _queryString = buildQueryString(_queryObject);

    if (_queryString === queryString) {
      //Don't do anything, nothing has updated
      return;
    }

    setQueryString(buildQueryString(_queryObject));
    setQueryObject(_queryObject);

    updateLocalQuery && updateLocalQuery(_queryObject);
  }, [
    persistantQuery,
    pageQuerySize,
    currentBackendPage,
    badgeQuery,
    usingSearchString,
    filterQuery,
    currentOrderBy,
    isReadyForFetch,
  ]);

  const [hasLoadedInitialQuery, setHasLoadedInitialQuery] =
    React.useState(false);

  const { items, count, isLoading, error } = useStandardPagination({
    queryString,
    constants,
    isReadyForFetch,
  });

  let results = items ? items : [];

  let pageCount = count ? Math.ceil(count / pageQuerySize) : 0;

  const [useColumns, setUseColumns] = React.useState(columns);

  const isLoadingInitialQuery = items.length === 0 && !hasLoadedInitialQuery;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    setHiddenColumns,
    state: { pageIndex, pageSize },
    getColumn,
  } = useTable(
    {
      columns: useColumns,
      data: results,
      initialState,
      manualPagination: true,
      pageCount,
    },
    useExpanded,
    usePagination
  );

  React.useEffect(() => {
    if (hasLoadedInitialQuery) return;
    if (isLoading) return;
    setHasLoadedInitialQuery(true);

    //has just loaded initial query here, this will only run once.
    if (error && initialPageNumber !== 0) {
      gotoPage(0);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (ignoreLocalStorage) return;

    if (isLoadingInitialQuery && pageIndex !== initialPageNumber) {
      gotoPage(initialPageNumber);
      return;
    }

    localStorage.setItem(`TABLE_CURRENT_PAGE_NUMBER_${tableId}`, pageIndex);
  }, [pageIndex]);

  const getFullColumnData = (accessor) => {
    if (typeof accessor === "function") accessor = accessor();

    const found = columns.find((obj) => {
      return obj.accessor === accessor || obj.id === accessor;
    });

    return found;
  };

  React.useEffect(() => {
    if (pageIndex + 1 !== currentBackendPage) gotoPage(currentBackendPage - 1);
  }, [currentBackendPage]);

  React.useEffect(() => {
    let removeOrdering = [];

    for (let accessor of internalHiddenColumns) {
      if (currentOrderBy.includes(accessor)) {
        removeOrdering.push(accessor);
      } else if (currentOrderBy.includes(`-${accessor}`)) {
        removeOrdering.push(`-${accessor}`);
      }
    }

    if (removeOrdering.length !== 0) {
      let clone = currentOrderBy.slice();

      clone = clone.filter((str) => !removeOrdering.includes(str));

      setCurrentOrderBy(clone);
    }

    setHiddenColumns(internalHiddenColumns);

    if (ignoreLocalStorage) return;
    localStorage.setItem(
      `TABLE_HIDDEN_COLUMNS_${tableId}`,
      JSON.stringify(internalHiddenColumns)
    );
  }, [internalHiddenColumns]);

  const headerClicked = (col) => {
    if (col.disableSortBy) return;

    const data = columns.find((obj) => obj.Header === col.Header);
    if (!data) return;
    if (!data.accessor) return;

    let clone = currentOrderBy.slice();

    if (clone.includes(data.accessor)) {
      clone[clone.indexOf(data.accessor)] = `-${data.accessor}`;
    } else if (clone.includes(`-${data.accessor}`)) {
      clone.splice(clone.indexOf(`-${data.accessor}`), 1);
    } else {
      clone.push(data.accessor);
    }

    localStorage.setItem(`TABLE_ORDER_BY_${tableId}`, JSON.stringify(clone));

    setCurrentOrderBy(clone);
  };

  React.useEffect(() => {
    setCurrentBackendPage(pageIndex + 1);
  }, [pageIndex]);

  const doSearch = (str) => {
    if (str.length < 3 && str.length !== 0) return;

    if (pageIndex !== 0 && !isLoadingInitialQuery) {
      gotoPage(0);
    }

    setUsingSearchString(str);
  };

  const debounceSearch = React.useCallback(debounce(doSearch, 500), [
    pageIndex,
    pageQuerySize,
    currentBackendPage,
    isLoadingInitialQuery,
  ]);

  React.useEffect(() => {
    debounceSearch(currentSearchString);
  }, [currentSearchString]);

  const onPageSizeUpdated = (newPageSize) => {
    setPageSize(newPageSize);
    setPageQuerySize(newPageSize);
  };

  const { convertAndDownloadExcel } = useExcelHandler({
    hiddenColumns: internalHiddenColumns,
    columns,
    title,
  });

  const [isExporting, setIsExporting] = React.useState(false);

  const exportList = async () => {
    let clone = cloneDeep(queryObject);
    delete clone._page;
    delete clone._page_size;

    let excelQueryString = buildQueryString(clone);

    if (items.length === 0) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Tomt resultat",
          description:
            "Exporterar inte tabellen för att det inte finns någon data att exportera. Ändra filteringen och försök igen",
        })
      );

      return;
    }

    if (isExporting) return;
    setIsExporting(true);

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Påbörjar export",
        description: "Vänligen vänta",
      })
    );

    await new Promise((resolve) => {
      retrieve({ url: `${constants.LIST_URL}?${excelQueryString}` })
        .then(async (res) => {
          await convertAndDownloadExcel({
            data: res.data,
          });

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Exporten lyckades",
              description: "Exporten laddas ner automatiskt",
            })
          );

          resolve();
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Exporten kunde inte utföras",
            })
          );
          resolve();
        });
    });

    setIsExporting(false);
  };

  const [expandedRows, setExpandedRows] = React.useState([]);
  const expandedRowsRef = React.useRef(expandedRows);

  React.useEffect(() => {
    expandedRowsRef.current = expandedRows;
  }, [expandedRows]);

  const onRowExpand = (row) => {
    let clone = expandedRowsRef.current.slice();

    if (clone.includes(row.index)) {
      clone.splice(clone.indexOf(row.index), 1);
    } else {
      clone.push(row.index);
    }

    setExpandedRows(clone);
  };

  let customColumnsAmount = onRowSelected ? 1 : 0;
  if (isRowExpandable) customColumnsAmount++;

  React.useEffect(() => {
    if (!isRowExpandable) {
      setUseColumns(columns);
      return;
    } else {
      if (useColumns.length === 0) return;

      if (useColumns[0].id === "__expander") return;

      let newCols = useColumns.slice();

      newCols = [
        {
          Header: "",
          id: "__expander",
          Cell: ({ row }) => {
            return (
              <span {...row.getToggleRowExpandedProps()}>
                <div
                  onClick={(evt) => {
                    onRowExpand(row);
                  }}
                  className="bg-slate-100 shadow rounded p-2"
                >
                  <ChevronRightIcon
                    className={classNames(
                      "transition-transform",
                      row.isExpanded ? "rotate-90" : "rotate-0"
                    )}
                    width={16}
                  />
                </div>
              </span>
            );
          },
        },
        ...newCols,
      ];

      setUseColumns(newCols);
    }
  }, [columns, expandedRowsRef.current]);

  const tableWrapperRef = React.useRef();
  const [tableWidth, setTableWidth] = React.useState(0);

  React.useEffect(() => {
    if (!tableWrapperRef.current) return;

    let { width } = tableWrapperRef.current.getBoundingClientRect();

    setTableWidth(width);
  }, [tableWrapperRef.current, windowWidth]);

  const [skeletonPageInfo, setSkeletonPageInfo] = React.useState({
    pageSize: pageQuerySize,
    columnLength: 0,
    built: false,
    element: null,
  });

  let skeletonPage;

  if (isLoadingInitialQuery) {
    if (
      !skeletonPageInfo.built ||
      columns.length + customColumnsAmount !== skeletonPageInfo.columnLength
    ) {
      skeletonPage = generateSkeletonPage(
        columns,
        pageQuerySize,
        customColumnsAmount
      );
      setSkeletonPageInfo({
        pageSize: pageQuerySize,
        columnLength: columns.length + customColumnsAmount,
        built: true,
        element: skeletonPage,
      });
    } else {
      skeletonPage = skeletonPageInfo.element;
    }
  }

  return (
    <>
      <div className="w-full relative">
        {/* fontSize: "0.75rem", lineHeight: "1rem" = tailwind "text-xs" */}
        <TextButton
          clicked={resetAllFilters}
          extraStyle={{
            fontSize: "0.75rem",
            lineHeight: "1rem",
            opacity: usingAnyFilters && !isBare ? 1 : 0,
            cursor: usingAnyFilters && !isBare ? "pointer" : "default",
          }}
          title="Återställ filtrering"
        />
        <div className="flex items-center flex-wrap space-x-2 mt-[-0.5rem]">
          {/* Use a unique table id when hiding serach or something else. Otherwise they might be loaded from local storage with no way of managing them */}
          {!hideSearch && (
            <div className="border border-solid border-gray-300 rounded mt-2 relative overflow-hidden flex items-center ring-1 ring-transparent focus-within:ring-blue-400">
              <div
                className="px-2.5 py-2.5 h-full bg-primaryblue/10"
                onClick={({ target }) => {
                  target.parentElement.children[1].focus();
                }}
              >
                <MagnifyingGlassIcon
                  width={16}
                  className="pointer-events-none"
                />
              </div>
              <input
                value={currentSearchString}
                onChange={({ target: { value } }) =>
                  setCurrentSearchString(value)
                }
                placeholder={`Sök ${
                  title ? `bland ${title?.toLowerCase()}` : "i listan"
                }...`}
                className="min-w-[300px] p-2 bg-gray-50 ml-0 text-sm mr-0 rounded outline-none"
              />
            </div>
          )}

          {!hideExport && (
            <div
              className="border border-solid border-gray-300 rounded relative flex items-center p-2.5 mt-2 cursor-pointer transition-colors hover:bg-gray-200"
              onClick={exportList}
            >
              <ArrowDownTrayIcon className="" width={16} />
            </div>
          )}

          {!hideHiddenColumns && (
            <div
              className="relative border border-solid border-gray-300 rounded flex items-center justify-centeå p-2.5 mt-2 cursor-pointer transition-colors hover:bg-gray-200"
              onClick={() => setIsManagingColumns(true)}
            >
              <ViewColumnsIcon className="opacity-100" width={16} />
            </div>
          )}

          {!hideFilters && (
            <>
              {filterElement}
              {activeFilterElements}
            </>
          )}
        </div>
        {!hideBadges ? (
          //mb-4
          <div className="flex items-center mb-3 space-x-2 flex-wrap mt-1">
            {badgeFilters}
          </div>
        ) : (
          <div className="mb-2 mt-1"></div>
        )}

        <div className="w-full">
          <div
            className={classNames(
              "w-full overflow-x-auto rounded-tl rounded-tr border border-solid border-slate-200 border-l border-r border-t",
              pageCount < 2 && "rounded-bl rounded-br"
            )}
            ref={tableWrapperRef}
          >
            <table
              {...getTableProps}
              className="table-auto w-full text-sm text-left text-slate-600"
            >
              <Header
                onHeaderClick={headerClicked}
                orderBy={currentOrderBy}
                headerGroups={headerGroups}
                hideHeaders={hideHeaders}
                onRowSelected={onRowSelected}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                results={results}
                getFullColumnData={getFullColumnData}
              ></Header>
              {isLoading && !isLoadingInitialQuery && <OverlaySpinner />}
              {isLoadingInitialQuery ? (
                skeletonPage
              ) : (
                <Body
                  props={getTableBodyProps()}
                  prepareRow={prepareRow}
                  page={page}
                  rowClassName={rowClassName}
                  onRowClicked={handleOnRowClicked}
                  getContentForExpandedRow={getContentForExpandedRow}
                  expandedRows={expandedRows}
                  onRowExpand={onRowExpand}
                  tableWidth={tableWidth}
                  checkRowHighlighted={checkRowHighlighted}
                  onRowSelected={onRowSelected}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  getFullColumnData={getFullColumnData}
                >
                  {(count === 0 || items?.length === 0) &&
                    usingSearchString.length === 0 &&
                    Object.keys(filterQuery).length === 0 &&
                    Object.keys(badgeQuery).length === 0 && (
                      <tr>
                        <td colSpan={columns?.length > 8 ? 8 : columns?.length}>
                          <div className="flex flex-col justify-center items-center p-6 text-center font-medium text-xs">
                            <ArchiveBoxXMarkIcon width={32} className="mb-2" />
                            Det finns ingen data att visa
                          </div>
                        </td>
                      </tr>
                    )}
                  {(count === 0 || items?.length === 0) &&
                    (usingSearchString.length !== 0 ||
                      Object.keys(filterQuery).length !== 0 ||
                      Object.keys(badgeQuery).length !== 0) && (
                      <tr className="h-44">
                        <td colSpan={columns?.length > 8 ? 8 : columns?.length}>
                          <div className="flex flex-col justify-center items-center p-6 text-center font-medium text-xs absolute left-[38.5%]">
                            <ArchiveBoxXMarkIcon width={32} className="mb-2" />
                            <p className="mb-2">
                              Hittade inga resultat för din filtrering
                            </p>
                            {!isBare && (
                              <PrimaryBtn onClick={resetAllFilters}>
                                Återställ filtrering
                              </PrimaryBtn>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                </Body>
              )}
            </table>
          </div>
          {items && items?.length !== 0 && (
            <Pagination
              {...{
                onNext: nextPage,
                onPrevious: previousPage,
                pageCount,
                pageSize,
                currentPage: pageIndex + 1,
                onSetPage: (newPage) => gotoPage(newPage - 1),
                onPageSizeUpdated,
                canNext: canNextPage,
                canPrevious: canPreviousPage,
              }}
            />
          )}
        </div>
      </div>
      {isManagingColumns && (
        <Modal
          title="Visa/dölj kolumner"
          closeFunction={() => setIsManagingColumns(false)}
        >
          <p className="text-sm">
            Välj vilka kolumner som ska visas i tabellen.
          </p>
          <div className="max-w-[50%] space-y-2 bg-white rounded shadow-md p-2">
            {columns.map((col, index) => {
              let accessor = col.accessor || col.id;

              let value = !internalHiddenColumns.includes(accessor);

              return (
                <div
                  className="pt-2 border-t border-gray-300 first-of-type:border-t-0"
                  key={accessor}
                >
                  <Toggle
                    title={col.Header}
                    value={value}
                    onToggle={() => {
                      let clone = cloneDeep(internalHiddenColumns);
                      if (value) {
                        //enabled, make disabled
                        clone.push(accessor);
                      } else {
                        clone.splice(clone.indexOf(accessor), 1);
                      }
                      setInternalHiddenColumns(clone);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </Modal>
      )}
    </>
  );
}
