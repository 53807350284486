import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  useBrfCompany,
  useBrfCompanyForm,
  update,
  constants,
} from "../../../store/brfCompanies";
import FinancialDocForm from "../../../components/Forms/BrfCompany/FinancialDocForm";
import Pdf from "../../../components/Pdf/Pdf";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  setActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import Modal from "src/components/Forms/Base/Modals/Modal";
import M2mField from "src/components/Forms/Base/Fields/M2mField";
import MediaField from "src/components/Forms/Base/Fields/MediaField";
import DateSelect from "src/components/Forms/Base/Fields/DateSelect";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";

export default function BrfCompanyDocuments() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { brfCompanyId } = useParams();
  const [brfCompany] = useBrfCompany(brfCompanyId);

  const [createFinancialOpen, setCreateFinancialOpen] = React.useState(false);
  const [handleDocsOpen, setHandleDocsOpen] = React.useState(false);
  const [saveDocsLoading, setSaveDocsLoading] = React.useState(false);

  const rulesAndDocs = useFormInstanceField({
    storeName,
    fieldKey: "rule_documents",
  });

  useBrfCompanyForm("PATCH", brfCompanyId);

  React.useEffect(() => {
    if (brfCompany) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: brfCompany,
        })
      );
    }
  }, [brfCompany]);

  const handleSaveDocuments = () => {
    setSaveDocsLoading(true);
    const cleanAttachmentsFormInstance = {
      id: brfCompany,
      rule_documents: rulesAndDocs,
    };

    dispatch(
      update({
        id: brfCompanyId,
        forceData: cleanAttachmentsFormInstance,
        successCallback: () => {
          setSaveDocsLoading(false);
          setHandleDocsOpen(false);
        },
        errorCallback: () => {
          setSaveDocsLoading(false);
        },
      })
    );
  };

  return (
    <>
      {handleDocsOpen && (
        <Modal
          title="Hantera regler & dokument"
          closeFunction={() => setHandleDocsOpen(false)}
          onAccept={handleSaveDocuments}
          acceptTitle="Spara"
        >
          {saveDocsLoading && <OverlaySpinner />}
          <M2mField
            storeName={storeName}
            method="PATCH"
            fieldKey="rule_documents"
            instructionsKey="rule_documents"
            title="Regler & Dokument"
            instanceTitle="Dokument"
          >
            {(parentPath, instructionsPath) => (
              <>
                <MediaField
                  title="Dokument"
                  method="PATCH"
                  fieldKey={`${parentPath}.file`}
                  instructionsKey={`${instructionsPath}.file`}
                  storeName={storeName}
                  allowedFormats={[".pdf"]}
                />
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <DateSelect
                    title="Gäller från"
                    method="PATCH"
                    fieldKey={`${parentPath}.actual_date`}
                    instructionsKey={`${instructionsPath}.actual_date`}
                    storeName={storeName}
                  />
                </div>
              </>
            )}
          </M2mField>
        </Modal>
      )}

      <DetailInnerWrapper>
        <FinancialDocForm
          method="PATCH"
          id={brfCompanyId}
          isOpen={createFinancialOpen}
          onCheckout={() => setCreateFinancialOpen(false)}
        />

        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "70%", maxWidth: "70%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Ekonomisk plan</OverviewTitle>

              <PrimaryButton
                clicked={() => setCreateFinancialOpen(true)}
                title={
                  brfCompany?.financial_plan
                    ? "Uppdatera ekonomisk plan"
                    : "Lägg till ekonomisk plan"
                }
              />
            </OverviewTitleWrapper>

            <>
              {!brfCompany?.financial_plan ? (
                <div
                  style={{
                    padding: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Ingen ekonomisk plan har laddats upp än
                </div>
              ) : (
                <>
                  <div
                    style={{ fontSize: 14, fontWeight: 400, marginBottom: 12 }}
                  >
                    Datum för ekonomisk plan: {brfCompany?.date_financial_plan}
                  </div>
                  <Pdf url={brfCompany?.financial_plan.get} />
                </>
              )}
            </>
          </DetailPageBox>

          <DetailPageBox flexStart style={{ width: "30%", minWidth: "300px" }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Regler & Dokument</OverviewTitle>
                <OverviewSubtitle>
                  <TextButton
                    iconType="edit"
                    title="Hantera dokument"
                    iconPlacement="right"
                    clicked={() => setHandleDocsOpen(true)}
                  />
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {rulesAndDocs?.map((rd) => {
              return (
                <InnerBox
                  className="cursor-pointer overflow-hidden mb-1"
                  onClick={() => window.open(rd.file.get, "_blank")}
                  key={rd.id}
                >
                  <div className="text-sm">{rd.actual_date}</div>
                  <div className="font-medium">{rd.str_representation}</div>
                </InnerBox>
              );
            })}
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
