export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTAACCOUNTSRECEIVABLE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTAACCOUNTSRECEIVABLE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FORM_IN_PROGRESS_BILLECTAACCOUNTSRECEIVABLE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTAACCOUNTSRECEIVABLE",
  DESTROY_FORM: "DESTROY_FORM_BILLECTAACCOUNTSRECEIVABLE",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTAACCOUNTSRECEIVABLE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTAACCOUNTSRECEIVABLE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTAACCOUNTSRECEIVABLE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTAACCOUNTSRECEIVABLE",
  RESET_STATE: "RESET_STATE_BILLECTAACCOUNTSRECEIVABLE",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTAACCOUNTSRECEIVABLE",

  SET_SEARCH_INVOICES: "SET_SEARCH_INVOICES_BILLETAACCTOUNTSRECEIVABLE",
  SET_ACCOUNTS_RECEIVABLE_BY_DATE:
    "SET_ACCOUNTS_RECEIVABLE_BY_DATE_BILLECTAACCOUNTSRECEIVABLE",
  CLEAR_DATA: "CLEAR_DATA_BILLETACCOUNTSRECEIVABLE",

  STORE_NAME: "billectaAccountsReceivable",
};
