import * as React from "react";
import { useTransition } from "@react-spring/web";
import * as SC from "./styles";

export default function SideModal({
  closeFunction,
  isOpen,
  extraStyle,
  children,
  extraBackdropStyle = {},
}) {
  const backdropRef = React.useRef();

  const modalTransition = useTransition(!!isOpen, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateX(100%)` },
    enter: { opacity: 1, transform: `translateX(0)` },
    leave: { opacity: 0 },
  });

  const handleBackdropClicked = (e) => {
    e.stopPropagation();
    if (backdropRef?.current === e.target) {
      closeFunction();
    }
  };

  return modalTransition(
    (styles, open) =>
      open && (
        <>
          <SC.StandardBackDrop
            onClick={handleBackdropClicked}
            ref={backdropRef}
            style={{
              opacity: styles.opacity,
              justifyContent: "flex-end",
              ...extraBackdropStyle,
            }}
          >
            <SC.SideModal style={{ ...styles, ...extraStyle, opacity: 1 }}>
              {children}
            </SC.SideModal>
          </SC.StandardBackDrop>
        </>
      )
  );
}
