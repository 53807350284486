import * as React from "react";
// style, design

import Table from "../../Billecta/Table/BasicTable";

import moment from "moment";
import { cloneDeep } from "lodash";
import { toMoneyString } from "../../utils/stringUtils";

export default function PaymentGroupSubtable({
  paymentGroup,
  cutWithQuota = 1,
  cutAgainstTodayDebt,
}) {
  const boldOrNotBold = (value, row) => {
    if (row.original?._finalRow) {
      return <b>{value}</b>;
    }

    return value;
  };

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Period start",
        accessor: "period_start",
        Cell: ({ value, row }) => <div>{boldOrNotBold(value, row)}</div>,
      },
      {
        Header: "Period slut",
        accessor: "period_end",
        Cell: ({ value, row }) => <div>{boldOrNotBold(value, row)}</div>,
      },
      {
        Header: "Inbet. / Utbet.",
        id: "_total_quota",
        Cell: ({ row }) => (
          <div>
            {boldOrNotBold(
              row.original.out_loan_total
                ? `${(
                    (row.original.total * 100) /
                    row.original.out_loan_total
                  ).toFixed(2)} %`
                : "-",
              row
            )}
          </div>
        ),
      },
      {
        Header: "Inbet. Totalt",
        accessor: "total",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Utbet. Lån - Totalt",
        accessor: "out_loan_total",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Inbet. Lån - Kapitaltillskott",
        accessor: "loan_capital_contribution",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Inbet. Lån - Ej kapitaltillskott",
        accessor: "loan_non_capital_contribution",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Inbet. Lån - Ränteavgift",
        accessor: "loan_interest_fee",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Inbet. Lån - Låst ränta, avgift",
        accessor: "loan_firm_interest_fee",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Inbet. Ej lån",
        accessor: "non_loan",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Utbet. Lån - Amortering",
        accessor: "out_loan_amortization",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Utbet. Lån - Ränteavgift",
        accessor: "out_loan_interest",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
      {
        Header: "Utbet. Lån - Låst ränta, avgift",
        accessor: "out_loan_firm_interest_fee",
        Cell: ({ value, row }) => (
          <div>{boldOrNotBold(toMoneyString(value, true, 2), row)}</div>
        ),
      },
    ];
  }, []);

  let earliestStart = null;
  let latestEnd = null;

  let total_loan_capital = 0;
  let total_loan_non_capital = 0;
  let total_interest = 0;
  let total_firm_interest_fee = 0;
  let total_non_loan = 0;
  let total = 0;

  let out_loan_amort = 0;
  let out_loan_interest = 0;
  let out_loan_firm_interest_fee = 0;
  let out_loan_total = 0;

  const today = moment();
  today.set({ days: 1 });

  let data = cloneDeep(paymentGroup.fee_previews ?? []);
  data.forEach((item) => {
    const start = moment(item.period_start);
    const end = moment(item.period_end);
    if (start && (!earliestStart || earliestStart > start)) {
      earliestStart = start;
    }
    if (end && (!latestEnd || latestEnd < end)) {
      latestEnd = end;
    }

    let _hasConformedToQuota = 0;

    if (cutAgainstTodayDebt != null && start > today && item.total) {
      // a period to debit in the future - cut off based on the debt
      if (cutAgainstTodayDebt < item.total * cutWithQuota) {
        // paid too much, cut it off to the actual debt you have left
        const actualToPay = cutAgainstTodayDebt >= 0 ? cutAgainstTodayDebt : 0;
        item.loan_capital_contribution =
          actualToPay * (item.loan_capital_contribution / item.total);
        item.loan_non_capital_contribution =
          actualToPay * (item.loan_non_capital_contribution / item.total);
        item.loan_interest_fee =
          actualToPay * (item.loan_interest_fee / item.total);
        item.loan_firm_interest_fee =
          actualToPay * (item.loan_firm_interest_fee / item.total);
        item.non_loan = actualToPay * (item.non_loan / item.total);

        item.total = actualToPay;
        cutAgainstTodayDebt -= item.total;

        _hasConformedToQuota = true;
      } else {
        cutAgainstTodayDebt -= item.total * cutWithQuota;
      }
    }

    if (!_hasConformedToQuota) {
      item.loan_capital_contribution *= cutWithQuota;
      item.loan_non_capital_contribution *= cutWithQuota;
      item.loan_interest_fee *= cutWithQuota;
      item.loan_firm_interest_fee *= cutWithQuota;
      item.non_loan *= cutWithQuota;
      item.total *= cutWithQuota;
    }

    // these are always cut and never cut before
    item.out_loan_amortization *= cutWithQuota;
    item.out_loan_interest *= cutWithQuota;
    item.out_loan_firm_interest_fee *= cutWithQuota;
    item.out_loan_total *= cutWithQuota;

    total_loan_capital += item.loan_capital_contribution;
    total_loan_non_capital += item.loan_non_capital_contribution;
    total_interest += item.loan_interest_fee;
    total_firm_interest_fee += item.loan_firm_interest_fee;
    total_non_loan += item.non_loan;
    total += item.total;

    out_loan_amort += item.out_loan_amortization;
    out_loan_interest += item.out_loan_interest;
    out_loan_firm_interest_fee += item.out_loan_firm_interest_fee;
    out_loan_total += item.out_loan_total;
  });

  if (earliestStart != null && latestEnd != null) {
    data.push({
      period_start: earliestStart.format("YYYY-MM-DD"),
      period_end: latestEnd.format("YYYY-MM-DD"),
      loan_capital_contribution: total_loan_capital,
      loan_non_capital_contribution: total_loan_non_capital,
      loan_interest_fee: total_interest,
      loan_firm_interest_fee: total_firm_interest_fee,
      non_loan: total_non_loan,
      total: total,
      out_loan_amortization: out_loan_amort,
      out_loan_interest: out_loan_interest,
      out_loan_firm_interest_fee: out_loan_firm_interest_fee,
      out_loan_total: out_loan_total,
      _finalRow: true,
    });
  }

  return (
    <Table
      columns={tableColumns}
      data={data}
      hideSearch
      forceInitialPageSize={data.length}
      extraStyles={{ marginTop: 0 }}
    />
  );
}
