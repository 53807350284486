import * as React from "react";
export default function ContractType() {
  return (
    <>
      Existerande avtal är avtal som är signerade sedan tidigare och enbart
      läggs in i dokumentationssyfte.
      <br />
    </>
  );
}
