import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { uid } from "uid";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../store/base";

import {
  constants,
  update as updateCompanyInvoicing,
} from "../../../store/invoicingCompany";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Table from "../../Billecta/Table/BasicTable";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { BooleanLabel } from "../../Displays";
import { PrimaryButton, TextButton } from "../../Forms/Base/Buttons";
import { DoubleFieldWrapper } from "../../Forms/Base/Chapters/styles";
import { RadioGroup, TextInput } from "../../Forms/Base/Fields";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { InnerBox } from "../../sharedStyles";

export default function HandlePaymentMethods({ companyInvoiceConfig }) {
  const dispatch = useDispatch();

  const method = "PATCH";
  const storeName = constants.STORE_NAME;

  const [handleCostId, setHandleCostId] = React.useState(null);
  const [loadingPayments, setLoadingPayments] = React.useState(false);

  const formCosts = useFormInstanceField({
    storeName,
    fieldKey: "companypaymentmethod_set",
  });
  const costErrors = useFormError({
    storeName,
    fieldKey: "companypaymentmethod_set",
  });

  const tableCosts = React.useMemo(() => formCosts || [], [formCosts]);

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Primär metod",
        accessor: "primary",
        Cell: ({ value }) => (
          <BooleanLabel
            value={value}
            onLabel={"Primär"}
            offLabel={"Ej primär (alternativ)"}
          />
        ),
      },
      {
        Header: "Typ",
        id: "kind",
        Cell: ({ row }) => {
          const value = row.original.is_bankgiro
            ? "Bankgiro"
            : row.original.is_plusgiro
            ? "Plusgiro"
            : row.original.is_bank_account
            ? "Bankkonto"
            : row.original.is_iban
            ? "IBAN"
            : "Okänt";

          return <div>{value}</div>;
        },
      },
      {
        Header: "Bankgiro",
        accessor: "bankgiro",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Plusgiro",
        accessor: "plusgiro",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Clearingnummer",
        accessor: "clearing_number",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Bankkonto",
        accessor: "bank_account",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "IBAN",
        accessor: "iban",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "BIC",
        accessor: "bic",
        Cell: ({ value }) => <div>{value}</div>,
      },
    ];
  }, [formCosts]);

  const onUpdate = () => {
    setEditActive(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostId(null);
  };

  const onRemove = (id) => {
    const costsCopy = cloneDeep(formCosts);

    const idx = costsCopy.findIndex((c) => (c.id || c._tempId) === id);

    costsCopy.splice(idx, 1);

    setHandleCostId(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          companypaymentmethod_set: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );
  };

  const handleEditCost = (row) => {
    setHandleCostId(row.original.id || row.original._tempId);
  };

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  const onUpdateSuccess = (_, returnedData) => {
    setLoadingPayments(false);

    dispatch(
      setActiveFormInstance({
        storeName,
        data: returnedData,
      })
    );
  };
  const onUpdateError = () => {
    setLoadingPayments(false);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!!dataClone.companypaymentmethod_set?.length) {
      dataClone.companypaymentmethod_set.forEach((c) => {
        c.config = { id: companyInvoiceConfig.id };
      });
    }

    return dataClone;
  };

  const updateCosts = () => {
    setLoadingPayments(true);
    dispatch(
      updateCompanyInvoicing({
        id: companyInvoiceConfig.id,
        successCallback: onUpdateSuccess,
        errorCallback: onUpdateError,
        preProcess: preProcess,
      })
    );
  };

  const canUpdate = checkCanUpdatePayments({
    formCosts,
    object: companyInvoiceConfig,
  });

  const handleAddCost = () => {
    const costsCopy = [...(formCosts || [])];
    const tempId = uid();

    costsCopy.push({ _tempId: tempId });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          companypaymentmethod_set: costsCopy,
        },
      })
    );

    setHandleCostId(tempId);
  };

  const currentCost = formCosts?.find(
    (c) => c.id === handleCostId || c._tempId === handleCostId
  );
  const currentCostIndex = formCosts?.findIndex(
    (c) => c.id === handleCostId || c._tempId === handleCostId
  );

  const [editActive, setEditActive] = React.useState(false);

  // Set new costs to edit
  React.useEffect(() => {
    if (handleCostId != null && currentCost) {
      setEditActive(true);
    }
  }, [handleCostId, currentCost]);

  const infoObj = {
    Detaljer: [
      {
        title: "Primär metod",
        value:
          currentCost?.primary == null ? (
            "-"
          ) : (
            <BooleanLabel
              value={currentCost?.primary}
              onLabel={"Primär"}
              offLabel={"Ej primär (alternativ)"}
            />
          ),
      },
      {
        title: "Typ",
        value: currentCost?.is_bankgiro
          ? "Bankgiro"
          : currentCost?.is_plusgiro
          ? "Plusgiro"
          : currentCost?.is_bank_account
          ? "Bankkonto"
          : currentCost?.is_iban
          ? "IBAN"
          : "Okänt",
      },
      {
        title: "Bankgiro",
        value: currentCost?.bankgiro,
      },
      {
        title: "Plusgiro",
        value: currentCost?.plusgiro,
      },
      {
        title: "Clearingnummer",
        value: currentCost?.clearing_number,
      },
      {
        title: "Bankkonto",
        value: currentCost?.bank_account,
      },
      {
        title: "IBAN",
        value: currentCost?.iban,
      },
      {
        title: "BIC",
        value: currentCost?.bic,
      },
    ],
  };

  return (
    <>
      <StandardModal
        isOpen={handleCostId !== null}
        closeFunction={
          editActive ? () => setEditActive(false) : () => setHandleCostId(null)
        }
        title={`${editActive ? "Redigera" : "Hantera"} betalningsmetod`}
        withActionBar
        actionBarAcceptTitle={editActive ? "Klar" : "Redigera"}
        actionBarCancelTitle={editActive ? "Tillbaka" : "Stäng"}
        saveFunction={editActive ? onUpdate : () => setEditActive(true)}
      >
        {!editActive ? (
          <>
            <DetailInfo infoObj={infoObj} />

            <TextButton
              title="Radera rad"
              clicked={() => onRemove(handleCostId)}
              red
              iconType="close"
              iconPlacement="right"
              extraStyle={{ marginTop: 24 }}
            />
          </>
        ) : (
          <>
            <TextInput
              title="Bankgiro"
              storeName={storeName}
              method={method}
              fieldKey={`companypaymentmethod_set[${currentCostIndex}].bankgiro`}
              instructionsKey="companypaymentmethod_set.bankgiro"
            />
            <TextInput
              title="Plusgiro"
              storeName={storeName}
              method={method}
              fieldKey={`companypaymentmethod_set[${currentCostIndex}].plusgiro`}
              instructionsKey="companypaymentmethod_set.plusgiro"
            />
            <DoubleFieldWrapper>
              <TextInput
                title="Clearingnummer"
                extraStyles={{ minWidth: "150px", marginRight: "20px" }}
                {...{
                  storeName,
                  method,
                  fieldKey: `companypaymentmethod_set[${currentCostIndex}].clearing_number`,
                  instructionsKey: "companypaymentmethod_set.clearing_number",
                }}
              />

              <TextInput
                title="Bankkonto"
                extraStyles={{ minWidth: "290px" }}
                {...{
                  storeName,
                  method,
                  fieldKey: `companypaymentmethod_set[${currentCostIndex}].bank_account`,
                  instructionsKey: "companypaymentmethod_set.bank_account",
                }}
              />
            </DoubleFieldWrapper>

            <DoubleFieldWrapper>
              <TextInput
                extraStyles={{ minWidth: "150px", marginRight: "20px" }}
                title="BIC/Swift"
                description="BIC/Swift är det internationella ID:t för banken"
                {...{
                  storeName,
                  method,
                  fieldKey: `companypaymentmethod_set[${currentCostIndex}].bic`,
                  instructionsKey: "companypaymentmethod_set.bic",
                }}
              />

              <TextInput
                extraStyles={{ minWidth: "290px" }}
                title="IBAN"
                description="IBAN är det internationella formatet för bankkontot"
                {...{
                  storeName,
                  method,
                  fieldKey: `companypaymentmethod_set[${currentCostIndex}].iban`,
                  instructionsKey: "companypaymentmethod_set.iban",
                }}
              />
            </DoubleFieldWrapper>
            <RadioGroup
              title="Primär betalmetod"
              description="Detta är den primära betalmetoden som används"
              storeName={storeName}
              method={method}
              options={[
                { label: "Ja", value: true },
                { label: "Nej", value: false },
              ]}
              fieldKey={`companypaymentmethod_set[${currentCostIndex}].primary`}
              instructionsKey="companypaymentmethod_set.primary"
              defaultValue={false}
            />
          </>
        )}
      </StandardModal>

      {loadingPayments && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Betalningsmetoder</OverviewTitle>
          <OverviewSubtitle>
            Tryck på en rad för att redigera den. För att spara ändringar, tryck
            på "Spara uppdateringar" längst ner.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
        <PrimaryButton title="Lägg till rad" clicked={handleAddCost} />
      </OverviewTitleWrapper>

      {!!formCosts?.length ? (
        <>
          <Table
            onRowClicked={handleEditCost}
            columns={tableColumns}
            data={tableCosts}
            checkRowError={checkRowError}
            hideSearch
          />
        </>
      ) : (
        <InnerBox
          style={{
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Inga betalningsmetoder tillagda
        </InnerBox>
      )}
      {canUpdate && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 12 }}
        >
          <PrimaryButton
            pulse
            title="Spara uppdateringar"
            clicked={updateCosts}
          />
        </div>
      )}
    </>
  );
}

function checkCanUpdatePayments({ formCosts, object }) {
  const existingCosts = object?.companypaymentmethod_set;

  if (!existingCosts?.length && !formCosts?.length) return false;

  let hasChange = false;

  if (existingCosts?.length !== formCosts?.length) return true;
  if (!existingCosts?.length && !formCosts?.length) return false;

  existingCosts.forEach((cost) => {
    const match = formCosts.find((fCost) => {
      return fCost.id === cost.id;
    });

    if (!match) {
      hasChange = true;
      return;
    }

    if (
      match.bankgiro !== cost.bankgiro ||
      match.plusgiro !== cost.plusgiro ||
      match.bank_account !== cost.bank_account ||
      match.clearing_number !== cost.clearing_number ||
      match.iban !== cost.iban ||
      match.bic !== cost.bic ||
      match.primary !== cost.primary
    ) {
      hasChange = true;
    }
  });

  return hasChange;
}
