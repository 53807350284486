export default ({ isManualSign }) => {
  let fields = [
    {
      title: "Uppsägning",
      key: "CANCELATION",
      visited: false,
      hasError: false,
      fieldKeys: [
        "status",
        "closed_date",
        "closed_renew_date",
        "closed_cause",
        "landlord_cancelled",
        "closed_signed",
      ],
    },
    {
      title: "Dokument",
      key: "DOCUMENT",
      visited: false,
      hasError: false,
      fieldKeys: ["cancelled_doc.doc"],
    },
    {
      title: "Bilagor",
      key: "ATTACHMENTS",
      visited: false,
      hasError: false,
      fieldKeys: ["cancelled_doc.attachments"],
    },
    {
      title: "Granska och slutför",
      key: "CONFIRM",
      visited: false,
      hasError: false,
      fieldKeys: [""],
    },
  ];

  if (!isManualSign) {
    fields = fields.filter((f) => f.key !== "ATTACHMENTS");
    fields = fields.filter((f) => f.key !== "DOCUMENT");
  }

  return fields;
};
