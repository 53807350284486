import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import clearTableFilters from "./utils/clearTableFilters";
import "./utils/promiseProgress";
import { Buffer } from "buffer";

import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/store";

window.Buffer = Buffer;
export const ENVIRONMENTS = {
  PROD: "app.pigello",
  STAGING: "staging.app.pigello",
  // ALPHA: "tfdemo.app.pigello",
  ALPHA: "demo.app.pigello.se",
  TEST: "test.app.pigello",
  DEV: "localhost",
};

export let currentEnv;
Object.keys(ENVIRONMENTS).forEach((key) => {
  const val = ENVIRONMENTS[key];
  if (window.location.host.includes(val)) {
    currentEnv = val;
  }
});

export const SOCKET_URL =
  currentEnv === ENVIRONMENTS.PROD
    ? "wss://9eabjypqx5.execute-api.eu-north-1.amazonaws.com/production"
    : currentEnv === ENVIRONMENTS.STAGING
    ? "wss://4abiipmiz2.execute-api.eu-north-1.amazonaws.com/staging"
    : "wss://nrvmskesh1.execute-api.eu-north-1.amazonaws.com/dev";

export const FEEDBACK_URL =
  currentEnv === ENVIRONMENTS.PROD
    ? "https://xnmbjk7llg.execute-api.eu-north-1.amazonaws.com/Development/feedback-beta"
    : "https://xnmbjk7llg.execute-api.eu-north-1.amazonaws.com/Development/feedback";

// if (currentEnv !== ENVIRONMENTS.DEV && currentEnv !== ENVIRONMENTS.TEST) {
//   Sentry.init({
//     dsn: "https://bd5c16c0a6c54b849eabaa3e2463166b@o562869.ingest.sentry.io/5702021",
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.5,
//   });
// }

export const isDev = () => {
  return currentEnv === ENVIRONMENTS.DEV;
};

export const isStaging = () => {
  return currentEnv === ENVIRONMENTS.STAGING;
};

export const isAlpha = () => {
  return currentEnv === ENVIRONMENTS.ALPHA;
};

export const isProd = () => {
  return currentEnv === ENVIRONMENTS.PROD;
};

export const isTest = () => {
  return currentEnv === ENVIRONMENTS.TEST;
};

clearTableFilters();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);
