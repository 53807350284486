import * as React from "react";
import theme from "../../../../theme";
import * as SC from "../../../Billecta/ExternalFilters/styles";
import { COST_ROW_STATES } from "./costCols";

export default function CostFilters({ selectedFilters, toggleFilter }) {
  return (
    <>
      <SC.FilterWrapper style={{ marginTop: 2 }}>
        <SC.ClosedInvoiceFilterButton
          selected={selectedFilters.includes(COST_ROW_STATES.ACTIVE)}
          onClick={() => toggleFilter(COST_ROW_STATES.ACTIVE)}
        >
          Aktiva
        </SC.ClosedInvoiceFilterButton>

        <SC.NonPaidFilterButton
          selected={selectedFilters.includes(COST_ROW_STATES.COMING)}
          onClick={() => toggleFilter(COST_ROW_STATES.COMING)}
        >
          Kommande
        </SC.NonPaidFilterButton>

        <SC.UninterestingFilterButton
          selected={selectedFilters.includes(COST_ROW_STATES.EARLIER)}
          onClick={() => toggleFilter(COST_ROW_STATES.EARLIER)}
        >
          Tidigare
        </SC.UninterestingFilterButton>
      </SC.FilterWrapper>
    </>
  );
}
