import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function resetPassword({ username, email }) {
  await axiosInstance.post("/accounts/auth/password_reset/", {
    username,
    email,
  });

  return true;
}

async function updateUser({ userId, data }) {
  const { data: updated } = await axiosInstance.patch(
    `/accounts/users/user/${userId}/`,
    data
  );
  return updated;
}

async function inviteUsers(userIds) {
  const res = await axiosInstance.post(constants.INVITE_USERS_URL, {
    user_ids: userIds,
  });
  return res;
}

export { resetPassword, updateUser, inviteUsers };
