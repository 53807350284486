import * as React from "react";

// style, design
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/basicDocs";
import { useFilteredRealEstates } from "../../../store/realEstates";

export default function BasicDocTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const [realEstates] = useFilteredRealEstates();

  // const exportParser = {
  //   category: (data) => data.category_display,
  // };
  // const exportExclude = ["content_type"];

  const columns = React.useMemo(
    () => columnDefs(realEstates),
    [realEstates, persistantQuery]
  );

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "basic_docs_full_table"}
      title={"Dokument"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      hideExport={true}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
