export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BILLECTAINTEGRATIONS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BILLECTAINTEGRATIONS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BILLECTAINTEGRATIONS",
  DESTROY_FORM: "DESTROY_FORM_BILLECTAINTEGRATIONS",
  SET_FORM_ERROR: "SET_FORM_ERROR_BILLECTAINTEGRATIONS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BILLECTAINTEGRATIONS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BILLECTAINTEGRATIONS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BILLECTAINTEGRATIONS",
  RESET_STATE: "RESET_STATE_BILLECTAINTEGRATIONS",
  REMOVE_TO_IN_PROGRESS: "REMOVE_TO_IN_PROGRESS_BILLECTAINTEGRATIONS",

  SET_KIVRA_SETTINGS: "SET_KIVRA_SETTINGS_BILLECTAINTEGRATIONS",
  SET_KIVRA_SETTINGS_NEW: "SET_KIVRA_SETTINGS_BILLECTAINTEGRATIONS_NEW",
  SET_VISMA_SETTINGS: "SET_VISMA_SETTINGS_BILLECTAINTEGRATIONS",
  SET_PE_SETTINGS: "SET_PE_SETTINGS_BILLECTAINTEGRATIONS",
  SET_FORTNOX_SETTINGS: "SET_FORTNOX_SETTINGS_BILLECTAINTEGRATIONS",
  SET_AUTOGIRO_SETTINGS: "SET_AUTOGIRO_SETTINGS_BILLECTAINTEGRATIONS",
  SET_EINVOICE_SETTINGS: "SET_EINVOICE_SETTINGS:BILLECTAINTEGRATIONS",
  CLEAR_INTEGRATION_STATE: "CLEAR_INTEGRATION_STATE_BILLECTAINTEGRATIONS",

  BULK_SET_INTEGRATION_SETTINGS: "BULK_SET_INTEGRATION_SETTINGS",

  KIVRA_INTEGRATION_NAME: "KIVRA",

  STORE_NAME: "billectaIntegrations",
};
