import {
  CheckBadgeIcon,
  DocumentPlusIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import illustration from "../../../assets/illustrations/illustration_newcontract.svg";
import * as React from "react";
import { useDispatch } from "react-redux";
import { getContractProposal as apartmentProposal } from "../../../store/apartments";
import { getContractProposal as indpProposal } from "../../../store/industrialPremises";
import { getContractProposal as parkingProposal } from "../../../store/parkingSpots";
import {
  constants as leaseConstants,
  createUrl as leaseCreateUrl,
  createOptionsUrl as leaseCreateOptionsUrl,
} from "../../../store/leaseContracts";
import {
  constants as parkingContractConstants,
  createUrl as parkingCreateUrl,
  createOptionsUrl as parkingCreateOptionsUrl,
} from "../../../store/parkingContracts";
import ActionBanner from "../../Displays/ActionBanner";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import StatusFlag, { STATUS_FLAG_STATES } from "../../Displays/StatusFlag";
import { useHistory } from "react-router-dom";
import { useCanUseScrive } from "../../../store/editabledocs/hooks/retrieve";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import moment from "moment";

export const NEW_CONTRACT_GENERATION_KINDS = {
  APARTMENT: "apartments",
  INDUSTRIAL_PREMISES: "industrial_premises_list",
  PARKING_SPOT: "parking_spots",
};

const PROPOSAL_METHOD = {
  apartments: apartmentProposal,
  industrial_premises_list: indpProposal,
  parking_spots: parkingProposal,
};

const CREATE_URL = {
  apartments: leaseCreateUrl,
  industrial_premises_list: leaseCreateUrl,
  parking_spots: parkingCreateUrl,
};

const OPTIONS_URL = {
  apartments: leaseCreateOptionsUrl,
  industrial_premises_list: leaseCreateOptionsUrl,
  parking_spots: parkingCreateOptionsUrl,
};

export default function NewContractGeneration({
  premises,
  kind,
  onDone,
  skipRedirect = false,
  overrideBtnTitle,
  isDone,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [proposalData, setProposalData] = React.useState(null);
  const [hasError, setHasError] = React.useState(false);

  const proposalMethod = PROPOSAL_METHOD[kind];

  const lastProposalId = useFormInstanceField({
    storeName:
      kind === NEW_CONTRACT_GENERATION_KINDS.PARKING_SPOT
        ? parkingContractConstants.STORE_NAME
        : leaseConstants.STORE_NAME,
    fieldKey: "_lastProposalId",
  });

  const [canUseScrive] = useCanUseScrive();

  React.useEffect(() => {
    if (hasError || loading) return;

    if (premises?.id && !proposalData && lastProposalId != premises?.id) {
      dispatch(
        updateActiveFormInstance({
          storeName:
            kind === NEW_CONTRACT_GENERATION_KINDS.PARKING_SPOT
              ? parkingContractConstants.STORE_NAME
              : leaseConstants.STORE_NAME,
          data: {
            _proposalSourceId: null,
            _proposalIsDone: false,
            _lastProposalId: null,
          },
        })
      );
      setLoading(true);
      dispatch(
        proposalMethod({
          id: premises.id,
          successCallback: (data) => {
            setLoading(false);
            setProposalData(data);
          },
          errorCallback: () => {
            setLoading(false);
            setHasError(true);
          },
        })
      );
    }
  }, [premises]);

  const handleProposeContract = () => {
    const proposal = proposalData.proposal;

    const newStartDate = proposal.start_date
      ? moment(proposal.start_date).add(1, "day").format("YYYY-MM-DD")
      : undefined;

    dispatch(
      setActiveFormInstance({
        storeName:
          kind === NEW_CONTRACT_GENERATION_KINDS.PARKING_SPOT
            ? parkingContractConstants.STORE_NAME
            : leaseConstants.STORE_NAME,
        data: {
          ...proposal,
          start_date: newStartDate,
          _proposalSourceId: proposalData?.source_id,
          _proposalIsDone: true,
          _lastProposalId: premises.id,
          [kind]: [premises],
        },
      })
    );

    onDone && onDone(proposalData);
    if (skipRedirect) return;

    push(OPTIONS_URL[kind]());
    // push(
    //   CREATE_URL[kind]({
    //     existing: false,
    //     digitalSign: canUseScrive,
    //     kind:
    //       kind === NEW_CONTRACT_GENERATION_KINDS.APARTMENT
    //         ? "apartments"
    //         : "industrialpremises",
    //   })
    // );
  };

  if (isDone)
    return (
      <ActionBanner
        fillWidth
        renderTitle={() => (
          <>
            Inställningar hämtades{" "}
            <CheckBadgeIcon width={24} className="ml-1 text-green-600" />
          </>
        )}
        renderDescription={() => (
          <>Attributen som föreslogs har klistrats in i formuläret</>
        )}
        illustrationSvg={illustration}
      />
    );

  if (!proposalData || hasError || loading) return null;

  return (
    <ActionBanner
      fillWidth
      renderTitle={() => <>Sätt upp en ny avtalsgeneration</>}
      renderDescription={() => (
        <>
          Ett automatiskt avtalsförslag har genererats utifrån objektets
          inställningar och eventuella tidigare avtal på detta objekt.
          {proposalData?.conflicts?.length > 0 && (
            <>
              <br />
              <br />
              <StatusFlag state={STATUS_FLAG_STATES.ERROR}>
                <ExclamationTriangleIcon width={16} className="mr-1" /> Det
                finns redan aktiva ej uppsagda avtal på detta objekt
              </StatusFlag>
            </>
          )}
        </>
      )}
      illustrationSvg={illustration}
      renderActions={() => (
        <PrimaryBtn onClick={handleProposeContract}>
          {overrideBtnTitle || "Sätt upp nytt avtal"}{" "}
          <DocumentPlusIcon className="ml-1" width={16} />
        </PrimaryBtn>
      )}
    />
  );
}
