import * as React from "react";

import * as SC from "../../Fields/styles";

export default React.memo(
  ({
    value,
    onChange,
    onBlur,
    onFocus,
    required,
    maxLength,
    error,
    label,
    helpText,
    renderHelpText,
    id,
    disabled,
    renderDescription,
    renderSuggestions = false,
    suggestions = [],
    handleSuggestionClicked,
    extraStyles,
    type,
    noMargin,
    rowSize,
    placeholder,
    onKeyPress,
  }) => {
    const handleChange = (value) => {
      onChange(value);
    };

    return (
      <SC.InputSpacing {...{ noMargin }} style={{ ...extraStyles }}>
        <SC.InputFieldTitle>
          {label}
          {required ? "*" : ""}
        </SC.InputFieldTitle>
        {(renderHelpText || helpText) && (
          <SC.InputFieldDescription>
            {renderHelpText ? renderHelpText() : helpText}
          </SC.InputFieldDescription>
        )}
        {renderDescription ? renderDescription : null}
        <SC.InputFieldWrapper error={!!error}>
          <SC.InputField
            value={value || ""}
            onChange={({ target: { value } }) => handleChange(value)}
            onBlur={onBlur}
            onFocus={onFocus}
            maxLength={maxLength}
            disabled={disabled}
            id={id}
            onKeyPress={onKeyPress}
            required={required}
            type={type || "text"}
            {...{ rowSize, placeholder }}
          />
        </SC.InputFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
