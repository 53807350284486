import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { useDispatch } from "react-redux";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    instructionsKey,
    forceInstructions,
    forceRequired,
    forceError,
    title,
    description,
    options = [],
    defaultValue,
    disabled,
  }) => {
    const dispatch = useDispatch();

    const instructions =
      useFormField({
        storeName,
        method,
        fieldKey: instructionsKey || fieldKey,
      }) || forceInstructions;
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey }) || forceError;
    const required = instructions?._required || forceRequired;

    React.useEffect(() => {
      if (value === undefined && defaultValue === null) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: defaultValue },
          })
        );
      }

      if (value == null && defaultValue != null) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: defaultValue },
          })
        );
      }
    }, []);

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );
    };

    return (
      <>
        <div>
          <div className="font-medium text-gray-900 text-sm flex items-center">
            {error && (
              <ExclamationTriangleIcon
                width={16}
                className="text-red-600 mr-1"
              />
            )}{" "}
            {title}
            {required ? "*" : ""}
          </div>
          {description && (
            <div className="text-xs font-normal text-gray-500 bg-transparent mb-2">
              {description}
            </div>
          )}
        </div>

        {error && (
          <div className="text-xs font-normal text-red-600 mb-2">{error}</div>
        )}

        {options.map((op) => (
          <div className="flex items-center ">
            <div className="flex items-center">
              <input
                onChange={() => onChange(op.value)}
                disabled={disabled || op.disabled}
                id={fieldKey}
                type="radio"
                checked={value === op.value}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 "
              />
            </div>
            <div className="ml-2 text-sm">
              <label htmlFor={fieldKey} className="font-medium text-gray-900 ">
                {op.title}
              </label>
              <p
                id="helper-radio-text"
                className="text-xs font-normal text-gray-500 "
              >
                {op.description}
              </p>
            </div>
          </div>
        ))}
      </>
    );
  }
);
