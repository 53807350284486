import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

// style, design
import * as SC from "../../Base/Chapters/styles";
import { Select, Checkbox } from "../../Base/Fields";
import { PrimaryButton } from "../../Base/Buttons";

// store, state
import {
  constants,
  destroyPatchForm,
  useSettingForm,
  update,
} from "../../../../store/notifications";
import { useHistory } from "react-router";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

const OBJECT_TYPES = [
  { v: "standard.leasecontract", d: "Hyresavtal" },
  { v: "standard.othercontract", d: "Övriga avtal" },
  { v: "standard.parkingcontract", d: "Parkeringsavtal" },
  { v: "standard.apartment", d: "Lägenheter" },
  { v: "standard.industrialpremises", d: "Lokaler" },
  { v: "brf.brfpremis", d: "Brf lägenheter" },
  { v: "errands.reporterrand", d: "Felanmälningar" },
  { v: "errands.inspectionerrand", d: "Besiktningar" },
  { v: "errands.roundingerrand", d: "Ronderingar" },
  { v: "errands.goverrand", d: "Myndighetskrav" },
];

export default ({ untouchedInstance }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const method = "PATCH";
  const { push } = useHistory();
  const formLoaded = Boolean(useSettingForm());
  const [loading, setLoading] = React.useState(false);

  const hasChanged = useSelector((state) => {
    return !isEqual(untouchedInstance, state[storeName].formInstance);
  });

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));
    dispatch({ type: constants.RESET_STATE });
    push("/configcenter/profile/notifications");
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      update({
        id: untouchedInstance?.id,
        successCallback: () => checkout(true),
        errorCallback: () => setLoading(false),
      })
    );
  };

  if (!formLoaded) {
    return <OverlaySpinner />;
  }

  return (
    <SC.FormArea>
      {loading && <OverlaySpinner />}

      <Checkbox
        storeName={storeName}
        fieldKey={"send_email"}
        method={method}
        title="Få e-post vid notis"
      />
      <Select
        storeName={storeName}
        fieldKey={"blocked_object_types"}
        method={method}
        isMulti={true}
        bodyPortaled
        title="Stäng av notiser från..."
        forceChoices={OBJECT_TYPES}
      />
      <Checkbox
        storeName={storeName}
        fieldKey={"recieve_invoice_reports"}
        method={"PATCH"}
      />

      <PrimaryButton
        newDesign
        clicked={onSubmit}
        title="Uppdatera"
        disabled={!hasChanged}
      />
    </SC.FormArea>
  );
};
