import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

// style, design
import * as SC from "../../../Base/Chapters/styles";
import { Select } from "../../../Base/Fields";
import FullPageSpinner from "../../../../Loaders/FullPageSpinner";
import { LinkButton, PrimaryButton } from "../../../Base/Buttons";

// store, state
import {
  constants,
  update,
  destroyPatchForm,
  useConfigCenterForm,
} from "../../../../../store/configcenter";

import { useUser } from "../../../../../store/users";

import {
  buildQueryString,
  setActiveFormInstance,
  useFormInstanceField,
  usePermissionCheck,
} from "../../../../../store/base";

import { useHistory } from "react-router-dom";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import { OverviewTitle } from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";

export default ({ method, untouchedInstance, nextUrl }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { push, goBack } = useHistory();

  const formLoaded = Boolean(
    useConfigCenterForm(method, storeName, untouchedInstance?.id)
  );

  const hasChanged = useSelector((state) => {
    return !isEqual(untouchedInstance, state[storeName].formInstance);
  });

  const canSubmit = usePermissionCheck(`change_can_configcenter`);

  React.useEffect(() => {
    dispatch(
      setActiveFormInstance({
        storeName,
        data: untouchedInstance,
      })
    );
  }, [untouchedInstance]);

  const defaultSignatoryParty = useFormInstanceField({
    storeName,
    fieldKey: "default_signatory_party",
  });

  const signatoryUser = useFormInstanceField({
    storeName,
    fieldKey: "default_signatory_user",
  });

  const [selectedSignatoryUser] = useUser(
    method === "PATCH" ? signatoryUser?.id : null
  );

  const userQuerystring = buildQueryString({
    user_type__in: [0, 1],
  });

  const userFilter = (obj) => {
    return [0, 1].includes(obj.user_type);
  };

  const checkout = (success) => {
    setLoading(false);
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
      push(nextUrl || `/configcenter/e-signing`);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: (data, returnData) => {
            // set current configcenter so form and overview are updated properly
            dispatch({
              type: constants.SET_CURRENT_CONFIGCENTER,
              payload: {
                returnData,
              },
            });
            checkout(true);
          },
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  if (!formLoaded) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <SC.FormArea>
        {loading && <OverlaySpinner />}

        <OverviewTitle small>E-signering</OverviewTitle>
        <Select
          bodyPortaled
          storeName={storeName}
          fieldKey={"default_signatory_party"}
          method={method}
          title="Standard signerande part för hyresvärd på digitala mallar"
        />

        {defaultSignatoryParty == "user" && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              persistantQuery={{
                user_type__in: [0, 1],
              }}
              placeholder="Välj Hyresvärd..."
              title="Hyresvärd"
              fieldKey={`default_signatory_user`}
              method={method}
              TableComponent={UsersTable}
            />
          </div>
        )}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <LinkButton clicked={() => goBack()} title={"Avbryt"} />
          <PrimaryButton
            newDesign
            clicked={onSubmit}
            title={method === "POST" ? "Lägg till" : "Uppdatera"}
            disabled={!canSubmit || !hasChanged}
          />
        </div>
      </SC.FormArea>
    </>
  );
};
