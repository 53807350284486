import OverviewDiff from "./OverviewDiff";
import OverviewGeneral from "./OverviewGeneral";
import QuickDetail from "./QuickDetail";
import DonutChartDetailed from "./DonutChartDetailed";

import moment from "moment";

// kpi icons
import accountBalanceIcon from "../../../assets/svg/material/account_balance_white.svg";
import buildingIcon from "../../../assets/svg/material/building_white.svg";
import rentalsIcon from "../../../assets/svg/material/rentals_white.svg";
import documentIcon from "../../../assets/svg/material/document_white.svg";
import overviewIcon from "../../../assets/svg/material/overview.svg";
import peopleIcon from "../../../assets/svg/material/people_white.svg";
import realestateIcon from "../../../assets/svg/material/realestate_white.svg";
import statsIcon from "../../../assets/svg/material/stats_white.svg";
import handyman from "../../../assets/svg/material/handyman_white.svg";

const LEGEND_DELIMITER = "-";

export const KPI_ECONOMIC_VACANCY = "economic_vacancy";
export const KPI_CONTRACT_VALUE = "contract_value";
export const KPI_DISCOUNTS = "discounts";
export const KPI_NET_LEASTING = "net_leasing";
export const KPI_CONTRACT_TERM = "contract_term";
export const KPI_MARKET_RENT = "market_rent";
export const KPI_RENT_PER_AREA = "rent_per_area";
export const KPI_TOP_TENANT = "top_tenant";
export const KPI_RENTABLE_AREA = "rentable_area";
export const KPI_OCCUPANCY = "occupancy";
export const KPI_INVOICING_FORECAST = "invoicing_forecast";
export const KPI_VACANT_OBJECTS_MARKET_RENT = "vacant_objects_market_rent";
export const KPI_REALESTATE_COUNT = "realestate_count";
export const KPI_REALESTATE_TAX_RECORD = "realestate_tax_record";
export const KPI_DELIVERY_METHOD = "delivery_method";
export const KPI_ERRAND_COUNT = "errand_count";
export const KPI_GOVT_ERRAND_FAULT_COUNT = "govt_errand_fault_count";
export const KPI_COMPONENT_RATING_WARRANTY = "component_rating_warranty";
export const KPI_ERRAND_PERFORMANCE_PERIOD = "errand_performance_period";

export const KPI_CONTRACT_COUNT = "contract_count";
export const KPI_ACTUAL_VS_FORECAST_INVOICING = "actual_vs_forecast_invoicing";
export const KPI_AUTOGIRO_TENANT_COUNT = "autogiro_tenant_count";
export const KPI_INVOICING_COUNT = "invoicing_count";
export const KPI_UNATTESTED_CURRENT_INVOICES = "unattested_current_invoices";
export const KPI_REMINDER_INVOICE_COUNT = "reminder_invoice_count";
export const KPI_UNMATCHED_PAYMENTS = "unmatched_payments";
export const KPI_AUTOGIRO_FAILED = "autogiro_failed";
export const KPI_INVOICE_REVENUE = "invoice_revenue";
export const KPI_INVOICE_PERIOD_COUNT = "invoice_period_count";
export const KPI_AUTOGIRO_REVENUE = "autogiro_revenue";
export const KPI_VAT_KEYS = "vat_keys";
export const KPI_TENANT_COUNT = "tenant_count";
export const KPI_VACANCY = "vacancy";
export const KPI_VACANT_OBJECTS = "vacant_objects";
export const KPI_LEASED_OBJECTS = "leased_objects";
export const KPI_BUILDING_COUNT = "building_count";
export const KPI_LEASE_OBJECT_COUNT = "lease_object_count";
export const KPI_WARRANTY_ERRAND_COUNT = "warranty_errand_count"
export const KPI_BRF_OWNER_COUNT = "brf_owner_count"
export const KPI_BRF_PREMIS_COUNT = "brf_premis_count"

export const KPI_APARTMENT_CONTRACT_COUNT = "apartment_contract_count"
export const KPI_INDUSTRIALPREMISES_CONTRACT_COUNT = "industrialpremises_contract_count"
export const KPI_PARKINGCONTRACT_COUNT = "parkingcontract_count"
export const KPI_OTHERCONTRACT_COUNT = "othercontract_count"
export const KPI_PRIVATE_TENANT_COUNT = "private_tenant_count"
export const KPI_CORPORATE_TENANT_COUNT = "corporate_tenant_count"
export const KPI_REPORTERRAND_COUNT = "reporterrand_count"
export const KPI_INSPECTIONERRAND_COUNT = "inspectionerrand_count"
export const KPI_ROUNDINGERRAND_COUNT = "roundingerrand_count"
export const KPI_GOVT_ERRAND_COUNT = "govt_errand_count"
export const KPI_VACANT_APARTMENTS_COUNT = "vacant_apartments_count"
export const KPI_VACANT_INDUSTRIALPREMISES_COUNT = "vacant_industrialpremises_count"
export const KPI_VACANT_PARKINGSPOTS_COUNT = "vacant_parkingspots_count"
export const KPI_LEASED_APARTMENTS_COUNT = "leased_apartments_count"
export const KPI_LEASED_INDUSTRIALPREMISES_COUNT = "leased_industrialpremises_count"
export const KPI_LEASED_PARKINGSPOTS_COUNT = "leased_parkingspots_count"
export const KPI_APARTMENT_AREA = "apartment_area"
export const KPI_INDUSTRIALPREMISES_AREA = "industrialpremises_area"
export const KPI_APARTMENT_OCCUPANCY = "apartment_occupancy"
export const KPI_INDUSTRIALPREMISES_OCCUPANCY = "industrialpremises_occupancy"
export const KPI_APARTMENT_VACANCY = "apartment_vacancy"
export const KPI_INDUSTRIALPREMISES_VACANCY = "industrialpremises_vacancy"
export const KPI_APARTMENT_COUNT = "apartment_count"
export const KPI_INDUSTRIALPREMISES_COUNT = "industrialpremises_count"
export const KPI_PARKINGSPOT_COUNT = "parkingspot_count"
export const KPI_APARTMENT_VACANT_AREA = "apartment_vacant_area"
export const KPI_INDUSTRIALPREMISES_VACANT_AREA = "industrialpremises_vacant_area"

// Widget keys
const WIDGET_LINECHART = "linechart";
export const WIDGET_BARCHART = "barchart";
export const WIDGET_BARCHART_WITH_DATA_LABELS = "barchart_with_data_labels";
export const WIDGET_DONUTCHART = "donutchart";
const WIDGET_DONUTCHART_DETAILED = "donutchart_detailed";
export const WIDGET_OVERVIEW_DIFF = "overview_diff";
export const WIDGET_OVERVIEW_GENERAL = "overview_general";
export const WIDGET_BARCHART_NEGATIVE_TRENDLINE =
  "barchart_plus_negative_trendline";
export const WIDGET_MULTIPLE_Y_AXIS = "multiple_y_axis";
export const WIDGET_QUICK_DETAIL = "quick_detail";

// Common widget choices, for validation
const WIDGET_CHOICES_STANDARD = [
  WIDGET_LINECHART,
  WIDGET_BARCHART,
  WIDGET_QUICK_DETAIL,
];
const WIDGET_CHOICES_OVERVIEW = [
  WIDGET_OVERVIEW_DIFF,
  WIDGET_OVERVIEW_GENERAL,
  WIDGET_QUICK_DETAIL,
];
const WIDGET_CHOICES_DOUBLE_VALUE = [
  WIDGET_OVERVIEW_GENERAL,
  WIDGET_BARCHART_NEGATIVE_TRENDLINE,
];
const WIDGET_CHOICES_TIME_DISABLED = [WIDGET_BARCHART, WIDGET_QUICK_DETAIL];

// Util functions
const currencyFormatter = function (val, kpi, widgetPlacement) {
  let unit = null;
  if (widgetPlacement) {
    unit = getDynamicUnit(widgetPlacement);
  } else {
    if (kpiDataMapping[kpi]?.chart_unit && kpiDataMapping[kpi]?.chart_unit?.length > 1) unit = kpiDataMapping[kpi]?.chart_unit
    else if (kpiDataMapping[kpi]?.chart_units) unit = kpiDataMapping[kpi]?.chart_units[0]
  }
  return `${Math.round(val)
    .toLocaleString("sv")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit || ""}`;
};

const numberFormatter = function (val, kpi, widgetPlacement) {
  return val.toLocaleString("sv");
};

const getTickAmount = (widget, labels) => {
  const widgetWidth = getInfoForWidget(widget).width;
  if (labels && labels.length < widgetWidth * 3) {
    return labels.length;
  } else {
    return widgetWidth * 3;
  }
};

export const kpiDataMapping = {
  [KPI_CONTRACT_VALUE]: {
    quick_detail_text: "Kontraktsvärde",
    info_text:
      "Debiterad hyra för perioden med tillägg för bedömd marknadshyra för vakanta ytor.",
    chart_unit: " kr",
    icon: statsIcon,
    themeColor: 0,
    perms: [],
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_CONTRACT_TERM]: {
    quick_detail_text: "Löptid, avtal",
    info_text: "Snitt skillnad mellan ett avtals start och slutdatum",
    chart_unit: " dagar",
    icon: overviewIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL],
    end_date_only: true,
    value_formatter: function (val) {
      const years = Math.floor(val / 365);
      const months = Math.floor((val % 365) / 30);
      const days = Math.floor((val % 365) % 30);
      const yearsDisplay =
        years > 0 ? years + (years == 1 ? " år, " : " år, ") : "";
      const monthsDisplay =
        months > 0 ? months + (months == 1 ? " månad, " : " månader, ") : "";
      const daysDisplay =
        days > 0 ? days + (days == 1 ? " dag" : " dagar") : "";
      if (!yearsDisplay && !monthsDisplay && !daysDisplay) return "0 dagar"
      return `${yearsDisplay}${monthsDisplay}${daysDisplay}`;
    },
  },
  [KPI_TOP_TENANT]: {
    quick_detail_text: "Top hyresgäst",
    info_text:
      "Top hyresgäst beroende på antal objekt uthyrda eller antal kvm uthyrt",
    chart_unit: "",
    icon: peopleIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_BARCHART],
    column_defs: [
      { title: "Hyresgäst", accessor: 3 },
      { title: "Ålder", accessor: 4, type: "number" },
      { title: "Kön", accessor: 5 },
      {
        title: "Kontraktsvärde",
        accessor: 0,
        type: "number",
        formatter: currencyFormatter,
      },
      {
        title: "Objekt",
        accessor: 2,
        type: "number",
        formatter: numberFormatter,
      },
      {
        title: "Total area",
        accessor: 1,
        type: "number",
        formatter: numberFormatter,
      },
    ],
  },
  [KPI_RENTABLE_AREA]: {
    quick_detail_text: "Uthyrningsbar area",
    info_text: "Sammanlagd area av uthyrningsbara objekt",
    chart_unit: " kvm",
    icon: rentalsIcon,
    themeColor: 1,
    allowed_widgets: [WIDGET_BARCHART, WIDGET_QUICK_DETAIL, WIDGET_DONUTCHART],
    disable_time: true,
  },
  [KPI_APARTMENT_AREA]: {
    quick_detail_text: "Uthyrningsbar BOA",
    info_text: "Sammanlagd area av lägenheter",
    chart_unit: " kvm",
    icon: rentalsIcon,
    themeColor: 1,
    allowed_widgets: [WIDGET_BARCHART, WIDGET_QUICK_DETAIL, WIDGET_DONUTCHART],
    disable_time: true,
  },
  [KPI_INDUSTRIALPREMISES_AREA]: {
    quick_detail_text: "Uthyrningsbar LOA",
    info_text: "Sammanlagd area av lokaler",
    chart_unit: " kvm",
    icon: rentalsIcon,
    themeColor: 1,
    allowed_widgets: [WIDGET_BARCHART, WIDGET_QUICK_DETAIL, WIDGET_DONUTCHART],
    disable_time: true,
  },
  [KPI_OCCUPANCY]: {
    quick_detail_text: "Total uthyrningsgrad",
    info_text: "Procent av totala area/objekt som är uthyrda under perioden",
    chart_unit: " %",
    icon: realestateIcon,
    value_labels: ["Uthyrt", "Vakans"],
    themeColor: 1,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_BARCHART_WITH_DATA_LABELS],
    percentage_value: true,
  },
  [KPI_APARTMENT_OCCUPANCY]: {
    quick_detail_text: "Uthyrningsgrad BOA",
    info_text: "Procent av totala lägenhetsyta/lägenheter som är uthyrda under perioden",
    chart_unit: " %",
    icon: realestateIcon,
    value_labels: ["Uthyrt", "Vakans"],
    themeColor: 1,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_BARCHART_WITH_DATA_LABELS],
    percentage_value: true,
  },
  [KPI_INDUSTRIALPREMISES_OCCUPANCY]: {
    quick_detail_text: "Uthyrningsgrad LOA",
    info_text: "Procent av totala lokalyta/lokaler som är uthyrda under perioden",
    chart_unit: " %",
    icon: realestateIcon,
    value_labels: ["Uthyrt", "Vakans"],
    themeColor: 1,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_BARCHART_WITH_DATA_LABELS],
    percentage_value: true,
  },
  [KPI_INVOICING_FORECAST]: {
    quick_detail_text: "Aviseringsprognos",
    info_text: "Se alla aviseringskostnader för förtida eller framtida period",
    chart_unit: " kr",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_VACANT_OBJECTS_MARKET_RENT]: {
    quick_detail_text: "Objektsvärde",
    info_text: "Bedömd marknadshyra för vakanta objekt",
    chart_unit: " kr",
    icon: rentalsIcon,
    themeColor: 2,
    allowed_widgets: [WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_QUICK_DETAIL, WIDGET_OVERVIEW_DIFF],
  },
  [KPI_REALESTATE_COUNT]: {
    quick_detail_text: "Fastigheter",
    info_text: "Möjlighet att fördela efter portfölj",
    chart_unit: "",
    icon: realestateIcon,
    themeColor: 3,
    allowed_widgets: WIDGET_CHOICES_TIME_DISABLED,
    disable_time: true,
  },
  [KPI_REALESTATE_TAX_RECORD]: {
    quick_detail_text: "Fastighetstaxering",
    info_text:
      "Se hur taxeringsvärdet har förändrats över tid för en eller ett antal fastigheter",
    chart_unit: " kr",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_DELIVERY_METHOD]: {
    quick_detail_text: "Leveransmetoder",
    info_text: "Se användning av olika leveransmetoder för hyresgäster",
    chart_unit: "",
    icon: documentIcon,
    themeColor: 4,
    allowed_widgets: [WIDGET_BARCHART, WIDGET_DONUTCHART],
  },
  [KPI_ERRAND_COUNT]: {
    quick_detail_text: "Antal ärenden",
    info_text:
      "Antal ärenden för perioden, d.v.s ärenden som är skapade och ej avklarade innan perioden. Med widget 'Översikt med skillnad' visas alla inkomna ärenden. Möjlighet att fördela t.ex efter typ, status eller objekt",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_LINECHART, WIDGET_QUICK_DETAIL, WIDGET_OVERVIEW_DIFF, WIDGET_BARCHART],
  },
  [KPI_REPORTERRAND_COUNT]: {
    quick_detail_text: "Antal felanmälningar",
    info_text:
      "Antal felanmälningar för perioden, d.v.s felanmälningar som är skapade och ej avklarade innan perioden. Med widget 'Översikt med skillnad' visas alla inkomna felanmälningar. Möjlighet att fördela t.ex efter typ, status eller objekt",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_LINECHART, WIDGET_QUICK_DETAIL, WIDGET_OVERVIEW_DIFF],
  },
  [KPI_ROUNDINGERRAND_COUNT]: {
    quick_detail_text: "Antal ronderingar",
    info_text:
      "Antal ronderingar för perioden, d.v.s ronderingar som är skapade och ej avklarade innan perioden. Med widget 'Översikt med skillnad' visas alla inkomna ronderingar. Möjlighet att fördela t.ex efter typ, status eller objekt",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_LINECHART, WIDGET_QUICK_DETAIL, WIDGET_OVERVIEW_DIFF],
  },
  [KPI_INSPECTIONERRAND_COUNT]: {
    quick_detail_text: "Antal besiktningar",
    info_text:
      "Antal besiktningar för perioden, d.v.s besiktningar som är skapade och ej avklarade innan perioden. Med widget 'Översikt med skillnad' visas alla inkomna besiktningar. Möjlighet att fördela t.ex efter typ, status eller objekt",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_LINECHART, WIDGET_QUICK_DETAIL, WIDGET_OVERVIEW_DIFF],
  },
  [KPI_GOVT_ERRAND_COUNT]: {
    quick_detail_text: "Antal myndighetskrav",
    info_text:
      "Antal myndighetskrav för perioden, d.v.s myndighetskrav som är skapade och ej avklarade innan perioden. Med widget 'Översikt med skillnad' visas alla inkomna myndighetskrav. Möjlighet att fördela t.ex efter typ, status eller objekt",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_LINECHART, WIDGET_QUICK_DETAIL, WIDGET_OVERVIEW_DIFF],
  },
  [KPI_GOVT_ERRAND_FAULT_COUNT]: {
    quick_detail_text: "Myndighetskrav",
    info_text: "Hur många avvikelser som kommer ifrån myndighetskrav.",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: [WIDGET_OVERVIEW_DIFF, WIDGET_QUICK_DETAIL, WIDGET_BARCHART],
  },
  [KPI_NET_LEASTING]: {
    quick_detail_text: "Nettouthyrning",
    info_text: "Jämför nyuthyrningar mot uppsägningar",
    chart_unit: " kr",
    value_labels: ["Nyuthyrning", "Uppsägningar", "Nettouthyrning"],
    icon: statsIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_BARCHART_NEGATIVE_TRENDLINE],
  },
  [KPI_RENT_PER_AREA]: {
    quick_detail_text: "Hyra per kvm",
    info_text: "Hyresvärde delat på antal kvm",
    chart_unit: " kr",
    icon: rentalsIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_LINECHART, WIDGET_BARCHART],
  },
  [KPI_DISCOUNTS]: {
    quick_detail_text: "Rabatter",
    info_text: "Alla kostnader med negativa värden",
    chart_unit: " kr",
    icon: documentIcon,
    themeColor: 0,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_ECONOMIC_VACANCY]: {
    quick_detail_text: "Ekonomisk vakansgrad",
    info_text: "Hyresvärde delat på hyresvärde plus vakanta objektskostnader",
    chart_units: [" kr", " %"],
    icon: statsIcon,
    themeColor: 1,
    allowed_widgets: [WIDGET_MULTIPLE_Y_AXIS],
    value_labels: ["Total vakans (kr)", "Total vakans (%)"],
    percentage_value: true,
    end_date_only: true,
  },
  [KPI_MARKET_RENT]: {
    quick_detail_text: "Marknadshyra",
    info_text:
      "Antalet felanmälningar/avvikelser ifrån besiktningar som har gjorts på komponenter som fortfarande är under garantitid.",
    chart_unit: " kr",
    icon: rentalsIcon,
    themeColor: 1,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_COMPONENT_RATING_WARRANTY]: {
    quick_detail_text: "Avvikelser under garantitid",
    info_text: "Debiterad marknadshyra för perioden",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_ERRAND_PERFORMANCE_PERIOD]: {
    quick_detail_text: "Genomförandetid",
    info_text:
      "Hur lång tid det tar för ett ärende att gå från skapat till klarmarkerat och ifrån påbörjat till avklarat.",
    chart_unit: " dagar",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_CONTRACT_COUNT]: {
    quick_detail_text: "Avtal",
    info_text: "Antal aktiva avtal som löper under perioden",
    chart_unit: "",
    icon: documentIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_DONUTCHART],
  },
  [KPI_APARTMENT_CONTRACT_COUNT]: {
    quick_detail_text: "Bostadsavtal",
    info_text: "Antal aktiva bostadsavtal avtal som löper under perioden",
    chart_unit: "",
    icon: documentIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_DONUTCHART],
  },
  [KPI_INDUSTRIALPREMISES_CONTRACT_COUNT]: {
    quick_detail_text: "Lokalavtal",
    info_text: "Antal aktiva lokalavtal avtal som löper under perioden",
    chart_unit: "",
    icon: documentIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_DONUTCHART],
  },
  [KPI_PARKINGCONTRACT_COUNT]: {
    quick_detail_text: "Parkeringsavtal",
    info_text: "Antal aktiva parkeringsavtal avtal som löper under perioden",
    chart_unit: "",
    icon: documentIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_DONUTCHART],
  },
  [KPI_OTHERCONTRACT_COUNT]: {
    quick_detail_text: "Övriga avtal",
    info_text: "Antal aktiva parkeringsavtal avtal som löper under perioden",
    chart_unit: "",
    icon: documentIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART, WIDGET_DONUTCHART],
  },
  [KPI_ACTUAL_VS_FORECAST_INVOICING]: {
    quick_detail_text: "",
    info_text: "Fakturerat belopp i förhållande till vad som borde faktureras enligt avtal",
    chart_unit: " kr",
    icon: accountBalanceIcon,
    themeColor: 4,
    value_labels: ["Prognos", "Fakturerat", "Skillnad"],
    allowed_widgets: [WIDGET_OVERVIEW_GENERAL, WIDGET_LINECHART],
  },
  [KPI_AUTOGIRO_TENANT_COUNT]: {
    quick_detail_text: "Hyresgäster med autogiro",
    info_text: "Antal hyresgäster som debiteras med Autogiro",
    chart_unit: "",
    icon: peopleIcon,
    themeColor: 0,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_INVOICING_COUNT]: {
    quick_detail_text: "Fakturor",
    info_text: "Antal fakturor",
    chart_unit: "",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_UNATTESTED_CURRENT_INVOICES]: {
    quick_detail_text: "Oattesterade fakturor",
    info_text: "Antal oattesterade fakturor",
    chart_unit: "",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_TIME_DISABLED,
    disable_time: true,
  },
  [KPI_REMINDER_INVOICE_COUNT]: {
    quick_detail_text: "Påminnelser",
    info_text: "Antal påminnelser",
    chart_unit: "",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_TIME_DISABLED,
    disable_time: true,
  },
  [KPI_UNMATCHED_PAYMENTS]: {
    quick_detail_text: "Omatchade betalningar",
    info_text: "Antal omatchade betalningar",
    chart_unit: "",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_TIME_DISABLED,
    disable_time: true,
  },
  [KPI_AUTOGIRO_FAILED]: {
    quick_detail_text: "Misslyckade autogiro dragningar",
    info_text: "Antal misslyckade autogiro dragningar",
    chart_unit: "",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_INVOICE_REVENUE]: {
    quick_detail_text: "Fakturerat belopp",
    info_text: "Totalt belopp som har fakturerats under perioden",
    chart_unit: " kr",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_INVOICE_PERIOD_COUNT]: {
    quick_detail_text: "Fakturor som genereras",
    info_text: "Antal fakturor som genereras",
    chart_unit: " kr",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_AUTOGIRO_REVENUE]: {
    quick_detail_text: "Fakturerat belopp (autogiro)",
    info_text: "Totalt belopp som har fakturerats med autogiro under perioden",
    chart_unit: " kr",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_VAT_KEYS]: {
    quick_detail_text: "Momsnyckel",
    info_text: "Momsad area delat med total area för alla avtal",
    chart_unit: "",
    icon: accountBalanceIcon,
    themeColor: 4,
    allowed_widgets: WIDGET_CHOICES_STANDARD,
  },
  [KPI_BUILDING_COUNT]: {
    quick_detail_text: "Antal byggnader",
    info_text: "Antal byggnader",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART],
    disable_time: true
  },
  [KPI_TENANT_COUNT]: {
    quick_detail_text: "Antal hyresgäster",
    info_text: "Antal hyresgäster",
    chart_unit: "",
    icon: peopleIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_PRIVATE_TENANT_COUNT]: {
    quick_detail_text: "Antal privata hyresgäster",
    info_text: "Antal privata hyresgäster",
    chart_unit: "",
    icon: peopleIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_CORPORATE_TENANT_COUNT]: {
    quick_detail_text: "Antal företagshyresgäster",
    info_text: "Antal företagshyresgäster",
    chart_unit: "",
    icon: peopleIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_VACANCY]: {
    quick_detail_text: "Vakansgrad",
    info_text: "Procent av totala area/objekt som är vakanta under perioden",
    chart_unit: " %",
    icon: realestateIcon,
    themeColor: 1,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_APARTMENT_VACANT_AREA]: {
    quick_detail_text: "Vakant BOA",
    info_text: "Total bostadsarea som är vakant under perioden",
    chart_unit: " kvm",
    icon: realestateIcon,
    themeColor: 1,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_INDUSTRIALPREMISES_VACANT_AREA]: {
    quick_detail_text: "Vakant LOA",
    info_text: "Total lokalarea som är vakant under perioden",
    chart_unit: " kvm",
    icon: realestateIcon,
    themeColor: 1,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_VACANT_OBJECTS]: {
    quick_detail_text: "Antal vakanta objekt",
    info_text: "Antal vakanta objekt, går att filtrera mot objektstyp",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_VACANT_APARTMENTS_COUNT]: {
    quick_detail_text: "Antal vakanta bostäder",
    info_text: "Antal vakanta bostäder",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_VACANT_INDUSTRIALPREMISES_COUNT]: {
    quick_detail_text: "Antal vakanta lokaler",
    info_text: "Antal vakanta lokaler",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_VACANT_PARKINGSPOTS_COUNT]: {
    quick_detail_text: "Antal vakanta fordonsplatser",
    info_text: "Antal vakanta fordonsplatser",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_LEASED_OBJECTS]: {
    quick_detail_text: "Antal uthyrda objekt",
    info_text: "Antal uthyrda objekt, går att filtrera mot objektstyp",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_LEASED_APARTMENTS_COUNT]: {
    quick_detail_text: "Antal uthyrda bostäder",
    info_text: "Antal uthyrda bostäder",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_LEASED_INDUSTRIALPREMISES_COUNT]: {
    quick_detail_text: "Antal uthyrda lokaler",
    info_text: "Antal uthyrda lokaler",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_LEASED_PARKINGSPOTS_COUNT]: {
    quick_detail_text: "Antal uthyrda fordonsplatser",
    info_text: "Antal uthyrda fordonsplatser",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_LEASED_APARTMENTS_COUNT]: {
    quick_detail_text: "Antal uthyrda bostäder",
    info_text: "Antal uthyrda bostäder, går att filtrera mot objektstyp",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_DONUTCHART, WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_LINECHART],
  },
  [KPI_LEASE_OBJECT_COUNT]: {
    quick_detail_text: "Antal hyresobjekt",
    info_text: "Antal hyresobjekt",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_DONUTCHART],
    disable_time: true
  },
  [KPI_APARTMENT_COUNT]: {
    quick_detail_text: "Antal lägenheter",
    info_text: "Antal lägenheter",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_DONUTCHART],
    disable_time: true
  },
  [KPI_INDUSTRIALPREMISES_COUNT]: {
    quick_detail_text: "Antal lokaler",
    info_text: "Antal lokaler",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_DONUTCHART],
    disable_time: true
  },
  [KPI_PARKINGSPOT_COUNT]: {
    quick_detail_text: "Antal fordonsplatser",
    info_text: "Antal fordonsplatser",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_DONUTCHART],
    disable_time: true
  },
  [KPI_WARRANTY_ERRAND_COUNT]: {
    quick_detail_text: "Antal garantiärenden",
    info_text: "Antal garantiärenden",
    chart_unit: "",
    icon: handyman,
    themeColor: 4,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_DONUTCHART, WIDGET_OVERVIEW_DIFF],
  },
  [KPI_BRF_OWNER_COUNT]: {
    quick_detail_text: "Antal medlemmar",
    info_text: "Antal medlemmar",
    chart_unit: "",
    icon: peopleIcon,
    themeColor: 0,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_DONUTCHART],
    disable_time: true
  },
  [KPI_BRF_PREMIS_COUNT]: {
    quick_detail_text: "Antal bostadsrätter",
    info_text: "Antal bostadsrätter",
    chart_unit: "",
    icon: buildingIcon,
    themeColor: 3,
    allowed_widgets: [WIDGET_QUICK_DETAIL, WIDGET_BARCHART, WIDGET_DONUTCHART],
    disable_time: true
  },
};

const getDynamicUnit = (widgetPlacement) => {
  let unit = '';
  if (widgetPlacement.kpi === KPI_TOP_TENANT) {
    if (widgetPlacement?.body_parameters?.sort_method === "contract_value") {
      unit = " kr";
    } else if (widgetPlacement?.body_parameters?.sort_method === "total_area") {
      unit = " kvm";
    } else {
      unit = " st";
    }
  } else {
    unit = kpiDataMapping[widgetPlacement.kpi].chart_unit;
  }
  return unit;
};

const generatePieChartOptions = (
  labels,
  widgetPlacement,
  colors,
  chartSeries,
  goalLength
) => {
  let unit = getDynamicUnit(widgetPlacement);

  return {
    labels: labels,
    legend: {
      show: false,
    },
    fill: {
      colors: colors,
    },
    colors: colors,
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val.toFixed(1)
            .toLocaleString("sv")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit || ""}`;
        },
      },
    },
    plotOptions: {
      pie: {
        customScale: 1.0,
        offsetY: 20,
        dataLabels: {
          minAngleToShowLabel: 30,
          fontSize: "8px",
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: "10px",
      },
    },
  };
}

const generatePieChartDetailedOptions = (
  widgetPlacement,
  colors,
  cleanValueDict,
  chartSeries,
  labels,
  additionalData,
  goalLength
) => {
  cleanValueDict["chart_options"] = generatePieChartOptions(
    labels,
    widgetPlacement,
    colors
  );
  cleanValueDict["chart_series"] = chartSeries;
  cleanValueDict["additional_data"] = additionalData;
};
const generateLineChartOptions = (
  labels,
  widgetPlacement,
  colors,
  chartSeries,
  goalLength
) => {
  const unit = kpiDataMapping[widgetPlacement.kpi].chart_unit;
  return {
    legend: {
      show: false,
    },
    colors: colors,
    chart: {
      id: "basic-bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      dashArray: [
        ...Array.from(new Array(chartSeries.length - goalLength)).map(
          (elem) => 0
        ),
        ...Array.from(new Array(goalLength)).map((elem) => 4),
      ],
    },
    xaxis: {
      categories: labels,
      tickAmount: getTickAmount(widgetPlacement.widget, labels),
      tickPlacement: "between",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${Math.round(val)
            .toLocaleString("sv")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit || ""}`;
        },
      },
    },
  };
};

const generateBarChartOptions = (
  labels,
  widgetPlacement,
  colors,
  chartSeries,
  goalLength
) => {
  const unit = kpiDataMapping[widgetPlacement.kpi].chart_unit;
  return {
    legend: {
      show: false,
    },
    colors: colors,
    chart: {
      id: "basic-bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    dataLabels : {
      enabled: false
    },
    // dataLabels: {
    //       enabled: true,
    //       // textAnchor: 'start',
    //       style: {
    //         colors: ['#fff']
    //       },
    //       formatter: function (val, opt) {
    //         console.log(opt)
    //         return opt.w.config.series[opt.seriesIndex].name
    //       },
    //       position: "bottom",
    //       dropShadow: {
    //         enabled: true
    //       }
    //     },
    fill: {
      type: [
        ...Array.from(new Array(chartSeries.length - goalLength)).map(
          (elem) => "solid"
        ),
        ...Array.from(new Array(goalLength)).map((elem) => "pattern"),
      ],
      opacity: 1,
      pattern: {
        style: [
          ...Array.from(new Array(chartSeries.length)).map(
            (elem) => "slantedLines"
          ),
        ],
      },
    },
    xaxis: {
      categories: labels,
      tickAmount: getTickAmount(widgetPlacement.widget, labels),
      tickPlacement: "between",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${Math.round(val)
            .toLocaleString("sv")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit || ""}`;
        },
      },
    },
  };
};

const generateBarChartWithDataLabelsOptions = (
  labels,
  widgetPlacement,
  colors,
  chartSeries,
  goalLength
) => {
  const unit = kpiDataMapping[widgetPlacement.kpi].chart_unit;
  const options = generateBarChartOptions(labels, widgetPlacement, colors, chartSeries, goalLength)
  options["plotOptions"] = {
    bar: {
      dataLabels: {
        position: 'top'
      }
    }
  }
  options["dataLabels"] = {
    enabled: true,
        formatter: function (val) {
          return `${Math.round(val)
            .toLocaleString("sv")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit || ""}`;
        },
  offsetY: -20,
  style: {
    fontSize: '12px',
    colors: ["#304758"]
  }
  }
  return options
}

const generateBarChartPlusNegativeWithTrendOptions = (
  labels,
  widgetPlacement,
  colors,
  chartSeries,
  goalLength
) => {
  const unit = kpiDataMapping[widgetPlacement.kpi].chart_unit;
  return {
    legend: {
      show: false,
    },
    colors: colors,
    xaxis: {
      categories: labels,
      tickAmount: getTickAmount(widgetPlacement.widget, labels),
    },
    stroke: {
      dashArray: [
        ...Array.from(new Array(chartSeries.length - goalLength)).map(
          (elem) => 0
        ),
        ...Array.from(new Array(goalLength)).map((elem) => 4),
      ],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${Math.round(val)
            .toLocaleString("sv")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit || ""}`;
        },
      },
    },
  };
};
const generateMultipleYAxisOptions = (
  labels,
  widgetPlacement,
  colors,
  chartSeries,
  goalLength
) => {
  const units = kpiDataMapping[widgetPlacement.kpi]?.chart_units;
  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    stroke: {
      dashArray: [
        ...Array.from(new Array(chartSeries.length - goalLength)).map(
          (elem) => 0
        ),
        ...Array.from(new Array(goalLength)).map((elem) => 4),
      ],
    },
    yaxis: [
      {
        seriesName: kpiDataMapping[widgetPlacement.kpi]?.value_labels[0],
        axisTicks: {
          show: true,
        },
        labels: {
          formatter: function (val) {
            return `${Math.round(val)
              .toLocaleString("sv")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${units[0] || ""}`;
          },
        },
        title: {
          text: kpiDataMapping[widgetPlacement.kpi]?.value_labels[0],
        },
      },
      {
        seriesName: kpiDataMapping[widgetPlacement.kpi]?.value_labels[1],
        min: 0,
        max: 100,
        opposite: true,
        axisTicks: {
          show: true,
        },
        labels: {
          formatter: function (val) {
            return `${Math.round(val)
              .toLocaleString("sv")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${units[1] || ""}`;
          },
        },
        title: {
          text: kpiDataMapping[widgetPlacement.kpi]?.value_labels[1],
        },
      },
    ],
    xaxis: {
      categories: labels,
      tickAmount: getTickAmount(widgetPlacement.widget, labels),
    },
    legend: {
      show: false,
    },
  };
  Array.from({ length: goalLength }).map((elem) => {
    options.yaxis.push({
      seriesName: kpiDataMapping[widgetPlacement.kpi]?.value_labels[0],
      show: false,
      labels: {
        formatter: function (val) {
          return `${Math.round(val)
            .toLocaleString("sv")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${units[0] || ""}`;
        },
      },
    });
  });
  return options;
};

const generateOverviewDiffOptions = (
  widgetPlacement,
  colors,
  cleanValueDict,
  chartSeries,
  labels,
  additionalData,
  goalLength
) => {
  if (chartSeries?.length > 0) {
    const value_dict = chartSeries[0];
    if (
      !isNaN(value_dict["current_value"]) &&
      kpiDataMapping[widgetPlacement.kpi]?.value_formatter
    ) {
      cleanValueDict["current_value"] = kpiDataMapping[
        widgetPlacement.kpi
      ].value_formatter(value_dict.current_value);
    } else if (!isNaN(value_dict["current_value"])) {
      cleanValueDict["current_value"] = currencyFormatter(
        value_dict.current_value,
        widgetPlacement.kpi
      );
    }
    if (value_dict.compare_value) {
      cleanValueDict["diff_value"] = Math.round(
        (value_dict.current_value / value_dict.compare_value - 1) * 100
      );
    } else {
      cleanValueDict["diff_value"] = 0;
    }
    if (value_dict.value_count) {
      cleanValueDict["average_value"] = currencyFormatter(
        value_dict.value_sum / value_dict.value_count,
        widgetPlacement.kpi
      );
    } else {
      cleanValueDict["average_value"] = 0;
    }
    cleanValueDict["date_key"] = value_dict.date_key;
  } else {
    cleanValueDict["current_value"] = 0;
    cleanValueDict["diff_value"] = 0;
    cleanValueDict["average_value"] = 0;
    cleanValueDict["date_key"] = "";
  }
  cleanValueDict["quick_detail_text"] = "";
};

const generateQuickDetailOptions = (
  widgetPlacement,
  colors,
  cleanValueDict,
  chartSeries,
  labels,
  additionalData,
  goalLength
) => {
  generateOverviewDiffOptions(
    widgetPlacement,
    colors,
    cleanValueDict,
    chartSeries,
    labels,
    additionalData,
    goalLength
  );
  cleanValueDict["icon"] = kpiDataMapping[widgetPlacement.kpi].icon;
  cleanValueDict["color"] = kpiDataMapping[widgetPlacement.kpi].themeColor;
  cleanValueDict["quick_detail_text"] = kpiDataMapping[widgetPlacement.kpi].quick_detail_text;
};

const generateOverviewGeneralOptions = (
  widgetPlacement,
  colors,
  cleanValueDict,
  chartSeries,
  labels,
  additionalData,
  goalLength
) => {
  const value_dict = chartSeries[0];
  cleanValueDict["primary_value"] = currencyFormatter(
    value_dict.primary_value,
    widgetPlacement.kpi
  );
  cleanValueDict["primary_label"] = value_dict.primary_label;
  cleanValueDict["secondary_value"] = currencyFormatter(
    value_dict.secondary_value,
    widgetPlacement.kpi
  );
  cleanValueDict["secondary_label"] = value_dict.secondary_label;
  cleanValueDict["diff_value"] = currencyFormatter(
    value_dict.diff_value,
    widgetPlacement.kpi
  );
  cleanValueDict["diff_label"] = value_dict.diff_label;
  cleanValueDict["current_value"] = currencyFormatter(
    value_dict.current_value,
    widgetPlacement.kpi
  );
};

export function getInfoForWidget(widget) {
  return widgetInfo[widget];
}

export function traverseResultForWidget(widgetPlacement, result_dataset) {
  const widget = widgetPlacement.widget;
  let datapoints = [];
  const labelSet = new Set();
  let additionalData = {};
  traverseStandardResult(
    widgetPlacement,
    result_dataset,
    datapoints,
    labelSet,
    additionalData
  );

  let labels = Array.from(labelSet);
  if (labels.length === 1 && !labels[0]) labels = ["Total"];

  return [labels, datapoints, additionalData];
}

const getCompareDate = (timeDiff, intervalType) => {
  if (30 <= timeDiff && timeDiff < 61) {
    if (intervalType === "ytd") {
      return [
        moment().subtract({ month: 1 }).startOf("month").format("YYYY-MM-DD"),
        "månad",
      ];
    }
    return [
      moment().subtract({ month: 1 }).startOf("month").format("YYYY-MM-DD"),
      "månad",
    ];
  }
  if (61 <= timeDiff && timeDiff < 183) {
    if (intervalType === "ytd") {
      return [
        moment().subtract({ month: 1 }).startOf("month").format("YYYY-MM-DD"),
        "månad",
      ];
    }
    return [
      moment().subtract({ month: 1 }).startOf("month").format("YYYY-MM-DD"),
      "månad",
    ];
  }
  if (183 <= timeDiff && timeDiff < 730) {
    if (intervalType === "ytd") {
      return [
        moment().subtract({ month: 3 }).startOf("month").format("YYYY-MM-DD"),
        "3 mån.",
      ];
    }
    return [
      moment().subtract({ month: 3 }).startOf("month").format("YYYY-MM-DD"),
      "3 mån.",
    ];
  }

  if (730 <= timeDiff) {
    if (intervalType === "ytd") {
      return [
        moment().subtract({ year: 1 }).startOf("month").format("YYYY-MM-DD"),
        "år",
      ];
    }

    return [
      moment().subtract({ year: 1 }).startOf("month").format("YYYY-MM-DD"),
      "år",
    ];
  } else return [null, null];
};
const evaluateSingleTimeBasedResult = (
  result_dataset,
  widgetPlacement,
  datapoints,
  label_set,
  legend,
  additional_params
) => {
  if (WIDGET_CHOICES_OVERVIEW.includes(widgetPlacement.widget)) {
    const timeDiff =
      (Date.parse(additional_params["end"]) -
        Date.parse(additional_params["start"])) /
      (1000 * 60 * 60 * 24);
    const [compareDate, dateKey] = getCompareDate(
      timeDiff,
      widgetPlacement.interval_type
    );
    const startDate = new Date(additional_params["start"]);
    startDate.setDate(startDate.getDate() + timeDiff / 2);
    startDate.setDate(1);

    // removing timestamp from Date
    const startAverageDate = new Date(startDate.toDateString());

    let seriesHolder = {};

    if (datapoints.length === 0) {
      seriesHolder = {
        current_value: 0,
        value_sum: 0,
        value_count: 0,
        compare_value: 0,
        date_key: dateKey,
      };
      datapoints.push(seriesHolder);
    } else {
      seriesHolder = datapoints[0];
    }

    result_dataset.forEach((subItem) => {
      const subItemStart = new Date(new Date(subItem["start"]).toDateString());
      if (subItem["start"] === compareDate)
        seriesHolder.compare_value += subItem["value"][0];
      if (subItemStart >= startAverageDate) {
        seriesHolder.value_sum += subItem["value"][0];
        seriesHolder.value_count += 1;
      }
      if (subItem["end"] === additional_params["end"]) {
        seriesHolder.current_value += subItem["value"][0];
      }
    });
  } else {
    const seriesHolder = {
      data: [],
      name: legend.join(LEGEND_DELIMITER),
    };
    result_dataset.forEach((subItem) => {
      if (widgetPlacement.kpi === KPI_TOP_TENANT && subItem["value"][0] === 0) seriesHolder.data.push(null)
      else seriesHolder.data.push(subItem["value"][0]);
      label_set.add(subItem["verbose_date"]);
    }, datapoints.push(seriesHolder));
  }
};

const evaluateDoubleResult = (
  result_dataset,
  widgetPlacement,
  datapoints,
  label_set,
  legend,
  additional_params
) => {
  const value_labels = kpiDataMapping[widgetPlacement.kpi]?.value_labels;
  let tripleResult =
    widgetInfo[widgetPlacement.widget]["chart_types"]?.length === 3
      ? true
      : false;

  if (widgetPlacement.widget === WIDGET_OVERVIEW_GENERAL) {
    let seriesHolder = {};

    if (datapoints.length === 0) {
      seriesHolder = {
        primary_value: 0,
        primary_label: value_labels[0],
        secondary_value: 0,
        secondary_label: value_labels[1],
        diff_value: 0,
        diff_label: value_labels[2],
      };
      datapoints.push(seriesHolder);
    } else {
      seriesHolder = datapoints[0];
    }

    result_dataset.forEach((subItem) => {
      seriesHolder.primary_value += subItem["value"][0];
      seriesHolder.secondary_value += subItem["value"][1];
    });
    seriesHolder.diff_value = seriesHolder.secondary_value < 0 ? seriesHolder.primary_value + seriesHolder.secondary_value : seriesHolder.secondary_value - seriesHolder.primary_value
  } else if (widgetPlacement.widget === WIDGET_QUICK_DETAIL) {
      const seriesHolder = {
        current_value: 0,
      };
      result_dataset.forEach((subItem) => {
        seriesHolder.current_value += (subItem["value"][0] - subItem["value"][1])
      })
      datapoints.push(seriesHolder);

  } else if (widgetPlacement.widget === WIDGET_LINECHART) {
    const primarySeriesHolder = {
      data: [],
      name: kpiDataMapping[widgetPlacement.kpi]["value_labels"][0],
    };
    const secondarySeriesHolder = {
      data: [],
      name: kpiDataMapping[widgetPlacement.kpi]["value_labels"][1],
    };
    result_dataset.forEach((subItem) => {
      primarySeriesHolder.data.push(subItem["value"][0]);
      secondarySeriesHolder.data.push(subItem["value"][1]);
      label_set.add(subItem["verbose_date"]);
    })
    datapoints.push(primarySeriesHolder, secondarySeriesHolder)
  } else {
    const primarySeriesHolder = {
      data: [],
      type: widgetInfo[widgetPlacement.widget]["chart_types"][0],
      name: kpiDataMapping[widgetPlacement.kpi]["value_labels"][0],
    };
    const secondarySeriesHolder = {
      data: [],
      type: widgetInfo[widgetPlacement.widget]["chart_types"][1],
      name: kpiDataMapping[widgetPlacement.kpi]["value_labels"][1],
    };
    const diffSeriesHolder = {
      data: [],
      type: tripleResult
        ? widgetInfo[widgetPlacement.widget]["chart_types"][2]
        : null,
      name: tripleResult
        ? kpiDataMapping[widgetPlacement.kpi]["value_labels"][2]
        : null,
    };

    result_dataset.forEach((subItem) => {
      primarySeriesHolder.data.push(subItem["value"][0]);
      secondarySeriesHolder.data.push(subItem["value"][1]);
      diffSeriesHolder.data.push(subItem["value"][0] + subItem["value"][1]);

      label_set.add(subItem["verbose_date"]);
    });
    if (tripleResult) {
      datapoints.push(
        primarySeriesHolder,
        secondarySeriesHolder,
        diffSeriesHolder
      );
    } else {
      datapoints.push(primarySeriesHolder, secondarySeriesHolder);
    }
  }
};

const evaluateDonutResult = (
  result_dataset,
  widgetPlacement,
  datapoints,
  label_set,
  legend,
  additional_params
) => {
  result_dataset.forEach((subItem) => {
    datapoints.push(subItem["value"][0]);
  })
  label_set.add(legend[0])
}

// All endpoints provide a "value" key which is an array in length depending on the KPI, where each element is a certain category of values
// each of these methods derives the proper values for each KPI
const getResultEvaluationMethod = (widgetPlacement) => {
  const kpi = widgetPlacement.kpi
  const widget = widgetPlacement.widget

  if (widget === WIDGET_DONUTCHART) {
    return evaluateDonutResult;
  }

  switch (kpi) {
    case KPI_NET_LEASTING:
    case KPI_ACTUAL_VS_FORECAST_INVOICING:
    case KPI_ECONOMIC_VACANCY: {
      return evaluateDoubleResult;
    }
    default:
      return evaluateSingleTimeBasedResult;
  }
};

const traverseStandardResult = (
  widgetPlacement,
  result_dataset,
  datapoints,
  label_set,
  additional_params,
  legend = []
) => {
  const aggregate_legend = result_dataset["aggregate_legend"];
  if (!additional_params?.start || !additional_params?.end) {
    additional_params["start"] = result_dataset["start"];
    additional_params["end"] = result_dataset["end"];
    additional_params["verbose_date"] = result_dataset["verbose_date"];
  }
  if (result_dataset["data"] != undefined) {
    Object.keys(result_dataset["data"]).forEach((key) => {
      legend.push(aggregate_legend[key]);
      traverseStandardResult(
        widgetPlacement,
        result_dataset["data"][key],
        datapoints,
        label_set,
        additional_params,
        legend
      );
      // we have gone all the way down to the bottom-est node and we can now remove the last legend accordingly
      legend.pop();
    });
  } else {
    if (legend.length === 1 && legend[0] === "values") kpiDataMapping[widgetPlacement?.kpi].value_labels ? legend = [kpiDataMapping[widgetPlacement?.kpi].value_labels[0]] : legend = ["Värde"];
    getResultEvaluationMethod(widgetPlacement)(
      result_dataset,
      widgetPlacement,
      datapoints,
      label_set,
      legend,
      additional_params
    );
  }
};

const mainGoalPrepper = (goal, ind, chartSeries) => {
  chartSeries.push({
    data: [
      ...Array.from(new Array(chartSeries?.[0].data.length)).map(
        (elem) => goal.value
      ),
    ],
    name: `${goal?.str_representation} ${ind + 1}`,
  });
};
const barChartNegativeGoalPrepper = (goal, ind, chartSeries) => {
  chartSeries.push({
    type: "line",
    data: [
      ...Array.from(new Array(chartSeries?.[0].data.length)).map(
        (elem) => goal.value
      ),
    ],
    name: `${goal?.str_representation} Nettouthyrning ${ind + 1}`,
  });
};
const doubleResultGoalPrepper = (goal, ind, chartSeries) => {
  chartSeries.push({
    type: "line",
    data: [
      ...Array.from(new Array(chartSeries?.[0].data.length)).map(
        (elem) => goal.value
      ),
    ],
    name: `${goal?.str_representation} Total vakans (kr) ${ind + 1}`,
  });
};

export const prepGoalsForWidget = (widget, goals, chartSeries) => {
  const goalPrepper = getInfoForWidget(widget)["goalPrepper"];
  let achieved = 0;
  let achievedGoals = [];
  let notAchieved = 0;
  let notAchievedGoals = [];
  goals.map((goal, ind) => {
    if (goal.achieved) {
      achieved += goal.current_value / goal.value - 1;
      achievedGoals.push(goal);
    } else {
      notAchieved += goal.current_value / goal.value - 1;
      notAchievedGoals.push(goal);
    }
    if (goalPrepper) {
      goalPrepper(goal, ind, chartSeries);
    }
  });
  const meanAchievedDiff =
    achievedGoals.length > 0 ? achieved / achievedGoals.length : 0;
  const meanNotAchievedDiff =
    notAchievedGoals.length > 0 ? notAchieved / notAchievedGoals.length : 0;
  if (Math.abs(meanAchievedDiff) > Math.abs(meanNotAchievedDiff)) {
    return [achieved, achievedGoals, true];
  } else {
    return [notAchieved, notAchievedGoals, false];
  }
};

// widget either has apex_chart_type (can be undefined) or internal_component
export const widgetInfo = {
  [WIDGET_LINECHART]: {
    id: WIDGET_LINECHART,
    title: "Linjediagram",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/line_beta.png",
    width: 3,
    height: 2,
    apex_chart_type: "line",
    chart_options: generateLineChartOptions,
    goalPrepper: mainGoalPrepper,
  },
  [WIDGET_BARCHART]: {
    id: WIDGET_BARCHART,
    title: "Stapeldiagram",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/barchart_beta.png",
    width: 3,
    height: 2,
    apex_chart_type: "bar",
    chart_options: generateBarChartOptions,
    goalPrepper: mainGoalPrepper,
  },
  [WIDGET_BARCHART_WITH_DATA_LABELS]: {
    id: WIDGET_BARCHART_WITH_DATA_LABELS,
    title: "Stapeldiagram med etikett",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/barchart_with_data_labels_beta.png",
    width: 3,
    height: 2,
    apex_chart_type: "bar",
    chart_options: generateBarChartWithDataLabelsOptions,
    goalPrepper: mainGoalPrepper,
  },
  [WIDGET_DONUTCHART]: {
    id: WIDGET_DONUTCHART,
    title: "Cirkeldiagram",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/donutchart_beta.png",
    width: 2,
    height: 2,
    apex_chart_type: "donut",
    chart_options: generatePieChartOptions,
    goalPrepper: undefined,
  },
  [WIDGET_DONUTCHART_DETAILED]: {
    id: WIDGET_DONUTCHART_DETAILED,
    title: "Cirkeldiagram detaljerad",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/donutchart_detailed.png",
    width: 3,
    height: 2,
    internal_component: DonutChartDetailed,
    chart_options: generatePieChartDetailedOptions,
    goalPrepper: undefined,
  },
  [WIDGET_OVERVIEW_DIFF]: {
    id: WIDGET_OVERVIEW_DIFF,
    title: "Översikt med skillnad",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/overview_diff_beta.png",
    width: 1,
    height: 1,
    internal_component: OverviewDiff,
    chart_options: generateOverviewDiffOptions,
    double_intervals: true,
    goalPrepper: undefined,
  },
  [WIDGET_OVERVIEW_GENERAL]: {
    id: WIDGET_OVERVIEW_GENERAL,
    title: "Översikt",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/overview_general.png",
    width: 1,
    height: 1,
    internal_component: OverviewGeneral,
    chart_options: generateOverviewGeneralOptions,
    goalPrepper: undefined,
  },
  [WIDGET_BARCHART_NEGATIVE_TRENDLINE]: {
    id: WIDGET_BARCHART_NEGATIVE_TRENDLINE,
    title: "Stapeldiagram med negativt värde och trendlinje",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/barchart_negative_trendline_beta.png",
    width: 3,
    height: 2,
    // undefined so we can render multiple chart types in the same component
    apex_chart_type: undefined,
    chart_types: ["bar", "bar", "line"],
    chart_options: generateBarChartPlusNegativeWithTrendOptions,
    goalPrepper: barChartNegativeGoalPrepper,
  },
  [WIDGET_QUICK_DETAIL]: {
    id: "quick_detail",
    title: "Snabbfakta",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/quick_detail.png",
    width: 1,
    height: 1,
    internal_component: QuickDetail,
    chart_options: generateQuickDetailOptions,
    goalPrepper: undefined,
  },
  [WIDGET_MULTIPLE_Y_AXIS]: {
    id: "multiple_y_axis",
    title: "Multi-Y axlar",
    image_url:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/multiple_y_axis_beta.png",
    width: 3,
    height: 2,
    apex_chart_type: undefined,
    chart_types: ["line", "line"],
    chart_options: generateMultipleYAxisOptions,
    goalPrepper: doubleResultGoalPrepper,
  },
};

export function getFilteredWidgets(filter) {
  if (!filter || !filter["kpi"]) return [];

  let filteredWidgetsDict = {};
  let filteredWidgets = [];
  const areas = filter["areas"];
  for (let tic = 0; tic < Math.round(areas.length / 2); tic++) {
    let width = areas[tic * 2];
    let height = areas[tic * 2 + 1];

    Object.keys(widgetInfo).forEach((key) => {
      if (
        !(key in filteredWidgetsDict) &&
        kpiDataMapping[filter["kpi"]]["allowed_widgets"].includes(key) &&
        widgetInfo[key]["width"] <= width &&
        widgetInfo[key]["height"] <= height
      ) {
        filteredWidgetsDict[key] = true;
        filteredWidgets.push(getInfoForWidget(key));
      }
    });
  }
  return filteredWidgets;
}
