import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import companyIcon from "../../../assets/svg/company.svg";
import editIcon from "../../../assets/svg/edit.svg";
import infoIcon from "../../../assets/svg/info.svg";
import menuIcon from "../../../assets/svg/material/menu_black.svg";
import closeIcon from "../../../assets/svg/material/close-black.svg";
import arrowIcon from "../../../assets/svg/material/keyboard-arrow-down-black.svg";

export const MainWrapper = styled.div.attrs((props) => ({
  id: "ApplicationMainSidebar",
}))`
  width: ${(p) => (p.open ? "250px" : "75px")};
  min-width: ${(p) => (p.open ? "250px" : "75px")};
  min-height: 100vh;
  border-right: ${(p) => p.theme.borders.standard};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  color: ${(p) => p.theme.colors.primaryText};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  padding-bottom: 140px;
  position: relative;
`;

// export const MainWrapper = styled.div`
//   width: ${(p) => (p.open ? "250px" : "75px")};
//   min-width: ${(p) => (p.open ? "250px" : "75px")};
//   min-height: 100vh;
//   border-right: ${(p) => p.theme.borders.standard};
//   background-color: #fff;
//   display: flex;
//   flex-direction: column;
//   font-family: "Inter", sans-serif;
//   color: ${(p) => p.theme.colors.primaryText};
//   font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
//   font-size: ${(p) => p.theme.fontSizes.headerXSmall};
//   padding-bottom: 140px;
//   position: relative;
// `;

export const LogoWrapper = styled.div`
  cursor: pointer;
  width: 250px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(p) =>
    !p.noBorder &&
    css`
      border-bottom: 2px solid whitesmoke;
    `}

  ${(p) =>
    p.borderRight &&
    css`
      border-right: ${(p) => p.theme.borders.standard};
      margin-right: 24px;
    `}

  & > svg {
    width: 160px;
  }
`;

export const MiniLogoWrapper = styled.div`
  cursor: pointer;
  width: ${(p) => (p.overrideWidth ? `${p.overrideWidth}px` : "75px")};
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(p) =>
    !p.noBorder &&
    css`
      border-bottom: 2px solid whitesmoke;
    `}

  ${(p) =>
    p.borderRight &&
    css`
      border-right: ${(p) => p.theme.borders.standard};
      margin-right: 24px;
    `}

  & > svg {
    width: 45px;
  }
`;

export const ExpandMenuIcon = styled.div`
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${menuIcon});
  opacity: 0.8;

  cursor: pointer;
`;

export const CloseMenuIcon = styled.div`
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeIcon});
  opacity: 0.8;
  cursor: pointer;
`;

export const Chapter = styled.div`
  margin: 12px 0 12px 30px;
  display: flex;
  flex-direction: column;

  ${(p) =>
    p.closedMode &&
    css`
      margin: 12px 0 12px 12px;
    `}
`;

export const SidebarToggleItem = styled.div`
  cursor: pointer;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  color: ${(p) => p.theme.colors.secondaryText};
  margin: ${(p) => (p.inner ? "1px 0 1px 24px" : "4px 0")};
  position: relative;

  background-color: ${(p) => (p.isOpen ? " #f1f1f1" : "#fff")};
  align-self: flex-start;
  padding: ${(p) =>
    p.onClick || p.filterActive ? "4px 36px 4px 36px" : "4px 14px 4px 36px"};
  border-radius: 30px;
  transition: ${(p) =>
    p.closedMode ? "" : "background-color, color 0.2s ease-in-out"};

  &:hover {
    background-color: #f1f1f1;
  }

  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => p.theme.colors.blueLight};
      color: ${(p) => p.theme.colors.primaryBlue};

      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed !important;
      color: ${p.theme.colors.secondaryText};

      &:hover {
        color: ${p.theme.colors.secondaryText};
      }
    `}

    ${(p) =>
    p.closedMode &&
    css`
      width: 18px;
      max-width: 18px;
      height: 18px;
      border-radius: 50%;
      ${"" /* margin: 4px 0px 8px 14px; */}
      margin:0 0 0 6px;
      padding: 18px;
    `}


    ${(p) =>
    p.icon &&
    css`
      &:before {
        position: absolute;
        top: calc(50% - 9px);
        left: ${(p) => (p.closedMode ? "9px" : "12px")};
        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        content: "";
        background-position: center;
        background-size: contain;
        background-image: url(${p.icon});
      }
    `}

    ${(p) =>
    p.onClick &&
    !p.closedMode &&
    css`
      &:after {
        position: absolute;
        top: calc(50% - 10px);
        right: 12px;
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        content: "";
        background-position: center;
        background-size: contain;
        background-image: url(${arrowIcon});
        transform: ${(p) => (p.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
        transition: all 0.2s ease-in-out;
      }
    `}


    ${(p) =>
    p.filterActive &&
    css`
      &:after {
        position: absolute;
        top: calc(50% - 5px);
        right: ${(p) => (p.closedMode ? "-6px" : "12px")};
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: ${(p) => p.theme.colors.primaryBlueFaded};
        box-shadow: 0px 0px 6px 2px ${(p) => p.theme.colors.primaryBlueFaded};
        content: "";
      }
    `}
`;

export const SidebarItem = styled(Link)`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  color: ${(p) => p.theme.colors.secondaryText};
  margin: ${(p) => (p.inner ? "1px 0 1px 24px" : "4px 0")};

  ${(p) =>
    p.inner &&
    p.last &&
    css`
      margin-bottom: 12px;
    `}
  position: relative;

  background-color: ${(p) => (p.isOpen ? " #f1f1f1" : "#fff")};
  align-self: flex-start;
  padding: ${(p) =>
    p.filterActive ? "4px 36px 4px 36px" : "4px 14px 4px 36px"};
  border-radius: 30px;
  transition: ${(p) =>
    p.closedMode ? "" : "background-color, color 0.2s ease-in-out"};

  &:hover {
    background-color: #f1f1f1;
  }

  ${(p) =>
    p.inner &&
    css`
      z-index: 10;

      ${(p) =>
        p.closedModeInner &&
        css`
          min-width: 200px;
          border-radius: 0;
          margin: 0 0 0 24px;
          box-shadow: ${(p) => p.theme.boxShadows.small};
        `}
    `}

  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => p.theme.colors.blueLight};
      color: ${(p) => p.theme.colors.primaryBlue};

      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
        color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed !important;
      color: ${p.theme.colors.secondaryText};

      &:hover {
        color: ${p.theme.colors.secondaryText};
      }
    `}

    ${(p) =>
    p.closedMode &&
    css`
      width: 18px;
      max-width: 18px;
      height: 18px;
      border-radius: 50%;
      ${"" /* margin: 4px 0px 8px 14px; */}
      margin:0 0 0 6px;
      padding: 18px;
    `}


    ${(p) =>
    p.icon &&
    css`
      &:before {
        position: absolute;
        top: calc(50% - 9px);
        left: ${(p) => (p.closedMode ? "9px" : "12px")};
        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        content: "";
        background-position: center;
        background-size: contain;
        background-image: url(${p.icon});
      }
    `}


    ${(p) =>
    p.filterActive &&
    css`
      &:after {
        position: absolute;
        top: calc(50% - 5px);
        right: ${(p) => (p.closedMode ? "-6px" : "12px")};
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: ${(p) => p.theme.colors.primaryBlueFaded};
        box-shadow: 0px 0px 6px 2px ${(p) => p.theme.colors.primaryBlueFaded};
        content: "";
      }
    `}
`;

export const Wrapper = styled.div`
  width: 250px;
  min-height: calc(100vh - 50px);
  border: 1px solid ${(p) => p.theme.colors.gray};
  box-shadow: ${(p) => p.theme.boxShadows.discrete};
  background-color: #fff;
  flex: 0 1;
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

export const RealEstateItem = styled.div`
  background-color: #fff;
  color: ${(p) => p.theme.colors.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grayLight};
`;

export const RealEstateImage = styled.div`
  background-color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  box-shadow: ${(p) => p.theme.boxShadows.standard};

  background-size: ${(p) => (p.src ? "cover" : "65%")};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(p) => (p.src ? p.src : companyIcon)});
  margin-bottom: 12px;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoButton = styled.div`
  cursor: pointer;
  position: relative;
  padding-left: 1rem;
  font-size: 0.7rem;
  font-weight: 600;

  &::before {
    position: absolute;
    top: calc(50% - 0.4rem);
    left: 0;
    content: "";
    height: 0.8rem;
    width: 0.8rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${infoIcon});
  }
`;

export const EditButton = styled.div`
  cursor: pointer;
  position: relative;
  padding-left: 1rem;
  font-size: 0.7rem;
  font-weight: 600;

  &::before {
    position: absolute;
    top: calc(50% - 0.4rem);
    left: 0;
    content: "";
    height: 0.8rem;
    width: 0.8rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${editIcon});
  }
`;

export const RealEstateTitle = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
`;

export const Item = styled(Link)`
  padding: 12px 14px;
  color: ${(p) => p.theme.colors.primary};
  font-size: 0.9rem;
  border-bottom: 1px solid ${(p) => p.theme.colors.gray};
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${(p) =>
    p.isActive &&
    css`
      background-color: ${(p) => p.theme.colors.activeGray};
      color: ${(p) => p.theme.colors.activePrimary};

      &:hover {
        background-color: ${(p) => p.theme.colors.activeGray};
        color: ${(p) => p.theme.colors.activePrimary};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed !important;
      opacity: 0.5;
    `}
`;

export const ChapterTitle = styled.div`
  font-weight: 600;
  padding: 12px 14px;
  font-size: 0.8rem;
  padding-left: 12px;
  text-transform: uppercase;

  color: ${(p) => p.theme.colors.textSecondary};
`;

export const InfoHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;

export const InfoItem = styled.div`
  margin: 8px 0;
`;

export const NotificationDot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  font-size: 12px;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.primaryBlue};
  border: thin solid ${(p) => p.theme.colors.primaryBlueFaded};
  border-radius: 50%;
  margin-left: 8px;
`;

export const ActionDot = styled.div`
  position: absolute;
  top: 2px;
  left: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  width: 14px;
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  font-size: 8px;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.primaryBlue};
  border: thin solid ${(p) => p.theme.colors.primaryBlueFaded};
  border-radius: 50%;
`;
