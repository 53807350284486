import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  usePaymentContributionForm,
  constants,
  create,
  update,
  destroyPostForm,
  destroyPatchForm,
  usePaymentContribution,
} from "../../../../store/paymentContribution";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import { setActiveFormInstance, updateActiveFormInstance } from "../../../../store/base";
import { usePaymentGroup } from "../../../../store/paymentGroup";
import { useBrfPremises } from "../../../../store/brfPremises";

export default function BrfPaymentContributionForm({ method = "POST", id, paymentGroupId, brfPremisesId }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(usePaymentContributionForm(method, id));
  const { replace, goBack } = useHistory();

  const [instance, instanceLoading] = usePaymentContribution(id);
  const [hasSetInstance, setHasSetInstance] = React.useState(false);
  
  const [paymentGroup, paymentGroupQuotaLoading] = usePaymentGroup(paymentGroupId)
  const [brfPremises, brfPremisesLoading] = useBrfPremises(brfPremisesId)

  const [hasSetDefaults, setHasSetDefaults] = React.useState(false);

  React.useEffect(() => {
    if (!hasSetInstance && instance != null) {
      dispatch(setActiveFormInstance({
        storeName: storeName,
        data: instance
      }));

      setHasSetInstance(true);
    }
  }, [hasSetInstance, instance])

  React.useEffect(() => {
    if (id != null) { return; }

    if (!hasSetDefaults && (!!paymentGroup || !!brfPremises) && (paymentGroupId == null || !!paymentGroup) && (brfPremisesId == null || !!brfPremises)) {
      const obj = {"payment_group":paymentGroup, "brf_premis":brfPremises};
      dispatch(updateActiveFormInstance({
        storeName: storeName,
        data: obj
      }));

      setHasSetDefaults(true);
    }

  }, [paymentGroup, brfPremises, hasSetDefaults])

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    goBack();
  };

  React.useEffect(() => {
    return () => {
      if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      } else {
        dispatch(destroyPostForm(false));
      }
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    } else {
      dispatch(destroyPostForm(success));
    }
  };

  const onSubmit = () => {
    setLoading(true);

    if (method === "PATCH") {
      dispatch(
        update({
          successCallback: onSuccess,
          errorCallback: onError,
          id,
        })
      );
    } else {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: onError
        })
      );
    }
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,

          descriptions,
          method,
          onSubmit,

          title:
            method === "POST" ? "Lägg till Skuld/Betalningslogg" : "Uppdatera Skuld/Betalningslogg",
        }}
      />
    </>
  );
}
