import * as React from "react";

import { DatePicker, NumberInput } from "../../../components/Forms/Base/Fields";
import StandardModal from "../../../components/Modals/StandardModal";

import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { DateCell } from "../../../components/Displays";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";

import {
  constants,
  update as updateLoan,
  useLoanForm,
  destroyPatchForm,
} from "../../../store/loan";

import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { cloneDeep } from "lodash";
import { uid } from "uid";

import Table from "../../../components/Billecta/Table/BasicTable";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../store/base";
import { useDispatch } from "react-redux";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { InnerBox } from "../../../components/sharedStyles";

export default function HandleInterest({ loan }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => dispatch(destroyPatchForm(true));
  }, []);

  const method = "PATCH";
  const storeName = constants.STORE_NAME;

  useLoanForm(method, loan.id);

  const [handleCostId, setHandleCostId] = React.useState(null);
  const [formDirty, setFormDirty] = React.useState(false);
  const [loadingPayments, setLoadingPayments] = React.useState(false);

  const formCosts = useFormInstanceField({
    storeName,
    fieldKey: "adjustedloaninterest_set",
  });
  const costErrors = useFormError({
    storeName,
    fieldKey: "adjustedloaninterest_set",
  });

  React.useEffect(() => {
    // not fetched costs yet
    if (formDirty || !loan) {
      return;
    }

    dispatch(
      setActiveFormInstance({
        storeName,
        data: loan,
      })
    );

    setFormDirty(true);
  }, [formDirty, loan]);

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Räntesats",
        accessor: "value",
        Cell: ({ value }) => <div>{value} %</div>,
      },
      {
        Header: "Gäller f.o.m",
        accessor: "from_date",
        Cell: ({ value }) => <DateCell date={value} />,
      },
    ];
  }, [loan]);

  const onUpdate = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostId(null);
  };

  const onRemove = (id) => {
    const costsCopy = cloneDeep(formCosts);

    const idx = costsCopy.findIndex((c) => (c.id || c._tempId) === id);

    costsCopy.splice(idx, 1);

    setHandleCostId(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          adjustedloaninterest_set: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );
  };

  const handleEditCost = (row) => {
    setHandleCostId(row.original.id || row.original._tempId);
  };

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  const onUpdateSuccess = (_, returnedData) => {
    setLoadingPayments(false);

    dispatch(
      setActiveFormInstance({
        storeName,
        data: returnedData,
      })
    );
  };
  const onUpdateError = () => {
    setLoadingPayments(false);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!!dataClone.adjustedloaninterest_set?.length) {
      dataClone.adjustedloaninterest_set.forEach((c) => {
        c.loan = { id: loan.id };
      });
    }

    return dataClone;
  };

  const updateCosts = () => {
    setLoadingPayments(true);
    dispatch(
      updateLoan({
        id: loan.id,
        successCallback: onUpdateSuccess,
        errorCallback: onUpdateError,
        preProcess: preProcess,
      })
    );
  };

  const canUpdate = checkCanUpdatePayments({
    formCosts,
    object: loan,
  });

  const handleAddCost = () => {
    const costsCopy = [...(formCosts || [])];
    const tempId = uid();

    costsCopy.push({ _tempId: tempId });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          adjustedloaninterest_set: costsCopy,
        },
      })
    );

    setHandleCostId(tempId);
  };

  const currentCost = formCosts?.find(
    (c) => c.id === handleCostId || c._tempId === handleCostId
  );
  const currentCostIndex = formCosts?.findIndex(
    (c) => c.id === handleCostId || c._tempId === handleCostId
  );

  const [editActive, setEditActive] = React.useState(false);

  // Set new costs to edit
  React.useEffect(() => {
    if (handleCostId != null && currentCost) {
      setEditActive(true);
    }
  }, [handleCostId, currentCost]);

  const infoObj = {
    Detaljer: [
      {
        title: "Räntesats",
        value: currentCost?.value != null ? `${currentCost.value} %` : "-",
      },
      {
        title: "Gäller f.o.m",
        value: <DateCell date={currentCost?.from_date} />,
      },
    ],
  };

  return (
    <>
      <StandardModal
        isOpen={handleCostId !== null}
        closeFunction={
          editActive ? () => setEditActive(false) : () => setHandleCostId(null)
        }
        title={`${editActive ? "Redigera" : "Hantera"} räntevärde`}
        withActionBar
        actionBarAcceptTitle={editActive ? "Klar" : "Redigera"}
        actionBarCancelTitle={editActive ? "Tillbaka" : "Stäng"}
        saveFunction={editActive ? onUpdate : () => setEditActive(true)}
      >
        {!editActive ? (
          <>
            <DetailInfo infoObj={infoObj} />

            <TextButton
              title="Radera rad"
              clicked={() => onRemove(handleCostId)}
              red
              iconType="close"
              iconPlacement="right"
              extraStyle={{ marginTop: 24 }}
            />
          </>
        ) : (
          <>
            <NumberInput
              title="Räntesats"
              storeName={storeName}
              method={method}
              fieldKey={`adjustedloaninterest_set[${currentCostIndex}].value`}
              instructionsKey="adjustedloaninterest_set.value"
            />
            <DatePicker
              title="Gäller f.o.m"
              storeName={storeName}
              method={method}
              fieldKey={`adjustedloaninterest_set[${currentCostIndex}].from_date`}
              instructionsKey="adjustedloaninterest_set.from_date"
            />
          </>
        )}
      </StandardModal>

      {loadingPayments && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Räntevärden</OverviewTitle>
          <OverviewSubtitle>
            Tryck på en rad för att redigera den. För att spara ändringar, tryck
            på "Spara uppdateringar" längst ner.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
        <PrimaryButton title="Lägg till rad" clicked={handleAddCost} />
      </OverviewTitleWrapper>

      {!!formCosts?.length ? (
        <>
          <Table
            onRowClicked={handleEditCost}
            columns={tableColumns}
            data={formCosts}
            checkRowError={checkRowError}
            hideSearch
          />
        </>
      ) : (
        <InnerBox
          style={{
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Inga räntevärden tillagda
        </InnerBox>
      )}
      {canUpdate && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <PrimaryButton
            pulse
            title="Spara uppdateringar"
            clicked={updateCosts}
          />
        </div>
      )}
    </>
  );
}

function checkCanUpdatePayments({ formCosts, object }) {
  const existingCosts = object?.adjustedloaninterest_set;

  if (!existingCosts?.length && !formCosts?.length) return false;

  let hasChange = false;

  if (existingCosts?.length !== formCosts?.length) return true;
  if (!existingCosts?.length && !formCosts?.length) return false;

  existingCosts.forEach((cost) => {
    const match = formCosts.find((fCost) => {
      return fCost.id === cost.id;
    });

    if (!match) {
      hasChange = true;
      return;
    }

    if (match.value !== cost.value || match.from_date !== cost.from_date) {
      hasChange = true;
    }
  });

  return hasChange;
}
