import * as React from "react";

import { ToolTipCell, EmailTag, PhoneTag, BooleanLabel } from "../../Displays";
import AnonymousCell from "../../Displays/AnonymousCell";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import StateFilter, { IsActive, falsySort } from "./Filters/StateFilter";

export const getMemberDisplay = (state) => {
  if (state === 0 || state === 1) return "Nuvarande medlem";

  return "Ej nuvarande medlem";
};
export const getMemberState = (state) => {
  if (state === 0 || state === 1) return 0;

  return 3;
};

export default (
  realEstates,
  isMembers = false,
  hasTenantPortal,
  hasBillectaViewPermission
) => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }) => (
        <StatusLabel
          state={
            isMembers ? getMemberState(row.original.state) : row.original.state
          }
          contentType="tenant"
        >
          {isMembers
            ? getMemberDisplay(row.original.state)
            : row.original.state_display}
        </StatusLabel>
      ),
      Filter: StateFilter,
      filter: "textExact",
    },

    {
      Header: "Namn",
      accessor: "str_representation",
      Cell: ({ value, row }) =>
        !!row?.original?.user?.anonymization ? (
          <AnonymousCell />
        ) : (
          <ToolTipCell text={value} />
        ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Person/org-nr",
      accessor: "user.legal_id",
      Cell: ({ value, row }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Hyresgästnummer",
      accessor: "customer_id",
      Cell: ({ value, row }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Email",
      accessor: "user.email",
      Cell: ({ value }) => <EmailTag mail={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Tel-nummer",
      accessor: "user.phone",
      Cell: ({ value }) => <PhoneTag phone={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
      sortType: falsySort,
    },

    {
      Header: "Fastigheter",
      accessor: "user.realestates",
      Cell: ({ value }) => {
        const rs = realEstates?.filter((b) => {
          return value?.some((r) => r.id === b.id);
        });
        const valueCheck = rs?.map((r) => r.str_representation)?.join(", ");
        return <ToolTipCell text={valueCheck} />;
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
  ];

  // Disabled for now
  if (hasBillectaViewPermission) {
    cols.splice(6, 0, {
      Header: "Konfigurerad för avisering",
      accessor: "debtor_invoice_config",
      Cell: ({ value }) => {
        const valueCheck = !!value?.id;
        return (
          <StatusLabel state={valueCheck ? 0 : 3}>
            {valueCheck ? "Konfigurerad" : "Ej konfigurerad"}
          </StatusLabel>
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      // Filter: IsConfigured,
      // filter: "textExact",
    });
  }

  if (hasTenantPortal) {
    cols.splice(6, 0, {
      Header: `Aktiv i "Mina sidor"`,
      accessor: "user.is_active",
      Cell: ({ value }) => (
        <StatusLabel state={value ? 0 : 3}>
          {value ? "Aktiv" : "Inaktiv"}
        </StatusLabel>
      ),
      Filter: IsActive,
      filter: "textExact",
    });

    cols.splice(7, 0, {
      Header: 'Status inbjudan "Mina sidor"',
      accessor: "invite_pending",
      Cell: ({ value, row }) => {
        let user = row.original.user;

        let accepted = user.is_accepted || user.is_accepted;
        let invited = user.invite_pending;

        let val = accepted
          ? "Accepterad"
          : invited
          ? "Inbjuden"
          : "Ej inbjuden";

        return (
          <StatusLabel
            state={val === "Accepterad" ? 0 : val === "Inbjuden" ? 4 : 10}
          >
            {val}
          </StatusLabel>
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    });
  }

  return cols;
};

// cols.splice(7, 0, {
//   Header: 'Status inbjudan "Mina sidor"',
//   accessor: (row) => {
//     if (row.user.is_accepted || row.user.is_active) {
//       return "Accepterad";
//     } else if (row.user.invite_pending) {
//       return "Inbjuden";
//     }

//     return "Ej inbjuden";
//   },
//   Cell: ({ value, row }) => {
//     return (
//       <StatusLabel
//         state={value === "Accepterad" ? 0 : value === "Inbjuden" ? 4 : 10}
//       >
//         {value}
//       </StatusLabel>
//     );
//   },
//   Filter: SimpleTextFilter,
//   filter: "text",
// });
