import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";

import {
  useApartmentAd,
  constants,
  overviewUrl,
  destroyPatchForm,
  update,
} from "../../store/marketApartmentAds";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import { useDispatch } from "react-redux";
import ApartmentAdDetailOverview from "./ApartmentAdDetail/Overview";
import ApartmentAdForm from "../../components/Forms/MarketApartmentAd/ChapterForm/ModalForm";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

export default function ApartmentAdDetail() {
  const dispatch = useDispatch();
  const { adId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const [ad] = useApartmentAd(adId);
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [confirmCancelOpen, setConfirmCancelOpen] = React.useState(false);
  const [confirmActivateOpen, setConfirmActivateOpen] = React.useState(false);

  const canDelete = true;
  const canEdit = true;

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm());
    };
  }, []);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ApartmentAdDetailOverview,
    },
  ];

  const cancelAd = () => {
    dispatch(
      update({
        id: adId,
        forceData: {
          closed: true,
        },
      })
    );
  };
  const activateAd = () => {
    dispatch(
      update({
        id: adId,
        forceData: {
          closed: false,
        },
      })
    );
  };

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canEdit) {
    handleActions.push({
      name: "Redigera",
      onClick: () => setEditOpen(true),
    });

    if (!ad?.closed) {
      handleActions.push({
        name: "Stäng annons",
        onClick: () => setConfirmCancelOpen(true),
      });
    }
    if (!ad?.confirmed && ad?.closed) {
      handleActions.push({
        name: "Återaktivera annons",
        onClick: () => setConfirmActivateOpen(true),
      });
    }
  }
  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  const renderInfoPills = () => {
    return <></>;
  };

  return (
    <>
      <ConfirmationModal
        isOpen={confirmCancelOpen}
        title="Stäng annons"
        renderContent={() => (
          <div>
            Detta innebär att annonsen plockas bort från eventuella
            publiceringsplattformar och ej längre är aktiv. När denna annons har
            stängts kan en ny annons aktiveras.
          </div>
        )}
        closeFunction={() => setConfirmCancelOpen(false)}
        acceptCallback={cancelAd}
        acceptTitle="Fortsätt"
      />
      <ConfirmationModal
        isOpen={confirmActivateOpen}
        title="Återaktivera annons"
        renderContent={() => (
          <div>
            Detta innebär att annonsen aktiveras och återpubliceras på
            eventuella aktiva publiceringsplattformar. Endast en annons i taget
            kan vara aktiv för varje objekt.
          </div>
        )}
        closeFunction={() => setConfirmActivateOpen(false)}
        acceptCallback={activateAd}
        acceptTitle="Aktivera annons"
      />

      <ApartmentAdForm
        method="PATCH"
        instance={ad}
        id={adId}
        onCheckout={() => setEditOpen(false)}
        isOpen={editOpen}
      />

      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={ad}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Annons för ${ad?.str_representation || "laddar..."}`}
          breadCrumbs={[
            { url: "/market/ads", label: "Annonser" },
            { label: ad?.str_representation || "Laddar..." },
          ]}
          eventContentType="market.apartment_ad"
          eventObjectId={adId}
          eventIdQueryStr="apartmentad"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
