export const constructStrRep = (obj) => {
  return obj.apartment_id || "Bostadsrätt";
};
export const detailUrl = ({ id }) => {
  return `/brf-premises/detail/${id}`;
};
export const overviewUrl = () => {
  return `/brf-premises`;
};

export const createCostsUrl = ({ id }) => {
  return `/brf-premises/create/costs/${id}`;
};

export const handleManyInvoicingUrl = () => {
  return "/brf-premises/handle-invoicing-statuses";
};
