export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PRICEVALUE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PRICEVALUE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PRICEVALUE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PRICEVALUE",
  DESTROY_FORM: "DESTROY_FORM_PRICEVALUE",
  SET_FORM_ERROR: "SET_FORM_ERROR_PRICEVALUE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PRICEVALUE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PRICEVALUE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PRICEVALUE",
  RESET_STATE: "RESET_STATE_PRICEVALUE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PRICEVALUE",
  REMOVE_OBJECT: "REMOVE_OBJECT_PRICEVALUE",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_PRICEVALUE",
  CLEAR_FETCHED: "CLEAR_FETCHED_PRICEVALUE",

  LIST_URL: "/imd/pricevalue/list/",
  POST_URL: "/imd/pricevalue/",
  PATCH_URL: "/imd/pricevalue/",
  GET_URL: "/imd/pricevalue/",

  STORE_NAME: "IMDPriceValue",
};
