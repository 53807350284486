import { constants } from "..";

const INITIAL_STATE = {
  authCheckInProgress: false,
  rejectionReasons: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: payload.authenticated,
        authCheckInProgress: false,
      };

    case constants.SET_AUTH_CHECK_IN_PROGRESS:
      return { ...state, authCheckInProgress: payload.inProgress };

    case constants.SET_REJECTION_REASONS:
      return { ...state, rejectionReasons: payload.rejectionReasons };

    case constants.SET_PROJECTS: {
      return { ...state, projects: payload.projects };
    }
    default:
      return state;
  }
};
