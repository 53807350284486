import * as React from "react";

export default () => {
  return (
    <div>
      Specifiera inställningar för eventuell schemaläggning av
      tjänsten/leveransen som avtalet gäller.
      <br />
    </div>
  );
};
