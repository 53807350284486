import * as React from "react";
import { constants } from "../../../../../store/inspectionErrands";

// style, design

import { TextField, TimePicker } from "../../../Base/Fields";
import ManyMediaField from "../../../Base/Fields/ManyMediaField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <TimePicker
        overrideInstructions={{ _readOnly: false }}
        storeName={storeName}
        fieldKey={"execute_end"}
        method={method}
        title="Tid för avklarande"
      />
      <TextField
        {...{
          storeName,
          method,
          fieldKey: "completion_comment",
          title: "Kommentar kring besiktningen",
          overrideInstructions: { _readOnly: false },
        }}
      />
      <ManyMediaField
        overrideInstructions={{ _readOnly: false }}
        storeName={storeName}
        fieldKey={"completion_files"}
        method={method}
        title="Övriga bilder från besiktningen"
        fileKey={"file"}
        allowedFormats={[".png", ".jpg", ".jpeg"]}
      />
    </>
  );
};
