import * as React from "react";
import { constants } from "../../../../store/whitelistPerms";
import Table from "../../../Lists/Base/CompleteList/Table";
import StandardModal from "../../../Modals/StandardModal";

import {
  constants as buildingConstants,
  performFilter as buildingPerformFilter,
  useBuildingPagination,
  useFilteredBuildings,
} from "../../../../store/buildings";
import buildingColumnDefs from "../../../Tables/Buildings/listDefs";

import {
  useFilteredRealEstates,
  useRealEstatePagination,
  constants as realestateConstants,
  performFilter as realestatePerformFilter,
} from "../../../../store/realEstates";
import { realEstateColumnDefs } from "../../../Tables/RealEstate/listDefs";

import {
  useApartmentPagination,
  constants as apartmentConstants,
  performFilter as apartmentPerformFilter,
} from "../../../../store/apartments";
import { apartmentColumnDefs } from "../../../Tables/Apartments/listDefs";

import {
  useIndustrialPremisesPagination,
  constants as industrialpremisesConstants,
  performFilter as industrialpremisesPerformFilter,
} from "../../../../store/industrialPremises";
import { industrialPremisesColumnDefs } from "../../../Tables/IndustrialPremises/listDefs";

import {
  useCompanyPagination,
  constants as companyConstants,
  performFilter as companyPerformFilter,
} from "../../../../store/companies";
import { companyColumnDefs } from "../../../Tables/Companies/listDefs";

import {
  useLeaseContractPagination,
  constants as leasecontractConstants,
  performFilter as leasecontractPerformFilter,
} from "../../../../store/leaseContracts";
import leasecontractColumnDefs from "src/components/Tables/LeaseContracts/listDefs";

import {
  useOtherContractPagination,
  constants as othercontractConstants,
  performFilter as othercontractPerformFilter,
} from "../../../../store/otherContracts";
import othercontractColumnDefs from "src/components/Tables/OtherContracts/listDefs";

import {
  useParkingContractPagination,
  constants as parkingcontractConstants,
  performFilter as parkingcontractPerformFilter,
} from "../../../../store/parkingContracts";
import parkingcontractColumnDefs from "../../../Tables/Parking/ParkingContracts/listDefs";

import {
  useServiceContractPagination,
  constants as servicecontractConstants,
  performFilter as servicecontractPerformFilter,
} from "../../../../store/serviceContracts";
import servicecontractColumnDefs from "src/components/Tables/ServiceContracts/listDefs";

import {
  useServicePartnerPagination,
  constants as servicepartnerConstants,
  performFilter as servicepartnerPerformFilter,
} from "../../../../store/servicePartners";
import { servicepartnerColumnDefs } from "../../../Tables/ServicePartners/listDefs";

import {
  useParkingLotPagination,
  constants as parkinglotConstants,
  performFilter as parkinglotPerformFilter,
} from "../../../../store/parkingLots";
import parkinglotColumnDefs from "../../../Tables/Parking/ParkingLots/listDefs";

import {
  useParkingSpotPagination,
  constants as parkingspotConstants,
  performFilter as parkingspotPerformFilter,
} from "../../../../store/parkingSpots";
import parkingspotColumnDefs from "../../../Tables/Parking/ParkingSpots/listDefs";

import {
  useRoundingErrandSettingsPagination,
  constants as roundingerrandsettingConstants,
  performFilter as roundingerrandsettingPerformFilter,
} from "../../../../store/roundingErrandSettings";
import roundingerrandsettingColumnDefs from "../../../Tables/RoundingErrandSettings/listDefs";

import {
  useInspectionErrandSettingsPagination,
  constants as inspectionerrandsettingConstants,
  performFilter as inspectionerrandsettingPerformFilter,
} from "../../../../store/inspectionErrandSettings";
import { inspectionErrandSettingColumnDefs } from "../../../Tables/InspectionErrandSettings/listDefs";

import {
  useReportErrandSettingsPagination,
  constants as reporterrandsettingConstants,
  performFilter as reporterrandsettingPerformFilter,
} from "../../../../store/reportErrandSettings";
import { reportErrandSettingColumnDefs } from "../../../Tables/ReportErrandSettings/listDefs";

import {
  useRoundingErrandsPagination,
  constants as roundingerrandConstants,
  performFilter as roundingerrandPerformFilter,
} from "../../../../store/roundingErrands";
import { roundingErrandColumnDefs } from "../../../Tables/RoundingErrands/listDefs";

import {
  useInspectionErrandsPagination,
  constants as inspectionerrandConstants,
  performFilter as inspectionerrandPerformFilter,
} from "../../../../store/inspectionErrands";
import { inspectionErrandColumnDefs } from "../../../Tables/InspectionErrands/listDefs";

import {
  useReportErrandsPagination,
  constants as reporterrandConstants,
  performFilter as reporterrandPerformFilter,
} from "../../../../store/reportErrands";
import { reportErrandColumnDefs } from "../../../Tables/ReportErrands/listDefs";

import {
  useEditabledocPagination,
  constants as editabledocConstants,
  performFilter as editabledocPerformFilter,
} from "../../../../store/editabledocs";
import { editableDocsColumnDefs } from "../../../Tables/EditableDocs/listDefs";

import {
  useBasicDocPagination,
  constants as basicdocConstants,
  performFilter as basicdocPerformFilter,
} from "../../../../store/basicDocs";
import basicdocColumnDefs from "../../../Tables/BasicDocs/listDefs";

import {
  useCommonAreasPagination,
  constants as commonareaConstants,
  performFilter as commonareaPerformFilter,
} from "../../../../store/commonAreas";
import commonareaColumnDefs from "../../../Lists/NonTableListDefs/CommonAreaListDefs";

import {
  useBlueprintPagination,
  constants as blueprintConstants,
  performFilter as blueprintPerformFilter,
} from "../../../../store/blueprints";
import blueprintColumnDefs from "../../../Lists/NonTableListDefs/BlueprintListDefs";

import {
  useBrfContractPagination,
  constants as brfContractConstants,
  performFilter as brfContractPerformFilter,
} from "../../../../store/brfContract";
import brfContractColumnDefs from "../../../Tables/BrfContracts/listDefs";

import {
  useBrfPremisesPagination,
  constants as brfPremisConstants,
  performFilter as brfPremisPerformFilter,
} from "../../../../store/brfPremises";
import brfPremisColumnDefs from "../../../Tables/BrfPremises/listDefs";

import {
  useTenantGroupPagination,
  constants as tenantGroupConstants,
  performFilter as tenantGroupPerformFilter,
} from "../../../../store/tenantGroup";
import tenantGroupColumnDefs from "../../../Tables/TenantGroups/listDefs";

import { useDispatch, useSelector } from "react-redux";
import {
  addWhitelistObject,
  removeWhiteListObject,
} from "../../../../store/whitelistPerms/store/actions";

const CONTENT_TYPE_MAP = {
  realestate: {
    columnDefs: realEstateColumnDefs,
    storeName: realestateConstants.STORE_NAME,
    performFilter: realestatePerformFilter,
    paginationMethod: useRealEstatePagination,
  },
  building: {
    columnDefs: buildingColumnDefs,
    storeName: buildingConstants.STORE_NAME,
    performFilter: buildingPerformFilter,
    paginationMethod: useBuildingPagination,
  },
  apartment: {
    columnDefs: apartmentColumnDefs,
    storeName: apartmentConstants.STORE_NAME,
    performFilter: apartmentPerformFilter,
    paginationMethod: useApartmentPagination,
  },
  industrialpremises: {
    columnDefs: industrialPremisesColumnDefs,
    storeName: industrialpremisesConstants.STORE_NAME,
    performFilter: industrialpremisesPerformFilter,
    paginationMethod: useIndustrialPremisesPagination,
  },
  company: {
    columnDefs: companyColumnDefs,
    storeName: companyConstants.STORE_NAME,
    performFilter: companyPerformFilter,
    paginationMethod: useCompanyPagination,
  },
  leasecontract: {
    columnDefs: leasecontractColumnDefs,
    storeName: leasecontractConstants.STORE_NAME,
    performFilter: leasecontractPerformFilter,
    paginationMethod: useLeaseContractPagination,
  },
  othercontract: {
    columnDefs: othercontractColumnDefs,
    storeName: othercontractConstants.STORE_NAME,
    performFilter: othercontractPerformFilter,
    paginationMethod: useOtherContractPagination,
  },
  parkingcontract: {
    columnDefs: parkingcontractColumnDefs,
    storeName: parkingcontractConstants.STORE_NAME,
    performFilter: parkingcontractPerformFilter,
    paginationMethod: useParkingContractPagination,
  },
  servicecontract: {
    columnDefs: servicecontractColumnDefs,
    storeName: servicecontractConstants.STORE_NAME,
    performFilter: servicecontractPerformFilter,
    paginationMethod: useServiceContractPagination,
  },
  servicepartner: {
    columnDefs: servicepartnerColumnDefs,
    storeName: servicepartnerConstants.STORE_NAME,
    performFilter: servicepartnerPerformFilter,
    paginationMethod: useServicePartnerPagination,
  },
  parkinglot: {
    columnDefs: parkinglotColumnDefs,
    storeName: parkinglotConstants.STORE_NAME,
    performFilter: parkinglotPerformFilter,
    paginationMethod: useParkingLotPagination,
  },
  parkingspot: {
    columnDefs: parkingspotColumnDefs,
    storeName: parkingspotConstants.STORE_NAME,
    performFilter: parkingspotPerformFilter,
    paginationMethod: useParkingSpotPagination,
  },
  roundingerrandsetting: {
    columnDefs: roundingerrandsettingColumnDefs,
    storeName: roundingerrandsettingConstants.STORE_NAME,
    performFilter: roundingerrandsettingPerformFilter,
    paginationMethod: useRoundingErrandSettingsPagination,
  },

  inspectionerrandsetting: {
    columnDefs: inspectionErrandSettingColumnDefs,
    storeName: inspectionerrandsettingConstants.STORE_NAME,
    performFilter: inspectionerrandsettingPerformFilter,
    paginationMethod: useInspectionErrandSettingsPagination,
  },
  reporterrandsetting: {
    columnDefs: reportErrandSettingColumnDefs,
    storeName: reporterrandsettingConstants.STORE_NAME,
    performFilter: reporterrandsettingPerformFilter,
    paginationMethod: useReportErrandSettingsPagination,
  },
  roundingerrand: {
    columnDefs: roundingErrandColumnDefs,
    storeName: roundingerrandConstants.STORE_NAME,
    performFilter: roundingerrandPerformFilter,
    paginationMethod: useRoundingErrandsPagination,
  },
  inspectionerrand: {
    columnDefs: inspectionErrandColumnDefs,
    storeName: inspectionerrandConstants.STORE_NAME,
    performFilter: inspectionerrandPerformFilter,
    paginationMethod: useInspectionErrandsPagination,
  },
  reporterrand: {
    columnDefs: reportErrandColumnDefs,
    storeName: reporterrandConstants.STORE_NAME,
    performFilter: reporterrandPerformFilter,
    paginationMethod: useReportErrandsPagination,
  },

  editabledoc: {
    columnDefs: editableDocsColumnDefs,
    storeName: editabledocConstants.STORE_NAME,
    performFilter: editabledocPerformFilter,
    paginationMethod: useEditabledocPagination,
  },
  basicdoc: {
    columnDefs: basicdocColumnDefs,
    storeName: basicdocConstants.STORE_NAME,
    performFilter: basicdocPerformFilter,
    paginationMethod: useBasicDocPagination,
  },
  commonarea: {
    columnDefs: commonareaColumnDefs,
    storeName: commonareaConstants.STORE_NAME,
    performFilter: commonareaPerformFilter,
    paginationMethod: useCommonAreasPagination,
  },
  blueprint: {
    columnDefs: blueprintColumnDefs,
    storeName: blueprintConstants.STORE_NAME,
    performFilter: blueprintPerformFilter,
    paginationMethod: useBlueprintPagination,
  },
  brfcontract: {
    columnDefs: brfContractColumnDefs,
    storeName: brfContractConstants.STORE_NAME,
    performFilter: brfContractPerformFilter,
    paginationMethod: useBrfContractPagination,
  },
  brfpremis: {
    columnDefs: brfPremisColumnDefs,
    storeName: brfPremisConstants.STORE_NAME,
    performFilter: brfPremisPerformFilter,
    paginationMethod: useBrfPremisesPagination,
  },
};

export default ({ contentType, isOpen, closeFunction }) => {
  const dispatch = useDispatch();
  const currentSetting = CONTENT_TYPE_MAP[contentType];
  const fetchAllTreshold = 25;
  const filterInstructions = {
    name: { operator: "icontains" },
  };

  const [buildings] = useFilteredBuildings();
  const [realEstates] = useFilteredRealEstates();

  const current = useSelector((state) => state[constants.STORE_NAME].current);

  const onSelected = (row) => {
    const { original } = row;
    if (current[contentType]?.find((r) => r.object_id === original.id)) {
      dispatch(removeWhiteListObject({ object: original, contentType }));
    } else {
      dispatch(addWhitelistObject({ object: original, contentType }));
    }
  };

  const checkRowHighlighted = (row) => {
    const { original } = row;

    let match = false;

    match = current[contentType]?.find((s) => s.object_id === original.id);

    return match;
  };

  const columns = React.useMemo(
    () => currentSetting.columnDefs(realEstates, buildings),
    [contentType, buildings, realEstates]
  );

  return (
    <StandardModal
      isOpen={isOpen}
      closeFunction={() => closeFunction(false)}
      title={getReadablePermissionType(contentType)}
      withActionBar
      actionBarCancelTitle="Klar"
      modalInModal
    >
      <Table
        storeName={currentSetting.storeName}
        columns={columns}
        paginationMethod={currentSetting.paginationMethod}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={currentSetting.performFilter}
        // forceFrontendColumns={forceFrontendColumns}
        {...{
          hideExport: true,
          hideColumns: true,
          onRowClicked: onSelected,
          checkRowHighlighted,
        }}
      />
    </StandardModal>
  );
};

const getReadablePermissionType = (permissionType) => {
  switch (permissionType) {
    case "tenant":
      return "Hyresgäst";
    case "tenantrevenue":
      return "Omsättningsbaserad hyra";
    case "user":
      return "Användare";
    case "component":
      return "Komponenter";
    case "message":
      return "Meddelanden";
    case "notification":
      return "Notiser";
    case "permissiondetail":
      return "Behörigheter - detaljer";
    case "permissionrule":
      return "Behörigheter - regler";
    case "apartment":
      return "Lägenheter";
    case "basicdoc":
      return "Dokument";
    case "blueprint":
      return "Planritningar";
    case "building":
      return "Byggnader";
    case "commonarea":
      return "Gemensamma ytor";
    case "company":
      return "Företag";
    case "editabledoc":
      return "Mallar & Avtalsdokument";
    case "industrialpremises":
      return "Lokaler";
    case "key":
      return "Nycklar";
    case "leasecontract":
      return "Hyresavtal";
    case "othercontract":
      return "Övriga avtal";
    case "premisesimages":
      return "Bilder på lokaler & lägenheter";
    case "realestate":
      return "Fastigheter";
    case "room":
      return "Rum";
    case "servicecontract":
      return "Serviceavtal";
    case "servicepartner":
      return "Servicepartner";
    case "inspectionerrand":
      return "Besiktningar";
    case "inspectionerrandsetting":
      return "Besiktningstyper";
    case "reporterrand":
      return "Felanmälningar";
    case "reporterrandsetting":
      return "Felanmälningstyper";
    case "roundingerrand":
      return "Ronderingar & myndighetskrav";
    case "roundingerrandsetting":
      return "Rondering & myndighetskravtyper";
    case "brfpremis":
      return "Brf objekt";
    case "brfcontract":
      return "Brf avtal";
    default:
      return permissionType;
  }
};
