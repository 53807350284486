import { useDispatch, useSelector } from "react-redux";
import {
  getSbfCredentials,
  getSbfRequirementConnections,
  getSbfRequirements,
} from "../store/actions";
import constants from "../store/constants";

export const useSbfCredentials = () => {
  const dispatch = useDispatch();

  const credentials = useSelector(
    (state) => state[constants.STORE_NAME].credentials
  );
  const inProgress = useSelector(
    (state) => state[constants.STORE_NAME].credentialsCheckInProgress
  );

  if (inProgress) return [undefined, true];

  if (Array.isArray(credentials)) return [credentials, false];

  // if not true or false, get it
  if (!inProgress && credentials == null) dispatch(getSbfCredentials());

  return [undefined, true];
};

export const useSbfRequirements = () => {
  const dispatch = useDispatch();

  const requirements = useSelector(
    (state) => state[constants.STORE_NAME].sbfRequirements
  );
  const inProgress = useSelector(
    (state) => state[constants.STORE_NAME].requirementsInProgress
  );
  if (inProgress) return [undefined, true];

  if (requirements) return [requirements, false];

  // if not true or false, get it
  if (!inProgress && requirements == null) dispatch(getSbfRequirements());

  return [undefined, true];
};

export const useSbfRequirementConnections = () => {
  const dispatch = useDispatch();

  const connections = useSelector(
    (state) => state[constants.STORE_NAME].requirementConnections
  );
  const inProgress = useSelector(
    (state) => state[constants.STORE_NAME].requirementConnectionsInProgress
  );

  if (inProgress) return [undefined, true];

  if (Array.isArray(connections)) return [connections, false];

  // if not true or false, get it
  if (!inProgress && connections == null)
    dispatch(getSbfRequirementConnections());

  return [undefined, true];
};
