import * as React from "react";
import * as SharedStyles from "../../../components/sharedStyles";

import KycForm from "../../../components/Forms/Kyc/PlainForm";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../store/base";
import { constants, useKYC } from "../../../store/billectaKyc";
import { useParams, useHistory } from "react-router";
import { useFilteredCompanies } from "../../../store/companies";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";

export default function BillectaKyc() {
  const dispatch = useDispatch();
  const { creditorId } = useParams();

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const { goBack } = useHistory();
  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];
  const kycReportId = company?.kyc_report?.id;

  const [existingKyc] = useKYC(kycReportId);

  React.useEffect(() => {
    if (kycReportId && existingKyc) {
      dispatch(
        setActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: existingKyc,
        })
      );
    } else if (company) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {
            company: { id: company?.id },
            us_persons: [],
            majority_holders: [],
            benifitial_owners: [],
          },
        })
      );
    }
  }, [company, existingKyc]);

  return (
    <SharedStyles.LayoutWrapper style={{ padding: "20px" }}>
      <TextButton
        extraStyle={{ marginBottom: 12 }}
        title="Tillbaka"
        clicked={goBack}
        iconType="arrow-back"
      />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>
            Blankett för kundkännedom för {company?.str_representation}
          </OverviewTitle>
          <OverviewSubtitle>
            För att få erbjuda finansiella tjänster kräver Finansinspektionen
            att en blankett för kundkännedom fylls i och skickas in. Fyll i
            uppgifterna nedan för att komma igång med hyresavisering
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <KycForm existingKyc={existingKyc} company={company} />
    </SharedStyles.LayoutWrapper>
  );
}
