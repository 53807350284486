import * as React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { buildQueryString } from "../../../store/base";
import { deleteImportantMessage } from "../../../store/billectaEvents";
import { useImportantMessages } from "../../../store/billectaEvents/hooks/retrieve";
import { useFilteredCompanies } from "../../../store/companies";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { ItemTitle } from "../../Details/OverviewInfo/DetailInfo/styles";
import MenuButton from "../../Forms/Base/Buttons/MenuButton";
import { BodyText, InnerBox } from "../../sharedStyles";

// categories that billecta allow deletion for
const DELETEABLE_CATEGORIES = [
  "ContractInvoiceInformations",
  "AutogiroInformations",
  "BookkeepingIssues",
];

export default function BillectaImportantMessages() {
  const { creditorId } = useParams();
  const dispatch = useDispatch();
  const [messages] = useImportantMessages(creditorId);

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });
  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  return (
    <>
      {messages?.map((m) => {
        return (
          <InnerBox style={{ cursor: "pointer" }}>
            <BodyText style={{ display: "flex" }}>
              {company?.str_representation} -{" "}
              {moment(m.Created).format("YYYY-MM-DD HH:mm")}
              {DELETEABLE_CATEGORIES.includes(m.NotificationType) && (
                <MenuButton
                  leftAligned
                  extraStyle={{ marginLeft: "auto" }}
                  actions={[
                    {
                      name: "Ta bort",
                      onClick: () =>
                        dispatch(
                          deleteImportantMessage({
                            creditorId,
                            messageId: m.NotificationPublicId,
                            successCallback: () => {
                              dispatch(
                                addToast({
                                  type: TOAST_TYPES.INFO,
                                  title: "Meddelandet raderas",
                                })
                              );
                            },
                            errorCallback: (e) => {
                              dispatch(
                                addToast({
                                  type: TOAST_TYPES.ERROR,
                                  title: "Meddelandet kunde ej raderas",
                                  description: e || "Försök igen",
                                })
                              );
                            },
                          })
                        ),
                    },
                  ]}
                />
              )}
            </BodyText>
            <ItemTitle>{m.Message}</ItemTitle>
          </InnerBox>
        );
      })}
    </>
  );
}
