export const constructStrRep = (obj) => {
  return obj.apartment_id || "Hyreslägenhet";
};
export const detailUrl = ({ id }) => {
  return `/apartments/detail/${id}`;
};
export const overviewUrl = () => {
  return `/apartments`;
};
export const createUrl = () => {
  return `/apartments/create`;
};
export const editUrl = ({ id }) => {
  return `/apartments/edit/${id}`;
};
