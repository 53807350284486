import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  addToProcess,
} from "../../base";
import { retrieve } from "../../base/store/services";
import { getSingleFetchProcessName } from "../../base/utils";
import { getPublicErrandArticleProcessName } from "../utils";
import constants from "./constants";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (querystring, callback, taskToken, initiatedBySocket) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken, initiatedBySocket });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    preProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    preProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback
  });
};

export const fetchPublicArticles = (querystring) => {
  const url = `${constants.PUBLIC_LIST_URL}?${querystring}`;
  const name = getPublicErrandArticleProcessName(querystring);

  return async (dispatch) => {
    addToProcess(dispatch, constants, name);

    const result = await retrieve({ url });
    let data = result.data;

    dispatch({
      type: constants.SET_PUBLIC_ARTICLES,
      payload: { result: data, querystring },
    });
  };
};