import * as React from "react";

// style, design

export default () => [
  {
    Header: "Namn",
    accessor: "str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Roll",
    accessor: "participation.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Fastighet",
    accessor: "participation.realestate.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
];
