import * as React from "react";
import * as SC from "./styles";

export default function InvoiceTableFilters({
  selectedFilter,
  updateFilter,
  filterCounts,
}) {
  return (
    <SC.FilterWrapper>
      <SC.UnmatchedPaymentFilterButton
        selected={selectedFilter === "Unhandled"}
        onClick={() => updateFilter("Unhandled")}
      >
        Ej matchade ({filterCounts?.Unhandled || 0})
      </SC.UnmatchedPaymentFilterButton>

      <SC.OverpaidFilterButton
        selected={selectedFilter === "Overpaid"}
        onClick={() => updateFilter("Overpaid")}
      >
        Överbetalda ({filterCounts?.Overpaid || 0})
      </SC.OverpaidFilterButton>

      <SC.MatchedPaymentFilterButton
        selected={selectedFilter === "Matched"}
        onClick={() => updateFilter("Matched")}
      >
        Matchade ({filterCounts?.Matched || 0})
      </SC.MatchedPaymentFilterButton>
    </SC.FilterWrapper>
  );
}
