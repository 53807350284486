import Confirm from "./Confirm";

import PickFile from "./PickFile";
import ImportSettings from "./ImportSettings";
import ColumnSettings from "./ColumnSettings";
import MeasureType from "./MeasureType";

export default {
  MEASURE_TYPE: MeasureType,
  PICK_FILE: PickFile,
  IMPORT_SETTINGS: ImportSettings,
  COLUMN_SETTINGS: ColumnSettings,
  CONFIRM: Confirm,
};
