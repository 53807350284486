import { checkTrailingUrlSlash } from "../../base";
import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function markNotificationsAsRead(ids) {
  const url = checkTrailingUrlSlash(`${constants.MARK_READ_URL}`);

  const res = await axiosInstance.patch(url, { ids });

  return res;
}

async function getImportantMessages() {
  const res = await axiosInstance.patch(constants.IMPORTANT_MESSAGES_URL);

  return res;
}

async function deleteImportantMessage({ creditorId, messageId }) {
  const res = await axiosInstance.delete(
    `${constants.IMPORTANT_MESSAGES_URL}?id=${creditorId}&notificationId=${messageId}`
  );

  return res;
}

export {
  markNotificationsAsRead,
  getImportantMessages,
  deleteImportantMessage,
};
