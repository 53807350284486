import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { Checkbox, NumberInput, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingDebtSettings";
import { useFormInstanceField } from "../../../../../store/base";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const alwaysLastDay = useFormInstanceField({
    storeName,
    fieldKey: "contract_pay_last_day_month",
  });

  return (
    <>
      <FormAreaTitle>Inställning för Betalningsvillkor</FormAreaTitle>

      <TextInput
        title="Titel på inställning"
        {...{ storeName, method, fieldKey: "title" }}
      />

      <Checkbox
        small
        extraStyles={{ marginBottom: 12 }}
        title="Fakturor förfaller alltid den sista dagen i månaden för utskick"
        description="Om denna inställning är aktiv så kommer förfallodatumet alltid vara den sista i månaden för fakturan."
        {...{
          storeName,
          method,
          fieldKey: "contract_pay_last_day_month",
        }}
        forceHorizontalToolTipPlacement="left"
      />

      <Checkbox
        small
        extraStyles={{ marginBottom: 12 }}
        title="Flytta förfallodatum till närmaste vardag vid helgdag på förfallodatum"
        description="Om denna inställning är aktiv så kommer förfallodatumet aldrig att infalla på helgen."
        {...{
          storeName,
          method,
          fieldKey: "attempt_no_weekend_pay_day",
        }}
        forceHorizontalToolTipPlacement="left"
      />

      {!alwaysLastDay && (
        <NumberInput
          title="Betalningsvillkor i dagar"
          description="Ange antal dagar från fakturadatum till förfallodatum. Skickas fakturan ut den 1:a och denna inställning sätts till 10 så kommer förfallodatumet vara den 11:e"
          {...{
            storeName,
            method,
            fieldKey: "contract_payment_term_days",
          }}
        />
      )}
    </>
  );
};
