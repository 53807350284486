import * as React from "react";
import { Redirect } from "react-router-dom";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { DetailPageBox } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import { detailUrl, useApartment } from "../../store/apartments";
import { buildQueryString } from "../../store/base";
import { useFilteredApartmentAds } from "../../store/marketApartmentAds";

export default function HomeQRedirectPage() {
  const query = useQuery();

  const homeQAdId = query.get("object_ad_id");

  const adQ = buildQueryString({
    homeq_id: homeQAdId,
  });

  const [matches] = useFilteredApartmentAds(adQ);

  const ad = matches?.[0];

  const [apartment, apartmentLoading] = useApartment(ad?.apartment?.id);

  if (!apartment)
    return (
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Skickar vidare till rätt sida...</OverviewTitle>
        </OverviewTitleWrapper>
      </DetailPageBox>
    );

  return <Redirect to={`${detailUrl({ id: apartment?.id })}/market`} />;
}
