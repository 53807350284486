import * as React from "react";

// style, design
import { LinkedObject, ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { detailUrl as pipeDetailUrl } from "../../../store/pipes";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default () => {
  return [
    {
      Header: "Projekt",
      accessor: (row) => row.pipe?.str_representation,
      Cell: ({ row }) => {
        return (
          <LinkedObject obj={row.original?.pipe} urlMethod={pipeDetailUrl} />
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Steg",
      accessor: "stage",
      Cell: ({ value }) => {
        return <StatusLabel state={9}>{value.str_representation}</StatusLabel>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Bolagsnamn",
      accessor: "corporate_name",
      Cell: ({ value }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Förnamn",
      accessor: "first_name",
      Cell: ({ value }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Efternamn",
      accessor: "last_name",
      Cell: ({ value, row }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "E-post",
      accessor: "email",
      Cell: ({ value, row }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Telefon",
      accessor: "phone",
      Cell: ({ value, row }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Nuvarande hyresgäst",
      accessor: "tenant",
      Cell: ({ value, row }) => {
        return value ? (
          <LinkedObject obj={value} urlMethod={tenantDetailUrl} />
        ) : (
          <ToolTipCell text={"Nej"} />
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];
};
