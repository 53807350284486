import * as React from "react";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  clearFetched,
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
  usePermissionCheck,
} from "../../../../../store/base";
import { useFilteredErrandCosts } from "../../../../../store/errandCosts";
import { detailUrl as articleDetailUrl } from "../../../../../store/errandArticles";
import { detailUrl as componentDetailUrl } from "../../../../../store/errandComponents";
import {
  useInspectionErrandForm,
  constants as inspectionErrandConstants,
  update as updateInspectionErrand,
} from "../../../../../store/inspectionErrands";
import {
  useReportErrandForm,
  constants as reportErrandConstants,
  update as updateReportErrand,
} from "../../../../../store/reportErrands";
import { DateCell, InfoBox, LinkedObject } from "../../../../Displays";
import { PrimaryButton } from "../../../../Forms/Base/Buttons";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../styles";
import { toMoneyString } from "../../../../utils/stringUtils";
import Table from "../../../../Billecta/Table/BasicTable";
import { InnerBox } from "../../../../sharedStyles";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import { cloneDeep } from "lodash";
import HandleErrandCost from "./HandleErrandCost";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import { getCurrentProductTypeUsage } from "../../../../TechnicalManagement/utils";

export const ERRAND_LIABLE_MAP = {
  0: "Hyresgäst",
  1: "Hyresvärd",
  2: "Annan",
};

export const ERRAND_COST_TYPES = {
  REPORT_ERRAND: 1,
  INSPECTION_ERRAND: 2,
};

const getFormHook = (kind) => {
  if (kind === ERRAND_COST_TYPES.REPORT_ERRAND) return useReportErrandForm;
  if (kind === ERRAND_COST_TYPES.INSPECTION_ERRAND)
    return useInspectionErrandForm;
};

const getConstants = (kind) => {
  if (kind === ERRAND_COST_TYPES.REPORT_ERRAND) return reportErrandConstants;
  if (kind === ERRAND_COST_TYPES.INSPECTION_ERRAND)
    return inspectionErrandConstants;
};

const getUpdateMethod = (kind) => {
  if (kind === ERRAND_COST_TYPES.REPORT_ERRAND) return updateReportErrand;
  if (kind === ERRAND_COST_TYPES.INSPECTION_ERRAND)
    return updateInspectionErrand;
};

export default function ErrandCostSummary({ kind, id, errand, costs }) {
  const dispatch = useDispatch();
  const useForm = getFormHook(kind, id);
  const constants = getConstants(kind);
  const updateMethod = getUpdateMethod(kind);
  const [hasSetCosts, setHasSetCosts] = React.useState(false);
  const [handleCostIndex, setHandleCostIndex] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [initialCosts, setInitialCosts] = React.useState(null);

  const storeName = constants.STORE_NAME;
  const canEditErrand = usePermissionCheck(
    `change_can_${
      kind === ERRAND_COST_TYPES.REPORT_ERRAND
        ? "reporterrand"
        : "inspectionerrand"
    }`
  );

  useForm("PATCH");

  const formCosts = useFormInstanceField({
    storeName,
    fieldKey: "costs",
  });

  const costErrors = useFormError({
    storeName,
    fieldKey: "costs",
  });

  const costIds = costs?.map((c) => c.id);

  const q = buildQueryString({
    id__in: costIds || [],
  });

  const [currentCosts, currentCostsLoading] = useFilteredErrandCosts(q);

  React.useEffect(() => {
    if (hasSetCosts || currentCosts?.length !== costs?.length || !errand)
      return;

    setInitialCosts(currentCosts);
    setHasSetCosts(true);
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          costs: currentCosts,
        },
      })
    );
  }, [currentCosts]);

  const tableCosts = React.useMemo(() => {
    if (!formCosts) return [];

    return formCosts;
  }, [formCosts, hasSetCosts]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "Ansvarig",
        accessor: "liable_display",
        Cell: ({ value, row }) => {
          return <div>{value || ERRAND_LIABLE_MAP[row.original?.liable]}</div>;
        },
        TotalFooter: () => {
          return <div style={{ fontWeight: 600 }}>Total:</div>;
        },
      },
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Artikel",
        accessor: "article",
        Cell: ({ value }) => {
          return <LinkedObject obj={value} urlMethod={articleDetailUrl} />;
        },
      },
      {
        Header: "Gäller komponent",
        accessor: "component_placement",
        Cell: ({ value }) => {
          const productTypeUsage = getCurrentProductTypeUsage(value);
          return (
            <LinkedObject
              obj={{
                id: value?.id,
                str_representation:
                  productTypeUsage?.product_type?.str_representation,
              }}
              urlMethod={componentDetailUrl}
            />
          );
        },
      },
      {
        Header: "Antal",
        accessor: "unit_amount",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Pris/enhet",
        accessor: "unit_cost",
        Cell: ({ value }) => {
          return <div>{toMoneyString(value, true)}</div>;
        },
      },
      {
        Header: "Summa",
        id: "sum",
        Cell: ({ row }) => {
          return (
            <div>
              {toMoneyString(
                (row.original.unit_amount || 0) * (row.original.unit_cost || 0),
                true
              )}
            </div>
          );
        },
        TotalFooter: ({ row }) => {
          const totalSum = formCosts?.reduce((acc, cur) => {
            const curTot = cur.unit_cost * cur.unit_amount;

            return acc + curTot;
          }, 0);

          return (
            <div style={{ fontWeight: 600 }}>
              {toMoneyString(totalSum, true)}
            </div>
          );
        },
      },
      {
        Header: "Momssats (%)",
        accessor: "vat",
        Cell: ({ value }) => {
          return <div>{value}%</div>;
        },
      },
      {
        Header: "Tillagd",
        accessor: "created_at",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
    ];

    return cols;
  }, [formCosts]);

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  const handleEditCost = (row) => {
    setHandleCostIndex(row.index);
  };

  const handleAddCost = () => {
    const costsCopy = [...(formCosts || [])];
    const newIndex = costsCopy.length;

    costsCopy.push({ order: newIndex + 1 });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          costs: costsCopy,
        },
      })
    );

    setHandleCostIndex(newIndex);
  };

  const handleRemoveCost = (index) => {
    const costsCopy = cloneDeep(formCosts);

    costsCopy.splice(index, 1);

    setHandleCostIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          costs: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );
  };

  const canUpdate = checkCanUpdate({
    formCosts,
    initialCosts,
  });

  const handleEditCostDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostIndex(null);
  };

  const renderCostErrors = () => {
    return (
      <InfoBox
        title="En eller flera kostnader innehåller fel"
        text="Kontrollera de rödmarkerade raderna."
        boxTheme="warning"
      />
    );
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (dataClone.costs?.length > 0) {
      dataClone.costs.forEach((c) => {
        if (!c.article) {
          c.article = null;
        }
      });
    }

    return dataClone;
  };

  const onUpdateSuccess = (_, returnedData) => {
    setLoading(false);

    setInitialCosts(formCosts);
    dispatch(
      setActiveFormInstance({
        storeName,
        data: returnedData,
      })
    );
  };
  const onUpdateError = () => {
    setLoading(false);
  };

  const updateCosts = () => {
    setLoading(true);
    dispatch(
      updateMethod({
        preProcess: preProcess,
        id: errand?.id,
        successCallback: onUpdateSuccess,
        errorCallback: onUpdateError,
      })
    );
  };

  return (
    <>
      <HandleErrandCost
        costs={formCosts}
        handleCostIndex={handleCostIndex}
        closeFunction={() => setHandleCostIndex(null)}
        onUpdate={handleEditCostDone}
        method={"PATCH"}
        storeName={storeName}
        errand={errand}
        onRemove={() => handleRemoveCost(handleCostIndex)}
      />

      {(!errand || loading || currentCostsLoading) && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Kostnader för utförande</OverviewTitle>
          <OverviewSubtitle>
            Klicka på en rad för att redigera kostnaden
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
        <PrimaryButton
          title="Lägg till kostnad"
          clicked={() => handleAddCost()}
        />
      </OverviewTitleWrapper>

      {canUpdate && (
        <InfoBox
          title="Uppdateringar ej sparade"
          text={`Tryck på "Spara uppdateringar" nere till höger för att spara ändringarna i kostnaderna.`}
        />
      )}

      {costErrors?.length && renderCostErrors()}

      {tableCosts?.length > 0 ? (
        <>
          <Table
            onRowClicked={handleEditCost}
            columns={tableColumns}
            data={tableCosts}
            checkRowError={checkRowError}
            hideSearch
            withTotalSummaryFooter
          />
        </>
      ) : (
        <InnerBox
          style={{
            marginBottom: 12,
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            Inga kostnader är tillagda än
            <div style={{ marginTop: 24 }}>
              {canEditErrand && (
                <PrimaryButton
                  title="Lägg till kostnad"
                  clicked={() => handleAddCost()}
                />
              )}
            </div>
          </>
        </InnerBox>
      )}

      {canUpdate && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 24,
          }}
        >
          <PrimaryButton
            pulse
            title="Spara uppdateringar"
            clicked={updateCosts}
          />
        </div>
      )}
    </>
  );
}

function checkCanUpdate({ formCosts, initialCosts }) {
  let hasChange = false;

  if (initialCosts?.length !== formCosts?.length) return true;
  if (!initialCosts?.length && !formCosts?.length) return false;

  initialCosts.forEach((cost) => {
    const match = formCosts.find((fCost) => {
      return fCost.id === cost.id;
    });

    if (!match) {
      hasChange = true;

      return;
    }

    if (
      match.title !== cost.title ||
      match.unit_amount !== cost.unit_amount ||
      match.vat !== cost.vat ||
      match.unit_cost !== cost.unit_cost ||
      match.article?.id !== cost.article?.id ||
      match.liable !== cost.liable
    ) {
      hasChange = true;
    }
  });

  return hasChange;
}
