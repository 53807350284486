import {
  CheckIcon,
  ChevronLeftIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import { useParams } from "react-router-dom";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import ReportErrandForm from "src/components/Forms/ReportErrand/ChapterForm/ModalForm";
import RoundingFaultModalForm from "src/components/Forms/RoundingFault/ChapterForm/ModalForm";

import RoundingFaultTable from "src/components/Tables/RoundingFaults/FullTable";

export default function FaultReportForm({
  selectedObject,
  setSelectedComponentPlacement,
  selectedComponentPlacement,
  realestate,
  componentType,
  TYPES,
  selectedType,
  goBackToStart,
}) {
  const [createOpen, setCreateOpen] = React.useState(false);

  const [createReportErrandOpen, setCreateReportErrandOpen] =
    React.useState(false);
  const [createReportErrandFault, setCreateReportErrandFault] =
    React.useState(null);

  const { errandId } = useParams();

  const onErrandFaultsQ = {
    component_placement: selectedComponentPlacement.id,
    roundingerrands: errandId,
  };

  return (
    <>
      {createReportErrandOpen && (
        <ReportErrandForm
          preventGoToDetail
          method="POST"
          isOpen={true}
          onCheckout={() => setCreateReportErrandOpen(false)}
          instance={{
            title: `Ronderingsavvikelse ${createReportErrandFault?.title}`,
            rounding_fault: {
              ...createReportErrandFault,
              str_representation: createReportErrandFault?.title,
            },
            report_comments: [
              {
                content: createReportErrandFault?.description,
              },
            ],
            component_placement: {
              ...createReportErrandFault?.component_placement,
              _dontClear: true,
            },
          }}
        />
      )}

      {createOpen && (
        <RoundingFaultModalForm
          method="POST"
          instance={{
            is_ok: false,
            component_placement: selectedComponentPlacement,
            realestate,
            rounding_errands: [{ id: errandId }],
          }}
          onCheckout={() => setCreateOpen(false)}
        />
      )}

      <div className="mb-6">
        <PrimaryBtn
          secondary
          onClick={() => setSelectedComponentPlacement(null)}
        >
          <ChevronLeftIcon width={16} className="mr-1" /> TIllbaka till lista
          över komponettyper i objekt
        </PrimaryBtn>
      </div>

      <div>
        <div className="font-semibold text-lg">
          Avvikelseregistrering på {componentType}
        </div>
        <div className="text-sm">
          Placerad i{" "}
          {selectedObject?.str_representation || realestate?.str_representation}
          {selectedType === TYPES.REAL_ESTATE
            ? ""
            : ` i fastigheten ${realestate.str_representation}`}
        </div>
      </div>

      <div className="flex justify-between items-center mt-2">
        <div className="text-sm text-gray-500">
          Avvikelser för denna rondering
        </div>
        <PrimaryBtn onClick={() => setCreateOpen(true)}>
          Lägg till <PlusIcon width={16} className="ml-1" />
        </PrimaryBtn>
      </div>

      {!createOpen && !createReportErrandOpen && (
        <RoundingFaultTable
          isBare
          persistantQuery={onErrandFaultsQ}
          onRowClicked={() => {}}
          createReportErrandCallback={(obj) => {
            setCreateReportErrandFault(obj);
            setCreateReportErrandOpen(true);
          }}
        />
      )}

      <div className="flex justify-end mt-2">
        <PrimaryBtn highlight onClick={goBackToStart}>
          Klar, gå tillbaka till översikten{" "}
          <CheckIcon width={16} className="ml-1" />
        </PrimaryBtn>
      </div>
    </>
  );
}
