import * as React from "react";

import { RadioGroup } from "../../../Base/Fields";

import { constants } from "../../../../../store/realEstates";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useFormInstanceField } from "../../../../../store/base";
import { InnerBox } from "../../../../sharedStyles";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";

export default ({ method, forceAvyId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(forceAvyId || instance?.avytmpl_id);

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Texten kopierades",
      })
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Hantera integration</OverviewTitle>
          <OverviewSubtitle>
            När Avy-Tmpl-integrationen är aktiv kommer underliggande
            hyresgäster, avtal och objekt synkas mellan Avy-Tmpl och Pigello.
            Eventuella felmeddelanden syns under denna integrationssida.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              Steg 2 - Aktivera synk mot Avy-Tmpl
            </OverviewTitle>
            <OverviewSubtitle>
              För att synkningen ska fungera så måste ID:t som genererades ha
              installerats ordentligt av Avy-Tmpl.
            </OverviewSubtitle>

            <OverviewSubtitle>
              <code>ID: {forceAvyId || instance?.avytmpl_id}</code>
              <TextButton
                title="Kopiera ID till urklipp"
                extraStyle={{ marginTop: 12 }}
                iconType="copy"
                iconPlacement="right"
                clicked={copyToClipBoard}
              />
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <RadioGroup
          {...{ storeName, method, fieldKey: "should_sync_to_avytmpl" }}
          title="Aktivera integrationen för denna fastighet"
          options={[
            {
              label: "Aktivera integrationen",
              value: true,
            },
            {
              label: "Nej, aktivera ej integrationen för denna fastighet",
              value: false,
            },
          ]}
          defaultValue={false}
        />
      </InnerBox>
    </>
  );
};
