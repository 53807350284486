import * as React from "react";
import { useParams, useHistory } from "react-router-dom";

import { detailUrl as pipeDetailUrl } from "../../../store/pipes";
import { detailUrl as requirementDetailUrl } from "../../../store/marketRequirements";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import AdForm from "../../../components/Forms/MarketParkingSpotAd/ChapterForm/ModalForm";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { buildQueryString } from "../../../store/base";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import SyncErrorTable, {
  SYNC_ERROR_MAP,
} from "../../../components/Tables/ExternalSyncErrors/FullTable";
import { useExternalSyncErrorPaginationCount } from "../../../store/externalSyncErrors";
import { DateCell, LinkedObject } from "../../../components/Displays";
import LeadsTable from "../../../components/Tables/Leads/FullTable";
import { getAdStateAndLabel } from "../../../store/marketApartmentAds/utils";
import {
  useFilteredParkingSpotAds,
  detailUrl as adDetailUrl,
} from "../../../store/marketParkingAds";

export default function ParkingSpotMarket() {
  const { parkingSpotId } = useParams();
  const parkingQ = buildQueryString({
    parkingspot: parkingSpotId,
  });

  const [marketingAds, marketingAdsLoading] =
    useFilteredParkingSpotAds(parkingQ);

  const { push } = useHistory();

  const hasActiveAd = marketingAds?.find((m) => !m.confirmed && !m.closed);

  const [createAdOpen, setCreateAdOpen] = React.useState(false);
  const [editAdId, setEditAdId] = React.useState(null);

  const [syncErrorCount] = useExternalSyncErrorPaginationCount({
    filters: {
      api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
      parkingspot: parkingSpotId,
    },
  });

  const hasSyncError = syncErrorCount > 0;

  const errorQuery = {
    api_kind__in: [SYNC_ERROR_MAP.HOMEQ, SYNC_ERROR_MAP.SBF],
    parkingspot: parkingSpotId,
  };

  const leadQuery = {
    parkingspot: parkingSpotId,
  };

  return (
    <>
      <AdForm
        method="POST"
        isOpen={createAdOpen}
        instance={{ parking_spot: { id: parkingSpotId } }}
        onCheckout={() => setCreateAdOpen(false)}
        hideParkingSpot
      />

      <AdForm
        method="PATCH"
        isOpen={!!editAdId}
        id={editAdId}
        instance={marketingAds?.find((ad) => ad.id == editAdId)}
        onCheckout={() => setEditAdId(null)}
        hideParkingSpot
      />

      <DetailInnerWrapper>
        {hasSyncError && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle>Felmeddelanden</OverviewTitle>
            </OverviewTitleWrapper>
            <SyncErrorTable
              {...{
                persistantQuery: errorQuery,
              }}
            />
          </DetailPageBox>
        )}

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Annonser</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {marketingAds?.length > 0 ? (
            <>
              {marketingAds.map((ad) => {
                const { stateDisplay, state } = getAdStateAndLabel(ad);
                return (
                  <InnerBox style={{ marginBottom: 8 }}>
                    <OverviewTitleWrapper>
                      <OverviewTitleWithSubtitleWrapper>
                        <OverviewTitle small>
                          Publ. {ad.date_publish}
                        </OverviewTitle>
                      </OverviewTitleWithSubtitleWrapper>
                      <StatusLabel state={state}>{stateDisplay}</StatusLabel>
                    </OverviewTitleWrapper>

                    <DetailInfo
                      infoObj={{
                        Detaljer: [
                          {
                            title: "Planerat tillträde",
                            value: <DateCell date={ad.access_date} />,
                          },
                          {
                            title: "Kravprofil",
                            value: (
                              <LinkedObject
                                urlMethod={requirementDetailUrl}
                                obj={ad.requirement}
                              />
                            ),
                          },
                          {
                            title: "Projekt",
                            value: (
                              <LinkedObject
                                urlMethod={pipeDetailUrl}
                                obj={ad.dump_in_pipe}
                              />
                            ),
                          },
                          {
                            title: "Pigello marknad",
                            value: (
                              <StatusLabel state={ad.publish_pigello ? 0 : 3}>
                                {ad.publish_pigello ? "Aktiv" : "Inaktiv"}
                              </StatusLabel>
                            ),
                          },
                        ],
                      }}
                    />

                    <TextButton
                      title="Gå till annons"
                      iconType="edit"
                      iconPlacement="right"
                      clicked={() => push(adDetailUrl({ id: ad.id }))}
                    />
                  </InnerBox>
                );
              })}
            </>
          ) : (
            <InnerBox style={{ marginBottom: 8 }}>
              Inga annonser har skapats för denna lokal
            </InnerBox>
          )}

          <InnerBox style={{ marginTop: 24 }}>
            {hasActiveAd && (
              <OverviewSubtitle style={{ marginBottom: 12 }}>
                Endast en annons i taget kan vara aktiv för ett objekt. Avbryt
                den nuvarande aktiva annonsen för att kunna skapa en ny.
              </OverviewSubtitle>
            )}
            <div style={{ margin: "0 auto" }}>
              <PrimaryButton
                disabled={hasActiveAd}
                title="Skapa ny annons"
                clicked={() => setCreateAdOpen(true)}
              />
            </div>
          </InnerBox>
        </DetailPageBox>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Leads på detta objekt</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <LeadsTable
            {...{
              persistantQuery: leadQuery,
            }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
