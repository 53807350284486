import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { detailUrl } from "../../../store/apartments";
import { constants } from "../../../store/parkingSpotCancellations";
import moment from "moment";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import useActiveFilters from "../../../hooks/useActiveFilters";

export default ({
  persistantQuery = {},
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  const [startDate, setStartDate] = React.useState(
    moment({ year: moment().year(), month: 0, day: 1 })
  );
  const [endDate, setEndDate] = React.useState(
    moment({ year: moment().year(), month: 11, day: 31 })
  );

  let [query, setQuery] = React.useState(persistantQuery);

  React.useEffect(
    () =>
      setQuery({
        ...persistantQuery,
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      }),

    [startDate, endDate]
  );

  const columns = React.useMemo(() => columnDefs(), [query]);

  const badges = {};
  const filters = {};

  return (
    <>
      <div style={{ display: "flex", maxWidth: "100%" }}>
        <div style={{ flex: 1, maxWidth: "50%", marginRight: "4px" }}>
          <NonConnectedDatePicker
            value={startDate.format("YYYY-MM-DD")}
            onChange={(value) => {
              value != null && setStartDate(moment(value));
            }}
            label={"Från"}
          />
        </div>
        <div style={{ flex: 1, maxWidth: "50%", marginRight: "4px" }}>
          <NonConnectedDatePicker
            value={endDate.format("YYYY-MM-DD")}
            onChange={(value) => {
              value != null && setEndDate(moment(value));
            }}
            label={"Till"}
          />
        </div>
      </div>

      <BaseTable
        tableId={tableId || "apartmentcancellations_full_table"}
        title={"Fordonsplatser"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery: query,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
};
