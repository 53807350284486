import * as React from "react";

import { useRouteMatch, Redirect, Route, Switch } from "react-router-dom";

import CreateNewGroup from "../../../../../views/Account/UserSetup/CreateNewGroup";
import CreateNewWhiteListSettings from "../../../../../views/Account/UserSetup/CreateNewWhiteListSettings";
import CreateUser from "../../../../../views/Account/UserSetup/CreateUser";
import InviteUser from "../../../../../views/Account/UserSetup/InviteUser";
import PickExistingGroup from "../../../../../views/Account/UserSetup/PickExistingGroup";
import SetUserPermissions from "../../../../../views/Account/UserSetup/SetUserPermissions";
import EditUser from "../../../../../views/Account/EditUser";
import User from "../../../../../views/Account/User";
import Users from "../../../../../views/Account/Users";

import Groups from "../../../../../views/Account/Groups";
import AddGroup from "../../../../../views/Account/AddGroup";
import Group from "../../../../../views/Account/Group";
import GroupContentTypeDetail from "../../../../../views/Account/GroupContentTypeDetail";
import EditGroup from "../../../../../views/Account/EditGroup";



import * as SC from "../../styles";


export default () => {
  const { path, url } = useRouteMatch();

  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  const SUB_PAGES = [
    {
      path: "/user-setup/create-user",
      component: CreateUser,
    },
    {
      path: "/user-setup/set-user-permissions/existing-group/:userId",
      component: PickExistingGroup,
    },
    {
      path: "/user-setup/set-user-permissions/create-group/:userId",
      component: CreateNewGroup,
    },
    {
      path: "/user-setup/set-user-permissions/create-white-list-setting/:groupId/:userId",
      component: CreateNewWhiteListSettings,
    },
    {
      path: "/user-setup/set-user-permissions/:userId",
      component: SetUserPermissions,
    },
    {
      path: "/user-setup/invite-user/:groupId/:userId",
      component: InviteUser,
    },
    {
      path: "/user/edit/:userId",
      component: EditUser,
    },
    {
      path: "/user/:userId",
      component: User,
    },
    {
      path: "/user",
      component: Users,
    },
    {
      path: "/group/add-group",
      component: AddGroup,
    },
    {
      path: "/group/edit/:groupId",
      component: EditGroup,
    },
    {
      path: "/group/:groupId/:contentType",
      component: GroupContentTypeDetail,
    },
    {
      path: "/group/:groupId",
      component: Group,
    },
    {
      path: "/group",
      component: Groups,
    },
    

  ];
  return (
    <SC.ConfigCenterDetailWrapper>
      <SC.ConfigCenterSideBarDetailWrapper>
        <SC.DetailPageBox>
          <SC.DetailPageItem
            active={getIsActive(
              "/configcenter/groups-and-users/user",
              false
            )}
            to={"/configcenter/groups-and-users/user"}
          >
            Användare
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={getIsActive(
              "/configcenter/groups-and-users/group",
              false
            )}
            to={"/configcenter/groups-and-users/group"}
          >
            Behörighetsgrupper
          </SC.DetailPageItem>
        
        </SC.DetailPageBox>
      </SC.ConfigCenterSideBarDetailWrapper>

      <SC.ConfigCenterDetailElement>
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              exact={sp.exact}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[8].path}`} path={`${path}`} />
        </Switch>
      </SC.ConfigCenterDetailElement>
    </SC.ConfigCenterDetailWrapper>
  );
};
