export default [
  {
    title: "User",
    key: "USER",
    visited: true,
    hasError: false,
    fieldKeys: [
      "corporate_name",
      "first_name",
      "last_name",
      "email",
      "phone",
      "legal_id",
      "user_type",
      "realestates",
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
