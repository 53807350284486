import * as React from "react";
// style, design
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Select, TextField, TextInput, TimePicker } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingProjects";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Projekt</FormAreaTitle>

      <Select
        title="Status för projekt"
        {...{
          storeName,
          method,
          fieldKey: `status`,
        }}
      />

      <TextInput
        title="Namn på projekt"
        storeName={storeName}
        fieldKey={"title"}
        method={method}
      />
      <TextField
        title="Beskrivning"
        storeName={storeName}
        fieldKey={"description"}
        method={method}
      />

      <DoubleFieldWrapper>
        <TimePicker
          title="Startdatum för projekt"
          {...{
            storeName,
            method,
            fieldKey: `start_date`,
          }}
        />
        <TimePicker
          extraStyle={{ marginLeft: 12 }}
          title="Slutdatum för projekt"
          {...{
            storeName,
            method,
            fieldKey: `end_date`,
          }}
        />
      </DoubleFieldWrapper>

      <TextInput
        title="Projektledare"
        {...{
          storeName,
          method,
          fieldKey: `project_leader`,
        }}
      />

      <TextInput
        title="Kontaktperson"
        {...{
          storeName,
          method,
          fieldKey: `contact_name`,
        }}
      />
    </>
  );
};
