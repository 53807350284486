import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import { TextButton } from "../../Base/Buttons";
import chapterDefs from "./chapterDefs";
import chapters from "./Chapters";
import {
  constants,
  getManualInvoiceProposal,
  invoiceDetailUrl,
  destroyPatchForm,
  destroyPostForm,
} from "../../../../store/billectaInvoicing";
import PrimaryBtn from "../../Base/Buttons/PrimaryBtn";
import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../../store/base";
import { useFilteredCompanies } from "../../../../store/companies";
import { useCompanyInvoicing } from "../../../../store/invoicingCompany";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { constants as debtorInvoicingConstants } from "../../../../store/invoicingDebtor";
import { axiosInstance } from "../../../../store/base/store/axios";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import checkValidations from "../../../Billecta/Invoices/InvoiceComponents/checkValidations";
import {
  getAutogiroSettings,
  getEInvoiceSettings,
  getKivraSettings,
} from "../../../../store/billectaIntegrations";
import checkRequired from "../../../Billecta/Invoices/InvoiceComponents/checkRequired";
import { useManualInvoiceForm } from "../../../../store/billectaInvoicing/hooks/form";
import {
  ExclamationTriangleIcon,
  FolderArrowDownIcon,
} from "@heroicons/react/24/outline";
import Pdf from "../../../Pdf/Pdf";
import {
  attestInvoice,
  createManualInvoice,
  getInvoicePreview,
  updateInvoice,
  creditInvoice,
} from "../../../../store/billectaInvoicing/store/actions";
import { debounce } from "lodash";
import { uid } from "uid";
import { useInvoicingSetting } from "../../../../store/invoicingSettings";
import moment from "moment";
import validationErrorMap from "../../../Billecta/Invoices/InvoiceComponents/validationErrorMap";

export default function InvoicePreviewForm({
  method,
  id,
  isCredit,
  presetTenant,
}) {
  const isPost = method === "POST";
  const storeName = constants.STORE_NAME;

  useManualInvoiceForm(method, id);
  const { push, goBack } = useHistory();

  const dispatch = useDispatch();
  const { creditorId } = useParams();
  const formInstance = useSelector((state) => state[storeName].formInstance);
  const canAttestDirectly = moment(formInstance?.InvoiceDate).isSameOrBefore(
    moment()
  );
  const [loadingProposal, setLoadingProposal] = React.useState(false);
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  const [buttonContainerWidth, setButtonContainerWidth] = React.useState(0);

  const formAreaRef = React.useRef();
  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });
  const [companies] = useFilteredCompanies(companyQuery);
  const invoicingCompany = companies?.[0];
  const usesCentRounding = invoicingCompany?.use_cent_rounding;
  const [companyInvoiceConfig] = useCompanyInvoicing(
    invoicingCompany?.invoicing_details?.id
  );
  const [companyDefaultInvoicingSetting] = useInvoicingSetting(
    invoicingCompany?.invoicing_config?.id
  );
  const [tenant, setTenant] = React.useState(null);
  const [tenantInvoiceConfig, setTenantInvoiceConfig] = React.useState(null);
  const [periodStart, setPeriodStart] = React.useState(null);
  const [periodEnd, setPeriodEnd] = React.useState(null);

  const [invoicePreviewData, setInvoicePreviewData] = React.useState(null);
  const [previewLoading, setPreviewLoading] = React.useState(false);

  const [requiredMissing, setRequiredMissing] = React.useState([]);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [hasValidationError, setHasValidationError] = React.useState(false);
  const [hasRequiredMissing, setHasRequiredMissing] = React.useState(false);
  const [validationCheckVersion, setValidationCheckVersion] =
    React.useState(null);

  const kivraSettings = useSelector(
    (state) => state.billectaIntegrations.kivraSettings
  );
  const eInvoiceSettings = useSelector(
    (state) => state.billectaIntegrations.eInvoiceSettings
  );

  const autogiroSettings = useSelector(
    (state) => state.billectaIntegrations.autogiroSettings
  );

  const tenantHasEInvoiceActivated = !!tenantInvoiceConfig?.e_invoice_bank;

  const tenantHasAutogiro = tenantInvoiceConfig?.use_autogiro;
  const creditorHasAutogiro = !!autogiroSettings;

  React.useEffect(() => {
    if (presetTenant && !tenant) {
      setTenant(presetTenant);
    }
  }, [presetTenant]);

  const onResize = () => {
    if (formAreaRef?.current) {
      const width = formAreaRef.current.offsetWidth;

      setButtonContainerWidth(width);
    }
  };

  React.useLayoutEffect(() => {
    onResize();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm());
      dispatch(destroyPatchForm());
    };
  }, []);

  const getPreview = () => {
    dispatch(
      getInvoicePreview({
        creditorId,
        method,
        isCredit,
        companyId: invoicingCompany?.id,
        skipDownload: true,
        successCallback: (b64) => {
          setInvoicePreviewData(b64);
          setPreviewLoading(false);
        },
        errorCallback: () => {
          setPreviewLoading(false);
        },
      })
    );
  };
  const loadPreviewData = React.useCallback(debounce(getPreview, 1500), [
    invoicingCompany,
  ]);

  // check if should load preview data
  React.useEffect(() => {
    if (
      !validationCheckVersion ||
      !Object.keys(formInstance)?.length > 0 ||
      hasValidationError ||
      hasRequiredMissing ||
      (isPost && !isCredit && !tenant) ||
      (isPost && !isCredit && !tenantInvoiceConfig)
    ) {
      return;
    }
    setPreviewLoading(true);

    loadPreviewData();
  }, [validationCheckVersion]);

  React.useEffect(() => {
    if (!kivraSettings) {
      dispatch(getKivraSettings(creditorId));
    }
    if (!eInvoiceSettings) {
      dispatch(getEInvoiceSettings(creditorId));
    }

    if (!autogiroSettings) {
      dispatch(getAutogiroSettings(creditorId));
    }
  }, []);

  const handleValidationError = React.useCallback((validationError) => {
    if (Object.keys(validationError).length) {
      setHasValidationError(true);
    } else {
      setHasValidationError(false);
    }
  }, []);

  const handleRequiredMissing = React.useCallback((requiredMissing) => {
    if (requiredMissing.length) {
      setHasRequiredMissing(true);
    } else {
      setHasRequiredMissing(false);
    }
  }, []);

  const setValidationErrorsCallback = React.useCallback(
    (errors) => {
      setValidationErrors(errors);
      handleValidationError(errors);
    },
    [validationErrors]
  );

  const setRequiredCallback = React.useCallback(
    (required) => {
      setRequiredMissing(required);
      handleRequiredMissing(required);
    },
    [requiredMissing]
  );

  const onTenantSelected = async (tenant) => {
    if (!tenant || !isPost || isCredit) return;
    try {
      if (!tenant?.debtor_invoice_config?.id) {
        throw Error("Aviseringsinställning saknas på hyresgästen");
      }
      const { data } = await axiosInstance.get(
        `${debtorInvoicingConstants.GET_URL}${tenant.debtor_invoice_config.id}/`
      );

      setTenantInvoiceConfig(data);
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta aviseringsuppfifter till vald mottagare",
          description:
            typeof e === "string"
              ? e
              : "Kontrollera att mottagaren är uppsatt för avisering",
        })
      );
    }
  };

  React.useEffect(() => {
    onTenantSelected(tenant);
  }, [tenant]);

  const setCompanyDefaults = () => {
    const debtSetting = companyDefaultInvoicingSetting?.debt_setting;
    const interval = companyDefaultInvoicingSetting?.interval_setting;

    const dispatchData = {};

    //If tenant has e-invoice set up, that is the only allowed delivery method
    // So, we force default it. The field should also be disabled.
    // Invoice fees are not allowed for Einvoice, so its reset to 0
    if (tenantHasEInvoiceActivated) {
      dispatchData["DeliveryMethod"] = "Einvoice";
      dispatchData["InvoiceFee"] = null;
    }

    if (debtSetting?.use_debt_collection) {
      dispatchData["DebtCollectionDetails"] = {
        DaysDelayAfterDueDate: debtSetting.send_to_debt_after_days || 10,
        NumberOfReminders: debtSetting.number_of_reminders || 1,
        PaymentTermsInDays: debtSetting.debt_term_days || 10,
        SendToDebtCollection: true,
        StartDebtCollectionActionLevel: "Reminders",
      };
    }

    if (interval) {
      if (interval.invoice_period === 1) {
        setPeriodStart(
          moment().set({ date: 1 }).add({ month: 1 }).format("YYYY-MM-DD")
        );
        setPeriodEnd(
          moment().add({ month: 1 }).endOf("month").format("YYYY-MM-DD")
        );
      } else if (interval.invoice_period === 0) {
        setPeriodStart(moment().set({ date: 1 }).format("YYYY-MM-DD"));
        setPeriodEnd(moment().endOf("month").format("YYYY-MM-DD"));
      } else if (interval.invoice_period === -1) {
        setPeriodStart(
          moment().set({ date: 1 }).subtract({ month: 1 }).format("YYYY-MM-DD")
        );
        setPeriodEnd(
          moment().subtract({ month: 1 }).endOf("month").format("YYYY-MM-DD")
        );
      }
    }

    if (Object.keys(dispatchData)?.length > 0) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: dispatchData,
        })
      );
    }
  };

  React.useEffect(() => {
    if (companyDefaultInvoicingSetting && isPost && !isCredit) {
      setCompanyDefaults();
    }
  }, [companyDefaultInvoicingSetting]);

  const setPatchPeriod = () => {
    const records = formInstance?.Records;

    const earliestStart = records?.reduce((acc, cur) => {
      if (cur.RecordType === "Message") return acc;

      if (!acc) return cur.PeriodStart;

      if (moment(acc).isAfter(moment(cur.PeriodStart))) return cur.PeriodStart;

      return acc;
    }, null);

    const latestEnd = records?.reduce((acc, cur) => {
      if (cur.RecordType === "Message") return acc;

      if (!acc) return cur.PeriodEnd;

      if (moment(acc).isBefore(moment(cur.PeriodEnd))) return cur.PeriodEnd;

      return acc;
    }, null);

    if (earliestStart) {
      setPeriodStart(earliestStart);
    }

    if (latestEnd) {
      setPeriodEnd(latestEnd);
    }
  };

  React.useEffect(() => {
    if (!isPost || isCredit) {
      setPatchPeriod();
    }
  }, []);

  const getProposal = () => {
    if (loadingProposal) return;
    setLoadingProposal(true);
    dispatch(
      getManualInvoiceProposal({
        creditorId,
        errorCallback: () => {
          setLoadingProposal(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta föreslagen inställning",
              description: "Avin kan fortfarande skapas manuellt",
            })
          );
        },
        successCallback: () => {
          setLoadingProposal(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title:
                "Föreslagna inställningar hämtades från vald mottagare och aviserande bolag",
            })
          );
        },
        selectedDebtorConfig: tenantInvoiceConfig,
        companyInvoiceConfig,
      })
    );
  };

  // get proposal
  React.useEffect(() => {
    if (tenantInvoiceConfig && companyInvoiceConfig && isPost && !isCredit) {
      getProposal();
    }
  }, [tenantInvoiceConfig, companyInvoiceConfig]);

  React.useEffect(() => {
    if (formInstance) {
      checkValidations({
        setErrors: setValidationErrorsCallback,
        formInstance: formInstance,
        debtorInstance: tenantInvoiceConfig,
        tenant,
        kivraEnabled: kivraSettings?.IsEnabled,
        eInvoiceEnabled: !!eInvoiceSettings?.FUI,
        type: "INVOICE",
      });

      checkRequired({
        formInstance: formInstance,
        type: "INVOICE",
        setRequired: setRequiredCallback,
      });

      setValidationCheckVersion(uid());
    }
  }, [
    formInstance,
    eInvoiceSettings,
    kivraSettings,
    tenant,
    tenantInvoiceConfig,
  ]);

  const update = () => {
    setLoadingCreate(true);

    dispatch(
      updateInvoice({
        successCallback: () => {
          setLoadingCreate(false);

          push(
            invoiceDetailUrl({
              creditorId,
              invoiceId: id,
            })
          );
        },
        errorCallback: (_, returnedData) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Fakturan kunde inte uppdateras",
              description:
                returnedData?.Message || "Kontrollera fälten och försök igen",
            })
          );

          setLoadingCreate(false);
        },
        id,
        companyId: invoicingCompany.id,
      })
    );
  };

  const credit = (attestDirectly = false) => {
    setLoadingCreate(true);

    dispatch(
      creditInvoice({
        debitInvoiceId: id,
        attestDirectly,
        successCallback: (newInvoiceId) => {
          setLoadingCreate(false);

          push(
            invoiceDetailUrl({
              creditorId,
              invoiceId: newInvoiceId,
            })
          );
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kreditfakturan kunde inte skapas",
              description: message || "Kontrollera fälten och försök igen",
            })
          );

          setLoadingCreate(false);
        },
        companyId: invoicingCompany?.id,
      })
    );
  };

  const createInvoice = (attestDirectly = false) => {
    setLoadingCreate(true);
    dispatch(
      createManualInvoice({
        companyId: invoicingCompany.id,
        successCallback: (_, returnedData) => {
          if (attestDirectly) {
            dispatch(
              attestInvoice({
                invoiceId: returnedData.PublicId,
                creditorId,
                skipGetInvoice: true,
                successCallback: () => {
                  setLoadingCreate(false);

                  push(
                    invoiceDetailUrl({
                      creditorId,
                      invoiceId: returnedData?.PublicId,
                    })
                  );
                },
                errorCallback: () => {
                  setLoadingCreate(false);

                  push(
                    invoiceDetailUrl({
                      creditorId,
                      invoiceId: returnedData?.PublicId,
                    })
                  );
                },
              })
            );
          } else {
            setLoadingCreate(false);

            push(
              invoiceDetailUrl({
                creditorId,
                invoiceId: returnedData?.PublicId,
              })
            );
          }
        },
        errorCallback: (_, returnedData) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Fakturan kunde inte skapas",
              description:
                returnedData?.Message || "Kontrollera fälten och försök igen",
            })
          );

          setLoadingCreate(false);
        },
      })
    );
  };

  const downloadPreview = () => {
    const a = document.createElement("a");
    a.href = invoicePreviewData;
    a.download = "preview.pdf";
    a.click();
  };

  return (
    <div className="h-[calc(100vh-73px)] relative w-screen min-w-0 max-w-[100vw] flex">
      {(loadingProposal || loadingCreate) && <OverlaySpinner />}
      <div
        ref={formAreaRef}
        className="flex-1 flex min-w-[600px] max-w-[900px] flex-col relative overflow-y-auto shadow border-r border-slate-200 bg-white pb-16"
      >
        <div className="p-6 flex flex-col bg-slate-50 border-b border-solid border-slate-200">
          <TextButton
            title="Tillbaka"
            clicked={goBack}
            iconType="arrow-back"
            extraStyle={{ marginBottom: 12 }}
          />
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              {isPost && !isCredit ? (
                <OverviewTitle>
                  Skapa ny faktura för{" "}
                  {invoicingCompany?.str_representation || ""}
                </OverviewTitle>
              ) : isCredit ? (
                <OverviewTitle>Skapa kreditfaktura</OverviewTitle>
              ) : (
                <OverviewTitle>Uppdatera faktura</OverviewTitle>
              )}
              {isPost && !isCredit && (
                <OverviewSubtitle>
                  Föreslagna inställningar hämtas automatiskt från bolagets
                  aviseringsinställningar
                </OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
        </div>

        {chapterDefs({ method, isCredit }).map((chapterDef) => {
          const Chapter = chapters[chapterDef.key];

          return (
            <div
              key={chapterDef.key}
              className="p-6 border-b border-solid border-slate-200 flex flex-col odd:bg-slate-50"
            >
              <Chapter
                {...{
                  ...chapterDef,
                  method,
                  storeName,
                  constants,
                  tenant,
                  setTenant,
                  tenantInvoiceConfig,
                  creditorHasAutogiro,
                  tenantHasAutogiro,
                  usesCentRounding,
                  periodEnd,
                  setPeriodEnd,
                  periodStart,
                  setPeriodStart,
                  tenantHasEInvoiceActivated,
                  validationErrors,
                  isCredit,
                }}
              />
            </div>
          );
        })}

        <div
          style={{ width: buttonContainerWidth - 1 }}
          className={`z-1  bg-white fixed bottom-0 p-4 left-0  flex justify-end border-t border-solid border-slate-200`}
        >
          {isPost && !isCredit ? (
            <>
              <PrimaryBtn
                secondary
                disabled={hasValidationError || hasRequiredMissing}
                onClick={() => createInvoice(false)}
              >
                Spara
              </PrimaryBtn>
              <PrimaryBtn
                className="ml-2"
                disabled={
                  hasValidationError || hasRequiredMissing || !canAttestDirectly
                }
                onClick={() => createInvoice(true)}
              >
                Spara och attestera
              </PrimaryBtn>
            </>
          ) : isCredit ? (
            <>
              <PrimaryBtn
                secondary
                disabled={hasValidationError || hasRequiredMissing}
                onClick={() => credit(false)}
              >
                Spara
              </PrimaryBtn>
              <PrimaryBtn
                className="ml-2"
                disabled={hasValidationError || hasRequiredMissing}
                onClick={() => credit(true)}
              >
                Spara och attestera
              </PrimaryBtn>
            </>
          ) : (
            <PrimaryBtn
              disabled={hasValidationError || hasRequiredMissing}
              onClick={update}
            >
              Uppdatera faktura
            </PrimaryBtn>
          )}
        </div>
      </div>
      <div className="flex-1 flex flex-col min-w-0 overflow-y-auto relative bg-slate-100">
        <div className="bg-white p-2 border-b border-solid shadow-md flex items-center justify-between">
          <div className="flex flex-col">
            <div className="text-sm font-medium">Förhandsgranskning</div>
            <div className="text-xs">
              {previewLoading
                ? "Uppdateras..."
                : "Uppdateras automatiskt vid ändringar"}
            </div>
          </div>
          <PrimaryBtn
            disabled={!invoicePreviewData || previewLoading}
            onClick={downloadPreview}
          >
            Ladda ner <FolderArrowDownIcon width={18} className="ml-1" />
          </PrimaryBtn>
        </div>

        {(Object.keys(validationErrors || {})?.length > 0 ||
          requiredMissing?.length > 0 ||
          (isPost && !isCredit && !tenant) ||
          (isPost && !isCredit && !tenantInvoiceConfig)) &&
        !loadingCreate ? (
          <div className="bg-white p-4 m-2 flex flex-col rounded border border-solid border-slate-200 shadow-sm">
            <div className="flex justify-center text-red-500">
              <ExclamationTriangleIcon width={36} />
            </div>
            <div className="my-6">
              Kan ej hämta förhandsgranskning eller spara fakturan då formuläret
              innehåller fel eller saknar data som krävs:
            </div>

            <div>
              {(requiredMissing?.length > 0 ||
                Object.keys(validationErrors)?.length > 0 ||
                !tenant ||
                (tenant && !tenantInvoiceConfig)) && (
                <ul className="list-disc pl-4">
                  {isPost && !isCredit && !tenant && (
                    <li>Ingen mottagare är vald</li>
                  )}
                  {isPost && !isCredit && tenant && !tenantInvoiceConfig && (
                    <li>Mottagaren är ej uppsatt för avisering</li>
                  )}
                  {requiredMissing?.map((r) => (
                    <li key={r}>{r}</li>
                  ))}

                  {Object.keys(validationErrors)?.map((key) => {
                    const readable = validationErrorMap[key] || key;
                    return <li key={key}>{readable}</li>;
                  })}
                </ul>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full">
            <Pdf
              fullScreenFormLayout
              loading={previewLoading}
              documentMissingTitle="Förhandsgranskning laddas in vid förändringar"
              b64={invoicePreviewData}
            />
          </div>
        )}
      </div>
    </div>
  );
}
