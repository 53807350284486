import * as React from "react";

// store, state
import { constants } from "../../../../../store/vacancyBookingSettings";
import {
  Checkbox,
  NumberInput,
  RadioGroup,
  TextInput,
} from "../../../Base/Fields";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställning för vakansbokning</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för hur denna inställning ska genomföra
            vakansbokningar
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        {...{
          method,
          storeName,
          fieldKey: "title",
          title: "Titel på inställning",
        }}
      />

      <RadioGroup
        {...{
          method,
          storeName,
          fieldKey: "for_apartments",
          title: "Gäller för hyreslägenheter",
          options: [
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ],
          defaultValue: true,
        }}
      />

      <RadioGroup
        {...{
          method,
          storeName,
          fieldKey: "for_industrial_premises",
          title: "Gäller för lokaler",
          options: [
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ],
          defaultValue: true,
        }}
      />

      <RadioGroup
        {...{
          method,
          storeName,
          fieldKey: "for_parking_spots",
          title: "Gäller för fordonsplatser",
          options: [
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ],
          defaultValue: true,
        }}
      />

      <NumberInput
        title="Dag i månad för generering"
        description={`
        Vilken dag i månaden som bokningarna genereras.`}
        {...{ storeName, method, fieldKey: "day_in_month" }}
      />
      <NumberInput
        title="Antal månader bak i tiden bokning genereras för"
        description={`Ange hur många månaders förskjutning vakanser ska bokas med. Med t.ex. 1 månad så bokas januaris vakanser på den angivna dagen i februari`}
        {...{ storeName, method, fieldKey: "month_offset" }}
      />

      <TextInput
        {...{
          method,
          storeName,
          fieldKey: "override_bookkeeping_account",
          title: "Bokför på (konto)",
        }}
      />
    </>
  );
};
