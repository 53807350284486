import * as React from "react";

import {
  DatePicker,
  NumberInput,
  RadioGroup,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/paymentContribution";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <DatePicker
      title="Tilläggsdatum"
      description="Datumet tillägg av denna skuld/betalning"
      storeName={storeName}
      method={method}
      fieldKey={"registration_date"}
      defaultNow={true}
      />
      <DatePicker
      title="Registreringsdatum"
      description="Datumet som denna skuld/betalning faktiskt registrerades"
      storeName={storeName}
      method={method}
      fieldKey={"performed_date"}
      defaultNow={true}
      />
      <DatePicker
      title="Period start"
      description="Startdatumet för perioden som betalningen avsåg"
      storeName={storeName}
      method={method}
      fieldKey={"period_start"}
      />
      <DatePicker
      title="Period slut"
      description="Slutdatumet för perioden som betalningen avsåg"
      storeName={storeName}
      method={method}
      fieldKey={"period_end"}
      />
    </>
  );
};
