import styled, { css } from "styled-components";

export const Header = styled.div`
  margin: 100px auto 0 auto;

  font-size: 3rem;

  font-weight: 200;
  text-align: center;
  -webkit-transition: opacity 0.5s linear, margin-top 0.5s linear; /* Safari */
  transition: opacity 0.5s linear, margin-top 0.5s linear;
`;
export const SubHeader = styled.div`
  margin: 20px auto 10px auto;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  -webkit-transition: opacity 0.5s linear, margin-top 0.5s linear; /* Safari */
  transition: opacity 0.5s linear, margin-top 0.5s linear;

  ${(p) =>
    p.onClick &&
    css`
      margin: 0 auto 50px auto;

      text-decoration: underline;
      cursor: pointer;
    `}
`;

export const Gears = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  height: 0;
`;

export const Gear = styled.div`
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: black;

  &:before {
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 2;
    content: "";
    border-radius: 50%;
    background: white;
  }

  &:after {
    position: absolute;
    left: 25px;
    top: 25px;
    z-index: 3;
    content: "";
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid black;
    box-sizing: border-box;
    background: white;
  }
`;

export const Bar = styled.div`
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 0;
  width: 150px;
  height: 30px;
  margin-top: -15px;
  border-radius: 5px;
  background: black;

  &:before {
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 1;
    content: "";
    border-radius: 2px;
    background: white;
  }

  &:nth-child(2) {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
  }

  &:nth-child(3) {
    transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
  }
`;
