import * as React from "react";

import {
  Confirm,
  InvoicingSetting,
  Info,
  Handling,
  ContractSpecific,
  Deposit,
} from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "INVOICING_SETTING":
      return <InvoicingSetting {...{ method }} />;
    case "INVOICING_CONTRACT_SETTING":
      return <ContractSpecific {...{ method }} />;
    case "INVOICING_INFO":
      return <Info {...{ method }} />;

    case "INVOICE_HANDLING":
      return <Handling {...{ method }} />;
    case "DEPOSIT":
      return <Deposit {...{ method }} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
