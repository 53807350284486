import * as React from "react";

import {
  DatePicker,
  RadioGroup,
  TableSelect,
  TextField,
} from "../../../Base/Fields";
import { constants } from "../../../../../store/marketParkingAds";

import { buildQueryString } from "../../../../../store/base";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";

export default ({ method, hideParkingSpot }) => {
  const { filteredRealEstates } = useActiveFilters();
  const storeName = constants.STORE_NAME;

  const parkingQuery = buildQueryString({
    realestate_ids: filteredRealEstates,
  });

  const parkingFilter = (obj) => {
    if (!filteredRealEstates) return obj;

    return filteredRealEstates.includes(obj.realestate.id);
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Annonsinställningar</OverviewTitle>
          <OverviewSubtitle>
            Ställ in hur annonsen ska se ut och fungera
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {!hideParkingSpot && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectField
            storeName={storeName}
            TableComponent={ParkingSpotsTable}
            method={method}
            fieldKey="parking_spot"
            persistantQuery={{
              realestate_ids: filteredRealEstates,
            }}
            title="Fordonsplats annons gäller för"
            placeholder="Välj fordonsplats..."
          />
        </div>
      )}

      <DatePicker
        fieldKey="date_publish"
        title="Publiceringsdatum"
        {...{ storeName, method }}
      />

      <TextField
        title="Annonsbeskrivning"
        description="Denna beskrivning kommer tillsammans med bilder på objektet samt annonsdetaljerna publiceras på aktiva annonsplatser"
        {...{ storeName, method }}
        fieldKey="description"
      />

      <RadioGroup
        extraStyles={{ flex: 1 }}
        fieldKey="is_pending"
        title="Pausad"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />
    </>
  );
};
