import * as React from "react";
import { useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, LinkedObject } from "../../../components/Displays";
import { durationParse } from "../../../components/Displays/utils";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import ServicePartnerTable from "../../../components/Tables/ServicePartners/FullTable";
import RealEstateTable from "../../../components/Tables/RealEstate/FullTable";

import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString, usePermissionCheck } from "../../../store/base";

import { detailUrl as leaseDetailUrl } from "../../../store/leaseContracts";
import { useFilteredNotes } from "../../../store/notes";
import ServiceContractManualCancellation from "../../../components/Details/ServiceContract/Cancellation/ManualSign";
import ServiceContractManualSign from "../../../components/Details/ServiceContract/Signing/ManualSign";
import { useServiceContract } from "../../../store/serviceContracts";
import { detailUrl as companyDetailUrl } from "../../../store/companies";
import UpdateServiceContractOpponentModalForm from "../../../components/Forms/ServiceContract/EditContractOpponentParty/ModalForm";
import { useServiceInvoicing } from "../../../store/invoicingService";
import { getIntervalStr } from "../../../components/Displays/InvoicingHelpers";
import UpdateServiceIntervalModalForm from "../../../components/Forms/ServiceContract/EditContractInterval/ModalForm";
import UpdateServiceContractModalForm from "../../../components/Forms/ServiceContract/EditContractForm/ModalForm";

export default function ServiceContractOverview() {
  const { serviceContractId } = useParams();
  const [contract] = useServiceContract(serviceContractId);
  const [invoicingObj] = useServiceInvoicing(contract?.service_invoicing?.id);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editServiceIntervalOpen, setEditServiceIntervalOpen] =
    React.useState(false);
  const [editServicePartnerOpen, setEditServicePartnerOpen] =
    React.useState(false);

  const canEdit = usePermissionCheck("change_can_contract");

  const showSigningDetails = !contract?.is_signed;

  const isCancelled = contract?.closed_date || contract?.closed_renew_date;

  const notesQ = buildQueryString({
    id__in: contract?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const servicePartnerQuery = {
    id: contract?.opponent_party?.id,
  };

  const realEstateQuery = {
    id__in: contract?.realestates?.map((r) => r.id) || [],
  };

  return (
    <>
      {editOpen && (
        <UpdateServiceContractModalForm
          instance={contract}
          onCheckout={() => setEditOpen(false)}
          id={serviceContractId}
        />
      )}

      {editServicePartnerOpen && (
        <UpdateServiceContractOpponentModalForm
          instance={contract}
          onCheckout={() => setEditServicePartnerOpen(false)}
          id={serviceContractId}
        />
      )}

      <UpdateServiceIntervalModalForm
        instance={invoicingObj}
        isOpen={editServiceIntervalOpen}
        onCheckout={() => setEditServiceIntervalOpen(false)}
        id={invoicingObj?.id}
      />

      <DetailInnerWrapper>
        {contract && showSigningDetails && (
          <>
            <ServiceContractManualSign {...{ contract }} />
          </>
        )}

        {contract && isCancelled && (
          <>
            <ServiceContractManualCancellation />
          </>
        )}

        <DetailPageBoxFlexWrapper>
          <div style={{ width: "65%", marginRight: 12 }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>
                    Översikt över {contract?.id_number}
                  </OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera avtalsdetaljer"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {contract && <DetailInfo infoObj={getInfoObj({ contract })} />}
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Gäller fastigheter</OverviewTitle>
              </OverviewTitleWrapper>

              <RealEstateTable
                persistantQuery={realEstateQuery}
                hideTitle
                hideSearch
                hideExport
                hideFilters
                noSelect
                hideColumns
              />
            </DetailPageBox>
          </div>

          <div style={{ width: "35%" }}>
            {invoicingObj && (
              <DetailPageBox style={{ alignSelf: "flex-start" }}>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Schemaläggning</OverviewTitle>
                    {canEdit && (
                      <OverviewSubtitle>
                        <TextButton
                          title="Redigera schemaläggning"
                          iconType="edit"
                          iconPlacement="right"
                          clicked={() => setEditServiceIntervalOpen(true)}
                        />
                      </OverviewSubtitle>
                    )}
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <InnerBox>
                  {getIntervalStr(invoicingObj?.interval_setting)}
                </InnerBox>
              </DetailPageBox>
            )}

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Servicepartner</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera servicepartner"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditServicePartnerOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <ServicePartnerTable
                persistantQuery={servicePartnerQuery}
                hideTitle
                hideSearch
                hideExport
                hideFilters
                hideColumns
              />
            </DetailPageBox>

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <Notes
                notes={notes}
                contentType="standard.servicecontract"
                objectId={serviceContractId}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ contract }) {
  const infoObj = {
    Avtalsdetaljer: [
      {
        title: "Avtalsstatus",
        value: (
          <StatusLabel state={contract?.state}>
            {contract?.state_display}
          </StatusLabel>
        ),
      },
      { title: "Avtals-ID", value: contract?.id_number },
      {
        title: "Huvudavtal",
        value: (
          <LinkedObject
            obj={contract?.main_contract}
            urlMethod={leaseDetailUrl}
          />
        ),
        hidden: !contract?.main_contract?.id,
      },
      { title: "Startdatum", value: <DateCell date={contract?.start_date} /> },
      { title: "Slutdatum", value: <DateCell date={contract?.end_date} /> },
      {
        title: "Förlängt till",
        value: <DateCell date={contract?.renewed_to} />,
        hidden: !contract?.renewed_to,
      },
      {
        title: "Uppsägningstid",
        value: contract?.notify_interval
          ? durationParse(contract?.notify_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Förlängningstid",
        value: contract?.renew_interval
          ? durationParse(contract?.renew_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Varningstid",
        value: contract?.warning_interval
          ? durationParse(contract?.warning_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Signerades",
        value: <DateCell date={contract?.signed_date} />,
      },
    ],
  };

  if (contract.recurrence?.id) {
    infoObj["Utföranden"] = [
      {
        title: "Nästa utförande",
        value: "-",
      },
    ];
  }

  if (contract?.ordering_party?.id) {
    infoObj["Beställande part"] = [
      {
        title: "Bolag",
        value: contract?.ordering_party ? (
          <LinkedObject
            obj={contract?.ordering_party}
            urlMethod={companyDetailUrl}
          />
        ) : (
          "-"
        ),
      },
    ];
  }

  return infoObj;
}
