import * as React from "react";
import * as SC from "./styles";

export default ({ steps, activeIndex }) => {
  return (
    <SC.StepDisplayWrapper>
      {steps?.map((s, i) => (
        <React.Fragment key={i}>
          <SC.Step finished={i < activeIndex} active={i === activeIndex}>
            {s}
          </SC.Step>
          {i < steps.length - 1 && <SC.StepSeparator />}
        </React.Fragment>
      ))}
    </SC.StepDisplayWrapper>
  );
};
