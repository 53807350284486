import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/sendout";
import {
  useAllPermissionCheck,
  useFrequentPermissions,
} from "../../../store/base";
import DeleteModal from "../../Forms/Delete/DeleteModal";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  const [deleteOpen, setDeleteOpen] = React.useState(null);

  const { hasBillectaViewPermission } = useFrequentPermissions();
  const hasTenantPortal = useAllPermissionCheck([
    "view_can_tenantportal",
    "allow_tenantportal",
  ]);

  const columns = React.useMemo(
    () => columnDefs(setDeleteOpen),
    [persistantQuery, hasTenantPortal, hasBillectaViewPermission]
  );

  const badges = {};
  const filters = {};

  return (
    <>
      <DeleteModal
        isOpen={!!deleteOpen}
        instance={deleteOpen}
        constants={constants}
        closeFunction={() => setDeleteOpen(null)}
      />

      <BaseTable
        tableId={tableId || "sendouts_full_table"}
        title={"Utskick"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
};
