import * as React from "react";
import * as SC from "./styles";

/**
 * The open tooltip will position itself relative to closest relative parent and try to avoid it.
 * forceHorizontalPlacement takes "left" or "right"
 * forceVerticalPlacement tkaes "top" or "bottom"
 */

export default function DescriptionToolTip({
  description,
  forceVerticalPlacement,
  forceHorizontalPlacement,
}) {
  const [open, setOpen] = React.useState(false);
  const iconRef = React.useRef();

  const [align, setAlign] = React.useState("center");
  const [yAlign, setYAlign] = React.useState("center");

  React.useEffect(() => {
    if (iconRef.current) {
      if (forceVerticalPlacement) {
        setYAlign(forceVerticalPlacement);
      }

      if (forceHorizontalPlacement) {
        setAlign(forceHorizontalPlacement);
      }

      const { current } = iconRef;
      const { offsetLeft, offsetWidth, offsetParent, offsetTop } = current;
      const offsetRight = offsetParent.clientWidth - offsetLeft - offsetWidth;

      if (!forceHorizontalPlacement) {
        if (offsetLeft < 120 || offsetRight === 0 /* checkbox */) {
          setAlign("right");
        } else if (offsetRight < 120) {
          setAlign("left");
        }
      }

      if (!forceVerticalPlacement) {
        if (offsetTop < 240) {
          setYAlign("bottom");
        }
      }
    }
  }, [iconRef]);

  return (
    <>
      <SC.InfoIcon
        ref={iconRef}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {open && (
          <SC.InfoContent {...{ yAlign }} {...{ align }}>
            {description}
          </SC.InfoContent>
        )}
      </SC.InfoIcon>
    </>
  );
}
