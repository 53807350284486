import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { BodyText, DetailLayoutWrapper } from "../../../components/sharedStyles";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { useInvoicingSetting, constants } from "../../../store/invoicingSettings";
import InvoicingSettingOverview from "./setting/Overview";
import InvoicingSettingUsedBy from "./setting/UsedBy";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";
import { useLeaseInvoicingPaginationCount } from "../../../store/invoicingLease/hooks/retrieve";
import { useOtherInvoicingPaginationCount } from "../../../store/invoicingOther/hooks/retrieve";
import { useParkingInvoicingPaginationCount } from "../../../store/invoicingParking/hooks/retrieve";
import { useBrfInvoicingPaginationCount } from "../../../store/invoicingBrf/hooks/retrieve";
import { useCompanyPaginationCount } from "../../../store/companies/hooks/retrieve";
import StandardModal from "../../../components/Modals/StandardModal";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

function InvoicingSettingDetail() {
  const { settingId } = useParams();
  const { path, url } = useRouteMatch();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [cantDeleteInfoOpen, setCantDeleteInfoOpen] = React.useState(false);
  const { replace } = useHistory();

  const [setting] = useInvoicingSetting(settingId);

  const usedByFilter = {
    setting: settingId,
  };
  const usedByCompanyFilter = {
    invoicing_config: settingId,
  };

  const [usedByLeaseInvoicingsCount] = useLeaseInvoicingPaginationCount({
    filters: usedByFilter,
  });
  const [usedByOtherInvoicingsCount] = useOtherInvoicingPaginationCount({
    filters: usedByFilter,
  });
  const [usedByParkingInvoicingsCount] = useParkingInvoicingPaginationCount({
    filters: usedByFilter,
  });
  const [usedByBrfInvoicingsCount] = useBrfInvoicingPaginationCount({
    filters: usedByFilter,
  });
  const [usedByCompaniesCount] = useCompanyPaginationCount({
    filters: usedByCompanyFilter,
  });

  const totalCount =
    (usedByLeaseInvoicingsCount || 0) +
    (usedByOtherInvoicingsCount || 0) +
    (usedByParkingInvoicingsCount || 0) +
    (usedByBrfInvoicingsCount || 0) +
    (usedByCompaniesCount || 0);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: InvoicingSettingOverview,
    },
    {
      path: "/used-by",
      label: `Används av (${totalCount})`,
      component: InvoicingSettingUsedBy,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [
    {
      name: "Radera",
      isDelete: true,
      onClick:
        totalCount > 0
          ? () => setCantDeleteInfoOpen(true)
          : () => setDeleteOpen(true),
    },
  ];

  return (
    <>
      <StandardModal
        withActionBar
        title="Kan ej radera inställning"
        isOpen={cantDeleteInfoOpen}
        actionBarCancelTitle="Stäng"
        closeFunction={() => setCantDeleteInfoOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Denna inställning kan ej raderas då den används av {totalCount}{" "}
            objekt
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          För att kunna radera denna inställning måste de eventuella avtal,
          bostadsrätter och bolag som använder sig av den byta
          aviseringsinställning. Under fliken "Används av" finns detaljlistor
          över vilka objekt som använder sig av denna inställning.
        </BodyText>
      </StandardModal>

      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={setting}
        constants={constants}
        deletedCallback={() => replace("/configcenter/rental-notifications")}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Inställning ${setting?.str_representation || "Laddar..."}`}
          subPages={subPageLinks}
          breadCrumbs={[
            {
              url: "/configcenter/rental-notifications/invoicing-setting",
              label: "Avisering"
            },
            {
              label: `Inställning ${
                setting?.str_representation || "Laddar..."
              }`,
            },
          ]}
          handleActions={handleActions}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default InvoicingSettingDetail;
