import * as React from "react";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ErrandRoleUsersTable from "src/components/Tables/RoleUsers/FullTable";

import { constants } from "../../../../../store/inspectionErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InfoBox } from "../../../../Displays";

import { Checkbox } from "../../../Base/Fields";
import DurationField from "../../../Base/Fields/DurationField";

const listDefs = () => [
  {
    Header: "Namn",
    accessor: "str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Roll",
    accessor: "participation.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
  {
    Header: "Fastighet",
    accessor: "participation.realestate.str_representation",
    Cell: ({ value }) => {
      return <div>{value || "-"}</div>;
    },
  },
];
export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställningar för utförare</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för vem som är ansvarig för utförande
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <InfoBox
        title="OBS"
        text="Vid val av specifik utförare kommer inte automatisk delegering att användas."
      />

      <hr />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          TableComponent={ErrandRoleUsersTable}
          method={method}
          fieldKey="performer"
          title="Utförare"
          placeholder="Välj utförare"
        />
      </div>

      <Checkbox
        storeName={storeName}
        method={method}
        fieldKey={"can_use_key"}
        title={"Hyresgäst har förberett för tillträde"}
        description={
          "Hyresgästen har föreberett lägenheten/lokalen för utförares tillträde"
        }
      />

      <DurationField
        storeName={storeName}
        method={method}
        title="Påminn utförare så här lång tid innan planerad start"
        fieldKey="remind_executor_duration"
        menuPlacement="top"
      />
    </>
  );
};
