import React from "react";
import { useDispatch } from "react-redux";
import { updateActiveFormInstance } from "../../../../store/base";
import NonConnectedSelect from "../Old/NonConnected/NonConnectedSelect";

const FLOOR_ALTERNATIVES = [
  { value: -1, label: "KV" },
  { value: 0, label: "BV" },
  { value: 1, label: "Våning 1" },
  { value: 2, label: "Våning 2" },
  { value: 3, label: "Våning 3" },
  { value: 4, label: "Våning 4" },
  { value: 5, label: "Våning 5" },
  { value: 6, label: "Våning 6" },
  { value: 7, label: "Våning 7" },
  { value: 8, label: "Våning 8" },
  { value: 9, label: "Våning 9" },
  { value: 10, label: "Våning 10" },
  { value: 11, label: "Våning 11" },
  { value: 12, label: "Våning 12" },
  { value: 13, label: "Våning 13" },
  { value: 14, label: "Våning 14" },
  { value: 15, label: "Våning 15" },
];

export const renderFloors = (floors) => {
  if (floors?.length < 1) return "-";

  if (floors?.length === 1) {
    if (floors[0] === 0) return "BV";
    return `Våning ${floors[0]}`;
  }
  return floors
    ?.sort((a, b) => a - b)
    ?.map((f) => {
      if (f < 0) return "KV";
      if (f === 0) {
        return "BV";
      }
      return f;
    })
    .join(", ");
};

export default function FloorSelector({
  storeName,
  fieldKey,
  floors,
  customUpdateHandler,
}) {
  const dispatch = useDispatch();

  const fixedFloors = () => {
    let fullFloors = [
      ...new Set(
        floors?.map((f) => FLOOR_ALTERNATIVES.find((fa) => fa.value == f))
      ),
    ];

    return fullFloors;
  };

  const handleFloorChange = (data) => {
    if (customUpdateHandler) {
      customUpdateHandler(data);
    } else {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );
    }
  };

  return (
    <NonConnectedSelect
      id="floorSelect"
      label="Våningsplan"
      helpText="Välj vilken/vilka våningar det gäller"
      isMulti
      value={fixedFloors()}
      choices={FLOOR_ALTERNATIVES}
      getOptionLabel={(obj) => obj.label}
      getOptionValue={(obj) => obj.value}
      onUpdate={handleFloorChange}
    />
  );
}
