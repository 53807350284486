import * as React from "react";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import DocsTable from "../../../components/Tables/BasicDocs/FullTable";

export default function BasicDocs() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate__in: filteredRealEstates,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>
          </OverviewTitleWrapper>

          <DocsTable persistantQuery={persistantQuery} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
