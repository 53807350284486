import * as React from "react";

import { Select, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/tenantPortalSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

const FRESHCHAT_OPTIONS = [
  { d: "Namn", v: "name" },
  { d: "E-postadress", v: "email" },
  { d: "Telefonnummer", v: "phone" },
  { d: "Lägenheter/Lokaler", v: "premises" },
  { d: "Hyresgästnummer", v: "customer_id" },
];

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Intercom integration</OverviewTitle>
          <OverviewSubtitle>
            Fyll i token och vilken data som ska skickas till Intercom vid
            initierad chatt
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        method={method}
        title="Intercom token"
        fieldKey="intercom_token"
        description="Denna token kan hämtas ifrån Intercom."
      />

      <TextInput
        storeName={storeName}
        method={method}
        title="Intercom säkerhetsnyckel"
        fieldKey="intercom_user_key"
        description="Denna nyckel kan genereras och hämtas ifrån Intercom."
      />

      <Select
        isMulti
        method={method}
        storeName={storeName}
        fieldKey="freschat_attributes"
        forceChoices={FRESHCHAT_OPTIONS}
        title="Data som ska skickas till Intercom vid initierad chatt"
        description="Detta är den datan som dyker upp på kundkortet inne i Intercom. Observera att det är kontoinnehavarens ansvar att data som lämnas ut till tredjeparter följer bolagets policy."
      />
    </>
  );
};
