import * as React from "react";
import { RemoveButton } from "../../Billecta/Invoices/InvoiceComponents/Multiple/styles";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default (withRemove, handleRemove) => {
  const cols = [
    {
      Header: "Titel",
      accessor: "name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Studentboende",
      accessor: "student",
      Cell: ({ value }) => <ToolTipCell text={value ? "Ja" : "Nej"} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Seniorboende",
      accessor: "senior",
      Cell: ({ value }) => <ToolTipCell text={value ? "Ja" : "Nej"} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Ungdomsboende",
      accessor: "youth",
      Cell: ({ value }) => <ToolTipCell text={value ? "Ja" : "Nej"} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Rökning tillåtet",
      accessor: "smoking",
      Cell: ({ value }) => <ToolTipCell text={value ? "Ja" : "Nej"} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Husdjur tillåtna",
      accessor: "pets",
      Cell: ({ value }) => <ToolTipCell text={value ? "Ja" : "Nej"} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  if (withRemove) {
    cols.unshift({
      Header: "Ta bort",
      id: "remove",
      Cell: ({ row }) => (
        <RemoveButton
          onClick={(e) => {
            e.stopPropagation();
            handleRemove(row);
          }}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    });
  }

  return cols;
};
