import * as React from "react";

// style, design
// import BaseTable from "src/components/Lists/Base/CompleteList/Table";
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";
import { constants, detailUrl } from "../../../store/otherContracts";
import { buildQueryString, useFrequentPermissions } from "../../../store/base";

import moment from "moment";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import DescriptionToolTip from "../../Forms/Base/Layout/DescriptionToolTip";
import ToggleSqm from "../../Displays/ToggleSqm";
import ToggleYearlyMonthly from "../../Displays/ToggleYearlyMonthly";
import { InnerBox } from "../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../../Forms/Base/Buttons";

import { buildRangeFilter } from "../../Lists/Base/utils";
import { getExpandedContentForContracts } from "src/components/Lists/Base/FinalTable/utils";

import { BADGE_TYPES } from "src/components/Badge/Badge";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export const getDateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  useSpecifiedPeriod,
  setUseSpecifiedPeriod,
  useSqm,
  setUseSqm,
  showMonthlyCosts,
  setShowMonthlyCosts,
}) => {
  let monthChoices = [];
  for (let index = 0; index < 12; index++) {
    monthChoices.push({ id: index, str_representation: `${index + 1}` });
  }

  return (
    <InnerBox>
      <OverviewTitleWrapper style={{ marginBottom: 0 }}>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Inställningar för kostnadsrader</OverviewTitle>
          <OverviewSubtitle>
            {useSpecifiedPeriod ? (
              <TextButton
                title="Använd gällande avtalsår"
                iconType="eventrepeat"
                iconPlacement="right"
                clicked={() => setUseSpecifiedPeriod(false)}
              />
            ) : (
              <TextButton
                title="Specificera period för kostnader"
                iconType="event"
                iconPlacement="right"
                clicked={() => setUseSpecifiedPeriod(true)}
              />
            )}
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>

        <div style={{ display: "flex", alignItems: "center" }}>
          {setUseSqm && (
            <ToggleSqm isSqm={useSqm} setIsSqm={(val) => setUseSqm(val)} />
          )}

          <ToggleYearlyMonthly
            isYearly={!showMonthlyCosts}
            setIsYearly={(val) => setShowMonthlyCosts(!val)}
            extraStyles={{ marginLeft: 24 }}
          />
        </div>
      </OverviewTitleWrapper>

      {useSpecifiedPeriod && (
        <div style={{ display: "flex", alignItems: "center" }}>
          Baserar kostnadsrader på{" "}
          <NonConnectedDatePicker
            extraStyle={{ margin: "0 6px" }}
            inputExtraStyle={{ maxWidth: 140 }}
            format="YYYY-MM"
            noMargin
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
          />{" "}
          till{" "}
          <NonConnectedDatePicker
            extraStyle={{ margin: "0 6px" }}
            inputExtraStyle={{ maxWidth: 140 }}
            format="YYYY-MM"
            noMargin
            value={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
          />
          <DescriptionToolTip
            description={
              "Kostnader och indexuppräkning på objekten i listan räknas ut för det månadsintervallet som är valt. Beräkningen görs från den 1:a i startmånaden till den sista i slutmånaden."
            }
          />
        </div>
      )}
    </InnerBox>
  );
};

export default function OtherContractWithCostsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  withCosts = true,
}) {
  const { hasBillectaViewPermission } = useFrequentPermissions();

  const sDate = moment({ year: moment().year(), month: 0, day: 1 });
  const eDate = moment({ year: moment().year(), month: 11, day: 31 });
  const [startDate, setStartDate] = React.useState(sDate.format("YYYY-MM"));
  const [endDate, setEndDate] = React.useState(eDate.format("YYYY-MM"));

  const [useSqm, setUseSqm] = React.useState(false);

  const [useSpecifiedPeriod, setUseSpecifiedPeriod] = React.useState(false);
  const [showMonthlyCosts, setShowMonthlyCosts] = React.useState(false);

  // const filterInstructions = {
  //   id_number: { operator: "icontains" },
  //   "realestate.str_representation": { operator: "icontains" },
  //   state: {
  //     handler: (filterObject) => {
  //       // for 0 and 1 we want to use an operator to check for any of the two
  //       // operator must be attached to current_state variable
  //       // filter on variable state is only supported on backend as a convenience
  //       if ([0, 1].includes(filterObject?.value)) {
  //         return buildQueryString({ state__in: [0, 1] });
  //       }
  //       return buildQueryString({ state: filterObject.value });
  //     },
  //   },
  //   start_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  //   end_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  // };

  // const exportExclusions = ["main_contract", "content_type"];

  const columns = React.useMemo(
    () =>
      columnDefs(
        startDate,
        endDate,
        withCosts,
        hasBillectaViewPermission,
        useSqm,
        showMonthlyCosts,
        useSpecifiedPeriod
      ),
    [
      persistantQuery,
      startDate,
      endDate,
      withCosts,
      hasBillectaViewPermission,
      useSqm,
      showMonthlyCosts,
      useSpecifiedPeriod,
    ]
  );

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  const filters = {
    Avtalsnummer: {
      queryKey: "id_number",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Motpart: {
      //Not implemented on backend
      queryKey: "tenant_name",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Aviseringsstatus: {
      queryKey: "lease_invoicing_active",
      type: "boolean",
      operators: OPERATOR_TEMPLATES.BOOLEAN,
    },
    Fastighet: {
      queryKey: "realestate_key",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Startdatum: {
      queryKey: "start_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    Slutdatum: {
      queryKey: "end_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  return (
    <>
      {withCosts &&
        getDateRangePicker({
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          useSpecifiedPeriod,
          setUseSpecifiedPeriod,
          useSqm,
          setUseSqm,
          showMonthlyCosts,
          setShowMonthlyCosts,
        })}
      <BaseTable
        tableId={tableId || "othercontracts_full_table"}
        title={"Avtal"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        getContentForExpandedRow={(row) =>
          getExpandedContentForContracts(row, {
            start_date: startDate,
            end_date: endDate,
          })
        }
        isRowExpandable={true}
        disableBadgeFilterCounts
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
