import * as React from "react";

import { RadioGroup } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/key";
import useActiveFilters from "../../../../../hooks/useActiveFilters";

import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";
import SelectField from "src/components/Forms/Base/Fields/SelectField";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();

  const storeName = constants.STORE_NAME;

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            realestate_ids: filteredRealEstates,
          }}
          placeholder="Välj motpart..."
          title="Motpart"
          method={method}
          fieldKey="user"
          TableComponent={UsersTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          title="Innehavande status"
          storeName={storeName}
          method={method}
          fieldKey="possession"
        />

        <TextInputField
          title="Namn"
          storeName={storeName}
          method={method}
          fieldKey="key_name"
        />
        <TextInputField
          title="Serienummer"
          storeName={storeName}
          method={method}
          fieldKey="serial_number"
        />
      </div>

      <RadioGroup
        title="Elektronisk nyckel"
        description="Ange om detta är en elektronsik och inte fysisk nyckel"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"electronic_key"}
        defaultValue={true}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Kod"
          storeName={storeName}
          method={method}
          fieldKey="pin_code"
        />
      </div>
    </>
  );
};
