export const constructStrRep = (obj) => {
  return obj.str_representation || obj.title || "Produkt";
};

export const detailUrl = ({ id }) => {
  return `/rental-notifications/invoicing-objects/products/${id}`;
};

export const PRODUCT_CATEGORIES = {
  INDEX: 0,

  BASE_RENT: 1,

  PROPERTY_TAX: 2,

  ELECTRICITY: 3,

  HOT_WATER: 4,

  COLD_WATER: 5,

  HEATING: 6,

  COOLING: 7,

  WATER: 8,

  PARKING: 9,

  DEPOSIT: 10,

  RENT_INCREASE: 11,

  MEMBER_FEE: 12,
};
