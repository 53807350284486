import * as React from "react";

// style, design
import { DateCell, LinkedObject, ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as industrialDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { detailUrl as cooperativePremisDetailUrl } from "../../../store/brfPremises";
import { detailUrl as commonAreaDetailUrl } from "../../../store/commonAreas";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import moment from "moment";

export default () => [
  {
    Header: "Objekt",
    accessor: (row) => {
      if (row.apartment) {
        return row.apartment.str_representation;
      } else if (row.industrial_premises) {
        return row.industrial_premises.str_representation;
      } else if (row.parking_spot) {
        return row.parking_spot.str_representation;
      } else if (row.brf_premis) {
        return row.brf_premis.str_representation;
      } else if (row.common_area) {
        return row.common_area.str_representation;
      }

      return "Ej kopplad";
    },
    Cell: ({ row }) => {
      const org = row.original;
      if (org.apartment) {
        return (
          <LinkedObject obj={org.apartment} urlMethod={apartmentDetailUrl} />
        );
      } else if (org.industrial_premises) {
        return (
          <LinkedObject
            obj={org.industrial_premises}
            urlMethod={industrialDetailUrl}
          />
        );
      } else if (org.parking_spot) {
        return (
          <LinkedObject obj={org.parking_spot} urlMethod={parkingDetailUrl} />
        );
      } else if (org.brf_premis) {
        return (
          <LinkedObject
            obj={org.brf_premis}
            urlMethod={cooperativePremisDetailUrl}
          />
        );
      } else if (org.common_area) {
        return (
          <LinkedObject obj={org.common_area} urlMethod={commonAreaDetailUrl} />
        );
      }

      return <ToolTipCell text="Ej kopplad" />;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Skapades",
    accessor: "created_at",
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Gäller från",
    accessor: "apply_from",
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Förändringskvot",
    accessor: "quota",
    Cell: ({ value }) => <ToolTipCell text={`${value.toFixed(2)}%`} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Status",
    id: "applied",
    Cell: ({ row }) => {
      const value = moment(row.original.apply_from).isSameOrBefore(moment());

      return (
        <StatusLabel state={value ? 0 : 4}>
          {value ? "Genomförd" : "Planerad"}
        </StatusLabel>
      );
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
];
