export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SERVICECATEGORY",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SERVICECATEGORY",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SERVICECATEGORY",
  DESTROY_FORM: "DESTROY_FORM_SERVICECATEGORY",
  SET_FORM_ERROR: "SET_FORM_ERROR_SERVICECATEGORY",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SERVICECATEGORY",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SERVICECATEGORY",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SERVICECATEGORY",
  RESET_STATE: "RESET_STATE_SERVICECATEGORY",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SERVICECATEGORY",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_SERVICECATEGORY",
  CLEAR_FETCHED: "CLEAR_FETCHED_SERVICECATEGORY",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SERVICECATEGORY",

  LIST_URL: "/standard/service/servicecontractcategory/list/",

  STORE_NAME: "serviceCategories",
};
