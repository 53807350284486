import * as React from "react";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import { constants } from "../../../../../store/inspectionErrands";

import { InfoBox } from "../../../../Displays";

import { TextInput } from "../../../Base/Fields";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useDispatch } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InspectionErrandSettingsTable from "src/components/Tables/InspectionErrandSettings/FullTable";

export default ({ method, status }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const _dontClear = useFormInstanceField({
    storeName,
    fieldKey: "_dontClear",
  });

  const canChange = () => {
    if (status && status !== 0) return false;

    return true;
  };

  const handleTypeChanged = () => {
    if (!_dontClear) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            apartment: undefined,
            industrial_premises: undefined,
            brf_premis: undefined,
            parking_spot: undefined,
            common_area: undefined,
          },
        })
      );
    }
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Inställning</OverviewTitle>
          <OverviewSubtitle>
            Välj vilken typ av besiktning som ska skapas
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {!canChange() && (
        <InfoBox
          title={
            status === 3 || status === 4
              ? "Ärendet slutfört"
              : "Ärendet påbörjat"
          }
          text={`Eftersom ärendet är ${
            status === 3 || status === 4 ? "slutfört" : "påbörjat"
          } kan du inte redigera typ av besiktning.`}
        />
      )}

      <TextInput
        storeName={storeName}
        fieldKey={"title"}
        method={method}
        title="Titel"
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"setting"}
          placeholder="Välj typ..."
          title="Typ av besiktning"
          persistantQuery={{ kind__isnull: true }}
          disabled={!canChange()}
          changeCallback={handleTypeChanged}
          TableComponent={InspectionErrandSettingsTable}
        />
      </div>
    </>
  );
};
