import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import FlowForm from "../../components/Forms/ParkingSpot/FlowForm/FlowForm";
import { updateActiveFormInstance } from "../../store/base";
import { useParkingSpot, constants } from "../../store/parkingSpots";

export default () => {
  const dispatch = useDispatch();

  const { parkingSpotId } = useParams();
  const [parkingSpot] = useParkingSpot(parkingSpotId);

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: parkingSpot,
      })
    );
  }, [parkingSpot]);

  return <FlowForm id={parkingSpotId} method="PATCH" />;
};
