import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { LinkedObject } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { detailUrl as checklistDetailUrl } from "../../../store/checklists";
import { detailUrl as userDetailUrl } from "../../../store/users";
import {
  detailUrl as componentTypeDetailUrl,
  useComponentType,
} from "../../../store/componentTypes";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import ProductTypeForm from "../../../components/Forms/ProductType/ChapterForm/ModalForm";
import { useProductType, update } from "../../../store/productTypes";
import StandardModal from "../../../components/Modals/StandardModal";
import Table from "../../../components/Lists/Base/CompleteList/Table";

import articleListDefs from "../../../components/Tables/ErrandArticles/listDefs";
import {
  constants as articleConstants,
  useErrandArticlesPagination,
  performFilter as performArticleFilter,
  useFilteredErrandArticles,
} from "../../../store/errandArticles";
import { cloneDeep } from "lodash";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import { buildQueryString } from "../../../store/base";
import { toMoneyString } from "../../../components/utils/stringUtils";

export default function ProductTypeDetailOverview() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);
  const [addArticlesLoading, setAddArticlesLoading] = React.useState(false);
  const [removeArticlesLoading, setRemoveArticlesLoading] =
    React.useState(false);

  const [productType] = useProductType(id);
  const [componentType] = useComponentType(productType?.component_type?.id);
  const [pickArticlesOpen, setPickArticlesOpen] = React.useState(false);
  const [selectedArticles, setSelectedArticles] = React.useState([]);

  const articleQ = buildQueryString({
    id__in: productType?.default_articles?.map((da) => da.id) || [],
  });
  const [defaultArticles, defaultArticlesLoading] =
    useFilteredErrandArticles(articleQ);

  React.useEffect(() => {
    if (pickArticlesOpen) {
      setSelectedArticles(defaultArticles || []);
    }
  }, [pickArticlesOpen]);

  const addSelectedArticles = () => {
    setAddArticlesLoading(true);

    dispatch(
      update({
        forceData: {
          default_articles: selectedArticles,
        },
        id,
        successCallback: () => {
          setSelectedArticles([]);
          setAddArticlesLoading(false);
          setPickArticlesOpen(false);
        },
        errorCallback: () => {
          setAddArticlesLoading(false);
        },
      })
    );
  };

  const checkRowHighlighted = (row) => {
    const { original } = row;
    const match = selectedArticles?.find((s) => s.id === original.id);

    return match;
  };

  const onSelected = (row) => {
    const { original } = row;

    let selectedClone = cloneDeep(selectedArticles);

    if (!selectedClone) {
      selectedClone = [original];
    } else if (selectedClone.find((s) => s.id === original.id)) {
      selectedClone = selectedClone.filter((s) => s.id !== original.id);
    } else {
      selectedClone.push(original);
    }

    setSelectedArticles(selectedClone);
  };

  const removeArticle = (article) => {
    setRemoveArticlesLoading(true);

    const newArticles = productType?.default_articles?.filter(
      (a) => a.id !== article.id
    );

    dispatch(
      update({
        forceData: {
          default_articles: newArticles,
        },
        id,
        successCallback: () => {
          setRemoveArticlesLoading(false);
        },
        errorCallback: () => {
          setRemoveArticlesLoading(false);
        },
      })
    );
  };

  return (
    <>
      <ProductTypeForm
        method="PATCH"
        id={id}
        instance={productType}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <StandardModal
        isOpen={pickArticlesOpen}
        closeFunction={() => setPickArticlesOpen(false)}
        withActionBar
        title="Lägg till kopplade artiklar"
        saveFunction={addSelectedArticles}
      >
        {addArticlesLoading && <OverlaySpinner />}
        <Table
          storeName={articleConstants.STORE_NAME}
          columns={articleListDefs()}
          paginationMethod={useErrandArticlesPagination}
          fetchAllTreshold={25}
          filterAction={performArticleFilter}
          {...{
            hideExport: true,
            hideColumns: true,
            onRowClicked: onSelected,
            checkRowHighlighted,
          }}
        />
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>
              <OverviewSubtitle>
                <TextButton
                  iconType="edit"
                  iconPlacement="right"
                  title="Redigera komponentmodell"
                  clicked={() => setEditOpen(true)}
                />
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <DetailInfo
            infoObj={getDetailInfoObj({ productType, componentType })}
          />
        </DetailPageBox>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Kopplade artiklar</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              title="Lägg till kopplad artikel"
              clicked={() => setPickArticlesOpen(true)}
            />
          </OverviewTitleWrapper>

          {removeArticlesLoading && <OverlaySpinner />}

          <PremisesTable
            onRowClicked={() => {}}
            headers={["Namn", "Artikelnr.", "Enhet", "Kostnad per enhet", ""]}
            rows={defaultArticles?.map((a) => {
              return [
                a.title,
                a.code,
                a.unit,
                toMoneyString(a.cost_per_unit, true),
                <TextButton
                  title="Ta bort"
                  red
                  clicked={() => removeArticle(a)}
                />,
              ];
            })}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getDetailInfoObj({ productType, componentType }) {
  return {
    Komponentmodell: [
      {
        title: "Komponenttyp",
        value: (
          <LinkedObject
            obj={productType?.component_type}
            urlMethod={componentTypeDetailUrl}
          />
        ),
      },
      {
        title: "Namn",
        value: productType?.name,
      },
      {
        title: "Artikelnr.",
        value: productType?.code || "-",
      },
      {
        title: "Antal månaders garanti",
        value: productType?.warranty_months || "-",
      },
      {
        title: "URL-länk",
        value: productType?.url_reference ? (
          <a href={productType.url_reference} target="_blank">
            {productType.url_reference}
          </a>
        ) : (
          "-"
        ),
      },
      {
        title: "Checklista för felanmälan",
        value: (
          <LinkedObject
            obj={
              productType?.report_errand_checklist
                ? productType?.report_errand_checklist
                : componentType?.report_errand_checklist
                ? {
                    ...componentType.report_errand_checklist,
                    str_representation: `${
                      componentType.report_errand_checklist
                        .str_representation || ""
                    } (från komponenttyp)`,
                  }
                : null
            }
            urlMethod={checklistDetailUrl}
          />
        ),
      },
      {
        title: "Checklista för besiktning",
        value: (
          <LinkedObject
            obj={
              productType?.inspection_errand_checklist
                ? productType?.inspection_errand_checklist
                : componentType?.inspection_errand_checklist
                ? {
                    ...componentType.inspection_errand_checklist,
                    str_representation: `${
                      componentType.inspection_errand_checklist
                        .str_representation || ""
                    } (från komponenttyp)`,
                  }
                : null
            }
            urlMethod={checklistDetailUrl}
          />
        ),
      },
      {
        title: "Rapport skickas till",
        value: (
          <LinkedObject
            obj={productType?.send_faults_to_user}
            urlMethod={userDetailUrl}
          />
        ),
      },
    ],
  };
}
