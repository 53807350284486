import * as React from "react";

export default () => [
  {
    Header: "Område",
    accessor: "str_representation",
    Cell: ({ value }) => <div>{value}</div>,
  },
  {
    Header: "Fastigheter",
    accessor: "realestates",
    Cell: ({ value }) => {
      return <div>{value?.map((v) => v.str_representation)?.join(",")}</div>;
    },
  },
  {
    Header: "Komponenttyper som ingår",
    accessor: "component_types",
    Cell: ({ value }) => {
      if (value?.length > 3) {
        let rendered = [];
        for (let i = 0; i < 4; i++) {
          rendered.push(value[i]);
        }

        return (
          <div>
            {rendered?.map((v) => v.str_representation)?.join(",")} +
            {value.length - 3}
          </div>
        );
      } else {
        return <div>{value?.map((v) => v.str_representation)?.join(",")}</div>;
      }
    },
  },
];
