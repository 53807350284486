import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

export default () => [
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
  {
    Header: "Fastigheter",
    accessor: "realestates",
    Cell: ({ value }) => <div>{value?.length}</div>,
  },
];
