import ContractDetails from "./ContractDetails";
import Confirm from "./Confirm";

import SignStatus from "./SignStatus";
import Parties from "./Parties";
import ParkingSpots from "./ParkingSpots";

// Map of chapterdef KEY: Component
export default {
  PARTIES: Parties,
  PARKING_SPOTS: ParkingSpots,

  CONTRACT: ContractDetails,

  SIGN_STATUS: SignStatus,
  CONFIRM: Confirm,
};
