import * as React from "react";
import * as SC from "../OverviewInfo/styles";

import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import BasicTable from "../../Billecta/Table/BasicTable";
import { EmailTag, InfoBox, PhoneTag } from "../../Displays";
import moment from "moment";
import DetailInfo from "../OverviewInfo/DetailInfo/DetailInfo";

export default ({ user, group }) => {
  const data = React.useMemo(() => {
    return user?.realestates;
  }, [user]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Tillgång till dessa fastigheter",
        accessor: "_estate",
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.str_representation ||
                "Hittade inte fastighetens namn"}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <DetailInfo infoObj={getInfoObj(user, group)} />

      {user.user_type !== 0 ? (
        <>
          {!!user?.realestates?.length ? (
            <BasicTable
              data={data}
              columns={columns}
              onRowClicked={() => {}}
              tableId="userrealestatestable"
              hideSearch={user?.realestates?.length < 5}
            />
          ) : (
            <div>Användaren är inte kopplad till några fastigheter.</div>
          )}
        </>
      ) : (
        <InfoBox
          title="Fastigheter"
          text="Användaren har tillgång till samtliga fastigheter då användaren är av
          användartyp Admin."
        />
      )}
    </>
  );
};

function getInfoObj(user, group) {
  const isActive = user?.is_active;
  const invited = user?.invite_pending;
  const infoObj = {
    Information: [
      {
        title: "Användartyp",
        value: user?.user_type_display || "-",
      },
      {
        title: "Namn",
        value:
          user?.str_representation ||
          `${user?.first_name} ${user?.last_name} ${
            user?.corporate_name ? `(${user?.corporate_name})` : ""
          }`,
      },
      {
        title: "Status",
        value: (
          <StatusLabel state={isActive ? 0 : invited ? 4 : 3}>
            {isActive ? "Aktiv" : invited ? "Inbjuden" : "Inaktiv"}
          </StatusLabel>
        ),
      },
      { title: "E-post", value: <EmailTag mail={user?.email} /> },
      { title: "Telefon", value: <PhoneTag phone={user?.phone} /> },
      {
        title: user?.corporate_name ? "Orgnr" : "Personnummer",
        value: user?.legal_id || "-",
      },
      {
        title: "Behörighetsgrupp",
        value: group ? (group?.name ? group?.name : "Hittade inte") : "-",
      },
      {
        title: "Aktiv till och med",
        value: user?.active_to
          ? moment(user?.active_to).format("YYYY-MM-DD HH:mm")
          : "Tillsvidare",
      },
    ],
  };

  return infoObj;
}
