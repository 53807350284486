import * as React from "react";

import { TimePicker, TextField, TextInput } from "../../../Base/Fields";

import { constants } from "../../../../../store/calendarEvents";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import { useDispatch, useSelector } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import UsersTable from "src/components/Tables/Users/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const userId = useSelector((state) => state.app.user?.id);

  const participants = useFormInstanceField({
    storeName,
    fieldKey: "participants",
  });

  // need to remove extra step for the table picker to work since we don't want to care about the participant object in frontend
  React.useEffect(() => {
    if (method === "PATCH" && participants?.some((p) => p.user?.id)) {
      const newParticipants = participants?.map((p) => {
        if (p.user?.id) {
          return { ...p.user, str_representation: p.str_representation };
        } else {
          return p;
        }
      });

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            participants: newParticipants,
          },
        })
      );
    }
  }, [participants]);

  const userPersistantQuery = {
    user_type__in: [0, 1, 2],
    "user_id!": userId,
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Att göra</OverviewTitle>
          <OverviewSubtitle>Ange information om uppgiften</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        storeName={storeName}
        title={"Titel"}
        method={method}
        fieldKey={"title"}
      />

      <TextField
        storeName={storeName}
        title={"Uppgift"}
        method={method}
        fieldKey={"description"}
      />

      <TimePicker
        storeName={storeName}
        title={"Ska vara gjord senast"}
        method={method}
        fieldKey={"end"}
      />

      <div className="">
        <TableSelectField
          storeName={storeName}
          isMany
          method={method}
          fieldKey="participants"
          persistantQuery={userPersistantQuery}
          title="Ansvariga för uppgift"
          placeholder="Välj ansvariga..."
          description="Inbjudna deltagare kommer att se denna händelse i sina kalendrar och få notiser om händelsen."
          TableComponent={UsersTable}
        />
      </div>
    </>
  );
};
