import * as React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import Pdf from "../../../components/Pdf/Pdf";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useInspectionErrand } from "../../../store/inspectionErrands";
import { axiosInstance } from "../../../store/base/store/axios";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

export default function Protocol() {
  const dispatch = useDispatch();
  const [result, setResult] = React.useState(null);
  const { inspectionErrandId } = useParams();
  const [inspectionErrand] = useInspectionErrand(inspectionErrandId);

  const getProtocol = async () => {
    try {
      const { data } = await axiosInstance.post(
        "reporting/generators/run_report/errands_inspectionerrandreportgenerator/",
        {
          additional_kwargs: {
            errand_id: parseInt(inspectionErrandId),
          },
          format: "pdf",
        }
      );

      setResult(data.result.get);
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej generera protokoll",
        })
      );
    }
  };

  React.useEffect(() => {
    getProtocol();
  }, []);

  const download = () => {
    const linkSource = result;
    const downloadLink = document.createElement("a");
    const fileName = `${
      inspectionErrand?.errand_id
    }_protokoll_${moment().format("YYYY-MM-DD")}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.target = "_blank";
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <DetailInnerWrapper>
      {result ? (
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{
              flex: 2,

              minHeight: 1000,
              alignSelf: "flex-start",
            }}
          >
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Protokoll</OverviewTitle>
                <OverviewSubtitle>
                  Observera att protokollet uppdateras i takt med att ärendet
                  uppdateras
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>

              <PrimaryButton title="Ladda ner" clicked={download} />
            </OverviewTitleWrapper>

            <Pdf url={result} />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      ) : (
        <DetailPageBox style={{ minHeight: 400 }}>
          <OverlaySpinner />
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
