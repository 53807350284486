import * as React from "react";

export default () => {
  return (
    <div>
      Välj om detta avtal ska aviseras för tillsammans med huvudavtalet eller om
      det ska aviseras separat.
    </div>
  );
};
