import * as React from "react";
import { useDispatch } from "react-redux";
import useActiveFilters from "../../../../hooks/useActiveFilters";
import {
  buildQueryString,
  useAllPermissionCheck,
  useAnyPermissionCheck,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../../../store/base";
import { useFilteredCompanies } from "../../../../store/companies";
import { useInvoicingErrorPaginationCount } from "../../../../store/invoicingErrors/hooks/retrieve";
import { useInvoicingRecordPaginationCount } from "../../../../store/invoicingRecords";
import { useVacancyInvoicingRecordPaginationCount } from "../../../../store/invoicingVacancyRecords";
import {
  useTodoContractStatistics,
  useTodoImdStatistics,
  useTodoPremisesStatistics,
  useTodoTenantStatistics,
  useTodoUserStatistics,
  useTodoErrandStatistics,
  clearTodoOverviewData,
  useTodoBillingInvoiceStatistics,
  useTodoYourBlockStatistics,
} from "../../../../store/overview";
import * as SC from "./styles";
import TaskCard from "./TaskCard";

export default function TaskCards() {
  const dispatch = useDispatch();
  const { filteredRealEstates } = useActiveFilters();

  const { hasBillectaFullPermission } = useFrequentPermissions();
  const hasContractPermission = usePermissionCheck("view_can_contract");
  const hasObjectPermission = usePermissionCheck("view_can_baseobject");
  const hasTenantPermission = useAllPermissionCheck([
    "view_can_tenant",
    "view_can_user",
  ]);
  const hasUserPermission = usePermissionCheck("view_can_user");
  const hasTenantOrUserPermission = hasUserPermission || hasTenantPermission;
  const hasImdPermission = useAllPermissionCheck(["view_can_imd", "allow_imd"]);
  const hasYourBlockPermission = useAllPermissionCheck([
    "view_can_yourblock",
    "allow_yourblock",
  ]);
  const hasAnyErrandPermission = useAnyPermissionCheck([
    "view_can_reporterrand",
    "view_can_inspectionerrand",
    "view_can_roundingerrand",
    "view_can_goverrand",
  ]);

  const hasIntegrationPermission = hasYourBlockPermission; // add more integrations and check if any

  const companyQuery = buildQueryString({
    realestate_ids: filteredRealEstates,
  });
  const [companies] = useFilteredCompanies(companyQuery);

  const creditorIds = companies
    ?.filter((c) => c.billecta_id)
    ?.map((c) => c.billecta_id);

  const invoicingErrorQuery = buildQueryString({
    creditor_id__in: creditorIds,
  });
  const [invoicingErrorCount, invoicingErrorCountLoading] =
    useInvoicingErrorPaginationCount(
      filteredRealEstates?.length ? invoicingErrorQuery : ""
    );

  const [failedInvoicingsCount, failedInvoicingCountLoading] =
    useInvoicingRecordPaginationCount({
      filters: {
        billecta_id__isnull: true,
        skipped_generating: false,
        realestate_ids: filteredRealEstates,
      },
    });
  const [failedVacancyBookingsCount, failedVacancyBookingsCountLoading] =
    useVacancyInvoicingRecordPaginationCount({
      filters: {
        billecta_id__isnull: true,
        skipped_generating: false,
        realestate_ids: filteredRealEstates,
      },
    });

  const [rawTodoContractStats, contractLoading] = useTodoContractStatistics();

  const [rawTodoBillingInvoiceStats, todoBillingLoading] =
    useTodoBillingInvoiceStatistics();
  const [rawTodoPremisesStats, premisesLoading] = useTodoPremisesStatistics();
  const [rawTodoTenantStats, tenantLoading] = useTodoTenantStatistics();
  const [rawTodoUserStats, userLoading] = useTodoUserStatistics();
  const [rawTodoImdStats, imdLoading] = useTodoImdStatistics();
  const [rawErrandStats, errandLoading] = useTodoErrandStatistics();

  const [rawYourBlockStatistics, yourblockLoading] =
    useTodoYourBlockStatistics();

  React.useEffect(() => {
    return () => {
      dispatch(clearTodoOverviewData());
    };
  }, []);

  const chapters = getChapters({
    hasBillectaFullPermission,
    hasContractPermission,
    hasObjectPermission,
    hasTenantOrUserPermission,
    hasImdPermission,
    hasAnyErrandPermission,
    hasIntegrationPermission,

    filteredRealEstates,
    contractStats: rawTodoContractStats?.data,
    contractLoading,
    premisesStats: rawTodoPremisesStats?.data,
    premisesLoading,
    tenantStats: rawTodoTenantStats?.data,
    tenantLoading,
    userStats: rawTodoUserStats?.data,
    userLoading,
    imdStats: rawTodoImdStats?.data,
    imdLoading,
    errandStats: rawErrandStats?.data,
    errandLoading,
    billingComingActions: rawTodoBillingInvoiceStats?.data,
    yourBlockStats: rawYourBlockStatistics?.data,
    integrationsLoading: yourblockLoading,
    companies,

    failedInvoicingsCount,
    invoicingLoading:
      invoicingErrorCountLoading ||
      failedVacancyBookingsCountLoading ||
      invoicingErrorCountLoading ||
      todoBillingLoading,
    failedVacancyBookingsCount,
    invoicingErrorCount,
  });

  return (
    <SC.TaskCardsContainer>
      {chapters.map((card) => (
        <TaskCard key={card.link} {...{ ...card }} />
      ))}
    </SC.TaskCardsContainer>
  );
}

const getChapters = ({
  hasBillectaFullPermission,
  hasContractPermission,
  hasObjectPermission,
  hasTenantOrUserPermission,
  hasImdPermission,
  hasAnyErrandPermission,
  hasIntegrationPermission,

  filteredRealEstates,
  contractStats,
  contractLoading,
  premisesStats,
  premisesLoading,
  tenantStats,
  tenantLoading,
  userStats,
  userLoading,
  imdStats,
  imdLoading,
  errandStats,
  errandLoading,
  billingComingActions,
  yourBlockStats,
  integrationsLoading,
  companies,
  failedInvoicingsCount,
  failedVacancyBookingsCount,
  invoicingErrorCount,
  invoicingLoading,
}) => {
  const billingActions = getBillingActions({ billingComingActions, companies });
  const billingErrors = getBillingErrorItems({
    failedInvoicingsCount,
    failedVacancyBookingsCount,
    invoicingErrorCount,
  });

  const integrationItems = getIntegrationItems({
    yourBlockStats,
    integrationsLoading,
  });

  const errandItems = getErrandItems({
    filteredRealEstates,
    errandStats,
  });

  const contractItems = getContractItems({
    filteredRealEstates,
    contractStats,
  });

  const userItems = getUserItems({
    userStats,
  });

  const tenantItems = getTenantItems({
    filteredRealEstates,
    tenantStats,
  });

  const premisesItems = getPremisesItems({
    filteredRealEstates,
    premisesStats,
  });

  const imdItems = getImdItems({ imdStats });

  let baseChapters = [
    {
      title: "Avisering",
      items: [...billingErrors, ...billingActions],
      loading: invoicingLoading,
      link: "invoicing",
    },
    {
      title: "Avtal",
      items: contractItems,
      loading: contractLoading,
      link: "contracts",
    },
    {
      title: "Objekt",
      items: premisesItems,
      loading: premisesLoading,
      link: "objects",
    },
    {
      title: "Hyresgäster & användare",
      items: [...(userItems || []), ...(tenantItems || [])],
      loading: userLoading && tenantLoading,
      link: "users",
    },
    {
      title: "IMD",
      items: imdItems,
      loading: imdLoading,
      link: "imd",
    },
    {
      title: "Teknisk förvaltning",
      items: errandItems,
      loading: errandLoading,
      link: "errands",
    },
    {
      title: "Integrationer",
      items: integrationItems,
      loading: integrationsLoading,
      link: "integrations",
    },
  ];

  if (!hasBillectaFullPermission) {
    baseChapters = baseChapters.filter((bc) => bc.link !== "invoicing");
  }
  if (!hasContractPermission) {
    baseChapters = baseChapters.filter((bc) => bc.link !== "contracts");
  }
  if (!hasObjectPermission) {
    baseChapters = baseChapters.filter((bc) => bc.link !== "objects");
  }
  if (!hasTenantOrUserPermission) {
    baseChapters = baseChapters.filter((bc) => bc.link !== "users");
  }
  if (!hasImdPermission) {
    baseChapters = baseChapters.filter((bc) => bc.link !== "imd");
  }
  if (!hasAnyErrandPermission) {
    baseChapters = baseChapters.filter((bc) => bc.link !== "errands");
  }
  if (!hasIntegrationPermission) {
    baseChapters = baseChapters.filter((bc) => bc.link !== "integrations");
  }

  return baseChapters;
};

const errandItemsMap = {
  no_performer: "ärenden saknar utförare",
  not_accepted: "ärenden har ej accepterats",
  long_pause: "ärenden har varit pausat länge",
  delayed_start: "ärenden har försenad start",
  delayed_end: "ärenden har dragit ut på tiden",
};

const getIntegrationItems = ({ yourBlockStats, filteredRealEstates }) => {
  let items = [];

  if (!yourBlockStats) return items;

  const unsyncedApartmentsCount = yourBlockStats.unsynced?.apartments?.filter(
    (i) => {
      if (!filteredRealEstates?.length) return i;
      return i?.realestates?.some((re) => filteredRealEstates.includes(re));
    }
  )?.length;

  const unsyncedRealEstatesCount = yourBlockStats.unsynced?.realestates?.filter(
    (i) => {
      if (!filteredRealEstates?.length) return i;
      return filteredRealEstates.includes(i);
    }
  )?.length;

  const unsyncedTenantsCount = yourBlockStats.unsynced?.tenants?.filter((i) => {
    if (!filteredRealEstates?.length) return i;
    return i?.realestates?.some((re) => filteredRealEstates.includes(re));
  })?.length;

  if (unsyncedRealEstatesCount) {
    items.push(
      `${unsyncedRealEstatesCount} fastigheter kunde ej synkas mot YourBlock`
    );
  }

  if (unsyncedApartmentsCount) {
    items.push(
      `${unsyncedApartmentsCount} lägenheter kunde ej synkas mot YourBlock`
    );
  }

  if (unsyncedTenantsCount) {
    items.push(
      `${unsyncedTenantsCount} hyresgäster kunde ej synkas mot YourBlock`
    );
  }

  return items;
};

const getErrandItems = ({ errandStats, filteredRealEstates }) => {
  let items = [];

  if (!errandStats) return items;

  Object.keys(errandStats).forEach((key) => {
    const filteredKeyItems = errandStats[key]?.filter((item) => {
      return filteredRealEstates?.length
        ? filteredRealEstates.some((r) => item.realestates?.includes(r))
        : true;
    });

    if (filteredKeyItems?.length === 0) return;

    items.push(`${filteredKeyItems?.length} ${errandItemsMap[key]}`);
  });

  return items;
};

const getBillingErrorItems = ({
  failedInvoicingsCount,
  failedVacancyBookingsCount,
  invoicingErrorCount,
}) => {
  let items = [];

  if (failedInvoicingsCount > 0) {
    items.push(`${failedInvoicingsCount} misslyckade aviseringar`);
  }
  if (failedVacancyBookingsCount > 0) {
    items.push(`${failedVacancyBookingsCount} misslyckade vakansbokningar`);
  }

  if (invoicingErrorCount > 0) {
    items.push(`${invoicingErrorCount} övriga aviseringsrelaterade fel`);
  }

  return items;
};

export const BILLING_KEY_MAPPER = {
  not_attested: "Att attestera",
};

const getBillingActions = ({ billingComingActions, companies }) => {
  let items = [];

  if (
    Object.keys(billingComingActions || {})?.length === 0 ||
    !companies?.length
  )
    return items;

  const currentCreditors = companies?.map((c) => c.billecta_id);

  Object.keys(billingComingActions).forEach((key) => {
    const filteredBillingComingActionsCount = billingComingActions[key]?.filter(
      (item) => currentCreditors.includes(item.creditor_id)
    )?.length;

    if (filteredBillingComingActionsCount === 0) return;

    items.push(
      `${BILLING_KEY_MAPPER[key] || key} (${filteredBillingComingActionsCount})`
    );
  });

  return items;
};

const getImdItems = ({ imdStats }) => {
  let items = [];

  if (!imdStats) return items;

  const missingPricesCount = imdStats.missing_prices?.length;
  const missingConsumptionsCount = imdStats.missing_consumptions?.length;
  const unconnectedSensorsCount = imdStats.unconnected_sensors?.length;

  if (missingPricesCount) {
    items.push(`${missingPricesCount} mätartyper saknar pris`);
  }

  if (missingConsumptionsCount) {
    items.push(`${missingConsumptionsCount} sensorer saknar mätvärden`);
  }

  if (unconnectedSensorsCount) {
    items.push(
      `${unconnectedSensorsCount} sensorer är ej kopplade till ett objekt`
    );
  }

  return items;
};

const getPremisesItems = ({ filteredRealEstates, premisesStats }) => {
  let items = [];

  if (!premisesStats) return items;

  const vacantWarningCount = premisesStats.vacant_warning?.filter((i) => {
    if (!filteredRealEstates?.length) return i;
    return i?.realestates?.some((re) => filteredRealEstates.includes(re));
  })?.length;

  if (vacantWarningCount) {
    items.push(`${vacantWarningCount} kommande vakanser`);
  }

  return items;
};

const getTenantItems = ({ filteredRealEstates, tenantStats }) => {
  let items = [];

  if (!tenantStats) return items;

  const anonymizeCount = tenantStats.anonymize?.filter((i) => {
    if (!filteredRealEstates?.length) return i;
    return filteredRealEstates.includes(i.realestate);
  })?.length;
  const uninvitedCount = tenantStats.uninvited?.filter((i) => {
    if (!filteredRealEstates?.length) return i;
    return filteredRealEstates.includes(i.realestate);
  }).length;

  if (anonymizeCount) {
    items.push(`${anonymizeCount} hyresgäster bör anonymiseras/tas bort`);
  }

  if (uninvitedCount) {
    items.push(
      `${uninvitedCount} hyresgäster har ej bjudits in till "Mina sidor"`
    );
  }

  return items;
};

const getUserItems = ({ userStats }) => {
  let items = [];

  if (!userStats) return items;

  const unacceptedCount = userStats.unaccapted?.length;
  const missingRealestatesCount = userStats.missing_realestates?.length;
  const inactiveTodayCount = userStats.inactive_today?.length;

  if (unacceptedCount) {
    items.push(`${unacceptedCount} användare har ej accepterat inbjudan`);
  }

  if (missingRealestatesCount) {
    items.push(
      `${missingRealestatesCount} användare saknar tilldelad fastighet`
    );
  }

  if (inactiveTodayCount) {
    items.push(
      `${inactiveTodayCount} användares tillgång till Pigello utgår idag`
    );
  }

  return items;
};

const getContractItems = ({ filteredRealEstates, contractStats }) => {
  let items = [];

  if (!contractStats) return items;

  const notBilledCount = contractStats.not_billed_for?.filter((i) => {
    if (!filteredRealEstates?.length) return i;
    return filteredRealEstates.includes(i.realestate);
  }).length;

  const notSignedCount = contractStats.not_signed?.filter((i) => {
    if (!filteredRealEstates?.length) return i;
    return filteredRealEstates.includes(i.realestate);
  }).length;

  const cancelledUnconfirmedCount = contractStats.cancelled_unconfirmed?.filter(
    (i) => {
      if (!filteredRealEstates?.length) return i;
      return filteredRealEstates.includes(i.realestate);
    }
  ).length;

  const warningCount = contractStats.warning?.filter((i) => {
    if (!filteredRealEstates?.length) return i;
    return filteredRealEstates.includes(i.realestate);
  }).length;

  if (notBilledCount) {
    items.push(`${notBilledCount} avtal aviseras ej för`);
  }
  if (notSignedCount) {
    items.push(`${notSignedCount} avtal är ej signerade`);
  }
  if (cancelledUnconfirmedCount) {
    items.push(
      `${cancelledUnconfirmedCount} avtal är uppsagda men ej bekräftade`
    );
  }
  if (warningCount) {
    items.push(`${warningCount} avtal är utgående`);
  }

  return items;
};
