import { cloneDeep, findIndex } from "lodash";
import * as React from "react";
import { uid } from "uid";
import { useFormField } from "../../../store/base";
import { useRoomForm, constants as roomConstants } from "../../../store/rooms";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import NonConnectedTextInput from "../../Forms/Base/Old/NonConnected/NonConnectedTextInput";
import StandardModal from "../../Modals/StandardModal";

import * as SC from "./styles";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { TextButton } from "../../Forms/Base/Buttons";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import ProductTypesTable from "src/components/Tables/ErrandProductTypes/FullTable";
export default function RoomsAndComponentsMassForm({
  rooms,
  isNonRoom,
  updateFunction,
}) {
  useRoomForm("POST");

  const formCategories = useFormField({
    storeName: roomConstants.STORE_NAME,
    fieldKey: "kind",
    method: "POST",
  });
  const roomKinds = formCategories?._choices;

  const [selectedRoom, setSelectedRoom] = React.useState(null);
  const [addRoomOpen, setAddRoomOpen] = React.useState(false);
  const [roomKind, setRoomKind] = React.useState(null);
  const [roomName, setRoomName] = React.useState(null);
  const [addComponentOpen, setAddComponentOpen] = React.useState(false);

  const [productType, setProductType] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(null);

  const roomCount = rooms?.length;
  const compCount = isNonRoom
    ? rooms?.length
    : selectedRoom?.components?.length;
  const maxCount = roomCount > compCount ? roomCount : compCount;

  const addRoom = () => {
    const roomsClone = cloneDeep(rooms);

    const newRoom = {
      _id: uid(),
      kind: roomKind,
      room_id: roomName,
      components: [],
    };
    roomsClone.push(newRoom);

    updateFunction(roomsClone);
    setRoomKind(null);
    setRoomName("");
    setAddRoomOpen(false);
    setSelectedRoom(newRoom);
  };

  const removeRoom = (room) => {
    const roomsClone = cloneDeep(rooms);

    const idx = roomsClone.findIndex((r) => r._id === room?._id);

    roomsClone.splice(idx, 1);

    updateFunction(roomsClone);
    setSelectedRoom(null);
  };

  const addComponent = () => {
    const roomsClone = cloneDeep(rooms);

    const selectedRoomClone = cloneDeep(selectedRoom);

    selectedRoomClone.components.push({
      _id: uid(),
      product_type: productType,
      from_date: fromDate,
    });

    const idx = roomsClone.findIndex((r) => r._id === selectedRoomClone?._id);
    roomsClone.splice(idx, 1, selectedRoomClone);

    setSelectedRoom(selectedRoomClone);
    updateFunction(roomsClone);
    setProductType(null);
    setFromDate(null);
    setAddComponentOpen(false);
  };

  const addNonRoomComponent = () => {
    const roomsClone = cloneDeep(rooms);

    roomsClone.push({
      _id: uid(),
      product_type: productType,
      from_date: fromDate,
    });

    updateFunction(roomsClone);
    setProductType(null);
    setFromDate(null);
    setAddComponentOpen(false);
  };

  const removeComponent = (comp) => {
    const roomsClone = cloneDeep(rooms);

    const selectedRoomClone = cloneDeep(selectedRoom);

    const compIdx = selectedRoomClone.components.findIndex(
      (c) => c._id === comp._id
    );

    selectedRoomClone.components.splice(compIdx, 1);

    const idx = roomsClone.findIndex((r) => r._id === selectedRoomClone?._id);
    roomsClone.splice(idx, 1, selectedRoomClone);

    setSelectedRoom(selectedRoomClone);

    updateFunction(roomsClone);
  };

  const removeNonRoomComponent = (comp) => {
    const roomsClone = cloneDeep(rooms);

    const idx = roomsClone.findIndex((r) => r._id === comp?._id);
    roomsClone.splice(idx, 1);

    updateFunction(roomsClone);
  };

  return (
    <>
      <StandardModal
        isOpen={addRoomOpen}
        closeFunction={() => setAddRoomOpen(false)}
        title="Lägg till rum"
        canAccept={roomKind != null && !!roomName}
        saveFunction={addRoom}
        withActionBar
        actionBarAcceptTitle="Lägg till"
      >
        <NonConnectedSelect
          choices={roomKinds}
          allowNull={false}
          getOptionLabel={(o) => o.d}
          getOptionValue={(o) => o.v}
          label="Typ av rum"
          id="roomkind"
          value={roomKind}
          onUpdate={(v, d) => {
            setRoomKind(v);
            setRoomName(d.d);
          }}
        />

        <NonConnectedTextInput
          value={roomName}
          onChange={(v) => setRoomName(v)}
          label="Namn på rum"
          id="roomname"
        />
      </StandardModal>

      <StandardModal
        isOpen={addComponentOpen}
        closeFunction={() => setAddComponentOpen(false)}
        title={`Lägg till komponent i ${isNonRoom ? "objekt" : "rum"}`}
        canAccept={productType != null && !!fromDate}
        saveFunction={isNonRoom ? addNonRoomComponent : addComponent}
        withActionBar
        actionBarAcceptTitle="Lägg till"
      >
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            value={productType}
            TableComponent={ProductTypesTable}
            title="Komponentmodell"
            placeholder="Välj komponentmodell..."
            onChange={(val) => setProductType(val)}
          />
        </div>

        <NonConnectedDatePicker
          value={fromDate}
          id="fromdate"
          label="Installationsdatum"
          onChange={(date) => setFromDate(date)}
        />
      </StandardModal>

      <SC.Container maxElementCount={maxCount}>
        {!isNonRoom && (
          <SC.RoomContainer>
            <SC.Header>Rum</SC.Header>
            {rooms?.map((r) => (
              <SC.RoomItem
                selected={selectedRoom?._id === r._id}
                key={r._id}
                onClick={() => setSelectedRoom(r)}
              >
                <div style={{ flex: 3 }}>{r.room_id}</div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <SC.RemoveButton
                    onClick={() => removeRoom(r)}
                    white={selectedRoom?._id === r._id}
                  />
                </div>
              </SC.RoomItem>
            ))}

            {rooms?.length === 0 && (
              <SC.RoomItem>
                Lägg till ett rum genom at klicka på "Lägg till rum" nedanför
              </SC.RoomItem>
            )}

            <SC.AddRoomButton onClick={() => setAddRoomOpen(true)}>
              Lägg till rum <SC.AddRoomIcon />
            </SC.AddRoomButton>
          </SC.RoomContainer>
        )}

        <SC.ComponentsContainer>
          <SC.ComponentHeader>
            <SC.ComponentTypeInner style={{ fontWeight: 600 }}>
              Komponenttyp
            </SC.ComponentTypeInner>
            <SC.ComponentTypeInner style={{ fontWeight: 600 }}>
              Komponentmodell
            </SC.ComponentTypeInner>
            <SC.ComponentTypeInner style={{ fontWeight: 600 }}>
              Installerades
            </SC.ComponentTypeInner>
            <SC.ComponentTypeInner style={{ flex: 1 }}></SC.ComponentTypeInner>
          </SC.ComponentHeader>
          {/* <SC.ComponentItem>Tvättmaskin</SC.ComponentItem> */}

          {(isNonRoom ? rooms : selectedRoom?.components)?.map((c) => (
            <SC.ComponentItem key={c._id}>
              <SC.ComponentTypeInner>
                {c.product_type?.component_type?.str_representation}
              </SC.ComponentTypeInner>

              <SC.ComponentTypeInner>
                {c.product_type?.str_representation}
              </SC.ComponentTypeInner>
              <SC.ComponentTypeInner>{c.from_date}</SC.ComponentTypeInner>
              <SC.ComponentTypeInner style={{ flex: 1 }}>
                <TextButton
                  title="Ta bort"
                  red
                  iconType="close"
                  clicked={() =>
                    isNonRoom ? removeNonRoomComponent(c) : removeComponent(c)
                  }
                />
              </SC.ComponentTypeInner>
            </SC.ComponentItem>
          ))}

          {!selectedRoom && !isNonRoom && (
            <SC.ComponentItem>
              Välj ett rum i listan till vänster för att se rummets komponenter
            </SC.ComponentItem>
          )}

          {((isNonRoom && rooms?.length === 0) ||
            (!isNonRoom &&
              selectedRoom &&
              !(selectedRoom?.components?.length > 0))) && (
            <SC.ComponentItem>
              Lägg till komponenter i {isNonRoom ? "objektet" : "rummet"} genom
              att klicka på "Lägg till komponent" nedanför
            </SC.ComponentItem>
          )}

          {(selectedRoom || isNonRoom) && (
            <SC.AddComponentButton onClick={() => setAddComponentOpen(true)}>
              Lägg till komponent <SC.AddRoomIcon />
            </SC.AddComponentButton>
          )}
        </SC.ComponentsContainer>
      </SC.Container>
    </>
  );
}
