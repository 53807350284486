import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoteContainer = styled.div`
  border: ${(p) => p.theme.borders.standard};
  background-color: ${(p) => p.theme.colors.yellowLight};
  border-radius: 5px;
  border-color: ${(p) => p.theme.colors.yellow};
  margin-bottom: 12px;
  padding: 8px;
  display: flex;
  flex-direction: column;

  ${(p) =>
    p.green &&
    css`
      background-color: ${(p) => p.theme.colors.greenLight};
      border-color: ${(p) => p.theme.colors.green};
    `}
`;

export const NoteHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const NoteTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const NoteDate = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const NoteContent = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
`;
