import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

import InvoicingSettingDetail from "../../../../../views/ConfigCenter/InvoicingSettings/InvoicingSettingsDetail";
import DebtSettingDetail from "../../../../../views/ConfigCenter/InvoicingSettings/DebtSettingsDetail";
import IMDSettingDetail from "../../../../../views/ConfigCenter/InvoicingSettings/IMDSettingsDetail";
import VacancyBookingSettingDetail from "../../../../../views/ConfigCenter/InvoicingSettings/VacancyBookingSettingsDetail";

import InvoicingSetting from "../../../../../views/ConfigCenter/InvoicingSettings/Setting";
import DebtSetting from "../../../../../views/ConfigCenter/InvoicingSettings/DebtSetting";
import IMDSetting from "../../../../../views/ConfigCenter/InvoicingSettings/IMDSetting";
import VacancyBookingSetting from "../../../../../views/ConfigCenter/InvoicingSettings/VacancyBookingSetting";
import DetailedBillectaOutErrors from "../../../../../views/Billecta/DetailedBillectaOutErrors";

import * as SC from "../../styles";

export default () => {
  const { path, url } = useRouteMatch();

  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  const SUB_PAGES = [
    {
      path: "/invoicing-setting/:settingId",
      component: InvoicingSetting,
    },
    {
      path: "/invoicing-setting",
      component: InvoicingSettingDetail,
    },
    {
      path: "/debt-setting/:settingId",
      component: DebtSetting,
    },
    {
      path: "/debt-setting",
      component: DebtSettingDetail,
    },
    {
      path: "/imd-setting/:settingId",
      component: IMDSetting,
    },
    {
      path: "/imd-setting",
      component: IMDSettingDetail,
    },
    {
      path: "/vacancy-booking-setting/:settingId",
      component: VacancyBookingSetting,
    },
    {
      path: "/vacancy-booking-setting",
      component: VacancyBookingSettingDetail,
    },
    {
      path: "/detailed-invoicing-errors",
      component: DetailedBillectaOutErrors,
    }
  ];
  return (
    <SC.ConfigCenterDetailWrapper>
      <SC.ConfigCenterSideBarDetailWrapper>
        <SC.DetailPageBox>
          <SC.DetailPageItem
            active={getIsActive(
              "/configcenter/rental-notifications/invoicing-setting",
              false
            )}
            to={"/configcenter/rental-notifications/invoicing-setting"}
          >
            Avisering
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={getIsActive(
              "/configcenter/rental-notifications/debt-setting",
              false
            )}
            to={"/configcenter/rental-notifications/debt-setting"}
          >
            Betalningsvillkor
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={getIsActive(
              "/configcenter/rental-notifications/imd-setting",
              false
            )}
            to={"/configcenter/rental-notifications/imd-setting"}
          >
            IMD-debitering
          </SC.DetailPageItem>
          <SC.DetailPageItem
            active={getIsActive(
              "/configcenter/rental-notifications/vacancy-booking-setting",
              false
            )}
            to={"/configcenter/rental-notifications/vacancy-booking-setting"}
          >
            Vakansbokning
          </SC.DetailPageItem>
          <SC.DetailPageItem separateMode={true}
            active={getIsActive(
              "/configcenter/rental-notifications/detailed-invoicing-errors",
              false
            )}
            to={"/configcenter/rental-notifications/detailed-invoicing-errors"}
          >
            Aviseringsvarningar
          </SC.DetailPageItem>
        </SC.DetailPageBox>
      </SC.ConfigCenterSideBarDetailWrapper>

      <SC.ConfigCenterDetailElement>
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              exact={sp.exact}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[1].path}`} path={`${path}`} />
        </Switch>
      </SC.ConfigCenterDetailElement>
    </SC.ConfigCenterDetailWrapper>
  );
};
