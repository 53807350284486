import styled, { css } from "styled-components";
import { pulseYellow } from "../../../components/sharedStyles";

export const SliderTabWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  background-color: white;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border: ${(p) => p.theme.borders.standard};
`;

export const Slider = styled.div`
  position: absolute;
  height: 88%;
  top: 6%;
  width: ${(p) => 100 / p.itemCount}%;
  border-radius: 8px;
  left: ${(p) =>
    p.currentItem * (100 / p.itemCount) +
    (p.currentItem === 0 ? "0" : p.currentItem === p.itemCount ? "-1" : 0)}%;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  transition: all 0.2s ease-in-out;
`;

export const SliderTab = styled.div`
  width: ${(p) => 100 / p.itemCount}%;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  display: flex;
  justify-content: center;
  padding: 12px 24px;
  cursor: pointer;
  color: ${(p) => (p.active ? p.theme.colors.white : p.theme.colors.black)};
  z-index: 2;
  transition: all 0.2s ease-in-out;
  position: relative;
  white-space: nowrap;

  ${(p) =>
    p.highlighted &&
    css`
      &:after {
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        top: 10px;
        right: 12px;
        background-color: ${(p) => p.theme.colors.yellow};
        animation: ${pulseYellow} 1.2s linear infinite;
      }
    `}
`;
