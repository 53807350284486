import * as React from "react";
import { useHistory } from "react-router";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import * as SharedStyles from "../../../components/sharedStyles";
import { useAllPermissionCheck } from "../../../store/base";

import { useConfigCenter } from "../../../store/configcenter";

import PageTitle from "../../Layouts/PageTitle/PageTitle";

export default () => {
  const { push } = useHistory();

  const [configCenter] = useConfigCenter();

  const canEditConfigCenter = useAllPermissionCheck([
    "view_can_configcenter",
    "change_can_configcenter",
  ]);

  const goToEdit = () => {
    push(`/configcenter/general/autoinvitation/edit/`);
  };

  const breadCrumbs = [
    {
      label: "Automatisk Inbjudan",
      url: "/configcenter/general/autoinvitation",
    },
  ];

  let actions = [];

  if (canEditConfigCenter)
    actions.push({
      name: "Redigera",
      onClick: () => goToEdit(),
    });

  return (
    <SharedStyles.BareLayoutWrapper fillWidth>
      {!configCenter && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="Automatisk Inbjudan" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Automatisk Inbjudan</OverviewTitle>
          {actions?.length > 0 && (
            <PopupButton title="Hantera" actions={actions} />
          )}
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox style={{ marginBottom: 12 }}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Automatisk inbjudan av kommande hyresgäster
              </OverviewTitle>
              <OverviewSubtitle>
                Om detta värde är satt kommer systemet automatiskt att bjuda in
                hyresgäster till "Mina sidor" så många dagar innan som är
                angivet.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <SharedStyles.InnerBox>
            {configCenter?.tenant_invite_grace_period
              ? `Hyresgäster bjuds in ${configCenter?.tenant_invite_grace_period} dagar innan avtal börjar löpa`
              : "Bjuds ej automatiskt"}
          </SharedStyles.InnerBox>
        </SharedStyles.InnerBox>
      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
