import * as React from "react";
import { useDispatch } from "react-redux";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString } from "../../../store/base";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { inviteUsers } from "../../../store/users/store/actions";
import InvitesTable from "src/components/Tables/Tenants/InvitesTable";
import StandardModal from "../../Modals/StandardModal";

export default function InviteTenantsModal({ open, closeFunction }) {
  const { filteredRealEstates } = useActiveFilters();
  const [inviteLoading, setInviteLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleInvite = (ids) => {
    setInviteLoading(true);
    dispatch(
      inviteUsers({
        userIds: ids,
        successCallback: () => {
          setInviteLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Hyresgästerna bjöds in",
            })
          );

          closeFunction();
        },
        errorCallback: () => {
          setInviteLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Försök igen",
            })
          );
        },
      })
    );
  };

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
    user__is_active: false,
    user__invite_pending: false,
    user__invite_accepted: false,
    "state__in!": [2, 3],
    is_brf: false,
  };

  return (
    <StandardModal
      title={`Bjud in till "Mina sidor"`}
      isOpen={open}
      closeFunction={closeFunction}
      large
      newDesign
    >
      <InvitesTable
        persistantQuery={persistantQuery}
        {...{ handleInvite, inviteLoading }}
      />
    </StandardModal>
  );
}
