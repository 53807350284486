import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default () => {
  const defs = [
    {
      Header: "Titel",
      accessor: "name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Antal användare",
      accessor: "users",
      Cell: ({ value }) => <ToolTipCell text={value?.length} />,
    },

    {
      Header: "Typ",
      accessor: "group_type",
      Cell: ({ value }) => (
        <ToolTipCell text={value != null ? "Användartyp" : "Undergrupp"} />
      ),
    },
  ];

  return defs;
};
