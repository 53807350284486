import * as React from "react";
import * as SC from "./styles";

const STAGES = {
  HOMEQ: [
    {
      name: "Nekade",
      description: "Nekade intressenter",
      order: 1,
    },
    {
      name: "Redo för signering",
      description: "Intressenter som är redo att signera avtal",
      order: 2,
    },
  ],
  SIMPLE: [
    {
      name: "Inkomna",
      description: "Inkomna intressenter",
      order: 1,
    },
    {
      name: "Redo för signering",
      description: "Intressenter som är redo att signera avtal",
      order: 2,
    },
  ],
  EXTENDED: [
    {
      name: "Inkomna",
      description: "Inkomna intressenter",
      order: 1,
    },
    {
      name: "Intressanta",
      description: "Intressanta inkomna intressenter",
      order: 2,
    },
    {
      name: "Kontaktade",
      description: "Intressenter som har blivit kontaktade",
      order: 3,
    },
    {
      name: "Redo för signering",
      description: "Intressenter som är redo att signera avtal",
      order: 4,
    },
  ],
  CUSTOM: [
    {
      name: "Inkomna",
      description: "Inkomna intressenter",
      order: 1,
    },
  ],
};

export default function CreateStages({ createDefaultStages, isHomeQPipe }) {
  return (
    <SC.CreateStagesWrapper>
      {isHomeQPipe && (
        <SC.CreateStagesProposal>
          <SC.CreateStagesProposalHeader>
            <SC.CreateStagesProposalTitle>HomeQ</SC.CreateStagesProposalTitle>

            <SC.CreateStagesProposalSubtitle>
              Steg som är anpassade till vad som behövs i ett HomeQ-projekt
            </SC.CreateStagesProposalSubtitle>
          </SC.CreateStagesProposalHeader>

          <SC.CreateStagesProposalContent>
            {STAGES.HOMEQ.map((s, i) => (
              <>
                {i + 1}. {s.name} <br />
                <br />
              </>
            ))}
          </SC.CreateStagesProposalContent>

          <SC.CreateStagesProposalFooter
            onClick={() => createDefaultStages(STAGES.HOMEQ)}
          >
            Lägg till dessa steg
          </SC.CreateStagesProposalFooter>
        </SC.CreateStagesProposal>
      )}

      {!isHomeQPipe && (
        <>
          <SC.CreateStagesProposal>
            <SC.CreateStagesProposalHeader>
              <SC.CreateStagesProposalTitle>Enkel</SC.CreateStagesProposalTitle>

              <SC.CreateStagesProposalSubtitle>
                Passar t.ex för enklare projekt där mycket av kontroller och
                kontakt sker automatiskt
              </SC.CreateStagesProposalSubtitle>
            </SC.CreateStagesProposalHeader>

            <SC.CreateStagesProposalContent>
              {STAGES.SIMPLE.map((s, i) => (
                <>
                  {i + 1}. {s.name} <br />
                  <br />
                </>
              ))}
            </SC.CreateStagesProposalContent>

            <SC.CreateStagesProposalFooter
              onClick={() => createDefaultStages(STAGES.SIMPLE)}
            >
              Lägg till dessa steg
            </SC.CreateStagesProposalFooter>
          </SC.CreateStagesProposal>

          <SC.CreateStagesProposal>
            <SC.CreateStagesProposalHeader>
              <SC.CreateStagesProposalTitle>
                Utökad
              </SC.CreateStagesProposalTitle>
              <SC.CreateStagesProposalSubtitle>
                Passar t.ex för projekt menade för uthyrning av lokaler där det
                är fler steg inblandade i uthyrningsprocessen
              </SC.CreateStagesProposalSubtitle>
            </SC.CreateStagesProposalHeader>

            <SC.CreateStagesProposalContent>
              {STAGES.EXTENDED.map((s, i) => (
                <>
                  {i + 1}. {s.name} <br />
                  <br />
                </>
              ))}
            </SC.CreateStagesProposalContent>

            <SC.CreateStagesProposalFooter
              onClick={() => createDefaultStages(STAGES.EXTENDED)}
            >
              Lägg till dessa steg
            </SC.CreateStagesProposalFooter>
          </SC.CreateStagesProposal>

          <SC.CreateStagesProposal>
            <SC.CreateStagesProposalHeader>
              <SC.CreateStagesProposalTitle>
                Anpassad
              </SC.CreateStagesProposalTitle>
              <SC.CreateStagesProposalSubtitle>
                Skapa ett anpassat projekt med steg som passar era processer
              </SC.CreateStagesProposalSubtitle>
            </SC.CreateStagesProposalHeader>
            <SC.CreateStagesProposalContent>
              {STAGES.CUSTOM.map((s, i) => (
                <>
                  {i + 1}. {s.name} <br />
                  <br />
                </>
              ))}
              + Egna steg
            </SC.CreateStagesProposalContent>
            <SC.CreateStagesProposalFooter
              onClick={() => createDefaultStages(STAGES.CUSTOM)}
            >
              Skapa anpassat projekt
            </SC.CreateStagesProposalFooter>
          </SC.CreateStagesProposal>
        </>
      )}
    </SC.CreateStagesWrapper>
  );
}
