export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MEASURETYPE",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MEASURETYPE",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MEASURETYPE",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MEASURETYPE",
  DESTROY_FORM: "DESTROY_FORM_MEASURETYPE",
  SET_FORM_ERROR: "SET_FORM_ERROR_MEASURETYPE",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MEASURETYPE",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MEASURETYPE",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MEASURETYPE",
  RESET_STATE: "RESET_STATE_MEASURETYPE",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MEASURETYPE",
  REMOVE_OBJECT: "REMOVE_OBJECT_MEASURETYPE",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_MEASURETYPE",
  CLEAR_FETCHED: "CLEAR_FETCHED_MEASURETYPE",

  LIST_URL: "/imd/measuretype/list/",
  POST_URL: "/imd/measuretype/",
  PATCH_URL: "/imd/measuretype/",
  GET_URL: "/imd/measuretype/",

  STORE_NAME: "IMDMeasureType",
};
