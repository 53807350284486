import { useForm } from "../../base";

import { getPostForm, constants } from "..";

export function useKycForm() {
  let params = {
    storeName: constants.STORE_NAME,
    method: "POST",
  };

  params.fetchMethod = getPostForm;

  return useForm(params);
}
