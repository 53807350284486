import * as React from "react";
import Modal from "../Forms/Base/Modals/Modal";
import ContainerSpinner from "./ContainerSpinner";
import * as SC from "./styles";

export default function ({ text, title = "Laddar..." }) {
  return (
    <div
      id="large-modal"
      tabindex="-1"
      aria-modal="true"
      role="dialog"
      className="overflow-y-auto p-6 overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-slate-700/70 flex justify-center"
    >
      <div className="relative w-full max-w-4xl h-full md:h-auto">
        <div className="relative bg-white rounded shadow min-h-[400px]">
          <div className="flex justify-between items-center p-5 rounded-t border-b ">
            <h3 className="text-l font-medium text-gray-900 ">{title}</h3>
          </div>

          <div className="p-6 space-y-6 flex flex-col justify-center items-center min-h-[400px] h-full">
            <SC.SpinningCircleContainer viewBox="0 0 50 50">
              <SC.SpinningCircle
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke="#93bfec"
                strokeWidth="3"
              />
            </SC.SpinningCircleContainer>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
