import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import { usePermissionCheck } from "../../store/base";
import {
  useIndustrialPremisesPaginationCount,
  createUrl,
} from "../../store/industrialPremises";
import IndustrialPremises from "./overview/IndustrialPremises";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import IndustrialPremisesCancellations from "./overview/IndustrialPremisesCancellations";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function MainIndustrialPremisesOverview() {
  const { path, url } = useRouteMatch();
  const { filteredRealEstates } = useActiveFilters();
  const { push } = useHistory();

  const canAdd = usePermissionCheck("add_can_baseobject");
  usePermissionRedirect(["view_can_baseobject"]);

  const [activeCount] = useIndustrialPremisesPaginationCount({
    filters: { realestate_ids: filteredRealEstates },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Objekt",
      component: IndustrialPremises,
    },
    {
      path: "/cancellations",
      label: "Utflyttsöversikt",
      component: IndustrialPremisesCancellations,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal lokaler: {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <>
      {canAdd && (
        <PrimaryBtn onClick={() => push(createUrl())}>
          Lägg till lokal
        </PrimaryBtn>
      )}
    </>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Lokaler`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
