export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SENDOUT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SENDOUT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SENDOUT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SENDOUT",
  DESTROY_FORM: "DESTROY_FORM_SENDOUT",
  SET_FORM_ERROR: "SET_FORM_ERROR_SENDOUT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SENDOUT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SENDOUT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SENDOUT",
  RESET_STATE: "RESET_STATE_SENDOUT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SENDOUT",
  REMOVE_OBJECT: "REMOVE_OBJECT_SENDOUT",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_SENDOUT",
  CLEAR_FETCHED: "CLEAR_FETCHED_SENDOUT",

  LIST_URL: "/events/sendout/list/",
  POST_URL: "/events/sendout/",
  PATCH_URL: "/events/sendout/",
  GET_URL: "/events/sendout/",

  STORE_NAME: "sendout",
};
