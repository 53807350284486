import styled from "styled-components";
import calendarIcon from "../../../../../assets/svg/calendar.svg";

export const DateIntervalPicker = styled.input.attrs({
  type: "text",
})`
  cursor: pointer;
  position: relative;
  padding: 8px 12px 8px 34px;
  max-width: 140px;
  font-size: 1rem;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 4px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  background-position: 8px;
  background-repeat: no-repeat;
  background-image: url(${calendarIcon});
  background-size: 18px;
`;
