export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CHECKLIST_ROW",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CHECKLIST_ROW",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CHECKLIST_ROW",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CHECKLIST_ROW",
  DESTROY_FORM: "DESTROY_FORM_CHECKLIST_ROW",
  SET_FORM_ERROR: "SET_FORM_ERROR_CHECKLIST_ROW",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CHECKLIST_ROW",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CHECKLIST_ROW",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CHECKLIST_ROW",
  RESET_STATE: "RESET_STATE_CHECKLIST_ROW",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CHECKLIST_ROW",
  REMOVE_OBJECT: "REMOVE_OBJECT_CHECKLIST_ROW",
  CLEAR_FETCHED: "CLEAR_FETCHED_CHECKLIST_ROW",

  LIST_URL: "/errands/checklist/checklistrow/list/",
  POST_URL: "/errands/checklist/checklistrow/",
  PATCH_URL: "/errands/checklist/checklistrow/",
  GET_URL: "/errands/checklist/checklistrow/",

  STORE_NAME: "checklistRow",
};
