import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { uid } from "uid";
import {
  updateActiveFormInstance,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { useFilteredTags } from "../../../../store/tags";
import { CirclePicker } from "react-color";
import { Pill, PillsWrapper } from "../../../sharedStyles";
import StandardModal from "../../../Modals/StandardModal";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import { lightOrDark } from "../../../DigitalDoc/utils";
import NonConnectedTextInput from "../Old/NonConnected/NonConnectedTextInput";
import { TextButton } from "../Buttons";
import { InputFieldTitle } from "../Fields/styles";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

export default ({
  storeName,
  instanceId,
  formMethod,
  instanceMethod,
  destroyPatchForm,
  updateMethod,
  isOpen,
  onDone,
}) => {
  const dispatch = useDispatch();
  const [displayColor, setDisplayColor] = React.useState(undefined);

  const method = "PATCH";

  const formLoaded = formMethod(method, instanceId);
  const [instance] = instanceMethod(instanceId);
  const [loading, setLoading] = React.useState(false);
  const [addNewTagOpen, setAddNewTagOpen] = React.useState(false);
  const [newTagTitle, setNewTagTitle] = React.useState("");
  const [newTagColor, setNewTagColor] = React.useState("");

  const instructions = useFormField({ storeName, method, fieldKey: "tags" });
  const value = useFormInstanceField({ storeName, fieldKey: "tags" });

  const canTitle = instructions?.title && !instructions?.title?._readOnly;
  const canColor = instructions?.color && !instructions?.color?._readOnly;

  const [tags] = useFilteredTags("");

  React.useEffect(() => {
    if (formLoaded && instance && method === "PATCH" && value === undefined) {
      // set editable doc category
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: { tags: instance?.tags || [] },
        })
      );
    }
  }, [formLoaded, dispatch, instance, method, value]);

  const addTag = (tag) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          tags: [...value, tag],
        },
      })
    );
  };

  const removeTag = (tag) => {
    let valueClone = cloneDeep(value);

    valueClone = valueClone.filter((v) => {
      if (tag.id) {
        return v.id != tag.id;
      } else {
        return v._reduxId != v._reduxId;
      }
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          tags: valueClone,
        },
      })
    );
  };

  const createNewTag = () => {
    let valueClone = cloneDeep(value || []);

    valueClone.push({
      title: newTagTitle,
      color: newTagColor?.hex,
      _reduxId: uid(8),
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          tags: valueClone,
        },
      })
    );

    setNewTagTitle("");
    setNewTagColor("");
    setAddNewTagOpen(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Taggen lades till",
      })
    );
  };

  const preProcess = (data) => {
    const clone = cloneDeep(data);
    (clone?.tags || []).forEach((v) => {
      if (v?.color && v?.color?.length <= 6 && v.color[0] !== "#") {
        v.color = `#${v.color}`;
      }
    });

    return clone;
  };

  const onClose = (success) => {
    dispatch(destroyPatchForm(success));
    onDone();
  };

  const onSave = () => {
    setLoading(true);
    if (method === "PATCH") {
      dispatch(
        updateMethod({
          id: instanceId,
          successCallback: () => {
            setLoading(false);
            onClose(true);
          },
          errorCallback: () => {
            setLoading(false);
          },
          preProcess,
        })
      );
    } else {
      onClose(false);
    }
  };

  return (
    <StandardModal
      isOpen={isOpen}
      closeFunction={onClose}
      saveFunction={onSave}
      withActionBar={true}
      title={"Taggar"}
    >
      {loading && <OverlaySpinner />}
      <StandardModal
        modalInModal
        isOpen={addNewTagOpen}
        closeFunction={() => setAddNewTagOpen(false)}
        title="Ny tagg"
        withActionBar
        saveFunction={createNewTag}
        canAccept={!!newTagColor && !!newTagTitle}
        actionBarAcceptTitle="Lägg till tagg"
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Lägg till ny tagg</OverviewTitle>
            <OverviewSubtitle>
              Taggen kommer inte att sparas innan den sparas på objektet
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <NonConnectedTextInput
          label="Namn på tagg"
          value={newTagTitle}
          onChange={(val) => setNewTagTitle(val)}
        />

        <br />

        <InputFieldTitle style={{ marginBottom: 8 }}>
          Färg på tagg
        </InputFieldTitle>
        <div
          style={{
            boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
            border: "thin solid #f3f4f6",
            borderRadius: 8,
            padding: 8,
            display: "inline-flex",
          }}
        >
          <CirclePicker
            color={newTagColor}
            onChangeComplete={(val) => setNewTagColor(val)}
          />
        </div>
      </StandardModal>

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Aktiva taggar</OverviewTitle>
          <OverviewSubtitle>
            Tryck på en tagg för att ta bort den från detta objekt
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <PillsWrapper>
        {value?.map((t) => (
          <Pill
            onClick={() => removeTag(t)}
            style={{
              backgroundColor: t.color,
              color: lightOrDark(t.color) === "light" ? "#000" : "#fff",
            }}
            key={t.id || t._reduxId}
          >
            {t.title}
          </Pill>
        ))}
      </PillsWrapper>

      <hr />

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Tillgängliga taggar</OverviewTitle>
          <OverviewSubtitle>
            Tryck på en tagg för att lägga till på detta objekt
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <PillsWrapper>
        {tags
          ?.filter(
            (t) => !value?.map((at) => at.id || at._reduxId)?.includes(t.id)
          )
          ?.map((t) => (
            <Pill
              onClick={() => addTag(t)}
              style={{
                backgroundColor: t.color,
                color: lightOrDark(t.color) === "light" ? "#000" : "#fff",
              }}
              key={t.id || t._reduxId}
            >
              {t.title}
            </Pill>
          ))}

        {canTitle && canColor && (
          <TextButton
            extraStyle={{ marginBottom: 6 }}
            iconType="add"
            iconPlacement="right"
            clicked={() => setAddNewTagOpen(true)}
            title="Skapa ny tagg"
          />
        )}
      </PillsWrapper>
    </StandardModal>
  );
};
