import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGenerateReportForm } from "../../../store/billectaAccountingReports/hooks/form";
import {
  clearAccountingReports,
  downloadAccountingReport,
  getAccountingReports,
} from "../../../store/billectaAccountingReports/store/actions";
import { useCompany } from "../../../store/companies";
import { InfoBox } from "../../Displays";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import AccountingReportsForm from "./AccountingReportsForm";
import AccountingReportsTable from "./AccountingReportsTable";

export default function AccountingReportsContainer({ forceCompanyId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { companyId } = useParams();

  useGenerateReportForm("POST");

  const [company] = useCompany(forceCompanyId || companyId);

  const creditorId = company?.billecta_id;
  const accountingReports = useSelector(
    (state) => state.billectaAccountingReports.reports
  );

  React.useEffect(() => {
    if (creditorId && !accountingReports) {
      setLoading(true);
      dispatch(
        getAccountingReports({
          creditorId,
          successCallback: () => setLoading(false),
        })
      );
    }
  }, [creditorId]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAccountingReports());
    };
  }, []);

  const downloadFile = (row) => {
    const { FilePublicId, FileName } = row.original.File;

    dispatch(
      downloadAccountingReport({
        fileId: FilePublicId,
        fileName: `${company?.str_representation || ""}_${FileName}`,
      })
    );
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <InfoBox
        boxTheme="info"
        title="Exporter"
        text="Generera en export och tryck sedan på den exporten du vill hämta i listan"
      />
      <AccountingReportsForm creditorId={creditorId} />
      {accountingReports && (
        <AccountingReportsTable
          reports={accountingReports}
          handleRowClicked={downloadFile}
        />
      )}
    </>
  );
}
