import Parties from "./Parties";
import Attachments from "./Attachments";
import Premises from "./Premises";
import ContractDetails from "./ContractDetails";
import Indexation from "./Indexation";
import ExistingDocument from "./ExistingDocument";
import Confirm from "./Confirm";
import SignStatus from "./SignStatus";
import Tax from "./Tax";
export default {
  PARTIES: Parties,
  EXISTINGDOC: ExistingDocument,
  ATTACHMENTS: Attachments,
  PREMISES: Premises,
  CONTRACT: ContractDetails,
  INDEXATION: Indexation,
  CONFIRM: Confirm,
  SIGN_STATUS: SignStatus,
  TAX: Tax,
};
