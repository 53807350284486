export const ALL_VIEW_PERM_MAP = {
  view_can_reporterrand: "Visa felanmälningar",
  view_can_inspectionerrand: "Visa besiktningar",
  view_can_roundingerrand: "Visa ronderingar",
  view_can_goverrand: "Visa myndighetskrav",
  view_can_realestate: "Visa fastigheter",
  view_can_brf: "Visa BRF",
  view_can_company: "Visa företag",
  view_can_baseobject: "Visa objekt",
  view_can_parking: "Visa parkingar",
  view_can_tenant: "Visa hyresgäster",
  view_can_contract: "Visa kontrakt",
  view_can_tenantportal: `Visa "Mina sidor"`,
  view_can_lead: "Visa leads",
  view_can_market: "Visa marknad",
  view_can_user: "Visa användare",
  view_can_permissions: "Visa behörigheter",
  view_can_billing: "Visa avisering/fakturering",
  view_can_e_signing: "Visa e-signering",
  view_can_fastdoc: "Visa fastighetsägarna dokument",
  view_can_homeq: "Visa HomeQ",
  view_can_avytmpl: "Visa Avy/Tmpl",
  view_can_sbf: "Visa Bostadsförmedligen Stockholm",
  view_can_fortnox: "Visa driftnetto (Fortnox)",
  view_can_imd: "Visa IMD",
  view_can_anonymization: "Visa anonymisering",
};

export const ALL_CUSTOMER_PERM_MAP = {
  allow_billecta: "Billecta",
  allow_scrive: "Scrive (E-signering)",
  allow_fastdoc: "Fastighetsägarna dokument",
  allow_tenantportal: "Mina sidor",
  allow_market: "Marknad",
  allow_brf: "BRF",
  allow_errand_faults: "Felanmälningar",
  allow_errand_inspections: "Besiktningar",
  allow_errand_roundings: "Ronderingar",
  allow_errand_gov: "Myndighetskrav",
  allow_imd: "IMD",
  allow_fortnox: "Driftnetto (Fortnox)",
  allow_avytmpl: "Avy/Tmpl",
};
