import * as React from "react";
import MediaField from "src/components/Forms/Base/Fields/MediaField";

import { constants } from "../../../../../store/serviceContracts";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <>
        <MediaField
          title="Dokument för avtal"
          description="Ladda upp det existerande avtalet om det finns ett sådant"
          storeName={storeName}
          fieldKey={"editabledoc.doc"}
          method={method}
        />
      </>
    </>
  );
};
