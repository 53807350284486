import React from "react";
import { PopupButton, PrimaryButton } from "../../Forms/Base/Buttons";
import * as SC from "./styles";
import ModalForm from "../../Forms/TenantPortalSettings/ChapterForm/ModalForm";
import BasicTable from "../../Billecta/Table/BasicTable";

import { OverviewTitle, OverviewTitleWrapper } from "../OverviewInfo/styles";
import { InnerBox } from "../../sharedStyles";
import { useAllPermissionCheck, usePermissionCheck } from "../../../store/base";
import DeleteModal from "../../Forms/Delete/DeleteModal";
import { constants, overviewUrl } from "../../../store/tenantPortalSettings";
import { useHistory } from "react-router";
import { lightOrDark } from "../../DigitalDoc/utils";
import DetailInfo from "../OverviewInfo/DetailInfo/DetailInfo";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";

export default function SettingOverview({ currentSetting, realestates }) {
  const { push } = useHistory();
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(false);

  const canChangePortalSetting = useAllPermissionCheck([
    "allow_tenantportal",
    "view_can_tenantportal",
    "change_can_tenantportal",
  ]); //CUSTOMERPERMISSION?

  const openImage = (src) => {
    window.open(src, "_blank").focus();
  };

  const realestateList = () => {
    const matches = [];
    const settingRealestateIds = currentSetting?.realestates?.map((r) => r.id);
    (realestates || []).forEach((r) => {
      if (settingRealestateIds?.includes(r.id)) {
        matches.push(r.str_representation);
      }
    });
    return matches.length ? matches.join(", ") : "Ingen fastighet kopplad";
  };

  const deletedCallback = () => {
    push(overviewUrl());
  };

  let actions = [];

  if (canChangePortalSetting) {
    //edit perm
    actions.push({
      onClick: () => setEditModalOpen(true),
      name: "Redigera",
    });
  }

  if (canChangePortalSetting) {
    //delete perm
    actions.push({
      onClick: () => setDeleteModalOpen(true),
      name: "Radera",
      isDelete: true,
    });
  }

  const data = React.useMemo(() => {
    return currentSetting?.contact_persons;
  }, [currentSetting?.contact_persons]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Namn",
        accessor: "name",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Telefon",
        accessor: "phone",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
    ],
    []
  );

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitle>Översikt inställning</OverviewTitle>
        {canChangePortalSetting && (
          <PopupButton title="Hantera" actions={actions} />
        )}
      </OverviewTitleWrapper>
      <ModalForm
        method="PATCH"
        id={currentSetting?.id}
        isOpen={editModalOpen}
        existingData={currentSetting}
        onCheckout={setEditModalOpen}
      />
      <DeleteModal
        isOpen={deleteModalOpen}
        instance={currentSetting}
        constants={constants}
        closeFunction={setDeleteModalOpen}
        deletedCallback={deletedCallback}
      />

      <DetailInfo infoObj={getInfoObj({ currentSetting, realestateList })} />

      <InnerBox style={{ marginBottom: "24px" }}>
        <OverviewTitle small style={{ marginBottom: "12px" }}>
          Logga och välkomstbild
        </OverviewTitle>
        <div style={{ display: "flex" }}>
          <SC.ImageWrapper style={{ flex: 1 }}>
            <SC.Image
              onClick={() => openImage(currentSetting?.logo?.get)}
              src={currentSetting?.logo?.get}
            >
              {!currentSetting?.logo?.get && "Bild saknas"}
            </SC.Image>
            <SC.ImageDescription>
              Logga, synlig på inloggningssidan samt i "Mina sidor"
            </SC.ImageDescription>
          </SC.ImageWrapper>
          <SC.ImageWrapper style={{ flex: 1 }}>
            <SC.Image
              onClick={() => openImage(currentSetting?.hello_image?.get)}
              src={currentSetting?.hello_image?.get}
            >
              {!currentSetting?.hello_image?.get && "Bild saknas"}
            </SC.Image>
            <SC.ImageDescription>
              Välkomstbild på inloggningssidan
            </SC.ImageDescription>
          </SC.ImageWrapper>
        </div>
      </InnerBox>
      {currentSetting?.contact_persons?.length ? (
        <InnerBox style={{ padding: " 24px 12px 12px 12px" }}>
          <>
            <OverviewTitle small>Kontaktpersoner</OverviewTitle>
            <BasicTable
              data={data}
              columns={columns}
              onRowClicked={() => {}}
              selectedRow={selectedRow}
              hideSearch={true}
              tableId="settingoverviewtenantportaltable"
              withPersistantSortBy
              withPersistantGlobalFilter
            />
          </>
        </InnerBox>
      ) : null}
    </>
  );
}

function getInfoObj({ currentSetting, realestateList }) {
  const infoObj = {
    Inställningar: [
      {
        title: "Bolagsnamn",
        value: currentSetting?.company_name,
      },
      {
        title: "URL",
        value: `portal.pigello.se/${currentSetting?.verbose_key}`, //TODO: check if this is okay - remove, (probably)
      },
      {
        title: "Välkomstfras",
        value: currentSetting?.hello_phrase,
      },
      {
        title: "Välkomstundertext",
        value: currentSetting?.hello_description,
      },
      {
        title: "Färgkod",
        value: (
          <SC.ColorCode
            backgroundColor={currentSetting?.color_code}
            color={lightOrDark(`#${currentSetting?.color_code}`)}
          >
            {`#${currentSetting?.color_code}`}
          </SC.ColorCode>
        ),
      },
      {
        title: "Fastigheter",
        value: realestateList(),
      },
    ],

    Jourinfo: [{ title: "Jourinfo", value: currentSetting?.jour_info }],

    Funktionalitet: [
      {
        title: "Tillåt skapande av felanmälan",
        value: (
          <StatusLabel state={currentSetting?.hide_errand_handling ? 6 : 0}>
            {currentSetting?.hide_errand_handling ? "Tillåt ej" : "Tillåt"}
          </StatusLabel>
        ),
      },
      {
        title: "Tillåt redigering av personuppgifter",
        value: (
          <StatusLabel
            state={currentSetting?.hide_personal_info_edit_fields ? 6 : 0}
          >
            {currentSetting?.hide_personal_info_edit_fields
              ? "Tillåt ej"
              : "Tillåt"}
          </StatusLabel>
        ),
      },
    ],

    Integrationer: [
      {
        title: "Intercom",
        value: (
          <StatusLabel state={currentSetting?.intercom_token ? 0 : 3}>
            {currentSetting?.intercom_token ? "Aktiv" : "Inaktiv"}
          </StatusLabel>
        ),
      },
      {
        title: "FreshChat",
        value: (
          <StatusLabel state={currentSetting?.freshchat_token ? 0 : 3}>
            {currentSetting?.freshchat_token ? "Aktiv" : "Inaktiv"}
          </StatusLabel>
        ),
      },
      {
        title: "Aptus",
        value: (
          <StatusLabel
            state={
              currentSetting?.apptus_sso_host &&
              currentSetting?.apptus_sso_customer_name_field != null
                ? 0
                : 3
            }
          >
            {currentSetting?.apptus_sso_host &&
            currentSetting?.apptus_sso_customer_name_field != null
              ? "Aktiv"
              : "Inaktiv"}
          </StatusLabel>
        ),
      },
      {
        title: "RCO",
        value: (
          <StatusLabel
            state={
              currentSetting?.rco_sso_host &&
              currentSetting?.rco_sso_customer_name_field != null
                ? 0
                : 3
            }
          >
            {currentSetting?.rco_sso_host &&
            currentSetting?.rco_sso_customer_name_field != null
              ? "Aktiv"
              : "Inaktiv"}
          </StatusLabel>
        ),
      },
    ],
  };
  return infoObj;
}
