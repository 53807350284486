import * as React from "react";
import { buildQueryString } from "../../../store/base";
import { useFilteredMilestones } from "../../../store/leadMilestones";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { BodyText, InnerBox } from "../../sharedStyles";

export default function LeadMilestones({ lead }) {
  const mileQ = buildQueryString({
    id__in: lead?.milestones?.map((m) => m.id),
  });
  const [milestones] = useFilteredMilestones(mileQ);

  return (
    <InnerBox style={{ border: 0, boxShadow: 0 }}>
      <OverviewTitleWrapper>
        <OverviewTitle>Händelser</OverviewTitle>
      </OverviewTitleWrapper>

      {milestones?.map((m) => (
        <InnerBox key={m.id}>
          <OverviewTitleWrapper>
            <OverviewTitle small>{m.str_representation}</OverviewTitle>
          </OverviewTitleWrapper>
        </InnerBox>
      ))}

      {lead.milestones?.length === 0 && (
        <InnerBox>
          <BodyText>Inga händelser på leaden än</BodyText>
        </InnerBox>
      )}
    </InnerBox>
  );
}
