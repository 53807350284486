import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAllPermissionCheck } from "../../../../../store/base";

import { useFilteredMarketingRequirements } from "../../../../../store/marketRequirements";
import { useFilteredPipes } from "../../../../../store/pipes";
import { PILOT_OPTIONS } from "../../../../../views/Market/ManyHandling/CreateManyAds";
import NonConnectedDatePicker from "../../../Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedRadioGroup from "../../../Base/Old/NonConnected/NonConnectedRadioGroup";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";

// style, design

export default ({ storeName, updateDefaultSettings }) => {
  const dispatch = useDispatch();

  const hasHomeQPerm = useAllPermissionCheck(["allow_homeq", "view_can_homeq"]);

  const [availablePipes, setAvailablePipes] = React.useState([]);
  const [availableRequirements, setAvailablaRequirements] = React.useState([]);

  const [requirementProfiles] = useFilteredMarketingRequirements("");
  const [pipes] = useFilteredPipes("");

  React.useEffect(() => {
    if (requirementProfiles?.length && !availableRequirements?.length) {
      setAvailablaRequirements(requirementProfiles);
    }
  }, [requirementProfiles]);

  React.useEffect(() => {
    if (pipes?.length && !availablePipes?.length) {
      setAvailablePipes(pipes);
    }
  }, [pipes]);

  const defaultSettingsInstance = useSelector(
    (state) => state[storeName].defaultSettings
  );

  const updateAttr = ({ attr, val }) => {
    dispatch(updateDefaultSettings({ attr, val }));
  };

  return (
    <>
      <NonConnectedDatePicker
        label="Publiceras"
        value={defaultSettingsInstance.date_publish}
        onChange={(val) => {
          updateAttr({ attr: "date_publish", val });
        }}
        clearable={false}
        id={`date_publish_`}
      />

      <NonConnectedDatePicker
        value={defaultSettingsInstance.access_date}
        onChange={(val) => {
          updateAttr({ attr: "access_date", val });
        }}
        label="Tillträde"
        clearable={false}
        id={`access_date_`}
      />

      <NonConnectedSelect
        extraStyles={{ minWidth: 200 }}
        label="Annonsläge"
        id={`pilot_mode_`}
        value={defaultSettingsInstance.pilot_mode}
        choices={PILOT_OPTIONS}
        getOptionLabel={(o) => o.d}
        getOptionValue={(o) => o.v}
        allowNull={false}
        onUpdate={(val) => {
          updateAttr({ attr: "pilot_mode", val });
        }}
      />

      <NonConnectedSelect
        extraStyles={{ minWidth: 200 }}
        label="Kravprofil"
        id={`requirement_profile_`}
        value={defaultSettingsInstance.requirement}
        choices={availableRequirements}
        getOptionLabel={(o) => o.str_representation}
        getOptionValue={(o) => o.id}
        allowNull={false}
        onUpdate={(val) => {
          updateAttr({ attr: "requirement", val });
        }}
      />

      <NonConnectedSelect
        extraStyles={{ minWidth: 200 }}
        label="Projekt för inkomna leads"
        helpText={
          hasHomeQPerm
            ? "OBS: Om annonserna ska publiceras på HomeQ så måste det valda projektet vara ett HomeQ-projekt"
            : ""
        }
        id={`pipe_`}
        value={defaultSettingsInstance.dump_in_pipe}
        choices={availablePipes}
        getOptionLabel={(o) => o.str_representation}
        getOptionValue={(o) => o.id}
        allowNull={false}
        onUpdate={(val) => {
          updateAttr({ attr: "dump_in_pipe", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`paused_`}
        title="Ange om annonserna ska vara pausade"
        options={[
          {
            label: "Ja, Pausa annonserna",
            value: true,
          },
          {
            label: "Nej, Annonserna ska vara aktiva",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.is_paused}
        onChange={(val) => {
          updateAttr({ attr: "is_paused", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`prior_access_`}
        title="Tidigare inflytt"
        options={[
          {
            label: "Ja, tidigare inflytt möjlig",
            value: true,
          },
          {
            label: "Nej, tidigare inflytt är ej möjlig",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.prior_access}
        onChange={(val) => {
          updateAttr({ attr: "prior_access", val });
        }}
      />

      <NonConnectedRadioGroup
        id={`prioritize_customers_`}
        title="Nuvarande hyresgäster har företräde"
        options={[
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nej",
            value: false,
          },
        ]}
        value={defaultSettingsInstance.prioritize_customers}
        onChange={(val) => {
          updateAttr({
            attr: "prioritize_customers",

            val,
          });
        }}
      />
    </>
  );
};
