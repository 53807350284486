import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import ServiceContractsTable from "src/components/Tables/ServiceContracts/FullTable";

import { buildQueryString } from "../../../store/base";

export default function ServicePartnerContracts() {
  const { servicePartnerId } = useParams();
  const serviceContractQuery = buildQueryString({
    opponent_party: servicePartnerId,
  });

  const serviceContractFilter = (obj) => {
    return obj.opponent_party?.id == servicePartnerId;
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Serviceavtal</OverviewTitle>
        </OverviewTitleWrapper>
        <ServiceContractsTable
          persistantQueryString={serviceContractQuery}
          persistantFilterMethod={serviceContractFilter}
          hideSearch
          hideExport
          hideFilters
          hideTitle
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
