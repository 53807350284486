import styled from "styled-components";

export const Paragraph = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
  margin: 24px 0;
`;

export const List = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
`;
