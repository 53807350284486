import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import { useInvoicesForContractBasedOnDebtor } from "../../../store/billectaSearch";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ContractViewInvoicesTable from "../../Billecta/Invoices/ContractViewInvoicesTable";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { DetailPageBox, InnerBox } from "../../sharedStyles";

export default function ContractInvoices({
  debtorInvoiceConfig,
  billingCompany,
  invoicingKey,
  contractId,
  invoicingRecords,
  invoicingActive,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [invoices, invoicesLoading] = useInvoicesForContractBasedOnDebtor({
    debtorId: debtorInvoiceConfig?.billecta_ids?.find(
      (bid) => bid.creditor_id == billingCompany?.billecta_id
    )?.object_id,
    creditorId: billingCompany?.billecta_id,
    searchId: `${invoicingKey}_${contractId}`,
    errorCallback: () => {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta avier för avtal",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );
    },
  });

  const possibleIds = invoicingRecords
    ?.filter((i) => i.billecta_id)
    ?.map((i) => i.billecta_id.object_id);

  const handleInvoiceRowClicked = ({ original }) => {
    push(
      invoiceDetailUrl({
        creditorId: original.CreditorPublicId,
        invoiceId: original.ActionPublicId,
      })
    );
  };

  return (
    <DetailPageBox style={{ opacity: invoicingActive ? 1 : 0.7 }}>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Genererade avier</OverviewTitle>
          <OverviewSubtitle>
            Skapade avier som är kopplade till detta avtal
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {!invoices?.length ? (
        <InnerBox>
          {invoicesLoading
            ? "Hämtar avier..."
            : "Inga avier har genererats för detta avtal än"}
        </InnerBox>
      ) : (
        <ContractViewInvoicesTable
          invoices={invoices.sort((a, b) =>
            a.InvoiceDate > b.InvoiceDate ? -1 : 1
          )}
          possibleIds={possibleIds}
          filterFromPossibleIds
          handleRowClicked={handleInvoiceRowClicked}
        />
      )}
    </DetailPageBox>
  );
}
