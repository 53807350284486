import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import IMDSensors from "./overview/Sensors";
import MeasureTypes from "./overview/MeasureTypes";
import { usePermissionCheck } from "../../store/base";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { ExportExcelButton } from "../../components/Forms/Base/Buttons";
import IMDObjects from "./overview/IMDObjects";
import IMDContracts from "./overview/IMDContracts";

export default function IMDOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["allow_imd", "view_can_imd"]);

  const canAddImd = usePermissionCheck("add_can_imd");

  const SUB_PAGES = [
    // {
    //   path: "/overview",
    //   label: "Översikt",
    //   component: () => <div>Dashboard</div>,
    // },
    {
      path: "/imd-enabled-objects",
      label: "Objekt med IMD",
      component: IMDObjects,
    },
    {
      path: "/imd-enabled-contracts",
      label: "Debiteras med IMD",
      component: IMDContracts,
    },
    {
      path: "/measure-types",
      label: "Mätartyper",
      component: MeasureTypes,
    },
    {
      path: "/sensors",
      label: "Sensorer",
      component: IMDSensors,
    },
  ];

  const renderActions = () => (
    <div>
      {canAddImd && (
        <ExportExcelButton
          title="Importera mätvärden"
          onClick={() => push("/imd/manual-import")}
        />
      )}
    </div>
  );

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title={`IMD - Individuell mätning och debitering`}
        {...{ subPages: subPageLinks, renderActions }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}
