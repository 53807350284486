export default ({
  disableEdit,
  disableExecute,
  disableDelete,
  disableDelegate,
  onExecute,
  onEdit,
  onDelegate,
  onDelete,
  setErrandPaused,
  setErrandActive,
  status,
}) => {
  const result = [
    {
      name: "Utför",
      onClick: onExecute,
      hidden: disableExecute,
    },
  ];

  if (status === 1) {
    result.push({
      name: "Pausa",
      onClick: setErrandPaused,
      hidden: disableExecute,
    });
  }
  if (status === 2) {
    result.push({
      name: "Återuppta",
      onClick: setErrandActive,
      hidden: disableExecute,
    });
  }
  if (status === 0) {
    result.push({
      name: "Starta",
      onClick: setErrandActive,
      hidden: disableExecute,
    });
  }

  result.push(
    {
      name: "Redigera",
      onClick: onEdit,
      hidden: disableEdit,
    },
    {
      name: "Delegera ärende",
      onClick: onDelegate,
      hidden: disableDelegate,
    },
    {
      name: "Radera",
      onClick: onDelete,
      hidden: disableDelete,
      isDelete: true,
    }
  );

  return result;
};
