import * as React from "react";
import { constants } from "../../../../../store/errandArticles";
import { RadioGroup } from "../../../Base/Fields";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";

export default function Article({ method }) {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Artikel</OverviewTitle>
          <OverviewSubtitle>
            Specificera information kring artikeln
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey={"title"}
          title={"Namn på artikel"}
        />

        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey={"code"}
          title={"Artikelnummer"}
        />

        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey={"description"}
          title="Beskrivning"
        />

        <TextInputField
          storeName={storeName}
          method={method}
          fieldKey={"unit"}
          title={"Enhet"}
        />

        <TextInputField
          isNumber
          title="Standard antal"
          storeName={storeName}
          method={method}
          fieldKey={"standard_unit_amount"}
        />

        <TextInputField
          isNumber
          step={".01"}
          title="Kostnad (SEK) per enhet"
          storeName={storeName}
          method={method}
          fieldKey={"cost_per_unit"}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj produkt..."
          description="Val av produkt är inte obligatoriskt, lämnas den tom skapas en passande produkt automatiskt."
          title="Produkt för bokföring"
          method={method}
          fieldKey={`product`}
          TableComponent={InvoicingProductTable}
        />
      </div>

      <RadioGroup
        title="Artikeln är aktiv"
        defaultValue={true}
        options={[
          {
            label: "Ja, artikeln är tillgänglig för val på utföranden",
            value: true,
          },
          {
            label: "Nej, artikeln är EJ tillgänglig för val på utföranden",
            value: false,
          },
        ]}
        {...{
          storeName,
          method,
          fieldKey: `is_active`,
        }}
      />
    </>
  );
}
