import * as React from "react";
import { useParams } from "react-router-dom";

import LoanForm from "../../../components/Forms/Loan/FlowForm/FlowForm";

export default () => {
  const { brfCompanyId, paymentGroupId } = useParams();

  return <LoanForm method="POST" brfCompanyId={brfCompanyId} paymentGroupId={paymentGroupId}/>;
};
