import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import validationErrorMap from "../../../../Billecta/Invoices/InvoiceComponents/validationErrorMap";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { NumberInput, Select, TextField } from "../../../Base/Fields";
import LocalCheckField from "../../../Base/Fields/LocalCheckField";
import SelectField from "../../../Base/Fields/SelectField";
import TextAreaField from "../../../Base/Fields/TextAreaField";
import TextInputField from "../../../Base/Fields/TextInputField";

export default function Interest({
  title,
  description,
  storeName,
  method,
  validationErrors,
}) {
  const dispatch = useDispatch();
  const useInterest = !!useFormInstanceField({
    storeName,
    fieldKey: "InterestType",
  });

  const interestPercentage = useFormInstanceField({
    storeName,
    fieldKey: "InterestPercentage",
  });

  const handleActivateInterest = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          InterestPercentage: 8,
          InterestStartInDaysAfterDueDate: 8,
          InterestType: "AboveEffectiveReference",
        },
      })
    );
  };
  const handleInactivateInterest = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          InterestPercentage: undefined,
          InterestStartInDaysAfterDueDate: undefined,
          InterestType: undefined,
        },
      })
    );
  };
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>
          {description && <OverviewSubtitle>{description}</OverviewSubtitle>}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {useInterest ? (
        <>
          <div className="mb-6">
            <LocalCheckField
              title="Använd dröjsmålsränta"
              value={true}
              onChange={handleInactivateInterest}
            />
          </div>

          <div className="grid gap-6 mb-6 grid-cols-2">
            <SelectField
              title="Typ av ränta"
              {...{ storeName, method }}
              fieldKey="InterestType"
            />
            <TextInputField
              isNumber
              title="Börjar räknas efter ... dagar"
              fieldKey="InterestStartInDaysAfterDueDate"
              {...{ storeName, method }}
              forceError={
                validationErrors["InterestStartInDaysAfterDueDate"] &&
                validationErrorMap["InterestStartInDaysAfterDueDate"]
              }
            />
          </div>

          <div className="grid grid-cols-2 mb-6 gap-6">
            <TextInputField
              isNumber
              title="Ränta i %"
              fieldKey="InterestPercentage"
              {...{ storeName, method }}
            />

            {interestPercentage > 8 && (
              <TextAreaField
                title="Anledning till hög ränta"
                description="Måste anges om räntan är över 8%"
                {...{ storeName, method }}
                fieldKey={`ReasonForHigherInterest`}
                forceError={
                  validationErrors["ReasonForHigherInterest"] &&
                  validationErrorMap["ReasonForHigherInterest"]
                }
              />
            )}
          </div>
        </>
      ) : (
        <LocalCheckField
          title="Använd dröjsmålsränta"
          value={false}
          onChange={handleActivateInterest}
        />
      )}
    </>
  );
}
