export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_REALESTATETAXRECORD",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_REALESTATETAXRECORD",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_REALESTATETAXRECORD",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_REALESTATETAXRECORD",
  DESTROY_FORM: "DESTROY_FORM_REALESTATETAXRECORD",
  SET_FORM_ERROR: "SET_FORM_ERROR_REALESTATETAXRECORD",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_REALESTATETAXRECORD",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_REALESTATETAXRECORD",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_REALESTATETAXRECORD",
  RESET_STATE: "RESET_STATE_REALESTATETAXRECORD",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_REALESTATETAXRECORD",
  REMOVE_OBJECT: "REMOVE_OBJECT_REALESTATETAXRECORD",
  CLEAR_FETCHED: "CLEAR_FETCHED_REALESTATETAXRECORD",

  LIST_URL: "/standard/toplevel/realestatetaxrecord/list/",
  POST_URL: "/standard/toplevel/realestatetaxrecord/",
  PATCH_URL: "/standard/toplevel/realestatetaxrecord/",
  GET_URL: "/standard/toplevel/realestatetaxrecord/",

  STORE_NAME: "realEstateTaxRecord",
};
