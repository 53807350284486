import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { mergeInvoices } from "../../../store/billectaInvoicing/store/actions";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import LocalDateSelect from "../Base/Fields/LocalDateSelect";
import Modal from "../Base/Modals/Modal";

export default function MergeInvoicesForm({
  closeFunction,
  selectedInvoices,
  debtorCount,
  initialInvoiceDate,
  initialDueDate,
  creditorId,
}) {
  const dispatch = useDispatch();
  const [invoiceDate, setInvoiceDate] = React.useState(null);
  const [invoiceDateError, setInvoiceDateError] = React.useState(null);
  const [dueDate, setDueDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const canAccept = !!invoiceDate && !!dueDate && !invoiceDateError;

  React.useEffect(() => {
    setInvoiceDate(initialInvoiceDate);
    setDueDate(initialDueDate);
  }, [initialInvoiceDate, initialDueDate]);

  React.useEffect(() => {
    if (!invoiceDate || !dueDate) {
      setInvoiceDateError(null);
    } else if (moment(invoiceDate).isSameOrAfter(moment(dueDate))) {
      setInvoiceDateError("Förfallodatumet måste ligga efter fakturadatumet");
    } else {
      setInvoiceDateError(null);
    }
  }, [invoiceDate, dueDate]);

  const handleMerge = () => {
    setLoading(true);

    dispatch(
      mergeInvoices({
        invoices: selectedInvoices?.map((s) => s.original),
        invoiceDate: invoiceDate,
        dueDate: dueDate,
        creditorId,
        successCallback: ({
          couldNotMergeCount,
          couldMergeCount,
          errorResults,
          validResults,
        }) => {
          setLoading(false);

          if (couldMergeCount > 0 && validResults?.length > 0) {
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Fakturorna sammanfördes",
              })
            );
          }

          if (couldNotMergeCount > 0) {
            dispatch(
              addToast({
                type: TOAST_TYPES.INFO,
                title: `${couldNotMergeCount} fakturor sammanfördes inte`,
                description:
                  "För att sammanföras krävs det minst två fakturor till samma motpart",
              })
            );
          }

          if (errorResults?.length > 0) {
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: `Ett eller fler försök till sammanslagning misslyckades`,
                description: "Kontrollera datan och försök igen",
              })
            );
          }

          closeFunction();
        },
        errorCallback: (message) => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  return (
    <Modal
      title={`Sammanför ${selectedInvoices?.length} fakturor till ${
        debtorCount > 1 ? `${debtorCount} olika hyresgäster` : "samma hyresgäst"
      } `}
      closeFunction={closeFunction}
      acceptTitle="Sammanför fakturor"
      canAccept={canAccept}
      onAccept={handleMerge}
    >
      {loading && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Välj datum för samlingsfakturan</OverviewTitle>
          <OverviewSubtitle>
            De sammanslagna fakturorna kommer att raderas efter sammanslagningen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalDateSelect
          id="_mergeInvoiceDate"
          title="Fakturadatum"
          required
          value={invoiceDate}
          onChange={(val) => setInvoiceDate(val)}
          description="Fakturadatum på den sammanslagna fakturan"
          error={invoiceDateError}
        />

        <LocalDateSelect
          id="_mergeDueDate"
          title="Förfallodatum"
          required
          value={dueDate}
          onChange={(val) => setDueDate(val)}
          description="Förfallodatum på den sammanslagna fakturan"
        />
      </div>
    </Modal>
  );
}

//  <StandardModal
//         title="Sammanför fakturor till samma hyresgäst"
//         isOpen={mergeInvoicesOptionsOpen}
//         closeFunction={() => setMergeInvoicesOptionsOpen(false)}
//         withActionBar
//         canAccept={!!mergeInvoicesDueDate && !!mergeInvoicesInvoiceDate}
//         saveFunction={handleMergeInvoices}
//         actionBarAcceptTitle={`Sammanför ${mergeInvoicesCount} fakturor`}
//       >
//         {mergeInvoicesLoading && <OverlaySpinner />}
//         <InfoBox
//           title="Sammanför fakturor till samma hyresgäst"
//           text={`${mergeInvoicesCount} fakturor till ${mergeTenantsCount} ${
//             mergeTenantsCount > 1 ? `olika hyresgäster` : "hyresgäst"
//           } kommer sammmanföras till ${mergeTenantsCount} ${
//             mergeTenantsCount > 1 ? "nya fakturor" : "ny faktura"
//           }. De existerande fakturorna kommer raderas. Välj fakturadatum samt förfallodatum för samlingsfakturorna. Det tidigaste fakturadatumet samt det senaste förfallodatumet har föreslagits automatiskt.`}
//         />

//         <NonConnectedDatePicker
//           id="merge_from_date"
//           value={mergeInvoicesInvoiceDate}
//           onChange={(val) => setMergeInvoicesInvoiceDate(val)}
//           label="Fakturadatum på samlingsfaktura"
//         />

//         <NonConnectedDatePicker
//           id="merge_to_date"
//           value={mergeInvoicesDueDate}
//           onChange={(val) => setMergeInvoicesDueDate(val)}
//           label="Förfallodatum på samlingsfaktura"
//         />
//       </StandardModal>
