export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_LEASEINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_LEASEINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_LEASEINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_LEASEINVOICING",
  DESTROY_FORM: "DESTROY_FORM_LEASEINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_LEASEINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_LEASEINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_LEASEINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_LEASEINVOICING",
  RESET_STATE: "RESET_STATE_LEASEINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_LEASEINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_LEASEINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_LEASEINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_LEASEINVOICING",
  SET_PERIODS_FOR_CONTRACT: "SET_PERIODS_FOR_CONTRACT_LEASEINVOICING",
  CLEAR_PERIODS_FOR_CONTRACT: "CLEAR_PERIODS_FOR_CONTRACT_LEASEINVOICING",

  LIST_URL: "/accounting/contract/leaseinvoicing/list/",
  POST_URL: "/accounting/contract/leaseinvoicing/",
  PATCH_URL: "/accounting/contract/leaseinvoicing/",
  GET_URL: "/accounting/contract/leaseinvoicing/",
  MASS_INVOICE_STATUS_UPDATE_URL:
    "/accounting/contract/masspause_leaseinvoicing/",
  PREVIEW_URL: "/accounting/contract/preview_leaseinvoicing/",
  GENERATE_URL: "/accounting/contract/generatenow_leaseinvoicing/",
  PERIOD_URL: "/accounting/contract/periodinfo_leaseinvoicing/",

  STORE_NAME: "invoicingLease",
};
