import * as React from "react";

// style, design
import { LinkedObject, DateCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import { detailUrl as leaseDetailUrl } from "../../../store/leaseContracts";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: "is_active",
      Cell: ({ value }) => {
        const label = value ? "Aktiv" : "Ej aktiv";
        const state = value ? 1 : 3;

        return <StatusLabel state={state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Hyresavtal",
      accessor: "str_representation",
      Cell: ({ row, value }) => (
        <LinkedObject
          obj={{
            str_representation: value,
            id: row.original.lease_contract?.id,
          }}
          urlMethod={leaseDetailUrl}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Startdatum för avisering",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
  ];

  return cols;
};
