import * as React from "react";

export default () => {
  return (
    <div>
      Kontrollera att uppgifterna stämmer. Tryck sedan på "Spara och gå vidare
      till debiteringsrader" för att spara och gå vidare.
      <br />
      <br />I nästa steg kommer kostnader för avtalet att sättas upp.
    </div>
  );
};
