import {
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  ChevronLeftIcon,
  CloudArrowUpIcon,
  FolderArrowDownIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import {
  getEditorUrl,
  unlockFieldEditing,
} from "../../store/editabledocs/utils";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import { exportEditableDoc } from "./utils";
import PrimaryBtn from "../Forms/Base/Buttons/PrimaryBtn";
import ConfirmModal from "../Forms/Base/Modals/ConfirmModal";
import DigitalDocMenu from "./Menu";

export default function DigitalDocHeader({
  storeName,
  pageBounds,
  method,
  clearDocument,
  isTemplateEditor,
  onSubmit,
  isFastDoc,
  usesESigning,
  setConfirmCancelOpen,
  clearPageBoundsData,
}) {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [confirmEditInFastDocOpen, setConfirmEditInFastDocOpen] =
    React.useState(false);
  const [confirmUnlockFastDocOpen, setConfirmUnlockFastDocOpen] =
    React.useState(false);
  const [hasBeenEditedInFastDoc, setHasBeenEditedInFastDoc] =
    React.useState(false);
  const editableDoc = useFormInstanceField({
    storeName,
    fieldKey: "",
  });
  const fastDocLocked = useFormInstanceField({
    storeName,
    fieldKey: "lock_auto_fastdoc_fields",
  });

  const hasDoc = editableDoc?.doc || editableDoc?.fastdoc_id;
  const fastDocId = editableDoc?.fastdoc_id;
  const digitalDocId = editableDoc?.id;

  const handleEditInFastdoc = async () => {
    try {
      const result = await getEditorUrl(digitalDocId, fastDocId);
      window.open(result.data.data, "_blank");

      setHasBeenEditedInFastDoc(true);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { lock_auto_fastdoc_fields: true },
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: "Kunde inte öppna editorn",
          type: TOAST_TYPES.ERROR,
        })
      );
    }
  };

  const promptFastdocDataReload = () => {
    const _fastdocId = fastDocId;

    // this will prompt reload of the pdf
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { fastdoc_id: null },
      })
    );

    clearPageBoundsData();

    setTimeout(() => {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { fastdoc_id: _fastdocId },
        })
      );
    }, 0);
  };

  const handleUnlockFastDoc = async () => {
    try {
      await unlockFieldEditing(editableDoc.id);
      promptFastdocDataReload();

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { lock_auto_fastdoc_fields: false },
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: "Kunde inte låsa upp fält",
          type: TOAST_TYPES.ERROR,
        })
      );
    }
  };

  const onDownload = async () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Hämtar förhandsgranskning",
        description: "Detta kan ta några sekunder...",
      })
    );

    await exportEditableDoc({ editableDoc, forShow: true, download: true });
  };

  return (
    <>
      {confirmUnlockFastDocOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmUnlockFastDocOpen(false)}
          onAccept={handleUnlockFastDoc}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Återställ och lås upp
            <br />
          </h3>

          <p>
            Ändringar som har gjorts i Fastighetsägarna dokuments portal kommer
            att återställas. Ifyllnad av fält kommer återigen hanteras av
            Pigellos autofyllnad utifrån datan på avtalet.
          </p>
        </ConfirmModal>
      )}

      {confirmEditInFastDocOpen && (
        <ConfirmModal
          isInfo
          closeFunction={() => setConfirmEditInFastDocOpen(false)}
          onAccept={handleEditInFastdoc}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Redigera i Fastighetsägarna Dokument
            <br />
          </h3>

          <p>
            <strong>Dokumentets inbyggda fält kommer att låsas</strong> för
            ändringar från Pigello för att inte skriva över de ändringar som
            görs i Fastighetsägarna.
            <br /> <br />
            <strong>Observera att dokumentet måste</strong> hämtas om efter
            redigering i Fastighetsägarna dokument för att reflektera ändringar
            som gjorts.
            <br /> <br />
            <strong>För att återigen låta Pigello styra</strong> ifyllnad av
            inbyggda fält så måste eventuella ändringar i Fastighetsägarna
            återställas. Detta görs genom att trycka på <br />
            <strong>"Återställ och lås upp Pigello autofyllnad"</strong>.
          </p>
        </ConfirmModal>
      )}
      {hasBeenEditedInFastDoc && (
        <ConfirmModal
          isInfo
          closeFunction={() => setHasBeenEditedInFastDoc(false)}
          onAccept={promptFastdocDataReload}
          acceptTitle="Hämta om dokument"
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Hämta om dokument
            <br />
          </h3>

          <p>
            Dokumentet har redigerats i Fastighetsägarna Dokument och behöver
            därför hämtas om. Avbryt om inga ändringar har gjorts.
          </p>
        </ConfirmModal>
      )}

      <div className="bg-white sticky top-0 border-b border-solid border-gray-300 w-full flex justify-center z-30">
        <div className="p-2 w-full max-w-[1200px] flex justify-between">
          <div className="flex space-x-2 items-center">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              onClick={() => setConfirmCancelOpen(true)}
            >
              <ChevronLeftIcon width={24} />
            </button>
            <PrimaryBtn
              onClick={() => setMenuOpen(true)}
              disabled={!hasDoc || menuOpen}
            >
              Hantera dokument <Bars3Icon width={16} className="ml-1" />{" "}
            </PrimaryBtn>
            {isFastDoc && !isTemplateEditor && (
              <PrimaryBtn
                onClick={() => setConfirmEditInFastDocOpen(true)}
                secondary
                disabled={!hasDoc}
              >
                Redigera i Fastighetsägarna Dokument{" "}
                <ArrowTopRightOnSquareIcon width={16} className="ml-1" />{" "}
              </PrimaryBtn>
            )}
          </div>

          {hasDoc && (
            <div className="flex space-x-2 items-center">
              <PrimaryBtn onClick={onDownload} secondary>
                Ladda ned <FolderArrowDownIcon width={16} className="ml-1" />
              </PrimaryBtn>
              <PrimaryBtn onClick={onSubmit} highlight>
                {isTemplateEditor ? "Spara mall" : "Spara dokument"}{" "}
                <CloudArrowUpIcon width={16} className="ml-1" />{" "}
              </PrimaryBtn>
            </div>
          )}
        </div>

        <DigitalDocMenu
          open={menuOpen}
          storeName={storeName}
          closeFunction={() => setMenuOpen(false)}
          pageBounds={pageBounds}
          method={method}
          clearDocument={clearDocument}
          isTemplateEditor={isTemplateEditor}
          isFastDoc={isFastDoc}
          usesESigning={usesESigning}
        />
      </div>

      {fastDocLocked && (
        <div className="bg-white sticky top-[55px] border-b border-solid border-gray-300 w-full flex justify-center z-20">
          <div className="p-2 w-full max-w-[1200px] flex justify-between">
            <div className="flex flex-col">
              <div>
                Detta dokument är låst för ändringar av Pigello autofyllnad
              </div>
              <div className="text-xs">
                För att låsa upp dokumentet för autofyllnad igen krävs det att
                ändringar som gjorts i Fastighetsägarna dokument återställs.
              </div>
            </div>
            <div className="flex space-x-2 items-center">
              <PrimaryBtn
                error
                onClick={() => setConfirmUnlockFastDocOpen(true)}
              >
                Återställ och lås upp Pigello autofyllnad
              </PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
