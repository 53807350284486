import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  clearFetched,
} from "../../base";
import { axiosInstance } from "../../base/store/axios";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const createManyStages = ({
  stages,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(constants.LIST_URL, stages);

      dispatch(clearFetched(constants, true));

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const upLeadPaginationOffset = ({ pipeId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.UP_LEAD_PAGINATION_OFFSET,
      payload: {
        pipeId,
      },
    });
  };
};

export const setFilters = ({ filterObj, pipeId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_FILTERS,
      payload: {
        filters: filterObj,
        pipeId,
      },
    });
  };
};

export const performSearch = ({ searchTerm, pipeId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_SEARCH_TERM,
      payload: {
        searchTerm: searchTerm,
        pipeId,
      },
    });
  };
};
