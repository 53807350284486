import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];
    const b64Prefix = "data:application/pdf;base64,";

    // docs
    const documents = formInstance?.documents || [];
    const responsedocuments = responseData?.documents || [];

    if (documents.length === responsedocuments.length) {
      responsedocuments.forEach((a, index) => {
        const doc = documents[index];
        let file = doc?._tempData?.data;
        if (file && !file.includes(b64Prefix)) {
          file = b64Prefix + file;
        }

        const url = a?.file?.put;

        if (file && url) {
          urls.push(url);
          files.push(file);
        }
      });
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
