import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";

export default function StepOptions({ options, title, onSelected, goBack }) {
  return (
    <div className="flex flex-col">
      {goBack && (
        <div
          onClick={goBack}
          className=" cursor-pointer text-sm group font-medium text-primaryblue flex items-center"
        >
          <ArrowLeftIcon
            className="transition-all ease-in-out duration-200 group-hover:-translate-x-1"
            width={14}
          />{" "}
          Tillbaka{" "}
        </div>
      )}
      <div className="text-lg text-slate-600 font-medium mb-2">{title}</div>

      {options.map((option) => (
        <div
          onClick={() => onSelected(option.id)}
          key={option.id}
          className="flex transition-all ease-in-out duration-200 group border cursor-pointer border-slate-300  mb-2 rounded shadow-inner overflow-hidden hover:shadow"
        >
          <div className="flex flex-1 flex-col p-4">
            <div className="text-base text-slate-700 mb-2">{option.title}</div>
            <div className="text-sm text-slate-500 ">
              {option.renderDescription()}
            </div>
          </div>

          <div className="flex-shrink transition-all ease-in-out duration-200 group-hover:bg-primaryblue flex justify-center items-center p-2 border-l border-slate-300">
            <ChevronRightIcon
              className="text-primaryblue group-hover:text-slate-50"
              width={24}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
