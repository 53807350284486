import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import useActiveFilters from "../../../hooks/useActiveFilters";
import IndpTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";

export const PERSISTANT_CATEGORY_KEY = "persistant_category_indp";

export default function IndustrialPremises() {
  const { filteredRealEstates } = useActiveFilters();

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Översikt</OverviewTitle>
        </OverviewTitleWrapper>
        <IndpTable
          persistantQuery={persistantQuery}
          withCosts
          onlyWithCosts={true}
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
