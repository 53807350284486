import React from "react";
import {
  buildQueryString,
  useAllPermissionCheck,
} from "../../../../store/base";
import { useWidgetPlacements } from "../../../../store/widgetPlacements";
import { constants } from "../../../../store/insightsGoal";

import InsightsGoalsTable from "../../../Lists/InsightsGoals/FullTable";
import { PrimaryButton } from "../../Base/Buttons";
import InsightsGoalForm from "./InsightsGoalForm";

import { WidgetPlacementDefaults } from "../../../../views/Insights/WidgetPlacementDefaults";
import Modal from "../../Base/Modals/Modal";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "src/store/toasts";
import { axiosInstance } from "src/store/base/store/axios";

export default function InsightsGoalOverview({
  insightsPage,
  open,
  checkout,
  onSubmit,
  defaultPage,
}) {
  const [insightsGoalFormOpen, setInsightsGoalFormOpen] = React.useState(false);
  const [goalFormMethod, setGoalFormMethod] = React.useState("POST");
  const [goal, setGoal] = React.useState(null);
  const dispatch = useDispatch();

  const [kpiChoices, setKpiChoices] = React.useState([]);

  const pageId = insightsPage?.id
  let widgetPlacementFilter = buildQueryString({
    page: pageId,
  });
  if (defaultPage || !pageId) {
    widgetPlacementFilter = null;
  }

  const [_widgetPlacements, widgetPlacementsLoading] = useWidgetPlacements(
    widgetPlacementFilter
  );

  let widgetPlacements = null;
  if (defaultPage) {
    widgetPlacements = WidgetPlacementDefaults;
  } else {
    widgetPlacements = _widgetPlacements;
  }

  const pageKpis = [];
  widgetPlacements.forEach((placement) => {
    pageKpis.push(placement.kpi);
  });

  const filterRealEstateIds = insightsPage?.query_parameters?.realestate_ids || []
  const goalOnPageQuery = {
    kpi__in: pageKpis,
    exact_realestate_ids: filterRealEstateIds
  };

  const hasCreatePermission = useAllPermissionCheck([
    "allow_insights",
    "add_can_insights",
  ]);

  const hasChangePermission = useAllPermissionCheck([
    "allow_insights",
    "change_can_insights",
  ]);

  const onTableRowClicked = (original) => {
    if (!hasChangePermission) return;
    setGoal(original);
    setGoalFormMethod("PATCH");
    setInsightsGoalFormOpen(true);
  };

  const onCreateButtonClicked = () => {
    setGoal(null);
    setGoalFormMethod("POST");
    setInsightsGoalFormOpen(true);
  };
  
  React.useEffect(() => {
    getKpiChoices()
  }, [])

  const getKpiChoices = (timesTried = 0) => {
    if (timesTried === 5) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde inte hämta Nyckeltal",
          description: "Ladda om sidan och försök igen"
        })
      )
      return
    }
    axiosInstance.get("/insights/permcheckedkpioptions/?for_goals=true").then((response) => {
      setKpiChoices(response.data)
    }
      
      ).catch((err) => {
        getKpiChoices(timesTried +1)
      })
  }


  return (
    <>
    {open && (
    
    <Modal
      closeFunction={checkout}
      onAccept={onSubmit}
      title={"Mål - Insights"}

    >
      <InsightsGoalsTable
        persistantQuery={goalOnPageQuery}
        onTableRowClicked={onTableRowClicked}
      />

      {hasCreatePermission && (
        <PrimaryButton
          extraStyle={{ marginTop: "12px" }}
          title="Ställ in mål"
          clicked={() => onCreateButtonClicked()}
        />
      )}

      {insightsGoalFormOpen && (
        <InsightsGoalForm
          open={insightsGoalFormOpen}
          method={goalFormMethod}
          insightsGoal={goal}
          onCheckout={() => setInsightsGoalFormOpen(false)}
          handleGoalPatched={() => { 

    window.dispatchEvent(
      new CustomEvent("TABLE_SOCKET_CHANGES", {
        bubbles: true,
        detail: { storeName: constants.STORE_NAME, clearItems: true },
      })
    );
          }
          }
          kpiChoices={kpiChoices}
        />
      )}
    </Modal>
   
    )}
    </>
  );
}
