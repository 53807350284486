import * as React from "react";
import { detailUrl as debtSettingDetailUrl } from "../../../store/invoicingDebtSettings";
import { detailUrl as IMDSettingDetailUrl } from "../../../store/invoicingIMDSettings";
import { LinkedObject } from "../../Displays";
import { getIntervalStr } from "../../Displays/InvoicingHelpers";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";

export default () => {
  const cols = [
    {
      Header: "Titel",
      accessor: "str_representation",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Intervall",
      accessor: "interval_setting",
      Cell: ({ value }) => {
        return <div>{getIntervalStr(value)}</div>;
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Period",
      accessor: "interval_setting.invoice_period_display",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Automatisk attestering",
      accessor: "auto_attest",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 0 : 3}>
            {value ? "Aktiv" : "Inaktiv"}
          </StatusLabel>
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Sammanför fakturor",
      accessor: "auto_merge",
      Cell: ({ value }) => {
        return (
          <StatusLabel state={value ? 0 : 3}>
            {value ? "Aktiv" : "Inaktiv"}
          </StatusLabel>
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },

    {
      Header: "Inställning för betalningsvillkor",
      accessor: "debt_setting",
      Cell: ({ row }) => {
        const obj = row.original.debt_setting;

        return <LinkedObject obj={obj} urlMethod={debtSettingDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Inställning för IMD-debitering",
      accessor: "imd_setting",
      Cell: ({ row }) => {
        const obj = row.original.imd_setting;

        return <LinkedObject obj={obj} urlMethod={IMDSettingDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];

  return cols;
};
