import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useLeaseContractForm,
  constants,
  update,
  destroyPostForm,
  destroyPatchForm,
  useLeaseContract,
} from "../../../../store/leaseContracts";
import useQuery from "../../../utils/useQuery";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import moment from "moment";
import { getProposedEndDate } from "../../Base/utils";
import { updateActiveFormInstance } from "../../../../store/base";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  handleContractCancelledDocUrl,
} from "../../../../store/editabledocs";

export default function CancelLeaseContractForm({ method = "PATCH" }) {
  const dispatch = useDispatch();
  const { leaseContractId } = useParams();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useLeaseContractForm(method, leaseContractId));
  const [leaseContract] = useLeaseContract(leaseContractId);
  const [hasSetInitialData, setHasSetInitialData] = React.useState(false);
  const { replace } = useHistory();

  const query = useQuery();
  const isManualSign = query.get("manual_sign") === "true";

  // Set initial data
  React.useEffect(() => {
    if (!leaseContract || hasSetInitialData) return;

    const initialData = {
      notify_interval: leaseContract.notify_interval, // needed to calculate end date
    };
    initialData.closed_on = moment().format("YYYY-MM-DD");

    initialData.status = [6, 7].includes(leaseContract?.state)
      ? leaseContract.state
      : 6;

    // the one not relevant will be deleted in the preprocess
    initialData.closed_date = getProposedEndDate(initialData);
    initialData.closed_renew_date = getProposedEndDate(initialData);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: initialData,
      })
    );

    setHasSetInitialData(true);
  }, [leaseContract]);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    replace(
      handleContractCancelledDocUrl({
        contractType: EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT,
        id: leaseContractId,
      })
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id: leaseContractId,
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data, isManualSign }),
      })
    );
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,
          descriptions,
          method,
          onSubmit,
          isManualSign,
          leaseContract,
          leaseContractId,
          title: "Säg upp hyresavtal",
        }}
      />
    </>
  );
}

const preProcess = ({ data, isManualSign }) => {
  const copy = cloneDeep(data);

  copy.uses_cancellation_e_signing = !isManualSign;
  copy.closed_confirmed = true;

  if (copy.status === 6) {
    delete copy.closed_renew_date;
  } else if (copy.status === 7) {
    delete copy.closed_date;
  }

  // always create an cancelled doc
  if (!copy.cancelled_doc?.title) {
    set(copy, "cancelled_doc.title", "canceldoc");
  }

  return copy;
};
