import * as React from "react";
import { TextButton } from "../../Forms/Base/Buttons";
import MenuButton from "../../Forms/Base/Buttons/MenuButton";
import { Droppable } from "react-beautiful-dnd";
import * as SC from "./styles";
import Lead from "./Lead";
import ConfirmLeadModal from "./ConfirmLeadModal";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useDispatch } from "react-redux";
import { acceptLeads, rejectLeads } from "../../../store/leads";
import RejectLeadModal from "./RejectLeadModal";
import {
  setLeadsForContract,
  prepareForContractUrl,
  costProposalUrl,
  pickTemplateUrl,
} from "../../../store/pipes";
import { useHistory } from "react-router-dom";
import StandardModal from "../../Modals/StandardModal";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";

export default function Stage({
  onEdit,
  onDelete,
  onCreate,
  onCreateLead,
  isIncomingStage,
  isSignStage,
  isCreateStage,
  stage,
  color,
  leads,
  onLeadDetailOpened,
  updateLeadsInstance,
  isHomeQPipe,
  pipeAttributeChoices
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [confirmLeadsOpen, setConfirmLeadsOpen] = React.useState(false);
  const [rejectLeadsOpen, setRejectLeadsOpen] = React.useState(false);
  const [confirmLeadsLoading, setConfirmLeadsLoading] = React.useState(false);
  const [chooseCostsTypeOpen, setChooseCostsTypeOpen] = React.useState(false);
  const [chooseDocsTypeOpen, setChooseDocsTypeOpen] = React.useState(false);

  const leadContainerRef = React.useRef();

  const allLeadsConfirmed = leads?.every((l) => l?.confirmed === true);

  const handlePrepareLeadsForContract = () => {
    dispatch(setLeadsForContract({ leads }));

    push(prepareForContractUrl());
  };

  const handleAcceptLeads = ({
    acceptType,
    homeQRejectionReason,
    sbfRejectionReason,
    prioOrder,
    acceptedLeads,
  }) => {
    setConfirmLeadsLoading(true);
    dispatch(
      acceptLeads({
        ids: acceptedLeads?.map((l) => l.id) || leads.map((l) => l.id),
        acceptType,
        homeQRejectionReason,
        sbfRejectionReason,
        prioOrder,
        successCallback: () => {
          setConfirmLeadsLoading(false);
          setConfirmLeadsOpen(false);
          updateLeadsInstance();

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Leads bekräftades",
              description: "Avtal kan nu sättas upp för leads i detta steg",
            })
          );
        },
        errorCallback: (message) => {
          setConfirmLeadsLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const handleRejectLeads = ({ homeQRejectionReason, sbfRejectionReason }) => {
    setConfirmLeadsLoading(true);

    dispatch(
      rejectLeads({
        ids: leads.map((l) => l.id),
        homeQRejectionReason,
        sbfRejectionReason,
        successCallback: () => {
          setConfirmLeadsLoading(false);
          setRejectLeadsOpen(false);
          updateLeadsInstance();

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Leadsen i detta projekt nekades",
            })
          );
        },
        errorCallback: (message) => {
          setConfirmLeadsLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  return (
    <>
      {isSignStage && (
        <StandardModal
          small
          isOpen={chooseCostsTypeOpen}
          closeFunction={() => setChooseCostsTypeOpen(false)}
          title="Välj typ av avtal"
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj typ av avtal att sätta upp kostnader för
              </OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <div>
            <TextButton
              title="Hyresavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => push(costProposalUrl({ type: "leasecontracts" }))}
            />
          </div>
          <div style={{ marginTop: 8 }}>
            <TextButton
              title="Parkeringsavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() =>
                push(costProposalUrl({ type: "parkingcontracts" }))
              }
            />
          </div>
        </StandardModal>
      )}

      {isSignStage && (
        <StandardModal
          small
          isOpen={chooseDocsTypeOpen}
          closeFunction={() => setChooseDocsTypeOpen(false)}
          title="Välj typ av avtal"
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>
                Välj typ av avtal att sätta upp signeringsdokument för
              </OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <div>
            <TextButton
              title="Hyresavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => push(pickTemplateUrl({ type: "leasecontracts" }))}
            />
          </div>
          <div style={{ marginTop: 8 }}>
            <TextButton
              title="Parkeringsavtal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() =>
                push(pickTemplateUrl({ type: "parkingcontracts" }))
              }
            />
          </div>
        </StandardModal>
      )}

      {isSignStage && (
        <ConfirmLeadModal
          isOpen={confirmLeadsOpen}
          closeFunction={() => setConfirmLeadsOpen(false)}
          callback={handleAcceptLeads}
          loading={confirmLeadsLoading}
          leads={leads}
          isHomeQPipe={isHomeQPipe}
          openLeadCallback={(l) => {
            onLeadDetailOpened(l.id);
          }}
        />
      )}

      {isIncomingStage && (
        <RejectLeadModal
          isOpen={rejectLeadsOpen}
          closeFunction={() => setRejectLeadsOpen(false)}
          callback={handleRejectLeads}
          loading={confirmLeadsLoading}
          leads={leads?.filter((l) => !l.denied)}
          isHomeQPipe={isHomeQPipe}
          openLeadCallback={(l) => {
            onLeadDetailOpened(l.id);
          }}
        />
      )}

      <SC.Stage>
        <SC.StageHeader color={color}>
          {!isCreateStage ? (
            <>
              <SC.StageTitle>
                <div>
                  {stage.name} ({leads?.length || 0}){" "}
                </div>
                <MenuButton
                  leftAligned
                  actions={[
                    {
                      name: "Bekräfta leads",
                      onClick: () => setConfirmLeadsOpen(true),
                      hidden: !(isSignStage && !allLeadsConfirmed),
                    },
                    {
                      name: "Sätt upp avtal",
                      onClick: handlePrepareLeadsForContract,
                      hidden: !isSignStage || !allLeadsConfirmed,
                    },
                    {
                      name: "Gå direkt till kostnadsförslag",
                      onClick: () => setChooseCostsTypeOpen(true),
                      hidden: !isSignStage,
                    },
                    {
                      name: "Gå direkt till förslag på signerings-dokument",
                      onClick: () => setChooseDocsTypeOpen(true),

                      hidden: !isSignStage,
                    },
                    { name: "Redigera", onClick: onEdit },
                    { name: "Ta bort", onClick: onDelete },
                  ]}
                />
              </SC.StageTitle>

              {isIncomingStage && (
                <>
                  <hr style={{ margin: "8px 0" }} />
                  <TextButton
                    title="Lägg till lead"
                    iconType="add"
                    iconPlacement="right"
                    clicked={onCreateLead}
                  />
                  {leads?.filter((l) => !l.denied)?.length > 0 && (
                    <>
                      <hr style={{ margin: "8px 0" }} />

                      <TextButton
                        red
                        title="Neka leads"
                        iconType="close"
                        iconPlacement="right"
                        clicked={() => setRejectLeadsOpen(true)}
                      />
                    </>
                  )}
                </>
              )}

              {isSignStage && allLeadsConfirmed && (
                <>
                  <hr style={{ margin: "8px 0" }} />
                  <TextButton
                    title="Sätt upp avtal"
                    iconType="add"
                    iconPlacement="right"
                    clicked={handlePrepareLeadsForContract}
                  />
                </>
              )}
              {isSignStage && !allLeadsConfirmed && (
                <>
                  <hr style={{ margin: "8px 0" }} />
                  <TextButton
                    title="Bekräfta leads"
                    iconType="done"
                    iconPlacement="right"
                    clicked={() => setConfirmLeadsOpen(true)}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <TextButton
                title="Lägg till nytt steg"
                iconType="add"
                iconPlacement="right"
                clicked={onCreate}
              />
            </>
          )}
        </SC.StageHeader>

        <SC.LeadContainer {...{ isCreateStage }} ref={leadContainerRef}>
          <Droppable droppableId={`${stage.id}`}>
            {(provided) => (
              <div
                style={{ padding: 4 }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {(leads || []).map((lead, index) => (
                  <Lead
                    key={lead?.id}
                    lead={lead}
                    togetherWith={leads?.find(
                      (l) => l.id == lead?.together_with?.id
                    )}
                    index={index}
                    onClick={() => onLeadDetailOpened(lead?.id)}
                    onTogetherWithClick={() => {
                      const togetherW = leads?.find(
                        (l) => l.id == lead?.together_with?.id
                      );

                      onLeadDetailOpened(togetherW?.id);
                    }}
                    pipeAttributeChoices={pipeAttributeChoices}
                  />
                ))}
                {provided.placeholder}

                <div
                  style={{
                    minHeight: 200,
                    width: "100%",
                  }}
                ></div>
              </div>
            )}
          </Droppable>
        </SC.LeadContainer>
      </SC.Stage>
    </>
  );
}
