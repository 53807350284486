import * as React from "react";
import { useHistory, useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitleWrapper,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewSubtitle,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useRealEstate } from "../../../store/realEstates";

import { DateCell, LinkedObject } from "../../../components/Displays";
import { detailUrl as userDetailUrl } from "../../../store/users";
import { detailUrl as companyDetailUrl } from "../../../store/companies";
import {
  toMoneyString,
  toSqmString,
} from "../../../components/utils/stringUtils";
import { useSummarizedStatistics } from "../../../store/overview";
import {
  buildQueryString,
  updateActiveFormInstance,
  useAllPermissionCheck,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../../store/base";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useFilteredNotes } from "../../../store/notes";
import AccountingModalRealEstate from "../../../components/Forms/RealEstate/AccountingSettings/ModalForm";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import { useFilteredRealEstateTaxRecords } from "../../../store/realEstateTaxRecord";

import { constants as realEstateTaxRecordConstants } from "../../../store/realEstateTaxRecord";
import { useDispatch } from "react-redux";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

import RealEstateTaxRecordForm from "../../../components/Forms/RealEstate/AccountingSettings/RealEstateTaxRecordForm";
import { uniqBy } from "lodash";

export default function RealEstateOverview() {
  const dispatch = useDispatch();
  const { realEstateId } = useParams();
  const { push } = useHistory();
  const [realEstate] = useRealEstate(realEstateId);

  const [realEstateTaxRecordFormOpen, setRealEstateTaxRecordFormOpen] =
    React.useState(false);
  const [realEstateTaxRecordEditFormOpen, setRealEstateTaxRecordEditFormOpen] =
    React.useState(false);
  const [deleteRealEstateTaxRecordOpen, setDeleteRealEstateTaxRecordOpen] =
    React.useState(false);

  const realEstateTaxRecordsQuery = buildQueryString({
    realestate: realEstateId,
  });

  const [realestateTaxRecords] = useFilteredRealEstateTaxRecords(
    realEstateTaxRecordsQuery
  );

  const [stats, isLoadingStats] = useSummarizedStatistics() || {};
  const [accountingSettingsOpen, setAccountingSettingsOpen] =
    React.useState(false);

  const { hasBillectaViewPermission } = useFrequentPermissions();
  const avyTmplActive = usePermissionCheck("allow_avytmpl");

  const canRentIncrease = useAllPermissionCheck([
    "change_can_baseobject",
    "change_can_contract",
  ]);

  const notesQ = buildQueryString({
    id__in: realEstate?.notes?.map((n) => n.id) || [],
  });

  const [notes] = useFilteredNotes(notesQ);

  // filter stats
  const filteredStats = stats?.data?.find((s) => s.id == realEstateId);

  const handleAddTaxRecord = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: realEstateTaxRecordConstants.STORE_NAME,
        data: {
          realestate: {
            id: realEstateId,
          },
        },
      })
    );

    setRealEstateTaxRecordFormOpen(true);
  };

  const handleRealEstateTaxRecordClicked = (id) => {
    const taxRecord = realestateTaxRecords.find((tr) => tr.id === id);

    dispatch(
      updateActiveFormInstance({
        storeName: realEstateTaxRecordConstants.STORE_NAME,
        data: taxRecord,
      })
    );

    setRealEstateTaxRecordEditFormOpen(id);
  };

  return (
    <>
      <AccountingModalRealEstate
        id={realEstateId}
        instance={realEstate}
        isOpen={accountingSettingsOpen}
        onCheckout={() => setAccountingSettingsOpen(false)}
      />

      <DeleteModal
        isOpen={!!deleteRealEstateTaxRecordOpen}
        instance={deleteRealEstateTaxRecordOpen}
        constants={realEstateTaxRecordConstants}
        closeFunction={() => setDeleteRealEstateTaxRecordOpen(false)}
      />

      <RealEstateTaxRecordForm
        isOpen={realEstateTaxRecordFormOpen}
        onCheckout={() => setRealEstateTaxRecordFormOpen(false)}
        method={"POST"}
      />

      <RealEstateTaxRecordForm
        isOpen={realEstateTaxRecordEditFormOpen}
        onCheckout={() => setRealEstateTaxRecordEditFormOpen(false)}
        method={"PATCH"}
        id={realEstateTaxRecordEditFormOpen}
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "65%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>
            </OverviewTitleWrapper>

            <DetailInfo
              infoObj={getInfoObj({
                realEstate,
                avyTmplActive,
                filteredStats,
                setAccountingSettingsOpen,
                hasBillectaViewPermission,
              })}
            />
          </DetailPageBox>

          <div style={{ width: "34%" }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hyreshöjning</OverviewTitle>
                  <OverviewSubtitle>
                    Genomför hyreshöjning för objekt i denna fastighet
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {canRentIncrease && (
                <>
                  <div>
                    <TextButton
                      title="Hyreshöjning för lägenheter"
                      iconType="arrow"
                      iconPlacement="right"
                      clicked={() =>
                        push(
                          `/apartment-rent-increase?realestate=${realEstate?.id}`
                        )
                      }
                    />
                  </div>

                  <div style={{ marginTop: 12 }}>
                    <TextButton
                      title="Hyreshöjning för lokaler"
                      iconType="arrow"
                      iconPlacement="right"
                      clicked={() =>
                        push(
                          `/industrial-premises-rent-increase?realestate=${realEstate?.id}`
                        )
                      }
                    />
                  </div>
                </>
              )}
            </DetailPageBox>

            <DetailPageBox>
              <Notes
                notes={notes}
                contentType="standard.realestate"
                objectId={realEstateId}
              />
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Fastighetstaxeringar</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <PremisesTable
                onRowClicked={handleRealEstateTaxRecordClicked}
                headers={[
                  "Skapad",
                  "Namn",
                  "Taxeringsvärde",
                  "Avseende år",
                  "Ta bort",
                ]}
                rows={realestateTaxRecords?.map((tr) => {
                  return [
                    tr.created_at,
                    tr.str_representation,
                    sumTaxValue(tr),
                    tr.for_year,
                    <TextButton
                      key={tr.id}
                      red
                      title="Ta bort"
                      clicked={() => setDeleteRealEstateTaxRecordOpen(tr)}
                      iconType="close"
                      iconPlacement="right"
                    />,
                  ];
                })}
                onClickRowMapping={realestateTaxRecords?.map((pf) => pf.id)}
              />

              <PrimaryButton title="Lägg till" clicked={handleAddTaxRecord} />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

const sumTaxValue = (tr) =>
  toMoneyString(
    (tr?.build_housing_value || 0) +
      (tr?.ground_housing_value || 0) +
      (tr?.build_industrial_value || 0) +
      (tr?.ground_premises_value || 0) +
      (tr?.build_premises_value || 0) +
      (tr?.gound_premises_value || 0),
    true
  );

const getAddressPreview = (realEstate) => {
  let preview = undefined;
  let main = realEstate?.main_adress;
  let bi = realEstate?.addresses;
  if (!main) {
    main = bi?.[0];
  }

  const uniqBi = uniqBy(realEstate?.addresses || [], "base");

  if (main) {
    const extraLength = uniqBi?.length;
    preview =
      extraLength > 1
        ? `${main?.base?.split(",")?.[0]} (+${extraLength - 1})`
        : main?.base?.split(",")?.[0];
  }

  return preview;
};

function getInfoObj({
  realEstate,
  avyTmplActive,
  filteredStats,
  setAccountingSettingsOpen,
  hasBillectaViewPermission,
}) {
  const infoObj = {
    ["Fastighetsinformation"]: [
      {
        title: "Huvuadress",
        value:
          realEstate?.main_address?.str_representation?.split(",")?.[0] || "-",
      },
      {
        title: "Övriga adress(er)",
        value: getAddressPreview(realEstate),
      },
      {
        title: "Beteckning",
        value: realEstate?.str_representation,
      },
      {
        title: "Publikt namn",
        value: realEstate?.popular_name || "-",
      },
      {
        title: "Typkod",
        value: realEstate?.type_code_display,
      },
      {
        title: "Tax. Area",
        value: toSqmString(realEstate?.area),
      },
      {
        title: "Tomtarea",
        value: toSqmString(realEstate?.land_area),
      },
      {
        title: "Ägare sedan",
        value: <DateCell date={realEstate?.owner_since} />,
      },
      {
        title: "Ansvarig",
        value: (
          <LinkedObject obj={realEstate?.owner} urlMethod={userDetailUrl} />
        ),
      },
      {
        title: "Bolag",
        value: (
          <LinkedObject
            obj={realEstate?.company}
            urlMethod={companyDetailUrl}
          />
        ),
      },
      {
        title: "Synka mot Avy-Tmpl",
        value: (
          <StatusLabel state={realEstate?.should_sync_to_avytmpl ? 0 : 3}>
            {realEstate?.should_sync_to_avytmpl ? "Ja" : "Nej"}
          </StatusLabel>
        ),
        hidden: !avyTmplActive,
      },
      {
        title: "Avy-Tmpl ID",
        value: realEstate?.avytmpl_id || "Ej genererat",
        hidden: !avyTmplActive,
      },
    ],
    ["Bestånd"]: [
      {
        title: "Antal byggnader",
        value: filteredStats?.building_count || 0,
      },
      {
        title: "Antal hyresgäster",
        value: filteredStats?.tenant_count || 0,
      },
      {
        title: "Antal hyreslägenheter",
        value: filteredStats?.apartment_total_count || 0,
      },
      {
        title: "Antal lokaler",
        value: filteredStats?.industrialpremises_total_count || 0,
      },
      {
        title: "Antal bostadsrätter",
        value: filteredStats?.brfpremis_total_count || 0,
      },
      {
        title: "Tot. area hyreslägenheter",
        value: toSqmString(filteredStats?.apartment_total_area),
      },
      {
        title: "Tot. area lokaler",
        value: toSqmString(filteredStats?.industrialpremises_total_area),
      },
      {
        title: "Tot. uthyrningsbar area",
        value: toSqmString(filteredStats?.rentable_area),
      },
    ],
    ["Avtal"]: [
      {
        title: "Hyresavtal, aktiva ",
        value: filteredStats?.leasecontract_active_count || 0,
      },
      {
        title: "Hyresavtal, kommande ",
        value: filteredStats?.leasecontract_upcoming_count || 0,
      },
      {
        title: "Hyresavtal utgående ",
        value: filteredStats?.leasecontract_warning_count || 0,
      },
      {
        title: "Hyresavtal, uppsagda",
        value: filteredStats?.leasecontract_closed_count || 0,
      },
      {
        title: "Övriga avtal, aktiva ",
        value: filteredStats?.othercontract_active_count || 0,
      },
      {
        title: "Övriga avtal, kommande ",
        value: filteredStats?.othercontract_upcoming_count || 0,
      },
      {
        title: "Övriga avtal utgående ",
        value: filteredStats?.othercontract_warning_count || 0,
      },
      {
        title: "Övriga avtal, uppsagda",
        value: filteredStats?.othercontract_closed_count || 0,
      },
      {
        title: "Parkeringsavtal, aktiva ",
        value: filteredStats?.parkingcontract_active_count || 0,
      },
      {
        title: "Parkeringsavtal, kommande ",
        value: filteredStats?.parkingcontract_upcoming_count || 0,
      },
      {
        title: "Parkeringsavtal utgående ",
        value: filteredStats?.parkingcontract_warning_count || 0,
      },
      {
        title: "Parkeringsavtal, uppsagda",
        value: filteredStats?.parkingcontract_closed_count || 0,
      },
    ],
  };

  if (hasBillectaViewPermission) {
    infoObj["Bokföringsdimensioner"] = [
      {
        title: "Projekt",
        value: realEstate?.project?.str_representation || "-",
      },
      {
        title: "Kostnadsställe",
        value: realEstate?.cost_center?.str_representation || "-",
      },
      {
        title: (
          <TextButton
            title="Redigera bokföringsdimensioner"
            clicked={() => setAccountingSettingsOpen(true)}
            iconType="edit"
            iconPlacement="right"
          />
        ),
      },
    ];
  }

  return infoObj;
}
