import * as React from "react";
import * as SC from "./styles";

import settingsIcon from "../../../assets/svg/material/settings_black.svg";
import notifIcon from "../../../assets/svg/material/notifications_black.svg";

import calendarIcon from "../../../assets/svg/material/calendar_black.svg";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NotificationsPopup from "../../Popups/NotificationsPopup/NotificationsPopup";
import Search from "./Search";
import { useNotificationsPagination } from "../../../store/notificationsDisplay";
import { buildQueryString } from "../../../store/base";
import { isEqual } from "lodash";
import { TextButton } from "../../Forms/Base/Buttons";
import { logout } from "../../../store/app/actions";
// import GlobalFilter from "./GlobalFilter";

export default ({ sideBarOpen }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const user = useSelector((state) => state.app.user);

  const [notifsOpen, setNotifsOpen] = React.useState(false);

  const notReadDataRef = React.useRef();

  const authedUserId = useSelector((state) => state.app?.user?.id);

  const queryString = buildQueryString({
    _page: 1,
    _page_size: 10,
    not_read_by: authedUserId ? [authedUserId] : undefined,
  });

  const [notReadData, loadingNotReadData] =
    useNotificationsPagination(queryString);

  React.useEffect(() => {
    if (isEqual(notReadData, notReadDataRef?.current)) return;

    notReadDataRef.current = notReadData;
  }, [notReadData]);

  const notReadCount = notReadData?.results?.length;

  const userImg = user?.image?.get;
  const userName = `${
    user?.first_name ? `${user?.first_name.replace(" ", "")}+` : ""
  }${user?.last_name ? `${user?.last_name.replace(" ", "")}` : ""}`;

  const goToSupport = () => {
    window.open("https://support.pigello.se/sv/", "_blank").focus();
  };

  const goToSettings = () => {
    push("/configcenter/groups-and-users");
  };

  const goToProfile = () => {
    push("/configcenter/profile");
  };

  const goToCalendar = () => {
    push("/calendar");
  };

  return (
    <SC.Wrapper>
      <Search sideBarOpen={sideBarOpen} />
      {/* <GlobalFilter sideBarOpen={sideBarOpen} /> */}

      <SC.TopBarItemsContainer>
        <SC.SupportLink onClick={goToSupport}>Support</SC.SupportLink>
        <SC.IconButton onClick={goToSettings} icon={settingsIcon} />
        <SC.IconButton
          onClick={() => setNotifsOpen(true)}
          icon={notifIcon}
          notReadCount={notReadCount}
        >
          <NotificationsPopup
            data={notReadDataRef?.current}
            loadingData={loadingNotReadData}
            open={notifsOpen}
            setOpen={setNotifsOpen}
          />
        </SC.IconButton>
        <SC.IconButton onClick={goToCalendar} icon={calendarIcon} />

        <SC.ProfileBadge
          onClick={goToProfile}
          name={userName}
          image={userImg}
        />
      </SC.TopBarItemsContainer>
    </SC.Wrapper>
  );
};
