import { useDispatch, useSelector } from "react-redux";
import { collectPermissions } from "../store/actions";

export const usePermissions = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);
  const permissions = user?.permissions;
  const isFetching = useSelector((state) => state.app.loadingPerms);
  
  if (!permissions) {

    if (!isFetching && user?.id) {
      // collect if we can and is not already loading
      dispatch(collectPermissions({userId:user.id}));
    }

    return {
      userPermissions: undefined,
      customerPermissions: undefined
    }
  }

  return {
    userPermissions: permissions?.permissions,
    customerPermissions: permissions?.customer_permissions,
  };
};

export const useSupervisorCheck = () => {
  const user = useSelector((state) => state.app.user);
  if (!user || !user.supervisor) {
    return false;
  }

  return user.id === user.supervisor.id;
};

export const usePermissionCheck = (permission) => {
  const { userPermissions, customerPermissions } = usePermissions();

  if (!permission) {
    return true;
  }

  // check user perms first
  if (
    (userPermissions && userPermissions?.includes(permission)) ||
    (customerPermissions && customerPermissions?.[permission])
  ) {
    return true;
  }

  return false;
};

export const useAnyPermissionCheck = (permissions) => {
  const { userPermissions, customerPermissions } = usePermissions();
  if (!permissions && !permissions?.length) {
    return true;
  }

  if (
    (userPermissions &&
      permissions?.some((perm) => userPermissions.includes(perm))) ||
    (customerPermissions &&
      permissions?.some((perm) => customerPermissions?.[perm]))
  ) {
    return true;
  }

  return false;
};

export const useAllPermissionCheck = (permissions) => {
  const { userPermissions, customerPermissions } = usePermissions();
  if (!permissions?.length) {
    return false;
  }

  if (!userPermissions?.length) {
    return false;
  }

  if (
    permissions.every(
      (perm) => userPermissions?.includes(perm) || customerPermissions?.[perm]
    )
  ) {
    return true;
  }

  return false;
};

/**
 * Intended as a way to avoid wrong checks when multiple permisions are checked for frequently used permissions
 */
export const useFrequentPermissions = () => {
  const hasBillectaViewPermission = useAllPermissionCheck([
    "allow_billecta",
    "view_can_billing",
  ]);
  const hasBillectaFullPermission = useAllPermissionCheck([
    "allow_billecta",
    "view_can_billing",
    "change_can_billing",
    "delete_can_billing",
  ]);

  return {
    hasBillectaViewPermission,
    hasBillectaFullPermission,
  };
};
