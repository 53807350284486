import styled from "styled-components";

import arrowDown from "../../../../assets/svg/material/keyboard-arrow-down-black.svg";
import arrowUp from "../../../../assets/svg/material/keyboard-arrow-up-black.svg";

export const SelectFieldWrapper = styled.div`
  width: 50%;
  min-width: 250px;
`;

export const ArrowDownIcon = styled.div`
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${arrowDown});
`;
export const ArrowUpIcon = styled.div`
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${arrowUp});
`;
