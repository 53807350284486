import * as React from "react";
import { useHistory, Link } from "react-router-dom";
import * as SC from "./styles";
import { useTransition } from "@react-spring/web";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import { useFilteredLeaseContracts } from "../../../store/leaseContracts";
import { buildQueryString } from "../../../store/base";

export default function PremisesInfo({ premises }) {
  const { push } = useHistory();
  const [displayedPremises, setDisplayedPremises] = React.useState(null);

  const apartmentQuery = buildQueryString({
    apartments: displayedPremises?.id,
  });
  const indpQuery = buildQueryString({
    industrial_premises_list: displayedPremises?.id,
  });
  const [leaseContracts, leaseContractsLoading] = useFilteredLeaseContracts(
    displayedPremises?.kind === "apartment" ? apartmentQuery : indpQuery
  );

  const activeContract = leaseContracts?.find((lc) =>
    [0, 1, 2].includes(lc.state)
  );
  // keep UI looking OK when no premises from props
  React.useEffect(() => {
    if (premises) {
      setDisplayedPremises(premises);
    } else {
      setTimeout(() => {
        setDisplayedPremises(null);
      }, 300);
    }
  }, [premises]);

  const modalTransition = useTransition(!!premises, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const goToPremises = () => {
    if (!premises) return;
    push(
      `/${
        premises.kind === "apartment"
          ? "apartments"
          : "industrialpremises"
          ? "industrial-premises"
          : "common-areas"
      }/detail/${premises.id}`
    );
  };

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.PremisesInfoContainer style={{ opacity: styles.opacity }}>
          <SC.PremisesTitle>
            {displayedPremises?.str_representation}
          </SC.PremisesTitle>

          <SC.PremisesInfo>
            <strong>Area:</strong>
            &nbsp;{displayedPremises?.area}m2
          </SC.PremisesInfo>

          {displayedPremises?.kind !== "commonarea" && (
            <>
              <SC.PremisesInfo>
                <strong>Avtalsstatus:</strong>&nbsp;
                {displayedPremises?.state_display}
              </SC.PremisesInfo>

              <SC.PremisesInfo>
                <strong>Total årshyra:</strong>
                &nbsp;{activeContract?.total_rent}
              </SC.PremisesInfo>

              <SC.PremisesInfo>
                <strong>Hyresgäst:</strong>
                &nbsp;
                <Link to={`/tenants/${displayedPremises?.tenant?.id}`}>
                  {displayedPremises?.tenant?.str_representation}
                </Link>
              </SC.PremisesInfo>

              <PrimaryButton
                extraStyle={{ marginTop: "6px" }}
                title="Gå till hyresobjekt"
                clicked={goToPremises}
              />
            </>
          )}
        </SC.PremisesInfoContainer>
      )
  );
}
