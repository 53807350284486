import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useTodoBillingInvoiceStatistics } from "../../../store/overview";

import { useHistory } from "react-router-dom";
import { BILLING_KEY_MAPPER } from "../../../components/Details/Dashboard/MyWorkday/TaskCards";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import CompanyTable from "../../../components/Tables/Companies/BillectaTable";
import InvoicingErrorsTable from "../../../components/Tables/InvoicingErrors/FullTable";
import InvoicingRecordTable from "../../../components/Tables/InvoicingRecord/FullTable";
import VacancyBookingRecordTable from "../../../components/Tables/InvoicingVacancyRecord/FullTable";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { creditorInvoiceDetailUrl } from "../../../store/billectaInvoicing";
import { useFilteredCompanies } from "../../../store/companies";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import { useInvoicingRecordPaginationCount } from "../../../store/invoicingRecords";
import { useVacancyInvoicingRecordPaginationCount } from "../../../store/invoicingVacancyRecords";
import InvoicesSearchTable from "./InvoicesSearchTable";

export default function InvoicingTodo() {
  const { filteredRealEstates } = useActiveFilters();
  const [openTable, setOpenTable] = React.useState(null);
  const [rawTodoBillingInvoiceStats, todoBillingInvoiceLoading] =
    useTodoBillingInvoiceStatistics();
  const data = rawTodoBillingInvoiceStats?.data;
  const { push } = useHistory();

  const companyQuery = buildQueryString({
    realestate_ids: filteredRealEstates,
  });
  const [companies] = useFilteredCompanies(companyQuery);

  const creditorIds = companies
    ?.filter((c) => c.billecta_id)
    ?.map((c) => c.billecta_id);

  const invoicingErrorQuery = {};

  if (filteredRealEstates?.length) {
    invoicingErrorQuery.creditor_id__in = creditorIds;
  }

  const [invoicingErrorCount] = useInvoicingErrorPaginationCount(
    filteredRealEstates?.length ? invoicingErrorQuery : ""
  );

  const failedInvoiceQ = {
    billecta_id__isnull: true,
    skipped_generating: false,
    realestate_ids: filteredRealEstates,
  };

  const [failedInvoicingsCount] = useInvoicingRecordPaginationCount({
    filters: {
      billecta_id__isnull: true,
      skipped_generating: false,
      realestate_ids: filteredRealEstates,
    },
  });
  const [failedVacancyBookingsCount] = useVacancyInvoicingRecordPaginationCount(
    {
      filters: {
        billecta_id__isnull: true,
        skipped_generating: false,
        realestate_ids: filteredRealEstates,
      },
    }
  );

  const dataCount = Object.keys(data || {}).reduce((acc, cur) => {
    return acc + data?.[cur]?.length;
  }, 0);

  const totalCount =
    (invoicingErrorCount || 0) +
    (dataCount || 0) +
    (failedInvoicingsCount || 0) +
    (failedVacancyBookingsCount || 0);

  const contentTypes = Object.keys(data || {});

  const goToCreditor = (obj) => {
    push(creditorInvoiceDetailUrl({ creditorId: obj.billecta_id }));
  };

  return (
    <DetailInnerWrapper>
      {totalCount > 0 ? (
        <>
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitle>Avisering ({totalCount})</OverviewTitle>
            </OverviewTitleWrapper>

            {failedInvoicingsCount > 0 && (
              <InnerBox style={{ marginBottom: 12 }}>
                <OverviewTitleWrapper
                  style={{
                    marginBottom: openTable === `failedinvoicings` ? 24 : 0,
                  }}
                >
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Misslyckade aviseringar ({failedInvoicingsCount})
                    </OverviewTitle>
                    <OverviewSubtitle>
                      {openTable !== `failedinvoicings`
                        ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa fel`
                        : `Klicka på ett fel för att komma till källan`}
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                  <PrimaryButton
                    title={
                      openTable !== `failedinvoicings`
                        ? "Visa detaljer"
                        : "Stäng"
                    }
                    clicked={
                      openTable !== `failedinvoicings`
                        ? () => setOpenTable(`failedinvoicings`)
                        : () => setOpenTable(null)
                    }
                  />
                </OverviewTitleWrapper>

                {openTable === "failedinvoicings" && (
                  <InvoicingRecordTable
                    isBare
                    ignoreLocalStorage
                    withDetailUrl
                    persistantQuery={failedInvoiceQ}
                  />
                )}
              </InnerBox>
            )}

            {failedVacancyBookingsCount > 0 && (
              <InnerBox style={{ marginBottom: 12 }}>
                <OverviewTitleWrapper
                  style={{
                    marginBottom:
                      openTable === `failedvacancybookings` ? 24 : 0,
                  }}
                >
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Misslyckade vakansbokingar ({failedVacancyBookingsCount})
                    </OverviewTitle>
                    <OverviewSubtitle>
                      {openTable !== `failedvacancybookings`
                        ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa fel`
                        : `Klicka på ett fel för att komma till källan`}
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                  <PrimaryButton
                    title={
                      openTable !== `failedvacancybookings`
                        ? "Visa detaljer"
                        : "Stäng"
                    }
                    clicked={
                      openTable !== `failedvacancybookings`
                        ? () => setOpenTable(`failedvacancybookings`)
                        : () => setOpenTable(null)
                    }
                  />
                </OverviewTitleWrapper>

                {openTable === "failedvacancybookings" && (
                  <VacancyBookingRecordTable
                    isBare
                    ignoreLocalStorage
                    withDetailUrl
                    persistantQuery={failedInvoiceQ}
                  />
                )}
              </InnerBox>
            )}

            {invoicingErrorCount > 0 && (
              <InnerBox style={{ marginBottom: 12 }}>
                <OverviewTitleWrapper
                  style={{
                    marginBottom: openTable === `invoicingerrors` ? 24 : 0,
                  }}
                >
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Övriga aviseringsrelaterade fel ({invoicingErrorCount})
                    </OverviewTitle>
                    <OverviewSubtitle>
                      {openTable !== `invoicingerrors`
                        ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa fel`
                        : `Klicka på ett fel för att komma till källan`}
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                  <PrimaryButton
                    title={
                      openTable !== `invoicingerrors`
                        ? "Visa detaljer"
                        : "Stäng"
                    }
                    clicked={
                      openTable !== `invoicingerrors`
                        ? () => setOpenTable(`invoicingerrors`)
                        : () => setOpenTable(null)
                    }
                  />
                </OverviewTitleWrapper>

                {openTable === "invoicingerrors" && (
                  <InvoicingErrorsTable
                    isBare
                    ignoreLocalStorage
                    persistantQuery={invoicingErrorQuery}
                  />
                )}
              </InnerBox>
            )}

            {contentTypes?.map((ct) => {
              const contentTypeData = data[ct];

              const open = openTable === `${ct}`;

              const creditorIds = contentTypeData?.map((c) => c.creditor_id);

              const companyPersistantQuery = {
                billecta_id__in: creditorIds,
              };

              return (
                <InnerBox style={{ marginBottom: 12 }} key={ct}>
                  <OverviewTitleWrapper style={{ marginBottom: open ? 24 : 0 }}>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>
                        {BILLING_KEY_MAPPER[ct] || ct} (
                        {contentTypeData?.length})
                      </OverviewTitle>
                      <OverviewSubtitle>
                        {!open
                          ? ct === "not_attested"
                            ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa bolag`
                            : `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa fakturor`
                          : ct === "not_attested"
                          ? "Tryck på ett av bolagen för att komma till dess aviseringsöversikt"
                          : `Klicka på en faktura för att komma till detaljsidan`}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>

                    <PrimaryButton
                      title={!open ? "Visa detaljer" : "Stäng"}
                      clicked={
                        !open
                          ? () => setOpenTable(`${ct}`)
                          : () => setOpenTable(null)
                      }
                    />
                  </OverviewTitleWrapper>

                  {open && ct !== "not_attested" && (
                    <InvoicesSearchTable invoices={contentTypeData} />
                  )}

                  {open && ct === "not_attested" && (
                    <CompanyTable
                      {...{
                        onRowClicked: goToCreditor,
                        persistantQuery: companyPersistantQuery,
                      }}
                    />
                  )}
                </InnerBox>
              );
            })}
          </DetailPageBox>
        </>
      ) : (
        <DetailPageBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OverviewTitle style={{ padding: "48px 0 " }}>
            {todoBillingInvoiceLoading ? "Laddar..." : "Inget att hantera"}
          </OverviewTitle>
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
