import { cloneDeep } from "lodash";
import moment from "moment";

import { store } from "../../store";
import constants from "./constants";
import * as services from "./services";

export const generateAccountingReport = ({
  successCallback,
  errorCallback,
  creditorId,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const data = cloneDeep(state[constants.STORE_NAME].formInstance);

      data.CreditorPublicId = creditorId;
      data.From = moment(data.From)
        .set({ hour: 0, minute: 0, seconds: 0 })
        .format("YYYY-MM-DD HH:mm");

      data.To = moment(data.To)
        .set({ hour: 23, minute: 59, seconds: 59 })
        .format("YYYY-MM-DD HH:mm");

      await services.generateReport(data);

      // fetch exports again
      dispatch(getAccountingReports({ creditorId }));

      //restore form

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e);
    }
  };
};

export const generateDynamicAccountingReport = ({
  successCallback,
  errorCallback,
  creditorId,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const data = cloneDeep(state[constants.STORE_NAME].formInstance);

      data.CreditorPublicId = creditorId;
      data.From = moment(data.From)
        .set({ hour: 0, minute: 0, seconds: 0 })
        .format("YYYY-MM-DD HH:mm");

      data.To = moment(data.To)
        .set({ hour: 23, minute: 59, seconds: 59 })
        .format("YYYY-MM-DD HH:mm");

      const file = await services.generateDynamicReport(data);

      successCallback && successCallback(file);
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback(e);
    }
  };
};

export const clearAccountingReports = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_REPORTS,
      payload: {
        reports: null,
      },
    });
  };
};

export const getAccountingReports = ({ creditorId, successCallback }) => {
  return async (dispatch) => {
    const reports = await services.getAccountingReports(creditorId);

    dispatch({
      type: constants.SET_REPORTS,
      payload: {
        reports,
      },
    });

    successCallback && successCallback();
  };
};

export const downloadAccountingReport = ({ fileId, fileName }) => {
  return async (dispatch) => {
    const { data } = await services.getFileData(fileId);
    const blob = base64toBlob(data, "application/octet-stream");
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = fileName;
    a.click();
  };
};

function base64toBlob(base64Data, contentType) {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}
