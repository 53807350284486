import * as React from "react";
import { cloneDeep } from "lodash";

//store, state
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { useReportErrandsPaginationCount } from "../../../store/reportErrands/hooks/retrieve";

//components & styles
import ErrandsTable from "../../../components/Tables/ReportErrands/FullTableNewVersion";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  FilterButton,
  FilterWrapper,
} from "../../../components/Filters/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ReportErrandModalForm from "../../../components/Forms/ReportErrand/ChapterForm/ModalForm";

export default function Errands() {
  const { filteredRealEstates } = useActiveFilters();

  const [createErrandOpen, setCreateErrandOpen] = React.useState(false);

  const query = {
    realestate_ids: filteredRealEstates,
  };

  const canAdd = useAllPermissionCheck([
    "add_can_reporterrand",
    "allow_errand_faults",
  ]);

  return (
    <>
      {canAdd && (
        <ReportErrandModalForm
          method="POST"
          isOpen={createErrandOpen}
          onCheckout={() => setCreateErrandOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Ärenden</OverviewTitle>
            {canAdd && (
              <PrimaryButton
                title="Skapa felanmälan"
                clicked={() => setCreateErrandOpen(true)}
              />
            )}
          </OverviewTitleWrapper>
          <ErrandsTable persistantQuery={query} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
