import * as React from "react";
import moment from "moment";
import * as SC from "./styles";

export const DateCell = ({ date }) => {
  if (!date) {
    return <>-</>;
  }

  return (
    <SC.DateCell>{date ? moment(date).format("YYYY-MM-DD") : "-"}</SC.DateCell>
  );
};
