import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  usePipeForm,
  destroyPatchForm,
  destroyPostForm,
  detailUrl,
  update,
  usePipe,
} from "../../../../store/pipes";
import {
  buildQueryString,
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../../store/base";
import { useFilteredPipeAttributes } from "../../../../store/pipeAttributes";
import { cloneDeep } from "lodash";

export default function PipeModalForm({ method, id, isOpen, onCheckout }) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [current] = usePipe(id);
  const [lastSet, setLastSet] = React.useState(null); // id of lastest matched form

  const dynamicAttributeQuery = buildQueryString({
    id__in: current?.dynamic_attributes?.map((d) => d.id) || [], // emtpry array to return 0 if no query
  });

  const [dynamicAttributes] = useFilteredPipeAttributes(dynamicAttributeQuery);

  const formLoaded = Boolean(usePipeForm(method, id));

  React.useEffect(() => {
    if (method === "PATCH" && !!current && id !== lastSet) {
      // wait for dynamic attributes to be able to match
      if (current?.dynamic_attributes?.length && !dynamicAttributes?.length) {
        return;
      }

      const currentClone = cloneDeep(current);

      if (
        currentClone?.dynamic_attributes?.length &&
        dynamicAttributes?.length
      ) {
        currentClone.dynamic_attributes = currentClone.dynamic_attributes.map(
          (da) => dynamicAttributes.find((a) => a.id == da.id)
        );
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: currentClone,
        })
      );

      setLastSet(id);
    }
  }, [id, current, dynamicAttributes]);

  React.useEffect(() => {
    if (isOpen && method === "POST") {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: { name: "", description: "" },
        })
      );
    }

    if (!isOpen) {
      setLastSet(null);
    }
  }, [isOpen]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      push(detailUrl({ id: returned.id }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method, id)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Lägg till projekt" : "Uppdatera projekt"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
