import * as React from "react";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";

import { DetailPageBox, InnerBox } from "../../sharedStyles";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import { detailUrl as requirementDetailUrl } from "../../../store/marketRequirements";
import { DateCell, LinkedObject } from "../../Displays";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { useAllPermissionCheck } from "../../../store/base";
import theme from "../../../theme";
import { HomeQL } from "./styles";
import { TextButton } from "../../Forms/Base/Buttons";
import { getAdStateAndLabel } from "../../../store/marketApartmentAds/utils";
import { currentEnv, ENVIRONMENTS } from "../../..";

export default function AdInfo({ ad, isHomeQPipe }) {
  const hasHomeQPerm = useAllPermissionCheck(["allow_homeq", "view_can_homeq"]);
  const hasSbfPerm = useAllPermissionCheck(["view_can_sbf", "view_can_market"]);

  const isDev = currentEnv !== ENVIRONMENTS.PROD;

  const goToHomeQ = () => {
    window
      .open(
        isDev
          ? `https://demo.homeq.se/biz#/demo/adlisting/${ad.homeq_id}`
          : `https://portal.homeq.se/biz#/demo/adlisting/${ad.homeq_id}`,
        "_blank"
      )
      .focus();
  };

  return (
    <InnerBox style={{ border: 0, borderRadius: 0, boxShadow: 0 }}>
      <OverviewTitleWrapper>
        <OverviewTitle>Information</OverviewTitle>
      </OverviewTitleWrapper>

      {isHomeQPipe && ad?.homeq_id ? (
        <DetailPageBox
          onClick={goToHomeQ}
          style={{
            flexDirection: "row",
            cursor: "pointer",
            alignItems: "center",
            borderRadius: 5,
            fontWeight: 500,
            padding: 12,
          }}
        >
          <div
            style={{
              padding: 12,
              border: "1px solid #ddd",
              borderRadius: 5,
              marginRight: 12,
            }}
          >
            <HomeQL />
          </div>
          <TextButton
            clicked={goToHomeQ}
            title="Visa annons i HomeQ"
            iconType="arrow"
            iconPlacement="right"
          />
        </DetailPageBox>
      ) : isHomeQPipe ? (
        <DetailPageBox
          error
          style={{
            flexDirection: "row",

            alignItems: "center",
            borderRadius: 5,
            fontWeight: 500,
            padding: 12,
          }}
        >
          <div
            style={{
              padding: 12,
              border: "1px solid #ddd",
              borderRadius: 5,
              marginRight: 12,
            }}
          >
            <HomeQL />
          </div>
          <div
            style={{
              fontWeight: theme.fontWeights.headerXSmall,
              fontSize: theme.fontSizes.headerXSmall,
            }}
          >
            Denna annons kunde ej skapas i HomeQ. Kontrollera felmeddelanden
            under <strong>Marknad -> Översikt</strong>
          </div>
        </DetailPageBox>
      ) : null}

      <DetailInfo
        infoObj={getInfoObj({ ad, hasHomeQPerm, hasSbfPerm, isHomeQPipe })}
      />
    </InnerBox>
  );
}

function getInfoObj({ ad, hasHomeQPerm, hasSbfPerm, isHomeQPipe }) {
  const { stateDisplay, state } = getAdStateAndLabel(ad);

  let infoObj = {
    Grundläggande: [
      {
        title: "Status",
        value: <StatusLabel state={state}>{stateDisplay}</StatusLabel>,
      },
      {
        title: "Publiceringsdatum",
        value: <DateCell date={ad.date_publish} />,
      },
      {
        title: "Planerad inflytt",
        value: <DateCell date={ad.access_date} />,
      },
      {
        title: "Kravprofil",
        value: (
          <LinkedObject
            obj={ad?.requirement}
            urlMethod={requirementDetailUrl}
          />
        ),
      },
      {
        title: "Annonshantering",
        value: ad?.pilot_mode_display,
      },
      {
        title: "Prioritera nuvarande hyresgäster",
        value: (
          <StatusLabel state={ad?.prioritize_customers ? 0 : 6}>
            {ad?.prioritize_customers ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Korttidskontrakt",
        value: (
          <StatusLabel
            state={ad?.short_lease_max_date || ad?.short_lease_min_date ? 0 : 6}
          >
            {ad?.short_lease_max_date || ad?.short_lease_min_date
              ? "Ja"
              : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Uthyres som minst till",
        value: <DateCell date={ad?.short_lease_min_date} />,
        hidden: !ad?.short_lease_min_date,
      },
      {
        title: "Uthyres som mest till",
        value: <DateCell date={ad?.short_lease_max_date} />,
        hidden: !ad?.short_lease_max_date,
      },
    ],
    Objekt: [
      {
        title: "Typ",
        value: ad.apartment
          ? "Lägenhet"
          : ad.industrial_premises
          ? "Lokal"
          : ad.parking_spot
          ? "Parkeringsplats"
          : "-",
      },
      {
        title: "Objekt",
        value: ad.apartment ? (
          <LinkedObject obj={ad.apartment} urlMethod={apartmentDetailUrl} />
        ) : ad.industrial_premises ? (
          <LinkedObject
            obj={ad.industrial_premises}
            urlMethod={indpDetailUrl}
          />
        ) : ad.parking_spot ? (
          <LinkedObject obj={ad.parking_spot} urlMethod={parkingDetailUrl} />
        ) : (
          "-"
        ),
      },
    ],
    // Visning: [
    //   {
    //     title: "Visning",
    //     value: !ad?.viewing_type ? "Ingen visning" : ad?.viewing_type_display,
    //   },
    //   {
    //     title: "Tid och datum",
    //     value: <TimeCell date={ad?.viewing_time} />,
    //     hidden: !ad?.viewing_type,
    //   },
    //   {
    //     title: "Anteckningar",
    //     value: ad?.viewing_notes,
    //     hidden: !ad?.viewing_type,
    //   },
    // ],
    "Publiceras på": [
      // {
      //   title: "Pigello marknadsplats",
      //   value: (
      //     <StatusLabel state={ad?.publish_pigello ? 0 : 3}>
      //       {ad?.publish_pigello ? "Aktiv" : "Inaktiv"}
      //     </StatusLabel>
      //   ),
      // },
      {
        title: "HomeQ",
        value: (
          <StatusLabel state={ad?.publish_homeq ? 0 : 3}>
            {ad?.publish_homeq ? "Aktiv" : "Inaktiv"}
          </StatusLabel>
        ),
        hidden: !ad?.apartment?.id || !hasHomeQPerm || !isHomeQPipe,
      },
      {
        title: "Stockholms bostadsförmedling",
        value: (
          <StatusLabel state={ad?.publish_sbf ? 0 : 3}>
            {ad?.publish_sbf ? "Aktiv" : "Inaktiv"}
          </StatusLabel>
        ),
        hidden: !ad?.apartment?.id || !hasSbfPerm || isHomeQPipe,
      },
    ],
  };

  return infoObj;
}
