import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default (realestates) => {
  const defs = [
    {
      Header: "Namn",
      accessor: "company_name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Applicerad på fastighet",
      accessor: "realestates",
      Cell: ({ value }) => {
        const names = [];
        value.forEach((id) => {
          return (realestates || []).find((r) => {
            if (r.id === id.id) {
              names.push(r.str_representation);
            }
          });
        });

        return (
          <div>
            {names?.length ? names.join(",") : "Ingen specifik fastighet"}
          </div>
        );
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return defs;
};
