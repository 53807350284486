export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_KEYHANDLING_KEY",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_KEYHANDLING_KEY",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_KEYHANDLING_KEY",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_KEYHANDLING_KEY",
  DESTROY_FORM: "DESTROY_FORM_KEYHANDLING_KEY",
  SET_FORM_ERROR: "SET_FORM_ERROR_KEYHANDLING_KEY",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_KEYHANDLING_KEY",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_KEYHANDLING_KEY",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_KEYHANDLING_KEY",
  RESET_STATE: "RESET_STATE_KEYHANDLING_KEY",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_KEYHANDLING_KEY",
  REMOVE_OBJECT: "REMOVE_OBJECT_KEYHANDLING_KEY",
  CLEAR_FETCHED: "CLEAR_FETCHED_KEYHANDLING_KEY",

  LIST_URL: "/keyhandling/key/list/",
  POST_URL: "/keyhandling/key/",
  PATCH_URL: "/keyhandling/key/",
  GET_URL: "/keyhandling/key/",

  STORE_NAME: "key",
};
