import {
  post,
  destroyForm,
  options,
  getSingleFetchProcessName,
  get,
  patch,
} from "../../base";

import constants from "./constants";

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const update = ({
  processSuccess,
  id,
  processError,
  successCallback,
  errorCallback,
}) => {
  return patch({
    url: `${constants.POST_URL}${id}/`,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};
