import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { TableLink } from "../Forms/Base/Buttons";

export default ({ mappings, restLength = 0 }) => {
  const { push } = useHistory();

  if (!mappings || !mappings.length) {
    return <span className={"eui-textTruncate"}>-</span>;
  }

  const renderMappings = () =>
    mappings.map((m, index) => {
      const { obj, urlMethod } = m;

      if (!obj || !obj.id) {
        return null;
      }

      let fetchedObj = typeof obj === "function" ? obj() : obj;
      if (!fetchedObj) {
        return null;
      }

      const isLast = index === mappings.length - 1;

      return (
        <>
          <TableLink
            withComma={!isLast}
            clicked={(event) => {
              if (event) event.stopPropagation();
              push(urlMethod({ id: fetchedObj.id }));
            }}
            title={fetchedObj.str_representation || "-"}
          />
        </>
      );
    });

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {renderMappings()}
      {restLength > 0 && (
        <div
          style={{
            marginLeft: 4,
            color: "#499dff",
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          &nbsp;+&nbsp;{restLength}
        </div>
      )}
    </div>
  );
};
