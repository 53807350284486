import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import {
  constants as productConstants,
  useInvoicingProductPagination,
  performFilter as productPerformFilter,
} from "../../../../../store/invoicingProducts";
import {
  constants as costCenterConstants,
  useInvoicingCostCenterPagination,
  performFilter as costCenterPerformFilter,
} from "../../../../../store/invoicingCostCenters";
import {
  constants as projectConstants,
  useInvoicingProjectPagination,
  performFilter as projectPerformFilter,
} from "../../../../../store/invoicingProjects";
import costCenterListDefs from "../../../../Tables/InvoicingCostCenters/listDefs";
import projectListDefs from "../../../../Tables/InvoicingProjects/listDefs";
import productListDefs from "../../../../Tables/InvoicingProducts/listDefs";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import PrimaryBtn from "../../../Base/Buttons/PrimaryBtn";

import { toMoneyString } from "../../../../utils/stringUtils";
import {
  ExclamationTriangleIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import StatusFlag, {
  STATUS_FLAG_STATES,
} from "../../../../Displays/StatusFlag";
import validationErrorMap from "../../../../Billecta/Invoices/InvoiceComponents/validationErrorMap";
import TextInputField from "../../../Base/Fields/TextInputField";
import SelectField from "../../../Base/Fields/SelectField";
import TableSelectField from "../../../Base/Fields/TableSelectField";
import { TextButton } from "../../../Base/Buttons";
import DateSelect from "../../../Base/Fields/DateSelect";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";
import InvoicingCostCenterTable from "src/components/Tables/InvoicingCostCenters/FullTable";
import InvoicingProjectsTable from "src/components/Tables/InvoicingProjects/FullTable";

export default function Costs({
  title,
  description,
  storeName,
  method,
  usesCentRounding,
  periodStart,
  periodEnd,
  validationErrors,
}) {
  const dispatch = useDispatch();

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = React.useState([]);

  const records = useFormInstanceField({
    storeName,
    fieldKey: "Records",
  });
  const invoiceFee = useFormInstanceField({
    storeName,
    fieldKey: "InvoiceFee",
  });

  const updateRecordPeriods = () => {
    if (!records?.length) return;
    const recordsClone = cloneDeep(records);

    recordsClone.forEach((r) => {
      r.PeriodStart = periodStart;
      r.PeriodEnd = periodEnd;
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          Records: recordsClone,
        },
      })
    );
  };

  // update all invoice rows if period start/end updated
  React.useEffect(() => {
    if (periodStart && periodEnd) updateRecordPeriods();
  }, [periodStart, periodEnd]);

  const getMaxVAT = () => {
    let maxVAT = records?.reduce((acc, cur) => {
      const rowVAT = cur?.VAT;
      if (rowVAT > acc) return rowVAT;
      return acc;
    }, 0);

    if (!maxVAT) maxVAT = 0;

    return maxVAT;
  };

  const addInvoiceFee = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          InvoiceFee: {
            CurrencyCode: "SEK",
            Value: 0,
          },
        },
      })
    );
  };

  const deleteInvoiceFee = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          InvoiceFee: undefined,
        },
      })
    );
  };

  const onAddRow = () => {
    const recordsClone = cloneDeep(records || []);
    recordsClone.push({
      ArticleDescription: "",
      ProductPublicId: "",
      CostCenter: "",
      Project: "",
      Quantity: 1,
      Units: "st",
      UnitPrice: { Value: 0, CurrencyCode: "SEK" },
      VAT: getMaxVAT(),
      VatIsIncluded: false,
      RecordType: "Standard",
      PeriodStart: periodStart,
      PeriodEnd: periodEnd,
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          Records: recordsClone,
        },
      })
    );
  };

  const onRemoveRow = (idx) => {
    setAdvancedSettingsOpen([]);
    const recordsClone = cloneDeep(records);

    recordsClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          Records: recordsClone,
        },
      })
    );
  };

  const onAddMessageRow = () => {
    const recordsClone = cloneDeep(records || []);
    recordsClone.push({
      ArticleDescription: "",
      RecordType: "Message",
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          Records: recordsClone,
        },
      })
    );
  };

  const toggleAdvancedSettings = (idx) => {
    let settingsClone = cloneDeep(advancedSettingsOpen);

    if (settingsClone.includes(idx)) {
      settingsClone = settingsClone.filter((s) => s !== idx);
    } else {
      settingsClone.push(idx);
    }

    setAdvancedSettingsOpen(settingsClone);
  };

  const renderSummary = () => {
    let totalNet = records?.reduce((acc, cur) => {
      if (!cur?.UnitPrice?.Value) return acc;
      return acc + parseInt(cur?.UnitPrice?.Value) * (cur?.Quantity || 0);
    }, 0);

    // net portion of admin fee
    const adminFeeNet = parseInt(invoiceFee?.Value);
    // vat portion of admon fee
    const adminFeeVat =
      (invoiceFee?.Value || 0) * (1 + getMaxVAT() / 100) -
      (invoiceFee?.Value || 0);

    let totalVAT =
      records?.reduce((acc, cur) => {
        if (!cur?.UnitPrice?.Value) return acc;

        return (
          acc +
          ((cur?.UnitPrice?.Value || 0) *
            (cur?.Quantity || 0) *
            (cur?.VAT || 0)) /
            100
        );
      }, 0) || 0;

    if (adminFeeVat) {
      totalVAT = (totalVAT || 0) + (adminFeeVat || 0);
    }
    if (adminFeeNet) {
      totalNet = (totalNet || 0) + (adminFeeNet || 0);
    }
    let totalWithVAT = (totalNet || 0) + (totalVAT || 0);

    let centRounding = 0;

    if (usesCentRounding) {
      const totalRounded = Math.round(totalWithVAT);
      const diff = totalWithVAT - totalRounded;

      centRounding = diff * -1;
    }

    const totalPaid = totalWithVAT - centRounding * -1;

    const isCreditInvoice = totalPaid < 0;

    return (
      <div className="flex flex-col border border-solid border-slate-200 rounded bg-white mb-12">
        {records?.map((r, idx) => {
          const advancedOpen = advancedSettingsOpen.includes(idx);

          if (r.RecordType === "Message") {
            return (
              <div className="odd:bg-slate-100 border-b border-solid border-slate-200 py-4 px-2">
                <div className="flex justify-between">
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                    onClick={() => onRemoveRow(idx)}
                  >
                    <XCircleIcon width={24} />
                  </button>
                </div>
                <div className=" mb-2 ">
                  <TextInputField
                    title="Meddelande"
                    fieldKey={`Records[${idx}].ArticleDescription`}
                    instructionsKey={"Records.ArticleDescription"}
                    {...{ method, storeName }}
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div className="odd:bg-slate-100 border-b border-solid border-slate-200 py-4 px-2">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                    onClick={() => onRemoveRow(idx)}
                  >
                    <XCircleIcon width={24} />
                  </button>
                </div>
                <div className="grid gap-2 mb-2 grid-cols-2">
                  <TextInputField
                    title="Beskrivning"
                    fieldKey={`Records[${idx}].ArticleDescription`}
                    instructionsKey={"Records.ArticleDescription"}
                    {...{ method, storeName }}
                  />

                  <TableSelectField
                    {...{ method, storeName }}
                    fieldKey={`Records[${idx}].ProductPublicId`}
                    instructionsKey={"Records.ProductPublicId"}
                    title="Produkt"
                    placeholder="Välj produkt..."
                    TableComponent={InvoicingProductTable}
                  />
                </div>

                <div className="grid gap-2 mb-2 grid-cols-6">
                  <TextInputField
                    isNumber
                    title="Antal"
                    fieldKey={`Records[${idx}].Quantity`}
                    instructionsKey={"Records.Quantity"}
                    {...{ method, storeName }}
                  />

                  <TextInputField
                    title="Enhet"
                    fieldKey={`Records[${idx}].Units`}
                    instructionsKey={"Records.Units"}
                    {...{ method, storeName }}
                  />

                  <SelectField
                    title="Moms %"
                    fieldKey={`Records[${idx}].VAT`}
                    instructionsKey={`Records.VAT`}
                    {...{ method, storeName }}
                  />

                  <div className="col-span-3">
                    <TextInputField
                      isNumber
                      step="0.01"
                      title="À-pris"
                      fieldKey={`Records[${idx}].UnitPrice.Value`}
                      instructionsKey={"Records.UnitPrice.Value"}
                      {...{ method, storeName }}
                    />
                  </div>
                </div>

                {advancedOpen && (
                  <>
                    <div className="grid gap-2 mb-2 grid-cols-2">
                      <TableSelectField
                        {...{ method, storeName }}
                        fieldKey={`Records[${idx}].CostCenter`}
                        instructionsKey={"Records.CostCenter"}
                        title="Kostnadsställe"
                        placeholder="Välj kostnadsställe..."
                        TableComponent={InvoicingCostCenterTable}
                      />

                      <TableSelectField
                        {...{ method, storeName }}
                        fieldKey={`Records[${idx}].Project`}
                        instructionsKey={"Records.Project"}
                        title="Projekt"
                        placeholder="Välj projekt..."
                        TableComponent={InvoicingProjectsTable}
                      />
                    </div>

                    <div className="grid gap-2 mb-2 grid-cols-2">
                      <DateSelect
                        title="Period från"
                        fieldKey={`Records[${idx}].PeriodStart`}
                        instructionsKey={"Records.PeriodStart"}
                        {...{ method, storeName }}
                      />

                      <DateSelect
                        title="Period till"
                        fieldKey={`Records[${idx}].PeriodEnd`}
                        instructionsKey={"Records.PeriodEnd"}
                        {...{ method, storeName }}
                      />
                    </div>
                  </>
                )}

                <div className="">
                  <TextButton
                    title={
                      advancedOpen
                        ? "Dölj avancerade inställningar"
                        : "Visa avancerade inställningar"
                    }
                    iconType={advancedOpen ? "close" : "add"}
                    clicked={() => toggleAdvancedSettings(idx)}
                  />
                </div>
              </div>
            );
          }
        })}

        <div className="p-2 bg-slate-100">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <div className="text-xs">Administrationsavgift</div>
              {!!invoiceFee && (
                <TextButton
                  title="Radera"
                  iconType="close"
                  iconPlacement="right"
                  clicked={deleteInvoiceFee}
                />
              )}
            </div>

            <div>
              {invoiceFee ? (
                <TextInputField
                  isNumber
                  fieldKey="InvoiceFee.Value"
                  placeholder="Administrationsavgift"
                  {...{ storeName, method }}
                />
              ) : (
                <PrimaryBtn onClick={addInvoiceFee}>
                  <PlusIcon className="mr-1" width={16} /> Administrationsavgift
                </PrimaryBtn>
              )}
            </div>
          </div>
        </div>

        <div className="p-2">
          <div className="flex items-center justify-between mb-1">
            <div className="text-xs">Netto</div>
            <div className="text-xs font-semibold">
              {toMoneyString(totalNet, true)}
            </div>
          </div>
          <div className="flex items-center justify-between mb-1">
            <div className="text-xs">Moms</div>
            <div className="text-xs font-semibold">
              {toMoneyString(totalVAT, true)}
            </div>
          </div>
          <div className="flex items-center justify-between mb-1">
            <div className="text-xs">Total ink. moms</div>
            <div className="text-xs font-semibold">
              {toMoneyString(totalWithVAT, true)}
            </div>
          </div>
          {usesCentRounding && (
            <div className="flex items-center justify-between mb-1">
              <div className="text-xs">Öresavrundning</div>
              <div className="text-xs font-semibold">
                {toMoneyString(centRounding, true)}
              </div>
            </div>
          )}
          <div className="flex items-center justify-between mb-1">
            <div className="text-xs">
              {isCreditInvoice ? "Krediteras" : "Att betala"}
            </div>
            <div className="text-xs font-semibold">
              {toMoneyString(totalPaid, true)}
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <PrimaryBtn secondary onClick={onAddMessageRow} className="mr-2">
              Lägg till meddelanderad
            </PrimaryBtn>
            <PrimaryBtn onClick={onAddRow}>Lägg till rad</PrimaryBtn>
          </div>
        </div>
      </div>
    );
  };

  const missingPeriod = !periodStart || !periodEnd;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>
          {description && <OverviewSubtitle>{description}</OverviewSubtitle>}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      {missingPeriod && (
        <StatusFlag className="mb-4" state={STATUS_FLAG_STATES.ERROR}>
          <ExclamationTriangleIcon width={24} className="mr-2" /> Ange start och
          slut-period på fakturan för att kunna lägga till debiteringsrader
        </StatusFlag>
      )}

      {validationErrors["Credit_invoice_records_positivevalue"] && (
        <StatusFlag className="mb-4" state={STATUS_FLAG_STATES.ERROR}>
          <ExclamationTriangleIcon width={24} className="mr-2" />{" "}
          {validationErrorMap["Credit_invoice_records_positivevalue"]}
        </StatusFlag>
      )}

      {renderSummary()}
    </>
  );
}
