import * as React from "react";

export default () => {
  return (
    <div>
      Kontrollera att uppgifterna stämmer. Tryck sedan på "Registrera och
      slutför ärende" för att slutföra ärendet.
    </div>
  );
};
