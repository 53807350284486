import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import * as SC from "./styles";

import ResetPasswordForm from "../../components/ResetPassword/ResetPasswordForm";
import { axiosInstance } from "../../store/base/store/axios";

export default () => {
  const { replace } = useHistory();
  const { resetToken } = useParams();
  const [passwordInput, setPasswordInput] = React.useState("");
  const [sendDisabled, setSendDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (passwordInput.length < 6) {
      setSendDisabled(true);
    } else {
      setSendDisabled(false);
    }
  }, [passwordInput]);

  const handlePasswordInput = ({ target: { value } }) => {
    setPasswordInput(value);
  };

  const handleSetPassword = async () => {
    setLoading(true);

    try {
      await axiosInstance.put("/accounts/auth/password_reset/", {
        password_key: resetToken,
        password: passwordInput,
      });

      replace("/login");
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <SC.Wrapper>
      <ResetPasswordForm
        inputValue={passwordInput}
        onInputChanged={handlePasswordInput}
        buttonDisabled={sendDisabled}
        onSetPassword={handleSetPassword}
        loading={loading}
      />
    </SC.Wrapper>
  );
};
