import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InvoicePreviewForm from "../../components/Forms/Invoice/PreviewForm/PreviewForm";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import { preparePutInvoice } from "../../store/billectaInvoicing";
import { addToast, TOAST_TYPES } from "../../store/toasts";

export default function EditInvoice() {
  const dispatch = useDispatch();
  const [ready, setReady] = React.useState(false);
  const { invoiceId } = useParams();

  const { goBack } = useHistory();

  React.useEffect(() => {
    dispatch(
      preparePutInvoice({
        invoiceId,
        successCallback: () => setReady(true),
        errorCallback: (e) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta detaljer för redigering",
              description: e?.Message || e || "",
            })
          );

          goBack();
        },
      })
    );
  }, [invoiceId]);

  if (!ready) return <FullPageSpinner />;

  return <InvoicePreviewForm method="PUT" id={invoiceId} />;
}
