import * as React from "react";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import { constants } from "../../../../../store/reportErrands";
import { InfoBox } from "../../../../Displays";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { useDispatch } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ReportErrandSettingsTable from "src/components/Tables/ReportErrandSettings/FullTable";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const status = useFormInstanceField({
    storeName,
    fieldKey: "status",
  });
  const componentPlacement = useFormInstanceField({
    storeName,
    fieldKey: "component_placement",
  });
  const settingsQuery = { kind__isnull: true };

  const handleTypeChanged = () => {
    if (!componentPlacement?._dontClear) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            component_placement: null,
          },
        })
      );
    }
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Typ av fel</OverviewTitle>
          <OverviewSubtitle>
            Välj vad för typ av felanmälan som ska skapas
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {status != null && status !== 0 && (
        <InfoBox
          title={
            status === 3 || status === 4
              ? "Ärendet slutfört"
              : "Ärendet påbörjat"
          }
          text={`Eftersom ärendet är ${
            status === 3 || status === 4 ? "slutfört" : "påbörjat"
          } kan du inte redigera typ av felanmälan.`}
        />
      )}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"setting"}
          title="Typ av felanmälan"
          fieldTitle="Typ av felanmälan"
          description="Välj typ av felanmälan"
          persistantQuery={settingsQuery}
          disabled={status != null && status !== 0}
          changeCallback={handleTypeChanged}
          TableComponent={ReportErrandSettingsTable}
        />
      </div>
    </>
  );
};
