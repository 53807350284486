import * as React from "react";

// style, design
import { ToolTipCell, GenericCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default (realEstates) => [
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },

  {
    Header: "Fastighet",
    accessor: (row, _) => {
      const realEstate = realEstates?.find((b) => b.id === row.realestate);
      return realEstate?.str_representation || "-";
    },
    id: "realestates[0].str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Kategori",
    accessor: "category",
    Cell: ({ row }) => <ToolTipCell text={row.category_display} />,
  },
  {
    Header: "Koppling",
    accessor: "content_type",
    Cell: ({ row }) => (
      <GenericCell
        contentType={row.original.content_type}
        id={row.original.object_id}
      />
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Kommentar",
    accessor: "note",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
];
