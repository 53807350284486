import * as React from "react";

// store, state
import { constants } from "../../../../../store/tenantPortalSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import MediaField from "../../../Base/Fields/MediaField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Logga och Banner</OverviewTitle>
          <OverviewSubtitle>
            Ställ in vilken logotyp och bannerbild som ska synas i portalen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      <MediaField
        storeName={storeName}
        method={method}
        fieldKey={`hello_image`}
        instructionsKey={`hello_image`}
        allowedFormats={[".png", ".jpg", ".jpeg"]}
        title='Bild i Banner på "Mina sidor"'
        fileKey="hello_image"
      />

      <MediaField
        storeName={storeName}
        method={method}
        fieldKey={`logo`}
        instructionsKey={`logo`}
        allowedFormats={[".svg", ".png", ".jpg", ".jpeg"]}
        title="Logga"
        fileKey="logo"
      />
    </>
  );
};
