import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { usePermissionCheck } from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import { createUrl } from "../../store/editabledocs";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";

import { useEditableDocPaginationCount } from "../../store/editabledocs";
import Templates from "./overview/Templates";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function TemplateOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["view_can_contract"]);

  const canAdd = usePermissionCheck("add_can_contract");

  const [activeCount] = useEditableDocPaginationCount({ filters: {} });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Mallar",
      component: Templates,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal mallar: {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {canAdd && (
        <PrimaryBtn onClick={() => push(createUrl())}>
          Lägg till mall
        </PrimaryBtn>
      )}
    </div>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Avtalsmallar`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
