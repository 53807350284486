import { store } from "../../store";

import {
  retrieve,
  instructions,
  upload,
  update,
  putUpdate,
  fetchAskDeleteData,
  deleteData,
} from "./services";
import {
  getFormFetchProcessName,
  getFormInstanceStateType,
  getFormErrorStateType,
  getBackgroundRemoveStateType,
} from "../utils";
import { getWantedBackgroundTasks } from "../../utils";
import { getCancelToken } from "./axios";
import { getSummarizedStatistics } from "../../overview/store/actions";
import { addToast, TOAST_TYPES } from "../../toasts";
import { cloneDeep } from "lodash";
import appConstants from "../../app/constants";

export const checkTrailingUrlSlash = (url) => {
  // backend want's a trailing slash in the url

  if (!url) return url;

  // if query, dont end with /
  if (url.includes("?")) return url;

  const last = url?.slice(-1);
  if (last !== "/") {
    return `${url}/`;
  }
  return url;
};

const fireBackgroundTasks = (dispatch) => {
  const state = store.getState();

  const userLoggedin = !!state.app.authorization;

  if (userLoggedin) {
    getWantedBackgroundTasks().forEach((f) => {
      const [method, name, backgroundStateType] = f;
      const taskToken = getCancelToken();

      dispatch({
        type: backgroundStateType,
        payload: { name: name, taskToken },
      });

      method(dispatch);
    });
  }
};

/**
 * Iterate through delete data
 */
function iterElement(elem, result) {
  if (Array.isArray(elem)) {
    elem.forEach((childElem) => {
      iterElement(childElem, result);
    });
  } else if (elem.id) {
    if (result[elem.type_code]) {
      result[elem.type_code].push(elem);
    } else {
      result[elem.type_code] = [elem];
    }
  }
}

const structureDeleteData = (data) => {
  const result = {};

  iterElement(data, result);

  return result;
};

/**
 * @param {method} dispatch dispatch method
 * @param {Object} constants must include 'ADD_TO_IN_PROGRESS' which defines the state type
 * @param {string} name name of process
 */
export const addToProcess = (dispatch, constants, name) => {
  dispatch({
    type: constants.ADD_TO_IN_PROGRESS,
    payload: { name },
  });
};

/**
 * @param {method} dispatch dispatch method
 * @param {Object} constants must include 'REMOVE_FROM_IN_PROGRESS' which defines the state type
 * @param {string} name name of process
 */
export const removeFromProgress = (dispatch, constants, name) => {
  dispatch({
    type: constants.REMOVE_FROM_IN_PROGRESS,
    payload: { name },
  });
};

export const insertIntoAll = (constants, data) => {
  return async (dispatch) => {
    dispatch({
      type: constants.INSERT_INTO_ALL,
      payload: { result: data },
    });
  };
};

/**
 * @param {string} url to to perform GET on
 * @param {Object} constants must include 'INSERT_INTO_ALL' which defines the state type
 * @param {string} name name of process
 */
export const get = ({ url, constants, name, preProcess, returnData }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, name);

    const conformedUrl = checkTrailingUrlSlash(url);

    const result = await retrieve({ url: conformedUrl });
    let data = result.data;
    if (preProcess) {
      data = preProcess(data);
    }

    // return data to caller, used in i.e. in billecta
    if (returnData) {
      returnData(data);
    }

    // with exact match
    const allMode = conformedUrl === checkTrailingUrlSlash(constants.LIST_URL);

    dispatch({
      type: constants.INSERT_INTO_ALL,
      payload: { result: data, name: name, allMode: allMode },
    });

    // fire remaining background tasks
    fireBackgroundTasks(dispatch);
  };
};

/**
 * querystring will NOT be appended to url
 * you must do that yourself
 */
export const getFiltered = ({
  url,
  constants,
  querystring,
  preProcess,
  callback,
  taskToken,
  initiatedBySocket,
}) => {
  const isBackgroundTask = Boolean(
    store.getState()[constants.STORE_NAME].backgroundTasks[querystring]
  );

  return async (dispatch) => {
    addToProcess(dispatch, constants, querystring);

    // if this is not a background task, cancel all other background tasks
    if (!isBackgroundTask) {
      dispatch({
        type: getBackgroundRemoveStateType(constants.STORE_NAME),
        payload: {},
      });
    }

    const result = await retrieve({ url, taskToken });
    let data = result.data;
    if (preProcess) {
      data = preProcess(data);
    }

    dispatch({
      type: constants.INSERT_INTO_FILTERED,
      payload: {
        result: data,
        querystring: querystring,
        keepProgress: initiatedBySocket,
      },
    });

    if (callback) {
      callback(data);
    }

    // fire remaining background tasks
    fireBackgroundTasks(dispatch);

    if (initiatedBySocket) {
      setTimeout(() => {
        dispatch({
          type: constants.REMOVE_FROM_IN_PROGRESS,
          payload: { name: querystring },
        });
      }, 1000);
    }
  };
};

/**
 * querystring will NOT be appended to url
 * you must do that yourself
 */
export const getPagination = ({ url, constants, querystring, preProcess }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, querystring);

    const result = await retrieve({ url });
    let data = result.data;
    if (preProcess) {
      data = preProcess(data);
    }

    dispatch({
      type: constants.INSERT_INTO_PAGINATION,
      payload: { result: data, querystring: querystring },
    });

    // fire remaining background tasks
    fireBackgroundTasks(dispatch);
  };
};

export const cleanFields = (data, id) => {
  // we'll simply traverse the response
  // clean it up by removing unecessary values
  // and add additional values to ease the process
  // of using these instructions
  let result = {};

  // # read_only -> ro
  // # helpt_text -> h
  // # allow_null -> an
  // # validation -> va
  // # label -> l
  // # is_many -> im
  // # min_length -> mil
  // # max_length -> mal
  // # choices -> c, choices.value -> v, chocies.display_name -> d
  // # min_value -> miv
  // # max value -> mav
  // # required -> re
  // # child -> c1
  // # children -> cm

  const nested = data?.cm || data?.c1?.cm;

  // Django ArrayField choices are supported only if there is only one nested field which has choices defined
  const hasArrayFieldChoices =
    data?.k === "ListField" && data?.c1?.k === "ChoiceField";
  // some fields will always exist on this level
  // even if it's nested
  result._required = data["re"];
  result._readOnly = data["ro"];
  result._label = data["l"];
  result._allowNull = data["an"];
  result._internalId = id;
  result._helpText = data["h"];
  result._choices = hasArrayFieldChoices ? data["c1"]["c"] : data["c"];
  result._nested = Boolean(nested);
  result._minLength = data["mil"];
  result._maxLength = data["mal"];
  result._nestedUpdate = nested && data["nu"];

  // if it's not nested, we're fine with this and should just return the result
  if (!nested) {
    return result;
  }

  // if it's nested, traverse the tree, repeat the process
  Object.keys(nested).forEach((key) => {
    result[key] = cleanFields(nested[key], `${id}.${key}`);
  });

  return result;
};

export const cleanOptions = (data) => {
  if (!data) return null;
  let result = {};
  Object.keys(data).forEach((key) => {
    result[key] = cleanFields(data[key], key);
  });

  return result;
};

/**
 * It is assumed that the process name used is formatted
 * as `form${method}`
 *
 * constants must specify INSERT_INTO_FORMS which defines the state type to use
 */
export const options = ({ url, constants, method }) => {
  const urlWithOptionsFlag = `${checkTrailingUrlSlash(url)}?_with_actions=1`;

  const name = getFormFetchProcessName(method);

  return async (dispatch) => {
    addToProcess(dispatch, constants, name);

    const result = await instructions({
      url: urlWithOptionsFlag,
    });

    let formattedMethod = method === "PATCH" ? "PUT" : method;
    const cleaned = cleanOptions(result?.data?.actions?.[formattedMethod]);

    if (cleaned) {
      dispatch({
        type: constants.INSERT_INTO_FORMS,
        payload: { result: cleaned, name: name, method: method },
      });
    }
  };
};

export const destroyForm = ({ constants, method, success }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.DESTROY_FORM,
      payload: { method: method, success: success },
    });
  };
};

export const updateActiveFormInstance = ({ storeName, data }) => {
  return async (dispatch) => {
    dispatch({
      type: getFormInstanceStateType(storeName),
      payload: { result: data },
    });
  };
};

export const setActiveFormInstance = ({ storeName, data }) => {
  return async (dispatch) => {
    dispatch({
      type: getFormInstanceStateType(storeName),
      payload: { result: data, clean: true },
    });
  };
};

export const updateFormErrors = ({ storeName, data }) => {
  return async (dispatch) => {
    dispatch({
      type: getFormErrorStateType(storeName),
      payload: { result: data },
    });
  };
};

export const setFormErrors = ({ storeName, data }) => {
  return async (dispatch) => {
    dispatch({
      type: getFormErrorStateType(storeName),
      payload: { result: data, clean: true },
    });
  };
};

export const destroyDeleteData = ({ constants }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_ASK_DELETE_DATA,
      payload: { deleteData: null },
    });
  };
};

export const post = ({
  url,
  constants,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  asyncPreProcess,
  uploadCallback,
  unauthenticatedCall = false,
  updateStateCallback,
  preventDefaultToast,
  forceData,
}) => {
  let data = forceData || store.getState()[constants.STORE_NAME].formInstance;
  return async (dispatch) => {
    try {
      if (preProcess) {
        data = preProcess(data);
      }

      if (asyncPreProcess) {
        data = await asyncPreProcess(data);
      }
      const result = await upload({ url: checkTrailingUrlSlash(url), data });
      let returnedData = result?.data;

      // handle uploads if there are any
      if (uploadCallback) {
        await uploadCallback(result?.data, dispatch);
      }

      // handle clearing and adding of data
      updateStateCallback &&
        updateStateCallback({
          sentData: data,
          receivedData: result?.data,
          dispatch,
        });

      // destroy form
      dispatch(destroyForm({ constants, method: "POST", success: true }));

      // check if postProcess
      if (processSuccess) {
        returnedData = processSuccess(returnedData);
      }

      // insert into state
      dispatch({
        type: constants.INSERT_INTO_ALL,
        payload: { result: returnedData },
      });

      // check if callback
      if (successCallback) {
        successCallback(data, returnedData);
      }
      // fetch new overview data
      if (!unauthenticatedCall) {
        dispatch(getSummarizedStatistics());
      }

      if (!preventDefaultToast) {
        dispatch(
          addToast({
            type: TOAST_TYPES.SUCCESS,
            title: "Skapandet lyckades",
          })
        );
      }
    } catch (error) {
      console.log({ error });
      let returnedData = error?.response?.data;
      if (processError) {
        returnedData = processError(returnedData);
      }

      dispatch({
        type: constants.SET_FORM_ERROR,
        payload: { result: returnedData },
      });

      // check if callback
      if (errorCallback) {
        errorCallback(data, returnedData, error);
      }

      if (!preventDefaultToast) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Skapandet misslyckades",
          })
        );
      }
    }
  };
};

export const patch = ({
  url,
  constants,
  preProcess,
  asyncPreProcess,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  uploadCallback,
  unauthenticatedCall = false,
  updateStateCallback,
  forceData, // if forminstance isn't used, we can pass the specific data to send as forceData
  preventDefaultToast,
}) => {
  let data = forceData || store.getState()[constants.STORE_NAME].formInstance;
  return async (dispatch) => {
    try {
      if (preProcess) {
        data = preProcess(data);
      }

      if (asyncPreProcess) {
        data = await asyncPreProcess(data);
      }
      const result = await update({ url: checkTrailingUrlSlash(url), data });
      let returnedData = result.data;

      // handle uploads if there are any
      if (uploadCallback) {
        await uploadCallback(result.data, dispatch);
      }

      // handle clearing and adding of data
      updateStateCallback &&
        updateStateCallback({
          sentData: data,
          receivedData: result?.data,
          dispatch,
        });

      // destroy form
      dispatch(destroyForm({ constants, method: "PATCH", success: true }));

      // check if postProcess
      if (processSuccess) {
        returnedData = processSuccess(returnedData);
      }

      // insert into state
      dispatch({
        type: constants.INSERT_INTO_ALL,
        payload: { result: returnedData },
      });

      // check if callback
      if (successCallback) {
        successCallback(data, returnedData);
      }

      if (!preventDefaultToast) {
        dispatch(
          addToast({
            type: TOAST_TYPES.SUCCESS,
            title: "Uppdateringen lyckades",
          })
        );
      }

      // fetch new overview data

      if (!unauthenticatedCall) {
        dispatch(getSummarizedStatistics());
      }
    } catch (error) {
      console.log({ error });
      let returnedData = error?.response?.data;
      if (processError) {
        returnedData = processError(returnedData);
      }

      dispatch({
        type: constants.SET_FORM_ERROR,
        payload: { result: returnedData },
      });

      // check if callback
      if (errorCallback) {
        errorCallback(data, returnedData);
      }

      if (!preventDefaultToast) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Uppdateringen misslyckades",
          })
        );
      }
    }
  };
};

export const put = ({
  url,
  constants,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  uploadCallback,
  unauthenticatedCall = false,
  updateStateCallback,
  asyncPreProcess,
}) => {
  let data = store.getState()[constants.STORE_NAME].formInstance;
  return async (dispatch) => {
    try {
      if (preProcess) {
        data = preProcess(data);
      }

      if (asyncPreProcess) {
        data = await asyncPreProcess(data);
      }

      const result = await putUpdate({ url: checkTrailingUrlSlash(url), data });
      let returnedData = result.data;

      // handle uploads if there are any
      if (uploadCallback) {
        await uploadCallback(result.data, dispatch);
      }
      // handle clearing and adding of data
      updateStateCallback &&
        updateStateCallback({
          sentData: data,
          receivedData: result?.data,
          dispatch,
        });

      // destroy form
      dispatch(destroyForm({ constants, method: "PUT" }));

      // check if postProcess
      if (processSuccess) {
        returnedData = processSuccess(returnedData);
      }

      // insert into state
      // dispatch({
      //   type: constants.INSERT_INTO_ALL,
      //   payload: { result: returnedData },
      // });

      // check if callback
      if (successCallback) {
        successCallback(data, returnedData);
      }

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Uppdateringen lyckades",
        })
      );

      // fetch new overview data
      if (!unauthenticatedCall) {
        dispatch(getSummarizedStatistics());
      }
    } catch (error) {
      let returnedData = error?.response?.data;
      if (processError) {
        returnedData = processError(returnedData);
      }

      dispatch({
        type: constants.SET_FORM_ERROR,
        payload: { result: returnedData },
      });

      // check if callback
      if (errorCallback) {
        errorCallback(data, returnedData);
      }

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Uppdateringen misslyckades",
        })
      );
    }
  };
};

export const deleteObject = ({
  instance,
  constants,
  successCallback,
  errorCallback,
  unauthenticatedCall = false,
  skipDestroyDeleteData = false,
  noToast = false,
}) => {
  return async (dispatch) => {
    const url = `${constants.GET_URL}`;
    try {
      const result = await deleteData({
        url: checkTrailingUrlSlash(url),
        id: instance.id,
      });
      let returnedData = result?.data;

      window.dispatchEvent(
        new CustomEvent("TABLE_SOCKET_CHANGES", {
          bubbles: true,
          detail: { storeName: constants?.STORE_NAME, clearItems: true },
        })
      );

      // destroy delete data
      if (!skipDestroyDeleteData) {
        dispatch(destroyDeleteData({ constants }));
      }

      // remove from state
      if (constants.REMOVE_OBJECT) {
        dispatch({
          type: constants.REMOVE_OBJECT,
          payload: { id: instance.id },
        });
      }

      // check if callback
      if (successCallback) {
        successCallback(returnedData);
      }

      if (!noToast) {
        dispatch(
          addToast({
            type: TOAST_TYPES.SUCCESS,
            title: "Raderingen lyckades",
          })
        );
      }

      // fetch new overview data
      if (!unauthenticatedCall) {
        dispatch(getSummarizedStatistics());
      }
    } catch (error) {
      let returnedData = error?.response?.data;

      // check if callback
      if (errorCallback) {
        errorCallback(returnedData);
      }

      if (!noToast) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Raderingen misslyckades",
          })
        );
      }
    }
  };
};

export const getAskDeleteData = ({ instance, constants }) => {
  return async (dispatch) => {
    const { data: deleteData } = await fetchAskDeleteData({
      url: constants.GET_URL,
      id: instance.id,
    });

    const structuredDeleteData = structureDeleteData(deleteData);

    dispatch({
      type: constants.SET_ASK_DELETE_DATA,
      payload: {
        deleteData: structuredDeleteData,
      },
    });
  };
};

export const clearFetched = (constants, clearAll = false) => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_FETCHED,
      payload: { clearAll },
    });
  };
};

export const removeObject = ({ constants, objectId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_OBJECT,
      payload: { id: objectId },
    });
  };
};

export const collectPermissions = ({ userId }) => {
  return async (dispatch) => {
    dispatch({
      type: appConstants.SET_LOADING_PERMS,
      payload: { loadingPerms: true },
    });

    const result = await retrieve({
      url: `/accounts/users/user/${userId}/permissions/`,
    });
    let data = result.data;

    dispatch({
      type: appConstants.SET_PERMS,
      payload: { permissions: data },
    });
  };
};
