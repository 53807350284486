import * as React from "react";
import { DetailLayoutWrapper } from "../../components/sharedStyles";

import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import VacancyRecordOverview from "./vacancyDetail/Overview";

export default function MainVacancyRecordDetail() {
  const { path, url } = useRouteMatch();

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Vakansbokningslogg",
      component: VacancyRecordOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Vakansbokningslogg`}
          breadCrumbs={[
            {
              url: "/rental-notifications/failed-generations?tab=VACANCIES",
              label: "Misslyckade vakansbokningar",
            },
            { label: "Vakansbokningslogg" },
          ]}
          {...{ subPages: subPageLinks }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
