import * as React from "react";

import SelectFilter from "../../../Lists/Base/CompleteList/Filters/SelectFilter"

export default ({column}) => {

    const choices = [
        {value:"inv_created", display:"Faktura skapad"},
        {value:"inv_fully_payed", display:"Faktura fullständigt betalad"},
        {value:"inv_sale_accepted", display:"Fakturaförsäljning accepterad"},
        {value:"inv_sale_denied", display:"Fakturaförsäljning nekad"},
        {value:"inv_attested", display:"Faktura attesterad"},
        {value:"inv_payment", display:"Faktura betald"},
        {value:"inv_undelivered", display:"Faktura ej levererad"},
        {value:"inv_payment_unmatched", display:"Ej matchad inbetalning"},
        {value:"inv_overpayed", display:"Överbetalad faktura"},
        {value:"inv_sent", display:"Skickad faktura"},
        {value:"autogiro_approve_failed", display:"Autogiro godkänd"},
        {value:"autogiro_changed", display:"Autogiro förändrad"},
        {value:"autogiro_payment_failed", display:"Misslyckad autogiro-dragning"},
        {value:"autogiro_payment_failed_retry", display:"Misslyckad autogiro-dragning (återförsök)"}
    ]

    return <SelectFilter choices={choices} column={column} disablePortal={true}/>
}