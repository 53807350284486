import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton, PopupButton } from "../../../components/Forms/Base/Buttons";


import { useAllPermissionCheck } from "../../../store/base";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { axiosInstance } from "../../../store/base/store/axios";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useDispatch } from "react-redux";
import NonConnectedSelect from "../../../components/Forms/Base/Old/NonConnected/NonConnectedSelect";

export default function FastDocIntegration() {
  const dispatch = useDispatch();

  const current = INTEGRATIONS.find(
    (i) => i.title === "Fastighetsägarna Dokument"
  );

  const customerHasFastDoc = useAllPermissionCheck(["allow_fastdoc", "view_can_fastdoc"]);
  const canChangeFastdoc = useAllPermissionCheck(["allow_fastdoc", "change_can_fastdoc"]);
  const canDeleteFastdoc = useAllPermissionCheck(["allow_fastdoc", "delete_can_fastdoc"]);


    const [fastdocActivated, setFastdocActivated] = React.useState(undefined);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [deletionOpen, setDeletionOpen] = React.useState(false);

    const [token, setToken] = React.useState(undefined);
    const [fastdocOrgId, setFastdocOrgId] = React.useState(undefined);

    const [fastdocOrgIdChoices, setFastdocOrgIdChoices] = React.useState([]);

    const performFastdocChoicesCollection = async () => {
      try {
        const res = await axiosInstance.get(`external/fastdoc/premature_organization_ids/?api_key=${token}`);
        setFastdocOrgIdChoices(res.data ?? []);
      } catch (error) {
        dispatch(addToast({
          title: "Kunde inte hämta organisationer",
          type: TOAST_TYPES.ERROR
        }))
      }
    }

    // <
    React.useEffect(() => {
      checkHasActivated();
    }, []);
  
    const clearInput = () => {
      setToken(undefined);
      setFastdocOrgId(undefined);
    };
  
    const performModalSave = async () => {
      setModalOpen(false);
  
      try {
        if (fastdocActivated) {
          await axiosInstance.put("external/fastdoc/handle/", {
            api_key: token,
            fastdoc_organization_id: fastdocOrgId,
          });
        } else {
          await axiosInstance.post("external/fastdoc/handle/", {
            api_key: token,
            fastdoc_organization_id: fastdocOrgId,
          });
        }
        dispatch(
          addToast({
            title: fastdocActivated
              ? "Fastighetsägarana Dokument-informationen har uppdaterats"
              : "Fastighetsägarana Dokument-integrationen har aktiverats",
            type: TOAST_TYPES.SUCCESS,
          })
        );
  
        if (!fastdocActivated) {
          setFastdocActivated(true);
        }
      } catch (err) {
        dispatch(
          addToast({
            title: fastdocActivated
              ? "Kunde inte uppdatera Fastighetsägarana Dokument-informationen"
              : "Kunde inte aktivera Fastighetsägarana Dokument-integrationen",
            type: TOAST_TYPES.ERROR,
          })
        );
      }
  
      clearInput();
    };
  
    const performDeletion = async () => {
      try {
        await axiosInstance.delete("external/fastdoc/handle/");
        dispatch(
          addToast({
            title: "Fastighetsägarana Dokument-integrationen har tagits bort",
            type: TOAST_TYPES.SUCCESS,
          })
        );
        setFastdocActivated(false);
      } catch (error) {
        dispatch(
          addToast({
            title: "Kunde inte ta bort Fastighetsägarana Dokument-integrationen",
            type: TOAST_TYPES.ERROR,
          })
        );
      }
  
      setDeletionOpen(false);
    };
  
    const performModalClose = () => {
      setModalOpen(false);
  
      clearInput();
    };
  
    const fastdocActions = [
      {
        name: "Uppdatera information",
        onClick: () => setModalOpen(true),
        hidden: fastdocActivated !== true || !canChangeFastdoc,
      },
      {
        name: "Aktivera integration",
        onClick: () => setModalOpen(true),
        hidden: fastdocActivated !== false,
      },
      {
        name: "Ta bort integration",
        onClick: () => setDeletionOpen(true),
        hidden: fastdocActivated !== true || !canDeleteFastdoc,
      },
    ];

    const checkHasActivated = async () => {
      // undeinfed is not collected - null is bad collection
      // true false is the result
      if (fastdocActivated !== undefined) {
        return fastdocActivated;
      }
  
      try {
        const result = await axiosInstance.get("external/fastdoc/check_activated/");
        const activated = result?.data?.is_activated ?? null;
        setFastdocActivated(activated);
      } catch (err) {
        setFastdocActivated(null);
      }
    };

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
    <ConfirmationModal
        isOpen={deletionOpen}
        closeFunction={() => setDeletionOpen(false)}
        acceptCallback={performDeletion}
        acceptTitle={"Radera"}
        renderContent={() => (
          <div>Är du säker på att du vill ta bort integrationen?</div>
        )}
      />
      <StandardModal
        isOpen={modalOpen}
        saveFunction={performModalSave}
        closeFunction={performModalClose}
        title={
          fastdocActivated
            ? "Uppdatera Fastighetsägarna Dokument Information"
            : "Aktivera Fastighetsägarna Dokument"
        }
        canAccept={
          token != null &&
          token !== "" &&
          fastdocOrgId != null &&
          fastdocOrgId != ""
        }
        withActionBar
      >
        <NonConnectedTextInput
          id="token"
          value={token}
          onChange={setToken}
          label={"API-token"}
        />

        <TextButton
          title={"Hämta tillgängliga organisationer"}
          clicked={performFastdocChoicesCollection}
          disabled={!token}
          iconType={"download"}
        />
        <NonConnectedSelect
          id="fastdoc_organization_id"
          value={fastdocOrgId}
          onUpdate={setFastdocOrgId}
          label={"Organisation hos fastighetsägarna dokument"}
          choices={fastdocOrgIdChoices}
          getOptionLabel={(item) => item.d}
          getOptionValue={(item) => item.v}
        />
      </StandardModal>

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/configcenter/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
          config={true}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Fastighetsägarnas hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Fastighetsägarna-integrationen aktiveras globalt och tillåter
                  alla användare i systemet att använda mallar i Pigellos
                  avtalsflöden.
                  <br />
                  <br />
                  Så fungerar integrationen:
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Välj manuell eller digital signering som signeringsmetod på
                  ett avtal eller dokument.
                  <br />
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Vid val av dokument för signering, autentisera mot FastDok för
                  att låsa upp FastDok-mallarna.
                  <br />
                  <br />
                  <strong>Steg 3:</strong>
                  <br />
                  Välj den mall du vill baseras avtalet på, så hämtas den
                  automatiskt in från Fastighetsägarna. Vissa mallar kostar
                  extra att använda och kommer att hämtas in som "Utkast".
                  <br />
                  <br />
                  <strong>Steg 4:</strong>
                  <br />
                  Om mallen medför en kostnad så måste den betalas för att ta
                  bort vattenstämpeln. Detta görs enkelt via "Betala
                  mall"-knappen direkt i Pigello.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              {customerHasFastDoc !== true ? (
                <>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Aktivera integration</OverviewTitle>
                      <OverviewSubtitle>
                        Fastighetsägarna-integrationen aktiveras av Pigello på
                        begäran. Kontakta oss för att veta mer.
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>
                  <TextButton
                    title="Kontakta oss"
                    iconType="arrow"
                    iconPlacement="right"
                    clicked={() => window.Intercom("show")}
                  />
                </>
              ) : (
                <>
                  <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Hantera integration</OverviewTitle>
                    <OverviewSubtitle>
                      {fastdocActivated === true ? (
                        <div>Denna integrationen är aktiverad</div>
                      ) : fastdocActivated === false ? (
                        <div>Denna integrationen är inte aktiverad</div>
                      ) : (
                        <div>
                          {fastdocActivated === undefined
                            ? "Laddar..."
                            : "Kunde ej hämta informationen som behövs, behörighet saknas"}
                        </div>
                      )}
                    </OverviewSubtitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                {fastdocActions?.length > 0 && (
                  <div>
                    <PopupButton
                      leftAligned
                      title="Hantera"
                      actions={fastdocActions}
                    />
                  </div>
                )}
                </>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
