export const constructStrRep = (obj) => {
  return obj.str_representation || "Nyckelbehörighet";
};
export const detailUrl = ({ id }) => {
  return `/keys/keypermissions/detail/${id}`;
};
export const overviewUrl = () => {
  return `/keys/keypermissions`;
};
export const createUrl = () => {
  return `/keys/keypermissions/create`;
};
export const updateUrl = ({id}) => {
  return `/keys/keypermissions/update/${id}`;
};
