import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useHistory, useParams } from "react-router";
import { useFilteredCompanies } from "../../../store/companies";
import { constants } from "../../../store/invoicingSummary";
import ContractInvoicingTable from "../../../components/Billecta/Invoices/ContractInvoicingTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { axiosInstance } from "../../../store/base/store/axios";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

const redirectMap = {
  "accounting.leaseinvoicing": "invoicinglease",
  "accounting.otherinvoicing": "invoicingother",
  "accounting.parkinginvoicing": "invoicingparking",
  "accounting.brfinvoicing": "invoicingbrf",
};

function InvoicingContracts() {
  const dispatch = useDispatch();
  const { creditorId } = useParams();
  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const { push } = useHistory();
  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const [invoicings, setInvoicings] = React.useState();
  const [invoicingsLoading, setInvoicingsLoading] = React.useState(false);

  const getData = async () => {
    setInvoicingsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${constants.LIST_URL}?company=${company.id}`,
        {
          responseType: "application/json",
        }
      );
      setInvoicingsLoading(false);

      if (response.status === 200) {
        setInvoicings(response.data);
      } else {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde ej ladda in aviserade avtal",
          })
        );
      }
    } catch (e) {
      setInvoicingsLoading(false);

      console.error(e);
    }
  };
  React.useEffect(() => {
    if (company && !invoicings && !invoicingsLoading) getData();
  }, [company]);

  const goToContract = ({ original }) => {
    const contentType = redirectMap[original.content_type];

    push(`/error-redirect/${contentType}/${original.id}`);
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Avtal/objekt som aviseras under detta bolag
            </OverviewTitle>
            <OverviewSubtitle>
              Använd filterknapparna för att visa översikt för en viss typ av
              avtal eller status
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {invoicingsLoading || isLoadingCompany ? (
          <InnerBox style={{ minHeight: 300 }}>
            <OverlaySpinner />
          </InnerBox>
        ) : !invoicings?.length ? (
          <InnerBox>Inga avtal att avisera för hittades</InnerBox>
        ) : (
          <ContractInvoicingTable
            handleRowClicked={goToContract}
            invoices={invoicings || []}
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default InvoicingContracts;
