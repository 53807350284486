import * as React from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToast, TOAST_TYPES } from "../../store/toasts";

import { axiosInstance } from "../../store/base/store/axios";

export default () => {
  const data = useLocation();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleActivation = async (locationData) => {
    const search = locationData?.search;
    if (!search) {
      dispatch(
        addToast({
          title: "Något gick fel",
          description: "Kunde ej initiera outlook kopplingen",
          type: TOAST_TYPES.ERROR,
        })
      );
      push("/calendar");
      return;
    } else {
      const url = `events/outlook/finalize_authentication/${search}`;
      try {
        await axiosInstance.get(url);
        dispatch(
          addToast({
            title: "Koppling genomförd",
            description: "Lyckades genomföra kopplingen till outlook",
            type: TOAST_TYPES.SUCCESS,
          })
        );
      } catch (err) {
        dispatch(
          addToast({
            title: "Något gick fel",
            description: "Kunde ej initiera outlook kopplingen",
            type: TOAST_TYPES.ERROR,
          })
        );
      }
      push("/calendar");
    }
  };

  React.useEffect(() => {
    handleActivation(data);
  }, [data]);

  return null;
};
