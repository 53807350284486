import * as React from "react";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import DocsList from "src/components/Tables/OtherContracts/FullTableNewVersionDocs";
import { detailUrl } from "src/store/otherContracts";
import { useHistory } from "react-router-dom";
export default function OtherContractDocuments() {
  const { filteredRealEstates } = useActiveFilters();
  const { push } = useHistory();

  const persistantQuery = {
    has_scrive: true,
    is_template: false,
    is_other: true,
    realestate__in: filteredRealEstates,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Digital signering av övriga avtal</OverviewTitle>
          </OverviewTitleWrapper>

          <DocsList
            onRowClicked={(obj) =>
              push(detailUrl({ id: obj.other_contract?.id }))
            }
            persistantQuery={persistantQuery}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
