import { uploadMultiBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];

    // attachments
    const attachments = formInstance?.images || [];
    const responseAttachments = responseData?.images || [];

    if (attachments.length === responseAttachments.length) {
      responseAttachments.forEach((a, index) => {
        const attachment = attachments[index];
        const url = a?.image?.put;
        const file = attachment?._tempData?.data;

        if (file && url) {
          urls.push(url);
          files.push(file);
        }
      });
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );

    return;
  }
};
