import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewTitleWrapper,
  OverviewTitle,
} from "../../components/Details/OverviewInfo/styles";
import {
  BareLayoutTitleWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";

import PageTitle from "../Layouts/PageTitle/PageTitle";

import { useRealEstatePortfolio } from "../../store/realEstatePortfolios";
import PortfolioForm from "../../components/Forms/RealEstatePortfolio/RealEstatePortfolioForm";
import * as SharedStyles from "../../components/sharedStyles";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

export default function PortfolioDetail() {
  const [editOpen, setEditOpen] = React.useState(false);
  const { portfolioId } = useParams();
  const [portfolio] = useRealEstatePortfolio(portfolioId);
  const breadCrumbs = [
    {
      label: "Portföljer",
      url: "/portfolios",
    },
    {
      label: portfolio?.title || "Laddar...",
    },
  ];

  const realEstateQuery = {
    id__in: portfolio?.realestates?.map((r) => r.id) || [],
  };

  return (
    <>
      <PortfolioForm
        method="PATCH"
        open={editOpen}
        portfolioId={portfolioId}
        onCheckout={() => setEditOpen(false)}
      />

      <SharedStyles.DetailInnerWrapper>
        <BareLayoutTitleWrapper>
          <PageTitle
            title={portfolio?.title || "Laddar..."}
            breadCrumbs={breadCrumbs}
          />
        </BareLayoutTitleWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>{portfolio?.title || "Laddar..."}</OverviewTitle>

            <PrimaryBtn onClick={() => setEditOpen(true)}>Redigera</PrimaryBtn>
          </OverviewTitleWrapper>

          <SharedStyles.InnerBox className="mt-0">
            <OverviewTitleWrapper>
              <OverviewTitle small>Fastigheter i portfölj</OverviewTitle>
            </OverviewTitleWrapper>

            <RealEstateTable
              persistantQuery={realEstateQuery}
              ignoreLocalStorage
            />
          </SharedStyles.InnerBox>
        </DetailPageBox>
      </SharedStyles.DetailInnerWrapper>
    </>
  );
}
