import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import DigitalDocContainer from "../../components/DigitalDoc/Container";
import {
  fastDocTemplateName,
  privateFastDocTemplateName,
} from "../../components/DigitalDoc/TemplateSelector/TemplateContainer";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import {
  create,
  destroyPostForm,
  detailUrl,
  performDocAndFastDocValidation,
} from "../../store/editabledocs";
import {
  handleDocError,
  performPageFieldsValidation,
} from "../../store/editabledocs/utils";

export default function CreateTemplate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const { push } = useHistory();

  const successCallback = (_, returned) => {
    setLoading(false);

    dispatch(destroyPostForm(true));
    push(detailUrl({ id: returned.id }));
  };

  const errorCallback = (data, returnedData) => {
    setLoading(false);
    handleDocError(data, returnedData);
  };

  const preProcess = (data) => {
    let clone = performPageFieldsValidation(
      performDocAndFastDocValidation(cloneDeep(data))
    );

    clone.is_template = true;

    const chosenTemplate = clone._chosenTemplate;
    const isFastDocTemplate = [
      fastDocTemplateName,
      privateFastDocTemplateName,
    ].includes(chosenTemplate?.name);
    const fastdocId = chosenTemplate?.id;

    if (!isFastDocTemplate || !fastdocId) {
      return clone;
    }
    clone.fastdoc_id = fastdocId;
    delete clone.docData_;

    performPageFieldsValidation(clone);

    return clone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      create({
        preProcess,
        successCallback,
        errorCallback,
        preventDefaultToast: true,
      })
    );
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      <DigitalDocContainer method="POST" onSubmit={onSubmit} isTemplateEditor />
    </>
  );
}
