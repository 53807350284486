import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../store/calendarEvents";

export default function TodosTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const columns = React.useMemo(() => columnDefs(), []);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "todos_full_table"}
      title={"Att göra"}
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
