import * as React from "react";
import * as SC from "./styles";
import { TextButton } from "../../Forms/Base/Buttons";

export default function Ad({
  ad,
  topLeadObj,
  onClick,
  onLeadClicked,
  isNoInterestStage,
  onCreateLead,
  isHomeQPipe,
}) {
  const [allLeadsOpen, setAllLeadsOpen] = React.useState(false);

  const topLeadId = topLeadObj?.id;
  const topLead = ad.leads?.find((l) => l.id === topLeadId);

  return (
    <SC.LeadBox
      onClick={onClick}
      style={{ maxWidth: "100%", cursor: "pointer" }}
    >
      <SC.LeadHeader>
        <SC.LeadDataWrapper>
          <SC.LeadName>
            {ad.apartment?.id && (
              <TextButton
                clicked={onClick}
                title={ad.apartment.str_representation}
              />
            )}
            {ad.industrial_premises?.id && (
              <TextButton
                clicked={onClick}
                title={ad.industrial_premises.str_representation}
              />
            )}
            {ad.parking_spot?.id && (
              <TextButton
                clicked={onClick}
                title={ad.parking_spot.str_representation}
              />
            )}
          </SC.LeadName>

          <SC.LeadName
            style={{
              fontSize: 12,
              lineHeight: "110%",
              marginBottom: 0,
            }}
          >
            Publicering:<strong> {ad.date_publish}</strong>
          </SC.LeadName>
          <SC.LeadName
            style={{
              fontSize: 12,
              lineHeight: "110%",
              marginBottom: 0,
            }}
          >
            Planerad inflytt: <strong>{ad.access_date}</strong>
          </SC.LeadName>
          <SC.LeadName
            style={{
              fontSize: 12,
              lineHeight: "110%",
              marginBottom: 0,
            }}
          >
            Antal leads:<strong> {ad.leads?.length || "Inga leads"}</strong>
          </SC.LeadName>
        </SC.LeadDataWrapper>
      </SC.LeadHeader>
      {isNoInterestStage ? (
        <SC.LeadFooter
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          {!isHomeQPipe && (
            <TextButton
              extraStyle={{ fontSize: 12, marginTop: 4 }}
              title="Lägg till lead"
              iconType="add"
              iconPlacement="right"
              clicked={() => {
                const objKind = ad.apartment
                  ? "apartment"
                  : ad.industrial_premises
                  ? "industrial_premises"
                  : "parking_spot";
                const objId = ad[objKind]?.id;
                onCreateLead({ objKind, objId });
              }}
            />
          )}
        </SC.LeadFooter>
      ) : (
        <SC.LeadFooter
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          {topLead && (
            <TextButton
              extraStyle={{ fontSize: 12 }}
              title={`Topplead: ${topLead.str_representation}${
                topLead?.together_with
                  ? ` ( + ${topLead?.together_with?.str_representation})`
                  : ""
              }`}
              clicked={() => onLeadClicked(topLead.id)}
            />
          )}

          {topLead && topLead.confirmed ? (
            <SC.LeadConfirmedWrapper>
              <SC.LeadConfirmed />{" "}
              <SC.LeadName style={{ fontSize: 12 }}>Bekräftad</SC.LeadName>
            </SC.LeadConfirmedWrapper>
          ) : (
            <>
              {ad.leads?.length - 1 > 0 ? (
                <TextButton
                  extraStyle={{ marginTop: 4, fontSize: 12 }}
                  title={
                    allLeadsOpen ? "Dölj övriga leads" : "Visa övriga leads"
                  }
                  clicked={
                    allLeadsOpen
                      ? () => setAllLeadsOpen(false)
                      : () => setAllLeadsOpen(true)
                  }
                  iconType="add"
                  iconPlacement="right"
                />
              ) : (
                <></>
              )}
            </>
          )}
        </SC.LeadFooter>
      )}

      {allLeadsOpen && (
        <SC.LeadFooter
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <hr
            style={{
              margin: "4px 0",
              backgroundColor: "rgba(0,0,0,0.04)",
              width: "100%",
            }}
          />
          {ad.leads
            ?.filter((l) => l.id !== topLead?.id)
            ?.map((l) => (
              <div key={l.id}>
                <TextButton
                  extraStyle={{ fontSize: 12 }}
                  title={l.str_representation}
                  clicked={() => onLeadClicked(l.id)}
                />
              </div>
            ))}
        </SC.LeadFooter>
      )}
    </SC.LeadBox>
  );
}
