import * as React from "react";
import { useFormInstanceField } from "../../../store/base";
import Alert from "../../Alert/Alert";
import { FIELD_KIND_CHOICES, FIELD_TYPES } from "../../DigitalDoc/utils";
import LocalCheckField from "../../Forms/Base/Fields/LocalCheckField";
import LocalSelectField from "../../Forms/Base/Fields/LocalSelectField";
import LocalTextInputField from "../../Forms/Base/Fields/LocalTextInputField";
import Modal from "../../Forms/Base/Modals/Modal";

export default function DigitalDocHandleAddField({
  storeName,
  closeFunction,
  partyIdx,
  partyName,
  onFieldAdded,
  isTemplateEditor,
}) {
  const [fieldName, setFieldName] = React.useState("");
  const [fieldValue, setFieldValue] = React.useState("");
  const [fieldKind, setFieldKind] = React.useState(null);
  const [fieldObligatory, setFieldObligatory] = React.useState(false);
  const [fieldFilledBySender, setFieldFilledBySender] = React.useState(false);

  const parties = useFormInstanceField({
    storeName,
    fieldKey: "parties",
  });
  const currentParty = parties?.[partyIdx];

  const fieldWithMatchingName =
    fieldName && currentParty.fields?.find((f) => f.name === fieldName);

  // reset field value when switching kinds
  React.useEffect(() => {
    setFieldValue("");
  }, [fieldKind]);

  return (
    <Modal
      title="Skapa nytt fält"
      closeFunction={closeFunction}
      onAccept={() => {
        onFieldAdded({
          name: fieldName,
          [fieldKind === FIELD_TYPES.TEXT ? "value" : "is_checked"]: fieldValue,
          kind: fieldKind,
          is_obligatory: fieldObligatory,
          should_be_filled_by_sender: fieldFilledBySender,
        });

        closeFunction();
      }}
      acceptTitle={
        fieldWithMatchingName ? "Lägg till placering" : "Lägg till nytt fält"
      }
      canAccept={fieldName && fieldKind != null}
    >
      <Alert title={`Lägg till fält på ${partyName}`}>
        Ange inställningar för fältet och spara för att lägga till fältet på
        dokumentet. Observera att om namnet är samma som ett på parten redan
        existerande fält så skapas inget nytt fält utan enbart en till placering
        av fältet.
      </Alert>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalSelectField
          title="Typ av fält"
          description="Vilken fälttyp som avses."
          value={fieldKind}
          onChange={(val) => setFieldKind(val)}
          choices={FIELD_KIND_CHOICES}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTextInputField
          title="Namn på fält"
          description="Unik identifierare för fältet."
          value={fieldName}
          onChange={(val) => setFieldName(val)}
        />

        {fieldKind === FIELD_TYPES.TEXT && (
          <LocalTextInputField
            title="Värde på fält"
            description="Ange vad fältet ska innehålla."
            value={fieldValue}
            onChange={(val) => setFieldValue(val)}
          />
        )}
      </div>

      {fieldKind === FIELD_TYPES.CHECKBOX && (
        <LocalCheckField
          id="fieldval"
          title="Värde på fält"
          description="Ange om fältet ska vara ibockat."
          value={fieldValue}
          onChange={(val) => setFieldValue(val)}
        />
      )}

      <div className="mb-6">
        <LocalCheckField
          id="newfieldobligatory"
          title="Fältet kräver ifyllning innan signering"
          description="Ange om fältet kräver ifyllning innan dokumentet kan signeras (OBS: Gäller enbart Scrive)."
          value={fieldObligatory}
          onChange={(val) => setFieldObligatory(val)}
        />
      </div>

      <div className="mb-6">
        <LocalCheckField
          id="newfieldfilledbysender"
          title="Fältet ska fyllas i av avsändare"
          description="Ange om fältet fylls i av avsändare och därmed ej får redigeras av mottagare."
          value={fieldFilledBySender}
          onChange={(val) => setFieldFilledBySender(val)}
        />
      </div>
    </Modal>
  );
}
