import * as React from "react";

// style, design

import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/realEstates";
import { useDispatch } from "react-redux";
import { setActiveFilteredRealEstates } from "../../../store/overview/store/actions";
import { useFilteredBuildings } from "../../../store/buildings";
import { isEqual } from "lodash";
import { useHistory } from "react-router";
import { useSummarizedStatistics } from "../../../store/overview";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function RealEstateTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  withSelect,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [selected, setSelected] = React.useState([]);

  const [buildings] = useFilteredBuildings("");
  const [rawStats, isLoadingStats] = useSummarizedStatistics();
  const buildingsRef = React.useRef(buildings);

  React.useEffect(() => {
    if (isEqual(buildingsRef.current, buildings)) return;

    buildingsRef.current = buildings;
  }, [buildings, persistantQuery]);

  const columns = React.useMemo(() => {
    const data = columnDefs(buildings, rawStats);
    return data;
  }, [buildingsRef.current, persistantQuery, rawStats]);

  const setFilter = () => {
    dispatch(
      setActiveFilteredRealEstates({
        realEstateIds: selected || [],
      })
    );
    push("/");
  };

  const filters = {
    Beteckning: {
      queryKey: "key",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  return (
    <>
      <BaseTable
        tableId={tableId || "realestate_full_table"}
        title={"Fastigheter"}
        onRowClickedWithDetail={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected: withSelect ? (obj) => setSelected(obj) : undefined,
        }}
      />
      {selected?.length > 0 && withSelect && (
        <div className="fixed bottom-0 left-0 right-0 px-24 py-2 bg-white border-t border-solid border-slate-100 shadow-sm flex justify-end">
          <PrimaryBtn highlight onClick={setFilter}>
            Filtrera systemet på {selected?.length} fastigheter
          </PrimaryBtn>
        </div>
      )}
    </>
  );
}
