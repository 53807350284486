import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants, performSearch } from "../../../store/globalSearch";
import { debounce } from "lodash";

import * as SC from "./styles";
import { clearResults } from "../../../store/globalSearch/store/actions";
import { useHistory } from "react-router";
import { KEY_MAP, URL_MAP, HIGHLIGHT_MAP } from "./searchUtils";
import useActiveFilters from "../../../hooks/useActiveFilters";

export default ({ openBlankWindow, sideBarOpen = true }) => {
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [searchHistory, setSearchHistory] = React.useState([]);

  const { resetFilters } = useActiveFilters();

  const results = useSelector((state) => state[storeName].results);

  React.useEffect(() => {
    const history =
      JSON.parse(localStorage.getItem("main_search_history")) || [];

    setSearchHistory(history);
  }, []);

  const goTo = (url) => {
    resetFilters();
    if (openBlankWindow) {
      window.open(url, "_blank").focus();
    } else {
      push(url);
    }
  };

  const addSearchToHistory = ({ hit, key }) => {
    const history =
      JSON.parse(localStorage.getItem("main_search_history")) || [];

    history.unshift({ hit, key });

    if (history.length > 5) {
      history.pop();
    }

    setSearchHistory(history);

    localStorage.setItem("main_search_history", JSON.stringify(history));
  };

  React.useEffect(() => {
    if (results) {
      setHistoryOpen(false);
    }
  }, [results]);

  const search = (query) => {
    setLoading(true);
    dispatch(
      performSearch({
        query,
        successCallback: () => setLoading(false),
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  const clearSearch = () => {
    setSearchValue("");
    dispatch(clearResults());
  };

  const clearSearchHistory = () => {
    setSearchHistory([]);
    localStorage.setItem("main_search_history", JSON.stringify([]));
  };

  const debouncedSearch = React.useCallback(debounce(search, 300), []);

  React.useEffect(() => {
    if (searchValue.length > 1) {
      debouncedSearch(searchValue);
    } else {
      dispatch(clearResults());
    }
  }, [searchValue]);

  return (
    <SC.SearchBarContainer style={{ maxWidth: 500 }}>
      <SC.SearchIcon />
      <SC.SearchBar
        style={{ zIndex: results ? 1000 : 1 }}
        value={searchValue}
        onFocus={() => setHistoryOpen(true)}
        onChange={({ target: { value } }) => setSearchValue(value)}
      />
      {loading && (
        <SC.SpinningCircleContainer>
          <SC.SpinningCircle
            cx="12"
            cy="12"
            r="10"
            fill="none"
            stroke="#93bfec"
            strokeWidth="3"
          />
        </SC.SpinningCircleContainer>
      )}
      <SC.HistoryIcon onClick={() => setHistoryOpen(true)} />

      {historyOpen && (
        <>
          <SC.ResultsOverlay onClick={() => setHistoryOpen(false)} />
          <SC.HistoryContainer>
            <SC.ResultsTitleBar>
              <SC.ResultsTitleWrapper>
                <SC.ResultsTitle>Historik</SC.ResultsTitle>
                <SC.ResultsSubTitle
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={clearSearchHistory}
                >
                  Rensa historik
                </SC.ResultsSubTitle>
              </SC.ResultsTitleWrapper>
              <SC.ClearButton onClick={() => setHistoryOpen(false)} />
            </SC.ResultsTitleBar>

            {searchHistory.length > 0 ? (
              searchHistory.map((his) => {
                const urlFunc = URL_MAP[his.key];
                return (
                  <SC.HistoryItem
                    onClick={() => {
                      setHistoryOpen(false);
                      goTo(urlFunc(his.hit._id));
                    }}
                  >
                    <SC.HistoryTitle>
                      <em>{KEY_MAP[his.key]}</em>
                      <div>{his.hit?._source?.str_representation}</div>
                    </SC.HistoryTitle>

                    <SC.HistoryArrow />
                  </SC.HistoryItem>
                );
              })
            ) : (
              <SC.HistoryTitle>Saknar sökhistorik</SC.HistoryTitle>
            )}
          </SC.HistoryContainer>
        </>
      )}

      {results && (
        <>
          <SC.ResultsOverlay onClick={clearSearch} />
          <SC.ResultsContainer {...{ sideBarOpen }}>
            <SC.ResultsTitleBar>
              <SC.ResultsTitleWrapper>
                <SC.ResultsTitle>Sökresultat</SC.ResultsTitle>
                <SC.ResultsSubTitle>
                  Visar de max 5 bästa träffarna per kategori
                </SC.ResultsSubTitle>
              </SC.ResultsTitleWrapper>
              <SC.ClearButton onClick={clearSearch} />
            </SC.ResultsTitleBar>

            <SC.ResultsWrapper>
              {Object.keys(results || {})?.length ? (
                Object.keys(results).map((key) => {
                  const hits = results[key];

                  return (
                    <SC.Result key={key}>
                      <SC.ResultTitle>
                        {KEY_MAP[key]} ({hits?.length})
                      </SC.ResultTitle>
                      {hits.map((hit) => {
                        const urlFunc = URL_MAP[key];

                        let highlights;

                        if (Object.keys(hit?.highlight || {})?.length > 0) {
                          highlights = Object.keys(hit?.highlight)?.[0]
                            ?.split(".")
                            ?.map((h) => HIGHLIGHT_MAP[h] || h)
                            ?.join(" > ");
                        }

                        const highlightMatch = Object.values(
                          hit?.highlight || {}
                        )?.[0]?.[0];

                        return (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <SC.ResultLink
                              onClick={() => {
                                addSearchToHistory({ hit, key });
                                clearSearch();
                                goTo(urlFunc(hit._id));
                              }}
                            >
                              {hit._source.str_representation}
                            </SC.ResultLink>
                            <SC.HighlightWrapper>
                              {highlights && highlights}
                              {highlightMatch && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${highlightMatch}`,
                                  }}
                                />
                              )}
                            </SC.HighlightWrapper>
                          </div>
                        );
                      })}
                    </SC.Result>
                  );
                })
              ) : (
                <SC.ResultTitle>
                  Inga träffar, kontrollera söktermen och försök igen
                </SC.ResultTitle>
              )}
            </SC.ResultsWrapper>
          </SC.ResultsContainer>
        </>
      )}
    </SC.SearchBarContainer>
  );
};
