import { buildQueryString, useFilteredObjects, usePagination } from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import { filterPagination, performFilter } from "../store/actions";
import constants from "../store/constants";

export function useFilteredNotifications(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useNotificationsPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useNotificationsPaginationCount(read) {
  const queryString = buildQueryString({
    _page: 1,
    _page_size: 0,
    read_by_me:read
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}