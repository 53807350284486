import * as React from "react";
import { ToolTipCell } from "../../Displays";
import { TextButton } from "../../Forms/Base/Buttons";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default (setInstance, canDeleteSetting) => {
  const defs = [
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "Typ",
      accessor: "category_display",
      Cell: ({ value }) => {
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
      disableSortBy: true,
      disableFilters: true,
    },
  ];
  if (canDeleteSetting) {
    defs.push({
      Header: "Radera typ",
      accessor: "_setting",
      Cell: (props) => {
        const setting = props.row.original;
        return (
          <TextButton
            title="Radera"
            clicked={() => {
              setInstance(setting);
            }}
            red
          />
        );
      },
      disableSortBy: true,
      disableFilters: true,
    });
  }

  return defs;
};

//Exporting columDefs as funciton to play nicely with detailPerms logic (components/Details/Account/UserGroups/WhiteListTable.js)

export const inspectionErrandSettingColumnDefs = () => {
  return [
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];
};
