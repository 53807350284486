import * as React from "react";

import SelectFilter from "src/components/Lists/Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: 0, display: "Ej påbörjad" },
    { value: 1, display: "Påbörjad" },
    { value: 2, display: "Pausad" },
    { value: 3, display: "Avklarad" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
