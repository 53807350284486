import * as React from "react";

// style, design
import * as SC from "../../Fields/styles";

import DescriptionToolTip from "../../Layout/DescriptionToolTip";

export default React.memo(
  ({
    noMargin,
    extraStyles = {},
    noHelp,
    small,
    alwaysShowHelp,
    title,
    description,
    options = [],
    id = "radioselect",
    required = false,

    // top, bottom
    forceVerticalToolTipPlacement,
    //left ,right
    forceHorizontalToolTipPlacement,
    value,
    onChange,
  }) => {
    return (
      <SC.InputSpacing {...{ small, noMargin }} style={extraStyles}>
        <SC.InputFieldTitle
          style={{ display: "flex", alignItems: "center", marginBottom: 12 }}
        >
          {title} {required ? "*" : ""}{" "}
          {description && !alwaysShowHelp && !noHelp && (
            <DescriptionToolTip
              forceHorizontalPlacement={forceHorizontalToolTipPlacement}
              forceVerticalPlacement={forceVerticalToolTipPlacement}
              description={description}
            />
          )}
        </SC.InputFieldTitle>
        {options.map((o) => {
          return (
            <SC.CheckBoxWrapper small noMargin key={`${id}_${o.label}`}>
              <SC.CheckBox
                small
                radioStyle
                id={`${id}_${o.label}`}
                checked={value === o.value}
                onChange={() => onChange(o.value)}
              />
              <label htmlFor={`${id}_${o.label}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {o.label}
                </div>
              </label>
            </SC.CheckBoxWrapper>
          );
        })}

        {alwaysShowHelp && <div>{description}</div>}
      </SC.InputSpacing>
    );
  }
);
