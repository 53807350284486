import styled, { css } from "styled-components";
import arrowIcon from "../../assets/svg/play.svg";

export const DocumentWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  ${(p) =>
    p.fullscreenFormLayout &&
    css`
      overflow-y: auto;
    `}
`;

export const PagePickerWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  border-top: thin solid rgba(0, 0, 0, 0.2);
  padding: 4px 80px;
  z-index: 2;
  justify-content: space-between;
  align-items: center;

  ${(p) =>
    p.absolutePositioned &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    `}
`;

export const PageButton = styled.button`
  border: 0;
  position: relative;
  color: ${(p) => p.theme.colors.primary};
  background-color: rgba(0, 0, 0, 0);
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0;
  position: relative;
  text-decoration: 0;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: no-wrap;

  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${arrowIcon});

  ${(p) =>
    p.isPrevious &&
    css`
      transform: rotate(180deg);
    `}

  &:disabled {
    visibility: hidden;
  }
`;

export const PageIndicator = styled.div`
  color: ${(p) => p.theme.colors.white};
  font-weight: 600;
`;
