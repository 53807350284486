import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import * as React from "react";
import { usePagination, useTable } from "react-table";
import Pagination from "../../Lists/Base/FinalTable/Pagination";
import OverlaySpinner from "../../Loaders/OverlaySpinner";

export default function InvoicingEventsTable({
  columns,
  data = [],
  canNext,
  canPrevious,
  pageCount,
  currentPage,
  onNext,
  onPrevious,
  fetching,
  onSetPage,
  setPageSize,
  pageSize,
  onRowClicked,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize: setTablePageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    usePagination
  );

  const onPageSizeUpdated = (newPageSize) => {
    setTablePageSize(newPageSize);
    setPageSize(newPageSize);
  };

  return (
    <div>
      <div className="relative border border-solid border-slate-200 shadow-sm overflow-x-auto rounded mt-4">
        <table
          className="table-auto w-full text-sm text-left text-slate-600"
          {...getTableProps()}
        >
          <thead className="text-xs text-slate-700 uppercase bg-slate-100">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="px-2 py-4 text-left whitespace-nowrap"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.length ? (
              page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr
                    onClick={() => onRowClicked(row)}
                    className={classNames(
                      "bg-white border-b even:bg-slate-50 hover:bg-blue-100",
                      {
                        "cursor-pointer": !!onRowClicked,
                      }
                    )}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="p-2 whitespace-nowrap"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns?.length}>
                  <div className="flex flex-col justify-center items-center p-6 text-center font-medium text-xs">
                    <ArchiveBoxXMarkIcon width={32} className="mb-2" />
                    Det finns ingen data att visa
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {fetching && <OverlaySpinner />}
      </div>
      {pageCount > 1 && (
        <Pagination
          {...{
            onNext,
            onPrevious,
            pageCount,
            pageSize,
            currentPage,
            onSetPage,
            onPageSizeUpdated,
            canNext,
            canPrevious,
          }}
        />
      )}
    </div>
  );
}
