import * as React from "react";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

import * as SC from "./styles";
import { SecondaryButton } from "../Buttons";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

import { useFilePicker } from "../../../../hooks/useFilePicker";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

const MAX_FILE_SIZE = 100;
const ALLOWED_FORMATS = [".pdf"];

export default function FileManyToMany({
  storeName,
  method,
  fieldKey,
  instructionsKey,
  fileKey,
  allowedFileFormats,
  title,
  description,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const { files, onClick, errors, HiddenFileInput } = useFilePicker({
    maxFileSize: MAX_FILE_SIZE,
  });

  const actualFileKey = fileKey || "pdf";

  const instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });
  const value = useFormInstanceField({ storeName, fieldKey });
  let error = useFormError({ storeName, fieldKey });

  if (error?.constructor === Object && error?.non_field_errors) {
    error = error.non_field_errors;
  }

  const fileFormats = allowedFileFormats || ALLOWED_FORMATS;

  React.useEffect(() => {
    handleChange();
  }, [files]);

  const removeFile = (id) => {
    let valueCopy = cloneDeep(value);

    valueCopy = valueCopy.filter((v) => v.id !== id && v._referenceId !== id);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [fieldKey]: valueCopy },
      })
    );
  };

  const handleChange = async () => {
    if (files.length === 0) {
      return;
    } else {
      setLoading(true);

      let newFiles = [];
      if (value?.length) {
        newFiles = [...value];
      }

      for (let i = 0; i < files.length; i++) {
        const restFile = await toBase64(files[i]);
        newFiles.push(restFile);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: newFiles },
        })
      );
      setLoading(false);
    }
  };

  const toBase64 = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileData = {
          [actualFileKey]: file.name,
          _tempData: {
            file_name: file.name,
            file_size: file.size,
            data: reader.result.toString(),
          },
          _referenceId: `${file.name}&${file.size}&${Math.floor(
            Math.random() * 100
          )}`,
        };

        resolve(fileData);
      };
    });
  };

  if (!instructions) {
    return null;
  }
  if (instructions._readOnly) {
    return null;
  }

  return (
    <>
      <SC.InputSpacing>
        <SC.InputFieldTitle>
          {title}
          {instructions._required ? "*" : ""}
        </SC.InputFieldTitle>
        {description && (
          <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
        )}
        <SC.InputFieldDescription>
          Tillåtna filformat: {fileFormats.join(", ")}. Maximal storlek:{" "}
          {MAX_FILE_SIZE}mb.
        </SC.InputFieldDescription>
        <SC.ImageUploaderWrapper>
          {loading && <OverlaySpinner />}
          <SC.UploadedImagesWrapper>
            {value && value.length ? (
              value.map((fileObj) => (
                <SC.UploadededFileWrapper>
                  <SC.UploadedFile
                    key={fileObj.id || fileObj._referenceId}
                    src={
                      fileObj.id ? fileObj.image?.get : fileObj._tempData.data
                    }
                  >
                    <SC.RemoveImageButton
                      onClick={() =>
                        removeFile(fileObj.id || fileObj._referenceId)
                      }
                    />
                  </SC.UploadedFile>
                  <SC.UploadFileTitle>
                    {fileObj?.str_representation || fileObj[actualFileKey]}
                  </SC.UploadFileTitle>
                </SC.UploadededFileWrapper>
              ))
            ) : (
              <SC.NoFile />
            )}
          </SC.UploadedImagesWrapper>
          <SecondaryButton
            title={
              loading
                ? "Laddar..."
                : value && value.length
                ? "Ladda upp fler filer"
                : "Ladda upp filer"
            }
            clicked={onClick}
          />

          <HiddenFileInput accept={fileFormats.join(", ")} multiple={true} />
        </SC.ImageUploaderWrapper>
        {errors?.hasInvalidFileSize && (
          <SC.ErrorMessage>
            En eller fler av filerna är för stora (får vara max {MAX_FILE_SIZE}
            mb)
          </SC.ErrorMessage>
        )}
        {errors?.hasInvalidImage && (
          <SC.ErrorMessage>
            En eller fler av filerna är inte tillåtna. Välj en ny i ett tillåtet
            format och storlek.
          </SC.ErrorMessage>
        )}
        {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    </>
  );
}
