import classNames from "classnames";
import * as React from "react";

export const BADGE_TYPES = {
  DEFAULT: "DEFAULT",
  GRAY: "GRAY",
  RED: "RED",
  GREEN: "GREEN",
  YELLOW: "YELLOW",
  INDIGO: "INDIGO",
  PURPLE: "PURPLE",
  PINK: "PINK",
  ORANGE: "ORANGE",
  TEAL: "TEAL",
  CYAN: "CYAN",
};

const TYPE_CLASSES = {
  DEFAULT: "bg-blue-100 text-blue-800 ",
  GRAY: "bg-gray-100 text-gray-800",
  RED: "bg-red-100 text-red-800 ",
  GREEN: "bg-green-100 text-green-800",
  YELLOW: "bg-yellow-100 text-yellow-800 ",
  INDIGO: "bg-indigo-100 text-indigo-800",
  PURPLE: "bg-purple-100 text-purple-800",
  PINK: "bg-pink-100 text-pink-800",
  ORANGE: "bg-orange-100 text-orange-700",
  TEAL: "bg-teal-100 text-teal-800",
  CYAN: "bg-cyan-100 text-cyan-800",
};

export default function Badge({
  type = BADGE_TYPES.DEFAULT,
  large = false,
  small = false,
  className,
  children,
  onClick,
  selected = null,
}) {
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
      }}
      className={classNames(
        "relative inline-flex font-semibold px-2.5 py-0.5 rounded-xl",
        TYPE_CLASSES[type],
        {
          "text-sm leading-normal": large,
          "text-[10px] leading-normal": small,
          "text-xs leading-normal": !large && !small,
          "opacity-70": selected === false,
          "ring-1 ring-blue-900": selected === true,
          "cursor-pointer": !!onClick,
        },

        className
      )}
    >
      {children}
    </span>
  );
}
