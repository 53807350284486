const defs = [
  {
    title: "Hyresobjekt",
    key: "PREMISES",
    visited: false,
    hasError: false,
    fieldKeys: ["apartments", "industrial_premises_list", "realestate"],
  },
  {
    title: "Parter",
    key: "PARTIES",
    visited: true,
    hasError: false,
    fieldKeys: ["tenant", "bi_tenants", "landlord"],
  },
  {
    title: "Dokument",
    key: "EXISTINGDOC",
    visited: false,
    hasError: false,
    fieldKeys: ["editabledoc.doc"],
  },
  {
    title: "Bilagor",
    key: "ATTACHMENTS",
    visited: false,
    hasError: false,
    fieldKeys: ["attachments"],
  },
  {
    title: "Avtal",
    key: "CONTRACT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "id_number",
      "status",
      "start_date",
      "end_date",
      "renew_interval",
      "warning_interval",
      "notify_interval",
      "invoice_month_interval",
      "first_billing_date",
      "renew_indefinetely",
      "closed_date",
      "closed_renew_date",
      "closed_cause",
      "landlord_cancelled",
      "closed_signed",
    ],
  },
  {
    title: "Fastighetsskatt & Indexuppräkning",
    key: "TAX",
    visited: false,
    hasError: false,
    fieldKeys: ["tenant_tax_billing_quota", "tax_mode", "index_base_date"],
  },
  {
    title: "Signeringsstatus",
    key: "SIGN_STATUS",
    visited: false,
    hasError: false,
    fieldKeys: ["is_signed", "signed_date"],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default ({ premisesKind, contractType }) => {
  const exclude = [];

  // special chapters depending on premises kind (apartment/indp)
  if (premisesKind === "apartments") {
    exclude.push("TAX");
  }

  // only include signing if existing contract, new contracts uses SCRIVE_SETTINGS
  if (contractType === "new") {
    exclude.push("EXISTINGDOC");
    exclude.push("ATTACHMENTS"); // uploaded with editable doc
    exclude.push("SIGN_STATUS"); // handled latare
  }

  return defs.filter((d) => !exclude.includes(d.key));
};
