export default [
  {
    title: "Kalender",
    key: "MAIN",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "remind_before",
      "display_as_occupied",
      "outlook_calendar_id",
      "color_code",
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
