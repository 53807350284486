import * as React from "react";
import { useTransition } from "@react-spring/web";

// style, design
import * as SC from "./styles";
import companyIcon from "../../../assets/svg/company.svg";
import userIcon from "../../../assets/svg/user.svg";

export default function FormModal({ isOpen, onClose, onSelect, isMember }) {
  const modalTransition = useTransition(!!isOpen, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateY(100%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.BackDrop onClick={onClose} style={{ opacity: styles.opacity }}>
          <SC.Modal
            onClick={(e) => e.stopPropagation()}
            style={{ ...styles, opacity: 1 }}
          >
            <SC.ModalContent>
              <SC.ModalTitle>
                Välj om {isMember ? "medlemmen" : "hyresgästen"} är ett företag
                eller en privatperson
              </SC.ModalTitle>

              <SC.OptionsWrapper>
                <SC.Option onClick={() => onSelect("private")}>
                  <SC.OptionIcon imgSrc={userIcon} />
                  <SC.OptionLabel>Privatperson</SC.OptionLabel>
                </SC.Option>
                <SC.Option onClick={() => onSelect("corporate")}>
                  <SC.OptionIcon imgSrc={companyIcon} />
                  <SC.OptionLabel>Företag</SC.OptionLabel>
                </SC.Option>
              </SC.OptionsWrapper>
            </SC.ModalContent>
          </SC.Modal>
        </SC.BackDrop>
      )
  );
}
