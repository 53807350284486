import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import ApartmentsTable from "../../../components/Tables/Apartments/FullTableNewVersion";
import IndpTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import ParkingSpotTable from "../../../components/Tables/Parking/ParkingSpots/FullTable";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useApartmentPaginationCount } from "../../../store/apartments/hooks/retrieve";
import { useIndustrialPremisesPaginationCount } from "../../../store/industrialPremises/hooks/retrieve";
import { useParkingSpotsPaginationCount } from "../../../store/parkingSpots/hooks/retrieve";
function TenantPremises() {
  const { tenantId } = useParams();

  const [apartmentCount] = useApartmentPaginationCount({
    filters: {
      broad_tenant: tenantId,
    },
  });

  const [indpCount] = useIndustrialPremisesPaginationCount({
    filters: {
      broad_tenant: tenantId,
    },
  });

  const [parkingSpotCount] = useParkingSpotsPaginationCount({
    filters: {
      tenant: tenantId,
    },
  });

  const apartmentQuery = { broad_tenant: tenantId };

  const industrialQuery = { broad_tenant: tenantId };

  return (
    <DetailInnerWrapper>
      {indpCount === 0 && apartmentCount === 0 && (
        <DetailPageBox>
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <OverviewTitle>Inga kopplade hyresobjekt</OverviewTitle>
          </div>
        </DetailPageBox>
      )}

      {indpCount > 0 && (
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Lokaler</OverviewTitle>
          </OverviewTitleWrapper>
          <IndpTable
            persistantQuery={industrialQuery}
            isBare
            ignoreLocalStorage
          />
        </DetailPageBox>
      )}

      {apartmentCount > 0 && (
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Lägenheter</OverviewTitle>
          </OverviewTitleWrapper>
          <ApartmentsTable
            persistantQuery={apartmentQuery}
            isBare
            ignoreLocalStorage
          />
        </DetailPageBox>
      )}

      {parkingSpotCount > 0 && (
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Fordonsplatser</OverviewTitle>
          </OverviewTitleWrapper>

          <ParkingSpotTable
            isBare
            ignoreLocalStorage
            persistantQuery={{
              tenant: tenantId,
            }}
          />
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}

export default TenantPremises;
