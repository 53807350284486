import * as React from "react";
import NonConnectedCheckbox from "../../../components/Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedNumberInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedNumberInput";
import NonConnectedSelect from "../../../components/Forms/Base/Old/NonConnected/NonConnectedSelect";
import {
  BALCONY_OPTIONS,
  KITCHEN_OPTIONS,
  MULTI_OPTIONS,
} from "../../../components/Forms/MarketApartmentCommodities/FlowForm/Chapters/Amenities";
import { WATER_OPTIONS } from "../../../components/Forms/MarketApartmentCommodities/FlowForm/Chapters/Included";
import StandardModal from "../../../components/Modals/StandardModal";

export default function HandleCommodities({
  editInstance,
  closeFunction,
  isOpen,
  handleAttrUpdated,
  handleRenovationDateUpdates,
}) {
  return (
    <StandardModal
      title={`Redigera annonsdetaljer för ${editInstance?.apartment_id}`}
      isOpen={isOpen}
      closeFunction={closeFunction}
      withActionBar
      actionBarCancelTitle="Klar"
    >
      {!!editInstance && (
        <>
          <NonConnectedCheckbox
            title="El inkluderat"
            id={`electricity_included${editInstance.id}`}
            value={editInstance.electricity_included}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "electricity_included",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Värme inkluderat"
            id={`heating_included${editInstance.id}`}
            value={editInstance.heating_included}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "heating_included",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Internet inkluderat"
            id={`internet_included${editInstance.id}`}
            value={editInstance.internet_included}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "internet_included",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="TV-paket inkluderat"
            id={`cable_package_included${editInstance.id}`}
            value={editInstance.cable_package_included}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "cable_package_included",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Hiss finns i byggnaden"
            id={`elevator${editInstance.id}`}
            value={editInstance.elevator}
            onChange={(val) => {
              handleAttrUpdated({ attr: "elevator", id: editInstance.id, val });
            }}
          />

          <NonConnectedCheckbox
            title="Skafferi"
            id={`pantry${editInstance.id}`}
            value={editInstance.pantry}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "pantry",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Uteplats"
            id={`patio${editInstance.id}`}
            value={editInstance.patio}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "patio",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Tillgänglighetsanpassad"
            id={`accessibility_adapted${editInstance.id}`}
            value={editInstance.accessibility_adapted}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "accessibility_adapted",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Handikappsanpassad"
            id={`handicap_adapted${editInstance.id}`}
            value={editInstance.handicap_adapted}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "handicap_adapted",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Säkerhetsdörr"
            id={`security_door${editInstance.id}`}
            value={editInstance.security_door}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "security_door",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Tvättstuga"
            id={`laundry_room${editInstance.id}`}
            value={editInstance.laundry_room}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "laundry_room",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Dusch"
            id={`shower${editInstance.id}`}
            value={editInstance.shower}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "shower",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Utfällbar säng"
            id={`bed_in_wall${editInstance.id}`}
            value={editInstance.bed_in_wall}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "bed_in_wall",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Spis"
            id={`stove${editInstance.id}`}
            value={editInstance.stove}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "stove",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Gasspis"
            id={`gas_cooker${editInstance.id}`}
            value={editInstance.gas_cooker}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "gas_cooker",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedCheckbox
            title="Öppen spis"
            id={`tiled_stove${editInstance.id}`}
            value={editInstance.tiled_stove}
            onChange={(val) => {
              handleAttrUpdated({
                attr: "tiled_stove",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedSelect
            label="Kökstyp"
            extraStyles={{ minWidth: 200 }}
            id={`kithcen${editInstance.id}`}
            value={editInstance.kitchen}
            choices={KITCHEN_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({ attr: "kitchen", id: editInstance.id, val });
            }}
          />

          <NonConnectedSelect
            label="Vatten"
            extraStyles={{ minWidth: 200 }}
            id={`water${editInstance.id}`}
            value={editInstance.water}
            choices={WATER_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({ attr: "water", id: editInstance.id, val });
            }}
          />

          <NonConnectedDatePicker
            label="Renoverades senast"
            value={editInstance.renovation_date}
            onChange={(val) => {
              handleRenovationDateUpdates({ id: editInstance.id, val });
            }}
            clearable={false}
            id={`renovation_date${editInstance.id}`}
          />

          <NonConnectedSelect
            label="Balkong"
            extraStyles={{ minWidth: 200 }}
            id={`balcony${editInstance.id}`}
            value={editInstance.balcony}
            choices={BALCONY_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({ attr: "balcony", id: editInstance.id, val });
            }}
          />

          <NonConnectedSelect
            label="Köksfläkt"
            extraStyles={{ minWidth: 200 }}
            id={`kitchen_fan${editInstance.id}`}
            value={editInstance.kitchen_fan}
            choices={MULTI_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "kitchen_fan",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedSelect
            label="Diskmaskin"
            extraStyles={{ minWidth: 200 }}
            id={`dishwasher${editInstance.id}`}
            value={editInstance.dishwasher}
            choices={MULTI_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "dishwasher",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedSelect
            label="Tvättmaskin"
            extraStyles={{ minWidth: 200 }}
            id={`washing_machine${editInstance.id}`}
            value={editInstance.washing_machine}
            choices={MULTI_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "washing_machine",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedSelect
            label="Torktumlare"
            extraStyles={{ minWidth: 200 }}
            id={`dryer${editInstance.id}`}
            value={editInstance.dryer}
            choices={MULTI_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "dryer",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedSelect
            label="Badkar"
            extraStyles={{ minWidth: 200 }}
            id={`bathtub${editInstance.id}`}
            value={editInstance.bathtub}
            choices={MULTI_OPTIONS}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            allowNull={false}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "bathtub",
                id: editInstance.id,
                val,
              });
            }}
          />

          <NonConnectedNumberInput
            label="Antal tillgängliga fordonsplatser"
            id={`available_parking_spots${editInstance.id}`}
            value={editInstance.available_parking_spots}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "available_parking_spots",
                id: editInstance.id,
                val,
              });
            }}
            extraStyle={{ minWidth: 200 }}
          />

          <NonConnectedNumberInput
            label="Antal inkluderade fordonsplatser"
            id={`included_parking_spots${editInstance.id}`}
            value={editInstance.included_parking_spots}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "included_parking_spots",
                id: editInstance.id,
                val,
              });
            }}
            extraStyle={{ minWidth: 200 }}
          />

          <NonConnectedNumberInput
            label="Antal tillgängliga garageplatser"
            id={`available_garage_spots${editInstance.id}`}
            value={editInstance.available_garage_spots}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "available_garage_spots",
                id: editInstance.id,
                val,
              });
            }}
            extraStyle={{ minWidth: 200 }}
          />

          <NonConnectedNumberInput
            label="Antal inkluderade garageplatser"
            id={`included_garage_spots${editInstance.id}`}
            value={editInstance.included_garage_spots}
            onUpdate={(val) => {
              handleAttrUpdated({
                attr: "included_garage_spots",
                id: editInstance.id,
                val,
              });
            }}
            extraStyle={{ minWidth: 200 }}
          />
        </>
      )}
    </StandardModal>
  );
}
