import { cloneDeep, set } from "lodash";
import {
  get,
  options,
  destroyForm,
  getPagination,
  getFiltered,
} from "../../base";
import { patch } from "../../base/store/actions";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import * as services from "./services";

export const getSinglePpt = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({
    url,
    constants: { ...constants, LIST_URL: constants.LIST_URL },
    name: getSingleFetchProcessName(id),
  });
};

export const performPptFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPptPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPptPatchForm = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const updatePpt = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const url = `${constants.GET_URL}${id}`;

  return patch({
    url,
    constants,
    preProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    forceData,
  });
};

export const downloadPowerPointPDF = ({
  reportId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const data = await services.getPdfFromPowerPoint({ reportId });

      const url = data?.url;
      if (url) {
        window.open(url, "_blank").focus();
      } else {
        throw Error("No url");
      }

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};
