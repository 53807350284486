export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SERVICECONTRACT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SERVICECONTRACT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SERVICECONTRACT",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SERVICECONTRACT",
  DESTROY_FORM: "DESTROY_FORM_SERVICECONTRACT",
  SET_FORM_ERROR: "SET_FORM_ERROR_SERVICECONTRACT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SERVICECONTRACT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SERVICECONTRACT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SERVICECONTRACT",
  RESET_STATE: "RESET_STATE_SERVICECONTRACT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SERVICECONTRACT",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_SERVICECONTRACT",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_SERVICECONTRACT",
  CLEAR_FETCHED: "CLEAR_FETCHED_SERVICECONRTACT",

  LIST_URL: "/standard/service/servicecontract/list/",
  POST_URL: "/standard/service/servicecontract/",
  PATCH_URL: "/standard/service/servicecontract/",
  GET_URL: "/standard/service/servicecontract/",

  STORE_NAME: "serviceContracts",
};
