import Confirm from "./Confirm";
import IndustrialPremises from "./IndustrialPremises";
import Images from "./Images";
import Costs from "./Costs";
export default {
  INDUSTRIAL_PREMISES: IndustrialPremises,
  IMAGES: Images,
  COSTS: Costs,
  CONFIRM: Confirm,
};
