import * as React from "react";

import { Confirm, Ad, Viewing, Contract, Leads, Publish } from "./Chapters";

export default ({ key, method, hideApartment }) => {
  switch (key) {
    case "AD":
      return <Ad method={method} hideApartment={hideApartment} />;

    case "LEADS":
      return <Leads method={method} />;

    case "CONTRACT":
      return <Contract method={method} />;

    case "PUBLISH":
      return <Publish method={method} />;

    case "VIEWING":
      return <Viewing method={method} />;

    default:
      return <Confirm method={method} />;
  }
};
