import * as React from "react";
import { DatePicker, RadioGroup } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketIndpAds";
import { useFormInstanceField } from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const isShortLease = useFormInstanceField({
    storeName,
    fieldKey: "is_short_lease",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Villkor</OverviewTitle>
          <OverviewSubtitle>Ange villkor för hyresavtal</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <DatePicker
        fieldKey="access_date"
        title="Tillträdesdatum"
        {...{ storeName, method }}
      />

      <RadioGroup
        extraStyles={{ flex: 1 }}
        fieldKey="prior_access"
        title="Tidigare inflytt möjlig"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />

      <RadioGroup
        extraStyles={{ flex: 1 }}
        fieldKey="prioritize_customers"
        title="Nuvarande hyresgäster har företräde"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />

      <RadioGroup
        extraStyles={{ flex: 1 }}
        fieldKey="is_short_lease"
        title="Korttidsavtal"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />

      {isShortLease && (
        <>
          {" "}
          <DatePicker
            fieldKey="short_lease_min_date"
            title="Uthyres som minst till"
            {...{ storeName, method }}
          />
          <DatePicker
            fieldKey="short_lease_max_date"
            title="Uthyres som mest till"
            {...{ storeName, method }}
          />
        </>
      )}
    </>
  );
};
