import * as React from "react";
import { useParams } from "react-router-dom";

import QuotaForm from "../../../components/Forms/BrfPaymentGroupQuota/FlowForm/FlowForm";

export default () => {
  const { paymentGroupId, brfPremisesId } = useParams();

  return <QuotaForm method="POST" paymentGroupId={paymentGroupId} brfPremisesId={brfPremisesId}/>;
};
