import * as React from "react";

import { Confirm, User } from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "USER":
      return <User method={method} />;
    default:
      return <Confirm />;
  }
};
