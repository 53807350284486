import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function publishNewProductionApartments({ postObj, pipeId }) {
  const res = await axiosInstance.patch(
    `${constants.PUBLISH_NEW_PRODUCTION_URL}${pipeId}/`,
    postObj
  );

  return res;
}

export { publishNewProductionApartments };
