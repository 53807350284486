import * as React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/app/actions";
import { TextButton } from "../../Forms/Base/Buttons";

import * as SC from "./styles";

export default function UtilUserBanner() {
  const dispatch = useDispatch();

  return (
    <SC.Banner>
      <div></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SC.BannerTitle>Inloggad som extern förvaltare</SC.BannerTitle>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          title="Logga ut"
          iconType="arrow"
          iconPlacement="right"
          extraStyle={{ marginRight: 0 }}
          inverted
          clicked={() => {
            dispatch(logout());
          }}
        />
      </div>
    </SC.Banner>
  );
}
