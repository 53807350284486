import * as React from "react";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default () => [
  {
    Header: "Kategori",
    accessor: "title",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
