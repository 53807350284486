import * as React from "react";

import Modal from "../Base/Modals/Modal";
import StatusFlag, { STATUS_FLAG_STATES } from "../../Displays/StatusFlag";
import LocalTextInputField from "../Base/Fields/LocalTextInputField";
import LocalDateSelect from "../Base/Fields/LocalDateSelect";
import LocalCheckField from "../Base/Fields/LocalCheckField";
import LocalSelectField from "../Base/Fields/LocalSelectField";
import PrimaryBtn from "../Base/Buttons/PrimaryBtn";
import { toMoneyString } from "../../utils/stringUtils";
import { getPaymentMeansInstance } from "../../../store/billectaPaymentMeans/store/services";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const OVERPAYMENT_OPTIONS = [
  {
    d: "Ignorera överskjutande belopp",
    v: 0,
  },
  {
    d: "Som en valutakursdifferens",
    v: 1,
  },
  {
    d: "Som en överbetalning",
    v: 2,
  },
];

export default function RegisterInvoicePayment({
  registerPayment,
  isCreditInvoice,
  defaultValue,
  creditorId,
  isDebtHandling,
}) {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [currency, setCurrency] = React.useState("SEK");
  const [paidDate, setPaidDate] = React.useState(null);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [overpaymentHandle, setOverpaymentHandle] = React.useState(2);
  const [reference, setReference] = React.useState("");
  const [paymentMeansInstance, setPaymentMeansInstance] = React.useState(null);
  const [paymentMeansCode, setPaymentMeansCode] = React.useState("BG");
  const [isPaymentCreditation, setIsPaymentCreditation] = React.useState(true);
  const [invoiceInterest, setInvoiceInterest] = React.useState(false);

  const getPaymentMeansOptions = async () => {
    const res = await getPaymentMeansInstance(creditorId);
    setPaymentMeansInstance(res);
  };

  React.useEffect(() => {
    getPaymentMeansOptions();
  }, []);

  React.useEffect(() => {
    if (!!value && !!currency && !!paidDate) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [value, currency, paidDate]);

  React.useEffect(() => {
    if (!value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      {open && (
        <Modal
          closeFunction={() => setOpen(false)}
          title="Registrera manuell inbetalning"
          onAccept={() => {
            registerPayment({
              value,
              currency,
              paidDate,
              overpaymentHandle,
              reference,
              paymentMeansCode,
              invoiceInterest,
              isPaymentCreditation,
              isDebtHandling,
            });
          }}
          canAccept={canSubmit}
          acceptTitle="Registrera inbetalning"
          denyTitle="Avbryt"
        >
          {isCreditInvoice && (
            <StatusFlag className="mb-6" state={STATUS_FLAG_STATES.WARNING}>
              <div className="flex flex-col">
                <div className="font-medium">OBS: Kreditfaktura</div>
                <div className="text-sm">
                  För att registrera en betalning mot en kreditfaktura anges
                  värdet negativt
                </div>
              </div>
            </StatusFlag>
          )}

          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalTextInputField
              value={reference}
              id="reference"
              onChange={(v) => setReference(v)}
              title={isDebtHandling ? "Kommentar" : "Referens"}
            />

            <LocalDateSelect
              value={paidDate}
              onChange={(d) => setPaidDate(d)}
              title="Inbetalningsdatum"
            />
          </div>

          {isDebtHandling && (
            <>
              <div className="mb-6">
                <LocalCheckField
                  id="paymentCreditationCheckbox"
                  value={isPaymentCreditation}
                  title="Minskningen är en betalning från kunden"
                  onChange={(d) => setIsPaymentCreditation(d)}
                />
              </div>
              <div className="mb-6">
                <LocalCheckField
                  id="invoiceInterestPaymentCheckbox"
                  value={invoiceInterest}
                  title="Fakturera ränta för minskat belopp"
                  onChange={(d) => setInvoiceInterest(d)}
                />
              </div>
            </>
          )}

          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalSelectField
              choices={
                paymentMeansInstance?.map((pm) => ({
                  d: pm.Description,
                  v: pm.PaymentCode,
                })) || []
              }
              title="Betalsätt"
              id="payment_code"
              value={paymentMeansCode}
              onChange={(v) => setPaymentMeansCode(v)}
            />
            <LocalTextInputField
              value={value}
              onChange={(v) => setValue(v)}
              title="Belopp (SEK)"
              isNumber
            />
          </div>

          <div className="mb-6">
            <div>
              <strong>Återstående: </strong>
              {toMoneyString(defaultValue - (value || 0), true)}
            </div>
          </div>

          {value > defaultValue && (
            <div className="mb-6">
              <LocalSelectField
                choices={OVERPAYMENT_OPTIONS}
                title="Hantering av överskjutande belopp"
                id="overpayment_id"
                value={overpaymentHandle}
                onChange={(v) => setOverpaymentHandle(v)}
              />
            </div>
          )}

          {value != defaultValue && value < defaultValue && (
            <div className="mb-6">
              <StatusFlag state={STATUS_FLAG_STATES.ERROR}>
                <div className="flex">
                  <ExclamationTriangleIcon width={24} className="mr-2" />

                  <div>
                    {`Det inskrivna beloppet matchar ej det kvarstående beloppet på fakturan. Det kvarstående beloppet på fakturan efter uppdateringen blir ${(
                      defaultValue - value
                    ).toLocaleString("sv")} SEK`}
                  </div>
                </div>
              </StatusFlag>
            </div>
          )}
        </Modal>
      )}

      <div className="flex justify-start">
        <PrimaryBtn onClick={() => setOpen(true)}>
          Registrera manuell inbetalning
        </PrimaryBtn>
      </div>
    </>
  );
}
