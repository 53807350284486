import * as React from "react";
import { useParams, useRouteMatch, useHistory } from "react-router";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PopupButton,
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";

import ApartmentsTable from "../../../components/Tables/Apartments/FullTableNewVersion";
import IndpTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";
import KeyTable from "src/components/Tables/Keys/FullTable";

import {
  useKeyPermission,
  updateUrl,
  detailUrl,
  constants,
} from "../../../store/keypermission";

import { DetailLayoutWrapper } from "../../../components/sharedStyles";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import ParakeyPermissionDetail from "./ParakeyPermission";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { axiosInstance } from "../../../store/base/store/axios";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

export default function KeyPermissionDetail() {
  const { id } = useParams();
  const { push } = useHistory();

  const canUpdate = useAllPermissionCheck(["change_can_keys"]);
  const canParakey = useAllPermissionCheck([
    "allow_parakey",
    "view_can_parakey",
  ]);
  const canParakeyUpdate =
    useAllPermissionCheck(["change_can_parakey"]) && canParakey;
  const canDelete = useAllPermissionCheck(["delete_can_keys"]);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const [askParakeyConvertOpen, setAskParakeyConvertOpen] =
    React.useState(false);
  const [showParakeyConversion, setShowParakeyConversion] =
    React.useState(undefined);
  const [parakeyConversionLoading, setParakeyConversionLoading] =
    React.useState(false);

  const [permission, loading] = useKeyPermission(id);

  const apartmentQueryString = buildQueryString({
    id__in: permission?.apartments?.map((a) => a.id),
  });
  const indpQueryString = buildQueryString({
    id__in: permission?.industrial_premises?.map((a) => a.id),
  });
  const commonQueryString = buildQueryString({
    id__in: permission?.common_areas?.map((a) => a.id),
  });

  const keyQuery = { permission: permission?.id };

  const apartmentFilterMethod = (ap) =>
    permission?.apartments != null &&
    permission.apartments.some((c) => c.id === ap.id);
  const industrialFilterMethod = (indp) =>
    permission?.industrial_premises != null &&
    permission.industrial_premises.some((c) => c.id === indp.id);
  const commonFilterMethod = (com) =>
    permission?.common_areas != null &&
    permission.common_areas.some((c) => c.id === com.id);

  const keyFilterMethod = (key) => key?.permission?.id === permission?.id;

  const canParakeyConvert =
    !!permission && permission.parakey_id == null && canParakeyUpdate;

  const convertToParakey = async () => {
    setParakeyConversionLoading(true);

    try {
      const result = await axiosInstance.patch(
        `${constants.PARAKEY_CONVERT_URL}${permission.id}/`
      );

      setAskParakeyConvertOpen(false);
      setParakeyConversionLoading(false);

      setShowParakeyConversion([true, result.data]);
    } catch (error) {
      setAskParakeyConvertOpen(false);
      setParakeyConversionLoading(false);

      setShowParakeyConversion([false, null]);
    }
  };

  return (
    <>
      {!!parakeyConversionLoading && <OverlaySpinner />}

      <ConfirmationModal
        isOpen={askParakeyConvertOpen}
        title={"Vill du konvertera Behörigheten till en Parakey behörighet?"}
        renderContent={() =>
          "Detta kommer bara gå om dem kopplade objeketen har Parakey-enheter kopplade på sig"
        }
        closeFunction={() => setAskParakeyConvertOpen(false)}
        acceptCallback={() => convertToParakey()}
      />
      <ConfirmationModal
        isOpen={showParakeyConversion !== undefined}
        title={
          !showParakeyConversion
            ? "-"
            : showParakeyConversion[0]
            ? "Konvertering lyckades"
            : "Konvertering misslyckades"
        }
        acceptTitle={"Stäng"}
        renderContent={() =>
          !showParakeyConversion ? (
            "-"
          ) : showParakeyConversion[0] ? (
            showParakeyConversion[1]?.id !== permission?.id ? (
              <>
                Det fanns redan en behörighet för dessa objekt hos Parakey,{" "}
                <TextButton
                  title={"Se den här"}
                  clicked={() =>
                    push(detailUrl({ id: showParakeyConversion[1]?.id }))
                  }
                />
              </>
            ) : (
              "Nyckelbehörigheten har kopplats till Parakey"
            )
          ) : (
            "Alla involverade objekt måste ha Parakey-enheter kopplad på sig"
          )
        }
        closeFunction={() => setShowParakeyConversion(undefined)}
        showCancel={false}
        acceptCallback={() => setShowParakeyConversion(undefined)}
      />
      {canDelete && (
        <DeleteModal
          isOpen={deleteModalOpen}
          instance={permission}
          closeFunction={() => setDeleteModalOpen(false)}
          constants={constants}
          deletedCallback={() => push("/keys/keypermissions")}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Nyckelbehörighet ${
            permission?.str_representation
              ? ` - ${permission.str_representation}`
              : ""
          }`}
          breadCrumbs={[
            {
              label: "Nyckelbehörigheter",
              url: "/keys/keypermissions",
            },
            {
              label: "Detaljsida",
            },
          ]}
          eventContentType="keyhandling.keypermission"
          eventObjectId={id}
          eventIdQueryStr="keypermission"
        />

        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox style={{ minWidth: "49%", alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Översikt</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>

                <PopupButton
                  title="Hantera"
                  disabled={!permission || !canUpdate}
                  actions={[
                    {
                      name: "Uppdatera",
                      onClick: () => push(updateUrl({ id: permission.id })),
                    },
                    {
                      name: "Konvertera till Parakey Behörighet",
                      onClick: () => setAskParakeyConvertOpen(true),
                      hidden: !canParakeyConvert,
                    },
                    {
                      name: "Radera",
                      onClick: () => setDeleteModalOpen(true),
                      isDelete: true,
                      hidden: permission == null,
                    },
                  ]}
                />
              </OverviewTitleWrapper>
              <KeyTable
                persistantQuery={keyQuery}
                ignoreLocalStorage
                isBare
                overrideTitle={"Nycklar"}
              />
            </DetailPageBox>

            <DetailPageBox style={{ minWidth: "50%", alignSelf: "flex-start" }}>
              {permission?.parakey_id == null || !canParakey ? (
                <>
                  <OverviewTitleWrapper>
                    <OverviewTitle>Gemensamma ytor</OverviewTitle>
                  </OverviewTitleWrapper>
                  <CommonAreaTable
                    persistantQueryString={commonQueryString}
                    persistantFilterMethod={commonFilterMethod}
                    //   toggleFormCallback={canAddLease ? initiateLeaseCreation : undefined}
                    hideSearch
                    hideExport
                    hideFilters
                    hideTitle
                  />
                  <OverviewTitleWrapper style={{ marginTop: "16px" }}>
                    <OverviewTitle>Lägenheter</OverviewTitle>
                  </OverviewTitleWrapper>
                  <ApartmentsTable
                    persistantQueryString={apartmentQueryString}
                    persistantFilterMethod={apartmentFilterMethod}
                    //   toggleFormCallback={canAddLease ? initiateLeaseCreation : undefined}
                    hideSearch
                    hideExport
                    hideFilters
                    hideTitle
                  />
                  <OverviewTitleWrapper style={{ marginTop: "16px" }}>
                    <OverviewTitle>Lokaler</OverviewTitle>
                  </OverviewTitleWrapper>
                  <IndpTable
                    persistantQueryString={indpQueryString}
                    persistantFilterMethod={industrialFilterMethod}
                    //   toggleFormCallback={canAddLease ? initiateLeaseCreation : undefined}
                    hideSearch
                    hideExport
                    hideFilters
                    hideTitle
                  />
                </>
              ) : (
                <ParakeyPermissionDetail permissionId={id} />
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
