import constants from "./constants";
import * as services from "./services";
import { store } from "../../store";
import { addToast, TOAST_TYPES } from "../../toasts";

export const getSbfCredentials = () => {
  return async (dispatch) => {
    const state = store.getState();
    const inProgress = state[constants.STORE_NAME].credentialsCheckInProgress;

    if (inProgress) return;

    try {
      dispatch({
        type: constants.SET_CREDENTIALS_IN_PROGRESS,
        payload: {
          inProgress: true,
        },
      });

      const res = await services.getSbfCredentials();

      const credentials = res.data;

      dispatch({
        type: constants.SET_CREDENTIALS,
        payload: {
          credentials,
        },
      });
    } catch (e) {
      if (e?.response?.status !== 403) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde ej hämta integrationsdata",
            description: "Kontakta Pigello om problemet kvarstår",
          })
        );
      }
    }
  };
};

export const addCredential = ({
  legalId,
  password,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const postData = {
        legal_id: legalId,
        password,
      };

      const res = await services.addCredential(postData);

      dispatch({
        type: constants.SET_CREDENTIAL,
        payload: {
          credential: res.data,
        },
      });

      successCallback();
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej lägga till bolag för integration",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );

      errorCallback();
    }
  };
};

export const deleteCredential = ({ id, successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      const res = await services.deleteCredential(id);

      dispatch({
        type: constants.REMOVE_CREDENTIAL,
        payload: {
          id,
        },
      });

      successCallback();
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej radera integration för bolag",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );

      errorCallback();
    }
  };
};

export const updateCredential = ({
  id,
  legalId,
  password,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const patchData = {
        id,
        legal_id: legalId,
        password,
      };

      const res = await services.updateCredential(patchData);

      dispatch({
        type: constants.UPDATE_CREDENTIAL,
        payload: {
          credential: res.data,
        },
      });

      successCallback();
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej uppdatera integration för bolag",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );

      errorCallback();
    }
  };
};

export const getSbfRequirements = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const inProgress = state[constants.STORE_NAME].requirementsInProgress;

      if (inProgress) return;

      dispatch({
        type: constants.SET_SBF_REQUIREMENTS_INPROGRESS,
        payload: {
          inProgress: true,
        },
      });

      const res = await services.getSbfRequirements();

      const requirements = res.data;

      dispatch({
        type: constants.SET_SBF_REQUIREMENTS,
        payload: {
          requirements,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getSbfRequirementConnections = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const inProgress =
        state[constants.STORE_NAME].requirementConnectionsInProgress;

      if (inProgress) return;

      dispatch({
        type: constants.SET_SBF_REQUIREMENT_CONNECTIONS_INPROGRESS,
        payload: {
          inProgress: true,
        },
      });

      const res = await services.getSbfRequirementConnections();

      const connections = res.data;

      dispatch({
        type: constants.SET_SBF_REQUIREMENT_CONNECTIONS,
        payload: {
          connections,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const connectSbftoPigelloRequirement = ({
  companyId,
  sbfId,
  requirementId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const postData = {
        company: companyId,
        sbf_id: sbfId,
        requirement: parseInt(requirementId),
      };

      const res = await services.connectSbftoPigelloRequirement(postData);

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};
