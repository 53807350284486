import * as React from "react";

// store, state
import { constants } from "../../../../../store/industrialPremises";
import ObjectCostsBase from "../../../Costs/ObjectCostsBase";

export default ({ method }) => {
  return (
    <>
      <ObjectCostsBase constants={constants} method={method} />
    </>
  );
};
