import moment from "moment";
import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PopupButton,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { DoubleFieldWrapper } from "../../../components/Forms/Base/Chapters/styles";
import NonConnectedCheckbox from "../../../components/Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedSelect from "../../../components/Forms/Base/Old/NonConnected/NonConnectedSelect";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { useFilteredCompanies } from "../../../store/companies";
import { buildQueryString } from "../../../store/base";
import Toggle from "../../../components/Toggle/Toggle";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAdvancedPaymentsReport } from "../../../store/billectaReports/store/actions";
import { constants } from "../../../store/billectaReports";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import PaymentsReportInvoiceTable from "../../../components/Billecta/Reports/PaymentsReportInvoiceTable";
import PaymentsReportUnhandledPaymentTable from "../../../components/Billecta/Reports/PaymentsReportUnhandledPaymentTable";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import { useHistory } from "react-router-dom";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";

const REPORT_TYPES = {
  QUICK: "QUICK",
  EXCEL: "EXCEL",
  BGMAX: 0,
  TOTALIN: 1,
};

const PAYMENT_MEANS = [
  {
    v: "BG",
    d: "Bankgiro (BG)",
  },
  {
    v: "AG",
    d: "Autogiro (AG)",
  },
  {
    v: "SWISH",
    d: "Swish (SWISH)",
  },
  {
    v: "KORT",
    d: "Kort (KORT)",
  },
  {
    v: "KREDIT",
    d: "Kreditering (KREDIT)",
  },
  {
    v: "BFF",
    d: "Fakturaförsäljning (BFF)",
  },
  {
    v: "CAMT",
    d: "Bankkonto (CAMT)",
  },
];

const CATEGORIES = [
  {
    v: 0,
    d: "Betalningar",
  },
  {
    v: 1,
    d: "Krediteringar",
  },
  {
    v: 2,
    d: "Överbetalningar",
  },
  {
    v: 3,
    d: "Omatchade betalningar",
  },
  {
    v: 4,
    d: "Bortskrivningar",
  },
  {
    v: 5,
    d: "Öresavrundningar",
  },
  {
    v: 6,
    d: "Påminnelsebetalningar",
  },
];

export default function PaymentReport() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [reportType, setReportType] = React.useState(0);
  const [categoryType, setCategoryType] = React.useState(0);
  const [periodFrom, setPeriodFrom] = React.useState(
    moment().subtract({ months: 1 }).startOf("month")
  );
  const [periodTo, setPeriodTo] = React.useState(
    moment().subtract({ months: 1 }).endOf("month")
  );
  const [includeAllCompanies, setIncludeAllCompanies] = React.useState(true);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false);
  const [selectedCategories, setSelectedCategories] = React.useState([
    0, 2, 3, 6,
  ]);
  const [selectedPaymentMeans, setSelectedPaymentMeans] = React.useState([
    "BG",
    "AG",
    "SWISH",
    "KORT",
    "KREDIT",
    "BFF",
    "CAMT",
  ]);

  const advancedReportData = useSelector(
    (state) => state.billectaReports.advancedPaymentsReport
  );

  const [selectedTab, setSelectedTab] = React.useState("INVOICE_PAYMENTS");

  const TABS = {
    INVOICE_PAYMENTS: "Fakturabetalningar",
    OVER_PAYMENTS: "Överbetalningar",
    UNHANDLED_PAYMENTS: "Omatchade betalningar",
  };

  const overPayments = advancedReportData?.UnhandledPayments?.filter(
    (p) => p.UnhandledPaymentType !== "UnmatchedPayment"
  );
  const unhandledPayments = advancedReportData?.UnhandledPayments?.filter(
    (p) => p.UnhandledPaymentType === "UnmatchedPayment"
  );

  TABS.INVOICE_PAYMENTS = `${TABS.INVOICE_PAYMENTS} (${
    advancedReportData?.ValuesOnActions?.length || 0
  })`;
  TABS.UNHANDLED_PAYMENTS = `${TABS.UNHANDLED_PAYMENTS} (${
    unhandledPayments?.length || 0
  })`;
  TABS.OVER_PAYMENTS = `${TABS.OVER_PAYMENTS} (${overPayments?.length || 0})`;

  const persistantFilterMethod = (obj) => !!obj.billecta_id;
  const persistantQueryString = buildQueryString({
    billecta_id__isnull: false,
  });
  const [companies] = useFilteredCompanies(persistantQueryString);

  const handleTogglePaymentMean = (value) => {
    let clone = cloneDeep(selectedPaymentMeans);
    if (clone.includes(value)) {
      clone = clone.filter((pm) => pm !== value);
    } else {
      clone.push(value);
    }

    setSelectedPaymentMeans(clone);
  };

  const handleToggleCategory = (value) => {
    let clone = cloneDeep(selectedCategories);
    if (clone.includes(value)) {
      clone = clone.filter((pm) => pm !== value);
    } else {
      clone.push(value);
    }

    setSelectedCategories(clone);
  };

  const getReport = (exportType) => {
    setLoading(true);
    let creditorIds;

    if (includeAllCompanies) {
      creditorIds = companies
        ?.filter((b) => !!b.billecta_id)
        .map((c) => c.billecta_id);
    } else {
      creditorIds = selectedCompanies?.map((c) => c.billecta_id);
    }

    dispatch(
      getAdvancedPaymentsReport({
        quickview: exportType === REPORT_TYPES.QUICK,
        exportExcel: exportType === REPORT_TYPES.EXCEL,
        Format:
          exportType === REPORT_TYPES.EXCEL || exportType === REPORT_TYPES.QUICK
            ? 1
            : exportType,
        Creditors: creditorIds,
        From: periodFrom,
        To: periodTo,
        Types: selectedCategories,
        PaymentMeanCodes: selectedPaymentMeans,
        DateSelectionType: reportType,
        ResultSelectionType: categoryType,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.INFO,
              title: "Inbetalningsrapporten hämtades",
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                "Inbetalningsrapporten kunde inte hämtas. Kontrollera inställningarna och försök igen",
            })
          );
        },
      })
    );
  };

  const goToInvoice = (row) => {
    const invoiceId = row?.original?.ActionPublicId;
    const creditorId = row?.original?.CreditorPublicId;

    push(invoiceDetailUrl({ creditorId, invoiceId }));
  };

  return (
    <DetailInnerWrapper style={{ paddingBottom: 200 }}>
      <DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Inbetalningsrapport</OverviewTitle>
            <OverviewSubtitle>
              Analysera betalningar, krediteringar, bortskrivningar och
              ohanterade betalningar på kundfakturor. Du kan även exportera data
              i BgMax och TotalIN för import i extern kundreskontra
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
          {advancedReportData && (
            <PrimaryButton
              extraStyle={{ marginLeft: 24 }}
              title="Ny inbetalningsrapport"
              clicked={() => {
                dispatch({
                  type: constants.SET_ADVANCED_INCOMING_PAYMENTS,
                  payload: {
                    data: null,
                  },
                });
              }}
            />
          )}
        </OverviewTitleWrapper>

        {advancedReportData ? (
          <>
            <InnerBox>
              <div style={{ alignSelf: "flex-end" }}>
                <PageSlider
                  {...{ TABS }}
                  selectedTab={selectedTab}
                  onTabSelected={setSelectedTab}
                />
              </div>
              {advancedReportData?.ValuesOnActions &&
                selectedTab === "INVOICE_PAYMENTS" && (
                  <PaymentsReportInvoiceTable
                    reportRows={advancedReportData.ValuesOnActions}
                    handleRowClicked={goToInvoice}
                    companies={companies}
                  />
                )}

              {overPayments && selectedTab === "OVER_PAYMENTS" && (
                <PaymentsReportUnhandledPaymentTable
                  reportRows={overPayments}
                />
              )}

              {unhandledPayments && selectedTab === "UNHANDLED_PAYMENTS" && (
                <PaymentsReportUnhandledPaymentTable
                  reportRows={unhandledPayments}
                />
              )}
            </InnerBox>
          </>
        ) : (
          <>
            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Inställningar för rapport</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <DoubleFieldWrapper>
                <NonConnectedSelect
                  extraStyles={{ minWidth: "30%", marginRight: 12 }}
                  value={reportType}
                  onUpdate={(val) => setReportType(val)}
                  choices={[
                    { v: 0, d: "Transaktionsdatum" },
                    { v: 1, d: "Händelsedatum" },
                  ]}
                  getOptionLabel={(o) => o.d}
                  getOptionValue={(o) => o.v}
                  id="payment_report_period_type"
                  label="Perioden baseras på..."
                  description="Anger du att perioden ska vara 'Händelsedatum' tar exporten fram de betalningar utifrån när i tiden den hanterades av systemet/användaren mellan de 2 angivna datumen oavsett om något bakdaterats. Det är när händelsen uppstod som utgör sökkriteriet för urvalet. Väljer du istället 'Transaktionsdatum' så är sökkriteriet för urvalet på vilket datum betalningen skedde på banken, dvs transaktionsdatum/bokföringsdatum."
                />

                <NonConnectedDatePicker
                  value={periodFrom}
                  extraStyle={{ marginRight: 12 }}
                  label="Period från"
                  id="period_from_report"
                  onChange={(val) => setPeriodFrom(val)}
                />
                <NonConnectedDatePicker
                  value={periodTo}
                  label="Period till"
                  id="period_To_report"
                  onChange={(val) => setPeriodTo(val)}
                />
              </DoubleFieldWrapper>

              <NonConnectedCheckbox
                id="report_all_companies"
                description="Välj om du vill visa/exportera data från specifika bolag eller för alla aviserande bolag"
                title="Inkludera alla bolag"
                value={includeAllCompanies}
                noMargin
                extraStyles={{ marginBottom: 12 }}
                onChange={(val) => setIncludeAllCompanies(val)}
              />

              {!includeAllCompanies && (
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <LocalTableSelectField
                    value={selectedCompanies}
                    TableComponent={CompanyTable}
                    persistantQuery={{
                      billecta_id__isnull: false,
                    }}
                    placeholder="Välj bolag"
                    title="Bolag"
                    onChange={(val) => setSelectedCompanies(val)}
                    isMany
                  />
                </div>
              )}

              {!showAdvancedSettings && (
                <TextButton
                  title="Visa avancerade inställningar..."
                  clicked={() => setShowAdvancedSettings(true)}
                />
              )}

              {showAdvancedSettings && (
                <>
                  <NonConnectedSelect
                    extraStyles={{ marginTop: 12 }}
                    value={categoryType}
                    onUpdate={(val) => setCategoryType(val)}
                    choices={[
                      { v: 0, d: "Ingen kategorisering" },
                      { v: 1, d: "Per likviddag" },
                      { v: 2, d: "Per slutdatum på vald period" },
                    ]}
                    getOptionLabel={(o) => o.d}
                    getOptionValue={(o) => o.v}
                    id="payment_report_category_type"
                    label="Kategorisering av betalningar"
                    description="Anger du att resultatet ska vara 'Ingen kategorisering' tar exporten fram alla betalningar och dubbletter kan förekomma då betalningen visas som ohanterad och hanterad. Väljer du istället 'Per likviddag' så visas betalningen bara en gång så som den registrerades när den kom in i systemet. 'Per slutdatum på vald period' visar hur betalningarna såg ut per sista dagen i angiven period."
                  />

                  <DoubleFieldWrapper style={{ maxWidth: 800 }}>
                    <InnerBox style={{ flex: 1, marginRight: 24 }}>
                      <OverviewTitleWrapper>
                        <OverviewTitleWithSubtitleWrapper>
                          <OverviewTitle small>
                            Inkluderade betalsätt
                          </OverviewTitle>
                        </OverviewTitleWithSubtitleWrapper>
                      </OverviewTitleWrapper>

                      {PAYMENT_MEANS.map((pm) => {
                        return (
                          <Toggle
                            extraStyle={{ margin: "2px 0" }}
                            value={selectedPaymentMeans.includes(pm.v)}
                            onToggle={() => handleTogglePaymentMean(pm.v)}
                            title={pm.d}
                          />
                        );
                      })}
                    </InnerBox>

                    <InnerBox style={{ flex: 1 }}>
                      <OverviewTitleWrapper>
                        <OverviewTitleWithSubtitleWrapper>
                          <OverviewTitle small>
                            Inkluderade kategorier
                          </OverviewTitle>
                        </OverviewTitleWithSubtitleWrapper>
                      </OverviewTitleWrapper>

                      {CATEGORIES.map((pm) => {
                        return (
                          <Toggle
                            extraStyle={{ margin: "2px 0" }}
                            value={selectedCategories.includes(pm.v)}
                            onToggle={() => handleToggleCategory(pm.v)}
                            title={pm.d}
                          />
                        );
                      })}
                    </InnerBox>
                  </DoubleFieldWrapper>
                </>
              )}
            </InnerBox>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 24,
              }}
            >
              <SecondaryButton
                extraStyle={{ marginRight: 12 }}
                title="Visa snabböversikt"
                clicked={() => getReport(REPORT_TYPES.QUICK)}
              />
              <PopupButton
                actions={[
                  {
                    name: "Excel",
                    onClick: () => getReport(REPORT_TYPES.EXCEL),
                  },
                  {
                    name: "BgMax",
                    onClick: () => getReport(REPORT_TYPES.BGMAX),
                  },
                  {
                    name: "TotalIN",
                    onClick: () => getReport(REPORT_TYPES.TOTALIN),
                  },
                ]}
                title="Exportera som..."
              />
            </div>
          </>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
