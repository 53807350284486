import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  invoiceDetailUrl,
  getInvoiceIdPreview,
} from "../../../store/billectaInvoicing";
import { useDispatch, useSelector } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import moment from "moment";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { setInvoicesClosedFromDate } from "../../../store/billectaInvoicing/store/actions";
import ContainerSpinner from "../../../components/Loaders/ContainerSpinner";
import VacancyBookingsTable from "../../../components/Billecta/Invoices/VacancyBookingsTable";

export default function VacancyBookings() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { creditorId } = useParams();

  const vacancyBookingInvoices = useSelector(
    (state) => state.billectaInvoicing.vacancyBookingInvoices
  );
  const invoicesClosedFromDate = useSelector(
    (state) => state.billectaInvoicing.invoicesClosedFromDate
  );

  const handleDownloadInvoice = (invoice) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Hämtar faktura...",
      })
    );
    dispatch(
      getInvoiceIdPreview({
        invoiceId: invoice?.ActionPublicId,
        download: true,
      })
    );
  };

  const handleRowClicked = (row) => {
    push(
      invoiceDetailUrl({
        creditorId,
        invoiceId: row.original.ActionPublicId,
      })
    );
  };

  const renderClosedDatePicker = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 8 }}>Visa fakturor som är stängda sedan</div>

        <NonConnectedDatePicker
          inputExtraStyle={{ maxWidth: 160 }}
          id="billecta_date_from"
          value={
            invoicesClosedFromDate ||
            moment().subtract(3, "months").format("YYYY-MM-DD")
          }
          onChange={(date) => {
            dispatch(
              setInvoicesClosedFromDate({
                date,
                creditorId,
              })
            );
          }}
          clearable={false}
          noMargin
          maxDate={moment().toDate()}
        />
      </div>
    );
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Vakansbokningar</OverviewTitle>
          </OverviewTitleWrapper>

          {!!vacancyBookingInvoices ? (
            <VacancyBookingsTable
              invoices={vacancyBookingInvoices}
              handleDownloadInvoice={handleDownloadInvoice}
              handleRowClicked={handleRowClicked}
              renderBesideSearch={renderClosedDatePicker}
              noActions
              noFilters
            />
          ) : (
            <ContainerSpinner />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
