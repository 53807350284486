import * as React from "react";

import { constants } from "../../../../../store/invoicingOther";

import ContractCostsBase from "../../../Costs/ContractCostsBase";

export default ({
  method,
  skipInvoicing,
  goBackToInvoicingSetup,
  contract,
}) => {
  return (
    <ContractCostsBase
      {...{
        method,
        skipInvoicing,
        goBackToInvoicingSetup,
        contract,
        constants,
        hideCostsFromObject: true,
      }}
    />
  );
};
