import * as React from "react";

import {
  Confirm,
  Commodities,
  Parking,
  Included,
  Description,
} from "./Chapters";

export default (key, method) => {
  switch (key) {
    case "INCLUDED":
      return <Included method={method} />;
    case "COMMODITIES":
      return <Commodities method={method} />;

    case "PARKING":
      return <Parking method={method} />;
    case "DESCRIPTION":
      return <Description method={method} />;
    default:
      return <Confirm method={method} />;
  }
};
