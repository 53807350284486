import * as React from "react";
// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { buildRangeFilter } from "../../Lists/Base/utils";

import {
  useParkingInvoicingPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/invoicingParking";

export default ({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) => {
  // const filterInstructions = {
  //   next_period_invoice: {
  //     handler: (filterObject) => buildRangeFilter(filterObject),
  //   },
  //   start_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  //   end_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  // };

  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "parking_invoicing_full_table"}
      title={"Parkeringsavtal"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.parking_contract.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
};
