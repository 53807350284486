import { getPagination, buildQueryString } from "../../base";
import {
  getAvailableReportsUrl,
  createNewReportUrl,
  reRunReportUrl,
  updateCreatedReportTemplateUrl,
  deleteReportUrl,
  getLoadReportTemplateUrl,
} from "../utils";
import constants from "./constants";

import { addToast, TOAST_TYPES } from "../../toasts";

import {
  retrieve,
  upload,
  update,
  deleteData,
} from "../../base/store/services";
import axios from "axios";

import { paramsToObject } from "../hooks/retrieve";

export const purgeTemplatePathing = (querystring = undefined) => {
  return (dispatch) => {
    if (querystring !== undefined) {
      const queryInfo = new URLSearchParams(querystring);

      const entries = queryInfo.entries();
      const queryObject = paramsToObject(entries);

      if (queryObject.hasOwnProperty("_page")) {
        delete queryObject["_page"];
      }

      querystring = buildQueryString(queryObject);
    }

    dispatch({
      type: constants.PURGE_TEMPLATE_PATHING,
      payload: querystring,
    });
  };
};

export const setTemplatePathing = (newPathing) => {
  return (dispatch) => {
    dispatch({
      type: constants.SET_TEMPLATE_PATHING,
      payload: newPathing,
    });
  };
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const setBadgeFilterCounts = (badgeFilterCounts) => {
  return (dispatch) => {
    dispatch({
      type: constants.SET_BADGE_FILTER_COUNTS,
      payload: badgeFilterCounts,
    });
  };
};

export const setHotRefresh = (report) => {
  return (dispatch) => {
    dispatch({
      type: constants.SET_HOT_REFRESH,
      payload: report,
    });
  };
};

export const removeReportInAll = (report, query) => {
  return (dispatch) => {
    dispatch({
      type: constants.REMOVE_REPORT_IN_ALL,
      payload: { report, query },
    });
  };
};

export const updateReportInAll = (report) => {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_REPORT_IN_ALL,
      payload: report,
    });
  };
};

const requestCleanup = (dispatch) => {
  dispatch({
    type: constants.PURGE_FORM_ERRORS,
  });
};

const handleError = (dispatch, errData) => {
  if (!errData) return;
  dispatch({
    type: constants.SET_FORM_ERROR,
    payload: { result: errData },
  });
};

export const getInstancesByIds = async ({ constants, ids }) => {
  let res = undefined;
  await new Promise((resolve, reject) => {
    retrieve({
      url: `${constants.LIST_URL}?${buildQueryString({
        id__in: ids,
      })}`,
    })
      .then((_res) => {
        res = _res.data.map((obj) => {
          return {
            id: obj.id,
            str_representation: obj.str_representation,
          };
        });
        resolve();
      })
      .catch((err) => {
        console.log("error");
        res = ids.map((id) => {
          return { id, str_representation: "Okänt objekt" };
        });
        resolve();
      });
  });

  return res;
};

export const purgeAll = () => {
  return (dispatch) => {
    dispatch({
      type: constants.PURGE_ALL,
    });
  };
};

export const setSelectedReportTab = (path) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_SELECTED_REPORT_TAB,
      payload: path,
    });
  };
};

export const getAvailableReports = (successCallback, errorCallback) => {
  const url = getAvailableReportsUrl();

  return async (dispatch) => {
    retrieve({ url: url })
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          //User doesnt have permission to view any reports
          res.data["NO_REPORTS"] = "NO_REPORTS";
        }

        // clean backend wrong filters
        try {
          delete res.data.invoicing_invoicedreportgenerator.options.filters
            .realestate_ids;
        } catch (e) {
          console.log("Could not clean ");
        }

        dispatch({
          type: constants.SET_AVAILABLE_REPORTS,
          payload: {
            allAvailableReports: res?.data,
          },
        });

        successCallback && successCallback(res);
        requestCleanup(dispatch);
      })
      .catch((err) => {
        console.log("unable to fetch all available reports");

        //User doesnt have permission to view any reports
        let data = {
          NO_REPORTS: "NO_REPORTS",
        };

        dispatch({
          type: constants.SET_AVAILABLE_REPORTS,
          payload: {
            allAvailableReports: data,
          },
        });

        errorCallback
          ? errorCallback(err.response.data, err)
          : dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Ett fel har uppstått",
                description: "Ladda om sidan och försök igen",
              })
            );
      });
  };
};

export const setCurrentReportData = (reportData) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_REPORT_DATA,
      payload: reportData,
    });
  };
};

export const setCurrentReportStructure = (structure) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_REPORT_STRUCTURE,
      payload: structure,
    });
  };
};

export const deleteReport = ({ id, onSuccess, onError }) => {
  const url = deleteReportUrl(id);

  return async (dispatch) => {
    deleteData({ url, id })
      .then((res) => {
        onSuccess && onSuccess(res);
        requestCleanup(dispatch);
      })
      .catch((err) => {
        handleError(dispatch, err.response.data);
        onError
          ? onError(err.response.data, err)
          : dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Ett fel har uppstått",
                description: "Ladda om sidan och försök igen",
              })
            );
      });
  };
};

export const hotLoadReportTemplate = ({ id, onSuccess, onError }) => {
  const url = getLoadReportTemplateUrl(id);

  console.log("hot load", url);

  return async (dispatch) => {
    retrieve({ url })
      .then((res) => {
        onSuccess && onSuccess(res);
        requestCleanup(dispatch);
      })
      .catch((err) => {
        handleError(dispatch, err.response.data);
        onError && onError(err.response.data, err);
      });
  };
};

//Cannot set these params in creation, can only change them here.
//postData: {
// is_template: true,
// is_starred: postData.is_starred,
// shared_with: postData.shared_with,
// }
export const updateMiscForReport = ({
  reportId,
  postData,
  onSuccess,
  onError,
}) => {
  return async (dispatch) => {
    update({
      url: updateCreatedReportTemplateUrl(reportId),
      data: postData,
    })
      .then((res) => {
        onSuccess && onSuccess(res, postData);
        requestCleanup(dispatch);
      })
      .catch((err) => {
        handleError(dispatch, err.response.data);
        onError
          ? onError(err.response.data, err)
          : dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Ett fel har uppstått",
                description: "Ladda om sidan och försök igen",
              })
            );
      });
  };
};

export const updateReportTemplate = ({
  reportData,
  postData,
  onSuccess,
  onError,
}) => {
  return async (dispatch) => {
    update({
      url: updateCreatedReportTemplateUrl(reportData.id),
      data: postData,
    })
      .then((res) => {
        onSuccess && onSuccess(res, postData);
        requestCleanup(dispatch);
      })
      .catch((err) => {
        handleError(dispatch, err.response.data);
        onError
          ? onError(err.response.data, err)
          : dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Ett fel har uppstått",
                description: "Ladda om sidan och försök igen",
              })
            );
      });
  };
};

export const createReportTemplate = ({
  reportData,
  reportStructure,
  postData,
  onSuccess,
  onError,
}) => {
  return async (dispatch) => {
    dispatch(
      createNewReport({
        reportData,
        reportStructure,
        postData,
        onSuccess: (res) => {
          dispatch(
            updateMiscForReport({
              reportId: res.data.id,
              postData: {
                is_template: true,
                is_starred: postData.is_starred,
                shared_with: postData.shared_with,
              },
              onSuccess: (res) => {
                onSuccess && onSuccess(res, postData);
                requestCleanup(dispatch);
              },
              onError: (err) => {
                handleError(dispatch, err.response.data);
                onError
                  ? onError(err.response.data, err)
                  : dispatch(
                      addToast({
                        type: TOAST_TYPES.ERROR,
                        title: "Ett fel har uppstått",
                        description: "Ladda om sidan och försök igen",
                      })
                    );
              },
            })
          );
        },
        onError: (err) => {
          handleError(dispatch, err.response.data);
          onError
            ? onError(err?.response?.data ? err?.response?.data : err, err)
            : dispatch(
                addToast({
                  type: TOAST_TYPES.ERROR,
                  title: "Ett fel har uppstått",
                  description: "Ladda om sidan och försök igen",
                })
              );
        },
      })
    );
  };
};

export const createNewReport = ({
  reportData,
  reportStructure,
  postData,
  onSuccess,
  onError,
}) => {
  const url = createNewReportUrl(reportStructure.createPath);

  return async (dispatch) => {
    upload({
      url,
      data: postData,
    })
      .then((res) => {
        onSuccess && onSuccess(res);
        requestCleanup(dispatch);
      })
      .catch((err, res) => {
        handleError(dispatch, err?.response?.data);

        try {
          let errorData = err;
          if (errorData?.response) errorData = errorData.response?.data;

          onError
            ? onError(errorData, err)
            : dispatch(
                addToast({
                  type: TOAST_TYPES.ERROR,
                  title: "Ett fel har uppstått",
                  description: "Ladda om sidan och försök igen",
                })
              );
        } catch (_err) {
          onError
            ? onError(err, err)
            : dispatch(
                addToast({
                  type: TOAST_TYPES.ERROR,
                  title: "Ett fel har uppstått",
                  description: "Ladda om sidan och försök igen",
                })
              );
        }
      });
  };
};

export const reRunReport = ({
  reportData,
  reportStructure,
  postData,
  onSuccess,
  onError,
}) => {
  const url = reRunReportUrl(reportData.id);

  return async (dispatch) => {
    upload({ url, data: postData })
      .then((res) => {
        onSuccess && onSuccess(res);
        requestCleanup(dispatch);
      })
      .catch((err) => {
        handleError(dispatch, err.response.data);
        onError
          ? onError(err.response.data, err)
          : dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Ett fel har uppstått",
                description: "Ladda om sidan och försök igen",
              })
            );
      });
  };
};

export const downloadReportFile = (url, filename, format) => {
  if (format === "json") {
    const awsAxois = axios.create({
      transformRequest: (data, headers) => {
        delete headers.common;
      },
    });

    awsAxois
      .get(url, {
        headers: { "Access-Control-Expose-Headers": "Access-Token, Uid" },
      })
      .then((res) => {
        let a = document.createElement("a");

        let str = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(res.data)
        )}`;

        a.href = str;

        // Give filename you wish to download
        a.download = filename;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log("got error", err);
      });
  } else {
    const dl = document.createElement("a");
    dl.href = url;
    dl.target = "_blank";
    dl.download = filename;
    dl.click();
  }
};
