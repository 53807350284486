export default {
  SET_CURRENT_OBJECT: "SET_CURRENT_OBJECT",
  SET_WHITELIST_OBJECT: "SET_WHITELIST_OBJECT",
  SET_ROWS: "SET_ROWS",
  GET_WHITELIST_OBJECT: "GET_WHITELIST_OBJECT",
  REMOVE_WHITELIST_OBJECT: "REMOVE_WHITELIST_OBJECT",

  PUT_URL: "/permissions/permission_handling/",
  LIST_URL: "/permissions/permission_handling/",

  STORE_NAME: "whitelistPerms",
};
