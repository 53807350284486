import * as React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

//store, state
import { setActiveFormInstance, useAllPermissionCheck } from "../../store/base";
import {
  useReportErrandsPaginationCount,
  constants,
} from "../../store/reportErrands";
import useActiveFilters from "../../hooks/useActiveFilters";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

//components & styles
import Errands from "./overview/Errands";
import FortnoxInvoices from "./overview/FortnoxInvoices";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

export default function MainReportErrandsOverview() {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { filteredRealEstates } = useActiveFilters();

  usePermissionRedirect(["allow_errand_faults", "view_can_reporterrand"]);

  const [activeCount] = useReportErrandsPaginationCount({
    realEstates: filteredRealEstates,
  });

  //setting is included in reporterrand
  const canAdd = useAllPermissionCheck([
    "add_can_reporterrand",
    "allow_errand_faults",
  ]);

  const canViewFortnox = useAllPermissionCheck([
    "view_can_fortnox",
    "allow_fortnox",
  ]);

  const SUB_PAGES = [
    {
      path: "/errands",
      label: "Ärenden",
      component: Errands,
    },
  ];

  // if (canViewFortnox) {
  //   SUB_PAGES.push({
  //     path: "/fortnoxinvoices",
  //     label: "Leverantörsfakturor",
  //     component: FortnoxInvoices,
  //   });
  // }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0} style={{ marginRight: 12 }}>
          Antal ärenden: {activeCount == null ? "Laddar..." : activeCount}
        </StatusLabel>
      </>
    );
  };

  React.useEffect(() => {
    dispatch(setActiveFormInstance({ storeName, data: {} }));
  }, []);

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Felanmälningar`}
          {...{ subPages: subPageLinks, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
