import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { DateCell } from "../../Displays";

const STATE_MAP = {
  Deleted: "Borttagen",
  UsedOnInvoice: "Matchad mot faktura",
  Unhandled: "Ej hanterad",
};

export default ({ reportRows, fromDate, toDate, handleRowClicked }) => {
  const data = React.useMemo(() => {
    return reportRows;
  }, [reportRows]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Referens",
        accessor: "Reference",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
        Footer: () => {
          return <Field style={{ fontWeight: 600 }}>Total sida:</Field>;
        },
        TotalFooter: () => {
          return <Field style={{ fontWeight: 600 }}>Total:</Field>;
        },
      },
      {
        Header: "Bolag",
        accessor: "CreditorName",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Status",
        accessor: "State",
        Cell: (props) => {
          return <Field>{STATE_MAP[props.value] || props.value || "-"}</Field>;
        },
      },
      {
        Header: "Inbetalare namn",
        accessor: "SenderName",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Belopp (SEK)",
        accessor: "Amount.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")} </Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.Amount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")} </Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.rows.reduce((acc, cur) => {
            const val = cur.original.Amount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")} </Field>;
        },
      },
      {
        Header: "Betaldatum",
        accessor: "PaymentDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Inbetalare adress",
        accessor: "SenderAddress",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Inbetalare postnummer",
        accessor: "SenderZipCode",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Inbetalare ort",
        accessor: "SenderCity",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
      {
        Header: "Inbetalare bankgironummer",
        accessor: "SenderBankgiroNo",
        Cell: (props) => {
          return <Field>{props.value || "-"}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        exportName={`rapport_${fromDate}-${toDate}`}
        withExport
        onRowClicked={handleRowClicked}
        withSummaryFooter
        withTotalSummaryFooter
        tableId="paymentreporunhandledpaymenttable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
