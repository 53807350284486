import * as React from "react";

export default () => {
  return (
    <div>
      Ladda upp huvuddokumentet för avtalet om det finns ett sådant. <br />
      <br />
      Dokumentet går att visa/dölja för att underlätta ifyllande av data genom
      att trycka på den blå dokumentfliken till vänster.{" "}
    </div>
  );
};
