import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";

import { usePermissionCheck } from "../../store/base";

import {
  useParkingSpecification,
  constants,
} from "../../store/parkingSpecifications";
import ParkingSpecificationOverview from "./specificationDetails/Overview";

export default function MainSpecificationDetails() {
  const { specificationId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [specification] = useParkingSpecification(specificationId);
  const canDelete = usePermissionCheck("delete_can_parking");

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: ParkingSpecificationOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={specification}
        constants={constants}
        deletedCallback={() => replace("/parking/parking-specifications")}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Specifikation ${
            specification?.str_representation || "Laddar..."
          }`}
          breadCrumbs={[
            {
              url: "/parking/parking-specifications",
              label: "Specifikationer",
            },
            { label: specification?.str_representation || "Laddar..." },
          ]}
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
