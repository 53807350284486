import { cloneDeep } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  clearFetched,
} from "../../base";
import { axiosInstance } from "../../base/store/axios";
import { getSingleFetchProcessName } from "../../base/utils";
import { store } from "../../store";
import constants from "./constants";
import * as services from "./services";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};
export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const createMany = ({ postData, successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(constants.LIST_URL, postData);

      successCallback && successCallback();
    } catch (e) {
      console.log({ e });

      errorCallback && errorCallback();
    }
  };
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess,
    forceData,
  });
};

export const clearFetchedData = () => {
  return clearFetched(constants);
};

export const addApartment = ({ apartment }) => {
  return async (dispatch) => {
    const defaultSettings = store.getState().marketApartmentAds.defaultSettings;

    apartment = { ...apartment, ...defaultSettings };

    dispatch({
      type: constants.ADD_APARTMENT,
      payload: {
        apartment,
      },
    });
  };
};

export const addMultipleApartments = ({ apartments }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.ADD_MULTIPLE_APARTMENTS,
      payload: {
        apartments,
      },
    });
  };
};

export const removeApartment = ({ apartment }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_APARTMENT,
      payload: {
        apartment,
      },
    });
  };
};

export const setAds = ({ newAds }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_ADS,
      payload: {
        newAds,
      },
    });
  };
};

export const setPublishDateForId = ({ id, val }) => {
  return async (dispatch) => {
    const ads = cloneDeep(store.getState().marketApartmentAds.ads);

    const cur = ads.find((i) => i.id === id);

    cur.date_publish = val;

    dispatch({
      type: constants.SET_ADS,
      payload: {
        newAds: ads,
      },
    });
  };
};

export const setAccessDateForId = ({ id, val }) => {
  return async (dispatch) => {
    const ads = cloneDeep(store.getState().marketApartmentAds.ads);

    const cur = ads.find((i) => i.id === id);

    cur.access_date = val;

    dispatch({
      type: constants.SET_ADS,
      payload: {
        newAds: ads,
      },
    });
  };
};

export const updateDefaultSettings = ({ attr, val }) => {
  return async (dispatch) => {
    const settings = cloneDeep(
      store.getState().marketApartmentAds.defaultSettings
    );
    settings[attr] = val;

    dispatch({
      type: constants.SET_DEFAULT_SETTINGS,
      payload: {
        settings,
      },
    });
  };
};

export const publishNewProductionApartments = ({
  postObj,
  pipeId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const { data } = await services.publishNewProductionApartments({
        postObj,
        pipeId,
      });

      successCallback && successCallback(data);
    } catch (e) {
      const responseData = e.response?.data;
      errorCallback && errorCallback(responseData);
    }
  };
};

export const clearNewProductionFlowData = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_NEW_PRODUCTION_FLOW_DATA,
    });
  };
};
