import moment from "moment";
import baseReducer from "../../billecta/reducer";
import constants from "./constants";

const INITIAL_STATE = {
  connectedPaymentFromDate: moment()
    .subtract({ months: 1 })
    .format("YYYY-MM-DD"),
  connectedPaymentToDate: moment().format("YYYY-MM-DD"),
  all: {},
  allCopy: {},
  inProgress: [],
  paginations: {},
  paginationsCopy: {},
  filtered: {},
  filteredCopy: {},
  backgroundTasks: {},
  hasFetchedAll: false,

  forms: {},
  formInstance: {},
  formErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.RESET_STATE: {
      return {
        ...newState,
        invoices: undefined,
        vacancyBookingInvoices: undefined,
        payments: undefined,
      };
    }

    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_ALL_INVOICES: {
      return {
        ...newState,
        invoices: payload.invoices,
        vacancyBookingInvoices: payload.vacancyBookingInvoices,
      };
    }

    case constants.SET_PAYMENTS: {
      return { ...newState, payments: payload.payments };
    }

    case constants.SET_CURRENT_INVOICE: {
      return {
        ...newState,
        currentInvoice: payload.invoice,
        currentInvoiceType: payload.invoiceType,
      };
    }

    case constants.SET_CURRENT_DEBT_INVOICE_INFO: {
      return {
        ...newState,
        currentDebtInvoiceInfo: payload.debtInvoiceInfo,
      };
    }

    case constants.SET_CURRENT_REMINDER_INVOICE: {
      return {
        ...newState,
        currentReminderInvoice: payload.reminderInvoice,
      };
    }

    case constants.SET_CURRENT_INVOICE_PREVIEW_PDF: {
      return {
        ...newState,
        currentInvoicePreview: {
          id: payload.id,
          pdf: payload.pdf,
        },
      };
    }

    case constants.CLEAR_CURRENT_INVOICE_PREVIEW_PDF: {
      return {
        ...newState,
        currentInvoicePreview: null,
      };
    }

    case constants.SET_DELETE_INVOICES_LOADING: {
      return {
        ...newState,
        deleteInvoicesLoading: payload.loading,
      };
    }

    case constants.SET_CLOSED_FROM_DATE: {
      return {
        ...newState,
        invoicesClosedFromDate: payload.date,
      };
    }

    case constants.SET_DEBTOR_INVOICE_SEARCH_RESULT: {
      return {
        ...newState,
        [payload.identifier]: payload.invoices,
      };
    }

    case constants.SET_CONNECTED_PAYMENT_DATE_FROM: {
      return {
        ...newState,
        connectedPaymentFromDate: payload.date,
      };
    }

    default:
      return newState;
  }
};
