import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";
export default () => [
  {
    Header: "Bild",
    accessor: "image.get",
    Cell: ({ value }) => (
      <div
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "5px",
          backgroundImage: `url(${value})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      ></div>
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Namn",
    accessor: "name",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fastighet",
    accessor: "realestate.str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Adress",
    accessor: "addresses[0].str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
