export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PPTREPORTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PPTREPORTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PPTREPORTS",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PPTREPORTS",
  DESTROY_FORM: "DESTROY_FORM_PPTREPORTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_PPTREPORTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PPTREPORTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PPTREPORTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PPTREPORTS",
  RESET_STATE: "RESET_STATE_PPTREPORTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_PPTREPORTSS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PPTREPORTS",
  REMOVE_OBJECT: "REMOVE_OBJECTL_PPTREPORTS",

  LIST_URL: "/reporting/generators/powerpoint/performedreport/list/",
  GET_URL: "/reporting/generators/powerpoint/performedreport/",
  PPT_AVAILABLE_REPORTS_URL:
    "/reporting/generators/powerpoint/available_reports/",
  PPT_CREATE_REPORT_URL: "/reporting/generators/powerpoint/run_report/",
  PPT_RERUN_REPORT_URL: "/reporting/generators/powerpoint/rerun_report/",
  PPT_PDF_URL: "/reporting/generators/powerpoint/pdfprinturl/",

  STORE_NAME: "powerPointReports",
};
