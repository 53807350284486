const defs = [
  {
    title: "Lägenheten",
    key: "APARTMENT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "apartment_id",
      "premises_id",
      "category",
      "area",
      "floors",
      "building",
      "address",
      "images",
      "landlord",
      "tenant",
      "bi_tenants",
      "vacancy_booking_company",
    ],
  },
  {
    title: "Bilder",
    key: "IMAGES",
    visited: true,
    hasError: false,
    fieldKeys: ["images"],
  },
  {
    title: "Debiteringsrader",
    key: "COSTS",
    visited: true,
    hasError: false,
    fieldKeys: ["cost_set"],
  },

  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
