import * as React from "react";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import DocsList from "src/components/Tables/Parking/ParkingContracts/FullTableNewVersionDocs";
import { detailUrl } from "src/store/parkingContracts";
import { useHistory } from "react-router-dom";

export default function ParkingContractDocuments() {
  const { filteredRealEstates } = useActiveFilters();
  const { push } = useHistory();

  const persistantQuery = {
    has_scrive: true,
    is_template: false,
    is_parking: true,
    realestate__in: filteredRealEstates,
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Digital signering av parkeringsavtal</OverviewTitle>
          </OverviewTitleWrapper>

          <DocsList
            onRowClicked={(obj) =>
              push(detailUrl({ id: obj.parking_contract?.id }))
            }
            persistantQuery={persistantQuery}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
