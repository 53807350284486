import * as React from "react";
import { useDispatch } from "react-redux";

import { constants } from "../../../../../store/roundingErrands";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import ManyMediaField from "src/components/Forms/Base/Fields/ManyMediaField";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const docFileName = useFormInstanceField({
    storeName,
    fieldKey: "completion_files[0].file",
  });
  const docTitle = useFormInstanceField({
    storeName,
    fieldKey: "title",
  });

  React.useEffect(() => {
    if (docFileName && !docTitle) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { title: docFileName },
        })
      );
    }
  }, [docFileName]);

  return (
    <>
      <div className="mb-6">
        <ManyMediaField
          storeName={storeName}
          fieldKey={"completion_files"}
          method={method}
          title="Dokument "
          fileKey={"file"}
          allowedFormats={[".pdf"]}
        />
      </div>
    </>
  );
};
