import * as React from "react";

import {
  NumberInput,
  RadioGroup,
  TextInput,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/loan";
export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
    <NumberInput
      title="Antal dagar i år"
      description="Systemet räknar med att detta lån är skrivet utifrån premissen att antalet dagar på ett år (e.x 360) är det angivna värdet"
      storeName={storeName}
      method={method}
      fieldKey={"days_in_year"}
    />

      <RadioGroup
        title="Godtagbart för kapitaltillskott"
        description="Indikerar på om avgifter som betalas in avseende detta lånet, är godtagbara för att bidra till kapitaltillskottet som en medlem gör. Det är exempelvis ofta godtagbart om lånet finansierar ny-byggnationer men inte om det finansierar renoveringar."
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"consider_capital_contribution"}
        defaultValue={true}
        />
     <TextInput
      title="Bank"
      description="Banken som utfärdat lånet"
      storeName={storeName}
      method={method}
      fieldKey={"bank"}
     />
     <TextInput
      title="Referensnummer"
      description="Referensnummer till lånet hos banken"
      storeName={storeName}
      method={method}
      fieldKey={"reference_number"}
     />
    </>
  );
};
