import * as React from "react";

// style, design
import { BooleanLabel, ToolTipCell } from "../../Displays";
import SimpleBooleanFilter from "src/components/Lists/Base/CompleteList/Filters/BooleanFilter";
import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

export default () => [
  {
    Header: "Namn",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Enhet",
    accessor: "unit_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Typ",
    accessor: "kind_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Importerad från",
    accessor: "imported_from_display",
    Cell: ({ value }) => <ToolTipCell text={value ?? "Manuellt"} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Fakturerbar",
    accessor: "billable",
    Cell: ({ value }) => (
      <BooleanLabel value={value} onLabel={"Ja"} offLabel={"Nej"} />
    ),
    Filter: SimpleBooleanFilter,
    filter: "text",
  },
  {
    Header: "Produkt kategori",
    accessor: "product_category_display",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];
