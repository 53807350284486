import * as React from "react";
import { constants } from "../../../../../store/tenantPortalSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { RadioGroup } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Funktioner</OverviewTitle>
          <OverviewSubtitle>
            Ställ in vad hyresgäster ska kunna göra i portalen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      <RadioGroup
        title="Skapa felanmälningar"
        description="Om denna funktion är avstängd syns ej felanmälanformuläret i portalen"
        storeName={storeName}
        method={method}
        options={[
          { label: "Inaktivera felanmälan via portalen", value: true },
          { label: "Tillåt felanmälan via portalen", value: false },
        ]}
        fieldKey={`hide_errand_handling`}
      />

      <RadioGroup
        title="Redigera personuppgifter & kontaktinformation"
        description="Om denna funktion är avstängd kan hyresgäster ej uppdatera t.ex. namn, e-post och personnummer"
        storeName={storeName}
        method={method}
        options={[
          { label: "Inaktivera redigering via portalen", value: true },
          { label: "Tillåt redigering via portalen", value: false },
        ]}
        fieldKey={`hide_personal_info_edit_fields`}
      />
    </>
  );
};
