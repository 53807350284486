import * as React from "react";
import { useParams } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

import { constants, detailUrl } from "../../../store/servicePartners";
import { usePermissionCheck } from "../../../store/base";

export default function ServicePartnerTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  // const filterInstructions = {
  //   address: {
  //     handler: (filterObject) => {
  //       return buildQueryString({ address__base: filterObject.value });
  //     },
  //   },
  //   invoice_address: {
  //     handler: (filterObject) => {
  //       return buildQueryString({ invoice__address__base: filterObject.value });
  //     },
  //   },
  // };

  const columns = React.useMemo(() => columnDefs, [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "servicepartners_full_table"}
      title={"Service partners"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
