import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default function CheckField({
  fieldKey,
  storeName,
  instructionsKey,
  method,
  title,
  description,
  forceRequired,
  disabled,
}) {
  const dispatch = useDispatch();
  const instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });
  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey });

  const onChange = (checked) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: checked,
        },
      })
    );
  };

  if (!instructions || instructions?._readOnly) return null;

  return (
    <div>
      <div className="flex">
        <div className="flex items-center h-5">
          <input
            id={`check-${fieldKey}`}
            type="checkbox"
            value=""
            checked={value}
            disabled={disabled}
            onChange={({ target: { checked } }) => onChange(checked)}
            className={`w-4  h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 ${
              disabled ? "cursor-not-allowed" : ""
            }`}
          />
        </div>
        <div className="ml-2 text-sm">
          <label
            htmlFor={`check-${fieldKey}`}
            className="font-medium text-gray-900 "
          >
            {title}
            {instructions?._required || forceRequired ? "*" : ""}
          </label>
          {description && (
            <p
              id="helper-checkbox-text"
              className="text-xs font-normal text-gray-500 bg-transparent "
            >
              {description}
            </p>
          )}
          {error && (
            <p
              id="helper-checkbox-text"
              className="text-xs font-normal text-red-700  bg-transparent"
            >
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
