import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";

import constants from "./constants";

const STATE = {
  ...INITIAL_STATE,
};

export default (state = STATE, action) => {
  return baseReducer(state, action, constants);
};
