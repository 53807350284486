import * as React from "react";
import theme from "../../../theme";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { DetailPageBox } from "../../sharedStyles";

export default function PerformerStatusBar({
  errand,
  currentUser,
  onPerform,
  onAccept,
  onPause,
  onDecline,
  onPlan,
  loading,
  canPause,
  canStart,
  onStart,
}) {
  return (
    <>
      {errand?.requested_performer?.user?.id === currentUser?.id &&
        !errand?.performer &&
        ![3, 4].includes(errand?.status) && (
          <DetailPageBox style={{ backgroundColor: theme.colors.primaryBlue }}>
            {loading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle style={{ color: "#fff" }} small>
                  Du har blivit tillfrågad att utföra detta ärende
                </OverviewTitle>
                <OverviewSubtitle style={{ color: "#fff" }}>
                  Acceptera eller neka ärendet nedan för att komma vidare. Om du
                  nekar ärendet går det vidare till nästa utförare.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div style={{ display: "flex" }}>
              <PrimaryButton
                extraStyle={{ backgroundColor: theme.colors.green }}
                title="Acceptera ärende"
                clicked={onAccept}
              />
              <PrimaryButton
                extraStyle={{
                  backgroundColor: theme.colors.red,
                  marginLeft: 12,
                }}
                title="Neka ärende"
                clicked={onDecline}
              />
            </div>
          </DetailPageBox>
        )}

      {errand?.performer?.user?.id === currentUser?.id &&
        ![3, 4].includes(errand?.status) && (
          <DetailPageBox style={{ backgroundColor: theme.colors.primaryBlue }}>
            {loading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle style={{ color: "#fff" }} small>
                  Du har accepterat ärendet
                </OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div style={{ display: "flex" }}>
              <PrimaryButton
                extraStyle={{
                  backgroundColor: theme.colors.blueLight,
                  color: theme.colors.primaryBlue,
                }}
                title="Planera ärende"
                clicked={onPlan}
              />

              {canStart ? (
                <PrimaryButton
                  extraStyle={{
                    backgroundColor: theme.colors.green,
                    marginLeft: 12,
                  }}
                  title="Markera som påbörjat"
                  clicked={onStart}
                />
              ) : (
                <PrimaryButton
                  extraStyle={{
                    backgroundColor: theme.colors.green,
                    marginLeft: 12,
                  }}
                  title="Utför ärende"
                  clicked={onPerform}
                />
              )}
              {canPause && (
                <PrimaryButton
                  extraStyle={{
                    backgroundColor: theme.colors.yellow,
                    marginLeft: 12,
                  }}
                  title="Pausa ärende"
                  clicked={onPause}
                />
              )}
              <PrimaryButton
                extraStyle={{
                  backgroundColor: theme.colors.red,
                  marginLeft: "auto",
                }}
                title="Neka ärende"
                clicked={onDecline}
              />
            </div>
          </DetailPageBox>
        )}
    </>
  );
}
