import * as React from "react";

import { RadioGroup } from "../../../Base/Fields";
import { constants } from "../../../../../store/marketParkingAds";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Publicering av annons</OverviewTitle>
          <OverviewSubtitle>
            Ställ in var annonsen ska publiceras
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <RadioGroup
        extraStyles={{ flex: 1 }}
        fieldKey="publish_pigello"
        title="Publicera i Pigello Marknadsplats"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
        {...{ storeName, method }}
      />
    </>
  );
};
