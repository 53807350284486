import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function fetchAskUpdateSharedSettingLeaseInvoicing(data) {
  const res = await axiosInstance.patch(
    constants.ASK_UPDATE_SHARED_SETTING_URL_LEASE_INVOICING,
    data
  );
  return res;
}

async function fetchAskUpdateSharedSettingOtherInvoicing(data) {
  const res = await axiosInstance.patch(
    constants.ASK_UPDATE_SHARED_SETTING_URL_OTHER_INVOICING,
    data
  );
  return res;
}

async function fetchAskUpdateSharedSettingParkingInvoicing(data) {
  const res = await axiosInstance.patch(
    constants.ASK_UPDATE_SHARED_SETTING_URL_PARKING_INVOICING,
    data
  );
  return res;
}

async function fetchAskUpdateSharedSettingBrfInvoicing(data) {
  const res = await axiosInstance.patch(
    constants.ASK_UPDATE_SHARED_SETTING_URL_BRF_INVOICING,
    data
  );
  return res;
}

export {
  fetchAskUpdateSharedSettingLeaseInvoicing,
  fetchAskUpdateSharedSettingOtherInvoicing,
  fetchAskUpdateSharedSettingParkingInvoicing,
  fetchAskUpdateSharedSettingBrfInvoicing,
};
