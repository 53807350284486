import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useTodoPremisesStatistics } from "../../../store/overview";

import ApartmentTable from "../../../components/Tables/Apartments/FullTableNewVersion";
import IndustrialPremises from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import ParkingSpotTable from "../../../components/Tables/Parking/ParkingSpots/FullTable";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import useActiveFilters from "../../../hooks/useActiveFilters";

const READABLE_TYPES = {
  vacant_warning: "Kommande vakanser",
};

const READABLE_CONTENT_TYPES = {
  "standard.apartment": "Lägenheter",
  "standard.industrialpremises": "Lokaler",
  "standard.parkingspot": "Fordonsplatser",
};

const TABLE_MAP = {
  "standard.apartment": ApartmentTable,
  "standard.industrialpremises": IndustrialPremises,
  "standard.parkingspot": ParkingSpotTable,
};

export default function ObjectsTodo() {
  const { filteredRealEstates } = useActiveFilters();
  const [openTable, setOpenTable] = React.useState(null);
  const [rawTodoPremisesStats, premisesLoading] = useTodoPremisesStatistics();

  const filteredStatKeys = Object.keys(rawTodoPremisesStats?.data || {}).filter(
    (key) => rawTodoPremisesStats?.data?.[key]?.length > 0
  );

  const byContentType = (data) => {
    const contentTypes = [];

    data.forEach((d) => {
      if (contentTypes.includes(d.content_type)) return;

      contentTypes.push(d.content_type);
    });

    return contentTypes;
  };

  return (
    <DetailInnerWrapper>
      {filteredStatKeys?.length > 0 ? (
        <>
          {filteredStatKeys.map((key) => {
            const data = rawTodoPremisesStats?.data[key];

            const contentTypes = byContentType(data);

            return (
              <DetailPageBox key={key}>
                <OverviewTitleWrapper>
                  <OverviewTitle>
                    {READABLE_TYPES[key]} ({data?.length})
                  </OverviewTitle>
                </OverviewTitleWrapper>

                {contentTypes?.map((ct) => {
                  const contentTypeData = data.filter(
                    (d) => d.content_type === ct
                  );

                  const TableComp = TABLE_MAP[ct];

                  const ids = contentTypeData?.map((d) => d.id);

                  const persistantQuery = {
                    id__in: ids,
                  };

                  const open = openTable === `${key}${ct}`;

                  return (
                    <InnerBox style={{ marginBottom: 12 }} key={ct}>
                      <OverviewTitleWrapper
                        style={{ marginBottom: open ? 24 : 0 }}
                      >
                        <OverviewTitleWithSubtitleWrapper>
                          <OverviewTitle small>
                            {READABLE_CONTENT_TYPES[ct]} (
                            {contentTypeData?.length})
                          </OverviewTitle>
                          <OverviewSubtitle>
                            {!open
                              ? `Tryck på "Visa detaljer" för att se en detaljerad lista över dessa objekt`
                              : `Klicka på ett objekt för att komma till detaljsidan`}
                          </OverviewSubtitle>
                        </OverviewTitleWithSubtitleWrapper>

                        <PrimaryButton
                          title={!open ? "Visa detaljer" : "Stäng"}
                          clicked={
                            !open
                              ? () => setOpenTable(`${key}${ct}`)
                              : () => setOpenTable(null)
                          }
                        />
                      </OverviewTitleWrapper>

                      {open && !!TableComp && (
                        <TableComp
                          {...{
                            persistantQuery,
                          }}
                        />
                      )}
                    </InnerBox>
                  );
                })}
              </DetailPageBox>
            );
          })}
        </>
      ) : (
        <DetailPageBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OverviewTitle style={{ padding: "48px 0 " }}>
            {premisesLoading ? "Laddar..." : "Inget att hantera"}
          </OverviewTitle>
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
