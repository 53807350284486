import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import InvoicingErrorsTable from "../../../components/Tables/InvoicingErrors/FullTable";

import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import { buildQueryString } from "../../../store/base";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { useParams } from "react-router-dom";
import { NotificationDot } from "../../../components/Dashboard/styles";
import { DateCell, LinkedObject } from "../../../components/Displays";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import theme from "../../../theme";
import { useFilteredVacancyInvoicingRecords } from "../../../store/invoicingVacancyRecords";
import { INVOICE_TYPES } from "../../../store/invoicingLease";

const getInvoiceTypeFromRecord = ({ record }) => {
  if (record?.lease_invoicing) return INVOICE_TYPES.LEASE;
  if (record?.other_invoicing) return INVOICE_TYPES.OTHER;
  if (record?.parking_invoicing) return INVOICE_TYPES.PARKING;
  if (record?.brf_invoicing) return INVOICE_TYPES.BRF;
};
const getInvoiceObjKeyFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return "lease_invoicing";
  if (type === INVOICE_TYPES.OTHER) return "other_invoicing";
  if (type === INVOICE_TYPES.PARKING) return "parking_invoicing";
  if (type === INVOICE_TYPES.BRF) return "brf_invoicing";
};
const getInvoiceObjFromRecord = ({ record }) => {
  if (record?.lease_invoicing) return record?.lease_invoicing;
  if (record?.other_invoicing) return record?.other_invoicing;
  if (record?.parking_invoicing) return record?.parking_invoicing;
  if (record?.brf_invoicing) return record?.brf_invoicing;
};

const getInvoicingContentTypeFromType = (type) => {
  if (type === INVOICE_TYPES.LEASE) return "accounting.leaseinvoicing";
  if (type === INVOICE_TYPES.OTHER) return "accounting.otherinvoicing";
  if (type === INVOICE_TYPES.PARKING) return "accounting.parkinginvoicing";
  if (type === INVOICE_TYPES.BRF) return "accounting.brfinvoicing";
};

export default function VacancyRecordOverview() {
  const [errorCount] = useInvoicingErrorPaginationCount();

  const { vacancyBookingId } = useParams();

  const query = buildQueryString({
    id__in: vacancyBookingId,
  });
  const [vacancyRecords] = useFilteredVacancyInvoicingRecords(query);
  const vacancyRecord = vacancyRecords?.[0];

  const invoiceType = getInvoiceTypeFromRecord({ record: vacancyRecord });
  const nestedInvoiceObj = getInvoiceObjFromRecord({ record: vacancyRecord });
  const invoiceObjKey = getInvoiceObjKeyFromType(invoiceType);
  const contentType = getInvoicingContentTypeFromType(invoiceType);

  const errorQ = {
    [invoiceObjKey]: nestedInvoiceObj?.id,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBoxFlexWrapper>
        <DetailPageBox
          style={{ flex: 1, maxWidth: "33%", alignSelf: "flex-start" }}
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Information</OverviewTitle>
              <OverviewSubtitle>
                Vakansbokningar kommer att försöka genereras ett antal gånger
                under dygnet. Åtgärda problemen så kommer bokningen automatiskt
                att genereras vid nästa körning.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <DetailInfo infoObj={getInfoObj({ record: vacancyRecord })} />
        </DetailPageBox>

        <DetailPageBox
          style={{ flex: 2, maxWidth: "66%", alignSelf: "flex-start" }}
          error
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
                small
              >
                Felmeddelanden{" "}
                <NotificationDot
                  style={{
                    backgroundColor: theme.colors.red,
                    borderColor: theme.colors.red,
                  }}
                >
                  {errorCount}
                </NotificationDot>
              </OverviewTitle>

              <OverviewSubtitle>
                Dessa fel kan innebära att bolag, hyresgäster,
                aviseringsinställningar, kostnadsställen, produkter, kostnader,
                projekt och fakturor inte kan synkas som de ska mot
                aviseringssystemet.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <InvoicingErrorsTable
            isBare
            ignoreLocalStorage
            persistantQuery={errorQ}
          />
        </DetailPageBox>
      </DetailPageBoxFlexWrapper>
    </DetailInnerWrapper>
  );
}

function getInfoObj({ record }) {
  const detailUrl = record?.apartment ? apartmentDetailUrl : null;
  const source = record?.apartment ? record.apartment : null;

  return {
    Logginformation: [
      {
        title: "Källa",
        value: <LinkedObject obj={source} urlMethod={detailUrl} />,
      },
      {
        title: "Period",
        value: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <DateCell date={record?.period_start} /> -{" "}
            <DateCell date={record?.period_end} />
          </div>
        ),
      },
      {
        title: "Avidatum",
        value: <DateCell date={record?.invoice_date} />,
      },
      {
        title: "Förfallodatum",
        value: <DateCell date={record?.due_date} />,
      },
    ],
  };
}
