import { cloneDeep } from "lodash";
import { getFormFetchProcessName } from "src/store/base";
import baseReducer, { removeFromProgress } from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  if (type === constants.POST_ACCEPT_DECLINE) {
    return postAcceptDecline(state, payload.id);
  }

  return baseReducer(state, action, constants);
};

const postAcceptDecline = (state, id) => {
  // refresh the instance
  const clone = cloneDeep(state);

  const formFetchName = getFormFetchProcessName("PATCH");
  const inProgress = removeFromProgress(state.inProgress, formFetchName);

  delete clone.all[id];
  clone.forms["PATCH"] = undefined;
  clone.formInstance = {};
  clone.paginations = {};
  clone.filtered = {};
  clone.inProgress = inProgress;

  return clone;
};
