import * as React from "react";

// style, design
import * as SC from "../../../Base/Chapters/styles";
import { PrimaryButton } from "../../../Base/Buttons";

import { useHistory } from "react-router-dom";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import { OverviewTitle } from "../../../../Details/OverviewInfo/styles";
import LocalCheckField from "src/components/Forms/Base/Fields/LocalCheckField";


const localStorageReducer = (state, action) => {
    localStorage.setItem(`sidebar__hidden__${action.key}`, action.value);

  return {
    ...state,
    [action.key]: action.value,
  };
};

export default () => {
  const [loading, setLoading] = React.useState(false);
  const { push, goBack } = useHistory();

  const [storageState, dispatchStorage] = React.useReducer(localStorageReducer, {
    portfolio: false
  })

  React.useEffect(() => {
    let portfolioHidden = localStorage.getItem("sidebar__hidden__portfolio");
    if (portfolioHidden != null) dispatchStorage({key: "portfolio", value: portfolioHidden === "true" ? true : false})
  }, []);

  return (
    <>
      <SC.FormArea>
        {loading && <OverlaySpinner />}

        <OverviewTitle style={{ marginBottom: "10px"}} small>Visa</OverviewTitle>
        <LocalCheckField
          title="Portföljer"
          value={storageState.portfolio}
          onChange={(val) => dispatchStorage({key: "portfolio", value: val})}
        />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <PrimaryButton
            newDesign
            clicked={() => push(`/configcenter/general/sidebar`)}
            title="Gå tillbaka"
          />
        </div>
      </SC.FormArea>
    </>
  );
};
