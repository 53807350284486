export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_REPORT_ERRAND",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_REPORT_ERRAND",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_REPORT_ERRAND",
  DESTROY_FORM: "DESTROY_FORM_REPORT_ERRAND",
  SET_FORM_ERROR: "SET_FORM_ERROR_REPORT_ERRAND",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_REPORT_ERRAND",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_REPORT_ERRAND",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_REPORT_ERRAND",
  RESET_STATE: "RESET_STATE_REPORT_ERRAND",
  CLEAR_FETCHED: "CLEAR_FETCHED_REPORT_ERRAND",
  SET_OVERVIEW_STATS: "SET_REPORT_ERRAND_OVERVIEW_STATS",
  POST_ACCEPT_DECLINE: "POST_ACCEPT_DECLINE_REPORT_ERRAND",
  INITIATE_INVOICING: "INITIATE_INVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_REPORTERRAND",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_REPORTERRAND",
  REMOVE_OBJECT: "REMOVE_OBJECT_REPORTERRAND",

  LIST_URL: "/errands/main/reporterrand/list/",
  POST_URL: "/errands/main/reporterrand/",
  PATCH_URL: "/errands/main/reporterrand/",
  GET_URL: "/errands/main/reporterrand/",
  GET_URL: "/errands/main/reporterrand/",
  ACCEPT_DECLINE_URL: "/errands/main/reporterrand/accepting/",
  OVERVIEW_STATS_UTL: "/general/report_errand_overview/", //FEL men finns inte än

  STORE_NAME: "reportErrands",
};
