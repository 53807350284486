export default [
  {
    title: "Lead",
    key: "LEAD",
    visited: true,
    hasError: false,
    fieldKeys: [
      "first_name",
      "last_name",
      "corporate_name",
      "address",
      "profession",
      "email",
      "phone",
      "stage",
      "pipeattributevalue_set",
    ],
  },
  {
    title: "Detaljer",
    key: "DETAILS",
    visited: true,
    hasError: false,
    fieldKeys: [
      "value",
      "tenant",
      "end_date",
      "apartment",
      "industrial_premises",
      "parking_spot",
      "yearly_income",
      "yearly_income_year",
      "together_with",
    ],
  },
  {
    title: "Kontaktpersoner",
    key: "CONTACT_PERSONS",
    visited: true,
    hasError: false,
    fieldKeys: ["contact_persons"],
  },
  {
    title: "Dokument",
    key: "DOCUMENTS",
    visited: true,
    hasError: false,
    fieldKeys: ["documents"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
