export default [
  {
    title: "Projekt",
    key: "PROJECT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "description",
      "status",
      "project_leader",
      "start_date",
      "end_date",
      "contact_name",
    ],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
