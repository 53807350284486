import * as React from "react";

import { Integration } from "./Chapters";

export default ({ key, method, forceAvyId }) => {
  switch (key) {
    default:
      return <Integration {...{ method, forceAvyId }} />;
  }
};
