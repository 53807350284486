import * as React from "react";

import { 
    Confirm, 
    FortnoxConfig
} from "./Chapters";

export default (key, method) => {
    switch (key) {
        case "FORTNOX":
            return <    FortnoxConfig
            method={method} />;
        default:
            return <Confirm />;
    }
};