export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INSIGHTSCENARIOS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INSIGHTSCENARIOS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INSIGHTSCENARIOS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INSIGHTSCENARIOS",
  DESTROY_FORM: "DESTROY_FORM_INSIGHTSCENARIOS",
  SET_FORM_ERROR: "SET_FORM_ERROR_INSIGHTSCENARIOS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INSIGHTSCENARIOS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INSIGHTSCENARIOS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INSIGHTSCENARIOS",
  RESET_STATE: "RESET_STATE_INSIGHTSCENARIOS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INSIGHTSCENARIOS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_INSIGHTSCENARIOS",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_INSIGHTSCENARIOS",
  CLEAR_FETCHED: "CLEAR_FETCHED_INSIGHTSCENARIOS",

  LIST_URL: "/statistics/scenario/list/",
  POST_URL: "/statistics/scenario/",
  PATCH_URL: "/statistics/scenario/",
  GET_URL: "/statistics/scenario/",

  STORE_NAME: "insightsScenarios",
};
