import * as React from "react";
import { useHistory, useParams } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import CostCenterForm from "../../../components/Forms/InvoicingCostCenter/ChapterForm/ModalForm";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  useInvoicingCostCenter,
  constants,
} from "../../../store/invoicingCostCenters";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import InvoicingErrorTable from "../../../components/Tables/InvoicingErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import StandardModal from "../../../components/Modals/StandardModal";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

function InvoicingCostCenter() {
  const { costCenterId } = useParams();

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [cantDeleteInfoOpen, setCantDeleteInfoOpen] = React.useState(false);

  const { replace } = useHistory();
  const [editOpen, setEditOpen] = React.useState(false);
  const [costCenter] = useInvoicingCostCenter(costCenterId);

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      cost_center: costCenterId,
    },
  });

  const errorQuery = {
    cost_center: costCenterId,
  };

  const detailInfo = {
    "Information om kostnadsställe": [
      {
        title: "Kod",
        value: costCenter?.title,
      },
      {
        title: "Beskrivning",
        value: costCenter?.description,
      },
    ],
  };

  return (
    <>
      <StandardModal
        withActionBar
        title="Kan ej radera kostnadsställe"
        isOpen={cantDeleteInfoOpen}
        actionBarCancelTitle="Stäng"
        closeFunction={() => setCantDeleteInfoOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Detta kostnadsställe kan ej raderas då det används
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          Kostnadsställen som används kan ej raderas av bokföringsskäl.
        </BodyText>
      </StandardModal>

      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={costCenter}
        constants={constants}
        errorCallback={() => setCantDeleteInfoOpen(true)}
        deletedCallback={() =>
          replace("/rental-notifications/invoicing-objects")
        }
      />

      <CostCenterForm
        method="PATCH"
        id={costCenter?.id}
        instance={costCenter}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Kostnadsställe ${
            costCenter?.str_representation || "Laddar..."
          }`}
          breadCrumbs={[
            {
              url: "/rental-notifications/invoicing-objects",
              label: "Produkter & Bokföring",
            },
            {
              label: `Kostnadsställe ${
                costCenter?.str_representation || "Laddar..."
              }`,
            },
          ]}
        />

        <DetailInnerWrapper>
          {errorCount > 0 && (
            <DetailPageBox error>
              <OverviewTitleWrapper>
                <OverviewTitle>Felmeddelanden</OverviewTitle>
              </OverviewTitleWrapper>

              <InvoicingErrorTable
                isBare
                ignoreLocalStorage
                persistantQuery={errorQuery}
              />
            </DetailPageBox>
          )}

          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              <PopupButton
                leftAligned
                title="Hantera"
                actions={[
                  {
                    name: "Redigera",
                    onClick: () => setEditOpen(true),
                  },
                  {
                    name: "Radera",
                    isDelete: true,

                    onClick: () => setDeleteOpen(true),
                  },
                ]}
              />
            </OverviewTitleWrapper>

            <DetailInfo infoObj={detailInfo} />
          </DetailPageBox>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}

export default InvoicingCostCenter;
