export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MILESTONES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MILESTONES",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MILESTONES",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MILESTONES",
  DESTROY_FORM: "DESTROY_FORM_MILESTONES",
  SET_FORM_ERROR: "SET_FORM_ERROR_MILESTONES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MILESTONES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MILESTONES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MILESTONES",
  RESET_STATE: "RESET_STATE_MILESTONES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MILESTONES",
  REMOVE_OBJECT: "REMOVE_OBJECT_MILESTONES",
  CLEAR_FETCHED: "CLEAR_FETCHED_MILESTONES",

  LIST_URL: "/market/crm/milestone/list",
  POST_URL: "/market/crm/milestone/",
  PATCH_URL: "/market/crm/milestone/",
  GET_URL: "/market/crm/milestone/",

  STORE_NAME: "milestones",
};
