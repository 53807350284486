import * as React from "react";
import { useHistory } from "react-router";

import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PopupButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import * as SharedStyles from "../../../components/sharedStyles";
import { useAllPermissionCheck } from "../../../store/base";

import { useConfigCenter } from "../../../store/configcenter";

import PageTitle from "../../Layouts/PageTitle/PageTitle";

import * as TenantPortalSettingStyles from "../../../components/Details/TenantPortalSettings/styles";
import { lightOrDark } from "../../../components/DigitalDoc/utils";

export default () => {
  const { push } = useHistory();

  const [configCenter] = useConfigCenter();

  const canEditConfigCenter = useAllPermissionCheck([
    "view_can_configcenter",
    "change_can_configcenter",
  ]);

  const goToEdit = () => {
    push(`/configcenter/general/insights/edit/`);
  };

  const breadCrumbs = [
    {
      label: "Insights",
      url: "/configcenter/general/insights",
    },
  ];

  let actions = [];

  if (canEditConfigCenter)
    actions.push({
      name: "Redigera",
      onClick: () => goToEdit(),
    });

  return (
    <SharedStyles.BareLayoutWrapper fillWidth>
      {!configCenter && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="Insights" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Insights</OverviewTitle>
          {actions?.length > 0 && (
            <PopupButton title="Hantera" actions={actions} />
          )}
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox style={{ marginBottom: 12 }}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Färger för widgets</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <SharedStyles.InnerBox>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {configCenter?.insights_color_scheme.map((elem, idx) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "700", fontSize: "16px" }}>
                      Färg {idx + 1}
                    </p>
                    <TenantPortalSettingStyles.ColorCode
                      backgroundColor={`${elem}`}
                      color={lightOrDark(`${elem}`)}
                      width={"100px"}
                    >
                      {`#${elem}`}
                    </TenantPortalSettingStyles.ColorCode>
                  </div>
                );
              })}
            </div>
          </SharedStyles.InnerBox>
        </SharedStyles.InnerBox>
      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
