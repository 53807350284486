import * as React from "react";
import { Page } from "react-pdf";

export default function DigitalDocGhostPDFPage({
  pageNumber,
  pageWidth,
  updatePageBounds,
}) {
  const [height, setHeight] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const pageRef = React.useRef();

  const onPageLoaded = (data) => {
    updatePageBounds({ pageNumber, height: data.height, width: data.width });
    setHeight(data.height);

    setLoaded(true);
  };

  return (
    <div
      ref={pageRef}
      style={{ minHeight: loaded ? undefined : height }}
      className={`mb-4 shadow`}
    >
      <Page
        renderAnnotationLayer={false}
        renderMode="none"
        onLoadSuccess={onPageLoaded}
        width={pageWidth}
        pageNumber={pageNumber}
        loading="Laddar sida..."
        externalLinkTarget="_blank"
      />
    </div>
  );
}
