import * as React from "react";

import PrimaryBtn from "../../../Forms/Base/Buttons/PrimaryBtn";

import {
  PlusIcon,
  LanguageIcon,
  RectangleStackIcon,
  XMarkIcon,
  CheckCircleIcon,
  Square3Stack3DIcon,
  CalendarIcon,
  VariableIcon,
} from "@heroicons/react/24/outline";

import classNames from "classnames";
import { clone, cloneDeep, debounce, update } from "lodash";
import {
  AVAILABLE_OPERATORS,
  OPERATORS_TO_TEXT,
  OPERATORS_TO_FIELD,
} from "./utils";
import LocalCheckField from "../../../Forms/Base/Fields/LocalCheckField";
import LocalSelectManyField from "../../../Forms/Base/Fields/LocalSelectManyField";
import LocalDateSelect from "../../../Forms/Base/Fields/LocalDateSelect";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getIconForFilter = (filter) => {
  switch (filter.type) {
    case "text":
      return <p className="font-serif w-[16px] text-center">T</p>;

    case "boolean":
      return <CheckCircleIcon width={16} />;

    case "select":
      return <Square3Stack3DIcon width={16} />;

    case "date":
      return <CalendarIcon width={16} />;

    case "number":
      return <VariableIcon width={16} />;

    default:
      return <RectangleStackIcon width={16} />;
  }
};

const buildFilters = (insts, currentFilters) => {
  let newFilters = [];

  for (let instName in insts) {
    if (currentFilters && currentFilters.hasOwnProperty(instName)) {
      // newFilters[instName] = currentFilters[instName];
      // newFilters.push(currentFilters[instName]);
      //this should never happen, if it does something is wrong with table setup
      console.warn("Hot-updating filter instructions is not supported");
      continue;
    }

    let data = {
      ...insts[instName],
      name: instName,
      value: undefined,
      selectedOperator: 0, //first operator will be default
    };

    if (insts[instName].type === "boolean") {
      data.value = false;
    }

    if (insts[instName].type === "select") {
      data.value = Array.from(new Array(insts[instName].choices.length)).map(
        () => {
          return false;
        }
      );
    }

    newFilters.push(data);
  }

  return newFilters;
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const useFilters = ({
  filterInstructions,
  tableId,
  filtersChecked,
  setFiltersChecked,
  ignoreLocalStorage,
}) => {
  const [filters, setFilters] = React.useState([]);

  React.useEffect(() => {
    setFilters(buildFilters(filterInstructions, filters));
  }, [filterInstructions]);

  const getInitialActiveFilters = () => {
    if (ignoreLocalStorage) return;
    let stringifiedActiveFilters = localStorage.getItem(
      `TABLE_ACTIVE_FILTERS_${tableId}`
    );

    if (!stringifiedActiveFilters || stringifiedActiveFilters.length === 0)
      return;

    let parsed;

    try {
      parsed = JSON.parse(stringifiedActiveFilters);
    } catch (err) {
      return;
    }

    return parsed;
  };

  const [activeFilters, setActiveFilters] = React.useState(
    getInitialActiveFilters()
  );

  const removeAllFilters = () => {
    setActiveFilters({});
  };

  React.useEffect(() => {
    if (ignoreLocalStorage) return;
    localStorage.setItem(
      `TABLE_ACTIVE_FILTERS_${tableId}`,
      JSON.stringify(activeFilters)
    );
  }, [activeFilters]);

  const buildIdentifierForActiveFilter = (activeFilter) => {
    return (
      activeFilter.queryKey +
      activeFilter.operators[activeFilter.selectedOperator]
    );
  };

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const [updatingFilter, setUpdatingFilter] = React.useState("");

  const [filterSearchString, setFilterSearchString] = React.useState("");

  const updateFilterElementRef = React.useRef();
  const updateActiveFilterElementRef = React.useRef();

  const windowSize = useWindowSize();

  const [renderUpdateFilterToRight, setRenderUpdateFilterToRight] =
    React.useState(true);

  const [
    anchorUpdateActiveFilterElementToLeft,
    setAnchorUpdateActiveFilterElementToLeft,
  ] = React.useState(true);

  React.useEffect(() => {
    // console.log(updateFilterElementRef.current, 117);
    if (!updateFilterElementRef.current) return;
    if (renderUpdateFilterToRight === false) return;
    if (!isFilterOpen) return;
    if (updatingFilter.length === 0) return;

    let rect = updateFilterElementRef.current.getBoundingClientRect();

    let isOutside = rect.x + rect.width + 10 > windowSize.width;

    if (isOutside) setRenderUpdateFilterToRight(false);
  }, [updateFilterElementRef, windowSize.width, updatingFilter, isFilterOpen]);

  const [
    lastActiveUpdatingFilterBoxChecked,
    setLastActiveUpdatingFilterBoxChecked,
  ] = React.useState("");

  React.useEffect(() => {
    if (!updateActiveFilterElementRef.current) return;
    if (updatingFilter.length === 0) return;

    if (
      updatingFilter === lastActiveUpdatingFilterBoxChecked &&
      !anchorUpdateActiveFilterElementToLeft
    ) {
      return;
    }

    let rect = updateActiveFilterElementRef.current.getBoundingClientRect();

    if (rect.x + rect.width + 10 > windowSize.width) {
      setAnchorUpdateActiveFilterElementToLeft(false);
    } else if (!anchorUpdateActiveFilterElementToLeft)
      setAnchorUpdateActiveFilterElementToLeft(true);

    setLastActiveUpdatingFilterBoxChecked(updatingFilter);
  }, [updateActiveFilterElementRef.current, updatingFilter, windowSize.width]);

  const filterDropdownRef = React.useRef();

  const outsideClickListener = React.useCallback(
    (evt) => {
      if (!isFilterOpen && updatingFilter.length === 0) return;

      let close = true;

      let currentElement = evt.target;

      while (currentElement.parentElement) {
        if (currentElement.getAttribute("data-isactivefilter")) {
          close = false;
          break;
        }

        if (currentElement === filterDropdownRef.current) {
          close = false;
          break;
        }

        if (currentElement.getAttribute("data-isupdatefilterelement")) {
          close = false;
          break;
        }

        currentElement = currentElement.parentElement;
      }

      if (close) {
        setIsFilterOpen(false);
        setUpdatingFilter("");
      }
    },
    [isFilterOpen, updatingFilter]
  );

  React.useEffect(() => {
    window.addEventListener("click", outsideClickListener);

    return () => {
      window.removeEventListener("click", outsideClickListener);
    };
  });

  const filterQuery = React.useMemo(() => {
    let queryObject = {};

    //can use identifier for query, but thats probably not smart, if something has to change in the future

    for (let identifier in activeFilters) {
      let filter = activeFilters[identifier];
      if (
        filter.value === undefined ||
        filter.value === null ||
        filter.value.length === 0
      )
        continue;

      let filterQuery = `${filter.queryKey}${
        filter.operators[filter.selectedOperator]
      }`;

      if (filter.type === "select") {
        let newValue = [];

        for (let i = 0; i < filter.value.length; i++) {
          let bool = filter.value[i];
          if (bool) {
            newValue[i] = filter.choices[i].v;
          }
        }

        newValue = newValue.filter((str) => str.length !== 0);

        queryObject[filterQuery] = newValue;
        continue;
      }

      queryObject[filterQuery] = filter.value;
    }

    return queryObject;
  }, [activeFilters]);

  const [usingFilterQuery, setUsingFilterQuery] = React.useState({});

  const doSetFilterQuery = (query) => {
    setUsingFilterQuery(query);
    if (!filtersChecked) setFiltersChecked(true);
  };

  const debounceFilterQuery = React.useCallback(
    debounce(doSetFilterQuery, 500),
    [filtersChecked]
  );

  React.useEffect(() => {
    debounceFilterQuery(filterQuery);
  }, [filterQuery]);

  const openFilterFromList = (filterName) => {
    setUpdatingFilter(filterName);
  };

  const filterListElements = React.useMemo(() => {
    let elems = [];

    let filteredFilters = filters.slice();

    if (filterSearchString.length !== 0) {
      filteredFilters = filteredFilters.filter((filter) =>
        filter.name.toLowerCase().includes(filterSearchString.toLowerCase())
      );
    }

    filteredFilters = filteredFilters.map((filter) => filter.name);

    for (let filterIndex in filters) {
      let filter = filters[filterIndex];
      let icon = getIconForFilter(filter);

      if (!filteredFilters.includes(filter.name)) continue;

      elems.push(
        <div
          key={filter.name}
          onClick={() => openFilterFromList(filter.name, filter)}
          className={classNames(
            "flex items-center border border-solid space-x-2 p-2 rounded text-sm transition-colors cursor-pointer",
            filter.name === updatingFilter
              ? "border-primaryblue/30 bg-primaryblue/10"
              : "border-transparent hover:bg-primaryblue hover:text-white"
          )}
        >
          {icon}
          <p>{filter.name}</p>
        </div>
      );
    }

    return elems;
  }, [filters, activeFilters, updatingFilter, filterSearchString]);

  const updateFilterState = (filter, operator, value, isActive) => {
    if (isActive) {
      //write logic for changing input in active filters

      let clone = cloneDeep(activeFilters);

      clone[buildIdentifierForActiveFilter(filter)].value = value;

      setActiveFilters(clone);

      return;
    }

    let clone = cloneDeep(filters);

    let filterIndex = filters.findIndex((obj) => obj.name === filter.name);

    clone[filterIndex].value = value;

    setFilters(clone);
  };

  const switchSelectedOperator = (filter, operatorIndex, isActive) => {
    if (filter.selectedOperator === operatorIndex) return;

    if (isActive) {
      //write logic for changing active filters
      let oldIdentifier = buildIdentifierForActiveFilter(filter);

      let newFilter = cloneDeep(filter);

      newFilter.selectedOperator = operatorIndex;
      newFilter.value = undefined;

      let newIdentifer = buildIdentifierForActiveFilter(newFilter);

      let clone = cloneDeep(activeFilters);

      delete clone[oldIdentifier];

      clone[newIdentifer] = newFilter;

      setUpdatingFilter(newIdentifer);

      setActiveFilters(clone);

      return;
    }
    //is in base filters array

    let clone = filters.slice();

    let filterIndex = filters.findIndex((obj) => obj.name === filter.name);

    clone[filterIndex].selectedOperator = operatorIndex;

    //reset value if switching from input to select for example
    clone[filterIndex].value = undefined;

    setFilters(clone);
  };

  const getActiveOperatorsForFilter = (filter) => {
    let usedIndexes = [];

    for (let identifier in activeFilters) {
      let activeFilter = activeFilters[identifier];

      if (activeFilter.queryKey !== filter.queryKey) continue;

      usedIndexes.push(activeFilter.selectedOperator);
    }

    return usedIndexes;
  };

  const getElementForFilterInput = (
    filter,
    operator,
    operatorIndex,
    isActive,
    allUsedOperatorIndexes,
    useSelectedOperator
  ) => {
    //Create clone of filter to put it into activeFilters which will be passed in here.
    //so can always rely on eg. filter.selectedOperator is correct

    //make all not checkable select fields disabled & out greyed
    //if that operator already exists in activeFilters

    //if active, cannot remove the acitve filter, just disable switching operator, no the current operator
    //do not update any states

    let filterElem;
    let selectElemText = OPERATORS_TO_TEXT[operator];

    if (!selectElemText)
      selectElemText = `Unsupported check field operator: ${operator}`;

    if (
      typeof selectElemText === "object" &&
      !Array.isArray(selectElemText) &&
      selectElemText !== null
    ) {
      //is object, gather text from filter.type
      if (!selectElemText.hasOwnProperty(filter.type)) {
        console.error(
          "Unsupported type of OPERATORS_TO_TEXT type:",
          filter.type,
          OPERATORS_TO_TEXT[operator]
        );
        selectElemText = "Unsupported (check console)";
      } else {
        selectElemText = selectElemText[filter.type];
      }
    }

    selectElemText = capitalizeFirstLetter(selectElemText);

    let isOperatorActive = !allUsedOperatorIndexes.includes(operatorIndex);

    //Do not disable the operator if we are editing the active instance with that operator selected
    if (isActive && useSelectedOperator === operatorIndex)
      isOperatorActive = true;

    let selectElem = (
      <div
        className={classNames(!isOperatorActive && "opacity-50")}
        onClick={() => switchSelectedOperator(filter, operatorIndex, isActive)}
      >
        <LocalCheckField
          disabled={!isOperatorActive}
          onChange={() => {}}
          value={useSelectedOperator === operatorIndex}
          title={selectElemText}
          labelClassName="font-normal"
          rounded
        />
      </div>
    );

    if (useSelectedOperator === operatorIndex) {
      let fieldType = OPERATORS_TO_FIELD[operator];

      if (Array.isArray(fieldType)) {
        const found = fieldType.find((str) => str === filter.type);
        if (!found) {
          console.warn(
            "Unsupported field type for fieldType array",
            fieldType,
            filter.type,
            "falling back to",
            fieldType[0]
          );
          fieldType = fieldType[0];
        } else {
          fieldType = found;
        }
      }

      switch (fieldType) {
        case "input":
          filterElem = (
            <input
              key={filter.name}
              className={classNames(
                "border border-solid border-gray-200 w-full rounded p-2 text-sm"
              )}
              placeholder={selectElemText}
              value={filter.value}
              onChange={({ target: { value } }) =>
                updateFilterState(filter, operator, value, isActive)
              }
            />
          );
          break;

        case "number":
          filterElem = (
            <input
              key={filter.name}
              className={classNames(
                "border border-solid border-gray-200 w-full rounded p-2 text-sm"
              )}
              placeholder={selectElemText}
              value={filter.value}
              onChange={({ target: { value } }) =>
                updateFilterState(filter, operator, value, isActive)
              }
            />
          );
          break;

        case "boolean":
          filterElem = (
            <LocalCheckField
              title={filter.value ? "Sant" : "Falskt"}
              className="ml-2 cursor-pointer"
              value={filter.value}
              onChange={() => {
                updateFilterState(filter, operator, !filter.value, isActive);
              }}
            />
          );
          break;

        case "select":
          filterElem = (
            <LocalSelectManyField
              value={filter.value}
              choices={filter.choices}
              onChange={(val) => {
                updateFilterState(filter, operator, val, isActive);
              }}
            />
          );
          break;

        case "date":
          filterElem = (
            <LocalDateSelect
              title={selectElemText}
              value={filter.value}
              onChange={(val) => {
                updateFilterState(filter, operator, val, isActive);
              }}
              alwaysOpen={true}
            />
          );
          break;

        default:
          filterElem = (
            <p>
              Unsupported input operator (fieldType): {operator} {fieldType}
            </p>
          );
          break;
      }
    }

    return (
      <>
        {selectElem}
        {filterElem}
      </>
    );
  };

  const addActiveFilter = (filter) => {
    let clone = cloneDeep(activeFilters);

    if (!clone) clone = {};

    let activeFilter = cloneDeep(filter);

    let identifier = buildIdentifierForActiveFilter(activeFilter);

    clone[identifier] = activeFilter;

    setActiveFilters(clone);

    setUpdatingFilter("");
  };

  const removeActiveFilter = (identifier) => {
    let clone = cloneDeep(activeFilters);

    delete clone[identifier];

    setActiveFilters(clone);
  };

  const generateUpdateFilterElement = (filterName, addRef) => {
    if (filterName.length === 0) return;

    let filter = filters.find((obj) => obj.name === filterName);

    let active = false;

    if (!filter) {
      //is active filter, filterName is now filter identifier
      active = true;

      filter = activeFilters[filterName];
    }

    const allUsedOperatorIndexes = getActiveOperatorsForFilter(filter);

    const canMakeAChoice = !(
      allUsedOperatorIndexes.length === filter.operators.length
    );

    let useSelectedOperator = filter.selectedOperator;

    if (!active && allUsedOperatorIndexes.includes(useSelectedOperator)) {
      let index = filters.findIndex((obj) => obj.name === filter.name);

      //go through all operator indexes until a unoccupied index show up
      //if not the user already has filtered on all available operators
      let newSelectedOperator = 0;

      if (canMakeAChoice) {
        let foundOperator = false;

        while (newSelectedOperator < filter.operators.length) {
          if (!allUsedOperatorIndexes.includes(newSelectedOperator)) {
            foundOperator = true;
            break;
          }
          newSelectedOperator++;
        }

        if (!foundOperator) newSelectedOperator = -1;
      } else {
        newSelectedOperator = -1;
      }

      let clone = filters.slice();

      clone[index].selectedOperator = newSelectedOperator;

      //probably useless, but better safe than sorry
      clone[index].value = undefined;
      setFilters(clone);

      useSelectedOperator = newSelectedOperator;
    }

    let inputs = filter.operators.map((operator, operatorIndex) => {
      let elem = getElementForFilterInput(
        filter,
        operator,
        operatorIndex,
        active,
        allUsedOperatorIndexes,
        useSelectedOperator
      );
      return elem;
    });

    let classesToAdd =
      !active && !renderUpdateFilterToRight && "left-[calc(-260px*2)]";

    if (active && anchorUpdateActiveFilterElementToLeft) {
      classesToAdd = "left-0";
    } else if (active) {
      classesToAdd = "right-0";
    }

    return (
      <div
        className={classNames("absolute z-10", classesToAdd)}
        data-isupdatefilterelement={true}
        ref={addRef && addRef}
      >
        <div
          className={classNames(
            "w-[275px] mt-2 bg-white rounded transition-opacity p-4 space-y-3"
          )}
          style={{
            boxShadow: "1px 3px 15px -3px rgba(0, 0, 0, 0.3)",
          }}
        >
          {/* <p className="text-md font-normal">{filter.name}</p> */}
          <div className="space-y-3" onClick={(evt) => evt.stopPropagation()}>
            {inputs}
          </div>
          {!active && canMakeAChoice && (
            <div className="flex justify-end">
              <PrimaryBtn
                onClick={(e) => {
                  e.stopPropagation();
                  addActiveFilter(filter);
                }}
              >
                Lägg till
              </PrimaryBtn>
            </div>
          )}
          {!canMakeAChoice && !active && (
            <p className="text-xs">Filterar redan på alla val</p>
          )}
        </div>
      </div>
    );
  };

  const toggleFilter = () => {
    setUpdatingFilter("");
    setIsFilterOpen(!isFilterOpen);
  };

  const filterElement = React.useMemo(() => {
    if (filters.length === 0) return;

    return (
      <div className="relative" ref={filterDropdownRef}>
        <PrimaryBtn
          className="mt-2"
          secondary={false}
          onClick={() => {
            toggleFilter();
          }}
        >
          <p>Lägg till filter</p>{" "}
          <PlusIcon
            className={classNames(
              "ml-1 mr-[-4px] transition-transform",
              isFilterOpen && "rotate-45"
            )}
            width={16}
          />
        </PrimaryBtn>
        <div className="relative">
          <div
            className={classNames(
              "w-[250px] max-h-[400px] overflow-y-auto mt-2 bg-white rounded absolute z-10 border border-solid shadow-md transition-opacity border-primaryblue/10 p-2 space-y-2",
              isFilterOpen
                ? "pointer-events-auto opacity-1"
                : "opacity-0 pointer-events-none"
            )}
          >
            <input
              className="p-2 rounded border border-primaryblue/50 w-full"
              placeholder="Sök"
              value={filterSearchString}
              onChange={({ target: { value } }) => setFilterSearchString(value)}
            />
            {filterListElements}
          </div>
          {isFilterOpen && (
            <div className="left-[252px] absolute">
              {generateUpdateFilterElement(
                updatingFilter,
                updateFilterElementRef
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [
    isFilterOpen,
    activeFilters,
    filters,
    updatingFilter,
    renderUpdateFilterToRight,
    filterSearchString,
  ]);

  const getTextForActiveFilterElement = (filter) => {
    let value = filter.value;
    let text = OPERATORS_TO_TEXT[filter.operators[filter.selectedOperator]];

    if (filter.type === "boolean") {
      if (value) value = "sant";
      else value = "falskt";
    }

    if (filter.type === "select") {
      value = "";
      if (filter.value) {
        let addIndex = 0;
        for (let i = 0; i < filter.value.length; i++) {
          if (filter.value[i]) {
            if (addIndex !== 0) value += ", ";
            value += filter.choices[i].d;
            addIndex++;
          }
        }
      }
    }

    if (typeof text === "object" && !Array.isArray(text) && text !== null) {
      //is object, gather text from filter.type
      text = text[filter.type];
    }

    return (
      <>
        <span className="font-normal">{text}</span>{" "}
        <span className="font-bold">{value}</span>
      </>
    );
  };

  const activeFilterElements = React.useMemo(() => {
    let toRender = [];

    for (let identifier in activeFilters) {
      let filter = activeFilters[identifier];
      toRender.push(
        <div className="relative" key={identifier} data-isactivefilter={true}>
          <PrimaryBtn
            className="mt-2 max-w-[450px]"
            gray
            onClick={() => {
              if (!anchorUpdateActiveFilterElementToLeft)
                setAnchorUpdateActiveFilterElementToLeft(true);

              setLastActiveUpdatingFilterBoxChecked("");

              if (isFilterOpen) {
                setIsFilterOpen(false);
              }
              if (updatingFilter === identifier) {
                setUpdatingFilter("");
              } else {
                setUpdatingFilter(identifier);
              }
            }}
          >
            <p className="w-full whitespace-nowrap text-ellipsis overflow-hidden">
              <span className="font-bold">{filter.name}</span>{" "}
              {getTextForActiveFilterElement(filter)}
            </p>
            <XMarkIcon
              onClick={(evt) => {
                evt.stopPropagation();
                removeActiveFilter(identifier);
              }}
              className={classNames("ml-1 mr-[-4px]")}
              width={16}
            />
          </PrimaryBtn>
          {generateUpdateFilterElement(
            updatingFilter === identifier && !isFilterOpen ? identifier : "",
            updateActiveFilterElementRef
          )}
        </div>
      );
    }

    return toRender;
  }, [
    activeFilters,
    updatingFilter,
    filters,
    isFilterOpen,
    anchorUpdateActiveFilterElementToLeft,
  ]);

  return {
    filterQuery: usingFilterQuery,
    filterElement,
    activeFilterElements,
    removeAllFilters,
  };
};
