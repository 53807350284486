import * as React from "react";
import * as SC from "./styles";

/**
 *
 * @param {options}
 * {
 *     title: String,
 *     iconType: String,
 *     type: String[default(White), primary(Blue), warning(Yellow), error(Red)]
 * }
 */
export default function StackedButtons({ options = [] }) {
  return (
    <SC.StackedButtonsContainer>
      {options.map((btn) => (
        <SC.StackedButtonsButton
          onClick={(e) => {
            e.preventDefault();
            btn.onClick && btn.onClick();
          }}
          type={btn.type || "default"}
          key={btn.title}
        >
          <SC.StackedButtonsText>{btn.title}</SC.StackedButtonsText>

          <SC.StackedButtonsIcon iconType={btn.iconType || "arrow"} />
        </SC.StackedButtonsButton>
      ))}
    </SC.StackedButtonsContainer>
  );
}
