export default {
  SET_AUTHENTICATED: "SET_AUTHENTICATED_HOMEQ",
  SET_AUTH_CHECK_IN_PROGRESS: "SET_AUTH_CHECK_IN_PROGRESS_HOMEQ",
  SET_PROJECTS: "SET_PROJECTS_HOMEQ",
  MASS_MATCHING_URL: "/market/homeq/mass_matching/", // POST realestates, apartments, requirements, ads - BOOLS om man vill matcha, minst 1
  MASS_COLLECTION_URL: "/market/homeq/mass_collection/", // POST == + om ads, speca pipeId
  HANDLE_URL: "/market/homeq/handle/", // config of auth
  CHECK_ACTIVATED_URL: "/market/homeq/check_activated/",

  REJECTION_REASONS_URL: "/market/homeq/rejectionreason/list/",
  PROJECTS_URL: "/market/homeq/list_homeq_projects", // available projects to choose in pigello, used are filtered away
  SET_REJECTION_REASONS: "SET_REJECTION_REASONS_HOMEQ",
  STORE_NAME: "homeQ",
};
