import { addToast, TOAST_TYPES } from "../../toasts";
import constants from "./constants";
import * as services from "./services";

import {
  getAllFetchProcessName,
  getSingleFetchProcessName,
  getFiltered,
  get,
  post,
  patch,
  options,
  addToProcess,
  destroyForm,
  buildQueryString,
} from "../../base";
import {
  traverseResultForWidget,
  KPI_ECONOMIC_VACANCY,
  KPI_CONTRACT_VALUE,
  KPI_DISCOUNTS,
  KPI_NET_LEASTING,
  KPI_CONTRACT_TERM,
  KPI_MARKET_RENT,
  KPI_RENT_PER_AREA,
  KPI_TOP_TENANT,
  KPI_RENTABLE_AREA,
  KPI_OCCUPANCY,
  KPI_INVOICING_FORECAST,
  KPI_VACANT_OBJECTS_MARKET_RENT,
  KPI_REALESTATE_COUNT,
  KPI_REALESTATE_TAX_RECORD,
  KPI_DELIVERY_METHOD,
  KPI_ERRAND_COUNT,
  KPI_GOVT_ERRAND_FAULT_COUNT,
  KPI_COMPONENT_RATING_WARRANTY,
  KPI_ERRAND_PERFORMANCE_PERIOD,
  KPI_CONTRACT_COUNT,
  KPI_ACTUAL_VS_FORECAST_INVOICING,
  KPI_INVOICING_COUNT,
  KPI_UNATTESTED_CURRENT_INVOICES,
  KPI_REMINDER_INVOICE_COUNT,
  KPI_UNMATCHED_PAYMENTS,
  KPI_AUTOGIRO_FAILED,
  KPI_INVOICE_REVENUE,
  KPI_INVOICE_PERIOD_COUNT,
  KPI_VAT_KEYS,
  KPI_AUTOGIRO_REVENUE,
  KPI_AUTOGIRO_TENANT_COUNT,
  KPI_TENANT_COUNT,
  KPI_VACANCY,
  KPI_VACANT_OBJECTS,
  KPI_LEASED_OBJECTS,
  KPI_BUILDING_COUNT,
  KPI_LEASE_OBJECT_COUNT,
  KPI_WARRANTY_ERRAND_COUNT,
  KPI_BRF_OWNER_COUNT,
  KPI_BRF_PREMIS_COUNT,
  KPI_APARTMENT_AREA,
  KPI_APARTMENT_CONTRACT_COUNT,
  KPI_APARTMENT_COUNT,
  KPI_APARTMENT_OCCUPANCY,
  KPI_APARTMENT_VACANT_AREA,
  KPI_CORPORATE_TENANT_COUNT,
  KPI_GOVT_ERRAND_COUNT,
  KPI_INDUSTRIALPREMISES_AREA,
  KPI_INDUSTRIALPREMISES_CONTRACT_COUNT,
  KPI_INDUSTRIALPREMISES_COUNT,
  KPI_INDUSTRIALPREMISES_OCCUPANCY,
  KPI_INDUSTRIALPREMISES_VACANT_AREA,
  KPI_INSPECTIONERRAND_COUNT,
  KPI_LEASED_APARTMENTS_COUNT,
  KPI_LEASED_INDUSTRIALPREMISES_COUNT,
  KPI_LEASED_PARKINGSPOTS_COUNT,
  KPI_OTHERCONTRACT_COUNT,
  KPI_PARKINGCONTRACT_COUNT,
  KPI_PARKINGSPOT_COUNT,
  KPI_PRIVATE_TENANT_COUNT,
  KPI_REPORTERRAND_COUNT,
  KPI_ROUNDINGERRAND_COUNT,
  KPI_VACANT_APARTMENTS_COUNT,
  KPI_VACANT_INDUSTRIALPREMISES_COUNT,
  KPI_VACANT_PARKINGSPOTS_COUNT,
} from "../../../components/Insights/Widgets/WidgetInfo";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (querystring, callback) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
  });
};

export const getValueForWidget = (params) => {
  // deconstructing params doesn't seem to work here... so doing it manually
  const postObj = params.postObj;
  const widgetPlacement = params.widgetPlacement;
  const kpi = params.kpi;
  const queryParams = params.queryParams;
  const queryString = buildQueryString(params.queryParams);

  const getEndpointForKPI = (kpi) => {
    switch (kpi) {
      case KPI_ECONOMIC_VACANCY:
        return `/insights/objects/economicvacancy/`;

      case KPI_CONTRACT_VALUE:
        return `/insights/contract/contractvalue/`;

      case KPI_DISCOUNTS:
        return `/insights/contract/discounts/`;

      case KPI_NET_LEASTING:
        return `/insights/contract/netleasing/`;

      case KPI_CONTRACT_TERM:
        return `/insights/contract/contractterm/`;

      case KPI_MARKET_RENT:
        return `/insights/contract/marketrent/`;

      case KPI_RENT_PER_AREA:
        return `/insights/contract/rentperarea/`;

      case KPI_TOP_TENANT:
        return `/insights/contract/toptenant/`;

      case KPI_RENTABLE_AREA:
        return `/insights/objects/rentablearea/`;

      case KPI_OCCUPANCY:
        return `/insights/objects/occupancy/`;

      case KPI_INVOICING_FORECAST:
        return `/insights/contract/invoicingforecast/`;

      case KPI_VACANT_OBJECTS_MARKET_RENT:
        return `/insights/objects/vacantobjectsmarketrent/`;

      case KPI_REALESTATE_COUNT:
        return `/insights/realestate/realestatecount/`;

      case KPI_REALESTATE_TAX_RECORD:
        return `/insights/realestate/realestatetaxrecord/`;

      case KPI_DELIVERY_METHOD:
        return `/insights/contract/tenantdeliverymethod/`;

      case KPI_ERRAND_COUNT:
        return `/insights/errands/errandcount/`;

      case KPI_GOVT_ERRAND_FAULT_COUNT:
        return `/insights/errands/govterrandfaultcount/`;

      case KPI_COMPONENT_RATING_WARRANTY:
        return `/insights/errands/componentratingwarranty/`;

      case KPI_ERRAND_PERFORMANCE_PERIOD:
        return `/insights/errands/errandperformanceperiod/`;

      case KPI_CONTRACT_COUNT:
        return `/insights/contract/contractcount/`;

      case KPI_ACTUAL_VS_FORECAST_INVOICING:
        return `/insights/contract/actualvsforecastinvoicing/`;

      case KPI_AUTOGIRO_TENANT_COUNT:
        return `/insights/contract/autogirotenantcount/`;

      case KPI_INVOICING_COUNT:
        return `/insights/invoicing/invoicecount/`;

      case KPI_UNATTESTED_CURRENT_INVOICES:
        return `/insights/invoicing/unattestedcurrentinvoices/`;

      case KPI_REMINDER_INVOICE_COUNT:
        return `/insights/invoicing/reminderinvoicecount/`;

      case KPI_UNMATCHED_PAYMENTS:
        return `/insights/invoicing/unmatchedpayments/`;

      case KPI_AUTOGIRO_FAILED:
        return `/insights/invoicing/autogirofailed/`;

      case KPI_INVOICE_REVENUE:
        return `/insights/invoicing/invoicerevenue/`;

      case KPI_INVOICE_PERIOD_COUNT:
        return `/insights/invoicing/invoiceperiodcount/`;

      case KPI_AUTOGIRO_REVENUE:
        return `/insights/invoicing/autogirorevenue/`;

      case KPI_VAT_KEYS:
        return `/insights/objects/vatkeys/`;

      case KPI_TENANT_COUNT:
        return `/insights/contract/tenantcount/`;

      case KPI_VACANCY:
        return `/insights/objects/vacancy/`;

      case KPI_VACANT_OBJECTS:
        return `/insights/objects/vacantobjectscount/`;

      case KPI_LEASED_OBJECTS:
        return `/insights/objects/leasedobjectscount/`;

      case KPI_BUILDING_COUNT:
        return `/insights/objects/buildingcount/`;

      case KPI_LEASE_OBJECT_COUNT:
        return `/insights/objects/leaseobjectcount/`;

      case KPI_WARRANTY_ERRAND_COUNT:
        return `/insights/errands/warrantyerrandcount/`;

      case KPI_BRF_OWNER_COUNT:
        return `/insights/brf/brfownercount/`;

      case KPI_BRF_PREMIS_COUNT:
        return `/insights/brf/brfpremiscount/`;

      case KPI_APARTMENT_CONTRACT_COUNT:
        return `/insights/contract/apartmentcontractcount/`;
      case KPI_INDUSTRIALPREMISES_CONTRACT_COUNT:
        return `/insights/contract/industrialpremisescontractcount/`;
      case KPI_PARKINGCONTRACT_COUNT:
        return `/insights/contract/parkingcontractcount/`;
      case KPI_OTHERCONTRACT_COUNT:
        return `/insights/contract/othercontractcount/`;
      case KPI_PRIVATE_TENANT_COUNT:
        return `/insights/contract/privatetenantcount/`;
      case KPI_CORPORATE_TENANT_COUNT:
        return `/insights/contract/corporatetenantcount/`;
      case KPI_REPORTERRAND_COUNT:
        return `/insights/errands/reporterrandcount/`;
      case KPI_INSPECTIONERRAND_COUNT:
        return `/insights/errands/inspectionerrandcount/`;
      case KPI_ROUNDINGERRAND_COUNT:
        return `/insights/errands/roundingerrandcount/`;
      case KPI_GOVT_ERRAND_COUNT:
        return `/insights/errands/govterrandcount/`;
      case KPI_VACANT_APARTMENTS_COUNT:
        return `/insights/objects/vacantapartmentscount/`;
      case KPI_VACANT_INDUSTRIALPREMISES_COUNT:
        return `/insights/objects/vacantindustrialpremisescount/`;
      case KPI_VACANT_PARKINGSPOTS_COUNT:
        return `/insights/objects/vacantparkingspotscount/`;
      case KPI_LEASED_APARTMENTS_COUNT:
        return `/insights/objects/leasedapartmentscount/`;
      case KPI_LEASED_INDUSTRIALPREMISES_COUNT:
        return `/insights/objects/leasedindustrialpremisescount/`;
      case KPI_LEASED_PARKINGSPOTS_COUNT:
        return `/insights/objects/leasedparkingspotscount/`;
      case KPI_APARTMENT_AREA:
        return `/insights/objects/apartmentrentablearea/`;
      case KPI_INDUSTRIALPREMISES_AREA:
        return `/insights/objects/industrialpremisesrentablearea/`;
      case KPI_APARTMENT_OCCUPANCY:
        return `/insights/objects/apartmentoccupancy/`;
      case KPI_INDUSTRIALPREMISES_OCCUPANCY:
        return `/insights/objects/industrialpremisesoccupancy/`;
      case KPI_APARTMENT_COUNT:
        return `/insights/objects/apartmentcount/`;
      case KPI_INDUSTRIALPREMISES_COUNT:
        return `/insights/objects/industrialpremisescount/`;
      case KPI_PARKINGSPOT_COUNT:
        return `/insights/objects/parkingspotcount/`;
      case KPI_APARTMENT_VACANT_AREA:
        return `/insights/objects/apartmentvacantarea/`;
      case KPI_INDUSTRIALPREMISES_VACANT_AREA:
        return `/insights/objects/industrialpremisesvacantarea/`;
    }
  };

  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, `value-${widgetPlacement.id}`);
      const endpoint = `${getEndpointForKPI(kpi)}?${queryString}`;
      const contractValue = await services.getKPIResultData(postObj, endpoint);
      let resultData = {};
      if (Object.keys(contractValue).length === 0) {
        resultData[widgetPlacement.id] = {
          labels: [],
          datapoints: [],
          additionalData: {},
        };
      } else {
        contractValue.bodyParameters = postObj;
        const [labels, datapoints, additionalData] = traverseResultForWidget(
          widgetPlacement,
          contractValue
        );
        delete additionalData["start"];
        delete additionalData["end"];
        resultData[widgetPlacement.id] = {
          labels: labels,
          datapoints: datapoints,
          additionalData: additionalData,
          realestate_ids: queryParams?.realestate_ids || [],
        };
      }

      dispatch({
        type: constants.SET_VALUE_FOR_WIDGET_PLACEMENT,
        payload: {
          id: widgetPlacement.id,
          data: resultData[widgetPlacement.id],
        },
      });

      if (params.responseCallback)
        params.responseCallback(resultData[widgetPlacement.id]);
    } catch (e) {
      const err = e;
      console.log(e);
      dispatch({
        type: constants.SET_VALUE_FOR_WIDGET_PLACEMENT,
        payload: { id: widgetPlacement.id, data: { no_data: true } },
      });
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Widgeten kunde ej hämtas",
        })
      );
      if (params.errorCallback) params.errorCallback();
    }
  };
};

export const clearAllValues = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_VALUES,
      payload: {},
    });
  };
};

export const clearSpecificValues = (ids) => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_SPECIFIC_VALUES,
      payload: ids,
    });
  };
};

export const clearDeleteQueueWidgetPlacement = (id) => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_DELETE_QUEUE_WIDGET_PLACEMENT,
      payload: { id: id },
    });
  };
};

export const setDeleteQueueWidgetPlacement = (id) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_DELETE_QUEUE_WIDGET_PLACEMENT,
      payload: { id: id },
    });
  };
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const getPostForm = (id) => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const create = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  forceData,
  preProcess
}) => {
  return post({
    url: `${constants.POST_URL}`,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    forceData,
    preProcess
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  forceData,
  preProcess,
}) => {
  return patch({
    url: `${constants.PATCH_URL}${id}`,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    forceData,
    preProcess,
  });
};
