import * as React from "react";

// style, design
import { ToolTipCell, DateCell, LinkedObject } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import { detailUrl } from "../../../store/leaseContracts";
import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import { SCRIVE_STATE_MAP } from "../../../store/editabledocs/utils";
import StatusFilter from "./Filters/StatusFilter";

export default () => [
  {
    Header: "Titel",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Dagar att signera",
    accessor: "days_to_sign",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => (
      <StatusLabel state={SCRIVE_STATE_MAP[row.original.status]}>
        {row.original.status_display}
      </StatusLabel>
    ),
    Filter: StatusFilter,
    filter: "textExact",
  },
  {
    Header: "Avtal",
    accessor: "lease_contract.str_representation",
    Cell: ({ row }) => (
      <LinkedObject obj={row.original.lease_contract} urlMethod={detailUrl} />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Motparter (Hyresgäster)",
    id: "signing_counterparties",
    accessor: "parties",
    Cell: ({ row }) => {
      let mapping = [];

      const parties = row.original.parties ?? [];
      parties.forEach((b) => {
        if (b.user?.user_type === 3 && b?.str_representation) {
          mapping.push(b.str_representation);
        }
      });

      return <ToolTipCell text={mapping.join(", ")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Uppdaterades senast",
    accessor: "inhouse_time",
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
];
