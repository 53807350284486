import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../../components/sharedStyles";
import VacancyBookingSettingModalForm from "../../../../components/Forms/VacancyBookingSetting/ChapterForm/ModalForm";
import { useVacancyBookingSetting } from "../../../../store/vacancyBookingSettings";
import DetailInfo from "../../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { StatusLabel } from "../../../../components/Lists/Base/CompleteList/styles";
import { buildQueryString } from "../../../../store/base";
import PremisesStatusTable from "../../../../components/Tables/PremisesStatuses/FullTable";

export default function VacancySettingOverview() {
  const { settingId } = useParams();
  const [setting] = useVacancyBookingSetting(settingId);
  const [editOpen, setEditOpen] = React.useState(false);

  const excludedQ = {
    id__in: setting?.not_for_statuses?.map((s) => s.id) || [],
  };
  const includedQ = {
    id__in: setting?.only_for_statuses?.map((s) => s.id) || [],
  };

  return (
    <>
      <VacancyBookingSettingModalForm
        method="PATCH"
        id={settingId}
        instance={setting}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>

            <PrimaryButton title="Redigera" clicked={() => setEditOpen(true)} />
          </OverviewTitleWrapper>

          {setting && <DetailInfo infoObj={getInfoObj({ setting })} />}
        </DetailPageBox>

        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ flex: 1, maxWidth: "50%" }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Exkluderade statusar</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <PremisesStatusTable
              persistantQueryString={excludedQ}
              isBare
              ignoreLocalStorage
            />
          </DetailPageBox>
          <DetailPageBox style={{ flex: 1, maxWidth: "50%" }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Inkluderade statusar</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {setting?.only_for_statuses?.length ? (
              <PremisesStatusTable
                persistantQueryString={includedQ}
                isBare
                ignoreLocalStorage
              />
            ) : (
              <InnerBox>
                Gäller för alla objekt under bolag som denna inställning finns
                på
              </InnerBox>
            )}
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ setting }) {
  return {
    Inställningar: [
      {
        title: "Namn",
        value: setting.title,
      },
      {
        title: "Gäller för lägenheter",
        value: (
          <StatusLabel state={setting?.for_apartments ? 0 : 6}>
            {setting?.for_apartments ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Gäller för lokaler",
        value: (
          <StatusLabel state={setting?.for_industrial_premises ? 0 : 6}>
            {setting?.for_industrial_premises ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Gäller för fordonsplatser",
        value: (
          <StatusLabel state={setting?.for_parking_spots ? 0 : 6}>
            {setting?.for_parking_spots ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      },
      {
        title: "Dag i månad för generering",
        value: setting.day_in_month,
      },
      {
        title: "Antal månaders förskjutning",
        value: setting.month_offset,
      },
      {
        title: "Bokförs på",
        value:
          setting.override_bookkeeping_account ||
          "Standard för debiteringsradens produkt",
      },
    ],
  };
}
