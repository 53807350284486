import * as React from "react";

import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

import { constants, detailUrl } from "../../../store/invoicingProducts";

export default function InvoicingProductTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
}) {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const badges = {};
  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "product_invoicing_full_table"}
      title={"Produkter"}
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      {...{
        isBare,
        ignoreLocalStorage,
        onRowClicked,
        columns,
        persistantQuery,
        badges,
        filters,
        constants,
        checkRowHighlighted,
        onRowSelected,
      }}
    />
  );
}
