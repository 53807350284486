export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_LEADS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_LEADS",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_LEADS",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_LEADS",
  DESTROY_FORM: "DESTROY_FORM_LEADS",
  SET_FORM_ERROR: "SET_FORM_ERROR_LEADS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_LEADS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_LEADS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_LEADS",
  RESET_STATE: "RESET_STATE_LEADS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_LEADS",
  REMOVE_OBJECT: "REMOVE_OBJECT_LEADS",
  CLEAR_FETCHED: "CLEAR_FETCHED_LEADS",
  SET_LEAD_PER_STAGE_COUNT: "SET_LEAD_PER_STAGE_COUNT_LEADS",
  SET_LEAD_RANKING: "SET_LEAD_RANKING_LEADS",
  CLEAR_LEAD_RANKING: "CLEAR_LEAD_RANKING_LEADS",

  LIST_URL: "/market/crm/lead/list/",
  POST_URL: "/market/crm/lead/",
  PATCH_URL: "/market/crm/lead/",
  GET_URL: "/market/crm/lead/",
  MASS_ACCEPT_URL: "/market/crm/leadmanagement/confirm/",
  MASS_REJECT_URL: "/market/crm/leadmanagement/reject/",
  LEADS_RANKING_APARTMENT_URL: "/market/crm/leadmanagement/rankings/apartment/",
  LEADS_RANKING_INDP_URL:
    "/market/crm/leadmanagement/rankings/industrialpremises/",
  LEADS_RANKING_PARKING_URL: "/market/crm/leadmanagement/rankings/parkingspot/",
  STAGE_LEAD_COUNT_URL: "/market/crm/leadmanagement/stageoverview/",

  STORE_NAME: "leads",
};
