import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function getSbfCredentials() {
  const res = await axiosInstance.get(constants.CREDENTIAL_LIST);

  return res;
}

async function addCredential(postData) {
  const res = await axiosInstance.post(constants.CREATE_CREDENTIAL, postData);

  return res;
}

async function updateCredential(patchData) {
  const res = await axiosInstance.put(
    `${constants.HANDLE_CREDENTIAL}${patchData.id}/`,
    patchData
  );

  return res;
}

async function deleteCredential(id) {
  const res = await axiosInstance.delete(
    `${constants.HANDLE_CREDENTIAL}${id}/`
  );

  return res;
}

async function getSbfRequirements() {
  const res = await axiosInstance.get(constants.REQUIREMENT_GET);

  return res;
}

async function getSbfRequirementConnections() {
  const res = await axiosInstance.get(constants.REQUIREMENT_CONNECTIONS);

  return res;
}

async function connectSbftoPigelloRequirement(postData) {
  const res = await axiosInstance.put(constants.REQUIREMENT_MATCH, postData);

  return res;
}

export {
  getSbfCredentials,
  addCredential,
  updateCredential,
  deleteCredential,
  getSbfRequirements,
  getSbfRequirementConnections,
  connectSbftoPigelloRequirement,
};
