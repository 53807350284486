import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 12px;
`;

export const Representation = styled.div`
  font-size: 0.9rem;
`;
