export default [
  {
    title: "Rad i checklista",
    key: "CHECKLIST_ROW",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "description",
      "hide_for_tenants",
      "hide_for_non_tenants",
      "check_list",
      "files",
    ],
  },
];
