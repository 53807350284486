import * as React from "react";

import { Role } from "./Chapters";

export default (key, method) => {
  switch (key) {
    default:
      return <Role {...{ method }} />;
  }
};
