import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  constants,
  create,
  useLeadForm,
  destroyPatchForm,
  destroyPostForm,
  update,
  useLead,
} from "../../../../store/leads";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { useFilteredPipeAttributes } from "../../../../store/pipeAttributes";
import { cloneDeep } from "lodash";

export default function LeadModalForm({
  method,
  id,
  isOpen,
  onCheckout,
  onLeadCreated,
  hideObjects,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { pipeId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [lastSet, setLastSet] = React.useState(null);

  const attrQuery = buildQueryString({
    pipe: pipeId,
  });

  const [current] = useLead(id);
  const [pipeAttributeChoices] = useFilteredPipeAttributes(attrQuery);

  const pipeAttrValues = useFormInstanceField({
    storeName,
    fieldKey: "pipeattributevalue_set",
  });

  const formLoaded = Boolean(useLeadForm(method, id));

  // handle pipe attributes for post
  React.useEffect(() => {
    if (
      pipeAttrValues ||
      !pipeAttributeChoices?.length ||
      method !== "POST" ||
      current
    )
      return;

    const vals = pipeAttributeChoices.map((attr) => ({
      pipe_attribute: { id: attr.id },
    }));

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          pipeattributevalue_set: vals,
        },
      })
    );
  }, [pipeAttributeChoices]);

  React.useEffect(() => {
    if (method === "PATCH" && !!current && id !== lastSet) {
      let pipeAttrVals = pipeAttributeChoices.map((attr) => ({
        pipe_attribute: { id: attr.id },
      }));

      pipeAttrVals = pipeAttrVals.filter((v) => {
        const exists = current?.pipeattributevalue_set?.find(
          (pav) => pav.pipe_attribute.id === v.pipe_attribute.id
        );

        return !exists;
      });

      const newVals = [
        ...(current?.pipeattributevalue_set || []),
        ...(pipeAttrVals || []),
      ];

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { ...current, pipeattributevalue_set: newVals },
        })
      );

      setLastSet(id);
    }
  }, [id, current]);

  React.useEffect(() => {
    if (!isOpen) {
      setLastSet(null);
    }
  }, [isOpen]);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout(success);
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    onLeadCreated && onLeadCreated(returned);
  };

  const preProcess = (data) => {
    const clone = cloneDeep(data);

    let res = [];
    (clone.pipeattributevalue_set || []).forEach((pa) => {
      if (pa?.char_value != null || pa?.float_value != null || pa?.max_float_value != null) {
        res.push(pa);
      }
    });

    clone.pipeattributevalue_set = res;

    return clone;
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          preProcess,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter({ key, method, hideObjects })}
      submitCallback={onSubmit}
      title={method === "POST" ? "Lägg till lead" : "Uppdatera lead"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
}
