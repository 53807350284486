import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import InvoiceSettingsTable from "../../../../components/Tables/InvoicingSettings/FullTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../../components/sharedStyles";
import { buildQueryString } from "../../../../store/base";

export default function InvoicingDebtSettingUsedBy() {
  const { settingId } = useParams();

  const persistantQuery = {
    debt_setting: settingId,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Används av</OverviewTitle>

            <OverviewSubtitle>
              Visar vilka aviseringsinställningar som använder sig av dessa
              inställningar för betalningsvillkor
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <InvoiceSettingsTable {...{ persistantQuery }} />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
