import * as React from "react";

import * as ISC from "../Details/InfoTable/styles";

import { constructStrRep, useCalendar } from "../../store/calendar";

import { DurationCell, GenericCell, TimeCell } from "../Displays";
import { InnerBox } from "../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";

export default ({ instance }) => {
  const [calendar] = useCalendar(instance?.calendar?.id);

  return (
    <>
      {instance.description?.includes("<html") && (
        <InnerBox style={{ maxWidth: "100%" }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Händelseinfo</OverviewTitle>
          </OverviewTitleWrapper>
          <div
            style={{ maxWidth: "100%", overflowX: "scroll" }}
            dangerouslySetInnerHTML={{ __html: instance.description }}
          />
        </InnerBox>
      )}

      <ISC.InfoTable>
        <ISC.InfoTableContent>
          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Kalender</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.calendar
                ? constructStrRep(instance.calendar)
                : "Kalender saknas"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          {!instance.description?.includes("<html") && (
            <ISC.InfoTableRow>
              <ISC.InfoTableRowTitle>Beskrivning</ISC.InfoTableRowTitle>
              <ISC.InfoTableRowValue>
                {instance?.description ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: instance.description }}
                  />
                ) : (
                  "Beskrivning saknas"
                )}
              </ISC.InfoTableRowValue>
            </ISC.InfoTableRow>
          )}

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Start</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.start ? (
                <TimeCell date={instance.start} />
              ) : (
                "Starttid saknas"
              )}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Slut</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.end ? (
                <TimeCell date={instance.end} />
              ) : (
                "Sluttid saknas"
              )}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Kopplat objekt</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.object_id && instance.content_type ? (
                <GenericCell
                  contentType={instance.content_type}
                  id={instance.object_id}
                />
              ) : (
                "Objekt saknas"
              )}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Plats</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.place ? instance.place.base : "Plats saknas"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Påminn innan</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.dynamic_remind_before &&
              !calendar?.is_outlook_calendar ? (
                <DurationCell durationStamp={instance.dynamic_remind_before} />
              ) : calendar?.is_outlook_calendar &&
                instance?.dynamic_remind_before ? (
                `${instance?.dynamic_remind_before / 60} min`
              ) : (
                "Tid saknas"
              )}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>
              Inför möte information
            </ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.dynamic_remind_content
                ? instance.dynamic_remind_content
                : "Information saknas"}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>

          <ISC.InfoTableRow>
            <ISC.InfoTableRowTitle>Deltagare</ISC.InfoTableRowTitle>
            <ISC.InfoTableRowValue>
              {instance?.participants?.map((p) => (
                <React.Fragment key={p.user?.str_representation}>
                  {p.user?.str_representation} <br />
                </React.Fragment>
              ))}
            </ISC.InfoTableRowValue>
          </ISC.InfoTableRow>
        </ISC.InfoTableContent>
      </ISC.InfoTable>
    </>
  );
};
