import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { axiosInstance } from "../../../store/base/store/axios";
import { getAllPayments } from "../../../store/billectaInvoicing";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import Alert, { ALERT_TYPES } from "../../Alert/Alert";
import LocalDateSelect from "../../Forms/Base/Fields/LocalDateSelect";
import LocalSelectField from "../../Forms/Base/Fields/LocalSelectField";
import LocalTextInputField from "../../Forms/Base/Fields/LocalTextInputField";
import OverlaySpinner from "../../Loaders/OverlaySpinner";

export default function DevCreateUnmatchedPayment() {
  const dispatch = useDispatch();
  const [reference, setReference] = React.useState("");
  const [date, setDate] = React.useState("");
  const [val, setVal] = React.useState("");
  const [type, setType] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const { creditorId } = useParams();

  const createPayment = async () => {
    setLoading(true);
    await axiosInstance.post(`/accounting/gateways/dev/unhandled_payment/`, {
      CreditorPublicId: creditorId,
      Amount: { Value: parseInt(val, 10) * 100, CurrencyCode: "SEK" },
      PaymentDate: date,
      Reference: reference,
      UnhandledPaymentType: type,
    });

    setLoading(false);

    setVal(0);
    setDate("");
    setReference("");

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Betalningen skapades",
        description: "Listan uppdateras...",
      })
    );

    dispatch(getAllPayments(creditorId));
  };

  return (
    <Alert
      type={ALERT_TYPES.INFO}
      primaryAction={createPayment}
      primaryTitle="Skapa omatchad betalning"
      title="(DEMO) Skapa omatchad betalning"
    >
      {loading && <OverlaySpinner />}
      <div className="text-sm mb-6">
        Denna funktion är till för att testa funktioner gällande omatchade
        betalningar.
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTextInputField
          title="Referens"
          value={reference}
          onChange={(val) => setReference(val)}
        />
        <LocalDateSelect
          title="Datum (YYYY-MM-DD)"
          value={date}
          onChange={(val) => setDate(val)}
          required
        />
        <LocalTextInputField
          title="Summa"
          value={val}
          onChange={(val) => setVal(val)}
          required
        />

        <LocalSelectField
          title="Typ av betalning"
          choices={[
            {
              d: "Omatchad",
              v: 0,
            },
            {
              d: "Överbetalning",
              v: 1,
            },
            {
              d: "Manuell",
              v: 2,
            },
            {
              d: "Från faktura",
              v: 4,
            },
          ]}
          onChange={(val) => setType(val)}
          value={type}
        />
      </div>
    </Alert>
  );
}
