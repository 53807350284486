export const constructStrRep = (obj) => {
  if (obj.str_representation) return obj.str_representation;
  if (obj.sensor_id) return obj.sensor_id;
  return "Sensor";
};

export const connectUrl = () => `/imd/connect-sensors`;
export const overviewUrl = () => `/imd/sensors`;
export const detailUrl = () => `/imd/sensors`;
export const handleUrl = () => `/imd/handle-sensors`;
