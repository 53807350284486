export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CALENDAR",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CALENDAR",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CALENDAR",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CALENDAR",
  DESTROY_FORM: "DESTROY_FORM_CALENDAR",
  SET_FORM_ERROR: "SET_FORM_ERROR_CALENDAR",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CALENDAR",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CALENDAR",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CALENDAR",
  RESET_STATE: "RESET_STATE_CALENDAR",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CALENDAR",
  REMOVE_OBJECT: "REMOVE_OBJECT_CALENDAR",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_CALENDAR",
  CLEAR_FETCHED: "CLEAR_FETCHED_CALENDAR",

  LIST_URL: "/events/calendar/list/",
  POST_URL: "/events/calendar/",
  PATCH_URL: "/events/calendar/",
  GET_URL: "/events/calendar/",

  STORE_NAME: "calendar",
};
