import { useSelector, useDispatch } from "react-redux";

import get from "lodash/get";

import { getFormFetchProcessName } from "../utils";

export function useForm({ storeName, method, fetchMethod, id }) {
  const dispatch = useDispatch();

  const processName = getFormFetchProcessName(method);

  const existing = useSelector((state) => state[storeName].forms[method]);
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(processName)
  );

  // first check if it exists in store
  if (existing) {
    return existing;
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result
  if (isFetching) {
    return undefined;
  }

  // if id wasn't set, return undefined as well
  if (!id && method !== "POST") {
    return undefined;
  }

  // we're not waiting, so we should initiate a retrival
  if (method === "PATCH") {
    dispatch(fetchMethod(id));
  } else {
    dispatch(fetchMethod());
  }

  return undefined;
}

export function useFormField({ storeName, method, fieldKey }) {
  return useSelector((state) => {
    const all = state[storeName].forms[method];
    if (!all) {
      return undefined;
    }
    return get(all, fieldKey);
  });
}

export function useFormInstanceField({ storeName, fieldKey }) {
  return useSelector((state) => {
    if (fieldKey === "") {
      return state[storeName].formInstance;
    }
    return get(state[storeName].formInstance, fieldKey);
  });
}

export function useFormError({ storeName, fieldKey }) {
  return useSelector((state) => get(state[storeName].formErrors, fieldKey));
}

export function useChapterErrors({ storeName, chapterDefs }) {
  return useSelector((state) => {
    const fieldErrors = state[storeName].formErrors;

    let chapterErrors = [];
    chapterDefs.forEach((def) => {
      if (!def.fieldKeys) {
        return;
      }

      def.fieldKeys.forEach((fieldKey) => {
        if (chapterErrors.includes(def.key)) {
          return;
        }

        const error = get(fieldErrors, fieldKey);
        if (!error) {
          return;
        }

        let hasErr = false;
        if (error.constructor === Object) {
          hasErr = Boolean(Object.values(error).length);
        } else if (Array.isArray(error)) {
          hasErr = Boolean(error.length);
        } else {
          hasErr = Boolean(error);
        }

        if (hasErr) {
          chapterErrors.push(def.key);
        }
      });
    });

    return chapterErrors;
  });
}
