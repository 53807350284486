import * as React from "react";
import Table from "../../../components/Billecta/Table/BasicTable";
import { DateCell } from "../../../components/Displays";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import {
  detailUrl as requirementDetailUrl,
  useFilteredMarketingRequirements,
} from "../../../store/marketRequirements";
import { detailUrl as apartmentDetailUrl } from "../../../store/apartments";
import { detailUrl as indpDetailUrl } from "../../../store/industrialPremises";
import { detailUrl as parkingDetailUrl } from "../../../store/parkingSpots";
import {
  detailUrl as pipeDetailUrl,
  useFilteredPipes,
} from "../../../store/pipes";
import { RemoveButton } from "../../../components/Billecta/Invoices/InvoiceComponents/Multiple/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { AD_CREATE_TYPES } from "./ManyAdRootObjectSelect";
import { PILOT_OPTIONS } from "./CreateManyAds";
import { useAllPermissionCheck } from "../../../store/base";

export default function CreateAdsTable({
  adsInstance,
  setEditId,
  onSelected,
  type,
}) {
  const [requirements] = useFilteredMarketingRequirements("");
  const [pipes] = useFilteredPipes("");

  const hasHomeQPerm = useAllPermissionCheck(["allow_homeq", "view_can_homeq"]);
  const hasSbfPerm = useAllPermissionCheck(["view_can_sbf", "view_can_market"]);

  const data = React.useMemo(() => {
    return adsInstance.map((ad) => ({
      ...ad,
      requirement: {
        id: ad.requirement,
        str_representation: requirements?.find((r) => r.id == ad.requirement)
          ?.str_representation,
      },
      dump_in_pipe: {
        id: ad.dump_in_pipe,
        str_representation: pipes?.find((p) => p.id == ad.dump_in_pipe)
          ?.str_representation,
      },
    }));
  }, [adsInstance, pipes, requirements]);

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: "",
        id: "remove",
        Cell: ({ row }) => {
          return (
            <RemoveButton
              onClick={(e) => {
                e.stopPropagation();
                onSelected(row);
              }}
            />
          );
        },
      },

      {
        Header: "Publiceringsdatum",
        accessor: "date_publish",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
      {
        Header: "Tillträdesdatum",
        accessor: "access_date",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
      {
        Header: "Annonsläge",
        accessor: "pilot_mode",
        Cell: ({ value }) => {
          const readable = PILOT_OPTIONS.find((o) => o.v === value)?.d;
          return <div>{readable}</div>;
        },
      },
      {
        Header: "Kravprofil",
        accessor: "requirement.str_representation",
        Cell: ({ value, row }) => {
          const url = requirementDetailUrl({
            id: row.original.requirement?.id,
          });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      },
      {
        Header: "Projekt",
        accessor: "dump_in_pipe.str_representation",
        Cell: ({ value, row }) => {
          const url = pipeDetailUrl({
            id: row.original.dump_in_pipe?.id,
          });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      },
      {
        Header: "Pausad",
        accessor: "paused",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 6 : 0}>
              {value ? "Pausad" : "Aktiv"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Tidigare inflytt möjlig",
        accessor: "prior_access",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Tidigare hyresgäster har företräde",
        accessor: "prioritize_customers",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 0 : 6}>
              {value ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
      // {
      //   Header: "Publicera på Pigello Marknad",
      //   accessor: "publish_pigello",
      //   Cell: ({ value }) => {
      //     return (
      //       <StatusLabel state={value ? 0 : 6}>
      //         {value ? "Ja" : "Nej"}
      //       </StatusLabel>
      //     );
      //   },
      // },

      {
        Header: "Annonsbeskrivning",
        accessor: "description",
        Cell: ({ value }) => {
          return (
            <div>
              {value.substr(0, 50)}
              {value?.length > 50 ? "..." : ""}
            </div>
          );
        },
      },
    ];

    if (type === AD_CREATE_TYPES.APARTMENT) {
      cols.splice(1, 0, {
        Header: "Lgh. nr",
        accessor: "apartment_id",
        Cell: ({ value, row }) => {
          const url = apartmentDetailUrl({ id: row.original.id });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      });
      cols.splice(2, 0, {
        Header: "Obj. nr",
        accessor: "premises_id",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      });

      if (hasHomeQPerm) {
        cols.splice(11, 0, {
          Header: "Publicera på HomeQ",
          accessor: "publish_homeq",
          Cell: ({ value }) => {
            return (
              <StatusLabel state={value ? 0 : 6}>
                {value ? "Ja" : "Nej"}
              </StatusLabel>
            );
          },
        });
      }

      if (hasSbfPerm) {
        cols.splice(hasHomeQPerm ? 12 : 11, 0, {
          Header: "Publicera på Bostadsförmedlingen Stockholm",
          accessor: "publish_sbf",
          Cell: ({ value }) => {
            return (
              <StatusLabel state={value ? 0 : 6}>
                {value ? "Ja" : "Nej"}
              </StatusLabel>
            );
          },
        });
      }
    }

    if (type === AD_CREATE_TYPES.INDP) {
      cols.splice(1, 0, {
        Header: "Lokalnr.",
        accessor: "premises_id",
        Cell: ({ value, row }) => {
          const url = indpDetailUrl({ id: row.original.id });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      });
    }

    if (type === AD_CREATE_TYPES.PARKING) {
      cols.splice(1, 0, {
        Header: "Platsnr.",
        accessor: "title",
        Cell: ({ value, row }) => {
          const url = parkingDetailUrl({ id: row.original.id });
          return (
            <TextButton
              title={value}
              iconType="launch"
              iconPlacement="right"
              clicked={() => openInNewTab(url)}
            />
          );
        },
      });
    }

    return cols;
  }, [type]);

  return (
    <Table
      columns={tableColumns}
      data={data}
      onRowClicked={(row) => setEditId(row.original.id)}
    />
  );
}
