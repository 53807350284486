import * as React from "react";
import ManyMediaField from "src/components/Forms/Base/Fields/ManyMediaField";
import { constants } from "../../../../../store/serviceContracts";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <ManyMediaField
        title="Bilagor"
        description="Ladda upp eventuella bilagor till avtalet"
        storeName={storeName}
        method={method}
        fieldKey={"cancelled_doc.attachments"}
      />
    </>
  );
};
