import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function confirmLeads({ postData }) {
  const res = await axiosInstance.post(constants.MASS_ACCEPT_URL, postData);

  return res;
}

async function rejecteads({ postData }) {
  const res = await axiosInstance.post(constants.MASS_REJECT_URL, postData);

  return res;
}

async function getRankings({ ids, url }) {
  const idsQ = ids.join("&ids=");

  const res = await axiosInstance.get(`${url}?ids=${idsQ}`);

  return res;
}

async function getLeadPerStageCount() {
  const res = await axiosInstance.get(constants.STAGE_LEAD_COUNT_URL);

  return res;
}

export { confirmLeads, rejecteads, getRankings, getLeadPerStageCount };
