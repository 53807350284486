import * as React from "react";

// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/servicePartners";

import { useFilteredBuildings } from "../../../../../store/buildings";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const [buildings] = useFilteredBuildings("");

  return (
    <>
      <FormAreaTitle>Servicepartner</FormAreaTitle>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          fieldKey="user.corporate_name"
          method={method}
          title="Bolagets namn"
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          fieldKey="user.legal_id"
          method={method}
          title="Organisationsnummer"
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          title={"Kontaktperson förnamn"}
          fieldKey={"user.first_name"}
          method={method}
        />
        <TextInputField
          storeName={storeName}
          title={"Kontaktperson efternamn"}
          fieldKey={"user.last_name"}
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          fieldKey={"user.email"}
          title="E-post kontakt"
          method={method}
        />
        <TextInputField
          storeName={storeName}
          fieldKey={"user.phone"}
          title="Telefonnummer kontakt"
          method={method}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          placeholder="Välj fastigheter..."
          title="Fastigheter servicepartnern ska arbeta med"
          method={method}
          fieldKey="user.realestates"
          isMany
          TableComponent={RealEstateTable}
        />
      </div>
    </>
  );
};
