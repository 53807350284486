import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useServiceContractForm,
  constants,
  update,
  destroyPostForm,
  detailUrl,
  destroyPatchForm,
  useServiceContract,
} from "../../../../store/serviceContracts";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import moment from "moment";
import { getProposedEndDate } from "../../Base/utils";
import { updateActiveFormInstance } from "../../../../store/base";

export default function CancelServiceContractForm({ method = "PATCH" }) {
  const dispatch = useDispatch();
  const { serviceContractId } = useParams();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useServiceContractForm(method, serviceContractId));
  const [serviceContract] = useServiceContract(serviceContractId);
  const [hasSetInitialData, setHasSetInitialData] = React.useState(false);
  const { replace } = useHistory();

  const isManualSign = true;

  // Set initial data
  React.useEffect(() => {
    if (!serviceContract || hasSetInitialData) return;

    const initialData = {
      notify_interval: serviceContract.notify_interval, // needed to calculate end date
    };
    initialData.closed_on = moment().format("YYYY-MM-DD");

    initialData.status = [6, 7].includes(serviceContract?.state)
      ? serviceContract.state
      : 6;

    // the one not relevant will be deleted in the preprocess
    initialData.closed_date = getProposedEndDate(initialData);
    initialData.closed_renew_date = getProposedEndDate(initialData);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: initialData,
      })
    );

    setHasSetInitialData(true);
  }, [serviceContract]);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    replace(detailUrl({ id: returned.id }));
  };

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id: serviceContractId,
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data, isManualSign }),
      })
    );
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs({
            isManualSign,
          }),
          chapters,
          descriptions,
          method,
          onSubmit,
          isManualSign,
          serviceContract,
          displayDocumentFieldKey: "cancelled_doc.docData_",
          title: "Säg upp avtal",
        }}
      />
    </>
  );
}

const preProcess = ({ data, isManualSign }) => {
  const copy = cloneDeep(data);

  copy.uses_cancellation_e_signing = !isManualSign;
  copy.closed_confirmed = true;

  if (copy.status === 6) {
    delete copy.closed_renew_date;
  } else if (copy.status === 7) {
    delete copy.closed_date;
  }

  if (isManualSign) {
    copy.closed_signed = true;
  }

  // always create an cancelled doc
  if (!copy.cancelled_doc?.title) {
    set(copy, "cancelled_doc.title", "canceldoc");
  }

  return copy;
};
