import { axiosInstance } from "../../store/base/store/axios";
import { getToken } from "../../utils";

const WidgetPlacementDefaults = {
  dashboard: [
     {
        "widget": "linechart",
        "kpi": "rent_per_area",
        "kpi_display": "Hyra per area",
        "grid_row": 1,
        "grid_column": 1,
        "grid_row_end": 3,
        "grid_column_end": 4,
        "start_date": null,
        "end_date": null,
        "interval_type": "l3y",
        "interval_type_display": "Tidigare 3 åren",
        "month_granularity": 6,
        "day_granularity": null,
        "double_result": false,
        "aggregates": [],
        "body_parameters": {
            "tenant_age_intervals": null,
            "building_age_intervals": null
        },
        
    },
    {
        "widget": "barchart",
        "kpi": "vacant_objects_market_rent",
        "kpi_display": "Marknadshyra för vakanta objekt",
        "grid_row": 1,
        "grid_column": 4,
        "grid_row_end": 3,
        "grid_column_end": 7,
        "start_date": null,
        "end_date": null,
        "interval_type": "l3y",
        "interval_type_display": "Tidigare 3 åren",
        "month_granularity": 4,
        "day_granularity": null,
        "double_result": false,
        "aggregates": [],
        "body_parameters": {
            "tenant_age_intervals": null,
            "building_age_intervals": null
        },
      
    },
    {
        "widget": "overview_diff",
        "kpi": "contract_value",
        "kpi_display": "Kontraktsvärde",
        "grid_row": 3,
        "grid_column": 1,
        "grid_row_end": 4,
        "grid_column_end": 2,
        "start_date": null,
        "end_date": null,
        "interval_type": "l3y",
        "interval_type_display": "Tidigare 3 åren",
        "month_granularity": 6,
        "day_granularity": null,
        "double_result": false,
        "aggregates": null,
        "body_parameters": {
            "tenant_age_intervals": null,
            "building_age_intervals": null
        },
        
    },
    {
        "widget": "donutchart_detailed",
        "kpi": "top_tenant",
        "kpi_display": "Topp hyresgäst",
        "grid_row": 3,
        "grid_column": 4,
        "grid_row_end": 5,
        "grid_column_end": 7,
        "start_date": null,
        "end_date": null,
        "interval_type": "l3y",
        "interval_type_display": "Tidigare 3 åren",
        "month_granularity": null,
        "day_granularity": null,
        "double_result": false,
        "aggregates": [],
        "body_parameters": {
            "limit": 5,
            "sort_method": "contract_value",
            "corporate_tenants": "false",
            "tenant_age_intervals": null,
            "building_age_intervals": null
        },
       
    },
    {
        "widget": "overview_general",
        "kpi": "net_leasing",
        "kpi_display": "Nettouthyrning",
        "grid_row": 3,
        "grid_column": 2,
        "grid_row_end": 4,
        "grid_column_end": 3,
        "start_date": null,
        "end_date": null,
        "interval_type": "l3y",
        "interval_type_display": "Tidigare 3 åren",
        "month_granularity": 7,
        "day_granularity": null,
        "double_result": false,
        "aggregates": null,
        "body_parameters": {
            "tenant_age_intervals": null,
            "building_age_intervals": null
        },
        
    }
  
  ],
};

export default async (selectedDefault, title, queryParameters) => {
  const defaultId = selectedDefault?.id;
  const widgetPlacementData = WidgetPlacementDefaults[defaultId];

  const pageData = {
    title: title,
    width: selectedDefault["width"],
    height: selectedDefault["height"],
    query_parameters: queryParameters,
    widget_placements: widgetPlacementData,
  };

  const requestHeaders = {
    Authorization: `Token ${getToken().token}`,
    "Content-Type": "application/json",
  };

  const pagePromise = await axiosInstance.post(
    "/insights/insightspagefromtemplate/",
    pageData,
    {
      headers: requestHeaders,
    }
  );

  return pagePromise.data;
};
