import React, { useEffect } from "react";

import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  localLogin,
  redirectToMgmtPortal,
  setScreenSize,
} from "./store/app/actions";

import { ThemeProvider as SCTheme } from "styled-components";
import theme from "./theme";

import Routes from "./Routes";
import ScrollToTop from "./utils/ScrollToTop";
import { addAlphaScript, addBetaScript } from "./external/gtagScripts";
import { debounce } from "lodash";
import TOSAgreement from "./components/Account/Terms/TOSAgreement";

import { handleSocketUpdates } from "./store/utils";
import { currentEnv, ENVIRONMENTS, SOCKET_URL } from ".";
import ToastList from "./components/Toasts/ToastList";
import { initIntercom } from "./external/intercom";
import MfaSetting from "./components/Account/MFA/MfaSetting";
import moment from "moment";
import "moment/locale/sv";
import UtilUserBanner from "./components/Account/UtilUserBanner/UtilUserBanner";
import { useIsImpersonation } from "./store/app/hooks";

moment.locale("sv");

window["isEmpty"] = function (obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

let socket;

const App = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const isAuthenticated = useSelector((state) => !!state?.app?.user?.id);
  // @ts-ignore
  const user = useSelector((state) => state?.app?.user);
  // @ts-ignore
  const intercomData = useSelector((state) => state?.app?.intercom);
  // @ts-ignore
  const authToken = useSelector((state) => state?.app?.authorization);
  // @ts-ignore
  const mustAcceptTOS = useSelector(
    // @ts-ignore
    (state) => state?.app?.user?.terms_accepted_date == null
  );
  // @ts-ignore
  const forceMfaSetting = useSelector((state) => state?.app?.showMfaSetting);
  // @ts-ignore
  const isAdmin = useIsImpersonation();

  function connectSocket() {
    if (authToken && !socket && socket?.readyState !== "OPEN") {
      socket = new WebSocket(`${SOCKET_URL}?Token=${authToken}`);

      socket.onmessage = function (e) {
        handleSocketUpdates(e, dispatch);
      };

      socket.onerror = function (err) {};
    }
  }

  // intercom setup
  React.useEffect(() => {
    // @ts-ignore
    if (!isAuthenticated || !user?.id) return;
    // @ts-ignore

    initIntercom({
      user,
      intercomData,
      isDev: currentEnv !== ENVIRONMENTS.PROD,
    });
  }, [isAuthenticated, user]);

  React.useEffect(() => {
    if (isAuthenticated && !socket && socket?.readyState !== "OPEN") {
      connectSocket();
    }

    // if (!isAuthenticated && socket) {
    //   try {
    //     socket.close();
    //   } catch (e) {
    //     console.log("could not close socket");
    //   }
    // }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentEnv === ENVIRONMENTS.ALPHA) {
      addAlphaScript();
    } else if (currentEnv === ENVIRONMENTS.PROD) {
      addBetaScript();
    }
    dispatch(localLogin());
  }, []);

  const handleSetScreenSize = () => {
    dispatch(setScreenSize());
  };

  useEffect(() => {
    handleSetScreenSize();
    window.addEventListener("resize", debounce(handleSetScreenSize, 100));

    return () => {
      window.removeEventListener("resize", debounce(handleSetScreenSize));
    };
  }, []);

  // disable changing number input on scroll
  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      // @ts-ignore

      if (document.activeElement.type === "number") {
        // @ts-ignore

        document.activeElement.blur();
      }
    });
  }, []);

  useEffect(() => {
    if (
      !window.location.origin.includes("localhost") &&
      user?.id &&
      !user?.is_staff &&
      user?.permissions?.customer_permissions?.allow_management === true
    ) {
      // set token data in mgmt instead and redirect
      dispatch(redirectToMgmtPortal());
    }
  }, [user]);

  // if (!isAdmin) {
  //   return <ServicePage />;
  // }

  return (
    <SCTheme theme={theme}>
      <BrowserRouter>
        {user?.util_user && <UtilUserBanner />}
        {mustAcceptTOS && <TOSAgreement />}
        {forceMfaSetting && <MfaSetting />}
        <ScrollToTop />
        <ToastList />
        <Routes />
      </BrowserRouter>
    </SCTheme>
  );
};

export default App;
