import * as React from "react";
import { DateCell } from ".";

import { durationParse } from "./utils";

export default ({ durationStamp, shadowDate }) => {
  const parsed = durationParse(durationStamp);
  if (parsed === undefined) {
    return <span className={"eui-textTruncate"}> - </span>;
  }

  if (!shadowDate) {
    return (
      <span className={"eui-textTruncate"}>
        {parsed.presentation}
        &nbsp;
        {parsed.type}
      </span>
    );
  }

  return (
    <span>
      {parsed.presentation}
      &nbsp;
      {parsed.type}
      &nbsp; <DateCell date={shadowDate} />
    </span>
  );
};
