export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MUTATION",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MUTATION",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MUTATION",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MUTATION",
  DESTROY_FORM: "DESTROY_FORM_MUTATION",
  SET_FORM_ERROR: "SET_FORM_ERROR_MUTATION",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MUTATION",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MUTATION",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MUTATION",
  RESET_STATE: "RESET_STATE_MUTATION",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MUTATION",
  REMOVE_OBJECT: "REMOVE_OBJECT_MUTATION",
  CLEAR_FETCHED: "CLEAR_FETCHED_MUTATION",

  LIST_URL: "/general/mutations/mutation/list/",
  POST_URL: "/general/mutations/mutation/",
  PATCH_URL: "/general/mutations/mutation/",
  GET_URL: "/general/mutations/mutation/",

  STORE_NAME: "mutation",
};
