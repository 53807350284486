import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  deleteObject,
  useAnyPermissionCheck,
} from "../../../../store/base";

import Table from "../../../Billecta/Table/BasicTable";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import { DetailInnerWrapper, DetailPageBox } from "../../../sharedStyles";

import {
  constants as costConstants,
  update as updateCost,
  useFilteredCosts,
} from "../../../../store/costs";
import { useParams, useRouteMatch } from "react-router";

import { useApartment } from "../../../../store/apartments";
import { useIndustrialPremises } from "../../../../store/industrialPremises";
import { useParkingSpot } from "../../../../store/parkingSpots";
import { useRealEstate } from "../../../../store/realEstates";
import costCols, { COST_ROW_STATES, getCostRowState } from "./costCols";

import CostFilters from "./CostFilters";
import ConfirmModal from "../../../Forms/Base/Modals/ConfirmModal";
import CostModalForm from "../../../Forms/Costs/ModalForm/ModalForm";
import PrimaryBtn from "../../../Forms/Base/Buttons/PrimaryBtn";
import { PlusIcon } from "@heroicons/react/24/outline";

const getObjectHook = (url) => {
  if (url.includes("apartments")) return useApartment;
  if (url.includes("industrial-premises")) return useIndustrialPremises;
  if (url.includes("parking-spots")) return useParkingSpot;

  return null;
};

const getAttr = (url) => {
  if (url.includes("apartments")) return "apartment";
  if (url.includes("industrial-premises")) return "industrial_premises";
  if (url.includes("parking-spots")) return "parking_spot";

  return null;
};

export default function ObjectCosts() {
  const dispatch = useDispatch();

  const [selectedFilters, setSelectedFilters] = React.useState([
    COST_ROW_STATES.ACTIVE,
    COST_ROW_STATES.COMING,
  ]);

  const [addCostOpen, setAddCostOpen] = React.useState(false);
  const [editCostOpen, setEditCostOpen] = React.useState(false);
  const [deleteCostOpen, setDeleteCostOpen] = React.useState(false);

  const { url } = useRouteMatch();
  const { apartmentId, industrialPremisesId, parkingSpotId } = useParams();

  const objectId = apartmentId || industrialPremisesId || parkingSpotId;

  const useObjectHook = getObjectHook(url);
  const attrName = getAttr(url);

  const [object] = useObjectHook(objectId);
  const [realEstate] = useRealEstate(object?.realestate?.id);

  const canAddCost = useAnyPermissionCheck(["add_can_baseobject"]);

  const canEditCost = useAnyPermissionCheck(["change_can_baseobject"]);

  const [objCosts] = useFilteredCosts(
    buildQueryString({ [attrName]: objectId || "-1" })
  );

  const handleRowDown = (event, cost) => {
    event.stopPropagation();

    const prevOrder = cost.order || 0;
    const newOrder = prevOrder + 1;

    const costThatPreviuoslyHadOrder = objCosts?.find(
      (r) => r.order === newOrder
    );

    // update both rows
    dispatch(
      updateCost({
        id: cost.id,
        forceData: {
          order: newOrder,
        },
        preventDefaultToast: true,
      })
    );

    if (costThatPreviuoslyHadOrder) {
      dispatch(
        updateCost({
          id: costThatPreviuoslyHadOrder.id,
          forceData: {
            order: prevOrder,
          },
          preventDefaultToast: true,
        })
      );
    }
  };

  const handleRowUp = (event, cost) => {
    event.stopPropagation();

    const prevOrder = cost.order || 0;
    const newOrder = prevOrder - 1;

    const costThatPreviuoslyHadOrder = objCosts?.find(
      (r) => r.order === newOrder
    );

    // update both rows
    dispatch(
      updateCost({
        id: cost.id,
        forceData: {
          order: newOrder,
        },
        preventDefaultToast: true,
      })
    );

    if (costThatPreviuoslyHadOrder) {
      dispatch(
        updateCost({
          id: costThatPreviuoslyHadOrder.id,
          forceData: {
            order: prevOrder,
          },
          preventDefaultToast: true,
        })
      );
    }
  };

  const toggleFilter = (filter) => {
    let filterCopy = [...selectedFilters];
    if (filterCopy.includes(filter)) {
      filterCopy = filterCopy.filter((f) => f != filter);
    } else {
      filterCopy.push(filter);
    }

    setSelectedFilters(filterCopy);
  };

  const tableCosts = React.useMemo(() => {
    const costs = cloneDeep(objCosts || []);

    costs.sort((a, b) => {
      return a.order - b.order;
    });

    if (selectedFilters?.length) {
      return costs.filter((fc) => {
        const state = getCostRowState({
          costRow: fc,
          contract: object,
          invoicingObj: object,
        });

        return selectedFilters.includes(state);
      });
    }

    return costs;
  }, [object, objCosts, selectedFilters]);

  const tableColumns = React.useMemo(() => {
    const cols = costCols({
      invoicingObj: object,
      realEstate,
      setDeleteCostOpen,
      handleRowDown,
      handleRowUp,
    });

    return cols;
  }, [realEstate]);

  const handleRemoveCost = (id) => {
    dispatch(
      deleteObject({
        instance: { id },
        constants: costConstants,
      })
    );
  };

  const maxOrder = tableCosts?.[tableCosts?.length - 1]?.order || 0;

  return (
    <>
      {deleteCostOpen && (
        <ConfirmModal
          onAccept={() => handleRemoveCost(deleteCostOpen)}
          closeFunction={() => setDeleteCostOpen(false)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Radera debiteringsrad?
          </h3>
          <p>Denna debiteringsrad kommer att tas bort.</p>
        </ConfirmModal>
      )}

      {addCostOpen && (
        <CostModalForm
          method="POST"
          instance={{
            [attrName]: { id: object?.id },
            order: maxOrder + 1,
          }}
          closeFunction={() => setAddCostOpen(false)}
          areaForRentField={object?.area}
        />
      )}
      {editCostOpen?.id && (
        <CostModalForm
          method="PATCH"
          id={editCostOpen.id}
          instance={editCostOpen}
          closeFunction={() => setEditCostOpen(false)}
          areaForRentField={object?.area}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox
          style={{
            minHeight: 400,
          }}
        >
          {!object && <OverlaySpinner />}

          <div className="flex justify-between items-center">
            <div>
              <CostFilters {...{ selectedFilters, toggleFilter }} />
            </div>

            <div className="flex items-center space-x-2">
              {canAddCost && (
                <PrimaryBtn onClick={() => setAddCostOpen(true)}>
                  <PlusIcon width={16} className="mr-1" /> Lägg till rad
                </PrimaryBtn>
              )}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="text-xs font-medium mb-1 mt-4">
              Debiteringsrader på objekt
            </div>
            <Table
              extraStyles={{ marginTop: 0 }}
              onRowClicked={({ original }) => {
                if (!canEditCost) return;
                setEditCostOpen(original);
              }}
              columns={tableColumns}
              data={tableCosts}
              hideSearch
            />
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
