export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGLOT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGLOT",

  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGLOT",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGLOT",
  DESTROY_FORM: "DESTROY_FORM_PARKINGLOT",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGLOT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGLOT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGLOT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGLOT",
  RESET_STATE: "RESET_STATE_PARKINGLOT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGLOT",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PARKINGLOT",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PARKINGLOT",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGLOT",
  LIST_URL: "/standard/parking/parkinglot/list/",
  POST_URL: "/standard/parking/parkinglot/",
  PATCH_URL: "/standard/parking/parkinglot/",
  GET_URL: "/standard/parking/parkinglot/",

  STORE_NAME: "parkingLots",
};
