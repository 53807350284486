export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_PARKINGCONTRACT_WITH_COSTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_PARKINGCONTRACT_WITH_COSTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PARKINGCONTRACT_WITH_COSTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_PARKINGCONTRACT_WITH_COSTS",
  DESTROY_FORM: "DESTROY_FORM_PARKINGCONTRACT_WITH_COSTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_PARKINGCONTRACT_WITH_COSTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_PARKINGCONTRACT_WITH_COSTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_PARKINGCONTRACT_WITH_COSTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_PARKINGCONTRACT_WITH_COSTS",
  RESET_STATE: "RESET_STATE_PARKINGCONTRACT_WITH_COSTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_PARKINGCONTRACT_WITH_COSTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PARKINGCONTRACT_WITH_COSTS",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_PARKINGCONTRACT_WITH_COSTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_PARKINGCONTRACT_WITH_COSTS",

  LIST_URL: "/standard/parking/parkingcontract/list/fullcosts/",

  STORE_NAME: "parkingContractsWithCosts",
};
