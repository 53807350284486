import * as React from "react";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useParams } from "react-router";
import { useBasicDoc } from "../../../store/basicDocs";
import BasicDocDocumentDisplay from "../../../components/Details/BasicDoc/BasicDocDocumentDisplay";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import { useFilteredNotes } from "../../../store/notes";
import { useRealEstate } from "../../../store/realEstates";
import { GenericCell } from "../../../components/Displays";
import BasicModal from "../../../components/Forms/BasicDoc/ChapterForm/ModalForm";

export default function Document() {
  const { basicDocId } = useParams();

  const [basicDoc] = useBasicDoc(basicDocId);
  const [realEstate] = useRealEstate(basicDoc?.realestate?.id);
  const [editBasicActive, setEditBasicActive] = React.useState(false);

  const canEdit = usePermissionCheck("change_can_baseobject");

  const notesQ = buildQueryString({
    id__in: basicDoc?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const handleDownload = () => {
    window.open(basicDoc.doc.get);
  };

  return (
    <>
      {editBasicActive && (
        <BasicModal
          method="PATCH"
          id={basicDoc?.id}
          instance={basicDoc}
          onCheckout={() => setEditBasicActive(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "65%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Dokument</OverviewTitle>
              {canEdit && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditBasicActive(true)}
                />
              )}
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getInfoObj({ basicDoc, realEstate })} />
          </DetailPageBox>

          <DetailPageBox style={{ width: "35%", alignSelf: "flex-start" }}>
            <Notes
              notes={notes}
              contentType="standard.basicdoc"
              objectId={basicDocId}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Fil</OverviewTitle>
              {basicDoc?.doc?.get && (
                <OverviewSubtitle>
                  <TextButton
                    title="Ladda ner fil"
                    iconType="download"
                    iconPlacement="right"
                    clicked={handleDownload}
                  />
                </OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <BasicDocDocumentDisplay basicdoc={basicDoc} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ basicDoc, realEstate }) {
  const infoObj = {
    Dokument: [
      {
        title: "Kategori",
        value: basicDoc?.category_display,
      },
      {
        title: "Fastighet",
        value: realEstate?.str_representation || "-",
      },

      {
        title: "Kopplat objekt",
        value: (
          <GenericCell
            contentType={basicDoc?.content_type}
            id={basicDoc?.object_id}
          />
        ),
      },
    ],
  };

  return infoObj;
}
