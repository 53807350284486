import styled, { css } from "styled-components";
import AdminBlack from "../../../../assets/svg/material/admin_black.svg";
import AdminWhite from "../../../../assets/svg/material/admin_white.svg";
import StandardBlack from "../../../../assets/svg/material/standard_black.svg";
import StandardWhite from "../../../../assets/svg/material/standard_white.svg";
import GuestBlack from "../../../../assets/svg/material/guest_black.svg";
import GuestWhite from "../../../../assets/svg/material/guest_white.svg";
import WorkerBlack from "../../../../assets/svg/material/worker_black.svg";
import WorkerWhite from "../../../../assets/svg/material/worker_white.svg";
import Expand from "../../../../assets/svg/material/keyboard-arrow-down-black.svg";
import Collapse from "../../../../assets/svg/material/keyboard-arrow-up-black.svg";
import InfoBlack from "../../../../assets/svg/material/info_black.svg";
import InfoBlue from "../../../../assets/svg/material/info_blue.svg";

export const UserTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const UserTypeChoice = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 120px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  border-radius: 12px;
  margin: 12px;
  cursor: pointer;

  ${(p) =>
    p.selected &&
    css`
      background-color: ${(p) => p.theme.colors.primaryBlue};
      color: white;
    `}
`;

export const UserTypeIcon = styled.div`
  width: 42px;
  height: 42px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  ${(p) =>
    p.admin &&
    css`
      background-image: url(${AdminBlack});
    `}

  ${(p) =>
    p.admin &&
    p.active &&
    css`
      background-image: url(${AdminWhite});
    `}

  ${(p) =>
    p.standard &&
    css`
      background-image: url(${StandardBlack});
    `}

  ${(p) =>
    p.standard &&
    p.active &&
    css`
      background-image: url(${StandardWhite});
    `}
    
  ${(p) =>
    p.worker &&
    css`
      background-image: url(${WorkerBlack});
    `}

  ${(p) =>
    p.worker &&
    p.active &&
    css`
      background-image: url(${WorkerWhite});
    `}

  ${(p) =>
    p.guest &&
    css`
      background-image: url(${GuestBlack});
    `}

  ${(p) =>
    p.guest &&
    p.active &&
    css`
      background-image: url(${GuestWhite});
    `}
`;

export const PermPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: ${(p) => p.theme.borders.dark};
  box-shadow: ${(p) => p.theme.boxShadows.discrete};
  position: relative;
  overflow: hidden;
`;

export const HeaderRow = styled.div`
  display: flex;
  padding: 12px;

  border-bottom: ${(p) => p.theme.borders.dark};
  background-color: ${(p) => p.theme.colors.gray4};
  color: #fff;
`;

export const HeaderTitle = styled.p`
  font-size: ${(p) => p.theme.fontSizes.data};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  position: relative;

  ${(p) =>
    p.icon &&
    css`
      cursor: help;
      padding-left: 20px;
      &:before {
        position: absolute;
        height: 16px;
        width: 16px;
        content: "";
        left: -4px;
        top: calc(50% - 8px);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(${InfoBlack});
      }
      &:hover {
        color: #5165fb;
        &:before {
          background-image: url(${InfoBlue});
        }
      }
    `}
`;

export const ChapterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 12px;
  border-bottom: ${(p) => p.theme.borders.dark};

  &:last-child {
    border: none;
  }
  &:nth-child(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }
`;

export const ChapterExpand = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${Expand});

  ${(p) =>
    p.open &&
    css`
      background-image: url(${Collapse});
    `}
`;

export const ChapterContentWrapper = styled.div`
  display: flex;
  width: 95%;
  flex-direction: column;
  padding-left: 12px;
  margin-bottom: 24px;
  border-bottom-right-radius: 8px;
`;

export const ChapterContentRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
`;

export const TableFiller = styled.div`
  width: 32px;
  content: "";
`;

export const SingleToggleRow = styled.div`
  display: flex;
  padding: 24px 12px;
  border-bottom: ${(p) => p.theme.borders.dark};

  &:last-child {
    border: none;
  }
  &:nth-child(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }
`;
