import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { Checkbox, NumberInput, Select } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingOther";
import { useFormInstanceField } from "../../../../../store/base";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });
  const useDeposition = defaultSetting?.automatic_deposit_handling;

  let subtitle = "Hur mycket som ska betalas i deposition, lämna blankt om du vill använda aviseringsinställningens depositionskvot";
  if (defaultSetting?.deposit_quota != null) {
      subtitle += ` (${defaultSetting.deposit_quota}% av bashyran)`
  }

  return (
    <>
      <FormAreaTitle>Depositionshantering </FormAreaTitle>
      {
          !useDeposition ? 
          (
              <>
              Depositions hantering är inte aktiverat på aviseringsinställningen
              </>
          ) :
          (
              <>
                <NumberInput
                storeName={storeName}
                fieldKey={"deposit_value"}
                method={method}
                title={"Depositionsvärde"}
                subtitle={subtitle}
                />
                <Checkbox
                storeName={storeName}
                fieldKey={"deposit_payed"}
                method={method}
                title={"Deposition betald"}
                subtitle={"Ingen faktura, eller annat bokföringsunderlag kommer att sättas upp för depositionen om detta gäller"}
                />
              </>
          )
      }
    </>
  );
};
