export default [
  {
    title: "Inställningar",
    key: "SETTING",
    visited: true,
    hasError: false,
    fieldKeys: [
      "color_code",
      "hello_phrase",
      "hello_description",
      "logo",
      "logo_temp_data",
      "hello_image",
      "hello_image_temp_data",
      "company_name",
      "verbose_key",
      "realestates",
    ],
  },
  {
    title: "Funktionalitet",
    key: "FUNCTIONALITY",
    visited: false,
    hasError: false,
    fieldKeys: ["hide_personal_info_edit_fields", "hide_errand_handling"],
  },
  {
    title: "Logga och Banner",
    key: "IMAGES",
    visited: false,
    hasError: false,
    fieldKeys: [
      "logo",
      "logo_temp_data",
      "hello_image",
      "hello_image_temp_data",
    ],
  },
  {
    title: "Jourinformation",
    key: "JOURINFO",
    visited: false,
    hasError: false,
    fieldKeys: ["jour_info"],
  },
  {
    title: "Kontaktuppgifter",
    key: "CONTACTS",
    visited: false,
    hasError: false,
    fieldKeys: ["contact_persons"],
  },
  {
    title: "Intercom integration",
    key: "INTERCOM",
    visited: false,
    hasError: false,
    fieldKeys: ["intercom_token", "intercom_user_key", "freschat_attributes"],
  },
  {
    title: "Freshchat integration",
    key: "FRESHCHAT",
    visited: false,
    hasError: false,
    fieldKeys: ["freshchat_token", "freschat_attributes"],
  },
  {
    title: "Aptus",
    key: "APPTUS",
    visited: false,
    hasError: false,
    fieldKeys: ["apptus_sso_host", "apptus_sso_customer_name_field", "apptus_sso_hash_key"],
  },
  {
    title: "RCO",
    key: "RCO",
    visited: false,
    hasError: false,
    fieldKeys: ["rco_sso_host", "rco_sso_customer_name_field", "rco_sso_hash_key"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
