import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingDebtSettings";
import { useFormInstanceField } from "../../../../../store/base";
import { NumberInput, RadioGroup, Select } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const useReminders = useFormInstanceField({
    storeName,
    fieldKey: "use_reminders",
  });

  return (
    <>
      <FormAreaTitle>Påminnelser</FormAreaTitle>

      <RadioGroup
        title="Använd påminnelser"
        description="Om denna inställning är aktiv kommer påminnelser att skickas ut efter fakturornas förfallodatum."
        {...{
          storeName,
          method,
          fieldKey: "use_reminders",
        }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      {useReminders && (
        <>
          <NumberInput
            title="Antal dagar efter förfallodatum som påminnelse skickas"
            {...{
              storeName,
              method,
              fieldKey: "send_reminder_after_days",
            }}
          />

          <NumberInput
            title="Betalningsvillkor i antal dagar för påminnelser"
            description="Antal dagar efter utskick av påminnelse som påminnelsefakturan förfaller"
            {...{
              storeName,
              method,
              fieldKey: "reminder_term_days",
            }}
          />

          <NumberInput
            title="Påminnelseavgift"
            {...{
              storeName,
              method,
              fieldKey: "reminder_fee",
            }}
          />

          <NumberInput
            title="Nådedagar för påminnelseavgift"
            description="Nådedagar innebär att om betalning inkommer ett visst antal dagar efter att påminnelsen skickas ut så stryks påminnelseavgiften."
            {...{
              storeName,
              method,
              fieldKey: "reminder_grace_days",
            }}
          />

          <NumberInput
            title="Minsta summa på faktura för att påminnelse ska skickas"
            description="Minsta totalsumman på en faktura för att påminnelse ska skickas ut. Om minsta summan är t.ex. 1 000 SEK så kommer inte påminnelsefakturor skickas på förfallna fakturor som understiger detta belopp."
            {...{
              storeName,
              method,
              fieldKey: "reminder_grace_amount",
            }}
          />
        </>
      )}
    </>
  );
};
